import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllReportsComponent } from './pages';

const routes: Routes = [
    // { path: "", redirectTo: "consent-recieved", pathMatch: "full" },
    { 
      path: "", 
      component: AllReportsComponent, 
      data: {
        titleheading: "All Report",
        title: "All Report",
        minPath: "Basic"
      },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RepotsRoutingModule { }
