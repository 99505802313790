import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { SubscriptionPlanHandlerService } from "./subscription-plan-handler.service";
import { WidgetConfigService } from "./widget-config.service";

@Injectable({ providedIn: "root" })
export class AuthGuardPlan implements CanActivate {
  appSubscriptionPlan;
  constructor(
    private _router: Router,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService
  ) {}

  async canActivate() {

    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
    if (
      this._SubscriptionPlanHandlerService.IsValidRoute(
        this.appSubscriptionPlan
      )
    ) {
      return true;
    } else {
      this._router.navigate(["/app/app-home"], {
        queryParams: { type: "Objectives" },
      });
      return false;
    }
  }
}
