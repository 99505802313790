<div class="qb-spinner" *ngIf="loading">
  <mat-spinner diameter="20"></mat-spinner>
</div>

<div class="qb-body" *ngIf="!loading">
  <div class="qb-main-div">
    <div class="qb-logo">
      <img [src]="partnerData.shopLogoURL" class="qb-logo-image" />
    </div>
    <div class="qb-content">
      <div class="qb-header">
        <div class="qb-header-details">
          <div class="qb-store-name">{{ partnerData.partnerName }}</div>
          <div class="qb-store-link">
            <a [href]="link" target="_blank"> {{ partnerData.shopURL }}</a>
          </div>
          <div class="qb-partner-analytics">
            <div>{{'PARTNERSHIP_PAGE.TOTAL_PARTNER' | i18nTranslate}}: {{ partnerData.totalPartners }}</div>
            <div class="qb-customer-strength">
              <div>
                {{'PARTNERSHIP_PAGE.CUSTOMER_STRENGTH' | i18nTranslate}}: {{ partnerData["customerStrength"] }}
              </div>
              <div
                class="qb-strength"
                *ngIf="partnerData['customerStrength'] === 'high'"
              >
                <div class="qb-strength1"></div>
                <div class="qb-strength2"></div>
                <div class="qb-strength3"></div>
                <div class="qb-strength4"></div>
                <div class="qb-strength5"></div>
              </div>
              <div
                class="qb-strength"
                *ngIf="partnerData['customerStrength'] === 'medium'"
              >
                <div class="qb-mediumstrength1"></div>
                <div class="qb-mediumstrength2"></div>
                <div class="qb-mediumstrength3"></div>
                <div class="qb-mediumstrength4"></div>
                <div class="qb-strength5"></div>
              </div>
              <div
                class="qb-strength"
                *ngIf="partnerData['customerStrength'] === 'low'"
              >
                <div class="qb-lowstrength1"></div>
                <div class="qb-lowstrength2"></div>
                <div class="qb-lowstrength3"></div>
                <div class="qb-lowstrength4"></div>
                <div class="qb-strength5"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="primary-button"
          (click)="acceptRequest(partnerData.partnerName)"
          *ngIf="action === 'view'"
        >
          {{'PARTNERSHIP_PAGE.ACCEPT_REQ' | i18nTranslate}}
        </div>
        <div
          class="primary-button"
          (click)="initiatePartnership()"
          *ngIf="partnerData.isIntiatePartnership && action !== 'view'"
        >
          {{'PARTNERSHIP_PAGE.INITIATE_PATNERSHIP_BACK' | i18nTranslate}}
        </div>
      </div>
      <div class="qb-reward-info">
        <div class="qb-reward-div">
          <div class="qb-reward-image">
            <img
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/reward.png"
            />
          </div>
          <div>
            <div class="qb-store-name"><strong>{{'PARTNERSHIP_PAGE.REWARD' | i18nTranslate}}</strong></div>
            <div class="qb-reward">
              {{'PARTNERSHIP_PAGE.EARN' | i18nTranslate}} <strong>{{ partnerData.rewardValueShared }}%</strong> {{'PARTNERSHIP_PAGE.REWARD_MSG_PREFIX' | i18nTranslate}}
            </div>
          </div>
        </div>
        <div *ngIf="action === 'OFFER_NOT_SHARED'">
          <div class="qb-image-div">
            <img
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/partner-details.png"
            />
          </div>
          <div>
            {{'PARTNERSHIP_PAGE.WAIT_FOR_ACCEPTING_MSG' | i18nTranslate :  partnerData.partnerName}}
          </div>
        </div>
        <div
          class="qb-offer"
          *ngIf="
            action !== 'OFFER_NOT_SHARED' &&
            action !== 'view' &&
            action !== 'sent'
          "
        >
          <div class="qb-reward-div">
            <div class="qb-reward-image">
              <img
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/offer.png"
              />
            </div>
            <div>
              <div class="qb-store-name">
                <strong>{{'PARTNERSHIP_PAGE.OFFER_SHARED_BY_PARTNER' | i18nTranslate}}</strong>
              </div>
              <div class="qb-offer-details">
                <strong>{{
                  partnerData.offersShared[partnerData.offersShared.length - 1]
                    .offerName
                }}</strong>
              </div>
              <div class="qb-offer-info"></div>
            </div>
          </div>
          <div *ngIf="partnerData.activeForms.length == 0" class="qb-form-div">
            <div class="qb-reward-image">
              <img
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/form.png"
              />
            </div>
            <div>
              <div class="qb-offer-info qb-create-form">
                <strong>
                 {{'PARTNERSHIP_PAGE.CREATE_FORM_FOR_WEBSITE' | i18nTranslate}}</strong
                >
              </div>
              <div
                class="primary-button qb-create-form-button"
                (click)="createForm(data.partnershipRequestId)"
              >
                {{'PARTNERSHIP_PAGE.CREATE_FORM' | i18nTranslate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
