import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToTitleCase' })
export class CamelCaseToTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return ''; // Handle null or undefined values

    // Replace underscores with spaces, add space before each capital letter, and convert to Title Case
    return value.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim().replace(/^\w/, (c) => c.toUpperCase());
  }
}
