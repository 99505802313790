<div class="font">
    <div>
      <div class="header">
            <span style="text-align: center">{{ data["subject"] }}</span>
        <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
      </div>
    </div>
    <div style="padding: 20px; height: 280px;">
        <!-- <div class="qb-customer-segments">
            <div class="qb-schedule-bold-text">
              <span>Customer Segments</span
              ><span
                class="qb-i-div-schedule-offer"
                popoverClass="qb-my-custom-class"
                placement="bottom"
                [ngbPopover]="popContentCustomerSegments"
                triggers="mouseenter:mouseleave"
              >
                i
              </span>
            </div>
            <div
              class="qb-form-div-schedule qb-full-width cursor-pointer"
              (click)="selectopenclose('customerSegmentsSelect')"
            >
              <mat-select
                #customerSegmentsSelect
                (selectionChange)="changeSegmentSelection()"
                class="qb-font-exo2"
                color="primary"
                placeholder="Select customer segment"
                multiple
                disableOptionCentering
                panelClass="myPanelClass"
              >
                <div class="qb-rectangle">
                  <div class="qb-rect">
                    <mat-icon class="qb-icon" matPrefix>search </mat-icon>
                    <input
                      class="qb-no-border-input"
                      type="text"
                      placeholder=" Search by name "
                    />
                  </div>
                </div>
                <div class="qb-mat-select-dropdowns">
                  <mat-option
                    *ngFor="
                      let segment of allcustomerObj
                        | labelPipe
                          : templateDispatchForm.controls.tempsegmentFilter
                              .value
                          : 'name'
                    "
                    [value]="segment.id"
                    [disabled]="disableAllCustomerSelection"
                    class="qb-font-exo2"
                    [value]="segment.id"
                  >
                    {{ segment.name }}
                  </mat-option>

                  <mat-option
                    class="qb-font-exo2"
                    *ngFor="
                      let segment of segments
                        | labelPipe
                          : templateDispatchForm.controls.tempsegmentFilter
                              .value
                          : 'name'
                    "
                    [value]="segment.id"
                    [disabled]="
                      disableCustomerSegmentSelection ||
                      !segment['isActive']
                    "
                    [matTooltip]="
                      segment['isActive']
                        ? ''
                        : 'We are preparing your segment. It will be available for use shortly'
                    "
                  >
                    {{ segment.name }}
                  </mat-option>
                </div>
              </mat-select>
              <div
                class="qb-keyboard-down-icon-container qb-rotate cursor-pointer"
                id="keyboard-down-icon-container"
                (click)="selectopenclose('customerSegmentsSelect')"
              >
                <mat-icon
                  class="qb-keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                  role="img"
                  aria-hidden="true"
                  >keyboard_arrow_down</mat-icon
                >
              </div>
            </div>
            <mat-error
              class="qb-error"
              *ngIf="
                !submitted &&
                templateDispatchForm.controls.dispatchcustomerSegments
                  .touched &&
                templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                  'required'
                )
              "
            >
              Segment is required</mat-error
            >
            <mat-error
              class="qb-error"
              *ngIf="
                submitted &&
                templateDispatchForm.controls.dispatchcustomerSegments
                  .touched &&
                templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                  'required'
                )
              "
            >
              Segment is required</mat-error
            >
          </div> -->



          <div class="qb-w-70">
            <div class="qb-d-flex">
              <div class="qb-gf-pyg-title-label">{{'SHARED_PAGE.CUSTOMER_SEGMENTS' | i18nTranslate}}</div>
                <span
                class="qb-i-div"
                popoverclass="qb-my-custom-class"
                placement="right"
                [ngbPopover]="popCustomerSegment"
                triggers="mouseenter:mouseleave"
                >i
                </span>
                <ng-template #popCustomerSegment>
                <div class="qb-ng-template-text">
                  {{'SHARED_PAGE.CUSTOMER_SEGMENTS_INFO' | i18nTranslate}}
                </div>
                </ng-template>
            </div>
            <div class="qb-gf-pyg-normal-label qb-mb-24">
              <!-- customer segement     -->
              <div
                #userSegment
                class="qb-user-segment-div"
              >
                <div>
                  <div
                    class="qb-multi-select-panel-seg"
                    id="multi-select-panel-seg"
                    (click)="toggleSelectSegment()"
                  >
                    <span *ngIf="customerSelectedSegment[0] === 'ALL'"
                      >{{'SHARED_PAGE.SEGMENTS_SELECTED' | i18nTranslate}} :
                      {{'SHARED_PAGE.ALL_CUSTOMERS' | i18nTranslate}}</span
                    >
                    <span *ngIf="customerSelectedSegment[0] !== 'ALL'"
                    >{{'SHARED_PAGE.SEGMENTS_SELECTED' | i18nTranslate}} :
                    {{ customerSelectedSegment.length }}</span
                  >
                    <div id="keyboard-down-icon-container-seg">
                      <mat-icon class="qb-keyboard-down-icon-seg"
                        >keyboard_arrow_down</mat-icon
                      >
                    </div>
                  </div>
                  <div class="qb-p-relative">
                    <div
                      class="qb-multi-select-dropdown-seg"
                      id="multi-select-dropdown-seg"
                    >
                      <div class="qb-search">
                        <mat-icon class="qb-group-icon">search</mat-icon>
                        <input
                          [(ngModel)]="tempSegmentGameFilter"
                          placeholder="Search Segment"
                          type="text"
                          class="qb-search-segment"
                        />
                      </div>
                      <div class="qb-border-line"></div>
                      <div id="segment-select-seg" class="qb-segment-select">
                        <div
                          *ngFor="
                            let customer of allcustomer
                              | labelPipe: tempSegmentGameFilter:'name'
                          "
                        >
                          <mat-checkbox
                            [checked]="
                              customerSelectedSegment.length === 1 &&
                              customerSelectedSegment[0] === 'ALL'
                            "
                            (change)="addAllCustomers($event)"
                            color="primary"
                            >{{ customer.name }}</mat-checkbox
                          >
                          </div>
                          <div
                            *ngFor="
                              let segment of customerSegment
                                | labelPipe: tempSegmentGameFilter:'name'
                            "
                          >
                            <mat-checkbox
                              [checked]="isChecked(segment)"
                              (change)="
                                updateCheckboxValuesCustomerSegment(segment)
                              "
                              [disabled]="segment.isActive===false"
                              color="primary"
                              >{{ segment.name }}
                            </mat-checkbox>

                            <div>
                              <!-- {{customerSelectedSegment | isCheckedPYG: segment.id}} -->
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="qb-invalid-text-h qb-h-10">
                    <!-- <div>{{segmentErrorText}}</div> -->
                  </div>
                </div>
                <!-- {{customerSelectedSegment[0]}} -->
                <div
                class="qb-error-text"
                *ngIf="customerSelectedSegment.length === 0"
              >
                {{'SHARED_PAGE.SELCT_ANY_ONE_SEGEMENT' | i18nTranslate}}
              </div>

                <div
                 class="qb-multiple-select-info"
                >
                  *{{'SHARED_PAGE.SELECT_MULTIPLE_SEGMENT' | i18nTranslate}}
                </div>
              </div>
              <!-- customer segment end -->
            </div>
          </div>


    </div>
  
    <div class="button-container">
        <!-- <span
          class="secondary-button-border"
          *ngIf="!data['cancelButtonText']"
          (click)="submit(false)"
          >Cancel</span
        > -->
        <span class="secondary-button-border" *ngIf="data['cancelButtonText']" (click)="submit(false)"
          data-button-name="Canceled Operation" [attr.data-item-name]="data['data-item']">{{ data["cancelButtonText"]
          }}</span>
        <!-- <span
          class="primary-button"
          *ngIf="!data['successButtonText']"
          (click)="submit(true)"
          >Confirm</span
        > -->
        <span class="primary-button"
          *ngIf="data['successButtonText']"
          (click)="submit(true)">{{
          data["successButtonText"] }}</span>
    
    
        <!-- <div class="primary-button qb-center" *ngIf="data['successButtonText'] &&data['successButtonText']=='Upgrade Now'"
          (click)="submitPlanAction(true)" [attr.data-button-sname]="data['data-button']"
          [attr.data-item-name]="data['data-item']">
          <span *ngIf="!planUpgradeLoading">{{ data["successButtonText"] }}</span>
          <span class="gf-select-btn-preview-loading" *ngIf="planUpgradeLoading">
            <mat-spinner diameter="13"></mat-spinner>
          </span>
        </div> -->
    
        <!-- <span class="primary-button" *ngIf="data['successButtonText']" (click)="submit(true)">Delete</span> -->
      </div>
  </div>