<div class="qb-main-conatainer-serach">
    <div class="qb-header-wrapper">
        <div class="qb-search qb-search-conatiner">
            <mat-icon class="qb-group-icon">search</mat-icon>
            <input #search style="width: 100%;" (input)="onKeySearch(search.value,true)" placeholder="Search by Customer Name/Number/Email"
                type="text" class="qb-search-segment" />
        </div>
        <div class="cursor-pointer" (click)="close()">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#D8DEE6" />
                <g clip-path="url(#clip0_59_1782)">
                    <path d="M19 19L29 29M19 29L29 19" stroke="#4C5664" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_59_1782">
                        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="qb-customer-data-conatiner" (scroll)="onScroll($event)">
        <div class="qb-customer-search-wrapper cursor-pointer" *ngFor="let customer of customers" (click) = "selectCustomer(customer['merchantCustomerId'])">
            <div class="qb-profile-icon">
                <span class="qb-profile-icon-text">
                    {{customer["customerName"].charAt(0)}}
                </span>
            </div>
            <div class="qb-profile-details-search">
                <div class="qb-profile-name">
                    {{customer["customerName"] | limitTo: 18}}
                </div>
                <div class="qb-nationality-text">
                    {{(customer["email"] | limitTo: 25) || '----'}}
                </div>
            </div>
        </div>
        <div class="list-loader-container-search" *ngIf="loading">
            <app-qubriux-loading  [size]="65" [loadingText]="'Fetching your customers...'"></app-qubriux-loading>
        </div>
        <div *ngIf="!loading && customers.length == 0" class="empty-list-indicator-search">
            No Customers Found
        </div>
    </div>
</div>