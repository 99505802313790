import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { ContactUsDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
interface UserData{
  'username':string
  'email':string
}
@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {

  constructor(private apiCall:ApiCallService,
    private dialog: MatDialog,
    public dialogRef:MatDialogRef<DeleteUserComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data:UserData) { }

    apiloading = false;

  ngOnInit() {
  }
  delete(email){
    this.apiloading = true;
    this.apiCall.DeleteUserManagementUser(this.data['email']).subscribe((response)=>{
      this.apiloading = false;
      this.dialogRef.close();
    },(error)=>{
      const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
      width: "380px",
      height: "auto",
      disableClose: true,
      data: {
        header: this._i18nDynamicTranslate.transform("Failure", ['POS_PAGE']),
        body: this._i18nDynamicTranslate.transform(error["error"]["message"], ['POS_PAGE']),
      },
    });
    this.dialogRef.close();
    dialogRef.afterClosed().subscribe((result) => {
     // this.submitted = false;
    });
    })
  }
  submit(decision){
    this.dialogRef.close(decision)
  }

}
