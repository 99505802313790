import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { PaymentComponent, PaymentConfirmationComponent, PaymentOrderSummaryComponent, PaymentPlanComponent, PaymentPosComponent, PaymentPosRedirectComponent, PaymentRedirectComponent, PaymentTopupComponent, PaymentTopUpPosComponent, StoreNotReadyComponent } from './pages';
import { PaymentsRoutingModule } from './payments-routing.module';



@NgModule({
  declarations: [
    PaymentComponent,
    PaymentConfirmationComponent,
    PaymentOrderSummaryComponent,
    PaymentPlanComponent,
    PaymentTopupComponent,
    PaymentPosComponent,
    PaymentPosRedirectComponent,
    StoreNotReadyComponent,
    PaymentTopUpPosComponent,
    PaymentRedirectComponent
  ],
  imports: [
    SharedModule,
    PaymentsRoutingModule
  ]
})
export class PaymentsModule { }
