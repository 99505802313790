import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import { PieChartConfig } from '../config/pie-chart.config';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {

  @Input() data;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  chartContainerElement;

  constructor() { }

  ngOnInit() {
    this.chartContainerElement = this.chartContainer.nativeElement;
  }

  ngOnChanges() {
    this.chartContainerElement = this.chartContainer.nativeElement;
    this.renderGraph()
  }

  renderGraph() {
    let interval = setInterval(() => {
      let width : number = this.chartContainer.nativeElement.offsetWidth;
      let height : number = this.chartContainer.nativeElement.offsetHeight;
      if(width > 0 && height > 0) {
        let radius = (Math.min(width, height) / 2)-25;
        let colors : any;
        let svgClass = 'pie-chart-container';
        let arc : any, outerArc, labelArc;
        let pie : any;
        let svg : any;
        let g : any;
        let legendContainer: any;
        let data : any = this.data;
        let scoreContainer : any;
        let sum: number;
        let config = new PieChartConfig();
        // let d3 : D3 = this.d3;
        if( this.chartContainerElement != null ) {
            d3.selectAll('svg.' + svgClass).remove();
            colors = d3.scaleOrdinal().range(config.colors);
            sum = d3.sum(data, (d) => d.value);
            arc = d3.arc()
            .innerRadius(30)
            .outerRadius(radius - 25);
            outerArc = d3.arc()
            .innerRadius(20)
            .outerRadius(radius - 5);
            labelArc = d3.arc()
            .innerRadius(20)
            .outerRadius(2 * (radius));

            pie = d3.pie()
            .value((d) => d.value)
            .sort(null);

            svg = d3.select(this.chartContainerElement).append('svg')
            .attr('width', width)
            .attr('height', height)
            .attr('class', svgClass)
            .append('g')
            .attr('transform', 'translate(' + (width - (radius + 35)) + ', ' + ((height / 2)-25) + ')');

            g = svg.selectAll('.arc')
            .data(pie(data))
            .enter()
            .append('g')
            .attr('class', 'arc')
            .on('mouseover',(d)=>{
              tooltip.style('display',null)
            })  
            .on('mouseout',(d)=>{
              tooltip.style('display','none')
            })
            .on('mousemove',(event, d)=>{
              let xPos = event.pageX/30;
              let yPos = event.pageY/45;
              tooltip.attr('transform','translate('+ xPos +','+ yPos +')');
              tooltip.select('text')
              .attr('x',xPos)
              .attr('y',yPos)
              .text(d.value)
            });;

            let tooltip = svg.append('g');

            tooltip.append('text')
            .attr('class','tooltip')
            .style('font-size', '20px');

            g.append('path')
            .attr('d', outerArc)
            .style('fill', 'transparent')
            .attr('class', (d) => 'outer-arc-' + d.data.name);

            g.append('path')
            .attr('d', arc)
            .style('fill', (d) => colors(d.data.name));
            // .on('mouseover', (d) => {
            //   d3.selectAll('.outer-arc-' + d.data.label)
            //   .style('fill', colors(d.data.label));
            //   d3.selectAll('.percent-label-' + d.data.label)
            //   .style('fill', 'black');
            // })
            // .on('mouseout', (d) => {
            //   d3.selectAll('.outer-arc-' + d.data.label)
            //   .style('fill', 'transparent');
            //   d3.selectAll('.percent-label-' + d.data.label)
            //   .style('fill', 'transparent');
            // });

            g.append('text')
            .attr('transform', (d) => { return 'translate(' + labelArc.centroid(d) + ')'; })
            .attr('text-anchor', 'middle')
            .attr('class', (d) => 'percent-label-' + d.data.name)
            .attr('fill', 'transparent')
            .text((d) => d.value + '%');

            // g.append('path')
            // .attr('d', (d) => {
            // })

            legendContainer = d3.select('svg.' + svgClass).append('g')
            .attr('class', 'pie-legend-container')
            .attr('transform', 'translate(10, 30)')
            .append('g');

            for(let i = 0; i < data.length; i++) {
              legendContainer.append('rect')
              .attr('x', 0)
              .attr('y', (20 * i))
              .attr('width', 30)
              .attr('height', 15)
              .style('fill', (d) => colors(data[i].name));

              legendContainer.append('text')
              .attr('x', 40)
              .attr('y', (20 * i) + 12)
              .style('font-size', '15px')
              .text((d) => data[i].name + ' (' + data[i].value + '%)');
            }
        }
        clearInterval(interval);
      }
    }, 200)
    

        //            

    
  }

}
