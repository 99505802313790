<div>
    <div class="heading-text"><span>{{'SHARED_PAGE.SURVEY_ACTION_RECOMMENDATION' | i18nTranslate}}</span></div>
    <div style="margin-top: 15px;">
        <form style="margin-top: 5px;" [formGroup]="dataForm">
            <div class="sub-head-text">
                <span>{{'SHARED_PAGE.SELECT_CUSTOMERS' | i18nTranslate}}</span>
            </div>
            <div class="formDiv" [ngClass]="{'error': dataForm.get('customerIds').errors && (dataForm.get('customerIds').touched || submitClicked)}">
                <mat-select class="font-fam" placeholder="Customer Ids" style="width:95%;" class="font-class" formControlName="customerIds" multiple>
                    <!-- <mat-option class="font-class" value="null">Select All</mat-option> -->
                    <mat-option class="font-class" *ngFor="let customer of data['customerIds']" [value]="customer.id">
                        {{customer.name}}</mat-option>
                </mat-select>
            </div>
            <span class="errorText" *ngIf="dataForm.get('customerIds').errors && (dataForm.get('customerIds').touched || submitClicked)">{{'SHARED_PAGE.SELECT_CUSTOMER_REQUIRED' | i18nTranslate}}</span>
            <div *ngIf="data['type'] === 'EMAIL'" style="margin-top: 10px;">
                <div class="sub-head-text">
                    <span>{{'SHARED_PAGE.EMAIL_SUBJECT' | i18nTranslate}}</span>&nbsp;<span style="font-size: 12px; font-weight: 100; color: grey;">({{'SHARED_PAGE.CHARACTER_MAX_50' | i18nTranslate}})</span>
                </div>
                <div class="formDiv" [ngClass]="{'error': dataForm.get('emailSubject').errors && (dataForm.get('emailSubject').touched || submitClicked)}">
                    <input class="formInput" type="text" formControlName="emailSubject">
                </div>
                <span class="errorText" *ngIf="dataForm.get('emailSubject').hasError('required') && (dataForm.get('emailSubject').touched || submitClicked)">{{'SHARED_PAGE.EMAIL_SUBJECT_REQUIRED' | i18nTranslate}}</span>
                <span class="errorText" *ngIf="dataForm.get('emailSubject').errors?.maxlength && (dataForm.get('emailSubject').touched || submitClicked)"> {{'SHARED_PAGE.EMAIL_SUBJECT_MAX_50' | i18nTranslate}}</span>

            </div>
        </form>
    </div>
    <div *ngIf="data['type'] === 'CAMPAIGN'" style="margin-top: 15px; overflow: hidden; max-height: 250px">
        <!-- <div class="sub-head-text" style="display: flex; justify-content:space-between;">
			<img *ngIf="data['type'] === 'OFFER' || data['type'] === 'EMAIL'" class="add-segment-button"
				matTooltip="Create new Campaign" (click)="createTemplate(data['type'])"
				[src]="s3BucketUrl+'/greyfox-assets/segment-assets/group-5.svg'" alt="Add">
		</div> -->
        <div class="template-list" style="margin-top: 5px; max-height: 250px; overflow: auto;">
            <div *ngIf="recommendedTemplate">
                <div>
                    <span class="font-fam" style="font-weight: 600;">{{'SHARED_PAGE.RECOMMENDED_CAMPAIGN' | i18nTranslate}}</span>
                </div>
                <div class="template-card" [style.border]="selectedTemplateId === recommendedTemplate['id'] ? '3px solid #4abb5975' : '3px solid #FFF'" (click)="selectTemplate(recommendedTemplate['id'])">
                    <div style="height: 95px;">
                        <!-- <div class="edit-template-icon">
							<mat-icon (click)="editTemplate(data['type'],recommendedTemplate['id'])" matTooltip="Edit and dispatch"
								class="edit-template-icon-background">edit</mat-icon>
						</div> -->
                        <img style="height: 100%; width: 100%;" class="image-class" [src]="recommendedTemplate['snapshotUrl']" alt="Email">
                    </div>
                    <div class="template-name-div">
                        <div style="display: flex; overflow: hidden;">
                            <span [matTooltip]="recommendedTemplate['layoutName']" style="min-inline-size: max-content;">{{recommendedTemplate['layoutName']}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data['templates'].length > 0" style="width:100%;">
                <div>
                    <span class="font-fam" style="font-weight: 600;">{{'SHARED_PAGE.CHOOSE_CAMPAIGN' | i18nTranslate}}</span>
                </div>
                <div class="template-list">
                    <div *ngFor="let template of data['templates']; let i = index" class="template-card" (click)="selectTemplate(template['id'])" [style.border]="selectedTemplateId === template['id'] ? '3px solid #4abb5975' : '3px solid #FFF'">
                        <div style="height: 95px;">
                            <img style="height: 100%; width: 100%;" class="image-class" [src]="template['snapshot']" alt="Journey">
                        </div>
                        <div class="template-name-div">
                            <div style="display: flex; overflow: hidden;">
                                <span [matTooltip]="template['strategyName']" style="min-inline-size: max-content;">{{template['strategyName']}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data['templates'].length === 0 && !recommendedTemplate" class="no-active-strategy">
                <span>{{'SHARED_PAGE.NO_ACTIVATED_STRATAGIES' | i18nTranslate}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="data['type'] === 'OFFER'" style="margin-top: 15px; max-height: 250px">
        <!-- <div class="sub-head-text" style="display: flex; justify-content:space-between;">
			<div>
				<img *ngIf="data['type'] === 'OFFER' || data['type'] === 'EMAIL'" class="add-segment-button"
					matTooltip="Create new Offer" (click)="createTemplate(data['type'])"
					[src]="s3BucketUrl+'/greyfox-assets/segment-assets/group-5.svg'" alt="Add">
			</div>
		</div> -->
        <div style="margin-top: 5px; overflow: hidden; max-height: 250px; overflow: auto;">
            <div *ngIf="recommendedTemplate">
                <div>
                    <span class="font-fam" style="font-weight: 600;">{{'SHARED_PAGE.RECOMMENDED_OFFER' | i18nTranslate}}</span>
                </div>
                <div class="template-card" [style.border]="selectedTemplateId === recommendedTemplate['id'] ? '3px solid #4abb5975' : '3px solid #FFF'" (click)="selectTemplate(recommendedTemplate['id'])">
                    <div style="height: 95px;">
                        <div class="edit-template-icon">
                            <mat-icon (click)="editTemplate(data['type'],recommendedTemplate['id'])" matTooltip="Edit and dispatch" class="edit-template-icon-background">edit</mat-icon>
                        </div>
                        <img style="height: 100%; width: 100%;" class="image-class" [src]="recommendedTemplate['snapshotUrl']" alt="Email">
                    </div>
                    <div class="template-name-div">
                        <div style="display: flex; overflow: hidden;">
                            <span [matTooltip]="recommendedTemplate['layoutName']" style="min-inline-size: max-content;">{{recommendedTemplate['offerName']}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span class="font-fam" style="font-weight: 600;">{{'SHARED_PAGE.CREATE_OR_CHOOSE_OFFER' | i18nTranslate}}</span>
            </div>
            <div class="template-list">
                <div class="template-card" style="padding: 15px;">
                    <div style="display: flex; align-items: center; justify-content: center;">
                        <div class="add-button-div" (click)="createTemplate(data['type'])">
                            <mat-icon class="add-button-icon">add</mat-icon>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: center; margin-top: 18px;">
                        <span class="font-fam" style="font-weight: 600;">{{'SHARED_PAGE.CREATE_OFFER' | i18nTranslate}}</span>
                    </div>
                </div>
                <div *ngFor="let template of data['templates']; let i = index" class="template-card" (click)="selectTemplate(template['id'])" [style.border]="selectedTemplateId === template['id'] ? '3px solid #4abb5975' : '3px solid #FFF'">
                    <div style="height: 95px;">
                        <div class="edit-template-icon">
                            <mat-icon (click)="editTemplate(data['type'],template['id'])" matTooltip="Edit and dispatch" class="edit-template-icon-background">edit</mat-icon>
                        </div>
                        <img style="height: 100%; width: 100%;" class="image-class" [src]="template['snapshotUrl']" alt="Offer">
                    </div>
                    <div class="template-name-div">
                        <div style="display: flex; overflow: hidden;">
                            <span [matTooltip]="template['emailLayoutName']" style="min-inline-size: max-content;">{{template['offerName']}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data['type'] === 'EMAIL'" style="margin-top: 15px; max-height: 250px">
        <!-- <div class="sub-head-text" style="display: flex; justify-content:space-between;">
			<img *ngIf="data['type'] === 'OFFER' || data['type'] === 'EMAIL'" class="add-segment-button"
				matTooltip="Create new Email" (click)="createTemplate(data['type'])"
				[src]="s3BucketUrl+'/greyfox-assets/segment-assets/group-5.svg'" alt="Add">
		</div> -->
        <div style="margin-top: 5px; overflow: hidden; max-height: 250px; overflow: auto;">
            <div *ngIf="recommendedTemplate">
                <div>
                    <span class="font-fam" style="font-weight: 600;">{{'SHARED_PAGE.RECOMMENDED_EMAIL' | i18nTranslate}}</span>
                </div>
                <div class="template-card" [style.border]="selectedTemplateId === recommendedTemplate['id'] ? '3px solid #4abb5975' : '3px solid #FFF'" (click)="selectTemplate(recommendedTemplate['id'])">
                    <div style="height: 95px;">
                        <div class="edit-template-icon">
                            <mat-icon (click)="editTemplate(data['type'],recommendedTemplate['id'])" matTooltip="Edit and dispatch" class="edit-template-icon-background">edit</mat-icon>
                        </div>
                        <img style="height: 100%; width: 100%;" class="image-class" [src]="recommendedTemplate['snapshotUrl']" alt="Email">
                    </div>
                    <div class=" template-name-div ">
                        <div style="display: flex; overflow: hidden; ">
                            <span [matTooltip]="recommendedTemplate[ 'layoutName'] " style="min-inline-size: max-content; ">{{recommendedTemplate['layoutName']}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span class="font-fam " style="font-weight: 600; ">{{'SHARED_PAGE.CREATE_OR_CHOOSE_EMAIL' | i18nTranslate}}</span>
            </div>
            <div class="template-list ">
                <div class="template-card " style="padding: 15px; ">
                    <div style="display: flex; align-items: center; justify-content: center; ">
                        <div class="add-button-div " (click)="createTemplate(data[ 'type']) ">
                            <mat-icon class="add-button-icon ">add</mat-icon>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: center; margin-top: 18px; ">
                        <span class="font-fam " style="font-weight: 600; ">{{'SHARED_PAGE.CREATE_EMAIL' | i18nTranslate}}</span>
                    </div>
                </div>
                <div [id]=" 'survey-alert-template-'+template[ 'id'] " *ngFor="let template of allTemplates; let i=i ndex " class="template-card " (click)="selectTemplate(template[ 'id']) " [style.border]="selectedTemplateId===template[ 'id']
                            ? '3px solid #4abb5975' : '3px solid #FFF' ">
                    <div style="height: 95px; ">
                        <div class="edit-template-icon ">
                            <mat-icon (click)="editTemplate(data[ 'type'],template[ 'id']) " matTooltip="Edit and dispatch " class="edit-template-icon-background ">edit</mat-icon>
                        </div>
                        <div class="image-class ">
                            <img style="    height: 95px;
                            width: 100%; width: 100%; " [src]="template[ 'snapshotUrl'] " (error)="doSomethingOnError($event,template[ 'id'],template[ 'snapshotUrl'] )">
                            <div style="    position: absolute;
                            top: 40%;
                            left: 50%;
                            transform: translate(-50%, -50%);">
                                <mat-spinner *ngIf="recentNull && template[ 'snapshotUrl']==null" strokeWidth="3" [diameter]="40"></mat-spinner>

                            </div>
                        </div>
                    </div>
                    <div class="template-name-div ">
                        <div style="display: flex; overflow: hidden; ">
                            <span [matTooltip]="template[ 'layoutName'] " style="min-inline-size: max-content; ">{{template['layoutName']}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="!systemTemplatesSelected ">
		<div *ngIf="data[ 'type']==='CAMPAIGN' " style="margin-top: 15px; overflow: hidden; max-height: 250px ">
			<div class="sub-head-text " style="display: flex; justify-content:space-between; ">
				<span style="display: flex; align-items: center; ">Recommended Campaign</span>
				<div style="display: flex; align-items: center; ">
					<div matTooltip="Show More Campaigns " style="display: flex; cursor: pointer; "
						(click)="switchRecommendedTemplate(true) ">
						<mat-icon class="more-templates-icon ">apps</mat-icon>
					</div>
					<img *ngIf="data[ 'type']==='OFFER' || data[ 'type']==='EMAIL' " class="add-segment-button "
						matTooltip="Create new Campaign " (click)="createTemplate(data[ 'type']) "
						[src]="s3BucketUrl+ '/greyfox-assets/segment-assets/group-5.svg' " alt="Add ">
				</div>
			</div>
			<div class="template-list " style="margin-top: 5px; max-height: 200px; overflow: auto; ">
				<div *ngFor="let template of data[ 'templates']; let i=i ndex " class="template-card " (click)="selectTemplate(i) "
					[style.border]="selectedTemplate===i ? '3px solid #4abb5975' : '3px solid #FFF' ">
					<div style="height: 95px; ">
						<img style="height: 100%; width: 100%; " class="image-class " [src]="template[ 'snapshot'] " alt="Campaign ">
					</div>
					<div class="template-name-div ">
						<div style="display: flex; overflow: hidden; ">
							<span [matTooltip]="template[ 'strategyName'] "
								style="min-inline-size: max-content; ">{{template['strategyName']}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="data[ 'type']==='OFFER' " style="margin-top: 15px; max-height: 250px ">
			<div class="sub-head-text " style="display: flex; justify-content:space-between; ">
				<span style="display: flex; align-items: center; ">Recommended Offer</span>
				<div style="display: flex; align-items: center; ">
					<div matTooltip="Show more Offers " style="display: flex; cursor: pointer; "
						(click)="switchRecommendedTemplate(true) ">
						<mat-icon class="more-templates-icon ">apps</mat-icon>
					</div>
					<div>
						<img *ngIf="data[ 'type']==='OFFER' || data[ 'type']==='EMAIL' " class="add-segment-button "
							matTooltip="Create new Offer " (click)="createTemplate(data[ 'type']) "
							[src]="s3BucketUrl+ '/greyfox-assets/segment-assets/group-5.svg' " alt="Add ">
					</div>
				</div>
			</div>
			<div class="template-list " style="margin-top: 5px; overflow: hidden; max-height: 200px; overflow: auto; ">
				<div *ngFor="let template of data[ 'templates']; let i=i ndex " class="template-card " (click)="selectTemplate(i) "
					[style.border]="selectedTemplate===i ? '3px solid #4abb5975' : '3px solid #FFF' ">
					<div style="height: 95px; ">
						<div class="edit-template-icon ">
							<mat-icon (click)="editTemplate(data[ 'type'],i) " matTooltip="Edit and dispatch "
								class="edit-template-icon-background ">edit</mat-icon>
						</div>
						<img style="height: 100%; width: 100%; " class="image-class " [src]="template[ 'snapshotUrl'] " alt="Offer ">
					</div>
					<div class="template-name-div ">
						<div style="display: flex; overflow: hidden; ">
							<span [matTooltip]="template[ 'emailLayoutName'] "
								style="min-inline-size: max-content; ">{{template['emailLayoutName']}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="data[ 'type']==='EMAIL' " style="margin-top: 15px; max-height: 250px ">
			<div class="sub-head-text " style="display: flex; justify-content:space-between; ">
				<span style="display: flex; align-items: center; ">Recommended Email</span>
				<div style="display: flex; align-items: center; ">
					<div matTooltip="Show more Emails " style="display: flex; cursor: pointer; "
						(click)="switchRecommendedTemplate(true) ">
						<mat-icon class="more-templates-icon ">apps</mat-icon>
					</div>
					<img *ngIf="data[ 'type']==='OFFER' || data[ 'type']==='EMAIL' " class="add-segment-button "
						matTooltip="Create new Email " (click)="createTemplate(data[ 'type']) "
						[src]="s3BucketUrl+ '/greyfox-assets/segment-assets/group-5.svg' " alt="Add ">
				</div>
			</div>
			<div class="template-list " style="margin-top: 5px; overflow: hidden; max-height: 200px; overflow: auto; ">
				<div *ngFor="let template of data[ 'templates']; let i=i ndex " class="template-card " (click)="selectTemplate(i) "
					[style.border]="selectedTemplate===i ? '3px solid #4abb5975' : '3px solid #FFF' ">
					<div style="height: 95px; ">
						<div class="edit-template-icon ">
							<mat-icon (click)="editTemplate(data[ 'type'],i) " matTooltip="Edit and dispatch "
								class="edit-template-icon-background ">edit</mat-icon>
						</div>
						<img style="height: 100%; width: 100%; " class="image-class " [src]="template[ 'snapshotUrl'] " alt="Email ">
					</div>
					<div class="template-name-div ">
						<div style="display: flex; overflow: hidden; ">
							<span [matTooltip]="template[ 'layoutName'] "
								style="min-inline-size: max-content; ">{{template['layoutName']}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
    <div style="display: flex; justify-content: flex-end; margin-top: 10px; ">
        <div (click)="closeDialog(false) " class="dismiss-button "><span style=" font-size: 13px; ">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span></div>
        <div *ngIf="data[ 'type']==='CAMPAIGN' " (click)="closeDialog(true) " class="done-button " style="margin-left: 10px; "><span style=" font-size: 13px; ">{{'SHARED_PAGE.ADD' | i18nTranslate}}</span></div>
        <div *ngIf="data[ 'type']==='EMAIL' || data[ 'type']==='OFFER' " (click)="closeDialog(true) " class="done-button " style="margin-left: 10px; "><span style=" font-size: 13px; ">{{'SHARED_PAGE.SEND' | i18nTranslate}}</span></div>
    </div>
</div>