<div
  class="qb-insightAnalytics qb-main-container"
  data-widget-name="JOURNEY-ANALYTICS"
  id="parent-div"
>
  <div class="qb-w-100 qb-select-journey-secondry">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div>
    <div class="qb-header">
      <div class="qb-header-div">
        <div>
          <div class="qb-d-flex" *ngIf="validWidgets | widgetConfig : 82">
            <div class="qb-filter-wrapper">
              <div class="qb-heading-analytics-text">
                {{ getDropDownType("campaign") }} &nbsp;&nbsp;
              </div>
              <div class="qb-d-flex">
                <div
                  class="attribute-value"
                  (click)="
                    toggleDropdown($event, logExpIndex, compIndex, 'value')
                  "
                >
                  <div class="attribute-value-text" [title]="selectedId">
                    {{ getNameFromEmailSenderList(selectedId) }}
                  </div>
                  <mat-icon
                    class="down-arrow"
                    [id]="'down-arrow-value' + logExpIndex + compIndex"
                    >keyboard_arrow_down</mat-icon
                  >
                  <div
                    class="dropdown parameter-dropdown"
                    [id]="'value-dropdown' + logExpIndex + compIndex"
                  >
                    <div class="category-container">
                      <div
                        *ngFor="let category of categoryList"
                        class="category"
                        title="{{ category }}"
                        (click)="selectJourneyCategory(category)"
                        [ngClass]="{
                          'active-category': mainCategory === category
                        }"
                      >
                        {{ getCategoryDisplayName(category) }}
                      </div>
                    </div>
                    <div class="parameters-container">
                      <input
                        class="op-search"
                        placeholder="Search"
                        type="text"
                        (keydown)="stringInputHandler($event, 50)"
                        [(ngModel)]="op2ListSearch"
                      />
                      <div
                        class="search-filtered-dropdown"
                        *ngIf="!dropdownListLoading"
                      >
                        <div
                          *ngFor="let item of searchFiltered(modules)"
                          class="dropdown-opt"
                          (click)="selectJourney(item)"
                          [ngClass]="{
                            'active-opt': selectedId == item.moduleId
                          }"
                          [matTooltip]="item.moduleName"
                          [matTooltipPosition]="'right'"
                          [matTooltipShowDelay]="500"
                        >
                          <span>{{ item.moduleName }}</span>
                        </div>
                        <div
                          class="no-product"
                          *ngIf="searchFiltered(modules).length === 0"
                          (click)="$event.stopPropagation()"
                        >
                          {{ "SEGMENT_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
                        </div>
                      </div>
                      <div *ngIf="dropdownListLoading">
                        <div class="qb-d-flex qb-jc-center">
                          <mat-spinner diameter="12"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedId !== -1" class="qb-view-data-main-container">
    <div class="qb-wrapper-journey-analyrtics">
      <div class="qb-header">
        <div class="qb-d-flex qb-heading-container">
          <div class="qb-sub-heading-journey-analytics">
            {{ selectedModule["moduleName"] }}
          </div>
          <div
            style="font-size: 10px"
            class="mini-list-item"
            [matTooltip]="
              'Created by ' +
              getCreatedByData(strategyData?.created_by, 'storeName')
            "
          >
            <span class="material-symbols-outlined">person_edit</span>
            {{ getCreatedByData(strategyData?.created_by, "storeName") }}
          </div>
          <div
            class="qb-sub-heading-status-journey-analytics"
            [ngClass]="{
              'qb-active': selectedModule['status'].toUpperCase() === 'ACTIVE',
              'qb-ended': selectedModule['status'].toUpperCase() === 'ENDED'
            }"
          >
            {{ selectedModule["status"] }}
          </div>
        </div>
        <div style="display: flex; margin-top: 5px">
          <div style="max-width: 50%; gap: 10px" class="qb-d-flex">
            <div
              class="segment-name-container"
              *ngIf="strategyData.segments.length > 0"
            >
              <span
                class="align-center-header"
                *ngFor="let segment of strategyData.segments; let i = index"
                style="min-width: fit-content"
                >{{ segment["segmentName"]
                }}<span *ngIf="i < strategyData.segments.length - 1"
                  >,&nbsp;</span
                ></span
              >
            </div>
            <div *ngIf="strategyData.allCustomer">
              <span
                class="align-center-header"
                style="min-width: fit-content"
                >{{ "CAMPAIGN_PAGE.ALL_CUSTOMERS" | i18nTranslate }}</span
              >
            </div>
            <div *ngIf="strategyData.allLead">
              <span
                class="align-center-header"
                style="min-width: fit-content"
                >{{ "CAMPAIGN_PAGE.ALL_LEADS" | i18nTranslate }}</span
              >
            </div>
          </div>
          <span *ngIf="showMoreSegmentButtonStatus">...</span>
          <div
            *ngIf="showMoreSegmentButtonStatus"
            class="align-center-header more-segment-chevron"
          >
            <span
              (click)="showSegments($event)"
              class="chevron-icon-header align-center-header"
              data-widget-name="CANVAS"
              data-button-name="Show Segment"
              class="material-icons icons-primary chevron-icon"
            >
              double_arrow
            </span>
            <div class="segment-popup" id="segment-popup">
              <span
                style="padding: 5px 7px"
                *ngFor="let segment of strategyData.segments"
                >{{ segment["segmentName"] }}</span
              >
            </div>
          </div>
          <div class="header-seperator"></div>
          <div style="display: flex" class="align-center-header">
            <span class="material-icons-outlined person-icon icons-primary">
              person
            </span>
            <span style="margin-left: 5px; font-size: 13px">{{
              strategyData.customerCount
            }}</span>
          </div>
        </div>
        <div class="qb-tab-container-analytics">
          <div class="qb-tab-analytics">
            <div class="qb-tab-container-module-analytics">
              <div
                *ngFor="
                  let subModule of moduleAnalyticsData
                    | keyvalue : orderByRowNum
                "
                class="tab-label"
                (click)="subModucleChange(subModule.key)"
                [ngClass]="{ active: mainTopic == subModule.key }"
              >
                {{ getDisplayName(subModule.key) }}
              </div>
            </div>
            <div
              class="no-product"
              (click)="$event.stopPropagation()"
              style="display: none;"
              id="no-moduleAnalytics-data"
            >
              <div>
                {{ "SEGMENT_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="qb-header-deatils-container">
          <div
            class="qb-analytics-card-container"
            *ngFor="
              let data of moduleAnalyticsData[mainTopic]
                | keyvalue : orderByRowNum
            "
          >
            <div class="qb-profile-icon">
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'delivered'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="M268-240 42-466l57-56 170 170 56 56-57 56Zm226 0L268-466l56-57 170 170 368-368 56 57-424 424Zm0-226-57-56 198-198 57 56-198 198Z"
                />
              </svg>
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'sent'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"
                />
              </svg>
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'unsubscribed'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="M480-440 160-640v400h320q0 21 3 40.5t9 39.5H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v228q-18-9-38.5-15t-41.5-9v-124L480-440Zm0-80 320-200H160l320 200ZM760-40q-83 0-141.5-58.5T560-240q0-83 58.5-141.5T760-440q83 0 141.5 58.5T960-240q0 83-58.5 141.5T760-40ZM640-220h240v-40H640v40Zm-480-20v-480 480Z"
                />
              </svg>
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'failed'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                />
              </svg>
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'spam'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"
                />
              </svg>
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'opened'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="m480-920 362 216q18 11 28 30t10 40v434q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200v-434q0-21 10-40t28-30l362-216Zm0 466 312-186-312-186-312 186 312 186Zm0 94L160-552v352h640v-352L480-360Zm0 160h320-640 320Z"
                />
              </svg>
              <svg
                *ngIf="data.key.trim().toLowerCase() === 'clicked'"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FEC53D"
              >
                <path
                  d="M638-80 468-250l56-56 114 114 226-226 56 56L638-80ZM480-520l320-200H160l320 200Zm0 80L160-640v400h206l80 80H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v174l-80 80v-174L480-440Zm0 0Zm0-80Zm0 80Z"
                />
              </svg>
            </div>
            <div class="qb-details-container">
              <div class="qb-analytics-header-text">
                {{ data.value.displayName }}
              </div>
              <div class="qb-analytics-value-text">
                {{ data.value.value }}
                <span *ngIf="data.key.trim().toLowerCase() !== 'sent'">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-accordion
        *ngFor="let data of subModuleAnalyticsData | keyvalue : orderByRowNum"
      >
        <mat-expansion-panel
          class="qb-mat-expansion"
          (opened)="getSelectedModuleData(mainTopic, data.key)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="qb-text-transform-capitalize">
              {{ getsufixOfDisplayName() }} {{ getModuleName(data.key) }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <div class="qb-header-deatils-container" [id]="'data' + data.key">
              <div
                class="qb-analytics-card-container"
                *ngFor="
                  let subdata of subModuleAnalyticsData[data.key]
                    | keyvalue : orderByRowNum
                "
                [ngClass]="{
                  'qb-analytics-card-container-hover':
                    nonClickableEvent.indexOf(subdata.key.toLowerCase()) ===
                      -1 && subdata.value.value > 0,
                  'qb-analytics-card-not-allowed':
                    nonClickableEvent.indexOf(subdata.key.toLowerCase()) !==
                      -1 || subdata.value.value === 0
                }"
                (click)="
                  nonClickableEvent.indexOf(subdata.key.toLowerCase()) === -1 &&
                  subdata.value.value > 0
                    ? getCustomerData(data.key, subdata)
                    : ''
                "
              >
                <div class="qb-profile-icon">
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'delivered'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="M268-240 42-466l57-56 170 170 56 56-57 56Zm226 0L268-466l56-57 170 170 368-368 56 57-424 424Zm0-226-57-56 198-198 57 56-198 198Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'redeemed'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="M856-390 570-104q-12 12-27 18t-30 6q-15 0-30-6t-27-18L103-457q-11-11-17-25.5T80-513v-287q0-33 23.5-56.5T160-880h287q16 0 31 6.5t26 17.5l352 353q12 12 17.5 27t5.5 30q0 15-5.5 29.5T856-390ZM513-160l286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640Zm220 160Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'sent'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'unsubscribed'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="M480-440 160-640v400h320q0 21 3 40.5t9 39.5H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v228q-18-9-38.5-15t-41.5-9v-124L480-440Zm0-80 320-200H160l320 200ZM760-40q-83 0-141.5-58.5T560-240q0-83 58.5-141.5T760-440q83 0 141.5 58.5T960-240q0 83-58.5 141.5T760-40ZM640-220h240v-40H640v40Zm-480-20v-480 480Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'failed'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'spam'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'opened'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="m480-920 362 216q18 11 28 30t10 40v434q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200v-434q0-21 10-40t28-30l362-216Zm0 466 312-186-312-186-312 186 312 186Zm0 94L160-552v352h640v-352L480-360Zm0 160h320-640 320Z"
                    />
                  </svg>
                  <svg
                    *ngIf="subdata.key.trim().toLowerCase() === 'clicked'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FEC53D"
                  >
                    <path
                      d="M638-80 468-250l56-56 114 114 226-226 56 56L638-80ZM480-520l320-200H160l320 200Zm0 80L160-640v400h206l80 80H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v174l-80 80v-174L480-440Zm0 0Zm0-80Zm0 80Z"
                    />
                  </svg>
                </div>
                <div class="qb-details-container">
                  <div class="qb-analytics-header-text">
                    {{ subdata.value.displayName }}
                  </div>
                  <div class="qb-analytics-value-text">
                    {{ subdata.value.value }}
                  </div>
                </div>
              </div>
              <div
                class="no-product"
                style="display: none"
                (click)="$event.stopPropagation()"
                [id]="'noData' + data.key"
              >
                <div>
                  {{ "SEGMENT_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
                </div>
              </div>
            </div>
            <div
              style="height: 100%; justify-content: center; align-items: center"
              [id]="data.key"
            >
              <app-qubriux-loading
                [size]="45"
                [loadingText]="'REGISTRATION_PAGE.LOADING' | i18nTranslate"
              ></app-qubriux-loading>
            </div>
          </mat-expansion-panel-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div *ngIf="selectedId === -1" class="qb-illustration-container">
    <img
      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/illustrations/img_illustration-Data-Analysis+(1).svg"
      alt=""
    />
  </div>
</div>
