import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-negotiator-template-preview',
  templateUrl: './negotiator-template-preview.component.html',
  styleUrls: ['./negotiator-template-preview.component.css']
})
export class NegotiatorTemplatePreviewComponent implements OnInit {
  constructor() { }
  @Input() selectedLayout;
  ngOnInit() {
  }
}
