export const countries =
[
    {
      "name": "Afghanistan",
      "alpha2Code": "AF",
      "callingCodes": [
        "93"
      ],
      "currencies": [
        {
          "code": "AFN",
          "name": "Afghan afghani",
          "symbol": "؋"
        }
      ],
      "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Åland Islands",
      "alpha2Code": "AX",
      "callingCodes": [
        "358"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/ax.svg",
      "independent": false,
      "phoneLength": [7,8,9,10]
    },
    {
      "name": "Albania",
      "alpha2Code": "AL",
      "callingCodes": [
        "355"
      ],
      "currencies": [
        {
          "code": "ALL",
          "name": "Albanian lek",
          "symbol": "L"
        }
      ],
      "flag": "https://flagcdn.com/al.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Algeria",
      "alpha2Code": "DZ",
      "callingCodes": [
        "213"
      ],
      "currencies": [
        {
          "code": "DZD",
          "name": "Algerian dinar",
          "symbol": "د.ج"
        }
      ],
      "flag": "https://flagcdn.com/dz.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "American Samoa",
      "alpha2Code": "AS",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States Dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/as.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Andorra",
      "alpha2Code": "AD",
      "callingCodes": [
        "376"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/ad.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Angola",
      "alpha2Code": "AO",
      "callingCodes": [
        "244"
      ],
      "currencies": [
        {
          "code": "AOA",
          "name": "Angolan kwanza",
          "symbol": "Kz"
        }
      ],
      "flag": "https://flagcdn.com/ao.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Anguilla",
      "alpha2Code": "AI",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ai.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Antarctica",
      "alpha2Code": "AQ",
      "callingCodes": [
        "672"
      ],
      "flag": "https://flagcdn.com/aq.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Antigua and Barbuda",
      "alpha2Code": "AG",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ag.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Argentina",
      "alpha2Code": "AR",
      "callingCodes": [
        "54"
      ],
      "currencies": [
        {
          "code": "ARS",
          "name": "Argentine peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ar.svg",
      "independent": false,
      "phoneLength": [
        6,
        7,
        8
      ]
    },
    {
      "name": "Armenia",
      "alpha2Code": "AM",
      "callingCodes": [
        "374"
      ],
      "currencies": [
        {
          "code": "AMD",
          "name": "Armenian dram",
          "symbol": "֏"
        }
      ],
      "flag": "https://flagcdn.com/am.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Aruba",
      "alpha2Code": "AW",
      "callingCodes": [
        "297"
      ],
      "currencies": [
        {
          "code": "AWG",
          "name": "Aruban florin",
          "symbol": "ƒ"
        }
      ],
      "flag": "https://flagcdn.com/aw.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Australia",
      "alpha2Code": "AU",
      "callingCodes": [
        "61"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/au.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Austria",
      "alpha2Code": "AT",
      "callingCodes": [
        "43"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/at.svg",
      "independent": false,
      "phoneLength": [
        10,
        11
      ]
    },
    {
      "name": "Azerbaijan",
      "alpha2Code": "AZ",
      "callingCodes": [
        "994"
      ],
      "currencies": [
        {
          "code": "AZN",
          "name": "Azerbaijani manat",
          "symbol": "₼"
        }
      ],
      "flag": "https://flagcdn.com/az.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Bahamas",
      "alpha2Code": "BS",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "BSD",
          "name": "Bahamian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/bs.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Bahrain",
      "alpha2Code": "BH",
      "callingCodes": [
        "973"
      ],
      "currencies": [
        {
          "code": "BHD",
          "name": "Bahraini dinar",
          "symbol": ".د.ب"
        }
      ],
      "flag": "https://flagcdn.com/bh.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Bangladesh",
      "alpha2Code": "BD",
      "callingCodes": [
        "880"
      ],
      "currencies": [
        {
          "code": "BDT",
          "name": "Bangladeshi taka",
          "symbol": "৳"
        }
      ],
      "flag": "https://flagcdn.com/bd.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Barbados",
      "alpha2Code": "BB",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "BBD",
          "name": "Barbadian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/bb.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Belarus",
      "alpha2Code": "BY",
      "callingCodes": [
        "375"
      ],
      "currencies": [
        {
          "code": "BYN",
          "name": "New Belarusian ruble",
          "symbol": "Br"
        },
        {
          "code": "BYR",
          "name": "Old Belarusian ruble",
          "symbol": "Br"
        }
      ],
      "flag": "https://flagcdn.com/by.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Belgium",
      "alpha2Code": "BE",
      "callingCodes": [
        "32"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/be.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Belize",
      "alpha2Code": "BZ",
      "callingCodes": [
        "501"
      ],
      "currencies": [
        {
          "code": "BZD",
          "name": "Belize dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/bz.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Benin",
      "alpha2Code": "BJ",
      "callingCodes": [
        "229"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/bj.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Bermuda",
      "alpha2Code": "BM",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "BMD",
          "name": "Bermudian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/bm.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Bhutan",
      "alpha2Code": "BT",
      "callingCodes": [
        "975"
      ],
      "currencies": [
        {
          "code": "BTN",
          "name": "Bhutanese ngultrum",
          "symbol": "Nu."
        },
        {
          "code": "INR",
          "name": "Indian rupee",
          "symbol": "₹"
        }
      ],
      "flag": "https://flagcdn.com/bt.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Bolivia (Plurinational State of)",
      "alpha2Code": "BO",
      "callingCodes": [
        "591"
      ],
      "currencies": [
        {
          "code": "BOB",
          "name": "Bolivian boliviano",
          "symbol": "Bs."
        }
      ],
      "flag": "https://flagcdn.com/bo.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Bonaire, Sint Eustatius and Saba",
      "alpha2Code": "BQ",
      "callingCodes": [
        "599"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/bq.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Bosnia and Herzegovina",
      "alpha2Code": "BA",
      "callingCodes": [
        "387"
      ],
      "currencies": [
        {
          "code": "BAM",
          "name": "Bosnia and Herzegovina convertible mark",
          "symbol": "KM"
        }
      ],
      "flag": "https://flagcdn.com/ba.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Botswana",
      "alpha2Code": "BW",
      "callingCodes": [
        "267"
      ],
      "currencies": [
        {
          "code": "BWP",
          "name": "Botswana pula",
          "symbol": "P"
        }
      ],
      "flag": "https://flagcdn.com/bw.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Bouvet Island",
      "alpha2Code": "BV",
      "callingCodes": [
        "47"
      ],
      "currencies": [
        {
          "code": "NOK",
          "name": "Norwegian krone",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/bv.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Brazil",
      "alpha2Code": "BR",
      "callingCodes": [
        "55"
      ],
      "currencies": [
        {
          "code": "BRL",
          "name": "Brazilian real",
          "symbol": "R$"
        }
      ],
      "flag": "https://flagcdn.com/br.svg",
      "independent": false,
      "phoneLength": 11
    },
    {
      "name": "British Indian Ocean Territory",
      "alpha2Code": "IO",
      "callingCodes": [
        "246"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/io.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "United States Minor Outlying Islands",
      "alpha2Code": "UM",
      "callingCodes": [
        "246"
      ],
      "currencies": [
        {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/um.svg",
      "independent": false,
      "phoneLength": [7,8,9,10]
    },
    {
      "name": "Virgin Islands (British)",
      "alpha2Code": "VG",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/vg.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Virgin Islands (U.S.)",
      "alpha2Code": "VI",
      "callingCodes": [
        "1 340"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/vi.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Brunei Darussalam",
      "alpha2Code": "BN",
      "callingCodes": [
        "673"
      ],
      "currencies": [
        {
          "code": "BND",
          "name": "Brunei dollar",
          "symbol": "$"
        },
        {
          "code": "SGD",
          "name": "Singapore dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/bn.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Bulgaria",
      "alpha2Code": "BG",
      "callingCodes": [
        "359"
      ],
      "currencies": [
        {
          "code": "BGN",
          "name": "Bulgarian lev",
          "symbol": "лв"
        }
      ],
      "flag": "https://flagcdn.com/bg.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Burkina Faso",
      "alpha2Code": "BF",
      "callingCodes": [
        "226"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/bf.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Burundi",
      "alpha2Code": "BI",
      "callingCodes": [
        "257"
      ],
      "currencies": [
        {
          "code": "BIF",
          "name": "Burundian franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/bi.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Cambodia",
      "alpha2Code": "KH",
      "callingCodes": [
        "855"
      ],
      "currencies": [
        {
          "code": "KHR",
          "name": "Cambodian riel",
          "symbol": "៛"
        },
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/kh.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Cameroon",
      "alpha2Code": "CM",
      "callingCodes": [
        "237"
      ],
      "currencies": [
        {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/cm.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Canada",
      "alpha2Code": "CA",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "CAD",
          "name": "Canadian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ca.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Cabo Verde",
      "alpha2Code": "CV",
      "callingCodes": [
        "238"
      ],
      "currencies": [
        {
          "code": "CVE",
          "name": "Cape Verdean escudo",
          "symbol": "Esc"
        }
      ],
      "flag": "https://flagcdn.com/cv.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Cayman Islands",
      "alpha2Code": "KY",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "KYD",
          "name": "Cayman Islands dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ky.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Central African Republic",
      "alpha2Code": "CF",
      "callingCodes": [
        "236"
      ],
      "currencies": [
        {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/cf.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Chad",
      "alpha2Code": "TD",
      "callingCodes": [
        "235"
      ],
      "currencies": [
        {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/td.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Chile",
      "alpha2Code": "CL",
      "callingCodes": [
        "56"
      ],
      "currencies": [
        {
          "code": "CLP",
          "name": "Chilean peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/cl.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "China",
      "alpha2Code": "CN",
      "callingCodes": [
        "86"
      ],
      "currencies": [
        {
          "code": "CNY",
          "name": "Chinese yuan",
          "symbol": "¥"
        }
      ],
      "flag": "https://flagcdn.com/cn.svg",
      "independent": false,
      "phoneLength": 11
    },
    {
      "name": "Christmas Island",
      "alpha2Code": "CX",
      "callingCodes": [
        "61"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/cx.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Cocos (Keeling) Islands",
      "alpha2Code": "CC",
      "callingCodes": [
        "61"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/cc.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Colombia",
      "alpha2Code": "CO",
      "callingCodes": [
        "57"
      ],
      "currencies": [
        {
          "code": "COP",
          "name": "Colombian peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/co.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Comoros",
      "alpha2Code": "KM",
      "callingCodes": [
        "269"
      ],
      "currencies": [
        {
          "code": "KMF",
          "name": "Comorian franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/km.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Congo",
      "alpha2Code": "CG",
      "callingCodes": [
        "242"
      ],
      "currencies": [
        {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/cg.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Congo (Democratic Republic of the)",
      "alpha2Code": "CD",
      "callingCodes": [
        "243"
      ],
      "currencies": [
        {
          "code": "CDF",
          "name": "Congolese franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/cd.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Cook Islands",
      "alpha2Code": "CK",
      "callingCodes": [
        "682"
      ],
      "currencies": [
        {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
        },
        {
          "code": "CKD",
          "name": "Cook Islands dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ck.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Costa Rica",
      "alpha2Code": "CR",
      "callingCodes": [
        "506"
      ],
      "currencies": [
        {
          "code": "CRC",
          "name": "Costa Rican colón",
          "symbol": "₡"
        }
      ],
      "flag": "https://flagcdn.com/cr.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Croatia",
      "alpha2Code": "HR",
      "callingCodes": [
        "385"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/hr.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Cuba",
      "alpha2Code": "CU",
      "callingCodes": [
        "53"
      ],
      "currencies": [
        {
          "code": "CUC",
          "name": "Cuban convertible peso",
          "symbol": "$"
        },
        {
          "code": "CUP",
          "name": "Cuban peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/cu.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Curaçao",
      "alpha2Code": "CW",
      "callingCodes": [
        "599"
      ],
      "currencies": [
        {
          "code": "ANG",
          "name": "Netherlands Antillean guilder",
          "symbol": "ƒ"
        }
      ],
      "flag": "https://flagcdn.com/cw.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Cyprus",
      "alpha2Code": "CY",
      "callingCodes": [
        "357"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/cy.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Czech Republic",
      "alpha2Code": "CZ",
      "callingCodes": [
        "420"
      ],
      "currencies": [
        {
          "code": "CZK",
          "name": "Czech koruna",
          "symbol": "Kč"
        }
      ],
      "flag": "https://flagcdn.com/cz.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Denmark",
      "alpha2Code": "DK",
      "callingCodes": [
        "45"
      ],
      "currencies": [
        {
          "code": "DKK",
          "name": "Danish krone",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/dk.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Djibouti",
      "alpha2Code": "DJ",
      "callingCodes": [
        "253"
      ],
      "currencies": [
        {
          "code": "DJF",
          "name": "Djiboutian franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/dj.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Dominica",
      "alpha2Code": "DM",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/dm.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Dominican Republic",
      "alpha2Code": "DO",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "DOP",
          "name": "Dominican peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/do.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Ecuador",
      "alpha2Code": "EC",
      "callingCodes": [
        "593"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ec.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Egypt",
      "alpha2Code": "EG",
      "callingCodes": [
        "20"
      ],
      "currencies": [
        {
          "code": "EGP",
          "name": "Egyptian pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/eg.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "El Salvador",
      "alpha2Code": "SV",
      "callingCodes": [
        "503"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/sv.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Equatorial Guinea",
      "alpha2Code": "GQ",
      "callingCodes": [
        "240"
      ],
      "currencies": [
        {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/gq.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Eritrea",
      "alpha2Code": "ER",
      "callingCodes": [
        "291"
      ],
      "currencies": [
        {
          "code": "ERN",
          "name": "Eritrean nakfa",
          "symbol": "Nfk"
        }
      ],
      "flag": "https://flagcdn.com/er.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Estonia",
      "alpha2Code": "EE",
      "callingCodes": [
        "372"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/ee.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Ethiopia",
      "alpha2Code": "ET",
      "callingCodes": [
        "251"
      ],
      "currencies": [
        {
          "code": "ETB",
          "name": "Ethiopian birr",
          "symbol": "Br"
        }
      ],
      "flag": "https://flagcdn.com/et.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "alpha2Code": "FK",
      "callingCodes": [
        "500"
      ],
      "currencies": [
        {
          "code": "FKP",
          "name": "Falkland Islands pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/fk.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Faroe Islands",
      "alpha2Code": "FO",
      "callingCodes": [
        "298"
      ],
      "currencies": [
        {
          "code": "DKK",
          "name": "Danish krone",
          "symbol": "kr"
        },
        {
          "code": "FOK",
          "name": "Faroese króna",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/fo.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Fiji",
      "alpha2Code": "FJ",
      "callingCodes": [
        "679"
      ],
      "currencies": [
        {
          "code": "FJD",
          "name": "Fijian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/fj.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Finland",
      "alpha2Code": "FI",
      "callingCodes": [
        "358"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/fi.svg",
      "independent": false,
      "phoneLength": [9,10,11]
    },
    {
      "name": "France",
      "alpha2Code": "FR",
      "callingCodes": [
        "33"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/fr.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "French Guiana",
      "alpha2Code": "GF",
      "callingCodes": [
        "594"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/gf.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "French Polynesia",
      "alpha2Code": "PF",
      "callingCodes": [
        "689"
      ],
      "currencies": [
        {
          "code": "XPF",
          "name": "CFP franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/pf.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "French Southern Territories",
      "alpha2Code": "TF",
      "callingCodes": [
        "262"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/tf.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Gabon",
      "alpha2Code": "GA",
      "callingCodes": [
        "241"
      ],
      "currencies": [
        {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/ga.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Gambia",
      "alpha2Code": "GM",
      "callingCodes": [
        "220"
      ],
      "currencies": [
        {
          "code": "GMD",
          "name": "Gambian dalasi",
          "symbol": "D"
        }
      ],
      "flag": "https://flagcdn.com/gm.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Georgia",
      "alpha2Code": "GE",
      "callingCodes": [
        "995"
      ],
      "currencies": [
        {
          "code": "GEL",
          "name": "Georgian Lari",
          "symbol": "ლ"
        }
      ],
      "flag": "https://flagcdn.com/ge.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Germany",
      "alpha2Code": "DE",
      "callingCodes": [
        "49"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/de.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Ghana",
      "alpha2Code": "GH",
      "callingCodes": [
        "233"
      ],
      "currencies": [
        {
          "code": "GHS",
          "name": "Ghanaian cedi",
          "symbol": "₵"
        }
      ],
      "flag": "https://flagcdn.com/gh.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Gibraltar",
      "alpha2Code": "GI",
      "callingCodes": [
        "350"
      ],
      "currencies": [
        {
          "code": "GIP",
          "name": "Gibraltar pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/gi.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Greece",
      "alpha2Code": "GR",
      "callingCodes": [
        "30"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/gr.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Greenland",
      "alpha2Code": "GL",
      "callingCodes": [
        "299"
      ],
      "currencies": [
        {
          "code": "DKK",
          "name": "Danish krone",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/gl.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Grenada",
      "alpha2Code": "GD",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/gd.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Guadeloupe",
      "alpha2Code": "GP",
      "callingCodes": [
        "590"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/gp.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Guam",
      "alpha2Code": "GU",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/gu.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Guatemala",
      "alpha2Code": "GT",
      "callingCodes": [
        "502"
      ],
      "currencies": [
        {
          "code": "GTQ",
          "name": "Guatemalan quetzal",
          "symbol": "Q"
        }
      ],
      "flag": "https://flagcdn.com/gt.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Guernsey",
      "alpha2Code": "GG",
      "callingCodes": [
        "44"
      ],
      "currencies": [
        {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
        },
        {
          "code": "GGP",
          "name": "Guernsey pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/gg.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Guinea",
      "alpha2Code": "GN",
      "callingCodes": [
        "224"
      ],
      "currencies": [
        {
          "code": "GNF",
          "name": "Guinean franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/gn.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Guinea-Bissau",
      "alpha2Code": "GW",
      "callingCodes": [
        "245"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/gw.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Guyana",
      "alpha2Code": "GY",
      "callingCodes": [
        "592"
      ],
      "currencies": [
        {
          "code": "GYD",
          "name": "Guyanese dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/gy.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Haiti",
      "alpha2Code": "HT",
      "callingCodes": [
        "509"
      ],
      "currencies": [
        {
          "code": "HTG",
          "name": "Haitian gourde",
          "symbol": "G"
        }
      ],
      "flag": "https://flagcdn.com/ht.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Heard Island and McDonald Islands",
      "alpha2Code": "HM",
      "callingCodes": [
        "672"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/hm.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Vatican City",
      "alpha2Code": "VA",
      "callingCodes": [
        "379"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/va.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Honduras",
      "alpha2Code": "HN",
      "callingCodes": [
        "504"
      ],
      "currencies": [
        {
          "code": "HNL",
          "name": "Honduran lempira",
          "symbol": "L"
        }
      ],
      "flag": "https://flagcdn.com/hn.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Hungary",
      "alpha2Code": "HU",
      "callingCodes": [
        "36"
      ],
      "currencies": [
        {
          "code": "HUF",
          "name": "Hungarian forint",
          "symbol": "Ft"
        }
      ],
      "flag": "https://flagcdn.com/hu.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Hong Kong",
      "alpha2Code": "HK",
      "callingCodes": [
        "852"
      ],
      "currencies": [
        {
          "code": "HKD",
          "name": "Hong Kong dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/hk.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Iceland",
      "alpha2Code": "IS",
      "callingCodes": [
        "354"
      ],
      "currencies": [
        {
          "code": "ISK",
          "name": "Icelandic króna",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/is.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "India",
      "alpha2Code": "IN",
      "callingCodes": [
        "91"
      ],
      "currencies": [
        {
          "code": "INR",
          "name": "Indian rupee",
          "symbol": "₹"
        }
      ],
      "flag": "https://flagcdn.com/in.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Indonesia",
      "alpha2Code": "ID",
      "callingCodes": [
        "62"
      ],
      "currencies": [
        {
          "code": "IDR",
          "name": "Indonesian rupiah",
          "symbol": "Rp"
        }
      ],
      "flag": "https://flagcdn.com/id.svg",
      "independent": false,
      "phoneLength": 11
    },
    {
      "name": "Ivory Coast",
      "alpha2Code": "CI",
      "callingCodes": [
        "225"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/ci.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Iran (Islamic Republic of)",
      "alpha2Code": "IR",
      "callingCodes": [
        "98"
      ],
      "currencies": [
        {
          "code": "IRR",
          "name": "Iranian rial",
          "symbol": "﷼"
        }
      ],
      "flag": "https://flagcdn.com/ir.svg",
      "independent": false,
      "phoneLength": 11
    },
    {
      "name": "Iraq",
      "alpha2Code": "IQ",
      "callingCodes": [
        "964"
      ],
      "currencies": [
        {
          "code": "IQD",
          "name": "Iraqi dinar",
          "symbol": "ع.د"
        }
      ],
      "flag": "https://flagcdn.com/iq.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Ireland",
      "alpha2Code": "IE",
      "callingCodes": [
        "353"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/ie.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Isle of Man",
      "alpha2Code": "IM",
      "callingCodes": [
        "44"
      ],
      "currencies": [
        {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
        },
        {
          "code": "IMP[G]",
          "name": "Manx pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/im.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Israel",
      "alpha2Code": "IL",
      "callingCodes": [
        "972"
      ],
      "currencies": [
        {
          "code": "ILS",
          "name": "Israeli new shekel",
          "symbol": "₪"
        }
      ],
      "flag": "https://flagcdn.com/il.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Italy",
      "alpha2Code": "IT",
      "callingCodes": [
        "39"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/it.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Jamaica",
      "alpha2Code": "JM",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "JMD",
          "name": "Jamaican dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/jm.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Japan",
      "alpha2Code": "JP",
      "callingCodes": [
        "81"
      ],
      "currencies": [
        {
          "code": "JPY",
          "name": "Japanese yen",
          "symbol": "¥"
        }
      ],
      "flag": "https://flagcdn.com/jp.svg",
      "independent": false,
      "phoneLength": 10 
    },
    {
      "name": "Jersey",
      "alpha2Code": "JE",
      "callingCodes": [
        "44"
      ],
      "currencies": [
        {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
        },
        {
          "code": "JEP[G]",
          "name": "Jersey pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/je.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Jordan",
      "alpha2Code": "JO",
      "callingCodes": [
        "962"
      ],
      "currencies": [
        {
          "code": "JOD",
          "name": "Jordanian dinar",
          "symbol": "د.ا"
        }
      ],
      "flag": "https://flagcdn.com/jo.svg",
      "independent": false,
      "phoneLength": [
        8,
        9
      ]
    },
    {
      "name": "Kazakhstan",
      "alpha2Code": "KZ",
      "callingCodes": [
        "76",
        "77"
      ],
      "currencies": [
        {
          "code": "KZT",
          "name": "Kazakhstani tenge",
          "symbol": "₸"
        }
      ],
      "flag": "https://flagcdn.com/kz.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Kenya",
      "alpha2Code": "KE",
      "callingCodes": [
        "254"
      ],
      "currencies": [
        {
          "code": "KES",
          "name": "Kenyan shilling",
          "symbol": "Sh"
        }
      ],
      "flag": "https://flagcdn.com/ke.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Kiribati",
      "alpha2Code": "KI",
      "callingCodes": [
        "686"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        },
        {
          "code": "KID",
          "name": "Kiribati dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ki.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Kuwait",
      "alpha2Code": "KW",
      "callingCodes": [
        "965"
      ],
      "currencies": [
        {
          "code": "KWD",
          "name": "Kuwaiti dinar",
          "symbol": "د.ك"
        }
      ],
      "flag": "https://flagcdn.com/kw.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Kyrgyzstan",
      "alpha2Code": "KG",
      "callingCodes": [
        "996"
      ],
      "currencies": [
        {
          "code": "KGS",
          "name": "Kyrgyzstani som",
          "symbol": "с"
        }
      ],
      "flag": "https://flagcdn.com/kg.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Lao People's Democratic Republic",
      "alpha2Code": "LA",
      "callingCodes": [
        "856"
      ],
      "currencies": [
        {
          "code": "LAK",
          "name": "Lao kip",
          "symbol": "₭"
        }
      ],
      "flag": "https://flagcdn.com/la.svg",
      "independent": false,
      "phoneLength": [
        8,
        9
      ]
    },
    {
      "name": "Latvia",
      "alpha2Code": "LV",
      "callingCodes": [
        "371"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/lv.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Lebanon",
      "alpha2Code": "LB",
      "callingCodes": [
        "961"
      ],
      "currencies": [
        {
          "code": "LBP",
          "name": "Lebanese pound",
          "symbol": "ل.ل"
        }
      ],
      "flag": "https://flagcdn.com/lb.svg",
      "independent": false,
      "phoneLength": [
        7,
        8
      ]
    },
    {
      "name": "Lesotho",
      "alpha2Code": "LS",
      "callingCodes": [
        "266"
      ],
      "currencies": [
        {
          "code": "LSL",
          "name": "Lesotho loti",
          "symbol": "L"
        },
        {
          "code": "ZAR",
          "name": "South African rand",
          "symbol": "R"
        }
      ],
      "flag": "https://flagcdn.com/ls.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Liberia",
      "alpha2Code": "LR",
      "callingCodes": [
        "231"
      ],
      "currencies": [
        {
          "code": "LRD",
          "name": "Liberian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/lr.svg",
      "independent": false,
      "phoneLength": [
        8,
        9
      ]
    },
    {
      "name": "Libya",
      "alpha2Code": "LY",
      "callingCodes": [
        "218"
      ],
      "currencies": [
        {
          "code": "LYD",
          "name": "Libyan dinar",
          "symbol": "ل.د"
        }
      ],
      "flag": "https://flagcdn.com/ly.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Liechtenstein",
      "alpha2Code": "LI",
      "callingCodes": [
        "423"
      ],
      "currencies": [
        {
          "code": "CHF",
          "name": "Swiss franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/li.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Lithuania",
      "alpha2Code": "LT",
      "callingCodes": [
        "370"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/lt.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Luxembourg",
      "alpha2Code": "LU",
      "callingCodes": [
        "352"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/lu.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Macao",
      "alpha2Code": "MO",
      "callingCodes": [
        "853"
      ],
      "currencies": [
        {
          "code": "MOP",
          "name": "Macanese pataca",
          "symbol": "P"
        }
      ],
      "flag": "https://flagcdn.com/mo.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "North Macedonia",
      "alpha2Code": "MK",
      "callingCodes": [
        "389"
      ],
      "currencies": [
        {
          "code": "MKD",
          "name": "Macedonian denar",
          "symbol": "ден"
        }
      ],
      "flag": "https://flagcdn.com/mk.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Madagascar",
      "alpha2Code": "MG",
      "callingCodes": [
        "261"
      ],
      "currencies": [
        {
          "code": "MGA",
          "name": "Malagasy ariary",
          "symbol": "Ar"
        }
      ],
      "flag": "https://flagcdn.com/mg.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Malawi",
      "alpha2Code": "MW",
      "callingCodes": [
        "265"
      ],
      "currencies": [
        {
          "code": "MWK",
          "name": "Malawian kwacha",
          "symbol": "MK"
        }
      ],
      "flag": "https://flagcdn.com/mw.svg",
      "independent": false,
      "phoneLength": [
        7,
        8,
        9
      ]
    },
    {
      "name": "Malaysia",
      "alpha2Code": "MY",
      "callingCodes": [
        "60"
      ],
      "currencies": [
        {
          "code": "MYR",
          "name": "Malaysian ringgit",
          "symbol": "RM"
        }
      ],
      "flag": "https://flagcdn.com/my.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Maldives",
      "alpha2Code": "MV",
      "callingCodes": [
        "960"
      ],
      "currencies": [
        {
          "code": "MVR",
          "name": "Maldivian rufiyaa",
          "symbol": ".ރ"
        }
      ],
      "flag": "https://flagcdn.com/mv.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Mali",
      "alpha2Code": "ML",
      "callingCodes": [
        "223"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/ml.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Malta",
      "alpha2Code": "MT",
      "callingCodes": [
        "356"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/mt.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Marshall Islands",
      "alpha2Code": "MH",
      "callingCodes": [
        "692"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/mh.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Martinique",
      "alpha2Code": "MQ",
      "callingCodes": [
        "596"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/mq.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Mauritania",
      "alpha2Code": "MR",
      "callingCodes": [
        "222"
      ],
      "currencies": [
        {
          "code": "MRO",
          "name": "Mauritanian ouguiya",
          "symbol": "UM"
        }
      ],
      "flag": "https://flagcdn.com/mr.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Mauritius",
      "alpha2Code": "MU",
      "callingCodes": [
        "230"
      ],
      "currencies": [
        {
          "code": "MUR",
          "name": "Mauritian rupee",
          "symbol": "₨"
        }
      ],
      "flag": "https://flagcdn.com/mu.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Mayotte",
      "alpha2Code": "YT",
      "callingCodes": [
        "262"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/yt.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Mexico",
      "alpha2Code": "MX",
      "callingCodes": [
        "52"
      ],
      "currencies": [
        {
          "code": "MXN",
          "name": "Mexican peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/mx.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Micronesia (Federated States of)",
      "alpha2Code": "FM",
      "callingCodes": [
        "691"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/fm.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Moldova (Republic of)",
      "alpha2Code": "MD",
      "callingCodes": [
        "373"
      ],
      "currencies": [
        {
          "code": "MDL",
          "name": "Moldovan leu",
          "symbol": "L"
        }
      ],
      "flag": "https://flagcdn.com/md.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Monaco",
      "alpha2Code": "MC",
      "callingCodes": [
        "377"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/mc.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Mongolia",
      "alpha2Code": "MN",
      "callingCodes": [
        "976"
      ],
      "currencies": [
        {
          "code": "MNT",
          "name": "Mongolian tögrög",
          "symbol": "₮"
        }
      ],
      "flag": "https://flagcdn.com/mn.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Montenegro",
      "alpha2Code": "ME",
      "callingCodes": [
        "382"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/me.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Montserrat",
      "alpha2Code": "MS",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/ms.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Morocco",
      "alpha2Code": "MA",
      "callingCodes": [
        "212"
      ],
      "currencies": [
        {
          "code": "MAD",
          "name": "Moroccan dirham",
          "symbol": "د.م."
        }
      ],
      "flag": "https://flagcdn.com/ma.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Mozambique",
      "alpha2Code": "MZ",
      "callingCodes": [
        "258"
      ],
      "currencies": [
        {
          "code": "MZN",
          "name": "Mozambican metical",
          "symbol": "MT"
        }
      ],
      "flag": "https://flagcdn.com/mz.svg",
      "independent": false,
      "phoneLength": 12
    },
    {
      "name": "Myanmar",
      "alpha2Code": "MM",
      "callingCodes": [
        "95"
      ],
      "currencies": [
        {
          "code": "MMK",
          "name": "Burmese kyat",
          "symbol": "Ks"
        }
      ],
      "flag": "https://flagcdn.com/mm.svg",
      "independent": false,
      "phoneLength":[7,8,9,10]
    },
    {
      "name": "Namibia",
      "alpha2Code": "NA",
      "callingCodes": [
        "264"
      ],
      "currencies": [
        {
          "code": "NAD",
          "name": "Namibian dollar",
          "symbol": "$"
        },
        {
          "code": "ZAR",
          "name": "South African rand",
          "symbol": "R"
        }
      ],
      "flag": "https://flagcdn.com/na.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Nauru",
      "alpha2Code": "NR",
      "callingCodes": [
        "674"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/nr.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Nepal",
      "alpha2Code": "NP",
      "callingCodes": [
        "977"
      ],
      "currencies": [
        {
          "code": "NPR",
          "name": "Nepalese rupee",
          "symbol": "₨"
        }
      ],
      "flag": "https://flagcdn.com/np.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Netherlands",
      "alpha2Code": "NL",
      "callingCodes": [
        "31"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/nl.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "New Caledonia",
      "alpha2Code": "NC",
      "callingCodes": [
        "687"
      ],
      "currencies": [
        {
          "code": "XPF",
          "name": "CFP franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/nc.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "New Zealand",
      "alpha2Code": "NZ",
      "callingCodes": [
        "64"
      ],
      "currencies": [
        {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/nz.svg",
      "independent": false,
      "phoneLength": [
        8,
        9
      ]
    },
    {
      "name": "Nicaragua",
      "alpha2Code": "NI",
      "callingCodes": [
        "505"
      ],
      "currencies": [
        {
          "code": "NIO",
          "name": "Nicaraguan córdoba",
          "symbol": "C$"
        }
      ],
      "flag": "https://flagcdn.com/ni.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Niger",
      "alpha2Code": "NE",
      "callingCodes": [
        "227"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/ne.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Nigeria",
      "alpha2Code": "NG",
      "callingCodes": [
        "234"
      ],
      "currencies": [
        {
          "code": "NGN",
          "name": "Nigerian naira",
          "symbol": "₦"
        }
      ],
      "flag": "https://flagcdn.com/ng.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Niue",
      "alpha2Code": "NU",
      "callingCodes": [
        "683"
      ],
      "currencies": [
        {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
        },
        {
          "code": "NZD",
          "name": "Niue dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/nu.svg",
      "independent": false,
      "phoneLength": 4
    },
    {
      "name": "Norfolk Island",
      "alpha2Code": "NF",
      "callingCodes": [
        "672"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/nf.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Korea (Democratic People's Republic of)",
      "alpha2Code": "KP",
      "callingCodes": [
        "850"
      ],
      "currencies": [
        {
          "code": "KPW",
          "name": "North Korean won",
          "symbol": "₩"
        }
      ],
      "flag": "https://flagcdn.com/kp.svg",
      "independent": false,
      "phoneLength": [
        4,
        6,
        7,
        13
      ]
    },
    {
      "name": "Northern Mariana Islands",
      "alpha2Code": "MP",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/mp.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Norway",
      "alpha2Code": "NO",
      "callingCodes": [
        "47"
      ],
      "currencies": [
        {
          "code": "NOK",
          "name": "Norwegian krone",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/no.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Oman",
      "alpha2Code": "OM",
      "callingCodes": [
        "968"
      ],
      "currencies": [
        {
          "code": "OMR",
          "name": "Omani rial",
          "symbol": "ر.ع."
        }
      ],
      "flag": "https://flagcdn.com/om.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Pakistan",
      "alpha2Code": "PK",
      "callingCodes": [
        "92"
      ],
      "currencies": [
        {
          "code": "PKR",
          "name": "Pakistani rupee",
          "symbol": "₨"
        }
      ],
      "flag": "https://flagcdn.com/pk.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Palau",
      "alpha2Code": "PW",
      "callingCodes": [
        "680"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/pw.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Palestine, State of",
      "alpha2Code": "PS",
      "callingCodes": [
        "970"
      ],
      "currencies": [
        {
          "code": "EGP",
          "name": "Egyptian pound",
          "symbol": "E£"
        },
        {
          "code": "ILS",
          "name": "Israeli new shekel",
          "symbol": "₪"
        },
        {
          "code": "JOD",
          "name": "Jordanian dinar",
          "symbol": "د.أ"
        }
      ],
      "flag": "https://flagcdn.com/ps.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Panama",
      "alpha2Code": "PA",
      "callingCodes": [
        "507"
      ],
      "currencies": [
        {
          "code": "PAB",
          "name": "Panamanian balboa",
          "symbol": "B/."
        },
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/pa.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Papua New Guinea",
      "alpha2Code": "PG",
      "callingCodes": [
        "675"
      ],
      "currencies": [
        {
          "code": "PGK",
          "name": "Papua New Guinean kina",
          "symbol": "K"
        }
      ],
      "flag": "https://flagcdn.com/pg.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Paraguay",
      "alpha2Code": "PY",
      "callingCodes": [
        "595"
      ],
      "currencies": [
        {
          "code": "PYG",
          "name": "Paraguayan guaraní",
          "symbol": "₲"
        }
      ],
      "flag": "https://flagcdn.com/py.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Peru",
      "alpha2Code": "PE",
      "callingCodes": [
        "51"
      ],
      "currencies": [
        {
          "code": "PEN",
          "name": "Peruvian sol",
          "symbol": "S/."
        }
      ],
      "flag": "https://flagcdn.com/pe.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Philippines",
      "alpha2Code": "PH",
      "callingCodes": [
        "63"
      ],
      "currencies": [
        {
          "code": "PHP",
          "name": "Philippine peso",
          "symbol": "₱"
        }
      ],
      "flag": "https://flagcdn.com/ph.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Pitcairn",
      "alpha2Code": "PN",
      "callingCodes": [
        "64"
      ],
      "currencies": [
        {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
        },
        {
          "code": "PND",
          "name": "Pitcairn Islands dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/pn.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Poland",
      "alpha2Code": "PL",
      "callingCodes": [
        "48"
      ],
      "currencies": [
        {
          "code": "PLN",
          "name": "Polish złoty",
          "symbol": "zł"
        }
      ],
      "flag": "https://flagcdn.com/pl.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Portugal",
      "alpha2Code": "PT",
      "callingCodes": [
        "351"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/pt.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Puerto Rico",
      "alpha2Code": "PR",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/pr.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Qatar",
      "alpha2Code": "QA",
      "callingCodes": [
        "974"
      ],
      "currencies": [
        {
          "code": "QAR",
          "name": "Qatari riyal",
          "symbol": "ر.ق"
        }
      ],
      "flag": "https://flagcdn.com/qa.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Republic of Kosovo",
      "alpha2Code": "XK",
      "callingCodes": [
        "383"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/xk.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Réunion",
      "alpha2Code": "RE",
      "callingCodes": [
        "262"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/re.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Romania",
      "alpha2Code": "RO",
      "callingCodes": [
        "40"
      ],
      "currencies": [
        {
          "code": "RON",
          "name": "Romanian leu",
          "symbol": "lei"
        }
      ],
      "flag": "https://flagcdn.com/ro.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Russian Federation",
      "alpha2Code": "RU",
      "callingCodes": [
        "7"
      ],
      "currencies": [
        {
          "code": "RUB",
          "name": "Russian ruble",
          "symbol": "₽"
        }
      ],
      "flag": "https://flagcdn.com/ru.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Rwanda",
      "alpha2Code": "RW",
      "callingCodes": [
        "250"
      ],
      "currencies": [
        {
          "code": "RWF",
          "name": "Rwandan franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/rw.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Saint Barthélemy",
      "alpha2Code": "BL",
      "callingCodes": [
        "590"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/bl.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Saint Helena, Ascension and Tristan da Cunha",
      "alpha2Code": "SH",
      "callingCodes": [
        "290"
      ],
      "currencies": [
        {
          "code": "SHP",
          "name": "Saint Helena pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/sh.svg",
      "independent": false,
      "phoneLength": 4
    },
    {
      "name": "Saint Kitts and Nevis",
      "alpha2Code": "KN",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/kn.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Saint Lucia",
      "alpha2Code": "LC",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/lc.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Saint Martin (French part)",
      "alpha2Code": "MF",
      "callingCodes": [
        "590"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/mf.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Saint Pierre and Miquelon",
      "alpha2Code": "PM",
      "callingCodes": [
        "508"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/pm.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "alpha2Code": "VC",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/vc.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Samoa",
      "alpha2Code": "WS",
      "callingCodes": [
        "685"
      ],
      "currencies": [
        {
          "code": "WST",
          "name": "Samoan tālā",
          "symbol": "T"
        }
      ],
      "flag": "https://flagcdn.com/ws.svg",
      "independent": false,
      "phoneLength": [
        5,
        6,
        7
      ]
    },
    {
      "name": "San Marino",
      "alpha2Code": "SM",
      "callingCodes": [
        "378"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/sm.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Sao Tome and Principe",
      "alpha2Code": "ST",
      "callingCodes": [
        "239"
      ],
      "currencies": [
        {
          "code": "STD",
          "name": "São Tomé and Príncipe dobra",
          "symbol": "Db"
        }
      ],
      "flag": "https://flagcdn.com/st.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Saudi Arabia",
      "alpha2Code": "SA",
      "callingCodes": [
        "966"
      ],
      "currencies": [
        {
          "code": "SAR",
          "name": "Saudi riyal",
          "symbol": "ر.س"
        }
      ],
      "flag": "https://flagcdn.com/sa.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Senegal",
      "alpha2Code": "SN",
      "callingCodes": [
        "221"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/sn.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Serbia",
      "alpha2Code": "RS",
      "callingCodes": [
        "381"
      ],
      "currencies": [
        {
          "code": "RSD",
          "name": "Serbian dinar",
          "symbol": "дин."
        }
      ],
      "flag": "https://flagcdn.com/rs.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Seychelles",
      "alpha2Code": "SC",
      "callingCodes": [
        "248"
      ],
      "currencies": [
        {
          "code": "SCR",
          "name": "Seychellois rupee",
          "symbol": "₨"
        }
      ],
      "flag": "https://flagcdn.com/sc.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Sierra Leone",
      "alpha2Code": "SL",
      "callingCodes": [
        "232"
      ],
      "currencies": [
        {
          "code": "SLL",
          "name": "Sierra Leonean leone",
          "symbol": "Le"
        }
      ],
      "flag": "https://flagcdn.com/sl.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Singapore",
      "alpha2Code": "SG",
      "callingCodes": [
        "65"
      ],
      "currencies": [
        {
          "code": "SGD",
          "name": "Singapore dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/sg.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Sint Maarten (Dutch part)",
      "alpha2Code": "SX",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "ANG",
          "name": "Netherlands Antillean guilder",
          "symbol": "ƒ"
        }
      ],
      "flag": "https://flagcdn.com/sx.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Slovakia",
      "alpha2Code": "SK",
      "callingCodes": [
        "421"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/sk.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Slovenia",
      "alpha2Code": "SI",
      "callingCodes": [
        "386"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/si.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Solomon Islands",
      "alpha2Code": "SB",
      "callingCodes": [
        "677"
      ],
      "currencies": [
        {
          "code": "SBD",
          "name": "Solomon Islands dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/sb.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Somalia",
      "alpha2Code": "SO",
      "callingCodes": [
        "252"
      ],
      "currencies": [
        {
          "code": "SOS",
          "name": "Somali shilling",
          "symbol": "Sh"
        }
      ],
      "flag": "https://flagcdn.com/so.svg",
      "independent": false,
      "phoneLength": [
        8,
        9
      ]
    },
    {
      "name": "South Africa",
      "alpha2Code": "ZA",
      "callingCodes": [
        "27"
      ],
      "currencies": [
        {
          "code": "ZAR",
          "name": "South African rand",
          "symbol": "R"
        }
      ],
      "flag": "https://flagcdn.com/za.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "alpha2Code": "GS",
      "callingCodes": [
        "500"
      ],
      "currencies": [
        {
          "code": "FKP",
          "name": "Falkland Islands Pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/gs.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Korea (Republic of)",
      "alpha2Code": "KR",
      "callingCodes": [
        "82"
      ],
      "currencies": [
        {
          "code": "KRW",
          "name": "South Korean won",
          "symbol": "₩"
        }
      ],
      "flag": "https://flagcdn.com/kr.svg",
      "independent": false,
      "phoneLength": [
        7,
        8
      ]
    },
    {
      "name": "Spain",
      "alpha2Code": "ES",
      "callingCodes": [
        "34"
      ],
      "currencies": [
        {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
        }
      ],
      "flag": "https://flagcdn.com/es.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Sri Lanka",
      "alpha2Code": "LK",
      "callingCodes": [
        "94"
      ],
      "currencies": [
        {
          "code": "LKR",
          "name": "Sri Lankan rupee",
          "symbol": "Rs"
        }
      ],
      "flag": "https://flagcdn.com/lk.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Sudan",
      "alpha2Code": "SD",
      "callingCodes": [
        "249"
      ],
      "currencies": [
        {
          "code": "SDG",
          "name": "Sudanese pound",
          "symbol": "ج.س."
        }
      ],
      "flag": "https://flagcdn.com/sd.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "South Sudan",
      "alpha2Code": "SS",
      "callingCodes": [
        "211"
      ],
      "currencies": [
        {
          "code": "SSP",
          "name": "South Sudanese pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/ss.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Suriname",
      "alpha2Code": "SR",
      "callingCodes": [
        "597"
      ],
      "currencies": [
        {
          "code": "SRD",
          "name": "Surinamese dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/sr.svg",
      "independent": false,
      "phoneLength": [
        6,
        7
      ]
    },
    {
      "name": "Svalbard and Jan Mayen",
      "alpha2Code": "SJ",
      "callingCodes": [
        "47"
      ],
      "currencies": [
        {
          "code": "NOK",
          "name": "Norwegian krone",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/sj.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Swaziland",
      "alpha2Code": "SZ",
      "callingCodes": [
        "268"
      ],
      "currencies": [
        {
          "code": "SZL",
          "name": "Swazi lilangeni",
          "symbol": "L"
        }
      ],
      "flag": "https://flagcdn.com/sz.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Sweden",
      "alpha2Code": "SE",
      "callingCodes": [
        "46"
      ],
      "currencies": [
        {
          "code": "SEK",
          "name": "Swedish krona",
          "symbol": "kr"
        }
      ],
      "flag": "https://flagcdn.com/se.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Switzerland",
      "alpha2Code": "CH",
      "callingCodes": [
        "41"
      ],
      "currencies": [
        {
          "code": "CHF",
          "name": "Swiss franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/ch.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Syrian Arab Republic",
      "alpha2Code": "SY",
      "callingCodes": [
        "963"
      ],
      "currencies": [
        {
          "code": "SYP",
          "name": "Syrian pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/sy.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Taiwan",
      "alpha2Code": "TW",
      "callingCodes": [
        "886"
      ],
      "currencies": [
        {
          "code": "TWD",
          "name": "New Taiwan dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/tw.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Tajikistan",
      "alpha2Code": "TJ",
      "callingCodes": [
        "992"
      ],
      "currencies": [
        {
          "code": "TJS",
          "name": "Tajikistani somoni",
          "symbol": "ЅМ"
        }
      ],
      "flag": "https://flagcdn.com/tj.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Tanzania, United Republic of",
      "alpha2Code": "TZ",
      "callingCodes": [
        "255"
      ],
      "currencies": [
        {
          "code": "TZS",
          "name": "Tanzanian shilling",
          "symbol": "Sh"
        }
      ],
      "flag": "https://flagcdn.com/tz.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Thailand",
      "alpha2Code": "TH",
      "callingCodes": [
        "66"
      ],
      "currencies": [
        {
          "code": "THB",
          "name": "Thai baht",
          "symbol": "฿"
        }
      ],
      "flag": "https://flagcdn.com/th.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Timor-Leste",
      "alpha2Code": "TL",
      "callingCodes": [
        "670"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States Dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/tl.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Togo",
      "alpha2Code": "TG",
      "callingCodes": [
        "228"
      ],
      "currencies": [
        {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/tg.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Tokelau",
      "alpha2Code": "TK",
      "callingCodes": [
        "690"
      ],
      "currencies": [
        {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/tk.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Tonga",
      "alpha2Code": "TO",
      "callingCodes": [
        "676"
      ],
      "currencies": [
        {
          "code": "TOP",
          "name": "Tongan paʻanga",
          "symbol": "T$"
        }
      ],
      "flag": "https://flagcdn.com/to.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Trinidad and Tobago",
      "alpha2Code": "TT",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "TTD",
          "name": "Trinidad and Tobago dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/tt.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Tunisia",
      "alpha2Code": "TN",
      "callingCodes": [
        "216"
      ],
      "currencies": [
        {
          "code": "TND",
          "name": "Tunisian dinar",
          "symbol": "د.ت"
        }
      ],
      "flag": "https://flagcdn.com/tn.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Turkey",
      "alpha2Code": "TR",
      "callingCodes": [
        "90"
      ],
      "currencies": [
        {
          "code": "TRY",
          "name": "Turkish lira",
          "symbol": "₺"
        }
      ],
      "flag": "https://flagcdn.com/tr.svg",
      "independent": false,
      "phoneLength": 11
    },
    {
      "name": "Turkmenistan",
      "alpha2Code": "TM",
      "callingCodes": [
        "993"
      ],
      "currencies": [
        {
          "code": "TMT",
          "name": "Turkmenistan manat",
          "symbol": "m"
        }
      ],
      "flag": "https://flagcdn.com/tm.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Turks and Caicos Islands",
      "alpha2Code": "TC",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/tc.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Tuvalu",
      "alpha2Code": "TV",
      "callingCodes": [
        "688"
      ],
      "currencies": [
        {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
        },
        {
          "code": "TVD[G]",
          "name": "Tuvaluan dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/tv.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Uganda",
      "alpha2Code": "UG",
      "callingCodes": [
        "256"
      ],
      "currencies": [
        {
          "code": "UGX",
          "name": "Ugandan shilling",
          "symbol": "Sh"
        }
      ],
      "flag": "https://flagcdn.com/ug.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Ukraine",
      "alpha2Code": "UA",
      "callingCodes": [
        "380"
      ],
      "currencies": [
        {
          "code": "UAH",
          "name": "Ukrainian hryvnia",
          "symbol": "₴"
        }
      ],
      "flag": "https://flagcdn.com/ua.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "United Arab Emirates",
      "alpha2Code": "AE",
      "callingCodes": [
        "971"
      ],
      "currencies": [
        {
          "code": "AED",
          "name": "United Arab Emirates dirham",
          "symbol": "د.إ"
        }
      ],
      "flag": "https://flagcdn.com/ae.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "United Kingdom of Great Britain and Northern Ireland",
      "alpha2Code": "GB",
      "callingCodes": [
        "44"
      ],
      "currencies": [
        {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
        }
      ],
      "flag": "https://flagcdn.com/gb.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "United States of America",
      "alpha2Code": "US",
      "callingCodes": [
        "1"
      ],
      "currencies": [
        {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/us.svg",
      "independent": false,
      "phoneLength": 10
    },
    {
      "name": "Uruguay",
      "alpha2Code": "UY",
      "callingCodes": [
        "598"
      ],
      "currencies": [
        {
          "code": "UYU",
          "name": "Uruguayan peso",
          "symbol": "$"
        }
      ],
      "flag": "https://flagcdn.com/uy.svg",
      "independent": false,
      "phoneLength": 8
    },
    {
      "name": "Uzbekistan",
      "alpha2Code": "UZ",
      "callingCodes": [
        "998"
      ],
      "currencies": [
        {
          "code": "UZS",
          "name": "Uzbekistani so'm",
          "symbol": "so'm"
        }
      ],
      "flag": "https://flagcdn.com/uz.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Vanuatu",
      "alpha2Code": "VU",
      "callingCodes": [
        "678"
      ],
      "currencies": [
        {
          "code": "VUV",
          "name": "Vanuatu vatu",
          "symbol": "Vt"
        }
      ],
      "flag": "https://flagcdn.com/vu.svg",
      "independent": false,
      "phoneLength": 5
    },
    {
      "name": "Venezuela (Bolivarian Republic of)",
      "alpha2Code": "VE",
      "callingCodes": [
        "58"
      ],
      "currencies": [
        {
          "code": "VEF",
          "name": "Venezuelan bolívar",
          "symbol": "Bs S"
        }
      ],
      "flag": "https://flagcdn.com/ve.svg",
      "independent": false,
      "phoneLength": 7
    },
    {
      "name": "Vietnam",
      "alpha2Code": "VN",
      "callingCodes": [
        "84"
      ],
      "currencies": [
        {
          "code": "VND",
          "name": "Vietnamese đồng",
          "symbol": "₫"
        }
      ],
      "flag": "https://flagcdn.com/vn.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Wallis and Futuna",
      "alpha2Code": "WF",
      "callingCodes": [
        "681"
      ],
      "currencies": [
        {
          "code": "XPF",
          "name": "CFP franc",
          "symbol": "Fr"
        }
      ],
      "flag": "https://flagcdn.com/wf.svg",
      "independent": false,
      "phoneLength": 6
    },
    {
      "name": "Western Sahara",
      "alpha2Code": "EH",
      "callingCodes": [
        "212"
      ],
      "currencies": [
        {
          "code": "MAD",
          "name": "Moroccan dirham",
          "symbol": "د.م."
        },
        {
          "code": "DZD",
          "name": "Algerian dinar",
          "symbol": "د.ج"
        }
      ],
      "flag": "https://flagcdn.com/eh.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Yemen",
      "alpha2Code": "YE",
      "callingCodes": [
        "967"
      ],
      "currencies": [
        {
          "code": "YER",
          "name": "Yemeni rial",
          "symbol": "﷼"
        }
      ],
      "flag": "https://flagcdn.com/ye.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Zambia",
      "alpha2Code": "ZM",
      "callingCodes": [
        "260"
      ],
      "currencies": [
        {
          "code": "ZMW",
          "name": "Zambian kwacha",
          "symbol": "ZK"
        }
      ],
      "flag": "https://flagcdn.com/zm.svg",
      "independent": false,
      "phoneLength": 9
    },
    {
      "name": "Zimbabwe",
      "alpha2Code": "ZW",
      "callingCodes": [
        "263"
      ],
      "currencies": [
        {
          "code": "ZMW",
          "name": "Zambian kwacha",
          "symbol": "K"
        }
      ],
      "flag": "https://flagcdn.com/zw.svg",
      "independent": false,
      "phoneLength": 9
    }
]