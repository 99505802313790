import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-send-sms-consent-dialog-box',
  templateUrl: './send-sms-consent-dialog-box.component.html',
  styleUrls: ['./send-sms-consent-dialog-box.component.css']
})
export class SendSmsConsentDialogBoxComponent implements OnInit {

  constructor(private apiCall: ApiCallService,
              private dialogRef: MatDialogRef<SendSmsConsentDialogBoxComponent>,
              @Inject(MAT_DIALOG_DATA)public data: any) { }

  ngOnInit() {
    console.log("DATAS",this.data)
  }
  reminderStatus;
  sendConsent(){
    if(this.data['reminderStatus']=='1'){
      this.reminderStatus=true
    }
    else{
      this.reminderStatus=false
    }
    let segment = [];
    segment = this.data['segment'][0] === 'ALL_CUSTOMERS' ? null : this.data['segment'];
    this.apiCall.sendConsentSMS(segment,this.reminderStatus,this.data['templeteName'],this.data['templeteId']).subscribe((response)=>{
      this.dialogRef.close(true);
    });
  }
  submit(decision){
    this.dialogRef.close(decision)
  }
}
