<div class="qb-dialog-container">
  <div style="display: flex; justify-content: space-between; width: 100%">
    <div class="qb-dialog-header" *ngIf="data['channel'] === 'facebook'">
      {{'SHARED_PAGE.ADD_FACEBOOK_PAGE' | i18nTranslate}}
    </div>
    <div class="qb-dialog-header" *ngIf="data['channel'] === 'instagram'">
      {{'SHARED_PAGE.ADD_INSTAGRAM_ACCOUNT' | i18nTranslate}}
    </div>
    <div>
      <mat-icon class="qb-close-button" (click)="close(0)">cancel</mat-icon>
    </div>
  </div>

  <div class="qb-social-channel-list"       *ngIf="
  socialChannelList != null &&
  socialChannelList.length > 1 &&
  data['channel'] === 'instagram'
">
    <div
      class="qb-dialog-subheader"
    >
      {{'SHARED_PAGE.SELECT_ACCOUNT_TO_ADD' | i18nTranslate}}
    </div>
    <div
      class="cursor-pointer"
      (click)="selectAllClicked()"
      *ngIf="socialChannelList != null && socialChannelList.length > 1"
    >
      {{'SHARED_PAGE.SELECT_ALL' | i18nTranslate}}
    </div>
  </div>
  <!-- <div class="qb-dialog-text">If you have several accounts to connect, don’t worry—we’ll bring you back to this screen after each profile is set up.</div> -->

  <div class="qb-social-channel-list qb-w-h-100" *ngIf="pageListLoading">
    <mat-spinner diameter="15"></mat-spinner>
  </div>
  <div
    style="max-height: 300px; overflow: auto; padding-right: 10px"
    *ngIf="data['channel'] === 'facebook'"
  >
  <div *ngIf = "(validWidgets | widgetConfig: 52)">
    <div class="qb-social-channel-list">
      <div
        class="qb-dialog-subheader"
        *ngIf="
          socialChannelList != null &&
          socialChannelList.length > 1 &&
          data['channel'] === 'facebook'
        "
      >
        {{'SHARED_PAGE.SELECT_PAGE_TO_ADD' | i18nTranslate}}
      </div>
      <div
        class="cursor-pointer"
        (click)="selectAllClicked()"
        *ngIf="socialChannelList != null && socialChannelList.length > 1"
      >
        {{'SHARED_PAGE.SELECT_ALL' | i18nTranslate}}
      </div>
      </div>
      <div *ngFor="let page of socialChannelList; let i = index; let last = last">
        <div class="qb-social-channel-list">
          <div class="qb-page-dat">
            <div>
              <img
                [src]="page.picture.data.url"
                alt=""
                class="qb-page-profilepic"
              />
            </div>
            <div class="qb-page-name">{{ page.name }}</div>
          </div>
          <div>
            <input
              style="cursor: pointer"
              type="checkbox"
              [checked]="selectedPages.indexOf(page.id) != -1"
              (change)="checkChanged($event, page.id)"
            />
          </div>
        </div>
        <hr *ngIf="!last" />
      </div>
  </div>
    <div class="qb-social-channel-list" style="margin-top: 25px;">
      <div
        class="qb-dialog-subheader"
        *ngIf="
          socialChannelListAds != null &&
          socialChannelListAds.length > 0 &&
          data['channel'] === 'facebook' && message === ''
        "
      >
        {{'SHARED_PAGE.SELECT_AD_ACCOUNTS_TO_ADD' | i18nTranslate}}
      </div>
      <div style="width: 100%; display: flex; justify-content: center; align-items: center;"
        class="qb-dialog-subheader"
        *ngIf="
          socialChannelListAds != null &&
          socialChannelListAds.length > 0 &&
          data['channel'] === 'facebook' && message !== ''
        "
      >
       {{message}}
      </div>
      </div>
    <div
    style="max-height: 300px; overflow: auto; padding-right: 10px"
    *ngIf="data['channel'] === 'facebook' && message === ''"
  >
    <div *ngFor="let page of socialChannelListAds; let i = index; let last = last">
      <div class="qb-social-channel-list">
        <div class="qb-page-dat">
          <div>
          </div>
          <div class="qb-page-name">{{ page.adAccountName }}</div>
        </div>
        <div>
          <input
            style="cursor: pointer"
            type="radio"
            [checked]="selectedAd.adAccountId === page.adAccountId"
            (change)="changeadaccount($event, page)"
          />
        </div>
      </div>
      <hr *ngIf="!last" />
    </div>
  </div>
  </div>

  <div
    style="max-height: 300px; overflow: auto; padding-right: 10px"
    *ngIf="data['channel'] === 'instagram'"
  >
    <div *ngFor="let page of socialChannelList; let i = index; let last = last">
      <div class="qb-social-channel-list">
        <div class="qb-page-dat">
          <div>
            <img
              [src]="page.picture.data.url"
              alt=""
              class="qb-page-profilepic"
            />
          </div>
          <div class="qb-page-name">{{ page.name }}</div>
        </div>
      </div>

      <div
        class="qb-social-channel-list"
        style="margin-left: 55px;"
        *ngIf="page.instagramProfile.id !== null"
      >
        <div class="qb-page-dat">
          <div>
            <img
              [src]="page.instagramProfile.profilePictureUrl"
              alt=""
              class="qb-page-profilepic"
            />
          </div>
          <div class="qb-page-name">{{ page.instagramProfile.username }}</div>
        </div>
        <div>
          <input
            style="cursor: pointer"
            type="checkbox"
            [checked]="selectedPages.indexOf(page.id) != -1"
            (change)="checkChanged($event, page.id)"
          />
        </div>

        <hr *ngIf="!last" />
      </div>
    </div>
  </div>

    <div
      class="qb-dialog-subheader"
      style="display: flex; justify-content: center; align-items: center"
      *ngIf="
        (socialChannelList == null || socialChannelList.length == 0) &&
        !pageListLoading && (data['channel'] === 'facebook' || data['channel'] === 'instagram')
      "
    >
      {{'SHARED_PAGE.NO_PAGES_TO_ADD' | i18nTranslate}}
    </div>

    <div class="qb-page-footer">
      <div class="secondary-button qb-btn-set" (click)="close(0)">Cancel</div>
      <div
        class="primary-button qb-btn-set"
        (click)="save(1)"
        *ngIf="socialChannelList != null && socialChannelList.length > 0 || socialChannelListAds != null || socialChannelListAds.length > 0"
      >
        <span *ngIf="!saveLoading">{{'SHARED_PAGE.SAVE' | i18nTranslate}}</span>
        <mat-spinner *ngIf="saveLoading" diameter="12"></mat-spinner>
      </div>
    </div>
</div>
