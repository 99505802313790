import { NgModule } from '@angular/core';
import { IntroComponent, ReportComponent } from './pages';
import { LoyaltyRoutingModule } from './loyalty-routing.module';
import { LoyaltyProgramCreateComponent } from './pages';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { PreviewComponent } from './pages/preview/preview.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { NgxEchartsModule } from 'ngx-echarts';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
  declarations: [IntroComponent, LoyaltyProgramCreateComponent, PreviewComponent, DashboardComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatButtonModule,
    MatNativeDateModule,
    NgbPopoverModule,
    FormsModule,
    MatSelectModule,
    LoyaltyRoutingModule,
    MatSlideToggleModule,
    SharedModule,
    ScrollingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ]
})
export class LoyaltyModule { }
