<div class="page-container">
    <div class="page-actions-row">
        <app-breadcrumb></app-breadcrumb>
        <button
              *ngIf="(validWidgets | widgetConfig: 11)"
              class="primary-button create-button-2"
              (click)="createCampaign()"
              data-button-name="Create New Journey">
                <mat-icon
                style="color: #fff; margin: 0; font-size: 16px; height: 18px">add
                </mat-icon>
                Create New Journey
        </button>
    </div>
    <div class="main-list-container pos-rel main-white-panel">
        <div class="list-title-row">
            <div class="list-header-title">
                A/B Testing Journeys
            </div>
            <div class="search-container">
                <input
                    #search
                    type="text"
                    placeholder="Search by Journey Name"
                    (input)="onKeySearch(search.value)"
                    [(ngModel)]="listSearch"
                />
                <span class="material-icons icons-primary" *ngIf="search.value.length==0"> search </span>
                <span class="material-icons icons-primary cursor-pointer" *ngIf="search.value.length>0" (click)="clearSearch()">
                    close
                </span>
            </div>
        </div>
        <div class="list-column-header-row">
            <div class="col-header">
                Journey Name
            </div>
            <div class="col-header wide-column-width">
                Branch Details
            </div>
            <div class="col-header">
                Experimentation Window
            </div>
            <div class="col-header narrow-column-width">
                Status
            </div>
            <div class="col-header narrow-column-width">
                Actions
            </div>
        </div>
        <div class="main-list">
            <div class="list-loader-container" *ngIf="listLoading">
                <app-qubriux-loading  [size]="65" [loadingText]="'Fetching your list...'"></app-qubriux-loading>
            </div>
            <div *ngIf="!listLoading && abTestingCampaignList.length == 0" class="empty-list-indicator">
                No Journey found
            </div>
            <div *ngIf="!listLoading && abTestingCampaignList.length > 0">
                <div *ngFor="let abTestCamp of abTestingCampaignList" class="list-item-row">
                    <div class="list-item stacked-window-container">
                        <div>{{abTestCamp.strategyName}}</div>
                        <div class="mini-list-item" [matTooltip]="'Created By '+abTestCamp.createdBy"><span class="material-symbols-outlined font-18">person_edit</span> <strong>{{abTestCamp.createdBy}}</strong></div>
                    </div>
                    <div class="list-item branch wide-column-width">
                        <div *ngFor="let path of abTestCamp.pathGroupsList" class="each-branch-disp">
                            <div class="branch-name-disp" [matTooltip]="path.pathName">{{path.pathName}}</div>
                            <div class="branch-perc-disp">{{path.weight}}%</div>  
                        </div>
                    </div>
                    <div class="list-item stacked-data-container">
                        <div class="exp-window-display-2">
                            <div *ngIf="abTestCamp.expDuration[1]">
                              <strong>{{abTestCamp.expDuration[1]}} </strong><em> Day(s)</em>
                            </div>
                            <div *ngIf="abTestCamp.expDuration[0] && abTestCamp.expDuration[1]"> and </div>
                            <div *ngIf="abTestCamp.expDuration[0]">
                              <strong>{{abTestCamp.expDuration[0]}} </strong><em> Hour(s)</em>
                            </div>
                        </div>
                        <div class="label-style-2" *ngIf="abTestCamp.status != 'DRAFT'">
                            <strong>{{getDateTimeInFormatForDisplay(abTestCamp.startDate)}}</strong> - <strong>{{getDateTimeInFormatForDisplay(abTestCamp.endDate)}}</strong>
                         </div>
                    </div>
                    <div class="list-item narrow-column-width d-f-a-c" [ngClass]="{'green-text':abTestCamp.status=='ACTIVE','red-text':abTestCamp.status=='ENDED','grey-text':abTestCamp.status=='DRAFT'}">
                        <span class="material-icons icons-primary color-inherit">flag</span>
                        <span class="font-weight-600">{{abTestCamp.status}}</span>
                    </div>
                    <div class="list-item narrow-column-width action-icon-container">
                        <span *ngIf="abTestCamp.status != 'DRAFT'" [matTooltip]="'View Analytics'" (click)="openAnalyticsPopUp(abTestCamp)" class="material-symbols-outlined sp-icon-1">
                            monitoring
                        </span>
                        <span [matTooltip]="'View Journey'" (click)="openStrategy(abTestCamp.strategyId)" class="material-symbols-outlined sp-icon-1">
                            visibility
                        </span>
                    </div>
                </div>
            </div>
        </div> 
        <div class="lv-options-2">
            <mat-paginator
            #paginator 
              [pageIndex]="abTestingCampaignListPaginationConfig.pageIndex"
              [length]="abTestingCampaignListPaginationConfig.paginationCount"
              [pageSize]="abTestingCampaignListPaginationConfig.recordsPerPage"
              [pageSizeOptions]="[5, 10, 25, 100]"
              (page)="refreshPaginatedData($event)"
            >
            </mat-paginator>
        </div>
    </div>
</div>



<div class="ab-popup-container" *ngIf="showAnalyticsPopUp" (click)="closeAnalyticsPopUp()">
    <div class="ab-popup" (click)="$event.stopPropagation()">
        <div class="acp-top-row">
            <h2>Branch Analytics</h2>
            <button class="primary-button margin-left-13" (click)="addNewWindow(trackedMetrices[0].value)">
                <mat-icon
                style="color: #fff; margin: 0; font-size: 16px; height: 18px">add
                </mat-icon>
                Add Analytics Window
            </button>
            <div class="acp-close-1">
                <div class="selected-strat" [matTooltip]="'Selected Journey: '+selectedCampaign.strategyName">
                    {{selectedCampaign.strategyName}}
                </div>
                <div class="material-symbols-outlined cursor-pointer" (click)="closeAnalyticsPopUp()">
                    close
                </div>
            </div>
        </div>
        
        <div class="list-loader-container" *ngIf="analyticsPopUpLoading">
            <app-qubriux-loading  [size]="65" [loadingText]="'Fetching analytics...'"></app-qubriux-loading>
        </div>

        <div class="windows-container" *ngIf="!analyticsPopUpLoading">
            <div class="analytics-window pos-rel" *ngFor="let windowData of windowDataList;let windowIndex = index">
                <div class="analytics-header">
                    <div class="display-flex-with-align-center">
                        <div class="analytics-header-text">
                            Analytics for
                        </div>
                        <div *ngIf="!analyticsPopUpLoading" class="analytics-header-selector">
                            <mat-select [(ngModel)]="windowData.selectedMetric" (selectionChange)="updateWindow(windowData)">
                                <mat-option *ngFor="let mertric of trackedMetrices" [value]="mertric.value" [matTooltip]="mertric.displayName">{{mertric.displayName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    
                    <div class="margin-left-auto display-flex-with-align-center">
                        <button mat-button [matMenuTriggerFor]="menu" class="options-button">Graph settings 
                            <span class="material-symbols-outlined">
                                settings
                            </span>
                        </button>
                        <mat-menu #menu="matMenu"  class="custom-menu">
                            <div class="display-flex-with-align-center menu-row">
                                <div class="label-style ">
                                    Dates:
                                </div>
                                <div class="date-range-container">
                                    <div class="date-range-top-text">
                                        Start Date - End Date
                                    </div>
                                    <mat-date-range-input [rangePicker]="picker" style="width: 185px;" [min]="this.selectedCampaign.startDateObj" [max]="this.selectedCampaign.endDateObj" (click)="$event.stopPropagation()">
                                        <input class="cursor-pointer" matStartDate placeholder="Start date" [(ngModel)]="windowData.graphStartDateObj" (click)="picker.open()" (ngModelChange)="updateWindow(windowData)" readonly>
                                        <input class="cursor-pointer" matEndDate placeholder="End date" [(ngModel)]="windowData.graphEndDateObj" (click)="picker.open()" (ngModelChange)="updateWindow(windowData)" readonly>
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                    <div class="date-range-bottom-text">
                                        DD/MM/YYYY - DD/MM/YYYY
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="display-flex-with-align-center menu-row">
                                <div>
                                    Times:
                                </div>
                                <div class="date-range-container">
                                    <div class="">
                                        <input class="cursor-pointer" style="width: 80px;" [(ngModel)]="startTime"
                                          [ngxTimepicker]="picker1T" [max]="startMaxTime" [min]="startMinTime" [format]="24" (click)="$event.stopPropagation()" readonly/>
                                        <ngx-material-timepicker #picker1T (closed)="startTimeChange()">
                                        </ngx-material-timepicker>
                                        <img [ngxTimepicker]="picker1T" style="margin-right: 10px; cursor: pointer;"
                                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg" alt="" (closed)="startTimeChange()" (click)="$event.stopPropagation()"/>
                                    </div>
                                </div>
                            </div> -->
                            <div class="display-flex-with-align-center menu-row">
                                <div class="label-style">
                                    Intervals:
                                </div>
                                <div class="display-flex-with-align-center" *ngIf="!analyticsPopUpLoading">
                                    <mat-select class="analytics-header-selector" [(ngModel)]="windowData.xInterval" (selectionChange)="updateWindow(windowData)"  (click)="$event.stopPropagation()">
                                        <mat-option *ngFor="let interval of xIntervalConstantList" [value]="interval">{{capitalize(interval+'s')}}</mat-option>
                                    </mat-select>
                                </div>  
                            </div>
                        </mat-menu>
                        <div *ngIf="windowDataList.length>1" class="material-symbols-outlined cursor-pointer acp-close-2" (click)="removeWindow(windowData.windowId)">
                            close
                        </div>
                    </div>
                </div>
                
                <div class="display-flex-with-justify-center branch-comp-row">
                    <div *ngFor="let branchStat of windowData.eachPathStats" class="each-branch-stats" [style.backgroundColor]="branchStat.color">
                        <div class="display-flex-with-align-center border-bottom-1">
                               <div class="ellipsis-text-container" [matTooltip]="branchStat.pathName">{{branchStat.pathName}}</div>
                               <div [matTooltip]="'Customer Count: '+branchStat.customerCount" class="display-flex-with-align-center margin-left-auto margin-right-5">
                                    <span class="material-symbols-outlined font-size-18">
                                        person
                                    </span>
                                    <span>{{branchStat.customerCount}}</span>
                                </div>
                        </div>
                        <div class="display-flex-with-justify-center distribution-row">
                            <!-- <div class="display-flex-with-align-center">
                                <span class="material-symbols-outlined">
                                    person
                                </span>
                                <span>{{branchStat.distributionPerc}}%</span>
                            </div> -->
                        </div>
                        <div class="ellipsis-text-container">
                            <span [matTooltip]="isRevenueMetric(windowData.selectedMetric)?currencySymbol+' '+branchStat.kpiValue:branchStat.kpiValue">
                                <span *ngIf="isRevenueMetric(windowData.selectedMetric)">{{currencySymbol}} </span>
                                {{branchStat.kpiValue}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="window-graph-container">
                    <div *ngIf="windowData.graphLoading" class="graph-loader">
                        <app-qubriux-loading  [size]="35" [loadingText]="'Fetching your graph...'"></app-qubriux-loading>
                    </div>
                    <div *ngIf="!windowData.graphLoading && !windowData.hasGraphData" class="graph-loader flex-column pos-rel">
                        <div echarts [options]="windowData.graphOption" class="graph-height"></div>
                        <div class="graph-no-data">
                            <div class="material-symbols-outlined font-size-90">
                                snowing
                            </div>
                            <div>
                                No data available.
                            </div>
                            <div class="center-text">
                                Try adjusting the Date range from the graph setting.
                            </div>
                        </div> 
                    </div>
                    <div *ngIf="!windowData.graphLoading && !windowData.graphEndDateObj" class="graph-loader flex-column">
                        <div class="material-symbols-outlined font-size-90">
                            snowing
                        </div>
                        <div>
                            Please select an End date
                        </div>
                    </div>
                    <div *ngIf="!windowData.graphLoading && windowData.hasGraphData && windowData.graphEndDateObj" echarts [options]="windowData.graphOption" class="graph-height"></div>
                </div>
            </div>
        </div>

    </div>
</div>