import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getLogoUrlService } from 'src/app/core/services/get-logo-url.service';
import { SignoutService } from 'src/app/core/services/signout.service';

@Component({
  selector: 'app-store-not-ready',
  templateUrl: './store-not-ready.component.html',
  styleUrls: ['./store-not-ready.component.css']
})
export class StoreNotReadyComponent implements OnInit {

  constructor(
    private router: Router,
    private tokenStorage: TokenStorageService,
    private _signoutService: SignoutService,
    private apicall: ApiCallService,
    private getLogoService: getLogoUrlService
  ) { }
    userName = "";
    logoUrl = this.getLogoService.getLogo();

  ngOnInit(): void {
    this.getPlanDetails();
  }
  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.router.navigate(["auth/login"], {});
  }
  getPlanDetails(){
    this.apicall.getCurrentPlanPos().subscribe((res)=>{
      this.userName = res['body']['userEmail'];
      if(res['body']['status'] === 'ACTIVE')
        this.router.navigate(['data-ingestion/check']);
    })
  }
}
