import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-node-delete-branch-selector",
  templateUrl: "./node-delete-branch-selector.component.html",
  styleUrls: ["./node-delete-branch-selector.component.css"],
})
export class NodeDeleteBranchSelectorComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NodeDeleteBranchSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {

  }

  selection = 1;
  radioSelection = "1";

  submit(status) {
    if (this.data["operation"] === "add") {
      let result = {
        status: status,
        value: this.selection,
      };
      this.dialogRef.close(result);
    } else {
      if (status) {
        this.dialogRef.close(this.selection);
      } else {
        this.dialogRef.close(false);
      }
    }
  }

  radioSelectionChange() {
    if (this.radioSelection === "1") {
      this.selection = 1;
    }
    if (this.radioSelection === "2") {
      this.selection = 2;
    }
    if(this.radioSelection === "2" && this.data.type === 'multiple-split'){
      this.changeSelection(this.data.nodeData[0]);
    }
  }
  changeSelection(name){
    this.selection = name;
  }
}
