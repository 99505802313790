import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

interface DialogData {
  operation: string;
  segments: [];
  data: {};
  Id: number;
}

@Component({
  selector: "app-campaign-strategy-dialog",
  templateUrl: "./campaign-strategy-dialog.component.html",
  styleUrls: ["./campaign-strategy-dialog.component.css"],
})
export class CampaignStrategyDialogComponent implements OnInit {
  merchantTrackingAction;
  merchantTrackingType;
  dropDownStatus: boolean = false;
  customerSegments = [];
  endDate = new FormControl(new Date());
  startDate = new FormControl(new Date());
  startDateMinValue = new Date();
  startDateMaxValue = new Date();
  endDateMinValue = new Date();
  endDateMaxValue = new Date();
  totalCustomerCount = 0;
  storeTargetedCustomerCount = 0;
  totalCustomersValidEmail = 0;
  totalCustomersValidPhNo = 0;
  createstrategyApi = null;
  allcustomerObj = [
    { id: "ALL", segmentName: "My Customers" },
    { id: "LEADS", segmentName: "All Leads" },
  ];
  dataForm = new FormGroup({
    strategyName: new FormControl("", [
      Validators.required,
      Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      Validators.maxLength(50),
      Validators.minLength(3),
    ]),
    customerSegments: new FormControl("", [Validators.required]),
    customerIds: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.maxLength(300)]),
  });

  submitClicked = false;
  disableCustomerSegments = false;
  apiErrorMessage = null;
  segmentFilter = "";
  store;
  adminCreated = {
    storeId: null,
    storeName: "ADMIN",
    qid: null,
  };
  storeDataJSON = {};
  duplicateNameErrorText = "";
  validScreens;
  isAdmin = true;

  constructor(
    public dialogRef: MatDialogRef<CampaignStrategyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiCallService: ApiCallService,
    private popUp: PopUpDialogService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private _WidgetConfigService: WidgetConfigService
  ) {
    window["screen-name"] = "CAMPAIGN CREATE";
  }

  ngOnInit() {
    if (this.tokenStorage.getqId() !== "null") {
      this.getParticularStoreJSON();
      this.isAdmin = false;
    }
    console.log(this.data);
    this.getScreenId();
    this.store = this.tokenStorage.getMerchantOnlineStore();
    this.startDateMinValue = this.startDate.value;
    this.endDateMinValue = this.startDate.value;
    this.startDateMaxValue = null;
    this.endDateMaxValue = null;
    this.merchantTrackingAction = this.data["strategyAction"];
    this.merchantTrackingType = this.data["strategyType"];
    if (this.merchantTrackingAction == "create") {
      window["screen-id"] = 6;
      this.merchantTrackingType = "custom";
    } else {
      window["screen-id"] = 8;
    }
    if (this.data["operation"] === "UPDATE") {
      this.dataForm
        .get("strategyName")
        .setValue(this.data["data"]["strategyName"]);
      this.dataForm
        .get("description")
        .setValue(this.data["data"]["strategyDescription"]);
      // this.dataForm
      //   .get("customerSegments")
      //   .setValue(JSON.parse(this.data["data"]["definition"])["segmentIds"]);
      // this.dataForm
      //   .get("customerIds")
      //   .setValue(JSON.parse(this.data["data"]["definition"])["segmentIds"]);
      this.totalCustomerCount = this.data["data"]["customerCount"];
      this.toggleDisabledValue();
      if (this.data["data"]["segments"].length > 0) {
        this.customerSegments = this.data["data"]["segments"].map((x) => x);
      }
      if (this.data["data"]["allLead"] === true) {
        this.customerSegments.push("LEADS");
      }
      if (this.data["data"]["allCustomer"] === true) {
        this.customerSegments.push("ALL");
      }
      this.getCustomerCountInSegmentAndTiers();
    }
    if (this.data["recommendation"] === "true") {
      let id = [];
      for (let i = 0; i < this.data["customerIds"].length; i++) {
        id.push(this.data["customerIds"][i]["id"]);
      }
      this.dataForm.get("customerIds").setValue(id);
    }
  }

  ngAfterViewInit() {
    document
      .getElementById("form-container")
      .addEventListener("click", (event) => this.hideAllEvents(event));
  }

  async getScreenId() {
    this.validScreens = await this._WidgetConfigService.getScreen();
    let index = this.validScreens?.findIndex(d => d === 57);
    if(index === -1){
      this.allcustomerObj = this.allcustomerObj.filter(item => item.id !== "LEADS");
    }
  }

  hideAllEvents(event) {
    let element = document.getElementById("multi-select-dropdown");
    if (!element.contains(event.target)) {
      if (this.dropDownStatus) {
        this.dropDownStatus = false;
        element.style.height = "0px";
        let keyboardDownIconElement = document.getElementById(
          "keyboard-down-icon-container"
        );
        keyboardDownIconElement.style.transform = "rotate(0deg)";
        this.getCustomerCountInSegmentAndTiers();
      }
    }
  }

  toggleDisabledValue() {
    for (
      let i = 0;
      i < this.dataForm.get("customerSegments").value.length;
      i++
    ) {
      if (this.dataForm.get("customerSegments").value[i] === "null") {
        this.disableCustomerSegments = true;
        let value = ["null"];
        this.dataForm.get("customerSegments").setValue(value);
        return;
      }
    }
    this.disableCustomerSegments = false;
  }

  closeDialog(status) {
    document.getElementById("errorField").innerHTML = "";
    let nameError = document.getElementById("name-error");
    if (nameError) {
      nameError.innerHTML = "";
    }
    let descriptionError = document.getElementById("description-error");
    if (descriptionError) {
      descriptionError.innerHTML = "";
    }
    this.apiErrorMessage = null;
    if (status) {
      if (!this.createstrategyApi) {
        this.submitClicked = true;
        if (
          this.dataForm.get("strategyName").errors ||
          this.customerSegments.length === 0 ||
          this.dataForm.get("description").errors
        ) {
          if (this.dataForm.get("strategyName").errors) {
            if (this.dataForm.get("strategyName").value.length > 50) {
              document.getElementById("name-error").innerHTML = this._i18nDynamicTranslate.transform("Journey name should not exceed 50 characters", ['SHARED_PAGE']);
            }
            else if (this.dataForm.get("strategyName").value.length == 0) {
              document.getElementById("name-error").innerHTML = this._i18nDynamicTranslate.transform("Journey name should not be empty", ['SHARED_PAGE']);
            }
            else if (this.dataForm.get("strategyName").value.length < 3) {
              document.getElementById("name-error").innerHTML = this._i18nDynamicTranslate.transform("Journey name should have at least 3 charcters", ['SHARED_PAGE']);
            }
            else{
              document.getElementById("name-error").innerHTML = this._i18nDynamicTranslate.transform("Please enter a valid Journey name", ['SHARED_PAGE']);
            }
          }
          if (this.dataForm.get("description").errors) {
            if (this.dataForm.get("description").value.length > 300) {
              document.getElementById("description-error").innerHTML = this._i18nDynamicTranslate.transform("Journey description should not exceed 300 characters", ['SHARED_PAGE']);
            }
          }
          if (this.customerSegments.length === 0) {
            document.getElementById("errorField").innerHTML =
              this._i18nDynamicTranslate.transform("Select any segment", [
                "SHARED_PAGE",
              ]);
            document.getElementById("multi-select-panel").style.border =
              "1px solid #c71a1a";
          }
          return;
        }
        let data;
        //let segments = [];
        // for(let i=0;i<this.dataForm.get('customerSegments').value.length;i++){
        //   if(this.dataForm.get('customerSegments').value[i] === 'ALL_CUSTOMERS'){
        //     segments.push({'id':null, 'segmentName' : "ALL_CUSTOMERS"})
        //   }
        //   else{
        //     segments.push({
        //       'id': this.data.segments[this.data.segments.findIndex(x=>x['segmentName'] === this.dataForm.get('customerSegments').value[i])]['id'],
        //       'segmentName' : this.dataForm.get('customerSegments').value[i]})
        //   }
        // }
        let segments = [];
        let tiers = [];
        let customerList = [];
        let employeeList = [];
        if (!this.customerSegments.includes("ALL")) {
          this.customerSegments.map((x) => {
            if (x?.category?.toLowerCase() === "segment") {
              segments.push(x.id);
            }
            if (x?.category?.toLowerCase() === "tier") {
              tiers.push(x.id);
            }
            if (x?.category?.toLowerCase() === "customerlist") {
              customerList.push(x.id);
            }
            if (x?.category?.toLowerCase() === "employeelist") {
              employeeList.push(x.id);
            }
          });
        }

        let definition = {};
        definition["tierIds"] = tiers;
        definition["segmentIds"] = segments;
        definition["customerListIds"] = customerList;
        definition["employeeListIds"] = employeeList;
        definition["allLead"] = this.customerSegments.includes("LEADS") ? true : false;
        definition["allCustomer"] = this.customerSegments.includes("ALL") ? true : false;
        data = {
          strategyName: this.dataForm.get("strategyName").value,
          description: this.dataForm.get("description").value,
          definition: JSON.stringify(definition),
          isSegmented:
            segments.length > 0 ||
            tiers.length > 0 ||
            customerList.length > 0 ||
            employeeList.length > 0
              ? true
              : false,
          createdBy:
            this.tokenStorage.getqId() === "null"
              ? this.adminCreated
              : this.storeDataJSON,
          // customerCount: this.totalCustomerCount,
          // startDate: this.startDate.value,
          // endDate: this.endDate.value,
          // isSegmented:
          //   this.dataForm.get("customerSegments").value.length === 1 &&
          //   this.dataForm.get("customerSegments").value[0] === "null" &&
          //   this.data["recommendation"] === "false"
          //     ? false
          //     : true,
          // definition: JSON.stringify({
          //   segmentIds: this.dataForm.get("customerSegments").value,
          //   customerIds:
          //     this.data["recommendation"] === "true"
          //       ? this.dataForm.get("customerIds").value
          //       : null,
          //}),
        };
        let resultData = {
          strategyName: this.dataForm.get("strategyName").value,
          strategyDescription: this.dataForm.get("description").value,
          segments:
            segments.length > 0 ||
            tiers.length > 0 ||
            customerList.length > 0 ||
            employeeList.length > 0
              ? this.getOnlySegments(this.customerSegments)
              : [],
          customerCount: this.totalCustomerCount,
          allLead: this.customerSegments.includes("LEADS") ? true : false,
          allCustomer: this.customerSegments.includes("ALL") ? true : false,
          created_by: data.createdBy,
        };
        if (this.data["operation"] === "UPDATE") {
          this.createstrategyApi = this.apiCallService
            .updateStrategy(this.data["Id"], data)
            .subscribe(
              (response) => {
                let result = {
                  status: true,
                  strategyId: this.data["Id"],
                  strategyData: resultData,
                  operation: this.data["operation"],
                };
                this.dialogRef.close(result);
                this.createstrategyApi = null;
              },
              (err) => {
                document.getElementById("errorField").innerHTML =
                  err["error"]["body"];
                this.createstrategyApi = null;
                return;
              }
            );
        } else {
          this.createstrategyApi = this.apiCallService
            .createStrategy(data)
            .subscribe(
              (response) => {
                let result = {
                  status: true,
                  strategyId: response["body"],
                  strategyData: resultData,
                  operation: this.data["operation"],
                };
                this.createstrategyApi = null;
                this.dialogRef.close(result);
              },
              (err) => {
                if (
                  err["error"]["body"].toLowerCase() ==
                  "duplicate journey name"
                ) {
                  this.duplicateNameErrorText = err["error"]["body"];
                  this.createstrategyApi = null;
                  return;
                } else {
                  document.getElementById("errorField").innerHTML =
                    err["error"]["body"];
                  this.createstrategyApi = null;
                  return;
                }
              }
            );
        }
      }
    } else {
      let result = {
        status: false,
        strategyId: null,
        strategyData: null,
        operation: this.data["operation"],
      };
      this.dialogRef.close(result);
    }
  }

  toggleSelect(event) {
    event.stopPropagation();
    let element = document.getElementById("multi-select-dropdown");
    let segmentSelect = document.getElementById("segment-select");
    let segmentSelectPanel = document.getElementById("multi-select-panel");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container"
    );
    if (this.dropDownStatus) {
      this.dropDownStatus = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.border = "1px solid #919191";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      this.getCustomerCountInSegmentAndTiers();
      //element.style.display = 'none';
    } else {
      this.dropDownStatus = true;
      element.style.height = "180px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      segmentSelectPanel.style.border = "1px solid #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }

  updateCheckboxValues(segment, category) {
    document.getElementById("errorField").innerHTML = "";
    if (this.customerSegments.includes("ALL")) {
      if (this.customerSegments.includes("LEADS")) {
        this.customerSegments = [];
        this.customerSegments.push("LEADS");
      } else this.customerSegments = [];
    }
    let index = this.customerSegments.findIndex((x) => (x.id === segment.id && x.category.toLowerCase() === category));
    if (index < 0) {
      this.customerSegments.push(segment);
    } else {
      this.customerSegments.splice(index, 1);
    }
  }

  checkboxStatus(segment) {
    let index = this.customerSegments.findIndex((x) => x.id === segment.id);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  submitDashboardFilter() {
    this.startDateMaxValue = this.endDate.value;
    this.endDateMinValue = this.startDate.value;
  }

  getCustomerCountInSegmentAndTiers() {
    this.totalCustomerCount = 0;
    this.totalCustomersValidEmail = 0;
    this.totalCustomersValidPhNo = 0;
    this.storeTargetedCustomerCount=0;
    // if (this.customerSegments.length === 0) {
    //   this.totalCustomerCount = 0;
    //   this.totalCustomersValidEmail = 0;
    //   this.totalCustomersValidPhNo = 0;
    // } else {
    if (this.customerSegments.includes("ALL") || this.customerSegments.includes("LEADS")) {
      let startDate = new Date();
      let endDate = new Date();
      let isLead = this.customerSegments.includes("LEADS") ? true : false;
      let isCustomer = this.customerSegments.includes("ALL") ? true : false;
      this.apiCallService
        .getAnalytics(startDate, endDate, isLead, isCustomer)
        .subscribe((response) => {
          const responseBody = JSON.parse(response["body"]);
          this.totalCustomerCount =
            this.totalCustomerCount + responseBody.numberOfCustomers;
          this.storeTargetedCustomerCount =
            this.storeTargetedCustomerCount + responseBody.numberOfCustomers;
          this.totalCustomersValidEmail =
            this.totalCustomersValidEmail + responseBody.ValidEmailCount;
          this.totalCustomersValidPhNo =
            this.totalCustomersValidPhNo + responseBody.ValidMobileNumberCount;
        });
    }
    // if (this.customerSegments.includes("LEADS")) {
    //   let startDate = new Date();
    //   let endDate = new Date();
    //   this.apiCallService
    //     .getAnalytics(startDate, endDate, true)
    //     .subscribe((response) => {
    //       const responseBody = JSON.parse(response["body"]);
    //       this.totalCustomerCount =
    //         this.totalCustomerCount + responseBody.numberOfCustomers;
    //       this.totalCustomersValidEmail =
    //         this.totalCustomersValidEmail + responseBody.ValidEmailCount;
    //       this.totalCustomersValidPhNo =
    //         this.totalCustomersValidPhNo + responseBody.ValidMobileNumberCount;
    //     });
    // }
    if (this.isSegmentPresentArray(this.customerSegments)) {
      this.apiCallService
        .getCustomerCountInSegmentAndTiers(
          this.getOnlySegments(this.customerSegments)
        )
        .subscribe((response) => {
          const responseBody = JSON.parse(response["body"]);
          this.totalCustomerCount =
            this.totalCustomerCount + responseBody.totalCustomerCount;
            this.storeTargetedCustomerCount = 
            this.storeTargetedCustomerCount + responseBody.targetedCustomerCount;
          this.totalCustomersValidEmail =
            this.totalCustomersValidEmail +
            responseBody.totalCustomerValidEmailCount;
          this.totalCustomersValidPhNo =
            this.totalCustomersValidPhNo +
            responseBody.totalCustomerValidSMSCount;
        });
    }
    // }
  }

  isSegmentPresentArray(dataArray) {
    return dataArray.some(
      (item) => typeof item === "object" && item !== null && "id" in item
    );
  }

  addAllCustomers(event, id) {
    document.getElementById("errorField").innerHTML = "";
    if (id === "ALL") {
      if (this.customerSegments.includes("LEADS")) {
        this.customerSegments = [];
        this.customerSegments.push("LEADS");
      } else this.customerSegments = [];
    }
    if (id === "LEADS") {
      let index = this.customerSegments.indexOf("LEADS");
      if (index < 0) {
        this.customerSegments.push(id);
      } else {
        this.customerSegments.splice(index, 1);
      }
    }
    if (event["checked"] === true && id === "ALL") {
      this.customerSegments.push(id);
    }
  }

  isChecked(segment, category) {
    if (this.customerSegments.includes("ALL")) {
      return false;
    } else {
      let index = this.customerSegments.findIndex((x) => (x.id === segment.id && x.category.toLowerCase() === category));
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  getSearchFilteredSegments() {
    return (this.data.segments as any[]).filter((segment) =>
      this.segmentFilter
        ? segment["segmentName"]
            .toLowerCase()
            .includes(this.segmentFilter.toLowerCase())
        : true
    );
  }
  isSegmentPresent() {
    if (this.data && this.data.segments) {
      const filteredSegments = this.getSearchFilteredSegments();
      return (
        filteredSegments.length > 0 &&
        filteredSegments.some(
          (segment) => segment.category.toLowerCase() === "segment"
        )
      );
    }
    return false;
  }
  isTierSegmentPresent(): boolean {
    if (this.data && this.data.segments) {
      const filteredSegments = this.getSearchFilteredSegments();
      return (
        filteredSegments.length > 0 &&
        filteredSegments.some(
          (segment) => segment.category.toLowerCase() === "tier"
        )
      );
    }
    return false;
  }
  isCustomerListPresent(): boolean {
    if (this.data && this.data.segments) {
      const filteredSegments = this.getSearchFilteredSegments();
      return (
        filteredSegments.length > 0 &&
        filteredSegments.some(
          (segment) => segment.category.toLowerCase() === "customerlist"
        )
      );
    }
    return false;
  }

  isEmployeeListPresent(): boolean {
    if (this.data && this.data.segments) {
      const filteredSegments = this.getSearchFilteredSegments();
      return (
        filteredSegments.length > 0 &&
        filteredSegments.some(
          (segment) => segment.category.toLowerCase() === "employeelist"
        )
      );
    }
    return false;
  }

  getParticularStoreJSON() {
    let data: any = {};
    this.apiCallService.getSpecificStoreOffer().subscribe((response) => {
      console.log(response);
      data.storeId = response["body"]["storeMetaInfo"].storeId;
      data.storeName = response["body"]["storeMetaInfo"].storeName;
      data.qid = response["body"]["storeMetaInfo"].qid;
      this.storeDataJSON = data;
    });
  }

  stringInputHandler(event: KeyboardEvent, maxLength, comparisonStatement?) {
    if (this.duplicateNameErrorText) this.duplicateNameErrorText = "";
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  getOnlySegments(data) {
    const temp = [...data]; // Create a shallow copy of the array
    let index1 = temp.indexOf("LEADS");
    let index2 = temp.indexOf("ALL");

    if (index1 !== -1) {
      temp.splice(index1, 1);
    }
    // Recalculate index2 after modifying the array
    index2 = temp.indexOf("ALL");
    if (index2 !== -1) {
      temp.splice(index2, 1);
    }

    return temp;
  }
}
