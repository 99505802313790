<div class="qb-w-100 qb-h-100">
    <div>
        <div class="header">
          <span style="text-align: center;">{{subject}}</span>
          <mat-icon class="close-icon" (click)="closeDialog(false)">close</mat-icon>
        </div>
    </div>
    <div class="dialog-content">
        <div echarts [options]="sampleChart" style="height: 300px; width: 400px; margin-top: 20px;"></div>
        <p class="description">{{description}}</p>
    </div>
    <div align="end" class="button-padding">
      <button mat-button class="primary-button" *ngIf="currentPage === 'ideal'" (click)="navigateTo('worst')">Show worst Case</button>
      <button mat-button class="primary-button" *ngIf="currentPage === 'worst'" (click)="navigateTo('ideal')">Show ideal Case</button>
    </div>
</div>