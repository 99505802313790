import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-offer-select-doalog-box',
  templateUrl: './offer-select-doalog-box.component.html',
  styleUrls: ['./offer-select-doalog-box.component.css']
})
export class OfferSelectDoalogBoxComponent implements OnInit {

  constructor(
    private apiCall:ApiCallService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OfferSelectDoalogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private snackBar: SnackbarCollection,
    private router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }
  selectedOfferType = "";
  offers = [];

  ngOnInit(): void {
    this.getAllFormOffers();
  }
  selectOffer(type){
    this.selectedOfferType = type;
  }
  submit(){
    this.dialogRef.close(false);
  }
  createOffer(){
    if(this.selectedOfferType === ''){
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please select which type of Offer you want to create", ['SHARED_PAGE']), 3000);
    }
    else{
      if(this.selectedOfferType === 'singleOffer'){
        this.router.navigate(["/app/partnership/my-offers/new-offer"]);
        this.dialogRef.close(true);
      }
      else{
        if(this.offers.length>0){
          this.router.navigate(["/app/partnership/my-offers/new-group-offer"]);
          this.dialogRef.close(true);
        }
        else
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("You should have at least one source offer to create a grouped offer", ['SHARED_PAGE']), 3000);
      }
    }
  }
  async getAllFormOffers() {
    await this.apiCall
      .getAllPartnershipFormOffers(null, false)
      .subscribe((response) => {
        this.offers = response["body"]["formOfferDetailsList"];
      });
  }

}
