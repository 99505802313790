import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";


@Component({
  selector: "app-name-and-description",
  templateUrl: "./name-and-description.component.html",
  styleUrls: ["./name-and-description.component.css"],
})
export class NameAndDescriptionComponent implements OnInit {
  field1Value: string = "";
  field2Value: string = "";
  errorText: string= "";
  errorTextDesc: string = "";

  constructor(
    public dialogRef: MatDialogRef<NameAndDescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiCall: ApiCallService,
    private _snackBar: SnackbarCollection
  ) {}

  ngOnInit() {}
  cancel() {
    this.dialogRef.close(0);
  }
  submit() {
    if (this.data.action == "Gamify") {
      // if(this.field1Value.charAt(0)
      // if (this.field1Value.match(/^\d/)){
      //   this.errorText="Game name must start with a letter"
      //   return 0;
      // }
      // if (this.field1Value == "") {
      //   this.errorText="Game name is required."
      //   return 0;
      // } else if (this.field1Value.length < 3) {
      //   this.errorText="Game name should contain minimum 3 characters and maximum 45 characters."
      //   return 0;
      // }
      // else if (this.field1Value.length > 45) {
      //   this.errorText="Game name should contain minimum 3 characters and maximum 45 characters."
      //   return 0;
      // }
      // if(this.field2Value.length > 255){
      //   this.errorTextDesc="Game description can not be more than 255 characters"
      //   return 0;
      // }
      let data = {
        title: this.field1Value
      };
      this._apiCall.createGame(data).subscribe((response) => {
        if(response["body"]["qbObject"]!=null)
          this.dialogRef.close(response["body"]["qbObject"]["id"]);
        else{
          this.errorText=response["body"]["qbErrors"][0]["message"]
          console.log(this.errorText)
        }

      },(error)=>{
        this.errorText=error.error['body']
      })
    }
  }
  gameNameKeyPress(event) {
    if (45 - this.field1Value.length === 0) {
      return false;
    }
  }
}
