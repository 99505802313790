import { Component, OnInit, EventEmitter, Output, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, } from '@angular/router';
import { assetPath } from 'src/app/configs/asset-path-config';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { Globals } from 'src/app/core/services/global.service';
import { WindowRef } from 'src/app/core/services/windowRef.service';
import { ContactUsDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';


@Component({
  selector: 'app-payment-order-summary',
  templateUrl: './payment-order-summary.component.html',
  styleUrls: ['./payment-order-summary.component.css']
})
export class PaymentOrderSummaryComponent implements OnInit {
  imagePath1 = assetPath.path + '/Capture.PNG';
  imagePath2 = assetPath.path + '/Razorpay_logo.png';

  repeat = 'no-repeat';
  currentPath: string;
  message: string = "ordersummary";
  orderId: string = null;
  useremail: string = null;



  model = { option: 'Razorpay' };
  paymentService;


  finalObj: [];
  masterPlan;
  userId:any;
  subplanId;
  PlanSelDes=[];
  currency:string;


  shopifyBilling;
  loading:boolean=false;

  @Output() messageEvent = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, private apiCall: ApiCallService, private winRef: WindowRef, private router: Router, private ngZone: NgZone,private globalService:Globals,private dialog:MatDialog,private _i18nDynamicTranslate: TranslateDynamicText) {
    this.route.queryParams.subscribe(params => {
      this.subplanId = params['planSelectedId'];
      if(this.subplanId==1){
        this.userId=2;
      }else if(this.subplanId==3){
      this.userId=3;
      }
      else{

      }
    });
  }

  ngOnInit() {
    this.route.url.subscribe(urlSeg => {
      this.currentPath = urlSeg[0].path;
    })
    //let currency=this.globalService.merchantDetailsCurrency;
    this.currency=sessionStorage.getItem("serviceCurrency");
    this.apiCall.getAllSubscriptionMasterPlan("USD").subscribe((response) => {
      if (response['message'] === "success") {
        let resBody = JSON.parse(response['body']);
        this.masterPlan =resBody
        for (let i = 0; i < this.masterPlan.length; i++) {
          if (this.userId == this.masterPlan[i].id) {
            this.PlanSelDes=(this.masterPlan[i]);
          }
        }
      }
    }, (err) => {

      const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
        width: '380px',
        height: 'auto',
        disableClose: true,
        // this.dialogRef.componentInstance.data : "You will be logged out!"
        data: {
          "header": this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
        "body":this._i18nDynamicTranslate.transform(err.error['message'], ['POS_PAGE'])
        }
      })
  })
  }
  sendMessage() {
    this.messageEvent.emit(this.message)
  }
  shopifyOrderCreate(){
    this.loading=true;
    this.apiCall.createShopifyBillingOrder(this.subplanId,"MONTHLY_SUBSCRIPTION").subscribe((result)=>{
    this.shopifyBilling=result['body']
      let redirectUrl=this.shopifyBilling.confirmationURL;
      let chargeID=this.shopifyBilling.chargeId;
      sessionStorage.setItem('chargeId',chargeID)
      window.location.href = redirectUrl;
//log("shopifyBilling",this.shopifyBilling)
    },
    (err) => {

      const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
        width: '380px',
        height: 'auto',
        disableClose: true,
        // this.dialogRef.componentInstance.data : "You will be logged out!"
        data: {
          "header":this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
        "body":this._i18nDynamicTranslate.transform(err.error['message'], ['POS_PAGE'])
        }
      })
  })
  }

  ToPay() {
    this.apiCall.setPurchaseOrder("razorpay", this.userId, this.currency).subscribe((result) => {
      this.orderId = JSON.parse(result['body'])['paymentGatewayOrderId'];
      this.useremail = JSON.parse(result['body'])['userEmail'];
      this.payWithRazor(this.orderId, this.useremail);




    },
    (err) => {

      const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
        width: '380px',
        height: 'auto',
        disableClose: true,
        // this.dialogRef.componentInstance.data : "You will be logged out!"
        data: {
          "header":this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
        "body":this._i18nDynamicTranslate.transform(err.error['message'], ['POS_PAGE'])
        }
      })
  })
  }

  payWithRazor(orderId, useremail) {
    let options: any = {
      "key": "rzp_test_MHQA7354Ywa6SX",
      //"amount": 100,
      "name": "Qubriux",
      "description": "Purchase",
     // "currency":"USD",
      "base_amount": 7129,
     "base_currency": "INR",
      "image": "../../../assets/noun_shape_1709165.svg",
      "modal": {
        "escape": false
      },
      "order_id": orderId,
      //   "handler": function (response){
      //       this.PaymentResponse1=response;



      // },
      "prefill": {
        // "name": finalObj.customerDetail.name,
        "contact": "1234567890",
        "email": useremail,
        // "method": 'card',
        // 'card[number]': finalObj.cardDetail.cardNumber,
        // 'card[expiry]': finalObj.cardDetail.cardExpDate,
        // 'card[cvv]': finalObj.cardDetail.cardCvv
      },
      "notes": {
        //  "address": finalObj.addressDetail.address + ', ' + finalObj.addressDetail.landmark + ', ' + finalObj.addressDetail.city + ', ' + finalObj.addressDetail.state + '-' + finalObj.addressDetail.pincode
      },
      "theme": {
        "color": "#57b2bc"
      }
    };
    //   options['payment_response_id'] = response.razorpay_payment_id;
    //     this.paymentService.payWithRazor({cart: this.finalObj, payment: options});
    // });
    // options.modal.ondismiss = (() => {
    //     this.loginService.SetLoader = false;
    // });
    options.handler = ((response) => {

      this.apiCall.setPaymentStatus(response).subscribe((result) => {
        result = result;
        if (result['message'] === "success" && result['body'] === "payment is success") {
          this.navigateTo();
        }

      })
    });
    let rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();



    //   var rzp1 = new this.winRef.nativeWindow.Razorpay(options);
    //document.getElementById('rzp-button1').onclick = function(e){
    //  rzp1.open();
    // e.preventDefault();
    //}
  }

  navigateTo() {
    // this.router.navigate(['../payment-confirmed'], {
    //   relativeTo: this.route,
    this.ngZone.run(() => this.router.navigate(['../payment-confirmed'], { relativeTo: this.route, }))
  };
}





