import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavserviceService {

  private messageSource4 = new BehaviorSubject<boolean>(false);
  apply = this.messageSource4.asObservable();
  private messageSource = new BehaviorSubject<boolean>(false);
  increase = this.messageSource.asObservable();

  constructor() { }

  sidenavToggled(message: boolean) {
    this.messageSource4.next(message);
  }

  increaseWidth(value) {
    this.messageSource.next(value);
  }

}
