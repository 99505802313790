<div class="qb-header">
    <div class="qb-store-info">{{'POS_PAGE.ON_BOARDING_MERCHANTS' | i18nTranslate}}</div>
  </div>
<mat-accordion>
  <mat-expansion-panel (opened)="openPanel(store['id'])" *ngFor="let store of stores">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{store['storeName']}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div> 
      <div class="qb-main">
        <div class="qb-store-details">
          <div style="width: 40%">
          <div class="qb-label">{{'POS_PAGE.MERCHANT_EMAIL_ID' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['chainEmail']" placeholder="Merchant Email ID"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.MERCHANT_NAME' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['chainName']" placeholder="Merchant Name"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.MERCHANT_PHONE_NUMBER' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['chainManagerPhone']" placeholder="Merchant Phone Number"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.CATEGORY' | i18nTranslate}}</div>
          <div class="qb-input-field qb-category-dropdown-div">
              <mat-select [(ngModel)]="storeDetails['chainCategory']">
                <mat-option *ngFor="let category of allCategories" [value]="category['categoryName']">{{category["categoryName"]}}</mat-option>
              </mat-select>
          </div>
          <div class="qb-label">{{'POS_PAGE.STORE_MANAGER_NAME' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['storeManagerName']" placeholder="Store Manager Name"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.STORE_MANAGER_EMAIL_ID' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['storeEmail']" placeholder="Store Manager Email ID" 
              class="qb-input"
              type="text"
            />
          </div>
          </div>
          <div style="width: 40%">
            <div class="qb-label">{{'POS_PAGE.SHOP_NAME' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['storeName']" placeholder="Shop Name"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.STORE-MANAGER_PHONE' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['storeManagerPhone']" placeholder="Store Manager Phone Number"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.ADDRESS' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <div class="search-div">
              <div style="width: 100%">
                <input type="text"
                placeholder="Address"
                class="qb-input"
                (ngModelChange)="changeAddress($event)"
                 [(ngModel)]="storeDetails['locationDescription']"
               >
              </div>
            </div>
            <div class="dropdown-div customers-div" *ngIf="showAddressDiv">
              <div *ngFor="let address of addresses">
              <div style="font-size: 14px; width: 100%" (click)="storeDetails['locationDescription']=address['description'];storeDetails['placeId']=address['placeId'];showAddressDiv = false;">
                <div style="display: flex" class="customers">
                    <div style="min-width: 170px;">
                      {{address["description"]}}
                    </div>
                </div>
              </div>
              </div>
            </div>
            </div>
            <div class="qb-label">{{'POS_PAGE.LATITUDE' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['latitude']" placeholder="Latitude"
              class="qb-input"
              type="text"
            />
          </div>
          <div class="qb-label">{{'POS_PAGE.LONGITUDE' | i18nTranslate}}</div>
          <div class="qb-input-field">
            <input [(ngModel)]="storeDetails['longitude']" placeholder="Longitude"
              class="qb-input"
              type="text"
            />
          </div>
          <div style="display: flex;gap: 25px;
          margin-top: 35px;">
          <div class="qb-label">
          <a [href]="storeDetails['logoUrl']" target="_blank" class="qb-label">{{'POS_PAGE.STORE_MAIN_IMAGE' | i18nTranslate}}</a>
          </div>
          <div class="qb-label">
          <a [href]="storeDetails['shopThumbnailUrl']" target="_blank" class="qb-label">{{'POS_PAGE.OTHER_STORE_IMAGES' | i18nTranslate}}</a>
          </div>
          <div class="qb-label">
          <a [href]="storeDetails['upiQRCodeUrl']" target="_blank" class="qb-label">{{'POS_PAGE.QR_CODE_IMAGE' | i18nTranslate}}</a>
          </div>
          </div>
          </div>
          </div>
          <div class="qb-buttons">
            <div class="primary-button" (click)="update('Update')">{{'POS_PAGE.UPDATE' | i18nTranslate}}</div>
            <div class="primary-button" (click)="update('Activate')" *ngIf="!storeDetails['isQfferEnabled']">{{'POS_PAGE.ACTIVATE' | i18nTranslate}}</div>
          </div>
        </div>      
    </div>
  </mat-expansion-panel>
</mat-accordion>
<mat-paginator
          [pageIndex]="0"
          [length]="totalNumberOfStores"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="getNextData($event)"
        >
        </mat-paginator>