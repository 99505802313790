import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-campaign-fullscreen',
  templateUrl: './campaign-fullscreen.component.html',
  styleUrls: ['./campaign-fullscreen.component.css']
})
export class CampaignFullscreenComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CampaignFullscreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apicall: ApiCallService,
  ) { }
  nodeList = [];
  parameter = [];
  zoomAmount = 70;

  ngOnInit(): void {
    this.nodeList = this.data['data'];
    console.log("DATAtaTATAT", this.nodeList)
    this.prefillData();
  }

  async prefillData(){
    let parent = document.getElementById("canvas-fullscreen");
    let count_offer = 1;
    let count_audience = 1;
    let count_AB = 1;
    let count_email = 1;
    let count_survey = 1;
    let count_delay = 1;
    let count_reposne = 1;

    for(let nodeData in this.nodeList){
      let data = this.nodeList[nodeData]
      let previousNodeIndex = this.nodeList.findIndex(
        (x) => x.nodeId === data['previousNodeId']
      );
      if(data['nodeType'] === 'TRIGGER'){
        let i : any = {};
        i.displayname ="Journey Trigger";
        i.data = data;
        this.parameter.push(i);
        let inputWraper=document.createElement("div");
        // inputWraper.setAttribute("style","width:300px")
        inputWraper.setAttribute("id","fullscreen-"+data['nodeBlockId'])
        let wrapper = `
                <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                    <div class="node-kpi">
                        <div class="node-block-kpi trigger-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                            <div class="node-header-kpi">
                                <span class="material-icons-outlined node-header-icon-kpi icons-primary">play_circle</span>
                                <span>Journey Trigger</span>
                            </div>
                        </div>
                    </div>
                    <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                </div>
        `;
      //   inputWraper.addEventListener("click", (event) =>
      //   this.testClick(data)
      // );
        inputWraper.innerHTML = wrapper
        parent.append(inputWraper)
        // parent.insertAdjacentElement(
        //   "afterend",
        //   inputWraper
        // );
      }
      if(previousNodeIndex >-1){
        let parent1;
        if(data['previousNodeRelation'] === "NEXT" && this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'UNBRANCHED'){
          parent1 = document.getElementById("fullscreen-"+this.nodeList[previousNodeIndex]['nodeBlockId']);          
        }
        if(data['previousNodeRelation'] === "NEXT" && this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'RESPONSE_EVENT'){
          let connector_response = document.getElementById("fullscreen-left-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi" id="fullscreen-left-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.insertAdjacentElement(
            "afterend",
            inputWraper1
          );
          parent1 = document.getElementById("fullscreen-left-branch-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
        }
        if(data['previousNodeRelation'] === "TIMEOUT" && this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'RESPONSE_EVENT'){
          let connector_response = document.getElementById("fullscreen-right-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi" id="fullscreen-right-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.insertAdjacentElement(
            "afterend",
            inputWraper1
          );
          parent1 = document.getElementById("fullscreen-right-branch-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
        }
        if(this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'ABTESTING'){
          let i = data.previousNodeRelation.substring(6,(data.previousNodeRelation.length))
          let connector_response = document.getElementById("fullscreen-ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi-ab" id="fullscreen-right-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.append(
            inputWraper1
          );
          parent1 = document.getElementById("fullscreen-ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
        } 
        if(this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'AUDIENCESPLIT'){
          let i = data.previousNodeRelation.substring(7,(data.previousNodeRelation.length))
          let connector_response = document.getElementById("fullscreen-ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi-ab" id="fullscreen-right-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.append(
            inputWraper1
          );
          parent1 = document.getElementById("fullscreen-ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
        } 
        let inputWraper=document.createElement("div");
        // inputWraper.setAttribute("style","width:300px")

        inputWraper.setAttribute("id","fullscreen-"+data['nodeBlockId']);
        if(data['nodeType'] === "CHANNEL"){
          if(data['nodeData']['definition']['channelType'] === 'EMAIL'){
            let i : any = {};
            i.displayname ="Email-"+count_email;
            i.data = data;
            this.parameter.push(i);
            let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi email-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">email</span>
                                  <span>Email</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          count_email = count_email + 1;
          }
        }
        if(data['nodeType'] === "SURVEY"){
          let i : any = {};
          i.displayname ="Survey-"+count_survey;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi survey-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">thumb_up_off_alt</span>
                                  <span>Survey</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          count_survey++;
        }
        if(data['nodeType'] === "OFFER"){
          let i : any = {};
          i.displayname ="Offer-"+count_offer;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi offer-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary"> local_offer </span>
                                  <span>Offer</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          count_offer = count_offer + 1;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          
        }
        if(data['nodeType'] === "WAIT"){
          let i : any = {};
          i.displayname ="Time Delay-"+count_delay;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi delay-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">  timer  </span>
                                  <span>Time Delay</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          count_delay++;
        }
        if(data['nodeType'] === "RESPONSE_EVENT"){
          let i : any = {};
          i.displayname ="If/Else Branch-"+count_reposne;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi delay-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary icon-rotation">   alt_route   </span>
                                  <span>If/Else Condition</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                      <div class="horizontal-connector-kpi" id="fullscreen-horizontal-connector-${data['nodeBlockId']}"></div>                    
                      <div><div class="left-branch-node-kpi" id="fullscreen-left-${data['nodeBlockId']}">YES</div></div>
                      <div><div class="right-branch-node-kpi" id="fullscreen-right-${data['nodeBlockId']}">NO</div></div>
                      <div class="node-branch-kpi">
                        <div class="left-branch"><div id="fullscreen-left-branch-${data['nodeBlockId']}"></div></div>
                        <div class="right-branch"><div id="fullscreen-right-branch-${data['nodeBlockId']}"></div></div>
                      </div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
         

          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );

          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          // let connector_response = document.getElementById("right-"+data['nodeBlockId']);
          // let inputWraper1=document.createElement("div");
          // let wrapper1 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper1.innerHTML = wrapper1;
          // connector_response.insertAdjacentElement(
          //   "afterend",
          //   inputWraper1
          // );

          // let connector_response1 = document.getElementById("left-"+data['nodeBlockId']);
          // let inputWraper11=document.createElement("div");
          // let wrapper11 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper11.innerHTML = wrapper11;
          // connector_response1.insertAdjacentElement(
          //   "afterend",
          //   inputWraper11
          // );
          count_reposne++;
        }
        if(data['nodeType'] === "AUDIENCESPLIT"){
          let i : any = {};
          i.displayname ="Audience Split -"+count_audience;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi" id="fullscreen-node-kpi-${data['nodeBlockId']}">
                          <div class="node-block-kpi delay-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">   group   </span>
                                  <span>Audience Split</span>
                              </div>
                          </div>
                          <div class="qb-d-flex">
                            <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                          </div>
                      </div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          let node_branch = document.getElementById("fullscreen-node-kpi-"+data['nodeBlockId'])
          let pathGroupData=data.nodeData.definition.groupName
          // node_branch.style.width=`${(pathGroupData.length)*(355)}px`;
          let container = document.createElement("div");
              container.setAttribute("class","node-branch-kpi");

              let Abwrapper = document.createElement("div");
            Abwrapper.setAttribute("id","fullscreen-abtest-wrapper-"+data['nodeBlockId']);
            // Abwrapper.setAttribute("style","display:flex")
            Abwrapper.style.width=`${(pathGroupData.length)*(350)}px`;
            container.append(Abwrapper);

            let branchNodePath = document.createElement("div");
            branchNodePath.setAttribute("class","ab-branch-node-path-kpi");
            branchNodePath.setAttribute("id", "fullscreen-ab-" +data['nodeBlockId']+"-path-ab");
            Abwrapper.append(branchNodePath);

            
          for (var k in pathGroupData){ 
            if (pathGroupData.hasOwnProperty(k)) {
              let sub_container = document.createElement("div");
              sub_container.setAttribute("class","split-branch");
              sub_container.setAttribute("id","fullscreen-split-"+pathGroupData[k]);
              branchNodePath.append(sub_container);
              let mainWraper = document.createElement("div")
              mainWraper.style.position="relative";
              mainWraper.style.display="flex";
              mainWraper.style.width = "106px";
              mainWraper.style.flexDirection = "column";
              mainWraper.style.alignItems = "center";
              mainWraper.style.marginLeft = "98px"
              if(pathGroupData[k] != "Everyone else")
              mainWraper.setAttribute("id", "fullscreen-ab-"+data['nodeBlockId']+"-path-"+pathGroupData[k])
              else
              mainWraper.setAttribute("id", "fullscreen-ab-"+data['nodeBlockId']+"-path-others")

              let inputWraper=document.createElement("div");
              if(pathGroupData[k] != "Everyone else")
              inputWraper.setAttribute("id", "fullscreen-split-block-"+data['nodeBlockId']+"-path-"+pathGroupData[k])
              else
              inputWraper.setAttribute("id", "fullscreen-split-block-"+data['nodeBlockId']+"-path-others")
              inputWraper.style.background="#065D72";
              inputWraper.style.display="flex";
              inputWraper.style.width="110px";
              inputWraper.style.position="relative";
              inputWraper.style.padding="5px";
              inputWraper.style.color="#FFFFFF";
              inputWraper.style.justifyContent="center";
              inputWraper.style.borderRadius="4px";
              let wrapContent = `<div class="dynamic-split-vertical-kpi"></div> ${pathGroupData[k]}`;
              // inputElement.setAttribute("type", "number");
              // inputElement.setAttribute("class","abtest-input-area "+k);
              // inputElement.setAttribute("style","width: 48px;")
              // inputElement.value=pathGroupData[k];
              inputWraper.innerHTML = wrapContent
              mainWraper.append(inputWraper)
              sub_container.append(mainWraper)
              node_branch.insertAdjacentElement(
                "afterend",
                container
              );
            }
          }
          let horizontalLine = document.getElementById("fullscreen-ab-" +data['nodeBlockId']+"-path-ab")
          let horizontalLineContent = `<div class="horizaontal-node-connector-kpi-split" id="fullscreen-horizontal-connector-${data['nodeBlockId']}"></div>`
          let input = document.createElement("div");

          let count = this.getNumberOfNodeCount(data['nodeId'], pathGroupData);
          input.innerHTML = horizontalLineContent;
          horizontalLine.append(input);
          
          let horizontalLength = document.getElementById("fullscreen-horizontal-connector-"+data['nodeBlockId']);
          // if(count === 0){
          //   horizontalLength.setAttribute("style","width: calc(100% - 350px)")
          // }
          // else{
          //   horizontalLength.setAttribute("style","width: calc(100% - 445px)")
          // }

          horizontalLength.setAttribute("style","width: calc(100% - "+count+"px)")
          // let connector_response = document.getElementById("right-"+data['nodeBlockId']);
          // let inputWraper1=document.createElement("div");
          // let wrapper1 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper1.innerHTML = wrapper1;
          // connector_response.insertAdjacentElement(
          //   "afterend",
          //   inputWraper1
          // );

          // let connector_response1 = document.getElementById("left-"+data['nodeBlockId']);
          // let inputWraper11=document.createElement("div");
          // let wrapper11 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper11.innerHTML = wrapper11;
          // connector_response1.insertAdjacentElement(
          //   "afterend",
          //   inputWraper11
          // );
          count_audience++;
        }
        if(data['nodeType'] === "ABTESTING"){
          let i : any = {};
          i.displayname ="A/B Testing -"+count_AB;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="fullscreen-container-${data['nodeBlockId']}">
                      <div class="node-kpi" id="fullscreen-node-kpi-${data['nodeBlockId']}">
                          <div class="node-block-kpi delay-node-block" id ="fullscreen-node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary icon-rotation">   alt_route   </span>
                                  <span>A/B Testing</span>
                              </div>
                          </div>
                          <div class="qb-d-flex">
                            <div class="node-connector-kpi" id="fullscreen-connector-${data['nodeBlockId']}"></div>
                          </div>
                      </div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("fullscreen-node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          let node_branch = document.getElementById("fullscreen-node-kpi-"+data['nodeBlockId'])
          let pathGroupData=data.nodeData.definition.pathGroups
          // node_branch.style.width=`${(pathGroupData.length)*(355)}px`;
          let container = document.createElement("div");
              container.setAttribute("class","node-branch-kpi");
            let Abwrapper = document.createElement("div");
            Abwrapper.setAttribute("id","fullscreen-abtest-wrapper-"+data['nodeBlockId']);
            // Abwrapper.setAttribute("style","display:flex")
            let countPath = this.getPathLength(pathGroupData)
            Abwrapper.style.width=`${(countPath)*(350)}px`;
            // Abwrapper.style.minWidth=`340px`;
            container.append(Abwrapper);

            let branchNodePath = document.createElement("div");
            branchNodePath.setAttribute("class","ab-branch-node-path-kpi");
            branchNodePath.setAttribute("id", "fullscreen-ab-" +data['nodeBlockId']+"-path-ab");
            Abwrapper.append(branchNodePath);

          for (var k in pathGroupData){ 
            if (pathGroupData.hasOwnProperty(k)) {
              
              let sub_container = document.createElement("div");
              sub_container.setAttribute("class","split-branch");
              sub_container.setAttribute("id","fullscreen-split-"+data['nodeBlockId']+"-"+k);
              branchNodePath.append(sub_container);
              let mainWraper = document.createElement("div")
              mainWraper.style.position="relative";
              mainWraper.style.display="flex";
              mainWraper.style.width = "106px";
              mainWraper.style.flexDirection = "column";
              mainWraper.style.alignItems = "center";
              mainWraper.style.marginLeft = "98px"
              mainWraper.setAttribute("id", "fullscreen-ab-"+data['nodeBlockId']+"-path-"+k)

              let inputWraper=document.createElement("div");
              inputWraper.setAttribute("id", "fullscreen-ab-block-"+data['nodeBlockId']+"-path-"+k)
              inputWraper.style.background="#6165D7";
              inputWraper.style.display="flex";
              inputWraper.style.width="120px";
              inputWraper.style.position="relative";
              inputWraper.style.padding="5px";
              inputWraper.style.borderRadius="4px";
              inputWraper.style.color="#FFFFFF";
              let value = pathGroupData[k]['weight']
              let wrapContent = `<div class="dynamic-split-vertical-kpi"></div><input type="number" class="abtest-input-area ${k}" style="width: 48px;" value="${value}"/> <div class="qb-path-name">&nbsp; ${k}</div>`;
              // let inputElement = document.createElement("input");
              // inputElement.setAttribute("type", "number");
              // inputElement.setAttribute("class","abtest-input-area "+k);
              // inputElement.setAttribute("style","width: 48px;")
              // inputElement.value=pathGroupData[k];
              inputWraper.innerHTML = wrapContent
              mainWraper.append(inputWraper)
              sub_container.append(mainWraper)
              node_branch.insertAdjacentElement(
                "afterend",
                container
              );
            }
          }
          let horizontalLine = document.getElementById("fullscreen-ab-" +data['nodeBlockId']+"-path-ab")
          let horizontalLineContent = `<div class="horizaontal-node-connector-kpi-test" id="fullscreen-horizontal-connector-${data['nodeBlockId']}"></div>`
          let input = document.createElement("div");
          input.innerHTML = horizontalLineContent;

          let count = this.getNumberOfNodeCount(data['nodeId'], pathGroupData);

          
          horizontalLine.append(input);
          let horizontalLength = document.getElementById("fullscreen-horizontal-connector-"+data['nodeBlockId']);
          // if(count === 0){
          //   horizontalLength.setAttribute("style","width: calc(100% - 350px)")
          // }
          // if(count<0){
          //   horizontalLength.setAttribute("style","width: calc(100% + 50px)")
          // }
          // else{
          //   horizontalLength.setAttribute("style","width: calc(100% - 445px)")
          // }
          horizontalLength.setAttribute("style","width: calc(100% - "+count+"px)")

          // let connector_response = document.getElementById("right-"+data['nodeBlockId']);
          // let inputWraper1=document.createElement("div");
          // let wrapper1 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper1.innerHTML = wrapper1;
          // connector_response.insertAdjacentElement(
          //   "afterend",
          //   inputWraper1
          // );

          // let connector_response1 = document.getElementById("left-"+data['nodeBlockId']);
          // let inputWraper11=document.createElement("div");
          // let wrapper11 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper11.innerHTML = wrapper11;
          // connector_response1.insertAdjacentElement(
          //   "afterend",
          //   inputWraper11
          // );
          count_AB++;
        }
      }
      if(this.nodeList.length-1 === parseInt(nodeData) && this.getNodeBranchType(data['nodeType']) === 'UNBRANCHED'){
        let connector = document.getElementById("fullscreen-connector-"+data['nodeBlockId']);
        connector.setAttribute("style", "display:none");
        for(let k in this.nodeList){
          console.log("K",k)
          let previousNodeIndex = this.nodeList.findIndex(
            (x) => x.previousNodeId === this.nodeList[k]['nodeId']
          );
          if(previousNodeIndex === -1){
            let connector = document.getElementById("fullscreen-connector-"+this.nodeList[k]['nodeBlockId']);
            connector.setAttribute("style", "display:none");
          }
        }
      }
    }
  }
  getNodeBranchType(nodeType) {
    switch (nodeType) {
      case "WAIT":
      case "CHANNEL":
      case "OFFER":
      case "SURVEY":
      case "TRIGGER":
        return "UNBRANCHED";
      case "RESPONSE_EVENT":
        return "RESPONSE_EVENT"
      case "ABTESTING":
        return "ABTESTING"
      case "AUDIENCESPLIT":
        return "AUDIENCESPLIT";
        // return "GROUPBRANCH"
    }
  }
  getNumberOfNodeCount(nodeId, data){
    let count = 0;
    let subCount = 0;
    let totalCount = 0;
    let branchedNodeCount = 0;
    let subbranchCount = 0;
    let noOfBranch = this.getPathLength(data)
    for(let i=0;i<this.nodeList.length;i++){
      if(this.nodeList[i]['previousNodeId'] === nodeId){
        totalCount++;
        if(this.nodeList[i]['nodeType'] === 'ABTESTING' || this.nodeList[i]['nodeType'] ==='AUDIENCESPLIT' || this.nodeList[i]['nodeType'] === 'RESPONSE_EVENT'){
          branchedNodeCount++;
          let split = this.nodeList[i]['previousNodeRelation'].substring(7,(this.nodeList[i]['previousNodeRelation'].length))
          let path = this.nodeList[i]['previousNodeRelation'].substring(6,(this.nodeList[i]['previousNodeRelation'].length))
          let pathgroupData = (this.nodeList[i]['previousNodeId'])
          let index = this.nodeList.findIndex(
            (x) => x.nodeId === pathgroupData
          );
          if(split !== 'others' && path !='Path-'+(this.getPathLength(this.nodeList[index]['nodeData']['definition']['pathGroups']))){
            count++; 
          }
        }
        else{
          let check = false;
          let previous = this.nodeList.findIndex(
            (x) => x.previousNodeId === this.nodeList[i]['nodeId']
          );
          while(previous > -1){
              if(this.nodeList[previous]['nodeType'] ==='ABTESTING' || this.nodeList[previous]['nodeType'] ==='AUDIENCESPLIT' || this.nodeList[previous]['nodeType'] === 'RESPONSE_EVENT'){
                branchedNodeCount++;
                let split = this.nodeList[i]['previousNodeRelation'].substring(7,(this.nodeList[i]['previousNodeRelation'].length))
                let path = this.nodeList[i]['previousNodeRelation'].substring(6,(this.nodeList[i]['previousNodeRelation'].length))
                let pathgroupData = (this.nodeList[i]['previousNodeId'])
                let index = this.nodeList.findIndex(
                  (x) => x.nodeId === pathgroupData
                );
                if(split !== 'others' && path !='Path-'+(this.getPathLength(this.nodeList[index]['nodeData']['definition']['pathGroups']))){
                  count++;
                  if(this.nodeList[previous]['nodeType'] === 'ABTESTING')
                    subbranchCount = this.getPathLength(this.nodeList[previous]['nodeData']['definition']['pathGroups'])
                  else if(this.nodeList[previous]['nodeType'] === 'AUDIENCESPLIT')
                    subbranchCount = this.getPathLength(this.nodeList[previous]['nodeData']['definition']['groupName'])
                }
                check = true;
              }
              previous = this.nodeList.findIndex(
                (x) => x.previousNodeId === this.nodeList[previous]['nodeId']
              );
          }
          if(check === false)
            subCount++;
        }
      }
    }

    if(subCount === totalCount && noOfBranch === totalCount){
      return 350;
    }
    if(subCount === totalCount && totalCount === 0){
      return 350;
    }
    if(subCount === totalCount && noOfBranch !== totalCount && totalCount !=0){
      return 400;
    }
    if(count === totalCount && noOfBranch === totalCount && subbranchCount<3){
      return -50
    }
    if(count === totalCount && count === branchedNodeCount && subbranchCount<3){
      return -50
    }
    if(branchedNodeCount === totalCount && count>0 && branchedNodeCount != count && subbranchCount<3){
      return -50
    }
    if(count === totalCount && noOfBranch !== totalCount && subbranchCount<3){
      return 350
    }
    if(subbranchCount >2){
        if(subbranchCount === 3){
          return -400
        }
        else{
          let value = 650 +((subbranchCount - 2)*50);
          return (-value)
        }
    }
    else
      return 350;
  }
  getPathLength(data){
    let count = 0;
    for(let k in data){
      count++;
    }
    return count;
  }

  magnify(type) {
    // this.hideAllEvents();
    let canvas = document.getElementById("canvas-fullscreen");
    if (type === "ADD") {
      if (this.zoomAmount < 150) {
        this.zoomAmount += 10;
        canvas.style.transform = "scale(" + this.zoomAmount / 100 + ")";
        canvas.style.transformOrigin = "left top";
      }
    } else {
      if (this.zoomAmount > 30) {
        this.zoomAmount -= 10;
        canvas.style.transform = "scale(" + this.zoomAmount / 100 + ")";
        canvas.style.transformOrigin = "left top";
      }
    }
  }
  close(decision){
    this.dialogRef.close(decision);
  }
}
