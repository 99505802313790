<div class="qb-body">
  <div class="qb-main-div">
    <div>{{'PARTNERSHIP_PAGE.OFFER_DETAILS_PARTNER_CUSTOMER' | i18nTranslate}}</div>
    <div class="qb-offer-div">
      <div class="qb-input-label">{{'PARTNERSHIP_PAGE.OFFER_NAME' | i18nTranslate}}</div>
      <div class="qb-input-div">
        <input
          type="text"
          placeholder="SPRINGSALE"
          [(ngModel)]="request.partnershipOfferName"
        />
      </div>
      <div
        class="qb-error"
        *ngIf="
          request.partnershipOfferName.split(' ').join('').length < 3 &&
          this.submitClicked
        "
      >
        {{'PARTNERSHIP_PAGE.MIN_CHARACTER_3' | i18nTranslate}}
      </div>
      <div
        class="qb-error"
        *ngIf="
          request.partnershipOfferName.split(' ').join('').length > 25 &&
          this.submitClicked
        "
      >
        {{'PARTNERSHIP_PAGE.MAX_CHARACTER_25' | i18nTranslate}}
      </div>
      <div
        class="qb-error"
        *ngIf="request.partnershipOfferName.split(' ').join('').length != 0"
      >
        {{ offerNameError }}
      </div>
    </div>
    <div class="qb-offer-div">
      <div class="qb-discount-label-div">
        <div class="qb-label-div">
          <div class="qb-input-label">{{'PARTNERSHIP_PAGE.DISCOUNT_CODE' | i18nTranslate}}</div>
          <div
            class="qb-i-div"
            type="button"
            [ngbPopover]="popContent"
            triggers="mouseenter:mouseleave"
          >
            <ng-template #popContent>
              <div class="qb-i-text">
                {{'PARTNERSHIP_PAGE.DISCOUNT_CODE_INFO' | i18nTranslate}}
              </div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
        </div>
        <div class="qb-input-div qb-discount-div">
          <div class="qb-discount-default">[PartnerName]-</div>
          <input
            type="text"
            class="qb-discount-code-input"
            placeholder="SPRING20"
            [(ngModel)]="request.discountCoupon"
            (ngModelChange)="request.discountCoupon = $event.toUpperCase()"
          />
        </div>
        <div
          class="qb-error"
          *ngIf="
            request.discountCoupon.split(' ').join('').length < 3 &&
            this.submitClicked
          "
        >
          {{'PARTNERSHIP_PAGE.MIN_CHARACTER_3' | i18nTranslate}}
        </div>
        <div
          class="qb-error"
          *ngIf="
            request.discountCoupon.split(' ').join('').length > 25 &&
            this.submitClicked
          "
        >
          {{'PARTNERSHIP_PAGE.MAX_CHARACTER_25' | i18nTranslate}}
        </div>
      </div>
    </div>
    <div class="qb-discount-types">{{'PARTNERSHIP_PAGE.DISCOUNT_TYPES' | i18nTranslate}}</div>
    <div>
      <mat-checkbox
        class="qb-discount-type"
        value="PERCENTAGE_OFF"
        (change)="offerJSON.discountType = 'PERCENTAGE_OFF'"
        [checked]="offerJSON.discountType === 'PERCENTAGE_OFF'"
        >{{'PARTNERSHIP_PAGE.PERCENTAGE' | i18nTranslate}}</mat-checkbox
      >
    </div>
    <div class="qb-discount-label-div">
      <mat-checkbox
        class="qb-discount-type"
        value="FIXED_AMOUNT_OFF"
        (change)="offerJSON.discountType = 'FIXED_AMOUNT_OFF'"
        [checked]="offerJSON.discountType === 'FIXED_AMOUNT_OFF'"
        >{{'PARTNERSHIP_PAGE.FIXED_AMT' | i18nTranslate}}</mat-checkbox
      >
    </div>
    <div class="qb-discount-types value">{{'PARTNERSHIP_PAGE.VALUE' | i18nTranslate}}</div>
    <div>
      <div class="qb-input-label">{{'PARTNERSHIP_PAGE.DISCOUNT_VALUE' | i18nTranslate}}</div>
      <div *ngIf="offerJSON.discountType === 'PERCENTAGE_OFF'" class="qb-input-div">
        <input type="number" [(ngModel)]="offerJSON.discountValue" />
        <div class="qb-percentage">%</div>
      </div>
      <div
        *ngIf="offerJSON.discountType === 'FIXED_AMOUNT_OFF'"
        class="qb-input-div"
      >
        <div>{{ currencySymbol }}</div>
        <input type="text" [(ngModel)]="offerJSON.discountValue" />
      </div>
      <div
        class="qb-error"
        *ngIf="offerJSON.discountValue <= 0 && this.submitClicked"
      >
        {{'PARTNERSHIP_PAGE.DISCOUNT_VALUE_CANNOT_BE_ZERO' | i18nTranslate}}
      </div>
    </div>
    <div class="qb-applies-to">{{'PARTNERSHIP_PAGE.APPLIES_TO' | i18nTranslate}}</div>
    <div class="qb-radio-div">
      <mat-radio-button
        class="qb-discount-type"
        value="ALL_PRODUCTS"
        (change)="offerJSON.appliesTo = 'ALL_PRODUCTS'; searchText = ''"
        [checked]="offerJSON.appliesTo === 'ALL_PRODUCTS'"
        >{{'PARTNERSHIP_PAGE.ALL_PRODUCTS' | i18nTranslate}}</mat-radio-button
      >
    </div>
    <div class="qb-radio-div">
      <mat-radio-button
        class="qb-discount-type"
        value="SPECIFIC_CATEGORIES"
        (change)="offerJSON.appliesTo = 'SPECIFIC_CATEGORIES'; searchText = ''"
        [checked]="offerJSON.appliesTo === 'SPECIFIC_CATEGORIES'"
        >{{'PARTNERSHIP_PAGE.SPECIFIC_CATEGORY' | i18nTranslate}}</mat-radio-button
      >
    </div>
    <div class="qb-radio-div">
      <mat-radio-button
        class="qb-discount-type"
        value="SPECIFIC_PRODUCTS"
        (change)="offerJSON.appliesTo = 'SPECIFIC_PRODUCTS'; searchText = ''"
        [checked]="offerJSON.appliesTo === 'SPECIFIC_PRODUCTS'"
        >{{'PARTNERSHIP_PAGE.SPECIFIC_PRODUCTS' | i18nTranslate}}</mat-radio-button
      >
    </div>
    <div *ngIf="offerJSON.appliesTo != 'ALL_PRODUCTS'" class="qb-radio-input">
      <div class="qb-input-div qb-radio-input-div">
        <input
          type="text"
          placeholder="Search by name (Minimum 3 characters)"
          [(ngModel)]="searchText"
          (keyup)="browse(offerJSON.appliesTo, 'PRODUCT')"
        />
        <div class="material-icons-outlined" *ngIf="searchText.length == 0">
          search
        </div>
        <span
          class="material-icons-outlined icons-primary cursor-pointer"
          class="qb-close-icon"
          *ngIf="searchText.length > 0"
          (click)="searchText = ''"
        >
          close
        </span>
      </div>
      <div
        class="secondary-button-border qb-browse-button"
        (click)="browse(offerJSON.appliesTo, 'PRODUCT', null)"
      >
        {{'PARTNERSHIP_PAGE.BROWSE' | i18nTranslate}}
      </div>
    </div>
    <div
      class="qb-error"
      *ngIf="
        selectedBarrierCollectionList.length == 0 &&
        submitClicked &&
        offerJSON.appliesTo === 'SPECIFIC_CATEGORIES'
      "
    >
      {{'PARTNERSHIP_PAGE.SELECT_SPECIFIC_CATEGORIES' | i18nTranslate}}
    </div>
    <div
      class="qb-error"
      *ngIf="
        selectedBarrierProductList.length == 0 &&
        submitClicked &&
        offerJSON.appliesTo === 'SPECIFIC_PRODUCTS'
      "
    >
      {{"PARTNERSHIP_PAGE.SELECT_SPECIFIC_PRODUCTS" | i18nTranslate}}
    </div>
    <div
      *ngIf="offerJSON.appliesTo === 'SPECIFIC_PRODUCTS'"
      class="qb-products-div"
    >
      <div
        *ngFor="let product of selectedBarrierProductList"
        class="qb-products"
      >
        <div>
          <span>
            <mat-icon class="qb-bullet-point">fiber_manual_record</mat-icon>
          </span>
          <span *ngIf="product.variantName != product.parentProductName"
            >{{ product.parentProductName }} ({{ product.variantName }})</span
          >
          <span *ngIf="product.variantName === product.parentProductName">{{
            product.variantName
          }}</span>
        </div>
        <div>
          <span
            class="material-icons qb-remove-btn cursor-pointer"
            (click)="removeEntry(product.variantId)"
          >
            cancel
          </span>
        </div>
      </div>
    </div>
    <div
      *ngIf="offerJSON.appliesTo === 'SPECIFIC_CATEGORIES'"
      class="qb-products-div"
    >
      <div
        *ngFor="let product of selectedBarrierCollectionList; let i = index"
        class="qb-products"
      >
        <div>
          <span>
            <mat-icon class="qb-bullet-point">fiber_manual_record</mat-icon>
          </span>
          <span>{{ product.collectionName }}</span>
        </div>
        <div>
          <span
            class="material-icons qb-remove-btn cursor-pointer"
            (click)="removeEntry(product.collectionId)"
          >
            cancel
          </span>
        </div>
      </div>
    </div>
    <div class="qb-validity">{{'PARTNERSHIP_PAGE.COUPON_OFFER_DISPLAY_VALIDITY' | i18nTranslate}}</div>
    <div class="qb-label-div">
      <div class="qb-input-label">{{'PARTNERSHIP_PAGE.COUPON_VALID_TILL' | i18nTranslate}}</div>
    </div>
    <div class="qb-input-div">
      <input
        [(ngModel)]="couponValidity"
        [min]="todayDate"
        [matDatepicker]="picker1"
        placeholder='Choose a Date'
        [ngStyle]="couponValidity ? { opacity: 0 , width: '0px' } : null" 
      />
      <div style="width: 90%;">
        {{couponValidity | date: getDateFormat() }}
    </div>
      <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
      <mat-datepicker #picker1 [touchUi]="true"></mat-datepicker>
    </div>
    <div
      class="qb-error"
      *ngIf="request.couponValidity === '' && this.submitClicked"
    >
      {{'PARTNERSHIP_PAGE.FIELD_REQ' | i18nTranslate}}
    </div>
    <div class="qb-label-div qb-partner-display">
      <div class="qb-input-label">{{'PARTNERSHIP_PAGE.PARTNER_DISPLAY_TILL' | i18nTranslate}}</div>
    </div>
    <div class="qb-input-div">
      <input
        [(ngModel)]="offerDisplayValidy"
        [min]="todayDate"
        [max]="couponValidity"
        [matDatepicker]="picker2"
        placeholder='Choose a Date'
        [ngStyle]="offerDisplayValidy ? { opacity: 0 , width: '0px' } : null" 
      />
      <div style="width: 90%;">
        {{offerDisplayValidy | date: getDateFormat() }}
    </div>
      <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
      <mat-datepicker #picker2 [touchUi]="true"></mat-datepicker>
    </div>
    <div
      class="qb-error"
      *ngIf="request.offerDisplayValidy === '' && this.submitClicked"
    >
      {{'PARTNERSHIP_PAGE.FIELD_REQ' | i18nTranslate}}
    </div>
    <div class="qb-buttons-div">
      <div
        class="secondary-button-border qb-cancel-button"
        (click)="rerouteToMyOffers()"
      >
        {{'PARTNERSHIP_PAGE.CANCEL' | i18nTranslate}}
      </div>
      <div class="primary-button" (click)="checkValidityDates()">
        {{'PARTNERSHIP_PAGE.SAVE_CONTINUE' | i18nTranslate}}
      </div>
    </div>
  </div>
  <div class="qb-main-div">
    <div>{{'PARTNERSHIP_PAGE.SUMMARY' | i18nTranslate}}</div>
    <div class="qb-coupon">{{ request.partnershipOfferName }}</div>
    <div>
      <ul>
        <li class="qb-list-item" *ngIf="request.discountCoupon !== ''">
          {{'PARTNERSHIP_PAGE.DISCOUNT_CODE' | i18nTranslate}} {{ request.discountCoupon?.toUpperCase() }}
        </li>
        <li
          class="qb-list-item"
          *ngIf="
            offerJSON.discountType === 'PERCENTAGE_OFF' &&
            offerJSON.discountValue > 0
          "
        >
          {{ offerJSON.discountValue }}% of
          {{ offerJSON.appliesTo.replace("_", " ").toLowerCase() }}
        </li>
        <li
          class="qb-list-item"
          *ngIf="
            offerJSON.discountType === 'FIXED_AMOUNT_OFF' &&
            offerJSON.discountValue > 0
          "
        >
          {{ currencySymbol }}{{ offerJSON.discountValue }} of
          {{ offerJSON.appliesTo.replace("_", " ").toLowerCase() }}
        </li>
        <li class="qb-list-item" *ngIf="request.couponValidity !== ''">
          {{'PARTNERSHIP_PAGE.COUPON_VALID_TILL_1' | i18nTranslate}} {{ request.couponValidity }}
        </li>
        <li class="qb-list-item" *ngIf="request.offerDisplayValidy !== ''">
          {{'PARTNERSHIP_PAGE.PARTNER_CAN_DISPLAY_OFFER_TILL' | i18nTranslate}} {{ request.offerDisplayValidy }}
        </li>
      </ul>
    </div>
  </div>
</div>
