import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { OfferAttachEmailDialogComponent } from '../../../offer/pages';
import { HtmlDataDialogComponent, HtmlForEmailTemplatesDialogComponent, SmsPreviewDialogComponent } from 'src/app/shared/components/dialog-box';

@Component({
  selector: 'app-campaign-channel-select',
  templateUrl: './campaign-channel-select.component.html',
  styleUrls: ['./campaign-channel-select.component.css']
})
export class CampaignChannelSelectComponent implements OnInit {
  message: string = "Hola Mundo!";
  emailLayoutJSON;
  offerText1 = "";
  offerText2 = "";
  displayEmailLayoutHTML
  templateName = "templat_name";
  templateName2 = ""
  templateNameSMS = "templateWSMS";
  templateNameSMS2 = "";
  templateNameWhatsApp = "templatewhatsapp";
  templateNameWhatsApp2 = "";
  emailSubject = "";
  emailSubject2 = "";
  selectedChannel=[];
  selectedOfferId = [];
  communication = {};
  channelDetails: any;
  isPos = false;
  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;
  emailId1 = null;
  emailId2 = null;
  offerId;
  appSubscriptionPlan;
  SurveyId;
  layoutId;
  submit = false;
  validWidgets;
  constructor(
    private apiCall: ApiCallService,
    private tokenstorage: TokenStorageService,
    private dialog: MatDialog,
    private snackbar: SnackbarCollection ,
    private router: Router,
    private route: ActivatedRoute,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
  ) { 
    this.getScreenSize();
  }
  @ViewChild("channelSelect") channelSelect: any;
  @Input() id: any;
  @Output() messageEvent = new EventEmitter<any>();
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.SurveyId = this.id;
    this.getWidgets();
    this.getemailLayout();
    // this.getLayoutId();
    if(this.tokenstorage.getMerchantOnlineStore() === 'shopify'){
      this.isPos = false;
    }
    else{
      this.isPos = true;
    }
  }
  sendChannel(){
    this.submit = true;
    if(this.selectedChannel.length === 0){
      console.log("testdtt")
      return;
    }
    else if(this.emailSubject.length==0 && this.selectedChannel.indexOf('email') !== -1){
      return
    }
    else
      this.messageEvent.emit(this.getData());
  }
  getCommunication1(){

  }
  sendcancelMessage() {
    this.messageEvent.emit(this.message);
  }
  selectopenclose(){
    this.channelSelect.open();
  }
  changeSelection(){
    console.log("PEVER",this.channelSelect)
    this.selectedChannel = this.channelSelect.value;
    console.log("SELCTED CHANNEL",this.selectedChannel);
    var value: any = {};
    
  }
  getemailLayout(){
  //   let response =  {
  //     "message": "success",
  //     "body": {
  //         "email": {
  //             "id": 12334,
  //             "layoutName": "Survey Email",
  //             "merchantId": 10393,
  //             "subject": null,
  //             "emailType": "STANDARD",
  //             "lastModifiedTime": "2023-01-11 12:13:56.0",
  //             "createdTime": "2023-01-11 12:13:56.0",
  //             "snapshotUrl": "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/snapshots/prod/27/surv.png",
  //             "recentlyUsedFlag": false,
  //             "layoutHtmlURL": "greyfox-templates-dev/shopify/10393/EMAIL/SURVEY/Survey Email-12334.html",
  //             "layoutJsonURL": "greyfox-templates-dev/shopify/10393/EMAIL/SURVEY/Survey Email-12334.json",
  //             "type": "SURVEY"
  //         },
  //         "sms": null,
  //         "whatsapp": [
  //             {
  //                 "id": 30,
  //                 "name": "Survey Link",
  //                 "merchantId": null,
  //                 "layoutData": "{\n  \"templateName\": \"survey_basic_feedback_link\",\n  \"body\": \"We would like to hear from you on your experience so far. If there's anything else we can do to bring ease and comfort to you, we are all ears. Fill out your response here {{Survey Page Url}}\",\n  \"bodyText\": [\n    [\n      \"Survey Page Url\"\n    ]\n  ],\n  \"header\": \"TEXT\",\n  \"headerText\": \"Feedback\",\n  \"footer\": \"Please type STOP, to stop receiving future messages\",\n  \"buttons\": null\n}",
  //                 "layoutType": "SURVEY",
  //                 "type": "SURVEY_LINK",
  //                 "channel": null,
  //                 "channelType": null,
  //                 "lastModifiedTime": null,
  //                 "createdTime": null,
  //                 "recentlyUsedFlag": false,
  //                 "dltTemplateId": null,
  //                 "status": null
  //             }
  //         ]
  //     }
  // }

    this.apiCall.getSurveyChannelDetails().subscribe((response)=>{
      console.log("RESPONSE",response);
      this.channelDetails = response['body'];
      this.layoutId = this.channelDetails['email']['id'];
      this.emailSubject = "";
      this.templateName = this.channelDetails['email']['layoutName'];
      this.templateNameWhatsApp = this.channelDetails['whatsapp'][0]['name'] ? this.channelDetails['whatsapp'][0]['name'] : null;
      this.templateNameSMS = this.channelDetails['sms'][0]['name'] ? this.channelDetails['sms'][0]['name'] : null;
    });
    // this.apiCall
    //     .getPartnershipFormConfigurations(this.pformId, "TIME_EMAIL", this.s)
    //     .subscribe((response2) => {
    //       this.emailLayoutJSON = response2["body"]["emailJson"];
    //       this.displayEmailLayoutHTML =
    //         '<div class="bloghtml2">' +
    //         response2["body"]["emailHtml"] +
    //         "</div>";
    //       this.templateName = response2["body"]["emailTemplateName"];
    //       this.timeEmailConfig.emailSubject = response2["body"]["subject"];
    //       this.previewData.selectedLayout = response2["body"]["emailHtml"];
    //       this.previewData.templateName =
    //         response2["body"]["emailTemplateName"];
    //       this.offerData.channel = "EMAIL";
    //       this.getOfferDescription();
    //     });
  }
  getOfferDescription() {
    // this.apiCall.getOfferDescription(this.offerData).subscribe((response3) => {
    //   this.offerText = response3["body"]["allOfferDescription"];
    // });
  }
  openwhatsAppPreview(){

  }
  opensmsPreview(){
    let layout : any;
      layout =  (this.channelDetails['sms'][0]['layoutData'])
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout,
        type: "SMS"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  // save(){
  //   let communication = JSON.stringify(this.getCommunication())
  //   this.submitData.communication = communication;
  //   console.log("SUBMIT",this.submitData   )
  //   const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
  //     panelClass: "no-padding-dialog-popup",
  //     width: "20%",
  //     data: {
  //       subject: "Schedule survey?",
  //       message: "",
  //       "data-widget": "SURVEY SCHEDULE",
  //       "data-button": "Confirm: Schedule",
  //       successButtonText: "Confirm",
  //       cancelButtonText: "Cancel",
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.apiCall.scheduleSurvey(this.submitData).subscribe(
  //         (response) => {
  //           // this.messageEvent.emit(this.message);
  //           if (response["message"] === "SUCCESS") {
  //             const dialogRef = this.dialog.open(
  //               SurveySchedulesSuccessComponent,
  //               {
  //                 panelClass: "no-padding-dialog-popup",
  //                 width: "auto",
  //                 disableClose: true,
  //               }
  //             );
  //             dialogRef.afterClosed().subscribe((result) => {
  //               if (result) {
  //                 this.router.navigate(["/app/surveys"], {
  //                   queryParams: { scheduled: true },
  //                 });
  //               }
  //             });
  //           } else {
  //             this.snackbar.openSnackBar("Failed", 2000);
  //           }
  //         },
  //         (err) => {
  //           const dialogRef = this.dialog.open(ContactUsDialogComponent, {
  //             panelClass: "no-padding-dialog-popup",
  //             height: "auto",
  //             disableClose: true,
  //             width: "380px",
  //             data: {
  //               header: "Error",
  //               body: err.error["body"],
  //             },
  //           });
  //           dialogRef.afterClosed().subscribe((result) => {
  //             // this.disabled = false;
  //           });
  //           //  this.snackBar.openSnackBar(err["error"]["message"], 2000);
  //         }
  //       );
  //     }
  //   });

  // }
  getData(){
    let data = {
      subject: this.emailSubject,
      communication: JSON.stringify(this.getCommunication())
    }
    return data;
  }
  getCommunication(){
    var commu: any = {};
    let data1: any = {};
    for(let i = 0; i<this.selectedChannel.length;i++){
      console.log("Channel",this.selectedChannel[i])
      let value: any = {};
      if(this.selectedChannel[i]==='email'){
        value.id = this.layoutId;
        value.subject = this.emailSubject;
        data1[this.selectedChannel[i]] = value;
        commu = data1
      }
      else if(this.selectedChannel[i]==='sms'){
        value.id = this.channelDetails['sms']['id'];
        data1[this.selectedChannel[i]] = value;
        commu = data1
      }
      else{
        value.id = this.channelDetails['whatsapp'][0]['id'];
        data1[this.selectedChannel[i]] = value;
        commu = data1
      }
    }
    return commu;
  }

  openEditor(sh, sw, datas,type) {
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1100px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: datas,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
        // this.timeEmailConfig.emailLayoutId = result.layoutId;
        if(type === 1){
          this.emailId1 = result.layoutId;
        }
        else{
          this.emailId2 = result.layoutId; 
        }
      }
    });
  }

  loadEmailEditorReward(type) {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    var datas = {
      process: "NewTemplateAndLayout",
      id: type === 1 ? this.emailId1: this.emailId2,
      tags: [],
      imageTagStatus: false,
      specialTagType: "",
      offerId: null,
      type: "FORM",
      rewardType: null,
      isReward: false,
      subject: type === 1 ? this.emailSubject : this.emailSubject2,
      emailLayoutHTML: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailHtml'] : this.channelDetails['afterBarrier']['email']['emailHtml'],
      emailLayoutJSON: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailJson'] : this.channelDetails['afterBarrier']['email']['emailJson']
    };
    this.openEditor(sh, sw, datas, type);
  }

  openSanpshotDialog(dat, type) {
    const dialogRef = this.dialog.open(HtmlForEmailTemplatesDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "60%",
      height: "500px",
      data: dat,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "edit") {
        this.loadEmailEditorReward(type);
      }
    });
  }

  // opensnapshotHtmlDialog(type) {
  //   var dat = {
  //     type: "FORM",
  //     id: null,
  //     channel: null,
  //     templateID: null,
  //     rewardType: null,
  //     actionType: null,
  //     layoutHTML: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailHtml'] : this.channelDetails['afterBarrier']['email']['emailHtml'],
  //     subject: this.templateName,
  //     emailSubject: type === 1 ? this.emailSubject : this.emailSubject2
  //   };
  //   this.openSanpshotDialog(dat, type);
  // }
  async getWidgets() {
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
      this.validWidgets = await this._WidgetConfigService.getWidget();
  }
  openWhatsAppPreview(type){
    let layout : any;
      layout =  JSON.parse(this.channelDetails['whatsapp'][0]['layoutData'])
    console.log("LAYOUTYES",layout)
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout['body'],
        type: "WhatsApp"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  opensnapshotHtmlDialog() {
    var dat = { type: null, id: null, templateId: null, actionType: null };
    if (this.layoutId > 0) {
      const dialogRef = this.dialog.open(HtmlDataDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "60%",
        height: "500px",
        data: { type: "survey", id: this.layoutId, templateId: this.SurveyId },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "edit") {
          this.loadEmailEditor();
        }
      });
    } else {
      this.apiCall.listEmailLayoutFilter("SURVEY").subscribe((res) => {
        if (res["message"] === "SUCCESS" || res["message"] === "success") {
          this.surveyid = res["body"][0]["id"];
          dat = {
            type: "surveyemailtemplate",
            id: res["body"][0]["id"],
            templateId: this.SurveyId,
            actionType: "Survey",
          };
          const dialogRef = this.dialog.open(
            HtmlForEmailTemplatesDialogComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "60%",
              height: "500px",
              data: dat,
            }
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result == "edit") {
              this.loadEmailEditor();
            }
          });
        }
      });
    }
  }

  surveyid;

  loadEmailEditor() {
    var specialTagType = null;
    var imageTagStatus = false;
    this.finalScreenHeight = this.scrHeight;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);

    if (this.layoutId > 0) {
      var datas = {
        process: "EditTextBodySurvey",
        id: this.layoutId,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        type: "Survey",
        offerId: this.SurveyId,
        isReward: false,
        rewardType: null,
      };
      const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
        panelClass: "customAttachEmail",
        height: sh + "px",
        width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
        maxWidth: "99vw",
        disableClose: true,
        data: datas,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
          this.layoutId = result.layoutId;
        }
      });
    } else {
      var datas = {
        process: "surveyEmail",
        id: this.surveyid,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        type: "SURVEY",
        offerId: this.SurveyId,
        isReward: false,
        rewardType: null,
      };

      const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
        panelClass: "customAttachEmail",
        height: sh + "px",
        width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
        maxWidth: "99vw",
        disableClose: true,
        data: datas,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
          this.layoutId = result.layoutId;
        }
      });
    }
  }
  // getLayoutId(){
  //   this.apiCall.listEmailLayoutFilter("SURVEY").subscribe((res) => {
  //     this.layoutId = res["body"][0]["id"];
  //   });
  // }

}
