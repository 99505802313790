import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SidenavOperationService } from 'src/app/core/services/sidenav-operation.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';

@Component({
  selector: 'app-review-dashboard',
  templateUrl: './review-dashboard.component.html',
  styleUrls: ['./review-dashboard.component.css']
})
export class ReviewDashboardComponent implements OnInit {

  netPromoterScore;
  promoters;
  passives;
  diteractors;
  feedbacks = [];
  timeSeriesData = [];
  dashboardData = null;
  heatmapvaluesrefreshed: boolean = false;
  dummyvalue = 54;
  NPS_MM_Period = "YEARLY";

  NPSStatus;
  NPSMMStatus;
  LFStatus;
  validWidgets = null;
  graphData = null;
  totalCountNPS = 0;

  topicwiseCatogory = [];
  topicwiseKey = [{}];
  topicwise = null;
  nodataInTopicWise = null;
  subtopic = null;
  selectedSubtopic = {
    name: "global",
    positive: 0,
    negative: 0,
    neutral: 0,
    total: 0,
    count: 0,
    subTopics: [],
  };
  topicdataLoading = false;
  topicdonutloading = false;
  subtopicdataLoading = false;
  responsesubTopicCount;
  responsesubTopic;
  topicGraphData = [];
  linegraphdata = [];
  lastdata;
  barChartData;
  chartheight;
  filterViewBy = "Months";
  YLegend;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileView;
  totalValue = 0;
  isAdmin = false;
  storeFilterList = [{
    storeName:'None',
    qid: null,
  }];
  selectedStore;
  @Input() platformFilter: string = 'None';

  npsChartOptions: EChartsOption;
  topicGraphOptions: EChartsOption;
  timeSeriesOptions: EChartsOption;
  barchartOptions : EChartsOption;

  @ViewChild("heatMapContainer") heatMapContainer: ElementRef;
  @ViewChild("linechartContainer") linechartContainer: ElementRef;
  constructor(
    private apiCall: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private _WidgetConfigService: WidgetConfigService,
    private _SidenavOperationService: SidenavOperationService,
    private tokenStorage : TokenStorageService
  ) {}
  ngAfterViewInit(): void {
    this.initMethods();
  }
  ngOnInit() {
    if(this.tokenStorage.getqId() == 'null'){
      this.isAdmin = true;
      this.apiCall.getReviewsActiveStore().subscribe(
        (response) => {
          this.storeFilterList = [{
            storeName:'None',
            qid: null,
          }];
          if(response['body']) {
            response['body'].forEach((storeData)=>{
              if(storeData.qid) {
                this.storeFilterList.push({
                  'qid':storeData.qid,
                  'storeName':storeData.storeName,
                });
              }
            });
            this.selectedStore = this.storeFilterList[0];
          }
        },
        (err) => {
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['platformFilter'] && !changes['platformFilter'].firstChange) {
      this.initMethods();
    }
  }

  dashboardLoading = false;
  initMethods(storeid?) {
    this.dashboardLoading = true;
    this.chartheight = this.linechartContainer?.nativeElement.offsetWidth - 60;
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.getWidgets();
    this.getTopicDivisionDetails(storeid);
    this.apiCall.getOverallReviewdashboard(this.platformFilter,storeid).subscribe((response) => {
      if (response["message"] === "SUCCESS") {
        let dashboardData = response["body"];
        this.dashboardData = dashboardData;
        this.dashboardLoading = false;

        if (dashboardData["npsScore"] != "NaN") {
          if(dashboardData["npsScore"] != 0){
            this.netPromoterScore = dashboardData["npsScore"].toFixed(2);
          }
          else{
            this.netPromoterScore = 0;
          }
        } else {
          this.NPSStatus = false;
          this.netPromoterScore = 0;
        }
        this.heatmampData(dashboardData);
        var data = [];
        this.totalCountNPS =
          dashboardData["promoters"] +
          dashboardData["passives"] +
          dashboardData["detractors"];

        data.push({
          name: "Promoters",
          count: dashboardData["promoters"],
          color: "#4AD991",
        });
        data.push({
          name: "Passives",
          count: dashboardData["passives"],
          color: "#FEC53D",
        });
        data.push({
          name: "Detractors",
          count: dashboardData["detractors"],
          color: "#FF6565",
        });
        this.graphData = data;
        this.getTopicTimeSeries(
          "months",
          "update",
          this.selectedSubtopic.name
        );
        this.setNPSChartOptions();
        this.setTopicGraphOptions();
        this.getTimeSeriesData(dashboardData["categoryData"]);
        this.setChartOptions();
        this.setBarChartOptions();
      }
    });
    this.chartheight = this.linechartContainer?.nativeElement.offsetWidth - 60;

  }

  setNPSChartOptions() {
    this.npsChartOptions = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['50%', '60%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.graphData.map((item) => ({
              value: item.count,
              name: item.name,
              itemStyle: {
                  color: item.color
              }
          }))
        }
      ]
    };
    
}

setTopicGraphOptions() {
  this.topicGraphOptions = {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        type: 'pie',
        center: ['50%', '50%'],
        radius: ['50%', '60%'], 
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: this.topicGraphData.map(item => ({
          value: item.count,
          name: item.name,
          itemStyle: {
            color: item.color
          }
        }))
      }
    ]
  };
}

setChartOptions(): void {
  console.log(this.timeSeriesData);
  const formattedDates = this.timeSeriesData.map(item => {
    const [month, year] = item.date.split('/'); 
    const date = new Date(Number(year), Number(month) - 1); 
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' }; 
    return date.toLocaleDateString('en-US', options); 
  });


  this.timeSeriesOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: '0%',
      data: ['PROMOTERS', 'PASSIVES', 'DETRACTORS']
    },
    xAxis: [{
      type: 'category',
      data: formattedDates,
      axisLabel: {
        interval: 0, 
      },
      name: "Months",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [15, 0, 0, 0]
      }
    }],
    yAxis: {
      type: 'value',
      name: 'Number of customers',
      nameLocation: "middle",
      nameTextStyle: {
      padding: [0, 0, 30, 0]
      }
    },
    series: [
      {
        name: 'PROMOTERS',
        type: 'bar',
        data: this.timeSeriesData.map(item => item.PROMOTERS || 0), // default to 0 if undefined
        itemStyle: {
          color: '#4abb59'
        },
        barWidth: '20%',
        barGap: '0%' 
      },
      {
        name: 'PASSIVES',
        type: 'bar',
        data: this.timeSeriesData.map(item => item.PASSIVES || 0), 
        itemStyle: {
          color: '#edbd22'
        },
        barWidth: '20%', 
        barGap: '0%' 
      },
      {
        name: 'DETRACTORS',
        type: 'bar',
        data: this.timeSeriesData.map(item => item.DETRACTORS || 0), 
        itemStyle: {
          color: '#f84737'
        },
        barWidth: '20%',
        barGap: '0%' 
      }
    ],
  };
}

setBarChartOptions() {
  const data = this.barChartData;
  const formattedDates = data?.map(d => {
    const [year, month] = d.orginalDate.split('-');
    const date = new Date(Number(year), Number(month) - 1);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }); 
  });
  console.log(data);
  this.barchartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}'
    },
    xAxis: {
      name : 'Months',
      type: 'category',
      data: formattedDates,
      nameLocation: "middle",
      nameTextStyle: {
        padding: [30, 0, 0, 0]
      }
    },
    yAxis: {
      type: 'value',
      name: this.selectedSubtopic.name,
      nameLocation: "middle",
      nameTextStyle: {
      padding: [0, 0, 20, 0]
      }
    },
    series: [{
      data: data.map(d => d.value),
      type: 'bar',
      color: '#4880FF'
    }]
  };
}


  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  getDashboardForStore() {
    this.initMethods(this.selectedStore.qid);
  }

  getTimeSeriesData(data) {
    let finalData = [];
    for (let year in data) {
      for (let month in data[year]) {
        let monthlyData: any = {};
        monthlyData["date"] = +month + "/" + year;
        monthlyData[data[year][month][0]["category"]] =
          data[year][month][0]["count"];
        monthlyData[data[year][month][1]["category"]] =
          data[year][month][1]["count"];
        monthlyData[data[year][month][2]["category"]] =
          data[year][month][2]["count"];
        finalData.push(monthlyData);
      }
    }
    this.timeSeriesData = finalData;
    for (let i=0;i<this.timeSeriesData.length;i++){
      this.totalValue+=this.timeSeriesData[i]['PROMOTERS']+this.timeSeriesData[i]['PASSIVES']+this.timeSeriesData[i]['DETRACTORS'];
    }
  }

  getFeedbackData(data) {
    for (let key in data) {
      let feedback: any = {};
      feedback["cName"] = key;
      feedback["data"] = data[key];
      this.feedbacks.push(feedback);
    }
    if (this.feedbacks.length < 1) {
      this.LFStatus = false;
    } else {
      this.LFStatus = true;
    }
  }

  heatmampData(dashboardData) {
    this.heatmapvaluesrefreshed = false;
    let totalValue =
      dashboardData["promoters"] +
      dashboardData["passives"] +
      dashboardData["detractors"];
    this.promoters = ((dashboardData["promoters"] / totalValue) * 100).toFixed(
      0
    );
    this.passives = ((dashboardData["passives"] / totalValue) * 100).toFixed(0);
    this.diteractors = (
      (dashboardData["detractors"] / totalValue) *
      100
    ).toFixed(0);
    this.heatmapvaluesrefreshed = true;
  }

  createStandardTemplate() {
    this.router.navigate(["app/surveys/add-survey", { id: null }], {});
  }

  topicsLoading = false;
  getTopicDivisionDetails(storeid?) {
    var topicDivision = [];
    var topicblock;
    this.topicsLoading = true;
    this.topicdataLoading = true;
    setTimeout(()=>{
      this.apiCall.getReviewTopicDivision(this.platformFilter,storeid).subscribe((result) => {
        this.topicwise = result["body"];
        var body;
        body = result["body"];
        if (body.length == 0) {
          this.nodataInTopicWise = false;
        } else {
          this.nodataInTopicWise = true;
        }
        var i;
  
        this.topicwiseCatogory = [];
        var topicDivision1 = [];
  
        for (i = 0; i < body.length; i++) {
          topicDivision = [];
          topicDivision["topic"] = body[i]["topic"];
          var d = topicDivision["topic"];
          var total = 0;
          total =
            body[i]["sentiment"]["POSITVE"] +
            body[i]["sentiment"]["NEUTRAL"] +
            body[i]["sentiment"]["NEGATIVE"];
          topicDivision["positive"] = body[i]["sentiment"]["POSITVE"] / total;
          topicDivision["neutral"] = body[i]["sentiment"]["NEUTRAL"] / total;
          topicDivision["negative"] = body[i]["sentiment"]["NEGATIVE"] / total;
          topicDivision["total"] = total;
  
          this.topicwiseCatogory.push(topicDivision);
        }
        this.topicdataLoading = false;
        this.getTopicCount(storeid);
        
      });
    },1000);
    
  }

  subtopicChange(val) {
    this.topicdonutloading = true;
    this.selectedSubtopic.name = val;
    this.YLegend = val;
    var i;
    this.getTopicTimeSeries("months", "update", val);
    this.topicGraphData = [];
    this.topicdataLoading = true;
    for (i = 0; i < this.topicwiseCatogory.length; i++) {
      if (this.topicwiseCatogory[i]["topic"] == val) {
        this.selectedSubtopic.positive =
          this.topicwiseCatogory[i]["positive"] * 100;
        this.selectedSubtopic.neutral =
          this.topicwiseCatogory[i]["neutral"] * 100;
        this.selectedSubtopic.negative =
          this.topicwiseCatogory[i]["negative"] * 100;
        this.selectedSubtopic.total = this.topicwiseCatogory[i]["total"];

        var data = [];

        data.push({
          name: "positive",
          count: this.selectedSubtopic.positive,
          color: "#4AD991",
        });
        data.push({
          name: "neutral",
          count: this.selectedSubtopic.neutral,
          color: "#FEC53D",
        });
        data.push({
          name: "negative",
          count: this.selectedSubtopic.negative,
          color: "#FF6565",
        });
        this.topicGraphData = data;
        break;
      }
    }
    for (i = 0; i < this.responsesubTopic.length; i++) {
      if (this.responsesubTopic[i]["topic"] == val) {
        this.selectedSubtopic.count = this.responsesubTopic[i]["count"];
        if (this.responsesubTopic[i]["subTopics"].length > 0) {
          this.selectedSubtopic.subTopics =
            this.responsesubTopic[i]["subTopics"];
          break;
        } else {
          this.selectedSubtopic.subTopics = null;
        }
      }
    }
    this.setTopicGraphOptions();
    this.topicdataLoading = false;
    this.topicdonutloading = false;
  }

  getTopicCount(storeid?) {
    this.subtopicdataLoading = true;
    this.apiCall.getReviewsTextAnalysisTopicCount(this.platformFilter,storeid).subscribe((result) => {
      this.responsesubTopic = result["body"];
      this.responsesubTopicCount = this.responsesubTopic.length;
      this.subtopicdataLoading = false;
      if (
        this.responsesubTopicCount > 0 &&
        this.topicwiseCatogory[0]["topic"]
      ) {
        this.subtopicChange(this.topicwiseCatogory[0]["topic"]);
      } else {
        this.subtopicdataLoading = false;
      }
    });
  }

  getTopicTimeSeries(type, status, topic) {
    var colorset = [];
    this.apiCall.getReviewsTopicTimeSeries(type,this.platformFilter,this.selectedStore?.qid).subscribe((result) => {
      var data = result["body"]["data"];
      var linedata = [];
      var i2 = 0;
      var totalCountDataValue = 0;
      for (i2 = 0; i2 < data.length; i2++) {
        if (data[i2][topic] == undefined) {
          linedata.push({
            name: ((data[i2]["date"])),
            value: 0,
            orginalDate:  (data[i2]["date"]),
          });
        } else {
          linedata.push({
            name: ((data[i2]["date"])),
            value: data[i2][topic],
            orginalDate: (data[i2]["date"]),
          });
          totalCountDataValue = totalCountDataValue + data[i2][topic];
        }
      }

      this.barChartData = linedata;
      if (totalCountDataValue > 0) {
        this.linegraphdata = linedata;
      } else {
        this.linegraphdata = [];
      }
      this.setBarChartOptions();

    });
  }

}
