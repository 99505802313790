<link [href]="fontCollection" rel="stylesheet" />

<!--Option to delete an offer template-->

<!-- <div *ngIf="data.type === 'delete'">
  <div mat-dialog-content>
    <div style="display:flex;  justify-content: space-evenly;  width: 150px;">
      <p>Delete Offer</p>
      <p style="color:#EF5350">{{data.id}}</p>
      <p>?</p>
    </div>
  </div>
  <div style="display:flex; justify-content: flex-end;">
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
    </div>
  </div>
</div> -->

<!--Option to delete an offer template Ends here-->

<!-- <div *ngIf="data.type === 'view'">
  <div class="displayBarrier">
    <div class="barrierSpan" *ngIf="data.id['productPropensity']">
      <div class="barrierDiv">
        <span>Propensity</span>
      </div>
      <div>
        <span
          *ngIf="data.id['productPropensity'] || data.id['productCategoryPropensity']">{{data.id['productPropensity']}}</span>
        <span *ngIf="dataBase.personalizedChecked && !data.id['productPropensity']">null</span>
      </div>
    </div>
    <div class="barrierSpan" *ngIf="data.id['barrierType']">
      <div class="barrierDiv">
        <span>Barrier Type</span>
      </div>
      <div>
        <span>{{data.id['barrierType']}}</span>
      </div>
    </div>
    <div *ngIf="data.id['product']">
      <div class="barrierSpan" *ngIf="data.id['product']['productName']">
        <div class="barrierDiv">
          <span>Barrier Product</span>
        </div>
        <div>
          <span>{{data.id['product']['productName']}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="data.id['productCategory']">
      <div class="barrierSpan" *ngIf="data.id['productCategory']['productCategoryName']">
        <div class="barrierDiv">
          <span>Barrier Product Category</span>
        </div>
        <div>
          <span>{{data.id['productCategory']['productCategoryName']}}</span>
        </div>
      </div>
    </div>
    <div class="barrierSpan" *ngIf="data.id['spentBarrier']">
      <div class="barrierDiv">
        <span>Spent Barrier</span>
      </div>
      <div>
        <span>{{data.id['spentBarrier']}}</span>
      </div>
    </div>
    <div *ngIf="data.id['barrierDates']">
      <div class="barrierSpan" *ngIf="data.id['barrierDates']['dates']">
        <div class="barrierDiv">
          <span>Date</span>
        </div>
        <div>
          <span>{{data.id['barrierDates']['dates']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['barrierDates']['dateFrom']">
        <div class="barrierDiv">
          <span>Date range</span>
        </div>
        <div>
          <span>{{data.id['barrierDates']['dateFrom']}}
            To
            {{data.id['barrierDates']['dateTo']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['barrierDates']['daysOfTheWeek'].length>0">
        <div class="barrierDiv">
          <span>Days of week</span>
        </div>
        <div>
          <div *ngFor="let day of data.id['barrierDates']['daysOfTheWeek']">{{day}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="data.id['offerReward']">
      <div class="barrierSpan" *ngIf="data.id['offerReward']['freeProducts'].length>0">
        <div class="barrierDiv">
          <span>Reward product</span>
        </div>
        <div>
          <span>{{data.id['offerReward']['freeProducts'][0]['productName']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['offerReward']['discount']">
        <div class="barrierDiv">
          <span>Reward Discount</span>
        </div>
        <div>
          <span>{{data.id['offerReward']['discount']['discountAmount']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['offerReward']['rewardPoint']">
        <div class="barrierDiv">
          <span>Reward Points</span>
        </div>
        <div>
          <span>{{data.id['offerReward']['rewardPoint']['rewardPoints']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['offerReward']['discountedProducts']">
        <div class="barrierDiv">
          <span>Product +
            Discount</span>
        </div>
        <div>
          <span>{{data.id['offerReward']['discountedProducts'][0]['productName']}}
            + {{data.id['offerReward']['discountedProducts'][0]['discount']['discountAmount']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['offerReward']['discountedProductCategories']">
        <div class="barrierDiv">
          <span>Product Category+
            Discount</span>
        </div>
        <div>
          <span>{{data.id['offerReward']['discountedProductCategories'][0]['productCategoryName']}}
            + {{data.id['offerReward']['discountedProductCategories'][0]['discount']['discountAmount']}}</span>
        </div>
      </div>
      <div class="barrierSpan" *ngIf="data.id['offerReward']['daysToRedeem']">
        <div class="barrierDiv">
          <span>Days to Redeem</span>
        </div>
        <div>
          <span>{{data.id['offerReward']['daysToRedeem']}}</span>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div *ngIf="data.type === 'rule'">
  <div>
    <p style="font-size: 18px; ">Sms Template</p>
    <mat-form-field style="width: 100%;">
      <textarea matInput placeholder="Rule" rows="5" cols="60" [(ngModel)]="dataBase.smsTemplate"></textarea>
    </mat-form-field>
  </div>
  <div>
    <p style="font-size: 18px; ">Email Template</p>
    <mat-form-field style="width: 100%;">
      <textarea matInput placeholder="Rule" rows="5" cols="60" [(ngModel)]="dataBase.emailTemplate"></textarea>
    </mat-form-field>
  </div>
  <div style="display:flex; justify-content: flex-end;">
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
    </div>
  </div>
</div> -->

<!-- <div *ngIf="data.type === 'viewText'">
  <p style="font-weight: 600; font-size: x-large;">Template</p>
  <span style="font-size: 16px; ">{{data.id}}</span>
</div> -->

<!-- <div *ngIf="data.type === 'offerReward'" class="offerReward-div">
  <div style=" display: flex; justify-content: center;">
    <h2>Select rewards for the offer</h2>
  </div>
  <div class="reward-container" style="width:100%; margin-left:0%; ">
    <div class="content-box" style="height:280px;">
      <mat-radio-group [(ngModel)]="data.finalRewardType" style="display: flex;justify-content: space-evenly;">
        <mat-radio-button *ngFor="let type of rewardTypes" [value]='type' color="primary">{{type}}
        </mat-radio-button>
      </mat-radio-group>
      <div style="height: 83%; display: flex; justify-content: space-around; margin-top: 6%;">
        <div *ngIf="data.finalRewardType === 'product' || data.finalRewardType === 'product discount'"
          class="rewardInputs">
          <div *ngIf="data.finalRewardType === 'product discount'">
            <mat-form-field appearance="outline">
              <mat-label>Product Type</mat-label>
              <mat-select [(ngModel)]="data.finalCategoryType">
                <mat-option value="Product">Product</mat-option>
                <mat-option value="Product Category">Product Category</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="data.finalCategoryType!=='Product Category'">
            <mat-form-field appearance="outline">
              <mat-label>Product</mat-label>
              <mat-select [(ngModel)]="data.finalRewardProduct">
                <mat-option *ngFor="let name of data['data']" [value]="name['name']">
                  {{name['name']}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="data.finalCategoryType==='Product Category'">
            <mat-form-field appearance="outline">
              <mat-label>Product Category</mat-label>
              <mat-select [(ngModel)]="data.finalRewardProductCategory">
                <mat-option *ngFor="let name of data['category']" [value]="name['name']">
                  {{name['name']}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div>
          <div *ngIf="data.finalRewardType === 'product discount' || data.finalRewardType === 'discount'"
            class="rewardInputs">
            <div>
              <input class="inputClass" type="text" placeholder="Discount" style="margin-bottom:10px;"
                [(ngModel)]="data.finalDiscount">
            </div>
            <div>
              <input class="inputClass" type="text" placeholder="Discount%" style="margin-bottom:10px;"
                [(ngModel)]="data.finalDiscountPercent">
            </div>
          </div>
          <div *ngIf="data.finalRewardType === 'points'" class="rewardInputs" style="margin-bottom:10px;">
            <input class="inputClass" type="text" placeholder="Points" [(ngModel)]="data.finalPoints">
          </div>
          <div class="rewardInputs">
            <input class="inputClass" type="text" placeholder="Days to redeem" [(ngModel)]="data.finalDaysToRedeem">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display:flex; justify-content:space-between; width: 100%">
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Add Template</button>
  </div>
</div> -->

<!--Displaying email templates while campaign template creation-->

<!-- <div *ngIf="data.type === 'campaignEmail'">
  <div>
    <div *ngIf="data.data.length>0">
      <div *ngFor="let template of data.data; index as i">
        <div class="emailCampaignOuterDiv">
          <div style="display:flex; width: 100%; align-items: baseline;">
            <div style="padding: 1%; color: #5cb8e7; width: 10%;">
              <span>Subject : </span>
            </div>
            <div style="padding: 2%; width: 90%;">
              <span>{{template['subject']}}</span>
            </div>
          </div>
          <div style="padding: 1%; color: #5cb8e7;">
            <span>Body</span>
          </div>
          <div style="padding: 2%;">
            <div [innerHtml]="template['body'] | safeHtml"></div>
          </div>
          <div style="display:flex; justify-content:center; margin: 1%;">
            <div
              style="display:flex; justify-content:center; width:90%; border-radius: 5px; background: #5cb8e7; padding: 1%; color: white; font-size: 1vw; cursor:pointer;"
              (click)="selectTemplate(template['subject'],template['body'],template['id'],template['offerMasterId'],'notoffer')">
              <div>
                <span>Select Template</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.data.length === 0">
      <div class="allFont"
        style="display:flex; justify-content:center; margin: 14%; color:#8080804a; font-size: 1.4vw;">
        <div><span>No templates available</span></div>
      </div>
    </div>
  </div>
</div> -->

<!--Displaying email templates while campaign template creation and offer listing Ends here-->

<!--Listing the Campaign Custom Email Templates-->

<div *ngIf="data.type === 'customEmailListing'" class="allFont">
  <div *ngFor="let template of data.data; let i = index">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px;
        border-bottom: 2px solid #e2e2e2;
        align-items: center;
      "
    >
      <span>{{ template["name"] }}</span>
      <span
        style="
          padding: 5px;
          background: #36d6e7;
          border-radius: 3px;
          cursor: pointer;
        "
        (click)="selectCustomEmail(template['id'])"
        >{{'SHARED_PAGE.SELECT' | i18nTranslate}}</span
      >
    </div>
  </div>
</div>

<!--Listing the campaign Cuton Email Templates ends here-->

<!--Displaying Campaign Email Layouts-->

<div *ngIf="data.type === 'campaignLayout'" class="allFont">
  <!-- <div *ngIf="!showLayoutDescriptionTextArea">
    <div *ngFor="let layout of data.data;let i = index;">
      <div
        style="display: flex; justify-content: space-between; padding: 5px; border-bottom: 2px solid #e2e2e2; align-items: center;">
        <span>{{layout['name']}}</span>
        <span style="padding: 5px; background: #36d6e7; border-radius: 3px; cursor: pointer;"
          (click)="selectLayout(layout['id'],data['messageTemplateSubType'],data['offerId'])">Select</span>
      </div>
    </div>
  </div> -->
  <div class="fontOpenSans">
    <!-- <div>
      <mat-icon (click)="showLayoutDescriptionTextArea = false;" style="cursor:pointer;">arrow_back</mat-icon>
    </div> -->
    <div *ngIf="data.medium === 'EMAIL_DELIVERY'">
      <div style="display: flex; justify-content: center">
        <span style="font-size: 14px">{{'SHARED_PAGE.ENTER_MESSAGE_SUBJECT' | i18nTranslate}}</span>
      </div>
      <div class="formDiv" style="margin-top: 10px">
        <input class="formInput" type="text" [(ngModel)]="layoutEmailSubject" />
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px">
      <span style="font-size: 14px">{{'SHARED_PAGE.ENTER_MESSAGE_BODY' | i18nTranslate}}</span>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px">
      <span style="font-size: 12px; color: #ff5252"
        >{{'SHARED_PAGE.HIT_FOR_SUGGESTION' | i18nTranslate}}</span
      >
    </div>
    <div>
      <textarea
        class="fontOpenSans"
        id="descriptionText"
        name="descriptionText"
        [mentionConfig]="data.data"
        style="
          width: 100%;
          height: 50px;
          margin-top: 10px;
          margin-bottom: 15px;
          border: 1px solid #cacaca;
          border-radius: 4px;
          padding: 0;
          resize: none;
        "
        [(ngModel)]="layoutEmailDescription"
        (searchTerm)="tester($event)"
      >
      </textarea>
      <!-- <input type="text" [mentionConfig]="mentionConfig"> -->
    </div>
    <div style="display: flex; justify-content: flex-end">
      <div
        style="
          display: flex;
          justify-content: center;
          margin-right: 20px;
          background: transparent
            linear-gradient(5deg, #07b6b6 0%, #1193d9 100%) 0% 0% no-repeat
            padding-box;
          padding: 1px;
          border-radius: 3px;
          cursor: pointer;
          color: #ffffff;
          align-items: center;
          display: flex;
          align-items: center;
        "
      >
        <div
          style="
            display: flex;
            background: #ffffff;
            color: #000000;
            align-items: center;
            padding: 8px;
            border-radius: 2px;
          "
        >
          <span (click)="selectIndexForEmailLayout(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <span
          style="
            background: transparent
              linear-gradient(5deg, #07b6b6 0%, #1193d9 100%) 0% 0% no-repeat
              padding-box;
            padding: 8px;
            border-radius: 3px;
            cursor: pointer;
            color: #ffffff;
          "
          (click)="selectIndexForEmailLayout(true)"
          >{{'SHARED_PAGE.SUBMIT_MESSAGE_TEXT' | i18nTranslate}}</span
        >
      </div>
    </div>
  </div>
</div>
<!--Displaying campaign Email Layouts ends here-->

<!--Displaying offer template in campaign templates ******-------discontinued-----=====-----**********-->

<div
  *ngIf="
    data.type === 'campaignOfferView' ||
    data.type === 'offerListView' ||
    data.type === 'selectOfferTemplate'
  "
>
  <div>
    <!-- <div class="allFont" style="width:100%; display:flex; justify-content:flex-end">
        <div style="color:#5cb8e7; text-decoration: underline; cursor: pointer; margin: 3%;"
          (click)="gotoAddTemplate('email')">
          <span>Add New Template</span>
        </div>
      </div> -->
    <div *ngIf="data.data.length > 0">
      <div *ngFor="let offerMaster of data.data; index as i">
        <div class="emailCampaignOuterDiv">
          <div style="display: flex; justify-content: center">
            <div style="width: 100%">
              <div
                class="displayBarrier"
                style="
                  border: none;
                  margin: 0;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  width: 100%;
                "
              >
                <div style="width: 100%">
                  <div class="barrierSpan">
                    <div class="barrierDiv">
                      <span>{{'SHARED_PAGE.OFFER_NAME' | i18nTranslate}}</span>
                    </div>
                    <div>
                      <span>{{ offerMaster["offerName"] }}</span>
                    </div>
                  </div>
                  <div *ngIf="offerMaster['reward']">
                    <div class="barrierSpan">
                      <div class="barrierDiv">
                        <span>{{'SHARED_PAGE.REWARD_TYPE' | i18nTranslate}}</span>
                      </div>
                      <div>
                        <span>{{
                          offerMaster["reward"]["rewardtype"] === "PRODUCT"
                            ? "Product"
                            : offerMaster["reward"]["rewardtype"] ===
                              "PRODUCT_SPECIFIC_DISCOUNT"
                            ? "Discount on Product"
                            : offerMaster["reward"]["rewardtype"] ===
                              "ALL_PRODUCT_DISCOUNT"
                            ? "Discount"
                            : "Discount on Product category"
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="barrierSpan"
                      *ngIf="offerMaster['reward']['rewardtype'] === 'PRODUCT'"
                    >
                      <div class="barrierDiv">
                        <span>{{'SHARED_PAGE.REWARD_PRODUCT' | i18nTranslate}}</span>
                      </div>
                      <div>
                        <span>{{
                          offerMaster["reward"]["freeProducts"][0][
                            "productName"
                          ]
                        }}</span>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        offerMaster['reward']['rewardtype'] ===
                        'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'
                      "
                    >
                      <div
                        class="barrierSpan"
                        *ngIf="
                          offerMaster['reward']['discountedProductCategories']
                        "
                      >
                        <div class="barrierDiv">
                          <span>{{'SHARED_PAGE.DISCOUNTED_CATEGORY' | i18nTranslate}}</span>
                        </div>
                        <div>
                          <span>{{
                            offerMaster["reward"][
                              "discountedProductCategories"
                            ][0]["productCategoryName"]
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        offerMaster['reward']['rewardtype'] ===
                        'PRODUCT_SPECIFIC_DISCOUNT'
                      "
                    >
                      <div
                        class="barrierSpan"
                        *ngIf="offerMaster['reward']['discountedProducts']"
                      >
                        <div class="barrierDiv">
                          <span>{{'SHARED_PAGE.DISCOUNTED_PRODUCT' | i18nTranslate}}</span>
                        </div>
                        <div>
                          <span>{{
                            offerMaster["reward"]["discountedProducts"][0][
                              "productName"
                            ]
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="barrierSpan"
                      *ngIf="
                        offerMaster['reward']['rewardtype'] ===
                          'ALL_PRODUCT_DISCOUNT' ||
                        offerMaster['reward']['rewardtype'] ===
                          'PRODUCT_SPECIFIC_DISCOUNT' ||
                        offerMaster['reward']['rewardtype'] ===
                          'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'
                      "
                    >
                      <div class="barrierDiv">
                        <span>{{'SHARED_PAGE.DISCOUNT' | i18nTranslate}}</span>
                      </div>
                      <div
                        *ngIf="
                          offerMaster['reward']['rewardtype'] ===
                          'ALL_PRODUCT_DISCOUNT'
                        "
                      >
                        <span>{{
                          offerMaster["reward"]["discount"][
                            "discountPercentage"
                          ]
                        }}</span>
                      </div>
                      <div *ngIf="offerMaster['reward']['discountedProducts']">
                        <span>{{
                          offerMaster["reward"]["discountedProducts"][0][
                            "discount"
                          ]["discountPercentage"]
                        }}</span>
                      </div>
                      <div
                        *ngIf="
                          offerMaster['reward']['discountedProductCategories']
                        "
                      >
                        <span>{{
                          offerMaster["reward"][
                            "discountedProductCategories"
                          ][0]["discount"]["discountPercentage"]
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="barrierSpan"
                      *ngIf="
                        offerMaster['reward']['rewardtype'] === 'REWARD_POINTS'
                      "
                    >
                      <div class="barrierDiv">
                        <span>{{'SHARED_PAGE.REWARD_POINTS' | i18nTranslate}}</span>
                      </div>
                      <div>
                        <span>{{
                          offerMaster["reward"]["rewardPoint"]["rewardPoints"]
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="barrierSpan"
                      *ngIf="offerMaster['reward']['daysToRedeem']"
                    >
                      <div class="barrierDiv">
                        <span>{{'SHARED_PAGE.DAYS_TO_REDEEM' | i18nTranslate}}</span>
                      </div>
                      <div>
                        <span>{{ offerMaster["reward"]["daysToRedeem"] }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="barrierSpan">
                        <div class="barrierDiv">
                          <span>Sms Template</span>
                        </div>
                        <div>
                          <span (click)="viewText('sms')" class="textSpan">Click to view</span>
                        </div>
                      </div>
                      <div class="barrierSpan">
                        <div class="barrierDiv">
                          <span>Email Template</span>
                        </div>
                        <div>
                          <span (click)="viewText('email')" class="textSpan">Click to view</span>
                        </div>
                      </div> -->
                </div>
              </div>
              <div class="content-box">
                <div
                  style="
                    font-weight: 700;
                    font-size: 22px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  {{'SHARED_PAGE.BARRIER_LIST' | i18nTranslate}}
                </div>
                <div style="border: 1px solid gray; padding: 5px; margin: 3%">
                  <div
                    *ngFor="let barrier of offerMaster.barrierList; index as i"
                  >
                    <div
                      style="display: flex; justify-content: center"
                      *ngIf="i > 0"
                    >
                      <div style="font-weight: 800; color: #79ff7a">
                        {{ offerMaster.barrierOperators[i] }}
                      </div>
                    </div>
                    <div
                      class="displayBarrier"
                      style="border: none; margin: 0; padding: 0; width: 100%"
                    >
                      <div class="barrierSpan">
                        <div class="barrierDiv">
                          <span>{{'SHARED_PAGE.BARRIER_PARAM_TYPE' | i18nTranslate}}</span>
                        </div>
                        <div>
                          <span>{{
                            barrier["barrierParamType"] === "PERSONALIZED"
                              ? "Personalized"
                              : "Non Personalized"
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="barrierSpan"
                        *ngIf="barrier['productPropensity']"
                      >
                        <div class="barrierDiv">
                          <span>{{'SHARED_PAGE.PROPENSITY' | i18nTranslate}}</span>
                        </div>
                        <div>
                          <span
                            *ngIf="
                              barrier['productPropensity'] ||
                              barrier['productCategoryPropensity']
                            "
                            >{{
                              barrier["productPropensity"] === "HIGH"
                                ? "High"
                                : barrier["productPropensity"] === "MEDIUM"
                                ? "Medium"
                                : "Low"
                            }}</span
                          >
                          <!-- <span *ngIf="dataBase.personalizedChecked && !barrier['productPropensity']">null</span> -->
                        </div>
                      </div>
                      <div class="barrierSpan" *ngIf="barrier['barrierType']">
                        <div class="barrierDiv">
                          <span>{{'SHARED_PAGE.BARRIER_TYPE' | i18nTranslate}}</span>
                        </div>
                        <div>
                          <span>{{
                            barrier["barrierType"] === "PRODUCT_BASED"
                              ? "Product"
                              : barrier["barrierType"] === "SPENT_BASED"
                              ? "Spent"
                              : "Visit"
                          }}</span>
                        </div>
                      </div>
                      <div *ngIf="barrier['product']">
                        <div
                          class="barrierSpan"
                          *ngIf="barrier['product']['productName']"
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.BARRIER_PRODUCT' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span>{{ barrier["product"]["productName"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="barrier['productCategory']">
                        <div
                          class="barrierSpan"
                          *ngIf="
                            barrier['productCategory']['productCategoryName']
                          "
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.BARRIER_PRODUCT_CATEGORY' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span>{{
                              barrier["productCategory"]["productCategoryName"]
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="barrierSpan" *ngIf="barrier['spentBarrier']">
                        <div class="barrierDiv">
                          <span>{{'SHARED_PAGE.SPENT_BARRIER' | i18nTranslate}}</span>
                        </div>
                        <div>
                          <span>{{ barrier["spentBarrier"] }}</span>
                        </div>
                      </div>
                      <!--<div *ngIf="barrier['barrierDates']">
                             <div class="barrierSpan" *ngIf="barrier['barrierDates']['dates']">
                              <div class="barrierDiv">
                                <span>Date</span>
                              </div>
                              <div>
                                <span>{{barrier['barrierDates']['dates']}}</span>
                              </div>
                            </div>
                            <div class="barrierSpan" *ngIf="barrier['barrierDates']['dateFrom']">
                              <div class="barrierDiv">
                                <span>Date range</span>
                              </div>
                              <div>
                                <span>{{barrier['barrierDates']['dateFrom']}}
                                  To
                                  {{barrier['barrierDates']['dateTo']}}</span>
                              </div>
                            </div>
                            <div class="barrierSpan" *ngIf="barrier['barrierDates']['daysOfTheWeek'].length>0">
                              <div class="barrierDiv">
                                <span>Days of week</span>
                              </div>
                              <div>
                                <div *ngFor="let day of barrier['barrierDates']['daysOfTheWeek']">{{day}}</div>
                              </div>
                            </div>
                          </div> -->
                      <div *ngIf="barrier['offerReward']">
                        <div *ngIf="barrier['offerReward']['freeProducts']">
                          <div
                            class="barrierSpan"
                            *ngIf="
                              barrier['offerReward']['freeProducts'].length > 0
                            "
                          >
                            <div class="barrierDiv">
                              <span>{{'SHARED_PAGE.REWARD_PRODUCT' | i18nTranslate}}</span>
                            </div>
                            <div>
                              <span>{{
                                barrier["offerReward"]["freeProducts"][0][
                                  "productName"
                                ]
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="barrierSpan"
                          *ngIf="barrier['offerReward']['discount']"
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.REWARD_DISCOUNT' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span>{{
                              barrier["offerReward"]["discount"][
                                "discountPercentage"
                              ]
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="barrierSpan"
                          *ngIf="barrier['offerReward']['rewardPoint']"
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.REWARD_POINTS' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span>{{
                              barrier["offerReward"]["rewardPoint"][
                                "rewardPoints"
                              ]
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="barrierSpan"
                          *ngIf="barrier['offerReward']['discountedProducts']"
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.PRODUCT_DISCOUNT' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span
                              >{{
                                barrier["offerReward"]["discountedProducts"][0][
                                  "productName"
                                ]
                              }}
                              +
                              {{
                                barrier["offerReward"]["discountedProducts"][0][
                                  "discount"
                                ]["discountPercentage"]
                              }}</span
                            >
                          </div>
                        </div>
                        <div
                          class="barrierSpan"
                          *ngIf="
                            barrier['offerReward'][
                              'discountedProductCategories'
                            ]
                          "
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.PRODUCT_CATEGORY_DISCOUNT' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span
                              >{{
                                barrier["offerReward"][
                                  "discountedProductCategories"
                                ][0]["productCategoryName"]
                              }}
                              +
                              {{
                                barrier["offerReward"][
                                  "discountedProductCategories"
                                ][0]["discount"]["discountPercentage"]
                              }}</span
                            >
                          </div>
                        </div>
                        <div
                          class="barrierSpan"
                          *ngIf="barrier['offerReward']['daysToRedeem']"
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.DAYS_TO_REDEEM' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span>{{
                              barrier["offerReward"]["daysToRedeem"]
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="barrierSpan"
                          *ngIf="barrier['barrierDates']"
                        >
                          <div class="barrierDiv">
                            <span>{{'SHARED_PAGE.DAYS_TO_COMPLETE' | i18nTranslate}}</span>
                          </div>
                          <div>
                            <span>{{
                              barrier["barrierDates"]["endDayCount"]
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="data.type === 'campaignOfferView'"
            style="display: flex; justify-content: center; margin: 1%"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                width: 90%;
                border-radius: 5px;
                background: #5cb8e7;
                padding: 1%;
                color: white;
                font-size: 1vw;
                cursor: pointer;
              "
              (click)="
                selectTemplate(
                  'Offer',
                  offerMaster['textTemplate']['emailTemplate'],
                  offerMaster['offerId'],
                  offerMaster['offerId'],
                  'offer'
                )
              "
            >
              <div>
                <span>{{'SHARED_PAGE.SELECT_TEMPLAETE' | i18nTranslate}}</span>
              </div>
            </div>
          </div>
          <div
            *ngIf="data.type === 'selectOfferTemplate'"
            style="display: flex; justify-content: center; margin: 1%"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                width: 90%;
                border-radius: 5px;
                background: #5cb8e7;
                padding: 1%;
                color: white;
                font-size: 1vw;
                cursor: pointer;
              "
              (click)="selectTemplateForEmail(offerMaster)"
            >
              <div>
                <span>{{'SHARED_PAGE.SELECT_TEMPLAETE' | i18nTranslate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.data.length === 0">
      <div
        class="allFont"
        style="
          display: flex;
          justify-content: center;
          margin: 14%;
          color: #8080804a;
          font-size: 1.4vw;
        "
      >
        <div><span>{{'SHARED_PAGE.NO_TEMPLATE_AVAILABLE' | i18nTranslate}}</span></div>
      </div>
    </div>
  </div>
</div>

<!--Displaying offer template in campaign templates and offer listing Ends here-->

<!--Confirmation for Campaign template Submission and Campaign temlate deletion-->

<!-- <div *ngIf="data.type === 'campaignEmailSubmit' ||
  data.type === 'deleteCampaignTemplate' ||
  data.type === 'EmailSubmit' ||
  data.type === 'SmsSubmit' ||
  data.type === 'segmentSubmit' ||
  data.type === 'layoutSubmit' ||
  data.type === 'offerTemplateSubmit' ||
  data.type === 'offerTemplateDelete' ||
  data.type === 'layoutDelete' ||
  data.type === 'segmentDelete'">
  <div style="display:flex; justify-content:center;">
    <div style="width: 100%; display: flex; justify-content: center; margin: 5%;">
      <span *ngIf="data.type === 'campaignEmailSubmit'">Submit Campaign Template??</span>
      <span *ngIf="data.type === 'deleteCampaignTemplate'">Delete Template??</span>
      <span *ngIf="data.type === 'EmailSubmit'">Add Email Template??</span>
      <span *ngIf="data.type === 'segmentSubmit'">Add Segment??</span>
      <span *ngIf="data.type === 'layoutSubmit'">Submit Layout??</span>
      <span *ngIf="data.type === 'offerTemplateSubmit'">Submit Offer Template??</span>
      <span *ngIf="data.type === 'offerTemplateDelete'">Delete Offer Template??</span>
      <span *ngIf="data.type === 'layoutDelete'">Delete Layout??</span>
      <span *ngIf="data.type === 'segmentDelete'">Delete Segment??</span>
      <span *ngIf="data.type === 'SmsSubmit'">Add SMS Template??</span>
    </div>
  </div>
  <div>
    <div style="margin:1%;">
      <div (click)="submitCampaignTemplate(false)" class="confirmDialog" style="background:#e8e8e8; color: #000000">
        <span>Cancel</span>
      </div>
    </div>
    <div style="margin:1%;">
      <div (click)="submitCampaignTemplate(true)" class="confirmDialog"
        style="background:transparent linear-gradient(7deg, #07B6B6 0%, #1193D9 100%) 0% 0% no-repeat padding-box;">
        <span>Submit</span>
      </div>
    </div>
  </div>
</div> -->

<!--Confirmation for Campaign template Submission and Campaign temlate deletion Ends here-->

<!--Confirmation for launching a campaign-->

<div
  *ngIf="data.type === 'launchCampaign'"
  class="fontOpenSans"
  style="padding: 10px"
>
  <div>
    <span style="font-size: 16px; font-weight: bold">{{'SHARED_PAGE.LAUNCH_CAMPAIGN' | i18nTranslate}}</span>
  </div>
  <div
    style="display: flex; justify-content: center"
    *ngIf="data.triggerType.campaignTriggerType === 'INSTANT_TRIGGER'"
  >
    <div style="width: 100%; display: flex; margin: 8%; margin-left: 10px">
      <span>{{'SHARED_PAGE.CLICK_SUBMIT_LAUNCH' | i18nTranslate}}</span>
    </div>
  </div>
  <div
    *ngIf="
      data.triggerType.campaignTriggerType === 'TIME_TRIGGER' ||
      data.triggerType.campaignTriggerType === 'EVENT_TRIGGER'
    "
  >
    <div
      style="
        display: flex;
        justify-content: space-between;
        margin: 5%;
        align-items: center;
      "
    >
      <div>
        <span>{{'SHARED_PAGE.START_DATE' | i18nTranslate}}</span>
      </div>
      <div class="formDiv" style="width: 50%">
        <input
          style="margin-left: 5%"
          [formControl]="campaignStartDate"
          class="formInput"
          [min]="campaignStartDate.value"
          matInput
          [matDatepicker]="picker1"
          placeholder="Choose a date"
          [ngStyle]="campaignStartDate.value ? { opacity: 0 , width: '0px' } : null" 
          />
          <div class="formInput">
            {{campaignStartDate.value | date: getDateFormat() }}
        </div>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </div>
    </div>
    <!-- <div *ngIf="data.triggerType.campaignTriggerType ==='TIME_TRIGGER'">
      <div style="margin:5%;">
        <div style="display:flex; justify-content:space-between;">
          <span class="fontOpenSans" style="display:flex; align-items:center; ">Days of the week</span>
          <div class="formDiv" style="width: 50%;">
            <mat-icon style="color: #88c4fb; padding: 10px;">scatter_plot</mat-icon>
            <mat-select class="fontOpenSans" color="primary" placeholder="All days" [(ngModel)]="selectedDays" multiple>
              <mat-option class="fontOpenSans" *ngFor="let day of days" [value]="day.name">
                {{day.name}}</mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div
      *ngIf="data.triggerType.campaignTriggerType ==='TIME_TRIGGER' || data.triggerType.campaignTriggerType ==='EVENT_TRIGGER'"
      style="margin:5%;">
      <span>Ends</span>
    </div> -->
    <div
      *ngIf="
        data.triggerType.campaignTriggerType === 'TIME_TRIGGER' ||
        data.triggerType.campaignTriggerType === 'EVENT_TRIGGER'
      "
      style="
        display: flex;
        justify-content: space-between;
        margin: 5%;
        align-items: center;
      "
    >
      <div>
        <span>{{'SHARED_PAGE.END_DATE' | i18nTranslate}}</span>
      </div>
      <div class="formDiv" style="width: 50%">
        <input
          style="margin-left: 5%"
          [formControl]="campaignEndDate"
          [min]="campaignEndDate.value"
          class="formInput"
          matInput
          [matDatepicker]="picker1"
          placeholder="Choose a date"
          [ngStyle]="campaignEndDate.value ? { opacity: 0 , width: '0px' } : null" 
          />
          <div class="formInput">
            {{campaignEndDate.value | date: getDateFormat() }}
        </div>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </div>
      <!--<mat-radio-group aria-label="Select an option" [(ngModel)]="scheduledCampaignEndType">
        <mat-radio-button color="primary" value="DATE">
        </mat-radio-button>
         <mat-radio-button color="primary" value="COUNT" style="margin-top:20px;">
          <div style="display:flex;">
            <div style="display: flex; align-items: center;">
              <span>After</span>
            </div>
            <div style="display: flex;">
              <div class="formDiv" style="width: 20%; margin-left:10px;">
                <input class="formInput" style="text-align:center" type="number" placeholder="Count"
                  [(ngModel)]="occurrenceCountForCampaignSchedule">
              </div>
              <div style="display: flex;">
                <span style="display:flex; align-items:center; margin-left:10px;">Occurrence</span>
              </div>
            </div>
          </div>
        </mat-radio-button>
      </mat-radio-group> -->
    </div>
    <!-- <div *ngIf="data.triggerType.campaignTriggerType ==='TIME_TRIGGER'"
      style="display:flex; justify-content:space between; margin: 5%; align-items: center;">

    </div> -->
  </div>
  <div style="height: 15px">
    <span *ngIf="dateRangeValidity" style="font-size: 12px; color: #ff5f31"
      >{{'SHARED_PAGE.INVALID_DATE_RANGE' | i18nTranslate}}</span
    >
  </div>
  <div style="display: flex; justify-content: flex-end">
    <div style="margin: 1%">
      <div
        (click)="submitCampaign(true, data)"
        class="confirmDialog"
        style="
          background: transparent
            linear-gradient(7deg, #07b6b6 0%, #1193d9 100%) 0% 0% no-repeat
            padding-box;
        "
      >
        <span>{{'SHARED_PAGE.SUBMIT' | i18nTranslate}}</span>
      </div>
    </div>
    <div style="margin: 1%">
      <div
        (click)="submitCampaign(false, data)"
        class="confirmDialog"
        style="background: #e8e8e8; color: #000000"
      >
        <span>{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
      </div>
    </div>
  </div>
</div>

<!--Confirmation for launching a campaign ends-->

<!-- <div *ngIf="data.type === 'campaignSms'">
  <div>
    <div class="allFont" style="width:100%; display:flex; justify-content:flex-end">
      <div style="color:#5cb8e7; text-decoration: underline; cursor: pointer; margin: 3%;"
        (click)="gotoAddTemplate('sms')">
        <span>Add New Template</span>
      </div>
    </div>
    <div *ngIf="data.data.length>0">
      <div *ngFor="let template of data.data; index as i">
        <div class="emailCampaignOuterDiv">
          <div style="padding: 8%;">
            <span>{{template['templateJson']}}</span>
          </div>
          <div style="display:flex; justify-content:center; margin: 5%;">
            <div
              style="display:flex; justify-content:center; width:90%; border-radius: 5px; background: #5cb8e7; padding: 3%; color: white; font-size: 1vw; cursor:pointer;"
              (click)="selectTemplate('sms',i)">
              <div>
                <span>Select Template</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.data.length === 0">
      <div class="allFont"
        style="display:flex; justify-content:center; margin: 14%; color:#8080804a; font-size: 1.4vw;">
        <div><span>No templates available</span></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data.type === 'campaignOffer'">
  <div>
    <div class="allFont" style="width:100%; display:flex; justify-content:flex-end">
      <div style="color:#5cb8e7; text-decoration: underline; cursor: pointer; margin: 3%;"
        (click)="gotoAddTemplate('offer')">
        <span>Add New Template</span>
      </div>
    </div>
    <div *ngIf="data.data.length>0">
      <div *ngFor="let template of data.data; index as i">
        <div class="emailCampaignOuterDiv">
          <div style="padding: 8%;">
            <span>{{template['offerName']}}</span>
          </div>
          <div style="display:flex; justify-content:center; margin: 5%;">
            <div
              style="display:flex; justify-content:center; width:90%; border-radius: 5px; background: #5cb8e7; padding: 3%; color: white; font-size: 1vw; cursor:pointer;"
              (click)="selectTemplate('offer',i)">
              <div>
                <span>Select Template</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.data.length === 0">
      <div class="allFont"
        style="display:flex; justify-content:center; margin: 14%; color:#8080804a; font-size: 1.4vw;">
        <div><span>No templates available</span></div>
      </div>
    </div>
  </div>
</div> -->

<!--Displaying the details of a campaign template ---- discontinued****-->

<!-- <div *ngIf="data.type === 'discontinued'">
  <div style="width:100%;">
    <div style="display:flex; justify-content:center; font-size: 21px; font-weight: bold; color:#a500ff;"
      class="allFont">
      <span>Target Customer Segments</span>
    </div>
  </div>
  <div style="display:flex; justify-content: center; flex-wrap: wrap;">
    <div *ngFor="let segment of data.data['targetCustomerSegments']"
      style="margin:1%; padding: 5px; border: 1px solid#ff6462; border-radius: 10px;" class="allFont">
      <div>
        <span>{{segment['segmentName']}}</span>
      </div>
    </div>
  </div>
  <div style="width:100%; margin-top: 4%;">
    <div style="display:flex; justify-content:center; font-size: 21px; font-weight: bold; color:#a500ff;  "
      class="allFont">
      <span>Campaign Flow</span>
    </div>
  </div>
  <div style="width:100%; display: flex; justify-content: center;">
    <div style="padding:5px; border:1px solid gray; margin: 4%;" class="allFont">
      <span>Trigger</span>
    </div>
  </div>
  <div *ngFor="let action of data.data['campaignActions']; let i = index">
    <div style="width:100%; display: flex; justify-content: center;">
      <mat-icon>arrow_downward</mat-icon>
    </div>
    <div *ngIf="action['timeToTriggerInMinutes']" style="width:100%; display: flex; justify-content: center;">
      <div style="margin: 4%;" class="allFont">
        <span>Delay {{convertMinutes(action['timeToTriggerInMinutes'])}}
          {{delayType(action['timeToTriggerInMinutes'])}}</span>
      </div>
    </div>
    <div *ngIf="action['timeToTriggerInMinutes']" style="width:100%; display: flex; justify-content: center;">
      <mat-icon>arrow_downward</mat-icon>
    </div>
    <div *ngIf="action['campaignActionType'] === 'EMAIL_DELIVERY'">
      <div style="width:100%; display: flex; justify-content: center;">
        <div style="margin: 4%; font-weight: bold;" class="allFont">
          <span>Email()</span>
        </div>
      </div>
      <div style="width:100%; display: flex; justify-content: center;">
        <div style="margin: 1%;" class="allFont">
          <span>{{action['messageTemplateParams']['messageTemplateId']['subject']}}</span>
        </div>
      </div>
      <div style="width:100%; display: flex; justify-content: center;">
        <div [innerHtml]="action['messageTemplateParams']['messageTemplateId']['body'] | safeHtml"></div>
      </div>
    </div>
    <div *ngIf="action['campaignActionType'] === 'SMS_DELIVERY'">
      <div style="width:100%; display: flex; justify-content: center;">
        <div style="margin: 4%; font-weight: bold;" class="allFont">
          <span>Sms</span>
        </div>
      </div>
      <div style="width:100%; display: flex; justify-content: center;">
        <div style="margin: 1%; text-align: center;" class="allFont">
          <span>{{action['campaignMessageTemplate']['campaignSMSTemplate']['body']}}</span>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!--Displaying the details of a campaign template Ends here-->

<!--Displaying campaign template in detail-->

<!-- <div *ngIf="data.type === 'campaignTemplate'" style="display:flex;">
  <div style="width:30%; background: #F1F1F1; height: 520px; overflow: scroll;">
    <div style="padding: 20px; border-bottom: 2px solid #c1c1c1;">
      <span style="font-weight:bold;">{{data.data['name']}}</span>
    </div>
    <div style="padding:20px;">
      <div *ngIf="data.data['campaignType'] === 'SEGMENTED'">
        <div style="margin-bottom: 10px;">
          <span style="font-weight:bold;">Targeted Segments</span>
        </div>
        <div style="display:flex; flex-wrap: wrap;">
          <div *ngFor="let segment of data.data['targetCustomerSegments']" style="margin: 5px;
                    padding: 8px;
                    border-radius: 25px;
                    background: #FFFFFF;
                    padding-top: 4px;
                    padding-bottom: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;" class="allFont">
            <div>
              <span>{{segment['segmentName']}}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;">
        <span style="font-weight:bold; padding: 5px;">Trigger Type</span>
        <span style="padding: 5px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;">{{data.data['campaignTrigger']['campaignTriggerType']}}</span>
      </div>
      <div style="margin-top: 10px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: bold;
      display: flex;
      justify-content: center;">
        <span>campaign flow</span>
      </div>
      <div *ngFor="let action of data.data['campaignActions']; let i = index;">
        <div style="display: flex;
        justify-content: center;
        align-items: center;">
          <div style="padding: 4px;
          background: #FFFFFF;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;">
            <mat-icon style="color: #88c4fb;">timer</mat-icon>
            <span>Delay {{convertMinutes(action['timeToTriggerInMinutes'])}}
              {{delayType(action['timeToTriggerInMinutes'])}}</span>
          </div>
        </div>
        <span style="display: flex; justify-content: center;"><img [src]="assetPath.arrowUrl" alt=""></span>
        <div style="    background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border-radius: 4px;
        cursor: pointer;"
          (click)="viewMessageTemplateDetail(action['campaignActionType'],action['messageTemplateParams']['messageTemplateId'],action['messageTemplateParams']['campaignMessageLayOutType'])">
          <span style="display: flex;" *ngIf="action['campaignActionType'] === 'EMAIL_DELIVERY'">
            <mat-icon style="color: #88c4fb;">mail</mat-icon>Email
          </span>
          <span style="display: flex;" *ngIf="action['campaignActionType'] === 'SMS_DELIVERY'">
            <mat-icon style="color: #88c4fb;">mail</mat-icon>SMS
          </span>
        </div>
        <span *ngIf="i<data.data['campaignActions'].length-1" style="display: flex; justify-content: center;"><img
            [src]="assetPath.arrowUrl" alt=""></span>
      </div>
    </div>
  </div>
  <div style="width:70%; height: 520px; overflow: scroll;">
    <div *ngIf="messageTemplate" style="width:100%;">
      <div style="display:flex; justify-content:center; margin: 25px;" *ngIf="messageTemplate['subject']">
        <span style="font-weight: bold; margin-right: 10px;">Subject</span>
        <span>{{messageTemplate['subject']}}</span>
      </div>
      <div style="width:100%;" [innerHtml]="messageTemplate['body'] | safeHtml"></div>
    </div>
  </div>
</div> -->

<!--Displaying campaign template in detail ends here-->

<!--Displaying details of  a campaign-->

<!-- <div *ngIf="data.type === 'campaign'">
  <div *ngFor="let action of data.data; let i = index">
    <div *ngIf="action['timeToTriggerInMinutes']" style="width:100%; display: flex; justify-content: center;">
      <div style="margin: 4%;" class="allFont">
        <span>Delay {{convertMinutes(action['timeToTriggerInMinutes'])}}
          {{delayType(action['timeToTriggerInMinutes'])}}</span>
      </div>
    </div>
    <div *ngIf="action['timeToTriggerInMinutes']" style="width:100%; display: flex; justify-content: center;">
      <mat-icon>arrow_downward</mat-icon>
    </div>
    <div style="width:100%; display: flex; justify-content: center;">
      <div style="margin: 4%; font-weight: bold;" class="allFont">
        <span>Email({{action['campaignMessageTemplate']['campaignEmailTemplate']['campaignEmailTemplateType'] === 'OFFER_TEMPLATE' ?
              'Offer' : action['campaignMessageTemplate']['campaignEmailTemplate']['campaignEmailTemplateType'] === 'BASIC_TEMPLATE' ?
            'Basic' : action['campaignMessageTemplate']['campaignEmailTemplate']['campaignEmailTemplateType'] === 'REMINDER_TEMPLATE' ?
          'Reminder' : 'Reward'}})</span>
      </div>
    </div>
    <div style="width:100%; display: flex; justify-content: center;">
      <div style="margin: 1%;" class="allFont">
        <span>{{action['campaignMessageTemplate']['campaignEmailTemplate']['subject']}}</span>
      </div>
    </div>
    <div style="width:100%; display: flex; justify-content: center;">
      <div style="margin: 1%; text-align: center;" class="allFont">
        <span>{{action['campaignMessageTemplate']['campaignEmailTemplate']['body']}}</span>
      </div>
    </div>
  </div>
</div> -->

<!--Displaying details of  a campaign Ends here-->

<div class="allFont" *ngIf="data.type === 'templateVariable'">
  <div style="display: flex; justify-content: center">
    <div>{{'SHARED_PAGE.SELECT_VARIABLE' | i18nTranslate}}</div>
  </div>
  <div style="display: flex; justify-content: center">
    <div>
      <div
        style="
          padding: 8px;
          display: flex;
          justify-content: center;
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.05),
            0px 8px 10px 1px rgba(0, 0, 0, 0),
            0px 3px 14px 2px rgba(0, 0, 0, 0.05);
        "
        *ngFor="let variable of data.data"
        (click)="selectVariable(variable)"
      >
        <span>{{ variable }}</span>
      </div>
    </div>
  </div>
</div>

<!--Displayinng uploaded html equivalent screen-->
<div *ngIf="data.type === 'viewHtmlContent'">
  <!-- <quill-view-html [content]="data.data" theme="snow">
      </quill-view-html> -->
  <app-email-template-preview
    [selectedLayout]="data.data"
  ></app-email-template-preview>
</div>
<!--Displayinng uploaded html equivalent screen ends here-->

<div
  *ngIf="data.type === 'viewEmailTemplate'"
  style="overflow: auto; height: 500px"
>
  <!-- <div>
    <span>Subject</span>
    <span>{{data.data['subject']}}</span>
  </div> -->
  <!-- <quill-view-html [content]="data.data.body" theme="snow">
      </quill-view-html> -->
  <app-email-template-preview
    style="overflow: auto"
    [selectedLayout]="data.data.body"
  ></app-email-template-preview>
</div>

<!--Displaying layout-->

<div style="width: 100%; height: 100%" *ngIf="data.type === 'viewLayout'">
  <app-email-template-preview
    style="height: 100%"
    [selectedLayout]="data.data"
  ></app-email-template-preview>
</div>

<!--Displaying layout-->

<!--Displaying Segment in detail-->

<!-- <div class="allFont" *ngIf="data.type === 'segmentView'"
  style="display:flex; flex-wrap: wrap; justify-content: center;">
  <div *ngFor="let expression of data.data['logicalExpressions']" style="display:flex; flex-wrap: wrap;">
    <span *ngIf="expression['logicalOperator']">&nbsp;{{expression['logicalOperator']}}&nbsp;</span>
    <span>(</span>
    <div *ngFor="let attribute of expression['comparisonStatements']" style="display:flex; flex-wrap: wrap;">
      <span *ngIf="attribute['logicalOperator']">&nbsp;{{attribute['logicalOperator']}}&nbsp;</span>
      <span>( {{attribute['operand1'].substring(1,attribute['operand1'].length)}}&nbsp;</span>
      <span>{{attribute['operator']}}&nbsp;</span>
      <span>{{attribute['operand2']}} )</span>
    </div>
    <span>)</span>
  </div>
</div> -->

<!--Displaying segment in detail ends here-->

<!--Displaying offer templates for campaign templates-->

<div *ngIf="data.type === 'offerForCampaign'" class="allFont">
  <div *ngFor="let offer of data.data; let i = index">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px;
        border-bottom: 2px solid #e2e2e2;
        align-items: center;
      "
    >
      <span>{{ offer["offerName"] }}</span>
      <span
        style="
          padding: 5px;
          background: #36d6e7;
          border-radius: 3px;
          cursor: pointer;
        "
        (click)="selectOfferForCampaign(offer['offerId'])"
        >{{'SHARED_PAGE.SELECT' | i18nTranslate}}</span
      >
    </div>
  </div>
</div>

<!--Displaying offer templates for campaign templates ends here-->

<!--Offer Template detailed view-->

<!-- <div *ngIf="data.type === 'OfferTemplateDetailedView'" style="display:flex;">
  <div style="width:30%; background: #F1F1F1; overflow: scroll; height:520px;">
    <div *ngIf="selectedbarrier">
      <span style="display: flex;
        padding: 20px;
        border-bottom: 1px solid #d8d8d8;
        font-weight: bold;">{{selectedbarrier['barrierName']}}</span>
      <div>
        <div style="padding: 20px;">
          <span>Barrier Type</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['barrierParamType']}}</span>
          </div>
        </div>
      </div>
      <div>
        <div style="padding: 20px;">
          <span>Hurldle Type</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['barrierType']}}</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedbarrier['barrierParamType'] === 'PERSONALIZED' && selectedbarrier['barrierType'] === 'PRODUCT_BASED'">
        <div style="padding: 20px;">
          <span>Propensity</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span *ngIf="selectedbarrier['productPropensity']">{{selectedbarrier['productPropensity']}}</span>
            <span
              *ngIf="selectedbarrier['productCategoryPropensity']">{{selectedbarrier['productCategoryPropensity']}}</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedbarrier['barrierParamType'] !== 'PERSONALIZED' && selectedbarrier['barrierType'] === 'PRODUCT_BASED'">
        <div style="padding: 20px;">
          <span *ngIf="selectedbarrier['product']">Product</span>
          <span *ngIf="selectedbarrier['productCategory']">Product Category</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span *ngIf="selectedbarrier['product']">{{selectedbarrier['product']['productName']}}</span>
            <span
              *ngIf="selectedbarrier['productCategory']">{{selectedbarrier['productCategory']['productCategoryName']}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="selectedbarrier['barrierType'] === 'PRODUCT_BASED'">
        <div style="padding: 20px;">
          <span>Product Count</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['product']['productCount']}}</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedbarrier['barrierParamType'] !== 'PERSONALIZED' && selectedbarrier['barrierType'] === 'SPENT_BASED'">
        <div style="padding: 20px;">
          <span>Amount</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['spentBarrier']}}</span>
          </div>
        </div>
      </div>
      <div>
        <div style="padding: 20px;">
          <span>Time Range</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['barrierDates']['startTime']}} - </span>
            <span>{{selectedbarrier['barrierDates']['endTime']}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="selectedbarrier['barrierDates']['daysOfTheWeek']">
        <div style="padding: 20px;">
          <span>Days of the week</span>
          <div style="min-width: 70%; margin-top: 15px;">
            <span *ngFor="let day of selectedbarrier['barrierDates']['daysOfTheWeek']"
              style="margin: 5px; background: #FFFFFF; padding: 5px; border-radius: 25px;">{{day}}</span>
          </div>
        </div>
      </div>
      <div>
        <div style="padding: 20px;">
          <span>Time to complete</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['barrierDates']['endDayCount']}} days</span>
          </div>
        </div>
      </div> -->
<!-- <div *ngIf="selectedbarrier['offerReward']">
        <div style="padding: 20px;">
          <span>Reward</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>{{selectedbarrier['offerReward']['rewardtype']}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!selectedbarrier['offerReward']">
        <div style="padding: 20px;">
          <span>Reward</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span>No Reward</span>
          </div>
        </div>
      </div>
      <div *ngIf="selectedbarrier['offerReward']">
        <div style="padding: 20px;">
          <span *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT'">Reward Product</span>
          <span *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'ALL_PRODUCT_DISCOUNT'">Discount</span>
          <span *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'REWARD_POINTS'">Reward Points</span>
          <span
            *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">Discount
            on</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT'">{{selectedbarrier['offerReward']['freeProducts'][0]['productName']}}</span>
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'ALL_PRODUCT_DISCOUNT'">{{selectedbarrier['offerReward']['discount']['discountPercentage']}}</span>
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'REWARD_POINTS'">{{selectedbarrier['offerReward']['rewardPoint']['rewardPoints']}}</span>
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT'">{{selectedbarrier['offerReward']['discountedProducts'][0]['productName']}}</span>
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">{{selectedbarrier['offerReward']['discountedProductCategories'][0]['productCategoryName']}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="selectedbarrier['offerReward']">
        <div style="padding: 20px;"
          *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">
          <span
            *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">Discount</span>
          <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT'">{{selectedbarrier['offerReward']['discountedProducts'][0]['discount']['discountPercentage']}}</span>
            <span
              *ngIf="selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">{{selectedbarrier['offerReward']['discountedProductCategories'][0]['discount']['discountPercentage']}}</span>
          </div>
        </div>
      </div> -->
<!-- </div>
  </div>
  <div style="width:70%; overflow: scroll; height:500px; padding: 20px;">
    <span style="display: flex; justify-content: center; text-transform: uppercase;    padding-top: 30px;
    padding-bottom: 30px; font-weight: bold;">{{data.data['offerName']}}</span>
    <div style="display:flex; justify-content:center;">
      <div style="display:flex; flex-direction:column; justify-content:space-between; width: 70%;">
        <div *ngFor="let barrier of data.data['barrierList'];let i=index">
          <div style="    display: flex;
              flex-direction: column;">
            <span style="padding: 15px;
                  background: #F5F5F5;
                  border: 1px solid #DEDEDE;
                  border-radius: 4px;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;" (click)="selectBarrier(barrier)">Barrier {{i+1}}</span>
            <span
              *ngIf="i<data.data['barrierList'].length && ( barrier['offerReward'] || data.data['barrierList'][i+1] )"
              style="display: flex; justify-content: center;"><img [src]="assetPath.arrowUrl" alt=""></span>
          </div>
          <div *ngIf="barrier['offerReward']" style="display: flex;
              flex-direction: column;">
            <span style="padding: 15px;
                  background: #F5F5F5;
                  border: 1px solid #DEDEDE;
                  border-radius: 4px;
                  cursor: pointer;" (click)="selectBarrier(barrier)">
              <div>
                <div style="display: flex; justify-content: center;">
                  <div>
                    <span>Reward Type : </span>
                    <span>{{barrier['offerReward']['rewardtype']}}</span>
                  </div>
                </div>
                <div style="display: flex; justify-content: center;">
                  <div>
                    <span *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT'">Reward Product : </span>
                    <span *ngIf="barrier['offerReward']['rewardtype'] === 'ALL_PRODUCT_DISCOUNT'">Discount : </span>
                    <span *ngIf="barrier['offerReward']['rewardtype'] === 'REWARD_POINTS'">Reward Points : </span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || barrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">Discount
                      on :</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT'">{{barrier['offerReward']['freeProducts'][0]['productName']}}</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'ALL_PRODUCT_DISCOUNT'">{{barrier['offerReward']['discount']['discountPercentage']}}</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'REWARD_POINTS'">{{barrier['offerReward']['rewardPoint']['rewardPoints']}}</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT'">{{barrier['offerReward']['discountedProducts'][0]['productName']}}</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">{{barrier['offerReward']['discountedProductCategories'][0]['productCategoryName']}}</span>
                  </div>
                </div>
                <div
                  *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || barrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'"
                  style="display: flex; justify-content: center;">
                  <div>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || barrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">Discount
                      :</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT'">{{barrier['offerReward']['discountedProducts'][0]['discount']['discountPercentage']}}</span>
                    <span
                      *ngIf="barrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">{{barrier['offerReward']['discountedProductCategories'][0]['discount']['discountPercentage']}}</span>
                  </div>
                </div>
              </div>
            </span>
            <span
              *ngIf="i<data.data['barrierList'].length-1"
              style="display: flex; justify-content: center;"><img [src]="assetPath.arrowUrl" alt=""></span>
          </div> -->
<!-- <span *ngIf="i<data.data['barrierList'].length" style="display: flex; justify-content: center;"><img
              [src]="assetPath.arrowUrl" alt=""></span> -->
<!-- </div> -->
<!-- <div style="display: flex;
        width: 100%;
        justify-content: center;">
          <span style="padding: 15px;
              width:100%;
              background: #F5F5F5;
              border: 1px solid #DEDEDE;
              border-radius: 4px;
              margin-bottom: 25px;">
            <div>
              <div style="display: flex; justify-content: center;">
                <div>
                  <span>Reward Type : </span>
                  <span>{{data.data['reward']['rewardtype']}}</span>
                </div>
              </div>
              <div style="display: flex; justify-content: center;">
                <div>
                  <span *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT'">Reward Product : </span>
                  <span *ngIf="data.data['reward']['rewardtype'] === 'ALL_PRODUCT_DISCOUNT'">Discount : </span>
                  <span *ngIf="data.data['reward']['rewardtype'] === 'REWARD_POINTS'">Reward Points : </span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">Discount
                    on :</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT'">{{data.data['reward']['freeProducts'][0]['productName']}}</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'ALL_PRODUCT_DISCOUNT'">{{data.data['reward']['discount']['discountPercentage']}}</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'REWARD_POINTS'">{{data.data['reward']['rewardPoint']['rewardPoints']}}</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT'">{{data.data['reward']['discountedProducts'][0]['productName']}}</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">{{data.data['reward']['discountedProductCategories'][0]['productCategoryName']}}</span>
                </div>
              </div>
              <div
                *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'"
                style="display: flex; justify-content: center;">
                <div>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT' || selectedbarrier['offerReward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">Discount
                    :</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_SPECIFIC_DISCOUNT'">{{data.data['reward']['discountedProducts'][0]['discount']['discountPercentage']}}</span>
                  <span
                    *ngIf="data.data['reward']['rewardtype'] === 'PRODUCT_CATEGORY_SPECIFIC_DISCOUNT'">{{data.data['reward']['discountedProductCategories'][0]['discount']['discountPercentage']}}</span>
                </div>
              </div>
            </div>
          </span>
        </div> -->
<!-- </div>
      <div style="display:flex; justify-content:center;">

      </div> -->

<!-- <span style="padding: 15px;
      padding-left: 200px;
      padding-right: 200px;
      background: #F5F5F5;
      border: 1px solid #DEDEDE;
      border-radius: 4px;">Reward</span> -->
<!-- </div>
  </div>
</div> -->

<!--Offer Template detailed view ends here-->

<!-- <div *ngIf="data.type === 'viewSeg'" style="display:flex; margin:3%">
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;width:100%">
    <div style="text-align: left;
      font-size: 14px; font-weight: 600;
      letter-spacing: 0;
      color: #000000;
      text-transform: uppercase;
      opacity: 1; margin-bottom: 18px;">{{data.data['segmentName']}}</div>

    <span *ngFor="let expression of data.data['segmentDefinition']['logicalExpressions']"
      style="display:flex; flex-direction: column; ">
      <div style="display: flex;justify-content: center; align-items: center;text-align: left;
    ;
    letter-spacing: 0;
    color: #0CA4C7;
    text-transform: uppercase;
    opacity: 1;" *ngIf="expression['logicalOperator']">
        <div>
          <img style="height:100%" src="assets/Group 1270.svg">
        </div>&nbsp;{{expression['logicalOperator']}}&nbsp;
      </div>
      <div style="background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    opacity: 1; padding:0px 30px;">
        <span *ngFor="let attribute of expression['comparisonStatements']" style="display:flex;">
          <span style="text-align: left;
              font-size: 13px;
              letter-spacing: 0;
              color: #0CA4C7;
              text-transform: uppercase;
              opacity: 1;" *ngIf="attribute['logicalOperator']">&nbsp;{{attribute['logicalOperator']}}&nbsp;</span>
          <span >(
            {{attribute['operand1'].substring(1,attribute['operand1'].length)}}&nbsp;</span>
          <span >{{attribute['operator']}}&nbsp;</span>
          <span >{{attribute['operand2']}} )</span>
        </span>
      </div>
    </span>
  </div>
</div> -->
