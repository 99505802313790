<div class="header" >
  <span class="header-title"><strong>{{'SHARED_PAGE.CONNECT_PAYPAL' | i18nTranslate}}</strong></span>
  <mat-dialog-actions>
    <button class="xIcon" mat-dialog-close><div class="material-icons" style="margin-left: -4.5px;
      font-size: 15px;">clear</div></button>
  </mat-dialog-actions>
</div>
<div data-widget-name="SUPPRESSION LISTS">
  <div>
    <div class="enterEmail">{{'SHARED_PAGE.PAYPAL_PARAGRAPH' | i18nTranslate}}</div>
    <div class="selectDomainName">
      <span class="filterTitle">{{'SHARED_PAGE.PAYPAL_ID' | i18nTranslate}}</span>
      <input type="text" [(ngModel)]="data.payPalId" (input)="error = ''">
      <div *ngIf="error.length > 0" class="error">
        {{error}}
      </div>
    </div>
  </div>
    <!-- </div> -->
  <!-- </div> -->
  <div class="buttons">
    <mat-dialog-actions>
      <button class="confirm primary-button" (click)="add()" data-button-name="Confirm: Contact Added" [attr.data-item-name]="email" >{{'SHARED_PAGE.CONTINUE' | i18nTranslate}}</button>
    </mat-dialog-actions>
  </div>
</div>