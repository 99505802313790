import { Injectable, OnInit } from "@angular/core";
import { TokenStorageService } from "../auth/token-storage.service";

@Injectable()
export class getdateformatService implements OnInit {
  constructor(
    private tokenStorage: TokenStorageService,
  ){}
  ngOnInit(){
    }
    getDateFormat(knownType?){
        if(sessionStorage.getItem("serviceCountry") === 'US'){
            return "MM/dd/yyyy";
        }
        else{
            return knownType? knownType : "dd/MM/yyyy";
        }
    }
  
}