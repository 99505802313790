import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { PercentPipe } from '@angular/common';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {

  constructor() {
    this.getScreenSize();
  }

  @Input() data;
  @Input() id;
  @Input() maxX;
  @Input() maxY;
  @ViewChild('chartContainer') chartContainer: ElementRef;

  scrHeight;
  scrWidth;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    //this.scrContentHeight = window.innerHeight - 60 - 250;
    this.scrWidth = window.innerWidth;
  }

  ngOnChanges() {
    if (this.data) {
      this.graphRenderer();
    }
  }

  //margin = { top:20 , bottom: 20, left:20, right:20};

  graphRenderer() {

    let margin;
    let ind;
    let ticks;
    this.scrWidth > 1500 ? (ind = 30, ticks = 5) : (ind = 10, ticks = 3);
    margin = { top: ind, bottom: ind, left: ind, right: ind };
    let width = this.chartContainer.nativeElement.offsetWidth - margin.left - margin.right// Use the window's width
    let height = this.chartContainer.nativeElement.offsetWidth - margin.top - margin.bottom // Use the window's height


    if (height > 0 && width > 0 && this.data.length > 0) {



      let interval = setInterval(() => {

        if (this.data) {
          svg = d3.select(this.chartContainer.nativeElement);
          svg.selectAll("*").remove()
          let dataset = this.data;
          var parseTime = d3.timeParse("%Y-%m-%d")
          const bisectDate = d3.bisector(d => d.x_value).left;
          this.data.forEach(function (d) {
            d.x_value = parseTime(d.x_value);
            d.y_value = +d.y_value;
          });

          // parse the date / time

          //let data1 = [{x_value : 'abi', y_value : 2},{x_value : 'abi', y_value : 2},{x_value : 'abi', y_value : 2},{x_value : 'abi', y_value : 2}]

          // set the ranges
          // let xValue = this.data.map(function (d) { return d.x_value })
          // let xScale = d3.scaleOrdinal()
          //   .domain(xValue)
          //   .range([0,width],0.2);

          let xValue = this.data.map(function (d) { return d.x_value })
          let xScale = d3.scaleTime().range([0, width]);

          // 6. Y scale will use the randomly generate number
          let max = d3.max(this.data, function (d) { return +d.y_value; });
          let yScale = d3.scaleLinear().range([height, 0]); // output

          // define the line

          // append the svg obgect to the body of the page
          // appends a 'group' element to 'svg'
          // moves the 'group' element to the top left margin
          var svg = d3.select(this.chartContainer.nativeElement).append("svg")
            .attr("width", "100%")
            .attr("height", height + margin.right + margin.top)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          var valueline = d3.line()
            .x(function (d) { return xScale(d.x_value); })
            .y(function (d) { return yScale(d.y_value); })
            .curve(d3.curveMonotoneX);

          xScale.domain(d3.extent(this.data, function (d) { return d.x_value; }))
          yScale.domain([d3.min(this.data, function (d) { return d.y_value; }) / 1.005, d3.max(this.data, function (d) { return d.y_value; }) * 1.005])


          // gridlines in x axis function

          // svg.append("text")
          //   .text("days")
          //   .attr("transform", "translate(" + (width - 40) + "," + (height + 30) + ")")
          //   .style("font-size", "12px")
          //   .style("fill", "black")

          // svg.append("text")
          //   .text("rate")
          //   .attr("transform", "translate(-30,50)rotate(270)")
          //   .style("font-size", "12px")
          //   .style("fill", "black")

          // add the X gridlines
          // svg.append("g")			
          //     .attr("class", "grid")
          //     .style("stroke","lightgrey")
          //     .style("stroke-width","0.2px")
          //     .style("shape-rendering","crispEdges")
          //     .attr("transform", "translate(0," + height + ")")
          //     .call(this.make_x_gridlines(xScale)
          //         .tickSize(-height)
          //         .tickFormat("")
          //     )

          // add the Y gridlines

          svg.append("g")
            .attr('stroke-width', 0)
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(xScale)
            .ticks(5));
          //.tickValues([1,7,13,19,25]));

          // add the Y Axis
          let radius = 4;

          svg.append("g")
            .attr('stroke-width', 0)
            .call(d3.axisLeft(yScale));


          svg.append("g")
            .attr("class", "grid")
            .style("color", "#eeeeee")
            .style("stroke-width", "2px")
            .style("stroke-dasharray", ("5, 3"))
            .style("shape-rendering", "crispEdges")
            .call(make_y_gridlines(yScale)
              .tickSize(-width)
              .tickFormat("")
            )

          // add the valueline path.
          svg.append("path")
            .datum(this.data)
            .attr("class", "line")
            .style("fill", "none")
            .style("stroke", "#3ccbed")
            .style("stroke-width", "2px")
            .attr("d", valueline);

          // add the X Axis

          let tickGap = Math.round(this.data.length / ticks);
          let tickValue = []
          for (let i = 0; i < this.data.length; i += tickGap) {
            tickValue.push(this.data[i]['x_value'])
          }
          // if(this.data.length%ticks !== 0){
          //   tickValue.push(this.data[this.data.length]['x_value'])
          // }



          var focus = svg.append("g")
            .attr("class", "focus")
            .style("display", "none");

          focus.append("text")
            .attr("x", 9)
            .attr("dy", ".35em");


          svg.selectAll(".dot")
            .data(this.data)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", function (d, i) { return xScale(d.x_value) })
            .attr("cy", function (d) { return yScale(d.y_value) })
            .attr("r", radius)
            .style("stroke", "#08b4b9")
            .style("fill", "#08b4b9");


          focus.append("line")
            .attr("class", "x-hover-line hover-line")
            .attr("y1", 0)
            .attr("y2", height);

          focus.append("line")
            .attr("class", "y-hover-line hover-line")
            .attr("x1", width)
            .attr("x2", width);

          focus.append("circle")
            .attr("r", 7.5);

          focus.append("text")
            .attr("x", 15)
            .attr("dy", ".31em");


          svg.append("rect")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr("width", width)
            .attr("height", height)
            .on("mouseover", function () { focus.style("display", null); })
            .on("mouseout", function () { focus.style("display", "none"); })
            .on("mousemove", function mousemove() {
              var x0 = xScale.invert(d3.mouse(this)[0]),
                i = bisectDate(this.data, x0, 1),
                d0 = this.data[i - 1],
                d1 = this.data[i],
                d = x0 - d0.x_value > d1.x_value - x0 ? d1 : d0;
              focus.attr("transform", "translate(" + xScale(d.x_value) + "," + yScale(d.y_value) + ")");
              focus.select("text").text(function () { return d.y_value; });
              focus.select(".x-hover-line").attr("y2", height - yScale(d.y_value));
              focus.select(".y-hover-line").attr("x2", width + width);
            });
          // .on("mouseover", function (d, i) {
          //   d3.select(this)
          //     .transition(250)
          //     .attr("r", radius * 2);
          //   focus.attr("transform", "translate(" + xScale(d.x_value) + "," + yScale(d.y_value) + ")");
          //   focus.append("text");
          //   focus.style("display", null);
          // })
          // .on("mouseout", function (d, i) {
          //   focus.style("display", "none");
          //   d3.select(this)
          //     .transition(750)
          //     .attr("r", radius);
          // });
          // .on('mousemove',(d)=>{
          //   let xPos = d3.event.pageX/30;
          //   let yPos = d3.event.pageY/45;
          //   tooltip.attr('transform','translate('+ xPos +','+ yPos +')');
          //   tooltip.select('text')
          //   .attr('x',xPos)
          //   .attr('y',yPos)
          //   .text(d.value)
          // };


          clearInterval(interval)

        }

      }, 200)

    }



    let radius = 4
    function handleMouseOver(d, i) {
      d3.select(this)
        .transition(250)
        .attr("r", radius * 2);
    }

    function handleMouseOut(d, i) {
      d3.select(this)
        .transition(750)
        .attr("r", radius);
    }

    function make_y_gridlines(y) {
      return d3.axisLeft(y)
        .ticks(5)
    }

  }

  ngOnInit() {
  }

}

  // make_x_gridlines(x) {
  //   return d3.axisBottom(x)
  //     .ticks(5)
  // }

  // gridlines in y axis function
