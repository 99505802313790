import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { FilterDialogService } from "src/app/core/services/filter-dialog.service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: 'app-create-new-customer-list',
  templateUrl: './create-new-customer-list.component.html',
  styleUrls: ['./create-new-customer-list.component.css']
})
export class CreateNewCustomerListComponent implements OnInit {

    validWidgets = null;
    dialogPosition: DialogPosition = {
      top: "0px",
      right: "0px",
    };
    selected = "Purchase Frequency";
    merchantID = this.tokenService.getMerchantId();
    revenueSortBy = "All-Time";
    numberOfOrdersSortBy = "All-Time";
    revenueTime = "ALL";
    numberOfOrdersTime = "ALL";
    pageSize = 10;
    pageIndex = 1;
    customers = [];
    popupElementID;
    totalCustomers;
    sortBy = "";
    filters = {
      segments: [],
    };
    showCustomers = "all";
    searchValue = "";
    filterSegments = [];
    selectedSortBy = "Desc";
    timeout;
    sub: Subscription;
    @ViewChild("search") searchElement: ElementRef;
    revCurrency;
    mobileView;
    mobileMedia = window.matchMedia("(max-width: 896px)");
    showFiltersDiv = false;
    segments = {};
    loading = true;
    overallTotalCustomers=null;
    selectedCustomerList = []; 
    customerListName =  "";
    designation = "";
    submit = false;
    currentUrl = "";
    listType = "";
  
    
    constructor(
      private _WidgetConfigService: WidgetConfigService,
      private apiCall: ApiCallService,
      private tokenService: TokenStorageService,
      private date_format: getdateformatService,
      private dialog: MatDialog,
      private _i18nDynamicTranslate: TranslateDynamicText,
      private router: Router,
      private snackBar: SnackbarCollection
    ) {
      window["screen-name"] = "ALL CUSTOMERS";
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url.split(";")[0];
        }
      });
    }
  
    @HostListener("document:click", ["$event"])
    closeSegmentsDiv() {
      let ele = document.getElementById(this.popupElementID);
      if (ele) ele.setAttribute("style", "display:none");
    }
    ngOnInit() {    
      if (this.mobileMedia.matches) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      this.listType = this.getListType();
      this.revCurrency = sessionStorage.getItem("serviceCurrency");
      this.getTotalCustomerCount();
      this.getWidgets();
      this.getCustomers();
      // this.getSegments();
    }
  
    addSegmentFilter(value) {
      if (this.filters["segments"].indexOf(value) != -1) {
        this.filters["segments"].splice(
          this.filters["segments"].indexOf(value),
          1
        );
        if (this.filters["segments"].indexOf("ALL") != -1 && value !== "ALL") {
          this.filters["segments"].splice(
            this.filters["segments"].indexOf("ALL"),
            1
          );
        }
      } else {
        this.filters["segments"].push(value);
        if (value === "ALL")
          for (let key in this.segments) {
            if (this.filters["segments"].indexOf(key) == -1)
              this.filters["segments"].push(key);
          }
      }
      for (let key in this.segments) {
        if (this.filters["segments"].indexOf(key) == -1)
          if (this.filters["segments"].indexOf("ALL") != -1)
            this.filters["segments"].splice(
              this.filters["segments"].indexOf("ALL"),
              1
            );
      }
      if (value === "ALL") {
        if (this.filters["segments"].indexOf("ALL") == -1) {
          this.filters["segments"] = [];
        }
      }
    }
  
    clear() {
      this.filters["segments"] = [];
    }
  
    getSegments() {
      this.apiCall.getAllCustomerSegments().subscribe((response) => {
        JSON.parse(response["body"]).forEach((element) => {
          this.segments[element["id"]] = element["segmentName"];
        });
        // this.loading = false;
      });
    }
  
    async getWidgets() {
      this.validWidgets = await this._WidgetConfigService.getWidget();
    }
    reOrder() {
      if (this.selectedSortBy === "Desc") {
        this.sort("Asc");
      } else {
        this.sort("Desc");
      }
    }
  
    sort(sortBy) {
      this.selectedSortBy = sortBy;
      switch (sortBy) {
        case "Asc":
          if (this.selected == "Purchase Frequency")
            this.customers.sort((a, b) =>
              a.purchaseFrequency < b.purchaseFrequency ? -1 : 1
            );
          else if (this.selected == "Number of Orders")
            this.customers.sort((a, b) =>
              a.orderQuantity < b.orderQuantity ? -1 : 1
            );
          else this.customers.sort((a, b) => (a.revenue < b.revenue ? -1 : 1));
          break;
        case "Desc":
          if (this.selected == "Purchase Frequency")
            this.customers.sort((a, b) =>
              a.purchaseFrequency > b.purchaseFrequency ? -1 : 1
            );
          else if (this.selected == "Number of Orders")
            this.customers.sort((a, b) =>
              a.orderQuantity > b.orderQuantity ? -1 : 1
            );
          else this.customers.sort((a, b) => (a.revenue > b.revenue ? -1 : 1));
      }
    }
  
    getCustomers() {
      this.loading = true;
      this.apiCall
        .getCustomersV2(
          this.merchantID,
          this.pageSize,
          this.pageIndex,
          this.filterSegments
        )
        .subscribe((response) => {
          this.showCustomers = "all";
          if (this.mobileView) {
            (response["body"]).forEach((element) => {
              this.customers.push(element);
            });
          } else {
            this.customers = (response["body"]['customerDataList']);
          }
          this.totalCustomers = response["body"]['totalCount'];
          this.customers.forEach((customer)=>{
            if(customer['customerSegments'] && customer['customerSegments'].length>0) {
              customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
            } else {
              customer.segments = [];
            }
          });
          this.loading = false;
        },(err)=>{
          this.snackBar.openSnackBar('Failed to fetch custom list',5000);
        });
    }
  
    tableSortBy(sortFor) {
      switch (sortFor) {
        case "revenue":
          if (this.revenueSortBy === "All-Time") this.revenueTime = "ALL";
          else this.revenueTime = "30";
          break;
        case "numberOfOrders":
          if (this.numberOfOrdersSortBy === "All-Time")
            this.numberOfOrdersTime = "ALL";
          else this.numberOfOrdersTime = "30";
      }
      this.changeSortByValue();
      if (this.searchValue.length > 0)
        this.searchCustomer(this.searchValue, false);
      else this.getCustomers();
    }
  
    showSegments(event, i) {
      let ele = document.getElementById(this.popupElementID);
      if (ele) ele.setAttribute("style", "display:none");
      let id = event.srcElement.parentElement.id;
      event.stopPropagation();
      let element = document
        .getElementById(id)
        .getElementsByClassName("qb-segment-popup")[0];
      element.setAttribute("style", "display:flex");
      this.popupElementID = "popup" + i;
    }
  
    refreshPaginatedData(event) {
      this.pageIndex = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      if (this.showCustomers === "all") this.getCustomers();
      else this.searchCustomer(this.searchValue, false);
    }
  
    getTotalCustomerCount() {
      
      this.apiCall.getAnalytics(new Date(), new Date()).subscribe((response) => {
        this.totalCustomers = JSON.parse(response["body"]).numberOfCustomers;
        this.overallTotalCustomers=JSON.parse(response["body"]).numberOfCustomers;
      });
    }
  
    showSearch() {
      this.searchElement.nativeElement.focus();
    }
  
    changeSortByValue() {
      if (this.selected == "Purchase Frequency") this.sortBy = "";
      else if (this.selected == "Number of Orders")
        this.sortBy = this.numberOfOrdersSortBy;
      else this.sortBy = this.revenueSortBy;
      this.sort(this.selectedSortBy);
    }
  
    onKeySearch(event: any, resetPagination) {
      clearTimeout(this.timeout);
      var $this = this;
      if(event.length !==0){
        this.timeout = setTimeout(function () {
          // if (event.keyCode != 13) {
            $this.searchCustomer(event, resetPagination); //Your function goes here
          // }
        }, 600);
      }
      else{//if search value is 0
       this.getCustomers();
      }
    }
  
    searchCustomer(searchValue, resetPagination) {
      this.loading = true;
      if (resetPagination) this.pageIndex = 1;
      if (this.sub) {
        this.sub.unsubscribe();
      }
      this.sub = this.apiCall
        .searchCustomers(
          this.merchantID,
          searchValue,
          this.revenueTime,
          this.numberOfOrdersTime,
          this.pageSize,
          this.pageIndex,
          this.filterSegments
        )
        .subscribe((response) => {
          this.showCustomers = "search";
          if (!this.mobileView) {
            this.customers = (response["body"]['customerDataList']);
          } else {
            if (this.searchValue !== searchValue) {
              this.customers = (response["body"]['customerDataList']);
            } else {
              (response["body"]['customerDataList']).forEach((element) => {
                this.customers.push(element);
              });
            }
          }
          this.searchValue = searchValue;
          this.totalCustomers = response["body"]['totalCount'];
          this.customers.forEach((customer)=>{
            if(customer['customerSegments'] && customer['customerSegments'].length>0) {
              customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
            } else {
              customer.segments = [];
            }
          });
          this.loading = false;
        },(err)=>{
          this.snackBar.openSnackBar('Failed to search customer',5000);
        });
    }
  
    getSearchCount(searchValue) {
      this.apiCall
        .getSearchCount(searchValue, this.filterSegments)
        .subscribe((response) => {
          this.totalCustomers = response["body"];
        });
        if(searchValue.length === 0){
          this.getTotalCustomerCount();
        }
    }
  
    getFilteredCustomersCount() {
      this.apiCall
        .getSegementPaginated(this.merchantID, this.filterSegments)
        .subscribe((response) => {
          this.totalCustomers = response["body"];
        });
    }
    getDateFormat(type?){
      // this.date_format.getDateFormat();
      return this.date_format.getDateFormat(type);
    }
  
  submitCustomerListData(){
    this.submit = true;
    if(this.customerListName.length > 0 && this.selectedCustomerList.length > 0){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Save Custom List", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to Save the Custom List?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
          "data-widget": "CUSTOMER LIST CREATION",
          "data-button": "Confirm: Save Custom List",
          "data-item": "",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          let data :any = {};
          data.customerListName = this.customerListName;
          data.merchantId = this.tokenService.getMerchantId();
          data.customerIds = this.selectedCustomerList;
          data.operation = "create";
          data.createdBy = {
            storeId: this.tokenService.getqId() === "null" ? null : this.tokenService.getqId(),
            storeName:  this.tokenService.getStoreName()
        };
          this.customerListCreateApi(data).then((result) => {
            if(result){
             console.log(result)
             const dialogRef = this.dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Your Custom List has been successfully created", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("Back to Custom List", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((re) => {
                let routeId = result["body"].split("=")[1];
                this.router.navigate(["/app/customer-list", { id: routeId }]);
            });
            }
          },(error)=>{
            this.snackBar.openSnackBar("Custom List Name Already Exists", 2000);
          });
        }
      });
    }
  }

  submitEmployeeListData(){
    this.submit = true;
    if(this.customerListName.length > 0 && this.selectedCustomerList.length > 0 && this.designation.length > 0){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Save Employee List", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to Save the Employee List?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
          "data-widget": "EMPLOYEE LIST CREATION",
          "data-button": "Confirm: Save Employee List",
          "data-item": "",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          let data :any = {};
          data.employeeListName = this.customerListName;
          data.employeeDesignationName = this.designation;
          data.merchantId = this.tokenService.getMerchantId();
          data.customerIds = this.selectedCustomerList;
          data.operation = "create";
          this.employeeListCreateApi(data).then((result) => {
            if(result){
             console.log(result)
             const dialogRef = this.dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Your Employee List has been successfully created", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("Back to Employee List", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((re) => {
                let routeId = result["body"].split("=")[1];
                this.router.navigate(["/app/customer-list/employee", { id: routeId }]);
            });
            }
          },(error)=>{
            this.snackBar.openSnackBar("Employee List Name Already Exists", 2000);
          });
        }
      });
    }
  }

  checkWhetherSelected(id){
    if(this.selectedCustomerList.indexOf(id) === -1){
      return false;
    }
    else{
      return true;
    }
  }
  addCustomerToList(id){
    console.log(id);
    if(this.selectedCustomerList.indexOf(id) === -1){
      this.selectedCustomerList.push(id);
    }
    else{
      const index = this.selectedCustomerList.indexOf(id);
      this.selectedCustomerList.splice(index, 1);
    }
    console.log(this.selectedCustomerList)
  }

  customerListCreateApi(data): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.customerListUpdate(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(false);
        }
      );
    });
  }

  employeeListCreateApi(data): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.employeeListUpdate(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(false);
        }
      );
    });
  }

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  getListType(): string {
    this.currentUrl = this.currentUrl.split(";")[0];
    let currentUrlPath = this.currentUrl.split(";")[0];
    if (currentUrlPath.includes("app/customer-list/employee")) {
      return "employeeList";
    } else {
      return "customerList";
    }
  }

  getListName() {
    return this.listType === 'employeeList' ? "Employee List" : "Custom List";
  }

  cancelCreation() {
    if(this.listType=='employeeList') {
      this.router.navigate(['/app/customer-list/employee']);
    } else {
      this.router.navigate(["/app/customer-list"]);
    }
  }
}
