import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  ErrorHandler,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog"; 
import { Router, ActivatedRoute } from "@angular/router";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { DatePipe, getCurrencySymbol } from "@angular/common";
import { environment } from "src/environments/environment";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { AppDateAdapter, APP_DATE_FORMATS } from "src/app/core/services/date-format.service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { CommonTooltipDialogComponent, ConfirmationDialogBoxComponent, ContactUsDialogComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { SelectStoreDialogBoxComponent } from "src/app/shared/components/dialog-box/select-store-dialog-box/select-store-dialog-box.component";
import { ViewStoreDialogBoxComponent } from "src/app/shared/components/dialog-box/view-store-dialog-box/view-store-dialog-box.component";
import { response } from "express";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";

@Component({
  selector: "app-create-segments",
  templateUrl: "./create-segments.component.html",
  styleUrls: ["./create-segments.component.css"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class CreateSegmentsComponent implements OnInit {
  @ViewChild("mainHeader") mainHeader: ElementRef;
  @ViewChild("queryResults") queryResults: ElementRef;
  @ViewChild("segmentCreationRef") segmentCreationRef: ElementRef;
  @ViewChild("mainContainer") mainContainer: ElementRef;
  @ViewChild("previewHeading") previewHeading: ElementRef;
  @ViewChild("searchProduct") searchProduct: ElementRef;
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    window["screen-name"] = "SEGMENT CREATION";
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private fb: FormBuilder,
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandle: ErrorHandler,
    private popUp: PopUpDialogService,
    private _dialog: MatDialog,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private datePipe: DatePipe,
    private snackBar: SnackbarCollection
  ) {
    window["screen-name"] = "SEGMENT CREATION";
    this.getScreenSize();
  }
  merchantTrackingAction;
  merchantTrackingType;
  s3BucketUrl = environment.s3BucketUrl;
  segment: FormGroup;
  sample: FormGroup;
  segmentVariables = [];
  dataFetchComplete: boolean = false;
  dataFillComplete: boolean = false;
  segmentName: FormControl;
  innerRel: string;
  submitClicked = false;
  previewClicked = false;
  templateId;
  subscription;
  segmentDetail;
  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  templateListContentHeight;
  isExpanded: boolean = false;
  queryloading: boolean = false;

  stringtype = "String";
  queryAvgSpends = { high: 0, low: 0, medium: 0 };
  querySegmentCount;
  queryCount;
  querysegmentTotal = 0;
  gaugeType = "semi";
  gaugeValue = 28.3;
  gaugeLabel = "Speed";
  gaugeAppendText = "km/hr";
  customerCount;
  namePattern = "^[A-Za-z]+$";
  spendPattern = "^[0-9.]+";
  numberPattern = "^[0-9]+$";
  mobilenumberPattern = "[- +()0-9]+";
  emailPattern = new RegExp(
    /^(([a-zA-Z0-9]+([\.\+][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|((([a-zA-Z0-9])+(\-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}))$/
  );
  revCurrency;
  currencySymbol;
  Number = "number";
  String = "string";
  afterSegment;
  totalCustomerCount = 1;
  createSegmentStatus = false;
  topicGraphData = [];
  customerPercentage;
  createContainerHeight;
  previewHeadingHeight;
  eventDropdownElement;
  attributeDropdownElement;
  productDropdownElement;
  cloneStatus = false;
  queryAverageSpendTotal;
  gameId = null;
  storeDataAdded = false;
  storeData = [];
  store = '';
  previewLoading = false;
  today;
  search = '';
  array = ["$avg_daily_order_count", "$avg_monthly_order_count", "$avg_weekly_order_count", "$life_time_order_count", "$avg_monthly_spend", "$avg_spend_per_purchase", "$avg_weekly_spend", "$life_time_value", "$num_days_from_last_tran", "$num_purchases_in_last_365_days", "$num_purchases_last_6_months", "$num_purchases_last_month", "$num_purchases_last_year", "$num_purchases_this_year"];
  loading = false;
  totalProductCount =10;
  pageNo = 1;
  pagesize = 50;
  currentChar = "";
  previousChar = "";
  apiProductList = [];
  timeout;
  qId;
  ngOnInit() {
    this.getProduct(this.search, this.pageNo, this.pagesize);
    this.today = new Date();
    this.store = this.tokenStorage.getMerchantOnlineStore();
    this.qId = this.tokenStorage.getqId()=='null'?null:this.tokenStorage.getqId();
    this.getStores();
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.apiCall.getTotalCustomerCount().subscribe((result) => {
      this.totalCustomerCount = result["body"].count;
      if (this.totalCustomerCount == 0) {
        this.totalCustomerCount = 1;
      }
    });
    let interval = setInterval(() => {
      if (this.mainHeader) {
        this.templateListContentHeight =
          this.finalScreenHeight - this.mainHeader.nativeElement.offsetHeight;
        clearInterval(interval);
      }
    });
    this.apiCall.getCustomerSegmentVariables().subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.segmentVariables = response["body"];
          let keys = [];
          for (let key in this.segmentVariables) {
            keys.push(key);
          }
          keys = keys.sort();
          this.dataFetchComplete = true;
          this.initForm();
          this.route.params.subscribe((param) => {
            if (param["gameId"]) {
              this.gameId = param["gameId"];
            }
            this.templateId = param["id"];
            //this.parentToChildService.setId(this.templateId)
            if (param["id"] === "null") {
              this.submitClicked = false;
              this.previewClicked = false;
              //this.segment.reset();
              this.merchantTrackingAction = "create";
              this.merchantTrackingType = "custom";
              //this.initForm();
              this.dataFillComplete = true;
            } else {
              this.createSegmentStatus = true;
              if (param["clone"] === "true") {
                this.cloneStatus = true;
              } else {
                this.cloneStatus = false;
              }
              this.apiCall.getCustomerSegments().subscribe(
                (response) => {
                  if (response["message"] === "success") {
                    let masterSegment = response["body"];
                    let segmentExist = false;
                    for (let i = 0; i < masterSegment.length; i++) {
                      if (
                        JSON.stringify(masterSegment[i]["id"]) ===
                        this.templateId
                      ) {
                        segmentExist = true;

                        this.segmentDetail = masterSegment[i];

                        this.merchantTrackingType =
                          this.segmentDetail["segmentType"] == "STANDARD"
                            ? "standard"
                            : "custom";
                        this.merchantTrackingAction = "modify";
                        this.prefillForm(this.segmentDetail);
                        this.dataFillComplete = true;
                        break;
                      }
                    }
                    if (segmentExist === false) {
                      this.router.navigate([
                        "/app/segments/create-segment",
                        { id: null },
                      ]);
                    }
                  }
                },
                (err) => {
                  this.errorHandle.handleError(err);
                }
              );
            }
          });
        } else {
          this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to load data", ['POS_PAGE']));
        }
      },
      (err) => {
        this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']));
      }
    );
    // if (this.route.snapshot.paramMap.get('id')) {
    //   this.apiCall.getCustomerSegments().subscribe((response) => {
    //       let masterSegment = JSON.parse(response['body']);
    //       for (let i = 0; i < masterSegment.length; i++) {
    //         if (masterSegment[i]['id'] === parseInt(this.route.snapshot.paramMap.get('id'))) {
    //           this.preFillForm(masterSegment[i])
    //           break;
    //         }
    //         else {

    //         }
    //       }
    //   },
    //     err => {
    //       this.errorHandle.showError(err);
    //     })
    // }
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.mainContainer && this.mainHeader && this.previewHeading) {
        this.createContainerHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.mainHeader.nativeElement.offsetHeight;
        this.previewHeadingHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.previewHeading.nativeElement.offsetHeight;
        document
          .getElementById("mainContainer")
          .addEventListener("click", (event) => this.hideDropdowns(event));
        clearInterval(interval);
      }
    });
  }

  hideDropdowns(event) {
    console.log(event)
    if(event.srcElement.id.indexOf('close') !== -1){
      return
    }
    this.hideAttributeDropdown(event);
    this.hideEventDropdown(event);
    this.hideProductDropDown(event);
  }

  hideAttributeDropdown(event) {
    if (this.attributeDropdownElement) {
      let i = this.attributeDropdownElement.split("+")[0];
      let j = this.attributeDropdownElement.split("+")[1];
      let element = document.getElementById(
        "multi-select-dropdown-condition" + i + j
      );
      let segmentSelect = document.getElementById(
        "segment-select-condition" + i + j
      );
      let segmentSelectPanel = document.getElementById(
        "multi-select-panel-condition" + i + j
      );
      let keyboardDownIconElement = document.getElementById(
        "keyboard-down-icon-container-condition" + i + j
      );
      if (element) {
        if (event === null || !element.contains(event.target)) {
          element.style.height = "0px";
          element.style.border = "unset";
          segmentSelect.style.overflow = "hidden";
          keyboardDownIconElement.style.transform = "rotate(0deg)";
        }
      }
    }
  }
  hideProductDropDown(event) {
    if (this.productDropdownElement) {
      let i = this.productDropdownElement.split("+")[0];
      let j = this.productDropdownElement.split("+")[1];
      // if()
      // this.getInputField('', i, j);
      // this.searchProduct.nativeElement.value = ''
      let element = document.getElementById(
        "multi-select-dropdown-product" + i + j
      );
      let segmentSelect = document.getElementById(
        "segment-select-product" + i + j
      );
      let segmentSelectPanel = document.getElementById(
        "multi-select-panel-product" + i + j
      );
      let keyboardDownIconElement = document.getElementById(
        "keyboard-down-icon-container-product" + i + j
      );
      if (element) {
        if (event === null || !element.contains(event.target)) {
          element.style.height = "0px";
          element.style.border = "unset";
          segmentSelect.style.overflow = "hidden";
          keyboardDownIconElement.style.transform = "rotate(0deg)";
        }
      }
    }
  }

  hideEventDropdown(event) {
    if (this.eventDropdownElement) {
      let i = this.eventDropdownElement.split("+")[0];
      let j = this.eventDropdownElement.split("+")[1];
      let element = document.getElementById("multi-select-dropdown" + i + j);
      let segmentSelect = document.getElementById("segment-select" + i + j);
      let segmentSelectPanel = document.getElementById(
        "multi-select-panel" + i + j
      );
      let keyboardDownIconElement = document.getElementById(
        "keyboard-down-icon-container" + i + j
      );
      if (element) {
        if (event === null || !element.contains(event.target)) {
          element.style.height = "0px";
          element.style.border = "unset";
          segmentSelect.style.overflow = "hidden";
          keyboardDownIconElement.style.transform = "rotate(0deg)";
        }
      }
    }
  }

  ngOnDestroy() {
    //this.parentToChildService.setId(null)
  }

  prefillForm(segment) {
    //this.segmentName = new FormControl(segment['segmentName'],[Validators.required]);
    segment["segmentName"] = this.cloneStatus
      ? segment["segmentName"] + "_copy"
      : segment["segmentName"];
    (<FormArray>this.segment.get("segmentName")).setValue(
      segment["segmentName"]
    );
    (<FormArray>this.segment.get("segmentGroupArray")).removeAt(0);
    for (
      let i = 0;
      i < segment["segmentDefinition"]["logicalExpressions"].length;
      i++
    ) {
      let innerRelation = "AND";
      if (
        segment["segmentDefinition"]["logicalExpressions"][i][
          "comparisonStatements"
        ].length > 1
      ) {
        innerRelation =
          segment["segmentDefinition"]["logicalExpressions"][i][
            "comparisonStatements"
          ][1]["logicalOperator"];
      }
      this.addGroup(
        "update",
        segment["segmentDefinition"]["logicalExpressions"][i][
          "logicalOperator"
        ],
        innerRelation
      );
      (<FormArray>(
        (<FormArray>this.segment.get("segmentGroupArray"))
          .at(i)
          .get("segmentConditionArray")
      )).removeAt(0);
      for (
        let j = 0;
        j <
        segment["segmentDefinition"]["logicalExpressions"][i][
          "comparisonStatements"
        ].length;
        j++
      ) {
        segment["segmentDefinition"]["logicalExpressions"][i][
          "comparisonStatements"
        ][j]["logicalOperator"] === "AND"
          ? this.addConditionAND(
              i,
              "update",
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand1"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operator"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand2"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["logicalOperator"]
            )
          : this.addConditionOR(
              i,
              "update",
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand1"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operator"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand2"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["logicalOperator"]
            );
        setTimeout(() => {
          //<<<---using ()=> syntax
          this.valueSuffix(i, j);
        }, 100);
        this.addValidatorOnForm(i, j, false);
      }
    }
  }

initForm() {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.segment = new FormGroup({
      segmentName: new FormControl("", [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^([a-zA-Z0-9_()]+([ a-zA-Z0-9_()]*)){2,50}$/)
      ]),
      segmentGroupArray: new FormArray([
        new FormGroup({
          segmentConditionArray: new FormArray([
            new FormGroup({
              attribute: new FormControl(
                this.segmentVariables[firstKey][0]["name"],
                [Validators.required]
              ),
              attributeSearch: new FormControl(""),
              productSearch: new FormControl(""),
              condition: new FormControl(
                this.segmentVariables[firstKey][0]["operators"][0]["operator"],
                [Validators.required]
              ),
              value: new FormControl(null, [Validators.required,Validators.maxLength(50)]),
              attributeIndex: new FormControl(0, [Validators.required]),
              relation: new FormControl("AND"),
              attributeKey: new FormControl(
                Object.keys(this.segmentVariables)[0]
              ),
            }),
          ]),
          relation: new FormControl("AND"),
          innerRelation: new FormControl("AND"),
        }),
      ]),
    });
    this.addValidatorOnForm(0, 0, true);
    this.sample = this.fb.group({});
    this.valueSuffix(0, 0);
    // if (this.route.snapshot.paramMap.get('id')) {
    //   this.apiCall.getCustomerSegments().subscribe((response) => {
    //       let segments = JSON.parse(response['body'])
    //       for (let i = 0; i < segments.length; i++) {
    //         if (segments[i]['id'] == this.route.snapshot.paramMap.get('id')) {
    //           this.prefillForm(segments[i])
    //         }
    //       }
    //   },
    //     (err) => {
    //       this.snackbar.openSnackBar('Failed to load resource')
    //     })
    // }
  }

  addGroup(operation, relation, innerRelation) {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.submitClicked = false;
    this.previewClicked = false;
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl(
            this.segmentVariables[firstKey][0]["name"],
            [Validators.required]
          ),
          attributeSearch: new FormControl(""),
          productSearch: new FormControl(""),
          condition: new FormControl(
            this.segmentVariables[firstKey][0]["operators"][0]["operator"],
            [Validators.required]
          ),
          value: new FormControl("", [Validators.required]),
          attributeIndex: new FormControl(0, [Validators.required]),
          relation: new FormControl("AND"),
          attributeKey: new FormControl(Object.keys(this.segmentVariables)[0]),
        }),
      ]),
      relation: new FormControl(operation === "update" ? relation : "AND"),
      innerRelation: new FormControl(
        operation === "update" ? innerRelation : "AND"
      ),
    });
    (<FormArray>this.segment.get("segmentGroupArray")).push(control);
    operation === "update"
      ? null
      : this.addValidatorOnForm(
          (<FormArray>this.segment.get("segmentGroupArray")).length - 1,
          0,
          false
        );
    this.valueSuffix(
      (<FormArray>this.segment.get("segmentGroupArray")).length - 1,
      0
    );
  }

  addConditionAND(i, operation, attribute, condition, value, relation) {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.innerRel = "AND";
    const control = new FormGroup({
      attribute: new FormControl(
        operation === "create"
          ? this.segmentVariables[firstKey][0]["name"]
          : "",
        [Validators.required]
      ),
      attributeSearch: new FormControl(""),
      productSearch: new FormControl(""),
      condition: new FormControl(
        operation === "create"
          ? this.segmentVariables[firstKey][0]["operators"][0]["operator"]
          : "",
        [Validators.required]
      ),
      value: new FormControl(operation === "create" ? null : null, [
        Validators.required,
      ]),
      attributeIndex: new FormControl(0, [Validators.required]),
      attributeKey: new FormControl(Object.keys(this.segmentVariables)[0]),
      relation: new FormControl(operation === "create" ? "AND" : ""),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
    operation === "update"
      ? null
      : this.addValidatorOnForm(
          i,
          (<FormArray>(
            this.segment
              .get("segmentGroupArray")
              ["controls"][i].get("segmentConditionArray")
          )).length - 1,
          false
        );
    if (operation === "update") {
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["attribute"]["value"] = attribute;
      this.refreshConditionArray(
        i,
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1,
        this.getKeyForParam(attribute)
      );
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["condition"]["value"] = condition;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["value"]["value"] = value;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["relation"]["value"] = relation;
    }
    this.valueSuffix(
      i,
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray").length - 1
    );
  }

  changeInnerRelation(index, relation) {
    let length = this.segment
      .get("segmentGroupArray")
      ["controls"][index].get("segmentConditionArray").length;
    for (let i = 0; i < length; i++) {
      this.segment
        .get("segmentGroupArray")
        ["controls"][index].get("segmentConditionArray")["controls"][i][
        "controls"
      ]["relation"]["value"] = relation;
    }
    this.segment
      .get("segmentGroupArray")
      ["controls"][index].get("innerRelation")["value"] = relation;
  }

  addCondition(index) {
    this.submitClicked = false;
    this.previewClicked = false;
    let relation = this.segment
      .get("segmentGroupArray")
      ["controls"][index].get("innerRelation").value;
    if (relation === "AND") {
      this.addConditionAND(index, "create", null, null, null, null);
    } else {
      this.addConditionOR(index, "create", null, null, null, null);
    }
  }

  addConditionOR(i, operation, attribute, condition, value, relation) {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.innerRel = "OR";
    const control = new FormGroup({
      attribute: new FormControl(
        operation === "create" ? this.segmentVariables[firstKey][0]["name"] : ""
      ),
      attributeSearch: new FormControl(""),
      productSearch: new FormControl(""),
      condition: new FormControl(
        operation === "create"
          ? this.segmentVariables[firstKey][0]["operators"][0]["operator"]
          : ""
      ),
      value: new FormControl(operation === "create" ? null : null, [
        Validators.required,
      ]),
      attributeIndex: new FormControl(0),
      attributeKey: new FormControl(Object.keys(this.segmentVariables)[0]),
      relation: new FormControl(operation === "create" ? "OR" : ""),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
    operation === "update"
      ? null
      : this.addValidatorOnForm(
          i,
          (<FormArray>(
            this.segment
              .get("segmentGroupArray")
              ["controls"][i].get("segmentConditionArray")
          )).length - 1,
          false
        );
    if (operation === "update") {
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["attribute"]["value"] = attribute;
      this.refreshConditionArray(
        i,
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1,
        this.getKeyForParam(attribute)
      );
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["condition"]["value"] = condition;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["value"]["value"] = value;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["relation"]["value"] = relation;
    }
    this.valueSuffix(
      i,
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray").length - 1
    );
  }
  removeGroup(i) {
    (<FormArray>this.segment.get("segmentGroupArray")).removeAt(i);
  }

  removeCondition(i, j) {
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).removeAt(j);
    if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"].length === 0
    ) {
      this.removeGroup(i);
    }
  }

  relationToggle(relation, i) {
    this.segment.get("segmentGroupArray")["controls"][i]["controls"][
      "relation"
    ]["value"] = relation;
  }

  cRelationToggle(relation, i, j) {
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"][
      "relation"
    ]["value"] = relation;
  }

  addInnerGroup(i) {
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl("", [Validators.required]),
          attributeSearch: new FormControl(""),
          productSearch: new FormControl(""),
          condition: new FormControl("", [Validators.required]),
          value: new FormControl("", [Validators.required]),
          attributeIndex: new FormControl(0, [Validators.required]),
          relation: new FormControl(),
        }),
      ]),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
  }

  refreshConditionArray(groupArrayIndex, conditionArrayIndex, key) {
    let index = this.segmentVariables[key].findIndex(
      (x) =>
        x.name ===
        this.segment
          .get("segmentGroupArray")
          ["controls"][groupArrayIndex].get("segmentConditionArray")[
          "controls"
        ][conditionArrayIndex]["controls"]["attribute"]["value"]
    );
    this.segment
      .get("segmentGroupArray")
      ["controls"][groupArrayIndex].get("segmentConditionArray")["controls"][
      conditionArrayIndex
    ]["controls"]["attributeIndex"]["value"] = index;
    this.segment
      .get("segmentGroupArray")
      ["controls"][groupArrayIndex].get("segmentConditionArray")["controls"][
      conditionArrayIndex
    ]["controls"]["attributeKey"]["value"] = key;
    // for (let i = 0; i < this.segmentVariables.length; i++) {
    //   if (
    //     this.segment
    //       .get("segmentGroupArray")
    //       ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //       "controls"
    //     ][conditionArrayIndex]["controls"]["attribute"]["value"] ===
    //     this.segmentVariables[i].name
    //   ) {
    //     this.segment
    //       .get("segmentGroupArray")
    //       ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //       "controls"
    //     ][conditionArrayIndex]["controls"]["attributeIndex"]["value"] = i;
    //     for (let j = 0; j < this.segmentVariables[i].operators.length; j++) {
    //       if (
    //         this.segment
    //           .get("segmentGroupArray")
    //           ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //           "controls"
    //         ][conditionArrayIndex]["controls"]["condition"]["value"] ===
    //         this.segmentVariables[i]["operators"][j]["operator"]
    //       ) {
    //         this.segment
    //           .get("segmentGroupArray")
    //           ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //           "controls"
    //         ][conditionArrayIndex]["controls"]["condition"][
    //           "value"
    //         ] = this.segmentVariables[i]["operators"][j]["operator"];
    //         this.addValidatorOnForm(groupArrayIndex, conditionArrayIndex, true);
    //       }
    //     }
    //   }
    // }
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupArrayIndex].get("segmentConditionArray")["controls"][
        conditionArrayIndex
      ]["controls"]["condition"]["value"] =
        this.segmentVariables[key][index]["operators"][0]["operator"];
      (<FormControl>(
        (<FormArray>(
          (<FormArray>this.segment.get("segmentGroupArray")).controls[
            groupArrayIndex
          ].get("segmentConditionArray")
        )).controls[conditionArrayIndex].get("value")
      )).reset();
      this.addValidatorOnForm(groupArrayIndex, conditionArrayIndex, true);
    }

  validityCheck(): boolean {
  
    // If you want to check the overall validity of the form,
    // uncomment the following lines
    // if (this.segment.status === "INVALID") {
    //   // this.popUp.openPopup("failure", "Please check the form fields");
    //   return true;
    // }
  
    // Flag variable to track invalid conditions
    let foundInvalid = false;
    if(this.segment.get('segmentName').status ==="INVALID"){
      return true;
    }
  
    // Iterate through each element in segmentGroupArray
    this.segment.get('segmentGroupArray')['controls'].forEach((group, groupIndex) => {
      // Iterate through each element in segmentConditionArray
      group.get('segmentConditionArray').controls.forEach((condition, conditionIndex) => {
        const attribute = condition.get('attribute').value;
        const value = condition.get('value').value;
        const error = condition.status;
        // Check for specific condition to satisfy
        if (attribute !== '$is_customer_loyalty_card_holder' && (value === null || value === '' || error === 'INVALID')) {
          foundInvalid = true;
        }
      });
  
      // If an invalid condition is found, break out of both loops
      if (foundInvalid) {
        return;
      }
    });
  
    return foundInvalid; // Returns true if an invalid condition is found
  }
  

  queryValidate() {
    // for (
    //   let i = 0;
    //   i < (<FormArray>this.segment.get("segmentGroupArray")).length;
    //   i++
    // ) {
    //   for (
    //     let j = 0;
    //     j <
    //     (<FormArray>(
    //       this.segment
    //         .get("segmentGroupArray")
    //         ["controls"][i].get("segmentConditionArray")
    //     )).length;
    //     j++
    //   ) {
    //     if (
    //       this.segment.controls.segmentGroupArray["controls"][i]["controls"][
    //         "segmentConditionArray"
    //       ]["controls"][j]["controls"]["value"]["errors"]
    //     ) {
    //       //this.popUp.openPopup("failure", "Please check the form field value");
    //       return true;
    //     }
    //   }
    // }
     // Iterate through each element in segmentGroupArray
     let foundInvalid = false;
     this.segment.get('segmentGroupArray')['controls'].forEach((group, groupIndex) => {
      // Iterate through each element in segmentConditionArray
      group.get('segmentConditionArray').controls.forEach((condition, conditionIndex) => {
        const attribute = condition.get('attribute').value;
        const value = condition.get('value').value;
  
        // Check for specific condition to satisfy
        if (attribute !== '$is_customer_loyalty_card_holder' && (value === null || value === '')) {
          foundInvalid = true;
        }
      });
  
      // If an invalid condition is found, break out of both loops
      if (foundInvalid) {
        return;
      }
    });
  
    return foundInvalid; // Returns true if an invalid condition is found
  }

  trimData() {
    for (
      let i = 0;
      i < (<FormArray>this.segment.get("segmentGroupArray")).length;
      i++
    ) {
      for (
        let j = 0;
        j <
        (<FormArray>(
          this.segment
            .get("segmentGroupArray")
            ["controls"][i].get("segmentConditionArray")
        )).length;
        j++
      ) {
        if (
          this.getTypeOfData(
            (<FormControl>(
              (<FormArray>(
                (<FormArray>this.segment.get("segmentGroupArray")).controls[
                  i
                ].get("segmentConditionArray")
              )).controls[j].get("attribute")
            )).value
          ) === "String"
        ) {
          (<FormControl>(
            (<FormArray>(
              (<FormArray>this.segment.get("segmentGroupArray")).controls[
                i
              ].get("segmentConditionArray")
            )).controls[j].get("value")
          )).setValue(
            (<FormControl>(
              (<FormArray>(
                (<FormArray>this.segment.get("segmentGroupArray")).controls[
                  i
                ].get("segmentConditionArray")
              )).controls[j].get("value")
            )).value.trim()
          );
        }
      }
    }
  }

  submitSegmentData() {
      if (!this.createSegmentStatus) {
        console.log("1")
        this.popUp.openPopup("failure", "Please add condition before saving");
        return;
      }
      this.submitClicked = true;
      if (this.validityCheck() === true) {
        console.log("2")
        return;
      }
      this.trimData();
      let finalArray = [];
      let innerArray = [];
      let obj = this.segment.getRawValue();
      obj.segmentGroupArray.forEach((element, index) => {
        innerArray = [];
        element.segmentConditionArray.forEach((element, index) => {
          let value : any;
          if(element.value instanceof Date) {
            value = this.datePipe.transform(
              element.value,
              "yyyy-MM-dd HH:mm:ss"
            );
          }
          else{
            value = element.value;
          }
          if (index === 0) {
            innerArray.push({
              operand1: element.attribute,
              operator: element.condition,
              operand2: value,
            });
          } else {
            innerArray.push({
              operand1: element.attribute,
              operator: element.condition,
              operand2: value,
              logicalOperator: element.relation,
            });
          }
        });
        if (index === 0) {
          finalArray.push({
            comparisonStatements: innerArray,
          });
        } else {
          finalArray.push({
            comparisonStatements: innerArray,
            logicalOperator: element.relation,
          });
        }
      });
      let array = [];
      array.push({
        logicalExpressions: finalArray,
      });
      array = array[0];
      let abc: string = "     asad sds sdfsd     ";
      abc = abc.trim();
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Save Segment", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to Save the Segment?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
          "data-widget": "SEGMENT CREATION",
          "data-button": "Confirm: Save Segment",
          "data-item": "",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let operation =
            this.route.snapshot.paramMap.get("id") === "null" ||
            this.cloneStatus === true
              ? "create"
              : "update";
          let id =
            this.route.snapshot.paramMap.get("id") === "null" ||
            this.cloneStatus === true
              ? null
              : this.route.snapshot.paramMap.get("id");

          let data = {
                id: id,
                operation: operation,
                merchantid: this.tokenStorage.getMerchantId(),
                segmentName: this.segment.get("segmentName").value,
                segmentDefinition: array,
                stores: this.storeData,
            };    

            if (operation === 'create') {
              data['createdBy'] = {
                  storeId: this.tokenStorage.getqId(),
                  storeName: this.tokenStorage.getStoreName(),
              };
          }

          this.apiCall
            .updateCustomerSegment(data)
            .subscribe(
              (response) => {
                //this.ngOnInit()
                //this.parentToChildService.publish('call-parent');
                //this.popUp.openPopup("success", "Successfull");
                const dialogRef = this.dialog.open(SuccessDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  width: "20%",
                  disableClose: true,
                  data: {
                    message: this._i18nDynamicTranslate.transform("Your segment has been successfully created", ['POS_PAGE']),
                    buttonText: this.gameId
                      ? this._i18nDynamicTranslate.transform("Back to Game configuration", ['POS_PAGE'])
                      : this._i18nDynamicTranslate.transform("Back to Segment List", ['POS_PAGE']),
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                    let routeId = response["body"].split("=")[1];
                    if (this.gameId) {
                      this.router.navigate(["/app/gamify/create-game"], {
                        queryParams: { id: this.gameId, section:2},
                      });
                    } else {
                      this.router.navigate(["/app/segments", { id: routeId }]);
                    }
                  }
                });
                // if (operation === 'create') {
                //   this.ngOnInit();
                // }
              },
              (err) => {
                this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']));
              }
            );
        }
      });
  }

  addSampleGroup(id) {
    let divName = document.createElement("div");
    divName.id = "div" + Math.random();
    divName.className = "basic";
    document.getElementById(id).appendChild(divName);
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl(),
          attributeSearch: new FormControl(""),
          productSearch: new FormControl(""),
          condition: new FormControl(),
          value: new FormControl(),
          relation: new FormControl(),
        }),
      ]),
    });
    this.sample.addControl("control" + Math.random(), control);
    document.getElementById(divName.id).append();
  }

  addSampleCondition(i) {
    const control = new FormGroup({
      attribute: new FormControl(),
      attributeSearch: new FormControl(""),
      productSearch: new FormControl(""),
      condition: new FormControl(),
      value: new FormControl(),
      relation: new FormControl(),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
  }

  addSampleInnerGroup(i) {
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl(),
          attributeSearch: new FormControl(""),
          productSearch: new FormControl(""),
          condition: new FormControl(),
          value: new FormControl(),
          relation: new FormControl(),
        }),
      ]),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
  }

  segmentListing() {
    this.router.navigate(["/app/segments"]);
  }

  // preFillForm(segment) {
  //   // (<FormArray>this.campaignActionForm.get('campaignTemplateName')).setValue(campaign['name']);
  //   // (<FormArray>this.campaignActionForm.get('selectedCampaignTriggerType')).setValue(campaign['campaignTrigger']['campaignTriggerType']);
  //   // (<FormArray>this.campaignActionForm.get('selectedCampaignRepeat')).setValue((<FormArray>this.campaignActionForm.get('selectedCampaignTriggerType')).value === 'TIME_TRIGGER' ? campaign['campaignTrigger']['campaignTriggerRepeatType'] : null);
  //   // (<FormArray>this.campaignActionForm.get('selectedCampaignEvent')).setValue((<FormArray>this.campaignActionForm.get('selectedCampaignTriggerType')).value === 'EVENT_TRIGGER' ? campaign['campaignTrigger']['event']['eventID'] : null);
  //   // (<FormArray>this.campaignActionForm.get('targetCustomerSegments')).setValue(campaign['targetCustomerSegments']);
  //   // (<FormArray>this.segment.get('attribute')).setValue(segment['name']);
  //   this.segmentName = segment['segmentName'];

  //   // (<FormArray>this.segment.get('attribute')).setValue(segment['segmentName']);
  //   for (let i = 0; i < segment['segmentDefinition']['logicalExpressions'].length; i++) {
  //     // (<FormArray>this.segment.get('segmentGroupArray')["attribute['name']"]).setValue(["asdas"]);
  //   }

  // }

  displayQueryResults() {
    this.isExpanded = true;
    document.getElementById("segmentCreationRef").style.width = "75%";
    document.getElementById("queryResults").style.width = "25%";
    document.getElementById("queryResults").style.display = "block";
    // document.getElementById("queryToggleIcon").style.display = "block";
    // document.getElementById("queryResults").style.padding = "2%";
    document.getElementById("queryResults").style.paddingTop = "0%";
    let interval = setInterval(() => {
      if (this.mainContainer && this.mainHeader && this.previewHeading) {
        this.previewHeadingHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.previewHeading.nativeElement.offsetHeight;
        clearInterval(interval);
      }
    });
  }

  formatData(attribute, data) {
    if (attribute === "$customer_created_date") {
      var datePipe = new DatePipe("en-US");
      return datePipe.transform(data, "yyyy-MM-dd");
    } else {
      return null;
    }
  }

  closeQueryResults() {
    document.getElementById("queryResults").style.display = "none";
  }

  queryResultsDisplayToggle() {
    this.isExpanded = !this.isExpanded;
    if (!this.isExpanded) {
      document.getElementById("queryResults").style.width = "0%";
      document.getElementById("queryResults").style.padding = "0";
      document.getElementById("segmentCreationRef").style.width = "100%";
    }
    if (this.isExpanded) {
      document.getElementById("queryResults").style.width = "25%";
      document.getElementById("segmentCreationRef").style.width = "75%";
      // document.getElementById("queryResults").style.marginTop = "0px";
    }
  }

  getQueryDetails() {
    if (!this.createSegmentStatus) {
      this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Please add condition before Preview", ['POS_PAGE']));
      return;
    }
    this.previewClicked = true;
    if (this.queryValidate() === true) {
      return;
    } else {
      this.trimData();
    }
    let finalArray = [];
    let innerArray = [];
    let obj = this.segment.getRawValue();
    obj.segmentGroupArray.forEach((element, index) => {
      innerArray = [];
      element.segmentConditionArray.forEach((element, index) => {
        let value : any;
        if(element.value instanceof Date) {
          value = this.datePipe.transform(
            element.value,
            "yyyy-MM-dd HH:mm:ss"
          );
        }
        else{
          value = element.value;
        }
        if (index === 0) {
          innerArray.push({
            operand1: element.attribute,
            operator: element.condition,
            operand2: value,
          });
        } else {
          innerArray.push({
            operand1: element.attribute,
            operator: element.condition,
            operand2: value,
            logicalOperator: element.relation,
          });
        }
      });
      if (index === 0) {
        finalArray.push({
          comparisonStatements: innerArray,
        });
      } else {
        finalArray.push({
          comparisonStatements: innerArray,
          logicalOperator: element.relation,
        });
      }
    });
    let array = [];
    array.push({
      logicalExpressions: finalArray,
    });
    array = array[0];
    let data = {
      segmentId: null,
      //logicalExpressions: finalArray,
      stores : this.storeData,
      segmentDefinition: array
    };

    if(this.qId != null) {
      data.stores = data.stores.filter((store)=>this.qId===store.qid);
    }

    this.queryloading = true;
    this.previewLoading = true;

    this.apiCall.getSegmentQuery(data).subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.previewLoading = false;
          this.querysegmentTotal = 0;
          let bodySegmentCount = response["body"]["segmentCount"];
          this.querySegmentCount = response["body"]["segmentCount"];
          this.queryAvgSpends.high = response["body"]["highAverage"];
          this.queryAvgSpends.medium = response["body"]["mediumAverage"];
          this.queryAvgSpends.low = response["body"]["lowAverage"];
          this.queryAverageSpendTotal =
            this.queryAvgSpends.high +
            this.queryAvgSpends.medium +
            this.queryAvgSpends.low;
          var data = [];
          data.push({
            name: "High",
            count: this.queryAvgSpends.high,
            color: "#97CB64",
          });
          data.push({
            name: "Medium",
            count: this.queryAvgSpends.medium,
            color: "#FDB913",
          });
          data.push({
            name: "Low",
            count: this.queryAvgSpends.low,
            color: "#811620",
          });
          this.topicGraphData = data;
          this.customerCount = response["body"]["customerCount"];
          let interval = setInterval(() => {
            if (this.totalCustomerCount) {
              this.customerPercentage =
                (this.customerCount / this.totalCustomerCount) * 100;
              clearInterval(interval);
            }
          });
          this.queryCount =
            this.queryAvgSpends.high +
            this.queryAvgSpends.medium +
            this.queryAvgSpends.low;
          let totalCustomers = 0;
          for (let key in bodySegmentCount) {
            totalCustomers += bodySegmentCount[key];
          }
          this.querySegmentCount = [];
          for (let key in bodySegmentCount) {
            this.querysegmentTotal =
              this.querysegmentTotal + bodySegmentCount[key];
            this.querySegmentCount.push({
              Name: key,
              Percent: (
                (bodySegmentCount[key] / this.customerCount) *
                100
              ).toFixed(2),
              Value: bodySegmentCount[key],
            });
          }
          this.queryloading = false;
          this.displayQueryResults();
        } else {
          this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to query segment", ['POS_PAGE']));
          this.previewLoading = false;
        }
      },
      (err) => {
        this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']));
        this.previewLoading = false;
      }
    );
  }

  addValidatorOnForm(groupIndex, ConditionIndex, resetData) {
    resetData
      ? (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).reset()
      : null;
    // (<FormControl>(
    //   (<FormArray>(
    //     (<FormArray>this.segment.get("segmentGroupArray")).controls[
    //       groupIndex
    //     ].get("segmentConditionArray")
    //   )).controls[ConditionIndex].get("value")
    // )).clearValidators();

    let dataType = this.segmentVariables[
      this.segment
        .get('segmentGroupArray')
        ['controls'][groupIndex].get(
          'segmentConditionArray'
        )['controls'][ConditionIndex]['controls'][
        'attributeKey'
      ]['value']
    ][
      this.segment
        .get('segmentGroupArray')
        ['controls'][groupIndex].get(
          'segmentConditionArray'
        )['controls'][ConditionIndex]['controls'][
        'attributeIndex'
      ]['value']
    ]['dataType'];

    switch (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        ConditionIndex
      ]["controls"]["attribute"]["value"]
    ) {
      case "$first_name":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.namePattern),
        ]);
        break;
      case "$last_name":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.namePattern),
        ]);
        break;
      case "$mobile_number":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([Validators.required, Validators.maxLength(15)]);
        break;

      case "$email":
        let value = this.segment
          .get("segmentGroupArray")
          ["controls"][groupIndex].get("segmentConditionArray")["controls"][
          ConditionIndex
        ]["controls"]["value"]["value"];
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).reset();
        if (
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            ConditionIndex
          ]["controls"]["condition"]["value"] === "equals" ||
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            ConditionIndex
          ]["controls"]["condition"]["value"] === "not equal to"
        )
          (<FormControl>(
            (<FormArray>(
              (<FormArray>this.segment.get("segmentGroupArray")).controls[
                groupIndex
              ].get("segmentConditionArray")
            )).controls[ConditionIndex].get("value")
          )).setValidators([
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(this.emailPattern),
          ]);
        else
          (<FormControl>(
            (<FormArray>(
              (<FormArray>this.segment.get("segmentGroupArray")).controls[
                groupIndex
              ].get("segmentConditionArray")
            )).controls[ConditionIndex].get("value")
          )).setValidators([Validators.required, Validators.maxLength(50)]);
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValue(value);
        break;

      case "$customer_created_date":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
        ]);
        break;
      case "$life_time_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$avg_spend_per_purchase":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
        case "$avg_monthly_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
        case "$avg_daily_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$num_purchases_last_month":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_last_week":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_last_6_months":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_this_year":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_last_year":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_in_last_365_days":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$life_time_value":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$num_days_from_last_tran":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$avg_monthly_spend":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$avg_weekly_spend":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$avg_monthly_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$avg_weekly_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$is_customer_loyalty_card_holder":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        ));
        break;
    }

    switch(dataType) {
      case "Date":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
        ]);
      break;
    }
  }

  openInfoTooltip(groupIndex, ConditionIndex) {
    let message = this.getErrorMessage(groupIndex, ConditionIndex);
    let id = "id" + groupIndex + ConditionIndex;
    let element = document.getElementById(id);
    let dimension = element.getBoundingClientRect();
    let tooltipDialogReference = this.dialog.open(
      CommonTooltipDialogComponent,
      {
        data: message,
        autoFocus: false,
        hasBackdrop: true,
        position: {
          bottom: "0px",
          right: "0px",
          top: dimension.top + "px",
          left: dimension.left + 30 + "px",
        },
        panelClass: "tooltip-noPadding",
        backdropClass: "tooltip-backdrop",
      }
    );
  }

  getErrorMessage(groupIndex, ConditionIndex) {
    if (
      (this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        ConditionIndex
      ]["value"].value === null || this.segment
      .get("segmentGroupArray")
      ["controls"][groupIndex].get("segmentConditionArray")["controls"][
      ConditionIndex
    ]["value"].value === "") &&  this.segment
    .get("segmentGroupArray")
    ["controls"][groupIndex].get("segmentConditionArray")["controls"][
      ConditionIndex
  ]["controls"]["attribute"]["value"] !== '$is_customer_loyalty_card_holder'
    ) {
      return "This field cannot be empty";
    } else {
      if(this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
          ConditionIndex
      ]["controls"]["attribute"]["value"] !== '$is_customer_loyalty_card_holder' && this.segment
      .get("segmentGroupArray")
      ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        ConditionIndex
    ]["controls"]["attribute"]["value"] !== '$product_purchase'){
        switch (
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            ConditionIndex
          ]["controls"]["attribute"]["value"]
        ) {
          case "$mobile_number":
            return "The maximum length of field is 15 digits";
          case "$email":
            return "Please enter a valid email address";
          default:
            return "The maximum length of field is 50 characters";
        }
      }
    }
  }

  getTypeOfData(attribute) {
    switch (attribute) {
      case "$first_name":
      case "$last_name":
      case "$mobile_number":
      case "$email":
        return "String";
      case "$customer_created_date":
        return "Date"
      default:
        return null;
    }
  }

  keypresshandler(groupIndex, conditionIndex, event) {
    let charCode = event.key;
    if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$first_name" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$last_name"
    ) {
      if (charCode.match(this.namePattern) == null) {
        this.openInfoTooltip(groupIndex, conditionIndex);
        return false;
      }
    } else if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$mobile_number"
    ) {
      if (charCode.match(this.mobilenumberPattern) == null) {
        this.openInfoTooltip(groupIndex, conditionIndex);
        return false;
      }
    } else if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_spend_per_purchase" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_monthly_spend" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_weekly_spend" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$life_time_value" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_daily_order_count" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_monthly_order_count"
    ) {
      
      if (charCode.match(this.spendPattern) == null) {
        return false;
      }
    } else if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] != "$email"
    ) {
      if (charCode.match(this.numberPattern) == null) {
        // this.openInfoTooltip(groupIndex, conditionIndex);
        return false;
      }
    }
  }

  valueSuffix(groupIndex, conditionIndex) {
    let interval = setInterval((x) => {
      let element = document.getElementById(
        "pvalue-" + groupIndex + "-" + conditionIndex
      );

      let dataType = this.segmentVariables[
        this.segment
          .get('segmentGroupArray')
          ['controls'][groupIndex].get(
            'segmentConditionArray'
          )['controls'][conditionIndex]['controls'][
          'attributeKey'
        ]['value']
      ][
        this.segment
          .get('segmentGroupArray')
          ['controls'][groupIndex].get(
            'segmentConditionArray'
          )['controls'][conditionIndex]['controls'][
          'attributeIndex'
        ]['value']
      ]['dataType'];

      if (element) {
        clearInterval(interval);
        switch (
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            conditionIndex
          ]["controls"]["attribute"]["value"]
        ) {    
          case "$num_days_from_last_tran":
            if (
              this.segment
                .get("segmentGroupArray")
                ["controls"][groupIndex].get("segmentConditionArray")[
                "controls"
              ][conditionIndex]["controls"]["value"]["value"] > 1
            )
              element.innerHTML = "days";
            else element.innerHTML = "day";
            break;
          case "$customer_created_date":
            element.innerHTML = "date";
            break;
          case "$avg_monthly_spend":
          case "$avg_weekly_spend":
          case "$life_time_value":
          case "$avg_spend_per_purchase":
            element.innerHTML = this.currencySymbol;
            break;
          case "$avg_monthly_order_count":
          case "$avg_weekly_order_count":
          case "$life_time_order_count":
          case "$num_purchases_in_last_365_days":
          case "$num_purchases_last_6_months":
          case "$num_purchases_this_year":
          case "$num_purchases_last_year":
          case "$num_purchases_last_month":
          case "$num_purchases_last_week":
            if (
              this.segment
                .get("segmentGroupArray")
                ["controls"][groupIndex].get("segmentConditionArray")[
                "controls"
              ][conditionIndex]["controls"]["value"]["value"] > 1
            )
              element.innerHTML = "orders";
            else element.innerHTML = "order";
            break;
          default:
            element.innerHTML = "";
        }
        switch (dataType) {
          case 'Date': 
            element.innerHTML = "date";
            break;
          default:
            element.innerHTML = "";
        }
      }
    });
  }

  addNewCondition() {
    this.submitClicked = false;
    this.previewClicked = false;
    this.createSegmentStatus = true;
  }
  addStoreData(){
    let tempstoreData = this.storeData
    const dialogRef = this._dialog.open(SelectStoreDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "350px",
      height: "380px",
      data: {
        storeData: tempstoreData
      }
    });
    dialogRef.afterClosed().subscribe((result)=>{
      if(result){
        this.storeDataAdded = true;
        this.storeData = result;
      }
      else{
        if(this.storeData.length == 0)
        this.storeDataAdded = false;
      }
    })
  }
  viewStore(){
    const dialogRef = this._dialog.open(ViewStoreDialogBoxComponent,{
      panelClass: "no-padding-dialog-popup",
      width: "350px",
      height: "380px",
      data: {
        storeData: this.storeData
      }
    })
  }

  toggleSelect(event, i, j) {
    this.hideAttributeDropdown(null);
    event.stopPropagation();
    let element = document.getElementById("multi-select-dropdown" + i + j);
    let segmentSelect = document.getElementById("segment-select" + i + j);
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container" + i + j
    );
    this.eventDropdownElement = i + "+" + j;
    if (element.style.height === "185px") {
      element.style.height = "0px";
      element.style.border = "unset";
      segmentSelect.style.overflow = "hidden";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      element.style.height = "185px";
      element.style.border = "1px solid #636466";
      segmentSelect.style.overflow = "auto";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }

  toggleSelectCondition(event, i, j) {
    this.hideEventDropdown(null);
    event.stopPropagation();
    let element = document.getElementById(
      "multi-select-dropdown-condition" + i + j
    );
    let segmentSelect = document.getElementById(
      "segment-select-condition" + i + j
    );
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel-condition" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-condition" + i + j
    );
    this.attributeDropdownElement = i + "+" + j;
    if (element.style.height === "150px") {
      element.style.height = "0px";
      element.style.border = "unset";
      segmentSelect.style.overflow = "hidden";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      element.style.height = "150px";
      element.style.border = "1px solid #636466";
      segmentSelect.style.overflow = "auto";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }
  toggleSelectProduct(event, i, j) {
    this.hideAttributeDropdown(null);
    event.stopPropagation();
    let element = document.getElementById("multi-select-dropdown-product" + i + j);
    let segmentSelect = document.getElementById("segment-select-product" + i + j);
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel-product" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-product" + i + j
    );
    this.productDropdownElement = i + "+" + j;
    if (element.style.height === "185px") {
      element.style.height = "0px";
      element.style.border = "unset";
      segmentSelect.style.overflow = "hidden";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      element.style.height = "185px";
      element.style.border = "1px solid #636466";
      segmentSelect.style.overflow = "auto";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }

  selectAttributeFromDropdown(i, j, key, attribute, item) {
    this.submitClicked = false;
    this.previewClicked = false;
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"][
      "attribute"
    ]["value"] = attribute["name"];
    let element = document.getElementById("multi-select-dropdown" + i + j);
    let segmentSelect = document.getElementById("segment-select" + i + j);
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container" + i + j
    );
    element.style.height = "0px";
    element.style.border = "unset";
    segmentSelect.style.overflow = "hidden";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    this.refreshConditionArray(i, j, key);
    this.valueSuffix(i, j);
  }

  selectRelationFromDropdown(i, j, relation) {
    this.submitClicked = false;
    this.previewClicked = false;
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"][
      "condition"
    ]["value"] = relation["operator"];
    let element = document.getElementById(
      "multi-select-dropdown-condition" + i + j
    );
    let segmentSelect = document.getElementById(
      "segment-select-condition" + i + j
    );
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel-condition" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-condition" + i + j
    );
    element.style.height = "0px";
    element.style.border = "unset";
    segmentSelect.style.overflow = "hidden";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    //this.refreshConditionArray(i, j);
    this.addValidatorOnForm(i, j, false);
    this.valueSuffix(i, j);
  }
  selectProductFromDropdown(i, j, product){
    console.log(product)
    this.submitClicked = false;
    this.previewClicked = false;
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"].value.setValue(product["productName"]);
    let element = document.getElementById(
      "multi-select-dropdown-product" + i + j
    );
    let segmentSelect = document.getElementById(
      "segment-select-product" + i + j
    );
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel-product" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-product" + i + j
    );
    element.style.height = "0px";
    element.style.border = "unset";
    segmentSelect.style.overflow = "hidden";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
  }

  getAttributeDisplayName(i, j) {
    for (let key in this.segmentVariables) {
      if (
        key ===
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray")["controls"][j][
          "controls"
        ]["attributeKey"]["value"]
      ) {
        return this.segmentVariables[key][
          this.segmentVariables[key].findIndex(
            (x) =>
              x.name ===
              this.segment
                .get("segmentGroupArray")
                ["controls"][i].get("segmentConditionArray")["controls"][j][
                "controls"
              ]["attribute"]["value"]
          )
        ].displayName;
      }
    }
  }

  getKeyForParam(attribute) {
    for (let key in this.segmentVariables) {
      let index = this.segmentVariables[key].findIndex(
        (x) => x.name === attribute
      );
      if (index > -1) {
        return key;
      }
    }
  }
  getStores(){
    this.apiCall.getStoreData().subscribe((response)=>{
      if(response['body'] === null || response['body'].length ===0){
        this.storeDataAdded = true;
      }
      else{
        this.storeDataAdded = true;
        this.storeData = response['body'];
      }
    },(error)=>{
        this.storeData = [];
        this.storeDataAdded = true;
    })
  }
  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {

      if (this.totalProductCount > this.apiProductList.length) {
        this.pageNo += 1;
        this.getProduct(this.currentChar, this.pageNo, this.pagesize); // get next page on scroll
      }
    }
  }
  getProduct(char, pageno, pagesize, type?) { // get product list
    this.currentChar = char;
    if(type === 'search'){
      this.apiProductList = [];
    }
    this.loading = true;
    this.apiCall
      .getOfferManagementProductsList(char, pageno, pagesize)
      .subscribe((resultList) => {
        let data = (resultList["body"]);
        this.loading = false;
        this.previousChar = this.currentChar;
        this.apiProductList = [...this.apiProductList, ...data[Object.keys(data)[0]]];
        console.log(this.apiProductList)
      }, (error) => {
        this.loading = false;
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  2000);
       });
  }
  getproductDisplayName(i, j){
    return  this.segment
    .get("segmentGroupArray")
    ["controls"][i].get("segmentConditionArray")["controls"][
    j
  ]["value"].value ?  this.segment
  .get("segmentGroupArray")
  ["controls"][i].get("segmentConditionArray")["controls"][
  j
]["value"].value : 'Select product'
  }
  getInputField(data, i, j) { // get input field value using debounce
    clearTimeout(this.timeout);
    this.search = data;
    var $this = this;
    this.timeout = setTimeout(function () {  
    $this.getProduct(data, 1, $this.totalProductCount, 'search');
    },600);
  }
  emptyAttributeinputField(i,j){
    this.segment
    .get(
      'segmentGroupArray'
    )
    ['controls'][
      i
    ].get(
      'segmentConditionArray'
    )['controls'][j][
    'controls'
  ][
    'attributeSearch'
  ].value = ""
  }
}
