import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";

@Component({
  selector: "app-view-email-template",
  templateUrl: "./view-email-template.component.html",
  styleUrls: ["./view-email-template.component.css"],
})
export class ViewEmailTemplateComponent implements OnInit {
  templateLoading = false;
  selectedLayout = null;
  templateId;
  templateType;
  merchantId;

  constructor(private route: ActivatedRoute, private apiCall: ApiCallService) {}

  ngOnInit(): void {
    this.getRequiredParams();
    this.viewTemplateDetail();
  }

  async getRequiredParams() {
    await this.route.queryParams.subscribe((params) => {
      this.templateId = params.id;
      this.merchantId = params.mid;
      this.templateType = params.type;
    });
  }

  viewTemplateDetail() {
    if (this.templateType === "reward") {
      this.templateLoading = true;
      this.apiCall
        .getSharableRewardEmail(this.merchantId, this.templateId)
        .subscribe(
          (response) => {
            if ((response["message"] === "success") && (response['body'] !== 'null')) {
              this.selectedLayout = JSON.parse(response["body"])["layoutHtml"];
            }
            this.templateLoading = false;
          },
          (err) => {
            this.templateLoading = false;
          }
        );
    } else {
      this.templateLoading = true;
      this.apiCall.getSharableEmail(this.merchantId, this.templateId).subscribe(
        (response) => {
          if ((response["message"] === "success") && (response['body'] !== 'null')) {
            this.selectedLayout = JSON.parse(response["body"])["layoutHtml"];
          }
          this.templateLoading = false;
        },
        (err) => {
          this.templateLoading = false;
        }
      );
    }
  }
}
