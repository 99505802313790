import { Pipe, PipeTransform } from "@angular/core";
import { TextTranslationService } from "src/app/core/services/translate-text.service";

@Pipe({
    name: 'i18nDynamicTranslate',
    pure: false
})
export class TranslateDynamicText implements PipeTransform{
    constructor(private translateService : TextTranslationService){}

    transform(value: any, ...args: any[]) {
        return this.translateService.getDynamicTranslatedText(value, args);
    }
}