export const environment = {
  production: true,
  external:"prod",
  apiUrl: "app.qubriux.com/ezloyal-web",
  Origin:"app.qubriux.com",
  pygApiUrl:"app.qubriux.com/deepbrew-0.1.0",
  domain:"app.qubriux.com",
  s3BucketUrl:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com",
  s3SurveyScriptIsUrl:"https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/assets2/survey-assets/scripts/survey.js",
  s3SurveyPosScriptIsUrl:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/survey-assets/scripts/pos/survey-dev.js",
  s3SurveyCssUrl:"https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/assets2/survey-assets/scripts/survey.min.css",
  shopifyClientId: 'fcb02062abb190dcc3279f9a9ea574f4',
  cloverAppId:'DE61GP13RPD1A',
  shopifyAppScope:"read_customers,write_customers,read_products,read_orders,write_orders,read_price_rules,write_price_rules,read_discounts,write_discounts,write_content,read_inventory, write_inventory,read_locations,read_fulfillments, write_fulfillments,read_draft_orders, write_draft_orders,read_assigned_fulfillment_orders, write_assigned_fulfillment_orders,read_merchant_managed_fulfillment_orders, write_merchant_managed_fulfillment_orders,read_third_party_fulfillment_orders, write_third_party_fulfillment_orders, read_themes, write_themes,read_script_tags, write_script_tags",
  beepluginClientId:'55d274b7-252b-45ed-bff0-a2b81c79627c',
  beepluginClientSecretId:'b3uBjnItmw2cgzN8oRrPcEwvN6vg3DjSelVRBgHKrn3YlqBg6RJc',
  beeEditorS3BucketName:'bee-editor-images-prod',
  beePluginPopupClientId: '894a3df4-0f7a-47b1-a385-dfcd97312730',
  beePluginPopupClientSecretId: '0rcdVMei1pX07MyW7iDT8JLUhtLnnBaQS7orjxZIddAuQ71iHdIU',
  cloverUrl: 'www.clover.com',
  lightspeedClientId:"f701a5047d34d3e3c454a1df411aa9b0925a3c3aa8345c54b9f9677d15331e9f",
  demoLoginForPOS: "qubriux.demo@skellam.ai",
  demoLogin: "qubriux.demo+1@skellam.ai",
  googleMapsApiKey: "AIzaSyAMhHs1AppuMGbfbdnJ_iCLkeCEi3-7ACc",
  socialFBAppId:"499885735560932",
  qfferApiURL: "qffer.qubriux.com/qbshopper/api-v1-0/",
  demoAccounts: ['qubriuxdemo@gmail.com', 'qubriuxposdemo@skellam.ai'],
  fbScope: "pages_show_list,instagram_content_publish,business_management,read_insights,pages_read_engagement,ads_management,instagram_basic,pages_manage_posts,instagram_manage_insights"
};
