<div class="font" style="padding: 15px">
  <div>
    <div class="header">
      <div>
        <span style="text-align: center">{{ data["header"] }}</span>
        <div *ngIf="!data.isClicked" style="text-transform: capitalize">
          Number Of Customers: {{ totalCount }}
        </div>
      </div>
      <div class="header-tab">
        <span
          class="secondary-button"
          (click)="backToUrlListing()"
          *ngIf="
            !data.isClicked &&
            data.payLoadData.event.toLowerCase() === 'clicked'
          "
          >Go Back</span
        >
        <span
          class="primary-button"
          (click)="submitCustomerListData()"
          *ngIf="!data.isClicked"
          >Create Custom List</span
        >
        <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
      </div>
    </div>
  </div>
  <div class="sub-header" (scroll)="onScroll($event)" *ngIf="!data.isClicked">
    <table class="table">
      <thead>
        <tr>
          <th>Customer Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th *ngIf="data.payLoadData.event === 'redeemed'">Redeemed Count</th>
          <!-- <th>Status</th> -->
        </tr>
      </thead>
      <tbody *ngIf="customerDetails.length > 0">
        <tr *ngFor="let item of customerDetails">
          <td>{{ item.customerName || "---" }}</td>
          <td>{{ item.phoneNumber || "---" }}</td>
          <td>{{ item.email || "---" }}</td>
          <td *ngIf="data.payLoadData.event === 'redeemed'">{{ item.redeemedCount || "---" }}</td>
          <!-- <td style="color: black">{{ item.status }}</td> -->
        </tr>
      </tbody>
    </table>
    <div
      class="no-product"
      *ngIf="customerDetails.length === 0 && !loading"
      (click)="$event.stopPropagation()"
    >
      <div>
        {{ "SEGMENT_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
      </div>
    </div>
    <div
      class="qb-d-flex"
      style="justify-content: center; align-items: center"
      *ngIf="loading"
    >
      <app-qubriux-loading
        [size]="45"
        [loadingText]="'REGISTRATION_PAGE.LOADING' | i18nTranslate"
      ></app-qubriux-loading>
    </div>
  </div>
  <div class="sub-header" *ngIf="data.isClicked">
    <table class="table">
      <thead>
        <tr>
          <th>Url</th>
          <th>Unique Clicks</th>
          <th>All Clicks</th>
          <!-- <th>Status</th> -->
        </tr>
      </thead>
      <tbody *ngIf="urlDetails.length > 0">
        <tr
          *ngFor="let item of urlDetails"
          (click)="showUrlCustomerDetails(item.uniqueUrls)"
          class="qb-url-click"
        >
          <td>{{ item.uniqueUrls }}</td>
          <td>{{ item.uniqueClicks }}</td>
          <td>{{ item.allClicks }}</td>
          <!-- <td style="color: black">{{ item.status }}</td> -->
        </tr>
      </tbody>
    </table>
    <div
      class="no-product"
      *ngIf="urlDetails.length === 0 && !urlLoading"
      (click)="$event.stopPropagation()"
    >
      <div>
        {{ "SEGMENT_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
      </div>
    </div>
    <div
      class="qb-d-flex"
      style="justify-content: center; align-items: center"
      *ngIf="urlLoading"
      id="yesss"
    >
      <app-qubriux-loading
        [size]="45"
        [loadingText]="'REGISTRATION_PAGE.LOADING' | i18nTranslate"
      ></app-qubriux-loading>
    </div>
  </div>
</div>
