import { NgModule } from '@angular/core';
import { SurveyChannelSelectComponent, SurveyDashboardComponent, SurveyInstanceComponent } from './components';
import { SurveyBaseComponent, SurveyComponent, SurveyCreationComponent, SurveyScheduleComponent, SurveyViewComponent } from './pages';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { SurveyRoutingModule } from './survey-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelPipe } from 'src/app/shared/pipes';
import { SurveySentimentDialogboxComponent } from './components/survey-instance/survey-sentiment-dialogbox/survey-sentiment-dialogbox.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxEmojModule } from 'ngx-emoj';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [
    SurveyDashboardComponent,
    SurveyInstanceComponent,
    SurveyComponent,
    SurveyBaseComponent,
    SurveyCreationComponent,
    SurveyScheduleComponent,
    SurveyViewComponent,
    SurveySentimentDialogboxComponent,
    SurveyChannelSelectComponent
  ],
  imports: [
    SharedModule,
    SurveyRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxEmojModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ]
})
export class SurveyModule { }
