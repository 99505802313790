<head id="headTop">

</head>
<div *ngIf="validWidgets" style="height: 100%;
overflow: hidden;">
  <div *ngIf="validWidgets | widgetConfig: 6" style="height: 100%;overflow: hidden;">
    <div style="display: flex; align-items: center; justify-content: center;background: #fff; height: 100%;overflow: hidden;">
      <div class="main-container" *ngIf="selectedSurvey && !loading">
        <div data-widget-name="SURVEY TEMPLATES">
          <!-- <div *ngIf="selectedSurvey" class="headerClass">{{selectedSurveyName}}</div> -->
          <div style="
              display: flex;
              justify-content: space-between;

              align-items: center;margin: 20px;
            ">
            <span class="surveyNamText" [matTooltip]="selectedSurveyName">{{
              selectedSurveyName
              }}</span>
            <div style="display: flex">
              <div>
                <div *ngIf="appSubscriptionPlan && type !== 'standard'" class="icon-action" (click)="editTemplate()" data-button-name="Edit"
                  [attr.data-item-name]="surveyId" [matTooltip]="'COMMON_PAGE.EDIT' | i18nTranslate">
                  <span>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5625 10.7812L13.5625 9.78125C13.6458 9.69792 13.7396 9.67708 13.8438 9.71875C13.9479 9.76042 14 9.84375 14 9.96875V14.5C14 14.9167 13.8542 15.2708 13.5625 15.5625C13.2708 15.8542 12.9167 16 12.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V3.5C0 3.08333 0.145833 2.72917 0.4375 2.4375C0.729167 2.14583 1.08333 2 1.5 2H10.0625C10.1667 2 10.2396 2.05208 10.2812 2.15625C10.3229 2.26042 10.3021 2.35417 10.2188 2.4375L9.21875 3.4375C9.17708 3.47917 9.125 3.5 9.0625 3.5H1.5V14.5H12.5V10.9375C12.5 10.875 12.5208 10.8229 12.5625 10.7812ZM17.4688 4.46875L9.25 12.6875L6.4375 13C6.02083 13.0417 5.66667 12.9167 5.375 12.625C5.08333 12.3333 4.95833 11.9792 5 11.5625L5.3125 8.75L13.5312 0.53125C13.8854 0.177083 14.3125 0 14.8125 0C15.3125 0 15.75 0.177083 16.125 0.53125L17.4688 1.875C17.8229 2.22917 18 2.66667 18 3.1875C18 3.6875 17.8229 4.11458 17.4688 4.46875ZM14.375 5.4375L12.5625 3.625L6.75 9.4375L6.53125 11.4688L8.5625 11.25L14.375 5.4375ZM16.4062 2.9375L15.0625 1.59375C14.8958 1.44792 14.7396 1.44792 14.5938 1.59375L13.625 2.5625L15.4375 4.375L16.4062 3.40625C16.5521 3.26042 16.5521 3.10417 16.4062 2.9375Z" fill="#4379EF"/>
                      </svg>
                  </span>
                </div>
              </div>
              <div style="margin-left: 17px">
                <div *ngIf="appSubscriptionPlan" class="icon-action" (click)="cloneTemplate()" data-button-name="Clone"
                  [attr.data-item-name]="surveyId" [matTooltip]="'COMMON_PAGE.CLONE' | i18nTranslate">
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5 0C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5V11.5C16 11.9167 15.8542 12.2708 15.5625 12.5625C15.2708 12.8542 14.9167 13 14.5 13H13V14.5C13 14.9167 12.8542 15.2708 12.5625 15.5625C12.2708 15.8542 11.9167 16 11.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V4.5C0 4.08333 0.145833 3.72917 0.4375 3.4375C0.729167 3.14583 1.08333 3 1.5 3H3V1.5C3 1.08333 3.14583 0.729167 3.4375 0.4375C3.72917 0.145833 4.08333 0 4.5 0H14.5ZM11.3125 14.5C11.4375 14.5 11.5 14.4375 11.5 14.3125V13H4.5C4.08333 13 3.72917 12.8542 3.4375 12.5625C3.14583 12.2708 3 11.9167 3 11.5V4.5H1.6875C1.5625 4.5 1.5 4.5625 1.5 4.6875V14.3125C1.5 14.4375 1.5625 14.5 1.6875 14.5H11.3125ZM14.3125 11.5C14.4375 11.5 14.5 11.4375 14.5 11.3125V1.6875C14.5 1.5625 14.4375 1.5 14.3125 1.5H4.6875C4.5625 1.5 4.5 1.5625 4.5 1.6875V11.3125C4.5 11.4375 4.5625 11.5 4.6875 11.5H14.3125Z" fill="#4379EF"/>
                      </svg>
                  </span>
                </div>
              </div>
              <div style="margin-left: 17px" *ngIf="validWidgets | widgetConfig: 20">
                <div *ngIf="appSubscriptionPlan" class="icon-action" (click)="scheduleTemplate()"
                  data-button-name="Schedule" [attr.data-item-name]="surveyId" [matTooltip]="'COMMON_PAGE.SCHEDULE' | i18nTranslate">
                  <span >
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 2C12.9167 2 13.2708 2.14583 13.5625 2.4375C13.8542 2.72917 14 3.08333 14 3.5V14.5C14 14.9167 13.8542 15.2708 13.5625 15.5625C13.2708 15.8542 12.9167 16 12.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V3.5C0 3.08333 0.145833 2.72917 0.4375 2.4375C0.729167 2.14583 1.08333 2 1.5 2H3V0.375C3 0.125 3.125 0 3.375 0H4.625C4.875 0 5 0.125 5 0.375V2H9V0.375C9 0.125 9.125 0 9.375 0H10.625C10.875 0 11 0.125 11 0.375V2H12.5ZM12.3125 14.5C12.4375 14.5 12.5 14.4375 12.5 14.3125V5H1.5V14.3125C1.5 14.4375 1.5625 14.5 1.6875 14.5H12.3125Z" fill="#4379EE"/>
                      </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="seperator1"></div> -->
        </div>
        <div class="surveyViewerScrollContainer">
        <div style="width: 70%;margin: 13px 0;margin: 0 20px;">
          <span class="normText">{{'SURVEY_PAGE.SURVEY_TITLE' | i18nTranslate}}</span>
          <div>
            <div>
              {{selectedSurveyTitle}}
            </div>
          </div>
        </div>

        <div style="cursor: not-allowed !important" [matTooltip]="'COMMON_PAGE.CLICK_TO_EDIT' | i18nTranslate">
          <div *ngIf="selectedSurvey" style="pointer-events: none">
            <div id="selectedLayout" [innerHtml]="selectedSurvey | safeHtml"></div>
          </div>
        </div>
        </div>
        <!-- <div style="display:flex;justify-content: flex-end;margin-bottom: 10%;padding-right: 2%;">
                    <div>
                        <div class="preview-rectangle" (click)="editTemplate()">
                            <mat-icon class="visibility-icon">edit</mat-icon>
                            <span class="preview-text">Edit Survey Template</span>
                        </div>
                    </div>
                    <div style="margin-left: 15px;">
                        <div class="save-segment-rectangle" (click)="scheduleTemplate()">
                            <mat-icon class="save-icon">save</mat-icon>
                            <span class="save-segment-text">Schedule</span>
                        </div>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="cloneTemplate()">
                            <mat-icon>copy</mat-icon>
                            <span>Clone Template</span>
                        </button>
                    </mat-menu>
                </div> -->
      </div>
      <div *ngIf="!selectedSurvey" class="qb-no-data-area-email">
        <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg"
                        alt="">
                    <div style="margin-top: 15px;">
                        {{'SEGMENT_PAGE.THERE_IS_NO_DATA_AVAILABLE' | i18nTranslate}}
                    </div>
      </div>
      <div *ngIf="loading">
        <div style="
            display: flex;
            justify-content: center;
            width: 100%;
            height: 20vh;
            align-items: center;
          ">
          <mat-spinner diameter="35"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>