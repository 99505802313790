import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emoji-picker-sub',
  templateUrl: './emoji-picker-sub.component.html',
  styleUrls: ['./emoji-picker-sub.component.css']
})
export class EmojiPickerSubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
