import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import * as d3 from "d3";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";

@Component({
  selector: "app-segment-line-chart",
  templateUrl: "./segment-line-chart.component.html",
  styleUrls: ["./segment-line-chart.component.css"],
})
export class SegmentLineChartComponent implements OnInit {
  constructor(private _SidenavOperationService: SidenavOperationService) {
    this.getScreenSize();
  }

  @ViewChild("chartContainer") chartContainer: ElementRef;
  @Input() data;

  scrHeight;
  scrWidth;
  sidenavSubject;
  chartData;

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // if(this.data && this.data.length > 0){
    //   this.drawChart();
    // }
  }

  ngOnInit() {
    this.sidenavSubject = this._SidenavOperationService.sidenavStatus.subscribe(
      (response) => {
        d3.select(this.chartContainer.nativeElement).selectAll("*").remove();
        let interval = setInterval(() => {
          if (this.data.length > 0) {
            this.chartData = JSON.parse(JSON.stringify(this.data));
            this.drawChart();
            clearInterval(interval);
          }
        },500);
      }
    );

    // let interval = setInterval(()=>{
    //   if(this.data.length > 0){
    //     this.drawChart()
    //     clearInterval(interval)
    //   }
    // },200)
  }

  ngOnDestroy() {
    this.sidenavSubject.unsubscribe();
  }

  // ngOnChanges() {
  //   if (this.data.length > 0) {
  //     this.drawChart();
  //   }
  // }

  drawChart() {
    let dataInit = this.chartData;
    d3.select(this.chartContainer.nativeElement).selectAll("*").remove();
    const margin = { top: 10, right: 30, bottom: 30, left: 30 };
    const width =
      this.chartContainer.nativeElement.offsetWidth -
      margin.left -
      margin.right;
    const height =
      this.chartContainer.nativeElement.offsetHeight -
      margin.top -
      margin.bottom;

    const parseTime = d3.timeParse("%Y-%m-%d");
    const bisectDate = d3.bisector((d) => d.date).left;
    const formatTime = d3.timeFormat("%b");

    d3.select(this.chartContainer.nativeElement).style("font-size", "12px");

    let heightStatus = false;
    for (let i = 0; i < dataInit.length; i++) {
      if (dataInit[i].close > 0) {
        heightStatus = true;
      }
    }
    const x = d3.scaleTime().range([0, width]);

    const y = heightStatus
      ? d3.scaleLinear().range([height, 0])
      : d3.scaleLinear().range([height, height]);

    // const line = d3
    //   .line()
    //   .x((d) => {
    //     return x(d.date);
    //   })
    //   .y((d) => {
    //     return y(d.close);
    //   })
    //   .curve(d3.curveMonotoneX);

    const svg = d3
      .select(this.chartContainer.nativeElement)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    dataInit.forEach(function (d) {
      d.date = parseTime(d.date);
      d.close = +d.close;
    });

    const data = dataInit;

    x.domain([data[0].date, data[data.length - 1].date]);
    y.domain([
      0,
      d3.max(data, function (d) {
        return d.close;
      }),
    ]);

    svg
      .append("g")
      .attr("class", "x axis axis--x")
      .style("font-size", "11px")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).ticks(6).tickFormat(d3.timeFormat("%b")));

    d3.selectAll(".axis--x path").style("display", "none");

    svg
      .append("path")
      .datum(data)
      .attr("class", "line")
      .style("fill", "#bde4ff")
      .style("stroke", "#50aaff")
      .style("stroke-width", "1px")
      .attr(
        "d",
        d3
          .area()
          .curve(d3.curveMonotoneX)
          .x(function (d) {
            return x(d.date);
          })
          .y0(height)
          .y1(function (d) {
            return y(d.close);
          })
      );

    svg
      .selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", function (d, i) {
        return x(d.date);
      })
      .attr("cy", function (d) {
        return y(d.close);
      })
      .attr("r", 0)
      .style("stroke", "#ff5252")
      .style("fill", "#FFFFFF");

    const focus = svg.append("g").style("display", "none");

    focus
      .append("circle")
      .attr("r", "10px")
      .style("fill", "#3d3d3d")
      .attr("opacity", "0.2");

    focus
      .append("circle")
      .attr("r", "6px")
      .style("fill", "#4abb59")
      .style("stroke", "#FFF")
      .style("stroke-width", "6px");

    var div = d3
      .select(this.chartContainer.nativeElement)
      .append("div")
      .style("font-size", "12px")
      .style("display", "none")
      .style("position", "absolute")
      .style("pointer-events", "none")
      .style("text-align", "center");

    var div1 = div
      .append("div1")
      .style("padding", "10px")
      .style("border", "1px solid #FFFFFF")
      .style("font-size", "11px")
      .style("background", "#e2ecff")
      .style("border-radius", "4px");

    var div2 = div
      .append("div2")
      .style("padding", "10px")
      .style("border", "1px solid #FFFFFFF")
      .style("font-size", "11px")
      .style("background", "#e2ecff")
      .style("border-radius", "4px");

    svg
      .append("rect")
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("width", width)
      .attr("height", height)
      .on("mouseover", () => {
        focus.style("display", null);
        div.style("display", "inline");
      })
      .on("mouseout", () => {
        focus.style("display", "none");
        div.style("display", "none");
      })
      .on("mousemove", mousemove);

    function mousemove() {
      const x0 = x.invert(d3.mouse(this)[0]);
      const i = bisectDate(data, x0, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      if (d0 && d1) {
        const d = x0 - d0.date > d1.date - x0 ? d1 : d0;
        focus.attr("transform", `translate(${x(d.date)}, ${y(d.close)})`);
        toolTip(d, focus);
      }
    }

    function toolTip(d, focus) {
      div
        .style("left", d3.event.pageX - 24 + "px")
        .style("top", d3.event.pageY - 24 + "px");
      div1.text(d.close);
      div2.text(formatTime(d.date));
    }

    function make_y_gridlines(y) {
      return d3.axisLeft(y).ticks(5);
    }

    // function type(d) {
    //   d.date = parseTime(d.date);
    //   d.close = +d.close;
    //   return d;
    // }
  }
}
