<div *ngIf="storeData" style="height: 100%">
  <div *ngIf="storeData.message" class="qb-msg-header">

    <div></div>
    <div>{{storeData.message}}</div>
    <div class="qb-need-help"> Need help?</div>
  </div>

    <div class="container2" *ngIf="selectedPlatform!='posist' && storeData?.webhookMap">
      <div class="section" *ngIf="storeData?.webhookMap">
        <h2>Follow these steps:</h2>
        <div class="divider"></div>



        <div>

          <div class="qb-numbering-row"> <span class="qb-numbering"> 1</span> {{storeData.message}} </div>
        <!-- <div class="info-item" *ngIf="storeData.webhookMap?.envUrl">
          <label class="label-bold">Environment URL:</label>
          <span class="label-value"  (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.envUrl)" data-copy="{{ storeData.webhookMap.envUrl }}">{{ storeData.webhookMap.envUrl || 'N/A' }}</span>
        </div> -->
        <div class="qb-mr-l-52">
        <div class="info-item" *ngIf="storeData.webhookMap?.createOrder">
          <label class="label-bold">Create Order Webhook:</label>

          <div class="qb-value-length">
          <span class="label-value" [matTooltip]="storeData.webhookMap.envUrl + storeData.webhookMap.createOrder" >{{storeData.webhookMap.envUrl}}{{ storeData.webhookMap.createOrder || 'N/A' }}</span>
             <div class="qb-value-copy" (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.createOrder)" data-copy="{{ storeData.webhookMap.createOrder }}">Copy</div>
            </div>
        </div>
        <div class="info-item "*ngIf="storeData.webhookMap?.getCustomerLoyalty" >
          <label class="label-bold">Loyalty Webhook:</label>
          <div class="qb-value-length">
          <span class="label-value"   data-copy="{{ storeData.webhookMap.getCustomerLoyalty }}" [matTooltip]="storeData.webhookMap.envUrl + storeData.webhookMap.getCustomerLoyalty">{{storeData.webhookMap.envUrl}}{{ storeData.webhookMap.getCustomerLoyalty || 'N/A' }}</span>
                          <div class="qb-value-copy" (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.getCustomerLoyalty)">Copy</div>

            </div>
        </div>
        <div class="info-item" *ngIf="storeData.webhookMap?.offer">
          <label class="label-bold">Offer Webhook:</label>
          <div class="qb-value-length">
          <span class="label-value"   data-copy="{{ storeData.webhookMap.offer }}" [matTooltip]="storeData.webhookMap.envUrl + storeData.webhookMap.offer">{{storeData.webhookMap.envUrl}}{{ storeData.webhookMap.offer || 'N/A' }}</span>
                          <div class="qb-value-copy" (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.offer)">Copy</div>

            </div>
        </div>
        <div class="info-item" *ngIf="storeData.webhookMap?.redeemOffer">
          <label class="label-bold">Redeem Offer Webhook:</label>
          <div class="qb-value-length">
          <span class="label-value"  data-copy="{{ storeData.webhookMap.redeemOffer }}" [matTooltip]="storeData.webhookMap.envUrl + storeData.webhookMap.redeemOffer">{{storeData.webhookMap.envUrl}}{{ storeData.webhookMap.redeemOffer || 'N/A' }}</span>
                          <div class="qb-value-copy" (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.redeemOffer)">Copy</div>

            </div>
        </div>
        <div class="info-item" *ngIf="storeData.webhookMap?.syncMenu">
          <label class="label-bold">Sync Menu Webhook:</label>
          <div class="qb-value-length">
          <span class="label-value"   data-copy="{{ storeData.webhookMap.syncMenu }}" [matTooltip]="storeData.webhookMap.envUrl + storeData.webhookMap.syncMenu">{{storeData.webhookMap.envUrl}}{{ storeData.webhookMap.syncMenu || 'N/A' }}</span>
                          <div class="qb-value-copy" (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.syncMenu)">Copy</div>

            </div>
        </div>
        <div class="info-item" *ngIf="storeData.webhookMap?.redeemOfferAuthentication">
          <label class="label-bold">Redeem Offer Authentication Webhook:</label>
          <div class="qb-value-length">
          <span class="label-value"   data-copy="{{ storeData.webhookMap.redeemOfferAuthentication }}" [matTooltip]="storeData.webhookMap.envUrl + storeData.webhookMap.redeemOfferAuthentication">{{storeData.webhookMap.envUrl}}{{ storeData.webhookMap.redeemOfferAuthentication || 'N/A' }}</span>
                          <div class="qb-value-copy" (click)="copyToClipboard(storeData.webhookMap.envUrl,storeData.webhookMap.redeemOfferAuthentication)">Copy</div>

            </div>
        </div>
      </div>
        <div class="divider" *ngIf="storeData.apiKey"></div>
        <!-- <div class="qb-mr-l-52"> -->
        <div class="info-item" *ngIf="storeData.apiKey">
          <div class="qb-numbering-row"> <span class="qb-numbering">2</span>  Share the API Key  <span class="qb-sk-tooltip" matTooltipPosition="right" matTooltip="Share your api key">i</span></div>
          <div class="qb-mr-l-52">
          <label class="label-bold">API Keys:</label>
          <div class="qb-value-length">
          <span class="label-value"   data-copy="{{ storeData.apiKey }}">{{ storeData.apiKey || 'N/A' }}</span>
                          <div class="qb-value-copy" (click)="copyToClipboard('',storeData.apiKey)">Copy</div>

            </div>
        </div>
        </div>


        <div class="divider" *ngIf="storeData.apiKey"></div>
        <div class="info-item" *ngIf="storeData.apiKey">
          <div class="qb-numbering-row"> <span class="qb-numbering"> {{storeData.apiKey?3:2}}</span> Complete your integration</div>
          <div class="qb-mr-l-52">
              <div *ngIf="selectedPlatform=='petpooja'">
                <ul class="a">
                  <li>Input the Store key as given by Petpooja on submission on steps 1 and 2</li>
                  <li>Click on 'Onboard Store' button</li>
                </ul>
              </div>
              <div *ngIf="selectedPlatform=='flipoffice'">
                <ul class="a">
                  <li>Once the above steps are configured by your account manager, click on 'Onboard Store' button</li>
                </ul>
              </div>

              <div *ngIf="!isSaved&&!nonEditablePlatforms.includes(selectedPlatform)&&form.controls.length>0">
                <button class="primary-button qb-btn-wid-140" (click)="onSave()" *ngIf="!isApiLoading">Onboard Store</button>
                <button  class="primary-button qb-btn-wid-140" *ngIf="isApiLoading">
                  <mat-spinner diameter="15" ></mat-spinner>
                </button>
              </div>
        </div>
        </div>



    </div>
    </div>
      <div class="section" *ngIf="storeData.posSpecificInputData!=null&&storeData.posSpecificInputData!='{}'">
        <h2 *ngIf="isPosSpecificInputDataFieldEditable">Input Data</h2>
        <h2 *ngIf="!isPosSpecificInputDataFieldEditable">Your Account Data Fields</h2>

        <div class="divider"></div>

        <form [formGroup]="form" *ngIf="form">
            <!-- Render the dynamic form controls -->
            <div *ngFor="let controlName of form.controls | keyvalue">
              <div class="input-item">
                <label class="input-label" style="    padding: 8px;">{{ controlName.key | camelCaseToTitleCase }}:</label>
                <input type="text" class="qb-input-bx" [formControlName]="controlName.key" placeholder="Enter {{ controlName.key  | camelCaseToTitleCase}}" *ngIf="isPosSpecificInputDataFieldEditable">
                <!-- <span class="label-value"*ngIf="!isPosSpecificInputDataFieldEditable" >{{controlName.value || 'N/A' }}</span> -->
                <span class="label-value-disabled" *ngIf="!isPosSpecificInputDataFieldEditable" style="    display: flex;    border-radius: 8px;" matTooltip="This field is non-editable">
                  {{ form.get(controlName.key).value || 'N/A' }}
                </span>
                <div class="error-message" *ngIf="form.get(controlName.key).invalid && form.get(controlName.key).touched&&isPosSpecificInputDataFieldEditable">
                  {{ controlName.key | camelCaseToTitleCase }} is required.
                </div>
              </div>
            </div>
            <div *ngIf="!isSaved &&!nonEditablePlatforms.includes(selectedPlatform)">
              <button class="primary-button qb-btn-wid-140" (click)="onSave()" *ngIf="!isApiLoading">Onboard Store</button>
              <button  class="primary-button qb-btn-wid-140" *ngIf="isApiLoading">
                <mat-spinner diameter="15" ></mat-spinner>
              </button>
            </div>
          </form>
      </div>
    </div>
    <div *ngIf="selectedPlatform=='posist'" class="posist-container">
      <h1>Enter your Store Id</h1>
      <div class="info-item">
        <label class="label-bold">Customer Key:</label>
        <input class="qb-input-bx" [(ngModel)]="storeId">
      </div>
      <div>
        <button class="primary-button qb-btn-wid-140" (click)="onSavePosist()" *ngIf="!isApiLoading" [disabled]="!storeId">Onboard Store</button>
        <button  class="primary-button loading-button qb-btn-wid-140" *ngIf="isApiLoading">
          <mat-spinner diameter="15" ></mat-spinner>
        </button>
      </div>
    </div>
  
  <div *ngIf="this.storeQid!=null&&storeData?.webhookMap==null" class="qb-w-h-100">
  
    <div class="qb-center-center qb-flex-row" >
        <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/registration/customersupportreg.jpeg" alt="" class="qb-img-450">
        <!-- <button class="add-store-button" (click)="enableStoreEdit('NEW')">Add New Store</button> -->
        <div>Contact customer support</div>
  
    </div>
  </div>
  
  <div *ngIf="isloading" class="qb-w-h-100 qb-center-center">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  </div>
  <div *ngIf="this.storeQid==null" class="qb-w-h-100">
  
    <div class="qb-center-center qb-flex-row" >
        <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/registration/regkeys.jpeg" alt="" class="qb-img-450">
        <button class="add-store-button" (click)="enableStoreEdit('NEW')">Add New Store</button>

    </div>
</div>

  
  