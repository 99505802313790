import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-signup-dialog',
  templateUrl: './signup-dialog.component.html',
  styleUrls: ['./signup-dialog.component.css']
})
export class SignupDialogComponent implements OnInit {

  string1:string;
  constructor( public dialogRef: MatDialogRef<SignupDialogComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    // @Inject(MAT_DIALOG_DATA) public status: any) { }


  ngOnInit() {

    if(this.data=="userSignupSuccess"){
    this.string1= this._i18nDynamicTranslate.transform("We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.", ['SHARED_PAGE'])
    }

  }

}
