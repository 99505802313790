<div
  style="
    height: 100%;
    overflow: hidden;
    background: #dae3e3;
    position: relative;
  "
  id="full-container-email"
>
  <form [formGroup]="templateForm" style="width: 100%; height: 100%">
    <div
      style="
        width: 100%;
        background: #dae3e3;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        height: 50px;
      "
    >
<!-- <div *ngIf="
type === 'SURVEY' ||
type === 'Survey'
" style="color: #68B8E5; display: flex; margin-right: 30px; cursor: pointer; align-items: center;">
        <div style="display: flex; align-items: center;">
        <div class="material-icons" style="margin: 0px 10px 0;font-size: 12px;">thumb_up_alt</div>
        </div>
        <div style="font-size: 12px;">
          <u><a href="https://www.skellam.ai/blog/10-ways-to-avoid-sending-spam-emails" target="_blank" class="spam-link">How to avoid sending spam emails</a></u>
        </div>
      </div> -->

    <!-- <button
    *ngIf="
    type === 'SURVEY' ||
    type === 'Survey'
  "
        (click)="openSendTestEmailDialog()"
        style="
        width: 13%;
          background: #4cb9ea;
          border: none;
          color: white;
          border-radius: 5px;
          font-size: 1rem;
          padding: 6px 10px;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        Send Test Email
      </button> -->

      <span  *ngIf="
      type === 'SURVEY' ||
      type === 'Survey'
    " >
        <button (click)="openSendTestEmailDialog()" class="qb-send-test-email" matTooltip="Send Test Email">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
            fill="#5F6368">
            <path
              d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
          </svg>
        </button>
      </span>
      <span *ngIf="
      type === 'SURVEY' ||
      type === 'Survey'
    ">
        <button (click)="save()" class="qb-save" matTooltip="Save and Exit">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/></svg>
        </button>
      </span>

      <!-- <mat-icon
        (click)="snackbbb()"
        style="
          margin-right: 1%;
          cursor: pointer;
          color: #ff5252;
          font-size: 28px;
        "
      >
        view
      </mat-icon> -->
      <mat-icon
        (click)="closeEditorButton()"
        style="
          margin-right: 1%;
          cursor: pointer;
          color: #ff5252;
          font-size: 28px;
          display: flex;
          align-items: center;
        "
      >
        close
      </mat-icon>
    </div>
    <div
      style="
        display: flex;
        width: 100%;
        background: #dae3e3;
        align-items: center;
      "
    >
      <div
        style="
          width: 74.5%;
          background: #dae3e3;
          display: flex;
          align-items: center;
        "
      >
        <div
          style="
            margin-left: 2%;
            display: flex;
            flex-direction: column;
            width: 50%;
          "
          *ngIf="
            data.process === 'create' ||
            data.process === 'update' ||
            data.process === 'rewardupdate' ||
            data.process === 'createRewardTemplateLayout'
          "
        >
          <!-- <div style="display: flex; align-items: baseline; height: 90%">
            <span
              class="fontExo2"
              style="color: #fff; font-size: 13px; margin-right: 1%"
              >Email Template Name</span
            >

            <mat-form-field class="nameLabel" appearance="outline">
              <mat-label></mat-label>
              <input
                matInput
                #inpName
                style="text-align: center"
                class="fontExo2"
                type="text"
                formControlName="TemplateName"
              />

              <mat-error
                class="validationText"
                *ngIf="
                  !submitclicked &&
                  templateForm.controls.TemplateName.touched &&
                  templateForm.controls.TemplateName.hasError('required')
                "
              >
                Email Template Name is required</mat-error
              >
              <mat-error
                class="validationText"
                *ngIf="
                  submitclicked &&
                  templateForm.controls.TemplateName.hasError('required')
                "
                >Email Template Name is required
              </mat-error>
              <mat-error
                class="validationText"
                *ngIf="
                  templateForm.controls.TemplateName.dirty &&
                  templateForm.controls.TemplateName.errors?.minlength
                "
              >
                Email Template Name should have atleast 3 characters
              </mat-error>
              <mat-error
                class="validationText"
                *ngIf="
                  templateForm.controls.TemplateName.dirty &&
                  templateForm.controls.TemplateName.errors?.maxlength
                "
              >
                Email Template Name should not have more than 50 characters
              </mat-error>
            </mat-form-field>
          </div> -->
          <div style="display: flex; height: 100%">
            <div *ngIf="!isTemplateNameEdit" class="qb-d-flex">
              <button class="qb-stale" (click)="isTemplateNameEdit = true">
                <span class="qb-name-stale">
                  {{templateName}}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                  fill="#5F6368">
                  <path
                    d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                </svg>
              </button>
            </div>
  
            <div *ngIf="isTemplateNameEdit" class="qb-d-flex qb-ai-center">
              <div>
                <div class="qb-template-name-container">
                  <input matInput #inpName style="text-align: center" class="fontExo2 qb-template-name-class" type="text"
                    formControlName="TemplateName" />
  
                  <mat-error class="validationText" *ngIf="
                      !submitclicked &&
                      templateForm.controls.TemplateName.touched &&
                      templateForm.controls.TemplateName.hasError('required')
                    ">
                    {{'SHARED_PAGE.EMAIL_TEMPLATE_NAME_REQUIRED' | i18nTranslate}}</mat-error>
                  <mat-error class="validationText" *ngIf="
                      submitclicked &&
                      templateForm.controls.TemplateName.hasError('required')
                    ">{{'SHARED_PAGE.NAME_REQUIRED' | i18nTranslate}}
                  </mat-error>
                  <mat-error class="validationText" *ngIf="
                      templateForm.controls.TemplateName.dirty &&
                      templateForm.controls.TemplateName.errors?.minlength
                    ">
                    {{'SHARED_PAGE.MINIMIMUM_3_CHARACTER' | i18nTranslate}}
                  </mat-error>
                  <mat-error class="validationText" *ngIf="
                      templateForm.controls.TemplateName.dirty &&
                      templateForm.controls.TemplateName.errors?.maxlength
                    ">
                    {{'SHARED_PAGE.MAXIMUM_50_CHARACTER' | i18nTranslate}}
                  </mat-error>
                  <mat-error class="validationText" *ngIf="
                    templateForm.controls.TemplateName.dirty &&
                    templateForm.controls.TemplateName.errors?.pattern
                  ">
                    {{'SHARED_PAGE.VALID_TEMPLATE_NAME' | i18nTranslate}}
                  </mat-error>
                </div>
              </div>
              <div class="qb-actions">
                <button class="qb-action-confirm" (click)="nameChange(true)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                  </svg>
                </button>
                <button class="qb-action-cancel" (click)="nameChange(false)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#5f6368">
                    <path
                      d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                  </svg>
                </button>
              </div>
            </div>
  
            <div class="qb-d-flex qb-ai-center qb-action-icons">
              <div class="qb-icon-divider"></div>
              <span>
                <button (click)="previewEmailTemplate()" class="qb-preview-email" matTooltip="Preview Template">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#5F6368">
                    <path
                      d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
                  </svg>
                </button>
              </span>
              <span>
                <button (click)="openSendTestEmailDialog()" class="qb-send-test-email" matTooltip="Send Test Email">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#5F6368">
                    <path
                      d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div
          style="
            width: 50%;
            margin-left: 2%;
            display: flex;
            align-items: center;
            height: 90%;
          "
          *ngIf="
            data.process === 'AddTextBody' ||
            data.process === 'EditTextBody' ||
            data.process === 'NewTemplateAndLayout'
          "
        >
          <span
            class="fontExo2"
            style="color: #fff; font-size: 13px; margin-right: 1%"
            >Email Subject</span
          >
          <div
          id="emojiContainer"
            style="width: 70%;"
            class="fontExo2"
            [ngClass]="{
              active: templateForm['controls']['subject']['errors']
                ? (templateForm['controls']['subject']['errors']['required'] &&
                    templateForm['controls']['subject']['touched']) ||
                  submitClicked
                : false
            }"
          >
            <mat-form-field
              class="nameLabel"
              style="width: 100%; position: relative;"
              appearance="outline"
            >
              <input
                matInput
                #inpSubject
                class="formInput fontExo2 qb-emoji-content"
                type="text"
                formControlName="subject"
                id="emailSubjectInputField"
              />
              <span
              id = "emojidiv"
              class="smiley-container cursor-pointer"
              style="position: absolute;"
              (click)="toggled = !toggled"
              >😄</span
            >
            <ngx-emoj
            class="qb-emoji-content"
            *ngIf="toggled"
            style="position: absolute; top: 28px"
            (onemojipick)="handleEmoji($event)"
            (onchardelete)="handleCharDelete($event)"
            [maxRecentEmoji]="2"
            [recentEmojiStoreKey]="'String'"
            [searchEmojiPlaceholderText]="searchEmoji"
            [emojiNotFoundText]="'Not Found'"
            [theme] = "emojiTheme"
            [width]="'300px'"
            [height]="'32vh'">
            </ngx-emoj>
              <mat-error
                class="validationText"
                *ngIf="
                  templateForm['controls']['subject']['errors']
                    ? (templateForm['controls']['subject']['errors'][
                        'required'
                      ] &&
                        templateForm['controls']['subject']['touched']) ||
                      submitClicked
                    : false
                "
                >Email subject cannot be empty
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- <div style="height:100%;text-align: center;">
                <span class="fontExo2" style="margin-left: 5px; font-size: 12px; color: #ff5f31;" *ngIf="templateForm['controls']['subject']['errors'] ? (templateForm['controls']['subject']['errors']['required'] && templateForm['controls']['subject']['touched'] || submitClicked) : false">Field
                        cannot be empty</span>
            </div> -->

        <div
          style="width: 50%; display: flex; justify-content: flex-end"
          *ngIf="data['process'] === 'create'"
        >
          <div
            style="
              height: 58%;
              width: 80%;
              background: #dae3e3;
              display: flex;
              margin-right: 3%;
              font-size: 12px;
            "
          >
            <div
              style="border-radius: 4px 0px 0px 4px"
              (click)="selectTemplate('T1')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T1' }"
            >
              <span class="fontExo2">Layout 1</span>
            </div>
            <div
              (click)="selectTemplate('T2')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T2' }"
            >
              <span class="fontExo2">Layout 2</span>
            </div>
            <div
              (click)="selectTemplate('T3')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T3' }"
            >
              <span class="fontExo2">Layout 3</span>
            </div>
            <div
              style="border-radius: 0px 4px 4px 0px"
              (click)="selectTemplate('T4')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T4' }"
            >
              <span class="fontExo2">Layout 4</span>
            </div>
          </div>
        </div>
      </div>
      
      <div
        (click)="openViewOfferSummary()"
        style="
          display: flex;
          color: #5f6368;
          text-decoration: underline;
          cursor: pointer;
          width: 12%;
          font-size: 11px;
        "
        *ngIf="
          data['process'] != 'surveyEmail' &&
          data['process'] != 'EditTextBodySurvey' && dataLoad['type'] != 'Email' && this.dataLoad.type !== 'FORM'
        "
      >
        View offer summary
      </div>
      <span     *ngIf="
      data['process'] != 'surveyEmail' &&
      data['process'] != 'EditTextBodySurvey' && dataLoad['type'] != 'Email' && this.dataLoad.type !== 'FORM'
    ">
        <button (click)="save()" class="qb-save" matTooltip="Save and Exit">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/></svg>
        </button>
      </span>
      <!-- <div *ngIf="
      type !== 'SURVEY' &&
      type !== 'Survey'
    " style="color: #68B8E5; display: flex; margin-right: 30px; cursor: pointer; align-items: center;">
        <div style="display: flex; align-items: center;">
        <div class="material-icons" style="margin: 0px 10px 0;font-size: 12px;">thumb_up_alt</div>
        </div>
        <div style="font-size: 12px;">
          <u><a href="https://www.skellam.ai/blog/10-ways-to-avoid-sending-spam-emails" target="_blank" class="spam-link">How to avoid sending spam emails</a></u>
        </div>
      </div> -->
      <!-- <button
        *ngIf="type === 'OFFER'"
        (click)="openSendTestEmailDialog()"
        style="
          width: 13%;
          background: #4cb9ea;
          border: none;
          color: white;
          border-radius: 5px;
          font-size: 1rem;
          padding: 6px 10px;
        "
      >
        Send Test Email
      </button> -->
      <!-- <button
        *ngIf="type === 'EMAIL_LAYOUT_HTML'"
        (click)="openSendTestEmailDialog()"
        style="
          width: 13%;
          background: #4cb9ea;
          border: none;
          color: white;
          padding: 6px 10px;
          border-radius: 5px;
          font-size: 1rem;
        "
      >
        Send Test Email
      </button> -->
      <!-- <button
        *ngIf="
          type === 'SURVEY' || type === 'Survey'
        "
        (click)="openSendTestEmailDialog()"
        style="
          position: absolute;
          background: #4cb9ea;
          border: none;
          color: white;
          top: 35px;
          right: 120px;
          border-radius: 5px;
          font-size: 1rem;
          padding: 6px 10px;
        "
      >
        Send Test Email
      </button> -->
      <button
        *ngIf="
         dataLoad['type'] === 'Email' || dataLoad.type === 'FORM'
        "
        (click)="openSendTestEmailDialog()"
        style="
          position: absolute;
          background: #4cb9ea;
          border: none;
          color: white;
          top: 30px;
          right: 20px;
          border-radius: 5px;
          font-size: 1rem;
          padding: 6px 10px;
        "
      >
        Send Test Email
      </button>
    </div>

    <div
    style="width: 100%; height: calc(100% - 80px); min-height: 570px"
    id="bee-plugin-container"
  >
  </div>
    <ng-template #loadSpinner>
      <div
        style="
          display: flex;
          justify-content: center;
          margin-top: 30px;
          width: 100%;
          align-items: center;
        "
      >
        <mat-spinner diameter="45"></mat-spinner>
      </div>
    </ng-template>
  </form>
</div>
