import { NgModule } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { AddToSuppressionListComponent, DeleteContactComponent, EmailSuppressionListComponent, SmsSuppressionListComponent } from './components';
import { SuppressionListsComponent } from './pages';
import { SuppressionListRoutingModule } from './suppression-list-routing-module';
import { Ng2TelInputModule } from 'ng2-tel-input';


@NgModule({
  declarations: [
    AddToSuppressionListComponent,
    DeleteContactComponent,
    EmailSuppressionListComponent,
    SmsSuppressionListComponent,
    SuppressionListsComponent
   ],
  imports: [
    SharedModule,
    SuppressionListRoutingModule,
    MatOptionModule,
    MatSelectModule,
    Ng2TelInputModule
  ]
})
export class SuppressionListModule { }
