<div class="qb-body">
  <div>
    <div class="qb-header-div">
      <div class="qb-header-buttons">
        <div class="primary-button qb-add-button" (click)="createNewReward()">
          <div class="material-icons-outlined">add</div>
          <div>{{'PARTNERSHIP_PAGE.CREATE_NEW_REWARD' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-table-div">
    <div>
      <div class="qb-table-header">
        <div>{{'PARTNERSHIP_PAGE.REWARD_LIST' | i18nTranslate}}</div>
        <div class="qb-search-div">
          <input
            type="text"
            placeholder="Search by Reward Name"
            [(ngModel)]="rewardName"
            (input)="onKeySearch($event)"
          />
          <div class="material-icons-outlined" *ngIf="rewardName.length == 0">
            search
          </div>
          <span
            class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
            *ngIf="rewardName.length > 0"
            (click)="rewardName = ''; onKeySearch(searchString)"
          >
            close
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="qb-table-head">
        <div class="qb-table-head-section" *ngFor="let heading of tableHeadings">
          <div class="qb-table-head-text" *ngIf="heading !== 'No of Partners Shared with' && heading !== 'Reward Shared'">{{heading | i18nDynamicTranslate : 'POS_PAGE'}}</div>
        <div class="qb-table-flex qb-shared" *ngIf="heading === 'No of Partners Shared with'">
          <div>{{'PARTNERSHIP_PAGE.NO_SHARED_WITH' | i18nTranslate}}</div>
          <div
            class="qb-i-div"
            type="button"
            [ngbPopover]="popContent"
            triggers="mouseenter:mouseleave"
          >
            <ng-template #popContent>
              <div class="qb-i-text">
                {{'PARTNERSHIP_PAGE.TOTAL_NUMBER_OF_PARTNERS_WITH' | i18nTranslate}}
              </div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
        </div>
        <div class="qb-table-flex" *ngIf="heading === 'Reward Shared'">
          <div>{{'PARTNERSHIP_PAGE.REWARD_SHARED' | i18nTranslate}}</div>
          <div
            class="qb-i-div"
            type="button"
            [ngbPopover]="popContent1"
            triggers="mouseenter:mouseleave"
          >
            <ng-template #popContent1>
              <div class="qb-i-text">
                {{'PARTNERSHIP_PAGE.REWARD_SHARED_INFO' | i18nTranslate}}
              </div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
          <!-- <div class="dropdown-div">
          <mat-select [(value)]="revenueRange">
            <mat-option value="allTime">All Time</mat-option>
            <mat-option value="30days">30 Days</mat-option>
          </mat-select>
        </div>
        <div class="arrow-div">
          <div class="material-icons-outlined">arrow_drop_up</div>
          <div class="material-icons-outlined arrow-down">arrow_drop_down</div>
        </div> -->
        </div>
      </div>
    </div>

    </div>
    <div *ngIf="loading" class="qb-spinner-div">
      <mat-spinner diameter="15"></mat-spinner>
    </div>
    <div *ngIf="total == 0 && !loading" class="qb-no-data">
      {{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
    </div>
    <div class="qb-table-content" *ngIf="!loading && total > 0">
      <div class="qb-table-data-div" *ngFor="let reward of rewards">
        <div class="qb-table-data">{{ reward["rewardName"] }}</div>
        <div class="qb-table-data">
          {{ reward["rewardPercent"] }}% {{'PARTNERSHIP_PAGE.FOR_EVERY_SALE_VIA_PARTNER' | i18nTranslate}}
        </div>
        <div class="qb-table-data shared">{{ reward["usageCount"] }}</div>
        <div class="qb-table-data">
          {{ currencySymbol }}{{ reward["totalReward"] | number: "1.2-2" }}
        </div>
      </div>
    </div>
    <div class="qb-paginate-div">
      <mat-paginator
        [pageIndex]="pageNumber - 1"
        [length]="total"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="refreshPaginatedData($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
