<div style="overflow:hidden; position: absolue; width:100%; background: #e2e7ec;">
    <div class="listing">
      <div style="display: flex;">
          <div class="swapButton" (click)="selectManageType('Create')"
          [routerLink]="['./creation']" routerLinkActive="active">
          <span>Journey template creation</span>
        </div>
        <div class="swapButton" (click)="selectManageType('List')"
          [routerLink]="['./listing']" routerLinkActive="active">
          <span>Available Journey templates</span>
        </div>
      </div>
    </div>
    <!-- <div style="width: 11%;">
        <mat-list>
          <mat-list-item>
            <span (click)="selectManageType('Create offer')" class="sidenavButton btnNav"
            [routerLink]="['./creation']"
            routerLinkActive="active">Create offer</span>
          </mat-list-item>
          <mat-list-item>
            <span (click)="selectManageType('Browse offers')" class="sidenavButton btnNav"
            [routerLink]="['./listing']"
            routerLinkActive="active">Browse/Modify offers</span>
          </mat-list-item>
        </mat-list>
      </div> -->
    <div style="width : 100%; overflow: auto;" [style.height.px]="scrContentHeight" >
      <router-outlet></router-outlet>
    </div>
  </div>