import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { CustomerListRoutingModule } from './customer-list-routing.module';
import { CustomerListDataViewComponent } from './components';
import { CreateNewCustomerListComponent, CustomerListViewComponent } from './pages';



@NgModule({
  declarations: [
    CustomerListDataViewComponent,
    CustomerListViewComponent,
    CreateNewCustomerListComponent
   ],
  imports: [
    SharedModule,
    CustomerListRoutingModule
  ]
})
export class CustomerListModule { }
