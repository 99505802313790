import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";

@Component({
  selector: "app-search-modules-dialog-box",
  templateUrl: "./search-modules-dialog-box.component.html",
  styleUrls: ["./search-modules-dialog-box.component.css"],
})
export class SearchModulesDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SearchModulesDialogBoxComponent>,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private snackBar: SnackbarCollection,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  filter = {
    label: "",
  };
  loading = false;
  modules = [];

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  selectModule(id) {
    this.dialogRef.close(id);
  }
}
