import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { WhatsappTemplateListingComponent } from './pages';
import { WhatsAppRoutingModule } from './whatsapp-routing.module';
import { WhatsappRedirectComponent } from './pages/whatsapp-redirect/whatsapp-redirect.component';
import { WhatsappTemplateCreationComponent } from 'src/app/modules/pos/modules/whatsApp/components/whatsapp-template-creation/whatsapp-template-creation.component';



@NgModule({
  declarations: [
   WhatsappTemplateListingComponent,
   WhatsappRedirectComponent,
   WhatsappTemplateCreationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxEmojiPickerModule,
    WhatsAppRoutingModule
  ]
})
export class WhatsAppModule { }
