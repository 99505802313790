<div *ngIf="countryCode" class="qb-w-100 qb-h-100">
  <router-outlet
    *ngIf="(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US')"
    class="qb-w-100 qb-h-100"
  ></router-outlet>
  <div *ngIf="!(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US')" class="qb-disable-screen-div">
    <div class="qb-d-flex qb-fd-coloumn">
      <div class="qb-d-flex qb-jc-center">
        <img
          class="qb-w-50"
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/sms-screen-assets/winch.svg"
          alt=""
        />
      </div>
      <span class="qb-coming-soon-span">{{'COMMUNICATION_PAGE.COMING_SOON' | i18nTranslate}}</span>
    </div>
  </div>
</div>
<div *ngIf="!countryCode" class="qb-spinner-class">
  <mat-spinner diameter="25"></mat-spinner>
</div>
