<div style="display: flex;width: 100%; height: 100% ;justify-content: space-between;" *ngIf="validWidgets"
    data-widget-name="SURVEY LIST">



    <div class="Mask" *ngIf="validWidgets | widgetConfig: 12" (scroll)="onScroll($event)">
        <div *ngIf="mobileView" class="surveylistTable" id="side-menu-survey-parent">
            <div *ngFor="let survey of surveysScheduleBody; index as i; let last=last" id="side-menu-survey">
                <div style="background-color: transparent; margin: 0 10px;">
                    <div style="margin-bottom: 15px; background-color: white; box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
            border-radius: 8px; padding: 15px;" [style.margin-bottom.px]="last?'50':'15'">
                        <div style="display: flex; justify-content: space-between;">
                            <div style="overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 14px;">
                                {{survey.surveyName.split('#-#')[0]}}
                            </div>
                            <div style="display: flex;">
                                <span class="material-icons-outlined icons-primary"
                                    style="margin-top: -2px; font-size: 20px;"
                                    *ngIf="survey.surveyScheduleType.scheduleType==='CAMPAIGN'">campaign </span>
                                <span class="material-icons-outlined icons-primary"
                                    style="margin-top: 2px; font-size: 14px;"
                                    *ngIf="survey.surveyScheduleType.scheduleType==='PERIODIC'">loop </span>

                                <span style="font-size: 12px;">
                                    {{survey.surveyScheduleType.scheduleType | titlecase}}
                                </span>
                            </div>
                        </div>
                        <div
                            style="display: flex;justify-content: space-between; margin-top: 6px; margin-bottom: 20px;">
                            <div style="display: flex; margin-top:5px;">
                                <div style="color: rgba(34, 34, 34, 0.6); font-size: 14px;">{{'SURVEY_PAGE.STATUS' | i18nTranslate}} &nbsp;</div>
                                <div style="display:flex;align-items: center;">
                                    <span>
                                        <span class="material-icons icons-primary icons-flag"
                                            [ngClass]="{ACTIVE:survey.surveyStatus=='ACTIVE' &&survey.surveyScheduleType.scheduleType!='INSTANT', DRAFT:survey.surveyStatus == 'DRAFT',SCHEDULED:survey.surveyStatus == 'SCHEDULED', ENDED:(survey.surveyScheduleType.scheduleType=='INSTANT'||(survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='DRAFT'&&survey.surveyStatus!='SCHEDULED')) }">
                                            flag
                                        </span>
                                    </span>
                                    <span
                                        [ngClass]="(survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='SCHEDULED')?'redbox':'greenbox'"
                                        *ngIf="survey.surveyScheduleType.scheduleType!='INSTANT'">

                                        <span class="surveyStatusText ACTIVE"
                                            *ngIf="survey.surveyStatus=='ACTIVE'||survey.surveyStatus=='SCHEDULED'">{{'SURVEY_PAGE.ACTIVE' | i18nTranslate}}</span>
                                        <span class="surveyStatusText ENDED"
                                            *ngIf="survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='DRAFT'&&survey.surveyStatus!='SCHEDULED'">{{'SURVEY_PAGE.ENDED' | i18nTranslate}}</span>
                                        <span class="surveyStatusText DRAFT" *ngIf="survey.surveyStatus=='DRAFT'"
                                            style="color: #1A76B1;">{{'SURVEY_PAGE.DRAFT' | i18nTranslate}}</span>
                                    </span>
                                    <span
                                        [ngClass]="(survey.surveyScheduleType.scheduleType=='INSTANT')?'redbox':'greenbox'"
                                        *ngIf="survey.surveyScheduleType.scheduleType=='INSTANT'">

                                        <span class="surveyStatusText ENDED">{{'SURVEY_PAGE.ENDED' | i18nTranslate}}</span>
                                    </span>
                                </div>
                            </div>


                            <div style="width:10%; display: flex; justify-content: center;"
                                *ngIf="appSubscriptionPlan && (survey.surveyScheduleType.scheduleType === 'PERIODIC' || survey.surveyScheduleType.scheduleType === 'ORDER')">
                                <mat-slide-toggle (change)="toggleSurvey(i,$event, survey?.createdBy?.qid, survey?.createdBy?.storeName);" (click)="$event.stopPropagation();"
                                    color="primary" [checked]="survey.surveyStatus === 'ACTIVE' ? true : false"
                                    data-button-name="Schedule toggle"
                                    [attr.data-item-name]="'('+survey.surveyId+')'+survey.surveyName"
                                    *ngIf="survey.surveyScheduleType.scheduleType!='INSTANT'&&survey.surveyScheduleType.scheduleType!='CAMPAIGN'">
                                </mat-slide-toggle>
                                <mat-slide-toggle color="primary" checked="false" disabled="true"
                                    *ngIf="survey.surveyScheduleType.scheduleType=='INSTANT'" matTooltipPosition="below"
                                    [matTooltip]="'COMMON_PAGE.INSTANT_CANNOT_RESCHEDULE' | i18nTranslate">
                                </mat-slide-toggle>
                                <mat-slide-toggle color="primary" checked="false" disabled="true"
                                    *ngIf="survey.surveyScheduleType.scheduleType=='CAMPAIGN'"
                                    matTooltipPosition="below"
                                    [matTooltip]="'COMMON_PAGE.CAMPAIGN_SURVEY_RESCHEDULE' | i18nTranslate ">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div style="display: flex;;">
                            <span style="color: #0183FB; font-size: 14px;"
                                (click)=SentimentAnalysisParticularSurveyMobile(i,true)
                                [attr.data-item-name]="'('+survey.surveyId+')'+survey.surveyName">{{'SURVEY_PAGE.VIEW_ANALYTICS' | i18nTranslate}}
                                &nbsp;&nbsp;</span>
                            <span style="color: #0183FB; font-size: 14px;"
                                (click)=SentimentAnalysisParticularSurveyMobile(i,false)
                                [attr.data-item-name]="'('+survey.surveyId+')'+survey.surveyName">{{'SURVEY_PAGE.VIEW_FEEDBACK' | i18nTranslate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 50px;"></div>
        </div>

        <div *ngIf="mobileView==false" class="surveylistTable">

            <div>
            <div class="survey-details-table">
                <div style="    font-size: 1.5rem;
                font-weight: 700; display: flex; align-items: center; gap: 12px;">{{'SURVEY_PAGE.SURVEY_LIST' | i18nTranslate}}
                <div *ngIf="selectedSurveyListPageWise[pageNumber].length > 0">
                    <div
                    class="filterButton button-hover-effect"
                    (click)="deleteSelectedSurvey()"
                    data-button-name="Delete"
                    >
                    <div style="display: flex">
                        <span
                        class="material-icons-outlined icons-primary"
                        style="cursor: pointer"
                        [matTooltipShowDelay]="200"
                        [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate"
                        >
                        delete
                        </span>
                    </div>
                    </div>
                </div>    
            </div>

           


                <div *ngIf="isAdmin && storeFilterList.length>1" class="filter-container">
                        <div class="filter-title-2">Store filter: </div>
                        <mat-select [(ngModel)]="selectedStore" (selectionChange)="getFilteredSurveyList()">
                            <mat-option *ngFor="let store of storeFilterList" [value]="store" >
                            {{store.storeName}}
                            </mat-option>
                        </mat-select>
                </div>
                <!-- <div>
                    <div class="campaignlistRectangle">
                        <div class="campaignlistsearchBar">
                            <input #search type="text" placeholder="Search by Survey Name"
                                (input)="onKeySearch($event)" />
                            <img (click)="showSearch()"
                                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/home-screen/search.svg">
                        </div>
                    </div>
                </div> -->
            </div>
            </div>
            <div style="padding-top: 30px;">
            <div class="listTable" style="height: 50px;  background: var(--main-bg-bgrey); border-radius: 12px;" id="tableHeads"
                [style.padding-right.px]="rightMargin">
                <div class="listHead topicHead" style="flex: 2; gap: 8px; border-radius: 12px 0px 0px 12px;">
                    <mat-checkbox class="example-margin"
                    [checked]="(selectedSurveyListPageWise[pageNumber].length === surveysScheduleBody.length) && surveysScheduleBody.length > 0"
                    [indeterminate]="selectedSurveyListPageWise[pageNumber].length > 0 && selectedSurveyListPageWise[pageNumber].length !== (surveysScheduleBody.length)"
                    (change)="checkSurveyList($event.checked, 'all', null)"
                    color="primary">
                  </mat-checkbox>
                    {{'SURVEY_PAGE.SURVEY_NAME' | i18nTranslate}}
                </div>
                <div class="listHead topicHead" style="flex: 1;">{{'SURVEY_PAGE.TYPE' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1;">{{'SURVEY_PAGE.CREATED_ON' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1;">{{'SURVEY_PAGE.CREATED_BY' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1; border: none;">{{'SURVEY_PAGE.STATUS' | i18nTranslate}}</div>
                <!-- <div class="listHead topicHead" style="width: 10%;"></div> -->

                <!-- <div></div>
            <div></div> -->

            </div>
            </div>
            <div class="main" *ngIf="totalSchedulelength>0" id="side-menu-survey-parent">
                <div id="side-menu-survey">

                    <div class="listValueTable" *ngFor="let survey of surveysScheduleBody; index as i"
                        (click)="SentimentAnalysisParticularSurvey(i)" data-button-name="Survey"
                        [attr.data-item-name]="'('+survey.surveyId+')'+survey.surveyName"
                        [ngClass]="{active:selectedi==i}">
                        <div class="listHead" [ngClass]="{active:selectedi==i}" class=" listHead"
                            [ngClass]="{active:selectedi==i}" style="display: flex;align-items: center;gap: 8px; flex: 2;">

                            <div class="qb-d-flex qb-ai-center" style="width: 10%;">
                                <mat-checkbox class="example-margin"
                                     [checked]="checkIfPresent(survey['surveyId'])"
                                  (change)="checkSurveyList($event.checked, 'single', survey['surveyId'])"
                                  color="primary">
                                </mat-checkbox>
                              </div>

                            <div style="width: 90%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                <span class="surveyName" style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;" placement="bottom" [matTooltip]="survey.surveyName" matTooltipClass="ex-tooltip-red1"
                                *ngIf="survey.surveyScheduleType.scheduleType!='CAMPAIGN'">{{survey.surveyName}}</span>
                            <span style="white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;display: flex;flex-direction: column; width: 100%;" placement="bottom"
                                *ngIf="survey.surveyScheduleType.scheduleType=='CAMPAIGN'"><span
                                    [matTooltip]="survey.surveyName.split('#-#')[0]" matTooltipClass="ex-tooltip-red1"
                                    style="white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;font-size: 14px;" class="surveyName">{{survey.surveyName.split('#-#')[0]}}
                                </span> <span [matTooltip]="survey.surveyName.split('#-#')[1]"
                                    matTooltipClass="ex-tooltip-red1" style=" font-style: italic;  font-weight: 100;font-size: 14px;color: #919191;white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;">{{survey.surveyName.split('#-#')[1]}}</span></span>
                            </div>
                            
                        </div>
                        <div class="surveyScheduleType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;">
                            {{survey.surveyScheduleType.scheduleType === 'CAMPAIGN' ? 'Journey Builder' : (survey.surveyScheduleType.scheduleType | titlecase)}}</div>
                        <div class="surveyScheduleType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;">
                            {{survey.createdTime | date:getDateFormat("dd MMM yyyy")}} | {{survey.createdTime | customTime}}</div>
                        <div class="surveyScheduleType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;" title=" {{survey?.createdBy?.storeName}}">
                                {{survey?.createdBy?.storeName}}</div>
                        <div class="listHead" [ngClass]="{active:selectedi==i}" style="flex: 1;border-right: 0;display: flex;align-items: center; justify-content: space-evenly;">
                                <div style="display:flex;align-items: center;">
                                    <!-- <span> -->
                                        <!-- <span class="material-icons icons-primary icons-flag"
                                            [ngClass]="{ACTIVE:survey.surveyStatus=='ACTIVE' &&survey.surveyScheduleType.scheduleType!='INSTANT', DRAFT:survey.surveyStatus == 'DRAFT',SCHEDULED:survey.surveyStatus == 'SCHEDULED', ENDED:(survey.surveyScheduleType.scheduleType=='INSTANT'||(survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='DRAFT'&&survey.surveyStatus!='SCHEDULED')) }">
                                            flag
                                        </span> -->
                                        <!-- <img *ngIf="survey.surveyStatus=='ACTIVE' &&survey.surveyScheduleType.scheduleType!='INSTANT'"
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/campaign-list/active-flag.svg">
                    <img *ngIf="survey.surveyStatus == 'DRAFT'"
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/campaign-list/draft-flag.svg">
                    <img *ngIf="survey.surveyStatus == 'SCHEDULED'"
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/campaign-list/scheduled-flag.svg">
                    <img *ngIf="survey.surveyScheduleType.scheduleType=='INSTANT'||(survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='DRAFT'&&survey.surveyStatus!='SCHEDULED')"
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/campaign-list/ended-flag.svg"> -->
                                    <!-- </span> -->





                                    <span
                                        [ngClass]="(survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='SCHEDULED')?'redbox':'greenbox'"
                                        *ngIf="survey.surveyScheduleType.scheduleType!='INSTANT'">

                                        <span class="surveyStatusText ACTIVE"
                                            *ngIf="survey.surveyStatus=='ACTIVE'||survey.surveyStatus=='SCHEDULED'">{{'SURVEY_PAGE.ACTIVE' | i18nTranslate}}</span>
                                        <span class="surveyStatusText ENDED"
                                            *ngIf="survey.surveyStatus!='ACTIVE'&&survey.surveyStatus!='DRAFT'&&survey.surveyStatus!='SCHEDULED'">{{'SURVEY_PAGE.ENDED' | i18nTranslate}}</span>
                                        <span class="surveyStatusText DRAFT" *ngIf="survey.surveyStatus=='DRAFT'">{{'SURVEY_PAGE.DRAFT' | i18nTranslate}}</span>
                                    </span>
                                    <span
                                        [ngClass]="(survey.surveyScheduleType.scheduleType=='INSTANT')?'redbox':'greenbox'"
                                        *ngIf="survey.surveyScheduleType.scheduleType=='INSTANT'">

                                        <span class="surveyStatusText ENDED">{{'SURVEY_PAGE.ENDED' | i18nTranslate}}</span>

                                    </span>
                                </div>
                                <!-- <div class="surveyScheduleType " [ngClass]="{active:selectedi==i}" style="width: 10%;"
                                    *ngIf="appSubscriptionPlan">
                                    <mat-icon [matMenuTriggerFor]="menuDraft">
                                        more_vert</mat-icon>

                                    <mat-menu #menuDraft class="channelDialog">
                                        <button class="edit-button"
                                            *ngIf="(survey.surveyScheduleType.scheduleType!='INSTANT'&&survey.surveyScheduleType.scheduleType!='CAMPAIGN')"
                                            matTooltipPosition="below" [matTooltip]="'COMMON_PAGE.EDIT_SCHEDULE' | i18nTranslate"
                                            (click)="updateScheduledSurvey(survey.surveyId,survey.surveyTemplateId, survey?.createdBy?.qid, survey?.createdBy?.storeName);$event.stopPropagation();"
                                            style="color: #3a3a3a;cursor: pointer;" data-button-name="Edit Schedule"
                                            [attr.data-item-name]="'('+survey.surveyId+')'+survey.surveyName">{{'SURVEY_PAGE.EDIT' | i18nTranslate}}
                                        </button>
                                        <button class="edit-button default-cursor"
                                            *ngIf="(survey.surveyScheduleType.scheduleType=='INSTANT')"
                                            matTooltipPosition="below"
                                            [matTooltip]="'COMMON_PAGE.INSTANT_CANNOT_RESCHEDULE' | i18nTranslate"
                                            style="color: #3a3a3a;">{{'SURVEY_PAGE.EDIT' | i18nTranslate}}
                                        </button>
                                        <button class="edit-button default-cursor"
                                            *ngIf="(survey.surveyScheduleType.scheduleType=='CAMPAIGN')"
                                            matTooltipPosition="below"
                                            [matTooltip]="'COMMON_PAGE.CAMPAIGN_SURVEY_CANNOT_RESCHEDULE' | i18nTranslate">
                                            {{'SURVEY_PAGE.EDIT' | i18nTranslate}}</button>
                                    </mat-menu>
                                </div> -->
                            <!-- <div style="width:10%; display: flex; justify-content: center;" *ngIf="appSubscriptionPlan">
                                <mat-slide-toggle (change)="toggleSurvey(i,$event, survey?.createdBy?.qid, survey?.createdBy?.storeName);" (click)="$event.stopPropagation();"
                                    [checked]="survey.surveyStatus === 'ACTIVE' ? true : false"
                                    data-button-name="Schedule toggle"
                                    [attr.data-item-name]="'('+survey.surveyId+')'+survey.surveyName"
                                    *ngIf="survey.surveyScheduleType.scheduleType!='INSTANT'&&survey.surveyScheduleType.scheduleType!='CAMPAIGN'">
                                </mat-slide-toggle>
                                <mat-slide-toggle checked="false" disabled="true"
                                    *ngIf="survey.surveyScheduleType.scheduleType=='INSTANT'" matTooltipPosition="below"
                                    [matTooltip]="'COMMON_PAGE.INSTANT_CANNOT_RESCHEDULE' | i18nTranslate">
                                </mat-slide-toggle>
                                <mat-slide-toggle checked="false" disabled="true"
                                    *ngIf="survey.surveyScheduleType.scheduleType=='CAMPAIGN'"
                                    matTooltipPosition="below"
                                    [matTooltip]="'COMMON_PAGE.CAMPAIGN_SURVEY_CANNOT_RESCHEDULE' | i18nTranslate">
                                </mat-slide-toggle>
                            </div> -->

                        </div>
                    </div>

                </div>
                <div *ngIf="loading" style="    justify-content: center;
                display: flex;
                margin-top: 2%;
                height: 33px;
                align-items: center;">

                    <mat-spinner diameter="15"></mat-spinner>
                </div>
            </div>
            <div *ngIf="!loading && totalSchedulelength>0">
                <mat-paginator #paginator [pageIndex]=0 [length]=totalSchedulelength [pageSize]=pageSize
                    [pageSizeOptions]="[5, 10, 25, 100]" (page)="getNextData($event)">
                </mat-paginator>
            </div>
            <div *ngIf="totalSchedulelength==0">
                <div class="viewcustomerContanier" style="width: 100%;justify-content: center;">
                    <div style="display: flex;
                  align-items: center;
                  position: relative;
                  height: 50vh;
                  justify-content: center;
                flex-direction: column;">
                        <div *ngIf="isAdmin" style="
                        padding: 10px;
                        border: 1px dotted #ff3b30;
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 98%;
                       " class="data-avai-border">
                       {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                       </div>
                        <div *ngIf="!isAdmin">
                            <div class="People-will-enroll-i">{{'SURVEY_PAGE.NOT_SCHEDULED_ANY_SURVEY' | i18nTranslate}}</div>
                        </div>
                        <div *ngIf="!isAdmin" class="primary-button" (click)="surveylist()" data-button-name="Create New Survey">
                            <mat-icon class="AddNewEntryIcon2">
                                add
                            </mat-icon>
                            <span class="normText" style="color: #fff;">{{'SURVEY_PAGE.CREATE_NEW_SURVEY_ADD' | i18nTranslate}}</span>
                        </div>
                    </div>
                </div>

            </div>



            <!-- </div> -->


        </div>
        <!-- <ng-template #noSchdeuledSurveysData>
            <div style="margin: 5%;    border-radius: 3px;
            background-color: #edf0fa;
            padding: 2%;margin-top: 0;display: flex;justify-content: center;align-items: center;">No scheduled surveys

            </div>

        </ng-template> -->
    </div>

    
    <div *ngIf="!mobileView" class="sentimentMask" style="width: 35%; border-radius: 14px;">
        <div *ngIf="!graphDataLoading&&graphData">
            <app-survey-sentiment-dialogbox [surveyData]="graphData"></app-survey-sentiment-dialogbox>
        </div>
        <div *ngIf="graphDataLoading" style="    justify-content: center;
    display: flex;
    margin-top: 2%;
    height: 33px;
    align-items: center;">
            <mat-spinner diameter="15"></mat-spinner>
        </div>
        <div *ngIf="!graphData&&!graphDataLoading" class="qb-no-data-area-email">
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg"
                            alt="">
                        <div style="margin-top: 15px;">
                            {{'SEGMENT_PAGE.THERE_IS_NO_DATA_AVAILABLE' | i18nTranslate}}
                        </div>
    </div>
    </div>
</div>