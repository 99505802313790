import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { GetSmsConsentComponent, SmsTemplateCreationComponent, SmsTemplateListingComponent, SmsTemplateTileComponent } from './pages';

const routes: Routes = [
    { path: "", redirectTo: "choose-layout", pathMatch: "full" },
    {
        path: "choose-layout",
        component: SmsTemplateTileComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "SMS",
          title: "Choose SMS template",
          minPath: "Basic",
          id: 18,
        },
    },
    {
        path: "list",
        component: SmsTemplateListingComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "SMS",
          title: "Choose SMS template",
          minPath: "Basic",
          id: 18,
        },
    },
    {
        path: "sms-consent",
        component: GetSmsConsentComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "SMS",
          title: "Choose SMS template",
          minPath: "Basic",
          id: 18,
        },
    },
    {
        path: "layout-editor",
        component: SmsTemplateCreationComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "SMS",
          title: "Choose SMS template",
          minPath: "Basic",
          id: 19,
        },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmsRoutingModule { }
