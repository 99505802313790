<div class="qb-main-conatiner">
    <div class="qb-d-flex qb-jc-space-between">

        <div class="qb-mr-24"><img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/gamestep0.svg"
                alt=""></div>
        <div>
            <div class="qb-d-flex qb-jc-space-between qb-ai-center qb-mb-14">
                <div class="qb-gf-intro-text qb-heading-font" >{{'GAMIFY_PAGE.GAMIFY_HEADING_FIRST' | i18nTranslate}}</div>
                <div class="primary-button" (click)="createNewGame()">{{'GAMIFY_PAGE.CREATE_NEW_GAME' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-intro-text qb-mb-10">{{'GAMIFY_PAGE.GAMIFY_MESSAGE' | i18nTranslate}}
                <span class="qb-q-color">Qubriux Gamification!</span>
            </div>
            <br>
            <div class="qb-gf-intro-text qb-mb-10" >
               {{'GAMIFY_PAGE.GAMIFY_MESSAGE_TWO' | i18nTranslate}}
            </div>

            <div class="qb-gf-intro-text">{{'GAMIFY_PAGE.GAMIFY_MESSAGE_THREE' | i18nTranslate}} </div>
            <br>
            <div class="qb-gf-italics">{{'GAMIFY_PAGE.LEARN_MORE_GAMIFY' | i18nTranslate}}<a
                    class="qb-knowledge-gf" href="https://qubriux.skellam.ai/knowledge-hub/gamify" target="_blank">{{'GAMIFY_PAGE.KNOW;EDGE_HUB' | i18nTranslate}}</a>.</div>
        </div>
    </div>



    <div class="qb-how-to-create">
        <div class="qb-gf-intro-text qb-gf-intro-text-position" >{{'GAMIFY_PAGE.HOW_TO_CREATE_GAME' | i18nTranslate}}</div>
        <div class="qb-d-flex qb-jc-space-between qb-w-100">

            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep1q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    {{'GAMIFY_PAGE.CREATE_GAMIFY_STEP_ONE' | i18nTranslate}}
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep2q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    {{'GAMIFY_PAGE.CREATE_GAMIFY_STEP_TWO' | i18nTranslate}}
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        class="qb-p-10"
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep3q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    {{'GAMIFY_PAGE.CREATE_GAMIFY_STEP_THREE' | i18nTranslate}}
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep4q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    {{'GAMIFY_PAGE.CREATE_GAMIFY_STEP_FOUR' | i18nTranslate}}
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep5q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    {{'GAMIFY_PAGE.CREATE_GAMIFY_STEP_FIVE' | i18nTranslate}}
                </div>
            </div>



        </div>
    </div>