import { Injectable, EventEmitter } from "@angular/core";
import { FlatTopic } from "../../shared/models/flat-topic.model";

@Injectable()
export class EventsService {
  
  topicsForAModelFetched = new EventEmitter<boolean>();

  topicSelectedFromCountChart = new EventEmitter<string>();

  topicSelectedFromBreadCrumb = new EventEmitter<string>();

  topicSelectedFromTree = new EventEmitter<FlatTopic>();

  createNewTopicSelected = new EventEmitter<any>();

  deleteModelSelected = new EventEmitter<any>();

  newTopicCreated = new EventEmitter<any>();

  editTopicSelected = new EventEmitter<any>();

  autoTopicResultFetched = new EventEmitter<any>();

  bubbleChartUpdate = new EventEmitter<any>();

  bubbleChartRendered = new EventEmitter<any>();

  bubbleNodeSelected = new EventEmitter<any>();

  autoTopicCompleted = new EventEmitter<any>();

  newTopicSelectedForMultiReporting = new EventEmitter<FlatTopic[]>();

  reportingPanelClosed = new EventEmitter<number>();

  topicSelectedFromReportingGraph = new EventEmitter<any>();

}