<div class="qb-main-div">
  <span class="primary-button qb-create-new-button" (click)="createForm()">
    + {{'ACQUIRE_PAGE.CREATE_FROM_SCRATCH' | i18nTranslate}}</span
  >
</div>
<div class="qb-main">
  <div *ngIf="loading" class="qb-spinner-div">
    <mat-spinner diameter="15"></mat-spinner>
  </div>
  <div class="qb-no-match-container" *ngIf="!loading && formData.length == 0">
    <div class="qb-no-data">
      <div class="qb-no-match-div">{{'ACQUIRE_PAGE.NO_MATCH_FOUND' | i18nTranslate}}</div>
    </div>
  </div>
  <div class="qb-content" *ngIf="!loading" #content>
    <div *ngFor="let form of formData" class="qb-form-list">
      <div class="qb-rectangle-list-forms" [id]="'form-' + form['id']">
        <div class="qb-list">
          <div class="qb-edit-icon-forms">
            <span
              class="material-icons-outlined icons-primary cursor-pointer qb-clone-icon"
              (click)="cloneForm(form['id'])"
              [matTooltipShowDelay]="500"
              [matTooltip]="'COMMON_PAGE.CLONE' | i18nTranslate"
            >
              content_copy
            </span>
          </div>
        </div>
        <div class="qb-template-div">
          <div class="qb-template-image-container-forms">
            <img
              [src]="form['snapshot']"
              class="qb-form-snapshot"
              alt=" Form-Template "
            />
          </div>
          <div class="qb-form-details">
            <div>
              <div
                class="qb-template-head-text"
                [matTooltip]="form['name']"
                matTooltipClass="ex-tooltip-red1"
                matTooltipShowDelay="500"
              >
                {{ form["name"] }}
              </div>
            </div>
            <div class="qb-digits">
              <span class="qb-values"
                >{{'ACQUIRE_PAGE.VISIBLE_TO' | i18nTranslate}} :
                <span>{{ form["scheduleInfo"]["showTo"] }}</span>
              </span>
            </div>
            <div class="qb-digits">
              <span class="qb-values"
                >{{'ACQUIRE_PAGE.VISIBLE_WHEN' | i18nTranslate}} :
                <span
                  *ngIf="form['scheduleInfo']['showWhen'] === 'AFTER_X_PERCENT'"
                  >{{'ACQUIRE_PAGE.AFTER_SCROLL' | i18nTranslate : form["scheduleInfo"]["showWhenValue"]}} </span
                >
                <span
                  *ngIf="form['scheduleInfo']['showWhen'] === 'AFTER_X_SECONDS'"
                  >{{'ACQUIRE_PAGE.AFTER_SECOND_ACTIVITY' | i18nTranslate : form["scheduleInfo"]["showWhenValue"]}} </span
                >
                <span
                  *ngIf="
                    form['scheduleInfo']['showWhen'] ===
                    'Immediately on website'
                  "
                  >{{ form["scheduleInfo"]["showWhen"] }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
