import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { countries } from '../../../json-asset/countries';

@Component({
  selector: 'app-country-dropdown-wrapper',
  template: `
    <mat-select [ngModel]="selectedCountry" (ngModelChange)="onChange($event)">
      <mat-option *ngFor="let country of countries" [value]="country.alpha2Code">
        <img [src]="country.flag" alt="Flag of {{ country.name }}" width="20" height="15">
        {{ country.name }} ({{ country.alpha2Code }}) +{{ country.callingCodes[0] }}
      </mat-option>
    </mat-select>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryDropdownWrapperComponent),
      multi: true,
    },
  ],
})
export class CountryDropdownWrapperComponent implements ControlValueAccessor {
  countries = countries;
  selectedCountry: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.selectedCountry = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
