import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PartnershipFormsService } from "src/app/core/services/partnership-forms.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { ConfirmationDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: "app-partnership-forms",
  templateUrl: "./partnership-forms.component.html",
  styleUrls: ["./partnership-forms.component.css"],
})
export class PartnershipFormsComponent implements OnInit {
  constructor(
    private router: Router,
    private apiCall: ApiCallService,
    private sharedDataService: PartnershipFormsService,
    private snackBar: SnackbarCollection,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  formsList;
  selectedTab = "ALL";
  message: string;
  subscription: Subscription;
  formsLoading = true;
  pageNumber = 1;
  request;

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 5
    ) {
      this.pageNumber += 1;
      this.getFormsList(true);
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      this.request = param.request;
      this.getFormsList(false);
    });
  }

  getFormsList(appendData) {
    if (!appendData) {
      this.formsLoading = true;
    }
    this.apiCall
      .getPartnershipForms(this.pageNumber, this.selectedTab)
      .subscribe((response) => {
        if (appendData) {
          response["body"]["list"].forEach((form) => {
            this.formsList.push(form);
          });
        } else {
          this.formsList = response["body"]["list"];
        }
        this.formsList.forEach((form) => {
          form["scheduleInfo"] = JSON.parse(form["scheduleInfo"]);
        });
        this.formsLoading = false;
      });
  }

  createForm() {
    this.router.navigate([
      "/app/partnership/create-form",
      {
        request: this.request,
        type: "CUSTOM",
        editForm: false,
      },
    ]);
  }

  selectTab(type) {
    this.pageNumber = 1;
    this.selectedTab = type;
    this.getFormsList(false);
  }

  clone(form) {
    this.apiCall
      .getPartnershipFormDetails(form.id, form.type)
      .subscribe((response) => {
        this.sharedDataService.changeMessage(response["body"]);
        this.router.navigate([
          "/app/partnership/create-form",
          {
            id: form["id"],
            clone: true,
            request: this.request,
            type: form.type,
            editForm: false,
          },
        ]);
      });
  }

  edit(form) {
    this.sharedDataService.changeMessage(form);
    this.router.navigate([
      "/app/partnership/create-form",
      {
        id: form["id"],
        clone: false,
        request: -1,
        type: form.type,
        editForm: false,
      },
    ]);
  }

  delete(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject:  this._i18nDynamicTranslate.transform("Delete Form", ['POS_PAGE']),
        message:  this._i18nDynamicTranslate.transform("Do you wish to delete this form?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formsLoading = true;
        this.apiCall.deletePartnershipForm(id).subscribe(
          (response) => {
            this.getFormsList(false);
          },
          (error) => {
            this.formsLoading = false;
            this.snackBar.openSnackBar( this._i18nDynamicTranslate.transform("Failed to delete form", ['POS_PAGE']), 2000);
          }
        );
      }
    });
  }
}
