<div data-widget-name="OFFER: SAVE AND EXIT">
  <div>
    <div class="header-offer">
      <span style="text-align: center">{{'SHARED_PAGE.SAVE_EXIT' | i18nTranslate}}</span>
    </div>
  </div>
  <div>
    <div class="message-offer">
      <span>{{'SHARED_PAGE.ADD_CHANNEL_AND_SAVE' | i18nTranslate}}</span>
    </div>
  </div>
  <!-- <div *ngIf="data['to-screen']" style="
      display: flex;
      justify-content: space-between;
      padding: 0px 20px 15px 20px;
    ">
        <span class="dismiss-button" (click)="submit(false)">Cancel</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen'] != 'campaign'">OK</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen']!='campaign'&& data['to-screen']!='survey-alert'">OK</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen']=='survey-alert'">Delete</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen'] == 'campaign'" widget-id="16" event-id="2" type="button" [attr.data-strategy-action]="data['action']" [attr.object-type]="data['strategyType']">Confirm</span>
    </div> -->

  <div style="
      display: flex;
      justify-content: space-between;
      padding: 0px 20px 15px 20px;">
    <span class="dismiss-button-offer" (click)="submit('nochannel')" data-button-name="No, Just Save and Exit">{{'SHARED_PAGE.NO_JUST_SAVE_EXIT' | i18nTranslate}}</span>
    <span class="done-button-offer" (click)="submit('addchannel')"data-button-name="Yes, Add channel and Save">{{'SHARED_PAGE.YES_ADD_CHANNEL_SAVE' | i18nTranslate}}</span>
  </div>
</div>