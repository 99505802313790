<div>
    <div class="heading-text"><span>{{'SHARED_PAGE.SELECT_REWARD_TEMPLATE' | i18nTranslate}}</span></div>
    <div style="margin-top: 15px;">

    </div>
    <div style="margin-top: 15px; overflow: hidden; max-height: 250px">

        <div class="template-list" style="margin-top: 5px; max-height: 200px; overflow: auto;">
            <div *ngFor="let layout of data; let i = index" class="template-card" (click)="selectTemplate(i)" [style.border]="selectedTemplate === i ? '3px solid #4abb5975' : '3px solid #FFF'">
                <div style="height: 95px;">
                    <img style="height: 100%; width: 100%;" class="image-class" [src]="layout['snapshotUrl']" alt="Reward  Templates">
                </div>
                <div class="template-name-div">
                    <div style="display: flex; overflow: hidden;">
                        <span [matTooltip]="layout['layoutName']" style="min-inline-size: max-content;">{{layout['layoutName']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
        <div (click)="closeDialog(false)" class="dismiss-button"><span style=" font-size: 13px;">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span></div>
        <div (click)="closeDialog(selectedTemplate)" class="done-button" style="margin-left: 10px;"><span style=" font-size: 13px;">{{'SHARED_PAGE.SELECT' | i18nTranslate}}</span></div>
    </div>
</div>