import { Component, OnInit, HostListener} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-campaign-templates',
  templateUrl: './campaign-templates.component.html',
  styleUrls: ['./campaign-templates.component.css']
})
export class CampaignTemplatesComponent implements OnInit {

  scrHeight;
  scrContentHeight;
  scrWidth;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrContentHeight = window.innerHeight - 60 - 90;
        this.scrWidth = window.innerWidth;
  }
  constructor(private route : ActivatedRoute,
    private router : Router){ this.getScreenSize() }

  ngOnInit(){}

  selectManageType(type){
    if(type === 'List'){
      this.router.navigate(['listing'], {
        relativeTo: this.route,
      });
    }
    if(type === 'Create'){
      this.router.navigate(['creation'], {
        relativeTo: this.route,
      });
    }
  }
  
}
