import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { response } from 'express';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-schedule-partnership-offer-sucess-dialog-box',
  templateUrl: './schedule-partnership-offer-sucess-dialog-box.component.html',
  styleUrls: ['./schedule-partnership-offer-sucess-dialog-box.component.css']
})
export class SchedulePartnershipOfferSucessDialogBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SchedulePartnershipOfferSucessDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCall: ApiCallService,
    private _snackBar: SnackbarCollection,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }
  @ViewChild("userSegment") elementViewProfile: any;
  dropdownStatusSegment = false;
  customerSelectedSegment = ["ALL"];
  tempSegmentGameFilter = "";
  customerSegment = [];
  allcustomer = [{ id: "ALL_CUSTOMERS", name: this._i18nDynamicTranslate.transform("All customers", ['SHARED_PAGE']) }];
  ngOnInit(): void {
    this.getCustomerSegment();
  }
  // selectopenclose(value) {
  //   switch (value) {
  //     case "customerSegmentsSelect":
  //       this.customerSegmentsSelect.open();
  //       break;
  //   }
  // }
  toggleSelectSegment(click?) {
    let element = document.getElementById("multi-select-dropdown-seg");
    let segmentSelect = document.getElementById("segment-select-seg");
    let segmentSelectPanel = document.getElementById("multi-select-panel-seg");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-seg"
    );
    if (click && element && segmentSelect && segmentSelectPanel) {
      this.dropdownStatusSegment = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.border = "1px solid #636466";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      if (this.dropdownStatusSegment) {
        this.dropdownStatusSegment = false;
        element.style.height = "0px";
        segmentSelect.style.overflow = "hidden";
        segmentSelectPanel.style.boxShadow = "unset";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(0deg)";
        //element.style.display = 'none';
      } else {
        this.dropdownStatusSegment = true;
        element.style.height = "200px";
        segmentSelect.style.overflow = "auto";
        segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(180deg)";
        //element.style.display = 'block';
      }
    }
  }  addAllCustomers(event) {
    this.customerSelectedSegment = [];
    if (event["checked"] === true) {
      this.customerSelectedSegment = [];
      this.customerSelectedSegment.push("ALL");
    }
  }
  isChecked(segment) {
    if (
      this.customerSelectedSegment.length === 1 &&
      this.customerSelectedSegment[0] === "ALL"
    ) {
      return false;
    } else {
      let index = this.customerSelectedSegment.findIndex(
        (x) => x === segment.id
      );
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  updateCheckboxValuesCustomerSegment(segment) {
    if (
      this.customerSelectedSegment.length === 1 &&
      this.customerSelectedSegment[0] === "ALL"
    ) {
      this.customerSelectedSegment = [];
    }
    let index = this.customerSelectedSegment.findIndex((x) => x === segment.id);
    if (index < 0) {
      this.customerSelectedSegment.push(segment.id);
    } else {
      this.customerSelectedSegment.splice(index, 1);
    }
  }
  getCustomerSegment() {
    //NEED
    this.apiCall.getCustomerSegmentsAndTiers().subscribe(
      (response) => {
        if (response["message"] == "success") {
          let segment = (response["body"]);
          for (let i = 0; i < segment.length; i++) {
            this.customerSegment.push({
              id: segment[i]["id"],
              name: segment[i]["segmentName"],
              isActive: segment[i]["isActive"],
              status: false,
            });
          }
        } else {
          this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load customer segments", ['SHARED_PAGE']), 1000);
        }
      },
      (err) => {
        this._snackBar.openSnackBar(err.error.body, 1000);
      }
    );
  }
  submit(decision) {
    if(decision === true){
      let value = {
        segments: this.customerSelectedSegment,
        comOfferId:  this.data.offerId,
        merchantId: this.tokenStorage.getMerchantId()
      }
      this.apiCall.scheduleOfferPartnershipPOS(value).subscribe((response)=>{

      })
    }
    this.dialogRef.close(decision);
  }

}
