<div class="header">
  <span style="text-align: center; font-weight: bold;">{{data["subject"]}}</span>
  <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
</div>
<div style="overflow-y: auto; height: calc(100% - 46px);">
  <!-- <div class="header">
    <span style="text-align: center;">{{data["subject"]}}</span>
    <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
  </div> -->
    <div class="message">{{'SHARED_PAGE.TARGETIG_TACTICS_PARAGRAPH' | i18nTranslate}}</div>
  <b class="message">{{'SHARED_PAGE.TARGETING_TACTICS' | i18nTranslate}}:</b>
  <div [ngSwitch]="data['subject']">
    <div *ngSwitchCase="'Active'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.ACTIVE_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.ACTIVE_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.ACTIVE_POINT_3' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.ACTIVE_POINT_4' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="'At Risk'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.AT_RISK_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.AT_RISK_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.AT_RISK_POINT_3' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.AT_RISK_POINT_4' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.AT_RISK_POINT_5' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.AT_RISK_POINT_6' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="'High Spender'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.BIG_SPENDER_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.BIG_SPENDER_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.BIG_SPENDER_POINT_3' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="'Lost but high value'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_HIGH_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_HIGH_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_HIGH_POINT_3' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_HIGH_POINT_4' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_HIGH_POINT_5' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="'Lost but low value'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_LOW_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_LOW_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOST_BUT_LOW_POINT_3' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="'Loyal'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.LOYAL_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOYAL_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOYAL_POINT_3' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.LOYAL_POINT_4' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="'New Customer'">
      <ul class="tactics">
        <li>
          <div>{{'SHARED_PAGE.NEW_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.NEW_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.NEW_POINT_3' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'SHARED_PAGE.NEW_POINT_4' | i18nTranslate}}</div>
        </li>
      </ul>
    </div>
    <div *ngSwitchDefault>
      <ul>
        <li>
          <span>Sample1 for {{data["subject"]}}</span>
        </li>
        <li>
          <span>Sample2 for {{data["subject"]}}</span>
        </li>
      </ul>
    </div>
  </div>