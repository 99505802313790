export const template4 = {
  page: {
    body: {
      container: {
        style: {
          "background-color": "transparent",
        },
      },
      content: {
        computedStyle: {
          linkColor: "#7c4b96",
          messageBackgroundColor: "transparent",
          messageWidth: "500px",
        },
        style: {
          color: "#000000",
          "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif",
        },
      },
      type: "mailup-bee-page-properties",
      webFonts: [
        {
          fontFamily: "'Bitter', Georgia, Times, 'Times New Roman', serif",
          name: "Bitter",
          url: "https://fonts.googleapis.com/css?family=Bitter",
        },
      ],
    },
    description: "",
    rows: [
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "500px",
                  },
                  image: {
                    alt: "LogoPlaceHolder",
                    height: "148px",
                    href: "",
                    percWidth: 25,
                    prefix: "",
                    src: "https://bee-editor-images-qa.s3.amazonaws.com/user/user-id-249/Text%20%26%20Icons_Header-02.png",
                    target: "_self",
                    width: "148px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "a63c2f14-9a30-4c24-a7bd-e9e9ef3923e3",
              },
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "500px",
                  },
                  image: {
                    alt: "Alternate text",
                    height: "820px",
                    href: "",
                    src: "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/55d274b7-252b-45ed-bff0-a2b81c79627c/user-id-176/Excited%20Offer.jpg",
                    width: "1920px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "60cf4e8d-0bd3-4c48-bfa0-9328aa842f64",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Bitter, Georgia, Times, Times New Roman, serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Bitter, Georgia, Times, Times New Roman, serif; font-size: 12px; line-height: 21px;"><p style="font-size: 12px; line-height: 21px; text-align: center; word-break: break-word; font-family: Bitter, Georgia, Times, Times New Roman, serif;" data-mce-style="font-size: 12px; line-height: 21px; text-align: center; word-break: break-word;"><span style="font-size: 34px; line-height: 61px; color: rgb(255, 255, 255);" data-mce-style="font-size: 34px; line-height: 61px; color: rgb(255, 255, 255);"><strong>Here&apos;s an offer just for you! </strong></span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Bitter', Georgia, Times, 'Times New Roman', serif",
                      "line-height": "180%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "8fffb1ce-263b-4d20-8af2-b0ea20cf22fb",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Bitter, Georgia, Times, Times New Roman, serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Bitter, Georgia, Times, Times New Roman, serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; text-align: center; word-break: break-word; font-family: Bitter, Georgia, Times, Times New Roman, serif;" data-mce-style="font-size: 13px; line-height: 16px; text-align: center; word-break: break-word;"><span style="font-size: 24px; line-height: 28px;" data-mce-style="font-size: 24px; line-height: 28px;"><strong><span style="line-height: 28px; font-size: 24px;" data-mce-style="line-height: 28px; font-size: 24px;"><span style="color: rgb(255, 255, 255); font-size: 24px; line-height: 28px;" data-mce-style="color: rgb(255, 255, 255); font-size: 24px; line-height: 28px;">Hi {{customerName}}  </span></span></strong></span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Bitter', Georgia, Times, 'Times New Roman', serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "b850325f-2a64-465c-a4fe-b7037cd72d12",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "e13cb587-03f4-44b7-a0a8-ab0c23259082",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "d61702b8-ee16-4490-ae84-3f118799d272",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; text-align: center; word-break: break-word;">Get  exciting offers on your next order!</p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "38575f7d-68b2-4864-8fdb-b491389c18df",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "c29c716e-c0e7-4e96-9a97-de8f8d7de0ff",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "fdcf7058-e8e7-47cf-bdd7-d76026d1a0e1",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  button: {
                    href: "{{shopUrl}}",
                    label:
                      '<div class="txtTinyMce-wrapper" style="font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;" data-mce-style="font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;"><p style="font-size: 12px; line-height: 18px; word-break: break-word; font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif;" data-mce-style="font-size: 12px; line-height: 18px; word-break: break-word;"><strong>Shop Now</strong></p></div>',
                    style: {
                      "background-color": "#2e9db6",
                      "border-bottom": "0px solid transparent",
                      "border-left": "0px solid transparent",
                      "border-radius": "4px",
                      "border-right": "0px solid transparent",
                      "border-top": "0px solid transparent",
                      color: "#ffffff",
                      "font-family": "inherit",
                      "line-height": "150%",
                      "max-width": "100%",
                      "padding-bottom": "5px",
                      "padding-left": "20px",
                      "padding-right": "20px",
                      "padding-top": "5px",
                      width: "auto",
                    },
                  },
                  computedStyle: {
                    height: 27,
                    hideContentOnMobile: false,
                    width: 98,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                    "text-align": "center",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-button",
                uuid: "d8dd31ff-a750-47fa-9101-32b3948b9d7e",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "bbc745ea-2929-4a51-9282-574c6f049f54",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "d353bcee-4191-4c77-9e29-85aada7ad2a3",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Bitter, Georgia, Times, Times New Roman, serif; line-height: 14px; font-size: 12px;" data-mce-style="font-family: Bitter, Georgia, Times, Times New Roman, serif; line-height: 14px; font-size: 12px;"><p style="line-height: 14px; text-align: center; font-size: 12px; word-break: break-word; font-family: Bitter, Georgia, Times, Times New Roman, serif;" data-mce-style="line-height: 14px; text-align: center; font-size: 12px; word-break: break-word;"><span style="font-size: 14px; color: rgb(49, 49, 49); line-height: 19px;" data-mce-style="font-size: 14px; color: rgb(49, 49, 49); line-height: 19px;"><strong>Grab the offer from</strong></span><span style="line-height: 21px; font-size: 16px;" data-mce-style="line-height: 21px; font-size: 16px;"><span style="color: rgb(46, 157, 182); font-size: 16px; line-height: 21px;" data-mce-style="color: rgb(46, 157, 182); font-size: 16px; line-height: 21px;"><strong> {{shopUrl}} </strong></span></span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Bitter', Georgia, Times, 'Times New Roman', serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "b7c6a9cd-f2c4-4fb8-9acc-fd09c06c8336",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "bd35954e-9513-4a57-af8d-3d10e0c77dd1",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "08f5b5a2-053a-4e6b-b7e8-c04755b375f0",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "0px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;">{{merchantName}}</p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "5b4e275e-7c35-4220-82ec-a2c69e72eb5b",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "0px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "0px",
            },
            uuid: "1a4fef73-33d0-4010-876d-791e48881452",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "f3c0bb82-781e-4f38-b33d-a639f531bda9",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;">  </p><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;">Visit {{shopUrl}} for more</p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "82cd6efe-5742-4fd5-a447-b4f63b00dae6",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "a5066c80-0488-44bb-9f1e-5b3574ac1acf",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "d66d1d47-b567-40e4-b314-5ddd2d12dc61",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    height: 57,
                    hideContentOnMobile: false,
                    iconsDefaultWidth: 32,
                    padding: "0 2.5px 0 2.5px",
                    width: 151,
                  },
                  iconsList: {
                    icons: [],
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                    "text-align": "center",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-social",
                uuid: "3687c5a2-cd8f-4c25-ace5-1866278b0d1e",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="line-height: 14px; font-size: 12px;" data-mce-style="line-height: 14px; font-size: 12px;"><p style="font-size: 13px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 13px; line-height: 16px; word-break: break-word; text-align: center;"><span style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;">Copyrights © 2024  I   To unsubscribe from these mailings, you may opt out here.</span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "fd8eab67-9325-43e3-8a0e-feb047c165b0",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "f457a76d-0fc6-4468-ba28-759b7c335fb0",
          },
        ],
        container: {
          style: {
            "background-color": "#f8f5e9",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            verticalAlign: "top",
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "700px",
          },
        },
        empty: false,
        locked: false,
        synced: false,
        type: "one-column-empty",
        uuid: "da4234d5-9fa6-47bf-bdc5-8a9cfb24b77f",
      },
    ],
    template: {
      name: "template-base",
      type: "basic",
      version: "2.0.0",
    },
    title: "",
  },
  comments: null,
};
