import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardPlan } from 'src/app/core/services/current-plan-auth.service';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { SurveyDashboardComponent, SurveyInstanceComponent } from './components';
import { SurveyBaseComponent, SurveyComponent, SurveyCreationComponent, SurveyScheduleComponent, SurveyViewComponent } from './pages';

const routes: Routes = [
  { path: "", redirectTo: "feature", pathMatch: "full" },
  { path: 'feature', component: SurveyBaseComponent },
  {
    path: "survey-list/schedule",
    component: SurveyScheduleComponent,
    canActivate: [WidgetRouteGaurdService],
    data: {
      titleheading: "Surveys > Create Survey > Schedule Survey",
      title: "Schedule Survey",
      minPath: "Advanced",
      id: 9,
    },
  },
  {
    path: "survey-list",
    component: SurveyComponent,
    data: { titleheading: "Surveys ", title: "" },
  },

  {
    path: "add-survey",
    component: SurveyCreationComponent,
    canActivate: [AuthGuardPlan, WidgetRouteGaurdService],
    data: {
      titleheading: "Surveys > Create Survey > New template",
      title: "Create Survey",
      minPath: "Advanced",
      id: 8,
    },
  },
  {
    path: "survey-dashboard",
    component: SurveyDashboardComponent,
    canActivate: [WidgetRouteGaurdService],
    data: {
      titleheading: "Surveys",
      title: "Survey Dashboard",
      minPath: "Basic",
      id: 7,
    },
  },
  // {
  //   path: "survey-sentiment",
  //   component: SurveySentimentComponent,
  //   canActivate: [WidgetRouteGaurdService],
  //   data: {
  //     titleheading: "Feedback Analysis",
  //     title: "Feedback Analysis",
  //     minPath: "Pro",
  //     id: 8,
  //   },
  // },
  {
    path: "scheduled-surveys",
    component: SurveyInstanceComponent,
    data: { titleheading: "", title: "Schedule Survey" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyRoutingModule { }
