import { Component, EventEmitter, OnInit, Output, ViewChild, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { GroupOfferSucessDialogBoxComponent, HtmlForEmailTemplatesDialogComponent, SchedulePartnershipOfferSucessDialogBoxComponent, SmsPreviewDialogComponent } from 'src/app/shared/components/dialog-box';
import { OfferAttachEmailDialogComponent } from '../../../offer/pages';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-partnership-channel-select',
  templateUrl: './partnership-channel-select.component.html',
  styleUrls: ['./partnership-channel-select.component.css']
})
export class PartnershipChannelSelectComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(
    private apiCall: ApiCallService,
    private tokenstorage: TokenStorageService,
    private dialog: MatDialog,
    private snackbar: SnackbarCollection,
    private router: Router,
    private route: ActivatedRoute,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }
  message: string = "Hola Mundo!";
  emailLayoutJSON;
  offerText1 = "";
  offerText2 = "";
  displayEmailLayoutHTML
  templateName = "templat_name";
  templateName2 = ""
  templateNameSMS = "templateWSMS";
  templateNameSMS2 = "";
  templateNameWhatsApp = "templatewhatsapp";
  templateNameWhatsApp2 = "";
  emailSubject = "There is a great offer for you";
  emailSubject2 = "";
  selectedChannel=[];
  selectedOfferId = [];
  communication = {};
  channelDetails: any;
  isPos = false;
  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;
  emailId1 = null;
  emailId2 = null;
  offerId;
  appSubscriptionPlan;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() barrierId: number;
  @Input() offer_list: any;
  @Input() offer_name: any;
  @ViewChild("channelSelect") channelSelect: any;

  ngOnInit(): void {
    this.getWidgets();
    this.getemailLayout();
    if(this.tokenstorage.getMerchantOnlineStore() === 'shopify'){
      this.isPos = false;
    }
    else{
      this.isPos = true;
    }
  }
  sendcancelMessage() {
    this.messageEvent.emit(this.message);
  }
  selectopenclose(){
    this.channelSelect.open();
  }
  changeSelection(){
    console.log("PEVER",this.channelSelect)
    this.selectedChannel = this.channelSelect.value;
    console.log("SELCTED CHANNEL",this.selectedChannel);
    var value: any = {};
    
  }
  getemailLayout(){
    console.log("TESTTT",this.barrierId,this.offer_list)
    this.apiCall.getComarketingChannelDetails(this.barrierId, this.offer_list).subscribe((response)=>{
      console.log("RESPONSE",response);
      this.channelDetails = response['body'];
      this.offerText1 = this.channelDetails['beforeBarrier']['email']['offerText'];
      this.offerText2 = this.channelDetails['afterBarrier']['email']['offerText'];
      this.emailSubject = this.channelDetails['beforeBarrier']['email']['subject'];
      this.emailSubject2 = this.channelDetails['afterBarrier']['email']['subject'];
      this.templateName = this.channelDetails['beforeBarrier']['email']['emailTemplateName'];
      this.templateName2 = this.channelDetails['afterBarrier']['email']['emailTemplateName'];
      this.templateNameWhatsApp = this.channelDetails['beforeBarrier']['whatsapp'][0]['name'] ? this.channelDetails['beforeBarrier']['whatsapp'][0]['name'] : null;
      console.log("TEMPLATE_NAME",this.templateNameWhatsApp)
      this.templateNameWhatsApp2 = this.channelDetails['afterBarrier']['whatsapp'][0]['name'] ? this.channelDetails['afterBarrier']['whatsapp'][0]['name'] : null;
      this.templateNameSMS = this.channelDetails['beforeBarrier']['sms'][0]['name'] ? this.channelDetails['beforeBarrier']['sms'][0]['name'] : null;
      this.templateNameSMS2 = this.channelDetails['afterBarrier']['sms'][0]['name'] ? this.channelDetails['afterBarrier']['sms'][0]['name'] : null;
    })
    // this.apiCall
    //     .getPartnershipFormConfigurations(this.pformId, "TIME_EMAIL", this.s)
    //     .subscribe((response2) => {
    //       this.emailLayoutJSON = response2["body"]["emailJson"];
    //       this.displayEmailLayoutHTML =
    //         '<div class="bloghtml2">' +
    //         response2["body"]["emailHtml"] +
    //         "</div>";
    //       this.templateName = response2["body"]["emailTemplateName"];
    //       this.timeEmailConfig.emailSubject = response2["body"]["subject"];
    //       this.previewData.selectedLayout = response2["body"]["emailHtml"];
    //       this.previewData.templateName =
    //         response2["body"]["emailTemplateName"];
    //       this.offerData.channel = "EMAIL";
    //       this.getOfferDescription();
    //     });
  }
  getOfferDescription() {
    // this.apiCall.getOfferDescription(this.offerData).subscribe((response3) => {
    //   this.offerText = response3["body"]["allOfferDescription"];
    // });
  }
  openwhatsAppPreview(){

  }
  opensmsPreview(){
    let layout : any;
      layout =  (this.channelDetails['sms'][0]['layoutData'])
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout,
        type: "SMS"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  save(){
    let data = {
      id: null,
      offerName: this.offer_name,
      merchantId: this.tokenstorage.getMerchantId(),
      barrierId: this.barrierId,
      offerList: JSON.stringify(this.getOfferId()),
      communication: JSON.stringify(this.getCommunication())
    }
    this.apiCall.createComarketingOffer(data).subscribe((resposne)=>{
      this.offerId = resposne['body']
      console.log("DATA",data)
      const dialogRef = this.dialog.open(GroupOfferSucessDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "662px",
        data: {
          subject: this._i18nDynamicTranslate.transform("Offer create succesfully", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Your group offer has been created successfully.", ['POS_PAGE']),
          submessage:!this.isPos ? this._i18nDynamicTranslate.transform("You can now use it in forms or camapigns or both to share with your customers", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("You can now use it to schedule an offer or in journeys or both to share with your customers", ['POS_PAGE']),
          createCampaignButton: this._i18nDynamicTranslate.transform("Create Journey", ['POS_PAGE']),
          createformButton: !this.isPos ? this._i18nDynamicTranslate.transform("Create Form", ['POS_PAGE']): null,
          scheduleOfferbutton: this.isPos ? this._i18nDynamicTranslate.transform("Schedule Offer", ['POS_PAGE']) : null
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result ==='form') {
          this.createForm();
        }
        else if(result ==='offer'){
          this.scheduleOffer()
        }
        else if(result ==='campaign'){
          this.createCampaign();
        }
        else{
          this.router.navigate(['/app/partnership/my-offers'],{
            queryParams: { type: 'groupOffer' },
          });
        }
      });
    })


  }
  getOfferId(){
    let data :any  = {}
    for(let i=0; i < this.offer_list.length; i++){
      data.partnershipOfferId = this.offer_list[i]['offerId'];
      data.partnershipRequestId = this.offer_list[i]['partnershipRequests']
      this.selectedOfferId.push(JSON.parse(JSON.stringify(data)));
    }
    return this.selectedOfferId;
  }
  getCommunication(){
    var commu: any = {};
    let data1: any = {};
    let data2: any = {};
    for(let i = 0; i<this.selectedChannel.length;i++){
      console.log("Channel",this.selectedChannel[i])
      let value: any = {};
      let value2: any = {};
      if(this.selectedChannel[i]==='email'){
        value.id = this.emailId1;
        value.subject = this.emailSubject;
        value.offerText = this.offerText1;
        data1[this.selectedChannel[i]] = value;
        commu.beforeBarrier = data1
        value2.id = this.emailId2;
        value2.subject = this.emailSubject2;
        value2.offerText = this.offerText2;
        data2[this.selectedChannel[i]] = value2;
        commu.afterBarrier = data2;
      }
      else if(this.selectedChannel[i]==='sms'){
        value.id = this.channelDetails['beforeBarrier']['sms']['id'];
        data1[this.selectedChannel[i]] = value;
        commu.beforeBarrier = data1
        value2.id = this.channelDetails['afterBarrier']['sms']['id'];;
        data2[this.selectedChannel[i]] = value2;
        commu.afterBarrier = data2
      }
      else{
        value.id = this.channelDetails['beforeBarrier']['whatsapp'][0]['id'];
        data1[this.selectedChannel[i]] = value;
        commu.beforeBarrier = data1
        value2.id = this.channelDetails['afterBarrier']['whatsapp'][0]['id'];;
        data2[this.selectedChannel[i]] = value2;
        commu.afterBarrier = data2
      }
    }
    return commu;
  }
  createForm() {
    this.router.navigate([
      "/app/partnership/form-list"
    ]);
  }
  scheduleOffer(){
    const dialogRef2 = this.dialog.open(SchedulePartnershipOfferSucessDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "662px",
      height:"400px",
      data: {
        subject: this._i18nDynamicTranslate.transform("Schedule Offer", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Schedule", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        offerId: this.offerId
      },
    });
    dialogRef2.afterClosed().subscribe((result) => {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Offer Scheduled", ['POS_PAGE']), 4000);
      this.router.navigate(["/app/partnership/my-partners"]);
    });
  }

  async createCampaign() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      // this.apiCall.getCreditsThreshold(null, null).subscribe((response) => {
      //   if (response["body"] === "true") {
      //     this.snackBar.open("Not enough credits to create campaign");
      //   } else {
          this.router.navigate(
            [
              "/app/campaign/creation",
              {
                "return-url": "campaign/overview",
                id: null,
                recommendation: false,
              },
            ],
            { relativeTo: this.route }
          );
        }
    //   });
    // }
  }
  openEditor(sh, sw, datas,type) {
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1100px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: datas,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
        // this.timeEmailConfig.emailLayoutId = result.layoutId;
        if(type === 1){
          this.emailId1 = result.layoutId;
        }
        else{
          this.emailId2 = result.layoutId; 
        }
      }
    });
  }

  loadEmailEditorReward(type) {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    var datas = {
      process: "NewTemplateAndLayout",
      id: type === 1 ? this.emailId1: this.emailId2,
      tags: [],
      imageTagStatus: false,
      specialTagType: "",
      offerId: null,
      type: "FORM",
      rewardType: null,
      isReward: false,
      subject: type === 1 ? this.emailSubject : this.emailSubject2,
      emailLayoutHTML: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailHtml'] : this.channelDetails['afterBarrier']['email']['emailHtml'],
      emailLayoutJSON: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailJson'] : this.channelDetails['afterBarrier']['email']['emailJson']
    };
    this.openEditor(sh, sw, datas, type);
  }

  openSanpshotDialog(dat, type) {
    const dialogRef = this.dialog.open(HtmlForEmailTemplatesDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "60%",
      height: "500px",
      data: dat,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "edit") {
        this.loadEmailEditorReward(type);
      }
    });
  }

  opensnapshotHtmlDialog(type) {
    var dat = {
      type: "FORM",
      id: null,
      channel: null,
      templateID: null,
      rewardType: null,
      actionType: null,
      layoutHTML: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailHtml'] : this.channelDetails['afterBarrier']['email']['emailHtml'],
      subject: this.templateName,
      emailSubject: type === 1 ? this.emailSubject : this.emailSubject2
    };
    this.openSanpshotDialog(dat, type);
  }
  async getWidgets() {
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  openWhatsAppPreview(type){
    let layout : any;
    if(type === 1){
      layout =  JSON.parse(this.channelDetails['beforeBarrier']['whatsapp'][0]['layoutData'])
    }
    else{
      layout =  JSON.parse(this.channelDetails['afterBarrier']['whatsapp'][0]['layoutData'])

    }
    console.log("LAYOUTYES",layout)
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout['body'],
        type: "WhatsApp"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
