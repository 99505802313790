<div *ngIf="initialized">
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.SHOW_TO' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.showTo"
        (selectionChange)="sheduleDataChange.emit(data)"
      >
        <mat-option
          *ngFor="
            let showTo of scheduleConstants.configurations.schedule.showTo
          "
          [value]="showTo.value"
          >{{ showTo.label }}</mat-option
        >
      </mat-select>
    </div>
  </div>

  <div
    class="qb-parent-list qb-parent-list-block"
    *ngIf="data.showTo === 'SEGMENTS' && !contentData.sendEmail"
  >
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.SEGMENTS' | i18nTranslate}}</span>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          scheduleData.scheduleClicked === true &&
          scheduleData.segments.length === 0
      }"
    >
      <mat-select
        (selectionChange)="
          changeSegmentSelection(); sheduleDataChange.emit(data)
        "
        class="qb-font-exo2 qb-select"
        color="primary"
        placeholder="Select customer segment"
        [(ngModel)]="data.segments"
        multiple
        disableOptionCentering
        panelClass="myPanelClass"
      >
        <div>
          <div class="qb-rectangle">
            <mat-icon matPrefix class="qb-search-icon">search </mat-icon>
            <input
              class="qb-input"
              type="text"
              [(ngModel)]="tempsegmentFilter"
              placeholder=" Search by name "
            />
          </div>
        </div>
        <div class="qb-mat-select-dropdowns">
          <mat-option
            *ngFor="
              let segment of allcustomerObj
                | labelPipe: tempsegmentFilter:'name'
            "
            [value]="segment.id"
            [disabled]="disableAllCustomerSelection"
            class="qb-font-exo2"
            [value]="segment.id"
          >
            {{ segment.name }}
          </mat-option>

          <mat-option
            class="qb-font-exo2"
            *ngFor="
              let segment of segments | labelPipe: tempsegmentFilter:'name'
            "
            [value]="segment.id"
            [disabled]="disableCustomerSegmentSelection"
          >
            {{ segment.name }}
          </mat-option>
        </div>
      </mat-select>
    </div>
  </div>

  <div class="qb-seperator-line"></div>
  <div class="qb-success-message-container">
    <mat-checkbox [(ngModel)]="data.sendEmail"
      >{{'ACQUIRE_PAGE.SEND_EMAIL_SUBMISSION' | i18nTranslate}}</mat-checkbox
    >
    <span *ngIf="data.sendEmail" class="qb-form-email-name-container"
      ><span class="material-icons icons-primary qb-form-email-name-icon">
        attach_file </span
      ><span class="qb-form-email-name" (click)="openEmailPreviewDialog()">{{
        contentData.emailTemplateName
      }}</span></span
    >
  </div>

  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.SHOW_WHEN' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.showWhen"
        (selectionChange)="sheduleDataChange.emit(data)"
      >
        <mat-option
          *ngFor="
            let showWhen of scheduleConstants.configurations.schedule.showWhen
          "
          [value]="showWhen.value"
          >{{ showWhen.label }}</mat-option
        >
      </mat-select>
    </div>
  </div>

  <div
    class="qb-parent-list qb-parent-list-block"
    *ngIf="data.showWhen != 'IMMEDIATE'"
  >
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.VALUE' | i18nTranslate}}</span>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          (scheduleData.scheduleClicked === true &&
            scheduleData.showWhenValue < 0) ||
          data.showWhenValue === ''
      }"
    >
      <input
        [(ngModel)]="data.showWhenValue"
        (change)="sheduleDataChange.emit(data)"
        (input)="showWhenValueInput()"
        type="text"
        class="qb-input-box"
      />
      <span *ngIf="data.showWhen === 'AFTER_X_PERCENT'">%</span>
    </div>
  </div>
  <div class="qb-seperator-line"></div>

  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.DEVICE_VISIBILITY' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.deviceVisibility"
        (selectionChange)="sheduleDataChange.emit(data)"
      >
        <mat-option
          *ngFor="
            let device of scheduleConstants.configurations.schedule.devices
          "
          [value]="device"
          >{{ device }}</mat-option
        >
      </mat-select>
    </div>
  </div>

  <div class="qb-seperator-line"></div>

  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.SHOW_ON' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.showOn"
        (selectionChange)="sheduleDataChange.emit(data)"
      >
        <mat-option
          *ngFor="
            let showOn of scheduleConstants.configurations.schedule.showOn
          "
          [value]="showOn.value"
          >{{ showOn.label }}</mat-option
        >
      </mat-select>
    </div>
  </div>

  <div
    class="qb-parent-list qb-parent-list-block"
    *ngIf="data.showOn === 'OTHERS'"
  >
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.SELECT_URL' | i18nTranslate}}</span>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          scheduleData.scheduleClicked === true &&
          this.scheduleData.showOnSpecificUrl.length === 0
      }"
    >
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.showOnSpecificUrl"
        (selectionChange)="sheduleDataChange.emit(data)"
        multiple
      >
        <mat-option
          *ngFor="let url of scheduleConstants.configurations.routes"
          [value]="url.path"
          >{{ url.name }}</mat-option
        >
      </mat-select>
    </div>
  </div>

  <div
    class="qb-parent-list qb-parent-list-block"
    *ngIf="data.showOn === 'Specific URL' && data.showOnSpecificUrl == 'Other'"
  >
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.IF_OTHER_ENTER' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <input
        [(ngModel)]="data.selectOtherUrl"
        (change)="sheduleDataChange.emit(data)"
        type="text"
        class="qb-input-box"
      />
    </div>
  </div>

  <div class="qb-seperator-line"></div>

  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.LIMITS' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.limit"
        (selectionChange)="sheduleDataChange.emit(data)"
      >
        <mat-option
          *ngFor="let limit of scheduleConstants.configurations.schedule.limit"
          [value]="limit.value"
          >{{ limit.label }}</mat-option
        >
      </mat-select>
    </div>
  </div>
  <div
    class="qb-parent-list qb-parent-list-block"
    *ngIf="data.limit === 'REPEAT'"
  >
    <div class="qb-input-heading">
      <span>{{'ACQUIRE_PAGE.VALUE' | i18nTranslate}}</span>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          (scheduleData.scheduleClicked === true &&
            this.scheduleData.limitvalue < 0) ||
          data.limitvalue === ''
      }"
    >
      <input
        [(ngModel)]="data.limitvalue"
        (change)="sheduleDataChange.emit(data)"
        type="text"
        class="qb-input-box"
      />
    </div>
  </div>
</div>
