import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import {
  ConfirmationDialogBoxComponent,
  SendTestEmailDialogComponent,
} from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent implements OnInit {
  csvData: any = [];
  typeOfReports = [];
  typeOfStores = [];
  typeOfTiers = [];
  strategiesWithLoyaltyNode = [];
  selectedReport = "";
  selectedStore = [];
  selectedTiers = [];
  selectedCampaign = []
  dataNotAvailable = false;
  loading = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  validWidgets = null;
  isLoyaltyProgramCreated = true;
  checkForLoyalty = true;
  storeSelectionEnabled = [
    "TOTAL_POINTS_EARNED",
    "POS_ORDERS",
    "NON_POS_ORDERS",
    "TOTAL_LOYALTY_TRANSACTIONS",
  ];
  campaignSelectionEnabled = [
    "JOURNEY_REPORT"
  ];

  constructor(
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private date_format: getdateformatService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private tokenStorage: TokenStorageService,
    private _WidgetConfigService: WidgetConfigService
  ) {}

  ngOnInit(): void {
    // this.loadCSV();
    this.getAllFilter();
    this.getWidgets();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  changeReportTypeSelection(){
    this.csvData = [];
    this.selectedStore = [];
    this.selectedTiers = [];
  }
  getAllFilter() {
    this.apiCall.getReportFilters().subscribe(
      (response) => {
        console.log(response);
        this.typeOfReports = response["body"]["reports"];
        // this.selectedReport = response['body']['reports'][0].value;
        console.log(this.selectedReport);
        // this.getReportData();
        this.typeOfStores = response["body"]["stores"];
        this.typeOfTiers = response["body"]["tiers"];
        this.strategiesWithLoyaltyNode = response['body']['strategiesWithLoyaltyNode'];
        this.checkForLoyalty = false;
      },
      (err) => {
        this.isLoyaltyProgramCreated = false;
        this.checkForLoyalty = false;
      }
    );
  }

  searchReport() {
    this.dataNotAvailable = false;
    this.csvData = [];
    this.getReportData();
  }

  onEndDateChange(event) {
    // if(event.value){
    //   this.dataNotAvailable = false;
    //   this.csvData = [];
    //   this.getReportData();
    // }
  }

  getReportData() {
    if((this.campaignSelectionEnabled.indexOf(this.selectedReport) !== -1) && this.selectedCampaign.length === 0){
      this.snackBar.openSnackBar("Please Select All Manditory Fields", 5000);
      return;
    }
    this.loading = true;
    let data = {
      featureName: "LOYALTY",
      type: "CSV",
      filePath: "output/" + this.selectedReport + ".CSV",
      reportType: this.selectedReport,
      isPreview: true,
    };
    if (this.selectedTiers.length > 0) {
      data["tierIds"] = this.selectedTiers;
    }
    if (this.selectedStore.length > 0) {
      data["stores"] = this.selectedStore;
    }
    if (this.selectedCampaign.length > 0) {
      data["strategyIds"] = this.selectedCampaign;
    }
    if (this.range?.value?.start) {
      data["fromDate"] = this.datepipe.transform(
        this.range?.value?.start,
        "yyyy-MM-dd"
      );
    }
    if (this.range?.value?.end) {
      data["toDate"] = this.datepipe.transform(
        this.range?.value?.end,
        "yyyy-MM-dd"
      );
    }
    this.getReportsApi(data).then(
      (result) => {
        if (result["body"]) {
          this.csvData = result["body"];
          this.loading = false;
        } else {
          this.dataNotAvailable = true;
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  downloadData(filePath, fileType) {
    this.snackBar.openSnackBar(
      "Report download will start in a few minutes.",
      2000
    );
    let data = {
      featureName: "LOYALTY",
      type: fileType,
      filePath: filePath,
      reportType: this.selectedReport,
      isPreview: false,
    };
    if (this.selectedTiers.length > 0) {
      data["tierIds"] = this.selectedTiers;
    }
    if (this.selectedStore.length > 0) {
      data["stores"] = this.selectedStore;
    }
    if (this.selectedCampaign.length > 0) {
      data["strategyIds"] = this.selectedCampaign;
    }
    if (this.range?.value?.start) {
      data["fromDate"] = this.datepipe.transform(
        this.range?.value?.start,
        "yyyy-MM-dd"
      );
    }
    if (this.range?.value?.end) {
      data["toDate"] = this.datepipe.transform(
        this.range?.value?.end,
        "yyyy-MM-dd"
      );
    }
    this.getReportsApi(data).then(
      (result) => {
        window.open(result["body"]);
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  downloadLoyaltyReport() {
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: "Export Report",
        message: "Please select the format in which you want the report",
        segmentName: this.selectedReport,
        downloadButton_1: "Excel",
        downloadButton_2: "CSV",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let prefix = "";
        if (result.decision === "EXCEL") {
          prefix = ".xlsx";
        } else {
          prefix = ".csv";
        }
        let filePath = "output/" + result.fileName + prefix;
        //  let storeName = window.sessionStorage.getItem("storeName");
        this.downloadData(filePath, result.decision);
      }
    });
  }

  sendReportViaEmail() {
    if((this.campaignSelectionEnabled.indexOf(this.selectedReport) !== -1) && this.selectedCampaign.length === 0){
      this.snackBar.openSnackBar("Please Select All Manditory Fields", 5000);
      return;
    }
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      data: {
        subject: "Send report via email",
        message:
          "Please enter the email address to which the report should be sent.",
        reportEmail: true,
        downloadOption: ["Excel"],
        buttonNmae: "Send",
      },
      disableClose: true,
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
        let prefix = "";
        if (response["type"].toUpperCase() === "EXCEL") {
          prefix = ".xlsx";
        } else {
          prefix = ".csv";
        }
        let filePath = "output/" + this.selectedReport + prefix;
        this.sendReport(filePath, response.email, response["type"]);
      }
    });
  }

  sendReport(filePath, email, fileType) {
    // this.snackBar.openSnackBar(
    //   "Report download will start in a few minutes.",
    //   2000
    // );
    let data = {
      featureName: "LOYALTY",
      type: fileType.toUpperCase(),
      filePath: filePath,
      sendToEmailId: email,
      reportType: this.selectedReport,
      isPreview: false,
    };
    if (this.selectedTiers.length > 0) {
      data["tierIds"] = this.selectedTiers;
    }
    if (this.selectedStore.length > 0) {
      data["stores"] = this.selectedStore;
    }
    if (this.selectedCampaign.length > 0) {
      data["strategyIds"] = this.selectedCampaign;
    }
    if (this.range?.value?.start) {
      data["fromDate"] = this.datepipe.transform(
        this.range?.value?.start,
        "yyyy-MM-dd"
      );
    }
    if (this.range?.value?.end) {
      data["toDate"] = this.datepipe.transform(
        this.range?.value?.end,
        "yyyy-MM-dd"
      );
    }
    this.getReportsApi(data).then(
      (result) => {
        this.snackBar.openSnackBar(result["body"], 2000);
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  openSendTestEmailDialog() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
      }
    });
  }

  getReportsApi(data) {
    return new Promise((resolve, reject) => {
      this.apiCall.getReportSampleData(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }

  getMaxDate() {
    var currentDate = new Date();
    let startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    return startDateMinValue;
  }

  getDateTime(dates, type) {
    let time;
    let date = this.datepipe.transform(
      new Date(dates.replace(" ", "T")).toISOString(),
      "dd MMM yyyy"
    );
    let x = dates.split(" ");
    let y = x[1].split(":");
    if (x[1][0] + x[1][1] > 12)
      time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
    else time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
    if (type === "date") {
      return date;
    } else {
      return time;
    }
    // this.searchArray[item]["strategy"]["date"] = date;
    // this.searchArray[item]["strategy"]["time"] = time;
  }
}
