import { NgModule } from '@angular/core';
import { InsightsComponent } from './pages/insights/insights.component';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyzeRoutingModule } from './analyze-routing.module';
import { CampaignKpiComponent } from './components';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [
    InsightsComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AnalyzeRoutingModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ]
})
export class AnalyzeModule { }
