import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { assetPath } from 'src/app/configs/asset-path-config';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

interface DialogData {
  'type': string,
  'id': []
}

@Component({
  selector: 'app-campaign-template-view-dialog',
  templateUrl: './campaign-template-view-dialog.component.html',
  styleUrls: ['./campaign-template-view-dialog.component.css']
})
export class CampaignTemplateViewDialogComponent implements OnInit {

  assetPath = {
    arrowUrl: assetPath.path + "/Group 1393.svg",
  }
  messageTemplate;

  constructor(public dialogRef: MatDialogRef<CampaignTemplateViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private _i18nDynamicTranslate: TranslateDynamicText) { }

  selectedIndex = 0;

  ngOnInit() {
    this.viewMessageTemplateDetail(this.data['data']['campaignActions'][0]['campaignActionType'],
      this.data['data']['campaignActions'][0]['messageTemplateParams']['emailLayoutId'],
      this.data['data']['campaignActions'][0]['messageTemplateParams']['campaignMessageLayOutType'], this.selectedIndex)
  }

  convertMinutes(minute) {
    // if (!(minute % 43800)) {
    //   return (minute / 43800)
    // }
    if (minute === null) {
      return (0)
    }
    if (!(minute % 10080)) {
      return (minute / 10080)
    }
    else if (!(minute % 1440)) {
      return (minute / 1440)
    }
    else if (!(minute % 60)) {
      return (minute / 60)
    }
  }

  delayType(minute) {
    // if (!(minute % 43800)) {
    //   return ("Month(s)")
    // }
    if (minute === null) {
      return (null)
    }
    else if (!(minute % 10080)) {
      return ("Week(s)")
    }
    else if (!(minute % 1440)) {
      return ("Day(s)")
    }
    else if (!(minute % 60)) {
      return ("Hour(s)")
    }
  }

  viewMessageTemplateDetail(actionType, messageTemplateId, templateType, index) {
    this.selectedIndex = index;
    if (actionType === 'EMAIL_DELIVERY') {
      if (templateType === 'CUSTOM_LAYOUT') {
        this.apiCall.getMessageTemplate(messageTemplateId).subscribe((response) => {
          let messageTemplate = JSON.parse(response['body'])
          messageTemplate = JSON.parse(messageTemplate['templateJson'])
          this.messageTemplate = {
            'type': 'CUSTOM_LAYOUT',
            'subject': messageTemplate['subject'],
            'body': messageTemplate['body']
          }
        },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load resource", ['SHARED_PAGE']), 2000)
          })
      }
      if (templateType === 'STANDARD_LAYOUT') {
        this.apiCall.getEmailContent(messageTemplateId).subscribe((response) => {
          let messageTemplate = JSON.parse(response['body'])
          this.messageTemplate = {
            'type': 'STANDARD_TEMPLATE',
            'subject': messageTemplate['subject'],
            'body': messageTemplate['layoutHtml']
          }
        },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load resource", ['SHARED_PAGE']), 2000)
          })
      }
    }
  }

}
