import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.css']
})
export class MultiLineChartComponent implements OnInit {

  constructor() { this.getScreenSize() }

  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Input() data;

  scrHeight;
  scrWidth;
  csvData;
  colors = ["#ff5f31", "#880e4f", "#c150ff", "#4cb9ea", "#4bd762", "#07b6b6", "#f03e62", "#5aaefd"]

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // if(this.data && this.data.length > 0){
    //   this.drawChart();
    // }
  }


  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }

  ngAfterViewInit() {
    let interval1 = setInterval(() => {
      if (this.data && this.data.length > 0) {
        clearInterval(interval1)
        this.drawChart(this.data)
      }
    }, 100);
  }

  ngOnInit() {
  }

  // ngOnChanges() {
  //   if (this.data && this.data.length > 0) {
  //     this.drawChart(this.data)
  //   }
  // }

  drawChart(csv) {


    // Draw a line chart
    var svg = d3.select(this.chartContainer.nativeElement).selectAll("*").remove(),
      margin = { top: 20, right: 10, bottom: 20, left: 10 },
      width = this.chartContainer.nativeElement.offsetWidth - margin.left - margin.right,
      height = this.chartContainer.nativeElement.offsetHeight - margin.top - margin.bottom,
      svg = d3.select(this.chartContainer.nativeElement),
      g = svg.append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Graph title
    // g.append('text')
    //   .attr('x', (width / 2))
    //   .attr('y', 0 - (margin.top / 3))
    //   .attr('text-anchor', 'middle')
    //   .style('font-size', '16px')
    //   .text('Wallet chart');
    // Function to convert a string into a time
    // Function to show specific time format
    var formatTime = d3.timeFormat('%e %B');

    // Set the X scale
    var x = d3.scaleTime().range([0, width], 0.5);
    // Set the Y scale
    var y = d3.scaleLinear().range([height, 0]);
    // Set the color scale
    var color = d3.scaleOrdinal(this.colors);

    var xAxis = d3.axisBottom(x)
      .ticks(6)
      .tickFormat(d3.timeFormat("%b"));

    var yAxis = d3.axisLeft(y);

    var line = d3.line()
      // .interpolate("basis")
      .x(function (d) { return x(d.date); })
      .y(function (d) { return y(d.worth); })
      .curve(d3.curveMonotoneX);

    draw(csv)

    // load the data
    function draw(data) {


      // Select the important columns
      color.domain(d3.keys(data[0]).filter(function (key) {
        return key !== "Time" && key !== "_id";
      }));


      let parseTime = d3.timeParse('%Y-%m-%d %H:%M:%S');


      // Correct the types
      data.forEach(function (d) {
        d.date = parseTime(d.Time);
      });

      var currencies = color.domain().map(function (name) {
        return {
          name: name,
          values: data.map(function (d) {
            return {
              date: d.date,
              worth: +d[name]
            };
          })
        };
      });
      // Set the X domain
      x.domain(d3.extent(data, function (d) {
        return d.date;
      }));
      // Set the Y domain
      y.domain([
        d3.min(currencies, function (c) {
          return d3.min(c.values, function (v) {
            return v.worth;
          });
        }),
        d3.max(currencies, function (c) {
          return d3.max(c.values, function (v) {
            return v.worth;
          });
        })
      ]);
      // Set the X axis
      g.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
      // Set the Y axis
      // g.append("g")
      //   .attr("class", "y axis")
      //   .call(yAxis)
      // .append("text")
      // .attr("transform", "rotate(-90)")
      // .attr("y", 6)
      // .attr("dy", ".71em")
      // .style("text-anchor", "end")
      // .text("Value (USD)");

      // Draw the lines
      var currency = g.selectAll(".currency")
        .data(currencies)
        .enter().append("g")
        .attr("class", "currency");

      currency.append("path")
        .attr("class", "line")
        .style('fill', 'none')
        .style('stroke-width', '1px')
        .attr("d", function (d) {
          return line(d.values);
        })
        .style("stroke", function (d) {
          return color(d.name);
        });
      // Add the circles
      // currency.append("g").selectAll("circle")
      //   .data(function (d) { return d.values })
      //   .enter()
      //   .append("circle")
      //   .attr("r", "4px")
      //   .attr("cx", function (dd) { return x(dd.date) })
      //   .attr("cy", function (dd) { return y(dd.worth) })
      //   .attr("fill", function(d){return color(this.parentNode.__data__.name)})
      //   .attr("stroke", "#FFFFFF")
      //   .style('stroke-width', '2px');
      // Add label to the end of the line
      // currency.append("text")
      //   .attr("class", "label")
      //   .datum(function (d) {
      //     return {
      //       name: d.name,
      //       value: d.values[d.values.length - 1]
      //     };
      //   })
      //   .attr("transform", function (d) {
      //     return "translate(" + x(d.value.date) + "," + y(d.value.worth) + ")";
      //   })
      //   .attr("x", 3)
      //   .attr("dy", ".35em")
      //   .text(function (d) {
      //     return d.name;
      //   });

      // Add the mouse line
      var mouseG = g.append("g");
      //.attr("class", "mouse-over-effects");

      mouseG.append("path")
        .attr("class", "mouse-line")
        .style("stroke", "#c150ff6e")
        .style("stroke-width", "1px")
        .style("opacity", "0");

      var lines = document.getElementsByClassName('line');

      var mousePerLine = mouseG.selectAll('.mouse-per-line')
        .data(currencies)
        .enter()
        .append("g")
        .attr("class", "mouse-per-line");

      mousePerLine.append("circle")
        .attr("r", 4)
        .style("stroke", function (d) {
          return color(d.name);
        })
        .style("fill", function (d) {
          return color(d.name);
        })
        .style("stroke-width", "2px")
        .style("opacity", "0");

      mousePerLine.append("text")
        .attr("class", "hover-text")
        .attr("dy", "-1em")
        .attr("transform", "translate(10,3)");

      // Append a rect to catch mouse movements on canvas
      mouseG.append('svg:rect')
        .attr('width', width)
        .attr('height', height)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseout', function () { // on mouse out hide line, circles and text
          d3.select(".mouse-line")
            .style("opacity", "0");
          d3.selectAll(".mouse-per-line circle")
            .style("opacity", "0");
          d3.selectAll(".mouse-per-line text")
            .style("opacity", "0");
        })
        .on('mouseover', function () { // on mouse in show line, circles and text
          d3.select(".mouse-line")
            .style("opacity", "1");
          d3.selectAll(".mouse-per-line circle")
            .style("opacity", "1");
          d3.selectAll(".mouse-per-line text")
            .style("opacity", "1");
        })
        .on('mousemove', function () { // mouse moving over canvas
          var mouse = d3.mouse(this);

          d3.selectAll(".mouse-per-line")
            .attr("transform", function (d, i) {

              var xDate = x.invert(mouse[0]),
                bisect = d3.bisector(function (d) { return d.date; }).left;
              let idx = bisect(d.values, xDate);

              d3.select(this).select('text')
                .text(y.invert(y(d.values[idx].worth)).toFixed(2));

              d3.select(".mouse-line")
                .attr("d", function () {
                  var data = "M" + x(d.values[idx].date) + "," + height;
                  data += " " + x(d.values[idx].date) + "," + 0;
                  return data;
                });
              return "translate(" + x(d.values[idx].date) + "," + y(d.values[idx].worth) + ")";
            });
        });
    }
  }
}
