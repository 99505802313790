import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { AddToSuppressionListComponent } from "../add-to-suppression-list/add-to-suppression-list.component";
import { DeleteContactComponent } from "../delete-contact/delete-contact.component";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";


@Component({
  selector: "app-email-suppression-list",
  templateUrl: "./email-suppression-list.component.html",
  styleUrls: ["./email-suppression-list.component.css"],
})
export class EmailSuppressionListComponent implements OnInit {
  @ViewChild("search") searchElement: ElementRef;
  domainsArray = [];
  numberOfSuppressedContacts = 0;
  suppressedContactsArray = [];
  type = "All";
  requestBody;
  timeout;
  subscription: Subscription;
  searchvalue = "";
  length;
  pageSize = 10;
  pageIndex = 1;
  domain = "";
  contactsLoading = true;
  searching = false;
  noContact = false;
  defaultDomain = "";
  appSubscriptionPlan;

  constructor(
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private popup: PopUpDialogService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private snackbar:SnackbarCollection
  ) {
    window["screen-name"] = "SUPPRESSION LISTS";
  }

  ngOnInit() {
    this.getDomainNames();
    this.getEnvironment();
    this.getWidgets();
  }

  async getWidgets() {
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getDomainNames() {
    this.apiCall.getAllDomain().subscribe((response) => {
      this.domainsArray = JSON.parse(response["body"]).domainConfigs;
      const activeDomain = this.domainsArray.find(domainObj => domainObj.isActive);
      this.domain = activeDomain ? activeDomain.subDomainName : this.defaultDomain;
      this.getSuppressedContacts();
    });
  }

  change() {
    this.pageIndex = 1;
    if (this.searchvalue.length == 0) this.getSuppressedContacts();
    else this.searchSuppressedContacts(this.searchvalue);
  }

  getSuppressedContacts() {
    this.searching = false;
    this.contactsLoading = true;
    if (this.type !== "All")
      this.requestBody = {
        merchantId: this.tokenStorage.getMerchantId(),
        domainOrHeader: this.domain,
        platform: this.tokenStorage.getMerchantOnlineStore(),
        type: this.type,
        channel:'EMAIL'
      };
    else
      this.requestBody = {
        merchantId: this.tokenStorage.getMerchantId(),
        domainOrHeader: this.domain,
        platform: this.tokenStorage.getMerchantOnlineStore(),
        channel:'EMAIL'
      };
    this.apiCall
      .getSuppressedContacts(this.requestBody, this.pageSize, this.pageIndex)
      .subscribe((response) => {
        this.populateTable(JSON.parse(response["body"]));
        this.contactsLoading = false;
        if (
          this.suppressedContactsArray.length == 0 &&
          !this.contactsLoading &&
          !this.searching
        )
          this.noContact = true;
        else this.noContact = false;
      }, error => {
        this.contactsLoading = false;
        if (
          this.suppressedContactsArray.length == 0 &&
          !this.contactsLoading &&
          !this.searching
        )
          this.noContact = true;
        else this.noContact = false;
      });
  }

  showSearch() {
    this.searchElement.nativeElement.focus();
  }

  onKeySearch(event,value) {
    clearTimeout(this.timeout);
    var $this = this;
    let searchValue = value.trim();
      this.timeout = setTimeout(function () {
        if (event.data !== ' ')
          $this.searchSuppressedContacts(searchValue);
      }, 600);
    }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.searchSuppressedContacts(this.searchvalue);
  }

  getEnvironment() {
    if (environment.external === "dev" || environment.external === "qa")
      this.defaultDomain = "testing.qubriux.com";
    else this.defaultDomain = "mail.qubriux.com";
    // this.domain = this.defaultDomain;
  }

  searchSuppressedContacts(searchString) {
    this.searching = true;
    this.contactsLoading = true;
    this.searchvalue = searchString;
    if (this.type !== "All")
      this.requestBody = {
        merchantId: this.tokenStorage.getMerchantId(),
        domainOrHeader: this.domain,
        platform: this.tokenStorage.getMerchantOnlineStore(),
        type: this.type,
        channel:'EMAIL',
        "searchString": searchString
      };
    else
      this.requestBody = {
        merchantId: this.tokenStorage.getMerchantId(),
        domainOrHeader: this.domain,
        platform: this.tokenStorage.getMerchantOnlineStore(),
        channel:'EMAIL',
        "searchString": searchString
      };
    if (searchString.length > 0) {
      this.pageIndex = 1;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.apiCall
        .searchSuppressedContacts(
          this.requestBody,
          this.pageSize,
          this.pageIndex
        )
        .subscribe((response) => {
          this.populateTable(JSON.parse(response["body"]));
          this.contactsLoading = false;
          if (
            this.suppressedContactsArray.length == 0 &&
            !this.contactsLoading &&
            !this.searching
          )
            this.noContact = true;
          else this.noContact = false;
        });
    } else this.getSuppressedContacts();
  }

  populateTable(response) {
    this.suppressedContactsArray = [];
    for (let key in response) {
      this.length = key;
      let value = response[key];
      value.forEach((element) => {
        element.date = this.datePipe.transform(
          element.dateTime.split("T")[0],
          "dd MMM yyyy"
        );
        element.time =
          element.dateTime.split("T")[1].split(":")[0] +
          ":" +
          element.dateTime.split("T")[1].split(":")[1];
        this.suppressedContactsArray.push(element);
      });
    }
  }

  async openAddContactDialog() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(AddToSuppressionListComponent, {
        data: {
          domain: this.domain,
          channel:'EMAIL'
        },
        panelClass: "custom-dialog-container",
      });
      dialogRef.afterClosed().subscribe((email) => {
        if (email == undefined) {
          this.pageIndex = 1;
          this.getSuppressedContacts();
        }
      });
    }
  }

  openDeleteContactDialog(email, type,customerID) {
    let requestBody = {
      merchantId: this.tokenStorage.getMerchantId(),
      emailOrPhone: email,
      domainOrHeader: this.domain,
      type: type,
      "custId":customerID,
      "platform": this.tokenStorage.getMerchantOnlineStore(),
      channel:'EMAIL'
    };
    const dialogRef2 = this.dialog.open(DeleteContactComponent, {
      data: {
        dataKey: requestBody,
        channel:'EMAIL'
      },
      panelClass: "custom-dialog-container",
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if (result.length > 0) {
        this.apiCall
          .deleteEmailFromSuppressionList(requestBody)
          .subscribe((response) => {
            this.pageIndex = 1;
            this.snackbar.openSnackBar(JSON.parse(response['body']), 1500 )
            this.getSuppressedContacts();
          },
          (error)=>{
            this.snackbar.openSnackBar("Deletion failed", 1500 )
          });
      }
    });
  }
  dummyClick() {
    return 0;
  }
}
