import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ["./store-info.component.css"]
})
export class StoreInfoComponent implements AfterViewInit {
  tabs: Element[] = [];

  tabList=["Store Info","Store Access Keys and Tokens", "Data upload"].reverse();
  selectedTab: string = "Store Info";
  receivedData: { storeId: string; storeName: string ,action:string};
  parentData=null;

  oAuthPlatforms=["shopify","lightspeed"];
  selectTab(tab: string) {
    this.selectedTab = tab;
  }


  ngAfterViewInit() {
    this.tabs = Array.from(document.querySelectorAll('.tabs > li'));
    console.log(this.selectedTab)
  } 


  isTabActive(tab: string): boolean {
    console.log(this.selectedTab);
    return this.selectedTab === tab;
  }
  receiveDataFromChild(data: { storeId: string; storeName: string,action:string }) {
    this.receivedData = data;
    this.parentData=data;
    if(data.action=="ACCESS_KEYS"){
      this.selectedTab="Store Access Keys and Tokens";
    }
    console.log(data);
  }
}
