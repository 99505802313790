import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardPlan } from 'src/app/core/services/current-plan-auth.service';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { CreateSegmentsComponent, EditSegmentsComponent, NewCreateSegmentsComponent } from './pages';

const routes: Routes = [
    {
        path: "",
        component: EditSegmentsComponent,
        data: {
          titleheading: "Segments",
          title: "Segments",
        },
    },
    {
        path: "create-segment",
        component: NewCreateSegmentsComponent,
        canActivate: [AuthGuardPlan, WidgetRouteGaurdService],
        data: {
          titleheading: "Segments",
          title: "Create Segment",
          minPath: "Advanced",
          id: 4,
        },
    }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegmentRoutingModule { }
