<div id="viewconfigDiv">
  <div>
    <div class="qb-d-flex qb-buttons-heading">
      <div class="cursor-pointer">
        <div class="material-icons-outlined icons-primary qb-delete-button" [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" (click)="rerouteToMyOffers()">delete</div>
      </div>
      <div class="primary-button" (click)="checkValidityDates()">
        {{'PARTNERSHIP_PAGE.SAVE_SELECT_CHANNEL' | i18nTranslate}}
      </div>
    </div>
  </div>
  <div class="qb-body">
      <div class="qb-main-div-1">
        <div>Offer Details</div>
        <div class="qb-offer-div">
          <div class="qb-input-label">{{'PARTNERSHIP_PAGE.OFFER_NAME' | i18nTranslate}}</div>
          <div class="qb-input-div">
            <input
              type="text"
              [(ngModel)]="partnershipOfferName"
            />
          </div>
          <div
            class="qb-error"
            *ngIf="
              partnershipOfferName.split(' ').join('').length < 3 &&
              this.submitClicked
            "
          >
            {{'PARTNERSHIP_PAGE.MIN_CHARACTER_3' | i18nTranslate}}
          </div>
          <div
            class="qb-error"
            *ngIf="
              partnershipOfferName.split(' ').join('').length > 25 &&
              this.submitClicked
            "
          >
            {{'PARTNERSHIP_PAGE.MAX_CHARACTER_25' | i18nTranslate}}
          </div>
          <div
            class="qb-error"
            *ngIf="partnershipOfferName.split(' ').join('').length != 0"
          >
            {{ offerNameError | i18nDynamicTranslate : 'POS_PAGE'}}
          </div>
        </div>
        <div class="qb-discount-label-div">
          <div *ngIf="isPos==false">
            <div>{{'PARTNERSHIP_PAGE.SELECT_OFFERS' | i18nTranslate}}</div>
            <div class="qb-input-label" style="width: 420px; margin-top: 10px;">{{'PARTNERSHIP_PAGE.SELECT_OFFER_DESCRIPTION' | i18nTranslate}}</div>
          </div>  
  
            <!-- <div class="qb-select-offers">
              Select Offers (You can select a max of 3 offers)
            </div> -->
            <div>
              <div *ngFor="let offer of offers" style="margin: 5px 0px;">
                <mat-checkbox
                  *ngIf="offer['isActive'] !== false"
                  [checked]="s.indexOf(offer['offerId']) != -1"
                  [disabled]="
                    (s.length == 3 && s.indexOf(offer['offerId']) == -1) ||
                    offer['isActive']
                  "
                  (click)="!isPos ? check($event) : ''"
                  (change)="!isPos ?
                    addOffer(
                      offer['offerId'],
                      offer['description'],
                      offer['partnershipRequests'],
                      offer['storeName']
                    ) : 
                    addPOSOffer(
                      offer['offerId'],
                      offer['description'],
                      offer['partnershipRequests'],
                      offer['storeName']
                    )
                  "
                  >{{ offer["description"] }}</mat-checkbox
                >
              </div>
            </div>
          </div>
            <div>
              {{'PARTNERSHIP_PAGE.MIN_REQ' | i18nTranslate}}
            </div>
            <div class="qb-radio-divs">
              <div>
                <mat-radio-button
                  [checked]="barrierJSON.type === 'AMOUNT'"
                  (change)="barrierJSON.type = 'AMOUNT'"
                  >{{'PARTNERSHIP_PAGE.MIN_PURCHASE_AMT' | i18nTranslate}}</mat-radio-button
                >
                <div *ngIf="barrierJSON.type === 'AMOUNT'">
                  <div class="qb-offers-input">
                    <div class="qb-dollar">{{ currencySymbol }}</div>
                    <input
                      type="number"
                      [(ngModel)]="barrierJSON.value"
                      placeholder="100"
                    />
                  </div>
                </div>
              </div>
              <div>
                <mat-radio-button
                  [checked]="barrierJSON.type === 'QUANTITY'"
                  (change)="barrierJSON.type = 'QUANTITY'"
                  >{{'PARTNERSHIP_PAGE.MIN_QUANTITY_ITEMS' | i18nTranslate}}</mat-radio-button
                >
                <div *ngIf="barrierJSON.type === 'QUANTITY'">
                  <div class="qb-offers-input">
                    <input
                      type="number"
                      [(ngModel)]="barrierJSON.value"
                      placeholder="2"
                    />
                  </div>
                </div>
                <div class="qb-applies-to" *ngIf="barrierJSON.type === 'QUANTITY'">
                  {{'PARTNERSHIP_PAGE.APPLIES_TO' | i18nTranslate}}
                </div>
                <div *ngIf="barrierJSON.type === 'QUANTITY'">
                  <div
                    class="qb-offers-input qb-dropdown-input"
                    (click)="showDropdown = !showDropdown; $event.stopPropagation()"
                  >
                    <div *ngIf="ids.length == 0">{{'PARTNERSHIP_PAGE.SELECT_CATEGORY' | i18nTranslate}}</div>
                    <div *ngIf="ids.length == 1">
                      {{ selectedProductCollections[0].collectionName }}
                    </div>
                    <div *ngIf="ids.length > 1">
                      {{'PARTNERSHIP_PAGE.CATEGORIES_SELECTED' | i18nTranslate}} - {{ ids.length }}
                    </div>
                    <div *ngIf="!showDropdown" class="material-icons-outlined">
                      keyboard_arrow_down
                    </div>
                    <div *ngIf="showDropdown" class="material-icons-outlined">
                      expand_less
                    </div>
                  </div>
                  <div *ngIf="showDropdown" class="qb-dropdown-div" #myDiv>
                    <div>
                      <mat-checkbox
                        (change)="selectCollection(-1, All, $event)"
                        [checked]="
                          selectedProductCollections.collectionName === 'ALL_CATEGORIES'
                        "
                        >{{'PARTNERSHIP_PAGE.ALL_CATEGORIES' | i18nTranslate}}</mat-checkbox
                      >
                    </div>
                    <div *ngFor="let collection of productCollections">
                      <div class="qb-dropdown-option">
                        <mat-checkbox
                          [checked]="
                            (ids.indexOf(collection['productCategoryId']) != -1)
                          "
                          (change)="
                            selectCollection(
                              collection['productCategoryId'],
                              collection['productCategoryName'],
                              $event
                            )
                          "
                          >{{ collection["productCategoryName"] }}</mat-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        <!-- <div class="qb-buttons-div">
          <div
            class="secondary-button-border qb-cancel-button"
            (click)="rerouteToMyOffers()"
          >
            Cancel
          </div>
          <div class="primary-button" (click)="checkValidityDates()">
            Save and Continue
          </div>
        </div> -->
      </div>
      <div class="qb-main-div-2">
        <div>{{'PARTNERSHIP_PAGE.SUMMARY' | i18nTranslate}}</div>
        <div class="qb-coupon">{{ partnershipOfferName }}</div>
        <div>
          <ul>
            <li class="qb-list-item" *ngIf="partnershipOfferName !== '' && allOfferMaps.length > 0">
              {{partnershipOfferName}} {{'PARTNERSHIP_PAGE.SELECTED' | i18nTranslate}}
             </li>
             <ul *ngFor="let store of allOfferMaps">
              <li *ngIf="partnershipOfferName !== '' && allOfferMaps.length > 0">{{store['storeName']}}</li>
             </ul>
            <li class="qb-list-item" *ngIf="barrierJSON.type === 'AMOUNT' && barrierJSON.value > 0">
              {{'PARTNERSHIP_PAGE.MIN_PURCHASE_OF' | i18nTranslate}} {{ currencySymbol }}{{ barrierJSON.value }}
            </li>
            <li class="qb-list-item" *ngIf="barrierJSON.type === 'QUANTITY' && barrierJSON.value > 0">
              {{'PARTNERSHIP_PAGE.MIN_PURCHASE_OF' | i18nTranslate}} {{ barrierJSON.value }}
            </li>
          </ul>
        </div>
      </div>
    </div>
</div>
  <div id="overlay"></div>
  <div style="  position: absolute;
          top: 0;
          right: 0;
          height: 100vh;
          width: 45%;
          z-index: -1;font-size: 13px;" id="configDiv">


  <app-partnership-channel-select style="width: 100%;height: 100%;" (messageEvent)="receiveMessage($event)"
    [offer_list]="allOfferMaps" [offer_name]="partnershipOfferName"
    [barrierId]="barrier_id" *ngIf='openScheduleOverlay'>
  </app-partnership-channel-select>
</div>
  