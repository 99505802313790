<div class="main" data-widget-name="CUSTOMER INSIGHTS">
    <div class="your-customers-div">
        <div style="display: flex;">
        <div class="your-customers">{{'SHARED_PAGE.CUSTOMER_INSIGHT' | i18nTranslate}}</div>
        <div class="i-div" style="    margin: 0 2px 0 6px;
        " type="button" [ngbPopover]="popContent" triggers="mouseenter:mouseleave">
          <ng-template #popContent>
            <div style="border-radius: 4px; margin: -9px -12px; padding: 10px;
            font-size: 12px;">
              {{'SHARED_PAGE.NUMBER_CUSTOMER' | i18nTranslate}}
            </div>
          </ng-template>
          <div class="i">i</div>
        </div>
        </div>
        <div style="display: flex; background-color: white;border: 1px solid #EDF0F2;
            border-radius: 2px; padding-left: 4px; margin-top: 0px;justify-content: space-between;
    width: 15%;
    align-items: center;" (click)="openSelect()">
              <div style="height: 108%;">
                <span class="material-icons-outlined icons-primary" style="margin-top: 4px; cursor: pointer;">
                    calendar_today
                    </span>            </div>
            <div class="select-div-2" >
            <mat-select #select class="graph-select" (selectionChange)="changeGraph()" [(ngModel)]="selectedTime" [value]="selectedTime">
                <mat-option data-widget-name="CUSTOMER INSIGHTS" data-button-name="All Time" value="All Time">{{'SHARED_PAGE.ALL_TIME' | i18nTranslate}}</mat-option>
                <mat-option data-widget-name="CUSTOMER INSIGHTS" data-button-name="This Week" value="This week">{{'SHARED_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
            </mat-select>
        </div>
          </div>

    </div>
    <div class="graph-section">
        <div class="graph-header">
            <div>
                <div class="customer-count">{{'SHARED_PAGE.ALL_CUSTOMERS' | i18nTranslate}}</div>
                <div class="customer-count-value-div">
                    <div class="customer-count-value">{{totalCustomerCount}}</div>
                </div>
            </div>
            <!-- <div style="height: 0px;" *ngIf="store === 'shopify'">
                <button class="graph-buttons" name="all customers" (click)="getGraphStats($event, 'all customers')"
                    [ngClass]="{active: selectedSegment === 'all customers'}" data-button-name="All Customers">All Customers</button>
                <button class="graph-buttons" name="Active" (click)="getGraphStats($event, 'Active')"
                    [ngClass]="{active: selectedSegment === 'Active'}" data-button-name="Active">Active</button>
                <button class="graph-buttons" name="At Risk" (click)="getGraphStats($event, 'At Risk')"
                    [ngClass]="{active: selectedSegment === 'At Risk'}" data-button-name="At Risk">At Risk</button>
                <button class="graph-buttons" name="Big Spenders" (click)="getGraphStats($event, 'Big Spenders')"
                    [ngClass]="{active: selectedSegment === 'Big Spenders'}" data-button-name="Big Spenders">Big Spenders</button>
                <button class="graph-buttons" name="Lost but high value"
                    (click)="getGraphStats($event, 'Lost but high value')" matTooltip="Lost but high value"
                    [ngClass]="{active: selectedSegment === 'Lost but high value'}" data-button-name="Lost - HV">Lost - HV</button>
                <button class="graph-buttons" name="Lost but low value"
                    (click)="getGraphStats($event, 'Lost but low value')" matTooltip="Lost but low value"
                    [ngClass]="{active: selectedSegment === 'Lost but low value'}" data-button-name="Lost - LV">Lost - LV</button>
                <button class="graph-buttons" name="Loyal" (click)="getGraphStats($event, 'Loyal')"
                    [ngClass]="{active: selectedSegment === 'Loyal'}" data-button-name="Loyal">Loyal</button>
                <button class="graph-buttons" name="New" (click)="getGraphStats($event, 'New')"
                    [ngClass]="{active: selectedSegment === 'New'}" data-button-name="New">New</button>
            </div> -->
        </div>
        <div class="graph-customers">
            <div echarts [options]="customerCountChartOptions" class="line-chart-height"></div>
        </div>
</div>