import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { StoreInfo } from '../../shared/models/store-details.model';
import { StoreMetaInfo } from '../../shared/models/store-details.model';

import { ApiCallService } from './api-call-service';

@Injectable()
export class StoreService {
  constructor(private http: HttpClient,private _apicall:ApiCallService) {}

  getStoreData(storeQId: string): Observable<StoreInfo> {
    console.log(storeQId);
    return this._apicall.getBasicStoreData(storeQId).pipe(
      map((response: any) => {
        if (response && response.body) {
          const storeInfo = new StoreInfo();
          storeInfo.storeMetaInfo = new StoreMetaInfo();
          storeInfo.phoneNumber = response.body.phoneNumber;
          storeInfo.email = response.body.email;
          storeInfo.timezone = response.body.timezone;
          storeInfo.googleReviewLink = response.body.googleReviewLink;
          storeInfo.placeId = response.body.placeId;
          // Populate storeMetaInfo properties
          Object.assign(storeInfo.storeMetaInfo, response.body.storeMetaInfo);
          return storeInfo;
        } else {
          throw new Error('Invalid API response');
        }
      })
    );
  }
}
