<div class="qb-body">
  <div>
    <div class="qb-header-div">
      <div class="qb-all-total-div">
        <div class="qb-total-div">
          <div class="qb-total-text">
            <div class="qb-main-div">
              <div class="qb-total revenue">{{'PARTNERSHIP_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
              <div class="qb-total">
                <div
                  class="qb-i-div"
                  type="button"
                  [ngbPopover]="popContent"
                  triggers="mouseenter:mouseleave"
                >
                  <ng-template #popContent>
                    <div class="qb-i-text">
                      {{'PARTNERSHIP_PAGE.REVENUE_GENERATED_INFO' | i18nTranslate}}
                    </div>
                  </ng-template>
                  <div class="qb-i">i</div>
                </div>
              </div>
            </div>
            <div class="qb-image-div">
              <div class="qb-image">
                <img
                  class="qb-images"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/money.png"
                />
              </div>
            </div>
          </div>
          <div class="qb-spinner" *ngIf="statsLoading">
            <mat-spinner diameter="15"></mat-spinner>
          </div>
          <div class="qb-total-value" *ngIf="!statsLoading">
            {{ currencySymbol }}{{ totalRevenue }}
          </div>
        </div>
        <div class="qb-total-div">
          <div class="qb-total-text qb-width-div">
            <div class="qb-total qb-sold">{{'PARTNERSHIP_PAGE.PRODUCTS_SOLD' | i18nTranslate}}</div>
            <div class="qb-total">
              <div
                class="qb-i-div"
                type="button"
                [ngbPopover]="popContent1"
                triggers="mouseenter:mouseleave"
              >
                <ng-template #popContent1>
                  <div class="qb-i-text">
                    {{'PARTNERSHIP_PAGE.PRODUCTS_SOLD_INFO' | i18nTranslate}}
                  </div>
                </ng-template>
                <div class="qb-i">i</div>
              </div>
            </div>
            <div class="qb-image-div qb-image-div2">
              <div class="qb-image">
                <img
                  class="qb-images"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/cart.png"
                />
              </div>
            </div>
          </div>
          <div class="qb-spinner" *ngIf="statsLoading">
            <mat-spinner diameter="15"></mat-spinner>
          </div>
          <div class="qb-total-value" *ngIf="!statsLoading">
            {{ totalProductSold }}
          </div>
        </div>
      </div>
      <div class="qb-header-buttons">
        <div
          class="primary-button-border qb-invite-button"
          (click)="invitePartner()"
        >
          {{'PARTNERSHIP_PAGE.INVITE_PARTNER' | i18nTranslate}}
        </div>
        <div class="primary-button qb-add-button" (click)="addNewPartner()">
          <div class="material-icons-outlined">add</div>
          <div>{{'PARTNERSHIP_PAGE.ADD_NEW_PARTNER' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-table-div">
    <div class="qb-table-header">
      <div class="qb-partners-list-div">
        <div>{{'PARTNERSHIP_PAGE.PARTNERS_LIST' | i18nTranslate}}</div>
      </div>
      <div class="qb-search-div">
        <input
          type="text"
          placeholder="Search by Partner Name"
          [(ngModel)]="searchString"
          (input)="onKeySearch($event)"
        />
        <div class="material-icons-outlined" *ngIf="searchString.length == 0">
          search
        </div>
        <span
          class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
          *ngIf="searchString.length > 0"
          (click)="searchString = ''; onKeySearch(searchString)"
        >
          close
        </span>
      </div>
    </div>
    <div>
      <div class="qb-table-head">
        <div class="qb-table-head-section" *ngFor="let heading of tableHeadings">
        <div
          *ngIf="heading !== 'Revenue Generated' && heading !== 'Partner Type' && heading != 'Revenue Cut Given'"
        >
          {{ heading | i18nDynamicTranslate : 'POS_PAGE' }}
        </div>
        <div
          class="qb-revenue-section"
          *ngIf="heading === 'Revenue Generated'"
        >
          <div>{{ heading | i18nDynamicTranslate : 'POS_PAGE' }}</div>
          <!-- <div class="qb-dropdown-div">
          <mat-select [(value)]="qb-revenueRange">
            <mat-option value="allTime">All Time</mat-option>
            <mat-option value="30days">30 Days</mat-option>
          </mat-select>
        </div>
        <div class="qb-arrow-div">
          <div class="material-icons-outlined">arrow_drop_up</div>
          <div class="material-icons-outlined qb-arrow-down">arrow_drop_down</div>
        </div> -->
        </div>
        <div
        class="qb-revenue-section"
        *ngIf="heading === 'Revenue Cut Given'"
      >
        <div>{{ heading | i18nDynamicTranslate : 'POS_PAGE' }}</div>
        <!-- <div class="qb-dropdown-div">
        <mat-select [(value)]="qb-revenueRange">
          <mat-option value="allTime">All Time</mat-option>
          <mat-option value="30days">30 Days</mat-option>
        </mat-select>
      </div>
      <div class="qb-arrow-div">
        <div class="material-icons-outlined">arrow_drop_up</div>
        <div class="material-icons-outlined qb-arrow-down">arrow_drop_down</div>
      </div> -->
      </div>
        <div
          *ngIf="heading === 'Partner Type'"
        >
          {{ heading | i18nDynamicTranslate : 'POS_PAGE' }}
        </div>
        </div>
      </div>
    </div>
    <div *ngIf="tableLoading" class="qb-spinner-div">
      <mat-spinner diameter="15"></mat-spinner>
    </div>
    <div
      *ngIf="this.partnerList.length == 0 && !tableLoading"
      class="qb-no-data"
    >
      {{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
    </div>
    <div *ngIf="!tableLoading && this.partnerList.length > 0" class="qb-list">
      <div class="qb-table-content" *ngFor="let list of partnerList">
        <div class="qb-table-data-div">
          <div class="qb-table-data qb-partner-name">
            <div
              (click)="
                action(
                  list['requestId'],
                  'ACTIVE',
                  list['source'],
                  list['shopUrl'],
                  list['shopName']
                )
              "
            >
              <u> {{ list["shopName"] }}</u>
            </div>
          </div>
          <div class="qb-table-data">
            {{ list["partnerSince"] | toDateObj | date: getDateFormat("dd MMM yyyy") }} &nbsp;
            {{ list["partnerSince"] | toDateObj | customTime }}
          </div>
          <div
            class="qb-table-data qb-revenue-data"
            *ngIf="list['revenueGenerated'] !== null"
          >
            {{ currencySymbol }}{{ list["revenueGenerated"] | number: "1.2-2" }}
          </div>
          <div
            class="qb-table-data"
            *ngIf="list['revenueGenerated'] === null"
          >
            {{ currencySymbol }}{{ 0 | number: "1.2-2" }}
          </div>
          <div
            class="qb-table-data qb-revenue-data"
            *ngIf="list['percentageCutGiven'] !== null"
          >
            {{ currencySymbol }}{{ list["percentageCutGiven"] | number: "1.2-2" }}
          </div>
          <div
            class="qb-table-data"
            *ngIf="list['percentageCutGiven'] === null"
          >
            {{'PARTNERSHIP_PAGE.NOT_APPLICABLE' | i18nTranslate}}
          </div>
          <div
            class="qb-table-data"
            *ngIf="!list['source']"
          >
            {{'PARTNERSHIP_PAGE.AFFILIATE' | i18nTranslate}}
          </div>
          <div
            class="qb-table-data qb-partner-type-data"
            *ngIf="list['source']"
          >
            {{'PARTNERSHIP_PAGE.SELLER' | i18nTranslate}}
          </div>
          <div class="qb-table-data qb-action-data">
            <div
              class="qb-send-offer"
              *ngIf="list['status'] === 'SEND_OFFER'"
              (click)="getOffersList(list['requestId'], list['shopName'])"
            >
              {{'PARTNERSHIP_PAGE.SEND_OFFER' | i18nTranslate}}
            </div>
            <div
              class="qb-send-offer"
              *ngIf="
                list['status'] === 'OFFER_SENT' ||
                list['status'] === 'VIEW_OFFER'
              "
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName'],
                  list['comId']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.VIEW_OFFER' | i18nTranslate}}
            </div>
            <div
              class="qb-offer-not-shared"
              *ngIf="list['status'] === 'OFFER_DISABLED'"
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName'],
                  list['comId']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.OFFER_DISABLED' | i18nTranslate}}
            </div>
            <div
              class="qb-offer-not-shared"
              *ngIf="list['status'] === 'FORM_DISABLED'"
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.FORM_DISABLED' | i18nTranslate}}
            </div>
            <div
              class="qb-offer-not-shared"
              *ngIf="list['status'] === 'FORM_ENDED'"
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.FORM_ENDED' | i18nTranslate}}
            </div>
            <div
              class="qb-offer-not-shared"
              *ngIf="list['status'] === 'OFFER_NOT_SHARED'"
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.OFFER_NOT_SHARED' | i18nTranslate}}
            </div>
            <div
              class="qb-active-offer"
              *ngIf="list['status'] === 'ACTIVE'"
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.ACTIVE' | i18nTranslate}}
            </div>
            <div
              class="qb-expired-offer"
              *ngIf="list['status'] === 'EXPIRED'"
              (click)="
                action(
                  list['requestId'],
                  list['status'],
                  list['source'],
                  list['shopUrl'],
                  list['shopName']
                )
              "
            >
              {{'PARTNERSHIP_PAGE.EXPIRED' | i18nTranslate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qb-paginate-div">
      <mat-paginator
        [pageIndex]="pageNumber - 1"
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="refreshPaginatedData($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
