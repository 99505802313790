<!-- <div>
    <span>
    <img [src]="s3BucketUrl+'/greyfox-assets/assets/shop.png'" alt="">
  </span>
    <div class="formDiv">
        <input class="formInput" style="text-align:center;" type="text" placeholder="shop name" [(ngModel)]="shopName">
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 30px;">
        <span class="submitButton" (click)="submitDialog()">Submit</span>
        <span class="cancelButton" (click)="closeDialog()">Cancel</span>
    </div>
</div> -->





<div style="margin-top:4%; display: flex; justify-content: center; align-items: center;">
    <div class="shop-container">
        <div>
            <img [src]="s3BucketUrl+'/greyfox-assets/assets/shop.png'" class="shopify-img" style="width:100%;">
        </div>
        <div class="form-container">
            <div style="display: flex; justify-content: center;">
                <span style="font-size: 15px; font-weight: 600;">{{'SHARED_PAGE.SHOP_URL' | i18nTranslate}}</span>
            </div>
            <div style="margin-top: 5px;" class="formDiv" [ngClass]="{'active' : ((formSubmit || shopName.touched) && (shopName.value === null || shopName.value === '')) || (shopName.touched && shopName.errors?.pattern)}" *ngIf="!authenticated">
                <input class="formInput" type="text" placeholder="shopname.myshopify.com" [formControl]="shopName" pattern="([A-z]|[0-9]|-|\.)+myshopify\.com">
            </div>
            <div class="invalid-shop-div">
                <span *ngIf="(formSubmit || shopName.touched) && (shopName.value === null || shopName.value === '')">{{'SHARED_PAGE.SHOP_URL_CANNOT_EMPTY' | i18nTranslate}}</span>
                <span *ngIf="shopName.touched && shopName.errors?.pattern">{{'SHARED_PAGE.SHOP_URL_NOT_VALID' | i18nTranslate}}</span>
            </div>
            <div style="display: flex;justify-content: space-between;">

                <div class="shop-name-cancel" (click)="closeDialog()">
                    <span class="fontExo2">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
                </div>

                <div class="shop-name-submit" (click)="getAuthenticated()" [ngClass]="{active:authInProgress}">
                    <span class="fontExo2">{{'SHARED_PAGE.SUBMIT' | i18nTranslate}}</span>
                </div>

                <!-- <div class="shop-name-submit-disabled" *ngIf="authenticated">
                    <span>Submit</span>
                </div> -->
            </div>
        </div>
    </div>
</div>