import { HttpClient } from '@angular/common/http'
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable()
export class PygApiCallService {

    private apiUrl = window.location.protocol + '//' + environment.pygApiUrl;
    constructor(private http: HttpClient,
        private tokenStorage: TokenStorageService) { }

    startGame(userID) {
        let url = this.apiUrl + "/pyg/startgame/"+userID;

        return this.http.get(url)
    }
    playGame(data,userID) {

        let url = this.apiUrl + '/pyg/playgame/'+userID;
        return this.http.post(url, data);
    }
    removeQtables() {
        let url = this.apiUrl + '/pyg/removeQtable';
        return this.http.get(url);
    }

    restartGame(userID) {
        let url = this.apiUrl + '/pyg/restartgame/'+userID;
        return this.http.get(url);
    }
    endGame(userID) {
        let url = this.apiUrl + '/pyg/endgame/'+userID;
        return this.http.get(url);
    }
    exitGame(userID) {
        let url = this.apiUrl + '/pyg/exitgame/'+userID;
        return this.http.get(url);
    }
    endGameStats(userID) {
        let url = this.apiUrl + '/pyg/endgamestats/'+userID;
        return this.http.get(url);
    }

    setParams(data) {
        let url = this.apiUrl + '/pyg/setparams/CC2437F9-1BC2-4AC0-A32F-C617D4377AA5';
        return this.http.post(url, data);

    }

    gameinfo() {
        let url = this.apiUrl + '/pyg/gameinfo/config/';
        return this.http.get(url);
    }
    getPropensity(userID){
        let url =this.apiUrl +'/pyg/userfreq/'+userID;
        return this.http.get(url);
    }
}
