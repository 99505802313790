import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-comarketing-offer',
  templateUrl: './preview-comarketing-offer.component.html',
  styleUrls: ['./preview-comarketing-offer.component.css']
})
export class PreviewComarketingOfferComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PreviewComarketingOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  closedialog(){
    this.dialogRef.close();
  }

}
