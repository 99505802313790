import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { TokenStorageService } from "../auth/token-storage.service";
import { ApiCallService } from "./api-call-service";

@Injectable()
export class currentPlanDataService {
  currentPlanBody: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _apiCallService: ApiCallService,
    private _router: Router,
    private _tokenStorage: TokenStorageService
  ) {}

  /**
   * Use to change current plan
   * @data type: string
   */

  setCurrentPlanBody(data: string) {
    this.currentPlanBody.next(data);
    console.log("data:",data)
  }
  clearData() {
    this.currentPlanBody.next(null);
  }

  getCurrentPlanDetalas() {
    if(this._tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this._apiCallService.getCurrentPlanData().subscribe(
        (response) => {
          let c = response["body"];
  
          if (c == null || c == undefined) {
            this._router.navigate(["/payment-plan"]);
          } else {
            this.setCurrentPlanBody(c);
          }
        },
        (error) => {
          console.log(error);
          this._router.navigate(["/payment-plan"]);
        }
      );
    }
    else{
      this._apiCallService.getCurrentPlanData().subscribe(
        (response) => {
          let c = response["body"];
  
          if (c == null || c == undefined) {
            this._router.navigate(["/payment-plan-pos"]);
          } else {
            this.setCurrentPlanBody(c);
          }
        },
        (error) => {
          console.log(error);
          this._router.navigate(["/payment-plan-pos"]);
        }
      );
    }
    return 1;
  }
}
