import { Pipe, PipeTransform } from "@angular/core";
import { TextTranslationService } from "src/app/core/services/translate-text.service";

@Pipe({
    name: 'i18nTranslate',
    pure: false
})
export class TranslateText implements PipeTransform{
    constructor(private translateService : TextTranslationService){}


    transform(value: any, ...dynamicTexts:any[]) {
        let textPosition = value.split(".");
        return this.translateService.getTranslatedText(textPosition, dynamicTexts);
    }
}