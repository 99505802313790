<div
  style="
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
  "
>
  <div class="surveyNameText">
    <div>Subject: {{ subject }}</div>
  </div>
  <div style="cursor: pointer" *ngIf="data.isEditAllowed!=false">
    <mat-icon
      style="font-family: 'Material Icons'; margin-right: 25px"
      (click)="submit('edit')"
      >edit</mat-icon
    >
    <mat-icon style="font-family: 'Material Icons'" (click)="submit(false)"
      >close</mat-icon
    >
  </div>
</div>

<div class="seperator1"></div>

<div *ngIf="!loading" style="pointer-events: none; padding: 15px">
  <app-email-template-preview
    [selectedLayout]="htmlrend"
  ></app-email-template-preview>
</div>
