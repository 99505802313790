import { Injectable } from "@angular/core";
import { ConfirmationDialogBoxComponent } from "../../shared/components/dialog-box/confirmation-dialog-box/confirmation-dialog-box.component";
import { MatDialog } from "@angular/material/dialog"; 
import { Router } from "@angular/router";
import { WidgetConfigService } from "./widget-config.service";
import { ApiCallService } from "./api-call-service";
import { InactivePlanDialogComponent } from "../../shared/components/dialog-box/inactive-plan-dialog/inactive-plan-dialog.component";
import { ContactUsDialogComponent } from "../../shared/components/dialog-box/contact-us-dialog/contact-us-dialog.component";
import { TokenStorageService } from "../auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Injectable({
  providedIn: "root",
})
export class SubscriptionPlanHandlerService {
  //appSubscriptionPlan;

  constructor(
    private _dialog: MatDialog,
    public _router: Router,
    private _WidgetConfigService: WidgetConfigService,
    private _ApiCallService: ApiCallService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  public async IsValidOperation(subscription: number) {
    if (subscription) {
      return true;
    } else {
      if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
        this._ApiCallService.getCurrentPlan().subscribe(
          (response) => {
            let subscriptionData = response["body"];
            if (subscriptionData["isEmailVerified"] === false) {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Email Verification Pending",
                    message:
                     this._i18nDynamicTranslate.transform( "Check your inbox and verify your email address to access full features on Qubriux.", ['POS_PAGE']),
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            } else if (
              subscriptionData["isCustomerThresholdReached"] === true &&
              subscriptionData["planName"] === "Basic"
            ) {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Error",
                    message:
                       this._i18nDynamicTranslate.transform("You have consumed your contact credit limit, to continue using Qubriux and all its features, either Upgrade your plan or Top up your account.", ['POS_PAGE']),
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
          },
          (err) => {
            if (err.status === 402) {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: "Error",
                  body: this._i18nDynamicTranslate.transform("You do not have any active subscription. To continue using Qubriux and all of its features please subscribe to one of your plans. To subscribe to Qubriux go to your profile and click on Upgrade Plan button.", ['POS_PAGE']),
                },
              });
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            } else {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Error",
                    message:
                      "Something went wrong. Please try again after some time.",
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
          }
        );
      }
      else{
        this._ApiCallService.getCurrentPlanPos().subscribe(
          (response) => {
            let subscriptionData = response["body"];
            if (subscriptionData["isEmailVerified"] === false) {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Email Verification Pending",
                    message:
                      this._i18nDynamicTranslate.transform("Check your inbox and verify your email address to access full features on Qubriux.", ['POS_PAGE']),
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            } else if (
              subscriptionData["isCustomerThresholdReached"] === true
            ) {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Error",
                    message:
                      this._i18nDynamicTranslate.transform("You have consumed your contact credit limit, to continue using Qubriux and all its features, either Upgrade your plan or Top up your account.", ['POS_PAGE']),
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
          },
          (err) => {
            if (err.status === 402) {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: "Error",
                  body: this._i18nDynamicTranslate.transform("You do not have any active subscription. To continue using Qubriux and all of its features please subscribe to one of your plans. To subscribe to Qubriux go to your profile and click on Upgrade Plan button.", ['POS_PAGE']),
                },
              });
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            } else {
              if (this._dialog.openDialogs.length>0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Error",
                    message:
                      "Something went wrong. Please try again after some time.",
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
          }
        );
      }
    }
  }

  public IsValidRoute(subscription: number) {
    if (subscription) {
      return true;
    } else {
      this._router.navigate(["/app/app-home"], {
        queryParams: { type: "Objectives" },
      });

      return false;
    }
  }
}
