import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-my-store-preview',
  templateUrl: './my-store-preview.component.html',
  styleUrls: ['./my-store-preview.component.css']
})
export class MyStorePreviewComponent implements OnInit {
  storeName = "";
  storeId = ""
  storeEmail = "";
  appSubscriptionPlan;
  validWidgets;
  imageLoading = false;
  fileToUpload2: File = null;
  files: any[] = [];
  imagePath;
  image = false;
  message;
  url;
  data;
  limit =4;
  list;
  show = false;
  isHovering: boolean;
  previewUrl = null;
  logoUrl = null;
  logoPresent = true;
  previewPresent = true;
  tag = [];
  zoom = 15.5;
  lat;
  long;
  timeStamp;
  isLogo;
  number;

  constructor(
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private snackBar: SnackbarCollection,
    private apicall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  ngOnInit(): void {
    this.getWidgets();
    this.route.queryParams
    .subscribe(params => {
      this.tokenStorage.saveStoreId(params.id);
    }
  );
    this.getData();
  }
  getData(){
    // this.data={
    //   "id": 4,
    //   "storeName": "damian",
    //   "email": "damian.daruja@yahoo.com",
    //   "phone": "9539422861",
    //   "address": "Moolaiparamil house jesus cross road vaduthala",
    //   "category": "Food and Beverage",
    //   "tags": "'Coffee', 'Side', 'Tea', 'Snacks', 'Moctails', 'hello', 'hey', 'cofeeee', 'starbucks'",
    //   "lat" : 51.6784,
    //   "lng" : 7.809007
    // }
    // this.list = this.data['tags']
    // console.log(this.data['tags'])
    this.apicall.getStoreDetails().subscribe((response)=>{
      this.data = (response['body']['storeMetaInfo'])
      // if(this.tokenStorage.getShopName())
      //   this.storeName = this.tokenStorage.getShopName();
      // else
        this.storeName = this.data['storeName'];
        this.storeId = this.data['storeId'];
      // if(this.tokenStorage.getUserEmail())
      //   this.storeEmail = this.tokenStorage.getUserEmail();
      // else
        this.storeEmail = response['body']['email']
        this.number = response['body']['phoneNumber']
      if(this.data['tags'] !== null)
        this.tag = this.data['tags'].split(',')
      this.lat=parseFloat(this.data['latitude']);
      this.long=parseFloat(this.data['longitude'])
    })
    this.logoUrl = "https://qbshopper-public.s3.ap-south-1.amazonaws.com/merchant/list/"+this.tokenStorage.getMerchantId()+"/logo.png"
    this.previewUrl = "https://qbshopper-public.s3.ap-south-1.amazonaws.com/merchant/list/"+this.tokenStorage.getMerchantId()+"/shopThumbnail.png"
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  async fileBrowseHandler(event, files, isLogo) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.isLogo = isLogo;
      let allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.indexOf(files[0].type) !== -1) {
        this.prepareFilesList(files);
        this.setFiles2(event);
        this.fileToUpload2 = <File>event.target.files[0];
        // this.addBranding();
        if(isLogo){
          this.imageLoading = true;
          this.addLogo()       
        }
        else
          this.addPreview();
      } else {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Invalid file type", ['POS_PAGE']), 2000);
      }
    }
  }
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);

    }
  }
  setFiles2(event) {
    this.image = true;
    const files = event.target.files;
    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = this._i18nDynamicTranslate.transform("Only images are supported.", ['POS_PAGE']);
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
  showMap(){
    this.show = !this.show;
  }
  async onFileDropped($event) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.prepareFilesList($event);
      this.setFiles2($event);
    }
  }
  addPreview(){
    const fd = new FormData();
    fd.append("file", this.fileToUpload2, this.fileToUpload2.name);
    this.imgUpload(fd, false);
  }
  addLogo(){
    const fd = new FormData();
    fd.append("file", this.fileToUpload2, this.fileToUpload2.name);
    this.imgUpload(fd, true);
  }
  imgUpload(file, isLogo){
    this.apicall.uploadImageStore(file, isLogo).subscribe(
      (response) => {
        this.updateImage()
      },
      (error) => {
        this.imageLoading = false;
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to update logo", ['POS_PAGE']), 2000);
      }
    );
  }
  noLogo(){
    this.logoPresent = false;
  }
  noPreview(){
    this.previewPresent = false;
  }
  toEditPage(){
    this.router.navigate(['/app/user-profile/edit-shop-details'], {
      queryParams: { id: this.data['qid'] },
    })
  }
  updateImage(){
    this.logoPresent = true;
    this.previewPresent = true;
    let interval = setInterval(() => {
      this.timeStamp = new Date().getTime();
      if(this.isLogo)
        this.logoUrl = this.logoUrl + "?" + this.timeStamp;
      else
        this.previewUrl = this.previewUrl + "?" + this.timeStamp;
      this.imageLoading = false;
      if(this.isLogo){
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Logo updated", ['POS_PAGE']), 2000);
      }
      else{
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Preview updated", ['POS_PAGE']), 2000);
      }
      clearInterval(interval);
    }, 500);
    // console.log(this.isLogo)
    // this.timeStamp = new Date().getTime();
    
  }
  getAddress(){
    if(this.data){
      console.log(this.data);
      let address= JSON.parse(this.data['addressLocationJson'])
      return address?.['address'];
    }
  }
  addNewCustomer(){
    this.router.navigate(["/app/customers/add-new-customer"]);
  }
}
