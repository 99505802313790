<div class="page-bg">
  <div class="qb-main-header">
    <div class="qb-main-header-name-div">
      <div class="qb-w-70">
        <div class="qb-formDiv">
          {{data["customer-list-name"]}}
        </div>
      </div>
    </div>
    <div>
      <div class="qb-d-flex">
        <span class="secondary-button-border" (click)="close(false)"
        data-button-name="Canceled Operation" [attr.data-item-name]="data['data-item']">{{ data["cancelButtonText"]
        }}</span>
        <div class="qb-primary-button primary-button qb-ml-15 " appButtonDebounce [throttleTime]="2000"
          (throttledClick)="data.isEmployeeList ?  submitEmployeeListData() : submitCustomerListData()" data-button-name="Save Customer List">
          <span class="material-icons-outlined qb-button-icon">
            save
          </span>
          <span class="qb-save-segment-text">{{'SEGMENT_PAGE.SAVE' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-w-100 main-white-panel" style="height: calc(100% - 58px);  overflow-y: scroll;">
    <div class="qb-create-customer-list-card sp-height-100">
      <div class="qb-table-header" *ngIf="(validWidgets | widgetConfig: 7) || (validWidgets | widgetConfig: 72)" data-widget-name="CUSTOMER LIST">
        <div class="qb-customer-list" style="gap: 10px;">
          <span>Select customers to add to custom list</span>
          <span class="mini-list-item" *ngIf="selectedCustomerList.length > 0"> Selected : {{ selectedCustomerList.length }}</span>
        </div>
        <div class="qb-search-bar-div">
          <div class="qb-search-bar">
            <input type="text" #search class="qb-search-input" placeholder="Search by Customer Name/Number/Email"
              (input)="onKeySearch(search.value, true)" />
            <span class="material-icons icons-primary  qb-fs-19 color-white" *ngIf="search.value.length==0"> search </span>
            <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19 color-white" *ngIf="search.value.length>0"
              (click)="search.value='';onKeySearch(search.value, true)">
              close
            </span>
          </div>
        </div>
      </div>

      <div class="main-list-container-3 pos-rel" *ngIf="validWidgets">
        <div class="list-column-header-row-2" #headerContainer>
            <div class="col-header">
                Name
            </div>
            <div class="col-header wide-column-width">
                Email
            </div>
            <div class="col-header medium-col-width">
                Phone
            </div>
            <div class="col-header medium-column-width">
                Customer Since
            </div>
            <div class="col-header">
              Segment
            </div>
            <div class="col-header" *ngIf="!(validWidgets | widgetConfig: 72)">
              Store
            </div>
            <div class="col-header ex-narrow-column-width">
              Selected
            </div>
        </div>
        <div class="main-list-2" #contentContainer>
          <div class="list-loader-container" *ngIf="loading">
              <app-qubriux-loading  [size]="65" [loadingText]="'Fetching your customers...'"></app-qubriux-loading>
          </div>
          <div *ngIf="!loading && customers.length == 0" class="empty-list-indicator">
              No Customers Found
          </div>
          <div *ngIf="!loading && customers.length > 0" >
              <div *ngFor="let customer of customers" class="list-item-row-2" (click)="!customer.alreadyInList && addCustomerToList(customer['merchantCustomerId'])" [ngClass]="{'disabled-row':customer.alreadyInList}" [matTooltip]="customer.alreadyInList?'Already present in list':''">
                  <div class="list-item">
                    <div class="list-item-content" [matTooltip]="customer.customerName">{{ customer["customerName"] || '----'}}</div>
                  </div>
                  <div class="list-item">
                    <div class="list-item-content" [matTooltip]="customer.email">{{ customer["email"] || '----'}}</div>
                  </div>
                  <div class="list-item medium-col-width">
                    <div class="list-item-content" [matTooltip]="customer.phone">{{ customer["phone"] || '----'}}</div>
                  </div>
                  <div class="list-item medium-column-width">
                    <div class="list-item-content">{{ getDateInFormatForDisplay(customer["customerSince"]) || '----'}}</div>
                  </div>
                  <div class="list-item">
                    <div *ngIf="customer['segments'].length==0;else segmentList">
                      ----
                    </div>
                    <ng-template #segmentList>
                      <div class="mini-list-item" [matTooltip]="customer['segments'][0]">{{customer['segments'][0]}}</div>
                      <div *ngIf="customer['segments']?.length>1" [matMenuTriggerFor]="menu" class="see-more" (click)="$event.stopPropagation()">See All {{customer['segments'].length}} segments<span class="material-symbols-outlined">keyboard_double_arrow_right</span></div>
                      <mat-menu #menu="matMenu"  class="custom-menu">
                        <div *ngFor="let segmentName of customer['segments']" class="mini-list-item" [matTooltip]="segmentName">
                          {{segmentName}}
                        </div>
                      </mat-menu>
                    </ng-template>
                  </div>
                  <div class="list-item" *ngIf="!(validWidgets | widgetConfig: 72)">
                    <div *ngIf="!customer['stores'] || customer['stores']?.length==0;else storeList">
                      ----
                    </div>
                    <ng-template #storeList>
                      <div class="mini-list-item" [matTooltip]="customer['stores'][0]">{{customer['stores'][0]}}</div>
                      <div *ngIf="customer['stores']?.length>1" [matMenuTriggerFor]="menu" class="see-more" (click)="$event.stopPropagation()">See All {{customer['stores'].length}} stores<span class="material-symbols-outlined">keyboard_double_arrow_right</span></div>
                      <mat-menu #menu="matMenu"  class="custom-menu">
                        <div *ngFor="let storeName of customer['stores']" class="mini-list-item" [matTooltip]="storeName">
                          {{storeName}}
                        </div>
                      </mat-menu>
                    </ng-template>
                  </div>
                  <div class="list-item ex-narrow-column-width action-icon-container">
                    <div class="card-selection-2" [ngClass]="{active: checkWhetherSelected(customer['merchantCustomerId']), 'disabled-active':customer.alreadyInList}">
                      <mat-icon class="card-selection-icon">done</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div class="lv-options-2">
                <mat-paginator
                  [pageIndex]="pageIndex - 1"
                  [length]="totalCustomers"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="[5, 10, 25, 100]"
                  (page)="refreshPaginatedData($event)"
                >
                </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>