import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-ai-email-creation',
  templateUrl: './ai-email-creation.component.html',
  styleUrls: ['./ai-email-creation.component.css']
})
export class AiEmailCreationComponent implements OnInit {

  constructor(private dailogRef: MatDialogRef<AiEmailCreationComponent>, private apiCallService: ApiCallService, private snackBar: SnackbarCollection, private _i18nDynamicTranslate: TranslateDynamicText) { }

  showDropdown = false;
  allTags = ['Customer Name', 'Promo Code', 'Product Type', 'Product Benefit', 'Discount'];
  selectedTags = [];
  questionError = "";
  question = "";
  data = {text:"", params:{}};
  params = {"customer name": "{{Customer Name}}", "promo code": "{{Promo Code}}", "product type": "{{Product Type}}", "product benefit": "{{Product Benefit}}", "discount": "{{Discount}}"}
  sampleEmailText = "";
  loadingSample = false;

  ngOnInit(): void {
  }

  selectTag(tag) {
    const index: number = this.selectedTags.indexOf(tag);
    if (index !== -1) {
        this.selectedTags.splice(index, 1);
    } else {
      this.selectedTags.push(tag);
    }      
    console.log(this.selectedTags);
  }

  open(event) {
    this.showDropdown = !this.showDropdown;
  }

  deleteTag(tag) {
    const index: number = this.selectedTags.indexOf(tag);
        this.selectedTags.splice(index, 1);
    console.log(this.selectedTags);
  }

  generate() {
    this.sampleEmailText = "";
    if(this.question === "") {
      this.questionError = this._i18nDynamicTranslate.transform("This field is required", ['SHARED_PAGE']);
    } else {
      this.data['params'] = {};
      this.loadingSample = true;
      this.questionError =  "";
      this.data['text'] = this.question;
      this.selectedTags.forEach(tag => {
        this.data['params'][tag.toLowerCase()] = this.params[tag.toLowerCase()]
      })
      this.apiCallService.getAIEmailTemplate(this.data).subscribe(response => {

        const responseBody = JSON.parse(response['body']);
        const bodyContent = responseBody.email_template;
        this.sampleEmailText = bodyContent;
        // this.sampleEmailText = JSON.parse(response['body'])
        this.loadingSample = false;
      })
    }
  }

  close() {
    this.dailogRef.close();
  }

  copy() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.sampleEmailText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Text copied!", ['SHARED_PAGE'])
    , 2000);
  }

}
