import { NgModule } from '@angular/core';
import { FormCreationComponent, MyOffersComponent, MyPartnersComponent, NewRewardComponent, PartnerDetailsComponent, PartnerOfferCreationComponent, PartnerSearchComponent, PartnershipAnalyticsComponent, PartnershipComponent, PartnershipCreateFormComponent, PartnershipDashboardComponent, PartnershipFormsComponent, PartnershipRequestsComponent, PaymentsComponent, RemoveOfferComponent, RewardsComponent, TargetDetailComponent } from './pages';
import { PartnershipAgreementComponent, PartnershipChannelSelectComponent } from './component';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { PartnershipRoutingModule } from './partnership-routing.module';
import { PartnerGroupOfferCreationComponent } from './pages/partner-group-offer-creation/partner-group-offer-creation.component';



@NgModule({
  declarations: [
    FormCreationComponent,
    MyOffersComponent,
    MyPartnersComponent,
    NewRewardComponent,
    PartnerDetailsComponent,
    PartnerOfferCreationComponent,
    PartnerSearchComponent,
    PartnershipComponent,
    PartnershipAnalyticsComponent,
    PartnershipCreateFormComponent,
    PartnershipDashboardComponent,
    PartnershipFormsComponent,
    PartnershipRequestsComponent,
    PaymentsComponent,
    RemoveOfferComponent,
    RewardsComponent,
    TargetDetailComponent,
    PartnershipAgreementComponent,
    PartnerGroupOfferCreationComponent,
    PartnershipChannelSelectComponent
  ],
  imports: [
    SharedModule,
    PartnershipRoutingModule
  ]
})
export class PartnershipModule { }
