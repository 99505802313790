<div class="qb-body">
  <div class="qb-main">
    <div>
      <div class="qb-form-config" #goUp>{{'PARTNERSHIP_PAGE.FORM_CONFIG' | i18nTranslate}}</div>
      <div class="qb-options qb-options-num">
        <div
          [ngClass]="{
            'qb-selected': section == 1,
            'qb-not-selected': section < 1,
            'qb-done': section > 1
          }"
          (click)="goToSection(1)"
        >
          <span *ngIf="section <= 1">1</span>
          <span
            class="material-icons-outlined qb-done-icon"
            *ngIf="section > 1"
          >
            done
          </span>
        </div>
        <div class="qb-line"></div>
        <div
          [ngClass]="{
            'qb-selected': section == 2,
            'qb-not-selected': section < 2,
            'qb-done': section > 2
          }"
          (click)="goToSection(2)"
        >
          <span *ngIf="section <= 2">2</span>
          <span
            class="material-icons-outlined qb-done-icon"
            *ngIf="section > 2"
          >
            done
          </span>
        </div>
        <div class="qb-line"></div>
        <div
          [ngClass]="{
            'qb-selected': section == 3,
            'qb-not-selected': section < 3,
            'qb-done': section > 3
          }"
          (click)="goToSection(3)"
        >
          <span *ngIf="section <= 3">3</span>
          <span
            class="material-icons-outlined qb-done-icon"
            *ngIf="section > 3"
          >
            done
          </span>
        </div>
        <div class="qb-line"></div>
        <div
          [ngClass]="{
            'qb-selected': section == 4,
            'qb-not-selected': section < 4,
            'qb-done': section > 4
          }"
          (click)="goToSection(4)"
        >
          <span *ngIf="section <= 4">4</span>
          <span
            class="material-icons-outlined qb-done-icon"
            *ngIf="section > 4"
          >
            done
          </span>
        </div>
      </div>
      <div class="qb-options">
        <div
          [ngClass]="{
            'qb-option-text': section == 1,
            'qb-not-selected-text': section != 1
          }"
        >
          {{'PARTNERSHIP_PAGE.FORM_NAME_OFFER_TYPE' | i18nTranslate}}
        </div>
        <div
          [ngClass]="{
            'qb-option-text': section == 2,
            'qb-not-selected-text': section != 2
          }"
        >
          {{'PARTNERSHIP_PAGE.FORM_CONTENT_STYLE' | i18nTranslate}}
        </div>
        <div
          [ngClass]="{
            'qb-option-text': section == 3,
            'qb-not-selected-text': section != 3
          }"
          class="qb-config-sec"
        >
          {{'PARTNERSHIP_PAGE.CONFIG' | i18nTranslate}}
        </div>
        <div
          [ngClass]="{
            'qb-option-text': section == 4,
            'qb-not-selected-text': section != 4
          }"
          class="qb-schedule-sec"
        >
          Schedule
        </div>
      </div>
      <div *ngIf="section == 1">
        <div class="qb-title">{{'PARTNERSHIP_PAGE.FORM_NAME_OFFER_TYPE' | i18nTranslate}}</div>
        <div class="qb-text">
          {{'PARTNERSHIP_PAGE.FORM_NAME_OFFER_TYPE_MESSAGE' | i18nTranslate}}
        </div>
        <div class="qb-form-name">{{'PARTNERSHIP_PAGE.FORM_NAME' | i18nTranslate}}</div>
        <div class="qb-input-div">
          <div class="qb-form-name-input">
            <input
              type="text"
              placeholder="Offers from Partner Stores"
              [(ngModel)]="data.name"
              (input)="formNameError = ''"
            />
          </div>
          <div
            *ngIf="data.name.length == 0 || formNameError.length > 0"
            class="qb-error"
          >
            {{ formNameError }}
          </div>
        </div>
        <div class="qb-select-offers" style="color: rgba(34, 34, 34, 0.6);">
          {{'PARTNERSHIP_PAGE.SELECT_GROUP_OFFER' | i18nTranslate}}
        </div>
        <div class="qb-home-offer-selector qb-offer-page-selector-div" (click)="openSelectOffer()">
          <div class="qb-offer-page-selector-container">
          </div>
          <div class="qb-select-div-2 qb-offer-page-selector-container-2">
            <mat-select
              #selectOffer
              class="qb-graph-select qb-of-auto"
              [(ngModel)]="selectedOffer"
              (selectionChange)="changeOffer()"
              [value]="selectedOffer"
              placeholder="Select Offer"

            >
              <mat-option *ngFor="
              let offer of coMarketingOffers"
              [value]="offer"
  
              >{{offer['offerName']}}</mat-option
            >
            </mat-select>
          </div>
        </div>
        <!-- <div>
          <div *ngFor="let offer of offers">
            <mat-checkbox
              *ngIf="offer['isActive'] !== false"
              [checked]="s.indexOf(offer['offerId']) != -1"
              [disabled]="
                (s.length == 3 && s.indexOf(offer['offerId']) == -1) ||
                offer['isActive']
              "
              (click)="check($event)"
              (change)="
                addOffer(
                  offer['offerId'],
                  offer['description'],
                  offer['partnershipRequests']
                )
              "
              >{{ offer["description"] }}</mat-checkbox
            >
          </div>
        </div> -->
        <!-- <div class="qb-offer-details">
          Choose what the customer has to do to get the offer details
        </div> -->
        <!-- <div class="qb-radio-divs">
          <div>
            <mat-radio-button
              [checked]="barrierJSON.type === 'AMOUNT'"
              (change)="barrierJSON.type = 'AMOUNT'"
              >Minimum purchase amount</mat-radio-button
            >
            <div *ngIf="barrierJSON.type === 'AMOUNT'">
              <div class="qb-offers-input">
                <div class="qb-dollar">$</div>
                <input
                  type="number"
                  [(ngModel)]="barrierJSON.amountOrQuntity"
                  placeholder="100"
                />
              </div>
            </div>
          </div>
          <div>
            <mat-radio-button
              [checked]="barrierJSON.type === 'QUANTITY'"
              (change)="barrierJSON.type = 'QUANTITY'"
              >Minimum quantity of items</mat-radio-button
            >
            <div *ngIf="barrierJSON.type === 'QUANTITY'">
              <div class="qb-offers-input">
                <input
                  type="number"
                  [(ngModel)]="barrierJSON.amountOrQuntity"
                  placeholder="2"
                />
              </div>
            </div>
            <div class="qb-applies-to" *ngIf="barrierJSON.type === 'QUANTITY'">
              Applies To
            </div>
            <div *ngIf="barrierJSON.type === 'QUANTITY'">
              <div
                class="qb-offers-input qb-dropdown-input"
                (click)="showDropdown = !showDropdown; $event.stopPropagation()"
              >
                <div *ngIf="ids.length == 0">Select Category</div>
                <div *ngIf="ids.length == 1">
                  {{ selectedProductCollections[0].collectionName }}
                </div>
                <div *ngIf="ids.length > 1">
                  Categories selected - {{ ids.length }}
                </div>
                <div *ngIf="!showDropdown" class="material-icons-outlined">
                  keyboard_arrow_down
                </div>
                <div *ngIf="showDropdown" class="material-icons-outlined">
                  expand_less
                </div>
              </div>
              <div *ngIf="showDropdown" class="qb-dropdown-div" #myDiv>
                <div>
                  <mat-checkbox
                    (change)="selectCollection(-1, All, $event)"
                    [checked]="
                      selectedProductCollections.length ==
                      productCollections.length
                    "
                    >All Categories</mat-checkbox
                  >
                </div>
                <div *ngFor="let collection of productCollections">
                  <div class="qb-dropdown-option">
                    <mat-checkbox
                      [checked]="
                        ids.indexOf(collection['productCategoryId']) != -1
                      "
                      (change)="
                        selectCollection(
                          collection['productCategoryId'],
                          collection['productCategoryName'],
                          $event
                        )
                      "
                      >{{ collection["productCategoryName"] }}</mat-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="qb-buttons-div">
          <div class="secondary-button-border" (click)="delete()">{{'PARTNERSHIP_PAGE.DISCARD' | i18nTranslate}}</div>
          <div class="primary-button" (click)="continue(2)">{{'PARTNERSHIP_PAGE.CONTINUE' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="section == 2" class="qb-section">
      <div class="qb-editor-div">
        <app-form-creation
          (apiData)="addHTML($event)"
          [offerData]="offerData"
          [comOfferId]="selectedOffer.id"
          [json]="data.formJson"
        ></app-form-creation>
      </div>
      <div class="qb-editor-buttons">
        <div class="qb-previous" (click)="previous(1)">{{'PARTNERSHIP_PAGE.PREVIOUS' | i18nTranslate}}</div>
        <div class="qb-editor-proceed-buttons">
          <div class="secondary-button-border" (click)="delete()">{{'PARTNERSHIP_PAGE.DISCARD' | i18nTranslate}}</div>
          <div class="primary-button-border" (click)="saveandexit(2)">
            {{'PARTNERSHIP_PAGE.SAVE_EXIT' | i18nTranslate}}
          </div>
          <div class="primary-button" (click)="continue(3, true)">{{'PARTNERSHIP_PAGE.CONTINUE' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="section == 3" class="qb-config-section">
      <div>
        <div class="qb-title">{{'PARTNERSHIP_PAGE.CONFIG' | i18nTranslate}}</div>
        <div class="qb-subtitle">
          {{'PARTNERSHIP_PAGE.CONFIG_MESSAGE' | i18nTranslate}}
        </div>
        <div class="qb-dropdown-text">{{'PARTNERSHIP_PAGE.SHOW_TO' | i18nTranslate}}</div>
        <div class="qb-offers-input qb-config-input">
          <mat-select [(ngModel)]="scheduleInfo.contentAndStyle.showTo">
            <mat-option *ngFor="let to of showTo" [value]="to.value">{{
              to.label
            }}</mat-option>
          </mat-select>
        </div>
        <div
          *ngIf="scheduleInfo.contentAndStyle.showTo == 'SEGMENTS'"
          class="qb-dropdown-text"
        >
          {{'PARTNERSHIP_PAGE.SEGMENTS' | i18nTranslate}}
        </div>
        <div
          *ngIf="scheduleInfo.contentAndStyle.showTo == 'SEGMENTS'"
          class="qb-offers-input qb-config-input"
        >
          <mat-select
            (selectionChange)="changeSegmentSelection()"
            color="primary"
            placeholder="Select customer segment"
            [(ngModel)]="scheduleInfo.contentAndStyle.segments"
            multiple
            disableOptionCentering
            panelClass="myPanelClass"
          >
            <div>
              <div class="qb-mat-select-div">
                <mat-icon class="qb-mat-icon-div" matPrefix>search </mat-icon>
                <input
                  class="qb-input-div"
                  type="text"
                  [(ngModel)]="tempsegmentFilter"
                  placeholder="Search by name"
                />
              </div>
            </div>
            <div>
              <mat-option
                *ngFor="
                  let segment of allcustomerObj
                    | labelPipe: tempsegmentFilter:'name'
                "
                [value]="segment.id"
                [disabled]="disableAllCustomerSelection"
                [value]="segment.id"
              >
                {{ segment.name }}
              </mat-option>

              <mat-option
                *ngFor="
                  let segment of segments | labelPipe: tempsegmentFilter:'name'
                "
                [value]="segment.id"
                [disabled]="disableCustomerSegmentSelection"
              >
                {{ segment.name }}
              </mat-option>
            </div>
          </mat-select>
        </div>
        <div class="qb-dropdown-text">{{'PARTNERSHIP_PAGE.SHOW_WHEN' | i18nTranslate}}</div>
        <div class="qb-offers-input qb-config-input">
          <mat-select [(ngModel)]="scheduleInfo.contentAndStyle.showWhen">
            <mat-option *ngFor="let when of showWhen" [value]="when.value">{{
              when.label
            }}</mat-option>
          </mat-select>
        </div>
        <div
          *ngIf="
            scheduleInfo.contentAndStyle.showWhen == 'AFTER_X_PERCENT' ||
            scheduleInfo.contentAndStyle.showWhen == 'AFTER_X_SECONDS'
          "
          class="qb-dropdown-text"
        >
          {{'PARTNERSHIP_PAGE.VALUE' | i18nTranslate}}
        </div>
        <div
          *ngIf="
            scheduleInfo.contentAndStyle.showWhen == 'AFTER_X_PERCENT' ||
            scheduleInfo.contentAndStyle.showWhen == 'AFTER_X_SECONDS'
          "
          class="qb-offers-input qb-config-input"
        >
          <input
            [(ngModel)]="scheduleInfo.contentAndStyle.showWhenValue"
            type="text"
            class="qb-input-box"
          />
          <span
            *ngIf="scheduleInfo.contentAndStyle.showWhen === 'AFTER_X_PERCENT'"
            >%</span
          >
        </div>
        <div class="qb-dropdown-text">{{'PARTNERSHIP_PAGE.DEVICE_VISIBILITY' | i18nTranslate}}</div>
        <div class="qb-offers-input qb-config-input">
          <mat-select
            [(ngModel)]="scheduleInfo.contentAndStyle.deviceVisibility"
          >
            <mat-option *ngFor="let device of devices" [value]="device">{{
              device
            }}</mat-option>
          </mat-select>
        </div>
        <div class="qb-dropdown-text">{{'PARTNERSHIP_PAGE.SHOW_ON' | i18nTranslate}}</div>
        <div class="qb-offers-input qb-config-input">
          <mat-select [(ngModel)]="scheduleInfo.contentAndStyle.showOn">
            <mat-option *ngFor="let on of showOn" [value]="on.value">{{
              on.label
            }}</mat-option>
          </mat-select>
        </div>
        <div
          class="qb-dropdown-text"
          *ngIf="scheduleInfo.contentAndStyle.showOn === 'OTHERS'"
        >
          {{'PARTNERSHIP_PAGE.SELECT_URL' | i18nTranslate}}
        </div>
        <div
          class="qb-offers-input qb-config-input"
          *ngIf="scheduleInfo.contentAndStyle.showOn === 'OTHERS'"
        >
          <mat-select
            [(ngModel)]="scheduleInfo.contentAndStyle.showOnSpecificUrl"
            multiple
          >
            <mat-option [value]="route.path" *ngFor="let route of routes">{{
              route.name
            }}</mat-option>
          </mat-select>
        </div>
        <div class="qb-dropdown-text">{{'PARTNERSHIP_PAGE.LIMITS' | i18nTranslate}}</div>
        <div class="qb-offers-input qb-config-input">
          <mat-select [(ngModel)]="scheduleInfo.contentAndStyle.limit">
            <mat-option *ngFor="let limits of limit" [value]="limits.value">{{
              limits.label
            }}</mat-option>
          </mat-select>
        </div>
        <div
          class="qb-dropdown-text"
          *ngIf="scheduleInfo.contentAndStyle.limit == 'REPEAT'"
        >
          {{'PARTNERSHIP_PAGE.VALUE' | i18nTranslate}}
        </div>
        <div
          class="qb-offers-input qb-config-input"
          *ngIf="scheduleInfo.contentAndStyle.limit == 'REPEAT'"
        >
          <input
            [(ngModel)]="scheduleInfo.contentAndStyle.limitValue"
            type="text"
          />
        </div>
        <div class="qb-editor-buttons qb-config-buttons">
          <div class="qb-previous" (click)="previous(2)">{{'PARTNERSHIP_PAGE.PREVIOUS' | i18nTranslate}}</div>
          <div class="qb-editor-proceed-buttons">
            <div class="secondary-button-border" (click)="delete()">
              {{'PARTNERSHIP_PAGE.DISCARD' | i18nTranslate}}
            </div>
            <div class="primary-button-border" (click)="saveandexit(3)">
              {{'PARTNERSHIP_PAGE.SAVE_EXIT' | i18nTranslate}}
            </div>
            <div class="primary-button" (click)="continue(4, false)">
              {{'PARTNERSHIP_PAGE.CONTINUE' | i18nTranslate}}
            </div>
          </div>
        </div>
      </div>
      <div class="qb-form-preview">
        <div class="qb-title">{{'PARTNERSHIP_PAGE.FORM_PREVIEW' | i18nTranslate}}</div>
        <div>
          <div [innerHtml]="data.formHtml | safeHtml"></div>
        </div>
      </div>
    </div>
    <div *ngIf="section == 4" class="qb-config-section">
      <div class="qb-main-divs">
        <div class="qb-title">{{'PARTNERSHIP_PAGE.SCHDULE_FORM' | i18nTranslate}}</div>
        <div class="qb-subtitle">
          {{'PARTNERSHIP_PAGE.SCHDULE_FORM_MESSAGE' | i18nTranslate}}
        </div>
        <div>
          <mat-radio-group
            name="date"
            [(ngModel)]="selectedRadio"
            class="qb-date-radio-div"
          >
            <mat-radio-button value="range">{{'PARTNERSHIP_PAGE.DATE_RANGE' | i18nTranslate}}</mat-radio-button>
            <mat-radio-button value="specific">{{'PARTNERSHIP_PAGE.SPECIFIC_DATE' | i18nTranslate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="qb-date-div" *ngIf="selectedRadio == 'specific'">
          <div>
            <div>{{'PARTNERSHIP_PAGE.DATE' | i18nTranslate}}<span class="required">*</span></div>
            <div class="qb-form-name-input qb-date-input">
              <input
                placeholder="Choose a Date"
                [(ngModel)]="date"
                [matDatepicker]="picker1"
                [min]="todayDate"
                [max]="endDate"
                [ngStyle]="date ? { opacity: 0 , width: '0px' } : null" 
              />
              <div [ngStyle]="offerDisplayValidy ? { width: '90%' } : null" >
                {{date | date: getDateFormat() }}
            </div>
              <mat-datepicker-toggle matSuffix [for]="picker1">
              </mat-datepicker-toggle>
              <mat-datepicker #picker1 [touchUi]="true"></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="qb-date-div" *ngIf="selectedRadio == 'range'">
          <div>
            <div>{{'PARTNERSHIP_PAGE.START_DATE' | i18nTranslate}}<span class="qb-required">*</span></div>
            <div class="qb-form-name-input qb-date-input">
              <input
                placeholder="Choose a Date"
                [(ngModel)]="startDate"
                [matDatepicker]="picker2"
                [min]="todayDate"
                [max]="endDate"
                [ngStyle]="startDate ? { opacity: 0 , width: '0px' } : null" 
                />
                <div [ngStyle]="startDate ? { width: '90%' } : null" >
                  {{startDate | date: getDateFormat() }}
              </div>
              <mat-datepicker-toggle matSuffix [for]="picker2">
              </mat-datepicker-toggle>
              <mat-datepicker #picker2 [touchUi]="true"></mat-datepicker>
            </div>
          </div>
          <div>
            <div>{{'PARTNERSHIP_PAGE.END_DATE' | i18nTranslate}}<span class="qb-required">*</span></div>
            <div class="qb-form-name-input qb-date-input">
              <input
                [(ngModel)]="endDate"
                [matDatepicker]="picker3"
                [min]="todayDate"
                [max]="endDate"
                disabled
                [ngStyle]="endDate ? { opacity: 0 , width: '0px' } : null" 
                />
                <div style="width: 90%;">
                  {{endDate | date: getDateFormat() }}
              </div>
              <mat-datepicker-toggle matSuffix [for]="picker3">
              </mat-datepicker-toggle>
              <mat-datepicker #picker1 [touchUi]="true"></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="qb-at-any-time-div">
          <div>{{'PARTNERSHIP_PAGE.AT_ANY_TIME' | i18nTranslate}}</div>
          <div>
            <mat-slide-toggle
              (change)="atAnyTime = $event.checked"
              class="qb-example-margin"
              [color]="color"
              [checked]="atAnyTime"
              [disabled]="disabled"
            >
            </mat-slide-toggle>
          </div>
        </div>
        <div class="qb-date-div" *ngIf="!atAnyTime">
          <div class="qb-time">
            <div>{{'PARTNERSHIP_PAGE.START_TIME' | i18nTranslate}}<span class="qb-required">*</span></div>
            <div class="qb-form-name-input qb-date-input">
              <input
                [(ngModel)]="startTime"
                [format]="serviceCountry === 'US' ? 12 : 24"
                [ngxTimepicker]="picker1T"
                readonly
              />
              <ngx-material-timepicker #picker1T> </ngx-material-timepicker>
            </div>
          </div>
          <div class="qb-time">
            <div>{{'PARTNERSHIP_PAGE.END_TIME' | i18nTranslate}}<span class="qb-required">*</span></div>
            <div class="qb-form-name-input qb-date-input">
              <input
                [(ngModel)]="endTime"
                [format]="serviceCountry === 'US' ? 12 : 24"
                [ngxTimepicker]="picker2T"
                [min]="startTime"
                readonly
              />
              <ngx-material-timepicker #picker2T> </ngx-material-timepicker>
            </div>
          </div>
        </div>
        <div class="qb-at-any-time-div" *ngIf="selectedRadio == 'range'">
          <div>{{'PARTNERSHIP_PAGE.EVERY_DAY_OF_WEEK' | i18nTranslate}}</div>
          <div>
            <mat-slide-toggle
              (change)="everyDay = $event.checked"
              class="qb-example-margin"
              [color]="color"
              [checked]="everyDay"
              [disabled]="disabled"
            >
            </mat-slide-toggle>
          </div>
        </div>
        <div class="qb-day-div" *ngIf="selectedRadio == 'range' && !everyDay">
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Sun') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Sun') != -1
            }"
            (click)="selectDay('Sun')"
          >
            Sun
          </div>
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Mon') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Mon') != -1
            }"
            (click)="selectDay('Mon')"
          >
            Mon
          </div>
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Tue') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Tue') != -1
            }"
            (click)="selectDay('Tue')"
          >
            Tue
          </div>
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Wed') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Wed') != -1
            }"
            (click)="selectDay('Wed')"
          >
            Wed
          </div>
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Thu') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Thu') != -1
            }"
            (click)="selectDay('Thu')"
          >
            Thu
          </div>
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Fri') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Fri') != -1
            }"
            (click)="selectDay('Fri')"
          >
            Fri
          </div>
          <div
            [ngClass]="{
              'qb-day': timeInfoDays.daysRule.indexOf('Sat') == -1,
              'qb-selected-day': timeInfoDays.daysRule.indexOf('Sat') != -1
            }"
            (click)="selectDay('Sat')"
          >
            Sat
          </div>
        </div>
        <!-- <div>
          <div class="qb-email-div">Offer Text</div>
          <div class="qb-offer-text-div">
            <input type="text" [(ngModel)]="timeEmailConfig.offerText" />
          </div>
          <div class="qb-email-div">Email Subject</div>
          <div class="qb-form-name-input qb-subject-div">
            {{ timeEmailConfig.emailSubject }}
          </div>
          <div class="qb-attach-div" (click)="opensnapshotHtmlDialog()">
            <div class="material-icons-outlined qb-attach-icon">
              attach_file
            </div>
            <div class="qb-form-email">{{ templateName }}</div>
          </div>
        </div> -->
        <div class="qb-editor-buttons qb-enable-buttons">
          <div class="qb-previous" (click)="previous(3)">{{'PARTNERSHIP_PAGE.PREVIOUS' | i18nTranslate}}</div>
          <div class="qb-editor-proceed-buttons">
            <div class="secondary-button-border" (click)="delete()">
              {{'PARTNERSHIP_PAGE.DISCARD' | i18nTranslate}}
            </div>
            <div class="primary-button-border" (click)="saveandexit(4)">
              {{'PARTNERSHIP_PAGE.SAVE_EXIT' | i18nTranslate}}
            </div>
            <div class="primary-button" (click)="enable()">{{'PARTNERSHIP_PAGE.ENABLE' | i18nTranslate}}</div>
          </div>
        </div>
      </div>
      <!-- <div class="qb-main-divs" class="qb-preview-div">
        <div class="qb-email-preview-title">Email Template Preview</div>
        <div class="qb-top">
          <div [innerHtml]="displayEmailLayoutHTML | safeHtml"></div>
        </div>
      </div> -->
    </div>
  </div>
</div>
