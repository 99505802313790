import EN from "../../../assets/i18n/en";
import EN_DYNAMIC from "../../../assets/i18nDynamic/en";
import FR from "../../../assets/i18n/fr";
import FR_DYNAMIC from "../../../assets/i18nDynamic/fr";
import ES from "../../../assets/i18n/es";
import ES_DYNAMIC from "../../../assets/i18nDynamic/es";
import { TokenStorageService } from "../auth/token-storage.service";
import { Injectable } from "@angular/core";

@Injectable()
export class TextTranslationService{
    constructor(
        private tokenStorage: TokenStorageService
      ) {}
    currentUrl = window.location.origin;
    private dictionary = {
        "en": EN,
        "fr": FR,
        "es": ES,
    };
    private dynamicDictionary = {
        "en": EN_DYNAMIC,
        "fr": FR_DYNAMIC,
        "es": ES_DYNAMIC,
    }
    private selectedLanguage = "en";
    private defaultLanguage = "en";
    private languageOptions: String[] = ["en", "fr", "es"];
    private availableLanguages = [{label: "English", value: "en", flagImgUrl: "https://flagcdn.com/gb.svg"}, {label: "French", value: "fr", flagImgUrl: "https://flagcdn.com/fr.svg"}, {label: "Spanish", value: "es", flagImgUrl: "https://flagcdn.com/es.svg"}];

    getSelectedLang(){
        return this.selectedLanguage;
    }

    getAvailableLanguages(){
        return this.availableLanguages;
    }

    private findTextByPosition(position: string[], dynamicTexts: any[], language: string){
        let selectedDictionary = this.dictionary[language];
        let moduleData = selectedDictionary[position[0]];
        // position.forEach(item=>{
        //     textByPosition = textByPosition && textByPosition[item]? textByPosition[item] : placeholder;
        // });
        let textToOut = moduleData[position[1]];
        if(dynamicTexts && dynamicTexts.length){
            dynamicTexts.forEach((dynamicText,i)=>{
                textToOut=textToOut.replace(`{{dynamicText-${i+1}}}`, dynamicText);
            })
        }
        return textToOut; 
    }

    getTranslatedText(position: string[], dynamicTexts: any[]){
        let translatedText = "";
        if(this.languageOptions.indexOf(this.selectedLanguage)!=-1 && this.dictionary[this.selectedLanguage]){
            let translatedTextObserver = this.findTextByPosition(position, dynamicTexts, this.selectedLanguage);
            translatedText = translatedTextObserver;
        }
        else{
            let translatedTextObserver = this.findTextByPosition(position, dynamicTexts, this.defaultLanguage);
            translatedText = translatedTextObserver;
        }
        return (this.currentUrl.includes('restomail') || this.tokenStorage?.getMerchantOnlineStore()?.toLowerCase() === 'restomail') ? translatedText?.replace('Qubriux', 'Restomail') : (translatedText);;
        
    }

    updateSelectedLanguage(language){
        this.selectedLanguage = language;
    }

    getDynamicTranslatedText(text: string, args: any[]){
        let selectedDictionary :any
        let moduleData :any
        let translatedText = text;
        if(this.dynamicDictionary[this.selectedLanguage]) {
            selectedDictionary = this.dynamicDictionary[this.selectedLanguage];
            moduleData = selectedDictionary[args[0]];
            translatedText = moduleData[text];
        }
        else if(this.dynamicDictionary[this.defaultLanguage]){
            // translatedText = this.dynamicDictionary[this.defaultLanguage][text]; 
            selectedDictionary = this.dynamicDictionary[this.defaultLanguage];
            moduleData = selectedDictionary[args[0]];
            translatedText = moduleData[text];  
        }   
        if(translatedText?.length>0 && translatedText !== null && translatedText !== undefined)
            return (this.currentUrl.includes('restomail') || this.tokenStorage?.getMerchantOnlineStore()?.toLowerCase() === 'restomail') ? translatedText?.replace('Qubriux', 'Restomail') : (translatedText);
        else{
            return (this.currentUrl.includes('restomail') || this.tokenStorage?.getMerchantOnlineStore()?.toLowerCase() === 'restomail') ? text?.replace('Qubriux', 'Restomail') : (text);;
        }
    }
}