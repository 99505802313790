
<div *ngIf="!changePassword" style="padding: 15px">
  <div style="display: flex; justify-content: center">
    <img
      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/profile-screen/success.svg"
    />
  </div>
  <div class="msg">{{'PROFILE_PAGE.PASSWORD_UPDATE_SUCESS' | i18nTranslate}}</div>
  <div class="mat-d" style="display: flex; justify-content: center">
    <div
      (click)="submit(1)"
      class="primary-button"
      style="width: 80%; justify-content: center; margin-top: 15px"
    >
      {{'PROFILE_PAGE.DONE' | i18nTranslate}}
    </div>
  </div>
</div>
<div *ngIf="!success" style="padding: 15px">
  <div class="header">
        <div><strong *ngIf="data.isPasswordGenerated">{{'PROFILE_PAGE.CHANGE_PASS_CAPS' | i18nTranslate}}</strong>
      <strong *ngIf="!data.isPasswordGenerated">{{'PROFILE_PAGE.GENERATE_PASS' | i18nTranslate}}</strong>
    </div>
    <div
      class="material-icons password-change-close-icon"
      (click)="submit(0)"
    >
      clear
    </div>
  </div>
  <div
    *ngIf="passwordError"
    style="
      background: rgba(129, 22, 32, 0.25);
      color: #811620;
      margin: 10px 20px;
      padding: 10px;
    "
  >
    <div><strong>{{'PROFILE_PAGE.ERROR' | i18nTranslate}}: </strong>{{ passwordErrorValue | i18nDynamicTranslate : 'POS_PAGE' }}</div>
  </div>
  <div class="password-change-input-field-margin" *ngIf="data.isPasswordGenerated">
    <span class="filterTitle">Old Password</span>
    <div class="text-div">
      <input
        type="text"
        type="{{ showPassword1 ? 'text' : 'password' }}"
        [(ngModel)]="oldPassword"
        (input)="onOldPassword($event.target.value)"
        (focus)="enterNewPassword = false"
        (keyup.enter)="update()"
      />
      <div
        *ngIf="showPassword1"
        class="password-change-input-sho-hide-text"
        (click)="show1()"
      >
        Hide
      </div>
      <div
        *ngIf="!showPassword1"
        class="password-change-input-sho-hide-text"
        (click)="show1()"
      >
        Show
      </div>
    </div>
    <div
      style="color: red; font-size: 10px; line-height: 12px; margin-right: 8px"
      *ngIf="oldPasswordError"
    >
      <em>{{ oldPasswordErrorValue | i18nDynamicTranslate : 'POS_PAGE' }}</em>
    </div>
    <div
    style="color: red; font-size: 10px; line-height: 12px; margin-right: 8px"
    *ngIf="oldPassword === '' && updatePassword ===true"
  >
    <em>{{'PROFILE_PAGE.FIELD_REQ' | i18nTranslate}}</em>
  </div>
  </div>
  <div class="password-change-input-field-margin">
    <span class="filterTitle">{{'PROFILE_PAGE.NEW_PASSWORD' | i18nTranslate}}</span>
    <div class="text-div">
      <input
        type="text"
        (click)="showErrorBox()"
        type="{{ showPassword2 ? 'text' : 'password' }}"
        (focus)="onNewPassword($event.target.value)"
        (input)="onNewPassword($event.target.value)"
        [(ngModel)]="newPassword"
        (keyup.enter)="update()"
      />
      <div
        *ngIf="showPassword2"
        class="password-change-input-sho-hide-text"
        (click)="show2()"
      >
        Hide
      </div>
      <div
        *ngIf="!showPassword2"
        class="password-change-input-sho-hide-text"
        (click)="show2()"
      >
        Show
      </div>
    </div>
    <div
      style="color: red; font-size: 10px; line-height: 12px"
      *ngIf="newPasswordError"
    >
      <em>{{ newPasswordErrorValue | i18nDynamicTranslate : 'POS_PAGE' }}</em>
    </div>
  </div>
  <div id="passwordPopOver" *ngIf="enterNewPassword">
    <div class="change-password-valid-container">
      <span *ngIf="!pattern1Error" class="change-password-valid-success">
        8-20 {{'PROFILE_PAGE.CHARACTERS' | i18nTranslate}}</span
      >
      <span *ngIf="pattern1Error" class="change-password-valid-error">
        8-20 {{'PROFILE_PAGE.CHARACTERS' | i18nTranslate}}</span
      >
      <span *ngIf="!pattern2Error" class="change-password-valid-success">
        {{'PROFILE_PAGE.ATELEAST_ONE_LOWCASE' | i18nTranslate}}</span
      >
      <span *ngIf="pattern2Error" class="change-password-valid-error">
        {{'PROFILE_PAGE.ATELEAST_ONE_LOWCASE' | i18nTranslate}}</span
      >
      <span *ngIf="!pattern3Error" class="change-password-valid-success">
        {{'PROFILE_PAGE.ATLEAST_ONE_UPPERCASE' | i18nTranslate}}</span
      >
      <span *ngIf="pattern3Error" class="change-password-valid-error">
        {{'PROFILE_PAGE.ATLEAST_ONE_UPPERCASE' | i18nTranslate}}</span
      >
      <span *ngIf="!pattern4Error" class="change-password-valid-success">
        {{'PROFILE_PAGE.ATELEASE_ONE_NUMBER' | i18nTranslate}}</span
      >
      <span *ngIf="pattern4Error" class="change-password-valid-error">
        {{'PROFILE_PAGE.ATELEASE_ONE_NUMBER' | i18nTranslate}}</span
      >
      <span *ngIf="!pattern5Error" class="change-password-valid-success">
        {{'PROFILE_PAGE.ALTEAST_ONE_SPL_CHARACTER' | i18nTranslate}}</span
      >
      <span *ngIf="pattern5Error" class="change-password-valid-error">
        {{'PROFILE_PAGE.ALTEAST_ONE_SPL_CHARACTER' | i18nTranslate}}</span
      >
    </div>
  </div>
  <div class="password-change-input-field-margin">
    <span class="filterTitle">{{'PROFILE_PAGE.CONFIRM_NEW_PASS' | i18nTranslate}}</span>
    <div class="text-div">
      <input
        type="text"
        type="{{ showPassword3 ? 'text' : 'password' }}"
        (input)="onConfirm()"
        [(ngModel)]="confirmPassword"
        (focus)="enterNewPassword = false"
        (keyup.enter)="update()"
      />
      <div
        *ngIf="showPassword3"
        class="password-change-input-sho-hide-text"
        (click)="show3()"
      >
        Hide
      </div>
      <div
        *ngIf="!showPassword3"
        class="password-change-input-sho-hide-text"
        (click)="show3()"
      >
        Show
      </div>
    </div>
    <div
      style="color: red; font-size: 10px; line-height: 12px"
      *ngIf="confirmPasswordError"
    >
      <em>{{ confirmPasswordErrorValue | i18nDynamicTranslate : 'POS_PAGE' }}</em>
    </div>
    <div style="color: red; font-size: 10px; line-height: 12px"
      *ngIf="confirmPassword === '' && updatePassword ===true">
      <em>{{'PROFILE_PAGE.FIELD_REQ' | i18nTranslate}}</em>
    </div>
  </div>
  <div class="confirm-password-button-container">
    <div
      (click)="dialogRef.close()"
      class="secondary-button-border"
      data-widget-name="PROFILE"
      data-button-name="Canceled: Password update"
    >
      {{'PROFILE_PAGE.CANCEL' | i18nTranslate}}
    </div>
    <div
      class="primary-button"
      (click)="update()"
      data-widget-name="PROFILE"
      data-button-name="Confirm: Password update"
    >
      {{'PROFILE_PAGE.CONFIRM' | i18nTranslate}}
    </div>
  </div>
</div>