
export class AuthLoginInfo {
    username: string;
    password: string;
    posProvider: string;
    posAuthentication: string;
    platformGenerated:string;

    constructor(username: string, password: string, posProvider: string, posAuthentication: string,platformGenerated:string) {
        this.username = username.toLowerCase();
        this.password = password;
        this.posProvider = posProvider;
        this.posAuthentication = posAuthentication;
        this.platformGenerated =platformGenerated;
    }
}
