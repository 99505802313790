<div class="qb-titleDiv">
  <div class="qb-d-flex qb-jc-flex-start">
    <mat-dialog-actions>
      <button class="qb-xIcon" mat-dialog-close><div class="material-icons qb-clear-icon">clear</div></button>
    </mat-dialog-actions>
    </div>
    <div class="qb-filter-div">
      <div class="qb-title">{{'CUSTOMER_PAGE.FILTERS' | i18nTranslate}}</div>
      <div *ngIf="filters['segments'].length > 0" class="qb-clear-all" (click)="clear()">{{'CUSTOMER_PAGE.CLEAR_ALL' | i18nTranslate}}</div>
      </div>
</div>
<div class="qb-filter qb-lastFilter">
  <p><strong class="qb-fs-13" style="text-transform: uppercase;">{{'CUSTOMER_PAGE.SEGMENTS' | i18nTranslate}}</strong></p>
  <div *ngIf="loading" class="qb-d-flex qb-jc-center">
  <mat-spinner diameter="30"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <p><mat-checkbox color="primary" [checked]="filters['segments'].indexOf('ALL') != -1" value="ALL" (change)="addSegmentFilter('ALL')"></mat-checkbox><span class="qb-ml-9">{{'CUSTOMER_PAGE.ALL' | i18nTranslate}}</span></p>
    <p *ngFor="let item of segments | keyvalue">
      <mat-checkbox color="primary" [checked]="filters['segments'].indexOf(item.key) != -1 " value={{item.key}} (change)="addSegmentFilter(item.key)"></mat-checkbox><span class="qb-ml-9">{{item.value}}</span>
    </p>
  </div>
</div>
<div>
  <div class="qb-buttons">
    <mat-dialog-actions>
    <button class="qb-clear secondary-button-border" mat-dialog-close>{{'CUSTOMER_PAGE.CANCEL' | i18nTranslate}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button class="qb-apply primary-button" (click)="applyFilter()" mat-dialog-close>{{'CUSTOMER_PAGE.APPLY' | i18nTranslate}}</button>
    </mat-dialog-actions>
  </div>
</div>