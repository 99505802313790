import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../ecommerce-dialog/ecommerce-dialog.component';

@Component({
  selector: 'app-not-deleted-list-dialog-box',
  templateUrl: './not-deleted-list-dialog-box.component.html',
  styleUrls: ['./not-deleted-list-dialog-box.component.css']
})
export class NotDeletedListDialogBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NotDeletedListDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

}
