import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'widgetConfig'
})
export class WidgetConfig implements PipeTransform {

  transform(widgets, widgetId): any {
    let index = widgets?.findIndex(d => d.widget.id === widgetId);
    if(index > -1){
        return true;
    }
    else{
        return false;
    }
  }
}