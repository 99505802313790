import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-analytics-chat-gpt',
  templateUrl: './analytics-chat-gpt.component.html',
  styleUrls: ['./analytics-chat-gpt.component.css']
})
export class AnalyticsChatGptComponent implements OnInit {

  constructor(private dailogRef: MatDialogRef<AnalyticsChatGptComponent>, private apiCallService: ApiCallService, private _i18nDynamicTranslate: TranslateDynamicText) { }

  showDropdown = false;
  allTags = ['Merchant Order'];
  selectedTags = 'Select';
  questionError = "";
  question = "";
  data = {text:"", params:{}};
  params = {"customer name": "{{Customer Name}}", "promo code": "{{Promo Code}}", "product type": "{{Product Type}}", "product benefit": "{{Product Benefit}}", "discount": "{{Discount}}"}
  sampleEmailText = {};
  loadingSample = false;
  question2 = "";

  ngOnInit(): void {
  }

  selectTag(tag) {
    this.selectedTags = tag;  
    this.showDropdown = false;
  }

  generate() {
    this.sampleEmailText = {};
    this.question2 = "";
    if(this.question === "") {
      this.questionError = this._i18nDynamicTranslate.transform("This field is required", ['SHARED_PAGE'])
    } else {
      this.loadingSample = true;
      this.questionError =  "";
      this.apiCallService.getAIAnalytics(this.question).subscribe(response => {
        // this.sampleEmailText = response[0][Object.keys(response[0])[0]];
        
        this.sampleEmailText = JSON.parse(response['body'])

        // this.convertObject(JSON.parse(JSON.stringify(response)));
        // if(typeof(this.sampleEmailText) === 'number'){
        //   console.log("TESTING")
        //  this.sampleEmailText= Math.round(Number(this.sampleEmailText) * 100) / 100;
        // }
        this.question2 = this.question;
        this.loadingSample = false;
      })
    }
  }
  convertObject(data){
    let value =[];
    
    
    let temp = []
    // for(let i in data[0]) {
    //   temp.push(i);
    // }
    // value.push(temp);
    for (let i in data){
      let temp=[];
      let k=[];
      let j: any={};
      for (let key in data[i]) {
        // temp.push(data[i][key]);
        // console.log(key)
        // console.log(data[i][key])
        j.name = key;
        j.value = data[i][key];
        console.log("VALUEEE_OF_J", j)  
        // k.def = j;
        k.push(j);
        console.log("VALUEEE_OF_KK", k)  

      }
      value.push(k);
      // j ={};
    }
    let feedbacks = [];
for(let i=0;i<data.length;i++){
 
        for (let key in data[i]) {
          let feedback: any = {};
          feedback["name"] = key;
          feedback["value"] = data[key];
          feedbacks.push(feedback);
        }
console.log(feedbacks)
}
 
      
     
    console.log("VALUEEE", value)
    return value
  }

  close() {
    this.dailogRef.close();
  }

}
