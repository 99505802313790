<head>
    <meta charset="utf-8">
      <title> Marketing Automation Platform | Hyper-Personalized Marketing Platform </title>
      <meta name="description" content="Onboarding">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
<div class="qb-d-block qb-w-100" style="overflow: hidden; height: 100svh;">
    <div class="qb-row-fluid qb-dnd-section">
        <div class="qb-dnd-column">
            <div>
                <div class="qb-w-100">
                    <div class="qb-d-flex qb-ta-center">
                        <img [src]="logoUrlIcon" alt="" style="height: 50px; object-fit: contain;" *ngIf="(status === 'INACTIVE')">
                        <h1 class="qb-header-wrapper">Choose From Our Business Friendly Plans</h1>
                        <div class="qb-d-flex">
                            <div class="qb-formDivSchedule qb-w-25 cursor-pointer" style="margin: 10px;"
                            (click)="selectOpenClose('currencySelect1')">
                                <mat-select #currencySelect1 [(ngModel)]="currency1" (selectionChange)="changeCurrencySelection('currencySelect1')"  class="qb-fontExo2"
                                color="primary"
                                disableOptionCentering panelclass="qb-myPanelClass">
                                    <mat-option value="INR">
                                        {{'INR' | currencySymbol}}  IN
                                    </mat-option>
                                    <mat-option value="USD">
                                        {{'USD' | currencySymbol}} US
                                    </mat-option>
                                </mat-select>
                                <div class="qb-keyboard-down-icon-container qb-downward-arrow" id="keyboard-down-icon-container"
                                    (click)="selectOpenClose('currencySelect1')">
                                        <mat-icon class="
                                            qb-keyboard-down-icon
                                            mat-icon
                                            notranslate
                                            material-icons
                                            mat-icon-no-color
                                        " role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: center; justify-content:space-between; margin-bottom: 10px;" *ngIf="!isChangePlan && !isTrialPlan && (status === 'INACTIVE')">
                                <!-- <div><span *ngIf="userName"
                              style="text-align: left; font: 100 16px Gravity; letter-spacing: 0; color: #BEBEBE; opacity: 1; user-select: none; font-family:'Open sans';">{{userName}}</span>
                          </div> -->
                                <div class="headerIcons2 ">
                                    <div class="headerUserIcon gradient " [matMenuTriggerFor]="menu " style="cursor:pointer; ">
                                        <span style="font-family:'Open sans';" >{{userName[0]}}</span>
                                    </div>
                                </div>
                                <mat-menu #menu="matMenu">
                                    <div class="logout-button" (click)="logout() ">Logout</div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="qb-d-block qb-scroll-container" [ngClass]="{ active: isTrialPlan}">
        <div class="qb-contacts-container">
            <span class="qb-contacts-text">
                Select a contact amount:
            </span>
            <div class="qb-p-relative">
                <select (change)="rangeChange($event)" id="contacts" [(ngModel)]="selectedRange" class="qb-contact-select-div">
                   <option  *ngFor="let data of contactsRange" [value]="data">{{data}}</option>
                </select>
                <div class="qb-indicator"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false" aria-hidden="true" class="wink-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 10.707l1.414-1.414L12 13.586l4.293-4.293 1.414 1.414L12 16.414l-5.707-5.707z"></path></svg></div>
            </div>
        </div>
        <div class="qb-w-100 qb-d-flex qb-dnd-section">
            <!-- <div class="qb-w-31" style="margin-left: 20px;" *ngFor="let plan of planDetails | keyvalue">
                <div class="qb-ta-center qb-card qb-card-padding">
                    <div class="qb-card-header">
                        <h4 class="qb-card-heading">
                            {{plan['planName']}}
                        </h4>
                        <p class="qb-card-subtitle">
                            Ideal for QSRs, Restaurants and Retail outlets with less than 10 outlets
                        </p>
                    </div>
                    <hr>
                    <div class="qb-card-body">
                        <ul style="padding: 0;">
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    {{plan['emailCredits']}} Emails
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    {{plan['smsCredits']}} Domestic SMS
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Unlimited Push Notifications
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Product Recommendations
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Personalized Offer Creation
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    POS Integration
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Mobile App Integration
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Customer Segmentation
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Email & SMS Journeys
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Analytics & Dashboards
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Customer Journeys
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Push Notifications
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    NLP based Survey Analysis
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Dedicated Account Manager
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Knowledge Base Access
                                </div>
                            </li>
                        </ul>
                        <hr>
                        <div class="qb-card-price">
                            <div class="qb-d-flex" style="justify-content: space-around;">
                                <p>
                                    {{ plan['price'][region] | customCurrency: currency1: true}}/mo
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="qb-add-icon-position">
                                <div class="primary-button qb-add-group-button" (click)="buyPlan(plan)" *ngIf="status !== 'FRESH'">
                                  <span data-button-name="Add New Group">Buy Now</span>
                                </div>
                                <div class="primary-button qb-add-group-button-trial" (click)="buyPlan(plan)" *ngIf="status === 'FRESH'">
                                    <span data-button-name="Add New Group">Try free for 15 days</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="qb-w-31" style="margin-left: 20px;">
                <div class="qb-ta-center qb-card qb-card-padding">
                    <div class="qb-card-header">
                        <h4 class="qb-card-heading">
                            Enterprise Fortune
                        </h4>
                        <p class="qb-card-subtitle">
                            For Large Enterprises with more than 25 outlets or 50,000 customers                        </p>
                    </div>
                    <hr>
                    <div class="qb-card-body">
                        <ul style="padding: 0;">
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Customized Emails & SMS
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Unlimited Push Notifications
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Product Recommendations
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Personalized Offer Creation
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    POS Integration
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Mobile App Integration
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Customer Segmentation
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Email & SMS Journeys
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Analytics & Dashboards
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Customer Journeys
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Push Notifications
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    NLP based Survey Analysis
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Dedicated Account Manager
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Knowledge Base Access
                                </div>
                            </li>
                            <li class="qb-d-flex" style="padding: 10px;"> 
                                <span class="material-icons-outlined qb-done-icon">
                                     done
                                </span>
                                <div class="qb-mt-3">
                                    Solution Consultant Support
                                </div>
                            </li>
                        </ul>
                        <hr>
                        <div>
                            <div class="qb-add-icon-position" style="padding: 29px;">
                                <div class="primary-button qb-add-group-button" (click)="buyPlan(0 ,'contactUs')">
                                  <span data-button-name="Add New Group">Contact Us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="accordion-scroll-container">
                <div class="objectives-card-offer" *ngFor="let plan of planDetails">
                    <div class="qb-ta-center qb-card qb-card-padding">
                        <div class="qb-card-header">
                            <h4 class="qb-card-heading">
                                {{plan['planName']}}_{{plan['contacts']}}
                            </h4>
                            <p class="qb-card-subtitle">
                                Ideal for QSRs, Restaurants and Retail outlets with less than {{plan['contacts']}} customer
                            </p>
                        </div>
                        <hr>
                        <div class="qb-card-body">
                            <ul style="padding: 0;">
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        {{plan['emailCredits']}} Emails
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;" *ngIf="plan['smsCredits'] !== null"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        {{plan['smsCredits']}} Domestic SMS
                                    </div>
                                </li>
                                <!-- <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Unlimited Push Notifications
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Product Recommendations
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Personalized Offer Creation
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        POS Integration
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Mobile App Integration
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Customer Segmentation
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Email & SMS Journeys
                                    </div>
                                </li> -->
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Analytics & Dashboards
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Customer Journeys
                                    </div>
                                </li>
                                <!-- <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Push Notifications
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        NLP based Survey Analysis
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Dedicated Account Manager
                                    </div>
                                </li> -->
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Knowledge Base Access
                                    </div>
                                </li>
                            </ul>
                            <hr>
                            <div class="qb-card-price">
                                <div class="qb-d-flex" style="justify-content: space-around;">
                                    <p>
                                        {{ plan['price'][region] | customCurrency: currency1: true}}/mo
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div class="qb-add-icon-position">
                                    <div class="primary-button qb-add-group-button" (click)="buyPlan(plan)">
                                      <span data-button-name="Add New Group">Buy Now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="objectives-card-offer">
                    <div class="qb-ta-center qb-card qb-card-padding">
                        <div class="qb-card-header">
                            <h4 class="qb-card-heading">
                                Enterprise Plan
                            </h4>
                            <p class="qb-card-subtitle">
                                For Large Enterprises</p>
                        </div>
                        <hr>
                        <div class="qb-card-body">
                            <ul style="padding: 0;">
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Customized Emails & SMS
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Unlimited Push Notifications
                                    </div>
                                </li>
                                <!-- <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Product Recommendations
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Personalized Offer Creation
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        POS Integration
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Mobile App Integration
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Customer Segmentation
                                    </div>
                                </li> -->
                                <!-- <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Email & SMS Journeys
                                    </div>
                                </li> -->
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Analytics & Dashboards
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Customer Journeys
                                    </div>
                                </li>
                                <!-- <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Push Notifications
                                    </div>
                                </li> -->
                                <!-- <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        NLP based Survey Analysis
                                    </div>
                                </li> -->
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Dedicated Account Manager
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Knowledge Base Access
                                    </div>
                                </li>
                                <li class="qb-d-flex" style="padding: 10px;"> 
                                    <span class="material-icons-outlined qb-done-icon">
                                         done
                                    </span>
                                    <div class="qb-mt-3">
                                        Solution Consultant Support
                                    </div>
                                </li>
                            </ul>
                            <hr>
                            <div>
                                <div class="qb-add-icon-position" style="padding: 29px;">
                                    <div class="primary-button qb-add-group-button" (click)="buyPlan(0 ,'contactUs')">
                                      <span data-button-name="Add New Group">Contact Us</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>