<div class="main">
  <div class="onboarding-div" *ngIf="!emailVerified">
    <div class="onboarding-header">
      <span class="text" *ngIf="retryAllowed">{{emailVerificationMessage}} <u class="blue-text" *ngIf="retryAllowed"
          (click)="resendVerificationEmail()">{{'POS_PAGE.RESEND_VERIFICATION_EMAIL' | i18nTranslate}}</u></span>
      <span class="text" *ngIf="!retryAllowed">{{emailVerificationMessage.split(".")[0]}}.
        {{emailVerificationMessage.split(".")[1]}}. <span
          class="red-text">{{emailVerificationMessage.split(".")[2]}}.</span></span>
    </div>
  </div>

  <div class="notification-div" *ngIf="showEmailSentMessage">
    <div id="notification" class="notification">
      <!-- <mat-icon class="notification-done-icon">done</mat-icon> -->
      <span class="notificationn-content">{{'POS_PAGE.VERIFICATION_RESENT_SUCESS' | i18nTranslate}}</span>
      <!-- <mat-icon class="notification-close-icon" (click)="hideNotification()">close</mat-icon> -->
    </div>
  </div>

  <div *ngIf="!isOnboardingCheckApiLoaded" style="height: 100%; justify-content: center;" class="obj-card-text-container">
    <app-qubriux-loading  [size]="75"></app-qubriux-loading>
  </div>
  <div class="heading-margins" *ngIf="selectedTab === 'Objectives' && isOnboardingCheckApiLoaded">
    <h3 class="module-heading">Objectives</h3>

  </div>
  <div class="obj-accordion-content-wrapper" *ngIf="selectedTab === 'Objectives' &&!isOnboardingStepsCompleted&&isOnboardingCheckApiLoaded" [ngClass]="{
    'qb-pointer-events-none': (athorities | checkIfCustomerCare)
}">
    <div class="obj-accordion-content" [ngClass]="one==two? 'accordion-scroll-wrapper': ''">
      <div class="accordion-content-cards-container">
        <div class="accordion-scroll-container"  style="padding: 0% 2%;"
          [ngClass]="one==two? 'accordion-scroll-active': ''">

          <div class="objectives-card" 
            [ngClass]="one==two? 'objective-completed':''">
            <div class="objectives-card-bg"></div>
            <div class="card-content-container">
              <!-- <div class="card-icon-wrapper">
                <img class="obj-card-icon"src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/registration/add-new-store.png" alt="Card Icon">
              </div> -->
              <div class="card-icon-wrap">
                <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="61" cy="61" r="61" fill="#E5E4FF"/>
                  <path d="M38.8125 56C37.25 56 36 54.75 36 53.1875C36 52.5625 36.1562 52.0156 36.4688 51.5469L42.3281 42.7188C43.0312 41.625 44.2031 41 45.4531 41H76.4688C77.7188 41 78.8906 41.625 79.5938 42.7188L85.4531 51.5469C85.7656 52.0156 86 52.5625 86 53.1875C86 54.75 84.6719 56 83.1094 56H38.8125ZM41 58.5H46V71H61V58.5H66V71V77.25C66 79.3594 64.2812 81 62.25 81H44.75C42.6406 81 41 79.3594 41 77.25V71V58.5ZM76 58.5H81V78.5C81 79.9062 79.8281 81 78.5 81C77.0938 81 76 79.9062 76 78.5V58.5Z" fill="#8280FF"/>
                  </svg>                  
              </div>
              <div class="obj-card-text-container">
                <h4 class="obj-card-title" (click)="onObjectiveCardClick('addStore')">Link your store</h4>
                <p class="obj-card-desc">
                  <span class="nowrap-text">Enhance Your Store Management Experience:</span><br>Seamlessly add new stores or link existing ones with our intuitive tools. Stay organized and in control with ease!
                </p>
              </div>
              <button *ngIf="!onboardingSteps?.['storeAdditionCompleted']" class="obj-btn"
                [ngClass]="j===0? 'obj-primary-btn': 'obj-secondary-btn'"
                (click)="onObjectiveCardClick('addStore')">Add stores</button>
                <svg *ngIf="onboardingSteps?.['storeAdditionCompleted']" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.5312 1.48438L20.0859 8.75781L27.9062 9.90625C28.5625 10.0156 29.1094 10.4531 29.3281 11.1094C29.5469 11.7109 29.3828 12.4219 28.8906 12.8594L23.2031 18.4922L24.5703 26.4766C24.6797 27.1328 24.4062 27.7891 23.8594 28.1719C23.3125 28.6094 22.6016 28.6094 22 28.3359L15 24.5625L7.94531 28.3359C7.39844 28.6094 6.6875 28.6094 6.14062 28.1719C5.59375 27.7891 5.32031 27.1328 5.42969 26.4766L6.74219 18.4922L1.05469 12.8594C0.617188 12.4219 0.453125 11.7109 0.617188 11.1094C0.835938 10.4531 1.38281 10.0156 2.03906 9.90625L9.91406 8.75781L13.4141 1.48438C13.6875 0.882812 14.2891 0.5 15 0.5C15.6562 0.5 16.2578 0.882812 16.5312 1.48438Z" fill="#7CE1B0"/>
                  </svg>
                  
            </div>
          </div>

          <div class="objectives-card"
          [ngClass]="one==two? 'objective-completed':''" *ngIf="this.tokenStorage.getMerchantOnlineStore()!='shopify'">
          <div class="objectives-card-bg"></div>
          <div class="card-content-container">
            <!-- <div class="card-icon-wrapper">
              <img class="obj-card-icon"src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/dashboard-assets/demo.png" alt="Card Icon">
            </div> -->
            <div class="card-icon-wrap">
              <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="61" cy="61" r="61" fill="#F7ECD2"/>
                <path d="M44 46H79C81.7344 46 84 48.2656 84 51V56C84 56.7031 83.375 57.25 82.75 57.4844C81.2656 58.0312 80.25 59.3594 80.25 61C80.25 62.6406 81.2656 64.0469 82.75 64.5938C83.375 64.8281 84 65.375 84 66V71C84 73.8125 81.7344 76 79 76H44C41.1875 76 39 73.8125 39 71V66C39 65.375 39.5469 64.8281 40.1719 64.5938C41.6562 64.0469 42.75 62.6406 42.75 61C42.75 59.3594 41.6562 58.0312 40.1719 57.4844C39.5469 57.25 39 56.7031 39 56V51C39 48.2656 41.1875 46 44 46ZM49 54.75C49 54.125 49.5469 53.5 50.25 53.5H72.75C73.375 53.5 74 54.125 74 54.75V67.25C74 67.9531 73.375 68.5 72.75 68.5H50.25C49.5469 68.5 49 67.9531 49 67.25V54.75ZM46.5 53.5V68.5C46.5 69.9062 47.5938 71 49 71H74C75.3281 71 76.5 69.9062 76.5 68.5V53.5C76.5 52.1719 75.3281 51 74 51H49C47.5938 51 46.5 52.1719 46.5 53.5Z" fill="#FEC53D"/>
                </svg>
                
            </div>
            <div class="obj-card-text-container">
              <h4 class="obj-card-title">Select plan</h4>
              <p class="obj-card-desc">Tailored Just for You:<br>Find your ideal plan from a selection designed to fit your unique goals and aspirations.
              </p>
            </div>
            <button *ngIf="!onboardingSteps?.['planAdditionCompleted']" class="obj-btn"
              [ngClass]="j===0? 'obj-primary-btn': 'obj-secondary-btn'"
              (click)="onObjectiveCardClick('planSelect')">Add Plan</button>
              <svg *ngIf="onboardingSteps?.['planAdditionCompleted']" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5312 1.48438L20.0859 8.75781L27.9062 9.90625C28.5625 10.0156 29.1094 10.4531 29.3281 11.1094C29.5469 11.7109 29.3828 12.4219 28.8906 12.8594L23.2031 18.4922L24.5703 26.4766C24.6797 27.1328 24.4062 27.7891 23.8594 28.1719C23.3125 28.6094 22.6016 28.6094 22 28.3359L15 24.5625L7.94531 28.3359C7.39844 28.6094 6.6875 28.6094 6.14062 28.1719C5.59375 27.7891 5.32031 27.1328 5.42969 26.4766L6.74219 18.4922L1.05469 12.8594C0.617188 12.4219 0.453125 11.7109 0.617188 11.1094C0.835938 10.4531 1.38281 10.0156 2.03906 9.90625L9.91406 8.75781L13.4141 1.48438C13.6875 0.882812 14.2891 0.5 15 0.5C15.6562 0.5 16.2578 0.882812 16.5312 1.48438Z" fill="#7CE1B0"/>
                </svg>


          </div>
        </div>

        <div class="objectives-card"
        [ngClass]="one==two ?'objective-completed':''">
        <div class="objectives-card-bg"></div>
        <div class="card-content-container">
          <!-- <div class="card-icon-wrapper">
            <img class="obj-card-icon"src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/dashboard-assets/demo.png" alt="Card Icon">
          </div> -->
          <div class="card-icon-wrap">
            <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="61" cy="61" r="61" fill="#D1F0E4"/>
              <path d="M55.375 44.75C54.9844 44.75 54.75 45.0625 54.75 45.375V48.5H67.25V45.375C67.25 45.0625 66.9375 44.75 66.625 44.75H55.375ZM51 45.375C51 43.0312 52.9531 41 55.375 41H66.625C68.9688 41 71 43.0312 71 45.375V48.5H76C78.7344 48.5 81 50.7656 81 53.5V61H66H56H41V53.5C41 50.7656 43.1875 48.5 46 48.5H51V45.375ZM81 63.5V73.5C81 76.3125 78.7344 78.5 76 78.5H46C43.1875 78.5 41 76.3125 41 73.5V63.5H56V66C56 67.4062 57.0938 68.5 58.5 68.5H63.5C64.8281 68.5 66 67.4062 66 66V63.5H81Z" fill="#4AD991"/>
              </svg>
              
          </div>
          <div class="obj-card-text-container">
            <h4 class="obj-card-title">Update Branding</h4>
            <p class="obj-card-desc">A Picture's Worth a Thousand Words:<br>Upload your store logo to instantly communicate your brand's essence. Combine it with social media links to foster a thriving online community.
            </p>
          </div>
          <button *ngIf="!onboardingSteps?.['brandingAdditionCompleted']" class="obj-btn"
            [ngClass]="1==0? 'obj-primary-btn': 'obj-secondary-btn'"
            (click)="onObjectiveCardClick('branding')">Branding</button>
            <svg *ngIf="onboardingSteps?.['brandingAdditionCompleted']" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5312 1.48438L20.0859 8.75781L27.9062 9.90625C28.5625 10.0156 29.1094 10.4531 29.3281 11.1094C29.5469 11.7109 29.3828 12.4219 28.8906 12.8594L23.2031 18.4922L24.5703 26.4766C24.6797 27.1328 24.4062 27.7891 23.8594 28.1719C23.3125 28.6094 22.6016 28.6094 22 28.3359L15 24.5625L7.94531 28.3359C7.39844 28.6094 6.6875 28.6094 6.14062 28.1719C5.59375 27.7891 5.32031 27.1328 5.42969 26.4766L6.74219 18.4922L1.05469 12.8594C0.617188 12.4219 0.453125 11.7109 0.617188 11.1094C0.835938 10.4531 1.38281 10.0156 2.03906 9.90625L9.91406 8.75781L13.4141 1.48438C13.6875 0.882812 14.2891 0.5 15 0.5C15.6562 0.5 16.2578 0.882812 16.5312 1.48438Z" fill="#7CE1B0"/>
              </svg>
        </div>
      </div>


      </div>

        </div>
      </div>
      <div *ngIf="1==1" class="scroll-controls-wrapper">
        <div class="scroll-left-btn scroll-btn" (click)="handleCardsScroll('left')"
          [ngClass]="objectivesScrollIndex>0? 'scroll-btn-active':''">
          <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.1095 24.1095C12.5888 24.6302 11.7446 24.6302 11.2239 24.1095L0.557191 13.4428C0.0364921 12.9221 0.0364921 12.0779 0.557191 11.5572L11.2239 0.890525C11.7446 0.369825 12.5888 0.369825 13.1095 0.890525C13.6302 1.41122 13.6302 2.25544 13.1095 2.77614L3.38562 12.5L13.1095 22.2239C13.6302 22.7446 13.6302 23.5888 13.1095 24.1095Z"
              fill="black" />
          </svg>
        </div>
        <div class="scroll-right-btn scroll-btn" (click)="handleCardsScroll('right')"
          [ngClass]="objectivesScrollIndex<1? 'scroll-btn-active':''">
          <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.890524 0.890524C1.41122 0.369825 2.25544 0.369825 2.77614 0.890524L13.4428 11.5572C13.9635 12.0779 13.9635 12.9221 13.4428 13.4428L2.77614 24.1095C2.25544 24.6302 1.41122 24.6302 0.890524 24.1095C0.369825 23.5888 0.369825 22.7446 0.890524 22.2239L10.6144 12.5L0.890524 2.77614C0.369825 2.25544 0.369825 1.41122 0.890524 0.890524Z"
              fill="black" />
          </svg>
        </div>
      </div>
    </div>


  <div *ngIf="validWidgets" style="padding-bottom: 20px"[ngClass]="{
    'qb-pointer-events-none': (athorities | checkIfCustomerCare)
}">
    <!-- <div *ngIf="objectivesLoading" style="
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
    ">
     <app-qubriux-loading  [size]="25"></app-qubriux-loading>
    </div> -->
    <div class="app-dashboard-analytics-container">
      <div *ngIf="(selectedTab === 'Objectives' || selectedTab === '') && showObjective" data-widget-name="RECOMMENDED ACTIONS">
        <div class="objectives-wrapper">
          <div class="obj-accordion-wrapper" *ngFor="let accordion of objectivesAccordion; let i = index"
            [ngClass]="accordion.isClosed? 'obj-accordion-closed-wrapper': ''">
            <div class="obj-accordion-header" (click)="handleAccordionStatus(i)">
              <div class="obj-header-title">
                <div class="accordion-icon">
                  <img [src]="accordion.header.icon" alt="accordion illustration">
                </div>
                <div class="sub-header">
                  <h3 class="header-title">{{accordion.content_title | i18nDynamicTranslate : 'POS_PAGE'}}</h3>
                </div>
              </div>
              <div class="obj-header-cta">
                <svg 
                *ngIf="accordion.header.showCompletedBtn" class="obj-completed-svg"
                  [ngClass]="accordion.header.isCompleted? 'obj-completed-svg-active':''"
                width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 24C8.6875 24 4.75 21.75 2.59375 18C0.4375 14.2969 0.4375 9.75 2.59375 6C4.75 2.29688 8.6875 0 13 0C17.2656 0 21.2031 2.29688 23.3594 6C25.5156 9.75 25.5156 14.2969 23.3594 18C21.2031 21.75 17.2656 24 13 24ZM18.2969 9.79688H18.25C18.7188 9.375 18.7188 8.67188 18.25 8.20312C17.8281 7.78125 17.125 7.78125 16.7031 8.20312L11.5 13.4531L9.29688 11.25C8.82812 10.7812 8.125 10.7812 7.70312 11.25C7.23438 11.6719 7.23438 12.375 7.70312 12.7969L10.7031 15.7969C11.125 16.2656 11.8281 16.2656 12.2969 15.7969L18.2969 9.79688Z" fill="#00B69B"/>
                  </svg>
              </div>
            </div>







            <div class="obj-accordion-content-wrapper">
              <div class="obj-accordion-content" [ngClass]="accordion.isScrollable? 'accordion-scroll-wrapper': ''">
                <!-- <h3 class="accordion-content-title">{{accordion.content_title}}</h3> -->
                <div class="accordion-content-cards-container">
                  <div class="accordion-scroll-container"
                    [ngClass]="accordion.isScrollable? 'accordion-scroll-active': ''">

                    <div *ngFor="let card of accordion.cards; let j=index" class="objectives-card"
                      [ngClass]="card.isCompleted? 'objective-completed':''">
                      <div [ngStyle]="{backgroundColor: card.background}" class="objectives-card-bg"></div>
                      <div class="card-content-container">
                        <div class="card-icon-wrapper">
                          <img class="obj-card-icon" [src]="card.icon" alt="Card Icon">
                        </div>
                        <div class="obj-card-text-container">
                          <h4 class="obj-card-title">{{card.isCompleted? (card.completed_title | i18nDynamicTranslate : 'POS_PAGE') : (card.title | i18nDynamicTranslate : 'POS_PAGE')}}</h4>
                          <p class="obj-card-desc">{{card.isCompleted? (card.completed_description | i18nDynamicTranslate : 'POS_PAGE') : (card.description | i18nDynamicTranslate : 'POS_PAGE')}}
                          </p>
                        </div>
                        <button *ngIf="!card.isCompleted" class="obj-btn obj-primary-btn"
                          (click)="onObjectiveCardClick(card.clickAction)">{{card.cta_text | i18nDynamicTranslate : 'POS_PAGE'}}</button>
                          <svg *ngIf="card.isCompleted" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5312 1.48438L20.0859 8.75781L27.9062 9.90625C28.5625 10.0156 29.1094 10.4531 29.3281 11.1094C29.5469 11.7109 29.3828 12.4219 28.8906 12.8594L23.2031 18.4922L24.5703 26.4766C24.6797 27.1328 24.4062 27.7891 23.8594 28.1719C23.3125 28.6094 22.6016 28.6094 22 28.3359L15 24.5625L7.94531 28.3359C7.39844 28.6094 6.6875 28.6094 6.14062 28.1719C5.59375 27.7891 5.32031 27.1328 5.42969 26.4766L6.74219 18.4922L1.05469 12.8594C0.617188 12.4219 0.453125 11.7109 0.617188 11.1094C0.835938 10.4531 1.38281 10.0156 2.03906 9.90625L9.91406 8.75781L13.4141 1.48438C13.6875 0.882812 14.2891 0.5 15 0.5C15.6562 0.5 16.2578 0.882812 16.5312 1.48438Z" fill="#7CE1B0"/>
                            </svg>

                      </div>
                    </div>

                  </div>
                </div>
                <div *ngIf="accordion.isScrollable" class="scroll-controls-wrapper">
                  <div class="scroll-left-btn scroll-btn" (click)="handleCardsScroll('left')"
                    [ngClass]="objectivesScrollIndex>0? 'scroll-btn-active':''">
                    <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.1095 24.1095C12.5888 24.6302 11.7446 24.6302 11.2239 24.1095L0.557191 13.4428C0.0364921 12.9221 0.0364921 12.0779 0.557191 11.5572L11.2239 0.890525C11.7446 0.369825 12.5888 0.369825 13.1095 0.890525C13.6302 1.41122 13.6302 2.25544 13.1095 2.77614L3.38562 12.5L13.1095 22.2239C13.6302 22.7446 13.6302 23.5888 13.1095 24.1095Z"
                        fill="black" />
                    </svg>
                  </div>
                  <div class="scroll-right-btn scroll-btn" (click)="handleCardsScroll('right')"
                    [ngClass]="objectivesScrollIndex<1? 'scroll-btn-active':''">
                    <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.890524 0.890524C1.41122 0.369825 2.25544 0.369825 2.77614 0.890524L13.4428 11.5572C13.9635 12.0779 13.9635 12.9221 13.4428 13.4428L2.77614 24.1095C2.25544 24.6302 1.41122 24.6302 0.890524 24.1095C0.369825 23.5888 0.369825 22.7446 0.890524 22.2239L10.6144 12.5L0.890524 2.77614C0.369825 2.25544 0.369825 1.41122 0.890524 0.890524Z"
                        fill="black" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="selectedTab === 'Dashboard'" data-widget-name="QUICK INSIGHTS">
        <!-- <div style="font-size: 14px;margin: 10px 0 0 28px;">
        <span style="color: rgba(34, 34, 34, 0.6);">Home / </span><span><strong>Dashboard</strong></span>
      </div> -->
      <div class="heading-margins-dashboard" *ngIf="selectedTab === 'Dashboard'" >
        <h3 class="module-heading">Dashboard</h3>
      </div>
        <div>
          <div>
            <div class="main-header">
              <div style="display: flex; gap: 5px; align-items: center">
                <div style="display: flex; align-items: center; font-weight: 700; font-size: 16px;">
                  {{'POS_PAGE.QUICK_INSIGHT' | i18nTranslate}}
                </div>
                <div>
                  <div class="home-info-icon-container" id="home-info-icon-container-insights">
                    <span>
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                        </svg>
                    </span>
                    <div class="home-info-message-container">
                      <span>{{'POS_PAGE.QUICK_INSIGHT_INFO' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="home-insight-line-container">
                <div class="line"></div>
              </div>
              <div class="home-date-range-selector" (click)="openSelect()">
                <div>
                  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.25 0.75C4.71484 0.75 5.125 1.16016 5.125 1.625V2.5H8.625V1.625C8.625 1.16016 9.00781 0.75 9.5 0.75C9.96484 0.75 10.375 1.16016 10.375 1.625V2.5H11.6875C12.3984 2.5 13 3.10156 13 3.8125V5.125H0.75V3.8125C0.75 3.10156 1.32422 2.5 2.0625 2.5H3.375V1.625C3.375 1.16016 3.75781 0.75 4.25 0.75ZM0.75 6H13V13.4375C13 14.1758 12.3984 14.75 11.6875 14.75H2.0625C1.32422 14.75 0.75 14.1758 0.75 13.4375V6ZM2.5 8.1875V9.0625C2.5 9.30859 2.69141 9.5 2.9375 9.5H3.8125C4.03125 9.5 4.25 9.30859 4.25 9.0625V8.1875C4.25 7.96875 4.03125 7.75 3.8125 7.75H2.9375C2.69141 7.75 2.5 7.96875 2.5 8.1875ZM6 8.1875V9.0625C6 9.30859 6.19141 9.5 6.4375 9.5H7.3125C7.53125 9.5 7.75 9.30859 7.75 9.0625V8.1875C7.75 7.96875 7.53125 7.75 7.3125 7.75H6.4375C6.19141 7.75 6 7.96875 6 8.1875ZM9.9375 7.75C9.69141 7.75 9.5 7.96875 9.5 8.1875V9.0625C9.5 9.30859 9.69141 9.5 9.9375 9.5H10.8125C11.0312 9.5 11.25 9.30859 11.25 9.0625V8.1875C11.25 7.96875 11.0312 7.75 10.8125 7.75H9.9375ZM2.5 11.6875V12.5625C2.5 12.8086 2.69141 13 2.9375 13H3.8125C4.03125 13 4.25 12.8086 4.25 12.5625V11.6875C4.25 11.4688 4.03125 11.25 3.8125 11.25H2.9375C2.69141 11.25 2.5 11.4688 2.5 11.6875ZM6.4375 11.25C6.19141 11.25 6 11.4688 6 11.6875V12.5625C6 12.8086 6.19141 13 6.4375 13H7.3125C7.53125 13 7.75 12.8086 7.75 12.5625V11.6875C7.75 11.4688 7.53125 11.25 7.3125 11.25H6.4375ZM9.5 11.6875V12.5625C9.5 12.8086 9.69141 13 9.9375 13H10.8125C11.0312 13 11.25 12.8086 11.25 12.5625V11.6875C11.25 11.4688 11.0312 11.25 10.8125 11.25H9.9375C9.69141 11.25 9.5 11.4688 9.5 11.6875Z" fill="#202224"/>
                    </svg>                    
                </div>
                <div class="select-div-2">
                  <mat-select #select class="graph-select" [(ngModel)]="selectedGraphButton"
                    (selectionChange)="showGraphByTime()" [value]="selectedGraphButton">
                    <mat-option data-widget-name="QUICK INSIGHTS" data-button-name="All Time" value="All Time">{{'POS_PAGE.ALL_TIME' | i18nTranslate}}
                    </mat-option>
                    <mat-option data-widget-name="QUICK INSIGHTS" data-button-name="This Week" value="This Week">{{'POS_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
            <div class="graphs-card">
              <div *ngIf="validWidgets | widgetConfig: 1" class="home-revenue-insight-container">
                <div class="home-insight-card">
                  <div class="home-insight-header-container">
                    <div style="display: flex; gap: 10px">
                      <div class="graph-button" (click)="showLineGraph('revenue')" [ngClass]="{
                        active: selectedLineGraphData === 'revenue'
                      }" data-button-name="Revenue">
                        <div class="graph-button-text" [ngClass]="{active:selectedLineGraphData === 'revenue'}">{{'POS_PAGE.REVENUE' | i18nTranslate}}</div>
                      </div>
                      <div class="graph-button" (click)="showLineGraph('my customers')" [ngClass]="{
                        active: selectedLineGraphData === 'my customers'
                      }" data-button-name="My Customers">
                        <div class="graph-button-text" [ngClass]="{active:selectedLineGraphData === 'my customers'}">{{'POS_PAGE.MY_CUSTOMERS' | i18nTranslate}}</div>
                      </div>
                    </div>
                    <div style="display: flex; gap: 10px">
                      <!-- <div
                      *ngIf="revenueCountLoading || customerCountLoading"
                      style="
                        display: flex;
                        justify-content: center;
                        margin: 10px 45px;
                      "
                    >
                      <mat-spinner diameter="25"></mat-spinner>
                    </div> -->
                      <div style="display: flex; align-items: center"
                        *ngIf="!customerCountLoading">
                        <div class="customercount" *ngIf="
                          selectedLineGraphData === 'my customers' &&
                          !customerCountLoading
                        ">
                          {{ totalCustomerCount }}
                        </div>
                        <!-- <div class="customercount" *ngIf="
                          selectedLineGraphData === 'revenue' &&
                          !revenueCountLoading
                        ">
                          {{ revenue | customCurrency: revCurrency: true }}
                        </div> -->
                      </div>
                      <div *ngIf="selectedLineGraphData !='revenue'" class="home-view-insights-button"
                        (click)="goToPage('customers')" data-button-name="Revenue-My Customer View Detailed Insights">
                        {{'POS_PAGE.VIEW_DETAILED_INSIGHT' | i18nTranslate}}
                      </div>
                    </div>
                  </div>
                  <app-line-chart-homescreen [selectedLineGraphData]="selectedLineGraphData"
                    [selectedGraphButton]="selectedGraphButton">
                  </app-line-chart-homescreen>
                </div>
              </div>
              <div *ngIf="validWidgets | widgetConfig: 2" class="home-email-insight-container">
                <div class="home-insight-card" style="padding: 13px">
                  <div class="header" style="justify-content: space-between">
                    <div class="graph-button email-button">
                      <div class="graph-button-text email-button-text">
                        {{'POS_PAGE.EMAIL' | i18nTranslate}}
                      </div>
                    </div>
                    <div style="display: flex; align-items: center">
                      <div class="home-view-insights-button" (click)="goToPage('insights')"
                        data-button-name="Email-View Detailed Insights">
                        {{'POS_PAGE.VIEW_DETAILED_INSIGHT' | i18nTranslate}}
                      </div>
                    </div>
                  </div>
                  <!-- <div
                  *ngIf="insightsLoading"
                  style="display: flex; justify-content: center; margin-top: 20px"
                >
                  <mat-spinner diameter="25"></mat-spinner>
                </div> -->
                  <div>
                    <div>
                      <div class="home-number-of-email-text">
                        {{'POS_PAGE.NO_EMAILS_SENT' | i18nTranslate}}
                      </div>
                      <div *ngIf="!isDemo" style="font-size: 16px; font-weight: 700;">
                        {{ numberOfEmailSent }}
                      </div>
                      <div *ngIf="isDemo" style="font-size: 16px; font-weight: 700;">
                        200
                      </div>
                    </div>
                    <div>
                      <div style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                      ">
                        <div style="font-size: 14px; font-weight: 600;">
                          {{'POS_PAGE.OPEN_RATE_DELIVERED_EMAILS' | i18nTranslate}}
                        </div>
                        <div *ngIf="!isDemo" style="font-size: 16px; font-weight: 700">
                          {{ openRate }}%
                        </div>
                        <div *ngIf="isDemo" style="font-size: 16px; font-weight: 700">
                          72.5%
                        </div>
                      </div>
                      <div>
                        <mat-progress-bar *ngIf="!isDemo" [mode]="mode" [value]="openRate">
                        </mat-progress-bar>
                        <mat-progress-bar *ngIf="isDemo" [mode]="mode" value="72.5">
                        </mat-progress-bar>
                      </div>
                    </div>
                    <div>
                      <div style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 30px;
                      ">
                        <div style="font-size: 14px; font-weight: 600;">
                          {{'POS_PAGE.CLICK_RATE_OPENED_EMAIL' | i18nTranslate}}
                        </div>
                        <div *ngIf="!isDemo" style="font-size: 16px; font-weight: 700">
                          {{ clickedRate }}%
                        </div>
                        <div *ngIf="isDemo" style="font-size: 16px; font-weight: 700">
                          78.2%
                        </div>
                      </div>
                      <div>
                        <mat-progress-bar *ngIf="!isDemo" [mode]="mode" [value]="clickedRate">
                        </mat-progress-bar>
                        <mat-progress-bar *ngIf="isDemo" [mode]="mode" value="78.2">
                        </mat-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="graphs-card" style="margin-top: 30px;">
          <div *ngIf="validWidgets | widgetConfig: 3" data-widget-name="SURVEY ALERTS"
            class="home-survey-alert-container">
            <div class="header">
              <div style="display: flex; gap: 5px; align-items: center; font-size: 16px; font-weight: 700;">
                <div class="title">{{'POS_PAGE.SURVEY_ALERTS' | i18nTranslate}}</div>
                <div>
                  <div class="home-info-icon-container" id="home-info-icon-container-survey">
                    <span>
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                        </svg>
                        
                    </span>
                    <div class="home-info-message-container">
                      <span>{{'POS_PAGE.SURVEY_ALERTS_INFO' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="view-surveys-text" (click)="goToPage('surveys')" data-button-name="View Surveys">
                  {{'POS_PAGE.VIEW_SURVEYS' | i18nTranslate}}
                </div>
                <div *ngIf="surveyAlertsLength > 5" class="view-all-surveys-text"
                  (click)="routeTo('/app/survey-alerts-m')">
                  {{'POS_PAGE.VIEW_ALL' | i18nTranslate}}
                </div>
              </div>
            </div>
            <div class="home-insight-card-survey-lead">
              <div style="margin-top: 15px; height: 100%">
                <div style="position: relative; height: 100%">
                  <div class="table-header">
                    <div class="table-header-div date-header">
                      <div class="table-header-text">{{'POS_PAGE.DATE' | i18nTranslate}}</div>
                    </div>
                    <div class="home-content-table-seperator"></div>
                    <div class="table-header-div status">
                      <div class="table-header-text">{{'POS_PAGE.STATUS' | i18nTranslate}}</div>
                    </div>
                    <div class="home-content-table-seperator last-seperator"></div>
                    <div class="table-header-div survey-action">
                      <div class="table-header-text">{{'POS_PAGE.ACTION' | i18nTranslate}}</div>
                    </div>
                  </div>
                  <div *ngIf="alertsLoading" style="display: flex; justify-content: center">
                    <app-qubriux-loading  [size]="25"></app-qubriux-loading>
                  </div>
                  <div class="alerts-table">
                    <div *ngIf="surveyAlertsLength > 0">
                      <div style="margin-top: 16px" *ngFor="let surveyAlert of surveyAlerts['data']">
                        <div class="table-content">
                          <div class="date">{{ surveyAlert["date"] }}</div>
                          <div class="home-content-table-seperator"></div>
                          <div class="home-survey-status-content">
                            <div class="survey-title">
                              {{ surveyAlert["title"] }}
                            </div>
                            <div class="survey-description">
                              {{ surveyAlert["narration"] }}
                            </div>
                          </div>
                          <div class="home-content-table-seperator"></div>
                          <div class="action-buttons" *ngIf="appSubscriptionPlan">
                            <div class="action-button2" *ngIf="surveyAlert['type'] === 'EMAIL'"
                              (click)="performAction(surveyAlert['id'], 'email')" data-button-name="Action:Email"
                              [attr.data-item-name]="surveyAlert['id']">
                              <span class="material-icons icons-primary" style="font-size: 14px; margin-bottom: 1px">
                                email
                              </span>
                              <div class="action-type">{{'POS_PAGE.SEND_EMAIL' | i18nTranslate}}</div>
                            </div>
                            <div class="action-button2 action-button-campaign"
                              *ngIf="surveyAlert['type'] === 'CAMPAIGN'" (click)="
                              performAction(surveyAlert['id'], 'campaign')
                            " data-button-name="Action:Campaign" [attr.data-item-name]="surveyAlert['id']">
                              <span class="material-symbols-outlined" style="font-size: 14px; margin-bottom: 1px">
                                family_history
                              </span>
                              <div class="action-type">{{'POS_PAGE.ADD_TO_CAMPAIGN' | i18nTranslate}}</div>
                            </div>
                            <div class="action-button2" *ngIf="surveyAlert['type'] === 'OFFER'"
                              (click)="performAction(surveyAlert['id'], 'offer')" data-button-name="Action: Offer"
                              [attr.data-item-name]="surveyAlert['id']">
                              <span class="material-icons icons-primary" style="font-size: 14px; margin-bottom: 1px">
                                local_offer
                              </span>
                              <div class="action-type">{{'POS_PAGE.SEND_OFFER' | i18nTranslate}}</div>
                            </div>
                            <span class="material-icons icons-primary" style="
                              font-size: 18px;
                              cursor: pointer;
                              margin-top: 3px;
                            " [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" (click)="
                              deleteRecommendation(
                                surveyAlert['id'],
                                index,
                                surveyAlert['customerDetails'].length
                              )
                            " data-button-name="Delete">
                              delete
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="surveyAlertsLength == 0" style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 45%;
                      width: 100%;
                      margin-top: 22px;
                    ">
                      <div style="
                        padding: 10px;
                        border: 1px dotted #ff3b30;
                        width: 50%;
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px;
                      ">
                        {{'POS_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                      </div>
                    </div>
                  </div>
                  <mat-paginator class="survey-alert-paginator" style="position: absolute; width: 100%; bottom: 0"
                    [pageIndex]="surveyAlertsPageIndex" [length]="surveyAlertsLength" [pageSize]="surveyAlertsPageSize"
                    [pageSizeOptions]="[5, 10, 25, 100]" (page)="refreshPaginatedData($event, 'survey alerts')">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="validWidgets | widgetConfig: 4" class="home-leads-container">
            <div class="header">
              <div style="display: flex; gap: 5px; align-items: center; font-size: 16px; font-weight: 700;">
                <div class="title">{{'POS_PAGE.LEADS' | i18nTranslate}}</div>
                <div>
                  <div class="home-info-icon-container" id="home-info-icon-container-leads">
                    <span >
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                        </svg>
                        
                    </span>
                    <div class="home-info-message-container" style="left: -46px">
                      <span>{{'POS_PAGE.LEADS_INFO' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="leadsLength > 5" class="view-all-leads-text" (click)="routeTo('/app/leads-m')">
                {{'POS_PAGE.VIEW_ALL' | i18nTranslate}}
              </div>
            </div>
            <div class="home-insight-card-survey-lead">
              <div style="margin-top: 15px; height: 100%">
                <div style="position: relative; height: 100%">
                  <div class="table-header">
                    <div class="table-header-text" style="
                      width: 60%;
                      padding-left: 16px;
                      display: flex;
                      align-items: center;
                    ">
                      {{'POS_PAGE.NAME' | i18nTranslate}}
                    </div>
                    <div class="home-content-table-seperator"></div>
                    <div class="table-header-text status activity-title" style="
                      display: flex;
                      align-items: center;
                      padding-left: 16px;
                    ">
                      {{'POS_PAGE.ACTIVITY' | i18nTranslate}}
                    </div>
                  </div>
                  <div *ngIf="leadsLoading" style="
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                  ">
                   <app-qubriux-loading  [size]="25"></app-qubriux-loading>
                  </div>
                  <div class="leads-content" data-widget-name="LEADS">
                    <div *ngIf="leads.length > 0">
                      <div class="table-content" style="margin: 13px 0 20px 0" *ngFor="let lead of leads; index as i">
                        <div matTooltipShowDelay="500" matTooltip="{{ lead['customerName'] }}" class="leads-name name">
                          {{ lead["customerName"] }}
                        </div>
                        <div class="home-content-table-seperator"></div>
                        <div class="home-leads-view-button" (click)="getCustomerDetails(i)" data-button-name="View"
                          [attr.data-item-name]="lead['customerName']">
                          {{'POS_PAGE.VIEW' | i18nTranslate}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="leadsLength == 0" style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 45%;
                      width: 100%;
                      margin-top: 22px;
                    ">
                      <div style="
                        padding: 10px;
                        border: 1px dotted #ff3b30;
                        width: 50%;
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px;
                      ">
                        {{'POS_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                      </div>
                    </div>
                  </div>
                  <mat-paginator class="lead-paginator" style="position: absolute; width: 100%; bottom: 0"
                    [pageIndex]="leadsPageIndex" [length]="leadsLength" [pageSize]="leadsPageSize"
                    [pageSizeOptions]="[5, 10, 25, 100]" (page)="refreshPaginatedData($event, 'leads')">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
<div *ngIf="actionDiv">
  <div class="overlay"></div>
  <div class="actions">
    <div class="sendEmailDiv">
      <div class="sub-header">
        <div class="send-email-text" *ngIf="actionType === 'email'" style="text-transform: uppercase;">
          {{'POS_PAGE.SEND_EMAIL' | i18nTranslate}}
        </div>
        <div class="send-email-text" *ngIf="actionType === 'offer'" style="text-transform: uppercase;">
          {{'POS_PAGE.SEND_OFFER' | i18nTranslate}}
        </div>
        <div class="send-email-text" *ngIf="actionType === 'campaign'">
          {{'POS_PAGE.ADD_TO_CAMPAIGN' | i18nTranslate}}
        </div>
        <button [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.CLOSE' | i18nTranslate" class="material-icons xIcon icons-primary"
          (click)="actionDiv = false" style="
            width: 4.7%;
            margin: 25px 20px 10px 0;
            height: 19px;
            color: rgba(99, 100, 102, 0.5);
          ">
          clear
        </button>
      </div>

      <div style="min-height: 140px; max-height: 140px">
        <div class="choose-customers-div" id="choose-customers-div2"
          style="min-height: 83%; max-height: 83%; overflow: hidden">
          <div class="choose-customers-text">{{'POS_PAGE.CUSTOMERS' | i18nTranslate}}</div>
          <div class="input-div inp" id="customers-input"
            (click)="selectCustomers(); displayNotificationPopover($event)">
            <div class="input-text" *ngIf="selectedCustomers.length===0">{{'POS_PAGE.SELECT' | i18nTranslate}}</div>
            <div class="input-text" *ngIf="selectedCustomers.length!=0 && selectedCustomers[0] != 'All'">{{'POS_PAGE.SELECTED' | i18nTranslate}}:
              {{selectedCustomers.length}} </div>
            <div class="input-text" *ngIf="selectedCustomers.length!=0 && selectedCustomers[0] == 'All'">{{'POS_PAGE.SELECT_ALL' | i18nTranslate}}
            </div>
            <div class="arrow-div">
              <span class="material-icons icons-primary" style="color: black; font-size: 25px">
                expand_more
              </span>
            </div>
          </div>
          <div *ngIf="customersError" style="margin-left: 20px; color: #811620; font-size: 12px">
            {{'POS_PAGE.SELECTED_CUSTOMER_FIELD_REQ' | i18nTranslate}}
          </div>
          <div class="dropdown-div" *ngIf="showSelectCustomersDiv">
            <div class="search-div">
              <div style="width: 100%">
                <input #search type="text" placeholder="Search Customer" class="search-customer-input"
                  [(ngModel)]="customerFilter" />
              </div>
              <span class="material-icons icons-primary" style="margin: 14px 10px 0 0" *ngIf="search.value.length==0">
                search
              </span>
              <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                *ngIf="search.value.length>0" (click)="search.value='';searchTemplates(search.value)">
                close
              </span>
            </div>
            <div class="customers-div">
              <!-- <div
                style="display: flex"
                class="customers"
                [ngClass]="{ active: selectedCustomers.indexOf('All') != -1 }"
              > -->
              <!-- <mat-checkbox
                  style="margin-right: 5px"
                  (change)="addCustomer('All')"
                  [checked]="selectedCustomers.indexOf('All') != -1"
                >
                </mat-checkbox> -->
              <div *ngFor="let customer of allcustomer | labelPipe
                  : customerFilter
                  : 'name'" style="font-size: 14px; width: 100%">
                <div style="display: flex" class="customers"
                  [ngClass]="{ active: selectedCustomers.indexOf('All') != -1 }">
                  <mat-checkbox style="margin-right: 5px;" (change)="addCustomer('All')"
                    [checked]="selectedCustomers[0] == 'All' && selectedCustomers.length > 0"
                    [disabled]="disableAllCustomerSelection">
                    <div style="min-width: 170px;">
                      {{customer["name"]}}
                    </div>

                  </mat-checkbox>

                </div>
              </div>
              <div *ngFor="let customer of customerDetails | labelPipe
              : customerFilter
              : 'name'">
                <div style="display: flex" class="customers" [ngClass]="{
                    active: selectedCustomers.indexOf(customer['id']) != -1
                  }">
                  <mat-checkbox style="margin-right: 5px; min-width: 97%;" (change)="addCustomer(customer['id'])"
                    [checked]="selectedCustomers[0] != 'All' && selectedCustomers.length > 0"
                    [disabled]="disableCustomerSelection">
                    <div style="font-size: 14px; width: 100%">
                      <div style="min-width: 170px;">
                        {{customer["name"]}}
                      </div>
                    </div>
                  </mat-checkbox>
                  <!-- <div
                    style="font-size: 14px; width: 100%"
                    (click)="addCustomer(customer['id'])"
                  >
                    {{ customer["name"] }}
                  </div> -->
                </div>
              </div>
            </div>
            <div class="buttons b">
              <div class="cancel-button secondary-button-border" (click)="cancelSelectCustomers()">
                <div class="cancel-text">{{'POS_PAGE.CANCEL' | i18nTranslate}}</div>
              </div>
              <div class="save-button primary-button" (click)="save()">
                <div class="save-text">{{'POS_PAGE.SAVE' | i18nTranslate}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="email-div" *ngIf="actionType === 'email' || actionType === 'offer'">
        <div class="choose-customers-text" style="margin-top: 0px" *ngIf="actionType === 'email'">
          {{'POS_PAGE.EMAIL_SUBJECT' | i18nTranslate}}
        </div>
        <div class="input-div email-subject-div" id="email-subject" *ngIf="actionType === 'email'">
          <input class="input-text" [(ngModel)]="subject" (input)="enterSubject()" />
        </div>
        <div *ngIf="actionType === 'email' && subjectError" style="margin-left: 21px; color: #811620; font-size: 12px">
          {{'POS_PAGE.ENTER_EMAIL_SUBJECT' | i18nTranslate}}
        </div>
        <div class="attach-template-button email-attach-button secondary-button" style="margin-top: 18px"
          (click)="attachTemplate()">
          <span class="material-icons icons-primary" style="transform: rotate(45deg)">
            attach_file
          </span>

          <div *ngIf="templateName.length == 0 && actionType === 'email'"
            class="attach-template-text email-attach-template">
            {{'POS_PAGE.ATTACH_TEMPLATE' | i18nTranslate}}
          </div>
          <div *ngIf="templateName.length == 0 && actionType === 'offer'"
            class="attach-template-text email-attach-template">
            {{'POS_PAGE.ATTACH_OFFER' | i18nTranslate}}
          </div>
          <div *ngIf="templateName.length > 0" class="attach-template-text email-attach-template">
            {{'POS_PAGE.REPLACE_TEMPLATE' | i18nTranslate}}
          </div>
        </div>
        <div style="display: flex; margin-left: 19px" *ngIf="!showSelectCustomersDiv && templateName.length > 0"
          (click)="showPreview()">
          <span class="material-icons icons-primary"
            style="cursor: pointer; transform: rotate(45deg); margin-top: 12px">
            attach_file
          </span>
          <div class="select-template-name">
            {{ templateName }}
          </div>
        </div>
        <div *ngIf="selectTemplateError" style="margin-left: 20px; color: #811620; font-size: 12px">
          {{'POS_PAGE.SELECT_A_TEMPLATE' | i18nTranslate}}
        </div>
      </div>
      <div class="email-div" *ngIf="actionType === 'campaign'">
        <div class="select-campaign">
          {{'POS_PAGE.CAMPAIGN_TO_ADD_TO' | i18nTranslate}}
        </div>
        <div *ngIf="campaignsLoading" style="display: flex; justify-content: center; margin-top: 20px">
          <app-qubriux-loading  [size]="25"></app-qubriux-loading>
        </div>
        <div style="overflow-x: hidden; overflow-y: hidden">
          <div *ngFor="let campaign of campaigns">
            <p *ngIf="!campaignsLoading">
              <mat-checkbox class="check" (click)="selectCampaign(campaign['id'])"></mat-checkbox>{{
              campaign["strategyName"] }}
            </p>
          </div>
        </div>
      </div>
      <div class="buttons" data-widget-name="SURVEY ALERTS">
        <div class="cancel-button template-cancel-button secondary-button-border" (click)="cancelActionDiv()"
          data-button-name="Cancel: Survey Alert Action">
          <div class="cancel-text">{{'POS_PAGE.CANCEL' | i18nTranslate}}</div>
        </div>
        <div class="save-button primary-button" (click)="send()">
          <div class="save-text send-email-button-text" *ngIf="actionType === 'email'"
            data-button-name="Confirm: Send Email">
            {{'POS_PAGE.SEND_EMAIL' | i18nTranslate}}
          </div>
          <div class="save-text send-email-button-text" *ngIf="actionType === 'offer'"
            data-button-name="Confirm: Send Offer">
            {{'POS_PAGE.SEND_OFFER' | i18nTranslate}}
          </div>
          <div class="save-text" *ngIf="actionType === 'campaign'" data-button-name="Confirm: Add to Campaign">
            {{'POS_PAGE.ADD' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
    <div class="attachEmailTemplateDiv" id="attachEmailTemplateDiv" *ngIf="templateDiv">
      <div class="sub-header">
        <div class="send-email-text" *ngIf="actionType === 'email'">
          {{'POS_PAGE.EMAIL_TEMPLATES' | i18nTranslate}}
        </div>
        <div class="send-email-text" *ngIf="actionType === 'offer'">
          {{'POS_PAGE.OFFER_TEMPLATES' | i18nTranslate}}
        </div>
        <button [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.CLOSE' | i18nTranslate" class="material-icons xIcon icons-primary"
          style="margin: 22px 20px 0 0; color: rgba(99, 100, 102, 0.5)" (click)="cancelTemplateDiv()">
          clear
        </button>
      </div>
      <div class="create-template" *ngIf="actionType === 'email'">
        <div class="create-new-template">{{'POS_PAGE.CREATE_NEW_TEMPLATE' | i18nTranslate}}</div>
        <div class="add-button" (click)="createEmailTemplate(-1)">
          <span class="material-icons icons-primary" style="color: white; cursor: pointer; font-size: 24px">
            add
          </span>
        </div>
      </div>
      <div class="choose-customers-div" style="border: none" [ngClass]="{
          'bottom-div': actionType === 'email',
          'bottom-div2': actionType === 'offer'
        }">
        <div style="display: flex; justify-content: space-between">
          <div class="choose-customers-text choose-template" *ngIf="actionType === 'email'">
            {{'POS_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}
          </div>
          <div *ngIf="actionType === 'email'" style="
              display: flex;
              justify-content: space-between;
              height: 60%;
              margin: 26px 20px 0 0;
            ">
            <div class="campaignlistRectangle">
              <div class="campaignlistsearchBar">
                <input style="padding-top: 0px" *ngIf="actionType === 'email'" #search type="text" class="input-text"
                  [(ngModel)]="searchValue" (input)="searchTemplates($event.target.value)"
                  placeholder="Search Template" />

                <span class="material-icons icons-primary" *ngIf="searchValue.length==0"> search </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                  *ngIf="searchValue.length>0" (click)="searchValue='';searchTemplates(searchValue)">
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div *ngIf="actionType === 'offer'" style="
              display: flex;
              justify-content: center;
              height: 60%;
              width: 85%;
              margin: 26px 20px 0 0;
            ">
            <div class="campaignlistRectangle">
              <div class="campaignlistsearchBar">
                <input style="padding-top: 0px" *ngIf="actionType === 'offer'" #search type="text"
                  [(ngModel)]="searchValue" class="input-text" (input)="searchTemplates($event.target.value)"
                  placeholder="Search Offers" />
                <span class="material-icons icons-primary" *ngIf="searchValue.length==0"> search </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                  *ngIf="searchValue.length>0" (click)="searchValue='';searchTemplates(searchString)">
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="actionType === 'offer'" style="border-bottom: 1px solid #e5e5e5">
          <div style="display: flex; padding: 0 25px 10px">
            <div class="select-format">{{'POS_PAGE.SELECT_CHANNEL_FORMAT' | i18nTranslate}}</div>
            <div>
              <div style="
                  display: flex;
                  justify-content: space-between;
                  width: 116%;
                ">
                <div style="display: flex; margin-left: -10px">
                  <mat-checkbox name="email" [checked]="channelEmail" (change)="changeChannel('email')" style="
                      margin-top: 56px;
                      font-size: 12px;
                      margin-right: 5px;
                      width: -1%;
                    " checked>
                  </mat-checkbox>
                  <div style="margin-top: 52px; font-size: 14px">{{'POS_PAGE.EMAIL' | i18nTranslate}}</div>
                </div>
                <div style="display: flex">
                  <mat-checkbox name="sms" [checked]="channelSMS" (change)="changeChannel('sms')" style="
                      margin-top: 56px;
                      font-size: 12px;
                      margin-right: 5px;
                      width: -1%;
                    " *ngIf="(validScreens | screenConfig: 18)"></mat-checkbox>
                  <div style="margin-top: 52px; font-size: 14px">{{'POS_PAGE.SMS' | i18nTranslate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <div style="
                margin: 0 2px 0 24px;
                height: 19.2px;
                background-color: rgba(247, 148, 29, 0.25);
                width: 10%;
                padding: 1px 0px 0 8px;
              " type="button">
              <div class="home-info-icon-container" style="font-size: 12px; color: #f7941d">
                <span class="material-icons-outlined home-info-icon">
                  info
                </span>
              </div>
            </div>
            <div style="color: #f7941d; font-size: 12px">
              {{'POS_PAGE.SMS_INFO' | i18nTranslate}}
            </div>
          </div>
        </div>
        <div *ngIf="emailTemplatesLoading && offerTemplatesLoading"
          style="display: flex; justify-content: center; margin-top: 20px">
          <app-qubriux-loading  [size]="25"></app-qubriux-loading>
        </div>
        <div style="width: 94%; margin-left: 20px">
          <div class="templates" *ngIf="actionType === 'email'">
            <div *ngFor="let temp of templates">
              <div [style.border]="
                  templateID == temp['id'] ? '3px solid #4abb5975' : 'none'
                " class="template-div" (click)="selectTemplate(temp['id'], temp['layoutName'], event)"
                [id]="temp['id']">
                <div style="
                    display: flex;
                    justify-content: center;
                    padding: 5px 6px 5px 5px;
                    border-bottom: 1px solid #e5e5e5;
                  ">
                  <img style="width: 100%; height: 125px" src="{{ temp['snapshotUrl'] }}" [id]="'img' + temp['id']" />
                </div>
                <div class="template-name">{{ temp["layoutName"] }}</div>
              </div>
            </div>
          </div>
          <div class="templates2" *ngIf="actionType === 'offer'">
            <div *ngFor="let temp of templates">
              <mat-checkbox class="custom-frame" [checked]="templateID == temp['id']"
                (click)="selectTemplate(temp['id'], temp['offerName'], event)">
                <div class="template-div2">
                  <div style="display: flex; justify-content: space-between">
                    <div class="template-name-offers" style="word-break: break-word">
                      {{ temp["offerName"] }}
                    </div>

                    <div class="template-name-offers" style="cursor: pointer; color: var(--primary-color)"
                      (click)="previewOfferSummary(temp['id'])">
                      {{'POS_PAGE.PREVIEW' | i18nTranslate}}
                    </div>
                  </div>
                </div>
              </mat-checkbox>
            </div>
            <div *ngIf="templates.length == 0 && !offerTemplatesLoading"
              style="display: flex; justify-content: center; margin-top: 30px">
              {{'POS_PAGE.NO_TEMPLATE_TO_DISPLAY' | i18nTranslate}}
            </div>
          </div>

          <!-- <div style="width: 304px; position: absolute" *ngIf="actionType === 'offer'">
        <div class="templates-offer">
          <div *ngFor="let temp of templates">
            <div class="template-div-offer" (click)="selectTemplate(temp['id'], temp['offerName'], event)" [id]="temp['id']">
              <div class="template-name-offers">{{temp['offerName']}}</div>
            </div>
          </div>
        </div>
      </div> -->
        </div>
      </div>
      <div class="buttons">
        <div class="cancel-button template-cancel-button secondary-button-border" (click)="cancelTemplateDiv()">
          <div class="cancel-text">{{'POS_PAGE.CANCEL' | i18nTranslate}}</div>
        </div>
        <button class="save-button primary-button" (click)="confirm()" [disabled]="template.length == 0">
          <div class="save-text confirm-text">{{'POS_PAGE.CONFIRM' | i18nTranslate}}</div>
        </button>
      </div>
    </div>
  </div>
</div>