import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardPlan } from 'src/app/core/services/current-plan-auth.service';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { GetCustomerPersonalOfferComponent, OfferAllListingComponent, OfferCreationSimplifiedComponent, OfferReportComponent } from './pages';

const routes: Routes = [
    { path: "", redirectTo: "choose-offer", pathMatch: "full" },
    { 
      path: "choose-offer", 
      component: OfferAllListingComponent,
      data: {
        titleheading: "Offers",
        title: "Choose Offer"
    } 
  },
    {
      path: "create-offer",
      component: OfferCreationSimplifiedComponent,
      canActivate: [AuthGuardPlan, WidgetRouteGaurdService],
      data: {
        titleheading: "Create Offer ",
        title: "Create Offer",
        minPath: "Advanced",
        id: 17,
      },
    },
    {
      path: "edit-offer",
      component: OfferCreationSimplifiedComponent,
      canActivate: [AuthGuardPlan, WidgetRouteGaurdService],
      data: {
        titleheading: "Edit Offer ",
        title: "Edit Offer",
        minPath: "Advanced",
        id: 17,
      },
    },
    {
      path: "preview-offer",
      component: OfferCreationSimplifiedComponent,
      canActivate: [AuthGuardPlan, WidgetRouteGaurdService],
      data: {
        titleheading: "Preview Offer ",
        title: "Preview Offer",
        minPath: "Advanced",
        id: 17,
      },
    },
    {
      path: "choose-offer",
      component: OfferAllListingComponent,
      canActivate: [WidgetRouteGaurdService],
      data: {
        titleheading: "Offers",
        title: "Choose Offer",
        minPath: "Basic",
        id: 16,
      },
    },
    { 
      path: "customer-offer", 
      component: GetCustomerPersonalOfferComponent,
      data: {
        titleheading: "Customer Offer",
        title: "Customer Offer"
    } 
  },
  {
    path: "report",
    component: OfferReportComponent,
    data: {
        titleheading: "Offer Report",
        title: "Offer Report",
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfferRoutingModule { }
