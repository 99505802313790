import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { response } from 'express';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-payment-pos-redirect',
  templateUrl: './payment-pos-redirect.component.html',
  styleUrls: ['./payment-pos-redirect.component.css']
})
export class PaymentPosRedirectComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<PaymentPosRedirectComponent>,
    private apicall: ApiCallService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  apiloading = true;
  urlData;
  failed = false;
  timertoRedirect = 10;
  ngOnInit(): void {
    console.log("DATA",this.data)
    if(this.data['type'] === "planChange"){
      this.addPlanSubscription();
    }
    else{
      this.addAddOnSubscription();
    }
  }
  addPlanSubscription(){
    this.apiloading = true;
    this.apicall.planSubscriptionURL(this.data).subscribe((response)=>{
      if(response){
        console.log("RESPOSNSE",response);
        this.urlData = JSON.parse(response['body'])
        console.log("URL", this.urlData)
        this.apiloading = false;
        let url = this.urlData['url']
      if (url) {
        // window.open("www.qubriux.skellam.com");
        window.location.href = url;
        // window.open(url)
      }
      else{
        this.dialogRef.close();
      }       
      }
    },(err)=>{
      this.failed = true;
      let timer = setInterval(()=>{
        // this.redirect(status)
        if(this.timertoRedirect === 0){
          this.dialogRef.close();
          this.router.navigate(['payment-pos']);
          clearInterval(timer);
        }
        this.timertoRedirect--;
      },1000)
    })
  }
  addAddOnSubscription(){
    this.apiloading = true;
    this.apicall.addOnSubscriptionURL(this.data).subscribe((response)=>{
      if(response){
        console.log("RESPOSNSE",response);
        this.urlData = JSON.parse(response['body'])
        console.log("URL", this.urlData)
        this.apiloading = false;
        let url = this.urlData['url']
      if (url) {
        // window.open("www.qubriux.skellam.com");
        window.location.href = url;
      }
      else{
        this.dialogRef.close();
      }       
      }
    },(err)=>{
      this.failed = true;
      let timer = setInterval(()=>{
        // this.redirect(status)
        if(this.timertoRedirect === 0){
          this.dialogRef.close();
          this.router.navigate(['/app/payments/top-up-pos']);
          clearInterval(timer);
        }
        this.timertoRedirect--;
      },1000)
    })
  }

}
