import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { ContactUsDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  constructor(private apiCall:ApiCallService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddUserComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private snackBar: SnackbarCollection,
    @Inject(MAT_DIALOG_DATA) public data:any) { }

  name="";
  email="";
  number="";
  cancel=true;
  sendagain=false;
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
  // unamePattern = " [A-Za-z0-9\s]{0,1}[\.]{0,1}[A-Za-z\s]{0,15}$";
  unamePattern = "^[A-Za-z0-9_ ]{3,15}$";
  errorName="";
  errorMail="";
  phoneNumberErrorText="";
  errorUserType="";
  errorStoreSelect = "";
  apiloading=false;
  countryCode = "91";
  countryCodeData = [];
  mobilenumberPattern = /^\d{10}$/;
  userType = {
    name: '',
    type: ''
  };
  selectedStores = [];
  stores : any;

  ngOnInit() {
    this.name=this.data['name'];
    this.email=this.data['emailId'];
    this.sendagain=this.data['sendAgain'];
    this.userType.type = this.data['role'] ? this.data['role'] : "";
    this.countryCode = this.data?.countryCode?.length > 0 ? this.data?.countryCode : "91";
    this.number = this.data?.phone ? this.data.phone : "";
    if(this.sendagain === true){
      this.getDataForResend()
    }
    this.getCountryCode();
    this.getStoreList();
    if(!this.name){
      this.name='';
    }
    if(!this.email){
      this.email='';
    }
  }
  keypress(){
    this.errorName="";
    this.errorMail="";
  }
  add(){
    console.log('selecetdstore', this.selectedStores)
    if(this.email.length>0 && this.name.length>0 && this.number.length>0 && this.userType.type.length>0){
      if(this.checkValidation()){
        return;
      }
      // else if(this.name.match(this.unamePattern)==null){
      //   this.errorName="Please enter valid user name";
      // }
      else{
        this.apiloading=true;  
        this.apiCall.AddUserManagementUser(this.email.toLowerCase(),this.name,this.sendagain,null, this.userType.type, this.selectedStores, this.countryCode + this.number).subscribe((response)=>{
          if(response['body']['emailSend']==true && response['body']['retryMailExceeded']==false){
            this.dialogRef.close(true);
          }   
        },(error)=>
        {  
          if(error.error?.body){
            if(error['error']['body']['retryMailExceeded']==false && error['error']['body']['emailSend']==false && error['message']=='failure'){
              const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
              width: "380px",
              height: "auto",
              disableClose: true,
              data: {
                header: this._i18nDynamicTranslate.transform("Failure", ['POS_PAGE']),
                body: this._i18nDynamicTranslate.transform("Mail not send due to technical issue.", ['POS_PAGE']),
              },
            });
            this.dialogRef.close();
            }
            else{
            this.apiloading=false;
            this.errorMail = error['error']['body']['message'];
            }
          }
          else{
            this.apiloading = false;
            this.snackBar.openSnackBar("Something Went Wrong", 2000);
          }        
        });
        // this.apiloading=false
      }
    }
    else{
      if(this.email.length<1){
        this.errorMail= this._i18nDynamicTranslate.transform("Email cannot be empty", ['POS_PAGE'])
      }
      if(this.name.length<1){
        this.errorName= this._i18nDynamicTranslate.transform("User Name cannot be empty", ['POS_PAGE'])
      }
      if(this.number.length<1){
        this.phoneNumberErrorText= this._i18nDynamicTranslate.transform("Phone Number cannot be empty", ['POS_PAGE'])
      }
      if(this.userType.type.length < 1){
        this.errorUserType = this._i18nDynamicTranslate.transform("User Type cannot be empty", ['POS_PAGE'])
      }
    }
  }
  sendAgain(){
    if(this.email.length>0 && this.name.length>0 && this.number.length>0 && this.userType.type.length>0){
      if(this.checkValidation()){
        return;
      }
      else{
        this.apiloading=true
        this.apiCall.AddUserManagementUser(this.email,this.name,true,this.data['userId'], this.userType.type, this.selectedStores, this.number).subscribe((response)=>{
          if(response['body']['emailSend']==true && response['body']['retryMailExceeded']==false)
            this.dialogRef.close(true);
          else if(response['body']['retryMailExceeded']==true && response['body']['emailSend']==false){
            const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
            width: "380px",
            height: "auto",
            disableClose: true,
            data: {
              header: this._i18nDynamicTranslate.transform("Failure", ['POS_PAGE']),
              body: response["body"]['message'],
            },
          });
          this.dialogRef.close();
          }
          else if(response['body']['retryMailExceeded']==false && response['body']['emailSend']==false && response['message']=='failure'){
            const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
            width: "380px",
            height: "auto",
            disableClose: true,
            data: {
              header: this._i18nDynamicTranslate.transform("Failure", ['POS_PAGE']),
              body: this._i18nDynamicTranslate.transform("Mail not send due to technical issue.", ['POS_PAGE']),
            },
          });
          this.dialogRef.close();
          }
        },(error)=>
        {
          if(error.error?.body){
            if(error['error']['body']['retryMailExceeded']==false && error['error']['body']['emailSend']==false && error['message']=='failure'){
              const dialogRef = this.dialog.open(ContactUsDialogComponent, { panelClass: "no-padding-dialog-popup",
              width: "380px",
              height: "auto",
              disableClose: true,
              data: {
                header: this._i18nDynamicTranslate.transform("Failure", ['POS_PAGE']),
                body: this._i18nDynamicTranslate.transform("Mail not send due to technical issue.", ['POS_PAGE']),
              },
            });
            this.dialogRef.close();
            }
            else{
            this.apiloading = false;
            this.errorMail = error['error']['body']['message'];
            }
          }
          else{
            this.apiloading = false;
            this.snackBar.openSnackBar("Something Went Wrong", 2000);
          }
        });
      }
      // this.apiloading=false;
    }
    else{
      if(this.email.length<1){
        this.errorMail=this._i18nDynamicTranslate.transform("Email cannot be empty", ['POS_PAGE'])
      }
      if(this.name.length<1){
        this.errorName= this._i18nDynamicTranslate.transform("User Name cannot be empty", ['POS_PAGE'])
      }
    }
    
  }
submit(){
  this.dialogRef.close(false)
  }
  getCountryCode(){
    this.apiCall.getCountryCode().subscribe((response)=>{
      this.countryCodeData = response['body'];

    })
  }
  changeCountry(code){
    this.countryCode = code;
  }
  getErrorTextNumber(event){
    this.phoneNumberErrorText = "";
        if(!this.mobilenumberPattern.test(this.number)){
          this.phoneNumberErrorText= 'Please Enter Valid Phone Number'
        }
        else{
          this.phoneNumberErrorText = '';
        }
  }

  numberInputHandler(event: KeyboardEvent) {
    const invalidCharacters = /[^0-9.]/;
    const inputValue = (event.target as HTMLInputElement).value;
    const hasDecimalPoint = inputValue.includes('.');
    
    if ((inputValue.length >= 10 && event.key !== 'Backspace') ||
        invalidCharacters.test(event.key) ||
        (event.key === '.' && hasDecimalPoint)) {
      event.preventDefault();
    }
  }

  chaneguserSelect(type, name){
      this.errorUserType = "";
      this.errorStoreSelect = "";
      this.userType.name = name;
      this.userType.type = type;
  }
  getStoreList(){
    this.sendStoreList().then((result) => {
      if (result) {
        console.log("result", result);
        this.stores = result['body'];
      }
    });
  }
  sendStoreList(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getListStore().subscribe(
        (res) => {
          console.log(res);

          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }
  checkValidation(){
    if(this.checkIfValid()){
      if(this.email.match(this.emailPattern.trim())==null){
        this.errorMail=this._i18nDynamicTranslate.transform("Please Enter Valid Email Address", ['POS_PAGE']);
      }
      if(this.number.match(this.mobilenumberPattern)==null){
        this.phoneNumberErrorText=this._i18nDynamicTranslate.transform("Please Enter Valid Phone Number", ['POS_PAGE']);
      }
      if(this.name.length>15){
        this.errorName=this._i18nDynamicTranslate.transform("This field cannot have more than 15 characters", ['POS_PAGE'])
      }
      else if(this.name.match(this.unamePattern)==null){
        this.errorName=this._i18nDynamicTranslate.transform("Please Enter Valid User Name", ['POS_PAGE'])
      }
      // this.errorMail="Please enter valid email address";
      // return true;
    }
    if(this.userTypeValidation() || this.checkIfValid()){
      return true;
    }
    if(!this.userTypeValidation() && !this.checkIfValid){
      return false;
    }
  }
  checkIfValid(){
    if(this.email.match(this.emailPattern.trim())==null || (this.name.match(this.unamePattern)==null) || (this.number.match(this.mobilenumberPattern)==null)){
      return true;
    }
    else{
      return false;
    }
  }
  userTypeValidation(){
    if(this.userType.type ==='ROLE_STORE_MANAGER'){
      if(this.selectedStores.length === 0){
        this.errorStoreSelect = this._i18nDynamicTranslate.transform("Please select at least one store", ['POS_PAGE']);
        return true;
      }
    }
    else{
      return false;
    }
  }
  getDataForResend(){
    this.userType.type = this.data['role'] ? this.data['role'] : "";
    this.data['stores'].forEach((data)=>{
      this.selectedStores.push(data['qid'])
    });
    console.log(this.selectedStores);
    if(this.userType.type === 'ROLE_STORE_MANAGER'){
      this.userType.name = "Store Manager";
    }
    else if(this.userType.type === 'ROLE_USER'){
      this.userType.name = "Delegate Admin";
    }
    else if(this.userType.type === 'ROLE_CUSTOMER_CARE'){
      this.userType.name = "Customer Care";
    }
    else if(this.userType.type === 'ROLE_CUSTOMER_CARE_VIEW'){
      this.userType.name = "Customer Care View Only";
    }
    else if(this.userType.type === 'ROLE_REPORTING_VIEW'){
      this.userType.name = "View Report User";
    }
  }
  removeError(){
    this.errorStoreSelect = "";
  }
}
