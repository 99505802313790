import { Component, OnInit } from '@angular/core';
import { create360dialogaccoountService } from 'src/app/core/services/create-360-dialog-account-service';

@Component({
  selector: 'app-whatsapp-redirect',
  templateUrl: './whatsapp-redirect.component.html',
  styleUrls: ['./whatsapp-redirect.component.css']
})
export class WhatsappRedirectComponent implements OnInit {

  constructor(
    private create360dialog: create360dialogaccoountService
  ) { }

  ngOnInit(): void {
    this.create360dialog.initializeOnBoard();
  }

}
