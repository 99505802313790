import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent, DialogOverviewComponent, NotDeletedListDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { MatPaginator } from '@angular/material/paginator';
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-survey-instance",
  templateUrl: "./survey-instance.component.html",
  styleUrls: ["./survey-instance.component.css"],
})
export class SurveyInstanceComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private date_format: getdateformatService,
    private _i18nDynamicTranslate: TranslateDynamicText,
  ) {}

  surveys;
  campaign;
  panelOpenState = false;
  panelIndex = null;
  dashboardData = [];
  netPromoterScore;
  promoters;
  passives;
  diteractors;
  feedbacks = [];
  pageNumber = 0;
  pageSize = 10;

  totalSchedulelength: number;
  surveysScheduleBody=[];
  loading: boolean = false;
  dialogOpen: boolean = false;
  selectedi = -1;
  graphData = null;
  graphDataLoading = false;
  surveyListContentHeight = 0;
  listContentHeight = 0;
  appSubscriptionPlan;
  validWidgets = null;
  rightMargin;
  sideMenu;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileView;
  sub: Subscription;
  storeFilterList = [{
    storeName:'None',
    qid: null,
  }];
  selectedStore;
  isAdmin = false;
  selectedSurveyList = [];

  selectedSurveyListPageWise = {
    0 : []
  };


  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (this.mobileView)
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 1
      ) {
        this.pageNumber += 1;
        this.getNewData(this.pageNumber, this.pageSize);
      }
  }
  @ViewChild("heatMapContainer") heatMapContainer: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit() {
    this.selectedStore = this.storeFilterList[0];
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.getWidgets();
    this.loading = true;

    this.apiCall.getScheduledSurveys(this.pageNumber, this.pageSize).subscribe(
      (response) => {
        this.surveys = response["body"];
        this.surveysScheduleBody = this.surveys["surveySchedulesResponse"];
        this.totalSchedulelength = this.surveys["totalElements"];
        this.SentimentAnalysisParticularSurvey(0);
        this.loading = false;
        let sheader = document.getElementById("tableHeads");

        let interval = setInterval(() => {
          //  setTimeout(() => {

          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );

          if (sideMenu) {
            clearInterval(interval);
            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; //5 is the scroller width
              if (this.mobileView == false) {
                sheader.style.paddingRight = this.rightMargin;
              }
              console.log("2");
            } else {
              if (this.mobileView == false) sheader.style.paddingRight = "0px";
              console.log("3");
            }
          }
          //}, 500)
        });
      },
      (err) => {
        this.snackBar.openSnackBar("Error ", 2000);
      }
    );

    if(this.tokenStorage.getqId() == 'null'){
      this.isAdmin = true;
      this.apiCall.getListStore().subscribe(
        (response) => {
          this.storeFilterList = [{
            storeName:'None',
            qid: null,
          }];
          if(response['body']) {
            response['body'].forEach((storeData)=>{
              if(storeData.qid) {
                this.storeFilterList.push({
                  'qid':storeData.qid,
                  'storeName':storeData.storeName,
                });
              }
            });
            this.selectedStore = this.storeFilterList[0];
          }
        },
        (err) => {
          this.snackBar.openSnackBar("Error ", 2000);
        }
      );
    }
    this.loading = false;
  }

  getFilteredSurveyList() {
    if(this.paginator) {
      this.paginator.pageIndex = 0; // Reset to the first page
      this.paginator.firstPage();
    }
    this.apiCall.getScheduledSurveys(this.pageNumber, this.pageSize,this.selectedStore.qid).subscribe(
      (response) => {
        this.surveys = response["body"];
        this.surveysScheduleBody = this.surveys["surveySchedulesResponse"];
        this.totalSchedulelength = this.surveys["totalElements"];
        this.SentimentAnalysisParticularSurvey(0);
        this.loading = false;
        let sheader = document.getElementById("tableHeads");

        let interval = setInterval(() => {

          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );

          if (sideMenu) {
            clearInterval(interval);
            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; //5 is the scroller width
              if (this.mobileView == false) {
                sheader.style.paddingRight = this.rightMargin;
              }
              console.log("2");
            } else {
              if (this.mobileView == false) sheader.style.paddingRight = "0px";
              console.log("3");
            }
          }
        });
      },
      (err) => {
        this.snackBar.openSnackBar("Error ", 2000);
      }
    );
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getNextData(pageEvent) {
    if(this.pageSize !== pageEvent.pageSize){
      this.clearList();
    }
    this.pageNumber = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    console.log(pageEvent);
    this.loading = true;
    this.surveysScheduleBody = [];
    if(Object.keys(this.selectedSurveyListPageWise).indexOf(this.pageNumber.toString()) === -1){
      this.selectedSurveyListPageWise[this.pageNumber] = [];
    }
    this.apiCall.getScheduledSurveys(this.pageNumber, this.pageSize, this.selectedStore.qid).subscribe(
      (response) => {
        this.surveys = response["body"];
        this.surveysScheduleBody = this.surveys["surveySchedulesResponse"];
        this.SentimentAnalysisParticularSurvey(0);
        this.selectedi = 0;
        this.totalSchedulelength = this.surveys["totalElements"];
        this.loading = false;
        let sheader = document.getElementById("tableHeads");
        let interval = setInterval(() => {
          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );
          // setTimeout(() => {

          console.log(sideMenu);
          if (sideMenu) {
            console.log(
              sideMenuParent.clientHeight,
              sideMenuParent.scrollHeight
            );
            clearInterval(interval);

            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; //5 is the scroller width
              if (this.mobileView == false) {
                sheader.style.paddingRight = this.rightMargin;
              }
              console.log("2");
            } else {
              if (this.mobileView == false) sheader.style.paddingRight = "0px";
              console.log("3");
            }
          }
          // },500)
        });
      },
      (err) => {
        this.snackBar.openSnackBar("error", 2000);
        this.loading = false;
      }
    );
    this.loading = false;
  }

  async toggleSurvey(surveyIndex, event, qid, storeName) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if(this.tokenStorage.getqId() === qid || qid === undefined || (this.tokenStorage.getqId() ==='null' && qid === null)){
        this.toggleStatus(surveyIndex);
        let data;
        data = this.surveysScheduleBody[surveyIndex];
        data.merchantId = this.tokenStorage.getMerchantId();
        this.apiCall.surveyScheduleStatusUpdate(data).subscribe(
          (response) => {
            if (response["message"] === "ERROR") {
              this.toggleStatus(surveyIndex);
              this.snackBar.openSnackBar("Failed to switch", 2000);
            }
          },
          (err) => {
            this.snackBar.openSnackBar("Failed to switch", 2000);
          }
        );
      }
      else{
        event.source.checked =
        this.surveysScheduleBody[surveyIndex].surveyStatus === "ACTIVE"
          ? true
          : false;
      event.checked =
        this.surveysScheduleBody[surveyIndex].surveyStatus === "ACTIVE"
          ? true
          : false;
        const dialogRef = this.dialog.open(ContactUsDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          height: "auto",
          disableClose: true,
          width: "380px",
          data: {
            header: "Error",
            body: qid === null ? "Admin created, can't edit." : "This survey is created by " + storeName + " You don't have the permission to edit.",
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          // this.disabled = false;
        });
      }
    } else {
      event.source.checked =
        this.surveysScheduleBody[surveyIndex].surveyStatus === "ACTIVE"
          ? true
          : false;
      event.checked =
        this.surveysScheduleBody[surveyIndex].surveyStatus === "ACTIVE"
          ? true
          : false;
    }
  }

  toggleStatus(surveyIndex) {
    if (this.surveysScheduleBody[surveyIndex].surveyStatus === "ACTIVE") {
      this.surveysScheduleBody[surveyIndex].surveyStatus = "INACTIVE";
    } else {
      this.surveysScheduleBody[surveyIndex].surveyStatus = "ACTIVE";
    }
  }

  refreshCampaigns() {
    this.ngOnInit();
  }

  getCampaignDetails(id) {
    this.apiCall.getcampaignDetails(id).subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.campaign = JSON.parse(response["body"]);
          const dialogRef = this.dialog.open(DialogOverviewComponent, {
            width: "40%",
            height: "500px",
            data: {
              type: "campaign",
              data: this.campaign["campaignTemplate"]["campaignActions"],
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              // this.apiCall.offerUpdate(this.offer).subscribe((response) => {
              //     this.openSnackBar("Template submitted", "");
              // },
              //   (err) => {
              //     alert("error" + err)
              //   })
            }
          });
        }
      },
      (err) => {
        // this.openSnackBar("error" + err,"")
      }
    );
  }

  viewStatusOfCampaign(id) {
    this.router.navigate(["/app/instances", { id: id }]);
  }
  SentimentAnalysisParticularSurvey(index) {
    // console.log(isAnalysis)
    // if(this.mobileView && (isAnalysis==true || isAnalysis==false)){

    // }

    if(index==this.selectedi){
      return;
    }
    this.selectedi=index;

    let dashboardData;
    this.graphDataLoading = true;
    if (index != null && this.surveysScheduleBody.length > 0) {
      this.apiCall
        .getIndividualSurveyDashboard(
          this.surveysScheduleBody[index]["surveyId"]
        )
        .subscribe(
          (response) => {
            // response={"message":"SUCCESS","body":"{\"surveyKeyphrase\":{\"surveyCategory\":[{\"keyphrase\":\"\",\"count\":1,\"category\":\"PROMOTERS\"},{\"keyphrase\":\"\",\"count\":0,\"category\":\"DETRACTORS\"},{\"keyphrase\":\"\",\"count\":0,\"category\":\"PASSIVES\"}],\"sentimentScore\":0,\"npsScore\":100,\"latestCustomerFeedbacks\":[{\"answers\":[\"survey\"],\"customerName\":\"Kavitha Sivakumar\",\"timestamp\":\"2021-04-15 12:13:37.0\"}]},\"surveySentiment\":{\"sentimentScore\":0,\"negative\":0,\"neutral\":1,\"positive\":0}}"}
            if (response["message"] === "SUCCESS") {
              this.panelIndex = index;
              this.promoters = 0;
              this.passives = 0;
              this.diteractors = 0;
              dashboardData = response["body"];
              this.dashboardData = dashboardData;
              this.graphData = {
                data: this.dashboardData,
                surveyName:
                  this.surveys["surveySchedulesResponse"][index]["surveyName"],
              };
              this.graphDataLoading = false;
            } else {
            }
          },
          (error) => {
            this.snackBar.openSnackBar("Error fetching data", 2000);
            this.graphDataLoading = false;
          }
        );
    } else {
      this.graphDataLoading = false;
    }
  }

  // getDataForSurvey(index) {
  //   this.apiCall
  //     .getIndividualSurveyDashboard(this.surveys[index]["surveyId"])
  //     .subscribe((response) => {

  //       if (response["message"] === "SUCCESS") {
  //         this.panelIndex = index;
  //         //document.getElementById('promoter').style.width = '0px';
  //         //document.getElementById('passive').style.width = '0px';
  //         //document.getElementById('deteractor').style.width = '0px';
  //         this.promoters = 0;
  //         this.passives = 0;
  //         this.diteractors = 0;
  //         let dashboardData = response["body"];
  //         this.dashboardData = dashboardData;

  //         this.netPromoterScore = dashboardData["npsScore"].toFixed(2);
  //         let heatMapDivWidth = this.heatMapContainer.nativeElement.offsetWidth;
  //         let totalValue =
  //           dashboardData["promoters"] +
  //           dashboardData["passives"] +
  //           dashboardData["detractors"];
  //         this.promoters = (
  //           (dashboardData["promoters"] / totalValue) *
  //           100
  //         ).toFixed(0);
  //         this.passives = (
  //           (dashboardData["passives"] / totalValue) *
  //           100
  //         ).toFixed(0);
  //         this.diteractors = (
  //           (dashboardData["detractors"] / totalValue) *
  //           100
  //         ).toFixed(0);
  //         document.getElementById("promoter" + index).style.width =
  //           (heatMapDivWidth / 100) * this.promoters + "px";
  //         document.getElementById("passive" + index).style.width =
  //           (heatMapDivWidth / 100) * this.passives + "px";
  //         document.getElementById("deteractor" + index).style.width =
  //           (heatMapDivWidth / 100) * this.diteractors + "px";
  //         //this.getTimeSeriesData(dashboardData['categoryData']);
  //         this.getFeedbackData(dashboardData["latestCustomerFeedbacks"]);
  //       } else {
  //       }
  //     });
  // }

  // getFeedbackData(data) {
  //   this.feedbacks = [];
  //   for (let key in data) {
  //     let feedback: any = {};
  //     feedback["cName"] = key;
  //     feedback["data"] = data[key];
  //     this.feedbacks.push(feedback);
  //   }
  // }
  async updateScheduledSurvey(surveyID, templateID, qid, storeName) {
    console.log(qid, this.tokenStorage.getqId())
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if(this.tokenStorage.getqId() === qid || qid === undefined || (this.tokenStorage.getqId() ==='null' && qid === null)){
        this.router.navigate([
          "/app/surveys/survey-list/schedule",
          { id: templateID, sid: surveyID, loc: "dash" },
        ]);
      }
      else{
        const dialogRef = this.dialog.open(ContactUsDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          height: "auto",
          disableClose: true,
          width: "380px",
          data: {
            header: "Error",
            body: qid === null ? "Admin created, can't edit." : "This survey is created by " + storeName + " You don't have the permission to edit.",
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          // this.disabled = false;
        });
      }
    }
  }

  surveylist() {
    this.router.navigate(["/app/surveys/survey-list"]);
  }
  SentimentAnalysisParticularSurveyMobile(index, isAnalysis) {
    this.router.navigate([
      "/app/reach-out/survey-sentiment-dialogbox",
      this.surveysScheduleBody[index]["surveyId"],
      this.surveys["surveySchedulesResponse"][index]["surveyName"],
      isAnalysis,
    ]);
  }
  totalOfferCount;
  getNewData(pageNum, pageSize) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .getScheduledSurveys(pageNum, pageSize)
      .subscribe((response) => {
        if (response["message"] == "SUCCESS") {
          let body = response["body"];
          body["surveySchedulesResponse"].forEach((element) => {
            this.surveysScheduleBody.push(element);
          });
          // this.surveysScheduleBody.push(body);
          console.log(this.surveysScheduleBody);
          // this.surveys = response["body"];
          // this.surveysScheduleBody = this.surveys["surveySchedulesResponse"];
          // this.totalOfferCount = JSON.parse(this.surveys["totalCount"]);
          let sheader = document.getElementById("tableHeads");

          let interval = setInterval(() => {
            //  setTimeout(() => {

            let sideMenu = document.getElementById("side-menu-survey");
            let sideMenuParent = document.getElementById(
              "side-menu-survey-parent"
            );

            if (sideMenu) {
              console.log(
                sideMenuParent.clientHeight,
                sideMenuParent.scrollHeight
              );
              clearInterval(interval);
              if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
                if (this.mobileView == false) {
                  this.rightMargin = "14px"; //5 is the scroller width
                  sheader.style.paddingRight = this.rightMargin;
                  console.log("2");
                }
              } else {
                if (this.mobileView == false) {
                  sheader.style.paddingRight = "0px";
                  console.log("3");
                }
              }
            }
            //}, 500)
          });
        }
      });
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  getAllSurvey(){
    this.apiCall.getScheduledSurveys(this.pageNumber, this.pageSize).subscribe(
      (response) => {
        this.surveys = response["body"];
        this.surveysScheduleBody = this.surveys["surveySchedulesResponse"];
        this.totalSchedulelength = this.surveys["totalElements"];
        this.SentimentAnalysisParticularSurvey(0);
        this.loading = false;
        let sheader = document.getElementById("tableHeads");

        let interval = setInterval(() => {
          //  setTimeout(() => {

          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );

          if (sideMenu) {
            clearInterval(interval);
            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; //5 is the scroller width
              if (this.mobileView == false) {
                sheader.style.paddingRight = this.rightMargin;
              }
              console.log("2");
            } else {
              if (this.mobileView == false) sheader.style.paddingRight = "0px";
              console.log("3");
            }
          }
          //}, 500)
        });
      },
      (err) => {
        this.snackBar.openSnackBar("Error ", 2000);
      }
    );
  }

  deleteSelectedSurvey(){
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: this.mobileMedia.matches ? "90%" : "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Surveys", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Do you wish to delete these Surveys?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        "data-widget": "SURVEY LISTING",
        "data-button": "Confirm: Delete",
        "data-item": null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let selectedOfferList = Object.values(this.selectedSurveyListPageWise).flat();
      if (result) {
        this.loading = true;
        this.deleteSelectedSurveyApi(selectedOfferList).then(
          (res) => {
            this.loading = false;
            this.clearList();
            this.getAllSurvey();
            this.snackBar.openSnackBar(res['body'], 2000);
          },
          (err) => {
            console.log(err);
            const notDeletedList: any[] = err;
            console.log(notDeletedList);
            this.loading = false;
            const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
              panelClass: "customer-dialog-popup",
              width: "540px",
              disableClose: false,
              data:{
                notDeletedList: notDeletedList,
                type: "SURVEY",
                heading: "OOPS",
                subHeading: notDeletedList[0]
              },
            });
            dialogRefDeleted.afterClosed().subscribe((res)=>{
              this.getAllSurvey();
              this.clearList();
            });
            // console.log(JSON.parse(err));
          }
        );
      }
    });
  }

  checkSurveyList(event, type, id){
    console.log(event)
    if(event){
      if(type === 'single'){
        this.selectedSurveyListPageWise[this.pageNumber].push(id);
      }
      else{
        this.selectedSurveyListPageWise[this.pageNumber] = [];
        console.log(this.surveysScheduleBody);
        this.surveysScheduleBody.forEach((data)=>{
          this.selectedSurveyListPageWise[this.pageNumber].push(data.surveyId);
        });
      }
    }
    else{
      if(type === 'single'){
        let index = this.selectedSurveyListPageWise[this.pageNumber].indexOf(id);
        this.selectedSurveyListPageWise[this.pageNumber].splice(index, 1);
      }
      else{
        this.selectedSurveyListPageWise[this.pageNumber] = [];
      }
    }
  }

  checkIfPresent(id){
    return this.selectedSurveyListPageWise[this.pageNumber]?.length > 0 && (this.selectedSurveyListPageWise[this.pageNumber].includes(id.toString()) || this.selectedSurveyListPageWise[this.pageNumber].includes(id));
  }

  deleteSelectedSurveyApi(data){
    return new Promise((resolve, reject) => {
      this.apiCall.deleteSurveys(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  clearList(){
    for (const key in this.selectedSurveyListPageWise) {
      if (this.selectedSurveyListPageWise.hasOwnProperty(key)) {
        this.selectedSurveyListPageWise[key] = [];
      }
    }
  }

}
