<!-- NEW UI -->

<div *ngIf="validWidgets" style="overflow: auto;height: 100%;background: #F5F6FA; padding: 30px;" #mainContainer
    data-widget-name="SURVEY CREATE/EDIT">
    <div *ngIf="validWidgets | widgetConfig : 8">
        <div [formGroup]="surveyForm">
            <div class="survey-header">
                <div>
                    <app-breadcrumb></app-breadcrumb>
                </div>
                    <!-- if no button clicked -->
                    <div style="display:flex;align-items: center; justify-content: end;"
                        *ngIf="!buttonSaveSurveyLoading&&!buttonSaveAndScheduleLoading&&!formempty">
                        <div style="margin-left: 26px;">
                            <div appButtonDebounce [throttleTime]="600" (throttledClick)="submitSurvey('SaveSurvey')"
                                 data-button-name="Save Template" matTooltip="Save and Exit">
                                <span class="material-icons-outlined icons-primary" style="padding: 6px 0; cursor: pointer;">
                                    save
                                </span>
                            </div>
                        </div>
                        <!-- <div style="margin-left: 15px;">
                            <div appButtonDebounce [throttleTime]="600" (throttledClick)="backPressed()" type="button"
                                data-button-name="Back" matTooltip="Discard">
                                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/survey/delete.svg"
                                    alt="Back">
                            </div>
                        </div> -->
                        <div style="margin-left: 26px;">
                            <!-- <button class="saveAndSchedule-rectangle" appButtonDebounce [throttleTime]="600"
                                (throttledClick)="submitSurvey('SaveSurveyaAndSchedule')" type="button"
                                data-button-name="Save and Schedule" matTooltip="Save and Schedule">
                                <span class="calender-icon" type="button"></span>
                                <span class="material-icons-outlined icons-primary" style="color:#fff;font-size: 20px;">
                                    event
                                </span>
                                <span class="saveAndSchedule-segment-text" type="button">Save and
                                    Schedule</span>
                            </button> -->
                            <div class="primary-button" appButtonDebounce [throttleTime]="600"
                            (throttledClick)="submitSurvey('SaveSurveyaAndSchedule')" data-button-name="Save and Schedule" [matTooltip]="'COMMON_PAGE.SAVE_AND_SCHEDULE' | i18nTranslate">
                            <span class="material-icons-outlined icons-primary" style="color:#fff;font-size: 20px;">
                                event
                            </span>
                            <span class="normText" style="color: #fff; margin-left: 3px;">{{'SURVEY_PAGE.SAVE_SCHEDULE' | i18nTranslate}}</span>                           
                             </div>
                        </div>

                    </div>


                    <!-- if saveonly button clicked -->
                    <div style="display:flex;margin-top: 18px;"
                        *ngIf="buttonSaveSurveyLoading&&!buttonSaveAndScheduleLoading&&!formempty">
                        <!-- <div>
                    <button class="preview-rectangle-disabled" appButtonDebounce [throttleTime]="600">
                        <mat-icon class="visibility-icon">visibility</mat-icon>
                        <span class="preview-text">Copy other template data</span>
                    </button>
                </div> -->
                        <div style="margin-left: 26px; margin-top: 10px;">
                            <div>
                                <mat-spinner diameter="22 " color="primary" style="padding: 6px 0;"></mat-spinner>
                            </div>
                        </div>
                        <!-- <div style="margin-left: 15px;">
                            <div appButtonDebounce [throttleTime]="600" (throttledClick)="backPressed()" type="button"
                                data-button-name="Back">

                            </div>
                        </div> -->
                        <div style="margin-left: 26px;">
                            <button class="saveAndSchedule-rectangle-disabled">
                                <span class="material-icons-outlined icons-primary" style="color:#fff;font-size: 20px;">
                                    event
                                </span>

                                <span class="saveAndSchedule-segment-text">{{'SURVEY_PAGE.SAVE_SCHEDULE' | i18nTranslate}}</span>
                            </button>
                        </div>

                    </div>

                    <!-- if saveandschedule button clicked -->
                    <div style="display:flex;margin-top: 18px;"
                        *ngIf="!buttonSaveSurveyLoading&&buttonSaveAndScheduleLoading&&!formempty">
                        <!-- <div>
                    <button class="preview-rectangle-disabled" appButtonDebounce [throttleTime]="600">
                        <mat-icon class="visibility-icon">visibility</mat-icon>
                        <span class="preview-text">Copy other template data</span>
                    </button>
                </div> -->
                        <div style="margin-left: 26px; margin-top: 2px;">
                            <div style="cursor:not-allowed;">
                                <span class="material-icons-outlined icons-primary" style="padding: 6px 0;">
                                    save
                                </span>

                            </div>
                        </div>
                        <!-- <div style="margin-left: 15px;">
                            <div appButtonDebounce [throttleTime]="600" (throttledClick)="backPressed()" type="button"
                                data-button-name="Back">

                            </div>
                        </div> -->
                        <div style="margin-left: 26px;">
                            <button class="saveAndSchedule-rectangle-disabled">
                                <!-- <mat-spinner diameter="10"></mat-spinner> -->
                                <mat-spinner diameter="22 " color="accent"></mat-spinner>
                            </button>
                        </div>

                    </div>
            </div>
            <div class="survey-creation-header" #mainHeader>
                <div>
                    <h4 class="add-survey-heading">{{'SURVEY_PAGE.ADD_SURVEY' | i18nTranslate}}</h4>
                </div>
                <div style="padding-top: 20px; display: flex;">
                    <div style="display: flex;
justify-content: space-between;width: 50%;align-items: center;">
                    <div style="display: flex;width: 90%;align-items: center;">
                        <!-- <div style="width: 11%;">
                        <button class="normText" style="
                               justify-content: center;
    align-items: center;
    display: flex;
    background: #fff;
    border-radius: 5px;
    height: 40px;
    margin-top: 7px;
    padding: 9px 13px 9px 7px;" (click)="backPressed()" data-button-name="Back"><span style="color: #0183fb;
    justify-content: center;
    align-items: center;
    display: flex;">
                                <mat-icon style="font-size: 22px;
    display: flex;
    align-items: center;">keyboard_arrow_left</mat-icon>
                            </span> Back</button>
                    </div> -->
                        <div style="display: flex;width:100%">
                            <div style="width: 100%;">
                                <span class="normTextHead">{{'SURVEY_PAGE.TEMPLATE_NAME' | i18nTranslate}}</span>
                                <div style="padding-top: 10px;">
                                    <div class="formDiv"
                                        [ngClass]="{'active' : (surveyForm.controls.surveyName.errors ? surveyForm.controls.surveyName.errors.maxlength : false) || (surveyForm.controls.surveyName.errors ? (surveyForm.controls.surveyName.errors.required && surveyForm.controls.surveyName.touched || submitClicked) : false) || (surveyForm.controls.surveyName.value.split(' ').join('').length == 0 && submitClicked)}">
                                        <input class="formInput" type="text"
                                            placeholder="Survey Template Name goes here" maxlength="100"
                                            formControlName="surveyName">

                                        <span class="charText" style="color:#222222 ;">
                                            {{100-surveyForm.controls.surveyName.value.length}} <span
                                                class="charText">/100</span> </span>
                                    </div>
                                    <div class="fontExo-14p" style="height:15px;">
                                        <div
                                            style="display: flex;flex-direction: row-reverse;justify-content: space-between;">
                                            <span style="font-size: 14px; color: #ff5f31; font-weight: 600; "
                                                *ngIf="surveyForm.controls.surveyName.errors ? (surveyForm.controls.surveyName.errors.required && (surveyForm.controls.surveyName.touched || submitClicked)) : false || (surveyForm.controls.surveyName.value.split(' ').join('').length == 0 && submitClicked)">
                                                {{'SURVEY_PAGE.TEMPLATE_NAME_REQ' | i18nTranslate}}</span>
                                            <!-- <span style="font-size: 12px; color: #ff5f31; "
                                        *ngIf="surveyForm.controls.surveyName.errors ? surveyForm.controls.surveyName.errors.maxlength : false">Maximum
                                        50 characters allowed</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="width: 50%;">
                    <div style="width: 90%;">   
                        <span class="normTextHead">{{'SURVEY_PAGE.SURVEY_TITLE' | i18nTranslate}}</span>
                        <div style="padding-top: 10px;">
                            <div class="formDiv"
                                [ngClass]="{'active' : (surveyForm.controls.surveyTitle.errors ? surveyForm.controls.surveyTitle.errors.maxlength : false) || (surveyForm.controls.surveyTitle.errors ? (surveyForm.controls.surveyTitle.errors.required && surveyForm.controls.surveyTitle.touched || submitClicked) : false) || (surveyForm.controls.surveyTitle.value.split(' ').join('').length == 0 && submitClicked)}">
                                <input class="formInput" type="text" placeholder="Survey Title goes here" maxlength="100"
                                    formControlName="surveyTitle">
    
                                <span class="charText" style="color:#222222 ;">
                                    {{100-surveyForm.controls.surveyTitle.value.length}} <span class="charText">/100</span>
                                </span>
                            </div>
                            <div class="fontExo-14p" style="height:15px;">
                                <div style="display: flex;flex-direction: row-reverse;justify-content: space-between;">
                                    <span style="font-size: 14px; color: #ff5f31; font-weight: 600; "
                                        *ngIf="surveyForm.controls.surveyTitle.errors ? (surveyForm.controls.surveyTitle.errors.required && (surveyForm.controls.surveyTitle.touched || submitClicked)) : false || (surveyForm.controls.surveyTitle.value.split(' ').join('').length == 0 && submitClicked)">
                                        {{'SURVEY_PAGE.TITLE_REQ' | i18nTranslate}}</span>
                                    <!-- <span style="font-size: 12px; color: #ff5f31; "
                                    *ngIf="surveyForm.controls.surveyName.errors ? surveyForm.controls.surveyName.errors.maxlength : false">Maximum
                                    50 characters allowed</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>


           

            <!-- <div class="seperator1"></div> -->
            <div style="overflow:auto" *ngIf="!formempty">
               
                <div class="viewcustomerContanier" *ngIf="!formempty">
                    <div style="display: flex;flex-direction: column;">
                        <!-- <div style="width: 11%;"></div> -->
                        <div class="customerContainer">

                            <div class="allFont" style="margin-top: 1%;">


                                <div class="segment-rule-container">
                                    <div *ngIf="!formempty ">
                                        <div formArrayName="SurveyQuestionArray">
                                            <div cdkDropList (cdkDropListDropped)="drop($event)">
                                                <div style="margin-bottom: 18px;"
                                                    *ngFor="let surveyQuestion of surveyForm.get('SurveyQuestionArray')['controls']; let i = index"
                                                    cdkDragBoundary=".segment-rule-container" cdkDragLockAxis="y"
                                                    cdkDrag>
                                                    <div>
                                                        <div [formGroupName]="i" style="background: #f5f5f5;">
                                                            

                                                            <div style="display: flex;">
                                                                <div class="Mask">
                                                                    <div
                                                                    style="display: flex; justify-content: space-between; margin-bottom: 10px;width: 100%;">
                                                                    <div class="normText-ques">{{'SURVEY_PAGE.QUESTION' | i18nTranslate}}
                                                                        {{i+1}}
                                                                    </div>
                                                                    <div class="icons-alignment">
                                                                        <span
                                                                        class="material-icons-outlined icons-primary"
                                                                        style=" cursor: pointer; color: #4379EF;"
                                                                        *ngIf="surveyForm.get('SurveyQuestionArray').controls.length > 1"
                                                                        (click)="removeSurveyQuestion(i)"
                                                                        data-button-name="Delete Question"
                                                                        alt="Back">delete
                                                                    </span>
    
                                                                    <div class="vertical-line"></div> <!-- Vertical line divider -->
    
                                                                        <mat-slide-toggle formControlName="questionRequired"
                                                                            labelPosition="before" class="normText">{{'SURVEY_PAGE.REQUIRED' | i18nTranslate}}
                                                                        </mat-slide-toggle>
    
                                                                        
                                                                    </div>
                                                                </div>
                                                            <div class="question-option-container">
                                                                <div class="question-container">
                                                                    <div >
                                                                        <div
                                                                            style="display: flex;align-items: center;">
                                                                            <!-- <div class="dragIcon" cdkDragHandle>
                                                                                <span
                                                                                    class="material-icons-outlined icons-primary">
                                                                                    open_with
                                                                                </span>
                                                                            </div> -->
                                                                            <div class="normTextHead">{{'SURVEY_PAGE.ENTER_QUESTION' | i18nTranslate}}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="Rectangle" [id]="'surveysec-'+i"
                                                                            [ngClass]="{'active' : surveyQuestion['controls']['surveyQuestion']['errors'] ? (surveyQuestion['controls']['surveyQuestion']['errors']['required'] && surveyQuestion['controls']['surveyQuestion']['touched'] || submitClicked) : false || (surveyQuestion['controls']['surveyQuestion'].value.split(' ').join('').length == 0 && submitClicked)}">
                                                                            <input class="Enter-your-question"
                                                                                placeholder="Enter your question here"
                                                                                type="text"
                                                                                formControlName="surveyQuestion"
                                                                                maxlength="150">
                                                                            <span class="charText"
                                                                                style="color:#222222 ;">
                                                                                {{150-surveyQuestion['controls']['surveyQuestion'].value.length}}
                                                                                <span class="charText">/150</span>
                                                                            </span>

                                                                        </div>

                                                                        <div class="fontExo-14p">
                                                                        <div
                                                                            style="display: flex;justify-content: space-between;">
                                                                            <span
                                                                                style="font-size: 14px; color: #ff5f31; font-weight: 600;"
                                                                                *ngIf="surveyQuestion.controls.surveyQuestion.errors ? (surveyQuestion.controls.surveyQuestion.errors.required && (surveyQuestion.controls.surveyQuestion.touched || submitClicked)) : false || (surveyQuestion['controls']['surveyQuestion'].value.split(' ').join('').length == 0 && submitClicked)">
                                                                                {{'SURVEY_PAGE.QUESTION_REQ' | i18nTranslate}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                        <div class="option-container">
                                                                            <div class="normTextHead">{{'SURVEY_PAGE.DEFINE_ANS_TYPE' | i18nTranslate}}
                                                                            </div>
                                                                            <div class="options-select">
                                                                                <mat-select (selectionChange)="selectSurveyType(i, $event.value)"
                                                                                [value]="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value">
                                                                      <mat-option *ngFor="let option of surveyOptions" [value]="option.value">
                                                                        {{ option.label | i18nTranslate }}
                                                                      </mat-option>
                                                                    </mat-select>
                                                                            </div>
                                                                            <!-- <div style="display: flex;border-radius: 5px;
                                                                    border: 1px solid #0183FB;">
                                                                                <div style="border-right: solid 1px #0183FB;cursor: pointer;"
                                                                                    (click)="selectSurveyType(i,'DROPDOWN')"
                                                                                    class="defanswerType"
                                                                                    [ngClass]="{active:surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value=='DROPDOWN'}">
                                                                                    {{'SURVEY_PAGE.DROP_DOWN' | i18nTranslate}}</div>
                                                                                <div style="border-right: solid 1px #0183FB;cursor: pointer;"
                                                                                    (click)="selectSurveyType(i,'RADIO')"
                                                                                    class="defanswerType"
                                                                                    [ngClass]="{active:surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value=='RADIO'}">
                                                                                    {{'SURVEY_PAGE.RADIO_BUTTON' | i18nTranslate}}</div>
                                                                                <div style="border-right: solid 1px #0183FB;cursor: pointer;"
                                                                                    (click)="selectSurveyType(i,'TEXT')"
                                                                                    class="defanswerType"
                                                                                    [ngClass]="{active:surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value=='TEXT'}">
                                                                                    {{'SURVEY_PAGE.FREE_TEXT' | i18nTranslate}}</div>
                                                                                <div style="cursor: pointer;"
                                                                                    (click)="selectSurveyType(i,'RANGE')"
                                                                                    class="defanswerType"
                                                                                    [ngClass]="{active:surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value=='RANGE'}">
                                                                                    {{'SURVEY_PAGE.RANGE' | i18nTranslate}}</div>
                                                                            </div> -->
                                                                        </div>

                                                            </div>
                                                                    <div class="selected-options">
                                                                        <!-- Code for Dropdown -->
                                                                        <div *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value === 'DROPDOWN'"
                                                                            formArrayName="surveyDropDownEntriesArray">
                                                                            <!-- <div class="Add-entries-for-the">Add entries for the
                                                                        drop
                                                                        down:</div> -->
                                                                            <div
                                                                                *ngFor="let dropDownEntry of surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyDropDownEntriesArray.controls; let j = index">
                                                                                <div [formGroupName]="j">
                                                                                    <div
                                                                                        style="display:flex;align-items: center;">
                                                                                        <!-- <div class="surveySlNo">{{j+1}}.
                                                                                        </div> -->
                                                                                        <div class="RectangleDropdownEntries"
                                                                                            [ngClass]="{'active' : dropDownEntry['controls']['surveyDropDownEntry']['errors'] ? (dropDownEntry['controls']['surveyDropDownEntry']['errors']['required'] && dropDownEntry['controls']['surveyDropDownEntry']['touched'] || submitClicked) : false || (surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyDropDownEntriesArray.controls[j]['controls']['surveyDropDownEntry'].value.split(' ').join('').length == 0 && submitClicked)}">
                                                                                            <input
                                                                                                [id]="'dropdownentry-'+i+'-'+j"
                                                                                                class="formInput"
                                                                                                type="text"
                                                                                                maxlength="100"
                                                                                                formControlName="surveyDropDownEntry"
                                                                                                placeholder="Option {{j+1}}">
                                                                                            <span class="charText"
                                                                                                style="color:#222222 ;">{{100-
                                                                                                (surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyDropDownEntriesArray.controls[j]['controls']['surveyDropDownEntry'].value.length)}}
                                                                                                <span
                                                                                                    class="charText">/100</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style="display: flex; align-items: center;">
                                                                                            <svg 
                                                                                            class="entryRemoveIcon"
                                                                                                *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyDropDownEntriesArray.controls.length>1"
                                                                                                (click)=removeDropdownEntry(i,j)
                                                                                            width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M11.2734 2.55859L7.58203 6.25L11.2734 9.97656C11.7305 10.3984 11.7305 11.1367 11.2734 11.5586C10.8516 12.0156 10.1133 12.0156 9.69141 11.5586L6 7.86719L2.27344 11.5586C1.85156 12.0156 1.11328 12.0156 0.691406 11.5586C0.234375 11.1367 0.234375 10.3984 0.691406 9.97656L4.38281 6.25L0.691406 2.55859C0.234375 2.13672 0.234375 1.39844 0.691406 0.976562C1.11328 0.519531 1.85156 0.519531 2.27344 0.976562L6 4.66797L9.69141 0.976562C10.1133 0.519531 10.8516 0.519531 11.2734 0.976562C11.7305 1.39844 11.7305 2.13672 11.2734 2.55859Z" fill="#F93C65"/>
                                                                                                </svg>
                                                                                                
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div>

                                                                                    <div class="AddNewEntry"
                                                                                        *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyDropDownEntriesArray.controls.length-1 === j"
                                                                                        (click)="addDropdownEntry(i)">
                                                                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M10.5 7.3125V8.1875C10.5 8.40625 10.3906 8.51562 10.1719 8.51562H7.76562V10.9219C7.76562 11.1406 7.65625 11.25 7.4375 11.25H6.5625C6.34375 11.25 6.23438 11.1406 6.23438 10.9219V8.51562H3.82812C3.60938 8.51562 3.5 8.40625 3.5 8.1875V7.3125C3.5 7.09375 3.60938 6.98438 3.82812 6.98438H6.23438V4.57812C6.23438 4.35938 6.34375 4.25 6.5625 4.25H7.4375C7.65625 4.25 7.76562 4.35938 7.76562 4.57812V6.98438H10.1719C10.3906 6.98438 10.5 7.09375 10.5 7.3125ZM11.7852 2.96484C13.1159 4.27734 13.7812 5.8724 13.7812 7.75C13.7812 9.6276 13.1159 11.2318 11.7852 12.5625C10.4727 13.875 8.8776 14.5312 7 14.5312C5.1224 14.5312 3.51823 13.875 2.1875 12.5625C0.875 11.2318 0.21875 9.6276 0.21875 7.75C0.21875 5.8724 0.875 4.27734 2.1875 2.96484C3.51823 1.63411 5.1224 0.96875 7 0.96875C8.8776 0.96875 10.4727 1.63411 11.7852 2.96484ZM10.8555 11.6328C11.931 10.5573 12.4688 9.26302 12.4688 7.75C12.4688 6.23698 11.931 4.95182 10.8555 3.89453C9.79818 2.81901 8.51302 2.28125 7 2.28125C5.48698 2.28125 4.19271 2.81901 3.11719 3.89453C2.0599 4.95182 1.53125 6.23698 1.53125 7.75C1.53125 9.26302 2.0599 10.5573 3.11719 11.6328C4.19271 12.6901 5.48698 13.2188 7 13.2188C8.51302 13.2188 9.79818 12.6901 10.8555 11.6328Z" fill="#4379EE"/>
                                                                                            </svg>
                                                                                            
                                                                                        <span>{{'SURVEY_PAGE.ADD_NEW_ENTRY' | i18nTranslate}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- Code for Radio -->
                                                                        <div *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value === 'RADIO'"
                                                                            formArrayName="surveyRadioEntriesArray">

                                                                            <div
                                                                                *ngFor="let radioEntry of surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyRadioEntriesArray.controls; let k = index">
                                                                                <div [formGroupName]="k">
                                                                                    <div
                                                                                        style="display:flex;align-items: baseline;">
                                                                                        <!-- <div class="surveySlNo">{{k+1}}.
                                                                                        </div> -->
                                                                                        <div class="RectangleDropdownEntries"
                                                                                            [ngClass]="{'active' : radioEntry['controls']['surveyRadioEntry']['errors'] ? (radioEntry['controls']['surveyRadioEntry']['errors']['required'] && radioEntry['controls']['surveyRadioEntry']['touched'] || submitClicked) : false || (surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyRadioEntriesArray.controls[k]['controls']['surveyRadioEntry'].value.split(' ').join('').length == 0 && submitClicked)}">
                                                                                            <input
                                                                                                [id]="'radiobuttonentry-'+i+'-'+k"
                                                                                                class="formInput"
                                                                                                type="text"
                                                                                                maxlength="100"
                                                                                                formControlName="surveyRadioEntry"
                                                                                                placeholder="Option {{k+1}}">
                                                                                            <span class="charText"
                                                                                                style="color:#222222 ;">{{100-
                                                                                                (surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyRadioEntriesArray.controls[k]['controls']['surveyRadioEntry'].value.length)}}
                                                                                                <span
                                                                                                    class="charText">/100</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style="display: flex; align-items: center;">
                                                                                            <mat-icon
                                                                                                class="entryRemoveIcon"
                                                                                                *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyRadioEntriesArray.controls.length>1"
                                                                                                (click)=removeRadioEntry(i,k)>
                                                                                                cancel
                                                                                            </mat-icon>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div class="AddNewEntry"
                                                                                        *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyRadioEntriesArray.controls.length-1 === k"
                                                                                        (click)="addRadioEntry(i)">
                                                                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M10.5 7.3125V8.1875C10.5 8.40625 10.3906 8.51562 10.1719 8.51562H7.76562V10.9219C7.76562 11.1406 7.65625 11.25 7.4375 11.25H6.5625C6.34375 11.25 6.23438 11.1406 6.23438 10.9219V8.51562H3.82812C3.60938 8.51562 3.5 8.40625 3.5 8.1875V7.3125C3.5 7.09375 3.60938 6.98438 3.82812 6.98438H6.23438V4.57812C6.23438 4.35938 6.34375 4.25 6.5625 4.25H7.4375C7.65625 4.25 7.76562 4.35938 7.76562 4.57812V6.98438H10.1719C10.3906 6.98438 10.5 7.09375 10.5 7.3125ZM11.7852 2.96484C13.1159 4.27734 13.7812 5.8724 13.7812 7.75C13.7812 9.6276 13.1159 11.2318 11.7852 12.5625C10.4727 13.875 8.8776 14.5312 7 14.5312C5.1224 14.5312 3.51823 13.875 2.1875 12.5625C0.875 11.2318 0.21875 9.6276 0.21875 7.75C0.21875 5.8724 0.875 4.27734 2.1875 2.96484C3.51823 1.63411 5.1224 0.96875 7 0.96875C8.8776 0.96875 10.4727 1.63411 11.7852 2.96484ZM10.8555 11.6328C11.931 10.5573 12.4688 9.26302 12.4688 7.75C12.4688 6.23698 11.931 4.95182 10.8555 3.89453C9.79818 2.81901 8.51302 2.28125 7 2.28125C5.48698 2.28125 4.19271 2.81901 3.11719 3.89453C2.0599 4.95182 1.53125 6.23698 1.53125 7.75C1.53125 9.26302 2.0599 10.5573 3.11719 11.6328C4.19271 12.6901 5.48698 13.2188 7 13.2188C8.51302 13.2188 9.79818 12.6901 10.8555 11.6328Z" fill="#4379EE"/>
                                                                                            </svg>
                                                                                        <span>{{'SURVEY_PAGE.ADD_NEW_ENTRY' | i18nTranslate}}</span>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- code for text -->
                                                                        <div
                                                                            *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value === 'TEXT'">
                                                                            <textarea type="text"
                                                                                style=" width: 100%; height: 100px;"
                                                                                maxlength="0" minlength="0"
                                                                                disabled></textarea>
                                                                        </div>

                                                                        <!-- code for range -->
                                                                        <div
                                                                            *ngIf="surveyForm.get('SurveyQuestionArray').controls[i].controls.surveyFeedbackType.value === 'RANGE'">
                                                                            <div class="slidecontainer">
                                                                                <input type="range" min="0" max="10"
                                                                                    value="0" class="slider" disabled>
                                                                            </div>
                                                                            <div style="display: flex;
                                                                      width: 100%;
                                                                      justify-content: space-between;">
                                                                                <div
                                                                                    *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]">
                                                                                    {{number}}</div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="display:flex;">
                        <div style="position: relative;">
                            <div class="Add-Next-Que" appButtonDebounce [throttleTime]="600"
                                (throttledClick)="addnewQuestion()" style="background: #FFFFFF;
                                border: 1px solid rgba(99, 100, 102, 0.2);
                                box-sizing: border-box;
                                border-radius: 4px;
                                gap: 10px;">
                                 <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5 7.3125V8.1875C10.5 8.40625 10.3906 8.51562 10.1719 8.51562H7.76562V10.9219C7.76562 11.1406 7.65625 11.25 7.4375 11.25H6.5625C6.34375 11.25 6.23438 11.1406 6.23438 10.9219V8.51562H3.82812C3.60938 8.51562 3.5 8.40625 3.5 8.1875V7.3125C3.5 7.09375 3.60938 6.98438 3.82812 6.98438H6.23438V4.57812C6.23438 4.35938 6.34375 4.25 6.5625 4.25H7.4375C7.65625 4.25 7.76562 4.35938 7.76562 4.57812V6.98438H10.1719C10.3906 6.98438 10.5 7.09375 10.5 7.3125ZM11.7852 2.96484C13.1159 4.27734 13.7812 5.8724 13.7812 7.75C13.7812 9.6276 13.1159 11.2318 11.7852 12.5625C10.4727 13.875 8.8776 14.5312 7 14.5312C5.1224 14.5312 3.51823 13.875 2.1875 12.5625C0.875 11.2318 0.21875 9.6276 0.21875 7.75C0.21875 5.8724 0.875 4.27734 2.1875 2.96484C3.51823 1.63411 5.1224 0.96875 7 0.96875C8.8776 0.96875 10.4727 1.63411 11.7852 2.96484ZM10.8555 11.6328C11.931 10.5573 12.4688 9.26302 12.4688 7.75C12.4688 6.23698 11.931 4.95182 10.8555 3.89453C9.79818 2.81901 8.51302 2.28125 7 2.28125C5.48698 2.28125 4.19271 2.81901 3.11719 3.89453C2.0599 4.95182 1.53125 6.23698 1.53125 7.75C1.53125 9.26302 2.0599 10.5573 3.11719 11.6328C4.19271 12.6901 5.48698 13.2188 7 13.2188C8.51302 13.2188 9.79818 12.6901 10.8555 11.6328Z" fill="#4379EE"/>
                                    </svg>
                                <span class="normText">{{'SURVEY_PAGE.ADD_NEW_QUESTION' | i18nTranslate}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div> -->
            <div class="viewcustomerContanier" style="width: 100%;justify-content: center;" *ngIf="formempty">
                <div style="display: flex;
              align-items: center;
              position: relative;
              height: 50vh;
              justify-content: center;
            flex-direction: column;">

                    <div style="display: flex;
                      justify-content: center;">
                        <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/survey/new-survey.svg"
                            alt="">

                    </div>
                    <div class="primary-button" (click)="addnewQuestion()" data-button-name="Add Your First Question">
                        <mat-icon class="AddNewEntryIcon2">
                            add
                        </mat-icon>
                        <span class="normText" style="color: #fff;">{{'SURVEY_PAGE.ADD_YOUR_FIRST_QUESTION' | i18nTranslate}}</span>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>



    <!-- listing -->
    <div #surveyHtml hidden="true">
        <div>
            <div class="session-newsurvey" id="mainDiv">
                <div class="asterisk-mandatory">{{'SURVEY_PAGE.ALL_FIELDS_MARKED' | i18nTranslate}} (<span
                        class="asterisk-only">*</span>) {{'SURVEY_PAGE.ARE_REQ' | i18nTranslate}} </div>
                <form id="formId">
                    <div id="questionDiv">
                        <div *ngFor="let survey of surveyArray; let i = index;" class="question-section-single">
                            <div class="surveyarray-head">
                                <div class="display-flex-100">
                                    <div class="width-100">
                                        <div class="question-head">
                                            <p class="question-vrfield" [id]="'Q-'+i"
                                                [attr.required]="survey.questionRequired=='true'||survey.questionRequired==true">
                                                {{survey.surveyQuestion}}<span class="question-required-marker"><sup
                                                        *ngIf="survey.questionRequired=='true'||survey.questionRequired==true"
                                                        class="asterisk-only">*
                                                    </sup></span></p>
                                        </div>
                                        <div>
                                            <div *ngIf="survey.surveyFeedbackType === 'RADIO'" class="di-flex">
                                                <div *ngFor="let entry of survey.surveyRadioEntriesArray"
                                                    class="survey-radioentry">
                                                    <input class="survey-radio-input" type="radio" id="r"
                                                        [name]="'B-'+i" [value]="entry['surveyRadioEntry']">
                                                    <label class="survey-radioentry-text"
                                                        for="male">{{entry['surveyRadioEntry']}}</label><br>
                                                </div>
                                            </div>
                                            <div *ngIf="survey.surveyFeedbackType === 'DROPDOWN'">
                                                <div class="survey-dropdownentry">
                                                    <select [id]="'S-'+i" name="a12"
                                                        class="survey-dropdownentry-select">
                                                        <option value=null>
                                                        </option>
                                                        <option [value]="entry['surveyDropDownEntry']"
                                                            *ngFor="let entry of survey.surveyDropDownEntriesArray">
                                                            {{entry['surveyDropDownEntry']}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div *ngIf="survey.surveyFeedbackType === 'TEXT'">
                                                <textarea type="text" [id]="'T-'+i" class="survey-textarea-input"
                                                    maxlength="1000"></textarea>
                                                <div [id]="'V-'+i" class="survey-textarea-limitvalidation-text">1000
                                                    {{'SURVEY_PAGE.CHARCTER_REMAINING' | i18nTranslate}}</div>
                                            </div>
                                            <div *ngIf="survey.surveyFeedbackType === 'RANGE'">
                                                <div class="slidecontainer">
                                                    <input type="range" min="0" max="10" value="0" class="slider"
                                                        [id]="'R-'+i">
                                                </div>
                                                <div class="slidernumber-range">
                                                    <div *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]">{{number}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="survey-submitarea" *ngIf="!isPos">
                        <button class="suvey-submitbuttom"  onclick='javascript:submitForm()'>Submit
                            feedback</button>
                        <div id="validationDiv" class="survey-submitbutton-validationtext"></div>
                    </div> -->
                    <div class="survey-submitarea">
                        <button class="suvey-submitbuttom" id="btn" type="button">{{'SURVEY_PAGE.SUBMIT_FEEDBACK' | i18nTranslate}}</button>
                        <div id="validationDiv" class="survey-submitbutton-validationtext"></div>
                    </div>
                </form>
            </div>
            <div id="feedbackDiv" class="feedbackdiv-section">
                <span id="feedbackSpan"></span>
            </div>
        </div>
        <div class="session-surveycheck">
            <div class="survey-ssr">
                <div class="surveycheck-result-text">
                    {{'SURVEY_PAGE.ALREADY_SUBMITTED_SURVEY' | i18nTranslate}}
                </div>
            </div>
        </div>
        <div class="session-error">
            <div class="session-error-section">
                <div class="surveycheck-result-text">
                    {{'SURVEY_PAGE.ERROR_TRY_AGAIN' | i18nTranslate}}
                </div>
            </div>
        </div>
    </div>
</div>