<div class="qb-single-game-container qb-main-container" id="qb-single-game-container">
  <div class="qb-main-head">
    <div class="qb-heading">
      {{gameName}}
    </div>
    <div class="qb-flag-container" *ngIf="StatusGame=='LIVE'">
        <div
        class="material-icons icons-primary qb-flag-font qb-flag-live"
      >
        flag
      </div>
      <div class="qb-flag-live qb-font-size-16">{{StatusGame}}</div>
    </div>
    <div class="qb-flag-container" *ngIf="StatusGame=='DRAFT'">
      <div
      class="material-icons icons-primary qb-flag-font qb-flag-draft"
    >
      flag
    </div>
    <div class="qb-flag-draft qb-font-size-16">{{StatusGame}}</div>
  </div>
  <div class="qb-flag-container" *ngIf="StatusGame=='SCHEDULED'">
    <div
    class="material-icons icons-primary qb-flag-font qb-flag-scheluded"
  >
    flag
  </div>
  <div class="qb-flag-scheluded qb-font-size-16">{{StatusGame}}</div>
</div>
<div class="qb-flag-container" *ngIf="StatusGame=='ENDED'">
  <div
  class="material-icons icons-primary qb-flag-font qb-flag-ended"
>
  flag
</div>
<div class="qb-flag-ended qb-font-size-16">{{StatusGame}}</div>
</div>
<div class="qb-flag-container" *ngIf="StatusGame=='PAUSED'">
  <div
  class="material-icons icons-primary qb-flag-font qb-flag-paused"
>
  flag
</div>
<div class="qb-flag-paused qb-font-size-16">{{StatusGame}}</div>
</div>
  </div>
  <div class="qb-game-summary-padding">
    <div class="qb-card">
      <div class="qb-game-summary-text"> 
        Game Summary
      </div>
      <div class="qb-d-flex">
        <div class="qb-lh-32 qb-min-w-25">
          <div class="qb-font-weight">{{'GAMIFY_PAGE.PRODUCT_DISCOUNT' | i18nTranslate}} </div>
          <div>{{'GAMIFY_PAGE.DISCOUNT_RANGE' | i18nTranslate}}{{discountLowerLimit}}% - {{discountUpperLimit}}%</div>
          <div *ngIf="!AllProductExcludedCheck">{{ProductExcludedLength}} products excluded from the game</div>
          <div *ngIf="AllProductExcludedCheck">All Products excluded from the game</div>
        </div>
        <div class="qb-verticalLine"></div>
        <div class="qb-lh-32 qb-min-w-20">
          <div class="qb-font-weight">{{'GAMIFY_PAGE.TARGET_AUDIENCE' | i18nTranslate}}</div>
          <!-- <div *ngIf="AllCustomersCheck">All Customers</div> -->
          <div class="qb-d-flex">
            <div *ngFor="let target of TargetAudiance;">
              {{target | titlecase}}, &nbsp;
            </div>
          </div>
          <div>{{'GAMIFY_PAGE.TOTAL_AUDIENCE' | i18nTranslate}} - {{TotalTargetCustomer}}</div>
        </div>
        <div class="qb-verticalLine"></div>
        <div class="qb-lh-32 qb-min-w-30">
          <div class="qb-font-weight">{{'GAMIFY_PAGE.GAME_CONFIG' | i18nTranslate}}</div>
          <div>{{totalGameSteps}} {{'GAMIFY_PAGE.STEPS' | i18nTranslate}}, {{productsPerStep}} {{'GAMIFY_PAGE.PRODUCTS_PER_STEPS' | i18nTranslate}}</div>
          <div><span *ngIf="coolOffPeriod['days']>0">{{coolOffPeriod['days']}} days</span> <span *ngIf="coolOffPeriod['hours']>0">{{coolOffPeriod['hours']}}hrs</span> <span *ngIf="coolOffPeriod['minutes']>0">{{coolOffPeriod['minutes']}}mins</span> <span *ngIf="coolOffPeriod['seconds']>0">{{coolOffPeriod['seconds']}}sec</span> cool off period, <span *ngIf="initialHaltDuration['days']>0">{{initialHaltDuration['days']}} days</span> <span *ngIf="initialHaltDuration['hours']>0">{{initialHaltDuration['hours']}}hrs</span> <span *ngIf="initialHaltDuration['minutes']>0">{{initialHaltDuration['minutes']}}mins</span> <span *ngIf="initialHaltDuration['seconds']>0">{{initialHaltDuration['seconds']}}sec</span> intital halt,</div>
           <div>{{'GAMIFY_PAGE.TIMETO_REDEEM' | i18nTranslate}} <span *ngIf="timeToRedeemReward['days']>0">{{timeToRedeemReward['days']}} days</span> <span *ngIf="timeToRedeemReward['hours']>0">{{timeToRedeemReward['hours']}}hrs</span> <span *ngIf="timeToRedeemReward['minutes']>0">{{timeToRedeemReward['minutes']}}mins</span> <span *ngIf="timeToRedeemReward['seconds']>0">{{timeToRedeemReward['seconds']}}sec</span>, and </div> 
           <div>{{maxRetries}} {{'GAMIFY_PAGE.RETRIES_ALLOWED' | i18nTranslate}}</div>
        </div>
        <div class="qb-verticalLine"></div>
        <div class="qb-lh-32 qb-min-w-25">
          <div class="qb-font-weight">{{'GAMIFY_PAGE.SCHEDULE' | i18nTranslate}}</div>
          <div>{{startDate | date:getDateFormat('dd MMM yyyy')}} - {{endDate | date:getDateFormat('dd MMM yyyy')}}</div>
          <div *ngIf = "atAnyTime">At any time</div>
          <div *ngIf = "!atAnyTime">{{startTime | date : 'HH:mm a'}} - {{endTime | date : 'HH:mm a'}}</div>
          <div *ngIf="AllDaysOfWeekCheck">{{'GAMIFY_PAGE.ALL_DAY_OF_WEEK' | i18nTranslate}}</div>
          <div *ngIf="!AllDaysOfWeekCheck qb-d-flex">
            <div *ngFor="let day of daysOfTheweek">
              {{day | titlecase}} &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div class="qb-d-flex qb-jc-flex-end">
        <div class="qb-d-flex qb-mt-5" (click)="showPreview()">
          <mat-icon class="qb-template-attach-icon">attach_file</mat-icon>
          <div class="qb-email-name">{{'GAMIFY_PAGE.NEGOTIATOR_REWARD_EMAIL' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-rectangle-padding">
    <div class="qb-RectangleCards">
      <div class="qb-RectangleCard">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-HeadingText">{{'GAMIFY_PAGE.IMPRESSION' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentImpression">
            <ng-template #popContentImpression class="qb-ng-template-background">
              <div class="qb-description-text">
                <div class="qb-d-inline-flex" >{{'GAMIFY_PAGE.IMPRESSION_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Impression.svg" alt="">
          </div>          
        </div>
        <div class="qb-ValueText">
          {{ImpressionValue}}
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_TARGET_CUSTOMER' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalTargetCustomer}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_VIEWS' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalViews}}</div>
        </div>
        <hr class="qb-hrline">
        <div  class="qb-d-flex qb-jc-flex-end">
          <div class="qb-d-flex qb-pl-5">
            <div>{{'GAMIFY_PAGE.DESKTOP' | i18nTranslate}} &nbsp;{{DeskstopPercentage}}%</div>
            <div>&nbsp; {{"GAMIFY_PAGE.MOBILE" | i18nTranslate}} &nbsp;{{MobilePercentage}}%</div>
          </div>
        </div>
      </div>
      <div class="qb-RectangleCard qb-ml-10">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-HeadingText">{{'GAMIFY_PAGE.ENGAGEMENT' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentEngagement">
            <ng-template #popContentEngagement class="qb-ng-template-background">
              <div class="qb-description-text">
                <div class="qb-d-inline-flex">{{'GAMIFY_PAGE.ENGAGEMENT_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Conversion-rate.svg" alt="">
          </div>          
        </div>
        <div class="qb-ValueText">
          {{EngagementValue}}%
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_PEOPLE_PLAYED' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalPeoplePlayed}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.NO_PEOPLE_COMPLETED' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{NoPeopleCompleted}}</div>
        </div>
        <hr class="qb-hrline">
        <div  class="qb-d-flex qb-jc-flex-end">
          <div class="qb-d-flex qb-pl-5">
            <div>{{'GAMIFY_PAGE.DESKTOP' | i18nTranslate}} &nbsp;{{DeskstopPercentageEngagement}}%</div>
            <div>&nbsp; {{'GAMIFY_PAGE.MOBILE' | i18nTranslate}} &nbsp;{{MobilePercentageEngagement}}%</div>
          </div>
        </div>
      </div>
      <div class="qb-RectangleCard qb-ml-10">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-HeadingText">{{'GAMIFY_PAGE.CONVERSION_RATE' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentConversion">
            <ng-template #popContentConversion class="qb-ng-template-background">
              <div class="qb-description-text">
                <div class="qb-d-inline-flex">{{'GAMIFY_PAGE.CONVERSION_RATE_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Engagement.svg" alt="">
          </div>          
        </div>
        <div class="qb-ValueText">
          {{ConversionRate}}%
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_PURCHASES' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalPurchases}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.ANONYMOUS_PURCHASE' | i18nTranslate}} </div>
          <div class="qb-text-format-stat">{{AnonymusPurchases}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.EMAIL_COLLECTED' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{EmailCollected}}</div>
        </div>
      </div>
      <div class="qb-RectangleCard qb-ml-10">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-HeadingText">{{'GAMIFY_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
            <!-- <div class="qb-i-div-survey-dash">
              <div class="qb-i">i</div>
            </div> -->
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Revenue.svg" alt="">
          </div>          
        </div>
        <div class="qb-ValueText">
          {{RevenueGenerated | customCurrency: revCurrency: true }}
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">via Desktop</div>
          <div class="qb-text-format-stat">{{viaDeskstop | customCurrency: revCurrency: true }}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">via Mobile</div>
          <div class="qb-text-format-stat">{{viaMobile | customCurrency: revCurrency: true }}</div>
        </div>
        <hr class="qb-hrline">
        <div  class="qb-d-flex qb-jc-flex-end">
          <div class="qb-d-flex qb-pl-5">
            <div>Desktop &nbsp;{{DeskstopPercentageRevenue}}%</div>
            <div>&nbsp; Mobile &nbsp;{{MobilePercentageRevenue}}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-revenue-generated-div">
    <div class="qb-graph-card">
      <div class="qb-graph-header">
        <div class="qb-graph-title">
          Revenue Generated
        </div>

        <div class="qb-home-date-range-selector" (click)="openSelect()">
          <div class="qb-calender-container">
            <span
              class="material-icons icons-primary qb-calender-icon-span"
            >
              calendar_today
            </span>
          </div>
          <div class="qb-select-div-2">
            <mat-select
              #select
              class="qb-graph-select"
              [(ngModel)]="range"
              
              [value]="range"
            >
              <mat-option
                value="TODAY"
                >Today</mat-option
              >
              <mat-option
                value="YESTERDAY"
                >Yesterday</mat-option
              >
              <mat-option
                value="LAST_7_DAYS"
                >Last Week</mat-option
              >
              <mat-option
                value="LAST_15_DAYS"
                >Last 15 Days</mat-option
              >
              <mat-option
                value="LAST_30_DAYS"
                >Last 30 Days</mat-option
              >
              <mat-option
                value="LAST_90_DAYS"
                >Last 90 Days</mat-option
              >
              <mat-option
                value="LAST_6_MONTHS"
                >Last 6 Months</mat-option
              >
              <mat-option
                value="LAST_1_YEAR"
                >Last Year</mat-option
              >
              <mat-option
                value="PREV_WEEK"
                >Previous Week</mat-option
              >
              <mat-option
                value="THIS_WEEK"
                >This Week</mat-option
              >
              <mat-option
                value="THIS_MONTH"
                >This Month</mat-option
              >
              <mat-option
                value="PREV_MONTH"
                >Previous Month</mat-option
              >
              <mat-option
                value="THIS_QUARTER"
                >This Quarter</mat-option
              >
              <mat-option
                value="PREV_QUARTER"
                >Previous Quarter</mat-option
              >
              <mat-option
                value="THIS_YEAR"
                >This Year</mat-option
              >
              <mat-option
                value="PREV_YEAR"
                >Preious Year</mat-option
              >
              <!-- <mat-option
                value="CUSTOM"
                >Custom</mat-option
              > -->
            </mat-select>
          </div>
        </div>


      </div>
      <div class="qb-graph-div"> 
        <div *ngIf="RevenueGenerated !=0">
          <app-negotiator-single-game-graph
  [selectedGraphButton]="range"
  [width]="1000"
  [gameid]="gameid"></app-negotiator-single-game-graph>
        </div>
        <div>
          <div
          *ngIf="RevenueGenerated == 0 "
          class="qb-no-data-div"
        >
          <!-- <div style="display: flex;justify-content: center;align-items: center;"> -->
          <!-- <div
            style="
              padding: 10px;
              border: 1px dotted #ff3b30;
              width: 50%;
              background: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            No Data Available
          </div> -->
          <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt="" style="padding-top: 50px;">
          <div style="margin-top: 15px;">
            There is no data available currently for this report.
          </div>
          <!-- </div> -->
        </div>
        </div>
      </div>
    </div>
  </div>
</div>