import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddUserComponent, ChangePasswordDialogComponent, CommunicationScreenComponent, DeleteUserComponent, DomainScreenComponent, PlanDetailsComponent } from './components';
import { ProfileUserComponent } from './pages';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { EditShopDetailsComponent } from './pages/edit-shop-details/edit-shop-details.component';
import { AgmCoreModule } from '@agm/core';
import { StoreInfoComponent } from './pages/store-info/store-info.component';
import { StoreDetailsComponent } from './pages/store-details/store-details.component';
import { StoreKeysComponent } from './pages/store-keys/store-keys.component';
import { StoreDataManualComponent } from './pages/store-data-manual/store-data-manual.component';

@NgModule({
  declarations: [
    AddUserComponent,
    ChangePasswordDialogComponent,
    DeleteUserComponent,
    DomainScreenComponent,
    PlanDetailsComponent,
    ProfileUserComponent,
    EditShopDetailsComponent,
    StoreInfoComponent,
    StoreDetailsComponent,
    StoreKeysComponent,
    StoreDataManualComponent,
    CommunicationScreenComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UserProfileRoutingModule,
    AgmCoreModule,
  ]
})
export class UserProfileModule { }
