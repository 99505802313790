import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loyalty-rule-preview-dialog',
  templateUrl: './loyalty-rule-preview-dialog.component.html',
  styleUrls: ['./loyalty-rule-preview-dialog.component.css']
})
export class LoyaltyRulePreviewDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoyaltyRulePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  rule;
  loyaltyPoints:{};
  offerList = [];
  applicableCustomerGroupList = [];
  loyaltyRules:[];

  ngOnInit(): void {
    this.rule = this.data['rule'];
    this.loyaltyPoints = this.data['loyaltyPoints'];
    this.offerList = this.data['offerList'];
    this.applicableCustomerGroupList = this.data['applicableCustomerGroupList'];
    this.loyaltyRules = this.data['loyaltyRules'];
  }

  closedialog(){
    this.dialogRef.close();
  }

  getOfferNameFromId(id) {
    if(this.offerList.length>1) {
      return (this.offerList.find(obj=>obj.id == id)).offerName;
    } else {
      return null;
    }
  }

  getExplicitlyExcludedProductNames(rule) {
    let allInclusionRuleProducts = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
      return rule.excludedProducts.filter(productName=>!allInclusionRuleProducts.includes(productName));
    }
    return rule.excludedProducts;
  }

  getExplicitlyExcludedCategoryNames(rule) {
    let allInclusionRuleCategories = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
      return rule.excludedProductCategories.filter(categoryName=>!allInclusionRuleCategories.includes(categoryName));
    }
    return rule.excludedProductCategories;
  }

  getGroupName(id) {
    let res = this.applicableCustomerGroupList.find(cs=>cs.id==id);
    return res?.name || res?.segmentName;
  }

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

}
