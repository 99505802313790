import { Injectable, OnInit } from "@angular/core";
import { TokenStorageService } from "../auth/token-storage.service";

@Injectable()
export class getLogoUrlService implements OnInit {
  currentUrl = window.location.origin;
  constructor(
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {}

  getSideNavLogo(){
    if(this.tokenStorage.getMerchantOnlineStore() === 'restomail' || this.currentUrl.includes('restomail') ){
        return 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/logo/restomail_logo.png';
    }
    else{
        return 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/logo/Qubriux+Logo+Design_Inverse+White.svg'
    }
  }

  getSideNavLogoIcon(){
    if(this.tokenStorage.getMerchantOnlineStore() === 'restomail' || this.currentUrl.includes('restomail') ){
        return 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/logo/restomail_color_logo.png';
    }
    else{
        return 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/logo/colored_qubriux_icon.png'
    }
  }

  getLogo(){
    if(this.currentUrl.includes('restomail') || this.tokenStorage.getMerchantOnlineStore() === 'restomail'){
        return 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/logo/restomail_color_logo.png';
    }
    else{
        return 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/logo/Qubriux+Logo+Design+1.svg';
    }
  }
}
