import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentConfirmationComponent, PaymentOrderSummaryComponent, PaymentPlanComponent, PaymentPosComponent, PaymentRedirectComponent, PaymentTopupComponent, PaymentTopUpPosComponent } from './pages';

const routes: Routes = [
    { path: "", redirectTo: "payment-plan", pathMatch: "full" },
    { 
        path: 'payment-plan', 
        component: PaymentPlanComponent, 
        data: { titleheading: "Payment", title: "Payment Plan" },  
    },
    { 
        path: 'payment-plan-pos', 
        component: PaymentPosComponent, 
        data: { titleheading: "Payment-POS", title: "Payment Plan POS" },  
    },
    {
        path: "top-up",
        component: PaymentTopupComponent,
        data: { titleheading: "Top-up", title: "Top-up" },
    },
    {
        path: "top-up-pos",
        component: PaymentTopUpPosComponent,
        data: { titleheading: "Top-up-POS", title: "Top-up" },
    },
    {
        path: "order-summary",
        component: PaymentOrderSummaryComponent,
        data: { titleheading: " ", title: "Order Summary" },
    },
    {
        path: "payment-confirmed",
        component: PaymentConfirmationComponent,
        data: { titleheading: " ", title: "Booking Confirmation" },
    },
    {
        path: "payment-redirect",
        component: PaymentRedirectComponent,
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule { }
