import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-pos-sucess-dialog',
  templateUrl: './payment-pos-sucess-dialog.component.html',
  styleUrls: ['./payment-pos-sucess-dialog.component.css']
})
export class PaymentPosSucessDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<PaymentPosSucessDialogComponent>,
    // private apicall: ApiCallService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log("DATA", this.data)
  }
  submit(){
    this.dialogRef.close();
    // this.router.navigate(["/app/app-home"], {
    //   queryParams: { type: "Objectives" },
    // });
    let status = this.data['status'] 
    switch(status){
      case "ACTIVE":
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: "Objectives" },
        });
        break;
      case "INACTIVE":
        this.router.navigate(['payment-pos']);
        break;
      case "INPROCESS":
        this.router.navigate(['store-inprogress']);
        break;
      case true:
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: "Objectives" },
        });
        break;
      case false:
        this.router.navigate(["/app/app-home"], {
           queryParams: { type: "Objectives" },
        });
        break;
    } 
  }

}
