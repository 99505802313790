import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SmsTemplateCreationDialogComponent, SmsScheduleDialogComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-sms-template-creation",
  templateUrl: "./sms-template-creation.component.html",
  styleUrls: ["./sms-template-creation.component.css"],
})
export class SmsTemplateCreationComponent implements OnInit {
  constructor(
    private _ApiCallService: ApiCallService,
    private _ActivatedRoute: ActivatedRoute,
    private _MatDialog: MatDialog,
    private _Router : Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  customerSegments = [];
  returnURL;
  smsId;

  ngOnInit() {
    console.log("added1");
    this._ActivatedRoute.params.subscribe((params) => { 
      this.returnURL = params.return;
      if (params.id === "null") {
        let dialogRef = this._MatDialog.open(
          SmsTemplateCreationDialogComponent,
          {
            data: null,
            panelClass: "create-sms-dialog-container",
            width: "80%",
            height: "90vh",
            disableClose: false,
          }
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (result["schedule"]) {
              this.sendNow(result["id"], null);
            } else {
              this.smsId = result['id'];
              this.openSuccessDialog(
                this._i18nDynamicTranslate.transform("Your SMS has been successfully created", ['POS_PAGE']),
                this._i18nDynamicTranslate.transform("OK", ['POS_PAGE'])
              );
            }
          }
          else{
            this._Router.navigate(['app/sms/'+this.returnURL]);
          }
        });
      } else {
        this.smsId = params.id;
        this._ApiCallService.getSmsTemplate(params.id).subscribe((response) => {
          let layout = response["body"];
          if (params.clone === "false") {
            let dialogRef = this._MatDialog.open(
              SmsTemplateCreationDialogComponent,
              {
                data: {
                  layoutData: layout,
                  clone: false,
                },
                panelClass: "create-sms-dialog-container",
                width: "80%",
                height: "90vh",
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                if (result["schedule"]) {
                  this.sendNow(result["id"], null);
                } else {
                  this.openSuccessDialog(
                    this._i18nDynamicTranslate.transform("Your SMS has been successfully edited", ['POS_PAGE']),
                    this._i18nDynamicTranslate.transform("OK", ['POS_PAGE'])
                  );
                }
              }
            });
          } else {
            let dialogRef = this._MatDialog.open(
              SmsTemplateCreationDialogComponent,
              {
                data: {
                  layoutData: layout,
                  clone: true,
                },
                panelClass: "create-sms-dialog-container",
                width: "80%",
                height: "90vh",
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                if (result["schedule"]) {
                  this.sendNow(result["id"], null);
                } else {
                  this.openSuccessDialog(
                    this._i18nDynamicTranslate.transform("Your SMS has been successfully cloned", ['POS_PAGE']),
                    this._i18nDynamicTranslate.transform("OK", ['POS_PAGE'])
                  );
                }
              }
            });
          }
        });
      }
    });
  }

  sendNow(layoutId, event) {
    this.getCustomerSegments();
    let interval = setInterval(() => {
      if (this.customerSegments.length > 0) {
        clearInterval(interval);
        let dialogRef = this._MatDialog.open(SmsScheduleDialogComponent, {
          panelClass: "schedule-sms-dialog-container",
          data: {
            customerSegments: this.customerSegments,
          },
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            let data = {
              templateId: layoutId,
              scheduleType: "INSTANT",
              scheduleName: "",
              channel: "SMS",
              delay: "",
              segmentIds: result[0] === "ALL" ? null : result,
              scheduleId: "",
              status: "ACTIVE",
            };
            this._ApiCallService.scheduleSMS(data).subscribe((response) => {
              this.smsId = layoutId;
              this.openSuccessDialog(
                this._i18nDynamicTranslate.transform("Your SMS has been successfully sent", ['POS_PAGE']),
                this._i18nDynamicTranslate.transform("OK", ['POS_PAGE'])
              );
            });
          }else{
            this._Router.navigate(['app/sms/'+this.returnURL,{id:layoutId}]);
          }
        });
      }
    });
  }

  getCustomerSegments() {
    this._ApiCallService.getAllCustomerSegments().subscribe(
      (response) => {
        let body = JSON.parse(response["body"]);
        for (let i = 0; i < body.length; i++) {
          if (body[i].isActive === true) {
            this.customerSegments.push(body[i]);
          }
        }
      },
      (err) => {}
    );
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this._MatDialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this._Router.navigate(['app/sms/'+this.returnURL,{id:this.smsId}]);
    });
  }
}
