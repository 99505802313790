import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { assetPath } from 'src/app/configs/asset-path-config';
import { ExternalAPI } from 'src/app/configs/externalAPI';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';


export interface DialogData {
  'type': string,
  'id': []
}

@Component({
  selector: 'app-ecommerce-dialog',
  templateUrl: './ecommerce-dialog.component.html',
  styleUrls: ['./ecommerce-dialog.component.css']
})
export class EcommerceDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EcommerceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: SnackbarCollection,
    private apiCall: ApiCallService) { }

    s3BucketUrl=environment.s3BucketUrl;
  _assetPath = {
    shopify: assetPath.path + '/shop.png'
  }
  shopName = new FormControl();
  invalidShopName: boolean = false;
  authenticated: boolean = false;
  formSubmit: boolean = false;
  authInProgress: boolean = false;

  ngOnInit() {
  }

  // submitDialog() {
  //   if (this.shopName === '' || this.shopName === null) {
  //     this._snackBar.openSnackBar('Enter a shop name',2000)
  //   }
  //   else {
  //     this.dialogRef.close(this.shopName)
  //   }
  // }

  getAuthenticated() {
    // if ((this.shopName.value.length < 1) || (this.shopName.errors?.pattern)) {
    //   if (this.shopName.value.length < 1) {
    //     this.invalidShopName = true; 
    //   }
    // }
    this.formSubmit = true;
    if ((this.shopName.value === null || this.shopName.value === '') || (this.shopName.touched && this.shopName.errors)) {
      return;
    }
    else {
      this.apiCall.checkDataOnboardStatus(this.shopName.value).subscribe((response) => {
        if (response['body'] === true) {
          // this.setCookie('authenticated', 'true');
          this.authenticateShopify();
        }
        else {
          // this.setCookie('authenticated', 'false');
          this.authenticateShopify();
        }
      })
    }
  }

  setCookie(name: string, value: string, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }
  authenticateShopify() {
    this.invalidShopName = false;
    this.authInProgress = true;
    let app_nonce = "2626272222222";
    let redirect_url = window.location.protocol + '//' + window.location.host + "/data-ingestion/shopify";
    let url = "https://" + this.shopName.value + ExternalAPI.SHOPIFY_OAUTH_URL + '?client_id=' + ExternalAPI.SHOPIFY_APP_CLIENT_ID + '&scope='
      + ExternalAPI.SHOPIFY_APP_SCOPE + '&redirect_uri=' + redirect_url + "&state=" + app_nonce;
    window.open(url, '_self');
  }
  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  closeDialog() {
    this.dialogRef.close()
  }

}
