<div

  class="qb-offer-schedule-head qb-full-div"
  data-widget-name="OFFER-CHANNEL-SCHEDULE"
>
  <div class="qb-full">
    <div class="qb-reverse">
      <div class="qb-config-label">
        <div class="qb-config-label-first">
          <div>{{'PARTNERSHIP_PAGE.SELECT_CHANNEL' | i18nTranslate}}</div>
          <div>
            <mat-icon
              (click)="sendcancelMessage()"
              class="cursor-pointer"
              data-button-name="Cancel:OFFER-CHANNEL-SCHEDULE "
              >close</mat-icon
            >
          </div>
        </div>
        <div class="qb-seperator"></div>
        <div class="qb-padding " style="height: 100%; overflow: auto;">
            <div class="qb-schedule-bold-text">
                <span>{{'PARTNERSHIP_PAGE.SELECT_CHANNEL' | i18nTranslate}}</span
                ><span
                  class="qb-i-div-schedule-offer"
                  popoverClass="qb-my-custom-class"
                  placement="bottom"
                  triggers="mouseenter:mouseleave"
                >
                  i
                </span>
              </div>
              <div
              class="qb-form-div-schedule qb-full-width cursor-pointer"
              (click)="selectopenclose()"
            >
              <mat-select
                #channelSelect
                (selectionChange)="changeSelection()"
                class="qb-font-exo2"
                color="primary"
                placeholder="Select channel"
                formControlName="dispatchcustomerSegments"
                multiple
                disableOptionCentering
                panelClass="myPanelClass"
              >
                <!-- <div class="qb-rectangle">
                  <div class="qb-rect">
                    <mat-icon class="qb-icon" matPrefix>search </mat-icon>
                    <input
                      class="qb-no-border-input"
                      type="text"
                      formControlName="tempsegmentFilter"
                      placeholder=" Search by name "
                    />
                  </div>
                </div> -->
                <div class="qb-mat-select-dropdowns">
                  <mat-option
                    class="qb-font-exo2"
                    value="email"
                  >
                    {{'PARTNERSHIP_PAGE.EMAIL' | i18nTranslate}}
                  </mat-option>
                  <mat-option
                  class="qb-font-exo2"
                  value="sms"
                  *ngIf="channelDetails['beforeBarrier']['sms'].length>0"
                >
                  {{'PARTNERSHIP_PAGE.SMS' | i18nTranslate}}
                </mat-option>
                <mat-option
                class="qb-font-exo2"
                value="whatsapp"
                *ngIf="channelDetails['beforeBarrier']['whatsapp'].length>0"
              >
                {{'PARTNERSHIP_PAGE.WHATSAPP' | i18nTranslate}}
              </mat-option>
                </div>
              </mat-select>
              <div
                class="qb-keyboard-down-icon-container qb-rotate cursor-pointer"
                id="keyboard-down-icon-container"
                (click)="selectopenclose()"
              >
                <mat-icon
                  class="qb-keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                  role="img"
                  aria-hidden="true"
                  >keyboard_arrow_down</mat-icon
                >
              </div>
            </div>
            <div class="qb-schedule-bold-text qb-email-div" *ngIf="selectedChannel.length > 0">
                <span>{{'PARTNERSHIP_PAGE.COMMUNICATION_BEFORE_BARRIER' | i18nTranslate}}</span
                ><span
                  class="qb-i-div-schedule-offer"
                  popoverClass="qb-my-custom-class"
                  placement="bottom"
                  triggers="mouseenter:mouseleave"
                >
                  i
                </span>
              </div>
            <div *ngIf="selectedChannel.indexOf('email') !== -1">
                <div>
                    <div class="qb-email-div">{{'PARTNERSHIP_PAGE.OFFER_TEXT_EMAIL' | i18nTranslate}}</div>
                    <div class="qb-offer-text-div">
                      <input type="text" [(ngModel)]="offerText1" />
                    </div>
                    <div class="qb-email-div">{{'PARTNERSHIP_PAGE.EMAIL_SUB' | i18nTranslate}}</div>
                    <div class="qb-form-name-input qb-subject-div">
                      {{ emailSubject }}
                    </div>
                    <div style="display: flex; justify-content: space-between; width: 100%;">
                        <div style="width: 50%; margin-top: 30px;">{{'PARTNERSHIP_PAGE.EMAIL_TEMPLATE' | i18nTranslate}}</div>
                        <div class="qb-attach-div" style="width: 50%;" (click)="opensnapshotHtmlDialog(1)">
                          <div class="material-icons-outlined qb-attach-icon">
                            attach_file
                          </div>
                          <div class="qb-form-email">{{ templateName }}</div>
                        </div>

                    </div>
                  </div>
            </div>
            <div class="qb-seperator" *ngIf="selectedChannel.length > 0"></div>
            <div style="display: flex; width: 100%;" *ngIf="selectedChannel.indexOf('sms') !== -1">
                <div style="width: 50%; margin-top: 30px;">{{'PARTNERSHIP_PAGE.SMS_TEMPLATE' | i18nTranslate}}</div>
                <div class="qb-attach-div" style="width: 50%;" (click)="opensmsPreview()">
                  <div class="material-icons-outlined qb-attach-icon">
                    attach_file
                  </div>
                  <div class="qb-form-email">{{ templateNameSMS }}</div>
                </div>
            </div>
            <div class="qb-seperator" *ngIf="selectedChannel.length > 0"></div>
            <div style="display: flex; width: 100%;" *ngIf="selectedChannel.indexOf('whatsapp') !== -1">
                <div style="width: 50%; margin-top: 30px;">{{'PARTNERSHIP_PAGE.WHATSAPP_TEMPLATE' | i18nTranslate}}</div>
                <div class="qb-attach-div" style="width: 50%;" (click)="openWhatsAppPreview(1)">
                  <div class="material-icons-outlined qb-attach-icon">
                    attach_file
                  </div>
                  <div class="qb-form-email">{{ templateNameWhatsApp }}</div>
                </div>
            </div>
            <div class="qb-seperator qb-line" *ngIf="selectedChannel.length > 0"></div>
            <div class="qb-schedule-bold-text qb-email-div" *ngIf="selectedChannel.length > 0">
                <span>{{'PARTNERSHIP_PAGE.COMMUNICATION_AFTER_BARRIER' | i18nTranslate}}</span
                ><span
                  class="qb-i-div-schedule-offer"
                  popoverClass="qb-my-custom-class"
                  placement="bottom"
                  triggers="mouseenter:mouseleave"
                >
                  i
                </span>
              </div>
            <div *ngIf="selectedChannel.indexOf('email') !== -1">
                <div>
                    <div class="qb-email-div">{{'PARTNERSHIP_PAGE.OFFER_TEXT_EMAIL' | i18nTranslate}}</div>
                    <div class="qb-offer-text-div">
                      <input type="text" [(ngModel)]="offerText2" />
                    </div>
                    <div class="qb-email-div">{{'PARTNERSHIP_PAGE.EMAIL_SUB' | i18nTranslate}}</div>
                    <div class="qb-form-name-input qb-subject-div">
                      {{ emailSubject2 }}
                    </div>
                    <div style="display: flex; justify-content: space-between; width: 100%;">
                        <div style="width: 50%; margin-top: 30px;">{{'PARTNERSHIP_PAGE.EMAIL_TEMPLATE' | i18nTranslate}}</div>
                        <div class="qb-attach-div" style="width: 50%;" (click)="opensnapshotHtmlDialog(2)">
                          <div class="material-icons-outlined qb-attach-icon">
                            attach_file
                          </div>
                          <div class="qb-form-email">{{ templateName2 }}</div>
                        </div>

                    </div>
                  </div>
            </div>
            <div class="qb-seperator" *ngIf="selectedChannel.length > 0"></div>
            <div style="display: flex; width: 100%;" *ngIf="selectedChannel.indexOf('sms') !== -1">
                <div style="width: 50%; margin-top: 30px;">{{'PARTNERSHIP_PAGE.SMS_TEMPLATE' | i18nTranslate}}</div>
                <div class="qb-attach-div" style="width: 50%;" (click)="opensmsPreview()">
                  <div class="material-icons-outlined qb-attach-icon">
                    attach_file
                  </div>
                  <div class="qb-form-email">{{ templateNameSMS2 }}</div>
                </div>
            </div>
            <div class="qb-seperator" *ngIf="selectedChannel.length > 0"></div>
            <div style="display: flex; width: 100%;" *ngIf="selectedChannel.indexOf('whatsapp') !== -1">
                <div style="width: 50%; margin-top: 30px;">{{'PARTNERSHIP_PAGE.WHATSAPP_TEMPLATE' | i18nTranslate}}</div>
                <div class="qb-attach-div" style="width: 50%;" (click)="openWhatsAppPreview(2)">
                  <div class="material-icons-outlined qb-attach-icon">
                    attach_file
                  </div>
                  <div class="qb-form-email">{{ templateNameWhatsApp2 }}</div>
                </div>
            </div>
            <div class="qb-seperator qb-line" *ngIf="selectedChannel.length > 0"></div>
        </div>
        <div
        class="qb-footer-labels qb-labels2">
        <div class="qb-cancel-button" (click)="sendcancelMessage()">{{'PARTNERSHIP_PAGE.CANCEL' | i18nTranslate}}</div>
        <div class="qb-confirm-button" (click)="save()">
          {{'PARTNERSHIP_PAGE.SAVE' | i18nTranslate}}
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #popContentChannel>
  <div class="qb-flex" *ngIf="(validScreens | screenConfig: 18)">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        Choose the channel of communication (SMS/Email/WhatsApp) to the customer.
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentChallengeTemplate>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-bulb-icon"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        Choose or create the template as per the requirements.
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentRewardTemplate>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90" style="color: white;">
      <div
        class="qb-choose-channel"
        *ngIf="rawJson['isNoneBarrierDetected'] != true"
      >
      Preview or edit the template as per the requirements. This will be sent
      once the challenge is overcome. 
      <span *ngIf="(validScreens | screenConfig: 18)">SMS templates can’t be edited.</span>
      </div>
      <div
        class="qb-no-preview"
        *ngIf="rawJson['isNoneBarrierDetected'] == true"
      >
        Preview or edit the template as per the requirements. 
        <span *ngIf="(validScreens | screenConfig: 18)">
          SMS templates
        can’t be edited.
        </span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentCustomerSegments>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        Choose the target segment to whom this offer must be sent. You can
        choose multiple customer segments.
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentDispatchType>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        Send the offer instantly or schedule for a particular date and time.
      </div>
    </div>
  </div>
</ng-template> -->
