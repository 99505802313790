import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuppressionListsComponent } from './pages';

const routes: Routes = [
    // { path: "", redirectTo: "suppression-list", pathMatch: "full" },
    { path: "", component: SuppressionListsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuppressionListRoutingModule { }
