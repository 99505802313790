<div class="config-dialog">
    <div class="header">
        <h2>{{'SURVEY_PAGE.REVIEW_CONFIGURATION' | i18nTranslate}}</h2>
        <mat-icon class="close-icon" (click)="onCancel()">close</mat-icon>
      </div>
      <div class="historical-sync-info-container">
        <p style="font-weight: 700;">{{'SURVEY_PAGE.HISTORICAL_SYNC_QUESTION' | i18nTranslate}}</p>
      <span class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
      [ngbPopover]="popContentHistoricalSync" triggers="mouseenter:mouseleave">
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
      </svg>   
    </span>
      </div>
      
    <div class="config-field">
      <label>{{'SURVEY_PAGE.MERCHANT_NAME' | i18nTranslate}}</label>
      <input type="text" [value]="merchantName" disabled />
    </div>

    <div class="config-field">
      <label>{{'SURVEY_PAGE.PLATFORM' | i18nTranslate}}</label> 
      <mat-select [(value)]="selectedPlatform" (selectionChange)="onPlatformAndStoreChange()" class="matSelect-filters" placeholder="Select Platform">
        <mat-option *ngFor="let platform of platformList" [value]="platform">{{ platform }}</mat-option>
      </mat-select>
    </div>

    <div class="config-field">
      <label>{{'SURVEY_PAGE.STORE' | i18nTranslate}}</label>
      <mat-select [(value)]="selectedStore" (selectionChange)="onStoreChange($event)" class="matSelect-filters" placeholder="Select Store">
        <mat-option *ngFor="let store of storeFilterList" [value]="store">{{ store.storeName }}</mat-option>
      </mat-select>
    </div>

    <div class="config-field place-id-field">
        <label>{{'SURVEY_PAGE.Place_ID' | i18nTranslate}}</label>
        <div class="place-id-wrapper">
          <input type="text" [value]="placeId" disabled />
          <button mat-icon-button (click)="refreshPlaceId()" class="refresh-icon" matTooltip="Refresh">
            <mat-icon class="mat-icon-two-tone">cached</mat-icon>
          </button>
        </div>
      </div>
      

    <div class="config-field">
      <div class="historical-sync-info-container">
        <label>{{'SURVEY_PAGE.DAILY_REVIEW_SYNC' | i18nTranslate}}</label>
        <span class="qb-i-div-alignment" style="margin:0 0 4px 4px;" popoverClass="qb-my-custom-class" placement="bottom"
        [ngbPopover]="popContentDailyReviewSync" triggers="mouseenter:mouseleave">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
        </svg>   
      </span>
      </div>
      <mat-slide-toggle [(ngModel)]="dailyReviewStatus" [disabled]="isReviewStatusLoading">Active</mat-slide-toggle>
    </div>

    <div class="dialog-buttons">
      <button class="secondary-button secondary-button-border" (click)="onCancel()">Cancel</button>
      <button class="primary-button" (click)="onSave()" [disabled]="placeId.length === 0" [ngClass]="{ 'disabled-button': placeId.length === 0 }">Save</button>
    </div>
</div>

<ng-template #popContentHistoricalSync>
    <div class="qb-flex">
      <div class="qb-flex2">
        <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
      </div>
      <div class="qb-width90">
        <div class="qb-choose-channel">
          {{'SURVEY_PAGE.HISTORIC_SYNC_INFO' | i18nTranslate}}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #popContentDailyReviewSync>
    <div class="qb-flex">
      <div class="qb-flex2">
        <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
      </div>
      <div class="qb-width90">
        <div class="qb-choose-channel">
          {{'SURVEY_PAGE.DAILY_REVIEW_SYNC_INFO' | i18nTranslate}}
        </div>
      </div>
    </div>
  </ng-template>
