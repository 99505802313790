
<div style="padding:15px;">
<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;height: 100%;">

<div>
  <mat-icon style="color: #22d093;
  font-size: 57px;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;">check_circle</mat-icon>
</div>
<div style="font-weight: normal;
font-stretch: normal;
font-style: normal;
font-size: 13px;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #232627;
margin-bottom: 40px;">
  {{'SHARED_PAGE.SURVEY_SCHEDULES_SUCESSFULLY' | i18nTranslate}}
</div>
<div>
  <button style="background:#e8ecf0;outline: none;border:none;padding:8px 18px; font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;cursor: pointer;" (click)="submit(1)">{{'SHARED_PAGE.BACK_TO_SURVEY_LIST' | i18nTranslate}}</button>
</div>
</div>

</div>