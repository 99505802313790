import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { ContactUsDialogComponent } from "../contact-us-dialog/contact-us-dialog.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-partnership-reward-creation-dialogbox",
  templateUrl: "./partnership-reward-creation-dialogbox.component.html",
  styleUrls: ["./partnership-reward-creation-dialogbox.component.css"],
})
export class PartnershipRewardCreationDialogboxComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PartnershipRewardCreationDialogboxComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  apiloading = false;
  rewardName = "";
  rewardValue = 10;
  errorName = "";
  errorValue = "";
  rnamePattern = "^[A-Za-z0-9_ ]{3,15}$";

  ngOnInit() {}
  submit() {
    this.dialogRef.close(false);
  }

  check() {
    if (this.rewardName.replace(/\s/g, "").length > 0 && this.rewardValue !== null) {
      if (this.rewardName.length > 15) {
        this.errorName = this._i18nDynamicTranslate.transform("This field cannot have more than 15 characters", ['SHARED_PAGE']);
      } else if (this.rewardName.match(this.rnamePattern) == null) {
        this.errorName = this._i18nDynamicTranslate.transform("Please enter valid reward name", ['SHARED_PAGE']);
      }
      else if (this.rewardValue > 100) {
        this.errorValue = this._i18nDynamicTranslate.transform("Reward value should not be greater that 100", ['SHARED_PAGE']);
      } else if(this.rewardValue < 1) {
        this.errorValue = this._i18nDynamicTranslate.transform("Reward value should not be less than one", ['SHARED_PAGE']);
      } else 
        {
        this.add();
      }
    } else {
      if (this.rewardName.length < 1) this.errorName = this._i18nDynamicTranslate.transform("Field cannot be empty", ['SHARED_PAGE']);
      else if(this.rewardName.replace(/\s/g, "").length < 1) this.errorName = this._i18nDynamicTranslate.transform("Please enter valid reward name", ['SHARED_PAGE']);
      else if (this.rewardValue === null)
        this.errorValue = this._i18nDynamicTranslate.transform("Value field cannot be empty", ['SHARED_PAGE']);
    }
  }
  add() {
    this.apiCall
      .CreatePartnershipReward(this.rewardName, this.rewardValue)
      .subscribe(
        (response) => {
          if (response["message"] == "Success") {
            this.apiCall
              .CreatePartnerShip(this.data["targetId"], response["body"])
              .subscribe(
                (resposne2) => {
                  this.dialogRef.close(true);
                },
                (error) => {
                  if (error["error"]["message"] == "failure") {
                    const dialogRef = this.dialog.open(
                      ContactUsDialogComponent,
                      {
                        panelClass: "no-padding-dialog-popup",
                        width: "380px",
                        height: "auto",
                        disableClose: true,
                        data: {
                          header: this._i18nDynamicTranslate.transform("Failure", ['SHARED_PAGE']),
                          body: this._i18nDynamicTranslate.transform(error["error"]["body"], ['SHARED_PAGE']),
                        },
                      }
                    );
                    this.dialogRef.close();
                  }
                }
              );
            // this.dialogRef.close(true)
          }
        },
        (error) => {
          if (error["error"]["message"] == "Failed") {
            const dialogRef = this.dialog.open(ContactUsDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "380px",
              height: "auto",
              disableClose: true,
              data: {
                header: this._i18nDynamicTranslate.transform("Failure", ['SHARED_PAGE']),
                body: this._i18nDynamicTranslate.transform(error["error"]["body"], ['SHARED_PAGE']),
              },
            });
            this.dialogRef.close();
          }
        }
      );
    // this.dialogRef.close(true)
  }
  keypress(input) {
    if(input === 'name')
    this.errorName = "";
    else
    this.errorValue = "";
  }
}
