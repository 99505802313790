import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { AddNewCustomerComponent, SegmentDashboardComponent,CustomerUploadHistoryComponent } from './pages';
import { GetCustomerPersonalOfferComponent } from '../offer/pages';
import { CustomerListDataViewComponent } from '../customer-list/components';

const routes: Routes = [
    { path: "", redirectTo: "segment-dashboard", pathMatch: "full" },
    { 
      path: "segment-dashboard", 
      component: SegmentDashboardComponent,
      canActivate: [WidgetRouteGaurdService],
            data: {
              titleheading: "My Customers",
              title: "Overview",
              minPath: "Basic",
              id: 2,
            },
    },
    {
      path: "add-new-customer", 
      component: AddNewCustomerComponent,
      canActivate: [WidgetRouteGaurdService],
            data: {
              titleheading: "My Customer",
              title: "Overview",
              minPath: "Basic",
              id: 2,
            },
    },
    { 
      path: "customer-offer", 
      component: GetCustomerPersonalOfferComponent,
      data: {
        titleheading: "Customer Offer",
        title: "Customer Offer"
      },
    },
    { 
      path: "upload-history", 
      component: CustomerUploadHistoryComponent,
      data: {
        titleheading: "Upload History",
        title: "Upload History",
        id:53
      },
    },
    { 
      path: "view-customer", 
      component: CustomerListDataViewComponent,
      data: {
        titleheading: "View Customer History",
        title: "View Customer History",
        id:53
      },
    }            
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
