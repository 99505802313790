<div class="qb-body">
  <div class="qb-table-div">
    <div class="qb-table-header">
      <div>{{'PARTNERSHIP_PAGE.PARTNERSHIP_REQ_LIST' | i18nTranslate}}</div>
      <div class="qb-search-div">
        <input
          type="text"
          placeholder="Search by Partner Name"
          (input)="onKeySearch($event)"
          [(ngModel)]="searchString"
        />
        <div class="material-icons-outlined" *ngIf="searchString.length == 0">
          search
        </div>
        <span
          class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
          *ngIf="searchString.length > 0"
          (click)="searchString = ''; onKeySearch(searchString)"
        >
          close
        </span>
      </div>
    </div>
    <div class="qb-table-head">
      <div class="qb-table-head-section" *ngFor="let heading of tableHeadings">
        {{heading}}
      </div>
    </div>
    <div *ngIf="loading" class="qb-spinner-div">
      <mat-spinner diameter="15"></mat-spinner>
    </div>
    <div *ngIf="length == 0 && !loading" class="qb-no-data">{{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
    <div class="qb-table-content" *ngFor="let list of requestList">
      <div class="qb-table-data-div">
        <div
          class="qb-table-data qb-partner-name"
          (click)="
            viewRequest(list['partnerName'], list['requestId'], list['status'])
          "
        >
          <u>{{ list["partnerName"] }}</u>
        </div>
        <div class="qb-table-data">
          {{ list["date"] | toDateObj | date: getDateFormat("dd MMM yyyy") }} &nbsp;
          {{ list["date"] | toDateObj | customTime}}
        </div>
        <div class="qb-table-data qb-status">
          {{ list["status"].replace("_", " ").toLowerCase() }}
        </div>
        <div
          class="qb-table-data qb-send-offer"
          *ngIf="list['status'] === 'REQUEST_RECEIVED'"
          (click)="checkPaypalID(list['requestId'])"
        >
          {{'PARTNERSHIP_PAGE.ACCEPT' | i18nTranslate}}
        </div>
        <div
          class="qb-table-data qb-send-offer"
          *ngIf="list['status'] === 'REQUEST_SENT'"
        ></div>
      </div>
    </div>
    <div class="qb-paginate-div">
      <mat-paginator
        [pageIndex]="pageIndex - 1"
        [length]="length"
        [pageSize]="pageSize"
        (page)="refreshPaginatedData($event)"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
  </div>
</div>
