import { Component, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import Bee from "@mailupinc/bee-plugin";
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { template1 } from 'src/app/shared/json-asset';
import { chefDrivenTemplate1 } from "src/app/shared/json-asset/chef-driven-templates";


@Component({
  selector: 'app-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.css']
})
export class UpdateFormComponent implements OnInit {

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }

  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  finalHeight;
  beeTest;
  beeToken;
  beeConfig;
  returnUrl;
  mergeTags = [];
  dialogNeeded;
  payload = {
    client_id: environment.beePluginPopupClientId,
    client_secret: environment.beePluginPopupClientSecretId,
    grant_type: "password",
  };
  specialLinks = [
    {
      type: "Unsubscribe",
      label: "Unsubscribe",
      link: "%unsubscribe_url%",
    },
  ];
  selectedTemplate = "T1";
  finalContent;
  templatesArray = [];
  templateName;
  templateForm: FormGroup;
  subjectError = false;
  nameError = false;
  submitClicked = false;
  responseMsg: string = null;
  templateIdNew = 130;
  templateType;
  formLayout;
  urlData = {
    id: null,
    type: null,
    return: null,
    clone: null,
  };
  layout;
  renderStatus = false;
  appSubscriptionPlan;
  validWidgets;
  html;
  loadingDialog = false;
  sendTestEmail;
  template1;

  constructor(
    private apiCall: ApiCallService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private token: TokenStorageService,
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private snack: SnackbarCollection,
    public dialogRef: MatDialogRef<UpdateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dataLoad,
  ) {
    this.getScreenSize();
  }

  formJSON;


  ngOnInit() {
    this.getSampleTemplates();
    this.init();
  }
  response;
  ngAfterContentInit() {
    this.init();
  }

  hello() {
    this.beeTest.save();
  }

  init() {
    // this.apiCall.getPartnershipFormDetails(this.dataLoad, 'CUSTOM').subscribe(response => {
      this.formJSON = this.dataLoad.json;
    this.scrHeight = window.innerHeight;
    this.beeTest = new Bee(this.beeToken);
    function userInput(message, sample) {
      return function handler(resolve, reject) {
        var data = prompt(message, JSON.stringify(sample));
        return data == null || data == ""
          ? reject()
          : resolve(JSON.parse(data));
      };
    }

    var beeConfig = {
      uid: "user-id-" + this.tokenStorage.getMerchantId(),
      container: "bee-plugin-container",
      autosave: 15,
      language: "en-US",
      trackChanges: true,

      // // defaultForm: {
      //   // structure: {
      //   //   title: "Form title",
      //     // fields: {
      //     //   email: {
      //     //     type: "email",
      //     //     label: "Email",
      //     //     attributes: { name: "email" },
      //     //   },
      //     //   password: { type: "password", label: "Password" },
      //     //   submit: {
      //     //     type: "submit",
      //     //     label: " ",
      //     //     attributes: { value: "Login" },
      //     //   },
      //     // },
      //     // layout: [["email"], ["password"], ["submit"]],
      //   // },
      //   fieldsOptions: {
      //     color: "#000000",
      //     backgroundColor: "#FF5733",
      //     outlineColor: "#3AAEE0",
      //   },
      // },
      // editorFonts: {
      //   showDefaultFonts: false,
      //   customFonts: [
      //     {
      //       name: "Comic Sans",
      //       fontFamily: "'Comic Sans MS', cursive, sans-serif",
      //     },
      //     {
      //       name: "Lobster",
      //       fontFamily: "'Lobster', Georgia, Times, serif",
      //       url: "https://fonts.googleapis.com/css?family=Lobster",
      //       fontWeight: ["Regular(200)", "medium(400)", "bold(600)"],
      //     },
      //     {
      //       name: "Roboto",
      //       fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
      //       url: "https://fonts.googleapis.com/css?family=Roboto",
      //     },
      //     {
      //       name: "Do Hyeon",
      //       fontFamily: "'Do Hyeon', sans-serif",
      //       url: "https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Lora&display=swap",
      //     },
      //     {
      //       name: "Pacifico",
      //       fontFamily: "'Pacifico', cursive",
      //       url: "https://fonts.googleapis.com/css2?family=Pacifico",
      //     },
      //     {
      //       name: "Raleway",
      //       fontFamily: "'Raleway', sans-serif",
      //       url: "https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap",
      //       fontWeight: { 400: "Regular", 500: "Medium", 700: "Bold" },
      //     },
      //   ],
      // },
      workspace: {
        popup: {
          layout: "default",
          theme: "bootstrap",
          backgroundImage:
            "https://i.ytimg.com/vi/sd0grLQ4voU/maxresdefault.jpg",
          backgroundImageMobile: "https://.../background.png",
        },
      },
      contentDialog: {
        specialLinks: {
          label: "Add a custom Special Link",
          handler: userInput("Enter the deep link:", {
            type: "custom",
            label: "external special link",
            link: "http://www.example.com",
          }),
        },
        mergeTags: {
          label: "Add custom tag 2",
          handler: userInput("Enter the merge tag:", {
            name: "name",
            value: "[name]",
          }),
        },
        mergeContents: {
          label: "Choose a custom merge content",
          handler: userInput("Enter the merge content:", {
            name: "my custom content",
            value: "{my-custom-content}",
          }),
        },
        rowDisplayConditions: {
          label: "Open builder",
          handler: userInput("Enter the row display condition:", {
            type: "People",
            label: "Person is a developer",
            description: "Check if a person is a developer",
            before: "{if job == 'developer'}",
            after: "{endif}",
          }),
        },
      },
      onChange: function (jsonFile, response) {},
      onSave: (jsonFile, htmlFile) => {
        this.saveForm(jsonFile, htmlFile);
        // var doc = new DOMParser().parseFromString(htmlFile, "text/html");
        // if (
        //   doc.getElementsByTagName("button").length != 1 ||
        //   doc.querySelectorAll("input[type=email]").length != 1 ||
        //   doc.querySelectorAll("input[type=tel]").length > 1
        // ) {
        //   if (doc.getElementsByTagName("button").length > 1)
        //     this.openDialog("button");
        //   else if (doc.querySelectorAll("input[type=email]").length > 1)
        //     this.openDialog("email input field");
        //   else if (doc.querySelectorAll("input[type=tel]").length > 1)
        //     this.openDialog("phone number input field");
        //     else if(doc.querySelectorAll("input[type=email]").length == 0)
        //     this.snack.openSnackBar("Form should contain at least one email input field", 2000);
        //   else if(doc.getElementsByTagName("button").length == 0)
        //     this.snack.openSnackBar("Form should contain at least one button", 2000);
        // } else {
        //   var elements = doc.querySelectorAll("input,textarea,select");
        //   var obj = [];
        //   for (var i = 0; i < elements.length; i++) {
        //     var item = elements[i];
        //     if (item.localName == "input") {
        //       if (obj.indexOf(item.attributes["type"].nodeValue) == -1)
        //         obj.push(item.attributes["type"].nodeValue);
        //     } else {
        //       obj.push(item.localName);
        //     }
        //   }
          // this.apiData.emit([htmlFile, jsonFile, []]);
        // }
      },
      onSaveAsTemplate: function () {},
      onAutoSave: function (jsonFile) {
        window.localStorage.setItem("newsletter.autosave", jsonFile);
      },
      onSend: function () {},
      onError: function () {},
    };
    this.beeTest
      .getToken(this.payload.client_id, this.payload.client_secret)
      .then(() =>
        this.beeTest.start(
          beeConfig,
          JSON.parse(this.formJSON)
        )
      );
    // })
  }

  saveForm(json, html) {
    let data = {
      "formId": this.dataLoad.id,
      "formHtml": html,
      "formJson": json,
      "merchantId": this.tokenStorage.getMerchantId(),
      "type": "CUSTOM"
    }
    this.apiCall.updatePForm(data).subscribe(response => {
      this.snackBar.open('Form updated', "", { duration: 2000 });
      this.dialogRef.close();
      this.router.navigate(['/app/partnership/my-partners'])
    }, error => {
      this.snackBar.open('Failed to update form', "", { duration: 2000 });
    })
  }

  openDialog(input) {
    this.snack.openSnackBar("Form can contain one " + input + " only", 2000);
  }

  save() {
    this.beeTest.save();
  }

  async saveAsHtml(type, json, html) {
    this.html = html;
    this.submitClicked = true;
    let mId = this.tokenStorage.getMerchantId();
    if (this.templateForm.controls.TemplateName.errors) {
      return 0;
    }
    this.finalContent = {
      id:
        this.urlData.id !== "null" || this.urlData.clone === "false"
          ? this.urlData.id
          : null,
      merchantId: mId,
      name: this.templateForm.get("TemplateName").value,
      layoutHtml: html,
      layoutJson: json,
      type: "GENERIC",
    };
    let snackBarRef = this.snackBar.open("Saving... Please wait");
    if (this.urlData.id !== "null" && this.urlData.clone === "false") {
      if (this.urlData.type === "REWARD") {
        let data = {
          merchantId: mId,
          subject: this.templateForm.get("subject").value,
          layoutHtml: html,
          layoutJson: json,
          id: this.urlData.id,
        };
        this.apiCall.updateRewardEmail(data).subscribe(
          (response) => {
            snackBarRef.dismiss();
            this.returnToPreviousScreen(this.urlData.id, this.urlData.type);
          },
          (err) => {
            snackBarRef.dismiss();
            this.snackBar.open(err["error"]["body"], "", { duration: 2000 });
          }
        );
      } else {
        this.apiCall.updateEmailLayout(this.finalContent).subscribe(
          (response) => {
            snackBarRef.dismiss();
            this.returnToPreviousScreen(response["body"], this.urlData.type);
          },
          (err) => {
            snackBarRef.dismiss();
            this.snackBar.open(err["error"]["body"], "", { duration: 2000 });
          }
        );
      }
    } else {
      this.apiCall.createEmailLayout(this.finalContent).subscribe(
        (response) => {
          snackBarRef.dismiss();
          this.returnToPreviousScreen(response["body"], "CUSTOM");
        },
        (err) => {
          snackBarRef.dismiss();
          this.snackBar.open(err["error"]["body"], "", { duration: 2000 });
        }
      );
    }
  }

  returnToPreviousScreen(id, type) {
    this.router.navigate([this.urlData.return, { id: id, type: type }]);
  }

  closeEditor() {
    this.router.navigate([
      this.urlData.return,
      {
        id: this.urlData.id === "null" ? null : this.urlData.id,
        type: this.urlData.type,
      },
    ]);
  }

  selectTemplate(template) {
    this.selectedTemplate = template;
    switch (template) {
      case "T1": {
        this.beeTest.load(this.template1);
        break;
      }
    }
  }
  closeb(){
    this.dialogRef.close();
  }

  getSampleTemplates(){
    if(this.tokenStorage.getMerchantOnlineStore() === 'restomail'){
      this.template1 = chefDrivenTemplate1;
    }
    else{
      this.template1 = template1;
    }
    this.templatesArray = [this.template1];
  }

}
