<div style="height: 100%; overflow: hidden">
  <div style="padding: 16px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div style="width: 100%">
        <span *ngIf="data.type === 'EMAIL' || data.type==='NEGOTIATOR_EMAIL' || data.type === 'REWARD'" class="surveyNamText"
          >{{'SHARED_PAGE.EMAIL_TEMPLATE_PREVIEW' | i18nTranslate}}</span
        >
        <span *ngIf="data.type === 'SMS'" class="surveyNamText"
          >{{'SHARED_PAGE.SMS_TEMPLATE_PREVIEW' | i18nTranslate}}</span
        >
        <span *ngIf="data.type === 'SURVEY'" class="surveyNamText"
          >{{'SHARED_PAGE.SURVEY_TEMPLATE_PREVIEW' | i18nTranslate}}</span
        >
        <span *ngIf="data.type === 'SURVEYEMAIL'" class="surveyNamText"
        >{{'SHARED_PAGE.SURVEY_EMAIL_PREVIEW' | i18nTranslate}}</span
      >
      </div>
      <!-- <div *ngIf="data.showButton" style="color: #68B8E5; display: flex; cursor: pointer; width: 60%;">
        <div>
        <div class="material-icons" style="margin: -1px 10px;font-size: 12px;">thumb_up_alt</div>
        </div>
        <div style="font-size: 12px;">
          <u><a href="https://www.skellam.ai/blog/10-ways-to-avoid-sending-spam-emails" target="_blank" class="spam-link">How to avoid sending spam emails</a></u>
        </div>
      </div> -->
      <div style="display: flex">
        <div>
          <button *ngIf="data.showButton && data.type !== 'SMS'"
          (click)="openSendTestEmailDialog()"
          style="
            background: #4cb9ea;
            border: none;
            color: white;
            padding: 7px 12px;
            border-radius: 5px;
            font-size: 12px;
            margin-right: 21px;
            margin-top: -3px;
            width: 118px;
          "
        >
          {{'SHARED_PAGE.SEND_TEST_EMAIL' | i18nTranslate}}
        </button>
        </div>
        <div
        *ngIf="(data.type === 'EMAIL' || data.type ==='SURVEYEMAIL' || data.type==='NEGOTIATOR_EMAIL' || data.type === 'REWARD') && data.isEditAllowed!=false"
        style="cursor: pointer; margin-right: 20px"
        (click)="createSharableLink()"
      >
        <mat-icon class="icons-primary" matTooltip="Email Preview Link">share</mat-icon>
      </div>
        <div
          *ngIf="(data.type === 'EMAIL' || data.type ==='SURVEYEMAIL' || data.type==='NEGOTIATOR_EMAIL' || data.type === 'REWARD') && data.isEditAllowed!=false"
          style="cursor: pointer; margin-right: 20px"
          (click)="editTemplate()"
        >
          <mat-icon class="icons-primary" [matTooltip]="'COMMON_PAGE.EDIT_TEMPLATE' | i18nTranslate">edit</mat-icon>
        </div>
        <div style="cursor: pointer" (click)="closePreview()">
          <mat-icon [matTooltip]="'COMMON_PAGE.CLOSE_PREVIEW' | i18nTranslate" class="close-icon"
            >close</mat-icon
          >
        </div>
      </div>
    </div>
    <div *ngIf="data.type !=='SURVEYEMAIL'">
      <div style="color: rgba(34, 34, 34, 0.6)">{{'SHARED_PAGE.TEMPLATE_NAME' | i18nTranslate}}</div>
      <div>{{ templateName }}</div>
    </div>
  </div>
  <div
    *ngIf="(data.type === 'EMAIL' || data.type === 'SURVEYEMAIL' || data.type ==='NEGOTIATOR_EMAIL' || data.type === 'REWARD')"
    style="
      cursor: not-allowed !important;
      overflow-y: auto;
      height: calc(100% - 98px);
      padding: 16px;
    "
    [matTooltip]="(data.isEditAllowed==true)?'Click on Edit Template button to edit' : ''"
  >
    <div *ngIf="data.obj && data.type !='NEGOTIATOR_EMAIL'" style="pointer-events: none">
      <app-email-template-preview
        [selectedLayout]="data.obj.selectedLayout"
      ></app-email-template-preview>
    </div>
    <div *ngIf="data.type =='NEGOTIATOR_EMAIL'" style="pointer-events: none">
      <app-negotiator-template-preview
        [selectedLayout]="data.obj.selectedLayout"
      ></app-negotiator-template-preview>
    </div>
  </div>

  <div
    *ngIf="data.type === 'SURVEY'"
    style="
      cursor: not-allowed !important;
      height: calc(100% - 98px);
      overflow-y: auto;
      padding: 16px;
    "
  >
    <div *ngIf="data.obj.selectedSurvey" style="pointer-events: none">
      <app-email-template-preview
        [selectedLayout]="data.obj.selectedSurvey"
      ></app-email-template-preview>
    </div>
  </div>

  <div
    *ngIf="data.type === 'SMS'"
    style="
      height: 100%;
      height: calc(100% - 98px);
      overflow-y: auto;
      padding: 16px;
    "
  >
    <div
      style="width: 100%; display: flex; justify-content: center; height: 100%"
    >
      <div style="width: 400px">
        <div class="phone-image1">
          <div class="preview-text-content1">
            <span style="white-space: pre-line; word-break: break-word">{{
              data.obj["layoutData"]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="data.type === 'OFFER'"
    style="
      display: flex;
      border: 1px dashed #fff;
      height: calc(100% - 98px);
      overflow-y: auto;
      padding: 16px;
    "
  >
    <div
      style="width: 30%; background: #edf0fa; overflow: scroll; height: 520px"
      *ngIf="data.obj.selectedbarrier"
    >
      <div
        *ngFor="let barrierReward of data.obj.selectedbarrier; let i = index"
      >
        <span
          *ngIf="i != 0"
          style="
            display: flex;
            padding: 20px;
            border-top: 1px solid #d8d8d8;
            font-weight: bold;
          "
          >{{'SHARED_PAGE.BARRIER' | i18nTranslate}} {{ i + 1 }}:</span
        >
        <span
          *ngIf="i == 0"
          style="display: flex; padding: 20px; font-weight: bold"
          >{{'SHARED_PAGE.BARRIER' | i18nTranslate}} {{ i + 1 }}:</span
        >
        <div>
          <div style="padding: 20px">
            <span class="barrierSubHeading">{{'SHARED_PAGE.BARRIER_TYPE' | i18nTranslate}}</span>
            <div class="offerBarrierDetailListDiv" style="min-width: 70%">
              <span *ngIf="barrierReward['barrier']['barrierParamType']">{{
                barrierReward["barrier"]["barrierParamType"]
                  | removeUnderscore
                  | titlecase
              }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="barrierReward['barrier']['barrierParamType'] !== 'NONE'">
          <div>
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.HURDLE_TYPE' | i18nTranslate}}</span>
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span *ngIf="barrierReward['barrier']['barrierType'] === 'NONE'"
                  >{{'SHARED_PAGE.PRODUCT_BASED' | i18nTranslate}}</span
                >
                <span
                  *ngIf="barrierReward['barrier']['barrierType'] !== 'NONE'"
                  >{{
                    barrierReward["barrier"]["barrierType"]
                      | removeUnderscore
                      | titlecase
                  }}</span
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="
              barrierReward['barrier']['barrierParamType'] === 'PERSONALIZED' &&
              barrierReward['barrier']['barrierType'] === 'PRODUCT_BASED'
            "
          >
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.PROPENSITY' | i18nTranslate}}</span>
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span *ngIf="barrierReward['barrier']['productPropensity']">{{
                  barrierReward["barrier"]["productPropensity"]
                    | removeUnderscore
                    | titlecase
                }}</span>
                <span
                  *ngIf="barrierReward['barrier']['productCategoryPropensity']"
                  >{{
                    barrierReward["barrier"]["productCategoryPropensity"]
                      | removeUnderscore
                      | titlecase
                  }}</span
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="
              barrierReward['barrier']['barrierParamType'] != 'PERSONALIZED' &&
              (barrierReward['barrier']['barrierType'] === 'PRODUCT_BASED' ||
                barrierReward['barrier']['barrierType'] === 'NONE')
            "
          >
            <div style="padding: 20px">
              <span
                *ngIf="barrierReward['barrier']['product']"
                class="barrierSubHeading"
                >{{'SHARED_PAGE.PRODUCT' | i18nTranslate}}</span
              >
              <span
                *ngIf="barrierReward['barrier']['productCategory']"
                class="barrierSubHeading"
                >{{'SHARED_PAGE.PRODUCT_CATEGORY' | i18nTranslate}}</span
              >
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span *ngIf="barrierReward['barrier']['product']">{{
                  barrierReward["barrier"]["product"]["productName"]
                }}</span>
                <span *ngIf="barrierReward['barrier']['productCategory']">{{
                  barrierReward["barrier"]["productCategory"][
                    "productCategoryName"
                  ]
                }}</span>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              barrierReward['barrier']['barrierType'] === 'PRODUCT_BASED' ||
              barrierReward['barrier']['barrierType'] === 'NONE'
            "
          >
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.PRODUCT_COUNT' | i18nTranslate}}</span>
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span *ngIf="barrierReward['barrier']['product'] != null">{{
                  barrierReward["barrier"]["product"]["productCount"]
                }}</span>
                <span
                  *ngIf="barrierReward['barrier']['productCategory'] != null"
                  >{{
                    barrierReward["barrier"]["productCategory"]["productCount"]
                  }}</span
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="
              barrierReward['barrier']['barrierParamType'] !== 'PERSONALIZED' &&
              barrierReward['barrier']['barrierType'] === 'SPENT_BASED'
            "
          >
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.AMOUNT' | i18nTranslate}}</span>
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span>{{ barrierReward["barrier"]["spentBarrier"] }}</span>
              </div>
            </div>
          </div>
          <div>
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.TIME_RANGE' | i18nTranslate}}</span>
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span
                  >{{ barrierReward["barrier"]["barrierDates"]["startTime"] }} -
                </span>
                <span>{{
                  barrierReward["barrier"]["barrierDates"]["endTime"]
                }}</span>
              </div>
            </div>
          </div>
          <div>
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.DAYS_OF_THE_WEEK' | i18nTranslate}}</span>
              <div
                style="
                  min-width: 70%;
                  margin-top: 15px;
                  display: flex;
                  flex-wrap: wrap;
                "
              >
                <span
                  *ngIf="
                    barrierReward['barrier']['barrierDates']['daysOfTheWeek'] !=
                    null
                  "
                >
                  <span
                    *ngFor="
                      let day of barrierReward['barrier']['barrierDates'][
                        'daysOfTheWeek'
                      ]
                    "
                    style="
                      margin: 5px;
                      background: #ffffff;
                      padding: 5px;
                      border-radius: 25px;
                    "
                    >{{ day }}</span
                  >
                </span>
                <span
                  *ngIf="
                    barrierReward['barrier']['barrierDates']['daysOfTheWeek'] ==
                    null
                  "
                  style="
                    margin: 5px;
                    background: #ffffff;
                    padding: 5px;
                    border-radius: 25px;
                  "
                  >{{'SHARED_PAGE.ALL_DAYS' | i18nTranslate}}</span
                >
              </div>
            </div>
          </div>
          <div>
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.TIME_TO_COMPLETE' | i18nTranslate}}</span>
              <div class="offerBarrierDetailListDiv" style="min-width: 70%">
                <span
                  >{{
                    barrierReward["barrier"]["barrierDates"]["endDayCount"]
                  }}
                  days</span
                >
              </div>
            </div>
          </div>

          <div
            *ngIf="isNoneBarrierDetected != true && isBuyXGetYDetected != true"
          >
            <div style="padding: 20px">
              <span class="barrierSubHeading">{{'SHARED_PAGE.EMAIL_TEMPLATE' | i18nTranslate}}</span>
              <div
                *ngIf="data.obj.offerDetailEmailLayout"
                style="margin-top: 10px"
              >
                <img
                  style="width: 180px; height: 190px; border: 1px solid #dadada"
                  [src]="layoutSnapshotUrl"
                  alt="No preview available"
                />
              </div>
              <div
                *ngIf="data.obj.offerDetailEmailLayout == null"
                class="offerBarrierDetailListDiv"
                style="min-width: 70%"
              >
                <span>{{'SHARED_PAGE.NO_EMAIL_TEMPLATE_ATTACHED' | i18nTranslate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        width: 70%;
        overflow: scroll;
        height: 520px;
        padding: 20px;
        background: #fff;
        font-size: 14px;
      "
    >
      <div style="display: flex; justify-content: center">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-width: 70%;
            margin-bottom: 20px;
          "
        >
          <div
            *ngFor="
              let barrierReward of data.obj.selectedbarrier;
              let i = index;
              let l = last
            "
          >
            <div style="display: flex; flex-direction: column">
              <span
                style="
                  padding: 15px;
                  background: #edf0fa;
                  border: 1px solid #dadada;
                  border-radius: 4px;

                  display: flex;
                  justify-content: center;
                "
                >{{'SHARED_PAGE.BARRIER' | i18nTranslate}} {{ i + 1 }}</span
              >
              <span
                *ngIf="i < data.obj.selectedbarrier.length"
                style="
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div style="height: 30px; border-left: 1px solid #4abb59"></div>
                <div>
                  <mat-icon class="keyboardArrowDown">
                    keyboard_arrow_down</mat-icon
                  >
                </div>
                <div style="height: 30px; border-left: 1px solid #4abb59"></div>
              </span>
            </div>
            <div
              *ngIf="barrierReward['reward']"
              style="display: flex; flex-direction: column"
            >
              <span
                style="
                  padding: 15px;
                  background: #edf0fa;
                  border: 1px solid #dadada;
                  border-radius: 4px;
                "
              >
                <div>
                  <div style="display: flex; justify-content: center">
                    <div>
                      <span>{{'SHARED_PAGE.REWARD_TYPE' | i18nTranslate}} : </span>
                      <span>{{
                        barrierReward["reward"]["rewardType"]
                          | removeUnderscore
                          | titlecase
                      }}</span>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: center">
                    <div>
                      <div
                        *ngIf="
                          barrierReward['reward']['rewardType'] ===
                          'BUY_X_GET_Y'
                        "
                      >
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span
                              *ngIf="
                                barrierReward['reward']['buyXGetYDiscount'][
                                  'entitledType'
                                ] === 'PRODUCT'
                              "
                              >{{'SHARED_PAGE.REWARD_PRODUCT' | i18nTranslate}} :
                            </span>
                            <span
                              *ngIf="
                                barrierReward['reward']['buyXGetYDiscount'][
                                  'entitledType'
                                ] === 'PRODUCT'
                              "
                              >{{
                                barrierReward["reward"]["buyXGetYDiscount"][
                                  "entitledProducts"
                                ][0]["productName"]
                              }}</span
                            >
                          </div>
                        </div>
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span
                              *ngIf="
                                barrierReward['reward']['buyXGetYDiscount'][
                                  'entitledType'
                                ] === 'COLLECTION'
                              "
                              >{{'SHARED_PAGE.REWARD_COLLECTION' | i18nTranslate}} :
                            </span>
                            <span
                              *ngIf="
                                barrierReward['reward']['buyXGetYDiscount'][
                                  'entitledType'
                                ] === 'COLLECTION'
                              "
                              >{{
                                barrierReward["reward"]["buyXGetYDiscount"][
                                  "entitledCollection"
                                ][0]["collectionName"]
                              }}</span
                            >
                          </div>
                        </div>
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span>{{'SHARED_PAGE.PRODUCT_COUNT' | i18nTranslate}} : </span>
                            <span>{{
                              barrierReward["reward"]["buyXGetYDiscount"][
                                "entitledQuantity"
                              ]
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          barrierReward['reward']['rewardType'] ===
                            'PERCENTAGE_OFF' ||
                          barrierReward['reward']['rewardType'] ===
                            'FIXED_AMOUNT_OFF'
                        "
                      >
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span>{{'SHARED_PAGE.OFFER_ON' | i18nTranslate}} : </span>
                            <span>{{
                              barrierReward["reward"][
                                "amountOrPercentageOffDiscount"
                              ]["entitledType"] | titlecase
                            }}</span>
                          </div>
                        </div>
                        <div
                          *ngIf="
                            !(
                              barrierReward['reward']['productPropensity'] ||
                              barrierReward['reward'][
                                'productCategoryPropensity'
                              ]
                            )
                          "
                        >
                          <div style="display: flex; justify-content: center">
                            <div>
                              <span
                                *ngIf="
                                  barrierReward['reward'][
                                    'amountOrPercentageOffDiscount'
                                  ]['entitledType'] === 'PRODUCT'
                                "
                                >{{'SHARED_PAGE.PRODUCT' | i18nTranslate}} :
                              </span>
                              <span
                                *ngIf="
                                  barrierReward['reward'][
                                    'amountOrPercentageOffDiscount'
                                  ]['entitledType'] === 'PRODUCT'
                                "
                                >{{
                                  barrierReward["reward"][
                                    "amountOrPercentageOffDiscount"
                                  ]["entitledProducts"][0]["productName"]
                                }}</span
                              >
                            </div>
                          </div>
                          <div style="display: flex; justify-content: center">
                            <div>
                              <span
                                *ngIf="
                                  barrierReward['reward'][
                                    'amountOrPercentageOffDiscount'
                                  ]['entitledType'] === 'COLLECTION'
                                "
                                >{{'SHARED_PAGE.COLLECTION' | i18nTranslate}} :
                              </span>
                              <span
                                *ngIf="
                                  barrierReward['reward'][
                                    'amountOrPercentageOffDiscount'
                                  ]['entitledType'] === 'COLLECTION'
                                "
                                >{{
                                  barrierReward["reward"][
                                    "amountOrPercentageOffDiscount"
                                  ]["entitledCollection"][0]["collectionName"]
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          style="display: flex; justify-content: center"
                          *ngIf="
                            barrierReward['reward']['productPropensity'] ||
                            barrierReward['reward']['productCategoryPropensity']
                          "
                        >
                          <span>{{'SHARED_PAGE.PROPENSITY' | i18nTranslate}} :</span>
                          <span
                            *ngIf="
                              barrierReward['reward']['productPropensity'] !==
                              'null'
                            "
                            >{{
                              barrierReward["reward"]["productPropensity"]
                                | titlecase
                            }}</span
                          >
                          <span
                            *ngIf="
                              barrierReward['reward'][
                                'productCategoryPropensity'
                              ] !== 'null'
                            "
                            >{{
                              barrierReward["barrier"][
                                "productCategoryPropensity"
                              ] | titlecase
                            }}</span
                          >
                        </div>
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span
                              *ngIf="
                                barrierReward['reward']['rewardType'] ===
                                'PERCENTAGE_OFF'
                              "
                              >{{'SHARED_PAGE.DISCOUNT_PERCENTAGE' | i18nTranslate}} :
                            </span>
                            <span
                              *ngIf="
                                barrierReward['reward']['rewardType'] ===
                                'PERCENTAGE_OFF'
                              "
                              >{{
                                barrierReward["reward"][
                                  "amountOrPercentageOffDiscount"
                                ]["discountValue"]
                              }}</span
                            >
                          </div>
                        </div>
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span
                              *ngIf="
                                barrierReward['reward']['rewardType'] ===
                                'FIXED_AMOUNT_OFF'
                              "
                              >{{'SHARED_PAGE.DISCOUNT_AMOUNT' | i18nTranslate}} :
                            </span>
                            <span
                              *ngIf="
                                barrierReward['reward']['rewardType'] ===
                                'FIXED_AMOUNT_OFF'
                              "
                              >{{
                                barrierReward["reward"][
                                  "amountOrPercentageOffDiscount"
                                ]["discountValue"]
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          barrierReward['reward']['rewardType'] ===
                          'FREE_SHIPPING'
                        "
                      >
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span>{{'SHARED_PAGE.FREE_SHIPPING_TYPE' | i18nTranslate}} : </span>
                            <span>{{
                              barrierReward["reward"]["freeShippingDiscount"][
                                "entitledType"
                              ]
                            }}</span>
                          </div>
                        </div>
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span
                              *ngIf="
                                barrierReward['reward']['freeShippingDiscount'][
                                  'entitledType'
                                ] === 'MIN_ORDER_AMOUNT'
                              "
                              >{{'SHARED_PAGE.MIN_AMOUNT' | i18nTranslate}} :
                            </span>
                            <span
                              *ngIf="
                                barrierReward['reward']['freeShippingDiscount'][
                                  'entitledType'
                                ] === 'MIN_ORDER_AMOUNT'
                              "
                              >{{
                                barrierReward["reward"]["freeShippingDiscount"][
                                  "minOrderAmount"
                                ]
                              }}</span
                            >
                          </div>
                        </div>
                        <div style="display: flex; justify-content: center">
                          <div>
                            <span
                              *ngIf="
                                barrierReward['reward']['freeShippingDiscount'][
                                  'entitledType'
                                ] === 'MIN_ORDER_QUANTITY'
                              "
                              >{{'SHARED_PAGE.MIN_ORDER' | i18nTranslate}} :
                            </span>
                            <span
                              *ngIf="
                                barrierReward['reward']['freeShippingDiscount'][
                                  'entitledType'
                                ] === 'MIN_ORDER_QUANTITY'
                              "
                              >{{
                                barrierReward["reward"]["freeShippingDiscount"][
                                  "minOrderQuantity"
                                ]
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; justify-content: center">
                        <div>
                          <span>{{'SHARED_PAGE.DAYS_TO_REDEEM' | i18nTranslate}} : </span>
                          <span>{{
                            barrierReward["reward"]["daysToRedeem"]
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              <span
                *ngIf="i < data.obj.selectedbarrier.length - 1"
                style="
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div style="height: 30px; border-left: 1px solid #4abb59"></div>
                <div>
                  <mat-icon class="keyboardArrowDown">
                    keyboard_arrow_down</mat-icon
                  >
                </div>
                <div style="height: 30px; border-left: 1px solid #4abb59"></div>
              </span>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center"></div>
      </div>
    </div>
  </div>
</div>
