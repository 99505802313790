import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-partnership-agreement",
  templateUrl: "./partnership-agreement.component.html",
  styleUrls: ["./partnership-agreement.component.css"],
})
export class PartnershipAgreementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
