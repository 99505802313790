import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';

interface DialogData{
  smsText : any;
  type : string;
}

@Component({
  selector: 'app-sms-preview-dialog',
  templateUrl: './sms-preview-dialog.component.html',
  styleUrls: ['./sms-preview-dialog.component.css']
})
export class SmsPreviewDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SmsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tokenStorage: TokenStorageService) { }

  channel;
  shopName = "";
  layout: any;
  
  ngOnInit() {
    console.log(this.data);
    this.channel = this.data.type;
    this.shopName = this.tokenStorage.getShopName();
    this.layout = this.data.smsText;
    console.log(this.layout);
  }
  getLayout(layout: any, returnType: string): any {
    if (returnType === 'header') {
      if (layout['header']) {
        return layout['header'].toLowerCase();
      } else {
        return null;
      }
    } else if (returnType === 'buttons') {
      if (layout['buttons'] != null) {
        if (layout['buttons'].length > 0) {
          if (layout['buttons'][0]['quickReply']) {
            return layout['buttons'][0]['quickReply'];
          }
          if (layout['buttons'][0]['urlText']) {
            return layout['buttons'][0]['urlText'];
          }
        }
      }
    } else {
      return layout[returnType];
    }
  }

}
