import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnershipFormsService {

  private messageSource = new BehaviorSubject('');
  private messageSource2 = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  currentMessage2 = this.messageSource2.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeAction(action: string) {
    this.messageSource2.next(action);
  }
}
