import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavOperationService {

  constructor() { }

  public sidenavStatus: BehaviorSubject<any> = new BehaviorSubject(null);

  public setIndexForTemplate(status){
    this.sidenavStatus.next(status)
  }
}
