<head>
    <meta charset="utf-8">
      <title> {{'REGISTRATION_PAGE.DATA_INGESTION_HEADING' | i18nTranslate}} </title>
      <meta name="description" content="Onboarding">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
<div [style.height.px]="scrContentHeight">
    <div style="height:61px; background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000000F;
    opacity: 1; width:100%; display:flex; justify-content: flex-end;">
        <div style="display: flex;flex-direction: row;align-items: center; justify-content:space-between;">
            <!-- <div><span *ngIf="userName"
          style="text-align: left; font: 100 16px Gravity; letter-spacing: 0; color: #BEBEBE; opacity: 1; user-select: none; font-family:'Open sans';">{{userName}}</span>
      </div> -->
            <div class="headerIcons2 ">
                <div class="headerUserIcon gradient " [matMenuTriggerFor]="menu " style="cursor:pointer; ">
                    <span style="font-family:'Open sans';" *ngIf="userName">{{userName[0]}}</span>
                </div>
            </div>
            <mat-menu #menu="matMenu">
                <div class="logout-button" (click)="logout() ">{{'REGISTRATION_PAGE.LOGOUT' | i18nTranslate}}</div>
            </mat-menu>
        </div>
    </div>
    <div style="margin: 2%; padding-bottom: 2%;" *ngIf="dataFetchComplete; else incomplete;">
        <div class="heading-class" style="margin-bottom: 20px">
            <span style="font-size: 32px;
            "><strong>{{'REGISTRATION_PAGE.SELECT_PLATFORM' | i18nTranslate}}</strong></span>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;gap: 70px;">
            <!-- <div style="margin: 2%; display: flex; justify-content: center;">
                <div class="logo-container" (click)="onShopifyClick()">
                    <span style="display: flex; align-items: center; justify-content: center;">
          <img style="height: 100px;" [src]="assetPath.shopifyImg" alt="">
        </span>
                    <span class="logo-text">Shopify</span>
                </div>
            </div>
            <div style="margin: 2%; display: flex; justify-content: center;">
                <div class="logo-container" (click)="onShopifyClick()">
                    <span style="display: flex; align-items: center; justify-content: center;">
        </span>
                    <span class="logo-text">Others</span>
                </div>
            </div> -->


            <div style="background: #FFFFFF;
            border: 1px solid #E0E0E0;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 8px;padding: 30px 40px; cursor: pointer;" (click)="onShopifyClick()">
                <div style="display:flex;justify-content:center;margin-bottom: 10px;">{{'REGISTRATION_PAGE.CONTINUE_WITH' | i18nTranslate}}</div>
                <div>
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/shopify-data-ingestion.png">
                </div>
            </div>


            <div style="background: #FFFFFF;
            border: 1px solid #E0E0E0;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 8px;padding: 30px 40px; cursor: pointer;" (click)="onCloverClick()">
                <div style="display:flex;justify-content:center;margin-bottom: 10px; cursor: pointer;">{{'REGISTRATION_PAGE.CONTINUE_WITH' | i18nTranslate}}</div>
                <div>
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/clover-data-ingestion.png">
                </div>
            </div>
            <div style="background: #FFFFFF;
            border: 1px solid #E0E0E0;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 8px;padding: 30px 40px; cursor: pointer;" (click)="onOtherClick()">
                <div style="display:flex;justify-content:center;margin-bottom: 10px; cursor: pointer;">{{'REGISTRATION_PAGE.CONTINUE_WITH' | i18nTranslate}}</div>
                <div class="qb-text-setup">
                    {{'REGISTRATION_PAGE.OTHERS' | i18nTranslate}}
                </div>
            </div>


            <!-- <div style="margin: 2%; display: flex; justify-content: center;">
                <div class="logo-container" (click)="onCloverClick()">
                    <span style="display: flex; align-items: center; justify-content: center;">
        <img style="height: 100px;" src="https://www.businessrevieweurope.eu/wp-content/uploads/2019/11/clover-logo.jpg" alt="">
      </span>
                    <span class="logo-text">Clover</span>
                </div>
            </div> -->
        </div>

    </div>
    <ng-template #incomplete>
        <div style="height: 100%; display: flex; justify-content: center; align-items: center;">
            <app-qubriux-loading [size]="45" [loadingText]="'REGISTRATION_PAGE.HOLD_ON' | i18nTranslate"></app-qubriux-loading>
        </div>
    </ng-template>
    <!-- <div style="display:flex; justify-content:center;">
    <div>
      <app-shopify-data-ingestion *ngIf="pathParam == 'shopify'"></app-shopify-data-ingestion>
    </div>
  </div> -->
</div>