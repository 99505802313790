import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { MatDateFormats } from "@angular/material/core";
import { DatePipe } from "@angular/common";
import { getdateformatService } from "./get-date-format.service";

@Injectable({
  providedIn: "root",
})
export class DateFormatService {
  constructor(
    private date_format: getdateformatService
  ) {}

  datePipe = new DatePipe("en-US");

  formatDate(date) {
    if (date) {
      const temp = date.toString().replace(" ", "T");
      let newDate = new Date(temp);
      switch (sessionStorage.getItem("serviceCountry")) {
        case "IN":
          return this.datePipe.transform(newDate, this.date_format.getDateFormat("MMM/dd/yyyy"));
        case "US":
          return this.datePipe.transform(newDate, this.date_format.getDateFormat("MMM/dd/yyyy"));
        default:
          return this.datePipe.transform(newDate, this.date_format.getDateFormat("MMM/dd/yyyy"));
      }
    }
  }

  formatRawDate(date) {
    switch (sessionStorage.getItem("serviceCountry")) {
      case "IN":
        return this.datePipe.transform(date, "MMM/dd/yyyy");
      case "US":
        return this.datePipe.transform(date, "MMM/dd/yyyy");
      default:
        return this.datePipe.transform(date, "MMM/dd/yyyy");
    }
  }
}

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === "input") {
      let day: string = date.getDate().toString();
      day = +day < 10 ? "0" + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? "0" + month : month;
      let year = date.getFullYear();
      if (sessionStorage.getItem("serviceCountry") === "IND") {
        return `${day}-${month}-${year}`;
      }
      if (sessionStorage.getItem("serviceCountry") === "US") {
        return `${month}-${day}-${year}`;
      }
    }
    return date.toDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "numeric" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" },
  },
};
