import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-group-offer-sucess-dialog-box',
  templateUrl: './group-offer-sucess-dialog-box.component.html',
  styleUrls: ['./group-offer-sucess-dialog-box.component.css']
})
export class GroupOfferSucessDialogBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GroupOfferSucessDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiCallService: ApiCallService,
    private _snackBar:SnackbarCollection
  ) { }

  ngOnInit(): void {
    console.log("DATA",this.data)
  }
  submit(decision) {
    this.dialogRef.close(decision);
  }
}
