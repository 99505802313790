import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { GamificationComponent, FirsttimeGameComponent, SetGameConfigurationComponent, SingleGameAnalyticsComponent } from './pages';

const routes: Routes = [
  { path: '', component: GamificationComponent, canActivate: [WidgetRouteGaurdService], data: { titleheading: "Gamify", title: "Create Game", id: 37 } },
  {
    path: "how-to-create-a-game",
    component: FirsttimeGameComponent,
    canActivate: [WidgetRouteGaurdService],
    data: { titleheading: "Gamify", title: "How to Create Game", id: 37},
  },
  {
    path: "create-game",
    component: SetGameConfigurationComponent,
    canActivate: [WidgetRouteGaurdService],
    data: { titleheading: "Gamify", title: "Create Game", id: 37 },
  },
  {
    path:":gameName",
    component:SingleGameAnalyticsComponent,
    canActivate: [WidgetRouteGaurdService],
    data: { titleheading: "Gamify", title: "Gamify", id: 37 },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GamifyRoutingModule { }
