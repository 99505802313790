export const template2 = {
  page: {
    body: {
      container: {
        style: {
          "background-color": "transparent",
        },
      },
      content: {
        computedStyle: {
          linkColor: "#E69A8D",
          messageBackgroundColor: "transparent",
          messageWidth: "695px",
        },
        style: {
          color: "#000000",
          "font-family": "Roboto, Tahoma, Verdana, Segoe, sans-serif",
        },
      },
      type: "mailup-bee-page-properties",
      webFonts: [
        {
          fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
          name: "Roboto",
          url: "https://fonts.googleapis.com/css?family=Roboto",
        },
      ],
    },
    description: "",
    rows: [
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "695px",
                  },
                  image: {
                    alt: "LogoPlaceHolder",
                    height: "148px",
                    href: "",
                    percWidth: 25,
                    prefix: "",
                    src: "https://bee-editor-images-qa.s3.amazonaws.com/user/user-id-249/Text%20%26%20Icons_Header-02.png",
                    target: "_self",
                    width: "148px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "0435c0b5-0f33-4986-9195-1952abdf975d",
              },
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "695px",
                  },
                  image: {
                    alt: "Alternate text",
                    height: "1077px",
                    href: "",
                    src: "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/55d274b7-252b-45ed-bff0-a2b81c79627c/user-id-176/Its%20been%20a%20while.jpg",
                    width: "1920px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "1e7813e5-2d2d-4f3c-9c4c-eec250ae58de",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#E69A8D",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word;"> </p><p style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word;"><span style="font-size: 18px; line-height: 24px; color: #003366;" data-mce-style="font-size: 18px; line-height: 24px; color: #003366;"><strong>Dear {{customerName}},</strong></span></p></div>',
                    style: {
                      color: "#f4f4f4",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "639430d9-5540-43c8-9801-478afaecbe8c",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#E69A8D",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;"><span style="font-size: 58px; line-height: 69px; color: #003366;" data-mce-style="font-size: 58px; line-height: 69px; color: #003366;"><strong><span style="line-height: 69px; font-size: 58px;" data-mce-style="line-height: 69px; font-size: 58px;">IT&apos;S BEEN A WHILE</span></strong></span></p></div>',
                    style: {
                      color: "#f4f4f4",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "2f7927bf-fe9a-4a71-83af-7352e132c636",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "9d0a12b9-863c-4d85-8144-74426eb9e93c",
          },
        ],
        container: {
          style: {
            "background-color": "#b3e8ff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "695px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "cf862562-071a-45a0-b4d1-96f2ac7e2574",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "5px",
                    "padding-left": "5px",
                    "padding-right": "5px",
                    "padding-top": "5px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#E69A8D",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;"><p style="font-size: 12px; line-height: 21px; text-align: center; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 21px; text-align: center; word-break: break-word;"><span style="font-size: 18px; line-height: 36px; color: #003366;" data-mce-style="font-size: 18px; line-height: 36px; color: #003366;">You haven&apos;t stopped by in a while. We hope you haven&apos;t</span></p><p style="font-size: 12px; line-height: 21px; text-align: center; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 21px; text-align: center; word-break: break-word;"><span style="font-size: 18px; line-height: 36px; color: #003366;" data-mce-style="font-size: 18px; line-height: 36px; color: #003366;"> forgotten about us, because we&apos;re still thinking of you!</span></p></div>',
                    style: {
                      color: "#f4f4f4",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "180%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "99bc3dfe-ca1e-48d1-a02d-086a8d109b29",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#E69A8D",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;"><p style="font-size: 13px; line-height: 25px; text-align: center; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 25px; text-align: center; word-break: break-word;"><span style="color: #003366; font-size: 18px; line-height: 36px;" data-mce-style="color: #003366; font-size: 18px; line-height: 36px;"><strong>COME BACK NOW AND GET  <span style="line-height: 36px; font-size: 18px;" data-mce-style="line-height: 36px; font-size: 18px;">EXCITING OFFERS</span> ON <span style="line-height: 36px; font-size: 18px;" data-mce-style="line-height: 36px; font-size: 18px;">ALL ORDERS</span></strong></span></p></div>',
                    style: {
                      color: "#f4f4f4",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "180%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "97f54c18-1ee1-4157-b8a7-4cd076bddadd",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "7b3f1a35-0dad-49af-9ee9-48b4d246516d",
          },
        ],
        container: {
          style: {
            "background-color": "#b3e8ff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "695px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "77b949ed-486a-4448-8ef1-1b27720f73ed",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  button: {
                    href: "{{shopUrl}}",
                    label:
                      '<div class="txtTinyMce-wrapper" style="font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 24px;" data-mce-style="font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif; font-size: 12px; line-height: 24px;"><p style="font-size: 14px; line-height: 32px; word-break: break-word; font-family: Arial, &apos;Helvetica Neue&apos;, Helvetica, sans-serif;" data-mce-style="font-size: 14px; line-height: 32px; word-break: break-word;">Shop Now</p></div>',
                    style: {
                      "background-color": "#21709d",
                      "border-bottom": "0px solid transparent",
                      "border-left": "0px solid transparent",
                      "border-radius": "4px",
                      "border-right": "0px solid transparent",
                      "border-top": "0px solid transparent",
                      color: "#ffffff",
                      "font-family": "inherit",
                      "line-height": "200%",
                      "max-width": "100%",
                      "padding-bottom": "0px",
                      "padding-left": "20px",
                      "padding-right": "20px",
                      "padding-top": "0px",
                      width: "auto",
                    },
                  },
                  computedStyle: {
                    height: 31,
                    hideContentOnMobile: false,
                    width: 113,
                  },
                  style: {
                    "padding-bottom": "20px",
                    "padding-left": "20px",
                    "padding-right": "20px",
                    "padding-top": "20px",
                    "text-align": "center",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-button",
                uuid: "eb6481fe-ec2e-49b6-b62e-b557761ed264",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "1e542969-d1b0-4d42-b366-ed87f5edfe1d",
          },
        ],
        container: {
          style: {
            "background-color": "#b3e8ff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "695px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "5908eeed-3f17-4a26-b179-bae241e3fdc0",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#E69A8D",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; line-height: 14px; font-size: 12px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; line-height: 14px; font-size: 12px;"><p style="line-height: 14px; text-align: center; font-size: 12px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="line-height: 14px; text-align: center; font-size: 12px; word-break: break-word;"><span style="font-size: 16px; line-height: 21px; color: #003366;" data-mce-style="font-size: 16px; line-height: 21px; color: #003366;">See you soon!</span></p><p style="line-height: 14px; text-align: center; font-size: 12px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="line-height: 14px; text-align: center; font-size: 12px; word-break: break-word;"><span style="font-size: 14px; line-height: 19px; color: #003366;" data-mce-style="font-size: 14px; line-height: 19px; color: #003366;"><span style="font-size: 16px; line-height: 21px;" data-mce-style="font-size: 16px; line-height: 21px;">Grab your reward soon</span><span style="font-size: 16px; line-height: 21px;" data-mce-style="font-size: 16px; line-height: 21px;"><span style="line-height: 21px; font-size: 16px;" data-mce-style="line-height: 21px; font-size: 16px;">.</span></span></span></p></div>',
                    style: {
                      color: "#f4f4f4",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "ecf2c555-33c6-4599-826e-d146ee298ca7",
              },
              {
                descriptor: {
                  computedStyle: {
                    align: "center",
                    hideContentOnMobile: false,
                  },
                  divider: {
                    style: {
                      "border-top": "1px solid #2C437C",
                      width: "100%",
                    },
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                },
                type: "mailup-bee-newsletter-modules-divider",
                uuid: "5d3f3ccd-451b-47a4-8989-b5729be7eeda",
              },
              {
                descriptor: {
                  computedStyle: {
                    height: 57,
                    hideContentOnMobile: false,
                    iconsDefaultWidth: 32,
                    padding: "0 2.5px 0 2.5px",
                    width: 151,
                  },
                  iconsList: {
                    icons: [],
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                    "text-align": "center",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-social",
                uuid: "3687c5a2-cd8f-4c25-ace5-1866278b0d1e",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#E69A8D",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="line-height: 14px; font-size: 12px;" data-mce-style="line-height: 14px; font-size: 12px;"><p style="font-size: 13px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 13px; line-height: 16px; word-break: break-word; text-align: center;"><span style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;">Copyrights © 2024  I   To <span style="color: #00ccff; line-height: 14px;" data-mce-style="color: #00ccff; line-height: 14px;">unsubscribe</span> from these mailings, you may opt out here.</span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "bb20f544-9ac5-42a0-803f-85e1942f83ab",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "f457a76d-0fc6-4468-ba28-759b7c335fb0",
          },
        ],
        container: {
          style: {
            "background-color": "#b3e8ff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "695px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "da4234d5-9fa6-47bf-bdc5-8a9cfb24b77f",
      },
    ],
    template: {
      name: "template-base",
      type: "basic",
      version: "2.0.0",
    },
    title: "",
  },
  comments: null,
};
