<div class="qb-main-container"
  *ngIf="selectedTab=='games' || selectedTab==null">

  <div class="qb-main" id="viewconfigDiv" data-widget-name="GAME LISTING"
    *ngIf="(selectedTab=='games' || selectedTab==null)&&!loading">
    <div>
      <!-- <div class="q-game-all-heading">



        <button class="primary-button" (click)="createNewGame()" style="border: none;outline:none; "
          data-button-name="Create New Offer">
          <mat-icon style="color: #fff;margin: 0 ;font-size: 16px;height: 18px;">add</mat-icon>
          Create New Game
        </button>

      </div> -->

      <div class="qb-sub-container">

        <div class="qb-img-container"><img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/gamestep0.svg"
                alt=""></div>
        <div>
            <div class="qb-intro-msg-container">
                <div class="qb-gf-intro-text qb-intro-text-font-size">Increase Engagement in a fun way</div>
                <!-- <div class="primary-button" (click)="createNewGame()">Create New Game</div> -->
                <button class="primary-button qb-button-style" (click)="createNewGame()" 
                data-button-name="Create New Offer">
                <mat-icon class="qb-create-new-game">add</mat-icon>
                Create New Game
              </button>
            </div>
            <div class="qb-pr-200">
              <div class="qb-gf-intro-text qb-margin-between-text">Your possibilities to increase engagement and sales are endless with
                  <span class="qb-bold-text">Qubriux Gamification!</span>
              </div>
              <br>
              <div class="qb-gf-intro-text qb-margin-between-text">
                  Take your marketing to the next level. Engage your audience and attract new customers with games to
                  generate leads and collect data.
              </div>
            </div>
        </div>
    </div>



    </div>
    <div>
      <div class="qb-section-header qb-section-header2">
        <div class="qb-offer-list">Game List</div>
        <div class="qb-container-2">
          <div class="qb-formDiv-search qb-form-search">
            <input class="qb-formInput" [(ngModel)]="filterSearch" type="text" placeholder="Search by name"
              (keyup)="onKeySearch($event)">
            <span class="material-icons-outlined icons-primary" *ngIf="filterSearch.length==0">
              search
            </span>
            <span class="material-icons-outlined icons-primary qb-cursor-pointer"
              *ngIf="filterSearch.length>0" (click)="filterSearch='';onKeySearch('');">
              close
            </span>
            <!-- <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/home-screen/search.svg" alt="" > -->
          </div>
          <span class="material-icons-outlined icons-primary qb-cursor-pointer qb-filter-button" (click)="openFilterDialog()">
            filter_alt
          </span>
        </div>
      </div>
    </div>



    <div class="qb-table-wrapper">
      <div>
        <div class="qb-section-header qb-table-header" [style.padding-right.px]="rightMargin" id="tableHeads">

          <div class="qb-game-name-div">
            <span class="qb-section-title">Game Name</span>
          </div>
          <div class="qb-audince-div">

            <span class="qb-section-title qb-text-align">Target Audience</span>

          </div>
          <div class="qb-status-div">
            <span class="qb-section-title">Status</span>

          </div>

          <div class="qb-date-div">
            <span class="qb-section-title qb-text-align">Date </span>


          </div>

          <div class="qb-action-div">
            <span class="qb-section-title qb-text-align">Action </span>


          </div>
          <!-- <div style="width:13%;display: flex;
      background: #EDF0F2;
      height: 100%;
      align-items: center;
      ">



      </div> -->

        </div>
      </div>


      <div class="qb-table-content" id="side-menu-offer-parent" *ngIf="!loading && !dataNotAvailable">
        <div class="qb-width-100" *ngFor="let game of gameList; let i=index" id="side-menu-offer">
          <div class="qb-section-value qb-text-align qb-width-100">
            <div class="qb-list-value-ss qb-game-title" (click)="changeGame(game['id'], game['title'])">
              <span class="qb-section-title-r">{{game['title']}}</span>
            </div>
            <div class="qb-list-value-ss qb-target-audiance-div">

              <div class="qb-section-title-r qb-text-align">

                <span class="qb-segment-name-div"
                  *ngIf="game['targetAudience']['elements']!=null&&game['targetAudience']['elements'].length>0">
                  <span class="qb-offer-section-value qb-p-0"
                    *ngFor="let segmentName of game['targetAudience']['elements'] ;let i = index;last as islast;"
                    #segmentnamefield>{{segmentName}}
                    <span *ngIf="!islast">,&nbsp;</span></span>
                </span>
                <mat-icon class="qb-arrow-div"
                  [matMenuTriggerFor]="menusegment"
                  *ngIf="game['targetAudience']['elements']!=null &&game['targetAudience']['elements'].length>0">
                  double_arrow
                </mat-icon>
                <mat-menu #menusegment="matMenu">
                  <div class="qb-segment-name-padding"><span
                      *ngFor="let segmentName of game['targetAudience']['elements'];last as islastseg;">
                      {{segmentName}}<span *ngIf="!islastseg">,</span></span>
                  </div>
                </mat-menu>


              </div>

              <div class="qb-audiance-count">
                <span class="material-icons qb-person-size">person_outline</span><span
                  class="qb-count-font-size">{{game['targetAudience']['count']}}</span>
              </div>
            </div>
            <div class="qb-list-value-ss qb-flag-div">
              <div class="qb-section-title-r qb-flag-live" *ngIf="game['status']=='LIVE'">
                <span class="material-icons qb-flag"> flag </span>
                <span>Live</span>
              </div>
              <div class="qb-section-title-r qb-flag-scheduled" *ngIf="game['status']=='SCHEDULED'"><span
                  class="material-icons qb-flag"> flag </span>
                <span>Scheduled</span>
              </div>

              <div class="qb-section-title-r qb-flag-paused" *ngIf="game['status']=='PAUSED'"><span
                  class="material-icons qb-flag"> flag </span>
                <span>Paused</span>
              </div>

              <div class="qb-section-title-r qb-flag-draft" *ngIf="game['status']=='DRAFT'"><span
                  class="material-icons qb-flag"> flag </span>
                <span>Draft</span>
              </div>
              <div class="qb-section-title-r qb-flag-ended" *ngIf="game['status']=='ENDED'"><span
                  class="material-icons qb-flag"> flag </span>
                <span>Ended</span>
              </div>


            </div>

            <div class="qb-list-value-ss qb-date-data-div">
              <span class="qb-section-title-r qb-cap-italics">{{getDateListTitleText(game['status'])}}</span>
              <span class="qb-section-title-r qb-text-align"> {{game['lastUpdatedAt'] |
                date:getDateFormat('dd MMM yyyy')}} |
                {{ game['lastUpdatedAt'] | date : 'HH:mm' }}</span>


            </div>

            <div class="qb-list-value-ss qb-status-text-div">
              <div class="qb-section-title-r qb-text-align qb-width-100">
                <span class="qb-flag-paused qb-action" *ngIf="game['status']=='LIVE'&&(flag!='pause'||i != index)"
                  (click)="setAction('pause',game,i)">Pause</span>
                <mat-spinner class="qb-action" *ngIf="i == index&&flag=='pause'" [diameter]="12"></mat-spinner>
                <span class="qb-flag-ended qb-action" *ngIf="game['status']!='LIVE'&&(flag!='delete'||i != index)"
                  (click)="setAction('delete',game,i)">Delete</span>
                <mat-spinner class="qb-action" *ngIf="i == index&&flag=='delete'" [diameter]="12"></mat-spinner>
                <span class="qb-flag-draft qb-action" *ngIf="game['status']!='LIVE'&&(flag!='edit'||i != index)"
                  (click)="setAction('edit',game,i)">Edit</span>
                <mat-spinner class="qb-action" *ngIf="i == index&&flag=='edit'" [diameter]="12"></mat-spinner>
                <span class="qb-flag-draft qb-action" *ngIf="game['status']=='PAUSED'&&(flag!='resume'||i != index)"
                  (click)="setAction('resume',game,i)">Resume</span>
                <mat-spinner class="qb-action" *ngIf="i == index&&flag=='resume'" [diameter]="12"></mat-spinner>
              </div>


            </div>
          </div>
        </div>

        <div *ngIf="loading && !mobileView"
          class="qb-spinner-div">

          <mat-spinner diameter="22"></mat-spinner>

        </div>


        <!-- <div *ngIf="gameList.length==0&&!loading">
          <div class="qb-spinner-div">
            <div class="qb-empty-customers-div"> No data available
            </div>
          </div>
        </div> -->

      </div>
      <div *ngIf="dataNotAvailable" class="qb-empty-game-list">
        <div>No data available</div>
      </div>
      <div>
        <div class="qb-paginate-div">
          <mat-paginator #paginator [pageIndex]=0 [length]="totalGameCount" [pageSize]="size"
            [pageSizeOptions]="[5, 10, 25, 100]" (page)="getNextData($event)">
          </mat-paginator>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="loading" class="qb-spinner-div">
    <mat-spinner diameter="15"></mat-spinner>
  </div>


  <div class="qb-offer-dispatch-div" id="configDiv">

    <app-offer-dispatch-direct class="qb-div-100" (messageEvent2)="receiveMessage($event)"
      [offerId]="selectedOfferId" [channel]="selectedChannel" *ngIf='openReScheduleOverlay'>
    </app-offer-dispatch-direct>
  </div>
</div>
<div class="qb-negotiator-analytics-div" *ngIf="selectedTab=='analytics'">
  <app-negotiator-analytics></app-negotiator-analytics>
</div>