import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { getLogoUrlService } from "src/app/core/services/get-logo-url.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
})
export class NotFoundComponent implements OnInit {
  s3BucketUrl = environment.s3BucketUrl;
  imgUrl =
    this.s3BucketUrl + "/greyfox-assets/assets2/icons/Error_404_+Page.jpg";

  countStart: number = 10;

  cleanCount: any;
  userLogged = false;
  logoUrl = this.getLogoService.getLogo();

  constructor(
    private router: Router,
    private tokenStorage: TokenStorageService,
    private getLogoService: getLogoUrlService
  ) {}

  ngOnInit() {
    if (this.tokenStorage.getToken()) {
      this.userLogged = true;
    } else {
      this.userLogged = false;
    }
    this.looper();
  }

  looper() {
    this.countStart = 10;
    this.cleanCount = setInterval(() => {
      this.countStart--;
      if (this.countStart == 0) {
        if (this.userLogged) {
          this.router.navigate(["/auth/login"], {
            skipLocationChange: false,
            replaceUrl: true,
          });
        } else {
          window.open("www.qubriux.skellam.com");
          window.location.href = "https://qubriux.skellam.ai/";
        }
        clearTimeout(this.cleanCount);
      }
    }, 1000);
  }
}
