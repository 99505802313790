import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { assetPath } from "src/app/configs/asset-path-config";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { NotificationServiceService } from "src/app/core/services/notification-service.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import {
  PlanFeaturesComponent,
  ContactUsDialogComponent,
  ConfirmationDialogBoxComponent,
  SuccessDialogComponent,
} from "src/app/shared/components/dialog-box";
import { OAuthRedirectDialogComponent } from "src/app/shared/components/oauth-redirect-dialog/oauth-redirect-dialog.component";
import { environment } from "src/environments/environment";
import {
  ChangePasswordDialogComponent,
  AddUserComponent,
  DeleteUserComponent,
} from "../../components";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { CurrencySymbolPipe } from "src/app/shared/pipes";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { request } from "http";

export interface ValidationResult {
  [key: string]: boolean;
}

// export interface InclusionList{

// }
export class PasswordValidator {
  public static strong(control: FormControl): ValidationResult {
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    let hasSpecial = /[!@#$%^&*?]/.test(control.value);
    const valid = hasNumber && hasUpper && hasLower && hasSpecial;
    if (!valid) {
      // return what´s not valid
      return { hasNumber, hasUpper, hasLower, hasSpecial };
    }
    return null;
  }
}

@Component({
  selector: "app-profile-user",
  templateUrl: "./profile-user.component.html",
  styleUrls: ["./profile-user.component.css"],
})
export class ProfileUserComponent implements OnInit {
  s3BucketUrl = environment.s3BucketUrl;
  profileLayoutSelected: boolean = true;
  passwordLayoutSelected: boolean = false;
  billingLayoutSelected: boolean = false;
  emailLayoutSelected: boolean = false;
  domainLayoutSelected: boolean = false;
  brandingLayoutSelected: boolean = false;
  usermanagementLayoutSelected: boolean = false;
  communicationLayoutSelected: boolean = false;
  profiledataLoaded = true;
  forgotpasswordForm;
  mode: MatProgressBarModule = "determinate";
  profileAvatarUrl;
  s3BucketUrl2 = environment.s3BucketUrl;
  merchantData;
  _assetPath = {
    shopify: assetPath.path + "/shopify.png",
  };
  profileDataForm: FormGroup;
  // isCountryLoaded=false;
  country = "us";
  isCountryLoaded = true;
  fileToUpload: File = null;
  emailPercentage;
  smsPercentage;
  registrationForm;
  paymentHistoryBody;
  paymentItem;
  paymentdataPresent = false;
  currentPlanBody;
  loading = false;
  currentPlan;
  billingtypeSubscritpion;
  masterPlan;
  totalNotification;
  singleNotification;
  notificationLoading = false;
  submitted = false;
  smsCredits = 0;
  emailCredits = 0;
  totalSmsCredits = 0;
  totalEmailCredits = 0;
  showAccess3 = false;
  showAccess2 = false;
  showAccess1 = false;
  store;
  qid;

  InclusionAllList = [
    "EMAIL_MARKETING",
    "SMS_MARKETING",
    "CAMPAIGN_CREATION",
    "OFFER_CREATION",
    "LETS_PLAY",
  ];
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileMedia2 = window.matchMedia("(max-width: 500px)");
  mobileView;
  showPreview = false;
  currentPlanIncludedList = [];
  currentPlanExcludedList = [];
  layout;
  notificationList;
  badgeCount;
  notificationId;
  viewAllNotification = true;
  paymentSuccessStatus;
  validWidgets;
  appSubscriptionPlan;
  revCurrency;
  image = false;
  message;
  imagePath;
  url;
  files: any[] = [];
  isHovering: boolean;
  fileToUpload2: File = null;
  brandingRequest: {
    merchantId: null;
    hasSkip: false;
    socialMediaUrls: {};
  };
  numberOfLinks = ["1"];
  values = {};
  keys = [];
  links = [
    "Facebook",
    "Twitter",
    "Instagram",
    "LinkedIn",
    "TikTok",
    // "YouTube", "Pinterest", "Vkontakte", "Odnoklassniki", "Blogger", "Medium", "Tumblr", "SoundCloud", "Spotify", "Vimeo", "Vine", "SinaWeibo", "Glassdoor", "Snapchat", "Telegram", "Wechat", "TikTok", "Tripadvisor", "WhatsApp"
  ];
  selectedLinks = [];
  displayLink = this.links;
  logoUrl = null;
  Facebook =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Facebook.png";
  Instagram =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Instagram.png";
  Twitter =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Twitter.png";
  LinkedIn =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/LinkedIn.png";
  uploadedLogo = false;
  dataUser = [];
  filter = {
    label: "",
  };
  requestBody;
  profileImgLoading: boolean = false;
  urlPattern = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
  oAuthPlatforms: string[] = ["shopify", "lightspeed"];

  athorities = this.tokenStorage.getAuthorities();

  allowedAccount = [];

  openDialog(isPasswordGenerated) {
    const dialogRefDel = this.dialog.open(ChangePasswordDialogComponent, {
      panelClass: "custom-dialog-container",
      data: {
        isPasswordGenerated: isPasswordGenerated,
      },
    });
    dialogRefDel.afterClosed().subscribe((result) => {
      if (result) {
        this.isPasswordGenerated = true;
      }
    });
  }

  merchantSocials = null;

  @ViewChild("fileInput") el: ElementRef;

  @Output() messageEvent = new EventEmitter<string>();
  linkform = new FormGroup({
    name: new FormControl("", [Validators.required]),
    category: new FormControl("", [Validators.required]),
  });

  imageLoading = true;
  isDemo;
  popupElementID;

  constructor(
    private apiCall: ApiCallService,
    public fb: FormBuilder,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private globalservice: Globals,
    private dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private globalService: Globals,
    private _WidgetConfigService: WidgetConfigService,
    private datePipe: DatePipe,
    private snackbar: SnackbarCollection,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {
    window["screen-name"] = "PROFILE";
  }

  @HostListener("document:click", ["$event"])
  closeSegmentsDiv() {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
  }

  ngOnInit() {
    this.qid = this.tokenStorage.getqId();
    this.allowedAccount = environment.demoAccounts;

    this.isDemo = this.tokenStorage.getIsDemo();
    if (this.mobileMedia.matches) {
      this.mobileView = true;
      this.showPreview = false;
    } else {
      this.mobileView = false;
      this.showPreview = true;
    }
    this.store = this.tokenStorage.getMerchantOnlineStore();
    //this.getCredits();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    // this.getNotificationUpdates();
    this.getWidgets();
    this.route.queryParams.subscribe((params) => {
      this.layout = params.layout;
      if (this.layout == "profile") {
        this.layoutChange(this.layout);
      } else if (this.layout == "password") {
        this.layoutChange(this.layout);
      } else if (this.layout == "billing") {
        this.layoutChange(this.layout);
      } else if (this.layout == "user-management") {
        this.layoutChange(this.layout);
      } else if (this.layout == "notifications") {
        this.layoutChange(this.layout);
        if (params.id == "all") {
          this.notificationId = null;
          this.viewAllNotification = true;
        } else {
          this.notificationId = params.id;
        }

        this.getNotificationUpdates(this.notificationId);
      } else if (this.layout == "domain") {
        this.layoutChange(this.layout);
      } else if (this.layout == "branding") {
        this.layoutChange(this.layout);
      } else if (this.layout == "communication") {
        this.layoutChange(this.layout);
      } else {
        this.layoutChange("profile");
      }
    });
    // if(this.store ===  'shopify'){
    //   this.apiCall.fetchUserMerchantData().subscribe((response) => {
    //     if (response["message"] == "success") {
    //       this.merchantData = JSON.parse(response["body"]);
    //     }
    //   });
    // }
    // else{
    this.apiCall.getStoreData().subscribe(
      (response) => {
        this.merchantData = response["body"];
      },
      (error) => {}
    );
    // }

    this.profileDataForm = new FormGroup({
      name: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),

      emailid: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$"
        ),
      ]),
      role: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      country: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      city: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),

      zipcode: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      mobileno: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          Validators.minLength(5),
        ]),
      ]),
    });

    this.getMerchantProfiledata();
    // this.checkCountry();
    this.getPaymentHistory();
    this.getCurrentPlanDetals();
    // this.getSubscriptionPlandetails();

    if (!this.notificationId) {
      this.getNotificationUpdates();
    }

    this.forgotpasswordForm = new FormGroup({
      oldpassword: new FormControl("", [Validators.required]),
      password: new FormControl("", [
        PasswordValidator.strong,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?])[A-Za-zd!@#$%^&*?].{7,}"
          ),
          Validators.maxLength(20),
        ]),
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
    });

    // this.apiCall.getCurrentSubscriptionPlan().subscribe((response) => {
    //   this.loading = false;
    //   this.paymentSuccessStatus = true;
    //   if (response["message"] === "success") {
    //     let ccpb = JSON.parse(response["body"]);
    //     this.currentPlanBody = ccpb;
    //     if (this.currentPlanBody.subscriptionMasterPlanId == 1) {
    //       this.currentPlan = "BASIC";
    //     } else if (this.currentPlanBody.subscriptionMasterPlanId == 2) {
    //       this.currentPlan = "ADVANCED";
    //     } else if (this.currentPlanBody.subscriptionMasterPlanId == 3) {
    //       this.currentPlan = "PROFESSIONAL";
    //     } else this.currentPlan = "";
    //   }
    // });

    this.apiCall.getBrandingDetails().subscribe(
      (response) => {
        if (
          JSON.parse(response["body"]).logoUrl !==
          "https://bee-editor-images-qa.s3.amazonaws.com/user/user-id-249/Text%20%26%20Icons_Header-02.png"
        )
          this.logoUrl = JSON.parse(response["body"]).logoUrl;
        this.merchantSocials = JSON.parse(response["body"]).socialMediaUrls;
        this.values[0] = "";
        Object.keys(this.merchantSocials).map((key, value) => {
          if (value > 0) this.numberOfLinks.push((value + 1).toString());
          this.keys[value] = key;
          this.values[value] = this.merchantSocials[key];
        });
        let array = [];
        for (let key in this.keys) {
          let value = this.keys[key];
          array.push(value);
        }
        this.selectedLinks = array;
        this.imageLoading = false;
      },
      (error) => {
        this.values[0] = "";
        this.imageLoading = false;
      }
    );
  }

  async save() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      let save = true;
      let urlLength = true;
      Object.keys(this.values).forEach((value) => {
        if (!new RegExp(this.urlPattern).test(this.values[value])) {
          save = false;
          if (this.values[value].length < 1) urlLength = false;
        } else {
          save = true;
        }
      });
      if (save && urlLength) {
        let requestBody = {
          merchantId: this.tokenStorage.getMerchantId(),
          logoUrl: this.logoUrl,
          hasSkip: false,
          socialMediaUrls: {},
        };
        let linkMap = {};
        Object.keys(this.keys).map((key) => {
          Object.keys(this.values).map((value) => {
            if (key == value) {
              linkMap[this.keys[key]] = this.values[value];
            }
          });
        });
        requestBody.socialMediaUrls = linkMap;
        if (this.qid != "null") {
          requestBody["storeId"] = this.qid;
        }
        const fd = new FormData();
        fd.append("brandingRequest", JSON.stringify(requestBody));
        if (!this.isDemo)
          this.apiCall.updateBrandingDetails(fd).subscribe((response) => {
            this.snackBar.openSnackBar(
              this._i18nDynamicTranslate.transform("Branding updated", [
                "POS_PAGE",
              ]),
              2000
            );
          });
      } else {
        if (!save)
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Please enter valid social media links",
              ["POS_PAGE"]
            ),
            2000
          );
        if (!urlLength)
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Please enter the social media links",
              ["POS_PAGE"]
            ),
            2000
          );
      }
    }
  }

  addBranding() {
    this.imageLoading = true;
    let requestBody = {
      merchantId: this.tokenStorage.getMerchantId(),
      hasSkip: true,
      socialMediaUrls: {},
    };
    let linkMap = {};
    Object.keys(this.keys).map((key) => {
      Object.keys(this.values).map((value) => {
        if (key == value) {
          linkMap[this.keys[key]] = this.values[value];
        }
      });
    });
    if (Object.keys(linkMap).length > 0) requestBody.hasSkip = false;
    requestBody.socialMediaUrls = linkMap;
    const fd = new FormData();
    fd.append("file", this.fileToUpload2, this.fileToUpload2.name);
    fd.append("brandingRequest", JSON.stringify(requestBody));
    if (!this.isDemo)
      this.apiCall.uploadLogoForBranding(fd).subscribe(
        (response) => {
          this.logoUrl = JSON.parse(response["body"]).logoUrl;
          this.imageLoading = false;
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform("Logo updated", ["POS_PAGE"]),
            2000
          );
        },
        (error) => {
          this.imageLoading = false;
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform("Failed to update logo", [
              "POS_PAGE",
            ]),
            2000
          );
        }
      );
  }

  deleteLogo() {
    let brandingRequest = {
      merchantId: this.tokenStorage.getMerchantId(),
      logoUrl: this.logoUrl,
      hasSkip: false,
    };
    const fd = new FormData();
    fd.append("brandingRequest", JSON.stringify(brandingRequest));
    this.apiCall.deleteBrandingLogo(fd).subscribe(() => {
      this.uploadedLogo = false;
      this.logoUrl = null;
      this.fileToUpload2 = null;
      this.deleteFile(0);
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Logo deleted", ["POS_PAGE"]),
        2000
      );
    });
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getShopName(posProviderAccount) {
    if (JSON.parse(posProviderAccount)["shopname"])
      return JSON.parse(posProviderAccount)["shopname"];
    else return "";
  }

  addNewShop() {
    this.router.navigate(["/app/add-shop"]);
  }

  showPlanFeatures() {
    this.dialog.open(PlanFeaturesComponent, {
      data: this.currentPlanIncludedList,
    });
  }

  updatePassword() {
    if (this.forgotpasswordForm.valid) {
      if (
        this.forgotpasswordForm.controls.oldpassword.value ==
        this.forgotpasswordForm.controls.password.value
      ) {
        const dialogRef = this.dialog.open(ContactUsDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          height: "auto",
          disableClose: true,
          width: "380px",
          data: {
            header: this._i18nDynamicTranslate.transform("Error", ["POS_PAGE"]),
            body: this._i18nDynamicTranslate.transform(
              "New password should not be same as old password",
              ["POS_PAGE"]
            ),
          },
        });
        return 0;
      }
      this.submitted = true;
      this.closePopover();
      this.apiCall
        .changePassword(
          this.forgotpasswordForm.controls.oldpassword.value,
          this.forgotpasswordForm.controls.password.value
        )
        .subscribe(
          (result) => {
            if (result["message"] == "SUCCESS") {
              this.submitted = false;

              this.resetForgotPasswordForm();

              var data = result["body"];
              this.tokenStorage.saveToken(data.accessToken);
              this.tokenStorage.saveUsername(data.username);
              this.tokenStorage.saveAuthorities(data.authorities);
              this.tokenStorage.setUserLogin("true");
              this.submitted = false;
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: this._i18nDynamicTranslate.transform("Success", [
                    "POS_PAGE",
                  ]),
                  body: this._i18nDynamicTranslate.transform(
                    "Your password has been changed",
                    ["POS_PAGE"]
                  ),
                },
              });
            } else {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: this._i18nDynamicTranslate.transform("Warning", [
                    "POS_PAGE",
                  ]),
                  body: result["body"],
                },
              });
              this.submitted = false;
            }
          },
          (err) => {
            this.snackBar.openSnackBar(
              this._i18nDynamicTranslate.transform(err.error["message"], [
                "POS_PAGE",
              ]),
              2000
            );
            this.submitted = false;
          }
        );
    }
    this.submitted = false;
  }

  resetForgotPasswordForm() {
    this.forgotpasswordForm = new FormGroup({
      oldpassword: new FormControl("", [Validators.required]),
      password: new FormControl("", [
        PasswordValidator.strong,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?])[A-Za-zd!@#$%^&*?].{7,}"
          ),
          Validators.maxLength(20),
        ]),
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
    });
    this.forgotpasswordForm.markAsPristine();
    this.forgotpasswordForm.markAsUntouched();
    // this.forgotpasswordForm.reset();
  }
  togglePassword(key: string) {
    if (key === "accessKey1") {
      this.showAccess1 = !this.showAccess1;
    }
    if (key === "accessKey2") {
      this.showAccess2 = !this.showAccess2;
    }
    if (key === "accessKey3") {
      this.showAccess3 = !this.showAccess3;
    }
  }

  getNotificationUpdates(layoutId?) {
    this.notificationLoading = true;
    var data;
    this.apiCall.getNotification(0, 10).subscribe((response) => {
      this.totalNotification = response["body"]["totalElements"];
      this.badgeCount = response["body"]["unSeenNotifications"];
      this.notificationList = response["body"]["notifications"];
      if (layoutId) {
        this.notificationIdDetails(layoutId);
      }
    });

    this.notificationLoading = false;
  }

  getNextData(event) {
    this.apiCall
      .getNotification(event.pageIndex, event.pageSize)
      .subscribe((response) => {
        this.totalNotification = response["body"]["totalElements"];
        this.badgeCount = response["body"]["unSeenNotifications"];
        this.notificationList = response["body"]["notifications"];
      });
  }

  notificationIdDetails(id) {
    this.notificationLoading = true;
    this.viewAllNotification = false;
    var i;
    for (i = 0; i <= this.notificationList.length; i++) {
      if (id == this.notificationList[i]["notificationId"]) {
        this.singleNotification = this.notificationList[i];
        this.loading = false;
        this.apiCall.updateNotification(id).subscribe((response) => {
          this.loading = false;
          this.notificationService.getNotification(0, 10);
        });
        break;
      }
    }
    this.loading = false;
    this.notificationLoading = false;
  }

  layoutChange(layout: string) {
    // const queryParams: Params = { layout: layout };
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: queryParams,
    //   // remove to replace all query params by provided
    // });
    if (layout == "notifications") {
      this.changequeryParam(layout);
    }
    if ("profile" == layout) {
      // const queryParams: Params = { layout: 'profile' };
      // this.router.navigate(
      //   [],
      //   {
      //     relativeTo: this.route,
      //     queryParams: queryParams,
      //     // remove to replace all query params by provided
      //   });
      this.profileLayoutSelected = true;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.emailLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = false;
    } else if ("password" == layout) {
      // const queryParams: Params = { layout: 'password' };
      // this.router.navigate(
      //   [],
      //   {
      //     relativeTo: this.route,
      //     queryParams: queryParams,
      // // remove to replace all query params by provided
      //   });
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = true;
      this.billingLayoutSelected = false;
      this.emailLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = false;
    } else if ("billing" == layout) {
      // const queryParams: Params = { layout: 'billing' };
      // this.router.navigate(
      //   [],
      //   {
      //     relativeTo: this.route,
      //     queryParams: queryParams,
      //     // remove to replace all query params by provided
      //   });
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = true;
      this.emailLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = false;
    } else if ("notifications" == layout) {
      this.emailLayoutSelected = true;
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = false;

      //this.getNotificationUpdates();
    } else if ("domain" == layout) {
      const queryParams: Params = { layout: "domain" };
      this.emailLayoutSelected = false;
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.domainLayoutSelected = true;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = false;

      // this.router.navigate(
      //   [],
      //   {
      //     relativeTo: this.route,
      //     queryParams: queryParams,
      //      // remove to replace all query params by provided
      //   });
      //this.getNotificationUpdates();
    } else if ("branding" == layout) {
      const queryParams: Params = { layout: "branding" };
      this.brandingLayoutSelected = true;
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.emailLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = false;
    } else if ("user-management" == layout) {
      this.emailLayoutSelected = false;
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = true;
      this.communicationLayoutSelected = false;
      this.getUser();
    } else if ("communication" == layout) {
      this.emailLayoutSelected = false;
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
      this.communicationLayoutSelected = true;
    } else {
      this.profileLayoutSelected = false;
      this.passwordLayoutSelected = false;
      this.billingLayoutSelected = false;
      this.emailLayoutSelected = false;
      this.domainLayoutSelected = false;
      this.brandingLayoutSelected = false;
      this.communicationLayoutSelected = false;
      this.usermanagementLayoutSelected = false;
    }
  }

  endDate;
  name;
  city;
  counttry;
  phone;
  email;
  role;
  zipcode;
  isPasswordGenerated;
  isAdmin;
  getMerchantProfiledata() {
    this.apiCall.getMerchantProfileData().subscribe((result) => {
      this.name = result["body"]["name"];
      this.city = result["body"]["city"];
      this.email = result["body"]["email"];
      this.phone = result["body"]["phoneNumber"];
      this.isPasswordGenerated = result["body"]["isPasswordGenerated"];
      this.zipcode = result["body"]["zipCode"];

      this.counttry = result["body"]["country"];
      this.loading = true;
      let profile = result["body"];
      this.profileAvatarUrl = result["body"]["avatarUrl"];
      this.isAdmin = result["body"]["isAdmin"];
      if (this.profileAvatarUrl) {
        this.globalservice.setNewAvatarUploaded(this.profileAvatarUrl);
      }
      this.addUserdataFormDetails(profile);

      this.loading = false;
    });
  }
  addUserdataFormDetails(profile) {
    this.profileDataForm = new FormGroup({
      name: new FormControl({ value: profile.name, disabled: true }, [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),

      emailid: new FormControl({ value: profile.email, disabled: true }, [
        Validators.required,
        Validators.pattern(
          "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$"
        ),
      ]),
      role: new FormControl({ value: "User", disabled: true }, [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      country: new FormControl({ value: profile.country, disabled: true }, [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      city: new FormControl({ value: profile.city, disabled: true }, [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      zipcode: new FormControl({ value: profile.zipCode, disabled: true }, [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ]),
      mobileno: new FormControl(
        { value: profile.phoneNumber, disabled: true },
        [
          Validators.compose([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            Validators.minLength(5),
          ]),
        ]
      ),
    });
  }
  setFiles(event: Event) {
    this.profileImgLoading = true;

    // Ensure event target is properly typed
    const input = event.target as HTMLInputElement;

    // Check if files exist
    if (input.files && input.files.length > 0) {
      this.fileToUpload = input.files[0]; // Get the first file from input

      const fd = new FormData();

      // Ensure the file is appended correctly
      fd.append("file", this.fileToUpload, this.fileToUpload.name);

      // Make API call to upload the file
      this.apiCall.uploadAvatar(fd).subscribe(
        (response) => {
          if (
            response["message"] === "success" &&
            typeof response["body"] === "string" &&
            response["body"].toLowerCase() === "success"
          ) {
            this.sendMessage();
            this.getMerchantProfiledata();
          } else {
            let errorMessage = this._i18nDynamicTranslate.transform(
              "Image size too big, please upload an image under 10MB",
              ["POS_PAGE"]
            );
            if (
              response["body"] &&
              response["body"]["message"] === "Content type is not image"
            ) {
              errorMessage = this._i18nDynamicTranslate.transform(
                "Please upload a valid image format.",
                ["POS_PAGE"]
              );
            }
            this.snackbar.openSnackBar(errorMessage, 4000);
          }
          this.profileImgLoading = false;
        },
        (error) => {
          // Handle error scenarios
          this.profileImgLoading = false;
          this.snackbar.openSnackBar(
            "An error occurred during the upload.",
            4000
          );
        }
      );
    } else {
      this.profileImgLoading = false;
      this.snackbar.openSnackBar("No file selected.", 4000);
    }
  }

  sendMessage() {
    this.messageEvent.emit("image-updated");
  }

  checkCountry() {
    let myItem = localStorage.getItem("country");
    if (myItem == null) {
      this.apiCall.getIPinfo().subscribe((result) => {
        let vari = result;
        this.country = result["country_code"];
        if (result["success"] == "false") {
          this.country = "us";
          this.isCountryLoaded = true;
        } else {
          if (this.country != undefined) {
            this.isCountryLoaded = true;
          } else {
            this.country = "us";
            this.isCountryLoaded = true;
          }
        }
        localStorage.setItem("country", this.country);
      }),
        (error) => {
          this.country = "us";
          this.isCountryLoaded = true;
        };
    } else {
      this.country = myItem;
      this.isCountryLoaded = true;
    }
  }

  telInputObject(obj) {}

  getPaymentHistory() {
    this.paymentdataPresent = false;
    this.apiCall.getPaymentHistory().subscribe((response) => {
      this.paymentHistoryBody = response["body"];

      this.paymentItem = this.paymentHistoryBody["paymentItems"];
      if (this.paymentItem.length === 0) {
        this.paymentItem = null;
      }
      this.paymentdataPresent = true;
    });
  }

  // getCredits() {
  //   this.apiCall.getRemainingCredits().subscribe(response => {
  //     this.smsCredits = JSON.parse(response['body'])[0].availableCredits;
  //     this.totalSmsCredits = JSON.parse(response['body'])[0].totalCredits;
  //     this.emailCredits = JSON.parse(response['body'])[1].availableCredits;
  //     this.totalEmailCredits = JSON.parse(response['body'])[1].totalCredits;
  //     this.smsPercentage = this.smsCredits / this.totalSmsCredits * 100;
  //     this.emailPercentage = this.emailCredits / this.totalEmailCredits * 100;
  //   })
  // }

  startDate;

  getCurrentPlanDetals() {
    if (this.tokenStorage.getMerchantOnlineStore() === "shopify") {
      this.apiCall.getCurrentPlan().subscribe((response) => {
        this.startDate = response["body"]["startDate"];
        this.loading = false;
        if (response["message"] === "success") {
          let ccpb = response["body"];
          this.currentPlanBody = ccpb;
          this.endDate = this.datePipe.transform(
            this.currentPlanBody["endDate"],
            "dd MMMM, yyyy"
          );
          // this.getSubscriptionPlandetails(
          //   this.currentPlanBody["subscriptionMasterPlanId"] - 1
          // );

          let trialdaysRemaining = this.currentPlanBody.trialDaysRemaining;
          if (trialdaysRemaining == null) {
            this.globalService.trialperiodBannerUpdate(0);
          } else {
            this.globalService.trialperiodBannerUpdate(trialdaysRemaining);
          }
        }
        // if (this.currentPlanBody["endDate"] == null) {
        //   this.billingtypeSubscritpion = true;
        // }
      });
    } else {
      this.apiCall.getCurrentPlanPos().subscribe((response) => {
        this.startDate = response["body"]["startDate"];
        this.loading = false;
        if (response["message"] === "SUCCESS") {
          let ccpb = response["body"];
          this.currentPlanBody = ccpb;
          this.endDate = this.datePipe.transform(
            this.currentPlanBody["endDate"],
            "dd MMMM, yyyy"
          );
          // this.getSubscriptionPlandetails(
          //   this.currentPlanBody["subscriptionMasterPlanId"] - 1
          // );

          // let trialdaysRemaining = this.currentPlanBody.trialDaysRemaining;
          // if (trialdaysRemaining == null) {
          //   this.globalService.trialperiodBannerUpdate(0);
          // } else {
          //   this.globalService.trialperiodBannerUpdate(trialdaysRemaining);
          // }
        }
        // if (this.currentPlanBody["endDate"] == null) {
        //   this.billingtypeSubscritpion = true;
        // }
      });
    }
  }

  // getSubscriptionPlandetails(planId) {
  //   this.apiCall.getAllSubscriptionMasterPlan("USD").subscribe((response) => {
  //     if (response["message"] === "success") {
  //       this.masterPlan = JSON.parse(response["body"]);
  //       this.currentPlanIncludedList = this.masterPlan[planId]["inclusions"];
  //       this.currentPlanExcludedList = [];
  //     }
  //   }),
  //     (err) => {};
  // }

  navigate(page) {
    if ("upgradePlan" == page) {
      this.router.navigate(["/app/payments/payment-plan"]);
    }
  }

  cancelSubscription() {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "30%",
      data: {
        subject: this._i18nDynamicTranslate.transform(
          "Do you want to cancel current subscription?",
          ["POS_PAGE"]
        ),
        message: "",
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", [
          "POS_PAGE",
        ]),
        successButtonText: this._i18nDynamicTranslate.transform("Ok", [
          "POS_PAGE",
        ]),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.cancelSubscription().subscribe(
          (response) => {
            this.snackBar.openSnackBar(
              this._i18nDynamicTranslate.transform("Subscription cancelled", [
                "POS_PAGE",
              ]),
              2000
            );
            this.getCurrentPlanDetals();
            this.getPaymentHistory();
            // this.currentPlanService.getPlanStatus();
          },
          (err) => {
            this.snackBar.openSnackBar(
              this._i18nDynamicTranslate.transform("Error", ["POS_PAGE"]),
              2000
            );
          }
        );
      }
    });
  }

  changequeryParam(layout) {
    //   if(layout=='notifications'){
    //     this.getNotificationUpdates();
    //   }
    //   this.router.navigate([], {queryParams:{
    //     "layout": layout,}
    // });
  }
  myaccountRoute(layout, id?) {
    this.router.navigate(["app/user-profile"], {
      queryParams: { layout: layout, id: id },
    });
  }
  displayPopover(event) {
    document.getElementById("passwordPopOver").style.display = "block";
  }
  closePopover() {
    document.getElementById("passwordPopOver").style.display = "none";
  }

  notificationBegin() {
    this.router.navigate(["app/user-profile"], {
      queryParams: { layout: "notifications" },
    });
  }

  reRoute(route) {
    this.router.navigate(["/app/app-home"], {
      queryParams: { type: "Objectives" },
    });
  }

  async onFileDropped($event) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.prepareFilesList($event);
      this.setFiles($event);
    }
  }

  setFiles2(event) {
    this.image = true;
    const files = event.target.files;
    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = this._i18nDynamicTranslate.transform(
        "Only images are supported.",
        ["POS_PAGE"]
      );
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }

  async fileBrowseHandler(event, files) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      let allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.indexOf(files[0].type) !== -1) {
        this.imageLoading = true;
        this.prepareFilesList(files);
        this.setFiles2(event);
        this.fileToUpload2 = <File>event.target.files[0];
        if (this.mobileView) this.uploadedLogo = true;
        this.addBranding();
      } else {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Invalid file type", [
            "POS_PAGE",
          ]),
          2000
        );
      }
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  addNewLink() {
    this.values[this.numberOfLinks.length] = "";
    this.numberOfLinks.push(this.numberOfLinks.length + "2");
  }

  removeLink(i) {
    if (i !== -1) {
      this.numberOfLinks.splice(i, 1);
      this.selectedLinks.splice(i, 1);
    }
    delete this.keys[i];
    delete this.values[i];
    let tempKeys = this.keys;
    this.keys = [];
    let index = 0;
    while (index < Object.keys(tempKeys).length) {
      Object.keys(tempKeys).map((key) => {
        this.keys[index] = tempKeys[key];
        index++;
      });
    }
    let tempValues = this.values;
    this.values = {};
    index = 0;
    while (index < Object.keys(tempValues).length) {
      Object.keys(tempValues).map((key) => {
        this.values[index] = tempValues[key];
        index++;
      });
    }
  }

  changeLink(link, i) {
    this.keys[i] = link.value;
    let array = [];
    for (let key in this.keys) {
      let value = this.keys[key];
      array.push(value);
    }
    this.selectedLinks = array;
  }

  input(event) {
    this.values[event.srcElement.id] = event.target.value;
  }
  date: string;
  userMail: string;
  isDate: Boolean;
  getUser() {
    // this.dataUser=[{
    //   "name" : 'Ben',
    //   "email": "damian@skellam.ai",
    //   "memberSince": "date/invite sent/invite expired"
    // },
    // {
    //   "name" : 'Alan',
    //   "email": "damian@skellam.ai",
    //   "memberSince": "date/invite sent/invite expired"
    // },
    // {
    //   "name" : 'Light',
    //   "email": "damian@skellam.ai",
    //   "memberSince": "invite expired"
    // }];
    // this.apiCall.GetUserManagementUser()
    this.userMail = this.tokenStorage.getUsername();
    this.apiCall.GetUserManagementUser().subscribe((response) => {
      this.dataUser = response["body"];
      for (const x in response["body"]) {
        if (
          response["body"][x]["isAdmin"] == true &&
          response["body"][x]["isLoggedIn"] == true
        ) {
          this.isAdmin = true;
          break;
        }
      }
    });
  }
  async addNewUser() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(AddUserComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "783px",
        height: "581px",
        data: {
          sendAgain: false,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const dialogRef2 = this.dialog.open(SuccessDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "20%",
            data: {
              message: this._i18nDynamicTranslate.transform(
                "Invite has been sent successfully",
                ["POS_PAGE"]
              ),
              buttonText: this._i18nDynamicTranslate.transform("OK", [
                "POS_PAGE",
              ]),
            },
          });
          this.getUser();
          dialogRef2.afterClosed();
        } else {
          dialogRef.close();
        }
        // var ele=
        // ele.scrollIntoView();
      });
    }
  }
  async DeleteUser(uname, email) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(DeleteUserComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "428px",
        height: "183px",
        data: {
          username: uname,
          email: email,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.openSuccessDialog("User deleted successfully.", "OK");
        this.getUser();
      });
      //this.getUser();
    }
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
  }

  async SendMailAgain(emailUser, userId, name, role, storeId, phone, stores) {
    // let requestBody={
    //   //"merchantId":this.tokenStorage.getMerchantId(),
    //   "name":null,
    //   "email":emailUser,
    //   "sendAgain":true
    // }
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(AddUserComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "783px",
        height: "581px",
        data: {
          name: name,
          emailId: emailUser,
          userId: userId,
          sendAgain: true,
          role: role,
          storeId: storeId,
          countryCode: this.getCountryCodePhoneNumber(phone),
          phone: this.getPhoneNumberWithoutCode(phone),
          stores: stores,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const dialogRef2 = this.dialog.open(SuccessDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "20%",
            data: {
              message: this._i18nDynamicTranslate.transform(
                "Invite has been sent again successfully",
                ["POS_PAGE"]
              ),
              buttonText: this._i18nDynamicTranslate.transform("OK", [
                "POS_PAGE",
              ]),
            },
          });
          this.getUser();
          dialogRef2.afterClosed();
        } else {
          dialogRef.close();
        }
        // var ele=
        // ele.scrollIntoView();
      });

      // this.apiCall.AddUserManagementUser(emailUser,name,true,userId).subscribe((response)=>{
      //   this.getUser();
      // })
    }
  }
  redirectToShopHomePage(id) {
    if (this.store === "shopify") {
      let url = "https://" + this.tokenStorage.getShopUrl();
      if (url) {
        window.open(url, "_blank");
      }
    } else if (this.store !== "shopify") {
      this.redirectToShopEditPage(id);
    }
  }
  redirectToShopEditPage(id) {
    //   if(this.store !== 'shopify'){

    // }
    this.router.navigate(["/app/user-profile/store-info"], {
      queryParams: { id: id },
    });
  }
  addNewStoreRedirect() {
    if (
      this.oAuthPlatforms.includes(this.tokenStorage.getMerchantOnlineStore())
    ) {
      this.oauthRedirect();
    } else {
      this.router.navigate(["/app/user-profile/store-info"]);
    }
  }

  oauthRedirect() {
    const dialogRef = this.dialog.open(OAuthRedirectDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "380px",
      data: {
        platform: this.tokenStorage.getMerchantOnlineStore(),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  changeCurrency(data) {
    this.revCurrency = data;
    localStorage.setItem("serviceCurrency", data);
    sessionStorage.setItem("serviceCurrency", data);
  }
  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }
  getUserType(type) {
    if (type === "ROLE_STORE_MANAGER") {
      return "Store Manager";
    } else if (type === "ROLE_USER") {
      return "Delegate Admin";
    } else if (type === "ROLE_CUSTOMER_CARE") {
      return "Customer Care";
    } else if (type === "ROLE_CUSTOMER_CARE_VIEW") {
      return "Customer Care View Only";
    } else if (type === "ROLE_REPORTING_VIEW") {
      return "View Report User";
    }
  }
  showSegments(event, i) {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
    let id = event.srcElement.parentElement.id;
    event.stopPropagation();
    let element = document.getElementById("popup" + i);
    element.setAttribute("style", "display:flex");
    this.popupElementID = "popup" + i;
  }
  getCountryCodePhoneNumber(phone) {
    let len = phone.length;
    let number = phone.substring(0, len - 10);
    return number;
  }
  getPhoneNumberWithoutCode(phone) {
    let len = phone.length;
    let number = phone.substring(len - 10, phone.length);
    return number;
  }
}
