import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { FilterDataServiceService } from "src/app/core/services/filter-data-service.service";

@Component({
  selector: "app-campaign-filter-dialog",
  templateUrl: "./campaign-filter-dialog.component.html",
  styleUrls: ["./campaign-filter-dialog.component.css"],
})
export class CampaignFilterDialogComponent implements OnInit {
  segments = [];
  filterSubscription: Subscription;
  filters;
  loading = true;
  currentFilters;
  showClearAll = false;
  favouriteArray = ["STARRED", "UNSTARRED"];
  statusArray = ["ACTIVE", "DRAFT", "ENDED", "SCHEDULED"];

  constructor(
    public dialogRef: MatDialogRef<CampaignFilterDialogComponent>,
    private apiCall: ApiCallService,
    private filterData: FilterDataServiceService
  ) {}

  ngOnInit() {
    this.getSegments();
    this.getSelectedFilters();
    this.currentFilters = this.filterData.getFilters();
  }

  getSegments() {
    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      JSON.parse(response["body"]).forEach((element) => {
        //this.segments[element["id"]] = element["segmentName"];
        this.segments.push({
          id:element["id"],
          segmentName: element["segmentName"],
          isActive: element["isActive"]
        })
      });
      this.loading = false;
    });
  }

  getSelectedFilters() {
    this.filters = this.filterData.getFilters();
    if (this.filters["star"] === undefined) {
      this.filters["star"] = [];
      this.filters["status"] = [];
      this.filters["segments"] = [];
    }
    // else {
    // if(this.filters['star'].length == 0)
    //   this.filters['star'] = ['ALL']
    // }
    // if(this.filters['status'].length == 0)
    //   this.filters['status'] = ['ALL']
    // if(this.filters['segments'].length == 0)
    //   this.filters['segments'] = ['ALL']
    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters["segments"].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  applyFilter() {
    this.filters["addFilters"] = true;
    this.filterData.setFilters(this.filters);
  }

  addFilter(value) {
    // if(event.target.checked) {
    //   if(event.target.name === "ALL")
    //     this.filters['star'] = [];
    //   else {
    //     let index = this.filters['star'].indexOf("ALL");
    //     if (index !== -1)
    //       this.filters['star'].splice(index, 1);
    //   }
    //   this.filters['star'].push(event.target.name)
    // } else {
    //   const index = this.filters['star'].indexOf(event.target.name, 0);
    //   if (index > -1)
    //     this.filters['star'].splice(index, 1);
    // }
    if (this.filters["star"].indexOf(value) != -1) {
      this.filters["star"].splice(this.filters["star"].indexOf(value), 1);
      if (this.filters["star"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["star"].splice(this.filters["star"].indexOf("ALL"), 1);
      }
    } else {
      this.filters["star"].push(value);
      if (value === "ALL")
        this.favouriteArray.forEach((customer) => {
          if (this.filters["star"].indexOf(customer) == -1)
            this.filters["star"].push(customer);
        });
    }
    this.favouriteArray.forEach((customer) => {
      if (this.filters["star"].indexOf(customer) == -1)
        if (this.filters["star"].indexOf("ALL") != -1)
          this.filters["star"].splice(this.filters["star"].indexOf("ALL"), 1);
    });
    // if(this.favouriteArray.length == this.filters['star'].length) {
    //   if(this.filters['star'].indexOf('ALL') == -1) {
    //     this.filters['star'].push('ALL');
    //   }
    // }

    if (value === "ALL") {
      if (this.filters["star"].indexOf("ALL") == -1) {
        this.filters["star"] = [];
      }
    }

    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters["segments"].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  addStatusFilter(value) {
    // if(event.target.checked) {
    //   if(event.target.name === "ALL")
    //     this.filters['status'] = [];
    //   else {
    //     let index = this.filters['status'].indexOf("ALL");
    //     if (index !== -1)
    //       this.filters['status'].splice(index, 1);
    //   }
    //   this.filters['status'].push(event.target.name)
    // } else {
    //   const index = this.filters['status'].indexOf(event.target.name, 0);
    //   if (index > -1)
    //     this.filters['status'].splice(index, 1);
    // }

    if (this.filters["status"].indexOf(value) != -1) {
      this.filters["status"].splice(this.filters["status"].indexOf(value), 1);
      if (this.filters["status"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["status"].splice(this.filters["status"].indexOf("ALL"), 1);
      }
    } else {
      this.filters["status"].push(value);
      if (value === "ALL")
        this.statusArray.forEach((customer) => {
          if (this.filters["status"].indexOf(customer) == -1)
            this.filters["status"].push(customer);
        });
    }
    this.statusArray.forEach((customer) => {
      if (this.filters["status"].indexOf(customer) == -1)
        if (this.filters["status"].indexOf("ALL") != -1)
          this.filters["status"].splice(
            this.filters["status"].indexOf("ALL"),
            1
          );
    });
    if (value === "ALL") {
      if (this.filters["status"].indexOf("ALL") == -1) {
        this.filters["status"] = [];
      }
    }
    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters["segments"].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  addSegmentFilter(value) {
    // if(event.target.checked) {
    //   if(event.target.name === "ALL")
    //     this.filters['segments'] = [];
    //   else {
    //     let index = this.filters['segments'].indexOf("ALL");
    //     if (index !== -1)
    //       this.filters['segments'].splice(index, 1);
    //   }
    //   this.filters['segments'].push(event.target.name)
    // } else {
    //   const index = this.filters['segments'].indexOf(event.target.name, 0);
    //   if (index > -1) {
    //     this.filters['segments'].splice(index, 1);
    //   }
    // }
    if (this.filters["segments"].indexOf(value) != -1) {
      this.filters["segments"].splice(
        this.filters["segments"].indexOf(value),
        1
      );
      if (this.filters["segments"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["segments"].splice(
          this.filters["segments"].indexOf("ALL"),
          1
        );
      }
    } else {
      this.filters["segments"].push(value);
      if (value === "ALL")
        for (let i=0; i< this.segments.length; i++) {
          if (this.filters["segments"].indexOf(this.segments[i].id) == -1 && this.segments[i].isActive === true)
            this.filters["segments"].push(this.segments[i].id);
        }
    }
    for (let i=0; i< this.segments.length; i++) {
      if (this.filters["segments"].indexOf(this.segments[i].id) == -1)
        if (this.filters["segments"].indexOf("ALL") != -1)
          this.filters["segments"].splice(
            this.filters["segments"].indexOf("ALL"),
            1
          );
    }
    if (value === "ALL") {
      if (this.filters["segments"].indexOf("ALL") == -1) {
        this.filters["segments"] = [];
      }
    }
    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters["segments"].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  clear() {
    this.filters["star"] = [];
    this.filters["status"] = [];
    this.filters["segments"] = [];
    this.showClearAll = false;
  }
}
