import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { SuccessDialogComponent, UploadCsvDialogBoxComponent } from 'src/app/shared/components/dialog-box';

@Component({
  selector: 'app-store-data-manual',
  templateUrl: './store-data-manual.component.html',
  styleUrls: ['./store-data-manual.component.css']
})
export class StoreDataManualComponent implements OnInit {

  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private dialog: MatDialog,
  ) { }

  validWidgets = null;

  ngOnInit(): void {
    this.getWidgets();
  }


  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  uploadCSV(){
    const dialogref = this.dialog.open(
      UploadCsvDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "750px",
        height : "600px",
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if (result.decision) {
        this.openSuccessDialog(
          result.response['body'],
          "OK"
        );
      }
    })
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
  }

}
