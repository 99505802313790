import { Injectable, NgModule, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "labelPipe",
})
@Injectable()
export class LabelPipe implements PipeTransform {
  transform(items: any[], param: string, itemName: string, filterMetadata): any {
    param = param?.toLowerCase();
    if (!items || !param) {
      return items;
    }
    if (items) {
      return items.filter((item, index) => {
        const find = item[itemName].toLowerCase().indexOf(param);
        return find === -1 ? false : true;
      });
    }
  }
}
