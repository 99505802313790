import { DatePipe, getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { PartnershipSendOfferDialogboxComponent, SuccessDialogComponent, InvitePartnerDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: "app-my-partners",
  templateUrl: "./my-partners.component.html",
  styleUrls: ["./my-partners.component.css"],
})
export class MyPartnersComponent implements OnInit {
  revenueRange = "allTime";
  addedBy = "addedByMe";
  partnerList = [];
  startDate;
  endDate;
  timePeriod;
  totalRevenue;
  totalProductSold;
  revCurrency;
  currencySymbol;
  pageNumber = 1;
  pageSize = 10;
  searchString = "";
  timeout;
  filter = null;
  length = 0;
  statsLoading = true;
  tableLoading = true;
  tableHeadings = [
    "Partner",
    "Partner Since",
    "Revenue Generated",
    "Revenue Cut Given",
    "Partner Type",
    "Action",
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private datePipe: DatePipe,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    partnershipRequestId: null,
    startDate: this.datePipe.transform(
      new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString(),
      "yyyy-MM-dd"
    ),
    endDate: this.datePipe.transform(
      new Date(Date.now()).toISOString(),
      "yyyy-MM-dd"
    ),
    dataPointType: "WEEK",
  };

  ngOnInit() {
    this.getMerchantData();
    this.getCurrencySymbol();
  }

  rerouteToAgreement() {
    this.router.navigate(["/app/partnership"]);
  }

  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }

  getStats() {
    this.apiCall.getMyAnalyticsGraphStats(this.data).subscribe(
      (response) => {
        let data = response["body"];
        this.totalRevenue =
          Math.round(data.totalRevenuegenerated * 100.0) / 100.0;
        this.totalProductSold = data.totalProductsSold;
        this.statsLoading = false;
      },
      (error) => {
        this.totalRevenue = 0;
        this.totalProductSold = 0;
      }
    );
  }

  //To check if the merchant has agreed to the partnership terms and conditions
  getMerchantData() {
    this.apiCall.getMerchantProfileData().subscribe((response) => {
      if (response["body"]["partnershipConsent"] == true) {
        this.getPartnerList();
        this.getStats();
      } else {
        this.rerouteToAgreement();
      }
    });
  }

  rerouteToOffers(id) {
    this.router.navigate([
      "/app/partnership/my-offers/new-offer",
      {
        id: id,
        type: name,
        send: true,
        sendOfferAgain: false,
      },
    ]);
  }

  getOffersList(id, name) {
    this.apiCall.getPOffers().subscribe((response) => {
      if (JSON.parse(response["body"]).length == 0) {
        this.rerouteToOffers(id);
      } else {
        this.sendOffer(id, name);
      }
    });
  }

  sendOffer(id, name) {
    let dialogRef = this.dialog.open(PartnershipSendOfferDialogboxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
      data: {
        id: id,
        name: name,
        sendOfferAgain: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSuccessDialog();
      }
    });
  }

  openSuccessDialog() {
    const dialogRef2 = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "25%",
      data: {
        message: this._i18nDynamicTranslate.transform("Offer sent succesfully", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Go to my partners page", ['POS_PAGE']),
      },
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if (result) {
        this.reroutetoMyPartners();
        this.pageNumber = 1;
        this.getPartnerList();
      } else {
        dialogRef2.close();
      }
    });
  }

  reroutetoMyPartners() {
    this.router.navigate(["/app/partnership/my-partners"]);
  }

  offerNotShared(id, store) {
    this.router.navigate([
      "/app/partnership/my-partners/" + store,
      {
        id: id,
        action: "offer-not-shared",
      },
    ]);
  }

  invitePartner() {
    const dialogRef = this.dialog.open(InvitePartnerDialogComponent, {
      panelClass: "custom-dialog-container",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  addNewPartner() {
    this.router.navigate(["/app/partnership/add-partner"]);
  }

  getPartnerList() {
    this.tableLoading = true;
    this.apiCall
      .getPartnerList(
        this.pageNumber,
        this.pageSize,
        this.searchString,
        this.filter
      )
      .subscribe((response) => {
        this.partnerList = response["body"]["list"];
        this.length = response["body"]["count"];
        this.partnerList.forEach((partner) => {
          partner["link"] = "https://" + partner["shopUrl"];
        });
        this.tableLoading = false;
      });
  }

  refreshPaginatedData(event) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getPartnerList();
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        this.pageNumber = 1;
        $this.getPartnerList();
      }
    }, 600);
  }

  rerouteToPartnerDetails(store, id, action, initiatePartnership, comId, type) {
    this.router.navigate([
      "/app/partnership/my-partners/" + store,
      {
        id: id,
        action: action,
        type: type,
        comId: comId,
        initiatePartnership: initiatePartnership,
      },
    ]);
  }

  action(id, action, isSource, url, store, comId) {
    if (
      isSource
    ) {
      this.rerouteToPartnerDetails(
        store,
        id,
        action,
        null,
        comId,
        "source"
      );
    } else {
      this.rerouteToPartnerDetails(store, id, action, null, comId, "target");
    }
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
