import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-offer-create-chat-gpt-dialog-box',
  templateUrl: './offer-create-chat-gpt-dialog-box.component.html',
  styleUrls: ['./offer-create-chat-gpt-dialog-box.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OfferCreateChatGptDialogBoxComponent implements OnInit {

  constructor(
    private dailogRef: MatDialogRef<OfferCreateChatGptDialogBoxComponent>, private apiCallService: ApiCallService
  ) { }
  questionError = "";
  loadingSample = false;
  searchValue = "";
  isSearch = false;
  showDropdown = false;
  selectedTagSegment = [];
  selectedTagCollection = [];
  selectedTagProduct = [];
  type = "ALL";
  allTags :any;
  @ViewChild('queryValue') queryValue: ElementRef;
  ngOnInit(): void {
  }
  testfun(event) {
    console.log(event);
    let len = this.queryValue.nativeElement.innerText.length;
    console.log("1",this.queryValue.nativeElement.innerText.indexOf('@'))
    if (event.key === '@') {
      this.isSearch = !this.isSearch;
      this.showDropdown = !this.showDropdown; 
      this.searchValue = "";
      this.getProductandSegment();
    }
    if(this.isSearch === true && event.key !== '@'){  
      this.searchValue = this.searchValue + event.key;
      console.log("SEARCH", this.searchValue);
      this.getProductandSegment();
    }
  }
  testkeyDown(event){
    console.log(event)
    if(event.key==='Backspace' && this.searchValue.length === 0){
      this.isSearch = false;
      this.showDropdown = false;
      this.searchValue = "";
    }
    else if(event.key==='Backspace' && this.searchValue.length > 0){
      this.searchValue = this.searchValue.substring(0,this.searchValue.length -1)
      this.getProductandSegment();
    }
  }
  setCaretPosition(ctrl, pos) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    
    // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }
  generate(){
    console.log(this.queryValue.nativeElement.innerText.replace('@', ''));
    this.getMetaData(this.queryValue.nativeElement.innerText);
    let data :any = {}
    data.query = this.queryValue.nativeElement.innerText;
    let metadata :any={}
    if(this.selectedTagCollection.length>0){
      metadata.COLLECTION = this.selectedTagCollection;
    }
    else if(this.selectedTagProduct.length>0){
      metadata.PRODUCT = this.selectedTagProduct;
    }
    else{
      metadata.ALL = [];
    }
    metadata.SEGMENT = this.selectedTagSegment;
    data.metadata = metadata;
    console.log("metatt",data)
    this.loadingSample = true;
    this.apiCallService.getChatGptOffer(data).subscribe((response)=>{
      console.log(response)
      this.dailogRef.close(response['body']);
      this.loadingSample = false
    },(err)=>{
      this.questionError = err['error']['body']
    })
  }
  close(){
    this.dailogRef.close(false);
  }
  selectTag(tag, type){
    this.type = type;
    if(type === 'COLLECTION')
      this.selectedTagCollection.push(tag);
    if(type === 'SEGMENT')
      this.selectedTagSegment.push(tag);
    if(type === 'PRODUCT')
      this.selectedTagProduct.push(tag);
    let l = this.queryValue.nativeElement.innerText.indexOf('@')
    let len = this.queryValue.nativeElement.innerText.length;
    let temp = this.queryValue.nativeElement.innerText.substring(l,len)
    console.log(l,len,temp);
    this.queryValue.nativeElement.innerText = this.queryValue.nativeElement.innerText.replace(temp, '')
    let e = document.getElementById('test');

    let inputWraper = document.createElement('span');
    inputWraper.setAttribute('contenteditable', 'false');
    inputWraper.setAttribute('class', 'test-style');
    let wrapper = `${tag}`;
    //   inputWraper.addEventListener("click", (event) =>
    //   this.testClick(data)
    // );
    inputWraper.innerHTML = wrapper;
    // e.append(inputWraper);
    e.insertAdjacentElement(
      "beforeend",
      inputWraper
      );
     
    e.insertAdjacentText(
      "beforeend",
      temp.substring(1,temp.length)
    )
      
    console.log("2",this.queryValue.nativeElement.innerText[0])
    this.isSearch = false;
    this.showDropdown = false;
    this.searchValue = "";
      setTimeout(() => {
        this.setCursor(this.queryValue.nativeElement.innerText.length - tag.length);
      }, 500);
      this.queryValue.nativeElement.innerText.replace('@', '')
    }
  getProductandSegment(){
    this.apiCallService.getProductListAndSegmentList(this.searchValue,this.type).subscribe((response)=>{
      console.log("RESPONSE", response);
      this.allTags = response['body']
    })
  }
  setCursor(pos) {
    var tag = document.getElementById("test");
    tag.focus();
    window.getSelection().selectAllChildren(tag)
    window.getSelection().collapseToEnd()
  }
  async getMetaData(data){
    await this.check(data)
  }
  check(data){
    for(let k in this.selectedTagCollection){
      console.log(k)
     if(data.indexOf(this.selectedTagCollection[k])== -1){
      let index = this.selectedTagCollection.indexOf(this.selectedTagCollection[k]);
      this.selectedTagCollection.splice(index, 1);
     }
    } 
    for(let k in this.selectedTagProduct){
      console.log(k)
     if(data.indexOf(this.selectedTagProduct[k])== -1){
      let index = this.selectedTagProduct.indexOf(this.selectedTagProduct[k]);
      this.selectedTagProduct.splice(index, 1);
     }
    } 
    for(let k in this.selectedTagSegment){
      console.log(k)
     if(data.indexOf(this.selectedTagSegment[k])== -1){
      let index = this.selectedTagSegment.indexOf(this.selectedTagSegment[k]);
      this.selectedTagSegment.splice(index, 1);
     }
    } 
  }
}
