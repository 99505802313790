import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailNavComponent, EmailSampleListingComponent, EmailTemplateChooseComponent, StandardEmailTemplateComponent } from './pages';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { EmailRoutingModule } from './email-routing.moule';



@NgModule({
  declarations: [
    EmailNavComponent,
    EmailTemplateChooseComponent,
    StandardEmailTemplateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EmailRoutingModule
  ]
})
export class EmailModule { }
