import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReviewBaseComponent } from './pages/review-base/review-base.component';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';

const routes: Routes = [
{ 
  path: "", 
  component: ReviewBaseComponent, 
  data: {
    titleheading: "Reviews",
    title: "Reviews",
    minPath: "Basic"
  },
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewsRoutingModule { }
