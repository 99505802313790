import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IntervalHistogram } from 'perf_hooks';

@Component({
  selector: 'app-set-up-campaign',
  templateUrl: './set-up-campaign.component.html',
  styleUrls: ['./set-up-campaign.component.css']
})
export class SetUpCampaignComponent implements OnInit {

  stepsCompleted: number = 1;
  StepsDone=1;
  loading: boolean = false;
  isContinueBtnLoading = false;
  isSaveBtnLoading = false;
  isDiscardBtnLoading = false;
  selected = true;
  campaignName = "";
  ConversionEventList =[{
    "name":"Test1",
    "id":11
  },
  {
    "name":"Test2",
    "id":12
  },
  {
    "name":"Test3",
    "id": 13
  }
];
whichUserList =[{
  "name":"user1",
  "id":11
},
{
  "name":"user2",
  "id":12
},
{
  "name":"user3",
  "id": 13
}
];
ConversionEvnetType ={
  "name":"Test3",
    "id": 13
};
whichUserEvent ={
  "name":"user1",
  "id": 11
}
deadLine: any = {
    days: 10,
    hours: 10,
    minutes: 2,
    seconds : 10
};
isFilter = false;
isConversionEvnetClicked = false;
conversionEvent: FormGroup;
  constructor(
    private _router: Router,
  ) { }
  @ViewChild("ConversionEvent") ConversionEvent: any;
  @ViewChild("whichUser") whichUser:any;

  // conversionEventMap:Map<number,String>;
  conversionEventMap: any={
    1:"Primary",
    2:"Secondary",
    3:"Tertary",
    4:"Quartery"
  };
  // conversionEventMap.get(conversionEvent.length)
  ngOnInit(): void {
    this.initForm();
    this.initConversionMap();
  }

  initConversionMap(){
  }
  initForm(){
    this.conversionEvent = new FormGroup({
      eventName: new FormControl("", [
        Validators.required,
        // Validators.maxLength(50),
        // Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
      ]),

          ConversionEventArray: new FormArray([
            new FormGroup({
              ConversionEvent: new FormControl(
              this.ConversionEvnetType,
                [Validators.required]
              ),
              whichUser: new FormControl(this.whichUserEvent),
              addfilter: new FormControl(
                this.isFilter,
                [Validators.required]
              ),
              deadLine: new FormGroup({
                days: new FormControl(this.deadLine.days),
                hours: new FormControl(this.deadLine.hours),
                minutes: new FormControl(this.deadLine.minutes),
                seconds: new FormControl(this.deadLine.seconds)
              }),
            }),
          ]),
    });
  }
  changeSection(section){
    this.stepsCompleted=section;
    this.routeUpdate(this.stepsCompleted,0) 
  }
  routeUpdate(value, section?){
    this._router.navigate(["/app/campaign/create-campaign"]);
    if((this.StepsDone<value) && (section !=0) ){
      this.StepsDone=this.StepsDone+1;
    }
  }
  previousButtonClicked() {
    this.stepsCompleted > 0 ? this.stepsCompleted-- : null;
    this.routeUpdate(this.stepsCompleted);
  }
  discardGameSetup(){

  }
  continueButtonClicked(action) {
    switch (this.stepsCompleted) {
      case 1:
        // this.step1Completed(action);
        break;
      case 2:
        if (action == 0) {
          // this.step2Completed(action);
        } else {
          this.isContinueBtnLoading = false;
          this.selected = false;
        }
        break;
      case 3:
        // if (
        //   this.gameSteps.productsPerStep <= 5 &&
        //   this.gameSteps.productsPerStep >= 2 &&
        //   this.gameSteps.steps <= 5 &&
        //   this.gameSteps.steps >= 2
        // )
          // this.step3Completed(action);
        // else {
        //   this.isContinueBtnLoading = false;
        //   this.selected = false;
        // }
        break;
      case 4:
        if (action == 0) {
          // this.step4Completed(action);
        } else {
          this.isContinueBtnLoading = false;
          this.selected = false;
        }
        break;
      case 5:
        if (
          (
          action == 0
        )) {
          // this.step5Completed(action);
        } else {
          this.isContinueBtnLoading = false;
          this.selected = false;
        }
        break;
    }
    document
            .getElementById("panelList")
            .scrollIntoView({ behavior: "smooth", block: "start" });
  }
  addTag(){

  }
  addConversionEvent(){
    if(this.conversionEvent.controls.ConversionEventArray.value.length ==1 && !this.isConversionEvnetClicked){
      this.isConversionEvnetClicked = true;
    }
    else{
      const control = new FormGroup({
        ConversionEvent: new FormControl(
        this.ConversionEvnetType,
          [Validators.required]
        ),
        whichUser: new FormControl(this.whichUserEvent),
        addfilter: new FormControl(
          this.isFilter,
          [Validators.required]
        ),
        deadLine: new FormGroup({
          days: new FormControl(this.deadLine.days),
          hours: new FormControl(this.deadLine.hours),
          minutes: new FormControl(this.deadLine.minutes),
          seconds: new FormControl(this.deadLine.seconds)
        }),
      });
      (<FormArray>(
        this.conversionEvent
          .get("ConversionEventArray")
      )).push(control);
    }

  }
  changeConversionSelection(){

  }
  selectopenclose(value) {
    switch (value) {
      case "whichUser":
        this.whichUser.open();
        break;
      case "ConversionEvent":
        this.ConversionEvent.open();
        break;
    }
  }
  filter(event){
    this.isFilter = event.checked;
  }
}
