import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/dialog-box';

@Component({
  selector: 'app-wallet-report',
  templateUrl: './wallet-report.component.html',
  styleUrls: ['./wallet-report.component.css']
})
export class WalletReportComponent implements OnInit {

  constructor(
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private date_format: getdateformatService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private tokenStorage: TokenStorageService,
    private _WidgetConfigService:WidgetConfigService
  ) { }

  selectedReport = 'ADJUSTMENT_REPORT_WALLET';
  selectedTransaction = "ALL";
  selectedStore = "";

  typeOfReports = [
    {
      "name": "Adjustment Wallet Report",
      "value": "ADJUSTMENT_REPORT_WALLET"
  },
  ]
  typeOfTransaction = [
    {
        "type": 'ALL',
        "name": "All"
    },
    {
        "type": 'CREDIT',
        "name": "Credit"
    },
    {
        "type": 'DEBIT',
        "name": "Debit"
    }
  ];
  typeOfStores = [];
  storeName = "";
  qid;
  range = new FormGroup({
    start: new FormControl(this.getOneMonthAgo()),
    end: new FormControl(new Date()),
  });

  ngOnInit(): void {
    this.qid = this.tokenStorage.getqId();
    if(this.qid === 'null'){
      this.selectedStore = "ALL"
      this.storeName = this.tokenStorage.getShopName();
      return;
    }
    this.selectedStore = "THIS_STORE";
    this.storeName = this.tokenStorage.getStoreName();
  }

  getOneMonthAgo(): Date {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  }

  getStores(){
    this.apiCall.getStoreData().subscribe((response)=>{
      this.typeOfStores = response['body'];
      // if(this.storeList.length != 0)
      //   this.storeAvailability = true;
      // else
      //   this.storeAvailability = false;
    },(error)=>{
        
    })
  }

  onEndDateChange(event){

  }
  
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  getMaxDate(){
    var currentDate = new Date();
    let startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    return startDateMinValue;
  }

  downloadWalletReport(){
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: "Export Report",
        message:
          "Please select the format in which you want the report",
        segmentName: this.selectedReport,
        downloadButton_1: "Excel",
        downloadButton_2: "CSV",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let prefix = "";
        if(result.decision === 'EXCEL'){
          prefix = ".xlsx"
        }
        else{
          prefix = ".csv"
        }
       let filePath = "output/"+result.fileName + prefix;
      //  let storeName = window.sessionStorage.getItem("storeName");
      this.downloadData(filePath, result.decision);
      }
    });
  }

  downloadData(filePath, fileType){
    this.snackBar.openSnackBar('Report download will start in a few minutes.', 2000);
    let data = {
      featureName: "WALLET",
      type : fileType,
      filePath :  filePath,
      reportType : this.selectedReport,
      transactionType : this.selectedTransaction,
      filteredUsers : this.selectedStore !== 'ALL' ? [this.tokenStorage.getUserId()] : []
    }
    if(this.range?.value?.start){
      data['fromDate'] = this.datepipe.transform(this.range?.value?.start, "yyyy-MM-dd");
    }
    if(this.range?.value?.end){
      data['toDate'] = this.datepipe.transform(this.range?.value?.end, "yyyy-MM-dd");
    }
    this.getReportsApi(data).then((result) => {
      console.log(result);
      window.open(result['body']);
     
    },(err)=>{
      this.snackBar.openSnackBar(err.body, 2000);
    });
  }

  getDateTime(dates, type) {
    let time;
      let date = this.datepipe.transform(
        new Date(dates.replace(' ','T')).toISOString(),
        "dd MMM yyyy"
      );
      let x = dates.split(" ");
      let y = x[1].split(":");
      if (x[1][0] + x[1][1] > 12)
        time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      else 
        time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      if(type === 'date'){
        return date;
      }
      else{
        return time;
      }
      // this.searchArray[item]["strategy"]["date"] = date;
      // this.searchArray[item]["strategy"]["time"] = time;
  }

  getReportsApi(data){
    return new Promise((resolve, reject) => {
      this.apiCall.getReportSampleData(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

}
