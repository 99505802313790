import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { SmsScheduleDialogComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-sms-template-listing",
  templateUrl: "./sms-template-listing.component.html",
  styleUrls: ["./sms-template-listing.component.css"],
})
export class SmsTemplateListingComponent implements OnInit {
  s3BucketUrl = environment.s3BucketUrl;
  filter = {
    label: "",
  };
  tabSelected = "all";
  smsLayouts = [];
  imageUrl =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/sms-screen-assets/15-d-4903-ffd-54-f-3-ad-76007-ffae-8722-fc-5%403x.png";
  selectedListType = "Sms";
  selectedLayout;
  allLayout = [];
  presetLayout = [];
  customLayout = [];
  customerSegments = [];
  IsEnvProd = true;
  appSubscriptionPlan;
  validWidgets;
  isSMSEditAllowed=false;
  smsCount;
  countryCode="";
  isStandard=true;
  templateList;
  customGenerictemplateList = [];
  customRewardtemplateList = [];
  standardGenerictemplateList = [];
  standardRewardtemplateList = [];
  customConsenttemplateList=[];
  standardConsenttemplateList=[];
 
  rewardTemplateList;
  mobileMatch = window.matchMedia("(max-width: 896px)");

  segmentType = 'all';
  mobileMedia = window.matchMedia("(max-width: 896px)");
  snapshotCounter = 0;
  snapshotInterval;
  loading = true;
  filterSelected;


//aashi
  @ViewChild('search') searchElement: ElementRef;
  @ViewChild("mainContainer") mainContainer: ElementRef;
  @ViewChild("mainHeader") mainHeader: ElementRef;
  constructor(
    private dialog: MatDialog,
    private _ApiCallService: ApiCallService,
    private router: Router,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private _ActivatedRoute: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    this.IsEnvProd = environment.external === "prod" ? true : false;

    this.checkSMSEditAllowed();
    this.getTemplates();
    // this.getCustomerSegments();
    this.getWidgets();
    this.getMerchantDetails();
    this.filterSelected = "All";
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getCustomerSegments() {
    this._ApiCallService.getAllCustomerSegments().subscribe(
      (response) => {
        let body = JSON.parse(response["body"]);
        for (let i = 0; i < body.length; i++) {
          if (body[i].isActive === true) {
            this.customerSegments.push(body[i]);
          }
        }
      },
      (err) => {}
    );
  }
  showCustomtempalteListFilter = false;
  showStandardtempalteListFilter = false;
  showFilters = true;
  getTemplates(list?) {
    this.allLayout = [];
    this.presetLayout = [];
    this.customerSegments = [];
    this.tabSelected = "preset";


     
    let layoutAPI= this._ApiCallService.getSmsTemplates("SMS", "SMS").subscribe((response) => {
      this.smsLayouts = response["body"];
      this.smsLayouts.map((layout) => {
        this.allLayout.push(layout);
        if (layout["layoutType"] === "CUSTOM") {
          this.customLayout.push(layout);
        } else {
          this.presetLayout.push(layout);
        }});
        this.smsLayouts.sort((a,b)=> {
          const dateA :Date = new Date(a.lastModifiedTime);
          const dateB :Date = new Date(b.lastModifiedTime);
          return dateB.getTime() - dateA.getTime();;
      });
        this.smsLayouts.forEach((layout) => {
        switch (layout["subLayout"]) {
          case "GENERIC":
            if (layout["layoutType"] != "STANDARD") {
              this.customGenerictemplateList.push({ ...layout, apiToCallForDetails: 'layout', isDeletable: true });
            } else {
              this.standardGenerictemplateList.push({ ...layout, apiToCallForDetails: 'layout', isDeletable: false });
            }
            break;
          case "REWARD":
            if (layout["layoutType"] != "STANDARD") {
              this.customRewardtemplateList.push({ ...layout, apiToCallForDetails: 'reward', isDeletable: true });
            } else {
              this.standardRewardtemplateList.push({ ...layout, apiToCallForDetails: 'reward', isDeletable: false });
            }
            break;
          case "CONSENT":
            if (layout["layoutType"] != "STANDARD") {
              this.customConsenttemplateList.push({ ...layout, apiToCallForDetails: 'consent', isDeletable: true });
            } else {
              this.standardConsenttemplateList.push({ ...layout, apiToCallForDetails: 'consent', isDeletable: false });
            }
            break;
          }
        })
       
      });

      this._ActivatedRoute.params.subscribe((params) => {
        if (params.id) {
          this.selectedLayout = this.allLayout[this.allLayout.findIndex(
            (x) => x.id == params.id
          )];
          let interval = setInterval(()=>{
            let element = document.getElementById(params.id);
            if(element){
              clearInterval(interval);
              element.scrollIntoView({behavior: "smooth", block:"nearest" })
            }
          })
        } else {
          this.selectedLayout = this.allLayout[0];
        }
    
    });
    let interval = setInterval(() => {
      if(layoutAPI.closed){
        if(this.customRewardtemplateList.length>0) {
          list = list?list:'custom';
        } else {
          list = list?list:'preset';
        }
        if(this.standardGenerictemplateList.length > 0 && this.standardRewardtemplateList.length>0) {
          this.showStandardtempalteListFilter = true;
        }
        if(this.customGenerictemplateList.length > 0 || this.customRewardtemplateList.length>0) {
          this.showCustomtempalteListFilter = true;
        }
       
        this.loading = false;
        this.selectList(list, null);
        clearInterval(interval);
  }})

  }

  showSearch(){
    this.searchElement.nativeElement.focus();
  }

  searchsms() {
    this.smsCount = 0;
    this.smsLayouts.forEach(element => {
      if(element.name.toLowerCase().includes(this.filter.label)){
      this.smsCount++;
      }
    })
  }

  async createTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/sms/layout-editor",
        { id: null, return: "list" },
      ]);
    }
  }


  async createsmsconsent(){
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/sms/sms-consent",
      ]);
    }
  }

  refreshScreen(id) {
    this.getTemplates();
    let interval = setInterval(() => {
      let element = document.getElementById(id);
      if (element) {
        this.tabSelected="custom";
        this.selectedLayout =
          this.smsLayouts[this.smsLayouts.findIndex((x) => x.id == id)];
        //element.scrollIntoView({ behavior: "smooth", block: "center" });
        clearInterval(interval);
      }
    });
  }

  editLayout() {
    this.router.navigate([
      "/app/sms/layout-editor",
      { id: this.selectedLayout.id, clone: false, return: "list" },
    ]);
  }

  selectLayoutView() {
    this.router.navigate(["/app/sms/choose-layout"]);
  }

  cloneLayout(layout, event) {
    this.clickEvent(event);
    event.stopPropagation();
    this.router.navigate(["/app/sms/layout-editor",{id:layout.id, clone: true,type:layout.type, sublayout:layout.sublayout,return:'choose-layout'}]);
  }
  clickEvent(event) {
    let elements = document.getElementsByClassName("qb-more-options");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("style", "display:none");
    }
  }
  // selectList(type) {
  //   this.tabSelected = type;
  //   switch (type) {
  //     // case "all":
  //     //   this.selectedListType = "All Sms";
  //     //   this.smsLayouts = this.allLayout;
  //     //   break;
  //     case "preset":
  //       this.selectedListType = "Preset Sms";
  //       this.smsLayouts = this.presetLayout;
  //       break;
  //     case "custom":
  //       this.selectedListType = "Custom Sms";
  //       this.smsLayouts = this.customLayout;
  //       break;
  //   }
  //   this.selectedLayout = this.smsLayouts[0];
  // }

 
  selectList(type, filter,selectFirst?) {
    this.loading = true;
    this.filterSelected = filter ? filter : "All";
    this.segmentType = type;
    this.tabSelected = type;
    this.isStandard = this.tabSelected == "preset"?true:false
    // this.filterSelected = filter && this.filterSelected == filter? null:filter;
    switch (type) {
      case "preset":
        if(this.filterSelected == 'general') {
          this.filterSelected = 'general';
          this.smsLayouts = this.standardGenerictemplateList;
        } else if(this.filterSelected == 'reward') {
          this.filterSelected = 'reward';
          this.smsLayouts = this.standardRewardtemplateList;
        } else if(this.filterSelected == 'consent') {
          this.filterSelected = 'consent';
          this.smsLayouts = this.standardConsenttemplateList;
        } 
        else {
          this.smsLayouts = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
        }
        break;
      case "custom":
        if(this.filterSelected == 'general') {
          this.filterSelected = 'general';
          this.smsLayouts = this.customGenerictemplateList;
        } else if(this.filterSelected == 'reward') {
          this.filterSelected = 'reward';
          this.smsLayouts = this.customRewardtemplateList;
        } else if(this.filterSelected == 'consent') {
          this.filterSelected = 'consent';
          this.smsLayouts = this.customConsenttemplateList;
        } 
        else {
          this.smsLayouts = this.customGenerictemplateList.concat(this.customRewardtemplateList);
        }
        break;
       
    } 
    this.selectedLayout = this.smsLayouts[0];
    if((this.tabSelected == 'preset' && this.showStandardtempalteListFilter) || (this.tabSelected == 'custom' && this.showCustomtempalteListFilter)) {
      this.showFilters = true;
    } else {
      this.showFilters = false;
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }



  viewTemplateDetail(layout) {
    this.selectedLayout = layout;
    this.router.navigate(['app/sms/list',{id:layout.id}])
  }

  sendNow(layoutId) {
    let dialogRef = this.dialog.open(SmsScheduleDialogComponent, {
      panelClass: "schedule-sms-dialog-container",
      data: {
        customerSegments: this.customerSegments,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let data = {
          templateId: layoutId,
          scheduleType: "INSTANT",
          scheduleName: "",
          channel: "SMS",
          delay: "0:0:0",
          segmentIds: result[0] === "ALL" ? null : result,
          scheduleId: "",
          status: "ACTIVE",
        };
        this._ApiCallService.scheduleSMS(data).subscribe((response) => {
          this.openSuccessDialog(this._i18nDynamicTranslate.transform("Your SMS has been successfully sent", ['POS_PAGE']), this._i18nDynamicTranslate.transform("OK", ['POS_PAGE']));
        });
      }
    });
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  checkSMSEditAllowed(){
    this._ApiCallService.checkSMSEditAllowed().subscribe((response) => {
      this.isSMSEditAllowed = response["body"];
    })
  }
  getMerchantDetails(){
    this._ApiCallService.getMerchantDetails().subscribe((response) => {
      let data = JSON.parse(response["body"]);
      this.countryCode = data.country;      // this.countryCode='IN'
    });
  }

  
}
