import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, DialogPosition } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";

import { NameAndDescriptionComponent } from "src/app/shared/components/dialog-box";
import { DeleteGameDialogBoxComponent } from "src/app/shared/components/dialog-box/delete-game-dialog-box/delete-game-dialog-box.component";
import { GamifyListingFilterComponent } from "./gamify-listing-filter/gamify-listing-filter.component";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
//import { Router } from "@angular/router";

@Component({
  selector: "app-gamification",
  templateUrl: "./gamification.component.html",
  styleUrls: ["./gamification.component.css"],
})
export class GamificationComponent implements OnInit {
  constructor(
    private _router: Router,
    private _apicall: ApiCallService,
    private _dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackbar: SnackbarCollection,
    private tokenStorage: TokenStorageService,
    private date_format: getdateformatService
  ) {}
  @ViewChild("paginator") paginator: MatPaginator;
  isNodataDetected = true;
  isGetgameListloading = false;
  totalGameCount = 0;
  rightMargin = 0;
  loading = false;
  filterSearch = "";
  timeout;
  mobileView;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  gameList = [];
  selectedTab = null;
  sort = ["DATE"];
  order = ["DESC"];
  statusDraft: boolean = false;
  statusSent: boolean = false;
  statusScheduled: boolean = false;
  page = 1;
  size = 10;
  status = ["LIVE", "SCHEDULED", "ENDED", "PAUSED", "DRAFT"];
  channel = [];
  index = null;
  flag = null;
  dataNotAvailable = false;
  ngOnInit() {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }

    this.route.queryParams.subscribe((params) => {
      if (params.type) this.selectedTab = params.type;
    });
    this.getGameList();
  }
  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.getGameList();
    }, 600);
  }
  createNewGame() {
    const dialogRef = this._dialog.open(NameAndDescriptionComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "30%",
      data: {
        title: "GAME DETAILS",
        field1Name: "Game Name",
        field2Name: "Game Description (Optional)",
        cancelButtonName: "Cancel",
        submitButtonName: "Continue",
        action: "Gamify",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(["/app/gamify/create-game"], {
          queryParams: { id: result, section: 1 },
        });
      }
    });
  }

  getGameList() {
    this.loading = true;
    this._apicall
      .getGameList(
        this.filterSearch,
        this.page,
        this.size,
        this.sort,
        this.order,
        this.status
      )
      .subscribe((response) => {
        this.gameList = response["body"]["elements"];
        this.totalGameCount = response["body"]["count"];
        if (
          this.totalGameCount == 0 &&
          this.filterSearch.length === 0 &&
          this.status.length === 5
        ) {
          this._router.navigate(["/app/gamify/how-to-create-a-game"]);
          this.dataNotAvailable = false;
        } else if (
          this.totalGameCount == 0 &&
          this.filterSearch.length !== 0 &&
          this.status.length !== 5
        ) {
          this.dataNotAvailable = true;
        } else {
          this.dataNotAvailable = false;
        }
        this.loading = false;
        // if (this.isNodataDetected) {
        //   this._router.navigate(["/app/gamify/how-to-create-a-game"]);
        // } else {
        // }
      });
  }

  getStatus(game) {
    if (game["isScheduled"] == true && game["isPaused"]) {
      return "Live";
    } else if (game["isPaused"]) {
      return "Paused";
    } else {
      return "Ended";
    }
  }

  getNextData(pageEvent) {
    this.page = pageEvent.pageIndex + 1;
    this.size = pageEvent.pageSize;

    this.getGameList();
  }
  getDateListTitleText(status) {
    let text = "";
    switch (status) {
      case "DRAFT":
        text = "Last Edited On";
        break;

      case "LIVE":
        text = "Enabled On";
        break;

      case "SCHEDULED":
        text = "Scheduled On";
        break;
      case "ENDED":
        text = "Ended On";
        break;
      default:
        text = "Last Edited On";
    }
    return text;
  }

  setAction(action, game, index) {
    if (this.index != null) {
      return;
    }
    this.toggle(index, action, true);
    switch (action) {
      case "edit":
        this._router.navigate(["/app/gamify/create-game"], {
          queryParams: { id: game.id, section: 1 },
        });
        break;
      case "delete":
        this.deleteGame(game.id, game.title);
        break;
      case "pause":
        {
          let data = {
            isPaused: true,
          };
          this.updateGame(game.id, data, index, "PAUSED");
        }
        break;
      case "resume":
        {
          let data = {
            isPaused: false,
          };
          this.updateGame(game.id, data, index, "LIVE");
        }
        break;
      default:
        break;
    }
  }
  toggle(i, flag, set): void {
    if (set) {
      this.index = i;
      this.flag = flag;
    } else {
      this.index = null;
      this.flag = null;
    }
    // if (this.index == i && this.flag) {
    //   this.index = null;
    // } else {
    //   this.flag = true;
    //   this.index = i;
    // }
  }
  deleteGame(id, name) {
    const dialogRef = this._dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: this.mobileMedia.matches ? "90%" : "540px",
      height:this.mobileMedia.matches ? "" : "250px",
      data: {
        subject: "Delete Game",
        // "data-widget": "GAME LISTING",
        // "data-button": "Confirm: Delete",
        "data-item": id,
        "game-name": name,
        successButtonText: "Delete",
        cancelButtonText: "Cancel",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._apicall.deleteGameData(id).subscribe(
          (response) => {
            this.getGameList();
            this.toggle(null, null, false);
          },
          (err) => {
            this._snackbar.openSnackBar("Error in deleting game", 1500);
            this.toggle(null, null, false);
          }
        );
      } else {
        this.toggle(null, null, false);
      }
    });
  }
  updateGame(id, data, index, toStatus) {
    this._apicall.updateGame(data, id).subscribe(
      (response) => {
        this.gameList[index].status = toStatus;
        this.gameList[index].lastUpdatedAt = response['body']['qbObject']['lastUpdatedAt']
        this.toggle(null, null, false);
      },
      (err) => {
        this._snackbar.openSnackBar("Error in updating game", 1500);
        this.toggle(null, null, false);
      }
    );
  }

  openFilterDialog(): void {
    const dialogPosition: DialogPosition = {
      top: "0px",
      right: "0px",
    };

    const dialogRef = this._dialog.open(GamifyListingFilterComponent, {
      width: "230px",
      height: "100%",
      position: dialogPosition,
      panelClass: "custom-dialog-container",
      data: {
        status: this.status,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.status = response.status;

        this.getGameList();
      }
    });
  }
  changeGame(gameid, gameName){
    this._router.navigate(["/app/gamify/"+gameName], {
      queryParams: { id: gameid},
    });
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
