import { DatePipe } from "@angular/common";
import {
  Component,
  ErrorHandler,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog"; 
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ContactUsDialogComponent, OfferSummaryDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-offer-dispatch-direct",
  templateUrl: "./offer-dispatch-direct.component.html",
  styleUrls: ["./offer-dispatch-direct.component.css"],
})
export class OfferDispatchDirectComponent implements OnInit {
  message: string = "Hola Mundow!";

  @Output() messageEvent2 = new EventEmitter<any>();
  @Input() offerId: number;
  @ViewChild("scheduleOpen") scheduleOpen: any;
  @ViewChild("segmentOpen") segmentOpen: any;

  // @Input() offerReqBodyObj: any;
  @Input() channel: any;
  sendcancelMessage(message) {
    this.messageEvent2.emit(message);
  }
  // tempsegmentFilter=""
  barrierReward;
  selectedChannel = "EMAIL";
  modifyDivOpened = true;
  channelConfigureStatus = false;
  additionallayoutSet = [];
  templateList = [];
  offerdataFetched;
  selectedbarrier;
  offerType;
  selectedEmailTemplateId;
  selectedEmailTemplateName;
  channelSectionOpen = false;
  data = {
    process: "create",
    id: "null",
    tags: [],
    imageTagStatus: true,
    offerId: null,
    saveName: null,
  };
  apicount = 5;
  filter = {
    label: "",
  };

  allcustomerObj = [{ id: "ALL_CUSTOMERS", name: "My Customers" }];

  emailtemplateList;
  layouts;
  templateDispatchForm;
  disableCustomerSegmentSelection;
  disableAllCustomerSelection;
  selectedSegments;
  segments = [];
  todayDate;
  finalData;
  templateId;
  templatesnapshotUrl;
  emailLayoutName;
  emailLayoutId;
  recAction: boolean = false;
  recomentedSegments;

  htmlrend;
  subject;
  htmlloading: boolean = false;
  layoutSnapshotUrl = null;

  submitted: boolean = false;
  apiCount = 0;
  loading;

  fullLayoutList = [];
  emailId;

  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;

  masterOffer;

  OfferName;
  saveSurevyClicked: boolean = false;

  emailLayoutSaved: boolean = false;
  emailTemplateSnapshotUrl;

  displayParameter;

  radioStatus = 0;

  selectedTemplateData;
  layoutPlusTemplateList = [];

  newLayoutDetails = { id: null, layoutSnapshotUrl: null, layoutName: null };

  layoutStorage = [];

  interval;
  destroyed: boolean = false;
  rewardTemplateList = [];
  rawJson;
  preEmailTemplateName;
  preemailId;
  presmsLayoutId;
  presmsTemplateId;
  presmsTemplateName;
  preemailLayoutId = null;
  preemailLayoutTemplateId;
  preemailLayoutName;

  prerewardId;

  mainRewardtemplate;
  barrierofZero;
  rewardofZero;
  rewardTypeAndName = {
    BUY_X_GET_Y: "Buy X Get Y Reward Email",
    PERCENTAGE_OFF: "Percentage Off Reward Email",
    FIXED_AMOUNT_OFF: "Fixed Amount Off Reward Email",
    FREE_SHIPPING: "Free Shipping Reward Email",
  };
  startDateMinValue;
  showSMSWarning;
  currentUTC;
  staticUTCWindow;
  currentTimeMin;
  currentDateTime;
  validScreens = null;
  customerSegmentList = [];
  customerTierList = [];
  customerList =[];
  employeeList = [];
  serviceCountry = sessionStorage.getItem("serviceCountry");
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(
    private apiCall: ApiCallService,
    private errorHandle: ErrorHandler,
    private snackBar: SnackbarCollection,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private tokenStorage: TokenStorageService,
    public dialog: MatDialog,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {
    window["screen-name"] = "CHOOSE OFFER";
    this.getScreenSize();
  }

  ngOnDestroy() {
    this.modifyDivOpened = false;
  }
  ngOnInit() {
    this.getScreenId();
    var now = new Date();
    var utc_timestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );

    // console.log(now, utc_timestamp);
    this.selectedChannel = this.channel;

    var currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    var m = new Date().getUTCDate();
    const activationDate = new Date();
    this.currentUTC = new Date(
      activationDate.getUTCFullYear(),
      activationDate.getUTCMonth(),
      activationDate.getUTCDate(),
      activationDate.getUTCHours(),
      activationDate.getUTCMinutes(),
      activationDate.getUTCSeconds()
    );

    var s = new Date(this.currentUTC).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
    let staticTimeWindow0830 = new Date("01/01/2011 08:55 PM");
    this.staticUTCWindow = new Date(
      staticTimeWindow0830.getUTCFullYear(),
      staticTimeWindow0830.getUTCMonth(),
      staticTimeWindow0830.getUTCDate(),
      staticTimeWindow0830.getUTCHours(),
      staticTimeWindow0830.getUTCMinutes(),
      staticTimeWindow0830.getUTCSeconds()
    );
    var r = new Date(this.staticUTCWindow).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });

    let staticTimeWindow0900 = new Date("01/01/2011 10:00 AM");
    let static1000 = new Date(
      staticTimeWindow0900.getUTCFullYear(),
      staticTimeWindow0900.getUTCMonth(),
      staticTimeWindow0900.getUTCDate(),
      staticTimeWindow0900.getUTCHours(),
      staticTimeWindow0900.getUTCMinutes(),
      staticTimeWindow0900.getUTCSeconds()
    );
    var t = new Date(static1000).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
    if (
      this.dateCompare(r.split(",")[1], s.split(",")[1]) == 1 &&
      this.dateCompare(s.split(",")[1], t.split(",")[1]) == 1
    ) {
      this.showSMSWarning = 1;
    }

    this.showSMSWarning = this.dateCompare(r.split(",")[1], s.split(",")[1]);
    var currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    this.modifyDivOpened = true;
    this.currentDateTime = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      }));
    this.initSchedule();
  }
  async getScreenId(){
    this.validScreens =  await this._WidgetConfigService.getScreen();
  }
  initSchedule() {
    this.todayDate = new Date();
    this.todayDate = this.todayDate.toLocaleTimeString();
    this.recAction = this.data["recomendedAction"];
    this.templatesnapshotUrl = this.data["emailtemplatesnapshotUrl"];
    this.emailLayoutName = this.data["emailLayoutName"];
    this.templateDispatchForm = new FormGroup({
      dispatchType: new FormControl("INSTANT", [Validators.required]),
      dispatchDate: new FormControl(null, [Validators.required]),
      dispatchTime: new FormControl(null, [Validators.required]),
      dispatchcustomerSegments: new FormControl("", [Validators.required]),
      tempsegmentFilter: new FormControl(""),
      isCustomerId: new FormControl(),
    });
    this.startDateChange();
    this.getCustomerSegmentsAndTiers();
  }
  getCustomerData() {
    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      let segment = (response["body"]);
      for (let i = 0; i < segment.length; i++) {
        this.segments.push({
          id: segment[i]["id"],
          name: segment[i]["segmentName"],
          isActive: segment[i]["isActive"],
          status: false,
        });
      }
    });
  }

  getCustomerSegmentsAndTiers() {
    this.apiCall.getCustomerSegmentsAndTiers().subscribe(response => {
      let res = (response['body']);
      for (let i = 0; i < res.length; i++) {
        if(res[i].category.toLowerCase() === 'segment') {
          this.customerSegmentList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
        if(res[i].category.toLowerCase() === 'tier') {
          this.customerTierList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
        if(res[i].category.toLowerCase() === 'customerlist') {
          this.customerList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
        if(res[i].category.toLowerCase() === 'employeelist') {
          this.employeeList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
      }
    },
    error => {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching getCustomerSegmentsAndTiers data", ['POS_PAGE']), 2000);
    });
  }

  launchDateConfig() {
    var launchData;
    var currentTime = new Date();
    if (this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED") {
      var launchdate = this.datePipe.transform(
        currentTime.toDateString(),
        "yyyy-MM-dd"
      );

      launchData =
        launchdate + "T" + currentTime.toTimeString().split("GMT")[0];
    } else {
      var launchdate = this.datePipe.transform(
        this.templateDispatchForm["value"]["dispatchDate"].toDateString(),
        "yyyy-MM-dd"
      );
      launchData =
        launchdate +
        "T" +
        this.templateDispatchForm["value"]["dispatchTime"].split(" ")[0] +
        ":00";
    }
    return launchData;
  }

  changeSegmentSelection() {
    if (
      this.templateDispatchForm.controls.dispatchcustomerSegments["value"]
        .length === 1
    ) {
      if (
        this.templateDispatchForm.controls.dispatchcustomerSegments[
          "value"
        ][0] === "ALL_CUSTOMERS"
      ) {
        this.disableCustomerSegmentSelection = true;
      } else {
        this.disableAllCustomerSelection = true;
      }
    } else if (
      this.templateDispatchForm.controls.dispatchcustomerSegments["value"]
        .length === 0
    ) {
      this.selectedSegments = [];
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
    }
    // for (let i = 0; i < this.templateDispatchForm.controls.customerSegments['value'].length; i++) {
    //   if (this.templateDispatchForm.controls.customerSegments['value'][i] === "ALL_CUSTOMERS") {
    //     this.disableCustomerSegments = true;
    //     let value = ["ALL_CUSTOMERS"]
    //     this.templateDispatchForm.controls.targetCustomerSegments.setValue(value);
    //     return;
    //   }
    // }
    // this.disableCustomerSegments = false;
  }

  launchOffer() {
    var isCustSegmented = true;
    this.submitted = true;
    if (this.templateDispatchForm["value"]["dispatchType"] == "SCHEDULED"&&!this.templateDispatchForm.valid) {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Fill all the forms", ['POS_PAGE']), 2000);
      return;
    }else{
      if(this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED" && this.templateDispatchForm.controls.dispatchcustomerSegments["value"].length==0){
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Fill all the forms", ['POS_PAGE']), 2000);
        return;
      }
    }
    if (
      this.templateDispatchForm.controls.dispatchcustomerSegments["value"] ==
      "ALL_CUSTOMERS"
    ) {
      this.templateDispatchForm.controls.dispatchcustomerSegments = [];
      isCustSegmented = false;
    }
    if (this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED") {
      this.finalData = {
        channel: this.channel,
        segments:
          this.templateDispatchForm.controls.dispatchcustomerSegments.value,
        offerId: this.offerId,
        isSegmented: isCustSegmented,
        dispatchType: "INSTANT",
      };
    } else {
      this.finalData = {
        channel: this.channel,
        segments:
          this.templateDispatchForm.controls.dispatchcustomerSegments.value,
        offerId: this.offerId,
        isSegmented: isCustSegmented,
        date: this.launchDateConfig(),
        dispatchType: "SCHEDULED",
      };
    }

    if(this.templateDispatchForm.controls.dispatchcustomerSegments.value && this.templateDispatchForm.controls.dispatchcustomerSegments.value.length>0 && isCustSegmented) {
      this.finalData.segments = [];
      this.templateDispatchForm.controls.dispatchcustomerSegments.value.forEach(selectedSegment => {
      let req = [...this.customerSegmentList,...this.customerTierList,...this.customerList,...this.employeeList].find(segment => selectedSegment === segment.id);
      if(req.category.toLowerCase() === 'tier') {
        if(!this.finalData.hasOwnProperty('tiers')) {
          this.finalData['tiers'] = [];
        }
        this.finalData['tiers'].push(req.id);
      }
      if(req.category.toLowerCase() === 'segment') {
        this.finalData['segments'].push(req.id);
      }
      if(req.category.toLowerCase() === 'customerlist') {
        if(!this.finalData.hasOwnProperty('customerListIds')) {
          this.finalData['customerListIds'] = [];
        }
        this.finalData['customerListIds'].push(req.id);
      }
      if(req.category.toLowerCase() === 'employeelist') {
        if(!this.finalData.hasOwnProperty('employeeListIds')) {
          this.finalData['employeeListIds'] = [];
        }
        this.finalData['employeeListIds'].push(req.id);
      }
    });
    }

    this.apiCall.launchOffer(this.finalData, this.offerId).subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Offer Scheduled", ['POS_PAGE']), 4000);
          this.sendcancelMessage("updated");
        }
      },
      (err) => {
        const dialogRef = this.dialog.open(ContactUsDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          height: "auto",
          disableClose: true,
          width: "380px",
          data: {
            header: this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
            body: this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          return;
        });
      }
    );
  }

  dateCompare(time1, time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], parts[2], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], parts[2], 0);
    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }

  openViewOfferSummary() {
    const dialogRefer = this.dialog.open(OfferSummaryDialogComponent, {
      panelClass: "customAttachEmail",
      width: "40%",
      maxWidth: "546px",
      maxHeight: "90vh",
      data: this.offerId,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  rescheduleOffer() {
    if (this.templateDispatchForm.controls.dispatchType.value === "SCHEDULED") {
      if (
        this.templateDispatchForm.controls.dispatchDate.value === null ||
        this.templateDispatchForm.controls.dispatchDate.value === undefined
      ) {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Select date", ['POS_PAGE']), 2000);
        return 1;
      }
      if (
        this.templateDispatchForm.controls.dispatchTime.value === null ||
        this.templateDispatchForm.controls.dispatchTime.value === undefined
      ) {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Select time", ['POS_PAGE']), 2000);
        return 1;
      }
    }
    this.launchOffer();
  }
  scheduledClick() {
    if (this.selectedChannel == "SMS") {
      this.checkTypeTime();
    }
    return 0;
  }
  checkTypeTime() {
    if (
      this.dateCompareSub(
        this.templateDispatchForm.get("dispatchTime").value,
        "20:55:00"
      ) == 1
    ) {
      this.templateDispatchForm.get("dispatchTime").setValue(null);
    }
    if (
      this.dateCompareSub(
        "10:00:00",
        this.templateDispatchForm.get("dispatchTime").value
      ) == 1
    ) {
      this.templateDispatchForm.get("dispatchTime").setValue(null);
    }
  }

  dateCompareSub(time1, time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], 0);
    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }

  convertDispatchType() {
    this.templateDispatchForm.controls.dispatchType.setValue("SCHEDULED");
    this.checkTypeTime();
  }


  startDateChange() {
    var ct = new Date(this.startDateMinValue.setHours(0, 0, 0, 0));
    if (this.templateDispatchForm.controls.dispatchDate.value!=null&&(
      ct.getTime() ==
      this.templateDispatchForm.controls.dispatchDate.value.getTime())
    ) {
      //this.currentTimeMin = this.startDateMinValue.toTimeString();
      this.updateMinTime(1);
    } else {
      this.updateMinTime(0);
    }
  }

  updateMinTime(value) {
    var date=new Date();
    var ct =new Date(
      date.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      }));
    if (value) {
      this.currentTimeMin = ct.toTimeString();
      if(this.dateCompareSub(this.currentTimeMin, this.templateDispatchForm.controls.dispatchTime.value)!=-1){
        this.templateDispatchForm.controls.dispatchTime.setValue(null)
      }

      if (this.channel == "SMS") {
        if (this.dateCompareSub(this.currentTimeMin, "10:00:00") != 1) {
          this.currentTimeMin = "10:00 AM";
        }
      }else{
     //   this.currentTimeMin = "00:00";
      }
    } else {
      if (this.channel == "SMS") {
        this.currentTimeMin = "10:00 AM";
      } else {
        this.currentTimeMin = "00:00";
      }
    }
  }
  selectOpenclose(value){
    switch(value){
      case "scheduleOpen":
        this.scheduleOpen.open();
        break;
      case "segmentOpen":
        this.segmentOpen.open();
        break;
    }
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
