<div class="font" [attr.data-widget-name]="data['data-widget']">
  <div>
    <div class="header">
      <span style="text-align: center">{{'PROFILE_PAGE.REMOVE_USER_CAPS' | i18nTranslate}}</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div>
    <div class="message">
      <span>{{'PROFILE_PAGE.WISH_TO_REMOVE' | i18nTranslate}}
        <b>{{data["username"]}}</b>  
      </span>
    </div>
  </div>
  <div class="button-container">
    <span
      class="secondary-button-border"
      (click)="submit(false)"
      >{{'PROFILE_PAGE.CANCEL' | i18nTranslate}}</span
    >
    <!-- <span
      class="primary-button"
      (click)="delete(data['email'])"
      ></span
    > -->
    <button class="confirm primary-button" (click)="delete(data['email'])" *ngIf="!apiloading">{{'PROFILE_PAGE.REMOVE' | i18nTranslate}}</button>
    <button class="confirm secondary-button" style="width: 100px; justify-content: center;" *ngIf="apiloading"><mat-spinner diameter="15"></mat-spinner></button>
    <!-- <span class="primary-button" *ngIf="data['successButtonText']" (click)="submit(true)">Delete</span> -->
  </div>
</div>
