import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  NgZone,
  AfterContentInit,
} from "@angular/core";
import Bee from "@mailupinc/bee-plugin";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute, UrlTree } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { SendTestEmailDialogComponent } from "../send-test-email-dialog/send-test-email-dialog.component";
import { template1, template2, template3, template4 } from "src/app/shared/json-asset";
import { chefDrivenTemplate1, chefDrivenTemplate2, chefDrivenTemplate3, chefDrivenTemplate4 } from "src/app/shared/json-asset/chef-driven-templates";
import { fontList } from "src/app/shared/json-asset/font-list";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { AiEmailCreationComponent } from "../ai-email-creation/ai-email-creation.component";
import { EmailTemplateNameDialogComponent } from "../email-template-name-dialog/email-template-name-dialog.component";

interface DialogData {
  type: string;
  id: string;
  clone: string;
  return: string;
  emailType: string;
}

@Component({
  selector: "app-email-editor-dialog",
  templateUrl: "./email-editor-dialog.component.html",
  styleUrls: ["./email-editor-dialog.component.css"],
})
export class EmailEditorDialogComponent implements AfterContentInit, OnInit {
  // @HostListener("window:resize", ["$event"])
  // getScreenSize(event?) {
  //   this.scrHeight = window.innerHeight;
  // }

  @ViewChild("inpName") inpName: ElementRef;
  @ViewChild("inpSubject") inpSubject: ElementRef;

  sendTestEmail;
  scrHeight;
  finalHeight;
  beeTest;
  beeToken;
  beeConfig;
  specialLinks = [
    {
      type: "Unsubscribe",
      label: "Unsubscribe",
      link: "%unsubscribe_url%",
    },
  ];
  // payload = {
  //   client_id: "c26d0546-7885-418b-ba52-598811e4cf64",
  //   client_secret: "xfh20L0C0d9R0imGZK7VE1epcsLpZPGE9ohtsHKWNIZzq6P5457J",
  //   grant_type: "password"
  // }
  payload = {
    client_id: environment.beepluginClientId,
    client_secret: environment.beepluginClientSecretId,
    grant_type: "password",
  };
  selectedTemplate = "T1";
  finalContent;
  // showSaveButton = false;
  templateName;
  templateForm: FormGroup = new FormGroup({
    TemplateName: new FormControl({ value: "", disabled: false }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    ]),
    subject: new FormControl({ value: "", disabled: false }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]),
  });
  subjectError = false;
  nameError = false;
  submitClicked = false;
  responseMsg: string = null;
  templateIdNew;
  showDuplicateError: boolean = false;
  layout;
  renderStatus = false;
  mergeTags = [];
  showSpinner = false;
  appSubscriptionPlan;
  validWidgets;
  template1;
  template2;
  template3;
  template4;
  newTemplate = {};
  isEditorLoaded: boolean = true;
  isTemplateNameEdit: boolean = false;
  editorSatus = "Saving";

  constructor(
    public dialogRef: MatDialogRef<EmailEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public urlData: DialogData,
    private apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private snack: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    //this.getScreenSize();
  }

  ngAfterContentInit() {}

  ngOnInit() {
    this.getSampleTemplates();
    this.getWidgets();
    if(this.urlData['gametype']==='NEGOTIATOR_EMAIL'){
      this.apiCall.getNegoriatorMergeTag().subscribe((response)=>{
        console.log(response)
        for(let key in response['body']){
          this.mergeTags.push({
            name: response['body'][key],
            value: key
          })
        }
      });
      this.apiCall.getNegotiatorEmailTemplate(this.urlData['gameId']).subscribe((response)=>{
        this.layout=response['body']
        this.initializeForm();
        console.log(this.layout)
      })
    }
    else{
      this.apiCall
        .getAllMessageTemplateVariablesforOffer(null)
        .subscribe((response) => {
          let variables = JSON.parse(response["body"]);
          for (let key in variables) {
            this.mergeTags.push({
              name: variables[key],
              value: key,
            });
          }
          console.log(this.urlData);
          if (this.urlData.id !== "null") {
            if (this.urlData.type === "REWARD") {
              this.apiCall
                .getRewardEmailDetail(this.urlData.id)
                .subscribe((response) => {
                  this.layout = JSON.parse(response["body"]);
                  this.initializeForm();
                });
            } else {
              if (this.urlData.type === "SURVEYEMAIL") {
                this.apiCall
                  .getEmailContent(this.urlData.id)
                  .subscribe((response) => {
                    this.layout = JSON.parse(response["body"]);
                    this.initializeForm();
                  });
              } else {
                this.apiCall
                  .getEmailLayoutDetail(this.urlData.id)
                  .subscribe((response) => {
                    if(response['body'] !== 'null'){
                      console.log(response)
                      this.layout = JSON.parse(response["body"]);
                      this.initializeForm();
                    }
                    else{
                      this.apiCall
                      .getRewardEmailDetail(this.urlData.id)
                      .subscribe((response) => {
                        console.log(response)
                        this.layout = JSON.parse(response["body"]);
                        this.initializeForm();
                      },
                      (err) => {
                        this.snackbar.openSnackBar("Something Went Wrong", 2000);
                      });
                    }
                  },
                  (err) => {
                    this.apiCall
                    .getRewardEmailDetail(this.urlData.id)
                    .subscribe((response) => {
                      console.log(response)
                      this.layout = JSON.parse(response["body"]);
                      this.initializeForm();
                    },
                    (err) => {
                      this.snackbar.openSnackBar("Something Went Wrong", 2000);
                    });
                  });
              }
            }
          } else {
            this.initializeForm();
          }
        });
    }
    this.checkAction();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  initializeForm() {
    let disabledStatus;
    if (this.urlData.id !== "null") {
      if (
        this.layout["emailType"] === "STANDARD" &&
        this.urlData.clone === "false"
      ) {
        disabledStatus = true;
      }
    }
    this.templateForm = new FormGroup({
      TemplateName: new FormControl({ value: "", disabled: disabledStatus }, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      subject: new FormControl({ value: "", disabled: disabledStatus }, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });
    this.renderStatus = true;
    this.finalHeight = this.scrHeight;
    this.beeTest = new Bee(this.beeToken);

    this.beeConfig = {
      uid: "user-id-" + this.tokenStorage.getMerchantId(),
      container: "bee-plugin-container",
      language: "en-US",
      autosave: 30,
      readOnly: true,
      onSave: (jsonFile, htmlFile) => {
        this.ngZone.run(() => {
          this.editorSatus = 'Saving';
          let emailTemplateName = this.templateForm.get("TemplateName").value;
          if (emailTemplateName && emailTemplateName.length) {
            this.saveAsHtml("onSave", jsonFile, htmlFile);
          }
          console.log(jsonFile, htmlFile);
        });
      },
      onLoad:() =>{
        console.log("BEE editor is ready. Executing function...");
        this.ngZone.run(() => {
          this.editorSatus = 'Saved!';
          this.save();
        });
      },
      onAutoSave: (jsonFile) =>{
        console.log("AutoSave is working", jsonFile);
        this.ngZone.run(() => {
          this.editorSatus = "Autosaving";
          this.save();
        });
        console.log(this.editorSatus)
        // this.save();
      },
      onChange: (jsonFile, response) => {
        console.log("onChange is working", jsonFile, response);
        this.ngZone.run(() => {
          this.editorSatus = "Editing";
          this.save();
        });
        console.log(this.editorSatus)
      },
      loadingSpinnerDisableOnSave: true,
      onSaveAsTemplate: (jsonFile) => {},
      onSend: (htmlFile) => {
        let emailTemplateName = this.templateForm.get("TemplateName").value;
        if (emailTemplateName && emailTemplateName.length) {
          this.apiCall
            .sendTestEngageEmailHTML(
              "EMAIL_LAYOUT_HTML",
              "EMAIL",
              null,
              this.sendTestEmail,
              null,
              false,
              null,
              htmlFile
            )
            .subscribe(
              (response) => {
                this.snack.openSnackBar(
                  this._i18nDynamicTranslate.transform("Test email sent", [
                    "SHARED_PAGE",
                  ]),
                  2000
                );
              },
              (err) => {
                this.snack.openSnackBar(
                  this._i18nDynamicTranslate.transform(
                    "Failed to send test email",
                    ["SHARED_PAGE"]
                  ),
                  2000
                );
              }
            );
        } else
          this.snack.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Email Template Name is required",
              ["SHARED_PAGE"]
            ),
            2000
          );
      },
      onError: (errorMessage) => {},
      // onChange: (jsonFile, response) => {
      //   let i = -1;
      //     Object.keys(JSON.parse(this.response['body']).socialMediaUrls).map(key => {
      //       i = -1;
      //       JSON.parse(jsonFile).page.rows[5].columns[0].modules[1].descriptor["iconsList"]["icons"].forEach(element => {
      //         i++;
      //         if(element["image"]["alt"] === key) {
      //           JSON.parse(jsonFile).page.rows[5].columns[0].modules[1].descriptor["iconsList"]["icons"][i]["image"]["href"] = JSON.parse(this.response['body']).socialMediaUrls[key];
      //         }
      //       });
      //       return (JSON.parse(jsonFile));
      //     });
      // },
      editorFonts: {
        showDefaultFonts: false,
        customFonts: fontList,
      },
    };

    // this.beeConfig = {
    //   uid: "user-id-" + this.tokenStorage.getMerchantId(),
    //   container: "bee-plugin-container",
    //   language: "en-US",
    //   onSave: (jsonFile, htmlFile) => {
    //     let emailTemplateName = this.templateForm.get("TemplateName").value;
    //     if (emailTemplateName && emailTemplateName.length)
    //       this.saveAsHtml("onSave", jsonFile, htmlFile);
    //     else this.snack.openSnackBar("Email Template Name is required", 2000);
    //   },
    //   onSaveAsTemplate: (jsonFile) => {},
    //   onSend: (htmlFile) => {
    //     let emailTemplateName = this.templateForm.get("TemplateName").value;
    //     if (emailTemplateName && emailTemplateName.length) {
    //       this.apiCall
    //         .sendTestEngageEmailHTML(
    //           "EMAIL_LAYOUT_HTML",
    //           "EMAIL",
    //           null,
    //           this.sendTestEmail,
    //           null,
    //           false,
    //           null,
    //           htmlFile
    //         )
    //         .subscribe(
    //           (response) => {
    //             this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Test email sent", ['SHARED_PAGE']), 2000);
    //           },
    //           (err) => {
    //             this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Failed to send test email", ['SHARED_PAGE']), 2000);
    //           }
    //         );
    //     } else this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Email Template Name is required", ['SHARED_PAGE']), 2000);
    //   },
    //   onError: (errorMessage) => {},
    //   onChange: function (jsonFile, response) {},
    //   editorFonts: {
    //     showDefaultFonts: false,
    //     customFonts: fontList,
    //   },
    // };
    this.beeConfig.specialLinks = this.specialLinks;
    if (this.urlData.id !== "null") {
      if (this.urlData.clone === "true") {
        this.layout["name"] = this.addClonedSuffix(this.layout["name"]);
      }
      this.templateForm.get("TemplateName").setValue(this.layout["name"]);
      this.templateName = this.layout["name"];
    }
    if (this.urlData.type === "REWARD") {
      if (this.urlData.clone === "true") {
        this.layout["name"] = this.addClonedSuffix(this.layout["name"]);
      } else {
        this.templateForm.get("subject").setValue(this.layout["subject"]);
      }
    }
    if(this.urlData['gametype']==="NEGOTIATOR_EMAIL"){
      this.templateForm.get("TemplateName").setValue("Negotiator Reward Email");
    }
    this.beeConfig.mergeTags =
      this.urlData.type === "REWARD" ? [] : this.mergeTags;
    
      if(this.urlData['gametype']==="NEGOTIATOR_EMAIL"){
        console.log(this.layout['json'])
        this.beeTest
        .getToken(this.payload.client_id, this.payload.client_secret)
        .then(() =>
          this.beeTest.start(
            this.beeConfig,
            JSON.parse(this.layout['json'])
          )
        );
      }
      else{  
      }
      if(this.urlData.id !== "null"){
        this.startBeeEditor();
      }   
  }

  openSendTestEmailDialog() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
        this.sendTestEmail = response;
        let emailTemplateName = this.templateForm.get("TemplateName").value;
        if (emailTemplateName && emailTemplateName.length) {
          this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Sending test email", ['SHARED_PAGE']), 4000);
          this.beeTest.send();
        } else this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Email Template Name is required", ['SHARED_PAGE']), 2000);
      }
    });
  }

  async saveAsHtml(type, json, html) {
    console.log(json);
    console.log(this.urlData);
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      console.log("Authorized");
      this.submitClicked = true;
      let mId = this.tokenStorage.getMerchantId();
      if (
        this.urlData.type !== "SURVEYEMAIL" &&
        this.templateForm.controls.TemplateName.errors
      ) {
        return 0;
      }
      this.finalContent = {
        id:
          this.urlData.id !== "null" || this.urlData.clone === "false"
            ? this.urlData.id
            : null,
        merchantId: mId,
        name: this.templateForm.get("TemplateName").value,
        layoutHtml: html,
        layoutJson: json,
        type: "GENERIC",
      };
      //let snackBarRef = this.snackBar.open("Saving... Please wait");
      if (this.urlData.id !== "null" && this.urlData.clone === "false") {
        console.log(this.urlData.type)
        if (this.urlData.emailType === "REWARD") {
          let data = {
            merchantId: mId,
            subject: this.templateForm.get("subject").value,
            name: this.templateForm.get("TemplateName").value ? this.templateForm.get("TemplateName").value : null,
            layoutHtml: html,
            layoutJson: json,
            id: this.urlData.id,
          };
          this.apiCall.updateRewardEmail(data).subscribe(
            (response) => {
              this.spinner(false);
              this.editorSatus = 'Saved!';
              // this.returnToPreviousScreen(this.urlData.id, this.urlData.type);
            },
            (err) => {
              this.spinner(false);
              this.callSnackBar(err["error"]["body"]);
            }
          );
        } else if (this.urlData.type === "SURVEYEMAIL") {
          let data = {
            merchantId: this.tokenStorage.getMerchantId(),
            subject: "",
            layoutHtml: html,
            layoutJson: json,
            id: this.urlData.id,
          };
          this.apiCall.updateEmailContent(data).subscribe((response) => {
            // this.returnToPreviousScreen(response["body"], this.urlData.type);
          });
        }else if(this.urlData['gametype']==="NEGOTIATOR_EMAIL"){
          this.apiCall.saveNegotiatorMail(this.urlData['gameId'], html, json).subscribe((response)=>{
            this.spinner(false);
            this.editorSatus = 'Saved!';
            this.ngZone.run(() => {
              this.dialogRef.close(true);
            });
            // this.dialogRef.close();
          },   (err) => {
            this.spinner(false);
            this.callSnackBar(err["error"]["body"]);
          })
        }
         else {
          this.apiCall.updateEmailLayout(this.finalContent).subscribe(
            (response) => {
              this.spinner(false);
              this.editorSatus = 'Saved!';
              // this.returnToPreviousScreen(response["body"], this.urlData.type);
            },
            (err) => {
              this.spinner(false);
              this.callSnackBar(err["error"]["body"]);
            }
          );
        }
      }else if(this.urlData['gametype']==="NEGOTIATOR_EMAIL"){
        this.apiCall.saveNegotiatorMail(this.urlData['gameId'], html, json).subscribe((response)=>{
          this.spinner(false);
          this.editorSatus = 'Saved!';
          this.ngZone.run(() => {
            this.dialogRef.close(true);
          });
        },
        (err) => {
          this.spinner(false);
          this.callSnackBar(err["error"]["body"]);
        })
      }
       else {
        this.apiCall.createEmailLayout(this.finalContent).subscribe(
          (response) => {
            this.spinner(false);
            this.editorSatus = 'Saved!';
            // this.returnToPreviousScreen(response["body"], "CUSTOM");
            this.urlData.id = response['body'];
            this.urlData.type = "CUSTOM";
            this.urlData.clone = "false";
          },
          (err) => {
            this.spinner(false);
            this.callSnackBar(err["error"]["body"]);
          }
        );
      }
    }
  }

  spinner(status) {
    this.ngZone.run(() => {
      this.showSpinner = status;
    });
  }

  callSnackBar(text) {
    this.ngZone.run(() => {
      this.snackBar.open(text, "", { duration: 2000 });
    });
  }
  returnToPreviousScreen(id, type) {
    console.log(id);
    if (this.urlData.return !== "false") {
      this.dialogRef.close();
      this.router.navigate([this.urlData.return, { id: id, type: type }]);
    } else {
      this.ngZone.run(() => {
        this.dialogRef.close(id);
      });
    }
  }

  closeEditor() {
    this.ngZone.run(() => {
    this.returnToPreviousScreen(this.urlData.id, "CUSTOM");
      // this.dialogRef.close();
    });
    if (this.urlData.return !== "false") {
      this.router.navigate([
        this.urlData.return,
        {
          id: this.urlData.id === "null" ? null : this.urlData.id,
          type: this.urlData.type,
        },
      ]);
    }
  }

  selectTemplate(template) {
    this.selectedTemplate = template;
    switch (template) {
      case "T1": {
        return (this.template1);
      }
      case "T2":
        return (this.template2);
      case "T3":
        return (this.template3);
      case "T4":
        return (this.template4);
      case "newTemplate":
        return (this.newTemplate);
     }
  }

  createEmail() {
    this.dialog.open(AiEmailCreationComponent);
  }

  getSampleTemplates(){
    if(this.tokenStorage.getMerchantOnlineStore() === 'restomail'){
      this.template1 = chefDrivenTemplate1;
      this.template2 = chefDrivenTemplate2;
      this.template3 = chefDrivenTemplate3;
      this.template4 = chefDrivenTemplate4;
    }
    else{
      this.template1 = template1;
      this.template2 = template2;
      this.template3 = template3;
      this.template4 = template4;
    }
  }
  checkAction(){
    if(this.urlData.id === "null" || this.urlData.id === null){
      let email_list = document.getElementById('sample-listing');
      email_list.style.display = 'block';
      return;
    }
    else{
      let lel = document.getElementById('qb-bee-editor-container');
      let email_list = document.getElementById('sample-listing');
      let header = document.getElementById('header-tab');
      let conatiner = document.getElementById('full-container-email');
      conatiner.style.padding = '0px';
      header.style.display = 'none';
      email_list.style.display = 'none';
      lel.style.display = 'block';
    }
  }

  shwoTemplate(template){
    const dialogRef = this.dialog.open(
      EmailTemplateNameDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "30%",
        data: {
          title: "Email Template Name",
          field1Name: this._i18nDynamicTranslate.transform("Template Name", ['POS_PAGE']),
          cancelButtonName: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          submitButtonName: this._i18nDynamicTranslate.transform("Continue", ['POS_PAGE']),
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.startBeeEditor(template);
        this.templateName = result;
        this.templateForm.get("TemplateName").setValue(result);
        let lel = document.getElementById('qb-bee-editor-container');
        let header = document.getElementById('header-tab');
        let email_list = document.getElementById('sample-listing');
        let conatiner = document.getElementById('full-container-email');
        email_list.style.display = 'none';
        conatiner.style.padding = '0px';
        header.style.display = 'none';
        lel.style.display = 'block';
      }
    });
  }

  save(){
    this.beeTest.save();
  }

  nameChange(type){
    if(type){
      if (this.templateForm.controls.TemplateName.errors) {
        return;
      }
      this.templateName = this.templateForm.get("TemplateName").value;
      this.isTemplateNameEdit = false;
      this.save();
    }
    else{
      this.templateForm.get("TemplateName").setValue(this.templateName);
      this.isTemplateNameEdit = false;
    }
  }
  
  previewEmailTemplate(){
    this.beeTest.preview();
  }

  returnBack() {
    this.router.navigate([this.urlData.return, { id: this.urlData.id, type: this.urlData.type }]);
  }

  async startBeeEditor(template?){
    await this.beeTest
    .getToken(this.payload.client_id, this.payload.client_secret)
    .then(() =>{
      this.beeTest.start(
        this.beeConfig,
        this.urlData.id !== "null"
          ? JSON.parse(this.layout["layoutJson"])
          : this.selectTemplate(template)
      )
    }
    );
  }

  createSharableLink() {
    const urlTree: UrlTree = this.router.createUrlTree(["view-template"], {
      queryParams: {
        id: this.urlData.id,
        mid: this.tokenStorage.getMerchantId(),
        type: this.urlData.emailType?.toLowerCase() ? this.urlData.emailType.toLowerCase() : this.urlData.type.toLowerCase(),
      },
    });

    const relativeUrl: string = this.router.serializeUrl(urlTree);

    const baseUrl = window.location.origin;
    const fullUrl: string = `${baseUrl}${relativeUrl}`;
    
    console.log(fullUrl);

    this.copyToClipboard(fullUrl);
  }

  copyToClipboard(url: string) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // alert("URL copied to clipboard: " + url); // Optional: show an alert or other UI feedback
    this.snack.openSnackBar("Email sharable link copied to clipboard", 5000);
  }

  addClonedSuffix(name) {
    let maxLength = 50;
    let suffix = "-Cloned";
    let randomString = Math.random().toString(36).substring(2, 6);  // Generate a random 4-character string
    let maxBaseNameLength = maxLength - suffix.length - randomString.length - 1; // Account for space between name and random string
    return name.length <= maxBaseNameLength
        ? name + suffix + "-" + randomString
        : name.substring(0, maxBaseNameLength).trim() + suffix + "-" + randomString;
  }
}
