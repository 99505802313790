<div class="body">
  <div class="title">
    <div>{{'SHARED_PAGE.DISABLE_OFFER' | i18nTranslate}}</div>
    <div class="material-icons-outlined close" (click)="dialogRef.close(null)">close</div>
  </div>
  <div>{{'SHARED_PAGE.DISABLE_OFFER_PARAGRAPH' | i18nTranslate}} <strong>{{data.name}}</strong></div>
  <div class="select-reason">{{'SHARED_PAGE.SELET_REASON' | i18nTranslate}}</div>
  <div class="reason-dropdown">
    <mat-select [(ngModel)]="reason" class="reason-select">
      <mat-option [value]="item" *ngFor="let item of reasons">{{item}}</mat-option>
      <!-- <mat-option value="Others">Others</mat-option> -->
    </mat-select>
  </div>
  <div *ngIf="reason === 'Others'">
    <div class="select-reason">{{'SHARED_PAGE.REASON' | i18nTranslate}}</div>
    <div class="reason-dropdown">
      <input type="text" [(ngModel)]="customReason" class="reason-select"(input)='customReasonError = ""'>
    </div>
    <div class="error">{{customReasonError}}</div>
  </div>
  <div class="buttons">
    <div class="secondary-button-border" (click)="dialogRef.close(null)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
    <div class="primary-button disable-button" (click)="disableOffer()" style="text-transform: capitalize;">{{'SHARED_PAGE.DISABLE_OFFER'}}</div>
  </div>
</div>
