import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';

@Component({
  selector: 'app-communication-screen',
  templateUrl: './communication-screen.component.html',
  styleUrls: ['./communication-screen.component.css']
})
export class CommunicationScreenComponent implements OnInit {

  constructor(
    private apicall : ApiCallService,
    private snackBar : SnackbarCollection,
    private clipboard: Clipboard,
    private _WidgetConfigService: WidgetConfigService
  ) { }
  otpBasedRedemption = false;
  offerBasedCommunication = false;
  onPointsEarned = false;
  onPointsRedeemed = false;
  onPointsBurnt = false;
  onTierUpgrade = false;
  onTierDowngrade = false;
  channel = [];
  customerLoginUrl = '';
  validWidgets;
  errorOnSave = false;
  ngOnInit(): void {
    this.getData();
    this.getUrl();
    this.getWidgets();
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }
  changeCommunication(check, data){
    if(check){
      this.channel.push(data);
    }
    else{
      const index = this.channel.indexOf(data);
      this.channel.splice(index, 1);
    }
    console.log("CHANNEL",this.channel);

  }
  toggleChange(check, type){
    console.log(check, type)
    if(type === 'otpBased'){
      this.otpBasedRedemption = check;
    } else if(type === 'offerBasedCommunication'){
      this.offerBasedCommunication = check;
    } else if(type === 'onPointsEarned'){
      this.onPointsEarned = check;
    } else if(type === 'onPointsRedeemed'){
      this.onPointsRedeemed = check;
    } else if(type === 'onPointsBurnt'){
      this.onPointsBurnt = check;
    } else if(type === 'onTierUpgrade'){
      this.onTierUpgrade = check;
    } else {
      this.onTierDowngrade = check;
    }
  }
  isToggleSelected(): boolean {
    return this.otpBasedRedemption || this.offerBasedCommunication ||
           this.onPointsEarned || this.onPointsRedeemed ||
           this.onPointsBurnt || this.onTierUpgrade ||
           this.onTierDowngrade;
  }

  saveSelection() {
    if (this.channel.length === 0 && !this.isToggleSelected()) {
        this.snackBar.openSnackBar("Please ensure that at least one channel is selected and at least one toggle is switched on.", 5000);
        this.errorOnSave = true;
    } else if (this.channel.length === 0) {
        this.snackBar.openSnackBar("Please ensure that at least one channel is selected.", 5000);
        this.errorOnSave = true;
    } else if (!this.isToggleSelected()) {
        this.snackBar.openSnackBar("Please ensure that at least one toggle is switched on.", 5000);
        this.errorOnSave = true;
    } else {
        this.errorOnSave = false;
        this.saveData(this.channel);
    }
}

  saveData(channels: string[]) {
    let data = {
      channels: channels,
      communicationAfterRedem: this.offerBasedCommunication,
      otpBasedRedem: this.otpBasedRedemption,
      onPointsEarned: this.onPointsEarned,
      onPointsRedeemed: this.onPointsRedeemed,
      onPointsBurnt: this.onPointsBurnt,
      onTierUpgrade: this.onTierUpgrade,
      onTierDowngrade: this.onTierDowngrade,
    };
    this.apicall.saveToggleCommunication(data).subscribe((response) => {
      if (response['body'] === 'SAVED') {
        this.snackBar.openSnackBar("Saved Selection Successfully", 5000);
      }
    }, (err) => {
      this.snackBar.openSnackBar("Something went Wrong", 5000);
    });
  }

  getData(){
    this.apicall.getToggleCommunicationdata().subscribe((response)=>{
      console.log(response);
      if(response['body'] != "" || response['body'] != null){
        let data = response['body'];
        console.log(data)
        this.offerBasedCommunication = data['communicationAfterRedem'];
        this.otpBasedRedemption = data['otpBasedRedem'];
        this.onPointsEarned = data['onPointsEarned'];
        this.onPointsRedeemed = data['onPointsRedeemed'];
        this.onPointsBurnt = data['onPointsBurnt'];
        this.onTierUpgrade = data['onTierUpgrade'];
        this.onTierDowngrade = data['onTierDowngrade'];
        this.channel = this.getChannels(data['channels'])
      }
    })
  }
  getChannels(data) {
    const channelMapping = {
      email: 'EMAIL',
      whatsapp: 'WHATSAPP',
      sms: 'SMS'
    };

    const uniqueChannels = new Set();

    return data.split(',').reduce((arrRes, ch) => {
      const channel = ch.trim().toLowerCase();
      if (channelMapping[channel] && !uniqueChannels.has(channelMapping[channel])) {
        uniqueChannels.add(channelMapping[channel]);
        arrRes.push(channelMapping[channel]);
      }
      return arrRes;
    }, []);
  }
  copyToClipboard(value: string) {
    let finalValue=value;
    this.clipboard.copy(finalValue);
    // Display "copied" for 1 second
  // const tooltip = this.renderer.createElement('span');
  // this.renderer.addClass(tooltip, 'tooltiptext');
  // this.renderer.appendChild(tooltip, this.renderer.createText('Copied'));
  // this.renderer.appendChild(finalValue, tooltip);


    this.snackBar.openSnackBar("Copied!", 1500);

  }
  redirectToCustomerPage(url){
    window.open(url);
  }
  getUrl(){
    this.getUrlApi().then((result) => {
      if(result){
        console.log(result);
        this.customerLoginUrl = result['body'];
      }
    });
  }
  getUrlApi(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apicall.getCustomerLoginUrl().subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }
}
