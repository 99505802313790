import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-gamify-listing-filter",
  templateUrl: "./gamify-listing-filter.component.html",
  styleUrls: ["./gamify-listing-filter.component.css"],
})
export class GamifyListingFilterComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GamifyListingFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  filters;
  status = [];
  channel = [];
  statusDraft;
  statusScheduled;
  statusSent;
  statusEnded;
  statusPaused;
  analyticsText: string;

  ngOnInit() {
    this.filters = this.data;

    this.statusDraft = this.filters.status.indexOf("DRAFT") != -1;
    this.statusScheduled = this.filters.status.indexOf("SCHEDULED") != -1;
    this.statusSent = this.filters.status.indexOf("LIVE") != -1;
    this.statusPaused = this.filters.status.indexOf("PAUSED") != -1;
    this.statusEnded = this.filters.status.indexOf("ENDED") != -1;
    this.setanalyticstext();
  }

  applyFilter() {
    this.statusSent ? this.status.push("LIVE") : null;
    this.statusDraft ? this.status.push("DRAFT") : null;
    this.statusScheduled ? this.status.push("SCHEDULED") : null;
    this.statusEnded ? this.status.push("ENDED") : null;
    this.statusPaused ? this.status.push("PAUSED") : null;
    this.setanalyticstext();
    this.dialogRef.close(
      (this.filters = { status: this.status, channel: this.channel })
    );
  }

  clear() {
    this.statusSent = false;
    this.statusDraft = false;
    this.statusScheduled = false;
    this.statusEnded = false;
    this.statusPaused=false;
    this.setanalyticstext();
  }
  close() {
    this.dialogRef.close(false);
  }
  setanalyticstext() {
    var email, sms, sent, draft, scheduled, ended,paused;

    sent = this.statusSent ? " Live " : "";
    draft = this.statusDraft ? " Draft " : "";
    scheduled = this.statusScheduled ? " Scheduled " : "";
    ended = this.statusEnded ? " Ended " : "";
    paused = this.statusPaused ? " Paused " : "";
    this.analyticsText = email + sms + sent + draft + scheduled + ended+paused;
  }

  closeFiltersDiv() {
    close();
  }
}
