import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SendTestEmailDialogComponent } from "../send-test-email-dialog/send-test-email-dialog.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { Router, UrlTree } from "@angular/router";

@Component({
  selector: 'app-html-for-email-templates-dialog',
  templateUrl: './html-for-email-templates-dialog.component.html',
  styleUrls: ['./html-for-email-templates-dialog.component.css']
})
export class HtmlForEmailTemplatesDialogComponent implements OnInit {
  htmlrend;
  subject;
  loading:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<HtmlForEmailTemplatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private snackbar: SnackbarCollection,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    this.loading=true;
    if(this.data.type=="reward"){
      this.apiCall
      .getRewardEmailDetail(this.data.id)
      .subscribe((response) => {
        this.loading=false;
        if (response["message"] === "success") {

            let layout = JSON.parse(response["body"]);
            this.htmlrend = layout["layoutHtml"];

            this.subject = layout["name"];
        }
      });

    }
    else if(this.data.type !== 'FORM') {
    // if(param['id']){
      this.apiCall.getEmailLayoutDetail(this.data.id).subscribe(
        (response) => {
          if (response["message"] === "success") {
            this.loading=false;
            let layout = JSON.parse(response["body"]);
            this.htmlrend = layout["layoutHtml"];
            this.subject = layout["name"];
          }
        },
        (err) => {}
      );
    // }

    } else {
      this.loading = false;
      this.htmlrend = this.data.layoutHTML;
            this.subject = this.data.subject;
    }
  }
  submit(decision) {
    this.dialogRef.close(decision)
  }

  openSendTestEmailDialog() {
    let data = {};
    if(this.data.type === 'reward') {
      data= {
        "type": 'OFFER',
        "channel": 'EMAIL',
        "templateID": this.data.templateId,
        "phone" : null,
        "isReward": true,
        "rewardType": this.data.rewardType
      }
    } else if(this.data.type == 'surveyemailtemplate') {
      data= {
        "type": 'SURVEY',
        "channel": 'EMAIL',
        "templateID": this.data.templateId,
        "phone" : null,
        "isReward": false,
        "rewardType": null
      }
    }
      const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        disableClose: true,
        width: "50%"
      });

      dialogRef.afterClosed().subscribe(response => {
        if(response != false) {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Sending test email", ['SHARED_PAGE']), 2000);
        this.apiCall.sendTestEngageEmailHTML(this.data.type, this.data.channel, this.data.templateID, response, this.data.phone, this.data.isReward, this.data.rewardType, this.data.emailLayouHtml).subscribe(response => {
          this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform('Test email sent', ['SHARED_PAGE']), 2000);
        },
        (err) => {
          this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to send test email", ['SHARED_PAGE']), 2000);
      });
    }
    });
  }

  createSharableLink() {
    event.stopPropagation();
    const urlTree: UrlTree = this.router.createUrlTree(["view-template"], {
      queryParams: {
        id: this.data.id,
        mid: this.tokenStorage.getMerchantId(),
        type: this.data.type.toLowerCase(),
      },
    });

    const relativeUrl: string = this.router.serializeUrl(urlTree);

    const baseUrl = window.location.origin;
    const fullUrl: string = `${baseUrl}${relativeUrl}`;
    
    console.log(fullUrl);

    this.copyToClipboard(fullUrl);
  }

  copyToClipboard(url: string) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // alert("URL copied to clipboard: " + url); // Optional: show an alert or other UI feedback
    this.snackbar.openSnackBar("Email sharable link copied to clipboard", 5000);
  }
}
