import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as d3 from "d3";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";

@Component({
  selector: "app-products-sold-chart",
  templateUrl: "./products-sold-chart.component.html",
  styleUrls: ["./products-sold-chart.component.css"],
})
export class ProductsSoldChartComponent implements OnInit {
  @Input() selectedGraphButton;
  @Input() width;
  @Input() page;
  @Input() id;
  @ViewChild("chartContainer") chartContainer: ElementRef;
  @ViewChild("line") line: ElementRef;
  array = [];
  data = [];
  max = 0;
  startDate;
  endDate;
  type;
  ylabel = "No of Products Sold";
  graphLoading = true;
  mobileMatch = window.matchMedia("(max-width: 896px)");
  sidenavSubject;
  graphLabelStatus = true;
  request = {
    merchantId: this.tokenStorage.getMerchantId(),
    partnershipRequestId: null,
    startDate: "",
    endDate: "",
    dataPointType: "",
  };
  isSource = false;
  noGraph = false;

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event) {
    this.graphLabelStatus = false;
    d3.select(this.chartContainer.nativeElement)
      .selectAll("tooltip-div")
      .remove();
    d3.select(this.chartContainer.nativeElement).selectAll("svg").remove();
    setTimeout(() => {
      this.getGraphByTime();
      this.graphLabelStatus = true;
    }, 600);
  }
  count = 0;
  defaultWidth;
  defaultGraphButton;

  constructor(
    private apiCall: ApiCallService,
    private datePipe: DatePipe,
    private _SidenavOperationService: SidenavOperationService,
    private tokenStorage: TokenStorageService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      if (param.type === "source") this.isSource = true;
    });
  }

  ngOnChanges() {
    //this.getGraphByTime();
    // if(this.count == 0) {
    //   this.defaultWidth = this.width;
    // }
    if (this.sidenavSubject) {
      this.sidenavSubject.unsubscribe();
    }
    this.sidenavSubject = this._SidenavOperationService.sidenavStatus.subscribe(
      (response) => {
        d3.select("svg").remove();
        let interval = setTimeout(() => {
          if(this.chartContainer.nativeElement){
            this.renderGraph();

            clearInterval(interval);
          }
        },500)
      //   this.count++;
      //   if(response === true) {
      //     if(this.defaultWidth == 720) {
      //         this.width = 850;
      //         } else {
      //         this.width = 1050;
      //   }

      // } else {
      //     this.width = this.defaultWidth;
      //   }
        this.graphLabelStatus = false;
        if(this.chartContainer !== undefined)
        d3.select(this.chartContainer.nativeElement).selectAll("svg").remove();
        setTimeout(() => {
          this.getGraphByTime();
          this.graphLabelStatus = true;
        }, 600);
      }
    );
  }

  ngOnDestroy() {
    this.sidenavSubject.unsubscribe();
  }

  getGraphByTime() {
    this.data = [];
    this.array = [];
    this.request.partnershipRequestId = this.id;
    if (this.selectedGraphButton === "All Time") {
      this.request.startDate = this.datePipe.transform(
        new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
        "yyyy-MM-dd"
      );
      this.request.endDate = this.datePipe.transform(
        new Date().toISOString(),
        "yyyy-MM-dd"
      );
      this.request.dataPointType = "WEEK";
    } else {
      this.request.startDate = this.datePipe.transform(
        new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString(),
        "yyyy-MM-dd"
      );
      this.request.endDate = this.datePipe.transform(
        new Date(Date.now()).toISOString(),
        "yyyy-MM-dd"
      );
      this.request.dataPointType = "DAY";
    }
    this.getCustomersGraphStats();
  }

  getCustomersGraphStats() {
    // this.ylabel = "No of Customers";
    this.graphLoading = true;
    this.array = [];
    this.data = [];
    this.max = 0;
    if (this.page === "total") {
      this.apiCall
        .getMyAnalyticsGraphStats(this.request)
        .subscribe((response) => {
          this.noGraph = true;
          if(response["body"]["productsSoldMap"] === null) {
            this.noGraph = true;
          } else {
          Object.keys(
            response["body"]["productsSoldMap"].dataSet
          ).map((key) => {
            if(response["body"]["productsSoldMap"].dataSet[key] > 0) {
              this.noGraph = false;
            }
          });
          Object.keys(
            response["body"]["productsSoldMap"].dataSet
          ).map((key) => {
            this.array.push(
              response["body"]["productsSoldMap"].dataSet[key]
            );
            this.data.push({
              date: this.datePipe.transform(
                new Date(key.substring(0, 10)),
                "dd-MM-yy"
              ),
              value: response["body"]["productsSoldMap"].dataSet[
                key
              ],
            });
          });
          this.array.forEach((value) => {
            if (value > this.max) this.max = value;
          });
          this.graphLoading = false;
          this.renderGraph();
        }
        });
    } else {
      if (!this.isSource)
        this.apiCall
          .getTargetMerchantStats(this.request)
          .subscribe((response) => {
            this.noGraph = true;
            if(response["body"]["productsSoldMap"] === null) {
              this.noGraph = true;
            } else {
            Object.keys(
              response["body"]["productsSoldMap"].dataSet
            ).map((key) => {
              if(response["body"]["productsSoldMap"].dataSet[key] > 0) {
                this.noGraph = false;
              }
            });
            Object.keys(
              response["body"]["productsSoldMap"]["dataSet"]
            ).map((key) => {
              this.array.push(
                response["body"]["productsSoldMap"]["dataSet"][key]
              );
              this.data.push({
                date: this.datePipe.transform(
                  new Date(key.substring(0, 10)),
                  "dd-MM-yy"
                ),
                value: response["body"]["productsSoldMap"][
                  "dataSet"
                ][key],
              });
            });
            this.array.forEach((value) => {
              if (value > this.max) this.max = value;
            });
            this.graphLoading = false;
            this.renderGraph();
          }
          });
          else 
          this.apiCall
          .getSourceMerchantStats(this.request)
          .subscribe((response) => {
            this.noGraph = true;
            if(response["body"]["productsSoldmap"] === null) {
              this.noGraph = true;
            } else {
            Object.keys(
              response["body"]["productsSoldmap"].dataSet
            ).map((key) => {
              if(response["body"]["productsSoldmap"].dataSet[key] > 0) {
                this.noGraph = false;
              }
            });
            Object.keys(
              response["body"]["productsSoldmap"]["dataSet"]
            ).map((key) => {
              this.array.push(
               response["body"]["productsSoldmap"]["dataSet"][key]
              );
              this.data.push({
                date: this.datePipe.transform(
                  new Date(key.substring(0, 10)),
                  "dd-MM-yy"
                ),
                value: response["body"]["productsSoldmap"][
                  "dataSet"
                ][key],
              });
            });
            this.array.forEach((value) => {
              if (value > this.max) this.max = value;
            });
            this.graphLoading = false;
            this.renderGraph();
          }
          });
    }

  }

  renderGraph() {
    let svgWidth: number = this.line.nativeElement.offsetWidth - 10;
    if (this.max == 0) this.max = 100;
    d3.select("#line").selectAll("svg").remove();
    var margin = { top: 10, right: 25, bottom: 40, left: 30 },
      // width = this.width,
      height = 250;
      const width = svgWidth - margin.left - margin.right-10;
    var svg = d3
      .select("#line")
      .append("svg")
      .attr("width", width + margin.left + margin.right - 15)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    var parseDate = d3.timeParse("%d-%m-%y");
    var i, n;
    let s = [];
    for (i = 0, n = this.data.length; i < n; i++) {
      var d = this.data[i];
      s[i] = parseDate(this.data[i]);

      for (var c in d) {
        if (c == "date") d[c] = parseDate(d[c]);
      }
    }

    var x = d3
      .scaleUtc()
      .domain(d3.extent(this.data, (d) => d.date))
      .range([0, width - margin.right]);
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(
        d3
          .axisBottom(x)
          .tickFormat(d3.timeFormat("%d %b"))
          .tickSizeOuter(0)
          .tickValues(this.data.map((d) => d.date))
          .tickSizeInner(0)
          .tickPadding(10)
          .ticks(20)
      );
    var y = d3
      .scaleLinear()
      .domain([0, this.max * 2])
      .range([height, 0]);
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        d3
          .axisLeft(y)
          .tickSizeOuter(0)
          .tickSizeInner(-(width - 5))
          .tickPadding(10)
          .tickFormat(function (d) {
            if (d / 1000 >= 1) {
              d = d / 1000 + "K";
            }
            return d;
          })
          .ticks(4)
      );

    svg
      .selectAll(".dot")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("id", "dot")
      .attr("cx", function (d, i) {
        return x(d.date);
      })
      .attr("cy", function (d) {
        return y(d.value);
      })
      .attr("r", 0)
      .style("stroke", "#ff5252")
      .style("display", "block")
      .style("fill", "#FFFFFF");

    d3.selectAll(".domain").style("stroke", "white");

    d3.selectAll(".y-axis line")
      .style("stroke", "rgba(34, 34, 34, 0.2)")
      .style("stroke-dasharray", "5 5");

    svg
      .append("path")
      .datum(this.data)
      .attr("fill", "#0183FB")
      .attr("stroke", "#0183FB")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .area()
          .x(function (d) {
            return x(d.date);
          })
          .y0(y(0))
          .y1(function (d) {
            return y(d.value);
          })
      );

    //     svg.append("linearGradient")
    // .attr("id", "mygrad")//id of the gradient
    // .attr("x1", "0%")
    // .attr("x2", "0%")
    // .attr("y1", "0%")
    // .attr("y2", "100%")//since its a vertical linear gradient
    // ;
    // svg.append("stop")
    // .attr("offset", "0%")
    // .style("stop-color", "red")//end in red
    // .style("stop-opacity", 1)

    // svg.append("stop")
    // .attr("offset", "100%")
    // .style("stop-color", "blue")//start in blue
    // .style("stop-opacity", 1)
    //     //const focus = svg.append("g").style("display", "none");

    //     //focus.append("circle").attr("r", "3px").style("fill", " #454546");

    //     // focus
    //     //   .append("line")
    //     //   .attr("x1", 20)
    //     //   .attr("y1", 5)
    //     //   .attr("x2", 0)
    //     //   .attr("y2", 0)
    //     //   .attr("stroke", " #454546")
    //     //   .style("transform", "rotate(270deg)");

    //     // svg
    //     //   .selectAll("g")
    //     //   .data(this.data)
    //     //   .enter()
    //     //   .append("g")
    //     //   .attr("transform", function (d, i) {
    //     //     return "translate(0," + i * 25 + ")";
    //   });

    // focus
    //   .append("rect")
    //   .attr("x", -40)
    //   .attr("y", -45)
    //   .attr("width", 100)
    //   .attr("height", 25)
    //   .attr("fill", "#454546")
    //   .attr("rx", 5);

    // var tooltip = d3
    //   .select("body")
    //   .append("div")
    //   .attr("class", "toolTip")
    //   .style("z-index:2");
    // svg
    //   .append("rect")
    //   .style("fill", "none")
    //   .style("pointer-events", "all")
    //   .attr("width", width)
    //   .attr("height", height)
    //   .on("mouseover", () => {
    //     focus.style("display", null);
    //   })
    //   .on("mouseout", () => {
    //     focus.style("display", "none");
    //   })
    //   .on("mousemove", mousemove);
    // const bisectDate = d3.bisector((d) => d.date).left;
    // let data = this.data;
    // const formatTime = d3.timeFormat("%d %b");
    // let graphData = this.selectedLineGraphData;
    // function mousemove() {
    //   let elements = document.querySelectorAll(".toolTip");
    //   elements.forEach((element) => {
    //     element.setAttribute("style", "display: none");
    //   });
    //   const x0 = x.invert(d3.mouse(this)[0]);
    //   const i = bisectDate(data, x0, 1);
    //   const d0 = data[i - 1];
    //   const d1 = data[i];
    //   if (d0 && d1) {
    //     d3.selectAll("g #tooltext").remove();
    //     const d = x0 - d0.date > d1.date - x0 ? d1 : d0;
    //     focus.attr("transform", `translate(${x(d.date)}, ${y(d.value)})`);
    //     focus
    //       .append("text")
    //       .attr("x", -30)
    //       .attr("y", -65 / 2)
    //       .attr("dy", ".35em")
    //       .attr("id", "tooltext")
    //       .attr("stroke", "white")
    //       .attr("fill", "white")
    //       .attr("stroke-width", 0.2)
    //       .style("font-size", 12)
    //       .text(
    //         formatTime(d.date) +
    //           " | " +
    //           (Math.round(d.value * 100) / 100).toFixed(0)
    //       );
    //   }
    // }
    // svg.on("mouseout", function (d) {
    //   tooltip.style("display", "none");
    //   let ele = document.getElementById("dot");
    //   ele.setAttribute("style", "display:none");
    // });
    // if (this.selectedGraphButton === "All Time")
    // d3.selectAll(".x-axis2 .tick text").each(function (_, i) {
    //   if (i % 3 !== 0) d3.select(this).remove();
    // });
    this.graphLoading = false;

    const parseTime = d3.timeParse("%Y-%m-%d");
    const bisectDate = d3.bisector((d) => d.date).left;
    const formatTime = d3.timeFormat("%d %b");

    const data = this.data;

    const focus = svg.append("g").style("display", "none");

    focus.append("circle").attr("r", "3px").style("fill", "#454546");

    // focus
    //   .append("circle")
    //   .attr("r", "6px")
    //   .style("fill", "#4abb59")
    //   .style("stroke", "#FFF")
    //   .style("stroke-width", "6px");

    var div = d3
      .select(this.chartContainer.nativeElement)
      .append("tooltip-div")
      .style("font-size", "12px")
      .style("display", "none")
      .style("position", "absolute")
      .style("pointer-events", "none")
      .style("text-align", "center")
      .style("color", "#FFFFFF");

    var div12 = div
      .append("div12")
      .style("padding", "8px 10px 8px 10px")
      .style("font-size", "11px")
      .style("background", "#454546")
      .style("border-radius", "4px")
      .style("color", "#FFFFFF");

    // var div2 = div
    //   .append("div2")
    //   .style("padding", "8px 10px 8px 0px")
    //   .style("font-size", "11px")
    //   .style("background", "#454546")
    //   .style("border-radius", "0px 4px 4px 0px");

    svg
      .append("rect")
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("width", width)
      .attr("height", height)
      .on("mouseover", () => {
        focus.style("display", null);
        div.style("display", "inline");
      })
      .on("mouseout", () => {
        focus.style("display", "none");
        div.style("display", "none");
      })
      .on("mousemove", mousemove);

    function mousemove() {
      const x0 = x.invert(d3.mouse(this)[0]);
      const i = bisectDate(data, x0, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      if (d0 && d1) {
        const d = x0 - d0.date > d1.date - x0 ? d1 : d0;
        focus.attr("transform", `translate(${x(d.date)}, ${y(d.value)})`);
        toolTip(d, focus);
      }
    }

    function toolTip(d, focus) {
      div.style("left", x(d.date) + "px").style("top", y(d.value) - 30 + "px");
      div12.text(formatTime(d.date) + " | " + d.value.toFixed(0));
      //div2.text(d.value.toFixed(0));
    }
    if (this.selectedGraphButton === "All Time")
      d3.selectAll(".x-axis .tick text").each(function (_, i) {
        if (i % 3 !== 0) d3.select(this).remove();
      });
  }
}
