import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AddToSuppressionListComponent } from '../add-to-suppression-list/add-to-suppression-list.component';
import { DeleteContactComponent } from '../delete-contact/delete-contact.component';
import { environment } from "src/environments/environment";
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { PopUpDialogService } from 'src/app/core/services/pop-up-dialog.service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-sms-suppression-list',
  templateUrl: './sms-suppression-list.component.html',
  styleUrls: ['./sms-suppression-list.component.css']
})
export class SmsSuppressionListComponent implements OnInit {


  @ViewChild('search') searchElement: ElementRef;
  domainsArray = [];
  numberOfSuppressedContacts = 0;
  suppressedContactsArray = [];
  type = 'All';
  requestBody;
  timeout;
  subscription: Subscription;
  searchvalue = "";
  length;
  pageSize = 10;
  pageIndex = 1;
  domain = '';
  contactsLoading = true;
  searching = false;
  noContact = false;
  defaultDomain = '';
  appSubscriptionPlan;
  mobileView;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  searchedValue = "";

  @HostListener("scroll", ["$event"])
onScroll(event: any) {
  if (this.mobileMedia.matches)
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.pageIndex += 1;
      if(this.searchvalue.length == 0)
      this.getSuppressedContacts();
      else
      this.searchSuppressedContacts(this.searchvalue);
    }
}

  constructor(private apiCall: ApiCallService,
              private tokenStorage: TokenStorageService,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private popup: PopUpDialogService,
              private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
              private _WidgetConfigService: WidgetConfigService,
              private snackbar: SnackbarCollection,
              private _i18nDynamicTranslate: TranslateDynamicText
              ) {
                window["screen-name"] = "SUPPRESSION LISTS";
              }

  ngOnInit() {
    this.getsmsSuppressionListDomain();
    this.getEnvironment();
    this.getWidgets();
  }

  async getWidgets(){
    this.appSubscriptionPlan =
    await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getsmsSuppressionListDomain() {
    this.apiCall.getsmsSuppressionListDomain().subscribe(response => {
      this.domainsArray =response['body'];
      this.domain=this.domainsArray[0].headerId;
      this.getSuppressedContacts();
    });
  }

  change() {
    this.pageIndex = 1;
    if(this.searchvalue.length == 0)
      this.getSuppressedContacts();
    else
      this.searchSuppressedContacts(this.searchvalue);
  }

  getSuppressedContacts() {
    this.searching = false;
    if(!this.mobileMedia.matches)
    this.contactsLoading = true;
    if(this.type !== 'All')
      this.requestBody = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "domainOrHeader": this.domain,
        "platform": this.tokenStorage.getMerchantOnlineStore(),
        "type": this.type,
        "channel":'SMS'
      };
    else
      this.requestBody = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "domainOrHeader": this.domain,
        "platform": this.tokenStorage.getMerchantOnlineStore(),
        "channel":'SMS'
      };
    this.apiCall.getSuppressedContacts(this.requestBody, this.pageSize, this.pageIndex).subscribe(response => {
      this.populateTable(JSON.parse(response['body']), "get");
      this.contactsLoading = false;
      if(this.suppressedContactsArray.length == 0 && !this.contactsLoading && !this.searching)
        this.noContact = true;
      else
        this.noContact = false;
    });
  }

  showSearch(){
    this.searchElement.nativeElement.focus();
  }

  onKeySearch(event,value) {
    clearTimeout(this.timeout);
    var $this = this;
    let searchValue2 = value.trim();
      this.timeout = setTimeout(function () {
        if (event.data !== ' ')
          $this.searchSuppressedContacts(searchValue2)
      }, 600);
    }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.searchSuppressedContacts(this.searchvalue);
  }

  getEnvironment() {
    if(environment.external === 'dev' || environment.external === 'qa')
      this.defaultDomain = 'testing.qubriux.com';
    else
      this.defaultDomain = "mail.qubriux.com";
    this.domain = this.defaultDomain;
  }

  searchSuppressedContacts(searchString) {
    this.searching = true;
    if(!this.mobileMedia.matches)
    this.contactsLoading = true;
    if(searchString !== this.searchvalue)
    this.pageIndex = 1;
    this.searchvalue = searchString;
    if(this.type !== 'All')
      this.requestBody = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "domainOrHeader": this.domain,
        "platform": this.tokenStorage.getMerchantOnlineStore(),
        "type": this.type,
        "channel":'SMS',
        "searchString": searchString
      };
    else
      this.requestBody = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "domainOrHeader": this.domain,
        "platform": this.tokenStorage.getMerchantOnlineStore(),
        "channel":'SMS',
        "searchString": searchString
      };
    if(searchString.length > 0) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.apiCall.searchSuppressedContacts(this.requestBody, this.pageSize, this.pageIndex).subscribe(response => {
        this.populateTable(JSON.parse(response['body']), "search");
        this.contactsLoading = false;
        if(this.suppressedContactsArray.length == 0 && !this.contactsLoading && !this.searching)
        this.noContact = true;
      else
        this.noContact = false;
      })
    } else
      this.getSuppressedContacts();
  }

  populateTable(response, action) {
    if(!this.mobileMedia.matches || this.pageIndex == 1)
      this.suppressedContactsArray = [];
        for (let key in response) {
          this.length = key;
          let value = response[key];
          value.forEach(element => {
            element.date = this.datePipe.transform(element.dateTime.split("T")[0], "dd MMM yyyy");
            element.time = element.dateTime.split("T")[1].split(":")[0] + ":" + element.dateTime.split("T")[1].split(":")[1]
            this.suppressedContactsArray.push(element);
          });
        }
  }

  async openAddContactDialog() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )){
        const dialogRef = this.dialog.open(AddToSuppressionListComponent, {
           data: {
          domain: this.domain,
          channel:'SMS'
        },
        panelClass: "custom-dialog-container",
        height: 'auto',
        width: 'auto',
        maxWidth: '400px',
         });
        dialogRef.afterClosed().subscribe(result => {
          if(result == undefined) {
            this.pageIndex = 1;
            this.getSuppressedContacts();
          }
        });
      }
  }

  openDeleteContactDialog(phnum, type,customerId) {
    let requestBody = {
      "merchantId": this.tokenStorage.getMerchantId(),
       "emailOrPhone": phnum,
      "domainOrHeader": this.domain,
      "type": type,
      "channel":'SMS',
      "custId":customerId,
      "platform": this.tokenStorage.getMerchantOnlineStore(),
    }
    const dialogRef2 = this.dialog.open(DeleteContactComponent, { data: {
      dataKey: requestBody,
      channel:'SMS'
    },
    panelClass: 'custom-dialog-container' });
    dialogRef2.afterClosed().subscribe(result => {
      if(result.length > 0) {
        this.apiCall.deleteEmailFromSuppressionList(requestBody).subscribe(response => {
          this.pageIndex = 1;
          this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform(JSON.parse(response['body']), ['POS_PAGE']), 1500 )
        this.getSuppressedContacts();
        })
      }
    },
    (error)=>{
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Deletion failed", ['POS_PAGE']), 1500 )
    });
  }
  dummyClick(){
    return 0;
  }

}

