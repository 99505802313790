import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SignoutService } from "src/app/core/services/signout.service";
import { ContactUsDialogComponent } from "src/app/shared/components/dialog-box";
import { PaymentPosRedirectComponent } from "../payment-pos-redirect/payment-pos-redirect.component";
import { getLogoUrlService } from "src/app/core/services/get-logo-url.service";

@Component({
  selector: "app-payment-pos",
  templateUrl: "./payment-pos.component.html",
  styleUrls: ["./payment-pos.component.css"],
})
export class PaymentPosComponent implements OnInit {
  @ViewChild("currencySelect1") currencySelect1: any;
  @ViewChild("currencySelect2") currencySelect2: any;
  @ViewChild("currencySelect3") currencySelect3: any;

  currency1 = "USD";
  currency2 = "USD";
  currency3 = "USD";
  currency = "USD";
  revCurrency;
  planDetails = [];
  cost = 123;
  region = "US";
  userName = "";
  isChangePlan = false;
  status = "";
  showLogout = false;
  isTrialPlan = false;
  planCancelled = false;
  contactsRange = [];
  selectedRange;
  allPlans = [];
  logoUrlIcon = this.getLogoService.getSideNavLogoIcon();

  constructor(
    private _dialog: MatDialog,
    private apicall: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private _signoutService: SignoutService,
    private getLogoService: getLogoUrlService
  ) {}

  ngOnInit(): void {
    this.userName = this.tokenStorage.getUsername();

    this._dialog.closeAll();
    this.getCurrentPlan();
    this.getPlanDetails();
  }

  getPlanDetails() {
    this.apicall.getPOSPlanDetails().subscribe((response) => {
      console.log("RESPONSE", response);
      this.contactsRange = this.getRangeKeys(response["body"]);
      this.allPlans = response["body"];
      // this.planDetails = Object.values(response["body"]);
      this.selectedRange = this.contactsRange[0];
      this.getPlanContactDetails();
    });
  }

  getCurrentPlan() {
    this.apicall.getCurrentPlanPos().subscribe((res) => {
      console.log(res["body"]["status"]);
      this.status = res["body"]["status"];
      this.isTrialPlan = res["body"]["trial"];
      this.planCancelled = res["body"]["planCancelled"];
      if (
        res["body"]["status"] === "ACTIVE" &&
        res["body"]["planCancelled"] !== true &&
        !res["body"]["trial"]
      ) {
        this.router.navigate(["data-ingestion/check"]);
      }
    });
  }

  selectOpenClose(value) {
    switch (value) {
      case "currencySelect1":
        this.currencySelect1.open();
        break;
      // case "currencySelect2":
      //   this.currencySelect2.open();
      //   break;
      // case "currencySelect3":
      //   this.currencySelect3.open();
    }
  }

  changeCurrencySelection(value) {
    switch (value) {
      case "currencySelect1":
        this.currency = this.currency1;
        if (this.currency === "USD") this.region = "US";
        else this.region = "IN";
        break;
      // case "currencySelect2":
      //   this.currency = this.currency2;
      //   if(this.currency === 'USD')
      //     this.region2 = 'US';
      //   else
      //     this.region2 = 'IN'
      //   break;
      // case "currencySelect3":
      //   this.currency = this.currency3;
    }
  }

  buyPlan(plan, value?) {
    console.log("VALUE", value);
    if (value !== "contactUs") {
      const dialogRef = this._dialog.open(PaymentPosRedirectComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "250px",
        height: "250px",
        data: {
          region: this.region,
          plan: plan,
          type: "planChange",
        },
        disableClose: true,
      });
    } else {
      // const dialogRef = this._dialog.open(ContactUsDialogComponent, {
      //   panelClass: "no-padding-dialog-popup",
      //   width: "250px",
      //   height: "250px",
      //   data: {
      //     header: "Contact Us",
      //     body: " "
      //   }
      // });
      window.open("https://qubriux.com/contact-us/");
    }
  }
  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.router.navigate(["auth/login"], {});
  }

  rangeChange(event) {
    console.log(event, this.selectedRange);
    this.planDetails = [];
    this.getPlanContactDetails();
  }

  getPlanContactDetails() {
    if (!this.selectedRange.toString().includes("+")) {
      const lastValue = "contacts" + this.selectedRange;
      this.planDetails.push(this.allPlans[lastValue]);
    }
    console.log(this.planDetails);
  }

  getRangeKeys(data) {
    const ranges = [];
    const contactKeys = Object.keys(data);

    for (let i = 0; i < contactKeys.length - 1; i++) {
      const currentContact = parseInt(contactKeys[i].replace("contacts", ""));
      ranges.push(currentContact);
    }

    // The last contact does not have a subsequent range, so it is handled separately
    const lastContact = parseInt(
      contactKeys[contactKeys.length - 1].replace("contacts", "")
    );
    ranges.push(lastContact + "+");

    return ranges;
  }
}
