<div class="qb-body">
  <div class="qb-paypal-div">
    <div class="qb-paypal-data">
      <div>
        <img
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/paypal.png"
        />
      </div>
      <div>
        <div>Shravya K</div>
        <div>shravyak@skellam.ai</div>
      </div>
    </div>
    <div class="primary-button">Disconnect PayPal</div>
  </div>
  <div class="qb-table-div">
    <div class="qb-table-header">
      <div class="qb-partners-list-div">
        <div>Payment History</div>
      </div>
      <div class="material-icons-outlined">filter_alt</div>
    </div>
    <div class="qb-table-head">
      <div class="qb-table-head-section qb-revenue-section">
        <div>Transaction Date</div>
        <div class="qb-arrow-div">
          <div class="material-icons-outlined">arrow_drop_up</div>
          <div class="material-icons-outlined qb-arrow-down">
            arrow_drop_down
          </div>
        </div>
      </div>
      <div class="qb-table-head-section qb-revenue-section">
        <div>Transaction Amount</div>
        <div class="qb-arrow-div">
          <div class="material-icons-outlined">arrow_drop_up</div>
          <div class="material-icons-outlined qb-arrow-down">
            arrow_drop_down
          </div>
        </div>
      </div>
      <div class="qb-table-head-section qb-partner-type-section">
        Charge Type
      </div>
      <div class="qb-table-head-section qb-action-section">
        Transaction Type
      </div>
    </div>
    <div *ngIf="false" class="qb-spinner-div">
      <mat-spinner diameter="15"></mat-spinner>
    </div>
    <div *ngIf="false" class="qb-no-data">No data available</div>
    <div>
      <div class="qb-table-content">
        <div class="qb-table-data-div">
          <div class="qb-table-data">1 Mar 2022 | 11:39 AM</div>
          <div class="qb-table-data">$1,000</div>
          <div class="qb-table-data revenue-data">Reward Given</div>
          <div class="qb-table-data qb-partner-type-data qb-expired-offer">
            Debit
          </div>
        </div>
      </div>
    </div>
    <div class="qb-paginate-div">
      <mat-paginator
        [pageIndex]="0"
        [length]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
  </div>
</div>
