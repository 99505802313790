import { HTTP_INTERCEPTORS, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { TokenStorageService } from "./token-storage.service";
import { retry, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";
import { MatSnackBarComponent } from "src/app/shared/components/mat-snack-bar/mat-snack-bar.component";
import { SignoutService } from "../services/signout.service";

const TOKEN_HEADER_KEY = "Authorization";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenStorageService,
    private snackBar: MatSnackBarComponent,
    private router: Router,
    private _signoutService: SignoutService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authReq = req;
    const token = this.token.getToken();
    const userId = this.token.getUserId();
    const userEmail = this.token.getUserEmail();
    const merchantId = this.token.getMerchantId();
    const qid = this.token.getqId();
    const storeName = this.token.getStoreName();
    if (token != null) {
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, "Bearer " + token)
      });
    }
    // Add custom headers
    if (userId != null) {
      authReq = authReq.clone({
        headers: authReq.headers.set('userId', userId),
      });
    }
    if (userEmail != null) {
      authReq = authReq.clone({
        headers: authReq.headers.set('userEmail', userEmail),
      });
    }
    if (merchantId != null) {
      authReq = authReq.clone({
        headers: authReq.headers.set('merchantId', merchantId),
      });
    }
    if (qid != null) {
      authReq = authReq.clone({
        headers: authReq.headers.set('qid', qid),
      });
    }
    if (storeName != null) {
      authReq = authReq.clone({
        headers: authReq.headers.set('storeName', storeName),
      });
    }
    return next.handle(authReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (
          error.status == 401 &&
          error.error.error == 'Unauthorized' && 
          error.error.message != "Bad credentials" &&
          error.error.message != "User is disabled"
        ) {
          this.router.navigate(["session-out"], {});
          this._signoutService.signOut();
          // localStorage.setItem('logout-event', 'logout' + Math.random());
          let snackBarRef = this.snackBar.openSnackBar(
            "Session expired ... Please login again!",
            "Close",
            "red-snackbar"
          );

          snackBarRef.afterDismissed().subscribe(() => {
            this.router.navigate(["/auth/login"], {});
            //   window.location.reload();
          });

          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
