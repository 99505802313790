import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, HostListener, OnDestroy, OnChanges } from "@angular/core";
import { DatePipe } from "@angular/common";
import { EChartsOption } from 'echarts';
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: "app-line-chart-homescreen",
  templateUrl: "./line-chart-homescreen.component.html",
  styleUrls: ["./line-chart-homescreen.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class LineChartHomescreenComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedGraphButton: string;
  @Input() selectedLineGraphData: string;
  @ViewChild("chartContainer") chartContainer: ElementRef;
  chartOptions: EChartsOption;
  array = [];
  data = [];
  max = 0;
  startDate: string;
  endDate: string;
  type: string;
  ylabel = "Revenue in Dollars";
  graphLoading = false;
  mobileMatch = window.matchMedia("(max-width: 896px)");
  sidenavSubject: Subject<void> = new Subject<void>();
  graphLabelStatus = true;
  private destroy$ = new Subject<void>();

  constructor(
    private apiCall: ApiCallService,
    private datePipe: DatePipe,
    private sidenavOperationService: SidenavOperationService
  ) {}

  ngOnInit() {
    this.getGraphByTime();
  }

  ngOnChanges() {
    this.sidenavSubject.pipe(
      debounceTime(1000),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.getGraphByTime();
      this.graphLabelStatus = true;
    });
    this.graphLabelStatus = true;

    this.sidenavOperationService.sidenavStatus.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.resetChart();
      this.sidenavSubject.next();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange() {
    this.graphLabelStatus = false;
    this.resetChart();
    this.sidenavSubject.next();
  }

  getGraphByTime() {
    this.data = [];
    this.array = [];
    if (this.selectedGraphButton === "All Time") {
      this.startDate = this.datePipe.transform(
        new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
        "yyyy-MM-dd"
      );
      this.endDate = this.datePipe.transform(
        new Date().toISOString(),
        "yyyy-MM-dd"
      );
      this.type = "WEEK";
    } else {
      this.startDate = this.datePipe.transform(
        new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString(),
        "yyyy-MM-dd"
      );
      this.endDate = this.datePipe.transform(
        new Date(Date.now()).toISOString(),
        "yyyy-MM-dd"
      );
      this.type = "DAY";
    }
    if (this.selectedLineGraphData === "my customers") {
      this.getCustomersGraphStats();
    } else {
      this.getRevenueGraphStats();
    }
  }

  getCustomersGraphStats() {
    this.ylabel = "No of Customers";
    this.array = [];
    this.data = [];
    this.max = 0;
    if (this.graphLoading) {
      return;
    }
    this.graphLoading = true;
    this.apiCall.getGraphStats(this.startDate, this.endDate, this.type).subscribe((response) => {
      this.graphLoading = false;

      Object.keys(response["body"]["all customers"]["dataSet"]).map((key) => {
        this.array.push(response["body"]["all customers"]["dataSet"][key]);
        this.data.push({
          date: this.datePipe.transform(new Date(key.substring(0, 10)), "dd-MM-yy"),
          value: response["body"]["all customers"]["dataSet"][key],
        });
      });
      this.array.forEach((value) => {
        if (value > this.max) this.max = value;
      });
      this.graphLoading = false;
      this.setChartOptions();
    });
  }

  getRevenueGraphStats() {
    this.ylabel = "Revenue";
    this.array = [];
    this.data = [];
    this.max = 0;
    if (this.graphLoading) {
      return;
    }
    this.graphLoading = true;
    this.apiCall.getRevenueGraph(this.startDate, this.endDate, this.type).subscribe((response) => {
      this.graphLoading = false;
      Object.keys(response["body"]["dataSet"]).map((key) => {
        this.array.push(response["body"]["dataSet"][key]);
        this.data.push({
          date: this.datePipe.transform(new Date(key.substring(0, 10)), "dd-MM-yy"),
          value: response["body"]["dataSet"][key],
        });
      });
      this.array.forEach((value) => {
        if (value > this.max) this.max = value;
      });

      this.setChartOptions();
    });
  }

  setChartOptions() {
    const xValues = this.data.map(d => d.date);
    const yValues = this.data.map(d => d.value);

    this.chartOptions = {
      grid: {
        left: '7%',  
        top: '10%',   
        bottom: '10%',
        containLabel: true 
      },

      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        name: 'Days',
        type: 'category',
        data: xValues,
        nameLocation: "middle",
        nameTextStyle: {
          padding: [20, 0, 0, 0]
        }
      },
      yAxis: {
        name: this.ylabel,
        type: 'value',
        nameLocation: "middle",
        nameTextStyle: {
        padding: [0, 0, 60, 0]
        }
      },
      series: [
        {
          data: yValues,
          type: 'line'
        }
      ]
    };
  }

  resetChart() {
    this.chartOptions = {};
  }
}
