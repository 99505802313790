import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-line-chart-with-tooltip',
  templateUrl: './line-chart-with-tooltip.component.html',
  styleUrls: ['./line-chart-with-tooltip.component.css']
})
export class LineChartWithTooltipComponent implements OnInit {

  constructor() { this.getScreenSize() }

  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Input() data;

  scrHeight;
  scrWidth;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // if(this.data && this.data.length > 0){
    //   this.drawChart();
    // }
  }


  ngOnInit() {

    // let interval = setInterval(()=>{
    //   if(this.data.length > 0){
    //     this.drawChart()
    //     clearInterval(interval)
    //   }
    // },200)

  }

  ngOnChanges() {
    if(this.data.length > 0){
      this.drawChart()
    }
  }

  drawChart() {

    let dataInit = this.data;

    d3.select(this.chartContainer.nativeElement).selectAll("*").remove();

    const margin = { top: 20, right: 30, bottom: 20, left: 30 };
    const width = this.chartContainer.nativeElement.offsetWidth - margin.left - margin.right;
    const height = (this.chartContainer.nativeElement.offsetWidth / 2) - margin.top - margin.bottom;

    const parseTime = d3.timeParse('%Y-%m-%d');
    const bisectDate = d3.bisector(d => d.date).left;
    const formatTime = d3.timeFormat("%d-%m-%Y");
    // const formatValue = d3.format(',.2f');
    // const formatCurrency = d => `$${formatValue(d)}`;

    d3.select(this.chartContainer.nativeElement)
      .style('font', '10px sans-serif')

    const x = d3.scaleTime()
      .range([0, width]);

    const y = d3.scaleLinear()
      .range([height, 0]);

    const line = d3.line()
      .x((d) => { return x(d.date); })
      .y((d) => { return y(d.close); })
      .curve(d3.curveLinear);

    const svg = d3.select(this.chartContainer.nativeElement).append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);


    dataInit.forEach(function (d) {
      d.date = parseTime(d.date);
      d.close = +d.close;
    });

    const data = dataInit

    //if (error) throw error;

    //data.sort((a, b) => a.date - b.date);

    x.domain([data[0].date, data[data.length - 1].date]);
    y.domain(d3.extent(data, d => d.close));

    svg.append('g')
      .attr('class', 'x axis axis--x')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x)
        .ticks(3)
        .tickFormat(d3.timeFormat("%d-%m-%Y")))
      .style("fill", "#8787871c");

    svg.append('g')
      .attr('class', 'y axis axis--y')
      .call(d3.axisLeft(y)
        .ticks(3)
        .tickSize(0))
      .style("fill", "#8787871c");

    // style the axes
    d3.selectAll('.axis path')
      .style('fill', 'none')
      .style('stroke', '#000');

    d3.selectAll('.axis line')
      .style('fill', 'none')
      .style('stroke', '#000');

    d3.selectAll('.axis--x path')
      .style('display', 'none');

    svg.append("g")
      .attr("class", "grid")
      .style("color", "#eeeeee")
      .style("stroke-width", "1px")
      .style("shape-rendering", "crispEdges")
      .call(make_y_gridlines(y)
        .ticks(3)
        .tickSize(-width)
        .tickFormat("")
      )

    svg.append('path')
      .datum(data)
      .attr("class", "line")
      .style("fill", "none")
      .style("stroke", "#3ccbed")
      .style("stroke-width", "2px")
      .attr('d', line);


    svg.selectAll(".dot")
      .data(data)
      .enter().append("circle")
      .attr("class", "dot")
      .attr("cx", function (d, i) { return x(d.date) })
      .attr("cy", function (d) { return y(d.close) })
      .attr("r", 2)
      .style("stroke", "#ff5252")
      .style("fill", "#FFFFFF");

    const focus = svg.append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    focus.append('circle')
      .attr('r', 5)
      .style("stroke", "#08b4b9")
      .style("fill", "#08b4b9");;

    focus.append('line')
      .classed('x', true)
      .style('fill', 'none')
      .style('stroke', '#e8e8e8')
      .style('stroke-width', '1.5px');

    focus.append('line')
      .classed('y', true)
      .style('fill', 'none')
      .style('stroke', '#e8e8e8')
      .style('stroke-width', '1.5px');

    // focus.append('text')
    //   .attr('x', 9)
    //   .attr('dy', '.35em');

    svg.append('rect')
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => { focus.style('display', null); div.style("display", "inline"); })
      .on('mouseout', () => { focus.style('display', 'none'); div.style("display", "none"); })
      .on('mousemove', mousemove);

    // d3.selectAll('.line')
    //   .style({
    //     'fill': 'none',
    //     'stroke': 'steelblue',
    //     'stroke-width': '1.5px'
    //   });

    // d3.select('.overlay')
    //   .style({
    //     'fill': 'none',
    //     'pointer-events': 'all'
    //   });

    d3.selectAll('.focus')
      .style('opacity', 0.7);

    d3.selectAll('.focus circle')
      .style({
        'fill': 'none',
        'stroke': 'black'
      });

    d3.selectAll('.focus line')
      .style({
        'fill': 'none',
        'stroke': 'black',
        'stroke-width': '1.5px'
      });

    var div = d3.select(this.chartContainer.nativeElement).append("div")
      // .style('width', '60px')
      // .style('height', '12px')
      .style('font', '10px sans-serif')
      .style("display", "none")
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('text-align', 'center');

    var div1 = div.append("div1")
    .style('padding', '10px')
    .style('border', '1px solid #FFFFFF')
    .style('background', '#e2ecff')
    .style('border-radius', '4px');

    var div2 = div.append("div2")
    .style('padding', '10px')
    .style('border', '1px solid #FFFFFFF')
    .style('background', '#e2ecff')
    .style('border-radius', '4px');

    function mousemove() {
      const x0 = x.invert(d3.mouse(this)[0]);
      const i = bisectDate(data, x0, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      const d = x0 - d0.date > d1.date - x0 ? d1 : d0;
      focus.attr('transform', `translate(${x(d.date)}, ${y(d.close)})`);
      focus.select('line.x')
        .attr('x1', 0)
        .attr('x2', -x(d.date))
        .attr('y1', 0)
        .attr('y2', 0);

      focus.select('line.y')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', 0)
        .attr('y2', height - y(d.close));

      toolTip(d)

      //focus.select('text').text(d.close)
      //   svg.append('text').text(d.close).attr({
      //      x: function() { return x(d.x) - 30; },
      //      y: function() { return y(d.y) - 15; }
      //  })
    }

    function toolTip(d) {
      div.style("left", (d3.event.pageX - 24) + "px")
        .style("top", (d3.event.pageY - 24) + "px");
      div1.text(d.close)
      div2.text(formatTime(d.date))
    }

    function make_y_gridlines(y) {
      return d3.axisLeft(y)
        .ticks(5)
    }

    // function type(d) {
    //   d.date = parseTime(d.date);
    //   d.close = +d.close;
    //   return d;
    // }
  }
}
