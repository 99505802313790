import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsentRecievedComponent } from './pages';

const routes: Routes = [
    // { path: "", redirectTo: "consent-recieved", pathMatch: "full" },
    { 
      path: "", 
      component: ConsentRecievedComponent, 
      data: {
        titleheading: "Consent Received",
        title: "Consent Recieved",
        minPath: "Basic"
      },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsentRecievedRoutingModule { }
