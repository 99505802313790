<head>
    <meta charset="utf-8">
      <title> Marketing Automation Platform | Hyper-Personalized Marketing Platform </title>
      <meta name="description" content="Onboarding">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
<div [style.height.px]="scrContentHeight" *ngIf = "apiloading">
    <div style="height:61px; background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000000F;
    opacity: 1; width:100%; display:flex; justify-content: flex-end;">
        <div style="display: flex;flex-direction: row;align-items: center; justify-content:space-between;">
            <!-- <div><span *ngIf="userName"
          style="text-align: left; font: 100 16px Gravity; letter-spacing: 0; color: #BEBEBE; opacity: 1; user-select: none; font-family:'Open sans';">{{userName}}</span>
      </div> -->
            <div class="headerIcons2 ">
                <div class="headerUserIcon gradient " [matMenuTriggerFor]="menu " style="cursor:pointer; ">
                    <span style="font-family:'Open sans';" *ngIf="userName">{{userName[0]}}</span>
                </div>
            </div>
            <mat-menu #menu="matMenu">
                <div class="logout-button" (click)="logout() ">Logout</div>
            </mat-menu>
        </div>
    </div>
        <div style="height: 100%; display: flex; justify-content: center; align-items: center;">
            <span style="font-family:'Open sans'; font-size: 25px; color: #c8c8c8;">Loading..</span>
            <mat-spinner diameter="25"></mat-spinner>
        </div>
</div>
