<div class="page-container">
  <div class="padding-top-13 pos-rel">
    <button class="primary-button profile-change-image-button upload-button button-hover-effect"
    (click)="uploadCSV()" >
    <span class="material-symbols-outlined">
      upload
    </span>
    Upload Data
    </button>
    <div class="search-container">
      <input
        #search
        type="text"
        placeholder="Search Leads"
        (input)="onKeySearch(search.value, true)"
      />
      <span
        class="material-icons icons-primary huge-icon"
        *ngIf="search.value.length == 0"
      >
        search
      </span>
      <span
        class="material-icons icons-primary cursor-pointer huge-icon"
        *ngIf="search.value.length > 0"
        (click)="
          search.value = ''; onKeySearch(search.value, true); searchValue = ''
        "
      >
        close
      </span>
    </div>
  </div>

  <div class="total-customer-count">
    Total Leads Count : <span class="count-text">{{ totalCustomers }}</span>
  </div>
  <div class="main-list-container-2 pos-rel" *ngIf="validWidgets">
    <div class="list-column-header-row-2" #headerContainer>
      <div class="col-header">Name</div>
      <div class="col-header wide-column-width">Email</div>
      <div class="col-header medium-col-width">Phone</div>
      <div class="col-header">Customer Tag</div>
      <div class="col-header">Store</div>
    </div>
    <div class="main-list-2" #contentContainer>
      <div class="list-loader-container" *ngIf="loading">
        <app-qubriux-loading
          [size]="65"
          [loadingText]="'Fetching your customers...'"
        ></app-qubriux-loading>
      </div>
      <div
        *ngIf="!loading && customers.length == 0"
        class="empty-list-indicator"
      >
        No Leads Found
      </div>
      <div *ngIf="!loading && customers.length > 0">
        <div *ngFor="let customer of customers" class="list-item-row-2">
          <div class="list-item">
            <div
              class="list-item-content"
              [matTooltip]="customer.leadName"
              *ngIf="searchValue.length === 0; else customerName"
            >
              {{ customer["leadName"] || "----" }}
            </div>
            <ng-template #customerName>
              <div class="list-item-content" [matTooltip]="customer.leadName">
                {{ customer["customerName"] || "----" }}
              </div>
            </ng-template>
          </div>
          <div class="list-item">
            <div
              class="list-item-content"
              [matTooltip]="customer.emailAddress"
              *ngIf="searchValue.length === 0; else email"
            >
              {{ customer["emailAddress"] || "----" }}
            </div>
            <ng-template #email>
              <div class="list-item-content" [matTooltip]="customer.email">
                {{ customer["email"] || "----" }}
              </div>
            </ng-template>
          </div>
          <div class="list-item medium-col-width">
            <div
              class="list-item-content"
              [matTooltip]="customer.phoneNumber"
              *ngIf="searchValue.length === 0; else phone"
            >
              {{ customer["phoneNumber"] || "----" }}
            </div>
            <ng-template #phone>
              <div class="list-item-content" [matTooltip]="customer.phone">
                {{ customer["phone"] || "----" }}
              </div>
            </ng-template>
          </div>
          <div class="list-item">
            <div
              *ngIf="
                customer?.customerTag === undefined ||
                  customer?.customerTag === null ||
                  customer?.tagValue?.length === 0;
                else customerTagList
              "
            >
              ----
            </div>
            <ng-template #customerTagList>
              <div class="mini-list-item">{{ customer["tagValue"][0] }}</div>
              <div
                *ngIf="customer['tagValue']?.length > 1"
                [matMenuTriggerFor]="menu"
                class="see-more"
                (click)="$event.stopPropagation()"
              >
                See All {{ customer["tagValue"].length }} Tags<span
                  class="material-symbols-outlined"
                  >keyboard_double_arrow_right</span
                >
              </div>
              <mat-menu #menu="matMenu" class="custom-menu">
                <div
                  *ngFor="let customerTag of customer['tagValue']"
                  class="mini-list-item"
                >
                  {{ customerTag }}
                </div>
              </mat-menu>
            </ng-template>
          </div>
          <div class="list-item" *ngIf="!(validWidgets | widgetConfig: 72)">
            <div *ngIf="!customer['stores'] || customer['stores']?.length==0;else storeList">
              ----
            </div>
            <ng-template #storeList>
              <div class="mini-list-item-1">{{customer['stores'][0]}}</div>
              <div *ngIf="customer['stores']?.length>1" [matMenuTriggerFor]="menu" class="see-more" (click)="$event.stopPropagation()">See All {{customer['stores'].length}} stores<span class="material-symbols-outlined">keyboard_double_arrow_right</span></div>
              <mat-menu #menu="matMenu"  class="custom-menu">
                <div *ngFor="let storeName of customer['stores']" class="mini-list-item-1">
                  {{storeName}}
                </div>
              </mat-menu>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="lv-options-2">
      <mat-paginator
        [pageIndex]="pageIndex - 1"
        [length]="totalCustomers"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="refreshPaginatedData($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
