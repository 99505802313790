import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, ɵConsole } from '@angular/core';
import * as d3 from 'd3';
import { ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-multi-line-chart-tt',
  templateUrl: './multi-line-chart-tt.component.html',
  styleUrls: ['./multi-line-chart-tt.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MultiLineChartTtComponent implements OnInit {

  constructor() { this.getScreenSize() }

  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Input() data;

  scrHeight;
  scrWidth;
  csvData;
  colors = ["#ff5f31", "#880e4f", "#c150ff", "#4cb9ea", "#4bd762", "#07b6b6", "#f03e62", "#5aaefd"]
  width;
  height;
  finalData;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.formatData();
    // if(this.data && this.data.length > 0){
    //   this.drawChart();
    // }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.formatData();
  }

  formatData(){
    let interval1 = setInterval(() => {
      if (this.data && this.data.length > 0) {
        clearInterval(interval1)
        this.finalData = d3.csvParse(this.data)
        this.drawChart(this.finalData)
        //d3.csv(this.data).then(d => this.drawChart(d))
      }
    }, 100);
  }

  drawChart(data) {
    var keys = data.columns.slice(0);
    var parseTime = d3.timeParse("%Y%m%d"),
      formatDate = d3.timeFormat("%Y-%m-%d"),
      bisectDate = d3.bisector(d => d.date).left,
      formatValue = d3.format(",.0f");

    data.forEach(function (d) {
      d.date = parseTime(d.date);
      return d;
    })

    var svg1 = d3.select(this.chartContainer.nativeElement).selectAll("*").remove(),
      margin = { top: 5, right: 2, bottom: 0, left: 2 },
      width = this.chartContainer.nativeElement.offsetWidth - margin.left - margin.right,
      height = this.chartContainer.nativeElement.offsetHeight - margin.top - margin.bottom;
    svg1 = d3.select(this.chartContainer.nativeElement);

    let tooltipdiv = d3.select(this.chartContainer.nativeElement).append('div')
      .attr('class', 'tooltipclass')

    let svg = svg1.append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    var x = d3.scaleTime()
      .rangeRound([margin.left, width - margin.right])
      .domain(d3.extent(data, d => d.date))

    var y = d3.scaleLinear()
      .rangeRound([height - margin.bottom, margin.top]);

    var z = d3.scaleOrdinal(d3.schemeCategory10);

    var line = d3.line()
      .curve(d3.curveMonotoneX)
      .x(d => x(d.date))
      .y(d => y(d.degrees));

    svg.append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + (height - margin.bottom) + ")")
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%m/%d")).ticks(5))
      .call(g => g.select(".domain").remove())
      .style("display", "none");

    svg.append("g")
      .attr("class", "y-axis")
      .style('opacity', '0')
      .attr("transform", "translate(" + margin.left + ",0)");

    svg.append("rect")
      .attr("x", margin.left)
      .attr("width", width - margin.right - margin.left)
      .attr("height", height - margin.bottom)
      .attr("fill", "#edf0fa");

    var focus = svg.append("g")
      .attr("class", "focus")
      .style("display", "none");

    focus.append("line").attr("class", "lineHover")
      .style("stroke", "#999")
      .attr("stroke-width", 1)
      .style("shape-rendering", "crispEdges")
      .style("opacity", 0.5)
      .attr("y1", -height)
      .attr("y2", -margin.bottom);

    var overlay = svg.append("rect")
      .attr("class", "overlay")
      .attr("x", margin.left)
      .attr("width", width - margin.right - margin.left)
      .attr("height", height - margin.bottom - margin.top)
      .attr('fill', 'none')
      .attr('pointer-events', 'all');

    update('_1', 0);

    function update(input, speed) {
      var copy = keys.filter(f => f !== 'date');
      var cities = copy.map(function (id) {
        return {
          id: id,
          values: data.map(d => { return { date: d.date, degrees: +d[id] } })
        };
      });

      y.domain([
        d3.min(cities, d => d3.min(d.values, c => c.degrees)),
        d3.max(cities, d => d3.max(d.values, c => c.degrees))
      ]).nice();

      svg.selectAll(".y-axis").transition()
        .duration(speed)
        .call(d3.axisLeft(y).tickSize(-width + margin.right + margin.left))

      var city = svg.selectAll(".cities")
        .data(cities);

      city.exit().remove();

      city.enter().insert("g", ".focus").append("path")
        .attr("class", "line cities")
        .attr('fill', 'none')
        .attr('stroke-width', 2)
        .style('opacity', 0.75)
        .style("stroke", function (d, i) { return z(i) })
        .merge(city)
        .transition().duration(speed)
        .attr("d", d => line(d.values))

      tooltip(keys);
    }

    function tooltip(copy) {

      var labels = focus.selectAll(".lineHoverText")
        .data(copy)

      labels.enter().append("text")
        .attr("class", "lineHoverText")
        .style("fill", d => z(d))
        .attr("text-anchor", "start")
        .attr("font-size", 12)
        .attr("dy", (_, i) => 1 + i * 2 + "em")
        .merge(labels);

      var circles = focus.selectAll(".hoverCircle")
        .data(copy)

      circles.enter().append("circle")
        .attr("class", "hoverCircle")
        .style("fill", d => z(d))
        .attr("r", 2.5)
        .merge(circles);

      svg.selectAll(".overlay")
        .on("mouseover", mOver)
        .on("mouseout", mOut)
        .on("mousemove", mousemove);


      function mOver() {
        focus.style("display", null);
        var x0 = x.invert(d3.mouse(this)[0]),
          i = bisectDate(data, x0, 1),
          d0 = data[i - 1],
          d1 = data[i],
          d = x0 - d0.date > d1.date - x0 ? d1 : d0;

        let dataArray = tooltipData(d)
        tooltipdiv.style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 24) + "px");
        tooltipdiv.append('div').html("<span style='font-weight:bold;'>" + dataArray.date + "</span>");
        let color = "#4abb59"
        for (let i = 0; i < d1.length; i++) {
          tooltipdiv.append('div').html("<span>" + dataArray.data[i]['key'] + "-" + dataArray.data[i]['value'] + "</span>")
            .style('color', d => z(i));;
        }
      }

      function mOut() {
        focus.style("display", "none");
        tooltipdiv.html(null)
          .style('display', 'none')
      }
      function mousemove() {
        var x0 = x.invert(d3.mouse(this)[0]),
          i = bisectDate(data, x0, 1),
          d0 = data[i - 1],
          d1 = data[i],
          d = x0 - d0.date > d1.date - x0 ? d1 : d0;

        let dataArray = tooltipData(d)

        focus.select(".lineHover")
          .attr("transform", "translate(" + x(d.date) + "," + height + ")");

        focus.selectAll(".hoverCircle")
          .attr("cy", e => y(d[e]))
          .attr("cx", x(d.date));

        tooltipdiv.html(null)
          .style('display', 'none')
        tooltipdiv.style("left", (d3.event.pageX - 60) + "px")
          .style("top", (d3.event.pageY + 20) + "px")
          .style("width", "120px");
        tooltipdiv.append('div').html("<span style='font-weight:bold;'>" + dataArray.date + "</span>");
        let color = "#4abb59"
        for (let i = 0; i < dataArray.data.length; i++) {
          tooltipdiv.append('div').html("<span>" + dataArray.data[i]['key'] + "-" + dataArray.data[i]['value'] + "</span>")
            .style('color', d => z(i));
          tooltipdiv.style('display', 'block')
        }
      }

      function tooltipData(data) {
        let d = [];
        for (let key in data) {
          if (key !== 'date') {
            d.push({ 'key': key, 'value': data[key] })
          }
        }
        var datePipe = new DatePipe('en-US');
        let fd = {
          'date': datePipe.transform(data['date'], 'yyyy-MM-dd'),
          'data': d
        }
        return fd;
      }

      function mousemove1() {

        var x0 = x.invert(d3.mouse(this)[0]),
          i = bisectDate(data, x0, 1),
          d0 = data[i - 1],
          d1 = data[i],
          d = x0 - d0.date > d1.date - x0 ? d1 : d0;

        focus.select(".lineHover")
          .attr("transform", "translate(" + x(d.date) + "," + height + ")");

        focus.selectAll(".hoverCircle")
          .attr("cy", e => y(d[e]))
          .attr("cx", x(d.date));

        // focus.selectAll(".lineHoverText")
        //   .attr("transform",
        //     "translate(" + (x(d.date)) + "," + 0 + ")")
        //   .text(e => e + " - " + d[e])
        //   .style('text-shadow', '-2px -2px 0 #edf0fa, 2px -2px 0 #edf0fa, -2px 0px 0 #edf0fa, 2px 2px 0 #edf0fa');

        // x(d.date) > (width - width / 4)
        //   ? focus.selectAll("text.lineHoverText")
        //     .attr("text-anchor", "end")
        //     .attr("dx", -10)
        //   : focus.selectAll("text.lineHoverText")
        //     .attr("text-anchor", "start")
        //     .attr("dx", 10)


      }
    }

    var selectbox = d3.select("#selectbox")
      .on("change", function () {
        update(this.value, 750);
      })
  }

}
