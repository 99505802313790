<div class="qb-social-media-insights-container">
  <div class="qb-sm-logo-select-panel">
    <div class="qb-flex-center ">
      <div class="qb-sm-logo-select-panel-logo" (click)="selectedTab = 'facebook'; getaccounts()">
        <div [ngStyle]="{'border-bottom': (selectedTab=='facebook') ? '2px solid #0183FB' : 'none'}">  <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="19.9999" cy="20" r="16.6667" fill="#3B5998" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.4291 14.1315C23.9097 14.0276 23.2081 13.95 22.7669 13.95C21.5722 13.95 21.4946 14.4694 21.4946 15.3006V16.7801H24.4811L24.2207 19.8448H21.4946V29.1667H17.7552V19.8448H15.8333V16.7801H17.7552V14.8844C17.7552 12.2878 18.9756 10.8333 22.0397 10.8333C23.1042 10.8333 23.8834 10.9892 24.896 11.1969L24.4291 14.1315Z"
            fill="white"
          />
        </svg></div>
        <!-- <img src="assets/images/social-media-logo.png" alt=""> -->
      </div>
     
    <!-- </div> -->

    <!-- <div class="qb-flex-center "> -->
      <div class="qb-sm-logo-select-panel-logo" (click)="selectedTab = 'instagram'; getaccounts()">
        <div [ngStyle]="{'border-bottom': (selectedTab=='instagram') ? '2px solid #0183FB' : 'none'}">  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><radialGradient id="yOrnnhliCrdS2gy~4tD8ma" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"/><stop offset=".328" stop-color="#ff543f"/><stop offset=".348" stop-color="#fc5245"/><stop offset=".504" stop-color="#e64771"/><stop offset=".643" stop-color="#d53e91"/><stop offset=".761" stop-color="#cc39a4"/><stop offset=".841" stop-color="#c837ab"/></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"/><radialGradient id="yOrnnhliCrdS2gy~4tD8mb" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"/><stop offset=".999" stop-color="#4168c9" stop-opacity="0"/></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"/><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"/><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"/><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"/></svg></div>
        <!-- <img src="assets/images/social-media-logo.png" alt=""> -->
      </div>
     
    </div>
    <!-- </div> -->

    <div>
      <!-- *date Filter -->
    </div>
  </div>




  <div class="qb-flex qb-w-100 qb-flex-1">
    <div class="qb-w-15 qb-wht-bk"  *ngIf="!(fbAccounts.length==0&&isNoAccountDetected)">

      <div class="qb-social-light-text qb-flex" *ngIf="selectedTab=='facebook'"><hr> {{'COMMUNICATION_PAGE.FACEBOOK_INSIGHT' | i18nTranslate}} <hr></div>
      <div class="qb-social-light-text qb-flex" *ngIf="selectedTab=='instagram'"><hr> {{'COMMUNICATION_PAGE.INSTAGRAM_INSIGHT' | i18nTranslate}} <hr></div>
      <div *ngIf="selectedTab == 'facebook'">
      <div *ngFor="let page of fbAccounts" class="qb-account-block qb-p-16 cursor-pointer" [ngClass]="{'active' : selectedAccount === page.userId}" (click)="selectAccount(page)">
        {{page.name}}
      </div>
      </div>
      <div *ngIf="selectedTab == 'instagram'">
        <div *ngFor="let page of fbAccounts" class="qb-account-block qb-p-16 cursor-pointer" [ngClass]="{'active' : selectedAccount === page.id}" (click)="selectAccount(page)">
          {{page.username}}
        </div>
      </div>
    </div>
 <div class="qb-w-85 qb-set-85" *ngIf="!(fbAccounts.length==0&&isNoAccountDetected)">
  <div class="qb-account-block-spinner qb-all-100" *ngIf="loading&&fbAccounts.length<1"> 
    <mat-spinner diameter="15"></mat-spinner>
       </div>
  <div class="qb-analytics-container" *ngIf="fbAccounts.length>0">

    <div class="qb-audience-card qb-audience-summary-card">

      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.AUDIENCE_SUMMARY' | i18nTranslate}}</div>
      <div class="qb-account-block-spinner qb-all-100" *ngIf="loading"> 
   <mat-spinner diameter="15"></mat-spinner>
      </div>
    <div class="qb-flex" *ngIf="!loading">

      <div class="qb-diplay-flex-column qb-r-b qb-w-33">
        <div class="qb-social-card-value-title" *ngIf="selectedTab=='facebook'">{{'COMMUNICATION_PAGE.TOTAL_PAGE_LIKES' | i18nTranslate}}</div>
        <div class="qb-social-card-value-title" *ngIf="selectedTab=='instagram'">{{'COMMUNICATION_PAGE.TOTAL_FOLLOWERS' | i18nTranslate}}</div>
        <div class="qb-social-card-value-blue">{{insightsSummary.total_page_likes}}</div>

      </div>
      <div class="qb-diplay-flex-column qb-w-33" [ngStyle]="{'border-right': (selectedTab=='facebook') ? '1px solid #E6E6E6' : 'none'}">
        <div class="qb-social-card-value-title" *ngIf="selectedTab=='facebook'">{{'COMMUNICATION_PAGE.NEW_LIKES' | i18nTranslate}}</div>
        <div style="display: flex;" *ngIf="selectedTab=='instagram'">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.NEW_FOLLOWERS' | i18nTranslate}}</div>
        <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentImpression">
            <ng-template #popContentImpression class="qb-background-ng-template">
              <div class="qb-ng-template-text">
                <div class="qb-d-inline-flex">{{'COMMUNICATION_PAGE.NEW_FOLLOWERS_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
        <div class="qb-social-card-value-blue">{{insightsSummary.new_likes}}</div>
        <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.AVG_PER_DAY' | i18nTranslate}}: <strong>{{insightsSummary.new_likes_avg}}</strong></div>

      </div>
      <div class="qb-diplay-flex-column qb-w-33" *ngIf="selectedTab=='facebook'">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.LIKES_LOST' | i18nTranslate}}</div>
        <div class="qb-social-card-value-blue">{{insightsSummary.likes_lost}}</div>
        <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.AVG_PER_DAY' | i18nTranslate}}: <strong>{{insightsSummary.likes_lost_avg}}</strong></div>
      </div>
    </div>
    </div>
    <div class="qb-audience-card qb-post-engangement-summary-card">
      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.POST_ENGAGEMENT_SUMMARY' | i18nTranslate}}</div>
      <div class="qb-account-block-spinner qb-all-100" *ngIf="loading"> 
        <mat-spinner diameter="15"></mat-spinner>
           </div>
         <div class="qb-flex" *ngIf="!loading">
      <div class="qb-diplay-flex-column qb-r-b qb-w-33">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.TOTAL_POST' | i18nTranslate}}</div>
        <div class="qb-social-card-value-blue">{{insightsSummary.total_post}}</div>
        <!-- <div class="qb-social-card-subtitle">Avg. per day: <strong>{{insightsSummary.total_post_avg}</strong></div> -->

      </div>
      <div class="qb-diplay-flex-column qb-w-33">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.TOTAL_ENGAGEMENT' | i18nTranslate}}</div>
        <div class="qb-social-card-value-blue">{{insightsSummary.total_engagement}}</div>
        <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.AVG_PER_DAY' | i18nTranslate}}: <strong>{{insightsSummary.total_engagement_avg}}</strong></div>
      </div>

      </div>
    </div>   
    <div class="qb-audience-card qb-performance-summary-card">
      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.PERFORMANCE_SUMMARY' | i18nTranslate}}</div>
      <div class="qb-account-block-spinner qb-all-100" *ngIf="loading"> 
        <mat-spinner diameter="15"></mat-spinner>
           </div>
         <div class="qb-flex" *ngIf="!loading">
      <div class="qb-diplay-flex-column qb-r-b qb-w-33">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.PAGE_VIEWS' | i18nTranslate}}</div>
        <div class="qb-social-card-value-blue">{{insightsSummary.page_profile_view}}</div>
        <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.AVG_PER_DAY' | i18nTranslate}}: <strong>{{insightsSummary.page_profile_view_avg}}</strong></div>

      </div>
      <div class="qb-diplay-flex-column qb-w-33" [ngStyle]="{'border-right': (selectedTab=='facebook') ? '1px solid #E6E6E6' : 'none'}">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.PAGE_CLICKS' | i18nTranslate}}</div>
        <div class="qb-social-card-value-blue">{{insightsSummary.page_clicks}}</div>
        <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.PAGE_CLICKS' | i18nTranslate}}: <strong>{{insightsSummary.page_clicks_avg}}</strong></div>

      </div>
      <div class="qb-diplay-flex-column qb-w-33" *ngIf="selectedTab=='facebook'">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.POSTIVE_NEGATIVE_FEEDBACK' | i18nTranslate}}</div>
        <div class="qb-flex-center qb-gap-60">
          <div class="qb-diplay-flex-column qb-w-80p">
            <div class="qb-social-card-subtitle"><span class="qb-perc-value qb-perc-value-positive-color">{{insightsSummary.positive_feedback_perc}}%</span> Positive</div>
            <div class="qb-social-card-subtitle"><span class="qb-perc-value qb-perc-value-negative-color">{{insightsSummary.negative_feedback_perc}}%</span> Negative</div>

          </div>
          <div style="height: 80px;width:80px" *ngIf="insightsSummary.positive_feedback_perc>0">
        <!-- <app-donut-chart [data]="feedbackgraphData" [customWidth]="80" [customHeight]="80" [totalCount]="totalCountNPS" idn="pnf">
        </app-donut-chart> -->
      </div>
      </div>
       

      </div>
      </div>

    </div>
    <div class="qb-audience-card qb-impression-summary-card">

      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.IMPRESSION_SUMMARY' | i18nTranslate}}</div>
      <div class="qb-account-block-spinner qb-all-100" *ngIf="loading"> 
        <mat-spinner diameter="15"></mat-spinner>
           </div>
         <div class="qb-flex" *ngIf="!loading">
        <div class="qb-diplay-flex-column qb-r-b qb-w-33">
          <div class="qb-social-card-value-title" *ngIf="selectedTab=='facebook'">{{'COMMUNICATION_PAGE.PAGE_IMPRESSION' | i18nTranslate}}</div>
          <div style="display: flex;" *ngIf="selectedTab=='instagram'">
            <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.PAGE_IMPRESSION' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
                [ngbPopover]="popContentImpression">
                <ng-template #popContentImpression class="qb-background-ng-template">
                  <div class="qb-ng-template-text">
                    <div class="qb-d-inline-flex">{{'COMMUNICATION_PAGE.PAGE_IMPRESSION_INFO' | i18nTranslate}}</div>
                  </div>
                </ng-template>
                  <div class="qb-i">i</div>
                </div>
              </div>
          <div class="qb-social-card-value-blue">{{insightsSummary.page_impression}}</div>
          <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.AVG_PER_DAY' | i18nTranslate}}: <strong>{{insightsSummary.page_impression_avg}}</strong></div>
  
        </div>
        <div class="qb-diplay-flex-column qb-w-33" [ngStyle]="{'border-right': (selectedTab=='facebook') ? '1px solid #E6E6E6' : 'none'}">
          <div class="qb-social-card-value-title" *ngIf="selectedTab=='facebook'">{{'COMMUNICATION_PAGE.PAGE_REACH' | i18nTranslate}}</div>
          <div style="display: flex;" *ngIf="selectedTab=='instagram'">
            <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.PAGE_REACH' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
                [ngbPopover]="popContentImpression">
                <ng-template #popContentImpression class="qb-background-ng-template">
                  <div class="qb-ng-template-text">
                    <div class="qb-d-inline-flex">{{'COMMUNICATION_PAGE.PAGE_REACH_INFO' | i18nTranslate}}</div>
                  </div>
                </ng-template>
                  <div class="qb-i">i</div>
                </div>
              </div>
          <div class="qb-social-card-value-blue">{{insightsSummary.page_reach}}</div>
          <div class="qb-social-card-subtitle">{{'COMMUNICATION_PAGE.AVG_PER_DAY' | i18nTranslate}}: <strong>{{insightsSummary.page_reach_avg}}</strong></div>
  
        </div>
        <div class="qb-diplay-flex-column qb-w-33" *ngIf="selectedTab=='facebook'">
          <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.ORGANIC_V_PAID' | i18nTranslate}}</div>
          <div class="qb-flex-center qb-gap-60">
            <div class="qb-diplay-flex-column qb-w-80p">
              <div class="qb-social-card-subtitle"><span class="qb-perc-value qb-perc-value-positive-color">{{insightsSummary.organic_reach}}</span> {{'COMMUNICATION_PAGE.ORGANIC' | i18nTranslate}}</div>
              <div class="qb-social-card-subtitle"><span class="qb-perc-value qb-perc-value-negative-color">{{insightsSummary.paid_reach}}</span> {{'COMMUNICATION_PAGE.PAID' | i18nTranslate}}</div>
  
            </div>
  <div style="height: 80px;width:80px" *ngIf="insightsSummary.organic_reach>0">
    <!-- <app-donut-chart [data]="reachGrapghData" [customWidth]="80" [customHeight]="80"[totalCount]="totalReachGrapghData" idn="opr">
    </app-donut-chart> -->
  </div>
        </div>
         
  
        </div>
      </div>

    </div>
    <div class="qb-audience-card qb-demographics-summary-card">
      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.AUDIENCE_SUMMARY' | i18nTranslate}}</div>
      <div class="qb-account-block-spinner qb-all-100" *ngIf="loading"> 
        <mat-spinner diameter="15"></mat-spinner>
           </div>
         <div class="qb-flex" *ngIf="!loading">
      <div class="qb-account-block"> 
        {{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
      </div>
         </div>
    </div>
   
   


  </div>

 </div>
 <div class="qb-w-85 qb-set-100" *ngIf="(fbAccounts.length==0&&isNoAccountDetected)">
 <div class="qb-analytics-container" >
  <div class="qb-audience-card qb-demographics-summary-card">
<div class="qb-account-block-spinner" style="    height: 100%;">
       <div class="qb-flex" *ngIf="!loading" style="display: flex;
       align-items: center;
       justify-content: center;
       height: 100%;
       flex-direction: column;">
    <div class="qb-account-block"> 
      {{'COMMUNICATION_PAGE.ADD_SOCIAL_CHANNEL' | i18nTranslate}}

    </div>
    <div
    class="qb-text-align-center cursor-pointer qb-add-icon"
    (click)="openConnectSocialProfilesDialog()"
  >
  <span class="material-symbols-outlined">
    add
    </span>
  </div>
       </div>
      </div>
  </div>
</div>
 </div>

  </div>
</div>
