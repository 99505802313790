import { Component, Input, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  Event,
} from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbHeading;
  breadCrumbs = [];
  showBreadCrumbHeading = false;
  showBreadCrumb = false;
  breadCrumbList;

  routeUrl = window.location.pathname;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    switch (this.routeUrl) {
      // case "/app/campaign/overview":
      // case "/app/campaign/overview?type=campaign":
      // case "/app/offer/choose-offer":
      // case "/app/offer":
      case "/app/sms":
      case "/app/negotiator/update/hurdle":
      case "/app/sms/choose-layout":
      case "/app/whatsapp/choose-layout":
      case "/app/sms/list":
      case "/app/segment-dashboard":
      case "/app/consent-received":
      case "/app/segments":
      case "/app/email/choose-layout":
      case "/app/email/layout":
      case "/app/suppression-lists":
      case "/app/payments/payment-plan":
      case "/app/payments/top-up":
      case "/app/payments/payment-confirmed":
      case "/app/payments/payment-plan-pos":
      case "/app/partnership":
      case "/app/partnership/add-partner":
      // case "/app/customers/segment-dashboard":
      // case "/app/loyalty":
      // case "/app/loyalty/intro":
      case "/app/customer-list/employee":
      case "/app/customer-list":
      case "/app/trend-report":
      case "/app/rfm-dynamics":
      case "/app/customer-evolution":
      case "/app/product-trends":
      case "/app/loyalty-trends":
      case "/app/wallet-report":
      case "/app/customers/customer-offer":
      case "/app/leads":
      case "/app/app/social-eng":
      case "/app/app-home":
      case "/app/customer-evolution":
      case "/app/campaign/overview":
      case "/app/campaign/ab-testing":
      case "/app/insights":
      case "/app/sms-analytics/sms-analytics":
      case "/app/surveys/feature":
      case "/app/loyalty/report":
      case "/app/offer/report":
      case "/app/campaign/report":
      case "/app/user-profile/store-info/store-details":
      case "/app/customers/add-new-customer":
      case "/app/offer/choose-offer":
      case "/app/journey-analytics":
      case "/app/reviews":
        this.showBreadCrumbHeading = true;
        break;
      default:
        this.showBreadCrumbHeading = false;
    }
    switch (this.routeUrl) {
      case "/app/campaign/creation":
      case "/app/offer/create-offer":
      case "/app/offer/preview-offer":
      case "/app/surveys/survey-list":
      case "/app/surveys/add-survey":
      case "/app/segments/create-segment":
      case "/app/surveys/survey-list/schedule":
      case "/app/offer/edit-offer":
      case "/app/offer/create-offer":
      case "/app/forms/new-form":
      case "/app/partnership/my-offers/new-offer":
      case "/app/partnership/rewards/new-reward":
      case "/app/user-profile/my-store":
      case "app/partnership/form-list":
      case "/app/loyalty/create-loyalty-program":
      case "/app/customer-list/employee/create-employee-list":
      case "/app/customer-list/create-customer-list":
      case "/app/sms/sms-consent":
      case "/app/offer/customer-offer":
      case "/app/reviews/review-list":
        this.showBreadCrumb = true;
        break;
      default:
        this.showBreadCrumb = false;
    }
    if (this.routeUrl.includes("/app/partnership/partnership-requests/")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/segments/create-segment")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/partnership/my-partners/")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/gamify/create-game?")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/gamify/")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/gamify/how-to-create-a-game")) {
      this.showBreadCrumb = false;
    }
    if (this.routeUrl.includes("/app/user-profile/my-store?")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/email/layout-editor")) {
      this.showBreadCrumbHeading = true;
    }
    if (this.routeUrl.includes("/app/surveys/add-survey")) {
      this.showBreadCrumb = true;
    }
    if (this.routeUrl.includes("/app/offer/edit-offer")) {
      this.showBreadCrumb = true;
    }

    // if(this.routeUrl.includes('/app/user-profile/store-info')){
    //   this.showBreadCrumb = true;
    // }

    this.getHeaderName();
  }

  getHeaderName() {
    let root: ActivatedRoute = this.route.root;
    let header = this.getBreadcrumbHeading(root);
    this.breadcrumbHeading = header[header.length - 1]["label"];
    console.log(this.breadcrumbHeading);
    console.log(header);
    if (
      header[header.length - 1]["url"] !==
      "/customer-list/employee/create-employee-list"
    ) {
      this.breadCrumbs = header[header.length - 1]["url"].split("/");
    } else {
      let urlParts = header[header.length - 1]["url"].split("/");
      let updatedUrl = "/" + urlParts.slice(2).join("/");
      this.breadCrumbs = updatedUrl.split("/");
    }
  }

  private getBreadcrumbHeading(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbHeadings = []
  ) {
    const ROUTE_DATA_BREADCRUMBHEAD = "titleheading";

    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbHeadings;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      //if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length == 0) {
      if (
        child.outlet !== PRIMARY_OUTLET ||
        child["snapshot"]["_urlSegment"]["segments"].length == 0
      ) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMBHEAD)) {
        return this.getBreadcrumbHeading(child, url, breadcrumbHeadings);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url
        .map((segment) => {
          if(segment.path == 'campaign')
            return 'journey'
          return segment.path;
        })
        .join("/");

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumbHeading = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMBHEAD],
        url: url,
      };
      breadcrumbHeadings.push(breadcrumbHeading);

      //recursive
      return this.getBreadcrumbHeading(child, url, breadcrumbHeadings);
    }
    return breadcrumbHeadings;
  }

  changeRoute(route, indexOfBreadCrumb) {
    let url = "/app";
    for (let i = 1; i <= indexOfBreadCrumb; i++) {
      url += "/" + this.breadCrumbs[i];
    }
    if(url ==='/app/employee'){
      this.router.navigate(['/app/customer-list/employee']);
    } else if(url ==='/app/journey') {
      this.router.navigate(['/app/campaign']);
    }
    else{
      this.router.navigate([url]);
    }
  }
}
