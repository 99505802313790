<div class="qb-body">
  <div>
    <div class="qb-header-div">
      <div class="qb-header-buttons">
        <div class="primary-button qb-add-button" (click)="createNewOffer()">
          <div class="material-icons-outlined">add</div>
          <div>{{'PARTNERSHIP_PAGE.CREATE_NEW_OFFER' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-table-div">
    <div>
      <div class="qb-table-header">
        <div>
          <div class="tabs">
            <div style="display: flex">
              <div class="tab-label" (click)="offerTabChange('singleOffer')"
                [ngClass]="{ active: mainTopic == 'singleOffer' }" data-widget-name="OFFER" data-button-name="singleOffer">
                {{'PARTNERSHIP_PAGE.MY_OFFERS' | i18nTranslate}}
              </div>
              <div class="tab-label objectives-tab-label" (click)="offerTabChange('groupOffer')"
                [ngClass]="{ active: mainTopic == 'groupOffer' }" data-widget-name="OFFER"
                data-button-name="groupOffer">
                {{'PARTNERSHIP_PAGE.COMARKETING_OFFER' | i18nTranslate}}
              </div>
            </div>
          </div>
        </div>
        <div class="qb-search-div">
          <input
            type="text"
            placeholder="Search by Offer Name"
            [(ngModel)]="offerName"
            (input)="onKeySearch($event)"
          />
          <div class="material-icons-outlined" *ngIf="offerName.length == 0">
            search
          </div>
          <span
            class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
            *ngIf="offerName.length > 0"
            (click)="offerName = ''; onKeySearch(offerName)"
          >
            close
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="mainTopic === 'singleOffer'">
      <div>
        <div class="qb-table-head">
          <div
            *ngFor="let heading of tableHeadings"
            class="qb-table-head-section"
          >
            <div *ngIf="heading !== 'Revenue Generated'">{{ heading | i18nDynamicTranslate : 'POS_PAGE' }}</div>
            <div
              class="qb-revenue-section"
              *ngIf="heading === 'Revenue Generated'"
            >
              <div>{{'PARTNERSHIP_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
              <!-- <div class="qb-dropdown-div">
            <mat-select [(value)]="revenueRange">
              <mat-option value="allTime">All Time</mat-option>
              <mat-option value="30days">30 Days</mat-option>
            </mat-select>
          </div>
          <div class="qb-arrow-div">
            <div class="material-icons-outlined">arrow_drop_up</div>
            <div class="material-icons-outlined qb-arrow-down">arrow_drop_down</div>
          </div> -->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading" class="qb-spinner-div">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
      <div *ngIf="length == 0 && !loading" class="qb-no-data">
        {{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
      </div>
      <div *ngIf="!loading && length > 0">
        <div class="qb-table-data-div" *ngFor="let offer of offersList">
          <div
            class="qb-table-data name-overflow"
            [matTooltipShowDelay]="500"
            [matTooltip]="offer['offerName']"
          >
            {{ offer["offerName"] }}
          </div>
          <div
            class="qb-table-data qb-name-overflow"
            [matTooltipShowDelay]="500"
            [matTooltip]="offer['comDiscountCode']"
          >
            {{ offer["comDiscountCode"] }}
          </div>
          <div
            class="qb-table-data qb-revenue-data"
            *ngIf="offer['comRevenueGenerated'] !== null"
          >
            {{ currencySymbol }}{{ offer["comRevenueGenerated"] | number: "1.2-2" }}
          </div>
          <div
            class="qb-table-data qb-revenue-data"
            *ngIf="offer['comRevenueGenerated'] === null"
          >
            {{ currencySymbol }}0
          </div>
          <div class="qb-table-data">{{ offer["offerValidity"] }}</div>
        </div>
      </div>
      <div class="qb-paginate-div">
        <mat-paginator
          [pageIndex]="pageNumber - 1"
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="refreshPaginatedData($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div *ngIf="mainTopic === 'groupOffer'" style="overflow: auto;">
        <div class="qb-table-head">
          <div
            *ngFor="let heading of tableHeadingsGroup"
            class="qb-table-head-section-group"
          >
            <div *ngIf="heading !== 'Revenue Generated' && heading !== 'Revenue Cut Given'">{{ heading | i18nDynamicTranslate : 'POS_PAGE' }}</div>
            <div
              class="qb-revenue-section"
              *ngIf="heading === 'Revenue Generated'"
            >
              <div>{{'PARTNERSHIP_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
              <!-- <div class="qb-dropdown-div">
            <mat-select [(value)]="revenueRange">
              <mat-option value="allTime">All Time</mat-option>
              <mat-option value="30days">30 Days</mat-option>
            </mat-select>
          </div>
          <div class="qb-arrow-div">
            <div class="material-icons-outlined">arrow_drop_up</div>
            <div class="material-icons-outlined qb-arrow-down">arrow_drop_down</div>
          </div> -->
            </div>
            <!-- <div
              class="qb-revenue-section"
              *ngIf="heading === 'Revenue Cut Generated'"
            >
              <div>Revenue Cut Generated</div>
            </div> -->
          </div>
      </div>
      <div *ngIf="loadingGroup" class="qb-spinner-div">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
      <div *ngIf="lengthGroup == 0 && !loadingGroup" class="qb-no-data">
        {{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
      </div>
      <div *ngIf="!loadingGroup && lengthGroup > 0">
        <div *ngFor="let offer of coMarketingOffers">
          <div class="qb-table-data-div"  style="border-bottom: 1px solid rgba(99, 100, 102, 0.2);">
            <div
              class="qb-table-data-group name-overflow"
              [matTooltipShowDelay]="500"
              [matTooltip]="offer['offerName']"
            >
              {{ offer["offerName"] }}
            </div>
            <div class="qb-table-data-group qb-name-overflow">
              <div *ngFor="let data of offer['offerList']" class="qb-pb-16" style="text-transform: uppercase;">
                <div
                  [matTooltipShowDelay]="500" 
                  [matTooltip]="offer['couponCode']"
                >
                  {{ data["couponCode"] }}
                </div>
              </div>
            </div>
            <div class="qb-table-data-group qb-name-overflow">
              <div *ngFor="let data of offer['offerList']" class="qb-pb-16">
                <div
                [matTooltipShowDelay]="500"
                [matTooltip]="offer['storeName']"
              >
                {{ data["storeName"] }}
              </div>
              </div>
            </div>
            <div class="qb-table-data-group qb-revenue-data">
              <div *ngFor="let data of offer['offerList']" class="qb-pb-16">
                <div
                  
                  *ngIf="data['revenue'] !== null"
                >
                  {{ data["revenue"] | customCurrency: revCurrency: true }}
                </div>
                <div
                  *ngIf="data['revenue'] === null"
                >
                  {{ zero | customCurrency: revCurrency: true }}
                </div>
              </div>
            </div>
            <!-- <div class="qb-table-data-group qb-revenue-data">
              <div *ngFor="let data of offer['offerList']" class="qb-pb-16">
                <div
                  
                  *ngIf="data['percentageCutGiven'] !== null"
                >
                  {{ data["percentageCutGiven"] | customCurrency: revCurrency: true }}
                </div>
                <div
                  *ngIf="data['percentageCutGiven'] === null"
                >
                  {{ zero | customCurrency: revCurrency: true }}
                </div>
              </div>
            </div> -->
            <div class="qb-table-data-group">
              <div *ngFor="let data of offer['offerList']" class="qb-pb-16">
                <div>{{ data["couponValid"] | date}}</div>
              </div>
            </div>
            <div class="qb-table-data-group">
              <div *ngFor="let data of offer['offerList']" class="qb-pb-16">
                <div *ngIf="data['status'] === 'ACTIVE'" class="qb-live-offer">{{'PARTNERSHIP_PAGE.LIVE' | i18nTranslate}}</div>
                <div *ngIf="data['status'] === 'DISABLED'" class="qb-disabled-offer">{{'PARTNERSHIP_PAGE.DISABLED' | i18nTranslate}}</div>
                <div *ngIf="data['status'] === 'EXPIRED'" class="qb-expired-offer">{{'PARTNERSHIP_PAGE.EXPIRED' | i18nTranslate}}</div>
                <div *ngIf="data['status'] === 'VIEW_OFFER'" class="qb-expired-offer">{{'PARTNERSHIP_PAGE.VIEW_OFFER' | i18nTranslate}}</div>
                <div *ngIf="data['status'] === 'SEND_OFFER'" class="qb-expired-offer">{{'PARTNERSHIP_PAGE.SEND_OFFER' | i18nTranslate}}</div>
                <div *ngIf="data['status'] === 'OFFER_DISABLED'" class="qb-expired-offer">{{'PARTNERSHIP_PAGE.OFFER_DISABLED' | i18nTranslate}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
