import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WhatsappRedirectComponent } from './modules/pos/modules/whatsApp/pages';
import { ActivateComponent } from './modules/pos/modules/activate/activate.component';
import { PaymentPosComponent, PaymentRedirectComponent, StoreNotReadyComponent } from './modules/pos/modules/payments/pages';
import { StaticPageComponent, SessionOutComponent, MobileAlertComponent, WelcomeWidgetComponent, MobileEmailPreviewComponent, NotFoundComponent, ViewSurveyComponent } from './modules/static/pages';
import { SelectUserPageComponent } from './modules/data-ingestion/pages';
import { ViewEmailTemplateComponent } from './modules/pos/modules/email/pages';

export const routes: Routes = [
  { path: "", component: StaticPageComponent },
  { path: "landing", component: StaticPageComponent },
  { path: "session-out", component: SessionOutComponent },
  { path: "mobile", component: MobileAlertComponent },
  { path: "welcome-feature", component: WelcomeWidgetComponent },
  { path: "payment-pos", component: PaymentPosComponent},
  { path: "payment-redirect", component: PaymentRedirectComponent},
  { path: "store-inprogress", component: StoreNotReadyComponent},
  { path: "mobile-email-preview", component: MobileEmailPreviewComponent },
  { path: 'auth', loadChildren: () => import('./modules').then(m => m.RegistrationModule) },
  { path: 'data-ingestion', loadChildren: () => import('./modules').then(m => m.DataIngestionModule) },
  { path: 'app', loadChildren: () => import('./modules').then(m => m.POSModule) },
  { path: 'whatsapp-redirect', component:WhatsappRedirectComponent},
  { path: 'activate', component: ActivateComponent},
  { path: 'pages/:surveyName',component: ViewSurveyComponent},
  { path: 'select-store-type', component: SelectUserPageComponent},
  { path: 'view-template', component: ViewEmailTemplateComponent},
  { path: "**", component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }