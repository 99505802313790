import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { assetPath } from 'src/app/configs/asset-path-config';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-offer-template-view-dialog',
  templateUrl: './offer-template-view-dialog.component.html',
  styleUrls: ['./offer-template-view-dialog.component.css']
})
export class OfferTemplateViewDialogComponent implements OnInit {

  constructor(private apiCall:ApiCallService,
    public dialogRef: MatDialogRef<OfferTemplateViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  selectedbarrier;
  assetPath = {
    arrowUrl: assetPath.path + "/Group 1393.svg",
  }
  loading=false;
  offerJson;
  htmlloading;
  layoutSnapshotUrl;
  offerDetailEmailLayout;
  isNoneBarrierDetected;
  ngOnInit() {
   // this.selectBarrier(this.data['data']['barrierList'][0])
    this.offerJson=(JSON.parse(this.data["offerjson"]))['barrierRewards'];
    this.offerDetailEmailLayout=JSON.parse(this.data["offerjson"])['emailLayoutId']
  this.isNoneBarrierDetected=JSON.parse(this.data["offerjson"])['isNoneBarrierDetected']
    this.selectedbarrier=this.offerJson;
    if(this.offerDetailEmailLayout){
    this.getHtml(this.offerDetailEmailLayout);
    }
  }

  selectBarrier(barrier) {
    this.selectedbarrier = barrier;

  }
  getHtml( offerDetailEmailLayout){

    this.apiCall.getEmailContent(offerDetailEmailLayout).subscribe((response) => {
      this.htmlloading=false;
      if (response["message"] === "success") {
        let layout = JSON.parse(response["body"]);
        this.layoutSnapshotUrl=layout["snapShot"];
        }
    });
  }

  close(){
    this.dialogRef.close("close")
  }

}
