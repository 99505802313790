<div class="qb-templateListContainer" #mainContainer id="container" *ngIf="validWidgets">
  <div class="qb-main-wrapper">
    <div>
    <div class="qb-main-header" #mainHeader>
      <app-breadcrumb></app-breadcrumb>
     
      <!-- <div style="display: flex; align-items: flex-end">
        <button
          style="border-radius: 3px 0px 0px 3px"
          class="qb-selectRectangle"
          (click)="selectList('all')"
          [ngClass]="{ active: tabSelected == 'all' }"
        >
          <span>All</span>
        </button>
        <button
          class="qb-selectRectangle"
          (click)="selectList('preset')"
          [ngClass]="{ active: tabSelected == 'preset' }"
        >
          <span>Preset</span>
        </button>
        <button
          style="border-radius: 0px 3px 3px 0px"
          class="qb-selectRectangle"
          (click)="selectList('mytemplates')"
          [ngClass]="{ active: tabSelected == 'mytemplates' }"
        >
          <span>Custom</span>
        </button>
      </div> -->

      
      <div class="qb-buttons">
        <div class="qb-template-style-switch-button-container">
          <div
            class="qb-template-style-switch-icon-container qb-icon-conatiner"
          >
          <span class="icons-primary qb-grid-view-container" [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.GRID_VIEW' | i18nTranslate">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.25 1.5V4.25H14.5V2C14.5 1.75 14.25 1.5 14 1.5H11.25ZM9.75 1.5H6.25V4.25H9.75V1.5ZM4.75 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V4.25H4.75V1.5ZM1.5 5.75V8.25H4.75V5.75H1.5ZM1.5 9.75V12C1.5 12.2812 1.71875 12.5 2 12.5H4.75V9.75H1.5ZM6.25 12.5H9.75V9.75H6.25V12.5ZM11.25 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V9.75H11.25V12.5ZM14.5 8.25V5.75H11.25V8.25H14.5ZM0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2ZM6.25 8.25H9.75V5.75H6.25V8.25Z" fill="#4379EE"/>
              </svg>
                            

            </span>            </div>
          <div
            (click)="selectListView()"
            class="qb-template-style-switch-icon-container cursor-pointer"
          >
          <span class="icons-primary cursor-pointer" [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.LIST_VIEW' | i18nTranslate">
            <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 0C1.53125 0 2 0.46875 2 1C2 1.5625 1.53125 2 1 2C0.4375 2 0 1.5625 0 1C0 0.46875 0.4375 0 1 0ZM4.75 0.25H14.25C14.6562 0.25 15 0.59375 15 1C15 1.4375 14.6562 1.75 14.25 1.75H4.75C4.3125 1.75 4 1.4375 4 1C4 0.59375 4.3125 0.25 4.75 0.25ZM4.75 5.25H14.25C14.6562 5.25 15 5.59375 15 6C15 6.4375 14.6562 6.75 14.25 6.75H4.75C4.3125 6.75 4 6.4375 4 6C4 5.59375 4.3125 5.25 4.75 5.25ZM4.75 10.25H14.25C14.6562 10.25 15 10.5938 15 11C15 11.4375 14.6562 11.75 14.25 11.75H4.75C4.3125 11.75 4 11.4375 4 11C4 10.5938 4.3125 10.25 4.75 10.25ZM2 6C2 6.5625 1.53125 7 1 7C0.4375 7 0 6.5625 0 6C0 5.46875 0.4375 5 1 5C1.53125 5 2 5.46875 2 6ZM1 10C1.53125 10 2 10.4688 2 11C2 11.5625 1.53125 12 1 12C0.4375 12 0 11.5625 0 11C0 10.4688 0.4375 10 1 10Z" fill="#4379EF"/>
              </svg>
              
            </span>             </div>
        </div>
        <!-- <button
          *ngIf="
            appSubscriptionPlan &&

            (validWidgets | widgetConfig: 19)
          "
          class="secondary-button qb-secondary-button"
          (click)="createsmsconsent()"
        >
          <span class="qb-create-template-span"
            > {{'COMMUNICATION_PAGE.SEND_CONSENT' | i18nTranslate}}</span
          >
        </button> -->
        <button
          *ngIf="isSMSEditAllowed && (validWidgets | widgetConfig: 19)"
          class="qb-create-template-div primary-button"
          (click)="createTemplate('new')"
        >
          <span class="qb-create-template-span"
            > Create New
            Template</span
          >
        </button>
      </div>
    </div>
    </div>
    <div class="sms-cards-container">
      <div class="template-main-heading">

        <div class="template-heading">
          <h5 class="template-heading-h5">{{headingTextKey}}</h5>
        </div>

        <div class="list-options">
          <div class="header-button-container">
            <button class="header-button header-button-left"  [ngClass]="{ active: tabSelected == 'preset'}" (click)="selectList('preset', $event)">
              <span>{{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}</span>
            </button>
            <button class="header-button header-button-right"  [ngClass]="{ active: tabSelected == 'custom'}" (click)="selectList('custom', $event)">
              <span>{{'COMMUNICATION_PAGE.CUSTOM' | i18nTranslate}}</span>
            </button>
          </div>
         
        </div>

        <div class="template-filters">
           <div class="qb-search-bar">
        <div class="qb-Rectangle">
          <div
            class="qb-d-flex qb-ai-center qb-w-96 qb-h-100"
          >
            <input
              class="qb-input-text-field"
              type="text"
              [(ngModel)]="filter.label"
              placeholder="Search"
              #search
            />
            <span class="material-icons icons-primary"  *ngIf="filter.label.length==0">
              search
              </span>
              <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"  *ngIf="filter.label.length>0" (click)="filter.label=''">
                close
              </span>
                  </div>
        </div>
      </div>

      <div
      class="filterButton button-hover-effect"
      (click)="openFiltersDiv()"
      data-button-name="Filter"
    >
      <div style="display: flex">
        <span
          style="cursor: pointer"
          [matTooltipShowDelay]="200"
          [matTooltip]="'COMMON_PAGE.FILTERS' | i18nTranslate"
        >
                  <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 10C16.1348 10 20.5 7.98528 20.5 5.5C20.5 3.01472 16.1348 1 10.75 1C5.36522 1 1 3.01472 1 5.5C1 7.98528 5.36522 10 10.75 10Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 5.5C1.00253 10.0155 4.10614 13.938 8.5 14.979V21.25C8.5 22.4926 9.50736 23.5 10.75 23.5C11.9926 23.5 13 22.4926 13 21.25V14.979C17.3939 13.938 20.4975 10.0155 20.5 5.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
        </span>
      </div>
    </div>

        </div>
      </div>
      <div class="qb-all-sms" #content *ngIf=" !loading && (validWidgets | widgetConfig: 18); else loadSpinner">
        <div class="qb-template-wrapper">
          <div
          class="qb-sms"
          *ngFor="
            let layout of smsLayouts | labelPipe: filter.label:'name';
            let i = index;
            let l = count
          "
        >
          <div
            class="qb-Rectangle-list"
            [id]="'email-' + layout['id']"
            (click)="openPreview(layout)"
          >
            <div class="qb-sms-template">
              <div class="qb-template-image-container">
                <div class="qb-phone-image">
                  <div class="qb-preview-text-content">
                    <span
                      class="qb-layout-data-text"
                      >{{ layout["layoutData"] }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="qb-sms-name">
                <div>
                  <div
                    class="qb-templateHeadText"
                    [matTooltip]="layout['name']"
                    matTooltipclass="qb-ex-tooltip-red1"
                    matTooltipShowDelay="500"
                  >
                    {{ layout["name"] }}
                  </div>
                </div>
                <!-- <div class="qb-Digits">
                  <span style="color: #aeaeae">Last edited on</span>
                  <span style="font-weight: 600">
                    {{ layout["lastModifiedTime"] | date:'dd MMM yyyy' }}</span
                  >
                </div> -->
              </div>
            </div>
            <mat-icon
            *ngIf="layout['subLayout'] != 'CONSENT'"
              type=" button "
              placement="bottom "
              class="qb-more-option-icon"
              [matTooltipShowDelay]="100"
              matTooltip="Options"
              (click)="openOptions($event)"
            >
              more_vert</mat-icon
            >
            <div class="qb-more-options">
              <div class="qb-d-flex qb-fd-coloumn">
                <div
                  class="qb-popover-menu"
                  style="cursor: pointer"
                  *ngIf="layout['layoutType'] === 'CUSTOM'"
                  (click)="editLayout(layout,$event)"
                >
                  Edit
                </div>
                
                <div 
                *ngIf="layout['subLayout'] != 'CONSENT'"
                  class="qb-popover-menu cursor-pointer"
                  (click)="cloneLayout(layout, $event)"
                >
                  {{'COMMUNICATION_PAGE.CLONE' | i18nTranslate}}
                </div>
                <!-- <div
                  class="qb-popover-menu cursor-pointer"
                  (click)="sendNow(layout['id'], $event)"
                >
                  {{'COMMUNICATION_PAGE.SEND_NOW' | i18nTranslate}}
                </div> -->
                <div
                  class="qb-popover-menu"
                  *ngIf="layout['layoutType'] === 'CUSTOM'"
                  style="cursor: pointer"
                  (click)="deleteLayout(layout['id'],$event)"
                >
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
           
            <div style="display: flex; align-items: center; width: 100%; justify-content: center;">
              <div class="qb-NoData" *ngIf="!loading && (
                (smsLayouts | labelPipe: filter.label:'name')
                  .length === 0)
              ">
                  {{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                </div>
            </div>
           
        <!-- <div
          class="qb-no-match-container"
          [style.height.px]="contentHeight"
          *ngIf="
            (selectedTabTemplateList | labelPipe: filter.label:'layoutName')
              .length === 0 && selectedTabTemplateList.length != 0
          "
        >
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div class="qb-no-match-div">No match found</div>
          </div>
        </div> -->
        <!-- </ng-template> -->
      </div>
    </div>
   
  </div>
  <ng-template #loadSpinner>
    <div class="loader">
      <app-qubriux-loading  [size]="65" [loadingText]="'Fetching SMS Templates...'"></app-qubriux-loading>
    </div>
  </ng-template>
</div>


<div *ngIf="showFiltersDiv">
  <div class="overlay"></div>
  <div class="filter-actions">
    <div class="titleDiv">
      <div
        style="display: flex; justify-content: space-between;align-items: center;"
      >
        <div class="title">{{'CAMPAIGN_PAGE.FILTERS_NOR' | i18nTranslate}}</div>
        <div
          *ngIf="showClearAll"
          class="clear-all-button button-hover-effect"
          (click)="clear()"
        >
          {{'CAMPAIGN_PAGE.CLEAR_ALL_CAPS' | i18nTranslate}}
        </div>
      </div>
      <div (click)="closeFiltersDiv()"
        class="close-style"
      >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.11529 8.99993L0.515625 2.40027L2.40124 0.514648L9.00091 7.11431L15.6006 0.514648L17.4862 2.40027L10.8865 8.99993L17.4862 15.5996L15.6006 17.4852L9.00091 10.8855L2.40124 17.4852L0.515625 15.5996L7.11529 8.99993Z" fill="#202224"/>
        </svg>
        
      </div>
    </div>
    <div style="padding: 0px 20px;">
      <hr style="border-style: dashed;">
    </div>
    <div class="filter-container-journey">
      <div class="filter">
        <div>
          
          <p><strong style="font-size: 18px">{{'COMMUNICATION_PAGE.TYPE' | i18nTranslate}}</strong></p>
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters.generic"
              value="generic"
              (change)="toggleFilter('generic')"
            ></mat-checkbox
            ><span class="option">{{'COMMUNICATION_PAGE.GENERIC' | i18nTranslate}}</span>
          </p>
           <!-- <div class="content-filter-container" *ngIf="showFilters">
            <div class="label">{{'COMMON_PAGE.FILTERS' | i18nTranslate}}:</div>
            <div>
              <button class="filter-button margin-right-5px"  [ngClass]="{ active: filterSelected == 'general'}" (click)="selectList(tabSelected, $event,'general')">
                <span>{{'COMMON_PAGE.GENERAL' | i18nTranslate}}</span>
              </button>
              <button class="filter-button margin-right-5px"  [ngClass]="{ active: filterSelected == 'reward'}" (click)="selectList(tabSelected, $event,'reward')">
                <span>{{'COMMON_PAGE.REWARD' | i18nTranslate}}</span>
              </button>
              <button *ngIf="isStandard" class="filter-button"  [ngClass]="{ active: filterSelected == 'consent'}" (click)="selectList(tabSelected, $event,'consent')">
                <span>{{'Consent'}}</span>
              </button>
            </div>
          </div> -->

          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters.reward"
              value="reward"
              (change)="toggleFilter('reward')"
            ></mat-checkbox
            ><span class="option">{{'COMMUNICATION_PAGE.REWARD' | i18nTranslate}}</span>
          </p>
          
         
        </div>
      </div>
    </div>
    <div class="bottom-button-container">
        <button class="secondary-button-border" (click)="closeFiltersDiv()">
          {{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}
        </button>
        <button class="apply primary-button" (click)="applyFilter()">
          {{'CAMPAIGN_PAGE.APPLY' | i18nTranslate}}
        </button>
    </div>
  </div>
</div>
