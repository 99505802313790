import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SuccessDialogComponent, WhatsappTemplateCreationDialogComponent } from 'src/app/shared/components/dialog-box';

@Component({
  selector: 'app-whatsapp-template-creation',
  templateUrl: './whatsapp-template-creation.component.html',
  styleUrls: ['./whatsapp-template-creation.component.css']
})
export class WhatsappTemplateCreationComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private apicall: ApiCallService
  ) { }
    layoutData;
  ngOnInit(): void {
    this._ActivatedRoute.params.subscribe((params)=>{
      if(params.id === null){
        const dialogRef = this.dialog.open(WhatsappTemplateCreationDialogComponent, {
          data: null,
          panelClass: "create-sms-dialog-container",
          width: "80%",
          height: "90vh",
          disableClose: false,
        })
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.openSuccessDialog(
              "Your WhatsApp template has been successfully created. It will take 2-3 days to get approved.",
              "OK"
            );
          }
          else{
            this._Router.navigate(['app/whatsapp/choose-layout']);
          }
        })
      }
      else{
        this.getLayoutData(params.id);
      }
    })
  }
  getLayoutData(id){
    console.log("IDDD",typeof(id))
    console.log("RESDYSTDYTS")
    this.apicall.getWhatsAppTemplates("ALL").subscribe((response)=>{
      console.log("TEMPLATE_RESPONSE", response);
      let whatsAppLayout = response['body'];
      let index =  whatsAppLayout.findIndex(
        (x) => x['id'].toString() === id
      );
      console.log("INDEX",index)
        this.layoutData = whatsAppLayout[index];
        console.log("DATA",this.layoutData)
        const dialogRef = this.dialog.open(WhatsappTemplateCreationDialogComponent, {
          data: this.layoutData,
          panelClass: "create-sms-dialog-container",
          width: "80%",
          height: "90vh",
          disableClose: false,
        })
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.openSuccessDialog(
              "Your WhatsApp template has been submitted for approval, It will take 1-2 days to get it approved. ",
              "OK"
            );
          }
          else{
            this._Router.navigate(['app/whatsapp/choose-layout']);
          }
        })
    })
  }
  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this._Router.navigate(['app/whatsapp/choose-layout']);
    });
  }

}
