import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog"; 
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { ContactUsDialogComponent, SendSmsConsentDialogBoxComponent, SuccessDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';


@Component({
  selector: 'app-get-sms-consent',
  templateUrl: './get-sms-consent.component.html',
  styleUrls: ['./get-sms-consent.component.css']
})
export class GetSmsConsentComponent implements OnInit {
  @ViewChild("customerSegmentsSelect") customerSegmentsSelect: any;
  @ViewChild("customerTempleteSelect") customerTempleteSelect: any;
  toggled: boolean = false;
  smsText = "";
  previewText="";
  optOutText = false;
  insertLink = false;
  shortenUrl = false;
  submit = false;
  mergetagFilter = "";
  dropDownStatus = false;
  textSelection;
  templateName = "";
  linkURL = "";
  smsCount = 0;
  charCount = 0;
  mergeTags = [
    { name: "shop name", value: "{{$shop_name}}" },
    { name: "user name", value: "{{$user_name}}" },
    { name: "product name", value: "{{$product_name}}" },
    { name: "category name", value: "{{$category_name}}" },
    { name: "date", value: "{{$date}}" },
  ];
  segments=[];
  templetes=[];
  templeteId;
  dispatchcustomerSegments=[];
  disableAllCustomerSelection=false;
  disableCustomerSegmentSelection=false;
  selectedSegments=[];
  customerCount=0;
  totalCustomer=0;
  InvalidCustomer=0;
  NotAllowedCustomer=0;
  ReminderCustomer=0;
  FirstTimeCustomer=0;
  ConsentAlreadySent=0;
  CustomerNotInUSA=0;
  ConsentGivenCount=0;
  ReminderGivenConsentCount=0;
  tempsegmentFilter="";
  allcustomerObj = [{ id: "ALL_CUSTOMERS", name: "My Customers" }];
  reminderStatus="0";
  submitted=false;
  segmentLength=false;
  templeteName;
  tname=null;
  isAllCustomer=false;
  apiloading=true;
  secondreminderCount=0;
  constructor(private _apiCall:ApiCallService,
              private dialog: MatDialog,
              private tokenstorage: TokenStorageService,
              private _i18nDynamicTranslate: TranslateDynamicText,
              private router: Router) { }

  ngOnInit() {
    this.getCustomerData();
    this.gettemplate();
  }
  keypresshandler(event) {
    this.updateSmsCount();
    // if (160 - this.smsText.length === 0) {
    //   return false;
    // }
    //let charCode = event.keyCode;
    // if (charCode === 64) {
    //   var txtarea = document.getElementById("smsText") as HTMLInputElement;
    //   this.textSelection = txtarea.selectionStart;
    //   let element = document.getElementById("multi-select-dropdown");
    //   let segmentSelect = document.getElementById("segment-select");
    //   let segmentSelectPanel = document.getElementById("multi-select-panel");
    //   let keyboardDownIconElement = document.getElementById(
    //     "keyboard-down-icon-container"
    //   );
    //   this.dropDownStatus = true;
    //   element.style.height = "140px";
    //   segmentSelect.style.overflow = "auto";
    //   segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
    //   segmentSelectPanel.style.border = "1px solid #25a7f9";
    //   keyboardDownIconElement.style.transform = "rotate(180deg)";
    //   return false;
    // } else {
    //   return true;
    // }
  }
  checkMergeTagExist(mergeTag){
    if(this.mergeTags.findIndex(x=>x.value === mergeTag) > -1){
      return true;
    }
    else{
      return false;
    }
  }

  addMergeTag(
    value,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId
  ) {
    // if (160 - this.smsText.length < value.length) {
    //   return;
    // }
    var txtarea = document.getElementById("smsText");
    let element = document.getElementById(elementId);
    let segmentSelect = document.getElementById(segmentSelectId);
    let segmentSelectPanel = document.getElementById(segmentSelectPanelId);
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId
    );
    this.dropDownStatus = false;
    element.style.height = "0px";
    segmentSelect.style.overflow = "hidden";
    segmentSelectPanel.style.boxShadow = "unset";
    segmentSelectPanel.style.border = "1px solid #919191";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    let text = this.smsText;
    this.smsText =
      text.slice(0, this.textSelection) +
      value +
      text.slice(this.textSelection);
    txtarea.focus();
    this.updateSmsCount();
    // let txtareaEle = document.getElementById("smsText") as HTMLInputElement;
    // txtareaEle.selectionEnd = ;
  }
  updateSmsCount(){
    let smsText = this.smsText;
    let countOfChar = 0;
    for(let i=0;i<smsText.length;i++){
      if(smsText[i] === '{'){
        if(smsText[i+1]=== '{'){
          for(let j=i+2, count=1; j<smsText.length;j++,count++){
            if(smsText[j] === '}'){
              if(smsText[j+1] === '}'){
                if(this.checkMergeTagExist(smsText.substring(i,j+2))){
                  countOfChar+=count+3;
                }
                break;
              }
            }
          }
        }
      }
    }
    let finalCharCount = this.smsText.length - countOfChar;
    if(this.optOutText){
      finalCharCount += 22;
    }
    this.smsCount = finalCharCount === 0 ? 1 : Math.ceil(finalCharCount/160);
    this.charCount = finalCharCount - (this.smsCount === 0 ? 0 : ((this.smsCount-1) * 160));
  }
  handleSelection(event) {
    if (!(160 - this.smsText.length < event.char.length)) {
      let text = this.smsText;
      this.smsText =
        text.slice(0, this.textSelection) +
        event.char +
        text.slice(this.textSelection);
      this.textSelection += event.char.length;
      var txtarea = document.getElementById("smsText") as HTMLInputElement;
      txtarea.selectionEnd = this.textSelection + 1;
    }
  }
  updateMeasurementPointComment(event) {
    var txtarea = document.getElementById("smsText") as HTMLInputElement;
    this.textSelection = txtarea.selectionStart;
  }

  onPaste(event) {
    this.updateSmsCount();
    // if (
    //   160 - this.smsText.length <
    //   event.clipboardData.getData("text").length
    // ) {
    //   return false;
    // }
  }

  toggleSelect(
    event,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId
  ) {
    event.stopPropagation();
    let element = document.getElementById(elementId);
    let segmentSelect = document.getElementById(segmentSelectId);
    let segmentSelectPanel = document.getElementById(segmentSelectPanelId);
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId
    );
    if (this.dropDownStatus) {
      this.dropDownStatus = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.boxShadow = "0 0 10px 0 #c6ced685";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //this.getSegmentCount();
      //element.style.display = 'none';
    } else {
      this.dropDownStatus = true;
      element.style.height = "140px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }
  addLink() {
    // if (160 - this.smsText.length < this.linkURL.length) {
    //   return;
    // }
    if (this.shortenUrl) {
      let text = this.smsText;
      this.smsText =
        text.slice(0, this.textSelection) +
        this.linkURL +
        text.slice(this.textSelection);
      let txtarea = document.getElementById("smsText");
      txtarea.focus();
    } else {
      let text = this.smsText;
      this.smsText =
        text.slice(0, this.textSelection) +
        this.linkURL +
        text.slice(this.textSelection);
      let txtarea = document.getElementById("smsText");
      txtarea.focus();
    }
    this.updateSmsCount();
  }

  getCustomerData() {
    this._apiCall.getCustomerSegmentsAndTiers().subscribe((response) => {
      let segment = (response["body"]);
      for (let i = 0; i < segment.length; i++) {
        //if (segment[i]["isActive"] === true) {
        this.segments.push({
          id: segment[i]["id"],
          name: segment[i]["segmentName"],
          isActive: segment[i]["isActive"],
          status: false,
        });
        //}
      }
    });
  }
  changeSegmentSelection() {
    if (
      this.dispatchcustomerSegments
        .length === 1
    ) {  
      if (
        this.dispatchcustomerSegments[0] === "ALL_CUSTOMERS"
      ) {
        this.disableCustomerSegmentSelection = true;
      } else {
        this.disableAllCustomerSelection = true;
      }
    } else if (
      this.dispatchcustomerSegments
        .length === 0
    ) {
      // this.getCustomerCount();
      this.customerCount=0;
      this.totalCustomer=0;
      this.InvalidCustomer=0;
      this.NotAllowedCustomer=0;
      this.FirstTimeCustomer=0;
      this.ReminderCustomer=0;
      this.secondreminderCount=0;
      this.CustomerNotInUSA=0;
      this.ConsentAlreadySent=0;
      this.selectedSegments = [];
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
      this.isAllCustomer=false;
    }
    // for (let i = 0; i < this.templateDispatchForm.controls.customerSegments['value'].length; i++) {
    //   if (this.templateDispatchForm.controls.customerSegments['value'][i] === "ALL_CUSTOMERS") {
    //     this.disableCustomerSegments = true;
    //     let value = ["ALL_CUSTOMERS"]
    //     this.templateDispatchForm.controls.targetCustomerSegments.setValue(value);
    //     return;
    //   }
    // }
    // this.disableCustomerSegments = false;
    // this.selectedSegments=this.customerSegmentsSelect.value
    this.getCustomerCount();
  }
  selectopenclose(value) {
    switch (value) {
      case "customerSegmentsSelect":
        this.selectedSegments=this.customerSegmentsSelect.value
        // this.getCustomerCount();
        this.customerSegmentsSelect.open(); 
        break;
      case "customerTempleteSelect":
        this.customerTempleteSelect.open();
        // console.log(this.templetes[n]['layoutData'])
        break;
    }
  }
  
  getCustomerCount(){
    this.segmentLength=false
    this.customerCount=0;
    this.NotAllowedCustomer=0;
    this.selectedSegments=this.customerSegmentsSelect.value
    // console.log(this.selectedSegments)
    if( this.disableCustomerSegmentSelection !=false || this.disableAllCustomerSelection != false){
    if(this.selectedSegments.length != 0)
    {
      this.segmentLength=true;
      // console.log(this.selectedSegments.length)
      if(this.selectedSegments[0]=='ALL_CUSTOMERS'){
        this.isAllCustomer=true;
        this._apiCall.getAllCustomersCount().subscribe((response)=>{
          this.apiloading=true;
          // console.log(response['body']['callingCodeCountMap'])
          this.totalCustomer=response['body']['totalCustomerCount']
          this.InvalidCustomer=response['body']['customersNotHavingValidMobileNumberCount']
          this.ReminderCustomer=response['body']['reminderCustomerIds'] -  response['body']['reminderCustomersGivenConsent'] 
          this.FirstTimeCustomer=response['body']['totalAllowedCustomerIds'] - response['body']['firstTimeReminderCustomerIds'] - response['body']['customersGivenConsentFromOtherMethods']   
          this.secondreminderCount=response['body']['firstTimeReminderCustomerIds'] - response['body']['reminderCustomerIds']; 
          this.ConsentAlreadySent=response['body']['firstTimeReminderCustomerIds']
          this.ConsentGivenCount=response['body']['reminderCustomersGivenConsent'] + response['body']['customersGivenConsentFromOtherMethods'] 
          this.ReminderGivenConsentCount=response['body']['reminderCustomerIds'];
          if(response['body']['callingCodeCountMap'] != null && this.InvalidCustomer != this.totalCustomer){
            for(const x in response['body']['callingCodeCountMap'])
            {
              //  console.log("helopr"+x)
              if(x=='ALLOWED'){
                this.CustomerNotInUSA=0;
                for(const y in response['body']['callingCodeCountMap']['ALLOWED']){
                  if(y != '+1'){
                    this.CustomerNotInUSA+=response['body']['callingCodeCountMap']['ALLOWED'][y]
                  }
                  this.customerCount+=response['body']['callingCodeCountMap']['ALLOWED'][y]
                }
              }
              else if(x=='NOT_ALLOWED'){
                for(const y in response['body']['callingCodeCountMap']['NOT_ALLOWED']){
                  this.NotAllowedCustomer+=response['body']['callingCodeCountMap']['NOT_ALLOWED'][y]
                }
              }
              else{

              }
            }
          }
          else{
            this.NotAllowedCustomer=0;
            this.customerCount=0;
          }
        })
        this.apiloading=false;
      }
      else{
        this.isAllCustomer=false;
        this._apiCall.getSegmentCustomersCount(this.selectedSegments).subscribe((response)=>{
          this.apiloading=true;
          this.totalCustomer=response['body']['totalCustomerCount']
          this.InvalidCustomer=response['body']['customersNotHavingValidMobileNumberCount']
          this.ReminderCustomer=response['body']['reminderCustomerIds'] - response['body']['reminderCustomersGivenConsent'] ;
          this.FirstTimeCustomer=response['body']['totalAllowedCustomerIds'] - response['body']['firstTimeReminderCustomerIds'] - response['body']['customersGivenConsentFromOtherMethods'] 
          this.secondreminderCount=response['body']['firstTimeReminderCustomerIds'] - response['body']['reminderCustomerIds'];
          this.ConsentAlreadySent=response['body']['firstTimeReminderCustomerIds']
          this.ConsentGivenCount=response['body']['reminderCustomersGivenConsent'] + response['body']['customersGivenConsentFromOtherMethods'] 
          this.ReminderGivenConsentCount=response['body']['reminderCustomerIds'];
          if(response['body']['callingCodeCountMap'] != null && this.InvalidCustomer != this.totalCustomer){
            for(const x in response['body']['callingCodeCountMap'])
            {
              if(x=='ALLOWED'){
                this.CustomerNotInUSA=0;
                for(const y in response['body']['callingCodeCountMap']['ALLOWED']){
                  if(y != '+1'){
                    this.CustomerNotInUSA+=response['body']['callingCodeCountMap']['ALLOWED'][y]
                  }  
                  this.customerCount+=response['body']['callingCodeCountMap']['ALLOWED'][y]
                }
              }
              else if(x=='NOT_ALLOWED'){
                for(const y in response['body']['callingCodeCountMap']['NOT_ALLOWED']){
                  this.NotAllowedCustomer+=response['body']['callingCodeCountMap']['NOT_ALLOWED'][y]
                }
              }
              else{

              }
            }
          }
          else{
            this.NotAllowedCustomer=0;
            this.customerCount=0;
          }
        })
        this.apiloading=false;
      }
    }
    else{
      this.segmentLength=false
    }
  }
  }
  SendSMSConsent(){
      this.submitted=true;
      this.templateName=this.tname.name
      this.templeteId=this.tname.id
      var height 
      if(this.NotAllowedCustomer == 0 && ((this.FirstTimeCustomer >0 && this.reminderStatus=='0') || (this.ReminderCustomer>0 && this.reminderStatus=='1'))){
        height='220px'
      }
      else if((this.FirstTimeCustomer ==0 && this.reminderStatus=='0') || (this.ReminderCustomer==0 && this.reminderStatus=='1')){
        height='275px'
      }
      else{
        height='300px'
      }
  
      if(this.submitted==true && this.segmentLength == true && this.charCount > 0){
        const dialogRef=this.dialog.open(SendSmsConsentDialogBoxComponent,{
          panelClass:"no-padding-dialog-popup",
          width:"540px",
          height:height,
          data:{
            segment: this.selectedSegments,
            reminderStatus: this.reminderStatus,
            templeteName : this.tname.name,
            templeteId: this.templeteId,
            CustomerNotInUSA :this.NotAllowedCustomer,
            FirtTimeCustomer :this.FirstTimeCustomer,
            ReminderCustomer :this.ReminderCustomer,
            secondreminderCount :this.secondreminderCount,
            customerCount :this.customerCount,
            ConsentGivenCount: this.ConsentGivenCount,
            InvalidCustomer: this.InvalidCustomer,
            totalCustomer: this.totalCustomer
          }
        })
        dialogRef.afterClosed().subscribe((result)=>{
          if(result){
            const dialogRef2=this.dialog.open(SuccessDialogComponent,{
              panelClass:"no-padding-dialog-popup",
            width:"20%",
            data:{
              message:this._i18nDynamicTranslate.transform("SMS consent has been sent successfully", ['POS_PAGE']),
              buttonText: this._i18nDynamicTranslate.transform("OK", ['POS_PAGE'])
            }
            })
            dialogRef2.afterClosed().subscribe((result)=>{
              if(result){
                this.router.navigate(["/app/sms/choose-layout"])
              }
            })
          }
          else{
            dialogRef.close();
          }
        })
      }
  }
  shopUrl;
  gettemplate(){
    this.templetes=[];
    if(this.reminderStatus=='0'){
    this._apiCall.getSMSConsentTemplate('CONSENT_FIRST_TIME').subscribe((response)=>{
      let templete = response['body']
      for (let i = 0; i < templete.length; i++) {
        this.templetes.push({
          id: templete[i]["id"],
          name: templete[i]["name"],
          layoutData: templete[i]["layoutData"]
        });
      }

      // console.log(response)
      // // console.log(response['body'][0]['layoutData'])
      // this.smsText=response['body'][0]['layoutData'];
      // this.charCount=this.smsText.length
      // this.shopUrl=this.tokenstorage.getShopName()
      // console.log(this.shopUrl)
      // this.smsText=this.smsText.replace("{{CustomerName}},","John,")
      // this.smsText=this.smsText.replace("{{shopUrl}}",this.shopUrl)

      // for (const x in response['body'][0]['layoutData']){
      //   console.log(x)
      // } 
    })
  }
  else{
    this._apiCall.getSMSConsentTemplate('CONSENT_REMINDER').subscribe((response)=>{
      let templete = response['body']
      for (let i = 0; i < templete.length; i++) {
        this.templetes.push({
          id: templete[i]["id"],
          name: templete[i]["name"],
          layoutData: templete[i]["layoutData"]
        });
      }
    })

  }
  }
  getSmsDetails(){
    this.smsText=this.tname.layoutData
    // this.templateName=this.tname.name
    // this.templeteId=this.tname.id
    this.previewText=this.smsText;
    this.shopUrl=this.tokenstorage.getShopName()
    this.previewText=this.previewText.replace("{{customerName}}","John")
    this.previewText=this.previewText.replace("{{merchantName}}",this.shopUrl)
    this.charCount=this.previewText.length

  }
  changeTempleteSelection(){
    this.getSmsDetails();
  }
  changeTemplete(){
    this.tname=null;
    this.previewText=""
    this.smsText=""
    this.charCount=0;
    this.gettemplate();
  }
}
