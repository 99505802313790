import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-domain-confirmation-dialog-pop-up',
  templateUrl: './domain-confirmation-dialog-pop-up.component.html',
  styleUrls: ['./domain-confirmation-dialog-pop-up.component.css']
})
export class DomainConfirmationDialogPopUpComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DomainConfirmationDialogPopUpComponent>,
    @Inject(MAT_DIALOG_DATA)public data:any
  ) { }

  ngOnInit() {
  }
  continue(decision){
    this.dialogRef.close(decision)
  }
  submit(decision){
    this.dialogRef.close(decision)
  }
  
}
