<div class="orderSummaryOutside">
    <div *ngIf="(subplanId==1||subplanId==2||subplanId==3||subplanId==4||subplanId==5);else elseblock">
        <div class="whiteContainer">
            <div class="DMPoster" style="width:20%" [style.background]=" 'url('+imagePath1+ ')' " [style.background-repeat]="repeat "></div>
            <div class="planDetails" style="width:40%">
                <div style="text-align: left;
          letter-spacing: 0;
          color: #000000;
          opacity: 1; margin-top: 40px; margin-bottom: 8px;">{{'PROFILE_PAGE.SELECTION_PACK' | i18nTranslate}}</div>
                <div style="text-align: left;
           letter-spacing: 0;
           color: #000000;
           opacity: 1;margin-bottom: 12px;">{{'PROFILE_PAGE.PLAN' | i18nTranslate}}: {{PlanSelDes.planName}}</div>
                <div style="text-align: left;
            letter-spacing: 0;
            color: #000000;
            opacity: 1;margin-bottom: 12px;">{{'PROFILE_PAGE.SUBSCRIPION_PERIOD' | i18nTranslate}}: {{PlanSelDes.subscriptionPeriod}}</div>
                <div></div>
                <div></div>


                <div style=" text-align: left;  letter-spacing: 0; color: #000000; opacity: 1; margin-bottom: 8px; ">
                    {{'PROFILE_PAGE.INCLUDES' | i18nTranslate}}</div>
                <div style="display:flex; text-align: left;  letter-spacing: 0;align-items: center; color: #000000; opacity: 1;margin-bottom: 12px; " *ngFor="let inclu of PlanSelDes['inclusions']">
                    <mat-icon>arrow_right</mat-icon> {{inclu.replace('_', ' ')|titlecase}}</div>





            </div>
            <div class="priceDetails ">
                <div style="height: auto; background: #FFFFFF 0% 0% no-repeat padding-box; border: 2px solid #EEF4F7; border-radius: 4px; opacity: 1;padding: 32px 30px 30px 32px; ">

                    <div style="text-align: left;letter-spacing: 0;color: #000000; opacity: 1;text-transform:uppercase;text-align: left;  ">
                       {{'PROFILE_PAGE.PRICE_SUMMARY' | i18nTranslate}} (in {{PlanSelDes.priceCurrency}} )</div>
                    <div style="border-bottom: 1px solid #707070; opacity: 0.1;margin-bottom: 8px; "></div>

                    <div style="display: flex;width: 100%;
                justify-content: space-between;">
                        <div>{{'PROFILE_PAGE.PLAN_FEE' | i18nTranslate}} :</div>
                        <div>{{PlanSelDes.price|currency:PlanSelDes.priceCurrency}}</div>
                    </div>

                </div>
            </div>

        </div>
        <div class="logoContainer ">
            <!-- <div style="text-align: center;  letter-spacing: 0; color: #030303; opacity: 1;margin:12px ">
                How would you like to pay?</div>

            <div style="text-align: center;  letter-spacing: 0; color: #030303; opacity: 1;margin-bottom: 20px; ">
                Select one of these options</div>

            <div style="display: flex; ">
                <div style="width: 170px;height: 80px;background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 6px;opacity: 1;display: flex;align-items: center; display:flex;">
                    <div style="display: flex;align-items: center;margin: 6%; "><input type="radio" (click)="model.option=Razorpay" name="payOption" value="razorpay" checked></div>
                    <div style="width: 106px;height: 22px; " [style.background]=" 'url('+imagePath2+ ')' " [style.background-repeat]="repeat ">
                    </div>
                </div>
            </div> -->


            <div style="width: 161px; height: 40px; background: transparent linear-gradient(6deg, #07B6B6 0%, #1193D9 100%) 0% 0% no-repeat padding-box; box-shadow: 0px 2px 3px #BABABA29; border-radius: 20px; opacity: 1;display: flex;align-items: center;justify-content:
                center; color: white;cursor: pointer; margin-top: 5%;" (click)="shopifyOrderCreate()" (disabled)="payOption==razorpay" *ngIf="loading == false;else loadSpinner">
                <div>{{'PROFILE_PAGE.PAY' | i18nTranslate}}</div>
            </div>
            <ng-template #loadSpinner>
                <div style="display: flex;justify-content: center;width: 100%;height: 100%;align-items: center;">
                    <mat-spinner diameter="45"></mat-spinner>
                </div>
            </ng-template>

        </div>
    </div>
    <ng-template #elseblock>
        <div class="whiteContainer" style="justify-content: center;">
            <div>{{'PROFILE_PAGE.INVALID_PAGE' | i18nTranslate}}</div>
        </div>
    </ng-template>

</div>