import { Component, OnInit ,EventEmitter, Output} from '@angular/core';
import { getLogoUrlService } from 'src/app/core/services/get-logo-url.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-alert',
  templateUrl: './mobile-alert.component.html',
  styleUrls: ['./mobile-alert.component.css']
})
export class MobileAlertComponent implements OnInit {

  @Output() messageEvent2 = new EventEmitter<any>();
  s3BucketUrl=environment.s3BucketUrl;
  logoUrl = this.getLogoService.getLogo();
  
  imgUrl=this.s3BucketUrl +"/greyfox-assets/assets2/icons/illustration.svg";
  constructor(
    private getLogoService: getLogoUrlService
  ) { }

  ngOnInit() {
  }
  sendConfirmMessage() {
    this.messageEvent2.emit(1);
  }
}
