import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-collection-list-dialog",
  templateUrl: "./collection-list-dialog.component.html",
  styleUrls: ["./collection-list-dialog.component.css"],
})
export class CollectionListDialogComponent implements OnInit {
  search = "";
  selectedItems = []; //array with collectionIds only
  selectedObj = [];//array with collection object
  loading = true;
  databean;
  constructor(
    public dialogRef: MatDialogRef<CollectionListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.databean = JSON.parse(JSON.stringify(this.data)); //deep copy
    var k = this.databean.selectedCollectionList;

    if (this.databean.searchString != null) {
      this.search = this.databean.searchString; //search string
    }
    this.setSelected(k);
  }

  setAll(checked, product) {
    if (checked) {
      this.selectedItems.push(product.collectionId); //add to array
      this.selectedObj.push(product);
    } else {
      this.selectedItems.splice(
        this.selectedItems.indexOf(product.collectionId),//remove from array
        1
      );
      this.selectedObj.splice(this.selectedItems.indexOf(product), 1);
    }
  }

  save() {
    let obj = { variantIdArray: this.selectedObj, search: this.search };//send selected collection object
    this.dialogRef.close(obj);
  }
  cancel() {
    this.dialogRef.close(undefined); //close dialog
  }

  setSelected(items) {
    for (let i = 0; i < items.length; i++) {
      this.selectedItems.push(items[i].collectionId); //add to array
      this.selectedObj.push(items[i]);
    }
    this.loading = false;
  }
  selectAll(checked){
    var i = 0;
    let product = this.databean.allCollection
    for(i = 0; i < product.length; i++){
      if (checked) {
        this.selectedItems.push(product[i].collectionId); //add to array
        this.selectedObj.push(product[i]);
      } else {
        this.selectedItems.splice(
          this.selectedItems.indexOf(product[i].collectionId),//remove from array
          1
        );
        this.selectedObj.splice(this.selectedItems.indexOf(product[i]), 1);
      }
    }
  }
}
