import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { AddNewCustomerComponent, CustomerProfileComponent, FilterDialogComponent, SegmentDashboardComponent } from './pages';
import { CustomerRoutingModule } from './customer-routing.module';
import { CustomerUploadHistoryComponent } from './pages/customer-upload-history/customer-upload-history.component';



@NgModule({
  declarations: [
    CustomerProfileComponent,
    FilterDialogComponent,
    SegmentDashboardComponent,
    AddNewCustomerComponent,
    CustomerUploadHistoryComponent
  ],
  imports: [
    SharedModule,
    CustomerRoutingModule
  ]
})
export class CustomersModule { }
