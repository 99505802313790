<div
    style="display: flex;width: 100%;height: 100%;justify-content: center;align-items: center;padding:24px;overflow-y: auto ;">
    <div class="gf-mask" *ngIf="paymentSuccessStatus==true&&!loading ">
        <div class="gf-text-area-cont">
            <div style="background: #fff;margin: 0 24px;">
                <div style="font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%; color: #000000;
       "><span style="    position: relative;    display: inline-block;">{{confirmationMsgTitle | i18nDynamicTranslate : 'POS_PAGE'}}</span> <span><img
                            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/done.gif"
                            style="    width: 90px;
    height: 90px;
    margin-left: -21px;
    margin-bottom: 5px;" alt=""></span></div>
                <div style="font-size: 16px;;line-height: 150%;color: #222222;" *ngIf="chargeType!='top-up'"
                    [innerHtml]="descmsg | safeHtml">
                </div>
                <div style="font-size: 16px;
    line-height: 150%;
    color: #222222;" *ngIf="chargeType=='top-up'"> {{'PROFILE_PAGE.TOP_UP_ADDED_MESSAGE' | i18nTranslate}}

                </div>
                <div style="font-size: 16px;
                line-height: 150%;
                color: #222222;" *ngIf="chargeType=='PARTNERSHIP'"> {{'PROFILE_PAGE.PARTNERSHIP_READY_TO_USE_MESSAGE' | i18nTranslate}}

                            </div>
                <!-- <br> -->
                <!-- <div style="font-size: 16px;;
line-height: 150%;
color: #222222;" *ngIf="chargeType=='top-up'"> Below are the details of the top up.</div>
                <div style="font-size: 16px;;
            line-height: 150%;
            color: #222222;
    margin-bottom: 12px;" *ngIf="chargeType!='top-up'">Below are the features included in your plan</div> -->

                <div style="display: flex;flex-direction: column;">

                    <!-- <div class="gf-price-includes-value" *ngIf="currentPlanBody.totalEmails>0">
                        <div class="gf-info-done">
                            <mat-icon class="gf-tick-mark">check_circle</mat-icon>
                        </div>
                        <div>{{currentPlanBody.totalEmails}} email credits add on</div>
                    </div>
                    <div class="gf-price-includes-value" *ngIf="currentPlanBody.totalSMS>0">
                        <div class="gf-info-done">
                            <mat-icon class="gf-tick-mark">check_circle</mat-icon>
                        </div>
                        <div>{{currentPlanBody.totalSMS}} SMS credits add on</div>
                    </div> -->

                    <div class="primary-button gf-select-btn" (click)="backToHome()">{{buttonHomeText | i18nDynamicTranslate : 'POS_PAGE'}}</div>
                </div>
            </div>
        </div>
        <div style="text-align: center;" class="gf-gif-area-cont">
            <img class="gf-area-img-cont"
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/jump.gif" style="width: 300px;
            height: 300px;" alt="">
        </div>
    </div>

    <div class="gf-mask" style="justify-content: center;" *ngIf="paymentSuccessStatus==false&&!loading">
        <div>{{'PROFILE_PAGE.PAYMENT_FAILED' | i18nTranslate}}</div>
    </div>

    <div class="gf-mask" style="justify-content: center;align-items: center;min-height:250px" *ngIf="loading">
        <mat-spinner diameter="20"></mat-spinner>
    </div>
</div>