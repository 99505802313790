import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-disable-form',
  templateUrl: './disable-form.component.html',
  styleUrls: ['./disable-form.component.css']
})
export class DisableFormComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DisableFormComponent>
  ) {}

  ngOnInit(): void {}

  submit(decision) {
    this.dialogRef.close(decision);
  }

}
