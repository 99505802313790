import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { dataBase } from 'src/app/shared/models/model';
import { assetPath } from 'src/app/configs/asset-path-config';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/core/services/date-format.service';
import { FontCollection } from 'src/app/core/services/fonts-collection';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';

@Component({
  selector: 'app-dialog-overview',
  templateUrl: './dialog-overview.component.html',
  styleUrls: ['./dialog-overview.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})

export class DialogOverviewComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataBase: dataBase,
    private router: Router,
    private apiCall: ApiCallService,
    private font: FontCollection,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService) { }


  rewardTypes: string[] = ['product', 'discount', 'points', 'product discount']
  selectedReward: string = this.rewardTypes[0]
  fontCollection = this.font.getFontCSS();
  layoutEmailDescription: string;
  //showLayoutDescriptionTextArea : boolean = false;
  //layoutId;
  layoutEmailSubject;
  messageVariables;
  days = [{ name: 'SUNDAY', status: false },
  { name: 'MONDAY', status: false },
  { name: 'TUESDAY', status: false },
  { name: 'WEDNESDAY', status: false },
  { name: 'THURSDAY', status: false },
  { name: 'FRIDAY', status: false },
  { name: 'SATURDAY', status: false }]
  selectedDays: [];
  // mentionConfig = {
  //   mentions: [
  //     {
  //       items: ["Noah", "Liam", "Mason", "Jacob"],
  //       triggerChar: "@",
  //       returntrigger : false,
  //     }
  //   ]
  // }
  assetPath = {
    arrowUrl: assetPath.path + "/Group 1393.svg",
  }
  //selectedbarrier;
  messageTemplate;
  scheduledCampaignEndType = "DATE";
  occurrenceCountForCampaignSchedule = 1;

  ngOnInit() {
    // if (this.data.type === 'OfferTemplateDetailedView') {
    //   this.selectBarrier(this.data['data']['barrierList'][0])
    // }
    if (this.data.type === 'campaignLayout') {
      if (this.data['operation'] === 'UPDATE') {
        this.layoutEmailSubject = this.data['subject']
        this.layoutEmailDescription = this.data['description']
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event['path'][0]['id'] === 'descriptionText') {
      if (event['ctrlKey'] === true && event['code'] === 'Space') {
      }
    }
  }

  // constructor(
  //   public dialogRef: MatDialogRef<DialogOverviewExampleDialog>) {}

  campaignStartDate = new FormControl(new Date());
  campaignEndDate = new FormControl(new Date());
  dateRangeValidity = false;

  gotoAddTemplate(type) {
    if (type === 'email' || type === 'sms') {
      this.router.navigate(['app/generate-email']);
      this.dialogRef.close();
    }
    if (type === 'offer') {
      this.router.navigate(['offermanager/creation']);
      this.dialogRef.close();
    }
  }

  selectTemplate(subject, body, id, offerId, type) {
    let result;
    if (type === 'offer') {
      result = {
        'subject': subject,
        'body': body,
        'id': null,
        'offerMasterId': offerId
      }
    }
    else {
      result = {
        'subject': subject,
        'body': body,
        'id': id,
        'offerMasterId': null
      }
    }
    this.dialogRef.close(result)
  }

  selectTemplateForEmail(offer) {
    this.dialogRef.close(offer)
  }

  convertMinutes(minute) {
    if (!(minute % 43800)) {
      return (minute / 43800)
    }
    else if (!(minute % 10080)) {
      return (minute / 10080)
    }
    else if (!(minute % 1440)) {
      return (minute / 1440)
    }
  }

  delayType(minute) {
    if (!(minute % 43800)) {
      return ("Month(s)")
    }
    else if (!(minute % 10080)) {
      return ("Week(s)")
    }
    else if (!(minute % 1440)) {
      return ("Day(s)")
    }
  }

  submitCampaignTemplate(status) {
    this.dialogRef.close(status)
  }

  submitCampaign(status, data) {
    this.dateRangeValidity = false;
    if (data.triggerType.campaignTriggerType === 'TIME_TRIGGER' || data.triggerType.campaignTriggerType === 'EVENT_TRIGGER') {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1)
      if (this.scheduledCampaignEndType === 'DATE') {
        if (this.campaignEndDate.value < this.campaignStartDate.value || currentDate > this.campaignStartDate.value) {
          this.dateRangeValidity = true;
          return;
        }
      }
      else {
        if (currentDate > this.campaignStartDate.value) {
          this.dateRangeValidity = true;
          return;
        }
      }
    }
    let returnData
    //this.dialogRef.close(status)
    if (status) {
      if (data.triggerType.campaignTriggerType === 'INSTANT_TRIGGER') {
        returnData = {
          type: 'INSTANT_TRIGGER'
        }
        this.dialogRef.close(returnData)
      }
      if (data.triggerType.campaignTriggerType === 'TIME_TRIGGER') {
        if (data.triggerType.campaignTriggerRepeatType === 'NO_REPEAT') {
          returnData = {
            type: 'TIME_TRIGGER',
            repeat: false,
            daysOfTheWeek: this.selectedDays,
            startDate: this.campaignStartDate.value,
            endDate: this.scheduledCampaignEndType === 'DATE' ? this.campaignEndDate.value : null,
            occurrence: this.scheduledCampaignEndType === 'COUNT' ? this.occurrenceCountForCampaignSchedule : null
          }
          this.dialogRef.close(returnData)
        }
        else {
          returnData = {
            type: 'TIME_TRIGGER',
            repeat: true,
            daysOfTheWeek: this.selectedDays,
            startDate: this.campaignStartDate.value,
            endDate: this.scheduledCampaignEndType === 'DATE' ? this.campaignEndDate.value : null,
            occurrence: this.scheduledCampaignEndType === 'COUNT' ? this.occurrenceCountForCampaignSchedule : null
          }
          this.dialogRef.close(returnData)
        }
      }
      if (data.triggerType.campaignTriggerType === 'EVENT_TRIGGER') {
        returnData = {
          type: 'EVENT_TRIGGER',
          startDate: this.campaignStartDate.value,
          endDate: this.scheduledCampaignEndType === 'DATE' ? this.campaignEndDate.value : null,
          occurrence: this.scheduledCampaignEndType === 'COUNT' ? this.occurrenceCountForCampaignSchedule : null
        }
        this.dialogRef.close(returnData)
      }
    }
    else this.dialogRef.close(false)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectVariable(variable) {
    this.dialogRef.close(variable)
  }

  // selectLayout(id,messageType,offerId){
  //   this.apiCall.getAllMessageTemplateVariablesforOffer('EMAIL_TEMPLATE',messageType,offerId).subscribe((response)=>{

  //       this.messageVariables = JSON.parse(response['body'])
  //       this.mentionConfig.mentions[0].items = this.messageVariables;
  //     }
  //     else{
  //       this.snackBar.openSnackBar('Failed to load Message variables','')
  //     }
  //   },
  //   (err)=>{
  //     this.snackBar.openSnackBar('Failed to load resources','')
  //   })
  //   this.layoutId = id;
  //   this.showLayoutDescriptionTextArea = true;
  //   this.dialogRef.close(id)
  // }

  selectCustomEmail(messageId) {
    this.dialogRef.close(messageId)
  }


  selectIndexForEmailLayout(feedback) {
    if (feedback) {
      let result = {
        'medium': this.data['medium'],
        'layoutDescripton': this.layoutEmailDescription,
        'layoutSubject': this.data['medium'] === 'EMAIL_DELIVERY' ? this.layoutEmailSubject : null
      }
      this.dialogRef.close(result)
    }
    else {
      this.dialogRef.close();
    }
  }

  viewMessageTemplateDetail(actionType, messageTemplateId, templateType) {
    if (actionType === 'EMAIL_DELIVERY') {
      if (templateType === 'CUSTOM_LAYOUT') {
        this.apiCall.getMessageTemplate(messageTemplateId).subscribe((response) => {
          let messageTemplate = JSON.parse(response['body'])
          messageTemplate = JSON.parse(messageTemplate['templateJson'])
          this.messageTemplate = {
            'type': 'CUSTOM_LAYOUT',
            'subject': messageTemplate['subject'],
            'body': messageTemplate['body']
          }
        },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load resource", ['SHARED_PAGE']), 2000)
          })
      }
      if (templateType === 'STANDARD_LAYOUT') {
        this.apiCall.getEmailLayoutDetail(messageTemplateId).subscribe((response) => {
          let messageTemplate = JSON.parse(response['body'])
          this.messageTemplate = {
            'type': 'STANDARD_TEMPLATE',
            'subject': null,
            'body': messageTemplate['source']
          }
        },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load resource", ['SHARED_PAGE']), 2000)
          })
      }
    }
  }

  selectOfferForCampaign(offerId) {
    this.dialogRef.close(offerId)
  }

  // selectBarrier(barrier) {
  //   this.selectedbarrier = barrier;
  // }

  tester(event) {
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

}
