<div>
  <div style="display: flex;width: 100%;justify-content: flex-end;">
<div style="cursor: pointer;" (click)="submit()">X</div>
  </div>
  <div style="display:flex; flex-direction: column; justify-content:center;font-size: 14px;">
    <div>
      <!-- <div class="dataTitle">
              <!-- {{data['header']}} -->
      <!-- Image Options -->
    </div>


    <div style=" margin-bottom: 15px;">
      <p style="font-size: 14px;
              margin-bottom: 10px;">{{'SHARED_PAGE.BEEEDITOR_PARAGRAHN_ONE' | i18nTranslate}} </p>

      <!-- <div *ngFor="let imageTag of data">
                  <div *ngIf="imageTag['name'].indexOf('product') !=-1">
                      <div>
                          {{imageTag.name}} : {{imageTag.value}}
                      </div>

                  </div>
              </div> -->
      <p>{{'SHARED_PAGE.BEEEDITOR_PARAGRAHN_TWO' | i18nTranslate}}
      </p>

      <ol>
        <li>{{'SHARED_PAGE.BEEEDITOR_LIST_ONE' | i18nTranslate}}</li>
        <li>{{'SHARED_PAGE.BEEEDITOR_LIST_TWO' | i18nTranslate}}</li>
        <li>{{'SHARED_PAGE.BEEEDITOR_LIST_THREE_ONE' | i18nTranslate}} <strong>"{{'SHARED_PAGE.ALTERNATE_TEXT' | i18nTranslate}}"</strong>
          {{'SHARED_PAGE.BEEEDITOR_LIST_THREE_TWO' | i18nTranslate}}</li>
      </ol>


      <table id="customers">
        <tr>
          <th>{{'SHARED_PAGE.IMAGE_BLOCK_NAME' | i18nTranslate}}</th>
          <th>{{'SHARED_PAGE.IMAGE_BLOCK_VALUE' | i18nTranslate}}</th>
        </tr>
        <tr *ngFor="let imageTag of imageTags">
          <td>
 {{imageTag.name.replace('URL','')}}
          </td>
          <td  style="display: flex;justify-content: space-between;">
            <span>{{imageTag.value}}</span>
            <div>
              <div type="button" ngbPopover="Copied!" placement="top">
                <mat-icon (click)="copyText(imageTag.value)">content_copy</mat-icon>
              </div>
            </div>
          </td>
        </tr>

      </table>

    </div>


    <div style="display: flex; width: 100%; justify-content: center;margin-bottom: 20px;">
      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/samplepersonalizedMock.gif"
        alt="" style="height: 50%;
      width: 50%;
      border: 3px solid #c2c2c2;
      border-radius: 5px;">
    </div>


    <div style="display: flex;width: 100%;justify-content: center;color: white;">
      <button style=" background:#636771; outline: none;width:40%;
            outline: none;
            -webkit-appearance: none;
            border: 0;
            padding: 10px 12px;
            color: #FFF;
            border-radius: 4px;" (click)="submit()">{{'SHARED_PAGE.OK' | i18nTranslate}}</button>
    </div>

  </div>
</div>
<!-- </div> -->