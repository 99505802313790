<!-- <div > -->

<div style="width: 100%; height: 100vh" class="offerDispatchHead">
  <div class="" style="height: 100%; width: 100%">
    <div
      style="
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        width: 100%;
      "
    >
      <div
        style="
          width: 60%;
          display: flex;
          flex-direction: column;
          background-color: #fff;
        "
      >
        <div class="configLabelFirst">
          <div>{{'OFFER_PAGE.RESCHEDULE_OFFER' | i18nTranslate}}</div>
          <div>
            <mat-icon
              (click)="sendcancelMessage('close')"
              style="cursor: pointer"
              >close</mat-icon
            >
          </div>
        </div>
        <div class="seperator"></div>

        <div
          style="
            flex-grow: 1;
            overflow: auto;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
          "
        >
          <div class="seperator"></div>
          <div [formGroup]="templateDispatchForm" style="padding: 15px">
            <div
              style="
                width: 100%;
                justify-content: space-between;
                margin-bottom: 14px;
              "
            >
              <div style="width: 45%; margin-bottom: 20px">
                <div class="scheduleBoldText">
                  <span>{{'OFFER_PAGE.CUSTOMER_SEGMENTS' | i18nTranslate}}</span
                  ><span
                    class="i-div"
                    popoverClass="my-custom-class"
                    placement="right"
                    [ngbPopover]="popContentCustomerSegments"
                    triggers="mouseenter:mouseleave"
                  >
                    i
                  </span>
                </div>
                <!-- <div class="formDiv" style="width: 100%" (click)="selectOpenclose('segmentOpen')">
                  <mat-select
                  #segmentOpen
                    (selectionChange)="changeSegmentSelection()"
                    class="fontExo2"
                    color="primary"
                    placeholder="Select customer segment"
                    formControlName="dispatchcustomerSegments"
                    multiple
                    disableOptionCentering
                    panelClass="myPanelClass"
                  >
                    <div class="Rectangle">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          border-bottom: 1px solid #c2c2c2;
                          height: 45px;
                        "
                      >
                        <mat-icon
                          style="color: #c0c4d0; margin: 0px 8px 0px 10px"
                          matPrefix
                          >search
                        </mat-icon>
                        <input
                          style="border: none; outline: none; height: 40px"
                          type="text"
                          formControlName="tempsegmentFilter"
                          placeholder=" Search by name "
                        />
                      </div>
                    </div>
                    <div class="matSelectDropdowns">
                      <mat-option
                        *ngFor="
                          let segment of allcustomerObj
                            | labelPipe
                              : templateDispatchForm.controls.tempsegmentFilter
                                  .value
                              : 'name'
                        "
                        [value]="segment.id"
                        [disabled]="disableAllCustomerSelection"
                        class="fontExo2"
                        [value]="segment.id"
                        color="primary"
                      >
                        {{ segment.name }}
                      </mat-option>

                      <mat-option
                        class="fontExo2"
                        *ngFor="
                          let segment of segments
                            | labelPipe
                              : templateDispatchForm.controls.tempsegmentFilter
                                  .value
                              : 'name'
                        "
                        [value]="segment.id"
                        [disabled]="
                          disableCustomerSegmentSelection ||
                          !segment['isActive']
                        "
                        [matTooltip]="
                          segment['isActive']
                            ? ''
                            : 'We are preparing your segment. It will be available for use shortly'
                        "
                        color="primary"
                      >
                        {{ segment.name }}
                      </mat-option>
                    </div>
                  </mat-select>
                  <div
                    _ngcontent-c14=""
                    class="keyboard-down-icon-container"
                    id="keyboard-down-icon-container"
                    style="transform: rotate(0deg); cursor: pointer;"
                    
                  >
                    <mat-icon
                    (click)="changeSegmentSelection()"
                      _ngcontent-c14=""
                      class="
                        keyboard-down-icon
                        mat-icon
                        notranslate
                        material-icons
                        mat-icon-no-color
                      "
                      role="img"
                      aria-hidden="true"
                      >keyboard_arrow_down</mat-icon
                    >
                  </div>
                </div> -->
                <div class="qb-form-div-schedule qb-w-100 cursor-pointer"
                (click)="selectOpenclose('customerSegmentsSelect')">
                <mat-select #customerSegmentsSelect (selectionChange)="changeSegmentSelection()" class="qb-font-exo2"
                  color="primary" placeholder="Select customer segment/tiers/lists"
                  formControlName="dispatchcustomerSegments" multiple disableOptionCentering
                  panelClass="myPanelClass">
                  <div class="qb-rectangle">
                    <div class="qb-rect">
                      <mat-icon class="qb-icon" matPrefix>search </mat-icon>
                      <input class="qb-no-border-input" type="text" formControlName="tempsegmentFilter"
                        placeholder=" Search by name " />
                    </div>
                  </div>
                  <div class="qb-mat-select-dropdowns">
                    <mat-option *ngFor="
                        let segment of allcustomerObj
                          | labelPipe
                            : templateDispatchForm.controls.tempsegmentFilter
                                .value
                            : 'name'
                      " [value]="segment.id" [disabled]="disableAllCustomerSelection" class="qb-font-exo2"
                      [value]="segment.id">
                      {{ segment.name }}
                    </mat-option>
                    <div class="category-text-container">
                      <div class="category-line"></div>
                      <span class="category-text">{{'OFFER_PAGE.SEGMENTS' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <mat-option class="qb-font-exo2" *ngFor="
                        let segment of customerSegmentList
                          | labelPipe
                            : templateDispatchForm.controls.tempsegmentFilter
                                .value
                            : 'name'
                      " [value]="segment.id" [disabled]="
                        disableCustomerSegmentSelection ||
                        !segment['isActive']
                      " [matTooltip]="
                        segment['isActive']
                          ? ''
                          : 'We are preparing your segment. It will be available for use shortly'
                      ">
                      {{ segment.name }}
                    </mat-option>
                    <div class="category-text-container" *ngIf="customerTierList.length > 0">
                      <div class="category-line"></div>
                      <span class="category-text">{{'OFFER_PAGE.TIERS' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <mat-option class="qb-font-exo2" *ngFor="
                        let segment of customerTierList
                          | labelPipe
                            : templateDispatchForm.controls.tempsegmentFilter
                                .value
                            : 'name'
                      " [value]="segment.id" [disabled]="
                        disableCustomerSegmentSelection ||
                        !segment['isActive']
                      " [matTooltip]="
                        segment['isActive']
                          ? ''
                          : 'We are preparing your segment. It will be available for use shortly'
                      ">
                      {{ segment.name }}
                    </mat-option>
                    <div class="category-text-container" *ngIf="customerList.length > 0">
                      <div class="category-line"></div>
                      <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <mat-option class="qb-font-exo2" *ngFor="
                        let segment of customerList
                          | labelPipe
                            : templateDispatchForm.controls.tempsegmentFilter
                                .value
                            : 'name'
                      " [value]="segment.id" [disabled]="
                        disableCustomerSegmentSelection ||
                        !segment['isActive']
                      " [matTooltip]="
                        segment['isActive']
                          ? ''
                          : 'We are preparing your segment. It will be available for use shortly'
                      ">
                      {{ segment.name }}
                    </mat-option>
                    <div class="category-text-container" *ngIf="employeeList.length > 0">
                      <div class="category-line"></div>
                      <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <mat-option class="qb-font-exo2" *ngFor="
                        let segment of employeeList
                          | labelPipe
                            : templateDispatchForm.controls.tempsegmentFilter
                                .value
                            : 'name'
                      " [value]="segment.id" [disabled]="
                        disableCustomerSegmentSelection ||
                        !segment['isActive']
                      " [matTooltip]="
                        segment['isActive']
                          ? ''
                          : 'We are preparing your employee list. It will be available for use shortly'
                      ">
                      {{ segment.name }}
                    </mat-option>
                  </div>
                </mat-select>
              </div>
                <mat-error
                  style="font-size: 12px; text-align: right"
                  *ngIf="
                    !submitted &&
                    templateDispatchForm.controls.dispatchcustomerSegments
                      .touched &&
                    templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                      'required'
                    )
                  "
                >
                  {{'OFFER_PAGE.SEGMENT_REQ' | i18nTranslate}}</mat-error
                >
                <mat-error
                  style="font-size: 12px; text-align: right"
                  *ngIf="
                    submitted &&
                    templateDispatchForm.controls.dispatchcustomerSegments
                      .touched &&
                    templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                      'required'
                    )
                  "
                >
                {{'OFFER_PAGE.SEGMENT_REQ' | i18nTranslate}}</mat-error
                >
              </div>

              <div *ngIf="recAction" style="width: 45%; margin-bottom: 20px">
                <div class="scheduleBoldText">Customers</div>
                <div class="formDiv" style="width: 100%">
                  <!-- <mat-icon style="color: #88c4fb; padding: 10px;">scatter_plot</mat-icon> -->
                  <mat-select
                    (selectionChange)="changeSegmentSelection()"
                    class="fontExo2"
                    color="primary"
                    placeholder="Select customer segment"
                    formControlName="dispatchcustomerSegments"
                    multiple
                    disableOptionCentering
                    panelClass="myPanelClass"
                  >
                    <mat-option
                      [disabled]="disableAllCustomerSelection"
                      class="fontExo2"
                      value="ALL_CUSTOMERS"
                      >{{'OFFER_PAGE.ALL_CUSTOMERS' | i18nTranslate}}
                    </mat-option>
                    <mat-option
                      [disabled]="disableCustomerSegmentSelection"
                      class="fontExo2"
                      *ngFor="let segment of recomentedSegments"
                      [value]="segment.id"
                    >
                      {{ segment.name }}</mat-option
                    >
                  </mat-select>
                </div>
                <mat-error
                  style="font-size: 12px; text-align: right"
                  *ngIf="
                    !submitted &&
                    templateDispatchForm.controls.dispatchcustomerSegments
                      .touched &&
                    templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                      'required'
                    )
                  "
                >
                {{'OFFER_PAGE.SEGMENT_REQ' | i18nTranslate}}</mat-error
                >
                <mat-error
                  style="font-size: 12px; text-align: right"
                  *ngIf="
                    submitted &&
                    templateDispatchForm.controls.dispatchcustomerSegments
                      .touched &&
                    templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                      'required'
                    )
                  "
                >
                {{'OFFER_PAGE.SEGMENT_REQ' | i18nTranslate}}</mat-error
                >
              </div>

              <div style="width: 45%; margin-bottom: 20px">
                <div class="scheduleBoldText">
                  <span>{{'OFFER_PAGE.DISPATCH_TYPE' | i18nTranslate}}</span
                  ><span
                    class="i-div"
                    popoverClass="my-custom-class"
                    placement="right"
                    [ngbPopover]="popContentDispatchType"
                    triggers="mouseenter:mouseleave"
                  >
                    i
                  </span>
                </div>
                <div class="formDiv" style="width: 100%" (click)="selectOpenclose('scheduleOpen')">
                  <mat-select
                  #scheduleOpen
                    class="fontExo2"
                    color="primary"
                    formControlName="dispatchType"
                    disableOptionCentering
                    panelClass="myPanelClass"
                  >
                    <mat-option class="fontExo2" value="INSTANT">
                      {{'OFFER_PAGE.INSTANT' | i18nTranslate}}</mat-option
                    >
                    <mat-option
                      class="fontExo2"
                      value="SCHEDULED"
                      (click)="scheduledClick()"
                    >
                      {{'OFFER_PAGE.SCHEDULE' | i18nTranslate}}</mat-option
                    >
                  </mat-select>
                  <div
                    _ngcontent-c14=""
                    class="keyboard-down-icon-container"
                    id="keyboard-down-icon-container"
                    style="transform: rotate(0deg)"
                  >
                    <mat-icon
                      _ngcontent-c14=""
                      class="
                        keyboard-down-icon
                        mat-icon
                        notranslate
                        material-icons
                        mat-icon-no-color
                        cursor-pointer
                      "
                      role="img"
                      aria-hidden="true"
                      >keyboard_arrow_down</mat-icon
                    >
                  </div>
                  <mat-error
                    style="font-size: 12px; text-align: right"
                    *ngIf="
                      !submitted &&
                      templateDispatchForm.controls.dispatchType.touched &&
                      templateDispatchForm.controls.dispatchType.hasError(
                        'required'
                      )
                    "
                  >
                    {{'OFFER_PAGE.DISPATCH_TYPE_REQ' | i18nTranslate}}</mat-error
                  >
                  <mat-error
                    style="font-size: 12px; text-align: right"
                    *ngIf="
                      submitted &&
                      templateDispatchForm.controls.dispatchType.touched &&
                      templateDispatchForm.controls.dispatchType.hasError(
                        'required'
                      )
                    "
                  >
                  {{'OFFER_PAGE.DISPATCH_TYPE_REQ' | i18nTranslate}}</mat-error
                  >
                </div>
              </div>
            </div>

            <!-- <div > -->
            <div
              style="
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 14px;
              "
              *ngIf="
                templateDispatchForm.controls.dispatchType.value === 'SCHEDULED'
              "
            >
              <!-- <div class="fontExo2">Date</div> -->
              <div style="width: 45%; margin-bottom: 20px">
                <div class="scheduleBoldText">Date</div>
                <div class="formDiv" style="width: 100%">
                  <input
                    style="
                      border: none;
                      width: 90%;

                      outline: none;
                    "
                    (dateChange)="startDateChange()"
                    [matDatepicker]="picker2"
                    formControlName="dispatchDate"
                    [min]="startDateMinValue"
                    readonly
                  />
                  <!-- <div class="rp-name-inp">
                    {{dispatchDate.value | date: getDateFormat() }}
                </div> -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>


                </div>
                <mat-error
                style="font-size: 12px; text-align: right"
                *ngIf="
                  !submitted &&
                  templateDispatchForm.controls.dispatchDate.touched &&
                  templateDispatchForm.controls.dispatchDate.hasError(
                    'required'
                  )
                "
              >
                {{'OFFER_PAGE.DATE_REQ' | i18nTranslate}}</mat-error
              >
              <mat-error
                style="font-size: 12px; text-align: right"
                *ngIf="
                  submitted &&
                  templateDispatchForm.controls.dispatchDate.touched &&
                  templateDispatchForm.controls.dispatchDate.hasError(
                    'required'
                  )
                "
              >
              {{'OFFER_PAGE.DATE_REQ' | i18nTranslate}}</mat-error
              >
              </div>

              <div style="width: 45%; margin-bottom: 20px">
                <div class="scheduleBoldText">{{'OFFER_PAGE.TIME' | i18nTranslate}}</div>
                <div class="formDiv" style="width: 100%; cursor: pointer;">
                  <input
                    style="
                      border: none;
                      width: 90%;

                      outline: none;
                    "
                    formControlName="dispatchTime"
                    [format]="serviceCountry === 'US' ? 12 : 24"
                    [ngxTimepicker]="pickerTo"
                    [min]="currentTimeMin"
                    [max]="channel == 'SMS' ? '08:55 PM' : '23:59'"
                    readonly
                  />
                  <ngx-material-timepicker #pickerTo></ngx-material-timepicker>
                  <img
                  [ngxTimepicker]="pickerTo"
                  style="margin-right: 10px; cursor: pointer;"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                  alt=""
                  (closed)="startTimeChange()"
                />

                </div>
                <mat-error
                    style="font-size: 12px; text-align: right"
                    *ngIf="
                      !submitted &&
                      templateDispatchForm.controls.dispatchTime.touched &&
                      templateDispatchForm.controls.dispatchTime.hasError(
                        'required'
                      )
                    "
                  >
                    {{'OFFER_PAGE.TIME_REQ' | i18nTranslate}}</mat-error
                  >
                  <mat-error
                    style="font-size: 12px; text-align: right"
                    *ngIf="
                      submitted &&
                      templateDispatchForm.controls.dispatchTime.touched &&
                      templateDispatchForm.controls.dispatchTime.hasError(
                        'required'
                      )
                    "
                  >
                  {{'OFFER_PAGE.TIME_REQ' | i18nTranslate}}</mat-error
                  >
              </div>
            </div>

            <div
              style="display: flex; width: 100%; align-items: baseline"
              *ngIf="
                selectedChannel == 'SMS' &&
                showSMSWarning != 1 &&
                templateDispatchForm.controls.dispatchType.value != 'SCHEDULED'
              "
            >
              <div style="width: 7%">
                <span
                  class="i-div2"
                  popoverClass="my-custom-class"
                  placement="right"
                >
                  i
                </span>
              </div>
              <div style="width: 93%">
                <div>
                  <span class="sms-caution"
                    >{{'OFFER_PAGE.SMS_TIME_WARNING' | i18nTranslate}}
                  </span>
                </div>
                <div style="margin-top: 5px; margin-bottom: 10px">
                  <span class="sms-caution" style="margin-right: 10px">
                    {{'OFFER_PAGE.SEND_SMS_IN_NEXT_SLOT' | i18nTranslate}}</span
                  >
                  <span
                    (click)="convertDispatchType('SCHEDULED')"
                    class="confirm-link-text"
                    >{{'OFFER_PAGE.YES' | i18nTranslate}}</span
                  >
                </div>
              </div>
            </div>

            <div
              style="display: flex; width: 100%; align-items: baseline"
              *ngIf="
                selectedChannel == 'SMS' &&
                showSMSWarning == 1 &&
                templateDispatchForm.controls.dispatchType.value != 'SCHEDULED'
              "
            >
              <div style="width: 7%">
                <span
                  class="i-div3"
                  popoverClass="my-custom-class"
                  placement="right"
                >
                  i
                </span>
              </div>
              <div style="width: 93%">
                <div>
                  <span class="sms-green"
                    >{{'OFFER_PAGE.SEND_SMS_INSTANTLY_WITHIN_VALID' | i18nTranslate}}
                  </span>
                </div>
              </div>
            </div>

            <!-- <div>
                <div *ngIf="(selectedChannel=='SMS'&&showSMSWarning==1)&&templateDispatchForm.controls.dispatchType.value != 'SCHEDULED'" class="sms-green">This message can be sent instantly. You’re trying to send this message within the valid time permitted by telecom regulatory authorities.</div>

              </div> -->

            <div
              style="display: flex; width: 100%; align-items: baseline"
              *ngIf="
                selectedChannel == 'SMS' &&
                templateDispatchForm.controls.dispatchType.value === 'SCHEDULED'
              "
            >
              <div style="width: 7%">
                <span
                  class="i-div2"
                  popoverClass="my-custom-class"
                  placement="right"
                >
                  i
                </span>
              </div>
              <div style="width: 93%">
                <span class="sms-caution"
                  >{{'OFFER_PAGE.TELECOME_WARNING_INFO' | i18nTranslate}}
                </span>
              </div>
            </div>

            <!-- </div> -->
          </div>
        </div>
        <div
          class="seperator"
          style="box-shadow: 0 -2px 4px 0 #cccccc; border: none; height: 1px"
        ></div>
        <div
          class="footerLabels"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
          "
          data-widget-name="OFFER LISTING RESCHEDULE"
        >
          <div
            class="cancelButton"
            (click)="sendcancelMessage('close')"
            data-button-name="Cancel"
          >
            {{'OFFER_PAGE.CANCEL' | i18nTranslate}}
          </div>
          <div
            class="confirmButton"
            (click)="rescheduleOffer()"
            data-button-name="Re-Scheduled"
            [attr.data-item-name]="
              channel + ':' + this.templateDispatchForm['value']['dispatchType']
            "
          >
            <span>{{'OFFER_PAGE.SCHEDULE' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- </div> -->
<!-- </div> -->

<ng-template #popContentChannel *ngIf="(validScreens | screenConfig: 18)">
  <div style="display: flex">
    <div style="display: flex; width: 10%">
      <mat-icon style="color: #fd8f1b"> lightbulb_outline </mat-icon>
    </div>
    <div style="width: 90%">
      <div style="font-size: 11px">
        {{'OFFER_PAGE.CHOOSE_CHANNEL_COMMUNICATION' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popContentChallengeTemplate>
  <div style="display: flex">
    <div style="display: flex; width: 10%">
      <mat-icon style="color: #fd8f1b"> lightbulb_outline </mat-icon>
    </div>
    <div style="width: 90%">
      <div style="font-size: 11px">
        {{'OFFER_PAGE.CHOOSE_OR_CREATE_TEMPLATE' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentRewardTemplate>
  <div style="display: flex">
    <div style="display: flex; width: 10%">
      <mat-icon style="color: #fd8f1b"> lightbulb_outline </mat-icon>
    </div>
    <div style="width: 90%">
      <div
        style="font-size: 11px"
        *ngIf="rawJson['isNoneBarrierDetected'] != true"
      >
        {{'OFFER_PAGE.PREVIEW_EDIT_TEMPLATE_MESSAGE' | i18nTranslate}}  </div>
      <div
        style="font-size: 11px"
        *ngIf="rawJson['isNoneBarrierDetected'] == true"
      >
        {{'OFFER_PAGE.SMS_TEMPLATE_CANT_BE_EDITED' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popContentCustomerSegments>
  <div style="display: flex">
    <div style="display: flex; width: 10%">
      <mat-icon style="color: #fd8f1b"> lightbulb_outline </mat-icon>
    </div>
    <div style="width: 90%">
      <div style="font-size: 11px">
        {{'OFFER_PAGE.CHOOSE_AVAILABILITY_OF_REWARD_MESSAGE' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #popContentRewardWillBeValidFor>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fd8f1b">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;">Choose the validity of reward in the number of days. After crossing the challenge, this reward will be valid for this specified number of days.
        </div>
      </div>
    </div>

  </ng-template> -->

<ng-template #popContentDispatchType>
  <div style="display: flex">
    <div style="display: flex; width: 10%">
      <mat-icon style="color: #fd8f1b"> lightbulb_outline </mat-icon>
    </div>
    <div style="width: 90%">
      <div style="font-size: 11px">
        {{'OFFER_PAGE.CHOOSE_AVAILABILITY_OF_REWARD_MESSAGE' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>
