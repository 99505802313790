<div class="header">
    <span style="text-align: center; font-weight: bold;">{{'SHARED_PAGE.STORE_LIST' | i18nTranslate}}</span>
    <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
  </div>
  <div style="
  padding: 10px;
  border: 1px dotted #ff3b30;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  " *ngIf="seletedStores.length === 0">
    {{'SHARED_PAGE.NO_STORE_SELECTED' | i18nTranslate}}
  </div>
  <!-- <mat-spinner diameter="15" style="margin-left: 45%" *ngIf="apiloading"></mat-spinner> -->
  <div style="overflow-y: auto; height: calc(100% - 120px);" *ngIf="seletedStores.length !== 0">
    <div *ngFor="let store of seletedStores; let i = index" style="padding: 16px;
    font-size: 14px;">
    <div style="display: flex; justify-content: space-between;">
      <li>{{store.storeName}}</li>
    </div>
    </div>
  </div>
  