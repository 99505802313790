import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { PopUpDialogService } from 'src/app/core/services/pop-up-dialog.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.css']
})
export class CampaignListComponent implements OnInit {
  customCampaigns = [];
  standardCampaigs = [];
  filter = {
    label: "",
  };
  s3BucketUrl=environment.s3BucketUrl;
  tabSelected='preset';
  selectedTabTemplateList;
  customLength;
  loadingCustom:boolean=false;
  loadingStandard:boolean=false;
  validWidgets = null;

  constructor(
    private apiCall: ApiCallService,
    public router: Router,
    private popup: PopUpDialogService,
    private dialog: MatDialog,
    private activateRoute: ActivatedRoute,
    private _WidgetConfigService: WidgetConfigService,
    private tokenStorage:TokenStorageService) { 
     
      window['screen-id']=7;
      window['screen-name']='strategy-tile-view';
      window.dataLayer.push({
        'userId' :  this.tokenStorage.getMerchantId()});
    
    }

  ngOnInit() {
    this.getWidgets();
    this.getTemplates();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  getTemplates() {
    this.loadingCustom=true;
    this.loadingStandard=true
    this.apiCall.getStrategies().subscribe((response) => {
      this.loadingCustom=false;
      this.customCampaigns = JSON.parse(response["body"]);
      this.customLength=this.customCampaigns.length;
    },
      err => { });

    this.apiCall.getStandardCampaigns().subscribe((response) => {
      this.loadingStandard=false

      this.standardCampaigs = JSON.parse(response['body'])
      this.selectedTabTemplateList=this.standardCampaigs;
    },
      err => { })
  }

  createCampaign() {
    this.router.navigate(["../creation", { 'return-url': 'campaign/list', 'id': null, 'recommendation': false }], { relativeTo: this.activateRoute });
  }

  editCampaign(id) {
    this.router.navigate(["../creation", { 'return-url': 'campaign/list', 'id': id, 'recommendation': false }], { relativeTo: this.activateRoute });
  }

  deleteCampaign(id) {
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: "Delete ?",
        message: "click Ok to Delete",
        cancelButtonText: "Cancel",
        successButtonText: "Ok",
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deleteStrategy(id).subscribe((response) => {
          this.popup.openPopup('success', 'Delete successful');
          this.customCampaigns.splice(this.customCampaigns.findIndex(x => x.id === id), 1);
        },
          err => {
            this.popup.openPopup('failure', err.error.body)
          })
      }
    })
  }
  selectList(index){
    this.tabSelected=index;
    if('preset'==index){
      this.selectedTabTemplateList=this.standardCampaigs
    }
    else if('mytemplates'==index){
      this.selectedTabTemplateList=this.customCampaigns
    }  
    else if('new'==index){
     this.createCampaign()   
    
    } 
  else{

    }

  }
}
