<div class="activity-spinner-container" *ngIf="dataLoading">
    <app-qubriux-loading [size]="45"></app-qubriux-loading>
</div>
<div class="qb-h-100" *ngIf="!isNoDataAvailable">
    <div class="qb-analytics-main-container" *ngIf="!dataLoading">
        <div class="qb-analytics-main-sub-container">
            <app-breadcrumb></app-breadcrumb>
            <div class="graph-filters" *ngIf="selectedTab === 'signUp' || selectedTab === 'loyaltyRevenue'">
                <div class="qb-each-filter">
                    <h6 class="graph-options-text">View By:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="timeFrame" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                            <mat-option *ngFor="let val of totalCustomerRevenueFrameList" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div *ngFor="let filter of availableFilter | keyvalue">
                    <div class="qb-each-filter" *ngIf="filter.key === 'group_value'">
                        <div style="display: flex; flex-direction: row; gap: 20px;">
                            <div>
                                <h8 class="graph-options-text">Start Date</h8>
                                <div class="month-matSelect-filters" style="margin-top: 5px;">
                                    <mat-select class="margin-right-10" [(ngModel)]="selectedFilter[filter.key][0]">
                                        <mat-option *ngFor="let val of filter.value" [value]="val" selected>{{val}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div>
                                <h8  class="graph-options-text">End Date</h8>
                                <div class="month-matSelect-filters"style="margin-top: 5px;">
                                    <mat-select class="margin-right-10" [(ngModel)]="selectedFilter[filter.key][1]">
                                        <mat-option *ngFor="let val of filter.value" [value]="val" selected>{{val}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-accordion *ngIf="selectedTab === 'signUp' || selectedTab === 'loyaltyRevenue'">
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Advanced Filters
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel-content>
                    <div class="qb-d-flex qb-fd-coloumn" style="justify-content: space-between;">
                        <div class="graph-filters">
                            <div *ngFor="let filter of availableFilter | keyvalue">
                                <div class="qb-each-filter" *ngIf="filter.key !== 'Segment' && filter.key !== 'cltv_bucket' && filter.key !== 'month' && filter.key !== 'week' && filter.key !== 'quarter' && filter.key !== 'group_value'">
                                    <h6 class="graph-options-text">{{getDisplayFilterName(filter.key)}}</h6>
                                    <div class="matSelect-filters">
                                        <mat-select placeholder="Select" multiple class="margin-right-10" [(ngModel)]="selectedFilter[filter.key]">
                                            <div class="qb-all-select" (click)="selectAllOptions(filter.key, filter.value)">
                                                <mat-checkbox color="primary" style="pointer-events: none;" [checked]="selectedFilter[filter.key].length === filter.value.length">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let val of filter.value" [value]="val">{{val}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="qb-each-filter" *ngIf="filter.key === 'Segment'">
                                    <h6 class="graph-options-text">{{getDisplayFilterName(filter.key)}}</h6>
                                    <div class="matSelect-filters">
                                        <mat-select placeholder="Select" multiple class="margin-right-10" [(ngModel)]="selectedFilter[filter.key]">
                                            <div class="qb-all-select" (click)="selectAllOptions(filter.key, filter.value)">
                                                <mat-checkbox color="primary" style="pointer-events: none;" [checked]="selectedFilter[filter.key].length === filter.value.length">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let val of filter.value" [value]="val.id">{{val.name}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="apply-filterbutton">
                            <button class="primary-button" (click)="getAnalytics(true)">
                                Apply Filter
                            </button>
                        </div>
                    </div></mat-expansion-panel-content>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'signUp'">
                <div  class="qb-analytics-graph-container" style="width: 25%;">
                    <h6 class="qb-analytics-heading">Total Loyalty Signup Trend</h6>
                    <div echarts [options]="totalSignUpPieChart" style="height: 200px; width: 100%;"></div>
                    <div class="donut-legend-trend">
                        <table class="donut-legend-table">
                            <tr *ngFor="let data of totalSignUpPie">
                                <td><div [style.backgroundColor]="data.color" class="donut-legend-color-box"></div></td>
                                <td><div>{{data.name}}: </div></td>
                                <td><div>{{data.count}}</div></td>
                            </tr>
                        </table>
                    </div>               
                </div>
                <div  class="qb-analytics-graph-container" style="width: 73%;">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Loyalty Signup Trend <span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup1"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('loyalty_sign_up')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div> 
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            Compare & analyse  the count of customers who opted for loyalty features over time period
                        </div>
                    </ng-template>
                    <div (chartClick)="onChartClick($event)" echarts [options]="loyaltyTrendGraph" style="height: 300px; width: 100%;"></div>               
                </div>
            </div>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'loyaltyRevenue'">
                <div  class="qb-analytics-graph-container" style="width: 25%;">
                    <h6 class="qb-analytics-heading">Total Revenue Trends</h6>
                    <div echarts [options]="loyaltyComparisonPieChart" style="height: 200px; width: 100%;"></div>
                    <div class="donut-legend-trend">
                        <table class="donut-legend-table">
                            <tr *ngFor="let data of totalComparisonPie">
                                <td><div [style.backgroundColor]="data.color" class="donut-legend-color-box"></div></td>
                                <td><div>{{data.name}}: </div></td>
                                <td><div>{{data.count}}</div></td>
                            </tr>
                        </table>
                    </div>               
                </div>
                <div  class="qb-analytics-graph-container" style="width: 73%;">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Loyalty vs Non-Loyalty Revenue <span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup1"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('loyaltyComparisonGraph')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div> 
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            This graph compares revenue from customers in a loyalty program (loyal customers) versus those not enrolled (non-loyal customers). Customers in loyalty programs tend to spend more consistently, while non-loyal customers may have variable spending patterns. Analyzing this revenue helps businesses enhance retention strategies and encourage non-loyal customers to join the loyalty program, boosting overall profitability and growth.
                        </div>
                    </ng-template>
                    <div echarts [options]="loyaltyComparisonGraph" style="height: 300px; width: 100%;"></div>               
                </div>
            </div>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'coinsRedemption'">
                <div class="qb-analytics-graph-container qb-flex-1">
                    <h6 class="widget-heading">{{'SEGMENT_PAGE.REWARD_REDEMPTION_RATE' | i18nTranslate}}<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup1"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            {{'SEGMENT_PAGE.REWARD_REDEMPTION_RATE_INFO' | i18nTranslate}}
                        </div>
                    </ng-template>
                    <div echarts [options]="rewardRedemptionRateStatOptions" style="height: 400px; width: 100%;"></div>
                </div>
            </div>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'coinsEarned'">
                <div class="qb-analytics-graph-container qb-flex-1">
                    <div class="qb-d-flex">
                        <h6 class="widget-heading">{{earnedByTierValue | i18nDynamicTranslate : 'POS_PAGE'}} {{'SEGMENT_PAGE.EARNER_TIER' | i18nTranslate}}</h6>
                        <div class="graph-options" style="display: flex;">
                            <h6 class="graph-options-text" style="margin-top: 7px;">{{'SEGMENT_PAGE.OPTIONS' | i18nTranslate}}:</h6>
                            <div class="graph-opt-mat-select">
                                <mat-select class="margin-right-10" [(ngModel)]="earnedByTierTimeFrame" (selectionChange)="earnedByTierDropdownHandler()">
                                    <mat-option *ngFor="let val of earnedByTierTimeFrameList" [value]="val" selected>{{val | i18nDynamicTranslate : 'POS_PAGE'}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="graph-opt-mat-select">
                                <mat-select [(ngModel)]="earnedByTierValue" (selectionChange)="earnedByTierDropdownHandler()">
                                    <mat-option *ngFor="let val of earnedByTierValueList" [value]="val" selected>{{val | i18nDynamicTranslate : 'POS_PAGE'}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div echarts [options]="earnedByTierChartOptions" class="line-chart-height"></div>
                </div>
            </div>
            <div class="row-gap-between" *ngIf="selectedTab === 'coinsEarned'">
                <div class="qb-analytics-graph-container qb-w-49">
                    <h6 class="widget-heading">{{'SEGMENT_PAGE.ACTIVE_USERS' | i18nTranslate}}<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup2"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup2>
                        <div class="qb-ng-template-text">
                            {{'SEGMENT_PAGE.GRAPHN_DATA_ACTIVE_MESSAGE' | i18nTranslate}}
                        </div>
                    </ng-template>
                    <div echarts [options]="activeUsersChartOptions"></div>
                </div>
                <div class="qb-analytics-graph-container width-49-perc">
                    <h6 class="widget-heading">{{'SEGMENT_PAGE.REVENUE_BY_TIER' | i18nTranslate}}<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup3"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup3>
                        <div class="qb-ng-template-text">
                            {{'SEGMENT_PAGE.GRAPGN_REVENUE_TIER' | i18nTranslate}}
                        </div>
                    </ng-template>
                    <div echarts [options]="tierWiseRevenueOptions"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loading-indicator" *ngIf="isLoading">
    <app-qubriux-loading [size]="45"></app-qubriux-loading>
</div>
<div class="no-program-created" *ngIf="isNoDataAvailable && (selectedTab === 'signUp' || selectedTab === 'coinsRedemption' || selectedTab === 'coinsEarned' || selectedTab === 'loyaltyRevenue')">
    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt=""
        style="padding-top: 100px;" height="400px">
    <div style="margin-top: 15px;">
        No Data Available.
    </div>
</div>