<div class="review-card">
  <div class="review-header">
    <h3 style="font-weight: 600;">{{ surveyData.reviewerName }}'s Review</h3>
    <div class="rating">
      <mat-icon *ngFor="let star of starsArray">star</mat-icon>
    </div>
  </div>
  
  <div class="review-box">
    <div class="review-text" *ngIf="surveyData.reviewText !== 'NaN'; else noReviewText"> 
      {{ surveyData.reviewText }}
    </div>
    
    <ng-template #noReviewText>
      <div class="review-text">
        No review provided.
      </div>
    </ng-template>

    <div class="review-footer">
      <div class="footer-item left">
        <label>Submitted:</label> {{ formatDate(surveyData.dateSubmitted) }}
      </div>
    </div>
  </div>

  <div class="sentiment">
    <label>Sentiment: </label>
    <span class="sentiment-icon">{{ getSentimentEmoji(surveyData.sentiment) }}</span>
  </div>

  <div class="key-phrases" *ngIf="surveyData.keyPhrase?.length > 0">
    <label style="font-weight: 700;">Key Phrases:</label>
    <div class="key-phrases-tags">
      <span class="tag" *ngFor="let phrase of surveyData.keyPhrase">{{ phrase }}</span>
    </div>
  </div>

  <div class="view-link">
    <a [href]="surveyData.reviewLink" target="_blank" class="primary-button btn-primary">View on Google</a>
  </div>

</div>
