<div style="display: flex;
display: flex;
flex-direction: column;background: #efefef;padding: 30px;">


  <div style="width: 100%;display:flex;justify-content: space-between;align-items: center;">
    <div>{{'SHARED_PAGE.COMARKETING_OFFER_SUMMARY' | i18nTranslate}}</div>
    <div>
      <mat-icon (click)="closedialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </div>
    <div>
      <!-- <div class="label-title">Summary</div> -->
      <div class="qb-coupon">{{ data['offerName'] }}</div>
      <div>
        <ul>
            {{'SHARED_PAGE.SELECT_OFFER_FROM_STORE' | i18nTranslate}}
           <ul *ngFor="let store of data['offerList']">
            <li>{{store['storeName']}}</li>
           </ul>
          <!-- <li class="qb-list-item" *ngIf="barrierJSON.type === 'AMOUNT' && barrierJSON.value > 0">
            Minimum purchase of {{ currencySymbol }}{{ barrierJSON.value }}
          </li>
          <li class="qb-list-item" *ngIf="barrierJSON.type === 'QUANTITY' && barrierJSON.value > 0">
            Minimum quantity of {{ barrierJSON.value }}
          </li> -->
        </ul>
      </div>
    </div>
