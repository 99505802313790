import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { assetPath } from "src/app/configs/asset-path-config";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { SurveyParentService } from "src/app/core/services/survey-parent.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit, AfterViewInit {
  constructor(
    private apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    public router: Router,
    private popup: PopUpDialogService,
    public parentToChildService: SurveyParentService,
    private route: ActivatedRoute,
    private globalService: Globals,
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    window["screen-name"] = "SURVEYS";
    this.getScreenSize();
  }
  s3BucketUrl = environment.s3BucketUrl;
  surveys;
  survey;
  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  templateListContentHeight;
  selectedTemplateIndex;
  selectedStandardTemplateIndex = 0;
  selectedCustomTemplateIndex = null;

  standardSurvey = [];
  customSurvey = [];
  subscription;
  surveyId;
  templateIndex = 0;
  templateType = "STANDARD";
  filter = {
    label: "",
  };
  type;
  validWidgets = null;

  loading: boolean = false;
  surveyType;
  surveyItem = 0;
  selectedSurveyList = [];
  selectedSurveyType = "ALL";
  allsurvey = [];
  listHeight;
  segmentHeight;
  appSubscriptionPlan;
  totalSurveys = 0;
  isDemo;
  @ViewChild("header") header: ElementRef;
  @ViewChild("segmentListHeader") segmentListHeader: ElementRef;
  @ViewChild("container") container: ElementRef;

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  assetPath = {
    dBinUrl: assetPath.path + "/noun_dustbin_2025484.svg",
    editUrl: assetPath.path + "/noun_edit_958193.svg",
  };

  ngAfterViewInit() {
    let interval = setInterval(() => {
      // if (this.segmentListHeader) {
      //   this.listHeight =
      //     this.container.nativeElement.offsetHeight -
      //     this.segmentListHeader.nativeElement.offsetHeight -
      //     this.header.nativeElement.offsetHeight;
      //   this.segmentHeight =
      //     this.container.nativeElement.offsetHeight -
      //     this.header.nativeElement.offsetHeight;
      //   clearInterval(interval);
      // }
    });
  }

  search() {
    this.totalSurveys = 0;
    this.selectedSurveyList.forEach(element => {
      console.log(element.layoutName)
      if(element.layoutName.toLowerCase().includes(this.filter.label.toLowerCase()))
      this.totalSurveys++;
    })
  }

  ngOnInit() {
    this.isDemo = this.tokenStorage.getIsDemo();
    this.getWidgets();
    // if (this.route.firstChild) {
    //   this.route.firstChild.paramMap.subscribe((params) => {
    //     this.surveyId = params.get("id");
    //     this.type = params.get("type");
    //     if (params.get("clone")) {
    //       this.getTemplates(this.surveyId);
    //     }
    //   });
    // }
    this.finalScreenHeight = this.scrHeight - 70;
    this.finalScreenWidth = this.scrWidth;
    this.templateListContentHeight = this.finalScreenHeight - 100;
    this.getTemplates(this.surveyId);
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getTemplates(SID) {
    this.loading = true;
    this.apiCall.getAllSurveyTemplates().subscribe(
      (response) => {
        if (response["message"] === "SUCCESS") {
          this.loading = false;
          this.surveys = response["body"];
          }
          else {
            this.surveys = [];
            this.totalSurveys = 0;
          }
          this.standardSurvey = [];
          this.customSurvey = [];
          this.allsurvey = [];
          for (let i = 0; i < this.surveys.length; i++) {
            if (this.surveys[i]["type"] === "STANDARD") {
              this.standardSurvey.push(this.surveys[i]);
            } else {
              this.customSurvey.push(this.surveys[i]);
            }
            this.allsurvey.push(this.surveys[i]);
            this.allsurvey.sort((a, b) =>
              a.layoutName.toLowerCase() > b.layoutName.toLowerCase() ? 1 : -1
            );
            this.standardSurvey.sort((a, b) =>
              a.layoutName.toLowerCase() > b.layoutName.toLowerCase() ? 1 : -1
            );
            this.customSurvey.sort((a, b) =>
              a.layoutName.toLowerCase() > b.layoutName.toLowerCase() ? 1 : -1
            );
          }
          this.surveyType = "ALL";
          this.selectedSurveyType = this._i18nDynamicTranslate.transform("All Survey Templates", ['POS_PAGE']);
          // var a=[]
          this.selectedSurveyList = this.allsurvey;
          if (SID == null) {
            // this.standardSurvey.length>0?this.type='standard':this.type='custom';
            // this.standardSurvey.length>0? this.activatelistClass(this.standardSurvey[0]['id']):this.customSurvey.length>0?this.activatelistClass(this.customSurvey[0]['id']):null;
            this.selectsurveyType("ALL");
          } else {
            this.viewTemplateDetail(true, this.surveyId, this.type);
            // this.activatelistClass(SID);
          }
      },
      (error) => {
        this.loading = false;
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching templates", ['POS_PAGE']), 2000);
      }
    );
  }

  // activatelistClass(SID) {
  //   var i: number;
  //   var arrayset;
  //   if(this.type=='standard'){
  //     arrayset=this.standardSurvey;
  //   }
  //   else{
  //     arrayset=this.customSurvey;
  //   }
  //   if (this.surveyId != null) {
  //     for (i = 0; i < arrayset.length; i++) {
  //       if (arrayset[i]["id"] == SID) {
  //         if (arrayset[i]['type'] === 'STANDARD') {

  //           this.viewTemplateDetail(i,arrayset[i]["id"],this.type)
  //           this.selectedStandardTemplateIndex = i;
  //           this.selectedCustomTemplateIndex = null;
  //         }
  //         else {
  //           this.selectedStandardTemplateIndex = null;
  //           this.selectedCustomTemplateIndex = i;
  //         }
  //         break;
  //       }
  //     }
  //     setTimeout(() => {

  //       document.getElementById('survey-'+this.surveyId).scrollIntoView({ behavior: "smooth", block: "center" });

  //     }, 500);
  //   } else {
  //     if(this.customSurvey.length!=0){
  //     this.viewTemplateDetail(0, this.customSurvey[0]['id'], 'custom');
  //     }
  //     else{
  //       this.viewTemplateDetail(0, this.standardSurvey[0]['id'], 'standard');

  //     }
  //   }

  // }

  editTemplate(i) {
    //this.selectedTemplateIndex = i;
    this.router.navigate([
      "/app/surveys/add-survey",
      { id: this.surveys[i]["id"] },
    ]);
  }

  deleteLayout(id) {
    let paramId = this.parentToChildService.getId();
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete template", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("click ok to delete", ['POS_PAGE']),
        "data-widget": "SURVEY TEMPLATES",
        "data-button": "Confirm: Delete",
        "data-item": id,
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deleteSurveyTemplate(id).subscribe(
          (response) => {
            if (response["message"] === "SUCCESS") {
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Successfully deleted", ['POS_PAGE']), 2000);
              if (id.toString() === paramId) {
                this.router.navigate([
                  "/app/surveys/add-survey",
                  { id: null },
                ]);
              }
              this.ngOnInit();
            } else {
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']), 2000);
            }
          },
          (err) => {
            this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']), 3000);
          }
        );
      }
    });
  }

  async createStandardTemplate() {
    //this.selectedTemplateIndex = null;
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate(
        ["/app/surveys/add-survey", { id: null }],
        {
          relativeTo: this.route,
        }
      );
    }
  }

  viewTemplateDetail(scroll, id, type) {
    this.surveyId = id;
    // this.templateIndex = index;
    this.templateType = type;
    // this.surveyItem['id']=id;
    var typ = "custom";
    if (type === "STANDARD" || type == "standard") {
      typ = "standard";
    } else {
      typ = "custom";
    }
    if (scroll && id > 0) {
      setTimeout(() => {
        document.getElementById("survey-" + this.surveyId).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 500);
    }
    this.router.navigate(["/app/surveys/survey-list", { id: id, type: typ }]);
  }

  scheduleTemplate(index, id, type) {
    this.templateIndex = index;
    this.templateType = type;
    if (type === "STANDARD") {
      this.selectedStandardTemplateIndex = index;
      this.selectedCustomTemplateIndex = null;
    } else {
      this.selectedStandardTemplateIndex = null;
      this.selectedCustomTemplateIndex = index;
    }
    this.router.navigate(["/app/surveys/survey/schedule", { id: id }]);
  }

  filterChange(event) {
    if (this.filter.label.length > 0) {
      this.selectedCustomTemplateIndex = null;
      this.selectedStandardTemplateIndex = null;
    } else {
      document.getElementById("survey-" + this.surveyId).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });

      if (this.templateType === "STANDARD") {
        this.selectedCustomTemplateIndex = null;
        this.selectedStandardTemplateIndex = this.templateIndex;
      } else {
        this.selectedCustomTemplateIndex = this.templateIndex;
        this.selectedStandardTemplateIndex = null;
      }
    }
  }

  selectsurveyType(type) {
    this.totalSurveys = null;
    this.filter.label = "";
    this.surveyType = type;
    // this.getSegments(type, true);
    this.selectedSurveyList = [];
    switch (type) {
      case "ALL":
        this.selectedSurveyType = this._i18nDynamicTranslate.transform("All Survey Templates", ['POS_PAGE']);
        var a = [];
        this.selectedSurveyList = this.allsurvey;

        break;
      case "PRESET":
        this.selectedSurveyType = this._i18nDynamicTranslate.transform("Preset Survey Templates", ['POS_PAGE']);
        this.selectedSurveyList = this.standardSurvey;
        break;
      case "CREATED":
        this.selectedSurveyType = this._i18nDynamicTranslate.transform("Custom Survey Templates", ['POS_PAGE']);
        this.selectedSurveyList = this.customSurvey;
        break;
    }
    if (this.selectedSurveyList.length > 0) {
      this.surveyId = this.selectedSurveyList[0]["id"];
      this.viewTemplateDetail(
        0,
        this.surveyId,
        this.selectedSurveyList[0]["type"] == "STANDARD" ? "standard" : "custom"
      );
    } else {
      this.surveyId = 0;
      this.viewTemplateDetail(0, null, null);
    }
    //this.surveyItem['id']=this.selectedSurveyList[0]['id'];
  }
}
