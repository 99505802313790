import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe, getCurrencySymbol } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { PartnershipSendOfferDialogboxComponent, SuccessDialogComponent, ProductListDialogComponent, CollectionListDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

@Component({
  selector: "app-partner-offer-creation",
  templateUrl: "./partner-offer-creation.component.html",
  styleUrls: ["./partner-offer-creation.component.css"],
})
export class PartnerOfferCreationComponent implements OnInit {
  constructor(
    private tokenStorage: TokenStorageService,
    private apiCall: ApiCallService,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
    private snackBar: SnackbarCollection,
    private activatedRoute: ActivatedRoute,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  discountType = "";
  hurdleProductInnerType = "";
  selectedBarrierProductList = [];
  selectedBarrierCollectionList = [];
  searchText;
  productCategoryType = null;
  fullproductCategoryListLength = null;
  selectedCollectionList = [];
  selectedProductList = [];
  revCurrency;
  currencySymbol;
  submitClicked = false;
  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  offerNameError = "";
  ifSend = false;
  id;
  name;
  offerDisplayValidy = "";
  couponValidity = "";

  request = {
    id: null,
    partnershipOfferName: "",
    offerjson: {},
    couponValidity: "",
    offerDisplayValidy: "",
    status: false,
    merchantId: this.tokenStorage.getMerchantId(),
    discountCoupon: "".toUpperCase(),
  };

  offerFinalReq = {};

  sendOfferAgain = false;

  offerJSON = {
    discountType: "PERCENTAGE_OFF",
    discountValue: 0,
    appliesTo: "ALL_PRODUCTS",
    categories: [],
    products: [],
  };

  ngOnInit() {
    this.getParams();
    this.getCurrencySymbol();
  }

  getParams() {
    this.activatedRoute.params.subscribe((param) => {
      this.id = param.id;
      this.name = param.name;
      this.ifSend = param.send;
      this.sendOfferAgain = param.sendOfferAgain;
    });
  }

  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }

  //Offer display till date should be less than or equal to the coupon validity date
  checkValidityDates() {
    if (this.couponValidity < this.offerDisplayValidy) {
      this.displaySnackbar(
        "Coupon validity date should be greater than the offer display date"
      );
    } else {
      this.save();
    }
  }

  displaySnackbar(text) {
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(text, ['POS_PAGE']), 2000);
  }

  setOfferValues() {
    this.request.offerjson = this.offerJSON;
    this.request.offerDisplayValidy = this.datePipe.transform(
      this.offerDisplayValidy,
      "yyyy-MM-dd"
    );
    this.request.couponValidity = this.datePipe.transform(
      this.couponValidity,
      "yyyy-MM-dd"
    );
    this.request.partnershipOfferName =
      this.request.partnershipOfferName.trim();
  }

  setOfferAPIValues() {
    let barrierListList = {
      barrierDates: {
        startDayCount: 0,
        endDayCount: 0,
        daysOfTheWeek: null,
        startTime: null,
        endTime: null,
      },
      spentBarrier: null,

      barrierType: null,

      barrierParamType: null,
      spentType: null,

      anyproduct: null,

      product: null,
      productVariant: null,
      productCategory: null,
      productPropensity: null,

      productCategoryPropensity: null,

      hurdleAtAnyTime: null,
      hurdleEverydayOfTheWeek: null,
    };

    let rewardListList = {
      daysToRedeem: null,
      serviceProvider: this.tokenStorage.getMerchantOnlineStore(),
      index: 0,
      rewardType: this.offerJSON.discountType,
      amountOrPercentageOffDiscount: this.getAmtOrPercentageDiscount(),
      buyXGetYDiscount: null,
      freeShippingDiscount: null,
      productPropensity: null,
      productCategoryPropensity: null,

      rewardTemplate: null,
      emailLayoutId: null,
      layoutId: null,
      channel: null,
      rewardLayoutName: null,
      title: this.request.discountCoupon,
      isOncePerCustomer: null,
      usageLimit: null,
      communication : null
    };
    let BarrierRewardFull = {
      barrier: barrierListList,
      reward: rewardListList,
    };
    let offerJson = {
      barrierRewards: [BarrierRewardFull],
      isNoneBarrierDetected: null,
      isBuyXGetYDetected: null,
      smsLayoutId: null,
      smsTemplateId: null,
      smsTemplateName: null,
      emailTemplateName: null,
      channel: null,
      emailLayoutId: null,
      emailLayoutTemplateId: null,
      emailLayoutName: null,
      emailLayoutSubject: null,
      emailTemplateSnapshotUrl: null,
      comCouponValidity: this.request.couponValidity += " 23:59:59",
      comDisplayValidity: this.request.offerDisplayValidy += " 23:59:59",
      stores: null,
      adminCreated: null,
      createdBy: null
    }
    // this.request.couponValidity += " 23:59:59";
    // this.request.offerDisplayValidy += " 23:59:59";
    // this.request.offerjson = JSON.stringify(this.request.offerjson);

    let offertestNew = {
      offerJson: JSON.stringify(offerJson),
      channel: null,
      offerName: this.request.partnershipOfferName,
      offerId: null,
      // offerHtml: this.finalHtml,
    };
    this.offerFinalReq = offertestNew;
  }

  openSendOfferDialog(response) {
    let dialogRef = this.matDialog.open(
      PartnershipSendOfferDialogboxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "540px",
        height: "228x",
        data: {
          id: this.id,
          name: this.name,
          ifSend: this.ifSend,
          offerName: this.request.partnershipOfferName,
          offerId: response["body"],
          sendOfferAgain: this.sendOfferAgain,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSuccessDialog();
      }
    });
  }

  openSuccessDialog() {
    const dialogRef2 = this.matDialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "25%",
      data: {
        message: this._i18nDynamicTranslate.transform("Offer sent successfully", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Continue to my partners page", ['POS_PAGE']),
      },
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if (result) {
        this.rerouteToMyPartners();
      } else {
        this.rerouteToMyOffers();
      }
    });
  }

  rerouteToMyPartners() {
    this.router.navigate(["/app/partnership/my-partners"]);
  }

  rerouteToMyOffers() {
    this.router.navigate(["/app/partnership/my-offers"]);
  }

  save() {
    this.offerNameError = "";
    this.submitClicked = true;
    this.setOfferValues();
    if (
      this.request.partnershipOfferName.replace(/\s/g, "").length < 3 ||
      this.request.partnershipOfferName.replace(/\s/g, "").length > 25 ||
      this.request.discountCoupon.replace(/\s/g, "").length < 3 ||
      this.request.discountCoupon.replace(/\s/g, "").length > 25 ||
      this.request.discountCoupon.replace(/\s/g, "").length == 0 ||
      this.offerJSON.discountValue <= 0 ||
      this.request.offerDisplayValidy === "" ||
      this.request.couponValidity === "" ||
      (this.offerJSON.appliesTo === "SPECIFIC_CATEGORIES" &&
        this.selectedBarrierCollectionList.length == 0) ||
      (this.selectedBarrierProductList.length == 0 &&
        this.offerJSON.appliesTo == "SPECIFIC_PRODUCTS")
    ) {
    } else {
      this.setOfferAPIValues();
      this.createOffer();
    }
  }

  createOffer() {
    this.apiCall.createPartnerOffer(this.offerFinalReq).subscribe(
      (response) => {
        if (this.ifSend) {
          this.openSendOfferDialog(response);
        } else {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Offer created", ['POS_PAGE']), 2000);
          this.rerouteToMyOffers();
        }
      },
      (error) => {
        this.offerNameError = this._i18nDynamicTranslate.transform("Duplicate offer name", ['POS_PAGE']);
      }
    );
  }

  openProductListDialog(dataOption) {
    let dialogRef = this.matDialog.open(ProductListDialogComponent, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.searchText = "";
      if (result != undefined) {
        this.selectedBarrierProductList = result.variantIdArray;
        this.selectedProductList = this.selectedBarrierProductList;
        this.offerJSON.products = this.selectedBarrierProductList;
      }
    });
  }

  getOfferManagementProductsList(listType) {
    this.apiCall
      .getOfferManagementProductsList("", 1, 10)
      .subscribe((response) => {
        let dataOption = {
          totalProducts: [],
          selectedProductList: this.selectedBarrierProductList,
          section: "BARRIER",
          listType: listType,
          searchString: this.searchText ? this.searchText : null,
        };
        if (
          this.searchText == null ||
          this.searchText.length > 2 ||
          this.searchText.length == 0
        ) {
          this.openProductListDialog(dataOption);
        }
      });
  }

  browse(type, listType) {
    this.productCategoryType = [];
    if (type === "SPECIFIC_PRODUCTS") {
      this.getOfferManagementProductsList(listType);
    } else {
      this.getConstantProducts();
    }
  }

  getConstantProducts() {
    this.apiCall.getConstantProducts().subscribe((response) => {
      if (response["message"] === "success") {
        let result: any = response["body"];
        result = JSON.parse(result);
        for (let i = 0; i < result["productCategories"].length; i++) {
          this.productCategoryType.push({
            collectionName: result["productCategories"][i].productCategoryName,
            collectionId: result["productCategories"][i].productCategoryId,
          });
        }

        this.fullproductCategoryListLength = this.productCategoryType.length;
        let dataOption = {
          allCollection: this.productCategoryType,
          selectedCollectionList: this.selectedCollectionList,
          searchString: this.searchText ? this.searchText : null,
        };
        if (
          this.searchText == null ||
          this.searchText.length > 2 ||
          this.searchText.length == 0
        ) {
          this.openCollectionListDialog(dataOption);
        }
      }
    });
  }

  openCollectionListDialog(dataOption) {
    let dialogRef = this.matDialog.open(CollectionListDialogComponent, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.searchText = "";
      if (result != undefined) {
        this.selectedBarrierCollectionList = result.variantIdArray;
        this.selectedCollectionList = this.selectedBarrierCollectionList;
        this.offerJSON.categories = this.selectedBarrierCollectionList;
      }
    });
  }

  removeEntry(id) {
    if (this.offerJSON.appliesTo === "SPECIFIC_PRODUCTS") {
      Object.keys(this.selectedBarrierProductList).forEach((product) => {
        if (this.selectedBarrierProductList[product]["variantId"] == id) {
          this.selectedBarrierProductList.splice(
            this.selectedBarrierProductList.indexOf(
              this.selectedBarrierProductList[product]
            ),
            1
          );
        }
      });
    } else {
      Object.keys(this.selectedBarrierCollectionList).forEach((product) => {
        if (this.selectedBarrierCollectionList[product]["collectionId"] == id) {
          this.selectedBarrierCollectionList.splice(
            this.selectedBarrierCollectionList.indexOf(
              this.selectedBarrierCollectionList[product]
            ),
            1
          );
        }
      });
    }
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  getAmtOrPercentageDiscount(){
    let amountOrPercentDiscount = {
      entitledType: 'ORDER',
      entitledProducts: this.offerJSON.products.length != 0 ? this.offerJSON.products : null,
      entitledVariants: null,
      entitledCollection: this.offerJSON.categories.length != 0 ? this.offerJSON.categories : null,
      discountValue: this.offerJSON.discountValue,
      minPurchaseAmount: null,
      minPurchaseQuantity: null,
      prerequisiteType: null,
    };

    return amountOrPercentDiscount;
  }
}
