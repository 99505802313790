import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ConfirmationDialogBoxComponent } from '../confirmation-dialog-box/confirmation-dialog-box.component';

@Component({
  selector: 'app-data-preview-amalytics-dialog-box',
  templateUrl: './data-preview-amalytics-dialog-box.component.html',
  styleUrls: ['./data-preview-amalytics-dialog-box.component.css']
})
export class DataPreviewAmalyticsDialogBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DataPreviewAmalyticsDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  submit(decision) {
    this.dialogRef.close(decision);
  }

  submitCustomerListData(){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Create Custom List", ['POS_PAGE']),
          // message: this._i18nDynamicTranslate.transform("Do you wish to Save the Cusomer List?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          saveustomerList: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
          "data-widget": "CUSTOMER LIST CREATION",
          "data-button": "Confirm: Save Custom List",
          "data-item": "",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.snackBar.openSnackBar("Your Custom List is being Created", 2000);
          let data :any = {};
          data.customerListName = result;
          data.merchantId = this.tokenService.getMerchantId();
          data.customerIds = this.selectedCustomerList();
          data.operation = "create";
          this.customerListCreateApi(data).then((result) => {
            if(result){
             console.log(result)
             const dialogRef = this.dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Your Custom List has been successfully created", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("OK", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((re) => {
                this.dialogRef.close();
            });
            }
          },(error)=>{
            this.snackBar.openSnackBar("Custom List Name Already Exists", 2000);
          });
        }
      });
  }

  selectedCustomerList(){
    let customerIds = [];
    this.data['customerDetails'].forEach((data)=>{
      customerIds.push(data.customer_id);
    });
    return customerIds;
  }

  customerListCreateApi(data): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.customerListUpdate(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(false);
        }
      );
    });
  }

}
