import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { error } from 'console';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { ConfirmationDialogBoxComponent, DeleteGameDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-review-config-dialog-box',
  templateUrl: './review-config-dialog-box.component.html',
  styleUrls: ['./review-config-dialog-box.component.css']
})
export class ReviewConfigDialogBoxComponent implements OnInit {
  storeFilterList = [{
    storeName:'None',
    qid: null,
  }];
  merchantName;
  selectedStoreId;
  selectedPlatform;
  selectedStore="None";
  dailyReviewStatus;
  placeId="";
  platformList;
  historicalSyncStatus;
  isReviewStatusLoading: boolean = true;
  merchantId;
  // isRedirected: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ReviewConfigDialogBoxComponent>,
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private dialog: MatDialog,
    private router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  ngOnInit(): void {
    this.merchantName = this.tokenStorage.getShopName();
    this.merchantId = this.tokenStorage.getMerchantId();
    this.getPlatforms();
    this.apiCall.getListStore().subscribe(
      (response) => {
        this.storeFilterList = [{
          storeName: 'None',
          qid: null,
        }];
        if (response['body']) {
          response['body'].forEach((storeData) => {
            if (storeData.qid) {
              this.storeFilterList.push({
                'qid': storeData.qid,
                'storeName': storeData.storeName,
              });
            }
          });
        }
      },
      (err) => {
        console.error("Error fetching store list", err);
      }
    );

    
  }

  getPlatforms() {
    this.apiCall.getReviewPlatforms().subscribe(
      (response) => {
          this.platformList = response['body'];
      },
      (err) => {
        console.error("Error fetching platforms", err);
      }
    );
  }

  onPlatformAndStoreChange() {
    console.log(this.selectedStoreId);
    if (this.selectedPlatform && this.selectedStoreId) {
      this.getPlaceId();
    }
  }

  getPlaceId() {
    this.apiCall.getPlaceId(this.selectedStoreId).subscribe(
      (response) => {
        if (response === null ) {
          this.openConfirmationDialog();
        } else if (response) {
          this.placeId = response['body'];
          this.getDailyReviewStatus();
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }

  getDailyReviewStatus() {
    this.apiCall.getDailyReviewStatus(this.selectedPlatform,this.selectedStoreId).subscribe(
      (response) => {
        if(response){
          this.dailyReviewStatus = response['body']['active'];
          this.historicalSyncStatus = response['body']['historicReviewSync'];
        }
        else{
          this.dailyReviewStatus = false;
          this.historicalSyncStatus = false;
        }
        this.isReviewStatusLoading = false;  
      },
      (error)=>{
        console.log(error);
        this.isReviewStatusLoading = false;
      }
    );
  }

  openConfirmationDialog() {
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
         width: "540px",
      height: "200px",
      data: {
        subject: this._i18nDynamicTranslate.transform("PlaceId Not Configured", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Configure PlaceId", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Redirect", ['POS_PAGE']),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.isRedirected = true;
        this.isReviewStatusLoading = true;
        this.placeId="";
        window.open(`/app/user-profile/store-info/store-details?id=${this.selectedStoreId}`, '_blank');
      }
      else{
        this.placeId="";
        this.isReviewStatusLoading = true;
      }
    });
  }

  onStoreChange(event: any): void {
    const store = event.value; 
    this.selectedStoreId = store.qid; 
    this.onPlatformAndStoreChange();
  }

  refreshPlaceId() {
    if (this.selectedPlatform && this.selectedStoreId) {
      this.getPlaceId();
    }
  }

  onSave() {
    const reviewConfigPayload = {
      storeId: this.selectedStoreId, 
      platform: this.selectedPlatform,
      merchantId: this.merchantId,
      active: this.dailyReviewStatus,
      historicSync: this.historicalSyncStatus, 
    };
    this.apiCall.updateReviewConfig(reviewConfigPayload).subscribe(
      (response) => {
          this.dialogRef.close({ decision: true });
      },
      (error) => {
        console.error("Error updating review config", error);
      }
    );
  
  }

  onCancel(): void {
    this.dialogRef.close();
  }


}
