<div class="qb-body">
  <div>
    <div class="qb-title">{{'PARTNERSHIP_PAGE.OVERALL_ANALYTICS' | i18nTranslate}}</div>
    <div class="qb-cards">
      <div class="qb-card">
        <div class="qb-card-titles">
          <div class="qb-card-title">{{'PARTNERSHIP_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
          <div
            class="qb-i-div"
            type="button"
            [ngbPopover]="popContent"
            triggers="mouseenter:mouseleave"
          >
            <ng-template #popContent>
              <div class="qb-i-text">
                {{'PARTNERSHIP_PAGE.REVENUE_GENERATED_INFO_ANALYTICS' | i18nTranslate}}
              </div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
          <div class="qb-image-div">
            <div class="qb-image">
              <img
                class="qb-images"
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/money.png"
              />
            </div>
          </div>
        </div>
        <div class="qb-card-content">
          <div>
            <div class="qb-card-value">
              {{ currencySymbol }}{{ totalRevenue | number: "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
      <div class="qb-card qb-small-card">
        <div class="qb-card-titles">
          <div class="qb-card-title">{{'PARTNERSHIP_PAGE.PRODUCTS_SOLD' | i18nTranslate}}</div>
          <div
            class="qb-i-div"
            type="button"
            [ngbPopover]="popContent2"
            triggers="mouseenter:mouseleave"
          >
            <ng-template #popContent2>
              <div class="qb-i-text">{{'PARTNERSHIP_PAGE.PRODUCTS_SOLD_INFO_ANALYTICS' | i18nTranslate}}</div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
          <div class="qb-image-div">
            <div class="qb-image">
              <img
                class="qb-images"
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/cart.png"
              />
            </div>
          </div>
        </div>
        <div class="qb-card-content">
          <div>
            <div class="qb-card-value">{{ totalProductSold }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="qb-card">
        <div class="qb-card-title">Customer Acquired</div>
        <div class="qb-card-content">
          <div>
            <div class="qb-card-value">{{ customersAcquired }}</div>
          </div>
        </div>
      </div> -->
      <div class="qb-card qb-small-card" *ngIf="!isPos">
        <div class="qb-card-titles">
          <div class="qb-card-title">{{'PARTNERSHIP_PAGE.TOTAL_VISITORS' | i18nTranslate}}</div>
          <div
            class="qb-i-div"
            type="button"
            [ngbPopover]="popContent3"
            triggers="mouseenter:mouseleave"
          >
            <ng-template #popContent3>
              <div class="qb-i-text">
                {{'PARTNERSHIP_PAGE.TOTAL_VISTORS_INFO_ANALYTICS' | i18nTranslate }}
              </div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
          <div class="qb-image-div">
            <div class="qb-image">
              <img
                class="qb-images"
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/visitors.png"
              />
            </div>
          </div>
        </div>
        <div class="qb-card-content">
          <div>
            <div class="qb-card-value">{{ totalVisitors }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="qb-graph-card">
    <div class="qb-graph-header">
      <div class="qb-main-div">
        <div class="qb-graph-title">{{'PARTNERSHIP_PAGE.REVENUE_GENERATED_BY_REWARDS' | i18nTranslate}}</div>
        <div
          class="qb-i-div"
          type="button"
          [ngbPopover]="popContent4"
          triggers="mouseenter:mouseleave"
        >
          <ng-template #popContent4>
            <div clas="qb-i-text">
              {{'PARTNERSHIP_PAGE.REVENUE_GENERATED_BY_REWARDS_INFO_ANALYTICS' | i18nTranslate}}
            </div>
          </ng-template>
          <div class="qb-i">i</div>
        </div>
      </div>
      <div class="qb-range">
        <div
          class="material-icons-outlined qb-calendar-icon"
          (click)="select.open()"
        >
          calendar_month
        </div>
        <div class="qb-range-select">
          <mat-select [(value)]="timeRange1" #select>
            <mat-option value="All Time">{{'PARTNERSHIP_PAGE.ALL_TIME' | i18nTranslate}}</mat-option>
            <mat-option value="This Week">{{'PARTNERSHIP_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="qb-graph-div">
      <div *ngIf="!noGraph" class="qb-graph">
        <app-revenue-rewards-chart
          [selectedGraphButton]="timeRange1"
          [width]="930"
          page="total"
          id="null"
        >
        </app-revenue-rewards-chart>
      </div>
    </div>
  </div>
  <div class="qb-graph-card">
    <div class="qb-graph-header">
      <div class="qb-main-div">
        <div class="qb-graph-title">{{'PARTNERSHIP_PAGE.REVENUE_GENERATED_BY_DISCOUNT' | i18nTranslate}}</div>
        <div
          class="qb-i-div"
          type="button"
          [ngbPopover]="popContent5"
          triggers="mouseenter:mouseleave"
        >
          <ng-template #popContent5>
            <div class="qb-i-text">
              {{'PARTNERSHIP_PAGE.REVENUE_GENERATED_BY_DISCOUNT_INFO' | i18nTranslate}}
            </div>
          </ng-template>
          <div class="qb-i">i</div>
        </div>
      </div>
      <div class="qb-range">
        <div
          class="material-icons-outlined qb-calendar-icon"
          (click)="select1.open()"
        >
          calendar_month
        </div>
        <div class="qb-range-select">
          <mat-select [(value)]="timeRange2" #select1>
            <mat-option value="All Time">{{'PARTNERSHIP_PAGE.ALL_TIME' | i18nTranslate}}</mat-option>
            <mat-option value="This Week">{{'PARTNERSHIP_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="qb-graph-div">
      <div *ngIf="!noGraph" class="qb-graph">
        <app-revenue-offer-chart
          [selectedGraphButton]="timeRange2"
          [width]="930"
          page="total"
          id="null"
        >
        </app-revenue-offer-chart>
      </div>
      <div *ngIf="noGraph" class="qb-no-graph">
        <div>
          <img
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/no-graph.png"
          />
          <div class="qb-no-graph-text">
            {{'PARTNERSHIP_PAGE.THERE_IS_NO_DATA_AVAILABLE' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-graph-card">
    <div class="qb-graph-header">
      <div class="qb-main-div">
        <div class="qb-graph-title">{{'PARTNERSHIP_PAGE.PRODUCTS_SOLD' | i18nTranslate}}</div>
        <div
          class="qb-i-div"
          type="button"
          [ngbPopover]="popContent6"
          triggers="mouseenter:mouseleave"
        >
          <ng-template #popContent6>
            <div class="qb-i-text">{{'PARTNERSHIP_PAGE.PRODUCTS_SOLD_INFO_ANALYTICS' | i18nTranslate}}</div>
          </ng-template>
          <div class="qb-i">i</div>
        </div>
      </div>
      <div class="qb-range">
        <div
          class="material-icons-outlined qb-calendar-icon"
          (click)="select3.open()"
        >
          calendar_month
        </div>
        <div class="qb-range-select">
          <mat-select [(value)]="timeRange3" #select3>
            <mat-option value="All Time">{{'PARTNERSHIP_PAGE.ALL_TIME' | i18nTranslate}}</mat-option>
            <mat-option value="This Week">{{'PARTNERSHIP_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="qb-graph-div">
      <div *ngIf="!noGraph" class="qb-graph">
        <app-products-sold-chart
          [selectedGraphButton]="timeRange3"
          [width]="930"
          page="total"
          id="null"
        >
        </app-products-sold-chart>
      </div>
      <div *ngIf="noGraph" class="qb-no-graph">
        <div>
          <img
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/no-graph.png"
          />
          <div class="qb-no-graph-text">
            {{'PARTNERSHIP_PAGE.THERE_IS_NO_DATA_AVAILABLE' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
