import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { AllReportsComponent } from './pages';
import { RepotsRoutingModule } from './reports-routing.module';
  



@NgModule({
  declarations: [
    AllReportsComponent
   ],
  imports: [
    SharedModule,
    RepotsRoutingModule
  ]
})
export class ReportsModule { }
