<div class="surveyContainer" data-widget-name="SURVEY">
  <div>
    <div class="featureTab">

      <!-- <div style="display: flex;width: 64%;    margin: 0 2%;">
      <div class="tabName" [ngClass]="{active:selectedTab=='survey-list'}" (click)="tabChange('survey-list')" data-button-name="Survey List"> SURVEY LIST

      </div>
      <div class="tabName" [ngClass]="{active:selectedTab=='dashboard'}" (click)="tabChange('dashboard')"  data-button-name="Dashboard">DASHBOARD

      </div>
    </div> -->
      <div *ngIf="mobileView===true" class="header-contents segment-type-container"
        style="justify-content: center; font-size: 14px">
        <span [ngClass]="{ active: selectedTab === 'survey-list' }" (click)="tabChange('survey-list')"
          class="segment-types" data-button-name="survey-list">{{'SURVEY_PAGE.SURVEY_LIST' | i18nTranslate}}</span>
        <span [ngClass]="{ active: selectedTab === 'dashboard' }" (click)="tabChange('dashboard')" class="segment-types"
          data-button-name="dashboard">{{'SURVEY_PAGE.DASHBOARD' | i18nTranslate}}</span>
      </div>

      <app-breadcrumb></app-breadcrumb>
      <div class="button">

        <!-- <div class="buttonList" (click)="surveylist()" style="margin-right: 15px;">

        <span class="normText" >Template List</span>
      </div> -->

        <div class="primary-button" (click)="surveylist()" data-button-name="Create New Survey">
          <span >{{'SURVEY_PAGE.CREATE_NEW_SURVEY_ADD' | i18nTranslate}}</span>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="selectedTab=='survey-list'" class="survey-details-container">
    <div class="tabContent">

      <div class="contentSurveyList">
        <app-survey-instance></app-survey-instance>
      </div>
      <!-- <div class="contentAnalysis"></div>

  </div> -->
    </div>


  </div>
  <div *ngIf="selectedTab=='dashboard'" style="width: 100%; flex: 1 1 auto; overflow: auto;">
    <div class="tabContent">

      <div class="contentSurveyList">
        <app-survey-dashboard></app-survey-dashboard>
      </div>
    </div>
  </div>
</div>