<div *ngIf="noGraph" class="no-graph">
  <div>
    <img
      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/no-graph.png"
    />
    <div class="no-graph-text">
      {{'SHARED_PAGE.NO_DATA' | i18nTranslate}}
    </div>
  </div>
</div>

<div *ngIf="!noGraph" class="dashboard-line-chart-main-container">
  <!-- <div *ngIf="graphLoading" class="offset-screen-container">
    <mat-spinner diameter="25"></mat-spinner>
  </div> -->
  <div
    class="dashboard-analytics-graph-container"
    style="width: 100%; height: 100%"
    #chartContainer
  >
    <div class="dashboard-analytics-graph-ylabel">
      <span *ngIf="graphLabelStatus" class="y-status">{{ ylabel | i18nDynamicTranslate : 'SHARED_PAGE'}}</span>
    </div>
    <div style="width: calc(100% - 25px)">
      <div #line id="line" class="dashboard-analytics-line-chart-container"></div>
      <div
        *ngIf="!graphLoading && graphLabelStatus"
        class="dashboard-analytics-graph-xlabel"
      >
        <span>{{'SHARED_PAGE.DAYS' | i18nTranslate}}</span>
      </div>
    </div>
  </div>
</div>
