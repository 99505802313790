import {
  Component,
  OnInit,
} from "@angular/core";

import { MatDialog, DialogPosition } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { FilterDialogService } from "src/app/core/services/filter-dialog.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SuccessDialogComponent, UploadCsvDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { Event,Router,NavigationEnd } from "@angular/router";


interface ImportEntry {
  status: 'success' | 'warning';
  title: string;
  details: string[];
  importedOn: string;
}

@Component({
  selector: 'app-customer-upload-history',
  templateUrl: './customer-upload-history.component.html',
  styleUrls: ['./customer-upload-history.component.css']
})
export class CustomerUploadHistoryComponent implements OnInit {
  dialogPosition: DialogPosition = {
    top: "0px",
    right: "0px",
  };
  pageSize = 10;
  pageIndex = 1;
  totalCount=0;
  searchValue = "";
  storeId = null;
  listType = "";
  currentUrl = "";
  isleads = false;
  importEntries: ImportEntry[] = [];
  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private filterData: FilterDialogService,
    private tokenStorage: TokenStorageService,
    private date_format: getdateformatService,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    public router: Router
  ) {
    window["screen-name"] = "ALL CUSTOMERS";
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.split(";")[0];
      }
    });
  }
 
  ngOnInit(): void {
    if(this.tokenStorage.getqId() === 'null'){
      this.storeId = null;
    }
    else{
      this.storeId = this.tokenStorage.getUploadStoreId();
    }
    this.listType = this.getListType();
    this.fetchUploadHistory(this.listType);
  }

  getListType(): string {
    this.currentUrl = this.currentUrl.split(";")[0];
    let currentUrlPath = this.currentUrl.split(";")[0];
    if (currentUrlPath.includes("app/customers/upload-history")) {
      this.isleads=false;
      return "customerUpload";
    } else {
      this.isleads=true;
      return "leadsUpload";
    }
  }

  uploadCSV(){
    const dialogref = this.dialog.open(
      UploadCsvDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "750px",
        height : "600px",
        data: { leads: this.isleads },
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if (result.decision) {
        this.openSuccessDialog(
          result.response['body'],
          "OK"
        );
      }
     this.fetchUploadHistory(this.listType);
    })
  }
  
  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
  }
  fetchUploadHistory(listType?: string): void {
    const leadsParam = listType === "leadsUpload" ? true : false;
    this.apiCall.getUploadHistory(this.pageIndex,this.pageSize,this.storeId,leadsParam).subscribe(
      response => {
        if (response['message'] === 'success') {
          this.totalCount = response['body']['count']
          this.importEntries = response['body']['fileUploadHistoryList'].map(entry => ({
            status: entry.status,
            title: `Import from ${entry.fileType}`,
            custListId: entry.customerListId,
            details: [
              `${entry.uploadCount} contact${entry.uploadCount !== 1 ? 's' : ''} were added/updated to your audience.`,
              `There were ${entry.duplicateCount} duplicate email${entry.duplicateCount !== 1 ? 's' : ''}  in your uploaded file.`,
              `${entry.failedCount} row${entry.failedCount !== 1 ? 's' : ''} had syntax errors.`].filter(detail => detail !== ''),
            importedOn: entry.createdTime ? new Date(entry.createdTime).toLocaleString() : 'Unknown time',

          }));
        }
      },
      error => {
        console.error('Error fetching upload history:', error);
      }
    );
  }

  previewList(id){
    this.router.navigate(["/app/customers/view-customer"], {
      queryParams: { id: id, type: "customerList" }
    });
  }
  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchUploadHistory(this.listType)
  }
 
}
