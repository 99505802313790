<div style="display: flex; flex-direction: column; height: 100%">
    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0px 15px;
        height: 40px;
      "
    >
      <div class="head-title">{{'SHARED_PAGE.SELECT_STORES' | i18nTranslate}}</div>
      <mat-icon
        style="
          color: #efefef;
          background: #a9a9a9;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        "
        (click)="cancel()"
        data-button-name="Close"
        >cancel</mat-icon
      >
    </div>

    <!-- </div>
    <div class="formDivGame-search" style="border-radius: 4px;
    background: #F8F8F8;
    margin: 0px 15px;
    height: 40px;">
        <input class="formInputGame" autocomplete="off" matInput [(ngModel)]="search"
            placeholder="Search category (Minimum 3 characters)" (ngModelChange)="getInputField($event);search=$event;">
        <span class="material-icons-outlined icons-primary cursor-pointer">
            search
        </span>
    </div> -->
    <div
      *ngIf="(!storeAvailability) || !NoData"
      style="
        flex-grow: 1;
        overflow: auto;
        height: calc(100% - 100px);
        padding: 0px 15px;
        margin: 10px 0px;
      "
    >
      <div style="display: flex; justify-content: center">{{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
    </div>
    <div
      *ngIf="apiloading"
      style="
        flex-grow: 1;
        overflow: auto;
        height: calc(100% - 100px);
        padding: 0px 15px;
        margin: 10px 0px;
      "
    >
      <mat-spinner diameter="15" style="margin-left: 45%"></mat-spinner>
    </div>
     <!-- <div
      style="
        flex-grow: 1;
        overflow: auto;
        height: calc(100% - 100px);
        padding: 0px 15px;
        margin: 10px 0px;
      "
      *ngIf="DataAvailability && !apiloading"
    >
      <div style="margin-bottom: 15px" *ngIf="search.length<3">
        <mat-checkbox
          class="example-margin"
          [checked]="
            totalNoOfProducts === ProductArrayId.length && totalNoOfProducts != 0
          "
          (change)="selectAllCategoryAndProduct($event.checked)"
          color="primary"
        >
        <div style="display: flex;">
          <div>Select All</div>
          
            <span
            class="i-div"
            popoverClass="my-custom-class"
            placement="right"
            [ngbPopover]="popSelctAll"
            triggers="mouseenter:mouseleave"
            >i
            </span>
            <ng-template #popSelctAll>
            <div style="font-size: 12px; color: #fff">
              Check Select All and then deselect the products that you want to be included in the game.
            </div>
            </ng-template>
        </div>
        </mat-checkbox>
      </div>
      <hr *ngIf="search.length<3">
      <div style="display: flex; margin-bottom: 15px;" *ngIf="search.length<3">
        <section class="example-section" style="width: 100%;">
          <div style="display: flex; justify-content: space-between;">
            <div class="parent-product-ln">
              <mat-checkbox
                class="example-margin"
                [checked]="
                  nonCategoryCount === countInArray('NON_CATEGORY') &&
                  countInArray('NON_CATEGORY')
                "
                [indeterminate]="
                  nonCategoryCount > countInArray('NON_CATEGORY') &&
                  countInArray('NON_CATEGORY') != 0
                "
                (change)="
                  setAllCheckedNonCategory(
                    $event.checked,
                    NonCategoryProduct,
                    'NON_CATEGORY'
                  )
                "
                color="primary"
              >
                Non-Category Products
              </mat-checkbox>
            </div>
            <div>
              <div
                class="material-icons-outlined parent-arrow"
                (click)="parentArrowNonCategory(); getDataForTable($event)"
              >
                chevron_right
              </div>
            </div>
  
          </div>
          <span
            class="variant-product-ln active"
            [id]="'variant-section-noncategory'"
          >
            <div class="heightProductList" (scroll)="onScroll($event)">
              <div *ngFor="let product of NonCategoryProduct">
                <mat-checkbox
                  color="primary"
                  class="example-margin"
                  #selectGame
                  [checked]="ProductArrayId.indexOf(product.id) != -1"
                  (change)="
                    updateSelection(
                      product.id,
                      $event.checked,
                      'NON_CATEGORY',
                      NonCategoryProduct
                    )
                  "
                >
                  {{ product.name }}
                </mat-checkbox>
              </div>
            </div>
          </span>
        </section>
  
      </div>
      <div
        *ngFor="let category of ProductAllIds; let i = index"
        style="margin-bottom: 15px"
      >
        <div style="display: flex" *ngIf="category.products.length != 0">
          <section class="example-section" style="width: 100%;">
            <div style="display: flex; justify-content: space-between;">
              <span class="parent-product-ln">
                <mat-checkbox
                  class="example-margin"
                  [checked]="
                    category.products.length === countInArray(category.id) &&
                    countInArray(category.id)
                  "
                  [indeterminate]="
                    category.products.length > countInArray(category.id) &&
                    countInArray(category.id) != 0
                  "
                  (change)="
                    setAllChecked($event.checked, category.products, category.id)
                  "
                  color="primary"
                >
                  {{ category.name }}
                </mat-checkbox>
              </span>
              <div>
                <span
                  class="material-icons-outlined parent-arrow"
                  (click)="parentArrowClicked(i); getDataForTable($event)"
                >
                  chevron_right
                </span>
              </div>
  
            </div>
            <span class="variant-product-ln active" [id]="'variant-section-' + i">
              <div class="heightProductList">
                <div *ngFor="let product of category['products']">
                  <mat-checkbox
                    color="primary"
                    [checked]="ProductArrayId.indexOf(product.id) != -1"
                    (change)="
                      updateSelection(
                        product.id,
                        $event.checked,
                        category.id,
                        category.products
                      )
                    "
                  >
                    {{ product.name }}
                  </mat-checkbox>
                </div>
              </div>
            </span>
          </section>
        </div>
      </div>
    </div> -->

    <div style="
    flex-grow: 1;
    overflow: auto;
    height: calc(100% - 100px);
    padding: 0px 15px;
    margin: 10px 0px;
  "
  *ngIf="storeAvailability">
  <div>
      <mat-checkbox (change)="addAllStores($event.checked)" color="primary"  class="example-margin" [checked]="allStoresSelected()">
          {{'SHARED_PAGE.SELECT_ALL' | i18nTranslate}}
      </mat-checkbox>     
  </div>
    <div *ngFor="let store of storeList">
        <mat-checkbox
        color="primary"
        [checked]="selectedStoresId.indexOf(store['storeId'])!=-1"
        class="example-margin"
        #selectStore
        (change)="updateStoreData($event.checked, store)"
        >
        {{ store.storeName }} 
        </mat-checkbox>
    </div>

    </div>

    <div     style="
    height: 85px;
    width: 100%;
    padding: 15px 15px;
    box-shadow: 2px -2px 20px rgb(0 0 0 / 8%);
  ">
  <div style="display: flex;justify-content: flex-end; align-items: center;">
    <div class="cancel-btn" (click)="cancel()">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
    <div class="primary-button" (click)="save()">{{'SHARED_PAGE.SAVE' | i18nTranslate}}</div>
  </div>
  
    </div>
</div>
  