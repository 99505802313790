<div class="header" >
  <span class="header-title" *ngIf="sendagain!=true"><strong>{{'PROFILE_PAGE.ADD_USER_CAPS' | i18nTranslate}}</strong></span>
  <span class="header-title" *ngIf="sendagain==true"><strong>{{'PROFILE_PAGE.RESEND_INVITATION_CAPS' | i18nTranslate}}</strong></span>
  <mat-dialog-actions>
    <button class="xIcon" mat-dialog-close><div class="material-icons" style="margin-left: -4.5px;
      font-size: 15px;">clear</div></button>
  </mat-dialog-actions>
</div>
<div>
  <div class="enterEmail">{{'PROFILE_PAGE.EMAIL_SENT_FOR_USER_MANAGEMENT_TEXT' | i18nTranslate}}</div>
  <div class="selectDomainName-user">
    <div style="display: flex; flex-direction: row; gap: 10px;">
      <div style="display: flex; flex-direction: column;">
        <span class="filterTitle">{{'PROFILE_PAGE.NAME' | i18nTranslate}}</span>
        <input type="text" [(ngModel)]="name" (keydown)="keypress()">
        <div *ngIf="errorName.length > 0" style="color: red; font-size: 11px;">{{errorName}}</div>
      </div>
      <div style="display: flex; flex-direction: column;">
        <span class="filterTitle">{{'PROFILE_PAGE.EMAIL' | i18nTranslate}}</span>
        <input type="text" [(ngModel)]="email" (keydown)="keypress()">
        <div *ngIf="errorMail.length > 0" style="color: red; font-size: 11px;">{{errorMail}}</div>
      </div>
    </div>
    <div style="display: flex; flex-direction: row;">
      <div style="display: flex; flex-direction: column;">
        <span class="filterTitle">{{'PROFILE_PAGE.PHONE_NUMBER' | i18nTranslate}}</span>
        <div class="qb-d-flex">
          <div>
            <div [matMenuTriggerFor]="countryCodeSelect" style="text-transform: capitalize;" class="border-country cursor-pointer">
              <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 80%;">
                <div class="qb-d-flex qb-ai-center">
                  +{{countryCode}}
                </div>
                <div class="qb-d-flex qb-ai-center">
                  <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
                  >keyboard_arrow_down</mat-icon
                >
                </div>
              </div>
            </div>
            <mat-menu #countryCodeSelect class="qb-channel-dialog">
              <div *ngFor="let country of countryCodeData | keyvalue">
                <div
                mat-menu-item
                class="qb-channel-dialog-content-country"
                (click)="changeCountry(country.value.Code)"
              >
              <div class="qb-d-flex" style="gap: 5px;">
                <img [src]="country.value.Flag" height="20px" width="20px">
                <span>(+{{country.value.Code}}) {{country.key}}</span>
              </div>
                </div>
              </div>
            </mat-menu>
          </div>
          <div class="qb-input-field" style="width: 276px;">
            <input
              class="qb-input"
              type="text"
              placeholder="9999999999"
              [(ngModel)]="number"
              maxlength=10
              (keydown)="numberInputHandler($event)"
              (input)="getErrorTextNumber($event)"
            />
          </div>
        </div>
        <div *ngIf="phoneNumberErrorText.length > 0" style="color: red; font-size: 11px;">{{phoneNumberErrorText}}</div>
        <!-- <div *ngIf="submit === true && errorTextNumber && number.length > 0" class="qb-error-text">{{'CUSTOMER_PAGE.ENTER_VALID_PHONE' | i18nTranslate}}</div>
        <div *ngIf="submit === true && number.length === 0" class="qb-error-text">{{'CUSTOMER_PAGE.PLEASE_ENTER_PHONE_NUMBER' | i18nTranslate}}</div>
        <div *ngIf="submit === true && number.length > 0" class="qb-error-text">{{phoneNumberErrorText | i18nDynamicTranslate : 'POS_PAGE'}}</div> -->
      </div>
      <div>
        <span class="filterTitle">{{'PROFILE_PAGE.USER_TYPE' | i18nTranslate}}</span>
        <div [matMenuTriggerFor]="userTypeSelect" style="text-transform: capitalize;" class="qb-border-usertype cursor-pointer">
          <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 339px;">
            <div class="qb-d-flex qb-ai-center" *ngIf="userType.name.length > 0">
              {{userType.name}}
            </div>
            <div class="qb-d-flex qb-ai-center" *ngIf="userType.name.length === 0">
              Select User Type
            </div>
            <div class="qb-d-flex qb-ai-center">
              <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
              >keyboard_arrow_down</mat-icon
            >
            </div>
          </div>
        </div>
        <mat-menu #userTypeSelect class="qb-channel-dialog">
          <div>
            <div
            mat-menu-item
            class="qb-channel-dialog-content-country"
            (click)="chaneguserSelect('ROLE_STORE_MANAGER', 'Store Manager')"
          >
          <div class="qb-d-flex" style="gap: 5px; width: 270px;">
            <span>Store Manager</span>
          </div>
            </div>
            <div
            mat-menu-item
            class="qb-channel-dialog-content-country"
            (click)="chaneguserSelect('ROLE_USER', 'Delegate Admin')"
          >
          <div class="qb-d-flex" style="gap: 5px;">
            <span>Delegate Admin</span>
          </div>
            </div>
            <div
            mat-menu-item
            class="qb-channel-dialog-content-country"
            (click)="chaneguserSelect('ROLE_CUSTOMER_CARE', 'Customer Care')"
          >
          <div class="qb-d-flex" style="gap: 5px;">
            <span>Customer Care</span>
          </div>
            </div>
            <div
            mat-menu-item
            class="qb-channel-dialog-content-country"
            (click)="chaneguserSelect('ROLE_CUSTOMER_CARE_VIEW', 'Customer Care View Only')"
          >
          <div class="qb-d-flex" style="gap: 5px;">
            <span>Customer Care View Only</span>
          </div>
            </div>
            <div
            mat-menu-item
            class="qb-channel-dialog-content-country"
            (click)="chaneguserSelect('ROLE_REPORTING_VIEW', 'View Report User')"
          >
          <div class="qb-d-flex" style="gap: 5px;">
            <span>View Report User</span>
          </div>
            </div>
          </div>
        </mat-menu>
        <div *ngIf="errorUserType.length > 0" style="color: red; font-size: 11px;">{{errorUserType}}</div>
      </div>

    </div>
    <div class="qb-d-flex" style="flex-direction: column;" *ngIf="userType.type === 'ROLE_STORE_MANAGER'">
      <span class="filterTitle">{{'PROFILE_PAGE.SELECT_STORES' | i18nTranslate}}</span>
      <div class="qb-border-usertype cursor-pointer" style="width: 340px;" (click)="removeError()">
        <mat-select placeholder="Select Stores" [(ngModel)]="selectedStores" multiple style="padding: 10px;">
          <div *ngFor="let store of stores">
            <mat-option [value]="store.qid" *ngIf="store.qid !== null">{{ store.storeName }}</mat-option>            
          </div>
        </mat-select>
      </div>
    </div>
    <div *ngIf="errorStoreSelect.length > 0" style="color: red; font-size: 11px;">{{errorStoreSelect}}</div>
  </div>
  <div class="info-header">User type info<span class="qb-i-div">i</span></div>
  <div class="info-container">
    <div class="info-text">Store Manager: Provides access to a particular store or set of stores.</div>
    <div class="info-text">Delegate Admin: Provides a similar access as your own account to another admin user.</div>
  </div>
  
  <div class="buttons">
    <mat-dialog-actions>  
      <button class="cancel secondary-button-border" (click)="submit()" [mat-dialog-close]="cancel" data-button-name="Canceled">{{'PROFILE_PAGE.CANCEL' | i18nTranslate}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="sendagain!=true">
      <button class="confirm primary-button" (click)="add()" data-button-name="Confirm: User Added" [attr.data-item-name]="email" *ngIf="!apiloading"> {{'PROFILE_PAGE.INVITE' | i18nTranslate}}</button>
      <button class="confirm secondary-button" style="width: 76px; justify-content: center;" *ngIf="apiloading"><mat-spinner diameter="15"></mat-spinner></button>
    </mat-dialog-actions>
    <!-- <mat-dialog-actions *ngIf="sendagain!=true && apiloading" #loadSpinner>
      <button class="confirm primary-button" (click)="add()" data-button-name="Confirm: User Added" [attr.data-item-name]="email" >     <mat-spinner
        *ngIf="apiloading"
        diameter="15"
      ></mat-spinner></button>
    </mat-dialog-actions> -->
    <mat-dialog-actions *ngIf="sendagain==true">
      <button class="confirm primary-button" (click)="sendAgain()" data-button-name="Confirm: User Added" [attr.data-item-name]="email" *ngIf="!apiloading">{{'PROFILE_PAGE.RESENT_INVITATION' | i18nTranslate}}</button>
      <button class="confirm secondary-button" style="width: 155px; justify-content: center;" *ngIf="apiloading"><mat-spinner diameter="15"></mat-spinner></button>
    </mat-dialog-actions>
  </div>
</div>