<div id="importContainer">


    <div>
        <span class="fontExo2">{{'SHARED_PAGE.SELECT_ANY_TEMPLATE' | i18nTranslate : data.type}}:</span>
        <div style="margin: 10px 0;" *ngIf="data.type=='offer'">
            <div class="formDiv" *ngIf="!loading;else loadSpinner">
                <!-- <mat-icon style="color: #88c4fb; padding: 10px;">scatter_plot</mat-icon> -->
                <mat-select class="fontExo2" color="primary" placeholder="Offer Name" [(ngModel)]="selectedId">
                    <mat-option class="fontExo2" *ngFor="let offer of master" [value]="offer.id">
                        {{offer.offerName}}</mat-option>
                </mat-select>
            </div>
        </div>
        <div style="margin: 10px 0;" *ngIf="data.type=='survey'">
            <div class="formDiv" *ngIf="!loading;else loadSpinner">
                <!-- <mat-icon style="color: #88c4fb; padding: 10px;">scatter_plot</mat-icon> -->
                <mat-select class="fontExo2" color="primary" placeholder="survey Name" [(ngModel)]="selectedId">
                    <mat-option class="fontExo2" *ngFor="let survey of master" [value]="survey.id">
                        {{survey.layoutName}}</mat-option>
                </mat-select>
            </div>
        </div>

        <div style="padding: 15px" *ngIf="data.type=='surveytest'">
            <div
              class="multi-select-panel"
              id="multi-select-panel"
              (click)="toggleSelect()"
            >
            <span> {{ selectedName }}</span>
              <div
                class="keyboard-down-icon-container"
                id="keyboard-down-icon-container"
              >
                <mat-icon class="keyboard-down-icon">keyboard_arrow_down</mat-icon>
              </div>
            </div>
            <div style="position: relative">
              <div class="multi-select-dropdown" id="multi-select-dropdown">
                <div class="search">
                  <mat-icon class="group-icon">search</mat-icon>
                  <input
                    [(ngModel)]="filterSearch.label"
                    placeholder="Search Segment"
                    type="text"
                    style="border: none; outline: none"
                    [value]="selectedName"
                  />
                </div>
                <div class="border-line"></div>
                <div id="segment-select" class="segment-select">
                  <div>
                    <div
                      *ngFor="
                      let survey of master| labelPipe: filterSearch.label:'layoutName'
                      "
                    >
                      <!-- <mat-select
                        (change)="updateCheckboxValues(survey)"
                        color="primary"
                        >{{ survey.layoutName }}</mat-select> -->
                        <!-- <mat-select class="fontExo2" color="primary" placeholder="survey Name" [(ngModel)]="selectedId"> -->
                          <mat-option class="optionList fontExo2" (click)="selectedId=survey.id;selectedName=survey.layoutName;toggleSelect()" >
                              {{survey.layoutName}}</mat-option>
                      <!-- </mat-select> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <ng-template #loadSpinner>
            <div style="display: flex;justify-content: center;">
                <mat-spinner diameter="25"></mat-spinner>
            </div>
        </ng-template>

    </div>
    <div style="display:flex; justify-content:space-between; width: 100%;">
        <span class="dismiss-button" (click)="closeDialog(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
        <span class="done-button" (click)="closeDialog(selectedId)">{{'SHARED_PAGE.IMPORT' | i18nTranslate}}</span>
    </div>

</div>