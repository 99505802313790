
<div class="font">
  <div>
    <div class="header">
      <span style="text-align: center">{{data['header']}}</span>
    </div>
  </div>
  <div>
    <div class="message">
      <span>{{data['body']}}</span>
    </div>
  </div>
  <!-- <div *ngIf="data['to-screen']" style="
      display: flex;
      justify-content: space-between;
      padding: 0px 20px 15px 20px;
    ">
        <span class="dismiss-button" (click)="submit(false)">Cancel</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen'] != 'campaign'">OK</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen']!='campaign'&& data['to-screen']!='survey-alert'">OK</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen']=='survey-alert'">Delete</span>
        <span class="done-button" (click)="submit(true)" *ngIf="data['to-screen'] == 'campaign'" widget-id="16" event-id="2" type="button" [attr.data-strategy-action]="data['action']" [attr.object-type]="data['strategyType']">Confirm</span>
    </div> -->

  <div
    style="
      display: flex;
      justify-content: space-between;
      padding: 0px 20px 15px 20px;
    " data-widget-name="DOMAINS"
  >
    <span class="dismiss-button" *ngIf="!data['cancelButtonText']" (click)="submit(false)" data-button-name="Canceled domain deletion" [attr.data-item-name]="data['domainName']">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="done-button" *ngIf="!data['successButtonText']" (click)="submit(true)" data-button-name="Confirm: Delete" [attr.data-item-name]="data['domainName']">{{'SHARED_PAGE.DELETE' | i18nTranslate}}</span>
  </div>
</div>









