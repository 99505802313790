import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';

@Pipe({
  name: 'boldText'
})
export class BoldTextPipe implements PipeTransform {

  constructor(
    private sanitizer: Sanitizer
  ) {}

  transform(textValue: string, subTextValue: string): string {
    return textValue.replace(new RegExp(`(${subTextValue})`, 'gi'), '<b>$1</b>');
}
  transform2(value: string, regex): any {
    return this.sanitize(this.replace(value, regex));
  }

  replace(str, regex) {
    return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
  }

  sanitize(str) {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }
}