import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-survey-alert-recommendation-dialog",
  templateUrl: "./survey-alert-recommendation-dialog.component.html",
  styleUrls: ["./survey-alert-recommendation-dialog.component.css"],
})
export class SurveyAlertRecommendationDialogComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    public dialogRef: MatDialogRef<SurveyAlertRecommendationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private tokenStorage: TokenStorageService
  ) {}

  s3BucketUrl = environment.s3BucketUrl;
  templateList = [];

  dataForm = new FormGroup({
    customerIds: new FormControl("", [Validators.required]),
    emailSubject: new FormControl("", [
      Validators.required,
      Validators.maxLength(50),
    ]),
  });

  selectedTemplateId;
  submitClicked = false;
  recommendedTemplate;
  templates;
  customerIdList;
  dummy = null;
  countTemplate=[];
  allTemplates=[];
  recentNull:boolean=false;
  ngOnInit() {
    let id = [];

    this.data["customerIds"].map((x) => {
      id.push(x.id);
    });
    this.customerIdList = id;
if(this.data['type']=='EMAIL'){
  this.allTemplates=this.data['templates']
}
    this.dataForm.get("customerIds").setValue(id);
    let recommendedTemplateIndex = this.data["templates"].findIndex(
      (x) => x.id === this.data["templateId"]
    );
    if (recommendedTemplateIndex > -1) {
      this.recommendedTemplate = this.data["templates"][
        recommendedTemplateIndex
      ];
      this.data["templates"].splice(recommendedTemplateIndex, 1);
      this.selectedTemplateId = this.recommendedTemplate["id"];
      if (this.data["recentlycreated"] > 0) {
        var s = "survey-alert-template-" + this.data["recentlycreated"];
        setTimeout(() => {
          var x: number = +this.data["recentlycreated"];
          this.selectTemplate(x);
          document
            .getElementById(s)
            .scrollIntoView({ behavior: "smooth", block: "center" });
        }, 1500);
      }
    } else {
      if (this.data["recentlycreated"] > 0) {
        var s = "survey-alert-template-" + this.data["recentlycreated"];

        setTimeout(() => {
          var x: number = +this.data["recentlycreated"];
          this.selectTemplate(x);
          document
            .getElementById(s)
            .scrollIntoView({ behavior: "smooth", block: "center" });
        }, 2500);
      } else {
        // this.selectedTemplateId = this.data['templates'][0]['id'];
      }
    }
  }

  selectTemplate(id) {
    this.selectedTemplateId = id;
  }

  createTemplate(type) {
    this.tokenStorage.setRecommendedUserId(
      JSON.stringify(this.dataForm.get("customerIds").value)
    );
    this.tokenStorage.setActionRecommenderId(this.data["id"]);
    var customerSelList = this.dataForm.get("customerIds").value;
    var excludedList = this.customerIdList.filter(function (obj) {
      return customerSelList.indexOf(obj) == -1;
    });

    this.tokenStorage.setExcludedRecommendedUserId(
      JSON.stringify(excludedList)
    );
    switch (type) {
      case "OFFER":
        this.dialogRef.close();
        this.router.navigate(["app/offer/create-offer"], {
          queryParams: { recAction: true },
        });
        break;
      case "EMAIL":
        this.dialogRef.close();
        this.router.navigate(
          ["app/email/layout-editor", { process: "recommend" }],
          { queryParams: { recAction: true } }
        );
        break;
      default:
        break;
    }
  }

  editTemplate(type, id) {
    this.tokenStorage.setRecommendedUserId(
      JSON.stringify(this.dataForm.get("customerIds").value)
    );
    this.tokenStorage.setActionRecommenderId(this.data["id"]);
    var customerSelList = this.dataForm.get("customerIds").value;
    var excludedList = this.customerIdList.filter(function (obj) {
      return customerSelList.indexOf(obj) == -1;
    });

    this.tokenStorage.setExcludedRecommendedUserId(
      JSON.stringify(excludedList)
    );
    switch (type) {
      case "OFFER":
        this.dialogRef.close();
        this.router.navigate(["app/offer/create-offer", { id: id }], {
          queryParams: { recAction: true },
        });
        break;
      case "EMAIL":
        this.dialogRef.close();
        this.router.navigate(
          ["app/email/layout-editor", { process: "recommend", id: id }],
          { queryParams: { recAction: true } }
        );
        break;
      default:
        break;
    }
  }

  closeDialog(status) {
    if (status) {
      this.submitClicked = true;
      if (
        this.dataForm.get("customerIds").errors ||
        (this.data["type"] === "EMAIL" &&
          this.dataForm.get("emailSubject").errors)
      ) {
        return;
      }
      var customerSelList = this.dataForm.get("customerIds").value;
      var excludedList = this.customerIdList.filter(function (obj) {
        return customerSelList.indexOf(obj) == -1;
      });
      let data = {
        customerIds: this.dataForm.get("customerIds").value,
        type: this.data["type"],
        templateId: this.selectedTemplateId,
        template:
          this.data["templates"].findIndex(
            (x) => x.id === this.selectedTemplateId
          ) > -1
            ? this.data["templates"][
                this.data["templates"].findIndex(
                  (x) => x.id === this.selectedTemplateId
                )
              ]
            : this.recommendedTemplate,
        subject: this.dataForm.get("emailSubject").value,
        alertId: this.data["id"],
        excludedCustomerList: excludedList,
      };
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close(false);
    }
  }


  doSomethingOnError(event,templateId,snapshotUrl) {
    
    var c;
    if(snapshotUrl==null){
      this.recentNull=true;
    c= this.getAlltemplates(event,templateId,1);
    }
  }

  getAlltemplates(event,templateId,recCount) {

    if(recCount==4){
      return true;
    }
    this.apiCall.listEmailLayout().subscribe((response) => {
      if (response["message"] === "success") {
        this.templateList = response["body"];       
    var s=this.templateList;


    var i;
    for(i=0;i<this.templateList.length;i++){

      if(this.templateList[i]['id']==templateId){
        if(this.templateList[i]['snapshotUrl']==null){
                  setTimeout(() => {
            this.getAlltemplates(event,templateId,recCount+1);
            return;
          },3000);
          
          return;          
        }else{
          this.recentNull=false;
          event.target.src =this.templateList[i]['snapshotUrl'];
          return;
        }
      }
    }
      }
    });
  }
}
