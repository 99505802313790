import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { HtmlForEmailTemplatesDialogComponent, ConfirmationDialogBoxComponent, PreviewTemplateComponent } from "src/app/shared/components/dialog-box";
import { OfferAttachEmailDialogComponent } from "../../../offer/pages";
import { MatSelect } from "@angular/material/select";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";


@Component({
  selector: "app-partnership-create-form",
  templateUrl: "./partnership-create-form.component.html",
  styleUrls: ["./partnership-create-form.component.css"],
})
export class PartnershipCreateFormComponent implements OnInit, PipeTransform {
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private datePipe: DatePipe,
    private snackBar: SnackbarCollection,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  @Output() apiData = new EventEmitter<string>();
  showDropdown = false;
  section = 1;
  disableCustomerSegmentSelection = false;
  disableAllCustomerSelection = false;
  selectedSegments = [];
  tempsegmentFilter = "";
  allcustomerObj = [{ id: "ALL_CUSTOMERS", name: "My Customers" }];
  segments = [];
  pformId = -1;
  days = [];
  devices = [];
  limit = [];
  showOn = [];
  showTo = [];
  showWhen = [];
  selectedRadio = "range";
  startDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  endDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  date = "";
  startTime = "";
  endTime = "";
  htmlforform;
  atAnyTime = true;
  everyDay = true;
  formNameError = "";
  productCollections = [];
  selectedProductCollections = [];
  ids = [];
  id = -1;
  message: string;
  subscription: Subscription;
  clone = false;
  imageURL = "";
  barrierId = -1;

  previewData = {
    selectedLayout: "",
    templateId: "null",
    templateName: "",
  };

  barrierJSON = {
    type: "AMOUNT",
    amountOrQuntity: 0,
    collection: [],
  };

  barrierData = {
    id: 0,
    merchantId: this.tokenStorage.getMerchantId(),
    barrierJson: {},
  };

  s2 = 0;
  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  offers;

  routes = [];
  data = {
    formId: null,
    name: "",
    type: "CUSTOM",
    status: "DRAFT",
    merchantId: this.tokenStorage.getMerchantId(),
    scheduleInfo: null,
    formData: null,
    fields: null,
    formHtml: null,
    formJson: null,
    barrierId: -1,
  };

  scheduleInfo = {
    merchantId: this.tokenStorage.getMerchantId(),
    pFormId: this.pformId,
    type: "CONTENT_AND_STYLE",
    timeEmailConfiguration: null,
    contentAndStyle: {
      showTo: "ALL",
      segments: [],
      showWhen: "IMMEDIATE",
      showWhenValue: 0,
      deviceVisibility: "DeskTop",
      showOn: "ALL",
      showOnSpecificUrl: [],
      selectOtherUrl: "",
      limit: "ONCE",
      limitValue: 0,
      scheduleClicked: false,
      sendEmail: false,
    },
  };

  emailLayoutHTML;

  timeInfoDays = {
    operator: "CONTAINS",
    ruleType: "DAYS",
    daysRule: [],
  };

  timeInfoDate = {
    operator: "BETWEEN",
    ruleType: "DATE",
    ruleDates: ["", ""],
  };

  timeInfoTime = {
    operator: "BETWEEN",
    ruleType: "TIME",
    ruleTimes: ["01:26", "23:59"],
  };

  timeInfo = {
    merchantId: this.tokenStorage.getMerchantId(),
    pFormId: this.pformId,
    type: "TIME_EMAIL",
    timeEmailConfiguration: "{}",
    contentAndStyle: null,
  };

  activeOffers = [];

  @ViewChild("myDiv") eleRef: ElementRef;

  request;
  s: Array<any> = [];
  allOfferMaps = [];
  type;
  offerMap = {
    description: "",
    partnershipRequests: -1,
    offerId: -1,
  };
  isFormCreated = false;

  emailLayoutJSON;

  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;
  templateName;

  timeEmailConfig = {
    conditions: {
      rules: {},
    },
    emailSubject: this._i18nDynamicTranslate.transform("Partnership Form Email", ['POS_PAGE']),
    offerText: "",
    emailLayoutId: null,
  };

  offerData = {
    formOfferDetailsList: [],
    merchantId: this.tokenStorage.getMerchantId(),
    channel: "EMAIL",
    barrierId: "",
  };

  isDisabled = false;
  editForm;
  pageYoffset;
  displayEmailLayoutHTML;
  coMarketingOffers = [];
  selectedOffer : any;
  serviceCountry = sessionStorage.getItem("serviceCountry");
  @ViewChild("selectOffer") selectOffer: MatSelect;

  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    if (this.eleRef !== undefined) {
      if (!this.eleRef.nativeElement.contains(event.target)) {
        this.showDropdown = false;
      }
    }
  }

  ngOnInit() {
    this.getScreenSize();
    this.getParams();
    this.getProducts();
    this.getComarketingOfferTemplate();
  }

  openSelectOffer() {
    this.selectOffer.open();
  }

  getProducts() {
    this.apiCall.getConstantProducts().subscribe((response) => {
      this.productCollections = JSON.parse(response["body"]).productCategories;
    });
  }

  getParams() {
    this.activatedRoute.params.subscribe((param) => {
      this.clone = param.clone;
      this.id = param.id;
      this.request = param.request;
      this.type = param.type;
      this.editForm = param.editForm;
      this.getData(param);
    });
  }

  getData(param) {
    if (param.editForm === "true") {
      this.getFormDetails(param, false);
      this.apiCall.getFormDetailEdit(param.request).subscribe((response) => {
        this.getFormDetailEdit(param);
        this.data.name = response["body"]["formName"];
        this.barrierJSON = JSON.parse(response["body"]["barrierJson"]);
        this.selectedProductCollections = this.barrierJSON.collection;
        this.selectedProductCollections.forEach((collection) => {
          this.ids.push(collection["collectionId"]);
        });
      });
    } else {
      this.getAllFormOffers();
    }
    if (this.id !== undefined && this.id != -1) {
      if (param.clone !== undefined) {
        this.getFormDetails(param, true);
      }
    }
  }

  setFormDetails(param, clone, response) {
    if (clone) {
      if (response["body"]["fields"] !== null) {
        this.data.fields = JSON.parse(response["body"]["fields"]);
      } else {
        this.data.fields = null;
      }
      this.scheduleInfo.contentAndStyle = response["body"]["scheduleInfo"];
      if (
        response["body"]["timeConfig"]["rules"]["DATE"]["operator"] ===
        "BETWEEN"
      ) {
        this.selectedRadio = "range";
      } else {
        this.selectedRadio = "specific";
        this.date =
          response["body"]["timeConfig"]["rules"]["DATE"]["ruleDates"][0];
      }
      if (response["body"]["timeConfig"]["rules"]["TIME"] !== undefined) {
        this.startTime =
          response["body"]["timeConfig"]["rules"]["TIME"]["ruleTimes"][0];
        this.endTime =
          response["body"]["timeConfig"]["rules"]["TIME"]["ruleTimes"][1];
      } else {
        this.atAnyTime = true;
      }
      if (response["body"]["timeConfig"]["rules"]["DAYS"] !== undefined) {
        this.timeInfoDays.daysRule =
          response["body"]["timeConfig"]["rules"]["DAYS"]["daysRule"];
      } else if (
        response["body"]["timeConfig"]["rules"]["DATE"]["operator"] ===
        "BETWEEN"
      ) {
        this.everyDay = true;
      }
    }
    if (param.clone === "false") {
      this.data.name = response["body"]["name"];
      this.pformId = response["body"]["formId"];
      this.data.formId = this.pformId;
    } else if (param.clone === "true") {
      this.data.name = response["body"]["name"] + "_copy";
      this.pformId = null;
      this.data.formId = this.pformId;
    }
  }

  getAllFormOffers() {
    this.apiCall
      .getAllPartnershipFormOffers(null, false)
      .subscribe((response) => {
        this.endDate = this.datePipe.transform(
          response["body"]["formEndDate"],
          "yyyy-MM-dd"
        );
        this.offers = response["body"]["formOfferDetailsList"];
        this.offerData.formOfferDetailsList = this.offers;
      });
  }

  getFormDetailEdit(param) {
    this.apiCall
      .getAllPartnershipFormOffers(param.request, true)
      .subscribe((response) => {
        this.offers = response["body"]["formOfferDetailsList"];
        this.offerData.formOfferDetailsList = this.offers;
        if (this.editForm === "true") {
          this.offers.forEach((offer) => {
            if (offer.isActive) {
              this.activeOffers.push(offer["offerId"]);
              this.addOffer(
                offer["offerId"],
                offer["description"],
                offer["partnershipRequests"]
              );
            }
          });
        }
      });
  }

  getFormDetails(param, clone) {
    if (clone) {
      this.apiCall
        .getPartnershipFormDetails(this.id, this.type)
        .subscribe((response) => {
          this.setFormDetails(param, clone, response);
        });
    } else {
      this.apiCall
        .getPartnershipFormDetails(param.request, "CUSTOM")
        .subscribe((response) => {
          this.setFormDetails(param, clone, response);
        });
    }
  }

  addOffer(id, d, request) {
    if (this.s.indexOf(id) == -1) {
      if (this.allOfferMaps.length < 3) {
        this.s.push(id);
        this.s2 = id;
        this.offerMap = {
          description: d,
          partnershipRequests: request,
          offerId: id,
        };
        this.allOfferMaps.push(this.offerMap);
      }
    } else {
      const index: number = this.s.indexOf(id);
      if (index !== -1) {
        this.s.splice(index, 1);
      }
      this.allOfferMaps.forEach((value) => {
        if (value["offerId"] == id) {
          const index: number = this.allOfferMaps.indexOf(value);
          if (index !== -1) {
            this.allOfferMaps.splice(index, 1);
          }
        }
      });
    }
    this.offerData.formOfferDetailsList = this.allOfferMaps;
  }

  check(event, id) {
    if (this.allOfferMaps.length >= 3 && this.s.indexOf(id) != -1) {
      this.displaySnackBar("You can select a max of 3 offers");
    }
  }

  displaySnackBar(text) {
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(text, ['POS_PAGE']), 2000);
  }

  selectCollection(id, name, event) {
    if (!event.checked) {
      if (id == -1) {
        this.ids = [];
        this.selectedProductCollections = [];
      } else {
        const index = this.ids.indexOf(id);
        if (index > -1) {
          this.ids.splice(index, 1);
        }
        this.selectedProductCollections.forEach((collection) => {
          if (collection["collectionId"] == id) {
            this.selectedProductCollections.splice(collection, 1);
          }
        });
      }
    } else {
      if (id == -1) {
        this.ids = [];
        this.selectedProductCollections = [];
        this.productCollections.forEach((collection) => {
          this.ids.push(collection["productCategoryId"]);
          this.selectedProductCollections.push({
            collectionId: collection["productCategoryId"],
            collectionName: collection["productCategoryName"],
          });
        });
      } else {
        if (this.ids.indexOf(id) == -1) {
          this.ids.push(id);
          this.productCollections.forEach((collection) => {
            if (collection["productCategoryId"] == id) {
              this.selectedProductCollections.push({
                collectionId: collection["productCategoryId"],
                collectionName: collection["productCategoryName"],
              });
            }
          });
        }
      }
    }
  }

  addHTML(event) {
    this.data.fields = event[2];
    this.data.formHtml =
      '<div class="bloghtml" style="display:flex;align-items:center;justify-content:center;height:100%;">' +
      event[0] +
      "</div>";
    this.data.formJson = event[1];
  }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  addBarrier() {
    this.barrierJSON.collection = this.selectedProductCollections;
    this.barrierData.barrierJson = this.barrierJSON;
    this.apiCall
      .addPartnershipBarrier(this.barrierData)
      .subscribe((response) => {
        this.offerData.barrierId = response["body"];
        this.data.barrierId = response["body"];
        this.section = 2;
      });
  }

  getFormConfig(contentAndStyle) {
    if (contentAndStyle) {
      this.apiCall
        .getPartnershipFormConfigurations(
          this.pformId,
          "CONTENT_AND_STYLE",
          null
        )
        .subscribe((response2) => {
          this.devices = response2["body"].devices;
          this.limit = response2["body"].limit;
          this.showOn = response2["body"].showOn;
          this.showTo = response2["body"].showTo;
          this.showWhen = response2["body"].showWhen;
          this.routes = response2["body"].routes;
        });
    } else {
      // this.apiCall
      //   .getPartnershipFormConfigurations(this.pformId, "TIME_EMAIL", this.s)
      //   .subscribe((response2) => {
      //     this.emailLayoutJSON = response2["body"]["emailJson"];
      //     this.displayEmailLayoutHTML =
      //       '<div class="bloghtml2">' +
      //       response2["body"]["emailHtml"] +
      //       "</div>";
      //     this.templateName = response2["body"]["emailTemplateName"];
      //     this.timeEmailConfig.emailSubject = response2["body"]["subject"];
      //     this.imageURL = response2["body"]["snapshotUrl"];
      //     this.previewData.selectedLayout = response2["body"]["emailHtml"];
      //     this.previewData.templateName =
      //       response2["body"]["emailTemplateName"];
      //     this.offerData.channel = "EMAIL";
      //     this.getOfferDescription();
      //   });
      this.getOfferDescription();
    }
  }

  getOfferDescription() {
    this.apiCall.getComOfferDescription(this.selectedOffer.id).subscribe((response3) => {
      this.endDate = this.datePipe.transform(
        new Date(response3["body"]["formEndDate"]),
        "yyyy-MM-dd"
      );
      this.timeEmailConfig.offerText = response3["body"]["allOfferDescription"];
    });
  }

  createForm(create) {
    if (create)
      this.apiCall.createPatnershipForm(this.data).subscribe(
        (response) => {
          this.data.formId = response["body"];
          this.pformId = response["body"];
          this.scheduleInfo.pFormId = this.pformId;
          this.timeInfo.pFormId = this.pformId;
          this.getCustomerData();
          this.isFormCreated = true;
          this.getFormConfig(true);
        },
        (error) => {
          this.section = 1;
          this.formNameError = this._i18nDynamicTranslate.transform(error["error"]["body"], ['POS_PAGE']);
        }
      );
  }

  openEditor(sh, sw, datas) {
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1100px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: datas,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
        this.timeEmailConfig.emailLayoutId = result.layoutId;
      }
    });
  }

  loadEmailEditorReward() {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    var datas = {
      process: "NewTemplateAndLayout",
      id: this.timeEmailConfig.emailLayoutId,
      tags: [],
      imageTagStatus: false,
      specialTagType: "",
      offerId: null,
      type: "FORM",
      rewardType: null,
      isReward: false,
      subject: this.timeEmailConfig.emailSubject,
      emailLayoutHTML: this.previewData.selectedLayout,
      emailLayoutJSON: this.emailLayoutJSON,
    };
    this.openEditor(sh, sw, datas);
  }

  openSanpshotDialog(dat) {
    const dialogRef = this.dialog.open(HtmlForEmailTemplatesDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "60%",
      height: "500px",
      data: dat,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "edit") {
        this.loadEmailEditorReward();
      }
    });
  }

  opensnapshotHtmlDialog() {
    var dat = {
      type: "FORM",
      id: null,
      channel: null,
      templateID: null,
      rewardType: null,
      actionType: null,
      layoutHTML: this.previewData.selectedLayout,
      subject: this.templateName,
      emailSubject: this.timeEmailConfig.emailSubject,
    };
    this.openSanpshotDialog(dat);
  }

  enable() {
    let error = false;
    this.timeInfoDate.ruleDates = [];
    this.timeInfoTime.ruleTimes = [];
    this.data.status = "ACTIVE";
    // if (this.timeEmailConfig.offerText.length == 0) {
    //   error = true;
    // }
    if (this.selectedRadio == "range") {
      if (this.date === "") {
        this.timeInfoDate.operator = "BETWEEN";
        this.timeInfoDate.ruleDates.push(this.startDate + " 00:00:00");
        this.timeInfoDate.ruleDates.push(this.endDate + " 00:00:00");
      } else {
        error = true;
      }
    } else {
      if (this.date !== "") {
        this.timeInfoDate.operator = "EQUALS";
        this.timeInfoDate.ruleDates.push(this.date);
        this.everyDay = true;
      } else {
        error = true;
      }
    }
    this.timeEmailConfig.conditions.rules["DATE"] = this.timeInfoDate;
    if (!this.atAnyTime) {
      if (this.startTime !== "" && this.endTime !== "") {
        this.timeInfoTime.ruleTimes.push(this.startTime);
        this.timeInfoTime.ruleTimes.push(this.endTime);
        this.timeEmailConfig.conditions.rules["TIME"] = this.timeInfoTime;
      } else {
        error = true;
      }
    }
    if (!this.everyDay && this.selectedRadio === "range") {
      if (this.timeInfoDays.daysRule.length > 0) {
        this.timeEmailConfig.conditions.rules["DAYS"] = this.timeInfoDays;
      } else {
        error = true;
      }
    }

    if (this.editForm === "true") {
      let temp = this.allOfferMaps;
      this.allOfferMaps = [];
      temp.forEach((offer) => {
        let index: number = this.activeOffers.indexOf(offer["offerId"]);
        if (index == -1) {
          this.allOfferMaps.push(offer);
        }
      });
    }
    this.timeInfo.timeEmailConfiguration = JSON.stringify(this.timeEmailConfig);
    if (!error) {
      this.submitFormConfig(false, false);
    } else {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("All fields are required", ['POS_PAGE']), 2000);
    }
  }

  submitFormConfig(form, saveAndExit) {
    if (saveAndExit) {
      this.apiCall.submitConfig(this.timeInfo).subscribe(
        (response) => {
          this.displaySnackBar("Form saved");
          this.redirect("/app/partnership/form-list");
        },
        (error) => {
          this.displaySnackBar("Failed to save form");
        }
      );
    } else {
      if (!form) {
        let offerId = [];
        offerId.push(this.selectedOffer.id)
        this.apiCall.submitConfig(this.timeInfo).subscribe(
          (response) => {
            this.apiCall
              .processPartnershipRequest(
                this.request,
                "ACTIVE",
                this.pformId,
                offerId,
                this.allOfferMaps,
                this.barrierId,
                null,
                false
              )
              .subscribe((response) => {});
            this.displaySnackBar("Form created");
            this.redirect("/app/partnership/my-partners");
          },
          (error) => {
            this.displaySnackBar("Failed to save form");
          }
        );
      } else {
        this.apiCall
          .submitConfig(this.scheduleInfo)
          .subscribe((response) => {});
      }
    }
  }

  redirect(link) {
    this.router.navigate([link]);
  }

  checkFormName(section, create) {
    this.apiCall.checkFormName(this.data.name).subscribe((response) => {
      this.formNameError = "";
      if (response["body"]["isDuplicate"]) {
        this.formNameError = this._i18nDynamicTranslate.transform("Duplicate form name", ['POS_PAGE']);
      } else {
        this.continueNext(section, create);
      }
    });
  }

  continue(section, create) {
    if (section == 2) {
      if (!this.isFormCreated) {
        this.checkFormName(section, create);
      } else {
        this.continueNext(section, create);
      }
    } else {
      this.continueNext(section, create);
    }
  }

  continueNext(section, create) {
    console.log("section",this.section)
    console.log("SELced",this.selectedOffer)
    if (
      // this.data.name.length > 0 &&
      // ((this.selectedProductCollections.length > 0 &&
      //   this.barrierJSON.type === "QUANTITY") ||
      //   this.barrierJSON.type === "AMOUNT") &&
      // this.allOfferMaps.length > 0 &&
      // this.barrierJSON.amountOrQuntity.toString() !== "" &&
      // this.barrierJSON.amountOrQuntity > 0
      this.selectedOffer
    ) {
      if (this.section == 1) {
        // if (
        //   (this.selectedProductCollections.length > 0 &&
        //     this.barrierJSON.type === "QUANTITY") ||
        //   this.barrierJSON.type === "AMOUNT"
        // ) {
        //   this.addBarrier();
        // } else {
        //   this.displaySnackBar("Please select categories");
        // }
        if(this.selectedOffer)
          this.section = 2;
      }
      else if (this.section == 2) {
        if (this.data.formHtml !== null) {
          if (create) {
            this.createForm(create);
          } else {
            this.getCustomerData();
          }
        } else {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please save the form", ['POS_PAGE']), 2000);
        }
      }
      if (this.section == 3) {
        if (
          ((this.scheduleInfo.contentAndStyle.showOnSpecificUrl.length > 0 &&
            this.scheduleInfo.contentAndStyle.showOn === "OTHERS") ||
            this.scheduleInfo.contentAndStyle.showOn === "ALL") &&
          ((this.scheduleInfo.contentAndStyle.segments.length > 0 &&
            this.scheduleInfo.contentAndStyle.showTo === "SEGMENTS") ||
            this.scheduleInfo.contentAndStyle.showTo === "NEW" ||
            this.scheduleInfo.contentAndStyle.showTo === "ALL") &&
          ((this.scheduleInfo.contentAndStyle.limit === "REPEAT" &&
            this.scheduleInfo.contentAndStyle.limitValue > 0) ||
            this.scheduleInfo.contentAndStyle.limit === "ONCE") &&
          (((this.scheduleInfo.contentAndStyle.showWhen === "AFTER_X_SECONDS" ||
            this.scheduleInfo.contentAndStyle.showWhen === "AFTER_X_PERCENT") &&
            this.scheduleInfo.contentAndStyle.showWhenValue > 0) ||
            this.scheduleInfo.contentAndStyle.showWhen === "IMMEDIATE")
        ) {
          this.submitFormConfig(true, false);
          this.getFormConfig(false);
        } else {
          this.displaySnackBar("All fields are required");
        }
      }
      if (
        (this.section == 2 && this.data.formHtml !== null) ||
        (this.section == 3 &&
          ((this.scheduleInfo.contentAndStyle.showOnSpecificUrl.length > 0 &&
            this.scheduleInfo.contentAndStyle.showOn === "OTHERS") ||
            this.scheduleInfo.contentAndStyle.showOn === "ALL"))
      ) {
        this.section = section;
      }
    } else if (this.data.name === "") {
      this.formNameError = this._i18nDynamicTranslate.transform("Please enter a form name", ['POS_PAGE']);
    } else{
      this.displaySnackBar("Please select Offer");
    }
    // else if (this.allOfferMaps.length == 0) {
    //   this.displaySnackBar("Please select an offer");
    // } else if (
    //   this.barrierJSON.amountOrQuntity.toString() === "" ||
    //   this.barrierJSON.amountOrQuntity <= 0
    // ) {
    //   if (this.barrierJSON.type === "AMOUNT")
    //     this.displaySnackBar(
    //       "Minimum purchase amount needs to be greater than zero"
    //     );
    //   else
    //     this.displaySnackBar(
    //       "Minimum quantity of items cannot be less than one"
    //     );
    // } else if (
    //   this.selectedProductCollections.length > 0 &&
    //   this.barrierJSON.type === "QUANTITY"
    // ) {
    //   this.displaySnackBar("Please select categories");
    // }
  }

  previous(section) {
    this.section = section;
  }

  goToSection(section) {
    if (this.section > section) {
      this.section = section;
    }
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Discard Form", ['POS_PAGE']),
        message:
          this._i18nDynamicTranslate.transform("Discard operation will delete this form and you will lose all the configurations", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deletePartnershipForm(this.pformId).subscribe(
          (response) => {
            this.redirect("/app/partnership/form-list");
          },
          (error) => {
            this.displaySnackBar("Failed to delete form");
          }
        );
      }
    });
  }

  delete() {
    if (this.pformId != -1 && this.pformId !== null) {
      this.openConfirmationDialog();
    } else {
      this.redirect("/app/partnership/form-list");
    }
  }

  saveandexit(section) {
    if (section == 2) {
      if (this.data.formHtml === null) {
        this.displaySnackBar("Please save the form");
      } else {
        this.createForm(true);
        this.redirect("/app/partnership/form-list");
      }
    }
    if (section == 3) {
      if (
        this.scheduleInfo.contentAndStyle.showOnSpecificUrl.length == 0 &&
        this.scheduleInfo.contentAndStyle.showOn === "OTHERS"
      ) {
        this.displaySnackBar("Please select specific URLs");
      } else {
        this.redirect("/app/partnership/form-list");
      }
    }
    if (section == 4) {
      let error = false;
      this.timeInfoDate.ruleDates = [];
      this.timeInfoTime.ruleTimes = [];
      this.data.status = "DRAFT";
      if (this.timeEmailConfig.offerText.length == 0) {
        error = true;
      }
      if (this.selectedRadio == "range") {
        if (this.date === "") {
          this.timeInfoDate.operator = "BETWEEN";
          this.timeInfoDate.ruleDates.push(this.startDate + " 00:00:00");
          this.timeInfoDate.ruleDates.push(this.endDate + " 00:00:00");
        } else {
          error = true;
        }
      } else {
        if (this.date !== "") {
          this.timeInfoDate.operator = "EQUALS";
          this.timeInfoDate.ruleDates.push(this.date);
          this.everyDay = true;
        } else {
          error = true;
        }
      }
      this.timeEmailConfig.conditions.rules["DATE"] = this.timeInfoDate;
      if (!this.atAnyTime) {
        if (this.startTime !== "" && this.endTime !== "") {
          this.timeInfoTime.ruleTimes.push(this.startTime);
          this.timeInfoTime.ruleTimes.push(this.endTime);
          this.timeEmailConfig.conditions.rules["TIME"] = this.timeInfoTime;
        } else {
          error = true;
        }
      }
      if (!this.everyDay && this.selectedRadio === "range") {
        if (this.timeInfoDays.daysRule.length > 0) {
          this.timeEmailConfig.conditions.rules["DAYS"] = this.timeInfoDays;
        } else {
          error = true;
        }
      }
      this.timeInfo.timeEmailConfiguration = JSON.stringify(
        this.timeEmailConfig
      );
      if (!error) {
        this.submitFormConfig(true, true);
      } else {
        this.displaySnackBar("All fields are required");
      }
    }
  }

  changeSegmentSelection() {
    if (this.scheduleInfo.contentAndStyle.segments.length === 1) {
      if (this.scheduleInfo.contentAndStyle.segments[0] === "ALL_CUSTOMERS") {
        this.disableCustomerSegmentSelection = true;
      } else {
        this.disableAllCustomerSelection = true;
      }
    } else if (this.scheduleInfo.contentAndStyle.segments.length === 0) {
      this.selectedSegments = [];
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
    }
  }

  openTemplatePreviewDialog() {
    let finalScreenHeight = window.innerHeight;
    let finalScreenWidth = window.innerWidth;
    let sh = finalScreenHeight * (90 / 100);
    let sw = finalScreenHeight * (70 / 100);
    const dialogRef = this.dialog.open(PreviewTemplateComponent, {
      panelClass: "previewDialog",
      height: sh + "px",
      width: finalScreenWidth < 1500 ? "1024px" : sw + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      disableClose: true,
      autoFocus: false,
      data: { obj: this.previewData, type: "EMAIL", height: finalScreenHeight },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.timeEmailConfig.emailLayoutId = result;
    });
  }

  getCustomerData() {
    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      let segment = JSON.parse(response["body"]);
      for (let i = 0; i < segment.length; i++) {
        if (segment[i]["isActive"] === true) {
          this.segments.push({
            id: segment[i]["id"],
            name: segment[i]["segmentName"],
            status: false,
          });
        }
      }
    });
  }

  selectDay(day) {
    if (this.timeInfoDays.daysRule.indexOf(day) == -1) {
      this.timeInfoDays.daysRule.push(day);
    } else {
      const index = this.timeInfoDays.daysRule.indexOf(day);
      if (index > -1) {
        this.timeInfoDays.daysRule.splice(index, 1);
      }
    }
  }
  getComarketingOfferTemplate(){
    this.apiCall.getCoMarketingOffers().subscribe((response)=>{
      console.log("RESPOMNSE",response)
      this.coMarketingOffers = response['body']['comarketingOffers'];
    })
    // this.coMarketingOffers = [
    //   {
    //     "comarkettingOffers": [
    //       {
    //         "id": 21,
    //         "offerName": "OFFER-CREATED-GROUPED",
    //         "isGrouped": true,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 21,
    //             "offerId": 11,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           },
    //           {
    //             "description": "xyz1",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere1",
    //             "status": "DISABLED",
    //             "revenue": 12344,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": null,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
  }
  changeOffer(){

  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
