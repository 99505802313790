<div class="sms-creator-container">
  <div class="header">
    <span class="header-text">{{'SHARED_PAGE.NEW_SMS_TEMPLATE' | i18nTranslate}}</span>
    <div class="close-icon-div" (click)="closeEditor()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.22222 8L0 1.77778L1.77778 0L8 6.22222L14.2222 0L16 1.77778L9.77778 8L16 14.2222L14.2222 16L8 9.77778L1.77778 16L0 14.2222L6.22222 8Z" fill="#202224"/>
        </svg>
        
    </div>
  </div>
  <div class="top-container">
    <div class="text-box">
      <div style="margin-bottom: 10px">
        <span class="input-header-text">{{'SHARED_PAGE.TEMPLATE_NAME' | i18nTranslate}}</span>
        <div
          class="formDiv"
          [ngClass]="{ error: (nameError && submit) || (templateName.length === 0 && submit)}"
        >
          <input
            (keypress)="templateNameKeyPress($event)"
            (paste)="templateNamePaste($event)"
            class="formInput"
            type="text"
            [(ngModel)]="templateName"
          />
          <span class="template-name-text-limiter"
            >{{ templateName.length
            }}<span style="color: #cccccc">/50</span></span
          >
        </div>
      </div>
      <span class="input-header-text">{{'SHARED_PAGE.SMS_DETAIL' | i18nTranslate}}</span>
      <div class="text-container">
        <textarea
          class="textarea"
          name="smsText"
          id="smsText"
          cols="20"
          rows="8"
          [(ngModel)]="smsText"
          [ngClass]="{ error: smsText.length === 0 && submit }"
          (focusout)="updateMeasurementPointComment($event)"
          (keyup)="keypresshandler($event)"
          (paste)="onPaste($event)"
        ></textarea>
        <span class="sms-count-text"
          >{{'SHARED_PAGE.COUNT' | i18nTranslate}} : <span>{{ smsCount }}</span></span
        >
        <span class="char-limit-text"
          ><span style="color: #00b759">{{ charCount }}</span
          >/ {{ usesUnicode ? '70' : '160' }}
          
          </span
        >
        <span
          class="smiley-container"
          (click)="toggled = !toggled"
          [(emojiPickerIf)]="toggled"
          [emojiPickerDirection]="'left'"
          (emojiPickerSelect)="handleSelection($event)"
          >😄</span
        >
      </div>
      <div class="sms-options-div">
        <div>
          <mat-checkbox
            [(ngModel)]="optOutText"
            color="primary"
            (change)="optoutValueChange($event)"
            >{{'SHARED_PAGE.ADD_OPT_OUT' | i18nTranslate}}</mat-checkbox
          >
        </div>
        <div style="display: flex; align-items: baseline; flex-direction: column; gap: 5px; font-size: 13px;">
          <span>{{'SHARED_PAGE.ADD_MERGE_TAGS' | i18nTranslate}}</span>

          <div style="margin-left: 12px">
            <div
              class="multi-select-panel"
              id="multi-select-panel"
              (click)="
                toggleSelect(
                  $event,
                  'multi-select-dropdown',
                  'segment-select',
                  'multi-select-panel',
                  'keyboard-down-icon-container'
                )
              "
            >
              <span>{{'SHARED_PAGE.SELECT_MERGE_TAG' | i18nTranslate}}</span>
              <div
                class="keyboard-down-icon-container"
                id="keyboard-down-icon-container"
              >
              <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.38672 8.17188C8.17578 8.38281 7.78906 8.38281 7.57812 8.17188L0.828125 1.42188C0.617188 1.21094 0.617188 0.824219 0.828125 0.613281C1.03906 0.402344 1.42578 0.402344 1.63672 0.613281L8 6.97656L14.3281 0.613281C14.5391 0.402344 14.9258 0.402344 15.1367 0.613281C15.3477 0.824219 15.3477 1.21094 15.1367 1.42188L8.38672 8.17188Z" fill="#202224"/>
                </svg>
                
              </div>
            </div>
            <div style="position: relative">
              <div class="multi-select-dropdown" id="multi-select-dropdown">
                <div class="search">
                  <mat-icon class="group-icon">search</mat-icon>
                  <input
                    [(ngModel)]="mergetagFilter"
                    placeholder="Search merge tag"
                    type="text"
                    style="border: none; outline: none"
                  />
                </div>
                <div class="border-line"></div>
                <div id="segment-select" class="segment-select">
                  <div>
                    <div
                      class="tag-element"
                      (click)="
                        addMergeTag(
                          tags['value'],
                          'multi-select-dropdown',
                          'segment-select',
                          'multi-select-panel',
                          'keyboard-down-icon-container'
                        )
                      "
                      *ngFor="
                        let tags of mergeTags | labelPipe: mergetagFilter:'name'
                      "
                    >
                      <span>{{ tags["name"] | i18nDynamicTranslate : 'STATIC_PAGE'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="preview-box">
      <div class="phone-image">
        <div class="text-container-div">
          <div class="preview-text-content">
            <span style="white-space: pre-line; word-break: break-word">{{
              smsText
            }}</span>
            <span *ngIf="optOutText">{{'SHARED_PAGE.REPLY_OPT_OUT' | i18nTranslate}}</span>
            <div class="preview-text-content-after"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div style="display: flex; font-size: 13px">
      <span style="color: var(--main-font-black)">{{'SHARED_PAGE.CREDITS_NEEDED' | i18nTranslate}} ≥ </span
      ><span><span style="color: #00b759">{{smsCount}}</span> {{'SHARED_PAGE.PER_CUSTOMER' | i18nTranslate}}</span>
      <span class="qb-i-div"
      popoverclass="custom"
      placement="auto"
      [ngbPopover]="smsCountDetails"
      triggers="mouseenter:mouseleave"
      >i
      </span>
    </div>
    <div style="display: flex">
      <span class="primary-button" (click)="saveSms(false)">{{'SHARED_PAGE.SAVE_EXIT' | i18nTranslate}}</span>
    </div>
  </div>

</div>

<ng-template #smsCountDetails>
  <div class="qb-ng-template-text">
    Please note that SMS messages containing non-GSM characters (like emojis or special symbols) are limited to 70 characters per segment.
  </div>
</ng-template>
