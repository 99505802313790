import { Injectable } from "@angular/core";
import { PopUpDialogComponent } from "../../shared/components/dialog-box/pop-up-dialog/pop-up-dialog.component";
import { MatDialog } from "@angular/material/dialog"; 

@Injectable({
  providedIn: "root",
})
export class PopUpDialogService {
  constructor(private dialog: MatDialog) {}

  mobileMedia = window.matchMedia("(max-width: 896px)");

  openPopup(status, message) {
    const dialog = this.dialog.open(PopUpDialogComponent, {
      data: {
        type: status,
        message: message,
      },
      width: this.mobileMedia.matches ? "80%" : "30vw",
      position: { bottom: "5vh" },
      panelClass: "no-padding-dialog-popup",
      hasBackdrop: false,
    });
  }
}
