import { Injectable } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class FontCollection{
  // Change whatever your like
  public link = "https://fonts.googleapis.com/css?family=Fira+Code|Lato|Merriweather|Montserrat|Muli|Nunito|Open+Sans|PT+Sans|PT+Serif|Roboto|Roboto+Condensed|Slabo+27px|Source+Sans+Pro|Titillium+Web|ZCOOL+XiaoWei&display=swap"

  availableFonts = [
    'Titillium Web',
    'Nunito',
    'Slabo',
    'Fira Code',
    'Muli',
    'PT Serif',
    'PT Sans',
    'ZCOOL XiaoWei',
    'Merriweather',
    'Source Sans Pro',
    'Roboto Condensed',
    'Montserrat',
    'Lato',
    'Roboto',
    'Open Sans'
  ]

  inbuiltFonts = [
    'Monospace',
    'serif',
    'sans-serif',
    'Courier New',
    'Franklin Gothic Medium',
    'Gill Sans',
    'Lucida Sans',
    'Segoe UI',
    'Times new Roman',
    'Tribuchet MS',
    '-apple-system',
    'Arial',
    'Cambria',
    'Georgia',
    'Impact',
    'Vardana',
    'Geneva',
    'Cochin',
    'Tahoma'
  ]

  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.sanitizer = sanitizer;
  }

  getFontCSS() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
  }
}