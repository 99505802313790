import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-edit-shop-details',
  templateUrl: './edit-shop-details.component.html',
  styleUrls: ['./edit-shop-details.component.css']
})
export class EditShopDetailsComponent implements OnInit {

  constructor(
    private apiCall: ApiCallService, 
    private tokenStorage: TokenStorageService, 
    private snackBar: SnackbarCollection, 
    private mapsAPILoader: MapsAPILoader,     
    private ngZone: NgZone,
    private router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
    ) { }

  shopName = "";
  email = "";
  phoneNumber = "";
  longitude = "";
  latitude = "";
  category = "";
  allTags = [];
  selectedTags = [];
  tags = "";
  address = "";
  lat ;
  long;
  googleMapType = 'roadmap';
  data;
  mobilenumberPattern = "[- +()0-9]+";
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$"
  categories = {
    "MG0000000004": {
        "categoryId": "MG0000000004",
        "categoryName": "Clothing retail"
    },
    "MG0000000005": {
        "categoryId": "MG0000000005",
        "categoryName": "Restaurant"
    },
    "MG0000000006": {
        "categoryId": "MG0000000006",
        "categoryName": "Electronics"
    },
    "MG0000000007": {
        "categoryId": "MG0000000007",
        "categoryName": "Jewelry"
    },
    "MG0000000008": {
        "categoryId": "MG0000000008",
        "categoryName": "Kitchen/Cooking"
    },
    "MG0000000009": {
        "categoryId": "MG0000000009",
        "categoryName": "Pet Supplies"
    },
     "MG0000000010": {
        "categoryId": "MG0000000010",
        "categoryName": "Appliance"
    },
    "MG0000000011": {
        "categoryId": "MG0000000011",
        "categoryName": "Furniture"
    },
    "MG0000000012": {
        "categoryId": "MG0000000012",
        "categoryName": "Classes"
    },
     "MG0000000013": {
        "categoryId": "MG0000000013",
        "categoryName": "Health/Spas/Beauty/Personal Care"
    },
    "MG0000000014": {
        "categoryId": "MG0000000014",
        "categoryName": "Retail and Consumer Merchandise"
    },
    "MG0000000015": {
        "categoryId": "MG0000000015",
        "categoryName": "Travel/Leisure"
    }
}
geoCoder;
zoom: number;
submit = false;
placeId = "";
location_url= "";
@ViewChild('search')
public searchElementRef: ElementRef;

  ngOnInit(): void {
    this.getData();
  }

  removeTag(tag) {
    const index: number = this.selectedTags.indexOf(tag);
    if (index !== -1) {
        this.selectedTags.splice(index, 1);
    }     
  }

  addTag(tag) {
    this.selectedTags.push(tag);
  }

  setCurrentLocation() {
    if(this.data['latitude'] && this.data['longitude']){
      this.lat=parseFloat(this.data['latitude']);
      this.long=parseFloat(this.data['longitude']);
    }
    else if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          this.searchElementRef.nativeElement = this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    
    });
  }

  selectCategory(event) {
    this.selectedTags = [];
    console.log(event)
    Object.keys(this.categories).forEach((key,index) => {
      if(this.categories[key].categoryName === event.value) {
        this.apiCall.getTags(key).subscribe(response => {
          this.allTags = response['body'];
        })
      } 
  });
  }

  cancel() {
    this.toStorePreview();
  }

  save() {
    this.submit = true;
    console.log(this.lat, this.latitude)
    if(this.email.match(this.emailPattern.trim())) {
    let data = {
      "storeMetaInfo" : {
        'storeId': this.data['storeId'],
        'storeName': this.shopName,
        'latitude': this.lat,
        'longitude': this.long,
        'category': this.category,
        'tags': this.selectedTags.join(),
        'addressLocationJson': JSON.stringify(this.getAddressJSON()),
        // 'adminUserName': this.tokenStorage.getUsername()
        'googlePlaceId' : this.placeId,
        'qid': this.tokenStorage.getStoreId(),
        'chainId' : this.data['chainId'],
        'platform' : this.data ['platform']
      },
      "email" : this.email,
      "phoneNumber" : this.phoneNumber,
    };
    this.apiCall.updateMerchantStoreDetails(data).subscribe(response => {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Shop details updated!", ['POS_PAGE']), 2000);
      console.log(data);
      this.toStorePreview()
    })
  }
  // else if(this.phoneNumber.length ==0) {
  //   this.snackBar.openSnackBar('Please enter the phone number', 2000);
  // }
   else if(!this.email.match(this.emailPattern.trim())) {
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please enter a valid email ID", ['POS_PAGE']), 2000);
  } 
  // else if(!this.phoneNumber.match(this.mobilenumberPattern)) {
  //   this.snackBar.openSnackBar('Please enter a valid phone number', 2000);
  // }
  }

  location(x){
    this.lat=x.coords.lat;
    this.long=x.coords.lng;
  }

  addMarker(event) {
    this.lat = event.coords.lat;
    this.long = event.coords.lng
  }

  markerDragEnd(event) {
    console.log(event);
    this.lat = event.coords.lat;
    this.long = event.coords.lng;
    this.getAddress(this.lat, this.long);
  }
  toStorePreview(){
    this.router.navigate(['/app/user-profile/my-store'], {
      queryParams: { id: this.tokenStorage.getStoreId() },
    })
  }
  getData(){
    this.apiCall.getStoreDetails().subscribe((response)=>{
      console.log((response['body']))
      this.data = (response['body']['storeMetaInfo'])
      // if(this.tokenStorage.getShopName())
      //   this.shopName = this.tokenStorage.getShopName();
      // else
        this.shopName = this.data['storeName'];
        this.email = response['body']['email'];
      this.phoneNumber = response['body']['phone'];
      this.setCurrentLocation();
      this.address = this.getAddressData();

      // if(this.data['latitude'] && this.data['longitude']){
      //   this.lat=parseFloat(this.data['latitude']);
      //   this.long=parseFloat(this.data['longitude']);
      // }
      // else{
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
          this.geoCoder = new google.maps.Geocoder;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              this.address = place.formatted_address
              console.log(place.formatted_address)
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
                this.lat = place.geometry.location.lat();
                this.long = place.geometry.location.lng();
                this.placeId = place.place_id;
                this.location_url = place.url;
                this.zoom = 15.5;
                console.log("PLACID",place.place_id);
                console.log("LOCATION",place.url)
            });
          });
        });
      //}
      this.zoom = 15.5;
      this.category = this.data['category']   
      this.prefilleCategory(this.category)
    })
  }
  prefilleCategory(category){
    Object.keys(this.categories).forEach((key,index) => {
      if(this.categories[key].categoryName === category) {
        this.apiCall.getTags(key).subscribe(response => {
          this.allTags = response['body'];
        })
      } 
  });
    this.selectedTags = this.data['tags'].split(',');
  }
  getAddressData(){
    if(this.data){
      let address= JSON.parse(this.data['addressLocationJson'])
      return address['address'];
    }
  }
  getAddressJSON(){
    let addressJSON :any = {};
    addressJSON = {
      locationUrl : this.location_url,
      address : this.address,
      latitude : this.lat,
      longitude : this.long
    };
    return addressJSON;
  }
}
