<div class="qb-parent-container">
  <div class="qb-layout-overview-container" id="layout-overview-container">
    <div class="qb-layout-oveview" id="layout-oveview">
      <div class="qb-overview" id="overview">
        <div *ngIf="formLoading" style="display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 250px;">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        <div
          class="qb-close-button"
          id="close-button"
          *ngIf="contentData.closeButton"
        >
          <span class="qb-close-button-icon">X</span>
        </div>
        <div class="qb-dummy-bg-container" id="dummy-bg-container">
          <img class="qb-bg-image" [src]="contentData.imageURL" alt="" />
        </div>
        <div
          class="qb-dummy-offset-container"
          id="dummy-offset-container"
        ></div>
        <div class="qb-dummy-image-container" id="dummy-image-container">
          <img [style.display]="formLoading ? 'none' : 'block'"
            *ngIf="contentData.imageURL.length > 0"
            class="qb-image"
            [src]="contentData.imageURL"
            alt=""
          />
        </div>
        <div class="qb-dummy-input-box" id="dummy-input-box" [style.display]="formLoading ? 'none' : 'block'">
          <span class="qb-overview-header" id="overview-header"
            >{{'ACQUIRE_PAGE.WELCOME_DISCOUNT' | i18nTranslate}}</span
          >
          <div>
            <span class="qb-overview-p" id="overview-p"
              >{{'ACQUIRE_PAGE.SIGN_UP_OFFER_FIRST' | i18nTranslate}}</span
            >
          </div>
          <div class="qb-dummy-input-container" id="dummy-input-container">
            <div class="qb-input-container">
              <span class="qb-dummy-input" id="dummy-input"
                >{{'ACQUIRE_PAGE.ENTER_YOUR_EMAIL' | i18nTranslate}}</span
              >
              <input
                class="qb-form-name qb-input"
                type="text"
                [placeholder]="contentData.inputPlaceholder"
                id="form-input"
              />
            </div>
            <span
              id="greyfox-text-error-message"
              class="qb-text-error-message"
            ></span>
            <span class="qb-dummy-button" id="dummy-button">{{'ACQUIRE_PAGE.SUBMIT' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-popup-configure">
    <div class="qb-layout-selector-container" *ngIf="screen === 'LAYOUT'">
      <div class="qb-form-name-input">
        <span class="qb-form-name-string">{{'ACQUIRE_PAGE.FORM_NAME_50_LIMIT' | i18nTranslate}}</span>
        <div
          [formGroup]="form"
          class="qb-input-container"
          [ngClass]="{
            'qb-error':
              (form.invalid && (form.dirty || form.touched)) ||
              duplicateFormNameError
          }"
        >
          <input
            (input)="sendTheNewValue($event)"
            placeholder="Enter Form Name"
            class="qb-form-name"
            type="text"
            formControlName="name"
            maxlength="50"
          />
          <span class="qb-char-text qb-char-input-length"
            >{{ 50 - form.controls.name.value.length }}
            <span class="qb-char-text">/50</span>
          </span>
        </div>
        <div class="qb-invalid-form-error">
          <div
            *ngIf="(form.dirty || form.touched) && form.controls.name.errors"
          >
            <span *ngIf="form.controls.name.errors.required"
              >{{'ACQUIRE_PAGE.ENTER_FORM_NAME' | i18nTranslate}}</span
            >
            <span *ngIf="form.controls.name.errors.maxlength"
              >{{'ACQUIRE_PAGE.MAXIMUN_50_ALLOWED' | i18nTranslate}}</span
            >
          </div>
          <div *ngIf="duplicateFormNameError">{{'ACQUIRE_PAGE.DUPLICATE_FORM_NAME' | i18nTranslate}}</div>
        </div>
      </div>
      <div class="qb-layouts-container">
        <span>{{'ACQUIRE_PAGE.LAYOUT' | i18nTranslate}}</span>
        <div class="qb-layout-tile-container">
          <div class="qb-layout-selector-parent">
            <div
              class="qb-layout-selector"
              (click)="selectLayout(1)"
              [ngClass]="{ active: contentData.selectedLayout === 1 }"
            >
              <div class="qb-image-div">
                <img
                  class="qb-layout-images"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/layout-dummy-image.svg"
                  alt=""
                />
              </div>
              <div class="qb-dummy-line-div">
                <div class="qb-dummy-line-1"></div>
                <div class="qb-dummy-line-2-div">
                  <div class="qb-dummy-line-2"></div>
                </div>
                <div class="qb-dummy-line-3"></div>
                <div class="qb-dummy-line-4"></div>
              </div>
            </div>
            <span class="qb-layout-type-label">{{'ACQUIRE_PAGE.IMAGE_ON_LEFT' | i18nTranslate}}</span>
          </div>
          <div class="qb-layout-selector-parent">
            <div
              class="qb-layout-selector"
              (click)="selectLayout(2)"
              [ngClass]="{ active: contentData.selectedLayout === 2 }"
            >
              <div class="qb-dummy-line-div">
                <div class="qb-dummy-line-1"></div>
                <div class="qb-dummy-line-2-div">
                  <div class="qb-dummy-line-2"></div>
                </div>
                <div class="qb-dummy-line-3"></div>
                <div class="qb-dummy-line-4"></div>
              </div>
              <div class="qb-image-div">
                <img
                  class="qb-layout-images"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/layout-dummy-image.svg"
                  alt=""
                />
              </div>
            </div>
            <span class="qb-layout-type-label">{{'ACQUIRE_PAGE.IMAGE_ON_RIGHT' | i18nTranslate}}</span>
          </div>
          <div class="qb-layout-selector-parent">
            <div
              class="qb-layout-selector qb-layout-selector-center"
              (click)="selectLayout(3)"
              [ngClass]="{ active: contentData.selectedLayout === 3 }"
            >
              <div class="qb-layout-selector-background"></div>
              <div class="qb-dummy-line-div">
                <div class="qb-dummy-line-1"></div>
                <div class="qb-dummy-line-2-div">
                  <div class="qb-dummy-line-2"></div>
                </div>
                <div class="qb-dummy-line-3"></div>
                <div class="qb-dummy-line-4"></div>
              </div>
            </div>
            <span class="qb-layout-type-label">{{'ACQUIRE_PAGE.BACKGROUND_IMAGE' | i18nTranslate}}</span>
          </div>
          <div class="qb-layout-selector-parent">
            <div
              class="qb-layout-selector qb-layout-selector-center"
              (click)="selectLayout(4)"
              [ngClass]="{ active: contentData.selectedLayout === 4 }"
            >
              <div class="qb-dummy-line-div">
                <div class="qb-dummy-line-1"></div>
                <div class="qb-dummy-line-2-div">
                  <div class="qb-dummy-line-2"></div>
                </div>
                <div class="qb-dummy-line-3"></div>
                <div class="qb-dummy-line-4"></div>
              </div>
            </div>
            <span class="qb-layout-type-label">{{'ACQUIRE_PAGE.SIMPLE' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
      <div class="qb-edit-button-container">
        <div>
          <span
            class="material-icons-outlined icons-primary qb-device-logo"
            [ngClass]="{ active: contentData.orientation === 'landscape' }"
            (click)="selectOrientation('landscape')"
          >
            laptop
          </span>
          <span
            class="material-icons-outlined icons-primary qb-device-logo"
            [ngClass]="{ active: contentData.orientation === 'potrait' }"
            (click)="selectOrientation('potrait')"
          >
            phone_iphone
          </span>
        </div>
        <div>
          <span class="primary-button qb-button-span" (click)="editContent()"
            >{{'ACQUIRE_PAGE.EDIT_CONTENT' | i18nTranslate}}</span
          >
        </div>
      </div>
    </div>
    <div *ngIf="screen === 'CONTENT'" class="qb-content-edit">
      <div class="qb-content-edit-header">
        <div class="qb-image-div">
          <span
            class="qb-back-button"
            (click)="
              screen = 'LAYOUT';
              scheduleData.scheduleClicked = false;
              contentData.contentClicked = false
            "
          >
            <mat-icon class="qb-chevron-left-icon"> chevron_left</mat-icon>{{'ACQUIRE_PAGE.BACK' | i18nTranslate}}
          </span>
          <span class="qb-form-name-content-edit">{{
            form.controls.name.value
          }}</span>
        </div>
        <div class="qb-image-div">
          <span
            class="material-icons-outlined icons-primary qb-device-logo"
            [ngClass]="{ active: contentData.orientation === 'landscape' }"
            (click)="selectOrientation('landscape')"
          >
            laptop
          </span>
          <span
            class="material-icons-outlined icons-primary qb-device-logo"
            [ngClass]="{ active: contentData.orientation === 'potrait' }"
            (click)="selectOrientation('potrait')"
          >
            phone_iphone
          </span>
        </div>
      </div>
      <div class="qb-content-class">
        <div class="qb-content-edit-switch">
          <span
            class="qb-content-edit-switch-button qb-content-edit-switch-button-span-left"
            (click)="
              contentEditSwitch('CONTENT'); scheduleData.scheduleClicked = false
            "
            [ngClass]="{ active: editType === 'CONTENT' }"
            >1</span
          >
          <span class="qb-horizontal-line"></span>
          <span
            class="qb-content-edit-switch-button qb-content-edit-switch-button-span-right"
            [ngClass]="{ active: editType === 'SCHEDULE' }"
            >2</span
          >
        </div>
        <div class="qb-content-edit-switch qb-content-edit-switch-div">
          <span class="qb-content">{{'ACQUIRE_PAGE.CONTENT' | i18nTranslate}}</span>
          <span class="qb-content">{{'ACQUIRE_PAGE.SCHEDULE' | i18nTranslate}}</span>
        </div>
        <div *ngIf="editType === 'CONTENT'" class="qb-component-container">
          <app-form-content-edit
            [scheduleConstants]="scheduleConstants"
            [contentData]="contentData"
            [changeDetect]="changeDetect"
            [msgError]="msgError"
            [formId]="formId"
            (contentDataChange)="contentDataChange($event)"
          >
          </app-form-content-edit>
        </div>
        <div *ngIf="editType === 'SCHEDULE'" class="qb-component-container">
          <app-form-schedule
            [scheduleConstants]="scheduleConstants"
            [scheduleData]="scheduleData"
            [contentData]="contentData"
            (sheduleDataChange)="sheduleDataChange($event)"
          >
          </app-form-schedule>
        </div>
      </div>
      <div class="qb-content-edit-footer">
        <div class="qb-button-layout qb-discard-button" (click)="discard()">
          {{'ACQUIRE_PAGE.DISCARD' | i18nTranslate}}
        </div>
        <div
          class="qb-button-layout qb-save-and-exit-button"
          (click)="saveandExit()"
        >
          {{'ACQUIRE_PAGE.SAVE_AND_EXIT' | i18nTranslate}}
        </div>
        <div
          class="qb-button-layout primary-button"
          (click)="
            contentEditSwitch('SCHEDULE'); contentData.contentClicked = true
          "
          *ngIf="editType === 'CONTENT'"
        >
          {{'ACQUIRE_PAGE.CONTINUE' | i18nTranslate}}
        </div>
        <div
          class="qb-button-layout primary-button"
          (click)="submit()"
          *ngIf="editType === 'SCHEDULE'"
        >
          {{'ACQUIRE_PAGE.ENABLE' | i18nTranslate}}
        </div>
      </div>
    </div>
  </div>
</div>
