import { Component, OnInit, EventEmitter, Output, Input, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent, HtmlDataDialogComponent, HtmlForEmailTemplatesDialogComponent, SmsPreviewDialogComponent, SurveySchedulesSuccessComponent } from 'src/app/shared/components/dialog-box';
import { OfferAttachEmailDialogComponent } from '../../../offer/pages';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { create360dialogaccoountService } from 'src/app/core/services/create-360-dialog-account-service';

@Component({
  selector: 'app-survey-channel-select',
  templateUrl: './survey-channel-select.component.html',
  styleUrls: ['./survey-channel-select.component.css']
})
export class SurveyChannelSelectComponent implements OnInit {

  constructor(
    private apiCall: ApiCallService,
    private tokenstorage: TokenStorageService,
    private dialog: MatDialog,
    private snackbar: SnackbarCollection ,
    private router: Router,
    private route: ActivatedRoute,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private create360dialog: create360dialogaccoountService
  ) { 
    this.getScreenSize();
  }

  message: string = "Hola Mundo!";
  emailLayoutJSON;
  offerText1 = "";
  offerText2 = "";
  displayEmailLayoutHTML
  templateName = "templat_name";
  templateName2 = ""
  templateNameSMS = "templateWSMS";
  templateNameSMS2 = "";
  templateNameWhatsApp = "templatewhatsapp";
  templateNameWhatsApp2 = "";
  emailSubject = "There is a great offer for you";
  emailSubject2 = "";
  selectedChannel=[];
  selectedOfferId = [];
  communication = {};
  channelDetails: any;
  isPos = false;
  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;
  emailId1 = null;
  emailId2 = null;
  offerId;
  appSubscriptionPlan;
  SurveyId;
  submit = false;
  layoutId;
  pos;
  currentUTC;
  staticUTCWindow;
  showSMSWarning = 2;
  validWidgets;
  toggled = false;
  emojiTheme =             {
    martShowHeader: true,
    martShowFooter: false,
    martHeaderPadding: {x: '0px', y: '0px'},
    martFooterPadding: {x: '0px', y: '0px'},
    martHeaderBG: '#e3e7e8',
    martFooterBG: '#e3e7e8',
    martBG: '#ebeff2',
    martCategoryColor: '#94a0a6',
    martCategoryColorActive:  '#455a64',
    martActiveCategoryIndicatorColor: '#00897b',
    martEmojiFontSize: '110%',
    martCategoryFontSize: '12px',
    martBorderRadius: '14px',
    martActiveCategoryIndicatorHeight: '1px',
    martEmojiPadding: {x: '40px', y: '40px'}
  };
  validScreens= null;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() submitData: any;
  @ViewChild("channelSelect") channelSelect: any;
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  ngOnInit(): void {
    console.log(this.submitData)
    this.SurveyId = this.submitData.surveyTemplateId;
    // this.layoutId = this.submitData.layoutId;
    this.getWidgets();
    this.getemailLayout();
    if(this.tokenstorage.getMerchantOnlineStore() === 'shopify'){
      this.isPos = false;
    }
    else{
      this.isPos = true;
    }
    this.pos = this.tokenstorage.getMerchantOnlineStore();
    this.getSMSWarning()
  }
  sendcancelMessage() {
    this.messageEvent.emit(this.message);
  }
  selectopenclose(){
    this.channelSelect.open();
  }
  changeSelection(){
    if(this.channelSelect.value.indexOf('whatsapp')!== -1){
      if(this.tokenstorage.getIsAccountCreated() === 'false'){
        const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
          panelClass: "no-padding-dialog-popup",
          data: {
            subject: 'WhatsApp account not linked',
            message: 'You can link WhatsApp Business account by clicking on Link.',
            cancelButtonText: "Cancel",
            successButtonText: "Link",
          }
        })
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.create360dialog.createAccountNew();
          }
          else{
            this.deselectValue('whatsapp');
          }
        });
      }
      else{
        this.selectedChannel = this.channelSelect.value;
      }
    }
    else{
      this.selectedChannel = this.channelSelect.value;
    }
    var value: any = {};
    
  }
  getemailLayout(){
  //   let response =  {
  //     "message": "success",
  //     "body": {
  //         "email": {
  //             "id": 12334,
  //             "layoutName": "Survey Email",
  //             "merchantId": 10393,
  //             "subject": null,
  //             "emailType": "STANDARD",
  //             "lastModifiedTime": "2023-01-11 12:13:56.0",
  //             "createdTime": "2023-01-11 12:13:56.0",
  //             "snapshotUrl": "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/snapshots/prod/27/surv.png",
  //             "recentlyUsedFlag": false,
  //             "layoutHtmlURL": "greyfox-templates-dev/shopify/10393/EMAIL/SURVEY/Survey Email-12334.html",
  //             "layoutJsonURL": "greyfox-templates-dev/shopify/10393/EMAIL/SURVEY/Survey Email-12334.json",
  //             "type": "SURVEY"
  //         },
  //         "sms": null,
  //         "whatsapp": [
  //             {
  //                 "id": 30,
  //                 "name": "Survey Link",
  //                 "merchantId": null,
  //                 "layoutData": "{\n  \"templateName\": \"survey_basic_feedback_link\",\n  \"body\": \"We would like to hear from you on your experience so far. If there's anything else we can do to bring ease and comfort to you, we are all ears. Fill out your response here {{Survey Page Url}}\",\n  \"bodyText\": [\n    [\n      \"Survey Page Url\"\n    ]\n  ],\n  \"header\": \"TEXT\",\n  \"headerText\": \"Feedback\",\n  \"footer\": \"Please type STOP, to stop receiving future messages\",\n  \"buttons\": null\n}",
  //                 "layoutType": "SURVEY",
  //                 "type": "SURVEY_LINK",
  //                 "channel": null,
  //                 "channelType": null,
  //                 "lastModifiedTime": null,
  //                 "createdTime": null,
  //                 "recentlyUsedFlag": false,
  //                 "dltTemplateId": null,
  //                 "status": null
  //             }
  //         ]
  //     }
  // }

    this.apiCall.getSurveyChannelDetails().subscribe((response)=>{
      this.channelDetails = response['body'];
      this.layoutId = this.channelDetails['email']['id'];
      this.emailSubject = this.channelDetails['email']['subject'] ? this.channelDetails['email']['subject'] : "";
      this.templateName = this.channelDetails['email']['layoutName'];
      this.templateNameWhatsApp = this.channelDetails['whatsapp'][0]['name'] ? this.channelDetails['whatsapp'][0]['name'] : null;
      this.templateNameSMS = this.channelDetails['sms'][0]['name'] ? this.channelDetails['sms'][0]['name'] : null;
    });
    // this.apiCall
    //     .getPartnershipFormConfigurations(this.pformId, "TIME_EMAIL", this.s)
    //     .subscribe((response2) => {
    //       this.emailLayoutJSON = response2["body"]["emailJson"];
    //       this.displayEmailLayoutHTML =
    //         '<div class="bloghtml2">' +
    //         response2["body"]["emailHtml"] +
    //         "</div>";
    //       this.templateName = response2["body"]["emailTemplateName"];
    //       this.timeEmailConfig.emailSubject = response2["body"]["subject"];
    //       this.previewData.selectedLayout = response2["body"]["emailHtml"];
    //       this.previewData.templateName =
    //         response2["body"]["emailTemplateName"];
    //       this.offerData.channel = "EMAIL";
    //       this.getOfferDescription();
    //     });
  }
  getOfferDescription() {
    // this.apiCall.getOfferDescription(this.offerData).subscribe((response3) => {
    //   this.offerText = response3["body"]["allOfferDescription"];
    // });
  }
  openwhatsAppPreview(){

  }
  opensmsPreview(){
    let layout : any;
      layout =  (this.channelDetails['sms'][0]['layoutData'])
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout,
        type: "SMS"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  save(){
    this.submit = true;
    if(this.selectedChannel.length != 0 ){
      if((this.selectedChannel.indexOf('email') !== -1 && this.emailSubject.length === 0)){
        return
      }
      else{
        let communication = JSON.stringify(this.getCommunication())
        this.submitData.communication = communication;
        const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
          panelClass: "no-padding-dialog-popup",
          width: "20%",
          data: {
            subject: this._i18nDynamicTranslate.transform("Schedule survey?", ['POS_PAGE']),
            message: "",
            "data-widget": "SURVEY SCHEDULE",
            "data-button": "Confirm: Schedule",
            successButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.apiCall.scheduleSurvey(this.submitData).subscribe(
              (response) => {
                // this.messageEvent.emit(this.message);
                if (response["message"] === "SUCCESS") {
                  const dialogRef = this.dialog.open(
                    SurveySchedulesSuccessComponent,
                    {
                      panelClass: "no-padding-dialog-popup",
                      width: "auto",
                      disableClose: true,
                    }
                  );
                  dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                      this.router.navigate(["/app/surveys"], {
                        queryParams: { scheduled: true },
                      });
                    }
                  });
                } else {
                  this.snackbar.openSnackBar("Failed", 2000);
                }
              },
              (err) => {
                const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
                    body: this._i18nDynamicTranslate.transform(err.error["body"], ['POS_PAGE']),
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  // this.disabled = false;
                });
                //  this.snackBar.openSnackBar(err["error"]["message"], 2000);
              }
            );
          }
        });
      }
    }
    else{
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please select at least one channel"), 5000);
    }


  }
  getCommunication(){
    var commu: any = {};
    let data1: any = {};
    for(let i = 0; i<this.selectedChannel.length;i++){
      let value: any = {};
      if(this.selectedChannel[i]==='email'){
        value.id = this.layoutId;
        value.subject = this.emailSubject;
        data1[this.selectedChannel[i]] = value;
        commu = data1
      }
      else if(this.selectedChannel[i]==='sms'){
        value.id = this.channelDetails['sms'][0]['id'];
        data1[this.selectedChannel[i]] = value;
        commu = data1
      }
      else{
        value.id = this.channelDetails['whatsapp'][0]['id'];
        data1[this.selectedChannel[i]] = value;
        commu = data1
      }
    }
    return commu;
  }

  openEditor(sh, sw, datas,type) {
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1100px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: datas,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
        // this.timeEmailConfig.emailLayoutId = result.layoutId;
        if(type === 1){
          this.emailId1 = result.layoutId;
        }
        else{
          this.emailId2 = result.layoutId; 
        }
      }
    });
  }

  loadEmailEditorReward(type) {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    var datas = {
      process: "NewTemplateAndLayout",
      id: type === 1 ? this.emailId1: this.emailId2,
      tags: [],
      imageTagStatus: false,
      specialTagType: "",
      offerId: null,
      type: "FORM",
      rewardType: null,
      isReward: false,
      subject: type === 1 ? this.emailSubject : this.emailSubject2,
      emailLayoutHTML: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailHtml'] : this.channelDetails['afterBarrier']['email']['emailHtml'],
      emailLayoutJSON: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailJson'] : this.channelDetails['afterBarrier']['email']['emailJson']
    };
    this.openEditor(sh, sw, datas, type);
  }

  openSanpshotDialog(dat, type) {
    const dialogRef = this.dialog.open(HtmlForEmailTemplatesDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "60%",
      height: "500px",
      data: dat,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "edit") {
        this.loadEmailEditorReward(type);
      }
    });
  }

  // opensnapshotHtmlDialog(type) {
  //   var dat = {
  //     type: "FORM",
  //     id: null,
  //     channel: null,
  //     templateID: null,
  //     rewardType: null,
  //     actionType: null,
  //     layoutHTML: type === 1 ? this.channelDetails['beforeBarrier']['email']['emailHtml'] : this.channelDetails['afterBarrier']['email']['emailHtml'],
  //     subject: this.templateName,
  //     emailSubject: type === 1 ? this.emailSubject : this.emailSubject2
  //   };
  //   this.openSanpshotDialog(dat, type);
  // }
  async getWidgets() {
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
      this.validWidgets = await this._WidgetConfigService.getWidget();
      this.validScreens = await this._WidgetConfigService.getScreen();
  }
  openWhatsAppPreview(type){
    let layout : any;
      layout =  JSON.parse(this.channelDetails['whatsapp'][0]['layoutData'])
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout['body'],
        type: "WhatsApp"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  opensnapshotHtmlDialog() {
    var dat = { type: null, id: null, templateId: null, actionType: null };
    if (this.layoutId > 0) {
      const dialogRef = this.dialog.open(HtmlDataDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "60%",
        height: "500px",
        data: { type: "survey", id: this.layoutId, templateId: this.SurveyId },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "edit") {
          this.loadEmailEditor();
        }
      });
    } else {
      this.apiCall.listEmailLayoutFilter("SURVEY").subscribe((res) => {
        if (res["message"] === "SUCCESS" || res["message"] === "success") {
          this.surveyid = res["body"][0]["id"];
          dat = {
            type: "surveyemailtemplate",
            id: res["body"][0]["id"],
            templateId: this.SurveyId,
            actionType: "Survey",
          };
          const dialogRef = this.dialog.open(
            HtmlForEmailTemplatesDialogComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "60%",
              height: "500px",
              data: dat,
            }
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result == "edit") {
              this.loadEmailEditor();
            }
          });
        }
      });
    }
  }

  surveyid;

  loadEmailEditor() {
    var specialTagType = null;
    var imageTagStatus = false;
    this.finalScreenHeight = this.scrHeight;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);

    if (this.layoutId > 0) {
      var datas = {
        process: "EditTextBodySurvey",
        id: this.layoutId,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        type: "Survey",
        offerId: this.SurveyId,
        isReward: false,
        rewardType: null,
      };
      const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
        panelClass: "customAttachEmail",
        height: sh + "px",
        width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
        maxWidth: "99vw",
        disableClose: true,
        data: datas,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
          this.layoutId = result.layoutId;
        }
      });
    } else {
      var datas = {
        process: "surveyEmail",
        id: this.surveyid,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        type: "SURVEY",
        offerId: this.SurveyId,
        isReward: false,
        rewardType: null,
      };

      const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
        panelClass: "customAttachEmail",
        height: sh + "px",
        width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
        maxWidth: "99vw",
        disableClose: true,
        data: datas,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
          this.layoutId = result.layoutId;
        }
      });
    }
  }
  deselectValue(value: string) {
    const index = this.selectedChannel.indexOf(value);
    if (index !== -1) {
      this.selectedChannel.splice(index, 1); // Remove the value from selectedValues array
      this.channelSelect.value.splice(index, 1);
      this.selectedChannel = [...this.selectedChannel];
    }
  }
  getSMSWarning(){
    const activationDate = new Date();
    this.currentUTC = new Date(
      activationDate.getUTCFullYear(),
      activationDate.getUTCMonth(),
      activationDate.getUTCDate(),
      activationDate.getUTCHours(),
      activationDate.getUTCMinutes(),
      activationDate.getUTCSeconds()
    );
    var s = new Date(this.currentUTC).toLocaleString(undefined, {
      timeZone: this.pos === 'shopify' ? 'Asia/Kolkata' : this.tokenstorage.getMerchantTimeZone(),
      hour12: false,
    });
    let staticTimeWindow0830 = new Date("01/01/2011 08:55 PM");
    this.staticUTCWindow = new Date(
      staticTimeWindow0830.getUTCFullYear(),
      staticTimeWindow0830.getUTCMonth(),
      staticTimeWindow0830.getUTCDate(),
      staticTimeWindow0830.getUTCHours(),
      staticTimeWindow0830.getUTCMinutes(),
      staticTimeWindow0830.getUTCSeconds()
    );
    var r = new Date(this.staticUTCWindow).toLocaleString(undefined, {
      timeZone: this.pos === 'shopify' ? 'Asia/Kolkata' : this.tokenstorage.getMerchantTimeZone(),
      hour12: false,
    });
    let staticTimeWindow0900 = new Date("01/01/2011 10:00 AM");
    let static930 = new Date(
      staticTimeWindow0900.getUTCFullYear(),
      staticTimeWindow0900.getUTCMonth(),
      staticTimeWindow0900.getUTCDate(),
      staticTimeWindow0900.getUTCHours(),
      staticTimeWindow0900.getUTCMinutes(),
      staticTimeWindow0900.getUTCSeconds()
    );
    var t = new Date(static930).toLocaleString(undefined, {
      timeZone: this.pos === 'shopify' ? 'Asia/Kolkata' : this.tokenstorage.getMerchantTimeZone(),
      hour12: false,
    });
    if (
      this.dateCompare(r.split(",")[1], s.split(",")[1]) == 1 &&
      this.dateCompare(s.split(",")[1], t.split(",")[1]) == 1
    ) {
      this.showSMSWarning = 1;
    }
  }
  dateCompare(time1, time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], parts[2], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], parts[2], 0);
    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }
  handleEmoji(e)  {
    let inputField: any = document.querySelector('#emailSubjectInputField');
    const caretPosition = inputField.selectionStart;
    let text = '';
    text = this.emailSubject
    let updatedContent =`${text.slice(0, caretPosition)}${e.char}${text.slice(caretPosition)}`;
    this.emailSubject = updatedContent;
    console.log('Emoji Name',  e.name);
}
@HostListener("document:click", ["$event"])
clickout3(event3) {
  // if (document.getElementById("helpProfile").contains(event3.target)) {
  //   this.displayHelpPopover();
  // } else {
  //   this.closeHelpPopover();
  // }
  if (document.getElementById("emojiContainer")?.contains(event3.target) || document.getElementById("emojiselector")?.contains(event3.target)) {
  } else {
    this.toggled = false;
  }
}
}
