<div>
    <span class="headText">{{'SHARED_PAGE.ENTER_SUBJECT_EMAIL' | i18nTranslate}} </span>
    <form [formGroup]="dataForm">
        <div style="margin-top: 10px; margin-bottom: 15px;">
            <div class="formDiv" [ngClass]="{'error': dataForm.get('Subject').errors && (dataForm.get('Subject').touched || submitClicked)}">
                <input type="text" formControlName="Subject" class="formInput" required>
            </div>
            <div style="height: 20px;">

                <mat-error style="font-size: 12px;text-align:right" *ngIf="dataForm.controls.Subject.dirty&&dataForm.controls.Subject.errors?.maxlength&&!dataForm.controls.Subject.hasError('pattern')">
                    {{'SHARED_PAGE.MAX_ALLOWED' | i18nTranslate}}
                </mat-error>
                <mat-error style="font-size: 12px;text-align:right" *ngIf="dataForm.controls.Subject.dirty&&dataForm.controls.Subject.errors?.minlength&&!dataForm.controls.Subject.hasError('pattern')">
                    {{'SHARED_PAGE.AT_LEAST' | i18nTranslate}}
                </mat-error>
            </div>
        </div>
    </form>
</div>
<div style="display:flex; justify-content:space-between; width: 100%;">
    <span class="dismiss-button" (click)="closeDialog(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="done-button" (click)="closeDialog(true)">{{'SHARED_PAGE.SAVE' | i18nTranslate}}</span>
</div>