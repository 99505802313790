<div class="qb-templateListContainer" *ngIf="validWidgets" #mainContainer id="container">
  <div *ngIf="validWidgets | widgetConfig: 22" class="qb-main-container" data-widget-name="EMAIL LISTING">

    <div class="qb-main-header" #mainHeader>
      <app-breadcrumb></app-breadcrumb>
      
      <div class="qb-buttons">
        <div class="qb-template-style-switch-button-container">
          <div class="qb-template-style-switch-icon-container qb-switch-icon-background">
            <span class="icons-primary qb-grid-container"
              [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.GRID_VIEW' | i18nTranslate">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.25 1.5V4.25H14.5V2C14.5 1.75 14.25 1.5 14 1.5H11.25ZM9.75 1.5H6.25V4.25H9.75V1.5ZM4.75 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V4.25H4.75V1.5ZM1.5 5.75V8.25H4.75V5.75H1.5ZM1.5 9.75V12C1.5 12.2812 1.71875 12.5 2 12.5H4.75V9.75H1.5ZM6.25 12.5H9.75V9.75H6.25V12.5ZM11.25 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V9.75H11.25V12.5ZM14.5 8.25V5.75H11.25V8.25H14.5ZM0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2ZM6.25 8.25H9.75V5.75H6.25V8.25Z" fill="#4379EE"/>
                </svg>
            </span>
          </div>
          <div (click)="selectListView()" class="qb-template-style-switch-icon-container cursor-pointer qb-switch-icon-background-2"
             data-button-name="List view">
            <span class="cursor-pointer" [matTooltipShowDelay]="500"
              [matTooltip]="'COMMON_PAGE.LIST_VIEW' | i18nTranslate">
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0C1.53125 0 2 0.46875 2 1C2 1.5625 1.53125 2 1 2C0.4375 2 0 1.5625 0 1C0 0.46875 0.4375 0 1 0ZM4.75 0.25H14.25C14.6562 0.25 15 0.59375 15 1C15 1.4375 14.6562 1.75 14.25 1.75H4.75C4.3125 1.75 4 1.4375 4 1C4 0.59375 4.3125 0.25 4.75 0.25ZM4.75 5.25H14.25C14.6562 5.25 15 5.59375 15 6C15 6.4375 14.6562 6.75 14.25 6.75H4.75C4.3125 6.75 4 6.4375 4 6C4 5.59375 4.3125 5.25 4.75 5.25ZM4.75 10.25H14.25C14.6562 10.25 15 10.5938 15 11C15 11.4375 14.6562 11.75 14.25 11.75H4.75C4.3125 11.75 4 11.4375 4 11C4 10.5938 4.3125 10.25 4.75 10.25ZM2 6C2 6.5625 1.53125 7 1 7C0.4375 7 0 6.5625 0 6C0 5.46875 0.4375 5 1 5C1.53125 5 2 5.46875 2 6ZM1 10C1.53125 10 2 10.4688 2 11C2 11.5625 1.53125 12 1 12C0.4375 12 0 11.5625 0 11C0 10.4688 0.4375 10 1 10Z" fill="#4379EF"/>
                </svg>
            </span>
          </div>
        </div>
        <button *ngIf="(validWidgets | widgetConfig: 23)" class="qb-create-template-div primary-button"
          (click)="createTemplate()" [ngClass]="{ active: tabSelected == 'new' }"
          data-button-name="Create New Template">
          <span class="qb-create-template-span">
           {{'COMMUNICATION_PAGE.CREATE_NEW_TEMPLATE' | i18nTranslate}}
          </span>
        </button>
      </div>
    </div>
  
    <div class="qb-content-container" #content>
      <div class="cards-header">
        <div class="cards-heading-text">
          <h5 style="font-size: 18px; font-weight: 700;">{{headingTextKey}}</h5>
        </div>
        <div class="cards-filter">
          <div class="list-options">
            <div class="header-button-container">
              <button class="header-button header-button-left"  [ngClass]="{ active: tabSelected == 'preset'}" (click)="selectList('preset', $event)">
                <span>{{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}</span>
              </button>
              <button class="header-button header-button-right"  [ngClass]="{ active: tabSelected == 'custom'}" (click)="selectList('custom', $event)">
                <span>{{'COMMUNICATION_PAGE.CUSTOM' | i18nTranslate}}</span>
              </button>
            </div>
            <!-- <div class="content-filter-container" *ngIf="showFilters">
              <div class="label">{{'COMMON_PAGE.FILTERS' | i18nTranslate}}:</div>
              <div>
                <button class="filter-button margin-right-5px"  [ngClass]="{ active: filterSelected == 'generic'}" (click)="selectList(tabSelected, $event,'generic')">
                  <span>{{'COMMON_PAGE.GENERAL' | i18nTranslate}}</span>
                </button>
                <button class="filter-button"  [ngClass]="{ active: filterSelected == 'reward'}" (click)="selectList(tabSelected, $event,'reward')">
                  <span>{{'COMMON_PAGE.REWARD' | i18nTranslate}}</span>
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <div class="cards-search">
          <div class="qb-search-bar ">
        <div class="qb-Rectangle">
          <div class="qb-d-flex qb-ai-center qb-h-100 qb-w-96">
            <input
            class="qb-search-input"
              type="text" [(ngModel)]="filter.label" placeholder="Search" (click)="    $event.stopPropagation();
              ;" #search />
            <span class="material-icons icons-primary" *ngIf="filter.label.length==0">
              search
            </span>
            <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
              *ngIf="filter.label.length>0" (click)="filter.label=''">
              close
            </span>
          </div>
        </div>
      </div>
      <div
      class="filterButton button-hover-effect"
      (click)="openFiltersDiv()"
      data-button-name="Filter"
    >
      <div style="display: flex">
        <span
          style="cursor: pointer"
          [matTooltipShowDelay]="200"
          [matTooltip]="'COMMON_PAGE.FILTERS' | i18nTranslate"
        >
                  <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 10C16.1348 10 20.5 7.98528 20.5 5.5C20.5 3.01472 16.1348 1 10.75 1C5.36522 1 1 3.01472 1 5.5C1 7.98528 5.36522 10 10.75 10Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 5.5C1.00253 10.0155 4.10614 13.938 8.5 14.979V21.25C8.5 22.4926 9.50736 23.5 10.75 23.5C11.9926 23.5 13 22.4926 13 21.25V14.979C17.3939 13.938 20.4975 10.0155 20.5 5.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
        </span>
      </div>
    </div>
      
        </div>
      </div>
      <div class="email-listing" *ngIf="!loading; else loadSpinner">
        <div class="qb-template-wrapper">
          <div class="qb-emails-div" *ngFor="
          let layout of selectedTabTemplateList
            | labelPipe: filter.label:'layoutName';
          let i = index;
          let l = count;
        ">
        <div class="qb-Rectangle-list" [id]="'email-' + layout['id']" (click)="editTemplate(layout)"
          data-button-name="Edit: Tile view" [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']">
          <div class="qb-d-flex qb-fd-coloumn qb-h-100">
            <div class="qb-template-image-container">
              <img *ngIf="layout['snapshotUrl']" [src]="layout['snapshotUrl']" width=" 100% " height=" 100% "
                class="qb-email-template-img" alt=" Email-Template " />
              <div *ngIf="layout['snapshotUrl'] == null" class="qb-flexAllCenter">
                <mat-spinner diameter="25"></mat-spinner>
              </div>
            </div>
            <div class="qb-template-name">
              <div>
                <div class="qb-templateHeadText" [matTooltip]="layout['layoutName']" matTooltipclass="qb-ex-tooltip-red1"
                  matTooltipShowDelay="500">
                  {{ layout["layoutName"] }}
                </div>
              </div>
              <div class="qb-Digits">
                <span class="qb-date-format">
                  {{
                  layout["lastModifiedTime"] | date: getDateFormat("dd MMM yyyy")
                  }}</span>
              </div>
            </div>
          </div>
          <div class="qb-more-options" id="s">
            <div class="qb-edit-icon" *ngIf="appSubscriptionPlan && layout['emailType']==='CUSTOM'">

              <span class="material-icons-outlined icons-primary qb-icon"
                [matTooltipShowDelay]="500"
                [matTooltipPosition]="'right'"
                [matTooltip]="'COMMON_PAGE.EDIT' | i18nTranslate" (click)="editTemplate(layout, false)" data-button-name="Edit: Tile view"
                [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']">
                edit
              </span>
            </div>

            <div class="qb-edit-icon" *ngIf="appSubscriptionPlan && layout['type'] != 'REWARD'">

              <span class="material-icons-outlined icons-primary qb-icon"
                (click)="cloneTemplate(layout)"
                data-button-name="Clone: Tile view"
                [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']" [matTooltipShowDelay]="500"
                [matTooltip]="'COMMON_PAGE.CLONE' | i18nTranslate"
                [matTooltipPosition]="'right'">
                content_copy
              </span>
            </div>

            <div class="qb-edit-icon" *ngIf="layout['isDeletable'] && appSubscriptionPlan">
                <span class="material-icons-outlined icons-primary qb-icon" 
                  (click)="deleteLayout(layout['id'], tabSelected, $event)"
                  [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']" [matTooltipShowDelay]="500"
                  [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" [matTooltipPosition]="'right'">
                  delete
                </span>
            </div>
            
            <div class="qb-edit-icon" *ngIf="appSubscriptionPlan">
              <span class="material-icons-outlined icons-primary qb-icon" 
                (click)="createSharableLink(layout)"
                [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']" [matTooltipShowDelay]="500"
                [matTooltip]="'COMMON_PAGE.SHARE' | i18nTranslate" [matTooltipPosition]="'right'">
                share
              </span>
          </div>
            <!-- <div class="qb-edit-icon" *ngIf="
            layout['emailType'] &&
            layout['emailType'] !== 'STANDARD' &&
            tabSelected != 'reward' &&
            appSubscriptionPlan
          ">

              <span class="material-icons-outlined icons-primary qb-icon"
                (click)="deleteLayout(layout['id'], tabSelected, $event)" data-button-name="Delete: Tile view"
                [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']" [matTooltipShowDelay]="500"
                matTooltip="Delete">
                delete
              </span>
            </div> -->
          </div>
          <!-- <mat-icon
            type=" button "
            placement="bottom "
            class="qb-more-option-icon"
            (click)="openOptions($event)"
          >
            more_vert</mat-icon
          > -->
          <!-- <div class="qb-more-options">
            <div style="display: flex; flex-direction: column">
              <div
                class="qb-popover-menu"
                style="cursor: pointer"
                (click)="editTemplate(layout, false)" data-button-name="Edit: Tile view" [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']"
              >
                Edit
              </div>
              <div
                *ngIf="appSubscriptionPlan && layout['type'] != 'REWARD'"
                class="qb-popover-menu"
                style="cursor: pointer"
                (click)="cloneTemplate(layout)" data-button-name="Clone: Tile view" [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']"
              >
                Clone
              </div>
              <div
                class="qb-popover-menu"
                *ngIf="
                  layout['emailType'] &&
                  layout['emailType'] !== 'STANDARD' &&
                  tabSelected != 'reward' &&
                  appSubscriptionPlan
                "
                style="cursor: pointer"
                (click)="deleteLayout(layout['id'], tabSelected, $event)" data-button-name="Delete: Tile view" [attr.data-item-name]="'('+layout['id']+')'+layout['layoutName']"
              >
                Delete
              </div>
            </div>
          </div> -->
        </div>
      </div>
        </div>
          
          <div *ngIf="
          (selectedTabTemplateList | labelPipe: filter.label:'layoutName')
            .length === 0
        " 
          class="no-data">
        <div class="qb-empty-customers-div">
          <div>{{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
        </div>
      </div>
      </div>
     
    </div>
    <ng-template #loadSpinner>
      <div class="qb-d-flex qb-jc-center qb-mt-10">
        <app-qubriux-loading  [size]="65" [loadingText]="'Fetching Email Templates...'"></app-qubriux-loading>
      </div>
    </ng-template>
  </div>
</div>




<div *ngIf="showFiltersDiv">
  <div class="overlay"></div>
  <div class="filter-actions">
    <div class="titleDiv">
      <div
        style="display: flex; justify-content: space-between;align-items: center;"
      >
        <div class="title">{{'CAMPAIGN_PAGE.FILTERS_NOR' | i18nTranslate}}</div>
        <div
          *ngIf="showClearAll"
          class="clear-all-button button-hover-effect"
          (click)="clear()"
        >
          {{'CAMPAIGN_PAGE.CLEAR_ALL_CAPS' | i18nTranslate}}
        </div>
      </div>
      <div (click)="closeFiltersDiv()"
        class="close-style"
      >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.11529 8.99993L0.515625 2.40027L2.40124 0.514648L9.00091 7.11431L15.6006 0.514648L17.4862 2.40027L10.8865 8.99993L17.4862 15.5996L15.6006 17.4852L9.00091 10.8855L2.40124 17.4852L0.515625 15.5996L7.11529 8.99993Z" fill="#202224"/>
        </svg>
        
      </div>
    </div>
    <div style="padding: 0px 20px;">
      <hr style="border-style: dashed;">
    </div>
    <div class="filter-container-journey">
      <div class="filter">
        <div>
          <p><strong style="font-size: 18px">{{'COMMUNICATION_PAGE.TYPE' | i18nTranslate}}</strong></p>
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters.generic"
              value="generic"
              (change)="toggleFilter('generic')"
            ></mat-checkbox
            ><span class="option">{{'COMMUNICATION_PAGE.GENERIC' | i18nTranslate}}</span>
          </p>

          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters.reward"
              value="reward"
              (change)="toggleFilter('reward')"
            ></mat-checkbox
            ><span class="option">{{'COMMUNICATION_PAGE.REWARD' | i18nTranslate}}</span>
          </p>
          
         
        </div>
      </div>
    </div>
    <div class="bottom-button-container">
        <button class="secondary-button-border" (click)="closeFiltersDiv()">
          {{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}
        </button>
        <button class="apply primary-button" (click)="applyFilter()">
          {{'CAMPAIGN_PAGE.APPLY' | i18nTranslate}}
        </button>
    </div>
  </div>
</div>