import { NgModule } from '@angular/core';
import { GenerateSmsComponent, GetSmsConsentComponent, SmsTemplateCreationComponent, SmsTemplateListingComponent, SmsTemplateTileComponent } from './pages';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { SmsRoutingModule } from './sms-routing.module';



@NgModule({
  declarations: [
    GenerateSmsComponent,
    GetSmsConsentComponent,
    SmsTemplateCreationComponent,
    SmsTemplateListingComponent,
    SmsTemplateTileComponent
  ],
  imports: [
    SharedModule,
    SmsRoutingModule
  ]
})
export class SmsModule { }
