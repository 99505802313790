<div class="intro-container" *ngIf="!showDashboard && dataLoaded">
    <div class="qb-top-panel">
        <img class="qb-top-panel-img" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/personClimbingLadder.svg" width="280px" height="280px" alt="personClimbingLadderImg">
        <div class="qb-top-panel-content">
            <h1 class="qb-top-panel-content-heading">{{'SEGMENT_PAGE.UNLOCK_LOYALITY_PROGRAM' | i18nTranslate}}</h1>
            <p class="qb-top-panel-content-text">{{'SEGMENT_PAGE.LOYALITY_PROGRAM_PARAGRAPH' | i18nTranslate}}</p>
            <button class="qb-top-panel-content-button primary-button button-hover-effect" *ngIf="emailVerified" (click)="redirectToCreate()">
                <span *ngIf="buttonType == 'create'">{{'SEGMENT_PAGE.CREATE_LOYALITY_PROGRAM' | i18nTranslate}}</span>
                <span *ngIf="buttonType == 'continueConfig'">{{'SEGMENT_PAGE.CONTINUE_CONFIG_LOYALITY' | i18nTranslate}}</span> 
                <span *ngIf="buttonType == 'edit'">{{'SEGMENT_PAGE.EDIT_LOYALITY_PROGRAM' | i18nTranslate}}</span> 
            </button>
            <button class="qb-top-panel-content-button primary-button button-hover-effect" *ngIf="!emailVerified" (click)="showVerifyEmailPopup()">
                <span>{{'SEGMENT_PAGE.CREATE_LOYALITY_PROGRAM' | i18nTranslate}}</span>
            </button>
        </div>
    </div>
    <div class="qb-bottom-panel">
        <h2 class="qb-bottom-panel-content-heading">{{'SEGMENT_PAGE.HOW_TO_CREATE_LOYALITY' | i18nTranslate}}</h2>
        <div class="qb-bottom-panel-box-container">
            <div class="qb-bottom-panel-box">
                <img class="qb-bottom-panel-box-img" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/personDefining.svg" width="120px" height="120px">
                <p class="qb-bottom-panel-box-text">{{'SEGMENT_PAGE.CREATE_LOYALITY_STEP_1' | i18nTranslate}}</p>
            </div>
            <div class="qb-bottom-panel-box">
                <img class="qb-bottom-panel-box-img" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/personBuilding.svg" width="120px" height="120px">
                <p class="qb-bottom-panel-box-text">{{'SEGMENT_PAGE.CREATE_LOYALITY_STEP_2' | i18nTranslate}}</p>
            </div>
            <div class="qb-bottom-panel-box">
                <img class="qb-bottom-panel-box-img" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/personIdeating.svg" width="120px" height="120px">
                <p class="qb-bottom-panel-box-text">{{'SEGMENT_PAGE.CREATE_LOYALITY_STEP_3' | i18nTranslate}}</p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showDashboard && dataLoaded" class="height-100-perc main-bg-bgrey">
    <app-dashboard [loyaltyProgramConfig]="loyaltyProgramConfig"></app-dashboard>
</div>
<div class="activity-spinner-container" *ngIf="!dataLoaded">
    <app-qubriux-loading [size]="45" ></app-qubriux-loading>
</div>

