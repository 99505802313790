import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { currentPlanDataService } from "src/app/core/services/current-plan-global.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
@Component({
  selector: "app-payment-topup",
  templateUrl: "./payment-topup.component.html",
  styleUrls: ["./payment-topup.component.css"],
})
export class PaymentTopupComponent implements OnInit {
  isPricePopulatedLoading: boolean = false;
  isPricePopulated: boolean = false;
  isContinuePaymentLoading: boolean = false;
  currencySymbol;
  currentPlanObj;
  requestedCredit = {
    contact: null,
    email: null,
    sms: null,
  };
  estimatedCreditAmount = { contact: 0, email: 0, sms: 0 };
  estimatedTotalPrice = 0;
  planSetup;
  resultCredit = {
    contact: null,
    email: null,
    sms: null,
  };
  mobileMedia = window.matchMedia("(max-width: 896px)");
  isfailuredetected;
  constructor(
    private _apicallService: ApiCallService,
    private _currentPlanDataService: currentPlanDataService,
    private _WidgetConfigService: WidgetConfigService,
    private _SnackbarCollectionService: SnackbarCollection,
    private _router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    // let e =document.getElementById('gf-req-sms').value;
    this.requestedCredit = {
      contact: null,
      email: null,
      sms: null,
    };
    let ko = this._currentPlanDataService.currentPlanBody.subscribe(
      async (value) => {
        this.currentPlanObj = value; // this.currentPlanObj will hold your value and modify it every time it changes
        if (this.currentPlanObj == null) {
          await this.getCurrentPlanStat();
         if(ko) ko.unsubscribe();
          this._router.navigate(["/app/payments/payment-plan"]);
          //   this.validatePage();

          // }
        }else{
        if(ko)  ko.unsubscribe();
        }
        //  else {
        //   this.validatePage();
        // }
      }
    );
  }
  validatePage() {
    if (this.currentPlanObj.planName == "Trial") {
      this._SnackbarCollectionService.openSnackBar(
        this._i18nDynamicTranslate.transform("You are in trial plan", ['POS_PAGE']),
        2000
      );
      this._router.navigate(["/app/app-home"], {
        queryParams: { type: "Objectives" },
      });
    }
    if (!this.currentPlanObj.isEmailVerified) {
      this._SnackbarCollectionService.openSnackBar(
        this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']),
        2000
      );
      this._router.navigate(["/app/app-home"], {
        queryParams: { type: "Objectives" },
      });
    }
  }
  getTopupPriceInfo() {
    if (
      this.requestedCredit.contact == null &&
      this.requestedCredit.email == null &&
      this.requestedCredit.sms == null
    ) {
      this._SnackbarCollectionService.openSnackBar(this._i18nDynamicTranslate.transform("Please enter credit", ['POS_PAGE']), 2000);
      return 0;
    } else if (
      this.requestedCredit.contact < 0 ||
      this.requestedCredit.email < 0 ||
      this.requestedCredit.sms < 0 ||
      this.requestedCredit.contact == "e" ||
      this.requestedCredit.email == "e" ||
      this.requestedCredit.sms == "e"
    ) {
      this._SnackbarCollectionService.openSnackBar(
        this._i18nDynamicTranslate.transform("Please enter correct value", ['POS_PAGE']),
        2000
      );
      return 0;
    }
    else if((this.requestedCredit.email%1)!=0 || (this.requestedCredit.sms%1)!=0){
      this._SnackbarCollectionService.openSnackBar(this._i18nDynamicTranslate.transform("Fractional value not allowed", ['POS_PAGE']), 2000);
      return 0;
    }

    else {
      this.requestedCredit.contact =
        this.requestedCredit.contact == null ? 0 : this.requestedCredit.contact;
      this.requestedCredit.email =
        this.requestedCredit.email == null ? 0 : this.requestedCredit.email;
      this.requestedCredit.sms =
        this.requestedCredit.sms == null ? 0 : this.requestedCredit.sms;
      this.getEstimatedCreditAmount(this.currentPlanObj.subscriptionSubPlanId);
    }
  }

  getEstimatedCreditAmount(subPlanId) {
    this.isPricePopulatedLoading = true;
    this.isPricePopulated = false;
    let k={
      contact: 0,
      email: this.requestedCredit.email*1000,
      sms: this.requestedCredit.sms*100,
    };
    this._apicallService
      .getTopupPriceInfo(k, subPlanId)
      .subscribe(
        (response) => {
          let responseBody = response["body"];
          let o = JSON.stringify(this.requestedCredit);
          this.resultCredit = JSON.parse(o);
          this.estimatedCreditAmount.contact = responseBody.CUSTOMER_COUNT;
          this.estimatedCreditAmount.email = responseBody.EMAILS_COUNT;
          this.estimatedCreditAmount.sms = responseBody.SMS_COUNT;
          this.estimatedTotalPrice = responseBody.total;
          this.isPricePopulatedLoading = false;
          this.isPricePopulated = true;
        },
        (err) => {
          this.isPricePopulatedLoading = false;
          this.isPricePopulated = true;
          this._SnackbarCollectionService.openSnackBar(
            this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']),
            2000
          );
        }
      );
  }
  async getCurrentPlanStat() {
    this.planSetup = await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  updateTopup() {
    let reqData = {
      subPlanId: this.currentPlanObj.subscriptionSubPlanId,
      smsCredits: this.requestedCredit.sms*100,
      emailCredits: this.requestedCredit.email*1000,
      customerCredit: this.requestedCredit.contact,
      price: this.estimatedTotalPrice,
    };
    this.isContinuePaymentLoading = true;
    this._apicallService.createTopUpCharge(reqData).subscribe(
      (response) => {
        let responseData = response["body"];
        window.open(responseData.confirmationURL, "_self");
        // this.isSelectButtonActive = false;
        // this.selectedPlanBtnName=null;
      },
      (err) => {
        this.isContinuePaymentLoading = false;
        this._SnackbarCollectionService.openSnackBar(
          this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']),
          2000
        );
      }
    );
  }

}
