// hideAllEvents - Hiding the option button on every nodes.
// checkStrategyStatus - Define whether to create or update a strategy on init.
// constantDataStatus - Checking whether the constant data is in the system.
// getConstantData - Getting the constatnt data.
// saveStrategy - Saving a new strategy.
// magnify - Changing the scale of the canvas.
// configureNodeData - Adding and updating node data on successfull addition and updation of every node.
// removeNodeFromView - Removing single node from view.
// setNodeContent - Adding metadata to the nodes.
// updateNodeData - Updating the node data.
// addNodeDataToView - Creating elements to serve as meta data of a node.
// nodeOperations - Node Operations selector(Move, Clone and Delete).
// addNodeDataToList - Adding the node data to the node data list.
// getNodeIdFromList - returns node Id for node block Id.
// addFirstNode - Adding the first node (Trigger) node to the strategy.
// addNewNode - Truiggering the event to add a new node.
// createUnbranchNode - Creating new unconditional node.
// createBranchNode - Creating new conditional node.
// getNodeBranchType - Returns the type of Node(Branched/Unbranched).
// deleteNode - Removing a single node from the view and  the list.
// appendBranchNodesToConditionalNode - Adding new intermediate conditional Node with conditional node as parent
// removeNodesInBranchFromView - Removing all the nodes in a branch from the view.
// deleteBranchedNode - Selecting how to remove branched nodes(Options).
// deleteConditionalNode - Removing single conditional node from the list.
// removeBranchedNodesFromView - Removing all the node in a branch of a conditional node from the View.
// removeBranchedNodes - Removing all the node in a branch of a conditional node from the list.
// cloneNode - Adding a clone of the node as a new node.
// moveNode - Moving the node position by updating the previous and next node.
// removeNodeFromList - Removing a single node item from the list.
// getNodeClassIdForView - For getting Id of the html element to add as a new Node.
// resetCanvas - Resetting the canvas.
// start - Adds the first node.
// updateStrategyInfo - Updating the strategy info like name, description, etc.
// displayMovePositionButton - Showing posible positions for move operation.
// displayClonePositionButton - Showing posible positions for clone operation.
// cancelMoveAndCloneOperations - Hiding buttons for move and clone operations.
// getTimeAsString - Returns the date as a string of total time left.
// showOrHideTriggerContent - Toggling the content conditions of the trigger node.
// getEventIcon - Returns material icon name for every event in the system.
// showNotification - Displays the notification on the screen.
// removeNotificationElement - Remove the notification element from the screen and the stack of notifications.
// hideNotification -Hides the notification with animation.
// activateStrategy - Shows dialog box to activate the strategy.
// addNodeDataOnCreate - Adding data inside a node block.
// addEndFlagToPreviousNode - Adding end flag to previous node.
// removeEndFlagFromPreviousNode - removing the end flag from the previous node.
// deletenodeApi - API call to delete a node.
// removebuttonPointerEvents - removing pointer events from the buttons in the canvas.
// addButtonPointerEvents - adding pointer events to the buttons in the canvas.
//getSegmentCount - Getting the customer count in the selected segments.

import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { formatDate, getCurrencySymbol } from "@angular/common";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { DateFormatService } from "src/app/core/services/date-format.service";
import { EventIconMappingService } from "src/app/core/services/event-icon-mapping.service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import {
  CampaignStrategyDialogComponent,
  ConfirmationDialogBoxComponent,
  NodeDeleteBranchSelectorComponent,
  CampaignStrategyActivateDialogComponent,
  SuccessDialogComponent,
  OfferSummaryDialogComponent,
  PreviewTemplateComponent,
  HtmlDataDialogComponent,
  HtmlForEmailTemplatesDialogComponent,
  SmsPreviewIdDialogComponent,
  ContactUsDialogComponent,
  SmsPreviewDialogComponent,
  PreviewComarketingOfferComponent,
} from "src/app/shared/components/dialog-box";
import { OfferAttachEmailDialogComponent } from "../../../offer/pages";
import { CampaignAddFilterComponent } from "../../component";
import e from "express";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { LogicalExpressionsViewerComponent } from "src/app/shared/components/logical-expression-viewer/logical-expression-viewer.component";
import { LoyaltyRulePreviewDialogComponent } from "src/app/shared/components/loyalty-rule-preview-dialog/loyalty-rule-preview-dialog.component";

interface nodeList {
  nodeId: number;
  nodeBlockId: string;
  nodeType: string;
  nodeData: {};
  previousNodeId: number;
  previousNodeRelation: string;
  nodeNote: string;
}

interface nodeEvent {
  nodeId: number;
  nodeType: string;
  nodeData: {};
  actionType: string;
  delete: boolean;
  error: boolean;
  nodeOperation: string;
  previousNodeId: number;
  previousNodeRelation: string;
}

interface strategyData {
  strategyDescription: string;
  strategyName: string;
  segments: any[];
  customerCount: number;
  strategyType: string;
  isSegmented: boolean;
  allLead: boolean;
  allCustomer: boolean;
  created_by: any;
}

@Component({
  selector: "app-campaign-strategy",
  templateUrl: "./campaign-strategy.component.html",
  styleUrls: ["./campaign-strategy.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignStrategyComponent implements OnInit {
  @ViewChild("leContainer") leContainer: ViewContainerRef;
  currencySymbol;
  customerSegments = [];
  strategyData: strategyData = {
    strategyDescription: "",
    strategyName: "",
    segments: [],
    customerCount: 0,
    strategyType: "",
    isSegmented: false,
    allLead: false,
    allCustomer: false,
    created_by: null,
  };
  strategyId;
  strategyName;
  zoomAmount = 100;
  actionType = null;
  nodeId;
  nodeData;
  nodeType;
  previousNodeId;
  mouseEvent;
  nodeCount = 0;
  activeNodeBlockId;
  nodeList: nodeList[] = [];
  nodeOperationBlockId;
  nextNodeId = null;
  previousNodeRelation = "NEXT";
  showConfigNav: boolean = false;
  nodeNote = "Sample Note";
  activeNodeNoteBlockId;
  strategyStatus = "DRAFT";
  addNodeEvent = null;
  showMoreSegmentButtonStatus = false;
  appSubscriptionPlan;
  validWidgets;
  templates;
  surveyTemplates;
  emailTemplates;
  whatsappTemplates;
  coMarketingOffers = [];
  offerTemplates;
  smsTemplates;
  surveyEmailTemplate;
  buffer;
  isAllCustomers;
  rewardTypeAndName = {
    BUY_X_GET_Y: "Buy X Get Y Reward Email",
    PERCENTAGE_OFF: "Percentage Off Reward Email",
    FIXED_AMOUNT_OFF: "Fixed Amount Off Reward Email",
    FREE_SHIPPING: "Free Shipping Reward Email",
  };
  offerBody;
  isStrategyCreated = false;
  validScreens;
  groupName = ["group1", "group2", "group3"];
  startIndex = 7; //Starting index in previousNodeRelation to get to know to which node the branch is connected
  customerSegmentAndTiers = [];
  store = "";
  segmentVariablesMap = {};
  allowABTestingNodeCreation = true;
  allowAudienceSplitNodeCreation = true;
  loyaltyRuleEventsInNodes = [];
  qIdOfAccount = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _apiCallService: ApiCallService,
    private _router: Router,
    private _dialog: MatDialog,
    private _popUpDialogService: PopUpDialogService,
    private _dateFormatService: DateFormatService,
    private _EventIconMappingService: EventIconMappingService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private _TokenStorageService: TokenStorageService,
    private _snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.qIdOfAccount = this._TokenStorageService.getqId() == 'null'? null :  this._TokenStorageService.getqId();
    this._EventIconMappingService.setCustomerEvents();
    let revCurrency = sessionStorage.getItem("serviceCurrency");
    this.store = this._TokenStorageService.getMerchantOnlineStore();
    this.currencySymbol = getCurrencySymbol(revCurrency, "narrow");
    this.getWidgets();
    this.getScreenId();
    // this.getConstantData();
    // this.checkStrategyStatus();
    // this.showConfigNav = true;
    this.getCustomerSegmentsAndTiers();
    this.getAndInitLoyaltyData();
    this.getOfferTemplates();
    this.getCustomerSegmentVariables();
    this.getAllWhatsAppTemplate();
  }

  offerList = [];
  getOfferTemplates() {
    this._apiCallService.getAllOfferList().subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.offerList = (response["body"]).offers;
        }
      },
      (err) => {
        this._snackBar.openSnackBar("Error fetching offer data", 2000);
        console.log(err);
      }
    );
  }

  async getScreenId() {
    this.validScreens = await this._WidgetConfigService.getScreen();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  ngAfterViewInit() {
    // this.getCustomerSegmentsAndTiers();
    this.getConstantData();
    this.getComarketingOfferTemplate();
    this.showConfigNav = true;
    document
      .getElementById("canvas")
      .addEventListener("click", (event) => this.hideAllEvents());
    document
      .getElementById("campaign-header")
      .addEventListener("click", (event) => this.hideHeaderEvents());
  }

  hideHeaderEvents() {
    let element = document.getElementById("segment-popup");
    if (element) {
      element.style.display = "none";
    }
  }

  hideAllEvents() {
    let element = document.getElementById(this.nodeOperationBlockId);
    if (element) {
      let popupElement = element.getElementsByClassName(
        "node-operations"
      ) as HTMLCollectionOf<HTMLElement>;
      if (popupElement[0]) {
        if (popupElement[0].style.display === "block") {
          popupElement[0].style.display = "none";
        }
      }
    }
    let nodeNoteElement = document.getElementById(this.activeNodeNoteBlockId);
    if (nodeNoteElement) {
      let popupElement = nodeNoteElement.getElementsByClassName(
        "node-note-input"
      ) as HTMLCollectionOf<HTMLElement>;
      if (popupElement[0]) {
        if (popupElement[0].style.display === "block") {
          popupElement[0].style.display = "none";
        }
      }
    }
    let popupElement = document.getElementById("segment-popup");
    if (popupElement) {
      if (popupElement.style.display === "flex") {
        popupElement.style.display = "none";
      }
    }
    // let canvasElement = document.getElementById("canvas");
    // let noteElements = canvasElement.getElementsByClassName(
    //   "node-note-input"
    // ) as HTMLCollectionOf<HTMLElement>;
    // for (let i = 0; i < noteElements.length; i++) {
    //   noteElements[0].style.display = "none";
    // }
  }

  async checkStrategyStatus() {
    this.addOrRemovePointerEvents(false);
    let strategyIdFromParam;
    let param;
    this._activatedRoute.params.subscribe((params) => {
      param = params;
      strategyIdFromParam = params["id"];
    });
    if (strategyIdFromParam === "null") {
      let interval = setInterval(() => {
        if (this.appSubscriptionPlan) {
          this.createNewStrategy(param);
          clearInterval(interval);
        }
      });
    } else {
      this.addOrRemovePointerEvents(false);
      this._apiCallService.getStrategy(strategyIdFromParam).subscribe(
        (response) => {
          this.addOrRemovePointerEvents(true);
          let strategyData = JSON.parse(response["body"]);
          this.strategyId = strategyData["id"];
          this.strategyData.strategyName = strategyData["strategyName"];
          this.strategyData.strategyDescription = strategyData["description"];
          this.strategyData.strategyType = strategyData["type"];
          this.strategyData.isSegmented = strategyData["isSegmented"];
          this.strategyData.allLead = JSON.parse(strategyData["definition"])['allLead'];
          this.strategyData.allCustomer = JSON.parse(strategyData["definition"])['allCustomer'];
          this.strategyData.created_by = JSON.parse(strategyData['created_by']);
          this.strategyStatus = strategyData["status"];
          this.isAllCustomers =
            JSON.parse(strategyData["definition"])["segmentIds"].length > 0
              ? false
              : true;
          this.renderNodes(strategyData);
        },
        (err) => {
          this.addOrRemovePointerEvents(true);
          this.showNotification(
            "Failed to load Journey, please try again",
            false
          );
        }
      );
    }
  }

  async renderNodes(strategyData) {
    if ((await this.constantDataStatus()) && (await this.eventStatus())) {
      let segmentIds = JSON.parse(strategyData["definition"])["segmentIds"];
      let tierIds = JSON.parse(strategyData["definition"])["tierIds"];
      let customerIds = JSON.parse(strategyData["definition"])[
        "customerListIds"
      ];
      let employeeIds = JSON.parse(strategyData["definition"])[
        "employeeListIds"
      ];
      if (this.customerSegmentAndTiers.length > 0) {
        if (segmentIds) {
          for (let i = 0; i < segmentIds.length; i++) {
            this.strategyData.segments.push(
              this.customerSegmentAndTiers[
                this.customerSegmentAndTiers.findIndex(
                  (x) => x.id === segmentIds[i]
                )
              ]
            );
          }
        }

        if (tierIds) {
          for (let i = 0; i < tierIds.length; i++) {
            this.strategyData.segments.push(
              this.customerSegmentAndTiers[
                this.customerSegmentAndTiers.findIndex(
                  (x) => x.id === tierIds[i]
                )
              ]
            );
          }
        }
        if (customerIds) {
          for (let i = 0; i < customerIds.length; i++) {
            this.strategyData.segments.push(
              this.customerSegmentAndTiers[
                this.customerSegmentAndTiers.findIndex(
                  (x) => x.id === customerIds[i]
                )
              ]
            );
          }
        }
        if (employeeIds) {
          for (let i = 0; i < employeeIds.length; i++) {
            this.strategyData.segments.push(
              this.customerSegmentAndTiers[
                this.customerSegmentAndTiers.findIndex(
                  (x) => x.id === employeeIds[i]
                )
              ]
            );
          }
        }
        this.getSegmentCount();
        this.checkMoreSegmentButtonStatus();
      }
      if (
        strategyData["displayParams"] != null &&
        JSON.parse(strategyData["displayParams"])["data"].length > 0
      ) {
        this.nodeList = JSON.parse(strategyData["displayParams"])["data"];
        this.prefillStrategy();
      } else {
        this.addDummyNode();
      }
    }
  }

  async createNewStrategy(param) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if (await this.constantDataStatus()) {
        this.addOrRemovePointerEvents(true);
        this.saveStrategy("CREATE");
      }
    } else {
      this._router.navigate(["/app/" + param["return-url"]]);
    }
  }

  getSegmentCount() {
    this.strategyData.customerCount = null;
    if (this.strategyData.segments.length > 0) {
      this._apiCallService
        .getCustomerCountInSegmentAndTiers(this.strategyData.segments)
        .subscribe((response) => {
          this.strategyData.customerCount = JSON.parse(
            response["body"]
          ).totalCustomerCount;
        });
    } else {
      this._apiCallService
        .getAnalytics(new Date(), new Date(), this.strategyData.allLead, this.strategyData.allCustomer)
        .subscribe((response) => {
          this.strategyData.customerCount = JSON.parse(
            response["body"]
          ).numberOfCustomers;
        });
    }
  }

  checkMoreSegmentButtonStatus() {
    this.showMoreSegmentButtonStatus = false;
    let length = 0;
    for (let i = 0; i < this.strategyData.segments.length; i++) {
      length += this.strategyData.segments[i].segmentName.length;
      if (i < this.strategyData.segments.length - 1) {
        length++;
      }
    }
    if (length > 20) {
      this.showMoreSegmentButtonStatus = true;
    }
  }

  prefillStrategy() {
    let parent = document.getElementById("canvas");
    this.attachNodeToCanvas(
      this.nodeList[this.nodeList.findIndex((x) => x.previousNodeId === null)],
      parent
    );
    let nodeCount = 0;
    for (let i = 0; i < this.nodeList.length; i++) {
      let count = +this.nodeList[i].nodeBlockId.substring(
        5,
        this.nodeList[i].nodeBlockId.length
      );
      if (count > nodeCount) {
        nodeCount = count;
      }
    }
    this.nodeCount = nodeCount + 1;
  }

  attachNodeToCanvas(nodeData: nodeList, parent) {
    let previosNodeIndex = this.nodeList.findIndex(
      (x) => x.nodeId === nodeData.previousNodeId
    );
    if (previosNodeIndex > -1) {
      if (
        this.getNodeBranchType(this.nodeList[previosNodeIndex].nodeType) ===
        "BRANCHED"
      ) {
        if (nodeData.previousNodeRelation === "NEXT") {
          document
            .getElementById(
              "left-node" +
                this.nodeList[previosNodeIndex].nodeBlockId.substring(
                  4,
                  this.nodeList[previosNodeIndex].nodeBlockId.length
                )
            )
            .getElementsByClassName("end-flag-left-branch")[0]
            .setAttribute("style", "display:none");
        }
        // else if(this.getNodeBranchType(this.nodeList[previosNodeIndex].nodeType) ===
        // "GROUPBRANCH"){}
        else if (nodeData.previousNodeRelation === "TIMEOUT") {
          document
            .getElementById(
              "right-node" +
                this.nodeList[previosNodeIndex].nodeBlockId.substring(
                  4,
                  this.nodeList[previosNodeIndex].nodeBlockId.length
                )
            )
            .getElementsByClassName("end-flag-right-branch")[0]
            .setAttribute("style", "display:none");
        } else if (nodeData.previousNodeRelation.substring(0, 5) === "SPLIT") {
          let i = nodeData.previousNodeRelation.substring(
            this.startIndex,
            nodeData.previousNodeRelation.length
          );
          document
            .getElementById(
              "end-flag-" +
                this.nodeList[previosNodeIndex].nodeBlockId +
                "-split-" +
                i
            )
            .setAttribute("style", "display :none");
        } else if (nodeData.previousNodeRelation.substring(0, 4) === "PATH") {
          let i = nodeData.previousNodeRelation.substring(
            6,
            nodeData.previousNodeRelation.length
          );
          document
            .getElementById(
              "end-flag-node" +
                this.nodeList[previosNodeIndex].nodeBlockId.substring(
                  4,
                  this.nodeList[previosNodeIndex].nodeBlockId.length
                ) +
                "-ab-" +
                i
            )
            .setAttribute("style", "display :none");
        } else {
        }
      } else {
        document
          .getElementById(this.nodeList[previosNodeIndex].nodeBlockId)
          .getElementsByClassName("end-flag")[0]
          .setAttribute("style", "display:none");
      }
    }
    if (this.getNodeBranchType(nodeData.nodeType) === "BRANCHED") {
      this.createBranchNode(
        parent,
        null,
        nodeData,
        "UPDATE",
        nodeData.nodeType
      );
    } else {
      this.createUnbranchNode(parent, null, nodeData, "UPDATE", false);
    }
    let nodeEvent: nodeEvent = {
      actionType: "CREATE",
      delete: false,
      error: false,
      nodeData: nodeData.nodeData,
      nodeId: nodeData.nodeId,
      nodeOperation: "CREATE",
      nodeType: nodeData.nodeType,
      previousNodeId: nodeData.previousNodeId,
      previousNodeRelation: nodeData.previousNodeRelation,
    };
    this.addNodeDataOnCreate(nodeEvent, "UPDATE");
    if (nodeData.nodeNote.length > 0) {
      this.addNodeNoteButton(null, nodeData.nodeBlockId, "UPDATE");
    }
    for (let i = 0; i < this.nodeList.length; i++) {
      if (this.nodeList[i].previousNodeId === nodeData.nodeId) {
        if (this.getNodeBranchType(nodeData.nodeType) === "BRANCHED") {
          if (this.nodeList[i].previousNodeRelation === "NEXT") {
            let parent = document.getElementById(
              "left-node-" +
                nodeData.nodeBlockId.substring(5, nodeData.nodeBlockId.length)
            );
            this.attachNodeToCanvas(this.nodeList[i], parent);
          } else if (this.nodeList[i].previousNodeRelation === "TIMEOUT") {
            let parent = document.getElementById(
              "right-node-" +
                nodeData.nodeBlockId.substring(5, nodeData.nodeBlockId.length)
            );
            this.attachNodeToCanvas(this.nodeList[i], parent);
          } else if (
            this.nodeList[i].previousNodeRelation.substring(0, 5) === "SPLIT"
          ) {
            let j = this.nodeList[i].previousNodeRelation.substring(
              this.startIndex,
              this.nodeList[i].previousNodeRelation.length
            );
            let parent = document.getElementById(
              "sp-" + nodeData.nodeBlockId + "-path-" + j
            );
            document
              .getElementById("wrap-split-" + nodeData.nodeBlockId + "-id-" + j)
              .setAttribute("style", "height:80px");
            this.attachNodeToCanvas(this.nodeList[i], parent);
          } else if (
            this.nodeList[i].previousNodeRelation.substring(0, 4) === "PATH"
          ) {
            let j = this.nodeList[i].previousNodeRelation.substring(
              6,
              this.nodeList[i].previousNodeRelation.length
            );
            let parent = document.getElementById(
              "sp-" + nodeData.nodeBlockId + "-path-" + j
            );
            document
              .getElementById("wrap-ab-" + nodeData.nodeBlockId + "-id-" + j)
              .setAttribute("style", "height:80px");
            this.attachNodeToCanvas(this.nodeList[i], parent);
          }
        } else {
          this.attachNodeToCanvas(this.nodeList[i], parent);
        }
      }
    }
  }

  constantDataStatus() {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (this.templates) {
          clearInterval(interval);
          this.buffer = true;
          resolve(true);
        }
      });
    });
  }

  eventStatus() {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (this._EventIconMappingService.isDataFetched) {
          clearInterval(interval);
          this.buffer = true;
          resolve(true);
        }
      });
    });
  }

  async getCustomerSegmentsAndTiers() {
    await this._apiCallService.getCustomerSegmentsAndTiers().subscribe(
      (response) => {
        this.customerSegmentAndTiers = response["body"];
        this.checkStrategyStatus();
      },
      (error) => {
        this._snackBar.openSnackBar(
          "Error fetching getCustomerSegmentsAndTiers data",
          2000
        );
      }
    );
  }

  async getConstantData() {
    this.templates = await this._apiCallService.getAllTemplates();
    let segments = this.templates[0]["body"];
    for (let i = 0; i < segments.length; i++) {
      //if (segments[i].isActive === true) {
      this.customerSegments.push(segments[i]);
      //}
    }
    this.surveyTemplates = this.templates[1]["body"];
    this.offerTemplates = this.templates[2]["body"];
    this.emailTemplates = this.templates[3]["body"];
    this.smsTemplates = this.templates[4]["body"];
    this.surveyEmailTemplate = this.templates[5]["body"];
  }
  async getAllWhatsAppTemplate() {
    await this._apiCallService
      .getWhatsAppTemplates("ALL")
      .subscribe((response) => {
        this.whatsappTemplates = response["body"];
      });
  }

  saveStrategy(operation) {
    const dialogRef = this._dialog.open(CampaignStrategyDialogComponent, {
      panelClass: "no-padding-no-border-radius-dialog",
      disableClose: true,
      height: "580px",
      width: "650px",
      data: {
        operation: operation,
        segments: this.customerSegmentAndTiers,
        data: this.strategyData,
        Id: this.strategyId,
        customerSegmentSelect:
          operation === "UPDATE"
            ? this.nodeList[
                this.nodeList.findIndex((x) => x.previousNodeId === null)
              ]["nodeData"]["definition"]["config"]["conditionGroups"][0][
                "conditions"
              ][0]["rules"]["EVENT"]["ruleEvent"] === "create_customer"
              ? false
              : true
            : true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result["status"]) {
        this._router.navigate([
          "/app/campaign/creation",
          {
            "return-url": "campaign/overview",
            id: result["strategyId"],
            recommendation: false,
          },
        ]);
        this.strategyData = result["strategyData"];
        this.strategyId = result["strategyId"];
        this.isAllCustomers =
          result["strategyData"]["segments"].length > 0 ? false : true;
        this.addDummyNode();
        this.checkMoreSegmentButtonStatus();
      } else {
        if (result["operation"] === "CREATE") {
          this._activatedRoute.params.subscribe((param) => {
            this._router.navigate(["/app/" + param["return-url"]]);
          });
        }
      }
    });
    //this.addFirstNode();
    // this.strategyData = {
    //   strategyName: "Name",
    //   strategyDescription: "",
    //   segments: this.customerSegments.map((x) => x),
    //   customerCount: 121,
    //   startDate: new Date(),
    //   endDate: new Date(),
    // };
    // this.addDummyNode();
  }

  async updateStrategyInfo() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this._dialog.open(CampaignStrategyDialogComponent, {
        panelClass: "no-padding-no-border-radius-dialog",
        disableClose: true,
        height: "580px",
        width: "650px",
        data: {
          operation: "UPDATE",
          segments: this.customerSegmentAndTiers,
          data: this.strategyData,
          Id: this.strategyId,
          customerSegmentSelect:
            this.nodeList.length > 0
              ? this.nodeList[
                  this.nodeList.findIndex((x) => x.previousNodeId === null)
                ]["nodeData"]["definition"]["config"]["conditionGroups"][0][
                  "conditions"
                ][0]["rules"]["EVENT"]["ruleEvent"] === "create_customer"
                ? false
                : true
              : true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result["status"]) {
          this.strategyData.strategyName =
            result["strategyData"]["strategyName"];
          this.strategyData.strategyDescription =
            result["strategyData"]["strategyDescription"];
          this.strategyData.segments = result["strategyData"]["segments"];
          this.strategyData.customerCount =
            result["strategyData"]["customerCount"];
          this.strategyId = result["strategyId"];
          this.isAllCustomers =
            result["strategyData"]["segments"].length > 0 ? false : true;
          this.strategyData.allCustomer = result["strategyData"]["allCustomer"];
          this.strategyData.allLead = result['strategyData']['allLead'];
          this.checkMoreSegmentButtonStatus();
          //this.addFirstNode();
          //this.addDummyNode();
        } else {
          if (result["operation"] === "CREATE") {
            this._activatedRoute.params.subscribe((param) => {
              this._router.navigate(["/app/" + param["return-url"]]);
            });
          }
        }
      });
    }
  }

  addDummyNode() {
    let element = document.createElement("div");
    element.setAttribute("class", "node-block-container");
    element.setAttribute("id", "trigger-node-block-dummy-delete");
    element.innerHTML = document.getElementById(
      "trigger-node-block-dummy"
    ).innerHTML;
    let startButton = element.getElementsByClassName("get-started-button")[0];
    startButton.addEventListener("click", (event) => this.start());
    document.getElementById("canvas").append(element);
  }

  showSegments(event) {
    event.stopPropagation();
    let element = document.getElementById("segment-popup");
    element.style.display = "flex";
  }

  /* Scaling the canvas
  Controlled using the 'add' and 'remove' button on the canvas */
  magnify(type) {
    this.hideAllEvents();
    let canvas = document.getElementById("canvas");
    if (type === "ADD") {
      if (this.zoomAmount < 150) {
        this.zoomAmount += 10;
        canvas.style.transform = "scale(" + this.zoomAmount / 100 + ")";
        canvas.style.transformOrigin = "left top";
      }
    } else {
      if (this.zoomAmount > 30) {
        this.zoomAmount -= 10;
        canvas.style.transform = "scale(" + this.zoomAmount / 100 + ")";
        canvas.style.transformOrigin = "left top";
      }
    }
  }
  changeIdsWithGroupName(id, groupName) {
    console.log(id);
    let nodeData =
      this.nodeList[this.nodeList.findIndex((x) => x.nodeId === id)].nodeData[
        "definition"
      ].groupName;
    let nodeId =
      this.nodeList[this.nodeList.findIndex((x) => x.nodeId === id)]
        .nodeBlockId;
    for (let i = 0; i < nodeData.length; i++) {
      if (nodeData[i] !== "Everyone else" && groupName[i] !== "Everyone else") {
        let element1 = document.getElementById(
          "sp-" + nodeId + "-path-" + nodeData[i]
        );
        element1.setAttribute("id", "sp-" + nodeId + "-path-" + groupName[i]);
        let element2 = document.getElementById(
          "ab-" + nodeId + "-path-" + nodeData[i]
        );
        element2.setAttribute("id", "ab-" + nodeId + "-path-" + groupName[i]);
        let element3 = document.getElementById(
          "end-flag-" + nodeId + "-split-" + nodeData[i]
        );
        element3.setAttribute(
          "id",
          "end-flag-" + nodeId + "-split-" + groupName[i]
        );
        let element4 = document.getElementById(
          "wrap-split-" + nodeId + "-id-" + nodeData[i]
        );
        element4.setAttribute(
          "id",
          "wrap-split-" + nodeId + "-id-" + groupName[i]
        );
        let noderelation = this.nodeList.findIndex(
          (x) => x.previousNodeRelation === "SPLIT##" + nodeData[i]
        );
        if (noderelation > -1) {
          this.nodeList[noderelation].previousNodeRelation =
            "SPLIT##" + groupName[i];
        }
      }
    }
  }
  /* Getting data of each node and diplaying the data on the node
  Event is emitted by the child component with data of the node */

  configureNodeData(event: nodeEvent, clone) {
    console.log(event);
    this.setNodeVisibilityWhileCreation(null);
    if (
      event["actionType"] === "CONFIGURE" &&
      event["nodeType"] === "AUDIENCESPLIT" &&
      !event["delete"]
    ) {
      this.changeIdsWithGroupName(
        event.nodeId,
        event.nodeData["definition"].groupName
      );
    }
    if (
      this.previousNodeRelation !== "NEXT" &&
      this.previousNodeRelation !== "TIMEOUT"
    ) {
      this.addnodeIndex(event.nodeId);
    }
    this.addButtonPointerEvents();
    event["error"] ? null : (this.actionType = null);
    if (event["actionType"] === "CREATE") {
      let dummyElement = document.getElementById("dummy");
      if (dummyElement) {
        dummyElement.parentElement.removeChild(dummyElement);
      }
      if (!event["delete"] && !event["error"]) {
        this.nodeType = event["nodeType"];
        //this.actionType = clone ? null : "CONFIGURE";
        let previousNodeBlockId;
        if (
          this.nodeType == "ABBRANCHED" ||
          this.nodeType == "RESPONSE_EVENT" ||
          this.nodeType == "AUDIENCESPLIT"
        ) {
          previousNodeBlockId =
            this.mouseEvent.srcElement.parentElement.parentElement.parentElement
              .id;
          previousNodeBlockId.substring(0, 4) === "node"
            ? null
            : (previousNodeBlockId =
                this.mouseEvent.srcElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.id);
          if (previousNodeBlockId.substring(0, 4) !== "node") {
            previousNodeBlockId =
              this.mouseEvent.srcElement.parentElement.parentElement
                .parentElement.parentElement.parentElement.parentElement
                .parentElement.parentElement.id;
          }
        } else {
          previousNodeBlockId =
            this.mouseEvent.srcElement.parentElement.parentElement.parentElement
              .id;
          previousNodeBlockId.substring(0, 4) === "node"
            ? null
            : (previousNodeBlockId =
                this.mouseEvent.srcElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.id);
          if (previousNodeBlockId.substring(0, 4) !== "node") {
            previousNodeBlockId =
              this.mouseEvent.srcElement.parentElement.parentElement
                .parentElement.parentElement.parentElement.parentElement
                .parentElement.parentElement.id;
          }
        }

        //this.previousNodeId = this.getNodeIdFromList(previousNodeBlockId);
        if (this.getNodeBranchType(event["nodeType"]) === "BRANCHED") {
          this.createBranchNode(
            this.mouseEvent,
            previousNodeBlockId,
            event,
            "CREATE",
            event["nodeType"],
            event["previousNodeRelation"]
          );
        }
        // else if(this.getNodeBranchType(event["nodeType"]) === "GROUPBRANCH"){
        //   this.createGroupBranchNode(
        //     this.mouseEvent,
        //     previousNodeBlockId,
        //     event,
        //     "CREATE"
        //   );
        // }
        else {
          this.createUnbranchNode(
            this.mouseEvent,
            previousNodeBlockId,
            event,
            "CREATE",
            clone,
            event["previousNodeRelation"],
            event["nodeType"]
          );
        }
        // If not adding a branched node in between
        if (
          !(
            this.getNodeBranchType(event["nodeType"]) === "BRANCHED" &&
            event["nextNodeId"]
          )
        ) {
          this.addNodeDataOnCreate(event, "CREATE");
        }
        if (!clone) {
          this.showNotification(
            this._EventIconMappingService.getBlockDisplayName(event.nodeType) +
              " " +
              this._i18nDynamicTranslate.transform(
                "block has been added successfully",
                ["POS_PAGE"]
              ),
            true
          );
        }
      } else {
        if (!event["nextNodeId"]) {
          let previousNodeBlockId =
            this.nodeList[
              this.nodeList.findIndex((x) => x.nodeId === event.previousNodeId)
            ].nodeBlockId;
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeId === event.previousNodeId
                )
              ].nodeType
            ) === "BRANCHED"
          ) {
            if (
              this.addNodeEvent.srcElement.parentElement.className ===
              "add-node-right-branch"
            ) {
              document
                .getElementById(
                  "right-node" +
                    previousNodeBlockId.substring(4, previousNodeBlockId.length)
                )
                .getElementsByClassName("end-flag-right-branch")[0]
                .setAttribute("style", "display:flex");
            } else if (
              this.addNodeEvent.srcElement.parentElement.className ===
              "add-node-left -branch"
            ) {
              document
                .getElementById(
                  "left-node" +
                    previousNodeBlockId.substring(4, previousNodeBlockId.length)
                )
                .getElementsByClassName("end-flag-left-branch")[0]
                .setAttribute("style", "display:flex");
            } else {
              let i = "";
              if (event["previousNodeRelation"].substring(0, 5) === "SPLIT") {
                i = event["previousNodeRelation"].substring(
                  this.startIndex,
                  this.previousNodeRelation.length
                );
                document
                  .getElementById(
                    "end-flag-" + previousNodeBlockId + "-split-" + i
                  )
                  .setAttribute("style", "display :flex");
                document
                  .getElementById(
                    "wrap-split-" + previousNodeBlockId + "-id-" + i
                  )
                  .setAttribute("style", "height:40px");
              } else if (
                event["previousNodeRelation"].substring(0, 4) === "PATH"
              ) {
                i = event["previousNodeRelation"].substring(
                  6,
                  this.previousNodeRelation.length
                );
                document
                  .getElementById(
                    "end-flag-" + previousNodeBlockId + "-ab-" + i
                  )
                  .setAttribute("style", "display :flex");
                document
                  .getElementById("wrap-ab-" + previousNodeBlockId + "-id-" + i)
                  .setAttribute("style", "height:40px");
              }
            }
          }
          // else if( this.getNodeBranchType(
          //   this.nodeList[
          //     this.nodeList.findIndex(
          //       (x) => x.nodeId === event.previousNodeId
          //     )
          //   ].nodeType
          // ) === "GROUPBRANCH"){

          // }
          else {
            document
              .getElementById(previousNodeBlockId)
              .getElementsByClassName("end-flag")[0]
              .setAttribute("style", "display:flex");
          }
        }
        if (event["errer"]) {
          this.showNotification(
            this._EventIconMappingService.getBlockDisplayName(event.nodeType) +
              " " +
              this._i18nDynamicTranslate.transform("block addition failed", [
                "POS_PAGE",
              ]),
            false
          );
        }
      }
    } else {
      event["error"] ? null : (this.actionType = null);
      if (!event["delete"] && !event["error"]) {
        let outerElement = document.getElementById(this.activeNodeBlockId);
        let innerElement = outerElement.getElementsByClassName("node-block")[0];
        let element1 = innerElement.getElementsByClassName("content-1")[0];
        let element2 = innerElement.getElementsByClassName("content-2")[0];

        this.setNodeContent(
          element1,
          element2,
          innerElement.getElementsByClassName("node-content")[0],
          event
        );
        if (event.nodeType === "AUDIENCESPLIT") {
          this.setBranchContent(event, "AUDIENCESPLIT");
        }
        if (event.nodeType === "ABTESTING") {
          this.setBranchContent(event, "ABTESTING");
        }
        if (this.nodeList.length === 0) {
          this.addNodeDataToList(event);
          let buttonElement = outerElement.getElementsByClassName(
            "button-container-none"
          )[0];
          buttonElement.removeAttribute("class");
          buttonElement.setAttribute("class", "button-container");
          this.addTriggerNodeConfigElement();
          this.showNotification(
            this._EventIconMappingService.getBlockDisplayName(event.nodeType) +
              " " +
              this._i18nDynamicTranslate.transform(
                "block has been added successfully",
                ["POS_PAGE"]
              ),
            true
          );
        } else {
          this.nodeList[
            this.nodeList.findIndex((x) => x.nodeId === event.nodeId)
          ].nodeData = event.nodeData;
          if (event.nodeType === "AUDIENCESPLIT") {
            this.changeNextNodeAfterEdit(event);
          }
          this.updateStrategy();
          this.showNotification(
            this._EventIconMappingService.getBlockDisplayName(event.nodeType) +
              " " +
              this._i18nDynamicTranslate.transform("block updated", [
                "POS_PAGE",
              ]),
            true
          );
        }
      } else {
        if (this.nodeList.length === 0) {
          if (event["error"]) {
            this.showNotification(
              this._EventIconMappingService.getBlockDisplayName(
                event.nodeType
              ) +
                " " +
                this._i18nDynamicTranslate.transform("block Addition failed", [
                  "POS_PAGE",
                ]),
              false
            );
          }
          this.removeNodeFromView();
          this.addDummyNode();
        } else {
          if (event["error"]) {
            this.showNotification(
              this._EventIconMappingService.getBlockDisplayName(
                event.nodeType
              ) +
                " " +
                this._i18nDynamicTranslate.transform("block updation failed", [
                  "POS_PAGE",
                ]),
              false
            );
          }
        }
      }
      // if (event["nodeOperation"] === "CREATE") {
      //   if (event["delete"]) {
      //     let dummyElement = document.getElementById("dummy");
      //     if (dummyElement) {
      //       dummyElement.parentElement.removeChild(dummyElement);
      //     }
      //     this.removeNodeFromView();
      //     if (event["previousNodeId"] === null) {
      //       this.addDummyNode();
      //     }
      //   } else {
      //     this.addNodeDataToList(event);
      //     this.addNodeDataToView(event);
      //     let nodeBlockElement = document.getElementById(
      //       this.activeNodeBlockId
      //     );
      //     let buttonElement = nodeBlockElement.getElementsByClassName(
      //       "button-container"
      //     )[0];
      //     buttonElement.removeAttribute("class");
      //     buttonElement.setAttribute("class", "button-container-block");
      //     let nodeElement = nodeBlockElement.getElementsByClassName(
      //       "node-block"
      //     ) as HTMLCollectionOf<HTMLElement>;
      //     nodeElement[0].style.cursor = "pointer";
      //     const nodeBlockId = this.activeNodeBlockId;
      //     nodeElement[0].addEventListener("click", (event) =>
      //       this.updateNodeData(event, nodeBlockId)
      //     );
      //     if (event["nextNodeId"]) {
      //       this.nodeList[
      //         this.nodeList.findIndex((x) => x.nodeId === event["nextNodeId"])
      //       ].previousNodeId = event["nodeId"];
      //     }
      //   }
      //   this.nextNodeId = null;
      // } else {
      //   if (!event["delete"]) {
      //     let outerElement = document.getElementById(this.activeNodeBlockId);
      //     let innerElement = outerElement.getElementsByClassName(
      //       "node-block"
      //     )[0];
      //     let element1 = innerElement.getElementsByClassName("content-1")[0];
      //     let element2 = innerElement.getElementsByClassName("content-2")[0];
      //     this.setNodeContent(element1, element2, null, event);
      //   }
      // }
    }
  }

  addNodeDataOnCreate(event, operation) {
    operation === "CREATE" ? this.addNodeDataToList(event) : null;
    this.addNodeDataToView(event);
    let nodeBlockElement = document.getElementById(this.activeNodeBlockId);
    // let buttonElement = nodeBlockElement.getElementsByClassName(
    //   "button-container"
    // )[0];
    // buttonElement.removeAttribute("class");
    // buttonElement.setAttribute("class", "button-container-block");
    let nodeElement = nodeBlockElement.getElementsByClassName(
      "node-block"
    ) as HTMLCollectionOf<HTMLElement>;
    //nodeElement[0].style.cursor = "pointer";
    const nodeBlockId = this.activeNodeBlockId;
    // nodeElement[0].addEventListener("click", (event) =>
    //   this.updateNodeData(event, nodeBlockId)
    // );
    if (event["nextNodeId"]) {
      this.nodeList[
        this.nodeList.findIndex((x) => x.nodeId === event["nextNodeId"])
      ].previousNodeId = event["nodeId"];
    }
    if (!(operation === "CREATE" && event["nodeType"] === "TRIGGER")) {
      let classAndId =
        this.getNodeBranchType(event["nodeType"]) === "UNBRANCHED"
          ? event["nodeType"] === "TRIGGER"
            ? "trigger-node-config"
            : "node-config"
          : "unconditional-node-config";
      let nodeConfigElement = document.createElement("div");
      nodeConfigElement.addEventListener("click", (event) =>
        this.nodeOperations(event, nodeBlockId)
      );
      nodeConfigElement.addEventListener("mouseenter", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          true,
          nodeBlockId,
          "node-config"
        )
      );
      nodeConfigElement.addEventListener("mouseleave", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          false,
          nodeBlockId,
          "node-config"
        )
      );
      nodeConfigElement.setAttribute("class", "node-config");
      nodeConfigElement.innerHTML =
        document.getElementById(classAndId).innerHTML;
      // let nodeNoteElement = document.createElement("div");
      // nodeNoteElement.addEventListener("click", (event) =>
      //   this.addNodeNote(event, nodeBlockId)
      // );
      // nodeNoteElement.setAttribute("class", "node-note");
      // nodeNoteElement.innerHTML = document.getElementById(
      //   "node-note"
      // ).innerHTML;
      // nodeElement[0].appendChild(nodeNoteElement);
      nodeElement[0].appendChild(nodeConfigElement);
    }
  }

  addTriggerNodeConfigElement() {
    const nodeBlockId = this.activeNodeBlockId;
    let nodeBlockElement = document.getElementById(this.activeNodeBlockId);
    let nodeElement = nodeBlockElement.getElementsByClassName(
      "node-block"
    ) as HTMLCollectionOf<HTMLElement>;
    let classAndId = "trigger-node-config";
    let nodeConfigElement = document.createElement("div");
    nodeConfigElement.addEventListener("click", (event) =>
      this.nodeOperations(event, nodeBlockId)
    );
    nodeConfigElement.addEventListener("mouseenter", (event) =>
      this.showOrHideBlockedOperationTooltip(
        event,
        true,
        nodeBlockId,
        "node-config"
      )
    );
    nodeConfigElement.addEventListener("mouseleave", (event) =>
      this.showOrHideBlockedOperationTooltip(
        event,
        false,
        nodeBlockId,
        "node-config"
      )
    );
    nodeConfigElement.setAttribute("class", "node-config");
    nodeConfigElement.innerHTML = document.getElementById(classAndId).innerHTML;
    // let nodeNoteElement = document.createElement("div");
    // nodeNoteElement.addEventListener("click", (event) =>
    //   this.addNodeNote(event, nodeBlockId)
    // );
    // nodeNoteElement.setAttribute("class", "node-note");
    // nodeNoteElement.innerHTML = document.getElementById("node-note").innerHTML;
    // nodeElement[0].appendChild(nodeNoteElement);
    nodeElement[0].appendChild(nodeConfigElement);
  }

  showOrHideBlockedOperationTooltip(
    event,
    status: boolean,
    nodeBlockId,
    className
  ) {
    let nodeElement = document.getElementById(nodeBlockId);
    let buttonElement = nodeElement.getElementsByClassName(className)[0];
    let toolTipElement = buttonElement.getElementsByClassName(
      "operation-blocker-tooltip"
    )[0];
    if (this.actionType) {
      if (status) {
        toolTipElement.setAttribute("style", "display:block");
      } else {
        toolTipElement.setAttribute("style", "display:none");
      }
    }
  }

  addNodeNoteButton(event, nodeBlockId, operation) {
    let nodeElement = document
      .getElementById(nodeBlockId)
      .getElementsByClassName("node-block");
    if (nodeElement[0].getElementsByClassName("node-note").length < 1) {
      let nodeNoteElement = document.createElement("div");
      nodeNoteElement.addEventListener("click", (event) =>
        this.addNodeNote(event, nodeBlockId)
      );
      nodeNoteElement.setAttribute("class", "node-note");
      nodeNoteElement.innerHTML =
        document.getElementById("node-note").innerHTML;
      nodeElement[0].appendChild(nodeNoteElement);
    }
    if (operation === "CREATE") {
      this.addNodeNote(event, nodeBlockId);
    }
  }

  addNodeNote(event, nodeBlockId) {
    this.hideAllEvents();
    event.stopPropagation();
    this.activeNodeNoteBlockId = nodeBlockId;
    let element = document.getElementById(nodeBlockId);
    let popupElement = element.getElementsByClassName(
      "node-note-input"
    ) as HTMLCollectionOf<HTMLElement>;
    popupElement[0].style.display = "block";
    let textElement = element.getElementsByClassName(
      "note-text"
    )[0] as HTMLTextAreaElement;
    if (
      !(
        event.srcElement.innerHTML === "Cancel" ||
        event.srcElement.innerHTML === "Save"
      )
    ) {
      textElement.value =
        this.nodeList[
          this.nodeList.findIndex((x) => x.nodeBlockId === nodeBlockId)
        ].nodeNote;
    }
    let buttonElement = document
      .getElementById(nodeBlockId)
      .getElementsByClassName("node-note");
    let elementIndex = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nodeBlockId
    );
    if (event.srcElement.innerHTML === "Cancel") {
      popupElement[0].style.display = "none";
      if (this.nodeList[elementIndex].nodeNote.length === 0) {
        buttonElement[0].parentElement.removeChild(buttonElement[0]);
      }
    }
    if (event.srcElement.innerHTML === "Save") {
      let content = textElement.value;
      this.nodeNoteClose(content, nodeBlockId);
      popupElement[0].style.display = "none";
      if (textElement.value.length === 0) {
        buttonElement[0].parentElement.removeChild(buttonElement[0]);
      }
    }
  }

  nodeNoteClose(content, nodeBlockId) {
    let elementIndex = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nodeBlockId
    );
    this.nodeList[elementIndex].nodeNote = content;
    this.updateStrategy();
    if (content.length !== 0) {
      this.showNotification(
        this._i18nDynamicTranslate.transform(
          "Note has been added successfully to",
          ["POS_PAGE"]
        ) +
          " " +
          this._EventIconMappingService.getBlockDisplayName(
            this.nodeList[elementIndex].nodeType
          ) +
          " " +
          this._i18nDynamicTranslate.transform("block", ["POS_PAGE"]),
        true
      );
    }
  }

  removeNodeFromView() {
    let element = document.getElementById(this.activeNodeBlockId);
    let previousNode = element.previousElementSibling;
    let parent = element.parentElement;
    parent.removeChild(element);
    if (previousNode) {
      if (previousNode.id) {
        // let deleteElement = document.createElement("div");
        // deleteElement.setAttribute("class", "add-node");
        // deleteElement.innerHTML = document.getElementById(
        //   "add-button"
        // ).innerHTML;
        // deleteElement.addEventListener("click", (event) =>
        //   this.addNewNode(event, "UNBRANCHED")
        // );
        // previousNode.appendChild(deleteElement);
      } else {
        let childElement = previousNode.firstElementChild;
        if (childElement.className === "node-left") {
          // let deleteElement = document.createElement("div");
          // deleteElement.setAttribute("class", "add-node-left-branch");
          // deleteElement.innerHTML = document.getElementById(
          //   "add-button"
          // ).innerHTML;
          // deleteElement.addEventListener("click", (event) =>
          //   this.addNewNode(event, "BRANCHED")
          // );
          // childElement.appendChild(deleteElement);
        } else {
          // let deleteElement = document.createElement("div");
          // deleteElement.setAttribute("class", "add-node-right-branch");
          // deleteElement.innerHTML = document.getElementById(
          //   "add-button"
          // ).innerHTML;
          // deleteElement.addEventListener("click", (event) =>
          //   this.addNewNode(event, "BRANCHED")
          // );
          // childElement.appendChild(deleteElement);
        }
      }
    }
  }

  le = null;
  setNodeContent(span1, span2, parentElement, triggerEvent) {
    parentElement.innerHTML = null;
    console.log("trigger-event", triggerEvent);
    switch (triggerEvent["nodeType"]) {
      case "WAIT":
        let delayNodeElement = document.createElement("div");
        delayNodeElement.setAttribute("class", "delay-node-content");
        delayNodeElement.innerHTML =
          document.getElementById("delay-node-content").innerHTML;
        delayNodeElement.getElementsByClassName(
          "delay-node-content-time"
        )[0].innerHTML = this.getTimeAsString(
          triggerEvent["nodeData"]["definition"]["timeoutValue"]
        );
        parentElement.append(delayNodeElement);
        break;
      case "CHANNEL":
        if (triggerEvent["nodeData"]["definition"]["channelType"] === "EMAIL") {
          let emailNodeElement = document.createElement("div");
          emailNodeElement.setAttribute("class", "email-node-content");
          emailNodeElement.innerHTML =
            document.getElementById("email-node-content").innerHTML;
          emailNodeElement.getElementsByClassName(
            "email-node-content-subject"
          )[0].innerHTML = triggerEvent["nodeData"]["definition"]["subject"];
          let emailTemplateName = emailNodeElement.getElementsByClassName(
            "email-node-template-name"
          )[0];
          emailTemplateName.innerHTML = this.getTemplateName(
            triggerEvent["nodeData"]["definition"]["channelType"],
            triggerEvent["nodeData"]["definition"]["emailLayoutId"],
            triggerEvent["nodeData"]["definition"]["emailTemplateName"]
          );
          emailTemplateName.addEventListener("click", (event) => {
            this.showTemplatePreview(event, triggerEvent["nodeData"]);
          });
          parentElement.append(emailNodeElement);
        }
        if (triggerEvent["nodeData"]["definition"]["channelType"] === "SMS") {
          let smsNodeElement = document.createElement("div");
          smsNodeElement.setAttribute("class", "sms-node-content");
          smsNodeElement.innerHTML =
            document.getElementById("sms-node-content").innerHTML;
          let smsTemplateName = smsNodeElement.getElementsByClassName(
            "sms-node-template-name"
          )[0];
          smsTemplateName.innerHTML = this.getTemplateName(
            triggerEvent["nodeData"]["definition"]["channelType"],
            triggerEvent["nodeData"]["definition"]["layoutId"],
            triggerEvent["nodeData"]["definition"]["smsTemplateName"]
          );
          smsTemplateName.addEventListener("click", (event) => {
            this.showTemplatePreview(event, triggerEvent["nodeData"]);
          });
          parentElement.append(smsNodeElement);
        }
        if (
          triggerEvent["nodeData"]["definition"]["channelType"] === "WHATSAPP"
        ) {
          let whatsappNodeElement = document.createElement("div");
          whatsappNodeElement.setAttribute("class", "whatsapp-node-content");
          whatsappNodeElement.innerHTML = document.getElementById(
            "whatsapp-node-content"
          ).innerHTML;
          //   whatsappNodeElement.getElementsByClassName(
          //   "whatsapp-node-content-subject"
          // )[0].innerHTML = triggerEvent["nodeData"]["definition"]["subject"];
          let whatsappTemplateName = whatsappNodeElement.getElementsByClassName(
            "whatsapp-node-template-name"
          )[0];
          whatsappTemplateName.innerHTML = this.getTemplateName(
            triggerEvent["nodeData"]["definition"]["channelType"],
            triggerEvent["nodeData"]["definition"]["layoutId"],
            triggerEvent["nodeData"]["definition"]["smsTemplateName"]
          );
          whatsappTemplateName.addEventListener("click", (event) => {
            this.showWhatsAppTemplate(event, triggerEvent["nodeData"]);
          });
          parentElement.append(whatsappNodeElement);
        }
        break;
      case "OFFER":
        let offerNodeElement = document.createElement("div");
        offerNodeElement.setAttribute("class", "offer-node-content");
        offerNodeElement.innerHTML =
          document.getElementById("offer-node-content").innerHTML;
        let offerEmailLayoutId = offerNodeElement.getElementsByClassName(
          "offer-node-content-subject"
        )[0];
        offerEmailLayoutId.innerHTML = "Channel template";
        let offerTemplateName = offerNodeElement.getElementsByClassName(
          "offer-node-template-name"
        )[0];
        offerTemplateName.innerHTML = this.getTemplateName(
          triggerEvent["nodeType"],
          triggerEvent["nodeData"]["definition"]["offerId"],
          triggerEvent["nodeData"]["definition"]["offerTemplateName"]
        );
        offerTemplateName.addEventListener("click", (event) => {
          this.showTemplatePreview(event, triggerEvent["nodeData"]);
        });
        offerEmailLayoutId.addEventListener("click", (event) => {
          this.getOfferDetails(
            triggerEvent["nodeData"]["definition"]["offerId"]
          );
        });
        parentElement.append(offerNodeElement);
        break;
      case "CUSTOM_AUDIENCE":
        let audienceNodeElement = document.createElement("div");
        audienceNodeElement.setAttribute("class", "audience-node-content");
        audienceNodeElement.innerHTML = document.getElementById(
          "audience-node-content"
        ).innerHTML;
        let audienceId = audienceNodeElement.getElementsByClassName(
          "audience-node-content-subject"
        )[0];
        audienceId.innerHTML = "Audience Id";
        let audienceIdValue = audienceNodeElement.getElementsByClassName(
          "audience-node-template-name"
        )[0];
        audienceIdValue.innerHTML =
          triggerEvent["nodeData"]["definition"]["audienceId"];
        let audienceNameTitle = audienceNodeElement.getElementsByClassName(
          "audience-node-content-subject1"
        )[0];
        audienceNameTitle.innerHTML = "Audience Name";
        let audienceName = audienceNodeElement.getElementsByClassName(
          "audience-node-template-name1"
        )[0];
        audienceName.innerHTML =
          triggerEvent["nodeData"]["definition"]["audienceName"];
        parentElement.append(audienceNodeElement);
        break;
      case "SURVEY":
        let surveyNodeElement = document.createElement("div");
        surveyNodeElement.setAttribute("class", "survey-node-content");
        surveyNodeElement.innerHTML = document.getElementById(
          "survey-node-content"
        ).innerHTML;
        let surveyEmailNodeData = {
          nodeType: "SURVEYEMAIL",
          definition: {
            channelType: "EMAIL",
            emailLayoutId: triggerEvent["nodeData"]["definition"]["layoutId"],
          },
        };
        let surveyTemplateName = surveyNodeElement.getElementsByClassName(
          "survey-node-template-name"
        )[0];
        surveyTemplateName.innerHTML = this.getTemplateName(
          triggerEvent["nodeType"],
          triggerEvent["nodeData"]["definition"]["surveyTemplateId"],
          triggerEvent["nodeData"]["definition"]["surveyTemplateName"]
        );
        surveyTemplateName.addEventListener("click", (event) => {
          this.showTemplatePreview(event, triggerEvent["nodeData"]);
        });
        parentElement.append(surveyNodeElement);
        break;
      case "RESPONSE_EVENT23":
        let unconditionalNodeElement = document.createElement("div");
        unconditionalNodeElement.setAttribute(
          "class",
          "unconditional-node-content"
        );
        unconditionalNodeElement.innerHTML = document.getElementById(
          "unconditional-node-content"
        ).innerHTML;
        unconditionalNodeElement.getElementsByClassName(
          "unconditional-node-content-icon"
        )[0].innerHTML = document.getElementById(
          this._EventIconMappingService.getEventIconName(
            triggerEvent["nodeData"]["definition"]["event"]
          )
        ).innerHTML;
        unconditionalNodeElement.getElementsByClassName(
          "unconditional-node-content-event-name"
        )[0].innerHTML = this._i18nDynamicTranslate.transform(
          this._EventIconMappingService.getEventLabel(
            triggerEvent["nodeData"]["definition"]["event"],
            this.store
          ),
          ["POS_PAGE"]
        );
        unconditionalNodeElement.getElementsByClassName(
          "unconditional-node-content-event-time"
        )[0].innerHTML = this.getTimeAsString(
          triggerEvent["nodeData"]["definition"]["timeoutValue"]
        );
        parentElement.append(unconditionalNodeElement);
        break;
      case "RESPONSE_EVENT":
        let responseConditionElement;
        for (
          let i = 0;
          i <
          triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"]
            .length;
          i++
        ) {
          let triggerGroup = document.createElement("div");
          triggerGroup.setAttribute("class", "trigger-group");
          for (
            let j = 0;
            j <
            triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"][
              i
            ]["conditions"].length;
            j++
          ) {
            responseConditionElement = document.createElement("div");
            responseConditionElement.setAttribute("class", "trigger-condition");
            responseConditionElement.innerHTML = document.getElementById(
              "unconditional-node-content"
            ).innerHTML;
            let conditionCountElement = document.createElement("span");
            conditionCountElement.innerHTML =
              this._i18nDynamicTranslate.transform(
                this._EventIconMappingService.getEventLabel(
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"],
                  this.store
                ),
                ["POS_PAGE"]
              );
            responseConditionElement
              .getElementsByClassName("response-condition-count")[0]
              .append(conditionCountElement);
            let triggerConditionToggle =
              responseConditionElement.getElementsByClassName(
                "response-condition-toggle"
              )[0];
            triggerConditionToggle.innerHTML = document.getElementById(
              "keyboard_arrow_down"
            ).innerHTML;
            triggerConditionToggle.addEventListener("click", (event) => {
              this.showOrHideTriggerContent(event);
            });

            // let unconditionalNodeElement = document.createElement("div");
            // unconditionalNodeElement.setAttribute(
            //   "class",
            //   "trigger-condition-content"
            // );
            // unconditionalNodeElement.innerHTML = document.getElementById(
            //   "response-node-content"
            // ).innerHTML;
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"] != "segment"
            ) {
              responseConditionElement.getElementsByClassName(
                "unconditional-node-content-icon"
              )[0].innerHTML = document.getElementById(
                this._EventIconMappingService.getEventIconName(
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"]
                )
              ).innerHTML;
              responseConditionElement.getElementsByClassName(
                "if-customer"
              )[0].innerHTML = "If the customer";
              responseConditionElement.getElementsByClassName(
                "unconditional-node-content-event-name"
              )[0].innerHTML = this._i18nDynamicTranslate.transform(
                this._EventIconMappingService.getEventLabel(
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"],
                  this.store
                ),
                ["POS_PAGE"]
              );
              responseConditionElement.getElementsByClassName(
                "by"
              )[0].innerHTML = "by";
              responseConditionElement.getElementsByClassName(
                "unconditional-node-content-event-time"
              )[0].innerHTML = this.getTimeAsString(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["EVENT"]["ttl"]["timeoutValue"]
              );
            } else {
              // responseConditionElement.getElementsByClassName(
              //   "unconditional-node-content-icon-segment"
              // )[0].innerHTML = document.getElementById(
              //   this._EventIconMappingService.getEventIconName(
              //     triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["event"]
              //   )
              // ).innerHTML;
              responseConditionElement.getElementsByClassName(
                "select-segment"
              )[0].innerHTML = "Selected Segment";
              let segmentName = [];
              let segment = [];
              for (
                let k = 0;
                k <
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["EVENT"]["segments"].length;
                k++
              ) {
                if (
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["EVENT"]["segments"][k] ===
                  "ALL"
                ) {
                  segmentName.push("All customers");
                  break;
                } else {
                  // for (let i = 0; i < segmentIds.length; i++) {
                  segment.push(
                    this.customerSegments[
                      this.customerSegments.findIndex(
                        (x) =>
                          x.id ===
                          triggerEvent["nodeData"]["definition"]["config"][
                            "conditionGroups"
                          ][i]["conditions"][j]["rules"]["EVENT"]["segments"][k]
                      )
                    ]
                  );
                  // if(segment)
                  // segmentName.push(segment[k].segmentName)
                  // }
                }
              }
              if (segment[0] != undefined)
                for (let i = 0; i < segment.length; i++) {
                  segmentName.push(segment[i]["segmentName"]);
                }
              let eventName = document.createElement("span");
              eventName.innerHTML = segmentName.toString();
              responseConditionElement
                .getElementsByClassName("trigger-event-name")[0]
                .append(eventName);
            }
            //check if node has additional config/filters and render them inside div
            //nth lifetime order count also is stored as config but is displayed as just number
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["CUSTOM"] &&
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["CUSTOM"]?.logicalExpressions
                ?.length >= 1
            ) {
              let leView = document.createElement("div");
              let data =
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["CUSTOM"] &&
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["CUSTOM"];
              let innerHtml = "";

              data.logicalExpressions.forEach((le) => {
                innerHtml += `<div class='le'>`;
                if (le["logicalOperator"])
                  innerHtml += `<div class='le-lo-container'><div class='le-lo'>${le.logicalOperator}</div></div>`;
                le.comparisonStatements.forEach((cs) => {
                  innerHtml += `<div class='filter-cs'>`;
                  if (cs["logicalOperator"])
                    innerHtml += `<div class='cs-lo-container'><div class='cs-lo'>${cs.logicalOperator}</div></div>`;
                  innerHtml += `<div class='filter-cs-pair'>
                        <div class='filter-label'>Parameter</div>
                        <div class='filter-val'>${cs.operand1Display}</div>
                      </div>
                      <div class='filter-cs-pair'>
                        <div class='filter-label'>Condition</div>
                        <div class='filter-val'>${cs.operator}</div>
                      </div>
                      <div class='filter-cs-pair'>
                        <div class='filter-label'>Value</div>`;

                  if (cs["operand2Display"]) {
                    innerHtml += `<div class='filter-val'>${cs.operand2Display}</div>`;
                  } else {
                    innerHtml += `<div class='filter-val'>${cs.operand2}</div>`;
                  }
                  innerHtml += `</div>
                    </div>`;
                });
                innerHtml += `</div>`;
              });
              leView.innerHTML = innerHtml;
              let ele =
                responseConditionElement.getElementsByClassName(
                  "le-display"
                )[0];
              ele.append(leView);
            }
            triggerGroup.append(responseConditionElement);
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"].length -
                1 !==
              j
            ) {
              let conditionOperatorDiv = document.createElement("div");
              conditionOperatorDiv.setAttribute(
                "class",
                "trigger-condition-operator-div"
              );
              let conditionOperatorSpan = document.createElement("span");
              conditionOperatorSpan.setAttribute(
                "class",
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditionsRelation"] === "OR"
                  ? "trigger-condition-operator-span-OR"
                  : "trigger-condition-operator-span-AND"
              );
              conditionOperatorSpan.innerHTML =
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditionsRelation"];
              let connector = document.createElement("div");
              connector.setAttribute("class", "trigger-condition-connector");
              conditionOperatorDiv.append(connector);
              conditionOperatorDiv.append(conditionOperatorSpan);
              triggerGroup.append(conditionOperatorDiv);
            }
            parentElement.append(triggerGroup);
          }
          if (
            triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"]
              .length -
              1 !==
            i
          ) {
            let groupOperatorDiv = document.createElement("div");
            groupOperatorDiv.setAttribute(
              "class",
              "trigger-group-operator-div"
            );
            let groupOperatorSpan = document.createElement("span");
            groupOperatorSpan.setAttribute(
              "class",
              "trigger-group-operator-span"
            );
            groupOperatorSpan.innerHTML =
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditionGroupRelation"];
            groupOperatorDiv.append(groupOperatorSpan);
            parentElement.append(groupOperatorDiv);
          }
        }
        if (
          triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"]
            .length === 1 &&
          triggerEvent["nodeData"]["definition"]["config"][
            "conditionGroups"
          ][0]["conditions"].length === 1
        ) {
          responseConditionElement.setAttribute(
            "style",
            "width:auto; left:0px;"
          );
        }
        break;
      case "TRIGGER":
        let triggerConditionElement;
        for (
          let i = 0;
          i <
          triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"]
            .length;
          i++
        ) {
          let triggerGroup = document.createElement("div");
          triggerGroup.setAttribute("class", "trigger-group");
          for (
            let j = 0;
            j <
            triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"][
              i
            ]["conditions"].length;
            j++
          ) {
            let eventType = this.getEventKey(triggerEvent["nodeData"]["definition"]["config"][
              "conditionGroups"
            ][i]["conditions"][j]);
            triggerConditionElement = document.createElement("div");
            triggerConditionElement.setAttribute("class", "trigger-condition");
            triggerConditionElement.innerHTML =
              document.getElementById("trigger-condition").innerHTML;
            let conditionCountElement = document.createElement("span");
            // conditionCountElement.innerHTML =
            //   "Condition " +
            //   +(
            //     i *
            //       event["nodeData"]["definition"]["config"]["conditionGroups"][
            //         i
            //       ]["conditions"].length +
            //     j +
            //     1
            //   );
            conditionCountElement.innerHTML =
              this._i18nDynamicTranslate.transform(
                this._EventIconMappingService.getEventLabel(
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"],
                  this.store,
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"][eventType] &&
                    triggerEvent["nodeData"]["definition"]["config"][
                      "conditionGroups"
                    ][i]["conditions"][j]["rules"][eventType]
                      ?.logicalExpressions?.[0].comparisonStatements[0].operand2
                ),
                ["POS_PAGE"]
              );
            triggerConditionElement
              .getElementsByClassName("triggger-condition-count")[0]
              .append(conditionCountElement);
            let triggerConditionToggle =
              triggerConditionElement.getElementsByClassName(
                "trigger-condition-toggle"
              )[0];
            triggerConditionToggle.innerHTML = document.getElementById(
              "keyboard_arrow_down"
            ).innerHTML;
            triggerConditionToggle.addEventListener("click", (event) => {
              this.showOrHideTriggerContent(event);
            });
            triggerConditionElement.getElementsByClassName(
              "trigger-condition-icon"
            )[0].innerHTML = document.getElementById(
              this._EventIconMappingService.getEventIconName(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"]
              )
            ).innerHTML;
            triggerConditionElement
              .getElementsByClassName("trigger-node-customer-text")[0]
              .setAttribute(
                "style",
                (triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"] ===
                  "time_event" || triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"] ===
                    "lead_created")
                  ? "display:none"
                  : "display:flex"
              );
            let eventName = document.createElement("span");
            eventName.innerHTML = this._i18nDynamicTranslate.transform(
              this._EventIconMappingService.getEventLabel(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"],
                this.store,
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"][eventType] &&
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"][eventType]
                    ?.logicalExpressions?.[0].comparisonStatements[0].operand2
              ),
              ["POS_PAGE"]
            );
            triggerConditionElement
              .getElementsByClassName("trigger-event-name")[0]
              .append(eventName);
            let eventDateType = document.createElement("span");
            eventDateType.innerHTML =
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["DATE"]["operator"] === "BETWEEN"
                ? "from"
                : "on";
            triggerConditionElement
              .getElementsByClassName("trigger-date-type")[0]
              .append(eventDateType);
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["DATE"]["operator"] === "BETWEEN"
            ) {
              let eventStartDate = document.createElement("span");
              eventStartDate.innerHTML = this._dateFormatService.formatDate(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["DATE"]["ruleDates"][0]
              );
              triggerConditionElement
                .getElementsByClassName("trigger-start-date")[0]
                .append(eventStartDate);
              let eventDateSeperator = document.createElement("span");
              eventDateSeperator.innerHTML = "to";
              triggerConditionElement
                .getElementsByClassName("trigger-date-seperator")[0]
                .append(eventDateSeperator);
              let eventEndDate = document.createElement("span");
              eventEndDate.innerHTML = this._dateFormatService.formatDate(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["DATE"]["ruleDates"][1]
              );
              triggerConditionElement
                .getElementsByClassName("trigger-end-date")[0]
                .append(eventEndDate);
            } else {
              let eventEndDate = document.createElement("span");
              eventEndDate.innerHTML = this._dateFormatService.formatDate(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["DATE"]["ruleDates"][0]
              );
              triggerConditionElement
                .getElementsByClassName("trigger-end-date")[0]
                .append(eventEndDate);
            }
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["TIME"]
            ) {
              let eventTimeType = document.createElement("span");
              eventTimeType.innerHTML = "between";
              eventTimeType.innerHTML =
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["TIME"]["operator"] === "BETWEEN"
                  ? "between"
                  : "at";
              triggerConditionElement
                .getElementsByClassName("trigger-time-type")[0]
                .append(eventTimeType);
              let eventStartTime = document.createElement("span");
              eventStartTime.innerHTML = this.transformTo12HourFormat(
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["TIME"]["ruleTimes"][0]
              );
              triggerConditionElement
                .getElementsByClassName("trigger-start-time")[0]
                .append(eventStartTime);
              if (
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["TIME"]["ruleTimes"][1]
              ) {
                let eventTimeSeperator = document.createElement("span");
                eventTimeSeperator.innerHTML = "-";
                triggerConditionElement
                  .getElementsByClassName("trigger-time-seperator")[0]
                  .append(eventTimeSeperator);
                let eventEndTime = document.createElement("span");
                eventEndTime.innerHTML = this.transformTo12HourFormat(
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["TIME"]["ruleTimes"][1]
                );
                triggerConditionElement
                  .getElementsByClassName("trigger-end-time")[0]
                  .append(eventEndTime);
              }
            } else {
              if (
                triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.EVENT?.ruleEvent === "BIRTHDAY" ||
                triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.EVENT?.ruleEvent === "ANNIVERSARY"
              ) {
                let eventTimeType = document.createElement("span");
                if (
                  this.getCelebrationName(
                    triggerEvent.nodeData.definition.config.conditionGroups[i]
                      .conditions[j].rules.CUSTOM?.event,
                    triggerEvent.nodeData.definition.config.conditionGroups[i]
                      .conditions[j].rules.CUSTOM?.timeBeforeToSend
                  ) !== ""
                ) {
                  eventTimeType.innerHTML = "Scheduled for";
                  triggerConditionElement
                    .getElementsByClassName("trigger-time-type")[0]
                    .append(eventTimeType);
                  let eventStartTime = document.createElement("span");
                  eventStartTime.innerHTML = this.getCelebrationName(
                    triggerEvent.nodeData.definition.config.conditionGroups[i]
                      .conditions[j].rules.CUSTOM?.event,
                    triggerEvent.nodeData.definition.config.conditionGroups[i]
                      .conditions[j].rules.CUSTOM?.timeBeforeToSend
                  );
                  triggerConditionElement
                    .getElementsByClassName("trigger-start-time")[0]
                    .append(eventStartTime);
                }
              } else {
                let eventTimeType = document.createElement("span");
                eventTimeType.innerHTML = "at";
                triggerConditionElement
                  .getElementsByClassName("trigger-time-type")[0]
                  .append(eventTimeType);
                let eventStartTime = document.createElement("span");
                eventStartTime.innerHTML = "Any Time";
                triggerConditionElement
                  .getElementsByClassName("trigger-start-time")[0]
                  .append(eventStartTime);
              }
            }
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["DAYS"]
            ) {
              let eventDayType = document.createElement("span");
              eventDayType.innerHTML = "on";
              triggerConditionElement
                .getElementsByClassName("trigger-day-type")[0]
                .append(eventDayType);
              let eventDays = "";
              for (
                let k = 0;
                k <
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["DAYS"]["daysRule"].length;
                k++
              ) {
                eventDays +=
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["DAYS"]["daysRule"][k];
                if (
                  k <
                  triggerEvent["nodeData"]["definition"]["config"][
                    "conditionGroups"
                  ][i]["conditions"][j]["rules"]["DAYS"]["daysRule"].length -
                    1
                ) {
                  eventDays += ", ";
                }
              }
              let eventTotalDays = document.createElement("span");
              eventTotalDays.innerHTML = eventDays;
              triggerConditionElement
                .getElementsByClassName("trigger-day")[0]
                .append(eventTotalDays);
            } else if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["DATE"]["operator"] === "BETWEEN"
            ) {
              if (
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"] !=
                  "time_event" &&
                triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.EVENT?.ruleEvent !== "BIRTHDAY" &&
                triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.EVENT?.ruleEvent !== "ANNIVERSARY" &&
                !triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.CUSTOM?.timeBeforeToSend
              ) {
                let eventDayType = document.createElement("span");
                eventDayType.innerHTML = "on";
                triggerConditionElement
                  .getElementsByClassName("trigger-day-type")[0]
                  .append(eventDayType);
                let eventTotalDays = document.createElement("span");
                eventTotalDays.innerHTML = "Any Day";
                triggerConditionElement
                  .getElementsByClassName("trigger-day")[0]
                  .append(eventTotalDays);
              } else if (
                triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.EVENT?.ruleEvent === "APPOINTMENT" &&
                triggerEvent.nodeData.definition.config.conditionGroups[i]
                  .conditions[j].rules.CUSTOM?.timeBeforeToSend
              ) {
                let eventTimeType = document.createElement("span");
                eventTimeType.innerHTML = "Scheduled for";
                triggerConditionElement
                  .getElementsByClassName("trigger-day-type")[0]
                  .append(eventTimeType);
                let eventStartTime = document.createElement("span");
                eventStartTime.innerHTML = this.getAppointmentName(
                  triggerEvent.nodeData.definition.config.conditionGroups[i]
                    .conditions[j].rules.CUSTOM?.event,
                  triggerEvent.nodeData.definition.config.conditionGroups[i]
                    .conditions[j].rules.CUSTOM?.timeBeforeToSend
                );
                triggerConditionElement
                  .getElementsByClassName("trigger-day")[0]
                  .append(eventStartTime);
              }
            }

            //check if node has additional config/filters and render them inside div
            //nth lifetime order count also is stored as config but is displayed as just number
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"][eventType] &&
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"][eventType]?.logicalExpressions
                ?.length >= 1 &&
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["EVENT"].ruleEvent !=
                "nth lifetime order count"
            ) {
              let leView = document.createElement("div");
              let data =
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"][eventType] &&
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"][eventType];
              let innerHtml = "";

              data.logicalExpressions.forEach((le) => {
                innerHtml += `<div class='le'>`;
                if (le["logicalOperator"])
                  innerHtml += `<div class='le-lo-container'><div class='le-lo'>${le.logicalOperator}</div></div>`;
                le.comparisonStatements.forEach((cs) => {
                  innerHtml += `<div class='filter-cs'>`;
                  if (cs["logicalOperator"])
                    innerHtml += `<div class='cs-lo-container'><div class='cs-lo'>${cs.logicalOperator}</div></div>`;
                  innerHtml += `<div class='filter-cs-pair'>
                        <div class='filter-label'>Parameter</div>
                        <div class='filter-val'>${cs.operand1Display}</div>
                      </div>
                      <div class='filter-cs-pair'>
                        <div class='filter-label'>Condition</div>
                        <div class='filter-val'>${cs.operator}</div>
                      </div>
                      <div class='filter-cs-pair'>
                        <div class='filter-label'>Value</div>`;
                  if (cs["operand2Display"]) {
                    innerHtml += `<div class='filter-val'>${cs.operand2Display}</div>`;
                  } else {
                    innerHtml += `<div class='filter-val'>${cs.operand2}</div>`;
                  }
                  innerHtml += `</div>
                    </div>`;
                });
                innerHtml += `</div>`;
              });
              leView.innerHTML = innerHtml;
              let ele =
                triggerConditionElement.getElementsByClassName("le-display")[0];
              ele.append(leView);
            }
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"][j]["rules"]["EVENT"].ruleEvent ==
              "nth lifetime order count"
            ) {
              let leView = document.createElement("div");
              leView.innerHTML = `N Value: ${
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"][eventType] &&
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditions"][j]["rules"][eventType]?.logicalExpressions[0]
                  .comparisonStatements[0].operand2
              }`;
              let ele =
                triggerConditionElement.getElementsByClassName("le-display")[0];
              ele.append(leView);
            }
            triggerGroup.append(triggerConditionElement);
            if (
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditions"].length -
                1 !==
              j
            ) {
              let conditionOperatorDiv = document.createElement("div");
              conditionOperatorDiv.setAttribute(
                "class",
                "trigger-condition-operator-div"
              );
              let conditionOperatorSpan = document.createElement("span");
              conditionOperatorSpan.setAttribute(
                "class",
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditionsRelation"] === "OR"
                  ? "trigger-condition-operator-span-OR"
                  : "trigger-condition-operator-span-AND"
              );
              conditionOperatorSpan.innerHTML =
                triggerEvent["nodeData"]["definition"]["config"][
                  "conditionGroups"
                ][i]["conditionsRelation"];
              let connector = document.createElement("div");
              connector.setAttribute("class", "trigger-condition-connector");
              conditionOperatorDiv.append(connector);
              conditionOperatorDiv.append(conditionOperatorSpan);
              triggerGroup.append(conditionOperatorDiv);
            }
            parentElement.append(triggerGroup);
          }
          if (
            triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"]
              .length -
              1 !==
            i
          ) {
            let groupOperatorDiv = document.createElement("div");
            groupOperatorDiv.setAttribute(
              "class",
              "trigger-group-operator-div"
            );
            let groupOperatorSpan = document.createElement("span");
            groupOperatorSpan.setAttribute(
              "class",
              "trigger-group-operator-span"
            );
            groupOperatorSpan.innerHTML =
              triggerEvent["nodeData"]["definition"]["config"][
                "conditionGroups"
              ][i]["conditionGroupRelation"];
            groupOperatorDiv.append(groupOperatorSpan);
            parentElement.append(groupOperatorDiv);
          }
        }
        if (
          triggerEvent["nodeData"]["definition"]["config"]["conditionGroups"]
            .length === 1 &&
          triggerEvent["nodeData"]["definition"]["config"][
            "conditionGroups"
          ][0]["conditions"].length === 1
        ) {
          triggerConditionElement.setAttribute(
            "style",
            "width:auto; left:0px;"
          );
        }
        break;

      case "AUDIENCESPLIT":
        for (
          let j = 0;
          j < triggerEvent["nodeData"]["definition"]["groupName"].length;
          j++
        ) {
          let triggerGroup = document.createElement("div");
          triggerGroup.setAttribute("class", "trigger-group");
          triggerConditionElement = document.createElement("div");
          triggerConditionElement.setAttribute("class", "trigger-condition");
          triggerConditionElement.innerHTML =
            document.getElementById("split-node-content").innerHTML;
          let conditionCountElement = document.createElement("span");
          conditionCountElement.innerHTML =
            triggerEvent["nodeData"]["definition"]["groupName"][j];
          conditionCountElement.setAttribute(
            "title",
            triggerEvent["nodeData"]["definition"]["groupName"][j]
          );
          triggerConditionElement
            .getElementsByClassName("split-condition-count")[0]
            .append(conditionCountElement);
          triggerGroup.append(triggerConditionElement);
          let name = triggerEvent["nodeData"]["definition"]["groupName"][j];
          if (
            triggerEvent["nodeData"]["definition"]["groupName"][j] !==
            "Everyone else"
          ) {
            let triggerConditionToggle =
              triggerConditionElement.getElementsByClassName(
                "split-condition-toggle"
              )[0];
            triggerConditionToggle.innerHTML = document.getElementById(
              "keyboard_arrow_down"
            ).innerHTML;
            triggerConditionToggle.addEventListener("click", (event) => {
              this.showOrHideTriggerContent(event);
            });
            triggerConditionElement
              .getElementsByClassName("trigger-node-customer-text")[0]
              .setAttribute("style", "display:flex");
            let segmentName = [];
            let segment = [];
            if (
              triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                "segments"
              ]
            ) {
              for (
                let k = 0;
                k <
                triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                  "segments"
                ].length;
                k++
              ) {
                if (
                  triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                    "segments"
                  ][j] === "ALL"
                ) {
                  segmentName.push("All customers");
                  break;
                } else {
                  // for (let i = 0; i < segmentIds.length; i++) {
                  segment.push(
                    this.customerSegmentAndTiers[
                      this.customerSegmentAndTiers.findIndex(
                        (x) =>
                          x.id ===
                          triggerEvent["nodeData"]["definition"]["splitGroups"][
                            name
                          ]["segments"][k]
                      )
                    ]
                  );
                  // if(segment)
                  // segmentName.push(segment[k].segmentName)
                  // }
                }
              }
            }
            if (
              triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                "customerList"
              ]
            ) {
              console.log(this.customerSegmentAndTiers);
              for (
                let p = 0;
                p <
                triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                  "customerList"
                ].length;
                p++
              ) {
                console.log(
                  triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                    "customerList"
                  ]
                );
                segment.push(
                  this.customerSegmentAndTiers[
                    this.customerSegmentAndTiers.findIndex(
                      (x) =>
                        x.id ===
                        triggerEvent["nodeData"]["definition"]["splitGroups"][
                          name
                        ]["customerList"][p]
                    )
                  ]
                );
              }
            }
            if (
              triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                "tiers"
              ]
            ) {
              console.log(this.customerSegmentAndTiers);
              for (
                let p = 0;
                p <
                triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                  "tiers"
                ].length;
                p++
              ) {
                console.log(
                  triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                    "tiers"
                  ]
                );
                segment.push(
                  this.customerSegmentAndTiers[
                    this.customerSegmentAndTiers.findIndex(
                      (x) =>
                        x.id ===
                        triggerEvent["nodeData"]["definition"]["splitGroups"][
                          name
                        ]["tiers"][p]
                    )
                  ]
                );
              }
            }
            if (segment[0] != undefined)
              for (let i = 0; i < segment.length; i++) {
                segmentName.push(segment[i]["segmentName"]);
              }
            let eventName = document.createElement("div");
            eventName.innerHTML = segmentName.toString();
            const parent =
              triggerConditionElement.getElementsByClassName(
                "trigger-event-name"
              )[0];
            parent.append(eventName);
            //add filter preview if present
            if (
              triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                "filter"
              ].hasOwnProperty("segmentDefinition") > 0
            ) {
              let filterDiv = document.createElement("div");
              let innerHtml = `<div class='filter-title'>Applied Filter:</div>`;
              let filterData =
                triggerEvent["nodeData"]["definition"]["splitGroups"][name][
                  "filter"
                ];
              filterData.segmentDefinition.logicalExpressions.forEach((le) => {
                innerHtml += `<div class='le'>`;
                if (le["logicalOperator"])
                  innerHtml += `<div class='le-lo-container'><div class='le-lo'>${le.logicalOperator}</div></div>`;
                le.comparisonStatements.forEach((cs) => {
                  innerHtml += `<div class='filter-cs'>`;
                  if (cs["logicalOperator"])
                    innerHtml += `<div class='cs-lo-container'><div class='cs-lo'>${cs.logicalOperator}</div></div>`;
                  innerHtml += `<div class='filter-cs-pair'>
                      <div class='filter-label'>Parameter</div>
                      <div class='filter-val'>${
                        this.segmentVariablesMap[cs.operand1].displayName
                      }</div>
                    </div>
                    <div class='filter-cs-pair'>
                      <div class='filter-label'>Condition</div>
                      <div class='filter-val'>${cs.operator}</div>
                    </div>
                    <div class='filter-cs-pair'>
                      <div class='filter-label'>Value</div>
                      <div class='filter-val'>${cs.operand2}</div>
                    </div>
                  </div>`;
                });
                innerHtml += `</div>`;
              });

              // filterData.segmentDefinition.logicalExpressions.forEach((le)=>{
              //   if(le['logicalOperator']) innerHtml += `${le['logicalOperator']} `;
              //   if(filterData.segmentDefinition.logicalExpressions.length > 0) {
              //     innerHtml += `[ `;
              //   }
              //   le.comparisonStatements.forEach((cs)=>{
              //     if(cs['logicalOperator']) innerHtml += ` ${cs['logicalOperator']}`;
              //     innerHtml +=
              //     ` ${this.segmentVariablesMap[cs.operand1].displayName} ${cs.operator} ${cs.operand2}`;
              //   });
              //   if(filterData.segmentDefinition.logicalExpressions.length > 0) {
              //     innerHtml += ` ] `;
              //   }
              // });
              filterDiv.innerHTML = innerHtml;
              parent.append(filterDiv);
            }
          }
          parentElement.append(triggerGroup);
        }
        break;
      case "ABTESTING":
        let groupNodeElement = document.createElement("div");
        groupNodeElement.setAttribute("class", "abtesting-node-content");
        groupNodeElement.innerHTML = document.getElementById(
          "abtesting-node-content"
        ).innerHTML;
        let innerHtml = "Experimentation window of ";
        if (triggerEvent["nodeData"]["definition"]["experimentDuration"][1]) {
          innerHtml += `<strong>${triggerEvent["nodeData"]["definition"]["experimentDuration"][1]} </strong><em> Day(s)</em>`;
        }
        if (
          triggerEvent["nodeData"]["definition"]["experimentDuration"][0] &&
          triggerEvent["nodeData"]["definition"]["experimentDuration"][1]
        ) {
          innerHtml += " and ";
        }
        if (triggerEvent["nodeData"]["definition"]["experimentDuration"][0]) {
          innerHtml += `<strong>${triggerEvent["nodeData"]["definition"]["experimentDuration"][0]} </strong><em> Hour(s)</em>`;
        }
        groupNodeElement.getElementsByClassName(
          "abtesting-node-content-time"
        )[0].innerHTML = innerHtml;
        parentElement.append(groupNodeElement);
        break;
      case "COMARKETING_OFFER":
        for (
          let i = 0;
          i < triggerEvent["nodeData"]["definition"]["offers"].length;
          i++
        ) {
          let comarketingofferNodeElement = document.createElement("div");
          comarketingofferNodeElement.setAttribute(
            "class",
            "comarketingoffer-node-content"
          );
          comarketingofferNodeElement.innerHTML = document.getElementById(
            "comarketingoffer-node-content"
          ).innerHTML;
          let comarketingofferTemplateName =
            comarketingofferNodeElement.getElementsByClassName(
              "comarketingoffer-node-template-name"
            )[0];
          comarketingofferTemplateName.innerHTML = this.getTemplateName(
            triggerEvent["nodeType"],
            triggerEvent["nodeData"]["definition"]["offers"][i]["id"],
            triggerEvent["nodeData"]["definition"]["offers"][i]["offerName"]
          );
          comarketingofferTemplateName.addEventListener("click", (event) => {
            this.showTemplatePreviewComarketing(
              event,
              triggerEvent["nodeData"]["definition"]["offers"][i]
            );
          });
          parentElement.append(comarketingofferNodeElement);
        }
        break;

      case "LOYALTY":
        let loyaltyNodeElement = document.createElement("div");
        loyaltyNodeElement.setAttribute("class", "loyalty-node-content");
        let color = "#97cb64";
        if (triggerEvent["nodeData"]["definition"]["rule"].ruleType == "BURN") {
          color = "#d43a2f";
        }
        if (triggerEvent["nodeData"]["definition"]["rule"]) {
          loyaltyNodeElement.innerHTML += `
              <div>
                  <div class="heading-pair">
                    <label class="label-style">Rule Name:</label>
                    <div>${
                      triggerEvent["nodeData"]["definition"]["rule"].ruleName
                    }</div>
                  </div>
              </div>`;
        }
        let previewButtonElement = document.createElement("div")
        previewButtonElement.textContent = "Preview Rule";
        previewButtonElement.classList.add("preview-button-rule");
        //show the current state of the rule which is present in the loyalty config with ruleName acting as ID
        previewButtonElement.addEventListener("click", (event) =>
          this.previewLoyaltyRule(this.getRuleFromLoyaltyConfigWithRuleName(triggerEvent["nodeData"]["definition"]["rule"]["ruleName"]))
        );
        parentElement.append(loyaltyNodeElement);
        parentElement.append(previewButtonElement);
        break;
    }
    // if (event["nodeOperation"] === "CREATE") {
    //   const nodeBlockId = this.activeNodeBlockId;
    //   if (event["nodeType"] != "TRIGGER") {
    //     let classAndId =
    //       this.getNodeBranchType(event["nodeType"]) === "UNBRANCHED"
    //         ? "node-config"
    //         : "unconditional-node-config";
    //     let nodeConfigElement = document.createElement("div");
    //     nodeConfigElement.addEventListener("click", (event) =>
    //       this.nodeOperations(event, nodeBlockId)
    //     );
    //     nodeConfigElement.setAttribute("class", "node-config");
    //     nodeConfigElement.innerHTML = document.getElementById(
    //       classAndId
    //     ).innerHTML;
    //     parentElement.appendChild(nodeConfigElement);
    //   }
    //   parentElement.appendChild(span1);
    //   parentElement.appendChild(span2);
    // }
  }

  getRuleFromLoyaltyConfigWithRuleName(ruleName) {
    return this.loyaltyProgramConfig['loyaltyRules'].find(rule=>rule.ruleName == ruleName);
  }

  previewLoyaltyRule(rule){
    if(rule) {
      const dialogRefer = this._dialog.open(LoyaltyRulePreviewDialogComponent, {
        panelClass: "customer-dialog-popup",
        maxWidth: "80%",
        data: {
          rule: rule,
          loyaltyPoints : this.loyaltyProgramConfig['fullConfig'].rewardPoints,
          loyaltyRules : this.loyaltyProgramConfig['loyaltyRules'],
          offerList : this.offerList,
          applicableCustomerGroupList : this.customerSegmentAndTiers,
        },
      });
    } else {
      const dialogref = this._dialog.open(
        ConfirmationDialogBoxComponent,
        {
          panelClass: "customer-dialog-popup",
          width: "380px",
          data: {
            message: 'Loyalty rule with that name does not exist in loyalty configuration. Please edit the node and choose an existing rule',
            successButtonText: "Confirm",
          }
        }
      );
    }
  }

  updateNodeData(event, nodeBlockId) {
    if (!(event.srcElement.id === "node-config-icon")) {
      let nodeIndex = this.nodeList.findIndex(
        (x) => x.nodeBlockId === nodeBlockId
      );
      this.activeNodeBlockId = nodeBlockId;
      this.nodeId = this.nodeList[nodeIndex].nodeId;
      this.nodeData = this.nodeList[nodeIndex].nodeData;
      this.nodeType = this.nodeList[nodeIndex].nodeType;
      this.previousNodeId = this.nodeList[nodeIndex].previousNodeId;
      this.previousNodeRelation = this.nodeList[nodeIndex].previousNodeRelation;
      this.actionType = "CONFIGURE";
    }
  }

  // removeLatestAddedNodeView(){
  //   let element = document.get
  // }

  addNodeDataToView(event) {
    let outerElement = document.getElementById(this.activeNodeBlockId);
    let innerElement = outerElement.getElementsByClassName("node-content")[0];
    let span1 = document.createElement("span");
    span1.setAttribute("class", "content-1");
    let span2 = document.createElement("span");
    span2.setAttribute("class", "content-2");
    console.log("11");
    this.setNodeContent(span1, span2, innerElement, event);
  }

  async nodeOperations(event, nodeBlockId) {
    if (!this.actionType) {
      if (this.strategyStatus != "DRAFT") {
        this.showNotification(
          this._i18nDynamicTranslate.transform("You can't edit", ["POS_PAGE"]) +
            " " +
            this.strategyStatus +
            " " +
            this._i18nDynamicTranslate.transform("Journey", ["POS_PAGE"]),
          false
        );
        return;
      }
      if(this.qIdOfAccount != this.strategyData.created_by['qid']) {
        this.showNotification(
          `You don't have the permission to edit Journey created by ${this.strategyData.created_by['storeName']}.`,
          false
        );
        return;
      }
      this.hideAllEvents();
      event.stopPropagation();
      if (event.srcElement.innerText === "more_vert") {
        let mouseLeft = event.clientX;
        let screenWidth = document.getElementById("canvas").offsetWidth;
        this.nodeOperationBlockId = nodeBlockId;
        let element = document.getElementById(nodeBlockId);
        let popupElement = element.getElementsByClassName(
          "node-operations"
        ) as HTMLCollectionOf<HTMLElement>;
        let deleteOptionsElement = element.getElementsByClassName(
          "delete-options"
        ) as HTMLCollectionOf<HTMLElement>;
        if (screenWidth - mouseLeft < 60 && screenWidth - mouseLeft > -70) {
          popupElement[0].style.left = "-80px";
          if (deleteOptionsElement[0]) {
            deleteOptionsElement[0].style.left = "-140px";
          }
        } else {
          popupElement[0].style.left = "35px";
          if (screenWidth - mouseLeft < 165 && screenWidth - mouseLeft >= 60) {
            if (deleteOptionsElement[0]) {
              deleteOptionsElement[0].style.left = "-140px";
            }
          } else {
            if (deleteOptionsElement[0]) {
              deleteOptionsElement[0].style.left = "80px";
            }
          }
        }
        popupElement[0].style.display = "block";
      } else {
        if (
          await this._SubscriptionPlanHandlerService.IsValidOperation(
            this.appSubscriptionPlan
          )
        ) {
          switch (event.srcElement.id) {
            case "deleteNode":
              this.deleteNode();
              break;
            case "deleteThis":
              const dialogRef1 = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "20%",
                  data: {
                    subject:
                      "Delete " +
                      this._EventIconMappingService.getBlockDisplayName(
                        this.nodeList[
                          this.nodeList.findIndex(
                            (x) => x.nodeBlockId === this.nodeOperationBlockId
                          )
                        ].nodeType
                      ) +
                      " Block",
                    message: "Click confirm to Delete",
                    cancelButtonText: "Cancel",
                    successButtonText: "Confirm",
                  },
                }
              );
              dialogRef1.afterClosed().subscribe((result) => {
                if (result) {
                  this.deleteUnbranchNode("NONE");
                }
              });
              break;
            case "deleteAllAfter":
              const dialogRef2 = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "20%",
                  data: {
                    subject:
                      "Delete " +
                      this._EventIconMappingService.getBlockDisplayName(
                        this.nodeList[
                          this.nodeList.findIndex(
                            (x) => x.nodeBlockId === this.nodeOperationBlockId
                          )
                        ].nodeType
                      ) +
                      " Block",
                    message: "Click confirm to Delete",
                    cancelButtonText: "Cancel",
                    successButtonText: "Confirm",
                  },
                }
              );
              dialogRef2.afterClosed().subscribe((result) => {
                if (result) {
                  this.deleteUnbranchNode("ALL");
                }
              });
              break;
            case "cloneNode":
              this.displayClonePositionButton();
              break;
            case "moveNode":
              this.displayMovePositionButton();
              break;
            case "editNode":
              this.updateNodeData(event, nodeBlockId);
              this.hideAllEvents();
              break;
            case "addNote":
              this.addNodeNoteButton(event, nodeBlockId, "CREATE");
              break;
          }
        }
      }
    }
  }

  async deleteUnbranchNode(deleteType) {
    if (deleteType === "NONE") {
      let deleteThisNodePromise = await this.deletenodeApi(
        this.nodeList[
          this.nodeList.findIndex(
            (x) => x.nodeBlockId === this.nodeOperationBlockId
          )
        ].nodeId,
        "NONE"
      );
      if (deleteThisNodePromise) {
        this.deleteNode();
        this.updateStrategy();
      }
    } else {
      let deleteAllNodePromise = await this.deletenodeApi(
        this.nodeList[
          this.nodeList.findIndex(
            (x) => x.nodeBlockId === this.nodeOperationBlockId
          )
        ].nodeId,
        "ALL"
      );
      if (deleteAllNodePromise) {
        this.addEndFlagToPreviousNode(this.nodeOperationBlockId);
        let nodeIndex = this.nodeList.findIndex(
          (x) => x.nodeBlockId === this.nodeOperationBlockId
        );
        this.removeBranchedNodesFromView(this.nodeList[nodeIndex].nodeId);
        this.removeBranchedNodes(this.nodeList[nodeIndex].nodeId);
        this.updateStrategy();
      }
    }
  }

  addNodeDataToList(event) {
    let item: nodeList = {
      nodeBlockId: this.activeNodeBlockId,
      nodeId: event["nodeId"],
      nodeType: event["nodeType"],
      nodeData: event["nodeData"],
      previousNodeId: event["previousNodeId"],
      previousNodeRelation: this.previousNodeRelation,
      nodeNote: "",
    };
    //item.nodeId = Math.floor(Math.random() * (1000 - 100) + 100);
    this.nodeList.push(item);
    this.updateStrategy();
  }

  /* Returns node Id bytrversing through node list
  Using node block Id */
  getNodeIdFromList(nodeBlockId) {
    return this.nodeList[
      this.nodeList.findIndex((x) => x.nodeBlockId === nodeBlockId)
    ].nodeId;
  }

  async resetCanvas() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if (
        this.strategyStatus === "SCHEDULED" ||
        this.strategyStatus === "ACTIVE"
      ) {
        this.showNotification(
          this._i18nDynamicTranslate.transform("You can't reset", [
            "POS_PAGE",
          ]) +
            " " +
            this.strategyStatus +
            " " +
            this._i18nDynamicTranslate.transform("Journey", ["POS_PAGE"]),
          false
        );
        return;
      }
      if (this.nodeList.length === 0) {
        this.showNotification("No Blocks present in workspace", false);
      } else {
        const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
          panelClass: "no-padding-dialog-popup",
          width: "20%",
          data: {
            subject: "Reset Canvas",
            message: "Reset canvas operation will remove all the blocks",
            "data-widget": "CANVAS",
            "data-button": "Confirm: Reset Canvas",
            "data-item": "",
            successButtonText: "Reset",
            cancelButtonText: "Cancel",
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            let indexOfFirstNode = this.nodeList.findIndex(
              (x) => x.previousNodeId === null
            );
            this.addOrRemovePointerEvents(false);
            this._apiCallService
              .deleteNode(
                this.strategyId,
                this.nodeList[indexOfFirstNode].nodeId,
                "ALL"
              )
              .subscribe(
                (reponse) => {
                  this.addOrRemovePointerEvents(true);
                  this.removeBranchedNodesFromView(
                    this.nodeList[indexOfFirstNode].nodeId
                  );
                  this.removeBranchedNodes(
                    this.nodeList[indexOfFirstNode].nodeId
                  );
                  this.addDummyNode();
                  this.previousNodeId = null;
                  this.actionType = null;
                  this.nodeType = null;
                  this.nodeId = null;
                  this.previousNodeRelation = null;
                  this.nodeData = null;
                  this.nodeCount = 0;
                  this.activeNodeBlockId = null;
                  this.nodeOperationBlockId = null;
                  this.activeNodeNoteBlockId = null;
                  this.showNotification("Your workspace has been reset", true);
                  this.updateStrategy();
                },
                (err) => {
                  this.addOrRemovePointerEvents(true);
                  this.showNotification("Failed to reset workspace", false);
                }
              );
          }
        });
      }
    }
  }

  async start() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if(this.isCreatorOfJourney()) {
        let element = document.getElementById("trigger-node-block-dummy-delete");
        element.parentElement.removeChild(element);
        this.addFirstNode();
      } else {
        this.showNotification(
          `You don't have the permission to edit Journey created by ${this.strategyData.created_by['storeName']}.`,
          false
        );
        return;
      }
    }
  }

  /* Adding the first node just after the strategy creation */
  addFirstNode() {
    let element = document.createElement("div");
    element.setAttribute("class", "node-block-container");
    this.activeNodeBlockId = "node-" + this.nodeCount++;
    element.setAttribute("id", this.activeNodeBlockId);
    element.innerHTML = document.getElementById("trigger-node-block").innerHTML;
    let buttonContainer = element.getElementsByClassName("button-container")[0];
    buttonContainer.removeAttribute("class");
    buttonContainer.setAttribute("class", "button-container-none");
    let deleteElement = document.createElement("div");
    deleteElement.setAttribute("class", "add-node");
    deleteElement.innerHTML = document.getElementById("add-button").innerHTML;
    deleteElement.addEventListener("click", (event) =>
      this.addNewNode(event, "UNBRANCHED")
    );
    let blockId = this.activeNodeBlockId;
    deleteElement.addEventListener("mouseenter", (event) =>
      this.showOrHideBlockedOperationTooltip(event, true, blockId, "add-node")
    );
    deleteElement.addEventListener("mouseleave", (event) =>
      this.showOrHideBlockedOperationTooltip(event, false, blockId, "add-node")
    );
    let moveButtonElement = document.createElement("div");
    moveButtonElement.setAttribute("class", "move-button");
    moveButtonElement.innerHTML =
      document.getElementById("move-button").innerHTML;
    moveButtonElement.addEventListener("click", (event) =>
      this.moveNode(event)
    );
    let cloneButtonElement = document.createElement("div");
    cloneButtonElement.setAttribute("class", "clone-button");
    cloneButtonElement.innerHTML =
      document.getElementById("clone-button").innerHTML;
    cloneButtonElement.addEventListener("click", (event) =>
      this.cloneNode(event)
    );
    let flagElement = document.createElement("div");
    flagElement.setAttribute("class", "end-flag");
    flagElement.innerHTML = document.getElementById("end-flag").innerHTML;
    let connectorElement = document.createElement("div");
    connectorElement.setAttribute("class", "node-connectors");
    buttonContainer.append(connectorElement);
    buttonContainer.append(moveButtonElement);
    buttonContainer.append(cloneButtonElement);
    buttonContainer.append(deleteElement);
    buttonContainer.append(flagElement);
    document.getElementById("canvas").append(element);
    this.previousNodeId = null;
    this.actionType = "CONFIGURE";
    this.nodeType = "TRIGGER";
  }

  /* Add a new node in canvas
  Triggers for the add button below nodes in the canvas */
  async addNewNode(event, type, pathName?) {
    if (!this.actionType) {
      if (
        await this._SubscriptionPlanHandlerService.IsValidOperation(
          this.appSubscriptionPlan
        )
      ) {
        if (this.strategyStatus != 'DRAFT') {
          this.showNotification(
            this._i18nDynamicTranslate.transform("You can't edit", [
              "POS_PAGE",
            ]) +
              " " +
              this.strategyStatus +
              " " +
              this._i18nDynamicTranslate.transform("Journey", ["POS_PAGE"]),
            false
          );
          return;
        }
        if(this.qIdOfAccount != this.strategyData.created_by['qid']) {
          this.showNotification(
            `You don't have the permission to edit Journey created by ${this.strategyData.created_by['storeName']}.`,
            false
          );
          return;
        }
        this.addNodeEvent = event;
        let previousNodeBlockId;
        let parent;
        if (
          type == "ABBRANCHED" ||
          type == "SPLITBRANCH" ||
          type == "AUDIENCESPLIT"
        ) {
          previousNodeBlockId =
            event.srcElement.parentElement.parentElement.parentElement
              .parentElement.id;
          previousNodeBlockId.substring(0, 4) === "node"
            ? null
            : (previousNodeBlockId =
                event.srcElement.parentElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.parentElement
                  .parentElement.parentElement.id);
          parent =
            event.srcElement.parentElement.parentElement.parentElement
              .parentElement;
        } else if (type == "BRANCHED") {
          previousNodeBlockId =
            event.srcElement.parentElement.parentElement.parentElement
              .parentElement.id;
          previousNodeBlockId.substring(0, 4) === "node"
            ? null
            : (previousNodeBlockId =
                event.srcElement.parentElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.parentElement
                  .parentElement.id);
          parent =
            event.srcElement.parentElement.parentElement.parentElement
              .parentElement;
        } else {
          previousNodeBlockId =
            event.srcElement.parentElement.parentElement.parentElement.id;
          previousNodeBlockId.substring(0, 4) === "node"
            ? null
            : (previousNodeBlockId =
                event.srcElement.parentElement.parentElement.parentElement
                  .parentElement.parentElement.parentElement.parentElement
                  .parentElement.id);
          parent =
            event.srcElement.parentElement.parentElement.parentElement
              .parentElement;
        }

        let element = document.createElement("div");
        element.setAttribute("class", "node-block-container");
        element.setAttribute("id", "dummy");
        element.innerHTML = document.getElementById("dummy-node").innerHTML;
        if (type === "BRANCHED") {
          parent.childNodes[0].insertAdjacentElement("afterend", element);
        } else if (type === "ABBRANCHED") {
          // let i =parseInt(parent.id.charAt(parent.id.length-1))
          parent.childNodes[0].insertAdjacentElement("afterend", element);
          let i = parent.id.substring(15, parent.id.length);
          this.previousNodeRelation = "PATH##" + i;
          // parent.childNodes[2].insertAdjacentElement("afterend", element)
          // parent.append(element);
          this.actionType = "CREATE";
          document
            .getElementById("wrap-ab-" + previousNodeBlockId + "-id-" + i)
            .setAttribute("style", "height:80px");
          // let buttonWrap = document.createElement("div")
          // buttonWrap.setAttribute("class", "buttonWrapLine2")
          // buttonWrap.setAttribute("id","wrap-id-" +i)
          // parent.append(buttonWrap)

          // document
          //     .getElementById(
          //       parent.id
          //     )
          //     .getElementsByClassName("buttonWrapLine")[i]
          //     .setAttribute("style", "height:80px");
        } else if (type === "SPLITBRANCH") {
          // let i =parseInt(parent.id.charAt(parent.id.length-1))
          let i = parent.id.substring(15, parent.id.length);
          this.previousNodeRelation = "SPLIT##" + i.replace("-", "");
          parent.childNodes[0].insertAdjacentElement("afterend", element);
          // parent.childNodes[2].insertAdjacentElement("afterend", element)
          // parent.append(element);
          this.actionType = "CREATE";
          document
            .getElementById("wrap-split-" + previousNodeBlockId + "-id-" + i)
            .setAttribute("style", "height:80px");
        } else {
          document
            .getElementById(previousNodeBlockId)
            .insertAdjacentElement("afterend", element);
        }
        this.previousNodeId = this.getNodeIdFromList(previousNodeBlockId);
        if (type === "BRANCHED" || type === "UNBRANCHED") {
          this.previousNodeRelation =
            event.srcElement.parentElement.className === "add-node-right-branch"
              ? "TIMEOUT"
              : "NEXT";
        } else if (type === "ABBRANCHED") {
          // let i =parseInt(parent.id.charAt(parent.id.length-1))
          let i = parent.id.substring(15, parent.id.length);
          this.previousNodeRelation = "PATH##" + i;
        } else if (type === "SPLITBRANCH") {
          // let i =parseInt(parent.id.charAt(parent.id.length-1))
          let i = parent.id.substring(15, parent.id.length);
          this.previousNodeRelation = "SPLIT##" + i.replace("-", "");
        }
        this.mouseEvent = event;
        this.actionType = "CREATE";
        this.nodeType = null;
        this.nodeId = "";
        let nextNodeIndex = this.nodeList.findIndex(
          (x) => x.previousNodeId === this.previousNodeId
        );
        if (nextNodeIndex > -1) {
          if (
            this.nodeList[
              this.nodeList.findIndex((x) => x.nodeId === this.previousNodeId)
            ].nodeType === "RESPONSE_EVENT"
          ) {
            let childCount = this.nodeList.reduce(
              (a, v) => (v.previousNodeId === this.previousNodeId ? a + 1 : a),
              0
            );
            if (childCount === 1) {
              if (
                this.nodeList[nextNodeIndex].previousNodeRelation === "NEXT"
              ) {
                if (
                  event.srcElement.parentElement.className !==
                  "add-node-right-branch"
                ) {
                  this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
                } else {
                  this.nextNodeId = null;
                }
              } else {
                if (
                  event.srcElement.parentElement.className ===
                  "add-node-right-branch"
                ) {
                  this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
                } else {
                  this.nextNodeId = null;
                }
              }
            }
            if (childCount === 2) {
              if (
                event.srcElement.parentElement.className ===
                "add-node-right-branch"
              ) {
                this.nextNodeId =
                  this.nodeList[
                    this.nodeList.findIndex(
                      (x) =>
                        x.previousNodeId === this.previousNodeId &&
                        x.previousNodeRelation === "TIMEOUT"
                    )
                  ].nodeId;
              } else {
                this.nextNodeId =
                  this.nodeList[
                    this.nodeList.findIndex(
                      (x) =>
                        x.previousNodeId === this.previousNodeId &&
                        x.previousNodeRelation === "NEXT"
                    )
                  ].nodeId;
              }
            }
          }
          // else if(this.getNodeBranchType(
          //   this.nodeList[
          //     this.nodeList.findIndex((x) => x.nodeId === this.previousNodeId)
          //   ].nodeType
          // ) === "GROUPBRANCH"){

          // }
          else {
            // console.log(this.nodeList, nextNodeIndex)
            // let childCount = this.nodeList.reduce(
            //   (a, v) => (v.previousNodeId === this.previousNodeId ? a + 1 : a),
            //   0
            // );
            // console.log(childCount, this.previousNodeId, this.previousNodeRelation)
            if (
              this.nodeList[nextNodeIndex].previousNodeRelation ===
              this.previousNodeRelation
            )
              this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
          }
        } else {
          this.nextNodeId = null;
        }
        if (type === "BRANCHED") {
          if (
            event.srcElement.parentElement.className === "add-node-right-branch"
          ) {
            document
              .getElementById(
                "right-node" +
                  previousNodeBlockId.substring(4, previousNodeBlockId.length)
              )
              .getElementsByClassName("end-flag-right-branch")[0]
              .setAttribute("style", "display:none");
          } else {
            document
              .getElementById(
                "left-node" +
                  previousNodeBlockId.substring(4, previousNodeBlockId.length)
              )
              .getElementsByClassName("end-flag-left-branch")[0]
              .setAttribute("style", "display:none");
          }
        } else if (type === "ABBRANCHED") {
          // let i =parseInt(parent.id.charAt(parent.id.length-1))
          let i = parent.id.substring(15, parent.id.length);
          // document.getElementById("end-flag-ab-"+i)
          document
            .getElementById("end-flag-" + previousNodeBlockId + "-ab-" + i)
            .setAttribute("style", "display :none");
        } else if (type === "SPLITBRANCH") {
          // let i =parseInt(parent.id.charAt(parent.id.length-1))
          let i = parent.id.substring(15, parent.id.length);
          document
            .getElementById("end-flag-" + previousNodeBlockId + "-split-" + i)
            .setAttribute("style", "display :none");
        } else {
          document
            .getElementById(previousNodeBlockId)
            .getElementsByClassName("end-flag")[0]
            .setAttribute("style", "display:none");
        }
        if (parent) {
          this.setNodeVisibilityWhileCreation(
            this.extractNodeNumber(parent.id)
          );
        }
        //set loyaltyRuleEventsInNodes
        this.loyaltyRuleEventsInNodes = [];
        let parentNode = this.nodeList.find(
          (node) => node.nodeBlockId == previousNodeBlockId
        );
        while(parentNode?.nodeId) {
          if(parentNode.nodeData["nodeType"] == "LOYALTY") {
            this.loyaltyRuleEventsInNodes.push(parentNode.nodeData['definition'].rule.ruleDefinition.event);
          }
          parentNode = this.nodeList.find(
            (node) => node.nodeId == parentNode.previousNodeId
          );
        }
        console.log('LOYALTY EVENTS IN CAMP',this.loyaltyRuleEventsInNodes);
        //this.removebuttonPointerEvents();
      }
    }
  }

  extractNodeNumber(input: string): string | null {
    const pattern = /node-(\d+)(?=-)/;
    const match = pattern.exec(input);
    return match ? match[0] : null;
  }

  setNodeVisibilityWhileCreation(parentNodeNumber) {
    let parentNodeId = this.nodeList.find(
      (node) => node.nodeBlockId == parentNodeNumber
    )?.nodeId;
    if (parentNodeId && this.hasParentABTestingNode(parentNodeId)) {
      this.allowABTestingNodeCreation = false;
      this.allowAudienceSplitNodeCreation = false;
    } else {
      this.allowABTestingNodeCreation = true;
      this.allowAudienceSplitNodeCreation = true;
    }
  }

  hasParentABTestingNode(nodeId) {
    let curNode = this.nodeList.find((node) => node.nodeId == nodeId);
    if (!curNode) return false;
    if (curNode.nodeData["nodeType"] == "ABTESTING") return true;
    else return this.hasParentABTestingNode(curNode.previousNodeId);
  }

  /* Add a node with ability to add one child */
  createUnbranchNode(
    event,
    previousNodeBlockId,
    triggerEvent,
    operation,
    cloneOrMove,
    relation?,
    type?
  ) {
    //let div = event.srcElement.parentElement;
    let parent;
    if (operation === "CREATE") {
      if (
        relation.substring(0, 4) === "PATH" ||
        relation.substring(0, 5) === "SPLIT"
      ) {
        if (!cloneOrMove) {
          parent =
            event.srcElement.parentElement.parentElement.parentElement
              .parentElement;
        } else {
          parent = event.srcElement.parentElement.parentElement;
        }
      } else {
        parent =
          event.srcElement.parentElement.parentElement.parentElement
            .parentElement;
      }
    } else {
      parent = event;
    }
    //let parentDiv = div.parentNode;
    //parentDiv.removeChild(div);
    //Creating a div to add node
    let element = document.createElement("div");
    element.setAttribute("class", "node-block-container");
    if (relation)
      if (
        relation.substring(0, 4) === "PATH" ||
        relation.substring(0, 5) === "SPLIT"
      ) {
        element.style.display = "flex";
        element.style.flexDirection = "column";
        // element.style.alignItems = "center";
      }
    //Adding node block id
    this.activeNodeBlockId =
      operation === "CREATE"
        ? "node-" + this.nodeCount++
        : triggerEvent.nodeBlockId;
    element.setAttribute("id", this.activeNodeBlockId);
    operation === "UPDATE" ? (this.nodeType = triggerEvent.nodeType) : null;
    element.innerHTML = document.getElementById(
      this.getNodeClassIdForView(triggerEvent)
    ).innerHTML;

    //APPENDING CUSTOMER COUNT ABOVE THE NODE
    if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
      let customerCountElement = document.createElement("div");
      customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
      customerCountElement.setAttribute("class", "customer-count");
      if(this.strategyStatus == 'ACTIVE')
        customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
      element.append(customerCountElement);
    }
    

    let buttonContainer = element.getElementsByClassName("button-container")[0];
    //creating element for addbutton
    let deleteElement = document.createElement("div");
    deleteElement.setAttribute("class", "add-node");
    deleteElement.innerHTML = document.getElementById("add-button").innerHTML;
    deleteElement.addEventListener("click", (event) =>
      this.addNewNode(event, "UNBRANCHED")
    );
    let blockId = this.activeNodeBlockId;
    deleteElement.addEventListener("mouseenter", (event) =>
      this.showOrHideBlockedOperationTooltip(event, true, blockId, "add-node")
    );
    deleteElement.addEventListener("mouseleave", (event) =>
      this.showOrHideBlockedOperationTooltip(event, false, blockId, "add-node")
    );
    let moveButtonElement = document.createElement("div");
    moveButtonElement.setAttribute("class", "move-button");
    moveButtonElement.innerHTML =
      document.getElementById("move-button").innerHTML;
    moveButtonElement.addEventListener("click", (event) =>
      this.moveNode(event)
    );
    let cloneButtonElement = document.createElement("div");
    cloneButtonElement.setAttribute("class", "clone-button");
    cloneButtonElement.innerHTML =
      document.getElementById("clone-button").innerHTML;
    cloneButtonElement.addEventListener("click", (event) =>
      this.cloneNode(event)
    );
    let flagElement = document.createElement("div");
    flagElement.setAttribute("class", "end-flag");
    flagElement.innerHTML = document.getElementById("end-flag").innerHTML;
    let connectorElement = document.createElement("div");
    connectorElement.setAttribute("class", "node-connectors");
    buttonContainer.append(connectorElement);
    buttonContainer.append(moveButtonElement);
    buttonContainer.append(cloneButtonElement);
    buttonContainer.append(flagElement);
    buttonContainer.append(deleteElement);
    //checking if intermediate node addition
    if (operation === "CREATE") {
      let nextNodeIndex = this.nodeList.findIndex(
        (x) =>
          x.previousNodeId ===
          this.nodeList[
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === previousNodeBlockId
            )
          ].nodeId
      );
      if (nextNodeIndex > -1) {
        //checking if the previous node is a conditional node
        if (
          this.getNodeBranchType(
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === previousNodeBlockId
              )
            ].nodeType
          ) === "UNBRANCHED"
        ) {
          this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
          document
            .getElementById(this.nodeList[nextNodeIndex].nodeBlockId)
            .insertAdjacentElement("beforebegin", element);
        } else {
          let parentOfnewNode =
            event.srcElement.parentElement.parentElement.parentElement;
          let childNodesOfConditionalNode =
            parentOfnewNode.getElementsByClassName("node-block-container");
          if (childNodesOfConditionalNode.length > 0) {
            this.nextNodeId = this.getNodeIdFromList(
              childNodesOfConditionalNode[0].id
            );
          }
          if (
            relation.substring(0, 4) === "PATH" ||
            relation.substring(0, 5) === "SPLIT"
          ) {
            if (cloneOrMove) {
              event.srcElement.parentElement.parentElement.parentElement.childNodes[0].insertAdjacentElement(
                "afterend",
                element
              );
            } else {
              event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                "afterend",
                element
              );
            }
          } else {
            event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
              "afterend",
              element
            );
          }
        }
        if (!cloneOrMove) {
          let nodeApiData = {
            definition: JSON.stringify(
              this.nodeList[nextNodeIndex].nodeData["definition"]
            ),
            nodeType: this.nodeList[nextNodeIndex].nodeType,
            previousNodeId: triggerEvent.nodeId,
            previousNodeRelation: "NEXT",
          };
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeBlockId === previousNodeBlockId
                )
              ].nodeType
            ) === "UNBRANCHED"
          ) {
            document
              .getElementById(this.activeNodeBlockId)
              .getElementsByClassName("end-flag")[0]
              .setAttribute("style", "display:none");
            this.addOrRemovePointerEvents(false);
            this._apiCallService
              .updateNode(
                this.strategyId,
                this.nodeList[nextNodeIndex].nodeId,
                nodeApiData
              )
              .subscribe(
                (response) => {
                  this.addOrRemovePointerEvents(true);
                  this.nodeList[nextNodeIndex].previousNodeId =
                    triggerEvent.nodeId;
                  this.updateStrategy();
                },
                (err) => {
                  this.addOrRemovePointerEvents(true);
                }
              );
          } else {
            let previousNodeIndex = this.nodeList.findIndex(
              (x) => x.nodeBlockId === previousNodeBlockId
            );
            let nextBranchNodeIndex = this.nodeList.findIndex(
              (x) =>
                x.previousNodeId === this.nodeList[previousNodeIndex].nodeId &&
                x.previousNodeRelation === triggerEvent.previousNodeRelation
            );
            if (nextBranchNodeIndex > -1) {
              document
                .getElementById(this.activeNodeBlockId)
                .getElementsByClassName("end-flag")[0]
                .setAttribute("style", "display:none");
              let nodeApiData = {
                definition: JSON.stringify(
                  this.nodeList[nextBranchNodeIndex].nodeData["definition"]
                ),
                nodeType: this.nodeList[nextBranchNodeIndex].nodeType,
                previousNodeId: triggerEvent.nodeId,
                previousNodeRelation: "NEXT",
              };
              this.addOrRemovePointerEvents(false);
              this._apiCallService
                .updateNode(
                  this.strategyId,
                  this.nodeList[nextBranchNodeIndex].nodeId,
                  nodeApiData
                )
                .subscribe(
                  (response) => {
                    this.addOrRemovePointerEvents(true);
                    this.nodeList[nextBranchNodeIndex].previousNodeId =
                      triggerEvent.nodeId;
                    this.nodeList[nextBranchNodeIndex].previousNodeRelation =
                      "NEXT";
                    this.updateStrategy();
                  },
                  (err) => {
                    this.addOrRemovePointerEvents(true);
                  }
                );
            }
          }
        }
      } else {
        this.nextNodeId = null;
        if (
          relation.substring(0, 4) === "PATH" ||
          relation.substring(0, 5) === "SPLIT"
        ) {
          if (cloneOrMove) {
            parent.insertAdjacentElement("afterend", element);
          } else {
            parent.append(element);
          }
        } else {
          parent.append(element);
        }
      }
    } else {
      this.nextNodeId = null;
      parent.append(element);
    }
    if (cloneOrMove) {
      this.addBoxShadowOnNodeBlock(
        element.getElementsByClassName("node-block")[0]
      );
    }
  }

  /* Add a node with ability to add two childeren */
  createBranchNode(
    event,
    previousNodeBlockId,
    triggerEvent,
    operation,
    type,
    relation?
  ) {
    //let div = event.srcElement.parentElement;
    let parent;
    if (operation === "CREATE") {
      if (
        relation.substring(0, 4) === "PATH" ||
        relation.substring(0, 5) === "SPLIT"
      ) {
        parent =
          event.srcElement.parentElement.parentElement.parentElement
            .parentElement;
      } else {
        parent =
          event.srcElement.parentElement.parentElement.parentElement
            .parentElement;
      }
    } else {
      parent = event;
    }
    //let parentDiv = div.parentNode;
    //parentDiv.removeChild(div);
    let element = <HTMLElement>document.createElement("div");
    element.setAttribute("class", "node-block-container");
    // if (relation)
    //   if (
    //     relation.substring(0, 4) === "PATH" ||
    //     relation.substring(0, 5) === "SPLIT"
    //   ) {
    //     // element.style.display = "flex";
    //     // element.style.flexDirection = "column";
    //     // element.style.alignItems = "center";
    //     if (type == "ABTESTING")
    //       document
    //         .getElementsByClassName("qb-line-fix")[0]
    //         .setAttribute("style", "left:50%");
    //     else if (type == "AUDIENCESPLIT")
    //       document
    //         .getElementsByClassName("qb-line-fix-split")[0]
    //         .setAttribute("style", "left:50%");
    //     // else
    //     //   document
    //     //     .getElementsByClassName("qb-line-fix-event")[0]
    //     //     .setAttribute("style", "left:50%");
    //     element.classList.add("ab-node-block");
    //   }
    this.activeNodeBlockId =
      operation === "CREATE"
        ? "node-" + this.nodeCount++
        : triggerEvent.nodeBlockId;
    element.setAttribute("id", this.activeNodeBlockId);
    if (type == "ABTESTING") {
      element.innerHTML = document.getElementById(
        "abtesting-node-block"
      ).innerHTML;
      element
        .getElementsByClassName("ab-node-container")[0]
        .setAttribute("id", "ab-" + this.activeNodeBlockId);
      // element
      //   .getElementsByClassName("right-node-container")[0]
      //   .setAttribute("id", "right-" + this.activeNodeBlockId);
      element
        .getElementsByClassName("ab-branch-node-path")[0]
        .setAttribute("id", "ab-" + this.activeNodeBlockId + "-path-ab");

      let pathGroupData = triggerEvent.nodeData.definition.pathGroups;
      let count_path = 0;
      for (var k in pathGroupData) {
        0;
        count_path = count_path + 1;
        let sub_container = document.createElement("div");
        sub_container.setAttribute("class", "split-branch");
        sub_container.setAttribute(
          "id",
          "sp-" + this.activeNodeBlockId + "-path-" + k
        );
        element
          .getElementsByClassName("ab-branch-node-path")[0]
          .append(sub_container);
        let mainWraper = document.createElement("div");
        mainWraper.style.position = "relative";
        mainWraper.style.display = "flex";
        mainWraper.style.width = "106px";
        mainWraper.style.flexDirection = "column";
        mainWraper.style.alignItems = "center";
        mainWraper.style.marginLeft = "93px";
        mainWraper.setAttribute(
          "id",
          "ab-" + this.activeNodeBlockId + "-path-" + k
        );

        let inputWraper = document.createElement("div");

        inputWraper.style.background = "#6165D7";
        inputWraper.style.display = "flex";
        inputWraper.style.width = "180px";
        inputWraper.style.position = "relative";
        inputWraper.style.padding = "5px";
        inputWraper.style.borderRadius = "4px";
        inputWraper.style.color = "#FFFFFF";
        let value = pathGroupData[k]["weight"];
        let pathName = pathGroupData[k]["pathName"];
        let wrapContent = ` <div class="dynamic-split-vertical"></div>
                              <div class="each-path-disp" id="abtesting-${this.activeNodeBlockId}-${k}">
                                <div class="path-name-disp" title="${pathName}">
                                  ${pathName}
                                </div>
                                <div class="path-perc-disp">
                                  ${value} %
                                </div>
                              </div>`;
        // let inputElement = document.createElement("input");
        // inputElement.setAttribute("type", "number");
        // inputElement.setAttribute("class","abtest-input-area "+k);
        // inputElement.setAttribute("style","width: 48px;")
        // inputElement.value=pathGroupData[k];
        inputWraper.innerHTML = wrapContent;
        mainWraper.append(inputWraper);
        // inputElement.innerHTML=k;

        let leftBranchElement =
          element.getElementsByClassName("ab-node-container");
        // let divelement = document.createElement("div")
        let deleteElement1 = document.createElement("div");
        deleteElement1.setAttribute("class", "add-node-ab-branch");
        deleteElement1.innerHTML =
          document.getElementById("add-button").innerHTML;
        deleteElement1.addEventListener("click", (event) =>
          this.addNewNode(event, "ABBRANCHED", pathGroupData[k])
        );
        let blockId = this.activeNodeBlockId;
        deleteElement1.addEventListener("mouseenter", (event) =>
          this.showOrHideBlockedOperationTooltip(
            event,
            true,
            blockId,
            "add-node-ab-branch"
          )
        );
        deleteElement1.addEventListener("mouseleave", (event) =>
          this.showOrHideBlockedOperationTooltip(
            event,
            false,
            blockId,
            "add-node-ab-branch"
          )
        );
        let flagElement = document.createElement("div");
        flagElement.setAttribute("class", "end-flag-ab");
        // flagElement.setAttribute("id","end-flag-ab-"+count)
        flagElement.setAttribute(
          "id",
          "end-flag-" + this.activeNodeBlockId + "-ab-" + k
        );
        flagElement.innerHTML = document.getElementById("end-flag").innerHTML;
        let leftButtonContainer = document.createElement("div");
        leftButtonContainer.style.padding = "25px 0px";
        leftButtonContainer.style.position = "relative";

        // let buttonWrap = `<div class="buttonWrapLine"></div>`
        // mainWraper.innerHTML = buttonWrap
        let buttonWrap = document.createElement("div");
        buttonWrap.setAttribute("class", "buttonWrapLine");
        mainWraper.append(buttonWrap);
        let leftButtonContainerParent = document.createElement("div");
        leftButtonContainerParent.setAttribute(
          "class",
          "leftButtonContainerParent"
        );
        let buttonWrap2 = document.createElement("div");
        buttonWrap.setAttribute("class", "buttonWrapLineAb");
        buttonWrap.setAttribute(
          "id",
          "wrap-ab-" + this.activeNodeBlockId + "-id-" + k
        );
        // buttonWrap.setAttribute("id","wrap-id-" +count)
        // parent.append(buttonWrap)
        let cloneButtonElement1 = document.createElement("div");
        cloneButtonElement1.setAttribute("class", "multi-branch-clone-button");
        cloneButtonElement1.innerHTML =
          document.getElementById("clone-button").innerHTML;
        cloneButtonElement1.addEventListener("click", (event) =>
          this.cloneNode(event)
        );
        let moveButtonElement1 = document.createElement("div");
        moveButtonElement1.setAttribute("class", "multi-branch-move-button");
        moveButtonElement1.innerHTML =
          document.getElementById("move-button").innerHTML;
        moveButtonElement1.addEventListener("click", (event) =>
          this.moveNode(event)
        );
        mainWraper.append(cloneButtonElement1);
        mainWraper.append(moveButtonElement1);
        leftButtonContainer.append(deleteElement1);
        leftButtonContainer.append(flagElement);
        leftButtonContainer.append(buttonWrap2);
        // leftButtonContainerParent.append(leftButtonContainer);

        // leftBranchElement.append(deleteElement1)
        mainWraper.append(leftButtonContainer);
        sub_container.append(mainWraper);
        element
          .getElementsByClassName("ab-branch-node-path")[0]
          .append(sub_container);

        // leftBranchElement[0].append(leftButtonContainerParent);
      }
      let abTestWrapper = <HTMLElement>(
        element.getElementsByClassName("abtest-wrapper")[0]
      );
      abTestWrapper.setAttribute(
        "id",
        "abtest-wrapper-" + this.activeNodeBlockId
      );
      // abTestWrapper.style.width = `${(count - 1) * 500}px`;
      // abTestWrapper.style.minWidth = `340px`;

      let leftBranchElement =
        element.getElementsByClassName("ab-node-container");
      let deleteElement1 = document.createElement("div");
      deleteElement1.setAttribute("class", "add-node-ab-branch");
      deleteElement1.innerHTML =
        document.getElementById("add-button").innerHTML;
      deleteElement1.addEventListener("click", (event) =>
        this.addNewNode(event, "BRANCHED")
      );
      let blockId = this.activeNodeBlockId;
      deleteElement1.addEventListener("mouseenter", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          true,
          blockId,
          "add-node-ab-branch"
        )
      );
      deleteElement1.addEventListener("mouseleave", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          false,
          blockId,
          "add-node-ab-branch"
        )
      );
      let rightBranchElement =
        element.getElementsByClassName("ab-node-container");

      let flagElement1 = document.createElement("div");
      flagElement1.setAttribute("class", "end-flag-left-branch");
      flagElement1.innerHTML = document.getElementById("end-flag").innerHTML;

      if (operation === "CREATE") {
        // Finding out if there is a node with node id of the previous node as the previous node id
        let nextNodeIndex = this.nodeList.findIndex(
          (x) =>
            x.previousNodeId ===
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === previousNodeBlockId
              )
            ].nodeId
        );
        // If there is a node
        if (nextNodeIndex > -1) {
          //Checking if there previous node is a conditional node
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeBlockId === previousNodeBlockId
                )
              ].nodeType
            ) === "UNBRANCHED"
          ) {
            //If not a conditional node, showing the popup to select what to do with the child nodes.
            this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
            let dialogRef = this._dialog.open(
              NodeDeleteBranchSelectorComponent,
              {
                panelClass: "no-padding-dialog-popup",
                data: {
                  operation: "add",
                  nodeData: this.getArrayFormData(pathGroupData),
                  type: "multiple-split",
                },
                disableClose: true,
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                if (result["status"]) {
                  document
                    .getElementById(this.nodeList[nextNodeIndex].nodeBlockId)
                    .insertAdjacentElement("beforebegin", element);
                  this.addNodeDataOnCreate(triggerEvent, "CREATE");
                  this.appendSplitBranchNodesToConditionalNode(
                    result["value"],
                    element,
                    this.nodeList[nextNodeIndex].nodeBlockId,
                    triggerEvent
                  );
                } else {
                  this.deletenodeApi(triggerEvent["nodeId"], "NONE");
                  let nodeApiData = {
                    definition: JSON.stringify(
                      this.nodeList[nextNodeIndex].nodeData["definition"]
                    ),
                    nodeType: this.nodeList[nextNodeIndex].nodeType,
                    previousNodeId: this.nodeList[nextNodeIndex].previousNodeId,
                    previousNodeRelation:
                      this.nodeList[nextNodeIndex].previousNodeRelation,
                  };
                  this.addOrRemovePointerEvents(false);
                  this._apiCallService
                    .updateNode(
                      this.strategyId,
                      this.nodeList[nextNodeIndex].nodeId,
                      nodeApiData
                    )
                    .subscribe(
                      () => {
                        this.addOrRemovePointerEvents(true);
                      },
                      (err) => {
                        this.addOrRemovePointerEvents(true);
                      }
                    );
                }
              }
              let split_container = document.getElementById(
                "sp-" + this.activeNodeBlockId + "-path-Path-" + count_path
              );
              split_container.setAttribute("class", "split-branch-last");
            });
          } else {
            // If the previous node is a conditional node
            let parentOfnewNode =
              event.srcElement.parentElement.parentElement.parentElement
                .parentElement;
            let childNodesOfConditionalNode =
              parentOfnewNode.getElementsByClassName("node-block-container");
            if (childNodesOfConditionalNode.length > 0) {
              let nextNodeId = childNodesOfConditionalNode[0].id;
              this.nextNodeId = this.getNodeIdFromList(
                childNodesOfConditionalNode[0].id
              );
              let dialogRef = this._dialog.open(
                NodeDeleteBranchSelectorComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  data: {
                    operation: "add",
                    nodeData: this.getArrayFormData(pathGroupData),
                    type: "multiple-split",
                  },
                  disableClose: true,
                }
              );
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  if (result["status"]) {
                    event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                      "afterend",
                      element
                    );
                    this.addNodeDataOnCreate(triggerEvent, "CREATE");
                    this.appendSplitBranchNodesToConditionalNode(
                      result["value"],
                      element,
                      nextNodeId,
                      triggerEvent
                    );
                  } else {
                    this.deletenodeApi(triggerEvent["nodeId"], "NONE");
                    let nextNodeIndex = this.nodeList.findIndex(
                      (x) => x.nodeId === this.nextNodeId
                    );
                    let nodeApiData = {
                      definition: JSON.stringify(
                        this.nodeList[nextNodeIndex].nodeData["definition"]
                      ),
                      nodeType: this.nodeList[nextNodeIndex].nodeType,
                      previousNodeId:
                        this.nodeList[nextNodeIndex].previousNodeId,
                      previousNodeRelation:
                        this.nodeList[nextNodeIndex].previousNodeRelation,
                    };
                    this.addOrRemovePointerEvents(false);
                    this._apiCallService
                      .updateNode(
                        this.strategyId,
                        this.nodeList[nextNodeIndex].nodeId,
                        nodeApiData
                      )
                      .subscribe(
                        () => {
                          this.addOrRemovePointerEvents(true);
                        },
                        (err) => {
                          this.addOrRemovePointerEvents(true);
                        }
                      );
                  }
                }
                let split_container = document.getElementById(
                  "sp-" + this.activeNodeBlockId + "-path-Path-" + count_path
                );
                split_container.setAttribute("class", "split-branch-last");
              });
            } else {
              event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                "afterend",
                element
              );
              let split_container = document.getElementById(
                "sp-" + this.activeNodeBlockId + "-path-Path-" + count_path
              );
              split_container.setAttribute("class", "split-branch-last");
            }
          }
        } else {
          this.nextNodeId = null;
          //APPENDING CUSTOMER COUNT ABOVE THE NODE
          if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
            let customerCountElement = document.createElement("div");
            customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
            customerCountElement.setAttribute("class", "customer-count");
            if(this.strategyStatus == 'ACTIVE')
              customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
            element.append(customerCountElement);
          }
          parent.append(element);
          let split_container = document.getElementById(
            "sp-" + this.activeNodeBlockId + "-path-Path-" + count_path
          );
          split_container.setAttribute("class", "split-branch-last");
        }
      } else {
        this.nextNodeId = null;
        //APPENDING CUSTOMER COUNT ABOVE THE NODE
        if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
          let customerCountElement = document.createElement("div");
          customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
          customerCountElement.setAttribute("class", "customer-count");
          if(this.strategyStatus == 'ACTIVE')
            customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
          element.append(customerCountElement);
        }
        parent.append(element);
      }

      // this.nextNodeId = null;
      // parent.append(element);

      // let leftButtonContainerParent = document.createElement("div");
      //let rightButtonContainerParent = document.createElement("div");
      // let leftButtonContainer = document.createElement("div");
      // leftButtonContainer.setAttribute("class", "leftButtonContainer");
      // let rightButtonContainer = document.createElement("div");
      // rightButtonContainer.setAttribute("class", "rightButtonContainer");
      // let leftButtonContainerParent = document.createElement("div");
      // leftButtonContainerParent.setAttribute(
      //   "class",
      //   "leftButtonContainerParent"
      // );
      // let rightButtonContainerParent = document.createElement("div");
      // rightButtonContainerParent.setAttribute(
      //   "class",
      //   "rightButtonContainerParent"
      // );
      // leftButtonContainer.append(moveButtonElement1);
      // rightButtonContainer.append(moveButtonElement2);
      // leftButtonContainer.append(cloneButtonElement1);
      // rightButtonContainer.append(cloneButtonElement2);
      // leftButtonContainer.append(deleteElement1);
      // rightButtonContainer.append(deleteElement2);
      // leftButtonContainer.append(flagElement1);
      // rightButtonContainer.append(flagElement2);
      // leftButtonContainerParent.append(leftButtonContainer);
      // rightButtonContainerParent.append(rightButtonContainer);
      // leftBranchElement[0].append(leftButtonContainerParent);
      // rightBranchElement[0].append(rightButtonContainerParent);
    } else if (type == "AUDIENCESPLIT") {
      element.innerHTML = document.getElementById("split-node-block").innerHTML;
      element
        .getElementsByClassName("split-node-container")[0]
        .setAttribute("id", "ab-" + this.activeNodeBlockId);
      // element
      //   .getElementsByClassName("right-node-container")[0]
      //   .setAttribute("id", "right-" + this.activeNodeBlockId);
      element
        .getElementsByClassName("split-branch-node-path")[0]
        .setAttribute("id", "ab-" + this.activeNodeBlockId + "-path-split");

      let pathGroupData = triggerEvent.nodeData.definition.groupName;
      let count = 0;
      for (var k in pathGroupData) {
        if (pathGroupData.hasOwnProperty(k)) {
          let sub_container = document.createElement("div");
          sub_container.setAttribute("class", "split-branch");
          if (pathGroupData[k] != "Everyone else")
            sub_container.setAttribute(
              "id",
              "sp-" + this.activeNodeBlockId + "-path-" + pathGroupData[k]
            );
          else
            sub_container.setAttribute(
              "id",
              "sp-" + this.activeNodeBlockId + "-path-others"
            );
          element
            .getElementsByClassName("split-branch-node-path")[0]
            .append(sub_container);
          let mainWraper = document.createElement("div");
          mainWraper.style.position = "relative";
          mainWraper.style.display = "flex";
          mainWraper.style.width = "106px";
          mainWraper.style.flexDirection = "column";
          mainWraper.style.alignItems = "center";
          mainWraper.style.marginLeft = "93px";
          if (pathGroupData[k] != "Everyone else")
            mainWraper.setAttribute(
              "id",
              "ab-" + this.activeNodeBlockId + "-path-" + pathGroupData[k]
            );
          else
            mainWraper.setAttribute(
              "id",
              "ab-" + this.activeNodeBlockId + "-path-others"
            );

          let inputWraper = document.createElement("div");

          inputWraper.style.background = "#065D72";
          inputWraper.setAttribute(
            "id",
            "split-branch" +
              this.activeNodeBlockId +
              "-path-" +
              pathGroupData[k]
          );
          inputWraper.style.display = "flex";
          inputWraper.style.width = "110px";
          inputWraper.style.position = "relative";
          inputWraper.style.padding = "5px";
          inputWraper.style.color = "#FFFFFF";
          inputWraper.style.justifyContent = "center";
          inputWraper.style.borderRadius = "4px";
          let wrapContent = `<div class="dynamic-split-vertical"></div> <div class="group-name" title='${pathGroupData[k]}'>${pathGroupData[k]}</div>`;
          // let inputElement = document.createElement("input");
          // inputElement.setAttribute("type", "number");
          // inputElement.setAttribute("class","abtest-input-area "+k);
          // inputElement.setAttribute("style","width: 48px;")
          // inputElement.value=pathGroupData[k];
          inputWraper.innerHTML = wrapContent;
          mainWraper.append(inputWraper);
          // inputElement.innerHTML=k;

          let leftBranchElement =
            element.getElementsByClassName("ab-node-container");
          // let divelement = document.createElement("div")
          let deleteElement1 = document.createElement("div");
          deleteElement1.setAttribute("class", "add-node-ab-branch");
          deleteElement1.innerHTML =
            document.getElementById("add-button").innerHTML;
          deleteElement1.addEventListener("click", (event) =>
            this.addNewNode(event, "SPLITBRANCH", pathGroupData[k])
          );
          let blockId = this.activeNodeBlockId;
          deleteElement1.addEventListener("mouseenter", (event) =>
            this.showOrHideBlockedOperationTooltip(
              event,
              true,
              blockId,
              "add-node-ab-branch"
            )
          );
          deleteElement1.addEventListener("mouseleave", (event) =>
            this.showOrHideBlockedOperationTooltip(
              event,
              false,
              blockId,
              "add-node-ab-branch"
            )
          );
          let flagElement = document.createElement("div");
          flagElement.setAttribute("class", "end-flag-ab");
          if (pathGroupData[k] != "Everyone else")
            flagElement.setAttribute(
              "id",
              "end-flag-" +
                this.activeNodeBlockId +
                "-split-" +
                pathGroupData[k]
            );
          else
            flagElement.setAttribute(
              "id",
              "end-flag-" + this.activeNodeBlockId + "-split-others"
            );
          flagElement.innerHTML = document.getElementById("end-flag").innerHTML;
          let leftButtonContainer = document.createElement("div");
          leftButtonContainer.style.padding = "25px 0px";
          leftButtonContainer.style.position = "relative";

          // let buttonWrap = `<div class="buttonWrapLine"></div>`
          // mainWraper.innerHTML = buttonWrap
          let buttonWrap = document.createElement("div");
          buttonWrap.setAttribute("class", "buttonWrapLine");
          mainWraper.append(buttonWrap);
          let leftButtonContainerParent = document.createElement("div");
          leftButtonContainerParent.setAttribute(
            "class",
            "leftButtonContainerParent"
          );
          let cloneButtonElement1 = document.createElement("div");
          cloneButtonElement1.setAttribute(
            "class",
            "multi-branch-clone-button"
          );
          cloneButtonElement1.innerHTML =
            document.getElementById("clone-button").innerHTML;
          cloneButtonElement1.addEventListener("click", (event) =>
            this.cloneNode(event)
          );
          let moveButtonElement1 = document.createElement("div");
          moveButtonElement1.setAttribute("class", "multi-branch-move-button");
          moveButtonElement1.innerHTML =
            document.getElementById("move-button").innerHTML;
          moveButtonElement1.addEventListener("click", (event) =>
            this.moveNode(event)
          );
          let buttonWrap2 = document.createElement("div");
          buttonWrap.setAttribute("class", "buttonWrapLine2");
          if (pathGroupData[k] != "Everyone else")
            buttonWrap.setAttribute(
              "id",
              "wrap-split-" + this.activeNodeBlockId + "-id-" + pathGroupData[k]
            );
          else
            buttonWrap.setAttribute(
              "id",
              "wrap-split-" + this.activeNodeBlockId + "-id-others"
            );

          // parent.append(buttonWrap)
          leftButtonContainer.append(deleteElement1);
          leftButtonContainer.append(flagElement);
          leftButtonContainer.append(buttonWrap2);
          mainWraper.append(cloneButtonElement1);
          mainWraper.append(moveButtonElement1);
          // leftButtonContainerParent.append(leftButtonContainer);

          // leftBranchElement.append(deleteElement1)
          mainWraper.append(leftButtonContainer);
          sub_container.append(mainWraper);
          element
            .getElementsByClassName("split-branch-node-path")[0]
            .append(sub_container);

          // leftBranchElement[0].append(leftButtonContainerParent);
          count++;
        }
      }
      let abTestWrapper = <HTMLElement>(
        element.getElementsByClassName("split-wrapper")[0]
      );
      abTestWrapper.setAttribute(
        "id",
        "split-wrapper-" + this.activeNodeBlockId
      );
      // abTestWrapper.style.width = `${(count - 1) * 500}px`;

      let leftBranchElement = element.getElementsByClassName(
        "split-node-container"
      );
      let deleteElement1 = document.createElement("div");
      deleteElement1.setAttribute("class", "add-node-split-branch");
      deleteElement1.innerHTML =
        document.getElementById("add-button").innerHTML;
      deleteElement1.addEventListener("click", (event) =>
        this.addNewNode(event, "BRANCHED")
      );
      let blockId = this.activeNodeBlockId;
      deleteElement1.addEventListener("mouseenter", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          true,
          blockId,
          "add-node-split-branch"
        )
      );
      deleteElement1.addEventListener("mouseleave", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          false,
          blockId,
          "add-node-split-branch"
        )
      );
      let rightBranchElement = element.getElementsByClassName(
        "split-node-container"
      );

      let flagElement1 = document.createElement("div");
      flagElement1.setAttribute("class", "end-flag-left-branch");
      flagElement1.innerHTML = document.getElementById("end-flag").innerHTML;

      if (operation === "CREATE") {
        // Finding out if there is a node with node id of the previous node as the previous node id
        let nextNodeIndex = this.nodeList.findIndex(
          (x) =>
            x.previousNodeId ===
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === previousNodeBlockId
              )
            ].nodeId
        );
        // If there is a node
        if (nextNodeIndex > -1) {
          //Checking if there previous node is a conditional node
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeBlockId === previousNodeBlockId
                )
              ].nodeType
            ) === "UNBRANCHED"
          ) {
            //If not a conditional node, showing the popup to select what to do with the child nodes.
            this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
            let dialogRef = this._dialog.open(
              NodeDeleteBranchSelectorComponent,
              {
                panelClass: "no-padding-dialog-popup",
                data: {
                  operation: "add",
                  nodeData: pathGroupData,
                  type: "multiple-split",
                },
                disableClose: true,
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                if (result["status"]) {
                  document
                    .getElementById(this.nodeList[nextNodeIndex].nodeBlockId)
                    .insertAdjacentElement("beforebegin", element);
                  this.addNodeDataOnCreate(triggerEvent, "CREATE");
                  this.appendAudienceBranchNodesToConditionalNode(
                    result["value"],
                    element,
                    this.nodeList[nextNodeIndex].nodeBlockId,
                    triggerEvent
                  );
                } else {
                  this.deletenodeApi(triggerEvent["nodeId"], "NONE");
                  let nodeApiData = {
                    definition: JSON.stringify(
                      this.nodeList[nextNodeIndex].nodeData["definition"]
                    ),
                    nodeType: this.nodeList[nextNodeIndex].nodeType,
                    previousNodeId: this.nodeList[nextNodeIndex].previousNodeId,
                    previousNodeRelation:
                      this.nodeList[nextNodeIndex].previousNodeRelation,
                  };
                  this.addOrRemovePointerEvents(false);
                  this._apiCallService
                    .updateNode(
                      this.strategyId,
                      this.nodeList[nextNodeIndex].nodeId,
                      nodeApiData
                    )
                    .subscribe(
                      () => {
                        this.addOrRemovePointerEvents(true);
                      },
                      (err) => {
                        this.addOrRemovePointerEvents(true);
                      }
                    );
                }
              }
              let split_container = document.getElementById(
                "sp-" + this.activeNodeBlockId + "-path-others"
              );
              split_container.setAttribute("class", "split-branch-last");
            });
          } else {
            // If the previous node is a conditional node
            let parentOfnewNode =
              event.srcElement.parentElement.parentElement.parentElement
                .parentElement;
            let childNodesOfConditionalNode =
              parentOfnewNode.getElementsByClassName("node-block-container");
            if (childNodesOfConditionalNode.length > 0) {
              let nextNodeId = childNodesOfConditionalNode[0].id;
              this.nextNodeId = this.getNodeIdFromList(
                childNodesOfConditionalNode[0].id
              );
              let dialogRef = this._dialog.open(
                NodeDeleteBranchSelectorComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  data: {
                    operation: "add",
                    nodeData: pathGroupData,
                    type: "multiple-split",
                  },
                  disableClose: true,
                }
              );
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  if (result["status"]) {
                    event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                      "afterend",
                      element
                    );
                    this.addNodeDataOnCreate(triggerEvent, "CREATE");
                    this.appendAudienceBranchNodesToConditionalNode(
                      result["value"],
                      element,
                      nextNodeId,
                      triggerEvent
                    );
                  } else {
                    this.deletenodeApi(triggerEvent["nodeId"], "NONE");
                    let nextNodeIndex = this.nodeList.findIndex(
                      (x) => x.nodeId === this.nextNodeId
                    );
                    let nodeApiData = {
                      definition: JSON.stringify(
                        this.nodeList[nextNodeIndex].nodeData["definition"]
                      ),
                      nodeType: this.nodeList[nextNodeIndex].nodeType,
                      previousNodeId:
                        this.nodeList[nextNodeIndex].previousNodeId,
                      previousNodeRelation:
                        this.nodeList[nextNodeIndex].previousNodeRelation,
                    };
                    this.addOrRemovePointerEvents(false);
                    this._apiCallService
                      .updateNode(
                        this.strategyId,
                        this.nodeList[nextNodeIndex].nodeId,
                        nodeApiData
                      )
                      .subscribe(
                        () => {
                          this.addOrRemovePointerEvents(true);
                        },
                        (err) => {
                          this.addOrRemovePointerEvents(true);
                        }
                      );
                  }
                }
                let split_container = document.getElementById(
                  "sp-" + this.activeNodeBlockId + "-path-others"
                );
                split_container.setAttribute("class", "split-branch-last");
              });
            } else {
              event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                "afterend",
                element
              );
              let split_container = document.getElementById(
                "sp-" + this.activeNodeBlockId + "-path-others"
              );
              split_container.setAttribute("class", "split-branch-last");
            }
          }
        } else {
          this.nextNodeId = null;
          //APPENDING CUSTOMER COUNT ABOVE THE NODE
          if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
            let customerCountElement = document.createElement("div");
            customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
            customerCountElement.setAttribute("class", "customer-count");
            if(this.strategyStatus == 'ACTIVE')
              customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
            element.append(customerCountElement);
          }
          parent.append(element);
          let split_container = document.getElementById(
            "sp-" + this.activeNodeBlockId + "-path-others"
          );
          split_container.setAttribute("class", "split-branch-last");
        }
      } else {
        this.nextNodeId = null;
        //APPENDING CUSTOMER COUNT ABOVE THE NODE
        if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
          let customerCountElement = document.createElement("div");
          customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
          customerCountElement.setAttribute("class", "customer-count");
          if(this.strategyStatus == 'ACTIVE')
            customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
          element.append(customerCountElement);
        }
        parent.append(element);
        let split_container = document.getElementById(
          "sp-" + this.activeNodeBlockId + "-path-others"
        );
        split_container.setAttribute("class", "split-branch-last");
      }

      // this.nextNodeId = null;
      // parent.append(element);
      // let width = split_container.offsetWidth;
    } else {
      element.innerHTML =
        document.getElementById("branch-node-block").innerHTML;
      element
        .getElementsByClassName("left-node-container")[0]
        .setAttribute("id", "left-" + this.activeNodeBlockId);
      element
        .getElementsByClassName("right-node-container")[0]
        .setAttribute("id", "right-" + this.activeNodeBlockId);
      let leftBranchElement = element.getElementsByClassName(
        "left-node-container"
      );
      let deleteElement1 = document.createElement("div");
      deleteElement1.setAttribute("class", "add-node-left-branch");
      deleteElement1.innerHTML =
        document.getElementById("add-button").innerHTML;
      deleteElement1.addEventListener("click", (event) =>
        this.addNewNode(event, "BRANCHED")
      );
      let blockId = this.activeNodeBlockId;
      deleteElement1.addEventListener("mouseenter", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          true,
          blockId,
          "add-node-left-branch"
        )
      );
      deleteElement1.addEventListener("mouseleave", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          false,
          blockId,
          "add-node-left-branch"
        )
      );
      let rightBranchElement = element.getElementsByClassName(
        "right-node-container"
      );
      let moveButtonElement1 = document.createElement("div");
      moveButtonElement1.setAttribute("class", "branch-move-button");
      moveButtonElement1.innerHTML =
        document.getElementById("move-button").innerHTML;
      moveButtonElement1.addEventListener("click", (event) =>
        this.moveNode(event)
      );
      let moveButtonElement2 = document.createElement("div");
      moveButtonElement2.setAttribute("class", "branch-move-button");
      moveButtonElement2.innerHTML =
        document.getElementById("move-button").innerHTML;
      moveButtonElement2.addEventListener("click", (event) =>
        this.moveNode(event)
      );
      let cloneButtonElement1 = document.createElement("div");
      cloneButtonElement1.setAttribute("class", "branch-clone-button");
      cloneButtonElement1.innerHTML =
        document.getElementById("clone-button").innerHTML;
      cloneButtonElement1.addEventListener("click", (event) =>
        this.cloneNode(event)
      );
      let cloneButtonElement2 = document.createElement("div");
      cloneButtonElement2.setAttribute("class", "branch-clone-button");
      cloneButtonElement2.innerHTML =
        document.getElementById("clone-button").innerHTML;
      cloneButtonElement2.addEventListener("click", (event) =>
        this.cloneNode(event)
      );
      let deleteElement2 = document.createElement("div");
      deleteElement2.setAttribute("class", "add-node-right-branch");
      deleteElement2.innerHTML =
        document.getElementById("add-button").innerHTML;
      deleteElement2.addEventListener("click", (event) =>
        this.addNewNode(event, "BRANCHED")
      );
      let blockId2 = this.activeNodeBlockId;
      deleteElement2.addEventListener("mouseenter", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          true,
          blockId2,
          "add-node-right-branch"
        )
      );
      deleteElement2.addEventListener("mouseleave", (event) =>
        this.showOrHideBlockedOperationTooltip(
          event,
          false,
          blockId2,
          "add-node-right-branch"
        )
      );
      let flagElement1 = document.createElement("div");
      flagElement1.setAttribute("class", "end-flag-left-branch");
      flagElement1.innerHTML = document.getElementById("end-flag").innerHTML;
      let flagElement2 = document.createElement("div");
      flagElement2.setAttribute("class", "end-flag-right-branch");
      flagElement2.innerHTML = document.getElementById("end-flag").innerHTML;
      if (operation === "CREATE") {
        // Finding out if there is a node with node id of the previous node as the previous node id
        let nextNodeIndex = this.nodeList.findIndex(
          (x) =>
            x.previousNodeId ===
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === previousNodeBlockId
              )
            ].nodeId
        );
        // If there is a node
        if (nextNodeIndex > -1) {
          //Checking if there previous node is a conditional node
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeBlockId === previousNodeBlockId
                )
              ].nodeType
            ) === "UNBRANCHED"
          ) {
            //If not a conditional node, showing the popup to select what to do with the child nodes.
            this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
            let dialogRef = this._dialog.open(
              NodeDeleteBranchSelectorComponent,
              {
                panelClass: "no-padding-dialog-popup",
                data: {
                  operation: "add",
                },
                disableClose: true,
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                if (result["status"]) {
                  document
                    .getElementById(this.nodeList[nextNodeIndex].nodeBlockId)
                    .insertAdjacentElement("beforebegin", element);
                  this.addNodeDataOnCreate(triggerEvent, "CREATE");
                  this.appendBranchNodesToConditionalNode(
                    result["value"],
                    element,
                    this.nodeList[nextNodeIndex].nodeBlockId,
                    triggerEvent
                  );
                } else {
                  this.deletenodeApi(triggerEvent["nodeId"], "NONE");
                  let nodeApiData = {
                    definition: JSON.stringify(
                      this.nodeList[nextNodeIndex].nodeData["definition"]
                    ),
                    nodeType: this.nodeList[nextNodeIndex].nodeType,
                    previousNodeId: this.nodeList[nextNodeIndex].previousNodeId,
                    previousNodeRelation:
                      this.nodeList[nextNodeIndex].previousNodeRelation,
                  };
                  this.addOrRemovePointerEvents(false);
                  this._apiCallService
                    .updateNode(
                      this.strategyId,
                      this.nodeList[nextNodeIndex].nodeId,
                      nodeApiData
                    )
                    .subscribe(
                      () => {
                        this.addOrRemovePointerEvents(true);
                      },
                      (err) => {
                        this.addOrRemovePointerEvents(true);
                      }
                    );
                }
              }
            });
          } else {
            // If the previous node is a conditional node
            let parentOfnewNode =
              event.srcElement.parentElement.parentElement.parentElement
                .parentElement;
            let childNodesOfConditionalNode =
              parentOfnewNode.getElementsByClassName("node-block-container");
            if (childNodesOfConditionalNode.length > 0) {
              let nextNodeId = childNodesOfConditionalNode[0].id;
              this.nextNodeId = this.getNodeIdFromList(
                childNodesOfConditionalNode[0].id
              );
              let dialogRef = this._dialog.open(
                NodeDeleteBranchSelectorComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  data: {
                    operation: "add",
                  },
                  disableClose: true,
                }
              );
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  if (result["status"]) {
                    event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                      "afterend",
                      element
                    );
                    this.addNodeDataOnCreate(triggerEvent, "CREATE");
                    this.appendBranchNodesToConditionalNode(
                      result["value"],
                      element,
                      nextNodeId,
                      triggerEvent
                    );
                  } else {
                    this.deletenodeApi(triggerEvent["nodeId"], "NONE");
                    let nextNodeIndex = this.nodeList.findIndex(
                      (x) => x.nodeId === this.nextNodeId
                    );
                    let nodeApiData = {
                      definition: JSON.stringify(
                        this.nodeList[nextNodeIndex].nodeData["definition"]
                      ),
                      nodeType: this.nodeList[nextNodeIndex].nodeType,
                      previousNodeId:
                        this.nodeList[nextNodeIndex].previousNodeId,
                      previousNodeRelation:
                        this.nodeList[nextNodeIndex].previousNodeRelation,
                    };
                    this.addOrRemovePointerEvents(false);
                    this._apiCallService
                      .updateNode(
                        this.strategyId,
                        this.nodeList[nextNodeIndex].nodeId,
                        nodeApiData
                      )
                      .subscribe(
                        () => {
                          this.addOrRemovePointerEvents(true);
                        },
                        (err) => {
                          this.addOrRemovePointerEvents(true);
                        }
                      );
                  }
                }
              });
            } else {
              event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
                "afterend",
                element
              );
            }
          }
        } else {
          this.nextNodeId = null;
          //APPENDING CUSTOMER COUNT ABOVE THE NODE
          if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
            let customerCountElement = document.createElement("div");
            customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
            customerCountElement.setAttribute("class", "customer-count");
            if(this.strategyStatus == 'ACTIVE')
              customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
            element.append(customerCountElement);
          }
          parent.append(element);
        }
      } else {
        this.nextNodeId = null;
        //APPENDING CUSTOMER COUNT ABOVE THE NODE
        if(this.strategyStatus == 'ACTIVE' || this.strategyStatus == 'ENDED') {
          let customerCountElement = document.createElement("div");
          customerCountElement.innerHTML = `<span class="material-symbols-outlined">group</span>${triggerEvent.customerCount}`;
          customerCountElement.setAttribute("class", "customer-count");
          if(this.strategyStatus == 'ACTIVE')
            customerCountElement.setAttribute("title", "Number of unique customers currently at the node");
          element.append(customerCountElement);
        }
        parent.append(element);
      }
      //let leftButtonContainerParent = document.createElement("div");
      //let rightButtonContainerParent = document.createElement("div");
      let leftButtonContainer = document.createElement("div");
      leftButtonContainer.setAttribute("class", "leftButtonContainer");
      let rightButtonContainer = document.createElement("div");
      rightButtonContainer.setAttribute("class", "rightButtonContainer");
      let leftButtonContainerParent = document.createElement("div");
      leftButtonContainerParent.setAttribute(
        "class",
        "leftButtonContainerParent"
      );
      let rightButtonContainerParent = document.createElement("div");
      rightButtonContainerParent.setAttribute(
        "class",
        "rightButtonContainerParent"
      );
      leftButtonContainer.append(moveButtonElement1);
      rightButtonContainer.append(moveButtonElement2);
      leftButtonContainer.append(cloneButtonElement1);
      rightButtonContainer.append(cloneButtonElement2);
      leftButtonContainer.append(deleteElement1);
      rightButtonContainer.append(deleteElement2);
      leftButtonContainer.append(flagElement1);
      rightButtonContainer.append(flagElement2);
      leftButtonContainerParent.append(leftButtonContainer);
      rightButtonContainerParent.append(rightButtonContainer);
      leftBranchElement[0].append(leftButtonContainerParent);
      rightBranchElement[0].append(rightButtonContainerParent);
    }
  }

  /* Differentiating the nodes on the number of childeren
  BRANCHED - Nodes with two childerens
  UNBRANCHED - Nodes with one child */
  getNodeBranchType(nodeType) {
    switch (nodeType) {
      case "WAIT":
      case "CHANNEL":
      case "OFFER":
      case "SURVEY":
      case "TRIGGER":
      case "COMARKETING_OFFER":
        return "UNBRANCHED";
      case "RESPONSE_EVENT":
      case "ABTESTING":
      case "AUDIENCESPLIT":
        return "BRANCHED";
      // return "GROUPBRANCH"
    }
  }

  deleteNode() {
    this.activeNodeBlockId = this.nodeOperationBlockId;
    let nodeIndex = this.nodeList.findIndex(
      (x) => x.nodeBlockId === this.nodeOperationBlockId
    );
    let nextNodeIndex = this.nodeList.findIndex(
      (x) => x.previousNodeId === this.nodeList[nodeIndex].nodeId
    );
    if (
      this.getNodeBranchType(this.nodeList[nodeIndex].nodeType) === "UNBRANCHED"
    ) {
      if (nextNodeIndex > -1) {
        this.nodeList[nextNodeIndex].previousNodeId =
          this.nodeList[nodeIndex].previousNodeId;
        this.nodeList[nextNodeIndex].previousNodeRelation =
          this.nodeList[nodeIndex].previousNodeRelation;
        if (
          this.nodeList[nodeIndex].previousNodeRelation !== "NEXT" &&
          this.nodeList[nodeIndex].previousNodeRelation !== "TIMEOUT"
        ) {
          this.addNextNodeIndexIfDelete(
            this.nodeList[nextNodeIndex].nodeId,
            this.nodeList[nodeIndex].previousNodeId,
            this.nodeList[nodeIndex].previousNodeRelation
          );
        }
      } else {
        if (
          this.nodeList[nodeIndex].previousNodeRelation !== "NEXT" &&
          this.nodeList[nodeIndex].previousNodeRelation !== "TIMEOUT"
        ) {
          this.addNextNodeIndexIfDeleteLast(
            this.nodeList[nodeIndex].previousNodeId,
            this.nodeList[nodeIndex].previousNodeRelation
          );
        }
        this.addEndFlagToPreviousNode(this.nodeOperationBlockId);
      }
      this.removeNodeFromView();
      this.removeNodeFromList();
    } else {
      if (
        this.nodeList[nodeIndex].previousNodeRelation !== "NEXT" &&
        this.nodeList[nodeIndex].previousNodeRelation !== "TIMEOUT"
      ) {
        this.addNextNodeIndexIfDeleteLast(
          this.nodeList[nodeIndex].previousNodeId,
          this.nodeList[nodeIndex].previousNodeRelation
        );
      }
      if (this.nodeList[nodeIndex].nodeType === "RESPONSE_EVENT") {
        let dialogRef = this._dialog.open(NodeDeleteBranchSelectorComponent, {
          panelClass: "no-padding-dialog-popup",
          data: {
            operation: "delete",
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.deleteBranchedNode(result);
          }
        });
      } else {
        if (this.nodeList[nodeIndex].nodeType !== "AUDIENCESPLIT") {
          this.deletePathBranch("ALL");
        } else {
          let dialogRef = this._dialog.open(NodeDeleteBranchSelectorComponent, {
            panelClass: "no-padding-dialog-popup",
            data: {
              operation: "delete",
              nodeData: this.nodeList[nodeIndex],
              type: "multiple",
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (this.nodeList[nodeIndex].nodeType === "AUDIENCESPLIT")
                this.deleteSplitBranch(result);
              else {
              }
            }
          });
        }
      }
      // let childCount = this.nodeList.reduce(
      //   (a, v) =>
      //     v.previousNodeId === this.nodeList[nodeIndex].nodeId ? a + 1 : a,
      //   0
      // );
      // if (childCount === 0) {
      //   this.removeBranchedNodes(this.nodeList[nodeIndex].nodeId);
      // } else {
      //   let deleteConfig;
      //   if (childCount === 1) {
      //     if (
      //       this.nodeList[
      //         this.nodeList.findIndex(
      //           (x) => x.previousNodeId === this.nodeList[nodeIndex].nodeId
      //         )
      //       ].previousNodeRelation === "NEXT"
      //     ) {
      //       deleteConfig = {
      //         left: true,
      //         right: false,
      //       };
      //     } else {
      //       deleteConfig = {
      //         left: false,
      //         right: true,
      //       };
      //     }
      //   } else {
      //     deleteConfig = {
      //       left: true,
      //       right: true,
      //     };
      //   }
      //   let dialogRef = this._dialog.open(NodeDeleteBranchSelectorComponent, {
      //     panelClass: "no-padding-dialog-popup",
      //     data: deleteConfig,
      //   });
      //   dialogRef.afterClosed().subscribe((result) => {
      //     if (result) {
      //       this.deleteBranchedNode(result);
      //     }
      //   });
      // }
    }
    this.hideAllEvents();
  }
  async deleteSplitBranch(name) {
    switch (name) {
      case 3:
        let deleteAllNodePromise = await this.deletenodeApi(
          this.nodeList[
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === this.nodeOperationBlockId
            )
          ].nodeId,
          "ALL"
        );
        if (deleteAllNodePromise) {
          this.addEndFlagToPreviousNode(this.activeNodeBlockId);
          this.deleteConditionalNode(this.activeNodeBlockId);
          this.removeNodeFromView();
          this.updateStrategy();
        }
        break;

      default:
        let pathName = "";
        let nodeIdIndex = this.nodeList.findIndex(
          (x) => x.nodeBlockId === this.activeNodeBlockId
        );
        let nextIndex = this.nodeList.findIndex(
          (x) => x.previousNodeId === this.nodeList[nodeIdIndex]["nodeId"]
        );
        let temp_data = this.nodeList[nextIndex];
        if (name != "Everyone else") {
          pathName = "AS##" + name;
        } else {
          name = "others";
          pathName = "AS##others";
        }
        let node_id =
          this.nodeList[
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === this.nodeOperationBlockId
            )
          ].nodeId;
        if (
          this.nodeList.findIndex(
            (x) =>
              x.previousNodeId === node_id &&
              x.previousNodeRelation === "SPLIT##" + name
          ) !== -1
        ) {
          let deleteSplitNodePromise = await this.deletenodeApi(
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === this.nodeOperationBlockId
              )
            ].nodeId,
            pathName
          );
          if (deleteSplitNodePromise) {
            let blockNumber2 = this.activeNodeBlockId.substring(
              5,
              this.activeNodeBlockId.length
            );
            // let nodeId2 = "left-node-" + blockNumber2;
            // let content2 = document.getElementById(nodeId2);
            // let childeren2 = content2.getElementsByClassName(
            //   "node-block-container"
            // );
            //content2.removeChild(childeren2[0]);
            let previousNodeId2 = document.getElementById(
              this.activeNodeBlockId
            ).previousElementSibling.id;
            if (!previousNodeId2) {
              previousNodeId2 = document.getElementById(this.activeNodeBlockId)
                .parentElement.id;
              previousNodeId2 =
                "node-" + previousNodeId2.substring(10, previousNodeId2.length);
            }
            let deleteNodeId2 =
              "sp-node-" +
              blockNumber2 +
              "-path-" +
              pathName.substring(4, pathName.length);
            let deleteContent2 = document.getElementById(deleteNodeId2);
            let deleteChilderen2 = deleteContent2.getElementsByClassName(
              "node-block-container"
            );
            if (deleteChilderen2.length > 0) {
              let deleteChildIndex2 = this.nodeList.findIndex(
                (x) => x.nodeBlockId === deleteChilderen2[0].id
              );
              if (
                this.getNodeBranchType(
                  this.nodeList[deleteChildIndex2].nodeType
                ) === "BRANCHED"
              ) {
                this.removeBranchedNodesFromView(this.nodeList[deleteChildIndex2].nodeId);
                this.deleteConditionalNode(
                  this.nodeList[deleteChildIndex2].nodeBlockId
                );
              } else {
                this.removeBranchedNodesFromView(this.nodeList[deleteChildIndex2].nodeId);
                this.removeBranchedNodes(
                  this.nodeList[deleteChildIndex2].nodeId
                );
              }
              // deleteContent2.removeChild(deleteChilderen2[0]);
            }

            this.addEndFlagToPreviousNodeSplit(temp_data);

            this.updateStrategy();
          }
        } else {
          this._snackBar.openSnackBar("There is no node to delete", 4000);
          break;
        }
    }
  }
  deletePathBranch(name) {
    const dialogRef1 = this._dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject:
          "Delete " +
          this._EventIconMappingService.getBlockDisplayName(
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === this.nodeOperationBlockId
              )
            ].nodeType
          ) +
          " Block",
        message: "Click confirm to Delete",
        cancelButtonText: "Cancel",
        successButtonText: "Confirm",
      },
    });
    dialogRef1.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteUnbranchNode("ALL");
        // let deletePathNodePromise =  this.deletenodeApi(
        //   this.nodeList[
        //     this.nodeList.findIndex(
        //       (x) => x.nodeBlockId === this.nodeOperationBlockId
        //     )
        //   ].nodeId,
        //   name
        // );
      }
    });
  }

  async appendBranchNodesToConditionalNode(
    result,
    element,
    nextNodeBlockId,
    triggerEvent
  ) {
    let blockNumber = this.activeNodeBlockId.substring(5, element.id.length);
    //1-Discard
    //2-Append to left branch
    //3-Append to right branch
    switch (result) {
      case 1:
        let indexOfNode = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        let deleteAllNodePromise = await this.deletenodeApi(
          this.nodeList[indexOfNode].nodeId,
          "ALL"
        );
        if (deleteAllNodePromise) {
          let parentNode =
            document.getElementById(nextNodeBlockId).parentElement;
          this.removeNodesInBranchFromView(nextNodeBlockId, parentNode);
          if (
            this.getNodeBranchType(this.nodeList[indexOfNode].nodeType) ===
            "BRANCHED"
          ) {
            this.deleteConditionalNode(nextNodeBlockId);
          } else {
            this.removeBranchedNodes(this.nodeList[indexOfNode].nodeId);
          }
          this.updateStrategy();
        }
        break;
      case 2:
        let nextNodeIndex = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        let nodeApiData = {
          definition: JSON.stringify(
            this.nodeList[nextNodeIndex].nodeData["definition"]
          ),
          nodeType: this.nodeList[nextNodeIndex].nodeType,
          previousNodeId: triggerEvent.nodeId,
          previousNodeRelation: "NEXT",
        };
        this.addOrRemovePointerEvents(false);
        this._apiCallService
          .updateNode(
            this.strategyId,
            this.nodeList[nextNodeIndex].nodeId,
            nodeApiData
          )
          .subscribe(
            (response) => {
              this.addOrRemovePointerEvents(true);
              document
                .getElementById(this.activeNodeBlockId)
                .getElementsByClassName("end-flag-left-branch")[0]
                .setAttribute("style", "display:none");
              this.addNodesToBranch(
                document.getElementById("left-node-" + blockNumber),
                nextNodeBlockId
              );
              this.nodeList[nextNodeIndex].previousNodeId = triggerEvent.nodeId;
              this.updateStrategy();
            },
            (err) => {
              this.addOrRemovePointerEvents(true);
            }
          );
        break;
      case 3:
        let nextNodeIndex1 = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        let nodeApiData1 = {
          definition: JSON.stringify(
            this.nodeList[nextNodeIndex1].nodeData["definition"]
          ),
          nodeType: this.nodeList[nextNodeIndex1].nodeType,
          previousNodeId: triggerEvent.nodeId,
          previousNodeRelation: "TIMEOUT",
        };
        this.addOrRemovePointerEvents(false);
        this._apiCallService
          .updateNode(
            this.strategyId,
            this.nodeList[nextNodeIndex1].nodeId,
            nodeApiData1
          )
          .subscribe(
            (response) => {
              this.addOrRemovePointerEvents(true);
              document
                .getElementById(this.activeNodeBlockId)
                .getElementsByClassName("end-flag-right-branch")[0]
                .setAttribute("style", "display:none");
              this.addNodesToBranch(
                document.getElementById("right-node-" + blockNumber),
                nextNodeBlockId
              );
              this.nodeList[nextNodeIndex1].previousNodeRelation = "TIMEOUT";
              this.nodeList[nextNodeIndex1].previousNodeId =
                triggerEvent.nodeId;
              this.updateStrategy();
            },
            (err) => {
              this.addOrRemovePointerEvents(true);
            }
          );
        break;
    }
  }

  addNodesToBranch(nodeElement, nextNodeBlockId) {
    let elementToMove = document.getElementById(nextNodeBlockId);
    nodeElement.append(elementToMove);
    let indexOfNode = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nextNodeBlockId
    );
    if (
      this.getNodeBranchType(this.nodeList[indexOfNode].nodeType) !== "BRANCHED"
    ) {
      let indexOfNextNode = this.nodeList.findIndex(
        (x) => x.previousNodeId === this.nodeList[indexOfNode].nodeId
      );
      if (indexOfNextNode > -1) {
        this.addNodesToBranch(
          nodeElement,
          this.nodeList[indexOfNextNode].nodeBlockId
        );
      }
    }
  }

  removeNodesInBranchFromView(nodeBlockId, parentElement) {
    let indexOfNode = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nodeBlockId
    );
    if (
      this.getNodeBranchType(this.nodeList[indexOfNode].nodeType) === "BRANCHED"
    ) {
      parentElement.removeChild(document.getElementById(nodeBlockId));
    } else {
      parentElement.removeChild(document.getElementById(nodeBlockId));
      let indexOfNextNode = this.nodeList.findIndex(
        (x) => x.previousNodeId === this.nodeList[indexOfNode].nodeId
      );
      if (indexOfNextNode > -1) {
        this.removeNodesInBranchFromView(
          this.nodeList[indexOfNextNode].nodeBlockId,
          parentElement
        );
      }
    }
  }

  async deleteBranchedNode(type) {
    switch (type) {
      case 3:
        let deleteAllNodePromise = await this.deletenodeApi(
          this.nodeList[
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === this.nodeOperationBlockId
            )
          ].nodeId,
          "ALL"
        );
        if (deleteAllNodePromise) {
          this.addEndFlagToPreviousNode(this.activeNodeBlockId);
          this.deleteConditionalNode(this.activeNodeBlockId);
          this.removeNodeFromView();
          this.updateStrategy();
        }
        break;
      case 2:
        let deleteLeftNodePromise = await this.deletenodeApi(
          this.nodeList[
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === this.nodeOperationBlockId
            )
          ].nodeId,
          "TIMEOUT"
        );
        if (deleteLeftNodePromise) {
          let blockNumber2 = this.activeNodeBlockId.substring(
            5,
            this.activeNodeBlockId.length
          );
          let nodeId2 = "left-node-" + blockNumber2;
          let content2 = document.getElementById(nodeId2);
          let childeren2 = content2.getElementsByClassName(
            "node-block-container"
          );
          //content2.removeChild(childeren2[0]);
          let previousNodeId2 = document.getElementById(this.activeNodeBlockId)
            .previousElementSibling.id;
            let previousNodeIdPath = previousNodeId2;
            if (previousNodeId2.includes("path")) {
              let match = previousNodeId2.match(/node-\d+/);
              // previousNodeId3 = document.getElementById(this.activeNodeBlockId)
              //   .parentElement.id;
              // previousNodeId3 =
              //   "node-" + previousNodeId3.substring(10, previousNodeId3.length);
              if(match){
                previousNodeId2 = match[0];
              }
            }
          let deleteNodeId2 = "right-node-" + blockNumber2;
          let deleteContent2 = document.getElementById(deleteNodeId2);
          let deleteChilderen2 = deleteContent2.getElementsByClassName(
            "node-block-container"
          );
          if (deleteChilderen2.length > 0) {
            let deleteChildIndex2 = this.nodeList.findIndex(
              (x) => x.nodeBlockId === deleteChilderen2[0].id
            );
            if (
              this.getNodeBranchType(
                this.nodeList[deleteChildIndex2].nodeType
              ) === "BRANCHED"
            ) {
              this.deleteConditionalNode(
                this.nodeList[deleteChildIndex2].nodeBlockId
              );
            } else {
              this.removeBranchedNodes(this.nodeList[deleteChildIndex2].nodeId);
            }
            deleteContent2.removeChild(deleteChilderen2[0]);
          }
          // this.nodeList.splice(
          //   this.nodeList.findIndex(
          //     (x) => x.nodeBlockId === this.activeNodeBlockId
          //   ),
          //   1
          // );
          let parent2 = document.getElementById(
            this.activeNodeBlockId
          ).parentElement;
          parent2.removeChild(document.getElementById(this.activeNodeBlockId));
          if (childeren2.length > 0) {
            let nextNodeIndex = this.nodeList.findIndex(
              (x) => x.nodeBlockId === childeren2[0].id
            );
            let previousNodeIndex = this.nodeList.findIndex(
              (x) => x.nodeBlockId === previousNodeId2
            );
            this.nodeList[
              this.nodeList.findIndex((x) => x.nodeBlockId === childeren2[0].id)
            ].previousNodeId =
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeBlockId === previousNodeId2
                )
              ].nodeId;
              if(previousNodeIdPath.includes("path")){
                let match = previousNodeIdPath.match(/path-(.+)/i);
                if (match) {
                  let path = match[1];
                  this.nodeList[nextNodeIndex].previousNodeRelation = this.nodeList[previousNodeIndex].nodeType !== "ABTESTING" ? "SPLIT##" + path : "PATH##" + path;
                }
              }
              else{
                this.nodeList[nextNodeIndex].previousNodeRelation = "NEXT";
              }
            while (childeren2.length > 0) {
              parent2.append(childeren2[0]);
            }
          }
          this.updateStrategy();
        }
        break;
      case 1:
        let deleteRightNodePromise = await this.deletenodeApi(
          this.nodeList[
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === this.nodeOperationBlockId
            )
          ].nodeId,
          "NEXT"
        );
        if (deleteRightNodePromise) {
          let blockNumber3 = this.activeNodeBlockId.substring(
            5,
            this.activeNodeBlockId.length
          );
          let nodeId3 = "right-node-" + blockNumber3;
          let content3 = document.getElementById(nodeId3);
          let childeren3 = content3.getElementsByClassName(
            "node-block-container"
          );
          //content3.removeChild(childeren3[0]);
          let previousNodeId3 = document.getElementById(this.activeNodeBlockId)
            .previousElementSibling.id;
          let previousNodeIdPath = previousNodeId3;
          if (previousNodeId3.includes("path")) {
            let match = previousNodeId3.match(/node-\d+/);
            // previousNodeId3 = document.getElementById(this.activeNodeBlockId)
            //   .parentElement.id;
            // previousNodeId3 =
            //   "node-" + previousNodeId3.substring(10, previousNodeId3.length);
            if(match){
              previousNodeId3 = match[0];
            }
          }
          let deleteNodeId3 = "left-node-" + blockNumber3;
          let deleteContent3 = document.getElementById(deleteNodeId3);
          let deleteChilderen3 = deleteContent3.getElementsByClassName(
            "node-block-container"
          );
          if (deleteChilderen3.length > 0) {
            let deleteChildIndex3 = this.nodeList.findIndex(
              (x) => x.nodeBlockId === deleteChilderen3[0].id
            );
            if (
              this.getNodeBranchType(
                this.nodeList[deleteChildIndex3].nodeType
              ) === "BRANCHED"
            ) {
              this.deleteConditionalNode(
                this.nodeList[deleteChildIndex3].nodeBlockId
              );
            } else {
              this.removeBranchedNodes(this.nodeList[deleteChildIndex3].nodeId);
            }
            deleteContent3.removeChild(deleteChilderen3[0]);
          }
          let indexOfactive =     this.nodeList.findIndex(
            (x) => x.nodeBlockId === this.activeNodeBlockId
          );
          this.nodeList.splice(
            this.nodeList.findIndex(
              (x) => x.nodeBlockId === this.activeNodeBlockId
            ),
            1
          );
          // this.nodeList.splice(
          //   this.nodeList.findIndex(
          //     (x) => x.nodeBlockId === this.activeNodeBlockId
          //   ),
          //   1
          // );
          let parent3 = document.getElementById(
            this.activeNodeBlockId
          ).parentElement;
          parent3.removeChild(document.getElementById(this.activeNodeBlockId));
          if (childeren3.length > 0) {
            let nextNodeIndex = this.nodeList.findIndex(
              (x) => x.nodeBlockId === childeren3[0].id
            );
            let previousNodeIndex = this.nodeList.findIndex(
              (x) => x.nodeBlockId === previousNodeId3
            );
            this.nodeList[nextNodeIndex].previousNodeId =
              this.nodeList[previousNodeIndex].nodeId;
              if(previousNodeIdPath.includes("path")){
                let match = previousNodeIdPath.match(/path-(.+)/i);
                if (match) {
                  let path = match[1];
                  this.nodeList[nextNodeIndex].previousNodeRelation = this.nodeList[previousNodeIndex].nodeType !== "ABTESTING" ? "SPLIT##" + path : "PATH##" + path;
                }
              }
              else{
                this.nodeList[nextNodeIndex].previousNodeRelation = "NEXT";
              }
            while (childeren3.length > 0) {
              parent3.append(childeren3[0]);
            }
          }
          this.updateStrategy();
        }
        break;
    }
  }

  /*Removing the branched Nodes, nodeBlockId is the block Id of the conditional Node */
  deleteConditionalNode(nodeBlockId) {
    //Calculating the number of childeren for the conditional node
    console.log(nodeBlockId, this.nodeList);
    let branches = this.nodeList.filter(
      (x) =>
        x.previousNodeId ===
        this.nodeList[
          this.nodeList.findIndex((x) => x.nodeBlockId === nodeBlockId)
        ].nodeId
    );
    //Getting the index in list for the conditional Node
    let indexOfNode = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nodeBlockId
    );
    //Getting the Node Id for the given NodeBlockId
    let nodeId = this.nodeList[indexOfNode].nodeId;
    //Removing the First child
    if (branches.length > 0) {
      if (this.getNodeBranchType(branches[0].nodeType) === "BRANCHED") {
        this.deleteConditionalNode(branches[0].nodeBlockId);
        this.nodeList.splice(indexOfNode, 1);
      } else {
        this.nodeList.splice(indexOfNode, 1);
        this.removeBranchedNodes(branches[0].nodeId);
      }
      //Checking if there is a second child to delete
      if (branches.length > 1) {
        if (this.getNodeBranchType(branches[1].nodeType) === "BRANCHED") {
          this.deleteConditionalNode(branches[1].nodeBlockId);
          this.nodeList.splice(indexOfNode, 1);
        } else {
          this.nodeList.splice(indexOfNode, 1);
          this.removeBranchedNodes(branches[1].nodeId);
        }
      }
    } else {
      this.nodeList.splice(indexOfNode, 1);
    }
  }

  removeBranchedNodesFromView(nodeId) {
    //Getting index of the selected Node Id to delete
    let indexOfNode = this.nodeList.findIndex((x) => x.nodeId === nodeId);
    //Checking if there is a Node to  delete
    if (indexOfNode > -1) {
      if (
        this.getNodeBranchType(this.nodeList[indexOfNode].nodeType) ===
        "BRANCHED"
      ) {
        let element = document.getElementById(
          this.nodeList[indexOfNode].nodeBlockId
        );
        if (element) element.parentElement?.removeChild(element);
      } else {
        let element = document.getElementById(
          this.nodeList[indexOfNode].nodeBlockId
        );
        if (element) element.parentElement?.removeChild(element);
      }
    }
    //Getting the index If there is a child node for selecterd Node
    let nextNodeIndex = this.nodeList.findIndex(
      (x) => x.previousNodeId === nodeId
    );
    // Checking if there is a child Node for the selected Node
    if (nextNodeIndex > -1) {
      if (
        this.getNodeBranchType(this.nodeList[nextNodeIndex].nodeType) ===
        "BRANCHED"
      ) {
        let element = document.getElementById(
          this.nodeList[nextNodeIndex].nodeBlockId
        );
        if (element) element.parentElement.removeChild(element);
      } else {
        let element = document.getElementById(
          this.nodeList[nextNodeIndex].nodeBlockId
        );
        if (element) element.parentElement.removeChild(element);
        this.removeBranchedNodesFromView(this.nodeList[nextNodeIndex].nodeId);
      }
    }
  }

  /*method to remove non conditional Node Childeren of conditional Nodes */
  removeBranchedNodes(nodeId) {
    //Getting index of the selected Node Id to delete
    let indexOfNode = this.nodeList.findIndex((x) => x.nodeId === nodeId);
    //Checking if there is a Node to  delete
    if (indexOfNode > -1) {
      this.nodeList.splice(indexOfNode, 1);
    }
    //Getting the index If there is a child node for selecterd Node
    let nextNodeIndex = this.nodeList.findIndex(
      (x) => x.previousNodeId === nodeId
    );
    //Checking if there is a child Node for the selected Node
    if (nextNodeIndex > -1) {
      if (
        this.getNodeBranchType(this.nodeList[nextNodeIndex].nodeType) ===
        "BRANCHED"
      ) {
        this.deleteConditionalNode(this.nodeList[nextNodeIndex].nodeBlockId);
        this.nodeList.splice(indexOfNode, 1);
      } else {
        this.removeBranchedNodes(this.nodeList[nextNodeIndex]?.nodeId);
        this.nodeList.splice(indexOfNode, 1);
      }
    }
  }

  getpreviousNodeRelation(data, type) {
    if (type === "ABTESTING" || type === "AUDIENCESPLIT") {
      let string = data.id;
      let match = string.match(/path-(.+)/i);
      if (match) {
        let path = match[1];
        return type !== "ABTESTING" ? "SPLIT##" + path : "PATH##" + path;
      }
    } else {
      return data.className === "rightButtonContainer" ? "TIMEOUT" : "NEXT";
    }
  }
  cloneNode(event) {
    this.mouseEvent = event;
    let nodeIndex = this.nodeList.findIndex(
      (x) => x.nodeBlockId === this.nodeOperationBlockId
    );
    let nodeEvent: nodeEvent;
    // nodeEvent = {
    //   nodeId: null,
    //   nodeType: this.nodeList[nodeIndex].nodeType,
    //   nodeData: {},
    //   actionType: "CREATE",
    //   nodeOperation: "CREATE",
    //   previousNodeId: null,
    //   delete: false,
    // };
    //Getting the previous Id on where the node is cloned.
    let previousNodeBlockId =
      event.srcElement.parentElement.parentElement.parentElement.id;
    previousNodeBlockId.substring(0, 4) === "node"
      ? null
      : (previousNodeBlockId =
          event.srcElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement.parentElement
            .parentElement.id);
    this.previousNodeId =
      this.nodeList[
        this.nodeList.findIndex((x) => x.nodeBlockId === previousNodeBlockId)
      ].nodeId;
    //Checking if there is a node next to the  position where the node is cloned.
    let nextNodeIndex = this.nodeList.findIndex(
      (x) =>
        x.previousNodeId ===
        this.nodeList[
          this.nodeList.findIndex((x) => x.nodeBlockId === previousNodeBlockId)
        ].nodeId
    );
    // let currentNodeIndex = this.nodeList.findIndex(
    //   (x) => x.nodeBlockId === this.nodeOperationBlockId
    // );
    let nodeApiData = {
      definition: JSON.stringify(
        this.nodeList[nodeIndex].nodeData["definition"]
      ),
      nodeType: this.nodeList[nodeIndex].nodeType,
      previousNodeId:
        this.nodeList[
          this.nodeList.findIndex((x) => x.nodeBlockId === previousNodeBlockId)
        ].nodeId,
      previousNodeRelation: this.getpreviousNodeRelation(
        event.srcElement.parentElement.parentElement,
        this.nodeList[
          this.nodeList.findIndex((x) => x.nodeBlockId === previousNodeBlockId)
        ].nodeType
      ),
    };
    this.addOrRemovePointerEvents(false);
    this._apiCallService.createNode(nodeApiData, this.strategyId).subscribe(
      (response) => {
        this.addOrRemovePointerEvents(true);
        this.showNotification(
          this._EventIconMappingService.getBlockDisplayName(
            nodeApiData.nodeType
          ) +
            " " +
            this._i18nDynamicTranslate.transform(
              "block has been cloned successfully",
              ["POS_PAGE"]
            ),
          true
        );
        let nodeId = response["body"];
        nodeEvent = {
          nodeId: nodeId,
          nodeType: this.nodeList[nodeIndex].nodeType,
          nodeData: this.nodeList[nodeIndex].nodeData,
          actionType: "CREATE",
          nodeOperation: "CREATE",
          previousNodeId: nodeApiData.previousNodeId,
          delete: false,
          error: false,
          previousNodeRelation: nodeApiData.previousNodeRelation,
        };
        this.previousNodeRelation = nodeApiData.previousNodeRelation;
        this.configureNodeData(nodeEvent, true);
        //this.configureNodeData(nodeEvent, false);
        if (nextNodeIndex > -1) {
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeId === nodeApiData.previousNodeId
                )
              ].nodeType
            ) === "BRANCHED"
          ) {
            let nextBranchChildNodeIndex = this.nodeList.findIndex(
              (x) =>
                x.previousNodeId === nodeApiData.previousNodeId &&
                x.previousNodeRelation === nodeApiData.previousNodeRelation &&
                x.nodeId !== nodeId
            );
            if (nextBranchChildNodeIndex > -1) {
              let nodeApiData = {
                definition: JSON.stringify(
                  this.nodeList[nextBranchChildNodeIndex].nodeData["definition"]
                ),
                nodeType: this.nodeList[nextBranchChildNodeIndex].nodeType,
                previousNodeId: nodeId,
                previousNodeRelation: "NEXT",
              };
              document
                .getElementById(
                  this.nodeList[
                    this.nodeList.findIndex((x) => x.nodeId === nodeId)
                  ].nodeBlockId
                )
                .getElementsByClassName("end-flag")[0]
                .setAttribute("style", "display:none");
              this._apiCallService
                .updateNode(
                  this.strategyId,
                  this.nodeList[nextBranchChildNodeIndex].nodeId,
                  nodeApiData
                )
                .subscribe(
                  (response) => {
                    this.nodeList[nextBranchChildNodeIndex].previousNodeId =
                      nodeId;
                    this.nodeList[
                      nextBranchChildNodeIndex
                    ].previousNodeRelation = "NEXT";
                    this.updateStrategy();
                  },
                  (err) => {
                    this.showNotification("Failed to update next node", false);
                  }
                );
            } else {
              this.removeEndFlagFromPreviousNode(
                this.nodeList[
                  this.nodeList.findIndex((x) => x.nodeId === nodeId)
                ].nodeBlockId
              );
            }
          } else {
            let nodeApiData = {
              definition: JSON.stringify(
                this.nodeList[nextNodeIndex].nodeData["definition"]
              ),
              nodeType: this.nodeList[nextNodeIndex].nodeType,
              previousNodeId: nodeId,
              previousNodeRelation: "NEXT",
            };
            document
              .getElementById(
                this.nodeList[
                  this.nodeList.findIndex((x) => x.nodeId === nodeId)
                ].nodeBlockId
              )
              .getElementsByClassName("end-flag")[0]
              .setAttribute("style", "display:none");
            this._apiCallService
              .updateNode(
                this.strategyId,
                this.nodeList[nextNodeIndex].nodeId,
                nodeApiData
              )
              .subscribe(
                (response) => {
                  this.nodeList[nextNodeIndex].previousNodeId = nodeId;
                  this.nodeList[nextNodeIndex].previousNodeRelation = "NEXT";
                  this.updateStrategy();
                },
                (err) => {
                  this.showNotification("Failed to update next node", false);
                }
              );
          }
        } else {
          document
            .getElementById(
              this.nodeList[this.nodeList.findIndex((x) => x.nodeId === nodeId)]
                .nodeBlockId
            )
            .getElementsByClassName("end-flag")[0]
            .setAttribute("style", "display:flex");
          this.removeEndFlagFromPreviousNode(
            this.nodeList[this.nodeList.findIndex((x) => x.nodeId === nodeId)]
              .nodeBlockId
          );
        }
        this.updateStrategy();
      },
      (err) => {
        this.addOrRemovePointerEvents(true);
        this.showNotification(
          this._EventIconMappingService.getBlockDisplayName(
            nodeApiData.nodeType
          ) +
            " " +
            this._i18nDynamicTranslate.transform("block clone failed", [
              "POS_PAGE",
            ]),
          false
        );
      }
    );
    this.cancelMoveAndCloneOperations();
  }

  moveNode(event) {
    let previousNodeBlockId =
      event.srcElement.parentElement.parentElement.parentElement.id;
    previousNodeBlockId.substring(0, 4) === "node"
      ? null
      : (previousNodeBlockId =
          event.srcElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement.parentElement
            .parentElement.id);
    let previousNodeId = this.getNodeIdFromList(previousNodeBlockId);
    let moveData = {
      nodeId: this.getNodeIdFromList(this.nodeOperationBlockId),
      parentNodeId: previousNodeId,
      parentNodeRelation:
        event.srcElement.parentElement.parentElement.className ===
        "rightButtonContainer"
          ? "TIMEOUT"
          : "NEXT",
    };
    this.addOrRemovePointerEvents(false);
    this._apiCallService.moveNode(this.strategyId, moveData).subscribe(
      (response) => {
        this.addOrRemovePointerEvents(true);
        this.showNotification(
          this._EventIconMappingService.getBlockDisplayName(
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === this.nodeOperationBlockId
              )
            ].nodeType
          ) +
            " " +
            this._i18nDynamicTranslate.transform(
              "block has been moved successfully",
              ["POS_PAGE"]
            ),
          true
        );
        let parent =
          event.srcElement.parentElement.parentElement.parentElement
            .parentElement;
        let element = document.getElementById(this.nodeOperationBlockId);
        let nextNodeIndex = this.nodeList.findIndex(
          (x) =>
            x.previousNodeId ===
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === previousNodeBlockId
              )
            ].nodeId
        );
        let currentNodeIndex = this.nodeList.findIndex(
          (x) => x.nodeBlockId === this.nodeOperationBlockId
        );
        let indexOfNextNodeBeforeMove = this.nodeList.findIndex(
          (x) => x.previousNodeId === this.nodeList[currentNodeIndex].nodeId
        );
        if (indexOfNextNodeBeforeMove > -1) {
          this.nodeList[indexOfNextNodeBeforeMove].previousNodeId =
            this.nodeList[currentNodeIndex].previousNodeId;
          this.nodeList[indexOfNextNodeBeforeMove].previousNodeRelation =
            this.nodeList[currentNodeIndex].previousNodeRelation;
        } else {
          this.addEndFlagToPreviousNode(
            this.nodeList[currentNodeIndex].nodeBlockId
          );
        }
        if (nextNodeIndex > -1) {
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeId === moveData.parentNodeId
                )
              ].nodeType
            ) === "BRANCHED"
          ) {
            let nextBranchChildNodeIndex = this.nodeList.findIndex(
              (x) =>
                x.previousNodeId === moveData.parentNodeId &&
                x.previousNodeRelation === moveData.parentNodeRelation
            );
            if (nextBranchChildNodeIndex > -1) {
              this.nodeList[nextBranchChildNodeIndex].previousNodeId =
                this.nodeList[currentNodeIndex].nodeId;
              this.nodeList[nextBranchChildNodeIndex].previousNodeRelation =
                "NEXT";
              document
                .getElementById(this.nodeList[currentNodeIndex].nodeBlockId)
                .getElementsByClassName("end-flag")[0]
                .setAttribute("style", "display:none");
            } else {
              document
                .getElementById(this.nodeList[currentNodeIndex].nodeBlockId)
                .getElementsByClassName("end-flag")[0]
                .setAttribute("style", "display:flex");
            }
            // let childCount = this.nodeList.reduce(
            //   (a, v) =>
            //     v.previousNodeId === moveData.parentNodeId ? a + 1 : a,
            //   0
            // );
            // if (childCount === 1) {
            //   if (
            //     this.nodeList[nextNodeIndex].previousNodeRelation === "NEXT"
            //   ) {
            //     if (
            //       event.srcElement.parentElement.className !==
            //       "add-node-right-branch"
            //     ) {
            //       this.nodeList[nextNodeIndex].previousNodeId = this.nodeList[
            //         currentNodeIndex
            //       ].nodeId;
            //     }
            //   } else {
            //     if (
            //       event.srcElement.parentElement.className ===
            //       "add-node-right-branch"
            //     ) {
            //       this.nodeList[nextNodeIndex].previousNodeId = this.nodeList[
            //         currentNodeIndex
            //       ].nodeId;
            //       this.nodeList[nextNodeIndex].previousNodeRelation = "NEXT";
            //     }
            //   }
            // }
            // if (childCount === 2) {
            //   if (
            //     event.srcElement.parentElement.className ===
            //     "add-node-right-branch"
            //   ) {
            //     let nextNodeIndex = this.nodeList.findIndex(
            //       (x) =>
            //         x.previousNodeId === moveData.parentNodeId &&
            //         x.previousNodeRelation === "TIMEOUT"
            //     );
            //     this.nodeList[nextNodeIndex].previousNodeId = this.nodeList[
            //       currentNodeIndex
            //     ].nodeId;
            //     this.nodeList[nextNodeIndex].previousNodeRelation = "NEXT";
            //   } else {
            //     let nextNodeIndex = this.nodeList.findIndex(
            //       (x) =>
            //         x.previousNodeId === moveData.parentNodeId &&
            //         x.previousNodeRelation === "NEXT"
            //     );
            //     this.nodeList[nextNodeIndex].previousNodeId = this.nodeList[
            //       currentNodeIndex
            //     ].nodeId;
            //   }
            // }
          } else {
            this.nodeList[nextNodeIndex].previousNodeId =
              this.nodeList[currentNodeIndex].nodeId;
            document
              .getElementById(this.nodeList[currentNodeIndex].nodeBlockId)
              .getElementsByClassName("end-flag")[0]
              .setAttribute("style", "display:none");
          }
          this.nodeList[currentNodeIndex].previousNodeId =
            moveData.parentNodeId;
          this.nodeList[currentNodeIndex].previousNodeRelation =
            moveData.parentNodeRelation;
          if (
            this.getNodeBranchType(
              this.nodeList[
                this.nodeList.findIndex(
                  (x) => x.nodeBlockId === previousNodeBlockId
                )
              ].nodeType
            ) === "UNBRANCHED"
          ) {
            this.nextNodeId = this.nodeList[nextNodeIndex].nodeId;
            document
              .getElementById(this.nodeList[nextNodeIndex].nodeBlockId)
              .insertAdjacentElement("beforebegin", element);
          } else {
            let parentOfnewNode =
              event.srcElement.parentElement.parentElement.parentElement
                .parentElement;
            let childNodesOfConditionalNode =
              parentOfnewNode.getElementsByClassName("node-block-container");
            if (childNodesOfConditionalNode.length > 0) {
              this.nextNodeId = this.getNodeIdFromList(
                childNodesOfConditionalNode[0].id
              );
            }
            event.srcElement.parentElement.parentElement.parentElement.insertAdjacentElement(
              "afterend",
              element
            );
          }
        } else {
          document
            .getElementById(this.nodeList[currentNodeIndex].nodeBlockId)
            .getElementsByClassName("end-flag")[0]
            .setAttribute("style", "display:flex");
          // this.removeEndFlagFromPreviousNode(
          //   this.nodeList[currentNodeIndex].nodeBlockId
          // );
          parent.append(element);
        }
        this.addBoxShadowOnNodeBlock(
          element.getElementsByClassName("node-block")[0]
        );
        this.nodeList[currentNodeIndex].previousNodeId = moveData.parentNodeId;
        this.nodeList[currentNodeIndex].previousNodeRelation =
          moveData.parentNodeRelation;
        this.removeEndFlagFromPreviousNode(
          this.nodeList[currentNodeIndex].nodeBlockId
        );
        this.updateStrategy();
      },
      (err) => {
        this.addOrRemovePointerEvents(true);
        this.showNotification(
          this._EventIconMappingService.getBlockDisplayName(
            this.nodeList[
              this.nodeList.findIndex(
                (x) => x.nodeBlockId === this.nodeOperationBlockId
              )
            ].nodeType
          ) +
            " " +
            this._i18nDynamicTranslate.transform("block move failed", [
              "POS_PAGE",
            ]),
          false
        );
      }
    );
    this.cancelMoveAndCloneOperations();
  }

  displayMovePositionButton() {
    this.actionType = null;
    this.hideAllEvents();
    let indexOfNode = this.nodeList.findIndex(
      (x) => x.nodeBlockId == this.nodeOperationBlockId
    );
    let previousNodeBlockId =
      this.nodeList[
        this.nodeList.findIndex(
          (x) => x.nodeId === this.nodeList[indexOfNode].previousNodeId
        )
      ].nodeBlockId;
    let blockNumberOfPreviousNode = previousNodeBlockId.substring(
      5,
      previousNodeBlockId.length
    );
    let previousBranchId;
    if (this.nodeList[indexOfNode].previousNodeRelation === "NEXT") {
      previousBranchId = "left-node-" + blockNumberOfPreviousNode;
    } else {
      previousBranchId = "right-node-" + blockNumberOfPreviousNode;
    }
    //document.getElementById("overlay").style.display = "block";
    let canvas = document.getElementById("canvas");
    let elements = canvas.getElementsByClassName("node-block-overlay");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]) {
        elements[i].setAttribute("style", "display:block");
      }
    }
    let leftBranchButtonElements = canvas.getElementsByClassName(
      "add-node-left-branch"
    );
    let rightBranchButtonElements = canvas.getElementsByClassName(
      "add-node-right-branch"
    );
    let addButtonElements = canvas.getElementsByClassName("add-node");
    let moveButtonElements = canvas.getElementsByClassName("move-button");
    let cloneButtonElements = canvas.getElementsByClassName("clone-button");
    let branchCloneButton = canvas.getElementsByClassName(
      "branch-clone-button"
    );
    let branchMoveButton = canvas.getElementsByClassName("branch-move-button");
    let nodeBockElement = document.getElementsByClassName("node-block");
    for (let i = 0; i < nodeBockElement.length; i++) {
      nodeBockElement[i].setAttribute("style", "pointer-events: none;");
    }
    for (let i = 0; i < branchCloneButton.length; i++) {
      branchCloneButton[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < branchMoveButton.length; i++) {
      if (
        !(
          branchMoveButton[i].parentElement.parentElement.parentElement.id ===
          previousBranchId
        )
      ) {
        branchMoveButton[i].setAttribute("style", "display: flex;");
      }
    }
    for (let i = 0; i < leftBranchButtonElements.length; i++) {
      leftBranchButtonElements[i].setAttribute(
        "style",
        "pointer-events: none;"
      );
      if (
        !(
          leftBranchButtonElements[i].parentElement.parentElement.parentElement
            .id === previousBranchId
        )
      ) {
        leftBranchButtonElements[i].setAttribute("style", "display: none;");
      }
    }
    for (let i = 0; i < rightBranchButtonElements.length; i++) {
      rightBranchButtonElements[i].setAttribute(
        "style",
        "pointer-events: none;"
      );
      if (
        !(
          rightBranchButtonElements[i].parentElement.parentElement.parentElement
            .id === previousBranchId
        )
      ) {
        rightBranchButtonElements[i].setAttribute("style", "display: none;");
      }
    }
    for (let i = 0; i < addButtonElements.length; i++) {
      addButtonElements[i].setAttribute("style", "pointer-events: none;");
      if (
        !(
          previousNodeBlockId ===
            moveButtonElements[i].parentElement.parentElement.id ||
          this.nodeOperationBlockId ===
            moveButtonElements[i].parentElement.parentElement.id
        )
      ) {
        addButtonElements[i].setAttribute("style", "display: none;");
      }
    }
    for (let i = 0; i < cloneButtonElements.length; i++) {
      cloneButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < moveButtonElements.length; i++) {
      if (
        !(
          previousNodeBlockId ===
            moveButtonElements[i].parentElement.parentElement.id ||
          this.nodeOperationBlockId ===
            moveButtonElements[i].parentElement.parentElement.id
        )
      ) {
        moveButtonElements[i].setAttribute("style", "display: flex;");
      }
    }
    document.getElementById("cancel-operation-button").style.display = "flex";
  }

  displayClonePositionButton() {
    this.actionType = null;
    this.hideAllEvents();
    //document.getElementById("overlay").style.display = "block";
    let canvas = document.getElementById("canvas");
    let elements = canvas.getElementsByClassName("node-block-overlay");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]) {
        elements[i].setAttribute("style", "display:block");
      }
    }
    let leftBranchButtonElements = canvas.getElementsByClassName(
      "add-node-left-branch"
    );
    let rightBranchButtonElements = canvas.getElementsByClassName(
      "add-node-right-branch"
    );
    let addButtonElements = canvas.getElementsByClassName("add-node");
    let cloneButtonElements = canvas.getElementsByClassName("clone-button");
    let moveButtonElements = canvas.getElementsByClassName("move-button");
    let branchCloneButton = canvas.getElementsByClassName(
      "branch-clone-button"
    );
    let branchMoveButton = canvas.getElementsByClassName("branch-move-button");
    let multibranchCloneButton = canvas.getElementsByClassName(
      "multi-branch-clone-button"
    );
    let multibranchMoveButton = canvas.getElementsByClassName(
      "multi-branch-move-button"
    );
    let nodeBockElement = document.getElementsByClassName("node-block");
    for (let i = 0; i < nodeBockElement.length; i++) {
      nodeBockElement[i].setAttribute("style", "pointer-events: none;");
    }
    for (let i = 0; i < branchCloneButton.length; i++) {
      branchCloneButton[i].setAttribute("style", "display: flex;");
    }
    for (let i = 0; i < branchMoveButton.length; i++) {
      branchMoveButton[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < leftBranchButtonElements.length; i++) {
      leftBranchButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < rightBranchButtonElements.length; i++) {
      rightBranchButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < addButtonElements.length; i++) {
      addButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < moveButtonElements.length; i++) {
      moveButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < cloneButtonElements.length; i++) {
      cloneButtonElements[i].setAttribute("style", "display: flex;");
    }
    for (let i = 0; i < multibranchCloneButton.length; i++) {
      multibranchCloneButton[i].setAttribute("style", "display: flex;");
    }
    for (let i = 0; i < multibranchMoveButton.length; i++) {
      multibranchMoveButton[i].setAttribute("style", "display: none;");
    }
    document.getElementById("cancel-operation-button").style.display = "flex";
  }

  cancelMoveAndCloneOperations() {
    let canvas = document.getElementById("canvas");
    let leftBranchButtonElements = canvas.getElementsByClassName(
      "add-node-left-branch"
    );
    let rightBranchButtonElements = canvas.getElementsByClassName(
      "add-node-right-branch"
    );
    let addButtonElements = canvas.getElementsByClassName("add-node");
    let moveButtonElements = canvas.getElementsByClassName("move-button");
    let cloneButtonElements = canvas.getElementsByClassName("clone-button");
    let branchCloneButton = canvas.getElementsByClassName(
      "branch-clone-button"
    );
    let branchMoveButton = canvas.getElementsByClassName("branch-move-button");
    let multibranchCloneButton = canvas.getElementsByClassName(
      "multi-branch-clone-button"
    );
    let multibranchMoveButton = canvas.getElementsByClassName(
      "multi-branch-move-button"
    );
    let nodeBockElement = document.getElementsByClassName("node-block");
    for (let i = 0; i < nodeBockElement.length; i++) {
      nodeBockElement[i].setAttribute("style", "pointer-events: auto;");
    }
    for (let i = 0; i < leftBranchButtonElements.length; i++) {
      leftBranchButtonElements[i].setAttribute(
        "style",
        "pointer-events: auto;"
      );
      leftBranchButtonElements[i].setAttribute("style", "display: flex;");
    }
    for (let i = 0; i < rightBranchButtonElements.length; i++) {
      rightBranchButtonElements[i].setAttribute(
        "style",
        "pointer-events: auto;"
      );
      rightBranchButtonElements[i].setAttribute("style", "display: flex;");
    }
    for (let i = 0; i < addButtonElements.length; i++) {
      addButtonElements[i].setAttribute("style", "pointer-events: auto;");
      addButtonElements[i].setAttribute("style", "display: flex;");
    }
    for (let i = 0; i < moveButtonElements.length; i++) {
      moveButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < cloneButtonElements.length; i++) {
      cloneButtonElements[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < branchMoveButton.length; i++) {
      branchMoveButton[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < branchCloneButton.length; i++) {
      branchCloneButton[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < multibranchCloneButton.length; i++) {
      multibranchCloneButton[i].setAttribute("style", "display: none;");
    }
    for (let i = 0; i < multibranchMoveButton.length; i++) {
      multibranchMoveButton[i].setAttribute("style", "display: none;");
    }
    document.getElementById("cancel-operation-button").style.display = "none";
    //document.getElementById("overlay").style.display = "none";
    let elements = canvas.getElementsByClassName("node-block-overlay");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]) {
        elements[i].setAttribute("style", "display:none");
      }
    }
  }

  removeNodeFromList() {
    this.nodeList.splice(
      this.nodeList.findIndex((x) => x.nodeBlockId === this.activeNodeBlockId),
      1
    );
  }

  getNodeClassIdForView(event) {
    switch (this.nodeType) {
      case "WAIT":
        return "delay-node-block";
      case "CHANNEL":
        if (event["nodeData"]["definition"]["channelType"] === "EMAIL") {
          return "email-node-block";
        }
        if (event["nodeData"]["definition"]["channelType"] === "SMS") {
          return "sms-node-block";
        }
        if (event["nodeData"]["definition"]["channelType"] === "WHATSAPP") {
          return "whatsapp-node-block";
        }
      case "OFFER":
        return "offer-node-block";
      case "SURVEY":
        return "survey-node-block";
      case "TRIGGER":
        return "trigger-node-block";
      case "ABTESTING":
        return "abtesting-node-block";
      case "AUDIENCESPLIT":
        return "split-node-block";
      case "CUSTOM_AUDIENCE":
        return "audience-node-block";
      case "COMARKETING_OFFER":
        return "comarketingoffer-node-block";
      case "LOYALTY":
        return "loyalty-node-block";
    }
  }

  showNotification(content, type, duration?) {
    let notificationElement = document.getElementById("notification");
    let notificationContainer = document.getElementById(
      "notification-container"
    );
    notificationElement.getElementsByClassName(
      "notificationn-content"
    )[0].innerHTML = this._i18nDynamicTranslate.transform(content, [
      "POS_PAGE",
    ]);
    let element = document.createElement("div");
    element.setAttribute("class", "notification");
    element.innerHTML = notificationElement.innerHTML;
    let doneButton = element.getElementsByClassName(
      "notification-done-icon"
    )[0];
    let closeButton = element.getElementsByClassName(
      "notification-fail-icon"
    )[0];
    if (type) {
      doneButton.setAttribute("style", "display:flex");
      closeButton.setAttribute("style", "display:none");
      element.style.backgroundColor = "#97cb64";
    } else {
      doneButton.setAttribute("style", "display:none");
      // closeButton.setAttribute("style", "display:flex");
      element.style.backgroundColor = "#811620";
    }
    element.style.display = "flex";
    element
      .getElementsByClassName("notification-close-icon")[0]
      .addEventListener("click", (event) => this.hideNotification(event));
    notificationContainer.append(element);
    let interval = setInterval(
      () => {
        element.classList.add("notification-delete");
        this.removeNotificationElement(notificationContainer, element);
        //notificationContainer.removeChild(element);
        clearInterval(interval);
      },
      duration ? duration : 4000
    );
  }

  removeNotificationElement(container, element) {
    let interval = setInterval(() => {
      try {
        container.removeChild(element);
      } catch {}
      clearInterval(interval);
    }, 500);
  }

  hideNotification(event) {
    event.srcElement.parentElement.classList.add("notification-delete");
    this.removeNotificationElement(
      event.srcElement.parentElement.parentElement,
      event.srcElement.parentElement
    );
  }

  async activateStrategy() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if (this.nodeList.length === 0) {
        this.showNotification("No Blocks present in the workflow", false);
      } else if (this.nodeList.length === 1) {
        this.showNotification(
          "Add more blocks to automate your workflow",
          false
        );
      } else {
        let dataset = null;
        let minimumDate: Date = null;
        let maximumDate: Date = null;
        let dateDifference = null;
        let currentDate = new Date();
        let minTime;
        //let maxTime;
        dataset =
          this.nodeList[
            this.nodeList.findIndex((x) => x.previousNodeId === null)
          ].nodeData["definition"];
        if (
          dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
            "DATE"
          ]
        ) {
          if (
            dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
              "DATE"
            ]["ruleDates"][0]
          ) {
            minimumDate =
              dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
                "DATE"
              ]["ruleDates"][0];
            if (
              dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
                "TIME"
              ]
            ) {
              if (
                dataset["config"]["conditionGroups"][0]["conditions"][0][
                  "rules"
                ]["TIME"]["ruleTimes"][0]
              ) {
                minTime =
                  dataset["config"]["conditionGroups"][0]["conditions"][0][
                    "rules"
                  ]["TIME"]["ruleTimes"][0];
              }
            }
          }
        }
        if (
          dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
            "DATE"
          ]
        ) {
          if (
            dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
              "DATE"
            ]["ruleDates"][1]
          ) {
            maximumDate =
              dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
                "DATE"
              ]["ruleDates"][1];
            // if (
            //   dataset["config"]["conditionGroups"][0]["conditions"][0]["rules"][
            //     "TIME"
            //   ]
            // ) {
            //   if (
            //     dataset["config"]["conditionGroups"][0]["conditions"][0][
            //       "rules"
            //     ]["TIME"]["ruleTimes"][1]
            //   ) {
            //     maxTime =
            //       dataset["config"]["conditionGroups"][0]["conditions"][0][
            //         "rules"
            //       ]["TIME"]["ruleTimes"][1];
            //   }
            // }
          }
        }
        for (let i = 0; i < dataset["config"]["conditionGroups"].length; i++) {
          for (
            let j = 0;
            j < dataset["config"]["conditionGroups"][i]["conditions"].length;
            j++
          ) {
            if (
              dataset["config"]["conditionGroups"][i]["conditions"][j]["rules"][
                "DATE"
              ]
            ) {
              if (
                dataset["config"]["conditionGroups"][i]["conditions"][j][
                  "rules"
                ]["DATE"]["ruleDates"][0]
              ) {
                if (
                  this.getDateWithoutTime(
                    dataset["config"]["conditionGroups"][i]["conditions"][j][
                      "rules"
                    ]["DATE"]["ruleDates"][0]
                  ) <= this.getDateWithoutTime(minimumDate)
                ) {
                  minimumDate =
                    dataset["config"]["conditionGroups"][i]["conditions"][j][
                      "rules"
                    ]["DATE"]["ruleDates"][0];

                  if (
                    dataset["config"]["conditionGroups"][i]["conditions"][j][
                      "rules"
                    ]["TIME"]
                  ) {
                    if (
                      dataset["config"]["conditionGroups"][i]["conditions"][j][
                        "rules"
                      ]["TIME"]["ruleTimes"]
                    ) {
                      if (
                        this.getDateWithoutTime(
                          dataset["config"]["conditionGroups"][i]["conditions"][
                            j
                          ]["rules"]["DATE"]["ruleDates"][0]
                        ) === this.getDateWithoutTime(minimumDate)
                      ) {
                        if (!minTime) {
                          minTime =
                            dataset["config"]["conditionGroups"][i][
                              "conditions"
                            ][j]["rules"]["TIME"]["ruleTimes"][0];
                        }
                        if (
                          this.getTimetoCompare(
                            dataset["config"]["conditionGroups"][i][
                              "conditions"
                            ][j]["rules"]["TIME"]["ruleTimes"][0]
                          ) < this.getTimetoCompare(minTime)
                        ) {
                          minTime =
                            dataset["config"]["conditionGroups"][i][
                              "conditions"
                            ][j]["rules"]["TIME"]["ruleTimes"][0];
                        }
                      }
                    }
                  }
                }
              }
              if (
                dataset["config"]["conditionGroups"][i]["conditions"][j][
                  "rules"
                ]["DATE"]["ruleDates"][1]
              ) {
                if (
                  this.getDateWithoutTime(
                    dataset["config"]["conditionGroups"][i]["conditions"][j][
                      "rules"
                    ]["DATE"]["ruleDates"][1]
                  ) >= this.getDateWithoutTime(maximumDate)
                ) {
                  maximumDate =
                    dataset["config"]["conditionGroups"][i]["conditions"][j][
                      "rules"
                    ]["DATE"]["ruleDates"][1];

                  // if (
                  //   dataset["config"]["conditionGroups"][i]["conditions"][j][
                  //     "rules"
                  //   ]["TIME"]
                  // ) {
                  //   if (
                  //     dataset["config"]["conditionGroups"][i]["conditions"][j][
                  //       "rules"
                  //     ]["TIME"]["ruleTimes"][1]
                  //   ) {
                  //     if (
                  //       this.getDateWithoutTime(
                  //         dataset["config"]["conditionGroups"][i]["conditions"][
                  //           j
                  //         ]["rules"]["DATE"]["ruleDates"][1]
                  //       ) === this.getDateWithoutTime(maximumDate)
                  //     ) {
                  //       if (
                  //         this.getTimetoCompare(
                  //           dataset["config"]["conditionGroups"][i][
                  //             "conditions"
                  //           ][j]["rules"]["TIME"]["ruleTimes"][1]
                  //         ) > this.getTimetoCompare(maxTime)
                  //       ) {
                  //         maxTime =
                  //           dataset["config"]["conditionGroups"][i][
                  //             "conditions"
                  //           ][j]["rules"]["TIME"]["ruleTimes"][1];
                  //       }
                  //     }
                  //   }
                  // }
                }
              }
            }
          }
        }
        let maxDate = maximumDate
          ? new Date(maximumDate.toString().replace(" ", "T"))
          : maximumDate;
        let minDate = minimumDate
          ? new Date(minimumDate.toString().replace(" ", "T"))
          : minimumDate;
        dateDifference = maximumDate
          ? (maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24)
          : null;
        // if (maximumDate) {
        //   let finalMaxDate = new Date(maxDate);
        //   // if (maxTime) {
        //   //   let maxHour = +maxTime.split(":")[0];
        //   //   let maxMinute = +maxTime.split(":")[1];
        //   //   finalMaxDate.setHours(maxHour);
        //   //   finalMaxDate.setMinutes(maxMinute);
        //   // } else {
        //     currentDate.setHours(0);
        //     currentDate.setMinutes(0);
        //     currentDate.setSeconds(0);
        //     currentDate.setMilliseconds(0);
        //   //}
        //   if (finalMaxDate < currentDate) {
        //     this.showNotification(
        //       "Choose any present or future dates in the Trigger block",
        //       false
        //     );
        //     return;
        //   }
        // }
        let finalDateMin = new Date(minDate);
        if (minimumDate) {
          let finalMinDate = new Date(minDate);
          if (minTime) {
            let minHour = +this.transformTo24HourFormat(minTime).split(":")[0];
            let minMinute =
              +this.transformTo24HourFormat(minTime).split(":")[1];
            finalMinDate.setHours(minHour);
            finalMinDate.setMinutes(minMinute);
          } else {
            currentDate.setHours(0);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            currentDate.setMilliseconds(0);
          }
          if (
            finalMinDate <
            new Date(
              currentDate.toLocaleString("en-US", {
                timeZone: this._TokenStorageService.getMerchantTimeZone(),
              })
            )
          ) {
            this.showNotification(
              "Choose any present or future dates in the Trigger block",
              false
            );
            return;
          }
          finalDateMin = finalMinDate;
        }
        //special check for loyalty that node should contain a rule that doesn't match with loyalty config rule (name of rule match)
        let loyaltyNodes = this.nodeList.filter(node=>node.nodeType == 'LOYALTY');
        if(loyaltyNodes?.length>0) {
          for(let node of loyaltyNodes) {
            if(!this.loyaltyProgramConfig['loyaltyRules'].some(rule=>rule.ruleName == node.nodeData['definition'].rule.ruleName)) {
              const dialogref = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "customer-dialog-popup",
                  width: "380px",
                  data: {
                    message: `Loyalty rule with ${node.nodeData['definition'].rule.ruleName} name does not exist in loyalty configuration. Please edit the node and choose an existing rule`,
                    successButtonText: "Confirm",
                  }
                }
              );
              return;
            }
          }
        }
        const dialogRef = this._dialog.open(
          CampaignStrategyActivateDialogComponent,
          {
            panelClass: "no-padding-dialog-popup",
            width: "30%",
            data: {
              operation: "ACTIVATE",
              campaignName: this.strategyData.strategyName,
              startDate: finalDateMin,
              endDate: maximumDate ? maxDate : null,
              dateDifference: dateDifference,
              strategyStatus: this.strategyStatus,
            },
          }
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.addOrRemovePointerEvents(false);
            this._apiCallService
              .activateStrategy(this.strategyId, result)
              .subscribe(
                (response) => {
                  //this.strategyStatus = "SCHEDULED";
                  //this.showNotification("Journey Activated");
                  this.addOrRemovePointerEvents(true);
                  const dialogRef = this._dialog.open(SuccessDialogComponent, {
                    panelClass: "no-padding-dialog-popup",
                    width: "20%",
                    disableClose: true,
                    data: {
                      message: this._i18nDynamicTranslate.transform(
                        "Your journey has been successfully activated",
                        ["POS_PAGE"]
                      ),
                      buttonText: this._i18nDynamicTranslate.transform(
                        "Back to Journey List",
                        ["POS_PAGE"]
                      ),
                    },
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                      this._router.navigate(["/app/campaign/overview"], {
                        queryParams: { type: "campaign" },
                      });
                    }
                  });
                  this._apiCallService
                    .getStrategy(this.strategyId)
                    .subscribe((response) => {
                      let strategyData = JSON.parse(response["body"]);
                      this.strategyStatus = strategyData["status"];
                    });
                },
                (err) => {
                  if (err.status == 403) {
                    this.addOrRemovePointerEvents(true);
                    this.showNotification(
                      "Failed to Activate - " + err.error.body,
                      false,
                      15000
                    );
                  } else {
                    this.addOrRemovePointerEvents(true);
                    this.showNotification(
                      "Failed to Activate - " + err.error.body,
                      false,
                      10000
                    );
                  }
                }
              );
          }
        });
      }
    }
  }

  getDateWithoutTime(dateWithTime) {
    return formatDate(dateWithTime.replace(" ", "T"), "yyyy-MM-dd", "en_US");
  }

  getTimetoCompare(timeString) {
    let date = new Date();
    date.setHours(+timeString.split(":")[0]);
    date.setMinutes(+timeString.split(":")[1]);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  deactivateStrategy() {
    const dialogRef = this._dialog.open(
      CampaignStrategyActivateDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "30%",
        data: {
          operation: "DEACTIVATE",
          campaignName: this.strategyData.strategyName,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addOrRemovePointerEvents(false);
        this._apiCallService.deactivateStrategy(this.strategyId).subscribe(
          (response) => {
            this.addOrRemovePointerEvents(true);
            this.showNotification("Journey Deactivated", true);
            this._apiCallService
              .getStrategy(this.strategyId)
              .subscribe((response) => {
                let strategyData = JSON.parse(response["body"]);
                this.strategyStatus = strategyData["status"];
              });
          },
          (err) => {
            this.addOrRemovePointerEvents(true);
            this.showNotification("Failed to deactivate", false);
          }
        );
      }
    });
  }

  getEventIcon(eventName) {
    switch (eventName) {
      case "add item to cart":
        return "add_shopping_cart";
      case "start checkout":
        return "credit_card";
      case "sign_up":
        return "person_add";
      case "place orders":
        return "library_add_check";
      case "view product":
        return "visibility";
      case "search product":
        return "search";
      case "Time based strategy":
        return "apps";
      case "Updates Checkout":
        return "update_checkout";
    }
  }

  showOrHideTriggerContent(event) {
    if (
      event.srcElement.parentElement.className === "trigger-condition-toggle" ||
      event.srcElement.parentElement.className === "split-condition-toggle" ||
      event.srcElement.parentElement.className === "response-condition-toggle"
    ) {
      event.srcElement.parentElement.removeAttribute("class");
      event.srcElement.parentElement.setAttribute(
        "class",
        "trigger-condition-toggle-rotate"
      );
      let conditionElement =
        event.srcElement.parentElement.parentElement.parentElement;
      let contentElement = conditionElement.getElementsByClassName(
        "trigger-condition-content"
      )[0];
      contentElement.removeAttribute("class");
      contentElement.setAttribute("class", "trigger-condition-content-enable");
      let parentCondition =
        event.srcElement.parentElement.parentElement.parentElement;
      let nextConnector = parentCondition.nextSibling;
      if (nextConnector) {
        let connector = nextConnector.getElementsByClassName(
          "trigger-condition-connector"
        )[0];
        connector.removeAttribute("class", "trigger-condition-connector");
        connector.setAttribute("class", "trigger-condition-connector-open");
        let connectorTextElement = connector.nextSibling;
        connectorTextElement.setAttribute("style", "top:-37px");
      }
    } else {
      event.srcElement.parentElement.removeAttribute("class");
      event.srcElement.parentElement.setAttribute(
        "class",
        "trigger-condition-toggle"
      );
      let conditionElement =
        event.srcElement.parentElement.parentElement.parentElement;
      let contentElement = conditionElement.getElementsByClassName(
        "trigger-condition-content-enable"
      )[0];
      contentElement.removeAttribute("class");
      contentElement.setAttribute("class", "trigger-condition-content");
      let parentCondition =
        event.srcElement.parentElement.parentElement.parentElement;
      let nextConnector = parentCondition.nextSibling;
      if (nextConnector) {
        let connector = nextConnector.getElementsByClassName(
          "trigger-condition-connector-open"
        )[0];
        connector.removeAttribute("class", "trigger-condition-connector-open");
        connector.setAttribute("class", "trigger-condition-connector");
        let connectorTextElement = connector.nextSibling;
        connectorTextElement.setAttribute("style", "top:-12px");
      }
    }
  }

  getTimeAsString(timeInMinutes) {
    //let time = this.data['nodeType'] === 'r-e' ? this.data['content']['timeoutValue'] : this.data['content']['timeUnitValue'];
    let days = +Math.floor(timeInMinutes / 1440).toFixed();
    let hours: any = +Math.floor((timeInMinutes - days * 1440) / 60).toFixed();
    let minutes = timeInMinutes - days * 1440 - hours * 60;
    return days + " days " + hours + " hours " + minutes + " minutes";
  }

  async discardCamapign() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: "Delete Journey",
          message:
            "Delete operation will delete this Journey and you will lose all the configurations",
          "data-widget": "CANVAS",
          "data-button": "Confirm: Delete",
          "data-item": "",
          successButtonText: "Delete",
          cancelButtonText: "Cancel",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.addOrRemovePointerEvents(false);
          this._apiCallService.deleteStrategy(this.strategyId).subscribe(
            (response) => {
              this.addOrRemovePointerEvents(true);
              //this.showNotification("Strategy Deleted");
              this._activatedRoute.params.subscribe((param) => {
                this._router.navigate(["/app/" + param["return-url"]]);
              });
            },
            (err) => {
              this.addOrRemovePointerEvents(true);
              this.showNotification(err["error"]["body"], false);
            }
          );
        }
      });
    }
  }

  async saveCampaign() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: "Save Journey",
          message: "Click confirm to Save",
          "data-widget": "CANVAS",
          "data-button": "Confirm: Save and Exit",
          "data-item": "",
          successButtonText: "Confirm",
          cancelButtonText: "Cancel",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._activatedRoute.params.subscribe((param) => {
            this._router.navigate(["/app/" + param["return-url"]]);
          });
        }
      });
    }
  }

  deletenodeApi(nodeId, deleteType) {
    //let deletePromise = new promise();
    let status = false;
    let result = false;
    this.addOrRemovePointerEvents(false);
    this._apiCallService
      .deleteNode(this.strategyId, nodeId, deleteType)
      .subscribe(
        (response) => {
          this.addOrRemovePointerEvents(true);
          this.showNotification(
            this._EventIconMappingService.getBlockDisplayName(
              this.nodeList[this.nodeList.findIndex((x) => x.nodeId === nodeId)]
                .nodeType
            ) +
              " " +
              this._i18nDynamicTranslate.transform(
                "block has been deleted successfully",
                ["POS_PAGE"]
              ),
            true
          );
          result = true;
          status = true;
          this.updateStrategy();
        },
        (err) => {
          this.addOrRemovePointerEvents(true);
          this.showNotification(
            this._EventIconMappingService.getBlockDisplayName(
              this.nodeList[this.nodeList.findIndex((x) => x.nodeId === nodeId)]
                .nodeType
            ) +
              " " +
              this._i18nDynamicTranslate.transform("block delete failed", [
                "POS_PAGE",
              ]),
            false
          );
          result = false;
          status = true;
        }
      );
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (status) {
          clearInterval(interval);
          resolve(result);
        }
      }, 10);
    });
  }

  addEndFlag(nodeBlockId, className) {
    document
      .getElementById(nodeBlockId)
      .getElementsByClassName(className)[0]
      .setAttribute("style", "display:flex");
  }

  removeEndFlag(nodeBlockId, className) {
    document
      .getElementById(nodeBlockId)
      .getElementsByClassName(className)[0]
      .setAttribute("style", "display:none");
  }

  addEndFlagToPreviousNode(nodeBlockId) {
    let elementIndex = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nodeBlockId
    );
    let previousNodeId = this.nodeList[elementIndex].previousNodeId;
    let previousNodeIndex = this.nodeList.findIndex(
      (x) => x.nodeId === previousNodeId
    );
    if (
      this.getNodeBranchType(this.nodeList[previousNodeIndex].nodeType) ===
      "BRANCHED"
    ) {
      if (this.nodeList[elementIndex].previousNodeRelation === "NEXT") {
        document
          .getElementById(
            "left-node" +
              this.nodeList[previousNodeIndex].nodeBlockId.substring(
                4,
                this.nodeList[previousNodeIndex].nodeBlockId.length
              )
          )
          .getElementsByClassName("end-flag-left-branch")[0]
          .setAttribute("style", "display:flex");
      } else if (
        this.nodeList[elementIndex].previousNodeRelation === "TIMEOUT"
      ) {
        document
          .getElementById(
            "right-node" +
              this.nodeList[previousNodeIndex].nodeBlockId.substring(
                4,
                this.nodeList[previousNodeIndex].nodeBlockId.length
              )
          )
          .getElementsByClassName("end-flag-right-branch")[0]
          .setAttribute("style", "display:flex");
      } else if (
        this.nodeList[elementIndex].previousNodeRelation.substring(0, 5) ===
        "SPLIT"
      ) {
        let i = this.nodeList[elementIndex].previousNodeRelation.substring(
          this.startIndex,
          this.nodeList[elementIndex].previousNodeRelation.length
        );
        document
          .getElementById(
            "end-flag-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-split-" +
              i
          )
          .setAttribute("style", "display:flex");
        document
          .getElementById(
            "wrap-split-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-id-" +
              i
          )
          .setAttribute("style", "height:40px");
      } else if (
        this.nodeList[elementIndex].previousNodeRelation.substring(0, 4) ===
        "PATH"
      ) {
        let i = this.nodeList[elementIndex].previousNodeRelation.substring(
          6,
          this.nodeList[elementIndex].previousNodeRelation.length
        );
        document
          .getElementById(
            "end-flag-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-ab-" +
              i
          )
          .setAttribute("style", "display:flex");
        document
          .getElementById(
            "wrap-ab-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-id-" +
              i
          )
          .setAttribute("style", "height:40px");
      }
    } else {
      document
        .getElementById(this.nodeList[previousNodeIndex].nodeBlockId)
        .getElementsByClassName("end-flag")[0]
        .setAttribute("style", "display:flex");
    }
  }
  addEndFlagToPreviousNodeSplit(data) {
    let i = data.previousNodeRelation.substring(
      this.startIndex,
      data.previousNodeRelation.length
    );
    document
      .getElementById("end-flag-" + this.activeNodeBlockId + "-split-" + i)
      .setAttribute("style", "display:flex");
    document
      .getElementById("wrap-split-" + this.activeNodeBlockId + "-id-" + i)
      .setAttribute("style", "height:40px");
  }

  removeEndFlagFromPreviousNode(nodeBlockId) {
    let elementIndex = this.nodeList.findIndex(
      (x) => x.nodeBlockId === nodeBlockId
    );
    let previousNodeId = this.nodeList[elementIndex].previousNodeId;
    let previousNodeIndex = this.nodeList.findIndex(
      (x) => x.nodeId === previousNodeId
    );
    if (
      this.getNodeBranchType(this.nodeList[previousNodeIndex].nodeType) ===
      "BRANCHED"
    ) {
      if (this.nodeList[elementIndex].previousNodeRelation === "NEXT") {
        document
          .getElementById(
            "left-node" +
              this.nodeList[previousNodeIndex].nodeBlockId.substring(
                4,
                this.nodeList[previousNodeIndex].nodeBlockId.length
              )
          )
          .getElementsByClassName("end-flag-left-branch")[0]
          .setAttribute("style", "display:none");
      }
      // else if(this.getNodeBranchType(this.nodeList[previosNodeIndex].nodeType) ===
      // "GROUPBRANCH"){}
      else if (this.nodeList[elementIndex].previousNodeRelation === "TIMEOUT") {
        document
          .getElementById(
            "right-node" +
              this.nodeList[previousNodeIndex].nodeBlockId.substring(
                4,
                this.nodeList[previousNodeIndex].nodeBlockId.length
              )
          )
          .getElementsByClassName("end-flag-right-branch")[0]
          .setAttribute("style", "display:none");
      } else if (
        this.nodeList[elementIndex].previousNodeRelation.substring(0, 5) ===
        "SPLIT"
      ) {
        let i = this.nodeList[elementIndex].previousNodeRelation.substring(
          this.startIndex,
          this.nodeList[elementIndex].previousNodeRelation.length
        );
        document
          .getElementById(
            "end-flag-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-split-" +
              i
          )
          .setAttribute("style", "display :none");
        let j = this.nodeList[elementIndex].previousNodeRelation.substring(
          this.startIndex,
          this.nodeList[elementIndex].previousNodeRelation.length
        );
        let parent = document.getElementById(
          "sp-" + this.nodeList[previousNodeIndex].nodeBlockId + "-path-" + j
        );
        document
          .getElementById(
            "wrap-split-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-id-" +
              j
          )
          .setAttribute("style", "height:80px");
      } else if (
        this.nodeList[elementIndex].previousNodeRelation.substring(0, 4) ===
        "PATH"
      ) {
        let i = this.nodeList[elementIndex].previousNodeRelation.substring(
          6,
          this.nodeList[elementIndex].previousNodeRelation.length
        );
        document
          .getElementById(
            "end-flag-node" +
              this.nodeList[previousNodeIndex].nodeBlockId.substring(
                4,
                this.nodeList[previousNodeIndex].nodeBlockId.length
              ) +
              "-ab-" +
              i
          )
          .setAttribute("style", "display :none");
        let j = this.nodeList[elementIndex].previousNodeRelation.substring(
          6,
          this.nodeList[elementIndex].previousNodeRelation.length
        );
        let parent = document.getElementById(
          "sp-" + this.nodeList[previousNodeIndex].nodeBlockId + "-path-" + j
        );
        document
          .getElementById(
            "wrap-ab-" +
              this.nodeList[previousNodeIndex].nodeBlockId +
              "-id-" +
              j
          )
          .setAttribute("style", "height:80px");
      } else {
      }
    } else {
      document
        .getElementById(this.nodeList[previousNodeIndex].nodeBlockId)
        .getElementsByClassName("end-flag")[0]
        .setAttribute("style", "display:none");
    }
    // if (
    //   this.getNodeBranchType(this.nodeList[previousNodeIndex].nodeType) ===
    //   "BRANCHED"
    // ) {
    //   if (this.nodeList[elementIndex].previousNodeRelation === "NEXT") {
    //     document
    //       .getElementById(
    //         "left-node" +
    //           this.nodeList[previousNodeIndex].nodeBlockId.substring(
    //             4,
    //             this.nodeList[previousNodeIndex].nodeBlockId.length
    //           )
    //       )
    //       .getElementsByClassName("end-flag-left-branch")[0]
    //       .setAttribute("style", "display:none");
    //   } else {
    //     document
    //       .getElementById(
    //         "right-node" +
    //           this.nodeList[previousNodeIndex].nodeBlockId.substring(
    //             4,
    //             this.nodeList[previousNodeIndex].nodeBlockId.length
    //           )
    //       )
    //       .getElementsByClassName("end-flag-right-branch")[0]
    //       .setAttribute("style", "display:none");
    //   }
    // } else {
    //   document
    //     .getElementById(this.nodeList[previousNodeIndex].nodeBlockId)
    //     .getElementsByClassName("end-flag")[0]
    //     .setAttribute("style", "display:none");
    // }
  }

  removebuttonPointerEvents() {
    let canvas = document.getElementById("canvas");
    let addButtonElements = canvas.getElementsByClassName("add-node");
    for (let i = 0; i < addButtonElements.length; i++) {
      addButtonElements[i].setAttribute("style", "pointer-events: none;");
    }
    let leftBranchAddNodeButton = canvas.getElementsByClassName(
      "add-node-left-branch"
    );
    for (let i = 0; i < leftBranchAddNodeButton.length; i++) {
      leftBranchAddNodeButton[i].setAttribute("style", "pointer-events: none;");
    }
    let rightBranchAddNodeButton = canvas.getElementsByClassName(
      "add-node-right-branch"
    );
    for (let i = 0; i < rightBranchAddNodeButton.length; i++) {
      rightBranchAddNodeButton[i].setAttribute(
        "style",
        "pointer-events: none;"
      );
    }
    let nodeConfigButton = canvas.getElementsByClassName("node-config");
    for (let i = 0; i < nodeConfigButton.length; i++) {
      nodeConfigButton[i].setAttribute("style", "pointer-events: none;");
    }
  }

  addButtonPointerEvents() {
    let canvas = document.getElementById("canvas");
    let addButtonElements = canvas.getElementsByClassName("add-node");
    for (let i = 0; i < addButtonElements.length; i++) {
      addButtonElements[i].setAttribute("style", "pointer-events: auto;");
    }
    let leftBranchAddNodeButton = canvas.getElementsByClassName(
      "add-node-left-branch"
    );
    for (let i = 0; i < leftBranchAddNodeButton.length; i++) {
      leftBranchAddNodeButton[i].setAttribute("style", "pointer-events: auto;");
    }
    let rightBranchAddNodeButton = canvas.getElementsByClassName(
      "add-node-right-branch"
    );
    for (let i = 0; i < rightBranchAddNodeButton.length; i++) {
      rightBranchAddNodeButton[i].setAttribute(
        "style",
        "pointer-events: auto;"
      );
    }
    let nodeConfigButton = canvas.getElementsByClassName("node-config");
    for (let i = 0; i < nodeConfigButton.length; i++) {
      nodeConfigButton[i].setAttribute("style", "pointer-events: auto;");
    }
  }
  updateStrategy() {
    let data = {
      definition: this.strategyData["definition"],
      displayParams: JSON.stringify({ data: this.nodeList }),
      strategyName: this.strategyData.strategyName,
      strategyDescription: this.strategyData.strategyDescription,
    };
    if (this.checkIfABTestingPresent()) {
      data["abTesting"] = true;
    } else {
      data["abTesting"] = false;
    }
    this.addOrRemovePointerEvents(false);
    this._apiCallService.updateStrategy(this.strategyId, data).subscribe(
      (response) => {
        this.addOrRemovePointerEvents(true);
      },
      (err) => {
        this.addOrRemovePointerEvents(true);
      }
    );
  }

  checkIfABTestingPresent() {
    for (let node of this.nodeList) {
      if (node.nodeData["nodeType"] == "ABTESTING") {
        return true;
      }
    }
    return false;
  }

  addBoxShadowOnNodeBlock(element: Element) {
    element.setAttribute("style", "box-shadow:0px 0px 20px 0px #00b75987");
    let interval = setInterval(() => {
      element.setAttribute("style", "box-shadow:none");
      clearInterval(interval);
    }, 4000);
  }

  addOrRemovePointerEvents(status) {
    //status true to add pointer event
    let headerElement = document.getElementById("campaign-header");
    let strategyElement = document.getElementById("strategy-container");
    if (status) {
      headerElement.style.pointerEvents = "all";
      strategyElement.style.pointerEvents = "all";
    } else {
      headerElement.style.pointerEvents = "none";
      strategyElement.style.pointerEvents = "none";
    }
  }

  showTemplatePreview(mouseEvent, nodeData) {
    switch (nodeData["nodeType"]) {
      case "SURVEY":
        this._apiCallService
          .getSurveyTemplate(nodeData["definition"]["surveyTemplateId"])
          .subscribe((response) => {
            var surveyData: any = {};
            let survey = JSON.parse(response["body"]);
            let selectedSurvey = survey["layoutHtml"];

            //For new survey to show the content
            var m = `class="session-newsurvey" id="mainDiv"`;
            var n = `id="mainDiv"`;
            selectedSurvey = selectedSurvey.replace(m, n);

            m = `id="mainDiv" class="session-newsurvey"`;
            n = `id="mainDiv"`;
            selectedSurvey = selectedSurvey.replace(m, n);
            //For older survey compatibility
            var a = `id="mainDiv" style="display:none;border-radius: 3px;`;
            var b = `id="mainDiv" style="border-radius: 3px;`;
            selectedSurvey = selectedSurvey.replace(a, b);

            surveyData.selectedSurvey = selectedSurvey;
            surveyData.selectedSurveyName = survey["name"];
            surveyData.surveyIdFinal = survey["id"];
            surveyData.type = survey["type"];
            this.openTemplatePreviewDialog(surveyData, "SURVEY");
          });
        break;
      case "CHANNEL":
        if (nodeData["definition"]["channelType"] === "SMS") {
          this._apiCallService
            .getSmsTemplate(nodeData["definition"]["layoutId"])
            .subscribe((response) => {
              this.openTemplatePreviewDialog(response["body"], "SMS");
            });
        }
        if (nodeData["definition"]["channelType"] === "EMAIL") {
          this._apiCallService
            .getEmailLayoutDetail(nodeData["definition"]["emailLayoutId"])
            .subscribe(
              (response) => {
                if (response["body"] !== "null") {
                  let emailTemplate = JSON.parse(response["body"]);
                  let data = {
                    selectedLayout: emailTemplate["layoutHtml"],
                    templateId: emailTemplate["id"],
                    templateName: emailTemplate["name"],
                  };
                  this.openTemplatePreviewDialog(data, "EMAIL");
                } else {
                  this.getFromRewardPreview(nodeData);
                }
              },
              (err) => {
                this.getFromRewardPreview(nodeData);
              }
            );
        }
        break;
      case "OFFER":
        this._apiCallService
          .getParticularOfferDetails(nodeData["definition"]["offerId"])
          .subscribe((response) => {
            var offerData: any = {};
            let masterOffer = JSON.parse(response["body"]);
            offerData.masterOffer = masterOffer;
            let offerJson = JSON.parse(masterOffer["offerjson"])[
              "barrierRewards"
            ];
            offerData.offerJson = offerJson;
            let parsedbody;
            let offerDetailEmailLayout;
            let emailtemplateSnapshot;
            let emailtemplateName;
            let isNoneBarrierDetected;
            let isBuyXGetYDetected;
            if (JSON.parse(masterOffer["offerjson"])["emailLayoutId"]) {
              parsedbody = JSON.parse(masterOffer["offerjson"]);
              offerDetailEmailLayout = parsedbody["emailLayoutId"];
              emailtemplateSnapshot = parsedbody["emailTemplateSnapshotUrl"];
              emailtemplateName = parsedbody["emailLayoutName"];
              isNoneBarrierDetected = parsedbody["isNoneBarrierDetected"];
              isBuyXGetYDetected = parsedbody["isBuyXGetYDetected"];
              if (offerDetailEmailLayout) {
                this.getHtml(offerDetailEmailLayout, offerData);
              }
              offerData.offerDetailEmailLayout = offerDetailEmailLayout;
              offerData.emailtemplateSnapshot = emailtemplateSnapshot;
              offerData.emailtemplateName = emailtemplateName;
              offerData.isNoneBarrierDetected = isNoneBarrierDetected;
              offerData.isBuyXGetYDetected = isBuyXGetYDetected;
            } else {
              offerDetailEmailLayout = null;
              offerData.offerDetailEmailLayout = offerDetailEmailLayout;
              let parsedbody2 = JSON.parse(masterOffer["offerjson"]);
              isNoneBarrierDetected = parsedbody2["isNoneBarrierDetected"];
              isBuyXGetYDetected = parsedbody2["isBuyXGetYDetected"];
              offerData.isNoneBarrierDetected = isNoneBarrierDetected;
              offerData.isBuyXGetYDetected = isBuyXGetYDetected;
            }
            let selectedbarrier = offerJson;
            offerData.selectedbarrier = selectedbarrier;
            this.openTemplatePreviewDialog(offerData, "OFFER");
          });
        break;
      case "SURVEYEMAIL":
        this._apiCallService
          .getEmailContent(nodeData["definition"]["emailLayoutId"])
          .subscribe(
            (response) => {
              let layout = JSON.parse(response["body"]);
              var emailData: any = {};
              emailData.selectedLayout = layout["layoutHtml"];
              emailData.templateName = layout["name"];
              emailData.templateId = nodeData["definition"]["emailLayoutId"];
              this.openTemplatePreviewDialog(emailData, "SURVEYEMAIL");
            },
            (err) => {}
          );
        break;
      case "COMARKETING_OFFER":
        this.openTemplatePreviewDialog(nodeData, "COMARKETING_OFFER");
        break;
    }
  }
  showTemplatePreviewComarketing(mouseEvent, nodeData) {
    this.openTemplatePreviewDialog(nodeData, "COMARKETING_OFFER");
  }

  openTemplatePreviewDialog(objectData: any, type: string): void {
    if (type === "OFFER") {
      const dialogRefer = this._dialog.open(OfferSummaryDialogComponent, {
        panelClass: "customAttachEmail",
        width: "40%",
        maxWidth: "546px",
        data: objectData["masterOffer"]["id"],
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
    } else if (type === "COMARKETING_OFFER") {
      const dialogRefer = this._dialog.open(PreviewComarketingOfferComponent, {
        panelClass: "customAttachEmail",
        width: "40%",
        maxWidth: "546px",
        data: objectData,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
    } else {
      let finalScreenHeight =
        document.getElementById("strategy-container").offsetHeight;
      let finalScreenWidth =
        document.getElementById("strategy-container").offsetWidth;
      let sh = finalScreenHeight * (90 / 100);
      let sw = finalScreenHeight * (70 / 100);
      const dialogRef = this._dialog.open(PreviewTemplateComponent, {
        panelClass: "previewDialog",
        height: sh + "px",
        width: finalScreenWidth < 1500 ? "1024px" : sw + "px",
        minWidth: "1024px",
        maxWidth: "99vw",
        disableClose: true,
        autoFocus: false,
        data: {
          obj: objectData,
          type: type,
          height: finalScreenHeight,
          isEditAllowed:
            this.strategyStatus === "SCHEDULED" ||
            this.strategyStatus === "ACTIVE"
              ? false
              : true,
        },
      });
    }
  }

  getHtml(offerDetailEmailLayout, offerData: any) {
    this._apiCallService
      .getEmailContent(offerDetailEmailLayout)
      .subscribe((response) => {
        offerData.htmlloading = false;
        if (response["message"] == "success") {
          let layout = JSON.parse(response["body"]);
          let layoutSnapshotUrl = layout["snapShot"];
          offerData.layoutSnapshotUrl = layoutSnapshotUrl;
        }
      });
  }

  getTemplateName(templateType, templateId, templateName) {
    let index;
    switch (templateType) {
      case "OFFER":
        index = this.offerTemplates.offers.findIndex(
          (offer) => offer.id === templateId
        );
        if (index > -1) {
          return this.offerTemplates.offers[index].offerName;
        } else {
          return templateName;
        }
      case "EMAIL":
        index = this.emailTemplates.findIndex(
          (email) => email.id === templateId
        );
        if (index > -1) {
          return this.emailTemplates[index].layoutName;
        } else {
          return templateName;
        }
      case "WHATSAPP":
        index = this.whatsappTemplates.findIndex(
          (whatsapp) => ((whatsapp.id === templateId) && (whatsapp.name === templateName))
        );
        if (index > -1) {
          return this.whatsappTemplates[index].name;
        } else {
          return templateName;
        }
      case "SMS":
        index = this.smsTemplates.findIndex((sms) => sms.id === templateId);
        if (index > -1) {
          return this.smsTemplates[index].name;
        } else {
          return templateName;
        }
      case "SURVEY":
        index = this.surveyTemplates.findIndex(
          (survey) => survey.id === templateId
        );
        if (index > -1) {
          return this.surveyTemplates[index].layoutName;
        } else {
          return templateName;
        }
      case "SURVEYEMAIL":
        index = this.emailTemplates.findIndex(
          (email) => email.id === templateId
        );
        if (index > -1) {
          return this.emailTemplates[index].layoutName;
        } else {
          return templateName;
        }
      case "COMARKETING_OFFER":
        index = this.coMarketingOffers.findIndex(
          (comarketing) => comarketing.id === templateId
        );
        if (index > -1) {
          return this.coMarketingOffers[index].offerName;
        } else {
          return templateName;
        }
    }
  }

  getrewardemail(offerId, offerType, emailLayoutId, offerJson) {
    this._apiCallService.getRewardEmailList().subscribe((result) => {
      let rewardTemplateList = result["body"];
      for (let i = 0; i < rewardTemplateList.length; i++) {
        if (emailLayoutId == rewardTemplateList[i]['id']) {
          let rewardStandardEmailtemplateId = rewardTemplateList[i]["id"];
          this.opensnapshotHtmlDialog(
            offerId,
            offerType,
            emailLayoutId,
            rewardStandardEmailtemplateId,
            offerJson
          );
        }
      }
    });
  }

  getOfferDetails(offerId) {
    this._apiCallService.getParticularOfferDetails(offerId).subscribe(
      (response) => {
        if (response["message"] == "success") {
          let masterOffer = JSON.parse(response["body"]);
          this.offerBody = masterOffer;
          let offerType = JSON.parse(masterOffer["offerjson"])[
            "barrierRewards"
          ][0]["reward"]["rewardType"];
          let comsObj = JSON.parse(JSON.parse(masterOffer["offerjson"])[
            "barrierRewards"
          ][0]["reward"]["communication"]);
          let emailLayoutId =  JSON.parse(masterOffer["offerjson"])[
            "barrierRewards"
          ][0]["reward"]["emailLayoutId"];

          if (
            JSON.parse(masterOffer["offerjson"])["barrierRewards"][0]["reward"][
              "channel"
            ] == "EMAIL"
          ) {
            emailLayoutId = comsObj.email?.id || emailLayoutId;
            this.getrewardemail(
              offerId,
              offerType,
              emailLayoutId,
              JSON.parse(masterOffer["offerjson"])["barrierRewards"][0]
            );
          } else if (
            JSON.parse(masterOffer["offerjson"])["barrierRewards"][0]["reward"][
              "channel"
            ] == "SMS"
          ) {
            this.opensmsPreviewDialog(
              JSON.parse(masterOffer["offerjson"])["barrierRewards"][0][
                "reward"
              ]["layoutId"]
            );
          } else {
            const dialogRef = this._dialog.open(
              ConfirmationDialogBoxComponent,
              {
                panelClass: "no-padding-dialog-popup",
                width: "20%",
                data: {
                  subject: "No channel offer",
                  message: "Looks like this offer has no channel",
                  successButtonText: "Ok",
                },
              }
            );
          }
        }
      },
      (err) => {
        //  this.snackBar.openSnackBar(err.error.body, 2000);
      }
    );
  }
  opensnapshotHtmlDialog(
    offerId,
    offerType,
    emailLayoutId,
    rewardStandardEmailtemplateId,
    offerJson
  ) {
    var dat = {
      type: null,
      id: null,
      channel: null,
      templateID: null,
      rewardType: null,
      actionType: null,
      isEditAllowed:
        this.strategyStatus === "SCHEDULED" || this.strategyStatus === "ACTIVE"
          ? false
          : true,
    };

    if (emailLayoutId > 0) {
      const dialogRef = this._dialog.open(HtmlDataDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "60%",
        height: "500px",
        data: {
          type: "offer",
          id: emailLayoutId,
          channel: "EMAIL",
          templateID: offerId,
          rewardType: offerType,
          isEditAllowed:
            this.strategyStatus === "SCHEDULED" ||
            this.strategyStatus === "ACTIVE"
              ? false
              : true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "edit") {
          this.loadEmailEditorReward(
            offerId,
            offerJson,
            rewardStandardEmailtemplateId
          );
        }
      });
    } else {
      if (emailLayoutId == null) {
        dat = {
          type: "reward",
          id: rewardStandardEmailtemplateId,
          channel: "EMAIL",
          templateID: offerId,
          rewardType: offerType,
          actionType: "Offer",
          isEditAllowed:
            this.strategyStatus === "SCHEDULED" ||
            this.strategyStatus === "ACTIVE"
              ? false
              : true,
        };
      } else {
        // dat = {
        //   type: "emailtemplate",
        //   id: this.prerewardId,
        //   channel: this.selectedChannel,
        //   templateID: this.offerId,
        //   rewardType: this.BarrierRewardFull.reward.rewardType,
        //   actionType: "Offer",
        // };
      }
      const dialogRef = this._dialog.open(
        HtmlForEmailTemplatesDialogComponent,
        {
          panelClass: "no-padding-dialog-popup",
          width: "60%",
          height: "500px",
          data: dat,
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "edit") {
          this.loadEmailEditorReward(
            offerId,
            offerJson,
            rewardStandardEmailtemplateId
          );
        }
      });
    }
  }

  loadEmailEditorReward(offerId, offerJson, rewardStandardEmailtemplateId) {
    var specialTagType = null;
    var imageTagStatus = false;
    let finalScreenHeight =
      document.getElementById("strategy-container").offsetHeight;
    let finalScreenWidth =
      document.getElementById("strategy-container").offsetWidth;
    let sh = finalScreenHeight * (90 / 100);
    let sw = finalScreenHeight * (70 / 100);

    if (offerJson["barrier"]["barrierParamType"] == "NONE") {
      if (
        offerJson["reward"]["rewardType"] == "PERCENTAGE_OFF" ||
        offerJson["reward"]["rewardType"] == "FIXED_AMOUNT_OFF"
      ) {
        if (
          offerJson["reward"]["productPropensity"] != null ||
          offerJson["reward"]["productCategoryPropensity"] != null
        ) {
          imageTagStatus = true;
          specialTagType = "REWARD_ALL";
        } else {
          imageTagStatus = false;
          specialTagType = null;
        }
      }
    }

    if (offerJson["reward"]["rewardType"] == "BUY_X_GET_Y") {
      if (offerJson["barrier"]["barrierParamType"] == "PERSONALIZED") {
        imageTagStatus = true;
        specialTagType = "BUYXGETY";
      } else {
        imageTagStatus = false;
        specialTagType = null;
      }
    }

    // if (offerJson["reward"]["emailLayoutId"] == null) {
    //   var datas = {
    //     process: "createRewardTemplateLayout",
    //     id: rewardStandardEmailtemplateId,
    //     tags: [],
    //     imageTagStatus: false,
    //     specialTagType: "",
    //     offerId: null,
    //     type: "OFFER",
    //     rewardType: offerJson["reward"].rewardType,
    //     isReward: true,
    //   };
    // }
    //  else if (
    //   (<FormArray>this.offerTemplate.get("offerBarrier").value)[index][
    //     "rewardSavedId"
    //   ] == "temp"
    // ) {
    //   var datas = {
    //     process: "AddTextBody",
    //     id: this.getProductId(name, "emailTemplate"),
    //     tags: [],
    //     imageTagStatus:false,
    //     specialTagType:''

    //   };
    // }
    // else {
    //   var datas = {
    //     process: "EditTextBody",
    //     id: offerJson["reward"]["emailLayoutId"],
    //     tags: [],
    //     imageTagStatus: false,
    //     specialTagType: "",
    //     offerId: null,
    //     type: "OFFER",
    //     rewardType: offerJson["reward"].rewardType,
    //     isReward: true,
    //   };
    // }
    // datas.process = "AddTextBody";
    // datas.id = null;
    var datas = {
      process: "createRewardTemplateLayout",
      id: rewardStandardEmailtemplateId,
      tags: [],
      imageTagStatus: false,
      specialTagType: "",
      offerId: null,
      type: "OFFER",
      rewardType: offerJson["reward"].rewardType,
      isReward: true,
    };
    this._apiCallService
      .getRewardOfferTags(offerJson["reward"]["rewardType"])
      .subscribe(
        (response) => {
          if (response["message"] === "success") {
            let messageVariables = JSON.parse(response["body"]);
            let finalVariables = [];
            for (let key in messageVariables) {
              finalVariables.push({
                name: messageVariables[key],
                value: key,
              });
            }

            datas.tags = finalVariables;
            datas.imageTagStatus = imageTagStatus;
            datas.specialTagType = specialTagType;
            datas.offerId = offerId;
            datas['tableType'] = datas.isReward?'REWARD':null;
            const dialogRefer = this._dialog.open(
              OfferAttachEmailDialogComponent,
              {
                panelClass: "customAttachEmail",
                height: '90vh',
                width: '90vw',
                maxWidth: "99vw",
                disableClose: true,
                data: datas,
              }
            );
            dialogRefer.afterClosed().subscribe((result) => {
              if (result) {
                var offerUpdateJson = offerJson;
                offerUpdateJson["reward"]["emailLayoutId"] = result.layoutId;
                offerUpdateJson["reward"]["layoutId"] = null;
                offerUpdateJson["reward"]["channel"] = "EMAIL";
                var OfferData = {
                  offerName: this.offerBody["offerName"],
                  offerJson: JSON.stringify({
                    emailLayoutId: result.layoutId,
                    barrierRewards: [offerUpdateJson],
                    emailLayoutTemplateId: JSON.parse(
                      this.offerBody["offerjson"]
                    )["emailLayoutTemplateId"],

                    emailLayoutName: JSON.parse(this.offerBody["offerjson"])[
                      "emailLayoutName"
                    ],
                    emailLayoutSubject: null,
                    emailTemplateSnapshotUrl: JSON.parse(
                      this.offerBody["offerjson"]
                    )["emailTemplateSnapshotUrl"],
                    // channel: "EMAIL",
                    layoutId: null,
                    smsLayoutId: null,
                    smsTemplateName: null,
                    isNoneBarrierDetected: JSON.parse(
                      this.offerBody["offerjson"]
                    )["isNoneBarrierDetected"],
                    isBuyXGetYDetected: JSON.parse(this.offerBody["offerjson"])[
                      "isBuyXGetYDetected"
                    ],
                  }),
                  // channel: "EMAIL",
                  offerId: this.offerBody.offerId,
                };

                this._apiCallService
                  .updateParticularOfferDetails(offerId, OfferData)
                  .subscribe(
                    (response) => {
                      if (response["message"] == "success") {
                        // this._snackBar.openSnackBar(Email template updated, 2000);
                      }
                    },
                    (err) => {
                      this._snackBar.openSnackBar(
                        err.error.body == null
                          ? "Failed updating template"
                          : err.error.body,
                        2000
                      );
                    }
                  );
              }
            });
          }
        },
        (err) => {
          // this.snackBar.openSnackBar(
          //   "Fetching message template variable failed",
          //   2000
          // );
        }
      );
  }

  opensmsPreviewDialog(id) {
    const dialogRef = this._dialog.open(SmsPreviewIdDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: id,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  showWhatsAppTemplate(event, data) {
    let data1 = this.whatsappTemplates.findIndex(
      (x) => ((x.id === data.definition.layoutId )&& (x.name === data.definition.smsTemplateName))
    );
    let layout = JSON.parse(this.whatsappTemplates[data1]["layoutData"]);
    console.log('Layout to be passed:', layout);
    const dialogRef = this._dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout,
        type: "WhatsApp",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getCustomerSegmentVariables() {
    this._apiCallService.getCustomerSegmentVariables().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let segmentVariable = response["body"];
          let keys = [];
          for (let key in segmentVariable) {
            keys.push(key);
          }
          keys = keys.sort();
          keys.forEach((element) => {
            segmentVariable[element].forEach((variable) => {
              this.segmentVariablesMap[variable.name] = variable;
            });
          });
        } else {
          this._snackBar.openSnackBar(
            "Error fetching segmentVariables data",
            2000
          );
        }
      },
      (err) => {
        this._snackBar.openSnackBar(
          "Error fetching segmentVariables data",
          2000
        );
      }
    );
  }

  loyaltyProgramConfig = {};
  ruleFrequencies;
  ruleEventDropdownValues = [];
  getAndInitLoyaltyData() {
    let self = this;
    this._apiCallService.getLoyaltyProgramConfig().subscribe(
      response=>{
        if(response['body'] && !response['body'].partialUpdate && response['body'].tierConfig.tierMap.length>0) {
          self.loyaltyProgramConfig['fullConfig'] = response['body'];
          self.loyaltyProgramConfig['loyaltyRules'] = response['body'].ruleConfig.loyaltyRules;
          this._apiCallService.getLoyaltyProgramDropdowns().subscribe(response => {
            let responseData = response["body"];
            this.ruleFrequencies = responseData.eventFrequencies
            const events: any[] = Object.values(responseData.loyaltyEvents);
            for (let event of events) {
              event.forEach((each) => {
                if(each.displayName.toLowerCase() === 'amount spent') {
                  each.displayName = `${this.currencySymbol} spent in a purchase`;
                }
                this.ruleEventDropdownValues.push({
                  name: each.name,
                  displayName: each.displayName,
                  valueEditable: each.valueEditable,
                  defaultValue: each.defaultValue,
                  operators: each.operators,
                  dataType: each.dataType,
                })
              });
            }
            self.loyaltyProgramConfig['loyaltyRules'].forEach(rule=>{
              if(rule.ruleCategory == 'BASIC') {
                this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
                  if(curDropdownVal.name == rule.ruleDefinition.event) {
                    rule.ruleDefinition.eventDropdownObj = curDropdownVal;
                    return;
                  }
                });
              } else {
                rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.forEach((logicalExpress)=>{
                  logicalExpress.comparisonStatements.forEach((comparisonStatement)=>{
                    this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
                      if(curDropdownVal.name == comparisonStatement.operand1) {
                        comparisonStatement.eventDropdownObj = curDropdownVal;
                        return;
                      }
                    });
                  });
                });
                for(let freqObj of this.ruleFrequencies as any) {
                  if(rule.ruleFrequency === freqObj.value) {
                    rule['ruleFrequencyDisplay'] = freqObj.key;
                  }
                }
              }
            });
          },
          error => {
            this._snackBar.openSnackBar("Error fetching loyalty events dropdown data", 2000);
          });
        }
      },
      error=>{
        this._snackBar.openSnackBar("Error fetching loyalty config", 2000);
      }
    );
  }

  setBranchContent(event, nodeType?) {
    let node_index = this.nodeList.findIndex((x) => x.nodeId === event.nodeId);
    let pathGroupData;
    if (nodeType == "ABTESTING") {
      pathGroupData =
        this.nodeList[node_index].nodeData["definition"].pathGroups;
      for (let k in pathGroupData) {
        let node_branch = document.getElementById(
          "abtesting-" + this.nodeList[node_index]["nodeBlockId"] + "-" + [k]
        );
        node_branch.innerHTML = null;
        let wrapContent = `
          <div class="path-name-disp" title="${event.nodeData.definition.pathGroups[k].pathName}">
            ${event.nodeData.definition.pathGroups[k].pathName}
          </div>
          <div class="path-perc-disp">
            ${event.nodeData.definition.pathGroups[k].weight} %
          </div>
        </div>`;
        node_branch.innerHTML = wrapContent;
      }
    }
    if (nodeType == "AUDIENCESPLIT") {
      pathGroupData =
        this.nodeList[node_index].nodeData["definition"].groupName;
      for (let k in pathGroupData) {
        let node_branch = document.getElementById(
          "split-branch" +
            this.nodeList[node_index]["nodeBlockId"] +
            "-path-" +
            pathGroupData[k]
        );
        node_branch.innerHTML = null;
        let wrapContent = `<div class="dynamic-split-vertical"></div>${event.nodeData.definition.groupName[k]}`;
        node_branch.innerHTML = wrapContent;
        node_branch.setAttribute(
          "id",
          "split-branch" +
            this.nodeList[node_index]["nodeBlockId"] +
            "-path-" +
            event.nodeData.definition.groupName[k]
        );
      }
    }
  }
  changeNextNodeAfterEdit(event) {
    let index = this.nodeList.findIndex(
      (x) => x.previousNodeId === event.nodeId
    );
    if (index > -1) {
      let nextNodeId = this.nodeList[index].nodeId;
      let node_index = this.nodeList.findIndex(
        (x) => x.nodeId === event.nodeId
      );
      let pathGroupData =
        this.nodeList[node_index].nodeData["definition"].groupName;
      console.log(nextNodeId, this.nodeList, pathGroupData);
      for (let i in pathGroupData) {
        let noderelation = this.nodeList.findIndex(
          (x) => x.previousNodeRelation === "SPLIT##" + pathGroupData[i]
        );
        if (noderelation > -1) {
          this.nodeList[
            this.nodeList.findIndex((x) => x.nodeId === event.nodeId)
          ].nodeData["definition"].splitGroups[pathGroupData[i]].nextNodeId =
            nextNodeId;
        }
        let nodeOtherRelation = this.nodeList.findIndex(
          (x) => x.previousNodeRelation === "SPLIT##others"
        );
        if (nodeOtherRelation > -1) {
          this.nodeList[
            this.nodeList.findIndex((x) => x.nodeId === event.nodeId)
          ].nodeData["definition"].splitGroups["others"].nextNodeId =
            this.nodeList[nodeOtherRelation].nodeId;
        }
      }
    }
  }
  async appendAudienceBranchNodesToConditionalNode(
    result,
    element,
    nextNodeBlockId,
    triggerEvent
  ) {
    let blockNumber = this.activeNodeBlockId.substring(5, element.id.length);
    //1-Discard
    //default-Append to the selected branch
    switch (result) {
      case 1:
        let indexOfNode = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        let deleteAllNodePromise = await this.deletenodeApi(
          this.nodeList[indexOfNode].nodeId,
          "ALL"
        );
        if (deleteAllNodePromise) {
          let parentNode =
            document.getElementById(nextNodeBlockId).parentElement;
          this.removeNodesInBranchFromView(nextNodeBlockId, parentNode);
          if (
            this.getNodeBranchType(this.nodeList[indexOfNode].nodeType) ===
            "BRANCHED"
          ) {
            this.deleteConditionalNode(nextNodeBlockId);
          } else {
            this.removeBranchedNodes(this.nodeList[indexOfNode].nodeId);
          }
          this.updateStrategy();
        }
        break;
      default:
        if (result === "Everyone else") {
          result = "others";
        }
        let nextNodeIndex1 = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        this.addnextNodeForInBetween(
          triggerEvent,
          this.nodeList[nextNodeIndex1].nodeId,
          result
        );
        let nodeApiData1 = {
          definition: JSON.stringify(
            this.nodeList[nextNodeIndex1].nodeData["definition"]
          ),
          nodeType: this.nodeList[nextNodeIndex1].nodeType,
          previousNodeId: triggerEvent.nodeId,
          previousNodeRelation: "SPLIT##" + result,
        };
        this.addOrRemovePointerEvents(false);
        this._apiCallService
          .updateNode(
            this.strategyId,
            this.nodeList[nextNodeIndex1].nodeId,
            nodeApiData1
          )
          .subscribe(
            (response) => {
              this.addOrRemovePointerEvents(true);
              document
                .getElementById(
                  "end-flag-" + this.activeNodeBlockId + "-split-" + result
                )
                .setAttribute("style", "display:none");
              document
                .getElementById(
                  "wrap-split-" + this.activeNodeBlockId + "-id-" + result
                )
                .setAttribute("style", "height:80px");
              let nodeId = "sp-node-" + blockNumber + "-path-" + result;
              this.addNodesToBranch(
                document.getElementById(nodeId),
                nextNodeBlockId
              );
              this.nodeList[nextNodeIndex1].previousNodeRelation =
                "SPLIT##" + result;
              this.nodeList[nextNodeIndex1].previousNodeId =
                triggerEvent.nodeId;
              this.updateStrategy();
            },
            (err) => {
              this.addOrRemovePointerEvents(true);
            }
          );
        break;
    }
  }
  async appendSplitBranchNodesToConditionalNode(
    result,
    element,
    nextNodeBlockId,
    triggerEvent
  ) {
    let blockNumber = this.activeNodeBlockId.substring(5, element.id.length);
    //1-Discard
    //default-Append to the selected branch
    switch (result) {
      case 1:
        let indexOfNode = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        let deleteAllNodePromise = await this.deletenodeApi(
          this.nodeList[indexOfNode].nodeId,
          "ALL"
        );
        if (deleteAllNodePromise) {
          let parentNode =
            document.getElementById(nextNodeBlockId).parentElement;
          this.removeNodesInBranchFromView(nextNodeBlockId, parentNode);
          if (
            this.getNodeBranchType(this.nodeList[indexOfNode].nodeType) ===
            "BRANCHED"
          ) {
            this.deleteConditionalNode(nextNodeBlockId);
          } else {
            this.removeBranchedNodes(this.nodeList[indexOfNode].nodeId);
          }
          this.updateStrategy();
        }
        break;
      default:
        let nextNodeIndex1 = this.nodeList.findIndex(
          (x) => x.nodeBlockId === nextNodeBlockId
        );
        this.addnextNodeForInBetween(
          triggerEvent,
          this.nodeList[nextNodeIndex1].nodeId,
          result
        );
        let nodeApiData1 = {
          definition: JSON.stringify(
            this.nodeList[nextNodeIndex1].nodeData["definition"]
          ),
          nodeType: this.nodeList[nextNodeIndex1].nodeType,
          previousNodeId: triggerEvent.nodeId,
          previousNodeRelation: "PATH##" + result,
        };
        this.addOrRemovePointerEvents(false);
        this._apiCallService
          .updateNode(
            this.strategyId,
            this.nodeList[nextNodeIndex1].nodeId,
            nodeApiData1
          )
          .subscribe(
            (response) => {
              this.addOrRemovePointerEvents(true);
              document
                .getElementById(
                  "end-flag-" + this.activeNodeBlockId + "-ab-" + result
                )
                .setAttribute("style", "display:none");
              document
                .getElementById(
                  "wrap-ab-" + this.activeNodeBlockId + "-id-" + result
                )
                .setAttribute("style", "height:80px");
              let nodeId = "sp-node-" + blockNumber + "-path-" + result;
              this.addNodesToBranch(
                document.getElementById(nodeId),
                nextNodeBlockId
              );
              this.nodeList[nextNodeIndex1].previousNodeRelation =
                "PATH##" + result;
              this.nodeList[nextNodeIndex1].previousNodeId =
                triggerEvent.nodeId;
              this.updateStrategy();
            },
            (err) => {
              this.addOrRemovePointerEvents(true);
            }
          );
        break;
    }
  }
  getArrayFormData(path) {
    let data = [];
    for (let k in path) {
      data.push(k);
    }
    return data;
  }
  getComarketingOfferTemplate() {
    this._apiCallService.getCoMarketingOffers().subscribe((response) => {
      this.coMarketingOffers = response["body"]["comarketingOffers"];
    });
  }
  //To add nextNodeId inside audiesce split or abtesting node if direct flow.
  addnodeIndex(nextNode) {
    let index = this.nodeList.findIndex(
      (x) => x.nodeId === this.previousNodeId
    );
    let previousNode = this.nodeList[index];
    if (previousNode.nodeType === "AUDIENCESPLIT") {
      let previousBranch = this.previousNodeRelation.substring(
        this.startIndex,
        this.previousNodeRelation.length
      );
      this.nodeList[index].nodeData["definition"].splitGroups[
        previousBranch
      ].nextNodeId = nextNode;
    } else {
      let previousBranch = this.previousNodeRelation.substring(
        6,
        this.previousNodeRelation.length
      );
      this.nodeList[index].nodeData["definition"].pathGroups[
        previousBranch
      ].nextNodeId = nextNode;
    }
  }
  //To add nextNodeId inside audience split and adtesting if we are adding in between branched node and any other node.
  addnextNodeForInBetween(data, nextNode, path) {
    console.log("2");
    let index = this.nodeList.findIndex((x) => x.nodeId === data.nodeId);
    if (this.nodeList[index].nodeType === "AUDIENCESPLIT") {
      this.nodeList[index].nodeData["definition"].splitGroups[path].nextNodeId =
        nextNode;
    } else {
      this.nodeList[index].nodeData["definition"].pathGroups[path].nextNodeId =
        nextNode;
    }
  }
  //To add nextNodeId when we delete a node whch is in between branched node and any other node.
  addNextNodeIndexIfDelete(nextNode, prevNodeId, previousNodeRelation) {
    console.log("3");
    let index = this.nodeList.findIndex((x) => x.nodeId === prevNodeId);
    let previousNode = this.nodeList[index];
    if (previousNode.nodeType === "AUDIENCESPLIT") {
      let previousBranch = previousNodeRelation.substring(
        this.startIndex,
        previousNodeRelation.length
      );
      this.nodeList[index].nodeData["definition"].splitGroups[
        previousBranch
      ].nextNodeId = nextNode;
    } else {
      let previousBranch = previousNodeRelation.substring(
        6,
        previousNodeRelation.length
      );
      this.nodeList[index].nodeData["definition"].pathGroups[
        previousBranch
      ].nextNodeId = nextNode;
    }
  }
  //To add nextNodeId if we are deleting a node which is not connected to anyother node and its previous node is a branched node for ABTESTING and AUDIENCESPILT.
  addNextNodeIndexIfDeleteLast(prevNodeId, previousNodeRelation) {
    console.log("4");
    let index = this.nodeList.findIndex((x) => x.nodeId === prevNodeId);
    let previousNode = this.nodeList[index];
    if (previousNode.nodeType === "AUDIENCESPLIT") {
      //for AUDIENCESPLIT
      let previousBranch = previousNodeRelation.substring(
        this.startIndex,
        previousNodeRelation.length
      );
      this.nodeList[index].nodeData["definition"].splitGroups[
        previousBranch
      ].nextNodeId = null;
    } else {
      //for ABTESTING
      let previousBranch = previousNodeRelation.substring(
        6,
        previousNodeRelation.length
      );
      this.nodeList[index].nodeData["definition"].pathGroups[
        previousBranch
      ].nextNodeId = null;
    }
  }
  getFromRewardPreview(nodeData) {
    this._apiCallService
      .getRewardEmailDetail(nodeData["definition"]["emailLayoutId"])
      .subscribe((response) => {
        let emailTemplate = JSON.parse(response["body"]);
        let data = {
          selectedLayout: emailTemplate["layoutHtml"],
          templateId: emailTemplate["id"],
          templateName: emailTemplate["name"],
        };
        this.openTemplatePreviewDialog(data, "REWARD");
      }),
      (err) => {};
  }
  getCelebrationName(value, days) {
    switch (value) {
      case "BIRTHDAY_WEEK":
      case "ANNIVERSARY_WEEK":
        return "Week";
      case "BIRTHDAY_MONTH":
      case "ANNIVERSARY_MONTH":
        return "Month";
      case "CUSTOM_BIRTHDAY":
      case "CUSTOM_ANNIVERSARY":
        return days ? days[0] + " " + "days prior" : "";
    }
  }
  getAppointmentName(type, data) {
    return data[0] + " days " + data[1] + " hours prior.";
  }
  transformTo24HourFormat(time12hr: string): string {
    if (sessionStorage.getItem("serviceCountry") === "US") {
      // Use the DatePipe to format the time in 12-hour format
      let formattedTime = time12hr;

      // Parse the formatted time to get the hour and minute
      let parts = formattedTime.split(":");
      let hour = parseInt(parts[0], 10);
      let minute = parseInt(parts[1], 10);

      // Check if it's PM, and if it is, add 12 to the hour
      if (time12hr.toLowerCase().includes("pm")) {
        hour += 12;
      }

      // Format the hour and minute as two-digit strings
      let hourStr = hour.toString().padStart(2, "0");
      let minuteStr = minute.toString().padStart(2, "0");

      // Return the time in 24-hour format
      return `${hourStr}:${minuteStr}`;
    } else {
      return time12hr;
    }
  }
  transformTo12HourFormat(time24hr: string): string {
    // Split the time string into hours and minutes
    if (sessionStorage.getItem("serviceCountry") === "US") {
      const [hoursStr, minutesStr] = time24hr.split(":");
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);

      // Determine AM or PM based on hours
      const period = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      const hours12 = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

      // Format the time in 12-hour format
      const formattedTime = `${hours12}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;

      return formattedTime;
    } else {
      return time24hr;
    }
  }

  isCreatorOfJourney() {
    return this.qIdOfAccount == this.strategyData?.created_by?.qid;
  }

  isJouneyInDraftStatus() {
    return this.strategyStatus == 'DRAFT';
  }

  navigateBackToListing() {
    this._router.navigate(["/app/campaign/overview"], {
      queryParams: { type: "campaign" },
    });
  }

  getEventKey(child): string | null {
    const searchKeys = ['NTHTRIGGER', 'CUSTOM'];
    const mySet = new Set(searchKeys);

    // Use find to get the first matching key
    const foundKey = Object.keys(child.rules).find(key => mySet.has(key));

    console.log("FOUND",foundKey);
    
    return foundKey || null; // Return the found key or null if none is found
  }
}
