<div style="width: 100%;height: 100%;overflow: hidden;">
    <div style="display: flex;justify-content: space-between;width: 100%;margin-bottom: 10px;padding:15px;height: 50px;">

        <div class="offerNameText">
            <div>{{data['offerName']}}</div>
        </div>
        <div style="cursor: pointer;" (click)="close()">
            <mat-icon style="font-family: 'Material Icons';">close</mat-icon>
        </div>
    </div>
    <div class="seperator1"></div>


        <div style="height: calc(100% - 52px);overflow: auto;" >
            <div style="display:flex; align-items:center; justify-content:center;margin-bottom: 5%;">
                <div class="main-container" *ngIf="selectedbarrier&&!loading">

                    <div style="display:flex;border:1px dashed #fff">
                        <div style="width:30%; background: #edf0fa; overflow: scroll; height:520px;"
                            *ngIf="selectedbarrier">
                            <div *ngFor="let barrierReward of selectedbarrier; let i= index">
                                <span *ngIf="i!=0" style="display: flex;
                  padding: 20px;
                  border-top: 1px solid #d8d8d8;
                  font-weight: bold;">{{'SHARED_PAGE.BARRIER' | i18nTranslate}} {{i+1}}:</span>
                                <span *ngIf="i==0" style="display: flex;
                  padding: 20px;
                  font-weight: bold;">{{'SHARED_PAGE.BARRIER' | i18nTranslate}} {{i+1}}:</span>
                                <div>
                                    <div style="padding: 20px;">
                                        <span class="barrierSubHeading">{{'SHARED_PAGE.BARRIER_TYPE' | i18nTranslate}}</span>
                                        <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                            <span
                                                *ngIf="barrierReward['barrier']['barrierParamType']">{{barrierReward['barrier']['barrierParamType']| removeUnderscore|titlecase}}</span>
                                            <!-- <span *ngIf="barrierReward['barrier']['barrierParamType']===null">None</span> -->

                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="barrierReward['barrier']['barrierParamType'] !== 'NONE'">
                                    <div>
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.HURDLE_TYPE' | i18nTranslate}}</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span *ngIf="barrierReward['barrier']['barrierType'] === 'NONE'">{{'SHARED_PAGE.PRODUCT_BASED' | i18nTranslate}}</span>
                                                <span
                                                    *ngIf="barrierReward['barrier']['barrierType'] !== 'NONE'">{{barrierReward['barrier']['barrierType']| removeUnderscore|titlecase}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="barrierReward['barrier']['barrierParamType'] === 'PERSONALIZED' && barrierReward['barrier']['barrierType'] === 'PRODUCT_BASED'">
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.PROPENSITY' | i18nTranslate}}</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span
                                                    *ngIf="barrierReward['barrier']['productPropensity']">{{barrierReward['barrier']['productPropensity']| removeUnderscore|titlecase}}</span>
                                                <span
                                                    *ngIf="barrierReward['barrier']['productCategoryPropensity']">{{barrierReward['barrier']['productCategoryPropensity']| removeUnderscore|titlecase}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="barrierReward['barrier']['barrierParamType'] != 'PERSONALIZED' && (barrierReward['barrier']['barrierType'] === 'PRODUCT_BASED' || barrierReward['barrier']['barrierType'] === 'NONE')">
                                        <div style="padding: 20px;">
                                            <span *ngIf="barrierReward['barrier']['product']"
                                                class="barrierSubHeading">{{'SHARED_PAGE.PRODUCT_VARIANT' | i18nTranslate}}</span>
                                            <span *ngIf="barrierReward['barrier']['productCategory']"
                                                class="barrierSubHeading">{{'SHARED_PAGE.PRODUCT_CATEGORY' | i18nTranslate}}</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span
                                                    *ngIf="barrierReward['barrier']['product']">{{barrierReward['barrier']['productVariant']['variantName']}}</span>
                                                <span
                                                    *ngIf="barrierReward['barrier']['productCategory']">{{barrierReward['barrier']['productCategory']['productCategoryName']}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="barrierReward['barrier']['barrierType'] === 'PRODUCT_BASED' || barrierReward['barrier']['barrierType'] === 'NONE'">
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">Product Count</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span
                                                    *ngIf="barrierReward['barrier']['product']!=null">{{barrierReward['barrier']['product']['productCount']}}</span>
                                                <span
                                                    *ngIf="barrierReward['barrier']['productCategory']!=null">{{barrierReward['barrier']['productCategory']['productCount']}}</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="barrierReward['barrier']['barrierParamType'] !== 'PERSONALIZED' && barrierReward['barrier']['barrierType'] === 'SPENT_BASED'">
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.AMOUNT' | i18nTranslate}}</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span>{{barrierReward['barrier']['spentBarrier']}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.TIME_RANGE' | i18nTranslate}}</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span>{{barrierReward['barrier']['barrierDates']['startTime']}} - </span>
                                                <span>{{barrierReward['barrier']['barrierDates']['endTime']}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.DAYS_OF_THE_WEEK' | i18nTranslate}}</span>
                                            <div style="min-width: 70%; margin-top: 15px; display: flex; flex-wrap: wrap;">
                                                <span
                                                    *ngIf="barrierReward['barrier']['barrierDates']['daysOfTheWeek']!=null">
                                                    <span
                                                        *ngFor="let day of barrierReward['barrier']['barrierDates']['daysOfTheWeek']"
                                                        style="margin: 5px; background: #FFFFFF; padding: 5px; border-radius: 25px;">{{day}}</span>
                                                </span> <span
                                                    *ngIf="barrierReward['barrier']['barrierDates']['daysOfTheWeek']==null"
                                                    style="margin: 5px; background: #FFFFFF; padding: 5px; border-radius: 25px;">{{'SHARED_PAGE.ALL_DAYS' | i18nTranslate}}</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.TIME_TO_COMPLETE' | i18nTranslate}}</span>
                                            <div class="offerBarrierDetailListDiv" style="min-width: 70%;">
                                                <span>{{barrierReward['barrier']['barrierDates']['endDayCount']}}
                                                    {{'SHARED_PAGE.DAYS' | i18nTranslate}}</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="isNoneBarrierDetected!=true&&isBuyXGetYDetected!=true">
                                        <div style="padding: 20px;">
                                            <span class="barrierSubHeading">{{'SHARED_PAGE.EMAIL_TEMPLATE' | i18nTranslate}}</span>
                                            <div *ngIf="offerDetailEmailLayout" style="margin-top: 10px;">
                                                <img style="width: 180px;height: 190px;"
                                                    [src]="layoutSnapshotUrl" alt="No preview available">
                                            </div>
                                            <div *ngIf="offerDetailEmailLayout==null" class="offerBarrierDetailListDiv"
                                                style="min-width: 70%;">
                                                <span>{{'SHARED_PAGE.NO_EMAIL_TEMPLATE_ATTACHED' | i18nTranslate}}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style="width:70%; height:520px; padding: 20px;background: #fff;font-size:14px">
                            <!-- <span style="display: flex; justify-content: center; text-transform: uppercase;    padding-top: 30px;
              padding-bottom: 30px; font-weight: bold;">{{data['offerName']}}</span> -->
                            <div style="display:flex; justify-content:center;">
                                <div
                                    style="display:flex; flex-direction:column; justify-content:space-between; min-width: 70%; margin-bottom: 20px;">
                                    <div *ngFor="let barrierReward of selectedbarrier;let i=index; let l=last;">
                                        <div style="    display: flex;
                        flex-direction: column;">
                                            <span style="padding: 15px;
                               background: #edf0fa;
                border: 1px solid #dadada;
                            border-radius: 4px;

                            display: flex;
                            justify-content: center;">{{'SHARED_PAGE.BARRIER' | i18nTranslate}} {{i+1}}</span>
                                            <!-- <span *ngIf="i<data['barrierList'].length && ( barrier['offerReward'] || data['barrierList'][i+1] )" style="display: flex; justify-content: center;flex-direction: column;align-items: center;"> -->
                                            <span *ngIf="i<selectedbarrier.length"
                                                style="display: flex; justify-content: center;flex-direction: column;align-items: center;">


                                                <!-- <img [src]="assetPath.arrowUrl" alt=""> -->
                                                <div style=" height: 30px;
                          border-left: 1px solid #4abb59;"></div>
                                                <div>
                                                    <mat-icon class="keyboardArrowDown"> keyboard_arrow_down</mat-icon>
                                                </div>
                                                <div style="    height: 30px;
                          border-left: 1px solid #4abb59;"></div>
                                            </span>
                                        </div>
                                        <div *ngIf="barrierReward['reward']" style="display: flex;
                        flex-direction: column;">
                                            <span style="padding: 15px;
                               background: #edf0fa;
                border: 1px solid #dadada;
                            border-radius: 4px;
                          ">
                                                <div>
                                                    <div style="display: flex; justify-content: center;">
                                                        <div>
                                                            <span>{{'SHARED_PAGE.REWARD_TYPE' | i18nTranslate}} : </span>
                                                            <span>{{barrierReward['reward']['rewardType'] | removeUnderscore |titlecase}}</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div style="display: flex; justify-content: center;">
                                    <div>
                                        <span>Reward Personalization : </span>
                                        <span *ngIf="barrierReward['barrier']['productPropensity'] || barrierReward['barrier']['productCategoryPropensity']">Personalized</span>
                                        <span *ngIf="!(barrierReward['barrier']['productPropensity'] ||barrierReward['barrier']['productCategoryPropensity'])">Non
                                  Personalized</span>
                                    </div>
                                </div> -->
                                                    <div style="display: flex; justify-content: center;">
                                                        <div>
                                                            <div
                                                                *ngIf="barrierReward['reward']['rewardType'] === 'BUY_X_GET_Y'">
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['buyXGetYDiscount']['entitledType'] === 'PRODUCT'">{{'SHARED_PAGE.REWARD' | i18nTranslate}}
                                                                            {{'SHARED_PAGE.PRODUCT_VARIANT' | i18nTranslate}} : </span>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['buyXGetYDiscount']['entitledType'] === 'PRODUCT'">{{barrierReward['reward']['buyXGetYDiscount']['entitledVariants'][0]['variantName']| removeUnderscore |titlecase}}</span>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['buyXGetYDiscount']['entitledType'] === 'COLLECTION'">{{'SHARED_PAGE.REWARD' | i18nTranslate}}
                                                                            {{'SHARED_PAGE.COLLECTION' | i18nTranslate}} : </span>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['buyXGetYDiscount']['entitledType'] === 'COLLECTION'">{{barrierReward['reward']['buyXGetYDiscount']['entitledCollection'][0]['collectionName']| removeUnderscore |titlecase}}</span>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span>{{'SHARED_PAGE.PRODUCT_COUNT' | i18nTranslate}} : </span>
                                                                        <span>{{barrierReward['reward']['buyXGetYDiscount']['entitledQuantity']}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                *ngIf="barrierReward['reward']['rewardType'] === 'PERCENTAGE_OFF' || barrierReward['reward']['rewardType'] === 'FIXED_AMOUNT_OFF'">
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span>{{'SHARED_PAGE.OFFER_ON' | i18nTranslate}} : </span>
                                                                        <span>{{barrierReward['reward']['amountOrPercentageOffDiscount']['entitledType'] | removeUnderscore |titlecase}}</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="!(barrierReward['reward']['productPropensity'] || barrierReward['reward']['productCategoryPropensity'])">
                                                                    <div style="display: flex; justify-content: center;">
                                                                        <div>
                                                                            <span
                                                                                *ngIf="barrierReward['reward']['amountOrPercentageOffDiscount']['entitledType'] === 'PRODUCT'">{{'SHARED_PAGE.PRODUCT' | i18nTranslate}}
                                                                                : </span>
                                                                            <span
                                                                                *ngIf="barrierReward['reward']['amountOrPercentageOffDiscount']['entitledType'] === 'PRODUCT'">{{barrierReward['reward']['amountOrPercentageOffDiscount']['entitledVariants'][0]['variantName']| removeUnderscore |titlecase}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div style="display: flex; justify-content: center;">
                                                                        <div>
                                                                            <span
                                                                                *ngIf="barrierReward['reward']['amountOrPercentageOffDiscount']['entitledType'] === 'COLLECTION'">{{'SHARED_PAGE.COLLECTION' | i18nTranslate}}
                                                                                : </span>
                                                                            <span
                                                                                *ngIf="barrierReward['reward']['amountOrPercentageOffDiscount']['entitledType'] === 'COLLECTION'">{{barrierReward['reward']['amountOrPercentageOffDiscount']['entitledCollection'][0]['collectionName']| removeUnderscore |titlecase}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style="display:flex; justify-content: center;"
                                                                    *ngIf="barrierReward['reward']['productPropensity'] || barrierReward['reward']['productCategoryPropensity']">
                                                                    <span>{{'SHARED_PAGE.PROPENSITY' | i18nTranslate}} :</span>
                                                                    <span
                                                                        *ngIf="barrierReward['reward']['productPropensity'] != null">{{barrierReward['reward']['productPropensity']| removeUnderscore |titlecase}}</span>
                                                                    <span
                                                                        *ngIf="barrierReward['reward']['productCategoryPropensity'] != null">{{barrierReward['reward']['productCategoryPropensity']| removeUnderscore |titlecase}}</span>
                                                                </div>
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['rewardType'] === 'PERCENTAGE_OFF'">{{'SHARED_PAGE.DISCOUNT_PERCENTAGE' | i18nTranslate}} :
                                                                        </span>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['rewardType'] === 'PERCENTAGE_OFF'">{{barrierReward['reward']['amountOrPercentageOffDiscount']['discountValue']}}</span>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['rewardType'] === 'FIXED_AMOUNT_OFF'">{{'SHARED_PAGE.DISCOUNT_AMOUNT' | i18nTranslate}}
                                                                            :
                                                                        </span>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['rewardType'] === 'FIXED_AMOUNT_OFF'">{{barrierReward['reward']['amountOrPercentageOffDiscount']['discountValue']}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                *ngIf="barrierReward['reward']['rewardType'] === 'FREE_SHIPPING'">
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span>{{'SHARED_PAGE.FREE_SHIPPING_TYPE' | i18nTranslate}} : </span>
                                                                        <span>{{barrierReward['reward']['freeShippingDiscount']['entitledType']| removeUnderscore |titlecase}}</span>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['freeShippingDiscount']['entitledType'] === 'MIN_ORDER_AMOUNT'">{{'SHARED_PAGE.MIN_AMOUNT' | i18nTranslate}}
                                                                            : </span>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['freeShippingDiscount']['entitledType'] === 'MIN_ORDER_AMOUNT'">{{barrierReward['reward']['freeShippingDiscount']['minOrderAmount']}}</span>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; justify-content: center;">
                                                                    <div>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['freeShippingDiscount']['entitledType'] === 'MIN_ORDER_QUANTITY'">{{'SHARED_PAGE.MIN_ORDER' | i18nTranslate}}
                                                                            : </span>
                                                                        <span
                                                                            *ngIf="barrierReward['reward']['freeShippingDiscount']['entitledType'] === 'MIN_ORDER_QUANTITY'">{{barrierReward['reward']['freeShippingDiscount']['minOrderQuantity']}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div style="display: flex; justify-content: center;">
                                            <div>
                                                <span>Coupon Code : </span>
                                                <span>{{barrierReward['reward']['title']}}</span>
                                            </div>
                                        </div> -->
                                                            <div style="display: flex; justify-content: center;">
                                                                <div>
                                                                    <span>{{'SHARED_PAGE.DAYS_TO_REDEEM' | i18nTranslate}} : </span>
                                                                    <span>{{barrierReward['reward']['daysToRedeem']}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                            <!-- <span *ngIf="i<data['barrierList'].length-1" style="display: flex; justify-content: center;flex-direction: column;align-items: center;"> -->
                                            <span *ngIf="i<selectedbarrier.length-1"
                                                style="display: flex; justify-content: center;flex-direction: column;align-items: center;">

                                                <!-- <img [src]="assetPath.arrowUrl" alt=""></span> -->
                                                <div style="    height: 30px;
                          border-left: 1px solid #4abb59;"></div>
                                                <div>
                                                    <mat-icon class="keyboardArrowDown"> keyboard_arrow_down</mat-icon>
                                                </div>
                                                <div style="height: 30px;
                          border-left: 1px solid #4abb59;"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style="display:flex; justify-content:center;">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="loading">
                    <div style="display: flex;justify-content: center;width: 100%;height: 20vh;align-items: center;">
                        <mat-spinner diameter="35"></mat-spinner>
                    </div>
                </div>



            </div>
        </div>

</div>