import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';


@Component({
  selector: 'app-delete-contact',
  templateUrl: './delete-contact.component.html',
  styleUrls: ['./delete-contact.component.css']
})
export class DeleteContactComponent implements OnInit {

  constructor(private tokenStorage: TokenStorageService,
    private apiCall: ApiCallService,
    @Inject(MAT_DIALOG_DATA) public data: any) {window["screen-name"] = "SUPPRESSION LISTS"; }

  result = "delete";

  ngOnInit() {
  }

}
