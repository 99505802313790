<div style="display: flex; flex-direction: column; height: 100%">
  <div
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 0px 15px;
      height: 40px;
    "
  >
    <div class="head-title">{{'SHARED_PAGE.EXCLUDE_CATEGORY_PRODUCT' | i18nTranslate}}</div>
    <mat-icon
      style="
        color: #efefef;
        background: #a9a9a9;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      "
      (click)="cancel()"
      data-button-name="Close"
      >cancel</mat-icon
    >
  </div>
  <div
    style="
      padding: 0px 15px;
      line-height: 26px;
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 15px;
    "
  >
    {{'SHARED_PAGE.EXCLUDE_CATEGORY_PARAGRAPGH' | i18nTranslate}}
  </div>
  <div class="formDivGame-search" style="border-radius: 4px;
  background: #F8F8F8;
  margin: 0px 15px;
  height: 40px;">
      <input class="formInputGame" autocomplete="off" matInput [(ngModel)]="search"
          placeholder="Search category (Minimum 3 characters)" (ngModelChange)="getInputField($event);search=$event;">
      <span class="material-icons-outlined icons-primary cursor-pointer">
          search
      </span>
  </div>
  <div
    *ngIf="(!DataAvailability && !apiloading) || !NoData"
    style="
      flex-grow: 1;
      overflow: auto;
      height: calc(100% - 100px);
      padding: 0px 15px;
      margin: 10px 0px;
    "
  >
    <div style="display: flex; justify-content: center">{{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
  </div>
  <div
    *ngIf="apiloading"
    style="
      flex-grow: 1;
      overflow: auto;
      height: calc(100% - 100px);
      padding: 0px 15px;
      margin: 10px 0px;
    "
  >
    <mat-spinner diameter="15" style="margin-left: 45%"></mat-spinner>
  </div>
  <div
    style="
      flex-grow: 1;
      overflow: auto;
      height: calc(100% - 100px);
      padding: 0px 15px;
      margin: 10px 0px;
    "
    *ngIf="DataAvailability && !apiloading"
  >
    <div style="margin-bottom: 15px" *ngIf="search.length<3">
      <mat-checkbox
        class="example-margin"
        [checked]="
          totalNoOfProducts === ProductArrayId.length && totalNoOfProducts != 0
        "
        (change)="selectAllCategoryAndProduct($event.checked)"
        color="primary"
      >
      <div style="display: flex;">
        <div>{{'SHARED_PAGE.SELECT_ALL' | i18nTranslate}}</div>
        
          <span
          class="i-div"
          popoverClass="my-custom-class"
          placement="right"
          [ngbPopover]="popSelctAll"
          triggers="mouseenter:mouseleave"
          >i
          </span>
          <ng-template #popSelctAll>
          <div style="font-size: 12px; color: #fff">
            {{'SHARED_PAGE.SELECT_ALL_INFO' | i18nTranslate}}
          </div>
          </ng-template>
      </div>
      </mat-checkbox>
    </div>
    <hr *ngIf="search.length<3">
    <div style="display: flex; margin-bottom: 15px;" *ngIf="search.length<3">
      <section class="example-section" style="width: 100%;">
        <div style="display: flex; justify-content: space-between;">
          <div class="parent-product-ln">
            <mat-checkbox
              class="example-margin"
              [checked]="
                nonCategoryCount === countInArray('NON_CATEGORY') &&
                countInArray('NON_CATEGORY')
              "
              [indeterminate]="
                nonCategoryCount > countInArray('NON_CATEGORY') &&
                countInArray('NON_CATEGORY') != 0
              "
              (change)="
                setAllCheckedNonCategory(
                  $event.checked,
                  NonCategoryProduct,
                  'NON_CATEGORY'
                )
              "
              color="primary"
            >
              {{'SHARED_PAGE.NON_CATEGORY_PRODUCT' | i18nTranslate}}
            </mat-checkbox>
          </div>
          <div>
            <div
              class="material-icons-outlined parent-arrow"
              (click)="parentArrowNonCategory(); getDataForTable($event)"
            >
              chevron_right
            </div>
          </div>

        </div>
        <span
          class="variant-product-ln active"
          [id]="'variant-section-noncategory'"
        >
          <div class="heightProductList" (scroll)="onScroll($event)">
            <div *ngFor="let product of NonCategoryProduct">
              <mat-checkbox
                color="primary"
                class="example-margin"
                #selectGame
                [checked]="ProductArrayId.indexOf(product.id) != -1"
                (change)="
                  updateSelection(
                    product.id,
                    $event.checked,
                    'NON_CATEGORY',
                    NonCategoryProduct
                  )
                "
              >
                {{ product.name }}
              </mat-checkbox>
            </div>
          </div>
        </span>
      </section>

    </div>
    <div
      *ngFor="let category of ProductAllIds; let i = index"
      style="margin-bottom: 15px"
    >
      <div style="display: flex" *ngIf="category.products.length != 0">
        <!-- [indeterminate]="someComplete(product)"  -->
        <section class="example-section" style="width: 100%;">
          <div style="display: flex; justify-content: space-between;">
            <span class="parent-product-ln">
              <mat-checkbox
                class="example-margin"
                [checked]="
                  category.products.length === countInArray(category.id) &&
                  countInArray(category.id)
                "
                [indeterminate]="
                  category.products.length > countInArray(category.id) &&
                  countInArray(category.id) != 0
                "
                (change)="
                  setAllChecked($event.checked, category.products, category.id)
                "
                color="primary"
              >
                {{ category.name }}
              </mat-checkbox>
            </span>
            <div>
              <span
                class="material-icons-outlined parent-arrow"
                (click)="parentArrowClicked(i); getDataForTable($event)"
              >
                chevron_right
              </span>
            </div>

          </div>
          <span class="variant-product-ln active" [id]="'variant-section-' + i">
            <div class="heightProductList">
              <div *ngFor="let product of category['products']">
                <mat-checkbox
                  color="primary"
                  [checked]="ProductArrayId.indexOf(product.id) != -1"
                  (change)="
                    updateSelection(
                      product.id,
                      $event.checked,
                      category.id,
                      category.products
                    )
                  "
                >
                  {{ product.name }}
                </mat-checkbox>
              </div>
            </div>
          </span>
        </section>
      </div>
    </div>
  </div>
  <div     style="
  height: 85px;
  width: 100%;
  padding: 15px 15px;
  box-shadow: 2px -2px 20px rgb(0 0 0 / 8%);
">
<div style="display: flex;justify-content: flex-end; align-items: center;">
  <div class="cancel-btn" (click)="cancel()">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
  <div class="primary-button" (click)="save()">{{'SHARED_PAGE.SAVE' | i18nTranslate}}</div>
</div>
<div id="qbGmExcludedProducts"style="font-size: 10px; color: red;margin-left: 125px;
margin-top: 5px;">
                      
</div>

  </div>
</div>
