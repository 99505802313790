import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logical-expressions-viewer',
  templateUrl: './logical-expression-viewer.component.html',
  styleUrls: ['./logical-expression-viewer.component.css']
})
export class LogicalExpressionsViewerComponent implements OnInit {

  @Input() logicalExpressions;

  constructor() { }

  ngOnInit(): void {
  }

}
