<div class="qb-main" #container id="container">
  <div class="your-customers-div">
    <div style="display: flex;">
      <div class="your-customers">{{'SHARED_PAGE.UPLOAD_HISTORY' | i18nTranslate}}</div>
      <div>
        <span class="material-symbols-outlined refresh" [matTooltip]="'SHARED_PAGE.REFRESH_STATUS' | i18nTranslate"
          (click)="fetchUploadHistory(listType)">
          autorenew
        </span>

      </div>
    </div>
    <div style="display: flex;">
      <div class="primary-button profile-change-image-button" style="margin-left: 10px;" (click)="uploadCSV()">
        Upload Data
      </div>

    </div>

  </div>
  <div class="main-white-panel main-cont">
    <div class="import-history-container">
      <div *ngFor="let entry of importEntries" class="import-entry" [ngClass]="{
            'completed-background': entry.status === 'COMPLETED',
            'failed-background': entry.status === 'FAILED',
            'in-progress-background': entry.status === 'IN_PROGRESS'
          }">
        <div class="icon">
          <span *ngIf="entry.status === 'COMPLETED'" class="material-icons success-icon">check_circle</span>
          <span *ngIf="entry.status === 'FAILED'" class="material-icons warning-icon">warning</span>
          <span *ngIf="entry.status === 'IN_PROGRESS'" class="material-symbols-outlined ongoing-icon">
            hourglass_bottom
          </span>
        </div>
        <div *ngIf="entry.status != 'IN_PROGRESS'" class="details">
          <div class="header" style="display: flex;">
            <h3>{{ entry.title }}</h3>
            <small>Imported on {{ entry.importedOn }}</small>
          </div>
          <ul>
            <li *ngFor="let detail of entry.details">{{ detail }}</li>
          </ul>
        </div>
        <div *ngIf="entry.status === 'IN_PROGRESS'" class="details">
          <div class="header" style="display: flex;">
            <h3>On-going Upload</h3>
            <small>Uploaded at {{ entry.importedOn }}</small>
          </div>
          <h6>Your upload is in progress. Please be patient.</h6>
        </div>
  
        <div class="actions">
          <button class="primary-button1 button-hover-effect" (click)="previewList(entry.custListId)">View</button>
        </div>
      </div>
      <div *ngIf="importEntries.length == 0" class="no-data">
        No Upload History
      </div>
    </div>
    <div class="qb-paginate-div">
      <mat-paginator
        [pageIndex]="pageIndex - 1"
        [pageSize]="pageSize"
        [length]="totalCount"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="refreshPaginatedData($event)"
      >
      </mat-paginator>
    </div>
  </div>
  
</div>