<div style="min-width: 700px;">
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div style="text-transform: capitalize; font-size:1.5rem; font-weight: 700;">{{'SHARED_PAGE.AI_GENERATED_MAIL' | i18nTranslate}}</div>
    <div
      (click)="close()"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      "
    >
      <div
      >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.22222 8L0 1.77778L1.77778 0L8 6.22222L14.2222 0L16 1.77778L9.77778 8L16 14.2222L14.2222 16L8 9.77778L1.77778 16L0 14.2222L6.22222 8Z" fill="#202224"/>
        </svg>
        
      </div>
    </div>
  </div>
  <div style="display: flex;">
    <div style="width: 49%">
      <div
        style="
          font-size: 14px;
          color: var(--main-font-grey2);
          font-weight: 600;
          margin-top: 25px;
          margin-bottom: 10px;
        "
      >
      {{'SHARED_PAGE.WRITE_BRIEF' | i18nTranslate}}
      </div>
      <div
        style="
          border: 1px solid var(--main-brd-grey);
          border-radius: 5px;
          width: 93%;
          height: 100px;
          padding: 5px 10px;
        "
      >
        <textarea
          placeholder="Example: Thanksgiving offers for our loyal customers"
          [(ngModel)]="question"
        ></textarea>
      </div>
      <div *ngIf="questionError !== ''" style="font-size: 12px; color: red">
        {{ questionError }}
      </div>
      <div
        style="
          font-size: 14px;
          color: var(--main-font-grey2);
          margin-top: 25px;
          margin-bottom: 10px;
          font-weight: 600;
        "
      >
        {{'SHARED_PAGE.ADD_TAG' | i18nTranslate}}
      </div>
      <div class="notificationPopOver" id="notificationPopOver"
        (click)="open($event)"
        style="
          position: relative;
          width: 93%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid var(--main-brd-grey);
          border-radius: 4px;
          padding: 5px 10px;
          cursor: pointer;
        "
      >
        <div style="color: var(--main-font-black)">{{'SHARED_PAGE.SELECT' | i18nTranslate}}</div>
        <div>
          <div
            *ngIf="!showDropdown"
            class="material-symbols-outlined"
            style="display: flex"
          >
            expand_more
          </div>
          <div
            *ngIf="showDropdown"
            class="material-symbols-outlined"
            style="display: flex"
          >
            expand_less
          </div>
        </div>
      </div>
      <div
        *ngIf="showDropdown"
        style="
          border: 1px solid var(--main-brd-grey);
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 4px 4px;
          margin-top: -3px;
          position: absolute;
          width: 24.9%;
          background: white;
          padding: 10px 0;
          height: 100px;
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <div
          *ngFor="let tag of allTags"
          style="
            padding: 10px;
            cursor: pointer;
            align-items: center;
            gap: 5px;
            width: 100%;
          "
          class="tb-tag"
        >
          <mat-checkbox style="width: 100%;"
            [checked]="selectedTags.indexOf(tag) !== -1"
            (change)="selectTag(tag)"
            ><div style="width: 100%;">{{ tag | i18nDynamicTranslate : 'SHARED_PAGE'}}</div></mat-checkbox
          >
        </div>
      </div>
      <div
        style="
          margin-top: 15px;
          display: inline-grid;
          grid-template-columns: auto auto;
          gap: 7px 6px;
        "
      >
        <div
          *ngFor="let tag of selectedTags"
          style="
            background: rgba(1, 131, 251, 0.05);
            border-radius: 34px;
            display: flex;
            width: fit-content;
            gap: 10px;
            padding: 5px 12px;
            align-items: center;
          "
        >
          <div>{{ tag }}</div>
          <div
            class="material-symbols-outlined"
            style="font-size: 15px; color: #0183fb; cursor: pointer"
            (click)="deleteTag(tag)"
          >
            close
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end; gap: 10px; margin-right: 25px;">
      <div
        class="secondary-button-border"
        style="margin-top: 40px"
        (click)="close()"
      >
        {{'SHARED_PAGE.CANCEL' | i18nTranslate}}
      </div>

      <div
      class="primary-button"
      style="    width: 125px;
      padding: 10px 0; margin-top: 40px"
      (click)="generate()"
    >
      {{'SHARED_PAGE.GENERATE_EMAIL' | i18nTranslate}}
    </div>
      </div>
     
    </div>
    <div style="border: 1px solid rgba(218, 215, 215, 0.31)"></div>
    <div [ngStyle]="{'margin-left': (!loadingSample) ? '38px' : '38px'}" *ngIf="sampleEmailText.length == 0">
      <img
        style="margin-left: 25px"
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/chat-gpt/chatbotgif.gif"
        width="250"
      />
      <div style="margin-top: -28px; margin-left: 65px" *ngIf="!loadingSample">
        {{'SHARED_PAGE.START_GENERATING' | i18nTranslate}}
      </div>
      <img
        *ngIf="!loadingSample"
        style="margin-right: 145px"
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/chat-gpt/arrow.png"
      />
      <div style="margin-top: -28px; margin-left: 65px" *ngIf="loadingSample">
        {{'SHARED_PAGE.AI_GENERATING' | i18nTranslate}}
        <div>
          <div
            *ngIf="loadingSample"
            style="
              border: 2px dashed #0183fb;
              border-radius: 14px;
              width: 160px;
              height: 17px;
              padding: 0 10px;
            "
          >
            <div
              style="
                background: #0183fb;
                border-radius: 14px;
                height: 5px;
                width: 80px;
                margin-top: 4px;
                padding: 0px;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="sampleEmailText.length !== 0 && !loadingSample"
      style="margin-left: 20px; width: 42%"
    >
      <div
        style="
          font-size: 12px;
          color: rgba(34, 34, 34, 0.5);
          margin-bottom: 10px;
        "
      >
        {{'SHARED_PAGE.GENERATED_EMAIL' | i18nTranslate}}
      </div>
      <div
        style="
          border: 1px solid #636466;
          border-radius: 5px;
          color: black;
          padding: 10px;
          line-height: 25px;
          cursor: not-allowed;
        "
      >
        {{ sampleEmailText }}
      </div>
      <div (click)="copy()" style="display:flex;border: 1px solid #0183FB;
      border-radius: 4px; color: #0183FB;    padding: 5px; cursor: pointer;
    width: 109px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;">
    <div class="material-symbols-outlined">
      content_copy
  </div>
        <div>{{'SHARED_PAGE.COPY_TEXT' | i18nTranslate}}</div>
      </div>
    </div>
  </div>
</div>
