<div style="display: flex; flex-direction: column;height: 100%;">
  <div class="header">
    <span style="text-align: center; font-weight: bold;" *ngIf="data['reminderStatus']=='0'">{{'SHARED_PAGE.SEND_CONSENT_SMS' | i18nTranslate}}</span>
    <span style="text-align: center; font-weight: bold;" *ngIf="data['reminderStatus']=='1'">{{'SHARED_PAGE.SEND_SMS_CONSENT_REMINDER' | i18nTranslate}}</span>
    <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
  </div>
  <div *ngIf="(data['FirtTimeCustomer'] >0 && data['reminderStatus']=='0') || (data['ReminderCustomer']>0 && data['reminderStatus']=='1')"> 
  <div class="message" *ngIf="data['CustomerNotInUSA']!='0'">{{'SHARED_PAGE.LOCATED_OUTSIDE_USA' | i18nTranslate}}</div>
  <div class="message1" *ngIf="data['CustomerNotInUSA']=='0' && data['reminderStatus']=='0'">{{'SHARED_PAGE.SMS_CONSENT_SEND_TO' | i18nTranslate}} <b>{{data['FirtTimeCustomer']}}</b> {{'SHARED_PAGE.OF_YOUR_CUSTOMER' | i18nTranslate}}.</div>
  <div class="message1" *ngIf="data['CustomerNotInUSA']=='0' && data['reminderStatus']=='1'">{{'SHARED_PAGE.SMS_CONSENT_SEND_TO' | i18nTranslate}} <b>{{data['ReminderCustomer']}}</b> {{'SHARED_PAGE.OF_YOUR_CUSTOMER' | i18nTranslate}}.</div>
  <div class="message1" *ngIf="data['CustomerNotInUSA']=='0' && data['reminderStatus']=='0'">{{'SHARED_PAGE.CONTINUE_CONSENT_PARAGRAPH' | i18nTranslate}}.</div>
  <div class="message1" *ngIf="data['CustomerNotInUSA']=='0' && data['reminderStatus']=='1'">{{'SHARED_PAGE.CONTINUE_CONSENT_REMINDER_PARAGRAPH' | i18nTranslate}}.</div>
    <ul class="Infolist" *ngIf="data['CustomerNotInUSA']!='0'">
      <li>
        <div style="width: 94%; margin-top: 5px;">{{'SHARED_PAGE.TO_CONTINUE_FOR_USA' | i18nTranslate}} <b>{{'SHARED_PAGE.PLEASE_CLICK_SEND' | i18nTranslate}}</b>.</div>
      </li>
      <li>
        <div style="width: 94%; margin-top: 5px;">{{'SHARED_PAGE.REACHOUT_TO_CS_TEAM' | i18nTranslate}} <b style="color: #0183FB; text-decoration: underline;">cs@qubriux.com</b></div>
      </li>
    </ul>
  </div>
    <div style="flex-grow: 1;" *ngIf="((data['FirtTimeCustomer'] ==0 && data['reminderStatus']=='0') || (data['ReminderCustomer'] ==0 && data['reminderStatus']=='1'))">
      <div class="message1" *ngIf="data['reminderStatus']=='1'&& data['ReminderCustomer'] == 0"><b>{{'SHARED_PAGE.NUMBER_OF_CUSTOMER_TARGETED' | i18nTranslate}} {{data['ReminderCustomer']}}</b></div>
      <div class="message1" *ngIf="data['reminderStatus']=='0'&& data['FirtTimeCustomer'] == 0"><b>{{'SHARED_PAGE.NUMBER_OF_CUSTOMER_TARGETED' | i18nTranslate}} {{data['FirtTimeCustomer']}}</b></div>
      <div class="message1" *ngIf="data['reminderStatus']=='0'&& data['totalCustomer'] == 0">{{'SHARED_PAGE.SEGMENT_NO_CUSTOMER' | i18nTranslate}}</div>
      <div class="message1" *ngIf="data['reminderStatus']=='1'&& data['totalCustomer'] == 0">{{'SHARED_PAGE.SEGMENT_NO_CUSTOMER' | i18nTranslate}}</div>
      <div class="message1" *ngIf="data['reminderStatus']=='1'&& data['totalCustomer'] == data['InvalidCustomer'] && data['totalCustomer']>0">{{'SHARED_PAGE.SEGMENT_NO_VALID_CUSTOMER' | i18nTranslate}}</div>
      <div class="message1" *ngIf="data['reminderStatus']=='0'&& data['totalCustomer'] == data['InvalidCustomer'] && data['totalCustomer']>0">{{'SHARED_PAGE.SEGMENT_NO_VALID_CUSTOMER' | i18nTranslate}}</div>
      <div class="message1" *ngIf="data['reminderStatus']=='0'&& data['customerCount'] == data['ConsentGivenCount'] && data['ConsentGivenCount']>0">{{'SHARED_PAGE.SEGMENT_ALREADY_GIVEN_CONSENT' | i18nTranslate}}.</div>
      <div class="message1" *ngIf="data['reminderStatus']=='1'&& data['customerCount'] == data['ConsentGivenCount'] && data['ConsentGivenCount']>0">{{'SHARED_PAGE.SEGMENT_ALREADY_GIVEN_CONSENT' | i18nTranslate}}.</div>
      <div class="message1" *ngIf="data['secondreminderCount'] !=0 && data['reminderStatus']=='1'&& data['ReminderCustomer'] > 0">{{'SHARED_PAGE.SEGMENT_FEW_CUSTOMER_GIVEN_CONSENT'| i18nTranslate}}.</div>
      <!-- <div class="reminder-text" *ngIf="segmentLength && isAllCustomer && secondreminderCount !=0 && reminderStatus=='1'&& ReminderCustomer == 0">This segment has customers who already have received the reminder sms. Consent SMS reminder cannot be sent to these customers.</div> -->
      <div class="message1" *ngIf="data['secondreminderCount'] !=0 && data['reminderStatus']=='1'&& data['ReminderCustomer'] == 0">{{'SHARED_PAGE.SEGMENT_ALREADY_RECIECED_REMINDER' | i18nTranslate}}.</div>
      <div class="message1" *ngIf="data['secondreminderCount'] !=0 && data['reminderStatus']=='0'&& data['ReminderCustomer'] == 0 && data['FirtTimeCustomer']==0">{{'SHARED_PAGE.SEGMENT_NOT_RESPONDED_TO_BOTH' | i18nTranslate}}.</div>
      <div class="message1" *ngIf="data['ReminderCustomer'] !=0 && data['reminderStatus']=='0'">{{'SHARED_PAGE.SEGEMNT_RECIEVED_FIRST_BUT_NO_RESPOND' | i18nTranslate}}. </div>
      <div class="message1" *ngIf="data['ReminderCustomer'] !=0 && data['reminderStatus']=='0'">{{'SHARED_PAGE.PLEASE_SELECT' | i18nTranslate}} <b>{{'SHARED_PAGE.CONSENT_REMINDER_SMS' | i18nTranslate}}</b> {{'SHARED_PAGE.OPTION_TO_SEND_REMINDER' | i18nTranslate}}. </div>
      <div class="message1" *ngIf="data['FirtTimeCustomer'] !=0 && data['reminderStatus']=='1'">{{'SHARED_PAGE.SEGMENT_NOT_SEND_FIRST_CONSENT' | i18nTranslate}}.</div>
      <div class="message1" *ngIf="data['FirtTimeCustomer'] !=0 && data['reminderStatus']=='1'">{{'SHARED_PAGE.PLEASE_SELECT' | i18nTranslate}} <b>{{'SHARED_PAGE.FIRST_CONSENT_SMS' | i18nTranslate}}</b> {{'SHARED_PAGE.OPTION_TO_SEND_SMS' | i18nTranslate}}.</div>
   
    </div>
  
    <div class="buttons">
      <mat-dialog-actions  *ngIf="(data['FirtTimeCustomer'] >0 && data['reminderStatus']=='0') || (data['ReminderCustomer']>0 && data['reminderStatus']=='1')">
        <button class="cancel secondary-button-border" (click)="submit(false)"  data-button-name="Canceled">Cancel</button>
      </mat-dialog-actions>
      <mat-dialog-actions *ngIf="data['reminderStatus']=='0' && data['FirtTimeCustomer'] !=0 ">
        <button class="confirm primary-button" (click)="sendConsent()" data-button-name="Confirm: consent added"  >{{'SHARED_PAGE.SEND_CONSENT_SMS_SMALL' | i18nTranslate}}</button>
      </mat-dialog-actions>
      <mat-dialog-actions *ngIf="data['reminderStatus']=='1' && data['ReminderCustomer'] !=0">
        <button class="confirm primary-button" (click)="sendConsent()" data-button-name="Confirm: consent added"  >{{'SHARED_PAGE.SEND_REMINDER_CONSENT_SMS' | i18nTranslate}}</button>
      </mat-dialog-actions>
      <mat-dialog-actions *ngIf="((data['FirtTimeCustomer'] ==0 && data['reminderStatus']=='0') || (data['ReminderCustomer'] ==0 && data['reminderStatus']=='1'))">
        <button class="confirm primary-button" (click)="submit(false)" data-button-name="Confirm: consent added"  >{{'SHARED_PAGE.CLOSE' | i18nTranslate}}</button>
      </mat-dialog-actions>
    </div>
  
  
</div>