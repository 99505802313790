import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-personal-offer-list-view-dialog-box',
  templateUrl: './personal-offer-list-view-dialog-box.component.html',
  styleUrls: ['./personal-offer-list-view-dialog-box.component.css']
})
export class PersonalOfferListViewDialogBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PersonalOfferListViewDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  listOfItems = [];

  ngOnInit(): void {
    console.log("DATA of list", this.data);
    this.listOfItems = this.data.list;
  }
  submit(decision){
    this.dialogRef.close(decision)
  }

}
