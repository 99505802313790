import {
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

@Component({
  selector: "app-survey-base",
  templateUrl: "./survey-base.component.html",
  styleUrls: ["./survey-base.component.css"],
})
export class SurveyBaseComponent implements OnInit {
  selectedTab = "survey-list";
  contentHeight = 0;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileView;
  mainTopic;
  appSubscriptionPlan;
  validWidgets
  constructor(private router: Router, private route: ActivatedRoute,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService) {
    window["screen-name"] = "SURVEY";
  }

  ngOnInit() {
    this.getWidgets();
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.route.queryParams.subscribe((params) => {
      if (params.type) this.selectedTab = params.type;
      else
        this.router.navigate(["/app/surveys"], {
          queryParams: { type: "dashboard" },
        });
    });
  }

  tabChange(tab) {
    this.selectedTab = tab;
  }

  newSurvey() {
    this.router.navigate(["/app/surveys/add-survey"]);
  }
  async surveylist() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ){
        this.router.navigate(["/app/surveys/survey-list"]);
      }
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }
}
