import { style } from "@angular/animations";
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ApiCallService } from "src/app/core/services/api-call-service";
interface contentData {
  orientation: string;
  boxPosition: number;
  imageURL: string;
  closeButton: boolean;
  addCoupon: boolean;
  couponType: string;
  couponValue: number;
  couponValidity: number;
  heading: {
    content: string;
    style: string;
  };
  subHeading: {
    content: string;
    style: string;
  };
  inputPlaceholder: string;
  button: {
    content: string;
    style: string;
  };
  actionOnButtonClick: string;
  sendEmail: boolean;
  emailTemplateId: string;
  emailTemplateName: string;
  successMessage: string;
  url: string;
  subscribedMessage: string;
  emptyMessage: string;
  invalidMessage: string;
  generalMessage: string;
  contentClicked: string;
}

interface style {
  color: string;
  fontSize: number;
  fontFamily: string;
  textDecoration: string;
  background: string;
}

@Component({
  selector: "app-form-content-edit",
  templateUrl: "./form-content-edit.component.html",
  styleUrls: ["./form-content-edit.component.css"],
})
export class FormContentEditComponent implements OnInit, OnChanges {
  constructor(private _ApiCallService: ApiCallService) {}

  @Input() contentData: contentData;
  @Input() changeDetect;
  @Input() scheduleConstants;
  @Input() formId;
  @Input() msgError;
  @Output() contentDataChange = new EventEmitter<any>();
  data: contentData;
  backupData: contentData;
  boxPositionToggleStatus: boolean = false;
  messageInputToggleStatus: boolean = false;
  availableFonts = [
    "Titillium Web",
    "Nunito",
    "Slabo",
    "Fira Code",
    "Muli",
    "PT Serif",
    "PT Sans",
    "ZCOOL XiaoWei",
    "Merriweather",
    "Source Sans Pro",
    "Roboto Condensed",
    "Montserrat",
    "Lato",
    "Roboto",
    "Open Sans",
  ];
  textDecoration = ["None", "Bold", "Italic", "Underline"];
  styles = {
    headerStyle: style,
    subHeadStyle: style,
    buttonStyle: style,
  };
  submitClicked = true;

  ngOnInit() {
    this.data = this.contentData;
    this.styles.headerStyle = JSON.parse(this.data.heading.style);
    this.styles.subHeadStyle = JSON.parse(this.data.subHeading.style);
    this.styles.buttonStyle = JSON.parse(this.data.button.style);
    this.initForm();
  }

  ngOnChanges() {
    this.boxPositionToggleStatus = false;
    if (this.msgError === true) {
      this.messageInputToggle(true);
    }
  }

  initForm() {
    if (this.data.addCoupon) {
      let interval = setInterval(() => {
        let element = document.getElementById("add-coupon-container");
        if (element) {
          clearInterval(interval);
          element.style.height = "auto";
        }
      });
    }
  }

  hidePopup(event) {
    let headerElement = document.getElementById("heading-config-popup");
    if (headerElement) {
      if (!headerElement.contains(event.target)) {
        headerElement.style.display = "none";
      }
    }
    let subHeaderElement = document.getElementById("sub-heading-config-popup");
    if (subHeaderElement) {
      if (!subHeaderElement.contains(event.target)) {
        subHeaderElement.style.display = "none";
      }
    }
  }

  updateData(param, value) {
    this.data[param] = value;
    this.contentDataChange.emit(this.data);
  }

  boxPositionToggle() {
    this.boxPositionToggleStatus = !this.boxPositionToggleStatus;
    let element = document.getElementById("box-position-parent");
    if (this.boxPositionToggleStatus) {
      element.style.height = "auto";
    } else {
      element.style.height = "56px";
    }
  }

  messageInputToggle(status) {
    this.messageInputToggleStatus = status;
    let element = document.getElementById("message-input-header");
    if (this.messageInputToggleStatus) {
      element.style.height = "auto";
    } else {
      element.style.height = "56px";
    }
  }

  addCouponToggle(status) {
    let element = document.getElementById("add-coupon-container");
    if (status) {
      element.style.height = "auto";
    } else {
      element.style.height = "52px";
    }
  }

  uploadImage(event) {
    let file = <File>event.target.files[0];
    const fd = new FormData();
    fd.append("file", file, file.name);
    this._ApiCallService
      .uploadFormImage(fd, this.formId)
      .subscribe((response) => {
        this.contentData.imageURL = response["body"];
        this.data.imageURL = response["body"];
      });
  }

  openPopup(event, id) {
    this.backupData = JSON.parse(JSON.stringify(this.data));
    event.stopPropagation();
    document.getElementById(id).style.display = "block";
  }

  fontSizeUpdate(dataObject, fontObject, status) {
    if (status) {
      this.styles[fontObject].fontSize++;
    } else {
      if (this.styles[fontObject].fontSize === 1) {
        return;
      }
      this.styles[fontObject].fontSize--;
    }
    this.data[dataObject].style = JSON.stringify(this.styles[fontObject]);
    this.contentDataChange.emit(this.data);
  }

  saveStyle(id, status, dataObject, objectName) {
    document.getElementById(id).style.display = "none";
    if (status) {
      this.data[dataObject].style = JSON.stringify(this.styles[objectName]);
      this.contentDataChange.emit(this.data);
    } else {
      this.data = JSON.parse(JSON.stringify(this.backupData));
      this.styles.headerStyle = JSON.parse(this.data.heading.style);
      this.styles.subHeadStyle = JSON.parse(this.data.subHeading.style);
      this.styles.buttonStyle = JSON.parse(this.data.button.style);
      this.contentDataChange.emit(this.data);
    }
  }

  updateValue(object, status) {
    if (status) {
      this.data[object]++;
    } else {
      this.data[object]--;
    }
    this.contentDataChange.emit(this.data);
  }

  styleChangeLive(dataObject, objectName) {
    this.data[dataObject].style = JSON.stringify(this.styles[objectName]);
    this.contentDataChange.emit(this.data);
  }
}
