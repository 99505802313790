<div *ngIf="!isLoading" class="main-side-nav" data-widget-name="CREATE NEW CAMPAIGN">
  <div *ngIf="showFirstNav" class="first-nav wd-50">
    <div class="first-nav-title fs08-rem">
      <div>
        <span style="font-size: 1.14rem">{{ firstNavTitle | i18nDynamicTranslate : 'POS_PAGE'}}</span>
      </div>
      <div>
        <mat-icon class="cursor-pointer close-icon" (click)="saveConfigOnLeftPanel(false)">
          close
        </mat-icon>
      </div>
    </div>
    <div style="height: calc(100% - 100px); background: white; overflow: auto">

      <div *ngIf="nodeType == 'RESPONSE_EVENT'" style="margin: 0px 10px 20px 10px">
        <div class="align-items-nav">
          <!-- <mat-radio-group [(ngModel)]="actionStatus" (change)="changeAction()" class="qb-d-inline-flex qb-radio-position">
            <mat-radio-button value="action" color="primary" style="margin-right: 10px;">Action Based</mat-radio-button>
            <mat-radio-button value="segment" color="primary">Segment Based</mat-radio-button>
          </mat-radio-group> -->
          <div *ngIf="actionStatus === 'action'">
            <div style="margin-top: 10px; margin-bottom: 15px">
              <span style="font-size: 12px;">{{'CAMPAIGN_PAGE.SELECT_BRANDING_COND' | i18nTranslate}}</span><span style="color: red">*</span>
              <div class="formDivNode" style="margin-top: 10px;" [ngClass]="{
                  active: eventName.errors
                    ? (eventName.errors.required &&
                        eventName.touched &&
                        submitted) ||
                      (eventName.errors.required && submitted)
                    : false
                }">
                <mat-select [compareWith]="compareCustomerEvent" class="font-class fs08-rem"
                  [formControl]="eventName" placeholder="Select an event" (selectionChange)="onResponseEventSelect($event)">
                  <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Event found'"
                      [formControl]="customerEventFilterCtrl" [disableInitialFocus]="true"></ngx-mat-select-search>
                  </mat-option>
                  <div *ngFor="let event of filteredcustomerEvents | async" >
                    <mat-option class="font-class fs08-rem"
                      style="color: grey" [value]="event" *ngIf="event.eventName !== 'ANNIVERSARY' && event.eventName !== 'BIRTHDAY' && event.eventName !== 'nth lifetime order count'">
                      <div style="display: flex; align-items: center;">
                        <div class="material-symbols-outlined icons-primary" style="font-size: 16px; margin-right: 5px">
                          {{ getImage(event.eventName) }}
                        </div>
                        <div>
                          {{ getName(event.eventName)}}
                        </div>
                      </div>
                    </mat-option>
                  </div>
                  <mat-select-trigger class="select-trigger">
                    <div class="icon-data">
                      <span class="
                          material-symbols-outlined
                          icons-primary
                          node-header-icon
                        ">
                        {{ getImage(eventName?.value?.eventName) }}
                      </span>
                      &nbsp;&nbsp;{{ getName(eventName?.value?.eventName) }}
                    </div>
                  </mat-select-trigger>
                </mat-select>
              </div>
              <small style="font-size: 0.7rem; color: red" *ngIf="
              eventName.value==null &&
              submitted
            ">{{'CAMPAIGN_PAGE.FIELD_REQ' | i18nTranslate}}</small>
    
    
    
            </div>
            <div>
              <span style="font-size: 12px;">{{'CAMPAIGN_PAGE.OCCUR_WITHIN' | i18nTranslate}}</span><span style="color: red">*</span>
              <div class="conditional-config">
                <div style="width: 25%">
                  <span style="font-size: 0.7rem; color: #959595">{{'CAMPAIGN_PAGE.DAYS' | i18nTranslate}}</span>
                  <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                      active: daysControl.errors
                        ? (daysControl.errors.required && submitted) ||
                          (!daysControl.errors.required &&
                            daysControl.errors.minVal &&
                            submitted)
                        : (daysControl.value == 0 &&
                            minutesControl.value == 0 &&
                            hoursControl.value === 0 &&
                            submitted) ||
                          false
                    }">
                    <input type="number" min="0" style="width: 95%" class="formInput fs08-rem"
                      [formControl]="daysControl" />
                  </div>
                </div>
                <div style="width: 25%">
                  <span style="font-size: 0.7rem; color: #959595">{{'CAMPAIGN_PAGE.HOURS' | i18nTranslate}}</span>
                  <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                      active: hoursControl.errors
                        ? (hoursControl.errors.required &&
                            hoursControl.touched &&
                            submitted) ||
                          (hoursControl.errors.required && submitted)
                        : (daysControl.value == 0 &&
                            minutesControl.value == 0 &&
                            hoursControl.value === 0 &&
                            submitted) ||
                          false
                    }">
                    <mat-select style="width: 95%" [formControl]="hoursControl" class="font-class fs08-rem">
                      <mat-option *ngFor="let hour of hours" [value]="hour" class="font-class fs08-rem">
                        {{ hour }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <div style="width: 25%">
                  <span style="font-size: 0.7rem; color: #959595">{{'CAMPAIGN_PAGE.MINUTES' | i18nTranslate}}</span>
                  <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                      active: minutesControl.errors
                        ? (minutesControl.errors.required &&
                            minutesControl.touched &&
                            submitted) ||
                          (minutesControl.errors.required && submitted)
                        : (daysControl.value == 0 &&
                            minutesControl.value == 0 &&
                            hoursControl.value === 0 &&
                            submitted) ||
                          false
                    }">
                    <mat-select style="width: 95%" [formControl]="minutesControl" class="font-class fs08-rem">
                      <mat-option *ngFor="let minute of minutes" [value]="minute" class="font-class fs08-rem">{{
                        minute }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
              <small style="font-size: 0.7rem; color: red" *ngIf="
                  daysControl.errors &&
                  !daysControl.errors.required &&
                  daysControl.errors.minVal &&
                  submitted
                ">{{'CAMPAIGN_PAGE.NEGATICE_NOT_ALLOWED' | i18nTranslate}}</small>
              <small style="font-size: 0.7rem; color: red" *ngIf="
                  daysControl.value == 0 &&
                  minutesControl.value == 0 &&
                  hoursControl.value === 0 &&
                  submitted
                ">{{'CAMPAIGN_PAGE.DURECATION_NOT_ZERO' | i18nTranslate}}</small>
            </div>
            <!-- <div *ngIf="!showfilter">
              <div class="primary-button" style="width: 130px; background: #2762AB;" (click)="filterChange()">
                <span class="material-icons-outlined button-icon" style="color: #fff">
                  add
                </span>
                <span>{{'CAMPAIGN_PAGE.ADD_FILTER' | i18nTranslate}}</span>
              </div>
            </div> -->
            <!-- <div>
              <div class="trigger-ac" [ngClass]="{'red-border':!leForAdditionalTriggerConfig2}">
                <div class="qb-error-text" *ngIf="!leForAdditionalTriggerConfig2">Please enter valid values</div>
                <app-trigger-additional-config [additionalConfigs]="additionalTriggerConfigs2" [showAc]="showAdditionalTriggerConfig2" 
                  [preSelectedLogicalExpressions]="preSelectedLogicalExpressions2" [apiProductList]="apiProductList" [storeList]="storeList" (cancelEvent)="leForAdditionalTriggerConfig2=true" #triggerAdditionalConfig2></app-trigger-additional-config>
              </div>
            </div> -->
          </div>
          <div *ngIf="actionStatus === 'segment'">
            <div class="qb-groupName-heading">
              {{'CAMPAIGN_PAGE.SELECT_SEGMENTS' | i18nTranslate}}
            </div>
            <div
            #userSegment
            class="qb-user-segment-div"
          >
            <div>
              <div
                class="qb-multi-select-panel-seg"
                id="multi-select-panel-seg"
                (click)="toggleSelectSegment()"
              >
                <span *ngIf="customerSelectedSegment[0] === 'ALL'"
                  >{{'CAMPAIGN_PAGE.SEGMENTS_SELECTED' | i18nTranslate}} :
                  {{'CAMPAIGN_PAGE.ALL_CUSTTOMERS' | i18nTranslate}}</span
                >
                <span *ngIf="customerSelectedSegment[0] !== 'ALL'"
                >{{'CAMPAIGN_PAGE.SEGMENTS_SELECTED' | i18nTranslate}} :
                {{ customerSelectedSegment.length }}</span
              >
                <div id="keyboard-down-icon-container-seg">
                  <mat-icon class="qb-keyboard-down-icon-seg"
                    >keyboard_arrow_down</mat-icon
                  >
                </div>
              </div>
              <div class="qb-p-relative">
                <div
                  class="qb-multi-select-dropdown-seg"
                  id="multi-select-dropdown-seg"
                >
                  <div class="qb-search">
                    <mat-icon class="qb-group-icon">search</mat-icon>
                    <input
                      [(ngModel)]="tempSegmentGameFilter"
                      placeholder="Search Segment"
                      type="text"
                      class="qb-search-segment"
                    />
                  </div>
                  <div class="qb-border-line"></div>
                  <div id="segment-select-seg" class="qb-segment-select">
                    <div
                      *ngFor="
                        let customer of allcustomer
                          | labelPipe: tempSegmentGameFilter:'name'
                      "
                    >
                      <mat-checkbox
                        [checked]="
                          customerSelectedSegment.length === 1 &&
                          customerSelectedSegment[0] === 'ALL'
                        "
                        (change)="addAllCustomers($event)"
                        color="primary"
                        >{{ customer.name }}</mat-checkbox
                      >
                      </div>
                      <div
                        *ngFor="
                          let segment of customerSegment
                            | labelPipe: tempSegmentGameFilter:'name'
                        "
                      >
                        <mat-checkbox
                          [checked]="isChecked(segment)"
                          (change)="
                            updateCheckboxValuesCustomerSegment(segment)
                          "
                          [disabled]="segment.isActive===false"
                          color="primary"
                          >{{ segment.name }}
                        </mat-checkbox>
  
                        <div>
                          <!-- {{customerSelectedSegment | isCheckedPYG: segment.id}} -->
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="qb-invalid-text-h qb-h-10">
                <!-- <div>{{segmentErrorText}}</div> -->
              </div>
            </div>
            <!-- {{customerSelectedSegment[0]}} -->
            <div
            class="qb-error-text"
            *ngIf="customerSelectedSegment.length === 0"
          >
            {{'CAMPAIGN_PAGE.SELECT_ANY_ONE_SEGMENT' | i18nTranslate}}
          </div>
  
            <div
             class="qb-multiple-select-info"
            >
              *{{'CAMPAIGN_PAGE.SELECT_MULTIPLE_SEGMENT' | i18nTranslate}}
            </div>
          </div>
          <!-- <div *ngIf="!showfilter">
            <div class="primary-button" style="width: 130px; background: #2762AB;" (click)="filterChange()">
              <span class="material-icons-outlined button-icon" style="color: #fff">
                add
              </span>
              <span>{{'CAMPAIGN_PAGE.ADD_FILTER' | i18nTranslate}}</span>
            </div>
          </div>
          <app-campaign-add-filter (messageEvent)="receiveMessage($event)" [showfilter]="showfilter" [showfilter]="showfilter"[segmentDataValue]="filterData" #filterNodeData></app-campaign-add-filter> -->
          </div>
        </div>
      </div>


      <div style="margin: 0px 10px 20px 10px" *ngIf="nodeType == 'ABTESTING'">
        <div class="align-items-nav">
          <div class="qb-ab-heading">
            {{'CAMPAIGN_PAGE.AB_TESTING_HEADING' | i18nTranslate}}
          </div>
          <div >
            {{abTesting[0][percValue]}}
            <div class="qb-path-background"  *ngFor="let condition of abTesting;let j = index" [style.background]="condition['pathErrorMessage']?'#ff00001c':'#F8F8F8'">
              <div class="qb-d-flex" style="justify-content: space-between; width: 100%;">
                <div class="qb-d-flex each-path-row">
                  <!-- <div>
                    Path {{j+1}}
                  </div> -->
                  <div class="rp-name-inp-container">
                    <input
                      type="text"
                      class="rp-name-inp"
                      (keydown)="stringInputHandler($event, 15)"
                      maxlength="15" #inp2
                      [(ngModel)]="condition.pathName"
                      (input)="clearPathErrors()"/>
                    <div class="inp-text-left">
                      {{inp2.value.length}}/{{inp2.getAttribute('maxlength')}}
                    </div>
                  </div>
                  <div class="rp-name-inp-container width-25-perc">
                    <input class="rp-name-inp" type="number" (input)="clearPathErrors()" [(ngModel)]="condition['percValue']" (keydown)="numberInputHandler($event,5,'float')" maxlength="5" #inp3 min="0" max="100">
                    <div class="inp-text-left">
                      {{inp3.value.length}}/{{inp3.getAttribute('maxlength')}}
                    </div>
                  </div>
                  <div class="font-18">
                    %
                  </div>
                </div>
                <div *ngIf="abTesting.length > 2 && !pathedit" (click)="deletePath(j)">
                  <span
                  class="
                    material-icons-outlined
                    icons-primary
                    qb-rule-cancel-icon
                  "
                >
                  delete
                </span>
                </div>
              </div>
              <div *ngIf="condition.pathErrorMessage" class="path-error-style">
                {{condition.pathErrorMessage}}
              </div>
            </div>

            <div class="qb-path-background" *ngIf="controlPath">
              <div class="qb-d-flex" style="justify-content: space-between; margin: 10px; width: 100%;" >
                <div class="qb-d-flex">
                  <div>
                    Control
                  </div>
                  <div class="qb-value-container">
                    <input type="number" class="qb-forminput" [(ngModel)]="controlValue">
                    <span>%</span>
                  </div>
                </div>
                <div (click)="addControl()" *ngIf="!pathedit">
                  <span
                  class="
                    material-icons-outlined
                    icons-primary
                    qb-rule-cancel-icon
                  "
                >
                  delete
                </span>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="checkSum() === false && isErrorAB === true && checkZero() === false" style="color: red; font-size: 10px; line-height: 12px; margin-right: 8px">
            <em>{{'CAMPAIGN_PAGE.TOTAL_SUM100' | i18nTranslate}}</em>
          </div>
          <!-- <div *ngIf="checkZero() === true && isErrorAB === true" style="color: red; font-size: 10px; line-height: 12px; margin-right: 8px">
            <em>{{'CAMPAIGN_PAGE.PATH_NOT_0' | i18nTranslate}}</em>
          </div> -->
          
          <div class="qb-d-flex gap-5-px">
            <div (click)="addPath()" *ngIf="this.abTesting.length <= 4 && !pathedit" class="primary-button">
              <div class="qb-add-condition-container font-size-13">
                <mat-icon
                  class="qb-add-condition-icon-2"
                  data-button-name="Add Condition"
                  >add</mat-icon
                >
                <span
                >{{'CAMPAIGN_PAGE.ADD_PATH' | i18nTranslate }}</span>
              </div>
            </div>
            <div class="primary-button font-size-13" (click)="distrubuteEvenly()">
              {{'CAMPAIGN_PAGE.DISTRIBUTE_EVENLY' | i18nTranslate}}
            </div>
            <!-- <div (click)="addControl()" style="margin-left: 20px;" *ngIf="!controlPath">
              <div class="qb-add-condition-container">
                <mat-icon
                  class="qb-add-condition-icon"
                  data-button-name="Add Condition"
                  >add</mat-icon
                >
                <span
                >Add Control Group</span>
              </div>
            </div> -->

          </div>
          <hr>
          <div class="qb-exp-text">
            {{'CAMPAIGN_PAGE.EXP_WINDOW' | i18nTranslate}}
          </div>
          <div class="qb-ab-heading" style="margin-top: 10px;">
            {{'CAMPAIGN_PAGE.EXP_WINDOW_DESP' | i18nTranslate}}
          </div>
          <div style="margin-top: 10px;">
            
            <div class="exp-window">
              <div class="exp-window-each">
                <div>Days</div>
                <div class="rp-name-inp-container">
                  <input class="rp-name-inp" type="number" (input)="clearPathErrors()" [(ngModel)]="expWindow[1]" (keydown)="numberInputHandler($event,3,'number')" maxlength="3" #inp8 min="0" max="999">
                  <div class="inp-text-left">
                    {{inp8.value.length}}/{{inp8.getAttribute('maxlength')}}
                  </div>
                </div>
              </div>
              
              <div class="exp-window-each">
                <div>Hours</div>
                <mat-select [(value)]="expWindow[0]" class="hours-picker">
                  <mat-option *ngFor="let value of hourValues" [value]="value">{{ value }}</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <div *ngIf="isErrorAB === true && (this.expWindow[0] === 0 && this.expWindow[1] === 0)" style="color: red; font-size: 10px; line-height: 12px; margin-right: 8px">
            <em>Durating cannot be 0</em>
          </div>
          

          
          

        </div>
      </div>

      <div style="margin: 0px 10px 20px 10px" *ngIf="nodeType == 'AUDIENCESPLIT'">
        <div class="align-items-nav">
          <div class="qb-groupName-heading">
            {{'CAMPAIGN_PAGE.GRP_NAME' | i18nTranslate}}
          </div>
          <div class="qb-name-input">
            <input type="text" class="formInput" [(ngModel)]="groupName" pattern="^[A-Za-z0-9]+$">
          </div>
          <div style="color: red; font-size: 10px; line-height: 12px; margin-right: 8px" *ngIf="isErrorAudience === true">
            <em>{{errorText | i18nDynamicTranslate : 'POS_PAGE'}}</em>
          </div>
          <hr>
          <div class="qb-exp-text">
            {{'CAMPAIGN_PAGE.AUD_DEF' | i18nTranslate}}
          </div>
          <div class="qb-ab-heading" style="margin-top: 10px;">
            {{'CAMPAIGN_PAGE.AUD_DEF_DESCRIPTION' | i18nTranslate}}
          </div>
          <div class="qb-groupName-heading">
            {{'CAMPAIGN_PAGE.SELECT_SEG_TIERS' | i18nTranslate}}
          </div>
          <div
          #userSegment
          class="qb-user-segment-div"
          id="qb-user-segment-div"
        >
          <div>
            <div
              class="qb-multi-select-panel-seg"
              id="multi-select-panel-seg"
              (click)="toggleSelectSegment()"
            >
              <span *ngIf="customerSelectedSegment.length === 0"
                >{{'CAMPAIGN_PAGE.SELECT_A_SEG_TIER' | i18nTranslate}}</span
              >
              <span *ngIf="customerSelectedSegment.length > 0"
              >{{'CAMPAIGN_PAGE.SELECTED' | i18nTranslate}} :
              {{ customerSelectedSegment.length }}</span
            >
              <div id="keyboard-down-icon-container-seg">
                <mat-icon class="qb-keyboard-down-icon-seg"
                  >keyboard_arrow_down</mat-icon
                >
              </div>
            </div>
            <div class="qb-p-relative">
              <div
                class="qb-multi-select-dropdown-seg"
                id="multi-select-dropdown-seg"
              >
                <div class="qb-search">
                  <mat-icon class="qb-group-icon">search</mat-icon>
                  <input
                    [(ngModel)]="tempSegmentGameFilter"
                    placeholder="Search"
                    type="text"
                    class="qb-search-segment"
                  />
                </div>
                <div class="qb-border-line"></div>
                <div id="segment-select-seg" class="qb-segment-select">
                    <div class="category-text-container" *ngIf="isTierSegmentPresent('segment')">
                      <div class="category-line"></div>
                      <span class="category-text">{{'CAMPAIGN_PAGE.SEGMENTS' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <div
                      *ngFor="
                        let segment of customerSegmentAndTiers
                          | labelPipe: tempSegmentGameFilter:'name'
                      "
                    >
                      <ng-container *ngIf="segment.category.toLowerCase() == 'segment'">
                        <mat-checkbox
                          [checked]="isChecked(segment)"
                          (change)="
                            updateCheckboxValuesCustomerSegment(segment)
                          "
                          [disabled]="segment.isActive===false"
                          color="primary"
                          >{{ segment.name }}
                        </mat-checkbox>
                      </ng-container>
                    </div>
                    <div class="category-text-container" *ngIf="isTierSegmentPresent('tier')">
                      <div class="category-line"></div>
                      <span class="category-text">{{'CAMPAIGN_PAGE.LOYALITY_TIERS' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <div
                      *ngFor="
                        let segment of customerSegmentAndTiers
                          | labelPipe: tempSegmentGameFilter:'name'
                      "
                    >
                      <ng-container *ngIf="segment.category.toLowerCase() == 'tier'">
                        <mat-checkbox
                          [checked]="isChecked(segment)"
                          (change)="
                            updateCheckboxValuesCustomerSegment(segment)
                          "
                          [disabled]="segment.isActive===false"
                          color="primary"
                          >{{ segment.name }}
                        </mat-checkbox>
                      </ng-container>
                    </div>
                    <div class="category-text-container" *ngIf="isTierSegmentPresent('customerlist')">
                      <div class="category-line"></div>
                      <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                      <div class="category-line"></div>
                    </div>
                    <div
                    *ngFor="
                      let segment of customerSegmentAndTiers
                        | labelPipe: tempSegmentGameFilter:'name'
                    "
                  >
                    <ng-container *ngIf="segment.category.toLowerCase() == 'customerlist'">
                      <mat-checkbox
                        [checked]="isChecked(segment)"
                        (change)="
                          updateCheckboxValuesCustomerSegment(segment)
                        "
                        [disabled]="segment.isActive===false"
                        color="primary"
                        >{{ segment.name }}
                      </mat-checkbox>
                    </ng-container>
                  </div>
                  <div class="category-text-container" *ngIf="isTierSegmentPresent('employeelist')">
                    <div class="category-line"></div>
                    <span class="category-text">{{'COMMON_PAGE.EMPLOYEE_LIST' | i18nTranslate}}</span>
                    <div class="category-line"></div>
                  </div>
                  <div
                  *ngFor="
                    let segment of customerSegmentAndTiers
                      | labelPipe: tempSegmentGameFilter:'name'
                  "
                >
                  <ng-container *ngIf="segment.category.toLowerCase() == 'employeelist'">
                    <mat-checkbox
                      [checked]="isChecked(segment)"
                      (change)="
                        updateCheckboxValuesCustomerSegment(segment)
                      "
                      [disabled]="segment.isActive===false"
                      color="primary"
                      >{{ segment.name }}
                    </mat-checkbox>
                  </ng-container>
                </div>
                </div>
              </div>
            </div>
            <div class="qb-invalid-text-h qb-h-10">
              <!-- <div>{{segmentErrorText}}</div> -->
            </div>
          </div>
          <!-- {{customerSelectedSegment[0]}} -->
          <div
          class="qb-error-text"
          *ngIf="customerSelectedSegment.length === 0 && isErrorAudience === true"
        >
          {{'CAMPAIGN_PAGE.SELECT_ANY_ONE_VALUE' | i18nTranslate}}
        </div>

          <div
           class="qb-multiple-select-info"
          >
            *{{'CAMPAIGN_PAGE.SELECT_MULTIPLE_VALUES' | i18nTranslate}}
          </div>
        </div>
        <!-- <div *ngIf="!showfilter">
          <div class="primary-button" style="width: 130px; background: #2762AB;" (click)="filterChange()">
            <span class="material-icons-outlined button-icon" style="color: #fff">
              add
            </span>
            <span>{{'CAMPAIGN_PAGE.ADD_FILTER' | i18nTranslate}}</span>
          </div>
        </div>
        <div *ngIf="showfilter && !showClicked">
          <div class="primary-button margin-bottom-20" style="width: 130px; background: #2762AB;" (click)="filterChange()">
            <span>{{'CAMPAIGN_PAGE.SHOW_FILTER' | i18nTranslate}}</span>
          </div>
        </div>
        <div class="qb-error-text" *ngIf="isErrorAudience && isfilterError">Please fill all values</div>
        <app-campaign-add-filter (messageEvent)="receiveMessage($event)" [showfilter]="showfilter"[segmentDataValue]="filterData" #filterNodeData></app-campaign-add-filter> -->
        </div>
      </div>

      <div style="margin: 0px 10px 20px 10px" *ngIf="nodeType == 'TRIGGER'">
        <div class="align-items-nav">
          <div style="margin-top: 10px; margin-bottom: 15px">
            <span class="fs08-rem">{{'CAMPAIGN_PAGE.WHAT_SHOULD_TRIGGER' | i18nTranslate}}?</span><span style="color: red">*</span>
            <div class="formDiv">
              <mat-select [compareWith]="compareCustomerEvent" placeholder="Select an event" class="font-class fs08-rem"
                [formControl]="eventName" (selectionChange)="onCustomerEventChange($event)">
                <mat-option>
                  <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Event found'"
                    [formControl]="customerEventFilterCtrl"></ngx-mat-select-search>
                  <span class="
                      material-icons-outlined
                      icons-primary
                      node-header-icon
                    ">
                    search
                  </span>
                </mat-option>
                <mat-option *ngFor="let event of filteredcustomerEvents | async" class="font-class fs08-rem"
                  style="color: grey" [value]="event">
                  <div style="display: flex; align-items: center;">
                    <div class="material-symbols-outlined icons-primary" style="font-size: 16px; margin-right: 5px"> 
                      {{ getImage(event.eventName) }}
                    </div>
                    <div>
                      {{ getName(event.eventName)  }}
                    </div>
                  </div>
                </mat-option>
                <mat-select-trigger class="select-trigger" style="height: 44px;">
                  <div class="icon-data">
                    <span class="
                        material-symbols-outlined
                        icons-primary
                        node-header-icon
                      ">
                      {{ getImage(eventName?.value?.eventName) }}
                    </span>
                    <span class="trigger-disp-style">{{ getName(eventName?.value?.eventName)}}</span>
                  </div>
                </mat-select-trigger>
              </mat-select>
            </div>
          </div>
          <div
          class="qb-show-warning qb-mb-10"
          style="display: none;"
          id = 'inforForIcon'
        >
          <div class="qb-width7">
            <span
              class="qb-i-div-schedule-offer2"
              popoverClass="qb-my-custom-class"
              placement="bottom"
            >
              i
            </span>
          </div>
          <div class="qb-width93">
            <div>
              <span class="qb-celeb-caution"
                >
                {{getInfoForEvents(eventName?.value?.eventName)}}
              </span>
            </div>
          </div>
        </div>
          <div *ngIf="showNumberInputForEvnet" class="n-value-container">
            <div class="padding-top-7">N =</div>
            <div class="rp-name-inp-container rule-value width-35">
              <input class="rp-name-inp" type="number" [(ngModel)]="nthLifeTimeOrderCountValue" (keydown)="numberInputHandler($event,5,'number')" maxlength="5" #inp2 min="0" max="99999">
              <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'CAMPAIGN_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
            </div>
            <div *ngIf="nthLifeTimeOrderCountValue==1" class="padding-top-7"> st lifetime order</div>
            <div *ngIf="nthLifeTimeOrderCountValue==2" class="padding-top-7"> nd lifetime order</div>
            <div *ngIf="nthLifeTimeOrderCountValue==3" class="padding-top-7"> rd lifetime order</div>
            <div *ngIf="nthLifeTimeOrderCountValue>3" class="padding-top-7"> th lifetime order</div>
          </div>
          <div *ngIf="isCelebration && checkCelebration(celebration,false) ">
            <span class="fs08-rem">{{'CAMPAIGN_PAGE.CAMPAIGN_ACTIVATION_TRIGGER' | i18nTranslate}}?</span><span style="color: red">*</span>
            <div [matMenuTriggerFor]="selectCelebration" style="text-transform: capitalize; margin-top: 5px;" class="border-celebration cursor-pointer">
              <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 90%;">
                <div *ngIf="celebrationType.length > 0">
                  {{getCelebrationType(celebrationType) | titlecase}}
                </div>
                <div *ngIf="celebrationType.length === 0">
                  {{'CAMPAIGN_PAGE.SELECT_CELBRATION_TYPE' | i18nTranslate}}
                </div>
                <span>
                  <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
                  >keyboard_arrow_down</mat-icon
                >
                </span>
              </div>
            </div>
            <mat-menu #selectCelebration class="qb-channel-dialog" style="width: auto;">
              <button
              mat-menu-item
              class="qb-channel-dialog-content"
              (click)="changeCelebration('Month', celebration)"
            >
              <span>{{celebration | titlecase}}&nbsp;{{'CAMPAIGN_PAGE.MONTH' | i18nTranslate}}</span>
            </button>
              <button
                mat-menu-item
                class="qb-channel-dialog-content"
                (click)="changeCelebration('Week', celebration)"
              >
                <span>{{celebration | titlecase}}&nbsp;{{'CAMPAIGN_PAGE.WEEK' | i18nTranslate}}</span>
              </button>
              <button
              mat-menu-item
              *ngIf="dateRadioOption === 1"
              class="qb-channel-dialog-content"
              (click)="changeCelebration('Custom', celebration)"
            >
              <span>{{'CAMPAIGN_PAGE.CUSTOM' | i18nTranslate}}&nbsp;{{celebration | titlecase}}</span>
            </button>
            <button
            mat-menu-item
            *ngIf="dateRadioOption === 2"
            class="qb-channel-dialog-content"
            style="width: 280px;"
            (click)="changeCelebration('Custom', celebration)"
          >
            <span style="text-transform: capitalize;">{{'CAMPAIGN_PAGE.ON' | i18nTranslate}}&nbsp;{{celebration | titlecase}}</span>
          </button>
            </mat-menu>
            <div style="margin-bottom: 10px;">
              <div
              class="qb-show-warning"
              *ngIf="
              isCelebration && celebrationType === 'Week'
              "
            >
              <div class="qb-width7">
                <span
                  class="qb-i-div-schedule-offer2"
                  popoverClass="qb-my-custom-class"
                  placement="bottom"
                >
                  i
                </span>
              </div>
              <div class="qb-width93">
                <div>
                  <span class="qb-celeb-caution"
                    >
                    {{'CAMPAIGN_PAGE.CELEBRATION_WEEK' | i18nTranslate : celebration.toLowerCase() }}
                  </span>
                </div>
              </div>
            </div>
            <div
            class="qb-show-warning"
            *ngIf="
            isCelebration && celebrationType === 'Month'
            "
          >
            <div class="qb-width7">
              <span
                class="qb-i-div-schedule-offer2"
                popoverClass="qb-my-custom-class"
                placement="bottom"
              >
                i
              </span>
            </div>
            <div class="qb-width93">
              <div>
                <span class="qb-celeb-caution"
                  >
                  {{'CAMPAIGN_PAGE.CELEBRATION_MONTH' | i18nTranslate : celebration.toLowerCase() }}
                </span>
              </div>
            </div>
          </div>
          <div
          class="qb-show-warning"
          *ngIf="
         isCelebration && celebrationType === 'Custom' && dateRadioOption === 1
          "
        >
          <div class="qb-width7">
            <span
              class="qb-i-div-schedule-offer2"
              popoverClass="qb-my-custom-class"
              placement="bottom"
            >
              i
            </span>
          </div>
          <div class="qb-width93">
            <div>
              <span class="qb-celeb-caution"
                >
                {{'CAMPAIGN_PAGE.CELEBRATION_CUSTOM' | i18nTranslate : celebration.toLowerCase()}}
              </span>
            </div>
          </div>
        </div>
        <div
          class="qb-show-warning"
          *ngIf="
         isCelebration && celebrationType === 'Custom' && dateRadioOption === 2
          "
        >
          <div class="qb-width7">
            <span
              class="qb-i-div-schedule-offer2"
              popoverClass="qb-my-custom-class"
              placement="bottom"
            >
              i
            </span>
          </div>
          <div class="qb-width93">
            <div>
              <span class="qb-celeb-caution"
                >
                {{'CAMPAIGN_PAGE.CELEBRATION_CUSTOM_SPECIFIC' | i18nTranslate : celebration.toLowerCase()}}
              </span>
            </div>
          </div>
        </div>
            </div>
          </div>
          <div *ngIf="isCelebration && checkCelebration(celebration,true)">
            <span class="fs08-rem">{{'CAMPAIGN_PAGE.CAMPAIGN_ACTIVATION_TRIGGER' | i18nTranslate}}?</span><span style="color: red">*</span>
            <div [matMenuTriggerFor]="selectAppointmnetType" style="text-transform: capitalize; margin-top: 5px;" class="border-celebration cursor-pointer">
              <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 90%;">
                <div>
                  {{appointmentType | titlecase}}
                </div>
                <span>
                  <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
                  >keyboard_arrow_down</mat-icon
                >
                </span>
              </div>
            </div>
            <mat-menu #selectAppointmnetType class="qb-channel-dialog" style="width: auto;">
              <button
              mat-menu-item
              class="qb-channel-dialog-content"
              (click)="changeAppointment('INSTANT')"
            >
              <span>Instant</span>
            </button>
              <button
              mat-menu-item
              class="qb-channel-dialog-content"
              (click)="changeAppointment('SCHEDULED')"
            >
              <span>Scheduled</span>
            </button>
            </mat-menu>
          </div>
          <div class="trigger-radio_group">
            <mat-radio-group style="display: flex">
              <mat-radio-button color="primary" [disableRipple]="true" class="fs08-rem" style="margin-right: 20px"
                [checked]="dateRadioOption == 1" (change)="onDateRadioChange($event)" value="1">Date Range
              </mat-radio-button>
              <mat-radio-button color="primary" [disableRipple]="true" class="fs08-rem" [checked]="dateRadioOption == 2"
                (change)="onDateRadioChange($event)" value="2" [ngClass]="
                  disableDateRadioSelection ? 'disable-radio-color' : ''
                ">Specific Date
              </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="dateRadioOption == 2" [ngStyle]="{ width: disableDateRadioSelection ? '88%' : '44%' }">

              <!-- <span *ngIf="disableDateRadioSelection" class="fs08-rem">Journey should be triggered on</span> -->
              <span class="fs08-rem">On</span><span style="color: red">*</span>

              <div class="formDiv" [ngStyle]="{
                  width: disableDateRadioSelection ? '44%' : '100%'
                }">
                <input style=" width: 0px; opacity: 0;"  [formControl]="startDate" class="formInput fs08-rem" matInput
                  [matDatepicker]="picker1" placeholder="Choose a date" [min]="startDateMinValue" (dateChange)="startDateChange()" readonly />
                  <div class=" formInput fs08-rem" style="margin-left: 5%;">
                    {{startDate.value | date: getDateFormat() }}
                  </div>
                <mat-datepicker-toggle matSuffix [for]="picker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </div>
            </div>
            <div *ngIf="dateRadioOption == 1" style="display: flex; justify-content: space-between">
              <div style="width: 44%">
                <span class="fs08-rem">{{'CAMPAIGN_PAGE.FROM' | i18nTranslate}}</span><span style="color: red">*</span>
                <div class="formDiv" [ngClass]="{active:startDate.value==null||startDate.length==0 || checkIfBeforeCurrentDate(startDate.value)}">
                  <input style=" width: 0px; opacity: 0;"  [formControl]="startDate" matInput
                    [matDatepicker]="picker1" placeholder="Choose a date" [min]="startDateMinValue"
                    (dateChange)="startDateChange()" readonly />
                    <div class=" formInput fs08-rem" style="margin-left: 5%;">
                      {{startDate.value | date: getDateFormat() }}
                    </div>
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </div>
              </div>
              <div style="width: 44%">
                <span class="fs08-rem">{{'CAMPAIGN_PAGE.TO' | i18nTranslate}}</span><span style="color: red">*</span>
                <div class="formDiv">
                  <input style=" width: 0px; opacity: 0;" [formControl]="endDate"  matInput
                    [matDatepicker]="picker2" placeholder="Choose a date" [min]="endDateMinValue"
                    (dateChange)="endDateChange()" readonly />
                    <div class=" formInput fs08-rem" style="margin-left: 5%;">
                      {{endDate.value | date: getDateFormat() }}
                    </div>
                  <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px" *ngIf="!isCelebration || checkCelebration(celebration,true)">
            <div *ngIf="!disableDateRadioSelection" style="display: flex; justify-content: space-between">
              <span class="fs08-rem">{{'CAMPAIGN_PAGE.AT_ANYtIME_DAY' | i18nTranslate}}</span>
              <mat-slide-toggle color="accent" [(ngModel)]="anyTimeSliderValue"></mat-slide-toggle>
            </div>
            <div *ngIf="!anyTimeSliderValue && !(dateRadioOption == 2 && disableDateRadioSelection)"
              style="display: flex; justify-content: space-between">
              <div style="width: 44%">
                <span class="fs08-rem">{{'CAMPAIGN_PAGE.FROM' | i18nTranslate}}</span><span style="color: red">*</span>
                <div class="formDiv" [ngClass]="{active:startTime.value==null||startTime.length==0}">
                  <input style="margin-left: 5%" class="formInput fs08-rem cursor-pointer" [formControl]="startTime"
                    [ngxTimepicker]="picker1T" [max]="startMaxTime" [min]="startMinTime" [format]="serviceCountry === 'US' ? 12 : 24" readonly />
                  <ngx-material-timepicker #picker1T (closed)="startTimeChange()">
                  </ngx-material-timepicker>
                  <img
                  [ngxTimepicker]="picker1T"
                  style="margin-right: 10px; cursor: pointer;"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                  alt=""
                  (closed)="startTimeChange()"
                />
                </div>
              </div>
              <div style="width: 44%">
                <span class="fs08-rem">{{'CAMPAIGN_PAGE.TO' | i18nTranslate}}</span><span style="color: red">*</span>
                <div class="formDiv" [ngClass]="{active:endTime.value==null||endTime.length==0}">
                  <input style="margin-left: 5%" class="formInput cursor-pointer" [formControl]="endTime"
                    [ngxTimepicker]="picker2T" [min]="endMinTime" [format]="serviceCountry === 'US' ? 12 : 24" readonly />
                  <ngx-material-timepicker #picker2T (closed)="endTimeChange()">
                  </ngx-material-timepicker>
                  <img
                  style="margin-right: 10px; cursor: pointer;"
                  [ngxTimepicker]="picker2T"
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                  alt=""
                  (closed)="endTimeChange()"
                />
                </div>
              </div>
            </div>
            <div
              *ngIf="(!anyTimeSliderValue && !disableDateRadioSelection)&&((startTime.value==null||startTime.length==0)||(endTime.value==null||endTime.length==0))"
              style="color: red;font-size: 11px;margin-top: 5px">{{'CAMPAIGN_PAGE.SELECT_TIME' | i18nTranslate}}</div>
            <div *ngIf="disableDateRadioSelection && dateRadioOption == 2" style="display: flex; justify-content: space-between">

              <div style="width: 88%">
                <span class="fs08-rem">{{'CAMPAIGN_PAGE.TRIGGERED_AT' | i18nTranslate}}</span><span style="color: red">*</span>
                <div style="width: 44%" class="formDiv" [ngClass]="{active:startTime.value==null||startTime.length==0}">
                  <input style="margin-left: 5%" class="formInput fs08-rem" [formControl]="startTime" [format]="serviceCountry === 'US' ? 12 : 24"
                    [ngxTimepicker]="picker1T" [max]="startMaxTime" [min]="startMinTime" readonly />
                  <ngx-material-timepicker #picker1T (closed)="startTimeChange()"> </ngx-material-timepicker>
                </div>
                <div *ngIf="(startTime.value==null||startTime.length==0)"
                  style="color: red;font-size: 11px;margin-top: 5px">{{'CAMPAIGN_PAGE.SELECT_TIME' | i18nTranslate}}</div>

              </div>
            </div>
          </div>
          <div *ngIf="(dateRadioOption == 1 && !disableDateRadioSelection && checkCelebration(celebration,false)) || ( !isCelebration && dateRadioOption == 1)" style="margin-top: 10px" >
            <div class="weekday-config">
              <span class="fs08-rem"> {{'CAMPAIGN_PAGE.EVERY_DAY_WEEK' | i18nTranslate}}</span>
              <mat-slide-toggle color="accent" [(ngModel)]="everyDaySliderValue"></mat-slide-toggle>
            </div>
            <mat-chip-list *ngIf="!everyDaySliderValue" placeholder="Select a day" class="font-class fs08-rem"
              multiple="true">
              <mat-chip color="accent" #c="matChip" *ngFor="let day of weekDaysChips"
                class="font-class fs08-rem cursor-pointer weekdays-chip" [value]="day.name"
                (click)="toggleSelection(day)" [selected]="day.selected">
                {{ day.name }}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div *ngIf="(celebrationType === 'Custom' && isCelebration && dateRadioOption === 1 && checkCelebration(celebration,false))" style="margin-top: 5px;">
            <span class="fs08-rem">{{'CAMPAIGN_PAGE.DAYS_PRIOR' | i18nTranslate}}</span><span style="color: red">*</span>
            <div style="margin-top: 5px;">
              <input type="number" [(ngModel)]="daysPriorToCelebration" class="qb-formInput-exp-window">
            </div>
            <em style="font-size: 10px; color: red;" *ngIf = "errorDiff.length > 0" [ngClass]="
              checkDiffForCustom() ? 'qb-d-flex' : 'qb-none'
            ">{{errorDiff | i18nDynamicTranslate : 'POS_PAGE'}}</em>
          </div>
          <div style="margin-top: 20px;" *ngIf="appointmentType === 'SCHEDULED' && checkCelebration(celebration,true)">
            <span class="fs08-rem" >Notifying Before Appointments</span><span style="color: red">*</span>
            <div style="display: flex; gap: 20px;">
              <div style="width: 25%" *ngIf="dateRadioOption === 1">
                <span style="font-size: 0.7rem; color: #959595">{{'CAMPAIGN_PAGE.DAYS' | i18nTranslate}}</span>
                <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                    active: daysControl.errors
                      ? (daysControl.errors.required && submitted) ||
                        (!daysControl.errors.required &&
                          daysControl.errors.minVal &&
                          submitted)
                      : (daysControl.value == 0 &&
                          minutesControl.value == 0 &&
                          hoursControl.value === 0 &&
                          submitted) ||
                        false
                  }">
                  <input type="number" min="0" style="width: 95%" class="formInput fs08-rem"
                  [(ngModel)]="daysControlAppointment" />
                </div>
              </div>
              <div style="width: 25%">
                <span style="font-size: 0.7rem; color: #959595">{{'CAMPAIGN_PAGE.HOURS' | i18nTranslate}}</span>
                <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                    active: hoursControl.errors
                      ? (hoursControl.errors.required &&
                          hoursControl.touched &&
                          submitted) ||
                        (hoursControl.errors.required && submitted)
                      : (daysControl.value == 0 &&
                          minutesControl.value == 0 &&
                          hoursControl.value === 0 &&
                          submitted) ||
                        false
                  }">
                  <mat-select style="width: 95%" [(ngModel)]="hoursControlAppointment" class="font-class fs08-rem">
                    <mat-option *ngFor="let hour of hours" [value]="hour" class="font-class fs08-rem">{{ hour }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <!-- <div style="width: 25%">
                <span class="fs08-rem" style="color: #959595">{{'CAMPAIGN_PAGE.MINUTES' | i18nTranslate}}</span>
                <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                    active: minutesControl.errors
                      ? (minutesControl.errors.required &&
                          minutesControl.touched &&
                          submitted) ||
                        (minutesControl.errors.required && submitted)
                      : (daysControl.value == 0 &&
                          minutesControl.value == 0 &&
                          hoursControl.value === 0 &&
                          submitted) ||
                        false
                  }">
                  <mat-select style="width: 95%" [(ngModel)]="minutesControlAppointment" class="font-class fs08-rem">
                    <mat-option *ngFor="let minute of minutes" [value]="minute" class="font-class fs08-rem">
                      {{ minute }}
                    </mat-option>
                  </mat-select>
                </div>
              </div> -->
            </div>
            <em style="font-size: 10px; color: red;" *ngIf = "errorDiff.length > 0" [ngClass]="
              checkDiffForAppointment() ? 'qb-d-flex' : 'qb-none'
            ">{{errorDiff | i18nDynamicTranslate : 'POS_PAGE'}}</em>
          </div>
          <span *ngIf="celebration == 'tier_upgrade'" class="fs08-rem">Tier Upgrade</span>
          <div *ngIf="celebration == 'tier_upgrade'" style="display: flex; justify-content: space-between">
            <div style="width: 45%">
              <span class="fs08-rem">{{'CAMPAIGN_PAGE.FROM' | i18nTranslate}}</span><span style="color: red">*</span>
              <div class="tierDiv">
                <div [matMenuTriggerFor]="fromTierSelect" style="text-transform: capitalize;" class="border-country cursor-pointer">
                  <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; height: 40px;">
                    <div class="qb-d-flex qb-ai-center">
                      {{fromTier  | limitTo: 6}} (level: {{fromTierLevel}})
                    </div>
                    <div class="qb-d-flex qb-ai-center">
                      <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
                      >keyboard_arrow_down</mat-icon
                    >
                    </div>
                  </div>
                </div>
                <mat-menu #fromTierSelect class="qb-channel-dialog">
                  <div *ngFor="let tier of customerTierList">
                    <div
                    mat-menu-item
                    class="qb-channel-dialog-content-country"
                    (click)="changeFromTier(tier.name, tier.level)"
                    *ngIf="customerTierList[1].level >= tier.level"
                  >
                  <span>{{tier.name  | limitTo: 18}} (level: {{tier.level}})</span>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </div>
            <div style="width: 45%">
              <span class="fs08-rem">{{'CAMPAIGN_PAGE.TO' | i18nTranslate}}</span><span style="color: red">*</span>
              <div class="tierDiv">
                <div [matMenuTriggerFor]="toTierSelect" style="text-transform: capitalize;" class="border-country cursor-pointer">
                  <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; height: 40px;">
                    <div class="qb-d-flex qb-ai-center">
                      {{toTier | limitTo: 6}} (level: {{toTierLevel}})
                    </div>
                    <div class="qb-d-flex qb-ai-center">
                      <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
                      >keyboard_arrow_down</mat-icon
                    >
                    </div>
                  </div>
                </div>
                <mat-menu #toTierSelect class="qb-channel-dialog">
                  <div *ngFor="let tier of customerTierList ">
                    <div
                    mat-menu-item
                    class="qb-channel-dialog-content-country"
                    (click)="changeToTier(tier.name, tier.level)"
                    *ngIf="customerTierList[0].level < tier.level"
                  >
                  <span>{{tier.name  | limitTo: 18}} (level: {{tier.level}})</span>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="trigger-ac" [ngClass]="{'red-border':!leForAdditionalTriggerConfig}">
            <div class="qb-error-text" *ngIf="!leForAdditionalTriggerConfig">Please enter valid values</div>
            <app-trigger-additional-config [additionalConfigs]="additionalTriggerConfigs" [showAc]="showAdditionalTriggerConfig" 
              [preSelectedLogicalExpressions]="preSelectedLogicalExpressions" [apiProductList]="apiProductList" [storeList]="storeList" (cancelEvent)="leForAdditionalTriggerConfig=true" #triggerAdditionalConfig></app-trigger-additional-config>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div *ngIf="nodeType == 'CHANNEL' && channelType == 'EMAIL'" style="display: flex; flex-direction: column">
        <div class="create-template-container">
          <button class="primary-button wd-100" (click)="createEmailTemplate('null')">
            <mat-icon class="mat-icon notranslate cursor-pointer create-offer-add material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font"> add_circle </mat-icon>
            {{'CAMPAIGN_PAGE.CREATE_NEW_TEMPLATE' | i18nTranslate}}
          </button>
        </div>
        <div class="template-search-container">
          <span style="white-space: nowrap">{{'CAMPAIGN_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}</span>
          <span style="display: flex; flex-direction: row; gap: 2px;">
            <div class="formDiv search-class-bk">
              <input #choose1 class="formInput fs08-rem" placeholder="Search Template"
                (input)="onSearchChange($event.target.value)" required />
              <span class="material-icons-outlined icons-primary node-header-icon" *ngIf="choose1.value.length==0">
                search
              </span>
              <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="choose1.value.length>0" (click)="choose1.value='';onSearchChange(choose1.value)">
                close
              </span>
            </div>
            <span
              class="material-icons-outlined icons-primary"
              style="cursor: pointer; margin-top: 5px;"
              [matTooltipShowDelay]="500"
              [matTooltip]="'COMMON_PAGE.FILTERS' | i18nTranslate"
              [matMenuTriggerFor]="filterSelect" 
            >
              filter_alt
            </span>
            <mat-menu #filterSelect="matMenu" class="qb-channel-dialog">
              <div style="border-bottom: 1px solid #edf0f2; padding: 10px;" (click)="$event.stopPropagation()">
                <p>
                  <mat-checkbox
                  [checked]="filters.indexOf('ALL') != -1"
                  (click)="$event.stopPropagation()"
                    value="ALL"
                    (change)="addFilter('ALL')"
                  >ALL</mat-checkbox>
                </p>
                <p>
                  <mat-checkbox
                  [checked]="filters.indexOf('STANDARD') != -1"
                  (click)="$event.stopPropagation()"
                    value="STANDARD"
                    (change)="addFilter('STANDARD')"
                  >PRESET</mat-checkbox>
                </p>
                <p>
                  <mat-checkbox
                  [checked]="filters.indexOf('CUSTOM') != -1"
                  (click)="$event.stopPropagation()"
                    value="CUSTOM"
                    (change)="addFilter('CUSTOM')"
                  >CUSTOM</mat-checkbox>
                </p>
              </div>
            </mat-menu>
          </span>
        </div>
        <div style="height: calc(100% - 100px)">
          <div *ngIf="filteredData.length > 0" class="choose-template">
            <div *ngFor="let template of filteredData; let i = index" class="template-card" (click)="
                selectTemplate(
                  'CHANNEL',
                  template['id'],
                  template['layoutName'],
                  ''
                )
              ">
              <div class="card-selection" [ngClass]="{
                  active: selectedEmailTemplateId == template['id']
                }">
                <mat-icon class="card-selection-icon">done</mat-icon>
              </div>
              <div matTooltip="Clone" class="card-clone" (click)="cloneEmail(template, $event)">
                <span class="material-icons-outlined icons-primary qb-card-clone-icon">
                  content_copy
                </span>
              </div>
              <div style="margin: 8px; height: 130px">
                <img *ngIf="template['snapshotUrl']" style="height: 100%; width: 100%" class="image-class"
                  [src]="template['snapshotUrl']" alt="No Preview Available" />
                <div *ngIf="!template['snapshotUrl'] && templateLoader" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                  ">
                  <mat-spinner diameter="45"></mat-spinner>
                </div>
              </div>
              <div class="template-name-div">
                <div style="display: flex; overflow: hidden">
                  <span class="template-name-span" [matTooltip]="template['layoutName']">{{ template["layoutName"]
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="filteredData.length == 0">
            <div class="empty-template">
              <span> {{'CAMPAIGN_PAGE.NO_TEMPL_DISPLAY' | i18nTranslate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="nodeType == 'CHANNEL' && channelType == 'SMS'"
        style="display: flex; flex-direction: column; margin: 0px 0px 20px 0px">
        <mat-divider></mat-divider>
        <div>
          <span class="fs08-rem" style="margin: 10px">{{'CAMPAIGN_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}</span>
          <div style="margin: 10px" class="formDiv search-class-bk">
            <input #choose2 class="formInput fs08-rem" placeholder="Search Template"
              (input)="onSearchChange($event.target.value)" required />
            <mat-icon class="search-icon-templates" *ngIf="choose2.value.length==0">search</mat-icon>
            <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="choose2.value.length>0" (click)="choose2.value='';onSearchChange(choose2.value)">
              close
            </span>
          </div>
          <div *ngIf="filteredData.length > 0" class="choose-template">
            <div
            class="qb-show-warning"
          >
            <div class="qb-width7">
              <span
                class="qb-i-div-schedule-offer2"
                popoverClass="qb-my-custom-class"
                placement="bottom"
              >
                i
              </span>
            </div>
            <div class="qb-width93">
              <span class="qb-sms-caution"
                >Telecom regulatory authorities in your region have prohibited
                sending promo SMSes between 08:55 PM to 10 AM local time.
              </span>
            </div>
          </div>
            <div *ngFor="
                let layout of filteredData | labelPipe: filter.label:'name';
                let i = index;
                let l = count
              " class="template-card" (click)="
                selectTemplate('SMS', layout['id'], layout['name'], layout)
              ">
              <div class="card-selection" [ngClass]="{
                active: selectedSmsTemplateId == layout['id']
              }">
              <mat-icon class="card-selection-icon">done</mat-icon>
            </div>
              <div style="display: flex; flex-direction: column; height: 100%">
                <div class="template-image-container" style="height: 115px;">
                  <div class="phone-image">
                    <div class="preview-text-content">
                      <span style="white-space: pre-line; word-break: break-word">{{ layout["layoutData"] }}</span>
                    </div>
                  </div>
                </div>
                <div style="height: 55px">
                  <div>
                    <div class="templateHeadText-sms" [matTooltip]="layout['name']">
                      {{ layout["name"] | limitTo: 20}}
                    </div>
                  </div>
                  <!-- <div class="Digits">
                    <span style="color: #aeaeae">{{'CAMPAIGN_PAGE.LAST_EDITED_ON' | i18nTranslate}}</span>
                    <span style="font-weight: 600">
                      {{ layout["lastModifiedTime"] | date }}</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="filteredData.length == 0">
            <div class="empty-template">
              <span> {{'CAMPAIGN_PAGE.NO_TEMPL_DISPLAY' | i18nTranslate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="nodeType == 'CHANNEL' && channelType == 'WHATSAPP'"
      style="display: flex; flex-direction: column; margin: 0px 0px 20px 0px">
      <mat-divider></mat-divider>
      <div>
        <span class="fs08-rem" style="margin: 10px">{{'CAMPAIGN_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}</span>
        <div style="margin: 10px" class="formDiv search-class-bk">
          <input #choose2 class="formInput fs08-rem" placeholder="Search Template"
            (input)="onSearchChange($event.target.value)" required />
          <mat-icon class="search-icon-templates" *ngIf="choose2.value.length==0">search</mat-icon>
          <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="choose2.value.length>0" (click)="choose2.value='';onSearchChange(choose2.value)">
            close
          </span>
        </div>
        <div *ngIf="filteredData.length > 0" class="choose-template">
          <div *ngFor="
              let layout of filteredData | labelPipe: filter.label:'name';
              let i = index;
              let l = count
            " class="template-card-whatsapp" (click)="
              selectTemplate('WHATSAPP', layout['id'], layout['name'], layout, undefined, undefined, layout['templateSource'])
            ">
            <div class="card-selection" [ngClass]="{
              active: selectedWhatsappTemplateName == layout['name']
            }">
            <mat-icon class="card-selection-icon">done</mat-icon>
          </div>
            <div style="display: flex; flex-direction: column; height: 100%">
              <div class="template-image-container">
                <div class="phone-image-whatsapp">
                  <div class="phone-background-whatsapp-contact-name">
                    <div class="qb-d-flex ">
                      <span style="color: black; font-size: 10px;">{{shopName | limitTo: 10}}</span>
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/verified_logo.svg" style="margin-top: 3px; margin-left: 2.5px;" width="10px" height="10px">
                    </div>
                  </div>
                  <div class="preview-text-content-whatsapp">
                    <div *ngIf="getLayout(layout,'header') === 'image'">
                      <img
                      *ngIf="getLayout(layout,'headerImage')"
                      [src]="getLayout(layout,'headerImage')"
                      alt=""
                      style="
                        height: 80px;
                        width: 80px;
                        object-fit: contain;
                      "
                    />
                    </div>
                    <div *ngIf="getLayout(layout,'header') === 'document'">
                      <embed [src]="getLayout(layout,'headerDocument')" type="application/pdf"  style="
                    height: 80px;
                    width: 80px;
                    object-fit: contain;
                  ">
                    </div>
                    <div *ngIf="getLayout(layout,'header') === 'text'">
                      <span style="font-size: 10px; font-weight: 600; white-space: pre-line;
                      word-break: break-word;">{{getLayout(layout,'headerText')}}</span>
                    </div>
                    <div *ngIf="getLayout(layout,'header') === 'video'">
                      <div *ngIf="getLayout(layout,'headerVideo')" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
                        <div class="img_wrp">
                          <video
                        alt=""
                        style="
                          height: 80px;
                          width: 80px;
                          object-fit: contain;
                        ">
                        <source [src]="getLayout(layout,'headerVideo')" />  
                      </video>
                      <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                        </div>
                      </div>
                    </div>
                    <span style="white-space: pre-line; word-break: break-word">{{
                      getLayout(layout,'body')
                    }}</span>
                    <span *ngIf="getLayout(layout,'footer')" style="color: #636466; font-size: 6px;">{{getLayout(layout,'footer')}}</span>
                  </div>
                  <div class="qb-d-flex">
                    <div class="qb-button-text-whatsapp">
                      STOP
                    </div>
                    <div class="qb-button-text-whatsapp" style="margin-left: 3px;">
                      SHOP NOW
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 55px">
                <div>
                  <div class="templateHeadText" [matTooltip]="layout['name']">
                    {{ layout["name"] }}
                  </div>
                </div>
                <!-- <div class="Digits">
                  <span style="color: #aeaeae">Last edited on</span>
                  <span style="font-weight: 600">
                    {{ layout["lastModifiedTime"] | date }}</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="filteredData.length == 0">
          <div class="empty-template">
            <span> {{'CAMPAIGN_PAGE.NO_TEMPL_DISPLAY' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
      </div>
      <div *ngIf="nodeType == 'OFFER'">
        <div class="create-new-offer" (click)="openOfferCreateDiaglog()">
          <mat-icon class="cursor-pointer create-offer-add">
          add_circle
          </mat-icon>
          Create New Offer
        </div>
        <div style="margin: 0px 16px" class="formDiv search-class-bk margin-bottom-8">
          <input #choose3 class="formInput fs08-rem" placeholder="Search Offers"
            (input)="onSearchChange($event.target.value)" required />
          <span class="material-icons-outlined icons-primary node-header-icon" *ngIf="choose3.value.length==0">
            search
          </span>
          <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="choose3.value.length>0" (click)="choose3.value='';onSearchChange(choose3.value)">
            close
          </span>
        </div>
        <!-- <div class="select-channel-format">
          <span class="fs08-rem">Select Channel Format</span>
          <mat-checkbox
            class="fs08-rem"
            color="primary"
            [matTooltip]="!(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US') ? 'Coming Soon' : ''"
            [disabled]="!(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US')"
            [checked]="(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US') ? smsCheckbox : false"
            (change)="onOfferTypeSelectChange(smsCheckbox, 'SMS')"
            *ngIf="(validScreens | screenConfig: 18)"
            >{{'CAMPAIGN_PAGE.SMS' | i18nTranslate}}
          </mat-checkbox>
          <mat-checkbox class="fs08-rem" color="primary" [checked]="emailCheckbox"
            (change)="onOfferTypeSelectChange(emailCheckbox, 'EMAIL')">
            {{'CAMPAIGN_PAGE.EMAIL' | i18nTranslate}}
          </mat-checkbox>
          <mat-checkbox class="fs08-rem" color="primary" [checked]="whatsappCheckBox"
          (change)="onOfferTypeSelectChange(whatsappCheckbox, 'WHATSAPP')">
          {{'CAMPAIGN_PAGE.WHATSAPP' | i18nTranslate}}
        </mat-checkbox>
        </div> -->
        <div
          style="display: flex; margin: 0px 16px"
          *ngIf="((countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US') ? smsCheckbox : false)  && ((validScreens | screenConfig: 18) ? smsCheckbox : false)"
        >
          <div>
            <span class="i-div2" popoverClass="my-custom-class" placement="right">
              i
            </span>
          </div>
          <div>
            <span class="sms-caution">{{'CAMPAIGN_PAGE.SMS_REGULATIONDESP' | i18nTranslate }}
            </span>
          </div>
        </div>
        <div class="seperator-line"></div>
        <div *ngIf="filteredData.length > 0" class="choose-template-column">
          <div *ngFor="let layout of filteredData" class="each-template">
            <div class="card-selection" style="position: static; cursor: pointer"
              [ngClass]="{ active: selectedOfferTemplateId == layout['id'] }" (click)="
                selectTemplate('OFFER', layout['id'], layout['offerName'], '')
              " *ngIf="layout['isNoneBarrierDetected']||layout['isBuyXGetYDetected']">
              <mat-icon class="card-selection-icon">done</mat-icon>
            </div>
            <div class="template-card-offer" (click)="
                selectTemplate('OFFER', layout['id'], layout['offerName'], '')
              " *ngIf="layout['isNoneBarrierDetected']||layout['isBuyXGetYDetected']">
              <div *ngIf="layout != null" style="display: flex">
                <div style="
                    display: flex;
                    align-items: center;
                    width: calc(100% - 40px);
                  " class="fs08-rem">
                  <div style="width: 100%; display: flex; gap: 5px;margin-right: 5px;">
                    <span class="template-name-span" [matTooltip]="layout['offerName']">{{
                      layout.offerName}}</span>
                      <div>
                        <span
                    [matTooltip]="getTooltipOffer('SMS')"
                    *ngIf="
                      checkChannel('SMS', layout['channelList'], layout['channel'])
                    "
                    >            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/sms.svg" width="15px" height="15px">
                    </span
                  >
                  <span
                    [matTooltip]="getTooltipOffer('Email')"
                    *ngIf="
                     checkChannel('EMAIL', layout['channelList'], layout['channel'])
                    "
                    >
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/email.svg" width="15px" height="15px">
                    </span
                  >
                  <span
                    [matTooltip]="getTooltipOffer('WhatsApp')"
                    *ngIf="
                       checkChannel('WHATSAPP', layout['channelList'], layout['channel'])
                    "
                    >
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/whatsapp.svg" width="15px" height="15px">
                    </span
                  >
                      </div>
                  </div>
                </div>
                <div style="display: flex; align-items: center; width: 40px" class="fs08-rem">
                  <span style="text-decoration: underline" class="campaign-color-class"
                    (click)="previewOfferSummary(layout.id)">{{'CAMPAIGN_PAGE.PREVIEW' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="filteredData.length == 0">
          <div class="empty-template">
            <span> {{'CAMPAIGN_PAGE.NO_TEMPL_DISPLAY' | i18nTranslate}}</span>
          </div>
        </div>
      </div>

      <div *ngIf="nodeType == 'COMARKETING_OFFER'">
        <div style="margin: 0px 16px" class="formDiv search-class-bk">
          <input #choose4 class="formInput fs08-rem" placeholder="Search Offers"
            (input)="onSearchChange($event.target.value)" required />
          <span class="material-icons-outlined icons-primary node-header-icon" *ngIf="choose4.value.length==0">
            search
          </span>
          <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="choose4.value.length>0" (click)="choose4.value='';onSearchChange(choose4.value)">
            close
          </span>
        </div>
        <!-- <div class="select-channel-format">
          <span class="fs08-rem">Select Channel</span>
          <mat-checkbox
            class="fs08-rem"
            color="primary"
            [matTooltip]="!(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US') ? 'Coming Soon' : ''"
            [disabled]="!(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US')"
            [checked]="(countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US') ? smsCheckbox : false"
            (change)="onOfferTypeSelectChange(smsCheckbox, 'SMS')"
            *ngIf="(validScreens | screenConfig: 18)"
            >SMS
          </mat-checkbox>
          <mat-checkbox class="fs08-rem" color="primary" [checked]="emailCheckbox"
            (change)="onOfferTypeSelectChange(emailCheckbox, 'EMAIL')">
            Email
          </mat-checkbox>
          <mat-checkbox class="fs08-rem" color="primary" [checked]="whatsappCheckBox"
          (change)="onOfferTypeSelectChange(whatsappCheckbox, 'WHATSAPP')">
          WhatsApp
        </mat-checkbox>
        </div> -->
        <!-- <div
          style="display: flex; margin: 0px 16px"
          *ngIf="((countryCode === 'IN'||countryCode === 'CA'||countryCode === 'US') ? smsCheckbox : false)  && ((validScreens | screenConfig: 18) ? smsCheckbox : false)"
        >
          <div>
            <span class="i-div2" popoverClass="my-custom-class" placement="right">
              i
            </span>
          </div>
          <div>
            <span class="sms-caution">SMS regulations don't allow sending promotional messages
              between 08:55PM - 10AM local time. We will queue your message and send it
              in the next allowed window.
            </span>
          </div>
        </div> -->
        <!-- <div class="seperator-line"></div> -->
        <div *ngIf="filteredData.length > 0" class="choose-template-column">
          <div *ngFor="let layout of filteredData" class="each-template">
            <div class="card-selection" style="position: static; cursor: pointer"
              [ngClass]="{ active: selectedCoMarketingTemplateId.indexOf(layout['id']) !== -1 }" (click)="
              selectTemplateComarketing(layout['id'], layout)
              ">
              <mat-icon class="card-selection-icon">done</mat-icon>
            </div>
            <div class="template-card-offer" (click)="
            selectTemplateComarketing(layout['id'], layout)
              ">
              <div *ngIf="layout != null" style="display: flex">
                <div style="
                    display: flex;
                    align-items: center;
                    width: calc(100% - 40px);
                  " class="fs08-rem">
                  <span class="template-name-span" style="width: 100%" [matTooltip]="layout['offerName']">{{
                    layout.offerName }}</span>
                </div>
                <div style="display: flex; align-items: center; width: 40px" class="fs08-rem">
                  <span style="text-decoration: underline" class="campaign-color-class"
                    (click)="previewComarketingOfferSummary(layout)">{{'CAMPAIGN_PAGE.PREVIEW' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="filteredData.length == 0">
          <div class="empty-template">
            <span> {{'CAMPAIGN_PAGE.NO_TEMPL_DISPLAY' | i18nTranslate}}</span>
          </div>
        </div>
      </div>


      <div *ngIf="nodeType == 'SURVEY' && !channelSelect">
        <div style="display: flex" style="
            margin: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <span class="fs08-rem" style="white-space: nowrap">{{'CAMPAIGN_PAGE.CHOOSE_SURVEY' | i18nTranslate}}</span>
          <div style="width: 100%; margin-left: 10px" class="formDiv search-class-bk">
            <input #choose4 class="formInput fs08-rem" placeholder="Search Template"
              (input)="onSearchChange($event.target.value)" required />
            <span class="material-icons-outlined icons-primary node-header-icon" *ngIf="choose4.value.length==0">
              search
            </span>
            <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="choose4.value.length>0" (click)="choose4.value='';onSearchChange(choose4.value)">
              close
            </span>
          </div>
        </div>
        <!-- <div *ngIf="filteredData.length > 0" class="choose-template">
                    <div style=" margin: 2%; margin-right: 1%;" class="template-card" *ngFor=" let layout of filteredData | labelPipe: filter.label: 'layoutName';let i=index;let l=count;" (click)="selectTemplate('SURVEY',layout['id'],layout['layoutName'],'')" [style.border]="selectedSurveyTemplateId == layout['id'] ? '3px solid #4abb5975' : '3px solid #FFF'">
                        <div style=" display: flex;flex-direction: column;height:150px; ">
                            <div style=" height: 70%; border-bottom: 1px solid #2222; cursor: pointer; ">
                                <img [src]="layout['snapshotUrl']" style="border-radius: 5px 5px 0 0;" width=" 100% " height=" 100% " alt=" Email-Template ">
                            </div>
                            <div style="padding-top: 2%;height: 30%; ">
                                <div style="display: flex;justify-content: space-between; margin: 0px 0px 0px 15px; ">
                                    <div class="templateHeadText">
                                        {{(layout['layoutName'] | limitTo: 18) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

        <div *ngIf="filteredData.length > 0" class="choose-template-column">
          <div *ngFor="let layout of filteredData" class="each-template">
            <div class="card-selection" style="position: static; cursor: pointer"
              [ngClass]="{ active: selectedSurveyTemplateId == layout['id'] }" (click)="
                selectTemplate(
                  'SURVEY',
                  layout['id'],
                  layout['layoutName'],
                  '',
                  layout['title']
                )
              ">
              <mat-icon class="card-selection-icon">done</mat-icon>
            </div>
            <div class="template-card-offer" (click)="
                selectTemplate(
                  'SURVEY',
                  layout['id'],
                  layout['layoutName'],
                  '',
                  layout['title']
                )
              ">
              <div *ngIf="layout != null" style="display: flex">
                <div style="
                    display: flex;
                    align-items: center;
                    width: calc(100% - 40px);
                  " class="fs08-rem">
                  <span [matTooltip]="layout['layoutName']" class="template-name-span" style="width: 100%">
                    {{ layout["layoutName"] }}</span>
                </div>
                <div style="display: flex; align-items: center" class="fs08-rem">
                  <span style="text-decoration: underline" class="campaign-color-class"
                    (click)="previewSurvey(layout['id'])">{{'CAMPAIGN_PAGE.PREVIEW' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="filteredData.length == 0">
          <div class="empty-template">
            <span> {{'CAMPAIGN_PAGE.NO_TEMPL_DISPLAY' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="channelSelect">
        <app-campaign-channel-select #channelSelectChild [id]="selectedSurveyTemplateId" (messageEvent)="recieveChannelSelect($event)"></app-campaign-channel-select>
      </div>
      <div *ngIf="nodeType == 'LOYALTY'">
        <div *ngIf="!loyaltyProgramConfig.buttonType">
          <div class="choose-tier-panel">
            <h5>Select a loyalty rule</h5>
            <p class="fs08-rem node-details margin-left-0">Rules that are marked as campaign only and that are not used in any other campaign can be selected here.</p>
            <div *ngFor="let rule of loyaltyProgramConfig.loyaltyRules" class="each-template" [matTooltip]="isLoyaltyRuleNotSelectable(rule)?'NOT SELECTABLE':''">
              <div class="card-selection" style="position: static; cursor: pointer"
                [ngClass]="{ 'active': loyaltyProgramConfig.selectedRule?.ruleName == rule.ruleName, 'disabled-rule-style':isLoyaltyRuleNotSelectable(rule) }" (click)="selectLoyaltyRule(rule)">
                <mat-icon class="card-selection-icon">{{isLoyaltyRuleNotSelectable(rule)?'close':'done'}}</mat-icon>
              </div>
              <div class="template-card-offer" (click)="selectLoyaltyRule(rule)">
                <div style="display: flex">
                  <div style="
                      display: flex;
                      align-items: center;
                      width: calc(100% - 40px);
                    " class="fs08-rem">
                    <span class="template-name-span" style="width: 100%" [matTooltip]="rule.ruleName">{{rule.ruleName}}</span>
                  </div>
                  <div style="display: flex; align-items: center; width: 40px" class="fs08-rem">
                    <span style="text-decoration: underline" class="campaign-color-class"
                      (click)="previewLoyaltyRule(rule)">{{'CAMPAIGN_PAGE.PREVIEW' | i18nTranslate}}</span>
                  </div>
                </div>
              </div>
            </div>
              <div>
                <div *ngFor="let rule of loyaltyProgramConfig.loyaltyRules" style="display: flex; align-items: center">
                  
                </div>
                <div *ngIf="loyaltyProgramConfig.loyaltyRules.length == 0">
                  <div class="no-data-style margin-top-12">
                    <span> No campaign only loyalty rule present in loyalty configuration. Please create a campaign only rule.</span>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div *ngIf="loyaltyProgramConfig.buttonType" class="no-loyalty-prg">
          <p class="no-loyalty-heading">{{'CAMPAIGN_PAGE.NO_PROGRAM_ADDED' | i18nTranslate}} !</p>
          <p class="no-loyalty-text" *ngIf="loyaltyProgramConfig.buttonType=='finish'">{{'CAMPAIGN_PAGE.NO_PROG_PARA1' | i18nTranslate}}</p>
          <p class="no-loyalty-text" *ngIf="loyaltyProgramConfig.buttonType=='create'">{{'CAMPAIGN_PAGE.NO_PROG_PARA2' | i18nTranslate}}</p>
          <button class="primary-button no-loyalty-buttons" (click)="redirectToLoyalty()" >
            <span *ngIf="loyaltyProgramConfig.buttonType=='finish'">
              {{'CAMPAIGN_PAGE.NOPROG_OPT1' | i18nTranslate}}
            </span>
            <span *ngIf="loyaltyProgramConfig.buttonType=='create'">
              {{'CAMPAIGN_PAGE.NOPROG_OPT2' | i18nTranslate}}
            </span>
          </button>
          <!-- <button class="primary-button border-none">
            <span>
              Use recommended loyalty settings
            </span>
          </button> -->
        </div>

      </div>
    </div>
    <div class="nav-action-button">
      <span class="secondary-button-border" (click)="saveConfigOnLeftPanel(false)">Cancel</span>
      <span [ngClass]="
          disableSaveButton() || disableSaveConfigOnLeftPanel ? 'primary-button-disabled' : 'primary-button'
        " (click)="saveConfigOnLeftPanel(true)">{{'CAMPAIGN_PAGE.SAVE' | i18nTranslate}}</span>
    </div>
  </div>
  <div *ngIf="showSecondNav" class="first-nav wd-50">
    <div class="first-nav-title fs08-rem">
      <div>
        <span style="font-size: 1.14rem">{{ secondNavTitle | i18nDynamicTranslate: 'POS_PAGE'}}</span>
      </div>
      <div>
        <mat-icon class="cursor-pointer close-icon" (click)="saveNodeConfig(false)">
          close
        </mat-icon>
      </div>
    </div>
    <div *ngIf="nodeType == 'TRIGGER'" style="height: calc(100% - 100px); background: white; overflow: auto">
      <div *ngIf="
          triggerGroupv2 &&
            (!triggerGroupv2.config ||
              (triggerGroupv2.config.conditionGroups &&
                triggerGroupv2.config.conditionGroups.length == 0));
          else other_content
        " class="second-nav-empty-trigger">
        <div style="display: flex; justify-content: center">
          <img style="height: 120px; width: 120px"
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/campaign-operations/add+condition+1.svg"
            alt="" />
        </div>
        <span class="node-details trigger-config-text">
          {{'CAMPAIGN_PAGE.TRIGGER_PARAGRAH' | i18nTranslate}}</span>
        <div class="action-items">
          <button [ngClass]="showFirstNav ? 'disable-base-color' : 'base-color'" [disabled]="showFirstNav"
            class="wd-100" mat-raised-button (click)="configTriggerNode(-1, -1, false)"
            data-button-name="Add New Condition">
            + {{'CAMPAIGN_PAGE.ADD_NEW_CONDITION' | i18nTranslate}}
          </button>
        </div>
      </div>
      <ng-template #other_content>
        <div *ngFor=" 
            let data of triggerGroupv2.config.conditionGroups;
            let j = index;
            trackBy: trackItem;
            let jlast = last
          " style="
            background: #f8f8f8;
            margin: 0px 8px 0px 8px;
            padding: 12px 0px 0px 0px;
          ">
          <div style="
              display: flex;
              justify-content: space-between;
              margin: 0px 8px 0px 40px;
            ">
            <div>
              <div *ngIf="data.conditions.length > 1" class="cursor-pointer and-or-operator-tabs">
                <div [ngClass]="{
                    active: data.conditionsRelation == 'OR'
                  }" style="border-radius: 4px 0px 0px 4px" class="and-or-alignment"
                  (click)="changeChildOperator(j, 'OR')">
                  <small>{{'CAMPAIGN_PAGE.OR' | i18nTranslate}}</small>
                </div>
                <div [ngClass]="{
                    active: data.conditionsRelation == 'AND'
                  }" style="border-radius: 0px 4px 4px 0px" class="and-or-alignment"
                  (click)="changeChildOperator(j, 'AND')">
                  <small>{{'CAMPAIGN_PAGE.AND' | i18nTranslate}}</small>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center">
              <mat-icon matTooltip="{{ getToolTip() }}" [ngClass]="{
                  inactive: disableAddingNewTriggerConditions()
                }" class="cursor-pointer campaign-condition-trigger-add-icon"
                (click)="configTriggerNode(j, -1, false)">
                add_circle </mat-icon><span class="fs08-rem span-center">{{'CAMPAIGN_PAGE.ADD_CONDITION' | i18nTranslate}}</span>
            </div>
          </div>
          <div *ngFor="
              let child of data.conditions;
              let i = index;
              let last = last
            " class="fs08-rem">
            <div style="display: flex; margin: 0px 8px 0px 40px">
              <div class="trigger-data">
                <div class="trigger-data-fields">
                  <div style="
                      display: flex;
                      padding: 8px 6px;
                      justify-content: space-between;
                      align-items: center;
                    ">
                    <span style="font-size: 0.85rem">{{'CAMPAIGN_PAGE.CONDITION' | i18nTranslate}}</span>
                    <div class="icon-containers">
                      <span matTooltip="Edit" (click)="editCondition(j, i)"
                        class="material-icons-outlined icons-primary cursor-pointer">
                        edit
                      </span>
                      <span matTooltip="Delete" *ngIf="triggerGroupv2.config.conditionGroups.length>1 || data.conditions.length>1" (click)="deleteCondition(j, i)"
                        class="material-icons-outlined icons-primary cursor-pointer">
                        delete
                      </span>
                    </div>
                  </div>
                  <div class="seperator-line"></div>
                  <div style="display: flex; padding: 6px 8px; cursor: pointer" matTooltip="Click to edit" (click)="editCondition(j, i)">
                    <div>
                      <span class="
                          material-symbols-outlined
                          icons-primary
                          node-header-icon
                        ">
                        {{ getImage(child.rules.EVENT.ruleEvent) }}
                      </span>
                    </div>
                    <div *ngIf="child.rules.EVENT.ruleEvent != 'time_event'" class="trigger-rules">
                      <span> <span *ngIf="child.rules.EVENT.ruleEvent != 'lead_created'">
                        {{'CAMPAIGN_PAGE.CUSTOMER' | i18nTranslate}}
                      </span>&nbsp;
                        <b>{{ getEventName(child) }}</b>
                      </span>
                      <span *ngIf="child.rules.DATE.operator == 'BETWEEN'">{{'CAMPAIGN_PAGE.FROM' | i18nTranslate}}
                        <b>
                          {{
                          child.rules.DATE.ruleDates[0]
                          | toDateObj
                          | date: getDateFormat("MMM/dd/yyyy")
                          }}
                          to
                          {{
                          child.rules.DATE.ruleDates[1]
                          | toDateObj
                          | date: getDateFormat("MMM/dd/yyyy")
                          }}</b></span>
                      <span *ngIf="child.rules.DATE.operator == 'EQUALS'">{{'CAMPAIGN_PAGE.ON' | i18nTranslate}}
                        <b>{{
                          child.rules.DATE.ruleDates[0]
                          | toDateObj
                          | date: getDateFormat("MMM/dd/yyyy")
                          }}</b></span>
                        <span *ngIf="
                          child.rules.DAYS &&
                          child.rules.DAYS.daysRule.length > 0
                        ">{{'CAMPAIGN_PAGE.ON' | i18nTranslate}} <b>{{ child.rules.DAYS.daysRule }}</b></span>
                      <span *ngIf="
                          !child.rules.DAYS &&
                          child.rules.DATE.operator == 'BETWEEN' && (child.rules.EVENT.ruleEvent !== 'ANNIVERSARY' && child.rules.EVENT.ruleEvent !== 'BIRTHDAY' && child.rules.EVENT.ruleEvent !== 'APPOINTMENT'  && child.rules.EVENT.ruleEvent !== 'MEETING')
                        ">on <b>Any Day</b></span>
                      <span *ngIf="
                      !child.rules.DAYS &&
                      child.rules.DATE.operator == 'BETWEEN' && (child.rules.EVENT.ruleEvent === 'ANNIVERSARY' || child.rules.EVENT.ruleEvent === 'BIRTHDAY')
                    ">
                          <span>{{'CAMPAIGN_PAGE.SCHEDULE_FOR' | i18nTranslate}}</span> &nbsp; <span>{{getCelebrationName(child.rules.CUSTOM?.event, child.rules.CUSTOM?.timeBeforeToSend)}}</span>
                      </span>
                      <span *ngIf="
                      child.rules.CUSTOM?.timeBeforeToSend && ((child.rules.EVENT.ruleEvent === 'APPOINTMENT' || child.rules.EVENT.ruleEvent === 'MEETING'))
                    ">
                          <span>{{'CAMPAIGN_PAGE.SCHEDULE_FOR' | i18nTranslate}}</span> &nbsp; <span style="font-weight: 700;">{{getAppointmentName(child.rules.CUSTOM?.event, child.rules.CUSTOM?.timeBeforeToSend)}}</span>
                      </span>
                      <span *ngIf="
                          child.rules.TIME &&
                          child.rules.TIME.operator == 'EQUALS'
                        ">at <b>{{ child.rules.TIME.ruleTimes[0] | customTime }}</b></span>
                        <span *ngIf="
                        child.rules.TIME &&
                        child.rules.TIME.operator == 'BETWEEN' &&
                        !disableDateRadioSelection && (child.rules.EVENT.ruleEvent !== 'ANNIVERSARY' && child.rules.EVENT.ruleEvent !== 'BIRTHDAY' && child.rules.EVENT.ruleEvent !== 'APPOINTMENT'  && child.rules.EVENT.ruleEvent !== 'MEETING' )
                      "> between
                      <b>{{ child.rules.TIME.ruleTimes[0] | customTime}}-{{
                        child.rules.TIME.ruleTimes[1] | customTime
                        }}</b></span>
                      <span *ngIf="!child.rules.TIME && (child.rules.EVENT.ruleEvent !== 'ANNIVERSARY' && child.rules.EVENT.ruleEvent !== 'BIRTHDAY' && child.rules.EVENT.ruleEvent !== 'APPOINTMENT' && child.rules.EVENT.ruleEvent !== 'MEETING' )">at <b>Any Time</b></span>
                      <span *ngIf="!child.rules.TIME && (child.rules.EVENT.ruleEvent === 'ANNIVERSARY' || child.rules.EVENT.ruleEvent === 'BIRTHDAY' || child.rules.EVENT.ruleEvent === 'APPOINTMENT' || child.rules.EVENT.ruleEvent === 'MEETING')"></span>
                      <div class="le-viewer" *ngIf="child.rules[getEventKey(child)] && child.rules[getEventKey(child)].logicalExpressions && child.rules[getEventKey(child)].logicalExpressions.length >= 1 && child.rules.EVENT.ruleEvent !='nth lifetime order count'"> 
                        <app-logical-expressions-viewer [logicalExpressions]="child.rules[getEventKey(child)].logicalExpressions"></app-logical-expressions-viewer>
                      </div>
                      <div *ngIf="child.rules.EVENT.ruleEvent =='nth lifetime order count'">
                        N value: {{child.rules[getEventKey(child)].logicalExpressions[0].comparisonStatements[0].operand2}}
                      </div>
                    </div>
                    <div *ngIf="child.rules.EVENT.ruleEvent == 'time_event'" class="trigger-rules">
                      <span>
                        <b>{{ getEventName(child) }}</b>
                      </span>

                      <span *ngIf="child.rules.DATE.operator == 'BETWEEN'">from
                        <b>
                          {{
                          child.rules.DATE.ruleDates[0]
                          | toDateObj
                          | date: getDateFormat("MMM/dd/yyyy")
                          }}
                          to
                          {{
                          child.rules.DATE.ruleDates[1]
                          | toDateObj
                          | date: getDateFormat("MMM/dd/yyyy")
                          }}</b></span>
                      <span *ngIf="child.rules.DATE.operator == 'EQUALS'">{{'CAMPAIGN_PAGE.TRIGG_ON' | i18nTranslate}}

                        <b>{{
                          child.rules.DATE.ruleDates[0]
                          | toDateObj
                          | date: getDateFormat("MMM/dd/yyyy")
                          }}</b></span>
                      <span *ngIf="
                          child.rules.TIME &&
                          child.rules.TIME.operator == 'EQUALS'

                        ">{{'CAMPAIGN_PAGE.TRIGG_AT' | i18nTranslate}} <b>{{ child.rules.TIME.ruleTimes[0] | customTime}}</b></span>
                        <span *ngIf="
                        child.rules.TIME &&
                        child.rules.TIME.operator == 'BETWEEN'"> between
                      <b>{{ child.rules.TIME.ruleTimes[0] | customTime}}-{{
                        child.rules.TIME.ruleTimes[1] | customTime
                        }}</b></span>
                         <span *ngIf="
                         child.rules.DAYS &&
                         child.rules.DAYS.daysRule.length > 0
                       ">{{'CAMPAIGN_PAGE.ON' | i18nTranslate}} <b>{{ child.rules.DAYS.daysRule }}</b></span>
                     <span *ngIf="
                         !child.rules.DAYS &&
                         child.rules.DATE.operator == 'BETWEEN' && (child.rules.EVENT.ruleEvent !== 'ANNIVERSARY' && child.rules.EVENT.ruleEvent !== 'BIRTHDAY' && child.rules.EVENT.ruleEvent !== 'APPOINTMENT' && child.rules.EVENT.ruleEvent !== 'MEETING')
                       ">on <b>Any Day</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!last" style="display: flex; position: relative; cursor: pointer">
              <div class="and-operator-background" style="position: absolute; left: 20px"
                *ngIf="data.conditionsRelation == 'AND'">
                <div class="logical-operator-container">
                  <span class="operator-text">{{'CAMPAIGN_PAGE.AND' | i18nTranslate}}</span>
                </div>
              </div>
              <div class="and-operator-background" style="position: absolute; left: 20px"
                *ngIf="data.conditionsRelation == 'OR'">
                <div class="logical-operator-container" style="left: -11px">
                  <span class="operator-text">{{'CAMPAIGN_PAGE.OR' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!jlast" class="and-or-group-connector">
            <div class="cursor-pointer and-or-group-tab">
              <div [ngClass]="{
                  active: data.conditionGroupRelation == 'OR'
                }" style="border-radius: 4px 0px 0px 4px" class="and-or-alignment"
                (click)="changeParentOperator(j, 'OR')">
                <small>{{'CAMPAIGN_PAGE.OR' | i18nTranslate}}</small>
              </div>
              <div [ngClass]="{
                  active: data.conditionGroupRelation == 'AND'
                }" style="border-radius: 0px 4px 4px 0px" class="and-or-alignment"
                (click)="changeParentOperator(j, 'AND')">
                <small>{{'CAMPAIGN_PAGE.AND' | i18nTranslate}}</small>
              </div>
            </div>
            <div class="trigger-group-connector">
              <div class="verticalLine" style="height: 10px"></div>
              <div style="
                  border: 1px solid #edf0f2;
                  border-radius: 2px;
                  padding: 2px 3px;
                ">
                <span class="fs08-rem">{{ data.conditionGroupRelation }}</span>
              </div>
              <div class="verticalLine" style="height: 10px"></div>
            </div>
          </div>
        </div>
        <!-- <div matTooltip="{{ getToolTip() }}" class="add_new_group_div">
          <button
            [ngClass]="
              disableAddingNewTriggerConditions()
                ? 'add_new_group_button_disable'
                : 'add_new_group_button'
            "
            [disabled]="disableAddingNewTriggerConditions()"
            class="fs08-rem"
            (click)="configTriggerNode(-1, -1, false)"
          >
            <span class="add-new-group-icon">+</span
            ><span style="color: black">Add New Group</span>
          </button>
        </div> -->
        <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0px;
          ">
          <mat-icon matTooltip="{{ getToolTip() }}" [ngClass]="{
              inactive: disableAddingNewTriggerConditions()
            }" class="cursor-pointer campaign-condition-trigger-add-icon" (click)="configTriggerNode(-1, -1, false)">
            add_circle </mat-icon><span class="fs08-rem">{{'CAMPAIGN_PAGE.ADD_GROUP' | i18nTranslate}}</span>
        </div>
      </ng-template>
    </div>
    <div *ngIf="type == 'CREATE' || (type == 'CONFIGURE' && nodeType != 'TRIGGER')" style="
        height: calc(100% - 100px);
        background: white;
        overflow-y: auto;
        overflow-x: hidden;
      ">
      <div>
        <mat-radio-group style="display: flex; flex-direction: column" [(ngModel)]="nodeOptionType"
          (change)="radioNodeChange($event)" [disabled]="disableNodeSelection">
          <div id="RESPONSE_EVENT" class="node-type-config-container" [ngStyle]="{
              background:
                nodeOptionType == 'RESPONSE_EVENT' ? '#F8F8F8' : 'white'
            }">
            <mat-radio-button color="primary" [disableRipple]="true" value="RESPONSE_EVENT"
              [checked]="nodeOptionType == 'RESPONSE_EVENT'" [ngClass]="
                disableNodeSelection && nodeOptionType == 'RESPONSE_EVENT'
                  ? 'disable-radio-color'
                  : ''
              ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'RESPONSE_EVENT'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <!-- <mat-icon
                  style="margin-right: 15px; transform: scale(0.8)"
                  [ngStyle]="{
                    color:
                      disableNodeSelection && nodeOptionType != 'RESPONSE_EVENT'
                        ? '#E8ECF0'
                        : '#ffb946'
                  }"
                >
                  device_hub
                </mat-icon> -->
                <span class="material-icons-outlined icons-primary node-header-icon" style="transform: rotate(180deg)">
                  alt_route
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.ADD_IF_ELSE_BRANCH' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="display: flex; flex-direction: column">
              <p class="fs08-rem node-details">
                {{'CAMPAIGN_PAGE.IF_ELSE_DESP' | i18nTranslate}}
              </p>
              <!-- <div *ngIf="nodeOptionType == 'RESPONSE_EVENT'" style="margin-left: 30px">
                <div style="margin-top: 10px; margin-bottom: 15px">
                  <span class="fs08-rem">Select Branching Condition</span><span style="color: red">*</span>
                  <div class="formDivNode" [ngClass]="{
                      active: eventName.errors
                        ? (eventName.errors.required &&
                            eventName.touched &&
                            submitted) ||
                          (eventName.errors.required && submitted)
                        : false
                    }">
                    <mat-select [compareWith]="compareCustomerEvent" class="font-class fs08-rem"
                      [formControl]="eventName" placeholder="Select an event">
                      <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Event found'"
                          [formControl]="customerEventFilterCtrl" [disableInitialFocus]="true"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let event of filteredcustomerEvents | async" class="font-class fs08-rem"
                        style="color: grey" [value]="event">
                        <div style="display: flex; align-items: center;">
                          <div class="material-icons-outlined icons-primary" style="font-size: 16px; margin-right: 5px">
                            {{ getImage(event.eventName) }}
                          </div>
                          <div>
                            {{ event.displayName }}
                          </div>
                        </div>
                      </mat-option>
                      <mat-select-trigger class="select-trigger">
                        <div class="icon-data">
                          <span class="
                              material-icons-outlined
                              icons-primary
                              node-header-icon
                            ">
                            {{ getImage(eventName?.value?.eventName) }}
                          </span>
                          &nbsp;&nbsp;{{ eventName.value?.displayName }}
                        </div>
                      </mat-select-trigger>
                    </mat-select>
                  </div>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                  eventName.value==null &&
                  submitted
                ">Field is required</small>



                </div>
                <div>
                  <span class="fs08-rem">Should occur within</span><span style="color: red">*</span>
                  <div class="conditional-config">
                    <div style="width: 25%">
                      <span style="font-size: 0.7rem; color: #959595">Days</span>
                      <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                          active: daysControl.errors
                            ? (daysControl.errors.required && submitted) ||
                              (!daysControl.errors.required &&
                                daysControl.errors.minVal &&
                                submitted)
                            : (daysControl.value == 0 &&
                                minutesControl.value == 0 &&
                                hoursControl.value === 0 &&
                                submitted) ||
                              false
                        }">
                        <input type="number" min="0" style="width: 95%" class="formInput fs08-rem"
                          [formControl]="daysControl" />
                      </div>
                    </div>
                    <div style="width: 25%">
                      <span style="font-size: 0.7rem; color: #959595">Hours</span>
                      <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                          active: hoursControl.errors
                            ? (hoursControl.errors.required &&
                                hoursControl.touched &&
                                submitted) ||
                              (hoursControl.errors.required && submitted)
                            : (daysControl.value == 0 &&
                                minutesControl.value == 0 &&
                                hoursControl.value === 0 &&
                                submitted) ||
                              false
                        }">
                        <mat-select style="width: 95%" [formControl]="hoursControl" class="font-class fs08-rem">
                          <mat-option *ngFor="let hour of hours" [value]="hour" class="font-class fs08-rem">
                            {{ hour }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                    <div style="width: 25%">
                      <span style="font-size: 0.7rem; color: #959595">Minutes</span>
                      <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                          active: minutesControl.errors
                            ? (minutesControl.errors.required &&
                                minutesControl.touched &&
                                submitted) ||
                              (minutesControl.errors.required && submitted)
                            : (daysControl.value == 0 &&
                                minutesControl.value == 0 &&
                                hoursControl.value === 0 &&
                                submitted) ||
                              false
                        }">
                        <mat-select style="width: 95%" [formControl]="minutesControl" class="font-class fs08-rem">
                          <mat-option *ngFor="let minute of minutes" [value]="minute" class="font-class fs08-rem">{{
                            minute }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </div>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                      daysControl.errors &&
                      !daysControl.errors.required &&
                      daysControl.errors.minVal &&
                      submitted
                    ">Negative numbers not allowed</small>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                      daysControl.value == 0 &&
                      minutesControl.value == 0 &&
                      hoursControl.value === 0 &&
                      submitted
                    ">Duration cannot be 0</small>
                </div>
              </div> -->
              <div *ngIf="nodeOptionType == 'RESPONSE_EVENT'" style="margin-left: 30px;">
                <div *ngIf="responseEventGroup.config.conditionGroups.length !=0">
                  <div *ngFor="
                      let data of responseEventGroup.config.conditionGroups;
                      let j = index;
                      trackBy: trackItem;
                      let jlast = last
                    " style="
                      background: #f8f8f8;
                      margin: 0px 8px 0px 8px;
                      padding: 12px 0px 0px 0px;
                    ">
                    <div style="
                        display: flex;
                        justify-content: space-between;
                        margin: 0px 8px 0px 40px;
                      ">
                      <div>
                        <div *ngIf="data.conditions.length > 1" class="cursor-pointer and-or-operator-tabs">
                          <div [ngClass]="{
                              active: data.conditionsRelation == 'OR'
                            }" style="border-radius: 4px 0px 0px 4px" class="and-or-alignment"
                            (click)="changeChildOperator(j, 'OR')">
                            <small>{{'CAMPAIGN_PAGE.OR' | i18nTranslate}}</small>
                          </div>
                          <div [ngClass]="{
                              active: data.conditionsRelation == 'AND'
                            }" style="border-radius: 0px 4px 4px 0px" class="and-or-alignment"
                            (click)="changeChildOperator(j, 'AND')">
                            <small>{{'CAMPAIGN_PAGE.AND' | i18nTranslate}}</small>
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; justify-content: center">
                        <mat-icon matTooltip="{{ getToolTip() }}" [ngClass]="{
                            inactive: disableAddingNewTriggerConditions()
                          }" class="cursor-pointer campaign-condition-trigger-add-icon"
                          (click)="showResponsePath(j, -1)">
                          add_circle </mat-icon><span class="fs08-rem span-center">{{'CAMPAIGN_PAGE.ADD_CONDITION' | i18nTranslate}}</span>
                      </div>
                    </div>
                    <div *ngFor="
                        let child of data.conditions;
                        let i = index;
                        let last = last
                      " class="fs08-rem">
                      <div style="display: flex; margin: 0px 8px 0px 40px">
                        <div class="trigger-data">
                          <div class="trigger-data-fields">
                            <div style="
                                display: flex;
                                padding: 8px 6px;
                                justify-content: space-between;
                                align-items: center;
                              ">
                              <span style="font-size: 0.85rem">{{'CAMPAIGN_PAGE.CONDITION' | i18nTranslate}}</span>
                              <div style="cursor: pointer">
                                <span matTooltip="Edit" (click)="editResponseEvent(j, i)"
                                  class="material-icons-outlined icons-primary">
                                  edit
                                </span>
                                <span matTooltip="Delete" *ngIf="data.conditions.length>1 || responseEventGroup.config.conditionGroups.length>0" (click)="deleteConditionResponse(j, i)"
                                  class="material-icons-outlined icons-primary">
                                  delete
                                </span>
                              </div>
                            </div>
                            <div class="seperator-line"></div>
                            <div style="display: flex; padding: 6px 8px; cursor: pointer" matTooltip="Click to Edit" (click)="editResponseEvent(j, i)">
                              <div>
                                <span class="
                                    material-symbols-outlined
                                    icons-primary
                                    node-header-icon
                                  ">
                                  {{ getImage(child.rules.EVENT.ruleEvent) }}
                                </span>
                              </div>
                              <div class="trigger-rules" *ngIf="child.rules.EVENT.ruleEvent != 'segment'">
                                <span>{{'CAMPAIGN_PAGE.IF_CUSTOMER' | i18nTranslate}}&nbsp;
                                  <b>{{ getEventName(child) }}</b>
                                </span>
                                <span>{{'CAMPAIGN_PAGE.BY' | i18nTranslate}}
                                  <b>
                                    {{
                                    child.rules.EVENT.ttl.days
                                    }}
                                    {{'CAMPAIGN_PAGE.DAYS_SMALL' | i18nTranslate}}
                                    {{
                                      child.rules.EVENT.ttl.hours
                                    }}
                                    {{'CAMPAIGN_PAGE.HOURS_SMALL' | i18nTranslate}}
                                    {{
                                      child.rules.EVENT.ttl.minutes
                                    }}
                                    {{'CAMPAIGN_PAGE.MINUTES_SMALL' | i18nTranslate}}
                                  </b></span>
                                  <div class="le-viewer" *ngIf="child.rules[getEventKey(child)] && child.rules[getEventKey(child)].logicalExpressions && child.rules[getEventKey(child)].logicalExpressions.length >= 1 && child.rules.EVENT.ruleEvent !='nth lifetime order count'"> 
                                    <app-logical-expressions-viewer [logicalExpressions]="child.rules[getEventKey(child)].logicalExpressions"></app-logical-expressions-viewer>
                                  </div>
                              </div>
                              <div class="trigger-rules" *ngIf="child.rules.EVENT.ruleEvent === 'segment'">
                                  <span>{{'CAMPAIGN_PAGE.SELECTED_SEG' | i18nTranslate}} :</span>
                                  <span *ngFor="let data of segmentName">{{data}}, &nbsp;</span>
                              </div>
                              <!-- <div *ngIf="child.rules.EVENT.ruleEvent == 'time_event'" class="trigger-rules">
                                <span>
                                  <b>{{ getEventName(child) }}</b>
                                </span>
                                <span *ngIf="child.rules.DATE.operator == 'EQUALS'">Triggered on
                                  <b>{{
                                    child.rules.DATE.ruleDates[0]
                                    | toDateObj
                                    | date: "MMM/dd/yyyy"
                                    }}</b></span>
                                <span *ngIf="
                                    child.rules.TIME &&
                                    child.rules.TIME.operator == 'EQUALS'
                                  ">Triggered at
                                  <b>{{ child.rules.TIME.ruleTimes[0] }}</b></span>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!last" style="display: flex; position: relative; cursor: pointer">
                        <div class="and-operator-background" style="position: absolute; left: 20px"
                          *ngIf="data.conditionsRelation == 'AND'">
                          <div class="logical-operator-container">
                            <span class="operator-text">{{'CAMPAIGN_PAGE.AND' | i18nTranslate}}</span>
                          </div>
                        </div>
                        <div class="and-operator-background" style="position: absolute; left: 20px"
                          *ngIf="data.conditionsRelation == 'OR'">
                          <div class="logical-operator-container" style="left: -11px">
                            <span class="operator-text">{{'CAMPAIGN_PAGE.OR' | i18nTranslate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div *ngIf="!jlast" class="and-or-group-connector">
                      <div class="cursor-pointer and-or-group-tab">
                        <div [ngClass]="{
                            active: data.conditionGroupRelation == 'OR'
                          }" style="border-radius: 4px 0px 0px 4px" class="and-or-alignment"
                          (click)="changeParentOperator(j, 'OR')">
                          <small>{{'CAMPAIGN_PAGE.OR' | i18nTranslate}}</small>
                        </div>
                        <div [ngClass]="{
                            active: data.conditionGroupRelation == 'AND'
                          }" style="border-radius: 0px 4px 4px 0px" class="and-or-alignment"
                          (click)="changeParentOperator(j, 'AND')">
                          <small>{{'CAMPAIGN_PAGE.AND' | i18nTranslate}}</small>
                        </div>
                      </div>
                      <div class="trigger-group-connector">
                        <div class="verticalLine" style="height: 10px"></div>
                        <div style="
                            border: 1px solid #edf0f2;
                            border-radius: 2px;
                            padding: 2px 3px;
                          ">
                          <span class="fs08-rem">{{ data.conditionGroupRelation }}</span>
                        </div>
                        <div class="verticalLine" style="height: 10px"></div>
                      </div>
                    </div>
                  </div>
                  <div style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin: 10px 0px;
                    ">
                    <mat-icon matTooltip="{{ getToolTip() }}" [ngClass]="{
                        inactive: disableAddingNewTriggerConditions()
                      }" class="cursor-pointer campaign-condition-trigger-add-icon" (click)="showResponsePath(-1, -1)"
                      [ngStyle]="{
                        cursor: showFirstNav  ? 'not-allowed' : 'pointer'
                      }">
                      add_circle </mat-icon><span class="fs08-rem">{{'CAMPAIGN_PAGE.ADD_GROUP' | i18nTranslate}}</span>
                  </div>
                </div>
                <div class="primary-button" style="margin: 20px;" (click)="showFirstNav ? '' : showResponsePath(-1,-1)" *ngIf="responseEventGroup.config.conditionGroups.length ===0"
                [ngStyle]="{
                  cursor: showFirstNav  ? 'not-allowed' : 'pointer'
                }">
                  <span class="material-icons-outlined button-icon" style="color: #fff">
                    add
                  </span>
                  <span>{{'CAMPAIGN_PAGE.ADD_NEW_CONDITION' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>

          <div id="AUDIENCESPLIT" *ngIf="allowAudienceSplitNodeCreation" class="node-type-config-container" [ngStyle]="{
            background: nodeOptionType == 'AUDIENCESPLIT' ? '#F8F8F8' : 'white'
          }">
            <mat-radio-button color="primary" [disableRipple]="true" value="AUDIENCESPLIT"
            [checked]="nodeOptionType == 'AUDIENCESPLIT'" [ngClass]="
              disableNodeSelection && nodeOptionType == 'AUDIENCESPLIT'
                ? 'disable-radio-color'
                : ''
            ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'AUDIENCESPLIT'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <span class="material-icons-outlined icons-primary node-header-icon">
                  group
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.AUD_SPLIT' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            ">
              <p class="fs08-rem node-details">
                {{'CAMPAIGN_PAGE.AUD_SPLIT_DESP' | i18nTranslate}}              </p>
            </div>
            <div *ngIf="nodeOptionType == 'AUDIENCESPLIT'">
              <div *ngIf="groupNameGroup.length > 1">
                <div *ngFor="let name of groupNameGroup let i = index">
                  <div class="qb-d-flex qb-group-box">
                    <div>
                      {{name}}
                    </div>
                    <div class="material-icons-outlined icons-primary cursor-pointer" (click)="deleteGroup(i)" *ngIf="name !=='Everyone else' && groupNameGroup.length > 2 && !splitedit">
                      delete
                    </div>
                    <div class="material-icons-outlined icons-primary cursor-pointer" (click)="editGroup(i)" *ngIf="name !=='Everyone else' && splitedit">
                      edit
                    </div>
                  </div>
                </div>
              </div>
              <div class="primary-button" style="margin: 20px;" (click)="showExperimentationPath()" *ngIf = "!splitedit">
                <span class="material-icons-outlined button-icon" style="color: #fff">
                  add
                </span>
                <span>{{'CAMPAIGN_PAGE.ADD_GROUP' | i18nTranslate}}</span>
              </div>
            </div>
          </div>

          <div id="WAIT" class="node-type-config-container" [ngStyle]="{
              background: nodeOptionType == 'WAIT' ? '#F8F8F8' : 'white'
            }">
            <mat-radio-button color="primary" [disableRipple]="true" value="WAIT" [checked]="nodeOptionType == 'WAIT'"
              [ngClass]="
                disableNodeSelection && nodeOptionType == 'WAIT'
                  ? 'disable-radio-color'
                  : ''
              ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'WAIT'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <!-- <mat-icon
                  style="margin-right: 15px; transform: scale(0.8)"
                  [ngStyle]="{
                    color:
                      disableNodeSelection && nodeOptionType != 'WAIT'
                        ? '#E8ECF0'
                        : '#e76868'
                  }"
                >
                  hourglass_full</mat-icon
                > -->
                <span class="material-icons-outlined icons-primary node-header-icon">
                  timer
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.ADD_TIME_DELAY' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
              ">
              <p class="fs08-rem node-details">
                {{'CAMPAIGN_PAGE.TIME_DELAY_DESP' | i18nTranslate}}
              </p>
              <div *ngIf="nodeOptionType == 'WAIT'" style="margin-left: 30px">
                <span class="fs08-rem">{{'CAMPAIGN_PAGE.DELAY_OF' | i18nTranslate}}</span><span style="color: red">*</span>
                <div style="display: flex; justify-content: space-between">
                  <div style="width: 25%">
                    <span class="main-label-style">{{'CAMPAIGN_PAGE.DAYS' | i18nTranslate}}</span>
                    <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                        active: daysControl.errors
                          ? (daysControl.errors.required && submitted) ||
                            (!daysControl.errors.required &&
                              daysControl.errors.minVal &&
                              submitted)
                          : (daysControl.value == 0 &&
                              minutesControl.value == 0 &&
                              hoursControl.value === 0 &&
                              submitted) ||
                            false
                      }">
                      <input type="number" min="0" style="width: 95%" class="formInput fs08-rem"
                        [formControl]="daysControl" />
                    </div>
                  </div>
                  <div style="width: 25%">
                    <span class="main-label-style">{{'CAMPAIGN_PAGE.HOURS' | i18nTranslate}}</span>
                    <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                        active: hoursControl.errors
                          ? (hoursControl.errors.required &&
                              hoursControl.touched &&
                              submitted) ||
                            (hoursControl.errors.required && submitted)
                          : (daysControl.value == 0 &&
                              minutesControl.value == 0 &&
                              hoursControl.value === 0 &&
                              submitted) ||
                            false
                      }">
                      <mat-select style="width: 95%" [formControl]="hoursControl" class="font-class fs08-rem">
                        <mat-option *ngFor="let hour of hours" [value]="hour" class="font-class fs08-rem">{{ hour }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div style="width: 25%">
                    <span class="main-label-style">{{'CAMPAIGN_PAGE.MINUTES' | i18nTranslate}}</span>
                    <div class="formDivNode" style="margin-top: 4px" [ngClass]="{
                        active: minutesControl.errors
                          ? (minutesControl.errors.required &&
                              minutesControl.touched &&
                              submitted) ||
                            (minutesControl.errors.required && submitted)
                          : (daysControl.value == 0 &&
                              minutesControl.value == 0 &&
                              hoursControl.value === 0 &&
                              submitted) ||
                            false
                      }">
                      <mat-select style="width: 95%" [formControl]="minutesControl" class="font-class fs08-rem">
                        <mat-option *ngFor="let minute of minutes" [value]="minute" class="font-class fs08-rem">
                          {{ minute }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <small style="font-size: 0.7rem; color: red" *ngIf="
                    daysControl.errors &&
                    !daysControl.errors.required &&
                    daysControl.errors.minVal &&
                    submitted
                  ">{{'CAMPAIGN_PAGE.NEGATICE_NOT_ALLOWED' | i18nTranslate}}</small>
                <small style="font-size: 0.7rem; color: red" *ngIf="
                    daysControl.value == 0 &&
                    minutesControl.value == 0 &&
                    hoursControl.value === 0 &&
                    submitted
                  ">{{'CAMPAIGN_PAGE.DURECATION_NOT_ZERO' | i18nTranslate}}</small>
              </div>
            </div>
          </div>
          <!-- REMOVING FOR NOW WILL NEED IN FUTURE -->
          <!-- <div id="COMARKETING_OFFER" class="node-type-config-container" [ngStyle]="{
            background: nodeOptionType == 'COMARKETING_OFFER' ? '#F8F8F8' : 'white'
          }">
          <mat-radio-button color="primary" [disableRipple]="true" value="COMARKETING_OFFER" [checked]="nodeOptionType == 'COMARKETING_OFFER'"
            [ngClass]="
              disableNodeSelection && nodeOptionType == 'COMARKETING_OFFER'
                ? 'disable-radio-color'
                : ''
            ">
            <div style="display: flex; font-weight: bold" [ngStyle]="{
                color:
                  disableNodeSelection && nodeOptionType != 'COMARKETING_OFFER '
                    ? '#E8ECF0'
                    : 'black'
              }">
              <span class="material-icons-outlined icons-primary node-header-icon">
                handshake
              </span>
              <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">Send
                Co-Marketing Offers</span>
            </div>
          </mat-radio-button>
          <div style="
              display: flex;
              flex-direction: column;
              padding-bottom: 10px;
            ">
            <p class="fs08-rem node-details">
              Send Partner offers to your consumers and upsell as well as earn % of sale done from the partner store.
            </p>
            <div *ngIf="nodeOptionType == 'COMARKETING_OFFER'" style="margin-left: 30px">
              <div>
                <div *ngIf="coMarketingOfferTemplateName == ''" class="attach-template fs08-rem"
                  (click)="attachTemplate('COMARKETING_OFFER')">
                  <div class="attach-template-inner-div">
                    <span class="
                        material-icons-outlined
                        icons-primary
                        node-header-icon
                      ">attach_file</span>
                    <span>Attach Offer</span>
                  </div>
                </div>
                <div style="font-size: 0.7rem;" *ngIf="selectedCoMarketingTemplateId.length > 0 && submittedData === true">
                  Selected Co-Marketing Offers: {{selectedCoMarketingTemplateId.length}}
                </div>
                <div *ngIf="coMarketingOfferTemplateName != ''" class="attach-template fs08-rem"
                  (click)="attachTemplate('COMARKETING_OFFER')">
                  <div class="attach-template-inner-div">
                    <span class="
                        material-icons-outlined
                        icons-primary
                        node-header-icon
                      ">attach_file</span>
                    <span>Replace Offer</span>
                  </div>
                </div>
              </div>
              <div>
                <span *ngIf="coMarketingOfferTemplateName != ''" (click)="previewOfferSummary(selectedOfferTemplateId)"
                  class="selected-template-label">{{ coMarketingOfferTemplateName }}</span>
              </div>
              <small style="font-size: 0.7rem; color: red" *ngIf="
                  (coMarketingOfferTemplateId == -1 || coMarketingOfferTemplateName == '') &&
                  submitted
                ">
                No offer template has been selected</small>
            </div>
          </div>
        </div> -->
          <div id="EMAIL" class="node-type-config-container" [ngStyle]="{
              background: nodeOptionType == 'EMAIL' ? '#F8F8F8' : 'white'
            }">
            <mat-radio-button color="primary" [disableRipple]="true" value="EMAIL" [checked]="nodeOptionType == 'EMAIL'"
              [ngClass]="
                disableNodeSelection && nodeOptionType == 'EMAIL'
                  ? 'disable-radio-color'
                  : ''
              ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'EMAIL'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <!-- <mat-icon
                  style="margin-right: 15px; transform: scale(0.8)"
                  [ngStyle]="{
                    color:
                      disableNodeSelection && nodeOptionType != 'EMAIL'
                        ? '#E8ECF0'
                        : '#48ab3a'
                  }"
                >
                  email
                </mat-icon> -->
                <span class="material-icons-outlined icons-primary node-header-icon">
                  email
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.SEND_EMAIL' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
              ">
              <p class="node-details">
                {{'CAMPAIGN_PAGE.SEND_EMAIL_DESP' | i18nTranslate}}
              </p>
              <div *ngIf="nodeOptionType == 'EMAIL'" style="margin-left: 30px">
                <div style="margin: 8px 0px">
                  <span class="main-label-style">{{'CAMPAIGN_PAGE.EMAIL_SUBJECT' | i18nTranslate}}</span><span style="color: red">*</span>
                </div>
                <div id="emojiContainer">
                  <div class="formDivNode"
                  style="position: relative;" [ngClass]="{
                      active: emailSubject.errors
                        ? (emailSubject.errors.required && submitted) ||
                          (!emailSubject.errors.required &&
                            emailSubject.errors.minlength &&
                            submitted) ||
                          (!emailSubject.errors.required &&
                            emailSubject.errors.maxlength &&
                            submitted)
                        : false
                    }">
                    <textarea class="formInput fs08-rem qb-emoji-content formInputCustom " placeholder="e.g. - Welcome To Our Shop" style="position: absolute; resize: none;" id="emailSubjectInputField"
                      [formControl]="emailSubject" required ></textarea>
                        <span
                        id = "emojidiv"
                        class="smiley-container cursor-pointer"
                        style="position: absolute; left: 90%;"
                        (click)="toggled = !toggled"
                        >😄</span
                      >
                      <div 
                      id="emojiselector"
                      class="qb-emoji-content"
                      *ngIf="toggled"
                      style="position: relative; bottom: 120px;">
                        <ngx-emoj            
                        (onemojipick)="handleEmoji($event)"
                        (onchardelete)="handleCharDelete($event)"
                        [maxRecentEmoji]="2"
                        [recentEmojiStoreKey]="'String'"
                        [searchEmojiPlaceholderText]="searchEmoji"
                        [emojiNotFoundText]="'Not Found'"
                        [theme] = "emojiTheme"
                        [width]="'300px'"
                        [height]="'32vh'">
                        </ngx-emoj>
                      </div>
                  </div>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                      emailSubject.errors &&
                      emailSubject.errors.required &&
                      submitted
                    ">{{'CAMPAIGN_PAGE.SUBJECT_NOT_EMPTY' | i18nTranslate}}</small>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                      emailSubject.errors &&
                      !emailSubject.errors.required &&
                      emailSubject.errors.maxlength &&
                      submitted
                    ">{{'CAMPAIGN_PAGE.SUB_NO_MORE_THAN_100' | i18nTranslate}}</small>
                </div>
                <div *ngIf="emailTemplateName == ''" class="attach-template"
                  (click)="attachTemplate('EMAIL')">
                  <div class="attach-template-inner-div">
                    <span class="
                        material-icons-outlined
                        icons-primary
                        node-header-icon
                      " style="color: var(--main-bg-white);">attach_file</span>
                    <span>{{'CAMPAIGN_PAGE.ATTACH_TEMPLATE' | i18nTranslate}}</span>
                  </div>
                </div>
                <div *ngIf="emailTemplateName != ''" class="attach-template"
                  (click)="attachTemplate('EMAIL')">
                  <div class="attach-template-inner-div">
                    <span class="
                        material-icons-outlined
                        icons-primary
                        node-header-icon
                      "  style="color: var(--main-bg-white);">attach_file</span>
                    <span>{{'CAMPAIGN_PAGE.REPLACE_TEMP' | i18nTranslate}}</span>
                  </div>
                </div>
                <div>
                  <span *ngIf="emailTemplateName != ''" (click)="previewEmail()" class="selected-template-label">{{
                    emailTemplateName }}</span>
                </div>
                <small style="font-size: 0.7rem; color: red" *ngIf="
                    (emailTemplateId == -1 || emailTemplateName == '') &&
                    submitted
                  ">
                  {{'CAMPAIGN_PAGE.NO_EMAL_SELECTED' | i18nTranslate}}</small>
              </div>
            </div>
          </div>

          <div *ngIf="validWidgets | widgetConfig: 47" id="WHATSAPP" class="node-type-config-container" [ngStyle]="{
              background: nodeOptionType == 'WHATSAPP' ? '#F8F8F8' : 'white'
          }">
          <mat-radio-button #buttonWhatsApp (click) = "checkAccountCreated(buttonWhatsApp)" color="primary" [disableRipple]="true" value="WHATSAPP" [checked]="nodeOptionType == 'WHATSAPP'"
            [ngClass]="
              disableNodeSelection && nodeOptionType == 'WHATSAPP'
                ? 'disable-radio-color'
                : ''
            ">
            <div style="display: flex; font-weight: bold" [ngStyle]="{
                color:
                  disableNodeSelection && nodeOptionType != 'WHATSAPP'
                    ? '#E8ECF0'
                    : 'black'
              }">
              <img class="node-header-icon" width="20px" height="20px" style="color: #fff;" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/whatsapp_logo_black.svg">
              <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.SEND_WHATSAPP' | i18nTranslate}}</span>
            </div>
          </mat-radio-button>
          <div style="
              display: flex;
              flex-direction: column;
              padding-bottom: 10px;
            ">
            <p class="fs08-rem node-details">
              {{'CAMPAIGN_PAGE.SEND_WHATSAPP_DESP' | i18nTranslate}}
            </p>
            <div *ngIf="nodeOptionType == 'WHATSAPP'" style="margin-left: 30px">
              <div *ngIf="whatsappTemplateName == ''" class="attach-template fs08-rem"
                (click)="attachTemplate('WHATSAPP')">
                <div class="attach-template-inner-div">
                  <span class="
                      material-icons-outlined
                      icons-primary
                      node-header-icon
                    " style="color: var(--main-bg-white);">attach_file</span>
                  <span>{{'CAMPAIGN_PAGE.ATTACH_TEMPLATE' | i18nTranslate}}</span>
                </div>
              </div>
              <div *ngIf="whatsappTemplateName != ''" class="attach-template fs08-rem"
                (click)="attachTemplate('WHATSAPP')">
                <div class="attach-template-inner-div">
                  <span class="
                      material-icons-outlined
                      icons-primary
                      node-header-icon
                    " style="color: var(--main-bg-white);">attach_file</span>
                  <span>{{'CAMPAIGN_PAGE.REPLACE_TEMP' | i18nTranslate}}</span>
                </div>
              </div>
              <div>
                <span *ngIf="whatsappTemplateName != ''" (click)="previewWhatsApp()" class="selected-template-label">{{
                  whatsappTemplateName }}</span>
              </div>
              <small style="font-size: 0.7rem; color: red" *ngIf="
                  (whatsappTemplateId == -1 || whatsappTemplateName == '') &&
                  submitted
                ">
                {{'CAMPAIGN_PAGE.NOWHATSAPP_SELECTED' | i18nTranslate}}</small>
            </div>
          </div>
        </div>

          <div id="SMS" class="node-type-config-container" [ngStyle]="{'background': nodeOptionType == 'SMS'? '#E8ECF0' : 'white' }" *ngIf="(validScreens | screenConfig: 18)">
                        <mat-radio-button color="primary" [disableRipple]="true" value="SMS" [checked]="nodeOptionType == 'SMS'" [ngClass]="disableNodeSelection && nodeOptionType == 'SMS'? 'disable-radio-color' : ''">
                            <div style=" display: flex; font-weight: bold;" [ngStyle]="{'color':disableNodeSelection && nodeOptionType != 'SMS' ? '#E8ECF0' : 'black' }">
                                <!-- <mat-icon style="
                            margin-right:15px;transform: scale(0.8);" [ngStyle]="{'color':disableNodeSelection && nodeOptionType != 'SMS'? '#E8ECF0' : '#48ab3a' }">
                                    sms
                                </mat-icon> -->
                                <span class="material-icons-outlined" style="
                                transform: scale(0.8);" [ngStyle]="{'color':disableNodeSelection && nodeOptionType != 'SMS'? '#E8ECF0' : 'black' }">
                                sms
                              </span>
                              <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">Send SMS</span>
                            </div>
                        </mat-radio-button>
                        <div style="display: flex;flex-direction: column;padding-bottom: 10px;">
                            <p class="fs08-rem node-details" style="padding-bottom: 10px;">Send SMS to engage with your customers. You can choose from existing SMS templates.</p>
                            <div *ngIf="nodeOptionType=='SMS'" style="margin-left: 30px">

                                <!-- <div style="flex-basis: 40%;width: 50%;margin-bottom: 10px;">
                                    <span *ngIf="smsTemplateName==''" class="attach-template fs08-rem" (click)="attachTemplate('SMS')">Attach
                                        Template</span>
                                    <span *ngIf="smsTemplateName!=''" class="attach-template fs08-rem" (click)="attachTemplate('SMS')">Replace
                                        Template</span>
                                </div> -->
                                <div *ngIf="smsTemplateName == ''" class="attach-template fs08-rem"
                                (click)="attachTemplate('SMS')">
                                <div class="attach-template-inner-div">
                                  <span class="
                                      material-icons-outlined
                                      icons-primary
                                      node-header-icon
                                    " style="color: var(--main-bg-white);">attach_file</span>
                                  <span>{{'CAMPAIGN_PAGE.ATTACH_TEMPLATE' | i18nTranslate}}</span>
                                </div>
                              </div>
                              <div *ngIf="smsTemplateName != ''" class="attach-template fs08-rem"
                                (click)="attachTemplate('SMS')">
                                <div class="attach-template-inner-div">
                                  <span class="
                                      material-icons-outlined
                                      icons-primary
                                      node-header-icon
                                    " style="color: var(--main-bg-white);">attach_file</span>
                                  <span>{{'CAMPAIGN_PAGE.REPLACE_TEMP' | i18nTranslate}}</span>
                                </div>
                              </div>
                                <div *ngIf="smsTemplateName!=''" style="display: flex;cursor:pointer; margin-top: 3px;" (click)="previewSms()">
                                    <span style="font-size: 13px;
                                            text-decoration: underline">{{smsTemplateName}}</span>
                                </div>
                                <small style="font-size:0.7rem;color: red;" *ngIf="(smsTemplateId == -1 || smsTemplateName == '') && submitted">
                                    No Sms template has been selected</small>
                                                </div>
                                            </div>
                                        </div>
          <div id="OFFER" class="node-type-config-container" [ngStyle]="{
              background: nodeOptionType == 'OFFER' ? '#F8F8F8' : 'white'
            }" *ngIf="(validScreens | screenConfig: 16)">
            <mat-radio-button color="primary" [disableRipple]="true" value="OFFER" [checked]="nodeOptionType == 'OFFER'"
              [ngClass]="
                disableNodeSelection && nodeOptionType == 'OFFER'
                  ? 'disable-radio-color'
                  : ''
              ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'OFFER'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <!-- <mat-icon
                  style="margin-right: 15px; transform: scale(0.8)"
                  [ngStyle]="{
                    color:
                      disableNodeSelection && nodeOptionType != 'OFFER'
                        ? '#E8ECF0'
                        : '#5875FF'
                  }"
                >
                  local_offer</mat-icon
                > -->
                <span class="material-icons-outlined icons-primary node-header-icon">
                  local_offer
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.SEND_OFFER' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
              ">
              <p class="fs08-rem node-details">
                {{'CAMPAIGN_PAGE.SEND_OFFER_DESP' | i18nTranslate}}
              </p>
              <div *ngIf="nodeOptionType == 'OFFER'" style="margin-left: 30px">
                <div>
                  <div *ngIf="offerTemplateName == ''" class="attach-template fs08-rem"
                    (click)="attachTemplate('OFFER')">
                    <div class="attach-template-inner-div">
                      <span class="
                          material-icons-outlined
                          icons-primary
                          node-header-icon
                        " style="color: var(--main-bg-white);">attach_file</span>
                      <span>{{'CAMPAIGN_PAGE.ATTACH_OFFER' | i18nTranslate}}</span>
                    </div>
                  </div>
                  <div *ngIf="offerTemplateName != ''" class="attach-template fs08-rem"
                    (click)="attachTemplate('OFFER')">
                    <div class="attach-template-inner-div">
                      <span class="
                          material-icons-outlined
                          icons-primary
                          node-header-icon
                        " style="color: var(--main-bg-white);">attach_file</span>
                      <span>{{'CAMPAIGN_PAGE.REPLACE_OFFER' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <span *ngIf="offerTemplateName != ''" (click)="previewOfferSummary(selectedOfferTemplateId)"
                    class="selected-template-label">{{ offerTemplateName }}</span>
                </div>
                <small style="font-size: 0.7rem; color: red" *ngIf="
                    (offerTemplateId == -1 || offerTemplateName == '') &&
                    submitted
                  ">
                  {{'CAMPAIGN_PAGE.NO_OFFER_SELECTED' | i18nTranslate}}</small>
              </div>
            </div>
          </div>


          <div id="ABTESTING" *ngIf="allowABTestingNodeCreation" class="node-type-config-container" [ngStyle]="{
            background: nodeOptionType == 'ABTESTING' ? '#F8F8F8' : 'white'
          }">
            <mat-radio-button color="primary" [disableRipple]="true" value="ABTESTING"
            [checked]="nodeOptionType == 'ABTESTING'" [ngClass]="
              disableNodeSelection && nodeOptionType == 'ABTESTING'
                ? 'disable-radio-color'
                : ''
            ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'ABTESTING'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <span class="material-icons-outlined icons-primary node-header-icon">
                  rule
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.AB_TESTING' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            ">
              <p class="fs08-rem node-details">
                {{'CAMPAIGN_PAGE.AB_TESTING_DESCRIPTION' | i18nTranslate}}
              </p>
            </div>
            <div *ngIf="nodeOptionType == 'ABTESTING'">
              <div *ngIf="abTesting.length > 0">
                <div *ngFor="let a of abTesting; let i = index">
                  <div class="qb-d-flex qb-group-box">
                    <div class="each-path-disp">
                      <div class="path-name-disp" [matTooltip]="a.pathName" [matTooltipShowDelay]="500">
                        {{a.pathName}}
                      </div>
                      <div class="path-perc-disp">
                        {{a.percValue}} %
                      </div>
                    </div>
                    <div class="material-icons-outlined icons-primary cursor-pointer" *ngIf="abTesting.length > 2 && !pathedit" (click)="deletePath(i)">
                      delete
                    </div>
                  </div>
                </div>
                <div class="qb-d-flex qb-group-box" *ngIf="controlPath">
                  <div>
                    Control
                  </div>
                  <div class="material-icons-outlined icons-primary" (click)="addControl()">
                    delete
                  </div>
                </div>
              </div>

              <div class="exp-window-display" *ngIf="abTesting.length > 0">
                <!-- <div>Experiment Window </div> -->
                <div *ngIf="expWindow[1]">
                  <strong>{{expWindow[1]}} </strong><em> Day(s)</em>
                </div>
                <div *ngIf="expWindow[0] && expWindow[1]"> and </div>
                <div *ngIf="expWindow[0]">
                  <strong>{{expWindow[0]}} </strong><em> Hour(s)</em>
                </div>
              </div>

              <div class="primary-button" style="margin: 20px;" *ngIf = "!pathedit"  (click)="showFirstNav ? '' : showExperimentationPath('ABTESTING')"[ngStyle]="{
                cursor: showFirstNav  ? 'not-allowed' : 'pointer'
              }">
                <span class="material-icons-outlined button-icon" style="color: #fff">
                  edit_note
                </span>
                <span>Configure node</span>
              </div>
              <div class="primary-button" style="margin: 20px;" *ngIf = "pathedit" (click)="editExperimentationPath()">
                <span class="material-icons-outlined button-icon" style="color: #fff">
                  edit
                </span>
                <span>{{'CAMPAIGN_PAGE.EDIT_EXP_PATH' | i18nTranslate}}</span>
              </div>
            </div>
          </div>

          <div id="SURVEY" class="node-type-config-container" [ngStyle]="{
              background: nodeOptionType == 'SURVEY' ? '#F8F8F8' : 'white'
            }">
            <mat-radio-button color="primary" [disableRipple]="true" value="SURVEY"
              [checked]="nodeOptionType == 'SURVEY'" [ngClass]="
                disableNodeSelection && nodeOptionType == 'SURVEY'
                  ? 'disable-radio-color'
                  : ''
              ">
              <div style="display: flex; font-weight: bold" [ngStyle]="{
                  color:
                    disableNodeSelection && nodeOptionType != 'SURVEY'
                      ? '#E8ECF0'
                      : 'black'
                }">
                <!-- <mat-icon
                  style="margin-right: 15px; transform: scale(0.8)"
                  [ngStyle]="{
                    color:
                      disableNodeSelection && nodeOptionType != 'SURVEY'
                        ? '#E8ECF0'
                        : '#FE8E4A'
                  }"
                >
                  event_note
                </mat-icon> -->
                <span class="material-icons-outlined icons-primary node-header-icon">
                  thumb_up_off_alt
                </span>
                <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.SEND_SURVEY' | i18nTranslate}}</span>
              </div>
            </mat-radio-button>
            <div style="
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
              ">
              <p class="fs08-rem node-details">
                {{'CAMPAIGN_PAGE.SEND_SURVEY_DESP' | i18nTranslate}}
              </p>
              <div *ngIf="nodeOptionType == 'SURVEY'" style="margin-left: 30px">
                <div style="display: flex; flex-direction: column">
                  <!-- <div style="margin-bottom: 5px;">
                                        <div><span class="fs08-rem">Survey Title</span><span style="color:red">*</span>
                                                </div>
                                                <div style="flex-basis: 60%;margin-right: 10px;" class="formDiv" [ngClass]="{'active' : (surveyTitle.errors ?  (surveyTitle.errors.required  && submitted) ||(!surveyTitle.errors.required && surveyTitle.errors.minlength  && submitted)||(!surveyTitle.errors.required && surveyTitle.errors.maxlength && submitted): false)}">
                                                    <input class="formInput fs08-rem" placeholder="Survey Title" [formControl]="surveyTitle" required>
                                                </div>
                                                <small style="font-size:0.7rem;color: red;" *ngIf=" surveyTitle.errors && surveyTitle.errors.required && submitted">Title
                                            cannot be empty</small>
                                                <small style="font-size:0.7rem;color: red;" *ngIf="surveyTitle.errors && !surveyTitle.errors.required && surveyTitle.errors.maxlength && submitted">Title
                                            cannot exceed more than 100 characters</small>
                                        </div> -->
                  <!-- <div>
                    <span class="fs08-rem">Survey Email Subject</span><span style="color: red">*</span>
                    <div class="formDivNode" [ngClass]="{
                        active: surveyEmailSubject.errors
                          ? (surveyEmailSubject.errors.required && submitted) ||
                            (!surveyEmailSubject.errors.required &&
                              surveyEmailSubject.errors.minlength &&
                              submitted) ||
                            (!surveyEmailSubject.errors.required &&
                              surveyEmailSubject.errors.maxlength &&
                              submitted)
                          : false
                      }">
                      <input class="formInput fs08-rem" placeholder="Survey Email Subject"
                        [formControl]="surveyEmailSubject" required />
                    </div>
                    <small style="font-size: 0.7rem; color: red" *ngIf="
                        surveyEmailSubject.errors &&
                        surveyEmailSubject.errors.required &&
                        submitted
                      ">Subject cannot be empty</small>
                    <small style="font-size: 0.7rem; color: red" *ngIf="
                        surveyEmailSubject.errors &&
                        !surveyEmailSubject.errors.required &&
                        surveyEmailSubject.errors.maxlength &&
                        submitted
                      ">Subject cannot exceed more than 100 characters</small>
                  </div> -->
                  <div>
                    <div *ngIf="surveyTemplateName == ''" class="attach-template fs08-rem"
                      (click)="attachTemplate('SURVEY')">
                      <div class="attach-template-inner-div">
                        <span class="
                            material-icons-outlined
                            icons-primary
                            node-header-icon
                          " style="color: var(--main-bg-white);">attach_file</span>
                        <span>{{'CAMPAIGN_PAGE.ATTACH_TEMPLATE' | i18nTranslate}}</span>
                      </div>
                    </div>
                    <div *ngIf="surveyTemplateName != ''" class="attach-template fs08-rem"
                      (click)="attachTemplate('SURVEY')">
                      <div class="attach-template-inner-div">
                        <span class="
                            material-icons-outlined
                            icons-primary
                            node-header-icon
                          " style="color: var(--main-bg-white);">attach_file</span>
                        <span>{{'CAMPAIGN_PAGE.REPLACE_TEMP' | i18nTranslate}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div *ngIf="surveyEmailTemplate && surveyTemplateName != ''"
                    style="display: flex; gap: 3px; margin: 5px 0px">
                    <mat-icon class="icons-primary attachment-icon-campaign-node">mail_outline</mat-icon>
                    <span (click)="previewSurveyEmail()" class="selected-template-label">{{
                      surveyEmailTemplate["layoutName"] }}</span>
                  </div> -->
                  <div *ngIf="surveyTemplateName != ''" style="display: flex; gap: 3px; margin: 5px 0px">
                    <span (click)="previewSurvey()" class="selected-template-label">{{ surveyTemplateName }}</span>
                  </div>
                  <span style="font-size: 0.7rem; color: red" *ngIf="
                      (surveyTemplateId == -1 || surveyTemplateName == '') &&
                      submitted
                    ">
                    {{'CAMPAIGN_PAGE.NO_SURVEY_SELECTED' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div id="CUSTOM_AUDIENCE" class="node-type-config-container" [ngStyle]="{
            background: nodeOptionType == 'CUSTOM_AUDIENCE' ? '#F8F8F8' : 'white'
          }"
          *ngIf="(validScreens | screenConfig: 40)">
          <mat-radio-button #button (change)="radioChange1($event, button)" color="primary" [disableRipple]="true" value="CUSTOM_AUDIENCE"
            [checked]="nodeOptionType == 'CUSTOM_AUDIENCE'" [ngClass]="
              disableNodeSelection && nodeOptionType == 'CUSTOM_AUDIENCE'
                ? 'disable-radio-color'
                : ''
            ">
            <div style="display: flex; font-weight: bold" [ngStyle]="{
                color:
                  disableNodeSelection && nodeOptionType != 'CUSTOM_AUDIENCE'
                    ? '#E8ECF0'
                    : 'black'
              }">
              <span class="material-icons-outlined icons-primary node-header-icon">
                groups
              </span>
              <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">
                Social Media Ads</span>
            </div>
          </mat-radio-button>
          <div style="
              display: flex;
              flex-direction: column;
              padding-bottom: 10px;
            ">
            <p class="fs08-rem node-details">
             Add social media ads to your journey
            </p>
            <div *ngIf="nodeOptionType == 'CUSTOM_AUDIENCE'" style="margin-left: 30px">
              <div style="display: flex; flex-direction: column">
                <div>
                  <span class="main-label-style">Audience Name</span><span style="color: red">*</span>
                  <div style="font-size: 13px;
                  padding-left: 12px;" *ngIf="submitAudience">
                    {{audienceGroupName.value}}
                  </div>
                  <div *ngIf="!submitAudience" class="formDivNode" [ngClass]="{
                      active: audienceGroupName.errors
                        ? (audienceGroupName.errors.required && submitted) ||
                          (!audienceGroupName.errors.required &&
                          audienceGroupName.errors.minlength &&
                            submitted) ||
                          (!audienceGroupName.errors.required &&
                          audienceGroupName.errors.maxlength &&
                            submitted)
                        : false
                    }">
                    <input class="formInput fs08-rem" placeholder="Audience Name"
                      [formControl]="audienceGroupName" required />
                  </div>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                  audienceGroupName.value.length == 0 &&
                  submitAud
                    ">Audience Name cannot be empty</small>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                  audienceGroupName.value.length > 100 &&
                  submitAud
                    ">Audience Name cannot exceed more than 100 characters</small>
                </div>
                <div>
                  <span class="main-label-style" *ngIf="!submitAudience || (submitAudience && audienceGroupDescription.value.length > 0)">Description</span>
                  <div style="font-size: 13px;
    padding-left: 12px;" *ngIf="submitAudience">
                    {{audienceGroupDescription.value}}
                  </div>
                  <div *ngIf="!submitAudience" class="formDivNode" [ngClass]="{
                      active: audienceGroupDescription.errors
                        ? (audienceGroupDescription.errors.required && submitted) ||
                          (!audienceGroupDescription.errors.required &&
                          audienceGroupDescription.errors.minlength &&
                            submitted) ||
                          (!audienceGroupDescription.errors.required &&
                          audienceGroupDescription.errors.maxlength &&
                            submitted)
                        : false
                    }">
                    <input class="formInput fs08-rem" placeholder="Description"
                      [formControl]="audienceGroupDescription" required />
                  </div>
                  <small style="font-size: 0.7rem; color: red" *ngIf="
                  audienceGroupDescription.value.length > 100 &&
                  submitAudience
                    ">Group Description cannot exceed more than 100 characters</small>
                </div>
                <div class="qb-save-button">
                <div *ngIf="!submitAudience" class="primary-button" (click)="saveAudience()">Save</div>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="LOYALTY" class="node-type-config-container" [ngStyle]="{
          background: nodeOptionType == 'LOYALTY' ? '#F8F8F8' : 'white'
        }"
        *ngIf="(validScreens | screenConfig: 43)">
          <mat-radio-button color="primary" [disableRipple]="true" value="LOYALTY"
          [checked]="nodeOptionType == 'LOYALTY'" [ngClass]="
            disableNodeSelection && nodeOptionType == 'LOYALTY'
              ? 'disable-radio-color'
              : ''
          ">
            <div style="display: flex; font-weight: bold" [ngStyle]="{
                color:
                  disableNodeSelection && nodeOptionType != 'LOYALTY'
                    ? '#E8ECF0'
                    : 'black'
              }">
              <span class="material-icons-outlined icons-primary node-header-icon">
                group
              </span>
              <span class="node-type-config-label" [ngClass]="disableNodeSelection ? 'text-style' : ''">{{'CAMPAIGN_PAGE.LOYALITY_NODE' | i18nTranslate}}</span>
            </div>
          </mat-radio-button>
          <div>
            <div style="display: flex;flex-direction: column;padding-bottom: 10px;">
              <p class="fs08-rem node-details">{{'CAMPAIGN_PAGE.LOYALITY_DESP' | i18nTranslate}}</p>
            </div>
            <div *ngIf="nodeOptionType == 'LOYALTY' && !loyaltyProgramConfig['selectedRule'] && !showFirstNav">
              <div class="primary-button" style="margin: 20px;" (click)="showLoyaltySidePanel(false)">
                <span>Select Rule</span>
              </div>
            </div>
          </div>
          <div *ngIf="nodeOptionType == 'LOYALTY' && loyaltyProgramConfig['selectedRule'] && !showFirstNav" class="margin-top-12">
            <app-loyalty-rule-preview 
                    (click)="selectLoyaltyRule(rule)" [rule]="loyaltyProgramConfig['selectedRule']" [loyaltyPoints]="loyaltyProgramConfig.fullConfig.rewardPoints" 
                    [loyaltyRules]="loyaltyProgramConfig.loyaltyRules" [offerList]="offers" 
                    [applicableCustomerGroupList]="customerSegmentAndTiers"></app-loyalty-rule-preview>
            <div>
              <div class="primary-button" style="margin: 20px;" (click)="showLoyaltySidePanel(true)">
                <span>Change Rule</span>
              </div>
            </div>
          </div>
        </div>

        </mat-radio-group>
      </div>
    </div>
    <div *ngIf="!showFirstNav &&
        (nodeType != 'TRIGGER' ||
        (triggerGroupv2 &&
          triggerGroupv2.config &&
          triggerGroupv2.config.conditionGroups &&
          triggerGroupv2.config.conditionGroups.length > 0))" class="nav-action-button">
      <span class="secondary-button-border" (click)="saveNodeConfig(false)" data-button-name="Cancel">{{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}</span>
      <span class="primary-button" (click)="saveNodeConfig(true)" data-button-name="Save block"
        [attr.data-item-name]="_gaConditionString">{{'CAMPAIGN_PAGE.SAVE' | i18nTranslate}}
      </span>
    </div>
  </div>
</div>
<div class="loading-indicator" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
  <span>{{'CAMPAIGN_PAGE.LOADING' | i18nTranslate}}</span>
</div>