import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

@Component({
  selector: "app-offer-filter-overlay",
  templateUrl: "./offer-filter-overlay.component.html",
  styleUrls: ["./offer-filter-overlay.component.css"],
})
export class OfferFilterOverlayComponent implements OnInit {
  constructor(
    private _WidgetConfigService: WidgetConfigService,
    public dialogRef: MatDialogRef<OfferFilterOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  filters;
  status = [];
  channel = [];
  channelSms;
  channelEmail;
  channelWhatsapp;
  statusDraft;
  statusScheduled;
  statusSent;
  analyticsText: string;
  validScreens;
  validWidget;

   ngOnInit() {
    this.getScreenId();
    this.filters = this.data;
    this.channelEmail = this.filters.channel.indexOf("EMAIL") != -1;
    this.channelSms = this.filters.channel.indexOf("SMS") != -1;
    this.channelWhatsapp = this.filters.channel.indexOf("WHATSAPP") != -1;
    this.statusDraft = this.filters.status.indexOf("DRAFT") != -1;
    this.statusScheduled = this.filters.status.indexOf("SCHEDULED") != -1;
    this.statusSent = this.filters.status.indexOf("SENT") != -1;
    this.setanalyticstext();
  }
  async getScreenId(){
    this.validScreens =  await this._WidgetConfigService.getScreen();
    this.validWidget = await this._WidgetConfigService.getWidget();
  }

  applyFilter() {
    this.channelEmail ? this.channel.push("EMAIL") : null;
    this.channelSms ? this.channel.push("SMS") : null;
    this.channelWhatsapp ? this.channel.push("WHATSAPP") : null;
    this.statusSent ? this.status.push("SENT") : null;
    this.statusDraft ? this.status.push("DRAFT") : null;
    this.statusScheduled ? this.status.push("SCHEDULED") : null;
    this.setanalyticstext();
    this.dialogRef.close(
      (this.filters = { status: this.status, channel: this.channel })
    );
  }

  clear() {
    this.channelEmail = false;
    this.channelSms = false;
    this.channelWhatsapp = false;
    this.statusSent = false;
    this.statusDraft = false;
    this.statusScheduled = false;
    this.setanalyticstext();
  }

  close() {
    this.dialogRef.close(false);
  }

  setanalyticstext() {
    var email, sms, sent, draft, scheduled, whatsapp;
    email = this.channelEmail ? " Email " : "";
    sms = this.channelSms ? " SMS " : "";
    whatsapp = this.channelWhatsapp ? " WhatsApp " : "";
    sent = this.statusSent ? " Sent " : "";
    draft = this.statusDraft ? " Draft " : "";
    scheduled = this.statusScheduled ? " Scheduled " : "";
    this.analyticsText = email + sms + sent + draft + scheduled;
  }

  closeFiltersDiv() {
    close();
  }
}
