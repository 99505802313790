import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-sentiment-dialogbox',
  templateUrl: './review-sentiment-dialogbox.component.html',
  styleUrls: ['./review-sentiment-dialogbox.component.css']
})
export class ReviewSentimentDialogboxComponent implements OnInit {
  @Input() surveyData: any;

  starsArray: number[] = [];
  constructor() { }

  ngOnInit(): void {
    console.log(this.surveyData);
    if(this.surveyData.rating){
      this.starsArray = Array(Math.round(this.surveyData.rating)).fill(0);
    }
  }

  getSentimentEmoji(sentiment: string): string {
    switch (sentiment?.toLowerCase()) {
      case 'positive':
        return '😊'; 
      case 'neutral':
        return '😐'; 
      case 'negative':
        return '😞'; 
      default:
        return '🤔'; 
    }
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

}
