import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, ViewEncapsulation,AfterViewInit } from '@angular/core';
import * as d3 from 'd3';
import d3Tip from "d3-tip";
@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class StackedBarChartComponent implements AfterViewInit,OnInit {
  @Input() data;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  totalValue=0;
  private chartContainerElement: any;
  private tooltip: any;

groupdata;
  colorset=["#97cb64","#fdb913","#811620"]
  ngOnInit(){
    for (let i=0;i<this.data.length;i++){
      this.totalValue+=this.data[i]['PROMOTERS']+this.data[i]['PASSIVES']+this.data[i]['DETRACTORS'];
    }
  }
  ngAfterViewInit() {
    console.log("DATA",this.data)
var k=[];
    var i=0;
    for (i=0;i<this.data.length;i++){
      this.totalValue+=this.data[i]['PROMOTERS']+this.data[i]['PASSIVES']+this.data[i]['DETRACTORS'];
      var v=[];
      var d=this.data[i]["date"];
var parseDate = d3.timeParse("%m/%Y");
var formatTime = d3.timeFormat("%b");
d = parseDate(d);
d = formatTime(d);
      v.push({grpName:"PROMOTERS",grpValue:this.data[i]['PROMOTERS'],grpColor:"#4abb59",date:d});
      v.push({grpName:"PASSIVES",grpValue:this.data[i]['PASSIVES'],grpColor:"#edbd22",date:d})
      v.push({grpName:"DETRACTORS",grpValue:this.data[i]['DETRACTORS'],grpColor:"#f84737",date:d})

      k.push({key:this.data[i]["date"],values:v})
//Sample data
     // { key: new Date(2019, 01, 07), values:
        //                                [
        //                                  {grpName:'Team1', grpValue:26},
        //                                  {grpName:'Team2', grpValue:15},
        //                                  {grpName:'Team3', grpValue:48}
        //                                ]
        //   },
        //   { key: new Date(2019, 01, 14), values:
    }

    this.groupdata=k;

if(this.totalValue>0){
    var self = this;
    // self.chartContainerElement = self.chartContainer.nativeElement;
    // self.tooltip = d3.select(self.chartContainerElement)
    //   .append('div').attr('class', 'tooltip').style('display', 'none').style('opacity', 0);
    let interval = setInterval(() => {
      if (this.data.length > 0) {
        clearInterval(interval);
        this.newgraph();
      }
    }, 500)
  }
  }


  newgraph(){


var parseDate = d3.timeParse("%m/%Y");
var formatTime = d3.timeFormat("%b");

    var i, n;
for (i = 0, n = this.groupdata.length; i < n; i++) {
var d = this.groupdata[i];
for (var c in d) {
  if (c == "key") {
         d[c] = parseDate(d[c]);
    d[c] = formatTime(d[c]);
  }
}
this.groupdata[i] = d;
}

const groupData=this.groupdata;

// var margin = {top: 20, right: 20, bottom: 30, left: 40},
// width = this.chartContainerElement.nativeElement.offsetWidth - margin.left - margin.right,
// height = this.chartContainerElement.nativeElement.offsetHeight - margin.top - margin.bottom;
let margin: any = { top: 30, right: 30, bottom: 30, left: 30 };
    let height: number = this.chartContainer.nativeElement.offsetHeight - margin.top - margin.bottom;
    let width: number = this.chartContainer.nativeElement.offsetWidth - margin.left - margin.right;
    console.log("HHH",this.chartContainer)



var x0  = d3.scaleBand().rangeRound([0, width], .5);
var x1  = d3.scaleBand();
var y   = d3.scaleLinear().rangeRound([height, 0]);

var xAxis = d3.axisBottom().scale(x0)
                            // .tickFormat(d3.timeFormat("%b"))
                            .tickValues(groupData.map(d=>d.key));

var yAxis =d3.axisLeft(y)
.ticks(3)
.tickSize(0, 0, 0)
.tickFormat(function (d) { return d });

var series = d3.stack().keys(["PROMOTERS","PASSIVES","DETRACTORS"])(groupData);
//const color = d3.scaleOrdinal(d3.schemeCategory10);
var color=this.colorset;
var colors = d3.scaleOrdinal()
.range(color)
.domain(series.map(function (d) { return d.key; }));

var svg = d3.select("#Test").append("svg")
.attr("width", width + margin.left + margin.right)
.attr("height", height + margin.top + margin.bottom)
.append("g")
.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

var categoriesNames = groupData.map(function(d) { return d.key; });
var rateNames       = groupData[0].values.map(function(d) { return d.grpName; });
// var tooltip = d3.select("body").append("div").attr("class", "toolTip");
x0.domain(categoriesNames);
x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);
y.domain([0, d3.max(groupData, function(key) { return d3.max(key.values, function(d) { return d.grpValue; }); })]);

svg.append("g")
  .attr("class", "x axis")
  .attr("transform", "translate(0," + height + ")")
  .call(xAxis).call((g) => g.select(".domain").remove());


svg.append("g")
  .attr("class", "y axis")
  .style('opacity','0')
  .call(yAxis)
  .call((g) => g.select(".domain").remove())
    .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .style("text-anchor", "end")
        .style('font-weight','bold')
        .attr("color", "#c2c2c2")
        .text("Value");



//         var div =  d3.select("body").append("div").attr("class", "toolTip")
// svg.select('.y').transition().duration(500).delay(1300).style('opacity','1');
var tip = d3Tip()
.attr("class", "d3-tip")
.style("background", "#454546")
.style("border-radius", "4px")
.style("color", "#FFFFFF")
.style("padding", "5px")
.style("font-size", "12px")
.style("text-align", "center")
.offset([-5, 0])
.html(function (d) {
  return d.date + " | " + d.grpValue;
});

var slice = svg.selectAll(".slice")
  .data(groupData)
  .enter().append("g")
  .attr("class", "g")
  .attr("transform",function(d) { return "translate(" + x0(d.key) + ",0)"; });

  var yGridLine = d3
  .axisLeft(y)
  .ticks(3)
  .tickSize(-width)
  .tickFormat("");

  slice.append("g")
  .classed("gridLine", true)
  .attr("transform", "translate(0,0)")
  .attr("color", "#c2c2c2")
  .style("stroke-dasharray", "3, 3")
  .call(yGridLine)
  .call((g) => g.select(".domain").remove());

  slice.call(tip);

  slice.selectAll("rect")
  .data(function(d) { return d.values; })
    .enter().append("rect")
        .attr("width", x1.bandwidth())
        .attr("x", function(d) { return x1(d.grpName); })
         .style("fill", function(d) { return colors(d.grpColor) })
         .attr("y", function(d) { return y(0); })
         .attr("height", function(d) { return height - y(0); })
        // .on("mouseover", function(d) {
        //     d3.select(this).style("fill", d3.rgb(colors(d.grpColor)).darker(2));
        //   tooltip.style("display", null);        })


          .on("mouseover", tip.show)
      .on("mouseout", tip.hide);


        // .on("mouseout", function(d) {
        //     d3.select(this).style("fill", colors(d.grpColor));
        //     tooltip.style("display", "none");
        // })
        // .on("mousemove", function (d) {
        //   var xPosition = d3.pointer (this)[0] ;
        //   var yPosition = d3.pointer (this)[1] - 25;

        //   tooltip.attr("transform", "translate(" + xPosition  + "," +yPosition + ")");
        //   tooltip.select("text").text(d.grpValue);
        // });






      //   var tooltip = svg.append("g")
      //   .attr("stroke", "#fff")
      //   .style("display", "none");

      // tooltip.append("rect")
      //   .attr("width", 30)
      //   .attr("height", 20)
      //   .attr("class", "tooltip-stack")
      //   .attr("fill", "#454546")
      //   .style("opacity", 1);

      // tooltip.append("text")
      //   .attr("x", 15)
      //   .attr("dy", "1.2em")
      //   .style("text-anchor", "middle")
      //   .attr("font-size", "12px")
      //   .attr("font-weight", "normal");


slice.selectAll("rect")
  .transition()
  .delay(function (d) {return Math.random()*1000;})
  .duration(1000)
  .attr("y", function(d) { return y(d.grpValue); })
  .attr("height", function(d) { return height - y(d.grpValue); })
//   .on("mousemove", function (d) {
//     tooltip
// .style("left", d3.event.pageX - 50 + "px")
// .style("top", d3.event.pageY - 70 + "px")
// .style("display", "inline-block")
// .html(
// "<div style='display:flex'><span>" +
//  d.grpName +
//  "</span><span> &nbsp;:  &nbsp;</span><span style='display:flex; justify-content: center; font-weight:bold;color:red'>" +
//  d.grpValue +
//  "</span></div>"
// );
// });
// slice.on("mouseout", function (d) {
// tooltip.style("display", "none");
// });


}
}