import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { Router } from "@angular/router";
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { getCurrencySymbol } from "@angular/common";
import { MatPaginator } from '@angular/material/paginator';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogBoxComponent,SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild("paginator") paginator: MatPaginator;
  @Input("loyaltyProgramConfig") loyaltyProgramConfig :{};
  constructor(
    private _apiCall:ApiCallService, 
    private _snackBar:SnackbarCollection,
    private _router:Router, 
    private dialog: MatDialog,
    private _TokenStorageService: TokenStorageService, 
    private _i18nDynamicTranslate: TranslateDynamicText,
    private tokenService: TokenStorageService) { }
  
  currencySymbol;
  dashboardData;
  dataLoading = false;
  activityLoading = false;
  activeUsersData = [];
  tierWiseRevenue = [];
  rewardRedemptionRateStat = [];
  pointsByTier = [];
  totalActiveUsers = 0;
  totalRevenue = 0;
  newLoyaltyMembers = 0;
  loyaltyMembersTimeFrameList = [
    {
      'display': 'This Week',
      'value':'week'
    },
    {
      'display': 'This Month',
      'value':'month'
    },
    {
      'display': 'This Year',
      'value':'year'
    }, 
  ];
  earnedByTierTimeFrameList = ['Week','Month', 'Year'];
  earnedByTierValueList = ['Points','Revenue'];
  loyaltyMembersTimeFrame = 'month';
  earnedByTierTimeFrame = 'Month';
  earnedByTierValue = 'Points';
  showAllCustomersPopup = false;
  showRewardCoinsPopup = false;
  allCustomersListViewData = [];
  allCustomersListViewDataLoading = false;
  isTierDataFetched = false;
  loyaltyDashboardDataDetched = false;
  loyaltyPoints;
  tierMap;
  loyaltyRules;

  //tierId = -1 is for all tiers and 0 for no tier
  //UI wise pagination starts from 0 but api takes 1 as lowerst value
  allMembersPopupConfig = {
    count:0,
    paginationCount:0,
    recordsPerPage: 10,
    pageNumber:0,
    tierId:-1,
    sortBy:'points',
    order:'d',
    nameOrder: 'd'
  }
  rewardCoinsPopupConfig = {
    count:0,
    recordsPerPage: 10,
    pageNumber:0,
    totalCustomers:0,
  }

  //all customers popup
  acpTabList = [
    {
      name:'All',
      tierId: -1,
    },
    {
      name:'No Tier',
      tierId:  0,
    },
  ];
  minDate;
  hasInvalidRule = false;
  coinsToGive = 0;
  earnedByTierChartOptions: EChartsOption;
  activeUsersChartOptions: EChartsOption;
  tierWiseRevenueOptions: EChartsOption;
  rewardRedemptionRateStatOptions: EChartsOption;

  setEarnedByTierChartOptions() {
    let valSerOpt :EChartsOption['series'] = [];
    let xAxisOpt :EChartsOption['xAxis'] = {
      type: 'category',
      boundaryGap: false,
      data:[],
      name: this.earnedByTierTimeFrame,
      nameLocation: 'middle',
      nameGap: 23
    }
    let legendOpt :EChartsOption['legend'] = {
      data:[],
      type:'scroll'
    }
    for(let each of this.pointsByTier) {
      legendOpt.data.push(each.tier);
      let val = [];
      for(let dataPoint of each.values) {
        val.push(parseFloat(dataPoint.value));
        if(xAxisOpt.data.length < each.values.length) {
          xAxisOpt.data.push(dataPoint.dateRange);
        }
      }
      valSerOpt.push({
        name: each.tier,
        type:'line',
        data:val,
      });
    }
    this.earnedByTierChartOptions = {
      tooltip: {
        trigger: 'axis'
      },
      legend: legendOpt,
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: xAxisOpt,
      yAxis: {
        type: 'value',
        name: this.earnedByTierValue,
        nameLocation: 'end',
        min: 'dataMin',
      },
      series: valSerOpt
    };
  }

  setActiveUsersChartOptions() {
    let dataOpt = this.activeUsersData.map((each)=>
    {
      return {
        value: each.count,
        name: each.name
      } 
    });
    this.activeUsersChartOptions = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        type: 'scroll',
        top: '90%',
        width: '100%',
        formatter: function(name: string) {
          const dataItem = dataOpt.find(item => item.name === name);
          return `${name} - ${dataItem ? dataItem.value : ''}`;
        },
        textStyle: {
          fontSize: '13px',
          fontWeight: 'bold',
        }
      },
      series: [
        {
          name: 'Active users',
          type: 'pie',
          center: ['50%','50%'],
          radius: ['20%', '75%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: dataOpt
        }
      ]
    };
  }

  setTierWiseRevenueChartOptions() {
    let dataOpt = this.tierWiseRevenue.map((each)=>{
      return {
        value: parseFloat(each.totalRevenue),
        name: each.tierName
      } 
    });
    this.tierWiseRevenueOptions = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const value = params.value || 0;
          return `${params.name}: ${this.currencySymbol} ${value}`;
        }
      },
      legend: {
        type: 'scroll',
        top: '90%',
        width: '100%',
        formatter: (name: string) => {
          const dataItem = dataOpt.find(item => item.name === name);
          return `${name} - ${this.currencySymbol} ${dataItem ? dataItem.value : ''}`;
        },
        textStyle: {
          fontSize: '13px',
          fontWeight: 'bold',
        }
      },
      series: [
        {
          name: 'Active users',
          type: 'pie',
          center: ['50%','50%'],
          radius: ['20%', '75%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: dataOpt
        }
      ]
    };
  }

  setRewardRedemptionRateStatChartOptions() {
    let valSerOpt = [];
    let xAxisDataOpt = [];
    for(let each of this.rewardRedemptionRateStat) {
      xAxisDataOpt.push(each.dateRange);
      valSerOpt.push(parseInt(each.count));
    }
    this.rewardRedemptionRateStatOptions = {
      tooltip : {
      },
      xAxis: {
        type: 'category',
        data: xAxisDataOpt,
        name: 'Month',
        nameLocation: 'middle',
        nameGap: 23
      },
      yAxis: {
        type: 'value',
        name: 'Number of Customers'
      },
      series: [
        {
          data: valSerOpt,
          type: 'bar'
        }
      ]
    };
  }

  ngOnInit(): void {
    console.log(this.loyaltyProgramConfig);
    this.getLoyaltyProgramDashboardData();
    let revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(revCurrency, "narrow");
    if(this.loyaltyProgramConfig) {
      this.loyaltyPoints = this.loyaltyProgramConfig['rewardPoints'];
      this.tierMap = this.loyaltyProgramConfig['tierConfig'].tierMap;
      this.loyaltyRules = this.loyaltyProgramConfig['ruleConfig'].loyaltyRules;
    }
    //create date objs and check for invalid rule
    const currentDate = new Date();
    this.minDate = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    for(let rule of this.loyaltyRules) {
      rule.validTillDateObj = new Date(rule.validTill[0],rule.validTill[1]-1,rule.validTill[2]);
      if(rule.validTillDateObj < this.minDate) {
        this.hasInvalidRule = true;
        break;
      }
      else this.hasInvalidRule = false;
    }
  }

  getRandomColor() {
    // Generate random RGB values between 0 and 255
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
  
    // Return the contrast color and the original color as an object
    return "rgb(" + r + "," + g + "," + b + ")";
  }

  redirectToEdit() {
    this._router.navigate(["/app/loyalty/create-loyalty-program"]);
  }

  getLoyaltyProgramDashboardData() {
    this.dataLoading = true;
    this._apiCall.getLoyaltyProgramDashboardData().subscribe(data => {
      this.dashboardData = data['body'];
      this.newLoyaltyMembers = this.dashboardData.newLoyaltyMembers;
      this.activeUsersData = this.dashboardData.activeUsers;
      this.setActiveUsersChartOptions();
      this.tierWiseRevenue = this.dashboardData.tierWiseRevenue;
      this.setTierWiseRevenueChartOptions();
      this.rewardRedemptionRateStat = this.dashboardData.rewardRedamationRateStat;
      this.setRewardRedemptionRateStatChartOptions();
      this.pointsByTier =  this.dashboardData.pointsByTier;
      this.setEarnedByTierChartOptions();
      this.dataLoading = false;
    },
    error => {
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching dashboard data", ['POS_PAGE']), 2000);
      console.log(error);
      this.dataLoading = false;
    });
  }

  changeLoyaltyActivityStatus() {
    this.activityLoading = true;
    this._apiCall.toggleLoyaltyProgramActivity(this.dashboardData.loyaltyProgramStatus).subscribe(data => {
      this.activityLoading = false;
    },
    error => {
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error performing action", ['POS_PAGE']), 2000);
      this.dashboardData.loyaltyProgramStatus = !this.dashboardData.loyaltyProgramStatus;
      console.log(error);
      this.activityLoading = false;
    });
  }

  changeNewLoyaltyMembersTimeFrame(loyaltyMembersTimeFrame) {
    this._apiCall.changeNewLoyaltyMembersTimeFrame(loyaltyMembersTimeFrame).subscribe(
      data=>{
        this.newLoyaltyMembers = data['body'];
      },
      error=>{
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error performing action", ['POS_PAGE']), 2000);
      }
    );
  }
  earnedByTierDropdownHandler() {
    this._apiCall.earnedByTierDropdownChange(this.earnedByTierTimeFrame.toLowerCase(),this.earnedByTierValue.toLowerCase()).subscribe(
      data=>{
        this.pointsByTier = data['body'];
        this.setEarnedByTierChartOptions();
      },
      error=>{
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error performing action", ['POS_PAGE']), 2000);
      } 
    );
  }
  getLoyaltyDashboardAllMembersPopupDetails() {
    this.allCustomersListViewData = [];
    this.allCustomersListViewDataLoading = true;
    this.loyaltyDashboardDataDetched = false;
    this._apiCall.getLoyaltyDashboardAllMembersPopupDetails(this.allMembersPopupConfig.recordsPerPage,
      this.allMembersPopupConfig.pageNumber+1,this.allMembersPopupConfig.tierId,
      this.allMembersPopupConfig.sortBy,this.getSortbyOrder(this.allMembersPopupConfig.sortBy), this.customerSearch).subscribe(
      (data)=>{
        let responseData = data['body'];
        this.dashboardData['tiers'] = responseData.tiers;
        if(!this.isTierDataFetched) {
          responseData.tiers.forEach((tier)=>{
            this.acpTabList.splice(1,0,{
              name:tier.tierName,
              tierId:tier.id
            });
            this.isTierDataFetched = true;
          });
        }
        this.dashboardData['totalActiveMembers'] = responseData.totalActiveMembers;
        this.dashboardData['totalRevenue'] = responseData.totalRevenue;
        this.allMembersPopupConfig['count'] = responseData.count;
        this.allMembersPopupConfig['paginationCount'] = responseData.paginationCount;
        this.allCustomersListViewData = responseData.customers;
        this.loyaltyDashboardDataDetched = true;
      },
      (error)=>{
        this.loyaltyDashboardDataDetched = true;
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching dashboard data", ['POS_PAGE']), 2000);
      }
    );
  }

  openAllMembersPopup() {
    this.showAllCustomersPopup=true;
    this.getLoyaltyDashboardAllMembersPopupDetails();
  }

  getCustomers() {
    this._apiCall.getAnalytics(new Date(), new Date()).subscribe((response) => {
      this.rewardCoinsPopupConfig.totalCustomers = JSON.parse(response["body"]).numberOfCustomers;
    });
    this.allCustomersListViewDataLoading = true;
    this.allCustomersListViewData = [];
    this._apiCall
      .getCustomers(
        this.tokenService.getMerchantId(),
        'ALL',
        'ALL',
        this.rewardCoinsPopupConfig.recordsPerPage,
        this.rewardCoinsPopupConfig.pageNumber+1,
        []
      )
      .subscribe((response) => {
        if(response["body"].length>0) {
          this.allCustomersListViewData = response["body"].map((customer) => ({
            id: customer.merchantCustomerId.toString(),
            name: customer.customerName,
            number: customer.phone,
            email: customer.email,
          }));
          this.allCustomersListViewDataLoading = false;
        } else {
          this.allCustomersListViewDataLoading = false;
        }
      },
      (error)=>{
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching dashboard data", ['POS_PAGE']), 2000);
      });
  }

  getCustomerSegmentsAndTiers() {
    this.allCustomersListViewDataLoading = true;
    this.allCustomersListViewData = [];
    this._apiCall.getCustomerSegmentsAndTiers().subscribe(response => {
        this.allCustomersListViewData = response["body"].map((segmentAndTiers) => ({
          id: segmentAndTiers.id.toString(),
          name: segmentAndTiers.segmentName,
          type: segmentAndTiers.category,
        }));
        this.allCustomersListViewData.unshift({
          id:-1,
          name:'My Customers',
          type:'My Customers'
        });
        this.allCustomersListViewDataLoading = false;
    },
    error => {
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching dashboard data", ['POS_PAGE']), 2000);
    });
  }

  openRewardCoinsPopup() {
    this.showRewardCoinsPopup = true;
    this.getCustomers();
  }
  resetPaginationData() {
    this.allMembersPopupConfig.count = 0;
    this.allMembersPopupConfig.paginationCount = 0;
    this.allMembersPopupConfig.recordsPerPage = 10;
    this.allMembersPopupConfig.pageNumber = 0;
    this.allMembersPopupConfig.sortBy = 'points';
    this.allMembersPopupConfig.order = 'd';
  }
  refreshPaginatedData(event,tableName) {
    if(tableName == 'allMembers') {
      this.allMembersPopupConfig.recordsPerPage = event.pageSize;
      this.allMembersPopupConfig.pageNumber = event.pageIndex;
      this.getLoyaltyDashboardAllMembersPopupDetails();
    } else {
      this.rewardCoinsPopupConfig.recordsPerPage = event.pageSize;
      this.rewardCoinsPopupConfig.pageNumber = event.pageIndex;
      if(!this.customerSearchString || this.customerSearchString === '') {
        this.getCustomers();
      } else {
        this.advanceCustomerSearch();
      }
    }
    
  }
  changeTierTab(event) {
    this.allMembersPopupConfig.tierId = this.acpTabList[event.index]['tierId'];
    this.customerSearch = "";
    this.resetPaginationData();
    this.getLoyaltyDashboardAllMembersPopupDetails();
  }
  changeOrder() {
    this.allMembersPopupConfig.sortBy = 'points';
    this.allMembersPopupConfig.order = this.allMembersPopupConfig.order == 'd'?'a':'d';
    this.getLoyaltyDashboardAllMembersPopupDetails();
  }
  changeCustoerNameOrder() {
    this.allMembersPopupConfig.sortBy = 'name';
    this.allMembersPopupConfig.nameOrder = this.allMembersPopupConfig.nameOrder == 'd'?'a':'d';
    this.getLoyaltyDashboardAllMembersPopupDetails();
  }
  getSortbyOrder(sortBy) {
    if(sortBy === 'name'){
      return this.allMembersPopupConfig.nameOrder;
    }
    else {
      return this.allMembersPopupConfig.order;
    }
  }
  numberInputHandler(event: KeyboardEvent,maxLength) {
    const invalidCharacters = ['e', '-', '+'];
    const inputValue = (event.target as HTMLInputElement).value;
    const hasDecimalPoint = inputValue.includes('.');
    if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key) ||
    (event.key === '.' && hasDecimalPoint)) {
      event.preventDefault();
    }
  }

  rewardCoinTabIndex = 0;
  changeRewardCoinTab(event) {
    if(event.index === 0) {
      this.rewardCoinTabIndex = 0;
      this.selectedIdsForRewardCoin = [];
      this.customerSearchString = null;
      this.getCustomers();
    } else {
      this.rewardCoinTabIndex = 1;
      this.segmentFilter = '';
      this.selectedIdsForRewardCoin = [];
      this.getCustomerSegmentsAndTiers();
    }
  }

  segmentFilter = "";
  selectedIdsForRewardCoin = [];
  isAllCustomersSegmentSelected = false;
  onCheckboxChange(event,id,type) {
      if(event.checked) {
        if(!this.isSelected(id)) {
          if(id === -1) {
            this.isAllCustomersSegmentSelected = true;
          }
          this.selectedIdsForRewardCoin.push({id:id,idType:type});
        }
      } else {
        const index = this.selectedIdsForRewardCoin.findIndex((obj) => obj.id === id);
        if(id === -1) {
          this.isAllCustomersSegmentSelected = false;
        }
        if (index !== -1) {
          this.selectedIdsForRewardCoin.splice(index, 1);
        }
      }
  }

  isSelected(id: string): boolean {
    return this.selectedIdsForRewardCoin.some(item => item.id === id);
  }

  areAllSelected() {
    if(this.allCustomersListViewData.length>0) {
      for( let customerData of  this.allCustomersListViewData) {
        if(!this.isSelected(customerData.id)) {
          return false;
        }
      }
      return true;
    }
  }

  timeout;
  searchForCustomer(event: any, resetPagination) {
    clearTimeout(this.timeout);
    if(event.length >2){
      this.timeout = setTimeout(()=> {
        this.customerSearchString = event;
        this.refreshPaginatedData({pageSize:10,pageIndex:0},'rewardCoins');
      }, 600);
    }
    else if(event.length==0){
      this.resetPaginationData();
      this.getCustomers();
    }
  }

  customerSearchString:string;
  advanceCustomerSearch() {
      
          this.allCustomersListViewDataLoading = true;
          this._apiCall.advanceCustomerSearch(this.tokenService.getMerchantId(), this.customerSearchString,this.rewardCoinsPopupConfig.recordsPerPage,
          this.rewardCoinsPopupConfig.pageNumber+1).subscribe(
            (response) => {
              if(response["body"]["totalCount"] > 0 && response["body"]["customerDataList"].length>0) {
                this.rewardCoinsPopupConfig.totalCustomers = response["body"]["totalCount"];
                this.allCustomersListViewData = response["body"]["customerDataList"].map((customer) => ({
                  id: customer.merchantCustomerId.toString(),
                  name: customer.customerName,
                  number: customer.phone,
                  email: customer.email,
                }));
                this.allCustomersListViewDataLoading = false;
              } else {
                this.allCustomersListViewDataLoading = false;
                this.allCustomersListViewData = [];
              }
            },
            (error) => {
              this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching dashboard data", ['POS_PAGE']), 2000);
            }
          );
  }

  numberOfCustomersToRewardCoins = 0;
  toggleConfirmation(from) {
    if(from === 'fromGroupIds') {
      if(this.selectedIdsForRewardCoin.length == 1 && this.selectedIdsForRewardCoin[0].id === -1) {
        this.numberOfCustomersToRewardCoins = this.rewardCoinsPopupConfig.totalCustomers;
        this.openConfirmRewardCoinsPopup();
      } else {
        let selectedSegmentsOrTiers = this.selectedIdsForRewardCoin.map((obj)=>({
          id: obj.id,
          category: obj.idType,
        }));
        this._apiCall
        .getCustomerCountInSegmentAndTiers(selectedSegmentsOrTiers)
        .subscribe((response) => {
          this.numberOfCustomersToRewardCoins = JSON.parse(
            response["body"]
          ).totalCustomerCount;
          this.openConfirmRewardCoinsPopup();
        });
      }
    } else {
      this.numberOfCustomersToRewardCoins = this.selectedIdsForRewardCoin.length;
      this.openConfirmRewardCoinsPopup();
    }
  }

  openConfirmRewardCoinsPopup() {
      const dialogref = this.dialog.open(
        ConfirmationDialogBoxComponent,
        {
          panelClass: "no-padding-dialog-popup",
          width: "380px",
          data: {
            subject: `AWARD POINTS CONFIRMATION`,
            message: `Are you sure you want to reward ${this.numberOfCustomersToRewardCoins} customer(s) with ${this.coinsToGive} reward point(s) ?`,
            cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
            successButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
          }
        }
      );
      dialogref.afterClosed().subscribe((result) => {
        if (result) {
          this.allCustomersListViewDataLoading = true;
          let data = {
            rewardCoins : this.coinsToGive,
            idList : this.selectedIdsForRewardCoin,
          }
          this._apiCall.giveRewardCoins(data).subscribe(
            response=>{
              if(response['body'] === 'done') {
                this.allCustomersListViewDataLoading = false;
                const dialogRef = this.dialog.open(SuccessDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  width: "30%",
                  data: {
                    message: "Points Awarded Successfully",
                    buttonText: "Ok",
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if(result) {
                    this.closeRewardCoinPopUp();
                  }
                });
              }
            },
            error=>{
              this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error awarding loyalty points", ['POS_PAGE']),2000);
            }
          );
        }
      });
  }

  
  selectAllCustomersInPage(event) {
    if(event.checked) {
      if(this.allCustomersListViewData.length>0) {
        this.allCustomersListViewData.forEach((customerData) => {
          this.onCheckboxChange({checked:true},customerData.id,'customer');
        });
      }
    } else {
      if(this.allCustomersListViewData.length>0) {
        this.allCustomersListViewData.forEach((customerData) => {
          this.onCheckboxChange({checked:false},customerData.id,'customer');
        });
      }
    }
    
  }

  closeRewardCoinPopUp() {
    this.showRewardCoinsPopup = false;
    this.selectedIdsForRewardCoin = [];
    this.coinsToGive = 0;
  }

  createCampaign() {
    this._router.navigate(['/app/campaign/creation',{
                "return-url": "campaign/overview",
                id: null,
                recommendation: false,
              },]);
  }
  private onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.onSearchChange(event);
      }
    }, 600);
  }

  customerSearch = "";
  onSearchChange(event){
    if(event.length !=0){
      this.customerSearch = event;
      this.resetPaginationData();
      this.getAdvancedSearchLoyaltyDashboardAllMembersPopupDetails();
    }
    else{
      this.customerSearch = event;
      this.getLoyaltyDashboardAllMembersPopupDetails();
    }
  }

  getAdvancedSearchLoyaltyDashboardAllMembersPopupDetails() {
    this.allCustomersListViewData = [];
    this.allCustomersListViewDataLoading = true;
    this.loyaltyDashboardDataDetched = false;
    this._apiCall.getLoyaltyDashboardAllMembersPopupDetails(this.allMembersPopupConfig.recordsPerPage,
      this.allMembersPopupConfig.pageNumber+1,this.allMembersPopupConfig.tierId,
      this.allMembersPopupConfig.sortBy,this.getSortbyOrder(this.allMembersPopupConfig.sortBy), this.customerSearch).subscribe(
      (data)=>{
        let responseData = data['body'];
        this.dashboardData['tiers'] = responseData.tiers;
        if(!this.isTierDataFetched) {
          responseData.tiers.forEach((tier)=>{
            this.acpTabList.splice(1,0,{
              name:tier.tierName,
              tierId:tier.id
            });
            this.isTierDataFetched = true;
          });
        }
        this.dashboardData['totalActiveMembers'] = responseData.totalActiveMembers;
        this.dashboardData['totalRevenue'] = responseData.totalRevenue;
        this.allMembersPopupConfig['count'] = responseData.count;
        this.allMembersPopupConfig['paginationCount'] = responseData.paginationCount;
        this.allCustomersListViewData = responseData.customers;
        this.loyaltyDashboardDataDetched = true;
      },
      (error)=>{
        this.loyaltyDashboardDataDetched = true;
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching dashboard data", ['POS_PAGE']), 2000);
      }
    );
  }
}


