<div>
    <div>
        <span class="campaign-name-text">{{data['name']}}</span>
    </div>
    <div style="margin-top: 5px;">
        <span class="campaign-description-text">{{data['description']}}</span>
    </div>
    <!-- <div>
    <span *ngIf="data['operation'] === 'ACTIVATE'">Activate Journey ?</span>
    <span *ngIf="data['operation'] === 'DEACTIVATE'">Deactivate Journey ?</span>
  </div> -->
    <div style="display: flex; justify-content: flex-end; margin-top: 40px;">
        <div style="display: flex; justify-content: space-between;">
            <div class="dismiss-button" (click)="closeDialog(false)">
                <span>Cancel</span>
            </div>
            <div class="done-button" (click)="closeDialog(true)" object-type="standard" type="button" [attr.data-strategy-action]="data['operation'] === 'ACTIVATE'?'dashboard-activate':'dashboard-deactivate'" widget-id="6" event-id="57">
                <span *ngIf="data['operation'] === 'ACTIVATE'">{{'SHARED_PAGE.ACTIVATE' | i18nTranslate}}</span>
                <span *ngIf="data['operation'] === 'DEACTIVATE'">{{'SHARED_PAGE.DEACTIVATE' | i18nTranslate}}</span>
            </div>
        </div>
    </div>
</div>