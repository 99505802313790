<!-- <div
  style="
    width: 100%;
    height:100%;
    min-height: 570px;
    /* position: relative; */
  "
 
> -->
<!-- <div style="width: 100%; height: 100%; min-height: 570px"> -->
<div class="close-div">
<div (click)="closeb()" class="material-icons-outlined close">close</div>
</div>
<div style="width: 100%; height: calc(100% - 80px); min-height: 570px"  id="bee-plugin-container"></div>
<!-- </div> -->