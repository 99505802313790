<head>
  <meta charset="utf-8" />
  <title>{{'STATIC_PAGE.NOTFOUND_TITLE' | i18nTranslate}}</title>
  <meta name="description" content="Sign In" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
</head>
<body>
  <div class="NFContainer">
    <div class="NFHeading">
      <span>
        <td>
          <img
            style="height: 96px"
            alt="Default"
            [src]="logoUrl"
          />
        </td>
      </span>
    </div>
    <div
      style="display: flex; justify-content: center; width: 100%; height: 40%"
    >
      <img [src]="imgUrl" alt="Page not found " class="notFoundImage" />
    </div>

    <div style="display: flex; justify-content: center; width: 100%">
      <div>
        <p class="NFText">
          {{'STATIC_PAGE.NOTFOUND_DESCRIPTION' | i18nTranslate}}
          <span style="font-size: 14px">{{'STATIC_PAGE.HOME' | i18nTranslate}}</span> {{'STATIC_PAGE.COUNT' | i18nTranslate : countStart}}
        </p>
        <!-- <p style="height: auto;width: 100%;">
      This page will be redirected in {{countStart}} sec.
  </p> -->
      </div>
    </div>
  </div>
</body>
