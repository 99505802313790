<div style="height: 100%;">
    <div>
        <div class="header">
          <span style="text-align: center">{{ data["subject"] }}</span>
          <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
        </div>
      </div>

    <div style="display:flex;flex-direction:column;align-items: center;justify-content: center;height: 100%;">
      <img class="gf-report-im" style="margin-top:10px"
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/email-pending.svg"
        alt="Email Verification Pending">

      <div class="gf-bold-text" style="margin: 24px 5px 8px 5px;">{{'SHARED_PAGE.EMAIL_NOT_VERIFIED' | i18nTranslate}}</div>
      <div style="    width: 84%;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-word;text-align: center;">
        {{'SHARED_PAGE.VERFIY_EMAIL_USE_WHATSAPP' | i18nTranslate}}</div>
    </div>
    <div class="button-container">
        <!-- <span class="secondary-button-border" *ngIf="data['cancelButtonText']" (click)="submit(false)"
          data-button-name="Canceled Operation" [attr.data-item-name]="data['data-item']">{{ data["cancelButtonText"]
          }}</span> -->
        <span class="primary-button"
          (click)="submit(true)" [attr.data-button-name]="data['data-button']" [attr.data-item-name]="data['data-item']">{{
          data["successButtonText"] }}</span>
    

    
      </div>

  </div>