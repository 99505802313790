<div class="font" [attr.data-widget-name]="data['data-widget']">
  <div>
    <div class="header">
      <span style="text-align: center">{{ data["subject"] }}</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div>
    <div class="message">
      <div class="q-field-block-customer" *ngIf="data['downloadButton_2']">
        <div class="q-field-name">{{'SHARED_PAGE.ENTER_FILE_NAME' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
        <div class="q-input-outline-dialog">
          <input class="q-input-field" type="text" [(ngModel)]="fileName" (keypress)="fileNameKeyPress($event)" (input)="getErrorFilename($event.target.value)">
          <span class="game-name-text-limiter"
          >{{ fileName.length
          }}<span style="color: #cccccc">/50</span></span>
        </div>
        <div style="color: #dc3545; font-size: 12px;" *ngIf="errorText.length > 0">{{errorText | i18nDynamicTranslate : 'POS_PAGE'}}</div>
      </div>
      <div class="q-field-block-customer" *ngIf="data['downloadButton_2']">
        <div class="q-field-name">Email address to which the report should be sent<span class="qb-imp-color">*</span></div>
        <div class="q-input-outline-dialog">
          <input class="q-input-field" type="text" [(ngModel)]="emailId" (keypress)="emailKeyPress($event)" (input)="getEmailError($event.target.value)">
          <span class="game-name-text-limiter">{{ emailId.length }}<span style="color: #cccccc">/50</span></span>
        </div>
        <div style="color: #dc3545; font-size: 12px;" *ngIf="emailError.length > 0">{{emailError}}</div>
      </div>
      <div class="q-field-block-customer" *ngIf="data['saveustomerList']">
        <div class="q-field-name">Custom List Name</div>
        <div class="q-input-outline-dialog">
          <input class="q-input-field" type="text" [(ngModel)]="fileName" (keypress)="fileNameKeyPress($event)" (input)="getErrorFilename($event.target.value)">
          <span class="game-name-text-limiter"
          >{{ fileName.length
          }}<span style="color: #cccccc">/50</span></span
        >
        </div>
        <div style="color: #dc3545; font-size: 12px;" *ngIf="errorText.length > 0">{{errorText | i18nDynamicTranslate : 'POS_PAGE'}}</div>
      </div>
      <div *ngIf="data['message']" style="font-weight: 600;">{{ data["message"] }}</div>
      <div *ngIf="data['guideLine']" [innerHTML]="data['guideLine']"></div>
      <div *ngIf="data['submessage']">{{data["submessage"]}}</div>
    </div>
  </div>
  <!-- <div *ngIf="data['to-screen']" style="
        display: flex;
        justify-content: space-between;
        padding: 0px 20px 15px 20px;
      ">
          <span class="secondary-button-border" (click)="submit(false)">Cancel</span>
          <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen'] != 'campaign'">OK</span>
          <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen']!='campaign'&& data['to-screen']!='survey-alert'">OK</span>
          <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen']=='survey-alert'">Delete</span>
          <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen'] == 'campaign'" widget-id="16" event-id="2" type="button" [attr.data-strategy-action]="data['action']" [attr.object-type]="data['strategyType']">Confirm</span>
      </div> -->
      <div style="display: flex; flex-direction: column; padding: 16px;" *ngIf="data.sampleComment">
        <label for="comments">Comments:</label>
        <div class="comment-container">
          <textarea class="textarea-comment" id="comments" name="comments" rows="5" cols="25" [(ngModel)]="userComments" [placeholder]="data.sampleComment" (keypress)="keypresshandler($event)"></textarea>
          <span class="char-limit-text-comment"><span style="color: #00b759">{{ userComments.length }}</span>/160</span>
        </div>
      </div>


  <div class="button-container">
    <!-- <span
      class="secondary-button-border"
      *ngIf="!data['cancelButtonText']"
      (click)="submit(false)"
      >Cancel</span
    > -->
    <span class="secondary-button-border" *ngIf="data['cancelButtonText']" (click)="submit(false)"
      data-button-name="Canceled Operation" [attr.data-item-name]="data['data-item']">{{ data["cancelButtonText"]
      }}</span>
      <span class="primary-button" *ngIf="data['commentSuccessButtonText']" (click)="submitComment(true)"
      data-button-name="Canceled Operation" [attr.data-item-name]="data['data-item']">{{ data["commentSuccessButtonText"]
      }}</span>
    <!-- <span
      class="primary-button"
      *ngIf="!data['successButtonText']"
      (click)="submit(true)"
      >Confirm</span
    > -->
    <span class="primary-button"
      *ngIf="data['successButtonText'] &&data['successButtonText']!='Cancel Plan'"
      (click)="submit(true)" [attr.data-button-name]="data['data-button']" [attr.data-item-name]="data['data-item']">{{
      data["successButtonText"] }}</span>
    <span class="cancel-button" *ngIf="data['successButtonText'] &&data['successButtonText']=='Cancel Plan'"
      (click)="submit(true)" [attr.data-button-name]="data['data-button']" [attr.data-item-name]="data['data-item']">{{
      data["successButtonText"] }}</span>

      <span class="primary-button" *ngIf = "data['saveustomerList']" (click)="saveCustomerList()">{{data['saveustomerList']}}</span>

      <button class="primary-button" *ngIf = "data['downloadButton_1']" (click)="download('EXCEL')" [disabled]="errorText.length > 0 || emailError.length > 0">{{data['downloadButton_1']}}</button>
      <button class="primary-button" *ngIf = "data['downloadButton_2'] && !data.hasOwnProperty('segmentName')" (click)="download('CSV')" [disabled]="errorText.length > 0 || emailError.length > 0">{{data['downloadButton_2']}}</button>


    <!-- <div class="primary-button qb-center" *ngIf="data['successButtonText'] &&data['successButtonText']=='Upgrade Now'"
      (click)="submitPlanAction(true)" [attr.data-button-sname]="data['data-button']"
      [attr.data-item-name]="data['data-item']">
      <span *ngIf="!planUpgradeLoading">{{ data["successButtonText"] }}</span>
      <span class="gf-select-btn-preview-loading" *ngIf="planUpgradeLoading">
        <mat-spinner diameter="13"></mat-spinner>
      </span>
    </div> -->

    <!-- <span class="primary-button" *ngIf="data['successButtonText']" (click)="submit(true)">Delete</span> -->
  </div>
</div>