import { SafeHtmlPipe } from "./safe-html";
import { TruncatePipe } from "./limiTo-pipe";
import { NumberToIterablePipe } from "./number-to-iterable";
import { FilterPipe } from "./filter.pipe";
import { RemoveUnderscorePipe } from "./removeUnderScore";
import { ReversePipe } from "./reverseForLoopPipe";
import { CurrencySymbolPipe } from "./currency-symbol-pipe";
import { IsCheckedPYG } from "./is-checked-PYG-pipe";
import { WidgetConfig } from "./widget-config-pipe";
import { WidgetLabel } from "./widget-label-pipe";
import { ScreenConfig } from "./screen-config-pipes";
import { IsChecked } from "./is-checked-pipe";
import { ToDateObjPipe } from "./toDate";
import { BoldTextPipe } from "./bold-text-pipe";
import { LabelPipe } from "./label.pipe";
import {CamelCaseToTitleCasePipe} from "./camelcase-to-title-case-pipe"
import {KeysPipe} from "./keys-pipe";
import {CustomTimePipe} from "./customTime-pipe";
import {CustomCurrencyPipe} from "./custom-currency-pipe";
import {checkIfRoleCustomerCare} from "./check-if-role-customer-care-pipe";
import { checkIfReportingView } from "./check-if-report-view-pipe";
export {
    SafeHtmlPipe,
    TruncatePipe,
    NumberToIterablePipe,
    FilterPipe,
    RemoveUnderscorePipe,
    ReversePipe,
    CurrencySymbolPipe,
    IsCheckedPYG,
    WidgetConfig,
    WidgetLabel,
    ScreenConfig,
    IsChecked,
    ToDateObjPipe,
    BoldTextPipe,
    LabelPipe,
    KeysPipe,
    CamelCaseToTitleCasePipe,
    CustomTimePipe,
    CustomCurrencyPipe,
    checkIfRoleCustomerCare,
    checkIfReportingView
}