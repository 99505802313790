import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.css']
})
export class AllReportsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  selectedTab = '';

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
          this.selectedTab = params.type;
          this.selectTab(params.type);
      }else{
        this.router.navigate(["/app/all-report"], {
          queryParams: { type: 'loyaltyReport' },
        });
        this.selectedTab = "loyaltyReport";
        this.selectTab("loyaltyReport");
      }
    });
  }
 
  selectTab(tab) {
    this.selectedTab = tab;
  }

}
