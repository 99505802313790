import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { SmsScheduleDialogComponent, ContactUsDialogComponent, SuccessDialogComponent, ConfirmationDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";


@Component({
  selector: "app-sms-template-tile",
  templateUrl: "./sms-template-tile.component.html",
  styleUrls: ["./sms-template-tile.component.css"],
})
export class SmsTemplateTileComponent implements OnInit {
  s3BucketUrl = environment.s3BucketUrl;
  filter = {
    label: "",
  };
  tabSelected = "preset";
  contentHeight;
  smsLayouts = [];
  imageUrl =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/sms-screen-assets/15-d-4903-ffd-54-f-3-ad-76007-ffae-8722-fc-5%403x.png";
  allLayout = [];
  customLayout = [];
  presetLayout = [];
  customerSegments = [];
  IsEnvProd = true;
  appSubscriptionPlan;
  validWidgets;
  isSMSEditAllowed=false;
  countryCode
  loading = true;
  isStandard= true;
  templateList;
  customGenerictemplateList = [];
  customRewardtemplateList = [];
  standardGenerictemplateList = [];
  standardRewardtemplateList = [];
  standardConsenttemplateList=[];
  showFiltersDiv = false;
  filters = { generic: false, reward: false }; 
  filtersBackup = { generic:false, reward:false };
  headingTextKey =this._i18nDynamicTranslate.transform("Preset Template", ['POS_PAGE']);
 
  rewardTemplateList;
  mobileMatch = window.matchMedia("(max-width: 896px)");

  segmentType = 'all';
  mobileMedia = window.matchMedia("(max-width: 896px)");
  snapshotCounter = 0;
  snapshotInterval;

  @ViewChild("mainContainer") mainContainer: ElementRef;
  @ViewChild("mainHeader") mainHeader: ElementRef;
  @ViewChild('search') searchElement: ElementRef;

  constructor(

    private globalService: Globals,
    private dialog: MatDialog,
    private _ApiCallService: ApiCallService,
    private router: Router,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private snackBar: SnackbarCollection,

  ) {}
  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.mainContainer && this.mainHeader) {
        this.contentHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.mainHeader.nativeElement.offsetHeight;
        document
          .getElementById("container")
          .addEventListener("click", (event) => {
            this.clickEvent(event);
          });
        clearInterval(interval);
      }
    });
  }

  clickEvent(event) {
    let elements = document.getElementsByClassName("qb-more-options");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("style", "display:none");
    }
  }
  ngOnInit() {
    this.checkSMSEditAllowed();
    this.getWidgets();
    this.IsEnvProd = environment.external === "prod" ? true : false;
   
    this.getSmsTemplates();
    // this.getCustomerSegments();
    this.getMerchantDetails();
    /////////
    let interval = setInterval(() => {
      let element = document.getElementById("container");
      if (element) {
        element.addEventListener("click", (event) => {
          this.clickEvent(event);
        });
        clearInterval(interval);
      }
    });
  }

  showSearch(){
    this.searchElement.nativeElement.focus();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  

  // getCustomerSegments() {
  //   this._ApiCallService.getAllCustomerSegments().subscribe(
  //     (response) => {
  //       let body = JSON.parse(response["body"]);
  //       for (let i = 0; i < body.length; i++) {
  //         if (body[i].isActive === true) {
  //           this.customerSegments.push(body[i]);
  //         }
  //       }
  //     },
  //     (err) => {}
  //   );
  // }
  showCustomtempalteListFilter = false;
  showStandardtempalteListFilter = false;
  showFilters = true;
  getSmsTemplates(list?) {
    this.allLayout = [];
    this.presetLayout = [];
    this.customLayout = [];
    this.customGenerictemplateList = [];
    this.customRewardtemplateList = [];
    this.standardGenerictemplateList = [];
    this.standardConsenttemplateList = [];
    this.standardRewardtemplateList = [];
    this.tabSelected = "preset";
    

    //
    let layoutAPI = this._ApiCallService.getSmsTemplates('SMS','SMS').subscribe((response) => {
      this.templateList = response["body"];
      this.templateList.sort((a,b)=> {
          const dateA :Date = new Date(a.lastModifiedTime);
          const dateB :Date = new Date(b.lastModifiedTime);
          return dateB.getTime() - dateA.getTime();;
      });
      
      this.templateList.forEach((layout) => {
        switch (layout["subLayout"]) {
          case "GENERIC":
            if (layout["layoutType"] != "STANDARD") {
              this.customGenerictemplateList.push({ ...layout, apiToCallForDetails: 'layout', isDeletable: true });
            } else {
              this.standardGenerictemplateList.push({ ...layout, apiToCallForDetails: 'layout', isDeletable: false });
            }
            break;
          case "REWARD":
            if (layout["layoutType"] != "STANDARD") {
              this.customRewardtemplateList.push({ ...layout, apiToCallForDetails: 'reward', isDeletable: true });
            } else {
              this.standardRewardtemplateList.push({ ...layout, apiToCallForDetails: 'reward', isDeletable: false });
            }
            break;
          case "CONSENT":
            if (layout["layoutType"] == "STANDARD") {
              this.standardConsenttemplateList.push({ ...layout, apiToCallForDetails: 'consent', isDeletable: false });
            }
            break;
          }
    });
  });


    let interval = setInterval(() => {
      if (layoutAPI.closed) {
        if(this.customRewardtemplateList.length>0) {
          list = list?list:'custom';
        } else {
          list = list?list:'preset';
        }
        if(this.standardGenerictemplateList.length > 0 && this.standardRewardtemplateList.length>0) {
          this.showStandardtempalteListFilter = true;
        }
        if(this.customGenerictemplateList.length > 0 || this.customRewardtemplateList.length>0) {
          this.showCustomtempalteListFilter = true;
        }
        if((list == 'preset' && this.showStandardtempalteListFilter) || (list == 'custom' && this.showCustomtempalteListFilter)) {
          this.showFilters = true;
        } else {
          this.showFilters = false;
        }
        this.loading = false;
        this.selectList(list, null);
        clearInterval(interval);
      }
    });
    // this._ApiCallService.getSmsTemplates("SMS", "SMS").subscribe((response) => {
    //   if(response['body']){
    //     this.smsLayouts = response["body"];
    //     this.smsLayouts.map((layout) => {
    //       this.allLayout.push(layout);
    //       if (layout["layoutType"] == "CUSTOM") {
    //         this.customLayout.push(layout);
    //       } else {
    //         this.presetLayout.push(layout);
    //       }
    //     });
    //   }
    //   this.loading = false;
   
    // });
  }

  async createTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate(["/app/sms/layout-editor",{id:null,type:"CUSTOM",return:'choose-layout'}]);
    }
  }
  async createsmsconsent(){
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/sms/sms-consent",
      ]);
    }
  }
  openOptions(event) {
    this.clickEvent(event);
    event.stopPropagation();
    let id = event.srcElement.parentElement.id;
    let element = document.getElementById(id);
    element
      .getElementsByClassName("qb-more-options")[0]
      .setAttribute("style", "display:block");
  }

  editLayout(layout,event) {
    this.clickEvent(event);
    event.stopPropagation();
    this.router.navigate(["/app/sms/layout-editor",{id:layout.id, clone: false,type:layout.type, sublayout:layout.sublayout,return:'choose-layout'}]);

  }

  cloneLayout(layout, event) {
    this.clickEvent(event);
    event.stopPropagation();
    this.router.navigate(["/app/sms/layout-editor",{id:layout.id, clone: true,type:layout.type, sublayout:layout.sublayout,return:'choose-layout'}]);
  }

  selectListView() {
    this.router.navigate(["/app/sms/list"]);
  }
  selectTileView() {
    this.router.navigate(["/app/sms/choose-layout"]);
  }
  

  
  filterSelected;
  selectList(type, event,filter?) {
    this.clear();
    this.segmentType = type;
    if(event != null)
    event.stopPropagation();
    this.tabSelected = type;
    this.isStandard = this.tabSelected == "preset"?true:false
    this.filterSelected = filter && this.filterSelected == filter? null:filter;
    switch (type) {
      case "preset":
        this.headingTextKey = this._i18nDynamicTranslate.transform("Preset Template", ['POS_PAGE'])
        if(this.filterSelected == 'general') {
          this.filterSelected = 'general';
          this.smsLayouts = this.standardGenerictemplateList;
        } else if(this.filterSelected == 'reward') {
          this.filterSelected = 'reward';
          this.smsLayouts = this.standardRewardtemplateList;
        } else if(this.filterSelected == 'consent') {
          this.filterSelected = 'consent';
          this.smsLayouts = this.standardConsenttemplateList;
        } 
        
        else {
          this.smsLayouts = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
        }
        break;
      case "custom":
        this.headingTextKey = this._i18nDynamicTranslate.transform("Custom Template", ['POS_PAGE'])
        if(this.filterSelected == 'general') {
          this.filterSelected = 'general';
          this.smsLayouts = this.customGenerictemplateList;
        } else if(this.filterSelected == 'reward') {
          this.filterSelected = 'reward';
          this.smsLayouts = this.customRewardtemplateList;
        } 
        
        else {
          this.smsLayouts = this.customGenerictemplateList.concat(this.customRewardtemplateList);
        }
        break;
    }
    if((this.tabSelected == 'preset' && this.showStandardtempalteListFilter) || (this.tabSelected == 'custom' && this.showCustomtempalteListFilter)) {
      this.showFilters = true;
    } else {
      this.showFilters = false;
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  sendNow(layoutId, event) {
    if (event) {
      this.clickEvent(event);
      event.stopPropagation();
    }
    // this.getCustomerSegments();
    let interval = setInterval(() => {
      if (this.customerSegments.length > 0) {
        clearInterval(interval);
        let dialogRef = this.dialog.open(SmsScheduleDialogComponent, {
          panelClass: "schedule-sms-dialog-container",
          data: {
            customerSegments: this.customerSegments,
          },
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            let data = {
              templateId: layoutId,
              scheduleType: "INSTANT",
              scheduleName: "",
              channel: "SMS",
              delay: "",
              segmentIds: result[0] === "ALL" ? null : result,
              scheduleId: "",
              status: "ACTIVE",
            };
            this._ApiCallService.scheduleSMS(data).subscribe((response) => {
              this.openSuccessDialog(
                this._i18nDynamicTranslate.transform("Your SMS has been successfully sent", ['POS_PAGE']),
                this._i18nDynamicTranslate.transform("OK", ['POS_PAGE'])
              );
            });
          }
        });
      }
    });
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openPreview(layout) {
    this.router.navigate(["/mobile-email-preview", {
      smsText: layout["layoutData"],
      smsName: layout['name'],
      smsId: layout['id']
    }])
    // const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
    //   panelClass: "sms-preview-dialog",
    //   data: {
    //     smsText: layout["layoutData"],
    //   },
    // });
    // dialogRef.afterClosed().subscribe((result) => {});
  }

  async deleteLayout(layoutId,event) {
    this.clickEvent(event);
    event.stopPropagation();
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Delete Template", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to delete this Template?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
          // "data-widget": "EMAIL LISTING",
          // "data-button": "Confirm: Delete",
          // "data-item":this.layout["id"],
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._ApiCallService.deleteSmsTemplate(layoutId).subscribe(
            (response) => {
              this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("SMS Deleted Successfully", ['POS_PAGE']), 2000);
              this.getSmsTemplates()
            },
            (err) => {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: 'Cannot Delete SMS',
                  body: err.error["body"]? err.error["body"]: "Something Went Wrong",
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                event.stopPropagation();
              });
            }
          );
        }
      });
    }
  }


// deleteSmsTemplate(layoutId){
//   this._ApiCallService.deleteSmsTemplate(layoutId).subscribe((response) => {

//   })

// }
  checkSMSEditAllowed(){
    this._ApiCallService.checkSMSEditAllowed().subscribe((response) => {
      this.isSMSEditAllowed = response["body"];
    })
  }
  getMerchantDetails(){
    this._ApiCallService.getMerchantDetails().subscribe((response) => {
      let data = JSON.parse(response["body"]);
      this.countryCode = data.country;      // this.countryCode='IN'
    });
  }

  openFiltersDiv() {
    this.showFiltersDiv = true;
  }

  closeFiltersDiv() {
    this.filters = {...this.filtersBackup};
    this.showFiltersDiv = false;
  }

  toggleFilter(filter: string) {
    this.filters[filter] = !this.filters[filter];
    console.log(this.filters);
    console.log(this.filtersBackup);
  }

  clear() {
    this.filters = { generic: false, reward: false }; 
    this.updateFilters();
  }

  applyFilter() {
    this.filtersBackup = {...this.filters};
    this.updateFilters();
    this.showFiltersDiv = false;
  }


  updateFilters() {
    if (this.tabSelected === 'preset') {
      if (this.filters.generic && this.filters.reward) {
        this.smsLayouts = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
      } else if (this.filters.generic) {
        this.smsLayouts = this.standardGenerictemplateList;
      } else if (this.filters.reward) {
        this.smsLayouts = this.standardRewardtemplateList;
      } else {
        this.smsLayouts = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
      }
    } else if (this.tabSelected === 'custom') {
      if (this.filters.generic && this.filters.reward) {
        this.smsLayouts = this.customGenerictemplateList.concat(this.customRewardtemplateList);
      } else if (this.filters.generic) {
        this.smsLayouts = this.customGenerictemplateList;
      } else if (this.filters.reward) {
        this.smsLayouts = this.customRewardtemplateList;
      } else {
        this.smsLayouts = this.customGenerictemplateList.concat(this.customRewardtemplateList);
      }
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }


  
}
