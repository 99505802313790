import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { currentPlanDataService } from "src/app/core/services/current-plan-global.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { InvitePartnerDialogComponent, ConfirmationDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: "app-partnership",
  templateUrl: "./partnership.component.html",
  styleUrls: ["./partnership.component.css"],
})
export class PartnershipComponent implements OnInit {
  showInfo;
  showAgreement = false;
  showAddPartner = false;
  checkStatus = false;
  submitted = false;
  currentPlanObj;
  currentPlanName;
  continueButtonLoading = false;
  action;
  id;
  redirect = false;
  appSubscriptionPlan;
  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private apiCall: ApiCallService,
    private _currentPlanDataService: currentPlanDataService,
    private _WidgetConfigService: WidgetConfigService,
    private _tokenStorage: TokenStorageService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}
  ngOnInit(): void {
    this.getWidgets();
    let ko = this._currentPlanDataService.currentPlanBody.subscribe(
      async (value) => {
        this.currentPlanObj = value; // this.currentPlanObj will hold your value and modify it every time it changes
        if (this.currentPlanObj == null) {
          await this.getCurrentPlanStat();
          this.currentPlanName =
            this.currentPlanObj != null ? this.currentPlanObj.planName : "null";
          if (ko) ko.unsubscribe();
        } else {
          this.currentPlanName = this.currentPlanObj.planName;
          if (ko) ko.unsubscribe();
        }
      }
    );
    this.init();
  }

  init() {
    this.apiCall.getMerchantProfileData().subscribe((response) => {
      if (response["body"]["partnershipConsent"] == true) {
        this.showInfo = false;
        this.showAgreement = false;
        this.router.navigate(["/app/partnership/my-partners"]);
      } else {
        this.showInfo = true;
      }
    });
  }

  async getWidgets() {
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  async next() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.showInfo = false;
      this.showAgreement = true;
    }
  }

  continue() {
    if(this._tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this.apiCall.getCurrentPlan().subscribe((response) => {
        let subscriptionData = response["body"];
        if (subscriptionData["isEmailVerified"] === false) {
          this.openAcceptPartnershipConfirmationDialog();
        } else {
          this.submitted = true;
          if (this.checkStatus == true) {
            this.continueButtonLoading = true;
            this.addPartnershipConsent();
          }
        }
      });
    }
    else{
      this.apiCall.getCurrentPlanPos().subscribe((response) => {
        let subscriptionData = response["body"];
        if (subscriptionData["isEmailVerified"] === false) {
          this.openAcceptPartnershipConfirmationDialog();
        } else {
          this.submitted = true;
          if (this.checkStatus == true) {
            this.continueButtonLoading = true;
            this.addPartnershipConsent();
          }
        }
      });
    }
  }

  addPartnershipConsent() {
    this.apiCall.addPartnershipConsent().subscribe((response) => {
      if (response["message"] == "success") {
        this._tokenStorage.setPartnershipConsentStatus(true);
        this.continueButtonLoading = false;
        this.showAgreement = false;
        if (this.redirect) {
          this.router.navigate([
            "/app/partnership/my-partners/partner-details",
            {
              action: this.action,
              id: this.id,
            },
          ]);
        } else this.showAddPartner = true;
      }
    });
  }

  inviteStorePartner() {
    const dialogRef = this.matDialog.open(InvitePartnerDialogComponent, {
      panelClass: "custom-dialog-container",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  addExistingPartner() {
    this.router.navigate(["/app/partnership/add-partner"]);
  }
  async getCurrentPlanStat() {
    // current plan details necessary
    await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  setPartnershipPlatformCharge() {
    this.submitted = true;
    if (!this.checkStatus) {
      return 0;
    }
    if (this.currentPlanName == "Trial" || this.currentPlanName == "Basic") {
      this.pricingDialog();
    } else {
      this.continue();
    }
  }
  createPartnershipChargeForTrialAndBasicPlan() {
    this.continueButtonLoading = true;
    this.apiCall
      .createPartnershipChargeForTrialAndBasicPlan("PARTNERSHIP")
      .subscribe((response) => {
        let responseData = response["body"];
        window.open(responseData.confirmationURL, "_self");
      });
  }
  activateChargeForPaidPlan() {
    this.continueButtonLoading = true;
    this.apiCall.activatePartnership(null).subscribe(
      (response) => {
        this.continue();
      },
      (error) => {
        this.continueButtonLoading = false;
      }
    );
  }

  pricingDialog() {
    let message = "",
      subMessage = "";
    if (this.currentPlanName == "Trial") {
      message =
        "A small fee of $0.50 will be charged to you for using co-marketing during the trial period. Once your trial period ends, co-marketing will be disabled.";
      subMessage =
        "If you want to continue using co-marketing after trial ends upgrade to paid plans.";
    } else if (this.currentPlanName == "Basic") {
      message =
        "A small recurring fee of $5.00 will be charged to you for using co-marketing every month.";
      subMessage =
        "If you upgrade to a paid plan you won’t be charged any additional fee to use co-marketing.";
    }
    this.openFeeConfirmationDialog(message, subMessage);
  }

  openFeeConfirmationDialog(message, subMessage) {
    const dialogRef = this.matDialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "47%",
      data: {
        subject: "Co-Marketing Charges",
        message: this._i18nDynamicTranslate.transform(message, ['POS_PAGE']),
        submessage: this._i18nDynamicTranslate.transform(subMessage, ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createPartnershipChargeForTrialAndBasicPlan();
      } else {
        this.continueButtonLoading = false;
      }
    });
  }

  openAcceptPartnershipConfirmationDialog() {
    let dialogReference = this.matDialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "40%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Email Verification Pending", ['POS_PAGE']),
        message:
           this._i18nDynamicTranslate.transform("Check your inbox and verify your email address to access full features on Qubriux.", ['POS_PAGE']),
        cancelButtonText: false,
        successButtonText: this._i18nDynamicTranslate.transform("Close", ['POS_PAGE']),
      },
    });
    dialogReference.afterClosed().subscribe((result) => {
      return false;
    });
  }
}
