<div style="display:flex; flex-direction: column; justify-content:center;">
    <div *ngIf="(data=='userSignupSuccess'); else elseBlock">
        <div class="dataTitle">
            {{'SHARED_PAGE.EMAIL_VERIFICATION' | i18nTranslate}}
        </div>
        <div class="dataMessage">
           {{'SHARED_PAGE.EMAIL_VERIFICATION_PARAGRAPH' | i18nTranslate}}
            <!-- We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link. -->
        </div>
        <div style="display: flex;width: 100%;justify-content: flex-end;color: white;">
            <button style=" background:#4abb59;outline: none;width:40%" mat-button [mat-dialog-close] cdkFocusInitial routerLink="/auth/login" routerLinkActive="active ">{{'SHARED_PAGE.OK' | i18nTranslate}}</button>
        </div>
    </div>
    <ng-template #elseBlock>
        <div class="dataTitle">
            {{'SHARED_PAGE.ERROR' | i18nTranslate}}:
        </div>
        <div class="dataMessage">
            {{data}}
            <!-- We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link. -->
        </div>
        <div style="display: flex;width: 100%;justify-content: flex-end;color: white;">
            <button style="background:#4abb59; outline: none;width:40%" mat-button [mat-dialog-close] cdkFocusInitial [routerLink]="[ '/auth/signup'] " routerLinkActive="active ">{{'SHARED_PAGE.OK' | i18nTranslate}}</button>
        </div>
    </ng-template>
</div>