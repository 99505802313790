<div *ngIf="validWidgets" class="mainContainer" style="overflow: auto;">
  <div class="waitContainer" *ngIf="!dashboardData || dashboardLoading">
    <app-qubriux-loading  [size]="45"></app-qubriux-loading>
  </div>
  <div class="mainContainerDash" *ngIf="dashboardData && !dashboardLoading">
    <div class="dashboardTitle">
      <div class="display-flex">{{'SURVEY_PAGE.NET_PROMOTER_SCORE' | i18nTranslate}}
        <div class="i-div-survey-dash" placement="right" popoverClass="my-custom-class" triggers="mouseenter:mouseleave"
        [ngbPopover]="popContent56">
        <ng-template #popContent56>
          <div style="font-size: 12px;color: #fff;">
            {{'SURVEY_PAGE.NET_PROMOTER_SCORE_INFO' | i18nTranslate}}
          </div>
        </ng-template>
        <div class="i">i</div>
        </div>
      </div>
      <div *ngIf="isAdmin && storeFilterList.length>1" class="filter-container">
        <div class="filter-title-2">Store filter: </div>
        <mat-select [(ngModel)]="selectedStore" (selectionChange)="getDashboardForStore()">
            <mat-option *ngFor="let store of storeFilterList" [value]="store" >
            {{store.storeName}}
            </mat-option>
        </mat-select>
      </div>


    </div>
    <div>
      <div class="Rectangle-container">
        <div class="Rectangle-NPS" *ngIf="validWidgets | widgetConfig: 16">
          <div style="
              font-size: 18px;
              display: flex;
              justify-content: space-between;
              width: 100%;
            ">
            <div style="display: flex;align-items: center;">
              <span class="npsTitle">{{ validWidgets | widgetLabel: 16 }}</span>
              <div class="i-div-survey-dash" placement="right" popoverClass="my-custom-class" triggers="mouseenter:mouseleave"
              [ngbPopover]="popContent">
              <ng-template #popContent>
                <div style="font-size: 12px;color: #fff;">
                  {{'SURVEY_PAGE.NET_PROMOTER_SCORE_INFO' | i18nTranslate}}
                </div>
              </ng-template>
              <div class="i">i</div>
              </div>
            </div>
            
            <div>
              <div style="display: flex">
                <span class="nps-icon " *ngIf="
                    netPromoterScore >= 70 &&
                    dashboardData['promoters'] +
                      dashboardData['passives'] +
                      dashboardData['detractors'] !=
                      0
                  "> <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/survey/promoter-smiley.svg" alt=""></span>
                <span class="nps-icon " *ngIf="
                    netPromoterScore >= 20 &&
                    netPromoterScore < 70 &&
                    dashboardData['promoters'] +
                      dashboardData['passives'] +
                      dashboardData['detractors'] !=
                      0
                  ">
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/survey/passive-smiley.svg" alt=""></span>
                <span class="nps-icon " *ngIf="
                    netPromoterScore < 20 &&
                    dashboardData['promoters'] +
                      dashboardData['passives'] +
                      dashboardData['detractors'] !=
                      0
                  ">
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/survey/detractor-smiley.svg" alt=""></span>
                <span class="npsTitle" style="font-size: 21px; font-weight: 600">{{ netPromoterScore }} </span>
              </div>
            </div>
          </div>

          <div style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 80%;
              align-items: center;
            ">
              <!-- <app-donut-chart [data]="graphData" [totalCount]="totalCountNPS" idn="nps">
              </app-donut-chart> -->
              <div echarts [options]="npsChartOptions" class="donut-chart"></div>
          

            <div #heatMapContainer class="heat-map">
              <div *ngIf="heatmapvaluesrefreshed == true" style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 16px;
                  gap: 20px;
                ">
                <div class="title">
                  <span style="
                      width: 13.7px;
                      height: 13.7px;
                      background-color: #97cb64;
                      margin-right: 8px;
                    "></span><span class="npsLabel">{{'SURVEY_PAGE.PROMOTERS' | i18nTranslate}}</span>
                </div>
                <span class="npsLabelValue">{{
                  dashboardData["promoters"]
                  }}</span>
              </div>
              <div *ngIf="heatmapvaluesrefreshed" style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 16px;
                ">
                <div class="title">
                  <span style="
                      width: 13.7px;
                      height: 13.7px;
                      background-color: #FEC53D;
                      margin-right: 8px;
                    "></span><span class="npsLabel">{{'SURVEY_PAGE.PASSIVES' | i18nTranslate}}</span>
                </div>
                <span class="npsLabelValue">{{
                  dashboardData["passives"]
                  }}</span>
              </div>
              <div *ngIf="heatmapvaluesrefreshed" style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 16px;
                ">
                <div class="title">
                  <span style="
                      width: 13.7px;
                      height: 13.7px;
                      background-color: #FF6565;
                      margin-right: 8px;
                    "></span><span class="npsLabel">{{'SURVEY_PAGE.DETRACTORS' | i18nTranslate}}</span>
                </div>
                <span class="npsLabelValue">{{
                  dashboardData["detractors"]
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Rectangle-NPSMM" *ngIf="validWidgets | widgetConfig: 17">
          <div style="
              font-size: 18px;

              display: flex;
              justify-content: space-between;
              height: 10%;
            ">
            <span class="npsTitle">{{ validWidgets | widgetLabel: 17 }}</span>
          </div>

          <div style="
              display: flex;
              height: 90%;
              justify-content: space-between;
              overflow: hidden;
            ">
            <div style="
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
              ">
              <div style="display: flex;width: 100%; height: 100%;">

              <!-- <div style="height: 90%; width: 98%; border-radius: 3px" *ngIf="timeSeriesData">
                <app-stacked-bar-chart [data]="timeSeriesData"></app-stacked-bar-chart>
              </div> -->
                <div *ngIf="timeSeriesData" echarts
                    [options]="timeSeriesOptions"
                    style="width: 100%; height: 90%;">
                </div>
            </div>

              <div *ngIf="timeSeriesData==null">
                <div style="
                  padding: 10px;
                  border: 1px dotted #ff3b30;
                  background: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center; " class="data-avai-border">
                  {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(validWidgets | widgetConfig: 7) ">
    <div class="dashboardTitle" *ngIf="!topicdataLoading">
      <div  class="display-flex">{{ validWidgets | widgetLabel: 18 }}
        <div class="i-div-survey-dash"  popoverClass="my-custom-class" placement="right"
        triggers="mouseenter:mouseleave" [ngbPopover]="popContent23">
        <ng-template #popContent23>
          <div style="font-size: 12px;color:#fff">
            {{'SURVEY_PAGE.SENTIMENT_CUSTOMER_INFO_MESSAGE' | i18nTranslate}}
          </div>
        </ng-template>
        <div class="i">i</div>
      </div>
      </div>
    </div>

    <div class="DashBoard" *ngIf="(topicwiseCatogory.length > 0) && mobileView && !topicdataLoading">
      <div class="topicDashboard">
        <div style="width: 24px;"> <span class="material-icons-outlined icons-primary profile-back-button-icon"> arrow_back_ios </span> </div>
        <div class="topictabs" style="display: flex; flex-grow: 1; overflow-x: auto;">
          <div class="sentimentTopic" (click)="subtopicChange(parentTopic['topic'])"
            data-widget-name="FEEDBACK ANALYSIS" [attr.data-button-name]="parentTopic['topic']"
            *ngFor="let parentTopic of topicwiseCatogory" [ngClass]="{
              active: selectedSubtopic.name == parentTopic['topic']
            }">
            {{ parentTopic["topic"] | titlecase }}
          </div>
        </div>
        <div style="width:24px"> <span class="material-icons-outlined icons-primary profile-front-button-icon"> arrow_forward_ios </span> </div>
      </div>
    </div>

    <div *ngIf="topicdataLoading" class="center-spinner">
      <app-qubriux-loading  [size]="45"></app-qubriux-loading>
    </div>

    <div style="display: flex; flex-direction: column; padding-top: 30px; height: 100%; overflow: auto;" *ngIf="topicwiseCatogory.length > 0 && !topicdataLoading">
      <div *ngIf="!mobileView" class="topicDashboard">
        <div style="display: flex; gap: 10px;">
          <div class="sentimentTopic" (click)="subtopicChange(parentTopic['topic'])"
            data-widget-name="FEEDBACK ANALYSIS" [attr.data-button-name]="parentTopic['topic']"
            *ngFor="let parentTopic of topicwiseCatogory" [ngClass]="{
              active: selectedSubtopic.name == parentTopic['topic']
            }">
            {{ parentTopic["topic"] | titlecase }}
          </div>
        </div>
      </div>
      <div class="topicandSubtopic" #chartArea [ngClass]="{
          active: selectedSubtopic.name == topicwiseCatogory[0]['topic']
        }" *ngIf="!topicdataLoading" style="width: 100%; height: 75%; display: flex">
        <div style="width: 100%; height: 100%;" class="feedback-container">
          <div class="CService" [ngClass]="{ brSect: selectedSubtopic['subTopics'] }">
            <div style="width: 100%; padding: 3%; height: 80%;">
              <div style="
                  font-size: 18px;
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  height: 20%;
                ">
                <span class="npsTitle">{{
                  selectedSubtopic.name | titlecase
                  }}</span>
                <div>
                  <div style="display: flex">
                    <span class="npsTitle" style="font-size: 21px; font-weight: 600">{{ selectedSubtopic.count }}
                    </span>
                  </div>
                </div>
              </div>

              <div style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  height: 80%;
                  align-items: center;
                ">
                <div *ngIf="!topicdonutloading" style="width: 70%; height: 100%;">
                  <div echarts [options]="topicGraphOptions" style="height: 200px;"></div>
                  <!-- <app-donut-chart [data]="topicGraphData" totalCount="100" idn="nps3">
                  </app-donut-chart> -->
                </div>
                <div *ngIf="topicdonutloading">
                  <mat-spinner diameter="15"></mat-spinner>
                </div>

                <div #heatMapContainer class="heat-map">
                  <div style="
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 16px;
                      gap: 20px;
                    ">
                    <div class="title">
                      <span style="
                          width: 13.7px;
                          height: 13.7px;
                          background-color: #4AD991;
                          margin-right: 8px;
                        "></span><span class="npsLabel">{{'SURVEY_PAGE.POSITIVE' | i18nTranslate}}</span>
                    </div>
                    <span class="npsLabelValue">{{
                      selectedSubtopic["positive"] | number: "1.2-2"
                      }}%</span>
                  </div>
                  <div style="
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 16px;
                      gap: 20px;
                    ">
                    <div class="title">
                      <span style="
                          width: 13.7px;
                          height: 13.7px;
                          background-color: #FEC53D;
                          margin-right: 8px;
                        "></span><span class="npsLabel">{{'SURVEY_PAGE.NEUTRAL' | i18nTranslate}}</span>
                    </div>
                    <span class="npsLabelValue">{{
                      selectedSubtopic["neutral"] | number: "1.2-2"
                      }}%</span>
                  </div>
                  <div style="
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 16px;
                      gap: 20px;
                    ">
                    <div class="title">
                      <span style="
                          width: 13.7px;
                          height: 13.7px;
                          background-color: #FF6565;
                          margin-right: 8px;
                        "></span><span class="npsLabel">{{'SURVEY_PAGE.NEGATIVE' | i18nTranslate}}</span>
                    </div>
                    <span class="npsLabelValue">{{
                      selectedSubtopic["negative"] | number: "1.2-2"
                      }}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider d"></div>
            <div style="height: 20%;">
              <div style="
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  padding: 3%;
                ">
                <div class="npsTitle">
                  {{'SURVEY_PAGE.RELATED_TOPIC_CAPS' | i18nTranslate}}
                </div>
                <div *ngFor="let sub of selectedSubtopic['subTopics']">
                  <div style="
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      align-items: center;
                    ">
                    <span>{{ sub["topic"] | titlecase }}</span>
                    <span class="npsLabelValue">{{ sub["count"] }}</span>
                  </div>
                </div>

                <div *ngIf="!selectedSubtopic['subTopics']">
                  <div style="
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      align-items: center;
                    ">
                    <span>-</span>
                    <span class="npsLabelValue">-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-avai" [ngClass]="{ blSect: !selectedSubtopic['subTopics'] }">
            <div *ngIf="linegraphdata.length < 1" style="margin-top: 22%;">
              <div style="
                  padding: 10px;
                  border: 1px dotted #ff3b30;
                  background: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="data-avai-border">
                {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </div>
            </div>
            <div style="width: 100%; height: 95%;display: flex; overflow-x: auto;" *ngIf="linegraphdata.length >0">
              <!-- <div id="container" class="svg-container" style="
              width: 100%;
              height: 100%;
              height: -moz-available;
              height: -webkit-fill-available;
              height: fill-available;
            " #linechartContainer></div> -->
            <div echarts [options]="barchartOptions" style="height: 300px; width: 100%;"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="topicwiseCatogory.length == 0 && !topicdataLoading" style="display: flex; justify-content: center; align-items: center">
      <div style="width: 50%">
        <div style="
            padding: 10px;
            border: 1px dotted #ff3b30;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          " class="data-avai-border">
          {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
        </div>
      </div>
    </div>
  </div>
</div>