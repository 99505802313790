<div class="invite-partner-main">
  <div class="invite-dialog-header">
    <div class="invite-partner-title">{{'SHARED_PAGE.INVITE_PARTNER' | i18nTranslate}}</div>
    <div class="material-icons-outlined close-button" (click)="close()">
      close
    </div>
  </div>
  <div class="invite-partner-info">
    {{'SHARED_PAGE.PARTNER_TEXT_MSG' | i18nTranslate}}
  </div>
  <div class="email-title">{{'SHARED_PAGE.EMAIL' | i18nTranslate}}</div>
  <div *ngFor="let container of divs; let i = index" [id]="i" class="email-main">
    <div class="email-div">
      <div class="email-input-div">
        <input
          type="email"
          class="email-input"
          (keyup)="emailInput($event, i)"
        />
      </div>
      <div
        (click)="removeDiv(i)"
        *ngIf="containers.length > 1"
        class="material-icons-outlined close-div"
      >
        close
      </div>
    </div>
    <div class="error" *ngIf="error[i] !== 'undefined'">
      <div>{{ error[i] }}</div>
    </div>
  </div>
  <div class="add-email-div" (click)="addNewEmail()" *ngIf="emailsArray.length == divs.length && !disableAddEmail">
    <div class="material-icons-outlined add-email-button">add</div>
    <div>{{'SHARED_PAGE.ADD_ANOTHER_EMAIL' | i18nTranslate}}</div>
  </div>
  <div class="cancel-invite-buttons">
    <div class="secondary-button-border" (click)="close()">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
    <div class="primary-button invite-button" (click)="submit()">{{'SHARED_PAGE.INVITE' | i18nTranslate}}</div>
  </div>
</div>
