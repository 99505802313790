import { Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bubble-chart-ad',
  templateUrl: './bubble-chart-ad.component.html',
  styleUrls: ['./bubble-chart-ad.component.css']
})
export class BubbleChartAdComponent implements OnInit {

  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Input() data;
  private chartContainerElement:any;
  spinFlag;
  // timer : number;
  // timeInterval;
  interval;
  // tickOut;

  constructor() { }

  ngOnInit(){

  }

  ngOnChanges() {
    // this.timer = 0;
    // this.tickOut=0;
    //this.refresh();
    this.renderGraph();
  }

  // refresh(){
  //   if(this.timer>1){
  //     //this.spinFlag = false;
  //     this.tickOut = true;
  //     clearInterval(this.interval)
  //     clearInterval(this.timeInterval)
  //   }
  //   else{
  //     let svg = d3.select(this.chartContainerElement);
  //     svg.selectAll("*").remove();
  //   }
  // }

  // timeout(){
  //   this.timeInterval=setInterval(()=>{
  //     this.timer++;
  //   },1000)
  // }

  renderGraph(){
    //this.spinFlag = true;
  //   let dataset1 = {
  //     "children": [{"Name":"Olives","Count":4319},
  //         {"Name":"Tea","Count":4159},
  //         {"Name":"Mashed Potatoes","Count":2583},
  //         {"Name":"Boiled Potatoes","Count":2074},
  //         {"Name":"Milk","Count":1894},
  //         {"Name":"Chicken Salad","Count":1809},
  //         {"Name":"Vanilla Ice Cream","Count":1713},
  //         {"Name":"Cocoa","Count":1636},
  //         {"Name":"Lettuce Salad","Count":1566},
  //         {"Name":"Lobster Salad","Count":1511},
  //         {"Name":"Chocolate","Count":1489},
  //         {"Name":"Apple Pie","Count":1487},
  //         {"Name":"Orange Juice","Count":1423},
  //         {"Name":"American Cheese","Count":1372},
  //         {"Name":"Green Peas","Count":1341},
  //         {"Name":"Assorted Cakes","Count":1331},
  //         {"Name":"French Fried Potatoes","Count":1328},
  //         {"Name":"Potato Salad","Count":1306},
  //         {"Name":"Baked Potatoes","Count":1293},
  //         {"Name":"Roquefort","Count":1273},
  //         {"Name":"Stewed Prunes","Count":1268}]
  // };

  this.interval = setInterval(()=>{

    let dataset = {
      "children" : this.data
       }
  
      this.chartContainerElement = this.chartContainer.nativeElement;
      let diameter = 400;
      let color = d3.scaleOrdinal(d3.schemeDark2);

      let svg = d3.select(this.chartContainerElement);
      svg.selectAll("*").remove();
      
      let bubble = d3.pack(dataset)
          .size([diameter, diameter])
          .padding(1.5);
      
      svg = d3.select(this.chartContainerElement)
          .append("svg")
          .attr('transform', 'translate(0, 0)')
          .attr("width", diameter)
          .attr("height", diameter)
          .attr("class", "bubble");
  
      let tooltip = d3.select("body")
          .append("div")
          .style("position", "absolute")
          .style("z-index", "10")
          .style("visibility", "hidden")
          .style("color", "white")
          .style("padding", "8px")
          .style("background-color", "rgba(0, 0, 0, 0.75)")
          .style("border-radius", "17px")
          .style("font", "12px sans-serif")
          .text("tooltip");
      
      let nodes = d3.hierarchy(dataset)
          .sum(function(d) { return d.Count; });
      
      var node = svg.selectAll(".node")
          .data(bubble(nodes).descendants())
          .enter()
          .filter(function(d){
              return  !d.children
          })
          .append("g")
          .attr("class", "node")
          .attr("transform", function(d) {
              return "translate(" + d.x + "," + d.y + ")";
          });
      
      // node.append("title")
      //     .text(function(d) {
      //         return d.Name + ": " + d.Count;
      //     });
      
      node.append("circle")
          .attr("r", function(d) {
              return d.r;
          })
          .style("fill", function(d,i) {
              return color(i);
          })
          .on("mouseover", function(d) {
            tooltip.text(d.data.Name + ": " + d.data.Count);
            tooltip.style("visibility", "visible");
          })
          .on("mousemove", function(event) {
         return tooltip.style("top", (event.pageY-10)+"px").style("left",(event.pageX+10)+"px");
         })
         .on("mouseout", function(){return tooltip.style("visibility", "hidden");});
      
      node.append("text")
          .attr("dy", ".2em")
          .style("text-anchor", "middle")
          .text(function(d) {
              return d.data.Name.substring(0, d.r / 3);
          })
          .attr("font-family", "sans-serif")
          .attr("font-size", function(d){
              return d.r/4;
          })
          .attr("fill", "white");
      
      // node.append("text")
      //     .attr("dy", "1.3em")
      //     .style("text-anchor", "middle")
      //     .text(function(d) {
      //         return d.data.Count;
      //     })
      //     .attr("font-family",  "Gill Sans", "Gill Sans MT")
      //     .attr("font-size", function(d){
      //         return d.r/3;
      //     })
      //     .attr("fill", "white");
      
      d3.select(self.frameElement)
          .style("height", diameter + "px");
  
      clearInterval(this.interval);
      //clearInterval(this.timeInterval);
        
    //}
   // this.spinFlag = false;
    
  },2000)

  
  }
  }

  
