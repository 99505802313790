import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isChecked'
})
export class IsChecked implements PipeTransform {

  transform(segments, segmentId): any {

    let index = segments.findIndex(d => d.id === segmentId);
    if(index > -1){
        return true;
    }
    else{
        return false;
    }
  }
}