<div class="qb-main" data-widget-name="OFFER LISTING">
  <div class="qb-main-container">
    <mat-icon class="qb-clos-icon" (click)="close()" data-button-name="Close">clear</mat-icon>
  </div>


  <div class="qb-titleDiv">
    <div class="qb-title">{{'GAMIFY_PAGE.FILTERS' | i18nTranslate}}</div>
    <span class="qb-clear" (click)="clear()" data-button-name="Clear Filter" *ngIf="statusPaused||statusDraft||statusSent||statusScheduled">CLEAR ALL</span>

  </div>
 <div class="qb-fg-1">

  <div class="qb-filter">
    <p><strong>{{'GAMIFY_PAGE.STATUS_CAPS' | i18nTranslate}}</strong></p>
    <p>
      <mat-checkbox color="primary" [checked]="statusSent" [(ngModel)]="statusSent" (change)="setanalyticstext()">{{'GAMIFY_PAGE.LIVE' | i18nTranslate}}</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color="primary" [checked]="statusPaused" [(ngModel)]="statusPaused" (change)="setanalyticstext()">{{'GAMIFY_PAGE.PAUSED' | i18nTranslate}}</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color="primary" [checked]="statusDraft" [(ngModel)]="statusDraft" (change)="setanalyticstext()">{{'GAMIFY_PAGE.DRAFT' | i18nTranslate}}</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color="primary" [checked]="statusScheduled" [(ngModel)]="statusScheduled" (change)="setanalyticstext()">{{'GAMIFY_PAGE.SCHEDULED' | i18nTranslate}}</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color="primary" [checked]="statusEnded" [(ngModel)]="statusEnded" (change)="setanalyticstext()">{{'GAMIFY_PAGE.ENDED' | i18nTranslate}}</mat-checkbox>
    </p>
  </div>
 </div>
  <div class="qb-h-70-px">
    <div class="qb-buttons">

      <mat-dialog-actions>
        <button class="qb-cancel-btn qb-cancel-container" mat-dialog-close  data-button-name="Close Filter" (click)="close()" >{{'GAMIFY_PAGE.CANCEL' | i18nTranslate}}

        </button>      </mat-dialog-actions>
      <mat-dialog-actions>
        <button class="primary-button qb-apply-container" (click)="applyFilter()" mat-dialog-close data-button-name="Apply Filter" [attr.data-item-name]="analyticsText">{{'GAMIFY_PAGE.APPLY' | i18nTranslate}}</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>