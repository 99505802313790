import { DatePipe } from "@angular/common";
import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { getCurrencySymbol } from "@angular/common";
import * as d3 from "d3";

import { ActivatedRoute, Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

@Component({
  selector: 'app-sms-analytics',
  templateUrl: './sms-analytics.component.html',
  styleUrls: ['./sms-analytics.component.css']
})
export class SmsAnalyticsComponent implements OnInit, AfterContentInit, OnChanges {
  dateRangeFilter: FormGroup;
  mainTopic: string = "allsms";
  widgetNameForAnalytics = "All SMS";
  daySpan: number = 150;
  revCurrency = sessionStorage.getItem("serviceCurrency");
  currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  YLegend = null;
  XLegend = null;
  section = 1;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  shop = this.tokenStorage.getMerchantOnlineStore();
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public currentDay: number = new Date().getDate();
  public start: Date = new Date(this.fullYear, this.month, this.currentDay - 1);
  public end: Date = new Date(this.fullYear, this.month, this.currentDay - 1);
  public dateFormat: String = "yyyy-MM-dd";
  public maxDate: Date = new Date(this.fullYear, this.month, this.currentDay);

  public today: Date = new Date(new Date().toDateString());

  public last7dayStart: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay - 7
  );
  public last7dayEnd: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay
  );
  public weekStart: Date = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  public weekEnd: Date = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );

  public monthStart: Date = new Date(
    new Date(new Date().setDate(1)).toDateString()
  );
  public monthEnd: Date = this.today;
  public last6month: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 5)).setDate(1)
    ).toDateString()
  );
  public lastStart: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay - 31
  );

  public lastEnd: Date = this.today;
  public yearStart: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 365)).toDateString()
  );
  public yearEnd: Date = this.today;
  allEmailsStatsDemo = {
    message: "success",
    body: {
      emailStats: {
        deliveredRate: 98.2,
        openedRate: 72.5,
        clickedRate: 78.2,
        unsubscribedRate: 10,
        spamRate: 2.2,
        addCartCount: 32,
        visitorsCount: 65,
        totalSMSSent: 200,
      },
      totalRevenue: 1734.68,
      totalNewOrders: 24,
    },
  };

  allEmailsGraphDemo = {
    message: "SUCCESS",
    body: {},
  };

  dropDownStatusFilter: boolean = false;
  dropDownStatusQuick: boolean = false;
  graphEventLoading: boolean = false;
  graphDataLoading: boolean = false;
  dropdownListLoading: boolean = false;
  dropdownOpen: boolean = false;
  validWidgets = null;
  jsonValue;
  apiList = [];
  apiCampaignList: any = [];
  apiOfferList: any = [];
  apiSurveyList: any = [];
  apiActiveCampaignList: any = [];
  apiActiveOfferList: any = [];
  apiActiveSurveyList: any = [];
  apiEndedCampaignList: any = [];
  apiEndedOfferList: any = [];
  apiEndedSurveyList: any = [];
  viewList: any = [];
  moduleId = null;
  dropDownList = {
    value: null,
    type: "all",
  };
  CategoryList = ["all", "active", "ended"];

  chartConfigurations: { [key: string]: echarts.EChartsCoreOption } = {
    sms_analytics_sent: {},
    sms_analytics_delivered: {},
    sms_analytics_failed: {},
  };

  @ViewChild("chartArea") chartArea: ElementRef;
  @ViewChild("filterdropdown") filterdropdown: ElementRef;

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    // if (
    //   this.paramsNav == "hurdle" ||
    //   (this.paramsNav == undefined && this.hurdleReady)
    // ) {
    if (this.filterdropdown.nativeElement.contains(event.target)) {
    } else {
      this.toggleSelect(true);
    }
    // }
  }
  selectedDays = "Last One Week";
  filterViewBy = "day";
  filterQuickView = null;
  subtopic = "SMS Sent";
  startDate;
  endDate;
  chartheight;

  catagorySelectData = null;
  graphDataNull: boolean = true;
  parseDate;
  formatTime;
  hoverFormat;
  graphData;
  queryparam;
  op2ListSearch = "";
  startDateMinValue;
  private documentClickListener: () => void;
  constructor(
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private datePipe: DatePipe,
    private _WidgetConfigService: WidgetConfigService,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private router: Router
  ) { 
    window["screen-name"] = "ANALYZE";
  }

  ngAfterContentInit() {
    this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
  }
  ngOnChanges() {
    this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
  }

  ngOnInit() {
    this.getWidgets();
    this.filterViewBy = "month";
    this.startDate = this.datePipe.transform(this.last6month, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.last7dayEnd, "yyyy-MM-dd");
    this.start = this.startDate;
    this.end = this.endDate;
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
        this.mainTopic = params.type;
        this.getModuleList("all");
        this.getAllEmailData(this.mainTopic);
      }
    });
    // this.getAllEmailStat(
    //   this.datePipe.transform(this.startDate, "yyyy-MM-dd"),
    //   this.datePipe.transform(this.endDate, "yyyy-MM-dd")
    // );
    this.documentClickListener = this.renderer.listen(
      "document",
      "click",
      (event) => {
        const isInsideDropdown = this.elRef.nativeElement
          .querySelector(".dropdown")
          .contains(event.target);
        if (!isInsideDropdown) {
          // If the click is outside the dropdown, hide all dropdowns
          this.hideAllDropdowns(event);
        }
      }
    );
  }
  resetDateFilter() {
    //to get the placeholders back
    this.range.patchValue({
      start: null,
      end: null,
    });
    let span = this.findDaySpan(this.start, this.end);
    let dateRange = {
      startDate: this.start,
      endDate: this.end,
      daySpan: span,
    };
    this.dateChangeDetected(dateRange);
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      // Clean up the event listener when the component is destroyed
      this.documentClickListener();
    }
  }
  errorDate() {}

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  dateChangeDetected(event) {
    var maximumdate = new Date(this.fullYear, this.month, new Date().getDate());
    let startdate = this.datePipe.transform(event["startDate"], "yyyy-MM-dd");
    let endDate = this.datePipe.transform(event["endDate"], "yyyy-MM-dd");
    let newcurrentDate = this.datePipe.transform(maximumdate, "yyyy-MM-dd");

    let newstartdate = new Date(startdate);
    let newendDate = new Date(endDate);
    let newnewcurrentDat = new Date(newcurrentDate);

    this.daySpan = event["daySpan"];
    this.startDate = this.datePipe.transform(event["startDate"], "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(event["endDate"], "yyyy-MM-dd");

    if (
      newendDate.getTime() < newstartdate.getTime() ||
      newstartdate.getTime() > newnewcurrentDat.getTime() ||
      newendDate.getTime() > newnewcurrentDat.getTime()
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Enter valid date range", [
          "POS_PAGE",
        ]),
        2000
      );
    } else {
      // this.getAllEmailStat(this.startDate, this.endDate);
      if (this.daySpan != 0 && this.startDate != null && this.endDate != null) {
        this.mainTopicChange(this.mainTopic, this.subtopic, this.jsonValue);
      }
    }
  }
  getMaxDate() {
    var currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    return this.startDateMinValue;
  }
  toggleSelect(status?) {
    let element = document.getElementById("qb-multi-select-dropdown");
    let segmentSelect = document.getElementById("qb-segment-select");
    let segmentSelectPanel = document.getElementById("qb-multi-select-panel");
    let keyboardDownIconElement = document.getElementById(
      "qb-keyboard-down-icon-container"
    );
    if (this.dropDownStatusFilter || status) {
      this.dropDownStatusFilter = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      // segmentSelectPanel.style.border = "1px solid #919191";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //element.style.display = 'none';
    } else {
      this.dropDownStatusFilter = true;
      element.style.height = "auto";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      // segmentSelectPanel.style.border = "1px solid #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }
  toggleSelect2() {
    let element = document.getElementById("qb-multi-select-dropdown2");
    let segmentSelect = document.getElementById("qb-segment-select2");
    let segmentSelectPanel = document.getElementById("qb-multi-select-panel2");
    let keyboardDownIconElement = document.getElementById(
      "qb-keyboard-down-icon-container2"
    );
    if (this.dropDownStatusQuick) {
      this.dropDownStatusQuick = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.border = "1px solid #919191";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //element.style.display = 'none';
    } else {
      this.dropDownStatusQuick = true;
      element.style.height = "auto";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      segmentSelectPanel.style.border = "1px solid #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }

  updateCheckboxValues(value) {
    this.filterViewBy = value;
    this.toggleSelect();
    if (this.daySpan != 0 && this.startDate != null && this.endDate != null) {
      this.mainTopicChange(this.mainTopic, this.subtopic);
    }
  }

  updateQuickViewValues(value) {
    this.filterQuickView = value;
    this.toggleSelect2();
    this.updateDatefromQuickView(value);
  }

  updateDatefromQuickView(value) {
    this.selectedDays = value;
    var currentDate = new Date();
    var month: number = new Date().getMonth();
    // public fullYear: number = new Date().getFullYear();
    var currentDay: number = new Date().getDate();

    if ("Last 7 Days" === value) {
      this.start = new Date(new Date().getFullYear(), month, currentDay - 8);
      this.end = new Date(new Date().getFullYear(), month, currentDay - 1);
    }
    if ("Last 1 Day" === value) {
      this.start = new Date(new Date().getFullYear(), month, currentDay - 1);
      this.end = new Date(new Date().getFullYear(), month, currentDay - 1);
    } else if ("Last One Week" === value) {
      this.start = new Date(new Date().getFullYear(), month, currentDay - 7);
      this.end = new Date(new Date().getFullYear(), month, currentDay - 1);
    } else if ("Last One Month" === value) {
      this.start = new Date(new Date().getFullYear(), month, currentDay - 31);
      this.end = new Date(new Date().getFullYear(), month, currentDay - 1);
    } else if ("Last Six Months" === value) {
      this.start = new Date(new Date().getFullYear(), month - 5);
      this.end = new Date(new Date().getFullYear(), month, currentDay - 1);
    } else if ("Last one Year" === value) {
      this.start = new Date(new Date().getFullYear() - 1, month, currentDay);
      this.end = new Date(new Date().getFullYear(), month, currentDay - 1);
    } else {
    }
  }

  reset() {
    this.getAllEmailData(this.mainTopic);
  }
  setSubtopicActive(value, jsonValue) {
    this.subtopic = value;
    this.jsonValue = jsonValue;
    var eventtype = value;
    if (value == "visitor_count") {
      eventtype = "email_clicked";
    }
    var data = {
      channel: "SMS",
      type: [],
      event: eventtype,
      timePeriod: this.filterViewBy,
      moduleId: this.dropDownList?.value?.toString()
        ? this.dropDownList?.value?.toString()
        : null,
    };
    if (this.mainTopic == "campaignsms") {
      data.type = ["CAMPAIGN"];
    } else if (this.mainTopic == "surveysms") {
      data.type = ["SURVEY"];
    } else if (this.mainTopic == "offersms") {
      data.type = ["OFFER"];
    } else if (this.mainTopic == "allsms") {
      data.type = ["OFFER", "CAMPAIGN", "SURVEY"];
    } else {
      data.type = [];
    }

    this.getGraphData(this.startDate, this.endDate, data);
    // this.loadGraph(this.startDate, this.endDate, data);
  }

//   getAllEmailStat(start: string, end: string, subtopic?: string, jsonValue?: string) {
//     this.graphEventLoading = true;

//     // Mocking the API response directly
//     let response = {
//         "message": "SUCCESS",
//         "body": {
//             "deliveredRate": 0.0,
//             "failedRate": 0.0,
//             "totalSMSSent": 0.0
//         }
//     };

//     // Simulating the response handling logic
//     if (response["message"] === "success" || response["message"] === "SUCCESS") {
//         this.catagorySelectData = response["body"];
//         this.setSubtopicActive(
//             subtopic ? subtopic : "sms_analytics_sent",
//             jsonValue ? jsonValue : "totalSMSSent"
//         );
//         this.graphEventLoading = false;
//     } else {
//         this.graphEventLoading = false;
//         this.snackBar.openSnackBar("An error occurred", 2000);
//     }
// }

  getAllEmailStat(start, end, subtopic?, jsonValue?) {
    this.graphEventLoading = true;
    this.apiCall.getAnalyticsAllSMS(start, end).subscribe(
      (response) => {
        if (this.tokenStorage.getIsDemo() === "true")
          response = this.allEmailsStatsDemo;
        if (
          response["message"] == "success" ||
          response["message"] == "SUCCESS"
        ) {
          this.catagorySelectData = response["body"];
          this.setSubtopicActive(
            subtopic ? subtopic : "sms_analytics_sent",
            jsonValue ? jsonValue : "totalSMSSent"
          );
          this.graphEventLoading = false;
        }
      },
      (error) => {
        this.graphEventLoading = false;
        this.snackBar.openSnackBar(error.error.message, 2000);
      }
    );
  }
  getInsightSurveyEmailStat(start, end, subtopic?, jsonValue?) {
    this.graphEventLoading = true;
    this.apiCall
      .getAnalyticsSurveySMS(
        start,
        end,
        this.dropDownList?.value?.toString()
          ? this.dropDownList?.value?.toString()
          : null
      )
      .subscribe(
        (response) => {
          if (
            response["message"] == "success" ||
            response["message"] == "SUCCESS"
          ) {
            this.catagorySelectData = response["body"];
            this.setSubtopicActive(
              subtopic ? subtopic : "sms_analytics_sent",
              jsonValue ? jsonValue : "totalSMSSent"
            );
            this.graphEventLoading = false;
          }
        },
        (error) => {
          this.graphEventLoading = false;
          this.catagorySelectData = null;
          this.snackBar.openSnackBar(error.error.message, 2000);
        }
      );
  }
  getInsightOfferEmailStat(start, end, subtopic?, jsonValue?) {
    this.graphEventLoading = true;
    this.apiCall
      .getAnalyticsOfferSMS(
        start,
        end,
        this.dropDownList?.value?.toString()
          ? this.dropDownList?.value?.toString()
          : null
      )
      .subscribe(
        (response) => {
          if (this.tokenStorage.getIsDemo() === "true")
            response = this.allEmailsStatsDemo;
          if (
            response["message"] == "success" ||
            response["message"] == "SUCCESS"
          ) {
            this.catagorySelectData = response["body"];
            this.setSubtopicActive(
              subtopic ? subtopic : "sms_analytics_sent",
              jsonValue ? jsonValue : "totalSMSSent"
            );
            this.graphEventLoading = false;
          }
        },
        (error) => {
          this.graphEventLoading = false;
          this.catagorySelectData = null;
          this.snackBar.openSnackBar(error.error.message, 2000);
        }
      );
  }
  getInsightCampaignEmailStat(start, end, subtopic?, jsonValue?) {
    this.graphEventLoading = true;
    this.apiCall
      .getAnalyticsCampaignSMS(
        start,
        end,
        this.dropDownList?.value?.toString()
          ? this.dropDownList?.value?.toString()
          : null
      )
      .subscribe(
        (response) => {
          if (this.tokenStorage.getIsDemo() === "true")
            response = this.allEmailsStatsDemo;
          if (
            response["message"] == "success" ||
            response["message"] == "SUCCESS"
          ) {
            this.catagorySelectData = response["body"];
            this.setSubtopicActive(
              subtopic ? subtopic : "sms_analytics_sent",
              jsonValue ? jsonValue : "totalSMSSent"
            );
            this.graphEventLoading = false;
          }
        },
        (error) => {
          this.graphEventLoading = false;
          this.catagorySelectData = null;
          this.snackBar.openSnackBar(error.error.message, 2000);
        }
      );
  }

  getAllEmailData(mainTopic, subtopic?, jsonValue?) {
    this.dropDownList.value = null;
    this.getNameFromEmailSenderList(this.dropDownList.value);
    if (mainTopic == "campaignsms") {
      this.widgetNameForAnalytics = "Campaign SMS";
      this.graphEventLoading = true;
      this.apiCall
        .getAnalyticsCampaignSMS(this.startDate, this.endDate, null)
        .subscribe(
          (response) => {
            if (this.tokenStorage.getIsDemo() === "true")
              response = this.allEmailsStatsDemo;
            if (
              response["message"] == "success" ||
              response["message"] == "SUCCESS"
            ) {
              this.catagorySelectData = response["body"];
              this.setSubtopicActive(
                subtopic ? subtopic : "sms_analytics_sent",
                jsonValue ? jsonValue : "totalSMSSent"
              );
              this.graphEventLoading = false;
            }
          },
          (error) => {
            this.graphEventLoading = false;
            this.catagorySelectData = null;
            this.snackBar.openSnackBar(error.error.message, 2000);
          }
        );
    } else if (mainTopic == "surveysms") {
      this.widgetNameForAnalytics = "Survey SMS";
      this.graphEventLoading = true;
      this.apiCall
        .getAnalyticsSurveySMS(this.startDate, this.endDate, null)
        .subscribe(
          (response) => {
            if (
              response["message"] == "success" ||
              response["message"] == "SUCCESS"
            ) {
              this.catagorySelectData = response["body"];
              this.setSubtopicActive(
                subtopic ? subtopic : "sms_analytics_sent",
                jsonValue ? jsonValue : "totalSMSSent"
              );
              this.graphEventLoading = false;
            }
          },
          (error) => {
            this.graphEventLoading = false;
            this.catagorySelectData = null;
            this.snackBar.openSnackBar(error.error.message, 2000);
          }
        );
    } else if (mainTopic == "offersms") {
      this.widgetNameForAnalytics = "Offer SMS";
      this.graphEventLoading = true;
      this.apiCall
        .getAnalyticsOfferSMS(this.startDate, this.endDate, null)
        .subscribe(
          (response) => {
            if (this.tokenStorage.getIsDemo() === "true")
              response = this.allEmailsStatsDemo;
            if (
              response["message"] == "success" ||
              response["message"] == "SUCCESS"
            ) {
              this.catagorySelectData = response["body"];
              this.setSubtopicActive(
                subtopic ? subtopic : "sms_analytics_sent",
                jsonValue ? jsonValue : "totalSMSSent"
              );
              this.graphEventLoading = false;
            }
          },
          (error) => {
            this.graphEventLoading = false;
            this.catagorySelectData = null;
            this.snackBar.openSnackBar(error.error.message, 2000);
          }
        );
    } else {
      this.widgetNameForAnalytics = "All SMS";
      this.getAllEmailStat(this.startDate, this.endDate, subtopic, jsonValue);
    }
  }

  mainTopicChange(mainTopic, subtopic?, jsonValue?) {
    this.mainTopic = mainTopic;
    this.getModuleList("all");
    this.dropDownList.type = "all";
    this.graphDataLoading = true;
    if (mainTopic == "allsms") {
      this.widgetNameForAnalytics = "All SMS";
      this.getAllEmailStat(this.startDate, this.endDate, subtopic, jsonValue);
    } else if (mainTopic == "campaignsms") {
      this.widgetNameForAnalytics = "Campaign SMS";
      this.getInsightCampaignEmailStat(
        this.startDate,
        this.endDate,
        subtopic,
        jsonValue
      );
    } else if (mainTopic == "surveysms") {
      this.widgetNameForAnalytics = "Survey SMS";
      this.getInsightSurveyEmailStat(
        this.startDate,
        this.endDate,
        subtopic,
        jsonValue
      );
    } else if (mainTopic == "offersms") {
      this.widgetNameForAnalytics = "Offer SMS";
      this.getInsightOfferEmailStat(
        this.startDate,
        this.endDate,
        subtopic,
        jsonValue
      );
    }
  }

  onEndDateChange(event) {
    if (event.value) {
      let start = this.range?.value?.start;
      let end = this.range?.value?.end;
      let span = this.findDaySpan(start, end);
      let dateRange = {
        startDate: start,
        endDate: end,
        daySpan: span,
      };
      if (start && end) this.dateChangeDetected(dateRange);
    }
  }

  findDaySpan(start, end) {
    let span = 0;
    if (start && end) {
      let startDate = new Date(start).getTime();
      let endDate = new Date(end).getTime();
      span = (endDate - startDate) / (1000 * 3600 * 24) + 1;
    }
    return span;
  }

  loadGraph(start, end, data) {
    this.graphDataLoading = true;
    this.graphData = null;
    this.apiCall.getSMSGraphData(start, end, data).subscribe(
      (response) => {
        if (this.tokenStorage.getIsDemo() === "true") {
          for (let i = 6; i > 0; i--) {
            if (
              this.jsonValue === "totalNewOrders" ||
              this.jsonValue === "totalRevenue"
            )
              this.allEmailsGraphDemo["body"][
                this.datePipe.transform(
                  new Date().setMonth(new Date().getMonth() - i),
                  "yyyy-MM-dd"
                )
              ] = this.allEmailsStatsDemo["body"][this.jsonValue] / 6;
            else
              this.allEmailsGraphDemo["body"][
                this.datePipe.transform(
                  new Date().setMonth(new Date().getMonth() - i),
                  "yyyy-MM-dd"
                )
              ] =
                this.allEmailsStatsDemo["body"]["emailStats"][this.jsonValue] /
                6;
          }
          response = this.allEmailsGraphDemo;
        }
        if (
          response["message"] == "success" ||
          response["message"] == "SUCCESS"
        ) {
          if (
            response["body"] != null &&
            Object.keys(response["body"]).length != 0
          ) {
            this.graphDataNull = false;
            let winBackData = response["body"];
            var k = [];
            for (let key in winBackData) {
              k.push({
                name: key.substring(0, 10),
                value: winBackData[key],
                originalDate: key.substring(0, 10),
              });
            }
            this.graphData = k;
            switch (this.filterViewBy) {
              case "month": {
                this.parseDate = d3.timeParse("%Y-%m-%d");
                this.formatTime = d3.timeFormat("%b");
                this.hoverFormat = d3.timeFormat("%b %Y");
                break;
              }
              case "day": {
                this.parseDate = d3.timeParse("%Y-%m-%d");
                this.formatTime = d3.timeFormat("%b %d");
                this.hoverFormat = d3.timeFormat("%d %b %Y");
                break;
              }
              case "year": {
                this.parseDate = d3.timeParse("%Y-%m-%d");
                this.formatTime = d3.timeFormat("%Y");
                this.hoverFormat = d3.timeFormat("%Y");
                break;
              }
              default: {
                this.parseDate = d3.timeParse("%Y-%m-%d");
                this.formatTime = d3.timeFormat("%b %d %Y");
                this.hoverFormat = d3.timeFormat("%b %d %Y");
                break;
              }
            }

            var i, n;
            for (i = 0, n = k.length; i < n; i++) {
              var d = k[i];
              for (var c in d) {
                if (c == "name") {
                  d[c] = this.parseDate(d[c]);
                  d[c] = this.formatTime(d[c]);
                }
                if (c == "originalDate") {
                  d[c] = this.parseDate(d[c]);
                  d[c] = this.hoverFormat(d[c]);
                }
              }
              k[i] = d;
            }

            this.graphDataLoading = false;
          } else {
            this.graphData = null;

            this.graphDataNull = true;
            this.graphDataLoading = false;
            // this.snackBar.openSnackBar("No Data ", 2000);
          }
        }
        this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
      },
      (error) => {
        this.graphData = null;
        this.graphDataNull = true;
        this.graphDataLoading = false;
        this.snackBar.openSnackBar(error.error.message, 2000);
      }
    );
  }
  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }
  hideAllDropdowns(event, exceptionIdForDropdown?, exceptionIdForDownArrow?) {
    const dropdownElements =
      this.elRef.nativeElement.querySelectorAll(".dropdown");
    const downArrowElements =
      this.elRef.nativeElement.querySelectorAll(".down-arrow");
    dropdownElements.forEach((element: HTMLElement) => {
      if (element.id != exceptionIdForDropdown) {
        this.renderer.setStyle(element, "height", "0px");
        this.renderer.setStyle(element, "border", "unset");
      }
    });
    downArrowElements.forEach((element: HTMLElement) => {
      if (element.id != exceptionIdForDownArrow) {
        this.renderer.setStyle(element, "transform", "rotate(0deg)");
      }
    });
  }
  toggleDropdown(event, logExpIndex, compIndex, dropdownName) {
    event.stopPropagation();

    //check if click happened within category-container or within search
    if (
      event.target.parentElement.classList.contains("category-container") ||
      event.target.classList.contains("op-search")
    ) {
      return; //if so dont toggle
    }
    let exceptionIdForDropdown = `${dropdownName}-dropdown${logExpIndex}${compIndex}`;
    let exceptionIdForDownArrow = `down-arrow-${dropdownName}${logExpIndex}${compIndex}`;
    const dropdownElement = this.elRef.nativeElement.querySelector(
      `#${exceptionIdForDropdown}`
    );
    const keyboardDownIconElement = this.elRef.nativeElement.querySelector(
      `#${exceptionIdForDownArrow}`
    );
    this.hideAllDropdowns(
      event,
      exceptionIdForDropdown,
      exceptionIdForDownArrow
    );
    if (dropdownElement.style.height === "180px") {
      this.renderer.setStyle(dropdownElement, "height", "0px");
      this.renderer.setStyle(dropdownElement, "border", "unset");
      this.renderer.setStyle(
        keyboardDownIconElement,
        "transform",
        "rotate(0deg)"
      );
      this.op2ListSearch = "";
    } else {
      this.renderer.setStyle(dropdownElement, "z-index", "10");
      this.renderer.setStyle(dropdownElement, "height", "180px");
      this.renderer.setStyle(dropdownElement, "border", "1px solid #636466");
      this.renderer.setStyle(
        keyboardDownIconElement,
        "transform",
        "rotate(180deg)"
      );
    }
  }
  getModuleList(status?) {
    this.dropdownListLoading = true;
    this.apiCall.getModuleList(status).subscribe(
      (response) => {
        console.log(response);
        switch (status) {
          case "all":
            if (this.mainTopic == "campaignsms") {
              // this.apiCampaignList = response['body']['CAMPAIGNLIST'];
              this.apiList = response["body"]["CAMPAIGNLIST"];
            } else if (this.mainTopic == "offersms") {
              // this.apiOfferList = response['body']['OFFERLIST'];
              this.apiList = response["body"]["OFFERLIST"];
            } else if (this.mainTopic == "surveysms") {
              // this.apiSurveyList = response['body']['SURVEYLIST'];
              this.apiList = response["body"]["SURVEYLIST"];
            }
          case "active":
            if (this.mainTopic == "campaignsms") {
              // this.apiActiveCampaignList = response['body']['CAMPAIGNLIST'];
              this.apiList = response["body"]["CAMPAIGNLIST"];
            } else if (this.mainTopic == "offersms") {
              // this.apiActiveOfferList = response['body']['OFFERLIST'];
              this.apiList = response["body"]["OFFERLIST"];
            } else if (this.mainTopic == "surveysms") {
              // this.apiActiveSurveyList = response['body']['SURVEYLIST'];
              this.apiList = response["body"]["SURVEYLIST"];
            }

          case "ended":
            if (this.mainTopic == "campaignsms") {
              // this.apiEndedCampaignList = response['body']['CAMPAIGNLIST'];
              this.apiList = response["body"]["CAMPAIGNLIST"];
            } else if (this.mainTopic == "offersms") {
              // this.apiEndedOfferList = response['body']['OFFERLIST'];
              this.apiList = response["body"]["OFFERLIST"];
            } else if (this.mainTopic == "surveysms") {
              // this.apiEndedSurveyList = response['body']['SURVEYLIST'];
              this.apiList = response["body"]["SURVEYLIST"];
            }
        }
        this.dropdownListLoading = false;
      },
      (error) => {
        this.snackBar.openSnackBar("Error fetching sms senders data", 3000);
      }
    );
  }

  searchFiltered(list): any[] {
    if (!this.op2ListSearch) {
      return list;
    }
    const searchTerm = this.op2ListSearch.trim().toLowerCase();
    return list.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm);
    });
  }
  handleCategoryClick(category: string) {
    let status: string;

    // Determine the status based on the clicked category
    if (category === "all") {
      status = "all";
    } else if (category === "active") {
      status = "active";
    } else if (category === "ended") {
      status = "ended";
    }
    this.getModuleList(status);
  }
  getNameFromEmailSenderList(id) {
    if (id == null) {
      return this.getListType(this.mainTopic);
    } else {
      let item = this.apiList.find((item) => item.id == id);
      if (item) {
        return item.name;
      }
    }
  }

  getListType(type) {
    switch (type) {
      case "campaignsms":
        return "All Journeys";
      case "offersms":
        return "All Offers";
      case "surveysms":
        return "All Surveys";
    }
  }

  getDropDownType(type) {
    switch (type) {
      case "campaignsms":
        return "Selected Journeys";
      case "offersms":
        return "Selected Offer";
      case "surveysms":
        return "Selected Survey";
    }
  }
  stringInputHandler(event: KeyboardEvent, maxLength) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  setAttribute(attributeType: string, attributeValue, dropDownList) {
    if (attributeType === "CATEGORY") {
      this.getModuleList(attributeValue);
      dropDownList.type = attributeValue;
      this.dropDownList.value = null;
    } else if (attributeType === "VALUE") {
      dropDownList.value = attributeValue;
      this.mainTopicChange(this.mainTopic, this.subtopic, this.jsonValue);
    }
  }
  getDisplayName(value) {
    switch (value) {
      case "all":
        return "All";
      case "active":
        return "Active";
      case "ended":
        return "Ended";
    }
  }

  selectCategory(category: string, dropDownList) {
    // Implement logic to load items based on the selected category
    // For example:
    switch (category) {
      case "all":
        if (this.mainTopic == "campaignsms") {
          this.apiList = this.apiCampaignList;
          this.dropDownList.value = this.apiCampaignList[0].id;
        } else if (this.mainTopic == "offersms") {
          this.apiList = this.apiOfferList;
          this.dropDownList.value = this.apiOfferList[0].id;
        } else if (this.mainTopic == "surveysms") {
          this.apiList = this.apiSurveyList;
          this.dropDownList.value = this.apiSurveyList[0].id;
        }
      case "active":
        if (this.mainTopic == "campaignsms") {
          this.apiList = this.apiActiveCampaignList;
        } else if (this.mainTopic == "offersms") {
          this.apiList = this.apiActiveOfferList;
        } else if (this.mainTopic == "surveysms") {
          this.apiList = this.apiActiveSurveyList;
        }

      case "ended":
        if (this.mainTopic == "campaignsms") {
          this.apiList = this.apiEndedCampaignList;
        } else if (this.mainTopic == "offersms") {
          this.apiList = this.apiEndedOfferList;
        } else if (this.mainTopic == "surveysms") {
          this.apiList = this.apiEndedSurveyList;
        }
    }
  }

  getGraphData(start, end, data){
    console.log(start, end, data);
    Object.keys(this.chartConfigurations).map((key)=>{
      this.apiCall.getSMSGraphData(start, end, data, key).subscribe((response)=>{
        console.log(key);
        console.log(response);
        if(response['body']){
          this.createGraph(response['body'], key)

        }
      })
    })
  }

  createGraph(data, event){
    let x = [], y = [];
    let type = this.filterViewBy;

    x = Object.keys(data);
    y = Object.values(data); 
    if(x.length > 0 && y.length > 0){
      this.chartConfigurations[event] = {
        xAxis: {
          data: x,
          name: type.toUpperCase(),
          nameLocation: "center",
          nameGap: 30,
          axisLabel: {
            formatter: function (value, index) {
              if (value) {
                  let [year, month, day] = value.split("-");
  
                  // Convert numeric month to three-letter abbreviation
                  let monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  let monthAbbreviation =
                    monthNames[parseInt(month, 10) - 1];
                if (type.toLowerCase() === "month") {
                  return `${monthAbbreviation}-${year}`;
                } else if (type.toLowerCase() === "day") {
                  return `${day} ${monthAbbreviation} ${year}`;
                } else if (type.toLowerCase() === "year") {
                  return `${year}`;
                }
              } else {
                return null;
              }
            },
          },
        },
        yAxis: {
          type: "value",
          name: this.getYAxisData(event),
          nameLocation: "center",
          axisLabel: {
            formatter: function (value) {
              if (value >= 1e9) {
                return value / 1e9 + "B";
              }
              if (value >= 1e6) {
                return value / 1e6 + "M";
              }
              if (value >= 1e3) {
                return value / 1e3 + "k";
              }
              return value;
            },
          },
          nameGap: 40,
        },
        tooltip: {
          // formatter: function (params) {
          //   const dataIndex = params.dataIndex;
          //   return `Avg. Revenue: ${currencySymbol} ${data[dataIndex]["avg_monetary"].toFixed(2)} <br/> Total Customers: ${data[dataIndex]["customer_count"]} <br/> Total Revenue: ${currencySymbol} ${data[dataIndex]["total_monetary"].toFixed(2)}`; // Customize the tooltip content
          // },
        },
        series: [
          {
            name: "Recency",
            type: "bar",
            data: y,
            itemStyle: {
              color: "#2C7DA0",
            },
          },
        ],
      };
    }
    else{
      this.chartConfigurations[event] = {
        graphic: {
          elements: [
            {
              type: "text",
              left: "center",
              top: "10%",
              style: {
                text: "No Records Found",
                font: "18px Arial, sans-serif",
                fill: "#202224",
              },
            },
            {
              type: "image",
              left: "center",  // Horizontal alignment
              top: "center",   // Vertical alignment
              z: 0,            // Z-order for layering (optional)
              style: {
                image: 'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg', // Path to your image
                width: 350,     // Image width
                height: 300,    // Image height
                opacity: 0.8,   // Optional: Set the opacity
              },
            },
          ],
        },
      };
    }
  }

  getYAxisData(value){
    switch (value) {
      case "email_sent_count":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "No. of Emails Sent",
          ["POS_PAGE"]
        );
        break;
      case "email_delivered":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "No.of Emails Delivered",
          ["POS_PAGE"]
        );
        break;
      case "email_opened":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "No.of Emails Opened",
          ["POS_PAGE"]
        );
        break;
      case "email_complained":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "Number of Complaints",
          ["POS_PAGE"]
        );
        break;
      case "email_clicked":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "No.of Emails Clicked",
          ["POS_PAGE"]
        );
        break;
      case "email_failed":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "Number of Emails Failed to Send",
          ["POS_PAGE"]
        );
        break;
      case "email_unsubscribed":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "Number of Unsubscribes",
          ["POS_PAGE"]
        );
        break;
      case "email_clicked_add_cart":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "No.of Cart Additions ",
          ["POS_PAGE"]
        );
        break;
      case "visitor_count":
        this.YLegend = this._i18nDynamicTranslate.transform("No. of Visitors", [
          "POS_PAGE",
        ]);
        break;
      case "order_count":
        this.YLegend = this._i18nDynamicTranslate.transform("No. of Orders", [
          "POS_PAGE",
        ]);
        break;
      case "order_revenue":
        this.YLegend =
          this._i18nDynamicTranslate.transform("Revenue", ["POS_PAGE"]) +
          "(" +
          this.currencySymbol +
          ")";
        break;
      case "survey_count":
        this.YLegend = this._i18nDynamicTranslate.transform(
          "No.of survey responses",
          ["POS_PAGE"]
        );
        break;
      default:
        this.YLegend = null;
        break;
    }

    return this.YLegend;
  }
  

}
