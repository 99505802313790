import STATIC_PAGE from "./static.json";
import SHARED_PAGE from "./shared.json";
import POS_PAGE from "./pos.json";

const EN = {
    STATIC_PAGE: STATIC_PAGE,
    SHARED_PAGE : SHARED_PAGE,
    POS_PAGE: POS_PAGE 
}

export default EN;