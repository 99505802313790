import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkIfReportingView'
})
export class checkIfReportingView implements PipeTransform {

  transform(authorities): any {
    let index = authorities?.indexOf('ROLE_REPORTING_VIEW');
    if(index > -1){
        return true;
    }
    else{
        return false;
    }
  }
}