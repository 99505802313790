<div class="qb-main-conatiner">
    <div class="qb-d-flex qb-jc-space-between">

        <div class="qb-mr-24"><img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/Rectangle+2037.svg"
                alt=""></div>
        <div>
            <div class="qb-d-flex qb-jc-space-between qb-ai-center qb-mb-14">
                <div class="qb-gf-intro-text qb-heading-font qb-w-60" >Reach out to all your customers through Journeys</div>
                <div class="primary-button" (click)="createNewCampaign()">Create New Journey</div>
            </div>
            <!-- <div class="qb-gf-intro-text qb-mb-10">Your possibilities to increase engagement and sales are endless with
                <span class="qb-q-color">Qubriux Gamification!</span>
            </div> -->
            <br>
            <div class="qb-gf-intro-text qb-mb-10" >
                Marketing automation helps you run automated journeys for ideal customer engagement with minimum effort.
            </div>

            <!-- <div class="qb-gf-intro-text">Recommend the most relevant products to your audience. In turn, generate more
                quality leads and
                increase sales. </div> -->
            <br>
            <!-- <div class="qb-gf-italics">You can learn more about Qubriux's Gamification feature from our <a
                    class="qb-knowledge-gf" href="https://qubriux.skellam.ai/knowledge-hub/gamify" target="_blank">knowledge hub</a>.</div> -->
        </div>
    </div>



    <div class="qb-how-to-create">
        <div class="qb-gf-intro-text qb-gf-intro-text-position" >How to create a Journey</div>
        <div class="qb-d-flex qb-jc-space-between qb-w-100">

            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep1q.svg"
                        alt=""></div>
                <div class="qb-ta-center" style="margin-top: 45px;">
                    Select a preset template or create a new workflow.
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep1q.svg"
                        alt=""></div>
                <div class="qb-ta-center" style="margin-top: 45px;">
                    Choose the trigger that will initiate the campaign.
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        class="qb-p-10"
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep2q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    Select the conditions and actions for your campaign.
                </div>
            </div>



            <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep5q.svg"
                        alt=""></div>
                <div class="qb-ta-center" style="margin-top: 35px;">
                    Schedule the campaign to go live.
                </div>
            </div>



            <!-- <div class="qb-img-container">
                <div class="qb-img-sub-conatiner"> <img
                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/vectorsgamestep5q.svg"
                        alt=""></div>
                <div class="qb-ta-center">
                    Schedule and enable 
                    the game for your
                    customers
                </div>
            </div> -->



        </div>
    </div>