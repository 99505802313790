import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
interface KeywordData{
  'subject':string,
  'keyword':any
}
@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.css']
})
export class KeywordComponent implements OnInit {

  constructor(public dialog: MatDialogRef<KeywordComponent>,
    @Inject(MAT_DIALOG_DATA) public data:KeywordData,) { }

  ngOnInit() {
  }
  submit(decision){
    this.dialog.close(decision)
  }

}
