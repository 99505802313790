import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-exclude-product-dialog',
  templateUrl: './exclude-product-dialog.component.html',
  styleUrls: ['./exclude-product-dialog.component.css']
})
export class ExcludeProductDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<ExcludeProductDialogComponent>,
    private apicall: ApiCallService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  excludedCategory=[];
  apiloading=false;
  check=false;
  productList=[];
  // CategoryProductList=[];
  CategoryProductList = new Map()
  ngOnInit() {
    this.apiloading=true
    this.apicall.getExcludedCategories(this.data['gameId'],true).subscribe((response)=>{
      this.excludedCategory=response['body']
      this.apiloading=false;
    })
  }
  submit(decision){
    this.dialog.close(decision)
  }
  parentArrowNonCategory(i,id) {
      document.getElementById("variant-section-" + i).classList.toggle("active"); // toggle variant section
      this.productLists(id);
  }
  getDataForTable($event: any) {
    $event.target.classList.toggle("active"); // toggle variant section
    // get data for table since we are opening the div to show the body
  }
  productLists(id){
    this.apicall.getExcludedProducts(this.data['gameId'],true,id).subscribe((response)=>{
      this.productList=response['body']
      this.CategoryProductList.set((id),this.productList)
    })
  }
}
