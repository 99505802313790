<div class="qb-sms-creator-container">

  <div class="qb-sms-send">
    <button class="qb-send-btn primary-button" (click)="SendSMSConsent()">{{'COMMUNICATION_PAGE.SEND' | i18nTranslate}}</button>
  </div>
  <div class="qb-top-container">
    <div class="qb-text-box qb-pb-100-px">
      <!-- <div style="margin-bottom: 10px">
        <span style="font-size: 13px; font-weight: 600">Template Name</span>
        <div
          class="qb-formDiv"
          [ngClass]="{ error: templateName.length === 0 && submit }"
        >
          <input
            (keypress)="templateNameKeyPress($event)"
            (paste)="templateNamePaste($event)"
            class="qb-formInput"
            type="text"
            [(ngModel)]="templateName"
          />
          <span class="qb-template-name-text-limiter"
            >{{ templateName.length
            }}<span style="color: #cccccc">/50</span></span
          >
        </div>
      </div> -->
      <div class="qb-mb-25">
        <mat-radio-group [(ngModel)]="reminderStatus" (change)="changeTemplete()" class="qb-d-inline-flex">
          <mat-radio-button value="0" color="primary">{{'COMMUNICATION_PAGE.FIRST_TIME_CONSENT' | i18nTranslate}}</mat-radio-button>
          <mat-radio-button value="1" color="primary">{{'COMMUNICATION_PAGE.CONSENT_SMS_REMINDER' | i18nTranslate}}<div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContent57">
            <ng-template #popContent57>
              <div class="qb-message-text">
                <div class="qb-d-inline-flex">{{'COMMUNICATION_PAGE.CONSENT_REMINDER_TEXT' | i18nTranslate}}</div>
              </div>
            </ng-template>
            <div class="qb-i">i</div>
          </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="qb-sms-span-extreme">
        <span class="qb-fs-12">{{'COMMUNICATION_PAGE.SMS_TEMPLATE' | i18nTranslate}}</span>
      </div>
      <div class="qb-formDivSchedule qb-w-100 cursor-pointer"
      (click)="selectopenclose('customerTempleteSelect')">
      <mat-select #customerTempleteSelect [(ngModel)]="tname" (selectionChange)="changeTempleteSelection()"  class="qb-fontExo2"
        color="primary" placeholder="Select SMS Template" 
        disableOptionCentering panelclass="qb-myPanelClass">
        <div class="qb-matSelectDropdowns">
          <!-- <mat-option *ngFor="
              let segment of allcustomerObj
                | labelPipe
                  : tempsegmentFilter
                  : 'name'" [value]="segment.id" [disabled]="disableAllCustomerSelection" class="qb-fontExo2" [value]="segment.id">
            {{ segment.name }}
          </mat-option> -->

          <mat-option class="qb-fontExo2" *ngFor="
              let templete of templetes
            " [value]="templete">
            {{ templete.name }}
          </mat-option>
        </div>
      </mat-select>
      <div class="qb-keyboard-down-icon-container qb-downward-arrow" id="keyboard-down-icon-container"
       (click)="selectopenclose('customerTempleteSelect')">
        <mat-icon class="
            qb-keyboard-down-icon
            mat-icon
            notranslate
            material-icons
            mat-icon-no-color
          " role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
      </div>
    </div>
    <span class="qb-error-text" *ngIf="submitted == true && charCount ==0">{{'COMMUNICATION_PAGE.PLEASE_SELECT_ANY_ONE' | i18nTranslate}}</span>
      <div class="qb-sms-span-extreme qb-mt-25">
        <span class="qb-fs-12">{{'COMMUNICATION_PAGE.SMS_DETAILS' | i18nTranslate}}</span>
        <span class="qb-fs-9">{{'COMMUNICATION_PAGE.SMS_DETAILS_TEXT' | i18nTranslate}}</span>
      </div>

      <div class="qb-text-container qb-not-allowed">
        <!-- <textarea class="qb-textarea" name="smsText" id="smsText" cols="20" rows="8" [(ngModel)]="smsText"
          [ngClass]="{ error: smsText.length === 0 && submit }" (focusout)="updateMeasurementPointComment($event)"
          (keyup)="keypresshandler($event)" (paste)="onPaste($event)"></textarea> -->
        <!-- <span class="qb-smiley-container" (click)="toggled = !toggled" [(emojiPickerIf)]="toggled"
          [emojiPickerDirection]="'left'" (emojiPickerSelect)="handleSelection($event)">😄</span> -->
          <div class="qb-textarea" cols="20" rows="8" name="smsText">
            {{smsText}}
          </div>
      </div>
      <div class="qb-char-limit-text">
        {{'COMMUNICATION_PAGE.CHARACTER_LEFT' | i18nTranslate}} <span><span class="qb-char-count-text">{{ charCount }}</span>/160</span>
      </div>
      <div class="qb-sms-options-div">
        <!-- <div>
          <mat-checkbox
            [(ngModel)]="optOutText"
            color="primary"
            (change)="optoutValueChange($event)"
            >Add opt-out text</mat-checkbox
          >
        </div> -->
        <!-- <div style="display: flex; align-items: center">
          <span>Add merge tags</span>
          <div style="margin-left: 12px">
            <div class="qb-multi-select-panel" id="multi-select-panel" (click)="
                toggleSelect(
                  $event,
                  'multi-select-dropdown',
                  'segment-select',
                  'multi-select-panel',
                  'keyboard-down-icon-container'
                )
              ">
              <span>Select merge tag</span>
              <div class="qb-keyboard-down-icon-container" id="keyboard-down-icon-container">
                <mat-icon class="qb-keyboard-down-icon">keyboard_arrow_down</mat-icon>
              </div>
            </div>
            <div style="position: relative">
              <div class="qb-multi-select-dropdown" id="multi-select-dropdown">
                <div class="qb-search">
                  <mat-icon class="qb-group-icon">search</mat-icon>
                  <input [(ngModel)]="mergetagFilter" placeholder="Search merge tag" type="text"
                    style="border: none; outline: none" />
                </div>
                <div class="qb-border-line"></div>
                <div id="segment-select" class="qb-segment-select">
                  <div>
                    <div class="qb-tag-element" (click)="
                        addMergeTag(
                          tags['value'],
                          'multi-select-dropdown',
                          'segment-select',
                          'multi-select-panel',
                          'keyboard-down-icon-container'
                        )
                      " *ngFor="
                        let tags of mergeTags | labelPipe: mergetagFilter:'name'
                      ">
                      <span>{{ tags["name"] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="qb-sms-options-div" style="height: 35px">
        <mat-checkbox [(ngModel)]="insertLink" color="primary">Insert Link</mat-checkbox>
        <div style="width: 70%; display: flex; justify-content: space-between" *ngIf="insertLink">
          <div style="width: 100%" class="qb-formDiv">
            <input class="qb-formInput" type="text" [(ngModel)]="linkURL" />
          </div>
          <div class="qb-link-add-button-container">
            <span class="qb-link-add-button" (click)="addLink()">Add</span>
          </div>
        </div>
      </div>
      <div class="qb-sms-options-div">
        <mat-checkbox [(ngModel)]="shortenUrl" color="primary">Shorten URL</mat-checkbox>
      </div> -->





      <div class="qb-customer-segment-container">
        <div class="qb-label-text">
          <span>{{'COMMUNICATION_PAGE.CUSTOMER_SEGMENTS' | i18nTranslate}}</span>
        </div>
        <div class="qb-formDivSchedule qb-w-100 cursor-pointer"
          (click)="selectopenclose('customerSegmentsSelect')">
          <mat-select #customerSegmentsSelect (selectionChange)="changeSegmentSelection()"  class="qb-fontExo2"
            color="primary" placeholder="Select customer segment" [(ngModel)]="dispatchcustomerSegments" multiple
            >
            <div class="qb-Rectangle">
              <div class="qb-search-container">
                <mat-icon class="qb-search-icon-position" matPrefix>search
                </mat-icon>
                <input class="qb-input-text" type="text" [(ngModel)]="tempsegmentFilter"
                  placeholder=" Search by name " />
              </div>
            </div>
            <div class="qb-matSelectDropdowns">
              <mat-option *ngFor="
                  let segment of allcustomerObj
                    | labelPipe
                      : tempsegmentFilter
                      : 'name'" [value]="segment.id" [disabled]="disableAllCustomerSelection" class="qb-fontExo2" [value]="segment.id">
                {{ segment.name }}
              </mat-option>

              <mat-option class="qb-fontExo2" *ngFor="
                  let segment of segments
                    | labelPipe
                      : tempsegmentFilter

                      : 'name'
                " [value]="segment.id" [disabled]="disableCustomerSegmentSelection || !segment['isActive']"
                [matTooltip]="
                  segment['isActive']
                    ? ''
                    : 'We are preparing your segment. It will be available for use shortly'
                ">
                {{ segment.name }}
              </mat-option>
            </div>
          </mat-select>
          <div class="qb-keyboard-down-icon-container qb-downward-arrow" id="keyboard-down-icon-container"
           (click)="selectopenclose('customerSegmentsSelect')">
            <mat-icon class="
                qb-keyboard-down-icon
                mat-icon
                notranslate
                material-icons
                mat-icon-no-color
              " role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <span class="qb-error-text" *ngIf="submitted == true && segmentLength == false">{{'COMMUNICATION_PAGE.PLEASE_SELECT_ANY_ONE_SEGMENT' | i18nTranslate}}</span>
      </div>
      <div class="qb-total-customer-margin">{{'COMMUNICATION_PAGE.TOTAL_CUSTOMER_TARGETED' | i18nTranslate}} - <span *ngIf="reminderStatus=='0'">{{FirstTimeCustomer}}</span><span *ngIf="reminderStatus=='1'">{{ReminderCustomer}}</span>
        <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
        [ngbPopover]="popContent56">
        <ng-template #popContent56>
          <div class="qb-message-text">
            <div class="qb-d-inline-flex"><div class="qb-min-w-325-px">{{'COMMUNICATION_PAGE.TOTAL_CUSTOMER_IN_SEGMENT' | i18nTranslate}}</div><div>: {{totalCustomer}}</div></div>
            <div class="qb-d-inline-flex"><div class="qb-min-w-325-px">{{'COMMUNICATION_PAGE.CUSTOMER_OUTSIDE_USA' | i18nTranslate}}</div><div>: {{NotAllowedCustomer}}</div></div>
            <div class="qb-d-inline-flex"><div class="qb-min-w-325-px"> {{'COMMUNICATION_PAGE.CONSENT_ALREADY_SENT' | i18nTranslate}}</div><div>: {{ConsentAlreadySent}}</div></div>
            <div class="qb-d-inline-flex"><div class="qb-min-w-325-px">{{'COMMUNICATION_PAGE.CUSTOMER_INCORRECT_PHONE_NUMBER' | i18nTranslate}}</div><div>: {{InvalidCustomer}}</div></div>
            <div *ngIf="reminderStatus=='0'" class="qb-d-inline-flex"><div class="qb-min-w-325-px"> {{'COMMUNICATION_PAGE.CONSENT_WILL_BE_SENT' | i18nTranslate}}</div><div>: {{FirstTimeCustomer}}</div></div>
            <div *ngIf="reminderStatus=='1'" class="qb-d-inline-flex"><div class="qb-min-w-325-px"> {{'COMMUNICATION_PAGE.CONSENT_WILL_BE_SENT' | i18nTranslate}}</div><div>: {{ReminderCustomer}}</div></div>
          </div>
        </ng-template>
        <div class="qb-i">i</div>
      </div>
      </div>
      <!-- <div class="qb-reminder-text" *ngIf="segmentLength && FirstTimeCustomer==0 && reminderStatus=='0' && ReminderCustomer > 0">
        This segment has customers who already have received the reminder sms. Consent SMS reminder cannot be sent to these customers.
      </div> -->
      <!-- <div style="color: red;" *ngIf="segmentLength && ReminderCustomer==0 && reminderStatus=='1'">
        No sms would be send as targeted customer in this segment is 0
      </div> -->

      <div class="qb-reminder-container">
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && reminderStatus=='1'&& totalCustomer == 0">{{'COMMUNICATION_PAGE.SEGMENT_SELECTED_NO_CUSTOMER' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && reminderStatus=='0'&& totalCustomer == 0">{{'COMMUNICATION_PAGE.SEGMENT_SELECTED_NO_CUSTOMER' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && reminderStatus=='0'&& totalCustomer == InvalidCustomer && totalCustomer>0">{{'COMMUNICATION_PAGE.SEGMENT_NO_VALID_CUSTOMER' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && reminderStatus=='1'&& totalCustomer == InvalidCustomer && totalCustomer>0">{{'COMMUNICATION_PAGE.SEGMENT_NO_VALID_CUSTOMER' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && reminderStatus=='0'&& customerCount == ConsentGivenCount && ConsentGivenCount>0">{{'COMMUNICATION_PAGE.SEGMENT_WHO_ALREADY_GAVE_CONSENT' | i18nTranslate'}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && reminderStatus=='1'&& customerCount == ConsentGivenCount && ConsentGivenCount>0">{{'COMMUNICATION_PAGE.SEGMENT_WHO_ALREADY_GAVE_CONSENT' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && secondreminderCount !=0 && reminderStatus=='1'&& ReminderCustomer > 0">{{'COMMUNICATION_PAGE.SEGEMNT_HAVE_FEW_CUSTOMER_WHO_GAVE_CONSENT' | i18nTranslate}}</div>
        <!-- <div class="qb-reminder-text" *ngIf="segmentLength && isAllCustomer && secondreminderCount !=0 && reminderStatus=='1'&& ReminderCustomer == 0">This segment has customers who already have received the reminder sms. Consent SMS reminder cannot be sent to these customers.</div> -->
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && secondreminderCount !=0 && reminderStatus=='1'&& ReminderCustomer == 0">{{'COMMUNICATION_PAGE.SEGMENT_WHO_RECIEVED_REMINDER_SMS' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && secondreminderCount !=0 && reminderStatus=='0'&& ReminderCustomer == 0 && FirstTimeCustomer == 0">{{'COMMUNICATION_PAGE.SEGMENT_NOT_RESPONDED-TO_REMINDER_AND_FIRST' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && ReminderCustomer !=0 && reminderStatus=='0'">{{'COMMUNICATION_PAGE.SEGMENT_EARLIER_RECIEVED_CONSENT_SMS_NO_RESPONSE' | i18nTranslate}} </div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && ReminderCustomer !=0 && reminderStatus=='0'">{{'COMMUNICATION_PAGE.PLEASE_SELECT_THE' | i18nTranslate}} <b>{{'COMMUNICATION_PAGE.CONSENT_SMS_REMINDER' | i18nTranslate}}</b> {{'COMMUNICATION_PAGE.OPTION_TO_SEND_REMINDER_TO_SEND' | i18nTranslate}} </div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && FirstTimeCustomer !=0 && reminderStatus=='1'">{{'COMMUNICATION_PAGE.SEGMENT_CUSTOMER_NOT_SENT_FIRST_TIME_CONSNT' | i18nTranslate}}</div>
        <div class="qb-reminder-text" *ngIf="segmentLength && apiloading && FirstTimeCustomer !=0 && reminderStatus=='1'">{{'COMMUNICATION_PAGE.PLEASE_SELECT_THE' | i18nTranslate}} <b>{{'COMMUNICATION_PAGE.FIRST_TIME_CONSENT' | i18nTranslate}}</b> {{'COMMUNICATION_PAGE.OPTION_TO_SEND_SMS' | i18nTranslate}}</div>
     
      </div>
    </div>
    <div class="qb-preview-box">
      <div class="qb-phone-image">
        <div class="qb-text-container-div">
          <div class="qb-preview-text-content">
            <span class="qb-preview-text-container">{{
              previewText
              }}</span>
            <span *ngIf="optOutText">{{'COMMUNICATION_PAGE.REPLY_STOP_OPT_OUT' | i18nTranslate}}</span>
            <div class="qb-preview-text-content-after"></div>
          </div>
        </div>
      </div>
      <!-- <span class="qb-save-button" (click)="saveSms()">Save SMS</span> -->
    </div>
  </div>

</div>