import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-nav',
  templateUrl: './email-nav.component.html',
  styleUrls: ['./email-nav.component.css']
})
export class EmailNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
