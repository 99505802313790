<div class="qb-h-100">
    <div class="activity-spinner-container" *ngIf="dataLoading">
        <mat-spinner diameter="45"></mat-spinner>
    </div>
    <div class="qb-analytics-main-container" *ngIf="!dataLoading">
        <div class="qb-analytics-main-sub-container">
            <app-breadcrumb *ngIf="selectedTab !== 'customerInsights'"></app-breadcrumb>
            <div class="graph-filters" *ngIf="selectedTab !== 'weightedMean' && selectedTab !== 'customerInsights'">
                <div class="qb-each-filter">
                    <h6 class="graph-options-text">View By:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="timeFrame" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                            <mat-option *ngFor="let val of totalCustomerRevenueFrameList" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-each-filter" *ngIf="selectedTab === 'topCustomer'  && (availableOrderType.length !== 1 && availableOrderType[0].toLowerCase() !== 'unknown')">
                    <h6 class="graph-options-text">Selected Type:</h6>

                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="selectedType" (selectionChange)="typeChangeHandler()">
                            <mat-option *ngFor="let val of availableOrderType" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div *ngIf="selectedTab === 'topCustomer'">
                    <div class="qb-each-filter" *ngIf="availableMonths.length > 0">
                        <h6 class="graph-options-text">Selected {{timeFrame}}:</h6>
                        <div class="matSelect-filters">
                            <mat-select class="margin-right-10" [(ngModel)]="selectedMonth" (selectionChange)="monthChangeHandler()">
                                <mat-option *ngFor="let val of availableMonths" [value]="val" selected>{{convertToMonthName(val)}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="selectedTab === 'topCustomer'">
                    <div class="graph-filters" *ngIf="availableMonths.length > 0">
                    </div>
                </div> -->
            </div>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'churnRate'">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Churn Rate Trend<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup1"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('churm_rate')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>
                    
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            Track customer loyalty with our Churn Rate Over Time graph. Visualize the evolution of churn rates, empowering you to make informed decisions for customer retention and business growth
                        </div>
                    </ng-template>
                    <div class="qb-d-flex">
                        <div class="qb-d-flex" style="flex-direction: column; justify-content: center; gap: 5px;">
                            <span class="qb-i-div qb-i-y-axis"
                                    popoverclass="qb-my-custom-class"
                                    placement="auto"
                                    [ngbPopover]="popTierChurnRate"
                                    triggers="mouseenter:mouseleave"
                                    >i</span>
                            <div class="customer-evolution-graph-ylabel" style="margin-left: 5px;">
                                <span>
                                    Churn Rate %
                                </span>
                            </div>
                            <ng-template #popTierChurnRate>
                                <div class="qb-ng-template-text">
                                    <!-- <div class="fraction">
                                        <span class="numerator">Customers Discontinued During Period</span>
                                        <span class="denominator">Total Customers</span>
                                    </div> -->
                                    The percentage of customers who discontinued after this period, calculated as (Number of Customers Discontinued after this period / Total Customers of this period).
                                </div>
                            </ng-template>
                        </div>
                        <div echarts (chartClick)="onChartClick($event, 'churnchartCustomerList')" [options]="customerChumRateChart" style="height: 400px; width: 100%;"></div>               
                    </div>
                </div>
            </div>
            
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'repeatCustomer'">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Repeat Rate Trend<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup2"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('customer_repeat_trend')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>
                    <ng-template #popTierIamgeSetup2>
                        <div class="qb-ng-template-text">
                            Follow customer loyalty trends over time with our Repeat Customer Trend graph. Gain insights into customer retention and engagement, guiding strategic decisions for sustained business growth
                        </div>
                    </ng-template>
                    <div class="qb-d-flex">
                        <div class="qb-d-flex" style="flex-direction: column; justify-content: center; gap: 5px;">
                            <span class="qb-i-div qb-i-y-axis"
                                    popoverclass="qb-my-custom-class"
                                    placement="auto"
                                    [ngbPopover]="popTierRepeat"
                                    triggers="mouseenter:mouseleave"
                                    >i</span>
                            <div class="customer-evolution-graph-ylabel" style="margin-left: 5px;">
                                <span>
                                    Repeat Customer Rate %
                                </span>
                            </div>
                            <ng-template #popTierRepeat>
                                <div class="qb-ng-template-text">
                                    <!-- <div class="fraction">
                                        <span class="numerator">Customers Continued During Period</span>
                                        <span class="denominator">Total Customers</span>
                                    </div> -->
                                    The percentage of customers who continued after this period, calculated as (Number of Customers Continued after this period / Total Customers of this period).
                                </div>
                            </ng-template>
                        </div>
                        <div echarts (chartClick)="onChartClick($event, 'repeatCustomerTrendCustomerList')" [options]="trendOfRepeatChart" style="height: 400px; width: 100%;"></div>               
                    </div>
                </div>
            </div>
            
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'newCustomer'">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">New Customer Rate Trend<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup3"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('new_customer_rate')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>  
                    <ng-template #popTierIamgeSetup3>
                        <div class="qb-ng-template-text">
                            Monitor new customer growth with our Line Graph. Time unfolds on the x-axis, while the y-axis captures the rate of new customer acquisitions. A visual insight for strategic business expansion
                        </div>
                    </ng-template>
                    <div class="qb-d-flex">
                        <div class="qb-d-flex" style="flex-direction: column; justify-content: center; gap: 5px;">
                            <span class="qb-i-div qb-i-y-axis"
                                    popoverclass="qb-my-custom-class"
                                    placement="auto"
                                    [ngbPopover]="popTierNewCustomer"
                                    triggers="mouseenter:mouseleave"
                                    >i</span>
                            <div class="customer-evolution-graph-ylabel" style="margin-left: 5px;">
                                <span>
                                    New Customer Rate %
                                </span>
                            </div>
                            <ng-template #popTierNewCustomer>
                                <div class="qb-ng-template-text">
                                    <!-- <div class="fraction">
                                        <span class="numerator">Customer with the first order</span>
                                        <span class="denominator">Total Customers</span>
                                    </div> -->
                                    The percentage of new customers during this period, calculated as (Number of New Customers this period / Total Customers of this period)
                                </div>
                            </ng-template>
                        </div>
                        <div echarts (chartClick)="onChartClick($event, 'newCustomerTrendCustomerList')" [options]="newcustomerschart" style="height: 400px; width: 100%;"></div>               
                    </div>
                </div>
            </div>
            
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'weightedMean'">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Customer Lifecycle Revenue Distribution<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup4"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('avg_revenue')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>        
                    <ng-template #popTierIamgeSetup4>
                        <div class="qb-ng-template-text">
                            Discover the impact of customer age on revenue with our Bar Chart. Customer age ranges are on the x-axis, showcasing the mean revenue on the y-axis. Uncover valuable insights into revenue dynamics as customer age grows, guiding targeted strategies for business success
                        </div>
                    </ng-template>
                    <div class="qb-d-flex">
                        <div class="qb-d-flex" style="flex-direction: column; justify-content: center; gap: 5px;">
                            <!-- <span class="qb-i-div qb-i-y-axis"
                                    popoverclass="qb-my-custom-class"
                                    placement="auto"
                                    [ngbPopover]="popTierChurnRate"
                                    triggers="mouseenter:mouseleave"
                                    >i</span> -->
                            <div class="customer-evolution-graph-ylabel" style="margin-left: 5px;">
                                <span>
                                    Average Revenue per Customer
                                </span>
                            </div>
                            <!-- <ng-template #popTierChurnRate>
                                <div class="qb-ng-template-text">
                                    Track customer loyalty with our Churn Rate Over Time graph. Visualize the evolution of churn rates, empowering you to make informed decisions for customer retention and business growth
                                </div>
                            </ng-template> -->
                        </div>
                        <div echarts [options]="weightedMeanRev" style="height: 400px; width: 100%;"></div>               
                    </div>
                </div>
            </div>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'topCustomer'">
                <div  class="qb-analytics-graph-container qb-w-49">
                    <h6 class="qb-analytics-heading">Top 10 Customer by Revenue<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup5"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup5>
                        <div class="qb-ng-template-text">
                            Visualize top customer spending with our Horizontal Bar Chart. Each bar on the y-axis represents a top customer, showcasing their total purchase amounts on the x-axis. Quickly identify and analyze the most significant contributors during the specified time period
                        </div>
                    </ng-template>
                    <div echarts [options]="top10Customer" style="height: 500px; width: 100%;"></div>               
                </div>
                <div  class="qb-analytics-graph-container qb-w-49">
                    <h6 class="qb-analytics-heading">Top 10 Customer by Order Count<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup5"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup5>
                        <div class="qb-ng-template-text">
                            Visualize top customer spending with our Horizontal Bar Chart. Each bar on the y-axis represents a top customer, showcasing their total purchase amounts on the x-axis. Quickly identify and analyze the most significant contributors during the specified time period
                        </div>
                    </ng-template>
                    <div echarts [options]="top10CustomerByOrderCount" style="height: 500px; width: 100%;"></div>               
                </div>
            </div>
            <div *ngIf="selectedTab === 'customerInsights' && customerId.length > 0">
                <app-customer-insights-analytics [customerId]="customerId"></app-customer-insights-analytics>
            </div>
        </div>
    </div>
</div>
<div class="loading-indicator" *ngIf="isLoading">
    <app-qubriux-loading [size]="45"></app-qubriux-loading>
</div>