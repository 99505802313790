import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import Bee from '@mailupinc/bee-plugin';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { template1, template2, template3, template4 } from 'src/app/shared/json-asset';
import { chefDrivenTemplate1, chefDrivenTemplate2, chefDrivenTemplate3, chefDrivenTemplate4 } from "src/app/shared/json-asset/chef-driven-templates";


interface DialogData {
  'process': string,
  'id': number
}

@Component({
  selector: 'app-survey-page-creation',
  templateUrl: './survey-page-creation.component.html',
  styleUrls: ['./survey-page-creation.component.css']
})
export class SurveyPageCreationComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }

  @ViewChild('inpName') inpName: ElementRef
  @ViewChild('inpSubject') inpSubject: ElementRef

  scrHeight;
  finalHeight;
  beeTest;
  beeToken;
  beeConfig;
  mergeTags = [
    {
      name: 'First Name',
      value: '[first-name]'
    }, {
      name: 'Last Name',
      value: '[last-name]'
    }, {
      name: 'Email',
      value: '[email]'
    }, {
      name: 'Latest order date',
      value: '[order-date]'
    }
  ];
  // payload = {
  //   client_id: "c26d0546-7885-418b-ba52-598811e4cf64",
  //   client_secret: "xfh20L0C0d9R0imGZK7VE1epcsLpZPGE9ohtsHKWNIZzq6P5457J",
  //   grant_type: "password"
  // }
  payload = {
    client_id: environment.beepluginClientId,
    client_secret: environment.beepluginClientSecretId,
    grant_type: "password"
  }
  selectedTemplate = 'T1';
  finalContent;
  templateName;
  templateForm: FormGroup;
  subjectError = false;
  nameError = false;
  submitClicked = false;
  responseMsg: string = null;
  template1;
  template2;
  template3;
  template4;

  constructor(public dialogRef: MatDialogRef<SurveyPageCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private tokenStorage: TokenStorageService) { this.getScreenSize() }

  ngOnInit() {
    this.getSampleTemplates();
    this.templateForm = new FormGroup({
      'TemplateName': new FormControl('', [Validators.required, Validators.maxLength(50)]),
      'subject': new FormControl('', [Validators.required, Validators.maxLength(50)])
    })
    this.finalHeight = this.scrHeight;
    this.beeTest = new Bee(this.beeToken)
    this.beeConfig = {
      uid: 'user-id-' + this.tokenStorage.getMerchantId(),
      container: 'bee-plugin-container',
      language: 'en-US',
      mergeTags: this.mergeTags,
      defaultForm: {
        structure: {
          title: 'Form title',
          fields: {
            text: { type: 'text', label: 'text' },
            submit: { type: 'submit', label: ' ', attributes: { value: 'Login' } }
          },
          layout: [
            ['text', 'submit']
          ]
        }
      },
      contentDialog: {
        manageForm: {
          label: 'Edit form',
          handler: async (resolve, reject, args) => {
            const structure = await this.onHandleManageForm(args)
            structure ? resolve(structure) : reject()
          }
        }
      },
      onSave: (jsonFile, htmlFile) => {
        this.saveAsHtml('onSave', jsonFile, htmlFile)
        return;
      },
      onSaveAsTemplate: (jsonFile) => {
      },
      onSend: (htmlFile) => {
      },
      onError: (errorMessage) => {
      }
    }
    if (this.data['process'] === 'create') {
      this.beeTest.getToken(this.payload.client_id, this.payload.client_secret)
        .then(() => this.beeTest.start(this.beeConfig, this.template1))
    }

    if (this.data['process'] === 'update') {
      this.apiCall.getEmailLayoutDetail(this.data['id']).subscribe((response) => {
        let layout = JSON.parse(response['body']);
        this.templateForm.get('TemplateName').setValue(layout['name'])
        let editTemplate = JSON.parse(layout['layoutJson'])
        this.beeTest.getToken(this.payload.client_id, this.payload.client_secret)
          .then(() => this.beeTest.start(this.beeConfig, editTemplate))
      },
        err => {
        })
    }
    if (this.data['process'] === 'AddTextBody') {
      this.apiCall.getEmailLayoutDetail(this.data['id']).subscribe((response) => {
        let layout = JSON.parse(response['body']);
        let editTemplate = JSON.parse(layout['layoutJson'])
        this.beeConfig.mergeTags = this.data['tags']
        this.beeTest.getToken(this.payload.client_id, this.payload.client_secret)
          .then(() => this.beeTest.start(this.beeConfig, editTemplate))
      },
        err => {
        })
    }
    if (this.data['process'] === 'EditTextBody') {
      this.apiCall.getEmailContent(this.data['id']).subscribe((response) => {
        let layout = JSON.parse(response['body']);
        this.templateForm.get('subject').setValue(layout['subject'])
        let editTemplate = JSON.parse(layout['layoutJson'])
        this.beeConfig.mergeTags = this.data['tags']
        this.beeTest.getToken(this.payload.client_id, this.payload.client_secret)
          .then(() => this.beeTest.start(this.beeConfig, editTemplate))
      },
        err => {
        })
    }
    if (this.data['process'] === 'rewardupdate') {
      this.apiCall.getRewardEmailDetail(this.data['id']).subscribe((response) => {
        let layout = JSON.parse(response['body'])
        let editTemplate = JSON.parse(layout['layoutJson'])
        this.templateForm.get('TemplateName').setValue(layout['name'])
        this.beeTest.getToken(this.payload.client_id, this.payload.client_secret)
          .then(() => this.beeTest.start(this.beeConfig, editTemplate))
      })
    }
  }

  saveAsHtml(type, json, html) {
    this.submitClicked = true;
    let mId = this.tokenStorage.getMerchantId();
    if (this.data.process === 'create') {
      if (this.templateForm.controls.TemplateName.errors) {
        return;
      }
      this.finalContent = {
        "merchantId": mId,
        "name": this.templateForm.get('TemplateName').value,
        "layoutHtml": html,
        "layoutJson": json
      }
      this.apiCall.createEmailLayout(this.finalContent).subscribe((response) => {
        this.setResponse('null', 'success')
      },
        (err) => {
          this.setResponse('Failed to save', 'fail')
        })
    }

    if (this.data.process === 'update') {
      if (this.templateForm.controls.TemplateName.errors) {
        //this.nameError = true;
        return;
      }
      this.finalContent = {
        "id": this.data['id'],
        "merchantId": mId,
        "name": this.templateForm.get('TemplateName').value,
        "layoutHtml": html,
        "layoutJson": json
      }
      this.apiCall.updateEmailLayout(this.finalContent).subscribe((response) => {
        this.setResponse(null, 'success')
      },
        (err) => {
          this.setResponse('Failed to save', 'fail')
        })
    }

    if (this.data.process === 'AddTextBody') {
      if (this.templateForm.controls.subject.errors) {
        return;
      }
      let data = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "subject": this.templateForm.get('subject').value,
        "layoutHtml": html,
        "layoutJson": json,
        "id": null
      }
      this.apiCall.addEmailContent(data).subscribe((response) => {
        let id = JSON.parse(response['body'])
        if (id) {
          this.setResponse(id, 'success')
        }
      })
    }

    if (this.data.process === 'EditTextBody') {
      if (this.templateForm.controls.subject.errors) {
        return;
      }
      let data = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "subject": this.templateForm.get('subject').value,
        "layoutHtml": html,
        "layoutJson": json,
        "id": this.data.id
      }
      this.apiCall.updateEmailContent(data).subscribe((response) => {
        let id = JSON.parse(response['body'])
        this.setResponse(id, 'success')
      }, err => {
        this.setResponse('Failed to save', 'fail')
      })
    }

    if (this.data['process'] === 'rewardupdate') {
      let data = {
        "merchantId": this.tokenStorage.getMerchantId(),
        "subject": this.templateForm.get('subject').value,
        "layoutHtml": html,
        "layoutJson": json,
        "id": this.data.id
      }
      this.apiCall.updateRewardEmail(data).subscribe((response) => {
        this.setResponse(null, 'success')
      }, err => {
        this.setResponse('Failed to save', 'fail')
      })
    }
    return;
  }

  closeEditor() {
    this.dialogRef.close()
  }

  selectTemplate(template) {
    this.selectedTemplate = template;
    switch (template) {
      case 'T1': this.beeTest.load(this.template1); break;
      case 'T2': this.beeTest.load(this.template2); break;
      case 'T3': this.beeTest.load(this.template3); break;
      case 'T4': this.beeTest.load(this.template4); break;
    }
  }

  setResponse(id, type) {
    if (this.data['process'] === 'create' || this.data['process'] === 'update' || this.data['process'] === 'rewardupdate') {
      type === 'success' ? this.dialogRef.close(true) : this.responseMsg = id;
      this.inpName.nativeElement.focus();
      this.inpName.nativeElement.blur();
    }
    if (this.data.process === 'EditTextBody' || this.data.process === 'AddTextBody') {
      type === 'success' ? this.dialogRef.close(id) : this.responseMsg = id;
      this.inpSubject.nativeElement.focus();
      this.inpSubject.nativeElement.blur();
    }
  }

  onHandleManageForm(arg) {
    let structure = {
      "structure": {
        "fields": {
          "name": {
            "type": "text",
            "label": "Name",
            "removeFromLayout": true,
            "canBeRemovedFromLayout": true
          },
          "surname": {
            "type": "text",
            "label": "Surname",
            "removeFromLayout": true,
            "canBeRemovedFromLayout": true,
          },
          "email": {
            "type": "email",
            "label": "Email *",
            "canBeRemovedFromLayout": false,
            "attributes": {
              "required": true
            }
          },
          "notes": {
            "type": "textarea",
            "label": "Notes",
            "removeFromLayout": true,
            "canBeRemovedFromLayout": true
          },
          "privacy": {
            "type": "checkbox",
            "label": "Accept privacy policy. [Read it here](http://example.com)",
            "canBeRemovedFromLayout": false,
            "attributes": {
              "required": true
            }
          },
          "radioButton": {
            "type": "radio",
            "options": [{
              "type": "option",
              "label": "Male",
              "value": "M"
            },
            {
              "type": "option",
              "label": "Female",
              "value": "F"
            },
            {
              "type": "option",
              "label": "Not telling",
              "value": "-"
            }
            ],
            "canBeRemovedFromLayout": true,
            "attributes": {
              "required": false
            }
          },
          // "rangeSelection": {
          //   "type": "range",
          //   "min":0,
          //   "max":10,
          //   "step":1,
          //   "canBeRemovedFromLayout": true,
          //   "attributes": {
          //     "required": false
          //   }
          // },
          "gender": {
            "type": "select",
            "label": "Gender",
            "options": [{
              "type": "option",
              "label": "Male",
              "value": "M"
            },
            {
              "type": "option",
              "label": "Female",
              "value": "F"
            },
            {
              "type": "option",
              "label": "Not telling",
              "value": "-"
            }
            ]
          },
          "submit_button": {
            "type": "submit",
            "label": "",
            "canBeRemovedFromLayout": false,
            "attributes": {
              "value": "SEND DATA",
              "name": "submit_button"
            }
          }
        }
      }
    }
    return structure;
  }

  getSampleTemplates(){
    if(this.tokenStorage.getMerchantOnlineStore() === 'restomail'){
      this.template1 = chefDrivenTemplate1;
      this.template2 = chefDrivenTemplate2;
      this.template3 = chefDrivenTemplate3;
      this.template4 = chefDrivenTemplate4;
    }
    else{
      this.template1 = template1;
      this.template2 = template2;
      this.template3 = template3;
      this.template4 = template4;
    }
  }

}
