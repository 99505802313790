import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { PosHomeComponent } from './pages';


const routes: Routes = [
    { path: '', component: PosHomeComponent },
]; 

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }