<div style="height: 100%; overflow: auto;">
  <div style="padding-top:20px;">
    <div style="display: flex; justify-content: space-between; margin: 0 20px;">
      <div class="revenueDiv" >
        <div class="rectangle">
          <div class="ConsentText">{{'CUSTOMER_PAGE.CONSENT_SMS_SENT' | i18nTranslate}}</div>
          <div class="ValueNumber">{{StatData['consentSmsSentCount']}}</div>
        </div>
        <div class="rectangle revenueRectangle">
          <div class="ConsentText">{{'CUSTOMER_PAGE.REMINDER_SENT' | i18nTranslate}}</div>
          <div class="ValueNumber">
            {{ StatData['consentReminderCount'] }}
          </div>
        </div>
        <div class="rectangle revenueRectangle r2">
          <div class="ConsentText">{{'CUSTOMER_PAGE.CONSENT_RECEIVED' | i18nTranslate}}</div>
          <div class="ValueNumber">
            {{ StatData['consentReceivedCount'] }}
          </div>
        </div>
        <div class="rectangle revenueRectangle">
          <div class="ConsentText">{{'CUSTOMER_PAGE.CONSENT_PENDING' | i18nTranslate}}</div>
          <div class="ValueNumber">
            {{ StatData['consentPendingCount'] }}
          </div>
        </div>
      </div>
    </div>
    <div class="tableContent">
      <div class="tableDiv">
          <div>
            <span class="suppressedText">{{'CUSTOMER_PAGE.CONSENT_RECEIVED' | i18nTranslate}} | {{length}}</span>
          </div>
          <div class="supressionRectangle">
            <div class="searchBar">
              <input type="text" #search placeholder=" Search by Name" (input)="onKeySearch($event,search.value)">
              <span class="material-icons icons-primary" style="padding: 0 5px 0 0;" *ngIf="search.value.length==0">
                search
                </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search.value.length>0" (click)="search.value='';onKeySearch(search.value,search.value)">
                  close
                </span>
            </div>
          </div>
      </div>
      <div *ngIf="this.mobileMedia.matches">
        <div *ngFor="let data of ConsentDetails['consentDetailsBeans']" style="margin: 25px; background-color: white; padding: 15px;box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
        border-radius: 8px;">
          <div style="padding-bottom: 15px;">{{data['name']}}</div>
          <div style="color: rgba(34, 34, 34, 0.7); padding-bottom: 15px;">{{data['emailOrPhone']}}</div>
          <!-- <div> 18 Jun 2021 | 6:17</div> -->
        </div>
        </div>
      <div class="table-main" *ngIf="!this.mobileMedia.matches">
        <div style="display: flex; padding-left: 20px; height: 50px; background: #edf0f2;">
          <div class="header" style="border-right:2px solid white; width:40% ">{{'CUSTOMER_PAGE.PHONE_NUMBER' | i18nTranslate}}</div>
          <div class="header" >Name</div>
          <div class="header" style="width: 0%; margin: 0px;"> </div>
        </div>
        <div *ngIf="length==0" style="display: flex; justify-content: center;">
          <div class="empty-customers-div">
              {{'CUSTOMER_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
          </div>
        </div>
      <div style="display: flex;padding: 20px 20px 14px;"*ngFor="let data of ConsentDetails['consentDetailsBeans']">
        <div style="width: 40%;">{{data['emailOrPhone']}}</div>
        <div style="width: 40%; margin-left: 25px;">{{data['name']}}</div>

      </div>

      </div>
  </div>
  </div>
  <mat-paginator
            [pageIndex]="pageIndex"
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="refreshPaginatedData($event)">

          </mat-paginator>
</div>