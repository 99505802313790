import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalAPI } from 'src/app/configs/externalAPI';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-oauth-redirect-dialog',
  templateUrl: './oauth-redirect-dialog.component.html',
  styleUrls: ['./oauth-redirect-dialog.component.css']
})
export class OAuthRedirectDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OAuthRedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {"platform":string}
  ) {}

  ngOnInit(): void {
console.log(this.data);
    switch(this.data["platform"]){
      case 'shopify':
        {
          this.authenticateShopify();
        }
        break;
        case 'lightspeed':
          {
this.authenticateLightspeed()
          }
          break;
          default:
            break;
    }

  }

  authenticateShopify(){
    let redirect_url =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
    let app_nonce = "2626272222222";
  let url = "https://" +     
ExternalAPI.SHOPIFY_GENERIC_URL +
"?client_id=" +
environment.shopifyClientId +
"&scope=" +
environment.shopifyAppScope +
"&redirect_uri=" +
redirect_url +
"&state=" +
app_nonce;
window.open(url, "_self");
  }

  // authenticateLightspeed() {
  //   console.log("authenticateLightspeed");
  //   let url =
  //     "https://cloud.lightspeedapp.com/oauth/authorize.php?response_type=code&client_id=f701a5047d34d3e3c454a1df411aa9b0925a3c3aa8345c54b9f9677d15331e9f&scope=employee:all";
  //   window.open(url, "_self");
  //   //}
  //   console.log("authenticateLightspeed-done");
  // }

  authenticateLightspeed() {
    let app_nonce = "2626272222222";

    let redirect_url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/data-ingestion/lspos";
      let url =
      "https://cloud.lightspeedapp.com/oauth/authorize.php?response_type=code&client_id="+environment.lightspeedClientId+"&scope=employee:all" +
      "&state=" +
      app_nonce;
    window.open(url, "_self");
    //}
  }

}
