import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared/shared-module";
import { JourneyAnalyticsRoutingModule } from "./journey-analytics-routing.module";
import { JourneyAnalyticsViewComponent } from "./pages";

@NgModule({
  declarations: [JourneyAnalyticsViewComponent],
  imports: [SharedModule, JourneyAnalyticsRoutingModule],
})
export class JourneyAnalyticsModule {}
