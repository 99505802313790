import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-select-store-dialog-box',
  templateUrl: './select-store-dialog-box.component.html',
  styleUrls: ['./select-store-dialog-box.component.css']
})
export class SelectStoreDialogBoxComponent implements OnInit {

  constructor(
    private apiCall : ApiCallService,
    private dialogRef: MatDialogRef<SelectStoreDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
    selectedStores = [];
    selectedStoresId = [];
    storeList=[
      {
        name: "s1",
        id: 1
      },
      {
        name: "s2",
        id: 2
      },
      {
        name: "s3",
        id: 3
      },
      {
        name: "s4",
        id: 4
      },
      {
        name: "s5",
        id: 5
      }
  ];
  apiloading = false;
  DataAvailability = false;
  NoData = true;
  storeAvailability = false;
  ngOnInit(): void {
    this.getStores();
    this.selectedStores = this.data['storeData']
    for(let i=0; i < this.selectedStores.length; i++){
      this.selectedStoresId.push(this.selectedStores[i]['storeId'])
    }
    if(this.storeList.length>0){
      this.DataAvailability = true;
    }
  }
  save(){
    let data = {
      selectedStore: this.selectedStores,
      isAllStoresSelected: this.allStoresSelected()
    }
    this.dialogRef.close(data);
  }
  cancel(){
    this.dialogRef.close(false);
  }
  updateStoreData(event, store){
    if(event){
      if(this.selectedStoresId.indexOf(store.storeId) == -1){
        this.selectedStores.push(store);
        this.selectedStoresId.push(store.storeId)
      }
    }
    else{
      if(this.selectedStoresId.indexOf(store.storeId) != -1){
        const index = this.selectedStoresId.indexOf(store.storeId);
        this.selectedStores.splice(index, 1);
        this.selectedStoresId.splice(index, 1);
      }
    }
  }
  isIdPresent(store){
    if(this.selectedStoresId.indexOf(store.storeId) != -1){
      return true;
    }
    else{
      return false;
    }
  }
  addAllStores(event){
    // let temp = this.storeList;
    this.selectedStores = [];
    this.selectedStoresId = [];
    if(event){
      // this.selectedStores = this.storeList;
      for(let i =0;i < this.storeList.length; i++){
        this.selectedStores.push(this.storeList[i]);
      }
      for(let i=0; i < this.selectedStores.length; i++){
        this.selectedStoresId.push(this.selectedStores[i]['storeId']);
      }
    }
    else{
      this.selectedStores = [];
      this.selectedStoresId = [];
    }
  }
  allStoresSelected(){
    if(this.selectedStores.length === this.storeList.length)
      return true;
    else
      return false;
  }
  getStores(){
    this.apiCall.getStoreData().subscribe((response)=>{
      this.storeList = response['body'];
      if(this.storeList.length != 0)
        this.storeAvailability = true;
      else
        this.storeAvailability = false;
      this.apiloading = false;
    },(error)=>{
        this.storeAvailability = false;
        this.storeList = [];
        this.apiloading = false;
    })
  }
}
