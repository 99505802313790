<div class="qb-body" data-widget-name="OFFER LISTING">
  <div class="qb-main">
    <mat-icon class="qb-icon" (click)="close()" data-button-name="Close"
      >clear</mat-icon
    >
  </div>
  <div class="qb-title-div">
    <div class="qb-title">{{'OFFER_PAGE.FILTERS' | i18nTranslate}}</div>
    <span
      class="qb-clear"
      (click)="clear()"
      data-button-name="Clear Filter"
      *ngIf="
        channelSms ||
        channelEmail ||
        statusDraft ||
        statusSent ||
        statusScheduled
      "
      >{{'OFFER_PAGE.CLEAR_ALL' | i18nTranslate}}</span
    >
  </div>
  <div class="qb-filter-div">
    <div class="qb-first-filter qb-filter">
      <p><strong>{{'OFFER_PAGE.CHANNEL_CAPS' | i18nTranslate}}</strong></p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="channelSms"
          [(ngModel)]="channelSms"
          (change)="setanalyticstext()"
          *ngIf="(validScreens | screenConfig: 18)"
          >{{'OFFER_PAGE.SMS' | i18nTranslate}}</mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="channelEmail"
          [(ngModel)]="channelEmail"
          (change)="setanalyticstext()"
          >{{'OFFER_PAGE.EMAIL' | i18nTranslate}}</mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="channelWhatsapp"
          [(ngModel)]="channelWhatsapp"
          (change)="setanalyticstext()"
          >{{'OFFER_PAGE.WHATSAPP' | i18nTranslate}}</mat-checkbox
        >
      </p>
    </div>
    <div class="qb-filter">
      <p><strong>{{'OFFER_PAGE.STATUS_CAPS' | i18nTranslate}}</strong></p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="statusSent"
          [(ngModel)]="statusSent"
          (change)="setanalyticstext()"
          >{{'OFFER_PAGE.SENT' | i18nTranslate}}</mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="statusDraft"
          [(ngModel)]="statusDraft"
          (change)="setanalyticstext()"
          >{{'OFFER_PAGE.DRAFT' | i18nTranslate}}</mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="statusScheduled"
          [(ngModel)]="statusScheduled"
          (change)="setanalyticstext()"
          >{{'OFFER_PAGE.SCHEDULED' | i18nTranslate}}</mat-checkbox
        >
      </p>
    </div>
  </div>
  <div class="qb-button-div">
    <div class="qb-buttons">
      <mat-dialog-actions>
        <button
          class="qb-cancel-btn qb-cancel"
          mat-dialog-close
          data-button-name="Close Filter"
          (click)="close()"
        >
          {{'OFFER_PAGE.CANCEL' | i18nTranslate}}
        </button>
      </mat-dialog-actions>
      <mat-dialog-actions>
        <button
          class="primary-button qb-apply"
          (click)="applyFilter()"
          mat-dialog-close
          data-button-name="Apply Filter"
          [attr.data-item-name]="analyticsText"
        >
          {{'OFFER_PAGE.APPLY' | i18nTranslate}}
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
