import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardRoutingModule } from './onboard-routing.module';
import { AccountSetupComponent } from './pages/account-setup/account-setup.component';
import { SharedModule } from 'src/app/modules/shared/shared-module';


@NgModule({
  declarations: [
    AccountSetupComponent
  ],
  imports: [
    CommonModule,
    SharedModule, 
    OnboardRoutingModule
  ]
})
export class OnboardModule { }
