<div class="font">
  <div>
    <div class="header">
      <span style="text-align: center">{{ data["subject"] }}</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div *ngIf="data['game-name']">
    <div class="message">
      <div>{{'SHARED_PAGE.IF_DELETE' | i18nTranslate}} <span style="font-weight: 600;">{{data["game-name"]}}</span> , {{'SHARED_PAGE.ALL_DATA_DELETE' | i18nTranslate}}</div>
      <br>
      <div>{{'SHARED_PAGE.SURE_TO_DELETE' | i18nTranslate}}</div>
    </div>
  </div>
  <div *ngIf="data['customer-name']">
    <div class="message" *ngIf="!data['isEmployee']">
      <div>{{'SHARED_PAGE.ARE_YOU_SURE' | i18nTranslate}} <span style="font-weight: 600;">{{data["customer-name"]}}</span> , {{'SHARED_PAGE.FROM_CUSTOM' | i18nTranslate}}</div>    
    </div>
    <div class="message" *ngIf="data['isEmployee']">
      <div>{{'SHARED_PAGE.ARE_YOU_SURE' | i18nTranslate}} <span style="font-weight: 600;">{{data["customer-name"]}}</span> , {{'SHARED_PAGE.FROM_EMPLOYEE' | i18nTranslate}}</div>    
    </div>
  </div>
  <div *ngIf="data['customer-list-name']">
    <div class="message">
      <div>{{'SHARED_PAGE.ARE_YOU_SURE_DELETE' | i18nTranslate}} <span style="font-weight: 600;">{{data["customer-list-name"]}}</span></div>    
    </div>
  </div>
  <div class="button-container">
    <span class="secondary-button-border" (click)="submit(false)"
      data-button-name="Canceled Operation" [attr.data-item-name]="data['data-item']">{{ data["cancelButtonText"]
      }}</span>
    <span class="primary-button" style="background: #D71920;"
      (click)="submit(true)" [attr.data-button-name]="data['data-button']" [attr.data-item-name]="data['data-item']">{{
      data["successButtonText"] }}</span>
  </div>
</div>