<div class="font" [attr.data-widget-name]="data['data-widget']">
  <div>
    <div class="header">
      <span style="text-align: center">{{'SHARED_PAGE.INITIATE_PARTNERSHIP' | i18nTranslate}}</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div>
    <div *ngIf="length === 0" class="message">
      {{'SHARED_PAGE.CREATE_REWARD_PARAGRAGH' | i18nTranslate : data["name"]}}
    </div>
    <div *ngIf="length != 0" class="message">
      {{'SHARED_PAGE.SELECT_REWARD_PARAGRAPH' | i18nTranslate : data["name"]}}
    </div>
  </div>
  <div
    *ngIf="length != 0"
    style="
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 0px 16px;
      width: 96%;
    "
  >
    <div style="margin-top: 20px">{{'SHARED_PAGE.SELECT_PARTNER_REWARD' | i18nTranslate}}</div>
    <div
      class="add-reward-div"
      style="cursor: pointer"
      (click)="continue(true)"
    >
      <div class="material-icons-outlined">add</div>
      <div>{{'SHARED_PAGE.CREATE_NEW_REWARD' | i18nTranslate}}</div>
    </div>
  </div>
  <div *ngIf="length != 0" class="rewards">
    <div
      *ngFor="let list of RewardList"
      style="display: flex; align-items: center; padding: 10px 0px 10px 16px"
    >
      <div
        class="card-selection"
        style="position: static; cursor: pointer"
        [ngClass]="{ active: rewardId == list['id'] }"
        (click)="
          selectList(list['rewardName'], list['rewardValue'], list['id'])
        "
      >
        <mat-icon class="card-selection-icon">done</mat-icon>
      </div>
      <span
        (click)="
          selectList(list['rewardName'], list['rewardValue'], list['id'])
        "
        style="padding: 10px 0px 10px 16px; cursor: pointer;"
        >{{'SHARED_PAGE.PARTNERSHIP_HOW_IT_WORKS_TEXT' | i18nTranslate : list["rewardName"] : list["rewardValue"]}}</span
      >
    </div>
  </div>
  <div class="button-container" style="margin-top: 30px">
    <span class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span *ngIf="length === 0" class="primary-button" (click)="continue(true)"
      >{{'SHARED_PAGE.CONTINUE' | i18nTranslate}}</span
    >
    <span *ngIf="length != 0" class="primary-button" (click)="Send()">
      {{'SHARED_PAGE.SEND_REQUEST' | i18nTranslate}}
    </span>
  </div>
</div>
