import { Component, OnInit, Inject, ErrorHandler } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { DialogData } from "../ecommerce-dialog/ecommerce-dialog.component";

@Component({
  selector: "app-offer-rules-import-dialog",
  templateUrl: "./offer-rules-import-dialog.component.html",
  styleUrls: ["./offer-rules-import-dialog.component.css"],
})
export class OfferRulesImportDialogComponent implements OnInit {
  master;
  offerCount;
  selectedId;
  loading = false;
  selectList=[];
  dropDownStatus=false;
  filterSearch={
    label:''
  };
  selectedName;

  constructor(
    public dialogRef: MatDialogRef<OfferRulesImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiCall: ApiCallService,
    private errorHandle: ErrorHandler
  ) {}

  ngOnInit() {
    if (this.data.type == "offer") {
      this.getOfferTemplates();
    } else if (this.data.type == "survey") {
      this.getSurveyTemplate();
    } else {
      this.getSurveyTemplate();
    }
  }

  getOfferTemplates() {
    this.loading = true;
    this.apiCall.getAllOfferList().subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.master = (response["body"]);
          this.offerCount = this.master.length;
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        // this.errorHandle.showError(err);
      }
    );
  }

  getSurveyTemplate() {
    this.loading = true;
    this.apiCall.getAllSurveyTemplates().subscribe(
      (response) => {
        if (response["message"] === "SUCCESS") {
          this.master = response["body"];
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        // this.errorHandle.showError(err);
      }
    );
  }
  closeDialog(value) {
    this.dialogRef.close(value);
  }



  toggleSelect() {
    let element = document.getElementById("multi-select-dropdown");
    let segmentSelect = document.getElementById("segment-select");
    let segmentSelectPanel = document.getElementById("multi-select-panel");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container"
    );    let importCont = document.getElementById("importContainer");
    if (this.dropDownStatus) {
      this.dropDownStatus = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.border = "1px solid #d2d8ee"
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //element.style.display = 'none';
      importCont.style.height="auto";
    } else {
      this.dropDownStatus = true;
      element.style.height = "200px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      segmentSelectPanel.style.border = "1px solid #d2d8ee"
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
      importCont.style.height="240px";
    }
  }
  updateCheckboxValues(segment) {
    let index = this.selectList.findIndex((x) => x.id === segment.id);
    if (index < 0) {
      this.selectList.push(segment);
    } else {
      this.selectList.splice(index, 1);
    }
  }
  checkboxStatus(segment) {
    let index = this.selectList.findIndex((x) => x.id === segment.id);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }
}
