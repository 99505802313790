import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { getCurrencySymbol } from '@angular/common';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-partner-group-offer-creation',
  templateUrl: './partner-group-offer-creation.component.html',
  styleUrls: ['./partner-group-offer-creation.component.css']
})
export class PartnerGroupOfferCreationComponent implements OnInit {

  constructor(
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  partnershipOfferName = "";
  allOfferMaps = [];
  s: Array<any> = [];
  offerMap;
  s2;
  revCurrency;
  currencySymbol;
  barrier = 0;
  quantityBarrier = 0;
  submitClicked = false;
  offers;
  barrierJSON = {
    type: "AMOUNT",
    value: 0,
    collection: [],
  };
  showDropdown = false;
  scrWidth;
  productCollections = [];
  selectedProductCollections = [];
  ids = [];
  id = -1;
  isPos =  false;
  @ViewChild("myDiv") eleRef: ElementRef;
  barrierData = {
    id: 0,
    merchantId: this.tokenStorage.getMerchantId(),
    barrierBody: {},
  };
  barrier_id = 0;
  openScheduleOverlay = false;
  message = "";
  ngOnInit(): void {
    this.getCurrencySymbol();
    this.getAllFormOffers();
    this.getProducts();
    if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this.isPos = false;
    }
    else{
      this.isPos = true;
    }
  }
  
  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    if (this.eleRef !== undefined) {
      if (!this.eleRef.nativeElement.contains(event.target)) {
        this.showDropdown = false;
      }
    }
  }
  addOffer(id, d, request, storeName) {
    if (this.s.indexOf(id) == -1) {
      if (this.allOfferMaps.length < 3) {
        this.s.push(id);
        this.s2 = id;
        this.offerMap = {
          description: d,
          partnershipRequests: request,
          offerId: id,
          storeName: storeName,
          isActive: null,
          reasonDisabled: null,
          status: null,
          revenue: null,
          couponValid: null,
          couponCode: null
        };
        this.allOfferMaps.push(this.offerMap);
      }
    } else {
      const index: number = this.s.indexOf(id);
      if (index !== -1) {
        this.s.splice(index, 1);
      }
      this.allOfferMaps.forEach((value) => {
        if (value["offerId"] == id) {
          const index: number = this.allOfferMaps.indexOf(value);
          if (index !== -1) {
            this.allOfferMaps.splice(index, 1);
          }
        }
      });
    }
    console.log("ALL_OFFER",this.allOfferMaps);
    // this.offerData.formOfferDetailsList = this.allOfferMaps;
  }
  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }
  check(event, id) {
    if (this.allOfferMaps.length >= 3 && this.s.indexOf(id) != -1) {
      this.displaySnackBar("You can select a max of 3 offers");
    }
  }
  displaySnackBar(text) {
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(text, ['POS_PAGE']), 2000);
  }
  checkValidityDates(){
    if (
          (this.selectedProductCollections.length > 0 &&
            this.barrierJSON.type === "QUANTITY") ||
          this.barrierJSON.type === "AMOUNT"
        ) {
            this.addBarrier();
           
        }
  }
  rerouteToMyOffers(){
    this.router.navigate(["/app/partnership/my-offers"]);
  }
  async getAllFormOffers() {
    await this.apiCall
      .getAllPartnershipFormOffers(null, false)
      .subscribe((response) => {
        this.offers = response["body"]["formOfferDetailsList"];
        console.log("OFFERS",this.offers)
      });
  }
  getProducts() {
    this.apiCall.getConstantProducts().subscribe((response) => {
      this.productCollections = JSON.parse(response["body"]).productCategories;
    });
  }
  selectCollection(id, name, event) {
    if (!event.checked) {
      if (id == -1) {
        this.ids = [];
        this.selectedProductCollections = [];
      } else {
        const index = this.ids.indexOf(id);
        if (index > -1) {
          this.ids.splice(index, 1);
        }
        this.selectedProductCollections.forEach((collection) => {
          if (collection["collectionId"] == id) {
            this.selectedProductCollections.splice(collection, 1);
          }
        });
      }
    } else {
      if (id == -1) {
        this.ids = [];
        this.selectedProductCollections = [];
        this.productCollections.forEach((collection) => {
          this.ids.push(collection["productCategoryId"]);
          // this.selectedProductCollections.push({
          //   collectionId: collection["productCategoryId"],
          //   collectionName: collection["productCategoryName"],
          // });
        });
          this.selectedProductCollections.push({
            collectionId: null,
            collectionName: "ALL_CATEGORIES",
          });
          console.log("data",this.selectedProductCollections)
      } else {
        if (this.ids.indexOf(id) == -1) {
          this.ids.push(id);
          this.productCollections.forEach((collection) => {
            if (collection["productCategoryId"] == id) {
              this.selectedProductCollections.push({
                collectionId: collection["productCategoryId"],
                collectionName: collection["productCategoryName"],
              });
            }
          });
        }
      }
    }
  }
  
  addBarrier() {
    this.barrierJSON.collection = this.selectedProductCollections;
    this.barrierData.barrierBody = this.barrierJSON;
    this.apiCall
      .addPartnershipBarrier(this.barrierData)
      .subscribe((response) => {
        this.barrier_id = response['body'];
        this.openScheduleOverlay = true;
        this.modifyConfig(true);
         console.log("data",this.barrier_id)
      },(err)=>{
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']),2000);
      });
  }
  modifyConfig(status) {
    document.getElementById("viewconfigDiv").style.zIndex = "1";
    document.getElementById("overlay").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.display = "flex";
    this.on();
  }

  cancelAllSchedule() {
    this.off();
    document.getElementById("viewconfigDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "-1";
    document.getElementById("configDiv").style.display = "none";
    document.getElementById("overlay").style.zIndex = "-1";
    document.getElementById("overlay").style.display = "none";
  }

  on() {
    // this.getParticularOfferDetail();
    // this.getTemplates();
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }
  receiveMessage($event) {
    this.message = $event;
    this.cancelAllSchedule();
    this.openScheduleOverlay = false;
  }
  addPOSOffer(id, d, request, storeName){
    console.log("POOOSO")
      if (this.s.indexOf(id) == -1) {
        this.s = [];
        this.allOfferMaps = [];
        this.s.push(id);
        this.s2 = id;
        this.offerMap = {
          description: d,
          partnershipRequests: request,
          offerId: id,
          storeName: storeName,
          isActive: null,
          reasonDisabled: null,
          status: null,
          revenue: null,
          couponValid: null,
          couponCode: null
        };
        this.allOfferMaps.push(this.offerMap);
    } else {
      const index: number = this.s.indexOf(id);
      if (index !== -1) {
        this.s.splice(index, 1);
      }
      this.allOfferMaps.forEach((value) => {
        if (value["offerId"] == id) {
          const index: number = this.allOfferMaps.indexOf(value);
          if (index !== -1) {
            this.allOfferMaps.splice(index, 1);
          }
        }
      });
    }
  }
}
