import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { currentPlanDataService } from "src/app/core/services/current-plan-global.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-payment-plan",
  templateUrl: "./payment-plan.component.html",
  styleUrls: ["./payment-plan.component.css"],
})
export class PaymentPlanComponent implements OnInit {
  planListLoading = true;
  isSelectPlanLoading = false;
  basicPlanBody = {
    monthly: null,
    yearly: null,
  };
  starterPlanBody = {
    monthly: null,
    yearly: null,
  };
  growthPlanBody = {
    monthly: null,
    yearly: null,
  };
  acceleratePlanBody = {
    monthly: null,
    yearly: null,
  };

  masterPlanList = [];
  planList = [];

  toggleStatus = false;
  currentPlanName = null;
  currentSubscriptionType = "MONTHLY_SUBSCRIPTION";
  recommendedPlanName = null;
  currentPlanObj;
  planSetup;
  isSelectButtonActive = false;
  selectedPlanBtnName = null;
  plangetStartedSelected = null;
  planMsgHeader = "Build Great Customer Experience with Our Exclusive Plans";
  recommId = null;
  partnershipConsentStatus = false;
  constructor(
    private _apiCallService: ApiCallService,
    private _dialog: MatDialog,
    private currentPlanDataService: currentPlanDataService,
    private _WidgetConfigService: WidgetConfigService,
    private _snackBar: SnackbarCollection,
    private _router: Router,
    private _activetedRoute: ActivatedRoute,
    private _tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    this._activetedRoute.queryParams.subscribe((params) => {
      this.recommId = params["subId"];
    });
  }
  ngOnInit() {
    if (this._dialog.openDialogs.length > 0) return;
    this.plangetStartedSelected = null;
    this.planMsgHeader =
      "Build Great Customer Experience with Our Exclusive Plans";
    this.partnershipConsentStatus =
      this._tokenStorage.getPartnershipConsentStatus()
        ? this._tokenStorage.getPartnershipConsentStatus() == "true"
          ? true
          : false
        : false;
    let ko = this.currentPlanDataService.currentPlanBody.subscribe(
      async (value) => {
        this.currentPlanObj = value; // this.currentPlanObj will hold your value and modify it every time it changes
        if (this.currentPlanObj == null) {
          await this.getCurrentPlanStat();
          this.currentPlanName =
            this.currentPlanObj != null ? this.currentPlanObj.planName : "null";
          this.currentSubscriptionType =
            this.currentPlanObj != null ? this.currentPlanObj.planType : "null";
          // if(this.currentPlanObj != null&&!this.currentPlanObj.isEmailVerified){
          //   this._snackBar.openSnackBar("Please verify your email to continue",1500);
          //   this.backBtnPressed()
          // }
        } else {
          this.currentPlanName = this.currentPlanObj.planName;
          this.currentSubscriptionType = this.currentPlanObj.planType;
          // if(this.currentPlanObj != null&&!this.currentPlanObj.isEmailVerified){
          //   this._snackBar.openSnackBar("Please verify your email to continue",1500);
          //   this.backBtnPressed()
          // }
        }
      }
    );
    this.getRecommendedPlan();
    this.getAllPlanData();
  }

  getAllPlanData() {
    this.planListLoading = true;

    this._apiCallService.getAllPlanData().subscribe((result) => {
      let response = JSON.parse(result["body"]);
      if (response["masterPlanList"].length == 0) {
        return 0;
      }
      for (var i = 0; i < response["masterPlanList"].length; i++) {
        if (
          response["masterPlanList"][i].planType == "MONTHLY_SUBSCRIPTION" &&
          response["masterPlanList"][i].planName != "Trial"
        ) {
          this.masterPlanList.push(response["masterPlanList"][i]);
        }
      }
      if (this.recommId != null) {
        let k = this.masterPlanList.filter((x) => x.subPlanId == this.recommId);
        this.selectGetStarted(k[0]);
      }
      this.planListLoading = false;
    });
  }

  getThresholdRecommendedPlanObject(master, id) {
    return master.filter((x) => x.subPlanId == id);
  }

  getRecommendedPlan() {
    this._apiCallService.getRecommendedPlan().subscribe((result) => {
      this.recommendedPlanName = result["body"]["planName"];
    });
  }

  toggleSubscriptionType(event) {
    this.toggleStatus = event.checked;
  }

  selectGetStarted(selectedPlanObj) {
    if (this.currentPlanName == selectedPlanObj.planName) {
      return 0;
    }
    this.plangetStartedSelected = selectedPlanObj;
    this.planMsgHeader = "Your Selected Plan";
  }
  selectPlan(selectedPlanObj) {
    // Trial plan -> Any plan = no proration
    // Basic plan -> Any plan = no proration
    //No plan -> Any plan =  create charge
    //Any plan but not trial -> Basic =  proration + redirection to payment confirmation page
    //Any plan -> paid plan =  proration + change plan api call
    if (!this.isSelectButtonActive) {
      this.selectedPlanBtnName = selectedPlanObj.planName;
      this.isSelectButtonActive = true;
      if (this.currentPlanObj != null && selectedPlanObj.planName != "Basic") {
        this.changePricingPlan(
          selectedPlanObj.masterPlanId,
          selectedPlanObj.subPlanId
        );
      } else {
        if (selectedPlanObj.planName != "Basic") {
          // for no plan
          this.createCharge(selectedPlanObj.subPlanId);
        } else {
          // For -> basic plan
          this.freePlanSelected(
            selectedPlanObj.masterPlanId,
            selectedPlanObj.subPlanId
          );
        }
      }
    }
  }

  // createPricingPlan(subplanId) {
  //   this._apiCallService
  //     .createPricingPlan(subplanId)
  //     .subscribe((response) => {});
  // }

  changePricingPlan(masterPlanIdNew, subplanIdNew) {
    // No proration needed for basic/Trial plan to any other plan
    if (
      this.currentPlanName != "Basic" &&
      this.currentPlanName != "Trial" &&
      this.currentPlanObj.subscriptionSubPlanId != subplanIdNew
    ) {
      this._apiCallService
        .getProratedCredits(masterPlanIdNew, subplanIdNew)
        .subscribe((res) => {
          let proratedObj = JSON.parse(res["body"]);
          const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "45%",
            data: {
              subject: this._i18nDynamicTranslate.transform("FOR YOUR INFORMATION", ['POS_PAGE']),
              message:
                this._i18nDynamicTranslate.transform("You will be having", ['POS_PAGE']) + " " +
                proratedObj["EMAIL"] + " " +
                this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']) + " " +
                proratedObj["SMS"] + " " +
                this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']),
              cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
              successButtonText: this._i18nDynamicTranslate.transform("Continue", ['POS_PAGE']),
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.callPricingApi(
                this.currentPlanObj.subscriptionMasterPlanId,
                masterPlanIdNew,
                this.currentPlanObj.subscriptionSubPlanId,
                subplanIdNew
              );
            } else {
              this.isSelectButtonActive = false;
              this.selectedPlanBtnName = null;
            }
          });
        });
    } else {
      this.callPricingApi(
        this.currentPlanObj.subscriptionMasterPlanId,
        masterPlanIdNew,
        this.currentPlanObj.subscriptionSubPlanId,
        subplanIdNew
      );
    }
  }

  callPricingApi(masterPlanIdOld, masterPlanIdNew, subplanIdOld, subplanIdNew) {
    this.isSelectPlanLoading = true;
    // To any higher plan
    this._apiCallService
      .changePricingPlan(
        masterPlanIdOld,
        masterPlanIdNew,
        subplanIdOld,
        subplanIdNew
      )
      .subscribe(
        (response) => {
          let responseData = JSON.parse(response["body"]);
          window.open(responseData.confirmationURL, "_self");

          // this.selectedPlanBtnName = null;
        },
        (error) => {
          this.isSelectPlanLoading = false;
          this.isSelectButtonActive = false;
          this.selectedPlanBtnName = null;
          const dialogRef = this._dialog.open(ContactUsDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "380px",
            height: "auto",
            disableClose: true,
            // this.dialogRef.componentInstance.data : "You will be logged out!"
            data: {
              header: this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
              body: this._i18nDynamicTranslate.transform(error.error.body, ['POS_PAGE']),
            },
          });
          // this._snackBar.openSnackBar(error.error.body, 1000);
        }
      );
  }
  async getCurrentPlanStat() {
    // current plan details necessary
    this.planSetup = await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  createCharge(subplanId) {
    //Only for no plan
    this._apiCallService.createCharge(subplanId).subscribe(
      (response) => {
        let responseData = response["body"];
        window.open(responseData.confirmationURL, "_self");
        // this.isSelectButtonActive = false;
        this.selectedPlanBtnName = null;
      },
      (error) => {
        this.isSelectButtonActive = false;
        this.selectedPlanBtnName = null;
        const dialogRef = this._dialog.open(ContactUsDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          width: "380px",
          height: "auto",
          disableClose: true,
          // this.dialogRef.componentInstance.data : "You will be logged out!"
          data: {
            header:  this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
            body: this._i18nDynamicTranslate.transform(error.error.body, ['POS_PAGE']),
          },
        });
        // this._snackBar.openSnackBar(error.error.body, 1000);
      }
    );
  }

  freePlanSelected(subscriptionMasterPlanId, subPlanId) {
    //Only for no plan
    // if (this.currentPlanObj == null || this.currentPlanName == "Trial" ) {
    //   this._router.navigate(["/app/payments/payment-confirmed"], {
    //     queryParams: { stype: this.toggleStatus ? "YEARLY" : "MONTHLY" },
    //   });
    // }
    if (!this.partnershipConsentStatus) {
      this._router.navigate(["/app/payments/payment-confirmed"], {
        queryParams: { stype: this.toggleStatus ? "YEARLY" : "MONTHLY" },
      });
    } else {
      this.checkIfMerchantEligibleForBasicIfPartnershipConsentIsGiven(
        subscriptionMasterPlanId,
        subPlanId
      );
      //from paid plan to free plan:::: NOT REQUIRED
      // this._apiCallService
      //   .getProratedCredits(subscriptionMasterPlanId, subPlanId)
      //   .subscribe((res) => {
      //     let proratedObj = JSON.parse(res["body"]);
      //     const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
      //       panelClass: "no-padding-dialog-popup",
      //       width: "45%",
      //       data: {
      //         subject: "FOR YOUR INFORMATION",
      //         message:
      //           "You will be having " +
      //           proratedObj["EMAIL"] +
      //           " email credits and " +
      //           proratedObj["SMS"] +
      //           " SMS credits for the remaining period.",
      //         cancelButtonText: "Cancel",
      //         successButtonText: "Continue",
      //       },
      //     });
      //     dialogRef.afterClosed().subscribe((result) => {
      //       if (result) {
      //         this._router.navigate(["/app/payments/payment-confirmed"], {
      //           queryParams: {
      //             stype: this.toggleStatus ? "YEARLY" : "MONTHLY",
      //           },
      //         });
      //       } else {
      //         this.isSelectButtonActive = false;
      //         this.selectedPlanBtnName = null;
      //       }
      //     });
      //   });
    }
  }
  backBtnPressed() {
    if (this.plangetStartedSelected != null) {
      this.plangetStartedSelected = null;
      this.planMsgHeader =
         this._i18nDynamicTranslate.transform("Build Great Customer Experience with Our Exclusive Plans", ['POS_PAGE']);
    } else {
      this._router.navigate(["/app/user-profile"], {
        queryParams: { layout: "billing" },
      });
    }
  }
  checkIfMerchantEligibleForBasicIfPartnershipConsentIsGiven(
    masterPlanIdNew,
    subplanIdNew
  ) {
    this._apiCallService
      .checkPartnershipIfMerchantEligibleForBasic(
        this.currentPlanObj.subscriptionMasterPlanId,
        masterPlanIdNew,
        this.currentPlanObj.subscriptionSubPlanId,
        subplanIdNew
      )
      .subscribe(
        (response) => {
          let responseData = JSON.parse(response["body"]);
          if(responseData.hasOwnProperty(true)){
            const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "47%",
              data: {
                subject:  this._i18nDynamicTranslate.transform("DOWNGRADE PLAN", ['POS_PAGE']),
                message:
                   this._i18nDynamicTranslate.transform("A small recurring fee of $5.00 will be charged to you for using co-marketing every month.", ['POS_PAGE']),
                submessage:
                   this._i18nDynamicTranslate.transform("If you upgrade to a paid plan you won’t be charged any additional fee to use co-marketing.", ['POS_PAGE']),
                cancelButtonText:  this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
                successButtonText:  this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.createPartnershipChargeForTrialAndBasicPlan();
              } else {
                this.isSelectPlanLoading = false;
                this.isSelectButtonActive = false;
                this.selectedPlanBtnName = null;
              }
            });
          }
          if(responseData.hasOwnProperty(false)){
            const dialogRef = this._dialog.open(ContactUsDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "380px",
              height: "auto",
              disableClose: true,
              // this.dialogRef.componentInstance.data : "You will be logged out!"
              data: {
                header: this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
                body:responseData['false'],
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.isSelectPlanLoading = false;
              this.isSelectButtonActive = false;
              this.selectedPlanBtnName = null;
            })
          }
     //window.open(responseData.confirmationURL, "_self");

        },
        (error) => {
          this.isSelectButtonActive = false;
          this.selectedPlanBtnName = null;
          this.isSelectPlanLoading = false;
          this._snackBar.openSnackBar(error.error.body, 1000);
        }
      );
  }
  createPartnershipChargeForTrialAndBasicPlan() {
    // this.continueButtonLoading = true;
    this._apiCallService
      .createPartnershipChargeForTrialAndBasicPlan("PARTNERSHIP_BASIC")
      .subscribe((response) => {
        let responseData = response["body"];
        window.open(responseData.confirmationURL, "_self");
      });
  }
}
