import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { DialogData } from "../ecommerce-dialog/ecommerce-dialog.component";


@Component({
  selector: "app-social-media-connect-channel-dialog",
  templateUrl: "./social-media-connect-channel-dialog.component.html",
  styleUrls: ["./social-media-connect-channel-dialog.component.css"],
})
export class SocialMediaConnectChannelDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SocialMediaConnectChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _apiCallService: ApiCallService
  ) {}
  socialChannelList = [
    {
      id: 1,
      channelName: "facebook",
      name: "Facebook",
      headBackroundColor: "#3B5998",
      svg: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="19.9999" cy="20" r="16.6667" fill="#3B5998" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                d="M24.4291 14.1315C23.9097 14.0276 23.2081 13.95 22.7669 13.95C21.5722 13.95 21.4946 14.4694 21.4946 15.3006V16.7801H24.4811L24.2207 19.8448H21.4946V29.1667H17.7552V19.8448H15.8333V16.7801H17.7552V14.8844C17.7552 12.2878 18.9756 10.8333 22.0397 10.8333C23.1042 10.8333 23.8834 10.9892 24.896 11.1969L24.4291 14.1315Z"
                fill="white" /></svg>`,
    },
    {
      id: 2,
      channelName: "instagram",
      name: "Instagram",
      headBackroundColor: "#3B5998",
      svg: `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><radialGradient id="yOrnnhliCrdS2gy~4tD8ma" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"/><stop offset=".328" stop-color="#ff543f"/><stop offset=".348" stop-color="#fc5245"/><stop offset=".504" stop-color="#e64771"/><stop offset=".643" stop-color="#d53e91"/><stop offset=".761" stop-color="#cc39a4"/><stop offset=".841" stop-color="#c837ab"/></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"/><radialGradient id="yOrnnhliCrdS2gy~4tD8mb" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"/><stop offset=".999" stop-color="#4168c9" stop-opacity="0"/></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"/><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"/><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"/><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"/></svg>`,
    }
  ];

  ngOnInit() {
    if(this.data["connectAds"]) {
      this.socialChannelList.splice(1,1);
    }
  }

  close(value){
    this.dialogRef.close(value);
  }
 
}
