<div class="qb-main">
  <div class="qb-header">
    <div class="qb-back-button cursor-pointer" (click)="toStorePreview()">
      <div class="material-icons-outlined qb-arrow">chevron_left</div>
      <div>{{'PROFILE_PAGE.BACK' | i18nTranslate}}</div>
    </div>
    <div class="qb-store-info">{{'PROFILE_PAGE.STORE_INFO' | i18nTranslate}}</div>
  </div>
  <div class="qb-store-details">
    <div class="qb-label">{{'PROFILE_PAGE.STORE_NAME' | i18nTranslate}}</div>
    <div class="qb-input-field">
      <input
        disabled
        class="qb-input"
        type="text"
        placeholder="My Store"
        [(ngModel)]="shopName"
      />
    </div>
    <div class="qb-label">{{'PROFILE_PAGE.CATEGORY' | i18nTranslate}}</div>
    <div class="qb-input-field">
      <div class="qb-input">
        <mat-select class="qb-select" (selectionChange)="selectCategory($event)" [(ngModel)]="category" placeholder="Select the Category">
          <mat-option *ngFor="let item of categories | keyvalue" [value]="item.value.categoryName">{{
            item.value.categoryName | i18nDynamicTranslate : 'POS_PAGE'
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="qb-label" *ngIf="category">{{'PROFILE_PAGE.TAGS' | i18nTranslate}}</div>
    <div class="qb-tags">
      <div [ngClass]="{'qb-tag': !selectedTags.includes(tag), 'qb-selected-tag' : selectedTags.includes(tag) }" *ngFor="let tag of allTags">
        <div>{{tag}}</div>
        <div class="material-icons-outlined qb-add" *ngIf="selectedTags.includes(tag)" (click)="removeTag(tag)">close</div>
        <div class="material-icons-outlined qb-add" *ngIf="!selectedTags.includes(tag)" (click)="addTag(tag)">add</div>
      </div>
    </div>
    <div class="qb-label">{{'PROFILE_PAGE.EMAIL' | i18nTranslate}}</div>
    <div class="qb-input-field">
      <input
      disabled
        class="qb-input"
        type="text"
        placeholder="mystore@gmail.com"
        [(ngModel)]="email"
      />
    </div>
    <div class="qb-label">{{'PROFILE_PAGE.PHONE_NUMBER' | i18nTranslate}}</div>
    <div class="qb-input-field">
      <input
        class="qb-input"
        type="text"
        placeholder="Enter phone number"
        [(ngModel)]="phoneNumber"
      />
    </div>
    <div *ngIf="submit === true && phoneNumber.length === 0" class="qb-error-text">{{'PROFILE_PAGE.ENTER_PHONE_NUMBER' | i18nTranslate}}</div>
    <div class="qb-label">{{'PROFILE_PAGE.ADDRESS' | i18nTranslate}}</div>
    <div class="qb-input-field qb-address-field qb-location-input">
      <textarea class="qb-input" [(ngModel)]="address" type="text"
      (keydown.enter)="$event.preventDefault()"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search></textarea>
      <!-- <input
      type="text"
      class="qb-input-address"
      (keydown.enter)="$event.preventDefault()"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search
      [(ngModel)]="address"
    /> -->
    </div>
    <!-- <div class="qb-label">Store Location</div>
    <div class="qb-input-field qb-location-input">
      <input
        type="text"
        class="qb-input"
        (keydown.enter)="$event.preventDefault()"
        placeholder="Search Nearest Location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
      />
    </div> -->
    <agm-map
      [latitude]="lat"
      [longitude]="long"
      [mapTypeId]="googleMapType"
      (mapClick)="addMarker($event)"
      [zoom]="zoom"
    >
      <agm-marker
        [latitude]="lat"
        [longitude]="long"
        (dragEnd)="markerDragEnd($event)"
        [markerDraggable]="true"
      >
      </agm-marker>
    </agm-map>
    <div class="qb-buttons">
      <div class="secondary-button-border" (click)="cancel()">{{'PROFILE_PAGE.CANCEL' | i18nTranslate}}</div>
      <div class="primary-button" (click)="save()">{{'PROFILE_PAGE.SAVE' | i18nTranslate}}</div>
    </div>
  </div>
</div>
