import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { LeadsRoutingModule } from './leads-routing.module';
import { LeadsListingComponent } from './pages';

@NgModule({
  declarations: [
    LeadsListingComponent
   ],
  imports: [
    SharedModule,
    LeadsRoutingModule,
  ]
})
export class LeadsModule { }
