<div class="qb-body" (scroll)="onScroll($event)">
  <div class="qb-header-div">
    <div class="qb-header">
      <div class="qb-tabs">
        <div
          (click)="selectTab('ALL')"
          [ngClass]="{
            'qb-selected': selectedTab === 'ALL',
            'qb-not-selected': selectedTab !== 'ALL'
          }"
        >
          {{'PARTNERSHIP_PAGE.ALL' | i18nTranslate}}
        </div>
        <div
          (click)="selectTab('STANDARD')"
          [ngClass]="{
            'qb-selected': selectedTab === 'STANDARD',
            'qb-not-selected': selectedTab !== 'STANDARD'
          }"
        >
          {{'PARTNERSHIP_PAGE.PRESET_FORMS' | i18nTranslate}}
        </div>
        <div
          (click)="selectTab('CUSTOM')"
          [ngClass]="{
            'qb-selected': selectedTab === 'CUSTOM',
            'qb-not-selected': selectedTab !== 'CUSTOM'
          }"
        >
          {{'PARTNERSHIP_PAGE.MY_FORMS' | i18nTranslate}}
        </div>
      </div>
      <div class="primary-button" (click)="createForm()">
        {{'PARTNERSHIP_PAGE.CREATE_FROM_SCRATCH' | i18nTranslate}}
      </div>
    </div>
  </div>
  <div class="qb-spinner" *ngIf="formsLoading">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
  <div *ngIf="this.formsList.length == 0 && !formsLoading" class="qb-no-data">
    {{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
  </div>
  <div class="qb-card-div" *ngIf="!formsLoading">
    <div class="qb-card" *ngFor="let form of formsList">
      <div class="qb-action-div">
        <div
          class="material-icons-outlined icons-primary qb-action"
          (click)="clone(form)"
        >
          content_copy
        </div>
      </div>
      <div class="qb-snapshot-div" (click)="clone(form)">
        <img
          [src]="form.snapshotUrl"
          class="qb-image"
          *ngIf="form.snapshotUrl !== null"
        />
        <div>
          <mat-spinner
            diameter="20"
            *ngIf="form.snapshotUrl === null"
          ></mat-spinner>
        </div>
      </div>
      <div class="qb-card-content" (click)="clone(form)">
        <div class="qb-form-name">{{ form.name }}</div>
        <div class="qb-form-details">
          <div>{{'PARTNERSHIP_PAGE.VISIBLE_TO' | i18nTranslate}}: {{ form.scheduleInfo?.showTo }}</div>
          <div>{{'PARTNERSHIP_PAGE.VISIBLE_WHEN' | i18nTranslate}}: {{ form.scheduleInfo?.showWhen }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
