import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WidgetRouteGaurdService } from "src/app/core/services/widget-route-gaurd.service";
import { JourneyAnalyticsViewComponent } from "./pages";

const routes: Routes = [
    {
        path: "",
        component: JourneyAnalyticsViewComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "Journey Analytics",
          title: "Journey Analytics",
          minPath: "Basic",
          id: 59
        },
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JourneyAnalyticsRoutingModule {}
