import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

interface DialogData {
  'nodeId': string
  'nodeType': string,
  'gridId': string,
  'nodeData': {},
  'operation': string,
  'customerEvents': {},
  'previousNodeType': string,
  'previousNodeRelation': string,
  'emailLayouts': {},
  'offers': {},
  'surveyTemplates': {}
}

@Component({
  selector: 'app-camapign-strategy-node-data',
  templateUrl: './camapign-strategy-node-data.component.html',
  styleUrls: ['./camapign-strategy-node-data.component.css']
})
export class CamapignStrategyNodeDataComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CamapignStrategyNodeDataComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  responseData: DialogData;
  dataForm = new FormGroup({
    eventName: new FormControl(this.data.nodeType === 'e-t' || this.data.nodeType === 'r-e' ? this.data.customerEvents[0]['eventName'] : ''),
    days: new FormControl(0, [Validators.required, Validators.min(0)]),
    hours: new FormControl(0),
    minutes: new FormControl(0),
    timeBasedType: new FormControl('Recurring'),
    relationType: new FormControl('NEXT'),
    repeatType: new FormControl('WEEK'),
    startDate: new FormControl(new Date),
    endDate: new FormControl(new Date),
    startTime: new FormControl('08:00'),
    endTime: new FormControl('20:00'),
    emailLayoutId: new FormControl(this.data.nodeType === 'e-c' ? this.data.emailLayouts[0].id : null),
    surveyTemplateId: new FormControl(this.data.nodeType === 's-s' ? this.data.surveyTemplates[0].id : null),
    // surveyTitle: new FormControl('', Validators.required),
    surveyEmailSubject: new FormControl('', Validators.required),
    emailContentType: new FormControl('EMAIL'),
    offerId: new FormControl(this.data.nodeType === 's-o' ? this.data.offers[0].id : null),
    emailSubject: new FormControl('', Validators.required)
  })
  hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  minutes = [0, 10, 20, 30, 40, 50]
  errorMessage = null;
  currentDate = new Date();
  node_event="";
  serviceCountry = sessionStorage.getItem("serviceCountry");

  ngOnInit() {
    this.dataForm.get('relationType').setValue(this.data.previousNodeRelation);
    if(this.data['nodeUpdate']==true){
     this.node_event="node-modify"
    }else{
      this.node_event="node-drag-drop"
    }
    if (this.data.operation === 'update' && this.data.nodeData) {
      this.assignNodeData();
    }
    this.currentDate.setDate(this.currentDate.getDate() - 1)
  }

  closeDialog(status) {
    let responseData: DialogData = { nodeId: '', gridId: '', nodeData: {}, operation: '', nodeType: '', customerEvents: {}, previousNodeType: '', previousNodeRelation: '', emailLayouts: {}, surveyTemplates: {}, offers: {} };
    let result;
    responseData.gridId = this.data.gridId;
    responseData.nodeType = this.data.nodeType;
    responseData.operation = this.data.operation;
    responseData.previousNodeType = this.data.previousNodeType;
    responseData.nodeId = this.data.nodeId;
    responseData.previousNodeRelation = this.dataForm.get('relationType').value;
    if (status) {
      if(this.validateNodeData()){
        return;
      }
      responseData.nodeData = this.getDataForNode();
    }
    else {
      responseData.nodeData = this.data.nodeData;
    }
    this.dialogRef.close({'status':status,'data':responseData});
  }

  getDataForNode() {
    switch (this.data.nodeType) {
      case 'e-t':
        this.data.nodeData = {
          event: this.dataForm.get('eventName').value,
        }
        break;
      case 't-b':
        this.data.nodeData = {
          type: this.dataForm.get('timeBasedType').value,
          startDate: this.dataForm.get('timeBasedType').value === 'Recurring' || this.dataForm.get('timeBasedType').value === 'Scheduled' ? this.formatDate(this.dataForm.get('startDate').value) + 'T' + this.dataForm.get('startTime').value + ':00' : this.formatDate(this.currentDate) +'T'+ this.formatTime(new Date()),
          endDate: this.dataForm.get('timeBasedType').value === 'Recurring' ? this.formatDate(this.dataForm.get('endDate').value) + 'T' + this.dataForm.get('endTime').value + ':00': null,
          repeatType: this.dataForm.get('timeBasedType').value === 'Recurring' ? this.dataForm.get('repeatType').value : 'NO_REPEAT'
        }
        break;
      case 'r-e':
        this.data.nodeData = {
          event: this.dataForm.get('eventName').value,
          timeUnit: 'MINUTE',
          timeoutValue: this.getTimeInMinutes()
        }
        break;
      case 's-s':
        this.data.nodeData = {
          surveyTemplateId: this.dataForm.get('surveyTemplateId').value,
          // surveyTitleName: this.dataForm.get('surveyTitle').value,
          surveyEmailSubject: this.dataForm.get('surveyEmailSubject').value
        }
        break;
      case 'e-c':
        this.data.nodeData = {
          'channelType': this.dataForm.get('emailContentType').value,
          'subject': this.dataForm.get('emailSubject').value,
          'emailLayoutId': this.dataForm.get('emailLayoutId').value
        }
        break;
      case 's-c':
        this.data.nodeData = {
          event: this.dataForm.get('eventName'),
          timeUnit: 'MINUTE',
          timeoutValue: this.data.previousNodeType === 'r-e' ? this.getTimeInMinutes() : 0
        }
        break;
      case 'p-n':
        this.data.nodeData = {
          event: this.dataForm.get('eventName'),
          timeUnit: 'MINUTE',
          timeoutValue: this.data.previousNodeType === 'r-e' ? this.getTimeInMinutes() : 0
        }
        break;
      case 'w-n':
        this.data.nodeData = {
          timeUnit: 'MINUTE',
          timeUnitValue: this.getTimeInMinutes()
        }
        break;
      case 's-o':
        this.data.nodeData = {
          offerId: this.dataForm.get('offerId').value
        }
        break;
    }
    return this.data.nodeData;
  }

  getTimeInMinutes() {
    return (this.dataForm.get('days').value * 1440) + (this.dataForm.get('hours').value * 60) + (this.dataForm.get('minutes').value);
  }

  assignNodeData() {
    switch (this.data.nodeType) {
      case 'e-t':
        if (this.data.nodeData != null) {
          this.dataForm.get('eventName').setValue(this.data.nodeData['event']);
        }
        break;
      case 't-b':
        if (this.data.nodeData != null) {
          this.dataForm.get('timeBasedType').setValue(this.data.nodeData['type']);
          if (this.data.nodeData['type'] === 'Recurring' || this.data.nodeData['type'] === 'Scheduled') {
            this.dataForm.get('startDate').setValue(this.data.nodeData['startDate'].substring(0, 10));
            this.dataForm.get('startTime').setValue(this.data.nodeData['startDate'].substring(11, 16));
            if (this.data.nodeData['type'] === 'Recurring') {
              this.dataForm.get('endDate').setValue(this.data.nodeData['endDate'].substring(0, 10));
              this.dataForm.get('endTime').setValue(this.data.nodeData['endDate'].substring(11, 16));
              this.dataForm.get('repeatType').setValue(this.data.nodeData['repeatType']);
            }
          }
        }
        break;
      case 'r-e':
        if (this.data.nodeData != null) {
          this.dataForm.get('eventName').setValue(this.data.nodeData['event']);
          this.setTimeInMinutes('r-e');
        }
        break;
      case 's-s':
        if (this.data.nodeData != null) {
          this.dataForm.get('surveyTemplateId').setValue(this.data.nodeData['surveyTemplateId']);
          // this.dataForm.get('surveyTitle').setValue(this.data.nodeData['surveyTitleName']);
          this.dataForm.get('surveyEmailSubject').setValue(this.data.nodeData['surveyEmailSubject']);
        }
        break;
      case 'e-c':
        if (this.data.nodeData != null) {
          //this.dataForm.get('emailContentType').setValue(this.data.nodeData['channelType']);
          this.dataForm.get('emailSubject').setValue(this.data.nodeData['subject'])
          this.dataForm.get('emailLayoutId').setValue(this.data.nodeData['emailLayoutId']);
        }
        break;
      case 's-c': break;
      case 'p-n': break;
      case 'w-n':
        if (this.data.nodeData != null) {
          this.setTimeInMinutes('w-n');
        }
        break;
      case 's-o':
        if (this.data.nodeData != null) {
          this.dataForm.get('offerId').setValue(this.data.nodeData['offerId']);
          break;
        }
    }
  }

  setTimeInMinutes(type) {
    let time = type === 'r-e' ? this.data.nodeData['timeoutValue'] : this.data.nodeData['timeUnitValue'];
    let days = +((Math.floor(time / 1440)).toFixed());
    let hours: any = +(Math.floor((time - (days * 1440)) / 60)).toFixed();
    let minutes = time - (days * 1440) - (hours * 60);
    this.dataForm.get('days').setValue(days);
    this.dataForm.get('hours').setValue(hours);
    this.dataForm.get('minutes').setValue(minutes);
  }

  getTimeBasedNodeType() {
    if (this.data.nodeData['startDate'] === null && this.data.nodeData['endDate'] === null && this.data.nodeData['repeatType'] === null) {
      return 'Instant';
    }
    else if (this.data.nodeData['startDate'] !== null && this.data.nodeData['endDate'] === null && this.data.nodeData['repeatType'] === null) {
      return 'Scheduled'
    }
    else return 'Recurring'
  }

  formatDate(date) {
    var datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd');
  }

  formatTime(date) {
    var datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'HH:mm:ss');
  }

  validateNodeData() {
    this.errorMessage = null;
    switch (this.data.nodeType) {
      case 'r-e':
        if (this.dataForm.get('days').errors) {
          if (this.dataForm.get('days').errors.required) {
            this.errorMessage = this._i18nDynamicTranslate.transform("Days cannot be empty", ['SHARED_PAGE'])
            ;
          }
          if (this.dataForm.get('days').errors.min) {
            this.errorMessage = this._i18nDynamicTranslate.transform("Days value should be greater than 0", ['SHARED_PAGE']);
          }
          return true;
        }
        return false;
      case 's-s':
        // if (this.dataForm.get('surveyTitle').errors) {
        //   this.errorMessage = 'Survey Tilte cannot be empty'
        //   return true;
        // }
        if (this.dataForm.get('surveyEmailSubject').errors) {
          this.errorMessage = this._i18nDynamicTranslate.transform("Email Subject cannot be empty", ['SHARED_PAGE'])
          return true;
        }
        return false;
      case 'e-c':
        if (this.dataForm.get('emailSubject').errors) {
          this.errorMessage = this._i18nDynamicTranslate.transform("Email Subject cannot be empty", ['SHARED_PAGE'])
          return true;
        }
        return false;
      case 'w-n':
        if (this.dataForm.get('days').errors) {
          if (this.dataForm.get('days').errors.required) {
            this.errorMessage = this._i18nDynamicTranslate.transform("Days cannot be empty", ['SHARED_PAGE']);
          }
          if (this.dataForm.get('days').errors.min) {
            this.errorMessage = this._i18nDynamicTranslate.transform("Days value should be greater than 0", ['SHARED_PAGE'])
          }
          return true;
        }
       default : return false;
    }
    // return this.data.nodeData;
  }

}
