import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode: string , symbolDisplay: boolean ): any {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 3, // Maximum number of decimal places
    }).format(value);

    if (symbolDisplay) {
      // Add space after the currency symbol
    //   return formattedValue.replace(currencyCode, currencyCode + 'aaaa ');
      return formattedValue.replace(/[0-9]/, (match) => ''+ match);
    }

    return formattedValue;
  }
}
