import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyParentService {

  constructor() { }

  routeParamId;

  private subjects = new Subject<any>();

  publish(eventName: string) {
    this.subjects[eventName] = this.subjects[eventName] || new Subject();
    this.subjects[eventName].next();
  }

  on(eventName: string):Observable<any> {
    this.subjects[eventName] = this.subjects[eventName] || new Subject();
    return this.subjects[eventName].asObservable();
  }

  setId(id){
    this.routeParamId = id;
  }

  getId(){
    return this.routeParamId;
  }
}
