<div class="header">
  <span class="header-title"><strong>{{'SHARED_PAGE.INITIATE_PARTNERSHIP' | i18nTranslate}}</strong></span>
  <mat-dialog-actions>
    <button class="xIcon" mat-dialog-close>
      <div class="material-icons" style="margin-left: -4.5px; font-size: 15px">
        clear
      </div>
    </button>
  </mat-dialog-actions>
</div>
<div>
  <div class="enterEmail">
    {{'SHARED_PAGE.CREATE_REWARD_PARAGRAGH' | i18nTranslate : data.name}}
  </div>
  <div style="font-size: 14px" class="enterEmail">
    <strong>{{'SHARED_PAGE.PARTNER_REWARD_DETAIL' | i18nTranslate}}</strong>
  </div>
  <div class="selectDomainName">
    <div style="display: flex;  margin: 15px 0 5px;
    ">
    <span class="filterTitle">{{'SHARED_PAGE.REWARD_NAME' | i18nTranslate}}</span>
    <div class="i-div" style="    margin: 0 2px 0 6px;
        " type="button" [ngbPopover]="popContent1" triggers="mouseenter:mouseleave">
          <ng-template #popContent1>
            <div style="border-radius: 4px; margin: -9px -12px; padding: 10px;
            font-size: 12px;">
               {{'SHARED_PAGE.REWARD_NAME_PARAGRAPH' | i18nTranslate}}
            </div>
          </ng-template>
          <div class="i">i</div>
        </div>
       </div>
      <!--</div> -->
    <input type="text" [(ngModel)]="rewardName" (input)="keypress('name')" />
    <div *ngIf="errorName.length > 0" style="color: red; font-size: 11px">
      {{ errorName }}
    </div>
    <div style="display: flex;  margin: 15px 0 5px;
    ">
    <div class="filterTitle">{{'SHARED_PAGE.REWARD_VALUE' | i18nTranslate}}</div>
    <div class="i-div" style="    margin: 0 2px 0 6px;
        " type="button" [ngbPopover]="popContent" triggers="mouseenter:mouseleave">
          <ng-template #popContent>
            <div style="border-radius: 4px; margin: -9px -12px; padding: 10px;
            font-size: 12px;">
               {{'SHARED_PAGE.REWARD_VALUE_PARAGRAPH' | i18nTranslate}}
            </div>
          </ng-template>
          <div class="i">i</div>
        </div>
      </div>
      <div style="border: 1px solid #636466;display: flex;
      width: 65%;border-radius: 5px;align-items: center;padding-right: 10px;">
    <input type="number" [(ngModel)]="rewardValue" (input)="keypress('value')" style="border: none;"/>
    <div>%</div>
    </div>
    <div *ngIf="errorValue.length > 0" style="color: red; font-size: 11px">
      {{ errorValue }}
    </div>
  </div>
  <div class="buttons">
    <mat-dialog-actions>
      <button
        class="cancel secondary-button-border"
        (click)="submit()"
        data-button-name="Canceled"
      >
        {{'SHARED_PAGE.CANCEL' | i18nTranslate}}
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button
        class="confirm primary-button"
        (click)="check()"
        data-button-name="Confirm: Reward Added"
      >
        {{'SHARED_PAGE.SEND_REQUEST' | i18nTranslate}}
      </button>
      <!-- <button class="confirm secondary-button" style="width: 76px; justify-content: center;" *ngIf="apiloading"><mat-spinner diameter="15"></mat-spinner></button> -->
    </mat-dialog-actions>
  </div>
</div>
