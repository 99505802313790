import { Component, OnInit } from '@angular/core';
import { assetPath } from 'src/app/configs/asset-path-config';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  imagePath1 = assetPath.path + '/Ellipse177.svg';
  imagePath2 = assetPath.path + '/Ellipse178.svg';
  imagePath3 = assetPath.path + '/Group5641.svg';

  packSelectCompleted:string="INPROGRESS" ;  //[COMPLETED,INPROGRESS,NOTSTARTED]
  orderSummaryCompleted:string ="NOTSTARTED";
  bookingConfirmationCompleted:string="NOTSTARTED";
  progressSection:string = "PACKSELECT";//[PACKSELECT ,ORDERSUMM,BOOKINGCONF]
  norepeat="no-repeat";

  currentSectionMessage:string="paymentplan";



  constructor() { }
  message:string;

  // receiveMessage($event) {
  //   this.message = $event
  // }
  ngOnInit() {
  }
//   onActivate(componentReference) {
//     this.currentSectionMessage=componentReference['message'];

//     if(this.currentSectionMessage === "paymentplan"){
//       this.packSelectCompleted="INPROGRESS";
//       this.orderSummaryCompleted="NOTSTARTED";
//       this.bookingConfirmationCompleted="NOTSTARTED";
//       this.progressSection="PACKSELECT";
//     }
//     else if(this.currentSectionMessage === "ordersummary"){
//       this.packSelectCompleted="COMPLETED";
//       this.orderSummaryCompleted="INPROGRESS";
//       this.bookingConfirmationCompleted="NOTSTARTED";
//       this.progressSection="ORDERSUMM";
      
//     }
    
//     else if(this.currentSectionMessage === "bookingconfirmation"){
//       this.packSelectCompleted="COMPLETED";
//       this.orderSummaryCompleted="COMPLETED";
//       this.bookingConfirmationCompleted="COMPLETED";
//       this.progressSection="BOOKINGCONF";
      
//     }

  

  
//  }


}
