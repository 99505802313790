import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
  HostListener,
} from "@angular/core";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { Options } from "@angular-slider/ngx-slider";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, ViewportScroller } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ProductGameListDialogComponent, PreviewTemplateComponent, SuccessDialogComponent, ConfirmationDialogBoxComponent, DeleteGameDialogBoxComponent, ExcludeProductDialogComponent, ContactUsDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
interface gamerequest {
  // "createdAt": "2012-02-15T15:12:21-05:00",
  id: string;
  title: string;
  description: string;
  merchantId: string;
  discountRange: {
    lowerLimit: number;
    upperLimit: number;
  };
  isEnabledForAnonymousUser: boolean;
  isEnabledForLoggedInUser: boolean;
  enabledCustomerSegments: number[];
  totalGameSteps: number;
  productsPerStep: number;
  initialHaltDuration: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  coolOffPeriod: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  redeemPeriod: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  retries: number;
  autoPilot: boolean;
  widgetPosition: {
    top: number;
    left: number;
    default: string;
  };
  widgetIcon: string;
  widgetBackgroundColor: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  recurrence: string;
  daysOfWeek: string[];
  emailSubject: string;
  emailTemplateLink: string;
  isScheduled: boolean;
  isPaused: boolean;
}

export interface scheduleObject {
  daysOfWeek: string[];
  endDate: Date;
  startDate: Date;
  endTime: string;
  startTime: string;
  recurrence: string;
}
const GAME_CONFIG_COMPLETE_FLAG = true;
const GAME_CONFIG_INCOMPLETE_FLAG = false;

@Component({
  selector: "app-set-game-configuration",
  templateUrl: "./set-game-configuration.component.html",
  styleUrls: ["./set-game-configuration.component.css"],
})
export class SetGameConfigurationComponent implements OnInit {
  @ViewChild("userSegment") elementViewProfile: any;
  @ViewChildren("el", { read: ElementRef }) el: QueryList<ElementRef>;
  @ViewChild("gfDragBoundary") gfDragBoundary: ElementRef;
  @ViewChild("pickerTo") elementpickerTo: any;
  @ViewChild("pickerFrom") elementpickerFrom: any;
  @ViewChild('panel', { read: ElementRef }) public panel: ElementRef<any>;
  gameRequestObj: gamerequest;
  liveGameRequestObj: gamerequest;
  selectedStep: number = 3;
  gameStepsIconConfig = {
    1: "Product and Discount",
    2: "Target Audience",
    3: "Game Steps",
    4: "Layout & Style",
    5: "Schedule",
  };

  title = "materialApp";
  stepsCompleted: number = 1;
  options: Options = {
    floor: 5,
    ceil: 95,
  };
  loading: boolean = false;
  tempSegmentGameFilter = "";
  customerSelectedSegment = ["ALL"];
  customerSegment = [];
  disableAllCustomerSelection = false;
  disableCustomerSegmentSelection = false;
  allCustomerSegmentIds = [];
  dropdownStatusSegment = false;
  isEnabledForAnonymousUser: boolean = false;

  autopilotStatus: boolean = false;
  isAdvancedSettingslideOpen: boolean = false;
  boxPosition = "BOTTOM_RIGHT";

  dragPosition = { x: 0, y: 0 };

  widgetPositionCordinates = {
    x: null,
    y: null,
  };
  productAndDiscount: any = {
    lowerLimit: 10,
    upperLimit: 20,
    categoryProductList: [],
  };
  gameSteps: any = {
    steps: 2,
    productsPerStep: 2,
    initialHalt: 15,
    coolOffPeriod: {
      days: 0,
      hours: 0,
      minutes: 2
    },
    timeToRedeemReward: {
      days: 0,
      hours: 0,
      minutes: 15,
    },
    retries: 3,
  };

  shopifyStorePages = {
    Home: "/",
    Products: "/products",
    Collections: "/collections",
    Blog: "/blog",
    About: "/about",
    Contact: "/contact",
    Cart: "/cart",
    Search: "/search",
  };
  showOnSelectedPages = ["ALL"];

  widgetIconImageList = {
    "1": "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/widget-icons/icon-1.svg",
    "2": "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/widget-icons/icon-2.svg",
    "3": "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/widget-icons/icon-3.svg",
  };
  selectedwidget;
  recurrence = null;
  widgetBackground = "#c7c7c8";
  scheduleDataObj = {} as scheduleObject;
  selectedStartDateString;
  MinDateString;
  startDateMinValue;
  currentTimeMin;
  timeErrorText = "";
  schedleConfigRadio = "DATE_RANGE";
  atAnyTime = true;
  endDateMinValue;
  tomorrowDate;
  allDaysList = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  days = [
    { name: "SUNDAY", status: false },
    { name: "MONDAY", status: false },
    { name: "TUESDAY", status: false },
    { name: "WEDNESDAY", status: false },
    { name: "THURSDAY", status: false },
    { name: "FRIDAY", status: false },
    { name: "SATURDAY", status: false },
  ];
  EveryDayofTheWeek: boolean = true;
  weekDaysChips: any[] = [
    { name: "SUN", selected: false, full: "SUNDAY" },
    { name: "MON", selected: false, full: "MONDAY" },
    { name: "TUE", selected: false, full: "TUESDAY" },
    { name: "WED", selected: false, full: "WEDNESDAY" },
    { name: "THU", selected: false, full: "THURSDAY" },
    { name: "FRI", selected: false, full: "FRIDAY" },
    { name: "SAT", selected: false, full: "SATURDAY" },
  ];
  arrayDynamic = [];
  id;
  layout;
  selectedTab = null;
  gameId = null;
  section:number = 2;
  snapshotUrl;
  startDate;
  endDate;
  emailSubject="Here’s your discounted products offer";
  transformX = 0;
  transformY = 0;
  isContinueBtnLoading = false;
  isSaveBtnLoading = false;
  isDiscardBtnLoading = false;
  storeImageUrl = null;
  selected = true;
  snapshotUrlCheck = false;
  snapshotUrlCheckCount = 0;
  appSubscriptionPlan;
  validWidgets = null;
  StepsDone=1;
  totalProducts=0;
  excludedProductCount=0;
  gameName="";
  temporaryProductId=[];
  serviceCountry = sessionStorage.getItem("serviceCountry");
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public currentDay: number = new Date().getDate();
  public start: Date = new Date(this.fullYear, this.month, this.currentDay);
  public end: Date = new Date(this.fullYear, this.month, this.currentDay);
  public dateFormat: String = "yyyy-MM-dd";
  allcustomer = [{ id: "ALL_CUSTOMERS", name: "All customers" }];
  @HostListener("document:click", ["$event"])
  onGlobalClick(event): void {
    if (this.stepsCompleted === 2) {
      if (this.elementViewProfile.nativeElement.contains(event.target)) {
      } else {
        this.toggleSelectSegment(1);
      }
    }
  }
  constructor(
    private _apiCall: ApiCallService,
    private _snackBar: SnackbarCollection,
    private _router: Router,
    private _datePipe: DatePipe,
    private _TokenStorageService: TokenStorageService,
    private route: ActivatedRoute,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private scroll: ViewportScroller,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  ngOnInit() {
    this.getMerchantWebsiteSnapshot();
    this._activatedRoute.queryParams.subscribe((params) => {
      if (params.id) {
        this.gameId = params.id;
        //  this.mainTopicChange(params.type);
      }
      if (params.section) {
        this.section = Number(params.section);
      //  this.section=Number(this.section)
        this.stepsCompleted = Number(params.section);
      //  this.stepsCompleted=Number(this.stepsCompleted)
      } else {
        this.section = 1;
      }
    });
    this.initGameVariables();
    this.loading = true;

    this.getCustomerSegment();

    if (this.gameId) {
      this.getGameData(this.gameId);
    } else {
      this.startDate = this.datePipe.transform(this.start, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(this.end, "yyyy-MM-dd");
      this.initGameVariables();
    }
    let id = this.route.snapshot.queryParams.id;
    let snap;
    this._apiCall.getNegotiatorEmailTemplate(id).subscribe((response) => {
      snap = response["body"]["snapshotUrl"];
      this.snapshotUrl = snap;
    });
    this.getWidgets()
    this.getExcludeandAllProductsCount();
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  loadSnapShot() {
    let id = this.route.snapshot.queryParams.id;
    let snap;
    this._apiCall.getNegotiatorEmailTemplate(id).subscribe((response) => {
      snap = response["body"]["snapshotUrl"];
      this.snapshotUrl = snap;
    });
  }
  initGameVariables() {
    this.selectedwidget = this.widgetIconImageList["1"];
    this.loading = false;

    const currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.scheduleDataObj["startDate"] = this.startDateMinValue;
    this.scheduleDataObj["endDate"] = new Date(
      this.startDateMinValue.getFullYear(),
      this.startDateMinValue.getMonth() + 1,
      this.startDateMinValue.getDate()
    );
    this.scheduleDataObj["startTime"] = "00:00";
    this.scheduleDataObj["endTime"] = "23:59";
    this.scheduleDataObj["daysOfWeek"] = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    this.scheduleDataObj["recurrence"] = "ONCE";
    this.EveryDayofTheWeek = true;
    this.atAnyTime = true;
    this.initDateVar();
  }
  SearchCategory="";
  openItemDialogwithSearchData(data) {
      if (data.length > 2) {
        this.openItemDialogwithoutSearchData(data)
      }
  }
  openItemDialogwithoutSearchData(data) {
    this.temporaryProductId=this.productAndDiscount.categoryProductList
    const dialogRef = this._dialog.open(ProductGameListDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "480px",
      data: {productsAndCategory:this.temporaryProductId, search: data, id: this.gameId}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.productAndDiscount.categoryProductList = [];
        this.productAndDiscount.categoryProductList.push({
          productIds: result["ProductId"],
          categoryIds: result["CategoryId"],
          allCategoryIds: result["AllCategoryId"],
        });
      // let reqbody = {
      //   excludedProductElements: {
      //     all: false,
      //     categories:
      //       this.productAndDiscount.categoryProductList[0]["categoryIds"],
      //     products: this.productAndDiscount.categoryProductList[0]["productIds"] ,
      //     allcategories:
      //       this.productAndDiscount.categoryProductList[0]["allCategoryIds"],
      //   },
      // };
      // this._apiCall.updateGame(reqbody, this.gameId).subscribe((response)=>{
      // })
      this.getExcludeandAllProductsCount();
    }
    else{

    }
    });
  }
  advancedSettingsChangeSlide(): void {
    this.isAdvancedSettingslideOpen = !this.isAdvancedSettingslideOpen;
  }
  continueButtonClicked(action) {
    switch (this.stepsCompleted) {
      case 1:
        this.step1Completed(action);
        this.scrollToLocation("step2");
        break;
      case 2:
        if (this.customerSelectedSegment.length != 0 || action == 0) {
          this.step2Completed(action);
          this.scrollToLocation("step3");
        } else {
          this.isContinueBtnLoading = false;
          this.selected = false;
        }
        break;
      case 3:
        // if (
        //   this.gameSteps.productsPerStep <= 5 &&
        //   this.gameSteps.productsPerStep >= 2 &&
        //   this.gameSteps.steps <= 5 &&
        //   this.gameSteps.steps >= 2
        // )
          this.step3Completed(action);
          this.scrollToLocation("step4");
        // else {
        //   this.isContinueBtnLoading = false;
        //   this.selected = false;
        // }
        break;
      case 4:
        if (this.showOnSelectedPages.length != 0 || action == 0) {
          this.step4Completed(action);
          this.scrollToLocation("step5");
        } else {
          this.isContinueBtnLoading = false;
          this.selected = false;
        }
        break;
      case 5:
        if (
          (this.emailSubject.length != 0 &&
            (this.timeErrorText.length === 0 || this.atAnyTime) &&
            (this.scheduleDataObj["startTime"] != null || this.atAnyTime) &&
            (this.scheduleDataObj["endTime"] != null || this.atAnyTime)) ||
          action == 0
        ) {
          this.step5Completed(action);
        } else {
          this.isContinueBtnLoading = false;
          this.selected = false;
        }
        break;
    }
    document
            .getElementById("panelList")
            .scrollIntoView({ behavior: "smooth", block: "start" });
  }

  initSectionProgressCountIncrement(section) {
    switch (section) {
      case 1: {
        this.stepsCompleted++;
        this.section++;
        break;
      }
      case 2: {
        this.stepsCompleted++;
        this.section++;
        break;
      }
      case 3: {
        this.stepsCompleted++;
        this.section++;
        this.setBoxPosition(this.boxPosition);

        break;
      }
      case 4: {
        this.stepsCompleted++;
        this.section++;
        break;
      }
      case 5: {
        this.stepsCompleted++;
        this.section++;
        break;
      }      
    }
    this.routeUpdate(this.stepsCompleted);
  }

  previousButtonClicked() {
    this.stepsCompleted > 0 ? this.stepsCompleted-- : null;
    if (this.stepsCompleted == 4) {
      this.setBoxPosition(this.boxPosition);
    }
    this.routeUpdate(this.stepsCompleted);
  }
  changeSection(section){
    this.stepsCompleted=section;
    this.scrollToLocation("step"+(this.section));
    if (this.stepsCompleted == 4) {
      this.setBoxPosition(this.boxPosition);
    }
    this.routeUpdate(this.stepsCompleted,0) 
  }
  getCustomerSegment() {
    //NEED
    this._apiCall.getAllCustomerSegments().subscribe(
      (response) => {
        if (response["message"] == "success") {
          let segment = JSON.parse(response["body"]);
          for (let i = 0; i < segment.length; i++) {
            this.customerSegment.push({
              id: segment[i]["id"],
              name: segment[i]["segmentName"],
              isActive: segment[i]["isActive"],
              status: false,
            });
          }
        } else {
          this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load customer segments", ['POS_PAGE']), 1000);
        }
      },
      (err) => {
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']), 1000);
      }
    );
  }
  toggleSelectSegment(click?) {
    let element = document.getElementById("multi-select-dropdown-seg");
    let segmentSelect = document.getElementById("segment-select-seg");
    let segmentSelectPanel = document.getElementById("multi-select-panel-seg");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-seg"
    );
    if (click && element && segmentSelect && segmentSelectPanel) {
      this.dropdownStatusSegment = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.border = "1px solid #636466";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      if (this.dropdownStatusSegment) {
        this.dropdownStatusSegment = false;
        element.style.height = "0px";
        segmentSelect.style.overflow = "hidden";
        segmentSelectPanel.style.boxShadow = "unset";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(0deg)";
        //element.style.display = 'none';
      } else {
        this.dropdownStatusSegment = true;
        element.style.height = "200px";
        segmentSelect.style.overflow = "auto";
        segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(180deg)";
        //element.style.display = 'block';
      }
    }
  }
  addAllCustomers(event) {
    this.customerSelectedSegment = [];
    if (event["checked"] === true) {
      this.customerSelectedSegment = [];
      this.customerSelectedSegment.push("ALL");
    }
  }
  addAllShowOnPage(event) {
    this.showOnSelectedPages = [];
    if (event === true) {
      this.showOnSelectedPages = [];
      this.showOnSelectedPages.push("ALL");
    }
  }
  AllPageSelected = false;
  showOnPages = [];
  changeShowOn() {
    if (this.showOnSelectedPages.indexOf("ALL") !== -1) {
      this.showOnSelectedPages = [];
      this.showOnSelectedPages.push("ALL");
      this.AllPageSelected = true;
    } else {
      this.AllPageSelected = false;
    }
  }
  isChecked(segment) {
    if (
      this.customerSelectedSegment.length === 1 &&
      this.customerSelectedSegment[0] === "ALL"
    ) {
      return false;
    } else {
      let index = this.customerSelectedSegment.findIndex(
        (x) => x === segment.id
      );
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  isCheckedShowOnPage(page) {
    if (
      this.showOnSelectedPages.length === 1 &&
      this.showOnSelectedPages[0] === "ALL"
    ) {
      return false;
    } else {
      let index = this.showOnSelectedPages.findIndex((x) => x === page.id);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  updateCheckboxValuesCustomerSegment(segment) {
    if (
      this.customerSelectedSegment.length === 1 &&
      this.customerSelectedSegment[0] === "ALL"
    ) {
      this.customerSelectedSegment = [];
    }
    let index = this.customerSelectedSegment.findIndex((x) => x === segment.id);
    if (index < 0) {
      this.customerSelectedSegment.push(segment.id);
    } else {
      this.customerSelectedSegment.splice(index, 1);
    }
  }
  updateShowOnPage(page) {
    if (
      this.showOnSelectedPages.length === 1 &&
      this.showOnSelectedPages[0] === "ALL"
    ) {
      this.showOnSelectedPages = [];
    }
    let index = this.showOnSelectedPages.findIndex((x) => x === page.value);
    if (index < 0) {
      this.showOnSelectedPages.push(page.value);
    } else {
      this.showOnSelectedPages.splice(page, 1);
    }
  }
  async routeToCreateSegmentScreen() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ){
      this._router.navigate([
        "/app/segments/create-segment",
        { id: null, clone: false, gameId: this.gameId },
      ]);

    }
  }
  toggleHelper() {
    this.el.toArray()[0].nativeElement.classList.toggle("active");
    const panel = this.el.toArray()[0].nativeElement.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  async setBoxPosition(value) {
    this.boxPosition = value;
    if (document.getElementById("chat-btn-pos")) {
      let widget1 = document.getElementById("chat-btn-pos");

      if (null == value) {
        widget1.style.top = this.transformX + "px";
        widget1.style.left = this.transformY + "px";
        return;
      }
      // }

      // widget1?  widget1.style.transform = "translate(-50%, -50%)": null;
      widget1.style.top = "0px";
      widget1.style.left = "0px";
      switch (value) {
        case "TOP_LEFT":
          widget1.style.transform = "translate(2px, 2px)";
          break;
        case "TOP_CENTER":
          widget1.style.transform = "translate(175px, 0px)";
          break;
        case "TOP_RIGHT":
          widget1.style.transform = "translate(433px, 2px)";
          break;
        case "MIDDLE_LEFT":
          widget1.style.transform = "translate(2px, 140.5px)";
          break;
        case "CENTER":
          widget1.style.transform = "translate(230px, 140.5px)";
          break;
        case "MIDDLE_RIGHT":
          widget1.style.transform = "translate(433px, 140.5px)";
          break;
        case "BOTTOM_LEFT":
          widget1.style.transform = "translate(2px, 263px)";
          break;
        case "BOTTOM_CENTER":
          widget1.style.transform = "translate(245px, 420px)";
          break;
        case "BOTTOM_RIGHT":
          widget1.style.transform = "translate(433px, 263px)";
          break;
        case "CUSTOM":
          widget1.style.top = this.transformX + "px";
          widget1.style.left = this.transformY + "px";
          // widget1.style.transform = "translate(x, y)";
          break;
      }
    } else {
      var $this = this;
      const interval = setTimeout(function () {
        $this.setBoxPosition(value);
      }, 100);
    }
  }

  styleChangeLive(event) {
    this.widgetBackground = event;
  }

  startDateChange(action) {
    let startDate = new Date(this.scheduleDataObj["startDate"]);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.scheduleDataObj["endDate"]);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (startDate >= endDate) {
      this.scheduleDataObj["endDate"] = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 1
      );
    }

    this.checkStartDateTime();
  }

  endDateChange(action) {
    let startDate = new Date(this.scheduleDataObj["startDate"]);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.scheduleDataObj["endDate"]);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (endDate <= startDate) {
      this.scheduleDataObj["startDate"] = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() - 1
      );
      this.checkStartDateTime();
    }
  }

  checkStartDateTime() {
    this.selectedStartDateString = this.scheduleDataObj["startDate"]
      ? new Date(this.scheduleDataObj["startDate"]).toDateString()
      : null;
    this.MinDateString = this.startDateMinValue.toDateString();
    if (
      this.selectedStartDateString == this.MinDateString &&
      this.currentTimeMin > this.scheduleDataObj["startTime"]
    ) {
      this.scheduleDataObj["startTime"] = null;
    }

    // if (
    //   this.selectedStartDateString == this.MinDateString &&
    //   this.currentTimeMin > this.scheduleDataObj["endTime"]
    // ) {
    //   this.scheduleDataObj["endTime"] = null;
    // }

    // if (
    //   this.startDate.value.setHours(0, 0, 0, 0) ==
    //   this.startDateMinValue.setHours(0, 0, 0, 0)
    // ) {
    //   this.startMinTime = this.currentDateTimeMinValue;
    //   this.endMinTime = this.addMinutes(this.startMinTime, 1);
    //   if (
    //     this.dateCompareSub(
    //       this.currentDateTimeMinValue,
    //       this.startTime.value
    //     ) == 1
    //   ) {
    //     this.startTime.setValue(null);
    //   }
    //   if (
    //     this.endTime.value != null &&
    //     this.dateCompareSub(this.currentDateTimeMinValue, this.endTime.value) ==
    //       1
    //   ) {
    //     this.endTime.setValue(null);
    //   }
    // } else {
    //   this.startMinTime = "00:00";
    //   this.endMinTime = this.addMinutes(this.startMinTime, 1);
    // }
  }

  checkTime() {
    var startD, endD;
    startD = this._datePipe.transform(
      this.scheduleDataObj["startDate"],
      "yyyy-MM-dd"
    );
    endD = this._datePipe.transform(
      this.scheduleDataObj["endDate"],
      "yyyy-MM-dd"
    );
    // var startDateYear = this.datePipe
    //     .transform(
    //       this.scheduleDataObj["startDate"],
    //       "yyyy-MM-dd"
    //     )
    //     .split("-")[0],
    //   endDateYear = this.datePipe
    //     .transform(
    //       this.scheduleDataObj["endDate"].value,
    //       "yyyy-MM-dd"
    //     )
    //     .split("-")[0];
    // var startDateMonth = startDateYear.split("-")[0],
    //   endDateMonth = endDateYear.split("-")[0];
    // var startDateDate = startDateMonth.split("-")[1],
    //   endDateDate = endDateMonth.split("-")[1];

    if (this.scheduleDataObj["startTime"] > this.scheduleDataObj["endTime"]) {
      if (startD == endD) {
        this.timeErrorText =
          "Ending time cannot be before starting time";
        return 1;
      } else {
        if (this.schedleConfigRadio == "DATE_ONLY" && this.atAnyTime == false) {
          this.timeErrorText = "Ending time cannot be before starting time";
          return 1;
        }
        if (
          this.schedleConfigRadio == "DATE_RANGE" &&
          this.atAnyTime == false
        ) {
          this.timeErrorText = "End time should not be before start time";
          return 1;
        }
        if (
          this.scheduleDataObj["recurrence"] == "WEEKLY" &&
          this.scheduleDataObj["startTime"] > this.scheduleDataObj["endTime"]
        ) {
          this.timeErrorText = "Ending time cannot be before starting time";
          return 1;
        } else if (
          this.scheduleDataObj["recurrence"] == "DAILY" &&
          this.scheduleDataObj["startTime"] > this.scheduleDataObj["endTime"]
        ) {
          this.timeErrorText = "Ending time cannot be before starting time";
          return 1;
        } else {
          this.timeErrorText = "";
          return 0;
        }
      }
    } else {
      this.timeErrorText = "";
      return 0;
    }
  }

  initDateVar() {
    const currentTime = new Date();
    const currentDate = new Date();
    const tomorrow = new Date(currentTime.setDate(currentTime.getDate() + 1));
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.endDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.tomorrowDate = new Date(
      tomorrow.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.currentTimeMin = this.startDateMinValue.toTimeString();

    this.MinDateString = this.startDateMinValue.toDateString();
  }
  toggleSelection(day: any) {
    day.selected = !day.selected;
    this.arrayDynamic = [];
    for (let day of this.weekDaysChips) {
      if (day.selected) {
        this.arrayDynamic.push(day.full);
      }
    }
  }
  toggleSelectionAllDays(event) {
    this.arrayDynamic = [];
    if (event.checked === true)
      for (let day of this.weekDaysChips) {
        if (!day.selected) {
          day.selected = !day.selected;
        }

        if (day.selected) {
          this.arrayDynamic.push(day.full);
        }
      }
    else {
      for (let day of this.weekDaysChips) {
        this.arrayDynamic.slice(day.full);
        day.selected = !day.selected;
      }
    }
  }

  showPreview(edit?) {
    this.id = this.route.snapshot.queryParams.id;
    this._apiCall.getNegotiatorEmailTemplate(this.id).subscribe((response) => {
      let emailTemplate = response["body"];
      let data = {
        selectedLayout: emailTemplate["html"],
        templateId: null,
        templateName: "Negotiator Reward Email",
        gameId: this.gameId,
      };
      this.layout = emailTemplate["html"];
      let finalScreenHeight =
        document.getElementById("set-game-container").offsetHeight;
      let finalScreenWidth =
        document.getElementById("set-game-container").offsetWidth;
      let sh = finalScreenHeight * (90 / 100);
      let sw = finalScreenHeight * (70 / 100);
      const dialogRef = this._dialog.open(PreviewTemplateComponent, {
        panelClass: "previewDialog",
        height: sh + "px",
        width: finalScreenWidth < 1500 ? "1024px" : sw + "px",
        minWidth: "1024px",
        maxWidth: "99vw",
        disableClose: true,
        autoFocus: false,
        data: {
          obj: data,
          type: "NEGOTIATOR_EMAIL",
          height: finalScreenHeight,
          isEditAllowed: true,
          editGame: edit,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        // this.loadSnapShot();
        if (result) {
          this.timeStamp = new Date().getTime();
          this.snapshotUrl = this.snapshotUrl + "?" + this.timeStamp;
          this.snapshotUrlCheckCount = 0; 
          var snap;
          this.snapshotUrl=true;
          let interval = setTimeout(() => {
            this._apiCall.getNegotiatorEmailTemplate(this.gameId).subscribe((response) => {
              snap = response["body"]["snapshotUrl"];
              this.snapshotUrl = snap;
            });   
            this.snapshotUrl=false;            
            clearInterval(interval);
          }, 2000);
        }
      });
    });
  }
  timeStamp;
  setGameParameters(reqObj, action, lastStep?) {
    this._apiCall.updateGame(reqObj, this.gameId).subscribe(
      (response) => {
        // this.setBoxPosition(this.boxPosition);
        if (action) {
          if (lastStep) {
            const dialogRef = this._dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Your game has been successfully enabled", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("Back to Game List", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this._router.navigate(["/app/gamify"]);
              } else {
                this.isContinueBtnLoading = false;
                this.isSaveBtnLoading = false;
              }
            });
          } else {
            if(response['body']['qbErrors'] == null)
              this.initSectionProgressCountIncrement(this.stepsCompleted);
            else{
              document.getElementById('qbGmRetries').innerHTML="";
              document.getElementById('qbGmRedeemPeriod').innerHTML="";
              document.getElementById('qbGmCoolOffPeriod').innerHTML="";
              document.getElementById('qbGmIntialHalt').innerHTML="";
              document.getElementById('qbGmProductsPerStep').innerHTML="";
              document.getElementById('qbGmGameSteps').innerHTML="";
              document.getElementById('qbGmExcludedProducts').innerHTML="";

              for(const x in response['body']['qbErrors'])
                document.getElementById(response['body']['qbErrors'][x]['uiField']).innerHTML = response['body']['qbErrors'][x]['message'];
            this.isContinueBtnLoading = false;
            this.selected = false;
            }
          }
        } else {
        if(response['body']['qbErrors'] == null){
          this._router.navigate(["/app/gamify"]);
        }
        else{
          // document.getElementById('qbGmEndTime').innerHTML="";
          // for(const x in response['body']['qbErrors'])
          //   document.getElementById(response['body']['qbErrors'][x]['uiField']).innerHTML = response['body']['qbErrors'][x]['message'];
        this.isContinueBtnLoading = false;
        this.selected = false;
        }
        }
        this.isContinueBtnLoading = false;
        this.isSaveBtnLoading = false;
        this.isDiscardBtnLoading = false;
      },
      (error) => {}
    );
  }
  step1Completed(action) {
    let reqbody = {
      discountRange: {
        lowerLimit: this.productAndDiscount.lowerLimit,
        upperLimit: this.productAndDiscount.upperLimit,
      },
      excludedProductElements: {
        all: false,
        categories:
          this.productAndDiscount.categoryProductList[0]["categoryIds"],
        products: this.productAndDiscount.categoryProductList[0]["productIds"],
        allcategories:
          this.productAndDiscount.categoryProductList[0]["allCategoryIds"],
      },
      uiStep: action===0 ? this.StepsDone : this.StepsDone+1,
    };
    this.setGameParameters(reqbody, action, GAME_CONFIG_INCOMPLETE_FLAG);
    this.selected = true;
  }
  step2Completed(action) {
    let reqbody = {
      isEnabledForAnonymousUser: this.isEnabledForAnonymousUser,
      enabledCustomerSegments: {
        all: this.customerSelectedSegment[0] == "ALL" ? true : false,
        elements:
          this.customerSelectedSegment[0] == "ALL"
            ? []
            : this.customerSelectedSegment,
      },
      uiStep:action===0 ? this.StepsDone : this.StepsDone+1,
    };
    this.setGameParameters(reqbody, action, GAME_CONFIG_INCOMPLETE_FLAG);
    this.selected = true;
  }
  step3Completed(action) {
    let reqbody = {
      totalGameSteps: this.gameSteps.steps,
      productsPerStep: this.gameSteps.productsPerStep,
      initialHaltDuration: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: this.gameSteps.initialHalt,
      },
      coolOffPeriod: {
        days: this.gameSteps.coolOffPeriod.days,
        hours: this.gameSteps.coolOffPeriod.hours,
        minutes: this.gameSteps.coolOffPeriod.minutes,
        seconds: 0,
      },
      timeToRedeemReward: {
        days: this.gameSteps.timeToRedeemReward.days,
        hours: this.gameSteps.timeToRedeemReward.hours,
        minutes: this.gameSteps.timeToRedeemReward.minutes,
        seconds: 0,
      },
      // timeToFinishGame: {
      //   days: 1,
      //   hours: 0,
      //   minutes: 5,
      //   seconds: 0,
      // },
      // timePerHurdleItem: {
      //   days: 1,
      //   hours: 0,
      //   minutes: 0,
      //   seconds: 15,
      // },
      maxRetries: this.gameSteps.retries,
      isAutopilot: this.autopilotStatus,
      uiStep:action===0 ? this.StepsDone : this.StepsDone+1,
    };
    let coolOff= (reqbody.coolOffPeriod.minutes/60) + reqbody.coolOffPeriod.hours + (reqbody.coolOffPeriod.days*24);
    let timeToRedeem = (reqbody.timeToRedeemReward.minutes/60) + reqbody.timeToRedeemReward.hours + (reqbody.timeToRedeemReward.days*24);
    if(reqbody.coolOffPeriod.hours>23 || reqbody.timeToRedeemReward.hours>23){
      this.isContinueBtnLoading=false;
      this.selected=false;
      if(reqbody.coolOffPeriod.hours>23)
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Hours shouldn't be greater than 23 in cool of period.", ['POS_PAGE']), 2000);
      else if(reqbody.timeToRedeemReward.hours>23)
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Hours shouldn't be greater than 23 in time to redeem award.", ['POS_PAGE']), 2000);
    }
    else if(reqbody.coolOffPeriod.minutes>59 || reqbody.timeToRedeemReward.minutes>59){
      this.isContinueBtnLoading=false;
      this.selected=false;
      if(reqbody.coolOffPeriod.minutes>59)
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Minutes shouldn't be greater than 59 in cool of period.", ['POS_PAGE']), 2000);
      else if(reqbody.timeToRedeemReward.minutes>59)
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Minutes shouldn't be greater than 59 in time to redeem award.", ['POS_PAGE']), 2000);
    }
    else if(reqbody.coolOffPeriod.seconds>59 || reqbody.timeToRedeemReward.seconds>59){
      this.isContinueBtnLoading=false;
      this.selected=false;
      if(reqbody.coolOffPeriod.seconds>59)
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Seconds shouldn't be greater than 59 in cool of period.", ['POS_PAGE']), 2000);
      else if(reqbody.timeToRedeemReward.seconds>59)
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Seconds shouldn't be greater than 59 in time to redeem award.", ['POS_PAGE']), 2000);
      // else if(reqbody.initialHaltDuration.seconds>59)
      //   this._snackBar.openSnackBar("Initial halt time should be between 0 sec and 60 sec", 2000);
    }
    // else if((reqbody.timeToRedeemReward.hours==0 && reqbody.timeToRedeemReward.minutes==0) && reqbody.timeToRedeemReward.days ==0 ){
    //   this.isContinueBtnLoading=false;
    //   this.selected=false;
    //   this._snackBar.openSnackBar("Time to redeem reward should be between 6 hrs and 30 days", 2000)
    // }
    // else if(reqbody.maxRetries==0 || reqbody.maxRetries >10){
    //   this.isContinueBtnLoading=false;
    //   this.selected=false;
    //   this._snackBar.openSnackBar("Number of retries allowed should be between 0 and 10", 2000)
    // }
    // else if(coolOff<6 || coolOff>(30*24)){
    //   this.isContinueBtnLoading=false;
    //   this.selected=false;
    //   this._snackBar.openSnackBar("Cool off period time should be between 6 hrs and 30 days", 2000)
    // }
    // else if(timeToRedeem<6 || timeToRedeem>(30*24)){
    //   this.isContinueBtnLoading=false;
    //   this.selected=false;
    //   this._snackBar.openSnackBar("Time to redeem reward should be between 6 hrs and 30 days", 2000)
    // }
    else{
      this.setGameParameters(reqbody, action, GAME_CONFIG_INCOMPLETE_FLAG);
      this.selected = true;
    }
    // this.selected = true;
  }
  step4Completed(action) {
    let reqbody = {
      widgetPosition: {
        position: {
          top: this.transformX,
          left: this.transformY,
        },
        preset: this.boxPosition ? this.boxPosition : "CUSTOM",
      },
      widgetShowOnPages: {
        all: this.showOnSelectedPages[0] == "ALL" ? true : false,
        elements:
          this.showOnSelectedPages[0] == "ALL"
            ? null
            : this.showOnSelectedPages,
      },
      widgetIconURL: this.selectedwidget,
      widgetBgColor: this.widgetBackground,
      recurrence: "ONCE",
      uiStep:action===0 ? this.StepsDone : this.StepsDone+1,
    };
    this.setGameParameters(reqbody, action, GAME_CONFIG_INCOMPLETE_FLAG);
    this.selected = true;
  }
  step5Completed(action) {
    this.schedleConfigRadio == "DATE_ONLY";
    let startdate = this.datePipe.transform(
      this.scheduleDataObj["startDate"],
      "yyyy-MM-dd"
    );
    let endDate =
      this.schedleConfigRadio == "DATE_ONLY"
        ? this.datePipe.transform(
            this.scheduleDataObj["startDate"],
            "yyyy-MM-dd"
          )
        : this.datePipe.transform(
            this.scheduleDataObj["endDate"],
            "yyyy-MM-dd"
          );
    let startTime =
      this.atAnyTime == true ? "00:00" : this.scheduleDataObj["startTime"];
    let endTime =
      this.atAnyTime == true ? "23:59" : this.scheduleDataObj["endTime"];
    // if(this.EveryDayofTheWeek ===true){
    //   this.toggleSelectionAllDays();
    // }
    let reqbody = {
      startDate: startdate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      rewardEmailSubject: this.emailSubject,
      isScheduled: action ? true : false,
      isPaused: false,
      daysOfWeek: this.arrayDynamic,
      uiStep:action===0 ? this.StepsDone : this.StepsDone+1,
    };
    if (action == 0) {
      this.setGameParameters(
        reqbody,
        action,
        action ? GAME_CONFIG_COMPLETE_FLAG : GAME_CONFIG_INCOMPLETE_FLAG
      );
      // this._router.navigate(["/app/gamify"]);
    } else {
      const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Enable the game?", ['POS_PAGE']),
          message: "",
          "data-widget": "GAME SCHEDULE",
          "data-button": "Confirm: Schedule",
          "data-item": this.gameId,
          successButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.setGameParameters(
            reqbody,
            action,
            action ? GAME_CONFIG_COMPLETE_FLAG : GAME_CONFIG_INCOMPLETE_FLAG
          );
        } else {
          this.isContinueBtnLoading = false;
        }
      });
      this.selected = true;
    }
  }

  getGameData(gameId) {
    this._apiCall.getGameData(gameId).subscribe((response) => {
      this.stepsCompleted = this.section;
        this.prefillGameData(response["body"]);
    });
  }
  prefillGameData(game) {
    this.gameId = game.id;
    this.gameName = game.title;
    this.StepsDone=game.uiStep !=null ? game.uiStep: 1;
    this.temporaryProductId=game["excludedProductElements"]["products"];
    this.productAndDiscount.lowerLimit = game.discountRange
      ? game.discountRange.lowerLimit
        ? game.discountRange.lowerLimit
        : 10
      : 10;
    this.productAndDiscount.upperLimit = game.discountRange
      ? game.discountRange.upperLimit
        ? game.discountRange.upperLimit
        : 20
      : 20;
    this.isEnabledForAnonymousUser = game.isEnabledForAnonymousUser
      ? true
      : false;
    this.customerSelectedSegment = game.enabledCustomerSegments
      ? game.enabledCustomerSegments.all
        ? ["ALL"]
        : game.enabledCustomerSegments.elements
      : [];
    this.gameSteps.steps = game.totalGameSteps ? game.totalGameSteps : 2;
    this.gameSteps.productsPerStep = game.productsPerStep
      ? game.productsPerStep
      : 3;
    this.gameSteps.initialHalt = game.initialHaltDuration
      ? game.initialHaltDuration.seconds
      : 5;
    this.gameSteps.coolOffPeriod.minutes = game.coolOffPeriod
      ? game.coolOffPeriod.minutes
      : 5;
    this.gameSteps.coolOffPeriod.hours = game.coolOffPeriod
      ? game.coolOffPeriod.hours
      : 0;
    this.gameSteps.coolOffPeriod.days = game.coolOffPeriod
      ? game.coolOffPeriod.days
      : 0;
    this.gameSteps.timeToRedeemReward.days = game.timeToRedeemReward
      ? game.timeToRedeemReward.days
      : 0;
    this.gameSteps.timeToRedeemReward.hours = game.timeToRedeemReward
      ? game.timeToRedeemReward.hours
      : 0;
    this.gameSteps.timeToRedeemReward.minutes = game.timeToRedeemReward
      ? game.timeToRedeemReward.minutes
      : 15;
    this.gameSteps.retries = game.maxRetries ? game.maxRetries : 3;
    this.autopilotStatus = game.isAutopilot ? true : false;
    this.transformX = game.widgetPosition
      ? game.widgetPosition.position.top
      : "345";
    this.transformY = game.widgetPosition
      ? game.widgetPosition.position.left
      : "345";
    this.boxPosition = game.widgetPosition
      ? game.widgetPosition.preset != null
        ? game.widgetPosition.preset
        : game.widgetPosition.position.top != null
        ? game.widgetPosition.preset
        : "BOTTOM_RIGHT"
      : "BOTTOM_RIGHT";
    // this.showOnSelectedPages = game.widgetShowOnPages
    //   ? game.widgetShowOnPages.elements
    //   : [];
    this.showOnSelectedPages = game.widgetShowOnPages.all
      ? ["ALL"]
      : game.widgetShowOnPages.elements;
    this.AllPageSelected = game.widgetShowOnPages.all ? true : false;
    this.selectedwidget = game.widgetIconURL
      ? game.widgetIconURL
      : this.widgetIconImageList["1"];
    this.widgetBackground = game.widgetBgColor ? game.widgetBgColor : "#c7c7c8";
    this.recurrence = game.recurrence ? game.recurrence : "DAILY";
    this.scheduleDataObj["startDate"] = game.startDate
      ? game.startDate
      : null;
    this.scheduleDataObj["endDate"] = game.endDate
      ? game.endDate
      : null;
    this.scheduleDataObj["startTime"] = game.startTime
      ? game.startTime
      : null;
    this.scheduleDataObj["endTime"] = game.endTime
      ? game.endTime
      : null;
    if (game.startDate === game.endDate) {
      this.schedleConfigRadio = "DATE_ONLY";
    } else {
      this.schedleConfigRadio = "DATE_RANGE";
    }
    if (
      this.scheduleDataObj["startTime"] === "00:00:00" &&
      this.scheduleDataObj["endTime"] === "23:59:00"
    ) {
      this.atAnyTime = true;
    } else this.atAnyTime = false;
    (this.emailSubject = game.rewardEmailSubject.length!=0? game.rewardEmailSubject : 'Here’s your discounted products offers'),
      this.productAndDiscount.categoryProductList.push({
        productIds: game["excludedProductElements"]["products"],
        categoryIds: game["excludedProductElements"]["categories"],
        allCategoryIds: [],
      });

    this.arrayDynamic = game["daysOfWeek"] == null ? [] : game["daysOfWeek"];
    if (this.arrayDynamic.length === 7) {
      this.EveryDayofTheWeek = true;
    } else this.EveryDayofTheWeek = false;
    if (game["daysOfWeek"] != null) {
      for (var i = 0; i < game["daysOfWeek"].length; i++) {
        for (var j = 0; j < this.weekDaysChips.length; j++) {
          if (game["daysOfWeek"][i] == this.weekDaysChips[j]["full"]) {
            this.weekDaysChips[j]["selected"] = true;
            break;
          }
        }
      }
    }
    this.loading = false;
  }
  prefillGameDateData(game) {
    // if (game != null) {
    //   if (game["startDate"] == game["endDate"] && game["daysOfWeek"] == null) {
    //     this.schedleConfigRadio = "DATE_ONLY";
    //   } else {
    //     this.schedleConfigRadio = "DATE_RANGE";
    //   }
    //   this.timeConfigEndDate = game["endDate"];
    //   this.timeConfigEndTime = game["endTime"];
    //   this.timeConfigStartDate = games["startDate"];
    //   this.timeConfigStartTime = game["startTime"];
    //   this.timeConfigDays = game["daysOfWeek"];
    //   this.selectedStartDateString = game["startDate"]
    //     ? new Date(game["startDate"]).toDateString()
    //     : null;
    //   this.EveryDayofTheWeek =
    //     game["recurrence"] == "ONCE" || game["recurrence"] == "DAILY"
    //       ? true
    //       : res["recurrence"] == "WEEKLY"
    //       ? false
    //       : false;
    //   this.atAnyTime =
    //     game["recurrence"] == "DAILY"
    //       ? false
    //       : game["startTime"] == "00:00" && game["endTime"] == "23:59"
    //       ? true
    //       : false;
    //   this.arrayDynamic = game["daysOfWeek"] == null ? [] : game["daysOfWeek"];
    //   if (game["daysOfWeek"] != null) {
    //     for (var i = 0; i < game["daysOfWeek"].length; i++) {
    //       for (var j = 0; j < this.weekDaysChips.length; j++) {
    //         if (game["daysOfWeek"][i] == this.weekDaysChips[j]["full"]) {
    //           this.weekDaysChips[j]["selected"] = true;
    //           break;
    //         }
    //       }
    //     }
    //   }
    //   if (this.scheduleDataObj["endDate"] < this.startDateMinValue) {
    //   } else {
    //   }
    // } else {
    // }
  }
  reRouteToCreateSegment() {
    var g = this.gameId;
    this._router.navigate([
      "/app/segments/create-segment",
      { id: null, clone: false, gameId: this.gameId },
    ]);
  }

  radioChange(event) {
    this.startDateChange("start");
    this.checkTime();
  }

  allowDrop(ev) {
    ev.preventDefault();
    let element = document.getElementById("gf-preview-boundary");
    let x, y;
    x = element.getBoundingClientRect().top;
    y = element.getBoundingClientRect().left;
    this.transformX = ev.clientY - x - 15;
    this.transformY = ev.clientX - y - 15;

    let b, r;
    b = 470;
    r = 300;
    this.transformY + 30 > b ? (this.transformY = b - 40) : this.transformY;
    this.transformX + 30 > r ? (this.transformX = r - 40) : this.transformX;
    this.transformX < 0 ? (this.transformX = 0) : this.transformX;
    this.transformY < 0 ? (this.transformY = 0) : this.transformY;
  }

  drag(ev) {}

  drop(ev) {
    ev.preventDefault();
    let widget = document.getElementById("chat-btn-pos");
    widget.style.transform = `translate( ${this.transformY}px, ${this.transformX}px)`;
    this.boxPosition = null;
  }
  clickTime(val) {
    if (val == "START") {
      this.elementpickerFrom.open();
    } else {
      this.elementpickerTo.open();
    }
  }

  discardGameSetup() {
    const dialogRef = this._dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height:"250px",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Game", ['POS_PAGE']),
        // "data-widget": "GAME LISTING",
        // "data-button": "Confirm: Delete",
        "data-item": this.gameId,
        "game-name": this.gameName,
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // if(this.stepsCompleted===1){
        //   let reqbody = {
        //     excludedProductElements: {
        //       all: false,
        //       categories:
        //         [],
        //       products: [],
        //       allcategories:
        //         [],
        //     },
        //   };
        //   this._apiCall.updateGame(reqbody, this.gameId).subscribe((response)=>{
        //   })
        // }
        this._apiCall.deleteGameData(this.gameId).subscribe(
          (response) => {
            this._router.navigate(["/app/gamify"]);
          },
          (err) => {
            this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error in deleting game", ['POS_PAGE']), 1500);
          }
        );
        
      }
      this.isContinueBtnLoading = false;
      this.isSaveBtnLoading = false;
      this.isDiscardBtnLoading = false;
    });
  }
  getMerchantWebsiteSnapshot() {
    this._apiCall
      .getMerchantStoreWebsiteSnapshotImage()
      .subscribe((res: any) => {
        this.storeImageUrl = res["body"];
        if (res["body"] == null) {
          this._apiCall.refreshMerchantStoreWebsiteSnapshotImage().subscribe();
        }
      });
  }
  updateUrl(event) {
    this.snapshotUrlCheck = true;
    if (this.snapshotUrlCheckCount !== 6) {
      let interval = setInterval(() => {
        this.timeStamp = new Date().getTime();
        this.snapshotUrl = this.snapshotUrl + "?" + this.timeStamp;
        this.snapshotUrlCheck = false;
        this.snapshotUrlCheckCount++;
        clearInterval(interval);
      }, 5000);
    } else {
      return 0;
    }
  }
  pilotMode(event) {
    if (event.checked === true) {
      this.gameSteps = {
        steps: 3,
        productsPerStep: 5,
        initialHalt: 3,
        coolOffPeriod: {
          days: 1,
          hours: 0,
          minutes: 0
        },
        timeToRedeemReward: {
          days: 1,
          hours: 0,
          minutes: 0,
        },
        retries: 1,
      };
    }
  }

  routeUpdate(value, section?){
    this._router.navigate(["/app/gamify/create-game"], {
      queryParams: { id: this.gameId, section: value },
    });
    if((this.StepsDone<value) && (section !=0) ){
      this.StepsDone=this.StepsDone+1;
    }
  }
  viewExcludedProduct(){
    const dialogRef = this._dialog.open(ExcludeProductDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "480px",
      data:{gameId: this.gameId}
    });
  }
  getExcludeandAllProductsCount(){
    this._apiCall.getExcludedProductsCount(this.gameId).subscribe((response)=>{
      this.totalProducts=response['body']['totalCount'];
      this.excludedProductCount=response['body']['excludedCount'];
    })
  }
  atAnyTimeChange(event){
    if(event.checked === false){
      this.scheduleDataObj['startTime'] = null;
      this.scheduleDataObj['endTime'] = null;
    }
  }
  scrollToLocation(id) {
      let interval = setInterval(() => {
        let element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          clearInterval(interval);
        }
      }, 10);
    }
    getDateFormat(type?){
      return this.date_format.getDateFormat(type);
    }
}
