<div style="width: 100%; overflow: hidden; padding: 30px;" *ngIf="validWidgets">
  <div class="profile-mobile-header">
    <span
      (click)="reRoute('/app/surveys')"
      class="material-icons-outlined icons-primary profile-back-button-icon"
    >
      arrow_back_ios
    </span>
    <span *ngIf="isAnalysis=='true'">{{'SURVEY_PAGE.SURVEY_ANALYSIS' | i18nTranslate}}</span>
    <span *ngIf="isAnalysis=='false'">{{'SURVEY_PAGE.SURVEY_FEEDBACK' | i18nTranslate}}</span>    
  </div>
  <div class="main-menu">
    <div class="main" *ngIf="(totalCountSENTI > 0 || totalCountNPS > 0) && isAnalysis=='true'">
      <div>
        <h3 class="heading">Summary</h3>
      </div>
      <div class="SurveyName">{{name}}</div>
      <div *ngIf="ASentScoreDetected && (validWidgets | widgetConfig: 13)" class="graphSection-sentiment">
        <span class="Latest-feedback-sentiment heading-icon-flex" style="display: flex;align-items: baseline;">{{ validWidgets | widgetLabel: 13 }}
          <div  popoverClass="my-custom-class" triggers="mouseenter:mouseleave" placement="bottom" [ngbPopover]="popContent">
            <ng-template #popContent>
              <div style="font-size: 12px;color:#fff">
                {{'SURVEY_PAGE.SURVEY_SENTIMENT_INFO_MESSAGE' | i18nTranslate}}
              </div>
            </ng-template>
            <div> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1.65625C6.89062 1.65625 5.09766 2.40625 3.62109 3.90625C2.14453 5.38281 1.40625 7.16406 1.40625 9.25C1.40625 11.3594 2.14453 13.1523 3.62109 14.6289C5.12109 16.1055 6.91406 16.8438 9 16.8438C11.1094 16.8438 12.9023 16.1055 14.3789 14.6289C15.8555 13.1289 16.5938 11.3359 16.5938 9.25C16.5938 7.14062 15.8438 5.34766 14.3438 3.87109C12.8672 2.39453 11.0859 1.65625 9 1.65625ZM2.8125 3.09766C4.52344 1.38672 6.58594 0.53125 9 0.53125C11.4141 0.53125 13.4648 1.38672 15.1523 3.09766C16.8633 4.78516 17.7188 6.83594 17.7188 9.25C17.7188 11.6641 16.8633 13.7266 15.1523 15.4375C13.4648 17.125 11.4141 17.9688 9 17.9688C6.58594 17.9688 4.52344 17.125 2.8125 15.4375C1.125 13.7266 0.28125 11.6641 0.28125 9.25C0.28125 6.83594 1.125 4.78516 2.8125 3.09766ZM7.73438 12.625H8.15625V8.40625H7.73438C7.45312 8.40625 7.3125 8.26562 7.3125 7.98438V7.70312C7.3125 7.42188 7.45312 7.28125 7.73438 7.28125H9.42188C9.70312 7.28125 9.84375 7.42188 9.84375 7.70312V12.625H10.2656C10.5469 12.625 10.6875 12.7656 10.6875 13.0469V13.3281C10.6875 13.6094 10.5469 13.75 10.2656 13.75H7.73438C7.45312 13.75 7.3125 13.6094 7.3125 13.3281V13.0469C7.3125 12.7656 7.45312 12.625 7.73438 12.625ZM8.19141 4.53906C8.42578 4.30469 8.69531 4.1875 9 4.1875C9.30469 4.1875 9.5625 4.30469 9.77344 4.53906C10.0078 4.75 10.125 5.00781 10.125 5.3125C10.125 5.61719 10.0078 5.88672 9.77344 6.12109C9.5625 6.33203 9.30469 6.4375 9 6.4375C8.69531 6.4375 8.42578 6.33203 8.19141 6.12109C7.98047 5.88672 7.875 5.61719 7.875 5.3125C7.875 5.00781 7.98047 4.75 8.19141 4.53906Z" fill="#202224"/>
              </svg></div>
          </div></span><span class="Latest-feedback-sentiment">{{ avgSentimentScore }}</span>
      </div>

      <div style="display: flex; justify-content: space-between; padding-bottom: 20px; align-items: center;" *ngIf="validWidgets | widgetConfig: 13">
        <div style="width: 60%; margin-top: 0">
          <div id="donutarea2"></div>
        </div>

        <div *ngIf="ASentScoreDetected" style="width: 40%; margin-top: 0">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            ">
            <div style="display: flex; margin-bottom: 10px;    align-items: center;">
              <div style="
                width: 14px;
height: 14px;
                  background: #4AD991;
                  margin: 8px;
                "></div>
              <div style="display: flex; flex-direction: column">
                <div class="title">
                  <span style="display: flex">
                    <div class="TypeText">{{'SURVEY_PAGE.POSITIVE' | i18nTranslate}} : </div>
                  </span><span class="TypeText">{{ avgPromoter }}</span>
                </div>
                <!-- <mat-progress-bar class="Promoters" mode="determinate" [value]=avgPromoterPerc>
                            </mat-progress-bar> -->
              </div>
            </div>
            <div style="display: flex; margin-bottom: 10px;    align-items: center;">
              <div style="
                   width: 14px;
height: 14px;
                  background: #FEC53D;
                  margin: 8px;
                "></div>
              <div style="display: flex; flex-direction: column">
                <div class="title">
                  <span style="display: flex">
                    <div class="TypeText">{{'SURVEY_PAGE.NEUTRAL' | i18nTranslate}} : </div>
                  </span><span class="TypeText">{{ avgPassive }}</span>
                </div>
                <!-- <mat-progress-bar class="Neutrals" mode="determinate" [value]="avgPassivePerc">
                            </mat-progress-bar> -->
              </div>
            </div>
            <div style="display: flex; margin-bottom: 10px;    align-items: center;">
              <div style="
                  width: 14px;height: 14px;
                  background: #FF6565;
                  margin: 8px;
                "></div>
              <div style="display: flex; flex-direction: column">
                <div class="title">
                  <span style="display: flex">
                    <div class="TypeText">{{'SURVEY_PAGE.NEGATIVE' | i18nTranslate}} : </div>
                  </span><span class="TypeText">{{ avgDetractor }}</span>
                </div>
                <!-- <mat-progress-bar class="Detractors" mode="determinate" [value]=avgDetractorPerc>
                            </mat-progress-bar> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="ASentScoreDetected" class="seperator1"></div>
      <div *ngIf="!NaNdetected && (validWidgets | widgetConfig: 14)" class="graphSection-sentiment">
        <span class="Latest-feedback-sentiment heading-icon-flex" style="display: flex;align-items: baseline;">{{ validWidgets | widgetLabel: 14 }}
          <div popoverClass="my-custom-class" triggers="mouseenter:mouseleave" placement="bottom" [ngbPopover]="popContent">
            <ng-template #popContent>
              <div style="font-size: 12px;color:#fff">
                {{'SURVEY_PAGE.NPS_SURVEY_INFO_MESSAGE' | i18nTranslate}}
              </div>
            </ng-template>
            <div>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.65625C6.89062 1.65625 5.09766 2.40625 3.62109 3.90625C2.14453 5.38281 1.40625 7.16406 1.40625 9.25C1.40625 11.3594 2.14453 13.1523 3.62109 14.6289C5.12109 16.1055 6.91406 16.8438 9 16.8438C11.1094 16.8438 12.9023 16.1055 14.3789 14.6289C15.8555 13.1289 16.5938 11.3359 16.5938 9.25C16.5938 7.14062 15.8438 5.34766 14.3438 3.87109C12.8672 2.39453 11.0859 1.65625 9 1.65625ZM2.8125 3.09766C4.52344 1.38672 6.58594 0.53125 9 0.53125C11.4141 0.53125 13.4648 1.38672 15.1523 3.09766C16.8633 4.78516 17.7188 6.83594 17.7188 9.25C17.7188 11.6641 16.8633 13.7266 15.1523 15.4375C13.4648 17.125 11.4141 17.9688 9 17.9688C6.58594 17.9688 4.52344 17.125 2.8125 15.4375C1.125 13.7266 0.28125 11.6641 0.28125 9.25C0.28125 6.83594 1.125 4.78516 2.8125 3.09766ZM7.73438 12.625H8.15625V8.40625H7.73438C7.45312 8.40625 7.3125 8.26562 7.3125 7.98438V7.70312C7.3125 7.42188 7.45312 7.28125 7.73438 7.28125H9.42188C9.70312 7.28125 9.84375 7.42188 9.84375 7.70312V12.625H10.2656C10.5469 12.625 10.6875 12.7656 10.6875 13.0469V13.3281C10.6875 13.6094 10.5469 13.75 10.2656 13.75H7.73438C7.45312 13.75 7.3125 13.6094 7.3125 13.3281V13.0469C7.3125 12.7656 7.45312 12.625 7.73438 12.625ZM8.19141 4.53906C8.42578 4.30469 8.69531 4.1875 9 4.1875C9.30469 4.1875 9.5625 4.30469 9.77344 4.53906C10.0078 4.75 10.125 5.00781 10.125 5.3125C10.125 5.61719 10.0078 5.88672 9.77344 6.12109C9.5625 6.33203 9.30469 6.4375 9 6.4375C8.69531 6.4375 8.42578 6.33203 8.19141 6.12109C7.98047 5.88672 7.875 5.61719 7.875 5.3125C7.875 5.00781 7.98047 4.75 8.19141 4.53906Z" fill="#202224"/>
                </svg>
            </div>
           
              
          </div></span><span class="Latest-feedback-sentiment">{{ npsTotalScore }}</span>
      </div>

      <div style="display: flex; justify-content: space-between" *ngIf="validWidgets | widgetConfig: 14">
        <div style="width: 60%;margin-top: 0">
          <div id="donutarea"></div>
        </div>

        <div style="width: 40%; margin-top: 0">
          <div *ngIf="!NaNdetected" style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            ">
            <div style="display: flex; margin-bottom: 10px">
              <div style="
                  width: 14px;
height: 14px;
                  background: #4AD991;
                  margin: 8px;
                "></div>
              <div style="display: flex; flex-direction: column">
                <div class="title">
                  <span style="display: flex">
                    <div class="TypeText">{{'SURVEY_PAGE.PROMOTERS' | i18nTranslate}} : </div>
                  </span><span class="TypeText">{{ promoters }}</span>
                </div>
                <!-- <mat-progress-bar class="Promoters " mode="determinate " [value]=promotersPercentage>
                            </mat-progress-bar> -->
                <div class="Digits-Copy">
                  <span class="text-style-1">
                    <span class="material-icons-outlined icons-primary" style="margin-right: 5px;font-size: 16px;">
                      vpn_key
                      </span>
                      <span *ngIf="mobileView" style="font-size: 14px; color:#0183FB;"(click)=keywordViewPromoter()>{{'SURVEY_PAGE.VIEW_KEYWORD' | i18nTranslate}}</span>
                     </span><span *ngIf="!mobileView" style="overflow-wrap: anywhere"><span *ngFor="
                        let PromoterKey of promotersKeywordArray;
                        let i = index;
                        last as islast
                      " class="text-style-key">{{ PromoterKey }}<span *ngIf="!islast">, </span></span><span
                      *ngIf="promotersKeywordArray[0].length == 0" class="text-style-key">-
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div style="display: flex; margin-bottom: 10px">
              <div style="
                  width: 14px;
height: 14px;
                  background: #FED46E;
                  margin: 8px;
                "></div>
              <div style="display: flex;flex-direction: column">
                <div class="title">
                  <span style="display: flex">
                    <div class="TypeText">{{'SURVEY_PAGE.PASSIVES' | i18nTranslate}} : </div>
                  </span><span class="TypeText">{{ passives }}</span>
                </div>
                <!-- <mat-progress-bar class="Passives " mode="determinate " [value]="passivesPercentage ">
                            </mat-progress-bar> -->
                <div class="Digits-Copy">
                  <span class="text-style-1">  <span class="material-icons-outlined icons-primary" style="margin-right: 5px;font-size: 16px;">
                    vpn_key
                    </span>
                    <span *ngIf="mobileView" style="font-size: 14px; color: #0183FB;"(click)=keywordViewPassive()>{{'SURVEY_PAGE.VIEW_KEYWORD' | i18nTranslate}}</span>
                  
                  </span><span *ngIf="!mobileView" style="overflow-wrap: anywhere"><span *ngFor="
                        let PassiveKey of passivesKeywordArray;
                        let i = index;
                        last as islast
                      " class="text-style-key">{{ PassiveKey }}<span *ngIf="!islast">, </span> </span><span
                      *ngIf="passivesKeywordArray[0].length == 0" class="text-style-key">-
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div style="display: flex; margin-bottom: 10px">
              <div style="
                   width: 14px;
height: 14px;
                  background: #FF6565;
                  margin: 8px;
                "></div>
              <div style="display: flex; flex-direction: column">
                <div class="title">
                  <span style="display: flex">
                    <div class="TypeText">{{'SURVEY_PAGE.DETRACTORS' | i18nTranslate}} : </div>
                  </span><span class="TypeText">{{ detractors }}</span>
                </div>
                <!-- <mat-progress-bar class="Detractors " mode="determinate " [value]=detractorsPercentage>
                            </mat-progress-bar> -->
                <div class="Digits-Copy">
                  <span class="text-style-1">  <span class="material-icons-outlined icons-primary" style="margin-right: 5px;font-size: 16px;">
                    vpn_key
                    </span>
                    <span *ngIf="mobileView" style="font-size: 14px; color: #0183FB;"(click)=keywordViewDetractor()>{{'SURVEY_PAGE.VIEW_KEYWORD' | i18nTranslate}}</span>
                  </span><span *ngIf="!mobileView" style="overflow-wrap: anywhere"><span *ngFor="
                        let DetractorsKey of detractorsKeywordArray;
                        let i = index;
                        last as islast
                      " class="text-style-key">{{ DetractorsKey }}<span *ngIf="!islast">, </span> </span><span
                      *ngIf="detractorsKeywordArray[0].length == 0" class="text-style-key">
                      -
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="
        (NaNdetected && !ASentScoreDetected) ||
        totalCountSENTI == 0 || (totalCountNPS == 0 && !LatestFeedbackDetected)
      " style="display: flex;align-items: center;justify-content: center;" class="Latest-feedback-sentiment">
      {{'SURVEY_PAGE.NO_DATA_FOR_ANALYSIS' | i18nTranslate}}
    </div>
    <div *ngIf="
        LatestFeedbackDetected && (totalCountSENTI > 0 || totalCountNPS > 0)
      " class="seperator1"></div>
    <div *ngIf="LatestFeedbackDetected && !mobileView && isAnalysis=='true'">
      <div style="width: 100%; display: flex; flex-direction: column" *ngIf="validWidgets | widgetConfig: 15">
        <div class="graphSection-sentiment">
          <span class="Latest-feedback-sentiment heading-icon-flex" style="display: flex;align-items: baseline;">{{ validWidgets | widgetLabel: 15
            }} <div popoverClass="my-custom-class" triggers="mouseenter:mouseleave" placement="bottom" [ngbPopover]="popContent">
              <ng-template #popContent>
                <div style="font-size: 12px;color: #fff;">
                  {{'SURVEY_PAGE.VERBATIUM_INFO_MESSAGE' | i18nTranslate}}
                </div>
              </ng-template>
              <div> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.65625C6.89062 1.65625 5.09766 2.40625 3.62109 3.90625C2.14453 5.38281 1.40625 7.16406 1.40625 9.25C1.40625 11.3594 2.14453 13.1523 3.62109 14.6289C5.12109 16.1055 6.91406 16.8438 9 16.8438C11.1094 16.8438 12.9023 16.1055 14.3789 14.6289C15.8555 13.1289 16.5938 11.3359 16.5938 9.25C16.5938 7.14062 15.8438 5.34766 14.3438 3.87109C12.8672 2.39453 11.0859 1.65625 9 1.65625ZM2.8125 3.09766C4.52344 1.38672 6.58594 0.53125 9 0.53125C11.4141 0.53125 13.4648 1.38672 15.1523 3.09766C16.8633 4.78516 17.7188 6.83594 17.7188 9.25C17.7188 11.6641 16.8633 13.7266 15.1523 15.4375C13.4648 17.125 11.4141 17.9688 9 17.9688C6.58594 17.9688 4.52344 17.125 2.8125 15.4375C1.125 13.7266 0.28125 11.6641 0.28125 9.25C0.28125 6.83594 1.125 4.78516 2.8125 3.09766ZM7.73438 12.625H8.15625V8.40625H7.73438C7.45312 8.40625 7.3125 8.26562 7.3125 7.98438V7.70312C7.3125 7.42188 7.45312 7.28125 7.73438 7.28125H9.42188C9.70312 7.28125 9.84375 7.42188 9.84375 7.70312V12.625H10.2656C10.5469 12.625 10.6875 12.7656 10.6875 13.0469V13.3281C10.6875 13.6094 10.5469 13.75 10.2656 13.75H7.73438C7.45312 13.75 7.3125 13.6094 7.3125 13.3281V13.0469C7.3125 12.7656 7.45312 12.625 7.73438 12.625ZM8.19141 4.53906C8.42578 4.30469 8.69531 4.1875 9 4.1875C9.30469 4.1875 9.5625 4.30469 9.77344 4.53906C10.0078 4.75 10.125 5.00781 10.125 5.3125C10.125 5.61719 10.0078 5.88672 9.77344 6.12109C9.5625 6.33203 9.30469 6.4375 9 6.4375C8.69531 6.4375 8.42578 6.33203 8.19141 6.12109C7.98047 5.88672 7.875 5.61719 7.875 5.3125C7.875 5.00781 7.98047 4.75 8.19141 4.53906Z" fill="#202224"/>
                </svg></div>
            </div></span>
        </div>
        <div>
          <div class="feedback-cards" *ngFor="let feedback of feedbacks; i as index">
            <div style="width: 100%;">
            
              <div >
                <div style="
                    display: flex;
                    text-transform: capitalize;
                    flex-direction: column;
                  ">
                  <span class="Title-Copy-2">{{
                    feedback["data"]["customerName"]
                    }}</span>
                </div>
                <div>
                  <div *ngFor="let text of feedback['data']['answers']">
                    <div class="feedbackText" style="width: 90%">
                      <span>{{ text }}</span>
                    </div>
                    <div style="
                        width: 10%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      "></div>
                  </div>
                </div>

                  <div class="feedbackDate">
                {{ feedback["data"]["timestamp"]  | toDateObj | date: getDateFormat("dd MMM yyyy")}} |
                {{ feedback["data"]["timestamp"] | toDateObj| customTime }}
              </div>

              </div>
            </div>
            <!-- <div *ngFor="let feedback of feedbacks; i as index; " style=" border-radius: 3px; background-color: #edf0fa; padding: 2%;margin-bottom: 10px; ">
                        <div>
                            <div style="display:flex;margin-bottom: 10px; ">
                                <div style="display:flex; width:50%; ">

                                    <div style="display: flex; text-transform: capitalize; flex-direction: column; ">
                                        <span class="Title-Copy-2 ">{{feedback['data']['customerName']}}</span>

                                    </div>
                                </div>
                                <div style="width:50%;display: flex; justify-content: flex-end; align-items: center; ">
                                    <span class="Title-Copy-2 ">{{feedback['data']['timestamp'] |date }}</span>
                                </div>
                            </div>
                            <div>
                                <div *ngFor="let text of feedback[ 'data'][ 'answers'] ">
                                    <div class="feedbackText " style="width:90%; ">
                                        <span>{{text}}</span>
                                    </div>
                                    <div style="width: 10%; display: flex; justify-content: flex-end; align-items: center; ">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="mobileView && LatestFeedbackDetected && isAnalysis=='false'">
      <div style="width: 100%; display: flex; flex-direction: column;background: white;" *ngIf="validWidgets | widgetConfig: 15">
        <div class="SurveyName">{{name}}</div>
        <div style="width: 100%;">
          <div *ngFor="let feedback of feedbacks; i as index">
            <div style="display: flex; width: 100%; margin-bottom: 14px; margin-top: 20px;">
              <div style="width: 100%">
                <div style="
                    display: flex;
                    text-transform: capitalize;
                    margin-left: 20px;
                  ">
                  <span class="Title-Copy-2">{{
                    feedback["data"]["customerName"]
                    }}</span>
                </div>
                <div>
                  <div *ngFor="let text of feedback['data']['answers'];">
                    <div class="feedbackText" style="width: 90%;
                                                      margin-left: 20px;
                                                      margin-bottom: 10px;">
                      <span>{{ text }}</span>
                    </div>
                    <div style="
                        width: 10%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      "></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="feedbackDate">
              {{ feedback["data"]["timestamp"]  | toDateObj | date: getDateFormat("dd MMM yyyy")}} |
              {{ feedback["data"]["timestamp"] | toDateObj | customTime }}
            </div>
            <div class="preview-split-line"></div>
          </div>
        </div>
        <div style="height: 50px; background-color: #f8f8f8;"></div>
        <!-- <div class="graphSection-sentiment">
          <span class="Latest-feedback-sentiment" style="display: flex;align-items: baseline;">{{ validWidgets | widgetLabel: 15
            }} <div class="i-div" popoverClass="my-custom-class" triggers="mouseenter:mouseleave" placement="bottom" [ngbPopover]="popContent">
              <ng-template #popContent>
                <div style="font-size: 12px;color: #fff;">
                  This tells the verbatim of feedback as given by your customers.
                </div>
              </ng-template>
              <div class="i">i</div>
            </div></span>
        </div> -->
      </div>
  </div>
  </div>
  <!-- <div id="donutarea "></div> -->
</div>