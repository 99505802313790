<div class="qb-d-block qb-dnd-section qb-h-100 qb-w-100" style="overflow: auto;">
    <div class="qb-row-fluid">
        <div class="qb-dnd-column">
            <div>
                <div class="qb-w-100 qb-jc-space-around">
                    <div class="qb-d-flex qb-ta-center qb-w-100">
                        <h1 class="qb-header-wrapper">{{'PROFILE_PAGE.ADD_ON_MESSAGES' | i18nTranslate}}</h1>
                        <div class="qb-formDivSchedule qb-w-25 cursor-pointer" style="margin: 10px;"
                        (click)="selectOpenClose('currencySelect1')">
                            <mat-select #currencySelect1 [(ngModel)]="currency1" (selectionChange)="changeCurrencySelection('currencySelect1')"  class="qb-fontExo2"
                            color="primary"
                            disableOptionCentering panelclass="qb-myPanelClass">
                                <mat-option value="INR">
                                    IN
                                </mat-option>
                                <mat-option value="USD">
                                    US
                                </mat-option>
                            </mat-select>
                            <div class="qb-keyboard-down-icon-container qb-downward-arrow" id="keyboard-down-icon-container"
                                (click)="selectOpenClose('currencySelect1')">
                                    <mat-icon class="
                                        qb-keyboard-down-icon
                                        mat-icon
                                        notranslate
                                        material-icons
                                        mat-icon-no-color
                                    " role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="qb-d-block">
        <div class="qb-w-100 qb-d-flex qb-jc-center">
            <div class="accordion-scroll-container">
                <div *ngFor="let plan of planDetails">
                    <div class="objectives-card-offer"  *ngIf="(validScreens | screenConfig: getId(plan.addonType))">
                        <div class="qb-ta-center qb-card qb-card-padding">
                            <div class="qb-card-header">
                                <h4 class="qb-card-heading">
                                    {{plan['addonName']}}
                                </h4>
                                <!-- <p class="qb-card-subtitle">
                                    Ideal for QSRs, Restaurants and Retail outlets with less than 10 outlets
                                </p> -->
                            </div>
                            <!-- <hr> -->
                            <div class="qb-card-body">
                                <ul style="padding: 0;">
                                    <li class="qb-d-flex" style="padding: 10px;"> 
                                        <span class="material-icons-outlined qb-done-icon">
                                             done
                                        </span>
                                        <div class="qb-mt-3">
                                            {{plan['units']}}&nbsp;{{plan['addonType']}}
                                        </div>
                                    </li>
                                </ul>
                                <hr>
                                <div class="qb-card-price">
                                    <div class="qb-d-flex" style="justify-content: space-around;">
                                        <!-- <div class="qb-formDivSchedule qb-w-25 cursor-pointer"
                                        (click)="selectOpenClose('currencySelect1')">
                                            <mat-select #currencySelect1 [(ngModel)]="currency1" (selectionChange)="changeCurrencySelection('currencySelect1')"  class="qb-fontExo2"
                                            color="primary"
                                            disableOptionCentering panelclass="qb-myPanelClass">
                                                <mat-option value="INR">
                                                    INR
                                                </mat-option>
                                                <mat-option value="USD">
                                                    USD
                                                </mat-option>
                                            </mat-select>
                                            <div class="qb-keyboard-down-icon-container qb-downward-arrow" id="keyboard-down-icon-container"
                                                (click)="selectOpenClose('currencySelect1')">
                                                    <mat-icon class="
                                                        qb-keyboard-down-icon
                                                        mat-icon
                                                        notranslate
                                                        material-icons
                                                        mat-icon-no-color
                                                    " role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div> -->
                                        <p>
                                            {{ plan['price'][region] | customCurrency: currency1: true }}/mo
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div class="qb-add-icon-position">
                                        <div class="primary-button qb-add-group-button" (click)="buyPlan(plan)">
                                          <span data-button-name="Add New Group">{{'PROFILE_PAGE.BUY_NOW' | i18nTranslate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>