import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-consent-recieved',
  templateUrl: './consent-recieved.component.html',
  styleUrls: ['./consent-recieved.component.css']
})
export class ConsentRecievedComponent implements OnInit {

  StatData=[];
  ConsentDetails=[];
  length;
  pageSize = 10;
  pageIndex = 0;
  mobileMedia = window.matchMedia("(max-width: 896px)");

  constructor(private apiCall: ApiCallService) { }

  ngOnInit() {
    this.getStat();
    this.getDetails();
  }
  getStat(){
    // this.StatData=[{
    //   "consentSmsSentCount":100,
    //   "consentReceivedCount":25,
    //   "consentPendingCount":50,
    //   "consentReminderCount":25
    // }]
    this.apiCall.getConsentStats().subscribe(response=>{
      this.StatData=response['body']
    })

    // console.log(this.StatData)
  }
  getDetails(){
    // this.ConsentDetails=[{
    //   "totalElements": 2,
    //   "consentDetailsBeans": [
    //     {
    //       "emailOrPhone": "+12311321123",
    //       "name": "Skellam1"
    //     },
    //     {
    //       "emailOrPhone": "+132311321123",
    //       "name": "Skellam2"
    //     }
    //   ]
    // }]
    this.apiCall.getConsentDetails(this.pageSize,this.pageIndex).subscribe(response=>{
      this.ConsentDetails=response['body']
      this.length=this.ConsentDetails["totalElements"]
    })
    // this.length=this.ConsentDetails[0]["totalElements"]
  }
  refreshPaginatedData(event){
    this.pageIndex=event.pageIndex;
    this.pageSize=event.pageSize;
    this.getDetails();
  }
  searchString;
  onKeySearch(event,value){
    this.searchString=value;
    this.apiCall.searchConsnetDetails(this.pageSize,this.pageIndex,this.searchString).subscribe((response)=>{
      this.ConsentDetails=response['body']
      this.length=response['body']['consentDetailsBeans']['length']
    })
  }
}
