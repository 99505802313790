import { Injectable } from "@angular/core";
import { ApiCallService } from "./api-call-service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationServiceService {
  public NotificationBody: BehaviorSubject<any> = new BehaviorSubject(null);
  public bannerBody: BehaviorSubject<any> = new BehaviorSubject(null);

  TotalCount;
  SurveyRecordsCount;
  totalElements;
  unSeenNotifications;
  notificationBody;

  constructor(private apiCall: ApiCallService) {}

  getNotification(page, size) {
    this.apiCall.getNotification(page, size).subscribe((response) => {
      this.notificationBody = response["body"];

      this.totalElements = response["body"]["totalElements"];
      this.unSeenNotifications = response["body"]["unSeenNotifications"];
      
      this.setValue(this.notificationBody);
      // return  this.notificationBody;
    });
  }

  setValue(newValue): void {
    this.NotificationBody.next(newValue);
  }
  setUnseenNotificationCount(count) {
    this.notificationBody["unSeenNotifications"] =
      this.notificationBody["unSeenNotifications"] - count;
  }
  getValue(): Observable<number> {
    return this.NotificationBody.asObservable();
  }

  //Banner changes

  getnotificationBanner() {
    //  var response= {
    //     "message": "success",
    //     "body": "[\n  {\n    \"id\": 1,\n    \"merchantId\": 111,\n    \"type\": \"MAINTENANCE\",\n    \"message\": \"Test \",\n    \"colour\": \"#007BFF\",\n    \"status\": \"UNSEEN\",\n    \"createdDate\": \"Sep 22, 2020 9:10:00 AM\",\n    \"expiryDate\": \"Sep 22, 2021 9:10:00 AM\"\n  }\n]"
    // }
    //this.passBannerBody(JSON.parse(response['body']));

    this.apiCall.getNotificationBanner().subscribe((response) => {
      if (response["message"] == "success") {

        this.passBannerBody(JSON.parse(response["body"]));
      }
    });
  }
  passBannerBody(data) {
    if (data) {
      this.bannerBody.next(data);
    }
  }
}
