<!-- <div style="width:100%;">
  <div style="display:flex;">
    <div style="width:100%; display:flex; flex-wrap: wrap;" *ngIf="masterCampaign">
      <div *ngFor="let campaign of masterCampaign; index as i" style="display:flex; width: 25%; margin: 4%;">
        <div style="background: white; border:1px solid #8080804a; width: 100%">
          <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
            <div><span>Journey Name</span></div>
            <div style="color:#a500ff"><span>{{campaign['name']}}</span></div>
          </div>
          <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
            <div><span>Journey Type</span></div>
            <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['campaignTriggerType'] === 'TIME_TRIGGER' ?
              'Scheduled' : campaign['campaignTrigger']['campaignTriggerType'] === 'EVENT_TRIGGER' ? 'Event based' : 'Instant'}}</span></div>
          </div>
          <div *ngIf="campaign['campaignTrigger']['campaignTriggerType'] === 'TIME_TRIGGER'">
            <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
              <div><span>Repeat</span></div>
              <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['campaignTriggerRepeatType'] === 'WEEK' ?
                'Week' : campaign['campaignTrigger']['campaignTriggerRepeatType'] === 'MONTH' ? 'Month' : 'Year'}}
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="campaign['campaignTrigger']['campaignTriggerType'] === 'EVENT_TRIGGER'">
            <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
              <div><span>Event</span></div>
              <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['event']['eventName']}}</span></div>
            </div>
          </div>
          <div>
            <div class="TargetTextDiv" style="margin-top: 2%;">
              <div style="border-radius: 5px; padding: 3%; background: #5cb8e7; color: white; font-size: 1vw;
                        display:flex; justify-content: center; cursor: pointer;" (click)="viewCampaignTemplate(i)">
                <span>View campaign details</span>
              </div>
            </div>
            <div class="TargetTextDiv" style="margin-top: 2%;">
              <div style="border-radius: 5px; padding: 3%; background: #4bd762; color: white; font-size: 1vw;
                      display:flex; justify-content: center; cursor: pointer;" (click)="launchCampaign(campaign['id'])">
                <span>Start campaign</span>
              </div>
            </div>
            <div class="TargetTextDiv" style="margin-top: 2%;">
                <div style="border-radius: 5px; padding: 3%; background: #ff6462; color: white; font-size: 1vw;
                        display:flex; justify-content: center; cursor: pointer;" (click)="deleteTemplate(campaign['id'])">
                  <span>Delete Template</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div style="margin:6%; margin-top:3%; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); border-radius: 8px 8px 0px 0px;"
  class="allFont">
  <div style="width:100%; display:flex; padding-top: 10px; padding-bottom: 10px;
    background: linear-gradient(180deg, #585ED6 0%, #9195e2bd 92.34%); color: white;
    border-radius: 8px 8px 0px 0px; padding-top: 20px; padding-bottom: 20px;">
    <div style="width:70%; display: flex;">
      <div style="width:50%; display: flex; justify-content: center;">
        <span>Journey Template name</span>
      </div>
      <div style="width:50%; display: flex; justify-content: center;">
        <span>Type</span>
      </div>
    </div>
    <div style="width:30%; display: flex; justify-content: center;">
      <span>Actions</span>
    </div>
  </div>
  <div style="width:100%; display:flex; margin-top: 1%; margin-bottom: 1%;
        align-items: center;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.05), 0px 8px 10px 1px rgba(0, 0, 0, 0), 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
      padding-top: 5px; padding-bottom: 5px;" *ngFor="let campaign of masterCampaign; index as i">
    <div style="width:70%; display: flex;">
      <div style="width:50%; display: flex; justify-content: center;">
        <span>{{campaign['name']}}</span>
      </div>
      <div style="width:50%; display: flex; justify-content: center;">
        <span>{{campaign['campaignTrigger']['campaignTriggerType'] === 'TIME_TRIGGER' ?
              'Scheduled' : campaign['campaignTrigger']['campaignTriggerType'] === 'EVENT_TRIGGER' ? 'Event based' : 'Instant'}}</span>
      </div>
    </div>
    <div style="width:30%; display: flex; justify-content: space-around;">
      <div style="width:33.33%; display: flex; justify-content: center;">
        <div (click)="viewCampaignTemplate(i)"
          style=" background: #00b8d4; padding: 6px; border-radius: 5px; cursor: pointer;"><span>view</span></div>
      </div>
      <div style="width:33.33%; display: flex; justify-content: center;">
        <div (click)="launchCampaign(campaign['id'])"
          style=" background: #4bd762; padding: 6px; border-radius: 5px; cursor: pointer;"><span>start</span></div>
      </div>
      <div style="width:33.33%; display: flex; justify-content: center;">
        <div (click)="deleteTemplate(campaign['id'])"
          style=" background: #ff6462; padding: 6px; border-radius: 5px; cursor: pointer;"><span>delete</span></div>
      </div>
    </div>
  </div>
</div> -->


<div
  style="margin:4%; margin-top: 0; background: #FFFFFF; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);">
  <div style="padding: 3%;">
    <span class="fontOpenSans" style="font-size: 14px; text-transform: uppercase; font-weight: bold;">view campaign
      templates</span>
  </div>
  <div style="width:100%; display:flex; color: #979797; border-bottom: 2px solid #9797971f; padding-bottom: 10px;">
    <div style="width:33.33%; display: flex;">
      <span style="padding-left: 15%;" class="fontOpenSans">Journey Template name</span>
    </div>
    <div style="width:33.33%; display: flex; justify-content: center;">
      <span class="fontOpenSans">Type</span>
    </div>
    <div style="width:33.33%; display: flex; justify-content: center;">
      <span class="fontOpenSans">Actions</span>
    </div>
  </div>
  <div class="listRow" [ngClass] = "{'active': i%2}"
    *ngFor="let campaign of masterCampaign; index as i">
    <div style="width:33.33%;">
      <span class="listName fontOpenSans" style="padding-left: 15%; cursor: pointer; display: flex;"
        (click)="viewCampaignTemplate(i)">{{campaign['name']}}</span>
    </div>
    <div style="width:33.33%; display: flex; justify-content: center;">
      <span
        class="fontOpenSans">{{campaign['campaignTrigger']['campaignTriggerType'] === 'TIME_TRIGGER' ?
            'Scheduled' : campaign['campaignTrigger']['campaignTriggerType'] === 'EVENT_TRIGGER' ? 'Event based' : 'Instant'}}</span>
    </div>
    <div style="width:33.33%; display: flex; justify-content: center;">
      <!-- <div style="width:33.33%; display: flex; justify-content: center;">
            <div (click)="getMasterOfferDetails(offer.offerId)"
              style=" background: #00b8d4; padding: 6px; border-radius: 5px; cursor: pointer;"><span>view</span></div>
          </div> -->
      <div style="display:flex;">
        <div>
          <div (click)="updateCampaignTemplate(campaign['id'])"
            style="cursor: pointer; padding-left: 10px; padding-right: 10px; border-right: 1px solid #9797971f;">
            <mat-icon style="color: #71cbe3; opacity: 1;" [matTooltip]="'COMMON_PAGE.EDIT' | i18nTranslate">edit</mat-icon>
          </div>
        </div>
        <div>
          <div (click)="viewCampaignTemplate(i)"
            style="cursor: pointer; padding-left: 10px; padding-right: 10px; border-right: 1px solid #9797971f;">
            <mat-icon style="color: #71cbe3;" [matTooltip]="'COMMON_PAGE.VIEW' | i18nTranslate">visibility</mat-icon>
          </div>
        </div>
        <div>
          <div (click)="launchCampaign(campaign['id'])"
            style="cursor: pointer; padding-left: 10px; padding-right: 10px; border-right: 1px solid #9797971f;">
            <mat-icon style="color: #71cbe3;" [matTooltip]="'COMMON_PAGE.LAUNCH' | i18nTranslate">play_arrow</mat-icon>
          </div>
        </div>
        <div>
          <div (click)="deleteTemplate(campaign['id'])"
            style="padding-left: 10px; padding-right: 10px; cursor: pointer;">
            <mat-icon style="color: #71cbe3;" [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate">delete_outline</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>