<div class="qb-w-100 qb-h-100">
    <div class="qb-main-container-preview">
        <div class="qb-heading-preview">
            <div class="qb-heading-wrapper-preview">
                <div class="heading-text">
                    <span class="qb-heading-text">
                        Template Preview
                    </span>
                </div>
                <!-- <div class="heading-close-icon">
                    <span>
                        <svg class="close-icon" (click) = "close()" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.22222 8L0 1.77778L1.77778 0L8 6.22222L14.2222 0L16 1.77778L9.77778 8L16 14.2222L14.2222 16L8 9.77778L1.77778 16L0 14.2222L6.22222 8Z" fill="#202224"/>
                            </svg>
                    </span>
                </div> -->
              
                <!-- <span>
                    Tetstttttt
                </span> -->
            </div>
        </div>
        <div class="qb-img-conatiner">
            <img [src]="imgUrl" alt="" class="qb-img-size">
        </div>
        <div style="display: flex; justify-content: end;">
            <button class="primary-button close-btn" (click) = "close()">
                Close Preview
            </button>
        </div>
        
    </div>
</div>