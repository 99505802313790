<head>
    <title>
        {{'POS_PAGE.POS_PROFILE_HEADER' | i18nTranslate}}
    </title>
    <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<div class="height-100-perc"> 
    <div class="qb-content-container">
        <div>
            <div *ngIf="!templateLoading; else loadSpinner2">
                <app-email-template-preview *ngIf="selectedLayout" [selectedLayout]="selectedLayout"></app-email-template-preview>
                <div class="qb-session-surveycheck" *ngIf="!selectedLayout">
                    <div class="survey-ssr">
                        <div class="surveycheck-result-text">
                            Email template not found.
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #loadSpinner2>
                <div class="qb-d-flex qb-jc-center qb-loader-center">
                    <app-qubriux-loading [size]="45"></app-qubriux-loading>
                </div>
            </ng-template>
        </div>
    </div>
</div>