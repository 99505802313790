<head>
    <meta charset="utf-8">
      <title> {{'REGISTRATION_PAGE.DATA_INGESTION_HEADING' | i18nTranslate}} </title>
      <meta name="description" content="Onboarding">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
<div>
    <div>
        <div style="height:61px; background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 6px #0000000F;
opacity: 1; width:100%; display:flex; justify-content: flex-end;" *ngIf="!onboarding">
            <div style="display: flex;flex-direction: row;align-items: center; justify-content:space-between;">

                <div class="headerIcons2">
                    <div class="headerUserIcon gradient" [matMenuTriggerFor]="menu " style="cursor:pointer; ">
                        <span *ngIf="userName">{{userName[0]}}</span>
                    </div>
                </div>
                <mat-menu #menu="matMenu">
                    <div class="logout-button" (click)="logout() ">{{'REGISTRATION_PAGE.LOGOUT' | i18nTranslate}}</div>
                </mat-menu>
            </div>
        </div>

        <div style="display: flex;width: 100vw;height: 100vh;justify-content: center;align-items: center;" *ngIf="apiLoading">
            <!-- <p>Clover-onboarding testing</p> -->
            <mat-spinner  diameter="25"></mat-spinner>
        </div>
        <div style="margin-top:4%; display: flex; justify-content: center; align-items: center;" *ngIf="!onboarding&&paramsPos=='shopify'">
            <div class="shop-container" *ngIf="showShopNameInput">
                <div>
                    <img [src]="assetPath.shopifyImg" class="shopify-img" style="width:100%;">
                </div>
                <div class="form-container">
                    <form>
                    <div style="width: 100%;">
                        <div class="label-tohead">Shopify URL</div>

                        <div style="display: flex;width: 100%;align-items: center;justify-content: space-between;">
                            <div style="margin-top: 5px;width: auto;flex-grow: 1;" class="Rectangle"
                                [ngClass]="{'rectangle-invalid' : ((formSubmit || shopName.touched) && (shopName.value === null || shopName.value === '')) ,'rectangle-valid':shopName.touched&&shopName.valid}"
                                *ngIf="!authenticated">
                                <input class="form-input-reg" type="text" placeholder="Shop Name" [formControl]="shopName"
                                    (paste)="onPaste($event)">
                            </div>
                            <div style="margin-left: 5px;">.myshopify.com</div>
                        </div>
                        <!-- </div> -->
                        <div class="invalid-shop-div">
                            <span
                                *ngIf="(formSubmit && shopName.value==null)||(formSubmit || shopName.touched) && (shopName.value == null || shopName.value == '')">Shopify
                                URL cannot be empty</span>
                                <span
                                *ngIf="!((formSubmit && shopName.value==null)||(formSubmit || shopName.touched) && (shopName.value == null || shopName.value == ''))&&isshopExists">
                                Shop is linked with another email. <a style="color: #0183fb;text-decoration: underline;cursor: pointer;" (click)="directToLoginFlow()"> Log in using shop url.</a></span>
                            <!-- <span *ngIf="storeUrl.touched && storeUrl.errors?.pattern">Shop URL not valid</span> -->
                        </div>
                        <div>
                            <div class="shop-name-submit" (click)="getAuthenticated()" [ngClass]="{active:authInProgress}" *ngIf="!authenticated" type="submit">
                                <span class="fontExo2">Submit</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div style="display: flex; justify-content: center;">
                        <span style="font-size: 15px; font-weight: 600;">Shop URL</span>
                    </div>
                    <div style="margin-top: 5px;" class="formDiv" [ngClass]="{'active' : ((formSubmit || shopName.touched) && (shopName.value === null || shopName.value === '')) || (shopName.touched && shopName.errors?.pattern)}" *ngIf="!authenticated">
                        <input class="formInput" type="text" placeholder="shopname.myshopify.com" [formControl]="shopName" pattern="((https:)?\/\/)?[^.\s]+\.myshopify\.com\/?">
                    </div>
                    <div class="invalid-shop-div">
                        <span *ngIf="(formSubmit || shopName.touched) && (shopName.value == null || shopName.value == '')">Shop
                            URL cannot be empty</span>
                        <span *ngIf="shopName.touched && shopName.errors?.pattern">Shop URL not valid</span>
                        <span *ngIf="!(shopName.touched && shopName.errors?.pattern)&&!((formSubmit || shopName.touched) && (shopName.value == null || shopName.value == ''))&&isshopExists">Shop already exists</span>
                    </div>
                    <div>
                        <div class="shop-name-submit" (click)="getAuthenticated()" [ngClass]="{active:authInProgress}" *ngIf="!authenticated">
                            <span class="fontExo2">Submit</span>
                        </div>
                    </div> -->
                </form>
                </div>
            </div>
        </div>

        <div style="margin-top:4%; display: flex; justify-content: center; align-items: center;" *ngIf="!onboarding&&paramsPos=='clover'&&!cloverParams">
            <div class="shop-container">
                <div style="display: flex;justify-content: center;">
                    <img src="https://www.clover.com/static/media/clover-logo.4204a79c.svg" class="clover" style="width:55%;margin:10%">
                </div>
                <div class="form-container">
                    <div style="display: flex; justify-content: center;">
                        <span style="font-size: 15px; font-weight: 600;">Clover</span>
                    </div>
                    <!-- <div style="margin-top: 5px;" class="formDiv" [ngClass]="{'active' : ((formSubmit || shopName.touched) && (shopName.value === null || shopName.value === '')) || (shopName.touched && shopName.errors?.pattern)}" *ngIf="!authenticated">
                        <input class="formInput" type="text" placeholder="shopname.myshopify.com" [formControl]="shopName" pattern="([A-z]|[0-9]|-|\.)+myshopify\.com">
                    </div>
                    <div class="invalid-shop-div">
                        <span *ngIf="(formSubmit || shopName.touched) && (shopName.value === null || shopName.value === '')">Shop
                            URL cannot be empty</span>
                        <span *ngIf="shopName.touched && shopName.errors?.pattern">Shop URL not valid</span>
                    </div> -->
                    <div>
                        <div class="shop-name-submit" (click)="getCloverAuthenticated(null)" [ngClass]="{active:authInProgress}" *ngIf="!authenticated">
                            <span class="fontExo2">Install</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <mat-progress-bar *ngIf="dataFetchInProgress;  else showFetchedData" mode="indeterminate"></mat-progress-bar> -->

    </div>



    <div *ngIf="onboarding" style="font-family: 'Rubik';">

        <div class="containerOnboard">

            <img [src]="s3BucketUrl+'/greyfox-assets/assets2/gradients/dashboard_sample.PNG'
			" alt="" style="width:100vw;height: 100vh;filter: blur(8px);
			-webkit-filter: blur(8px);">
            <div class="contentOnboard" style="display: flex;flex-direction: column;">

                <div style="height: 30%;">

                    <div style="display: flex;flex-direction: column; height:70% ; justify-content: center;align-items: center;">
                        <div [@appear] *ngIf="!completedPerc" class="waitText">
                            Your data is currently cooking.
                            <br> We will let you know via email when the dinner is ready.
                        </div>
                        <div [@appear] *ngIf="!completedPerc" style="display: flex;justify-content: center; margin:10px;font-size: 14px;">
                            <span>It usually takes just 5-10 mins.</span>
                        </div>

                        <div [@appear] *ngIf="completedPerc" class="waitText">
                            We are ready to serve you!
                        </div>
                        <div [@appear] *ngIf="completedPerc" class=" fontExo2 " style="display: flex;justify-content: center; margin:10px ;">

                            <span class="homeReButton" (click)="redirectToHomePage()">Click here to explore the amazing recipes</span>
                        </div>
                    </div>
                    <div style="display: flex;width: 100%; flex-direction:column;align-items: center;height: 30%;position: fixed;">
                        <div style="    height: 15px;
                    border-radius: 20px;
                    display: inline-block;
                    background: rgba(255, 255, 255, 0.05); margin-bottom: 10px;" [style.width.px]="scrWidth">

                            <div [style.width.px]="scrWidth*percWidth" class="full-center positive ">
                                <span style="
                            font-size:13px" [@appear]>{{percWidth*100 | number:'1.0-0'}}%</span></div>
                        </div>



                    </div>
                </div>               
            </div>
        </div>

    </div>


    <div *ngIf="cloverParams&&paramsPos=='clover'">
        <div style="display: flex;width: 100vw;height: 100vh;justify-content: center;align-items: center;">
            <!-- <p>Clover-onboarding testing</p> -->
            <mat-spinner *ngIf="!cloverapiFinished" diameter="25"></mat-spinner>

            <!-- <p *ngIf="cloverapiFinished&&cloverTestSuccess"> API Success</p> -->
            <!-- <p *ngIf="cloverapiFinished&&!cloverTestSuccess">API Failed</p> -->


        </div>
    </div>
</div>