<div class="qb-h-100 qb-w-100">
    <div class="qb-w-h-100">
    
        <div class="store-info-container">
            <div class="section">
                <h2 style="    display: flex;
                gap: 16px;align-items: center; text-transform: capitalize;">You can upload your customer data here</h2>
                <div class="qb-center-center qb-flex-row">
                    <img src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/assets2/illustrations/Group+4760customer.svg"
                        alt="" class="qb-img-450">
                    <div *ngIf="validWidgets | widgetConfig: 62">
                        <div class="primary-button profile-change-image-button" style="margin-left: 10px;" (click)="uploadCSV()">
                            Upload Data
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</div>