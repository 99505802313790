import { Component, OnInit } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent } from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

interface contentData {
  orientation: string;
  boxPosition: number;
  imageURL: string;
  closeButton: boolean;
  addCoupon: boolean;
  couponType: string;
  couponValue: number;
  couponValidity: number;
  heading: {
    content: string;
    style: string;
  };
  subHeading: {
    content: string;
    style: string;
  };
  inputPlaceholder: string;
  button: {
    content: string;
    style: string;
  };
  actionOnButtonClick: string;
  sendEmail: boolean;
  emailTemplateId: string;
  emailTemplateName: string;
  successMessage: string;
  url: string;
  subscribedMessage: string;
  emptyMessage: string;
  invalidMessage: string;
  generalMessage: string;
  contentClicked: boolean;
  selectedLayout: number;
}

interface scheduleData {
  showTo: string;
  segments: [];
  showWhen: string;
  showWhenValue: number;
  deviceVisibility: string;
  showOn: string;
  showOnSpecificUrl: string[];
  selectOtherUrl: string;
  limit: string;
  limitvalue: number;
  scheduleClicked: boolean;
  sendEmail: boolean;
}

interface style {
  color: string;
  fontSize: number;
  fontFamily: string;
  textDecoration: string;
  background: string;
}

@Component({
  selector: "app-new-form",
  templateUrl: "./new-form.component.html",
  styleUrls: ["./new-form.component.css"],
})
export class NewFormComponent implements OnInit {
  constructor(
    private _ApiCallService: ApiCallService,
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _MatDialog: MatDialog,
    private snackBar: SnackbarCollection,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(50)]),
  });
  laptopLogo: string =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/laptop-active.svg";
  mobileLogo: string =
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/mobile-inactive.svg";
  screen: string = "LAYOUT";
  editType: string = "CONTENT";
  scheduleConstants: any;
  contentData: contentData = {
    orientation: "landscape",
    boxPosition: 5,
    imageURL: "",
    closeButton: true,
    addCoupon: false,
    couponType: "",
    couponValue: 10,
    couponValidity: 10,
    heading: {
      content: "WELCOME DISCOUNT",
      style:
        '{"color":"#000000","fontSize":20,"fontFamily":"Open Sans","textDecoration":"None","background":""}',
    },
    subHeading: {
      content: "SIGN UP TO OUR NEWSLETTER FOR 15% OFF YOUR FIRST ORDER!",
      style:
        '{"color":"#000000","fontSize":12,"fontFamily":"Open Sans","textDecoration":"None","background":""}',
    },
    inputPlaceholder: "Enter Your Email",
    button: {
      content: "Submit",
      style:
        '{"color":"#FFFFFF","fontSize":14,"fontFamily":"Open Sans","textDecoration":"None","background":"#0183fb"}',
    },
    actionOnButtonClick: "",
    sendEmail: false,
    emailTemplateId: "",
    emailTemplateName: "",
    successMessage: "",
    url: "URL",
    subscribedMessage: "",
    emptyMessage: this._i18nDynamicTranslate.transform("Please fill in all the required form fields", ['POS_PAGE']),
    invalidMessage: this._i18nDynamicTranslate.transform("Email format is incorrect", ['POS_PAGE']),
    generalMessage: this._i18nDynamicTranslate.transform("Oops! Something went wrong", ['POS_PAGE']),
    contentClicked: false,
    selectedLayout: 1,
  };
  isDemo;
  scheduleData: scheduleData = {
    showTo: "All Visitors",
    segments: [],
    showWhen: "Immediately on website",
    showWhenValue: 0,
    deviceVisibility: "Desktop only",
    showOn: "All URLs",
    showOnSpecificUrl: [],
    selectOtherUrl: "",
    limit: "Show only once",
    limitvalue: 0,
    scheduleClicked: false,
    sendEmail: false,
  };

  changeDetect: boolean = false;
  formId = null;
  finalContent = null;
  duplicateFormNameError = false;
  clone = null;
  formLoading = true;

  ngOnInit() {
    this.isDemo = this.tokenStorage.getIsDemo();
    this.getParams();
    this.getFormConstants();
  }

  getFormConstants() {
    this._ApiCallService.getFormConstants().subscribe((response) => {
      this.scheduleConstants = JSON.parse(response["body"]);
      this.contentData.emailTemplateId =
        this.scheduleConstants.configurations.content.noCoupon.id;
      this.contentData.emailTemplateName =
        this.scheduleConstants.configurations.content.noCoupon.emailLabel;
      this._ActivatedRoute.params.subscribe((param) => {
        if (param["id"] === "-1") {
          this.contentData.actionOnButtonClick =
            this.scheduleConstants.configurations.content.onsubmit[0].value;
          this.contentData.url =
            this.scheduleConstants.configurations.routes[0].path;
          this.scheduleData.showTo =
            this.scheduleConstants.configurations.schedule.showTo[0].value;
          this.scheduleData.showWhen =
            this.scheduleConstants.configurations.schedule.showWhen[0].value;
          this.scheduleData.deviceVisibility =
            this.scheduleConstants.configurations.schedule.devices[0];
          this.scheduleData.showOn =
            this.scheduleConstants.configurations.schedule.showOn[0].value;
          this.scheduleData.limit =
            this.scheduleConstants.configurations.schedule.limit[0].value;
          this.contentData.imageURL =
            "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/overview-image.png";
          this.formLoading = false;
        } else {
          this.formId = param["id"];
          this.getFormData();
        }
      });
    });
  }

  getFormData() {
    this._ApiCallService.getFormData(this.formId).subscribe((response) => {
      let data = JSON.parse(response["body"]);
      if (this.clone) {
        this.form.get("name").setValue(data["name"] + "_copy");
      } else {
        this.form.get("name").setValue(data["name"]);
      }
      this.contentData = JSON.parse(data["formData"]);
      this.scheduleData = JSON.parse(data["scheduleInfo"]);
      this.selectLayout(this.contentData.selectedLayout);
      this.contentDataChange(this.contentData);
    });
  }

  getParams() {
    this._ActivatedRoute.params.subscribe((param) => {
      this.clone = param.clone;
    });
  }

  selectLayout(layout) {
    let imageElement = document.getElementById("dummy-image-container");
    let inputBoxElement = document.getElementById("dummy-input-box");
    let bgImageElement = document.getElementById("dummy-bg-container");
    let offsetContainer = document.getElementById("dummy-offset-container");
    let inputContainerElement = document.getElementById(
      "dummy-input-container"
    );
    let overviewElement = document.getElementById("overview");
    if (this.contentData.orientation === "landscape") {
      inputBoxElement.style.width = "50%";
      offsetContainer.style.margin = "16px 46px";
      switch (layout) {
        case 1:
          imageElement.style.order = "unset";
          imageElement.style.display = "flex";
          inputBoxElement.style.width = "60%";
          bgImageElement.style.display = "none";
          offsetContainer.style.display = "none";
          inputBoxElement.style.padding = "0px";
          inputContainerElement.style.bottom = "0px";
          break;
        case 2:
          imageElement.style.order = "2";
          imageElement.style.display = "flex";
          inputBoxElement.style.width = "60%";
          bgImageElement.style.display = "none";
          offsetContainer.style.display = "none";
          inputBoxElement.style.padding = "0px";
          inputContainerElement.style.bottom = "0px";
          break;
        case 3:
          imageElement.style.display = "none";
          bgImageElement.style.display = "block";
          inputBoxElement.style.width = "50%";
          offsetContainer.style.display = "block";
          inputBoxElement.style.padding = "16px 0px";
          inputContainerElement.style.bottom = "16px";
          break;
        case 4:
          imageElement.style.display = "none";
          inputBoxElement.style.width = "50%";
          bgImageElement.style.display = "none";
          offsetContainer.style.display = "none";
          inputBoxElement.style.padding = "0px";
          inputContainerElement.style.bottom = "0px";
          break;
      }
    } else {
      overviewElement.style.width = "80%";
      inputBoxElement.style.width = "unset";
      overviewElement.style.minWidth = "auto";
      offsetContainer.style.margin = "15px";
      switch (layout) {
        case 1:
        case 2:
        case 4:
          imageElement.style.order = "unset";
          imageElement.style.display = "none";
          inputBoxElement.style.width = "60%";
          bgImageElement.style.display = "none";
          offsetContainer.style.display = "none";
          inputBoxElement.style.padding = "0px";
          inputContainerElement.style.bottom = "0px";
          break;
        case 3:
          imageElement.style.display = "none";
          bgImageElement.style.display = "block";
          offsetContainer.style.display = "block";
          inputBoxElement.style.padding = "16px 0px";
          inputContainerElement.style.bottom = "16px";
          break;
      }
    }
    this.contentData.selectedLayout = layout;
  }

  selectOrientation(orientation) {
    if (this.contentData.orientation !== orientation) {
      this.changeDetect = !this.changeDetect;
    }
    let overviewContainerElement = document.getElementById("layout-oveview");
    this.contentData.orientation = orientation;
    let overviewElement = document.getElementById("layout-oveview");
    if (orientation === "landscape") {
      this.laptopLogo =
        "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/laptop-active.svg";
      this.mobileLogo =
        "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/mobile-inactive.svg";
      overviewElement.style.width = "100%";
      overviewElement.style.borderRight = "1px solid #EDF0F2";
      this.setPosition(overviewContainerElement);
    } else {
      this.laptopLogo =
        "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/laptop-inactive.svg";
      this.mobileLogo =
        "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/mobile-active.svg";
      overviewElement.style.width = "50%";
      overviewElement.style.border = "none";
      overviewContainerElement.style.alignItems = "center";
      overviewContainerElement.style.justifyContent = "center";
    }
    this.selectLayout(this.contentData.selectedLayout);
  }

  editContent() {
    if (!this.form.controls.name.value.replace(/\s/g, "").length) {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please enter a valid form name", ['POS_PAGE']), 2000);
    } else {
      this.form.markAsDirty();
      if (!this.form.invalid) {
        this.saveTemplate();
        let data = {
          formName: this.form.get("name").value,
          type: "CUSTOM",
          status: "DRAFT",
          formHtml: this.finalContent,
          scheduleInfo: this.scheduleData,
          formData: this.contentData,
        };
        if (this.formId === null || this.clone) {
          this.createForm(data);
        } else {
          this.updateForm(data, null, false);
        }
      }
    }
  }

  updateForm(data, editType, navigate) {
    this._ApiCallService.updateForm(data, this.formId).subscribe((response) => {
      if (editType === null && !navigate) this.screen = "CONTENT";
      else if (editType !== null) this.editType = editType;
      else this._Router.navigate(["/app/forms/overview"]);
    });
  }

  createForm(data) {
    this._ApiCallService.createForm(data).subscribe(
      (response) => {
        this.formId = response["body"];
        this._Router.navigate(["/app/forms/new-form", { id: this.formId }]);
        this.screen = "CONTENT";
      },
      (error) => {
        this.duplicateFormNameError = true;
      }
    );
  }

  sendTheNewValue() {
    if (this.clone) {
    }
    if (this.duplicateFormNameError) this.duplicateFormNameError = false;
  }

  contentEditSwitch(editType) {
    if (editType === "SCHEDULE") {
      if (this.contentValidate()) {
        this.saveTemplate();
        let data = {
          formName: this.form.get("name").value,
          type: "CUSTOM",
          status: "DRAFT",
          formHtml: this.finalContent,
          scheduleInfo: this.scheduleData,
          formData: this.contentData,
        };
        this.updateForm(data, editType, false);
      }
    } else {
      this.editType = editType;
    }
  }

  contentValidate() {
    if (
      this.contentData.button.content.length < 1 ||
      !this.contentData.button.content.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (
      this.contentData.heading.content.length < 1 ||
      !this.contentData.heading.content.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (
      this.contentData.subHeading.content.length < 1 ||
      !this.contentData.subHeading.content.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (this.contentData.addCoupon) {
      if (
        this.contentData.couponType === "DD" ||
        this.contentData.couponType === "DP"
      ) {
        if (this.contentData.couponValue < 1) {
          return false;
        }
      }
      if (this.contentData.couponValidity < 1) {
        return false;
      }
    }
    if (
      this.contentData.inputPlaceholder.length < 1 ||
      !this.contentData.inputPlaceholder.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (
      this.contentData.emptyMessage.length < 1 ||
      !this.contentData.emptyMessage.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (
      this.contentData.invalidMessage.length < 1 ||
      !this.contentData.invalidMessage.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (
      this.contentData.generalMessage.length < 1 ||
      !this.contentData.generalMessage.replace(/\s/g, "").length
    ) {
      return false;
    }
    if (
      this.contentData.actionOnButtonClick === "MSG" &&
      (this.contentData.successMessage.length < 1 ||
        !this.contentData.successMessage.replace(/\s/g, "").length)
    ) {
      return false;
    }
    return true;
  }

  contentDataChange(data) {
    let headerElement = document.getElementById("overview-header");
    let subHeaderElement = document.getElementById("overview-p");
    let inputPlaceholderElement = document.getElementById("dummy-input");
    let buttonElement = document.getElementById("dummy-button");
    let overviewContainerElement = document.getElementById("layout-oveview");
    if (this.contentData.orientation === "landscape") {
      this.setPosition(overviewContainerElement);
    } else {
      overviewContainerElement.style.alignItems = "center";
      overviewContainerElement.style.justifyContent = "center";
    }
    this.contentData = data;
    let headerData: style = JSON.parse(this.contentData.heading.style);
    headerElement.innerHTML = this.contentData.heading.content;
    headerElement.style.fontSize = headerData.fontSize.toString() + "px";
    headerElement.style.fontFamily = headerData.fontFamily;
    headerElement.style.color = headerData.color;
    this.setTextDecoration(headerElement, headerData.textDecoration);
    let subHeaderData: style = JSON.parse(this.contentData.subHeading.style);
    subHeaderElement.innerHTML = this.contentData.subHeading.content;
    subHeaderElement.style.fontSize = subHeaderData.fontSize.toString() + "px";
    subHeaderElement.style.fontFamily = subHeaderData.fontFamily;
    subHeaderElement.style.color = subHeaderData.color;
    this.setTextDecoration(subHeaderElement, subHeaderData.textDecoration);
    inputPlaceholderElement.innerHTML = this.contentData.inputPlaceholder;
    let buttonData: style = JSON.parse(this.contentData.button.style);
    buttonElement.innerHTML = this.contentData.button.content;
    buttonElement.style.fontSize = buttonData.fontSize.toString() + "px";
    buttonElement.style.fontFamily = buttonData.fontFamily;
    buttonElement.style.color = buttonData.color;
    buttonElement.style.background = buttonData.background;
    this.setTextDecoration(buttonElement, buttonData.textDecoration);
    this.formLoading = false;
  }

  sheduleDataChange(data) {
    this.scheduleData = data;
  }

  setTextDecoration(element: HTMLElement, decorator) {
    switch (decorator) {
      case "None":
        element.style.fontWeight = "unset";
        element.style.fontStyle = "unset";
        element.style.textDecoration = "unset";
        break;
      case "Bold":
        element.style.fontStyle = "unset";
        element.style.textDecoration = "unset";
        element.style.fontWeight = "bold";
        break;
      case "Italic":
        element.style.fontWeight = "unset";
        element.style.textDecoration = "unset";
        element.style.fontStyle = "italic";
        break;
      case "Underline":
        element.style.fontWeight = "unset";
        element.style.fontStyle = "unset";
        element.style.textDecoration = "underline";
        break;
    }
  }

  setPosition(element: HTMLElement) {
    switch (this.contentData.boxPosition) {
      case 1:
        element.style.justifyContent = "flex-start";
        element.style.alignItems = "flex-start";
        break;
      case 2:
        element.style.justifyContent = "center";
        element.style.alignItems = "flex-start";
        break;
      case 3:
        element.style.justifyContent = "flex-end";
        element.style.alignItems = "flex-start";
        break;
      case 4:
        element.style.justifyContent = "flex-start";
        element.style.alignItems = "center";
        break;
      case 5:
        element.style.justifyContent = "center";
        element.style.alignItems = "center";
        break;
      case 6:
        element.style.justifyContent = "flex-end";
        element.style.alignItems = "center";
        break;
      case 7:
        element.style.justifyContent = "flex-start";
        element.style.alignItems = "flex-end";
        break;
      case 8:
        element.style.justifyContent = "center";
        element.style.alignItems = "flex-end";
        break;
      case 9:
        element.style.justifyContent = "flex-end";
        element.style.alignItems = "flex-end";
        break;
    }
  }

  submit() {
    this.scheduleData.scheduleClicked = true;
    if (this.scheduleValidate()) {
      let dialogRef = this._MatDialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Enable form?", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Enable", ['POS_PAGE']),
          "data-widget": "",
          "data-button": "",
          "data-item": "",
          successButtonText: this._i18nDynamicTranslate.transform("Enable", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.saveTemplate();
          let data = {
            formName: this.form.get("name").value,
            type: "CUSTOM",
            status: "ACTIVE",
            formHtml: this.finalContent,
            scheduleInfo: this.scheduleData,
            formData: this.contentData,
          };
          this.updateForm(data, null, true);
        }
      });
    } else {
      return;
    }
  }

  scheduleValidate() {
    if (this.scheduleData.showTo === "SEGMENTS") {
      if (this.scheduleData.segments.length === 0) {
        return false;
      }
    }
    if (this.scheduleData.showWhen !== "IMMEDIATE") {
      if (this.scheduleData.showWhenValue < 0) {
        return false;
      }
    }
    if (this.scheduleData.showOn === "OTHERS") {
      if (this.scheduleData.showOnSpecificUrl.length === 0) {
        return false;
      }
    }
    if (this.scheduleData.limit === "REPEAT") {
      if (this.scheduleData.limitvalue < 0) {
        return false;
      }
    }
    return true;
  }

  discard() {
    let dialogRef = this._MatDialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Discard form?", ['POS_PAGE']),
        message:
          this._i18nDynamicTranslate.transform("Discard operation will delete this form and you will lose all the configurations", ['POS_PAGE']),
        "data-widget": "",
        "data-button": "",
        "data-item": "",
        successButtonText: this._i18nDynamicTranslate.transform("Discard", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._ApiCallService.deleteForm(this.formId).subscribe((response) => {
          this._Router.navigate(["/app/forms/overview"]);
        });
      }
    });
  }

  saveandExit() {
    let dialogRef = this._MatDialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Save form?", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
        "data-widget": "",
        "data-button": "",
        "data-item": "",
        successButtonText: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.saveTemplate();
        let data = {
          formName: this.form.get("name").value,
          type: "CUSTOM",
          status: "DRAFT",
          formHtml: this.finalContent,
          scheduleInfo: this.scheduleData,
          formData: this.contentData,
        };
        this.updateForm(data, null, true);
      }
    });
  }

  saveTemplate() {
    this.selectOrientation("landscape");
    let content = document.getElementById(
      "layout-overview-container"
    ).innerHTML;
    let container = document.createElement("div");
    container.setAttribute("class", "qb-layout-overview-container");
    container.innerHTML = content;
    container
      .getElementsByClassName("qb-dummy-input")[0]
      .setAttribute("style", "display:none");
    container
      .getElementsByClassName("qb-form-name")[0]
      .setAttribute("style", "display:block");
    let closeButton = container.getElementsByClassName("qb-close-button")[0];
    if (closeButton) {
      closeButton.setAttribute("style", "cursor: pointer");
    }
    let style =
      "<head><link rel='stylesheet' type='text/css' href='https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/forms/forms-style.css'/>  </head><body>";
    this.finalContent =
      style +
      container.innerHTML +
      "<script src='https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/forms/forms-script.js'></script></body>";
  }
}
