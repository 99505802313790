<div class="qb-my-post-container" *ngIf="!postAnalyticsPageSelected">
  <div class="qb-flex qb-w-100 qb-flex-space-bt">
    <div class="qb-my-post">{{'COMMUNICATION_PAGE.MY_POST' | i18nTranslate}}</div>
    <button
      class="primary-button"
      (click)="createNewPost()"
      style="border: none; outline: none"
      data-button-name="Create New Post"
    >
      <mat-icon style="color: #fff; margin: 0; font-size: 16px; height: 18px"
        >add</mat-icon
      >
      {{'COMMUNICATION_PAGE.CREATE_NEW_POST' | i18nTranslate}}
    </button>
  </div>

  <div class="qb-post-list-main-container">
    <div class="qb-social-channel">
      <div class="qb-flex">
        <div
          class="qb-social-channel-title"
          *ngFor="let label of listHeaders; let last = last"
          [style.width.%]="label.width"
        >
          {{ label.title | i18nDynamicTranslate : 'POS_PAGE' }}
        </div>
      </div>

      <div style="width: 100%" class="qb-post-list-container" *ngIf="!loading && total_count>0">
        <div
        class="qb-flex qb-block-height-70-min" 
          *ngFor="let post of postList; let last = last"
        >
         
          <div class="qb-social-channel-value qb-display-flex-center qb-w-55">
            <div style="display: flex; flex-direction: column;width: 100%;">
              <div style="word-wrap: break-word;">{{ post.contents.message }}</div>

              <div style="display: flex; gap: 10px;">
                <div *ngFor="let imageurl of post.contents.images">
                  <img
                    [src]="imageurl"
                    alt=""
                    style="
                      height: 80px;
                      width: 80px;
                      object-fit: contain;
                      border: 1px solid rgba(99, 100, 102, 0.3);
                    "
                  />
                </div>
                <div *ngFor="let videoUrl of post.contents.video">
                  <video
                    style="
                      height: 80px;
                      width: 80px;
                      object-fit: contain;
                      border: 1px solid rgba(99, 100, 102, 0.3);
                    "
                  >
                    <source [src]="videoUrl" />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div
          class="qb-social-channel-value qb-display-flex-center qb-w-15 "
          *ngIf="post.launchTimestamp!=null"
        >
        <span  *ngIf="post.launchTimestamp!=null"></span>
          {{ (post.launchTimestamp)| date: getDateFormat("MMM dd, yyyy")}}
          {{ post.launchTimestamp | customTime }}

          <span *ngIf="post.launchTimestamp==null"> -</span>
        </div>
        <div
          class="qb-social-channel-value qb-display-flex-center qb-w-15 cursor-pointer qb-text-underline"
          (click)="editPost(post.id)"  *ngIf="post.launchTimestamp==null"
        >

          <span *ngIf="post.launchTimestamp==null" matTooltip="Edit"> {{'COMMUNICATION_PAGE.DRAFT' | i18nTranslate}}</span>
        </div>

          <div class="qb-social-channel-value qb-display-flex-center qb-w-15" *ngIf="post['channel'] === 'FACEBOOK'">
            <img
              style="height: 20px; width: 20px"
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/facebook.png"
              alt=""
            />
          </div>

          <div class="qb-social-channel-value qb-display-flex-center qb-w-15" *ngIf="post['channel'] === 'INSTAGRAM'">
            <img
              style="height: 20px; width: 20px"
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Instagram.png"
              alt=""
            />
          </div>
          <div class="qb-social-channel-value qb-display-flex-center qb-w-15">
            Qubriux
          </div>
          <!-- <div class="qb-social-channel-value qb-display-flex-center"> {{ account.new_followers}} </div>
          <div class="qb-social-channel-value qb-display-flex-center"> {{ account.no_of_posts }} </div>
          <div class="qb-social-channel-value qb-display-flex-center"> {{ account.reach }} </div> -->
        </div>
      </div>
        <div style="width: 100%" class="qb-flex-all-center" *ngIf="loading">
          <mat-spinner diameter="15"></mat-spinner>     
      </div>
      <div *ngIf="!total_count && !loading" class="qb-no-data">
        {{'COMMUNICATION_PAGE.NO_POST_AVAILABLE' | i18nTranslate}}
      </div>
    </div>

  </div>
  <div class="paginate-div">
    <mat-paginator #paginator [pageIndex]=0 [length]="total_count" [pageSize]="size"
      [pageSizeOptions]="[2,5, 10, 25, 100]" (page)="getNextData($event)">
    </mat-paginator>
  </div>
</div>

<div
  class="qb-my-post-container"
  style="margin: 24px 0"
  *ngIf="postAnalyticsPageSelected"
>
  <!-- <div class="qb-post-summary-container">
<div class="qb-social-card-title">Post Summary   <span>ID:</span></div>

  </div> -->

  <div class="qb-post-analytics-container">
    <div style="width: 100%;height:100%" *ngIf="!loading">
    <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.POST_ENGAGEMENT' | i18nTranslate}}</div>

    <div style="display: flex; width: 100%">
      <div class="qb-w-16 qb-flex-colum">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.ENGAGEMENT' | i18nTranslate}}</div>

        <div class="qb-social-card-value-blue">
          {{ postAnalyticsObj.engagement }}
        </div>
      </div>

      <div class="qb-w-16 qb-flex-colum">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.REACTION' | i18nTranslate}}</div>

        <div class="qb-social-card-value-blue">
          {{ postAnalyticsObj.reactions }}
        </div>
      </div>
      <div class="qb-w-16 qb-flex-colum">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.COMMENTS' | i18nTranslate}}</div>

        <div class="qb-social-card-value-blue">
          {{ postAnalyticsObj.comments }}
        </div>
      </div>
      <div class="qb-w-16 qb-flex-colum">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.SHARES' | i18nTranslate}}</div>

        <div class="qb-social-card-value-blue">
          {{ postAnalyticsObj.shares }}
        </div>
      </div>
      <!-- <div class="qb-w-16 qb-flex-colum">
        <div class="qb-social-card-value-title">Clicks</div>

        <div class="qb-social-card-value-blue">{{postAnalyticsObj.engagement}}</div>
      </div> -->
      <div class="qb-w-16 qb-flex-colum">
        <div class="qb-social-card-value-title">{{'COMMUNICATION_PAGE.NEGATIVE_FEEDBAKS' | i18nTranslate}}</div>

        <div class="qb-social-card-value-blue">
          {{ postAnalyticsObj.negative_feedback }}
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;min-height:150px;display: flex;align-items: center;justify-content: center;" *ngIf="loading">
  <mat-spinner diameter="15"></mat-spinner>
  </div>
</div>
</div>
