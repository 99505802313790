import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { EmailTemplateCreationDialogComponent } from 'src/app/shared/components/email-template-creation-dialog/email-template-creation-dialog.component';
import { EmailTemplateChooseComponent, StandardEmailTemplateComponent } from './pages';

const routes: Routes = [
    { path: "", redirectTo: "choose-layout", pathMatch: "full" },
    { path: "choose-layout", component: EmailTemplateChooseComponent, canActivate: [WidgetRouteGaurdService],
        data: {
            titleheading: "Email",
            title: "Choose email template",
            minPath: "Basic",
            id: 10,
        }, 
    },
    {
        path: "layout",
        component: StandardEmailTemplateComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "Email",
          title: "Choose email template",
          planPath: "Communication",
          id: 10,
        },
      },
      {
        path: "layout-editor",
        component: EmailTemplateCreationDialogComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "Email",
          title: " Create email template",
          minPath: "Advanced",
          id: 11,
        },
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailRoutingModule { }
