import { DatePipe, getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";

@Component({
  selector: "app-partnership-analytics",
  templateUrl: "./partnership-analytics.component.html",
  styleUrls: ["./partnership-analytics.component.css"],
})
export class PartnershipAnalyticsComponent implements OnInit {
  timeRange1 = "This Week";
  timeRange2 = "This Week";
  timeRange3 = "This Week";
  revCurrency;
  currencySymbol;
  startDate;
  endDate;
  timePeriod;
  totalRevenue = 0;
  totalProductSold = 0;
  customersAcquired = 0;
  totalVisitors = 0;
  noGraph = false;
  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    partnershipRequestId: null,
    startDate: this.datePipe.transform(
      new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString(),
      "yyyy-MM-dd"
    ),
    endDate: this.datePipe.transform(
      new Date(Date.now()).toISOString(),
      "yyyy-MM-dd"
    ),
    dataPointType: "WEEK",
  };
  isPos = false;

  constructor(
    private apiCall: ApiCallService,
    private datePipe: DatePipe,
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.getCurrencySymbol();
    this.getStats();
    if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this.isPos = false;
    }
    else{
      this.isPos = true;
    }
  }

  getStats() {
    this.apiCall.getMyAnalyticsGraphStats(this.data).subscribe(
      (response) => {
        let data = response["body"];
        this.totalRevenue =
          Math.round(data.totalRevenuegenerated * 100.0) / 100.0;
        this.totalProductSold = data.totalProductsSold;
        this.customersAcquired = data.totalCustomersAcquired;
        this.totalVisitors = data.totalVisitors;
      },
      (error) => {
        this.noGraph = true;
      }
    );
  }

  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }

  scroll(element: HTMLElement, direction: number) {
    element.scrollLeft += 1000 * direction;
  }
}
