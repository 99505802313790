import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { SuccessDialogComponent, UploadCsvDialogBoxComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-leads-listing",
  templateUrl: "./leads-listing.component.html",
  styleUrls: ["./leads-listing.component.css"],
})
export class LeadsListingComponent implements OnInit {
  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private snackBar: SnackbarCollection,
    private dialog: MatDialog,
  ) {}

  timeout;
  pageSize = 10;
  pageIndex = 1;
  totalCustomers = 0;
  customers = [];
  showCustomers = "all";
  searchValue = "";
  validWidgets = null;
  filterSegments = [];
  selectedSortBy = "Desc";
  revenueTime = "ALL";
  numberOfOrdersTime = "ALL";
  sub: Subscription;
  loading = false;
  merchantID = this.tokenService.getMerchantId();
  isleads = true;

  @ViewChild("search") searchElement: ElementRef;

  ngOnInit(): void {
    this.getWidgets();
    this.getCustomers();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  onKeySearch(event: any, resetPagination) {
    clearTimeout(this.timeout);
    var $this = this;
    if (event.length !== 0) {
      this.timeout = setTimeout(function () {
        // if (event.keyCode != 13) {
        $this.searchCustomer(event, resetPagination); //Your function goes here
        // }
      }, 600);
    } else {
      //if search value is 0
      this.searchValue = "";
      this.getCustomers(resetPagination);
    }
  }

  searchCustomer(searchValue, resetPagination) {
    this.loading = true;
    this.searchValue = searchValue;
    if (resetPagination) this.pageIndex = 1;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .searchCustomers(
        this.merchantID,
        searchValue,
        this.revenueTime,
        this.numberOfOrdersTime,
        this.pageSize,
        this.pageIndex,
        this.filterSegments,
        true
      )
      .subscribe(
        (response) => {
          this.showCustomers = "search";
          this.customers = response["body"]["customerDataList"];
          this.searchValue = searchValue;
          this.totalCustomers = response["body"]["totalCount"];
          this.customers.forEach((customer) => {
            if (
              customer["customerTag"]
            ) {
              customer['tagValue'] = Object.values(JSON.parse(customer['customerTag']));
            }
          });
          this.loading = false;
        },
        (err) => {
          this.snackBar.openSnackBar("Failed to search customer", 5000);
        }
      );
    if (searchValue === "") this.getCustomers();
  }

  getCustomers(resetPagination?) {
    this.loading = true;
    if (resetPagination) this.pageIndex = 1;
    this.getLeadsApi().then((result) => {
      this.showCustomers = "all";
      this.loading = false;
      if (result) {
        this.customers = result["body"]["leadResponses"];
        this.totalCustomers = result["body"]["customerCount"];
        this.customers.forEach((customer) => {
          if (
            customer["customerTag"]
          ) {
            customer['tagValue'] = Object.values(JSON.parse(customer['customerTag']));
          }
        });
      }
    });
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.showCustomers === "all") this.getCustomers();
    else this.searchCustomer(this.searchValue, false);
  }

  getLeadsApi(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getLeads(this.pageIndex, this.pageSize).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          this.loading = false;
          reject(false);
        }
      );
    });
  }

  uploadCSV(){
    const dialogref = this.dialog.open(
      UploadCsvDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "750px",
        height : "600px",
        data: { leads: this.isleads },
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if (result.decision) {
        this.openSuccessDialog(
          result.response['body'],
          "OK"
        );
      }
    })
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
  }

}
