<div style="padding: 30px;">
    <div class="email-template-dialog-header">
        <div class="header-title">{{data.title}}</div>
        <!-- <div (click)="cancel()" class="cursor-pointer">x</div> -->
        <!-- <mat-icon class="close-icon" >cancel</mat-icon> -->
        <svg class="close-icon" (click)="cancel()" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.22222 8L0 1.77778L1.77778 0L8 6.22222L14.2222 0L16 1.77778L9.77778 8L16 14.2222L14.2222 16L8 9.77778L1.77778 16L0 14.2222L6.22222 8Z" fill="#202224"/>
            </svg>
            


    </div>
    <div class="q-field-block">
        <div class="q-field-name">{{data.field1Name}}</div>
        <div class="q-input-outline">
            <input class="q-input-field" type="text" name="" id="" [(ngModel)]="field1Value"
                (keypress)="emailTemplateNameKeyPress($event)">
            <span class="game-name-text-limiter">{{ field1Value.length }}<span>/45</span></span>
        </div>
        <div style="color: #dc3545; font-size: 12px;" *ngIf="errorText.length > 0">{{errorText}}</div>
    </div>
    <div style="display: flex;justify-content: flex-end; margin-top: 40px;">
        <div class="secondary-button-border" (click)="cancel()">{{data.cancelButtonName}}</div>
        <div class="primary-button" (click)="submit()">{{data.submitButtonName}}</div>

    </div>

</div>