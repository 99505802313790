import { NgModule } from '@angular/core';
import { OfferDispatchDirectComponent, OfferFilterOverlayComponent, OfferScheduleOverlayComponent } from './component';
import { OfferAllListingComponent, OfferAttachEmailDialogComponent, OfferCreationComponent, OfferCreationSimplifiedComponent, OfferManagerComponent, OfferNavComponent } from './pages';
import { OfferRoutingModule } from './offer-routing.module';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  MatDialogModule } from '@angular/material/dialog';
import {  MatRadioModule } from '@angular/material/radio';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GetCustomerPersonalOfferComponent } from './pages/get-customer-personal-offer/get-customer-personal-offer.component';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { NgxEmojModule } from 'ngx-emoj';


@NgModule({
  declarations: [
    OfferDispatchDirectComponent,
    OfferFilterOverlayComponent,
    OfferScheduleOverlayComponent,
    OfferCreationComponent,
    OfferCreationSimplifiedComponent,
    OfferAllListingComponent,
    OfferManagerComponent,
    OfferNavComponent,
    OfferAttachEmailDialogComponent,
    GetCustomerPersonalOfferComponent
  ],
  imports: [
    OfferRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    // MatDialogActions,
    MatDialogModule,
    MatRadioModule,
    // MatRadioButton,
    MatCheckboxModule,
    MatSelectModule,
    NgbPopoverModule,
    NgxMaterialTimepickerModule,
    NgxEmojiPickerModule.forRoot(),
    NgxEmojModule
  ],
  exports: [OfferCreationSimplifiedComponent],
})
export class OfferModule { }
