import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-insight-graph-dialog-box",
  templateUrl: "./insight-graph-dialog-box.component.html",
  styleUrls: ["./insight-graph-dialog-box.component.css"],
})
export class InsightGraphDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InsightGraphDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  sampleChart: echarts.EChartsCoreOption;
  currentPage = "ideal";
  subject = "ideal Case";
  description = "";
  sampleData = {
    scatterPlot: {
      ideal: {
        grapgh_data: {
          xAxis: {},
          yAxis: {},
          series: [
            {
              symbolSize: function (data) {
                return data[2] * 3; // Adjust the scale factor as needed
              },
              data: [
                [1, 8, 12],
                [2, 6, 14],
                [3.0, 7, 15],
                [3.05, 8, 16],
                [1.0, 6, 17],
                [12.0, 7, 7],
                [13.4, 8.81, 8],
                [13.0, 7, 9],
                [3.05, 1, 8],
                [1.0, 2, 7],
                [2.0, 2, 8],
                [13.4, 1, 9],
                [12.4, 1, 9],
              ],
              itemStyle: {
                color: "#013a63",
              },
              type: "scatter",
            },
          ],
        },
        description:
          "The RFM graph portrays a promising scenario where larger and more numerous bubbles are clustered in the left top corner, indicating high recent customer activity (Recency), frequent purchases (Frequency), and substantial monetary value. The gradient from light to dark blue suggests a growing customer base, reflecting positive engagement and potential for increased revenue.",
      },
      worst: {
        grapgh_data: {
          xAxis: {},
          yAxis: {},
          series: [
            {
              symbolSize: function (data) {
                return data[2] * 3; // Adjust the scale factor as needed
              },
              data: [
                [1, 8, 7],
                [2, 6, 8],
                [3.0, 7, 7],
                [3.05, 8, 8],
                [1.0, 6, 7],
                [12.0, 7, 17],
                [13.4, 8.81, 18],
                [13.0, 7, 19],
                [3.05, 1, 8],
                [1.0, 2, 7],
                [13.4, 1, 19],
                [12.4, 1, 19],
              ],
              itemStyle: {
                color: "#013a63",
              },
              type: "scatter",
            },
          ],
        },
        description:
          "The RFM graph reveals a concerning trend characterized by larger and more numerous bubbles clustered in the right top corner, signaling lower recent customer activity (Recency), infrequent purchases (Frequency), and reduced monetary value. The gradient from low to dark blue indicates a diminishing customer base, highlighting potential challenges in customer retention and revenue generation",
      },
    },
    trend_report: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          legend: {
            data: ["Current Year", "Previous Year"],
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              name: "Previous Year",
              lineStyle: {
                color: "#90e0ef", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "#90e0ef", // Customize the data point color
              },
            },
            {
              data: [
                300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              name: "Current Year",
              // lineStyle: {
              //   color: "green", // Customize the line color
              //   width: 2, // Customize the line width
              // },
              // itemStyle: {
              //   color: "green", // Customize the data point color
              // },
            },
          ],
        },
        description:
          "The upward trajectory signals remarkable growth, with this year's performance soaring above the previous year's, reflecting an impressive leap forward",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          legend: {
            data: ["Current Year", "Previous Year"],
          },
          series: [
            {
              data:
              [
                1400, 1300, 1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              name: "Previous Year",
              lineStyle: {
                color: "#90e0ef", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "#90e0ef", // Customize the data point color
              },
            },
            {
              data:  [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              name: "Current Year",
              // lineStyle: {
              //   color: "green", // Customize the line color
              //   width: 2, // Customize the line width
              // },
              // itemStyle: {
              //   color: "green", // Customize the data point color
              // },
            },
          ],
        },
        description:
          "The downward trend is cause for concern, with this year's performance declining below the previous year's, indicating a critical need for strategic intervention to reverse the trajectory",
      },
    },
    recency_bar: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "bar",
              barWidth: "40%", // Customize the bar width
              itemStyle: {
                color: "green", // Customize the bar color
              },
            },
          ],
        },
        description:
          "The bar chart presents an optimistic outlook, with bars extending towards the left side of the x-axis, indicating recent purchases (Recency), and reaching higher points on the y-axis, representing higher monetary value. The shades of blue deepen across the bars, signifying an increasing number of customers, suggesting a strong customer base with high-value transactions",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "bar",
              barWidth: "40%", // Customize the bar width
              itemStyle: {
                color: "red", // Customize the bar color
              },
            },
          ],
        },
        description:
          " The bar chart reveals a concerning trend, with bars predominantly clustered towards the right side of the x-axis, suggesting less recent purchases (Recency), and lower peaks on the y-axis, indicating reduced monetary value. The shades of blue remain lighter across the bars, implying a dwindling number of customers, highlighting potential challenges in customer engagement and revenue generation.",
      },
    },
    frequency_bar: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "bar",
              barWidth: "40%", // Customize the bar width
              itemStyle: {
                color: "green", // Customize the bar color
              },
            },
          ],
        },
        description:
          "The bar chart illustrates an encouraging trend, with bars extending towards higher frequencies on the x-axis, indicating frequent purchases, and reaching higher points on the y-axis, representing increased monetary value. The shades of blue deepen across the bars, reflecting a growing number of customers, suggesting a loyal customer base with significant purchasing power.",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "bar",
              barWidth: "40%", // Customize the bar width
              itemStyle: {
                color: "red", // Customize the bar color
              },
            },
          ],
        },
        description:
          "The bar chart depicts a concerning pattern, with bars clustered towards lower frequencies on the x-axis, suggesting infrequent purchases, and lower peaks on the y-axis, indicating reduced monetary value. The shades of blue remain lighter across the bars, hinting at a shrinking number of customers, highlighting potential challenges in customer engagement and revenue generation",
      },
    },
    churm_rate: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          series: [
            {
              data: [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              lineStyle: {
                color: "green", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "green", // Customize the data point color
              },
            },
          ],
        },
        description:
          "The churn customer rate graph exhibits a downward-sloping trendline, paralleling the x-axis, indicating a consistently low churn rate over the selected time period. This pattern reflects robust customer retention and satisfaction, underscoring the business's ability to maintain long-term relationships with its clientele.",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              lineStyle: {
                color: "red", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "red", // Customize the data point color
              },
            },
          ],
        },
        description:
          "The churn customer rate graph reveals an upward-sloping trendline, deviating from the x-axis, signaling an increasing churn rate over the selected time period. This trajectory suggests a growing number of customers discontinuing their subscriptions or purchases, posing challenges to customer retention efforts and necessitating proactive measures to address dissatisfaction and reduce churn",
      },
    },
    customer_repeat_trend: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              lineStyle: {
                color: "green", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "green", // Customize the data point color
              },
            },
          ],
        },
        description:
          "The customer repeat rate trend line graph displays an upward-sloping trendline, parallel to the x-axis, indicating a consistent increase in customer repeat rates over the selected time period. This positive trajectory reflects growing customer loyalty and satisfaction, highlighting the effectiveness of the business's retention strategies and the overall strength of its customer relationships.",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          series: [
            {
              data: [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              lineStyle: {
                color: "red", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "red", // Customize the data point color
              },
            },
          ],
        },
        description:
          "The customer repeat rate trend line graph shows a downward-sloping trendline, deviating from the x-axis, signaling a decline in customer repeat rates over the selected time period. This concerning pattern suggests a reduction in customer loyalty and repeat purchases, posing challenges to revenue growth and emphasizing the need for targeted efforts to improve customer retention and engagement.",
      },
    },
    new_customer_rate: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              lineStyle: {
                color: "green", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "green", // Customize the data point color
              },
            },
          ],
        },
        description:
          "The new customer rate graph exhibits an upward-sloping trendline, parallel to the x-axis, indicating a consistent increase in new customer acquisition over the selected time period. This positive trend reflects successful marketing efforts, expanding market reach, and growing brand awareness, contributing to the business's overall growth and market presence.",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          series: [
            {
              data: [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "line",
              smooth: true,
              symbol: "circle", // Customize the symbol shape
              symbolSize: 10, // Customize the symbol size
              lineStyle: {
                color: "red", // Customize the line color
                width: 2, // Customize the line width
              },
              itemStyle: {
                color: "red", // Customize the data point color
              },
            },
          ],
        },
        description:
          "The new customer rate graph displays a downward-sloping trendline, deviating from the x-axis, signaling a decline in new customer acquisition over the selected time period. This concerning trend suggests challenges in attracting and converting new customers, potentially due to market saturation or ineffective marketing strategies. Addressing this decline is critical to sustaining business growth and remaining competitive in the market",
      },
    },
    avg_revenue: {
      ideal: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [
                100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
              ],
              type: "bar",
              barWidth: "40%", // Customize the bar width
              itemStyle: {
                color: "green", // Customize the bar color
              },
            },
          ],
        },
        description:
          "The bar chart illustrates an upward trend in Average Revenue per Customer, with bars extending towards higher values on the y-axis as the customer lifetime value increases on the x-axis. This indicates that customers who have been with the business for longer periods tend to generate higher revenue on average, reflecting successful customer retention and upselling strategies",
      },
      worst: {
        grapgh_data: {
          title: {},
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisLabel: {
              show: false, // Hide the y-axis label
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [
                1200, 1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100,
              ],
              type: "bar",
              barWidth: "40%", // Customize the bar width
              itemStyle: {
                color: "red", // Customize the bar color
              },
            },
          ],
        },
        description:
          "The bar chart reveals a downward trend in Average Revenue per Customer, with bars declining on the y-axis as the customer lifetime value increases on the x-axis. This suggests that as customers stay with the business longer, their average revenue contribution diminishes, potentially indicating challenges in maintaining customer engagement and maximizing revenue potential over time",
      },
    },
    loyalty_sign_up: {
      ideal: {
        grapgh_data: {
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: ["Current Year", "Previous Year"],
          },
          series: [
            {
              type: "bar",
              name: "Previous Year",
              data: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600],
              barGap: "0%",
              barCategoryGap: "40%",
              itemStyle: {
                color: "#ADCCFF",
              },
            },
            {
              type: "bar",
              name: "Current Year",
              data: [
                100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
              ],
              itemStyle: {
                color: "#8280FF",
              },
            },
          ],
        },
        description:
          "The loyalty scheme sign-up trend graph depicts a consistent increase in sign-ups over time, with bars for the current year consistently surpassing those of the previous year. This upward trend suggests growing customer engagement and interest in the loyalty program, indicating a successful initiative in fostering customer loyalty and enhancing retention",
      },
      worst: {
        grapgh_data: {
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: ["Current Year", "Previous Year"],
          },
          series: [
            {
              type: "bar",
              name: "Previous Year",
              data: [
                650, 600, 550, 500, 450, 400, 350, 300, 250, 200, 150, 100,
              ],
              barGap: "0%",
              barCategoryGap: "40%",
              itemStyle: {
                color: "#ADCCFF",
              },
            },
            {
              type: "bar",
              name: "Current Year",
              data: [600, 550, 500, 450, 400, 350, 300, 250, 200, 150, 100, 50],
              itemStyle: {
                color: "#8280FF",
              },
            },
          ],
        },
        description:
          "The loyalty scheme sign-up trend graph shows a declining trend in sign-ups over time, with bars for the current year consistently falling short of those of the previous year. This downward trend indicates waning interest or effectiveness in the loyalty program, highlighting potential challenges in engaging customers and retaining their loyalty. Urgent measures may be needed to revitalize the program and attract more sign-ups.",
      },
    },
    loyaltyComparisonGraph: {
      ideal: {
        grapgh_data: {
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: ['Loyalty Customers', 'Non-Loyalty Customers'],
          },
          series: [
            {
              type: "bar",
              name: 'Non-Loyalty Customers',
              data: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600],
              barGap: "0%",
              barCategoryGap: "40%",
              itemStyle: {
                color: "#ADCCFF",
              },
            },
            {
              type: "bar",
              name: 'Loyalty Customers',
              data: [
                100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
              ],
              itemStyle: {
                color: "#8280FF",
              },
            },
          ],
        },
        description:
          "In the ideal scenario depicted in the graph, revenue from loyal customers significantly exceeds that from non-loyal customers. This indicates a successful loyalty program where members consistently engage and spend more. In this case, loyal customers not only provide steady revenue but also contribute to higher customer lifetime value through repeat purchases and brand advocacy. By maximizing loyalty, businesses can enhance customer satisfaction, reduce churn, and improve overall profitability. The focus should be on continually refining the loyalty program to attract non-loyal customers, encouraging them to join and enjoy the benefits of loyalty, ultimately driving further revenue growth.",
      },
      worst: {
        grapgh_data: {
          xAxis: {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: ['Loyalty Customers', 'Non-Loyalty Customers'],
          },
          series: [
            {
              type: "bar",
              name: 'Non-Loyalty Customers',
              data: [
                650, 600, 550, 500, 450, 400, 350, 300, 250, 200, 150, 100,
              ],
              barGap: "0%",
              barCategoryGap: "40%",
              itemStyle: {
                color: "#ADCCFF",
              },
            },
            {
              type: "bar",
              name: 'Loyalty Customers',
              data: [600, 550, 500, 450, 400, 350, 300, 250, 200, 150, 100, 50],
              itemStyle: {
                color: "#8280FF",
              },
            },
          ],
        },
        description:
          "In the worst-case scenario illustrated in the graph, revenue from non-loyal customers surpasses that from loyal customers. This suggests that the loyalty program may be ineffective, with members not engaging or spending adequately. Such a situation can indicate low customer retention, high churn rates, and a lack of compelling benefits within the loyalty program. This imbalance highlights the need for immediate action to enhance the program's appeal and effectiveness. Businesses must identify the reasons behind low loyalty engagement, improve incentives, and create more value for members. Without addressing these issues, reliance on non-loyal customers can lead to unpredictable revenue and hinder long-term growth.",
      },
    }
  };

  ngOnInit(): void {
    this.getGrapghData();
  }

  closeDialog(decision) {
    this.dialogRef.close(decision);
  }

  getGrapghData() {
    this.sampleChart =
      this.sampleData[this.data["type"]][this.currentPage].grapgh_data;
    this.description =
      this.sampleData[this.data["type"]][this.currentPage].description;
  }

  navigateTo(type) {
    this.currentPage = type;
    this.subject = type + " Case";
    this.getGrapghData();
  }
}
