<div *ngIf="data" id="content-parent">
  <div
    *ngIf="data.orientation === 'landscape'"
    id="box-position-parent"
    class="qb-box-position-parent"
  >
    <div class="qb-box-position-header">
      <span>{{'ACQUIRE_PAGE.BOX_POSITIONS' | i18nTranslate}}</span>
      <mat-icon
        *ngIf="!boxPositionToggleStatus"
        (click)="boxPositionToggle()"
        class="qb-icons"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="boxPositionToggleStatus"
        (click)="boxPositionToggle()"
        class="qb-icons"
        >remove</mat-icon
      >
    </div>
    <div class="qb-box-position-container">
      <div
        class="qb-box-container qb-position-1"
        [ngClass]="{ 'qb-active': data.boxPosition === 1 }"
        (click)="updateData('boxPosition', 1)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 1 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-2"
        [ngClass]="{ 'qb-active': data.boxPosition === 2 }"
        (click)="updateData('boxPosition', 2)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 2 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-3"
        [ngClass]="{ 'qb-active': data.boxPosition === 3 }"
        (click)="updateData('boxPosition', 3)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 3 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-4"
        [ngClass]="{ 'qb-active': data.boxPosition === 4 }"
        (click)="updateData('boxPosition', 4)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 4 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-5"
        [ngClass]="{ 'qb-active': data.boxPosition === 5 }"
        (click)="updateData('boxPosition', 5)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 5 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-6"
        [ngClass]="{ 'qb-active': data.boxPosition === 6 }"
        (click)="updateData('boxPosition', 6)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 6 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-7"
        [ngClass]="{ 'qb-active': data.boxPosition === 7 }"
        (click)="updateData('boxPosition', 7)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 7 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-8"
        [ngClass]="{ 'qb-active': data.boxPosition === 8 }"
        (click)="updateData('boxPosition', 8)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 8 }"
        ></div>
      </div>
      <div
        class="qb-box-container qb-position-9"
        [ngClass]="{ 'qb-active': data.boxPosition === 9 }"
        (click)="updateData('boxPosition', 9)"
      >
        <div
          class="qb-box"
          [ngClass]="{ 'qb-active': data.boxPosition === 9 }"
        ></div>
      </div>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list" *ngIf="data.selectedLayout !== 4">
    <span>{{'ACQUIRE_PAGE.CHANGE_IMAGE' | i18nTranslate}}</span>
    <span class="qb-upload-button" (click)="imageFile.click()">
      <img
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/forms/Upload.svg"
        alt=""
      />
      <span>{{'ACQUIRE_PAGE.UPLOAD' | i18nTranslate}}</span>
    </span>
    <input
      type="file"
      accept=".png, .jpg, .jpeg"
      #imageFile
      (change)="uploadImage($event)"
      hidden="true"
    />
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list">
    <mat-checkbox
      [(ngModel)]="data.closeButton"
      (change)="updateData('closeButton', $event.checked)"
      >{{'ACQUIRE_PAGE.CLOSE_BUTTON' | i18nTranslate}}</mat-checkbox
    >
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span class="qb-heading-label">{{'ACQUIRE_PAGE.HEADING_25_CHAR_LIMIT' | i18nTranslate}}</span>
      <div class="qb-style-config-div">
        <div class="qb-style-config-popup" id="heading-config-popup">
          <div class="qb-heading-text">
            <span>{{'ACQUIRE_PAGE.HEADING' | i18nTranslate}}</span>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.TEXT_COLOR' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container2">
                <input
                  class="qb-color-picket-input"
                  [(colorPicker)]="styles.headerStyle.color"
                  (colorPickerChange)="
                    styleChangeLive('heading', 'headerStyle')
                  "
                  [style.background]="styles.headerStyle.color"
                />
                <span>{{ styles.headerStyle.color }}</span>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.FONT_SIZE' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <span>{{ styles.headerStyle.fontSize }} px</span>
                <span
                  ><mat-icon
                    (click)="fontSizeUpdate('heading', 'headerStyle', true)"
                    class="qb-font-size-icons"
                  >
                    keyboard_arrow_up </mat-icon
                  ><mat-icon
                    (click)="fontSizeUpdate('heading', 'headerStyle', false)"
                    class="qb-font-size-icons"
                  >
                    keyboard_arrow_down
                  </mat-icon></span
                >
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.FONT_FAMILY' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <mat-select
                  (selectionChange)="styleChangeLive('heading', 'headerStyle')"
                  [(ngModel)]="styles.headerStyle.fontFamily"
                >
                  <mat-option
                    *ngFor="let font of availableFonts"
                    [value]="font"
                    >{{ font }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.TEXT_DECORATION' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <mat-select
                  (selectionChange)="styleChangeLive('heading', 'headerStyle')"
                  [(ngModel)]="styles.headerStyle.textDecoration"
                >
                  <mat-option
                    *ngFor="let decorator of textDecoration"
                    [value]="decorator"
                    >{{ decorator }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          <div class="qb-popup-button-container">
            <span
              class="qb-popup-button secondary-button-border"
              (click)="
                saveStyle(
                  'heading-config-popup',
                  false,
                  'heading',
                  'headerStyle'
                )
              "
              >{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</span
            >
            <span
              class="qb-popup-button primary-button"
              (click)="
                saveStyle(
                  'heading-config-popup',
                  true,
                  'heading',
                  'headerStyle'
                )
              "
              >{{'ACQUIRE_PAGE.SAVE' | i18nTranslate}}</span
            >
          </div>
        </div>
        <span
          (click)="openPopup($event, 'heading-config-popup')"
          class="material-icons-outlined qb-form-content-edit-icon icons-primary"
        >
          edit
        </span>
      </div>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          (data.contentClicked === true && data.heading.content.length < 1) ||
          (data.contentClicked === true &&
            !data.heading.content.split(' ').join('').length)
      }"
    >
      <input
        type="text"
        (input)="contentDataChange.emit(data)"
        [(ngModel)]="data.heading.content"
        class="qb-input-box"
        maxlength="25"
      />
      <span class="qb-char-text qb-char-text2"
        >{{ 25 - data.heading.content.length }}
        <span class="qb-char-text">/25</span>
      </span>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span class="qb-heading-label">{{'ACQUIRE_PAGE.SUB_HEADING_60_CHAR_LIMIT' | i18nTranslate}}</span>
      <div class="qb-style-config-div">
        <div class="qb-style-config-popup" id="sub-heading-config-popup">
          <div class="qb-heading-text">
            <span>{{'ACQUIRE_PAGE.SUB_HEADING' | i18nTranslate}}</span>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.TEXT_COLOR' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container2">
                <input
                  class="qb-color-picket-input"
                  [(colorPicker)]="styles.subHeadStyle.color"
                  (colorPickerChange)="
                    styleChangeLive('subHeading', 'subHeadStyle')
                  "
                  [style.background]="styles.subHeadStyle.color"
                />
                <span>{{ styles.subHeadStyle.color }}</span>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.FONT_SIZE' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <span>{{ styles.subHeadStyle.fontSize }} px</span>
                <span
                  ><mat-icon
                    (click)="fontSizeUpdate('subHeading', 'subHeadStyle', true)"
                    class="qb-font-size-icons"
                  >
                    keyboard_arrow_up </mat-icon
                  ><mat-icon
                    (click)="
                      fontSizeUpdate('subHeading', 'subHeadStyle', false)
                    "
                    class="qb-font-size-icons"
                  >
                    keyboard_arrow_down
                  </mat-icon></span
                >
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.FONT_FAMILY' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <mat-select
                  (selectionChange)="
                    styleChangeLive('subHeading', 'subHeadStyle')
                  "
                  [(ngModel)]="styles.subHeadStyle.fontFamily"
                >
                  <mat-option
                    *ngFor="let font of availableFonts"
                    [value]="font"
                    >{{ font }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.TEXT_DECORATION' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <mat-select
                  (selectionChange)="
                    styleChangeLive('subHeading', 'subHeadStyle')
                  "
                  [(ngModel)]="styles.subHeadStyle.textDecoration"
                >
                  <mat-option
                    *ngFor="let decorator of textDecoration"
                    [value]="decorator"
                    >{{ decorator }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          <div class="qb-popup-button-container">
            <span
              class="qb-popup-button secondary-button-border"
              (click)="
                saveStyle(
                  'sub-heading-config-popup',
                  false,
                  'subHeading',
                  'subHeadStyle'
                )
              "
              >{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</span
            >
            <span
              class="qb-popup-button primary-button"
              (click)="
                saveStyle(
                  'sub-heading-config-popup',
                  true,
                  'subHeading',
                  'subHeadStyle'
                )
              "
              >{{'ACQUIRE_PAGE.SAVE' | i18nTranslate}}</span
            >
          </div>
        </div>
        <span
          (click)="openPopup($event, 'sub-heading-config-popup')"
          class="material-icons-outlined qb-form-content-edit-icon icons-primary"
        >
          edit
        </span>
      </div>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          (data.contentClicked === true &&
            data.subHeading.content.length < 1) ||
          (data.contentClicked === true &&
            !data.subHeading.content.split(' ').join('').length)
      }"
    >
      <input
        type="text"
        (input)="contentDataChange.emit(data)"
        [(ngModel)]="data.subHeading.content"
        class="qb-input-box qb-input-box2"
        maxlength="60"
      />
      <span class="qb-char-text qb-char-text2"
        >{{ 60 - data.subHeading.content.length }}
        <span class="qb-char-text">/60</span>
      </span>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span class="qb-heading-label">{{'ACQUIRE_PAGE.INPUT_FIELD' | i18nTranslate}}</span>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          data.contentClicked === true &&
          (data.inputPlaceholder.length < 1 ||
            !data.inputPlaceholder.split(' ').join('').length)
      }"
    >
      <input
        type="text"
        (input)="contentDataChange.emit(data)"
        [(ngModel)]="data.inputPlaceholder"
        class="qb-input-box"
        maxlength="30"
      />
      <span class="qb-char-text qb-char-text2"
        >{{ 30 - data.inputPlaceholder.length }}
        <span class="qb-char-text">/30</span>
      </span>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span class="qb-heading-label">{{'ACQUIRE_PAGE.BUTTON' | i18nTranslate}}</span>
      <div class="qb-style-config-div">
        <div class="qb-style-config-popup" id="button-config-popup">
          <div class="qb-heading-text">
            <span>{{'ACQUIRE_PAGE.BUTTON' | i18nTranslate}}</span>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.TEXT_COLOR' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container2">
                <input
                  class="qb-color-picket-input"
                  [(colorPicker)]="styles.buttonStyle.color"
                  (colorPickerChange)="styleChangeLive('button', 'buttonStyle')"
                  [style.background]="styles.buttonStyle.color"
                />
                <span>{{ styles.buttonStyle.color }}</span>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.BACKGROUND_COLOR' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container2">
                <input
                  class="qb-color-picket-input"
                  [(colorPicker)]="styles.buttonStyle.background"
                  (colorPickerChange)="styleChangeLive('button', 'buttonStyle')"
                  [style.background]="styles.buttonStyle.background"
                />
                <span>{{ styles.buttonStyle.background }}</span>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.FONT_SIZE' | i18nTranslate}}</span>
              <div
                class="qb-input-container"
                style="justify-content: space-between; padding: 0px 8px"
              >
                <span>{{ styles.buttonStyle.fontSize }} px</span>
                <span
                  ><mat-icon
                    (click)="fontSizeUpdate('button', 'buttonStyle', true)"
                    class="qb-font-size-icons"
                  >
                    keyboard_arrow_up </mat-icon
                  ><mat-icon
                    (click)="fontSizeUpdate('button', 'buttonStyle', false)"
                    class="qb-font-size-icons"
                  >
                    keyboard_arrow_down
                  </mat-icon></span
                >
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.FONT_FAMILY' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <mat-select
                  (selectionChange)="styleChangeLive('button', 'buttonStyle')"
                  [(ngModel)]="styles.buttonStyle.fontFamily"
                >
                  <mat-option
                    *ngFor="let font of availableFonts"
                    [value]="font"
                    >{{ font }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
            <div>
              <span class="qb-style-heading">{{'ACQUIRE_PAGE.TEXT_DECORATION' | i18nTranslate}}</span>
              <div class="qb-input-container qb-input-container3">
                <mat-select
                  (selectionChange)="styleChangeLive('button', 'buttonStyle')"
                  [(ngModel)]="styles.buttonStyle.textDecoration"
                >
                  <mat-option
                    *ngFor="let decorator of textDecoration"
                    [value]="decorator"
                    >{{ decorator }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          <div class="qb-popup-button-container">
            <span
              class="qb-popup-button secondary-button-border"
              (click)="
                saveStyle('button-config-popup', false, 'button', 'buttonStyle')
              "
              >{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</span
            >
            <span
              class="qb-popup-button primary-button"
              (click)="
                saveStyle('button-config-popup', true, 'button', 'buttonStyle')
              "
              >{{'ACQUIRE_PAGE.SAVE' | i18nTranslate}}</span
            >
          </div>
        </div>
        <span
          (click)="openPopup($event, 'button-config-popup')"
          class="material-icons-outlined qb-form-content-edit-icon icons-primary"
        >
          edit
        </span>
      </div>
    </div>
    <div
      class="qb-input-container"
      [ngClass]="{
        'qb-error':
          data.contentClicked === true &&
          (data.button.content.length < 1 ||
            !data.button.content.split(' ').join('').length)
      }"
    >
      <input
        type="text"
        (input)="contentDataChange.emit(data)"
        [(ngModel)]="data.button.content"
        class="qb-input-box"
        maxlength="20"
      />
      <span class="qb-char-text qb-char-text3">
        {{ 20 - data.button.content.length }}
        <span class="qb-char-text">/20</span>
      </span>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-parent-list qb-parent-list-block">
    <div class="qb-input-heading">
      <span class="qb-heading-label">{{'ACQUIRE_PAGE.WHEN_BUTTON_CLICKED' | i18nTranslate}}</span>
    </div>
    <div class="qb-input-container">
      <mat-select
        class="qb-select-div"
        [(ngModel)]="data.actionOnButtonClick"
        (selectionChange)="contentDataChange.emit(data)"
      >
        <mat-option
          *ngFor="
            let option of scheduleConstants.configurations.content.onsubmit
          "
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div
    class="qb-parent-list qb-parent-list-block"
    *ngIf="data.actionOnButtonClick === 'MSG'"
  >
    <div class="qb-input-heading">
      <span class="qb-heading-label">{{'ACQUIRE_PAGE.SUCESS_MESSAGE' | i18nTranslate}}</span>
    </div>
    <div
      class="qb-text-input qb-input"
      [ngClass]="{
        'qb-error':
          data.contentClicked === true &&
          (data.successMessage.length < 1 ||
            !data.successMessage.split(' ').join('').length)
      }"
    >
      <textarea
        [(ngModel)]="data.successMessage"
        name="successMessage"
        id="successMessage"
        maxlength="25"
        (input)="contentDataChange.emit(data)"
      ></textarea>
      <span class="qb-char-text qb-char-text4">
        {{ 25 - data.successMessage.length }}
        <span class="qb-char-text">/25</span>
      </span>
    </div>
  </div>
  <div *ngIf="data.actionOnButtonClick === 'URL'">
    <div class="qb-parent-list qb-parent-list-block">
      <div class="qb-input-heading">
        <span class="qb-heading-label">{{'ACQUIRE_PAGE.ENTER_URL' | i18nTranslate}}</span>
      </div>
      <div class="qb-input-container">
        <mat-select
          class="qb-select-div"
          [(ngModel)]="data.url"
          (selectionChange)="contentDataChange.emit(data)"
        >
          <mat-option
            *ngFor="let route of scheduleConstants.configurations.routes"
            [value]="route.path"
            >{{ route.name }}</mat-option
          >
        </mat-select>
      </div>
    </div>
  </div>
  <div class="qb-seperator-line"></div>
  <div class="qb-message-input-header" id="message-input-header">
    <div class="qb-box-position-header">
      <span>{{'ACQUIRE_PAGE.MESSAGES' | i18nTranslate}}</span>
    </div>
    <div class="qb-parent-list qb-parent-list-block">
      <div class="qb-input-heading">
        <span class="qb-heading-label"
          >{{'ACQUIRE_PAGE.ERROR_EMPTY_FIELD' | i18nTranslate}}</span
        >
      </div>
      <div
        class="qb-input-container"
        [ngClass]="{
          'qb-error':
            data.contentClicked === true &&
            (data.emptyMessage.length > 50 ||
              data.emptyMessage.length < 1 ||
              !data.emptyMessage.split(' ').join('').length)
        }"
      >
        <input
          [(ngModel)]="data.emptyMessage"
          (input)="contentDataChange.emit(data)"
          type="text"
          class="qb-input-box"
          maxlength="50"
        />
        <span class="qb-char-text qb-char-text2"
          >{{ 50 - data.emptyMessage.length }}
          <span class="qb-char-text">/50</span>
        </span>
      </div>
    </div>
    <div class="qb-seperator-line"></div>
    <div class="qb-parent-list qb-parent-list-block">
      <div class="qb-input-heading">
        <span class="qb-heading-label">{{'ACQUIRE_PAGE.ERROR_EMAIL_INCORRECT' | i18nTranslate}}</span>
      </div>
      <div
        class="qb-input-container"
        [ngClass]="{
          'qb-error':
            data.contentClicked === true &&
            (data.invalidMessage.length > 50 ||
              data.invalidMessage.length < 1 ||
              !data.invalidMessage.split(' ').join('').length)
        }"
      >
        <input
          [(ngModel)]="data.invalidMessage"
          (input)="contentDataChange.emit(data)"
          type="text"
          class="qb-input-box"
          maxlength="50"
        />
        <span class="qb-char-text qb-char-text2"
          >{{ 50 - data.invalidMessage.length }}
          <span class="qb-char-text">/50</span>
        </span>
      </div>
    </div>
    <div class="qb-seperator-line"></div>
    <div class="qb-parent-list qb-parent-list-block">
      <div class="qb-input-heading">
        <span class="qb-heading-label">{{'ACQUIRE_PAGE.GENERAL_ERROR_MESSAGE' | i18nTranslate}}</span>
      </div>
      <div
        class="qb-input-container"
        [ngClass]="{
          'qb-error':
            data.contentClicked === true &&
            (data.generalMessage.length > 50 ||
              data.generalMessage.length < 1 ||
              !data.generalMessage.split(' ').join('').length)
        }"
      >
        <input
          [(ngModel)]="data.generalMessage"
          (input)="contentDataChange.emit(data)"
          type="text"
          class="qb-input-box"
          maxlength="50"
        />
        <span class="qb-char-text qb-char-text2"
          >{{ 50 - data.generalMessage.length }}
          <span class="qb-char-text">/50</span>
        </span>
      </div>
    </div>
  </div>
</div>
