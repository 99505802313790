import { Component, OnInit, ViewChild, ElementRef, OnChanges, Input } from '@angular/core';
import * as d3 from 'd3';
import { PieChartConfig } from '../config/pie-chart.config'
import { ViewEncapsulation } from '@angular/core';
import d3Tip from "d3-tip";
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BarChartComponent implements OnInit, OnChanges {

  @Input() data: { name: string, value: number, children: any[] }[];
  @ViewChild('chartContainer') chartContainer: ElementRef;
  private chartContainerElement: any;

  constructor(private events: EventsService) { }

  ngOnInit() {
    this.chartContainerElement = this.chartContainer.nativeElement;
  }

  ngOnChanges() {
    this.chartContainerElement = this.chartContainer.nativeElement;
    this.renderGraph();
  }

  renderGraph() {
    let xScale: any;
    let yScale: any;
    let xAxis: any;
    let yAxis: any;
    let yAxisTickLines;
    let data: { name: string, value: number }[] = [];
    let svg: any;
    let margin: any = { top: 20, right: 10, bottom: 20, left: 10 };
    let height: number = this.chartContainer.nativeElement.offsetHeight - margin.top - margin.bottom;
    let width: number = this.chartContainer.nativeElement.offsetWidth - margin.left - margin.right;
    let colors: any
    let barSpace: number;
    let barWidth: number;
    let barPadding: number;
    let bar: any;
    let legendContainer: any;
    let yMin, yMax, tickValues;
    let color = '#edf0fa'

    if (this.chartContainerElement != null) {
      let config = new PieChartConfig();
      d3.select('svg.chart-container').remove();

      var tip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html(function (d) {
        return "<div><span>"+ d.name +"</span><br/><span style='display:flex; justify-content: center; font-weight:bold;'>"+d.value+"</span></div>";
      })

      svg = d3.select(this.chartContainerElement).append('svg')
        .attr('height', height + margin.top + margin.bottom)
        .attr('width', width + margin.left + margin.right)
        .attr('class', 'chart-container')
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      data = this.data;

      colors = d3.scaleOrdinal().range(config.colors);

      barSpace = (width / data.length);
      barWidth = 0.5 * barSpace;
      barPadding = 0.5 * barSpace;

      let yValues = this.getYRange(0, d3.max(data, (d) => d.value))
      yMin = yValues.min;
      yMax = yValues.max;
      tickValues = yValues.ticks;

      yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);
      xScale = d3.scaleBand().domain(data.map((d) => d.name)).range([0, width]).padding(0);

      xAxis = d3.axisBottom(xScale);
      yAxis = d3.axisLeft(yScale).tickValues(tickValues);
      yAxisTickLines = d3.axisLeft(yScale).tickValues(tickValues).tickSize(-width).tickFormat('');

      svg.append('g')
        .attr('class', 'x-axis axis')
        .attr('display', 'none')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis)
        .call(g => g.select(".domain").remove());;

      svg.append('g')
        .attr('class', 'y-axis axis')
        .attr('display', 'none')
        .attr('transform', 'translate(0,0)')
        .call(yAxis);

      svg.call(tip);

      // svg.append('g')
      // .attr('class', 'grid y-axis-grid')
      // .style('color','#eeeeee')
      // .call(yAxisTickLines);

      // svg.append('g')
      // .attr('class', 'y-axis axis')
      // .attr('transform', 'translate(0,0)')
      // .call(yAxis);
      var div = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      bar = svg.selectAll('.rect-container').data(data).enter()
        .append('g')
        .attr('class', (d, i) => 'rect-container rect-container-' + i)
        .attr('transform', (d, i) => {
          return 'translate(' + (i * barSpace) + ', ' + 0 + ')';
        });

      bar.append('rect')
        .attr('class', 'bar')
        .attr('width', barWidth)
        .attr('height', (d) => height - yScale(d.value))
        .attr("rx", 3)
        .attr("ry", 3)
        .attr('x', (d) => barPadding / 2)
        .attr('y', (d) => yScale(d.value))
        .attr('id', (d, i) => 'id' + i);
      //.on('click', (d) => {
      //  this.events.topicSelectedFromCountChart.emit(d.name);
      //});

      // bar.on("mouseover", function (d) {
      //   let msa = d3.select(this).select('rect').attr('id');
      //   d3.select(this).select('rect')
      //     .attr("fill", "#4abb59");
      //   // div.style("opacity", 1);
      //   var svgPosition = document.getElementById("chart-container");
      //   // div.html((d.name) + "<br/>" + d.value)
      //   //   .style("left", (d3.event.pageX) + "px")
      //   //   .style("top", (d3.event.pageY - 28) + "px");
      // });
      bar.on('mouseover', tip.show)
      bar.on('mouseout', tip.hide)
      // bar.on("mousemove", function () {
      //   d3.select(this)
      //     .attr("fill", "#4abb59");
      // });
      // bar.on("mouseout", function () {
      //   d3.select(this).select('rect')
      //     .attr("fill", "#edf0fa");
      //   // div.style("opacity", 0);
      //   tip.hide
      // });

      //d3.tip = d3Tip;

      // var tip = d3.tip()
      //   .attr('class', 'd3-tip')
      //   .offset([-10, 0])
      //   .html(function (d) {
      //     return "<strong>Frequency:</strong> <span style='color:red'>" + d.frequency + "</span>";
      //   });
      // function type(d) {
      //   d.frequency = +d.frequency;
      //   return d;
      // }
      // bar.on('mouseover', (d, i) => {
      //   if(d.children.length > 0) {
      //     let gcBarSpace = barWidth / d.children.length;
      //     let gcBarPadding = 0.2 * gcBarSpace;
      //     let gcBarWidth = 0.6 * gcBarSpace;
      //     legendContainer = d3.select('svg.chart-container').append('g')
      //     .attr('class', 'legend-container')
      //     .attr('transform', 'translate(' + (width + margin.left + 5) + ', 30)')
      //     .append('g');
      //     for(let j = 0; j < d.children.length; j++) {
      //       d3.selectAll('.rect-container-' + i).append('rect')
      //       .attr('class', 'bar-rect grand-child-bar')
      //       .attr('width', gcBarWidth)
      //       .attr('height', height - yScale(d.children[j].value))
      //       .attr('x', (j * gcBarSpace) + gcBarPadding + (barPadding / 2))
      //       .attr('y', yScale(d.children[j].value))
      //       //.attr("fill", colors[j]);
      //       .attr("fill", color);

      // d3.selectAll('.rect-container-' + i).append('text')
      // .attr('x', (j * gcBarSpace) + (barPadding / 2) + (gcBarSpace / 2))
      // .attr('y', (d) => yScale(d.children[j].value) - 5)
      // .attr('text-anchor', 'middle')
      // .text((d) => d.children[j].value)
      // .attr('class', 'grand-child-text');

      // legendContainer.append('rect')
      // .attr('x', 0)
      // .attr('y', (j * 40))
      // .attr('width', 30)
      // .attr('height', 10)
      // //.style('fill', colors[j]);
      // .style('fill', color);

      // legendContainer.append('text')
      // .attr('x', 0)
      // .attr('y', (j * 40) + 25)
      // .attr('font-size', '10px')
      // .text(d.children[j].name + ' (' + d.children[j].value + ')');
      //     }
      //     d3.selectAll('.rect-container-' + i + ' .child-bar')
      //     .attr('fill', 'transparent')
      //     .attr('stroke', '#ccc')
      //     .attr('stroke-width', 2)
      //     .attr('stroke-dasharray', '4 2');
      //   }
      // })
      // .on('mouseout', (d, i) => {
      //   d3.selectAll('.rect-container-' + i + ' .child-bar')
      //   .attr('fill', '#3f51b5')
      //   .attr('stroke-width', 0);
      //   d3.selectAll('.rect-container-' + i + ' .grand-child-bar').remove();
      //   d3.selectAll('.rect-container-' + i + ' .grand-child-text').remove();
      //   d3.select('.legend-container').remove();
      // });

      // bar.append('text')
      // .attr('x', barSpace / 2)
      // .attr('y', (d) => yScale(d.value) - 5)
      // .attr('text-anchor', 'middle')
      // .text((d) => d.value)
      // .attr('class', 'child-text');

      //Adding Label for y axis for line
      // svg.append('g')
      // .attr('transform', 'translate(-30,' + (height/2) + ')')
      // .append('text')
      // .attr('fill', '#000')
      // .attr('text-anchor', 'middle')
      // .attr('transform', 'rotate(-90)')
      // .html('Acceptance Percentage')

      // svg.append('g')
      // .attr('transform', 'translate('+ (width/2)+','+(height+40)+')')
      // .append('text')
      // .attr('fill', '#000')
      // .attr('text-anchor', 'middle')
      // .html('Age groups')

      // legendContainer = d3.select('svg.' + this.containerClass).append('g')
      // .attr('class', 'legend-container')
      // .attr('transform', 'translate(' + (width + margin.left) + ', 30)')
      // .append('g');

      // legendContainer.append('rect')
      // .attr('x', 0)
      // .attr('y', 0)
      // .attr('width', 30)
      // .attr('height', 10)
      // .style('fill', '#19a17f');

      // legendContainer.append('text')
      // .attr('x', 0)
      // .attr('y', 25)
      // .text(this.legendText);

      // legendContainer.append('text')
      // .attr('x', 0)
      // .attr('y', 40)
      // .text('Score');

      // d3.selectAll('g.tick text')
      // .attr('dy', (d, i) => {
      //     return i % 2 == 0 ? '0.71em' : '1.7em';
      // })
    }
  }

  getYRange(min: number, max: number) {
    let minFloor, maxCeil, level = 1, diffUnit, ticks: number[] = [];
    while (max % level !== max) {
      level = level * 10;
    }
    level /= 10;
    minFloor = parseInt('' + (min / level)) * level;
    maxCeil = (parseInt('' + (max / level)) + 1) * level;
    diffUnit = (maxCeil - minFloor) / 4;
    // ticks.push(minFloor);
    for (let i = minFloor; i <= maxCeil; i += diffUnit) {
      ticks.push(i);
    }
    return {
      min: minFloor,
      max: maxCeil,
      ticks: ticks
    }
  }

}
