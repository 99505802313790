<!-- <div style="background-color: white; height: 546px;"> -->
<div class="body" style="height: 100%;" *ngIf="validWidgets">
  <div class="profile-mobile-header">
    <span (click)="reRoute('/app/app-home')" class="material-icons-outlined icons-primary profile-back-button-icon">
      arrow_back_ios
    </span>
    <span>{{'PROFILE_PAGE.MY_PROFILE' | i18nTranslate}}</span>
  </div>
  <div  class="qb-h-100">
    <div class="waitContainer" *ngIf="!profiledataLoaded">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
    <div class="mainContainer" *ngIf="profiledataLoaded">
      <!-- <div style="display: flex;padding: 20px 0 0 17px; border-bottom: 2px solid white;" data-widget-name="MY ACCOUNT">

                    <div *ngIf="validWidgets | widgetConfig: 24" class="layoutName" [ngClass]="{active: profileLayoutSelected === true}"
                        (click)="layoutChange('profile');profileLayoutSelected = true;" data-button-name="Profile"> {{ validWidgets | widgetLabel: 24 }}</div>

                    <div *ngIf="validWidgets | widgetConfig: 25" class="layoutName" [ngClass]="{active: billingLayoutSelected === true}"
                        (click)="layoutChange('billing');billingLayoutSelected = true;" data-button-name="Plan Details">{{ validWidgets | widgetLabel: 25 }}</div>


                    <div *ngIf="validWidgets | widgetConfig: 26" class="layoutName" [ngClass]="{active: domainLayoutSelected === true}"
                        (click)="layoutChange('domain');domainLayoutSelected = true;" data-button-name="Domains"> {{ validWidgets | widgetLabel: 26 }}</div>



                </div> -->
      <!-- <mat-tab-group animationDuration="0ms">
            <mat-tab label="Profile"> -->
      <div class="profile-parent-container" *ngIf="profileLayoutSelected">
        <!-- <div class="profile-details-card" data-widget-name="PROFILE">
          <div *ngIf="validWidgets | widgetConfig: 24">
            <div class="profile">
              <div class="image-div">
                <div class="header-icon-container">
                  <div class="headerIcons3 avatar-loading-wrapper" *ngIf="profileImgLoading">
                    <app-qubriux-loading [size]="35"></app-qubriux-loading>
                  </div>
                  <div class="headerIcons3" *ngIf="profileAvatarUrl && !profileImgLoading">
                    <img class="headerUserIcon3" [src]="profileAvatarUrl" alt="No image" />
                  </div>
                  <div class="headerIcons3" *ngIf="!profileAvatarUrl && !profileImgLoading">
                    <img class="headerUserIcon3" [src]="
                        s3BucketUrl +
                        '/greyfox-assets/assets2/gradients/profile1.png'
                      " alt="No image" />
                  </div>
                  <div *ngIf="!isDemo && qid === 'null'" class="primary-button profile-change-image-button"
                    (click)="fileInput.click()">
                    Change Image
                  </div>
                  <div *ngIf="isDemo" class="primary-button profile-change-image-button  not-allowed">
                    Change Image
                  </div>
                  <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                    <div class="group-gap">
                      <div class="avatar-upload">
                        <div class="avatar-edit">
                          <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                            (change)="setFiles($event)" hidden="true" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="user-details">
                <div class="name-country">
                  <div class="profile-name-container">
                    <div>
                      <div class="title">{{'PROFILE_PAGE.NAME' | i18nTranslate}}</div>
                      <div class="details">{{ name }}</div>
                    </div>
                  </div>
                  <div class="profile-name-country-mobile-seperator"></div>
                  <div class="profile-email-container">
                    <div>
                      <div class="title">{{'PROFILE_PAGE.EMAIL' | i18nTranslate}}</div>
                      <div class="details">{{ email }}</div>
                    </div>
                  </div>
                  <div class="profile-change-password-button-container">
                    <span *ngIf="isPasswordGenerated && !isDemo" class="primary-button mobile-password-change-button"
                      (click)="openDialog(true)" data-button-name="Change password">
                      {{'PROFILE_PAGE.CHANGE_PASS_SMALL' | i18nTranslate}}
                    </span>
                    <span *ngIf="isPasswordGenerated && isDemo"
                      class="primary-button mobile-password-change-button not-allowed">
                      {{'PROFILE_PAGE.CHANGE_PASS_SMALL' | i18nTranslate}}
                    </span>
                    <span *ngIf="!isPasswordGenerated" class="primary-button mobile-password-change-button"
                      (click)="openDialog(false)" data-button-name="Generate Password">
                      {{'PROFILE_PAGE.GENERATE_PASS' | i18nTranslate}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-email-button-seperator"></div>
          <div class="profile-email-button-seperator"></div>
          <div *ngIf="allowedAccount.indexOf(email) !== -1">
            <div class="qb-d-flex qb-jc-space-between" style="padding: 25px;">
              <div style="display: flex; margin-top: 10px;">
                Change Currency
              </div>
              <div>
                <div [matMenuTriggerFor]="selectCurrency" style="text-transform: capitalize;"
                  class="border-currency cursor-pointer">
                  <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 80%; margin-top: 3px;">
                    <div>
                      {{revCurrency}} ({{revCurrency | currencySymbol}})
                    </div>
                    <span>
                      <mat-icon class="keyboard-down-icon"
                        style="color: black; margin-left: 5px;">keyboard_arrow_down</mat-icon>
                    </span>
                  </div>
                </div>
                <mat-menu #selectCurrency class="qb-channel-dialog">
                  <button mat-menu-item class="qb-channel-dialog-content" (click)="changeCurrency('INR')">
                    <span>INR ({{'INR' | currencySymbol}})</span>
                  </button>
                  <button mat-menu-item class="qb-channel-dialog-content" (click)="changeCurrency('USD')">
                    <span>USD ({{'USD' | currencySymbol}})</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div> -->

        <div class="profile-data-card">
            <div class="objectives-card-bg"></div>
            <div class="card-content-container">
              <div class="card-icon-wrap qb-p-relative">
                <div class="headerIcons3 avatar-loading-wrapper" *ngIf="profileImgLoading">
                  <app-qubriux-loading [size]="35"></app-qubriux-loading>
                </div>
                <div class="headerIcons3" *ngIf="!profileImgLoading">
                  <img class="headerUserIcon3" [src]="profileAvatarUrl ? profileAvatarUrl :  s3BucketUrl +
                  '/greyfox-assets/assets2/gradients/profile1.png'" alt="No image" />
                </div>
                <div (click)="fileInput.click()"*ngIf="store !== 'shopify'"
                class="material-icons-outlined edit-profile-imag">
                edit
                </div>
              <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                <div class="group-gap">
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                        (change)="setFiles($event)" hidden="true" />
                    </div>
                  </div>
                </div>
              </form>
              </div>
              <div class="obj-card-text-container">
                <div class="user-details">
                  <div class="name-country">
                    <div class="profile-name-container">
                      <div>
                        <div class="title">{{'PROFILE_PAGE.NAME' | i18nTranslate}}</div>
                        <div class="details">{{ name }}</div>
                      </div>
                    </div>
                    <div class="profile-name-country-mobile-seperator"></div>
                    <div class="profile-email-container">
                      <div>
                        <div class="title">{{'PROFILE_PAGE.EMAIL' | i18nTranslate}}</div>
                        <div class="details">{{ email }}</div>
                      </div>
                    </div>
                    <div class="profile-change-password-button-container">
                      <span *ngIf="isPasswordGenerated && !isDemo" class="secondary-button-border mobile-password-change-button"
                        (click)="openDialog(true)" data-button-name="Change password">
                        {{'PROFILE_PAGE.CHANGE_PASS_SMALL' | i18nTranslate}}
                      </span>
                      <span *ngIf="isPasswordGenerated && isDemo"
                        class="secondary-button-border mobile-password-change-button not-allowed">
                        {{'PROFILE_PAGE.CHANGE_PASS_SMALL' | i18nTranslate}}
                      </span>
                      <span *ngIf="!isPasswordGenerated" class="secondary-button-border mobile-password-change-button"
                        (click)="openDialog(false)" data-button-name="Generate Password">
                        {{'PROFILE_PAGE.GENERATE_PASS' | i18nTranslate}}
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="allowedAccount.indexOf(email) !== -1">
                  <div class="qb-d-flex qb-jc-space-between" style="padding: 25px;">
                    <div style="display: flex; margin-top: 10px;">
                      Change Currency
                    </div>
                    <div>
                      <div [matMenuTriggerFor]="selectCurrency" style="text-transform: capitalize;"
                        class="border-currency cursor-pointer">
                        <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 80%; margin-top: 3px;">
                          <div>
                            {{revCurrency}} ({{revCurrency | currencySymbol}})
                          </div>
                          <span>
                            <mat-icon class="keyboard-down-icon"
                              style="color: black; margin-left: 5px;">keyboard_arrow_down</mat-icon>
                          </span>
                        </div>
                      </div>
                      <mat-menu #selectCurrency class="qb-channel-dialog">
                        <button mat-menu-item class="qb-channel-dialog-content" (click)="changeCurrency('INR')">
                          <span>INR ({{'INR' | currencySymbol}})</span>
                        </button>
                        <button mat-menu-item class="qb-channel-dialog-content" (click)="changeCurrency('USD')">
                          <span>USD ({{'USD' | currencySymbol}})</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="profile-my-shop-card" *ngIf="!(athorities | checkIfReportingView) && !(athorities | checkIfCustomerCare)">
          <div class="qb-profile-shop-card-wrapper">
            <div style="padding: 20px 20px 20px;height: 97%;">
              <div style="display: flex; justify-content: space-between">
                <div class="shops-header">Your Stores</div>
                <!-- <div class="primary-button" style="cursor: not-allowed">
                  <div class="material-icons button-icon">add</div>
                  <div>Add New Shop</div>
                </div> -->
              </div>
              <div class="stores-container">
                <div style="display: flex;flex-wrap: wrap;padding-bottom: 40px;">
                  <div style="margin: 2%; margin-right: 1%" *ngIf="qid == 'null'">
                    <div class="Rectangle-list" (click)="addNewStoreRedirect()">
  
                      <div class="qb-center" style="flex-direction: column;    gap: 10px;cursor: pointer;">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="30" cy="30" r="30" fill="#D1F0E4"/>
                          <path d="M32 20.4375V28.875H40.4375C41.4336 28.875 42.3125 29.7539 42.3125 30.75C42.3125 31.8047 41.4336 32.625 40.4375 32.625H32V41.0625C32 42.1172 31.1211 42.9375 30.125 42.9375C29.0703 42.9375 28.25 42.1172 28.25 41.0625V32.625H19.8125C18.7578 32.625 17.9375 31.8047 17.9375 30.75C17.9375 29.7539 18.7578 28.875 19.8125 28.875H28.25V20.4375C28.25 19.4414 29.0703 18.5625 30.125 18.5625C31.1211 18.5625 32 19.4414 32 20.4375Z" fill="#00B69B"/>
                        </svg>  
                        <div>Add New Store</div>
                      </div>
                    </div>
                  </div>
                  <div style="margin: 2%; margin-right: 1%" *ngFor="let merchant of merchantData; i as index">
                    <div class="Rectangle-list" *ngIf="(qid === 'null' || qid === merchant.qid)">
                      <div style="
                          display: flex;
                          flex-direction: column;
                          height: 100%; cursor: pointer;
                        ">
                        <div (click)="redirectToShopEditPage(merchant['qid'])"
                          class="qb-store-icon-container">
                          <img *ngIf="store == 'shopify'" [src]="
                              s3BucketUrl + '/greyfox-assets/assets/shopify.png'
                            " alt="" width="100" height="100" class="qb-d-flex qb-position-absolute" />
                          <img *ngIf="store == 'clover'"
                            src="https://www.businessrevieweurope.eu/wp-content/uploads/2019/11/clover-logo.jpg" alt=""
                            width="100" height="100" class="qb-d-flex qb-position-absolute" />
                          <div (click)="redirectToShopEditPage(merchant['qid'])" *ngIf="store !== 'shopify'"
                            class="material-icons-outlined edit-icon-profile">
                            edit
                          </div>
                          <div class="qb-onboarding-status-cont" *ngIf="merchant.onboardingStatus!=null">
                            <span class="material-symbols-outlined" *ngIf="merchant.onboardingStatus=='IN_PROCESS'"
                              style="color: orange;" matTooltip="In progress">
                              progress_activity
                            </span>
                            <span class="material-symbols-outlined" *ngIf="merchant.onboardingStatus=='ONBOARDED'"
                              style="color: green;" matTooltip="onboarded successfully">
                              check_circle
                            </span>
                            <span class="material-symbols-outlined" *ngIf="merchant.onboardingStatus=='REJECTED'"
                              style="color: tomato;" matTooltip="Error occured">
                              close
                            </span>
                          </div>
                        </div>
                        <div style="
                            display: flex;
                            align-items: center;
                            min-height: 22%;
                            padding: 4px 8px;
                            justify-content: space-between;">
  
                          <div class="templateHeadText-shop" (click)="redirectToShopEditPage(merchant['qid'])">
                            <span>{{ merchant['storeName'] }}</span>
                          </div>
                          <div style="display: flex;">
                            <mat-icon style="margin:0 6px;color: #0183fb;    font-size: 16px;
                            height: 16px;
                            width: 16px;cursor: pointer;
                        ">launch</mat-icon>
                          </div>
  
                        </div>
  
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <!-- </mat-tab> -->
      <!-- <mat-tab label="Plan Details"> -->
      <div *ngIf="billingLayoutSelected" data-widget-name="PLAN DETAILS">
        <app-plan-details></app-plan-details>
      </div>
      <!-- </mat-tab>
            <mat-tab label="Domains"> -->
      <div class="RectangleDomain" *ngIf="domainLayoutSelected">
        <div *ngIf="validWidgets | widgetConfig: 26">
          <app-domain-screen></app-domain-screen>
        </div>
      </div>
      <div *ngIf="brandingLayoutSelected">
        <div *ngIf="!showPreview" class="preview-text" (click)="showPreview = 'true'">
          {{'PROFILE_PAGE.PREVIEW' | i18nTranslate}}
        </div>
        <div>
          <div class="main">
            <div class="branding-div" *ngIf="!mobileView || (mobileView && !showPreview)">
              <div class="store-branding-info" *ngIf="qid!='null'">
                <span class="qb-i-div"
                      >i
                  </span>
                  <p class="branding-info">{{'PROFILE_PAGE.STORE_BRANDING_INFO' | i18nTranslate}}</p>
              </div>
              <div class="design-email-title">{{'PROFILE_PAGE.DESIGN_EMAIL_TEMPLATES' | i18nTranslate}}</div>
              <div class="design-email" *ngIf="qid=='null'">
                {{'PROFILE_PAGE.DESIGN_EMAIL_TEMPLATES_MESSAGE' | i18nTranslate}}
              </div>
              <div class="design-email"  *ngIf="qid!='null'">
                {{'PROFILE_PAGE.DESIGN_EMAIL_TEMPLATES_MESSAGE_STORE' | i18nTranslate}}
              </div>
              <div style="padding-top: 35px" *ngIf="qid=='null'">{{'PROFILE_PAGE.LOGO' | i18nTranslate}}</div>
              <div class="drag-drop-div" *ngIf="qid=='null'">
                <div *ngIf="
                    !mobileView ||
                    (mobileView && logoUrl === null && fileToUpload2 === null)
                  " class="container" appDnd (fileDropped)="onFileDropped($event)" style="
                    border: 1px solid black;
                    border-style: dashed;
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0;
                    align-items: center;
                    position: relative;
                  " [class.hovering]="isHovering">
                  <input style="
                      opacity: 0;
                      position: absolute;
                      z-index: 2;
                      width: 100%;
                      height: 172px;
                      top: 0px;
                      left: 0px;
                      cursor: pointer;
                    " type="file" #fileDropRef id="fileDropRef" multiple (click)="$event.target.value = null"
                    (change)="fileBrowseHandler($event, $event.target.files)" accept=".png, .jpg, .jpeg" />
                  <p>{{'PROFILE_PAGE.DRAG_DROP' | i18nTranslate}}</p>
                  <p>{{'PROFILE_PAGE.OR' | i18nTranslate}}</p>
                  <div label for="fileDropRef" class="primary-button browse-files">
                    {{'PROFILE_PAGE.BROWSE_FILE' | i18nTranslate}}
                  </div>
                </div>
                <div *ngIf="logoUrl !== null && !imageLoading" style="position: relative; display: flex">
                  <img style="
                      width: 40%;
                      margin-top: 10px;
                      background: #ffffff;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                      border-radius: 8px;
                    " [src]="logoUrl" />

                  <div (click)="deleteLogo()" class="material-icons-outlined delete-icon">
                    delete
                  </div>
                </div>
                <div *ngIf="imageLoading" style="position: relative">
                  <div style="
                      width: 40%;
                      margin-top: 10px;
                      height: 170px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    ">
                    <mat-spinner diameter="15"></mat-spinner>
                  </div>
                </div>
              </div>
              <div class="logo-buttons" *ngIf="
                  mobileView && (logoUrl !== null || fileToUpload2 !== null)
                ">
                <div class="primary-button remove-button logo-button" (click)="deleteLogo()">
                  <div>{{'PROFILE_PAGE.REMOVE' | i18nTranslate}}</div>
                </div>
                <input style="    opacity: 0;
                position: absolute;
                z-index: 2;
                width: 36%;
    height: 41px;
    top: 568px;
    left: 219px;" type="file" #fileDropRef id="fileDropRef" multiple
                  (change)="fileBrowseHandler($event, $event.target.files)" />
                <div label for="fileDropRef" class="primary-button logo-button">
                  <div>{{'PROFILE_PAGE.CHANGE_LOGO' | i18nTranslate}}</div>
                </div>
              </div>
              <div style="padding: 25px 0 0px; font-weight: 600;">{{'PROFILE_PAGE.SOCIAL_MEDIA_LINKS' | i18nTranslate}}</div>
              <div *ngFor="let link of numberOfLinks; let i = index">
                <div id="{{ i }}" style="
                    display: flex;
                    justify-content: space-between;
                    width: 95%;
                    margin-top: 20px;
                  ">
                  <div class="platform-div">
                    <div style="
                        font-size: 12px;
                        color: var(--main-font-black);
                        padding-bottom: 5px;
                      ">
                      {{'PROFILE_PAGE.PLATFORM' | i18nTranslate}}
                    </div>
                    <div style="
                      border: 1px solid black;
                      border-radius: 4px;
                      width: 100%;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      background: #F5F6FA;
                      ">
                      <img *ngIf="keys[i] !== undefined" class="logo-image1" [src]="
                          'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/' +
                          keys[i] +
                          '.png'
                        " />
                      <div class="social-key">
                        <div style="position: absolute; margin-top: -10px">
                          {{ keys[i] }}
                        </div>
                      </div>
                      <mat-select (selectionChange)="changeLink($event, i)" [value]="keys[i]">
                        <ng-container *ngFor="let selectLink of displayLink">
                          <mat-option *ngIf="this.selectedLinks.indexOf(selectLink) == -1" [value]="selectLink">
                            <div>
                              <img width="20px" [src]="
                                  'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/' +
                                  selectLink +
                                  '.png'
                                " />
                              {{ selectLink }}
                            </div>
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </div>
                  </div>
                  <div class="url-div">
                    <div style="
                         font-size: 12px;
                         color: var(--main-font-black);
                        padding-bottom: 5px;
                      ">
                      URL
                    </div>
                    <input (input)="input($event)" type="text" class="url-input" [value]="values[i]" [id]=i />
                  </div>
                  <div *ngIf="this.numberOfLinks.length > 0" style="padding-top: 30px; cursor: pointer"
                    (click)="removeLink(i)">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.6562 12.5938L6.5 7.39844L1.30469 12.5938C1.07031 12.8281 0.679688 12.8281 0.40625 12.5938C0.171875 12.3203 0.171875 11.9297 0.40625 11.6953L5.60156 6.5L0.40625 1.34375C0.171875 1.10938 0.171875 0.71875 0.40625 0.445312C0.679688 0.210938 1.07031 0.210938 1.30469 0.445312L6.5 5.64062L11.6562 0.445312C11.8906 0.210938 12.2812 0.210938 12.5547 0.445312C12.7891 0.71875 12.7891 1.10938 12.5547 1.34375L7.35938 6.5L12.5547 11.6953C12.7891 11.9297 12.7891 12.3203 12.5547 12.5938C12.2812 12.8281 11.8906 12.8281 11.6562 12.5938Z" fill="#202224"/>
                    </svg>  
                  </div>
                </div>
              </div>
              <div style="display: flex; padding-top: 20px; cursor: pointer" *ngIf="numberOfLinks.length < 5"
                (click)="addNewLink()">
                <div class="material-icons" style="color: #0183fb">add</div>
                <div style="color: #0183fb">{{'PROFILE_PAGE.ADD_SOCIAL_MEDIA_LINK' | i18nTranslate}}</div>
              </div>
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 20px;
                  margin-right: 40px;
                " (click)="save()">
                <div class="primary-button">{{'PROFILE_PAGE.SAVE' | i18nTranslate}}</div>
              </div>
            </div>
            <div class="sample-email-div" *ngIf="showPreview">
              <div class="back-arrow" (click)="showPreview = false">
                <div class="material-icons-outlined" style="font-size: 20px">
                  arrow_back_ios
                </div>
              </div>
              <div class="email-template-preview">{{'PROFILE_PAGE.EMAIL_TEMPLATE_PREVIEW' | i18nTranslate}}</div>
              <div style="padding: 20px">
                <div class="sample-email-header">
                  <div style="
                      background-color: #ff6058;
                      width: 11px;
                      height: 11px;
                      border-radius: 20px;
                      margin-left: 10px;
                    "></div>
                  <div style="
                      background-color: #ffc130;
                      width: 11px;
                      height: 11px;
                      border-radius: 20px;
                      margin-left: 10px;
                    "></div>
                  <div style="
                      background-color: #27ca40;
                      width: 11px;
                      height: 11px;
                      border-radius: 20px;
                      margin-left: 10px;
                    "></div>
                </div>
                <div class="sample-email">
                  <div style="opacity: 0.4; font-size: 24px" *ngIf="logoUrl === null">
                    {{'PROFILE_PAGE.YOUR_LOGO_HERE' | i18nTranslate}}
                  </div>
                  <img *ngIf="logoUrl !== null" style="width: 40%" [src]="logoUrl" />
                  <div style="
                      padding: 10px;
                      border-bottom: 1px solid black;
                      width: 100%;
                      display: flex;
                      justify-content: center;
                    ">
                    {{'PROFILE_PAGE.SHOP_NOW_CAPS' | i18nTranslate}}
                  </div>
                  <div style="font-size: 18px; padding-top: 10px">
                    {{'PROFILE_PAGE.THANKS_FOR_SHOPPING' | i18nTranslate}}
                  </div>
                  <div style="padding: 10px">
                    {{'PROFILE_PAGE.THANNKS_SHOPPING_MESSAGE' | i18nTranslate}}
                  </div>
                  <div class="primary-button start-button">{{'PROFILE_PAGE.START_SHOPPNG' | i18nTranslate}}</div>
                  <div class="social-div">
                    <div *ngFor="let link of selectedLinks" class="social-icon">
                      <div>
                        <img style="width: 20px" [src]="
                            'https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/' +
                            link +
                            '.png'
                          " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </mat-tab>
        </mat-tab-group> -->
      <div class="UserContainer" *ngIf="usermanagementLayoutSelected">
        <div class="featureTab">
          <div style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin: 0 15px;
            ">
            <div [ngClass]="
                isAdmin === true ? 'primary-button' : 'primay-button-nocursor'
              " (click)="isAdmin === true ? addNewUser() : ''" [matTooltip]="
                isAdmin === true ? '' : 'Only Admins can perform this action'
              ">
              <span class="addUserText">{{'PROFILE_PAGE.ADD_NEW_USER' | i18nTranslate}}</span>
            </div>
          </div>
        </div>
        <div class="tableContent">
          <div class="tableDiv">
            <div>
              <span class="UserText">{{'PROFILE_PAGE.USERS' | i18nTranslate}}</span>
            </div>
            <div class="supressionRectangle">
              <div class="searchBar">
                <input type="text" placeholder=" Search by User Name" [(ngModel)]="filter.label" />
                <span class="material-icons icons-primary" style="padding: 0 5px 0 0">
                  search
                </span>
              </div>
            </div>
          </div>
          <div class="table-main">
            <div class="qb-table-main-wrapper">
              <div class="header" style="width: 20%">
                {{'PROFILE_PAGE.USER_NAME' | i18nTranslate}}
              </div>
              <div class="header" style="width: 20%">
                {{'PROFILE_PAGE.EMAIL' | i18nTranslate}}
              </div>
              <div class="header" style="width: 15%">
                {{'PROFILE_PAGE.PHONE_NUMBER' | i18nTranslate}}
              </div>
              <div class="header" style="width: 15%">
                {{'PROFILE_PAGE.STORE_ACESS' | i18nTranslate}}
              </div>
              <div class="header">{{'PROFILE_PAGE.MEMBER_SINCE' | i18nTranslate}}</div>
              <div class="header" style="width: 0%; margin: 0px"></div>
            </div>

            <!-- <div style="display: flex; padding-left: 20px;
              height: 50px;
              background:
          #EDF0F2;">
                <div class="header" style="width: 33%;">User Name</div>
                <div class="header" style="width: 33%;">Email</div>
                <div class="header" >Member Since</div>
                <div class="header" style="width: 0%; margin: 0px;"> </div>
              </div> -->
            <!-- <div
                style="
                  display: flex;
                  padding-left: 20px;
                  height: 50px;
                  background: #edf0f2;
                "
              >
                <div
                  class="header"
                  style="width: 33%"
                >
                  User Name
                </div>
                <div
                  class="header"
                  style="width: 33%"
                >
                  Email
                </div>
                <div class="header">Member Since</div>
                <div class="header" style="width: 0%; margin: 0px"></div>
              </div> -->
            <div *ngIf="
                (dataUser | labelPipe: filter.label:'name').length === 0 &&
                dataUser.length != 0
              " style="display: flex; justify-content: center">
              <div class="empty-customers-div">
                <div>{{'PROFILE_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
              </div>
            </div>
            <div style="margin-bottom: 90px;height: calc(100% - 60px);overflow: auto;">
              <div style="display: flex; padding: 20px 20px 14px" class="qb-table-data-container"
                *ngFor="let user of dataUser | labelPipe: filter.label:'name';let i = index">
                <div style="
                    height: 35px;
                    align-items: center;
                    width: 21%;
                    margin-top: 5px;
                  " class="User textOverflow">
                  {{ user["name"] }}
                  <span *ngIf="user['isLoggedIn'] == true" style="color: #636466">({{'PROFILE_PAGE.YOU' |
                    i18nTranslate}})&nbsp;&nbsp;</span>
                  <span *ngIf="user['isAdmin'] == true" style="margin-top: -5px" class="qb-admin-text">{{'PROFILE_PAGE.ADMIN' |
                    i18nTranslate}}</span>
                  <div style="width: 100%;" *ngIf="user['isAdmin'] != true" class="qb-user-type-text">
                    <span>
                      User Type: {{
                      getUserType(user["role"])
                      }}
                    </span>
                  </div>
                </div>
                <div style="
                    height: 25px;
                    align-items: center;
                    width: 22%;
                    margin-left: 5px;
                    margin-top: 5px;
                  " class="User textOverflow">
                  {{ user["email"] }}
                </div>
                <div style="
                height: 25px;
                align-items: center;
                width: 16%;
                margin-left: 5px;
                margin-top: 5px;
              " class="User textOverflow">
                  +{{ user["phoneNumber"] }}
                </div>
                <div style="
            height: 25px;
            align-items: center;
            width: 16%;
            margin-left: 5px;
            margin-top: 5px;
            position: relative;
          " class="User" *ngIf="
            user['stores'].length > 0 &&
            user['stores'] !== undefined && user['stores'] !== null
          ">
                  <div class="qb-customer-segment-container textOverflow">
                    <span class="qb-segmentsTargeted">{{ user['stores'][0]['storeName'] | limitTo: 20}}
                      <div>
                        <div *ngIf="user?.stores?.length > 1" id="{{ i }}" class="qb-expandDiv"
                          (click)="showSegments($event, i)" data-button-name="Show Stores">
                          <span id="{{ i }}"
                            class="material-icons qb-doubleArrowIcon qb-double-arrow-icon">double_arrow</span>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="qb-stores-popup" [id]="'popup' + i">
                      <div *ngFor="let store of user['stores']">
                        <div class="qb-segment-name-container">
                          {{ store["storeName"] }}
                        </div>
                      </div>
                    </div>
                  </div>
                <div style="
      height: 25px;
      align-items: center;
      width: 16%;
      margin-left: 5px;
      margin-top: 5px;
    " class="User textOverflow" *ngIf="
    user['stores'].length <= 0 ||
    user['stores'] === undefined
    ">
                  <div style="padding-left: 30%;">
                    -
                  </div>
                </div>
                <div *ngIf="
                    user['memberSince'] == 'Invite Sent' ||
                    user['memberSince'] == 'Invite Expired'
                  " style="
                    height: 25px;
                    align-items: center;
                    width: 20%;
                    padding: 3px 4px 3px 4px;
                    margin-top: 5px;
                  " class="User textOverflow" [ngClass]="user['memberSince'] == 'Invite Sent' ? '' : 'red'">
                  <div style="display: flex; flex-direction: row; justify-content: space-between; width: 125px; align-items: center;">
                    <div>
                      {{ user["memberSince"] }}
                    </div>
                    <div *ngIf="
                        user['memberSince'] == 'Invite Sent' ||
                        user['memberSince'] == 'Invite Expired'
                      ">
                      <span [ngClass]="
                          isAdmin === true
                            ? 'material-icons icons-primary delete-button qb-icon-wrapper'
                            : 'material-icons icons-primary notAllowed qb-icon-wrapper'
                        " (click)="
                          isAdmin === true
                            ? SendMailAgain(
                                user['email'],
                                user['userId'],
                                user['name'],
                                user['role'],
                                user['storeId'],
                                user['phoneNumber'],
                                user['stores']
                              )
                            : ''
                        " [matTooltip]="
                          isAdmin === true
                            ? 'Send Again'
                            : 'Only Admins can perform this action'
                        ">forward_to_inbox</span>
                      <!-- <span [ngClass]="isAdmin===true?'material-icons-outlined icon-stack-1x':'material-icons-outlined icon-stack-1x notAllowed'">refresh</span> -->
                    </div>
                  </div>
                </div>
                <div style="
                    display: flex;
                    height: 25px;
                    align-items: center;
                    width: 20%;
                    padding: 3px 4px 3px 4px;
                    margin-top: 5px;
                  " class="User textOverflow" *ngIf="
                    user['memberSince'] != 'Invite Sent' &&
                    user['memberSince'] != 'Invite Expired'
                  ">
                  <!-- <span style="width:10%">{{date}} &nbsp;</span>
                  <span style="width: 10%;">| {{time}}</span> -->
                  {{ user["memberSince"] | toDateObj | date: getDateFormat("dd MMM yyyy") }}
                  |
                  {{ user["memberSince"] | toDateObj | customTime }}
                </div>
                <div *ngIf="user['isAdmin'] != true && user['isLoggedIn'] != true" class="qb-delete-button-container">
                  <div *ngIf="isAdmin == true"
                    class="icons-primary delete-button"
                    (click)="DeleteUser(user['name'], user['email'])"
                    [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate">
                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 3C14.1458 3 14.2604 3.05208 14.3438 3.15625C14.4479 3.23958 14.5 3.35417 14.5 3.5V4C14.5 4.14583 14.4479 4.27083 14.3438 4.375C14.2604 4.45833 14.1458 4.5 14 4.5H13.5L12.8438 15.0938C12.8229 15.4896 12.6667 15.8229 12.375 16.0938C12.0833 16.3646 11.7396 16.5 11.3438 16.5H3.65625C3.26042 16.5 2.91667 16.3646 2.625 16.0938C2.33333 15.8229 2.17708 15.4896 2.15625 15.0938L1.5 4.5H1C0.854167 4.5 0.729167 4.45833 0.625 4.375C0.541667 4.27083 0.5 4.14583 0.5 4V3.5C0.5 3.35417 0.541667 3.23958 0.625 3.15625C0.729167 3.05208 0.854167 3 1 3H3.5625L4.625 1.21875C4.91667 0.739583 5.35417 0.5 5.9375 0.5H9.0625C9.64583 0.5 10.0833 0.739583 10.375 1.21875L11.4375 3H14ZM5.9375 2L5.3125 3H9.6875L9.0625 2H5.9375ZM11.3438 15L12 4.5H3L3.65625 15H11.3438Z" fill="#4379EF"/>
                    </svg>                      
                  </div>
                  <div *ngIf="isAdmin != true" style="cursor: not-allowed"
                    class="icons-primary"
                    [matTooltip]="'COMMON_PAGE.ONLY_ADMIN_ACESS_DELETE' | i18nTranslate">
                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 3C14.1458 3 14.2604 3.05208 14.3438 3.15625C14.4479 3.23958 14.5 3.35417 14.5 3.5V4C14.5 4.14583 14.4479 4.27083 14.3438 4.375C14.2604 4.45833 14.1458 4.5 14 4.5H13.5L12.8438 15.0938C12.8229 15.4896 12.6667 15.8229 12.375 16.0938C12.0833 16.3646 11.7396 16.5 11.3438 16.5H3.65625C3.26042 16.5 2.91667 16.3646 2.625 16.0938C2.33333 15.8229 2.17708 15.4896 2.15625 15.0938L1.5 4.5H1C0.854167 4.5 0.729167 4.45833 0.625 4.375C0.541667 4.27083 0.5 4.14583 0.5 4V3.5C0.5 3.35417 0.541667 3.23958 0.625 3.15625C0.729167 3.05208 0.854167 3 1 3H3.5625L4.625 1.21875C4.91667 0.739583 5.35417 0.5 5.9375 0.5H9.0625C9.64583 0.5 10.0833 0.739583 10.375 1.21875L11.4375 3H14ZM5.9375 2L5.3125 3H9.6875L9.0625 2H5.9375ZM11.3438 15L12 4.5H3L3.65625 15H11.3438Z" fill="#4379EF"/>
                    </svg>  
                  </div>
                </div>
                <div *ngIf="user['isAdmin'] == true || user['isLoggedIn'] == true" style="color: transparent"
                  class="material-icons-outlined icons-primary">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="communicationLayoutSelected">
        <app-communication-screen></app-communication-screen>
      </div>
    </div>
  </div>
</div>