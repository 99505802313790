import { getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";

@Component({
  selector: "app-rewards",
  templateUrl: "./rewards.component.html",
  styleUrls: ["./rewards.component.css"],
})
export class RewardsComponent implements OnInit {
  revenueRange = "allTime";
  pageNumber = 1;
  pageSize = 10;
  rewardName = "";
  rewards = [];
  revCurrency;
  currencySymbol;
  timeout;
  total = 0;
  loading = true;
  tableHeadings = ['Reward Name', 'Value', 'No of Partners Shared with', 'Reward Shared'];

  constructor(private router: Router, private apiCall: ApiCallService) {}

  ngOnInit() {
    this.getCurrencySymbol();
    this.getRewardsList();
  }

  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }

  createNewReward() {
    this.router.navigate(["/app/partnership/rewards/new-reward"]);
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageNumber = 1;
        $this.getRewardsList();
      }
    }, 600);
  }

  getRewardsList() {
    this.loading = true;
    this.apiCall
      .getPartnershipRewards(this.pageNumber, this.pageSize, this.rewardName)
      .subscribe((response) => {
        this.rewards = JSON.parse(response["body"])["list"];
        this.total = JSON.parse(response["body"])["count"];
        this.loading = false;
      });
  }

  refreshPaginatedData(event) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getRewardsList();
  }
}
