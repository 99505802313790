import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-contact-us-dialog",
  templateUrl: "./contact-us-dialog.component.html",
  styleUrls: ["./contact-us-dialog.component.css"],
})
export class ContactUsDialogComponent implements OnInit {
  string1: string;
  constructor(
    public dialogRef: MatDialogRef<ContactUsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  // @Inject(MAT_DIALOG_DATA) public status: any) { }

  ngOnInit() {
  }
  submit(decision) {
    this.dialogRef.close(decision);
  }
}
