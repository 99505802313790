import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-email-template-preview-dialog',
  templateUrl: './email-template-preview-dialog.component.html',
  styleUrls: ['./email-template-preview-dialog.component.css']
})
export class EmailTemplatePreviewDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EmailTemplatePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  imgUrl;

  ngOnInit(): void {
    this.selectTemplate(this.data.template);
  }

  selectTemplate(template) {
    switch (template) {
      case "T1": 
        this.imgUrl = "https://cdn-greyfox.s3.amazonaws.com/greyfox-assets/snapshots/prod/emails/1297/CiyVhw3Llayouta1.png";
        break;
      case "T2":
        this.imgUrl = "https://cdn-greyfox.s3.amazonaws.com/greyfox-assets/snapshots/prod/emails/1297/DoMgxUmelayoutaa2.png";
        break;
      case "T3":
        this,this.imgUrl = "https://cdn-greyfox.s3.amazonaws.com/greyfox-assets/snapshots/prod/emails/1297/4xZrjI8Dlayoutaa3.png";
        break;
      case "T4":
        this.imgUrl = "https://cdn-greyfox.s3.amazonaws.com/greyfox-assets/snapshots/prod/emails/1297/JC6l6wiRlayoutaa4.png";
        break;
    }
  }

  close(){
    this.dialogRef.close();
  }
}
