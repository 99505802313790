import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
interface DialogData  {
  'type' : string
  'message' : string
}

@Component({
  selector: 'app-pop-up-dialog',
  templateUrl: './pop-up-dialog.component.html',
  styleUrls: ['./pop-up-dialog.component.css']
})
export class PopUpDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PopUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit() {
    this.data.message = this.data.message ? this.data.message.toString() : ''; 
    let minimumInterval = 2000;
    let extraLength;
    let finalInterval = minimumInterval;
    let length = this.data.message ? this.data.message.split(/[ ,.]+/).length : 0;
    if(length > 10){
      extraLength = length - 10;
      finalInterval += extraLength * 300;
    }
    let interval = setInterval(()=>{
      clearInterval(interval)
      this.dialogRef.close()
    },finalInterval > 10000 ? 10000 : finalInterval)
  }

}
