<div class="qb-forms-wrapper">
  <div class="qb-create-div">
    <span class="primary-button qb-create-form" (click)="createForm()">
      + {{'ACQUIRE_PAGE.CREATE_NEW_FORM' | i18nTranslate}}</span
    >
  </div>

  <div class="qb-forms" (scroll)="onScroll($event)">
    <div class="qb-forms-div">
      <div class="qb-buttons">
        <div class="qb-forms-title">{{'ACQUIRE_PAGE.FORM_LIST' | i18nTranslate}}</div>
        <div class="qb-search-bar-div">
          <div class="qb-form-list-rectangle">
            <div class="qb-form-list-search-bar">
              <input
                #search
                type="text"
                [(ngModel)]="filter.label"
                type="text"
                placeholder="Search by form name"
                (keyup)="onKeySearch($event)"
              />

              <span
                class="material-icons-outlined icons-primary qb-icons"
                *ngIf="filter.label.length == 0"
              >
                search
              </span>
              <span
                class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
                *ngIf="filter.label.length > 0"
                (click)="filter.label = ''; onKeySearch(filter.label)"
              >
                close
              </span>
            </div>
          </div>
          <div class="qb-all-filters">
            <div
              class="qb-filter-button"
              (click)="openFilters()"
              data-button-name="Filter"
            >
              <div class="qb-filter-div">
                <span class="material-icons-outlined icons-primary qb-icons">
                  filter_alt
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="qb-table-header">
        <div class="qb-header-items-top qb-form-name">{{'ACQUIRE_PAGE.FORM_NAME' | i18nTranslate}}</div>
        <div class="qb-header-items-top qb-target">{{'ACQUIRE_PAGE.TARGET' | i18nTranslate}}</div>
        <div class="qb-header-items-top qb-last-activated-on">
          {{'ACQUIRE_PAGE.LAST_ACTIVATED_ON' | i18nTranslate}}
        </div>
        <div class="qb-header-items-top qb-status">Status</div>
      </div>
      <div *ngIf="mobileView">
        <div *ngIf="formData.length == 0">
          <div class="qb-no-data">{{'ACQUIRE_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
        </div>
        <div *ngFor="let form of formData" class="qb-form-data-div">
          <div class="qb-form-div-padding">
            <div class="qb-form-name-div">
              <div>{{ form["formName"] }}</div>
              <div class="material-icons-outlined icons-primary">delete</div>
            </div>
            <div class="qb-status-div">
              <div class="qb-status-flex">
                <div class="qb-status-text">{{'ACQUIRE_PAGE.STATUS' | i18nTranslate}}</div>
                <div class="qb-status-flex">
                  <div
                    class="material-icons qb-margin"
                    [ngClass]="{
                      'qb-active': form['status'] === 'ACTIVE',
                      'qb-draft': form['status'] === 'DRAFT',
                      'qb-ended': form['status'] === 'ENDED'
                    }"
                  >
                    flag
                  </div>
                  <div
                    [ngClass]="{
                      'qb-active': form['status'] === 'ACTIVE',
                      'qb-draft': form['status'] === 'DRAFT',
                      'qb-ended': form['status'] === 'ENDED'
                    }"
                  >
                    {{ form["status"] }}
                  </div>
                </div>
              </div>
              <div>
                <mat-slide-toggle
                  (change)="toggleFormEvent(i, form, $event)"
                  (click)="$event.stopPropagation()"
                  color="primary"
                  [checked]="form['status'] === 'ACTIVE'"
                  [disabled]="form['status'] === 'DRAFT'"
                  [disabled]="form['status'] === 'ENDED'"
                >
                </mat-slide-toggle>
              </div>
            </div>
            <div *ngIf="form['status'] !== 'DRAFT'" class="qb-total-views">
              {{'ACQUIRE_PAGE.TOTAL_VIEWS' | i18nTranslate}}
            </div>
            <div
              *ngIf="form['status'] !== 'DRAFT' && viewMoreID !== form['id']"
              class="qb-view-more"
              (click)="viewMore(form['id'])"
            >
              {{'ACQUIRE_PAGE.VIEW_MORE' | i18nTranslate}}
            </div>
            <div
              *ngIf="
                form['status'] !== 'DRAFT' &&
                viewMoreID === form['id'] &&
                formanalyticsData
              "
            >
              <div class="qb-submission">
                <div>
                  {{'ACQUIRE_PAGE.SUBMISSION' | i18nTranslate}} -
                  <strong>{{ formanalyticsData.submittedViews }}</strong>
                </div>
                <div>
                  <strong
                    >{{
                      formanalyticsData.totalViews == 0
                        ? 0
                        : ((formanalyticsData.submittedViews * 100) /
                            formanalyticsData.totalViews | number: "1.2-2")
                    }}%</strong
                  >
                </div>
              </div>
              <div class="qb-analytics-data-div">
                <div class="qb-analytics-data" *ngIf="formanalyticsData">
                  <div
                    class="qb-data"
                    [style.width.%]="
                      (formanalyticsData.submittedViews * 100) /
                      formanalyticsData.totalViews
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="qb-view-less"
              *ngIf="form['status'] !== 'DRAFT' && viewMoreID === form['id']"
              (click)="viewMore(form['id'])"
            >
              {{'ACQUIRE_PAGE.VIEW_LESS' | i18nTranslate}}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading && !mobileView" class="qb-spinner">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
      <div *ngIf="formData.length == 0 && !loading" class="qb-form-data">
        <div class="qb-no-data">{{'ACQUIRE_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
      </div>
      <div
        *ngIf="!loading && !mobileView && formData.length > 0"
        class="qb-forms-data"
      >
        <div class="qb-for qb-loop" *ngFor="let form of formData; index as i">
          <div
            class="qb-form-list qb-status-flex cursor-pointer"
            [ngClass]="{ active: selectedIndex === i }"
            (click)="getFormAnalytics(form['id'], i)"
          >
            <div class="qb-value-items-top qb-form-name">
              <div
                [matTooltip]="form['formName']"
                matTooltipClass="ex-tooltip-red1"
                matTooltipShowDelay="500"
                class="qb-overflow"
              >
                {{ form["formName"] }}
              </div>
            </div>
            <div class="qb-value-items-top qb-target">
              {{ form["target"] }}
            </div>
            <div class="qb-value-items-top qb-last-activated-on">
              {{ form["lastActivatedOn"] | date: getDateFormat("dd MMM yyyy") }} |
              {{ form["lastActivatedOn"] | customTime }}
            </div>
            <div class="qb-value-items-top qb-status">
              <div class="qb-value-items">
                <div class="qb-value">
                  <span>
                    <span
                      class="material-icons"
                      [ngClass]="{
                        'qb-active': form['status'] === 'ACTIVE',
                        'qb-draft': form['status'] === 'DRAFT',
                        'qb-ended': form['status'] === 'ENDED'
                      }"
                    >
                      flag
                    </span>
                  </span>
                  <span
                    class="qb-form-status"
                    [ngClass]="{
                      'qb-active': form['status'] === 'ACTIVE',
                      'qb-draft': form['status'] === 'DRAFT',
                      'qb-ended': form['status'] === 'ENDED'
                    }"
                  >
                    {{ form["status"] }}
                  </span>
                </div>
                <div>
                  <mat-icon
                    [matMenuTriggerFor]="menuDraft"
                    class="cursor-pointer"
                    >more_vert</mat-icon
                  >
                  <mat-menu #menuDraft class="qb-channel-dialog">
                    <button
                      mat-menu-item
                      class="qb-channel-dialog-content"
                      (click)="editForm(form['id'])"
                    >
                      <span>{{'ACQUIRE_PAGE.EDIT' | i18nTranslate}}</span>
                    </button>
                    <button
                      mat-menu-item
                      class="qb-channel-dialog-content"
                      (click)="cloneForm(form['id'])"
                    >
                      <span>{{'ACQUIRE_PAGE.CLONE' | i18nTranslate}}</span>
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="form['status'] !== 'ACTIVE'"
                      class="qb-channel-dialog-content"
                      (click)="deleteForm(form['id'])"
                    >
                      <span>{{'ACQUIRE_PAGE.DELETE' | i18nTranslate}}</span>
                    </button>
                    <button
                      mat-menu-item
                      [disableRipple]="true"
                      *ngIf="form['status'] === 'ACTIVE'"
                      class="qb-channel-dialog-content qb-channel"
                      (click)="$event.stopPropagation()"
                      matTooltipPosition="below"
                      [matTooltip]="'COMMON_PAGE.ACTIVE_CANNOT_DELETE' | i18nTranslate"
                    >
                      <span>{{'ACQUIRE_PAGE.DELETE' | i18nTranslate}}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div>
                <mat-slide-toggle
                  (change)="toggleFormEvent(i, form, $event)"
                  (click)="$event.stopPropagation()"
                  color="primary"
                  [checked]="form['status'] === 'ACTIVE'"
                  [disabled]="form['status'] === 'DRAFT'"
                  [disabled]="form['status'] === 'ENDED'"
                >
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="qb-paginator-div">
        <mat-paginator
          *ngIf="!mobileView"
          #paginator
          [pageIndex]="0"
          [length]="formDataTotalCount"
          [pageSize]="recordsPerPage"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="getNextData($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div class="qb-analytics-div">
      <div *ngIf="!analyticsloading && formData.length == 0" class="qb-center">
        <div class="qb-no-data">{{'ACQUIRE_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
      </div>
      <div
        *ngIf="
          !analyticsloading && formDataTotalCount > 0 && formData.length > 0
        "
      >
        <div class="qb-margin-bottom">
          <span class="qb-break-word">{{
            formData[selectedIndex]["name"]
          }}</span>
        </div>

        <div class="qb-form-analytics" *ngIf="formanalyticsData">
          <div class="qb-margin-right">
            <div class="qb-form-subheader">{{'ACQUIRE_PAGE.TOTAL_VIEWS' | i18nTranslate}}</div>
            <div class="qb-form-sub-value">
              {{ formanalyticsData.totalViews }}
            </div>
          </div>
          <div>
            <div
              class="qb-form-subheader"
              *ngIf="formanalyticsData.revenue !== null"
            >
              {{'ACQUIRE_PAGE.REVENUE_GENERATED' | i18nTranslate}}
            </div>
            <div
              class="qb-form-sub-value"
              *ngIf="formanalyticsData.revenue !== null"
            >
              $
              {{
                formanalyticsData.revenue == null
                  ? 0
                  : formanalyticsData.revenue
              }}
            </div>
          </div>
        </div>

        <div class="qb-form-analytics-div" *ngIf="formanalyticsData">
          <div class="qb-form-subheader">
            {{'ACQUIRE_PAGE.SUBMISSION' | i18nTranslate}}- {{ formanalyticsData.submittedViews }}
          </div>
          <div class="qb-form-sideheader">
            {{
              formanalyticsData.totalViews == 0
                ? 0
                : ((formanalyticsData.submittedViews * 100) /
                    formanalyticsData.totalViews | number: "1.0-0")
            }}%
          </div>
        </div>

        <div class="qb-forms-analytics" *ngIf="formanalyticsData">
          <div
            class="qb-forms-total-views"
            [style.width.%]="
              formanalyticsData.totalViews == 0
                ? 0
                : (formanalyticsData.submittedViews * 100) /
                  formanalyticsData.totalViews
            "
          ></div>
        </div>

        <div class="qb-email-captured" *ngIf="formanalyticsData">
          <div class="qb-form-subheader">{{'ACQUIRE_PAGE.EMAIL_ADDRESS_CAPTURED' | i18nTranslate}}</div>
          <div class="qb-form-sub-value">
            {{ formanalyticsData["submittedViews"] }}
          </div>
        </div>
        <div *ngIf="formanalyticsData == null">
          <div class="qb-no-data-yet">
            <div class="qb-form-subheader">{{'ACQUIRE_PAGE.THERE_IS_NO_DATA' | i18nTranslate}}</div>
          </div>
        </div>
        <div *ngIf="formanalyticsData">
          <hr />
        </div>
        <div *ngIf="formanalyticsData.totalViews > 0">
          <div>
            <div class="qb-action-button2" (click)="performAction(1, 'email')">
              <span class="material-icons-outlined qb-email-icon" 
                >email</span
              >
              <div
                class="qb-action-type"
                (click)="
                  customerToSendEmailsCount =
                  formAnalytics.customerToSendEmailsCount
                "
              >
                {{'ACQUIRE_PAGE.SEND_EMAIL' | i18nTranslate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="analyticsloading" class="qb-spinner-div">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
    </div>
  </div>
</div>
<div *ngIf="actionDiv">
  <div class="qb-overlay"></div>
  <div class="qb-actions">
    <div class="qb-send-email-div">
      <div class="qb-sub-header">
        <div class="qb-send-email-text" style="text-transform: uppercase;">{{'ACQUIRE_PAGE.SEND_EMAIL' | i18nTranslate}}</div>
        <div class="qb-send-email-text" *ngIf="actionType === 'offer'">
          {{'ACQUIRE_PAGE.SEND_OFFER' | i18nTranslate}}
        </div>
        <div class="qb-send-email-text" *ngIf="actionType === 'campaign'">
          {{'ACQUIRE_PAGE.ADD_TO_CAMPAIGN' | i18nTranslate}}
        </div>
        <div class="qb-send-email-text" *ngIf="actionType === 'survey'">
          {{'ACQUIRE_PAGE.SEND_SURVEY' | i18nTranslate}}
        </div>
        <button
          class="material-icons qb-x-icon qb-clear-button"
          (click)="actionDiv = false; templateDiv = false"
        >
          clear
        </button>
      </div>
      <div class="qb-email-selected">
        {{'ACQUIRE_PAGE.EMAIL_ID_SELECTED' | i18nTranslate}} - {{ customerToSendEmailsCount }}
      </div>

      <div class="qb-email-div">
        <div class="qb-choose-customers-text qb-email-subject">
          {{'ACQUIRE_PAGE.EMAIL_SUBJECT' | i18nTranslate}}
        </div>
        <div class="qb-input-div qb-email-subject-div" id="email-subject">
          <input
            class="qb-input-text"
            [(ngModel)]="subject"
            (input)="this.subjectError = false"
          />
        </div>
        <div *ngIf="subjectError" class="qb-email-subject-error">
          {{'ACQUIRE_PAGE.PLEASE_ENTER_EMAIL_SUBJECT' | i18nTranslate}}
        </div>
        <div
          class="qb-attach-template-button qb-email-attach-button qb-attach-div"
          (click)="attachTemplate()"
        >
          <span class="material-icons icons-primary qb-attach-icon">
            attach_file
          </span>

          <div *ngIf="templateName.length == 0" class="qb-attach-template-text">
            {{'ACQUIRE_PAGE.ATTACT_TEMPLATE' | i18nTranslate}}
          </div>
          <div
            *ngIf="templateName.length == 0 && actionType === 'offer'"
            class="qb-attach-template-text qb-email-attach-template"
          >
            {{'ACQUIRE_PAGE.ATTACH_OFFER' | i18nTranslate}}
          </div>
          <div
            *ngIf="templateName.length == 0 && actionType === 'survey'"
            class="qb-attach-template-text qb-email-attach-template"
          >
            {{'ACQUIRE_PAGE.ATTACT_SURVEY_TEMPLATE' | i18nTranslate}}
          </div>
          <div
            *ngIf="templateName.length > 0"
            class="qb-attach-template-text qb-email-attach-template"
          >
            {{'ACQUIRE_PAGE.REPLACE_TEMPLATE' | i18nTranslate}}
          </div>
        </div>
        <div
          class="qb-status-flex"
          *ngIf="!showSelectCustomersDiv && templateName.length > 0"
        >
          <span class="material-icons qb-attach"> attach_file </span>
          <div class="qb-select-template-name" (click)="showPreview()">
            {{ templateName }}
          </div>
        </div>
        <div *ngIf="selectTemplateError" class="qb-email-subject-error">
          {{'ACQUIRE_PAGE.SELECT_TEMPLATE' | i18nTranslate}}
        </div>
      </div>
      <div class="qb-email-div" *ngIf="actionType === 'campaign'">
        <div class="qb-select-campaign">
          {{'ACQUIRE_PAGE.SELECT_CAMPAIGN_TO_ADD' | i18nTranslate}}
        </div>
        <div *ngIf="campaignsLoading" class="qb-spinner2">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
        <div class="qb-campaigns">
          <div *ngFor="let campaign of campaigns">
            <mat-checkbox
              class="qb-example-margin"
              (click)="selectCampaign(campaign['id'], $event)"
            >
              {{ campaign["strategyName"] }}</mat-checkbox
            >
          </div>
        </div>
      </div>
      <div class="qb-button-end" data-widget-name="FORMS">
        <div
          class="qb-cancel-button qb-template-cancel-button"
          (click)="cancelActionDiv()"
          data-button-name="Cancel: Survey Alert Action"
        >
          <div class="qb-cancel-text">{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</div>
        </div>
        <div class="qb-save-button" (click)="send()">
          <div
            class="qb-save-text qb-send-email-button-text"
            data-button-name="Confirm: Send Email"
          >
            {{'ACQUIRE_PAGE.SEND_EMAIL' | i18nTranslate}}
          </div>
          <div
            class="qb-save-text qb-send-email-button-text"
            *ngIf="actionType === 'offer'"
            data-button-name="Confirm: Send Offer"
          >
            {{'ACQUIRE_PAGE.SEND_OFFER' | i18nTranslate}}
          </div>
          <div
            class="qb-save-text"
            *ngIf="actionType === 'campaign'"
            data-button-name="Confirm: Add to Campaign"
          >
            Add
          </div>
        </div>
      </div>
    </div>
    <div
      class="qb-attach-email-template-div"
      id="attachEmailTemplateDiv"
      *ngIf="templateDiv"
    >
      <div class="qb-sub-header">
        <div class="qb-send-email-text">{{'ACQUIRE_PAGE.EMAIL_TEMPLATES' | i18nTranslate}}</div>
        <div class="qb-send-email-text" *ngIf="actionType === 'offer'">
          {{'ACQUIRE_PAGE.OFFER_TEMPLATES' | i18nTranslate}}
        </div>
        <div class="qb-send-email-text" *ngIf="actionType === 'survey'">
          {{'ACQUIRE_PAGE.SURVEY_TEMPLATES' | i18nTranslate}}
        </div>
        <button
          class="material-icons qb-x-icon qb-clear"
          (click)="cancelTemplateDiv()"
        >
          clear
        </button>
      </div>
      <div class="qb-create-template">
        <div class="qb-create-new-template">{{'ACQUIRE_PAGE.CREATE_NEW_TEMPLATE' | i18nTranslate}}</div>
        <div class="qb-add-button" (click)="createEmailTemplate(-1)">
          <span class="material-icons-outlined qb-white cursor-pointer">
            add
          </span>
        </div>
      </div>
      <div
        class="qb-choose-customers-div"
        [ngClass]="{
          'qb-bottom-div': actionType === 'email',
          'qb-bottom-div2': actionType === 'offer'
        }"
      >
        <div class="qb-form-name-div">
          <div
            class="qb-choose-customers-text choose-template"
            *ngIf="actionType != 'campagin'"
          >
            {{'ACQUIRE_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}
          </div>
          <div class="qb-campaign-div">
            <div class="qb-campaign-list-rectangle">
              <div class="qb-campaign-list-search-bar">
                <input
                  #search
                  type="text"
                  class="qb-input-text qb-no-padding"
                  [(ngModel)]="searchValue"
                  (input)="searchTemplates($event.target.value)"
                  placeholder="Search Template"
                />

                <div
                  class="material-icons-outlined"
                  (click)="showSearch()"
                  *ngIf="searchValue.length == 0"
                >
                  search
                </div>
                <span
                  class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
                  *ngIf="searchValue.length > 0"
                  (click)="searchValue = ''; searchTemplates(searchValue)"
                >
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="qb-center">
          <div *ngIf="actionType === 'offer'" class="qb-offer">
            <div class="qb-campaign-list-rectangle">
              <div class="qb-campaign-list-search-bar">
                <input
                  class="qb-in qb-input-text"
                  *ngIf="actionType === 'offer'"
                  #search
                  type="text"
                  [(ngModel)]="searchValue"
                  (input)="searchTemplates($event.target.value)"
                  placeholder="Search Offers"
                />
                <img
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/home-screen/search.svg"
                  class="qb-show-search"
                  (click)="showSearch()"
                />
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="actionType === 'offer'" class="qb-border">
          <div class="qb-select-channel">
            <div class="qb-select-format">{{'ACQUIRE_PAGE.SELECT_CHANNEL_FORMAT' | i18nTranslate}}</div>
            <div>
              <div class="qb-input-checkbox-div">
                <div class="qb-input-checkbox">
                  <input
                    type="checkbox"
                    name="email"
                    [checked]="channelEmail"
                    (change)="changeChannel('email')"
                    class="qb-checkbox"
                    checked
                  />
                  <div class="qb-email-text">{{'ACQUIRE_PAGE.EMAIL' | i18nTranslate}}</div>
                </div>
                <div class="qb-status-flex">
                  <input
                    type="checkbox"
                    name="sms"
                    [checked]="channelSMS"
                    (change)="changeChannel('sms')"
                    class="qb-checkbox"
                  />
                  <div class="qb-email-text" *ngIf="(validScreens | screenConfig: 18)">{{'ACQUIRE_PAGE.SMS' | i18nTranslate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="qb-status-flex">
            <div class="qb-i-div" type="button">
              <div class="qb-i">i</div>
            </div>
            <div class="qb-warning">
              {{'ACQUIRE_PAGE.SMS_REGULATION_WARNING' | i18nTranslate}}
            </div>
          </div>
        </div>
        <div
          *ngIf="emailTemplatesLoading && offerTemplatesLoading"
          class="qb-center qb-margin20"
        >
          <mat-spinner diameter="25"></mat-spinner>
        </div>
        <div class="qb-templates-div">
          <div class="qb-templates">
            <div *ngFor="let temp of templates">
              <div
                [style.border]="
                  templateID == temp['id'] && templateSelected
                    ? '3px solid #4abb5975'
                    : 'none'
                "
                class="qb-template-div"
                (click)="selectTemplate(temp['id'], temp['layoutName'], event)"
                [id]="temp['id']"
              >
                <div class="qb-images-div">
                  <img
                    class="qb-image"
                    src="{{ temp['snapshotUrl'] }}"
                    [id]="'img' + temp['id']"
                  />
                </div>
                <div class="qb-template-name">{{ temp["layoutName"] }}</div>
              </div>
            </div>
          </div>
          <div class="qb-templates2" *ngIf="actionType === 'offer'">
            <div *ngFor="let temp of templatesArray">
              <mat-checkbox
                class="qb-custom"
                [checked]="templateID == temp['id']"
                (click)="selectTemplate(temp['id'], temp['offerName'], event)"
              >
                <div class="qb-template-div2">
                  <div class="qb-form-name-div">
                    <div class="qb-template-name-offers qb-break-word">
                      {{ temp["offerName"] }}
                    </div>
                    <div
                      class="qb-template-name-offers cursor-pointer qb-preview"
                      (click)="previewOfferSummary(temp['id'])"
                    >
                      {{'ACQUIRE_PAGE.PREVIEW' | i18nTranslate}}
                    </div>
                  </div>
                </div>
              </mat-checkbox>
            </div>
            <div
              *ngIf="templatesArray.length == 0 && !offerTemplatesLoading"
              class="qb-center qb-margin30"
            >
              {{'ACQUIRE_PAGE.NO_TEMPLATE_TO_DISPLAY' | i18nTranslate}}
            </div>
          </div>
          <div class="qb-templates2" *ngIf="actionType === 'survey'">
            <div *ngFor="let temp of surveys">
              <mat-checkbox
                class="qb-custom"
                [checked]="templateID == temp['id']"
                (click)="selectTemplate(temp['id'], temp['layoutName'], $event)"
              >
                <div class="qb-template-div2">
                  <div class="qb-form-name-div">
                    <div class="qb-template-name-offers qb-break-word">
                      {{ temp["layoutName"] }}
                    </div>
                    <div
                      class="qb-template-name- cursor-pointer qb-preview"
                      (click)="previewOfferSummary(temp['id'])"
                    >
                      {{'ACQUIRE_PAGE.PREVIEW' | i18nTranslate}}
                    </div>
                  </div>
                </div>
              </mat-checkbox>
            </div>
            <div
              *ngIf="templatesArray.length == 0 && !offerTemplatesLoading"
              class="qb-center qb-margin30"
            >
              {{'ACQUIRE_PAGE.NO_TEMPLATE_TO_DISPLAY' | i18nTranslate}}
            </div>
          </div>
        </div>
      </div>
      <div class="qb-button-end">
        <div
          class="qb-cancel-button qb-template-cancel-button"
          (click)="cancelTemplateDiv()"
        >
          <div class="qb-cancel-text">{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</div>
        </div>
        <button
          class="qb-save-button"
          (click)="confirm()"
          [disabled]="template.length == 0"
        >
          <div class="qb-save-text qb-confirm-text">{{'ACQUIRE_PAGE.CONFIRM' | i18nTranslate}}</div>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showFiltersDiv">
  <div class="qb-overlay"></div>
  <div class="qb-filter-actions">
    <div class="qb-title-div">
      <div class="qb-titles">
        <button class="qb-x-icon" (click)="closeFiltersDiv()">
          <div class="material-icons icons-primary qb-font-size">clear</div>
        </button>
      </div>
      <div class="qb-form-name-div qb-margin15">
        <div class="qb-title-filter">{{'ACQUIRE_PAGE.FILTERS' | i18nTranslate}}</div>
        <div
          *ngIf="showClearAll"
          class="qb-clear-all cursor-pointer"
          (click)="clear()"
        >
          {{'ACQUIRE_PAGE.CLEAR_ALL' | i18nTranslate}}
        </div>
      </div>
    </div>
    <div class="qb-filter">
      <div>
        <p class="qb-margin20">
          <strong class="qb-status-strong">{{'ACQUIRE_PAGE.STATUS' | i18nTranslate}}</strong>
        </p>
        <div class="qb-mat-checkbox">
          <mat-checkbox
            class="qb-checkbox-filter"
            [checked]="filters['status'].indexOf('ALL') != -1"
            value="ALL"
            (change)="addStatusFilter('ALL')"
          ></mat-checkbox>
          <div class="qb-option">{{'ACQUIRE_PAGE.ALL' | i18nTranslate}}</div>
        </div>
        <div class="qb-mat-checkbox">
          <mat-checkbox
            class="qb-checkbox-filter"
            [checked]="filters['status'].indexOf('ACTIVE') != -1"
            value="ACTIVE"
            (change)="addStatusFilter('ACTIVE')"
          ></mat-checkbox>
          <div class="qb-option">{{'ACQUIRE_PAGE.ACTIVE' | i18nTranslate}}</div>
        </div>
        <div class="qb-mat-checkbox">
          <mat-checkbox
            class="qb-checkbox-filter"
            [checked]="filters['status'].indexOf('DRAFT') != -1"
            value="DRAFT"
            (change)="addStatusFilter('DRAFT')"
          ></mat-checkbox>
          <div class="qb-option">{{'ACQUIRE_PAGE.DRAFT' | i18nTranslate}}</div>
        </div>
        <div class="qb-mat-checkbox">
          <mat-checkbox
            class="qb-checkbox-filter"
            [checked]="filters['status'].indexOf('ENDED') != -1"
            value="ENDED"
            (change)="addStatusFilter('ENDED')"
          ></mat-checkbox>
          <div class="qb-option">{{'ACQUIRE_PAGE.ENDED' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="qb-buttons2">
        <button class="secondary-button-border" (click)="closeFiltersDiv()">
          {{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}
        </button>
        <button class="apply primary-button" (click)="applyFilter()">
          {{'ACQUIRE_PAGE.APPLY' | i18nTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>
