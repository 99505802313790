<div>
  <div style="display: flex; margin-left: 25px; margin-top: 30px; justify-content: space-between;">
    <div class="qb-d-flex qb-ai-center">
      <div>
        <div class="qb-header">Select Journey</div>
        <div class="qb-home-campaign-selector qb-campaign-page-selector-div" (click)="openSelectCampaign()">
          <div class="qb-campaign-page-selector-container">
          </div>
          <div class="qb-select-div-2 qb-campaign-page-selector-container-2">
            <mat-select
              #selectCampaign
              class="qb-graph-select qb-of-auto"
              [(ngModel)]="selectedCampaign"
              (selectionChange)="changeCampaign()"
              [value]="selectedCampaign"
            >
              <mat-option *ngFor="
              let campaign of AllCampaignList"
              [value]="campaign"
    
              >{{campaign['name']}}</mat-option
            >
            </mat-select>
          </div>
        </div>
      </div>
      <div>
        <div class="qb-header">Parameter</div>
        <div class="qb-home-campaign-selector qb-campaign-page-selector-div" (click)="openSelectParameter()">
          <div class="qb-campaign-page-selector-container">
          </div>
          <div class="qb-select-div-2 qb-campaign-page-selector-container-2">
            <mat-select
              #selectParameter
              class="qb-graph-select qb-of-auto"
              placeholder="Select a parameter"
              [(ngModel)]="selectedParameter"
              (selectionChange)="changeParameter()"
              [value]="selectedParameter"
            >
              <mat-option *ngFor="
              let data of parameter"
              [value]="data"
              >{{data['displayname']}}</mat-option
            >
            </mat-select>
          </div>
        </div>
      </div>
    </div>
    <div class="qb-d-flex qb-ai-center">
      <div class="qb-date-range-picker-wrapper">
        <mat-form-field appearance="fill">
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onEndDateChange($event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div class="qb-d-flex qb-ai-center qb-m-25">
        <div class="qb-filter-view-by-text">
          Filter View by &nbsp;&nbsp;
        </div>
        <div>
          <div>
            <div
              class="qb-multi-select-panel"
              id="qb-multi-select-panel"
              (click)="toggleSelect()"
            >
              <span *ngIf="filterViewBy" class="qb-text-capitalize"
                >{{ filterViewBy }}s</span
              >
              <span *ngIf="filterViewBy == null">Select</span>
              <div
                class="qb-keyboard-down-icon-container"
                id="qb-keyboard-down-icon-container"
              >
                <mat-icon class="keyboard-down-icon"
                  >keyboard_arrow_down</mat-icon
                >
              </div>
            </div>
            <div class="qb-filter-drop-down">
              <div class="qb-multi-select-dropdown" id="qb-multi-select-dropdown">
                <div id="qb-segment-select" class="qb-segment-select">
                  <div #filterdropdown>
                    <mat-option
                      class="qb-fs-11"
                      (click)="updateCheckboxValues('day')"
                      color="primary"
                      data-widget-name="widgetNameForAnalytics"
                      data-button-name="Filter By: Days"
                      >Days
                    </mat-option>
                    <!-- <mat-option  style="font-size: 11px;"*ngIf="daySpan>=7" (click)="updateCheckboxValues('week')" color="primary">Weeks
                  </mat-option> -->
                    <mat-option
                      class="qb-fs-11"
                      (click)="updateCheckboxValues('month')"
                      color="primary"
                      data-button-name="Filter By: Months"
                      >Months
                    </mat-option>
                    <!-- <mat-option  style="font-size: 11px;"*ngIf="daySpan>=365" (click)="updateCheckboxValues('year')" color="primary">Years
                  </mat-option> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!-- <div *ngIf="isAnalyticsAvailable">
        <div *ngFor="let data of selectedNodeAnalytics">
          <div>
            <div>data['name']</div>
            <div>data['revenue']</div>
          </div>
        </div>
      </div> -->
</div>
<div style="display: flex; margin-left: 25px; margin-top: 30px;">
  <div style="flex-direction: column; width: 60%;">
    <div class="qb-campaign-heading">Journey Flow</div>
    <div style="display: flex; justify-content: space-between; padding-top: 20px; background: #FFFFFF;">
      <div style="margin-left: 30px;">
        <div class="magnify-text-container-kpi">
          <span class="material-symbols-outlined">
            frame_inspect
          </span>
          <span class="magnify-text">{{ zoomAmount }}%</span>
        </div>
      </div>
      <div style="display: flex;">
        <mat-icon
        class="undo-button"
        [ngClass]="{ disabled: zoomAmount === 30 }"
        (click)="magnify('SUB')"
        data-widget-name="CANVAS"
        data-button-name="Zoom out"
        >remove</mat-icon>
        <mat-icon
          style="margin-left: 20px;"
          class="redo-button"
          [ngClass]="{ disabled: zoomAmount === 150 }"
          (click)="magnify('ADD')"
          data-widget-name="CANVAS"
          data-button-name="Zoom in"
          >add</mat-icon>
          <mat-icon
          style="margin-left: 20px; margin-right: 20px;"
          (click)="openfullscreen()"
          class="redo-button"
          data-widget-name="CANVAS"
          data-button-name="Zoom in"
          >zoom_out_map</mat-icon>
          
      </div>
    </div>
    <div class="qb-container-campaign-prefill" id="qb-container-campaign-prefill">
      <div class="canvas" id = "canvas" #myCanvas>
      
      </div>
    </div>
  </div>
  <div style="flex-direction: column; width: 40%; margin-left: 15px;">
    <div style="display: flex;">
      <div class="qb-campaign-heading">Journey Analysis</div>
      <span class="qb-campaign-heading" *ngIf="selectedParameter !=null"> - </span>
      <div class="qb-selected-parameter" *ngIf="selectedParameter !=null">{{selectedParameter.displayname}}</div>
    </div>
    <div *ngIf="selectedParameter ==null">
      <div style="display: flex;">
        <div class="revenueDiv-kpi">
          <div class="rectangle-kpi r">
            <div class="activeCampaigns">Active Journeys</div>
            <div class="numberOfCampaigns">{{ activeCampaigns }}</div>
          </div>
          <div class="rectangle-kpi revenueRectangle-kpi r2">
            <div class="activeCampaigns">Total Revenue</div>
            <div class="numberOfCampaigns total">
              {{ totalRevenue | customCurrency: revCurrency: true }}
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <div class="qb-overall-campaign">
          Over all Journey
        </div>
      </div>
      <div style="height: 300px; background: #fff; border-radius: 4px;">
        <app-line-chart-homescreen [selectedLineGraphData]="selectedLineGraphData"
                    [selectedGraphButton]="selectedGraphButton">
        </app-line-chart-homescreen>
      </div>
      <div style="margin-top: 15px;">
        <div style="display: flex;">
          <div class="revenueDiv-kpi">
            <div class="rectangle-kpi r">
              <div class="activeCampaigns">Emails Sent</div>
              <div class="numberOfCampaigns">{{ emailSent }}</div>
            </div>
            <div class="rectangle-kpi revenueRectangle-kpi r2">
              <div class="activeCampaigns">Revenue Generated</div>
              <div class="numberOfCampaigns total">
                {{ totalRevenueEmail | customCurrency: revCurrency: true }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedParameter !=null">
      <div *ngIf="selectedParameter.data.nodeType === 'AUDIENCESPLIT'">
        <div style="display: flex;">
          <div class="revenueDiv-kpi">
            <div class="rectangle-kpi r">
              <div class="activeCampaigns">Revenue for {{selectedParameter.displayname}}</div>
              <div class="numberOfCampaigns">{{ revenueTotalbranch | customCurrency: revCurrency: true}}</div>
            </div>
            <div class="rectangle-kpi revenueRectangle-kpi r2">
              <div class="activeCampaigns">Total Revenue</div>
              <div class="numberOfCampaigns total">
                {{ totalRevenue | customCurrency: revCurrency: true }}
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;" *ngFor="let data of selectedParameter.data.nodeData.definition.groupName">
          <div class="rectangle-kpi r">
            <div class="activeCampaigns">Revenue for {{data}}</div>
            <div class="numberOfCampaigns" *ngIf="revenueBranched[data]">{{ revenueBranched[data] | customCurrency: revCurrency: true}}</div>
            <div class="numberOfCampaigns" *ngIf="!revenueBranched[data]">{{ 0 | customCurrency: revCurrency: true}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedParameter.data.nodeType === 'ABTESTING'">
        <div style="display: flex;">
          <div class="revenueDiv-kpi">
            <div class="rectangle-kpi r">
              <div class="activeCampaigns">Revenue for {{selectedParameter.displayname}}</div>
              <div class="numberOfCampaigns">{{ revenue | customCurrency: revCurrency: true}}</div>
            </div>
            <div class="rectangle-kpi revenueRectangle-kpi r2">
              <div class="activeCampaigns">Total Revenue</div>
              <div class="numberOfCampaigns total">
                {{ totalRevenue | customCurrency: revCurrency: true }}
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;" *ngFor="let data of getArray(selectedParameter.data.nodeData.definition.pathGroups)">
          <div class="rectangle-kpi r">
            <div class="activeCampaigns">Revenue for {{data}}</div>
            <div class="numberOfCampaigns">{{ revenue | customCurrency: revCurrency: true}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedParameter.data.nodeType !== 'ABTESTING' && selectedParameter.data.nodeType !== 'AUDIENCESPLIT'">
        <div style="display: flex;">
          <div class="revenueDiv-kpi">
            <div class="rectangle-kpi r">
              <div class="activeCampaigns">Revenue for {{selectedParameter.displayname}}</div>
              <div class="numberOfCampaigns" *ngIf="revenue">{{ revenue | customCurrency: revCurrency: true}}</div>
              <div class="numberOfCampaigns" *ngIf="!revenue">{{ 0 | customCurrency: revCurrency: true}}</div>
            </div>
            <div class="rectangle-kpi revenueRectangle-kpi r2">
              <div class="activeCampaigns">Total Revenue</div>
              <div class="numberOfCampaigns total">
                {{ totalRevenue | customCurrency: revCurrency: true }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="margin-top: 20px;">
        <div class="qb-overall-campaign">
          Over all Journey
        </div>
      </div> -->
      <!-- <div style="height: 300px; background: #fff; border-radius: 4px;">
        <app-line-chart-homescreen [selectedLineGraphData]="selectedLineGraphData"
                    [selectedGraphButton]="selectedGraphButton">
        </app-line-chart-homescreen>
      </div> -->
      <!-- <div style="margin-top: 15px;">
        <div style="display: flex;">
          <div class="revenueDiv-kpi">
            <div class="rectangle-kpi r">
              <div class="activeCampaigns">Emails Sent</div>
              <div class="numberOfCampaigns">{{ emailSent }}</div>
            </div>
            <div class="rectangle-kpi revenueRectangle-kpi r2">
              <div class="activeCampaigns">Revenue Generated</div>
              <div class="numberOfCampaigns total">
                {{ totalRevenueEmail | customCurrency: revCurrency: true }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

  </div>
</div>
