<div class="qb-main-div">
    <div class="qb-main-div-container">
      <div class="qb-main-sub-container">
        <div
          class="set-game-container qb-game-config-container"
              id="set-game-container"
        >
          <!-- Stepper UI -->
          <div>
            <div
              class="qb-gf-gamify-heading qb-game-config-text"
            >
            Create Journey
            </div>
            <!-- <div style="display: flex;justify-content: space-between;    align-items: flex-start;
             padding: 0 15px;margin-bottom: 40px;   ">
                      <div style="display: flex;" class="qb-gf-step-container" *ngFor="let step of gameStepsIconConfig | keyvalue ; let i=index; let l=last;">
                          <div class="qb-gf-step-block">
                              <div class="qb-gf-step-icon" [ngClass]="stepsCompleted<i+2?'gf-step-icon':'gf-step-icon-active'"
                                  [ngClass]="stepsCompleted<i+2?'gf-step-icon':'gf-step-icon-active'">
                                  <span *ngIf="stepsCompleted<i+2">{{step.key}}</span>
                                  <mat-icon *ngIf="stepsCompleted>=i+2" class="qb-gf-step-tick">check</mat-icon>
                              </div>
                              <div>{{step.value}}</div>
  
                          </div>
                          <div class="qb-gf-stepper-horizontal-line" *ngIf="!l"></div>
                      </div>
                  </div> -->
            <div
            class="qb-steps-block-container"
            >
              <div class="qb-gf-step-block" *ngIf="stepsCompleted != 1">
                <div
                (click)="StepsDone >= 1 ? changeSection(1) : ''"
                  class="qb-gf-step-icon"
                  [ngClass]="
                  (StepsDone < 1 || stepsCompleted ===1) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 1">1</span>
                  <mat-icon *ngIf="StepsDone >= 1" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Basics</div>
              </div>
              <div class="qb-gf-step-block" *ngIf="stepsCompleted == 1" >
                <div
                (click)="StepsDone >= 1 ? changeSection(1) : ''"
                  class="qb-gf-step-icon"
                  [ngClass]="
                  (StepsDone < 1 || stepsCompleted ===1) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 2">1</span>
                  <mat-icon *ngIf="StepsDone >= 2" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Basics</div>
              </div>
              <div class="qb-gf-stepper-horizontal-line"></div>
  
              <div class="qb-gf-step-block" *ngIf="stepsCompleted == 2">
                <div
                  (click)="StepsDone >= 2 ? changeSection(2) : ''"
                  class="qb-gf-step-icon"
                  [ngClass]="
                  (StepsDone < 2 || stepsCompleted ===2) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 3">2</span>
                  <mat-icon *ngIf="StepsDone >= 3" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Target Audience</div>
              </div>
              <div class="qb-gf-step-block" *ngIf="stepsCompleted != 2">
                <div
                  (click)="StepsDone >= 2 ? changeSection(2) : ''"
                  class="qb-gf-step-icon-not"
                  [ngClass]="
                  StepsDone < 2 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 3">2</span>
                  <mat-icon *ngIf="StepsDone >= 3" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Target Audience</div>
              </div>
              <div class="qb-gf-stepper-horizontal-line"></div>
              <div class="qb-gf-step-block" *ngIf="stepsCompleted ===3">
                <div
                (click)="StepsDone >= 3 ? changeSection(3) : ''"
                  class="qb-gf-step-icon"
                  [ngClass]="
                  (StepsDone < 3 || stepsCompleted ===3) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 4">3</span>
                  <mat-icon *ngIf="StepsDone >= 4" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Send Settings</div>
              </div>
              <div class="qb-gf-step-block" *ngIf="stepsCompleted !==3">
                <div
                (click)="StepsDone >= 3 ? changeSection(3) : ''"
                  class="qb-gf-step-icon-not"
                  [ngClass]="
                  StepsDone < 3 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 4">3</span>
                  <mat-icon *ngIf="StepsDone >= 4" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Send Settings</div>
              </div>
              <div class="qb-gf-stepper-horizontal-line"></div>
              <div class="qb-gf-step-block" *ngIf="stepsCompleted !=4">
                <div
                  (click)="StepsDone >= 4 ? changeSection(4) : ''"
                  class="qb-gf-step-icon-not"
                  [ngClass]="
                  StepsDone < 4 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 5">4</span>
                  <mat-icon *ngIf="StepsDone >= 5" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Build Journey</div>
              </div>
              <div class="qb-gf-step-block" *ngIf="stepsCompleted ==4">
                <div
                  (click)="StepsDone >= 4 ? changeSection(4) : ''"
                  class="qb-gf-step-icon"
                  [ngClass]="
                  (StepsDone < 4 || stepsCompleted ===4) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                  "
                >
                  <span *ngIf="StepsDone < 5">4</span>
                  <mat-icon *ngIf="StepsDone >= 5" class="qb-gf-step-tick"
                    >check</mat-icon
                  >
                </div>
                <div>Build Journey</div>
              </div>
             
            </div>
          </div>
  
          <!-- Game configuration Content -->
  
          <div class="qb-discount-range-container" *ngIf="!loading" id="panelList">
            <div *ngIf="stepsCompleted == 1" class="qb-d-flex qb-w-100">
              <div class="qb-w-100" id="panelList" >
                <div class="qb-d-flex">
                  <div class="qb-gf-pyg-title-label">Journey Details </div>
                </div>
                <div>
                    <div>
                        <div
                        class="qb-email-sub"
                        >
                        Journey Name
                        </div>
                        <div class="qb-email-subject-game">
                          <input
                            type="text"
                            class="qb-formInput fontExo2"
                            placeholder="eg. Upsell to existing customers "
                            [(ngModel)]="campaignName"
                          />
                        </div>
                        <div
                          class="qb-imp-color"
                          *ngIf="campaignName.length === 0 && isContinueBtnLoading"
                        >
                        Journey name is required
                        </div>
                      </div>

                      <div style="margin-top: 30px;">
                        <div class="primary-button" style="width: 108px;height: 30px; background: #2762AB; font-size: 12px;" (click)="addTag()">
                          <span class="material-icons-outlined button-icon" style="color: #fff;">
                            add
                          </span>
                          <span>Add Tag</span>
                        </div>
                      </div>
                </div>
  
                <hr />
                <div class="qb-d-flex">
                  <div class="qb-w-90">
                      <div class="qb-gf-pyg-title-label">
                        Assign Conversion Event
                      </div>
                      <div class="qb-specific-info">
                        Specify up to 4 conversion events to track this campaign. Conversion events can only be specified during campaign creation and cannot be changed once the campaign goes live.
                      </div>
                      <div [formGroup]="conversionEvent" *ngIf="isConversionEvnetClicked">
                        <div formArrayName="ConversionEventArray">
                            <div *ngFor="let group of conversionEvent.controls.ConversionEventArray.controls;
                            let i = index"  formGroupName="{{i}}">
                                <div class="qb-email-sub">
                                    {{conversionEventMap[i+1]}}
                                </div>
                                <div class="qb-mt-30"> 
                                  <div class="qb-heading-event">
                                      Choose Conversion Event Type
                                  </div>
                                  <div class="qb-formDivSchedule cursor-pointer" style="width: 50%;"
                (click)="selectopenclose('ConversionEvent')">
                <mat-select #ConversionEvent formControlName="ConversionEvent" (selectionChange)="changeConversionSelection()"  class="qb-fontExo2"
                                  color="primary" placeholder={{ConversionEvnetType.name}}
                                  disableOptionCentering panelclass="qb-myPanelClass">
                                  <div class="qb-matSelectDropdowns">
                                    <mat-option class="qb-fontExo2" *ngFor="
                                        let list of ConversionEventList
                                      " [value]="list">
                                      {{ list.name }}
                                    </mat-option>
                                  </div>
                                </mat-select>
                                  </div> 
                                </div>
                                <hr style="margin-top: 30px;" />
                                <div class="qb-mt-30"> 
                                  <div class="qb-heading-event">
                                      Record Conversions for Users Who
                                  </div>
                                  <div class="qb-formDivSchedule cursor-pointer" style="width: 50%;"
                (click)="selectopenclose('whichUser')">
                <mat-select #whichUser formControlName="whichUser"  (selectionChange)="changeConversionSelection()"  class="qb-fontExo2"
                                  color="primary" placeholder={{whichUserEvent.name}}
                                  disableOptionCentering panelclass="qb-myPanelClass">
                                  <div class="qb-matSelectDropdowns">
                                    <mat-option class="qb-fontExo2" *ngFor="
                                        let list of whichUserList
                                      " [value]="list">
                                      {{ list.name }}
                                    </mat-option>
                                  </div>
                                </mat-select>
                                  </div>
                                </div>
                                <div>
                                  <mat-checkbox style="margin-top: 10px;" (change)="filter($event)">
                                      Add property filters
                                  </mat-checkbox>
                                </div>
                                <div class="qb-email-sub">
                                  Select Conversion Deadline
                                </div>
                                <div class="qb-specific-info">
                                  This is the maximum time allowed for the User to complete the action that triggers a Conversion Event.
                                </div>
                                <div style="margin-top: 30px;" [formGroup]="deadLine">
                                  <div
                                  class="qb-days-text"
                                  >
                                    <div>Days</div>
                                    <div class="qb-ml-65">Hrs</div>
                                    <div class="qb-ml-65">Mins</div>
                                    <div class="qb-ml-65">Seconds</div>
                                  </div>
                                  <div class="qb-input-value-container">
                                    <div
                                      class="qb-gf-block-input-box qb-w-auto"
                                    >
                                      <input
                                        type="number"
                                        min="0"
                                        max="365"
                                        formControlName="deadLine['days']"
                                       
                                        class="qb-gf-block-input-field"
                                        required
                                      />
                                    </div>
                                    <div
                                      class="qb-gf-block-input-box qb-w-auto"
                                    >
                                      <input
                                        type="number"
                                        min="0"
                                        max="23"
                                        formControlName="hours"
                                        class="qb-gf-block-input-field"
                                        required
                                      />
                                    </div>
                                    <div
                                      class="qb-gf-block-input-box qb-w-auto"
                                    >
                                      <input
                                        type="number"
                                        min="0"
                                        max="59"
                                        formControlName="
                                        minutes
                                        "
                                        class="qb-gf-block-input-field"
                                        required
                                      />
                                    </div>
                                    <div
                                    class="qb-gf-block-input-box qb-w-auto"
                                  >
                                    <input
                                      type="number"
                                      min="0"
                                      max="60"
                                      formControlName="seconds"
                                      class="qb-gf-block-input-field"
                                      required
                                    />
                                  </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div style="margin-top: 30px;">
                        <div class="primary-button" style="width: 210px; background: #2762AB;" (click)="addConversionEvent()">
                          <span class="material-icons-outlined button-icon" style="color: #fff;">
                            add
                          </span>
                          <span>Add Conversion Event</span>
                        </div>
                      </div>

                    <!-- <div class="qb-search-container">
                      <div
                       class="qb-search-inner-container"
                      >
                        <div class="qb-formDiv23 qb-w-85">
                          <input
                            class="qb-formInput qb-fontExo2"
                            [(ngModel)]="SearchCategory"
                            (keyup)="openItemDialogwithSearchData(SearchCategory)"
                            placeholder="Search category (Minimum 3 characters)"
                            type="text"
                          />
                          <span
                            class="material-icons-outlined icons-primary qb-cursor-pointer"
                          >
                            search
                          </span>
                        </div>
                        <button
                          class="qb-gf-browse-btn qb-cursor-pointer qb-ml-10"
                          (click)="openItemDialogwithoutSearchData('')"
                        >
                          Browse
                        </button>
                      </div>
                    </div>
                    <div class="qb-d-flex">
                      <div class="qb-mb-20">
                        No. of products excluded :
                        {{
                          productAndDiscount.categoryProductList[0]["productIds"]
                        .length
                        }} out of {{totalProducts}}
                      </div>
                      <div class="qb-view-exclude-product-text" (click)="viewExcludedProduct()">
                        View Excluded Products
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
  
            <!-- <div *ngIf="stepsCompleted == 2" class="qb-w-70">
              <div class="qb-d-flex">
                <div class="qb-gf-pyg-title-label">Customer Segments</div>
                  <span
                  class="qb-i-div"
                  popoverclass="qb-my-custom-class"
                  placement="right"
                  [ngbPopover]="popCustomerSegment"
                  triggers="mouseenter:mouseleave"
                  >i
                  </span>
                  <ng-template #popCustomerSegment>
                  <div class="qb-ng-template-text">
                    Select the customer segment you want to target for this game
                  </div>
                  </ng-template>
              </div>
              <div class="qb-gf-pyg-normal-label qb-mb-24">
                <div
                  #userSegment
                  class="qb-user-segment-div"
                >
                  <div>
                    <div
                      class="qb-multi-select-panel-seg"
                      id="multi-select-panel-seg"
                      (click)="toggleSelectSegment()"
                    >
                      <span *ngIf="customerSelectedSegment[0] === 'ALL'"
                        >Segments Selected :
                        All Customers</span
                      >
                      <span *ngIf="customerSelectedSegment[0] !== 'ALL'"
                      >Segments Selected :
                      {{ customerSelectedSegment.length }}</span
                    >
                      <div id="keyboard-down-icon-container-seg">
                        <mat-icon class="qb-keyboard-down-icon-seg"
                          >keyboard_arrow_down</mat-icon
                        >
                      </div>
                    </div>
                    <div class="qb-p-relative">
                      <div
                        class="qb-multi-select-dropdown-seg"
                        id="multi-select-dropdown-seg"
                      >
                        <div class="qb-search">
                          <mat-icon class="qb-group-icon">search</mat-icon>
                          <input
                            [(ngModel)]="tempSegmentGameFilter"
                            placeholder="Search Segment"
                            type="text"
                            class="qb-search-segment"
                          />
                        </div>
                        <div class="qb-border-line"></div>
                        <div id="segment-select-seg" class="qb-segment-select">
                          <div
                            *ngFor="
                              let customer of allcustomer
                                | labelPipe: tempSegmentGameFilter:'name'
                            "
                          >
                            <mat-checkbox
                              [checked]="
                                customerSelectedSegment.length === 1 &&
                                customerSelectedSegment[0] === 'ALL'
                              "
                              (change)="addAllCustomers($event)"
                              color="primary"
                              >{{ customer.name }}</mat-checkbox
                            >
                            </div>
                            <div
                              *ngFor="
                                let segment of customerSegment
                                  | labelPipe: tempSegmentGameFilter:'name'
                              "
                            >
                              <mat-checkbox
                                [checked]="isChecked(segment)"
                                (change)="
                                  updateCheckboxValuesCustomerSegment(segment)
                                "
                                [disabled]="segment.isActive===false"
                                color="primary"
                                >{{ segment.name }}
                              </mat-checkbox>
  
                              <div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="qb-invalid-text-h qb-h-10">
                    </div>
                  </div>
                  <div
                  class="qb-error-text"
                  *ngIf="customerSelectedSegment.length === 0"
                >
                  Please select any one of the segment
                </div>
  
                  <div
                   class="qb-multiple-select-info"
                  >
                    *You can select multiple segments
                  </div>
                </div>
                
                <div>
                  <mat-checkbox
                    class="qb-d-block"
                    [(ngModel)]="isEnabledForAnonymousUser"
                    color="primary"
                  >
                    Allow game for Guest Users
                  </mat-checkbox>
                </div>
                <div class="qb-d-flex">
                  <span
                    >Click&nbsp;<span
                      (click)="routeToCreateSegmentScreen()"
                      class="qb-create-segment"
                      >here</span
                    >&nbsp;to create segment</span
                  >
                </div>
              </div>
            </div> -->
  
            <!-- <div *ngIf="stepsCompleted == 3" class="qb-w-100">
              <div class="qb-gf-gamestep-br" id="panelList" >
                <div
                 class="qb-game-step-container"
                >
                  <div></div>
                  <div>
                    <span class="qb-gf-gamify-heading qb-mr-10" *ngIf="!autopilotStatus"
                      >Autopilot Off</span>
                      <span class="qb-gf-gamify-heading qb-mr-10 qb-mr-10" *ngIf="autopilotStatus"
                      >Autopilot On</span>
                    <span>
                      <mat-slide-toggle
                        color="accent"
                        [checked]="autopilotStatus"
                        (change)="pilotMode($event)"
                        [(ngModel)]="autopilotStatus"
                        data-button-name="Autopilot Mode"
                        [attr.data-item-name]="!autopilotStatus"
                      ></mat-slide-toggle>
                    </span>
                  </div>
                </div>
                <div class="qb-d-flex">
                  <div class="qb-w-30">
                    <img
                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/automation.gif"
                      alt=""
                    />
                    <div class="qb-gf-gamify-heading qb-d-flex qb-autopilot-text">Autopilot</div>
                    <div class="qb-ai-main-container">
                      <div class="qb-ai-text">Let AI choose:</div>
                      <div class="qb-d-flex qb-ai-flex-start">
                        <mat-icon class="qb-check-circle"
                          >check_circle_outline
                        </mat-icon>
                        <div>
                          Steps optimal for your customer.
                        </div>
                      </div>
                      <div class="qb-d-flex qb-ai-flex-start">
                        <mat-icon class="qb-check-circle"
                          >check_circle_outline
                        </mat-icon>
                        <div>
                          Products per step optimal for your customer.
                        </div>
                      </div>
                      <div class="qb-d-flex qb-ai-flex-start">
                        <mat-icon class="qb-check-circle"
                          >check_circle_outline
                        </mat-icon>
                        <div class="qb-ml-5">
                          Cool off period, initial halt, time to 
                          redeem reward and retries allowed 
                          which will be optimal for your 
                          customer.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="qb-w-70">
  
                    <div class="qb-gf-info-tip">
                      <div class="qb-mr-5" >
                        <span class="qb-i-div-offer">i </span>
                      </div>
                      <div>
                        <ul>
                          <li>
                            Steps - How many steps you want the customer to
                            cross to redeem the reward.
                          </li>
                          <li>
                            Products per step - Products you want to show in
                            each step.
                          </li>
                        </ul>
                        <div class="qb-pl-25">
                          We take care of these things when
                          <strong>Autopilot</strong> is <strong>On</strong>
                        </div>
                      </div>
                    </div>
                    
  
                    <div class="qb-steps-container">
                      <div>
                        <div class="qb-gf-block-input-label"><span>Steps</span></div>
                        <div
                          class="qb-gf-block-input-box"
                          [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                        >
                          <input
                            type="number"
                            min="2"
                            max="5"
                            [(ngModel)]="gameSteps.steps"
                            class="qb-gf-block-input-field"
                            [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            required
                            [disabled]="autopilotStatus"
                          />
                        </div>
                        <div id="qbGmGameSteps"style="font-size: 10px; max-width: 130px; color: red"></div>
                      </div>
                      <div>
                        <div class="qb-gf-block-input-label">
                          <span>Products</span>
                          <span class="qb-s-italic">(per step)</span>
                        </div>
                        <div
                          class="qb-gf-block-input-box"
                          [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                        >
                          <input
                            type="number"
                            min="2"
                            max="5"
                            [(ngModel)]="gameSteps.productsPerStep"
                            class="qb-gf-block-input-field"
                            [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            required
                            [disabled]="autopilotStatus"
                          />
                        </div>
                        <div id="qbGmProductsPerStep"style="font-size: 10px; max-width: 130px; color: red"></div>
                      </div>
                      <div id="qbGmExcludedProducts"style="font-size: 10px; max-width: 300px; color: red"></div>
                    </div>
  
                    <div>
                      <div
                        #el
                        class="qb-gf-gamify-heading gf-accordion qb-advanced-setting-container"
                        (click)="advancedSettingsChangeSlide(); toggleHelper()"
                      >
                        <div>Advanced Settings</div>
                        <mat-icon 
                        class="qb-gf-chv-down qb-ml-5"
                        [class.gf-icon-clicked]="isAdvancedSettingslideOpen"
                        >arrow_drop_down
                      </mat-icon>
                      </div>
                      <div class="qb-gf-panel">
                        <div
                          class="qb-initial-halt-container"
                        >
                          <div>
                            <div class="qb-gf-block-input-label">
                              <span>Initial Halt</span>
                            </div>
                            <div
                              class="qb-gf-block-input-label qb-in-second-text"
                            >
                              In seconds
                            </div>
                            <div
                              class="qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="0"
                                max="59"
                                [(ngModel)]="gameSteps.initialHalt"
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                required
                                [disabled]="autopilotStatus"
                              />
                            </div>
                            <div id="qbGmIntialHalt"style="font-size: 10px; max-width: 130px; color: red"></div>
                          </div>
                        
  
                          <div>
                            <div class="qb-gf-block-input-label">
                              <span>Cool Off Period</span>
                            </div>
                            <div
                            class="qb-days-text"
                            >
                              <div>Days</div>
                              <div class="qb-ml-40">Hrs</div>
                              <div class="qb-ml-43">Mins</div>
                            </div>
                            <div class="qb-input-value-container">
                              <div
                                class="qb-gf-block-input-box qb-w-auto"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              >
                                <input
                                  type="number"
                                  min="0"
                                  max="365"
                                  [(ngModel)]="gameSteps.coolOffPeriod.days"
                                  class="qb-gf-block-input-field"
                                  [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                  [disabled]="autopilotStatus"
                                  required
                                />
                              </div>
                              <div
                                class="qb-gf-block-input-box qb-w-auto"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              >
                                <input
                                  type="number"
                                  min="0"
                                  max="23"
                                  [(ngModel)]="gameSteps.coolOffPeriod.hours"
                                  class="qb-gf-block-input-field"
                                  [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                  [disabled]="autopilotStatus"
                                  required
                                />
                              </div>
                              <div
                                class="qb-gf-block-input-box qb-w-auto"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              >
                                <input
                                  type="number"
                                  min="0"
                                  max="59"
                                  [(ngModel)]="
                                    gameSteps.coolOffPeriod.minutes
                                  "
                                  class="qb-gf-block-input-field"
                                  [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                  [disabled]="autopilotStatus"
                                  required
                                />
                              </div>
                            </div>
                            <div id="qbGmCoolOffPeriod"style="font-size: 10px; max-width: 130px; color: red"></div>
                          </div>
  
  
                          <div>
                            <div class="qb-gf-block-input-label">
                              <span>Time to redeem reward</span>
                            </div>
                            <div
                              class="qb-days-text"
                            >
                              <div>Days</div>
                              <div class="qb-ml-40">Hrs</div>
                              <div class="qb-ml-43">Mins</div>
                            </div>
                            <div class="qb-input-value-container">
                              <div
                                class="qb-gf-block-input-box qb-w-auto"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              >
                                <input
                                  type="number"
                                  min="1"
                                  max="59"
                                  [(ngModel)]="gameSteps.timeToRedeemReward.days"
                                  class="qb-gf-block-input-field"
                                  [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                  [disabled]="autopilotStatus"
                                  required
                                />
                              </div>
                              <div
                                class="qb-gf-block-input-box qb-w-auto"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              >
                                <input
                                  type="number"
                                  min="1"
                                  max="23"
                                  [(ngModel)]="gameSteps.timeToRedeemReward.hours"
                                  class="qb-gf-block-input-field"
                                  [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                  [disabled]="autopilotStatus"
                                  required
                                />
                              </div>
                              <div
                                class="qb-gf-block-input-box qb-w-auto"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              >
                                <input
                                  type="number"
                                  min="1"
                                  max="59"
                                  [(ngModel)]="
                                    gameSteps.timeToRedeemReward.minutes
                                  "
                                  class="qb-gf-block-input-field"
                                  [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                  [disabled]="autopilotStatus"
                                  required
                                />
                              </div>
                            </div>
                            <div id="qbGmRedeemPeriod"style="font-size: 10px; max-width: 130px; color: red"></div>
                          </div>
                          <div>
                            <div class="qb-gf-block-input-label">
                              <span>Retries</span>
                            </div>
                            <div
                              class="qb-gf-block-input-label qb-retries-input"
                            >
                              Number
                            </div>
                            <div
                              class="qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="1"
                                max="59"
                                [(ngModel)]="gameSteps.retries"
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                            <div id="qbGmRetries"style="font-size: 10px; max-width: 130px; color: red"></div>
                          </div>
                        </div>
  
                        <div class="qb-gf-info-tip">
                          <div class="qb-mr-5">
                            <span class="qb-i-div-offer">i </span>
                          </div>
                          <div>
                            <ul>
                              <li>
                                Cool off period - After how many days customer can
                                play the game again.
                              </li>
                              <li>
                                Initial halt - How much time it takes for the game
                                icon to appear on your website after loading.
                              </li>
                              <li>The time to redeem - After the defined time period the customer will not be able use the coupon code.</li>
                              <li>Retries - How many times the customer can play, if due to some reason he is not able to complete the game.</li>
                            </ul>
                            <div class="qb-pl-25">
                              We take care of these things when
                              <strong>Autopilot</strong> is <strong>On</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
  
            <!-- <div *ngIf="stepsCompleted == 4" class="qb-w-100">
              <div  class="qb-d-flex qb-w-100" id="panelList" >
                <div class="qb-w-55">
                 
                  <div class="qb-d-flex">
                    <div class="qb-gf-pyg-title-label">Icon Position</div>                 
                      <span
                      class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="right"
                      [ngbPopover]="popLayoutandStyle"
                      triggers="mouseenter:mouseleave"
                      >i
                      </span>
                      <ng-template #popLayoutandStyle>
                      <div class="qb-ng-template-text">
                        Set the position of the game icon on your website.
                      </div>
                      </ng-template>
                  </div>
                  <div class="qb-gf-pyg-normal-label qb-mb-24">
                    Drag the icon in the preview to set the position where you
                    want to show the game or you can select from some default
                    position available.
                  </div>
  
                  <div>
                    <div class="qb-box-position-container">
                      <div
                        class="qb-box-container qb-ai-flex-start qb-jc-flex-start"
                        [ngClass]="{ active: boxPosition === 'TOP_LEFT' }"
                        (click)="setBoxPosition('TOP_LEFT')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'TOP_LEFT' }"
                        ></div>
                      </div>
                      <div
                        class="qb-box-container qb-ai-flex-start qb-jc-flex-end"
                        [ngClass]="{ active: boxPosition === 'TOP_RIGHT' }"
                        (click)="setBoxPosition('TOP_RIGHT')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'TOP_RIGHT' }"
                        ></div>
                      </div>
                      <div
                        class="qb-box-container qb-ai-center qb-jc-flex-start"
                        [ngClass]="{ active: boxPosition === 'MIDDLE_LEFT' }"
                        (click)="setBoxPosition('MIDDLE_LEFT')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'MIDDLE_LEFT' }"
                        ></div>
                      </div>
                      <div
                        class="qb-box-container qb-ai-center qb-jc-center"
                        [ngClass]="{ active: boxPosition === 'CENTER' }"
                        (click)="setBoxPosition('CENTER')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'CENTER' }"
                        ></div>
                      </div>
                      <div
                        class="qb-box-container qb-ai-center qb-jc-flex-end"
                        [ngClass]="{ active: boxPosition === 'MIDDLE_RIGHT' }"
                        (click)="setBoxPosition('MIDDLE_RIGHT')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'MIDDLE_RIGHT' }"
                        ></div>
                      </div>
                      <div
                        class="qb-box-container qb-ai-flex-end qb-jc-flex-start"
                        [ngClass]="{ active: boxPosition === 'BOTTOM_LEFT' }"
                        (click)="setBoxPosition('BOTTOM_LEFT')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'BOTTOM_LEFT' }"
                        ></div>
                      </div>
                      <div
                        class="qb-box-container qb-ai-flex-end qb-jc-flex-end"
                        [ngClass]="{ active: boxPosition === 'BOTTOM_RIGHT' }"
                        (click)="setBoxPosition('BOTTOM_RIGHT')"
                      >
                        <div
                          class="qb-box"
                          [ngClass]="{ active: boxPosition === 'BOTTOM_RIGHT' }"
                        ></div>
                      </div>
                    </div>
                  </div>
  
                  <div class="qb-m-24">
                    <div>Show on</div>
  
                    <div class="qb-multi-select-panel-seg2 qb-w-50" >
                      <mat-select
                        class="qb-w-95 qb-select-page"
                        [(ngModel)]="showOnSelectedPages"
                        multiple
                        (selectionChange)="changeShowOn(i)"
                      >
                        <mat-option
                          (change)="addAllShowOnPage($event)"
                          value="ALL"
                          >All Pages</mat-option
                        >
                        <mat-option
                          *ngFor="let showOn of shopifyStorePages | keyvalue"
                          (change)="updateShowOnPage(showOn)"
                          [disabled]="AllPageSelected"
                          [value]="showOn['value']"
                          >{{ showOn.key }}
                        </mat-option>
                      </mat-select>
                    </div>
                    <div
                    class="qb-error-text"
                      *ngIf="showOnSelectedPages.length == 0 && isContinueBtnLoading"
                    >
                      Please select any one of the above
                    </div>
                  </div>
  
                  <div class="qb-m-24">
                    <div class="qb-gf-pyg-title-label">Select Icon Image</div>
  
                    <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="qb-example-radio-group qb-select-icon-radio"
                      [(ngModel)]="selectedwidget"
                    >
                      <div *ngFor="let widget of widgetIconImageList | keyvalue">
                        <div
                          class="qb-widget-select-container"
                        >
                          <mat-radio-button
                            class="qb-example-radio-button qb-radio-button-container"
                            [value]="widget.value"
                            color="primary"
                          >
                            <img
                              [src]="widget.value"
                              alt=""
                              width="100px"
                              height="100px"
                              class="qb-img-padding-icon"
                            />
                          </mat-radio-button>
                        </div>
                      </div>
                    </mat-radio-group>
                  </div>
  
                  <div class="qb-m-24">
                    <div class="qb-gf-pyg-title-label">Background Color</div>
                    <div
                      class="qb-multi-select-panel-seg qb-color-picker-container"
                      [(colorPicker)]="widgetBackground"
                      (colorPickerChange)="styleChangeLive($event)"
                    >
                      <input
                        class="qb-color-picket-input qb-color-picker-sub-container"
                        [style.background]="widgetBackground"
                        [disabled]="true"
                      />
                      <span class="qb-widget-background">{{ widgetBackground }}</span>
                    </div>
                  </div>
                </div>
  
                <div class="qb-w-45">
                 
  
                  <div class="qb-w-100 qb-p-fixed">
                    <span> Preview</span>
  
                    <div
                      #gfDragBoundary
                      class="qb-gf-preview-boundary qb-p-relative"
                      id="gf-preview-boundary"
                      (drop)="drop($event)"
                      (dragover)="allowDrop($event)"
                      [ngStyle]="{
                        'background-image': 'url(' + storeImageUrl + ')'
                      }"
                    >
                      <div
                        class="qb-chat-btnPyg"
                        id="chat-btn-pos"
                        [style.background]="widgetBackground"
                        draggable="true"
                        (dragstart)="drag($event)"
                      >
                        <img [src]="selectedwidget" class="qb-w-20-px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
  
            <!-- <div *ngIf="stepsCompleted == 5"
              class="qb-d-flex qb-w-100"
            >
              <div id="panelList" class="qb-w-42">
                <div class="qb-m-24">
                  <div class="qb-gf-pyg-title-label">Schedule the game</div>
                  <div class="qb-gf-pyg-normal-label qb-mb-24">
                    Choose when you want to show the game to your selected
                    customer segment.
                  </div>
                </div>
  
                <div
                class="qb-date-range"
                >
                  <div class="qb-radio-toolbar qb-w-100">
                    <div class="qb-trigger-radio_group">
                      <mat-radio-group
                        class="qb-d-flex"
                        [(ngModel)]="schedleConfigRadio"
                        (change)="radioChange($event)"
                      >
                        <mat-radio-button
                          color="primary"
                          [disableRipple]="true"
                          class="qb-fs08-rem qb-mr-20"
                          [checked]="schedleConfigRadio == 'DATE_RANGE'"
                          value="DATE_RANGE"
                          >Date Range
                        </mat-radio-button>
                        <mat-radio-button
                          color="primary"
                          [disableRipple]="true"
                          class="qb-fs08-rem"
                          [checked]="schedleConfigRadio == 'DATE_ONLY'"
                          value="DATE_ONLY"
                          >Specific Date
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
  
                    <div>
                      <div class="qb-form-group">
                        <div
                          class="qb-w-100 qb-d-flex"
                          *ngIf="schedleConfigRadio == 'DATE_RANGE'"
                        >
                          <div class="qb-d-flex qb-w-100">
                            <div class="qb-mr-10">
                              <div class="qb-mb-5">
                                <span class="qb-configLabelSecond">Start Date</span
                                ><span class="qb-imp-color">*</span>
                              </div>
                              <div class="qb-formDivP">
                                <input
                                  [(ngModel)]="scheduleDataObj['startDate']"
                                  class="qb-formInput fontFam input-box-alignment"
                                  [min]="startDateMinValue"
                                  (dateChange)="startDateChange('start')"
                                  matInput
                                  [matDatepicker]="picker1"
                                  placeholder="Choose a date"
                                  readonly
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </div>
                            </div>
                            <div>
                              <div class="qb-mb-5">
                                <span class="qb-configLabelSecond"> End Date</span
                                ><span class="qb-imp-color" class="qb-imp-color">*</span>
                              </div>
                              <div class="qb-formDivP">
                                <input
                                  [(ngModel)]="scheduleDataObj['endDate']"
                                  class="qb-formInput fontFam input-box-alignment"
                                  [min]="tomorrowDate"
                                  (dateChange)="endDateChange('end')"
                                  matInput
                                  [matDatepicker]="picker2"
                                  placeholder="Choose a date"
                                  readonly
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                              </div>
                              <div class="qb-invalid-text-h">
                                <div>{{ dateErrorText }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div
                          class="qb-d-flex qb-w-50"
                          *ngIf="schedleConfigRadio == 'DATE_ONLY'"
                        >
                          <div class="qb-d-flex qb-w-100">
                            <div class="qb-mr-10">
                              <div class="qb-mb-5">
                                <span class="qb-configLabelSecond">On</span
                                ><span class="qb-imp-color">*</span>
                              </div>
                              <div class="qb-formDivP">
                                <input
                                  [(ngModel)]="scheduleDataObj['startDate']"
                                  class="qb-formInput fontFam input-box-alignment"
                                  [min]="startDateMinValue"
                                  (dateChange)="startDateChange('start')"
                                  matInput
                                  [matDatepicker]="picker1"
                                  placeholder="Choose a date"
                                  readonly
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div
                     class="qb-at-any-time-container"
                    >
                      <div class="qb-configLabelSecond">
                        <span>At any time</span>
                        <span
                          class="qb-i-div"
                          popoverclass="qb-my-custom-class"
                          placement="right"
                          [ngbPopover]="popContentAtAnyTime"
                          triggers="mouseenter:mouseleave"
                          *ngIf="!atAnyTime"
                          >i
                        </span>
                        <ng-template #popContentAtAnyTime>
                          <div class="qb-ng-template-text">
                            The game will be repeated in this selected time
                            interval for all days in the date range.
                          </div>
                        </ng-template>
                      </div>
  
                      <div>
                        <span>
                          <mat-slide-toggle
                            color="accent"
                            [checked]="atAnyTime"
                            [(ngModel)]="atAnyTime"
                            (change)="atAnyTimeChange($event)"
                          ></mat-slide-toggle>
                        </span>
                      </div>
                    </div>
  
                    <div *ngIf="!atAnyTime">
                      <div class="qb-form-group">

                        <div
                            class="qb-w-100 qb-d-flex qb-fd-coloumn"
                        >
                          <div class="qb-d-flex qb-w-100">
                            <div class="qb-w-49 qb-mr-10">
                              <div class="qb-mb-5">
                                <span class="qb-configLabelSecond">Start Time</span
                                ><span class="qb-imp-color">*</span>
                              </div>
                              <div
                                class="qb-d-flex qb-jc-flex-start"
                              >
                                <div class="qb-w-100">
                                  <div class="qb-d-flex">
                                    <div
                                      class="qb-formDiv2 qb-date-picker-div"
                                      [ngClass]="{
                                        active:
                                          scheduleDataObj['startTime'] == null ||
                                          scheduleDataObj['startTime'].length == 0
                                      }"
                                    >
                                      <input
                                        [(ngModel)]="scheduleDataObj['startTime']"
                                        [format]="24"
                                        [min]="
                                          selectedStartDateString == MinDateString
                                            ? currentTimeMin
                                            : '00:00'
                                        "
                                        class="qb-formInput qb-h-90 qb-cursor-pointer"
                                        [ngxTimepicker]="pickerFrom"
                                        (ngModelChange)="checkTime()"
                                        readonly
                                      />
                                      <ngx-material-timepicker #pickerFrom>
                                      </ngx-material-timepicker>
                                      <img
                                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                                        alt=""
                                        (click)="clickTime('START')"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div class="qb-w-49 qb-mr-10">
                              <div class="qb-mb-5">
                                <span class="qb-configLabelSecond">End Time</span
                                ><span class="qb-imp-color">*</span>
                              </div>
                              <div
                                class="qb-d-flex qb-jc-flex-start"
                              >
                                <div class="qb-w-100">
                                  <div class="qb-d-flex">
                                    <div
                                      class="qb-formDiv2 qb-date-picker-div"
                                      [ngClass]="{
                                        active:
                                          scheduleDataObj['endTime'] == null ||
                                          scheduleDataObj['endTime'].length == 0
                                      }"
                                    >
                                      <input
                                        [(ngModel)]="scheduleDataObj['endTime']"
                                        [format]="24"
                                        class="qb-formInput qb-h-90 qb-cursor-pointer"
                                        [ngxTimepicker]="pickerTo"
                                        (ngModelChange)="checkTime()"
                                        readonly
                                      />
                                      <ngx-material-timepicker #pickerTo>
                                      </ngx-material-timepicker>
                                      <img
                                        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                                        alt=""
                                        (click)="clickTime('END')"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="qb-invalid-text-h">
                            <div
                              *ngIf="
                                timeErrorText.length == 0 &&
                                (scheduleDataObj['startTime'] == null ||
                                  scheduleDataObj['startTime'].length == 0 ||
                                  scheduleDataObj['endTime'] == null ||
                                    scheduleDataObj['endTime'].length == 0)
                              "
                              class="qb-error-text-date"
                            >
                              Please select the time for the game
                            </div>
                            <div
                              *ngIf="
                                !(
                                  (scheduleDataObj['startTime'] == null ||
                                    scheduleDataObj['startTime'].length == 0) &&
                                  (scheduleDataObj['endTime'] == null ||
                                    scheduleDataObj['endTime'].length == 0)
                                )
                              "
                            >
                              {{ timeErrorText }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div
                      class="qb-every-day-of-week-container"
                      *ngIf="schedleConfigRadio != 'DATE_ONLY'"
                    >
                      <div class="qb-configLabelSecond">
                        <span>Every day of the week</span
                        ><span
                          class="qb-i-div"
                          popoverclass="qb-my-custom-class"
                          placement="right"
                          [ngbPopover]="popContentEveryDayOfTheWeek"
                          triggers="mouseenter:mouseleave"
                          *ngIf="!EveryDayofTheWeek"
                          >i
                        </span>
                        <ng-template #popContentEveryDayOfTheWeek>
                          <div class="qb-ng-template-text">
                            The game will be repeated for selected days in the
                            date range.
                          </div>
                        </ng-template>
                      </div>
  
                      <div>
                        <span>
                          <mat-slide-toggle
                            color="accent"
                            [checked]="EveryDayofTheWeek"
                            [(ngModel)]="EveryDayofTheWeek"
                            (change)="toggleSelectionAllDays($event)"
                          >
                          </mat-slide-toggle>
                        </span>
                      </div>
                    </div>
  
                    <div
                      *ngIf="
                        !EveryDayofTheWeek && schedleConfigRadio != 'DATE_ONLY'
                      "
                    >
                      <mat-chip-list
                        *ngIf="!EveryDayofTheWeek"
                        placeholder="Select a day"
                        class="qb-font-class fs08-rem"
                        multiple="true"
                      >
                        <mat-chip
                          color="accent"
                          #c="matChip"
                          *ngFor="let day of weekDaysChips"
                          class="qb-font-class fs08-rem qb-cursor-pointer weekdays-chip qb-mim-h-26-px"
                          [value]="day.full"
                          (click)="toggleSelection(day)"
                          [selected]="day.selected"
                        >
                          <span>{{ day.full[0] }}</span
                          ><span class="qb-text-lower-case"
                            >{{ day.full[1] }}{{ day.full[2] }}</span
                          >
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                  <div>
                    <div
                    class="qb-email-sub"
                    >
                      Email Subject
                    </div>
                    <div class="qb-email-subject-game">
                      <input
                        type="text"
                        class="qb-formInput fontExo2"
                        placeholder="Enter your email subject here"
                        [(ngModel)]="emailSubject"
                      />
                    </div>
                    <div
                      class="qb-imp-color"
                      *ngIf="emailSubject.length === 0 && isContinueBtnLoading"
                    >
                      Please fill the Email Subject
                    </div>
                    <div
                      class="qb-d-flex qb-mt-5"
                      (click)="showPreview()"
                    >
                      <mat-icon class="qb-template-attach-icon-game"
                        >attach_file</mat-icon
                      >
                      <div class="qb-email-name-game">Negotiator Reward Email</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="qb-email-preview-container">
                <div
                class="qb-email-preview-sub-div"
                >
                <div
               class="qb-email-preview-text"
                >
                  Email Preview
                </div>
                <div class="qb-d-flex">
                    <div
                      class="qb-cursor-pointer qb-mr-20"
                      (click)="showPreview(true)"
                    >
                      <mat-icon class="icons-primary" matTooltip="Edit Template"
                        >edit</mat-icon
                      >
                    </div>
                  </div>
                </div>
                <div *ngIf="!snapshotUrlCheck" >
                  <div class="qb-email-preview-border">
                    <img
                      src="{{ snapshotUrl }}"
                      width="100%"
                      height="100%"
                      (error)="updateUrl($event)"
                    />
                  </div>
  
                </div>
                <div
                  *ngIf="snapshotUrlCheck"
                  class="qb-spinner"
                >
                  <mat-spinner diameter="25"></mat-spinner>
                </div>
  
              </div>
            </div> -->
          </div>
  
          <div>
            <div class="qb-d-flex qb-pt-15">
              <div
                class="qb-gf-btn qb-cursor-pointer qb-previous-button-color"
                *ngIf="stepsCompleted > 1"
                (click)="previousButtonClicked()"
              >
                <span>Previous</span>
              </div>
    
              <div
                class="qb-gf-btn qb-gf-discard-btn qb-cursor-pointer"
                *ngIf="!isDiscardBtnLoading"
                (click)="discardGameSetup(); isDiscardBtnLoading = true"
              >
                <span>Delete</span>
              </div>
              <div
                class="qb-gf-btn qb-gf-discard-btn qb-previous-button-color"
                *ngIf="isDiscardBtnLoading"
              >
                <mat-spinner diameter="12"></mat-spinner>
              </div>
              <div
                class="qb-gf-btn qb-gf-save-btn qb-cursor-pointer"
                *ngIf="!isSaveBtnLoading"
                (click)="continueButtonClicked(0); isSaveBtnLoading = true"
              >
                <span>Save and Exit</span>
              </div>
              <div class="qb-gf-btn qb-gf-save-btn" *ngIf="isSaveBtnLoading">
                <mat-spinner diameter="12"></mat-spinner>
              </div>
              <div
                class="qb-gf-btn qb-gf-continue-btn qb-cursor-pointer"
                *ngIf="!isContinueBtnLoading || selected == false"
                (click)="continueButtonClicked(1); isContinueBtnLoading = true"
              >
                <span>{{ stepsCompleted != 5 ? "Continue" : "Enable" }}</span>
              </div>
              <div
                class="qb-gf-btn qb-gf-continue-btn"
                *ngIf="isContinueBtnLoading && selected"
              >
                <mat-spinner diameter="12"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  