import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SuccessDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import {countries} from 'src/app/shared/json-asset/countries';

@Component({
  selector: 'app-add-new-customer',
  templateUrl: './add-new-customer.component.html',
  styleUrls: ['./add-new-customer.component.css']
})
export class AddNewCustomerComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private apicall: ApiCallService,
    private router: Router,
    private snackbar : SnackbarCollection,
    private tokenStorage : TokenStorageService,
    private datePipe: DatePipe,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) { }
  step = 1;
  phoneNumber = "";
  submit = false;
  submitOTP = false;
  name = "";
  email = "";
  otp = "";
  namePattern = /^(([a-zA-Z])+([ a-zA-Z]*)){1,50}$/;
  errorTextNumber = true;
  errorTextEmail = true;
  errorTextName = true; 
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
  otpError = "";
  storeId :any = null;
  startDateMinValue;
  dateOfBirth;
  gender = "";
  countryCode = "91";
  countryCodeData = [];
  resendTime = 30;
  phoneNumberErrorText = "";
  emailErrorText = "";
  nameErrorText = "";
  loading = false;
  isMobileOtp = false;
  // emailPattern = new RegExp(
  //   /^(([a-zA-Z0-9]+([\.\+][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|((([a-zA-Z0-9])+(\-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}))$/
  // );
  genderList = ['Male','Female','Others'];
  phoneNumberLength = 10;
  minPhoneNumberLength = 10;
  phoneNumberRegex = new RegExp(`^\\d{${this.phoneNumberLength}}$`);
  phoneNumberPlaceHolder = "";

  ngOnInit(): void {
    let currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    this.getCountryCode()

  }

  
  // generateOtp(isMobileOtp?){
  //   this.isMobileOtp = isMobileOtp;
  //   this.resendTime = 30;
  //   let dob = this.datePipe.transform(
  //     this.dateOfBirth,
  //     "yyyy-MM-dd"
  //   );
  //   this.submit = true;
  //         let data = {
  //           "name" : this.name,
  //           "phone_number" : this.errorTextNumber?null: this.countryCode + this.phoneNumber,
  //           "email" : this.errorTextEmail?null:this.email,
  //           "birthday": this.dateOfBirth ? dob : null,
  //           "gender" : this.gender.length > 0 ? this.gender : null,
  //           "isMobileOtp": this.isMobileOtp,
  //         }
  //         this.loading = true;
  //         this.apicall.generateOTP(data).subscribe((response)=>{
  //           // this.errorTextEmail = false;
  //           // this.errorTextNumber = false;
  //           this.step = 2;
  //           this.timerResend();
  //           this.submit = false;
  //           this.loading = false;
  //         },(err)=>{
  //           console.log(err)
  //           this.loading = false;
            
  //         });
      // else{
      //   let data = {
      //     "name" : this.name,
      //     "phone_number" : this.countryCode + this.phoneNumber,
      //     "email" : null,
      //     "birthday": this.dateOfBirth ? dob : null,
      //     "gender" : this.gender.length > 0 ? this.gender : null
      //   }
      //   this.loading = true;
      //   this.apicall.generateOTP(data).subscribe((response)=>{
      //     this.errorTextEmail = false;
      //     this.errorTextNumber = false;
      //     this.timerResend();
      //     this.step = 2;
      //     this.submit = false;
      //     this.loading = false;
      //   },(err)=>{
      //     console.log(err);
      //     this.loading = false;
      //     if(err['error']['body']  === 'OTP is wrong')
      //       this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please wait 30 seconds before trying to generate OTP again.", ['POS_PAGE']), 5000);
      //     else{
      //       for(let j in err['error']['body']){
      //         console.log(j);
      //         this.phoneNumberErrorText = j;
      //     }
      //     }
  
      //   });
      // }
  // }
  // checkOtp(){
  //   let dob = this.datePipe.transform(
  //     this.dateOfBirth,
  //     "yyyy-MM-dd"
  //   );
  //   this.submitOTP = true;
  //   let data = {
  //     "name" : this.name,
  //     "phone_number" :this.errorTextNumber?null: this.countryCode + this.phoneNumber,
  //     "email" : this.errorTextEmail?null:this.email,
  //     "birthday": this.dateOfBirth ? dob : null,
  //     "gender" : this.gender.length > 0 ? this.gender : null,
  //     "isMobileOtp": this.isMobileOtp,
  //   }
  //   if(this.otp.length === 4){
  //     this.loading = true;
  //     this.apicall.verifyOTP(this.otp, data).subscribe((resposne)=>{
  //       if(resposne['body'] ==='SUCCESS'){
  //         this.apicall.signUpCustomer(data).subscribe((resposne)=>{
  //           this.createCustomer();
  //           this.loading = false;
  //         },(err)=>{
  //           this.loading = false;
  //           this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']), 6000);
  //         })
  //        }
  //     },(err)=>{
  //       // this.snackbar.openSnackBar("Something Went Wrong", 5000);
  //       this.loading = false;
  //       this.otpError = this._i18nDynamicTranslate.transform("OTP doesn't match please try again", ['POS_PAGE']);
  //     });
  //   }
   
  // }

  customerSignup(){
    let dob = this.datePipe.transform(
      this.dateOfBirth,
      "yyyy-MM-dd"
    );
    let data = {
      "name" : this.name,
      "phone_number" :this.errorTextNumber?null: this.countryCode + this.phoneNumber,
      "email" : this.errorTextEmail?null:this.email,
      "birthday": this.dateOfBirth ? dob : null,
      "gender" : this.gender.length > 0 ? this.gender : null,
      "isMobileOtp": this.isMobileOtp,
    }
      this.loading = true;
          this.apicall.signUpCustomer(data).subscribe((resposne)=>{
            this.createCustomer();
            this.loading = false;
          },(err)=>{
            this.loading = false;
            if (err['error'] && err['error']['body']['Customer already exists']) {
              this.snackbar.openSnackBar('Customer already exists', 5000);
              return;
            }
           else {
              if(err['error']['body']){
                this.snackbar.openSnackBar(err['error']['body'], 5000);
              }
              return;
            }
          })
         }

  createCustomer(){
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: this._i18nDynamicTranslate.transform("Customer added sucessfully.", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Back to home page", ['POS_PAGE'])
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: 'Objectives'},
        });
      }
      else{
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: 'Objectives'},
        });
      }
    });
  }
  toPreviousPage(){
    if(this.step === 1){
      this.router.navigate(['/app/user-profile/store-info/store-details'], {
        queryParams: { id: this.tokenStorage.getStoreId() },
      })
    }
    else{
      this.otp = "";
      this.submitOTP = false;
      this.otpError = "";
      this.step = this.step - 1;
    }
  }

  numberInputHandler(event: KeyboardEvent,maxLength) {
    const invalidCharacters = ['e', '-', '+','.'];
    const inputValue = (event.target as HTMLInputElement).value;
    const hasDecimalPoint = inputValue.includes('.');
    if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key) ||
    (event.key === '.' && hasDecimalPoint)) {
      event.preventDefault();
    }
  }

  getErrorTextNumber(value){
    let mobilenumberPattern = this.phoneNumberRegex;
    this.phoneNumberErrorText = "";
    if(!this.phoneNumber || !(mobilenumberPattern.test(this.phoneNumber))){
      this.errorTextNumber = true;
    }
    else{
      this.errorTextNumber = false;
    }
  }
  getErrorTextEmail(value){
    this.emailErrorText = "";
    if(!this.email.trim().match(this.emailPattern)){
      this.errorTextEmail = true;
    }
    else{
      this.errorTextEmail = false;
    }
  }
  getErrorTextName(value){
    this.nameErrorText = "";
    if(!this.name.match(this.namePattern)){
      this.errorTextName = true;
    }
    else{
      this.errorTextName = false;
    }
  }
  changeGender(value){
    this.gender = value;
  }
  // getStoreId(){
  //   this.apicall.getStoreData().subscribe((response)=>{
  //     if(response['body'] != null)
  //       this.storeId = response['body'][0]['storeId']; //qid is the storeId which is required by the backend to send the otp.
  //   },(error)=>{
      
  //   });
  // }
  getCountryCode(){
    // this.apicall.getCountryCode().subscribe((response)=>{
    //   this.countryCodeData = response['body'];
    // })
    this.changeCountry(countries.find((country)=>country.alpha2Code == (localStorage.getItem('storeServiceCountry') === 'null' ? localStorage.getItem('serviceCountry') : localStorage.getItem('storeServiceCountry'))));
  }

  changeCountry(country) {
    this.countryCode = country.callingCodes[0];
    this.phoneNumberLength = country.phoneLength;
    if (Array.isArray(this.phoneNumberLength)) {
      // phoneLength is an array, create a regex for the range
      const minLength = Math.min(...this.phoneNumberLength);
      const maxLength = Math.max(...this.phoneNumberLength);
      this.phoneNumberRegex = new RegExp(`^\\d{${minLength},${maxLength}}$`);
      this.minPhoneNumberLength = minLength;
      this.phoneNumberLength = maxLength
      this.phoneNumberPlaceHolder = `${this.minPhoneNumberLength} - ${this.phoneNumberLength} digit Phone number`
    } else {
      // phoneLength is a single value, create a regex for the exact length
      this.minPhoneNumberLength = null;
      this.phoneNumberRegex = new RegExp(`^\\d{${this.phoneNumberLength}}$`);
      this.phoneNumberPlaceHolder = `${this.phoneNumberLength} digit Phone number`

    }
    this.getErrorTextNumber(this.phoneNumber);
  }
  timerResend(){
    console.log(this.resendTime)
    let timer = setInterval(()=>{
      // this.redirect(status)
      if(this.resendTime === 0){
        clearInterval(timer);
      }
      this.resendTime--;
    },1000)
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
  getErrorOTP(event){
    this.otpError = "";
  }

  checkIfDisabled() { 
    if (!this.errorTextName) {
      if (this.email.length > 0 && this.phoneNumber?.toString().length > 0) {
        if (this.errorTextEmail || this.errorTextNumber) {
          return true; 
        }
        return false; 
      }
      if (this.email.length > 0 && !this.errorTextEmail) {
        return false; 
      }
      if (this.phoneNumber?.toString().length > 0 && !this.errorTextNumber) {
        return false; 
      }
    }
    return true;
  }
  
  
  }
