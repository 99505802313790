import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

// interface DialogData {

// }

@Component({
  selector: 'app-campaign-strategy-tooltip',
  templateUrl: './campaign-strategy-tooltip.component.html',
  styleUrls: ['./campaign-strategy-tooltip.component.css']
})
export class CampaignStrategyTooltipComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CampaignStrategyTooltipComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  getType(type) {
    switch (type) {
      case 'e-t': return this._i18nDynamicTranslate.transform("Event Trigger", ['SHARED_PAGE']);
      case 't-b': return this._i18nDynamicTranslate.transform("Time Based", ['SHARED_PAGE']);
      case 'r-e': return this._i18nDynamicTranslate.transform("Response Events", ['SHARED_PAGE']);
      case 's-s': return this._i18nDynamicTranslate.transform("Survey", ['SHARED_PAGE']);
      case 'e-c': return this._i18nDynamicTranslate.transform("Email", ['SHARED_PAGE']);
      case 's-o': return this._i18nDynamicTranslate.transform("Offer", ['SHARED_PAGE']);
      case 'w-n': return this._i18nDynamicTranslate.transform("Wait", ['SHARED_PAGE']);
    }
  }

  getEvent() {
    return this.data['constants']['customerEvents'][this.data['constants']['customerEvents'].findIndex(x => x.eventName === this.data['content']['event'])].displayName
  }

  getEmail() {
    return this.data['constants']['emailLayouts'][this.data['constants']['emailLayouts'].findIndex(x => x.id === this.data['content']['emailLayoutId'])].layoutName
  }

  getOffer() {
    return this.data['constants']['offers'][this.data['constants']['offers'].findIndex(x => x.id === this.data['content']['offerId'])].offerName
  }

  getSurvey() {
    return this.data['constants']['surveyTemplates'][this.data['constants']['surveyTemplates'].findIndex(x => x.id === this.data['content']['surveyTemplateId'])].layoutName
  }

  getTime() {
    let time = this.data['nodeType'] === 'r-e' ? this.data['content']['timeoutValue'] : this.data['content']['timeUnitValue'];
    let days = +((Math.floor(time / 1440)).toFixed());
    let hours: any = +(Math.floor((time - (days * 1440)) / 60)).toFixed();
    let minutes = time - (days * 1440) - (hours * 60);
    return (days + ' days ' + hours + ' hours ' + minutes + ' minutes');
  }

}
