import STATIC_PAGE from "../../i18nDynamic/es/static.json";
import SHARED_PAGE from "../../i18nDynamic/es/shared.json";
import POS_PAGE from "../../i18nDynamic/es/pos.json";

const ES = {
    STATIC_PAGE: STATIC_PAGE,
    SHARED_PAGE : SHARED_PAGE,
    POS_PAGE: POS_PAGE 
}

export default ES;