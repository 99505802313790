<div class="qb-templateListContainer" #mainContainer id="container" *ngIf="validWidgets">
  <div class="qb-main-wrapper">
    <div class="head">
      <app-breadcrumb></app-breadcrumb>
      <div class="whatsapp-btn">
        <div class="primary-button" *ngIf="isOnboarded === 'false'" (click)="craeteWhatsAppAccount()">
          {{'COMMUNICATION_PAGE.LINK_WHATSAPP' | i18nTranslate}}
        </div>
        <button
          class="qb-create-template-div primary-button"
          (click)="createTemplate('new')"
          *ngIf="isOnboarded === 'true'"
        >
          <span class="qb-create-template-span"
            > Create New
            Template</span
          >
        </button>
      </div>
    </div>
    <div class="wrapper-container main-white-panel">
      <div class="qb-main-header" #mainHeader>
        <div class="template-heading">
          <h5 class="template-heading-text">{{ headingTextKey | i18nTranslate }}</h5>
        </div>

        <!-- USED FOR WHATSAPP V2 -->
        <div class="qb-select-type">
          <span class="qb-selectRectangle" (click)="selectList('all', $event)"
            [ngClass]="{ active: tabSelected == 'all' }" data-button-name="All">{{'COMMUNICATION_PAGE.ALL' | i18nTranslate}}</span>
          <span class="qb-selectRectangle" (click)="selectList('preset', $event)"
            [ngClass]="{ active: tabSelected == 'preset' }" data-button-name="Preset">{{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}</span>
          <span class="qb-selectRectangle" (click)="selectList('mytemplates', $event)"
            [ngClass]="{ active: tabSelected == 'mytemplates' }" data-button-name="Custom">{{'COMMUNICATION_PAGE.CUSTOM' | i18nTranslate}}</span>
          <span class="qb-selectRectangle "
            [ngClass]="{ active: tabSelected == 'status' }" data-button-name="Status" [matMenuTriggerFor]="menuStatus">{{'COMMUNICATION_PAGE.STATUS' | i18nTranslate}}</span>
            <mat-menu #menuStatus class="qb-channel-dialog">
              <button
              mat-menu-item
              class="qb-channel-dialog-content"
              [ngClass]="{ active: status == 'SUBMITTED' }" 
              (click)="changeStatus('SUBMITTED')"
            >
              <span>{{'COMMUNICATION_PAGE.SUBMITTED' | i18nTranslate}}</span>
            </button>
              <button
                mat-menu-item
                class="qb-channel-dialog-content"
                [ngClass]="{ active: status == 'PENDING' }" 
                (click)="changeStatus('PENDING')"
              >
                <span>{{'COMMUNICATION_PAGE.PENDING' | i18nTranslate}}</span>
              </button>
              <button
                mat-menu-item
                class="qb-channel-dialog-content"
                [ngClass]="{ active: status == 'APPROVED' }" 
                (click)="changeStatus('APPROVED')"
              >
                <span>{{'COMMUNICATION_PAGE.APPROVED' | i18nTranslate}}</span>
              </button>
              <button
                mat-menu-item
                class="qb-channel-dialog-content"
                [ngClass]="{ active: status == 'REJECTED' }" 
                (click)="changeStatus('REJECTED')"
              >
                <span>{{'COMMUNICATION_PAGE.REJECTED' | i18nTranslate}}</span>
              </button>
            </mat-menu>
        </div>


        <div >
          <div class="qb-Rectangle">
            <div
              class="qb-d-flex qb-ai-center qb-w-96 qb-h-100"
            >
              <input
                class="qb-input-text-field"
                type="text"
                [(ngModel)]="filter.label"
                placeholder="Search"
                #search
              />
              <span class="material-icons icons-primary"  *ngIf="filter.label.length==0">
                search
                </span>
                <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"  *ngIf="filter.label.length>0" (click)="filter.label=''">
                  close
                </span>
                    </div>
          </div>
        </div>
      </div>

      <div class="qb-all-sms qb-w-100 qb-sms-div" #content  *ngIf="!loading && (validWidgets | widgetConfig: 18); else loadSpinner">
        <div class="qb-template-wrapper">
          <div
          class="qb-sms"
          *ngFor="
            let layout of whatsAppLayout | labelPipe: filter.label:'name';
            let i = index;
            let l = count
          "
        >
          <div
            class="qb-Rectangle-list"
            [id]="'whatsapp-' + layout['id']"
            (click)="openPreview(layout)"
          >
            <div class="qb-sms-template">
              <div class="qb-template-image-container">
                <div class="qb-phone-image">
                  <div class="phone-background-whatsapp-contact-name">
                    <div class="qb-d-flex ">
                      <span style="color: black; font-size: 8px;">{{shopName | limitTo: 10}}</span>
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/verified_logo.svg" style="margin-top: 3px; margin-left: 2.5px;" width="8px" height="8px">
                    </div>
                  </div>
                    <!-- <div class="phone-background-whatsapp"></div> -->
                  <div class="qb-preview-text-content">
                    <div class="preview-text-content-whatsapp-template-view">
                      <div *ngIf="getLayout(layout,'header') === 'image'">
                        <img
                        *ngIf="getLayout(layout,'headerImage')"
                        [src]="getLayout(layout,'headerImage')"
                        alt=""
                        style="
                          height: 80px;
                          width: 80px;
                          object-fit: contain;
                        "
                      />
                      </div>
                      <div *ngIf="getLayout(layout,'header') === 'document'">
                        <embed [src]="getLayout(layout,'headerDocument')" type="application/pdf"  style="
                      height: 80px;
                      width: 80px;
                      object-fit: contain;
                    ">
                      </div>
                      <div *ngIf="getLayout(layout,'header') === 'text'">
                        <span style="font-size: 10px; font-weight: 600; white-space: pre-line;
                        word-break: break-word;">{{getLayout(layout,'headerText')}}</span>
                      </div>
                      <div *ngIf="getLayout(layout,'header') === 'video'">
                        <div *ngIf="getLayout(layout,'headerVideo')" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
                          <div class="img_wrp">
                            <video
                          alt=""
                          style="
                            height: 80px;
                            width: 80px;
                            object-fit: contain;
                          ">
                          <source [src]="getLayout(layout,'headerVideo')" />  
                        </video>
                        <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                          </div>
                        </div>
                      </div>
                      <span style="white-space: pre-line; word-break: break-word">{{
                        getLayout(layout,'body')
                      }}</span>
                      <span *ngIf="getLayout(layout,'footer')" style="color: #636466; font-size: 6px;">{{getLayout(layout,'footer')}}</span>
                      <div class="preview-text-content-after"></div>
                  </div>
                  </div>
                  <div class="qb-d-flex">
                    <div class="qb-button-text-whatsapp" *ngIf="getLayout(layout,'buttons')">
                      {{getLayout(layout,'buttons')}}
                    </div>
                    <!-- <div class="qb-button-text-whatsapp" style="margin-left: 3px;">
                      SHOP NOW
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="qb-sms-name">
                <div>
                  <div
                    class="qb-templateHeadText"
                    [matTooltip]="layout['name']"
                    matTooltipclass="qb-ex-tooltip-red1"
                    matTooltipShowDelay="500"
                  >
                    {{ layout["name"] }}
                  </div>
                </div>
                <!-- <div class="qb-Digits">
                  <span style="color: #aeaeae">Last edited on</span>
                  <span style="font-weight: 600">
                    {{ layout["lastModifiedTime"] | date:'dd MMM yyyy' }}</span
                  >
                </div> -->
              </div>
            </div>

            <!-- Used for WhatsApp V2 -->
            <div class="qb-more-options" id="s">
              <div class="qb-edit-icon" *ngIf="layout['status'] === null">

                <span class="material-icons-outlined icons-primary qb-icon"
                  [matTooltipShowDelay]="100"
                  matTooltip="Clone" (click)="editTemplate(layout)" >
                  <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 10.5C2.53125 10.5 3 10.8125 3.28125 11.25C3.5625 11.7188 3.5625 12.3125 3.28125 12.75C3 13.2188 2.53125 13.5 2 13.5C1.4375 13.5 0.96875 13.2188 0.6875 12.75C0.40625 12.3125 0.40625 11.7188 0.6875 11.25C0.96875 10.8125 1.4375 10.5 2 10.5ZM2 5.5C2.53125 5.5 3 5.8125 3.28125 6.25C3.5625 6.71875 3.5625 7.3125 3.28125 7.75C3 8.21875 2.53125 8.5 2 8.5C1.4375 8.5 0.96875 8.21875 0.6875 7.75C0.40625 7.3125 0.40625 6.71875 0.6875 6.25C0.96875 5.8125 1.4375 5.5 2 5.5ZM3.5 2C3.5 2.5625 3.1875 3.03125 2.75 3.3125C2.28125 3.59375 1.6875 3.59375 1.25 3.3125C0.78125 3.03125 0.5 2.5625 0.5 2C0.5 1.46875 0.78125 1 1.25 0.71875C1.6875 0.4375 2.28125 0.4375 2.75 0.71875C3.1875 1 3.5 1.46875 3.5 2Z" fill="#4379EE"/>
                    </svg>
                </span>
              </div>

              <div class="qb-edit-icon" *ngIf="layout.status?.toUpperCase() === 'SUBMITTED' || layout.status?.toUpperCase() === 'PENDING'">

                <span class="material-icons-outlined icons-primary qb-icon-2"
                  [matTooltipShowDelay]="100"
                  matTooltip="Template submitted, waiting for approval.">
                  update
                </span>
              </div>
              <div class="qb-edit-icon" style="background-color: #60CA18;" *ngIf="layout.status?.toUpperCase() === 'APPROVED'">

                <span class="material-icons-outlined qb-icon-2"
                  [matTooltipShowDelay]="100"
                  matTooltip="Template Approved">
                  done
                </span>
              </div>
              <div class="qb-edit-icon" style="background-color: #D22928;" *ngIf="layout.status?.toUpperCase() === 'REJECTED'">

                <span class="material-icons-outlined qb-icon-2"
                  [matTooltipShowDelay]="100"
                  matTooltip="Template Declined">
                  close
                </span>
              </div>
            </div>



            <!-- <mat-icon
              type=" button "
              placement="bottom "
              class="qb-more-option-icon"
              (click)="openOptions($event)"
            >
              more_vert</mat-icon
            > -->
            <!-- <div class="qb-more-options">
              <div class="qb-d-flex qb-fd-coloumn">
                <div
                  *ngIf="!IsEnvProd"
                  class="qb-popover-menu cursor-pointer"
                  (click)="cloneLayout(layout, $event)"
                >
                  Clone
                </div>
                <div
                  class="qb-popover-menu cursor-pointer"
                  (click)="sendNow(layout['id'], $event)"
                >
                  Send Now
                </div>
              </div>
            </div> -->
          </div>
        </div>
        </div>
        
         
          <div class="no-data-container">
            <div class="qb-NoData" *ngIf="!loading && (
              (whatsAppLayout | labelPipe: filter.label:'name')
                .length === 0 || whatsAppLayout === null)
            ">
                {{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </div>
            <div class="qb-NoData" *ngIf="noData">
              {{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
            </div>
          </div>
          
      </div>
    </div>
   
    
  </div>
  <ng-template #loadSpinner>
    <div class="loader">
      <app-qubriux-loading  [size]="65" [loadingText]="'Fetching Whatsapp Templates...'"></app-qubriux-loading>
    </div>
  </ng-template>
</div>
