<div class="titleDiv">
  <div style="display: flex; justify-content: flex-end">
    <mat-dialog-actions>
      <button class="xIcon" mat-dialog-close>
        <div
          class="material-icons icons-primary"
          style="margin-left: -4.5px; font-size: 15px"
        >
          clear
        </div>
      </button>
    </mat-dialog-actions>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div class="title">{{'CAMPAIGN_PAGE.FILTERS' | i18nTranslate}}</div>
    <div
      *ngIf="showClearAll"
      style="
        color: var(--primary-color);
        font-size: 12px;
        font-weight: 600;
        margin-top: 3px;
        cursor: pointer;
      "
      (click)="clear()"
    >
      {{'CAMPAIGN_PAGE.CLEAR_ALL_CAPS' | i18nTranslate}}
    </div>
  </div>
</div>
<div class="firstFilter filter" style="padding-top: 30px">
  <div style="border-bottom: 1px solid #edf0f2">
    <p><strong style="font-size: 13px">{{'CAMPAIGN_PAGE.FAV_CAPS' | i18nTranslate}}</strong></p>
    <p>
      <mat-checkbox
        [checked]="filters['star'].indexOf('ALL') != -1"
        value="ALL"
        (change)="addFilter('ALL')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
    </p>
    <p>
      <mat-checkbox
        [checked]="filters['star'].indexOf('STARRED') != -1"
        value="STARRED"
        (change)="addFilter('STARRED')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.STARRED' | i18nTranslate}}</span>
    </p>
    <p>
      <mat-checkbox
        [checked]="filters['star'].indexOf('UNSTARRED') != -1"
        value="UNSTARRED"
        (change)="addFilter('UNSTARRED')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.UNSTARRED' | i18nTranslate}}</span>
    </p>
  </div>
</div>
<div class="filter">
  <div style="border-bottom: 1px solid #edf0f2">
    <p><strong style="font-size: 13px">{{'CAMPAIGN_PAGE.STATUS_CAPS' | i18nTranslate}}</strong></p>
    <p>
      <mat-checkbox
        [checked]="filters['status'].indexOf('ALL') != -1"
        value="ALL"
        (change)="addStatusFilter('ALL')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
    </p>
    <p>
      <mat-checkbox
        [checked]="filters['status'].indexOf('ACTIVE') != -1"
        value="ACTIVE"
        (change)="addStatusFilter('ACTIVE')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.ACTIVE' | i18nTranslate}}</span>
    </p>
    <p>
      <mat-checkbox
        [checked]="filters['status'].indexOf('DRAFT') != -1"
        value="DRAFT"
        (change)="addStatusFilter('DRAFT')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.DRAFT' | i18nTranslate}}</span>
    </p>
    <p>
      <mat-checkbox
        [checked]="filters['status'].indexOf('SCHEDULED') != -1"
        value="SCHEDULED"
        (change)="addStatusFilter('SCHEDULED')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.SCHEDULED' | i18nTranslate}}</span>
    </p>
    <p>
      <mat-checkbox
        [checked]="filters['status'].indexOf('ENDED') != -1"
        value="ENDED"
        (change)="addStatusFilter('ENDED')"
      ></mat-checkbox
      ><span>{{'CAMPAIGN_PAGE.ENDED' | i18nTranslate}}</span>
    </p>
  </div>
</div>
<div class="filter lastFilter">
  <div style="border-bottom: 1px solid #edf0f2">
    <p><strong style="font-size: 13px">{{'CAMPAIGN_PAGE.SEGMENTS_CAPS' | i18nTranslate}}</strong></p>
    <div *ngIf="loading" style="display: flex; justify-content: center">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div *ngIf="!loading">
      <p>
        <mat-checkbox
          [checked]="filters['segments'].indexOf('ALL') != -1"
          name="ALL"
          (change)="addSegmentFilter('ALL')"
          >{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</mat-checkbox
        >
      </p>
      <p *ngFor="let item of segments" style="word-break: break-all">
        <mat-checkbox
          [checked]="filters['segments'].indexOf(item.id) != -1"
          name="{{ item.segmentName }}"
          (change)="addSegmentFilter(item.id)"
          [disabled]="!item['isActive']"
          [matTooltip]="
            item['isActive']
              ? ''
              : 'We are preparing your segment. It will be available for use shortly'
          "
          >{{ item.segmentName }}</mat-checkbox
        >
      </p>
    </div>
  </div>
</div>
<div>
  <div class="buttons">
    <mat-dialog-actions style="margin-bottom: -6px">
      <button class="secondary-button-border" mat-dialog-close>{{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions style="margin-bottom: -6px">
      <button
        class="apply primary-button"
        (click)="applyFilter()"
        mat-dialog-close
      >
        {{'CAMPAIGN_PAGE.APPLY' | i18nTranslate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
