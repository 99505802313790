import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as d3 from "d3";
import { Router } from "@angular/router";
import { MatSelect } from "@angular/material/select";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-negotiator-analytics",
  templateUrl: "./negotiator-analytics.component.html",
  styleUrls: ["./negotiator-analytics.component.css"],
})
export class NegotiatorAnalyticsComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private datePipe: DatePipe,
    private router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  daySpan: number = 150;
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public currentDay: number = new Date().getDate();
  public start: Date = new Date(this.fullYear, this.month, this.currentDay);
  public end: Date = new Date(this.fullYear, this.month, this.currentDay);
  public dateFormat: String = "yyyy-MM-dd";
  public maxDate: Date = new Date(this.fullYear, this.month, this.currentDay);

  public today: Date = new Date(new Date().toDateString());

  public last7dayStart: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay - 7
  );
  public last7dayEnd: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay - 1
  );
  public weekStart: Date = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  public weekEnd: Date = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );

  public monthStart: Date = new Date(
    new Date(new Date().setDate(1)).toDateString()
  );
  public monthEnd: Date = this.today;
  public last6month: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 5)).setDate(1)
    ).toDateString()
  );
  public lastStart: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay - 31
  );

  public lastEnd: Date = this.today;
  public yearStart: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 365)).toDateString()
  );
  public yearEnd: Date = this.today;
  startDate;
  endDate;
  chartheight;
  dropDownStatusFilter: boolean = false;

  graphDataLoading;
  graphData;
  graphDataNull;
  filterViewBy = "daily";
  parseDate;
  formatTime;
  hoverFormat;
  revCurrency;
  @ViewChild("chartArea") chartArea: ElementRef;
  @ViewChild("select") select;
  @ViewChild("selectGame") selectGame: MatSelect;
  selectedGame = "All Games";
  AllGameList;
  private readonly RELOAD_TOP_SCROLL_POSITION = 100;
  @ViewChild("filterdropdown") filterdropdown: ElementRef;
  onGlobalClick(event): void {
    if (this.filterdropdown.nativeElement.contains(event.target)) {
    } else {
      this.toggleSelect(true);
    }
  }

  ImpressionValue;
  TotalTargetCustomer;
  TotalViews;
  DeskstopPercentage;
  MobilePercentage;
  EngagementValue;
  TotalPeoplePlayed;
  NoPeopleCompleted;
  ConversionRate;
  TotalPurchases;
  AnonymusPurchases;
  EmailCollected;
  RevenueGenerated;
  viaDeskstop;
  viaMobile;
  TotalRevenue;
  DeskstopPercentageEngagement;
  MobilePercentageEngagement;
  DeskstopPercentageRevenue;
  MobilePercentageRevenue;
  YLegend = this._i18nDynamicTranslate.transform("Revenue Generated($)", ['POS_PAGE']);
  Xlegend;
  status = ["LIVE", "SCHEDULED", "ENDED", "PAUSED", "DRAFT"];
  recordsperPage = 10;
  pageNo = 1;
  sortBy = "DATE";
  order = "ASC";

  Period;
  selectedPeriod = "LAST_7_DAYS";

  getNewData(pageNum, pageSize) {
    //To get the new game data when the scroll event is called.
    this.apiCall
      .getAllGameList(
        pageSize,
        pageNum,
        this.sortBy,
        this.status,
        this.order,
        ""
      )
      .subscribe((response) => {
        let body = response["body"]["elements"];
        body.forEach((element) => {
          this.AllGameList.push(element);
        });
      });
  }

  ngOnInit() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.filterViewBy = "monthly";
    this.Xlegend = this.filterViewBy;
    this.startDate = this.datePipe.transform(this.last6month, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.last7dayEnd, "yyyy-MM-dd");
    this.start = this.startDate;
    this.end = this.endDate;
    this.getStatData();
    this.getGames();
    this.selectGame.openedChange.subscribe(() =>
      this.registerPanelScrollEvent()
    );
  }
  registerPanelScrollEvent() {
    const panel = this.selectGame.panel.nativeElement;
    panel.addEventListener("scroll", (event) => this.loadAllOnScroll(event));
  }

  loadAllOnScroll(event) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.pageNo += 1;
      this.getNewData(this.pageNo, this.recordsperPage);
    }
  }
  ngAfterContentInit() {
    this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
  }
  ngOnChanges() {
    this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
  }

  getStatData() {
    //To get stat of the all games
    this.apiCall.getNegotiatorAnalytics().subscribe((response) => {
      this.ImpressionValue = response["body"]["total"]["distinctViewed"]
      this.TotalTargetCustomer = response["body"]["targetAudience"];
      this.TotalViews = response["body"]["total"]["overallViewed"];
      this.DeskstopPercentage = this.divide(
        response["body"]["desktop"]["overallViewed"],
        response["body"]["desktop"]["overallViewed"] +
          response["body"]["mobile"]["overallViewed"]
      );
      this.MobilePercentage = this.divide(
        response["body"]["mobile"]["overallViewed"],
        response["body"]["desktop"]["overallViewed"] +
          response["body"]["mobile"]["overallViewed"]
      );
      this.EngagementValue = this.divide(
        response["body"]["total"]["played"],
        response["body"]["total"]["distinctViewed"]
      );
      this.TotalPeoplePlayed = response["body"]["total"]["played"];
      this.NoPeopleCompleted = response["body"]["total"]["completed"];
      this.DeskstopPercentageEngagement = this.divide(
        response["body"]["desktop"]["completed"],
        response["body"]["desktop"]["completed"] +
          response["body"]["mobile"]["completed"]
      );
      this.MobilePercentageEngagement = this.divide(
        response["body"]["mobile"]["completed"],
        response["body"]["desktop"]["completed"] +
          response["body"]["mobile"]["completed"]
      );
      this.ConversionRate = this.divide(
        response["body"]["total"]["distinctPurchased"],
        response["body"]["total"]["completed"]
      );
      this.TotalPurchases = response["body"]["total"]["overallPurchased"];
      this.AnonymusPurchases = response["body"]["anonymous"]["overallPurchased"];
      this.EmailCollected = response["body"]["anonymous"]["emailsCollected"];
      this.RevenueGenerated =Math.round(response["body"]["total"]["revenue"] *100)/100;
      this.viaDeskstop = Math.round(response["body"]["desktop"]["revenue"]*100)/100;
      this.viaMobile = Math.round(response["body"]["mobile"]["revenue"]*100)/100;
      this.DeskstopPercentageRevenue = this.divide(
        response["body"]["desktop"]["revenue"],
        response["body"]["desktop"]["revenue"] +
          response["body"]["mobile"]["revenue"]
      );
      this.MobilePercentageRevenue = this.divide(
        response["body"]["mobile"]["revenue"],
        response["body"]["desktop"]["revenue"] +
          response["body"]["mobile"]["revenue"]
      );
    });
    this.loadCustomGraph(this.startDate, this.endDate);
  }
  divide(num1, num2) {
    if (num2 == 0) {
      return 0;
    } else {
      var value;
      value = (num1 / num2) * 100;
      value = Math.round(value * 100) / 100;
      return value;
    }
  }
  getGames() {
    //To get list of games
    this.apiCall
      .getAllGameList(
        this.recordsperPage,
        this.pageNo,
        this.sortBy,
        this.status,
        this.order,
        ""
      )
      .subscribe((response) => {
        this.AllGameList = response["body"]["elements"];
      });
  }
  dateChangeDetected(event) {
    //Used in custom when a date change is detected
    var maximumdate = new Date(this.fullYear, this.month, new Date().getDate());
    let startdate = this.datePipe.transform(event["startDate"], "yyyy-MM-dd");
    let endDate = this.datePipe.transform(event["endDate"], "yyyy-MM-dd");
    let newcurrentDate = this.datePipe.transform(maximumdate, "yyyy-MM-dd");
    let newstartdate = new Date(startdate);
    let newendDate = new Date(endDate);
    let newnewcurrentDat = new Date(newcurrentDate);

    this.daySpan = event["daySpan"];
    this.startDate = this.datePipe.transform(event["startDate"], "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(event["endDate"], "yyyy-MM-dd");

    if (
      newendDate.getTime() < newstartdate.getTime() ||
      newstartdate.getTime() > newnewcurrentDat.getTime() ||
      newendDate.getTime() > newnewcurrentDat.getTime()
    ) {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Enter valid date range", ['POS_PAGE']), 2000);
    } else {
      if (this.daySpan != 0 && this.startDate != null && this.endDate != null) {
        this.loadCustomGraph(this.startDate, this.endDate);
      }
    }
  }
  gameid;
  gameName;
  ChangePeriod() {
    //To change the period
    this.Period = this.selectedPeriod;
    if (this.selectedGame != "All Games")
      this.loadIndividualGameGraph(this.gameid);
    else this.loadCustomGraph(this.start, this.end);
  }
  ChangeGame() {
    //To change the game
    this.gameid = this.selectedGame["id"];
    this.gameName = this.selectedGame["title"];
    if (this.selectedGame != "All Games")
      this.loadIndividualGameGraph(this.gameid);
    else this.loadCustomGraph(this.startDate, this.endDate);
  }
  openSelect() {
    this.select.open();
  }
  openSelectGame() {
    this.selectGame.open();
    this.selectGame.openedChange.subscribe(() =>
      this.registerPanelScrollEvent()
    );
  }
  updateCheckboxValues(value) {
    //Used in custom to select periodicity
    this.filterViewBy = value;
    if (this.filterViewBy.toUpperCase() === "MONTHLY") {
      this.Xlegend = "Months";
    } else if (this.filterViewBy.toUpperCase() === "DAILY") {
      this.Xlegend = "Days";
    }
    this.toggleSelect();
    if (
      this.daySpan != 0 &&
      this.startDate != null &&
      this.endDate != null &&
      this.selectedGame == "All Games"
    ) {
      this.loadCustomGraph(this.startDate, this.endDate);
    } else {
      this.loadIndividualGameGraph(this.gameid);
    }
  }

  toggleSelect(status?) {
    let element = document.getElementById("multi-select-dropdown");
    let segmentSelect = document.getElementById("segment-select");
    let segmentSelectPanel = document.getElementById("multi-select-panel");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container"
    );
    if (this.dropDownStatusFilter || status) {
      this.dropDownStatusFilter = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      this.dropDownStatusFilter = true;
      element.style.height = "auto";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }

  loadCustomGraph(start, end) {
    //To load the graph of all games
    this.graphDataLoading = true;
    this.graphData = null;
    let filter = this.filterViewBy;
    if (this.filterViewBy.toUpperCase() === "MONTHLY") {
      this.Xlegend = "Months";
    } else if (this.filterViewBy.toUpperCase() === "DAILY") {
      this.Xlegend = "Days";
    }
    this.apiCall
      .getAllGamesNegotiatiorRevenueAnalytics(
        this.selectedPeriod,
        filter.toUpperCase(),
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response) => {
          if (
            response["message"] == "success" ||
            response["message"] == "SUCCESS"
          ) {
            if (
              response["body"] != null &&
              Object.keys(response["body"]).length != 0
            ) {
              this.graphDataNull = false;
              let winBackData = [];
              for (let i in response["body"]["dateRevenueList"]) {
                winBackData.push({
                  name: response["body"]["dateRevenueList"][i]["playedFrom"],
                  revenue: response["body"]["dateRevenueList"][i]["revenue"],
                });
              }

              var k = [];
              for (let key in winBackData) {
                k.push({
                  name: winBackData[key]["name"].substring(0, 10),
                  value: winBackData[key]["revenue"],
                  originalDate: winBackData[key]["name"].substring(0, 10),
                });
              }
              this.graphData = k;

              if (response["body"]["periodicity"] === "MONTHLY") {
                this.Xlegend = "Months";
              } else if (response["body"]["periodicity"] === "DAILY") {
                this.Xlegend = "Days";
              }
              this.TotalRevenue = Math.round(response["body"]["totalRevenue"] *100)/100;
              let filter = response["body"]["periodicity"];

              switch (filter.toUpperCase()) {
                case "MONTHLY":
                case "QUARTERLY": {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%b");
                  this.hoverFormat = d3.timeFormat("%b %Y");
                  break;
                }
                case "DAILY":
                case "WEEKLY": {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%b %d");
                  this.hoverFormat = d3.timeFormat("%d %b %Y");
                  break;
                }
                case "YEARLY": {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%Y");
                  this.hoverFormat = d3.timeFormat("%Y");
                  break;
                }
                default: {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%b %d %Y");
                  this.hoverFormat = d3.timeFormat("%b %d %Y");
                  break;
                }
              }

              var i, n;
              for (i = 0, n = k.length; i < n; i++) {
                var d = k[i];
                for (var c in d) {
                  if (c == "name") {
                    d[c] = this.parseDate(d[c]);
                    d[c] = this.formatTime(d[c]);
                  }
                  if (c == "originalDate") {
                    d[c] = this.parseDate(d[c]);
                    d[c] = this.hoverFormat(d[c]);
                  }
                }
                k[i] = d;
              }

              this.graphDataLoading = false;
            } else {
              this.graphData = null;

              this.graphDataNull = true;
              this.graphDataLoading = false;
            }
          }
          this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
        },
        (error) => {
          this.graphData = null;
          this.graphDataNull = true;
          this.graphDataLoading = false;
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(error.error.body, ['POS_PAGE']), 2000);
        }
      );
  }
  loadIndividualGameGraph(gameid) {
    //To load individual graphs
    this.graphDataLoading = true;
    this.graphData = null;
    let filter = this.filterViewBy;
    this.Xlegend = this.filterViewBy;
    this.apiCall
      .getSpecificGameNegotiatiorRevenueAnalytics(
        this.selectedPeriod,
        gameid,
        filter.toUpperCase(),
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response) => {
          if (
            response["message"] == "success" ||
            response["message"] == "SUCCESS"
          ) {
            if (
              response["body"] != null &&
              Object.keys(response["body"]).length != 0
            ) {
              this.graphDataNull = false;
              let winBackData = [];
              for (let i in response["body"]["dateRevenueList"]) {
                winBackData.push({
                  name: response["body"]["dateRevenueList"][i]["playedFrom"],
                  revenue: response["body"]["dateRevenueList"][i]["revenue"],
                });
              }
              var k = [];
              for (let key in winBackData) {
                k.push({
                  name: winBackData[key]["name"].substring(0, 10),
                  value: winBackData[key]["revenue"],
                  originalDate: winBackData[key]["name"].substring(0, 10),
                });
              }
              this.graphData = k;
              // this.filterViewBy=response['body']['periodicity']
              // this.Xlegend=response['body']['periodicity']
              if (response["body"]["periodicity"] === "MONTHLY") {
                this.Xlegend = "Months";
              } else if (response["body"]["periodicity"] === "DAILY") {
                this.Xlegend = "Days";
              }
              this.TotalRevenue = Math.round(response["body"]["totalRevenue"] *100)/100;
              let filter = response["body"]["periodicity"];

              switch (filter.toUpperCase()) {
                case "MONTHLY":
                case "QUARTERLY": {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%b");
                  this.hoverFormat = d3.timeFormat("%b %Y");
                  break;
                }
                case "DAILY":
                case "WEEKLY": {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%b %d");
                  this.hoverFormat = d3.timeFormat("%d %b %Y");
                  break;
                }
                case "YEARLY": {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%Y");
                  this.hoverFormat = d3.timeFormat("%Y");
                  break;
                }
                default: {
                  this.parseDate = d3.timeParse("%Y-%m-%d");
                  this.formatTime = d3.timeFormat("%b %d %Y");
                  this.hoverFormat = d3.timeFormat("%b %d %Y");
                  break;
                }
              }

              var i, n;
              for (i = 0, n = k.length; i < n; i++) {
                var d = k[i];
                for (var c in d) {
                  if (c == "name") {
                    d[c] = this.parseDate(d[c]);
                    d[c] = this.formatTime(d[c]);
                  }
                  if (c == "originalDate") {
                    d[c] = this.parseDate(d[c]);
                    d[c] = this.hoverFormat(d[c]);
                  }
                }
                k[i] = d;
              }

              this.graphDataLoading = false;
            } else {
              this.graphData = null;

              this.graphDataNull = true;
              this.graphDataLoading = false;
            }
          }
          this.chartheight = this.chartArea.nativeElement.offsetHeight - 10;
        },
        (error) => {
          this.graphData = null;
          this.graphDataNull = true;
          this.graphDataLoading = false;
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(error.error.body, ['POS_PAGE']), 2000);
        }
      );
  }
  changeGame() {
    //To navigate to the selected game to show single game data.
    this.router.navigate(["/app/gamify/"+this.gameName], {
      queryParams: { id: this.gameid },
    });
  }
}
