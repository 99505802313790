// custom-time.pipe.ts
import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "customTime" })
export class CustomTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
  transform(time24hr: string): string {
    let isValidTime = this.timePattern.test(time24hr);
    let time = isValidTime ? time24hr : this.datePipe.transform(time24hr, "HH:mm");
    // if(!isValidTime){
    //     time = this.datePipe.transform(time24hr, "HH:mm");
    // }
    // Split the time string into hours and minutes
    if (sessionStorage.getItem("serviceCountry") === "US") {
      const [hours, minutes] = time.split(":");

      // Convert hours to 12-hour format and determine 'am' or 'pm'
      const amPm = parseInt(hours) >= 12 ? "pm" : "am";
      const hours12 = parseInt(hours) % 12 || 12; // Ensure 12:00 is displayed as 12:00 pm

      // Create the 12-hour time string
      return `${hours12}:${minutes} ${amPm}`;
    } else {
      return time;
    }
  }
}
