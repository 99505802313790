import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as d3 from "d3";
@Component({
  selector: "app-donut-chart",
  templateUrl: "./donut-chart.component.html",
  styleUrls: ["./donut-chart.component.css"],
})
export class DonutChartComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() data;
  @Input() idn;
  @Input() totalCount;
  @ViewChild("chartContainer") chartContainer: ElementRef;
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  scrChartWidth;
  scrWidth;
  scrHeight;
  constructor() {
    this.getScreenSize();
    this.scrChartWidth = this.scrWidth * 0.6 * 0.3;
  }

  ngOnInit() {}
  ngOnDestroy() {}
  ngOnChanges() {
    if (this.data) this.getDounutChart(this.data, this.idn);
  }
  ngAfterViewInit() {
    if (this.data) this.getDounutChart(this.data, this.idn);
  }
  render() {}

  getDounutChart(dataset, idn) {
    var idnumber = "#" + idn;
    var p = 0.1;
    var i, n;
    for (i = 0, n = dataset.length; i < n; i++) {
      var d = dataset[i];
      for (var c in d) {
        if (c == "count") {
          if (d[c] == this.totalCount) p = 0;
        }
      }
      dataset[i] = d;
    }

    d3.select(idnumber).selectAll("*").remove();
    var data = dataset;
    // var data = [
    //   { name: 'Promoters', count: this.promoters, percentage: this.promotersPercentage, color: '#4abb59' },
    //   { name: 'Passives', count: this.passives, percentage: this.passivesPercentage, color: '#edbd22' },
    //   { name: 'Detractors', count: this.detractors, percentage: this.detractorsPercentage, color: '#f84737' },
    // ]
    //var totalCount = this.totalCountValue;		//calcuting total manually
    var width = 130,
      height = 130,
      radius = Math.min(width, height) / 2;
    var arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - 20);
      // .cornerRadius(5);

    var pie = d3
      .pie()
      .padAngle(p)
      .sort(null)
      .value(function (d) {
        return d.count;
      });
    var tooltipdiv = d3
      .select(idnumber)
      .append("div")
      .attr("class", "tooltipclass");
    var svg = d3
      .select(idnumber)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // d3.select("idnum)
    // .on("mouseover", function(){return tooltipset.style("visibility", "visible");})
    // .on("mousemove", function(){return tooltipset.style("top", (d3.event.pageY-50)+"px").style("left",(d3.event.pageX-50)+"px");})
    // .on("mouseout", function(){return tooltipset.style("visibility", "hidden");});

    // var tooltip = d3.select("idnum)
    // .append("div")
    // .style("position", "relative")
    // .style("z-index", "10")
    // .style("visibility", "hidden")
    // .text(function(d, i) {
    //   return data[i].name; });

    var g = svg.selectAll(".arc").data(pie(data)).enter().append("g");

    g.append("path")
      .attr("d", arc)
      .style("fill", function (d, i) {
        return d.data.color;
      })
      .transition()
      .duration(function (d, i) {
        return i * 800;
      })
      .attrTween("d", function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    var tooltip = d3
      .select(idnumber)
      .append("div")
      // .attr("stroke", "#000")
      .style("display", "none");
    tooltip
      .append("rect")
      .attr("width", 30)
      .attr("height", 20)
      .attr("fill", "white")
      .style("opacity", 0.5);
    tooltip
      .append("text")
      .attr("x", 15)
      .attr("dy", "1.2em")
      .style("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("font-weight", "bold");
    // tooltip
    //   .append("text")
    //   .attr("x", 15)
    //   .attr("dy", "1.2em")
    //   .style("text-anchor", "middle")
    //   .attr("font-size", "12px")
    //   .attr("font-weight", "bold")
    //   tooltipdiv.html(null)
    //   .style('display', 'none')
    // .style("left", (d3.event.pageX + 15) + "px")
    //   .style("top", (d3.event.pageY - 24) + "px");
    //   for (let i = 0; i < data.length; i++) {
    //     tooltipdiv.append('div').html("<span>" + data[i]['name'] + "-" + data[i]['count'] + "</span>")
    //     .style('color', data[i]['color']);
    //     tooltipdiv.style('display', 'block')
    //   }
    // var tooltipset = d3
    //   .select("body")
    //   .append("div")
    //   .style("position", "absolute")
    //   .style("z-index", "10")
    //   .style("visibility", "hidden")
    //   .html(null)
    //   .style("display", "none");
    // .style("left", (15) + "px")
    //   .style("top", ( - 24) + "px");
    // var textt="<div style='background:#fff;border-radius:4px;padding:5px'";
    // var textt = "";
    // for (let i = 0; i < data.length; i++) {
    //   textt =
    //     textt +
    //     "<span class='fontExo2' style='display:inline-block;color:" +
    //     data[i].color +
    //     ";font-family:'Open sans'; font-size:16px;' >" +
    //     data[i]["name"] +
    //     ":  " +
    //     data[i]["count"] +
    //     "</span>" +
    //     "<br>";
    //   tooltipset.style("display", "inline-block");
    // }
    // textt=textt+"</div>"
    // tooltipset.html(textt);
    // g.append("text")
    //   .attr("text-anchor", "middle")
    //   .attr("font-size", "26px")
    //   .attr("font-weight", "600")
    //   .attr("y", 10)
    //   .style("fill", "#101424")
    //   .attr("font-family", "Open sans")
    //   .text(this.totalCount);

    // g.append("text")
    //   .attr("text-anchor", "middle")
    //   .attr('font-size', '16px')
    //   .attr('y', '1em')
    //   .style("fill","#101424")
    //   .text("Total Survey Score");
  }
}
