import { Component, HostListener, Inject, Input, OnInit, Pipe } from '@angular/core';
import { DatePipe, getCurrencySymbol } from '@angular/common'
import { DialogPosition, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent, SuccessDialogComponent,OfferSummaryDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})

export class CustomerProfileComponent implements OnInit {

  customerDetails;
  recentOrdersArray = [];
  recentOrderDates: any = [];
  reviewsArray = [];
  revCurrency;
  mainTopic = "profile";
  loyaltyDetails = [];
  loyaltyTransactionDetails = [];
  walletTransactionDetails = [];
  offerTransactionDetails = [];
  giftcardDetails=[];
  offerList = [];
  selectedOffer = "";
  athorities = this.tokenStorage.getAuthorities();
  coinsToGive = 0;
  page = 1;
  size = 15;
  allCustomersListViewDataLoading = false;
  dialogPosition: DialogPosition = {
    top: "0px",
    right: "0px",
  };
  balanceAmtInWallet = 0;
  currencySymbol;
  customerWalletId = null;
  validWidgets = null;
  isActive: boolean = false;
  filter = {
    label: "",
  };
  minDate;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private tokenStorage: TokenStorageService,
              private apiCall: ApiCallService,
              private datePipe: DatePipe,
              private date_format: getdateformatService,
              private dialog: MatDialog,
              private _i18nDynamicTranslate: TranslateDynamicText,
              private _snackBar:SnackbarCollection,
              private _widgetConfigService: WidgetConfigService,
              public dialogRef: MatDialogRef<CustomerProfileComponent> ) { }
              private router: Router
              segments = [];

              @HostListener("document:click", ["$event"])
  onDialogClick(clickout): void {
    if(clickout.srcElement.target == undefined) {
      if(clickout.srcElement.id !== "popup") {
      let ele = document.getElementById("close-popup");
      if(ele)
        ele.setAttribute("style", "display:none");
      }
    }
  }

  closePopup() {
    this.dialogRef.close(this.customerDetails);
  }

  async ngOnInit() {
    const currentDate = new Date();
    this.minDate = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    this.segments = [];
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.customerDetails = this.data.customer;
    this.segments = this.customerDetails['customerSegments'];
    this.validWidgets = await this._widgetConfigService.getWidget();
    // this.data.customerList.forEach(element => {
    //   if(element['customerid'] == this.data.customerID) {
    //     this.customerDetails = this.data.customer;
    //     this.segments = this.customerDetails['customerSegments'];
    //   }
    // });
    this.getCustomerDetails(this.customerDetails['merchantCustomerId']);
    this.getRecentOrders();
    this.getReviews();
    // this.getCountryCode();
  }

  // countryCodeData;
  // getCountryCode(){
  //   this.apiCall.getCountryCode().subscribe((response)=>{
  //     this.countryCodeData = response['body'];
  //   })
  // }

  customerDetailLoading = false;
  getCustomerDetails(customerId) {
    this.customerDetailLoading = true;
    this.apiCall.getCustomerDetails(customerId).subscribe(
      (response) => {
        this.customerDetails = response['body'];
        if(this.customerDetails['customerSegments'] && this.customerDetails['customerSegments'].length>0) {
          this.customerDetails['segments'] = this.customerDetails['customerSegments'].map((segment)=>segment.segmentName);
        } else {
          this.customerDetails['segments'] = [];
        }
        this.customerDetailLoading = false;
      },
      (err) => {
        this._snackBar.openSnackBar('Failed to fetch customer detail',5000);
      }
    );
  }

  editMode = false;
  customerEditableDetails;
  customerEditableDetailsError;
  editCustomerDetails() {
    // let reqCountryData = this.countryCodeData[sessionStorage.getItem('serviceCountry')];
    this.customerEditableDetails = {
      merchantCustomerId :  this.customerDetails['merchantCustomerId'],
      firstName : this.customerDetails['firstName'],
      lastName : this.customerDetails['lastName'],
      phone : this.customerDetails['phone'],
      email : this.customerDetails['email'],
      birthday: this.customerDetails['birthday'],
      anniversary: this.customerDetails['anniversary'],
      // phoneNumberLength: reqCountryData.phoneNumberLength,
      // phoneCode : '+'+reqCountryData.Code,
    }
    this.customerEditableDetailsError = {
      firstNameError : false,
      // emailPhoneError : false,
    }
    this.editMode = true;
  }

  resetErrorState() {
    this.customerEditableDetailsError = {
      firstNameError : false,
      // emailPhoneError : false,
    }
  }

  saveCustomerDetails() {
    this.customerEditableDetails.firstName = this.customerEditableDetails.firstName?.trim();
    // this.customerEditableDetails.email = this.customerEditableDetails.email?.trim();
    // let emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
    if(!this.customerEditableDetails.firstName) {
      this.customerEditableDetailsError.firstNameError = 'Please enter valid first name'
      return;
    }
    // if(!this.customerEditableDetails.email?.match(emailPattern) &&  this.customerEditableDetails.phone?.length != this.customerEditableDetails.phoneNumberLength) {
    //   this.customerEditableDetailsError.emailPhoneError = 'Please enter valid email or phone number'
    //   return;
    // }
    
    // Correctly handle the birthday date conversion
    if (this.customerEditableDetails.birthday) {
      const birthday = new Date(this.customerEditableDetails.birthday);
      this.customerEditableDetails.birthday = new Date(Date.UTC(birthday.getFullYear(), birthday.getMonth(), birthday.getDate()))
        .toISOString()
        .split('T')[0];
    }

    // Correctly handle the anniversary date conversion
    if (this.customerEditableDetails.anniversary) {
      const anniversary = new Date(this.customerEditableDetails.anniversary);
      this.customerEditableDetails.anniversary = new Date(Date.UTC(anniversary.getFullYear(), anniversary.getMonth(), anniversary.getDate()))
        .toISOString()
        .split('T')[0];
    }
    this.apiCall.updateCustomer(this.customerEditableDetails).subscribe(
      (response)=>{
        this._snackBar.openSnackBar('Successfully updated detail',2000);
        this.editMode = false;
        this.customerDetails['firstName'] = this.customerEditableDetails['firstName'];
        this.customerDetails['lastName'] = this.customerEditableDetails['lastName'];
        this.customerDetails['customerName'] = this.customerEditableDetails['firstName']+(this.customerEditableDetails['lastName']?' '+this.customerEditableDetails['lastName']:'');
        this.customerDetails['birthday'] = this.customerEditableDetails['birthday'];
        this.customerDetails['anniversary'] = this.customerEditableDetails['anniversary'];
      },
      (err)=> {
        this._snackBar.openSnackBar('Failed to update detail',5000);
      }
    );
    
  }

  resetFilter() {
    this.filter.label = "";
  }

  showSegments(event, i) {
    let element = document.getElementById("close-popup");
    element.setAttribute("style", "display:flex");
  }

  getRecentOrders() {
    this.apiCall.getRecentOrders(this.tokenStorage.getMerchantId(), this.customerDetails['merchantCustomerId']).subscribe(response => {
      this.recentOrdersArray = response['body'];
      this.recentOrdersArray.forEach(order => {
        order['orderTime'] = (order['orderDate'].split(' '))[1];
        order['orderDate'] = this.getDateInFormat(order['orderDate']);
        // let t;
        // if(order['orderTime']['hour'] > 11) {
        //   t = "PM";
        // } else {
        //   t = "AM";
        // }
        // if(order['orderTime']['hour'] == 0) {
        //   order['orderTime']['hour'] = 12;
        // }
        // if(order['orderTime']['hour'] > 12) {
        //   order['orderTime']['hour'] = order['orderTime']['hour'] % 12;
        // }
        // if(order['orderTime']['minute'] < 10) {
        //   order['orderTime'] = order['orderTime']['hour'] + ":" + "0" + order['orderTime']['minute'] + t;
        // } else
        //   order['orderTime'] = order['orderTime']['hour'] + ":" + order['orderTime']['minute'] + t;
        if(!this.recentOrderDates.includes(order['orderDate']))
          this.recentOrderDates.push(order['orderDate']);
      })
    })
  }

  getDateInFormat(dateString: number): string | null {
    if (!dateString) return null;
    let date = new Date(dateString);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }



  transfor(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am';
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

  getReviews() {
    this.apiCall.getReviews(this.tokenStorage.getMerchantId(), this.customerDetails['merchantCustomerId']).subscribe(response => {
      this.reviewsArray = JSON.parse(response['body']);
      this.reviewsArray.forEach(review => {
        let time = review['createdAt'].split("T")[1]
        let hour = time.split(":")[0];
        let t;
        if(hour > 12) {
          t = "PM";
        } else {
          t = "AM";
        }
        if(hour == 0) {
          hour = 12;
        }
        if(hour > 12) {
          hour = hour % 12;
        }
        let minute = time.split(":")[1];
        let date = this.datePipe.transform(review['createdAt'].split("T")[0], 'dd MMM yyyy');
        review['createdAt'] = date + "| " + hour + ":" + minute + t;
      })
    })
  }

  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  tabChange(type){
    this.editMode = false;
    this.mainTopic = type;
    this.coinsToGive = 0;
    if(type==='loyalty' && this.loyaltyDetails.length === 0){
      this.allCustomersListViewDataLoading = true;
      this.getLoyaltyDetails();
    }
    else if( type === 'wallet' && this.walletTransactionDetails.length === 0){
      this.allCustomersListViewDataLoading = true;
      this.getCustomerWalletId();
    }
    else if( type === 'giftcard' && this.giftcardDetails.length === 0){
      this.allCustomersListViewDataLoading = true;
      this.getGiftCardDetails();
    }
    else if( type === 'sendoffer' && this.offerList.length === 0){
      this.allCustomersListViewDataLoading = true;
      this.getOfferTransactionDetails();
      this.getallOffer();
    }

  }
  getLoyaltyDetails(){
    this.getLoyaltyDetailsApi().then((result) => {
      if(result){
        console.log(result);
        this.loyaltyDetails = result['body'];
        let data = this.loyaltyDetails['transactions'];
        this.loyaltyTransactionDetails = [...this.loyaltyTransactionDetails, ...data];
        this.allCustomersListViewDataLoading = false;
        console.log(this.loyaltyTransactionDetails);
      }
    },(reject)=>{
      // this.allCustomersListViewDataLoading = false;
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error getting details", ['POS_PAGE']),2000);
    });
  }

  getWalletDetails(){
    let data = {
      "customerId": this.customerDetails['merchantCustomerId'],
      "walletId": this.customerWalletId
    }
    this.getWalletBalanceApi(data).then((result) => {
      if(result){
        console.log(result);
        this.balanceAmtInWallet = result['body']['availableBalance'];
        // this.loyaltyDetails = result['body'];
        // let data = this.loyaltyDetails['transactions'];
        // this.loyaltyTransactionDetails = [...this.loyaltyTransactionDetails, ...data];
        // this.allCustomersListViewDataLoading = false;
        // console.log(this.loyaltyTransactionDetails);
      }
    });
  }

  getWalletHistoryDetails(){
    let data = {
      "customerId": this.customerDetails['merchantCustomerId'],
      "walletId": this.customerWalletId
    }
    this.getWalletHistoryApi(data).then((result) => {
      if(result){
        console.log(result);
        this.walletTransactionDetails =  result['body']['transactions'];
        this.allCustomersListViewDataLoading =false;
        // this.loyaltyDetails = result['body'];
        // let data = this.loyaltyDetails['transactions'];
        // this.walletTransactionDetails = [...this.walletTransactionDetails, ...data];
        // this.allCustomersListViewDataLoading = false;
        // console.log(this.loyaltyTransactionDetails);
      }
    },(error)=>{
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error getting details", ['POS_PAGE']),2000);
      this.allCustomersListViewDataLoading =false;
    });
  }



  numberInputHandler(event: KeyboardEvent,maxLength) {
    this.resetErrorState();//for edit customer details
    const invalidCharacters = ['e', '-', '+'];
    const inputValue = (event.target as HTMLInputElement).value;
    const hasDecimalPoint = inputValue.includes('.');
    if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key) ||
    (event.key === '.' && hasDecimalPoint)) {
      event.preventDefault();
    }
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {

      if (this.loyaltyDetails['transactions'].length != 0) {
        this.page += 1;
        this.getLoyaltyDetails(); // get next page loyalty transaction details
      }
    }
  }

  addPointsToCustomer() {
    const dialogref = this.dialog.open(
      ConfirmationDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "380px",
        data: {
          subject: `AWARD POINTS CONFIRMATION`,
          message: `Are you sure you want to reward ${this.customerDetails['customerName']} with ${this.coinsToGive} reward point(s) ?`,
          sampleComment: 'Coins added by Support Centre user',
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          commentSuccessButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
        }
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      let selectedIdsForRewardCoin = [
        {
          id: this.customerDetails['merchantCustomerId'],
          idType: "customer"
        }
      ]
      if (result) {
        this.allCustomersListViewDataLoading = true;
        let data = {
          userId: this.tokenStorage.getUserId(),
          rewardCoins : this.coinsToGive,
          idList : selectedIdsForRewardCoin,
          comment: result.comment
        }
        this.apiCall.giveRewardCoins(data).subscribe(
          response=>{
            if(response['body'] === 'done') {
              const dialogRef = this.dialog.open(SuccessDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "30%",
                data: {
                  message: "Points Added Successfully",
                  buttonText: "Ok",
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                  this.loyaltyDetails = [];
                  this.loyaltyTransactionDetails = [];
                  this.coinsToGive = 0;
                  this.page = 1;
                  this.size = 10;
                  this.getLoyaltyDetails();
              });
            }
          },
          error=>{
            this.allCustomersListViewDataLoading = false;
                console.log(error);
                if(error.status === 403){
                  const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                    panelClass: "no-padding-dialog-popup",
                    width: "380px",
                    height: "auto",
                    disableClose: true,
                    // this.dialogRef.componentInstance.data : "You will be logged out!"
                    data: { header: "Permission Denied", body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance." },
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                    // this.submitted = false;
                  });
                }
                else{
                  this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
                }
          }
        );
      }
    });
}

burnCustomerPoints(){

  const dialogref = this.dialog.open(
    ConfirmationDialogBoxComponent,
    {
      panelClass: "no-padding-dialog-popup",
      width: "380px",
      data: {
        subject: `DEDUCT POINTS CONFIRMATION`,
        message: `Are you sure you want to deduct ${this.customerDetails['customerName']} with ${this.coinsToGive} reward point(s) ?`,
        sampleComment: 'Points deducted by Support Centre user',
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        commentSuccessButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
      }
    }
  );
  dialogref.afterClosed().subscribe((result) => {
    let selectedIdsForRewardCoin = [
      {
        id: this.customerDetails['merchantCustomerId'],
        idType: "customer"
      }
    ]
    if (result) {
      let data = null;
      if(this.loyaltyDetails['stats'].availablePoints >= this.coinsToGive){
        this.allCustomersListViewDataLoading = true;
        data = {
          userId: this.tokenStorage.getUserId(),
          rewardCoins : this.coinsToGive,
          comment : result.comment,
          idList : selectedIdsForRewardCoin,
          forceBurnPoints: false,
          burnExistingPoints: false
        }
        this.apiCall.burnRewardCoins(data).subscribe(
          response=>{
            if(response) {
              const dialogRef = this.dialog.open(SuccessDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "30%",
                data: {
                  message: "Points Deducted Successfully",
                  buttonText: "Ok",
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                  this.loyaltyDetails = [];
                  this.loyaltyTransactionDetails = [];
                  this.coinsToGive = 0;
                  this.page = 1;
                  this.size = 10;
                  this.getLoyaltyDetails();
              });
            }
          },
          error=>{
            this.allCustomersListViewDataLoading = false;
            console.log(error);
            if(error.status === 403){
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "380px",
                height: "auto",
                disableClose: true,
                // this.dialogRef.componentInstance.data : "You will be logged out!"
                data: { header: "Permission Denied", body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance." },
              });
              dialogRef.afterClosed().subscribe((result) => {
                // this.submitted = false;
              });
            }
            else{
              this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
            }
          }
        );
      }
      else{
        const dialogrefCoin = this.dialog.open(
          ConfirmationDialogBoxComponent,
          {
            panelClass: "no-padding-dialog-popup",
            width: "380px",
            data: {
              subject: `DEDUCT POINTS`,
              message: `Can't deduct points as the customer doesn't have enough balance.`,
              cancelButtonText: 'Cancel',
              successButtonText: 'Deduct Existing',
            }
          }
        );
        dialogrefCoin.afterClosed().subscribe((res)=>{
          if(res){
            this.allCustomersListViewDataLoading = true;
            data = {
              userId: this.tokenStorage.getUserId(),
              rewardCoins : this.coinsToGive,
              idList : selectedIdsForRewardCoin,
              comment : result.comment,
              forceBurnPoints: false,
              burnExistingPoints: true
            }
          // else{
          //   data = {
          //     userId: this.tokenStorage.getUserId(),
          //     rewardCoins : this.coinsToGive,
          //     idList : selectedIdsForRewardCoin,
          //     forceBurnPoints: false,
          //     burnExistingPoints: true
          //   }
          // }
          this.apiCall.burnRewardCoins(data).subscribe(
            response=>{
              if(response) {
                const dialogRef = this.dialog.open(SuccessDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  width: "30%",
                  data: {
                    message: "Points Deducted Successfully",
                    buttonText: "Ok",
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                    this.loyaltyDetails = [];
                    this.loyaltyTransactionDetails = [];
                    this.coinsToGive = 0;
                    this.page = 1;
                    this.size = 10;
                    this.getLoyaltyDetails();
                });
              }
            },
            error=>{
              this.allCustomersListViewDataLoading = false;
                console.log(error);
                if(error.status === 403){
                  const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                    panelClass: "no-padding-dialog-popup",
                    width: "380px",
                    height: "auto",
                    disableClose: true,
                    // this.dialogRef.componentInstance.data : "You will be logged out!"
                    data: { header: "Permission Denied", body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance." },
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                    // this.submitted = false;
                  });
                }
                else{
                  this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
                }
            }
          );
          }
        });
      }
    }
  });
}

  getLoyaltyDetailsApi(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerLoyatyDetails(this.customerDetails['merchantCustomerId'], this.page, this.size).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  addMoneyToWallet(){
    const dialogref = this.dialog.open(
      ConfirmationDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "380px",
        data: {
          subject: `ADD MONEY CONFIRMATION`,
          message: `Are you sure you want to add money to ${this.customerDetails['customerName']} with ${this.currencySymbol} ${this.coinsToGive} ?`,
          sampleComment: 'Amount added by Support Centre user',
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          commentSuccessButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
        }
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      // let selectedIdsForRewardCoin = [
      //   {
      //     id: this.customerDetails['merchantCustomerId'],
      //     idType: "customer"
      //   }
      // ]
      if (result) {
        this.allCustomersListViewDataLoading = true;
        let data = {
          "customerId": this.customerDetails['merchantCustomerId'],
          "walletId": this.customerWalletId,
          "amountPaid": this.coinsToGive,
          "amountDeduct": null,
          "isRefund": false,
          "type": "CREDIT",
          "eventTransactionId": null,
          "transactionReverseId": null,
          "createdTimestamp": null,
          "userId": this.tokenStorage.getUserId(),
          "comments": result.comment
        }
        this.addMoneyToWalletApi(data).then((response) => {
          if(response['body']) {
            const dialogRef = this.dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "30%",
              data: {
                message: "Money Added Successfully",
                buttonText: "Ok",
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
               this.walletTransactionDetails = [];
               this.coinsToGive = 0;
               this.allCustomersListViewDataLoading =true;
               this.getWalletDetails();
               this.getWalletHistoryDetails();
            });
          }
        },(error)=>{
          this.allCustomersListViewDataLoading = false;
          console.log(error);
          if(error.status === 403){
            const dialogRef = this.dialog.open(ContactUsDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "380px",
              height: "auto",
              disableClose: true,
              // this.dialogRef.componentInstance.data : "You will be logged out!"
              data: { header: "Permission Denied", body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance." },
            });
            dialogRef.afterClosed().subscribe((result) => {
              // this.submitted = false;
            });
          }
          else{
            this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
          }
        });

      }
    });
  }

  getCustomerWalletId(){
    this.getCustomerWallteIdApi(this.customerDetails['merchantCustomerId']).then((response) => {
      if(response['body']) {
        this.customerWalletId = response['body'];
        this.getWalletDetails();
        this.getWalletHistoryDetails();
      }
    },(error)=>{
      this.customerWalletId = null;
      this.allCustomersListViewDataLoading = false;
      //this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
    });
  }

  deductMoneyFromWallet(){
    const dialogref = this.dialog.open(
      ConfirmationDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "380px",
        data: {
          subject: `DEDUCT MONEY CONFIRMATION`,
          message: `Are you sure you want to deduct ${this.currencySymbol} ${this.coinsToGive} from ${this.customerDetails['customerName']}?`,
          sampleComment: 'Amount deducted by Support Centre user',
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          commentSuccessButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
        }
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      // let selectedIdsForRewardCoin = [
      //   {
      //     id: this.customerDetails['merchantCustomerId'],
      //     idType: "customer"
      //   }
      // ]
      if (result) {
        if(this.balanceAmtInWallet >= this.coinsToGive){
          this.allCustomersListViewDataLoading = true;
          let data = {
            "customerId": this.customerDetails['merchantCustomerId'],
            "walletId": this.customerWalletId,
            "amountPaid": null,
            "amountDeduct": this.coinsToGive,
            "isRefund": false,
            "type": "DEBIT",
            "eventTransactionId": null,
            "transactionReverseId": null,
            "createdTimestamp": null,
            "userId": this.tokenStorage.getUserId(),
            "comments": result.comment
        }
          this.deductMoneyFromWalletApi(data).then((response) => {
            if(response['body']) {
              const dialogRef = this.dialog.open(SuccessDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "30%",
                data: {
                  message: "Money Deducted Successfully",
                  buttonText: "Ok",
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                 this.walletTransactionDetails = [];
                 this.coinsToGive = 0;
                 this.allCustomersListViewDataLoading =true;
                 this.getWalletDetails();
                 this.getWalletHistoryDetails();
              });
            }
          },(error)=>{
            this.allCustomersListViewDataLoading = false;
            console.log(error);
            if(error.status === 403){
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "380px",
                height: "auto",
                disableClose: true,
                // this.dialogRef.componentInstance.data : "You will be logged out!"
                data: { header: "Permission Denied", body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance." },
              });
              dialogRef.afterClosed().subscribe((result) => {
                // this.submitted = false;
              });
            }
            else{
              this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
            }
          });
        }
        else{
          const dialogrefCoin = this.dialog.open(
            ConfirmationDialogBoxComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "380px",
              data: {
                subject: `DEDUCT MONEY`,
                message: `Can't deduct money as the customer doesn't have enough balance.`,
                cancelButtonText: 'Cancel',
                successButtonText: 'Deduct Existing',
              }
            }
          );
          dialogrefCoin.afterClosed().subscribe((res)=>{
            if(res){
              this.allCustomersListViewDataLoading = true;
              let data = {
                "customerId": this.customerDetails['merchantCustomerId'],
                "walletId": this.customerWalletId,
                "amountPaid": null,
                "amountDeduct": this.balanceAmtInWallet,
                "isRefund": false,
                "type": "DEBIT",
                "eventTransactionId": null,
                "transactionReverseId": null,
                "createdTimestamp": null,
                "comments": result.comment
            }
              this.deductMoneyFromWalletApi(data).then((response) => {
                if(response['body']) {
                  const dialogRef = this.dialog.open(SuccessDialogComponent, {
                    panelClass: "no-padding-dialog-popup",
                    width: "30%",
                    data: {
                      message: "Money Deducted Successfully",
                      buttonText: "Ok",
                    },
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                     this.walletTransactionDetails = [];
                     this.coinsToGive = 0;
                     this.allCustomersListViewDataLoading =true;
                     this.getWalletDetails();
                     this.getWalletHistoryDetails();
                  });
                }
              },(error)=>{
                this.allCustomersListViewDataLoading = false;
                console.log(error);
                if(error.status === 403){
                  const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                    panelClass: "no-padding-dialog-popup",
                    width: "380px",
                    height: "auto",
                    disableClose: true,
                    // this.dialogRef.componentInstance.data : "You will be logged out!"
                    data: { header: "Permission Denied", body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance." },
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                    // this.submitted = false;
                  });
                }
                else{
                  this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),2000);
                }
              });
            }
          });
        }

      }
    });
  }
  getWalletBalanceApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getWalletBalance(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(err);
        }
      );
    });
  }

  getWalletHistoryApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getWalletHistory(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(err);
        }
      );
    });
  }

  addMoneyToWalletApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.addToWallet(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(err);
        }
      );
    });
  }

  deductMoneyFromWalletApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.deductFromWallet(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(err);
        }
      );
    });
  }

  getCustomerWallteIdApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerWalletId(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(err);
        }
      );
    });
  }

  getTransactionType(type){
    switch(type.toUpperCase()){
      case "CREDITED":
      case "CREDIT":
        return '+';
      case "DEBITED":
      case "DEBIT":
      case "EXPIRE":
      case "EXPIRED":
        return "-";
    }
  }

  getDateTime(dates, type) {
    let time;
      let date = this.datePipe.transform(
        new Date(dates.replace(' ','T')).toISOString(),
        "dd MMM yyyy"
      );
      let x = dates.split(" ");
      let y = x[1].split(":");
      if (x[1][0] + x[1][1] > 12)
        time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      else
        time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      if(type === 'date'){
        return date;
      }
      else{
        return time;
      }
      // this.searchArray[item]["strategy"]["date"] = date;
      // this.searchArray[item]["strategy"]["time"] = time;
  }

  getUTCtimedate(value){
    const date = new Date(value/1000);
    return date.toUTCString();
  }

  getAggregatedValue(value) {
    if(value){
      if (Math.abs(value) >= 1e12) {
          return (value / 1e12).toFixed(2) + 'T';
      } else if (Math.abs(value) >= 1e9) {
          return (value / 1e9).toFixed(2) + 'B';
      } else if (Math.abs(value) >= 1e6) {
          return (value / 1e6).toFixed(2) + 'M';
      } else if (Math.abs(value) >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
      } else {
          return value;
      }
    }
    return 0;
}


getTotalAmounts() {
  let totalAmountIssued = 0;
  let totalAmountRedeemed = 0;
  let totalCardsReceived = this.giftcardDetails.length;

  this.giftcardDetails.forEach(card => {
    if (card.status === 'ISSUED') {
      totalAmountIssued += card.amount;
    } else if (card.status === 'REDEEMED') {
      totalAmountRedeemed += card.amount;
    }
  });
  let totalAmountReceived = totalAmountIssued + totalAmountRedeemed;
  return { totalAmountIssued, totalAmountRedeemed, totalCardsReceived, totalAmountReceived };
}


getGiftCardDetails(){
  let data = {
    "clientName": this.tokenStorage.getStoreName(),
    "customerId": this.customerDetails['merchantCustomerId'],
    "customerNumber" : this.customerDetails['phone']
  }
  this.apiCall.getGiftCardDetails(data).subscribe((result)=>{
    if(result){
          this.giftcardDetails =  result['body']['cards'];
          this.allCustomersListViewDataLoading =false;
          const { totalAmountIssued, totalAmountRedeemed, totalCardsReceived, totalAmountReceived } = this.getTotalAmounts();
        }
  },
  (err)=>{
    this.giftcardDetails=null;
    this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error getting details", ['POS_PAGE']),2000);
    this.allCustomersListViewDataLoading =false;
    console.log(err);
  });
}

getStatusColorClass(status: string): string {
  switch (status.toUpperCase()) {
    case 'REDEEMED':
      return 'status-blue';
    case 'EXPIRED':
      return 'status-red';
    case 'ISSUED':
      return 'status-green';
    default:
      return 'status-green';
  }
}


toggleActive(card) {
  this.giftcardDetails.forEach(c => {
    if (c !== card) {
      c.isActive = false;
    }
  });
  card.isActive = !card.isActive;
}

getallOffer() {
  this.apiCall.getAllOfferList().subscribe(
    (response) => {
      if (response["message"] === "success") {
        this.offerList = (response["body"]).offers.filter(offer =>  (offer.status === "Sent" || offer.status === "Draft"));
        this.allCustomersListViewDataLoading = false;
    }
    },
    (err) => {
    this.offerList=null;
    this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching offers", ['POS_PAGE']),2000);
    this.allCustomersListViewDataLoading =false;
    console.log(err);
    }
  );
}

sendOffer(){
  let data = {
    "customerId": this.customerDetails['merchantCustomerId'],
    "offer_id": parseInt(this.selectedOffer),
  };
  let dialogReference = this.dialog.open(
    ConfirmationDialogBoxComponent,
    {
      panelClass: "no-padding-dialog-popup",
      width: "40%",
      data: {
        subject: "Confirmation",
        message:
          "Are you sure to activate the offer",
        cancelButtonText: "Cancel",
        successButtonText: "Confirm",
      },
    }

  );
  dialogReference.afterClosed().subscribe((result) => {
    if(result){
      this.apiCall.sendOffertoCustomer(data).subscribe((result)=>{
        if(result){
          console.log(result)
          const dialogRef = this.dialog.open(SuccessDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "20%",
            disableClose: true,
            data: {
              message: this._i18nDynamicTranslate.transform("Offer has been successfully created", ['POS_PAGE']),
              buttonText: this._i18nDynamicTranslate.transform("Close", ['POS_PAGE']),
            },
          });
          this.getOfferTransactionDetails();
         }
      },
       (err) => {
          let errorMessage = "Error getting details";
          if (err && err.error && err.error.body) {
            errorMessage = err.error.body;
          }
          this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform(errorMessage, ['POS_PAGE']), 2000);
          this.allCustomersListViewDataLoading = false;
          console.error(err);
        }
    );
    }
  });
}

openViewOfferSummary() {
  const dialogRefer = this.dialog.open(OfferSummaryDialogComponent, {
    panelClass: "customAttachEmail",
    width: "40%",
    maxWidth: "546px",
    maxHeight: "80vh",
    data: {
      offerId: this.selectedOffer,
      customerId: this.customerDetails['merchantCustomerId'],
    },
  });
  dialogRefer.afterClosed().subscribe((result) => {
    if (result) {
    }
  });
}

getOfferTransactionDetails(){
  this.getOfferTransactionDetailsApi().then((result) => {
    if(result){
      console.log(result);
      let data = result['body'];
      this.offerTransactionDetails = [...data];
      this.allCustomersListViewDataLoading = false;
    }
  },(reject)=>{
    // this.allCustomersListViewDataLoading = false;
    this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error getting details", ['POS_PAGE']),2000);
  });
}


getOfferTransactionDetailsApi(): Promise<object> {
  return new Promise((resolve, reject) => {
    this.apiCall.getCustomerOfferTxnDetails(this.customerDetails['merchantCustomerId']).subscribe(
      (res) => {
        console.log(res);
        resolve(res);
      },
      (err) => {
        console.log(err.error);

        reject(false);
      }
    );
  });
}




  // numberInputHandler(event,maxLength) {
  //   if (
  //     [46, 8, 9, 27, 13].includes(event.keyCode) ||
  //     // Allow: Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
  //     (event.keyCode === 65 &&
  //       (event.ctrlKey === true || event.metaKey === true)) ||
  //     (event.keyCode === 67 &&
  //       (event.ctrlKey === true || event.metaKey === true)) ||
  //     (event.keyCode === 86 &&
  //       (event.ctrlKey === true || event.metaKey === true)) ||
  //     (event.keyCode === 88 &&
  //       (event.ctrlKey === true || event.metaKey === true)) ||
  //     // Allow: home, end, left, right, down, up
  //     (event.keyCode >= 35 && event.keyCode <= 40)
  //   ) {
  //     // Let it happen, don't do anything
  //     return;
  //   }

  //   // Ensure that it's a number or a period and stop the keypress if it's not
  //   const isNumberOrPeriod =
  //     (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
  //     (event.keyCode < 96 || event.keyCode > 105) &&
  //     event.keyCode !== 190; // Allow the period (.)

  //   if (isNumberOrPeriod) {
  //     event.preventDefault();
  //   }
  //   const invalidCharacters = ['e', '-', '+'];
  //   const inputValue = (event.target as HTMLInputElement).value;
  //   if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // }
  

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }


}
