<div class="qb-heading-domain-container">
  <span class="module-heading">
    <span *ngIf="progressStage==2">New</span>
    Domains
  </span>
  <div *ngIf="progressStage==1">
    <div style="display: flex;font-size: 14px;"
      *ngIf="existingDomainCount==0&&pendingDomainCount==0&&InprogressDomainCount==0 && !isDemo">
      <button class="AddNewDomain primary-button" appButtonDebounce [throttleTime]="700"
        (throttledClick)="progressStageChange(2)" data-button-name="Add New Domain">
        <mat-icon class="button-icon" style="transform: rotate(45deg); padding-top: 2px;">close</mat-icon> <span
          style="margin:0 10px">{{'PROFILE_PAGE.ADD_NEW_DOMAIN' | i18nTranslate}}</span>
      </button>
    </div>
    <div style="display: flex;font-size: 14px; cursor: not-allowed;"
      *ngIf="existingDomainCount==0&&pendingDomainCount==0&&InprogressDomainCount==0 && isDemo">
      <button style="cursor: not-allowed;" class="AddNewDomain primary-button" appButtonDebounce [throttleTime]="700"
        data-button-name="Add New Domain">
        <mat-icon class="button-icon" style="transform: rotate(45deg); padding-top: 2px;">close</mat-icon> <span
          style="margin:0 10px">{{'PROFILE_PAGE.ADD_NEW_DOMAIN' | i18nTranslate}}</span>
      </button>
    </div>

    <div style="display: flex;font-size: 14px;"
      *ngIf="(existingDomainCount>0||pendingDomainCount>0||InprogressDomainCount>0)">
      <button class="AddNewDomain primary-button" appButtonDebounce [throttleTime]="700"
        (throttledClick)="progressStageChange(2)" data-button-name="Add New Domain">
        <mat-icon class="button-icon" style="transform: rotate(45deg); padding-top: 2px;">close</mat-icon> <span
          style="margin:0 10px">{{'PROFILE_PAGE.ADD_DOMAIN' | i18nTranslate}}</span>
      </button>
    </div>
  </div>
</div>

<div data-widget-name="DOMAINS" class="qb-main-container-domain">

  <!-- <mat-spinner diameter="36" color='primary'></mat-spinner> -->
  <div *ngIf="progressStage==1">
    <p style="padding: 2% 2% 0 2% ;font-size: 14px">{{'PROFILE_PAGE.DEDICATE_DOMAIN_MESSAGE' | i18nTranslate}} </p>
    <p style="padding: 0 2% 0 2% ;font-size: 14px">{{'PROFILE_PAGE.DEDICATE_DOMAIN_SUB_MESSAGE' | i18nTranslate}}<strong>"via mail.qubriux.com"</strong> {{'PROFILE_PAGE.DEDICATEDOMAIN_SUBMESSAGE_PREFIX' | i18nTranslate}}.</p>
    <p style="padding: 0 2% 0 2% ;font-size: 14px"><strong>{{'PROFILE_PAGE.DOMAIN_VERIFCATION_HEADING' | i18nTranslate}}</strong></p>
    <p class="domain-documentation">{{'PROFILE_PAGE.DOMAIN_VERIFICATION_SUB1' | i18nTranslate}} <a href="https://help.mailgun.com/hc/en-us/articles/360026833053-Domain-Verification-Setup-Guide?_gl=1%2A1whcqos%2A_ga%2AMjgxODE3MDcxLjE3MTAyMjM5ODM.%2A_ga_PZB2JSMCGC%2AMTcxODE4NTUzOC4zOC4wLjE3MTgxODU1MzguMC4wLjA." target="_blank" class="links">Click here</a></p>
    <p class="domain-documentation">{{'PROFILE_PAGE.DOMAIN_VERIFICATION_SUB2' | i18nTranslate}} <a href="https://www.mailgun.com/resources/videos/getting-started-with-mailgun/?_gl=1*9yxfi9*_ga*MjgxODE3MDcxLjE3MTAyMjM5ODM.*_ga_PZB2JSMCGC*MTcxODE4NTUzOC4zOC4xLjE3MTgxODU1NjAuMC4wLjA." target="_blank" class="links">Click here</a> </p>
    <p style="font-size: 14px; margin-bottom: 3px;padding: 1% 2% 0 2%;"><strong>{{'PROFILE_PAGE.DOMAIN_PROVIDERS' | i18nTranslate}} </strong></p>
    <div class="provider-links">
      <a href="https://help.mailgun.com/hc/en-us/articles/15353034939931-GoDaddy-DNS-Setup-Guide?_gl=1*n1gc7o*_ga*MjgxODE3MDcxLjE3MTAyMjM5ODM.*_ga_PZB2JSMCGC*MTcxODE4NTUzOC4zOC4xLjE3MTgxODU1NjAuMC4wLjA." target="_blank">GoDaddy </a>
      <a href="https://help.mailgun.com/hc/en-us/articles/15585722150299-Cloudflare-DNS-Setup-Guide?_gl=1*n1gc7o*_ga*MjgxODE3MDcxLjE3MTAyMjM5ODM.*_ga_PZB2JSMCGC*MTcxODE4NTUzOC4zOC4xLjE3MTgxODU1NjAuMC4wLjA." target="_blank">Cloudflare</a>
      <a href="https://help.mailgun.com/hc/en-us/articles/15823826021275-Namecheap-DNS-Setup-Guide?_gl=1*1my7e7g*_ga*MjgxODE3MDcxLjE3MTAyMjM5ODM.*_ga_PZB2JSMCGC*MTcxODE4NTUzOC4zOC4xLjE3MTgxODU4MDAuMC4wLjA." target="_blank">Namecheap</a>
      <a href="https://help.mailgun.com/hc/en-us/articles/15351999098523-Google-Domains-DNS-Setup-Guide?_gl=1*1my7e7g*_ga*MjgxODE3MDcxLjE3MTAyMjM5ODM.*_ga_PZB2JSMCGC*MTcxODE4NTUzOC4zOC4xLjE3MTgxODU4MDAuMC4wLjA." target="_blank">Google Domains</a>
      <a href="https://help.mailgun.com/hc/en-us/articles/14707808393499-DigitalOcean-DNS-Setup-Guide?_gl=1*1my7e7g*_ga*MjgxODE3MDcxLjE3MTAyMjM5ODM.*_ga_PZB2JSMCGC*MTcxODE4NTUzOC4zOC4xLjE3MTgxODU4MDAuMC4wLjA." target="_blank">Digital Ocean</a>
    </div>
   
    <div *ngIf="allDomainApiloaded">
      <hr *ngIf="existingDomainCount>0||pendingDomainCount>0||InprogressDomainCount>0">

      <div *ngFor="let domain of verifiedDomainList;let i=index" style="padding: 0 2%;">
        <div *ngIf="domain['state']!='unverified'">
          <div *ngIf="i!=0">
            <hr>
          </div>
          <div style="justify-content: space-between;display: flex;width: 100%;" class="domainName"
            [ngClass]="{'active' : domain['isActive']}">
            <div style="width: 50%; gap: 10px; align-items: center" class="qb-d-flex">
              <div style="display: flex; align-items: center"><span style="width: 50px; align-items: center" class="qb-d-flex">
                  <mat-icon>language</mat-icon>
                </span> <span> {{domain['domainName']}}</span></div>
                <div class="subdomainText">
                  |
                </div>
              <div style="display: flex;">
                <span class="subdomainText">{{domain['subDomainName']}}</span>
              </div>
            </div>
            <div style="display: flex;align-items: center;">
              <div>
                <div *ngIf="domain['isActive']" class="activeText">{{'PROFILE_PAGE.ACTIVE' | i18nTranslate}}</div>
                <div *ngIf="!domain['isActive']&&indexVAct!=i" class="activateRectangle" appButtonDebounce
                  [throttleTime]="1000"
                  (throttledClick)="updateDomain(domain['domainName'],domain['subDomainName']);indexVAct=i;" style="    height: 23px;
                  width: 155px;
                  display: flex;
                  padding: 5px;
                  align-items: center;
                  justify-content: center;" data-button-name="Activate Domain"
                  [attr.data-item-name]="domain['domainName']">{{'PROFILE_PAGE.ACTIVATE' | i18nTranslate}}</div>
                <div *ngIf="!domain['isActive']&&indexVAct==i" class="activateRectangle" style="    height: 23px;
                width: 155px;
                display: flex;
                align-items: center;
                justify-content: center;">
                  <mat-spinner diameter="15"></mat-spinner>
                </div>
              </div>
              <!-- <mat-icon *ngIf="indexV!=i" class="deleteIcon" appButtonDebounce [throttleTime]="1000"
                (throttledClick)="deleteDomain(domain['domainName'],domain['subDomainName']);indexV=i;"
                data-button-name="Delete Domain">delete
              </mat-icon> -->
              <span class="material-icons icons-red" *ngIf="indexV!=i" appButtonDebounce [throttleTime]="1000"
              (throttledClick)="deleteDomain(domain['domainName'],domain['subDomainName']);indexV=i;"
              data-button-name="Delete Domain">delete</span>
              <div *ngIf="indexV==i">
                <mat-spinner diameter="15"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="pendingDomainCount>0" style="padding: 0 2%;">
        <hr>
      </div> -->
      <div *ngFor="let domain of InProgressDomainList;let i=index" style="padding: 0 2%;">
        <div>
          <div *ngIf="i!=0||existingDomainCount>0">
            <hr>
          </div>
          <div style="justify-content: space-between;display: flex;width: 100%; " class="domainName">
            <div style="width: 50%; gap: 10px; align-items: center;" class="qb-d-flex">
              <div style="display: flex; align-items: center"><span style="width: 50px; align-items: center" class="qb-d-flex">
                  <mat-icon>language</mat-icon>
                </span> <span style="
                display: flex;
                overflow-wrap: anywhere;"> {{domain['domainName']}}</span></div>
                  <div class="subdomainText">
                    |
                  </div>
              <div style="display: flex;">
                <span class="subdomainText" style="
                display: flex;
                overflow-wrap: anywhere;"> {{domain['subDomainName']}}</span>
              </div>
            </div>
            <div class="qb-domain-status-container">
              <div data-button-name="In Progress" [attr.data-item-name]="domain['domainName']">
                <div class="activateRectangle" style="    height: 26px;
                width: 182px;
                display: flex;
                align-items: end;
                justify-content: center;
                cursor: not-allowed;">{{'PROFILE_PAGE.IN_PROGRESS' | i18nTranslate}}</div>
              </div>
              <span *ngIf="indexProg!=i " class="material-icons icons-red" appButtonDebounce [throttleTime]="1000"
                (throttledClick)="deleteDomain(domain['domainName'],domain['subDomainName'],i);indexProg=i;"
                data-button-name="Delete Domain" style="cursor: pointer;">delete</span>

              <div *ngIf="indexProg==i" style="padding: 2px 0 4px 20px;">
                <mat-spinner diameter="15"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let domain of unverifiedDomainList;let i=index" style="padding: 0 2%;">
        <div>
          <div *ngIf="i!=0||existingDomainCount>0||InprogressDomainCount>0">
            <hr>
          </div>
          <div style="justify-content: space-between;display: flex;width: 100%;" class="domainName"
            [ngClass]="{'active' : domain['isActive']}">
            <div style="width: 50%; gap: 10px; align-items: center" class="qb-d-flex">
              <div style="display: flex; align-items: center"><span style="width: 50px; align-items: center" class="qb-d-flex">
                  <mat-icon>language</mat-icon>
                </span> <span style="
                display: flex;
                overflow-wrap: anywhere;"> {{domain['domainName']}}</span></div>
                  <div class="subdomainText">
                    |
                  </div>
              <div style="display: flex;">
                <span class="subdomainText" style="
                display: flex;
                overflow-wrap: anywhere;"> {{domain['subDomainName']}}</span>
              </div>
            </div>
            <div class="qb-domain-status-container">
              <div (click)="continueVerification(domain['domainName'],domain['subDomainName'])"
                data-button-name="Continue Verification" [attr.data-item-name]="domain['domainName']">
                <div class="secondary-button-border" style=" 
                width: 182px;
                display: flex;
                align-items: end;
                justify-content: center;">{{'PROFILE_PAGE.CONTINUE_VERIFICATION' | i18nTranslate}}</div>
              </div>
              <span *ngIf="indexU!=i" class="material-icons icons-red" appButtonDebounce [throttleTime]="1000"
                (throttledClick)="deleteDomain(domain['domainName'],domain['subDomainName'],i);indexU=i;"
                data-button-name="Delete Domain" style="cursor: pointer;">delete</span>

              <div *ngIf="indexU==i" style="padding: 2px 0 4px 20px;">
                <mat-spinner diameter="15"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div *ngIf="!allDomainApiloaded">
      <div style="display: flex;width: 100%;justify-content: center;align-items: center;height: 100%;">
        <mat-spinner diameter="22"></mat-spinner>
      </div>
    </div>
  </div>
  <div *ngIf="progressStage==2">
    <div class="NewDomainRectangle">{{'PROFILE_PAGE.NEW_DOMAIN' | i18nTranslate}}</div>
    <div>
      <p class="newDomainPara">
        {{'PROFILE_PAGE.NEW_DOMAIN_MESSAGE' | i18nTranslate}}
        <!-- For
        more information see our <a href="https://qubriux.skellam.ai/knowledge-hub/profile#Domains" target="_blank">help page</a>. -->
      </p>
    </div>
    <div style="padding: 0 2% 2% 2%;">
      <form [formGroup]="addDomainForm" (ngSubmit)="handleKeyEnter($event)">
        <div>
          <div class="formLabel">{{'PROFILE_PAGE.DOMAIN' | i18nTranslate}}<div class="i-div" style="    margin: -1px 2px 0 6px;
            " type="button" [ngbPopover]="popContent" triggers="mouseenter:mouseleave">
              <ng-template #popContent>
                <div style="border-radius: 4px; margin: -9px -12px; padding: 10px;
                font-size: 12px;">
                  {{'PROFILE_PAGE.DOMAIN_INFO_MESSAGE' | i18nTranslate}} <strong>mystore.com </strong>.
                </div>
              </ng-template>
              <div class="i">i</div>
            </div>
          </div>
          <div>
            <div class="domainRect">
              <input class="formInput" formControlName="domainName">
            </div>
            <div style="height: 20px;">
              <div style="font-size: 12px;width: 33%;color: #f44336;"
                *ngIf="addDomainForm.controls.domainName.dirty&& addDomainForm.controls.domainName.hasError('pattern')">
                {{'PROFILE_PAGE.ENTER_VALID_DOMAIN_NAME' | i18nTranslate}}</div>
              <div style="font-size: 12px;width: 33%;color: #f44336;"
                *ngIf="addDomainForm.controls.domainName.dirty&& addDomainForm.controls.domainName.hasError('required')">
                {{'PROFILE_PAGE.FIELD_CANNOT_BE_EMPTY' | i18nTranslate}}</div>
              <div style="font-size: 12px;width: 33%;color: #f44336;"
                *ngIf="addDomainForm.controls.domainName.dirty&& addDomainForm.controls.domainName.errors?.maxlength">
                {{'PROFILE_PAGE.MAX_CHARACTER_REACHED' | i18nTranslate}}</div>
            </div>
          </div>

        </div>
        <div style="margin-bottom: 10px;">
          <mat-checkbox color="primary" formControlName="checked" (change)="showcheckboxOptions($event)"
            data-button-name="Use Domain as Sending domain"
            [attr.data-item-name]="addDomainForm.controls.checked.value">{{'PROFILE_PAGE.USE_DOMAIN_MESSAGE' | i18nTranslate}}</mat-checkbox>
        </div>
        <div>
          <div class="formLabel">{{'PROFILE_PAGE.SENDING_DOMAIN' | i18nTranslate}}
            <div class="i-div" style="    margin: -1px 2px 0 6px;
            " type="button" [ngbPopover]="popContent" triggers="mouseenter:mouseleave">
              <ng-template #popContent>
                <div style="border-radius: 4px; margin: -9px -12px; padding: 10px;
                font-size: 12px;">
                  {{'PROFILE_PAGE.SENDING_DOMAIN_INFO_MESSAGE' | i18nTranslate}} <strong>mail.mystore.com</strong>.
                </div>
              </ng-template>
              <div class="i">i</div>
            </div>
          </div>
          <div>
            <div style=" display: flex;align-items: center;">
              <div class="domainRect" *ngIf="!addDomainForm.controls.checked.value">
                <input class="formInput" formControlName="subdomainName">
              </div>
              <div class="domainRectDisabled" *ngIf="addDomainForm.controls.checked.value">
                <div class="formInput" style="color: #3a3a3a;">{{addDomainForm.controls.domainName.value}}</div>
              </div>

              <div style="margin-left: 15px; overflow: auto; width: 75%;"
                *ngIf="!addDomainForm.controls.checked.value&&addDomainForm.controls.domainName.value.length>0">
                <span style="color: #3a3a3a;">.{{addDomainForm.controls.domainName.value}}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; width: 33%;">
              <div class="max-char-text">{{'PROFILE_PAGE.MAX_CHARACTER_ALLOWED_253' | i18nTranslate}}</div>
            <div style="height: 20px;">
              <div style="font-size: 12px;width: 100%;color: #f44336;"
                *ngIf="addDomainForm.controls.subdomainName.dirty&& addDomainForm.controls.subdomainName.hasError('pattern')">
                {{'PROFILE_PAGE.ENTER_VALID_SENDIND_DOMAIN' | i18nTranslate}}</div>
              <div style="font-size: 12px;width: 100%;color: #f44336;"
                *ngIf="addDomainForm.controls.subdomainName.dirty&& addDomainForm.controls.subdomainName.hasError('required')">
                {{'PROFILE_PAGE.FIELD_CANNOT_BE_EMPTY' | i18nTranslate}}</div>
              <div style="font-size: 12px;width: 100%;color: #f44336;"
                *ngIf="addDomainForm.controls.subdomainName.dirty&& addDomainForm.controls.subdomainName.errors?.maxlength">
                {{'PROFILE_PAGE.MAX_CHARACTER_REACHED' | i18nTranslate}}</div>

              </div>

            </div>

          </div>

        </div>

      </form>
    </div>


    <div style=" padding: 0 2% 2% 2%;display:flex">
      <button class="secondary-button-border" appButtonDebounce [throttleTime]="1000"
        [ngStyle]="{'cursor': addDomainLoaded ?  'pointer' : 'not-allowed'}"
        (throttledClick)="addDomainLoaded? exitProgress() : ''" data-button-name="Delete Domain">{{'PROFILE_PAGE.CANCEL' | i18nTranslate}}</button>
      <button class="ButtonActive primary-button" style="margin-left: 20px;" appButtonDebounce [throttleTime]="1000"
        (throttledClick)="progressStageChange(3)" *ngIf="addDomainLoaded"
        data-button-name="Save Domain and Continue">{{'PROFILE_PAGE.SAVE_AND_CONTINUE' | i18nTranslate}}</button>
      <button *ngIf="!addDomainLoaded" class="ButtonActive primary-button" style="width: 166.5px; margin-left: 20px;">
        <div style="display: flex;justify-content: center;align-items: center;width: 100%;" class="domainScreen">
          <mat-spinner diameter="15"></mat-spinner>
        </div>

      </button>
    </div>


  </div>

  <div *ngIf="progressStage==3">
    <div class="NewDomainRectangle">{{'PROFILE_PAGE.NEW_DOMAIN' | i18nTranslate}}</div>
    <div class="newDomainPara">
      <p>{{'PROFILE_PAGE.DOMAIN_CREATION_PARAGRAPH' | i18nTranslate}}
      </p>
      <ol class="tactics">
        <li>
          <div>{{'PROFILE_PAGE.DOMAIN_CREATION_POINT_1' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'PROFILE_PAGE.DOMAIN_CREATION_POINT_2' | i18nTranslate}}</div>
        </li>
        <li>
          <div>{{'PROFILE_PAGE.DOMAIN_CREATION_POINT_3' | i18nTranslate}}</div>
        </li>
      </ol>
    </div>
    <!-- Login to the
    DNS provider that you
    use to manage mystore.com and add the following DNS records. Copy the values of these five fields mentioned below
    and update in your DNS records. Once your DNS records are updated, click on the verify button. -->
    <div *ngIf="verifiyDomainLoaded">
      <div *ngIf="domainRecordsFound">
        <div class="newDomainPara">
          <div style="    margin-bottom: 24px;">

            <div class="subdomainText" style="font-size:11px;">{{'PROFILE_PAGE.DOMAIN' | i18nTranslate}}</div>
            <div>{{domain}}</div>
          </div>

          <div>

            <div class="subdomainText" style="font-size:11px;">{{'PROFILE_PAGE.SENDING_DOMAIN' | i18nTranslate}}</div>
            <div>{{subdomain}}</div>
          </div>
        </div>


        <div>
          <div class="NewDomainRectangle" style="padding: 1% 2% 0 2%;
          text-decoration: underline;">{{'PROFILE_PAGE.RECORDS_CAPS' | i18nTranslate}}</div>
        </div>


        <div style="justify-content: space-between;display: flex;width: 80%; padding: 3% 2% 0 2%;font-weight: 600;">
          <div style="width: 15%;" class="typeBox headerBox">{{'PROFILE_PAGE.TYPE' | i18nTranslate}}</div>
          <div style="width: 35%;" class="typeBox headerBox">{{'PROFILE_PAGE.HOST_NAME' | i18nTranslate}}</div>
          <div style="width: 35%;" class="valueBox headerBox">
            <div>{{'PROFILE_PAGE.VALUE' | i18nTranslate}}</div>
          </div>
          <div style="width: 15%;text-align: center;" class="typeBox"></div>



        </div>
        <hr>
        <!-- <div>Sending records</div> -->

        <!-- <div style="display: flex;width: 100%;">
      <div style="width: 20%;">Type</div>
      <div style="width: 40%;">Hostname</div>
      <div style="width: 40%;">
        <div>Value</div>
      </div> -->


        <div style="justify-content: space-between;width: 80%; padding: 0% 2% 0 2%;min-height: 70px;">


          <div *ngFor="let record of sendingDNSRecord">
            <div style="display: flex;width: 100%;">
              <div style="width: 15%;" class="typeBox"> <span>{{record['record_type']}}</span> </div>
              <div style="width: 35%;overflow-wrap: anywhere;" class="typeBox"><span class="dottedValues controlText"
[matTooltip]="'COMMON_PAGE.COPT_TO_CLIPBOARD' | i18nTranslate" (click)="copyMessage(record['name'])">{{record['name']}}</span></div>
              <div
                style="width: 35%;display: flex;justify-content: space-between;align-items: center; padding-left: 12px;">
                <span class="dottedValues controlText" matTooltip="Copy to clipboard"
                  (click)="copyMessage(record['value'])">{{record['value']}}</span>
                <!-- <div style="width: 10%;" (click)="copyMessage(record['value'])" ngbPopover="Copied" [closeDelay]="2000">
                <mat-icon>file_copy</mat-icon>
              </div> -->
              </div>
              <div style="width: 15%;text-align: center;" class="typeBox"></div>
            </div>
          </div>
          <!-- <div *ngFor="let record of recievingDNSRecod">
      <div style="display: flex;width: 100%;">
        <div style="width: 20%;">{{record['record_type']}}</div>
        <div style="width: 40%;">{{record['name']}}</div>
        <div style="width: 40%;">
          <div>{{record['value']}}</div>
        </div>
      </div>
    </div> -->
        </div>
        <!-- <hr> -->

        <div
          style="justify-content: space-between;display: flex;width: 80%; padding: 3% 2% 0 2%;font-weight: 600;min-height: 70px;">

          <div style="display: flex;width: 100%;">
            <div style="width: 15%;" class="typeBox headerBox">{{'PROFILE_PAGE.TYPE' | i18nTranslate}}</div>
            <div style="width: 35%; " class="typeBox headerBox">{{'PROFILE_PAGE.HOST_NAME' | i18nTranslate}}</div>

            <div style="width: 35%;" class="valueBox headerBox">
              <div>{{'PROFILE_PAGE.VALUE' | i18nTranslate}}</div>
            </div>
            <div style="width: 15%;text-align: center;" class="typeBox headerBox">{{'PROFILE_PAGE.PRIORITY' | i18nTranslate}}</div>
          </div>
        </div>
        <hr>
        <div style="justify-content: space-between;width: 80%; padding: 0% 2% 0 2%;;">

          <div *ngFor="let record of recievingDNSRecod">
            <div style="display: flex;width: 100%;padding-bottom: 2%;">
              <div style="width: 15%;" class="typeBox"><span>{{record['record_type']}}</span></div>
              <div style="width: 35%;overflow-wrap: anywhere;" class="typeBox">
                <span class="dottedValues controlText" [matTooltip]="'COMMON_PAGE.COPT_TO_CLIPBOARD' | i18nTranslate" 
                  (click)="copyMessage(subdomain)">{{subdomain}}</span>
              </div>
              <div
                style="width: 35%;display: flex;justify-content: space-between;align-items: center; padding-left: 12px;">
                <span class="dottedValues controlText" [matTooltip]="'COMMON_PAGE.COPT_TO_CLIPBOARD' | i18nTranslate" 
                  (click)="copyMessage(record['value'])">{{record['value']}}</span>
                <!-- <div style="width: 10%;" (click)="copyMessage(record['value'])" ngbPopover="Copied!" [closeDelay]="2000">
                <mat-icon>file_copy</mat-icon>
              </div> -->
              </div>
              <div style="width: 15%;text-align: center;" class="typeBox"><span class="dottedValues controlText"
                [matTooltip]="'COMMON_PAGE.COPT_TO_CLIPBOARD' | i18nTranslate"  (click)="copyMessage(record['priority'])">{{record['priority']}}</span>
              </div>


            </div>
          </div>
        </div>


        <div style="padding: 2% 2% 2% 2%;;">
          <button appButtonDebounce [throttleTime]="1000" (throttledClick)="verifyDomain()" class="ButtonActive"
            *ngIf="verifiedStatus=='none'&&verifyApiloaded"
            style="width: 100px;height:35px; color: #97CB64; border: 1px solid #97CB64; background: transparent;"
            data-button-name="Verify">
            <span>{{'PROFILE_PAGE.VERIFY' | i18nTranslate}}</span>
          </button>
          <button appButtonDebounce [throttleTime]="1000" class="ButtonActive"
            *ngIf="(verifiedStatus=='false'|| verifiedStatus=='NotFound')&&verifyApiloaded"
            style="width: 100px;height:35px; color: #97CB64; border: 1px solid #97CB64; background: transparent; cursor: not-allowed;"
            data-button-name="Verify">
            <span>{{'PROFILE_PAGE.VERIFY' | i18nTranslate}}</span>
          </button>
          <div *ngIf="verifiedStatus=='false'&&verifyApiloaded" class="textProgress">
            {{'PROFILE_PAGE.VERIFICATION_UNDERPROCESS' | i18nTranslate}}
          </div>
          <div *ngIf="verifiedStatus=='NotFound'&&verifyApiloaded" class="textProgress" style="color: red;">
            {{'PROFILE_PAGE.DOMAIN_NOT_FOUND' | i18nTranslate}}
          </div>
          <button class="ButtonActive domainScreen" *ngIf="verifiedStatus=='none'&&!verifyApiloaded"
            style="width: 100px;height:35px;display: flex;justify-content: center;align-items: center;">

            <mat-spinner diameter="15"></mat-spinner>
          </button>

          <button *ngIf="verifiedStatus=='true'" appButtonDebounce [throttleTime]="1000"
            (throttledClick)="verifyDomain()" class="ButtonActive"
            style="width: 100px;height:35px; color: white; display: flex; justify-content: center; background: #97CB64; border: 1px solid #97CB64;"
            data-button-name="Verify">
            <div style="background: white;
          border-radius: 21px;
          width: 17px;
          height: 17px; display: flex; padding-top: 1px; margin-top: 5px; color: #97CB64;"><mat-icon
                style="font-size: 15px;">done</mat-icon> </div>
            <span style="margin-left: 10px; margin-top: 5px;">{{'PROFILE_PAGE.VERIFIED' | i18nTranslate}}</span>
          </button>

          <!-- <button *ngIf="verifiedStatus=='false'"  class="ButtonActive"
         style="width: 182px;height:35px; color: white; display: inline-flex; justify-content: space-evenly; background:
         #811620; border: 1px solid
         #811620;align-items: center; margin-right: 15px;" data-button-name="Verify">
          <div style="background: white;
          border-radius: 21px;
          width: 17px;
          height: 17px; color:
#811620;"><div style="font-size: 15px; margin-top: -2px;">!</div> </div>
          <span>Could Not Verify</span>
          </button> -->
          <!-- <button *ngIf="verifiedStatus=='false' &&verifyApiloaded" appButtonDebounce [throttleTime]="1000" (throttledClick)="verifyDomain()" class="ButtonActive"
          style="width: 100px;height:35px; color: #97CB64; border: 1px solid #97CB64; border-radius: 5px; background: transparent;" data-button-name="Verify">
          <span>Verify Again</span>
          </button>
          <button class="ButtonActive domainScreen" *ngIf="verifiedStatus=='false'&&!verifyApiloaded"
            style="padding: 0px 0px 5px 0px; width: 100px;height:35px;justify-content: center;align-items: center; border-radius: 5px; display: inline-flex;">

            <mat-spinner diameter="15"></mat-spinner>
          </button> -->
          <div *ngIf="verifiedStatus=='NotFound'" style=" font-size: 14px;color:#3a3a3a"> {{'PROFILE_PAGE.ERROR_VERIFICATION_MESSAGE' | i18nTranslate}} 
            <!-- <a
              href="https://www.skellam.ai/greyfoxhelp" target="_blank">help
              page</a>. -->
          </div>

        </div>
        <hr>
        <div style="display:flex;width: 80%; padding: 2% 2% 2% 2%;;">
          <button class="secondary-button-border" appButtonDebounce [throttleTime]="1000"
            (throttledClick)="exitProgress()" data-button-name="Cancel" [disabled]="verifyButtonClicked">{{'PROFILE_PAGE.CANCEL' | i18nTranslate}}
          </button>
          <button class="buttonDisabled"  appButtonDebounce
          [throttleTime]="1000" (throttledClick)="exitProgress()"
          style="color: var(--primary-color);
            background: transparent;
            border: 1px solid var(--primary-color); margin-left: 20px;">{{'PROFILE_PAGE.SAVE_AND_EXIT' | i18nTranslate}} </button>

          <button *ngIf="verifiedStatus=='false'||verifiedStatus=='none'" class="buttonDisabled"
            style="margin-left: 20px;cursor: not-allowed !important;">{{'PROFILE_PAGE.USE_THIS_DOMAIN' | i18nTranslate}}</button>
          <!-- <button class="ButtonActive" style="margin-left: 20px;" appButtonDebounce
            [throttleTime]="1000" (throttledClick)="exitProgress()" data-button-name="Save and Exit">{{'PROFILE_PAGE.SAVE_AND_EXIT' | i18nTranslate}} </button> -->
          <button *ngIf="verifiedStatus=='true'&&updateDomainApiLoaded" appButtonDebounce [throttleTime]="1000"
            data-button-name="Use this domain"
            (throttledClick)="updateDomain(addDomainForm.controls.domainName.value,addDomainForm.controls.subdomainName.value)"
            class="ButtonActive" style="margin-left: 20px;margin-left: 20px;
          width: 160px;
          height: 40px;
          align-items: center;
          justify-content: center;">{{'PROFILE_PAGE.USE_THIS_DOMAIN' | i18nTranslate}}</button>

          <button *ngIf="verifiedStatus=='true'&&!updateDomainApiLoaded" appButtonDebounce [throttleTime]="1000"
            class="ButtonActive domainScreen" style="margin-left: 20px;margin-left: 20px;
          width: 160px;
          height: 35px;
          align-items: center;
          justify-content: center;">
            <mat-spinner diameter="15"></mat-spinner>
          </button>



        </div>
      </div>
      <div *ngIf="!domainRecordsFound">
        <div style="display:flex;width: 100%;justify-content: center;align-items: center; padding: 2% 2% 2% 2%;;">
          <div style="padding: 10px;
  border: 1px dotted #ff3b30;
  width: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;">
            {{'PROFILE_PAGE.DOMAIN_NOT_FOUND' | i18nTranslate}}
          </div>

        </div>
        <div style="display:flex;width: 80%; padding: 2% 2% 2% 2%;;">
          <button class="secondary-button-border" appButtonDebounce [throttleTime]="1000"
            (throttledClick)="exitProgress()">Cancel
          </button>
        </div>

      </div>
    </div>

    <div *ngIf="!verifiyDomainLoaded">
      <div style="display: flex;width: 100%;justify-content: center;align-items: center;height: 100%;">
        <mat-spinner diameter="22"></mat-spinner>
      </div>

    </div>
  </div>
</div>