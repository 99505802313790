<div class="header">
  <span style="text-align: center; font-weight: bold;">{{'SHARED_PAGE.EXCLUDE_PRODUCT_LIST' | i18nTranslate}}</span>
  <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
</div>
<div style="padding: 16px;
font-size: 14px;">
  {{'SHARED_PAGE.PRODUCT_EXCLUDE_FROM_GAME' | i18nTranslate}}
</div>
<div style="
padding: 10px;
border: 1px dotted #ff3b30;
width: 100%;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
" *ngIf="excludedCategory.length === 0 && !apiloading">
  {{'SHARED_PAGE.NO_PROFUCT_EXCLUDED' | i18nTranslate}}
</div>
<mat-spinner diameter="15" style="margin-left: 45%" *ngIf="apiloading"></mat-spinner>
<div style="overflow-y: auto; height: calc(100% - 120px);" *ngIf="excludedCategory.length !== 0 && !apiloading">
  <div *ngFor="let category of excludedCategory; let i = index" style="padding: 16px;
  font-size: 14px;">
  <div style="display: flex; justify-content: space-between;" (click)="parentArrowNonCategory(i, category.id); getDataForTable($event)">
    <div>{{category.name}}</div>
    <span
    id="chevron_rotate"
    class="material-icons-outlined parent-arrow"
    
  >
    chevron_right
  </span>
  </div>
  <div
  class="variant-product-ln active"
  [id]="'variant-section-' + i"
>
  <div class="heightProductList">
    <div *ngFor="let product of CategoryProductList.get(category.id)">
      <div style="padding: 10px">
        {{ product['name'] }}
      </div>
    </div>
  </div>
</div>
  </div>
</div>
