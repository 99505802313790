import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeadsListingComponent } from './pages';
import { CustomerUploadHistoryComponent } from '../customers/pages';



const routes: Routes = [
  { path: "", redirectTo: "leads-dashboard", pathMatch: "full" },
    { 
      path: "leads-dashboard", 
      component: LeadsListingComponent, 
      data: {
        titleheading: "Leads",
        title: "Leads",
        minPath: "Basic"
      },
    },
    { 
      path: "upload-history", 
      component: CustomerUploadHistoryComponent,
      data: {
        titleheading: "Upload History",
        title: "Upload History",
        id:53
      },
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadsRoutingModule { }
