<div class="qb-main-div">
  <div class="qb-main-div-container">
    <div class="qb-main-sub-container">
      <div
        class="set-game-container qb-game-config-container"
            id="set-game-container"
      >
        <!-- Stepper UI -->
        <div>
          <div
            class="qb-gf-gamify-heading qb-game-config-text"
          >
            {{'GAMIFY_PAGE.GAME_CONFIG' | i18nTranslate}}
          </div>
          <!-- <div style="display: flex;justify-content: space-between;    align-items: flex-start;
           padding: 0 15px;margin-bottom: 40px;   ">
                    <div style="display: flex;" class="qb-gf-step-container" *ngFor="let step of gameStepsIconConfig | keyvalue ; let i=index; let l=last;">
                        <div class="qb-gf-step-block">
                            <div class="qb-gf-step-icon" [ngClass]="stepsCompleted<i+2?'gf-step-icon':'gf-step-icon-active'"
                                [ngClass]="stepsCompleted<i+2?'gf-step-icon':'gf-step-icon-active'">
                                <span *ngIf="stepsCompleted<i+2">{{step.key}}</span>
                                <mat-icon *ngIf="stepsCompleted>=i+2" class="qb-gf-step-tick">check</mat-icon>
                            </div>
                            <div>{{step.value}}</div>

                        </div>
                        <div class="qb-gf-stepper-horizontal-line" *ngIf="!l"></div>
                    </div>
                </div> -->
          <div
          class="qb-steps-block-container"
          >
            <div class="qb-gf-step-block" *ngIf="stepsCompleted != 1">
              <div
              (click)="StepsDone >= 1 ? changeSection(1) : ''"
                class="qb-gf-step-icon"
                [ngClass]="
                (StepsDone < 1 || stepsCompleted ===1) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 1">1</span>
                <mat-icon *ngIf="StepsDone >= 1" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.PRODUCT_DISCOUNT' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted == 1" >
              <div
              (click)="StepsDone >= 1 ? changeSection(1) : ''"
                class="qb-gf-step-icon"
                [ngClass]="
                (StepsDone < 1 || stepsCompleted ===1) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 2">1</span>
                <mat-icon *ngIf="StepsDone >= 2" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.PRODUCT_DISCOUNT' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-stepper-horizontal-line"></div>

            <div class="qb-gf-step-block" *ngIf="stepsCompleted == 2">
              <div
                (click)="StepsDone >= 2 ? changeSection(2) : ''"
                class="qb-gf-step-icon"
                [ngClass]="
                (StepsDone < 2 || stepsCompleted ===2) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 3">2</span>
                <mat-icon *ngIf="StepsDone >= 3" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.TARGET_AUDIENCE' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted != 2">
              <div
                (click)="StepsDone >= 2 ? changeSection(2) : ''"
                class="qb-gf-step-icon-not"
                [ngClass]="
                StepsDone < 2 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 3">2</span>
                <mat-icon *ngIf="StepsDone >= 3" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.TARGET_AUDIENCE' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-stepper-horizontal-line"></div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted ===3">
              <div
              (click)="StepsDone >= 3 ? changeSection(3) : ''"
                class="qb-gf-step-icon"
                [ngClass]="
                (StepsDone < 3 || stepsCompleted ===3) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 4">3</span>
                <mat-icon *ngIf="StepsDone >= 4" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_MESSAGE.GAME_STEPS' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted !==3">
              <div
              (click)="StepsDone >= 3 ? changeSection(3) : ''"
                class="qb-gf-step-icon-not"
                [ngClass]="
                StepsDone < 3 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 4">3</span>
                <mat-icon *ngIf="StepsDone >= 4" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.GAME_STEPS' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-stepper-horizontal-line"></div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted !=4">
              <div
                (click)="StepsDone >= 4 ? changeSection(4) : ''"
                class="qb-gf-step-icon-not"
                [ngClass]="
                StepsDone < 4 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 5">4</span>
                <mat-icon *ngIf="StepsDone >= 5" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.LAYOUT_STYLE' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted ==4">
              <div
                (click)="StepsDone >= 4 ? changeSection(4) : ''"
                class="qb-gf-step-icon"
                [ngClass]="
                (StepsDone < 4 || stepsCompleted ===4) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 5">4</span>
                <mat-icon *ngIf="StepsDone >= 5" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.LAYOUT_STYLE' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-stepper-horizontal-line"></div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted ===5">
              
              <div
              (click)="StepsDone >= 5 ? changeSection(5) : ''"
                class="qb-gf-step-icon"
                [ngClass]="
                (StepsDone < 5 || stepsCompleted ===5) ? 'qb-gf-step-icon' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 6">5</span>
                <mat-icon *ngIf="StepsDone >= 6" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.SCHEDULE' | i18nTranslate}}</div>
            </div>
            <div class="qb-gf-step-block" *ngIf="stepsCompleted !==5">
              <div
              (click)="StepsDone >= 5 ? changeSection(5) : ''"
                class="qb-gf-step-icon-not"
                [ngClass]="
                StepsDone < 5 ? 'qb-gf-step-icon-not' : 'qb-gf-step-icon-active'
                "
              >
                <span *ngIf="StepsDone < 6">5</span>
                <mat-icon *ngIf="StepsDone >= 6" class="qb-gf-step-tick"
                  >check</mat-icon
                >
              </div>
              <div>{{'GAMIFY_PAGE.SCHEDULE' | i18nTranslate}}</div>
            </div>
          </div>
        </div>

        <!-- Game configuration Content -->

        <div class="qb-discount-range-container" *ngIf="!loading" id="panelList">
          <div *ngIf="stepsCompleted == 1" class="qb-d-flex qb-w-100" id="step1">
            <div class="qb-w-100" id="panelList" >
              <!-- <div class="qb-gf-pyg-title-label">Products and Discount</div>
              <div class="qb-gf-pyg-normal-label" style="margin-bottom: 24px;">
                Select the discount and products you want to include and exclude
                from your target audience
              </div> -->
              <div class="qb-d-flex">
                <div class="qb-gf-pyg-title-label">{{'GAMIFY_PAGE.DISCOUNT_RANGE' | i18nTranslate}}</div>
                <span
                class="qb-i-div"
                popoverclass="qb-my-custom-class"
                placement="right"
                [ngbPopover]="popDiscountRange"
                triggers="mouseenter:mouseleave"
                >i
              </span>
              <ng-template #popDiscountRange>
                <div class="qb-ng-template-text">
                  {{'GAMIFY_PAGE.DISCOUNT_RANGE_INFO' | i18nTranslate}}
                </div>
              </ng-template>
              </div>
              <div class="qb-discont-range-text">{{'GAMIFY_PAGE.DISCOUNT_RANGE_MESSAGE' | i18nTranslate}}</div>
              <div class="qb-gf-pyg-normal-label qb-discont-range-container">
                <div class="qb-gf-custom-slider qb-discont-range-sub-container">
                  <ngx-slider
                    [(value)]="productAndDiscount.lowerLimit"
                    [(highValue)]="productAndDiscount.upperLimit"
                    [options]="options"
                    class="qb-ngx-slider"
                  >
                  </ngx-slider>
                </div>
                <div
                  class="qb-lower-limit-text"
                >
                  <div
                    class="qb-gf-form-control qb-lower-limit-inner-container"
                  >
                    {{ productAndDiscount.lowerLimit }}%
                  </div>
                  <div
                    class="qb-gf-form-control qb-upper-limit-inner-container"
                  >
                    {{ productAndDiscount.upperLimit }}%
                  </div>
                </div>
              </div>

              <hr />
              <div class="qb-d-flex">
                <div class="qb-w-60">
                  <div class="qb-d-flex">
                    <div class="qb-gf-pyg-title-label">
                      {{'GAMIFY_PAGE.PRODUCT_CATEGORY_EXCLUDE' | i18nTranslate}}
                    </div>
                    <span
                    class="qb-i-div"
                    popoverclass="qb-my-custom-class"
                    placement="right"
                    [ngbPopover]="popContentProduct"
                    triggers="mouseenter:mouseleave"
                    >i
                  </span>
                  <ng-template #popContentProduct>
                    <div class="qb-ng-template-text">
                      {{'GAMIFY_PAGE.PRODUCT_CATEGORY_EXCLUDE_INFO' | i18nTranslate}}
                    </div>
                  </ng-template>

                  </div>
                  <!-- <div class="qb-gf-pyg-normal-label" style="margin-bottom: 24px">
                    Select the products or categories you do not want to show on the
                    game screen.
                  </div> -->
    
                  <div class="qb-search-container">
                    <div
                     class="qb-search-inner-container"
                    >
                      <div class="qb-formDiv23 qb-w-85">
                        <input
                          class="qb-formInput qb-fontExo2"
                          [(ngModel)]="SearchCategory"
                          (keyup)="openItemDialogwithSearchData(SearchCategory)"
                          placeholder="Search category (Minimum 3 characters)"
                          type="text"
                        />
                        <span
                          class="material-icons-outlined icons-primary qb-cursor-pointer"
                        >
                          search
                        </span>
                      </div>
                      <button
                        class="qb-gf-browse-btn qb-cursor-pointer qb-ml-10"
                        (click)="openItemDialogwithoutSearchData('')"
                      >
                        {{'GAMIFY_PAGE.BROWSE' | i18nTranslate}}
                      </button>
                    </div>
                  </div>
                  <div class="qb-d-flex">
                    <div class="qb-mb-20">
                     {{'GAMIFY_PAGE.NO_EXCLUDED_PRODUCT_OUT_OF_TOTAL' | i18nTranslate : productAndDiscount.categoryProductList[0]["productIds"].length : totalProducts}}
                    </div>
                    <div class="qb-view-exclude-product-text" (click)="viewExcludedProduct()">
                      {{'GAMIFY_PAGE.VIEW_EXCLUDED_PRODUCTS' | i18nTranslate }}
                    </div>
                  </div>
                </div>
                <!-- <div style="width: 40%">
                  <div
                    class="qb-ProTip-Main"
                    style="margin-left: 30%; margin-right: 16px"
                  >
                    <div style="display: flex">
                      <div class="qb-material-icons-outlined icons-primary rotated">
                        wb_incandescent
                      </div>
                      <div
                        style="font-weight: 600; font-size: 12px; margin-left: 3px"
                      >
                        Pro Tip
                      </div>
                    </div>
                    <div
                      style="line-height: 18px; font-size: 12px; margin-top: 10px"
                    >
                    Check <strong>Select All</strong> and then deselect the 
                    products that you want to be included
                    in the game.
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="qb-gf-pyg-title-label">
                Products or categories you want to exclude
              </div>
              <div class="qb-gf-pyg-normal-label" style="margin-bottom: 24px">
                Select the products or categories you do not want to show on the
                game screen.
              </div>

              <div style="width: 70%">
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 12px;
                  "
                >
                  <div class="qb-formDiv23" style="width: 85%">
                    <input
                      class="qb-formInput fontExo2"
                      [(ngModel)]="SearchCategory"
                      (keyup)="openItemDialogwithSearchData(SearchCategory)"
                      placeholder="Search category"
                      type="text"
                    />
                    <span
                      class="qb-material-icons-outlined icons-primary cursor-pointer"
                    >
                      search
                    </span>
                  </div>
                  <button
                    class="qb-gf-browse-btn cursor-pointer"
                    (click)="openItemDialogwithoutSearchData('')"
                  >
                    Browse
                  </button>
                </div>
                <div style="margin-bottom: 20px">
                  Products Excluded :
                  {{
                    productAndDiscount.categoryProductList[0]["productIds"]
                      .length
                  }}
                </div>
              </div> -->
            </div>
          </div>

          <div *ngIf="stepsCompleted == 2" class="qb-w-70" id="step2">
            <!-- <div class="qb-gf-pyg-title-label" id="panelList" >Target Audience</div>
            <div class="qb-gf-pyg-normal-label" style="margin-bottom: 24px">
              Select the customer segment you want to target for this game
            </div> -->
            <div class="qb-d-flex">
              <div class="qb-gf-pyg-title-label">{{'GAMIFY_PAGE.CUSTOMER_SEGMENTS' | i18nTranslate}}</div>
                <span
                class="qb-i-div"
                popoverclass="qb-my-custom-class"
                placement="right"
                [ngbPopover]="popCustomerSegment"
                triggers="mouseenter:mouseleave"
                >i
                </span>
                <ng-template #popCustomerSegment>
                <div class="qb-ng-template-text">
                  {{'GAMIFY_PAGE.SEGMENT_GAMIFY_INFO' | i18nTranslate}}
                </div>
                </ng-template>
            </div>
            <div class="qb-gf-pyg-normal-label qb-mb-24">
              <!-- customer segement     -->
              <div
                #userSegment
                class="qb-user-segment-div"
              >
                <div>
                  <div
                    class="qb-multi-select-panel-seg"
                    id="multi-select-panel-seg"
                    (click)="toggleSelectSegment()"
                  >
                    <span *ngIf="customerSelectedSegment[0] === 'ALL'"
                      >{{'GAMIFY_PAGE.SEGMENT_SELECTED' | i18nTranslate}} : {{'GAMIFY_PAGE.ALL_CUSTOMERS' | i18nTranslate}}</span
                    >
                    <span *ngIf="customerSelectedSegment[0] !== 'ALL'"
                    >{{'GAMIFY_PAGE.SEGMENT_SELECTED' | i18nTranslate}} :
                    {{ customerSelectedSegment.length }}</span
                  >
                    <div id="keyboard-down-icon-container-seg">
                      <mat-icon class="qb-keyboard-down-icon-seg"
                        >keyboard_arrow_down</mat-icon
                      >
                    </div>
                  </div>
                  <div class="qb-p-relative">
                    <div
                      class="qb-multi-select-dropdown-seg"
                      id="multi-select-dropdown-seg"
                    >
                      <div class="qb-search">
                        <mat-icon class="qb-group-icon">search</mat-icon>
                        <input
                          [(ngModel)]="tempSegmentGameFilter"
                          placeholder="Search Segment"
                          type="text"
                          class="qb-search-segment"
                        />
                      </div>
                      <div class="qb-border-line"></div>
                      <div id="segment-select-seg" class="qb-segment-select">
                        <div
                          *ngFor="
                            let customer of allcustomer
                              | labelPipe: tempSegmentGameFilter:'name'
                          "
                        >
                          <mat-checkbox
                            [checked]="
                              customerSelectedSegment.length === 1 &&
                              customerSelectedSegment[0] === 'ALL'
                            "
                            (change)="addAllCustomers($event)"
                            color="primary"
                            >{{ customer.name }}</mat-checkbox
                          >
                          </div>
                          <div
                            *ngFor="
                              let segment of customerSegment
                                | labelPipe: tempSegmentGameFilter:'name'
                            "
                          >
                            <mat-checkbox
                              [checked]="isChecked(segment)"
                              (change)="
                                updateCheckboxValuesCustomerSegment(segment)
                              "
                              [disabled]="segment.isActive===false"
                              color="primary"
                              >{{ segment.name }}
                            </mat-checkbox>

                            <div>
                              <!-- {{customerSelectedSegment | isCheckedPYG: segment.id}} -->
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="qb-invalid-text-h qb-h-10">
                    <!-- <div>{{segmentErrorText}}</div> -->
                  </div>
                </div>
                <!-- {{customerSelectedSegment[0]}} -->
                <div
                class="qb-error-text"
                *ngIf="customerSelectedSegment.length === 0"
              >
                {{'GAMIFY_PAGE.SELECT_ONE_SEGMENT' | i18nTranslate}}
              </div>

                <div
                 class="qb-multiple-select-info"
                >
                  {{'GAMIFY_PAGE.MULTIPLE_SEGMENT' | i18nTranslate}}
                </div>
              </div>
              
              <div>
                <mat-checkbox
                  class="qb-d-block"
                  [(ngModel)]="isEnabledForAnonymousUser"
                  color="primary"
                >
                  {{'GAMIFY_PAGE.GAME_FOR_GUEST_USER' | i18nTranslate}}
                </mat-checkbox>
              </div>
              <div class="qb-d-flex qb-mt-10">
                <span
                  >{{'GAMIFY_PAGE.CLICK' | i18nTranslate}}&nbsp;<span
                    (click)="routeToCreateSegmentScreen()"
                    class="qb-create-segment"
                    >{{'GAMIFY_PAGE.HERE' | i18nTranslate}}</span
                  >&nbsp;{{'GAMIFY_PAGE.TO_CREATE_SEGMENT' | i18nTranslate}}</span
                >
              </div>
              <!-- customer segment end -->
            </div>
          </div>

          <div *ngIf="stepsCompleted == 3" class="qb-w-100" id="step3">
            <div class="qb-gf-gamestep-br" id="panelList" >
              <div
               class="qb-game-step-container"
              >
                <div></div>
                <div>
                  <span class="qb-gf-gamify-heading qb-mr-10" *ngIf="!autopilotStatus"
                    >{{'GAMIFY_PAGE.AUTOPILOT_OFF' | i18nTranslate}}</span>
                    <span class="qb-gf-gamify-heading qb-mr-10 qb-mr-10" *ngIf="autopilotStatus"
                    >{{'GAMIFY_PAGE.AUTOPILOT_ON' | i18nTranslate}}</span>
                  <span>
                    <mat-slide-toggle
                      color="accent"
                      [checked]="autopilotStatus"
                      (change)="pilotMode($event)"
                      [(ngModel)]="autopilotStatus"
                      data-button-name="Autopilot Mode"
                      [attr.data-item-name]="!autopilotStatus"
                    ></mat-slide-toggle>
                  </span>
                </div>
              </div>
              <div class="qb-d-flex">
                <div class="qb-w-30">
                  <img
                    src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/vectors/automation.gif"
                    alt=""
                  />
                  <div class="qb-gf-gamify-heading qb-d-flex qb-autopilot-text">{{'GAMIFY_PAGE.AUTOPILOT' | i18nTranslate}}</div>
                  <div class="qb-ai-main-container">
                    <div class="qb-ai-text">{{'GAMIFY_PAGE.LET_AI_CHOOSE' | i18nTranslate}}</div>
                    <div class="qb-d-flex qb-ai-flex-start">
                      <mat-icon class="qb-check-circle"
                        >check_circle_outline
                      </mat-icon>
                      <div>
                        {{'GAMIFY_PAGE.STEPS_OPTIMAL_FOR_CUSTOMER' | i18nTranslate}}
                      </div>
                    </div>
                    <div class="qb-d-flex qb-ai-flex-start">
                      <mat-icon class="qb-check-circle"
                        >check_circle_outline
                      </mat-icon>
                      <div>
                        {{'GAMIFY_PAGE.PRODUCT_PER_STEP' | i18nTranslate}}
                      </div>
                    </div>
                    <div class="qb-d-flex qb-ai-flex-start">
                      <mat-icon class="qb-check-circle"
                        >check_circle_outline
                      </mat-icon>
                      <div class="qb-ml-5">
                        {{'GAMIFY_PAGE.FUNCTION_FOR_CUSTOMERS_MESSAGE' | i18nTranslate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="qb-w-70">

                  <div class="qb-gf-info-tip">
                    <div class="qb-mr-5" >
                      <span class="qb-i-div-offer">i </span>
                    </div>
                    <div>
                      <ul>
                        <li>
                          {{'GAMIFY_PAGE.STEPS_ONE_LIST' | i18nTranslate}}
                        </li>
                        <li>
                          {{'GAMIFY_PAGE.PRODUCTS_ONE_LIST' | i18nTranslate}}
                        </li>
                      </ul>
                      <div class="qb-pl-25">
                        {{'GAMIFY_PAGE.WE_TAKE_CARE' | i18nTranslate}} <strong>{{'GAMIFY_PAGE.AUTOPILOT' | i18nTranslate}}</strong> {{'GAMIFY_PAGE.IS' | i18nTranslate}} <strong>{{'GAMIFY_PAGE.ON' | i18nTranslate}}</strong>
                      </div>
                    </div>
                  </div>
                  <!-- <div style="width: 100%; margin-bottom: 24px">
                    <div class="qb-d-flex qb-ai-flex-start">
                      <mat-icon class="qb-check-circle"
                        >check_circle_outline
                      </mat-icon>
                      <div>
                        Let AI choose the steps optimal for your customer.
                      </div>
                    </div>
                    <div class="qb-d-flex qb-ai-flex-start">
                      <mat-icon class="qb-check-circle"
                        >check_circle_outline
                      </mat-icon>
                      <div>
                        Let AI choose the products per step optimal for your
                        customer.
                      </div>
                    </div>
                    <div class="qb-d-flex qb-ai-flex-start">
                      <mat-icon class="qb-check-circle"
                        >check_circle_outline
                      </mat-icon>
                      <div>
                        Let AI choose the cool off period, initial halt, time to
                        redeem reward and retries allowed which will be optimal
                        for your customer.
                      </div>
                    </div>
                  </div> -->

                  <div class="qb-steps-container">
                    <div>
                      <div class="qb-gf-block-input-label"><span>{{'GAMIFY_PAGE.STEPS' | i18nTranslate}}</span></div>
                      <div
                        class="qb-gf-block-input-box"
                        [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                      >
                        <input
                          type="number"
                          min="2"
                          max="5"
                          [(ngModel)]="gameSteps.steps"
                          class="qb-gf-block-input-field"
                          [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                          required
                          [disabled]="autopilotStatus"
                        />
                      </div>
                      <div id="qbGmGameSteps"style="font-size: 10px; max-width: 130px; color: red"></div>
                    </div>
                    <div>
                      <div class="qb-gf-block-input-label">
                        <span>{{'GAMIFY_PAGE.PRODUCTS' | i18nTranslate}}</span>
                        <span class="qb-s-italic">{{'GAMIFY_PAGE.PER_STEP' | i18nTranslate}}</span>
                      </div>
                      <div
                        class="qb-gf-block-input-box"
                        [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                      >
                        <input
                          type="number"
                          min="2"
                          max="5"
                          [(ngModel)]="gameSteps.productsPerStep"
                          class="qb-gf-block-input-field"
                          [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                          required
                          [disabled]="autopilotStatus"
                        />
                      </div>
                      <div id="qbGmProductsPerStep"style="font-size: 10px; max-width: 130px; color: red"></div>
                    </div>
                    <div id="qbGmExcludedProducts"style="font-size: 10px; max-width: 300px; color: red"></div>
                  </div>

                  <div>
                    <div
                      #el
                      class="qb-gf-gamify-heading gf-accordion qb-advanced-setting-container"
                      (click)="advancedSettingsChangeSlide(); toggleHelper()"
                    >
                      <div>{{"GAMIFY_PAGE.ADVANCED_SETTING" | i18nTranslate}}</div>
                      <mat-icon 
                      class="qb-gf-chv-down qb-ml-5"
                      [class.gf-icon-clicked]="isAdvancedSettingslideOpen"
                      >arrow_drop_down
                    </mat-icon>
                    </div>
                    <div class="qb-gf-panel">
                      <div
                        class="qb-initial-halt-container"
                      >
                        <div>
                          <div class="qb-gf-block-input-label">
                            <span>{{'GAMIFY_PAGE.INITIAL_HALT' | i18nTranslate}}</span>
                          </div>
                          <div
                            class="qb-gf-block-input-label qb-in-second-text"
                          >
                            {{'GAMIFY_PAGE.IN_SECONDS' | i18nTranslate}}
                          </div>
                          <div
                            class="qb-gf-block-input-box"
                            [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                          >
                            <input
                              type="number"
                              min="0"
                              max="59"
                              [(ngModel)]="gameSteps.initialHalt"
                              class="qb-gf-block-input-field"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              required
                              [disabled]="autopilotStatus"
                            />
                          </div>
                          <div id="qbGmIntialHalt"style="font-size: 10px; max-width: 130px; color: red"></div>
                        </div>
                      

                        <div>
                          <div class="qb-gf-block-input-label">
                            <span>{{'GAMIFY_PAGE.COOL_OF_PERIOD' | i18nTranslate}}</span>
                          </div>
                          <div
                          class="qb-days-text"
                          >
                            <div>Days</div>
                            <div class="qb-ml-40">Hrs</div>
                            <div class="qb-ml-43">Mins</div>
                          </div>
                          <div class="qb-input-value-container">
                            <div
                              class="qb-w-56 qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="0"
                                max="365"
                                [(ngModel)]="gameSteps.coolOffPeriod.days"
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                            <div
                              class="qb-w-56 qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="0"
                                max="23"
                                [(ngModel)]="gameSteps.coolOffPeriod.hours"
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                            <div
                              class="qb-w-56 qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="0"
                                max="59"
                                [(ngModel)]="
                                  gameSteps.coolOffPeriod.minutes
                                "
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                          </div>
                          <div id="qbGmCoolOffPeriod"style="font-size: 10px; max-width: 130px; color: red"></div>
                        </div>


                        <div>
                          <div class="qb-gf-block-input-label">
                            <span>{{'GAMIFY_PAGE.TIMETO_REDEEM' | i18nTranslate}}</span>
                          </div>
                          <div
                            class="qb-days-text"
                          >
                            <div>Days</div>
                            <div class="qb-ml-40">Hrs</div>
                            <div class="qb-ml-43">Mins</div>
                          </div>
                          <div class="qb-input-value-container">
                            <div
                              class="qb-w-56 qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="1"
                                max="59"
                                [(ngModel)]="gameSteps.timeToRedeemReward.days"
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                            <div
                              class="qb-w-56 qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="1"
                                max="23"
                                [(ngModel)]="gameSteps.timeToRedeemReward.hours"
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                            <div
                              class="qb-w-56 qb-gf-block-input-box"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                            >
                              <input
                                type="number"
                                min="1"
                                max="59"
                                [(ngModel)]="
                                  gameSteps.timeToRedeemReward.minutes
                                "
                                class="qb-gf-block-input-field"
                                [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                                [disabled]="autopilotStatus"
                                required
                              />
                            </div>
                          </div>
                          <div id="qbGmRedeemPeriod"style="font-size: 10px; max-width: 130px; color: red"></div>
                        </div>
                        <div>
                          <div class="qb-gf-block-input-label">
                            <span>Retries</span>
                          </div>
                          <div
                            class="qb-gf-block-input-label qb-retries-input"
                          >
                            {{'GAMIFY_PAGE.NUMBER' | i18nTranslate}}
                          </div>
                          <div
                            class="qb-gf-block-input-box"
                            [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                          >
                            <input
                              type="number"
                              min="1"
                              max="59"
                              [(ngModel)]="gameSteps.retries"
                              class="qb-gf-block-input-field"
                              [ngClass]="{ 'qb-gf-disabled': autopilotStatus }"
                              [disabled]="autopilotStatus"
                              required
                            />
                          </div>
                          <div id="qbGmRetries"style="font-size: 10px; max-width: 130px; color: red"></div>
                        </div>
                      </div>

                      <div class="qb-gf-info-tip">
                        <div class="qb-mr-5">
                          <span class="qb-i-div-offer">i </span>
                        </div>
                        <div>
                          <ul>
                            <li>
                              {{'GAMIFY_PAGE.COOL_OF_PERIOD_ONE_LIST' | i18nTranslate}}
                            </li>
                            <li>
                              {{'GAMIFY_PAGE.INITIAL_HALT_ONE_LIST' | i18nTranslate}}
                            </li>
                            <li>{{'GAMIFY_PAGE.TIME_REDEEM_ONE_LIST' | i18nTranslate}}</li>
                            <li>{{'GAMIFY_PAGE.RETRIES_ONE_LIST' | i18nTranslate}}</li>
                          </ul>
                          <div class="qb-pl-25">
                            {{'GAMIFY_PAGE.WE_TAKE_CARE' | i18nTranslate}}
                            <strong>{{'GAMIFY_PAGE.AUTOPILOT' | i18nTranslate}}</strong> {{'GAMIFY_PAGE.IS' | i18nTranslate}} <strong>{{"GAMIFY_PAGE.ON" | i18nTranslate}}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="stepsCompleted == 4" class="qb-w-100" id="step4">
            <div  class="qb-d-flex qb-w-100" id="panelList" >
              <div class="qb-w-55">
                <!-- <div class="qb-gf-pyg-title-label">Layout and Style</div>
                <div class="qb-gf-pyg-normal-label" style="margin-bottom: 24px">
                  Set the position of the game icon on your website and style it
                  as per your needs.
                </div> -->
                <div class="qb-d-flex">
                  <div class="qb-gf-pyg-title-label">{{'GAMIFY_PAGE.ICON_POSITION' | i18nTranslate}}</div>                 
                    <span
                    class="qb-i-div"
                    popoverclass="qb-my-custom-class"
                    placement="right"
                    [ngbPopover]="popLayoutandStyle"
                    triggers="mouseenter:mouseleave"
                    >i
                    </span>
                    <ng-template #popLayoutandStyle>
                    <div class="qb-ng-template-text">
                      {{'GAMIFY_PAGE.ICON_POSTION_INFO' | i18nTranslate}}
                    </div>
                    </ng-template>
                </div>
                <div class="qb-gf-pyg-normal-label qb-mb-24">
                  {{'GAMIFY_PAGE.ICON_POSITION_MESSAGE' | i18nTranslate}}
                </div>

                <div>
                  <div class="qb-box-position-container">
                    <div
                      class="qb-box-container qb-ai-flex-start qb-jc-flex-start"
                      [ngClass]="{ active: boxPosition === 'TOP_LEFT' }"
                      (click)="setBoxPosition('TOP_LEFT')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'TOP_LEFT' }"
                      ></div>
                    </div>
                    <!-- <div class="qb-box-container" [ngClass]="{ active: boxPosition === 'TOP_CENTER' }"
                                            (click)="setBoxPosition('TOP_CENTER')"
                                            style="align-items: flex-start; justify-content: center">
                                            <div class="qb-box" [ngClass]="{ active: boxPosition === 'TOP_CENTER' }"></div>
                                        </div> -->
                    <div
                      class="qb-box-container qb-ai-flex-start qb-jc-flex-end"
                      [ngClass]="{ active: boxPosition === 'TOP_RIGHT' }"
                      (click)="setBoxPosition('TOP_RIGHT')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'TOP_RIGHT' }"
                      ></div>
                    </div>
                    <div
                      class="qb-box-container qb-ai-center qb-jc-flex-start"
                      [ngClass]="{ active: boxPosition === 'MIDDLE_LEFT' }"
                      (click)="setBoxPosition('MIDDLE_LEFT')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'MIDDLE_LEFT' }"
                      ></div>
                    </div>
                    <div
                      class="qb-box-container qb-ai-center qb-jc-center"
                      [ngClass]="{ active: boxPosition === 'CENTER' }"
                      (click)="setBoxPosition('CENTER')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'CENTER' }"
                      ></div>
                    </div>
                    <div
                      class="qb-box-container qb-ai-center qb-jc-flex-end"
                      [ngClass]="{ active: boxPosition === 'MIDDLE_RIGHT' }"
                      (click)="setBoxPosition('MIDDLE_RIGHT')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'MIDDLE_RIGHT' }"
                      ></div>
                    </div>
                    <div
                      class="qb-box-container qb-ai-flex-end qb-jc-flex-start"
                      [ngClass]="{ active: boxPosition === 'BOTTOM_LEFT' }"
                      (click)="setBoxPosition('BOTTOM_LEFT')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'BOTTOM_LEFT' }"
                      ></div>
                    </div>
                    <!-- <div class="qb-box-container"
                                            [ngClass]="{ active: boxPosition === 'BOTTOM_CENTER' }"
                                            (click)="setBoxPosition('BOTTOM_CENTER')"
                                            style="align-items: flex-end; justify-content: center">
                                            <div class="qb-box" [ngClass]="{ active: boxPosition === 'BOTTOM_CENTER' }">
                                            </div>
                                        </div> -->
                    <div
                      class="qb-box-container qb-ai-flex-end qb-jc-flex-end"
                      [ngClass]="{ active: boxPosition === 'BOTTOM_RIGHT' }"
                      (click)="setBoxPosition('BOTTOM_RIGHT')"
                    >
                      <div
                        class="qb-box"
                        [ngClass]="{ active: boxPosition === 'BOTTOM_RIGHT' }"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="qb-m-24">
                  <div>{{'GAMIFY_PAGE.SHOW_ON' | i18nTranslate}}</div>

                  <div class="qb-multi-select-panel-seg2 qb-w-50" >
                    <mat-select
                      class="qb-w-95 qb-select-page"
                      [(ngModel)]="showOnSelectedPages"
                      multiple
                      (selectionChange)="changeShowOn(i)"
                    >
                      <mat-option
                        (change)="addAllShowOnPage($event)"
                        value="ALL"
                        >{{'GAMIFY_PAGE.ALL_PAGES' | i18nTranslate}}</mat-option
                      >
                      <mat-option
                        *ngFor="let showOn of shopifyStorePages | keyvalue"
                        (change)="updateShowOnPage(showOn)"
                        [disabled]="AllPageSelected"
                        [value]="showOn['value']"
                        >{{ showOn.key }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div
                  class="qb-error-text"
                    *ngIf="showOnSelectedPages.length == 0 && isContinueBtnLoading"
                  >
                    {{'GAMIFY_PAGE.SELECT_ANY_ONE_ABOVE' | i18nTranslate}}
                  </div>
                </div>

                <div class="qb-m-24">
                  <div class="qb-gf-pyg-title-label">{{"GAMIFY_PAGE.SELECT_ICON_IMG" | i18nTranslate}}</div>

                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="qb-example-radio-group qb-select-icon-radio"
                    [(ngModel)]="selectedwidget"
                  >
                    <div *ngFor="let widget of widgetIconImageList | keyvalue">
                      <div
                        class="qb-widget-select-container"
                      >
                        <mat-radio-button
                          class="qb-example-radio-button qb-radio-button-container"
                          [value]="widget.value"
                          color="primary"
                        >
                          <img
                            [src]="widget.value"
                            alt=""
                            width="100px"
                            height="100px"
                            class="qb-img-padding-icon"
                          />
                        </mat-radio-button>
                        <!-- <img [src]="widget.value" alt=""> -->
                      </div>
                    </div>
                  </mat-radio-group>
                </div>

                <div class="qb-m-24">
                  <div class="qb-gf-pyg-title-label">{{'GAMIFY_PAGE.BACKGROUND_CLR' | i18nTranslate}}</div>
                  <div
                    class="qb-multi-select-panel-seg qb-color-picker-container"
                    [(colorPicker)]="widgetBackground"
                    (colorPickerChange)="styleChangeLive($event)"
                  >
                    <input
                      class="qb-color-picket-input qb-color-picker-sub-container"
                      [style.background]="widgetBackground"
                      [disabled]="true"
                    />
                    <span class="qb-widget-background">{{ widgetBackground }}</span>
                  </div>
                </div>
              </div>

              <div class="qb-w-45">
               

                <div class="qb-w-100 qb-p-fixed">
                  <span> {{'GAMIFY_PAGE.PREVIEW' | i18nTranslate}}</span>
                  <!-- <div #gfDragBoundary class="qb-gf-preview-boundary" style="position: relative;" id="gf-preview-boundary">

                                        <div class="qb-chat-btnPyg" id="chat-btn-pos" cdkDragBoundary=".gf-preview-boundary" (cdkDragStarted)="onDragStarted($event)"
                                            cdkDrag (cdkDragEnded)="onDragEnded($event)" [style.background]="widgetBackground"   (cdkDragMoved)="dragMoved($event)" >
                                            <img [src]="selectedwidget"
                                                style="width: 40px" />
                                        </div>

                                    </div> -->

                  <div
                    #gfDragBoundary
                    class="qb-gf-preview-boundary qb-p-relative"
                    id="gf-preview-boundary"
                    (drop)="drop($event)"
                    (dragover)="allowDrop($event)"
                    [ngStyle]="{
                      'background-image': 'url(' + storeImageUrl + ')'
                    }"
                  >
                    <div
                      class="qb-chat-btnPyg"
                      id="chat-btn-pos"
                      [style.background]="widgetBackground"
                      draggable="true"
                      (dragstart)="drag($event)"
                    >
                      <img [src]="selectedwidget" class="qb-w-20-px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="stepsCompleted == 5"
            class="qb-d-flex qb-w-100"
            id="step5"
          >
            <div id="panelList" class="qb-w-42">
              <div class="qb-m-24">
                <div class="qb-gf-pyg-title-label">{{'GAMIFY_PAGE.SCHEDULE_THE_GAME' | i18nTranslate}}</div>
                <div class="qb-gf-pyg-normal-label qb-mb-24">
                  {{'GAMIFY_PAGE.CHOOSE_WHEN_TO_SELECT_GAME' | i18nTranslate}}
                </div>
              </div>

              <div
              class="qb-date-range"
              >
                <div class="qb-radio-toolbar qb-w-100">
                  <div class="qb-trigger-radio_group">
                    <mat-radio-group
                      class="qb-d-flex"
                      [(ngModel)]="schedleConfigRadio"
                      (change)="radioChange($event)"
                    >
                      <mat-radio-button
                        color="primary"
                        [disableRipple]="true"
                        class="qb-fs08-rem qb-mr-20"
                        [checked]="schedleConfigRadio == 'DATE_RANGE'"
                        value="DATE_RANGE"
                        >{{'GAMIFY_PAGE.DATE_RANGE' | i18nTranslate}}
                      </mat-radio-button>
                      <mat-radio-button
                        color="primary"
                        [disableRipple]="true"
                        class="qb-fs08-rem"
                        [checked]="schedleConfigRadio == 'DATE_ONLY'"
                        value="DATE_ONLY"
                        >{{'GAMIFY_PAGE.SPECIFIC_DATE' | i18nTranslate}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div>
                    <div class="qb-form-group qb-mt-10">
                      <div
                        class="qb-w-100 qb-d-flex"
                        *ngIf="schedleConfigRadio == 'DATE_RANGE'"
                      >
                        <div class="qb-d-flex qb-w-100">
                          <div class="qb-mr-10">
                            <div class="qb-mb-5">
                              <span class="qb-configLabelSecond">{{'GAMIFY_PAGE.START_DATE' | i18nTranslate}}</span
                              ><span class="qb-imp-color">*</span>
                            </div>
                            <div class="qb-formDivP">
                              <input
                                [(ngModel)]="scheduleDataObj['startDate']"
                                class="qb-formInput fontFam input-box-alignment"
                                [min]="startDateMinValue"
                                (dateChange)="startDateChange('start')"
                                matInput
                                [matDatepicker]="picker1"
                                placeholder="Choose a date"
                                readonly
                                [ngStyle]="scheduleDataObj['startDate'] ? { opacity: 0 , width: '0px' } : null"
                              />
                              <div class="qb-formInput fontFam input-box-alignment" style="width: 140px;">
                                {{scheduleDataObj['startDate'] | date: getDateFormat() }}
                              </div>
                              <mat-datepicker-toggle matSuffix [for]="picker1">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                            </div>
                          </div>
                          <div>
                            <div class="qb-mb-5">
                              <span class="qb-configLabelSecond"> {{'GAMIFY_PAGE.END_DATE' | i18nTranslate}}</span
                              ><span class="qb-imp-color" class="qb-imp-color">*</span>
                            </div>
                            <div class="qb-formDivP">
                              <input
                                [(ngModel)]="scheduleDataObj['endDate']"
                                class="qb-formInput fontFam input-box-alignment"
                                [min]="tomorrowDate"
                                (dateChange)="endDateChange('end')"
                                matInput
                                [matDatepicker]="picker2"
                                placeholder="Choose a date"
                                readonly
                                [ngStyle]="scheduleDataObj['endDate'] ? { opacity: 0 , width: '0px' } : null"
                              />
                              <div class="qb-formInput fontFam input-box-alignment" style="width: 140px;">
                                {{scheduleDataObj['endDate'] | date: getDateFormat() }}
                              </div>
                              <mat-datepicker-toggle matSuffix [for]="picker2">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </div>
                            <div class="qb-invalid-text-h">
                              <div>{{ dateErrorText | i18nDynamicTranslate : 'POS_PAGE'}}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="qb-d-flex qb-w-50"
                        *ngIf="schedleConfigRadio == 'DATE_ONLY'"
                      >
                        <div class="qb-d-flex qb-w-100">
                          <div class="qb-mr-10">
                            <div class="qb-mb-5">
                              <span class="qb-configLabelSecond">{{'GAMIFY_PAGE.ON' | i18nTranslate}}</span
                              ><span class="qb-imp-color">*</span>
                            </div>
                            <div class="qb-formDivP">
                              <input
                                [(ngModel)]="scheduleDataObj['startDate']"
                                class="qb-formInput fontFam input-box-alignment"
                                [min]="startDateMinValue"
                                (dateChange)="startDateChange('start')"
                                matInput
                                [matDatepicker]="picker1"
                                placeholder="Choose a date"
                                readonly
                                [ngStyle]="scheduleDataObj['startDate'] ? { opacity: 0 , width: '0px' } : null"
                              />
                              <div class="qb-formInput fontFam input-box-alignment" style="width: 140px;">
                                {{scheduleDataObj['startDate'] | date: getDateFormat() }}
                              </div>
                              <mat-datepicker-toggle matSuffix [for]="picker1">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                   class="qb-at-any-time-container"
                  >
                    <div class="qb-configLabelSecond">
                      <span>{{'GAMIFY_PAGE.AT_ANY_TIME' | i18nTranslate}}</span>
                      <span
                        class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="right"
                        [ngbPopover]="popContentAtAnyTime"
                        triggers="mouseenter:mouseleave"
                        *ngIf="!atAnyTime"
                        >i
                      </span>
                      <ng-template #popContentAtAnyTime>
                        <div class="qb-ng-template-text">
                          {{'GAMIFY_PAGE.ANY_TIME_INFO' | i18nTranslate}}
                        </div>
                      </ng-template>
                    </div>

                    <div>
                      <span>
                        <mat-slide-toggle
                          color="accent"
                          [checked]="atAnyTime"
                          [(ngModel)]="atAnyTime"
                          (change)="atAnyTimeChange($event)"
                        ></mat-slide-toggle>
                      </span>
                    </div>
                  </div>

                  <div *ngIf="!atAnyTime">
                    <div class="qb-form-group">
                      <!-- <div class="qb-labelSection">
                            <div class="qb-labelBlock" style=" margin-top: 18px;">
                              <div class="qb-labelNames" for="itemsPerHurdle">Time </div> <span>:</span>
                            </div>
                            <div class="qb-invalid-text-h">
                              <div></div>
                            </div>
                          </div> -->
                      <div
                          class="qb-w-100 qb-d-flex qb-fd-coloumn"
                      >
                        <div class="qb-d-flex qb-w-100">
                          <div class="qb-w-49 qb-mr-10">
                            <div class="qb-mb-5">
                              <span class="qb-configLabelSecond">{{'GAMIFY_PAGE.START_TIME' | i18nTranslate}}</span
                              ><span class="qb-imp-color">*</span>
                            </div>
                            <div
                              class="qb-d-flex qb-jc-flex-start"
                            >
                              <div class="qb-w-100">
                                <div class="qb-d-flex">
                                  <div
                                    class="qb-formDiv2 qb-date-picker-div"
                                    [ngClass]="{
                                      active:
                                        scheduleDataObj['startTime'] == null ||
                                        scheduleDataObj['startTime'].length == 0
                                    }"
                                  >
                                    <input
                                      [(ngModel)]="scheduleDataObj['startTime']"
                                      [format]="serviceCountry === 'US' ? 12 : 24"
                                      [min]="
                                        selectedStartDateString == MinDateString
                                          ? currentTimeMin
                                          : '00:00'
                                      "
                                      class="qb-formInput qb-h-90 qb-cursor-pointer"
                                      [ngxTimepicker]="pickerFrom"
                                      (ngModelChange)="checkTime()"
                                      readonly
                                    />
                                    <ngx-material-timepicker #pickerFrom>
                                    </ngx-material-timepicker>
                                    <img
                                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                                      alt=""
                                      (click)="clickTime('START')"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="qb-w-49 qb-mr-10">
                            <div class="qb-mb-5">
                              <span class="qb-configLabelSecond">{{'GAMIFY_PAGE,END_TIME' | i18nTranslate}}</span
                              ><span class="qb-imp-color">*</span>
                            </div>
                            <div
                              class="qb-d-flex qb-jc-flex-start"
                            >
                              <div class="qb-w-100">
                                <div class="qb-d-flex">
                                  <div
                                    class="qb-formDiv2 qb-date-picker-div"
                                    [ngClass]="{
                                      active:
                                        scheduleDataObj['endTime'] == null ||
                                        scheduleDataObj['endTime'].length == 0
                                    }"
                                  >
                                    <input
                                      [(ngModel)]="scheduleDataObj['endTime']"
                                      [format]="serviceCountry === 'US' ? 12 : 24"
                                      class="qb-formInput qb-h-90 qb-cursor-pointer"
                                      [ngxTimepicker]="pickerTo"
                                      (ngModelChange)="checkTime()"
                                      readonly
                                    />
                                    <ngx-material-timepicker #pickerTo>
                                    </ngx-material-timepicker>
                                    <img
                                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                                      alt=""
                                      (click)="clickTime('END')"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="qb-invalid-text-h">
                          <div
                            *ngIf="
                              timeErrorText.length == 0 &&
                              (scheduleDataObj['startTime'] == null ||
                                scheduleDataObj['startTime'].length == 0 ||
                                scheduleDataObj['endTime'] == null ||
                                  scheduleDataObj['endTime'].length == 0)
                            "
                            class="qb-error-text-date"
                          >
                            {{'GAMIFY_PAGE.PLEASE_SELECT_TIME_FOR_GAME' | i18nTranslate}}
                          </div>
                          <div
                            *ngIf="
                              !(
                                (scheduleDataObj['startTime'] == null ||
                                  scheduleDataObj['startTime'].length == 0) &&
                                (scheduleDataObj['endTime'] == null ||
                                  scheduleDataObj['endTime'].length == 0)
                              )
                            "
                          >
                            {{ timeErrorText }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="qb-every-day-of-week-container"
                    *ngIf="schedleConfigRadio != 'DATE_ONLY'"
                  >
                    <div class="qb-configLabelSecond">
                      <span>{{'GAMIFY_PAGE.EVERY_DAY_OF_WEEK' | i18nTranslate}}</span
                      ><span
                        class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="right"
                        [ngbPopover]="popContentEveryDayOfTheWeek"
                        triggers="mouseenter:mouseleave"
                        *ngIf="!EveryDayofTheWeek"
                        >i
                      </span>
                      <ng-template #popContentEveryDayOfTheWeek>
                        <div class="qb-ng-template-text">
                          {{'GAMIFY_PAGE.EVERY_DAY_OF_WEEK_INFO' | i18nTranslate}}
                        </div>
                      </ng-template>
                    </div>

                    <div>
                      <span>
                        <mat-slide-toggle
                          color="accent"
                          [checked]="EveryDayofTheWeek"
                          [(ngModel)]="EveryDayofTheWeek"
                          (change)="toggleSelectionAllDays($event)"
                        >
                        </mat-slide-toggle>
                      </span>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      !EveryDayofTheWeek && schedleConfigRadio != 'DATE_ONLY'
                    "
                  >
                    <mat-chip-list
                      *ngIf="!EveryDayofTheWeek"
                      placeholder="Select a day"
                      class="qb-font-class fs08-rem"
                      multiple="true"
                    >
                      <mat-chip
                        color="accent"
                        #c="matChip"
                        *ngFor="let day of weekDaysChips"
                        class="qb-font-class fs08-rem qb-cursor-pointer weekdays-chip qb-mim-h-26-px"
                        [value]="day.full"
                        (click)="toggleSelection(day)"
                        [selected]="day.selected"
                      >
                        <span>{{ day.full[0] }}</span
                        ><span class="qb-text-lower-case"
                          >{{ day.full[1] }}{{ day.full[2] }}</span
                        >
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
                <div>
                  <div
                  class="qb-email-sub"
                  >
                    {{'GAMIFY_PAGE.EMAIL_SUBJECT' | i18nTranslate}}
                  </div>
                  <div class="qb-email-subject-game">
                    <input
                      type="text"
                      class="qb-formInput fontExo2"
                      placeholder="Enter your email subject here"
                      [(ngModel)]="emailSubject"
                    />
                  </div>
                  <div
                    class="qb-imp-color"
                    *ngIf="emailSubject.length === 0 && isContinueBtnLoading"
                  >
                    {{'GAMIFY_PAGE.FILL_EMAIL_SUBJECT' | i18nTranslate}}
                  </div>
                  <div
                    class="qb-d-flex qb-mt-5"
                    (click)="showPreview()"
                  >
                    <mat-icon class="qb-template-attach-icon-game"
                      >attach_file</mat-icon
                    >
                    <div class="qb-email-name-game">{{'GAMIFY_PAGE.NEGOTIATOR_REWARD_EMAIL' | i18nTranslate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="qb-email-preview-container">
              <div
              class="qb-email-preview-sub-div"
              >
              <div
             class="qb-email-preview-text"
              >
                {{'GAMIFY_PAGE.EMAIL_PREVIEW' | i18nTranslate}}
              </div>
              <div class="qb-d-flex">
                  <div
                    class="qb-cursor-pointer qb-mr-20"
                    (click)="showPreview(true)"
                  >
                    <mat-icon class="icons-primary" [matTooltip]="'COMMON_PAGE.EDIT_TEMPLATE' | i18nTranslate"
                      >edit</mat-icon
                    >
                  </div>
                </div>
              </div>
              <!-- <hr style="border-top: 3px solid rgba(0, 0, 0, 0.3);"> -->
              <div *ngIf="!snapshotUrlCheck" >
                <div class="qb-email-preview-border">
                  <img
                    src="{{ snapshotUrl }}"
                    width="100%"
                    height="100%"
                    (error)="updateUrl($event)"
                  />
                </div>

              </div>
              <div
                *ngIf="snapshotUrlCheck"
                class="qb-spinner"
              >
                <mat-spinner diameter="25"></mat-spinner>
              </div>

              <!-- <div>
                                <app-negotiator-template-preview
                                [selectedLayout]="layout"
                              ></app-negotiator-template-preview>
                            </div> -->
            </div>
          </div>
        </div>

        <!-- Footer buttons -->
        <div>
          <div class="qb-d-flex qb-pt-15">
            <div
              class="qb-gf-btn qb-cursor-pointer qb-previous-button-color"
              *ngIf="stepsCompleted > 1"
              (click)="previousButtonClicked()"
            >
              <span>{{'GAMIFY_PAGE.PREVIOUS' | i18nTranslate}}</span>
            </div>
  
            <div
              class="qb-gf-btn qb-gf-discard-btn qb-cursor-pointer"
              *ngIf="!isDiscardBtnLoading"
              (click)="discardGameSetup(); isDiscardBtnLoading = true"
            >
              <span>{{'GAMIFY_PAGE.DELETE' | i18nTranslate}}</span>
            </div>
            <div
              class="qb-gf-btn qb-gf-discard-btn qb-previous-button-color"
              *ngIf="isDiscardBtnLoading"
            >
              <mat-spinner diameter="12"></mat-spinner>
            </div>
            <div
              class="qb-gf-btn qb-gf-save-btn qb-cursor-pointer"
              *ngIf="!isSaveBtnLoading"
              (click)="continueButtonClicked(0); isSaveBtnLoading = true"
            >
              <span>{{'GAMIFY_PAGE.SAVE_EXIT' | i18nTranslate}}</span>
            </div>
            <div class="qb-gf-btn qb-gf-save-btn" *ngIf="isSaveBtnLoading">
              <mat-spinner diameter="12"></mat-spinner>
            </div>
            <div
              class="qb-gf-btn qb-gf-continue-btn qb-cursor-pointer"
              *ngIf="!isContinueBtnLoading || selected == false"
              (click)="continueButtonClicked(1); isContinueBtnLoading = true"
            >
              <span>{{ stepsCompleted != 5 ? "Continue" : "Enable" }}</span>
            </div>
            <div
              class="qb-gf-btn qb-gf-continue-btn"
              *ngIf="isContinueBtnLoading && selected"
            >
              <mat-spinner diameter="12"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

