import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntroComponent, ReportComponent } from './pages';
import { LoyaltyProgramCreateComponent } from './pages';

const routes: Routes = [
  { path: "", redirectTo: "intro", pathMatch: "full" },
  {
    path: "intro",
    component: IntroComponent,
    data: {
        titleheading: "Loyalty Program",
        title: "Loyalty Program",
    },
  },
  {
    path: "create-loyalty-program",
    component: LoyaltyProgramCreateComponent,
    data: {
        titleheading: "Create Loyalty",
        title: "Create Loyalty",
    }
  },
  {
    path: "report",
    component: ReportComponent,
    data: {
        titleheading: "Search Loyalty Report",
        title: "Loyalty Report",
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class  LoyaltyRoutingModule{ }
