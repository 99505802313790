<div class="font" [attr.data-widget-name]="data['data-widget']">
  <div>
    <div class="header">
      <span style="text-align: center">{{'SHARED_PAGE.CONFIRM_DOMAIN' | i18nTranslate}}</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div>
    <div class="message">
      <span>{{'SHARED_PAGE.DOMAIN_ADDED_BY' | i18nTranslate}}
        <b><u>{{data["DomainName"]}}</u></b>  
      </span>
    </div>
    <div class="
    message">{{'SHARED_PAGE.DOMAIN_ADDED_PARAGRAPH' | i18nTranslate}}</div>
  </div>
  <div class="button-container" style="margin-top: 30px;">
    <span
      class="secondary-button-border"
      (click)="submit(false)"
      >{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span
    >
    <span
      class="primary-button"
      (click)="continue(true)"
      style="text-transform: capitalize;"
      >{{'SHARED_PAGE.CONFIRM_DOMAIN' | i18nTranslate}}</span
    >
    <!-- <span class="primary-button" *ngIf="data['successButtonText']" (click)="submit(true)">Delete</span> -->
  </div>
</div>
