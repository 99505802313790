import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { response } from 'express';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { PaymentPosRedirectComponent } from '../payment-pos-redirect/payment-pos-redirect.component';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';

@Component({
  selector: 'app-payment-top-up-pos',
  templateUrl: './payment-top-up-pos.component.html',
  styleUrls: ['./payment-top-up-pos.component.css']
})
export class PaymentTopUpPosComponent implements OnInit {
  @ViewChild("currencySelect1") currencySelect1: any;

  constructor(
    private apicall: ApiCallService,
    private _dialog: MatDialog,
    private _WidgetConfigService: WidgetConfigService
  ) { }
  currency = "USD";
  currency1 = "USD";
  revCurrency;
  planDetails = [];
  cost = 123
  region = 'US';
  validScreens;

  ngOnInit(): void {
    this.getScreenId();
    this.getAddOnPlans();
  }
  
  async getScreenId() {
    this.validScreens = await this._WidgetConfigService.getScreen();
  }

  getAddOnPlans(){
    this.apicall.getAddOnPlans().subscribe((response)=>{
      console.log("RES",response)
      this.planDetails = Object.values(response['body']);;
    })
  }
  selectOpenClose(value){
    switch (value) {
      case "currencySelect1":
        this.currencySelect1.open();
        break;
    }
  }
  changeCurrencySelection(value){
    switch (value) {
      case "currencySelect1":
        this.currency = this.currency1;
        if(this.currency === 'USD')
          this.region = 'US';
        else
          this.region = 'IN'
        break;
    }
    
  }

  getId(channel) {
    switch (channel.toUpperCase()) {
      case "SMS":
        return 18;
      case "EMAIL":
        return 10;
      case "WHATSAPP":
        return 41;
    }
  }

  buyPlan(plan, value?){
    //if(value !== 'contactUs'){
      const dialogRef = this._dialog.open(PaymentPosRedirectComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "250px",
        height: "250px",
        data: {
          region : this.region,
          plan : plan,
          type : "addOn"
        },
        disableClose: true
      });
    //}
    // else{
    //   // const dialogRef = this._dialog.open(ContactUsDialogComponent, {
    //   //   panelClass: "no-padding-dialog-popup",
    //   //   width: "250px",
    //   //   height: "250px",
    //   //   data: {
    //   //     header: "Contact Us",
    //   //     body: " "
    //   //   }
    //   // });
    //   window.open("https://qubriux.skellam.ai/contact-us");

    // }
  }

}
