import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-store-dialog-box',
  templateUrl: './view-store-dialog-box.component.html',
  styleUrls: ['./view-store-dialog-box.component.css']
})
export class ViewStoreDialogBoxComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ViewStoreDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
    seletedStores = [];
  ngOnInit(): void {
    this.seletedStores = this.data['storeData']
  }
  submit(decision){
    this.dialogRef.close(decision)
  }

}
