<div>
  <div class="qb-search-bar-div">
    <div class="qb-search-div">
      <input
        type="text"
        class="qb-search-input"
        placeholder="Search by tags, keywords, store name"
        [(ngModel)]="searchString"
        (input)="onKeySearch($event)"
      />
      <div class="material-icons-outlined" *ngIf="searchString.length == 0">
        search
      </div>
      <span
        class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
        *ngIf="searchString.length > 0"
        (click)="searchString = ''; onKeySearch(searchString)"
      >
        close
      </span>
    </div>
  </div>
  <div class="qb-center" *ngIf="loading">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
  <div class="qb-partners-div" *ngIf="!loading" (scroll)="onScroll($event)">
    <div class="qb-invite-partner-div">
      <div>
        <div class="qb-center">
          <div class="qb-invite-text">{{'PARTNERSHIP_PAGE.DONT_SEE_WHAT_LOOKING_FOR' | i18nTranslate}}</div>
        </div>
        <div class="qb-center">
          <div class="qb-invite-partner-button" (click)="invitePartner()">
            {{'PARTNERSHIP_PAGE.INVITE_PARTNER' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
    <div class="qb-partner-div" *ngFor="let partner of partnerList; index as i">
      <div>
        <div
          class="qb-partner-color"
          [ngStyle]="{ 'background-color': colors[i % 6] }"
        ></div>
        <div class="qb-partner-logo-div">
          <div class="qb-partner-logo">
            <img src="{{ partner['shopLogo'] }}" class="qb-logo-image" />
          </div>
        </div>
        <div class="qb-partner">
          <div class="qb-partner-name">
            <a href="{{ partner['link'] }}" target="_blank">{{
              partner["shopName"]
            }}</a>
          </div>
          <div class="qb-analytics-div total-partners-div">
            <div>{{'PARTNERSHIP_PAGE.TOTAL_PARTNER' | i18nTranslate}}</div>
            <div>{{ partner["totalPartners"] }}</div>
          </div>
          <div class="qb-analytics-div">
            <div>{{'PARTNERSHIP_PAGE.CUSTOMER_STRENGTH' | i18nTranslate}}</div>
            <div
              class="qb-strength"
              *ngIf="partner['customerStrength'] === 'high'"
            >
              <div class="qb-strength1"></div>
              <div class="qb-strength2"></div>
              <div class="qb-strength3"></div>
              <div class="qb-strength4"></div>
              <div class="qb-strength5"></div>
            </div>
            <div
              class="qb-strength"
              *ngIf="partner['customerStrength'] === 'medium'"
            >
              <div class="qb-mediumstrength1"></div>
              <div class="qb-mediumstrength2"></div>
              <div class="qb-mediumstrength3"></div>
              <div class="qb-mediumstrength4"></div>
              <div class="qb-strength5"></div>
            </div>
            <div
              class="qb-strength"
              *ngIf="partner['customerStrength'] === 'low'"
            >
              <div class="qb-lowstrength1"></div>
              <div class="qb-lowstrength2"></div>
              <div class="qb-lowstrength3"></div>
              <div class="qb-lowstrength4"></div>
              <div class="qb-strength5"></div>
            </div>
          </div>
        </div>
        <div class="qb-initiate-button-div">
          <div
            class="primary-button"
            (click)="
              initiatePartership(
                partner['shopName'],
                partner['targetMerchantId']
              )
            "
          >
            {{'PARTNERSHIP_PAGE.INITIATE_PATNERSHIP' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
