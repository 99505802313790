import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplatePreviewDialogComponent } from 'src/app/shared/components/dialog-box';

@Component({
  selector: 'app-email-sample-listing',
  templateUrl: './email-sample-listing.component.html',
  styleUrls: ['./email-sample-listing.component.css']
})
export class EmailSampleListingComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }
  @Input() isEditorLoaded: boolean;
  @Output() templateData = new EventEmitter<any>();

  ngOnInit(): void {
    console.log(this.isEditorLoaded);
  }

  ngOnChanges(changes) {
    if(changes['isEditorLoaded']){
      console.log(this.isEditorLoaded);
    }
  }

  startDesigningPage(template){
    this.templateData.emit(template);
  }

  previewEmailTemplate(template){
    const dialogRef = this.dialog.open(
      EmailTemplatePreviewDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "750px",
        data: {
          template: template,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
      }
    });
  }
}
