import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qubriux-loading',
  templateUrl: './qubriux-loading.component.html',
  styleUrls: ['./qubriux-loading.component.css']
})
export class QubriuxLoadingComponent implements OnInit {

  @Input() size: number = 30;
  @Input() loadingText: string;
  constructor() { }

  ngOnInit(): void {
  }

}
