<div class="qb-tabs-mobile">
  <div
    [ngClass]="selectedTab == 'email' ? 'tab-selected' : 'tab-not-selected'"
    (click)="selectTab('email')"
  >
    {{'CUSTOMER_PAGE.EMAIL' | i18nTranslate}}
  </div>
  <div
    [ngClass]="selectedTab == 'sms' ? 'tab-selected' : 'tab-not-selected'"
    (click)="selectTab('sms')"
    *ngIf="(validScreens | screenConfig: 18)"
  >
    {{'CUSTOMER_PAGE.SMS' | i18nTranslate}}
  </div>
</div>

<div *ngIf="selectedTab == 'email'" class="qb-h-100">
  <div class="qb-tabContent">
    <div class="qb-contentSuppressionList">
      <app-email-suppression-list></app-email-suppression-list>
    </div>
  </div>
</div>

<div *ngIf="selectedTab == 'sms'" class="qb-h-100">
  <div class="qb-tabContent">
    <div class="qb-contentSuppressionList">
      <app-sms-suppression-list></app-sms-suppression-list>
    </div>
  </div>
</div>