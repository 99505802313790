import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { channel } from "diagnostics_channel";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { DialogData } from "../ecommerce-dialog/ecommerce-dialog.component";

@Component({
  selector: "app-social-media-pages-select-dialog",
  templateUrl: "./social-media-pages-select-dialog.component.html",
  styleUrls: ["./social-media-pages-select-dialog.component.css"],
})
export class SocialMediaPagesSelectDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SocialMediaPagesSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _apiCallService: ApiCallService
  ) {}

  socialChannelList = [];
  selectedAccounts = [];
  selectedPages = [];
  socialChannelListAds = [];
  pageListLoading = true;
  saveLoading = false;
  selectedAd = {
    "adAccountId": null,
    "adAccountName": null,
    "businessName": null,
    "message": null
};

message = "";
  ngOnInit() {
    console.log(this.data);
    if (
      this.data["code"] &&
      this.data["code"] != "" &&
    (this.data["channel"] == "facebook" || this.data["channel"] == "instagram")
    ) {
      
      this._apiCallService.getSocialMediaPagesCode(this.data["channel"], this.data["code"]).subscribe(
        (response) => {
          let channelList=response["body"];
          this.socialChannelList = [];
          console.log(this.data, channelList)
          this.socialChannelListAds = []
          if(Object.keys(response['body']['facebookAdAccounts']).length == 1 && response['body']['facebookAdAccounts'][0]['message'] !== null) {
            this.message = response['body']['facebookAdAccounts'][0]['message'];
          }
          
          if(this.data["socialPagesIdList"]!=null &&this.data["socialPagesIdList"].length>0 ){
    
            for(let i=0;i<channelList['facebookAdAccounts'].length;i++){
                if(!this.data["socialPagesIdList"].includes(channelList['facebookAdAccounts'][i]["adAccountId"])){
                  this.socialChannelListAds.push(channelList['facebookAdAccounts'][i]);
                }
            }
            for(let i=0;i<channelList['facebookPagesList'].length;i++){
              if(this.data['channel'] === 'instagram') { 
              if(!this.data["socialPagesIdList"].includes(channelList['facebookPagesList'][i]['instagramProfile']['id'])){
                if(channelList['facebookPagesList'][i]['instagramProfile'] === null || channelList['facebookPagesList'][i]['instagramProfile']['id'] === null) {
                }
                else
                this.socialChannelList.push(channelList['facebookPagesList'][i]);   
              } 
              //  else {
              //   this.socialChannelList.push(channelList['facebookPagesList'][i]);   
              // }       
              }
              if(this.data['channel'] === 'facebook'){
                if(!this.data["socialPagesIdList"].includes(channelList['facebookPagesList'][i]["id"])){
                  this.socialChannelList.push(channelList['facebookPagesList'][i]);   
                }

              }
          }
          
            //this.selectedPages.push()
          }else{
            this.socialChannelList =channelList['facebookPagesList'];
            this.socialChannelListAds = channelList['facebookAdAccounts'];
          }
          this.pageListLoading = false;
        },
        (error) => {
       
          this.pageListLoading = false;
        }
      );
    }
  }

  changeadaccount(event, page) {
    this.selectedAd = page;
  }

  checkChanged(event, pageId) {
    if (event.target.checked) {
      let index: number = this.selectedPages.indexOf(pageId);
      if (index == -1) {
        this.selectedPages.push(<String>pageId);
      }
    } else {
      let index: number = this.selectedPages.indexOf(pageId);
      if (index !== -1) {
        this.selectedPages.splice(index, 1);
      }
    }
     this.selectedAccounts = [];
    this.selectedPages.forEach(id => {
      Object.keys(this.socialChannelList).forEach((key, value) => {
        if(this.socialChannelList[key]['id'] === id) {
          if(this.data['channel'] === 'instagram')
          this.selectedAccounts.push({"fbPageId": id, "igId": this.socialChannelList[key]['instagramProfile']['id']})
          else
          this.selectedAccounts.push({"fbPageId": id});

        }
      });
    })
  }

  dummytest() {
    this.selectedPages.push("101776435813060");

  }
  close(value) {
    if (!this.saveLoading) this.dialogRef.close(value);
  }

  save(value) {
    let ads = [];
    if (this.selectedPages.length > 0 || this.selectedAd.adAccountId !== null) {
      this.saveLoading = true;
      if(this.selectedAd.adAccountId === null) {
      ads = [];
      } else {
        ads = [this.selectedAd];
      }
      console.log(this.selectedAccounts,this.selectedPages)
      this._apiCallService.saveFbPages(this.selectedAccounts, this.data["channel"], ads).subscribe(
        (response) => {
      
          this.saveLoading = false;
          this.close(value);
        },
        (error) => {

          this.saveLoading = false;
        }
      );
    } else {
    }
  }
  selectAllClicked(){
    for(let i=0;i<this.socialChannelList.length;i++){
      if(!this.data["socialPagesIdList"].includes(this.socialChannelList[i]["id"])){
        let index: number = this.selectedPages.indexOf(this.socialChannelList[i]["id"]);
        if (index == -1) {
          this.selectedPages.push(this.socialChannelList[i]["id"]);
        }
      }
  }
  this.selectedAccounts = [];
  this.selectedPages.forEach(id => {
    Object.keys(this.socialChannelList).forEach((key, value) => {
      if(this.socialChannelList[key]['id'] === id) {
        if(this.data['channel'] === 'instagram')
        this.selectedAccounts.push({"fbPageId": id, "igId": this.socialChannelList[key]['instagramProfile']['id']})
        else
        this.selectedAccounts.push({"fbPageId": id});

      }
    });
  })
  console.log("DATA",this.selectedPages);
  }
}
