import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { ConnectPaypalComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-partnership-requests",
  templateUrl: "./partnership-requests.component.html",
  styleUrls: ["./partnership-requests.component.css"],
})
export class PartnershipRequestsComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private matDialog: MatDialog,
    private router: Router,
    private date_format: getdateformatService
  ) {}

  pageIndex = 1;
  pageSize = 10;
  length = 0;
  searchString = "";
  timeout;
  loading = false;
  requestList = [];
  tableHeadings = ['Partner', 'Request Sent/Received On', 'Request Type', 'Action'];

  ngOnInit() {
    this.getRequestList();
  }

  getRequestList() {
    this.loading = true;
    this.apiCall
      .getPartnerRequestList(this.pageIndex, this.pageSize, this.searchString)
      .subscribe((response) => {
        this.requestList = response["body"]["list"];
        this.length = response["body"]["count"];
        this.requestList.forEach((partner) => {
          partner["link"] = "https://" + partner["shopUrl"];
        });
        this.loading = false;
      });
  }

  openConnectToPaypalDialog(id) {
    const d = this.matDialog.open(ConnectPaypalComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
    });
    d.afterClosed().subscribe((res) => {
      if (res) {
        this.accept(id);
      }
    });
  }

  checkPaypalID(id) {
    this.apiCall.getPaypalID().subscribe((response) => {
      if (response["body"] === "Paypal id doesn't exist") {
        this.openConnectToPaypalDialog(id);
      } else {
        this.accept(id);
      }
    });
  }

  //Process partnership when partnership request is accepted
  accept(id) {
    this.apiCall
      .processPartnershipRequest(
        id,
        "REQUEST_ACCEPTED",
        null,
        null,
        null,
        null,
        null,
        false
      )
      .subscribe((response) => {
        this.getRequestList();
      });
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getRequestList();
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 1;
        $this.getRequestList();
      }
    }, 600);
  }

  viewRequest(name, id, status) {
    let action;
    if (status === "REQUEST_RECEIVED") {
      action = "view";
    } else {
      action = "sent";
    }
    this.rerouteToPartnershipRequests(name, id, action);
  }

  rerouteToPartnershipRequests(name, id, action) {
    this.router.navigate([
      "/app/partnership/partnership-requests/" + name,
      {
        id: id,
        action: action,
      },
    ]);
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
