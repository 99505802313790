import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-standard-campaign-activate-dialog',
  templateUrl: './standard-campaign-activate-dialog.component.html',
  styleUrls: ['./standard-campaign-activate-dialog.component.css']
})
export class StandardCampaignActivateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StandardCampaignActivateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  closeDialog(status){
    this.dialogRef.close(status)
  }

}
