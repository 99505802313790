<div style="display: flex;justify-content: space-between;width: 100%;padding:15px">

    <div class="surveyNameText">
        <div>{{'SHARED_PAGE.TEMPLATE_NAME' | i18nTranslate}}: {{subject}}</div>
    </div>
    <div style="cursor: pointer; display: flex; align-items: center;" >
      <!-- <div style="margin-right: 26px;">
      <button (click)="openSendTestEmailDialog()" style="background-color: #076AA9; border: none; color: white;
    padding: 7px 12px;
    border-radius: 5px; font-size: 12px;">Send Test Email</button></div> -->
        <mat-icon class="icons-primary" style="font-family: 'Material Icons';margin-right: 25px;" (click)="createSharableLink()"  matTooltip="Email Preview Link">share</mat-icon>
        <mat-icon style="font-family: 'Material Icons';margin-right: 25px;"(click)="submit('edit')" *ngIf="data['type']!='emailtemplate'&&data.isEditAllowed!=false">edit</mat-icon>
        <mat-icon style="font-family: 'Material Icons';"(click)="submit(false)">close</mat-icon>
    </div>
</div>

<div class="seperator1"></div>

<div *ngIf="!loading" style="pointer-events: none; padding: 15px">
  <app-email-template-preview
    [selectedLayout]="htmlrend"
  ></app-email-template-preview>
</div>
<div *ngIf="loading" style="pointer-events: none; padding: 15px">
  <div
    style="
      display: flex;
      width: 100%;
      margin-top: 40px;
      justify-content: center;
      align-items: center;
    "
  >
    <mat-spinner diameter="22 " color="accent"></mat-spinner>
  </div>
</div>
