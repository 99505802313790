<div class="qb-body">
  <div>
    <div class="qb-title" *ngIf="isRedirect"><strong>{{'PARTNERSHIP_PAGE.REMOVE_OFFER_FROM' | i18nTranslate}} {{formName}}</strong></div>
    <div class="qb-title" *ngIf="!isRedirect"><strong>{{'PARTNERSHIP_PAGE.FORM_DISABLED_DATA' | i18nTranslate : formName}}</strong></div>
    <div *ngIf="isRedirect">
    <span *ngFor="let offer of names; let i = index;"><strong>{{offer}}</strong>
      <span *ngIf='i == 0 && names.length == 3' >, </span>
      <span *ngIf='(i == 0 && names.length == 2) || (i == 1 && this.names.length == 3)' > {{'PARTNERSHIP_PAGE.AND_SMALL' | i18nTranslate}} </span>
    </span>
    <span> {{'PARTNERSHIP_PAGE.DISABLED_THEIR_OFFER' | i18nTranslate}}</span>
    </div>
    <div *ngIf="!isRedirect">
      <div>{{'PARTNERSHIP_PAGE.FORM_DISABLED_MESSAGE' | i18nTranslate : formName}}</div>
    </div>
    <div class="qb-remove-offer-from" *ngIf="isRedirect"><strong>{{'PARTNERSHIP_PAGE.REMOVE_OFFER_FROM_EDITOR' | i18nTranslate}}</strong></div>
    <div *ngIf="isRedirect" class="qb-list-title">{{'PARTNERSHIP_PAGE.REMOVE_HIGHLIGHTED_MESSAGE' | i18nTranslate}}</div>
    <div *ngIf="!isRedirect"><strong>{{'PARTNERSHIP_PAGE.OFFER_USED_IN_FORM' | i18nTranslate}}</strong></div>
    <div class="qb-offer-list" *ngFor="let offer of offerData">
      <ul>
        <li [ngClass]="{ 'qb-active-offer': offer['isActive'], 'qb-disabled-offer':!offer['isActive']}" *ngIf="offer['isActive'] !== null">
          {{offer['description']}}</li>
      </ul>
      <div [ngClass]="{ 'qb-reason':!offer['isActive'], 'qb-reason-default':offer['isActive']}" *ngIf="offer['reasonDisabled'] !== null && offer['isActive'] !== null"><strong>Reason</strong> - {{offer['reasonDisabled']}}</div>

    </div>
    <div class="qb-buttons" *ngIf="isRedirect">
      <div class="secondary-button-border" (click)="cancel()">{{'PARTNERSHIP_PAGE.CANCEL' | i18nTranslate}}</div>
      <!-- <div class="primary-button-border" (click)="editForm()">Edit Form Config</div> -->
      <div class="primary-button" (click)="update()">{{'PARTNERSHIP_PAGE.UPDATE_EXISTING_FORM' | i18nTranslate}}</div>
    </div>
    <div *ngIf="!isRedirect" class="qb-buttons qb-disabled-buttons">
      <div class="secondary-button-border qb-not-allowed">{{'PARTNERSHIP_PAGE.CANCEL' | i18nTranslate}}</div>
      <!-- <div class="primary-button-border qb-not-allowed">Edit Form Config</div> -->
      <div class="primary-button qb-not-allowed">{{'PARTNERSHIP_PAGE.UPDATE_EXISTING_FORM' | i18nTranslate}}</div>
    </div>
  </div>
  <div>
    <div class="qb-top">
      <div [innerHtml]="formHtml | safeHtml"></div>
    </div>
    
  </div>
</div>