import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription, debounce } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";

@Component({
  selector: "app-search-customer-dialog-box",
  templateUrl: "./search-customer-dialog-box.component.html",
  styleUrls: ["./search-customer-dialog-box.component.css"],
})
export class SearchCustomerDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SearchCustomerDialogBoxComponent>,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private snackBar: SnackbarCollection
  ) {}

  searchValue = "";
  loading = false;
  pageSize = 10;
  pageIndex = 1;
  showCustomers = "all";
  filterSegments = [];
  customers = [];
  totalCustomers;
  overallTotalCustomers;
  revenueTime = "ALL";
  numberOfOrdersTime = "ALL";
  sub: Subscription;
  timeout;

  merchantID = this.tokenService.getMerchantId();
  ngOnInit(): void {
    this.getCustomers();
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.pageIndex += 1;
      if (this.showCustomers === "all") {
        this.getCustomers();
      } else {
        this.searchCustomer(this.searchValue, false);
      }
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  selectCustomer(id){
    this.dialogRef.close(id);
  }

  getCustomers(resetPagination?) {
    this.loading = true;
    if (resetPagination) {
      this.pageIndex = 1;
      this.customers = [];
    }
    this.apiCall
      .getCustomersV2(
        this.merchantID,
        this.pageSize,
        this.pageIndex,
        this.filterSegments
      )
      .subscribe(
        (response) => {
          this.showCustomers = "all";
          response["body"]["customerDataList"].forEach((element) => {
            this.customers.push(element);
          });
          this.totalCustomers = response["body"]["totalCount"];
          this.overallTotalCustomers = this.overallTotalCustomers
            ? this.overallTotalCustomers
            : response["body"]["totalCount"];
          this.loading = false;
        },
        (err) => {
          this.snackBar.openSnackBar("Failed to fetch custom list", 5000);
        }
      );
  }

  searchCustomer(searchValue, resetPagination) {
    this.loading = true;
    if (resetPagination) {
      this.pageIndex = 1;
      this.customers = [];
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .searchCustomers(
        this.merchantID,
        searchValue,
        this.revenueTime,
        this.numberOfOrdersTime,
        this.pageSize,
        this.pageIndex,
        this.filterSegments
      )
      .subscribe(
        (response) => {
          this.showCustomers = "search";
          response["body"]["customerDataList"].forEach((element) => {
            this.customers.push(element);
          });
          this.searchValue = searchValue;
          this.totalCustomers = response["body"]["totalCount"];
          this.loading = false;
        },
        (err) => {
          this.snackBar.openSnackBar("Failed to search customer", 5000);
        }
      );
    if (searchValue === "") this.getCustomers();
  }

  onKeySearch(event: any, resetPagination) {
    clearTimeout(this.timeout);
    var $this = this;
    if (event.length !== 0) {
      this.timeout = setTimeout(function () {
        // if (event.keyCode != 13) {
        $this.searchCustomer(event, resetPagination); //Your function goes here
        // }
      }, 600);
    } else {
      //if search value is 0
      this.getCustomers(resetPagination);
    }
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.showCustomers === "all") this.getCustomers();
    else this.searchCustomer(this.searchValue, false);
  }
}
