import { Component, Input, OnInit, Output,EventEmitter, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { SignoutService } from 'src/app/core/services/signout.service';

@Component({
  selector: 'app-store-keys',
  templateUrl: './store-keys.component.html',
  styleUrls: ['./store-keys.component.css']
})
export class StoreKeysComponent implements OnInit {

  // @Input() data: { storeId: string; storeName: string };
  // @Output() dataChanged = new EventEmitter<string>();
  constructor(private _apicallService:ApiCallService,private _formBuilder: FormBuilder,private _tokenStorageServie:TokenStorageService,
    private _activatedRoute: ActivatedRoute,private _router:Router,private clipboard: Clipboard,
    private renderer: Renderer2, private snackBar: SnackbarCollection,private _widgetConfigService:WidgetConfigService,private _signoutService:SignoutService) {
      this._activatedRoute.queryParams
      .subscribe(params => {
       this.storeQid=params.id;
        // this.editMode=false;
        // this._tokenStorage.saveStoreId(this.storeQId);
      }
    );
     }
     data: { storeId: string; storeName: string };
  storeData: any;
  selectedPlatform: string;
  form: FormGroup;
  platforms: { label: string; value: string }[] = [
    { label: 'Brink', value: 'brink' },
    { label: 'Posist', value: 'posist' },
    // Add other platforms here
  ];
isloading:boolean=false;
storeQid=null;
showCopyMessage:boolean=false;
isSaved:boolean=false;
isApiLoading:boolean=false;
isPosSpecificInputDataFieldEditable:boolean=false;

nonEditablePlatforms:string[]=["shopify","lightspeed"]
  ngOnInit(): void {
    let currentPlatform= this._tokenStorageServie.getMerchantOnlineStore();
    if(currentPlatform==null){
    this.logout();  
    }
if(!this.nonEditablePlatforms.includes(currentPlatform)){
  this.isPosSpecificInputDataFieldEditable=true;
}
    console.log(this.data);
    if(this.storeQid!=null){
    this.getStoreData(this.storeQid);
    }
  }

  getStoreData(storeQId: string) {
    this.isloading=true;
    this.isApiLoading=true;
    this._apicallService.getStoreAccessData(storeQId).subscribe(
      (response) => {
        this.storeData = response["body"];
        console.log(this.storeData);

        this.selectedPlatform = this._tokenStorageServie.getMerchantOnlineStore();
        this.createFormControls();
        if(this.selectedPlatform == 'posist') {
          this.storeId = JSON.parse(this.storeData.posSpecificInputData)['storeMetaDataId'];
        }
        this.isloading=false;
        this.isApiLoading=false;
      },
      (error) => {
        console.error('Error fetching store data:', error);
        this.snackBar.openSnackBar("Error loading", 2000);

      }
    );
  }
  onPlatformChange() {
    this.createFormControls();
  }
  createFormControls() {
    this.form = this._formBuilder.group({});

    // Dynamically create form controls based on the platform's input data
    // if (this.selectedPlatform === 'brink') {
      const posModel = JSON.parse(this.storeData.posSpecificInputData || '{}');
      for (const key in posModel) {
        this.form.addControl(key, this._formBuilder.control(posModel[key]));
      // }
    // } else if (this.selectedPlatform === 'posist') {
    //   const posistModel = JSON.parse(this.storeData.posSpecificInputData || '{}');
    //   for (const key in posistModel) {
    //     this.form.addControl(key, this._formBuilder.control(posistModel[key]));
    //   }
    }
  }
  // onSave() {
  //   if (this.form.valid) {

  //     // Convert form values to JSON
  //     const formData = JSON.stringify(this.form.value);
  //     console.log(formData);

  //   }else{
  //     console.log("form invalid");
  // }
  // }

  enableStoreEdit(){
// this._activatedRoute.queryParams.subscribe(params => {
//   const updatedParams = { ...params, id: this.storeQid };
//   this._router.navigate([], { queryParams: updatedParams });
// });
this._router.navigate(['/app/user-profile/store-info/store-details']);
  }
  onSave(): void {
    console.log(this.form);
    if (this.form.valid) {
      let invalid = false;
      for (const controlName in this.form.controls) {
        if (this.form.controls.hasOwnProperty(controlName)) {
          const control = this.form.controls[controlName];
          if (control.value === null || control.value === '') {
            invalid = true;
            control.markAsTouched();
          }
        }
      }
      if(invalid){
        console.log("Form is invalid. Please fill in all required fields.");
        return
      }
      const formData = this.form.value;
      console.log(formData);
      const formData2 = JSON.stringify(this.form.value);
      // console.log(formData2);
      let data ={
        "storeId": this.storeQid,
        "merchantId":this._tokenStorageServie.getMerchantId(),
"posPlatform":this._tokenStorageServie.getMerchantOnlineStore(),
"posSpecificInputData":formData2

      }
      this.saveStoreAccessData(data);

    } else {
      this.markAllFieldsAsTouched(this.form);
    }
  }
  markAllFieldsAsTouched(group: FormGroup): void {
    Object.keys(group.controls).forEach((key) => {
      const control = group.get(key);
      if (control instanceof FormGroup) {
        this.markAllFieldsAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  
  copyToClipboard(envValue:string,value: string) {
    let finalValue=envValue+value;
    this.clipboard.copy(finalValue);
    // Display "copied" for 1 second
  // const tooltip = this.renderer.createElement('span');
  // this.renderer.addClass(tooltip, 'tooltiptext');
  // this.renderer.appendChild(tooltip, this.renderer.createText('Copied'));
  // this.renderer.appendChild(finalValue, tooltip);


  this.snackBar.openSnackBar("Copied!", 1500);

  }
  saveStoreAccessData(data){
    this.isApiLoading=true;
    this._apicallService.saveStoreAccessData(data).subscribe((response)=>{
      console.log(response);
      this.snackBar.openSnackBar("Saved", 2000);
      this.isSaved=true;
      this.isApiLoading=false;
      this._widgetConfigService.getAppSubscriptionPlan();
      this.routePage();
    },(error)=>{
      this.snackBar.openSnackBar("Error saving", 2000);
      this.isSaved=false;
      this.isApiLoading=false;

    })
  }
  logout() {
    console.log("logout");
    this._signoutService.signOut();
    this._tokenStorageServie.setUserLogin("false");
    this._router.navigate(["auth/login"], {});
  }
  routePage() {
    this._router.navigate(["/app/user-profile"], {
      queryParams: { layout: "billing" },
    });
  }
  copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    this.snackBar.openSnackBar("Text Copied!", 1500);
  }


  storeId:string = '';
  onSavePosist() {
    let temp = JSON.stringify({
      storeMetaDataId : this.storeId
    });
    let data ={
      "storeId": this.storeQid,
      "merchantId":this._tokenStorageServie.getMerchantId(),
      "posPlatform":this._tokenStorageServie.getMerchantOnlineStore(),
      "posSpecificInputData": temp,
    }
    this.saveStoreAccessData(data);
  }
}
