import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-connect-paypal',
  templateUrl: './connect-paypal.component.html',
  styleUrls: ['./connect-paypal.component.css']
})
export class ConnectPaypalComponent implements OnInit {

  constructor(private apiCall: ApiCallService, private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    public dialogRef: MatDialogRef<ConnectPaypalComponent>,) { }

    email;
    emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
    error = "";


  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    payPalId: ""
  }

  ngOnInit() {
  }

  add() {
      if (!this.data.payPalId.match(this.emailPattern.trim())) {
        this.error = this._i18nDynamicTranslate.transform("Please enter a valid Paypal ID", ['SHARED_PAGE']);
        console.log(this.error);
      } else {
        this.apiCall.createPaypalID(this.data).subscribe(response => {
          console.log(response);
          this.dialogRef.close(true);
        })      
      }
    } 

}
