import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-plan-features",
  templateUrl: "./plan-features.component.html",
  styleUrls: ["./plan-features.component.css"],
})
export class PlanFeaturesComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PlanFeaturesComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {}
}
