<div style="display: flex; justify-content: center">
    <div style="width:100%;">
        <div style="display:flex; justify-content:center;">
            <div style="width: 100%; margin: 4%; margin-top: 0%;" class="box">
                <form [formGroup]="campaignActionForm">
                    <div style="margin:2%;">
                        <div style="margin-bottom: 25px; margin: 2%;" class="allFont">
                            <span class="fontOpenSans" style="text-transform: uppercase; font-weight: bold; font-size: 14px;">create
                              journey template</span>
                        </div>
                        <div style="display:flex;">
                            <div class="commonInputDiv">
                                <div style="width:90%;">
                                    <div class="labelPadding fontOpenSans">
                                        <span>Journey Name</span>
                                    </div>
                                    <div>
                                        <div class="formDiv" [ngClass]="{'active' : (campaignActionForm['controls']['campaignTemplateName']['errors'] ? campaignActionForm['controls']['campaignTemplateName']['errors']['maxlength'] : false) || (campaignActionForm['controls']['campaignTemplateName']['errors'] ? (campaignActionForm['controls']['campaignTemplateName']['errors']['required'] && campaignActionForm['controls']['campaignTemplateName']['touched'] || submitClicked) : false)}">
                                            <input class="formInput fontOpenSans" style="text-align:center ;" type="text" formControlName="campaignTemplateName">
                                        </div>
                                    </div>
                                    <div style="height:13px">
                                        <span style="font-size: 12px; color: #ff5f31;" *ngIf="campaignActionForm['controls']['campaignTemplateName']['errors'] ? campaignActionForm['controls']['campaignTemplateName']['errors']['maxlength'] : false">Maximum
                      50 characters allowed</span>
                                        <span style="font-size: 12px; color: #ff5f31;" *ngIf="campaignActionForm['controls']['campaignTemplateName']['errors'] ? (campaignActionForm['controls']['campaignTemplateName']['errors']['required'] && campaignActionForm['controls']['campaignTemplateName']['touched'] || submitClicked) : false">Journey
                      name is important</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex;">
                            <div class="commonInputDiv" style="margin-top: 2%; margin-bottom: 2%;">
                                <div style="width:90%;">
                                    <div>
                                        <div style="display:flex;" class="labelPadding fontOpenSans">
                                            <span>Target customer segments</span>
                                        </div>
                                    </div>
                                    <div *ngIf="segments">
                                        <div class="formDiv" [ngClass]="{'active' : (campaignActionForm['controls']['targetCustomerSegments']['errors'] ? (campaignActionForm['controls']['targetCustomerSegments']['errors']['required'] && campaignActionForm['controls']['targetCustomerSegments']['touched'] || submitClicked) : false)}">
                                            <mat-icon style="color: #88c4fb; padding: 10px;">scatter_plot</mat-icon>
                                            <mat-select (selectionChange)="changeSegmentSelection()" class=" fontOpenSans" color="primary" placeholder="Select customer segment" formControlName="targetCustomerSegments" multiple>
                                                <mat-option [disabled]="campaignActionForm.controls.selectedCampaignTriggerType['value'] !== 'EVENT_TRIGGER'" value="ALL_CUSTOMERS">My Customers</mat-option>
                                                <mat-option [disabled]="disableCustomerSegments" class="fontOpenSans" *ngFor="let segment of segments" [value]="segment.name">
                                                    {{segment.name}}</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                    <div style="height:13px">
                                        <span style="font-size: 12px; color: #ff5f31;" *ngIf="campaignActionForm['controls']['targetCustomerSegments']['errors'] ? (campaignActionForm['controls']['targetCustomerSegments']['errors']['required'] && campaignActionForm['controls']['targetCustomerSegments']['touched'] || submitClicked) : false">Select
                      atleast 1 segment</span>
                                    </div>
                                </div>
                            </div>
                            <div class="commonInputDiv" style="margin-top: 2%; margin-bottom: 2%;">
                                <div style="width:90%;">
                                    <div>
                                        <div style="display:flex;" class="labelPadding fontOpenSans">
                                            <span>Journey Type</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="display:flex;" class="formDiv">
                                            <mat-select class="formInput fontOpenSans" formControlName="selectedCampaignTriggerType">
                                                <mat-option value="INSTANT_TRIGGER">Instant Journey</mat-option>
                                                <mat-option value="TIME_TRIGGER">Scheduled Journey</mat-option>
                                                <!-- <mat-option value="EVENT_TRIGGER">Event based</mat-option> -->
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="commonInputDiv" style="margin-top: 2%; margin-bottom: 2%;" *ngIf="campaignActionForm['controls']['selectedCampaignTriggerType']['value'] === 'TIME_TRIGGER'">
                                <div style="width:90%;">
                                    <div>
                                        <div style="display:flex;" class="labelPadding fontOpenSans">
                                            <span>Repeat</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="display:flex;" class="formDiv">
                                            <mat-select class="formInput fontOpenSans" formControlName="selectedCampaignRepeat">
                                                <mat-option value="NO_REPEAT">No repeat</mat-option>
                                                <mat-option value="WEEK">Week</mat-option>
                                                <mat-option value="MONTH">Month</mat-option>
                                                <mat-option value="YEAR">Year</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="commonInputDiv" style="margin-top: 2%; margin-bottom: 2%;" *ngIf="campaignActionForm['controls']['selectedCampaignTriggerType']['value'] === 'EVENT_TRIGGER'">
                                <div style="width:90%;">
                                    <div>
                                        <div style="display:flex;" class="labelPadding fontOpenSans">
                                            <span>Event Type</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="display:flex;" class="formDiv">
                                            <mat-select class="formInput fontOpenSans" formControlName="selectedCampaignEvent" style="margin-left:-4%;">
                                                <mat-option value="BDAY">Birthday</mat-option>
                                                <!-- <option value="Welcome">Welcome</option>
                        <option value="Anniversary">Anniversary</option>
                        <option value="Festival">Festival</option> -->
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div style="margin: 2%;">
              <div class="TargetTextDiv labelPadding fontOpenSans">
                <span>Target customer segments</span>
              </div>
              <div *ngIf="segments" style="width:33.3%">
                <div class="formDiv" style="width: 90%;">
                  <mat-icon style="color: #88c4fb; padding: 10px;">scatter_plot</mat-icon>
                  <mat-select class=" fontOpenSans" color="primary" placeholder="Segments"
                    formControlName="targetCustomerSegments" multiple>
                    <mat-option class="fontOpenSans" *ngFor="let segment of segments" [value]="segment.name">
                      {{segment.name}}</mat-option>
                  </mat-select>
                </div>
              </div>
            </div> -->
                    </div>
                    <!-- <div style="display:flex; justify-content:center;">
            <div style=" width : 15%;">
              <div class="TargetTextDiv" style="display:flex; justify-content:center; font-size: 1.1vw; margin: 2%;">
                <span>Journey Trigger</span>
              </div>
            </div>
          </div> -->
                    <div formArrayName="selectedCampaignActionDelayType">
                        <div style="display:flex; justify-content: space-around;" *ngFor="let action of campaignActionForm['controls']['selectedCampaignActionDelayType']['controls']; let i = index">
                            <div [formGroupName]="i" style="width: 100%; padding: 4%; padding-bottom: 0%; padding-top: 0%;">
                                <div style="display:flex; justify-content:center;">
                                    <img style="display: flex;" *ngIf="i>0" [src]="assetPath.arrowUrl" alt="">
                                </div>
                                <div style="width:100%; border:1px solid #DEDEDE; border-radius: 4px;">
                                    <div style="display:flex; justify-content:space-between;">
                                        <div style="padding:15px; font-size: 16px; ">
                                            <span>Journey Action</span>
                                        </div>
                                        <div style="display:flex; justify-content: flex-end;">
                                            <div *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'].length > 1" style="padding-top: 10px;
                      padding-right: 10px;">
                                                <div (click)="deleteCampaign(i)" style="cursor: pointer;
                        border: 1px solid #5E5E5E;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0.5;">
                                                    <mat-icon style="color: #aeaeae; opacity: 1;">delete_outline</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div style="display:flex; justify-content: center;">
                    <div>
                      <mat-icon style="font-size:50px; color:#8080804a; width: 100%;">arrow_downward</mat-icon>
                    </div>
                  </div> -->
                                    <div style="display:flex;">
                                        <div class="TargetTextDiv" style="width:33.33%; display:flex; justify-content: center;">
                                            <div style="width:90%;">
                                                <div style="width: 100%;">
                                                    <div class="labelPadding fontOpenSans">
                                                        <span>Delay</span>
                                                    </div>
                                                    <div class="formDiv" [ngClass]="{'active' : (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors'] ? campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors']['min'] : false)
                            || (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors']['required'] && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['touched'] || submitClicked) : false)}">
                                                        <mat-icon style="color: #88c4fb; padding: 10px;">timer</mat-icon>
                                                        <input class="formInput fontOpenSans" type="number" placeholder="count" style="width: 30%;" formControlName="DelayCount">
                                                        <mat-select class="formInput fontOpenSans" formControlName="DelayType" style="width: 70%;">
                                                            <mat-option value="hour">Hour(s)</mat-option>
                                                            <mat-option value="day">Day(s)</mat-option>
                                                            <mat-option value="week">Week(s)</mat-option>
                                                        </mat-select>
                                                    </div>
                                                    <div style="height:13px; font-size:12px; color:#ff5f31" class="fontOpenSans">
                                                        <span *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors'] ? campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors']['min'] : false">Count
                              value should be greater than or equal to 0</span>
                                                        <span *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors']['required'] && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['touched'] || 
                              (submitClicked && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['DelayCount']['errors']['required'])) : false">Count
                              is important</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div style="display:flex; justify-content: center;">
                          <div>
                            <mat-icon style="font-size:50px; color:#8080804a; width: 100%;">arrow_downward</mat-icon>
                          </div>
                        </div> -->
                                        <!-- <div style="display:flex; justify-content:center; font-size: 1.1vw; margin: 2%; font-weight: bold;">
                              <span>Email Template</span>
                            </div> -->
                                        <!-- <div class="TargetTextDiv" style="width:33.33%; display:flex; justify-content: center;">
                      <div style="width:90%;">
                        <div style="width: 100%;">
                          <div class="labelPadding fontOpenSans">
                            <span>Action</span>
                          </div>
                          <div class="formDiv">
                            <mat-icon style="color: #88c4fb; padding: 10px;">mail</mat-icon>
                            <mat-select class="formInput fontOpenSans" (selectionChange)="selectEmailType(i)"
                              formControlName="ActionMedium">
                              <mat-option value="EMAIL_DELIVERY">Send Email</mat-option>
                            </mat-select>
                          </div>
                        </div>
                      </div>
                    </div> -->
                                        <div class="TargetTextDiv" style="width:33.33%; display:flex; justify-content: center;">
                                            <div style="width:90%;">
                                                <div style="width: 100%;">
                                                    <div class="labelPadding fontOpenSans">
                                                        <span *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'EMAIL_DELIVERY'">Type
                              of email</span>
                                                        <span *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'SMS_DELIVERY'">Type
                              of sms</span>
                                                    </div>
                                                    <div class="formDiv">
                                                        <mat-select (selectionChange)="autoselectOfferTemplate(i)" class="formInput fontOpenSans" formControlName="ActionType">
                                                            <mat-option value="BASIC_TEMPLATE">Plain</mat-option>
                                                            <mat-option *ngIf="masterOffer" value="OFFER_TEMPLATE">Offer</mat-option>
                                                            <!-- <option value="REMINDER_TEMPLATE">Reminder</option>
                              <option value="REWARD_TEMPLATE">Reward</option> -->
                                                        </mat-select>
                                                        <!-- <div style="display: flex; align-items: center; margin:4%;">
                                                    <mat-icon style="font-size: 23px; color:#505050; cursor: pointer;" matTooltip="Select the type of email to sent to the users;
                                                    Plain : Sent a regular mail;
                                                    Offer : Sent an offer email;
                                                    Reminder : Sent an email to remind the user;
                                                    Reward : Sent an email regarding redumption of an offer" matTooltipPosition="after"
                                                      matTooltipClass="toolTipClass">help</mat-icon>
                                                  </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="TargetTextDiv" style="width: 33.33%; display:flex; justify-content: center;">
                                            <div style="width:90%;">
                                                <div class="labelPadding fontOpenSans">
                                                    <span>Type of offer</span>
                                                </div>
                                                <div class="formDiv">
                                                    <mat-icon *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'] === 'OFFER_TEMPLATE'" style="color: #88c4fb; padding: 10px;">local_offer</mat-icon>
                                                    <mat-icon *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'] !== 'OFFER_TEMPLATE'" style="color: #d2d2d2; padding: 10px;">local_offer</mat-icon>
                                                    <mat-select (selectionChange)="offerSelectionChange(i)" [disabled]="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'] === 'OFFER_TEMPLATE' ? null : true" class="formInput fontOpenSans" formControlName="OfferId">
                                                        <mat-option *ngFor="let offer of  masterOffer; let i=index;" [value]="offer['offerId']">
                                                            {{offer['offerName']}}</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div style="display:flex; justify-content:center;"
                    *ngIf="campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.value">
                    <span>Offer Selected with id :
                      {{campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.value}}</span>
                    <span style="font-size: 13px;
                        display: flex;
                        align-items: flex-end;
                        text-decoration: underline;
                        color: #1ac8db;
                        cursor: pointer;
                        margin-left: 1%;"
                      (click)="getVariables(campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'],i)">Change
                      offer</span>
                  </div> -->
                                    <div style="margin-top: 3%; margin-bottom: 2%;">
                                        <!-- <div style="display: flex;
                        justify-content: center;
                        margin: 2%;">
                          <span>How do yo like to add Email Template?</span>
                        </div>
                        <div class="listing">
                          <div class="swapButton"
                            (click)="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailUploadType'] = 'TEMPLATE'"
                            style="border-radius: 8px 0px 0px 8px;">
                            <span>Select from Templates</span>
                          </div>
                          <div class="swapButton"
                            (click)="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailUploadType'] = 'UPLOAD'"
                            style="border-radius: 0px 8px 8px 0px;">
                            <span>Upload a Template</span>
                          </div>
                        </div> -->
                                        <div *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailUploadType'] === 'TEMPLATE'">
                                            <div style="display:flex;">
                                                <!-- <div
                          *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'EMAIL_DELIVERY'"
                          class="TargetTextDiv" style="width: 33.33%; display:flex; justify-content: center;">
                          <div style="width:90%;">
                            <div class="labelPadding fontOpenSans">
                              <span>Email template type</span>
                            </div>
                            <div class="formDiv">
                              <mat-select class="formInput fontOpenSans" formControlName="EmailTemplateType">
                                <mat-option value="STANDARD_LAYOUT">Standard</mat-option>
                              </mat-select>
                            </div>
                          </div>
                        </div> -->
                                                <div *ngIf="emailTemplate && campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'EMAIL_DELIVERY' && campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailTemplateType'] === 'CUSTOM_LAYOUT'" class="TargetTextDiv"
                                                    style="width: 33.33%; display:flex; justify-content: center;">
                                                    <div style="width:90%;">
                                                        <div class="labelPadding">
                                                            <span>Select custom template</span>
                                                        </div>
                                                        <div class="formDiv" [ngClass]="{'active' : (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['messageTemplateId']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['messageTemplateId']['errors']['required'] && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['messageTemplateId']['touched'] || submitClicked) : false)}">
                                                            <div *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'] === 'OFFER_TEMPLATE' &&
                              !campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId']; else displayCustomTemplate">
                                                                <span style="font-size: 13px; color: #ff5252;">select an offer</span>
                                                            </div>
                                                            <ng-template #displayCustomTemplate>
                                                                <mat-select class="formInput fontOpenSans" formControlName="messageTemplateId">
                                                                    <mat-option *ngFor="let email of  emailTemplate; let i=index;" [value]="email['messageTemplateId']">
                                                                        {{email['messageTemplateName']}}</mat-option>
                                                                </mat-select>
                                                            </ng-template>
                                                        </div>
                                                        <div style="height:13px">
                                                            <span style="font-size: 12px; color: #ff5f31;" *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['messageTemplateId']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['messageTemplateId']['errors']['required'] && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['messageTemplateId']['touched'] || submitClicked) : false">Template
                                is mandatory</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="emailLayout && campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'EMAIL_DELIVERY' && campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailTemplateType'] === 'STANDARD_LAYOUT'" class="TargetTextDiv"
                                                    style="width: 33.33%; display:flex; justify-content: center;">
                                                    <div style="width:90%;">
                                                        <div class="labelPadding">
                                                            <span>Email template</span>
                                                        </div>
                                                        <div class="formDiv" [ngClass]="{'active' : (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['errors']['required'] && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['touched'] || submitClicked) : false) ||
                              campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['EmailLayoutContent']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['EmailLayoutContent']['errors']['required'] && submitClicked) : false}">
                                                            <div *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'] === 'OFFER_TEMPLATE' &&
                                !campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId']; else displayStandardTemplate">
                                                                <span style="font-size: 13px; color: #ff5252;">select an offer</span>
                                                            </div>
                                                            <ng-template #displayStandardTemplate>
                                                                <mat-select class="formInput fontOpenSans" formControlName="layoutTemplateId" (selectionChange)="addEmailBody(campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId'],i,
                                  campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'],'CREATE','EMAIL_TEMPLATE')">
                                                                    <mat-option *ngFor="let layout of  emailLayout; let i=index;" [value]="layout['id']">
                                                                        {{layout['layoutName']}}</mat-option>
                                                                </mat-select>
                                                                <div *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['value']" style="width:10%; display: flex; align-items: center; justify-content: center; cursor: pointer;" (click)="addEmailBody(campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId'],i,
                                  campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'],'UPDATE','EMAIL_TEMPLATE')">
                                                                    <mat-icon style="color: #71cbe3; opacity: 1;">edit</mat-icon>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div style="height:13px">
                                                            <span style="font-size: 12px; color: #ff5f31;" *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['errors']['required'] && campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['touched'] || submitClicked) : false">Template
                                is mandatory</span>
                                                            <span style="font-size: 12px; color: #ff5f31;" *ngIf=" campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['layoutTemplateId']['value'] !== '' &&
                                (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['EmailLayoutContent']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['EmailLayoutContent']['errors']['required'] && submitClicked) : false)">Email
                                body is mandatory, click on edit icon</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="smsTemplate &&campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'SMS_DELIVERY'" class="TargetTextDiv" style="width: 33.33%; display:flex; justify-content: center;">
                                                    <div style="width:90%;">
                                                        <div class="labelPadding">
                                                            <span>Sms template</span>
                                                        </div>
                                                        <div *ngIf="!campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['SmsContent']['value']" class="formDiv" [ngClass]="{'active' : (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['SmsContent']['errors'] ? (campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['SmsContent']['errors']['required'] && submitClicked) : false)}"
                                                            style="cursor:pointer;" (click)="addDescriptionText(campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId'],i,
                              campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'],'CREATE','SMS_TEMPLATE')">
                                                            <div *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'] === 'OFFER_TEMPLATE' &&
                              !campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId']; else displayTextAdder">
                                                                <span style="font-size: 13px; color: #ff5252;">select an offer</span>
                                                            </div>
                                                            <ng-template #displayTextAdder>
                                                                <div style="display:flex;">
                                                                    <span style="font-size:13px; display: flex; align-items: center; margin-right: 5px;">Add
                                    sms text</span>
                                                                    <mat-icon style="color: #71cbe3;">add</mat-icon>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div *ngIf="campaignActionForm['controls']['selectedCampaignActionDelayType']['controls'][i]['controls']['SmsContent']['value']" class="formDiv" style="cursor:pointer;" (click)="addDescriptionText(campaignActionForm['value']['selectedCampaignActionDelayType'][i]['OfferId'],i,
                            campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionType'],'UPDATE','SMS_TEMPLATE')">
                                                            <div style="display:flex;">
                                                                <span style="font-size:13px; display: flex; align-items: center; margin-right: 5px;">Edit
                                  sms text</span>
                                                                <mat-icon style="color: #71cbe3;">edit</mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div style="display:flex; justify-content:center; color: #5cb8e7;
                            font-size: 1.2vw; text-decoration: underline; margin: 2%; cursor: pointer;"
                            (click)="selectTemplate('email',i)">
                            <span>Click to select a template</span>
                          </div> -->
                                            <!-- <div style="display:flex; justify-content:center; margin: 2%;">
                        <span
                          style="padding: 8px; display: flex; align-items: center; width: 50%; justify-content: center;">Select
                          Email Template</span>
                        <div style="display:flex; padding:10px; width: 50%; justify-content: center;">
                          <div class="emailTemplateSelectionDiv" (click)="selectTemplate('email',i)">
                            <span>Custom Template</span>
                          </div>
                          <span style="padding: 9px;">OR</span>
                          <div class="emailTemplateSelectionDiv" (click)="selectLayout(i)">
                            <span>Standard Template</span>
                          </div>
                        </div>
                      </div> -->
                                            <!--<div style="display:flex; justify-content: center;">
                         <div style="width:100%;">
                              <div style="display:flex; justify-content: center; margin:2%;"
                                *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]">
                                <textarea style="text-align:center; width:90%; height:30px;"
                                  formControlName="EmailSubject" placeholder="Email Subject"></textarea>
                              </div>
                              <div>
                                <div style="display: flex;
                                  justify-content: center;
                                  margin: 1%;
                                  font-size: 1vw;
                                  color: red;">
                                  <span>*Use '~' to select a variable</span>
                                </div>
                              </div>
                              <div
                                style="display:flex; justify-content: center; margin-bottom: 8%; margin-left: 1%; margin-right: 1%;"
                                *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]">
                                <quill-editor id="quillEditor" (onEditorCreated)="quillCreated($event)"
                                  (onContentChanged)="formContentChange($event,i)" formControlName="EmailContent">
                                </quill-editor>
                              </div>
                            </div> 
                        <div>
                           <quill-view-html [content]="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailContent']" theme="snow"></quill-view-html> 
                           <div [innerHtml]="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailContent'] | safeHtml"></div>
                              <div style="width:100%;" *ngIf="emailLayoutContent" [innerHtml]="emailLayoutContent | safeHtml"></div>
                        </div>
                      </div> -->
                                        </div>
                                        <div *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['EmailUploadType'] === 'UPLOAD'">
                                            <div style="    display: flex;
                          justify-content: center;">
                                                <div style="margin-bottom: 4%;">
                                                    <label> Select Your File
                            <input type="file" (selectionChange)="fileChange($event,i)" style="margin: 4%;">
                          </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div
                    *ngIf="campaignActionForm['value']['selectedCampaignActionDelayType'][i]['ActionMedium'] === 'SMS_DELIVERY'">
                    <div style="display:flex; justify-content:center; color: #5cb8e7;
                          font-size: 1.2vw; text-decoration: underline; margin: 2%; cursor: pointer;"
                      (click)="selectTemplate('email',i)">
                      <span>Click to select a template</span>
                    </div>
                    <div style="display:flex; justify-content: center;">
                      <div style="width:100%;">
                        <span>{{campaignActionForm['value']['selectedCampaignActionDelayType'][i]['SmsContent']}}</span>
                      </div>
                    </div>
                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="display:flex; padding-left: 4%; padding-top: 1%;">
                    <div>
                        <!-- <mat-icon style="font-size:40px; color:#6aeb6a; width: 100%; cursor: pointer;" (click)="addActionForm()">
              add_circle
            </mat-icon> -->
                        <div style="border: 1px solid #0081BC;
              padding: 10px;
              border-radius: 4px;
              font-size: 15px;
              color: #0081BC;
              cursor: pointer;" class="TargetTextDiv" (click)="addActionForm()">
                            <span class="fontOpenSans">+ ADD GROUP</span>
                        </div>
                    </div>
                </div>
                <!-- <div style="margin:2%;">
            <div class="TargetTextDiv" style="margin: 2%;">Offer Templates</div>
          </div>
          <div style="display:flex; justify-content: space-around;">
            <div class="TargetTextDiv" style="width:90%; border:1px solid #8080804a;">
              <div style="display:flex; justify-content:center; font-size: 1.1vw; margin: 5%;">
                <span>Offer Template</span>
              </div>
              <div *ngIf="offerJson"
                style="display:flex; justify-content:center;  color: #c150ff; word-break: break-all; font-size: 0.9vw">
                <span>{{offerJson['offerName']}}</span>
              </div>
              <div *ngIf="!offerJson" style="display:flex; justify-content:center; color: #8080804a; font-size: 0.9vw">
                <span>None Selected</span>
              </div>
              <div style="display:flex; justify-content:center; margin: 5%;">
                <div style="border-radius: 5px; padding: 3%; background: #5cb8e7; color: white; font-size: 1vw; width: 90%;
                display:flex; justify-content: center; cursor: pointer;" (click)="selectTemplate('offer')">
                  <span>Select Template</span>
                </div>
              </div>
            </div>
          </div> -->
                <div class="TargetTextDiv" style="margin-top: 2%; margin-bottom: 2%; display: flex; justify-content: center;">
                    <!-- <div style="border-radius: 5px; padding: 1%; background: #ff6462; color: white; font-size: 1vw;
            display:flex; justify-content: center; cursor: pointer;" (click)="addCampaignConfirm()">
            <span>Add Journey</span>
          </div> -->
                    <div style=" background: transparent linear-gradient(7deg, #07B6B6 0%, #1193D9 100%) 0% 0% no-repeat padding-box;
           padding: 10px;
           border-radius: 4px;
           cursor: pointer;
           color: white;
           font-size: 13px;
           box-shadow: 0px 2px 3px #BABABA29;
           padding-left: 25px;
           padding-right: 25px;" (click)="addCampaignConfirm()">
                        <span>SUBMIT CAMPAIGN</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div style="width:50%">
      <div style="display:flex; justify-content:center;" class="AddCampaignTextDiv">
        <div style="margin: 3%; margin-bottom: 0%;">Select from available Templates</div>
      </div>
      <div style="display:flex; justify-content:center;">
        <div style="width:70%;" *ngIf="masterCampaign">
          <div *ngFor="let campaign of masterCampaign; index as i" style="margin-top: 4%;">
            <div style="background: white; border:1px solid #8080804a;">
              <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                <div><span>Journey Name</span></div>
                <div style="color:#a500ff"><span>{{campaign['campaignName']}}</span></div>
              </div>
              <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                <div><span>Journey Type</span></div>
                <div style="color:#a500ff"><span>{{campaign['campaignType']}}</span></div>
              </div>
              <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                <div><span>Email Template</span></div>
                <div style="color:#5cb8e7; text-decoration: underline;"
                  (click)="viewTemplates(campaign['campaignActions'][0]['campaignMessageTemplate']['messageTemplateId'],'email')">
                  <span>Click to view</span></div>
              </div>
              <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                <div><span>Sms Template</span></div>
                <div style="color:#5cb8e7; text-decoration: underline;"
                  (click)="viewTemplates(campaign['campaignActions'][1]['campaignMessageTemplate']['messageTemplateId'],'sms')">
                  <span>Click to view</span></div>
              </div>
              <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                <div><span>Offers</span></div>
                <div style="color:#5cb8e7; text-decoration: underline;"
                  (click)="viewTemplates(campaign['campaignActions'][2]['offerMaster']['offerId'],'offer')"><span>Click to
                    view</span></div>
              </div>
              <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                <div><span>Journey Trigger</span></div>
                <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['campaignTriggerType']}}</span></div>
              </div>
              <div *ngIf="campaign['campaignTrigger']['campaignTriggerType'] === 'TIME_TRIGGER'">
                <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                  <div><span>Repeat</span></div>
                  <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['campaignTriggerRepeatType']}}</span>
                  </div>
                </div>
                <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                  <div><span>Start</span></div>
                  <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['startDate'].slice(0,10)}}</span></div>
                </div>
                <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                  <div><span>End</span></div>
                  <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['endDate'].slice(0,10)}}</span></div>
                </div>
              </div>
              <div *ngIf="campaign['campaignTrigger']['campaignTriggerType'] === 'EVENT_TRIGGER'">
                <div class="TargetTextDiv" style="display:flex; justify-content:space-between">
                  <div><span>Event</span></div>
                  <div style="color:#5cb8e7;"><span>{{campaign['campaignTrigger']['event']['eventName']}}</span></div>
                </div>
              </div>
              <div *ngIf="campaign['campaignType'] === 'SEGMENTED'" class="TargetTextDiv">
                <span>Customer Segments</span>
              </div>
              <div class="TargetSegmentOuterDiv" *ngIf="campaign['campaignType'] === 'SEGMENTED'">
                <div style="border: 2px solid #5cb8e7; background:#5cb8e7;  padding:1.5%;" class="targetSegmentInnerDiv">
                  <span>At Risk</span>
                </div>
                <div style="border: 2px solid #c150ff; background:#c150ff; padding:1.5%;" class="targetSegmentInnerDiv">
                  <span>New</span>
                </div>
                <div style="border: 2px solid #79ff7a; background:#79ff7a; padding:1.5%;" class="targetSegmentInnerDiv">
                  <span>Exploring</span>
                </div>
                <div style="border: 2px solid #e9ddb3; background:#e9ddb3; padding:1.5%;" class="targetSegmentInnerDiv">
                  <span>Loyal</span>
                </div>
                <div style="border: 2px solid #fea28b; background:#fea28b; padding:1.5%;" class="targetSegmentInnerDiv">
                  <span>Ardant</span>
                </div>
              </div>
              <div class="TargetTextDiv" style="margin-top: 2%;">
                <div style="border-radius: 5px; padding: 3%; background: #ff6462; color: white; font-size: 1vw;
                    display:flex; justify-content: center;">
                  <span>Trigger this campaign</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
</div>
<!-- <div>
  <b>Exclude</b>
  <codemirror [(ngModel)]="testAuto"
    [config]="{mode:'structured', lineWrapping:true, dragDrop:false, extraKeys: {'Ctrl-Space': 'autocomplete'}, hintOptions: {schemaInfo: testAutoCmp}}">
  </codemirror>
</div> -->
<!-- <b>Exclude</b>
     <codemirror (change)="setDirty()" #excludesmall name="excludes" [(ngModel)]="constraint.notWords"
                 [config]="{mode:'structured', lineWrapping:true, dragDrop:false, extraKeys: {'Ctrl-Space': 'autocomplete'}, hintOptions: {schemaInfo: structuredDataTaxonomy}}">
     </codemirror> -->
<!-- <div class="codeMirror">
  <codemirror style="height:90%;" [(ngModel)]="testAuto" #includesmall name="includes"
  [config]="{mode:'structured', lineWrapping:true, dragDrop:false, extraKeys: {'Ctrl-Space': 'autocomplete'}, hintOptions: {hint : comp}}" autofocus>
  </codemirror>
</div> -->
<!-- <div>
  <textarea name="mention" id="mention" cols="30" rows="10" [mention]="mentionItems" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"></textarea>
</div> -->

<footer>
    <div style="height:5%; background:black; width:100%;"></div>
</footer>