export class StoreInfo {
    storeMetaInfo: StoreMetaInfo;
    phoneNumber: string | null;
    email: string;
    timezone:string | null;
    operation:string;
    googleReviewLink:string| null;
    placeId:string;
  
    constructor() {
      this.storeMetaInfo = new StoreMetaInfo();
      this.phoneNumber = null;
      this.email = '';
      this.timezone = null;
      this.googleReviewLink='';
      this.placeId = '';
    }
  }
  
  export class StoreMetaInfo {
    storeId: number | null;
    storeName: string;
    chainId: string;
    platform: string;
    category: string | null;
    tags: string|null;
    isOfferEnabled: boolean | null;
    currency: string | null;
    country: string | null;
    onesignalId: string | null;
    latitude: number;
    longitude: number;
    addressLocationJson: string;
    colourCode: string | null;
    storeStatus: string | null;
    qid: string;
    previewUrl: string | null;
    storeLogo: string;
    storeThumbnail: string;
    upiQrUrl: string | null;
    googlePlaceId: string;
    storeDynamicLink: string | null;
    storeLoyaltyCardLink: string | null;
    storeQRCodeLink: string | null;
    isTableManagementOpted: boolean | null;
    storeOpeningHours: string | null;
    googleReviewLink:string | null;
  
  
    constructor() {
      this.storeId = null;
      this.storeName = '';
      this.chainId = '';
      this.platform = '';
      this.category = null;
      this.tags='';
      this.isOfferEnabled = null;
      this.currency = null;
      this.country = null;
      this.onesignalId = null;
      this.latitude = 0;
      this.longitude = 0;
      this.addressLocationJson = '';
      this.colourCode = null;
      this.storeStatus = null;
      this.qid = '';
      this.previewUrl = null;
      this.storeLogo = '';
      this.storeThumbnail = '';
      this.upiQrUrl = null;
      this.googlePlaceId = '';
      this.storeDynamicLink = null;
      this.storeLoyaltyCardLink = null;
      this.storeQRCodeLink = null;
      this.isTableManagementOpted = null;
      this.storeOpeningHours = null;
      this.googleReviewLink='';
  
    }
  }
  