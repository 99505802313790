import { Injectable } from "@angular/core";
import { WidgetConfigService } from './widget-config.service';
import { currentPlanDataService } from "./current-plan-global.service";

@Injectable({
  providedIn: "root",
})
export class SignoutService {
  constructor(private _widgetConfigService : WidgetConfigService,
    private _currentPlanData: currentPlanDataService) {}

  public signOut() {
    window.localStorage.clear();
    localStorage.setItem("lStatus", "exit20");
    this._widgetConfigService.clearData();
  }
}
