import { Injectable } from "@angular/core";

@Injectable()
export class dataBase{
  updateOffer : boolean;
  selectedManage: string = 'Create offer';
  days = [{ name: 'SUNDAY', status: false },
  { name: 'MONDAY', status: false },
  { name: 'TUESDAY', status: false },
  { name: 'WEDNESDAY', status: false },
  { name: 'THURSDAY', status: false },
  { name: 'FRIDAY', status: false },
  { name: 'SATURDAY', status: false }]
  productCategoryType = []
  productType = []
  propensities: string[] = ['LOW', 'MEDIUM', 'HIGH']
  spentTypes: string[] = ['amount', 'average']
  spentDateRange: string[] = ['week', 'month']
  weekday: [{ "value": boolean }]
  dateTypeSelected: string = 'date';
  selectedReward: string = 'product';
  selectedSpentType: string;
  productHover: boolean = false;
  spentHover: boolean = false;
  selectedDay: string;
  personalizedChecked: boolean = false;
  offerName: string;
  amount: number;
  average: number;
  pType;
  offerTypeSelected: string = 'product';
  offerType = null;
  date;
  minDate;
  maxDate;
  discount;
  discountPercent;
  points;
  daysToRedeem;
  rewardProduct;
  propensity = null;
  barrierProduct;
  productCategory;
  barrierDates = [];
  rewardDiscount = [];
  discountedProducts = [];
  discountedProductCategories = [];
  freeProducts = [];
  reward = [];
  barrierList = [];
  personalizedOffer = [];
  offer = [];
  relation;
  barrierProductId;
  barrierproductCategoryId;
  rewardProductId
  barrierOperators = [];
  finalRewardType;
  finalRewardProduct;
  finalRewardProductCategory;
  finalDiscount;
  finalDiscountPercent;
  finalPoints;
  finalDaysToRedeem;
  finalReward = [];
  productPlusDiscount = [];
  barrierPersonalize = [];
  barrierDiscountArray = [];
  finalDiscountArray = [];
  finalDiscountedProducts = [];
  finalDiscountedProductCategory = [];
  finalFreeProducts = [];
  daysOfTheWeek = [];
  rewardChecked: boolean = false;
  rewardProductCategory;
  rType;
  offerId;
  rewardTypes: string[] = ['product', 'discount', 'points', 'product discount']
  selectedRewards: string = this.rewardTypes[0]
  finalCategoryType;
  smsTemplate : string ;
  emailTemplate : string ;
  offerForRule = [];
  daySelected : boolean;
  barrierRewardCount;
  enableOfferReward : boolean;
  userName;
  merchantId
}
export interface variant{
  variantName : string;
  variantId:number;
}
export interface Product{
  productName : string;
  productId : number;
  productCount : number;
 }

export interface freeProducts{
  product : Product[];
}

export interface ProductCategory{
  productCategoryName : string;
  productCategoryId : number;
  productCount : number;
}
export interface ProductVariant{
  variantName : string;
  variantId : number;
  parentProductGFId  : number;
}

export interface RewardPoint{
  rewardPoints : number;
}

export interface Discount{
  discountType : string;
  discountPercentage : number;
  discountAmount : number;
  maxDiscountAmount : number;
}

export interface DiscountedProducts{
   discount : Discount;
   productId : number;
   productName : string;
}

export interface DiscountedproductCategory{
  discount : Discount;
  productCategoryId : number;
  productCategoryName : string;
}

export interface Reward{
  rewardtype : string;
  rewardPoint : RewardPoint;
  discount : Discount;
  freeProducts : any[];
  discountedProducts : DiscountedProducts[];
  discountedProductCategories : DiscountedproductCategory[];
  expiryDate : string;
  daysToRedeem : number;
}

export interface OfferBarrier{
  barrierName : string;
  barrierParamType : string,
  barrierType : string;
  product : Product;
  productVariant:ProductVariant;
  productCategory : ProductCategory;
  barrierDates : barrierDates;
  offerReward : Reward;
  productPropensity : string;
  productCategoryPropensity : string;
  spentBarrier : number;
  spentType : string;
  visit : boolean;
}

export interface OfferMaster{
  barrierList : OfferBarrier[];
  barrierOperators : String[];
  reward : Reward;
  offerName : string;
  offerId : number;
  // textTemplate : {
  //   smsTemplate : string;
  //   emailTemplate : string;
  // }
}

export interface Offer{
  offerId : number;
  merchantId : number;
  operation : string;
  offerMaster : OfferMaster;
}

export interface barrierDates{
  startDayCount : number;
  endDayCount : number;
  daysOfTheWeek : [];
  startTime : string;
  endTime : string;
}

export interface IOfferMaster {
  barrierList: IBarrier[]
  barrierOperators : string[]
  reward : IReward
  offerMasterType : string
  offerName:string
  offerId:number
  textTemplate : ITextTemplate

}

export interface IBarrier {

  productPropensity?:string
  productCategoryPropensity?:string
  product?:IProduct
  productCategory?:IProductCategory
  barrierName:string
  barrierDates:IBarrierDates
  spentBarrier:number
  barrierType:string
  spentType:string
  offerReward?:IReward
  visit?: boolean
  windowHeight;

}

export interface IReward {
  rewardtype:string
  rewardPoint:IRewardPoint
  discount:IDiscount
  freeProducts: IProduct[]
  discountedProducts: IDiscountedProduct[]
  discountedProductCategories:IDiscountedProductCategory[]
  expiryDate:string
  daysToRedeem:number
}

export interface IBarrierDates
{

  dates:string[]
  dateFrom:string
  dateTo:string
  daysOfTheWeek:string[]

}

export interface IRewardPoint
{
  rewardPoints:number
}

export interface IDiscount
{
  discountType:string
  discountPercentage:number
  discountAmount:number
  maxDiscountAmount:number

}

export interface IProduct
{

  productId:string
  productName:string

}

export interface IDiscountedProduct
{
  product:IProduct
  discountPercentage:number
	discountAmount:number
	discountType:string
}

export interface IDiscountedProductCategory
{
  productCategory:IProductCategory
  discountPercentage:number
	discountAmount:number
	discountType:string

}

export interface IProductCategory
{
  productCategoryId:string
  productCategoryName:string

}

export interface OfferDetail {
  offerName: string;
  offerId: number;
  offerMasterType: string;
 }

 export interface IShopifyCustomer {
  customerId: string;
  name: String;
  email: string;
  phone:string;
 }

 export interface IShopifyProduct {
   productId: string;
   productSku:string;
   productName: string;
   productType: string;
   productPrice: string;

 }

 export interface IShopifyOrder
 {
  orderId:string;
  price:string;
  tax:String;
  totalPrice: string;
  currency:string;
  modePay:string;
  customerId: string;
  totalItems: string;
  itemList: IShopifyPurchasedItem[];

 }

 export interface IShopifyPurchasedItem
 {
    sku:string;
    name:string;
    price:string;
    quantity:string;
    totalPrice:string;
 }


 export interface ITextTemplate
 {
  smsTemplate:string;
  emailTemplate:string;
 }

 export interface targetCustomerSegments{
   id : number;
   segmentName : string;
 }

 export interface campaignTrigger{
  campaignTriggerRepeatType : string;
  campaignTriggerType : string;
  endDate : string
  event : string;
  startDate : string;
 }

 export interface campaignEmailTemplate{
  body : string;
  campaignEmailTemplateType : string;
  offerMasterid : number;
  subject : string;
 }

 export interface campaignMessageTemplate{
  campaignEmailTemplate : campaignMessageTemplate[];
  campaignSMSTemplate : string;
  messageTemplateType : string;
 }

 export interface campaignActions{
  campaignActionType : string;
  campaignMessageTemplate : campaignMessageTemplate[];
  offerMaster : number;
  sequenceNumber : number;
  timeToTriggerInMinutes : number;
 }

 export interface campaign{
  campaignActions : campaignActions[];
  campaignId : number;
  campaignName : string;
  campaignTrigger : campaignTrigger;
  campaignType :string;
  targetCustomerSegments : targetCustomerSegments[];
  targetCustomers : string;
 }

 export interface campaignResponse{
   message : string;
   body : campaign[];
 }