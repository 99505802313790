import { Component, ElementRef, ErrorHandler, HostListener, OnInit, ViewChild, } from "@angular/core";
import { MatDialog } from "@angular/material/dialog"; 
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { style, animate, transition, trigger } from "@angular/animations";
import { interval } from "rxjs";
import { environment } from "src/environments/environment";
import { assetPath } from "src/app/configs/asset-path-config";
import { ExternalAPI } from "src/app/configs/externalAPI";
import { AuthService } from "src/app/core/auth/auth.service";
import { AuthLoginInfo } from "src/app/core/auth/login-info";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { SignoutService } from "src/app/core/services/signout.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { ContactUsDialogComponent, DialogOverviewComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-pos-data-ingestion",
  templateUrl: "./pos-data-ingestion.component.html",
  styleUrls: ["./pos-data-ingestion.component.css"],
  animations: [
    trigger("openClose", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
    trigger("appear", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class POSDataIngestionComponent implements OnInit {
  s3BucketUrl = environment.s3BucketUrl;
  responseBody: any = null;
  responseMessage: any = null;
  //shopName: string = "";
  shopName = new FormControl();
  showShopNameInput: boolean;
  dataFetchInProgress: boolean = false;
  authInProgress: boolean = false;
  code: string;
  shopurl: string;
  showShopField: boolean = false;
  startDate = new Date();
  endDate = new Date();
  invalidShopName: boolean = false;
  authenticated: boolean = false;
  timeZoneOffset = new Date().getTimezoneOffset();
  assetPath = {
    shopifyImg: assetPath.path + "/shop.png",
  };
  userPosDetails;
  formSubmit: boolean = false;
  userName;

  onboarding: boolean = false;
  progressbarValue = 100;
  curSec: number = 60;
  // color: ThemePalette = 'primary';
  // mode: MatProgressBarModule = 'determinate';
  value = 20;
  bufferValue = 75;
  totalTasks;
  completedTasks;
  onboardingCompleted: boolean = false;
  percentageTaskCompleted = 0;

  sub;
  intervalId;
  counter = 0;
  percentageModulus = 0;
  scrHeight;
  scrWidth;
  percWidth;
  completedPerc: boolean = false;
  safeSrc1: SafeResourceUrl;
  safeSrc2: SafeResourceUrl;
  safeSrc3: SafeResourceUrl;
  safeSrc4: SafeResourceUrl;
  safeSrc5: SafeResourceUrl;
  safeSrc6: SafeResourceUrl;
  lightspeedapiFinished: boolean = false;
  lightspeedTestSuccess: boolean = false;

  bufferVariable: boolean = true;
  routeSub;
  helpTutorialHeight;
  paramsPos;
  cloverParams: boolean = false;
  cloverapiFinished: boolean = false;
  cloverTestSuccess: boolean = false;
  private loginInfo: AuthLoginInfo;
  shopcode;
  shopUrl;
  apiLoading: boolean = false;

  isshopExists = false;
  @ViewChild("helptutorials") helpTutorial: ElementRef;
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth * 0.9;
  }

  constructor(
    private route: ActivatedRoute,
    private apiCall: ApiCallService,
    private errorHandle: ErrorHandler,
    public dialog: MatDialog,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private sanitizer: DomSanitizer,
    private _signoutService: SignoutService,
    private globalService: Globals,
    private authService: AuthService
  ) {
    this.getScreenSize();
  }


  ngOnInit() {
    this.userName = this.tokenStorage.getUsername();
    this.routeSub = this.route.params.subscribe((params) => {
      console.log(params); //log the entire params object
      this.paramsPos = params["posProvider"]; //log the value of id
      console.log( this.paramsPos)
    });
    if (this.paramsPos == "shopify") {
      this.route.queryParams.subscribe((params) => {
        if (params["code"] || params["shop"]) {
          if (params["code"]) {
            let code = params["code"];
            let shopurl = params["shop"];
            this.shopcode = params["code"];
            this.shopUrl = params["shop"];
            this.isShopifyOnboarded(this.shopUrl, this.shopcode);
          } else {
            this.shopUrl = params["shop"];
            //this.isShopifyOnboarded(this.shopUrl)

            this.authenticateShopify(this.shopUrl);

            // this.showShopNameInput = false;
            // this.shopName.setValue(params["shop"]);
            // this.tokenStorage.saveMerchantOnlineStore("shopify");
            // this.tokenStorage.saveOnlineStoreUrl(window.location.href);
          //  this.getAuthenticated();
          }
        } else {
          if (
            this.tokenStorage.getOnlineStoreUrl() &&
            this.tokenStorage.getMerchantOnlineStore()
          ) {
            console.log("1");
            this.redirectToHomePage();
            // this.dataOnboardProgress();
          } else {
            console.log("121");

            if(this.tokenStorage.getToken()){
              this.showShopNameInput = true;
            }else{
              window.localStorage.clear();
              this.router.navigate(["auth/login"], {});
            }
          }
        }
      });
    } else if (this.paramsPos == "lspos") {
        this.route.queryParams.subscribe((params) => {
          if (params["code"] || params["shop"]) {
            if (params["code"]) {
              let code = params["code"];
              let shopurl = params["shop"];
              this.shopcode = params["code"];
              this.shopUrl = params["shop"];
              this.isLightspeedOnboarded(this.shopUrl, this.shopcode);
            } else {
              this.shopUrl = params["shop"];
  
              this.authenticateLightspeed();
  
              // this.showShopNameInput = false;
              // this.shopName.setValue(params["shop"]);
              // this.tokenStorage.saveMerchantOnlineStore("shopify");
              // this.tokenStorage.saveOnlineStoreUrl(window.location.href);
            //  this.getAuthenticated();
            }
          } else {
            if (
              this.tokenStorage.getOnlineStoreUrl() &&
              this.tokenStorage.getMerchantOnlineStore()
            ) {
              console.log("1");
              this.redirectToHomePage();
              // this.dataOnboardProgress();
            } else {
              console.log("121");
  
              if(this.tokenStorage.getToken()){
                this.showShopNameInput = true;
              }else{
                window.localStorage.clear();
                this.router.navigate(["auth/login"], {});
              }
            }
          }
        });
    } else if (this.paramsPos == "clover") {
      var shop, code, access_token;
      console.log("paramsPos", this.paramsPos);
      this.route.queryParams.subscribe((params) => {
        shop = params["merchant_id"];
        if (shop) {
          this.tokenStorage.setShopId(shop);
        }

        code = params["code"];
        console.log("shop", shop);
        console.log("code", code);
        console.log("manclover");

        if (shop || code) {
          this.cloverParams = true;

          if (params["code"]) {
            let code = params["code"];
            this.showShopNameInput = false;
            //console.log(this.getCookie('authenticated'))
            if (this.getCookie("authenticated") === "true") {
              this.deleteCookie("authenticated");
              //console.log(this.tokenStorage.getUserLogin())
              if (this.tokenStorage.getUserLogin() === "true") {
                const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Error",
                    body: "Kindly login using the associated credentials or try using a new store. Forgot credentials for this store? Contact Qubriux customer support.",
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.tokenStorage.saveOnlineStoreUrl(window.location.href);
                  var a = this.tokenStorage.getOnlineStoreUrl();
                  var b = this.tokenStorage.getMerchantOnlineStore();
                  this._signoutService.signOut();

                  this.router.navigate(["/auth/signup"], {
                    queryParams: {
                      redirection: a,
                      pos: "clover",
                    },
                  });
                });
              } else {
                this.tokenStorage.saveMerchantOnlineStore("clover");
                this.tokenStorage.saveOnlineStoreUrl(window.location.href);
                this.router.navigate(["/auth/signup"]);
                console.log("else1");
              }
            } else {
              console.log("else2");

              this.deleteCookie("authenticated");
              if (this.tokenStorage.getMerchantId()) {
                const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Error",
                    body: "User already logged in. Sign out and log in with new user?",
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.tokenStorage.saveOnlineStoreUrl(window.location.href);
                  this.apiCall
                    .cloverAPIOnboard(code, shop)
                    .subscribe((response) => {});
                  var a = this.tokenStorage.getOnlineStoreUrl();
                  var b = "clover";
                  this._signoutService.signOut();

                  this.router.navigate(["/auth/signup"], {
                    queryParams: {
                      redirection: a,
                      pos: b,
                    },
                  });
                });
              } else {
                this.onboardClover(code, shop);
              }
            }
          } else {
            console.log("else3");
            this.showShopNameInput = false;
            this.shopName.setValue(params["shop"]);
            this.tokenStorage.saveMerchantOnlineStore("clover");
            this.tokenStorage.saveOnlineStoreUrl(window.location.href);
            //console.log('auth');
            this.getCloverAuthenticated(shop);
            console.log(" this.getAuthenticated();");
          }
        } else {
          if (
            this.tokenStorage.getOnlineStoreUrl() &&
            this.tokenStorage.getMerchantOnlineStore()
          ) {
            this.onboarding = true;
            console.log("2");
           // this.dataOnboardProgress();
          } else {
            this.showShopNameInput = true;
          }
        }
      });
    }else{
      window.localStorage.clear();
      this.router.navigate(['auth/login']);
    }
  }
  public showFull(): void {
    this.onboarding = true;
    this.onboardStatus();
    this.sub = interval(6000).subscribe((val) => {
      {
        this.onboardStatus();
      }
    });
  }

  authenticateLightspeed() {
    let url =
      "https://cloud.lightspeedapp.com/oauth/authorize.php?response_type=code&client_id=f701a5047d34d3e3c454a1df411aa9b0925a3c3aa8345c54b9f9677d15331e9f&scope=employee:all";
    window.open(url, "_self");
    //}
  }

  onboardLightspeed(code, shop) {
    if (this.tokenStorage.getUserLogin() === "true") {
      this.apiCall.lightspeedAPIOnboard(code).subscribe(
        (response) => {
          this.lightspeedapiFinished = true;
          this.lightspeedTestSuccess = true;
        },
        (err) => {
          this.lightspeedapiFinished = true;
          this.lightspeedTestSuccess = false;
          console.log("error", err);
        }
      );
    } else {
      this.apiCall.lightspeedAPIOnboard(code).subscribe(
        (response) => {
          this.lightspeedapiFinished = true;
          this.lightspeedTestSuccess = true;
          this.tokenStorage.saveMerchantOnlineStore("lightspeed");
          this.tokenStorage.saveOnlineStoreUrl(document.location.href);

          this.onboarding = true;
          this.router.navigate(["auth/signup"]);
        },
        (err) => {
          this.lightspeedapiFinished = true;
          this.lightspeedTestSuccess = false;
          console.log("error", err);
        }
      );
    }
  } 

  getLightspeedAuthenticated(shop?) {
    //console.log(this.shopName.errors)
    // if (this.shopName.value.length < 1 || this.shopName.errors?.pattern) {
    //   if (this.shopName.value.length < 1) {
    //     this.invalidShopName = true;
    //   }
    // }
    // if(shop==null){
    //   this.authenticateClover();
    // }

    this.formSubmit = true;
    // if ((this.shopName.value === null || this.shopName.value === '') || (this.shopName.touched && this.shopName.errors)) {
    //   return;
    // }
    if (shop == null) {
      console.log("redirection1");
      this.setCookie("authenticated", "true");
      // this.authenticateShopify();
      this.authenticateLightspeed();
    } else {
      this.apiCall.isLightspeedAPIOnboard(this.code).subscribe((response) => {
        if (response["body"] === true) {
          this.setCookie("authenticated", "true");
          // this.authenticateShopify();
          this.authenticateLightspeed();
          console.log("redirection2");
        } else {
          console.log("redirection3");
          this.setCookie("authenticated", "false");
          // this.authenticateShopify();
          this.authenticateLightspeed();
        }
      });
    }
  }

  getCloverAuthenticated(shop?) {
    //console.log(this.shopName.errors)
    // if (this.shopName.value.length < 1 || this.shopName.errors?.pattern) {
    //   if (this.shopName.value.length < 1) {
    //     this.invalidShopName = true;
    //   }
    // }
    // if(shop==null){
    //   this.authenticateClover();
    // }

    this.formSubmit = true;
    // if ((this.shopName.value === null || this.shopName.value === '') || (this.shopName.touched && this.shopName.errors)) {
    //   return;
    // }
    if (shop == null) {
      console.log("redirection1");
      this.setCookie("authenticated", "true");
      // this.authenticateShopify();
      this.authenticateClover();
    } else {
      this.apiCall.isCloverAPIOnboard(shop).subscribe((response) => {
        if (response["body"] === true) {
          this.setCookie("authenticated", "true");
          // this.authenticateShopify();
          this.authenticateClover();
          console.log("redirection2");
        } else {
          console.log("redirection3");
          this.setCookie("authenticated", "false");
          // this.authenticateShopify();
          this.authenticateClover();
        }
      });
    }
  }

  onboardStatus() {
    this.apiCall.getOnboardingProgressStatus().subscribe((response) => {
      let responseBody = response["body"];
      this.onboardingCompleted = responseBody.onboardingCompleted;
      this.totalTasks = responseBody.totalTasks;
      this.completedTasks = responseBody.completedTasks;

      this.percentageTaskCompleted =
        (this.completedTasks * 100) / this.totalTasks;
      this.percWidth = this.completedTasks / this.totalTasks;
      this.counter++;

      if (responseBody.onboardingCompleted == true) {
        this.completedPerc = true;
        this.sub.unsubscribe();
      }
    });
  }
  authenticateClover() {
    let url =
      "https://" +
      "sandbox.dev.clover.com/oauth/authorize?client_id=" +
      environment.cloverAppId +
      "&redirect_uri=" +
      window.location.protocol +
      "//" +
      environment.Origin +
      "/data-ingestion/clover/";
    window.open(url, "_self");
    //}
  }

  onboardShopify(code, shopUrl) {
    if (this.tokenStorage.getUserLogin() === "true") {
      this.apiCall.onboardShopifyUser(shopUrl, code).subscribe((response) => {
        console.log("4");
        this.dataOnboardProgress();
      });
    } else {
      this.apiCall.onboardShopifyUser(shopUrl, code).subscribe((response) => {
        this.tokenStorage.saveMerchantOnlineStore("shopify");
        this.tokenStorage.saveOnlineStoreUrl(document.location.href);
        this.router.navigate(["/auth//signup"]);
      });
      // this.tokenStorage.saveMerchantOnlineStore('shopify');
      // this.tokenStorage.saveOnlineStoreUrl(document.location.href)
      // this.router.navigate(['/login']);
    }
  }
  onboardClover(code, shop) {
    if (this.tokenStorage.getUserLogin() === "true") {
      this.apiCall.cloverAPIOnboard(code, shop).subscribe(
        (response) => {
          this.cloverapiFinished = true;
          this.cloverTestSuccess = true;
        },
        (err) => {
          this.cloverapiFinished = true;
          this.cloverTestSuccess = false;
          console.log("error", err);
        }
      );
    } else {
      this.apiCall.cloverAPIOnboard(code, shop).subscribe(
        (response) => {
          this.cloverapiFinished = true;
          this.cloverTestSuccess = true;
          this.tokenStorage.saveMerchantOnlineStore("clover");
          this.tokenStorage.saveOnlineStoreUrl(document.location.href);

          this.onboarding = true;
          this.router.navigate(["/auth//signup"]);
        },
        (err) => {
          this.cloverapiFinished = true;
          this.cloverTestSuccess = false;
          console.log("error", err);
        }
      );
      // this.tokenStorage.saveMerchantOnlineStore('shopify');
      // this.tokenStorage.saveOnlineStoreUrl(document.location.href)
      // this.router.navigate(['/login']);
    }
  }

  dataOnboardProgress() {
    console.log("Called /posdataind");

    this.apiCall.fetchUserMerchantData().subscribe((result) => {
      this.userPosDetails = result["body"];
      this.userPosDetails = JSON.parse(this.userPosDetails);
      this.tokenStorage.saveMerchantId(this.userPosDetails[0]["id"]);
      if (
        this.tokenStorage.getOnlineStoreUrl() &&
        this.tokenStorage.getMerchantOnlineStore()
      ) {
        this.tokenStorage.removeOnlineStoreUrl();
        this.tokenStorage.removeMerchantOnlineStore();
      }
      this.showFull();
    });
  }

  getAuthenticated() {
    // if (this.shopName.value.length < 1 || this.shopName.errors?.pattern) {
    //   if (this.shopName.value.length < 1) {
    //     this.invalidShopName = true;
    //   }
    // }

    var x = this.shopName.value;

    var match = x.match("([A-z]|[0-9]|-|.)+myshopify.com");
    console.log(match);
    this.formSubmit = true;
    if (
      this.shopName.value === null ||
      this.shopName.value === "" ||
      (this.shopName.touched && this.shopName.errors)
    ) {
      return;
    } else {
      if (x.indexOf(".myshopify.com/") != -1) {
        x = x.split(".myshopify.com/")[0];
        // x = x.replace(".myshopify.com/", ".myshopify.com");
      }
      if (x.indexOf("https://") != -1) {
        x = x.replace("https://", "");
      }
      if (x.indexOf("/") != -1) {
        x = x.split("/")[1];
      }
      if (x.indexOf(".myshopify.com") != -1) {
        x = x.replace(".myshopify.com", "");
      }
      x = x + ".myshopify.com";
      // if (x.indexOf(".com/") != -1) {
      //   console.log("h");
      //   x = x.replace(".com/", ".com");
      //   console.log(x);
      // }
      // if (x.indexOf("https://") != -1) {
      //   console.log("2");
      //   x = x.replace("https://", "");
      // }
      // if (x.indexOf("/") != -1) {
      //   console.log("2");
      //   x = x.split("/")[1];
      // }

      this.apiCall.checkDataOnboardStatus(x).subscribe((response) => {
        if (response["body"]["status"] === true) {
          this.setCookie("authenticated", "true");
          this.isshopExists = true;
          // this.authenticateShopify(this.shopName.value);
        } else {
          this.isshopExists = false;
          this.setCookie("authenticated", "false");
          this.authenticateShopify(x);
        }
      });
    }
  }

  directToLoginFlow(){
    window.localStorage.clear();
    this.authenticateShopify(this.shopName.value+".myshopify.com");
  }

  authenticateShopify(shop) {
    this.invalidShopName = false;
    this.authInProgress = true;
    let app_nonce = "2626272222222";
    //if (environment.external == 'qa') {
    let redirect_url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    let url =
      "https://" +
      shop +
      ExternalAPI.SHOPIFY_OAUTH_URL +
      "?client_id=" +
      environment.shopifyClientId +
      "&scope=" +
      environment.shopifyAppScope +
      "&redirect_uri=" +
      redirect_url +
      "&state=" +
      app_nonce;
    window.open(url, "_self");
    //}
    // else {
    //   let redirect_url = window.location.protocol + '//' + window.location.host + window.location.pathname;
    //   let url = "https://" + this.shopName.value + ExternalAPI.SHOPIFY_OAUTH_URL + '?client_id=' + 'fcb02062abb190dcc3279f9a9ea574f4' + '&scope='
    //     + ExternalAPI.SHOPIFY_APP_SCOPE + '&redirect_uri=' + redirect_url + "&state=" + app_nonce;
    //   window.open(url, '_self');
    // }
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(name) {
    this.setCookie(name, "");
  }

  setCookie(name: string, value: string, path: string = "") {
    let d: Date = new Date();
    d.setTime(d.getTime() + 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : "";
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  // authenticateAndRedirect() {
  //   this.invalidShopName = false;
  //   this.authInProgress = true;
  //   let app_nonce = "2626272222222";
  //   let redirect_url = window.location.protocol + '//' + window.location.host + '/signup';
  //   let url = "https://" + this.shopName.value + '.' + ExternalAPI.SHOPIFY_OAUTH_URL + '?client_id=' + ExternalAPI.SHOPIFY_APP_CLIENT_ID + '&scope='
  //     + ExternalAPI.SHOPIFY_APP_SCOPE + '&redirect_uri=' + redirect_url + "&state=" + app_nonce;
  //   window.open(url, '_self');
  // }

  // getData() {
  //   this.startDate.setHours(0, 0, 0, 0);
  //   this.endDate.setHours(23, 59, 59, 999);
  //   this.showShopNameInput = false;
  //   let shopifyRequestData = {
  //     code: this.code,
  //     shop: this.shopurl,
  //     fromDate: this.startDate.toISOString(),
  //     toDate: this.endDate.toISOString(),
  //     timeZoneOffset: this.timeZoneOffset
  //   };
  //   this.dataFetchInProgress = true;
  //   this.apiCall.getShopifyData(shopifyRequestData).subscribe((response: Response) => {
  //     this.responseBody = response['body'];
  //     this.responseMessage = response['message'];
  //     this.dataFetchInProgress = false;
  //   },
  //     error => {
  //       this.errorHandle.showError(error)
  //       this.dataFetchInProgress = false;
  //       this.responseBody = error.error.error;
  //       this.responseMessage = error.error.message;
  //     })
  // }

  openDialog(data): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: "250px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.router.navigate(["auth/login"], {});
  }

  redirectToHomePage() {
    this.tokenStorage.removeOnlineStoreUrl();
    this.tokenStorage.removeMerchantOnlineStore();
    this.onboarding = false;
    this.router.navigate(["/data-ingestion"]);
  }

  isShopifyOnboarded(shop, code?) {
    let username = "";
    this.apiLoading = true;
    this.apiCall.checkDataOnboardStatus(shop).subscribe((response) => {
      if (response["body"]["status"] === true) {
        username = response["body"]["email"];
        //direct merchant to login wit posauthentication(platformGenerated=shopify)
        this.apiLoading = false;
        this.callLoginFlow(
          username,
          "shopify",
          window.location.href.split("?")[1],
          "shopify"
        );
        // call signin api returns JWT
        // this.authenticateShopify();
      } else {
        // this.apiLoading = true;
        // if (this.tokenStorage.getshopLinkedStatus() == "true") {
        //   window.localStorage.clear();
        // }
        this.apiCall.onboardShopifyUser(shop, code).subscribe((response) => {
          if (
            this.tokenStorage.getMerchantId() ||
            this.tokenStorage.getToken()
          ) {
            this.router.navigate(["data-ingestion"], {});
            return 0;
            if (this.tokenStorage.getUsername() === response["body"]["email"]) {
            } else {
              this.snackBar.openSnackBar("Invalid user session", 2000);
              this._signoutService.signOut();
            }
          }
          if (response["body"]["isEmailExisting"] === true) {
            //error page(email is already registered) with signup route button
            this.apiLoading = false;
            this.tokenStorage.saveOnlineStoreUrl(document.location.href);
            console.log("Email exists::"+ encodeURIComponent(response["body"]["email"])
            );
            // Bypass the flow to signup if merchant already logged in

            // this.router.navigate(["/auth/signup"], {
            //   queryParams: {
            //     pos: "shopify",
            //     useremail: 0,
            //   },
            // });
            this.callLoginFlow(
              response["body"]["email"],
              "shopify",
              window.location.href.split("?")[1],
              "shopify"
            );
          } else {
            this.tokenStorage.saveOnlineStoreUrl(document.location.href);
            console.log(
              "routeto",
              encodeURIComponent(response["body"]["email"])
            );
            this.router.navigate(["/auth/signup"], {
              queryParamsHandling: "merge",
              queryParams: {
                pos: "shopify",
                useremail: encodeURIComponent(response["body"]["email"]),
                name: response["body"]["name"]
                  ? encodeURIComponent(response["body"]["name"])
                  : "User",
              },
            });
          }
        },(error)=>{
         console.log(error)
          if(error.error.body.response=='Code failed'){
            this.authenticateShopify(shop)
          }else{
            window.localStorage.clear();
            this.router.navigate(["login"], {});
          }
        });
        //   this.setCookie("authenticated", "false");
        // this.authenticateShopify();
      }
    },
    (error)=>{
      this.apiLoading = false;
     this.snackBar.openSnackBar("Invalid user session.Please contact our Customer Support", 2000);
    });
  }

  isLightspeedOnboarded(shop, code?) {
    let username = "";
    this.apiLoading = true;
    this.apiCall.isLightspeedAPIOnboard(code).subscribe((response) => {
      if (response["body"]["status"] === true) {
        username = response["body"]["email"];
        //direct merchant to login wit posauthentication(platformGenerated=shopify)
        this.apiLoading = false;
        this.callLoginFlow(
          username,
          "lightspeed",
          window.location.href.split("?")[1],
          "lightspeed"
        );
        // call signin api returns JWT
        // this.authenticateShopify();
      } else {
        this.apiLoading = true;
        if (this.tokenStorage.getshopLinkedStatus() == "true") {
          window.localStorage.clear();
        }
        this.apiCall.lightspeedAPIOnboard(code).subscribe((response) => {
          if (
            this.tokenStorage.getMerchantId() ||
            this.tokenStorage.getToken()
          ) {
            this.router.navigate(["data-ingestion"], {});
            return 0;
            if (this.tokenStorage.getUsername() === response["body"]["email"]) {
            } else {
              this.snackBar.openSnackBar("Invalid user session", 2000);
              this._signoutService.signOut();
            }
          }
          if (response["body"]["isEmailExisting"] === true) {
            //error page(email is already registered) with signup route button
            this.apiLoading = false;
            this.tokenStorage.saveOnlineStoreUrl(document.location.href);
            console.log(
              "routeto",
              encodeURIComponent(response["body"]["email"])
            );
            // Bypass the flow to signup if merchant already logged in

            this.router.navigate(["auth/signup"], {
              queryParams: {
                pos: "lightspeed",
                useremail: 0,
              },
            });
          } else {
            this.tokenStorage.saveOnlineStoreUrl(document.location.href);
            console.log(
              "routeto",
              encodeURIComponent(response["body"]["email"])
            );
            this.router.navigate(["auth/signup"], {
              queryParamsHandling: "merge",
              queryParams: {
                pos: "lightspeed",
                id: response['body']['shopUrl'],
                useremail: encodeURIComponent(response["body"]["email"]),
                name: response["body"]["name"]
                  ? encodeURIComponent(response["body"]["name"])
                  : "User",
              },
            });
          }
        },(error)=>{
         console.log(error)
          if(error.error.body.response=='Code failed'){
            this.authenticateShopify(shop)
          }else{
            window.localStorage.clear();
            this.router.navigate(["login"], {});
          }
        });
        //   this.setCookie("authenticated", "false");
        // this.authenticateShopify();
      }
    },
    (error)=>{
      this.apiLoading = false;
     this.snackBar.openSnackBar("Invalid user session.Please contact our Customer Support", 2000);
    });
  }

  callLoginFlow(username, posProvider, posAuthentication, platformGenerated) {
    //this.isLoginFailed = false;
    // this.submitted = true;
    if (this.tokenStorage.getMerchantOnlineStore() == "clover") {
      var sus = this.tokenStorage
        .getOnlineStoreUrl()
        .replace("merchant_id", "shop");
      this.tokenStorage.removeOnlineStoreUrl();
      this.tokenStorage.saveOnlineStoreUrl(sus);
    }
    //console.log(this.submitted,this.loginForm.controls.username.invalid)
    // console.log(this.loginForm)
    if (1) {
      this.loginInfo = new AuthLoginInfo(
        username.toLowerCase(),
        "Skellam@123",
        posProvider,
        posAuthentication,
        platformGenerated
      );
      this.apiLoading = true;
      this.apiCall.setSigninCustomer(this.loginInfo).subscribe(
        (res) => {
          let response = res["body"];
          console.log(response["accessToken"]);
          console.log("userId", response['userId'])
          this.tokenStorage.saveToken(response["accessToken"]);
          this.tokenStorage.saveUsername(response["username"]);
          this.tokenStorage.saveAuthorities(response["authorities"]);
          this.tokenStorage.setUserLogin("true");
          this.tokenStorage.saveUserId(response['userId']);
          this.apiLoading = false;
          if (response["message"]) {
            this.tokenStorage.setExistingUserFlag(true);
          }

          this.globalService.passQueryValue(false, null);
          this.router.navigate(["data-ingestion"], {});
        },
        (error) => {
          console.log("ERROR");
        }
      );
    } else {
    }
  }
  onPaste(event) {
    let clipboardData = event.clipboardData;
    let x = clipboardData.getData("text");
    if (x.indexOf(".myshopify.com/") != -1) {
      x = x.split(".myshopify.com/")[0];
      // x = x.replace(".myshopify.com/", ".myshopify.com");
    }
    if (x.indexOf("https://") != -1) {
      x = x.replace("https://", "");
    }
    if (x.indexOf("/") != -1) {
      x = x.split("/")[1];
    }
    if (x.indexOf(".myshopify.com") != -1) {
      x = x.replace(".myshopify.com", "");
    }
    setTimeout(() => {
      this.shopName.setValue(x);
    });
  }
}

export interface IOptionData {
  value: string;
  viewValue: string;
}
