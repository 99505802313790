import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogData } from "../ecommerce-dialog/ecommerce-dialog.component";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

@Component({
  selector: "app-table-view-dialog-box",
  templateUrl: "./table-view-dialog-box.component.html",
  styleUrls: ["./table-view-dialog-box.component.css"],
})
export class TableViewDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TableViewDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private date_format: getdateformatService
  ) {}

  headingList = [];
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  isScrollVisible: boolean = false;

  ngOnInit(): void {
    this.headingList = this.getTheHeadingList();
  }
  
  ngAfterViewInit() {
    this.checkScroll();
  }

  getTheHeadingList() {
    switch (this.data["data"].type) {
      case "offers":
        return ["Offer Id", "Offer Code", "Status", "Valid Till"];
      case "surveys":
        return ["Survey Id", "Survey Name", "Date"];
      case "journeys":
        return ["Journey Id", "Journey Name", "Date"];
      default:
        return [];
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  getDateFormat(type?) {
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  checkScroll() {
    const element = this.scrollContainer.nativeElement;
    this.isScrollVisible = element.scrollHeight > element.clientHeight;
  }
}
