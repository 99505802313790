import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SidenavserviceService } from 'src/app/core/services/sidenavservice.service';
import { debounce } from 'lodash';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AreaChartComponent implements OnInit {

  @Input() allCustomerCount: number;

  constructor(
    private apiCall: ApiCallService,
    private datePipe: DatePipe,
    private tokenStorage: TokenStorageService,
    private sidenavService: SidenavserviceService
  ) {
    window["screen-name"] = "ALL CUSTOMERS";
  }

  @ViewChild('select') select;

  data = [];
  startDate;
  endDate;
  max = 5;
  type;
  selectedEvent;
  selectedTime = "All Time";
  selectedSegment = "all customers";
  totalCustomerCount;
  merchantID = this.tokenStorage.getMerchantId();
  array: number[] = [];
  sidenav: any;
  sidenavSub: Subscription;
  message;
  w;
  body;
  store;
  allCustomerOverallCount = null;
  customerCountChartOptions: EChartsOption;
  debouncedResize: () => void;

  ngOnInit() {
    this.store = this.tokenStorage.getMerchantOnlineStore();
    this.selectedEvent = null;
    this.sidenavSub = this.sidenavService.apply.subscribe(message => {
      this.w = message ? 1170 : 1000;
      if(this.allCustomerCount!=null){
        this.allCustomerOverallCount=this.allCustomerCount;
      }
      if (this.selectedTime === 'All Time')
        this.allTime();
      else
        this.thisWeek();
    });
    // this.allTime();
    this.debouncedResize = debounce(() => {
      this.onResize();
    }, 800);
  }

  setCustomerCountChartOptions() {
      let xValues = [];
      let yValues = [];
      this.data.forEach(element => {
        xValues.push(element.date);
        yValues.push(element.value);
      });
      this.customerCountChartOptions = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xValues,
        },
        yAxis: {
          name: 'Number of Customers',
          nameLocation: 'end',
          type: 'value'
        },
        series: [
          {
            data: yValues,
            type: 'line'
          }
        ]
      };
  }

  openSelect() {
    this.select.open();
  }

  thisWeek() {
    this.startDate = this.datePipe.transform(new Date((Date.now() - 6 * 24 * 60 * 60 * 1000)).toISOString(), "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(new Date((Date.now())).toISOString(), "yyyy-MM-dd");
    this.type = "DAY"
    this.apiCall.getGraphStats(this.startDate, this.endDate, this.type).subscribe(response => {
      this.body = (response["body"]);
      this.getGraphStats(this.selectedEvent, this.selectedSegment);
    });
  }

  changeGraph() {
    console.log(this.selectedTime);
    if (this.selectedTime === 'All Time') {
      this.allTime();
    } else {
      this.thisWeek();
    }
  }

  allTime() {
    var date = new Date();
    date.setMonth(date.getMonth() - 6);
    this.startDate = this.datePipe.transform(new Date(date).toISOString(), "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(new Date().toISOString(), "yyyy-MM-dd");
    this.type = "WEEK";

    this.apiCall.getGraphStats(this.startDate, this.endDate, this.type).subscribe(response => {
      this.body = (response["body"]);
      this.getGraphStats(this.selectedEvent, this.selectedSegment);
    });
  }

  ngOnDestroy() {
    this.sidenavSub.unsubscribe();
  }

  onResize() {
    this.allTime();
    this.getTotalCustomers();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.debouncedResize();
  }

  getTotalCustomers() {
    if (this.selectedTime === "All Time") {
      if (this.selectedSegment === "all customers" && this.allCustomerOverallCount == null) {
        this.apiCall.getAnalytics(new Date(), new Date()).subscribe((response) => {
          this.totalCustomerCount = JSON.parse(response["body"]).numberOfCustomers;
          this.allCustomerOverallCount = JSON.parse(response["body"]).numberOfCustomers;
        });
      } else if (this.selectedSegment === "all customers" && this.allCustomerOverallCount != null) {
        this.totalCustomerCount = this.allCustomerOverallCount;
      } else {
        this.apiCall.getAllCustomerSegments().subscribe(response => {
          let json = (response["body"]);
          json.forEach(element => {
            if (element['segmentName'] === this.selectedSegment)
              this.getTotalCustomerOfSegment(element['id']);
          });
        });
      }
    } else {
      if (this.selectedSegment === "all customers")
        this.getThisWeekCustomerCount(null);
      this.apiCall.getAllCustomerSegments().subscribe(response => {
        let json = (response["body"]);
        json.forEach(element => {
          if (element['segmentName'] === this.selectedSegment)
            this.getThisWeekCustomerCount(element['id']);
        });
      });
    }
  }

  getTotalCustomerOfSegment(segementID) {
    let segmentArray = [];
    segmentArray.push(segementID);
    this.apiCall.getCustomerCountSegmentList(segmentArray).subscribe(response => {
      this.totalCustomerCount = (response["body"]).totalCustomerCount;
      if (this.totalCustomerCount === "null")
        this.totalCustomerCount = 0;
    });
  }

  getThisWeekCustomerCount(id) {
    this.apiCall.getThisWeekCustomerCount(this.startDate, this.endDate, id).subscribe(response => {
      this.totalCustomerCount = response['body'];
      if (this.totalCustomerCount === "null") this.totalCustomerCount = 0;
    });
  }

  getGraphStats(event, segment) {
    this.selectedSegment = segment;
    this.getTotalCustomers();
    this.data = [];
    this.array = [];
    this.max = 5;
    Object.keys(this.body[segment]["dataSet"]).map((key) => {
      this.array.push(this.body[segment]["dataSet"][key]);
      let date = key.split(" ");
      let formatedDate = this.datePipe.transform(new Date(key).toISOString(), 'dd MMM yyyy');
      this.data.push({ date: formatedDate, value: this.body[segment]["dataSet"][key] });
    });
    this.array.forEach(value => {
      if (value > this.max)
        this.max = value;
    });
    this.setCustomerCountChartOptions();
  }

}
