import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { create360dialogaccoountService } from 'src/app/core/services/create-360-dialog-account-service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { ConfirmationDialogBoxComponent, WhatsappTemplateCreationDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-whatsapp-template-listing',
  templateUrl: './whatsapp-template-listing.component.html',
  styleUrls: ['./whatsapp-template-listing.component.css']
})
export class WhatsappTemplateListingComponent implements OnInit {

  constructor(
    private apiCall: ApiCallService,
    private _WidgetConfigService: WidgetConfigService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private create360dialog: create360dialogaccoountService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }
  loading = true;
  whatsAppLayout = [];
  filter = {
    label: "",
  };
  appSubscriptionPlan;
  validWidgets;
  contentHeight;
  shopName = "";
  clientId;
  channel;
  isOnboarded;
  tabSelected = 'all';
  noData = false;
  status = '';
  headingTextKey: string = 'COMMUNICATION_PAGE.ALL_TEMPLATES';
  @ViewChild("mainContainer") mainContainer: ElementRef;
  @ViewChild("mainHeader") mainHeader: ElementRef;
  @ViewChild('search') searchElement: ElementRef;
  ngOnInit(): void {
    this.shopName = this.tokenStorage.getShopName();
    this.getWidgets();
    this.getWhatsAppTemplate();
    // this.route.queryParams.subscribe((params)=>{
    //   console.log("PARAMS", params);
    //   this.clientId = params.client;
    //   this.channel = params.channels;
    // });
    // this.isOnboarded = this.create360dialog.checkIfOnBoarded();
    // this.checkIfOnBoarded();
    this.isOnboarded = this.tokenStorage.getIsAccountCreated();
    console.log("isOnboarded", this.isOnboarded)
    this.checkIfOnBoarded();
  }
  ngOnChanges(){
    this.isOnboarded = this.tokenStorage.getIsAccountCreated();
    console.log("isOnboarded", this.isOnboarded)
  }
  getWhatsAppTemplate(){
    this.apiCall.getWhatsAppTemplates("ALL").subscribe((response)=>{
      console.log("TEMPLATE_RESPONSE", response);
      this.whatsAppLayout = response['body'];
      this.loading = false;
    })
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.mainContainer && this.mainHeader) {
        this.contentHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.mainHeader.nativeElement.offsetHeight;
        document
          .getElementById("container")
          .addEventListener("click", (event) => {
            this.clickEvent(event);
          });
        clearInterval(interval);
      }
    });
  }
  
  clickEvent(event) {
    let elements = document.getElementsByClassName("more-options");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("style", "display:none");
    }
  }
  getLayout(layout, returnType){
    let layoutData = JSON.parse(layout['layoutData']);
    if(returnType === 'header'){
      if(layoutData['header'])
        return layoutData['header'].toLowerCase();
      else
        return
    }
    else if(returnType ==='buttons'){
      if(layoutData['buttons'] != null){
        if(layoutData['buttons'].length>0){
          if(layoutData['buttons'][0]['quickReply']){
            return layoutData['buttons'][0]['quickReply'];
          }
          if(layoutData['buttons'][0]['urlText']){
            return layoutData['buttons'][0]['urlText'];
          }
        }
      }
    }
    else{
      return layoutData[returnType];

    }
  }
  openPreview(layout) {
    let layoutData = JSON.parse(layout['layoutData']);
    let header = layoutData['header'];
    let button = layoutData['buttons'];
    let buttonText = '';
    console.log("BUTTONS",button)
    if(button?.length>0){
      if(button[0]['quickReply']){
        buttonText = button[0]['quickReply']
      }
      if(button[0]['urlText']){
        buttonText = button[0]['urlText']
      }
    }
    console.log("TEXT",buttonText)
    if(header)
      header=header.toLowerCase();
    let headerData = '';
    if(header === 'image'){
      headerData = layoutData['headerImage'];
    }
    if(header === 'video'){
      headerData = layoutData['headerVideo'];
    }
    if(header === 'document'){
      headerData = layoutData['headerDocument'];
    }
    if(header === 'text'){
      headerData = layoutData['headerText'];
    }
    this.router.navigate(["/mobile-email-preview", {
      smsText: layoutData['body'],
      footer: layoutData['footer'],
      header: header,
      headerData: headerData,
      smsName: layout['name'],
      type: "WHATSAPP",
      id: layout['id'],
      status: layout['status'],
      buttonText: buttonText
    }])
  }
  craeteWhatsAppAccount(){
    this.apiCall.checkWhatsAppOnboarded().subscribe((response)=>{
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "235px",
        data: {
          subject: this._i18nDynamicTranslate.transform("WhatsApp account already created", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Ok", ['POS_PAGE']),
        },
      })
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          
        }
      });
    },
    (err)=>{
      console.log("ERROE")
      this.create360dialog.createAccountNew();      
    });
    // this.create360dialog.sendWhatsAppDetails(this.clientId, this.channel);
    // const windowFeatures =
    // 'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
    // let partnerId= 'f167CmPA'
    // let url = 'https://hub.360dialog.com/dashboard/app/'+partnerId+'/permissions';
    // window.open(
    //   url,
    //   'integratedOnboardingWindow',
    //   windowFeatures
    // );

    // window.addEventListener(
    //   "message",
    //   (event) => {
    //     const { data } = event;
    //     const queryString = `${data}`;        
    //     console.log(queryString);  
    //     let params = new URLSearchParams(queryString);
    //     let channels = params.get("channels");  
    //     let client = params.get("client")    
    //     // ?client=oaY9LLfUCL&channels=[y9MiLoCH]
    //   }, false
    // );

    // window.onload = function() {
    //   this.processParams();
    // }
  }
  // processParams() {
  //   const params = window.location.search;
  //   if (window.opener) {
  //     window.opener.postMessage(params);
  //     window.close();
  //   }
  // }

  checkIfOnBoarded(){
    let check = 'false';
    this.apiCall.checkWhatsAppOnboarded().subscribe((response)=>{
      console.log("TESTT",check)
      this.isOnboarded = 'true';
    },
    (err)=>{
      console.log("ERROE")
      this.isOnboarded = 'false';
    });
  }
  checkIfAccountCreated(){
    this.apiCall.checkWhatsAppOnboarded().subscribe((response)=>{
      this.tokenStorage.setIsAccountCreated(true);
      this.isOnboarded = 'true';
    },
    (err)=>{
      this.isOnboarded = 'false';
      this.tokenStorage.setIsAccountCreated(false);
    });
  }
  createTemplate(type){
    this.router.navigate(["/app/whatsapp/layout-editor",{id:null}]);
  }
  editTemplate(layout){
    this.router.navigate(["/app/whatsapp/layout-editor",{id:layout['id']}]);

  }
  selectList(type, event) {
    if(event != null)
    event.stopPropagation();
    this.tabSelected = type;
    this.loading = true;
    switch (type) {
      case "preset":
        this.headingTextKey = 'COMMUNICATION_PAGE.PRESET_TEMPLATES';
        this.apiCall.getWhatsAppTemplates("PRESET").subscribe((response)=>{
          console.log("TEMPLATE_RESPONSE", response);
          this.whatsAppLayout = response['body'];
          this.loading = false;
        },(err)=>{
          this.whatsAppLayout = [];
        })
        break;
      case "mytemplates":
        this.headingTextKey = 'COMMUNICATION_PAGE.CUSTOM_TEMPLATES';
        this.apiCall.getWhatsAppTemplates("CUSTOM").subscribe((response)=>{
          console.log("TEMPLATE_RESPONSE", response);
          this.whatsAppLayout = response['body'];
          this.loading = false;
        },(err)=>{
          this.whatsAppLayout = [];
        })
        break;
      case "all":
        this.headingTextKey = 'COMMUNICATION_PAGE.ALL_TEMPLATES';
        this.apiCall.getWhatsAppTemplates("ALL").subscribe((response)=>{
          console.log("TEMPLATE_RESPONSE", response);
          this.whatsAppLayout = response['body'];
          this.loading = false;
        },(err)=>{
          this.whatsAppLayout = [];
        })
        break;
      default:
        switch (type) {
          case 'SUBMITTED':
            this.headingTextKey = 'COMMUNICATION_PAGE.SUBMITTED_TEMPLATES';
            break;
          case 'PENDING':
            this.headingTextKey = 'COMMUNICATION_PAGE.PENDING_TEMPLATES';
            break;
          case 'APPROVED':
            this.headingTextKey = 'COMMUNICATION_PAGE.APPROVED_TEMPLATES';
            break;
          case 'REJECTED':
            this.headingTextKey = 'COMMUNICATION_PAGE.REJECTED_TEMPLATES';
            break;
          default:
            this.headingTextKey = 'COMMUNICATION_PAGE.ALL_TEMPLATES';
            break;
        }
        this.apiCall.getWhatsAppTemplates(type).subscribe((response)=>{
          console.log("TEMPLATE_RESPONSE", response);
          this.whatsAppLayout = response['body'];
          this.tabSelected = 'status';
          this.loading = false;
        },(err)=>{
          this.whatsAppLayout = [];
        })
        break;
    }
  }
  changeStatus(data){
    this.status = data;
    this.selectList(data, null);
  }
}
