<div class="qb-main" *ngIf="validWidgets" #container id="container" data-widget-name="EMAIL LISTING">
  <div class="header-1">
    <app-breadcrumb></app-breadcrumb>
    <div class="qb-main-header padding-top-8px" #mainHeader id="mainHeader">
      <div class="qb-d-flex qb-ai-center">
        <div class="qb-template-style-switch-button-container">
          <div (click)="selectTileView()" class="qb-template-style-switch-icon-container qb-grid-view-icon"
            data-button-name="Tile view">
            <span class="grid-list-icon cursor-pointer" [matTooltipShowDelay]="500"
              [matTooltip]="'COMMON_PAGE.GRID_VIEW' | i18nTranslate">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.25 1.5V4.25H14.5V2C14.5 1.75 14.25 1.5 14 1.5H11.25ZM9.75 1.5H6.25V4.25H9.75V1.5ZM4.75 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V4.25H4.75V1.5ZM1.5 5.75V8.25H4.75V5.75H1.5ZM1.5 9.75V12C1.5 12.2812 1.71875 12.5 2 12.5H4.75V9.75H1.5ZM6.25 12.5H9.75V9.75H6.25V12.5ZM11.25 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V9.75H11.25V12.5ZM14.5 8.25V5.75H11.25V8.25H14.5ZM0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2ZM6.25 8.25H9.75V5.75H6.25V8.25Z" fill="#4379EE"/>
                </svg>
            </span>
          </div>
          <div class="qb-template-style-switch-icon-container qb-icon-background">
            <span class=" grid-list-icon qb-icon-container" [matTooltipShowDelay]="500"
              [matTooltip]="'COMMON_PAGE.LIST_VIEW' | i18nTranslate">
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0C1.53125 0 2 0.46875 2 1C2 1.5625 1.53125 2 1 2C0.4375 2 0 1.5625 0 1C0 0.46875 0.4375 0 1 0ZM4.75 0.25H14.25C14.6562 0.25 15 0.59375 15 1C15 1.4375 14.6562 1.75 14.25 1.75H4.75C4.3125 1.75 4 1.4375 4 1C4 0.59375 4.3125 0.25 4.75 0.25ZM4.75 5.25H14.25C14.6562 5.25 15 5.59375 15 6C15 6.4375 14.6562 6.75 14.25 6.75H4.75C4.3125 6.75 4 6.4375 4 6C4 5.59375 4.3125 5.25 4.75 5.25ZM4.75 10.25H14.25C14.6562 10.25 15 10.5938 15 11C15 11.4375 14.6562 11.75 14.25 11.75H4.75C4.3125 11.75 4 11.4375 4 11C4 10.5938 4.3125 10.25 4.75 10.25ZM2 6C2 6.5625 1.53125 7 1 7C0.4375 7 0 6.5625 0 6C0 5.46875 0.4375 5 1 5C1.53125 5 2 5.46875 2 6ZM1 10C1.53125 10 2 10.4688 2 11C2 11.5625 1.53125 12 1 12C0.4375 12 0 11.5625 0 11C0 10.4688 0.4375 10 1 10Z" fill="#4379EF"/>
                </svg>
            </span>
          </div>
        </div>
        <button *ngIf="(validWidgets | widgetConfig: 23)" class="qb-create-template-div primary-button"
          (click)="createStandardTemplate()" data-button-name="Create new Template">
          <span class="qb-create-template-span">
            {{'COMMUNICATION_PAGE.CREATE_NEW_TEMPLATE' | i18nTranslate}}
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="qb-select-list" *ngIf="layouts">
    <div class="qb-side-panel-container" id="side-panel-container" *ngIf="validWidgets | widgetConfig: 22">
      <div class="heading-templates">
        <span>{{headingTextKey}}</span>
      </div>
      <div class="list-options">
        <mat-select [(ngModel)]="filterSelected" (selectionChange)="selectList(tabSelected,filterSelected,true)"
          class="mini-select-style" >
          <mat-option [value]="'All'">All</mat-option>
          <mat-option [value]="'generic'">{{'COMMON_PAGE.GENERAL' | i18nTranslate}}</mat-option>
          <mat-option [value]="'reward'">{{'COMMON_PAGE.REWARD' | i18nTranslate}}</mat-option>
        </mat-select>
        <!-- <ng-template #noFilter>
          All
        </ng-template> -->
        <mat-select [(ngModel)]="tabSelected" (selectionChange)="selectList(tabSelected,null,true)"
          class="mini-select-style">
          <mat-option [value]="'preset'">{{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}</mat-option>
          <mat-option [value]="'custom'">{{'COMMUNICATION_PAGE.CUSTOM' | i18nTranslate}}</mat-option>
        </mat-select>
        <!-- <ng-template #noMainListFilter>
          {{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}
        </ng-template> -->
       
      </div>



      <div class="qb-d-flex qb-Rectangle">
        <input class="qb-input-field" [(ngModel)]="filter.label" type="text" placeholder="Search"
          (keyup)="filterChange($event)" (input)="searchEmails()" #search />
        <span class="material-icons icons-primary" *ngIf="filter.label.length==0"> search </span>
        <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19" *ngIf="filter.label.length>0"
          (click)="filter.label='';searchEmails(filter.label)">
          close
        </span>
      </div>
      <div class="qb-layout-name-container" id="layout-list-container">
        <div *ngIf="!loading && finalTemplates.length>0">
          <div [id]="'eTemp-' + selectedTemplate['id']" *ngFor="
          let layout of finalTemplates | labelPipe: filter.label:'layoutName';
          let i = index
            " class="qb-top-list" [class.active]="layout['id'] == selectedTemplate['id']"
            (click)="container.scrollTop = 0; viewTemplateDetail(layout)" data-button-name="View" [attr.data-item-name]="
              '(' + selectedTemplate['id'] + ')' + selectedTemplate['layoutName']
            ">
            <div class="qb-list-class" [matTooltip]="layout['layoutName']" matTooltipclass="qb-ex-tooltip-red1"
              matTooltipShowDelay="500" matTooltipPosition="right">
              <div class="material-icons-outlined email-icon-color"> email </div>
              <div class="qb-segment-name-div">
                <span class="qb-segment-name-text">
                  {{ layout["layoutName"] }}</span>
              </div>
              <!-- <div class="type-container"> -->
                <!-- <div [matTooltip]="layout['apiToCallForDetails']=='layout'?'General':'Reward'" class="type">
                  {{layout['apiToCallForDetails']=='layout'?'G':'R'}}
                </div> -->
                <!-- <div [matTooltip]="layout['emailType']=='STANDARD'?'Preset':'Custom'" class="type">
                  {{layout['emailType']=='STANDARD'?'P':'C'}}
                </div> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="loader">
          <app-qubriux-loading [size]="45" [loadingText]="'Fetching Email Templates...'"></app-qubriux-loading>
        </div>
        <div *ngIf="!loading && (finalTemplates.length === 0 || (finalTemplates | labelPipe: filter.label:'layoutName').length==0)"
          class="qb-empty-customers-div">
          <div>{{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
        </div>
      </div>
    </div>
    <div class="qb-widget-container" #container >
      <div *ngIf="validWidgets" class="height-100-perc ">
        <div class="height-100-perc" *ngIf="!templateLoading; else loadSpinner">
          <div class="height-100-perc" *ngIf="selectedLayout">
            <div class="qb-header-container">
              <span class="qb-segment-name-text qb-fw-600 main-title">
                {{ selectedTemplate["layoutName"] }}</span>
              <div class="icons-row ">
                <div class="cursor-pointer" *ngIf="selectedTemplate['emailType']!='STANDARD'">
                  <span class="material-icons-outlined icons-primary qb-icon-position" [matTooltipShowDelay]="500"
                    [matTooltip]="'COMMON_PAGE.EDIT' | i18nTranslate" (click)="editTemplate(layout, false)"
                    data-button-name="Edit: Tile view" [attr.data-item-name]="
                        '(' +
                        selectedTemplate['id'] +
                        ')' +
                        selectedTemplate['layoutName']
                      ">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5625 10.7812L13.5625 9.78125C13.6458 9.69792 13.7396 9.67708 13.8438 9.71875C13.9479 9.76042 14 9.84375 14 9.96875V14.5C14 14.9167 13.8542 15.2708 13.5625 15.5625C13.2708 15.8542 12.9167 16 12.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V3.5C0 3.08333 0.145833 2.72917 0.4375 2.4375C0.729167 2.14583 1.08333 2 1.5 2H10.0625C10.1667 2 10.2396 2.05208 10.2812 2.15625C10.3229 2.26042 10.3021 2.35417 10.2188 2.4375L9.21875 3.4375C9.17708 3.47917 9.125 3.5 9.0625 3.5H1.5V14.5H12.5V10.9375C12.5 10.875 12.5208 10.8229 12.5625 10.7812ZM17.4688 4.46875L9.25 12.6875L6.4375 13C6.02083 13.0417 5.66667 12.9167 5.375 12.625C5.08333 12.3333 4.95833 11.9792 5 11.5625L5.3125 8.75L13.5312 0.53125C13.8854 0.177083 14.3125 0 14.8125 0C15.3125 0 15.75 0.177083 16.125 0.53125L17.4688 1.875C17.8229 2.22917 18 2.66667 18 3.1875C18 3.6875 17.8229 4.11458 17.4688 4.46875ZM14.375 5.4375L12.5625 3.625L6.75 9.4375L6.53125 11.4688L8.5625 11.25L14.375 5.4375ZM16.4062 2.9375L15.0625 1.59375C14.8958 1.44792 14.7396 1.44792 14.5938 1.59375L13.625 2.5625L15.4375 4.375L16.4062 3.40625C16.5521 3.26042 16.5521 3.10417 16.4062 2.9375Z" fill="#4379EF"/>
                        </svg>
                        
                  </span>
                </div>
                <div class="cursor-pointer" *ngIf="
                appSubscriptionPlan
              ">
                  <span class="material-icons-outlined icons-primary qb-icon-position"
                    (click)="createSharableLink(selectedTemplate)" data-button-name="Clone: Tile view"
                    [attr.data-item-name]="
                  '(' +
                  selectedTemplate['id'] +
                  ')' +
                  selectedTemplate['layoutName']
                " [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.SHARE' | i18nTranslate">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 3C14 4.65625 12.6562 6 11 6C10.0938 6 9.28125 5.625 8.71875 4.96875L5.90625 6.375C5.96875 6.59375 6 6.8125 6 7C6 7.21875 5.96875 7.4375 5.90625 7.65625L8.71875 9.03125C9.28125 8.40625 10.0938 8 11 8C12.6562 8 14 9.34375 14 11C14 12.6562 12.6562 14 11 14C9.34375 14 8 12.6562 8 11C8 10.8125 8 10.5938 8.0625 10.375L5.25 8.96875C4.6875 9.625 3.875 10 3 10C1.34375 10 0 8.65625 0 7C0 5.34375 1.34375 4 3 4C3.875 4 4.6875 4.40625 5.25 5.03125L8.0625 3.65625C8 3.4375 8 3.21875 8 3C8 1.34375 9.34375 0 11 0C12.6562 0 14 1.34375 14 3ZM3 8.5C3.53125 8.5 4 8.21875 4.28125 7.75C4.5625 7.3125 4.5625 6.71875 4.28125 6.25C4 5.8125 3.53125 5.5 3 5.5C2.4375 5.5 1.96875 5.8125 1.6875 6.25C1.40625 6.71875 1.40625 7.3125 1.6875 7.75C1.96875 8.21875 2.4375 8.5 3 8.5ZM12.5 3C12.5 2.46875 12.1875 2 11.75 1.71875C11.2812 1.4375 10.6875 1.4375 10.25 1.71875C9.78125 2 9.5 2.46875 9.5 3C9.5 3.5625 9.78125 4.03125 10.25 4.3125C10.6875 4.59375 11.2812 4.59375 11.75 4.3125C12.1875 4.03125 12.5 3.5625 12.5 3ZM11 12.5C11.5312 12.5 12 12.2188 12.2812 11.75C12.5625 11.3125 12.5625 10.7188 12.2812 10.25C12 9.8125 11.5312 9.5 11 9.5C10.4375 9.5 9.96875 9.8125 9.6875 10.25C9.40625 10.7188 9.40625 11.3125 9.6875 11.75C9.96875 12.2188 10.4375 12.5 11 12.5Z" fill="#4379EF"/>
                  </svg>
                  
                  </span>
                </div>
                <div class="cursor-pointer" *ngIf="
                      selectedTemplate['type'] !== 'REWARD' &&
                      appSubscriptionPlan
                    ">
                  <span class="material-icons-outlined icons-primary qb-icon-position" (click)="cloneTemplate(layout)"
                    data-button-name="Clone: Tile view" [attr.data-item-name]="
                        '(' +
                        selectedTemplate['id'] +
                        ')' +
                        selectedTemplate['layoutName']
                      " [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.CLONE' | i18nTranslate">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5 0C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5V11.5C16 11.9167 15.8542 12.2708 15.5625 12.5625C15.2708 12.8542 14.9167 13 14.5 13H13V14.5C13 14.9167 12.8542 15.2708 12.5625 15.5625C12.2708 15.8542 11.9167 16 11.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V4.5C0 4.08333 0.145833 3.72917 0.4375 3.4375C0.729167 3.14583 1.08333 3 1.5 3H3V1.5C3 1.08333 3.14583 0.729167 3.4375 0.4375C3.72917 0.145833 4.08333 0 4.5 0H14.5ZM11.3125 14.5C11.4375 14.5 11.5 14.4375 11.5 14.3125V13H4.5C4.08333 13 3.72917 12.8542 3.4375 12.5625C3.14583 12.2708 3 11.9167 3 11.5V4.5H1.6875C1.5625 4.5 1.5 4.5625 1.5 4.6875V14.3125C1.5 14.4375 1.5625 14.5 1.6875 14.5H11.3125ZM14.3125 11.5C14.4375 11.5 14.5 11.4375 14.5 11.3125V1.6875C14.5 1.5625 14.4375 1.5 14.3125 1.5H4.6875C4.5625 1.5 4.5 1.5625 4.5 1.6875V11.3125C4.5 11.4375 4.5625 11.5 4.6875 11.5H14.3125Z" fill="#4379EF"/>
                        </svg>
                        
                  </span>
                </div>
                <div class="cursor-pointer" *ngIf="selectedTemplate['isDeletable'] && appSubscriptionPlan">
                  <span class="material-icons-outlined icons-primary qb-icon-position" (click)="
                        deleteEmail()
                      " data-button-name="Delete: Tile view" [attr.data-item-name]="
                        '(' +
                        selectedTemplate['id'] +
                        ')' +
                        selectedTemplate['layoutName']
                      " [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate">
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#4379EF"/>
                        </svg>
                        
                  </span>
                </div>
              </div>
            </div>
            <div class="qb-content-container">
              <div class="qb-not-allowed width-set">
                <div *ngIf="selectedTemplate && !templateLoading; else loadSpinner2" class="qb-pe-none">
                  <app-email-template-preview [selectedLayout]="selectedLayout"></app-email-template-preview>
                </div>
                <ng-template #loadSpinner2>
                  <div class="qb-d-flex qb-jc-center qb-mt-10 loading-container-2">
                    <app-qubriux-loading [size]="45" ></app-qubriux-loading>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div *ngIf="!loading && (finalTemplates.length === 0 || (finalTemplates | labelPipe: filter.label:'layoutName').length==0)"
           class="qb-no-data-area-email">
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg"
                        alt="">
                    <div style="margin-top: 15px;">
                        {{'SEGMENT_PAGE.THERE_IS_NO_DATA_AVAILABLE' | i18nTranslate}}
                    </div>
                </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadSpinner>
    <div style="display: flex;align-items: center; justify-content: center; width: 100%; height: 100%;">
      <app-qubriux-loading  [size]="65" [loadingText]="'Fetching Email Template...'"></app-qubriux-loading>
    </div>
  </ng-template>
</div>