import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-how-to-create',
  templateUrl: './campaign-how-to-create.component.html',
  styleUrls: ['./campaign-how-to-create.component.css']
})
export class CampaignHowToCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  createNewCampaign(){
    
  }

}
