import { Component, OnInit, Inject, NgZone } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { ContactUsDialogComponent } from "../contact-us-dialog/contact-us-dialog.component";
import { Router } from "@angular/router";
import { ConfirmationDialogBoxComponent } from "../confirmation-dialog-box/confirmation-dialog-box.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
interface DialogData {
  
  layoutData: [];
  clone: boolean;
  

  
}

@Component({
  selector: "app-sms-template-creation-dialog",
  templateUrl: "./sms-template-creation-dialog.component.html",
  styleUrls: ["./sms-template-creation-dialog.component.css"],
})
export class SmsTemplateCreationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SmsTemplateCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private dialog: MatDialog,
    private _ApiCallService: ApiCallService,
    private ngZone: NgZone,
    private _i18nDynamicTranslate: TranslateDynamicText,
  ) {}

  toggled: boolean = false;
  layoutType;
  smsText = "";
  optOutText = false;
  insertLink = false;
  shortenUrl = false;
  submit = false;
  selectedMergeTags :any = [[]];
  mergetagFilter = "";
  type = null;
  subLayout= null;
  usesUnicode: boolean = false;



  handleSelection(event) {
    if (!(160 - this.smsText.length < event.char.length)) {
      let text = this.smsText;
      this.smsText =
        text.slice(0, this.textSelection) +
        event.char +
        text.slice(this.textSelection);
      this.textSelection += event.char.length;
      var txtarea = document.getElementById("smsText") as HTMLInputElement;
      txtarea.selectionEnd = this.textSelection + 1;
      this.updateSmsCount();
    }
  }
  dropDownStatus = false;
  textSelection;
  templateName = "";
  namePatterm = new RegExp(/^(\s+\S+\s*)*(?!\s).*$/);
  nameError = false;
  linkURL = "";
  selectedCategory = "";

  smsCount = 0;
  charCount = 0;
  mergeTags=[];
  // mergeTags = [
  //   { name: "shop name", value: "{{$shop_name}}" },
  //   { name: "user name", value: "{{$user_name}}" },
  //   { name: "product name", value: "{{$product_name}}" },
  //   { name: "category name", value: "{{$category_name}}" },
  //   { name: "date", value: "{{$date}}" },
  // ];

  ngOnInit() {
    this.fetchSmsData();   
    this.updateSmsCount();
    this.getMergeTags();
    // if(this.data != null){
    //   this.prefillData();
    // }
  }
  // prefillData(){
  //   console.log("DARA",this.data);
  //   this.type = this.data['type'];
  //   let d = this.getLayout(this.data['layoutData']);
  //   this.templateName = d['templateName'] + "_copy";
  //   this.layoutType = this.data['layoutType'];
  //   this.smsText = d['body']
  //   this.updateSmsCount();
  //   this.selectedCategory = this.data['channelType'];
  //   console.log("Tem",this.templateName)
   
  // }
  
  // getLayout(layout){
  //   let layoutData = JSON.parse(layout);
  //   return layoutData;
  // }

    toggleSelect(
    event,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId
  ) {
    event.stopPropagation();
    let element = document.getElementById(elementId);
    let segmentSelect = document.getElementById(segmentSelectId);
    let segmentSelectPanel = document.getElementById(segmentSelectPanelId);
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId
    );
    if (this.dropDownStatus) {
      this.dropDownStatus = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.boxShadow = "0 0 10px 0 #c6ced685";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //this.getSegmentCount();
      //element.style.display = 'none';
    } else {
      this.dropDownStatus = true;
      element.style.height = "140px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }

  addMergeTag(
    value,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId
  ) {
    // if (160 - this.smsText.length < value.length) {
    //   return;
    // }
    var txtarea = document.getElementById("smsText");
    let element = document.getElementById(elementId);
    let segmentSelect = document.getElementById(segmentSelectId);
    let segmentSelectPanel = document.getElementById(segmentSelectPanelId);
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId
    );
    this.dropDownStatus = false;
    element.style.height = "0px";
    segmentSelect.style.overflow = "hidden";
    segmentSelectPanel.style.boxShadow = "unset";
    segmentSelectPanel.style.border = "1px solid #919191";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    let text = this.smsText;
    this.smsText =
      text.slice(0, this.textSelection) +
      value +
      text.slice(this.textSelection);
    txtarea.focus();
    this.updateSmsCount();
    // let txtareaEle = document.getElementById("smsText") as HTMLInputElement;
    // txtareaEle.selectionEnd = ;
  }

  updateMeasurementPointComment(event) {
    var txtarea = document.getElementById("smsText") as HTMLInputElement;
    this.textSelection = txtarea.selectionStart;
  }

  keypresshandler(event) {
    this.updateSmsCount();
    // if (160 - this.smsText.length === 0) {
    //   return false;
    // }
    //let charCode = event.keyCode;
    // if (charCode === 64) {
    //   var txtarea = document.getElementById("smsText") as HTMLInputElement;
    //   this.textSelection = txtarea.selectionStart;
    //   let element = document.getElementById("multi-select-dropdown");
    //   let segmentSelect = document.getElementById("segment-select");
    //   let segmentSelectPanel = document.getElementById("multi-select-panel");
    //   let keyboardDownIconElement = document.getElementById(
    //     "keyboard-down-icon-container"
    //   );
    //   this.dropDownStatus = true;
    //   element.style.height = "140px";
    //   segmentSelect.style.overflow = "auto";
    //   segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
    //   segmentSelectPanel.style.border = "1px solid #25a7f9";
    //   keyboardDownIconElement.style.transform = "rotate(180deg)";
    //   return false;
    // } else {
    //   return true;
    // }
  }

  templateNameKeyPress(event) {
    if (50 - this.templateName.length === 0) {
      return false;
    }
  }

  templateNamePaste(event) {
    if (
      50 - this.templateName.length <
      event.clipboardData.getData("text").length
    ) {
      return false;
    }
  }

  saveSms(schedule) {
    // console.log("TESTTT", this.templateName.match(this.namePatterm));
    this.submit = true;
    // this.getBodyText();

    if (this.smsText.length === 0 || this.templateName.length === 0) {
      return;
    }
    
    if (this.templateName.match(this.namePatterm) === null) {
      this.nameError = true;
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "30%",
      data: {
        subject: "Warning",
        guideLine: `Credit needed per customer ≥ ${this.smsCount}. <br> The count varies depending on the number of characters present in the merge tags.`,
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
      }
    });
  
    
    dialogRef.afterClosed().subscribe((confirmed) => {
    if (confirmed) {
 
    let data = {
      id: this.data
        && this.data.clone?
           this.data.layoutData["id"]
        : null,
      name: this.templateName,
      layoutData:
        this.smsText + (this.optOutText ? "\nReply STOP to opt out" : ""),
      layoutJson: null,
      layoutType: "CUSTOM",
      // type:(this.data?.layoutData["subLayout"] =="REWARD" && this.data?.clone)||(this.data?.layoutData["subLayout"] =="CONSENT" && this.data?.clone) ? this.data?.layoutData["type"] : this.templateName,
      type : this.data?.clone ? this.data?.layoutData["type"] : this.templateName,
      channel: "SMS",
      channelType: "PROMOTIONAL",
      // subLayout:this.data?.layoutData["subLayout"]=="REWARD"?"REWARD":this.data?.layoutData["subLayout"]=="CONSENT"?"CONSENT":"GENERIC",
      subLayout : this.data?.layoutData["subLayout"] ? this.data?.layoutData["subLayout"] : 'GENERIC'
      // bodyText: this.selectedMergeTags[0].length === 0 ? null : this.selectedMergeTags,

    };
    if(data.id && !this.data.clone){
    this._ApiCallService.updateSmsTemplate(data).subscribe((response) => {
      this.dialogRef.close({ id: response["body"], schedule: schedule });
    });

  }
  else{
    this._ApiCallService.createSmsTemplate(data).subscribe((response) => {
      this.dialogRef.close({ id: response["body"], schedule: schedule });
    });
  } 

}

});
  }


  closeEditor() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
    
      this.router.navigate(["/app/sms/choose-layout"]);
    
  }

  onPaste(event) {
    this.updateSmsCount();
    // if (
    //   160 - this.smsText.length <
    //   event.clipboardData.getData("text").length
    // ) {
    //   return false;
    // }
  }
  fetchSmsData(){
    if (this.data) {
      this.templateName = this.data.clone?
        this.addCopySuffix(this.data.layoutData["name"]) : this.data.layoutData["name"];
      if (this.data.clone) {
        if (
          this.data.layoutData["layoutData"].substring(
            this.data.layoutData["layoutData"].length - 21,
            this.data.layoutData["layoutData"].length
          ) === "Reply STOP to opt out"
        ) {
          this.smsText = this.data.layoutData["layoutData"].substring(
            0,
            this.data.layoutData["layoutData"].length - 21 
          );
          this.optOutText = true;
        } else {
          this.smsText = this.data.layoutData["layoutData"];
        }
      } else {
        console.log("added3");
        this.smsText = this.data.layoutData["layoutData"];
      }
      this.textSelection = this.smsText.length;
    }
  }

  addCopySuffix(name: string): string {
    const maxLength = 50;
    const suffix = '_copy';
    const maxBaseNameLength = maxLength - suffix.length;
    if (name.length <= maxBaseNameLength) {
        return name + suffix;
    }
    const trimmedBaseName = name.slice(0, maxBaseNameLength).trim();
    return trimmedBaseName + suffix;
  }

  isUsingUnicode(text: string): boolean {
    const GSM_7BIT_CHARS: string =
      "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1BÆæßÉ !\"#¤%&'()*+,-./" +
      "0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
  
    for (let i = 0; i < text.length; i++) {
      if (!GSM_7BIT_CHARS.includes(text[i])) {
        return true;
      }
    }
    return false;
  }

  updateSmsCount() {
    let smsText = this.smsText;
    this.usesUnicode = this.isUsingUnicode(smsText);
    let countOfChar = 0;
    for (let i = 0; i < smsText.length; i++) {
      if (smsText[i] === "{") {
        if (smsText[i + 1] === "{") {
          for (let j = i + 2, count = 1; j < smsText.length; j++, count++) {
            if (smsText[j] === "}") {
              if (smsText[j + 1] === "}") {
                if (this.checkMergeTagExist(smsText.substring(i, j + 2))) {
                  countOfChar += count + 3;
                }
                break;
              }
            }
          }
        }
      }
    }
    let finalCharCount = this.smsText.length - countOfChar;
    if (this.optOutText) {
      finalCharCount += 22;
    }
    let charsPerSegment = this.usesUnicode ? 70 : 160;
    this.smsCount = finalCharCount === 0 ? 1 : Math.ceil(finalCharCount / charsPerSegment);
    this.charCount =
      finalCharCount - (this.smsCount === 0 ? 0 : (this.smsCount - 1) * charsPerSegment);
  }

  checkMergeTagExist(mergeTag) {
    if (this.mergeTags.findIndex((x) => x.value === mergeTag) > -1) {
      return true;
    } else {
      return false;
    }
  }

  getMergeTags(){
    this.type=this.data?.layoutData["type"];
    this.subLayout=this.data?.layoutData["subLayout"]=="REWARD"?"REWARD":"GENERIC";
    this._ApiCallService.getMergeTagSMS(this.subLayout, this.type,'SMS').subscribe((response)=>{
      console.log("MERGETAGS", response);
      let data : any = [];
      for(let k in response['body']){
        let n:any={}
        n.name = k;
        n.value = response['body'][k];
        data.push(n);
      }
      this.mergeTags = data;
      console.log("TAGS",this.mergeTags)
    })
  }

  // getBodyText(){
  //   let smsText = this.smsText
  //   this.selectedMergeTags = [[]];
  //   for (let i = 0; i < smsText.length; i++) {
  //     if (smsText[i] === "{") {
  //       if (smsText[i + 1] === "{") {
  //         for (let j = i + 2, count = 1; j < smsText.length; j++, count++) {
  //           if (smsText[j] === "}") {
  //             if (smsText[j + 1] === "}") {
  //               this.selectedMergeTags[0].push(smsText.substring(i + 2, j))
  //               break;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   console.log("SELECTED_MERGETAGS", this.selectedMergeTags);
  // }

  optoutValueChange(event) {
    this.updateSmsCount();
    // if (event["checked"] === true) {
    //   if (this.smsText.length + 21 > 160) {
    //     this.optOutText = false;
    //     return false;
    //   }
    // }
  }

  addLink() {
    // if (160 - this.smsText.length < this.linkURL.length) {
    //   return;
    // }
    if (this.shortenUrl) {
      let text = this.smsText;
      this.smsText =
        text.slice(0, this.textSelection) +
        this.linkURL +
        text.slice(this.textSelection);
      let txtarea = document.getElementById("smsText");
      txtarea.focus();
    } else {
      let text = this.smsText;
      this.smsText =
        text.slice(0, this.textSelection) +
        this.linkURL +
        text.slice(this.textSelection);
      let txtarea = document.getElementById("smsText");
      txtarea.focus();
    }
    this.updateSmsCount();
  }
}
