import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  ErrorHandler,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog"; 
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe, getCurrencySymbol } from "@angular/common";
import { environment } from "src/environments/environment";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { ConfirmationDialogBoxComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";


@Component({
  selector: 'app-campaign-add-filter',
  templateUrl: './campaign-add-filter.component.html',
  styleUrls: ['./campaign-add-filter.component.css']
})
export class CampaignAddFilterComponent implements OnInit {
   @ViewChild("mainHeader") mainHeader: ElementRef;
  @ViewChild("queryResults") queryResults: ElementRef;
  @ViewChild("segmentCreationRef") segmentCreationRef: ElementRef;
  @ViewChild("mainContainer") mainContainer: ElementRef;
  @ViewChild("previewHeading") previewHeading: ElementRef;
  @Input() showfilter: boolean;
  @Input() submittedData: boolean;
  @Input() segmentDataValue: any;

  @Output() messageEvent = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandle: ErrorHandler,
    private popUp: PopUpDialogService,
    private cd :ChangeDetectorRef,
  ) {}
  merchantTrackingAction;
  merchantTrackingType;
  s3BucketUrl = environment.s3BucketUrl;
  segment: FormGroup;
  segmentData: any;
  sample: FormGroup;
  segmentVariables = [];
  dataFetchComplete: boolean = false;
  dataFillComplete: boolean = false;
  segmentName: FormControl;
  innerRel: string;
  submitClicked = false;
  previewClicked = false;
  templateId;
  subscription;
  segmentDetail;
  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  templateListContentHeight;
  isExpanded: boolean = false;
  queryloading: boolean = false;

  stringtype = "String";
  queryAvgSpends = { high: 0, low: 0, medium: 0 };
  querySegmentCount;
  queryCount;
  querysegmentTotal = 0;
  gaugeType = "semi";
  gaugeValue = 28.3;
  gaugeLabel = "Speed";
  gaugeAppendText = "km/hr";
  customerCount;
  namePattern = "^[A-Za-z]+$";
  spendPattern = "^[0-9.]+";
  numberPattern = "^[0-9]+$";
  mobilenumberPattern = "[- +()0-9]+";
  emailPattern = new RegExp(
    /^(([a-zA-Z0-9]+([\.\+][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|((([a-zA-Z0-9])+(\-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}))$/
  );
  revCurrency;
  currencySymbol;
  Number = "number";
  String = "string";
  afterSegment;
  totalCustomerCount = 1;
  createSegmentStatus = true;
  topicGraphData = [];
  customerPercentage;
  createContainerHeight;
  previewHeadingHeight;
  eventDropdownElement;
  attributeDropdownElement;
  cloneStatus = false;
  queryAverageSpendTotal;
  gameId = null;
  loading = false;

  ngOnInit() {
    this.initializeComponent();
  }

  initializeComponent() {
    this.loading = true;
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.apiCall.getCustomerSegmentVariables().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let segmentVariable = response["body"];
          let keys = [];
          for (let key in segmentVariable) {
            keys.push(key);
          }
          keys = keys.sort();
          keys.forEach((element) => {
            this.segmentVariables[element] = segmentVariable[element];
          });
          this.dataFetchComplete = true;
          this.initForm();
          this.route.params.subscribe((param) => {
            if (param["gameId"]) {
              this.gameId = param["gameId"];
            }
            this.templateId = param["id"];
            if (param["id"] === "null") {
              this.submitClicked = false;
              this.previewClicked = false;
              this.merchantTrackingAction = "create";
              this.merchantTrackingType = "custom";
              this.dataFillComplete = true;
            } else {
              this.createSegmentStatus = true;
              if (param["clone"] === "true") {
                this.cloneStatus = true;
              } else {
                this.cloneStatus = false;
              }
              if(this.segmentDataValue['segmentDefinition'])
                this.prefillForm(this.segmentDataValue);
              this.dataFillComplete = true;
            }
          });
          this.loading = false;
          this.cd.detectChanges();
        } else {
          this.loading = false;
          this.cd.detectChanges();
          this.popUp.openPopup("failure", "Failed to load data");
        }
      },
      (err) => {
        this.popUp.openPopup("failure", err.error.body);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes) {
      if(changes.segmentDataValue) {
        if(changes.segmentDataValue.previousValue != changes.segmentDataValue.currentValue){
          if(this.segmentDataValue['segmentDefinition']) {
            this.showfilter = true;
            this.initializeComponent();
          }
        }  
      }
      if(changes.showfilter && changes.showfilter.currentValue) {
        this.initializeComponent();
      }
    } 
  }
sendMessage(){
  this.messageEvent.emit(this.getQueryDetails());
}
  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.mainContainer && this.mainHeader && this.previewHeading) {
        this.createContainerHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.mainHeader.nativeElement.offsetHeight;
        this.previewHeadingHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.previewHeading.nativeElement.offsetHeight;
        document
          .getElementById("mainContainer")
          .addEventListener("click", (event) => this.hideDropdowns(event));
        clearInterval(interval);
      }
    });
  }

  hideDropdowns(event) {
    this.hideAttributeDropdown(event);
    this.hideEventDropdown(event);
  }

  hideAttributeDropdown(event) {
    if (this.attributeDropdownElement) {
      let i = this.attributeDropdownElement.split("+")[0];
      let j = this.attributeDropdownElement.split("+")[1];
      let element = document.getElementById(
        "multi-select-dropdown-condition" + i + j
      );
      let segmentSelect = document.getElementById(
        "segment-select-condition" + i + j
      );
      let segmentSelectPanel = document.getElementById(
        "multi-select-panel-condition" + i + j
      );
      let keyboardDownIconElement = document.getElementById(
        "keyboard-down-icon-container-condition" + i + j
      );
      if (element) {
        if (event === null || !element.contains(event.target)) {
          element.style.height = "0px";
          element.style.border = "unset";
          segmentSelect.style.overflow = "hidden";
          keyboardDownIconElement.style.transform = "rotate(0deg)";
        }
      }
    }
  }

  hideEventDropdown(event) {
    if (this.eventDropdownElement) {
      let i = this.eventDropdownElement.split("+")[0];
      let j = this.eventDropdownElement.split("+")[1];
      let element = document.getElementById("multi-select-dropdown" + i + j);
      let segmentSelect = document.getElementById("segment-select" + i + j);
      let segmentSelectPanel = document.getElementById(
        "multi-select-panel" + i + j
      );
      let keyboardDownIconElement = document.getElementById(
        "keyboard-down-icon-container" + i + j
      );
      if (element) {
        if (event === null || !element.contains(event.target)) {
          element.style.height = "0px";
          element.style.border = "unset";
          segmentSelect.style.overflow = "hidden";
          keyboardDownIconElement.style.transform = "rotate(0deg)";
        }
      }
    }
  }

  ngOnDestroy() {
    //this.parentToChildService.setId(null)
  }

  prefillForm(segment) {
    if(segment["segmentName"]){
      segment["segmentName"] = this.cloneStatus
        ? segment["segmentName"] + "_copy"
        : segment["segmentName"];
    }
    (<FormArray>this.segment.get("segmentGroupArray")).removeAt(0);
    for (
      let i = 0;
      i < segment["segmentDefinition"]["logicalExpressions"].length;
      i++
    ) {
      let innerRelation = "AND";
      if (
        segment["segmentDefinition"]["logicalExpressions"][i][
          "comparisonStatements"
        ].length > 1
      ) {
        innerRelation =
          segment["segmentDefinition"]["logicalExpressions"][i][
            "comparisonStatements"
          ][1]["logicalOperator"];
      }
      this.addGroup(
        "update",
        segment["segmentDefinition"]["logicalExpressions"][i][
          "logicalOperator"
        ],
        innerRelation
      );
      (<FormArray>(
        (<FormArray>this.segment.get("segmentGroupArray"))
          .at(i)
          .get("segmentConditionArray")
      )).removeAt(0);
      for (
        let j = 0;
        j <
        segment["segmentDefinition"]["logicalExpressions"][i][
          "comparisonStatements"
        ].length;
        j++
      ) {
        segment["segmentDefinition"]["logicalExpressions"][i][
          "comparisonStatements"
        ][j]["logicalOperator"] === "AND"
          ? this.addConditionAND(
              i,
              "update",
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand1"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operator"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand2"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["logicalOperator"]
            )
          : this.addConditionOR(
              i,
              "update",
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand1"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operator"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["operand2"],
              segment["segmentDefinition"]["logicalExpressions"][i][
                "comparisonStatements"
              ][j]["logicalOperator"]
            );
        setTimeout(() => {
          //<<<---using ()=> syntax
          this.valueSuffix(i, j);
        }, 100);
        this.addValidatorOnForm(i, j, false);
      }
    }
  }

initForm() {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.segment = new FormGroup({
      segmentName: new FormControl("", [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
      ]),
      segmentGroupArray: new FormArray([
        new FormGroup({
          segmentConditionArray: new FormArray([
            new FormGroup({
              attribute: new FormControl(
                this.segmentVariables[firstKey][0]["name"],
                [Validators.required]
              ),
              attributeSearch: new FormControl(""),
              condition: new FormControl(
                this.segmentVariables[firstKey][0]["operators"][0]["operator"],
                [Validators.required]
              ),
              value: new FormControl("", [Validators.required]),
              attributeIndex: new FormControl(0, [Validators.required]),
              relation: new FormControl("AND"),
              attributeKey: new FormControl(
                Object.keys(this.segmentVariables)[0]
              ),
            }),
          ]),
          relation: new FormControl("AND"),
          innerRelation: new FormControl("AND"),
        }),
      ]),
    });
    this.addValidatorOnForm(0, 0, true);
    this.sample = this.fb.group({});
    this.valueSuffix(0, 0);
  }

  addGroup(operation, relation, innerRelation) {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.submitClicked = false;
    this.previewClicked = false;
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl(
            this.segmentVariables[firstKey][0]["name"],
            [Validators.required]
          ),
          attributeSearch: new FormControl(""),
          condition: new FormControl(
            this.segmentVariables[firstKey][0]["operators"][0]["operator"],
            [Validators.required]
          ),
          value: new FormControl("", [Validators.required]),
          attributeIndex: new FormControl(0, [Validators.required]),
          relation: new FormControl("AND"),
          attributeKey: new FormControl(Object.keys(this.segmentVariables)[0]),
        }),
      ]),
      relation: new FormControl(operation === "update" ? relation : "AND"),
      innerRelation: new FormControl(
        operation === "update" ? innerRelation : "AND"
      ),
    });
    (<FormArray>this.segment.get("segmentGroupArray")).push(control);
    operation === "update"
      ? null
      : this.addValidatorOnForm(
          (<FormArray>this.segment.get("segmentGroupArray")).length - 1,
          0,
          false
        );
    this.valueSuffix(
      (<FormArray>this.segment.get("segmentGroupArray")).length - 1,
      0
    );
  }

  addConditionAND(i, operation, attribute, condition, value, relation) {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.innerRel = "AND";
    const control = new FormGroup({
      attribute: new FormControl(
        operation === "create"
          ? this.segmentVariables[firstKey][0]["name"]
          : "",
        [Validators.required]
      ),
      attributeSearch: new FormControl(""),
      condition: new FormControl(
        operation === "create"
          ? this.segmentVariables[firstKey][0]["operators"][0]["operator"]
          : "",
        [Validators.required]
      ),
      value: new FormControl(operation === "create" ? null : null, [
        Validators.required,
      ]),
      attributeIndex: new FormControl(0, [Validators.required]),
      attributeKey: new FormControl(Object.keys(this.segmentVariables)[0]),
      relation: new FormControl(operation === "create" ? "AND" : ""),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
    operation === "update"
      ? null
      : this.addValidatorOnForm(
          i,
          (<FormArray>(
            this.segment
              .get("segmentGroupArray")
              ["controls"][i].get("segmentConditionArray")
          )).length - 1,
          false
        );
    if (operation === "update") {
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["attribute"]["value"] = attribute;
      this.refreshConditionArray(
        i,
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1,
        this.getKeyForParam(attribute)
      );
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["condition"]["value"] = condition;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["value"]["value"] = value;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["relation"]["value"] = relation;
    }
    this.valueSuffix(
      i,
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray").length - 1
    );
  }

  changeInnerRelation(index, relation) {
    let length = this.segment
      .get("segmentGroupArray")
      ["controls"][index].get("segmentConditionArray").length;
    for (let i = 0; i < length; i++) {
      this.segment
        .get("segmentGroupArray")
        ["controls"][index].get("segmentConditionArray")["controls"][i][
        "controls"
      ]["relation"]["value"] = relation;
    }
    this.segment
      .get("segmentGroupArray")
      ["controls"][index].get("innerRelation")["value"] = relation;
  }

  addCondition(index) {
    this.submitClicked = false;
    this.previewClicked = false;
    let relation = this.segment
      .get("segmentGroupArray")
      ["controls"][index].get("innerRelation").value;
    if (relation === "AND") {
      this.addConditionAND(index, "create", null, null, null, null);
    } else {
      this.addConditionOR(index, "create", null, null, null, null);
    }
  }

  addConditionOR(i, operation, attribute, condition, value, relation) {
    let firstKey = Object.keys(this.segmentVariables)[0];
    this.innerRel = "OR";
    const control = new FormGroup({
      attribute: new FormControl(
        operation === "create" ? this.segmentVariables[firstKey][0]["name"] : ""
      ),
      attributeSearch: new FormControl(""),
      condition: new FormControl(
        operation === "create"
          ? this.segmentVariables[firstKey][0]["operators"][0]["operator"]
          : ""
      ),
      value: new FormControl(operation === "create" ? null : null, [
        Validators.required,
      ]),
      attributeIndex: new FormControl(0),
      attributeKey: new FormControl(Object.keys(this.segmentVariables)[0]),
      relation: new FormControl(operation === "create" ? "OR" : ""),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
    operation === "update"
      ? null
      : this.addValidatorOnForm(
          i,
          (<FormArray>(
            this.segment
              .get("segmentGroupArray")
              ["controls"][i].get("segmentConditionArray")
          )).length - 1,
          false
        );
    if (operation === "update") {
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["attribute"]["value"] = attribute;
      this.refreshConditionArray(
        i,
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1,
        this.getKeyForParam(attribute)
      );
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["condition"]["value"] = condition;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["value"]["value"] = value;
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"][
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray").length - 1
      ]["controls"]["relation"]["value"] = relation;
    }
    this.valueSuffix(
      i,
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray").length - 1
    );
  }

  openDeleteConfirmDialog(i) {
    const dialogref = this.dialog.open(
      ConfirmationDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "280px",
        data: {
          subject: `DELETE  GROUP`,
          message: `Are you sure you want to delete the group ?`,
          cancelButtonText: "Cancel",
          successButtonText: "Delete",
        }
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if (result ) {
        this. removeGroup(i);
      }
    });
  }

  removeGroup(i) {
    (<FormArray>this.segment.get("segmentGroupArray")).removeAt(i);
    this.cd.detectChanges();
  }

  removeCondition(i, j) {
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).removeAt(j);
    if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")["controls"].length === 0
    ) {
      this.openDeleteConfirmDialog(i);
    }
  }

  relationToggle(relation, i) {
    this.segment.get("segmentGroupArray")["controls"][i]["controls"][
      "relation"
    ]["value"] = relation;
  }

  cRelationToggle(relation, i, j) {
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"][
      "relation"
    ]["value"] = relation;
  }

  addInnerGroup(i) {
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl("", [Validators.required]),
          attributeSearch: new FormControl(""),
          condition: new FormControl("", [Validators.required]),
          value: new FormControl("", [Validators.required]),
          attributeIndex: new FormControl(0, [Validators.required]),
          relation: new FormControl(),
        }),
      ]),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
  }

  refreshConditionArray(groupArrayIndex, conditionArrayIndex, key) {
    let index = this.segmentVariables[key].findIndex(
      (x) =>
        x.name ===
        this.segment
          .get("segmentGroupArray")
          ["controls"][groupArrayIndex].get("segmentConditionArray")[
          "controls"
        ][conditionArrayIndex]["controls"]["attribute"]["value"]
    );
    this.segment
      .get("segmentGroupArray")
      ["controls"][groupArrayIndex].get("segmentConditionArray")["controls"][
      conditionArrayIndex
    ]["controls"]["attributeIndex"]["value"] = index;
    this.segment
      .get("segmentGroupArray")
      ["controls"][groupArrayIndex].get("segmentConditionArray")["controls"][
      conditionArrayIndex
    ]["controls"]["attributeKey"]["value"] = key;
    // for (let i = 0; i < this.segmentVariables.length; i++) {
    //   if (
    //     this.segment
    //       .get("segmentGroupArray")
    //       ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //       "controls"
    //     ][conditionArrayIndex]["controls"]["attribute"]["value"] ===
    //     this.segmentVariables[i].name
    //   ) {
    //     this.segment
    //       .get("segmentGroupArray")
    //       ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //       "controls"
    //     ][conditionArrayIndex]["controls"]["attributeIndex"]["value"] = i;
    //     for (let j = 0; j < this.segmentVariables[i].operators.length; j++) {
    //       if (
    //         this.segment
    //           .get("segmentGroupArray")
    //           ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //           "controls"
    //         ][conditionArrayIndex]["controls"]["condition"]["value"] ===
    //         this.segmentVariables[i]["operators"][j]["operator"]
    //       ) {
    //         this.segment
    //           .get("segmentGroupArray")
    //           ["controls"][groupArrayIndex].get("segmentConditionArray")[
    //           "controls"
    //         ][conditionArrayIndex]["controls"]["condition"][
    //           "value"
    //         ] = this.segmentVariables[i]["operators"][j]["operator"];
    //         this.addValidatorOnForm(groupArrayIndex, conditionArrayIndex, true);
    //       }
    //     }
    //   }
    // }
    this.segment
      .get("segmentGroupArray")
      ["controls"][groupArrayIndex].get("segmentConditionArray")["controls"][
      conditionArrayIndex
    ]["controls"]["condition"]["value"] =
      this.segmentVariables[key][index]["operators"][0]["operator"];
    (<FormControl>(
      (<FormArray>(
        (<FormArray>this.segment.get("segmentGroupArray")).controls[
          groupArrayIndex
        ].get("segmentConditionArray")
      )).controls[conditionArrayIndex].get("value")
    )).reset();
    this.addValidatorOnForm(groupArrayIndex, conditionArrayIndex, true);
  }

  validityCheck(): boolean {
    if (this.segment.status === "INVALID") {
      //this.popUp.openPopup("failure", "Please check the form fields");
      return true;
    }
  }

  queryValidate() {
    for (
      let i = 0;
      i < (<FormArray>this.segment.get("segmentGroupArray")).length;
      i++
    ) {
      for (
        let j = 0;
        j <
        (<FormArray>(
          this.segment
            .get("segmentGroupArray")
            ["controls"][i].get("segmentConditionArray")
        )).length;
        j++
      ) {
        if (
          this.segment.controls.segmentGroupArray["controls"][i]["controls"][
            "segmentConditionArray"
          ]["controls"][j]["controls"]["value"]["errors"]
        ) {
          //this.popUp.openPopup("failure", "Please check the form field value");
          return true;
        }
      }
    }
  }

  trimData() {
    for (
      let i = 0;
      i < (<FormArray>this.segment.get("segmentGroupArray")).length;
      i++
    ) {
      for (
        let j = 0;
        j <
        (<FormArray>(
          this.segment
            .get("segmentGroupArray")
            ["controls"][i].get("segmentConditionArray")
        )).length;
        j++
      ) {
        if (
          this.getTypeOfData(
            (<FormControl>(
              (<FormArray>(
                (<FormArray>this.segment.get("segmentGroupArray")).controls[
                  i
                ].get("segmentConditionArray")
              )).controls[j].get("attribute")
            )).value
          ) === "String"
        ) {
          (<FormControl>(
            (<FormArray>(
              (<FormArray>this.segment.get("segmentGroupArray")).controls[
                i
              ].get("segmentConditionArray")
            )).controls[j].get("value")
          )).setValue(
            (<FormControl>(
              (<FormArray>(
                (<FormArray>this.segment.get("segmentGroupArray")).controls[
                  i
                ].get("segmentConditionArray")
              )).controls[j].get("value")
            )).value.trim()
          );
        }
      }
    }
  }

  addSampleGroup(id) {
    let divName = document.createElement("div");
    divName.id = "div" + Math.random();
    divName.className = "basic";
    document.getElementById(id).appendChild(divName);
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl(),
          attributeSearch: new FormControl(""),
          condition: new FormControl(),
          value: new FormControl(),
          relation: new FormControl(),
        }),
      ]),
    });
    this.sample.addControl("control" + Math.random(), control);
    document.getElementById(divName.id).append();
  }

  addSampleCondition(i) {
    const control = new FormGroup({
      attribute: new FormControl(),
      attributeSearch: new FormControl(""),
      condition: new FormControl(),
      value: new FormControl(),
      relation: new FormControl(),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
  }

  addSampleInnerGroup(i) {
    const control = new FormGroup({
      segmentConditionArray: new FormArray([
        new FormGroup({
          attribute: new FormControl(),
          attributeSearch: new FormControl(""),
          condition: new FormControl(),
          value: new FormControl(),
          relation: new FormControl(),
        }),
      ]),
    });
    (<FormArray>(
      this.segment
        .get("segmentGroupArray")
        ["controls"][i].get("segmentConditionArray")
    )).push(control);
  }

  segmentListing() {
    this.router.navigate(["/app/segments"]);
  }


  displayQueryResults() {
    this.isExpanded = true;
    document.getElementById("segmentCreationRef").style.width = "75%";
    document.getElementById("queryResults").style.width = "25%";
    document.getElementById("queryResults").style.display = "block";
    // document.getElementById("queryToggleIcon").style.display = "block";
    // document.getElementById("queryResults").style.padding = "2%";
    document.getElementById("queryResults").style.paddingTop = "0%";
    let interval = setInterval(() => {
      if (this.mainContainer && this.mainHeader && this.previewHeading) {
        this.previewHeadingHeight =
          this.mainContainer.nativeElement.offsetHeight -
          this.previewHeading.nativeElement.offsetHeight;
        clearInterval(interval);
      }
    });
  }

  formatData(attribute, data) {
    if (attribute === "$customer_created_date") {
      var datePipe = new DatePipe("en-US");
      return datePipe.transform(data, "yyyy-MM-dd");
    } else {
      return data;
    }
  }

  closeQueryResults() {
    document.getElementById("queryResults").style.display = "none";
  }

  queryResultsDisplayToggle() {
    this.isExpanded = !this.isExpanded;
    if (!this.isExpanded) {
      document.getElementById("queryResults").style.width = "0%";
      document.getElementById("queryResults").style.padding = "0";
      document.getElementById("segmentCreationRef").style.width = "100%";
    }
    if (this.isExpanded) {
      document.getElementById("queryResults").style.width = "25%";
      document.getElementById("segmentCreationRef").style.width = "75%";
      // document.getElementById("queryResults").style.marginTop = "0px";
    }
  }

  getQueryDetails() {
    if (this.queryValidate() === true) {
      return;
    } else {
      this.trimData();
    }
    let finalArray = [];
    let innerArray = [];
    let obj = this.segment.getRawValue();
    obj.segmentGroupArray.forEach((element, index) => {
      innerArray = [];
      element.segmentConditionArray.forEach((element, index) => {
        if (index === 0) {
          innerArray.push({
            operand1: element.attribute,
            operator: element.condition,
            operand2: element.value,
          });
        } else {
          innerArray.push({
            operand1: element.attribute,
            operator: element.condition,
            operand2: element.value,
            logicalOperator: element.relation,
          });
        }
      });
      if (index === 0) {
        finalArray.push({
          comparisonStatements: innerArray,
        });
      } else {
        finalArray.push({
          comparisonStatements: innerArray,
          logicalOperator: element.relation,
        });
      }
    });
    let array = [];
    array.push({
      logicalExpressions: finalArray,
    });
    array = array[0];
    let data = {
      segmentId: null,
      logicalExpressions: finalArray,
    };

    this.queryloading = true;

  this.segmentData = data;

  return data;
  }

  addValidatorOnForm(groupIndex, ConditionIndex, resetData) {
    resetData
      ? (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).reset()
      : null;
    (<FormControl>(
      (<FormArray>(
        (<FormArray>this.segment.get("segmentGroupArray")).controls[
          groupIndex
        ].get("segmentConditionArray")
      )).controls[ConditionIndex].get("value")
    )).clearValidators();
    switch (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        ConditionIndex
      ]["controls"]["attribute"]["value"]
    ) {
      case "$first_name":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.namePattern),
        ]);
        break;
      case "$last_name":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.namePattern),
        ]);
        break;
      case "$mobile_number":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([Validators.required, Validators.maxLength(15)]);
        break;

      case "$email":
        let value = this.segment
          .get("segmentGroupArray")
          ["controls"][groupIndex].get("segmentConditionArray")["controls"][
          ConditionIndex
        ]["controls"]["value"]["value"];
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).reset();
        if (
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            ConditionIndex
          ]["controls"]["condition"]["value"] === "equals" ||
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            ConditionIndex
          ]["controls"]["condition"]["value"] === "not equal to"
        )
          (<FormControl>(
            (<FormArray>(
              (<FormArray>this.segment.get("segmentGroupArray")).controls[
                groupIndex
              ].get("segmentConditionArray")
            )).controls[ConditionIndex].get("value")
          )).setValidators([
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(this.emailPattern),
          ]);
        else
          (<FormControl>(
            (<FormArray>(
              (<FormArray>this.segment.get("segmentGroupArray")).controls[
                groupIndex
              ].get("segmentConditionArray")
            )).controls[ConditionIndex].get("value")
          )).setValidators([Validators.required, Validators.maxLength(50)]);
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValue(value);
        break;

      case "$customer_created_date":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$life_time_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$avg_spend_per_purchase":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$num_purchases_last_month":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_last_week":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_last_6_months":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_this_year":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_last_year":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$num_purchases_in_last_365_days":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$life_time_value":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$num_days_from_last_tran":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$avg_monthly_spend":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$avg_weekly_spend":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.spendPattern),
        ]);
        break;
      case "$avg_monthly_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
      case "$avg_weekly_order_count":
        (<FormControl>(
          (<FormArray>(
            (<FormArray>this.segment.get("segmentGroupArray")).controls[
              groupIndex
            ].get("segmentConditionArray")
          )).controls[ConditionIndex].get("value")
        )).setValidators([
          Validators.required,
          Validators.min(0),
          Validators.pattern(this.numberPattern),
        ]);
        break;
    }
  }

  getErrorMessage(groupIndex, ConditionIndex) {
    if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        ConditionIndex
      ]["value"].value === null
    ) {
      return "This field cannot be empty";
    } else {
      switch (
        this.segment
          .get("segmentGroupArray")
          ["controls"][groupIndex].get("segmentConditionArray")["controls"][
          ConditionIndex
        ]["controls"]["attribute"]["value"]
      ) {
        case "$mobile_number":
          return "The maximum length of field is 15 digits";
        case "$email":
          return "Please enter a valid email address";
        default:
          return "The maximum length of field is 50 characters";
      }
    }
  }

  getTypeOfData(attribute) {
    switch (attribute) {
      case "$first_name":
      case "$last_name":
      case "$mobile_number":
      case "$email":
        return "String";
      default:
        return null;
    }
  }

  keypresshandler(groupIndex, conditionIndex, event) {
    let charCode = event.key;
    if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$first_name" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$last_name"
    ) {
      if (charCode.match(this.namePattern) == null) {
        //this.openInfoTooltip(groupIndex, conditionIndex);
        return false;
      }
    } else if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$mobile_number"
    ) {
      if (charCode.match(this.mobilenumberPattern) == null) {
        //this.openInfoTooltip(groupIndex, conditionIndex);
        return false;
      }
    } else if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_spend_per_purchase" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_monthly_spend" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$avg_weekly_spend" ||
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] == "$life_time_value"
    ) {
      if (charCode.match(this.spendPattern) == null) {
        return false;
      }
    } else if (
      this.segment
        .get("segmentGroupArray")
        ["controls"][groupIndex].get("segmentConditionArray")["controls"][
        conditionIndex
      ]["controls"]["attribute"]["value"] != "$email"
    ) {
      if (charCode.match(this.numberPattern) == null) {
        //this.openInfoTooltip(groupIndex, conditionIndex);
        return false;
      }
    }
  }

  valueSuffix(groupIndex, conditionIndex) {
    let interval = setInterval((x) => {
      let element = document.getElementById(
        "pvalue-" + groupIndex + "-" + conditionIndex
      );
      if (element) {
        clearInterval(interval);
        switch (
          this.segment
            .get("segmentGroupArray")
            ["controls"][groupIndex].get("segmentConditionArray")["controls"][
            conditionIndex
          ]["controls"]["attribute"]["value"]
        ) {
          case "$customer_created_date":
          case "$num_days_from_last_tran":
            if (
              this.segment
                .get("segmentGroupArray")
                ["controls"][groupIndex].get("segmentConditionArray")[
                "controls"
              ][conditionIndex]["controls"]["value"]["value"] > 1
            )
              element.innerHTML = "days";
            else element.innerHTML = "day";
            break;
          case "$avg_monthly_spend":
          case "$avg_weekly_spend":
          case "$life_time_value":
          case "$avg_spend_per_purchase":
            element.innerHTML = this.currencySymbol;
            break;
          case "$avg_monthly_order_count":
          case "$avg_weekly_order_count":
          case "$life_time_order_count":
          case "$num_purchases_in_last_365_days":
          case "$num_purchases_last_6_months":
          case "$num_purchases_this_year":
          case "$num_purchases_last_year":
          case "$num_purchases_last_month":
          case "$num_purchases_last_week":
            if (
              this.segment
                .get("segmentGroupArray")
                ["controls"][groupIndex].get("segmentConditionArray")[
                "controls"
              ][conditionIndex]["controls"]["value"]["value"] > 1
            )
              element.innerHTML = "orders";
            else element.innerHTML = "order";
            break;
          default:
            element.innerHTML = "";
        }
      }
    });
  }

  addNewCondition() {
    this.submitClicked = false;
    this.previewClicked = false;
    this.createSegmentStatus = true;
  }

  toggleSelect(event, i, j) {
    this.hideAttributeDropdown(null);
    event.stopPropagation();
    let element = document.getElementById("multi-select-dropdown" + i + j);
    let segmentSelect = document.getElementById("segment-select" + i + j);
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container" + i + j
    );
    this.eventDropdownElement = i + "+" + j;
    if (element.style.height === "145px") {
      element.style.height = "0px";
      element.style.border = "unset";
      segmentSelect.style.overflow = "hidden";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      element.style.height = "145px";
      element.style.border = "1px solid #636466";
      segmentSelect.style.overflow = "auto";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }

  toggleSelectCondition(event, i, j) {
    this.hideEventDropdown(null);
    event.stopPropagation();
    let element = document.getElementById(
      "multi-select-dropdown-condition" + i + j
    );
    let segmentSelect = document.getElementById(
      "segment-select-condition" + i + j
    );
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel-condition" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-condition" + i + j
    );
    this.attributeDropdownElement = i + "+" + j;
    if (element.style.height === "150px") {
      element.style.height = "0px";
      element.style.border = "unset";
      segmentSelect.style.overflow = "hidden";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      element.style.height = "150px";
      element.style.border = "1px solid #636466";
      segmentSelect.style.overflow = "auto";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }

  selectAttributeFromDropdown(i, j, key, attribute) {
    this.submitClicked = false;
    this.previewClicked = false;
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"][
      "attribute"
    ]["value"] = attribute["name"];
    let element = document.getElementById("multi-select-dropdown" + i + j);
    let segmentSelect = document.getElementById("segment-select" + i + j);
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container" + i + j
    );
    element.style.height = "0px";
    element.style.border = "unset";
    segmentSelect.style.overflow = "hidden";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    this.refreshConditionArray(i, j, key);
    this.valueSuffix(i, j);
  }

  selectRelationFromDropdown(i, j, relation) {
    this.submitClicked = false;
    this.previewClicked = false;
    this.segment
      .get("segmentGroupArray")
      ["controls"][i].get("segmentConditionArray")["controls"][j]["controls"][
      "condition"
    ]["value"] = relation["operator"];
    let element = document.getElementById(
      "multi-select-dropdown-condition" + i + j
    );
    let segmentSelect = document.getElementById(
      "segment-select-condition" + i + j
    );
    let segmentSelectPanel = document.getElementById(
      "multi-select-panel-condition" + i + j
    );
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-condition" + i + j
    );
    element.style.height = "0px";
    element.style.border = "unset";
    segmentSelect.style.overflow = "hidden";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    //this.refreshConditionArray(i, j);
    this.addValidatorOnForm(i, j, false);
    this.valueSuffix(i, j);
  }

  getAttributeDisplayName(i, j) {
    for (let key in this.segmentVariables) {
      if (
        key ===
        this.segment
          .get("segmentGroupArray")
          ["controls"][i].get("segmentConditionArray")["controls"][j][
          "controls"
        ]["attributeKey"]["value"]
      ) {
        return this.segmentVariables[key][
          this.segmentVariables[key].findIndex(
            (x) =>
              x.name ===
              this.segment
                .get("segmentGroupArray")
                ["controls"][i].get("segmentConditionArray")["controls"][j][
                "controls"
              ]["attribute"]["value"]
          )
        ].displayName;
      }
    }
  }

  getKeyForParam(attribute) {
    for (let key in this.segmentVariables) {
      let index = this.segmentVariables[key].findIndex(
        (x) => x.name === attribute
      );
      if (index > -1) {
        return key;
      }
    }
  }
}
