import { animate, style, transition, trigger } from "@angular/animations";
import { getCurrencySymbol } from "@angular/common";
import {
  Component,
  ErrorHandler,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatChipList } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ContactUsDialogComponent, OfferChannelAddConfirmationDialogComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-offer-creation",
  templateUrl: "./offer-creation.component.html",
  styleUrls: ["./offer-creation.component.css"],
  animations: [
    trigger("openClose", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
    trigger("appear", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class OfferCreationComponent implements OnInit, OnDestroy {
  @ViewChild("chipList") chipList: MatChipList;
  @ViewChild("hurdleProductInnerTypeselect") hurdleProductInnerTypeselect: any;
  @ViewChild("hurdleproductselect") hurdleproductselect: any;
  @ViewChild("hurdleProductCategoryselect") hurdleProductCategoryselect: any;
  @ViewChild("hurdleProductVariantselect") hurdleProductVariantselect: any;
  @ViewChild("productPropensityselect") productPropensityselect: any;
  @ViewChild("buyXGetYTypeselect") buyXGetYTypeselect: any;
  @ViewChild("discountAndShippingTypeselect")
  discountAndShippingTypeselect: any;
  @ViewChild("freeShippingTypeselect") freeShippingTypeselect: any;
  @ViewChild("hurdleRewardProductselect1") hurdleRewardProductselect1: any;
  @ViewChild("rewardProductVariantselect1") rewardProductVariantselect1: any;
  @ViewChild("hurdleRewardProductCategoryselect")
  hurdleRewardProductCategoryselect: any;
  @ViewChild("rewardPropensityselect") rewardPropensityselect: any;
  @ViewChild("hurdleRewardProductselect3") hurdleRewardProductselect3: any;
  @ViewChild("rewardProductVariantselect2") rewardProductVariantselect2: any;
  @ViewChild("hurdleRewardProductCategoryselect2")hurdleRewardProductCategoryselect2:any;
  saveAndScheduleButtonLoading:boolean=false;
saveAndExitButtonLoading:boolean=false;
  fieldsetDisabled = "false";
  previewmode = false;
  // hurdleRewardProductCategoryselect2: any;
  scrHeight;
  scrWidth;
  openScheduleOverlay = false;
  offerReqBodyObj;
  masterOffer;
  offerJson;
  offerMasterUpdate;
  importedData;

  offerTemplateName = "";

  offerReward;
  offerType = "br";
  readyToBuild;
  recAction;
  revCurrency;
  currencySymbol;
  validWidgets;
  apiProductList = [];
  productType = [];
  barrierSectionExpanded: boolean = false;
  barrierSectionExpandedLength = "50px";
  rewardSectionExpanded: boolean = false;
  rewardSectionExpandedLength = "50px";
  productCategoryPropensity = null;
  productCategoryType = null;
  fullproductListLength = null;
  fullproductCategoryListLength = null;
  buyXGetYType = null;
  hurdleRewardType = "BUY_X_GET_Y";
  discountAndShippingType = null;
  freeShippingType = "ORDER";
  freeShippingMinimumAmount = null;
  freeShippingMinimumOrderCount = null;
  weekDaysChips: any[] = [
    { name: "SUN", selected: false, full: "SUNDAY" },
    { name: "MON", selected: false, full: "MONDAY" },
    { name: "TUE", selected: false, full: "TUESDAY" },
    { name: "WED", selected: false, full: "WEDNESDAY" },
    { name: "THU", selected: false, full: "THURSDAY" },
    { name: "FRI", selected: false, full: "FRIDAY" },
    { name: "SAT", selected: false, full: "SATURDAY" },
  ];

  barrierType = "PRODUCT_BASED";
  barrierParamType = "NON_PERSONALIZED";
  rewardPersonalizationType = "NON_PERSONALIZED";
  rewardPropensity = null;
  hurdleProductInnerType = null;
  hurdleProduct = null;
  temphurdleSegmentFilter = "";
  hurdleProductCategory = null;
  hurdleProductVariant = null;
  temphurdleVariantSegmentFilter = "";
  productPropensity = null;
  hurdleProductCount = null;
  hurdleTimeToComplete = null;
  hurdleRewardProductCount = null;
  hurdleAtAnyTime: boolean = true;
  hurdleEverydayOfTheWeek: boolean = true;
  arrayDynamic = [];
  hourFrom = "00:00";
  hourTo = "23:59";
  hurdleSpentInnerType = "AMOUNT";
  hurdleSpentAmount = null;
  hurdleRewardToggle = true;
  hurdleRewardProduct = null;
  temprewardSegmentFilter = "";
  rewardProductVariant = null;
  temprewardVariantSegmentFilter = "";
  hurdleRewardProductCategory = null;
  percentageOff = null;
  amountOff = null;
  rewardTimeToComplete = null;
  rewardTemplate = null;
  rewardSavedId = null;
  rewardSMSSavedId=null;
  smsTemplateId=null;
  rewardsmsName=null;
  smsTemplateName=null;
  channel=null;
  offerMaster;
  merchantTrackingAction = null;
  merchantTrackingType = null;

  hurdleCompletionDay = null;

  BarrierRewardFull = null;
  noneBarrierCount = 0;
  noneBarrierDetected = false;
  buyXGetYCount = 0;
  isBuyXGetYDetected = false;
  productCategory = null;
  offerTemplate = null;
  amountOrPercentDiscount = null;
  buyXGetYDiscount = null;
  freeShippingDiscount = null;
  //barrierReward = null;
  product = null;
  barrierListList = null;
  rewardListList = null;
  // loadingblock = null;
  oneTimeSaved: boolean = false;
  // responseLoading = null;
  oneTimeSavedId = null;
  dispatchRecAction = null;
  queryparamofferId = null;
  saveActionType = null;

  rewardHeight = 300;

  submitClicked: boolean = false;
  offerReady: boolean = false;
  showofferTemplateSkeleton: boolean = false;
  serviceCountry = sessionStorage.getItem("serviceCountry");
  constructor(
    public dialog: MatDialog,
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private _WidgetConfigService: WidgetConfigService
  ) {
    window["screen-name"] = "OFFER CREATE";
    this.fieldsetDisabled = "false";
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  ngOnDestroy() {
    if (sessionStorage.getItem("layoutDetails")) {
      sessionStorage.removeItem("layoutDetails");
    }
    // clearInterval(this.interval);
    // this.destroyed=true;
  }

  ngOnInit() {
    this.getWidgets();
    this.readyToBuild = false;
    this.route.queryParams.subscribe((params) => {
      this.recAction = params.recAction;
    });
    // this.getproductList("");
    // this.setConstants("create");

    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.queryparamofferId = this.route.snapshot.queryParams["id"]
      ? this.route.snapshot.queryParams["id"]
      : null;
    this.queryparamofferId = this.route.snapshot.paramMap.get("id")
      ? this.route.snapshot.paramMap.get("id")
      : this.queryparamofferId;
    if (this.queryparamofferId) {
      // this.getDetailedOffer();
      this.merchantTrackingAction = "modify";
      this.getOfferDetails(this.queryparamofferId, true);
    } else {
      this.merchantTrackingAction = "create";
      this.merchantTrackingType = "custom";
      this.setConstants("creation");
      // this.readyToBuild = true;
    }
    if (this.route.snapshot.paramMap.get("barrier") == "none") {
      this.barrierParamType = "NONE";
      this.rewardHeight = 260;
      this.hurdleRewardType = "PERCENTAGE_OFF";
    }
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }
  getOfferDetails(id, actionChange) {
    this.offerReady = false;
    this.apiCall.getParticularOfferDetails(id).subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.masterOffer = JSON.parse(response["body"]);
          if (this.route.snapshot.paramMap.get("clone")) {
            var k = JSON.parse(this.masterOffer["offerjson"]);
            (k.layoutId = null),
              (k.smsTemplateId = null),
               (k.smsTemplateName = null),
              (k.emailTemplateName = null),
              (k.channel = null),
              (k.emailLayoutId = null),
              (k.emailLayoutTemplateId = null),
              (k.emailLayoutName = null),
              (k.emailLayoutSubject = null),
              (k.emailTemplateSnapshotUrl = null),
              (k["barrierRewards"][0]["reward"]["emailLayoutId"] = null);
              (k["barrierRewards"][0]["reward"]["layoutId"] = null);
            this.masterOffer["offerjson"] = JSON.stringify(k);
            this.masterOffer["offerjson"];
          }
          if (actionChange) {
            if (this.masterOffer["type"] == "STANDARD") {
              this.merchantTrackingType = "standard";
            } else {
              this.merchantTrackingType = "custom";
            }
          }
          this.offerJson = JSON.parse(this.masterOffer["offerjson"])[
            "barrierRewards"
          ];
          // this.selectedbarrier=this.offerJson;
        }

        if (response["message"] === "success") {
          this.offerMasterUpdate = JSON.parse(response["body"]);
        }
        for (let i = 0; i < this.offerJson.length; i++) {
          if (
            true
            // this.masterOffer["id"] == this.route.snapshot.paramMap.get("id")
          ) {
            this.offerMasterUpdate = this.offerJson;
            this.importedData = true;
          }
        }
        this.setConstants("updation");
      },
      (err) => {
        this.showofferTemplateSkeleton = false;
        this.offerReady = true;
        this.snackBar.openSnackBar(err.error.body, 2000);
      }
    );
  }
  getproductList(char) {
    this.apiCall.getOfferManagementProductsList(char).subscribe((result) => {
      this.apiProductList = (result["body"]);
      for (let i = 0; i < this.apiProductList.length; i++) {
        this.productType.push({
          name: this.apiProductList[i].productName,
          id: this.apiProductList[i].productId,
        });
      }
    });
  }

  setConstants(operation) {
    this.productCategoryType = [];
    this.productType = [];
    this.offerReady = false;
    this.apiCall.getConstantProducts().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let result: any = response["body"];
          result = JSON.parse(result);

          this.apiCall
            .getOfferManagementProductsList("")
            .subscribe((resultList) => {
              this.apiProductList = JSON.parse(resultList["body"]);
              this.fullproductListLength = this.apiProductList.length;
              for (let i = 0; i < result["productCategories"].length; i++) {
                this.productCategoryType.push({
                  name: result["productCategories"][i].productCategoryName,
                  id: result["productCategories"][i].productCategoryId,
                });
              }
              for (let i = 0; i < this.apiProductList.length; i++) {
                this.productType.push({
                  name: this.apiProductList[i].productName,
                  id: this.apiProductList[i].productId,
                });
              }
              this.fullproductCategoryListLength =
                this.productCategoryType.length;

              if (
                this.productCategoryType.length > 0 ||
                this.apiProductList.length > 0
              ) {
                if (operation === "updation") {
                  //  this.initForm(this.productCategoryType, this.productType);
                  if (this.masterOffer["id"] == this.queryparamofferId)
                    this.offerTemplateName = this.route.snapshot.paramMap.get(
                      "clone"
                    )
                      ? this.masterOffer["offerName"] + "_copy"
                      : this.masterOffer["offerName"];
                  // this.offerTemplate.controls.offerName.setValue(
                  //   this.masterOffer["offerName"]
                  // );

                  // else{
                  //   if(tempOfferName){
                  //     this.offerTemplate.controls.offerName.setValue(tempOfferName );
                  //   }
                  //   else{
                  //     this.offerTemplate.controls.offerName.setValue("");

                  //   }
                  // }
                  this.addFilledForm(this.offerMasterUpdate);
                } else {
                  //this.initForm(this.productCategoryType, this.productType);
                }
                this.readyToBuild = true;
                this.offerReady = true;
                this.showofferTemplateSkeleton = true;
              } else {
                //this.snackBar.openSnackBar("Error:No product or category", 2000);
                const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Error",
                    body: "No product or category present in the shop",
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.router.navigate(["app/offer/choose-offer"]);
                  return;

                  // this.disabled = false;
                });
              }
            });
          // let result: any = response["body"];
          // for (let i = 0; i < result["productCategories"].length; i++) {
          //   this.productCategoryType.push({
          //     name: result["productCategories"][0].productCategoryName,
          //     id: result["productCategories"][0].productCategoryId,
          //   });
          // }
          // for (let i = 0; i < result["products"].length; i++) {
          //   this.productType.push({
          //     name: result["products"][0].productName,
          //     id: result["products"][0].productId,
          //   });
          // }
        } else {
          this.snackBar.openSnackBar("Error loading", 2000);
          this.offerReady = true;
          this.showofferTemplateSkeleton = false;
        }
      },
      (error) => {
        this.snackBar.openSnackBar(error.error.message, 2000);
        this.offerReady = true;
        this.showofferTemplateSkeleton = false;
      }
    );
  }

  barrierExpand(section?) {
    this.rewardSectionExpanded = false;
    if (section) this.barrierSectionExpanded = !this.barrierSectionExpanded;
    else this.barrierSectionExpanded = true;
  }
  rewardExpand(section?) {
    this.barrierSectionExpanded = false;
    if (section) this.rewardSectionExpanded = !this.rewardSectionExpanded;
    else this.rewardSectionExpanded = true;
  }

  barrierTypeChange(type) {
    this.barrierType = type;
    if (type == "SPENT_BASED" && this.hurdleRewardType == "BUY_X_GET_Y") {
      this.hurdleRewardType = "PERCENTAGE_OFF";
    }
  }
  rewardTypeChange(type) {
    if (
      type == "BUY_X_GET_Y" &&
      ((this.hurdleProductInnerType == "ANY_PRODUCT" &&
        this.barrierType === "PRODUCT_BASED") ||
        (this.hurdleAtAnyTime == false ||
        this.hurdleEverydayOfTheWeek == false))
    ) {
    } else {
      this.hurdleRewardType = type;
    }
  }

  openedChange(event, i, area) {
    // console.log("apiProductList", this.apiProductList);
    // if (!event) {
    //   if (area == "barrier") {
    //     this.offerTemplate.controls.offerBarrier["controls"][
    //       i
    //     ].controls.temphurdleSegmentFilter.setValue("");
    //     this.offerTemplate.controls.offerBarrier["controls"][
    //       i
    //     ].controls.temphurdleVariantSegmentFilter.setValue("");
    //   }
    //   if (area == "reward") {
    //     this.offerTemplate.controls.offerBarrier["controls"][
    //       i
    //     ].controls.temphurdleVariantSegmentFilter.setValue("");
    //     this.offerTemplate.controls.offerBarrier["controls"][
    //       i
    //     ].controls.temprewardVariantSegmentFilter.setValue("");
    //   }
    // }
  }
  mainProductChange(i, j, area) {
    // if (area == "barrier") {
    //   this.offerTemplate.controls.offerBarrier["controls"][
    //     i
    //   ].controls.hurdleProductVariant.setValue(null;
    // }
    // if (area == "reward") {
    //   this.offerTemplate.controls.offerBarrier["controls"][
    //     i
    //   ].controls.rewardProductVariant.setValue(null;
    // }
    // this.hurdleProduct
    // this.hurdleProduct
  }

  mainProductChange2(i, j) {
    // this.hurdleProduct
    // this.hurdleProduct
  }

  toggleAIassistance(event) {
    event.checked == false
      ? (this.barrierParamType = "NON_PERSONALIZED")
      : (this.barrierParamType = "PERSONALIZED");
  }
  toggleAIassistanceReward(event) {
    event.checked == false
      ? (this.rewardPersonalizationType = "NON_PERSONALIZED")
      : (this.rewardPersonalizationType = "PERSONALIZED");
    if (
      this.rewardPersonalizationType == "PERSONALIZED" &&
      (this.hurdleRewardType === "PERCENTAGE_OFF" ||
        this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
      this.discountAndShippingType === "ORDER"
    ) {
      this.discountAndShippingType = null;
    }
  }
  togglehurdleAtAnyTime(event) {
    this.hurdleAtAnyTime = event.checked;
    if (this.hurdleRewardType === "BUY_X_GET_Y" && !this.hurdleAtAnyTime) {
      this.hurdleRewardType = "PERCENTAGE_OFF";
    }
  }
  togglehurdleEverydayOfTheWeek(event) {
    this.hurdleEverydayOfTheWeek = event.checked;
    if (
      this.hurdleRewardType === "BUY_X_GET_Y" &&
      !this.hurdleEverydayOfTheWeek
    ) {
      this.hurdleRewardType = "PERCENTAGE_OFF";
    }
  }

  toggleSelection(day: any) {
    day.selected = !day.selected;
    this.arrayDynamic = [];
    for (let day of this.weekDaysChips) {
      if (day.selected) {
        this.arrayDynamic.push(day.full);
      }
    }

  }

  addFilledForm(offerMaster) {
    let barrierList = offerMaster["barrierList"];

    let barrierSet = offerMaster["barrier"];
    let rewardSet = offerMaster["reward"];
    // (this.offerTemplate.get("offerBarrier")).removeAt(0);
    // for (let i = 0; i < offerMaster.length; i++) {
    // const control = new FormGroup({

    this.barrierParamType = offerMaster[0]["barrier"]["barrierParamType"];
    if (this.barrierParamType == "NONE") {
      this.rewardHeight = 260;
    }
    this.hurdleAtAnyTime = offerMaster[0]["barrier"]
      ? offerMaster[0]["barrier"]["hurdleAtAnyTime"]
      : true;
    this.hurdleEverydayOfTheWeek = offerMaster[0]["barrier"]
      ? offerMaster[0]["barrier"]["hurdleEverydayOfTheWeek"]
      : true;

    (this.productPropensity =
      offerMaster[0]["barrier"]["barrierParamType"] === "PERSONALIZED"
        ? offerMaster[0]["barrier"]["productPropensity"]
          ? offerMaster[0]["barrier"]["productPropensity"]
          : offerMaster[0]["barrier"]["productCategoryPropensity"]
        : "LOW"),
      (this.barrierType =
        offerMaster[0]["barrier"]["barrierType"] == "NONE"
          ? "PRODUCT_BASED"
          : offerMaster[0]["barrier"]["barrierType"]),
      //  offerMaster[0]["barrier"]["barrierType"]),
      (this.temphurdleSegmentFilter = ""),
      (this.temphurdleVariantSegmentFilter = ""),
      (this.temprewardVariantSegmentFilter = ""),
      (this.temprewardSegmentFilter = ""),
      (this.hurdleProductInnerType =
        offerMaster[0]["barrier"]["barrierParamType"] === "PERSONALIZED"
          ? offerMaster[0]["barrier"]["productPropensity"]
            ? "PRODUCT"
            : "CATEGORY"
          : offerMaster[0]["barrier"]["product"]
          ? "PRODUCT"
          : offerMaster[0]["barrier"]["productCategory"]
          ? "CATEGORY"
          : offerMaster[0]["barrier"]["anyproduct"]
          ? "ANY_PRODUCT"
          : null),
      (this.hurdleProduct =
        offerMaster[0]["barrier"]["product"] != null
          ? this.getMainProductJson(
              offerMaster[0]["barrier"]["product"]["productId"]
            )
          : null),
      (this.hurdleProductVariant =
        offerMaster[0]["barrier"]["productVariant"] != null &&
        offerMaster[0]["barrier"]["product"] != null
          ? this.getvariantProductJson(
              offerMaster[0]["barrier"]["productVariant"]["variantId"],
              this.getMainProductJson(
                offerMaster[0]["barrier"]["product"]["productId"]
              )
            )
          : null),
      (this.hurdleProductCategory = offerMaster[0]["barrier"]["productCategory"]
        ? offerMaster[0]["barrier"]["productCategory"]["productCategoryName"]
        : null);
    this.hurdleProductCount =
      offerMaster[0]["barrier"]["barrierType"] === "PRODUCT_BASED" ||
      offerMaster[0]["barrier"]["barrierType"] === "NONE"
        ? offerMaster[0]["barrier"]["product"]
          ? offerMaster[0]["barrier"]["product"]["productCount"]
          : offerMaster[0]["barrier"]["productCategory"]
          ? offerMaster[0]["barrier"]["productCategory"]["productCount"]
          : 1
        : 1;
    this.hurdleSpentInnerType =
      offerMaster[0]["barrier"]["barrierParamType"] === "NON_PERSONALIZED"
        ? offerMaster[0]["barrier"]["barrierType"] === "SPENT_BASED"
          ? offerMaster[0]["barrier"]["spentType"]
          : "AMOUNT"
        : "AMOUNT";

    (this.hurdleSpentAmount =
      offerMaster[0]["barrier"]["barrierParamType"] === "NON_PERSONALIZED"
        ? offerMaster[0]["barrier"]["barrierType"] === "SPENT_BASED"
          ? offerMaster[0]["barrier"]["spentBarrier"]
          : null
        : null),
      (this.hurdleRewardToggle = offerMaster[0]["reward"] ? true : false);
    // rewardPersonalizationType: offerMaster[0]["reward"]
    //   ? offerMaster[0]["barrier"]["productPropensity"] !== null ||
    //     offerMaster[0]["barrier"]["productCategoryPropensity"] !== null
    //     ? "PERSONALIZED")
    //     : "NON_PERSONALIZED")
    //   : "NON_PERSONALIZED"),
    this.rewardPersonalizationType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["productPropensity"] !== null ||
        offerMaster[0]["reward"]["productCategoryPropensity"] !== null
        ? "PERSONALIZED"
        : "NON_PERSONALIZED"
      : "NON_PERSONALIZED";

    this.rewardPropensity = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["productPropensity"] !== null
        ? offerMaster[0]["reward"]["productPropensity"]
        : offerMaster[0]["reward"]["productCategoryPropensity"] !== null
        ? offerMaster[0]["reward"]["productCategoryPropensity"]
        : "LOW"
      : "LOW";

    this.hurdleRewardType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"]
      : "BUY_X_GET_Y";
    this.buyXGetYType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"]
        : "PRODUCT"
      : "PRODUCT";
    this.discountAndShippingType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" ||
        offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF"
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ]
        : "PRODUCT"
      : "PRODUCT";
    this.percentageOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF"
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "discountValue"
          ]
        : null
      : null;
    this.amountOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF"
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "discountValue"
          ]
        : null
      : null;
    this.hurdleRewardProduct = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
          "PRODUCT"
          ? this.getMainProductJson(
              offerMaster[0]["reward"]["buyXGetYDiscount"][
                "entitledProducts"
              ][0]["productId"]
            )
          : null
        : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
          !offerMaster[0]["reward"]["productPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "PRODUCT"
          ? this.getMainProductJson(
              offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                "entitledProducts"
              ][0]["productId"]
            )
          : null
        : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
          !offerMaster[0]["reward"]["productPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "PRODUCT"
          ? this.getMainProductJson(
              offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                "entitledProducts"
              ][0]["productId"]
            )
          : null
        : null
      : null;
    this.rewardProductVariant = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
          "PRODUCT"
          ? this.getvariantProductJson(
              offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledVariants"]
                ? offerMaster[0]["reward"]["buyXGetYDiscount"][
                    "entitledVariants"
                  ][0]["variantId"]
                : null,
              this.getMainProductJson(
                offerMaster[0]["reward"]["buyXGetYDiscount"][
                  "entitledProducts"
                ][0]["productId"]
              )
            )
          : null
        : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
          !offerMaster[0]["reward"]["productPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "PRODUCT"
          ? this.getvariantProductJson(
              offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                "entitledVariants"
              ]
                ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                    "entitledVariants"
                  ][0]["variantId"]
                : null,
              this.getMainProductJson(
                offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                  "entitledProducts"
                ][0]["productId"]
              )
            )
          : null
        : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
          !offerMaster[0]["reward"]["productPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "PRODUCT"
          ? this.getvariantProductJson(
              offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                "entitledVariants"
              ]
                ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                    "entitledVariants"
                  ][0]["variantId"]
                : null,
              this.getMainProductJson(
                offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
                  "entitledProducts"
                ][0]["productId"]
              )
            )
          : null
        : null
      : null;
    this.hurdleRewardProductCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledQuantity"]
        : 1
      : 1;

    (this.hurdleRewardProductCategory = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
          "COLLECTION"
          ? offerMaster[0]["reward"]["buyXGetYDiscount"][
              "entitledCollection"
            ][0]["collectionName"]
          : null
        : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
          !offerMaster[0]["reward"]["productCategoryPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "COLLECTION"
          ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
              "entitledCollection"
            ][0]["collectionName"]
          : null
        : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
          !offerMaster[0]["reward"]["productCategoryPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "COLLECTION"
          ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
              "entitledCollection"
            ][0]["collectionName"]
          : null
        : null
      : null),
      (this.freeShippingType = offerMaster[0]["reward"]
        ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"]
          : "ORDER"
        : "ORDER");
    this.freeShippingMinimumAmount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"] ===
          "ORDER"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
          : ""
        : ""
      : "";
    this.freeShippingMinimumOrderCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"] ===
          "MIN_ORDER_QUANTITY"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
          : ""
        : ""
      : "";
    this.hurdleTimeToComplete =
      offerMaster[0]["barrier"]["barrierDates"]["endDayCount"];
    this.rewardTimeToComplete = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["daysToRedeem"]
      : null;

    this.rewardTemplate = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] == "BUY_X_GET_Y"
        ? "BUY_X_GET_Y_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "PERCENTAGE_OFF"
        ? "PERCENTAGE_OFF_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "FIXED_AMOUNT_OFF"
        ? "FIXED_AMOUNT_OFF_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "FREE_SHIPPING"
        ? "FREE_SHIPPING_REWARD_EMAIL"
        : null
      : null;
    this.rewardSavedId = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["emailLayoutId"]
      : null;
      this.channel=offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["channel"]
      : null;
      this.rewardsmsName=offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardLayoutName"]
      : null;
this.rewardSMSSavedId=offerMaster[0]["reward"]
? offerMaster[0]["reward"]["layoutId"]
: null;
    this.hurdleCompletionDay =
      offerMaster[0]["barrier"]["barrierDates"]["daysOfTheWeek"] == null
        ? []
        : offerMaster[0]["barrier"]["barrierDates"]["daysOfTheWeek"];

    for (let i = 0; i < this.hurdleCompletionDay.length; i++) {
      for (var j = 0; j <= this.weekDaysChips.length; j++) {
        if (this.hurdleCompletionDay[i] == this.weekDaysChips[j]["full"]) {
          this.weekDaysChips[j]["selected"] = true;
          break;
        }
      }
    }

    this.hourFrom =
      offerMaster[0]["barrier"]["hurdleAtAnyTime"] == true
        ? "00:00"
        : offerMaster[0]["barrier"]["barrierDates"]["startTime"];

    this.hourTo =
      offerMaster[0]["barrier"]["hurdleAtAnyTime"] == true
        ? "23:59"
        : offerMaster[0]["barrier"]["barrierDates"]["endTime"];

    // });
    //(this.offerTemplate.get("offerBarrier")).push(control);

    this.checkDays(0);
    // }
    this.readyToBuild = true;

    if (this.route.snapshot.paramMap.get("preview") == "true") {
      this.fieldsetDisabled = "true";
      this.previewmode = true;
    }
  }
  checkDays(i) {
    // if (
    //   [
    //     "hurdleCompletionDay"
    //   ][0] === "ALL_DAYS"
    // ) {
    //   this.alldayDisable[0] = false;
    //   this.dayDisable[0] = true;
    //   return true;
    // } else if (
    //   [
    //     "hurdleCompletionDay"
    //   ].length === 0
    // ) {
    //   this.dayDisable[0] = false;
    //   this.alldayDisable[0] = false;
    // } else {
    //   this.dayDisable[0] = false;
    //   this.alldayDisable[0] = true;
    // }
  }
  getProductId(product: string, type: string) {
    if (type === "product") {
      for (let i = 0; i < this.productType.length; i++) {
        if (product === this.productType[i]["name"]) {
          return this.productType[i]["id"];
        }
      }
    }
    if (type === this.productCategory) {
      for (let i = 0; i < this.productCategoryType.length; i++) {
        if (product === this.productCategoryType[i]["name"]) {
          return this.productCategoryType[i]["id"];
        }
      }
    }
    // if (type === "emailTemplate") {
    //   for (let i = 0; i < this.rewardTemplateList.length; i++) {
    //     if (product == this.rewardTemplateList[i]["layoutName"]) {
    //       // console.log( product,this.rewardTemplateList[i]["layoutName"])
    //       return this.rewardTemplateList[i]["id"];
    //     }
    //   }
    // }
  }
  getMainProductJson(id) {
    // console.log(id, this.apiProductList.length);
    if (id == null) {
      return "";
    }
    for (var i = 0; i < this.apiProductList.length; i++) {
      if (this.apiProductList[i]["productId"] == id) {
        return this.apiProductList[i];
      }
    }
    return "";
  }
  getvariantProductJson(id, parent) {
    // console.log(parent);
    if (id == null) {
      return "";
    }
    for (var i = 0; i < parent.variants.length; i++) {
      if (parent.variants[i]["variantId"] == id) {
        return parent.variants[i];
      }
    }
    return "";
  }

  submitOfferForm(action) {
    this.submitClicked = true;

    this.noneBarrierCount = 0;
    this.buyXGetYCount = 0;

    if (this.hurdleEverydayOfTheWeek) {
      this.hurdleCompletionDay = [];
    } else {
      this.hurdleCompletionDay = [];
      for (let day of this.weekDaysChips) {
        if (day.selected) {
          this.hurdleCompletionDay.push(day.full);
        }
      }
      // this.hurdleCompletionDay = this.arrayDynamic;
    }

    if (action != "saveTemplate" && this.validationCheck()) {
      return 0;
    } else {
    }
    if (this.offerTemplateName.length == 0 && action == "saveTemplate") {
      return true;
    }


    this.productCategory = {
      productCategoryId: this.getProductId(
        this.hurdleRewardProductCategory,

        this.productCategory
      ),
      productCategoryName: this.hurdleRewardProductCategory,
      productCount: null,
    };

    let anyproduct = {
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
      anyproductSelected:
        this.hurdleProductInnerType == "ANY_PRODUCT" ? true : false,
    };

    let prerequisiteProduct = {
      gfId: this.hurdleProduct != null ? this.hurdleProduct["gfId"] : null,

      productName:
        this.hurdleProduct != null ? this.hurdleProduct["productName"] : null,
      productId:
        this.hurdleProduct != null ? this.hurdleProduct["productId"] : null,
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };
    let prerequisiteVariants = {
      gfId:
        this.hurdleProductVariant != null
          ? this.hurdleProductVariant["gfId"]
          : null,
      variantName:
        this.hurdleProductVariant != null
          ? this.hurdleProduct["productName"] !=
            this.hurdleProductVariant["variantName"]
            ? this.hurdleProduct["productName"] +
              "(" +
              this.hurdleProductVariant["variantName"] +
              ")"
            : this.hurdleProductVariant["variantName"]
          : "",
      variantId:
        this.hurdleProductVariant != null
          ? this.hurdleProductVariant["variantId"]
          : null,
      parentProductGFId:
        this.hurdleProductVariant != null ? this.hurdleProduct["gfId"] : null,
      variantCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };

    let prerequisiteCollection = {
      collectionId: this.getProductId(
        this.hurdleProductCategory,
        this.productCategory
      ),
      collectionName: this.hurdleProductCategory,
    };
    let entitledVariantsection = {
      gfId:
        this.rewardProductVariant != null
          ? this.rewardProductVariant["gfId"]
          : null,
      variantName: this.rewardProductVariant
        ? this.hurdleRewardProduct["productName"] !=
          this.rewardProductVariant["variantName"]
          ? this.hurdleRewardProduct["productName"] +
            "(" +
            this.rewardProductVariant["variantName"] +
            ")"
          : this.rewardProductVariant["variantName"]
        : null,
      variantId:
        this.rewardProductVariant != null
          ? this.rewardProductVariant["variantId"]
          : null,
      parentProductGFId: this.rewardProductVariant
        ? this.hurdleRewardProduct["gfId"]
        : null,
      variantCount:
        this.hurdleRewardProductCount != null
          ? this.hurdleRewardProductCount
          : 1,
    };

    let entitledProduct = {
      gfId:
        this.hurdleRewardProduct != null
          ? this.hurdleRewardProduct["gfId"]
          : null,
      productId:
        this.hurdleRewardProduct != null
          ? this.hurdleRewardProduct["productId"]
          : null,
      productName:
        this.hurdleRewardProduct != null
          ? this.hurdleRewardProduct["productName"]
          : null,
      variant: null,
    };

    let entitledCollection = {
      collectionId: this.getProductId(
        this.hurdleRewardProductCategory,
        this.productCategory
      ),
      collectionName: this.hurdleRewardProductCategory,
    };

    if (
      this.hurdleRewardType === "PERCENTAGE_OFF" ||
      this.hurdleRewardType === "FIXED_AMOUNT_OFF"
    ) {
      this.amountOrPercentDiscount = {
        entitledType: this.discountAndShippingType,
        entitledProducts:
          this.discountAndShippingType === "PRODUCT" &&
          this.rewardPersonalizationType === "NON_PERSONALIZED"
            ? [entitledProduct]
            : null,
        entitledVariants:
          this.discountAndShippingType === "PRODUCT" &&
          this.rewardPersonalizationType === "NON_PERSONALIZED"
            ? [entitledVariantsection]
            : null,
        entitledCollection:
          this.discountAndShippingType === "COLLECTION" &&
          this.rewardPersonalizationType === "NON_PERSONALIZED"
            ? [entitledCollection]
            : null,
        discountValue:
          this.hurdleRewardType === "PERCENTAGE_OFF"
            ? this.percentageOff
            : this.hurdleRewardType === "FIXED_AMOUNT_OFF"
            ? this.amountOff
            : null,
      };
    }

    if (this.hurdleRewardType === "BUY_X_GET_Y") {
      this.buyXGetYDiscount = {
        prerequisiteType:
          this.hurdleProductInnerType === "PRODUCT" ? "PRODUCT" : "COLLECTION",
        entitledType: this.buyXGetYType,
        prerequisiteProducts: null,
        // [
        //   "hurdleProductInnerType"
        // ] === "PRODUCT"
        //   ? [prerequisiteProduct]
        //   : null,
        prerequisiteVariants:
          this.hurdleProductInnerType === "PRODUCT"
            ? [prerequisiteVariants]
            : null,
        prerequisiteCollection:
          this.hurdleProductInnerType === "CATEGORY"
            ? [prerequisiteCollection]
            : null,
        entitledProducts:
          this.buyXGetYType === "PRODUCT" ? [entitledProduct] : null,
        entitledVariants:
          this.buyXGetYType === "PRODUCT" ? [entitledVariantsection] : null,
        entitledCollection:
          this.buyXGetYType === "COLLECTION" ? [entitledCollection] : null,
        prerequisiteQuantity: this.hurdleProductCount,
        entitledQuantity: this.hurdleRewardProductCount,
      };
    }
    if (this.hurdleRewardType === "FREE_SHIPPING") {
      this.freeShippingDiscount = {
        entitledType: this.freeShippingType,
        minOrderAmount:
          this.freeShippingType === "MIN_ORDER_AMOUNT"
            ? this.freeShippingMinimumAmount
            : null,
        minOrderQuantity:
          this.freeShippingType === "MIN_ORDER_QUANTITY"
            ? this.freeShippingMinimumOrderCount
            : null,
      };
    }

    // this.barrierReward = {
    //   amountOrPercentageOffDiscount:
    //     this.hurdleRewardType === "PERCENTAGE_OFF" ||
    //     this.hurdleRewardType === "FIXED_AMOUNT_OFF"
    //       ? this.amountOrPercentDiscount
    //       : null,
    //   productPropensity:
    //     (this.hurdleRewardType === "PERCENTAGE_OFF" ||
    //       this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
    //     this.discountAndShippingType === "PRODUCT" &&
    //     this.rewardPersonalizationType === "PERSONALIZED"
    //       ? this.rewardPropensity
    //       : null,
    //   productCategoryPropensity:
    //     (this.hurdleRewardType === "PERCENTAGE_OFF" ||
    //       this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
    //     this.discountAndShippingType === "COLLECTION" &&
    //     this.rewardPersonalizationType === "PERSONALIZED"
    //       ? this.rewardPropensity
    //       : null,
    //   buyXGetYDiscount:
    //     this.hurdleRewardType === "BUY_X_GET_Y" ? this.buyXGetYDiscount : null,
    //   freeShippingDiscount:
    //     this.hurdleRewardType === "FREE_SHIPPING"
    //       ? this.freeShippingDiscount
    //       : null,
    //   // title: ["rewardCoupon"],
    //   index: 0,
    //   daysToRedeem: this.hurdleRewardType === "BUY_X_GET_Y"?this.hurdleTimeToComplete:this.rewardTimeToComplete,
    //   serviceProvider: "shopify",
    //   rewardType: this.hurdleRewardType,
    // };

    var vari = {
      gfId:
        this.hurdleProductVariant != null
          ? this.hurdleProductVariant["gfId"]
          : null,
      variantName:
        this.hurdleProductVariant != null
          ? this.hurdleProduct["productName"] !=
            this.hurdleProductVariant["variantName"]
            ? this.hurdleProduct["productName"] +
              "(" +
              this.hurdleProductVariant["variantName"] +
              ")"
            : this.hurdleProductVariant["variantName"]
          : "",
      variantId:
        this.hurdleProductVariant != null
          ? this.hurdleProductVariant["variantId"]
          : null,
      parentProductGFId:
        this.hurdleProductVariant != null ? this.hurdleProduct["gfId"] : null,
      variantCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };
    this.product = {
      productName:
        this.hurdleProduct != null ? this.hurdleProduct["productName"] : null,
      productId:
        this.hurdleProduct != null ? this.hurdleProduct["productId"] : null,
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };
    this.productCategory = {
      productCategoryName: this.hurdleProductCategory,
      productCategoryId: this.getProductId(
        this.hurdleProductCategory,
        this.productCategory
      ),
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };

    // this.barrierOperators.push(
    //   [
    //     "hurdleRelation"
    //   ]
    // );

    // this.offerBarrier = {
    //   barrierName: "Barrier " + (0 + 1),

    //   barrierParamType: this.barrierParamType,

    //   barrierType: this.hurdleRewardToggle
    //     ? this.hurdleRewardType === "BUY_X_GET_Y"
    //       ? "NONE"
    //       : this.barrierType
    //     : this.barrierType,

    //     anyproduct:this.barrierType === "PRODUCT_BASED" &&
    //     this.hurdleProductInnerType === "ANY_PRODUCT"?anyproduct:null,

    //   product:
    //     this.barrierType === "PRODUCT_BASED" &&
    //     this.hurdleProductInnerType === "PRODUCT"
    //       ? this.product
    //       : null,
    //   productVariant:
    //     this.barrierType === "PRODUCT_BASED" &&
    //     this.hurdleProductInnerType === "PRODUCT"
    //       ? vari
    //       : null,
    //   productCategory:
    //     this.barrierType === "PRODUCT_BASED" &&
    //     this.hurdleProductInnerType === "CATEGORY"
    //       ? this.productCategory
    //       : null,

    //   barrierDates: {
    //     startDayCount: 0,
    //     endDayCount: this.hurdleTimeToComplete,
    //     daysOfTheWeek: this.hurdleEverydayOfTheWeek
    //       ? null
    //       : this.hurdleCompletionDay,
    //     startTime: this.hurdleAtAnyTime ? "00:00:00" : this.hourFrom,
    //     endTime: this.hurdleAtAnyTime ? "23:59:00" : this.hourTo,
    //   },

    //   offerReward: this.hurdleRewardToggle ? this.barrierReward : null,

    //   productPropensity:
    //     this.barrierParamType === "PERSONALIZED" &&
    //     this.hurdleProductInnerType === "PRODUCT"
    //       ? this.productPropensity
    //       : null,

    //   productCategoryPropensity:
    //     this.barrierParamType === "PERSONALIZED" &&
    //     this.hurdleProductInnerType === "CATEGORY"
    //       ? this.productPropensity
    //       : null,

    //   spentBarrier:
    //     this.barrierType === "SPENT_BASED" &&
    //     this.barrierParamType === "NON_PERSONALIZED"
    //       ? this.hurdleSpentAmount
    //       : null,

    //   spentType:
    //     this.barrierType === "SPENT_BASED" ? this.hurdleSpentInnerType : null,

    //   visit: this.barrierType === "VISIT_BASED" ? true : false,
    // };

    var starttimeDD = null;
    var endTimeDD = null;

    if (this.hourFrom.split(":")[2] == "00") {
      starttimeDD = this.hourFrom;
    } else {
      starttimeDD = this.hourFrom + ":00";
    }
    if (this.hourTo.split(":")[2] == "00") {
      endTimeDD = this.hourTo;
    } else {
      endTimeDD = this.hourTo + ":00";
    }

    this.barrierListList = {
      barrierDates: {
        startDayCount: 0,
        endDayCount: this.hurdleTimeToComplete,
        daysOfTheWeek:
          this.hurdleCompletionDay[0] == "ALL_DAYS"
            ? null
            : this.hurdleCompletionDay,

        startTime: starttimeDD,
        endTime: endTimeDD,
      },
      spentBarrier:
        this.barrierType === "SPENT_BASED" &&
        this.barrierParamType === "NON_PERSONALIZED"
          ? this.hurdleSpentAmount
          : null,

      barrierType:
        this.barrierParamType == "NONE"
          ? "NONE"
          : this.hurdleRewardToggle
          ? this.hurdleRewardType === "BUY_X_GET_Y"
            ? "NONE"
            : this.barrierType
          : this.barrierType,

      barrierParamType: this.barrierParamType,
      spentType:
        this.barrierType === "SPENT_BASED" ? this.hurdleSpentInnerType : null,

      anyproduct:
        this.barrierType === "PRODUCT_BASED" &&
        this.hurdleProductInnerType === "ANY_PRODUCT"
          ? anyproduct
          : null,

      product:
        this.barrierType === "PRODUCT_BASED" &&
        this.hurdleProductInnerType === "PRODUCT"
          ? this.product
          : null,
      productVariant:
        this.barrierType === "PRODUCT_BASED" &&
        this.hurdleProductInnerType === "PRODUCT"
          ? vari
          : null,
      productCategory:
        this.barrierType === "PRODUCT_BASED" &&
        this.hurdleProductInnerType === "CATEGORY"
          ? this.productCategory
          : null,

      productPropensity:
        this.barrierParamType === "PERSONALIZED" &&
        this.hurdleProductInnerType === "PRODUCT"
          ? this.productPropensity
          : null,

      productCategoryPropensity:
        this.barrierParamType === "PERSONALIZED" &&
        this.hurdleProductInnerType === "CATEGORY"
          ? this.productPropensity
          : null,

      hurdleAtAnyTime: this.hurdleAtAnyTime,
      hurdleEverydayOfTheWeek: this.hurdleEverydayOfTheWeek,
    };

    this.rewardListList = {
      daysToRedeem:this.hurdleRewardType === "BUY_X_GET_Y"?this.hurdleTimeToComplete:this.rewardTimeToComplete,
      serviceProvider: "shopify",
      index: 0,
      rewardType: this.hurdleRewardType,
      // title: [
      //   "rewardCoupon"
      // ],
      amountOrPercentageOffDiscount:
        this.hurdleRewardType === "PERCENTAGE_OFF" ||
        this.hurdleRewardType === "FIXED_AMOUNT_OFF"
          ? this.amountOrPercentDiscount
          : null,
      buyXGetYDiscount:
        this.hurdleRewardType === "BUY_X_GET_Y" ? this.buyXGetYDiscount : null,
      freeShippingDiscount:
        this.hurdleRewardType === "FREE_SHIPPING"
          ? this.freeShippingDiscount
          : null,
      // rewardPersonalizationType:
      // [
      //   "rewardPersonalizationType"
      // ] === "NON_PERSONALIZED"
      // ?"NON_PERSONALIZED"
      // :"PERSONALIZED",

      productPropensity:
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "PRODUCT" &&
        this.rewardPersonalizationType === "PERSONALIZED"
          ? this.rewardPropensity
          : null,
      productCategoryPropensity:
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "COLLECTION" &&
        this.rewardPersonalizationType === "PERSONALIZED"
          ? this.rewardPropensity
          : null,

      rewardTemplate: this.rewardTemplate,
      emailLayoutId: this.rewardSavedId,
      layoutId:this.rewardSMSSavedId,
      channel: this.channel,
      rewardLayoutName:this.rewardsmsName,
    };
    //       barrierBarrier[0]=this.barrierListList[0];
    // rewardReward[0]=this.rewardListList[0];
    if (this.hurdleRewardToggle == false) {
      this.rewardListList = null;
    }
    if (this.barrierParamType == "NONE") {
      // this.barrierListList[0] = null;
    }
    this.BarrierRewardFull = {
      barrier: this.barrierListList,
      reward: this.rewardListList,
    };
    // this.selectedbarrier = this.BarrierRewardFull;

    if (this.barrierParamType === "NONE") {
      this.noneBarrierCount++;
    }
    if (this.hurdleRewardType === "BUY_X_GET_Y") {
      this.buyXGetYCount++;
    }
    if (1) {
      if (this.noneBarrierCount == 1) {
        this.noneBarrierDetected = true;
      } else {
        this.noneBarrierDetected = false;
        // this.noneBarrierCount=0;
      }

      if (this.buyXGetYCount == 1) {
        this.isBuyXGetYDetected = true;
      } else {
        this.isBuyXGetYDetected = false;
        // this.buyXGetYCount=0;
      }

      if (this.noneBarrierCount + this.buyXGetYCount == 0 + 1) {
        if (this.noneBarrierCount != 0) {
          this.noneBarrierDetected = true;
        }
        if (this.buyXGetYCount != 0) {
          this.isBuyXGetYDetected = true;
        }

        // this.noneBarrierCount=0;
        // this.isBuyXGetYDetected=true;
        // this.buyXGetYCount=0;
      }
    }

    this.addRule(action);
  }

  addRule(action) {
    //  this.addData()
    if (0) {
      this.snackBar.openSnackBar("add barrier", 2000);
    } else {

      let font =
        "<link href='https://fonts.googleapis.com/css2?family=Exo+2&display=swap' rel='stylesheet'>";
      if (false) {
      } else {

        let offertestNew = {
          offerJson: JSON.stringify({
            barrierRewards: [this.BarrierRewardFull],
            isNoneBarrierDetected: this.noneBarrierDetected,
            isBuyXGetYDetected: this.isBuyXGetYDetected,
            smsLayoutId: null,
            smsTemplateId: null,
            smsTemplateName: null,
            emailTemplateName: null,
            channel: null,
            emailLayoutId: null,
            emailLayoutTemplateId: null,
            emailLayoutName: null,
            emailLayoutSubject: null,
            emailTemplateSnapshotUrl: null,
          }),
          channel: null,
          offerName: this.offerTemplateName,
          offerId: this.offerMasterUpdate ? this.queryparamofferId : null,
          // offerHtml: this.finalHtml,
        };
        this.offerReqBodyObj = offertestNew;
        // if ("DISPATCH" == action) {
        //   this.loadingblock = 2;
        // } else {
        //   this.loadingblock = 1;
        // }
        if ("DISPATCH" == action) {
        this.saveAndScheduleButtonLoading=true;
        this.saveAndExitButtonLoading=false;
        }else{
          this.saveAndScheduleButtonLoading=false;
          this.saveAndExitButtonLoading=true;
        }

        if (
          (offertestNew.offerId == null && this.oneTimeSaved == false) ||
          (this.route.snapshot.paramMap.get("clone") &&
            this.oneTimeSaved == false) ||
          (!this.queryparamofferId && this.oneTimeSaved == false)
        ) {

          this.apiCall.createParticularOffer(offertestNew).subscribe(
            (response) => {
              this.saveAndScheduleButtonLoading=false;
        this.saveAndExitButtonLoading=false;

              if (response["message"] === "success") {
                this.oneTimeSaved = true;
                this.oneTimeSavedId = response["body"];
                this.queryparamofferId = response["body"];
                const queryParams: Params = {
                  id: response["body"],
                };

                this.snackBar.openSnackBar("Template saved", 1000);
                if (response["body"] != null) {
                  if ("DISPATCH" == action) {
                    this.openScheduleOverlay = true;
                    this.saveActionType = "DISPATCH";
                    this.modifyConfig(true);
                  } else if ("saveTemplateAndChannel" == action) {
                    this.openScheduleOverlay = true;
                    this.saveActionType = "saveTemplateAndChannel";
                    this.modifyConfig(true);
                    // this.router.navigate([
                    //   "app/offer/shopify-offer/list-offer",
                    //   { id: response["body"] },
                    // ]);
                  } else {
                    this.snackBar.openSnackBar("Template saved", 1000);
                    this.router.navigate(["/app/offer/choose-offer"]);
                  }
                }
              }
            },
            (err) => {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: "Error",
                  body: err.error.body,
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                this.saveAndScheduleButtonLoading=false;
                this.saveAndExitButtonLoading=false;
                return;

                // this.disabled = false;
              });

              //   this.snackBar.openSnackBar("error", 2000);
            }
          );
        } else {

          var idnumber;
          if (this.masterOffer) {
            idnumber = this.masterOffer["id"];
          } else {
            idnumber = this.oneTimeSavedId;
          }

          var k = this.masterOffer
            ? JSON.parse(this.masterOffer["offerjson"])
            : this.offerReqBodyObj;
          let offertestUpdate = {
            offerJson: JSON.stringify({
              barrierRewards: [this.BarrierRewardFull],
              isNoneBarrierDetected: this.noneBarrierDetected,
              isBuyXGetYDetected: this.isBuyXGetYDetected,
              layoutId: k.layoutId,
              smsTemplateId: k.smsTemplateId,
              smsTemplateName: k.smsTemplateName,
              emailTemplateName: k.emailTemplateName,
              channel: k.channel,
              emailLayoutId: k.emailLayoutId,
              emailLayoutTemplateId: k.emailLayoutTemplateId,
              emailLayoutName: k.emailLayoutName,
              emailLayoutSubject: k.emailLayoutSubject,
              emailTemplateSnapshotUrl: k.emailTemplateSnapshotUrl,
                         }),
            channel: k.channel,
            offerName: this.offerTemplateName,
            offerId: this.offerMasterUpdate ? this.queryparamofferId : null,
            // offerHtml: this.finalHtml,
          };
          this.offerReqBodyObj = offertestUpdate;
          this.apiCall
            .updateParticularOfferDetails(
              this.queryparamofferId,
              offertestUpdate
            )
            .subscribe(
              (response) => {
                this.oneTimeSavedId = response["body"];
                this.queryparamofferId = response["body"];
                this.saveAndScheduleButtonLoading=false;
        this.saveAndExitButtonLoading=false;
                if (response["message"] === "success") {
                  this.snackBar.openSnackBar("Template saved", 1000);
                  const queryParams: Params = {
                    id: response["body"],
                  };
                  if ("DISPATCH" == action) {
                    this.openScheduleOverlay = true;
                    this.saveActionType = "DISPATCH";
                    this.modifyConfig(true);
                  } else if ("saveTemplateAndChannel" == action) {
                    this.openScheduleOverlay = true;
                    this.saveActionType = "saveTemplateAndChannel";
                    this.modifyConfig(true);
                    // this.router.navigate([
                    //   "app/offer/shopify-offer/list-offer",
                    //   { id: response["body"] },
                    // ]);
                  } else {
                    this.snackBar.openSnackBar("Template saved", 1000);
                    this.router.navigate(["/app/offer/choose-offer"]);
                  }
                }
              },
              (err) => {
                const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Error",
                    body: err.error.body,
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.saveAndScheduleButtonLoading=false;
                  this.saveAndExitButtonLoading=false;
                  return;
                });
              }
            );
        }
      }
    }
  }

  validationCheck() {
    if (this.offerTemplateName.length == 0) {
      return true;
    }

    if (!this.hurdleAtAnyTime&&!this.timeRangeSetValidation(this.hourFrom, this.hourTo)) {
      this.snackBar.openSnackBar("Please check the from and to timings", 2000);
      return true;
    }
    if (
      this.barrierParamType != "NONE" &&
      this.hurdleEverydayOfTheWeek == false
    ) {
      if (this.hurdleCompletionDay.length === 0) {
        this.snackBar.openSnackBar(
          "Please check Every day of the week field ",
          2000
        );
        this.barrierExpand();
        return true;
      }
    }

    if (
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED"
    ) {
      if (this.hurdleProductInnerType == null) {
        // this.snackBar.openSnackBar(
        //   "Please check 'Customer needs to purchase' field",
        //   2000
        // );
        this.barrierExpand();
        return true;
      }
    }

    if (
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "SPENT_BASED"
    ) {
      if (
        this.hurdleSpentAmount == null ||
        this.hurdleSpentAmount < 1 ||
        this.hurdleSpentAmount == "e"
      ) {
        // this.snackBar.openSnackBar(
        //   "Please check 'Minimum amount that needs to be spent' field",
        //   2000
        // );
        this.barrierExpand();
        return true;
      }
    }

    if (
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType === "CATEGORY" &&
      this.hurdleProductCategory == null
    ) {
      this.barrierExpand();
      return true;
    }

    if (
      this.barrierParamType === "PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType === null
    ) {
      this.barrierExpand();
      return true;
    }

    if (
      this.barrierParamType === "PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType != null &&
      this.productPropensity == null
    ) {
      this.barrierExpand();
      return true;
    }

    if (
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType === "PRODUCT"
    ) {
      if (this.hurdleProduct) {
        if (this.hurdleProductVariant) {
        } else {
          //   this.snackBar.openSnackBar("Please check the Variant field", 2000);
          this.barrierExpand();
          return true;
        }
      } else {
        //  this.snackBar.openSnackBar("Please check the Product field", 2000);
        this.barrierExpand();
        return true;
      }
    }
    if (
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "SPENT_BASED"
    ) {
      if (
        this.hurdleSpentAmount == null ||
        this.hurdleSpentAmount == undefined ||
        this.hurdleSpentAmount == 0 ||
        this.hurdleSpentAmount < 1 ||
        this.hurdleSpentAmount == "e"
      ) {
        //  this.snackBar.openSnackBar("Please check the form field amount", 2000);
        this.barrierExpand();
        return true;
      }
    }
    if (
      this.barrierParamType != "NONE" &&
      this.barrierType == "PRODUCT_BASED" &&
      (this.hurdleProductCount == null ||
        this.hurdleProductCount == undefined ||
        this.hurdleProductCount < 0 ||
        this.hurdleProductCount > 10 ||
        this.hurdleProductCount == "e")
    ) {
      // this.snackBar.openSnackBar(
      //   "Please check the 'Minimum items in a purchase' field",
      //   2000
      // );
      this.barrierExpand();
      return true;
    }
    if (this.barrierParamType != "NONE") {
      if (
        this.hurdleTimeToComplete == null ||
        this.hurdleTimeToComplete == undefined ||
        this.hurdleTimeToComplete == 0 ||
        this.hurdleTimeToComplete == "e" ||
        this.hurdleTimeToComplete < 1
      ) {
        // this.snackBar.openSnackBar(
        //   "Please check the form fields days to redeem",
        //   2000
        // );
        this.barrierExpand();
        return true;
      }
    }

    if (
      this.barrierParamType == "NONE" &&
      this.rewardPersonalizationType == "PERSONALIZED" &&
      (this.hurdleRewardType === "PERCENTAGE_OFF" ||
        this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
      this.discountAndShippingType == null
    ) {
      this.rewardExpand();
      return true;
    }

    if (this.hurdleRewardToggle) {
      if (
        (this.rewardTimeToComplete == null ||
        this.rewardTimeToComplete == undefined ||
        this.rewardTimeToComplete == 0 ||
        this.rewardTimeToComplete < 1 ||
        this.rewardTimeToComplete == "e") &&
          this.hurdleRewardType != "BUY_X_GET_Y"
      ) {
        // this.snackBar.openSnackBar(
        //   "Please check the 'Reward will be valid for' field",
        //   2000
        // );
        this.rewardExpand();
        return true;
      }

      if (this.hurdleRewardType === "PERCENTAGE_OFF") {
        if (
          this.percentageOff == null ||
          this.percentageOff == undefined ||
          this.percentageOff == 0 ||
          this.percentageOff < 1 ||
          this.percentageOff == "e" ||
          this.percentageOff > 100
        ) {
          this.rewardExpand();

          return true;
        }
      }

      if (this.hurdleRewardType === "BUY_X_GET_Y") {
        if (this.buyXGetYType == null) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Type of free product' field",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
        if (
          this.buyXGetYType == "COLLECTION" &&
          this.rewardPersonalizationType == "NON_PERSONALIZED" &&
          this.hurdleRewardProductCategory == null
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Type of free product' field",
          //   2000
          // );
          this.rewardExpand();
          return true;
        }

        if (
          this.hurdleRewardProductCount == 0 ||
          this.hurdleRewardProductCount == "e" ||
          this.hurdleRewardProductCount == null ||
          this.hurdleRewardProductCount > 10
        ) {
          this.rewardExpand();
          return true;
        }
      }
      if (
        this.hurdleRewardType === "FIXED_AMOUNT_OFF" ||
        this.hurdleRewardType === "PERCENTAGE_OFF"
      ) {
        if (this.discountAndShippingType == null) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get an amount off on' field",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
        if (
          this.discountAndShippingType == "COLLECTION" &&
          this.rewardPersonalizationType == "NON_PERSONALIZED" &&
          this.hurdleRewardProductCategory == null
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get an amount off on' field",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
        if (
          this.discountAndShippingType != null &&
          this.rewardPersonalizationType == "PERSONALIZED" &&
          this.rewardPropensity == null
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get an amount off on' field",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }

        // if(this.hurdleRewardProductCategory==null){
        //   this.snackBar.openSnackBar(
        //     "Please check the Category",
        //     2000
        //   );
        //   this.rewardExpand();

        //   return true;
        // }
      }
      if (this.hurdleRewardType === "PERCENTAGE_OFF") {
        if (this.discountAndShippingType == null) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get a percentage off on' field",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
      }

      if (
        this.hurdleRewardType === "BUY_X_GET_Y" &&
        this.buyXGetYType === "PRODUCT" &&
        this.barrierParamType != "NONE"
      ) {
        if (this.hurdleRewardProduct) {
          if (this.rewardProductVariant) {
          } else {
            //  this.snackBar.openSnackBar("Please check the Variant field", 2000);
            this.rewardExpand();

            return true;
          }
        } else {
          //this.snackBar.openSnackBar("Please check the Product field", 2000);
          this.rewardExpand();

          return true;
        }
      }
      // if (
      //   [
      //     "hurdleRewardType"
      //   ] === "PERCENTAGE_OFF" &&
      //   [
      //     "discountAndShippingType"
      //   ] === "PRODUCT"
      // ) {
      //   if (
      //     [
      //       "hurdleRewardProduct"
      //     ]
      //   ) {
      //     if (
      //       this.offerTemplate.controls.offerBarrier["controls"][0][
      //         "controls"
      //       ]this.rewardProductVariant
      //     ) {
      //     } else {
      //       this.snackBar.openSnackBar(
      //         "Please check the Variant field",
      //         2000
      //       );
      //       document
      //         .getElementById("reward-" + i)
      //         .scrollIntoView({ behavior: "smooth", block: "center" });
      //       return true;
      //     }
      //   } else {
      //     this.snackBar.openSnackBar("Please check the Product field", 2000);
      //     document
      //       .getElementById("reward-" + i)
      //       .scrollIntoView({ behavior: "smooth", block: "center" });

      //     return true;
      //   }
      // }
      if (
        this.rewardPersonalizationType === "NON_PERSONALIZED" &&
        this.discountAndShippingType === "PRODUCT" &&
        this.hurdleRewardType != "FREE_SHIPPING" &&
        this.hurdleRewardType != "BUY_X_GET_Y"
      ) {
        if (this.hurdleRewardProduct) {
          if (this.rewardProductVariant) {
          } else {
            // this.snackBar.openSnackBar("Please check the Variant field", 2000);
            this.rewardExpand();

            return true;
          }
        } else {
          // this.snackBar.openSnackBar("Please check the Product field", 2000);
          this.rewardExpand();

          return true;
        }
      }

      if (
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "PRODUCT" &&
        this.barrierParamType != "NONE"
      ) {
        if (this.hurdleRewardProduct) {
          if (this.rewardProductVariant) {
          } else {
            //  this.snackBar.openSnackBar("Please check the Variant field", 2000);
            this.rewardExpand();

            return true;
          }
        } else {
          // this.snackBar.openSnackBar("Please check the Product field", 2000);
          this.rewardExpand();

          return true;
        }
      }

      if (this.hurdleRewardType === "FIXED_AMOUNT_OFF") {
        if (this.amountOff == null || this.amountOff == undefined) {
          // this.snackBar.openSnackBar(
          //   "Please check the form fields amount",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
      }
      if (
        this.hurdleRewardType === "FREE_SHIPPING" &&
        this.freeShippingType === "MIN_ORDER_AMOUNT"
      ) {
        if (
          this.freeShippingMinimumAmount == null ||
          this.freeShippingMinimumAmount == undefined
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check the form fields amount",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
      }
      if (
        this.hurdleRewardType === "FREE_SHIPPING" &&
        this.freeShippingType === "MIN_ORDER_QUANTITY"
      ) {
        if (
          this.freeShippingMinimumOrderCount == null ||
          this.freeShippingMinimumOrderCount == undefined ||
          this.freeShippingMinimumOrderCount == 0 ||
          this.freeShippingMinimumOrderCount < 1 ||
          this.freeShippingMinimumOrderCount == "e"
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check the form field order count",
          //   2000
          // );
          this.rewardExpand();

          return true;
        }
      }

      if (
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "COLLLECTION" &&
        this.barrierParamType != "NONE"
      ) {
        if (
          this.rewardPersonalizationType === "NON_PERSONALIZED" &&
          this.hurdleRewardProductCategory == null
        ) {
          this.rewardExpand();
          return true;
        }
        if (
          this.rewardPersonalizationType === "PERSONALIZED" &&
          this.rewardPropensity == null
        ) {
          this.rewardExpand();

          return true;
        }
      }
    }
    // }
    // }
  }
  timeRangeSetValidation(startT, endT) {
    var startHH, endHH, startMM, endMM;
    startHH = startT.split(":")[0];
    endHH = endT.split(":")[0];
    startMM = startT.split(":")[1];
    endMM = endT.split(":")[1];
    if (endHH > startHH) {
      return true;
    } else if (endHH == startHH) {
      startMM = startMM.split(":")[0];
      endMM = endMM.split(":")[0];

      if (startMM < endMM) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  selectopenclose(value) {
    switch (value) {
      case "hurdleProductInnerTypeselect":
        this.hurdleProductInnerTypeselect.open();
        break;
      case "hurdleproductselect":
        this.hurdleproductselect.open();
        break;

      case "hurdleProductCategoryselect":
        this.hurdleProductCategoryselect.open();
        break;

      case "hurdleProductVariantselect":
        this.hurdleProductVariantselect.open();
        break;

      case "productPropensityselect":
        this.productPropensityselect.open();
        break;
      case "buyXGetYTypeselect":
        this.buyXGetYTypeselect.open();
        break;
      case "discountAndShippingTypeselect":
        this.discountAndShippingTypeselect.open();
        break;

      case "freeShippingTypeselect":
        this.freeShippingTypeselect.open();
        break;
      case "hurdleRewardProductselect1":
        this.hurdleRewardProductselect1.open();
        break;
      case "rewardProductVariantselect1":
        this.rewardProductVariantselect1.open();
        break;

      case "hurdleRewardProductCategoryselect":
        this.hurdleRewardProductCategoryselect.open();
        break;
      case "rewardPropensityselect":
        this.rewardPropensityselect.open();
        break;
      case "hurdleRewardProductselect3":
        this.hurdleRewardProductselect3.open();
        break;
      case "rewardProductVariantselect2":
        this.rewardProductVariantselect2.open();
        break;
      case "hurdleRewardProductCategoryselect2":
        this.hurdleRewardProductCategoryselect2.open();
        break;
    }
  }
  message;
  receiveMessage($event) {
    this.message = $event;
 this.saveAndExitButtonLoading=false;
 this.saveAndScheduleButtonLoading=false;
    this.cancelAllSchedule();
    this.openScheduleOverlay = false;
  }

  modifyConfig(status) {
    // this.modifyDivOpened = status;
    // this.initSchedule();
    document.getElementById("viewconfigDiv").style.zIndex = "1";
    document.getElementById("overlay").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.display = "flex";
    this.on();
  }

  cancelAllSchedule() {
    //this.modifyDivOpened = false;
    this.off();
    document.getElementById("viewconfigDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "-1";
    document.getElementById("configDiv").style.display = "none";
    document.getElementById("overlay").style.zIndex = "-1";
    document.getElementById("overlay").style.display = "none";
  }

  on() {
    // this.getParticularOfferDetail();
    // this.getTemplates();
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  saveandExitPressed() {
    const dialogRef = this.dialog.open(
      OfferChannelAddConfirmationDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        width: "380px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "nochannel") {
        this.submitOfferForm("saveTemplate");
      } else if (result == "addchannel") {
        this.submitOfferForm("saveTemplateAndChannel");
      } else {
      }
    });
  }

  closeOfferScreen() {
    this.router.navigate(["/app/offer/choose-offer"]);
  }

  anyProductForProductBasedSelected() {
    if (this.hurdleRewardType == "BUY_X_GET_Y") {
      this.hurdleRewardType = "PERCENTAGE_OFF";
    }
    this.barrierParamType = "NON_PERSONALIZED";
  }
  anyProductForFreeProductSelected() {}
}
