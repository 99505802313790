<div style="padding: 30px; height: 100%; overflow: auto;" *ngIf="isLoyaltyProgramCreated && !checkForLoyalty">
    <div class="qb-header-wrapper">
        <app-breadcrumb></app-breadcrumb>
        <button class="primary-button qb-create-new-offer-button"
        [matTooltip]="'COMMON_PAGE.SEND_REPORT_EMAIL' | i18nTranslate" appButtonDebounce
        [disabled] = "selectedReport.length === 0"
        [throttleTime]="600" (throttledClick)="sendReportViaEmail()" data-button-name="Create New Offer">
        Send Via Email
        </button>
    </div>
    <div class="qb-main-report-header">
        <div class="qb-heading-report">
            <h4>Loyalty Details <span style="color: #E55151;">*</span></h4>
        </div>
        <div class="qb-d-flex">
            <div class="qb-filters-div-report qb-mb-25">
                <div class="qb-d-flex qb-fd-coloumn qb-gap-10">
                    <div class="qb-heading-select">
                        Type
                    </div>
                    <div class="mat-select-report-filter" style="width: 250px;">
                        <mat-select placeholder="Select" [(ngModel)]="selectedReport"
                            (selectionChange)="changeReportTypeSelection()">
                            <div *ngFor="let val of typeOfReports">
                                <mat-option [value]="val.value" selected *ngIf="(strategiesWithLoyaltyNode.length > 0 && val.value === 'JOURNEY_REPORT') || val.value !== 'JOURNEY_REPORT'">{{val.name |
                                    i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                            </div>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-d-flex qb-fd-coloumn qb-gap-10" *ngIf="selectedReport.length > 0 && (campaignSelectionEnabled.indexOf(selectedReport) !== -1) && strategiesWithLoyaltyNode.length > 0">
                    <div class="qb-heading-select">
                        Campaign <span style="color: #E55151;">*</span>
                    </div>
                    <div class="mat-select-report-filter" style="width: 250px;">
                        <mat-select placeholder="Select" multiple [(ngModel)]="selectedCampaign"
                            (selectionChange)="changeReportTypeSelection(selectedReport)">
                            <mat-option *ngFor="let val of strategiesWithLoyaltyNode" [value]="val.id" selected>{{val.name |
                                i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-d-flex qb-fd-coloumn qb-gap-10" *ngIf="selectedReport.length > 0 && (storeSelectionEnabled.indexOf(selectedReport) !== -1)">
                    <div class="qb-heading-select">
                        Stores
                    </div>
                    <div class="mat-select-report-filter">
                        <mat-select placeholder="Select" multiple [(ngModel)]="selectedStore">
                            <mat-option *ngFor="let val of typeOfStores" [value]="val" selected>{{val |
                                i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-d-flex qb-fd-coloumn qb-gap-10" *ngIf="selectedReport.length > 0">
                    <div class="qb-heading-select">
                        Tiers
                    </div>
                    <div class="mat-select-report-filter">
                        <mat-select placeholder="Select" multiple [(ngModel)]="selectedTiers">
                            <mat-option *ngFor="let val of typeOfTiers" [value]="val.tierId" selected>{{val.tierName |
                                i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-d-flex qb-fd-coloumn qb-gap-10" *ngIf="selectedReport.length > 0">
                    <div class="qb-heading-select">
                        Select Date - End Date
                    </div>
                    <div class="qb-date-range-picker-wrapper">
                        <mat-form-field appearance="fill">
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="getMaxDate()"
                                [ngStyle]="range.value.start !== null ? { display: 'none' } : null">
                                <input matStartDate formControlName="start" placeholder="Select" readonly>
                                <input matEndDate formControlName="end" readonly
                                    (dateChange)="onEndDateChange($event)">
                            </mat-date-range-input>
                            <div style="display: flex; flex-direction: row;">
                                <div>{{range.value.start | date: getDateFormat() }}</div>
                                <span style="margin: 0 4px;" *ngIf="range.value.start != null">-</span>
                                <div>{{range.value.end | date: getDateFormat() }}</div>
                            </div>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
    
                            <mat-error
                                *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'ANALYZE_PAGE.INVALID_START_DATE'
                                | i18nTranslate}}</mat-error>
                            <mat-error
                                *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'ANALYZE_PAGE.INVALID_END_DATE' |
                                i18nTranslate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="qb-mb-25" style="display: flex; align-items: center; justify-content: flex-end;" *ngIf="selectedReport.length > 0">
            <button class="primary-button qb-create-new-offer-button" (click)="searchReport()"
                data-button-name="Create New Offer">
                Show Preview
            </button>
        </div>
        <div class="qb-ilustration-add-customer" *ngIf="csvData.length === 0 && loading === false && !dataNotAvailable">
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/illustrations/Checklist-removebg-preview.png"
                alt="" style="width: 350px; height:300px; margin-top: 30px">
            <!-- <div class="qb-mb-25" style="display: flex; align-items: center;">
                <button class="primary-button qb-create-new-offer-button"
                    [matTooltip]="'COMMON_PAGE.SEND_REPORT_EMAIL' | i18nTranslate" appButtonDebounce
                    [disabled] = "selectedReport.length === 0"
                    [throttleTime]="600" (throttledClick)="sendReportViaEmail()" data-button-name="Create New Offer">
                    Send Via Email
                </button>
            </div> -->
        </div>
        <div *ngIf="loading === true"
            style="width: 100%; height: 350px; display: flex; align-items: center; justify-content: center;">
            <mat-spinner diameter="45"></mat-spinner>
        </div>
        <div *ngIf="dataNotAvailable" style="width: 100%; height: 300px;">
            <div class="qb-empty-offer-list">No Records Found</div>
        </div>
        <div class="qb-d-flex" style="overflow-y: auto;" *ngIf="csvData.length !== 0">
            <table border="1" class="qb-report-table">
                <thead>
                    <tr>
                        <th *ngFor="let header of csvData.headers">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let customer of csvData.values">
                        <td *ngFor="let header of csvData.headers">
                            <span *ngIf="header !== 'Created At'">
                                {{ customer[header] }}
                            </span>
                            <span *ngIf="header === 'Created At'">
                                {{ getDateTime(customer[header], 'date') | date: getDateFormat("MMM dd yyyy") }}<br />{{
                                getDateTime(customer[header], 'time') | customTime
                                }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div style="display: flex; justify-content: end; flex-direction: row; gap: 20px;" *ngIf="csvData.length !== 0">
        <div style=" cursor: pointer;" *ngIf="validWidgets | widgetConfig: 55">
            <span class="material-icons-outlined icons-primary" data-button-name="Download"
                [matTooltip]="'COMMON_PAGE.DOWNLOAD_REPORT' | i18nTranslate" appButtonDebounce [throttleTime]="600"
                (throttledClick)="downloadLoyaltyReport()">
                download
            </span>
        </div>
        <div style=" cursor: pointer;" *ngIf="validWidgets | widgetConfig: 54">
            <span class="material-icons-outlined icons-primary" data-button-name="Download"
                [matTooltip]="'COMMON_PAGE.SEND_REPORT_EMAIL' | i18nTranslate" appButtonDebounce [throttleTime]="600"
                (throttledClick)="sendReportViaEmail()">
                forward_to_inbox
            </span>
        </div>
    </div> -->
</div>
<div class="no-program-created" *ngIf="!isLoyaltyProgramCreated ">
    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt=""
        style="padding-top: 100px;" height="400px">
    <div style="margin-top: 15px;">
        There is no report available.
    </div>
</div>
<div *ngIf="checkForLoyalty === true"
    style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
    <mat-spinner diameter="45"></mat-spinner>
</div>