<div *ngIf="showBreadCrumbHeading">
  <span class="module-heading">{{ breadcrumbHeading | i18nDynamicTranslate : 'STATIC_PAGE'}}</span>
</div>

<div
  style="display: flex; color: rgba(34, 34, 34, 0.6)"
  *ngIf="showBreadCrumb"
>
  <div *ngFor="let breadCrumb of breadCrumbs; let i = index">
    <span
      class="module-heading"
      style="text-transform: capitalize;font-weight: 700;"
      [style.color]="
        i === breadCrumbs.length - 1 ? '#000' : 'rgba(34, 34, 34, 0.6)'
      "
      [style.cursor]="
      i === breadCrumbs.length - 1 ? 'default' : 'pointer'
    "
      *ngIf="breadCrumb.length > 1"
      (click)="i === breadCrumbs.length - 1 ? null : changeRoute(breadCrumb, i)"
      >{{ breadCrumb | i18nDynamicTranslate : 'STATIC_PAGE'}}</span>
    <span class="module-heading" style="margin: 0px 5px;font-weight: 700;" *ngIf="i < breadCrumbs.length - 1 && i > 0"
      >/</span
    >
  </div>
</div>
