import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.css']
})
export class HeatMapComponent implements OnInit {

  @ViewChild('chart') chartContainer: ElementRef;

  constructor() { }

  ngOnInit() {
    this.renderGraph();
  }

  renderGraph() {
    var margin = { top: 50, right: 0, bottom: 100, left: 30 },
      width = 960 - margin.left - margin.right,
      height = 430 - margin.top - margin.bottom,
      gridSize = Math.floor(width / 24),
      legendElementWidth = gridSize * 2,
      buckets = 9,
      colors = ["#ffffd9", "#edf8b1", "#c7e9b4", "#7fcdbb", "#41b6c4", "#1d91c0", "#225ea8", "#253494", "#081d58"], // alternatively colorbrewer.YlGnBu[9]
      days = ["Mo", "Tu", "We"],
      times = ["1a", "2a", "3a", "4a", "5a", "6a", "7a", "8a", "9a", "10a"];
    //let datasets = ["data.tsv", "data2.tsv"];

    let dataSet = [
      { 'day': 1, 'hour': 1, 'value': 1 },
      { 'day': 1, 'hour': 2, 'value': 2 },
      { 'day': 1, 'hour': 3, 'value': 11 },
      { 'day': 1, 'hour': 4, 'value': 1 },
      { 'day': 1, 'hour': 5, 'value': 3 },
      { 'day': 1, 'hour': 6, 'value': 10 },
      { 'day': 1, 'hour': 7, 'value': 1 },
      { 'day': 1, 'hour': 8, 'value': 4 },
      { 'day': 1, 'hour': 9, 'value': 5 },
      { 'day': 1, 'hour': 10, 'value': 10 },

      { 'day': 2, 'hour': 1, 'value': 10 },
      { 'day': 2, 'hour': 2, 'value': 20 },
      { 'day': 2, 'hour': 3, 'value': 9 },
      { 'day': 2, 'hour': 4, 'value': 19 },
      { 'day': 2, 'hour': 5, 'value': 29 },
      { 'day': 2, 'hour': 6, 'value': 2 },
      { 'day': 2, 'hour': 7, 'value': 1 },
      { 'day': 2, 'hour': 8, 'value': 1 },
      { 'day': 2, 'hour': 9, 'value': 20 },
      { 'day': 2, 'hour': 10, 'value': 30 },

      { 'day': 3, 'hour': 1, 'value': 10 },
      { 'day': 3, 'hour': 2, 'value': 20 },
      { 'day': 3, 'hour': 3, 'value': 1 },
      { 'day': 3, 'hour': 4, 'value': 21 },
      { 'day': 3, 'hour': 5, 'value': 14 },
      { 'day': 3, 'hour': 6, 'value': 10 },
      { 'day': 3, 'hour': 7, 'value': 10 },
      { 'day': 3, 'hour': 8, 'value': 1 },
      { 'day': 3, 'hour': 9, 'value': 10 },
      { 'day': 3, 'hour': 10, 'value': 1 }
    ]

    var svg = d3.select(this.chartContainer.nativeElement).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var dayLabels = svg.selectAll(".dayLabel")
      .data(days)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", 0)
      .attr("y", function (d, i) { return i * gridSize; })
      .style("text-anchor", "end")
      .attr("transform", "translate(-6," + gridSize / 1.5 + ")")
      .attr("class", function (d, i) { return ((i >= 0 && i <= 4) ? "dayLabel mono axis axis-workweek" : "dayLabel mono axis"); });

    var timeLabels = svg.selectAll(".timeLabel")
      .data(times)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", function (d, i) { return i * gridSize; })
      .attr("y", 0)
      .style("text-anchor", "middle")
      .attr("transform", "translate(" + gridSize / 2 + ", -6)")
      .attr("class", function (d, i) { return ((i >= 7 && i <= 16) ? "timeLabel mono axis axis-worktime" : "timeLabel mono axis"); });

    var heatmapChart = function (data) {
      var colorScale = d3.scaleQuantile()
        .domain([0, buckets - 1, d3.max(data, function (d) { return d.value; })])
        .range(colors);

      var cards = svg.selectAll(".hour")
        .data(data, function (d) { return d.day + ':' + d.hour; });

      cards.append("title");

      cards.enter().append("rect")
        .attr("x", function (d) { return (d.hour - 1) * gridSize; })
        .attr("y", function (d) { return (d.day - 1) * gridSize; })
        .attr("rx", 4)
        .attr("ry", 4)
        .attr("class", "hour bordered")
        .attr("width", gridSize)
        .attr("height", gridSize)
        .style("fill", function(d,i){
          return colorScale(d.value);
        });

      // cards.transition().duration(1000)
      //   .style("fill", function (d) { return colorScale(d.value); });

      // cards.select("title").text(function (d) { return d.value; });

      // cards.exit().remove();

      var legend = svg.selectAll(".legend")
        .data([0].concat(colorScale.quantiles()), function (d) { return d; });

      legend.enter().append("g")
        .attr("class", "legend");

      legend.append("rect")
        .attr("x", function (d, i) { return legendElementWidth * i; })
        .attr("y", height)
        .attr("width", legendElementWidth)
        .attr("height", gridSize / 2)
        .style("fill", function (d, i) { return colors[i]; });

      legend.append("text")
        .attr("class", "mono")
        .text(function (d) { return "≥ " + Math.round(d); })
        .attr("x", function (d, i) { return legendElementWidth * i; })
        .attr("y", height + gridSize);

      legend.exit().remove();
    };

    heatmapChart(dataSet);

    // var datasetpicker = d3.select("#dataset-picker").selectAll(".dataset-button")
    //   .data(dataSet);

    // datasetpicker.enter()
    //   .append("input")
    //   .attr("value", function (d) { return "Dataset " + d })
    //   .attr("type", "button")
    //   .attr("class", "dataset-button")
    //   .on("click", function (d) {
    //     heatmapChart(d);
    //   });
  }

}
