<div style="width: 100%; height: 100%;">
  <form [formGroup]="templateForm" style="width: 100%; height: 100%;">
    <div
      style="width: 100%; height: 5%; background: #505659; display: flex; align-items: center; justify-content: flex-end;">
      <span class="responseMessage" *ngIf="responseMsg">{{responseMsg}}</span>
      <mat-icon (click)="closeEditor()" style="margin-right: 1%; cursor: pointer; color: #ff5252; font-size: 28px;">
        close
      </mat-icon>
    </div>
    <div style="width: 100%; height: 10%; background: #505659; display: flex; align-items: center;">
      <div style="width:50%; margin-left: 2%; display: flex; align-items: center;" *ngIf="data.process === 'create' || data.process === 'update' || data.process === 'rewardupdate'">
        <span style="color:#FFF; font-size: 13px; margin-right: 1%;">{{'SHARED_PAGE.NAME' | i18nTranslate}}</span>
        <div style="width: 60%;" class="formDiv" [ngClass]="{'active' : templateForm['controls']['TemplateName']['errors'] ? (templateForm['controls']['TemplateName']['errors']['required'] && templateForm['controls']['TemplateName']['touched'] || submitClicked) : false}">
          <input #inpName class="formInput" type="text" formControlName="TemplateName">
        </div>
        <div style="height:100%">
          <span style="margin-left: 5px; font-size: 12px; color: #ff5f31;"
          *ngIf="templateForm['controls']['TemplateName']['errors'] ? (templateForm['controls']['TemplateName']['errors']['required'] && templateForm['controls']['TemplateName']['touched'] || submitClicked) : false">Field cannot be empty</span>
        </div>
      </div>
      <div style="width:100%; margin-left: 2%; display: flex; align-items: center;"
        *ngIf="data.process === 'AddTextBody' || data.process === 'EditTextBody'">
        <span style="color:#FFF; font-size: 13px; margin-right: 1%;">{{'SHARED_PAGE.SUBJECT' | i18nTranslate}}</span>
        <div style="width:50%" class="formDiv" [ngClass]="{'active' : templateForm['controls']['subject']['errors'] ? (templateForm['controls']['subject']['errors']['required'] && templateForm['controls']['subject']['touched'] || submitClicked) : false}">
          <input #inpSubject class="formInput" type="text" formControlName="subject">
        </div>
        <div style="height:100%;">
          <span style="margin-left: 5px; font-size: 12px; color: #ff5f31;" *ngIf="templateForm['controls']['subject']['errors'] ? (templateForm['controls']['subject']['errors']['required'] && templateForm['controls']['subject']['touched'] || submitClicked) : false">Field cannot be empty</span>
        </div>
      </div>
      <div style="width:50%; display: flex; align-items: center; justify-content: flex-end;"
        *ngIf="data['process'] === 'create'">
        <div style="height:35px; width:80%; background: #505659; display: flex; margin-right: 3%; font-size: 12px;">
          <div style="border-radius: 4px 0px 0px 4px;" (click)="selectTemplate('T1')" class="template-selector"
            [ngClass]="{'active' : selectedTemplate === 'T1'}">
            <span>1</span>
          </div>
          <div (click)="selectTemplate('T2')" class="template-selector"
            [ngClass]="{'active' : selectedTemplate === 'T2'}">
            <span>2</span>
          </div>
          <div (click)="selectTemplate('T3')" class="template-selector"
            [ngClass]="{'active' : selectedTemplate === 'T3'}">
            <span>3</span>
          </div>
          <div style="border-radius: 0px 4px 4px 0px;" (click)="selectTemplate('T4')" class="template-selector"
            [ngClass]="{'active' : selectedTemplate === 'T4'}">
            <span>4</span>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 85%;" id="bee-plugin-container"></div>
  </form>
</div>