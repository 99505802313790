import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { EmojiPickerSubComponent } from '../emoji-picker-sub/emoji-picker-sub.component';


@NgModule({
  declarations: [EmojiPickerSubComponent],
  imports: [CommonModule, NgxEmojiPickerModule],
  exports: [EmojiPickerSubComponent]
})
export class EmojiPickerLazyModule {}