<div class="qb-partnership-body" *ngIf="showInfo">
  <div class="qb-partnership-main">
    <div class="qb-partnership-info">
      <div>
        <img
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/partnership-landing.png"
        />
      </div>
      <div class="qb-info">
        <div class="qb-header">
          <div>
            <div class="qb-info-title">{{'PARTNERSHIP_PAGE.WHAT_QUBRIUX_COMARKETING' | i18nTranslate}}</div>
          </div>
          <div
            class="primary-button qb-partnership-buttons qb-next-button"
            (click)="next()"
          >
            {{'PARTNERSHIP_PAGE.NEXT' | i18nTranslate}}
          </div>
        </div>
        <div>
          <p>
            {{'PARTNERSHIP_PAGE.COMARKETING_PARAGRAPH_1' | i18nTranslate}}
          </p>
          <p>
            {{'PARTNERSHIP_PAGE.COMARKETING_PARAGRAPH_2' | i18nTranslate}}
          </p>
          <p>
            {{'PARTNERSHIP_PAGE.COMARLRTING_PARAGRAPH_3' | i18nTranslate}}
          </p>
        </div>
        <!-- <div class="qb-link-div">
          <span>You can learn more about Qubriux's Co-Marketing from our </span>
          <span class="qb-link">knowledge hub</span>.<img
            class="qb-open-link"
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/open-link.png"
          /> -->
        <!-- </div> -->
      </div>
    </div>
    <div>
      <div class="qb-how-to-title">{{'PARTNERSHIP_PAGE.HOW_TO_ADD_STORE' | i18nTranslate}}</div>
    </div>
    <div class="qb-how-to-div">
      <div class="qb-how-to-steps">
        <div class="qb-step-image">
          <img
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/step1.png"
          />
        </div>
        <div class="qb-steps">
          <div class="qb-steps-title">{{'PARTNERSHIP_PAGE.SEND_REQ' | i18nTranslate}}</div>
          <div class="qb-steps-info">
            {{'PARTNERSHIP_PAGE.AGREE_TO_T_AND_C' | i18nTranslate}}
          </div>
        </div>
      </div>
      <div class="qb-how-to-steps">
        <div class="qb-step-image">
          <img
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/step2.png"
          />
        </div>
        <div class="qb-steps">
          <div class="qb-steps-title">{{'PARTNERSHIP_PAGE.COUPON_CODE' | i18nTranslate}}</div>
          <div class="qb-steps-info">
            {{'PARTNERSHIP_PAGE.COUPON_CODE_INFO' | i18nTranslate}}
          </div>
        </div>
      </div>
      <div class="qb-how-to-steps">
        <div class="qb-step-image">
          <img
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/step3.png"
          />
        </div>
        <div class="qb-steps">
          <div class="qb-steps-title">{{'PARTNERSHIP_PAGE.GET_NEW_CUSTOMER' | i18nTranslate}}</div>
          <div class="qb-steps-info">
            {{'PARTNERSHIP_PAGE.NEW_CUSTOMER_COME_BUY' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showAgreement" class="qb-agreement-body">
  <div class="qb-arrow-div">
    <div class="material-icons-outlined">east</div>
  </div>
  <div class="qb-agreement-info">
    <div class="qb-agreement-title">{{'PARTNERSHIP_PAGE.COMARKETING_AGREEMENT' | i18nTranslate}}</div>
    <div>
      <app-partnership-agreement></app-partnership-agreement>
    </div>
    <div class="qb-agree">
      <mat-checkbox
        class="qb-agreement"
        [(ngModel)]="checkStatus"
        value="true"
        [ngClass]="submitted && checkStatus == false ? 'qb-error' : ''"
        ><strong
          >{{'PARTNERSHIP_PAGE.READ_CONDITION_TERMS' | i18nTranslate}}</strong
        ></mat-checkbox
      >
    </div>
    <div
      [ngClass]="{
        'primary-button': checkStatus,
        'primary-button-disabled': !checkStatus
      }"
      class="primary-button-disabled qb-partnership-buttons qb-continue-button"
      (click)="setPartnershipPlatformCharge()"
      *ngIf="!continueButtonLoading"
    >
      {{'PARTNERSHIP_PAGE.CONTINUE' | i18nTranslate}}
    </div>
    <div
      class="primary-button qb-partnership-buttons qb-continue-button"
      *ngIf="continueButtonLoading"
    >
      <mat-spinner diameter="15"></mat-spinner>
    </div>
  </div>
</div>

<div *ngIf="showAddPartner" class="qb-add-partner-body">
  <div class="qb-partnership-main qb-partnership-info">
    <img
      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/invite-partner.png"
    />
    <div class="qb-info">
      <p class="qb-info-title">
        {{'PARTNERSHIP_PAGE.INVITE_PARTNER_PARA_1' | i18nTranslate}}
      </p>
      <p>
        {{'PARTNERSHIP_PAGE.INVITE_PARTNER_PARA_2' | i18nTranslate}}
      </p>
      <!-- <p class="qb-link-div">
        <span>You can learn more about Qubriux's Co-Marketing from our </span>
        <span class="qb-link">knowledge hub</span>.<img
          class="qb-open-link"
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/open-link.png"
        />
      </p> -->
      <div
        class="primary-button qb-partnership-buttons qb-invite-partner-button"
        (click)="inviteStorePartner()"
      >
        {{'PARTNERSHIP_PAGE.INVITE_STORE_PARTNER' | i18nTranslate}}
      </div>
    </div>
  </div>

  <div class="qb-partnership-main qb-partnership-info">
    <img
      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/add-existing-partner.png"
    />
    <div class="qb-info">
      <p class="qb-info-title">
        {{'PARTNERSHIP_PAGE.INVITE_STORE_PARTNER_PARA_1' | i18nTranslate}}
      </p>
      <p>
        {{'PARTNERSHIP_PAGE.INVITE_STORE_PARTNER_PARA_2' | i18nTranslate}}
      </p>
      <!-- <p class="qb-link-div">
        <span>You can learn more about Qubriux's Co-Marketing from our </span>
        <span class="qb-link">knowledge hub</span>.<img
          class="qb-open-link"
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/open-link.png"
        />
      </p> -->
      <div
        class="primary-button-border qb-partnership-buttons qb-add-existing-partner-button"
        (click)="addExistingPartner()"
      >
        {{'PARTNERSHIP_PAGE.ADD_EXISTING_STORE_PARTNER' | i18nTranslate}}
      </div>
    </div>
  </div>
</div>
