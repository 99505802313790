import STATIC_PAGE from "./static.json";
import SHARED_PAGE from "../../i18n/en/shared.json";
import POS_PAGE from "../../i18n/en/pos.json";
import ANALYZE_PAGE from "../../i18n/en/analyze.json";
import ACQUIRE_PAGE from "../../i18n/en/acquire.json";
import CUSTOMER_PAGE from "../../i18n/en/customer.json";
import COMMUNICATION_PAGE from "../../i18n/en/communication.json";
import PROFILE_PAGE from "../../i18n/en/profile.json";
import SURVEY_PAGE from "../../i18n/en/survey.json";
import SEGMENT_PAGE from "../../i18n/en/segment.json";
import PARTNERSHIP_PAGE from "../../i18n/en/partnership.json";
import OFFER_PAGE from "../../i18n/en/offer.json";
import GAMIFY_PAGE from "../../i18n/en/gamify.json";
import CAMPAIGN_PAGE from "../../i18n/en/campaign.json";
import COMMON_PAGE from "../../i18n/en/common.json";
import REGISTRATION_PAGE from "../../i18n/en/registration.json";
 
const EN = {
    STATIC_PAGE : STATIC_PAGE,
    SHARED_PAGE : SHARED_PAGE,
    POS_PAGE : POS_PAGE,
    ANALYZE_PAGE : ANALYZE_PAGE,
    ACQUIRE_PAGE : ACQUIRE_PAGE,
    CUSTOMER_PAGE : CUSTOMER_PAGE,
    COMMUNICATION_PAGE : COMMUNICATION_PAGE,
    PROFILE_PAGE : PROFILE_PAGE,
    SURVEY_PAGE : SURVEY_PAGE,
    SEGMENT_PAGE : SEGMENT_PAGE,
    PARTNERSHIP_PAGE : PARTNERSHIP_PAGE,
    OFFER_PAGE : OFFER_PAGE,
    GAMIFY_PAGE : GAMIFY_PAGE,
    CAMPAIGN_PAGE : CAMPAIGN_PAGE,
    COMMON_PAGE : COMMON_PAGE,
    REGISTRATION_PAGE : REGISTRATION_PAGE
}

export default EN;