<div class="qb-w-100 qb-h-100">
  <div>
    <div class="header">
      <span style="text-align: center;">Upload Data</span>
      <mat-icon class="close-icon" (click)="closeDialog(false)">close</mat-icon>
    </div>
  </div>
  <div class="drag-drop-div">
    <div *ngIf="fileName.length === 0 && fileToUpload === null" class="container" appDnd
      (fileDropped)="onFileDropped($event, $event.target.files)" style="
            border: 1px solid black;
            border-style: dashed;
            display: flex;
            flex-direction: column;
            padding: 30px 0;
            align-items: center;
            position: relative;
          " [class.hovering]="isHovering">
      <input style="
              opacity: 0;
              position: absolute;
              z-index: 2;
              width: 100%;
              height: 172px;
              top: 0px;
              left: 0px;
              cursor: pointer;
            " type="file" #fileDropRef id="fileDropRef" multiple (click)="$event.target.value = null"
        (change)="fileBrowseHandler($event, $event.target.files)" accept=".csv" />
      <div *ngIf="fileName.length === 0" class="qb-upload-data">
        <p>Drag and drop your CSV file</p>
        <p>{{'PROFILE_PAGE.OR' | i18nTranslate}}</p>
        <div label for="fileDropRef" class="primary-button browse-files">
          {{'PROFILE_PAGE.BROWSE_FILE' | i18nTranslate}}
        </div>
        <span style="padding-top: 10px;"><strong>Note :</strong> Accepted date format is <strong>"mm-dd-yyyy"</strong> </span>
      </div>
      
      <div *ngIf="fileName.length > 0" class="qb-upload-data">
        <div>
          {{fileSize.size}}
        </div>
        <div>
          {{fileName}}
        </div>
      </div>
    </div>
    <div *ngIf="fileName.length > 0 && fileToUpload !== null" class="qb-d-flex" style="gap: 10px;">
      <div style="display: flex; flex-direction: row; gap: 5px;">
        <div style="display: flex; justify-content: center; align-items: center;">
          <span class="material-symbols-outlined">
            description
          </span>
        </div>
        <div class="qb-file-details">
          <div style="font-size: 12px">
            {{fileName | limitTo:15}}
          </div>
          <div style="font-size: 10px">
            {{fileSize.size}}
          </div>
        </div>
      </div>
      <div style="position: relative;">
        <input style="
                opacity: 0;
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                cursor: pointer;
              " type="file" #fileDropRef id="fileDropRef" multiple (click)="$event.target.value = null"
          (change)="fileBrowseHandler($event, $event.target.files)" accept=".csv" />
        <button class="secondary-button qb-change-button">
          Change File
        </button>
      </div>
    </div>
    <div *ngIf="fileName.length > 0 && fileToUpload !== null" class="input-main-container">
      <div class="input-container-1">
        <div class="qb-label">Tag Name <span style="color: #d71920;">*</span> <span class="qb-i-div"
          popoverclass="qb-my-custom-class" placement="auto" [ngbPopover]="tagNameDetails"
          triggers="mouseenter:mouseleave">i
        </span></div>
        <ng-template #tagNameDetails>
          <div class="qb-ng-template-text">
            Tag your file upload, your uploaded data will be visible in custom list as this name.
          </div>
        </ng-template>
      <div class="qb-d-flex">
        <div class="qb-input-field" [ngClass]="{error : submit === true && tagName.length === 0}">
          <input class="qb-input" type="text" placeholder="Please Enter Tag Name" [(ngModel)]="tagName" maxlength="50" #inp2 />
          <div class="remaining-text-left-2">{{inp2.value.length}}/{{inp2.getAttribute('maxlength')}}</div>
        </div>
        <!-- <div>
          <mat-icon (click)="addTag()" class="qb-icons">add</mat-icon>
        </div> -->
      </div>
      <div *ngIf="submit === true && tagName.length === 0">
        <span class="qb-error-message">Please enter tag name</span>
      </div>
      </div>
      <div class="input-container-2" *ngIf="isAdmin && storeFilterList.length > 1">
        <div class="qb-label">Store Name <span style="color: #d71920;">*</span> </div>
        <div class="qb-formDiv" [ngClass]="{error : submit === true && selectedStoreId.length === 0 }">
          <mat-select [(value)]="selectedStoreId" placeholder="Select Store">
            <mat-option *ngFor="let store of filteredStoreList" [value]="store.storeId">
              {{ store.storeName }}
            </mat-option>
          </mat-select>
        </div>
        <div *ngIf="submit === true && selectedStoreId.length === 0">
          <span class="qb-error-message">Please select a store</span>
        </div>
       
      </div>
      <!-- <div class="qb-tag-area">
        <div class="qb-selected-tag" *ngFor="let tag of tagList" (click)="removeTag(tag)">
          <div>{{tag}}</div>
          <div class="material-icons-outlined qb-add">close</div>
        </div>
      </div> -->
    </div>
    <div *ngIf="dataLoading" style="position: relative">
      <div style="
              width: 100%;
              margin-top: 10px;
              height: 280px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
        <div style="display: flex; flex-direction: column; width: 100%; align-items: center; justify-content: center;">
          <mat-spinner diameter="25"></mat-spinner>
          <h6 style="text-transform: capitalize;">Please be Patient your file is being uploaded</h6>
        </div>
      </div>
    </div>
    <div *ngIf="!dataLoading && csvHeader.length > 0">
      <div class="qb-file-processed">
        <span class="material-icons-outlined">
          task_alt
        </span>
        <span>
          File Processed
        </span>
      </div>
    </div>
    <div class="qb-table-padding-upload-data" *ngIf="!dataLoading">
      <table class="table">
        <thead>
          <tr>
            <th class="qb-w-30">{{'PROFILE_PAGE.COLOUMN_NAME' | i18nTranslate}}<span class="qb-i-div"
                popoverclass="qb-my-custom-class" placement="auto" [ngbPopover]="coloumnName"
                triggers="mouseenter:mouseleave">i
              </span></th>
            <ng-template #coloumnName>
              <div class="qb-ng-template-text">
                Column names present in the CSV file will be displayed here.
              </div>
            </ng-template>
            <th class="qb-w-30">{{'PROFILE_PAGE.TYPE' | i18nTranslate}}<span class="qb-i-div"
                popoverclass="qb-my-custom-class" placement="auto" [ngbPopover]="typeHower"
                triggers="mouseenter:mouseleave">i
              </span></th>
            <ng-template #typeHower>
              <div class="qb-ng-template-text">
                Please select and map the desired column name.
              </div>
            </ng-template>
            <th>{{'PROFILE_PAGE.SAMPLE' | i18nTranslate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of csvHeader; let i = index">
            <td>
              {{item}}
            </td>
            <td>
              <div>
                <div class="multi-select-panel" [id]="'multi-select-panel' + i" (click)="
                        toggleSelect(
                          $event,
                          'multi-select-dropdown',
                          'segment-select',
                          'multi-select-panel',
                          'keyboard-down-icon-container',
                          i
                        )
                      ">
                  <span>{{getType(i)}}</span>
                  <div class="keyboard-down-icon-container" [id]="'keyboard-down-icon-container'+ i">
                    <mat-icon class="keyboard-down-icon">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div style="position: relative">
                  <div class="multi-select-dropdown" [id]="'multi-select-dropdown' + i">
                    <div class="search">
                      <mat-icon class="group-icon">search</mat-icon>
                      <input [(ngModel)]="typeFilter" placeholder="Search type" type="text"
                        style="border: none; outline: none" />
                    </div>
                    <div class="border-line"></div>
                    <div [id]="'segment-select'+ i" class="segment-select">
                      <div>
                        <div class="tag-element" (click)="
                                addType(
                                  type,
                                  'multi-select-dropdown',
                                  'segment-select',
                                  'multi-select-panel',
                                  'keyboard-down-icon-container',
                                  i
                                );
                                onSelectionChange(item,type['value'])
                              " *ngFor="
                                let type of typeOptions | labelPipe: typeFilter:'name'
                              ">
                          <span>{{ type["name"] }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="noteMessages[item] !== ''" class="note-message" [innerHTML]="noteMessages[item]"></div>
            </td>
            <td>
              <div style="display: flex; flex-direction: column;" *ngFor="let data of csvSampleData[i]">
                <div>
                  {{data}}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="csvHeader.length == 0" class="qb-no-data-history">
        File Not Uploaded
      </div>
    </div>
    <div class="qb-upload-data-button" *ngIf="fileToUpload !== null && !dataLoading">
      <button class="primary-button qb-create-new-offer-button" [matTooltip]="getToolTipForUploadData()"
        appButtonDebounce [disabled]="csvHeader.length === 0 || numberOfRows === 0 || numberOfRows === null"
        [throttleTime]="600" (throttledClick)="uploadData()" data-button-name="Upload Data">
        {{'COMMON_PAGE.UPLOAD_DATA' | i18nTranslate}}
      </button>
    </div>
  </div>
</div>