import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { EmailEditorDialogComponent, ConfirmationDialogBoxComponent, PreviewTemplateComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

@Component({
  selector: "app-forms-overview",
  templateUrl: "./forms-overview.component.html",
  styleUrls: ["./forms-overview.component.css"],
})
export class FormsOverviewComponent implements OnInit {
  customerToSendEmailsCount = 0;
  finalScreenHeight: any = 0;
  finalScreenWidth: any = 0;
  scrHeight: any = 0;
  scrWidth: any = 0;
  subject = "";
  validWidgets;
  appSubscriptionPlan;
  templateID = null;
  searchValue = "";
  campaignsLoading;
  customerDetails;
  actionType;
  saveTemplate = false;
  selectTemplateError = false;
  surveyAlertID;
  surveyAlerts;
  actionDiv;
  layoutId;
  campaigns;
  templateName = "";
  channelEmail;
  channelSMS;
  emailTemplatesLoading = false;
  offerTemplatesLoading = false;
  templateDiv;
  templates = [];
  offerTemplates = [];
  templatesArray = [];
  selectEmailLayout = null;
  selectedTile = false;
  selectedCampaigns = [];
  loading = true;
  analyticsloading = true;
  surveys;
  recordsPerPage = 5;
  pageNumber = 1;
  sortOrderByAsc = true;
  status = ["ACTIVE", "DRAFT", "ENDED"];
  mobileView;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  preemailLayoutId = null;
  subjectError = false;
  formData = [];
  filteredData = [];
  data = {
    process: "create",
    id: this.templateID,
    tags: [],
    imageTagStatus: false,
    specialTagType: "",
    type: "Email",
  };
  template = "";
  formDataTotalCount;
  filter = {
    label: "",
  };
  loaded;
  selectedLayout;
  layoutName;
  timeout;
  page;
  size;
  sub: Subscription;
  analyticsData;
  formAnalytics;
  selectedIndex = 0;
  formanalyticsData = {};
  formCustomerEmails;
  selectedFormID;
  customerIDsToSendEmail;
  showFiltersDiv = false;
  showClearAll = false;
  templateSelected = false;
  statusArray = ["ACTIVE", "DRAFT", "ENDED", "SCHEDULED"];
  filters = {
    star: [],
    status: [],
    segments: [],
  };
  viewMoreID;
  @ViewChild("paginator") paginator: MatPaginator;
  newLayoutDetails;
  displayParameter;
  selectedTemplateData;
  emailTemplateSnapshotUrl;

  constructor(
    private router: Router,
    private apiCall: ApiCallService,
    private _MatDialog: MatDialog,
    private tokenStorage: TokenStorageService,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private popup: PopUpDialogService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (this.mobileView)
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 1
      ) {
        this.pageNumber += 1;
        this.getAllForm(
          this.recordsPerPage,
          this.pageNumber,
          this.sortOrderByAsc,
          this.status,
          "",
          1
        );
      }
  }

  ngOnInit() {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.getWidgets();
    this.getAllForm(
      this.recordsPerPage,
      this.pageNumber,
      this.sortOrderByAsc,
      this.status,
      "",
      1
    );
    this.selectedIndex = 0;
  }

  viewMore(id) {
    if (this.viewMoreID == id) {
      this.viewMoreID = -1;
    } else {
      this.viewMoreID = id;
    }
  }

  async createForm() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate(["/app/forms/list-form"]);
    }
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  performAction(surveyAlertID, actionType) {
    if (actionType == "survey") {
      this.getTemplates();
    }
    this.campaignsLoading = true;
    this.customerDetails = [];
    this.actionType = actionType;
    this.surveyAlertID = surveyAlertID;
    this.actionDiv = true;
    this.showCampaigns();
  }

  sendEmailFromAnalytics(requestData) {
    this.apiCall
      .sendEmailFromFormAnalytics(
        this.tokenStorage.getMerchantId(),
        requestData
      )
      .subscribe(
        (response) => {
          this.matSnackBar.dismiss();
          this.popup.openPopup(this._i18nDynamicTranslate.transform("success", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Email sent successfully", ['POS_PAGE']));
          this.cancelActionDiv();
        },
        (err) => {
          this.matSnackBar.dismiss();
          this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE']));
        }
      );
  }

  addEmailContent(data, layoutId) {
    this.apiCall.addEmailContent(data).subscribe(
      (response) => {
        layoutId = response["body"];
        let requestData = {
          formId: this.selectedFormID,
          actionType: "SEND_EMAIL",
          layoutId: layoutId,
          customerIds: this.customerIDsToSendEmail,
        };
        this.sendEmailFromAnalytics(requestData);
      },
      (err) => {
        this.matSnackBar.dismiss();
        this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE']));
      }
    );
  }

  getEmailLayoutDetail() {
    this.apiCall.getEmailLayoutDetail(this.templateID).subscribe((response) => {
      let emailContent = JSON.parse(response["body"]);
      let data = {
        merchantId: this.tokenStorage.getMerchantId(),
        subject: this.subject,
        layoutHtml: emailContent["layoutHtml"],
        layoutJson: emailContent["layoutJson"],
        id: this.templateID,
        type: "FORMS",
      };
      let layoutId;
      this.addEmailContent(data, layoutId);
    });
  }

  send() {
    if (
      this.templateID !== null &&
      this.subject.length > 0 &&
      this.saveTemplate
    )
      this.getEmailLayoutDetail();
    else {
      if (this.subject.length == 0) this.subjectError = true;
      else this.selectTemplateError = true;
    }
  }

  showCampaigns() {
    this.apiCall.getStrategies().subscribe((response) => {
      this.campaigns = JSON.parse(response["body"]);
      this.campaignsLoading = false;
    });
  }

  attachTemplate() {
    this.displayTemplates();
    this.emailTemplatesLoading = true;
    this.offerTemplatesLoading = true;
    this.templateDiv = true;
    this.selectTemplateError = false;
    this.templateID = null;
    this.templateName = "";
    this.template = "";
    this.templateSelected = false;
    this.saveTemplate = false;
  }

  listEmailLayout() {
    this.apiCall.listEmailLayout().subscribe((response) => {
      this.templates = response["body"];
      this.templatesArray = response["body"];
      this.emailTemplatesLoading = false;
    });
  }

  getOfferDispatchOffers() {
    this.apiCall.getOfferDispatchOffers().subscribe((response) => {
      JSON.parse(response["body"]).offers.forEach((element) => {
        if (this.channelEmail && !this.channelSMS) {
          if (element["channel"] === "EMAIL") {
            this.templates.push(element);
            this.templatesArray.push(element);
          }
        } else if (!this.channelEmail && this.channelSMS) {
          if (element["channel"] === "SMS") {
            this.templates.push(element);
            this.templatesArray.push(element);
          }
        } else if (this.channelEmail && this.channelSMS) {
          if (element["channel"] != null) this.templates.push(element);
          this.templatesArray.push(element);
        }
      });
      this.offerTemplatesLoading = false;
    });
  }

  displayTemplates() {
    this.emailTemplatesLoading = true;
    this.offerTemplatesLoading = true;
    this.templates = [];
    this.templatesArray = [];
    if (this.actionType === "email") this.listEmailLayout();
    else this.getOfferDispatchOffers();
  }

  selectEmailTile(layout) {
    this.selectEmailLayout = layout;
    this.selectedTile = true;
  }

  searchTemplates(searchValue) {
    this.templates = [];
    if (searchValue.length > 0)
      this.templatesArray.forEach((template) => {
        if (this.actionType === "email") {
          if (
            template["layoutName"]
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
            this.templates.push(template);
        } else if (this.actionType === "offer") {
          if (
            template["offerName"]
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
            this.templates.push(template);
        }
      });
    else this.templates = this.templatesArray;
  }

  cancelTemplateDiv() {
    this.selectEmailLayout = null;
    this.selectedTile = false;
    this.templateName = "";
    this.template = "";
    this.searchValue = "";
    this.selectTemplateError = false;
    this.templateDiv = false;
    this.templateID = -1;
  }

  cancelActionDiv() {
    this.templateName = "";
    this.template = "";
    this.searchValue = "";
    this.selectTemplateError = false;
    this.actionDiv = false;
    this.templateDiv = false;
    this.subject = "";
    this.templateID = -1;
  }

  createEmailTemplate(id: any) {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;
    self.finalScreenWidth = self.scrWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    let sw = self.finalScreenWidth * (70 / 100);
    const dialogRef = self._MatDialog.open(EmailEditorDialogComponent, {
      panelClass: "customAttachEmail",
      height: 500 + "px",
      width: self.finalScreenWidth < 1500 ? "1024px" : sw + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      minHeight: "620px",
      disableClose: true,
      data: {
        type: "CUSTOM",
        id: "null",
        clone: "false",
        return: "false",
      },
    });
    dialogRef.afterClosed().subscribe((id) => {
      if (id) {
        self.snackbar.openSnackBar("Successfull", 2000);
        self.templateID = id;
        self.apiCall.listEmailLayout().subscribe(
          (response) => {
            self.templates = response["body"];
            self.templatesArray = response["body"];
            var data: any = self.templates.find((x) => x.id == id);
            self.templates = self.templates.filter((x) => x.id !== id);
            self.templates.unshift(data);
            self.filteredData = self.templates;
            self.template = data.layoutName;
            this.searchValue = "";
          },
          (err) => {}
        );
      }
    });
  }

  confirm() {
    if (this.templateID != -1) {
      this.templateDiv = false;
      this.saveTemplate = true;
      this.templateName = this.template;
      this.template = "";
      this.selectTemplateError = false;
    } else {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please select a template", ['POS_PAGE']), 2000);
    }
  }

  selectTemplate(templateID, templateName, event) {
    this.templateSelected = !this.templateSelected;
    if (this.templateID != templateID) {
      this.templateID = templateID;
      this.template = templateName;
      this.templateSelected = true;
    } else {
      this.templateID = -1;
      this.templateName = "";
    }
  }

  getTemplates() {
    this.loading = true;
    this.apiCall.getAllSurveyTemplates().subscribe((response) => {
      if (response["message"] === "SUCCESS") {
        this.loading = false;
        this.surveys = response["body"];
      }
    });
  }

  editForm(formID) {
    this.router.navigate([
      "/app/forms/new-form",
      {
        id: formID,
        return: "/app/forms/overview",
      },
    ]);
  }

  cloneForm(formID) {
    this.router.navigate([
      "/app/forms/new-form",
      {
        id: formID,
        clone: true,
        return: "/app/forms/overview",
      },
    ]);
  }

  getAllForm(
    recordsPerPage,
    pageNumber,
    sortOrderByAsc,
    status,
    name,
    analyticsIndex
  ) {
    this.loading = true;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .getAllForms(
        recordsPerPage,
        pageNumber,
        sortOrderByAsc,
        status,
        "CUSTOM",
        name
      )
      .subscribe(
        (response) => {
          if (
            response["message"] === "SUCCESS" ||
            response["message"] === "success"
          ) {
            if (this.mobileView) {
              JSON.parse(JSON.parse(response["body"]).forms).forEach(
                (element) => {
                  this.formData.push(element);
                }
              );
              this.formDataTotalCount = this.formData.length;
              this.loading = false;
              if (analyticsIndex && this.formDataTotalCount > 0) {
                this.getFormAnalytics(this.formData[0]["id"], 0);
              }
            } else {
              this.formData = JSON.parse(JSON.parse(response["body"]).forms);
              Object.keys(this.formData).map((key) => {
                this.formData[key]["formName"] = this.formData[key]["name"];
                this.formData[key]["formHtml"] =
                  this.formData[key]["formHtmlURL"];
                delete this.formData[key]["name"];
                delete this.formData[key]["formHtmlURL"];
              });
              this.formDataTotalCount = JSON.parse(response["body"]).count;
              this.loading = false;
              if (analyticsIndex && this.formDataTotalCount > 0) {
                this.getFormAnalytics(this.formData[0]["id"], 0);
              } else {
                this.analyticsloading = false;
              }
            }
          }
        },
        (error) => {
          this.formData = [];
          this.loading = false;
        }
      );
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.executeListing();
    }, 600);
  }

  executeListing() {
    this.paginator.pageIndex = 0;
    this.page = 1;
    this.getAllForm(
      this.recordsPerPage,
      this.page,
      this.sortOrderByAsc,
      this.status,
      this.filter.label,
      1
    );
  }

  getNextData(pageEvent) {
    this.page = pageEvent.pageIndex + 1;
    this.size = pageEvent.pageSize;
    this.recordsPerPage = pageEvent.pageSize;
    this.selectedIndex = 0;
    this.getAllForm(
      this.size,
      this.page,
      this.sortOrderByAsc,
      this.status,
      this.filter.label,
      1
    );
  }

  getFormAnalytics(formId, index) {
    this.selectedFormID = formId;
    this.selectedIndex = index;
    this.analyticsloading = true;
    this.apiCall.getFormAnalytics(formId).subscribe(
      (response) => {
        if (
          response["message"] === "SUCCESS" ||
          response["message"] === "success"
        ) {
          this.analyticsloading = false;
          this.formAnalytics = JSON.parse(response["body"]);
          this.formCustomerEmails = this.formAnalytics.customerEmails;
          this.customerIDsToSendEmail =
            this.formAnalytics["customerIdsToBeSendEmails"];
          if (Object.keys(this.formAnalytics).length > 0) {
            this.formanalyticsData = JSON.parse(
              this.formAnalytics.analyticsData
            );
          } else {
            this.formanalyticsData = {
              revenue: null,
              submittedViews: 0,
              totalViews: 0,
            };
          }
        }
      },
      (error) => {
        this.analyticsloading = false;
      }
    );
  }

  deleteForm(formId) {
    let dialogRef = this._MatDialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete form?", ['POS_PAGE']),
        message:
          this._i18nDynamicTranslate.transform("Delete operation will delete this form and you will lose all the configurations", ['POS_PAGE']),
        "data-widget": "",
        "data-button": "",
        "data-item": "",
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deleteForm(formId).subscribe(
          (response) => {
            this.paginator.pageIndex = 0;
            this.getAllForm(
              this.recordsPerPage,
              1,
              this.sortOrderByAsc,
              this.status,
              "",
              1
            );
          },

          (error) => {}
        );
      }
    });
  }

  toggleFormEvent(index, form, event) {
    if (typeof form["scheduleInfo"] === "string") {
      form["scheduleInfo"] = JSON.parse(form.scheduleInfo);
      form["formData"] = JSON.parse(form.formData);
    }
    let data = form;
    data.status = event.checked ? "ACTIVE" : "ENDED";
    data.status = event.checked ? "ENDED" : "ACTIVE";
    this.toggleStatus(index);
    this.apiCall.updateForm(data, data.id).subscribe(
      (response) => {
        if (response["message"] === "ERROR") {
          this.toggleStatus(index);
        }
      },
      (err) => {}
    );
  }

  toggleStatus(index) {
    if (this.formData[index].status === "ACTIVE") {
      this.formData[index].status = "ENDED";
    } else {
      this.formData[index].status = "ACTIVE";
    }
  }

  openFilters() {
    this.showFiltersDiv = true;
  }

  closeFiltersDiv() {
    this.showFiltersDiv = false;
  }

  clear() {
    this.filters["status"] = [];
    this.showClearAll = false;
  }

  addStatusFilter(value) {
    if (this.filters["status"].indexOf(value) != -1) {
      this.filters["status"].splice(this.filters["status"].indexOf(value), 1);
      if (this.filters["status"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["status"].splice(this.filters["status"].indexOf("ALL"), 1);
      }
    } else {
      this.filters["status"].push(value);
      if (value === "ALL")
        this.statusArray.forEach((customer) => {
          if (this.filters["status"].indexOf(customer) == -1)
            this.filters["status"].push(customer);
        });
    }
    this.statusArray.forEach((customer) => {
      if (this.filters["status"].indexOf(customer) == -1)
        if (this.filters["status"].indexOf("ALL") != -1)
          this.filters["status"].splice(
            this.filters["status"].indexOf("ALL"),
            1
          );
    });
    if (value === "ALL") {
      if (this.filters["status"].indexOf("ALL") == -1) {
        this.filters["status"] = [];
      }
    }
    if (this.filters["status"].length > 0) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  applyFilter() {
    if (this.filters["status"].indexOf("ALL") == -1)
      this.status = this.filters["status"];
    else this.status = ["ACTIVE", "DRAFT", "ENDED"];
    this.filters["status"] = this.filters["status"];
    this.getAllForm(
      this.recordsPerPage,
      this.pageNumber,
      this.sortOrderByAsc,
      this.status,
      this.filter.label,
      1
    );
    this.closeFiltersDiv();
  }

  showPreview() {
    this.previewEmail();
  }

  previewEmail() {
    var id: any = this.templateID;
    if (!id) {
    } else {
      this.apiCall.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            let layout = JSON.parse(response["body"]);
            let selectedLayout = layout["layoutHtml"];
            let layoutName = layout["name"];
            var emailData: any = {};
            emailData.selectedLayout = selectedLayout;
            emailData.templateName = this.templateName;
            emailData.templateId = this.templateID;
            this.openDialog(emailData, "EMAIL");
          }
        },
        (err) => {}
      );
    }
  }

  openDialog(objectData: any, type: string): void {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;
    self.finalScreenWidth = self.scrWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    let sw = self.finalScreenWidth * (70 / 100);
    const dialogRef = self.dialog.open(PreviewTemplateComponent, {
      panelClass: "previewDialog",
      width: "70%",
      maxHeight: "90vh",
      height: sh + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      disableClose: true,
      autoFocus: false,
      data: { obj: objectData, type: type, height: self.finalScreenHeight },
    });
    dialogRef.afterClosed().subscribe((id) => {
      self.apiCall.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            let layout = JSON.parse(response["body"]);
            self.templateID = id;
            self.templateName = layout.name;
          }
        },
        (err) => {}
      );
    });
  }
  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }
}
