<div class="font">
    <div>
      <div class="header">
        <div class="qb-d-flex">
            <span style="text-align: center">{{ data["subject"] }}</span>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/verified_logo.svg" style="margin-left: 2.5px;" width="20px" height="20px">
        </div>
        <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
      </div>
    </div>
    <div>
      <div class="message">
        <div>{{ data["message"] }}</div>
        <div *ngIf="data['submessage']">{{data["submessage"]}}</div>
      </div>
    </div>
    <!-- <div *ngIf="data['to-screen']" style="
          display: flex;
          justify-content: space-between;
          padding: 0px 20px 15px 20px;
        ">
            <span class="secondary-button-border" (click)="submit(false)">Cancel</span>
            <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen'] != 'campaign'">OK</span>
            <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen']!='campaign'&& data['to-screen']!='survey-alert'">OK</span>
            <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen']=='survey-alert'">Delete</span>
            <span class="primary-button" (click)="submit(true)" *ngIf="data['to-screen'] == 'campaign'" widget-id="16" event-id="2" type="button" [attr.data-strategy-action]="data['action']" [attr.object-type]="data['strategyType']">Confirm</span>
        </div> -->
  
    <div class="button-container-partnership">
      <!-- <span
        class="secondary-button-border"
        *ngIf="!data['cancelButtonText']"
        (click)="submit(false)"
        >Cancel</span
      > -->
    
      <!-- <span
        class="primary-button"
        *ngIf="!data['successButtonText']"
        (click)="submit(true)"
        >Confirm</span
      > -->
      <span class="primary-button"
        *ngIf="data['createformButton']"
        (click)="submit('form')" [attr.data-button-name]="data['data-button']">{{
        data["createformButton"] }}</span>
      <span class="primary-button" *ngIf="data['scheduleOfferbutton']"
        (click)="submit('offer')" [attr.data-button-name]="data['data-button']">{{
        data["scheduleOfferbutton"] }}</span>

        <span class="primary-button" *ngIf="data['createCampaignButton']" (click)="submit('campaign')"
        data-button-name="Canceled Operation" >{{ data["createCampaignButton"]
        }}</span>
  
  
      <!-- <div class="primary-button qb-center" *ngIf="data['successButtonText'] &&data['successButtonText']=='Upgrade Now'"
        (click)="submitPlanAction(true)" [attr.data-button-sname]="data['data-button']"
        [attr.data-item-name]="data['data-item']">
        <span *ngIf="!planUpgradeLoading">{{ data["successButtonText"] }}</span>
        <span class="gf-select-btn-preview-loading" *ngIf="planUpgradeLoading">
          <mat-spinner diameter="13"></mat-spinner>
        </span>
      </div> -->
  
      <!-- <span class="primary-button" *ngIf="data['successButtonText']" (click)="submit(true)">Delete</span> -->
    </div>
  </div>