<div style="height: 450px; overflow-y: auto; padding: 25px; min-width: 400px">
  <div *ngIf="!loadingSample"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div style="text-transform: uppercase">{{'SHARED_PAGE.AI_GENERATED_ANALYTICS' | i18nTranslate}}</div>
    <div
      (click)="close()"
      style="
        border-radius: 50%;
        background-color: rgba(99, 100, 102, 0.1);
        width: 17px;
        height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      "
    >
      <div
        class="material-symbols-outlined"
        style="font-size: 17px; color: rgba(99, 100, 102, 0.5)"
      >
        close
      </div>
    </div>
  </div>
  <div>
    <div>
      <div *ngIf="!loadingSample"
        style="
          font-size: 12px;
          color: rgba(34, 34, 34, 0.5);
          margin-top: 25px;
          margin-bottom: 10px;
        "
      >
        {{'SHARED_PAGE.CHOOSE_ANALYTICS_TYPE' | i18nTranslate}}
      </div>
      <div *ngIf="!loadingSample"
        (click)="showDropdown = !showDropdown"
        style="
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #636466;
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;
        "
      >
        <div style="color: rgba(34, 34, 34, 0.5)">{{ selectedTags | i18nDynamicTranslate : 'SHARED_PAGE'}}</div>
        <div>
          <div
            *ngIf="!showDropdown"
            class="material-symbols-outlined"
            style="display: flex"
          >
            expand_more
          </div>
          <div
            *ngIf="showDropdown"
            class="material-symbols-outlined"
            style="display: flex"
          >
            expand_less
          </div>
        </div>
      </div>
      <div
        *ngIf="showDropdown && !loadingSample"
        style="
          border: 1px solid #636466;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 4px 4px;
          margin-top: -3px;
          position: absolute;
          width: 27.8%;
          background: white;
          padding: 10px 0;
          height: 100px;
          overflow: auto;
          height: 63px;
        "
      >
        <div
          *ngFor="let tag of allTags"
          style="
            padding: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;
          "
          (click)="selectTag(tag)"
          class="tb-tag"
        >
          <div>{{ tag | i18nDynamicTranslate : 'SHARED_PAGE'}}</div>
        </div>
      </div>
      <div style="margin-top: 15px; gap: 7px 6px" *ngIf="!loadingSample">
        <div
          style="
            font-size: 12px;
            color: rgba(34, 34, 34, 0.5);
            margin-top: 25px;
            margin-bottom: 10px;
          "
        >
          {{'SHARED_PAGE.WRITE_QUERY' | i18nTranslate}}
        </div>
        <div
          style="
            border: 1px solid #636466;
            border-radius: 5px;
            width: 100%;
            height: 100px;
            padding: 5px 10px;
          "
        >
          <textarea
            placeholder="Example: Number of customers ordered in last 30 days"
            [(ngModel)]="question"
          ></textarea>
        </div>
        <div *ngIf="questionError !== ''" style="font-size: 12px; color: red">
          {{ questionError }}
        </div>
        <div
          class="primary-button"
          style="width: 145px; padding: 10px 0px 10px 0px; margin-top: 40px"
          (click)="generate()"
        >
          {{'SHARED_PAGE.GENERATE_ANALYTICS' | i18nTranslate}}
        </div>
      </div>
      <div
        *ngIf="(loadingSample || (!loadingSample && question2 !== '')) && (loadingSample && question2 !== '')"
        style="border: 1px solid rgba(218, 215, 215, 0.31); margin-top: 10px"
      ></div>
      <div *ngIf="loadingSample">
        <img
          style="margin-left: 60px"
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/chat-gpt/chatbotgif.gif"
          width="250"
        />
        <div style="margin-top: -28px; margin-left: 93px">
          {{'SHARED_PAGE.AI_GENERATING_ANALYTICS' | i18nTranslate}}
          <div>
            <div
              *ngIf="loadingSample"
              style="
                border: 2px dashed #0183fb;
                border-radius: 14px;
                width: 160px;
                height: 17px;
                padding: 0 10px;
              "
            >
              <div
                style="
                  background: #0183fb;
                  border-radius: 14px;
                  height: 5px;
                  width: 80px;
                  margin-top: 4px;
                  padding: 0px;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="question2 !== '' && !loadingSample"
        style="margin-top: 15px; width: 100%"
      >
      <div
        *ngIf="!loadingSample && question2 !== ''"
        style="border: 1px solid rgba(218, 215, 215, 0.31); margin-top: 10px"
      ></div>
        <div
          style="
            font-size: 12px;
            color: rgba(34, 34, 34, 0.5);
            margin-bottom: 10px;
          "
        >
          {{'SHARED_PAGE.GENERATED_DATA' | i18nTranslate}}
        </div>
        <div
          style="
            
            border-radius: 5px;
            color: #000000;
            padding: 10px;
            line-height: 25px;
            display: flex;
            flex-direction: column;
          "
        >
          <div style="font-size: 16px; margin-bottom: 5px;">{{ question2 | titlecase}} </div>
          <div *ngFor="let item of sampleEmailText" class="qb-border">
            <div *ngFor="let data of item | keyvalue">
             <b style="font-size: 12px;">{{data.key | removeUnderscore}}</b>  : {{data.value | keys}}
            </div>
            <!-- <div
            *ngIf="!loadingSample && question2 !== '' && sampleEmailText.length !== 1"
            style="border: 1px solid rgba(218, 215, 215, 0.31); margin-top: 10px"
          ></div> -->
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
