import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog"; 
import { ActivatedRoute, Router, UrlTree } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { Globals } from "src/app/core/services/global.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent } from "src/app/shared/components/dialog-box";
import { AiEmailCreationComponent } from "src/app/shared/components/dialog-box/ai-email-creation/ai-email-creation.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-email-template-choose",
  templateUrl: "./email-template-choose.component.html",
  styleUrls: ["./email-template-choose.component.css"],
})
export class EmailTemplateChooseComponent implements OnInit {
  s3BucketUrl = environment.s3BucketUrl;
  templateList;
  customGenerictemplateList = [];
  customRewardtemplateList = [];
  standardGenerictemplateList = [];
  standardRewardtemplateList = [];
  filter = {
    label: "",
  };
  loading = false;
  rewardTemplateList;
  tabSelected = "preset";
  mobileMatch = window.matchMedia("(max-width: 896px)");
  selectedTabTemplateList = [];
  validWidgets = null;
  appSubscriptionPlan;
  segmentType = 'all';
  @ViewChild('search') searchElement: ElementRef;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  snapshotCounter = 0;
  snapshotInterval;
  showFiltersDiv = false;
  filters = { generic: false, reward: false }; 
  filtersBackup = {generic:false, reward:false}
  headingTextKey = this._i18nDynamicTranslate.transform("Custom Template", ['POS_PAGE']);

  constructor(
    private apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private globalService: Globals,
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  clickEvent(event) {
    let elements = document.getElementsByClassName("qb-more-options");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("style", "display:none");
    }
  }

  ngOnInit() {
    this.getWidgets();
    this.getParams();
    let interval = setInterval(() => {
      let element = document.getElementById("container");
      if (element) {
        element.addEventListener("click", (event) => {
          this.clickEvent(event);
        });
        clearInterval(interval);
      }
    });
    this.getTemplates();
  }

  openFiltersDiv() {
    this.showFiltersDiv = true;
  }


  getParams(){
    this.route.params.subscribe((params) => {
      if(this.router.url.split(';')[1] === 'useAI=true') {
        let dialogRef = this.dialog.open(AiEmailCreationComponent);
      }
        if(params.id && params.id != 'null'){
          this.snapshotCounter= 0;
          this.checkForSnapshot(Number(params.id), params.type);
        } 
      }
    );
  }

  checkForSnapshot(id, type){
    if(type!="REWARD"){
      this.snapshotInterval = setInterval(()=>{
        this.getSnapshot(id);
      }, 7000);
    }
  }

  getSnapshot(id){
    this.snapshotCounter = this.snapshotCounter+ 1;
    if(this.snapshotCounter<6){
      this.apiCall.getEmailLayoutDetail(id).subscribe((response) => {
        let layout = JSON.parse(response["body"]);
        if(layout && layout.snapShot){
          clearInterval(this.snapshotInterval);
          this.changeSnapshot(id, layout.snapShot);
        }
      },
      (err)=>{
        clearInterval(this.snapshotInterval);
        this.changeSnapshot(id, null);
      });
    }
    else{
      clearInterval(this.snapshotInterval);
      this.changeSnapshot(id, null);
    }
  }

  changeSnapshot(id, url){
    let emptyIllustration = "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Not-available.png";
    let layouts = this.selectedTabTemplateList;
    let selectedIndex;
    layouts.forEach((layout, i)=>{
      if(layout.id===id) selectedIndex=i;
    });
    if(selectedIndex){
      layouts[selectedIndex].snapshotUrl = url? url : emptyIllustration;
      this.selectedTabTemplateList = layouts
    }
  }

  showSearch(){
    this.searchElement.nativeElement.focus();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  showCustomtempalteListFilter = false;
  showStandardtempalteListFilter = false;
  showFilters = false;
  getTemplates(list?) {
    this.loading = true;
    this.customGenerictemplateList = [];
    this.customRewardtemplateList = [];
    this.standardGenerictemplateList = [];
    this.standardRewardtemplateList = [];
    let layoutAPI = this.apiCall.listEmailLayout().subscribe((response) => {
      this.templateList = response["body"];
      this.templateList.sort((a,b)=> {
          const dateA :Date = new Date(a.lastModifiedTime);
          const dateB :Date = new Date(b.lastModifiedTime);
          return dateB.getTime() - dateA.getTime();;
      });
      this.templateList.map((layout) => {
        if (layout["emailType"] != "STANDARD") {
          this.customGenerictemplateList.push({...layout,apiToCallForDetails:'layout',isDeletable:true});
        } else {
          this.standardGenerictemplateList.push({...layout,apiToCallForDetails:'layout',isDeletable:true});
        }
      });
    });
    let rewardAPI = this.apiCall.getRewardEmailList().subscribe((result) => {
      this.rewardTemplateList = result["body"];
      this.rewardTemplateList.sort((a,b)=> {
        const dateA :Date = new Date(a.lastModifiedTime);
        const dateB :Date = new Date(b.lastModifiedTime);
        return dateB.getTime() - dateA.getTime();;
      });
      this.rewardTemplateList.map((layout) => {
        if (layout["emailType"] != "STANDARD") {
          this.customRewardtemplateList.push({...layout,apiToCallForDetails:'reward',isDeletable:true});
        } else {
          this.standardRewardtemplateList.push({...layout,apiToCallForDetails:'reward',isDeletable:false});
        }
      });
    });
    let interval = setInterval(() => {
      if (rewardAPI.closed && layoutAPI.closed) {
        if(this.customRewardtemplateList.length>0) {
          list = list?list:'custom';
        } else {
          list = list?list:'preset';
        }
        if(this.standardGenerictemplateList.length > 0 && this.standardRewardtemplateList.length>0) {
          this.showStandardtempalteListFilter = true;
        }
        if(this.customGenerictemplateList.length > 0 && this.customRewardtemplateList.length>0) {
          this.showCustomtempalteListFilter = true;
        }
        if((list == 'preset' && this.showStandardtempalteListFilter) || (list == 'custom' && this.showCustomtempalteListFilter)) {
          this.showFilters = true;
        } else {
          this.showFilters = false;
        }
        this.loading = false;
        this.selectList(list, null);
        clearInterval(interval);
      }
    });
  }

  async createTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/email/layout-editor",
        { id: null, type: "CUSTOM", return: "/app/email/choose-layout" },
      ]);
    }
  }



  async editTemplate(layout) {
    if (this.mobileMedia.matches) {
      this.router.navigate(["/mobile-email-preview", {
        layout: layout['id'],
        type: layout['type'],
        apiForDetails: layout['apiToCallForDetails'],
      }])
    }
    else {
    event.stopPropagation();
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )){
        this.clickEvent(null);
        let cloneStatus = layout["emailType"] == "STANDARD" ? true : false;
        this.router.navigate([
          "/app/email/layout-editor",
          {
            id: layout["id"],
            type: layout["type"],
            emailType: (layout["type"] === 'GENERIC' || layout["type"] === 'SURVEY') ? layout["type"] : "REWARD",
            apiForDetails: layout['apiToCallForDetails'],
            clone:cloneStatus,
            return: "/app/email/choose-layout",
          },
        ])
      }
    }
  }

  async cloneTemplate(layout) {
    event.stopPropagation();
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      console.log(layout)
      this.clickEvent(null);
      this.router.navigate([
        "/app/email/layout-editor",
        {
          id: layout["id"],
          type: layout["type"],
          emailType: (layout["type"] === 'GENERIC' || layout["type"] === 'SURVEY') ? layout["type"] : "REWARD",
          clone: true,
          tabSelected: this.tabSelected,
          apiForDetails: layout['apiToCallForDetails'],
          return: "/app/email/choose-layout",
        },
      ]);
    }
  }

  async deleteLayout(id, currentList, event) {
    event.stopPropagation();
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Delete Template", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to delete this Template?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.apiCall.deleteEmailLayout(id).subscribe(
            (response) => {
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Successfull", ['POS_PAGE']), 2000);
              this.getTemplates(currentList);
            },
            (err) => {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: 'Cannot Delete Email',
                  body: err.error["body"]? err.error["body"]: "Something Went Wrong",
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                event.stopPropagation();
              });
            }
          );
        }
      });
    }
  }

  filterSelected;
  selectList(type, event,filter?) {
    this.clear();
    this.segmentType = type;
    if(event != null)
    event.stopPropagation();
    this.tabSelected = type;
    this.filterSelected = filter && this.filterSelected == filter? null:filter;
    switch (type) {
      case "preset":
        this.headingTextKey = this._i18nDynamicTranslate.transform("Preset Template", ['POS_PAGE'])
        if(this.filterSelected == 'generic') {
          this.filterSelected = 'generic';
          this.selectedTabTemplateList = this.standardGenerictemplateList;
        } else if(this.filterSelected == 'reward') {
          this.filterSelected = 'reward';
          this.selectedTabTemplateList = this.standardRewardtemplateList;
        } else {
          this.selectedTabTemplateList = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
        }
        break;
      case "custom":
        this.headingTextKey = this._i18nDynamicTranslate.transform("Custom Template", ['POS_PAGE'])
        if(this.filterSelected == 'generic') {
          this.filterSelected = 'generic';
          this.selectedTabTemplateList = this.customGenerictemplateList;
        } else if(this.filterSelected == 'reward') {
          this.filterSelected = 'reward';
          this.selectedTabTemplateList = this.customRewardtemplateList;
        } else {
          this.selectedTabTemplateList = this.customGenerictemplateList.concat(this.customRewardtemplateList);
        }
        break;
    }
    if((this.tabSelected == 'preset' && this.showStandardtempalteListFilter) || (this.tabSelected == 'custom' && this.showCustomtempalteListFilter)) {
      this.showFilters = true;
    } else {
      this.showFilters = false;
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  openOptions(event) {
    this.clickEvent(event);
    event.stopPropagation();
    let id = event.srcElement.parentElement.id;
    let element = document.getElementById(id);
    element
      .getElementsByClassName("qb-more-options")[0]
      .setAttribute("style", "display:block");
  }

  selectListView() {
    this.router.navigate(["/app/email/layout"]);
  }

  // search(event) {
  //   event.stopPropagation();
  // }

  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  createSharableLink(layout) {
    event.stopPropagation();
    console.log(layout);
    const urlTree: UrlTree = this.router.createUrlTree(["view-template"], {
      queryParams: {
        id: layout.id,
        mid: this.tokenStorage.getMerchantId(),
        type: layout.apiToCallForDetails.toLowerCase(),
      },
    });

    const relativeUrl: string = this.router.serializeUrl(urlTree);

    const baseUrl = window.location.origin;
    const fullUrl: string = `${baseUrl}${relativeUrl}`;
    
    console.log(fullUrl);

    this.copyToClipboard(fullUrl);
  }

  copyToClipboard(url: string) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    //alert("URL copied to clipboard: " + url); // Optional: show an alert or other UI feedback
    this.snackbar.openSnackBar("Email sharable link copied to clipboard", 5000);
  }

  toggleFilter(filter: string) {
    this.filters[filter] = !this.filters[filter];
  }

  clear() {
    this.filters = { generic: false, reward: false }; 
    this.updateFilters();
  }

  applyFilter() {
    this.filtersBackup = {...this.filters};
    this.updateFilters();
    this.showFiltersDiv = false;
  }

  updateFilters() {
    if (this.tabSelected === 'preset') {
      if (this.filters.generic && this.filters.reward) {
        this.selectedTabTemplateList = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
      } else if (this.filters.generic) {
        this.selectedTabTemplateList = this.standardGenerictemplateList;
      } else if (this.filters.reward) {
        this.selectedTabTemplateList = this.standardRewardtemplateList;
      } else {
        this.selectedTabTemplateList = this.standardGenerictemplateList.concat(this.standardRewardtemplateList);
      }
    } else if (this.tabSelected === 'custom') {
      if (this.filters.generic && this.filters.reward) {
        this.selectedTabTemplateList = this.customGenerictemplateList.concat(this.customRewardtemplateList);
      } else if (this.filters.generic) {
        this.selectedTabTemplateList = this.customGenerictemplateList;
      } else if (this.filters.reward) {
        this.selectedTabTemplateList = this.customRewardtemplateList;
      } else {
        this.selectedTabTemplateList = this.customGenerictemplateList.concat(this.customRewardtemplateList);
      }
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  closeFiltersDiv() {
    this.filters = {...this.filtersBackup};
    this.showFiltersDiv = false;
  }

}

