<div *ngFor="let logicalExpression of logicalExpressions;let logicalExpIndex = index" class="logical-exp pos-relative"
        [class.first-logical-expression]="logicalExpIndex === 0">

        <div class="le-lo-options" *ngIf="logicalExpIndex>0">
            <div class="lo-and-or-selector">
                <div class="lo-selector" *ngIf="logicalExpression.logicalOperator === 'AND'">
                    AND
                </div>
                <div class="lo-selector" *ngIf="logicalExpression.logicalOperator === 'OR'">
                    OR
                </div>
                <div class="selector-line"></div>
            </div>
        </div>

        <div *ngFor="let comparisonStatement of logicalExpression.comparisonStatements;let compIndex = index" class="cs-container pos-relative"
            [class.first-comparision-statement]="compIndex === 0">
            <div class="op-container">
                <div class="op-title">
                    Parameter
                </div>
                <div class="mat-select-style" title="{{comparisonStatement.operand1}}">
                    {{comparisonStatement.operand1Display}}
                </div>
            </div>

            <div class="op-container">
                <div class="op-title">
                    Operator
                </div>
                <div class="mat-select-style" title="{{comparisonStatement.operator}}">
                    {{comparisonStatement.operator}}
                </div>
            </div>


            <div class="op-container" *ngIf="comparisonStatement.operand2">
                <div class="op-title">
                    Value
                </div>
                <div class="mat-select-style" *ngIf="comparisonStatement.operand2Display" title="{{comparisonStatement.operand2Display}}">
                    {{comparisonStatement.operand2Display}}
                </div>
                <div class="mat-select-style" *ngIf="!comparisonStatement.operand2Display" title="{{comparisonStatement.operand2}}">
                    {{comparisonStatement.operand2}}
                </div>
            </div>

            <div class="cs-condition-linker" *ngIf="compIndex!=0">
                <div class="qb-AND-operator-background">
                    <div class="qb-segment-logical-operator-container">
                        <span class="qb-operator-text">{{comparisonStatement.logicalOperator}}</span>
                    </div>
                </div>
            </div>
        </div>
</div>
