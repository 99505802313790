import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RfmDynamicsComponent } from './pages';



const routes: Routes = [
    { 
      path: "", 
      component: RfmDynamicsComponent, 
      data: {
        titleheading: "RFM Dynamics",
        title: "RFM Dynamics",
        minPath: "Basic"
      },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RfmDynamicsRoutingModule { }
