import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-offer-create-dialog',
  templateUrl: './offer-create-dialog.component.html',
  styleUrls: ['./offer-create-dialog.component.css']
})
export class OfferCreateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OfferCreateDialogComponent>) { }
  
  ngOnInit(): void {
  }

  closeDialog(value) {
    this.dialogRef.close(value);
  }
  receiveMessage($event) {
    this.closeDialog({action:'closeAfterOfferCreation',offerId:$event});
  }
}
