import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Router, ActivatedRoute, UrlTree } from "@angular/router";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { EmailEditorDialogComponent } from "../email-editor-dialog/email-editor-dialog.component";
import { SendTestEmailDialogComponent } from "../send-test-email-dialog/send-test-email-dialog.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";

@Component({
  selector: "app-preview-template",
  templateUrl: "./preview-template.component.html",
  styleUrls: ["./preview-template.component.css"],
})
export class PreviewTemplateComponent {
  dh: any;
  dw: any = 0;
  id: any = 0;
  templateName: string = "";
  constructor(
    public dialogRef: MatDialogRef<PreviewTemplateComponent>,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private router: Router,
    private tokenStorage: TokenStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dh = data.height;
    if (data.type === "EMAIL" || data.type === "REWARD") {
      this.templateName = data.obj.templateName;
      console.log(data)
    } else if (data.type === "SURVEY") {
      this.templateName = data.obj.selectedSurveyName;
      var m = `class="session-newsurvey" id="mainDiv"`;
      var n = `id="mainDiv"`;
      this.data.obj.selectedSurvey = this.data.obj.selectedSurvey.replace(m, n);
      var a = `id="mainDiv" style="display:none;border-radius: 3px;`;
      var b = `id="mainDiv" border-radius: 3px;`;
      this.data.obj.selectedSurvey = this.data.obj.selectedSurvey.replace(a, b);
    } else if (data.type === "OFFER") {
      this.templateName = data.obj.masterOffer.offerName;
    } else if (data.type === "SMS") {
      this.templateName = data.obj.name;
    } else if (data.type === "SURVEYEMAIL") {
      this.templateName = data.obj.templateName;
    }
    else if(data.type ==="NEGOTIATOR_EMAIL"){
      console.log(data)
      this.templateName=data.obj.templateName;
    }
    if(data.editGame === true){
      this.templateName=data.obj.templateName;
      this.editTemplate();
    }
  }

  openSendTestEmailDialog() {
    let data = null;
    console.log(this.data)
    if (this.data.type === "EMAIL" || this.data.type === "REWARD") {
      data = {
        type: "EMAIL_LAYOUT_HTML",
        channel: "EMAIL",
        emailLayouHtml: this.data.obj.selectedLayout,
        templateID: this.data.obj.templateId,
        phone: null,
        isReward: false,
        rewardType: null,
      };
    }
    if(this.data.type ==="SURVEY"){
      let channelDetails
      this.apiCall.listEmailLayoutFilter("SURVEY").subscribe((res) => {
        if (res["message"] === "SUCCESS" || res["message"] === "success") {
          channelDetails = res['body']
          this.apiCall.getEmailLayoutDetail(channelDetails[0].id).subscribe((response)=>{
            if (response["message"] === "SUCCESS" || response["message"] === "success") {
              let layout = JSON.parse(response['body']);
              data = {
                type: "EMAIL_LAYOUT_HTML",
                channel: "EMAIL",
                emailLayouHtml: layout.layoutHtml,
                templateID: channelDetails[0].id,
                phone: null,
                isReward: false,
                rewardType: null,
              };
    
            }
          });
        }
      });
    }
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "50%",
      data: data
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
        console.log(data)
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Sending test email", ['SHARED_PAGE']), 2000);
        this.apiCall
          .sendTestEngageEmailHTML(
            data.type,
            data.channel,
            data.templateID,
            response,
            data.phone,
            data.isReward,
            data.rewardType,
            data.emailLayouHtml
          )
          .subscribe(
            (response) => {
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Test email sent", ['SHARED_PAGE']), 2000);
            },
            (err) => {
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to send test email", ['SHARED_PAGE']), 2000);
            }
          );
      }
    });
  }

  editTemplate() {
    let ch = this.dh * (90 / 100);
    let cw = this.dw * (70 / 100);
    const dialogEditRef = this.dialog.open(EmailEditorDialogComponent, {
      panelClass: "previewDialog",
      height: ch + "px",
      width: this.dh < 1500 ? "1024px" : cw + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      minHeight: "620px",
      disableClose: true,
      autoFocus: false,
      data: {
        type: this.data.type === "SURVEYEMAIL" ? "SURVEYEMAIL" : "CUSTOM",
        emailType: this.data.emailType,
        gametype: this.data.type==="NEGOTIATOR_EMAIL" ? "NEGOTIATOR_EMAIL": null,
        gameId: this.data.type==="NEGOTIATOR_EMAIL"? this.data.obj.gameId: null,
        id: this.data.obj.templateId,
        clone: "false",
        return: "false",
      },
    });

    dialogEditRef.afterClosed().subscribe((id) => {
      if (id) {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("successful", ['SHARED_PAGE']), 2000);
        this.dialogRef.close(id);
      }else{
        this.dialogRef.close()
      }
    });
  }

  createSharableLink() {
    event.stopPropagation();
    const urlTree: UrlTree = this.router.createUrlTree(["view-template"], {
      queryParams: {
        id: this.data.obj.templateId,
        mid: this.tokenStorage.getMerchantId(),
        type: this.data.emailType?.toLowerCase() ? this.data.emailType.toLowerCase() : this.data.type.toLowerCase(),
      },
    });

    const relativeUrl: string = this.router.serializeUrl(urlTree);

    const baseUrl = window.location.origin;
    const fullUrl: string = `${baseUrl}${relativeUrl}`;
    
    console.log(fullUrl);

    this.copyToClipboard(fullUrl);
  }

  copyToClipboard(url: string) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // alert("URL copied to clipboard: " + url); // Optional: show an alert or other UI feedback
    this.snackbar.openSnackBar("Email sharable link copied to clipboard", 5000);
  }

  closePreview() {
    this.dialogRef.close();
  }
}
