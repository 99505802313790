import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { SocialMediaComponent } from './pages';
import { SocialMediaCreatePostComponent, SocialMediaHomeComponent, SocialMediaInsightsComponent, SocialMediaMyPostsComponent } from './components';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { SocialMediaRoutingModule } from './social-media-routing.module';



@NgModule({
  declarations: [
   SocialMediaComponent,
   SocialMediaCreatePostComponent,
   SocialMediaHomeComponent,
   SocialMediaInsightsComponent,
   SocialMediaMyPostsComponent 
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxEmojiPickerModule,
    SocialMediaRoutingModule
  ]
})
export class SocialMediaModule { }
