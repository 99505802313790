<div class="plan-section">
  <div class="plans">
    <div class="gf-plan-details-secction">
      <div *ngIf="!currentPlanLoading &&isThereCurrentPlan" style="display: flex;
      flex-direction: column;">
        <div style="
          padding: 30.5px 18px 14.2px 18px;
        ">
          <div class="gf-plan-action">
            <div style="    display: flex;
            align-items: center; gap: 4px;">

              <img class="gf-icon-plan" *ngIf="isShopify === true"
                src='{{ "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/" + currentPlanBody.planName + ".svg" }}'
                alt="">
                <div style="display: flex; flex-direction: column; gap: 4px;">
                  <div class="gf-plan-set" *ngIf="!enterprisePlan">
                    {{ currentPlanBody.planDisplayName }}
                  </div>
                  <div class="gf-plan-set" *ngIf="enterprisePlan">
                    Enterprise Plan
                  </div>
                  <div *ngIf="enterprisePlan">
                    <div class="qb-toggle-slider-wrapper" [ngClass]="{
                      'qb-toggle-slider-active': isAutoUpdate
                    }" (click)="toggeleAutoUpdate($event)">
                      <div class="qb-toggle-slider-container">
                        <div class="qb-toggle-slider-bubble"></div>
                      </div>
                      <div class="qb-toggle-slider-label">
                        Auto Top Up If Low On Credits
                      </div>
                    </div>
                    <!-- <mat-slide-toggle class="qb-fs-limited-time" (toggleChange)="toggeleAutoUpdate($event);" color="accent" 
                    [checked]="isAutoUpdate">
                      <span>
                        Auto Top Up If Low On Credits
                      </span>
                    </mat-slide-toggle> -->
                  </div>
                </div>
              <div class="qb-plan-cancelled-div" *ngIf="planCancelled && !enterprisePlan">Plan Cancelled</div>
            </div>


            <div style="display: flex;" *ngIf="currentPlanBody.isEmailVerified && tokenStorage.getMerchantOnlineStore().toLowerCase().includes('restomail')">
              <div class="gf-secondary-hover" (click)="navigateTo('topup')" data-button-name="topup"
                *ngIf="currentPlanBody.planName!='Trial' && isShopify">
                {{'PROFILE_PAGE.TOP_UP' | i18nTranslate}}
              </div>
              <div class="gf-secondary-hover" (click)="navigateTo('changeplanPos')" data-button-name="topup"
                *ngIf="(!isDemo && !isShopify && (trial == true || planCancelled) && !enterprisePlan)">
                {{'PROFILE_PAGE.BUY_PLAN' | i18nTranslate}}
              </div>
              <div class="gf-secondary-hover" (click)="navigateTo('topupPos')" data-button-name="topup"
                *ngIf="!isDemo && !isShopify && (validWidgets | widgetConfig: 48) && !isAutoUpdate  && !enterprisePlan && currentPlanBody.status === 'ACTIVE' && !planCancelled && !trial">
                {{'PROFILE_PAGE.TOP_UP' | i18nTranslate}}
              </div>
              <div (click)="navigateTo('changeplan')" data-button-name="Change plan"
              class="primary-button gf-primary-hover" *ngIf="!isDemo && isShopify"  >
              {{'PROFILE_PAGE.CHANGE_PLAN' | i18nTranslate}}
            </div>
            <!-- <div (click)="navigateTo('changeplanPos')" data-button-name="Change plan"
              class="primary-button gf-primary-hover" *ngIf="!isDemo && !isShopify"  >
              Change Plan
            </div> -->
            <div *ngIf="isDemo"
              class="primary-button gf-primary-hover not-allowed"   >
                {{'PROFILE_PAGE.CHANGE_PLAN' | i18nTranslate}}
              </div>
            </div>


          </div>
        </div>
          <div style="
            display: flex;
            justify-content: space-between;
            padding: 0px 18px 14.2px 18px;
          ">
            <div>
              <div *ngIf="currenPlanEndDate" class="qb-plan-endon-container">
                <div style="color: rgba(34, 34, 34, 0.6); font-size: 11px" *ngIf="currentPlanBody.planName=='Trial' || trial == true">
                  {{'PROFILE_PAGE.TRAIL_PLAN_ENDS_ON' | i18nTranslate}}
                </div>
                <div style="color: rgba(34, 34, 34, 0.6); font-size: 11px" *ngIf="currentPlanBody.planName!='Trial' && trial == false && !planCancelled">
                  {{(currentPlanBody.planName!='Basic')?'Payment renews on':'Credit expires on'}}
                </div>
                <div style="color: rgba(34, 34, 34, 0.6); font-size: 11px" *ngIf="currentPlanBody.planName!='Trial' && trial == false && planCancelled">
                  Plan Expires On
                </div>
                <div class="gf-bold-text">{{ currenPlanEndDate | date: getDateFormat('dd MMM yyyy') }}</div>
              </div>
            </div>
          </div>


        <div *ngIf="!currentPlanBody.isEmailVerified" style="height: 100%;">

          <div style="display:flex;flex-direction:column;align-items: center;justify-content: center;height: 100%;">
            <img class="gf-report-im" style="margin-top:10px"
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/email-pending.svg"
              alt="Email Verification Pending">

            <div class="gf-bold-text" style="margin: 24px 5px 8px 5px;">{{'PROFILE_PAGE.EMAIL_NOT_VERIFIED' | i18nTranslate}}</div>
            <div style="    width: 84%;
            display: flex;
            align-items: center;
            justify-content: center;
            word-break: break-word;text-align: center;">
              {{'PROFILE_PAGE.VERIFY_EMAIL_TEXT_MESSAGE' | i18nTranslate}}</div>
          </div>


        </div>
        <div style="
        border-top: 1px solid #c6ced6;
          padding: 12.5px 18px 14.2px 18px;" *ngIf="currentPlanBody.isEmailVerified">
          <div style="display: flex; justify-content: space-between">
            <div>{{'PROFILE_PAGE.EMAIL_USED' | i18nTranslate}} : <b>{{ currentPlanBody.emailCreditsUsed }}</b></div>
            <div style="color: rgba(34, 34, 34, 0.6); font-size: 11px">
              {{'PROFILE_PAGE.CREDIT_EMAIL' | i18nTranslate}}
            </div>
          </div>
          <div *ngIf="currentPlanBody.emailCreditsAvailableToParentUser !== null">
            <div>
              Total Email Credits Available : <b>{{currentPlanBody.emailCreditsAvailableToParentUser}}</b>
            </div>
          </div>
          <!-- <div style="display: flex;">
            <span class="gf-bold-text">{{ currentPlanBody.emailCreditsUsed }}&nbsp;</span>
          </div> -->
        </div>
        <div style="padding: 12.5px 18px 14.2px 18px" *ngIf="(currentPlanBody.isEmailVerified) && (validScreens | screenConfig: 18)">
          <div style="display: flex; justify-content: space-between">
            <div>{{'PROFILE_PAGE.SMS_USED' | i18nTranslate}} : <b>{{ currentPlanBody.smsCreditsUsed }}</b></div>
            <div style="color: rgba(34, 34, 34, 0.6); font-size: 11px">
              {{'PROFILE_PAGE.CREDIT_1' | i18nTranslate}} <span class="gf-asterik">**</span> = {{'PROFILE_PAGE.SMS_1' | i18nTranslate}}
            </div>
          </div>
          <div *ngIf="currentPlanBody.smsCreditsAvailableToParentUser !== null">
            <div>
             Total SMS Credits Available : <b>{{currentPlanBody.smsCreditsAvailableToParentUser}}</b>
            </div>
          </div>
          <!-- <div style="display: flex;">
            <span class="gf-bold-text">{{ currentPlanBody.smsCreditsUsed }}&nbsp;</span>
          </div> -->
        </div>
        <div style="padding: 12.5px 18px 14.2px 18px" *ngIf="(currentPlanBody.isEmailVerified) && (validScreens | screenConfig: 41)">
          <div style="display: flex; flex-direction: column;">
            <div>WhatsApp Used : <b>{{ currentPlanBody.whatsappCreditsUsed }}</b></div>
            <!-- <div style="color: rgba(34, 34, 34, 0.6); font-size: 11px">
              {{'PROFILE_PAGE.CREDIT_1' | i18nTranslate}} <span class="gf-asterik">**</span> = {{'PROFILE_PAGE.SMS_1' | i18nTranslate}}
            </div>
          </div> -->
          <div *ngIf="currentPlanBody.whatsappCreditsAvailableToParentUser !== null">
            <div>
              Total WhatsApp Credits Available : <b>{{currentPlanBody.whatsappCreditsAvailableToParentUser}}</b>
            </div>
          </div>
          <!-- <div style="display: flex;">
            <span class="gf-bold-text">{{ currentPlanBody.smsCreditsUsed }}&nbsp;</span>
          </div> -->
        </div>
        </div>

        <div *ngIf="(currentPlanBody.isEmailVerified) && (validScreens | screenConfig: 18)" style="padding: 30.5px 18px 14.2px 18px;
          flex: 1;
          display: flex;    justify-content: flex-end;
    flex-direction: column;">
          <div></div>
          <div>

            <!-- <div style="color: rgba(34, 34, 34, 0.6);
            font-size: 12px;
            margin-top: 15px;"><span class="gf-asterik">*</span>{{'PROFILE_PAGE.CHANGE_PLAN_TEXT_MESSAGE' | i18nTranslate}}</div> -->
            <div style="color: rgba(34, 34, 34, 0.6);
            font-size: 12px;
            margin-top: 15px;"><span class="gf-asterik">**</span>{{'PROFILE_PAGE.3_CREDIT_SMS_USA' | i18nTranslate}}</div>
          </div>


        </div>
      </div>
      <div *ngIf="currentPlanLoading" class="center-aligned-flex">
        <mat-spinner diameter="15"></mat-spinner>
      </div>

      <div *ngIf="!isThereCurrentPlan&&!currentPlanLoading" style="    height: 100%;">

        <div style="display:flex;flex-direction:column;align-items: center;justify-content: center;height: 100%;">
          <img class="gf-report-im" style="margin-top:10px"
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/noactiveplan.svg"
            alt="Email Verification Pending">

          <div class="gf-bold-text" style="margin: 24px 5px 8px 5px;">{{'PROFILE_PAGE.NO_ACTIVE_PLANS' | i18nTranslate}}</div>
          <div style="    width: 84%;
            display: flex;
            align-items: center;
            justify-content: center;
            word-break: break-word;text-align: center;">
           {{'PROFILE_PAGE.NO_PLAN_FOUND_MESSAGE' | i18nTranslate}}</div>
          <div (click)="navigateTo('changeplan')" data-button-name="Change plan" class="primary-button gf-primary-hover"
            style="
           margin-top: 20px;
    width: 84%;" *ngIf = "isShopify">
            {{'PROFILE_PAGE.SELECT_PLAN' | i18nTranslate}}
          </div>
          <div (click)="navigateTo('changeplanPos')" data-button-name="Change plan" class="primary-button gf-primary-hover"
            style="
           margin-top: 20px;
    width: 84%;" *ngIf = "!isShopify">
           {{'PROFILE_PAGE.SELECT_PLAN' | i18nTranslate}}
          </div>
        </div>



        <!-- <div class="center-aligned-flex">
          <div>
            <div style="font-size: 18px;
          font-weight: 500;
          color: rgba(34, 34, 34, 0.6);">
              You have no active plan
            </div>
            <div (click)="navigateTo('changeplan')" data-button-name="Change plan"
              class="primary-button gf-primary-hover" style="
           margin-top: 20px;
            ">
              Select Plan
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="payment-details">
      <div *ngIf="!paymentHistoryloading" class="d-flex flex-column align-items-stretch h-100">
        <div class="qb-d-flex qb-jc-space-between qb-w-100 qb-ai-center" style="padding: 16px 20px;">
          <div class="current-plan">{{'PROFILE_PAGE.PAYMENT_HISTORY' | i18nTranslate}}</div>
          <div class="gf-cancel-subscription" (click)="cancelMySubscription()"
            *ngIf="!currentPlanLoading&&currentPlanBody.planName!='Basic'&&currentPlanBody.planName!='Trial' && !currentPlanBody.isCancelled && isShopify">

            <span *ngIf="!cancelButtonLoading">{{'PROFILE_PAGE.CANCEL_MY_PLAN' | i18nTranslate}}</span>
            <div *ngIf="cancelButtonLoading">
              <mat-spinner diameter="12"></mat-spinner>
            </div>
          </div>
          <div class="gf-cancel-subscription" (click)="cancelMySubscriptionPOS()"
          *ngIf="!isShopify && !trial && !planCancelled && tokenStorage.getMerchantOnlineStore().toLowerCase().includes('restomail')  && !enterprisePlan">

          <span *ngIf="!cancelButtonLoading">{{'PROFILE_PAGE.CANCEL_MY_PLAN' | i18nTranslate}}</span>
          <div *ngIf="cancelButtonLoading">
            <mat-spinner diameter="12"></mat-spinner>
          </div>
        </div>
        <!-- <div class="gf-cancel-subscription" (click)="renewPlanPOS()"
        *ngIf="!isShopify && planCancelled">

        <span *ngIf="!cancelButtonLoading">Renew Plan</span>
        <div *ngIf="cancelButtonLoading">
          <mat-spinner diameter="12"></mat-spinner>
        </div>
      </div> -->
        </div>
        <div class="d-flex align-items-stretch flex-fill flex-column">
            <div class="px-3">
              <table class="table mb-0">
              <thead>
                <tr>
                  <th style="border-radius: 12px 0px 0px 12px; width: 25%;">{{'PROFILE_PAGE.DATE' | i18nTranslate}}</th>
                  <th style="width: 25%;">{{'PROFILE_PAGE.PLAN' | i18nTranslate}}</th>
                  <th style="width: 20%;">{{'PROFILE_PAGE.AMOUNT' | i18nTranslate}}</th>
                  <th *ngIf="paymentItems.length>0 && isShopify !== true" style="border-radius: 0px 12px 12px 0px;">Status</th>
                  <!-- <th>Status</th> -->
                </tr>
              </thead>
            </table>
            </div>
           <div class="qb-table-body-height-wrapper flex-fill position-relative">
            <div class="qb-table-body-scroll-wrapper position-absolute px-3">
              <table class="table">
              <tbody *ngIf="paymentItems.length>0 && isShopify === true">
                <tr *ngFor="let item of paymentItems; let i = index" class="qb-value-area custom-padding-tr">
                  <td>{{ item.billingDate.replace(' ', 'T') | date: getDateFormat('dd MMM yyyy')}}</td>
                  <td>{{ item.billingHead | removeUnderscore
                    | titlecase }}</td>
                  <td>${{ item.billedAmount}}</td>
                  <!-- <td style="color: black">{{ item.status }}</td> -->
                </tr>
              </tbody>
              <tbody *ngIf="paymentItems.length>0 && isShopify !== true">
                <tr *ngFor="let item of paymentItems" class="qb-value-area custom-padding-tr" >
                  <td style="width: 25%;">{{ item['data']['transactionTs'] | date: getDateFormat('dd MMM yyyy')}} <br> {{ item['data']['transactionTs']| date : 'h:mm a'}}</td>
                  <td style="width: 25%;">{{ item.rechargeName | removeUnderscore
                    | titlecase }}</td>
                  <td style="width: 20%;">{{ (item['data'].amount | currency : item.currencyCode) || '-' }}</td>
                  <td>{{item['data'].event | removeUnderscore
                    | titlecase}}</td>
                </tr>
              </tbody>
              <div *ngIf="paymentItems.length == 0" style="
              font-weight: 100;
              display: flex;
              justify-content: center;
              margin-top: 20px;
              font-style: italic;">
                {{'PROFILE_PAGE.NO_PAYEMNET_HISTORY' | i18nTranslate}}
              </div>
              </table>
            </div>
           </div>
        </div>

      </div>

      <div *ngIf="paymentHistoryloading" class="center-aligned-flex">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
    </div>
  </div>
  <div class="payment-details-transation">
    <div *ngIf="!paymentHistoryloading" class="d-flex flex-column align-items-stretch h-100">
      <div class="qb-d-flex qb-jc-space-between qb-w-100 qb-ai-center" style="padding: 16px 20px;">
        <div class="current-plan">Credits Transfer History</div>
      <!-- <div class="gf-cancel-subscription" (click)="renewPlanPOS()"
      *ngIf="!isShopify && planCancelled">

      <span *ngIf="!cancelButtonLoading">Renew Plan</span>
      <div *ngIf="cancelButtonLoading">
        <mat-spinner diameter="12"></mat-spinner>
      </div>
    </div> -->
      </div>
      <div class="d-flex align-items-stretch flex-fill flex-column">
          <div class="px-3">
            <table class="table mb-0">
            <thead>
              <tr>
                <th style="border-radius: 12px 0px 0px 12px; width: 24%;">{{'PROFILE_PAGE.DATE' | i18nTranslate}}</th>
                <th style="width: 24%;">Channel</th>
                <th style="width: 24%;">Transfer</th>
                <th style="width: 20%;">Remark</th>
                <!-- <th>Status</th> -->
              </tr>
            </thead>
          </table>
          </div>
         <div class="qb-table-body-height-wrapper flex-fill position-relative" style="height: 400px;">
          <div class="qb-table-body-scroll-wrapper px-3">
            <table class="table">
            <tbody *ngIf="transactionHistory.length>0 && isShopify !== true">
              <tr *ngFor="let item of transactionHistory" class="qb-value-area custom-padding-tr" >
                <td style="width: 25%;">{{ item['timestamp'] | date: getDateFormat('dd MMM yyyy')}} <br> {{ item['timestamp']| date : 'h:mm a'}}</td>
                <td style="width: 25%;">{{ item.channel | removeUnderscore
                  | titlecase }}</td>
                <td style="width: 25%;" [ngStyle]="{
                  color: item?.type.toUpperCase() === 'CREDIT'  ? 'green' : 'red'
              }">
                {{item?.credit}}
                </td>
                <td style="width: 20%;">{{ item.eventName | removeUnderscore
                  | titlecase }}</td>
              </tr>
            </tbody>
            <div *ngIf="transactionHistory.length == 0" style="
            font-weight: 100;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            font-style: italic;">
              No Credits Transfer History
            </div>
            </table>
          </div>
          <!-- <div class="qb-w-100 qb-paginator">
            <mat-paginator
              [pageIndex]="page - 1"
              [length]="totalHistory"
              [pageSize]="size"
              [pageSizeOptions]="[5, 10, 25, 100]"
              (page)="getNextData($event)"
            >
            </mat-paginator>
          </div> -->
         </div>
      </div>

    </div>

    <div *ngIf="paymentHistoryloading" class="center-aligned-flex">
      <mat-spinner diameter="15"></mat-spinner>
    </div>
  </div>
</div>