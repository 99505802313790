import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'userName';
const AUTHORITIES_KEY = 'AuthAuthorities';
const MERCHANT_ID = 'MErchantId';
const MERCHANT_STORE = 'MerchantStore';
const MERCHANT_HMAC = 'hMac';
const MERCHANT_TIMESTAMP = 'timeStamp';
const MERCHANT_STORE_URL = 'storeUrl';
const ONLINE_STORE_URL = '_o-s-u';
const USER_LOGGED = 'ULog';
const USERNAMEFULL_KEY = "UserName";
const RECOMMENDED_USER_ID = "RecommendedUserId";
const RECOMMENDED_EXCLUDED_USER_ID = "RecommendedExcludedUserId";
const ACTION_RECOMMENDER_ID = "RecommenderId";
const EXISTING_USER = "ExistingUser";
const SHOP_ID = 'ShopId';
const USER_EMAIL = 'userEmail';
const USER_SHOP_URL = 'userShopUrl';
const USER_SHOP_LK='user-shop-lk';
const MERCHANT_TIME_ZONE = '_m-tz';
const SHOP_NAME = 'ShopName';
const isSUPER_ADMIN='_iSA';
const isNew_User='newUser';
const PARTNERSHIP_TOKEN = 'partnershipToken';
const IS_PARTNERSHIP_CONSENT_RECIEVED="_is-p-c-r";
const ISONBOARDINGCOMPLETED="_iOC";
const SHOW_PROGRESSBAR_STATUS="_sPB";
const OBJECTIVES_STATUS = "_objStatus";
const IS_DEMO = "isDemo";
const IS_WHATSAPP_ACCOUNT_CREATED = "isAccountCreated"
const USER_ID = "UserId";
const Q_ID = "qid";
const STORE_NAME = "storeName";
const CHAT_GPT = 'chatgpt';
const STORE_ID = "storeId";
const UPLOAD_DATA_STORE_ID = "upload_storeId";
const MERCHANT_NAME = "merchantName";

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private roles: Array<string> = [];
  constructor(private router: Router) {
    window.addEventListener('storage', (event) => {
      if (event.key == 'lStatus') {
        if (localStorage.getItem('lStatus') == 'exit20') {
          // setTimeout(function(){
          this.router.navigate(['/auth/login']); // If you are using router
          // window.history.pushState( {} , "title", "login" );
          // },2000);
        }


      }
    });

  }

  eventStartListen() {
    console.log("listen")
    //   window.addEventListener('storage', function(event){
    //     if (event.key == 'lStatus') {
    //       if(localStorage.getItem('lStatus')=='exit20'){
    //       this.location.href='/auth/login';
    //       }
    //     }
    // });
    // window.addEventListener('storage', (event) => {
    //   console.log('checklog')
    //   if (event.storageArea == localStorage) {
    //     let token = localStorage.getItem('lStatus');
    //     if(token == 'exit20') { // you can update this as per your key
    //         // DO LOGOUT FROM THIS TAB AS WELL
    //         this.router.navigate(['/auth/login']); // If you are using router
    //         // OR
    //         // window.location.href = '<home page URL>';
    //     }
    //   }
    // }, false);
  }
  stopListen() {
    window.removeEventListener('storage', null)
  }



  // signOut() {
  //       window.localStorage.clear();
  //       localStorage.setItem('lStatus', 'exit20');
  // }

  public setIsDemo(token: string) {
    window.localStorage.removeItem(IS_DEMO);
    window.localStorage.setItem(IS_DEMO, token);
  }

  public getIsDemo(): string {
    return localStorage.getItem(IS_DEMO);
  }

  public setChatGPT(token: string) {
    window.localStorage.removeItem(CHAT_GPT);
    window.localStorage.setItem(CHAT_GPT, 'true');
  }

  public getChatGPT(): string {
    return localStorage.getItem(CHAT_GPT);
  }

  public saveToken(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUsername(username: string) {
    window.localStorage.removeItem(USERNAME_KEY);
    window.localStorage.setItem(USERNAME_KEY, username);
  }
  public saveUserNameFull(username: string) {
    window.localStorage.removeItem(USERNAMEFULL_KEY);
    window.localStorage.setItem(USERNAMEFULL_KEY, username);
  }
  public getUsername(): string {
    return localStorage.getItem(USERNAME_KEY);
  }
  public getUsernameFull(): string {
    return localStorage.getItem(USERNAMEFULL_KEY);
  }
  public setUserEmail(data:string) {
    window.localStorage.removeItem(USER_EMAIL);
    window.localStorage.setItem(USER_EMAIL, data);
  }
  public getUserEmail(): string {
    return localStorage.getItem(USER_EMAIL);
  }
  public setshopUrl(data:string) {
    window.localStorage.removeItem(USER_SHOP_URL);
    window.localStorage.setItem(USER_SHOP_URL, data);
  }
  public getShopUrl(): string {
    return localStorage.getItem(USER_SHOP_URL);
  }


  public setPartnershipToken(data:string) {
    window.localStorage.setItem(PARTNERSHIP_TOKEN, data);
  }

  public getPartnershipToken(): string {
    return localStorage.getItem(PARTNERSHIP_TOKEN);
  }

  public deletePartnershipToken() {
    window.localStorage.removeItem(PARTNERSHIP_TOKEN);
  }

  public setshopLinkedStatus(data:string) {
    window.localStorage.removeItem(USER_SHOP_LK);
    window.localStorage.setItem(USER_SHOP_LK, data);
  }
  public getshopLinkedStatus(): string {
    return localStorage.getItem(USER_SHOP_LK);
  }
  public removeshopLinkedStatus(data:string) {
    window.localStorage.removeItem(USER_SHOP_LK);
  }


  public saveAuthorities(authorities: string[]) {
    window.localStorage.removeItem(AUTHORITIES_KEY);
    window.localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];

    if (localStorage.getItem(TOKEN_KEY)) {
      JSON.parse(localStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }

    return this.roles;
  }

  public saveMerchantId(id) {
    window.localStorage.removeItem(MERCHANT_ID);
    window.localStorage.setItem(MERCHANT_ID, id);
  }

  public getMerchantId() {
    return localStorage.getItem(MERCHANT_ID);
  }

  public saveMerchantOnlineStore(store) {
    window.localStorage.removeItem(MERCHANT_STORE);
    window.localStorage.setItem(MERCHANT_STORE, store);
  }

  public removeMerchantOnlineStore() {
    window.localStorage.removeItem(MERCHANT_STORE);
  }

  public getMerchantOnlineStore() {
    return localStorage.getItem(MERCHANT_STORE);
  }

  public saveHmac(hmac) {
    window.localStorage.removeItem(MERCHANT_HMAC);
    window.localStorage.setItem(MERCHANT_HMAC, hmac);
  }

  public getHmac() {
    return localStorage.getItem(MERCHANT_HMAC);
  }

  public saveMerchantStoreUrl(storeUrl) {
    window.localStorage.removeItem(MERCHANT_STORE_URL);
    window.localStorage.setItem(MERCHANT_STORE_URL, storeUrl);
  }

  public getMerchantStoreUrl() {
    return window.localStorage.getItem(MERCHANT_STORE_URL);
  }

  public saveTimeStamp(timeStamp) {
    window.localStorage.removeItem(MERCHANT_TIMESTAMP);
    window.localStorage.setItem(MERCHANT_TIMESTAMP, timeStamp);
  }

  public getTimeStamp() {
    return window.localStorage.getItem(MERCHANT_TIMESTAMP);
  }

  public saveOnlineStoreUrl(url) {
    window.localStorage.removeItem(ONLINE_STORE_URL);
    window.localStorage.setItem(ONLINE_STORE_URL, url);
  }

  public removeOnlineStoreUrl() {
    window.localStorage.removeItem(ONLINE_STORE_URL);
  }

  public getOnlineStoreUrl() {
    return window.localStorage.getItem(ONLINE_STORE_URL)
  }

  public setUserLogin(info) {
    window.localStorage.removeItem(USER_LOGGED);
    window.localStorage.setItem(USER_LOGGED, info);
  }

  public getUserLogin() {
    return window.localStorage.getItem(USER_LOGGED);
  }

  public setRecommendedUserId(id) {
    window.localStorage.removeItem(RECOMMENDED_USER_ID);
    window.localStorage.setItem(RECOMMENDED_USER_ID, id);
  }

  public getRecommendedUserId() {
    return window.localStorage.getItem(RECOMMENDED_USER_ID);
  }

  public removeRecommendedUserId() {
    window.localStorage.removeItem(RECOMMENDED_USER_ID);
  }

  public setExcludedRecommendedUserId(id) {
    window.localStorage.removeItem(RECOMMENDED_EXCLUDED_USER_ID);
    window.localStorage.setItem(RECOMMENDED_EXCLUDED_USER_ID, id);
  }

  public getExcludedRecommendedUserId() {
    return window.localStorage.getItem(RECOMMENDED_EXCLUDED_USER_ID);
  }

  public removeExcludedRecommendedUserId() {
    window.localStorage.removeItem(RECOMMENDED_EXCLUDED_USER_ID);
  }

  public setActionRecommenderId(id) {
    window.localStorage.removeItem(ACTION_RECOMMENDER_ID);
    window.localStorage.setItem(ACTION_RECOMMENDER_ID, id);
  }

  public getActionRecommenderId() {
    return window.localStorage.getItem(ACTION_RECOMMENDER_ID);
  }

  public removeActionRecommenderId() {
    window.localStorage.removeItem(ACTION_RECOMMENDER_ID);
  }

  public setExistingUserFlag(status) {
    window.localStorage.removeItem(EXISTING_USER);
    window.localStorage.setItem(EXISTING_USER, status);
  }

  public getExistingUserFlag() {
    return window.localStorage.getItem(EXISTING_USER);
  }

  public removeExistingUserFlag() {
    window.localStorage.removeItem(EXISTING_USER);
  }


  public getShopId() {
    return window.localStorage.getItem(SHOP_ID);
  }
  public setShopId(data) {
    window.localStorage.setItem(SHOP_ID, data);
  }
  public removeShopID() {
    window.localStorage.removeItem(SHOP_ID);
  }

  public setStorageListener() {
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }

  public saveData(id: any, data: any) {
    window.sessionStorage.removeItem(id);
    window.sessionStorage.setItem(id, data);
  }

  public getData(id: any) {
    return window.sessionStorage.getItem(id);
  }

  public removeData(id: any) {
    window.sessionStorage.removeItem(id);
  }

  public setMerchantTimeZone(data:string) {
    window.localStorage.removeItem(MERCHANT_TIME_ZONE);
    window.localStorage.setItem(MERCHANT_TIME_ZONE, data);
  }

  public getMerchantTimeZone(): string {
    return localStorage.getItem(MERCHANT_TIME_ZONE);
  }

  public removeMerchantTimeZone(data:string) {
    window.localStorage.removeItem(MERCHANT_TIME_ZONE);
  }
  public setShopName(shopname) {
    window.localStorage.removeItem(SHOP_NAME);
    window.localStorage.setItem(SHOP_NAME, shopname);
  }

  public removeShopName() {
    window.localStorage.removeItem(SHOP_NAME);
  }

  public getShopName() {
    return window.localStorage.getItem(SHOP_NAME)
  }
  public setIsSuperAdmin(isAdmin){
    window.localStorage.removeItem(isSUPER_ADMIN);
    window.localStorage.setItem(isSUPER_ADMIN, isAdmin);
  }
  public removeIsSuperAdmin(){
    window.localStorage.removeItem(isSUPER_ADMIN);
  }
  public getIsSuperAdmin(){
    return window.localStorage.getItem(isSUPER_ADMIN);
  }
  public setIsNewUser(isNewUser){
    window.localStorage.removeItem(isNew_User);
    window.localStorage.setItem(isNew_User, isNewUser);
  }
  public removeIsNewUser(){
    window.localStorage.removeItem(isNew_User);
  }
  public getIsNewUser(){
    return window.localStorage.getItem(isNew_User);
  }
  public getPartnershipConsentStatus(){
    return window.localStorage.getItem(IS_PARTNERSHIP_CONSENT_RECIEVED);
  }
  public setPartnershipConsentStatus(status){
    window.localStorage.removeItem(IS_PARTNERSHIP_CONSENT_RECIEVED);
    window.localStorage.setItem(IS_PARTNERSHIP_CONSENT_RECIEVED, status);
  }
  public setIsOnboardingCompleted(flag) {
    window.localStorage.removeItem(ISONBOARDINGCOMPLETED);
    window.localStorage.setItem(ISONBOARDINGCOMPLETED, flag);
  }
  public getIsOnboardingCompleted() {
    return window.localStorage.getItem(ISONBOARDINGCOMPLETED);
  }
  public getObjectivesStatus(){
    let status = window.localStorage.getItem(OBJECTIVES_STATUS)? JSON.parse(window.localStorage.getItem(OBJECTIVES_STATUS)): null;
    return status;
  }
  public setObjectivesStatus(status){
    let stringifiedData = JSON.stringify(status);
    window.localStorage.removeItem(OBJECTIVES_STATUS);
    window.localStorage.setItem(OBJECTIVES_STATUS, stringifiedData);
  }
  public setProgressBarShowStatus(flag) {
    window.localStorage.removeItem(SHOW_PROGRESSBAR_STATUS);
    window.localStorage.setItem(SHOW_PROGRESSBAR_STATUS, flag);
  }
  public getProgressBarShowStatus() {
    return window.localStorage.getItem(SHOW_PROGRESSBAR_STATUS);
  }
  public setIsAccountCreated(token) {
    window.localStorage.removeItem(IS_WHATSAPP_ACCOUNT_CREATED);
    window.localStorage.setItem(IS_WHATSAPP_ACCOUNT_CREATED, token);
  }

  public getIsAccountCreated() {
    return localStorage.getItem(IS_WHATSAPP_ACCOUNT_CREATED);
  }
  public saveUserId(id) {
    window.localStorage.removeItem(USER_ID);
    window.localStorage.setItem(USER_ID, id);
  }

  public getUserId() {
    return localStorage.getItem(USER_ID);
  }
  public saveStoreId(id) {
    window.localStorage.removeItem(STORE_ID);
    window.localStorage.setItem(STORE_ID, id);
  }

  public getStoreId() {
    return localStorage.getItem(STORE_ID);
  }
  public saveqId(id) {
    window.localStorage.removeItem(Q_ID);
    window.localStorage.setItem(Q_ID, id);
  }

  public getqId() {
    return localStorage.getItem(Q_ID);
  }
  public saveStoreName(name) {
    window.localStorage.removeItem(STORE_NAME);
    window.localStorage.setItem(STORE_NAME, name);
  }

  public getStoreName() {
    return localStorage.getItem(STORE_NAME);
  }

  public saveUploadStoreId(id) {
    window.localStorage.removeItem(UPLOAD_DATA_STORE_ID);
    window.localStorage.setItem(UPLOAD_DATA_STORE_ID, id);
  }

  public getUploadStoreId() {
    return localStorage.getItem(UPLOAD_DATA_STORE_ID);
  }

  public saveMerchantName(name) {
    window.localStorage.removeItem(MERCHANT_NAME);
    window.localStorage.setItem(MERCHANT_NAME, name);
  }

  public getMerchantName() {
    return localStorage.getItem(MERCHANT_NAME);
  }
  
}
