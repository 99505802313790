import { DatePipe, getCurrencySymbol } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Observable, forkJoin } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

import { ConfirmationDialogBoxComponent, EmailEditorDialogComponent, PreviewTemplateComponent, OfferSummaryDialogComponent, CustomerLeadsComponent } from "src/app/shared/components/dialog-box";
import { OAuthRedirectDialogComponent } from "src/app/shared/components/oauth-redirect-dialog/oauth-redirect-dialog.component";
import { OnboardingStatus } from "src/app/shared/enums/onboarding-status.enum";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: "app-pos-home",
  templateUrl: "./pos-home.component.html",
  styleUrls: ["./pos-home.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PosHomeComponent implements OnInit, OnDestroy{
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }
  @ViewChild("select") select;
  @ViewChild("search") searchElement: ElementRef;
  validWidgets = null;
  pos = null;
  subscribedPlan;
  revenueCurrency;
  currencySymbol;
  totalCustomerCount;
  selectedGraphButton = "All Time";
  startDate;
  endDate;
  numberOfEmailSent = 0;
  isDemo;
  openRate = 0;
  clickedRate = 0;
  channelEmail = true;
  channelSMS = true;
  surveyAlertsPageSize = 10;
  surveyAlertsPageIndex = 0;
  surveyAlerts;
  surveyAlertsLength = 0;
  leadsPageSize = 10;
  leadsPageIndex = 0;
  leadsLength = 0;
  searchValue = "";
  leads = [];
  selectedOfferType = "reward";
  selectedTab = "";
  selectedLineGraphData = "revenue";
  customerDetails;
  actionType;
  actionDiv = false;
  templateDiv = false;
  showSelectCustomersDiv = false;
  searchCustomersArray = "";
  surveyAlertID;
  selectedCustomers = [];
  numberOfSelectedCustomers = "Select Customers";
  templates = [];
  templatesArray = [];
  templateID;
  saveTemplate = false;
  templateName = "";
  campaigns = [];
  selectedCampaigns = [];
  campaignID;
  subjectError = false;
  subject = "";
  customersError = false;
  template = "";
  revenue;
  emailTemplatesLoading = true;
  offerTemplatesLoading = true;
  campaignsLoading = true;
  alertsLoading = true;
  leadsLoading = true;
  insightsLoading = true;
  customerCountLoading = true;
  revenueCountLoading = true;
  pageSize = 10;
  pageIndex = 0;
  excludedCustomerList = [];
  objectivesArray = [];
  currentObjectives = [];
  appSubscriptionPlan;
  selectTemplateError = false;
  mode: MatProgressBarModule = "determinate";
  objectivesLoading = false;
  // bufferValue = 75;
  revCurrency;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  emailVerified = true;
  retryAllowed;
  emailVerificationMessage;
  showEmailSentMessage = false;
  FirstCheck=false;
  objectiveCheck=false;
  UserName;
  demoCheck=false;
  openCheck=false;
  allcustomer = [{ id: "ALL_CUSTOMERS", name: "All" }];
  customerFilter="";
  disableAllCustomerSelection=false;
  disableCustomerSelection=false;
  sidebarNavSubscription;
  objectivesScrollIndex = 0;
  store;
  objectivesAccordion = [
    {
      header: {
        icon : "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Hand.png",
        title: "Setup your account",
        showCompletedBtn: true,
        isCompleted: false
      },
      content_title: "Choose how you’d like to start",
      isClosed: false,
      isScrollable: true,
      cards: [
        {
          title: "Book a demo",
          description: "Hello! Let us show you how to get started with Qubriux. Click to get a tour of the app features from our in-house expert.",
          cta_text: "Book Demo",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/BookDemo.png`,
          background: "var(--main-bg-bgrey)",
          isCompleted: false,
          completed_title: "Demo Booked",
          completed_description: "Yay! You have booked the demo. We’ll be in touch with you on your booked date.",
          clickAction: ["book"]
        },
        {
          title: this._i18nDynamicTranslate.transform("Branding Setup" , ['POS_PAGE']),
          description: this._i18nDynamicTranslate.transform("Bingo! Set up your logo and integrate social media links. Create your brand identity in your marketing communication.", ['POS_PAGE']),
          cta_text: this._i18nDynamicTranslate.transform("Setup Branding", ['POS_PAGE']),
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Branding.png`,
          background: "var(--main-bg-bgrey)",
          isCompleted: false,
          completed_title: this._i18nDynamicTranslate.transform("Branding Setup Done", ['POS_PAGE']),
          completed_description: this._i18nDynamicTranslate.transform("Hurray!!! you have completed your branding setup.", ['POS_PAGE']),
          clickAction: ["redirect", "branding"]
        },
        {
          title: "Domain Setup",
          description: "En garde! Set up your domain name. Your store’s domain will be used to send emails to your customers.",
          cta_text: "Setup Domain",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Domain.png`,
          background: "var(--main-bg-bgrey)",
          isCompleted: false,
          completed_title: "Domain Setup Done",
          completed_description: "Hurray!!! you have completed your domain setup.",
          clickAction: ["redirect", "domain"]
        }
      ]
    },
    {
      header: {
        icon : "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Laptop.png",
        title: "Explore Qubriux",
        showCompletedBtn: false
      },
      content_title: "Explore our exciting features",
      isClosed: false,
      cards: [
        {
          title: "Explore Co-Marketing",
          description: "Partner with another Qubriux merchant to sell to their customers. Earn rewards by promoting partner merchants' offers.",
          cta_text: "Go to Co-Marketing",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/dashboard-assets/comarketing.png`,
          background: "var(--main-bg-bgrey)",
          clickAction: ["redirect", "co-marketing"]
        },
        {
          title: "Start a Journey",
          description: "Right-o! Get started by sending your first journey. Automated marketing helps you reach more customers with less effort and more results.",
          cta_text: "Explore Jouney",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Campaign.png`,
          background: "var(--main-bg-bgrey)",
          clickAction: ["redirect", "campaign"]
        },
        {
          title: "Collect Feedbacks",
          description: "Send surveys to collect feedback and First Party Data from your customers. Utilize the smart surveys we have created for you.",
          cta_text: "Explore Surveys",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/FeedBack.png`,
          background: "var(--main-bg-bgrey)",
          clickAction: ["redirect", "feedbacks"]
        },
        {
          title: "Gamify your Offerings",
          description: "Deploy the gamification feature to engage your customers in an exciting game that lets them get the offers on products they want.",
          cta_text: "Explore Game",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/dashboard-assets/gamify.png`,
          background: "var(--main-bg-bgrey)",
          clickAction: ["redirect", "gamify"]
        },
        {
          title: "Pop-ups",
          description: "Ta-da! Use intelligently placed pop-ups to generate leads and promote your offers. Use forms to collect useful customer data.",
          cta_text: "Explore Forms",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/dashboard-assets/popups.png`,
          background: "var(--main-bg-bgrey)",
          clickAction: ["redirect", "popups"]
        },
      ]
    }
  ]

  isOnboardingStepsCompleted:boolean=null;
  one=1;
  two=2;
  onboardingSteps=null;
  isOnboardingCheckApiLoaded:boolean=false;
  oAuthPlatforms:string[]=["shopify","lightspeed"];
  showObjective = false;
  athorities = this.tokenStorage.getAuthorities();

  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private datePipe: DatePipe,
    private apiCall: ApiCallService,
    private router: Router,
    private dialog: MatDialog,
    private popup: PopUpDialogService,
    private tokenStorage: TokenStorageService,
    private snackbar: SnackbarCollection,
    private matSnackbar: MatSnackBar,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private _SidenavOperationService: SidenavOperationService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    window["screen-name"] = "HOME";
  }

  displayNotificationPopover(event) {
    this.showSelectCustomersDiv = true;
    if (this.showSelectCustomersDiv)
      document.getElementById("dropdown-div").style.display = "block";
    else this.closeNotificationPopover();
  }
  closeNotificationPopover() {
    document.getElementById("notificationPopOver").style.display = "none";
    this.showSelectCustomersDiv = false;
  }

  resendVerificationEmail() {
    this.apiCall.resendVerificationEmail().subscribe(response => {
      this.showEmailSentMessage = true;
    let interval = setInterval(() => {
      this.showEmailSentMessage = false;
      this.checkEmailVerification();
      clearInterval(interval);
    }, 4000);
    })
  }

  hideNotification() {
    this.showEmailSentMessage = false;
  }

  handleAccordionStatus(key){
    let data = [...this.objectivesAccordion];
    data[key].isClosed = !data[key].isClosed;
    let accordionClosed = {
      0: data[0].isClosed,
      1: data[1].isClosed
    };
    this.objectivesAccordion = [...data];
    this.tokenStorage.setObjectivesStatus(accordionClosed);
  }

  checkEmailVerification() {
    this.apiCall.isEmailVerified().subscribe(response => {
      if(response['body']['message'] === 'verified') {
        this.emailVerified = true;
        let isOnboarded = this.tokenStorage.getIsOnboardingCompleted();
        if(isOnboarded) {
          this.checkAndCallCacheApi();
        }
      } else {
        this.emailVerified = false;
        if(response['body']['isRetryAllowed']) {
          this.retryAllowed = true;
        } else {
          this.retryAllowed = false;
        }
        this.emailVerificationMessage = response['body']['message'];
      }
    })
  }

  handleCardsScroll(type){
    const scrollableObject = <HTMLElement> document.querySelector(".accordion-scroll-active");
    const card = document.querySelector(".objectives-card");
    const cardWidth = card.getBoundingClientRect().width;
    const gridGap = 40;
    let scrollIndex = this.objectivesScrollIndex;
    if(type==="left" && scrollIndex>0) scrollIndex = scrollIndex-1;
    else if(type==="right" && scrollIndex<1) scrollIndex = scrollIndex+1;
    this.objectivesScrollIndex = scrollIndex;
    const scrollDistance = scrollIndex*(cardWidth+(gridGap/2));
    scrollableObject.style.transform = `translateX(-${scrollDistance}px)`;
  }

  getObjectivesStatus(){
    let accordionClosed = this.tokenStorage.getObjectivesStatus();
    this.objectivesLoading = true;
    this.apiCall.getObjectiveStatus().subscribe(
      (response) => {
        let objectiveItems = [...this.objectivesAccordion];
        let result = response["body"];
        if(result["domainAdded"]) objectiveItems[0].cards[2]["isCompleted"] = true;
        if(result["brandingUpdated"]) objectiveItems[0].cards[1]["isCompleted"] = true;
        if(result["domainAdded"] && result["brandingUpdated"]){
          objectiveItems[0].header["isCompleted"] = true;
          objectiveItems[0].isClosed = true;
        }
        if(accordionClosed && accordionClosed["0"]) objectiveItems[0].isClosed = true;
        if(accordionClosed && accordionClosed["1"]) objectiveItems[1].isClosed = true;


        this.objectivesAccordion = [...objectiveItems];
        this.objectivesLoading = false;
      },
      (error) => {
        this.objectivesLoading = false;
      }
    );
  }

  sideBarListener(){
    this.sidebarNavSubscription = this._SidenavOperationService.sidenavStatus.subscribe(
      (response) => {
        try {
          setTimeout(() => {
            this.handleCardsScroll("refresh");
          }, 500);
        } catch (error) {}
      }
    );
  }

  removeSideBarListener(){
    if(this.sidebarNavSubscription) this.sidebarNavSubscription.unsubscribe();
  }

  ngOnInit() {
    this.checkEmailVerification();
    this.getWidgets();
    this.getOnboardingSteps().then((result) => {
      
      switch (result) {
        case OnboardingStatus.STORE_ADDITION:
          console.log("STORE_ADDITION");
          // this._router.navigate(["/app/onboard"], {});

          break;
        case OnboardingStatus.PLAN_ADDITION:
          console.log("In PLAN_ADDITION");
          this.showObjective = true;
          this.initializeComponent();
          break;
        case OnboardingStatus.BRAND_ADDITION:
          console.log("BRAND_ADDITION started");
          // this._router.navigate(["/app/app-home"], {});

          break;
        default:
          // Default logic if none of the enum values match
          console.log("Unhandled status");
          break;
      }
    })
    .catch((error) => {
      // Logic when an error occurs
      console.log("Error:", error);
    });
   

    this.route.queryParams.subscribe((params) => {
      if (params.type) {
        if (this.mobileMedia.matches) {
          this.selectedTab = "Dashboard";
          this.selectTab("Dashboard");
        } else {
          this.selectedTab = params.type;
          this.selectTab(params.type);
          if(params.type.toLowerCase()==="objectives") this.objectivesScrollIndex = 0;
        }
      }else{
        this.selectedTab = "Dashboard";
        this.selectTab("Dashboard");
      }
    });
  }

  initializeComponent (){
    this.checkIfAccountCreated();
    this.isDemo = this.tokenStorage.getIsDemo();
    this.store = this.tokenStorage.getMerchantOnlineStore();
    if(this.store !== 'shopify') {
      this.objectivesAccordion[1]['cards'] = [
        {
          title: "Start a Journey",
          description: "Right-o! Get started by sending your first journey. Automated marketing helps you reach more customers with less effort and more results.",
          cta_text: "Explore Journey",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Campaign.png`,
          background: "var(--main-bg-bgrey);",
          clickAction: ["redirect", "campaign"]
        },
        {
          title: "Collect Feedbacks",
          description: "Send surveys to collect feedback and First Party Data from your customers. Utilize the smart surveys we have created for you.",
          cta_text: "Explore Surveys",
          icon: `https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/FeedBack.png`,
          background: "var(--main-bg-bgrey);",
          clickAction: ["redirect", "feedbacks"]
        },
      ]
    }
    this.UserName=this.tokenStorage.getUsernameFull();
    if(this.tokenStorage.getIsNewUser() == 'true'){
      this.FirstCheck=false;
    }
    else if(this.tokenStorage.getIsNewUser() =='partialTrue'){
      this.FirstCheck=true;
    }
    else{
      this.FirstCheck=true;
      this.objectiveCheck=true;
    }
    this.checkEmailVerification();
    if (this.mobileMedia.matches) {
      this.leadsPageSize = 5;
      this.surveyAlertsPageSize = 5;
    }
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.getPlan();
    // this.getTotalCustomerCount();
    this.showGraphByTime();
    this.getSurveyAlerts();
    this.getLeads();
    // this.getRevenue(this.selectedGraphButton);
    this.getObjectivesStatus();
    this.sideBarListener()
  }
  ngOnDestroy(){
    this.removeSideBarListener();
  }

  onObjectiveCardClick(args){
    console.log(args);
    if(args[0]==="redirect"){
      let path = args[1];
      switch (path) {
        case "branding":{
          this.router.navigate(["/app/user-profile"], {
            queryParams: { layout: "branding" },
          });
          break;
        }
        case "domain": {
          this.router.navigate(["/app/user-profile"], {
            queryParams: { layout: "domain" },
          });
          break;
        }
        case "co-marketing": {
          this.router.navigate(["/app/partnership"]);
          break;
        }
        case "campaign": {
          this.router.navigate(["/app/campaign/overview"],{
            queryParams: { type: "campaign" },
          });
          break;
        }
        case "offers": {
          this.router.navigate(["/app/offer/choose-offer"]);
          break;
        }
        case "feedbacks": {
          this.router.navigate(["/app/surveys/feature"],{
            queryParams: { type: "survey-list" },
          });
          break;
        }
        case "gamify": {
          this.router.navigate(["/app/gamify"],{
            queryParams:{type:"games"}
          });
          break;
        }
        case "popups": {
          this.router.navigate(["/app/forms/overview"]);
          break;
        }
      }
    }
    else if(args[0]==="book"){
      this.change();
    }
    else if(args==="addStore"){
      console.log("addstore");
      // this.router.navigate(["/app/user-profile/store-info"]);
      this.addNewStoreRedirect();
    }
    else if(args==="planSelect"){
      console.log("planSelect");
      this.router.navigate(["/app/payments/payment-plan-pos"]);
    }
    else if(args==="branding"){
      this.router.navigate(["/app/user-profile"], {
        queryParams: { layout: "branding" },
      });
    }
  }
  addNewStoreRedirect(){
    if(this.oAuthPlatforms.includes(this.tokenStorage.getMerchantOnlineStore())){
     this.oauthRedirect();
    }else{
     this.router.navigate(['/app/user-profile/store-info']);
    }
   
   }

   oauthRedirect(){
    const dialogRef = this.dialog.open(OAuthRedirectDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "380px",
      data: {
        "platform": this.tokenStorage.getMerchantOnlineStore()
      },
    });
    dialogRef.afterClosed().subscribe((result) => {

  })
}

  removeObjective(name) {
    const dialogRef1 = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Objective", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Do you want to delete this objective?", ['POS_PAGE']),
        "data-widget": "RECOMMENDED ACTIONS",
        "data-button": "Confirm: Delete",
        "data-item": name,
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef1.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.updateObjective(name).subscribe((response) => {
          this.objectivesArray.forEach((element) => {
            if (element.name === name) {
              const index: number = this.objectivesArray.indexOf(element);
              if (index !== -1) {
                this.objectivesArray.splice(index, 1);
              }
            }
          });
        });
      }
    });
  }

  openSelect() {
    this.select.open;
  }

  objectiveAction(path, category) {
    if (category === "BLOG") {
      window.open(path, "_blank");
    } else this.router.navigateByUrl(path);
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.pos = await this._WidgetConfigService.getPos();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getPlan() {
    this.revenueCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revenueCurrency, "narrow");
  }

  getTotalCustomerCount() {
    this.customerCountLoading = true;
    this.apiCall.getAnalytics(new Date(), new Date()).subscribe((response) => {
      this.totalCustomerCount = JSON.parse(response["body"]).numberOfCustomers;
      if (this.totalCustomerCount === "null") this.totalCustomerCount = 0;
      this.customerCountLoading = false;
    });
  }

  // getRevenue(button) {
  //   if(!this.isDemo) {
  //   this.revenueCountLoading = true;
  //   if (button === "All Time")
  //     this.apiCall.getRevenue(null, null).subscribe((response) => {
  //       this.revenue = response["body"];
  //       if (this.totalCustomerCount === "null") this.totalCustomerCount = 0;
  //       this.revenueCountLoading = false;
  //     });
  //   else
  //     this.apiCall
  //       .getRevenue(this.startDate, this.endDate)
  //       .subscribe((response) => {
  //         this.revenue = response["body"];
  //         if (this.totalCustomerCount === "null") this.totalCustomerCount = 0;
  //         this.revenueCountLoading = false;
  //       });
  //     } else {
  //       this.revenueCountLoading = false;
  //     }
  // }

  showGraphByTime() {
    let pdate = new Date();
    pdate.setDate(pdate.getDate() - 1);
    this.endDate = this.datePipe.transform(pdate, "yyyy-MM-dd");
    switch (this.selectedGraphButton) {
      case "All Time":
        this.startDate = this.datePipe.transform(
          new Date().setMonth(new Date().getMonth() - 6),
          "yyyy-MM-dd"
        );
        break;
      case "This Week":
        this.startDate = this.datePipe.transform(
          new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
          "yyyy-MM-dd"
        );
      // this.endDate = this.datePipe.transform(
      //   new Date(Date.now()),
      //   "yyyy-MM-dd"
      // );
    }
    // this.getRevenue(this.selectedGraphButton);
    if (this.selectedGraphButton === "All Time") {
      this.getTotalCustomerCount();
    } else this.getThisWeekCustomerCount();
    this.getEmailInsights();
  }

  getEmailInsights() {
    this.apiCall
      .getInsightAllEmail(this.startDate, this.endDate)
      .subscribe((response) => {
        if (
          response["body"]["emailStats"] != null &&
          response["body"]["emailStats"] != undefined
        ) {
          this.numberOfEmailSent =
            response["body"]["emailStats"]["totalEmailsSent"];
          this.openRate = response["body"]["emailStats"]["openedRate"];
          this.clickedRate = response["body"]["emailStats"]["clickedRate"];
        } else {
          this.numberOfEmailSent = 0;
          this.openRate = 0;
          this.clickedRate = 0;
        }
        this.insightsLoading = false;
      });
  }

  getSurveyAlerts() {
    this.apiCall
      .getRecommendationAlert(
        this.surveyAlertsPageSize,
        this.surveyAlertsPageIndex
      )
      .subscribe(
       (response) => {
          this.surveyAlerts = JSON.parse(response["body"]);
          this.surveyAlertsLength = this.surveyAlerts["totalElements"];
          this.surveyAlerts["data"].forEach((element) => {
            element["date"] = this.datePipe.transform(
              element["date"],
              "dd MMM yyyy"
            );
          });
          this.alertsLoading = false;
        },
        (error) => {
          this.alertsLoading = false;
        }
      );
  }

  getLeads() {
    this.apiCall
      .analyticsHotLead(this.leadsPageSize, this.leadsPageIndex)
      .subscribe(
        (response) => {
          this.leadsLength = response["body"]["totalElements"];
          let lead = response["body"]["customerData"];
          this.leads = [];
          for (let key in lead) this.leads.push(lead[key]);
          this.leadsLoading = false;
        },
        (error) => {
          this.leadsLoading = false;
        }
      );
  }

  selectTab(tab) {
    this.selectedTab = tab;
  }

  showLineGraph(lineGraphData) {
    this.selectedLineGraphData = lineGraphData;
  }

  finalScreenHeight: any = 0;
  finalScreenWidth: any = 0;
  scrHeight: any = 0;
  scrWidth: any = 0;
  filteredData = [];

  createEmailTemplate(id: any) {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;
    self.finalScreenWidth = self.scrWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    let sw = self.finalScreenWidth * (70 / 100);
    const dialogRef = self.dialog.open(EmailEditorDialogComponent, {
      panelClass: "customAttachEmail",
      height: 500 + "px",
      width: self.finalScreenWidth < 1500 ? "1024px" : sw + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      minHeight: "620px",
      disableClose: true,
      data: {
        type: "CUSTOM",
        id: "null",
        clone: "false",
        return: "false",
      },
    });
    dialogRef.afterClosed().subscribe((id) => {
      if (id) {
        self.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Successfull", ['POS_PAGE']), 2000);
        self.templateID = id;
        self.apiCall.listEmailLayout().subscribe(
          (response) => {
            self.templates = response["body"];
            self.templatesArray = response["body"];
            var data: any = self.templates.find((x) => x.id == id);
            self.templates = self.templates.filter((x) => x.id !== id);
            self.templates.unshift(data);
            self.filteredData = self.templates;
            self.template = data.layoutName;
            this.searchValue = "";
          },
          (err) => {}
        );
      }
    });
  }

  goToPage(page) {
    switch (page) {
      case "customers":
        this.router.navigate(["app//customers/segment-dashboard"]);
        break;
      case "insights": {
        this.router.navigate(["/app/insights"], {
          queryParams: { type: "allemails" },
        });
        break;
      }

      case "surveys": {
        this.router.navigate(["/app/surveys"], {
          queryParams: { type: "survey-list" },
        });
        break;
      }
    }
  }

  changeChannel(channel) {
    this.searchValue = "";
    if (channel === "email") this.channelEmail = !this.channelEmail;
    else this.channelSMS = !this.channelSMS;
    this.displayTemplates();
  }

  navigateToPlan() {
    this.router.navigate(["/app/user-profile"], {
      queryParams: { layout: "billing" },
    });
  }

  async performAction(surveyAlertID, actionType) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.campaignsLoading = true;
      this.customerDetails = [];
      this.actionType = actionType;
      this.surveyAlertID = surveyAlertID;
      this.surveyAlerts["data"].forEach((surveyAlert) => {
        if (surveyAlert["id"] == surveyAlertID)
          this.customerDetails = surveyAlert["customerDetails"];
      });
      this.actionDiv = true;
      this.showCampaigns();
    }
  }

  getThisWeekCustomerCount() {
    this.apiCall
      .getThisWeekCustomerCount(this.startDate, this.endDate, null)
      .subscribe((response) => {
        this.totalCustomerCount = response["body"];
        if (this.totalCustomerCount === "null") this.totalCustomerCount = 0;
      });
  }

  deleteRecommendation(recommendationID, index, number) {
    const dialogRef1 = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Survey Alert?", ['POS_PAGE']),
        message:
          this._i18nDynamicTranslate.transform("There is action pending for", ['POS_PAGE']) + " " +
          number + " " +
          this._i18nDynamicTranslate.transform("customer(s). Do you still want to delete it?", ['POS_PAGE']),
        "data-widget": "SURVEY ALERT",
        "data-button": "Confirm: Delete",
        "data-item": recommendationID,
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef1.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.ignoreRecommendationAlert(recommendationID).subscribe(
          (response) => {
            this.surveyAlerts["data"].splice(index, 1);
            this.getSurveyAlerts();
            this.popup.openPopup(
              this._i18nDynamicTranslate.transform("success", ['POS_PAGE']),
              this._i18nDynamicTranslate.transform("Recommendation Alert has been removed", ['POS_PAGE'])
            );
          },
          (err) => {
            this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), err.error.body);
          }
        );
      }
    });
  }

  refreshPaginatedData(event, card) {
    if (card === "survey alerts") {
      this.surveyAlertsPageIndex = event.pageIndex;
      this.surveyAlertsPageSize = event.pageSize;
      this.getSurveyAlerts();
    } else if (card === "leads") {
      this.leadsPageIndex = event.pageIndex;
      this.leadsPageSize = event.pageSize;
      this.getLeads();
    }
  }

  attachTemplate() {
    this.emailTemplatesLoading = true;
    this.offerTemplatesLoading = true;
    this.templateDiv = true;
    this.displayTemplates();
  }

  displayTemplates() {
    this.emailTemplatesLoading = true;
    this.offerTemplatesLoading = true;
    this.templates = [];
    this.templatesArray = [];
    if (this.actionType === "email")
      this.apiCall.listEmailLayout().subscribe((response) => {
        this.templates = response["body"];
        this.templatesArray = response["body"];
        this.emailTemplatesLoading = false;
      });
    else
      this.apiCall.getOfferDispatchOffers().subscribe((response) => {
        JSON.parse(response["body"]).offers.forEach((element) => {
          if (this.channelEmail && !this.channelSMS) {
            if (element["channel"] === "EMAIL") {
              this.templates.push(element);
              this.templatesArray.push(element);
            }
          } else if (!this.channelEmail && this.channelSMS) {
            if (element["channel"] === "SMS") {
              this.templates.push(element);
              this.templatesArray.push(element);
            }
          } else if (this.channelEmail && this.channelSMS) {
            if (element["channel"] != null) this.templates.push(element);
            this.templatesArray.push(element);
          }
        });
        this.offerTemplatesLoading = false;
      });
  }

  showSearch() {
    this.searchElement.nativeElement.focus();
  }

  cancelActionDiv() {
    this.templateName = "";
    this.template = "";
    this.actionDiv = false;
    this.templateID = -1;
    this.templateDiv = false;
    this.selectedCustomers = [];
    this.campaignID = undefined;
    this.numberOfSelectedCustomers = "Select Customers";
    this.showSelectCustomersDiv = false;
    this.subject = "";
    this.customersError = false;
    this.subjectError = false;
    this.searchValue = "";
    this.selectTemplateError = false;
  }

  cancelTemplateDiv() {
    this.templateID = -1;
    this.templateDiv = false;
    this.templateName = "";
    this.template = "";
    this.searchValue = "";
    this.selectTemplateError = false;
  }

  selectCustomers() {
    if (this.selectedCustomers.length > 0) {
      this.customersError = false;
      let element = document.getElementById("customers-input");
      element.setAttribute("style", "border: 1px solid #636466;");
    }
    this.showSelectCustomersDiv = true;
  }

  cancelSelectCustomers() {
    this.showSelectCustomersDiv = false;
    this.selectedCustomers = [];
    this.disableAllCustomerSelection=false;
    this.disableCustomerSelection=false;
  }

  searchCustomers(searchValue) {
    this.searchValue = searchValue;
    if (this.searchValue.length > 0) {
      this.customerDetails = [];
      this.surveyAlerts["data"].forEach((surveyAlert) => {
        if (surveyAlert["id"] == this.surveyAlertID)
          surveyAlert["customerDetails"].forEach((customer) => {
            if (
              customer["name"]
                .toLowerCase()
                .includes(this.searchValue.toLowerCase())
            )
              this.customerDetails.push(customer);
          });
      });
    } else this.performAction(this.surveyAlertID, this.actionType);
  }

  addCustomer(customerID) {
    if (this.selectedCustomers.indexOf(customerID) != -1) {
      this.selectedCustomers.splice(
        this.selectedCustomers.indexOf(customerID),
        1
      );
      if (customerID === "All") {
        if (this.selectedCustomers.indexOf("ALL") == -1) {
          this.selectedCustomers = [];
        }
      }
    } else {
      this.selectedCustomers.push(customerID);
      if (customerID === "All")
        this.customerDetails.forEach((customer) => {
          if (this.selectedCustomers.indexOf(customer["id"]) == -1)
            this.selectedCustomers.push(customer["id"]);
        });
    }
    this.customerDetails.forEach((customer) => {
      if (this.selectedCustomers.indexOf(customer["id"]) == -1)
        if (this.selectedCustomers.indexOf("All") != -1)
          this.selectedCustomers.splice(
            this.selectedCustomers.indexOf("All"),
            1
          );
    });
    if(customerID==="All"){
      this.disableCustomerSelection=true;
    }
    else{
      this.disableAllCustomerSelection=true;
    }
    if(this.selectedCustomers.length==0){
      this.disableAllCustomerSelection=false;
      this.disableCustomerSelection=false;
    }
  }

  save() {
    if (this.selectedCustomers.length > 0) {
      this.customersError = false;
      let element = document.getElementById("customers-input");
      element.setAttribute("style", "border: 1px solid #636466");
      this.showSelectCustomersDiv = false;
    }
    else{
      this.customersError=true;
    }
    // this.showSelectCustomersDiv = false;
  }

  searchTemplates(searchValue) {
    this.templates = [];
    if (searchValue.length > 0)
      this.templatesArray.forEach((template) => {
        if (this.actionType === "email") {
          if (
            template["layoutName"]
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
            this.templates.push(template);
        } else if (this.actionType === "offer") {
          if (
            template["offerName"]
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
            this.templates.push(template);
        }
      });
    else this.templates = this.templatesArray;
  }

  selectTemplate(templateID, templateName, event) {
    let element = document.getElementById(this.templateID);
    if (element) element.setAttribute("style", "border:none");
    // let ele = document.getElementById("img" + this.templateID);
    // if (ele) ele.setAttribute("style", "margin-left: 3px");
    this.templateID = templateID;
    this.template = templateName;
    element = document.getElementById(templateID);
    if (element)
      element.setAttribute("style", "border: 3px solid var(--primary-color);");
    // ele = document.getElementById("img" + this.templateID);
    // if (ele) ele.setAttribute("style", "margin-left: 0px");
  }

  confirm() {
    this.templateDiv = false;
    this.saveTemplate = true;
    this.templateName = this.template;
    this.template = "";
    this.selectTemplateError = false;
  }

  showCampaigns() {
    this.apiCall.getStrategies().subscribe((response) => {
      this.campaigns = JSON.parse(response["body"]);
      this.campaignsLoading = false;
    });
  }

  selectCampaign(campaignID) {
    if (this.campaignID == campaignID) {
      this.campaignID = null;
    } else {
      this.campaignID = campaignID;
    }
  }

  enterSubject() {
    if (this.subject.length > 0) this.subjectError = false;
    let element = document.getElementById("email-subject");
    element.setAttribute("style", "border: 1px solid #636466");
  }

  updateExcludedCustomersList() {
    this.customerDetails.forEach((customer) => {
      if (this.selectedCustomers.indexOf(customer.id) == -1) {
        this.excludedCustomerList.push(customer.id);
      }
    });
  }

  send() {
    this.excludedCustomerList = [];
    this.updateExcludedCustomersList();
    if (this.selectedCustomers.length > 0) {
      if (this.selectedCustomers.indexOf("All") != -1) {
        this.selectedCustomers.splice(this.selectedCustomers.indexOf("All"), 1);
      }
      if (this.actionType === "campaign") {
        this.matSnackbar.open(this._i18nDynamicTranslate.transform("Adding to journey", ['POS_PAGE']), "", {
          panelClass: ["white-snackbar"],
        });
        this.apiCall
          .addCustomerToCampaign(this.campaignID, this.selectedCustomers)
          .subscribe(
            (response) => {
              this.apiCall
                .updatesurveyrecommendation(
                  this.surveyAlertID,
                  this.excludedCustomerList.length == 0 ? "PROCESSED" : "",
                  this.excludedCustomerList
                )
                .subscribe(
                  (response) => {
                    this.matSnackbar.dismiss();
                    this.popup.openPopup(
                      this._i18nDynamicTranslate.transform("success", ['POS_PAGE']),
                      this._i18nDynamicTranslate.transform("Journey added successfully", ['POS_PAGE'])
                    );
                    this.surveyAlerts["data"].splice(
                      this.surveyAlerts["data"].findIndex(
                        (x) => x.id === this.surveyAlertID
                      ),
                      1
                    );
                    this.cancelActionDiv();
                    this.getSurveyAlerts();
                  },
                  (err) => {
                    this.matSnackbar.dismiss();
                    this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Add to journey failed", ['POS_PAGE']));
                  }
                );
            },
            (err) => {
              {
                this.matSnackbar.dismiss();
                this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Please select a journey", ['POS_PAGE']));
              }
            }
          );
        // this.apiCall.addCustomerToCampaign(this.campaignID, this.selectedCustomers).subscribe(response => {
        //   this.selectedCustomers = [];
        //   this.actionDiv = false;
        // })
      } else if (this.actionType === "offer") {
        let data;
        if (this.templateName.length == 0) {
          this.selectTemplateError = true;
        } else {
          if (this.channelEmail) {
            data = {
              channel: "EMAIL",
              segments: null,
              isSegmented: true,
              customerIds: this.selectedCustomers,
              dispatchType: "INSTANT",
            };
          } else {
            data = {
              channel: "SMS",
              segments: null,
              isSegmented: true,
              customerIds: this.selectedCustomers,
              dispatchType: "INSTANT",
            };
          }
          this.matSnackbar.open(this._i18nDynamicTranslate.transform("Sending offer", ['POS_PAGE']), "", {
            panelClass: ["white-snackbar"],
          });
          this.apiCall.launchOffer(data, this.templateID).subscribe(
            (response) => {
              this.apiCall
                .updatesurveyrecommendation(
                  this.surveyAlertID,
                  this.excludedCustomerList.length == 0 ? "PROCESSED" : "",
                  this.excludedCustomerList
                )
                .subscribe(
                  (response) => {
                    this.matSnackbar.dismiss();
                    this.popup.openPopup(this._i18nDynamicTranslate.transform("success", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Offer sent successfully", ['POS_PAGE']));
                    this.surveyAlerts["data"].splice(
                      this.surveyAlerts["data"].findIndex(
                        (x) => x.id === this.surveyAlertID
                      ),
                      1
                    );
                    this.cancelActionDiv();
                    this.getSurveyAlerts();
                  },
                  (err) => {
                    this.matSnackbar.dismiss();
                    this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send offer", ['POS_PAGE']));
                  }
                );
            },
            (err) => {
              this.matSnackbar.dismiss();
              this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send offer", ['POS_PAGE']));
            }
          );
        }
      } else {
        if (this.subject.length > 0) {
          if (this.templateName.length == 0) {
            this.selectTemplateError = true;
          } else {
            this.matSnackbar.open(this._i18nDynamicTranslate.transform("Sending email", ['POS_PAGE']), "", {
              panelClass: ["white-snackbar"],
            });
            this.apiCall.getEmailLayoutDetail(this.templateID).subscribe(
              (response) => {
                let emailContent = JSON.parse(response["body"]);
                // this.emailTemplates.push(template);
                let data = {
                  merchantId: this.tokenStorage.getMerchantId(),
                  subject: this.subject,
                  layoutHtml: emailContent["layoutHtml"],
                  layoutJson: emailContent["layoutJson"],
                  id: this.templateID,
                };
                let layoutId;
                this.apiCall.addEmailContent(data).subscribe(
                  (response) => {
                    layoutId = response["body"];
                    this.apiCall
                      .updateEmailRecommendation(
                        layoutId,
                        this.selectedCustomers
                      )
                      .subscribe((response) => {
                        this.apiCall
                          .updatesurveyrecommendation(
                            this.surveyAlertID,
                            this.excludedCustomerList.length == 0
                              ? "PROCESSED"
                              : "",
                            this.excludedCustomerList
                          )
                          .subscribe(
                            (response) => {
                              this.matSnackbar.dismiss();
                              this.popup.openPopup(
                                this._i18nDynamicTranslate.transform("success", ['POS_PAGE']),
                                this._i18nDynamicTranslate.transform("Email sent successfully", ['POS_PAGE'])
                              );
                              this.surveyAlerts["data"].splice(
                                this.surveyAlerts["data"].findIndex(
                                  (x) => x.id === this.surveyAlertID
                                ),
                                1
                              );
                              this.cancelActionDiv();
                              this.getSurveyAlerts();
                            },
                            (err) => {
                              this.matSnackbar.dismiss();
                              this.popup.openPopup(
                                this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']),
                                this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE'])
                              );
                            }
                          );
                      });
                  },
                  (err) => {
                    this.matSnackbar.dismiss();
                    this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE']));
                  }
                );
              },
              (err) => {
                this.matSnackbar.dismiss();
                this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE']));
              }
            );
          }
        } else {
          this.subjectError = true;
          let element = document.getElementById("email-subject");
          element.setAttribute("style", "border: 1px solid #811620");
        }
      }
    } else {
      this.customersError = true;
      let element = document.getElementById("customers-input");
      element.setAttribute("style", "border: 1px solid #811620");
    }
  }

  showPreview() {
    if (this.actionType === "offer") {
      this.previewOfferSummary(this.templateID);
    } else {
      this.previewEmail();
    }
  }

  loaded;
  selectedLayout;
  layoutName;

  previewEmail() {
    var id: any = this.templateID;
    if (!id) {
    } else {
      this.apiCall.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            this.loaded = true;
            let layout = JSON.parse(response["body"]);
            this.selectedLayout = layout["layoutHtml"];
            this.layoutName = layout["name"];
            var emailData: any = {};
            emailData.selectedLayout = this.selectedLayout;
            emailData.templateName = this.templateName;
            emailData.templateId = this.templateID;
            this.openDialog(emailData, "EMAIL");
          }
        },
        (err) => {}
      );
    }
  }

  openDialog(objectData: any, type: string): void {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;

    self.finalScreenWidth = self.scrWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    let sw = self.finalScreenWidth * (70 / 100);
    const dialogRef = self.dialog.open(PreviewTemplateComponent, {
      panelClass: "previewDialog",
      width: "70%",
      maxHeight: "90vh",
      height: sh + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      disableClose: true,
      autoFocus: false,
      data: { obj: objectData, type: type, height: self.finalScreenHeight},
    });

    dialogRef.afterClosed().subscribe((id) => {
      self.apiCall.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            let layout = JSON.parse(response["body"]);
            self.templateID = id;
            self.templateName = layout.name;
          }
        },
        (err) => {}
      );
    });
  }

  previewOfferSummary(id: any) {
    const dialogRefer = this.dialog.open(OfferSummaryDialogComponent, {
      panelClass: "customAttachEmail",
      width: "40%",
      maxWidth: "546px",
      maxHeight: "80vh",
      data: id,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  getCustomerDetails(index) {
    let data = [];
    if (this.leads[index]["productDetails"]["search_products"]) {
      for (
        let i = 0;
        i < this.leads[index]["productDetails"]["search_products"].length;
        i++
      ) {
        data.push({
          type: "Search",
          product: this.leads[index]["productDetails"]["search_products"][i],
        });
      }
    }
    if (this.leads[index]["productDetails"]["recentlyviewed_products"]) {
      for (
        let i = 0;
        i <
        this.leads[index]["productDetails"]["recentlyviewed_products"].length;
        i++
      ) {
        data.push({
          type: "View",
          product:
            this.leads[index]["productDetails"]["recentlyviewed_products"][i],
        });
      }
    }
    let finalData = {
      name: this.leads[index]["customerName"],
      data: data,
    };
    this.dialog.open(CustomerLeadsComponent, {
      data: finalData,
      panelClass: "leads-dialog",
      maxHeight: "400px",
      width: this.mobileMedia.matches ? "90%" : "600px",
    });
  }

  conditionalqueryChange(value) {
    const queryParams = { type: value };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      // queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  routeTo(route) {
    this.router.navigate([route]);
  }
  change(){
    this.FirstCheck=true;
    this.tokenStorage.setIsNewUser('partialTrue');
    window.open("https://calendly.com/ankita-52/30min");
  }
  getObjective(){
    this.tokenStorage.setIsNewUser('false')
    this.objectiveCheck=true;
  }
  // Demochange(){
  //   this.demoCheck=true;
  //   this.FirstCheck=true;
  // }
  bookdemoOpen=false;
  viewInfoOpen=false;
  helpCreateOpen=true;
  allcustomerObj = [{ id: "ALL_CUSTOMERS", name: "All Customers" }];
  HelpCreateData=[{name:"Campaign",image:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Campaign.jpg",url:"https://www.skellam.ai/grayfoxhelp/campaign-management"},
                  {name:"Gamify",image:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Gamify.jpg",url:"https://www.skellam.ai/grayfoxhelp/gamify"},
                  {name:"Offers",image:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Offers.jpg",url:"https://www.skellam.ai/grayfoxhelp/offer-screen"},
                  {name:"Surveys",image:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Surveys.jpg",url:"https://www.skellam.ai/grayfoxhelp/surveys-screen"},
                  {name:"Send SMS Content",image:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Send+SMS+Content.jpg",url:"https://www.skellam.ai/grayfoxhelp"},
                  {name:"Email Template",image:"https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Email+Template.jpg",url:"https://www.skellam.ai/grayfoxhelp/reach-out"}]
  openTab(x){
    switch(x){
      // case 'BookDemo':
      //   this.bookdemoOpen=!this.bookdemoOpen;
      //   console.log('bookdemo')
      //   break
      // case 'ViewInfo':
      //   this.viewInfoOpen=!this.viewInfoOpen;
      //   console.log('viewimfo')
      //   break
      case 'HelpCreate':
        this.helpCreateOpen=!this.helpCreateOpen;
        break
    }
  }
  isOverflown(element: HTMLElement) {
    return element.scrollWidth > element.clientWidth;
  }

  scroll(element: HTMLElement, direction: number) {
    element.scrollLeft += 1000 * direction;
  }

  canScrollStart(element: HTMLElement) {
    return element.scrollLeft > 0;
  }

  canScrollEnd(element: HTMLElement) {
    return Math.round(element.scrollLeft) + (element.clientWidth) != element.scrollWidth;
  }
  checkIfAccountCreated(){
    this.apiCall.checkWhatsAppOnboarded().subscribe((response)=>{
      this.tokenStorage.setIsAccountCreated(true);
    },
    (err)=>{
      this.tokenStorage.setIsAccountCreated(false);
    });
  }

  getOnboardingSteps(): Promise<OnboardingStatus> {
    return new Promise((resolve, reject) => {
      this.isOnboardingCheckApiLoaded=false;
      this.apiCall.getOnboardingCheck(this.tokenStorage.getUserId()).subscribe(
        (response) => {
          console.log('Status Code:', response);
          console.log(response["body"]?.["onboardingStepsResponse"]["stepsCompleted"]);
          // if(response["body"]?.["data"]?.["stepsCompleted"]!=null){
          //   resolve(response["body"]?.["data"]?.["stepsCompleted"]);
          // }        
        //  this.isOnboardingStepsCompleted=response["body"]?.["onboardingStepsResponse"]["stepsCompleted"];
          this.onboardingSteps=response["body"]?.["onboardingStepsResponse"]["data"];
          this.isOnboardingCheckApiLoaded=true;
          if(this.onboardingSteps!=null &&this.onboardingSteps["planAdditionCompleted"]){
            resolve(OnboardingStatus.PLAN_ADDITION);
          }
          if(this.onboardingSteps!=null &&this.onboardingSteps["brandingAdditionCompleted"]){
            resolve(OnboardingStatus.BRAND_ADDITION);
          }
          if(this.onboardingSteps!=null &&this.onboardingSteps["storeAdditionCompleted"]){
            resolve(OnboardingStatus.STORE_ADDITION);
          }
        },
        (error) => {
          console.log('Error:', error);
          let snackBarRef = this.snackbar.openSnackBar(
            "Something went wrong",
2000
          );
          resolve(OnboardingStatus.ERROR);
        }
      );
    });
  }
  navigateTo(page) {
    if ("changeplan" == page) {
      if(this.tokenStorage.getMerchantOnlineStore() === 'shopify')
      this.router.navigate(["/app/payments/payment-plan"]);
      else
      window.open('https://qubriux.skellam.ai/pricing/qubriux-ecommerce', "_blank");
    }
    if ("topup" == page) {
      this.router.navigate(["/app/payments/top-up"]);
    }
    if("topupPos" == page){
      this.router.navigate(["/app/payments/top-up-pos"]);
    }
    if("changeplanPos" == page){
      this.router.navigate(["/app/payments/payment-plan-pos"]);
    }
  }
  checkAndCallCacheApi() {
    if(window.localStorage.getItem('summarizeStatsCalled')!='done') {
      this.apiCall.summarizeStats().subscribe(
        (res) => {
          console.log(res['body']);
          window.localStorage.setItem('summarizeStatsCalled', 'done');
        },
        (err) => {
          console.log(err.error);
        }
      );
    }
  }
}
