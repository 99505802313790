import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CreateNewCustomerListComponent, CustomerListViewComponent } from "./pages";
import { WidgetRouteGaurdService } from "src/app/core/services/widget-route-gaurd.service";
const routes: Routes = [
  // { path: "", redirectTo: "consent-recieved", pathMatch: "full" },
  {
    path: "",
    component: CustomerListViewComponent,
    data: {
      titleheading: "Custom List",
      title: "Custom List",
      minPath: "Basic",
    },
  },
  {
    path: "employee",
    component: CustomerListViewComponent,
    canActivate: [WidgetRouteGaurdService],
    data: {
      titleheading: "Employee List",
      title: "Employee List",
      minPath: "Basic",
      id: 54
    },
  },
  {
    path: "employee/create-employee-list",
    component: CreateNewCustomerListComponent,
    canActivate: [WidgetRouteGaurdService],
    data: {
      titleheading: "Create Employee List",
      title: "Create Employee List",
      minPath: "Basic",
      id: 54
    },
  },
  {
    path: "create-customer-list",
    component: CreateNewCustomerListComponent,
    data: {
      titleheading: "Create Custom List",
      title: "Create Custom List",
      minPath: "Basic",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerListRoutingModule {}
