import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SendTestEmailDialogComponent } from "../send-test-email-dialog/send-test-email-dialog.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-html-data-dialog",
  templateUrl: "./html-data-dialog.component.html",
  styleUrls: ["./html-data-dialog.component.css"],
})
export class HtmlDataDialogComponent implements OnInit {
  htmlrend;
  subject;
  loading:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<HtmlDataDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    this.loading=true;
    this.apiCall.getEmailContent(this.data.id).subscribe((response) => {
      this.loading=false;
      if (response["message"] === "success" && response['body'] !== 'null') {
        let layout = JSON.parse(response["body"]);
        this.htmlrend = layout["layoutHtml"];
        this.subject =layout["subject"]
      }
      else{
        this.apiCall
        .getRewardEmailDetail(this.data.id)
        .subscribe((response) => {
          let layout = JSON.parse(response["body"]);
          this.htmlrend = layout["layoutHtml"];
          this.subject =layout["subject"]
        }),
        (err) => {};
      }
    });
  }

  openSendTestEmailDialog() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "50%"
    });

    dialogRef.afterClosed().subscribe(response => {
      if(response != false) {
      let email = response;
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Sending test email", ['SHARED_PAGE']), 2000);
      let data =
      {"type": "OFFER",
      "channel": "EMAIL",
        "templateID": this.data.templateId,
      "phone": null,
      "isReward": true,
      "rewardType": this.data.rewardType
      }
      this.apiCall.sendTestEngageEmailHTML(data.type, data.channel, data.templateID, email, data.phone, data.isReward, data.rewardType, this.data.emailLayouHtml).subscribe(response => {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform('Test email sent', ['SHARED_PAGE']), 2000);
      },
      (err) => {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to send test email", ['SHARED_PAGE']), 2000);
    });
      }
  });
  }

  submit(decision) {
    this.dialogRef.close(decision)
  }
}
