import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirsttimeGameComponent, GamificationComponent, GamifyListingFilterComponent, NegotiatorAnalyticsComponent, SetGameConfigurationComponent, SingleGameAnalyticsComponent } from './pages';
import { GamifyRoutingModule } from './gamify-routing.module';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatChipsModule } from '@angular/material/chips';



@NgModule({
  declarations: [
    GamificationComponent,
    FirsttimeGameComponent,
    SingleGameAnalyticsComponent,
    GamifyListingFilterComponent,
    NegotiatorAnalyticsComponent,
    SetGameConfigurationComponent,
  ],
  imports: [
    GamifyRoutingModule, 
    SharedModule,
    MatOptionModule,
    MatSelectModule,
    NgbPopoverModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatChipsModule
  ]
})
export class GamifyModule { }
