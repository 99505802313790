import { Component, ElementRef, HostListener, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { ContactUsDialogComponent, DeleteGameDialogBoxComponent, NotDeletedListDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { AddNewCustomerInListDialogBoxComponent } from 'src/app/shared/components/dialog-box/add-new-customer-in-list-dialog-box/add-new-customer-in-list-dialog-box.component';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { CustomerProfileComponent } from '../../../customers/pages';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-customer-list-data-view',
  templateUrl: './customer-list-data-view.component.html',
  styleUrls: ['./customer-list-data-view.component.css']
})
export class CustomerListDataViewComponent implements OnInit {
  @ViewChild("paginator") paginator: MatPaginator;

  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private date_format: getdateformatService,
    private _dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private tokenService: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router,

  ) { }
  @HostListener("document:click", ["$event"])
  closeSegmentsDiv() {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
  }
  selected = "Purchase Frequency";
  validWidgets = null;
  appSubscriptionPlan;
  revenueSortBy = "All-Time";
  numberOfOrdersSortBy = "All-Time";
  revenueTime = "ALL";
  numberOfOrdersTime = "ALL";
  pageSize = 10;
  pageIndex = 1;
  customers = [];
  popupElementID;
  totalCustomers;
  storeCustomerCount;
  historyFlag: Boolean = false;
  sortBy = "";
  filters = {
    segments: [],
  };
  showCustomers = "all";
  searchValue = "";
  filterSegments = [];
  selectedSortBy = "Desc";
  timeout;
  loading = true;
  sub: Subscription;
  revCurrency;
  dataAvailable: Boolean = null;
  @Input() customerId;
  @Input() listType;
  customerListName;
  employeeDesignation;
  previousState="";
  isAdmin : boolean = true; 
  @ViewChild("search") searchElement: ElementRef;
  @Output() customerDataFromChild = new EventEmitter<any>();
  dialogPosition: DialogPosition = {
    top: "0px",
    right: "0px",
  };

  ngOnInit(): void {
    this.getWidgets();
    if (!this.customerId) {
      this.route.queryParams.subscribe(params => {
        this.customerId = params['id'];
        this.historyFlag = true;
        this.listType = params['type'];
      });
    }
    if(this.listType === "customerList")
      this.getCustomers(this.customerId);
    else
      this.getEmployees();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    if(this.tokenService.getqId() != 'null'){
      this.isAdmin = false;
    }
  }
  ngOnChanges() {
    if (this.customerId) {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.searchValue = "";
      this.searchElement.nativeElement.value = "";
      if(this.listType === "customerList")
        this.getCustomers(this.customerId);
      else
        this.getEmployees();
    }
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  getCustomers(id){
    this.loading = true;
    this.getParticularCustomerListApi().then((result) => {
      this.loading =  false;
      if(result){
        this.customers =  result['body']['customerDetails'];
        this.totalCustomers = result['body']['customerCount'];
        this.storeCustomerCount = result['body']['storeCustomerCount']
        this.customerListName = result['body']['customerListName'];
        this.dataAvailable = result['body']['isDataAvailable'];
        this.customers.forEach((customer)=>{
          if(customer['customerSegments'] && customer['customerSegments'].length>0) {
            customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
          } else {
            customer.segments = [];
          }
        });
      }
    });
  }

  getEmployees(){
    this.loading = true;
    this.getParticularEmployeeListApi().then((result) => {
      this.loading =  false;
      if(result){
        this.customers =  result['body']['customerDetails'];
        this.totalCustomers = result['body']['customerCount'];
        this.customerListName = result['body']['customerListName'];
        this.employeeDesignation = result['body']['employeeDesignationName'];
        this.dataAvailable = result['body']['isDataAvailable'];
        this.customers.forEach((customer)=>{
          if(customer['customerSegments'] && customer['customerSegments'].length>0) {
            customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
          } else {
            customer.segments = [];
          }
        });
      }
    });
  }

  searchCustomers(searchValue, resetPagination){
    this.loading = true;
      if (resetPagination) this.pageIndex = 1;
      if(searchValue !== ""){
        this.searchCustomerListDetailApi(searchValue).then((result)=>{
          this.customers =  result['body']['customerData'];
          this.totalCustomers = result['body']['totalCount'];
          this.searchValue = searchValue;
          this.customers.forEach((customer)=>{
            if(customer['customerSegments'] && customer['customerSegments'].length>0) {
              customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
            } else {
              customer.segments = [];
            }
          });
          this.loading = false;
        });
      }
      if (searchValue === "") this.getCustomers(this.customerId);
  }

  searchEmployee(searchValue, resetPagination){
    this.loading = true;
      if (resetPagination) this.pageIndex = 1;
      if(searchValue !== ""){
        this.searchEmployeeListDetailApi(searchValue).then((result)=>{
          this.customers =  result['body']['customerData'];
          this.totalCustomers = result['body']['totalCount'];
          this.searchValue = searchValue;
          this.customers.forEach((customer)=>{
            if(customer['customerSegments'] && customer['customerSegments'].length>0) {
              customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
            } else {
              customer.segments = [];
            }
          });
          this.loading = false;
        });
      }
      if (searchValue === "") this.getEmployees();
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(this.searchValue.length === 0){
      if(this.listType === "customerList")
        this.getCustomers(this.customerId);
      else
        this.getEmployees();
    }
    else{
      if(this.listType === "customerList")
        this.searchCustomers(this.searchValue, false);
      else
        this.searchEmployee(this.searchValue, false);
    }
    // if (this.showCustomers === "all") this.getCustomers();
    // else this.searchCustomer(this.searchValue, false);
  }
  showSegments(event, i) {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
    let id = event.srcElement.parentElement.id;
    event.stopPropagation();
    let element = document
      .getElementById(id)
      .getElementsByClassName("qb-segment-popup")[0];
    element.setAttribute("style", "display:flex");
    this.popupElementID = "popup" + i;
  }
  onKeySearch(event: any, resetPagination) {
    clearTimeout(this.timeout);
    var $this = this;
    let searchValue = event.trim();
    if(searchValue.length !==0){
      this.timeout = setTimeout(function () {
        if($this.listType === "customerList")
          $this.searchCustomers(searchValue, resetPagination);
        else
        $this.searchEmployee(searchValue, resetPagination); 
      }, 600);
    }
    else if ((this.previousState!=searchValue) && searchValue.length === 0) {//if search value is 0 and to handle only search with spaces
      if(this.listType === "customerList")
        this.getCustomers(this.customerId);
      else
        this.getEmployees();
    }
    this.previousState =searchValue
  }
  tableSortBy(sortFor) {
    switch (sortFor) {
      case "revenue":
        if (this.revenueSortBy === "All-Time") this.revenueTime = "ALL";
        else this.revenueTime = "30";
        break;
      case "numberOfOrders":
        if (this.numberOfOrdersSortBy === "All-Time")
          this.numberOfOrdersTime = "ALL";
        else this.numberOfOrdersTime = "30";
    }
    this.changeSortByValue();
    if (this.searchValue.length > 0){

    }
      // this.searchCustomer(this.searchValue, false);
    else {
      // this.getCustomers();
    }
  }
  changeSortByValue() {
    if (this.selected == "Purchase Frequency") this.sortBy = "";
    else if (this.selected == "Number of Orders")
      this.sortBy = this.numberOfOrdersSortBy;
    else this.sortBy = this.revenueSortBy;
    this.sort(this.selectedSortBy);
  }
  reOrder() {
    if (this.selectedSortBy === "Desc") {
      this.sort("Asc");
    } else {
      this.sort("Desc");
    }
  }

  sort(sortBy) {
    this.selectedSortBy = sortBy;
    switch (sortBy) {
      case "Asc":
        if (this.selected == "Purchase Frequency")
          this.customers.sort((a, b) =>
            a.purchaseFrequency < b.purchaseFrequency ? -1 : 1
          );
        else if (this.selected == "Number of Orders")
          this.customers.sort((a, b) =>
            a.orderQuantity < b.orderQuantity ? -1 : 1
          );
        else this.customers.sort((a, b) => (a.revenue < b.revenue ? -1 : 1));
        break;
      case "Desc":
        if (this.selected == "Purchase Frequency")
          this.customers.sort((a, b) =>
            a.purchaseFrequency > b.purchaseFrequency ? -1 : 1
          );
        else if (this.selected == "Number of Orders")
          this.customers.sort((a, b) =>
            a.orderQuantity > b.orderQuantity ? -1 : 1
          );
        else this.customers.sort((a, b) => (a.revenue > b.revenue ? -1 : 1));
    }
  }
  deleteCustomer(id, name){
    const dialogRef = this._dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "200px",
      data: {
        subject: "Remove Customer",
        "data-item": id,
        "customer-name": name,
        isEmployee: false,
        successButtonText: "Remove",
        cancelButtonText: "Cancel",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let data :any = {};
        data.customerListName = this.customerListName;
        data.customerListId = this.customerId;
        data.merchantId = this.tokenService.getMerchantId();
        data.customerIds = [id];
        data.operation = "delete";
        this.deleteCustomerApi(data).then((result) => {
          this.loading =  false;
          if(result){
            console.log(result);
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Customer removed from list successfully", ['POS_PAGE']), 2000);
            this.getCustomers(this.customerId);
          }
        });
      } else {
        
      }
    });
  }

  deleteEmployee(id, name){
    const dialogRef = this._dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "200px",
      data: {
        subject: "Remove Employee",
        "data-item": id,
        "customer-name": name,
        isEmployee: true,
        successButtonText: "Remove",
        cancelButtonText: "Cancel",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let data :any = {};
        data.customerListName = this.customerListName;
        data.employeeListId = this.customerId;
        data.merchantId = this.tokenService.getMerchantId();
        data.customerIds = [id];
        data.operation = "delete";
        this.deleteEmployeeApi(data).then((result) => {
          this.loading =  false;
          if(result){
            console.log(result);
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Employee removed from list successfully", ['POS_PAGE']), 2000);
            this.getEmployees();
          }
        });
      } else {
        
      }
    });
  }

  deleteCustomerList(){
    const dialogRef = this._dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "180px",
      data: {
        subject: "Delete Custom List",
        "data-item": this.customerId,
        "customer-list-name": this.customerListName,
        successButtonText: "Delete",
        cancelButtonText: "Cancel",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteCustomerListApi(this.customerId).then((res) => {
          this.loading =  false;
          if(res){
            console.log(res);
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Custom list successfully deleted", ['POS_PAGE']), 2000);
            let event = {
              id: parseFloat(this.customerId),
              type: 'delete'
            }
            this.customerDataFromChild.emit(event);
          }
          else {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']), 2000);
          }
        }, (err)=>{
          console.log(JSON.parse(err));
          let errtext = JSON.parse(err);
          this.openErrorDialog(errtext['entityMap']);
      });
      }
    });
  }

  deleteEmployeeList(){
    const dialogRef = this._dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "180px",
      data: {
        subject: "Delete Employee List",
        "data-item": this.customerId,
        "customer-list-name": this.customerListName,
        successButtonText: "Delete",
        cancelButtonText: "Cancel",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteEmployeeListApi(this.customerId).then((res) => {
          this.loading =  false;
          if(res){
            console.log(res);
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Employee list successfully deleted", ['POS_PAGE']), 2000);
            let event = {
              id: parseFloat(this.customerId),
              type: 'delete'
            }
            this.customerDataFromChild.emit(event);
          }
          else {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']), 2000);
          }
        }, (err)=>{
          console.log(JSON.parse(err));
          let errtext = JSON.parse(err);
          this.openErrorDialog(errtext['detailMessage']);
      });
      }
    });
  }

  getParticularCustomerListApi(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerListDetail(this.customerId, this.pageIndex, this.pageSize).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          this.loading = false;
          reject(false);
        }
      );
    });
  }

  getParticularEmployeeListApi(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getEmployeeListDetail(this.customerId, this.pageIndex, this.pageSize).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          this.loading = false;
          reject(false);
        }
      );
    });
  }
  

  openErrorDialog(err) {
    
    const dialogRefDeleted = this._dialog.open(NotDeletedListDialogBoxComponent, {
      panelClass: "customer-dialog-popup",
      width: "540px",
      disableClose: false,
      data:{
        type: "CustomList",
        heading: "OOPS!",
        entityMap: err,
      },
    });
  }

  searchCustomerListDetailApi(searchValue): Promise<object>{
    return new Promise((resolve, reject) => {
      this.apiCall.searchCustomerListDetail(this.customerId, this.pageIndex, this.pageSize, searchValue).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  searchEmployeeListDetailApi(searchValue): Promise<object>{
    return new Promise((resolve, reject) => {
      this.apiCall.searchEmployeeListDetail(this.customerId, this.pageIndex, this.pageSize, searchValue).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  deleteCustomerApi(id): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.deleteCustomersFromList(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err);
        }
      );
    });
  }

  deleteEmployeeApi(id): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.deleteEmployeeFromList(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err);
        }
      );
    });
  }

  deleteCustomerListApi(id): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.deleteCustomerList(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  deleteEmployeeListApi(id): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.deleteEmployeeList(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  addCustomerToList(){
    const dialogRef = this._dialog.open(AddNewCustomerInListDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "85%",
      height: "90%",
      data: {
        subject: "Add Customer to List",
        isEmployeeList: false,
        submessage: "Already added customers will not be seen in list",
        "data-item": this.customerId,
        "customer-list-name": this.customerListName,
        customerIds: this.getCustomerIds(this.customers),
        successButtonText: "Add",
        cancelButtonText: "Cancel",
        customers: this.customers,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Custom list updated successfully", ['POS_PAGE']), 2000);
        let event = {
          id: this.customerId,
          type: 'update'
        }
        this.getCustomers(this.customerId);
        this.customerDataFromChild.emit(event);
      } else {
        
      }
    });
  }

  addEmployeeToList(){
    const dialogRef = this._dialog.open(AddNewCustomerInListDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "85%",
      height: "90%",
      data: {
        subject: "Add Employee to List",
        isEmployeeList: true,
        submessage: "Already added employees will not be seen in list",
        "data-item": this.customerId,
        "customer-list-name": this.customerListName,
        customerIds: this.getCustomerIds(this.customers),
        successButtonText: "Add",
        cancelButtonText: "Cancel",
        customers: this.customers,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Employee list updated successfully", ['POS_PAGE']), 2000);
        let event = {
          id: this.customerId,
          type: 'update'
        }
        this.getEmployees();
        this.customerDataFromChild.emit(event);
      } else {
        
      }
    });
  }

  getCustomerIds(data){
    let result :any =[];
    data.forEach((element)=>{
      result.push(element.merchantCustomerId);
    });
    console.log(result);
    return result;
  }

  openCustomerDetailsDialog(customer): void {
    this._dialog.open(CustomerProfileComponent, {
      data: {
        customer: customer,
      },
      height: "100%",
      position: this.dialogPosition,
      panelClass: "customer-profile-dialog-container",
    });
  }

  getIfDataAvailable(){
    if(this.dataAvailable === null && this.totalCustomers === 0){
      return false;
    }
    else if(this.dataAvailable === true && this.totalCustomers === 0){
      return true;
    }
    else if(this.dataAvailable === false && this.totalCustomers === 0){
      return true;
    }
  }

  back(){
    this.router.navigate(["app/customers/upload-history"], {});
  }

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

}
