import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { WidgetConfigService } from './widget-config.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetRouteGaurdService implements CanActivate {

  widgets = null;

  constructor(private _router: Router,
    private _widgetConfigService: WidgetConfigService) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    this.widgets = await this._widgetConfigService.getScreen();
    let index = this.widgets.findIndex(d => d === route.data.id)
    if (index > -1) {
      return true;
    }
    else {
      this._router.navigate(["/app/app-home"], {
        queryParams: { type: "Objectives" },
      });
      return false;
    }
  }
}
