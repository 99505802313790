import STATIC_PAGE from "./static.json";
import SHARED_PAGE from "./shared.json";
import POS_PAGE from "./pos.json";
import ANALYZE_PAGE from "./analyze.json";
import ACQUIRE_PAGE from "./acquire.json";
import CUSTOMER_PAGE from "./customer.json";
import COMMUNICATION_PAGE from "./communication.json";
import PROFILE_PAGE from "./profile.json";
import SURVEY_PAGE from "./survey.json";
import SEGMENT_PAGE from "./segment.json";
import PARTNERSHIP_PAGE from "./partnership.json";
import OFFER_PAGE from "./offer.json";
import GAMIFY_PAGE from "./gamify.json";
import CAMPAIGN_PAGE from "./campaign.json";
import COMMON_PAGE from "./common.json";
import REGISTRATION_PAGE from "./registration.json";
 
const ES = {
    STATIC_PAGE : STATIC_PAGE,
    SHARED_PAGE : SHARED_PAGE,
    POS_PAGE : POS_PAGE,
    ANALYZE_PAGE : ANALYZE_PAGE,
    ACQUIRE_PAGE : ACQUIRE_PAGE,
    CUSTOMER_PAGE : CUSTOMER_PAGE,
    COMMUNICATION_PAGE : COMMUNICATION_PAGE,
    PROFILE_PAGE : PROFILE_PAGE,
    SURVEY_PAGE : SURVEY_PAGE,
    SEGMENT_PAGE : SEGMENT_PAGE,
    PARTNERSHIP_PAGE : PARTNERSHIP_PAGE,
    OFFER_PAGE : OFFER_PAGE,
    GAMIFY_PAGE : GAMIFY_PAGE,
    CAMPAIGN_PAGE : CAMPAIGN_PAGE,
    COMMON_PAGE : COMMON_PAGE,
    REGISTRATION_PAGE : REGISTRATION_PAGE
}

export default ES;