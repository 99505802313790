<div style="display: flex;width: 100vw;height: 100vh;justify-content: center;align-items: center;">

  <div style="display: flex;justify-content: center;width: 100%;height: 40%;">
    <div style="display: flex;flex-direction: column;    justify-content: center;
    align-items: center;">
      <div class="NFText">
        {{'STATIC_PAGE.REALLY_YOU' | i18nTranslate}}
      </div>
<div style="display: flex;
justify-content: center;">
  <img [src]="imgUrl" alt="Page not found " class="notFoundImage">

</div>    </div>
  </div>

</div>