import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
interface TargetingData{
  'subject':string
  // 'message':string
}
@Component({
  selector: 'app-targeting-dialog',
  templateUrl: './targeting-dialog.component.html',
  styleUrls: ['./targeting-dialog.component.css']
})
export class TargetingDialogComponent implements OnInit {

  constructor(public dialog: MatDialogRef<TargetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:TargetingData,) { }

  ngOnInit() {
  }
  submit(decision){
    this.dialog.close(decision)
  }

}
