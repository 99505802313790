import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getLogoUrlService } from "src/app/core/services/get-logo-url.service";
import { Globals } from "src/app/core/services/global.service";
import { SignoutService } from "src/app/core/services/signout.service";

@Component({
  selector: "app-select-user-page",
  templateUrl: "./select-user-page.component.html",
  styleUrls: ["./select-user-page.component.css"],
})
export class SelectUserPageComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private route: Router,
    private tokenStorage: TokenStorageService,
    private _signoutService: SignoutService,
    private globalService: Globals,
    private getLogoService: getLogoUrlService
  ) {}

  userName = "";
  showLogout = false;
  userData = [];
  selectedStore = {};
  showPage = false;
  searchStoreName = "";
  logoUrl = this.getLogoService.getLogo();

  ngOnInit(): void {
    this.userName = this.tokenStorage.getUsername();
    this.sendStoreList().then(
      (result) => {
        if (result) {
          console.log("result", result);
          this.userData = result["body"];
          this.selectedStore = result["body"][0];
          if (
            this.userData.length === 1 ||
            (this.userData.length === 2 &&
              result["body"][0]["storeName"] === "ADMIN")
          ) {
            this.logIn();
          } else {
            this.showPage = true;
          }
          // if (result["body"][0]["storeName"] === "ADMIN") {
          //   this.route.navigate(["/select-store-type"]);
          // } else {

          // }
        } else {
          // this.snackBar.openSnackBar("Invalid OTP",2000);
        }
      },
      (err) => {
        this.logout();
      }
    );
  }
  sendStoreList(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getListStore().subscribe(
        (res) => {
          console.log(res);

          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(false);
        }
      );
    });
  }
  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.route.navigate(["auth/login"], {});
  }
  radioNodeChange(event) {
    console.log(event);
    console.log(this.selectedStore);
  }

  logIn() {
    console.log(this.selectedStore);
    if (this.selectedStore["storeName"] === "ADMIN") {
      this.tokenStorage.saveqId(this.selectedStore["qid"]);
      this.tokenStorage.saveUploadStoreId(
        this.userData.length > 1 ? this.userData[1]["storeId"] : null
      );
      sessionStorage.setItem("storeName", this.selectedStore["storeName"]);
      this.tokenStorage.saveStoreName(this.selectedStore["storeName"]);
      this.tokenStorage.setMerchantTimeZone(null);
      this.globalService.passQueryValue(false, null);
      this.route.navigate(["/data-ingestion"], {});
    } else {
      this.tokenStorage.saveqId(this.selectedStore["qid"]);
      this.tokenStorage.setMerchantTimeZone(this.selectedStore["timezone"]);
      this.tokenStorage.saveUploadStoreId(this.selectedStore["storeId"]);
      sessionStorage.setItem("storeName", this.selectedStore["storeName"]);
      this.tokenStorage.saveStoreName(this.selectedStore["storeName"]);
      this.globalService.passQueryValue(false, null);
      this.route.navigate(["/data-ingestion"], {});
      // this.selectStore().then((data) => {
      //   let response = data["body"];
      //   this.tokenStorage.saveToken(response.accessToken);
      //   this.globalService.passQueryValue(false, null);
      //   this.route.navigate(["/data-ingestion"], {});
      // });
    }
  }
  selectStore(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.selectStore().subscribe(
        (res) => {
          console.log(res);

          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }
}
