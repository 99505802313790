<div style="padding: 12px; height: 400px">
  <div class="leads-activity-header-container">
    <div class="leads-activity-header-label">
      <span class="header-class">{{'SHARED_PAGE.RECENT_ACTIVITY' | i18nTranslate}}</span>
      <span class="header-class leads-activity-header-seperator">-</span>
      <span class="name-content">{{ data["name"] }}</span>
    </div>
    <span (click)="closeDialog()" class="material-icons lead-dialog-close-icon">
      clear
    </span>
  </div>
  <div class="leads-activity-dialog-table-header">
    <div style="display: flex; justify-content: space-between">
      <div style="width: 59%; padding: 7px; border-right: 2px solid white">
        <span class="list-header">{{'SHARED_PAGE.PRODUCT_NAME' | i18nTranslate}}</span>
      </div>
      <div style="width: 21%; padding: 7px; border-right: 2px solid white">
        <span class="list-header">{{'SHARED_PAGE.ACTIVITY_TYPE' | i18nTranslate}}</span>
      </div>
      <div style="width: 20%; padding: 7px; border-right: 2px solid white">
        <span class="list-header">{{'SHARED_PAGE.DATE' | i18nTranslate}}</span>
      </div>
    </div>
  </div>
  <div style="height: calc(100% - 75px); overflow: overlay">
    <div>
      <div *ngFor="let data of data['data']; index as i">
        <div class="leads-activity-content-row">
          <div class="leads-activity-content-row-name">
            <span
              matTooltipShowDelay="500"
              matTooltip="{{ data['product']['productTitle'] }}"
              >{{ data["product"]["productTitle"] }}</span
            >
          </div>
          <div class="leads-activity-content-row-type-date">
            <div class="leads-activity-content-row-type">
              <span>{{ data["type"] }}</span>
            </div>
            <div class="leads-activity-content-row-date">
              <span
                ><span>{{
                  data["product"]["publishedDate"].substring(0, 10)
                    | date: getDateFormat("dd MMM yyyy")
                }}</span>
                <span>{{
                  data["product"]["publishedDate"].substring(10, 16)
                }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
