<div class="qb-customer-list-card">
    <div class="qb-table-header" *ngIf="(validWidgets | widgetConfig: 7) || (validWidgets | widgetConfig: 72)" data-widget-name="CUSTOMER LIST">
        <div class="header-element" style="display:flex; align-items: center;">
          <div class="material-symbols-outlined back-icon" *ngIf="historyFlag"  (click)="back()" matTooltip="back">
            arrow_back_ios
            </div>
        <div *ngIf="isAdmin || listType=='employeeList'" class="qb-customer-list">
            <span>{{ customerListName}}</span><span>&nbsp;:- {{totalCustomers}}</span>
        </div>
        <div *ngIf="!isAdmin && listType=='customerList'" class="qb-customer-list-store">
          <span class="qb-customer-list-store">{{ customerListName}}</span>
          <span class="qb-customer-list-store-count">Total customer count : {{totalCustomers}} </span>
          <span class="qb-customer-list-store-count">Store customer count : {{storeCustomerCount}}</span>
      </div>
        </div>
        <div class="qb-d-flex" style="gap: 7px;align-items: center;">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" *ngIf="!historyFlag" (click)="listType === 'customerList' ? addCustomerToList() : addEmployeeToList()" [matTooltip]="listType === 'customerList'?'Add customers':'Add Employees'">
              <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM8.25 10.75V8.75H6.25C5.8125 8.75 5.5 8.4375 5.5 8C5.5 7.59375 5.8125 7.25 6.25 7.25H8.25V5.25C8.25 4.84375 8.5625 4.5 9 4.5C9.40625 4.5 9.75 4.84375 9.75 5.25V7.25H11.75C12.1562 7.25 12.5 7.59375 12.5 8C12.5 8.4375 12.1562 8.75 11.75 8.75H9.75V10.75C9.75 11.1875 9.40625 11.5 9 11.5C8.5625 11.5 8.25 11.1875 8.25 10.75Z" fill="#4379EF"/>
            </svg>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" *ngIf="!historyFlag" (click)="listType === 'customerList' ? deleteCustomerList() : deleteEmployeeList()">
              <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#4379EF"/>
            </svg>           
            <div class="qb-search-bar-div">
              <div class="qb-search-bar">
                  <input type="text" #search class="qb-search-input" placeholder="Search by Customer Name"
                      (input)="onKeySearch(search.value, true)" #searchElement />
                  <span class="material-icons icons-primary qb-fs-19" *ngIf="search.value.length==0"> search </span>
                  <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
                      *ngIf="search.value.length>0" (click)="search.value='';onKeySearch(search.value, true);searchValue = '';">
                      close
                  </span>
              </div>
            </div>
        </div>
    </div>

    <div class="main-list-container-3 pos-rel" *ngIf="validWidgets" [ngClass]="{'main-white-panel':historyFlag}">
      <div class="list-column-header-row-2" #headerContainer>
          <div class="col-header">
              Name
          </div>
          <div class="col-header wide-column-width">
              Email
          </div>
          <div class="col-header medium-col-width">
              Phone
          </div>
          <div class="col-header medium-column-width">
              Customer Since
          </div>
          <div class="col-header">
            Segment
          </div>
          <div class="col-header" *ngIf="!(validWidgets | widgetConfig: 72)">
            Store
          </div>
          <div class="col-header ex-narrow-column-width"  *ngIf="!historyFlag">
            Actions
          </div>
      </div>
      <div class="main-list-2" #contentContainer>
          <div class="list-loader-container" *ngIf="loading">
              <app-qubriux-loading  [size]="65" [loadingText]="'Fetching your customers...'"></app-qubriux-loading>
          </div>
          <div *ngIf="!loading&& !getIfDataAvailable()  && customers.length == 0 && listType === 'customerList'" class="empty-list-indicator">
              No Customers Found
          </div>
          <div *ngIf="!loading&& getIfDataAvailable()  && customers.length == 0 && listType === 'customerList'" class="empty-list-indicator">
            Your data will be available shortly
        </div>
          <div *ngIf="!loading && customers.length == 0 && listType !== 'customerList'" class="empty-list-indicator">
            No Employee Found
        </div>
          <div *ngIf="!loading && customers.length > 0">
              <div *ngFor="let customer of customers" class="list-item-row-2">
                  <div class="list-item">
                    <div class="list-item-content" [matTooltip]="customer.customerName">{{ customer["customerName"] || '----'}}</div>
                  </div>
                  <div class="list-item">
                    <div class="list-item-content" [matTooltip]="customer.email">{{ customer["email"] || '----'}}</div>
                  </div>
                  <div class="list-item medium-col-width">
                    <div class="list-item-content" [matTooltip]="customer.phone">{{ customer["phone"] || '----'}}</div>
                  </div>
                  <div class="list-item medium-column-width">
                    <div class="list-item-content">{{ getDateInFormatForDisplay(customer["customerSince"]) || '----'}}</div>
                  </div>
                  <div class="list-item">
                    <div *ngIf="customer['segments'].length==0;else segmentList">
                      ----
                    </div>
                    <ng-template #segmentList>
                      <div class="mini-list-item" [matTooltip]="customer['segments'][0]">{{customer['segments'][0]}}</div>
                      <div *ngIf="customer['segments']?.length>1" [matMenuTriggerFor]="menu" class="see-more" (click)="$event.stopPropagation()">See All {{customer['segments'].length}} segments<span class="material-symbols-outlined">keyboard_double_arrow_right</span></div>
                      <mat-menu #menu="matMenu"  class="custom-menu">
                        <div *ngFor="let segmentName of customer['segments']" class="mini-list-item" [matTooltip]="segmentName">
                          {{segmentName}}
                        </div>
                      </mat-menu>
                    </ng-template>
                  </div>
                  <div class="list-item" *ngIf="!(validWidgets | widgetConfig: 72)">
                    <div *ngIf="!customer['stores'] || customer['stores']?.length==0;else storeList">
                      ----
                    </div>
                    <ng-template #storeList>
                      <div class="mini-list-item" [matTooltip]="customer['stores'][0]">{{customer['stores'][0]}}</div>
                      <div *ngIf="customer['stores']?.length>1" [matMenuTriggerFor]="menu" class="see-more" (click)="$event.stopPropagation()">See All {{customer['stores'].length}} stores<span class="material-symbols-outlined">keyboard_double_arrow_right</span></div>
                      <mat-menu #menu="matMenu"  class="custom-menu">
                        <div *ngFor="let storeName of customer['stores']" class="mini-list-item" [matTooltip]="storeName">
                          {{storeName}}
                        </div>
                      </mat-menu>
                    </ng-template>
                  </div>
                  <div class="list-item ex-narrow-column-width action-icon-container"  *ngIf="!historyFlag">
                    <svg 
                      (click)="listType === 'customerList' ?  deleteCustomer(customer['merchantCustomerId'], customer['customerName']) : deleteEmployee(customer['merchantCustomerId'], customer['customerName'])"
                      [matTooltip]="listType === 'customerList'?'Remove Customer':'Remove Employee'"
                       width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer">
                      <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#4379EF"/>
                    </svg> 
                </div>
              </div>
          </div>
      </div> 
      <div class="lv-options-2">
          <mat-paginator
            [pageIndex]="pageIndex - 1"
            [length]="totalCustomers"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="refreshPaginatedData($event)"
          >
          </mat-paginator>
      </div>
    </div>

</div>