<!--

<div class="font-class" style="display:flex; overflow: hidden; border-top: 1px solid #FAFAFA; background: #FFF;" [style.height.px]="finalScreenHeight" *ngIf="validWidgets">
    <div style="width:25%; height:100%; background: #FFF;  padding-bottom: 25px;" *ngIf="validWidgets | widgetConfig : 27">
        <div #mainHeader >
            <div class="template-list-header"style="padding:5%;">
                <div class="formDiv">
                    <input class="formInput" [(ngModel)]="filter.label" type="text" placeholder="Search by name" (keyup)="filterChange($event)">
                    <mat-icon class="dashboardText">search</mat-icon>
                </div>
                <div (click)="createStandardTemplate()">
                    <mat-icon style="color: #0183fb;
                    font-size: 34px;cursor: pointer;cursor: pointer;">add_circle</mat-icon>
                </div>

            </div>
        </div>
        <div style="margin-bottom: 10px;overflow-y: scroll; height: 90%;padding-bottom: 10px;">
            <div style="width: 99%;" *ngIf="!(((customSurvey | filterPipe: filter.label).length===0)&&( (standardSurvey | filterPipe: filter.label).length===0));">
                <div class="template-list-header " style="padding: 5%; " *ngIf="((standardSurvey | labelPipe: filter.label : 'layoutName').length!=0) ">
                    <span class="available-segment-static-text ">Preset Survey Templates</span>
                </div>
                <div [id]="'survey-'+survey[ 'id']" *ngFor="let survey of standardSurvey | labelPipe: filter.label : 'layoutName';let i=index ">
                    <div class="top-list " [class.active]="survey[ 'id']==surveyId " [class.noBorder]="i===selectedStandardTemplateIndex-1 " (click)=" viewTemplateDetail(i,survey[ 'id'],survey[ 'type']) ">
                        <mat-icon>dashboard</mat-icon><div class="list-class ">
                            <div style="width: 100%; padding: 10px;display: flex; justify-content: space-between; ">
                                <div style="display: flex; align-items: center; ">
                                    <span class="segment-name-text ">{{(survey['layoutName'] | limitTo: 20)}}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="template-list-header " style="padding: 5%;" *ngIf="((customSurvey  | labelPipe: filter.label :'layoutName').length!=0)">
                    <span class=" available-segment-static-text ">Custom Survey Templates</span>
                </div>
                <div [id]="'survey-'+survey[ 'id']" *ngFor="let survey of customSurvey | labelPipe: filter.label : 'layoutName';let i=index ">
                    <div class="top-list " [class.active]="survey[ 'id']==surveyId " [class.noBorder]="i===selectedCustomTemplateIndex-1 " (click)=" viewTemplateDetail(i,survey[ 'id'],survey[ 'type']) ">
                        <mat-icon  class="dashboardText">dashboard</mat-icon> <div class="list-class ">
                            <div style="width: 100%; padding: 10px;display: flex; justify-content: space-between; ">
                                <div style="display: flex; align-items: center; ">
                                     <span class="segment-name-text ">{{(survey['layoutName'] | limitTo: 20)}}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="((customSurvey | labelPipe: filter.label : 'layoutName').length==0)&&( (standardSurvey | labelPipe: filter.label : 'layoutName').length==0)&&!loading &&surveys.length!=0">
                <div style="display: flex;justify-content: center;align-items: center; ">
                    <div style=" padding: 10px; border: 1px dotted #ff3b30; background: #fff; display: flex; justify-content: center; align-items: center; margin-top: 20px; "> No Match Found
                    </div>
                </div>
            </div>

            <div *ngIf="loading">
                <div style="display: flex;justify-content: center;">
                    <mat-spinner diameter="45"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
    <div style="width:75%; height:100%; overflow-y: scroll;background: #f8f8f8; border-radius: 20px 0px 0px 0px; ">
        <router-outlet></router-outlet>
    </div>
</div> -->

<div class="mainContainer">
  
  <div style="display: flex; justify-content: space-between; align-items: center;" #container *ngIf="validWidgets">
    <!-- <div class="header" #header>
      <div class="header-contents" style="width: 89%;">
        <div style="width: 100%; padding: 0px 0px 0px 20px"> -->
          <!-- <span>Search</span> -->
          <!-- <div class="formDiv">
            <input class="formInput" [(ngModel)]="filter.label" type="text" (input)="search();"
              placeholder="Search Survey Template" />
            <span class="material-icons-outlined icons-primary" *ngIf="filter.label.length==0">
              search
            </span>
            <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
              *ngIf="filter.label.length>0" (click)="filter.label='';search()">
              close
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <app-breadcrumb></app-breadcrumb>
    <div class="header-contents"
      *ngIf="validWidgets | widgetConfig: 19">
        <span class="primary-button" (click)="createStandardTemplate()">
          {{'SURVEY_PAGE.CREATE_NEW_SURVEY' | i18nTranslate}}
        </span>
    </div>
  </div>
  <div class="segment-content" *ngIf="validWidgets">
    <div *ngIf="validWidgets | widgetConfig: 73" style="width: 100%; display: flex; background-color:var(--main-bg-bgrey); gap: 30px;">
      <div class="side-bar">

        <div class="header-contents" style="justify-content: center; gap: 10px;" data-widget-name="SURVEY TEMPLATES">
          <span [ngClass]="{ active: surveyType === 'ALL' }" (click)="selectsurveyType('ALL')"
            class="segment-types all-segment" data-button-name="All">{{'SURVEY_PAGE.ALL' | i18nTranslate}}</span>
          <span [ngClass]="{ active: surveyType === 'PRESET' }" (click)="selectsurveyType('PRESET')" class="segment-types"
            data-button-name="Preset">Preset</span>
          <span [ngClass]="{ active: surveyType === 'CREATED' }" (click)="selectsurveyType('CREATED')"
            class="segment-types create-segment" data-button-name="Custom">{{'SURVEY_PAGE.CUSTOM' | i18nTranslate}}</span>
        </div>

        <div style="    height: 100%;
        overflow: hidden;">
          
          <div *ngIf="selectedSurveyList.length>0" style="height: 100%;overflow: hidden;">

            <div style="height: 100%;overflow: hidden; display: flex; flex-direction: column; align-items: stretch;">
             
              <div class="segment-list-header" #segmentListHeader>
                <span>{{ selectedSurveyType }}</span>
              </div>
              <div class="empty-customers-div" *ngIf="totalSurveys == 0">
                {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </div>
              <div style="overflow: auto; flex: 1 1 auto;">
                <div [id]="'survey-' + layout['id']" *ngFor="
                    let layout of selectedSurveyList
                      | labelPipe: filter.label:'layoutName';
                    let i = index
                  ">
                  <div class="top-list" [ngClass]="{ active: surveyId == layout['id'] }" (click)="
                      viewTemplateDetail(false, layout['id'], layout['type'])
                    ">
                    <div class="list-class">
                      <div style="
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        ">
                        <div class="template-list-content">
                          <span class="material-icons-outlined icons-primary">
                            thumb_up_off_alt
                          </span>
                          <span [id]="layout['id']" [ngClass]="{ active: surveyId == layout['id'] }"
                            class="segment-name-text">{{ layout["layoutName"] | limitTo: 20 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                                          class="seperator"
                                          *ngIf="
                                              !(surveyItem
                                                  ? standardSegments[i]['id'] === surveyItem['id'] ||
                                                      (standardSegments[i + 1]
                                                          ? standardSegments[i + 1]['id'] === surveyItem['id']
                                                          : false)
                                                  : false)
                                          "
                                      ></div> -->
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="selectedSurveyList.length==0" style="height: 100%; display: flex; align-items: center; justify-content: center;">
            <div class="empty-customers-div-2">
              {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
            </div>
          </div>
          <!-- </div> -->

        </div>
      </div>
      <div class="segment-description">
        <!-- <router-outlet></router-outlet> -->
        <app-survey-view></app-survey-view>

      </div>
    </div>
  </div>
</div>