<div class="welcome-widget-container">
  <div>
  <div class="welcome-widget-header">
      <div><div class="widget-flex-fill"></div></div>
      <div class="widget-flex-fill widget-align-center">
          <div>
              <img
                  class="bitmap"
                  [src]="logoUrl"
                  alt="Qubriux"
              />
          </div>
      </div>
      <div>
      <div class="widget-flex-fill widget-align-right">
          <div class="skip-label" (click)="getStarted()">
              {{'STATIC_PAGE.SKIP' | i18nTranslate}}
            </div>
      </div>
    </div>
  </div>
  </div>
  <div class="welcome-widget-body">
      <div class="chevout-wrapper">
      <div class="chevOut" *ngIf="!mobileMedia.matches">
          <mat-icon
            class="chevron"
            [ngClass]="!showLeftChevron? 'chevron-hidden': ''"
            (click)="scrollItem(-1)"
            >keyboard_arrow_left</mat-icon
          >
      </div>
      </div>
      <div class="carousel-overflow-wrapper">
          <div class="carousel-full-width-wrapper" (touchstart)="onSwipeStart($event)" (touchend)="onSwipeEnd($event)">
            <div class="welcome-carousel-card" *ngFor="let card of welcomeData">
              <img
                class="widget-img"
                [src]="card['img']"
                alt="Carousel Card Image"
              />
              <div class="widget-title">{{card['title'] | i18nDynamicTranslate : 'STATIC_PAGE'}}</div>
              <div class="widget-subtitle">{{card['desc'] | i18nDynamicTranslate : 'STATIC_PAGE'}}</div>
            </div> 
          </div>
      </div>
      <div class="chevout-wrapper">
      <div class="chevOut" *ngIf="!mobileMedia.matches">
        <mat-icon
          class="chevron"
          (click)="scrollItem(1)"
          [ngClass]="!showRightChevron? 'chevron-hidden': ''"
          >keyboard_arrow_right</mat-icon
        >
      </div>
      </div>
      <div class="carousel-indicator-container">
        <div class="carousel-indicator-dot" *ngFor="let position of welcomeDataIndicator" (click)="scrollToItem(position)" [ngClass]="currentScrollIndex===position? 'carousel-indicator-active': ''"></div>
      </div>
  </div>
  <div>
  <div class="welcome-widget-footer">
      <div class="get-started" (click)="getStarted()" [ngClass]="showRightChevron? 'chevron-hidden': ''">
          {{'STATIC_PAGE.Get Started' | i18nTranslate}}
      </div>
      <div class="mob-next-btn-wrapper" *ngIf="mobileMedia.matches" [ngClass]="!showRightChevron? 'chevron-hidden': ''" (click)="scrollItem(1)">
          <div class="blue-next-btn">
            <mat-icon style="color: #fff; font-size: 26px">east</mat-icon>
          </div>
      </div>
  </div>
  </div>
</div>