<head>
  <meta charset="utf-8">
    <title> {{'PROFILE_PAGE.QUBRIUX_HEADING' | i18nTranslate}}</title>
    <meta name="description" content="Onboarding">
    <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<div class="NFContainer">
    <div style="height:61px; background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000000F;
    opacity: 1; width:100%; display:flex; justify-content: flex-end;">
        <div style="display: flex;flex-direction: row;align-items: center; justify-content:space-between;">
            <!-- <div><span *ngIf="userName"
          style="text-align: left; font: 100 16px Gravity; letter-spacing: 0; color: #BEBEBE; opacity: 1; user-select: none; font-family:'Open sans';">{{userName}}</span>
      </div> -->
            <div class="headerIcons2 ">
                <div class="headerUserIcon gradient " [matMenuTriggerFor]="menu " style="cursor:pointer; ">
                    <span style="font-family:'Open sans';" >{{userName[0]}}</span>
                </div>
            </div>
            <mat-menu #menu="matMenu">
                <div class="logout-button" (click)="logout() ">{{'PROFILE_PAGE.LOGOUT' | i18nTranslate}}</div>
            </mat-menu>
        </div>
    </div>
    <div class="NFHeading">
      <span>
        <td>
          <img
            style="height: 96px"
            alt="Default"
            [src]="logoUrl"
          />
        </td>
      </span>
    </div>
    <div
      style="display: flex; justify-content: center; width: 100%; height: 40%"
    >
    <h4>{{'PROFILE_PAGE.STORE_NOT_READY_MESSAGE' | i18nTranslate}}</h4>
    </div>

    <div style="display: flex; justify-content: center; width: 100%">
      <div class="qb-text-position">
        <p class="NFText">
            {{'PROFILE_PAGE.STORE_NOT_READY_SUBMESSAGE' | i18nTranslate}} <b style=" text-decoration: underline;">cs@qubriux.com</b> {{'PROFILE_PAGE.STORE_NOT_READy_SUBMESSAGE_PREFIX' | i18nTranslate}}
        </p>
        <!-- <p style="height: auto;width: 100%;">
      This page will be redirected in {{countStart}} sec.
  </p> -->
      </div>
    </div>
  </div>
