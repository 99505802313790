<div class="font" *ngIf="data.operation === 'ACTIVATE'">
  <div>
    <div class="header">
      <span *ngIf="data.strategyStatus !== 'ENDED'">{{'SHARED_PAGE.ACTIVATE_CAMPAIGN' | i18nTranslate}}</span>
      <span *ngIf="data.strategyStatus === 'ENDED'">{{'SHARED_PAGE.REACTIVATE_CAMPAIGN' | i18nTranslate}}</span>
      <mat-icon (click)="dialogRef.close(false)" class="close-icon"
        >close</mat-icon
      >
    </div>
  </div>
  <div>
    <div class="message">
      <div *ngIf="data.endDate">
        <span
          >{{'SHARED_PAGE.ABOUT_TO_ACTIVATE' | i18nTranslate}}
          <span style="font-weight: 600">{{ data.campaignName }}</span> {{'SHARED_PAGE.FROM' | i18nTranslate}}
          <span>{{ startDate | date: getDateFormat() }} to {{ endDate | date: getDateFormat() }}</span></span
        >
      </div>
      <div *ngIf="!data.endDate">
        <span
          >{{'SHARED_PAGE.ABOUT_TO_ACTIVATE' | i18nTranslate}}
          <span style="font-weight: 600">{{ data.campaignName }}</span> {{'SHARED_PAGE.ON' | i18nTranslate}}
          <span>{{ startDate | date: getDateFormat() }}</span></span
        >
      </div>
    </div>
    <!-- <div style="display: flex; flex-direction: column; gap: 10px;" class="message" *ngIf="data.strategyStatus === 'ENDED'">
      <span>
        {{'SHARED_PAGE.REACTIVATE_CAMPAIGN_TEXT' | i18nTranslate}}
      </span>
      <span>
        {{'SHARED_PAGE.ARE_YOU_SURE_REACTIVATE' | i18nTranslate}}
      </span>
    </div> -->
  </div>
  <div class="repeat-container" style="gap: 10px; display: flex; flex-direction: column;">
    <div *ngIf="data['dateDifference'] < 365">
      <mat-checkbox
        [(ngModel)]="checkboxValue"
        color="primary"
        (change)="repeat()"
        >{{'SHARED_PAGE.REPEAT' | i18nTranslate}}</mat-checkbox
      >
    </div>
    <div style="width: 50%">
      <div class="formDiv" *ngIf="data['dateDifference'] < 365">
        <mat-select
          [disabled]="!checkboxValue"
          style="width: 95%"
          [(ngModel)]="recurringPeriod"
          (selectionChange)="repeat()"
        >
          <mat-option
            *ngFor="let option of recurringOptions"
            [value]="option[0]"
            >{{ option }}</mat-option
          >
        </mat-select>
      </div>
    </div>
  </div>
  <div style="padding: 0px 16px 16px 16px" *ngIf="checkboxValue">
    <div>
      <mat-checkbox [(ngModel)]="recurringValue" color="primary"
        >{{'SHARED_PAGE.SELECT_DATE_NO_OCCURING' | i18nTranslate}}</mat-checkbox
      >
    </div>
    <div *ngIf="recurringValue">
      <div class="formDiv" style="width: 50%; margin-top: 12px">
        <input
          style="margin-left: 5%"
          [min]="recurEndMaxDate"
          [(ngModel)]="recurEndDate"
          class="formInput"
          matInput
          [matDatepicker]="picker1"
          placeholder="Choose a date"
          [ngStyle]="recurEndDate ? { opacity: 0 , width: '0px' } : null" 
          />
          <div class="formInput">
            {{recurEndDate | date: getDateFormat() }}
        </div>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </div>
    </div>
  </div>
  <div *ngIf="checkboxValue" style="padding: 0px 16px 16px 16px">
    <span *ngIf="data.endDate">
      <span style="font-weight: 600">{{ data.campaignName }}</span> {{'SHARED_PAGE.REPEATED_FROM' | i18nTranslate}}
      <span style="font-weight: 600"
        >{{ repeatStartDate | date: getDateFormat() }} to {{ repeatEndDate | date: getDateFormat()}}</span
      ></span
    >
    <span *ngIf="!data.endDate">
      <span style="font-weight: 600">{{ data.campaignName }}</span> {{'SHARED_PAGE.REPEATED_ON' | i18nTranslate}}  <span>{{ repeatStartDate | date: getDateFormat()}}</span></span
    >
  </div>
  <div
    style="
      display: flex;
      justify-content: flex-end;
      padding: 0px 16px 16px 16px;
      gap: 10px;
    "
  >
    <span class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="primary-button" (click)="submit(true)">{{'SHARED_PAGE.ACTIVATE' | i18nTranslate}}</span>
  </div>
</div>
<div class="font" *ngIf="data.operation === 'DEACTIVATE'">
  <div>
    <div class="header">
      <span>{{'SHARED_PAGE.DEACTIVATE_CAMPAIGN' | i18nTranslate}}</span>
    </div>
  </div>
  <div>
    <!-- <div class="message">
      <div>
        <span
          >{{'SHARED_PAGE.WISH_DEACTIVATE' | i18nTranslate}}
          <span style="font-weight: 600"
            >{{ data.campaignName
            }}<span style="font-weight: normal">?</span></span
          >
        </span>
      </div>
    </div> -->
    <div style="display: flex; flex-direction: column; gap: 10px;" class="message">
      <span>
        {{'SHARED_PAGE.DEACTIVATE_CAMPAIGN_TEXT' | i18nTranslate}}
      </span>
      <span>
        {{'SHARED_PAGE.ARE_YOU_SURE_DEACTIVATE' | i18nTranslate}}
      </span>
    </div>
  </div>
  <div
    style="
      display: flex;
      justify-content: flex-end;
      padding: 16px 16px 16px 16px;
      gap: 10px;
    "
  >
    <span class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="primary-button" (click)="submit(true)">{{'SHARED_PAGE.DEACTIVATE' | i18nTranslate}}</span>
  </div>
</div>
