import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { EChartsOption } from 'echarts';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe, getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-campaign-ab-testing',
  templateUrl: './campaign-ab-testing.component.html',
  styleUrls: ['./campaign-ab-testing.component.css']
})
export class CampaignAbTestingComponent implements OnInit {

  constructor(
    private apiCallService : ApiCallService,
    private snackBar : SnackbarCollection,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private _WidgetConfigService: WidgetConfigService,
    private datePipe: DatePipe,
  ) { }

  @ViewChild("paginator") paginator: MatPaginator;
  abTestingCampaignListPaginationConfig = {
    pageIndex:0,
    paginationCount:0,
    recordsPerPage: 10,
    pageNumber:0,
  };
  listLoading = false;
  showAnalyticsPopUp = false;
  analyticsPopUpLoading = false;
  revCurrency;
  currencySymbol;
  xIntervalConstantList = ['day','month','week','hour'];
  selectedTime;
  colorCodes = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#ea7ccc'];
  selectedCampaign;
  abPathAnalytics;
  
  ngOnInit(): void {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.getWidgets();
    this.getABNodeMetrices();
    this.getABTestingCampaignList();

  }

  validWidgets;
  appSubscriptionPlan;
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getDateTimeInFormatForRequest(timestamp, timeToAppend?) {
    if (!timestamp) return null;
  
    let date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    let hours, minutes, seconds;
  
    if (timeToAppend === 'start') {
      hours = '00';
      minutes = '00';
      seconds = '00';
    } else if (timeToAppend === 'end') {
      hours = '23';
      minutes = '59';
      seconds = '59';
    } else {
      hours = String(date.getHours()).padStart(2, '0');
      minutes = String(date.getMinutes()).padStart(2, '0');
      seconds = String(date.getSeconds()).padStart(2, '0');
    }
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  getDateTimeInFormatForDisplay(timestamp: number): string | null {
    if (!timestamp) return null; 
    let date = new Date(timestamp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  getWindowNoGraphOption(selectedMetric,xInterval) {
    let valSerOpt :EChartsOption['series'] = [];
    let xAxisOpt :EChartsOption['xAxis'] = {
      type: 'category',
      boundaryGap: true,
      data:['No Data', 'No Data', 'No Data'],
      name: this.capitalize(xInterval+'s'),
      nameLocation: 'end',
      nameGap: 3
    }
    let legendOpt :EChartsOption['legend'] = {
      data:[],
      type:'scroll'
    }
    let res = {
      tooltip: {
        trigger: 'axis'
      },
      legend: [],
      grid: {
        left: '7%',
        right: '8%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: xAxisOpt,
      yAxis: {
        type: 'value',
        // name: this.trackedMetrices.find((metric)=>metric.value == selectedMetric).displayName, long metric name causing issue
        nameLocation: 'end',
        show: true,
        min: 0,
        max: 100,
      },
      series: []
    };
    return res;
  }

  getWindowGraphOption(graphData,selectedMetric,xInterval) {
    let valSerOpt :EChartsOption['series'] = [];
    let xAxisOpt :EChartsOption['xAxis'] = {
      type: 'category',
      boundaryGap: true,
      data:[],
      name: this.capitalize(xInterval+'s'),
      nameLocation: 'end',
      nameGap: 3
    }
    let legendOpt :EChartsOption['legend'] = {
      data:[],
      type:'plain',
    }
    for(let pathName in graphData) {
      if(Object.keys(graphData[pathName]).length > 0) {
        legendOpt.data.push(pathName);
        let val = [];
        for(let dateTime in graphData[pathName]) {
          val.push(graphData[pathName][dateTime]);
          if(xAxisOpt.data.length < Object.keys(graphData[pathName]).length) {
            xAxisOpt.data.push(dateTime);
          }
        }
        valSerOpt.push({
          name: pathName,
          type:'line',
          data:val,
          color: this.abPathAnalytics[pathName].color,
        });
      }
    }
    return {
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => this.isRevenueMetric(selectedMetric)?this.currencySymbol+" "+value.toFixed(2):value  
      },
      legend: legendOpt,
      grid: {
        left: '7%',
        right: '8%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: xAxisOpt,
      yAxis: {
        type: 'value',
        // name: this.trackedMetrices.find((metric)=>metric.value == selectedMetric).displayName, long metric name causing issue
        nameLocation: 'end',
        min: 'dataMin',
        minInterval: 1,
        nameTruncate : {
          maxWidth: 100,
        },
        nameTextStyle : {
          overflow: 'break',
          // width
        },
        axisLabel: {
          formatter : this.isRevenueMetric(selectedMetric)?this.currencySymbol+' {value}':'{value}'
        }
      },
      series: valSerOpt
    };
  }

  listSearch='';
  abTestingCampaignList = [];
  getABTestingCampaignList() {
    this.listLoading = true;
    this.abTestingCampaignList = [];
    this.apiCallService.getABTestingCampaignList(this.abTestingCampaignListPaginationConfig.recordsPerPage,
      this.abTestingCampaignListPaginationConfig.pageNumber+1,this.listSearch).subscribe(
      (response) => {
        if(response['body'] && response['body'].length > 0) {

          this.abTestingCampaignList = response['body'].map((obj)=>{
            let nodeDetails = JSON.parse(obj['abTestingNode']);
            let pathGroupsList = Object.keys(nodeDetails.pathGroups).map((pathKey)=>{
              return {
                pathName : nodeDetails['pathGroups'][pathKey]['pathName'],
                weight : nodeDetails['pathGroups'][pathKey]['weight'],
                key: pathKey
              }
            });
            this.abTestingCampaignListPaginationConfig.paginationCount = obj['totalCount'];
            return {
              nodeId : obj['abNodeId'],
              strategyId :  obj['strategyId'],
              strategyName : obj['strategyName'],
              // nodeDetails : nodeDetails,
              status : obj['status'],
              pathGroupsList : pathGroupsList,
              createdBy: obj['createdBy'],
              startDate: obj['startDate'],
              endDate: obj['endDate'],
              startDateObj: new Date( obj['startDate']),
              endDateObj: new Date(obj['endDate']),
              expDuration: nodeDetails['experimentDuration'],
            }
          });
         
        } else {
          this.abTestingCampaignListPaginationConfig.paginationCount = 0;
        }
        // console.log(this.abTestingCampaignList);
        this.listLoading = false;
      },
      (err) => {
        this.snackBar.openSnackBar('Failed to get AB Testing Journey List',2000);
      }
    );
  }

  refreshPaginatedData(event) {
    this.abTestingCampaignListPaginationConfig.recordsPerPage = event.pageSize;
    this.abTestingCampaignListPaginationConfig.pageNumber = event.pageIndex;
    this.getABTestingCampaignList();
  }

  resetPaginationData() {
    this.abTestingCampaignListPaginationConfig.paginationCount = 0;
    this.abTestingCampaignListPaginationConfig.pageIndex = 0;
    // this.abTestingCampaignListPaginationConfig.recordsPerPage = 10;
    this.abTestingCampaignListPaginationConfig.pageNumber = 0;
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  clearSearch() {
    this.listSearch = null,
    this.resetPaginationData();
    this.getABTestingCampaignList();
  }

  timeout
  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.resetPaginationData();
        $this.getABTestingCampaignList();
      }
    }, 600);
  }

  trackedMetrices;
  getABNodeMetrices() {
    this.apiCallService.getABNodeMetrices().subscribe(
      (response)=>{
        this.trackedMetrices = Object.keys(response['body']).map((key)=>{
          return {
            value : key,
            displayName : response['body'][key],
          }
        });
      },
      (err)=>{
        this.snackBar.openSnackBar('Failed to get AB Testing Metrices',2000);
      }
    );
  }

  async createCampaign() {
    if (await this._SubscriptionPlanHandlerService.IsValidOperation(this.appSubscriptionPlan)) {
        this.router.navigate(
          [
            "../creation",
            {
              "return-url": "campaign/ab-testing",
              id: null,
              recommendation: false,
            },
          ],
          { relativeTo: this.route }
        );
      }
  }

  openStrategy(strategyId) {
    this.router.navigate(
      [
        "../creation",
        {
          "return-url": "campaign/ab-testing",
          id: strategyId,
          recommendation: false,
        },
      ],
      { relativeTo: this.route }
    );
  }

  updateWindow(windowDataToChange) {
    //Set X Interval
    windowDataToChange.xInterval = windowDataToChange.xInterval?windowDataToChange.xInterval:this.xIntervalConstantList[0];

    if(!windowDataToChange.graphStartDateObj || !windowDataToChange.graphEndDateObj) 
      return
    //GETTING GRAPH DATA
    let data = {
      startDate: this.getDateTimeInFormatForRequest(windowDataToChange.graphStartDateObj,'start'),
      endDate: this.getDateTimeInFormatForRequest(windowDataToChange.graphEndDateObj,'end'),
      kpiType: windowDataToChange.selectedMetric,
      event: null,
      timePeriod: windowDataToChange.xInterval,
      abNodeId : this.selectedCampaign.nodeId,
      strategyId : this.selectedCampaign.strategyId
    };
    //x Interval set
    windowDataToChange.graphLoading = true;
    let graphAPI = this.apiCallService.getABGraphAnalytics(data).subscribe(
      (response)=>{
        windowDataToChange.graphLoading = false;
        //check if all branches dont have data
        if(!this.isGraphDataPresentInAnyBranch(response['body'])) {
          windowDataToChange.hasGraphData = false;
          windowDataToChange.graphOption = this.getWindowNoGraphOption(windowDataToChange.selectedMetric, windowDataToChange.xInterval);
        } else {
          windowDataToChange.hasGraphData = true;
          windowDataToChange.graphData = response['body'];
          windowDataToChange.graphOption = this.getWindowGraphOption(response['body'], windowDataToChange.selectedMetric, windowDataToChange.xInterval);
        }
      },
      (err)=>{
        this.snackBar.openSnackBar('Failed to get Graph Analytics',2000);
      }
    );
    //Update path analytics too
    let pathAnalyticsAPI = this.apiCallService.getABPathAnalytics(this.selectedCampaign.strategyId,this.selectedCampaign.nodeId).subscribe(
      (response) =>{
        this.abPathAnalytics = response['body'];
        //set colors
        let i = 0;
        for(let pathName in this.abPathAnalytics) {
          this.abPathAnalytics[pathName].color = this.colorCodes[i++];
        }
      },
      (err) => {
        this.snackBar.openSnackBar('Failed to get AB Testing path Analytics',2000);
      }
    );
    let int = setInterval(()=>{
      if(graphAPI.closed && pathAnalyticsAPI.closed) {
        //Update KPI value in view
        windowDataToChange.eachPathStats.forEach((pathStat)=>{
          pathStat.kpiValue = this.abPathAnalytics[pathStat.pathName]['kpiMap'][windowDataToChange.selectedMetric];
        });
      }
    })
  }

  isGraphDataPresentInAnyBranch(graphDataHashMap) {
    for(let pathName in graphDataHashMap) {
      if(Object.keys(graphDataHashMap[pathName]).length > 0) {
        return true;
      }
    }
    return false;
  }

  windowDataList;
  addNewWindow(metric) {
    let eachPathStats = [];
    for(let pathName in this.abPathAnalytics) {
      eachPathStats.push({
        pathName: pathName,
        distributionPerc : 'DISTPERC',
        customerCount : this.abPathAnalytics[pathName]['customerCount'],
        kpiValue: this.abPathAnalytics[pathName]['kpiMap'][metric],
        color: this.abPathAnalytics[pathName]['color'],
      });
    }
    //GETTING GRAPH DATA
    let data = {
      startDate: this.getDateTimeInFormatForRequest(this.selectedCampaign.startDate,'start'),
      endDate: this.getDateTimeInFormatForRequest(this.selectedCampaign.endDate,'end'),
      kpiType: this.trackedMetrices[0].value,
      event: null,
      timePeriod: this.xIntervalConstantList[0],
      abNodeId : this.selectedCampaign.nodeId,
      strategyId : this.selectedCampaign.strategyId
    };
    this.analyticsPopUpLoading = true;
    this.apiCallService.getABGraphAnalytics(data).subscribe(
      (response)=>{
        this.analyticsPopUpLoading = false;
        //check if all branches dont have data
        if(!this.isGraphDataPresentInAnyBranch(response['body'])) {
          this.windowDataList.push({
            windowId: this.generateRandomString(4),
            selectedMetric: this.trackedMetrices[0].value,
            eachPathStats : eachPathStats,
            graphLoading : false,
            hasGraphData: false,
            xInterval: this.xIntervalConstantList[0],
            graphStartDateObj: new Date(this.selectedCampaign.startDate),
            graphEndDateObj: new Date(this.selectedCampaign.endDate),
            graphOption: this.getWindowNoGraphOption(this.trackedMetrices[0].value, this.xIntervalConstantList[0]),
          });
        } else {
          this.windowDataList.push({
            windowId: this.generateRandomString(4),
            selectedMetric: this.trackedMetrices[0].value,
            eachPathStats : eachPathStats,
            graphLoading : false,
            hasGraphData: true,
            xInterval: this.xIntervalConstantList[0],
            graphStartDateObj: new Date(this.selectedCampaign.startDate),
            graphEndDateObj: new Date(this.selectedCampaign.endDate),
            graphData: response['body'],
            graphOption: this.getWindowGraphOption(response['body'], this.trackedMetrices[0].value,this.xIntervalConstantList[0]),
          });
        }
      },
      (err)=>{
        this.snackBar.openSnackBar('Failed to get Graph Analytics',2000);
      }
    );
  }

  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  removeWindow(windowId) {
    let i = this.windowDataList.findIndex((windowData)=>windowData.windowId == windowId);
    this.windowDataList.splice(i,1);
  }

  openAnalyticsPopUp(selectedCampaign) {
    this.selectedCampaign = selectedCampaign;
    this.showAnalyticsPopUp = true;
    this.analyticsPopUpLoading = true;
    let pathAnalyticsAPI = this.apiCallService.getABPathAnalytics(this.selectedCampaign.strategyId,this.selectedCampaign.nodeId).subscribe(
      (response) =>{
        this.analyticsPopUpLoading = false;
        this.abPathAnalytics = response['body'];
        //set colors
        let i = 0;
        for(let pathName in this.abPathAnalytics) {
          this.abPathAnalytics[pathName].color = this.colorCodes[i++];
        }
        console.log(this.abPathAnalytics);
        this.windowDataList = [];
        this.addNewWindow(this.trackedMetrices[0].value);//create a window with the first KPI/Metric
      },
      (err) => {
        this.snackBar.openSnackBar('Failed to get AB Testing path Analytics',2000);
      }
    );
  }

  closeAnalyticsPopUp() {
    this.showAnalyticsPopUp = false;
  }
  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  isRevenueMetric(selectedMetric) {
    return this.trackedMetrices.find(metric=> metric.value == selectedMetric).displayName.toLowerCase().includes('revenue');
  }

}
