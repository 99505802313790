<div style="width: 100%; display: flex; justify-content: center; height: 100%" *ngIf="!loading">
  <div class="phone-container">
    <div class="phone-background"></div>
    <div class="phone-image">
      <div class="text-container-div">
        <div class="preview-text-content">
          <span style="white-space: pre-line; word-break: break-word">{{
            smsBody["layoutData"]
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" style="width: 100%; display: flex; justify-content: center; height: 100%;align-items: center;">
 <mat-spinner diameter="22"></mat-spinner>
</div>
