<div class="header">
  <span class="header-title" *ngIf="!data?.reportEmail"><strong style="text-transform: uppercase;">{{'SHARED_PAGE.SEND_TEST_EMAIL' | i18nTranslate}}</strong></span>
  <span class="header-title" *ngIf="data?.reportEmail"><strong style="text-transform: uppercase;">{{data.subject}}</strong></span>
  <mat-dialog-actions>
    <button class="xIcon" [mat-dialog-close]="false">
      <div class="material-icons" style="margin-left: -4.5px; font-size: 15px">
        clear
      </div>
    </button>
  </mat-dialog-actions>
</div>
<div>
  <div class="enterEmail" *ngIf="!data?.reportEmail">
    {{'SHARED_PAGE.TEST_DYNAMIC_NOTE' | i18nTranslate}}.
  </div>
  <div style="display: flex; flex-direction: row; align-items: center;" *ngIf="data?.reportEmail">
    <div class="enterEmail">
      {{data?.message}}
    </div>
    <!-- <div>
      <mat-select [(ngModel)]="fileType">
        <mat-option *ngFor="let val of data?.downloadOption" [value]="val" selected>{{val | i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
    </mat-select>
    </div> -->
  </div>
  <div class="selectDomainName">
    <span class="filterTitle">{{'SHARED_PAGE.EMAIL_ADDRESS' | i18nTranslate}}</span>
    <input
      type="text"
      [(ngModel)]="email"
      placeholder="test@email.com"
      (input)="keypress()"
    />
    <div *ngIf="error" style="color: red; font-size: 11px">
      {{'SHARED_PAGE.VALID_EMAIL_ADDRESS' | i18nTranslate}}
    </div>
  </div>
  <!-- <div class="enterEmail">
    If you want to test the dynamic content,
    <ins style="color: #0183fb; font-weight: 600; cursor: pointer"
      ><a
        style="color: #0183fb"
        href="https://qubriux.skellam.ai/knowledge-hub/reach-out"
        target="_blank"
        >{{'SHARED_PAGE.FOLLOW_INSTRUCTION' | i18nTranslate}}</a
      ></ins
    >.
  </div> -->
  <div class="buttons">
    <mat-dialog-actions>
      <button class="cancel" [mat-dialog-close]="false">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button class="confirm" (click)="sendTestEmail()">{{'SHARED_PAGE.SEND' | i18nTranslate}}</button>
    </mat-dialog-actions>
  </div>
</div>
