import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

interface BarData {
  name: string;
  value: number;
}

@Component({
  selector: 'app-line-chart-analytics',
  templateUrl: './line-chart-analytics.component.html',
  styleUrls: ['./line-chart-analytics.component.css']
})
export class LineChartAnalyticsComponent implements OnInit {

  @ViewChild('lineChartContainer')
  private chartContainer: ElementRef;

  @Input()
  data: BarData[];

  @Input() xLabel : string;
  @Input() yLabel : string;
  @Input() idn;

  margin = { top: 20, right: 20, bottom: 40, left: 50 };

  constructor(
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.chartContainer && this.chartContainer.nativeElement) {
      this.createChart(this.idn);
    }
  }

  ngOnChanges(): void {
    if (!this.data) { return; }
    if (this.chartContainer && this.chartContainer.nativeElement) {
      this.createChart(this.idn);
    }
  }

  private createChart(idn): void {
    var idnumber = "#" + idn;
    d3.select(idnumber).selectAll("*").remove();
    // d3.select('svg').remove();

    const element = document.getElementById(idn);
    const data = this.data;
    const legendData = [];

    const svg = d3.select(idnumber).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);

    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleBand()
      .range([0, contentWidth])
      .paddingInner(0.3)
      .paddingOuter(0.3)
      .domain(data.map(d => d.name));

      let maxY = d3.max(data, d => d.value );
      if (maxY == 0) {
        maxY = 10;
      }

    const y = d3
      .scaleLinear()
      .range([contentHeight, 0])
      .domain([0, maxY]);

    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
      .attr('font-size', '12px')
      .attr('display', 'none');

    //rotate x axix domain values text 
    g.selectAll('text')
      .attr('text-anchor', 'end')
      .attr('transform', 'rotate(-40)');

    //add x axis label
    g.append("text")
      .attr('font-size', '12px')
      .attr("class", "axis-title")
      .attr('x', contentWidth + 20)
      .attr('y', contentHeight - 10)
      .attr('dy', '2.7em')
      .style("text-anchor", "end")
      .attr("fill", "#5D6971")
      .text(this._i18nDynamicTranslate.transform(this.xLabel, ['POS_PAGE']));

    //draw y axis and y grid lines  
    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).tickSizeInner(-contentWidth))
      .attr('font-size', '12px')

    //style the y tick/grid lines
    d3.selectAll('.axis--y .tick line')
      .style('color', 'rgba(34, 34, 34, 0.2)')
      .style('stroke-dasharray', '20,5');


    //add y axis label
    g.append('text')
      .attr('x', 20)
      .attr('font-size', '12px')
      .attr('y', -12)
      .attr('dy', '0.71em')
      .attr("fill", "#5D6971")
      .attr('text-anchor', 'end')
      .text(this._i18nDynamicTranslate.transform(this.yLabel, ['POS_PAGE']));


    // draw points and lines for each tier
    //data.forEach((tierPair) => {
      //let randomColor = this.getRandomColor();

      // create legend info
      // legendData.push({
      //   color: randomColor,
      //   text: tierPair.tier,
      // });

      //translateXDist = distanceBetweenXpoints/2
      let translateXDist = x.bandwidth() / 2;

      //draw points
      g.append("g")
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d) { return x(d.name); })
        .attr("cy", function (d) { return y(d.value); })
        .attr("r", 3)
        .attr("transform", 'translate(' + translateXDist + ',0)')
        .style("fill", "#5D6971");

      //draw line 
      let line = d3.line()
        .x(function (d) { return x(d.name); })
        .y(function (d) { return y(d.value); })
        .curve(d3.curveMonotoneX)


      g.append("path")
        .datum(data)
        .attr("class", "line")
        .attr("transform", 'translate(' + translateXDist + ',0)')
        .attr("d", line)
        .style("fill", "none")
        .style("stroke", "#FFD46C")
        .style("stroke-width", "2");
    //});

    // Append the legend group element
    // const legend = svg.append('foreignObject')
    // .attr('x', 18) // Adjust the distance between the color rectangle and text
    // .attr('y', -4) // Adjust the vertical alignment of the text
    // .attr('width', 550) // Set the maximum width for the text container
    // .attr('height', 40)
    // .attr('class', 'legend')
    // .attr('transform', 'translate(120, 8)')
    // .append('xhtml:div')
    // .style('max-width', '100%')
    // .style('max-height', '100%')
    // .style('display','flex')
    // .style('overflow', 'scroll'); // Adjust the position as needed

    // // Append legend items
    // const legendItems = legend.selectAll('.legend-item')
    //   .data(legendData)
    //   .enter()
    //   .append('xhtml:div')
    //   .attr('class', 'legend-item')
    //   .style('display','flex')
    //   .style('align-items','center')
    //   .style('min-width','120px')
    //   .style('overflow', 'hidden')
    //   .style('text-overflow', 'ellipsis')


    // // Append color rectangles
    // legendItems.append('xhtml:div')
    //   .style('min-width', '14px') // Adjust the width of the color rectangle
    //   .style('min-height', '14px') // Adjust the height of the color rectangle
    //   .style('margin-right','5px')
    //   .style('background-color', d => d.color)
    //   .attr('class', 'legend-color');
    
    // legendItems
    // .append('xhtml:div')
    // .text(d => d.text)
    // .attr('title',d => d.text)
    // .style('overflow','hidden')
    // .style('text-overflow','ellipsis');

  }


}
