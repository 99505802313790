import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { assetPath } from 'src/app/configs/asset-path-config';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { TextTranslationService } from 'src/app/core/services/translate-text.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-static-header',
  templateUrl: './static-header.component.html',
  styleUrls:  ['../../pages/static-page/static-page.component.css', './static-header.component.css']
})
export class StaticHeaderComponent implements OnInit,OnDestroy {
  mobileView = false;
  imagePath7 = assetPath.path + '/grefoxLogo.svg';
  s3BucketUrl=environment.s3BucketUrl;
  selectedLang: string = "en";
  defaultLang: string = "en";
  displayLang = {label: "English", value: "en"};
  languagesAvailable = [];
  @ViewChild("langSelect") langSelect;
  @Input('coverLoaded') showHeader;

  constructor(private router:Router,private route:ActivatedRoute, private tokenStorage: TokenStorageService, private translateService : TextTranslationService,
    private _i18nDynamicTranslate: TranslateDynamicText) { }

  activateNavBar(){
    let screenposition=window.innerHeight/6;
    const navbar=document.querySelector('.qb-landing-header');

    if(window.pageYOffset<screenposition) navbar.classList.remove('qb-nav-active');
    else if(window.pageYOffset>screenposition) navbar.classList.add('qb-nav-active');
  }

  navigateToPath(isDemo, isPOS){
    if(isDemo && isPOS)
      this.router.navigate([
        "/auth/login",
        { isDemo: isDemo,
        isPOS: isPOS },
      ]);
    else if(isDemo && !isPOS)
    this.router.navigate([
      "/auth/login",
      { isDemo: isDemo,
      isPOS: isPOS },
    ]);
    else
      this.router.navigate([
        "/auth/login",
      ]);
  }
  
  ngOnInit(): void {
    this.checkView();
    window.addEventListener('scroll', this.activateNavBar);
    this.setBrowserLang();
  }

  ngOnDestroy(): void{
    window.removeEventListener('scroll', this.activateNavBar);
  }

  checkView(){
    let screenWidth = window.innerWidth;
    if(screenWidth<=768) this.mobileView = true;
  }
  getLangList(){
    let languages = this.translateService.getAvailableLanguages();
    this.languagesAvailable = languages;
    languages.forEach(item=>{
      if(item.value===this.selectedLang) this.displayLang = item;
    });
    console.log("test", this.languagesAvailable)
  }

  setBrowserLang(){
    let browserLanguage = window.navigator.language? window.navigator.language: "en_US";
    this.selectedLang = browserLanguage.slice(0, 2);
    this.translateService.updateSelectedLanguage(this.selectedLang);
    this.getLangList();
  }

  openLangDropdown(){
    this.langSelect.open();
  }

  changeLang(){
    this.languagesAvailable.forEach(item=>{
      if(item.value===this.selectedLang) this.displayLang = item;
    });
    this.translateService.updateSelectedLanguage(this.selectedLang);
  }

  getFlagUrl(){
    const language = this.languagesAvailable.find(lang => lang.value === this.selectedLang);
    return language.flagImgUrl;
  }
}
