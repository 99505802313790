import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-product-trends',
  templateUrl: './product-trends.component.html',
  styleUrls: ['./product-trends.component.css']
})
export class ProductTrendsComponent implements OnInit {

  constructor(
    private _apiCall:ApiCallService, 
    private _snackBar:SnackbarCollection, 
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  dataLoading = false;
  dashboardData;
  rewardRedamationRateStat = [];
  datasefor2 = [];
  totalCustomerRevenueFrameList = ['Quarter','Month', 'Week'];
  timeFrame = 'Month';
  selectedMonth = '';
  selectedType = '';
  availableMonths = [];
  availableOrderType = [];
  revCurrency = sessionStorage.getItem("serviceCurrency");
  top10customerAllData :any = [];
  top10CustomerByOrderCountAllData : any = [];
  top10Customer: echarts.EChartsCoreOption;

  ngOnInit(): void {
    this.getAnalytics();
    // this.getFilters();
  }

  totalCustomerRevenueTimeFrameDropDownHandler(){
    console.log(this.timeFrame);
    this.getAnalytics();
  }

  getAnalytics(){
    this.dataLoading = true;
    // let data = {
    //   stringFilters: [],
    //   stringValuesToCheck: [[]],
    //   doubleFilters: [],
    //   doubleValuesToCheck: [[]],
    // }

    this.getBucketChartsApi('topproductschartv2', this.timeFrame.toLowerCase()).then((result)=>{
      if(result['body']){
        this.availableMonths = [];
        this.availableOrderType = [];
        this.top10customerAllData = result['body'];
        for(let i =0; i<this.top10customerAllData.length;i++){
          // this.availableMonths.push(this.top10customerAllData[i][this.timeFrame.toLowerCase()]);
          if(this.availableOrderType.indexOf((this.top10customerAllData[i]['type'])) == -1){
            this.availableOrderType.push(this.top10customerAllData[i]['type']);
          }
        }
        // this.selectedMonth = this.top10customerAllData[this.top10customerAllData.length -1][this.timeFrame.toLowerCase()];
        this.selectedType = (this.availableOrderType[0].toLowerCase().indexOf('unknown') === -1 || this.availableOrderType.length === 1) ? this.availableOrderType[0] : this.availableOrderType[1];
        this.availableMonths = this.getAvailableMonths();
        this.selectedMonth = this.availableMonths[this.availableMonths.length -1];
        this.createTopTenGraph(this.getParticularMonthData(this.selectedMonth, this.timeFrame.toLowerCase()));
        this.dataLoading = false;
      }
      else{
        this.top10Customer = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
          }
        };
        this.dataLoading = false;
      }
    });
  }

  getParticularMonthData(monthToFind, type){
    console.log(monthToFind);
    const index = this.top10customerAllData.findIndex(item => (item[type] === monthToFind && item['type'] === this.selectedType));
    console.log(index);
    return this.top10customerAllData[index].products;
  }

  createTopTenGraph(data){
    console.log(data);
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    let x1 = [], y1 =[];
    var counter = 0;
    data.forEach((data)=>{
      //data['name'] = data.dateRange;
      if (counter >= (data.length < 10 ? data.length : 10)) {
        return; // Exit the loop if the limit is reached
      }
      x1.push(data.count);
      y1.push(data.name);
      counter++;
      // data['value'] = data.count;
    });
    this.top10Customer = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          },
          formatter: function (params) {
            const dataIndex = params[0].dataIndex;
            console.log(data);
            return `Revenue: ${currencySymbol} ${params[0].data}`; // Customize the tooltip content
          },
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
      },
      xAxis: {
          type: 'value',
          name: 'Orders',
          nameLocation:'center',
          nameGap: 30,
      },
      yAxis: {
          type: 'category',
          data: y1.reverse().map(label => label.length > 24 ? label.slice(0, 24) + '...' : label)
      },
      series: [
          {
              name: 'Revenue',
              type: 'bar',
              data: x1.reverse(),
              itemStyle: {
                  // You can add custom item styles here if needed
              },
              axisLabel: {
                formatter: function (value) {
                  if (value >= 1000) {
                    return (value / 1000) + 'k';
                  }
                  return value;
                },
              },
          }
      ]
  };
  }

  monthChangeHandler(){
    this.createTopTenGraph(this.getParticularMonthData(this.selectedMonth, this.timeFrame.toLowerCase()));
  }

  convertToMonthName(value) {
    if(value){
      if(this.timeFrame.toLowerCase() === 'month'){
        let [year, month] = value.split('-');
    
        // Convert numeric month to three-letter abbreviation
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
    
        // Format the label as 'AbbreviatedMonth-Year'
        return `${monthAbbreviation}-${year}`;
      }
      else if(this.timeFrame.toLowerCase() === 'week'){
        let [year, week] = value.split('-');
        return `Week-${week} ${year}`
      }
      else{
        let [year, quarter] = value.split('Q');
        return `${year} Q${quarter}`
      }
    }
    else{
      return null;
    }
  }

  typeChangeHandler(){
    this.availableMonths = this.getAvailableMonths();
    const index = this.top10customerAllData.findIndex(item => (item[this.timeFrame.toLowerCase()] === this.selectedMonth && item['type'] === this.selectedType));
    
    if(index === -1){
      this.selectedMonth = this.availableMonths[this.availableMonths.length - 1];
    }
    this.createTopTenGraph(this.getParticularMonthData(this.selectedMonth, this.timeFrame.toLowerCase()));
  }

  getAvailableMonths(){
    return this.top10customerAllData.filter(entry => entry.type === this.selectedType).map(entry => entry[this.timeFrame.toLowerCase()]);;
  }

  getBucketChartsApi(type, timeframe?){
    return new Promise((resolve, reject) => {
      this._apiCall.getReportChart(null, type, "GET", timeframe).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }


}
