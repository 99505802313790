import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import "rxjs";

@Injectable()
export class Globals {
  constructor() {}
  public sidenavToggleStatus: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public sidenavToggleButton: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public redirectToPaymentPlan: boolean = false;
  public getplanIdValue: number = null;
  public sidenavButtonActive;
  public merchantDetailsCurrency: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public merchantDetailsCountry: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public avatarUploaded: BehaviorSubject<string> = new BehaviorSubject(null);

  public trialdaysRemaining: BehaviorSubject<any> = new BehaviorSubject(null);
  public isOnboarding: BehaviorSubject<any> = new BehaviorSubject(null);
  ngOnInit() {}
  setNewAvatarUploaded(url) {
    this.avatarUploaded.next(url);
  }

  passQueryValue(status: boolean, value: number) {
    this.redirectToPaymentPlan = status;
    this.getplanIdValue = value;
  }

  setMerchantCurrencyandCountry(currency: string, country: string) {
    this.merchantDetailsCurrency.next(currency);
    this.merchantDetailsCountry.next(country);
    localStorage.setItem("serviceCurrency", this.merchantDetailsCurrency.value);
    localStorage.setItem("serviceCountry", this.merchantDetailsCountry.value);
    sessionStorage.setItem(
      "serviceCurrency",
      this.merchantDetailsCurrency.value
    );
    sessionStorage.setItem("serviceCountry", this.merchantDetailsCountry.value);
  }

  setSidenavToggleStatus(data, button) {
    this.sidenavToggleStatus.next(data);
    this.sidenavToggleButton.next(button);
    this.sidenavButtonActive = button;
  }

  getSidenavToggleStatus(days) {}

  trialperiodBannerUpdate(days) {
    // this.trialdaysRemaining=(days)
    //passing the data as the next observable
    // if (days) {
    this.trialdaysRemaining.next(days);
    //sessionStorage.setItem('trialdaysRemaining', this.trialdaysRemaining.value);
    // }
  }
  setIsOnboarding(status){
    this.isOnboarding.next(status);
  }
}
