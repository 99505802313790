import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  AfterViewInit,
  OnChanges,
} from "@angular/core";
import * as d3 from "d3";
import d3Tip from "d3-tip";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";

@Component({
  selector: "app-segment-customer-count-bar-chart",
  templateUrl: "./segment-customer-count-bar-chart.component.html",
  styleUrls: ["./segment-customer-count-bar-chart.component.css"],
})
export class SegmentCustomerCountBarChartComponent implements OnDestroy, OnChanges {
  @Input() data;
  @ViewChild("my_dataviz") chartContainer: ElementRef;
  sidenavSubject;
  chartData;

  constructor(private _SidenavOperationService: SidenavOperationService) {}


  // ngAfterViewInit() {
  //   console.log("HELLO")
  //   this.sidenavSubject = this._SidenavOperationService.sidenavStatus.subscribe(
  //     (response) => {
  //       d3.select(this.chartContainer.nativeElement).selectAll("svg").remove();
  //       let interval = setInterval(() => {
  //         if (this.data.length > 0) {
  //           this.chartData = JSON.parse(JSON.stringify(this.data));
  //           this.render();
  //           clearInterval(interval);
  //         }
  //       }, 500);
  //     }
  //   );
  // }
  ngOnChanges() {
    this.sidenavSubject = this._SidenavOperationService.sidenavStatus.subscribe(
      (response) => {
        d3.select(this.chartContainer.nativeElement).selectAll("svg").remove();
        let interval = setInterval(() => {
          if (this.data.length > 0) {
            this.chartData = JSON.parse(JSON.stringify(this.data));
            this.render();
            clearInterval(interval);
          }
        }, 500);
      }
    );
  }

  ngOnDestroy() {
    this.sidenavSubject.unsubscribe();
  }

  render() {
    d3.select(this.chartContainer.nativeElement).selectAll("svg").remove();
    var tip = d3Tip()
      .attr("class", "d3-tip")
      .style("background", "#575757b3")
      .style("border-radius", "4px")
      .style("color", "#FFFFFF")
      .style("padding", "5px")
      .style("font-size", "12px")
      .style("text-align", "center")
      .offset([-5, 0])
      .html(function (d) {
        return d.Month + " : " + d.Count;
      });
    var margin = { top: 20, right: 10, bottom: 20, left: 40 },
      width =
        this.chartContainer.nativeElement.offsetWidth -
        margin.left -
        margin.right,
      height =
        this.chartContainer.nativeElement.offsetHeight -
        margin.top -
        margin.bottom;
    var svg = d3
      .select("#my_dataviz")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    svg.call(tip);
    let data = this.chartData;
    var x = d3
      .scaleBand()
      .range([0, width])
      .domain(
        data.map(function (d) {
          return d.Month;
        })
      )
      .padding(0.2);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x--axis")
      .call(d3.axisBottom(x).tickSizeOuter(0).tickSizeInner(0))
      .selectAll("text")
      .attr("transform", "translate(0,2)")
      .style("color", "rgba(34, 34, 34, 0.5)");
    let heightStatus = height;
    for (let i = 0; i < this.chartData.length; i++) {
      if (this.chartData[i].Count > 0) {
        heightStatus = 0;
      }
    }
    var y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d) {
          return d.Count;
        }),
      ])
      .range([height, heightStatus]);
    svg
      .append("g")
      .attr("class", "y--axis")
      .call(
        d3
          .axisLeft(y)
          .ticks(2)
          .tickSizeOuter(0)
          .tickSizeInner(0)
          .tickSize(-width)
      )
      .style("stroke-dasharray", "4,4")
      .style("stroke", "#C6CED6")
      .style("stroke-opacity", "0.6")
      .selectAll("text")
      .style("color", "rgba(34, 34, 34, 0.5)");

    d3.selectAll(".y--axis path").style("display", "none");
    d3.selectAll(".x--axis path").style("display", "none");
    svg
      .selectAll("mybar")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return x(d.Month) + x.bandwidth() / 4;
      })
      .attr("y", function (d) {
        return y(d.Count);
      })
      .attr("width", x.bandwidth() / 2)
      .attr("height", function (d) {
        return height - y(d.Count) + 1;
      })
      .attr("fill", "#00BABE")
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide);
  }
}
