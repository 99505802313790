import { Component, OnInit, HostListener, ErrorHandler } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { assetPath } from 'src/app/configs/asset-path-config';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { DialogOverviewComponent, ConfirmationDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { dataBase } from 'src/app/shared/models/model';
import { EmailTemplateCreationDialogComponent } from 'src/app/shared/components/email-template-creation-dialog/email-template-creation-dialog.component';


@Component({
  selector: 'app-campaign-template-creation',
  templateUrl: './campaign-template-creation.component.html',
  styleUrls: ['./campaign-template-creation.component.css']
})
export class CampaignTemplateCreationComponent implements OnInit {

  campaignActionForm: FormGroup;
  campaignActionFormData;
  selectedSegments = [];
  campaignTemplateCount = ['a', 'b', 'c', 'd'];
  masterOffer;
  masterCampaign;
  smsTemplate;
  emailTemplate;
  //campaignMasterType = ['PERSONALIZED', 'SEGMENTED'];
  //selectedCampaignMasterType = this.campaignMasterType[0];
  customers = [];
  name;
  campaignTrigger = [];
  campaignTriggerType = ['TIME_TRIGGER', 'EVENT_TRIGGER', 'INSTANT_TRIGGER'];
  selectedCampaignActionDelayType = "day";
  selectedCampaignTriggerType = this.campaignTriggerType[0];
  event = [];
  eventName = [];
  //targetCustomerSegments = [];
  selectedCampaignRepeat = "NO_REPEAT";
  selectedCampaignEvent = "Birthday";
  segments = [];
  emailJson;
  smsJson;
  offerJson;
  timeToTrigger;
  campaignActions = [];
  campaignActionSequenceNumber = 2;
  campaignStartDate;
  campaignEndDate;
  campaignTemplate = [];
  campaignType;
  campaignData = [];
  viewCampaignDetails;
  selectedEvent = [];
  selectedEmailTemplateType = "BASIC_TEMPLATE";
  variable;
  quill;
  //keyEventData;
  emailLayoutContent;
  emailLayout;
  submitClicked = false;
  mentionItems: string[] = ["Noah", "Liam", "Mason", "Jacob"];
  assetPath = {
    dBinUrl: assetPath.path + "/noun_dustbin_2025484.svg",
    timerUrl: assetPath.path + "/noun_Time_2055748.svg",
    arrowUrl: assetPath.path + "/Group 1393.svg",
    messageUrl: assetPath.path + "/envelope.svg"
  }
  mentionConfig = {
    mentions: [
      {
        items: ["Noah", "Liam", "Mason", "Jacob"],
        triggerChar: "@",
        returntrigger: false,
      }
    ]
  }
  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  disableCustomerSegments = false;
  // testAutoCmp = [
  //   ["here", "hither"],
  //   ["asynchronous", "nonsynchronous"],
  //   ["completion", "achievement", "conclusion", "culmination", "expirations"],
  //   ["hinting", "advive", "broach", "imply"],
  //   ["function","action"],
  //   ["provide", "add", "bring", "give"],
  //   ["synonyms", "equivalents"],
  //   ["words", "token"],
  //   ["each", "every"],
  // ];
  // testAuto = 'hello';
  // comp = [{list : this.testAutoCmp, from : 0, to : 9}]


  constructor(public dialog: MatDialog,
    private apiCall: ApiCallService,
    private errorHandle: ErrorHandler,
    private dataBase: dataBase,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarCollection,
    private tokenStorage: TokenStorageService) {
    this.getScreenSize()
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  quillCreated(event) {
    this.quill = event;
  }

  // imgHandler() {
  //   var range = this.quill.getSelection();
  //   var value = prompt('What is the image URL');
  //   let interval = setInterval(()=>{
  //     if(this.dialog){
  //       const dialogRef = this.dialog.open(DialogOverviewComponent, {
  //         width: "20%",
  //         data: {
  //           'type': 'fileInput'
  //         }
  //       })

  //       dialogRef.afterClosed().subscribe((result) => {
  //         if (result) {
  //           clearInterval(interval)
  //         }
  //       })
  //     }
  //   },200)
  //   this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);  
  // }

  formContentChange(event, i) {
    //this.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    if (event['text'][event['text'].length - 2] === '~') {
      const dialogRef = this.dialog.open(DialogOverviewComponent, {
        width: "20%",
        data: {
          'type': 'templateVariable',
          'data': this.variable
        }
      })

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.quill.deleteText(this.quill.getLength() - 2, 1)
          //this.quill.insertText(this.quill.getLength()-1, ' ', {});
          this.quill.insertText(this.quill.getLength() - 1, result, {});
          this.quill.insertText(this.quill.getLength() - 1, ' ', {});
          this.quill.focus();
          // let formValue = this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailContent.value
          // formValue = formValue.substring(0,formValue.length-8)
          // let value = this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailContent.value + result
          // this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailContent.setValue(value)
        }
      })
    }
  }

  valueChanges(): Observable<String> {
    let variable: Observable<String>
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: "20%",
      data: {
        'type': 'templateVariable',
        'data': this.variable
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        variable = result
      }
    })
    return variable;
  }

  getVariables(actionType, index) {
    // this.apiCall.getAllMessageTemplateVariables('EMAIL_TEMPLATE', type).subscribe((response) => {
    //   this.variable = JSON.parse(response['body'])
    //})
    if (actionType === 'OFFER_TEMPLATE') {
      this.apiCall.getDetailMasterOffers().subscribe((response) => {
        let offerTemplates = JSON.parse(response['body']);
        const dialogRefForOffer = this.dialog.open(DialogOverviewComponent, {
          disableClose: true,
          width: '50%',
          height: '500px',
          data: {
            'type': 'offerForCampaign',
            'data': offerTemplates
          }
        })
        dialogRefForOffer.afterClosed().subscribe((result) => {
          if (result) {
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.OfferId.setValue(result)
          }
        })
      },
        (err) => {
          this.openSnackBar('Error loading resource', err)
        })
    }
    else {
      this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.OfferId.setValue(null)
    }
  }

  ngOnInit() {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    this.campaignActionForm = new FormGroup({
      'selectedCampaignActionDelayType': new FormArray([
        new FormGroup({
          'DelayType': new FormControl('hour'),
          'ActionMedium': new FormControl('EMAIL_DELIVERY'),
          'ActionType': new FormControl('BASIC_TEMPLATE'),
          'EmailUploadType': new FormControl('TEMPLATE'),
          'messageTemplateId': new FormControl('', Validators.required),
          'layoutTemplateId': new FormControl('', Validators.required),
          'EmailSubject': new FormControl(),
          'EmailContent': new FormControl(),
          'SmsContent': new FormControl('', Validators.required),
          'DelayCount': new FormControl(1, Validators.compose([Validators.min(0), Validators.required])),
          'OfferId': new FormControl(null),
          'EmailLayoutContent': new FormControl('', Validators.required),
          'EmailLayoutSubject': new FormControl(),
          'EmailTemplateType': new FormControl('STANDARD_LAYOUT')
        })
      ]),
      'campaignTemplateName': new FormControl('', Validators.compose([Validators.maxLength(50), Validators.required])),
      'selectedCampaignTriggerType': new FormControl('INSTANT_TRIGGER'),
      'selectedCampaignRepeat': new FormControl('NO_REPEAT'),
      'selectedCampaignEvent': new FormControl('BDAY'),
      'targetCustomerSegments': new FormControl('', Validators.required)
    });
    this.getInitData();
    if (this.route.snapshot.paramMap.get('id') && this.route.snapshot.paramMap.get('stat')) {
      this.apiCall.getStandardCampaignTemplates().subscribe((response) => {
        let masterCampaign = JSON.parse(response['body']);
        for (let i = 0; i < masterCampaign.length; i++) {
          if (masterCampaign[i]['id'] === this.route.snapshot.paramMap.get('id')) {
            this.preFillForm(masterCampaign[i])
          }
        }
      },
        err => {
          this.errorHandle.handleError(err);
        })
    }
    else if (this.route.snapshot.paramMap.get('id')) {
      this.apiCall.getMasterCampaigns().subscribe((response) => {
        let masterCampaign = JSON.parse(response['body']);
        for (let i = 0; i < masterCampaign.length; i++) {
          if (masterCampaign[i]['id'] === this.route.snapshot.paramMap.get('id')) {
            this.preFillForm(masterCampaign[i])
          }
        }
      },
        err => {
          this.errorHandle.handleError(err);
        })
    }
    // if (!this.dataBase.merchantId) {
    //   this.apiCall.fetchUserMerchantData().subscribe((response) => {
    //     let userPosDetails = JSON.parse(response['body']);
    //     if (response['message'] === 'failure') {
    //       alert("Request failed" + response['message'])
    //     }
    //     else {
    //       this.dataBase.merchantId = userPosDetails[0]['id'];
    //       this.getInitData();
    //     }
    //   },
    //     error => {
    //       this.errorHandle.handleError(error)
    //     });
    // }
    // else {
    //   this.getInitData();
    // }

    //this.getVariables('BASIC_TEMPLATE')

  }

  preFillForm(campaign) {
    (<FormArray>this.campaignActionForm.get('campaignTemplateName')).setValue(campaign['name']);
    (<FormArray>this.campaignActionForm.get('selectedCampaignTriggerType')).setValue(campaign['campaignTrigger']['campaignTriggerType']);
    (<FormArray>this.campaignActionForm.get('selectedCampaignRepeat')).setValue((<FormArray>this.campaignActionForm.get('selectedCampaignTriggerType')).value === 'TIME_TRIGGER' ? campaign['campaignTrigger']['campaignTriggerRepeatType'] : 'NO_REPEAT');
    (<FormArray>this.campaignActionForm.get('selectedCampaignEvent')).setValue((<FormArray>this.campaignActionForm.get('selectedCampaignTriggerType')).value === 'EVENT_TRIGGER' ? campaign['campaignTrigger']['event']['eventID'] : 'BDAY');
    (<FormArray>this.campaignActionForm.get('targetCustomerSegments')).setValue(this.getSegmentsArrayForRefill(campaign['targetCustomerSegments']));
    (<FormArray>this.campaignActionForm.get('selectedCampaignActionDelayType')).removeAt(0)
    for (let i = 0; i < campaign.campaignActions.length; i++) {
      const array = new FormGroup({
        'DelayType': new FormControl(this.delayType(campaign.campaignActions[i]['timeToTriggerInMinutes'])),
        'ActionMedium': new FormControl(campaign.campaignActions[i]['campaignActionType']),
        'ActionType': new FormControl(campaign.campaignActions[i]['messageTemplateParams']['messageSubType']),
        'EmailUploadType': new FormControl('TEMPLATE'),
        'messageTemplateId': new FormControl(campaign.campaignActions[i]['messageTemplateParams']['messageTemplateId']),
        'layoutTemplateId': new FormControl(campaign.campaignActions[i]['messageTemplateParams']['emailLayoutTemplateId']),
        'EmailSubject': new FormControl(),
        'EmailContent': new FormControl(),
        'SmsContent': new FormControl(campaign.campaignActions[i]['campaignActionType'] === 'SMS_DELIVERY' ? campaign.campaignActions[i]['messageTemplateParams']['messageBody'] : null),
        'DelayCount': new FormControl(this.convertMinutes(campaign.campaignActions[i]['timeToTriggerInMinutes'])),
        'OfferId': new FormControl(campaign.campaignActions[i]['messageTemplateParams']['offerMasterid']),
        'EmailLayoutContent': new FormControl(campaign.campaignActions[i]['campaignActionType'] === 'EMAIL_DELIVERY' ? campaign.campaignActions[i]['messageTemplateParams']['emailLayoutId'] : null),
        'EmailLayoutSubject': new FormControl(campaign.campaignActions[i]['messageTemplateParams']['messageSubject']),
        'EmailTemplateType': new FormControl(campaign.campaignActions[i]['messageTemplateParams']['campaignMessageLayOutType'])
      });
      (<FormArray>this.campaignActionForm.get('selectedCampaignActionDelayType')).push(array)
    }
    this.changeSegmentSelection()
  }

  getSegmentsArrayForRefill(segment) {
    let segmentArray = [];
    for (let i = 0; i < segment.length; i++) {
      segmentArray.push(segment[i].segmentName)
    }
    return segmentArray;
  }

  convertMinutes(minute) {
    if (minute === null) {
      return (0)
    }
    if (!(minute % 43800)) {
      return (minute / 43800)
    }
    else if (!(minute % 10080)) {
      return (minute / 10080)
    }
    else if (!(minute % 1440)) {
      return (minute / 1440)
    }
    else if (!(minute % 60)) {
      return (minute / 60)
    }
  }

  delayType(minute) {
    if (minute === null) {
      return ("hour")
    }
    else if (!(minute % 43800)) {
      return ("month")
    }
    else if (!(minute % 10080)) {
      return ("week")
    }
    else if (!(minute % 1440)) {
      return ("day")
    }
    else if (!(minute % 60)) {
      return ("hour")
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackbar.openSnackBar(message, 2000);
  }

  getInitData() {
    this.apiCall.getDetailMasterOffers().subscribe((response) => {
      this.masterOffer = JSON.parse(response['body']);
    }, err => {
      this.errorHandle.handleError(err);
    })
    this.apiCall.getEmailTemplates().subscribe((response) => {
      this.emailTemplate = JSON.parse(response['body']);
    },
      err => {
        this.errorHandle.handleError(err);
      })
    // this.apiCall.getSmsTemplates().subscribe((response) => {
    //   this.smsTemplate = JSON.parse(response['body']);
    // },
    //   err => {
    //     this.errorHandle.handleError(err);
    //   })

    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      let segment = JSON.parse(response['body'])
      for (let i = 0; i < segment.length; i++) {
        if (segment[i]['isActive'] === true) {
          this.segments.push({
            'id': segment[i]['id'],
            'name': segment[i]['segmentName'],
            'status': false
          })
        }
      }
    },
      err => {
        this.errorHandle.handleError(err)
      })

    this.apiCall.listEmailLayout().subscribe((response) => {
      this.emailLayout = JSON.parse(response['body'])
    },
      (err) => {
        this.openSnackBar('Failed to load resource', err)
      })
  }

  addActionForm() {
    const control = new FormGroup({
      'DelayType': new FormControl('hour'),
      'ActionMedium': new FormControl('EMAIL_DELIVERY'),
      'ActionType': new FormControl('BASIC_TEMPLATE'),
      'EmailUploadType': new FormControl('TEMPLATE'),
      'messageTemplateId': new FormControl('', Validators.required),
      'layoutTemplateId': new FormControl('', Validators.required),
      'EmailSubject': new FormControl(),
      'EmailContent': new FormControl(),
      'SmsContent': new FormControl('', Validators.required),
      'DelayCount': new FormControl(1, Validators.compose([Validators.min(0), Validators.required])),
      'OfferId': new FormControl(null),
      'EmailLayoutContent': new FormControl(),
      'EmailLayoutSubject': new FormControl(),
      'EmailTemplateType': new FormControl('STANDARD_LAYOUT')
    });
    (<FormArray>this.campaignActionForm.get('selectedCampaignActionDelayType')).push(control)
  }

  //Method to return Customer segment Id of a specific segment name
  getCustomerSegmentId(segmentName) {
    for (let i = 0; i < this.segments.length; i++) {
      if (this.segments[i].name === segmentName) {
        return this.segments[i].id
      }
    }
  }

  //Method to get Message Template Id of a specific message
  getMessageTemplateId(segmentName) {
    for (let i = 0; i < this.emailTemplate.length; i++) {
      if (this.emailTemplate[i].messageTemplateName === segmentName) {
        return this.segments[i].messageTemplateId
      }
    }
  }

  formatDate(date) {
    var datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-ddTHH:mm:SSZZZZZ');
  }

  selectSegment(segment) {
    for (let i = 0; i < this.segments.length; i++) {
      if (segment === this.segments[i].name) {
        this.segments[i].status = !this.segments[i].status
      }
    }
  }

  selectEmailType(i) {
  }

  selectTemplate(type, i) {
    let finalEmailJson = []
    // if (this.campaignActionForm.value.selectedCampaignActionDelayType[i]['ActionType'] === 'OFFER_TEMPLATE') {
    //   const dialogRef = this.dialog.open(DialogOverviewComponent, {
    //     width: '75%',
    //     height: '500px',
    //     data: {
    //       'type': 'campaignOfferView',
    //       'data': this.masterOffer
    //     }
    //   })
    //   dialogRef.afterClosed().subscribe((result) => {
    //     if (result) {
    //       if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'EMAIL') {
    //         this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailSubject.setValue(result['subject'])
    //         this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailContent.setValue(result['body'])
    //         this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.setValue(result['offerMasterId'])
    //       }
    //       if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'SMS') {
    //         this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.SmsContent.setValue(result['body'])
    //         this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.setValue(result['offerMasterId'])
    //       }
    //     }
    //   })
    // }
    // else {
    for (let j = 0; j < this.emailTemplate.length; j++) {
      let emailJson = JSON.parse(this.emailTemplate[j].templateJson)
      if (emailJson.campaignEmailTemplateType === this.campaignActionForm.value.selectedCampaignActionDelayType[i]['ActionType']) {
        finalEmailJson.push({
          'subject': emailJson.subject,
          'name': this.emailTemplate[j].messageTemplateName,
          'body': emailJson.body,
          'id': this.emailTemplate[j].messageTemplateId,
          'offerMasterId': emailJson.offerMasterid ? emailJson.offerMasterid : null
        })
      }
    }
    if (type === 'email') {
      const dialogRef = this.dialog.open(DialogOverviewComponent, {
        width: "50%",
        height: "500px",
        data: {
          'type': 'customEmailListing',
          'data': finalEmailJson
        }
      })

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.messageTemplateId.setValue(result['id'])
          this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailTemplateType.setValue('CUSTOM_LAYOUT')
          //if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'EMAIL') {
          // this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailSubject.setValue(result['subject'])
          // this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailContent.setValue(result['body'])
          //this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.setValue(result['offerMasterId'])
          //this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailTemplateType.setValue('CUSTOM')
          //}
          //if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'SMS') {
          // this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.SmsContent.setValue(result['body'])
          //this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.setValue(result['offerMasterId'])
          //}
        }
      })
    }
    //}
    // if (type === 'sms') {
    //   const dialogRef = this.dialog.open(DialogOverviewComponent, {
    //     width: '25%',
    //     data: {
    //       'type': 'campaignSms',
    //       'data': this.smsTemplate
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe((result) => {
    //     if (result) {
    //       this.smsJson = {
    //         'body': this.smsTemplate[result.index].templateJson
    //       }
    //     }
    //   })
    // }
    // if (type === 'offer') {
    //   const dialogRef = this.dialog.open(DialogOverviewComponent, {
    //     width: "25%",
    //     data: {
    //       'type': 'campaignOffer',
    //       'data': this.masterOffer
    //     }
    //   })
    //   dialogRef.afterClosed().subscribe((result) => {
    //     if (result) {
    //       this.offerJson = {
    //         'offerId': this.masterOffer[result.index].offerId,
    //         'offerName': this.masterOffer[result.index].offerName
    //       }
    //     }
    //   })
    // }
  }

  // selectLayout(index) {
  //   this.apiCall.listEmailLayout().subscribe((response) => {
  //       let emailLayout = JSON.parse(response['body'])
  //       const dialogRef = this.dialog.open(DialogOverviewComponent, {
  //         width: "50%",
  //         data: {
  //           'type': 'campaignLayout',
  //           'data': emailLayout,
  //           'messageTemplateSubType': this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.ActionType.value,
  //           'offerId': this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.ActionType.value === 'OFFER_TEMPLATE' ?
  //             this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.OfferId.value : null
  //         }
  //       })
  //       dialogRef.afterClosed().subscribe((result) => {
  //         if (result) {
  //           //let LayoutContent = result['layoutDescripton']
  //           this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.messageTemplateId.setValue(result['id'])
  //           this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(result['layoutDescripton'])
  //           this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutSubject.setValue(result['layoutSubject'])
  //           this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailTemplateType.setValue('STANDARD_LAYOUT')
  //         }
  //       })
  //   },
  //     (err) => {
  //       this.openSnackBar('Failed to load resource', err)
  //     })
  // }

  addDescriptionText(offerId, index, messageType, operation, medium) {
    if (operation === 'CREATE') {
      this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(null)
      this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutSubject.setValue(null)
    }
    this.apiCall.getAllMessageTemplateVariablesforOffer(offerId).subscribe((response) => {
      let messageVariables = JSON.parse(response['body'])
      this.mentionConfig.mentions[0].items = [];
      for (let key in messageVariables) {
        this.mentionConfig.mentions[0].items.push(key);
      }
      const dialogRef = this.dialog.open(DialogOverviewComponent, {
        width: "50%",
        data: {
          'type': 'campaignLayout',
          'data': this.mentionConfig,
          'medium': medium,
          'operation': operation,
          'subject': operation === 'UPDATE' ? (medium === 'EMAIL_TEMPLATE' ? this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutSubject.value : null) : null,
          'description': operation === 'UPDATE' ? (medium === 'EMAIL_TEMPLATE' ? this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.value :
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.SmsContent.value) : null
        },
        disableClose: true
      })
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          //let LayoutContent = result['layoutDescripton']
          //this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.messageTemplateId.setValue(result['id'])
          if (result.medium === 'EMAIL_TEMPLATE') {
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(result['layoutDescripton'])
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutSubject.setValue(result['layoutSubject'])
          }
          else {
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.SmsContent.setValue(result['layoutDescripton'])
          }
        }
      })
    },
      (err) => {
        this.snackbar.openSnackBar('Failed to load resources', 2000)
      })
  }

  addEmailBody(offerId, index, messageType, operation, medium) {
    let sh = this.finalScreenHeight * (90 / 100)
    let sw = this.finalScreenWidth * (70 / 100)
    this.apiCall.getAllMessageTemplateVariablesforOffer(offerId).subscribe((response) => {
      let messageVariables = JSON.parse(response['body'])
      let finalVariables = [];
      for (let key in messageVariables) {
        finalVariables.push({
          'name': messageVariables[key],
          'value': key
        })
      }
      if (operation === 'CREATE') {
        const dialogRef = this.dialog.open(EmailTemplateCreationDialogComponent, {
          data: {
            process: 'AddTextBody',
            id: this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.layoutTemplateId.value,
            tags: finalVariables
          },
          height: sh + 'px',
          width: this.finalScreenWidth < 1500 ? '1024px' : sw + 'px',
          maxWidth: '99vw',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(result)
          }
        })
      }
      if (operation === 'UPDATE') {
        let id;
        let process;
        if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.value) {
          id = this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.value;
          process = 'EditTextBody';
        }
        else {

          id = this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.layoutTemplateId.value;
          process = 'AddTextBody';
        }
        const dialogRef = this.dialog.open(EmailTemplateCreationDialogComponent, {
          data: {
            process: process,
            id: id,
            tags: finalVariables
          },
          height: sh + 'px',
          width: this.finalScreenWidth < 1500 ? '1024px' : sw + 'px',
          maxWidth: '99vw',
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(result)
          }
        })
      }
    },
      err => { })
  }

  validationCheck() {
    if (this.campaignActionForm.controls.campaignTemplateName.errors) {
      return true;
    }
    if (this.campaignActionForm.controls.targetCustomerSegments.errors) {
      return true;
    }

    for (let i = 0; i < (<FormArray>this.campaignActionForm.get('selectedCampaignActionDelayType')).length; i++) {

      if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['DelayCount']['errors']) { return true; }
      if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['ActionMedium']['value'] === 'EMAIL_DELIVERY') {
        if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['EmailTemplateType']['value'] === 'CUSTOM_LAYOUT') { if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['messageTemplateId']['errors']) { return true; } }

        if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['EmailTemplateType']['value'] === 'STANDARD_LAYOUT') {
          if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['layoutTemplateId']['errors']) { return true; }
          if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['EmailLayoutContent']['errors']) { return true; }
        }
      }

      if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['ActionMedium']['value'] === 'SMS_DELIVERY') {
        if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['SmsContent']['errors']) { return true; }
      }
    }
  }

  addCampaign() {
    this.submitClicked = true;
    if (this.validationCheck()) {
      this.snackbar.openSnackBar('Please check the form fields', 2000)
      return;
    }
    this.apiCall.setCampaign(this.campaignData).subscribe((response) => {
    },
      (err) => {
        this.openSnackBar("error", err)
      })
    //this.apiCall.setCampaign(this.selectedCampaignMasterType);
    // if (this.segments.length === 0) {
    //   this.apiCall.setCampaign('PERSONALIZED')
    // }
    // else {
    //   this.apiCall.setCampaign('SEGMENTED')
    // }
  }

  // validationCheck() {
  //   if (this.campaignActionForm.controls.campaignTemplateName.errors) {
  //     return true;
  //   }
  //   if (this.campaignActionForm.controls.targetCustomerSegments.errors) {
  //     return true;
  //   }

  //   for (let i = 0; i < (<FormArray>this.campaignActionForm.get('selectedCampaignActionDelayType')).length; i++) {

  //     if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['DelayCount']['errors']) { return true; }

  //     if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['ActionMedium']['value'] === 'EMAIL_DELIVERY') {
  //       if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['EmailTemplateType']['value'] === 'CUSTOM_LAYOUT') { if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['messageTemplateId']['errors']) { return true; } }

  //       if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['EmailTemplateType']['value'] === 'STANDARD_LAYOUT') {
  //         if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['layoutTemplateId']['errors']) { return true; }
  //         if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['EmailLayoutContent']['errors']) { return true; }
  //       }
  //     }

  //     if(this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['ActionMedium']['value'] === 'SMS_DELIVERY'){
  //       if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i]['controls']['SmsContent']['errors']) { return true; }
  //     }
  //   }
  // }

  addCampaignConfirm() {
    this.submitClicked = true;
    if (this.validationCheck()) {
      this.snackbar.openSnackBar('Please check the form fields', 2000)
      return;
    }
    let segmentId = [];
    this.campaignActionForm.get('targetCustomerSegments').value.forEach(segmentName => {
      this.segments.forEach((segment) => {
        if (segment['name'] === segmentName) {
          segmentId.push({ 'id': segment['id'] })
        }
      })
    });
    this.apiCall.getCustomerCountInSegmentList(segmentId).subscribe((response) => {
      let count = JSON.parse(response['body'])['totalCustomerCount'];
      if (count < 1) {
        this.snackbar.openSnackBar('No customers in the selected segment', 2000);
        return;
      }
      else {
        this.addCampaignTemplate();
      }
    },
      err => { })
  }

  addCampaignTemplate() {
    this.campaignActions = [];
    this.campaignActionFormData = this.campaignActionForm.value.selectedCampaignActionDelayType
    for (let i = 0; i < this.campaignActionFormData.length; i++) {
      this.campaignActions.push({
        "sequenceNumber": i,
        "campaignActionType": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value,
        "timeToTriggerInMinutes": this.convertToMinutes(this.campaignActionFormData[i].DelayType, this.campaignActionFormData[i].DelayCount),
        // "campaignMessageTemplate": {
        //   "messageTemplateType": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'EMAIL' ? "EMAIL_TEMPLATE" : "SMS_TEMPLATE",
        //   "campaignEmailTemplate": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'EMAIL' ? {
        //     "subject": this.campaignActionFormData[i].EmailSubject,
        //     "body": this.campaignActionFormData[i].EmailContent,
        //     "campaignEmailTemplateType": this.campaignActionFormData[i].ActionType,
        //     "offerMasterid": this.campaignActionFormData[i].OfferId
        //   } : null,
        //   "campaignSMSTemplate": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'SMS' ? {
        //     "body": this.campaignActionFormData[i].SmsContent
        //   } : null
        // }
        "messageTemplateParams": {
          "emailLayoutTemplateId": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailTemplateType.value === 'CUSTOM_LAYOUT' ? this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.messageTemplateId.value :
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.layoutTemplateId.value,
          "offerMasterid": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionType.value === 'OFFER_TEMPLATE' ? this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.OfferId.value : null,
          "campaignMessageLayOutType": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailTemplateType.value,
          "emailLayoutId": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionMedium.value === 'EMAIL_DELIVERY' ?
            (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailTemplateType.value === 'STANDARD_LAYOUT' ?
              this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailLayoutContent.value : null) :
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.SmsContent.value,
          "messageSubject": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailTemplateType.value === 'STANDARD_LAYOUT' ?
            this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailLayoutSubject.value : null,
          "messageSubType": this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.ActionType.value,
        }
      })
    }
    // this.selectedSegments = [];
    // for (let i = 0; i < this.segments.length; i++) {
    //   if (this.segments[i]['status'] === true) {
    //     this.selectedSegments.push({
    //       'id': this.segments[i]['id'],
    //       'segmentName': this.segments[i]['name']
    //     })
    //     this.campaignType = "segmented"
    //   }
    // }

    this.selectedSegments = [];
    if (this.campaignActionForm.controls.targetCustomerSegments.value) {
      for (let i = 0; i < this.campaignActionForm.controls.targetCustomerSegments.value.length; i++) {
        this.selectedSegments.push({
          'id': this.getCustomerSegmentId(this.campaignActionForm.controls.targetCustomerSegments.value[i]),
          'segmentName': this.campaignActionForm.controls.targetCustomerSegments.value[i]
        })
      }
    }

    this.selectedEvent = [{
      'eventName': null,
      'eventID': this.campaignActionForm.controls.selectedCampaignTriggerType.value === 'TIME_TRIGGER' || this.campaignActionForm.controls.selectedCampaignTriggerType.value === 'INSTANT_TRIGGER' ? null : this.campaignActionForm.controls.selectedCampaignEvent.value
    }]
    this.selectedEvent = this.selectedEvent[0]
    this.campaignTrigger = [{
      'campaignTriggerType': this.campaignActionForm.controls.selectedCampaignTriggerType.value,
      // 'startDate': this.selectedCampaignTriggerType === 'INSTANT_TRIGGER' ? null : this.formatDate(this.campaignStartDate),
      // 'endDate': this.selectedCampaignTriggerType === 'INSTANT_TRIGGER' ? null : this.formatDate(this.campaignEndDate),
      'startDate': null,
      'endDate': null,
      'campaignTriggerRepeatType': this.campaignActionForm.controls.selectedCampaignTriggerType.value === 'TIME_TRIGGER' ? this.campaignActionForm.controls.selectedCampaignRepeat.value : null,
      'event': this.campaignActionForm.controls.selectedCampaignTriggerType.value === 'TIME_TRIGGER' || this.campaignActionForm.controls.selectedCampaignTriggerType.value === 'INSTANT_TRIGGER' ? null : this.selectedEvent
    }]
    this.campaignTrigger = this.campaignTrigger[0]
    this.campaignTemplate = [{
      'campaignActions': this.campaignActions,
      'campaignTrigger': this.campaignTrigger,
      'targetCustomerSegments': this.selectedSegments,
      'campaignType': this.campaignActionForm.controls.targetCustomerSegments.value ? 'SEGMENTED' : 'PERSONALIZED',
      'name': this.campaignActionForm.controls.campaignTemplateName.value
    }]
    this.campaignTemplate = this.campaignTemplate[0]
    this.campaignData = [{
      'id': this.route.snapshot.paramMap.get('stat') ? null : this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : null,
      'operation': this.route.snapshot.paramMap.get('stat') ? 'create' : this.route.snapshot.paramMap.get('id') ? 'update' : 'create',
      'merchantId': this.tokenStorage.getMerchantId(),
      'campaignTemplate': this.campaignTemplate
    }]
    this.campaignData = this.campaignData[0]
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: 'Submit Journey?',
        message: 'click ok to submit journey',
        cancelButtonText: "Cancel",
        successButtonText: "Ok",
      }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.setCampaign(this.campaignData).subscribe((response) => {
          this.openSnackBar("Template submitted", "");
        },
          (err) => {
            this.openSnackBar('Error loading resource', err)
          })
      }
    })
  }

  toggleCampaignDetail(index) {
    this.toggleCampaignDetail = index;
  }

  viewTemplates(id, type) {
    if (type === 'sms') {
      // this.apiCall.getSmsTemplates().subscribe((response) => {
      //   response = JSON.parse(response['body'])
      // })
    }
    if (type === 'email') {
      this.apiCall.getEmailTemplates().subscribe((response) => {
        response = JSON.parse(response['body'])
      })
    }
    if (type === 'offer') {
      this.apiCall.getMasterOfferDetails(id).subscribe((response) => {
        response = JSON.parse(response['body'])
      })
    }
  }

  convertToMinutes(type, count) {
    let inMinute;
    if (type === 'hour') {
      inMinute = 60 * count;
      return inMinute
    }
    if (type === 'day') {
      inMinute = 1440 * count;
      return inMinute
    }
    if (type === 'week') {
      inMinute = 10080 * count;
      return inMinute
    }
    // if (type === 'month') {
    //   inMinute = 43800 * count;
    //   return inMinute
    // }
  }
  deleteCampaign(i) {
    (<FormArray>this.campaignActionForm.get('selectedCampaignActionDelayType')).removeAt(i)
  }

  fileChange(event, i) {
    let input = event.target;
    for (let index = 0; index < input.files.length; index++) {
      let reader = new FileReader();
      reader.onload = () => {
        // this 'text' is the content of the file
        var text = reader.result;
        this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][i].controls.EmailContent.setValue(text)
      }
      reader.readAsText(input.files[index]);
    };
  }

  autoselectOfferTemplate(index) {
    this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.layoutTemplateId.setValue(null)
    this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(null)
    if (this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index]['controls']['ActionType']['value'] === 'OFFER_TEMPLATE') {
      this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.OfferId.setValue(this.masterOffer[0]['offerId'])
    }
    else {
      this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.OfferId.setValue(null)
    }
  }

  offerSelectionChange(index) {
    this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.layoutTemplateId.setValue(null)
    this.campaignActionForm.controls.selectedCampaignActionDelayType['controls'][index].controls.EmailLayoutContent.setValue(null)
  }

  changeSegmentSelection() {
    for (let i = 0; i < this.campaignActionForm.controls.targetCustomerSegments['value'].length; i++) {
      if (this.campaignActionForm.controls.targetCustomerSegments['value'][i] === "ALL_CUSTOMERS") {
        this.disableCustomerSegments = true;
        let value = ["ALL_CUSTOMERS"]
        this.campaignActionForm.controls.targetCustomerSegments.setValue(value);
        return;
      }
    }
    this.disableCustomerSegments = false;
  }
}
