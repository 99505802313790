import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-survey-view",
  templateUrl: "./survey-view.component.html",
  styleUrls: ["./survey-view.component.css"],
})
export class SurveyViewComponent implements OnInit {
  editorContainerHeight;
  selectedSurvey = null;
  selectedSurveyOrgHtml = null;
  selectedSurveyOrgJson = null;
  selectedSurveyTitle=null;
  surveyId;
  surveyIdFinal;
  selectedSurveyName;
  type;
  validWidgets = null;
  loading = false;
  appSubscriptionPlan;
  styleUrl=environment.s3SurveyCssUrl;
  constructor(
    private route: ActivatedRoute,
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private router: Router,
    private globalService: Globals,
    private _WidgetConfigService: WidgetConfigService,
    private tokenStorage: TokenStorageService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {

    this.surveyId = this.route.snapshot.paramMap.get("id");
    console.log(this.surveyId)
    console.log(this.styleUrl)
  }

  ngOnInit() {
    this.getWidgets();
    document.getElementById('headTop').innerHTML="<link rel='stylesheet' type='text/css' href="+this.styleUrl+">"
    this.route.params.subscribe((param) => {
      console.log(param)
      if (param["id"]) {
        this.getSurveyTemplateDetail(param["id"]);
        this.surveyId = param["id"];
        this.type = param["type"];
      } else {
        // this.apiCall.getAllSurveyTemplates().subscribe((response) => {
        //   let defaultId;
        //   if (response["message"] === "SUCCESS") {
        //     let surveys = response["body"];
        //     this.getSurveyTemplateDetail(surveys[0]["id"]);
        //   } else {
        //     this.snackBar.openSnackBar("Error", 2000);
        //   }
        // });
      }
    });
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getSurveyTemplateDetail(surveyId: number) {
    this.loading = true;
    if (surveyId > 0) {
      this.apiCall.getSurveyTemplate(surveyId).subscribe(
        (response) => {
          if (response["message"] === "SUCCESS") {
            this.loading = false;
            let survey = JSON.parse(response["body"]);
            this.selectedSurvey = this.selectedSurveyOrgHtml =
              survey["layoutHtml"];
              this.selectedSurveyTitle=survey["title"];
            this.selectedSurveyOrgJson = survey["layoutJson"];
              //For new survey to show the content
              var m = `class="session-newsurvey" id="mainDiv"`;
              var n = `id="mainDiv"`;
              this.selectedSurvey = this.selectedSurvey.replace(m, n);

               m = `id="mainDiv" class="session-newsurvey"`;
               n = `id="mainDiv"`;
              this.selectedSurvey = this.selectedSurvey.replace(m, n);
            //For older survey compatibility
            var a = `id="mainDiv" style="display:none;border-radius: 3px;`;
            var b = `id="mainDiv" style="border-radius: 3px;`;
            this.selectedSurvey = this.selectedSurvey.replace(a, b);

            this.selectedSurveyName = survey["name"];
            this.surveyIdFinal = survey["id"];
                        if (survey["type"] == "REGULAR") {
              this.type = "custom";
            } else {
              this.type = "standard";
            }
          }
        },
        (err) => {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']), 2000);
          this.loading = false;
        }
      );
    } else {
      this.selectedSurvey = "";
      this.selectedSurveyName = "";
      this.surveyIdFinal = "";
      this.loading = false;
    }
  }
  async scheduleTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/surveys/survey-list/schedule",
        { id: this.surveyIdFinal, loc: "list" },
      ]);
    }
  }
  async editTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/surveys/add-survey",
        { id: this.surveyIdFinal, type: this.type },
      ]);
    }
  }
  async cloneTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(this.appSubscriptionPlan)
    ) {
      this.router.navigate([
        "/app/surveys/add-survey",
        { id: this.surveyIdFinal, clone: "1" },
      ]);
    }
  }
  cloneTemplate_remove() {
    var data;
    var i = 2;
    var x;
    var name;
    if (this.selectedSurveyName.indexOf("_copy") !== -1) {
      if (this.selectedSurveyName.indexOf("_copy_0") !== -1) {
        x = this.selectedSurveyName.split("_copy_0")[1];
      } else {
        x = 1;
      }
      var n = parseInt(x) + 1;
      var s = this.selectedSurveyName.split("_copy")[0];
      name = s + "_copy_0" + n;
    } else {
      name = this.selectedSurveyName + "_copy";
    }
    data = {
      id: this.surveyId,
      name: name,
      merchantId: this.tokenStorage.getMerchantId(),
      layoutHtml: this.selectedSurveyOrgHtml,
      layoutJson: this.selectedSurveyOrgJson,
      subject: "Customer survey",
    };
    this.loading = true;
    this.apiCall.saveSurveyTemplate(data, "CREATE").subscribe(
      (response) => {
        if (response["message"] === "SUCCESS") {
          this.loading = false;
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Cloned successfully", ['POS_PAGE']), 2000);
          var idnumber = response["body"];
          this.router.navigate([
            "/app/reach-out/survey/view",
            { id: idnumber, type: "custom", clone: "true" },
          ]);
        } else {
        }
      },
      (err) => {
        console.log("errror", err);

        if (err.error["body"] == "Duplicate Survey Template Name") {
          this.cloneReiterateName(i);
        } else {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to Clone", ['POS_PAGE']), 2000);
          this.loading = false;
        }
      }
    );
  }
  cloneReiterateName(nth) {
    var data;
    var nam = this.selectedSurveyName.split("_copy")[0] + "_copy_0" + nth;
    data = {
      id: this.surveyId,
      name: nam,
      merchantId: this.tokenStorage.getMerchantId(),
      layoutHtml: this.selectedSurveyOrgHtml,
      layoutJson: this.selectedSurveyOrgJson,
      subject: "Customer survey",
    };
    this.apiCall.saveSurveyTemplate(data, "CREATE").subscribe(
      (response) => {
        if (response["message"] === "SUCCESS") {
          this.loading = false;
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Cloned successfully", ['POS_PAGE']), 2000);
          var idnumber = response["body"];
          this.router.navigate([
            "/app/reach-out/survey/view",
            { id: idnumber, type: "custom", clone: "true" },
          ]);
        } else {
        }
      },
      (error) => {
        console.log("errror");
        if (error.error["body"] == "Duplicate Survey Template Name") {
          this.cloneReiterateName(nth + 1);
        } else {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to Clone", ['POS_PAGE']), 2000);
          this.loading = false;
        }
      }
    );
  }
}
