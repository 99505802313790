import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-analytics-dialog-box',
  templateUrl: './filter-analytics-dialog-box.component.html',
  styleUrls: ['./filter-analytics-dialog-box.component.css']
})
export class FilterAnalyticsDialogBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
