
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SignoutService } from "src/app/core/services/signout.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.css"],
})
export class ChangePasswordDialogComponent implements OnInit {
  showPassword1 = false;
  showPassword2 = false;

  showPassword3 = false;
  enterNewPassword = false;
  showNewPasswordConditiond = false;
  changePassword = true;
  success = false;
  oldPasswordError = false;
  newPasswordError = false;
  confirmPasswordError = false;
  oldPassword = "";
  newPassword = "";
  confirmPassword = "";
  oldPasswordErrorValue: String;
  confirmPasswordErrorValue: String;
  newPasswordErrorValue: String;
  pattern = "^(?=.*[@#$%^&+=])(?=.*[0-9])(?=.*[A-Z]).{8,20}$";
  pattern1 = "^.{8,20}$";
  pattern1Error = true;
  pattern2 = "[a-z]";
  pattern2Error = true;
  pattern3 = "[A-Z]";
  pattern3Error = true;
  pattern4 = "[0-9]";
  pattern4Error = true;
  pattern5 = "[@#$%.^&+=*!]";
  pattern5Error = true;
  updatePassword = false;
  passwordError = false;
  passwordErrorValue = "";

  constructor(
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private _signoutService: SignoutService,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    window["screen-name"] = "PROFILE";

  }

  ngOnInit() {}

  onOldPassword(value) {
    this.passwordError = false;
    this.newPasswordError = false;
    if (this.oldPassword === this.newPassword && this.oldPassword.length > 0) {
      this.passwordError = true;
      this.passwordErrorValue =
        "Your current password and the new password can not be the same";
    } else this.passwordError = false;
    if (this.oldPassword === undefined) {
      this.oldPasswordError = true;
      this.oldPasswordErrorValue = "This field is required";
    } else {
      this.oldPasswordError = false;
    }

    //this.onNewPassword(this.newPassword);
  }

  onNewPassword(value) {
    this.newPasswordError = false;
    if (this.oldPassword === this.newPassword && this.oldPassword.length > 0) {
      this.passwordError = true;
      this.passwordErrorValue =
        "Your current password and the new password can not be the same";
    } else this.passwordError = false;

    if (this.newPassword.match(this.pattern1)) {
      this.pattern1Error = false;
    } else {
      this.pattern1Error = true;
    }
    if (this.newPassword.match(this.pattern2)) {
      this.pattern2Error = false;
    } else {
      this.pattern2Error = true;
    }
    if (this.newPassword.match(this.pattern3)) {
      this.pattern3Error = false;
    } else {
      this.pattern3Error = true;
    }
    if (this.newPassword.match(this.pattern4)) {
      this.pattern4Error = false;
    } else {
      this.pattern4Error = true;
    }
    if (this.newPassword.match(this.pattern5)) {
      this.pattern5Error = false;
    } else {
      this.pattern5Error = true;
    }
    if (
      this.pattern1Error == false &&
      this.pattern2Error == false &&
      this.pattern3Error == false &&
      this.pattern4Error == false &&
      this.pattern5Error == false
    ) {
      this.enterNewPassword = false;
    } else {
      this.enterNewPassword = true;
    }
  }

  onConfirm() {
    if (this.updatePassword) {
      if (this.newPassword !== this.confirmPassword) {
        this.confirmPasswordError = true;
        this.confirmPasswordErrorValue = "Passwords do not match";
      } else {
        this.confirmPasswordError = false;
        this.updatePassword = false;
      }
    }
  }

  show1() {
    this.showPassword1 = !this.showPassword1;
  }

  show2() {
    this.showPassword2 = !this.showPassword2;
  }

  show3() {
    this.showPassword3 = !this.showPassword3;
  }

  showErrorBox() {
    this.showNewPasswordConditiond = true;
  }

  update() {

    this.updatePassword = true;
    if((this.oldPassword === undefined || this.oldPassword === "") && this.data.isPasswordGenerated) {
      this.oldPasswordError=true;
      this.oldPasswordErrorValue="This field is required";
    }
    if(this.data.isPasswordGenerated) {
      this.oldPasswordError=false;
     // this.oldPassword=null;
    }
    if(this.newPassword === "") {
      this.newPasswordError=true;
      this.newPasswordErrorValue="This field is required";
    }
    // if (this.confirmPassword === "") {
    //   this.confirmPasswordError = true;
    //   this.confirmPasswordErrorValue = "This field is required";
    // }
    if (this.newPassword !== this.confirmPassword) {
      this.confirmPasswordError = true;
      this.confirmPasswordErrorValue = "Passwords do not match";
    } else {
      this.confirmPasswordError = false;
    }
    if (!this.confirmPasswordError) {
      if (
        !this.pattern1Error &&
        !this.pattern2Error &&
        !this.pattern3Error &&
        !this.pattern4Error &&
        !this.pattern5Error
      ) {
        if (
          !this.oldPasswordError &&
          !this.newPasswordError &&
          !this.confirmPasswordError &&
          !this.passwordError
        ) {
          if(!this.data.isPasswordGenerated){
            this.oldPassword=null
          }
          this.apiCall
            .changePassword(this.oldPassword, this.newPassword)
            .subscribe((result) => {
              if (result["message"] == "SUCCESS") {
                var data = result["body"];
                this.tokenStorage.saveToken(data.accessToken);
                this.tokenStorage.saveUsername(data.username);
                this.tokenStorage.saveAuthorities(data.authorities);
                // this.tokenStorage.setUserLogin("true");
                this.changePassword = false;
                this.success = true;
                this.snackBar.open('Password changed, please relogin', 'Close', {
                  duration: 2000,  // Snackbar will be visible for 1 second (1000 ms)
                });
                setTimeout(() => {
                  this._signoutService.signOut();
                  this.tokenStorage.setUserLogin("false");
                  this.router.navigate(["auth/login"], {});
                }, 2000); 
              } else {
                this.passwordError = true;
                this.passwordErrorValue = "Current Password is incorrect";
              }
            });
        }
      }
    }
  }

  submit(decision){
    if(!this.changePassword)
    decision=1;
    this.dialogRef.close(decision)
  }

}
