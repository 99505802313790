<div class="main-container">
  <div style="display: flex; justify-content: flex-end; margin-bottom: 16px">
    <mat-icon (click)="dialogRef.close(false)" class="close-icon">close</mat-icon>
  </div>
  <div class="done-icon-container">
    <mat-icon class="done-icon">done</mat-icon>
  </div>
  <span class="message">{{ data["message"] }}</span>
  <div class="done-icon-container" style="margin-top: 15px">
    <span class="back-button" (click)="submit()">{{ data["buttonText"] }}</span>
  </div>
</div>
