import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnChanges,
  Input,
  OnDestroy,
} from "@angular/core";
import * as d3 from "d3";
import { ViewEncapsulation } from "@angular/core";
import { EventsService } from "src/app/core/services/events.service";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";

@Component({
  selector: "app-insights-bar-chart",
  templateUrl: "./insights-bar-chart.component.html",
  styleUrls: ["./insights-bar-chart.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class InsightsBarChartComponent implements OnInit,OnChanges,OnDestroy {
  @Input() data: { name: string; value: number; originalDate: string }[];
  @Input() sortBy: string;
  @Input() chartSize:number;
  @ViewChild("chartContainer") chartContainer: ElementRef;
  private chartContainerElement: any;
  parseDate;
  formatTime;
  hoverFormat;
  barRectOffset: number = 0;
  sidenavSubject;
normalizeXaxisnumber=1;
max=0;
  constructor(private events: EventsService,private _SidenavOperationService: SidenavOperationService) {}
  ngOnDestroy() {
    if(this.sidenavSubject) this.sidenavSubject.unsubscribe();
  }
  ngOnInit() {

    console.log(this.data)
    console.log(this.sortBy)

    console.log(this.chartSize)

    // let svgHeight: number = this.chartContainer.nativeElement.offsetHeight;
    // let svgWidth: number = this.chartContainer.nativeElement.offsetWidth;
        // switch (this.sortBy) {
    //   case "month": {
    //     this.parseDate = d3.timeParse("%Y-%m-%d");
    //     this.formatTime = d3.timeFormat("%b");
    //     this.hoverFormat = d3.timeFormat("%b %Y");
    //     break;
    //   }
    //   case "day": {
    //     this.parseDate = d3.timeParse("%Y-%m-%d");
    //     this.formatTime = d3.timeFormat("%b %d");
    //     this.hoverFormat = d3.timeFormat("%d %b %Y");
    //     break;
    //   }
    //   case "year": {
    //     this.parseDate = d3.timeParse("%Y-%m-%d");
    //     this.formatTime = d3.timeFormat("%Y");
    //     this.hoverFormat = d3.timeFormat("%Y");
    //     break;
    //   }
    //   default: {
    //     this.parseDate = d3.timeParse("%Y-%m-%d");
    //     this.formatTime = d3.timeFormat("%b %d %Y");
    //     this.hoverFormat = d3.timeFormat("%b %d %Y");
    //     break;
    //   }
    // }


    // var i, n;
    // for (i = 0, n = this.data.length; i < n; i++) {
    //   var d = this.data[i];
    //   for (var c in d) {
    //     if (c == "name") {
    //       d[c] = this.parseDate(d[c]);
    //       d[c] = this.formatTime(d[c]);
    //     }
    //     if (c == "originalDate") {
    //       d[c] = this.parseDate(d[c]);
    //       d[c] = this.hoverFormat(d[c]);
    //     }
    //   }
    //   this.data[i] = d;
    // }

    this.sidenavSubject = this._SidenavOperationService.sidenavStatus.subscribe(
      (response) => {
        d3.select("#insightChartContainer").selectAll("svg").remove();
        let interval = setTimeout(() => {
          if(this.chartContainer.nativeElement){
            this.newgraph(this.data,this.normalizeXaxisnumber);

            clearInterval(interval);
          }
        },500);
      }
    );

  }

  ngOnChanges() {
    d3.select("#insightChartContainer").selectAll("svg").remove();
    if(this.data.length<=10){
      this.normalizeXaxisnumber=1;
    }else if(this.data.length>10&&this.data.length<20){
      this.normalizeXaxisnumber=2;

    // else if(this.data.length>=25&&this.data.length<=50){
    //   this.normalizeXaxisnumber=4;
    // } else if(this.data.length>50&&this.data.length<=100){
    //   this.normalizeXaxisnumber=6;
    // } else if(this.data.length>100){
    //   this.normalizeXaxisnumber=10;
    }else{
      this.normalizeXaxisnumber=Math.ceil(this.data.length/10);
      console.log(this.normalizeXaxisnumber)
    }

      this.newgraph(this.data,this.normalizeXaxisnumber);

  }


  getYRange(min: number, max: number) {
    let minFloor,
      maxCeil,
      level = 1,
      diffUnit,
      ticks: number[] = [];
    while (max % level !== max) {
      level = level * 10;
    }
    level /= 10;
    minFloor = parseInt("" + min / level) * level;
    maxCeil = (parseInt("" + max / level) + 1) * level;
    diffUnit = (maxCeil - minFloor) / 4;
    // ticks.push(minFloor);
    for (let i = minFloor; i <= maxCeil; i += diffUnit) {
      ticks.push(i);
    }
    return {
      min: minFloor,
      max: maxCeil,
      ticks: ticks,
    };
  }
  newgraph(dataset,nm) {
    dataset.forEach((value) => {
      console.log(value['value'])
      if (value['value'] > this.max) this.max = value['value'];
    });
    console.log(dataset)
    console.log(nm)
    console.log(this.max)
    if (this.max == 0) this.max = 100;
    d3.select("#insightChartContainer").selectAll("svg").remove();
    // Article: https://dev.to/chooblarin/d3js-chart-with-gradient-color-4j71

    //const margin = { top: 20, right: 20, bottom: 160, left: 45 }
    // let margin: any = { top: 20, right: 10, bottom: 60, left: 10 };
    // const svgWidth = 420;
    // const svgHeight = 320;
    var maxDataDigitLength=d3.max(dataset, (d) => d.value).toString().length;
console.log(maxDataDigitLength)
    const margin = { top: 20, right: 20, bottom: 20, left: 45 };
    let svgHeight: number =  this.chartSize-10
    let svgWidth: number = this.chartContainer.nativeElement.offsetParent.offsetWidth - 10;
    const width = svgWidth - margin.left - margin.right-10*maxDataDigitLength;
    const height = svgHeight - margin.top - margin.bottom;
    console.log(maxDataDigitLength)
    console.log(svgHeight)

    const svg = d3
      .select(".containerInsightGraph")
      .append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight);

    const defs = svg.append("defs");

    const bgGradient = defs
      .append("linearGradient")
      .attr("id", "bg-gradient")
      .attr("gradientTransform", "rotate(90)");
    bgGradient
      .append("stop")
      .attr("stop-color", "#00BABE")
      .attr("offset", "0%");
    bgGradient
      .append("stop")
      .attr("stop-color", "#00BABE")
      .attr("offset", "100%");
    // var tip = d3Tip()
    // .attr('class', 'd3-tip')
    // // .offset([-10, 0])
    // .html(function(d) {
    //   return "<div style='background:#fff'><strong>"+d.name+" : </strong> <span style='color:red'>" + d.value + "</span></div>";
    // })

    // .html(function(d) {
    //     return "<div style='background:#fff'><strong>"+d.name+" : </strong> <span style='color:red'>" + d.value + "</span></div>";
    //   });
    // svg.call(tip);
    const graphArea = svg
      .append("g")
      .attr("transform", `translate(${margin.left+5*maxDataDigitLength}, ${margin.top-5})`);

    (async () => {
      let data = dataset;
           const getX = (d) => d["name"];
      const getY = (d) => +d["value"]; // convert to number from string

      // filter Top 10 data, and sort by num of votes
      // data = data
      //   .slice(0, 10)
      //   .sort( (a, b) => -(getY(a) - getY(b)));

      var barSize = 40;
      let xValues = this.getYRange(0, d3.max(data, (d) => d.value))
      var xtickValues = xValues.ticks;
      const x = d3
        .scaleBand()
        .rangeRound([0, width])
        .domain(data.map(getX))
        .padding(0.1);

      const y = d3
        .scaleLinear()
        .range([height, 0])
        // .domain([0, d3.max(data, getY)])
        .domain([0, this.max])
        .nice();

      const xAxis = d3.axisBottom(x).tickValues(x.domain().filter(function(d,i){ return !(i%nm)}));
      ;
      const yAxis = d3.axisLeft(y).ticks(5);
      let yValues = this.getYRange(
        0,
        d3.max(data, (d) => d.value)
      );
      let yMin = yValues.min;
      let yMax = yValues.max;
      let tickValues = yValues.ticks;

      let yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);
      // var tooltip = d3.select("body").append("div").attr("class", "toolTip");

      let yAxisTickLines = d3
        .axisLeft(yScale)
        .tickValues(tickValues)
        .tickSize(-width)
        .tickFormat("");
      //       var yAxis = d3.svg.axis().scale(yScale)
      // .orient("left").tickSize(0).ticks(0)
      // .tickFormat("");

      const ax = graphArea
        .append("g")
        .attr("class", "axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAxis)


      // ax.selectAll("text")
      //   .style("text-anchor", "middle")
      //   .style("alignment-baseline", "middle")
      //   // .attr("transform", `translate(${x.bandwidth() / 3}, 10) rotate(0)`);
      //   .attr("transform", `translate(${x.bandwidth() / 2}, 10)`)
      //   .attr("transform", "rotate(0)");

      graphArea
        .append("g")
        .attr("class", "axis")
        // .attr('class', 'y-axis axis')
        // .attr("display", "none")
        .call(yAxis)
       .call((g) => g.select(".domain").remove());

      // function for the y grid lines
      // function make_y_axis() {
      //   return d3.axisLeft()
      //   .scale(yScale);
      // }

      // Draw the y Grid lines
      // svg.append("g")
      //   .attr("class", "grid")
      //   .call(make_y_axis()
      //     .tickSize(-width, 0, 0)
      //     .tickFormat("")
      //   );

      graphArea
        .append("g")
        .attr("class", "grid")
        .call(yAxisTickLines)
        .attr("color", "#c2c2c2")
        .style("stroke-dasharray", "3, 3")
        .call((g) => g.select(".domain").remove());

      var tooltip = d3.select("body").append("div").attr("class", "toolTip-insights");

      graphArea
        .selectAll("bar")
        .data(data)
        .enter()
        .append("rect")
        .style("fill", "url(#bg-gradient)")
        .attr("x", (d) => {
          return data.length < 10 && x.bandwidth() > 100
            ? x(getX(d)) + x.bandwidth() / 2 - 30
            : x(getX(d));
        })
        .attr("y", (d) => y(getY(d)))
        .attr(
          "width",
          data.length < 10 && x.bandwidth() > 100 ? 60 : x.bandwidth()
        )
        // .attr("width",x.bandwidth())
        .attr("height", (d) => height - y(getY(d)))
        .on("mousemove", function (event, d) {
          console.log("D",event)
                   tooltip
            .style("left", d3.event.pageX - 50 + "px")
            .style("top", d3.event.pageY - 70 + "px")
            .style("display", "inline-block")
            .html(
              "<div style='display:flex;'><span>" +
                event.originalDate +
                "</span><span> &nbsp;|  &nbsp;</span><span style='display:flex; justify-content: center;color:#fff'>" +
                event.value?.toFixed(0) +
                "</span></div>"
            );
        });
      graphArea.on("mouseout", function (d) {
        tooltip.style("display", "none");
      });
    })();
  }
}
