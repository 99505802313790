import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { currentPlanDataService } from "src/app/core/services/current-plan-global.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { ConfirmationDialogBoxComponent, PaymentPosSucessDialogComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

interface planObj {
  customerAvailable: number;
  emailCreditsAvailable: number;
  endDate: any;
  merchantId: number;
  orderId: number;
  planName: string;
  planPlatForm: string;
  planType: string;
  smsCreditsAvailable: number;
  startDate: any;
  status: string;
  subscriptionMasterPlanId: number;
  subscriptionPlanId: number;
  subscriptionSubPlanId: number;
  totalEmails: number;
  totalSMS: number;
  userEmail: string;
}
@Component({
  selector: "app-plan-details",
  templateUrl: "./plan-details.component.html",
  styleUrls: ["./plan-details.component.css"],
})
export class PlanDetailsComponent implements OnInit {
  constructor(
    private _apiCallService: ApiCallService,
    private _router: Router,
    private currentPlanData: currentPlanDataService,
    private _dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private _datePipe: DatePipe,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService,
    private _WidgetConfigService: WidgetConfigService
  ) {}

  currentPlanBody: any;
  paymentHistoryBody: any;
  currentPlanLoading = true;
  paymentHistoryloading = true;
  paymentItems = [];
  isendDateRequired: boolean = false;
  currenPlanEndDate;
  cancelButtonLoading = false;
  isThereCurrentPlan:boolean=false;
  isDemo;
  isShopify = false;
  revCurrency = "";
  trial = false;
  planCancelled = false;
  validWidgets = null;
  validScreens = null;
  isAutoUpdate: boolean = false;
  enterprisePlan: boolean = false;
  transactionHistory = [];
  totalHistory = 10;
  page = 1;
  size = 100;

  async ngOnInit() {
    this.getWidgets();
    this.validScreens = await this._WidgetConfigService.getScreen();
    this.isDemo = this.tokenStorage.getIsDemo();
    this.getAutoUpdateStatus();
    this.getTransactionHistoryData();
    this.getCurrentPlanDetals();
    if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this.getPaymentHistory();
    }
    else{
      this.getPaymentHistoryPOS();
    }
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }
  getCurrentPlanDetals() {
    this.currentPlanLoading = true;
    if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this.isShopify = true;
      this._apiCallService.getCurrentPlanData().subscribe((response) => {
        this.currentPlanBody = response["body"];
        this.isThereCurrentPlan=true;
        if (this.currentPlanBody != null && this.currentPlanBody != undefined) {
          // if (this.currentPlanBody.planName == "Basic") {
          //   this.isendDateRequired = false;
          // } else {
            this.isendDateRequired = true;
           // this.currenPlanEndDate = new Date(this.currentPlanBody.endDate);
           this.currenPlanEndDate = this.currentPlanBody.endDate[0]+"-"+this.currentPlanBody.endDate[1]+"-"+this.currentPlanBody.endDate[2];
          // }
        } else {
          this._router.navigate(["/payment-plan"]);
        }
        this.currentPlanData.setCurrentPlanBody(this.currentPlanBody);
        this.currentPlanLoading = false;
      },(err)=>{
        this.currentPlanData.setCurrentPlanBody(null);
        this.isThereCurrentPlan=false;
        this.currentPlanLoading = false;
      });
    }
    else{

      this.isShopify = false;
      this._apiCallService.getCurrentPlanPos().subscribe((response) => {
        this.currentPlanBody = response["body"];
        this.isThereCurrentPlan=true;
        if (this.currentPlanBody != null && this.currentPlanBody != undefined) {
          // if (this.currentPlanBody.planName == "Basic") {
          //   this.isendDateRequired = false;
          // } else {
            this.trial = this.currentPlanBody['trial'];
            this.planCancelled = this.currentPlanBody['planCancelled'];
            this.enterprisePlan = this.currentPlanBody['enterprisePlan'];
            this.isendDateRequired = true;
           // this.currenPlanEndDate = new Date(this.currentPlanBody.endDate);
           if(this.currentPlanBody.status=='FRESH'){
            this.isThereCurrentPlan=false;
           }else{
           this.currenPlanEndDate = this.currentPlanBody.endDate[0]+"-"+this.currentPlanBody.endDate[1]+"-"+this.currentPlanBody.endDate[2];
           }
          // this.currenPlanEndDate = "12-12-23"
           this.currentPlanLoading = false;
         
          // }
        } else {
          this._router.navigate(["/payment-plan-pos"]);
        }
        this.currentPlanData.setCurrentPlanBody(this.currentPlanBody);
        this.currentPlanLoading = false;
        console.log("HellooHere", this.currentPlanLoading)
      },(err)=>{
        this.currentPlanData.setCurrentPlanBody(null);
        this.isThereCurrentPlan=false;
        this.currentPlanLoading = false;
      });
    }
  }

  getPaymentHistory() {
    this.paymentHistoryloading = true;
    this.isShopify = true;
    this._apiCallService.getPaymentHistory().subscribe((response) => {
      this.paymentHistoryBody = response["body"];
      console.log("PAYMENTHISTORY", this.paymentHistoryBody)

      this.paymentItems = this.paymentHistoryBody.paymentItems;
      this.paymentHistoryloading = false;
    },(error)=>{
      this.paymentHistoryloading = false;
    });
  }
  getPaymentHistoryPOS(){
    this.isShopify = false;
    this.paymentHistoryloading = true;
    this._apiCallService.getPaymentHistoryPos().subscribe((response) => {
      this.paymentHistoryBody = response["body"];
      console.log("PAYMENTHISTORY", this.paymentHistoryBody)
      this.paymentItems = [...this.paymentHistoryBody.plans, ...this.paymentHistoryBody.addons];
      console.log(this.paymentItems)
      this.paymentHistoryloading = false;
    },(error)=>{
      this.paymentHistoryloading = false;
    });
  }

  navigateTo(page) {
    if ("changeplan" == page) {
      if(this.tokenStorage.getMerchantOnlineStore() === 'shopify')
      this._router.navigate(["/app/payments/payment-plan"]);
      else
      window.open('https://qubriux.skellam.ai/pricing/qubriux-ecommerce', "_blank");
    }
    if ("topup" == page) {
      this._router.navigate(["/app/payments/top-up"]);
    }
    if("topupPos" == page){
      this._router.navigate(["/app/payments/top-up-pos"]);
    }
    if("changeplanPos" == page){
      this._router.navigate(["/app/payments/payment-plan-pos"]);
    }
  }
  cancelMySubscription() {
    console.log(this.currenPlanEndDate)
    let lastDate= this._datePipe.transform(this.currenPlanEndDate, "dd MMM yyyy");
    const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "47%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Cancel Subscription", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Your plan will be cancelled, but you will be able to use Qubriux features till", ['POS_PAGE']) + " " +lastDate+"." + this._i18nDynamicTranslate.transform(" After this date you will not be able to use any features. To use Qubriux again please subscribe to the best suited plan for you.", ['POS_PAGE']),
        submessage: this._i18nDynamicTranslate.transform("Are you sure you want to cancel the plan?", ['POS_PAGE']),
        cancelButtonText: false,
        successButtonText: this._i18nDynamicTranslate.transform("Cancel Plan", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelButtonLoading=true;
        this._apiCallService.cancelSubscription().subscribe(
          (response) => {
            this.cancelButtonLoading=false;
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Subscription cancelled", ['POS_PAGE']), 2000);
            this.getCurrentPlanDetals();
            this.getPaymentHistory();
            // this.currentPlanService.getPlanStatus();
          },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']), 2000);
          }
        );
      }
    });
  }
  planSucessDialogBox(){
    const dialogRef = this._dialog.open(PaymentPosSucessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "600px",
      height: "370px",
      data: {
        heading: this._i18nDynamicTranslate.transform("Payment Successful", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Your plan has been added to your current billing cycle.", ['POS_PAGE']),
        type : "PLAN",
        buttonText: this._i18nDynamicTranslate.transform("Go to Home page", ['POS_PAGE']),
        status: status
      },
    });
    }
  cancelMySubscriptionPOS(){
    const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "47%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Sorry to see you go :(", ['POS_PAGE']),
        message: "",
        submessage: this._i18nDynamicTranslate.transform("Are you sure you want to cancel the plan?", ['POS_PAGE']),
        cancelButtonText: false,
        successButtonText: this._i18nDynamicTranslate.transform("Cancel Plan", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelButtonLoading=true;
        this._apiCallService.cancelSubscriptionPOS().subscribe(
          (response) => {
            this.cancelButtonLoading=false;
            const dialogRef = this._dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Subscription cancelled successfully.", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("Go Back", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.planCancelled = true;
            });
          },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']), 2000);
          }
        );
      }
    });
  }
  renewPlanPOS(){
    this._router.navigate(["/app/payments/payment-plan-pos"]);
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  getAutoUpdateStatus(){
    this._apiCallService.getOrChangeAutoTopUp().subscribe((response)=>{
      if(response){
        this.isAutoUpdate = response['body'];
      }
    },(err)=>{
      this.isAutoUpdate = false;
    })
  }

  changeAutoUpdateStatus(status){
    this._apiCallService.getOrChangeAutoTopUp(status).subscribe((response)=>{
      if(response){
        this.isAutoUpdate = status;
      }
    },(err)=>{
      this.isAutoUpdate = !status;
    });
  }
  toggeleAutoUpdate(event){
    if (!this.isAutoUpdate) {
      this.changeAutoUpdateStatus(true);
    } else {
      const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "400px",
        data: {
          subject: this._i18nDynamicTranslate.transform("Confirm Auto Top-Up Change", ['POS_PAGE']),
          message: "",
          submessage: this._i18nDynamicTranslate.transform("Are you sure you want to disable auto top-up? Doing so will impact all your active journeys, offers, and other related modules. Please confirm if you wish to proceed with this change.", ['POS_PAGE']),
          cancelButtonText: "Cancel",
          successButtonText: this._i18nDynamicTranslate.transform("Turn Off", ['POS_PAGE']),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.changeAutoUpdateStatus(false);  
        }
        else{
          event.preventDefault();
        }
      });
    }
  }

  getTransactionHistoryData(){
    this._apiCallService.getTransactionHistory(this.page, this.size).subscribe((response)=>{
      this.transactionHistory = response['body'];
    })
  }

  getNextData(event){
    this.page = event.pageIndex + 1;
    this.size = event.pageSize;
    this.getTransactionHistoryData();
  }
}