import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-offer-channel-add-confirmation-dialog',
  templateUrl: './offer-channel-add-confirmation-dialog.component.html',
  styleUrls: ['./offer-channel-add-confirmation-dialog.component.css']
})
export class OfferChannelAddConfirmationDialogComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<OfferChannelAddConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {    window["screen-name"] = "OFFER CREATE"; }
    barrierParamType;
    rewardParamType;

  ngOnInit() {
  }
  submit(decision){
    this.dialogRef.close(decision)
  }
}
