<button class="primary-button margin-auto" color="primary" *ngIf="!isAddButtonClicked && showAc" (click)="isAddButtonClicked = true;">
    <span class="material-icons-outlined button-icon" style="color: #fff">
      add
    </span>
    <span>{{'CAMPAIGN_PAGE.ADD_FILTER' | i18nTranslate}}</span>
</button>

<div class="comp-container"  *ngIf="isAddButtonClicked && showAc">

    <h5>Additional Configuration</h5>

    <div *ngFor="let logicalExpression of logicalExpressions;let logicalExpIndex = index" class="logical-exp pos-relative"
        [class.first-logical-expression]="logicalExpIndex === 0">

        <div class="delete-le-container" *ngIf="logicalExpIndex!=0" (click)="deleteLogicalExpression(logicalExpIndex)">
            <span class="material-symbols-outlined small-icon-size" title="Delete Group">delete</span>
        </div>

        <div class="le-lo-options" *ngIf="logicalExpIndex>0">
            <div class="lo-and-or-selector">
                <div class="each-selector selector-left right-border-radius-none" (click)="selectLeCondition('AND',logicalExpression)"
                    [ngClass]="{'lo-active': logicalExpression.logicalOperator === 'AND' }">
                    AND
                </div>
                <div class="each-selector selector-right left-border-radius-none" (click)="selectLeCondition('OR',logicalExpression)"
                    [ngClass]="{'lo-active': logicalExpression.logicalOperator === 'OR' }">
                    OR
                </div>
                <div class="selector-line"></div>
            </div>
        </div>

        <div class="cs-lo-options">
            <div class="lo-and-or-selector" *ngIf="logicalExpression.comparisonStatements.length > 1">
                <div class="each-selector selector-left right-border-radius-none" (click)="selectCsCondition('AND',logicalExpression)"
                    [ngClass]="{'lo-active': logicalExpression.comparisonStatements[1].logicalOperator === 'AND' }">
                    AND
                </div>
                <div class="each-selector selector-right left-border-radius-none" (click)="selectCsCondition('OR',logicalExpression)"
                    [ngClass]="{'lo-active': logicalExpression.comparisonStatements[1].logicalOperator === 'OR' }">
                    OR
                </div>
            </div>
            <div class="add-cs" (click)="addComparisionStatement(logicalExpression)">
                <mat-icon class="mat-icon special-plus">add</mat-icon>
                <span>Add Parameter</span>
            </div>
        </div>

        <div *ngFor="let comparisonStatement of logicalExpression.comparisonStatements;let compIndex = index" class="cs-container pos-relative">
            <div class="delete-cs-container" *ngIf="compIndex!=0" (click)="deleteComparisonStatement(compIndex,logicalExpression)">
                <span class="material-symbols-outlined small-icon-size">delete</span>
            </div>
            <div class="op-container">
                <div class="op-title">
                    Parameter
                </div>
                <mat-select [(ngModel)]="comparisonStatement.operand1" placeholder="Select Parameter" (selectionChange)="selectOp1(comparisonStatement)" class="mat-select-style">
                    <mat-option *ngFor="let op of additionalConfigs" [value]="op">{{ op.variableDisplayName }}</mat-option>
                </mat-select>
            </div>

            <div class="op-container">
                <div class="op-title">
                    Operator
                </div>
                <mat-select [(ngModel)]="comparisonStatement.operator" placeholder="Select Operator" class="mat-select-style"> 
                    <mat-option *ngFor="let op of comparisonStatement.operand1.operators" [value]="op">{{ op.operator }}</mat-option>
                </mat-select>
            </div>


            <div class="op-container" *ngIf="comparisonStatement.operand2.dataType != 'boolean'">
                <div class="op-title">
                    Value
                </div>
                <div *ngIf="comparisonStatement.operand2.dataType == 'date'">
                    <input
                        [(ngModel)] = "comparisonStatement.operand2.value"
                        class="value-input"
                        matInput
                        [matDatepicker]="picker1"
                        placeholder="Choose a date"
                        readonly
                        (click)="picker1.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker1" class="cal-icon-pos">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </div>
                <div *ngIf="comparisonStatement.operand2.dataType == 'string'" class="pos-relative">
                    <input type="text" (keydown)="stringInputHandler($event, 50, comparisonStatement)" #inp1 maxlength="50" 
                    class="value-input" [(ngModel)]="comparisonStatement.operand2.value">
                    <div class="inp-text-left">{{inp1.getAttribute('maxlength') - inp1.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                </div>
                <div *ngIf="comparisonStatement.operand2.dataType == 'email'"  class="pos-relative">
                    <input type="email" (keydown)="stringInputHandler($event,50)"
                    class="value-input" [(ngModel)]="comparisonStatement.operand2.value" #inp maxlength="50">
                    <div class="inp-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                    <div class="inp-error" *ngIf="!validateEmail(comparisonStatement.operand2.value)">Please enter valid email</div>
                </div>
                <div *ngIf="comparisonStatement.operand2.dataType == 'number'" class="pos-relative">
                    <input type="number" (keydown)="numberInputHandler($event, 10)" class="value-input" #inp maxlength="10"
                    [(ngModel)]="comparisonStatement.operand2.value" min="0" max="9999999999">
                    <div class="inp-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                </div>
                <div *ngIf="comparisonStatement.operand2.dataType == 'product'" class="pos-relative">
                    <!-- <mat-select [(ngModel)]="comparisonStatement.operand2.value" placeholder="Select Product" class="mat-select-style">
                        <mat-option *ngFor="let productName of apiProductList" [value]="productName">{{productName}}</mat-option>
                    </mat-select> -->
                    <button [matMenuTriggerFor]="menu1" (menuOpened)="onProductMenuOpened()" class="mat-select-style">{{comparisonStatement.operand2.value || 'Select Product'}} <div class="arrow-down"></div></button>
                    <mat-menu #menu1="matMenu" [yPosition]="'below'" [panelClass]="'dropdown-menu'">
                        <div class="menu-content">
                            <input class="op-search-2" placeholder="Search" type="text" (keydown)="filterProduct($event)"  (click)="$event.stopPropagation();" [(ngModel)]="productSearch"/>
                            <div class="search-filtered-dropdown-2">
                                <div *ngFor="let productName of filteredProductList" class="dropdown-opt-2"
                                    (click)="setProduct(productName,comparisonStatement)"
                                    [ngClass]="{'active-opt-2': comparisonStatement.operand2.value === productName}">
                                    {{productName}}
                                </div>
                                <div class="no-data" *ngIf="filteredProductList.length==0">
                                    {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                </div>
                            </div>
                        </div>
                    </mat-menu>
                </div>
                <div *ngIf="comparisonStatement.operand2.dataType == 'store'" class="pos-relative">
                    <button [matMenuTriggerFor]="menu" (menuOpened)="onStoreMenuOpened()" class="mat-select-style">{{getSelectedStore(comparisonStatement) || 'Select Store'}} <div class="arrow-down"></div></button>
                    <mat-menu #menu="matMenu" [yPosition]="'below'" [panelClass]="'dropdown-menu'">
                        <div class="menu-content">
                            <input class="op-search-2" placeholder="Search" type="text" (keydown)="filterStore($event)"  (click)="$event.stopPropagation();" [(ngModel)]="storeSearch"/>
                            <div class="search-filtered-dropdown-2">
                                <div *ngFor="let store of filteredStoreList" class="dropdown-opt-2"
                                    (click)="setStoreId(store.qid,comparisonStatement)"
                                    [ngClass]="{'active-opt-2': comparisonStatement.operand2.value === store.qid}">
                                    {{store.storeName}}
                                </div>
                                <div class="no-data" *ngIf="filteredStoreList.length==0">
                                    {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                </div>
                            </div>
                        </div>
                    </mat-menu>
                </div>
            </div>

            <div class="cs-condition-linker" *ngIf="compIndex!=0">
                <div class="qb-AND-operator-background">
                    <div class="qb-segment-logical-operator-container">
                        <span class="qb-operator-text">{{comparisonStatement.logicalOperator}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-button-container">
        <div class="primary-button cancel-button" (click)="reset()">
            <span>Cancel</span>
        </div>
        <div class="primary-button" (click)="addLogicalExpression()">
            <mat-icon class="mat-icon">add</mat-icon>
            <span>Add New Group</span>
        </div>
    </div>
    

</div>