<div style="width: 100%; display: flex; justify-content: center; height: 100%">
  <div [ngClass]="channel == 'WhatsApp' ? 'phone-container-whatsapp' : 'phone-container-sms'">
    <div [ngClass]="channel == 'WhatsApp' ? 'phone-background-whatsapp' : 'phone-background-sms'"></div>
    <div class="phone-image">
      <div class="phone-background-whatsapp-contact-name" *ngIf="channel == 'WhatsApp'">
        <div class="qb-d-flex">
          <span style="color: black; font-size: 20px;">{{shopName | limitTo: 10}}</span>
          <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/verified_logo.svg" style="margin-top: 7px; margin-left: 5px;" width="20px" height="20px">
        </div>
      </div>
      <div class="text-container-div">
        <div class="whole">
          <div [ngClass]="channel == 'WhatsApp' ? 'preview-text-content-whatsapp' : 'preview-text-content-sms'">
            <div *ngIf="channel == 'WhatsApp'">
              <div *ngIf="getLayout(layout,'header') === 'image'">
                <img
                  *ngIf="getLayout(layout,'headerImage')"
                  [src]="getLayout(layout,'headerImage')"
                  alt=""
                  style="width: 100%; object-fit: contain;"
                />
              </div>
              <div *ngIf="getLayout(layout,'header') === 'document'">
                <embed [src]="getLayout(layout,'headerDocument')" type="application/pdf" style="height: 80px; width: 80px; object-fit: contain;">
              </div>
              <div *ngIf="getLayout(layout,'header') === 'text'">
                <span style="font-size: 15px; font-weight: 600; white-space: pre-line; word-break: break-word;">{{getLayout(layout,'headerText')}}</span>
              </div>
              <div *ngIf="getLayout(layout,'header') === 'video'">
                <div *ngIf="getLayout(layout,'headerVideo')" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0;">
                  <div class="img_wrp">
                    <video alt="" style="height: 80px; width: 80px; object-fit: contain;">
                      <source [src]="getLayout(layout,'headerVideo')" />
                    </video>
                    <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                  </div>
                </div>
              </div>
              <span style="white-space: pre-line; word-break: break-word;">{{ getLayout(layout,'body') }}</span>
            </div>
            <div *ngIf="channel == 'SMS'">
              <span style="white-space: pre-line; word-break: break-word;">{{ data["smsText"] }}</span>
            </div>
          </div>
          <div class="qb-d-flex" *ngIf="channel == 'WhatsApp'">
            <div class="qb-button-text-whatsapp-preview">
              {{'SHARED_PAGE.STOP' | i18nTranslate}}
            </div>
            <div class="qb-button-text-whatsapp-preview" style="margin-left: 3px;">
              {{'SHARED_PAGE.SHOP_NOW' | i18nTranslate}}
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  </div>
</div>