<div style="padding: 10px;">

  <div
    style="display: flex;flex-direction:row;justify-content: space-between;align-items: center;    margin-bottom: 21px;">
    <div>{{data.title}}</div>
    <!-- <div (click)="cancel()" class="cursor-pointer">x</div> -->
    <mat-icon class="close-icon" (click)="cancel()">cancel</mat-icon>


  </div>


  <div class="q-field-block">
    <div class="q-field-name">{{data.field1Name}}</div>
    <div class="q-input-outline">
      <input class="q-input-field" type="text" name="" id="" [(ngModel)]="field1Value" (keypress)="gameNameKeyPress($event)">
      <span class="game-name-text-limiter"
      >{{ field1Value.length
      }}<span style="color: #cccccc">/45</span></span
    >
    </div>
    <div style="color: #dc3545; font-size: 12px;" *ngIf="errorText.length > 0">{{errorText}}</div>
  </div>
  <!-- <div class="q-field-block">
    <div class="q-field-name">{{data.field2Name}}</div>
    <div class="q-input-outline">
      <textarea class="q-input-field" style="height: 90%" [(ngModel)]="field2Value">
    </textarea>
    </div>
    <div style="color: #dc3545; font-size: 12px;" *ngIf="errorTextDesc.length > 0">{{errorTextDesc}}</div>
  </div> -->



  <div style="display: flex;justify-content: flex-end;">
    <div class="secondary-button-border" (click)="cancel()">{{data.cancelButtonName}}</div>
    <div class="primary-button" (click)="submit()">{{data.submitButtonName}}</div>

  </div>

</div>