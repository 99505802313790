export const template1 = {
  page: {
    body: {
      container: {
        style: {
          "background-color": "transparent",
        },
      },
      content: {
        computedStyle: {
          linkColor: "#7c4b96",
          messageBackgroundColor: "transparent",
          messageWidth: "675px",
        },
        style: {
          color: "#000000",
          "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif",
        },
      },
      type: "mailup-bee-page-properties",
      webFonts: [
        {
          fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
          name: "Roboto",
          url: "https://fonts.googleapis.com/css?family=Roboto",
        },
      ],
    },
    description: "",
    rows: [
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "675px",
                  },
                  image: {
                    alt: "LogoPlaceHolder",
                    height: "148px",
                    href: "",
                    percWidth: 25,
                    prefix: "",
                    src: "https://bee-editor-images-qa.s3.amazonaws.com/user/user-id-249/Text%20%26%20Icons_Header-02.png",
                    target: "_self",
                    width: "148px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "fe1b5c95-ef00-4d1d-ba5d-5cf8c0c87be7",
              },
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "675px",
                  },
                  image: {
                    alt: "Alternate text",
                    height: "525px",
                    href: "",
                    src: "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/55d274b7-252b-45ed-bff0-a2b81c79627c/user-id-176/Welcome-01.png",
                    width: "1920px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "86741dc0-9860-4d77-ba7d-c28ccb67db92",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "45px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;"><p style="font-size: 12px; line-height: 21px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 21px; word-break: break-word;"><span style="color: #000000; line-height: 21px;" data-mce-style="color: #000000; line-height: 21px;"><span style="font-size: 22px; line-height: 39px;" data-mce-style="font-size: 22px; line-height: 39px;"><strong>Hi </strong></span><strong><span style="font-size: 22px; line-height: 39px;" data-mce-style="font-size: 22px; line-height: 39px;">{{customerName}}  </span></strong></span></p><p style="font-size: 12px; line-height: 21px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 21px; word-break: break-word;"> </p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "180@",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "65557255-4d8c-48d9-804f-a8496772c519",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "79f5a45f-5b9f-4884-b151-12d80081f566",
          },
        ],
        container: {
          style: {
            "background-color": "#fff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#333",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "c0420f43-42ce-4eb9-b96d-384cb4428002",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;"><p style="font-size: 13px; line-height: 25px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 25px; word-break: break-word;"><span style="color: #000000; font-size: 28px; line-height: 50px;" data-mce-style="color: #000000; font-size: 28px; line-height: 50px;"><strong><span style="line-height: 50px; font-size: 28px;" data-mce-style="line-height: 50px; font-size: 28px;">WE ARE HAPPY TO WELCOME YOU</span></strong></span></p><p style="font-size: 13px; line-height: 25px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 25px; word-break: break-word;"><span style="color: #000000; font-size: 28px; line-height: 50px;" data-mce-style="color: #000000; font-size: 28px; line-height: 50px;"><strong><span style="line-height: 50px; font-size: 28px;" data-mce-style="line-height: 50px; font-size: 28px;">TO {{merchantName}}</span></strong></span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "180@",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "55e5ef67-87e8-48d4-943c-31b041df63b6",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "f201d4f1-ca02-47e6-be57-165184703e32",
          },
        ],
        container: {
          style: {
            "background-color": "#fff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "805f3ce3-a283-47c5-883e-12e4c4aad259",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; word-break: break-word;"><span style="color: #000000; line-height: 14px;" data-mce-style="color: #000000; line-height: 14px;"><strong>WE HAVE AN EXCITING OFFER ONLY FOR <span style="font-size: 18px; line-height: 24px;" data-mce-style="font-size: 18px; line-height: 24px;">YOU</span></strong></span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120@",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "cf2a1fed-d4b4-44ff-8c46-b8c5993df625",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "6fe2f021-4174-4ea5-a144-fdee94cc16b1",
          },
        ],
        container: {
          style: {
            "background-color": "#fff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "76d03e75-6586-42b5-9786-7784c5a7fb40",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;">\n\n</div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120@",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "da337a56-2332-4d1d-8e50-6563b8dc207b",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "aacde758-626d-4488-8abb-7961fcbee846",
          },
        ],
        container: {
          style: {
            "background-color": "#fff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "7ff95c6b-f4ba-4c54-ba11-16493eb80110",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word;"><span style="color: #000000; line-height: 14px;" data-mce-style="color: #000000; line-height: 14px;"><strong>Grab it by visiting <span style="font-size: 14px; line-height: 19px;" data-mce-style="font-size: 14px; line-height: 19px;">{{shopUrl}}</span></strong></span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120@",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "4d842245-25fe-4b2d-b085-bee6bbb6dca2",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "0ba3d570-67e9-4bb9-8a23-8a0f95419095",
          },
        ],
        container: {
          style: {
            "background-color": "#fff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "fc8ecfd0-3e5d-49f8-aba2-7a32541f7ea9",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "30px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "0px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 21px;"><p style="font-size: 13px; line-height: 25px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 13px; line-height: 25px; text-align: center; word-break: break-word;"><span style="color: #000000; line-height: 21px;" data-mce-style="color: #000000; line-height: 21px;">{{merchantName}}</span></p><p style="font-size: 13px; line-height: 25px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 13px; line-height: 25px; text-align: center; word-break: break-word;"><span style="color: #000000; line-height: 21px;" data-mce-style="color: #000000; line-height: 21px;">Visit {{shopUrl}} for more</span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "180@",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "a12c759d-be01-42cf-beb0-9d2045dca91c",
              },
              {
                descriptor: {
                  computedStyle: {
                    height: 57,
                    hideContentOnMobile: false,
                    iconsDefaultWidth: 32,
                    padding: "0 2.5px 0 2.5px",
                    width: 151,
                  },
                  iconsList: {
                    icons: [],
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                    "text-align": "center",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-social",
                uuid: "3687c5a2-cd8f-4c25-ace5-1866278b0d1e",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="line-height: 14px; font-size: 12px;" data-mce-style="line-height: 14px; font-size: 12px;"><p style="font-size: 13px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 13px; line-height: 16px; word-break: break-word; text-align: center;"><span style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;">Copyrights © 2024  I   To <span style="color: #33cccc; line-height: 14px;" data-mce-style="color: #33cccc; line-height: 14px;">unsubscribe</span> from these mailings, you may opt out here.</span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "07fdfa25-7d25-4935-b82f-143b219a5688",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "0b312f71-d230-46de-b687-7db34f4fbd46",
          },
        ],
        container: {
          style: {
            "background-color": "#fff",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "481714be-16c9-4509-86b9-22c24c9d0b82",
      },
    ],
    template: {
      name: "template-base",
      type: "basic",
      version: "2.0.0",
    },
    title: "",
  },
  comments: null,
};
