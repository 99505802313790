<div class="qb-main">
    <div class="qb-header-container" style="display: flex; justify-content: space-between;">
        <div class="qb-header">
            {{storeName}}
        </div>
        <div class="primary-button" *ngIf="validWidgets | widgetConfig: 40"  style="gap: 5px; 
        padding: 12px 10px 12px 6px;" (click)="addNewCustomer()">
          <div style="margin-top: 5px;">
            <span class="material-icons-outlined qb-white cursor-pointer">
              add
            </span>
          </div>
          <div>{{'PROFILE_PAGE.ADD_NEW_CUSTOMER' | i18nTranslate}}</div>
        </div>
    </div>
    <div class="qb-parent-container">
        <div class="qb-profile-preview-card">
            <div class="qb-profile-container">
                <div class="qb-store-preview">
                    {{'PROFILE_PAGE.LOGO' | i18nTranslate}}
                </div>
                <div class="drag-drop-div">
                    <div 
                        *ngIf="!logoPresent"
                       class="container" appDnd (fileDropped)="onFileDropped($event)" style="
                        border: 1px solid black;
                        border-style: dashed;
                        display: flex;
                        flex-direction: column;
                        padding: 30px 0;
                        align-items: center;
                        position: relative;
                      " [class.hovering]="isHovering">
                      <input style="
                          opacity: 0;
                          position: absolute;
                          z-index: 2;
                          width: 100%;
                          height: 172px;
                          top: 0px;
                          left: 0px;
                          cursor: pointer;
                        " type="file" #fileDropRef id="fileDropRef" multiple (click)="$event.target.value = null"
                        (change)="fileBrowseHandler($event, $event.target.files, true)" accept=".png, .jpg, .jpeg" />
                      <p>{{'PROFILE_PAGE.DRAG_DROP' | i18nTranslate}}</p>
                      <p>{{'PROFILE_PAGE.OR' | i18nTranslate}}</p>
                      <div label for="fileDropRef" class="primary-button browse-files">
                        {{'PROFILE_PAGE.BROWSE_FILE' | i18nTranslate}}
                      </div>
                    </div>
                    <div *ngIf="logoPresent && !imageLoading" style="position: relative; display: flex">
                      <img style="
                          width: 20%;
                          margin-top: 10px;
                          background: #ffffff;
                          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                          border-radius: 8px;
                        " [src]="logoUrl"
                        (error)="noLogo()" />
                    </div>
                    <div *ngIf="imageLoading && isLogo" style="position: relative">
                      <div style="
                          width: 20%;
                          margin-top: 10px;
                          height: 170px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        ">
                        <mat-spinner diameter="15"></mat-spinner>
                      </div>
                    </div>
                    <input type="file" multiple (click)="$event.target.value = null" #file class="qb-logo-input"
                    (change)="fileBrowseHandler($event, $event.target.files, true)" accept=".png, .jpg, .jpeg">
                    <div class="primary-button qb-primary-buttoun" (click)="file.click()" *ngIf="logoPresent">
                        {{'PROFILE_PAGE.CHANGE_LOGO' | i18nTranslate}}
                    </div>
                  </div>
                <div class="qb-d-flex">
                    <div class="qb-store-preview">
                        {{'PROFILE_PAGE.STORE_PREVIEW' | i18nTranslate}}
                    </div>
                    <div class="qb-i-container">
                        <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview">
                            <ng-template #popStorePrview class="qb-ng-template-background">
                                <div class="qb-description-text">
                                    <div class="qb-d-inline-flex">{{'PROFILE_PAGE.STORE_PREVIEW_INFO_MESSAGE' | i18nTranslate}}</div>
                                </div>
                            </ng-template>
                            <div class="qb-i">i</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="position: relative; display: flex" >
                        <div class="qb-store-no-img-container" *ngIf="!previewPresent" style="vertical-align: middle;"> 
                            <img src="" alt="">
                        </div>
                        <img
                        class="qb-store-img-container" *ngIf="previewPresent"
                         [src]="previewUrl"
                         (error)="noPreview()"
                          />   
                         <div class="qb-d-flex qb-camera-icon">
                            <input class="qb-img-input" type="file" id="fileDropRef" multiple (click)="$event.target.value = null"
                            (change)="fileBrowseHandler($event, $event.target.files, false)" accept=".png, .jpg, .jpeg" />
                            <div label for="fileDropRef" class="qb-d-flex">
                                <div class="material-icons-outlined">
                                    photo_camera
                                </div>
                                <div class="qb-edit-cover">
                                   {{'PROFILE_PAGE.EDIT_STORE_PREVIEW' | i18nTranslate}}
                               </div>
                            </div>
                         </div>  
                    </div>
                </div>
                <hr class="qb-hr">
                <div class="qb-store-info">
                    <div class="qb-store-info-text">
                        {{'PROFILE_PAGE.STORE_INFO' | i18nTranslate}}
                    </div>
                    <div class="material-icons-outlined cursor-pointer" (click)="toEditPage()">
                        edit
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.STORE_ID' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container">
                        <div class="qb-data-text">
                            {{storeId}}
                        </div>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.STORE_NAME' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container">
                        <div class="qb-data-text">
                            {{storeName}}
                        </div>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.CATEGORY' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container">
                        <div class="qb-data-text" *ngIf="data && data['category'] !== null">
                            {{data['category']}}
                        </div>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.TAGS' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container qb-text-align-end">
                        <span  *ngFor ="let tag of tag | slice:0:limit">
                            <span *ngIf="tag.length > 0">
                                &nbsp;{{tag}},
                            </span>
                        </span>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.EMAIL' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container">
                        <div class="qb-data-text">
                            {{storeEmail}}
                        </div>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.PHONE' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container">
                        <div class="qb-data-text"  *ngIf="number">
                            {{number}}
                        </div>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.ADDRESS' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container">
                        <div class="qb-data-text">
                            {{getAddress()}}
                        </div>
                    </div>
                </div>
                <div class="qb-d-flex qb-info-container">
                    <div class="qb-details-heading-container">
                        <div class="qb-heading-text">
                            {{'PROFILE_PAGE.LOCATION' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="qb-details-data-container cursor-pointer">
                        <div class="qb-d-flex qb-jc-flex-end" (click)="showMap()">
                            <div class="qb-map">
                                {{'PROFILE_PAGE.GOOGLE_MAP' | i18nTranslate}}
                            </div>
                            <div class="material-icons-outlined">
                                map
                            </div>
                        </div>
                    </div>
                </div>
                <agm-map [latitude]="lat" [longitude]="long" *ngIf="show" [zoom]="zoom">
                    <agm-marker [latitude]="lat" [longitude]="long"></agm-marker>
                </agm-map>
            </div>
        </div>
        <!-- <div class="qb-products-card">
            <div class="qb-profile-container">
                <div class="qb-store-preview">
                    Product
                </div>
             
                <div class="drag-drop-div">
                    <div 
                       class="container" appDnd (fileDropped)="onFileDropped($event)" style="
                        border: 1px solid black;
                        border-style: dashed;
                        display: flex;
                        flex-direction: column;
                        padding: 30px 0;
                        align-items: center;
                        position: relative;
                      " [class.hovering]="isHovering">
                      <input style="
                          opacity: 0;
                          position: absolute;
                          z-index: 2;
                          width: 100%;
                          height: 230px;
                          top: 0px;
                          left: 0px;
                          cursor: not-allowed;
                        " type="file" #fileDropRef id="fileDropRef" multiple (click)="$event.target.value = null"
                        (change)="fileBrowseHandler($event, $event.target.files, true)" accept=".csv .tsv .xls .xlsx" disabled />
                        <div class="qb-d-flex">
                            <div class="qb-product-file-upload">
                                <p>Drag and drop your file here</p>
                                <p>or</p>
                                <div label for="fileDropRef" class="primary-button browse-files">
                                  Browse Files
                                </div>
                            </div>
                            <div class="qb-product-file-detail-text">
                                You can upload any .csv, .tsv, .xls, .xlsx file with any set of columns as long as it has 1 record per row. The next step will allow you to map your columns to the right data points. You will be able to clean up or remove any corrupted data before finalizing your file.
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div> -->
    </div>
</div>
