<div class="qb-main-div">
    <div class="qb-title-div">
    <div>{{'CAMPAIGN_PAGE.CONNECT_AD_ACCOUNT' | i18nTranslate}}</div>
    <div><span (click)="close()"
        class="material-icons-outlined icons-primary qb-close-icon"
      >
        close
      </span></div>
      </div>
    <div class="qb-table-header">
        <div class="qb-account">{{'CAMPAIGN_PAGE.ADD_ACCOUNT' | i18nTranslate}}</div>
        <div class="qb-id-header">ID</div>
    </div>
    <div class="qb-table-row">
    <mat-radio-button class="qb-radio" (change)="select('id1')">
    <div>
        <div class="qb-account">{{'CAMPAIGN_PAGE.ACCOUNT' | i18nTranslate}} 1</div>
    </div>
    </mat-radio-button>
    <div class="qb-id-header">ID 1</div>
    </div>
    <div class="qb-button-div">
    <div class="primary-button">{{'CAMPAIGN_PAGE.CONNECT_AD_ACC_ONLY' | i18nTranslate}}</div>
    </div>
</div>