<div class="dashboard-line-chart-main-container">
  <div *ngIf="graphLoading" class="offset-screen-container">
    <app-qubriux-loading  [size]="65"></app-qubriux-loading>
  </div>
  <div class="dashboard-analytics-graph-container" style="width: 100%; height: 100%" #chartContainer>
    <div style="width: calc(100% - 5px); height: calc(100% - 5px)">
      <div echarts [options]="chartOptions" class="dashboard-analytics-line-chart-container"></div>
    </div>
  </div>
</div>
