import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoyaltyTrendComponent } from './pages';



const routes: Routes = [
    { 
      path: "", 
      component: LoyaltyTrendComponent, 
      data: {
        titleheading: "Loyalty Trend",
        title: "Loyalty Trend",
        minPath: "Basic"
      },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoyaltyTrendRoutingModule { }
