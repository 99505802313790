import { OnInit, OnChanges, Component, ElementRef, Input, ViewChild, } from '@angular/core';
import * as d3 from 'd3';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

interface BarData {
  name: string;
  value: number;
}

@Component({
  selector: 'app-loyalty-bar-chart',
  templateUrl: './loyalty-bar-chart.component.html',
  styleUrls: ['./loyalty-bar-chart.component.css']
})
export class LoyaltyBarChartComponent implements OnInit, OnChanges {
  @ViewChild('barChartContainer')
  private chartContainer: ElementRef;

  @Input()
  data: BarData[];

  @Input() xLabel : string;
  @Input() yLabel : string;

  margin = { top: 20, right: 20, bottom: 40, left: 50 };

  constructor(
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.chartContainer && this.chartContainer.nativeElement) {
      this.createChart();
    }
  }

  ngOnChanges(): void {
    if (!this.data) { return; }
    if (this.chartContainer && this.chartContainer.nativeElement) {
      this.createChart();
    }
  }

  private createChart(): void {
    // d3.select('svg').remove();

    const element = this.chartContainer.nativeElement;
    const data = this.data;

    const svg = d3.select(element).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);

    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleBand()
      .range([0, contentWidth])
      .paddingInner(0.3)
      .paddingOuter(0.3)
      .domain(data.map(d => d.name));

    let maxY = d3.max(data, d => d.value );
    if (maxY == 0) {
      maxY = 10;
    }

    const y = d3
      .scaleLinear()
      .range([contentHeight, 0])
      .domain([0, maxY]);

    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
      .attr('font-size', '12px')
      .append("text")
      .attr('font-size', '12px')
      .attr("class", "axis-title")
      .attr('x', contentWidth/2)
      .attr('dy', '2.7em')
      .style("text-anchor", "end")
      .attr("fill", "#5D6971")
      .text(this._i18nDynamicTranslate.transform(this.xLabel, ['POS_PAGE']));

    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).tickSizeInner(-contentWidth))
      .attr('font-size', '12px')
      .append('text')
      .attr('x', -contentHeight/2)
      .attr('font-size', '12px')
      .attr('transform', 'rotate(-90)')
      .attr('y', -50)
      .attr('dy', '0.71em')
      .attr("fill", "#5D6971")
      .attr('text-anchor', 'end')
      .text(this._i18nDynamicTranslate.transform(this.yLabel, ['POS_PAGE']));

      d3.selectAll('.axis--y .tick line')
      .style('color', 'rgba(34, 34, 34, 0.2)')
      .style('stroke-dasharray', '20,5');

    g.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.name))
      .attr('y', d => y(d.value))
      .attr('width', x.bandwidth())
      .style('fill','#FFD46C')
      .attr('height', d => contentHeight - y(d.value));
  }
}