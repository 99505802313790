<div>
  <div>
    <div class="qb-header">
      <span>{{'SHARED_PAGE.DISABLE_FORM' | i18nTranslate}}</span>
      <mat-icon class="qb-close-icon cursor-pointer" (click)="submit(false)"
        >close</mat-icon
      >
    </div>
  </div>
  <div>
    <div class="qb-message">
      {{'SHARED_PAGE.DISABLE_FORM_PARAGRAPGH' | i18nTranslate}}
    </div>
    <div class="qb-message qb-message2">
      {{'SHARED_PAGE.SURE_DISABLE_FORM' | i18nTranslate}}
    </div>
  </div>
  <div class="qb-button-container">
    <span class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="primary-button" (click)="submit(true)" style="text-transform: capitalize;"> {{'SHARED_PAGE.DISABLE_FORM' | i18nTranslate}} </span>
  </div>
</div>