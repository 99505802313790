import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SignoutService } from 'src/app/core/services/signout.service';
import { PaymentPosSucessDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.css']
})
export class PaymentRedirectComponent implements OnInit {

  constructor(
    private apicall: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private _signoutService: SignoutService,
    private _dialog: MatDialog,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) { 
    this.getScreenSize();
  }

  loading = false;
  checkCount = 0;
  scrHeight;
  scrContentHeight;
  scrWidth;
  userName = "";
  timertoRedirect = 10;
  isSucess = false;
  screenBlank = false;
  redirectTime = 0;
  channel = '';

  ngOnInit(): void {
    this.userName = this.tokenStorage.getUsername();
    this.route.queryParams.subscribe((params)=>{
      this.channel = params.channel ? params.channel : 'EMAIL';
    });
    // this.getApi();
    this.getStatus()
  }
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrContentHeight = window.innerHeight - 60 - 90;
    this.scrWidth = window.innerWidth;
  }
  getStatus(){
    this.loading = true;
    // let interval = setInterval(()=>{
    //   if(this.redirectTime === 0)
    //     this.getApi(interval);
    // },5000);
    this.getPlanStatus();

  }

  getPlanStatus(){
    this.apicall.getCurrentPlanPos().subscribe((response)=>{
      let status = response['body']['status'];
      let trial = response['body']['trial'];
      let planCancelled = response['body']['planCancelled']
      if(status === 'INACTIVE' || status == null || trial || planCancelled){
        this.getPlanPurchaseStatus(status, planCancelled, trial);
      }
      if(status === 'ACTIVE' && !trial && !planCancelled){
        this.getAddonPurchaseStatus();
      }
    });
  }

  getPlanPurchaseStatus(status, planCancelled, trial){
    let timer = setInterval(()=>{
      console.log("REDIRECT", this.redirectTime)
      this.apicall.getPlanStatus().subscribe((result)=>{
        let planStatus = result['body']['status']
        if(planStatus === true){
          this.redirect('ACTIVE');
          this.screenBlank = true;
          clearInterval(timer);
        }
        else if(this.redirectTime === 18 && planStatus == false){
          this.loading = false;
          clearInterval(timer);
          let paymentRedirecttimer = setInterval(()=>{
            // this.redirect(status)
            if(this.timertoRedirect === 0){
              if(status === 'INACTIVE'){
                this.router.navigate(['payment-pos']);
              }
              else if((status === 'ACTIVE')){
                this.router.navigate(["/app/payments/payment-plan-pos"]);
              }
              clearInterval(paymentRedirecttimer);
            }
            this.timertoRedirect--;
          },1000)
        }
        this.redirectTime ++;
      })
    },5000)
  }

  getAddonPurchaseStatus(){
    let timer = setInterval(()=>{
      console.log("REDIRECT", this.redirectTime)
      this.apicall.getAddonStatus(this.channel).subscribe((result)=>{
        let addonStatus = result['body']['status']
        if(addonStatus === true){
          this.addOnSuccessDialogBox(addonStatus);
          this.screenBlank = true;
          clearInterval(timer);
        }
        else if(this.redirectTime === 18 && addonStatus == false){
          this.failedSucessDialogBox(addonStatus);
          this.screenBlank = true;
          clearInterval(timer);
        }
        this.redirectTime ++;
      })
    },5000)
  }

  async getApi(interval?){
    this.loading = true;
    this.apicall.getCurrentPlanPos().subscribe((response)=>{
      let status = response['body']['status'];
      if(this.checkCount == 20 || status === "ACTIVE"){
        console.log("CHECKCOUNT",this.checkCount)
        if(status === "ACTIVE" && this.checkCount == 0){
          clearInterval(interval);
          let timer = setInterval(()=>{
            console.log("REDIRECT", this.redirectTime)
            this.apicall.getAddonStatus('EMAIL').subscribe((result)=>{
              let addonStatus = result['body']['status']
              if(addonStatus === true){
                this.addOnSuccessDialogBox(addonStatus);
                this.screenBlank = true;
                clearInterval(timer);
              }
              else if(this.redirectTime === 18){
                this.failedSucessDialogBox(addonStatus);
                this.screenBlank = true;
                clearInterval(timer);
              }
              this.redirectTime ++;
            })
          },5000)
        }
        else{
          this.redirect(status);
          this.loading = false;
          clearInterval(interval);
        }
      }
      if(this.checkCount == 20 && status == "INACTIVE"){
        this.loading = false;
        clearInterval(interval);
        let timer = setInterval(()=>{
          // this.redirect(status)
          if(this.timertoRedirect === 0){
            this.router.navigate(['payment-pos']);
            clearInterval(timer);
          }
          this.timertoRedirect--;
        },1000)
      }
      this.checkCount++;
      console.log("Counter", this.checkCount);
      console.log("STATUS", status);
    },(err)=>{
      clearInterval(interval);
      this.router.navigate(['payment-pos']);
    });
  }
  redirect(status){
    console.log("Hello")
    switch(status){
      case "ACTIVE":
        // this.router.navigate(["/app/app-home"], {
        //   queryParams: { type: "Objectives" },
        // });
        this.planSucessDialogBox(status);
        break;
      case "INACTIVE":
        this.router.navigate(['payment-pos']);
        break;
      case "INPROCESS":
        this.planSucessDialogBox(status);
        // this.router.navigate(['store-inprogress']);
        break;
    } 
  }
  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.router.navigate(["auth/login"], {});
  }
  addOnSuccessDialogBox(status){
    const dialogRef = this._dialog.open(PaymentPosSucessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "250px",
      height: "250px",
      disableClose: true,
      data: {
        heading: this._i18nDynamicTranslate.transform("Add On added successfully", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Your add on has been added to your current billing cycle.", ['POS_PAGE']),
        type : "ADDON",
        buttonText: this._i18nDynamicTranslate.transform("Go to Home page", ['POS_PAGE']),
        status: status
      },
    });
  }
  planSucessDialogBox(status){
    this.screenBlank = true;
    const dialogRef = this._dialog.open(PaymentPosSucessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "250px",
      height: "250px",
      disableClose: true,
      data: {
        heading: this._i18nDynamicTranslate.transform("Plan added successfully", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Your plan has been added to your current billing cycle.", ['POS_PAGE']),
        type : "PLAN",
        buttonText: this._i18nDynamicTranslate.transform("Go to Home page", ['POS_PAGE']),
        status: status
      },
    });
  }
  failedSucessDialogBox(status){
    const dialogRef = this._dialog.open(PaymentPosSucessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "250px",
      height: "250px",
      disableClose: true,
      data: {
        heading: this._i18nDynamicTranslate.transform("Add On failed", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Your add on payment failed.", ['POS_PAGE']),
        type : "FAIL",
        buttonText: this._i18nDynamicTranslate.transform("Go to Home page", ['POS_PAGE']),
        status: status
      },
    });
  }
}
