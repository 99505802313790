import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent, SuccessDialogComponent } from 'src/app/shared/components/dialog-box';

@Component({
  selector: 'app-social-media-create-post',
  templateUrl: './social-media-create-post.component.html',
  styleUrls: ['./social-media-create-post.component.css']
})
export class SocialMediaCreatePostComponent implements OnInit {
 
  @ViewChild("pickerFrom") elementpickerFrom: any;
  @ViewChild('sm-accounts-multiple-select-button') toggleButton: ElementRef;
  @ViewChild('sm-accounts-multiple-select') menu: ElementRef;
  constructor(private _route:ActivatedRoute, private _router:Router, private _dialog:MatDialog, private datePipe: DatePipe,private _apicall:ApiCallService,private _TokenStorageService:TokenStorageService,private _snackBar:SnackbarCollection, private date_format: getdateformatService) {

    let params = this._route.snapshot.queryParams;
    if(params.id){
      this.postId=params.id;
    }
    if(params.clone){
      this.clone=true;
    }

   
   }

   @HostListener("document:click", ["$event"])
   clickout3(event3) {
 
     if (document.getElementById("sm-accounts-multiple-select-button").contains(event3.target)) {
     } else {
      this.toggleSelect('close');
     }
   }

   selected = false;
   submit = false;
   stepsCompleted = 1;
  fbAccounts;
  loading;
  selectedAccounts=[];
  postContent="" ;
  toggled: boolean = false;
  textSelection;
  videoOb=[];
  linkOb=[];
  imagesOb=[];
  fileToUpload:File = null;
  fileuploading=false;
  scheduleType="INSTANT";
  scheduleDataObj={ date: null, time:{HH:null,MM:null}};
  startDateMinValue;
  MinDateString;
  isFacebookSelected = false;
  isInstagramSelected = false;
  selectedChannels = {};
  currentTimeMin;
  isContinueBtnLoading=false;
  isSaveBtnLoading=false;
  isDiscardBtnLoading=false;
  toggleStatus = true;
  postId;
  clone=false;
  minHours;
  minMinutes
   pickerTime;

  ngOnInit() {
    this.initTime();
    this.getaccounts();
    this.textSelection = this.postContent.length;

    if(this.postId){
      this.getPostDetails(this.postId);
    }
    
  }
  updateMeasurementPointComment(event){

  }
  keypresshandler(event){

  }
  onPaste(event){
    
  }

  initTime(){
    const currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.currentTimeMin = this.startDateMinValue.toTimeString();
    this.MinDateString = this.startDateMinValue.toDateString();
    this.scheduleDataObj['date']=this.startDateMinValue;
    
  this.minHours =this.currentTimeMin.split(":")[0];

this.minMinutes=this.currentTimeMin.split(":")[1];
this.scheduleDataObj.time.HH=this.minHours;
this.scheduleDataObj.time.MM=this.minMinutes;

  }
  valuechange(event){

    let currentTimeMin = this.scheduleDataObj.date.toTimeString();
    let minDate=  this.datePipe.transform(
      this.startDateMinValue,
      "yyyy-MM-dd"
    );
    let selDate= this.datePipe.transform(
      this.scheduleDataObj.date,
      "yyyy-MM-dd"
    );
    if((minDate==selDate)&&this.scheduleDataObj.time.HH==this.minHours){

      this.minMinutes=this.currentTimeMin.split(":")[1];
      if(this.scheduleDataObj.time.MM<this.minMinutes){
        this.scheduleDataObj.time.MM=null;
      }
    }else{
      this.minMinutes="00";
    }
   
  }
  startDateChange(){
  
    let currentTimeMin = this.scheduleDataObj.date.toTimeString();
   
    let minDate=  this.datePipe.transform(
      this.startDateMinValue,
      "yyyy-MM-dd"
    );
    let selDate= this.datePipe.transform(
      this.scheduleDataObj.date,
      "yyyy-MM-dd"
    );
  
    if(minDate!=selDate){
    this.minHours ="0";
        this.minMinutes="00";
    }else{
      this.minHours =this.currentTimeMin.split(":")[0];

this.minMinutes=this.currentTimeMin.split(":")[1];
if(this.scheduleDataObj.time.HH<this.minHours){
  this.scheduleDataObj.time.HH=null;
}
if(this.scheduleDataObj.time.MM<this.minMinutes){
  this.scheduleDataObj.time.MM=null;
}
    }

  }
  checkTime(){

  }
  getaccounts() {
    this.loading=true;
  
    this._apicall.getSocialMdiaUsersList().subscribe((response) => {
      this.fbAccounts = this.getChannelDimensionsByFilter(
        response["body"]
      );

      this.loading=false;
    },(error)=>{
      this._snackBar.openSnackBar("Something went wrong",2000);
      this.loading=false;
    });
  }
  getChannelDimensionsByFilter(userList) {
    userList.forEach(item => {
      item.channel = item.channel.split(",");
    })
    return userList;
  }

  selectChange(){
   
  }

  handleSelection(event) {
    if (!(160 - this.postContent.length < event.char.length)) {
      let text = this.postContent;
      this.postContent =
        text.slice(0, this.textSelection) +
        event.char +
        text.slice(this.textSelection);
      this.textSelection += event.char.length;
      var txtarea = document.getElementById("postContent") as HTMLInputElement;
      txtarea.selectionEnd = this.textSelection + 1;
   //   this.updateSmsCount();
    }
  
  }
  addImage(){

  }
  addVideo(){

  }

  setFiles(event) {
    for (var i = 0; i < Object.keys(event.target.files).length; i++) { 
      if(Math.round(event.target.files[i].size / 1024) <= 8000) {
        this.fileuploading=true;
    this.fileToUpload = <File>event.target.files[i];
    const fd = new FormData();
    fd.append("files", this.fileToUpload, this.fileToUpload.name);
    this._apicall.uploadImages(fd).subscribe((response) => {
    
      for (let imageUrl of response["body"] ) {
        this.imagesOb.push(imageUrl);
   } 
   
      
    
      this.fileuploading=false;
    });
      } else {
          this._snackBar.openSnackBar("Images should be less than 8MB",2000);
      }
    }

  }
  setFilesVideo(event) {
    for (var i = 0; i < event.target.files.length; i++) { 
      var size = event.target.files[i].size;
      if(Math.round(event.target.files[0].size / 1024) <= 100000) {
    this.fileuploading=true;

    this.fileToUpload = <File>event.target.files[0];
    const fd = new FormData();
    fd.append("files", this.fileToUpload, this.fileToUpload.name);
    this._apicall.uploadVideo(fd).subscribe((response) => {
   
      for (let videoUrl of response["body"] ) {
        this.videoOb.push(videoUrl);
   } 
   
  
      this.fileuploading=false;
    });
  }else {
    this._snackBar.openSnackBar("Video files should be less than 100MB",2000);
}
}
  }

  removeImage(imageUrl){
    this.imagesOb.splice(this.imagesOb.indexOf(imageUrl),1);

  }
  removeVideourl(videoUrl){
    this.videoOb.splice(this.videoOb.indexOf(videoUrl),1);

  }
  clickTime(val) {

      this.elementpickerFrom.open();
     
  }
  continueButtonClicked(value){
    this.isContinueBtnLoading = false;

    if(this.selectedAccounts.length==0){
      this._snackBar.openSnackBar("Select atleast 1 account",2000);
      this.freeFooterBtns();
      return;
    }
    if(this.postContent.trim().length>5000){
      this._snackBar.openSnackBar("Message should be less than 5000 characters",2000);
      this.freeFooterBtns();
      return;
    }
    if(this.isFacebookSelected && (this.postContent.trim().length==0&&!(this.videoOb.length==0&&this.imagesOb.length==0&&this.linkOb.length==0))){
      this._snackBar.openSnackBar("Message should not be empty",2000);
      this.freeFooterBtns();
      return;
    }
    if(this.isInstagramSelected && (this.postContent.trim().length!=0&&(this.videoOb.length==0&&this.imagesOb.length==0&&this.linkOb.length==0))){
      this._snackBar.openSnackBar("For Instagram select atleast one image/video.",2000);
      this.freeFooterBtns();
      return;
    }
    if(this.isInstagramSelected && (this.postContent.trim().length==0)){
      this._snackBar.openSnackBar("Message should not be empty.",2000);
      this.freeFooterBtns();
      return;
    }
    if(this.isInstagramSelected && (this.postContent.trim().length==0&&!(this.videoOb.length==0&&this.imagesOb.length==0&&this.linkOb.length==0))){
      this._snackBar.openSnackBar("Message should not be empty.",2000);
      this.freeFooterBtns();
      return;
    }
    this.isContinueBtnLoading = true;

    let startdate=  this.datePipe.transform(
      this.scheduleDataObj.date,
      "yyyy-MM-dd"
    );
    let time;
    let hours;
    let minutes;
 if(this.scheduleType !="INSTANT"){
  hours=this.scheduleDataObj.time.HH.toString().length==1?"0"+this.scheduleDataObj.time.HH:this.scheduleDataObj.time.HH;
  minutes=this.scheduleDataObj.time.MM.toString().length==1?"0"+this.scheduleDataObj.time.MM:this.scheduleDataObj.time.MM;
   
 }
	
    let startAtValue=this.scheduleType =="INSTANT"?null:(startdate+"T"+hours+":"+minutes+":00");
  let data={
    id:this.postId?this.postId:null,
    accountIds:this.selectedAccounts,
    labels:[],
    video:this.videoOb,
    links:this.linkOb,
    images:this.imagesOb,
    scheduleType:this.scheduleType,
    startedAt:startAtValue,
    isLaunched:value?true:false,
    message:this.postContent

  }
  value==1?this.isContinueBtnLoading=true:this.isSaveBtnLoading=true;
  const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
    panelClass: "no-padding-dialog-popup",
    width: "20%",
    data: {
      subject: value?"Publish Post?":"Save Post",
      message: "",
      "data-widget": "Publish Post",
      "data-button": "Confirm: Schedule",
      "data-item": "post",
      successButtonText: "Confirm",
      cancelButtonText: "Cancel",
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      this.isContinueBtnLoading=true;
      const dialogRef = this._dialog.open(ContactUsDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        disableClose: true,
        width: "380px",
        data: {
          header: "Update",
          body: "Your post is being uploaded. Check the Posts tab after some time.",
        },
      });
      let apiserviceCall=this.postId&&!this.clone?this._apicall.updateSocialMediaContentPost(data,this.postId): this._apicall.publishSocialMediaPost(data);

      apiserviceCall.subscribe((response) => {

    
    this._router.navigate(["/app/social-eng/"],{
      queryParams: { type: "posts" },
    });
    this.freeFooterBtns();
  },
  (error)=>{
    let snackBarRef = this._snackBar.openSnackBar(
      "Something went wrong",2000);
     this.isContinueBtnLoading=false;
  });
} else {
  this.freeFooterBtns();
   }
 });
  this.isContinueBtnLoading=false;
  this.isSaveBtnLoading=false;
  this.isDiscardBtnLoading=false;

  }

  toggleSelect(event){
    let btn=document.getElementById("sm-accounts-multiple-select-arrow"); 

    if(this.loading)
    return 0;
    let toggle = document.getElementById("sm-accounts-multiple-select");
    let toggleButton = document.getElementById("sm-accounts-multiple-select-button");
    

    if(event=='close'){
 
      toggle.style.height = "0px";
      toggle.style.padding = "0px";
      toggle.style.border = "0px";
      this.toggleStatus = true;
      return;
    }
    if(event.srcElement.contains(toggle)||event.srcElement.contains(btn)){
      if(this.toggleStatus){

        toggle.style.height = "auto";
        toggle.style.padding = "20px";
        toggle.style.border = "1px solid #919191";
        this.toggleStatus = false;
      }else{

        toggle.style.height = "0px";
        toggle.style.padding = "0px";
        toggle.style.border = "0px";
        this.toggleStatus = true;
      }
    }
  }

  showDialog() {
    this._dialog.open(ContactUsDialogComponent, {
      panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: "Warning",
                  body: "Instagram allows adding only one media file in one post. If you wish to add multiple media files, please deselect Instagram channel.",
                },
    })
  }

freeFooterBtns(){
      this.isSaveBtnLoading=false;
    this.isContinueBtnLoading=false;
    this.isDiscardBtnLoading=false;
  }

  addSocialProfileToList(account, channel){
    this.isFacebookSelected = false;
      this.isInstagramSelected = false;
    let index;
    if(channel === "facebook")
    index = this.selectedAccounts.findIndex((item)=> item === account.userId);
    else
    index = this.selectedAccounts.findIndex((item)=> item === account.id);
    if(index > -1){
      this.selectedAccounts.splice(index,1);
      if(channel === 'facebook') {
        delete this.selectedChannels[account.userId];
      } else {
        delete this.selectedChannels[account.id]
      }
    }else{
      if(channel === 'facebook') {
      this.selectedAccounts.push(account.userId);
      this.selectedChannels[account.userId] = channel;
      } else {
      this.selectedAccounts.push(account.id);
      this.selectedChannels[account.id] = channel;
      }
    }
    Object.keys(this.selectedChannels).forEach(key => {
      if(!this.isFacebookSelected && this.selectedChannels[key] === 'facebook')
      this.isFacebookSelected = true;
      if(!this.isInstagramSelected && this.selectedChannels[key] === 'instagram')
      this.isInstagramSelected = true;
    })
    if(this.isFacebookSelected && this.isInstagramSelected)
    this.showDialog();
  }

  postSaved(value){
    const dialogRef = this._dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: value?"Your Post has been successfully enabled":"Your post has been saved",
        buttonText: "Back to Post List",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(["/app/social-eng/"],{
          queryParams: { type: "posts" },
        });
      } else {
        this.isContinueBtnLoading = false;
        this.isSaveBtnLoading = false;
      }
    });
  }
  discardGameSetup(){
    this._router.navigate(["/app/social-eng/"],{
      queryParams: { type: "posts" },
    });
  }
  getPostDetails(id){
  
     
        this._apicall.getPostData(id).subscribe((response)=>{
  
    let post=response['body'].contents;
    this.selectedAccounts=post.accountIds;
    this.videoOb=post.video;
    this.linkOb=post.links;
    this.imagesOb=post.images;
    this.scheduleType=post.scheduleType;
    this.postContent=post.message;
    var d = new Date(post.startedAt);
    this.scheduleDataObj.date=this.datePipe.transform(
      d.toDateString(),
      "yyyy-MM-dd"
    );
 
        })
        
      
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
  
}
