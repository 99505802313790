import STATIC_PAGE from "../../i18nDynamic/en/static.json";
import SHARED_PAGE from "../../i18nDynamic/en/shared.json";
import POS_PAGE from "../../i18nDynamic/en/pos.json";

const EN = {
    STATIC_PAGE: STATIC_PAGE,
    SHARED_PAGE : SHARED_PAGE,
    POS_PAGE: POS_PAGE 
}

export default EN;