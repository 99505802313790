

    <div class="header">
        <span class="header-title"><strong>{{'SHARED_PAGE.CREATE_NEW_OFFER' | i18nTranslate}}</strong></span>
        <mat-dialog-actions>
            <button class="xIcon" mat-dialog-close>
                <div class="material-icons" style="margin-left: -4.5px;
            font-size: 15px;">clear</div>
            </button>
        </mat-dialog-actions>
    </div>
    <div style="padding: 15px;">
        <div>
            <span class="qb-paragraph">
                {{'SHARED_PAGE.NEW_OFFER_PARAGRAPH' | i18nTranslate}}
            </span>
        </div>
        <div class="choose-template">
            <div class="template-card" (click)="
            selectOffer(
                          'singleOffer'
                        )
                      ">
                <div class="card-selection" [ngClass]="{
                          active: selectedOfferType == 'singleOffer'
                        }">
                    <mat-icon class="card-selection-icon">done</mat-icon>
                </div>
                <div style="margin: 8px; height: 90px; width: 160px; object-fit: contain;">
                    <img style="height: 100%; width: 100%" class="image-class" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/offers/partnerOffer.svg"
                        alt="No Preview Available" />
                </div>
                <div class="qb-offerType-heading">
                    {{'SHARED_PAGE.GENERAL_OFFERS' | i18nTranslate}}
                </div>
                <div class="template-name-div">
                    <div style="display: flex; text-align: center;">
                        <span class="template-name-span"> {{'SHARED_PAGE.CREATE_OFFER' | i18nTranslate}} - {{'SHARED_PAGE.PERCENTAGE' | i18nTranslate}},
                            {{'SHARED_PAGE.AMOUNT_OFF' | i18nTranslate}}, {{'SHARED_PAGE.FREE_SHIPPING' | i18nTranslate}},
                            {{'SHARED_PAGE.AND_BUY_X_GET_Y' | i18nTranslate}}</span>
                    </div>
                </div>
            </div>
            <div class="template-card" (click)="
            selectOffer(
              'partnerOffer'
            )
          ">
                <div class="card-selection" [ngClass]="{
              active: selectedOfferType == 'partnerOffer'
            }">
                    <mat-icon class="card-selection-icon">done</mat-icon>
                </div>
                <div style="margin: 8px; height: 90px; width: 160px; object-fit: contain;">
                    <img style="height: 100%; width: 100%" class="image-class" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/offers/singleOffer.svg"
                        alt="No Preview Available" />
                </div>
                <div class="qb-offerType-heading">
                    {{'SHARED_PAGE.CO_MARKETING_OFFER' | i18nTranslate}}
                </div>
                <div class="template-name-div">
                    <div style="display: flex; text-align: center;">
                        <span class="template-name-span"> {{'SHARED_PAGE.COMBO_OFFER_PARTNER' | i18nTranslate}}</span>
                    </div>
                </div>
            </div>
        </div>

    <div class="buttons">
        <mat-dialog-actions>
            <button class="cancel secondary-button-border" (click)="submit()"
                >{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</button>
        </mat-dialog-actions>
        <mat-dialog-actions>
            <button class="confirm primary-button" (click)="createOffer()"
                >{{'SHARED_PAGE.CREATE_OFFER' | i18nTranslate}}</button>
        </mat-dialog-actions>
    </div>
</div>