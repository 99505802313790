import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-invite-partner-dialog",
  templateUrl: "./invite-partner-dialog.component.html",
  styleUrls: ["./invite-partner-dialog.component.css"],
})
export class InvitePartnerDialogComponent implements OnInit {
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
  showError = false;
  containers = [0];
  emails = { 0: "" };
  emailsArray = [];
  i = 0;
  error = { 0: "" };
  divs = [0];
  errors =  { 0: "" };
  disableAddEmail;

  constructor(
    private dialogRef: MatDialogRef<InvitePartnerDialogComponent>,
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText

  ) {}

  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    emails: [],
    isSubmit: false,
  };

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  emailInput(event, index) {
    if (!event.target.value.match(this.emailPattern.trim())) {
      this.error[index] = this._i18nDynamicTranslate.transform("Please enter a valid email", ['SHARED_PAGE']);
      this.disableAddEmail = true;
    } else if (this.emailsArray.indexOf(event.target.value) != -1) {
      Object.keys(this.emails).forEach(email => {
        if(this.emails[email] === event.target.value && email != index)
        this.error[index] = this._i18nDynamicTranslate.transform("Duplicate email id", ['SHARED_PAGE'])
        this.disableAddEmail = true;
      })
    } 
    else {
      this.error[index] = "";
        this.checkIfEmailExists(event.target.value, index);
    }
    this.emails[index] = event.target.value;
        this.emailsArray[index] = event.target.value;
    // Object.keys(this.error).forEach(error => {
    //   if(this.error[error].length > 0) {
    //     this.disableAddEmail = true;
    //   } else {
    //     this.disableAddEmail = false;
    //   }
    // })
  }

  checkIfEmailExists(email, index) {
    this.data.isSubmit = false;
    this.data.emails = [email];
    this.apiCall.checkIfEmailExists(this.data).subscribe((response) => {
      if (response["body"].message === "A child user exists") {
        this.error[index] = this._i18nDynamicTranslate.transform("Email already registered", ['SHARED_PAGE'])
      } else if(response['body'].message === "You have exhausted 3 retries for the same email!") {
        this.error[index] = response['body'].message;
      }
      Object.keys(this.error).forEach(error => {
        if(this.error[error].length > 0) {
          this.disableAddEmail = true;
        } else {
          this.disableAddEmail = false;
        }
      })
    });
  }

  submit() {
    let errors = false;
    Object.keys(this.error).forEach(error => {
      if(this.error[error].length > 0) {
        errors = true;
      }
    }) 
    if(this.emails[0].length == 0) {
      errors = true;
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please enter an email", ['SHARED_PAGE']), 2000);
    }
    if(!errors) {
    this.data.isSubmit = true;
    this.data.emails = this.emailsArray;
    this.apiCall.checkIfEmailExists(this.data).subscribe((response) => {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Partnership invite sent", ['SHARED_PAGE']), 2000);
      this.closeDialog();
    });
  }
  }

  addNewEmail() {
    this.i++;
    this.containers.push(this.containers.length - 1);
    this.divs.push(this.i);
    this.disableAddEmail = true;
  }

  removeDiv(index) {
    const index2 = this.emailsArray.indexOf(this.emails[index]);
    if (index2 > -1) {
      this.emailsArray.splice(index2, 1);
      delete this.error[index2];
    }
    if (index > -1) {
      this.containers.splice(index, 1);
      this.divs.splice(index, 1);
    }
    let c2 = [];
    c2 = this.containers;
    this.containers = [];
    let c2i = 0;
    c2.forEach((item) => {
      this.containers.push(c2i);
      c2i++;
    });
    this.i = this.containers.length;
    this.emails = { 0: "" };
    this.errors =  { 0: "" };
    this.errors = this.error;
    this.error =  { 0: "" };
    let i = 0;
    this.emailsArray.forEach((email) => {
      this.emails[i] = email;
      i++;
    });
    i = 0;
    Object.keys(this.errors).forEach(error => {
      this.error[i] = this.errors[error];
      i++;
    });
    Object.keys(this.error).forEach(error => {
      if(this.error[error].length > 0) {
        this.disableAddEmail = true;
      } else {
        this.disableAddEmail = false;
      }
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }
  
}
