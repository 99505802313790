<div style="display: flex; justify-content: space-between; padding-top: 20px; background: #FFFFFF;" >
    <div style="margin-left: 30px;">
      <div class="magnify-text-container-kpi">
        <span class="material-symbols-outlined">
          frame_inspect
        </span>
        <span class="magnify-text">{{ zoomAmount }}%</span>
      </div>
    </div>
    <div style="display: flex;">
      <mat-icon
      class="undo-button"
      [ngClass]="{ disabled: zoomAmount === 30 }"
      (click)="magnify('SUB')"
      data-widget-name="CANVAS"
      data-button-name="Zoom out"
      >remove</mat-icon>
      <mat-icon
        style="margin-left: 20px;"
        class="redo-button"
        [ngClass]="{ disabled: zoomAmount === 150 }"
        (click)="magnify('ADD')"
        data-widget-name="CANVAS"
        data-button-name="Zoom in"
        >add</mat-icon>
        <mat-icon
        style="margin-left: 20px; margin-right: 20px;"
        (click)="close(false)"
        class="redo-button"
        data-widget-name="CANVAS"
        data-button-name="Zoom in"
        >close</mat-icon>
        
    </div>
  </div>
  <div class="qb-container-campaign-prefill" id="qb-container-campaign-prefill" style="height: 85%;">
      <div class="canvas" id = "canvas-fullscreen">
            
      </div>
  </div>
