import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewsRoutingModule } from './reviews-routing.module';
import { ReviewBaseComponent } from './pages/review-base/review-base.component';
import { ReviewInstanceComponent } from './components/review-instance/review-instance.component';
import { ReviewDashboardComponent } from './components/review-dashboard/review-dashboard.component';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { ReviewSentimentDialogboxComponent } from './components/review-instance/review-sentiment-dialogbox/review-sentiment-dialogbox.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ReviewConfigDialogBoxComponent } from './components/review-config-dialog-box/review-config-dialog-box.component';

@NgModule({
  declarations: [
    ReviewBaseComponent,
    ReviewInstanceComponent,
    ReviewDashboardComponent,
    ReviewSentimentDialogboxComponent,
    ReviewConfigDialogBoxComponent
  ],
  imports: [
    CommonModule,
    ReviewsRoutingModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ]
})
export class ReviewsModule { }
