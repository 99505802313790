export const template5 = {
    "page": {
      "body": {
        "container": {
          "style": {
            "background-color": "#e2dede"
          }
        },
        "content": {
          "computedStyle": {
            "linkColor": "#FF819C",
            "messageBackgroundColor": "transparent",
            "messageWidth": "500px",
            "messageWidthMobile": "250px"
          },
          "style": {
            "color": "#000000",
            "font-family": "Lato, Tahoma, Verdana, Segoe, sans-serif"
          }
        },
        "type": "mailup-bee-page-properties",
        "webFonts": [
          {
            "fontFamily": "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
            "name": "Montserrat",
            "url": "https://fonts.googleapis.com/css?family=Montserrat"
          },
          {
            "family": "'Helvetica Neue', Helvetica, Arial, sans-serif",
            "fontFamily": "'Helvetica Neue', Helvetica, Arial, sans-serif",
            "fontName": "Helvetica",
            "name": "Helvetica"
          },
          {
            "family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
            "fontFamily": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
            "fontName": "Lato",
            "name": "Lato",
            "url": "https://fonts.googleapis.com/css?family=Lato"
          },
          {
            "family": "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
            "fontFamily": "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
            "fontName": "Roboto",
            "name": "Roboto",
            "url": "https://fonts.googleapis.com/css?family=Roboto"
          },
          {
            "family": "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            "fontFamily": "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            "fontName": "Open Sans",
            "name": "Open Sans",
            "url": "https://fonts.googleapis.com/css?family=Open+Sans"
          },
          {
            "family": "Verdana, Geneva, sans-serif",
            "fontFamily": "Verdana, Geneva, sans-serif",
            "fontName": "Verdana",
            "name": "Verdana"
          },
          {
            "family": "Tahoma, Verdana, Segoe, sans-serif",
            "fontFamily": "Tahoma, Verdana, Segoe, sans-serif",
            "fontName": "Tahoma",
            "name": "Tahoma"
          },
          {
            "family": "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            "fontFamily": "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
            "fontName": "ヒラギノ角ゴ Pro W3",
            "name": "ヒラギノ角ゴ Pro W3"
          },
          {
            "fontFamily": "'Akaya Telivigala', arial",
            "name": "Akaya Telivigala",
            "url": "https://fonts.googleapis.com/css?family=Akaya+Telivigala"
          },
          {
            "family": "'Droid Serif', Georgia, Times, 'Times New Roman', serif",
            "fontFamily": "'Droid Serif', Georgia, Times, 'Times New Roman', serif",
            "fontName": "Droid Serif",
            "name": "Droid Serif",
            "url": "https://fonts.googleapis.com/css?family=Droid+Serif"
          },
          {
            "name": "Raleway",
            "fontFamily": "'Raleway', sans-serif",
            "url": "https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap",
            "fontWeight": {
              "400": "Regular",
              "500": "Medium",
              "700": "Bold"
            }
          }
        ]
      },
      "description": "BF-ecommerce-template",
      "rows": [
        {
          "container": {
            "style": {
              "background-color": "#c3e5ee",
              "background-image": "none",
              "background-repeat": "no-repeat",
              "background-position": "top left"
            }
          },
          "content": {
            "style": {
              "background-color": "transparent",
              "color": "#000000",
              "width": "800px",
              "background-image": "none",
              "background-repeat": "no-repeat",
              "background-position": "top left"
            },
            "computedStyle": {
              "rowColStackOnMobile": true,
              "rowReverseColStackOnMobile": false
            }
          },
          "columns": [
            {
              "style": {
                "background-color": "transparent",
                "border-bottom": "0px solid transparent",
                "border-left": "0px solid transparent",
                "border-right": "0px solid transparent",
                "border-top": "0px solid transparent",
                "padding-bottom": "5px",
                "padding-left": "0px",
                "padding-right": "0px",
                "padding-top": "5px"
              },
              "modules": [
                {
                  "type": "mailup-bee-newsletter-modules-image",
                  "descriptor": {
                    "image": {
                      "alt": "",
                      "src": "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/894a3df4-0f7a-47b1-a385-dfcd97312730/user-id-631/SALE0704%20%5BConverted%5D.png",
                      "href": "",
                      "width": "1920px",
                      "height": "2880px"
                    },
                    "style": {
                      "width": "100%",
                      "padding-top": "0px",
                      "padding-right": "0px",
                      "padding-bottom": "0px",
                      "padding-left": "0px"
                    },
                    "computedStyle": {
                      "class": "center fixedwidth",
                      "width": "225px"
                    }
                  },
                  "uuid": "c02afa33-92a2-4354-bb2e-66ca89d5f4f1"
                }
              ],
              "grid-columns": 6,
              "uuid": "ff413ae7-307f-43c2-aeb3-7fe5282fa1f9"
            },
            {
              "style": {
                "background-color": "transparent",
                "border-bottom": "0px solid transparent",
                "border-left": "0px solid transparent",
                "border-right": "0px solid transparent",
                "border-top": "0px solid transparent",
                "padding-bottom": "5px",
                "padding-left": "0px",
                "padding-right": "0px",
                "padding-top": "5px"
              },
              "modules": [
                {
                  "type": "mailup-bee-newsletter-modules-image",
                  "descriptor": {
                    "image": {
                      "alt": "LogoPlaceHolder",
                      "src": "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/894a3df4-0f7a-47b1-a385-dfcd97312730/user-id-631/Mail-11.png",
                      "href": "",
                      "width": "153px",
                      "height": "80px"
                    },
                    "style": {
                      "width": "100%",
                      "padding-top": "0px",
                      "padding-right": "0px",
                      "padding-bottom": "0px",
                      "padding-left": "0px"
                    },
                    "computedStyle": {
                      "class": "center fixedwidth",
                      "width": "100px"
                    }
                  },
                  "uuid": "4ced85d8-0674-4d67-8870-9f3d83d9437b"
                },
                {
                  "type": "mailup-bee-newsletter-modules-heading",
                  "descriptor": {
                    "heading": {
                      "title": "h3",
                      "text": "<span class=\"tinyMce-placeholder\">Get our very special offer</span>",
                      "style": {
                        "color": "#000",
                        "font-size": "16px",
                        "font-family": "'Raleway', sans-serif",
                        "link-color": "#E01253",
                        "line-height": "120%",
                        "text-align": "left",
                        "direction": "ltr",
                        "font-weight": "500",
                        "letter-spacing": "0px"
                      }
                    },
                    "style": {
                      "width": "100%",
                      "text-align": "center",
                      "padding-top": "0px",
                      "padding-right": "0px",
                      "padding-bottom": "0px",
                      "padding-left": "0px"
                    },
                    "computedStyle": {
                      "width": 52,
                      "height": 42
                    }
                  },
                  "uuid": "d2a032ed-b8fc-4944-9deb-d15c1b779a7f"
                },
                {
                  "type": "mailup-bee-newsletter-modules-divider",
                  "descriptor": {
                    "divider": {
                      "style": {
                        "border-top": "2px solid #FF7175",
                        "width": "20%"
                      }
                    },
                    "style": {
                      "padding-top": "10px",
                      "padding-right": "10px",
                      "padding-bottom": "10px",
                      "padding-left": "0px"
                    },
                    "computedStyle": {
                      "align": "left",
                      "hideContentOnMobile": false
                    }
                  },
                  "uuid": "2b327b45-422a-4aad-9bbe-980d356cd4cd"
                },
                {
                  "type": "mailup-bee-newsletter-modules-text",
                  "descriptor": {
                    "text": {
                      "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 14px; line-height: 16px; font-family: Raleway, sans-serif;\" data-mce-style=\"font-size: 14px; line-height: 16px; font-family: Raleway, sans-serif;\"><p style=\"font-size: 14px; line-height: 16px; word-break: break-word;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 34px; line-height: 40px;\" data-mce-style=\"font-size: 34px; line-height: 40px;\"><strong>Unlock Mega Special Offer</strong></span></p></div>",
                      "style": {
                        "color": "#000",
                        "line-height": "120%",
                        "font-family": "'Raleway', sans-serif"
                      },
                      "computedStyle": {
                        "linkColor": "#FF819C"
                      }
                    },
                    "style": {
                      "padding-top": "10px",
                      "padding-right": "10px",
                      "padding-bottom": "10px",
                      "padding-left": "10px"
                    },
                    "computedStyle": {
                      "hideContentOnMobile": false
                    }
                  },
                  "uuid": "6f2fae31-0a4f-426e-b86b-67ff05764cca"
                }
              ],
              "grid-columns": 6,
              "uuid": "8e533843-20cf-4c4d-8de6-43824ca5d337"
            }
          ],
          "type": "two-columns-empty",
          "uuid": "3260a9d6-6869-4ab0-9633-97427b29ecab"
        }
      ],
      "template": {
        "name": "template-base",
        "type": "basic",
        "version": "2.0.0"
      },
      "title": "BF-ecommerce-template"
    },
    "comments": {
      
    }
  };