<div class="font" style="padding: 15px">
  <div>
    <div class="contact-us-dialog-header" *ngIf="data['header']">
      <span style="text-align: center"> {{ data["header"] }}</span>
    </div>
    <div class="access-dialog-message" *ngIf="data['acess']">
      <div class="qb-d-flex" style="justify-content: center;">
        <span class="material-symbols-outlined" style="font-size: 35px; padding-bottom: 10px;">
          account_circle_off
        </span>
      </div>
        <span style="text-align: center; display: flex; justify-content: center;"> {{ data["acess"] }}</span>
    </div>
  </div>
  <div *ngIf="data['body']">
    <div class="contact-us-dialog-message">
      <span>{{ data["body"] }}</span>
    </div>
  </div>
  <div *ngIf="data['guideLine']" class="note-message" [innerHTML]="data['guideLine']"></div>
  <div style="display: flex; justify-content: flex-end" *ngIf="!data['buttonText']">
    <span class="primary-button" (click)="submit(false)">{{'SHARED_PAGE.OK' | i18nTranslate}}</span>
  </div>
  <div style="display: flex; justify-content: flex-end; gap: 20px;" *ngIf="data['buttonText']">
    <span class="qb-d-flex qb-ai-center qb-reach-out-text">
      <a href="mailto:Cs@qubriux.com">Reach out</a>
    </span>
    <span class="primary-button" (click)="submit(false)">{{data['buttonText']}}</span>
  </div>
</div>
