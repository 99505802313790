import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog"; 

@Component({
  selector: 'app-data-fetching-dialog',
  templateUrl: './data-fetching-dialog.component.html',
  styleUrls: ['./data-fetching-dialog.component.css']
})
export class DataFetchingDialogComponent implements OnInit {

  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

}
