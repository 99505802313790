import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { InvitePartnerDialogComponent, SuccessDialogComponent, PartnershipRewardCreationDialogboxComponent, PartnershipInitiateDialogBoxComponent, ConnectPaypalComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: "app-partner-search",
  templateUrl: "./partner-search.component.html",
  styleUrls: ["./partner-search.component.css"],
})
export class PartnerSearchComponent implements OnInit {
  loading = true;
  pageIndex = 1;
  pageSize = 10;
  searchString = "";
  timeout;
  length;
  colors = [
    "#5B86E5",
    "#47B27C",
    "#B24592",
    "#0183FB",
    "#F15F79",
    "#FDC854",
    "#36D1DC",
  ];
  partnerList = [];

  constructor(
    private matDialog: MatDialog,
    private apiCall: ApiCallService,
    private router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 5
    ) {
      this.pageIndex += 1;
      this.getPartnerList(false);
    }
  }

  ngOnInit() {
    this.getPartnerList(true);
  }

  invitePartner() {
    const dialogRef = this.matDialog.open(InvitePartnerDialogComponent, {
      panelClass: "custom-dialog-container",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getPartnerList(isActivate) {
    if (isActivate) {
      this.loading = true;
      this.apiCall
        .getPotentialPartnerList(
          this.pageIndex,
          this.pageSize,
          this.searchString
        )
        .subscribe((response) => {
          if (isActivate) this.partnerList = response["body"]["list"];
          else
            response["body"]["list"].forEach((item) => {
              this.partnerList.push(item);
            });
          this.length = response["body"].count;
          this.partnerList.forEach((partner) => {
            partner["link"] = "https://" + partner["shopUrl"];
          });
          this.loading = false;
        });
    } else {
      if (this.length != this.partnerList.length) {
        this.apiCall
          .getPotentialPartnerList(
            this.pageIndex,
            this.pageSize,
            this.searchString
          )
          .subscribe((response) => {
            if (isActivate) this.partnerList = response["body"]["list"];
            else
              response["body"]["list"].forEach((item) => {
                this.partnerList.push(item);
              });
            this.length = response["body"].count;
            this.partnerList.forEach((partner) => {
              partner["link"] = "https://" + partner["shopUrl"];
            });
            this.loading = false;
          });
      }
    }
  }

  openInitiatePartnershipDialog() {}

  openSuccessDialog() {
    const dialogRef2 = this.matDialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "25%",
      data: {
        message: this._i18nDynamicTranslate.transform("Request sent successfully", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Continue to my partnership request page", ['POS_PAGE']),
      },
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if (result) {
        this.rerouteToPartnershipRequests();
      } else {
        dialogRef2.close();
        this.pageIndex = 1;
        this.getPartnerList(true);
      }
    });
  }

  rerouteToPartnershipRequests() {
    this.router.navigate(["/app/partnership/partnership-requests"]);
  }

  openRewardCreationDialog(name, targetId) {
    const dialogRef = this.matDialog.open(
      PartnershipRewardCreationDialogboxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "540px",
        height: "228x",
        data: {
          name: name,
          targetId: targetId,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSuccessDialog();
      } else {
        dialogRef.close();
      }
    });
  }

  openInitiateDialog(name, targetId) {
    const dialog = this.matDialog.open(PartnershipInitiateDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
      data: {
        name: name,
        targetId: targetId,
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.openRewardCreationDialog(name, targetId);
      } else if (result === null) {
        this.openSuccessDialog();
      } else {
        dialog.close();
      }
    });
  }

  initiatePartership(name, targetId) {
    this.apiCall.getPaypalID().subscribe((response) => {
      if (response["body"] === "Paypal id doesn't exist") {
        this.openConnectToPaypalDialog(name, targetId);
      } else {
        this.openInitiateDialog(name, targetId);
      }
    });
  }

  openConnectToPaypalDialog(name, targetId) {
    const d = this.matDialog.open(ConnectPaypalComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
    });
    d.afterClosed().subscribe((res) => {
      if (res) {
        this.openInitiateDialog(name, targetId);
      }
    });
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.length = null;
        $this.pageIndex = 1;
        $this.getPartnerList(true);
      }
    }, 600);
  }
}
