import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-segmentation-customer-list-redirect',
  templateUrl: './segmentation-customer-list-redirect.component.html',
  styleUrls: ['./segmentation-customer-list-redirect.component.css']
})
export class SegmentationCustomerListRedirectComponent implements OnInit {

  @Input() miniVer = false;
  constructor(private _router:Router) { }

  ngOnInit(): void {
  }

  navigateToSegmentation() {
    this._router.navigate(['/app/segments']);
  }

  navigateToCustomerList() {
    this._router.navigate(['/app/customer-list']);
  }

}
