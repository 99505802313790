<div
  class="qb-insightAnalytics qb-main-container"
  *ngIf="validWidgets"
  data-widget-name="ANALYZE"
  id="parent-div"
>
<div>
  <app-breadcrumb></app-breadcrumb>
</div>
  <div class="qb-mobile-header" *ngIf="mainTopic != 'campaignkpi'">
    <div class="qb-sub-container">
      <mat-select
        [(ngModel)]="mainTopic"
        (selectionChange)="mainTopicChange(mainTopic)"
      >
        <mat-option value="allemails">{{
          "ANALYZE_PAGE.ALL_EMAILS" | i18nTranslate
        }}</mat-option>
        <mat-option value="campaignEmails">{{
          "ANALYZE_PAGE.CAMPAIGN_EMAILS" | i18nTranslate
        }}</mat-option>
        <mat-option value="surveyemails">{{
          "ANALYZE_PAGE.SURVEY_EMAILS" | i18nTranslate
        }}</mat-option>
        <mat-option value="offeremails">{{
          "ANALYZE_PAGE.OFFER_EMAILS" | i18nTranslate
        }}</mat-option>
      </mat-select>
    </div>
    <div class="qb-w-40">
      <div class="qb-selector-div">
        <div class="material-icons-outlined qb-pr-4">today</div>
        <mat-select
          [(ngModel)]="selectedDays"
          (selectionChange)="updateDatefromQuickView(selectedDays)"
        >
          <mat-option value="Last One Week">{{
            "ANALYZE_PAGE.LAST_ONE_WEEK" | i18nTranslate
          }}</mat-option>
          <mat-option value="Last One Month">{{
            "ANALYZE_PAGE.LAST_30_DAYS" | i18nTranslate
          }}</mat-option>
          <mat-option value="Last Six Months">{{
            "ANALYZE_PAGE.LAST_6_MONTHS" | i18nTranslate
          }}</mat-option>
          <mat-option value="Last one Year">{{
            "ANALYZE_PAGE.LAST_YEAR" | i18nTranslate
          }}</mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <!-- <div class="qb-header" *ngIf="mainTopic != 'campaignkpi'">
    <div class="qb-header-div">
      <div>
        <div
          *ngIf="mainTopic != 'allemails' && (validWidgets | widgetConfig : 74)"
          class="dropdown-list"
        >
        <div class="qb-filter-wrapper">
          <div class="qb-heading-text">
            {{ getDropDownType(mainTopic) }} &nbsp;&nbsp;
          </div>
          <div class="qb-d-flex">
            <div
                class="attribute-value"
                (click)="toggleDropdown($event, logExpIndex, compIndex, 'value')"
              >
                <div class="attribute-value-text" [title]="dropDownList.value">
                  {{ getNameFromEmailSenderList(dropDownList.value) }}
                </div>
                <mat-icon
                  class="down-arrow"
                  [id]="'down-arrow-value' + logExpIndex + compIndex"
                  >keyboard_arrow_down</mat-icon
                >
                <div
                  class="dropdown parameter-dropdown"
                  [id]="'value-dropdown' + logExpIndex + compIndex"
                >
                  <div class="category-container">
                    <div
                      *ngFor="let category of CategoryList"
                      class="category"
                      title="{{ category }}"
                      (click)="setAttribute('CATEGORY', category, dropDownList)"
                      [ngClass]="{
                        'active-category': dropDownList.type === category
                      }"
                    >
                      {{ getDisplayName(category) }}
                    </div>
                  </div>
                  <div class="parameters-container">
                    <input
                      class="op-search"
                      placeholder="Search"
                      type="text"
                      (keydown)="stringInputHandler($event, 50)"
                      [(ngModel)]="op2ListSearch"
                    />
                    <div
                      class="search-filtered-dropdown"
                      *ngIf="!dropdownListLoading"
                    >
                      <div
                        *ngFor="let item of searchFiltered(apiList)"
                        class="dropdown-opt"
                        (click)="setAttribute('VALUE', item.id, dropDownList)"
                        [ngClass]="{ 'active-opt': dropDownList.value == item.id }"
                        [matTooltip]="item.name"
                        [matTooltipPosition]="'right'"
                        [matTooltipShowDelay]="500"
                      >
                        <span>{{ item.name }}</span>
                      </div>
                      <div
                        class="no-product"
                        *ngIf="searchFiltered(apiList).length == 0"
                        (click)="$event.stopPropagation()"
                      >
                        {{ "SEGMENT_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
                      </div>
                    </div>
                    <div *ngIf="dropdownListLoading">
                      <div class="qb-d-flex qb-jc-center">
                        <mat-spinner diameter="12"></mat-spinner>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div>
                <span
                  class="material-symbols-outlined"
                  (click)="getAllEmailData(mainTopic)"
                  matTooltip="Reset Dropdown"
                >
                  sync
                </span>
            </div>
          </div>
        </div>
        
        </div>
      </div>
      <div class="qb-d-flex qb-ai-center">
        <div class="qb-filter-wrapper">
          <div class="qb-heading-text">
            Start Date - End Date
          </div>
          <div class="qb-d-flex">
            <div class="qb-date-range-picker-wrapper">
              <mat-form-field appearance="fill">
                <mat-date-range-input
                  [formGroup]="range"
                  [rangePicker]="picker"
                  [max]="getMaxDate()"
                  style="display: none;"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Select"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    (dateChange)="onEndDateChange($event)"
                  />
                </mat-date-range-input>
                <div style="display: flex; flex-direction: row">
                  <div>{{ range.value.start | date : getDateFormat() }}</div>
                  <span style="margin: 0 4px" *ngIf="range.value.start != null"
                    >-</span
                  >
                  <div>{{ range.value.end | date : getDateFormat() }}</div>
                </div>
                <div *ngIf="range.value.start == null">
                  Select
                </div>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
    
                <mat-error
                  *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                  >{{
                    "ANALYZE_PAGE.INVALID_START_DATE" | i18nTranslate
                  }}</mat-error
                >
                <mat-error
                  *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                  >{{ "ANALYZE_PAGE.INVALID_END_DATE" | i18nTranslate }}</mat-error
                >
              </mat-form-field>
            </div>
            <div class="reset-button-date">
            <span
                class="material-symbols-outlined"
                (click)="resetDateFilter()"
                matTooltip="Reset Dropdown"
              >
                sync
              </span>
            </div>
          </div>
        </div>
        <div class="qb-d-flex qb-m-25 qb-fd-coloumn qb-filter-wrapper">
          <div class="qb-heading-text">
            View By
          </div>
          <div>
            <div>
              <div
                class="qb-multi-select-panel"
                id="qb-multi-select-panel"
                (click)="toggleSelect()"
              >
                <span *ngIf="filterViewBy" class="qb-text-capitalize"
                  >{{ filterViewBy }}s</span
                >
                <span *ngIf="filterViewBy == null">{{
                  "ANALYZE_PAGE.SELECT" | i18nTranslate
                }}</span>
                <div
                  class="qb-keyboard-down-icon-container"
                  id="qb-keyboard-down-icon-container"
                >
                  <mat-icon class="keyboard-down-icon"
                    >keyboard_arrow_down</mat-icon
                  >
                </div>
              </div>
              <div class="qb-filter-drop-down">
                <div
                  class="qb-multi-select-dropdown"
                  id="qb-multi-select-dropdown"
                >
                  <div id="qb-segment-select" class="qb-segment-select">
                    <div #filterdropdown>
                      <mat-option
                        class="qb-fs-11"
                        (click)="updateCheckboxValues('day')"
                        color="primary"
                        data-widget-name="widgetNameForAnalytics"
                        data-button-name="Filter By: Days"
                        >{{ "ANALYZE_PAGE.DAYS" | i18nTranslate }}
                      </mat-option>
                      <mat-option
                        class="qb-fs-11"
                        (click)="updateCheckboxValues('month')"
                        color="primary"
                        data-button-name="Filter By: Months"
                        >{{ "ANALYZE_PAGE.MONTHS" | i18nTranslate }}
                      </mat-option>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div
    *ngIf="(validWidgets | widgetConfig : 21) && mainTopic != 'campaignkpi'"
    class="qb-mobile-main"
  >
    <div
      class="qb-selction-conatiner"
      (click)="section = 1"
      *ngIf="section == 2"
    >
      <span class="material-icons-outlined"> arrow_back_ios </span>
    </div>
    <div class="qb-w-100 qb-h-60-px">
      <div class="qb-email-clicked" *ngIf="section == 1">
        <div
          class="qb-topic"
          (click)="setSubtopicActive('email_sent_count', 'totalEmailsSent')"
          [ngClass]="{ active: subtopic === 'email_sent_count' }"
        >
          {{ "ANALYZE_PAGE.EMAIL_SENT" | i18nTranslate }}
        </div>
        <div
          class="qb-topic"
          (click)="setSubtopicActive('email_delivered', 'deliveredRate')"
          [ngClass]="{ active: subtopic === 'email_delivered' }"
        >
          {{ "ANALYZE_PAGE.DELIVERY_RATE" | i18nTranslate }}
        </div>
        <div
          class="qb-topic"
          (click)="setSubtopicActive('email_opened', 'openedRate')"
          [ngClass]="{ active: subtopic === 'email_opened' }"
        >
          {{ "ANALYZE_PAGE.OPENED_RATE" | i18nTranslate }}
        </div>
      </div>

      <div class="qb-email-clicked" *ngIf="section == 2">
        <div
          class="qb-topic"
          (click)="setSubtopicActive('email_clicked', 'clickedRate')"
          [ngClass]="{ active: subtopic === 'email_clicked' }"
        >
          {{ "ANALYZE_PAGE.CLICKED_RATE" | i18nTranslate }}
        </div>
        <div
          class="qb-topic"
          (click)="setSubtopicActive('order_count', 'totalNewOrders')"
          [ngClass]="{ active: subtopic === 'order_count' }"
        >
          {{ "ANALYZE_PAGE.NEW_ORDERS" | i18nTranslate }}
        </div>
        <div
          class="qb-topic"
          (click)="setSubtopicActive('order_revenue', 'totalRevenue')"
          [ngClass]="{ active: subtopic === 'order_revenue' }"
        >
          {{ "ANALYZE_PAGE.REVENUE_GENERATED" | i18nTranslate }}
        </div>
      </div>
    </div>
    <div
      class="qb-selction-conatiner"
      (click)="section = 2"
      *ngIf="section == 1"
    >
      <span class="material-icons-outlined"> arrow_forward_ios </span>
    </div>
  </div>

  <div
    class="qb-main"
    *ngIf="(validWidgets | widgetConfig : 21) && mainTopic != 'campaignkpi'"
  >
    <div class="qb-header-deatils-container">
      <div
        class="qb-analytics-graph-container"
        *ngIf="
          mainTopic == 'allemails' ||
          mainTopic == 'campaignEmails' ||
          mainTopic == 'surveyemails' ||
          mainTopic == 'offeremails'
        "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.EMAIL_SENT' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.totalEmailsSent || 0 }}</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_sent_count"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
          mainTopic == 'allemails' ||
          mainTopic == 'campaignEmails' ||
          mainTopic == 'surveyemails' ||
          mainTopic == 'offeremails'
        "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.DELIVERY_RATE' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.deliveredRate || 0 }}%</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_delivered"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
          mainTopic == 'allemails' ||
          mainTopic == 'campaignEmails' ||
          mainTopic == 'surveyemails' ||
          mainTopic == 'offeremails'
        "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.OPENED_RATE' | i18nTranslate}} {{'ANALYZE_PAGE.OF_DELIVERED_MAILS' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["openedRate"] || 0 }}%</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_opened"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
          mainTopic == 'allemails' ||
          mainTopic == 'campaignEmails' ||
          mainTopic == 'surveyemails' ||
          mainTopic == 'offeremails'
        "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.CLICKED_RATE' | i18nTranslate}} {{'ANALYZE_PAGE.OF_OPENED_MAILS' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["clickedRate"] || 0}}%</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_clicked"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
          mainTopic == 'allemails' ||
          mainTopic == 'campaignEmails' ||
          mainTopic == 'surveyemails' ||
          mainTopic == 'offeremails'
        "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.SPAM_RATE' | i18nTranslate}} {{'ANALYZE_PAGE.OF_DELIVERED_MAILS' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["spamRate"] || 0}}%</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_complained"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
          mainTopic == 'allemails' ||
          mainTopic == 'campaignEmails' ||
          mainTopic == 'surveyemails' ||
          mainTopic == 'offeremails'
        "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.FAILED_RATE' | i18nTranslate}} {{'ANALYZE_PAGE.OF_EMAILS_SENT' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["failedRate"] || 0}}%</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_failed"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
        mainTopic == 'campaignEmails' ||
        mainTopic == 'surveyemails' ||
        mainTopic == 'offeremails'
      "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.UNSUBSCRIBED_RATE' | i18nTranslate}} {{'ANALYZE_PAGE.OF_DELIVERED_MAILS' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["unsubscribedRate"] || 0}}%</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_unsubscribed"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="mainTopic == 'offeremails' && shop === 'shopify'"
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.CART_ADDITIONS' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["addCartCount"] || 0}}</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.email_clicked_add_cart"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="mainTopic == 'campaignEmails' && (validWidgets | widgetConfig: 68)"
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.VISITORS' | i18nTranslate}} - {{ catagorySelectData?.emailStats?.["visitorsCount"] || 0}}</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.visitor_count"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
        (mainTopic == 'allemails' ||
        mainTopic == 'campaignEmails' ||
        mainTopic == 'offeremails') && (validWidgets | widgetConfig: 66)
      "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.NEW_ORDERS' | i18nTranslate}} - {{ catagorySelectData?.totalNewOrders || 0}}</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.order_count"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="
        (mainTopic == 'allemails' ||
        mainTopic == 'campaignEmails' ||
        mainTopic == 'offeremails') && (validWidgets | widgetConfig: 67)
      "
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.REVENUE_GENERATED' | i18nTranslate}} - {{ currencySymbol }}
              {{ catagorySelectData?.totalRevenue >= 1000 ? (catagorySelectData?.totalRevenue / 1000).toFixed(1).replace('.0', '') + 'k' : catagorySelectData?.totalRevenue | number: "1.2" }}</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.order_revenue"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
      <div
        class="qb-analytics-graph-container"
        *ngIf="mainTopic == 'surveyemails'"
      >
        <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
          <div>
            <h6 class="qb-analytics-heading">{{'ANALYZE_PAGE.TOTAL_SURVEY_RESPONSE' | i18nTranslate}} - {{ catagorySelectData?.totalSurveyResponses || 0}}</h6>
          </div>
        </div>
        <div class="qb-d-flex" *ngIf="!graphLoading">
          <div
            echarts
            [options]="chartConfigurations.survey_count"
            style="height: 400px; width: 100%"
          ></div>
        </div>
        <div
          class="activity-spinner-container"
          style="height: 80%"
          *ngIf="graphLoading"
        >
          <app-qubriux-loading [size]="45"></app-qubriux-loading>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    class="qb-chart-area"
    #chartArea
    *ngIf="mainTopic != 'campaignkpi'"
  >
    <div
      *ngIf="!graphDataLoading"
      class="qb-chart-container"
      [style.height.px]="chartheight"
    >
      <div *ngIf="!graphDataNull" style="width: 100%; height: 100%" class="qb-w-100 qb-h-100">
        
        <div class="qb-d-flex qb-w-100 qb-h-98">
          <div class="qb-write-vr-bt">{{ YLegend | i18nDynamicTranslate : "POS_PAGE"}}</div>
          <div class="qb-h-98">
            <app-insights-bar-chart
              [data]="graphData"
              [sortBy]="filterViewBy"
              [chartSize]="chartheight"
            >
            </app-insights-bar-chart>
          </div>
        </div>
        <div class="qb-y-axis-div">
          {{ filterViewBy | titlecase | i18nDynamicTranslate : "POS_PAGE" }}s
        </div>
      </div>

      <div
        *ngIf="graphDataNull"
        class="qb-no-data-main-container"
      >
        <div
         class="qb-no-data-inner-container"
        >
          {{'ANALYZE_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
        </div>
      </div>
    </div>
    <div
      *ngIf="graphDataLoading"
      class="qb-graph-loading-div"
      [style.height.px]="chartheight"
    >
      <div
        class="qb-graph-loading-inner-div"
      >
        <mat-spinner diameter="25"></mat-spinner>
      </div>
    </div>
  </div> -->
  <div *ngIf="mainTopic === 'campaignkpi'">
    <app-campaign-kpi></app-campaign-kpi>
  </div>
</div>
