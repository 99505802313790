import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getLogoUrlService } from "src/app/core/services/get-logo-url.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-welcome-widget",
  templateUrl: "./welcome-widget.component.html",
  styleUrls: ["./welcome-widget.component.css"],
})
export class WelcomeWidgetComponent implements OnInit {
  welcomeData = [
    {
      title: "Welcome to Qubriux",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/welcome_to_gf.svg",
      id: 1,
      desc: "Accelerate your revenue growth with smart customer engagement using hyper-personalized offers and discounts."
    },
    {
      title: "Marketing Automation",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/marketing_automation.svg",
      id: 2,
      desc: "Automate your marketing workflows intelligently and efficiently. Convert abandoned carts, upsell to loyal customers and much more."
    },
    {
      title: "Hyper-personalized Offers",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/hyperpersonalized_offer.svg",
      id: 3,
      desc: "Increase average order value, convert intent into purchase, and delight customers with tailor-made offers."
    },
    {
      title: "Surveys",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/surveys.svg",
      id: 4,
      desc: "Gain customer insights from Surveys and Feedback. Use Qubriux to send surveys and gauge customer preferences with Sentiment Analysis."
    },
    {
      title: "Email & SMS",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/email&sms.svg",
      id: 5,
      desc: "Boost customer engagement with personalized communications via Emails and SMS."
    },
    {
      title: "Gamification",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/gamification.svg",
      id: 6,
      desc: "Use AI to gamify customer interaction and drive sales with offer bundles curated for each and every customer."
    },
    {
      title: "Segments",
      img: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/vectors/segments.svg",
      id: 7,
      desc: "Stay close to your customers and intelligently manage them along segments. Group your customers as per your business needs."
    }
  ];
  welcomeDataIndicator = [0, 1, 2, 3, 4, 5, 6];
  welcomeCardDragStart;
  welcomeCardDragging;
  scrollWidth;
  currentScrollIndex=0;
  showLeftChevron: boolean = false;
  showRightChevron: boolean = true;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileMedia2 = window.matchMedia("(max-width: 500px)");
  strokearray = "calc(0.8 * calc(6.2831853072 * 139)) calc(6.2831853072 * 139)";
  currentSlideLevel = 1;
  showUploadLogoScreen = false;
  urlPattern =  "^(http[s]?:\/\/){0,1}(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,10}$";
  links = [
    "Facebook",
    "Twitter",
    "Instagram",
    "LinkedIn",
    // "YouTube",
    // "Pinterest",
    // "Vkontakte",
    // "Odnoklassniki",
    // "Blogger",
    // "Medium",
    // "Tumblr",
    // "SoundCloud",
    // "Spotify",
    // "Vimeo",
    // "Vine",
    // "SinaWeibo",
    // "Glassdoor",
    // "Snapchat",
    // "Telegram",
    // "Wechat",
    // "TikTok",
    // "Tripadvisor",
    // "WhatsApp",
  ];
  selectedLinks = [];
  imageSrc;
  error: string;
  dragAreaClass: string;
  draggedFiles: any;
  numberOfLinks = ["1"];
  link1;
  selectedValue = { Facebook: "" };
  displayLink = this.links;
  values = {};
  keys = {};
  image = false;
  message;
  imagePath;
  url;
  files: any[] = [];
  isHovering: boolean;
  fileToUpload: File = null;
  brandingRequest = {
    merchantId: this.tokenStorage.getMerchantId(),
    hasSkip: true,
    socialMediaUrls: {},
  };
  mobileView;
  showPreview = false;
  uploadedLogo = false;
  imageLoading = false;
  logoUrl = this.getLogoService.getLogo();

  constructor(
    private router: Router,
    private apicall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private snackbar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private getLogoService: getLogoUrlService
  ) {}

  ngOnInit(): void {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
      this.showPreview = false;
    } else {
      this.mobileView = false;
      this.showPreview = true;
    }
  }

  handleChevronVisibility(count){
    if(count===0) this.showLeftChevron=false;
    else this.showLeftChevron = true;

    if(count===6) this.showRightChevron = false;
    else this.showRightChevron = true;
  }

  onSwipeStart(e){
    this.welcomeCardDragging= true;
    this.welcomeCardDragStart=e.touches[0].screenX;
  }

  onSwipeEnd(e){
    this.welcomeCardDragging=false;
    let movedTo = e.changedTouches[0].screenX;
    let distance = Math.abs(this.welcomeCardDragStart-movedTo);
    if((this.welcomeCardDragStart>movedTo)&& distance>100) this.scrollItem(1);
    else if((movedTo>this.welcomeCardDragStart) && distance>100) this.scrollItem(-1);
  }

  scrollToItem(position){
    let scrollableContainer = document.querySelector(".carousel-overflow-wrapper");
    this.scrollWidth = scrollableContainer.getBoundingClientRect().width;
    let carouselWrapper = <HTMLElement> document.querySelector(".carousel-full-width-wrapper");
    this.handleChevronVisibility(position)
    if(carouselWrapper){
      this.currentScrollIndex = position;
      carouselWrapper.style.transform = `translateX(${-(this.scrollWidth*position)}px)`;
    }
  }

  scrollItem(diff){
    let count = this.currentScrollIndex;
    count = count + diff;
    if(count<0) count = 0;
    else if(count>6) count = 6;
    let scrollableContainer = document.querySelector(".carousel-overflow-wrapper");
    this.scrollWidth = scrollableContainer.getBoundingClientRect().width;
    let carouselWrapper = <HTMLElement> document.querySelector(".carousel-full-width-wrapper");
    this.handleChevronVisibility(count)
    if(carouselWrapper){
      this.currentScrollIndex = count;
      carouselWrapper.style.transform = `translateX(${-(this.scrollWidth*count)}px)`;
    }
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
    this.setFiles($event);
  }

  setFiles(event) {
    this.image = true;
    const files = event.target.files;
    if (files.length === 0) return;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = this._i18nDynamicTranslate.transform("Only images are supported.", ['STATIC_PAGE']);
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }

  fileBrowseHandler(event, files) {
    let allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedFileTypes.indexOf(files[0].type) !== -1) {
      this.imageLoading = true;
      this.prepareFilesList(files);
      this.setFiles(event);
      this.fileToUpload = <File>event.target.files[0];
      if (this.mobileView) this.uploadedLogo = true;
      this.imageLoading = false;
    } else {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Invalid file type", ['STATIC_PAGE']), 2000);
    }
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  redirectToApp(skip) {
    let save = true;
    Object.keys(this.values).forEach(value => {
      if(!new RegExp(this.urlPattern).test(this.values[value])) {
      save = false;
      } else {
        save = true;
      }
    })
    if(save) {
    let linkMap = {};
    let numberOfSocialLinks = 0;
    Object.keys(this.keys).map((key) => {
      Object.keys(this.values).map((value) => {
        if (key == value) {
          numberOfSocialLinks++;
          linkMap[this.keys[key]] = this.values[value];
        }
      });
    });
    if (!skip && this.fileToUpload !== null) {
      if (Object.keys(linkMap).length > 0) this.brandingRequest.hasSkip = false;
      this.brandingRequest.socialMediaUrls = linkMap;
      const fd = new FormData();
      fd.append("file", this.fileToUpload, this.fileToUpload.name);
      fd.append("brandingRequest", JSON.stringify(this.brandingRequest));
      this.apicall.uploadLogoForBranding(fd).subscribe(() => {
        if(this.tokenStorage.getPartnershipToken() === null) {
          if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
            this.router.navigate(["/app/app-home"], {
              queryParams: { type: "Objectives" },
            });
          }
          else{
            this.router.navigate(["/data-ingestion/check"])
          }
      } else {
        this.router.navigate(["/app/partnership"]);
        this.tokenStorage.deletePartnershipToken();
      }
      });
    } else {
      if (skip) {
        if(this.tokenStorage.getPartnershipToken() === null) {
          if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
            this.router.navigate(["/app/app-home"], {
              queryParams: { type: "Objectives" },
            });
          }
          else{
            this.router.navigate(["/data-ingestion/check"])
          }
        } else {
          this.router.navigate(["/app/partnership"]);
          this.tokenStorage.deletePartnershipToken();
        }
      }
      else {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please upload a logo", ['STATIC_PAGE']), 2000);
      }
    }
  }else {
    this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please enter valid social media links", ['STATIC_PAGE']), 2000);
  }
  }

  getPassedData(event) {
    this.currentSlideLevel = event.startPosition + 1;
    if (event.startPosition == 6) {
      this.showRightChevron = false;
      this.showLeftChevron = true;
    }
    if (event.startPosition == 0) {
      this.showRightChevron = true;
      this.showLeftChevron = false;
    }
    if (event.startPosition != 6 && event.startPosition != 0) {
      this.showRightChevron = true;
      this.showLeftChevron = true;
    }
  }

  getStarted() {
    this.apicall.updateWelcomeWidgetStatus().subscribe((res) => {
      // this.showUploadLogoScreen = true;
      this.redirectToApp(true);
    });
  }

  saveFiles(files: FileList) {
    if (files.length > 1) this.error = this._i18nDynamicTranslate.transform("Only one file at time allow", ['STATIC_PAGE']);
    else {
      this.error = "";
      this.draggedFiles = files;
    }
  }

  addNewLink() {
    this.numberOfLinks.push(this.numberOfLinks.length + "2");
  }

  removeLink(i) {
    if (i !== -1) {
      this.numberOfLinks.splice(i, 1);
      this.selectedLinks.splice(i, 1);
    }
    delete this.keys[i];
    delete this.values[i];
    let tempKeys = this.keys;
    this.keys = {};
    let index = 0;
    while (index < Object.keys(tempKeys).length) {
      Object.keys(tempKeys).map((key) => {
        this.keys[index] = tempKeys[key];
        index++;
      });
    }
    let tempValues = this.values;
    this.values = {};
    index = 0;
    while (index < Object.keys(tempValues).length) {
      Object.keys(tempValues).map((key) => {
        this.values[index] = tempValues[key];
        index++;
      });
    }
  }

  deleteLogo() {
    this.uploadedLogo = false;
    this.image = false;
    this.fileToUpload = null;
    this.deleteFile(0);
    this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Logo deleted", ['STATIC_PAGE']), 2000);
  }

  changeLink(link, i) {
    this.keys[i] = link.value;
    let array = [];
    for (let key in this.keys) {
      let value = this.keys[key];
      array.push(value);
    }
    this.selectedLinks = array;
  }

  input(event) {
    this.values[event.path[2].id] = event.target.value;
  }
}
