import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { MyOffersComponent, MyPartnersComponent, NewRewardComponent, PartnerDetailsComponent, PartnerOfferCreationComponent, PartnerSearchComponent, PartnershipAnalyticsComponent, PartnershipComponent, PartnershipCreateFormComponent, PartnershipFormsComponent, PartnershipRequestsComponent, PaymentsComponent, RemoveOfferComponent, RewardsComponent, TargetDetailComponent } from './pages';
import { PartnerGroupOfferCreationComponent } from './pages/partner-group-offer-creation/partner-group-offer-creation.component';

const routes: Routes = [
    // { path: "", redirectTo: "", pathMatch: "full" },
    { path: "", component: PartnershipComponent},
    { path: "my-partners", component: MyPartnersComponent,
      data: {
        titleheading: "My Partners",
        title: "My Partners",
       
      }, 
    },
    {
        path: "my-offers",
        component: MyOffersComponent,
        data: {
            titleheading: "My Offer",
            title: "My Offer",
            minPath: "Basic",
          },
    },
    {
        path: "add-partner",
        component: PartnerSearchComponent,
        data: {
          titleheading: "Add Partner",
          title: "Add Partner",
          minPath: "Basic",
        },
      },
    {
        path: "rewards",
        component: RewardsComponent,
        data: {
          titleheading: "Rewards",
          title: "Rewards",
          minPath: "Basic",
        },
    },
    {
        path: "partnership-requests",
        component: PartnershipRequestsComponent,
        data: {
          titleheading: "Partnership Requests",
          title: "Partnership Requests",
          minPath: "Basic",
        },
    },
    {
        path: "payments",
        component: PaymentsComponent,
        data: {
          titleheading: "Payments",
          title: "Payments",
          minPath: "Basic",
        },
    },
    {
        path: "analytics",
        component: PartnershipAnalyticsComponent,
        data: {
          titleheading: "Analytics",
          title: "Analytics",
          minPath: "Basic",
        },
    },
    {
        path: "form-list",
        component: PartnershipFormsComponent,
        data: {
          titleheading: "Forms",
          title: "Forms",
          minPath: "Basic",
        },
    },
    {
        path: "create-form",
        component: PartnershipCreateFormComponent,
        data: {
          titleheading: "Create Form",
          title: "Create Form",
          minPath: "Basic",
        },
    },
    {
        path: "remove-offer",
        component: RemoveOfferComponent,
        data: {
          titleheading: "Remove Offer",
          title: "Remove Offer",
          minPath: "Basic",
        },
    },
    {
        path: "rewards/new-reward",
        component: NewRewardComponent,
        data: {
          titleheading: "New Reward",
          title: "New Reward",
          minPath: "Basic",
        },
    },
    {
        path: "my-offers/new-offer",
        component: PartnerOfferCreationComponent,
        data: {
          titleheading: "Offer",
          title: "Offer",
          minPath: "Basic",
        },
    },
    {
      path: "my-offers/new-group-offer",
      component: PartnerGroupOfferCreationComponent,
      data: {
        titleheading: "Group Offer",
        title: "Group Offer",
        minPath: "Basic",
      },
    },
    {
        path: "my-partners/:store",
        component: TargetDetailComponent,
        data: {
          titleheading: "Target Details",
          title: "Target Details",
          minPath: "Basic",
        },
    },
    {
        path: "partnership-requests/:store",
        component: PartnerDetailsComponent,
        data: {
          titleheading: "Partner Details",
          title: "Partner Details",
          minPath: "Basic",
        },
    },
    {
        path: "my-partners/partner-details",
        component: PartnerDetailsComponent,
        data: {
          titleheading: "Partner Details",
          title: "Partner Details",
          minPath: "Basic",
        },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnershipRoutingModule { }
