import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { ProductTrendsComponent } from './pages';
import { ProductTrendsRoutingModule } from './product-trends-routing.module';


@NgModule({
  declarations: [
    ProductTrendsComponent
   ],
  imports: [
    SharedModule,
    ProductTrendsRoutingModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ]
})
export class ProductTrendsModule { }
