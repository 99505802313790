import {
  Component,
  HostListener,
  AfterContentInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import Bee from "@mailupinc/bee-plugin";
import { FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { template5} from "src/app/shared/json-asset";
import { fontList } from "src/app/shared/json-asset/font-list";

interface urlData {
  id: string;
  return: string;
  type: string;
  clone: string;
}

@Component({
  selector: "app-form-creation",
  templateUrl: "./form-creation.component.html",
  styleUrls: ["./form-creation.component.css"],
})

export class FormCreationComponent implements AfterContentInit {

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }

  @Output() apiData = new EventEmitter<string[]>();
  @Input() offerData;
  @Input() comOfferId;
  @Input() json;
  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  finalHeight;
  beeTest;
  beeToken;
  beeConfig;
  returnUrl;
  mergeTags = [];
  dialogNeeded;
  payload = {
    client_id: environment.beePluginPopupClientId,
    client_secret: environment.beePluginPopupClientSecretId,
    grant_type: "password",
  };
  specialLinks = [
    {
      type: "Unsubscribe",
      label: "Unsubscribe",
      link: "%unsubscribe_url%",
    },
  ];
  selectedTemplate = "T1";
  finalContent;
  templatesArray = [template5];
  templateName;
  templateForm: FormGroup;
  subjectError = false;
  nameError = false;
  submitClicked = false;
  responseMsg: string = null;
  templateIdNew = 130;
  templateType;
  formLayout = null;
  urlData: urlData = {
    id: null,
    type: null,
    return: null,
    clone: null,
  };
  layout;
  renderStatus = false;
  appSubscriptionPlan;
  validWidgets;
  html;
  loadingDialog = false;
  sendTestEmail;
  response;

  constructor(
    private apiCall: ApiCallService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private snack: SnackbarCollection
  ) {
    this.getScreenSize();
  }

  ngOnInit() {
    this.init();
  }

  ngAfterContentInit() {
    this.init();
  }

  hello() {
    this.beeTest.save();
  }

  getOfferDescription() {
    this.apiCall
    .getComOfferDescription(this.comOfferId)
    .subscribe((response) => {
      template5.page.rows[0].columns[1].modules[3].descriptor.text.html =
        response["body"]["allOfferDescription"];
    });
  }

  init() {
    this.offerData.channel = "FORM";
    this.route.params.subscribe((param) => {
      this.urlData.id = param.id;
      if (param.id !== undefined) {
        this.apiCall
          .getPartnershipFormDetails(param.id, param.type)
          .subscribe((response) => {
            this.formLayout = JSON.parse(response["body"]["formJson"]);
          });
      } else {
        this.getOfferDescription();
      }
    });
    this.beeTest = new Bee(this.beeToken);
    function userInput(message, sample) {
      return function handler(resolve, reject) {
        var data = prompt(message, JSON.stringify(sample));
        return data == null || data == ""
          ? reject()
          : resolve(JSON.parse(data));
      };
    }

    var beeConfig = {
      uid: "user-id-" + this.tokenStorage.getMerchantId(),
      container: "bee-plugin-container",
      autosave: 15,
      language: "en-US",
      trackChanges: true,
      editorFonts: {
        showDefaultFonts: false,
        customFonts: fontList,
      },
      workspace: {
        popup: {
          layout: "default",
          theme: "bootstrap",
          backgroundImage:
            "https://i.ytimg.com/vi/sd0grLQ4voU/maxresdefault.jpg",
          backgroundImageMobile: "https://.../background.png",
        },
      },
      contentDialog: {
        specialLinks: {
          label: "Add a custom Special Link",
          handler: userInput("Enter the deep link:", {
            type: "custom",
            label: "external special link",
            link: "http://www.example.com",
          }),
        },
        mergeTags: {
          label: "Add custom tag 2",
          handler: userInput("Enter the merge tag:", {
            name: "name",
            value: "[name]",
          }),
        },
        mergeContents: {
          label: "Choose a custom merge content",
          handler: userInput("Enter the merge content:", {
            name: "my custom content",
            value: "{my-custom-content}",
          }),
        },
        rowDisplayConditions: {
          label: "Open builder",
          handler: userInput("Enter the row display condition:", {
            type: "People",
            label: "Person is a developer",
            description: "Check if a person is a developer",
            before: "{if job == 'developer'}",
            after: "{endif}",
          }),
        },
      },
      onChange: function (jsonFile, response) {},
      onSave: (jsonFile, htmlFile) => {
        this.apiData.emit([htmlFile, jsonFile, []]);
      },
      onSaveAsTemplate: function () {},
      onAutoSave: function (jsonFile) {
        window.localStorage.setItem("newsletter.autosave", jsonFile);
      },
      onSend: function () {},
      onError: function () {},
    };
    if (this.json !== null) {
      this.formLayout = JSON.parse(this.json);
    }
    this.beeTest
      .getToken(this.payload.client_id, this.payload.client_secret)
      .then(() =>
        this.beeTest.start(
          beeConfig,
          this.formLayout !== null ? this.formLayout : template5
        )
      );
        }

}
