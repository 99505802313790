import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { ReviewConfigDialogBoxComponent } from '../../components/review-config-dialog-box/review-config-dialog-box.component';
import { SuccessDialogComponent } from 'src/app/shared/components/dialog-box';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';

@Component({
  selector: 'app-review-base',
  templateUrl: './review-base.component.html',
  styleUrls: ['./review-base.component.css']
})
export class ReviewBaseComponent implements OnInit {
  selectedTab = "dashboard";
  platformList;
  selectedPlatform: string = 'None'; 
  isAdmin : boolean = true; 
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private tokenService: TokenStorageService,
    private apiCall: ApiCallService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.type) this.selectedTab = params.type;
      else
        this.router.navigate(["/app/reviews"], {
          queryParams: { type: "dashboard"},
        });
    });
    if(this.tokenService.getqId() != 'null'){
      this.isAdmin = false;
    }
    this.fetchPlatformList();
  }

  fetchPlatformList(): void {
    this.apiCall.getActivePlatforms().subscribe(
      (response) => {
        this.platformList = response['body'];
        this.platformList.unshift('None');
      },
      (error) => {
        console.error("Failed to load platform list", error);
      }
    );
  }

  onPlatformChange(event: any): void {
    this.selectedPlatform = event.target.value;
  }


  openSettingsDialog(){
    const dialogref = this.dialog.open(
      ReviewConfigDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "450px",
        height : "550px",
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if (result) {
        this.openSuccessDialog(
          "Configuration updated successfully!",
          "OK"
        );
      }
    })
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
  }



}
