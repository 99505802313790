<div *ngIf="data.type === 'campaignTemplate'" style="display: flex">
  <div style="width: 30%; background: #f1f1f1; height: 520px; overflow: scroll">
    <div style="padding: 20px; border-bottom: 2px solid #c1c1c1">
      <span style="font-weight: bold">{{ data.data["name"] }}</span>
    </div>
    <div style="padding: 20px">
      <div *ngIf="data.data['campaignType'] === 'SEGMENTED'">
        <div style="margin-bottom: 10px">
          <span style="font-weight: bold">{{'SHARED_PAGE.TARGETED_SEGMENTS' | i18nTranslate}}</span>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div
            *ngFor="let segment of data.data['targetCustomerSegments']"
            style="
              margin: 5px;
              padding: 8px;
              border-radius: 25px;
              background: #ffffff;
              padding-top: 4px;
              padding-bottom: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            class="fontOpenSans"
          >
            <div>
              <span>{{ segment["segmentName"] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 20px; margin-top: 10px">
        <div style="margin-bottom: 15px">
          <span style="font-weight: bold; padding: 5px">{{'SHARED_PAGE.TRIGGER_TYPE' | i18nTranslate}}</span>
        </div>
        <div>
          <span
            style="
              padding: 5px;
              background: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            >{{ data.data["campaignTrigger"]["campaignTriggerType"] }}</span
          >
        </div>
      </div>
      <div
        style="margin-bottom: 20px; margin-top: 10px"
        *ngIf="
          data.data['campaignTrigger']['campaignTriggerType'] === 'TIME_TRIGGER'
        "
      >
        <div style="margin-bottom: 15px">
          <span style="font-weight: bold; padding: 5px">{{'SHARED_PAGE.REPEAT' | i18nTranslate}}</span>
        </div>
        <div>
          <span
            style="
              padding: 5px;
              background: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            >{{
              data.data["campaignTrigger"]["campaignTriggerRepeatType"]
            }}</span
          >
        </div>
      </div>
      <div
        style="margin-bottom: 20px; margin-top: 10px"
        *ngIf="
          data.data['campaignTrigger']['campaignTriggerType'] ===
          'EVENT_TRIGGER'
        "
      >
        <div style="margin-bottom: 15px">
          <span style="font-weight: bold; padding: 5px">{{'SHARED_PAGE.EVENT' | i18nTranslate}}</span>
        </div>
        <div>
          <span
            style="
              padding: 5px;
              background: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            >{{ data.data["campaignTrigger"]["event"]["eventID"] }}</span
          >
        </div>
      </div>
      <div
        style="
          margin-top: 10px;
          margin-bottom: 10px;
          text-transform: uppercase;
          font-weight: bold;
          display: flex;
          justify-content: center;
        "
      >
        <span>{{'SHARED_PAGE.CAMPAIGN_FLOW' | i18nTranslate}}</span>
      </div>
      <div *ngFor="let action of data.data['campaignActions']; let i = index">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            style="
              padding: 4px;
              background: #ffffff;
              padding-left: 10px;
              padding-right: 10px;
              border-radius: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <mat-icon style="color: #88c4fb">timer</mat-icon>
            <span *ngIf="action['timeToTriggerInMinutes'] === 0">{{'SHARED_PAGE.NO_DELAY' | i18nTranslate}}</span>
            <span *ngIf="action['timeToTriggerInMinutes'] !== 0"
              >Delay {{ convertMinutes(action["timeToTriggerInMinutes"]) }}
              {{ delayType(action["timeToTriggerInMinutes"]) }}</span
            >
          </div>
        </div>
        <span style="display: flex; justify-content: center"
          ><img [src]="assetPath.arrowUrl" alt=""
        /></span>
        <div
          class="type-container"
          [ngClass]="{ active: selectedIndex === i }"
          (click)="
            viewMessageTemplateDetail(
              action['campaignActionType'],
              action['messageTemplateParams']['emailLayoutId'],
              action['messageTemplateParams']['campaignMessageLayOutType'],
              i
            )
          "
        >
          <span
            style="display: flex"
            *ngIf="action['campaignActionType'] === 'EMAIL_DELIVERY'"
          >
            <mat-icon style="color: #88c4fb">mail</mat-icon>{{'SHARED_PAGE.EMAIL' | i18nTranslate}}
          </span>
          <span
            style="display: flex"
            *ngIf="action['campaignActionType'] === 'SMS_DELIVERY'"
          >
            <mat-icon style="color: #88c4fb">mail</mat-icon>{{'SHARED_PAGE.SMS' | i18nTranslate}}
          </span>
        </div>
        <span
          *ngIf="i < data.data['campaignActions'].length - 1"
          style="display: flex; justify-content: center"
          ><img [src]="assetPath.arrowUrl" alt=""
        /></span>
      </div>
    </div>
  </div>
  <div style="width: 70%; height: 520px; overflow: scroll">
    <div *ngIf="messageTemplate" style="width: 100%">
      <div
        style="display: flex; margin: 10px; margin-bottom: 0"
        *ngIf="messageTemplate['subject']"
      >
        <span style="font-weight: bold; margin-right: 10px">{{'SHARED_PAGE.SUBJECT' | i18nTranslate}} :</span>
        <span>{{ messageTemplate["subject"] }}</span>
      </div>
      <app-email-template-preview
        [selectedLayout]="messageTemplate['body']"
      ></app-email-template-preview>
    </div>
  </div>
</div>
