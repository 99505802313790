import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'screenConfig'
})
export class ScreenConfig implements PipeTransform {

  transform(screens, screenId): any {
    let index = screens?.findIndex(d => d === screenId);
    if(index > -1){
        return true;
    }
    else{
        return false;
    }
  }
}