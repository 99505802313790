import { DatePipe, getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SuccessDialogComponent, PartnershipRewardCreationDialogboxComponent, PartnershipInitiateDialogBoxComponent, PartnershipSendOfferDialogboxComponent, DisablePartnershipOfferComponent, SchedulePartnershipOfferSucessDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-target-detail",
  templateUrl: "./target-detail.component.html",
  styleUrls: ["./target-detail.component.css"],
})
export class TargetDetailComponent implements OnInit {
  RevenueGenerated = 1996;
  isActive = false;
  timeRange1 = "This Week";
  timeRange2 = "This Week";
  revCurrency;
  currencySymbol;
  partnerData;
  id;
  action;
  link;
  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    partnershipRequestId: -1,
    startDate: this.datePipe.transform(
      new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
      "yyyy-MM-dd"
    ),
    endDate: this.datePipe.transform(
      new Date(Date.now()).toISOString(),
      "yyyy-MM-dd"
    ),
    dataPointType: "WEEK",
  };
  isSource = false;
  isInitiatePartnership;
  formId;
  showSendNewOffer = true;
  showCreateNewForm = false;
  loading = true;
  type;
  comId;
  isPos = false;
  len = 0;
  offerTableHeadings = ['Offer Name', 'Coupon Code', 'Offer Display Valid Till', 'Coupon Valid Till', 'Action'];
  formTableHeadings = ['Form Name', 'Target', 'Last Activated On', 'Status'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private apiCall: ApiCallService,
    private matDialog: MatDialog,
    private snackBar: SnackbarCollection,
    private router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    this.getCurrencySymbol();
    this.getCoMargetingOfferCount();
    this.getParams();
    if(this.tokenStorage.getMerchantOnlineStore() === 'shopify'){
      this.isPos = false;
    }
    else{
      this.isPos = true;
    }
  }

  toggleForm(id) {
        this.deactiveForm(id);
  }

  deactiveForm(id) {
    this.apiCall.deactivePForm(id).subscribe((response) => {
      this.getList();
    });
  }

  getList() {
    if (this.type !== "source") {
      this.isSource = false;
      this.getTargetMerchantStats();
    } else {
      this.isSource = true;
      this.getSourceMerchantStats();
    }
  }

  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }

  getTargetMerchantStats() {
    this.apiCall.getTargetMerchantStats(this.data).subscribe((response) => {
      this.partnerData = response["body"];
      this.link = "https://" + this.partnerData.shopURL;
      this.showCreateNewForm = true;
      this.showSendNewOffer = true;
      this.partnerData["offersShared"].forEach((offer) => {
        if (offer.action === "ACTIVE") {
          this.showSendNewOffer = false;
        }
      });
      this.loading = false;
    });
  }

  getSourceMerchantStats() {
    this.apiCall.getSourceMerchantStats(this.data).subscribe((response) => {
      this.partnerData = response["body"];
      this.link = "https://" + this.partnerData.shopURL;
      this.showSendNewOffer = false;
      this.partnerData.revenueGenerated = this.partnerData.rewardsEarned;
      this.partnerData["activeForms"].forEach((form) => {
        if (form.status === "ACTIVE") {
          this.showCreateNewForm = false;
        }
      });
      this.partnerData["offersShared"].forEach((offer) => {
        if (offer.action === "ACTIVE") {
          this.showSendNewOffer = true;
        }
      });
      this.loading = false;
    });
  }

  getParams() {
    this.activatedRoute.params.subscribe((param) => {
      this.action = param.action;
      this.data.partnershipRequestId = param.id;
      this.isInitiatePartnership = param.initiatePartnership;
      this.type = param.type;
      this.comId  = param.comId;    
      this.getList();
    });
  }

  createForm() {
    if(this.len === 0){
      this.redirectToCeateCoMarketing();
    }
    // else if(this.comId === null){
    //   this.redirectToCeateCoMarketing();
    // }
    else{
      this.router.navigate([
        "/app/partnership/form-list",
        {
          request: this.data.partnershipRequestId,
        },
      ]);
    }
  }

  openSuccessDialog() {
    const dialogRef2 = this.matDialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "25%",
      data: {
        message: this._i18nDynamicTranslate.transform("Request sent successfully", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Continue to my partnership request page", ['POS_PAGE']),
      },
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if (result) {
        this.rerouteToPartnershipRequests();
      } else {
        dialogRef2.close();
        this.getList();
      }
    });
  }

  rerouteToPartnershipRequests() {
    this.router.navigate(["/app/partnership/partnership-requests"]);
  }

  openRewardCreationDialog() {
    const dialogRef = this.matDialog.open(
      PartnershipRewardCreationDialogboxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "540px",
        height: "228x",
        data: {
          name: this.partnerData.partnerName,
          targetId: this.partnerData.sourceMerchantId,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSuccessDialog();
      } else {
        dialogRef.close();
      }
    });
  }

  initiatePartnership() {
    const dialog = this.matDialog.open(PartnershipInitiateDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
      data: {
        name: this.partnerData.partnerName,
        targetId: this.partnerData.sourceMerchantId,
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.openRewardCreationDialog();
      } else if (result == null) {
        this.openSuccessDialog();
      } else {
        dialog.close();
      }
    });
  }

  sendNewOffer() {
    let dialogRef = this.matDialog.open(
      PartnershipSendOfferDialogboxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "540px",
        height: "228x",
        data: {
          id: this.data.partnershipRequestId,
          name: this.partnerData.partnerName,
          sendOfferAgain: true,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getList();
      }
    });
  }

  //Process partnership when source is disabling offer
  processPartnershipRequest(result) {
    this.apiCall
      .processPartnershipRequest(
        this.data.partnershipRequestId,
        "OFFER_DISABLED",
        null,
        null,
        null,
        null,
        result,
        false
      )
      .subscribe((response) => {
        this.action = "OFFER_DISABLED";
        this.displaySnackBar("Offer disabled");
        this.loading = true;
        this.getList();
      });
  }

  displaySnackBar(text) {
    this.snackBar.openSnackBar(text, 2000);
  }

  disableOffer(couponCode) {
    let dialogRef = this.matDialog.open(DisablePartnershipOfferComponent, {
      panelClass: "o-item-dialog",
      width: "540px",
      data: {
        name: this.partnerData.partnerName,
        couponCode: couponCode,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null) {
        this.processPartnershipRequest(result);
      }
    });
  }
  scheduleOffer(){
    if(this.len === 0){
      this.redirectToCeateCoMarketing();
    }
    else if(this.comId === "null" || this.comId === null){
      this.redirectToCeateCoMarketing();
    }
    else{
      const dialogRef2 = this.matDialog.open(SchedulePartnershipOfferSucessDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "662px",
        height:"400px",
        data: {
          subject: this._i18nDynamicTranslate.transform("Schedule Offer", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Schedule", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          offerId: this.comId
        },
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if(result===true){
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Offer Scheduled", ['POS_PAGE']), 4000);
        }
        this.router.navigate(["/app/partnership/my-partners"]);
      });
    }
  }
  createCampaign(){
    if(this.len === 0){
      this.redirectToCeateCoMarketing();
    }
    // else if(this.comId === null){
    //   this.redirectToCeateCoMarketing();
    // }
    else{
      this.router.navigate(
        [
          "/app/campaign/creation",
          {
            "return-url": "campaign/overview",
            id: null,
            recommendation: false,
          },
        ],
        { relativeTo: this.activatedRoute }
      );
    }
     
  }
  getCoMargetingOfferCount(){
    // this.apiCall
    // .getAllPartnershipFormOffers(null, false)
    // .subscribe((response) => {
    //   let offers = response["body"]["formOfferDetailsList"];
    //   this.len = offers.length;
    //   // if(len > 0)
    //   //   return true;
    //   // else
    //   //   return false;
    // });
    this.apiCall.getCoMarketingOffers().subscribe((response)=>{
      console.log("RESPOMNSE",response)
      let offers = response['body']['comarketingOffers'];
      this.len = offers.length;
    })
  }
  redirectToCeateCoMarketing(){
    this.router.navigate(["/app/partnership/my-offers/new-group-offer"]);
  }
}
