import { DatePipe } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { PreviewTemplateComponent, EmailEditorDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-prospect-list",
  templateUrl: "./prospect-list.component.html",
  styleUrls: ["./prospect-list.component.css"],
})
export class ProspectListComponent implements OnInit {
  prospectList = [];
  pageIndex = 1;
  pageSize = 10;
  actionDiv = false;
  templateDiv;
  templates = [];
  templatesArray = [];
  subjectError = false;
  subject = "";
  templateName = "";
  loading = true;
  searchValue = "";
  template;
  filteredData;
  templateID;
  customersError = false;
  finalScreenHeight: any = 0;
  finalScreenWidth: any = 0;
  scrHeight: any = 0;
  scrWidth: any = 0;
  saveTemplate = false;
  selectTemplateError = false;
  selectEmailLayout = null;
  selectedTile = false;
  length = 0;
  showSelectCustomersDiv = false;
  selectedForm;
  emailList = [];
  emailsPageIndex = 1;
  emailsPageSize = 10;
  templateSelected = false;
  confirmTemplate = false;
  id = [];
  count = 0;
  prospectListEmails = {};
  totalCustomersSelected = 0;
  selectedCustomerEmail = null;
  searchedEmails = {};
  searching = "";
  selectedCustomers = [];
  searchedValue = "";
  globalClick() {
    if (this.showSelectCustomersDiv) {
      this.showSelectCustomersDiv = false;
    } 
  }
  constructor(
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private snackbar: SnackbarCollection,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private popup: PopUpDialogService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {}

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.emailsPageIndex++;
      this.apiCall
        .getProspectList(
          this.tokenStorage.getMerchantId(),
          this.pageSize,
          this.emailsPageIndex
        )
        .subscribe((response) => {
          Object.keys(response["body"]["customers"]).map((key) => {
            this.prospectListEmails[this.count] =
              response["body"]["customers"][key];
            this.count++;
          });
        });
    }
  }

  ngOnInit() {
    this.getProspectList();
  }

  showPreview() {
    this.previewEmail();
  }

  getProspectList() {
    this.emailList = [];
    this.loading = true;
    this.apiCall
      .getProspectList(
        this.tokenStorage.getMerchantId(),
        this.pageSize,
        this.pageIndex
      )
      .subscribe((response) => {
        this.prospectList = response["body"]["customers"];
        this.length = response["body"]["totalCustomerCount"];
        Object.keys(this.prospectList).map((element) => {
          this.emailList.push(this.prospectList[element]["customerEmail"]);
          this.prospectList[element]["time"] = this.timeConvert(
            this.prospectList[element]["date"].split(" ")[1].split(".")[0]
          );
          this.prospectList[element]["date"] = this.datePipe.transform(
            this.prospectList[element]["date"],
            "dd MMM yyyy"
          );
        });
        Object.keys(this.prospectList).map((key) => {
          if (this.id.indexOf(this.prospectList[key]["customerId"]) == -1) {
            this.id.push(this.prospectList[key]["customerId"]);
            this.prospectListEmails[this.count] = this.prospectList[key];
            this.count++;
          }
        });
        this.loading = false;
      });
    this.loading = false;
  }

  previewEmail() {
    var id: any = this.templateID;
    if (!id) {
    } else {
      this.apiCall.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            let layout = JSON.parse(response["body"]);
            let selectedLayout = layout["layoutHtml"];
            var emailData: any = {};
            emailData.selectedLayout = selectedLayout;
            emailData.templateName = this.templateName;
            emailData.templateId = this.templateID;
            this.openDialog(emailData, "EMAIL");
          }
        },
        (err) => {}
      );
    }
  }

  openDialog(objectData: any, type: string): void {
    var self = this;
    self.finalScreenHeight = document.getElementById("list-main").offsetHeight;
    self.finalScreenWidth = document.getElementById("list-main").offsetWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    const dialogRef = self.matDialog.open(PreviewTemplateComponent, {
      panelClass: "previewDialog",
      width: "70%",
      maxHeight: "90vh",
      height: sh + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      disableClose: true,
      autoFocus: false,
      data: { obj: objectData, type: type, height: self.finalScreenHeight },
    });

    dialogRef.afterClosed().subscribe((id) => {
      self.apiCall.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            let layout = JSON.parse(response["body"]);
            self.templateID = id;
            self.templateName = layout.name;
          }
        },
        (err) => {}
      );
    });
  }

  searchCustomers(searchValue) {
    if (searchValue.length > 0) {
      this.searchedEmails = {};
      this.searching = searchValue;
      if (searchValue.length > 0) {
        Object.keys(this.prospectListEmails).map((key) => {
          if (
            this.prospectListEmails[key].customerEmail.includes(
              searchValue.toLowerCase()
            )
          ) {
            this.searchedEmails[key] = this.prospectListEmails[key];
          }
        });
      }
    }
  }

  timeConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
      if (time[0] < 10) {
        time[0] = "0" + time[0];
      }
    }
    return time[0] + time[1] + time[2] + time[5];
  }

  displayNotificationPopover(event) {
    this.showSelectCustomersDiv = true;
  }

  closeNotificationPopover() {
    document.getElementById("notificationPopOver").style.display = "none";
    this.showSelectCustomersDiv = false;
  }

  selectCustomers() {
    this.customersError = false;
    if (this.selectedCustomers.length > 0) {
      let element = document.getElementById("customers-input");
      element.setAttribute("style", "border: 1px solid #636466;");
    }
    this.showSelectCustomersDiv = true;
  }

  addCustomer(customerID) {
    if (this.selectedCustomers.indexOf(customerID) != -1) {
      this.selectedCustomers.splice(
        this.selectedCustomers.indexOf(customerID),
        1
      );
      if (this.selectedCustomers.indexOf("All") != -1) {
        this.selectedCustomers.splice(this.selectedCustomers.indexOf("All"), 1);
      }
    } else {
      this.selectedCustomers.push(customerID);
    }
    if (customerID === "All") {
      if (this.selectedCustomers.indexOf("All") != -1) {
        Object.keys(this.prospectList).map((key) => {
          if (
            this.selectedCustomers.indexOf(this.prospectList[key].customerId) ==
            -1
          ) {
            this.selectedCustomers.push(this.prospectList[key].customerId);
          }
        });
      } else {
        Object.keys(this.prospectList).map((key) => {
          this.selectedCustomers.splice(
            this.selectedCustomers.indexOf(this.prospectList[key].customerId),
            1
          );
        });
      }
    }
    if (this.selectedCustomers.indexOf("All") == -1) {
      this.totalCustomersSelected = this.selectedCustomers.length;
    } else {
      this.totalCustomersSelected = this.selectedCustomers.length - 1;
    }
    if (this.selectedCustomers.length == 1) {
      this.prospectList.forEach((element) => {
        if (this.selectedCustomers[0] == element.customerId) {
          this.selectedCustomerEmail = element.customerEmail;
        }
      });
    } else {
      this.selectedCustomerEmail = null;
    }
  }

  save() {
    if (this.selectedCustomers.length > 0) {
      let element = document.getElementById("customers-input");
      element.setAttribute("style", "border: 1px solid #636466");
    }
    this.showSelectCustomersDiv = false;
  }

  cancelSelectCustomers() {
    this.showSelectCustomersDiv = false;
    this.selectedCustomers = [];
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getProspectList();
  }

  openActionDiv(formName) {
    this.selectedForm = formName;
    this.actionDiv = true;
  }

  attachTemplate() {
    this.templateDiv = true;
    this.displayTemplates();
    this.confirmTemplate = false;
    this.templateID = null;
    this.templateName = "";
    this.template = null;
    this.templateSelected = false;
    this.saveTemplate = false;
  }

  searchTemplates(searchValue) {
    this.templates = [];
    if (searchValue.length > 0)
      this.templatesArray.forEach((template) => {
        if (
          template["layoutName"]
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
          this.templates.push(template);
      });
    else this.templates = this.templatesArray;
  }

  selectTemplate(templateID, templateName, event) {
    this.templateSelected = !this.templateSelected;
    if (this.templateID != templateID) {
      this.templateID = templateID;
      this.template = templateName;
      this.templateSelected = true;
    } else {
      this.templateID = -1;
      this.templateName = "";
    }
  }

  cancelTemplateDiv() {
    this.selectEmailLayout = null;
    this.selectedTile = false;
    this.templateName = "";
    this.template = "";
    this.searchValue = "";
    this.selectTemplateError = false;
    this.templateDiv = false;
    this.templateID = -1;
    this.confirmTemplate = false;
  }

  confirm() {
    if (this.templateID != -1) {
      this.templateDiv = false;
      this.saveTemplate = true;
      this.templateName = this.template;
      this.template = "";
      this.selectTemplateError = false;
      this.confirmTemplate = true;
    } else {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please select a template", ['POS_PAGE']), 2000);
    }
  }

  cancelActionDiv() {
    this.templateName = "";
    this.template = "";
    this.searchValue = "";
    this.selectTemplateError = false;
    this.actionDiv = false;
    this.templateDiv = false;
    this.subject = "";
    this.templateID = -1;
    this.subjectError = false;
    this.customersError = false;
    this.confirmTemplate = false;
  }

  sendEmailFromAnalytics(requestData) {
    this.apiCall
      .sendEmailFromFormAnalytics(
        this.tokenStorage.getMerchantId(),
        requestData
      )
      .subscribe(
        (response) => {
          this.matSnackbar.dismiss();
          this.popup.openPopup(this._i18nDynamicTranslate.transform("success", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Email sent successfully", ['POS_PAGE']));
          this.cancelActionDiv();
        },
        (err) => {
          this.matSnackbar.dismiss();
          this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE']));
        }
      );
  }

  addEmailContent(data) {
    let layoutId;
    this.apiCall.addEmailContent(data).subscribe(
      (response) => {
        layoutId = response["body"];
        let requestData = {
          formId: null,
          actionType: "PROSPECT_LIST_EMAIL",
          layoutId: layoutId,
          customerIds: this.selectedCustomers,
        };
        this.sendEmailFromAnalytics(requestData);
      },
      (err) => {
        this.matSnackbar.dismiss();
        this.popup.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to send email", ['POS_PAGE']));
      }
    );
  }

  getEmailLayoutDetail() {
    this.apiCall.getEmailLayoutDetail(this.templateID).subscribe((response) => {
      let emailContent = JSON.parse(response["body"]);
      let data = {
        merchantId: this.tokenStorage.getMerchantId(),
        subject: this.subject,
        layoutHtml: emailContent["layoutHtml"],
        layoutJson: emailContent["layoutJson"],
        id: this.templateID,
        type: "FORMS",
      };
      this.addEmailContent(data);
    });
  }

  send() {
    if (this.selectedCustomers.indexOf("All") != -1) {
      this.selectedCustomers.splice(this.selectedCustomers.indexOf("All"), 1);
    }
    if (
      this.templateID !== null &&
      this.subject.length > 0 &&
      this.selectedCustomers.length > 0 &&
      this.saveTemplate
    )
      this.getEmailLayoutDetail();
    else {
      if (this.subject.length == 0) {
        this.subjectError = true;
      } else if (
        this.templateID === undefined ||
        this.templateID == -1 ||
        !this.saveTemplate
      ) {
        this.selectTemplateError = true;
      } else if (this.selectedCustomers.length == 0) {
        this.customersError = true;
      }
    }
  }

  createEmailTemplate(id: any) {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;
    self.finalScreenWidth = self.scrWidth;
    let sw = self.finalScreenWidth * (70 / 100);
    const dialogRef = self.matDialog.open(EmailEditorDialogComponent, {
      panelClass: "customAttachEmail",
      height: 500 + "px",
      width: self.finalScreenWidth < 1500 ? "1300px" : sw + "px",
      minWidth: "1024px",
      maxWidth: "1300px",
      minHeight: "620px",
      disableClose: true,
      data: {
        type: "CUSTOM",
        id: "null",
        clone: "false",
        return: "false",
      },
    });
    dialogRef.afterClosed().subscribe((id) => {
      if (id) {
        self.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Successfull", ['POS_PAGE']), 2000);
        self.templateID = id;
        self.apiCall.listEmailLayout().subscribe(
          (response) => {
            self.templates = response["body"];
            self.templatesArray = response["body"];
            var data: any = self.templates.find((x) => x.id == id);
            self.templates = self.templates.filter((x) => x.id !== id);
            self.templates.unshift(data);
            self.filteredData = self.templates;
            self.template = data.layoutName;
            this.searchValue = "";
          },
          (err) => {}
        );
      }
    });
  }

  displayTemplates() {
    this.templates = [];
    this.templatesArray = [];
    this.apiCall.listEmailLayout().subscribe((response) => {
      this.templates = response["body"];
      this.templatesArray = response["body"];
    });
  }
  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }
}
