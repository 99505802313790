import { getCurrencySymbol } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { countries } from "src/app/shared/json-asset/countries";
import {
  SearchCustomerDialogBoxComponent,
  TableViewDialogBoxComponent,
} from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-customer-insights-analytics",
  templateUrl: "./customer-insights-analytics.component.html",
  styleUrls: ["./customer-insights-analytics.component.css"],
})
export class CustomerInsightsAnalyticsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private date_format: getdateformatService
  ) {}

  loading = false;
  graphLoading = false;
  detailsContainerValue = [
    {
      type: "totalOrders",
      name: "Total Orders",
      value: "0",
    },
    {
      type: "lifeTimeValue",
      name: "Life time",
      value: "0",
    },
    {
      type: "totalOrderValue",
      name: "Total order value",
      value: "0",
    },
    {
      type: "averageAmount",
      name: "Average amount",
      value: "0",
    },
    {
      type: "uniqueProducts",
      name: "Unique products",
      value: "0",
    },
  ];

  customerInfoLeft = [
    {
      name: {
        displayName: "Name",
        value: "",
      },
      gender: {
        displayName: "Gender",
        value: "----",
      },
      age: {
        displayName: "Age",
        value: "----",
      },
      country: {
        displayName: "Country",
        value: "----",
      },
      dob: {
        displayName: "Date Of Birth",
        value: "----",
      },
    },
  ];

  customerInfoRight = [
    {
      email: {
        displayName: "Email",
        value: "----",
      },
      phone: {
        displayName: "Phone",
        value: "----",
      },
      city: {
        displayName: "City",
        value: "----",
      },
      anniversary: {
        displayName: "Anniversary",
        value: "----",
      },
    },
  ];

  customerFavs = [
    {
      displayName: "Favourite store",
      value: "----",
      type: "favStore",
    },
    {
      displayName: "Channels",
      value: "----",
      type: "channels",
    },
    {
      displayName: "Favourite time",
      value: "----",
      type: "favTime",
    },
    {
      displayName: "Segments",
      value: [],
      type: "segments",
    },
  ];

  customerTargedtedData = [
    {
      displayName: "Total Journeys",
      value: 0,
      type: "journeys",
    },
    {
      displayName: "Surveys completed",
      value: 0,
      type: "surveys",
    },
    {
      displayName: "Active Offers",
      value: 0,
      type: "offers",
    },
  ];

  revCurrency = sessionStorage.getItem("serviceCurrency");
  country = countries.find(
    (country) => country.alpha2Code == localStorage.getItem("serviceCountry")
  ).name;

  objectKeys = Object.keys;
  recentOrders = [];
  topProducts = [];
  topCategories = [];
  surveyList = [];
  campaignList = [];
  offerList = [];
  totalCustomerRevenueFrameList = ["Quarter", "Month", "Week"];
  timeFrame = "Month";
  totalSalesLinerChart: echarts.EChartsCoreOption;
  currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");

  @Input() customerId;

  ngOnInit(): void {
    this.resetAllDataToInitial();
    this.getCustomerDetails();
    this.getAllDetails();
    this.getChannelDetails();
    this.getCampaignList();
    this.getOffersList();
    this.getSurveyList();
    this.getAnalytics();
  }

  resetAllData() {
    this.resetAllDataToInitial();
    this.getCustomerDetails();
    this.getAllDetails();
    this.getChannelDetails();
    this.getCampaignList();
    this.getOffersList();
    this.getSurveyList();
    this.getAnalytics();
  }

  dialogBoxOpen(data) {
    this.dialog.open(TableViewDialogBoxComponent, {
      panelClass: "customer-dialog-popup",
      width: "540px",
      height: "420px",
      disableClose: false,
      data: {
        data: data,
        tableData: this.getTableData(data.type),
      },
    });
  }

  getTableData(type) {
    switch (type) {
      case "journeys":
        return this.campaignList;
      case "offers":
        return this.offerList;
      case "surveys":
        return this.surveyList;
      default:
        return [];
    }
  }

  getSurveyList() {
    this.getSurveyListApi(this.customerId).then(
      (res) => {
        this.loading = false;
        if (res) {
          console.log(res);
          this.surveyList = res["body"]["surveyData"];
          this.customerTargedtedData[1].value = res["body"]["surveysCompleted"];
        } else {
          return;
        }
      },
      (err) => {
        console.log(JSON.parse(err));
      }
    );
  }

  totalCustomerRevenueTimeFrameDropDownHandler() {
    console.log(this.timeFrame);
    this.getAnalytics();
  }

  getAnalytics() {
    this.graphLoading = true;
    this.getAnalyticsApi(this.customerId).then(
      (res) => {
        let xg1 = [],
          yg1 = [],
          yg2 = [];
        this.graphLoading = false;
        if (res["body"]) {
          res["body"].sort((a, b) =>
            a.group_value.localeCompare(b.group_value)
          );
          res["body"].forEach((data) => {
            //data['name'] = data.dateRange;
            xg1.push(data.group_value);
            yg2.push(data.amount_sum);
            yg1.push(data.order_count);
            // data['value'] = data.count;
          });
          let type = this.timeFrame;
          this.totalSalesLinerChart = {
            xAxis: {
              type: "category",
              name: type,
              nameLocation: "center",
              nameGap: 30,
              data: xg1,
              axisLabel: {
                formatter: function (value, index) {
                  if (value) {
                    if (type.toLowerCase() === "month") {
                      let [year, month] = value.split("-");

                      // Convert numeric month to three-letter abbreviation
                      let monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ];
                      let monthAbbreviation =
                        monthNames[parseInt(month, 10) - 1];

                      // Format the label as 'AbbreviatedMonth-Year'
                      return `${monthAbbreviation}-${year}`;
                    } else if (type.toLowerCase() === "week") {
                      let [year, week] = value.split("-");
                      return `Week-${week} ${year}`;
                    } else {
                      let [year, quarter] = value.split("Q");
                      return `${year} Q${quarter}`;
                    }
                  } else {
                    return null;
                  }
                },
              },
            },
            yAxis: {
              type: "value",
              // name: `Revenue(${this.currencySymbol})`,
              // nameLocation:'center',
              axisLabel: {
                formatter: function (value) {
                  if (value >= 1e9) {
                    return value / 1e9 + "B";
                  }
                  if (value >= 1e6) {
                    return value / 1e6 + "M";
                  }
                  if (value >= 1e3) {
                    return value / 1e3 + "k";
                  }
                  return value;
                },
              },
              // nameGap: 30,
            },
            legend: {
              data: ["Order Amount", "Order Count"],
            },
            tooltip: {},
            series: [
              {
                data: yg2,
                name: "Order Amount",
                type: "line",
                itemStyle: {
                  color: "#EBBB2F",
                },
              },
              {
                data: yg1,
                name: "Order Count",
                type: "line",
                itemStyle: {
                  color: "#482FEB",
                },
              },
            ],
          };
        } else {
          this.totalSalesLinerChart = {
            graphic: {
              elements: [
                {
                  type: "text",
                  left: "center",
                  top: "center",
                  style: {
                    text: "No Data Available",
                    font: "18px Arial, sans-serif",
                    fill: "#999",
                  },
                },
              ],
            },
          };
        }
      },
      (err) => {
        console.log(JSON.parse(err));
        this.graphLoading = false;
        this.totalSalesLinerChart = {
          graphic: {
            elements: [
              {
                type: "text",
                left: "center",
                top: "center",
                style: {
                  text: "No Data Available",
                  font: "18px Arial, sans-serif",
                  fill: "#999",
                },
              },
            ],
          },
        };
      }
    );
  }

  getCampaignList() {
    this.loading = true;
    this.getCampaignListApi(this.customerId).then(
      (res) => {
        this.loading = false;
        if (res) {
          console.log(res);
          this.campaignList = res["body"];
          this.customerTargedtedData[0].value = res["body"].length;
        } else {
          return;
        }
      },
      (err) => {
        console.log(JSON.parse(err));
      }
    );
  }

  getOffersList() {
    this.getOfferListApi(this.customerId).then(
      (res) => {
        this.loading = false;
        if (res) {
          console.log(res);
          this.offerList = res["body"];
          this.customerTargedtedData[2].value = res["body"].length;
        } else {
        }
      },
      (err) => {
        console.log(JSON.parse(err));
      }
    );
  }

  getCustomerDetails() {
    this.loading = true;
    this.getCustomerDetailsApi(this.customerId).then(
      (res) => {
        this.loading = false;
        if (res) {
          this.customerInfoLeft[0]["name"].value = res["body"]["customerName"];
          this.customerInfoLeft[0]["dob"].value = res["body"]["birthday"]
            ? res["body"]["birthday"]
            : "----";
          this.customerInfoLeft[0]["age"].value = res["body"]["birthday"]
            ? this.getAgeFromDOB(res["body"]["birthday"])
            : "----";
          this.customerInfoLeft[0]["gender"].value = res["body"]["gender"]
            ? res["body"]["gender"]
            : "----";

          this.customerInfoRight[0]["anniversary"].value = res["body"][
            "anniversary"
          ]
            ? res["body"]["anniversary"]
            : "----";
          this.customerInfoRight[0]["city"].value = res["body"]["city"]
            ? res["body"]["city"]
            : "----";
          this.customerInfoRight[0]["email"].value = res["body"]["email"]
            ? res["body"]["email"]
            : "----";
          this.customerInfoRight[0]["phone"].value = res["body"]["phone"]
            ? res["body"]["phone"]
            : "----";
          this.detailsContainerValue[0].value = res["body"]["orderQuantity"];
          this.detailsContainerValue[2].value = `${
            this.currencySymbol
          } ${parseFloat(res["body"]["revenue"]).toFixed(2)}`;
          this.detailsContainerValue[3].value = `${
            this.currencySymbol
          } ${parseFloat(res["body"]["averageOrderValue"]).toFixed(2)}`;
          this.customerFavs[3].value = res["body"]["customerSegments"]
            ? res["body"]["customerSegments"]
            : [];
          if (
            res["body"]["customerSegments"] &&
            res["body"]["customerSegments"].length > 0
          ) {
            this.customerFavs[3].value = res["body"]["customerSegments"].map(
              (segment) => segment.segmentName
            );
          } else {
            this.customerFavs[3].value = [];
          }
        } else {
        }
      },
      (err) => {
        console.log(JSON.parse(err));
      }
    );
  }

  getAllDetails() {
    this.loading = true;
    this.getAllDetailsApi(this.customerId).then(
      (res) => {
        this.loading = false;
        if (res && res["body"]) {
          this.topProducts = JSON.parse(res["body"][0]["top_products"]);
          this.topCategories = JSON.parse(res["body"][0]["top_categories"]);
          this.recentOrders = JSON.parse(res["body"][0]["past_five_orders"]);
          this.detailsContainerValue[1].value = res["body"][0].cltv;
          this.detailsContainerValue[4].value =
            res["body"][0].unique_product_name_count;
          this.customerFavs[0].value = (res["body"][0].fav_store !== 'null' && res["body"][0].fav_store !== null) ? res["body"][0].fav_store : "----";
          this.customerFavs[2].value = this.convertToAmPm(
            res["body"][0].fav_hour_bracket
          );
        } else {
        }
      },
      (err) => {
        console.log(JSON.parse(err));
      }
    );
  }

  resetAllDataToInitial() {
    this.recentOrders = [];
    this.topProducts = [];
    this.topCategories = [];
    this.surveyList = []; 
    this.campaignList = [];
    this.offerList = [];
    this.detailsContainerValue = [
      {
        type: "totalOrders",
        name: "Total Orders",
        value: "0",
      },
      {
        type: "lifeTimeValue",
        name: "Life time value",
        value: "0",
      },
      {
        type: "totalOrderValue",
        name: "Total order value",
        value: "0",
      },
      {
        type: "averageAmount",
        name: "Average amount",
        value: "0",
      },
      {
        type: "uniqueProducts",
        name: "Unique products",
        value: "0",
      },
    ];

    this.customerInfoLeft = [
      {
        name: {
          displayName: "Name",
          value: "",
        },
        gender: {
          displayName: "Gender",
          value: "----",
        },
        age: {
          displayName: "Age",
          value: "----",
        },
        country: {
          displayName: "Country",
          value: this.country ? this.country : "----",
        },
        dob: {
          displayName: "Date Of Birth",
          value: "----",
        },
      },
    ];

    this.customerInfoRight = [
      {
        email: {
          displayName: "Email",
          value: "----",
        },
        phone: {
          displayName: "Phone",
          value: "----",
        },
        city: {
          displayName: "City",
          value: "----",
        },
        anniversary: {
          displayName: "Anniversary",
          value: "----",
        },
      },
    ];

    this.customerFavs = [
      {
        displayName: "Favourite store",
        value: "----",
        type: "favStore",
      },
      {
        displayName: "Channels",
        value: "----",
        type: "channels",
      },
      {
        displayName: "Favourite time",
        value: "----",
        type: "favTime",
      },
      {
        displayName: "Segments",
        value: [],
        type: "segments",
      },
    ];

    this.customerTargedtedData = [
      {
        displayName: "Total Journeys",
        value: 0,
        type: "journeys",
      },
      {
        displayName: "Surveys completed",
        value: 0,
        type: "surveys",
      },
      {
        displayName: "Active Offers",
        value: 0,
        type: "offers",
      },
    ];
  }

  getChannelDetails() {
    this.getChannelsApi(this.customerId).then(
      (res) => {
        this.loading = false;
        if (res["body"]) {
          this.customerFavs[1].value =
            res["body"].length > 0 ? res["body"].join(", ") : "----";
        } else {
          this.customerFavs[1].value = "----";
        }
      },
      (err) => {
        console.log(JSON.parse(err));
      }
    );
  }

  changeCustomer() {
    const dialogRef = this.dialog.open(SearchCustomerDialogBoxComponent, {
      panelClass: "customer-dialog-popup",
      width: "850px",
      height: "420px",
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.customerId = result;
        this.resetAllData();
      }
    });
  }

  getCustomerDetailsApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerDetails(id).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err.error.body);
        }
      );
    });
  }

  getAnalyticsApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall
        .getOrderRevenueChartValue(id, this.timeFrame.toLowerCase())
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err.error.body);
          }
        );
    });
  }

  getSurveyListApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getSurveysOfParticularCustomer(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  getOfferListApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getOffersOfParticularCustomer(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  getCampaignListApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getCampaignsOfParticularCustomer(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  getChannelsApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getSubscribedChannel(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  getAllDetailsApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerAllDetails(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
      // let res = {
      //   message: "success",
      //   body: [
      //     {
      //       customer_id: "03b108aebac80e6591d6990ff5316497",
      //       total_order_amount: 4863.28,
      //       average_order_amount: 99.25061,
      //       total_orders: 16.0,
      //       unqiue_product_category_count: 4.0,
      //       unqiue_product_categories: [
      //         "dude onion 8 inch",
      //         "capsicum pizza 8 inch",
      //         "get four 8 pizzas @ rs 300",
      //         "margherita classic 8 inch",
      //       ],
      //       unique_product_name_count: 4.0,
      //       unique_product_names: [
      //         "get four 8” pizzas @ rs 300",
      //         "dude onion 8 inch",
      //         "capsicum pizza 8 inch",
      //         "margherita classic 8 inch",
      //       ],
      //       top_products: [
      //         {
      //           foodid: "24976",
      //           name: "capsicum pizza 8 inch",
      //           count: "56",
      //           rank: "1",
      //         },
      //         {
      //           foodid: "23143",
      //           name: "margherita classic 8 inch",
      //           count: "46",
      //           rank: "2",
      //         },
      //         {
      //           foodid: "23104",
      //           name: "dude onion 8 inch",
      //           count: "36",
      //           rank: "3",
      //         },
      //         {
      //           foodid: "25266",
      //           name: "get four 8” pizzas @ rs 300",
      //           count: "4",
      //           rank: "4",
      //         },
      //       ],
      //       top_categories: [
      //         {
      //           count: "56",
      //           rank: "1",
      //           category: "capsicum pizza 8 inch",
      //         },
      //         {
      //           count: "46",
      //           rank: "2",
      //           category: "margherita classic 8 inch",
      //         },
      //         {
      //           count: "36",
      //           rank: "3",
      //           category: "dude onion 8 inch",
      //         },
      //         {
      //           count: "4",
      //           rank: "4",
      //           category: "get four 8 pizzas @ rs 300",
      //         },
      //       ],
      //       past_five_orders: [
      //         {
      //           quantity: "5",
      //           orderid: "11687899",
      //           subtotal: "100.16",
      //           foodid: "23104",
      //           name: "dude onion 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "dude onion 8 inch",
      //           orderdate: "2024-05-23 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "7",
      //           orderid: "11687899",
      //           subtotal: "100.51",
      //           foodid: "23143",
      //           name: "margherita classic 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "margherita classic 8 inch",
      //           orderdate: "2024-05-23 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "9",
      //           orderid: "11687899",
      //           subtotal: "100.16",
      //           foodid: "24976",
      //           name: "capsicum pizza 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "capsicum pizza 8 inch",
      //           orderdate: "2024-05-23 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "7",
      //           orderid: "95687689",
      //           subtotal: "100.51",
      //           foodid: "23143",
      //           name: "margherita classic 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "margherita classic 8 inch",
      //           orderdate: "2024-05-23 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "9",
      //           orderid: "95687689",
      //           subtotal: "100.16",
      //           foodid: "24976",
      //           name: "capsicum pizza 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "capsicum pizza 8 inch",
      //           orderdate: "2024-05-23 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "5",
      //           orderid: "95687689",
      //           subtotal: "100.16",
      //           foodid: "23104",
      //           name: "dude onion 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "dude onion 8 inch",
      //           orderdate: "2024-05-23 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "7",
      //           orderid: "2098",
      //           subtotal: "100.51",
      //           foodid: "23143",
      //           name: "margherita classic 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "margherita classic 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "9",
      //           orderid: "1098",
      //           subtotal: "100.16",
      //           foodid: "24976",
      //           name: "capsicum pizza 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "capsicum pizza 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "5",
      //           orderid: "2098",
      //           subtotal: "100.16",
      //           foodid: "23104",
      //           name: "dude onion 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "dude onion 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "4",
      //           orderid: "2098",
      //           subtotal: "50.0",
      //           foodid: "25266",
      //           name: "get four 8” pizzas @ rs 300",
      //           storename: "FlipOffice_Ravina",
      //           category: "get four 8 pizzas @ rs 300",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "7",
      //           orderid: "1098",
      //           subtotal: "100.51",
      //           foodid: "23143",
      //           name: "margherita classic 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "margherita classic 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "5",
      //           orderid: "1098",
      //           subtotal: "100.16",
      //           foodid: "23104",
      //           name: "dude onion 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "dude onion 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "7",
      //           orderid: "11600899",
      //           subtotal: "100.51",
      //           foodid: "23143",
      //           name: "margherita classic 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "margherita classic 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "9",
      //           orderid: "11600899",
      //           subtotal: "100.16",
      //           foodid: "24976",
      //           name: "capsicum pizza 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "capsicum pizza 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "5",
      //           orderid: "11600899",
      //           subtotal: "100.16",
      //           foodid: "23104",
      //           name: "dude onion 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "dude onion 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //         {
      //           quantity: "9",
      //           orderid: "2098",
      //           subtotal: "100.16",
      //           foodid: "24976",
      //           name: "capsicum pizza 8 inch",
      //           storename: "FlipOffice_Ravina",
      //           category: "capsicum pizza 8 inch",
      //           orderdate: "2024-05-24 01:55:00",
      //           storeid: "2cd12584-6ee5-4bb3-9784-9e66531c567b",
      //         },
      //       ],
      //       fav_hour_bracket: "6-8",
      //       fav_store: "FlipOffice_Ravina",
      //       segment: [],
      //       cltv: 114.0,
      //       merchant: 1115.0,
      //     },
      //   ],
      // };
      // resolve(res);
    });
  }

  getDateFormat(type?) {
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  convertToAmPm = (timeRange: string): string => {
    const [start, end] = timeRange.split("-").map(Number);

    const formatTime = (hour: number): string => {
      const period = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      return `${formattedHour} ${period}`;
    };

    const startAmPm = formatTime(start);
    const endAmPm = formatTime(end);

    return `${startAmPm} - ${endAmPm}`;
  };

  findIndexByDisplayName = (array: any[], displayName: string): number => {
    return array.findIndex((item) => item.displayName === displayName);
  };

  getAgeFromDOB(dob: string): string {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Check if the birth month and day have not occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age.toString();
  }

  getCustomerInfoTooltip(key, value) {
    let toolTipList = ["name", "email"];
    if (toolTipList.indexOf(key) !== -1) {
      return value;
    }
  }
}
