export const chefDrivenTemplate3 = {
  page: {
    body: {
      container: {
        style: {
          "background-color": "transparent",
        },
      },
      content: {
        computedStyle: {
          linkColor: "#7c4b96",
          messageBackgroundColor: "transparent",
          messageWidth: "675px",
        },
        style: {
          color: "#000000",
          "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif",
        },
      },
      type: "mailup-bee-page-properties",
      webFonts: [
        {
          fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
          name: "Roboto",
          url: "https://fonts.googleapis.com/css?family=Roboto",
        },
      ],
    },
    description: "",
    rows: [
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "675px",
                  },
                  image: {
                    alt: "LogoPlaceHolder",
                    height: "148px",
                    href: "",
                    percWidth: 25,
                    prefix: "",
                    src: "https://bee-editor-images-qa.s3.amazonaws.com/user/user-id-249/Text%20%26%20Icons_Header-02.png",
                    target: "_self",
                    width: "148px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "8fba259f-2a29-4e7b-aee1-ca0a1699f915",
              },
              {
                descriptor: {
                  computedStyle: {
                    class: "center autowidth",
                    hideContentOnMobile: false,
                    width: "675px",
                  },
                  image: {
                    alt: "Alternate text",
                    height: "876px",
                    href: "",
                    src: "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/55d274b7-252b-45ed-bff0-a2b81c79627c/user-id-176/Excited%203.jpg",
                    width: "1920px",
                  },
                  style: {
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px",
                    width: "100%",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-image",
                uuid: "70852ad4-fe4f-463c-91b4-bf7947a2bc90",
              },
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "45px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 21px;"><p style="font-size: 12px; line-height: 21px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 21px; word-break: break-word;"><span style="font-size: 22px; line-height: 39px;" data-mce-style="font-size: 22px; line-height: 39px;"><strong>Hi </strong></span><strong><span style="font-size: 22px; line-height: 39px;" data-mce-style="font-size: 22px; line-height: 39px;"><span style="color: rgb(255, 255, 255); font-size: 22px; line-height: 39px;" data-mce-style="color: rgb(255, 255, 255); font-size: 22px; line-height: 39px;">{{customerName}}</span>  </span></strong></p><p style="font-size: 12px; line-height: 21px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 12px; line-height: 21px; word-break: break-word;"> </p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "180%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "47861843-d37a-410d-b5cd-236b0f6f1aea",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "42483055-7cf2-4c90-8ec5-638c75e0de98",
          },
        ],
        container: {
          style: {
            "background-color": "#fcda63",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#333",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "df055e8d-7962-46cc-9bdc-a426c18f9e99",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; word-break: break-word;"><strong>YOU HAVE AN EXCITING OFFER FROM <span style="color: rgb(255, 255, 255); font-size: 22px; line-height: 26px;" data-mce-style="color: rgb(255, 255, 255); font-size: 22px; line-height: 26px;">{{merchantName}}</span></strong></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "be955275-fb7b-40f1-8027-02679a2f783e",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "bc8895ea-8f65-4548-b0a6-927970c55a63",
          },
        ],
        container: {
          style: {
            "background-color": "#fcda63",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "c5e88741-787b-47a8-9356-84b28440153e",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;">\n\n</div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "91660f03-6f4f-4660-96d5-69b7a698ff36",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "becc08a4-472a-458b-b1e4-4c1091562587",
          },
        ],
        container: {
          style: {
            "background-color": "#fcda63",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "f9521ca2-ad96-40da-9935-64b2c566d4ae",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; line-height: 14px;"><p style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word; font-family: Roboto, Tahoma, Verdana, Segoe, sans-serif;" data-mce-style="font-size: 13px; line-height: 16px; text-align: left; word-break: break-word;"><strong>Grab the offer from <span style="font-size: 14px; line-height: 19px;" data-mce-style="font-size: 14px; line-height: 19px;">{{shopUrl}}</span></strong></p></div>',
                    style: {
                      color: "#555555",
                      "font-family":
                        "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "c6a7a3ab-a58f-4094-8fb3-2e05b89085ce",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "7dd861ac-3c50-40d8-b0dc-3fd8a2aa739a",
          },
        ],
        container: {
          style: {
            "background-color": "#fcda63",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "05c7a9c7-d263-4fd5-862a-13ebe2d04a68",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: {
                      linkColor: "#7c4b96",
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;" data-mce-style="font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 12px; line-height: 14px;"><p data-mce-style="font-size: 12px; line-height: 14px; word-break: break-word;" style="font-size: 12px; line-height: 14px; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;"><br data-mce-bogus="1"></p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "86ce43d5-ad5f-409d-9957-54b3052c79b7",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "d764dc52-8870-4af6-a353-a3a01c2a06d1",
          },
        ],
        container: {
          style: {
            "background-color": "#fcda63",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "583c1c9d-23b4-49fd-800e-2213e30541f7",
      },
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    height: 57,
                    hideContentOnMobile: false,
                    iconsDefaultWidth: 32,
                    padding: "0 2.5px 0 2.5px",
                    width: 151,
                  },
                  iconsList: {
                    icons: [],
                  },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                    "text-align": "center",
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-social",
                uuid: "3687c5a2-cd8f-4c25-ace5-1866278b0d1e",
              },
              {
                descriptor: {
                  computedStyle: { hideContentOnMobile: false },
                  style: {
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                    "padding-top": "10px",
                  },
                  text: {
                    computedStyle: { linkColor: "#7c4b96" },
                    html: '<div class="txtTinyMce-wrapper" style="line-height:14px;font-size:12px;font-family:inherit;" data-mce-style="line-height:14px;font-size:12px;font-family:inherit;"><p style="font-size:13px;line-height:15px;word-break:break-word;text-align:center;" data-mce-style="font-size:13px;line-height:15px;word-break:break-word;text-align:center;"><span style="font-size:12px;line-height:14px;" data-mce-style="font-size:12px;line-height:14px;">Copyrights © 2024&nbsp; I &nbsp;</span><em style="text-transform: uppercase;">{{merchantName}}.</em></p><p style="font-size:13px;line-height:15px;word-break:break-word;text-align:center;" data-mce-style="font-size:13px;line-height:15px;word-break:break-word;text-align:center;">&nbsp;</p><p style="text-align:center;line-height:14px;word-break:break-word;" data-mce-style="text-align:center;line-height:14px;word-break:break-word;">All rights reserved.</p><p style="text-align:center;line-height:14px;word-break:break-word;" data-mce-style="text-align:center;line-height:14px;word-break:break-word;">&nbsp;</p><p style="text-align:center;line-height:14px;word-break:break-word;" data-mce-style="text-align:center;line-height:14px;word-break:break-word;">{{Store Address}} | <span>{{shopUrl}}</span></p></div>',
                    style: {
                      color: "#555555",
                      "font-family": "inherit",
                      "line-height": "120%",
                    },
                  },
                },
                locked: false,
                type: "mailup-bee-newsletter-modules-text",
                uuid: "9169ee51-e882-47cf-90dc-8e755d603253",
              },
            ],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "817362d9-6f7a-4f78-8bee-9c5546967e9a",
          },
        ],
        container: {
          style: {
            "background-color": "#fcda63",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "675px",
          },
        },
        locked: false,
        type: "one-column-empty",
        uuid: "35c2c467-9b11-45e4-a9e5-b1d7d9635bce",
      },
    ],
    template: {
      name: "template-base",
      type: "basic",
      version: "2.0.0",
    },
    title: "",
  },
  comments: null,
};
