import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    constructor(private datePipe: DatePipe){}  
  transform(value): any {
    const dateObj = new Date(value);
    console.log(value, this.isDate(value))
    if(this.isDate(value)){
        return this.datePipe.transform(value,'MMM d, y, h:mm:ss a');
    }
    else{
        return value;
    }
  }
  isDate(value: any){
    if (typeof value !== 'string') {
        return false;
      }
    
      // Define an array of recognized date formats
      const formats = [
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/, // ISO8601 UTC
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/, // ISO8601
        /^([a-zA-Z]{3},\s\d{2}\s[a-zA-Z]{3}\s\d{4}\s\d{2}:\d{2}:\d{2}\sGMT)$/, // RFC1123
        /^([a-zA-Z]{3},\s\d{2}\s[a-zA-Z]{3}\s\d{4}\s\d{2}:\d{2}:\d{2}\s[a-zA-Z]{3})$/, // Other RFC formats
        /^(\d{4})-(\d{2})-(\d{2})$/, // YYYY-MM-DD
      ];
    
      // Check if the value matches any of the recognized date formats
      const match = formats.some(format => format.test(value));
    
      return match && !isNaN(new Date(value).getTime());
  }
}