import { getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as echarts from "echarts";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { DataPreviewAmalyticsDialogBoxComponent, InsightGraphDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-rfm-dynamics",
  templateUrl: "./rfm-dynamics.component.html",
  styleUrls: ["./rfm-dynamics.component.css"],
})
export class RfmDynamicsComponent implements OnInit {
  constructor(
    private _apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  dataLoading = false;
  isNoDataAvailable = false;
  dashboardData;
  rewardRedamationRateStat = [];
  datasefor2 = [];
  totalCustomerRevenueFrameList = ["Quarter", "Month"];
  totalCustomerRevenueTimeFrame = ["Month"];
  availableFilter: any = {};
  selectedFilter: any = {};
  revCurrency = sessionStorage.getItem("serviceCurrency");
  currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  selectedTab = "";
  isLoading = false;
  scatterPlot: echarts.EChartsCoreOption;
  recencyBucketChart: echarts.EChartsCoreOption;
  frequencyBucketChart: echarts.EChartsCoreOption;

  ngOnInit(): void {
    this.getAnalytics(false);
    this.getFilters();
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
        this.selectedTab = params.type;
        this.selectTab(params.type);
      } else {
        this.router.navigate(["/app/rfm-dynamics"], {
          queryParams: { type: "rfmAnalysis" },
        });
        this.selectedTab = "rfmAnalysis";
        this.selectTab("rfmAnalysis");
      }
    });
  }

  selectTab(tab) {
    this.selectedTab = tab;
  }

  getFilters() {
    this.getAllFiltersApi("rfm").then((result) => {
      this.dataLoading = false;
      if (result) {
        let data = result["body"];
        // this.createTotalCustomerSalesGraph(result['body']['totalSales'], result['body']['totalCustomers']);
        for (let d in data) {
          console.log(d, data[d]);
          if (d !== "Segment") {
            this.availableFilter[d] = data[d];
            this.selectedFilter[d] = [];
          } else {
            this.availableFilter[d] = this.getSegmentData(data[d]);
            this.selectedFilter[d] = [];
          }
        }
        console.log(this.availableFilter, this.selectedFilter);
      }
    },(err)=>{
      this.isNoDataAvailable = true;
      this.dataLoading = false;
    });
  }

  getSegmentData(data) {
    let customerSegment = [];
    this._apiCall.getAllCustomerSegments().subscribe(
      (response) => {
        if (response["message"] == "success") {
          let segment = response["body"];
          for (let i = 0; i < segment.length; i++) {
            if (data.indexOf(segment[i]["id"]) !== -1) {
              customerSegment.push({
                id: segment[i]["id"],
                name: segment[i]["segmentName"],
                // isActive: segment[i]["isActive"],
                // status: false,
                // category: segment[i]["category"],
              });
            }
          }
        } else {
          this.snackbar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Failed to load customer segments",
              ["POS_PAGE"]
            ),
            1000
          );
          return [];
        }
      },
      (err) => {
        this.snackbar.openSnackBar(
          this._i18nDynamicTranslate.transform(err.error.body, ["POS_PAGE"]),
          1000
        );
        return [];
      }
    );
    return customerSegment;
  }

  applyFilter() {
    let data = {
      stringFilters: [],
      stringValuesToCheck: [],
      doubleFilters: [],
      doubleValuesToCheck: [],
      listFilters: [],
      listValuesToCheck: []
    };

    for (let value in this.selectedFilter) {
      if (this.selectedFilter[value].length !== 0) {
        if (value !== "tier_level" && value !== "Segment") {
          data.stringFilters.push(value);
          data.stringValuesToCheck.push(this.selectedFilter[value]);
        } else if(value ==='Segment'){
          data.listFilters.push(value);
          data.listValuesToCheck.push(this.selectedFilter[value]);
        } 
        else {
          data.doubleFilters.push(value);
          data.doubleValuesToCheck.push(this.selectedFilter[value]);
        }
      }
    }

    console.log(data);
    return data;
  }

  getAnalytics(isFiltered) {
    this.dataLoading = true;
    let data = null;
    if (!isFiltered) {
      data = {
        stringFilters: [],
        stringValuesToCheck: [[]],
        doubleFilters: [],
        doubleValuesToCheck: [[]],
        listFilters: [],
        listValuesToCheck: [[]]
      };
    } else {
      data = this.applyFilter();
    }

    this.getBucketChartsApi(data, "rfmchart").then((result) => {
      this.dataLoading = false;
      if (result['body']) {
        this.createRFMGraph(result["body"]);
      }
      else{
        this.scatterPlot = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
        };
      }
    });

    this.getBucketChartsApi(data, "frequencybucketchart").then((result) => {
      if (result['body']) {
        this.createFrequencyGraph(result["body"]);
      } else{
        this.frequencyBucketChart = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
        };
      }
    });

    this.getBucketChartsApi(data, "recencybucketchart").then((result) => {
      if (result['body']) {
        this.createRecencyGraph(result["body"]);
      }
      else{
        this.recencyBucketChart = {
          graphic: { 
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
        };
      }
    });
  }

  createFrequencyGraph(data) {
    console.log(data);
    const largestCustomerObj = data.reduce((maxObj, currentObj) => {
      return currentObj.customer_count > maxObj.customer_count
        ? currentObj
        : maxObj;
    }, data[0]);

    // Find the object with the smallest customer_count
    const smallestCustomerObj = data.reduce((minObj, currentObj) => {
      return currentObj.customer_count < minObj.customer_count
        ? currentObj
        : minObj;
    }, data[0]);
    let x1 = [],
      y1 = [];

    data.sort((a, b) => {
      let [numA, plusA] = String(a.frequency_bucket).split(/-|\+/);
      let [numB, plusB] = String(b.frequency_bucket).split(/-|\+/);

      if (plusA === undefined) plusA = "";
      if (plusB === undefined) plusB = "";

      if (plusA === "+" && plusB !== "+") {
        return 1;
      } else if (plusA !== "+" && plusB === "+") {
        return -1;
      } else {
        // If both have + or neither has +, compare the numeric part
        let numPartA = parseInt(numA);
        let numPartB = parseInt(numB);
        return numPartA - numPartB;
      }
    });

    data.forEach((data) => {
      //data['name'] = data.dateRange;
      x1.push(data.frequency_bucket);
      y1.push(data.avg_monetary);
      // data['value'] = data.count;
    });
    
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");

    this.frequencyBucketChart = {
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `Avg. Revenue: ${currencySymbol} ${data[dataIndex]["avg_monetary"].toFixed(2)} <br/> Total Customers: ${data[dataIndex]["customer_count"]} <br/> Total Revenue: ${currencySymbol} ${data[dataIndex]["total_monetary"].toFixed(2)}`; // Customize the tooltip content
        },
      },
      xAxis: {
        data: x1,
        name: "Monthly Average order Count(Frequency)",
        nameLocation: "center",
        nameGap: 30,
      },
      yAxis: {
        type: "value",
        name: `Revenue(${this.currencySymbol})`,
        nameLocation: "center",
        axisLabel: {
          formatter: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
        },
        nameGap: 30,
      },
      series: [
        {
          name: "Frequency",
          type: "bar",
          data: y1,
          label: {
            show: false,
            position: 'top',
            rotate: 90,
            offset: [35, 8],
              formatter: function (params) {
        // Display the value of the bar with two decimal places
                return currencySymbol + ' ' + params.value.toFixed(2);
              },
            color: 'black' // Text color
          },
          itemStyle: {
            color: function (param) {
              // Use the condition based on your data
              // Here, I'm assuming you want different colors for different ranges
              const dataIndex = param.dataIndex;
              let value =
                (data[dataIndex]["customer_count"] -
                  smallestCustomerObj.customer_count) /
                (largestCustomerObj.customer_count -
                  smallestCustomerObj.customer_count);
              switch (true) {
                case value >= 0.0 && value < 0.2:
                  return "#a9d6e5";
                case value >= 0.2 && value < 0.4:
                  return "#61a5c2";
                case value >= 0.4 && value < 0.6:
                  return "#2c7da0";
                case value >= 0.6 && value < 0.8:
                  return "#2a6f97";
                case value >= 0.8 && value <= 1:
                  return "#013a63";
                default:
                  return "#012a4a";
              }
              // if (value === 1) {
              //   return "#008000"; // Red for values > 50
              // } else {
              //   console.log(value);
              //   return "#008000"; // Green otherwise
              // }
            },
          }
        },
      ],
    };
  }

  createRecencyGraph(data) {
    console.log(data);
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    const largestCustomerObj = data.reduce((maxObj, currentObj) => {
      return currentObj.customer_count > maxObj.customer_count
        ? currentObj
        : maxObj;
    }, data[0]);

    // Find the object with the smallest customer_count
    const smallestCustomerObj = data.reduce((minObj, currentObj) => {
      return currentObj.customer_count < minObj.customer_count
        ? currentObj
        : minObj;
    }, data[0]);
    let x1 = [],
      y1 = [];

    data.sort((a, b) => {
      let [numA, plusA] = String(a.recency_bucket).split(/-|\+/);
      let [numB, plusB] = String(b.recency_bucket).split(/-|\+/);

      if (plusA === undefined) plusA = "";
      if (plusB === undefined) plusB = "";

      if (plusA === "+" && plusB !== "+") {
        return 1;
      } else if (plusA !== "+" && plusB === "+") {
        return -1;
      } else {
        // If both have + or neither has +, compare the numeric part
        let numPartA = parseInt(numA);
        let numPartB = parseInt(numB);
        return numPartA - numPartB;
      }
    });

    data.forEach((data) => {
      //data['name'] = data.dateRange;
      x1.push(data.recency_bucket);
      y1.push(data.avg_monetary);
      // data['value'] = data.count;
    });

    this.recencyBucketChart = {
      xAxis: {
        data: x1,
        name: "Days Since Last order(Recency)",
        nameLocation: "center",
        nameGap: 30,
      },
      yAxis: {
        type: "value",
        name: `Revenue(${this.currencySymbol})`,
        nameLocation: "center",
        axisLabel: {
          formatter: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
        },
        nameGap: 30,
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `Avg. Revenue: ${currencySymbol} ${data[dataIndex]["avg_monetary"].toFixed(2)} <br/> Total Customers: ${data[dataIndex]["customer_count"]} <br/> Total Revenue: ${currencySymbol} ${data[dataIndex]["total_monetary"].toFixed(2)}`; // Customize the tooltip content
        },
      },
      series: [
        {
          name: "Recency",
          type: "bar",
          data: y1,
          label: {
            show: false,
            position: 'top',
            rotate: 90,
            offset: [35, 8],
              formatter: function (params) {
        // Display the value of the bar with two decimal places
                return currencySymbol + ' ' + params.value.toFixed(2);
              },
            color: 'black' // Text color
          },
          itemStyle: {
            color: function (param) {
              // Use the condition based on your data
              // Here, I'm assuming you want different colors for different ranges
              const dataIndex = param.dataIndex;
              let value =
                (data[dataIndex]["customer_count"] -
                  smallestCustomerObj.customer_count) /
                (largestCustomerObj.customer_count -
                  smallestCustomerObj.customer_count);
              switch (true) {
                case value >= 0.0 && value < 0.2:
                  return "#a9d6e5";
                case value >= 0.2 && value < 0.4:
                  return "#61a5c2";
                case value >= 0.4 && value < 0.6:
                  return "#2c7da0";
                case value >= 0.6 && value < 0.8:
                  return "#2a6f97";
                case value >= 0.8 && value <= 1:
                  return "#013a63";
                default:
                  return "#012a4a";
              }
              // if (value === 1) {
              //   return "#008000"; // Red for values > 50
              // } else {
              //   console.log(value);
              //   return "#008000"; // Green otherwise
              // }
            },
          },
        },
      ],
    };
  }

  createRFMGraph(data) {
    // let xg1 = [],
    //   eachValue = [];
    // data.forEach((data) => {
    //   //data['name'] = data.dateRange;
    //   eachValue = [data.recency_bucket, data.frequency_bucket, data.total_monetary];
    //   xg1.push(eachValue);
    //   // data['value'] = data.count;
    // });

    // console.log(xg1.sort((a, b) => b[2] - a[2]));

    // // function normalizeColumn(column: number[]): number[] {
    // //   const minValue = Math.min(...column);
    // //   const maxValue = Math.max(...column);

    // //   return column.map((value) => (value - minValue) / (maxValue - minValue));
    // // }

    // // // Extract the second index values (column) for normalization
    // // const columnToNormalize = xg1.map((row) => row[2]); // Change index to 1 for the second column

    // // // Normalize the second index values
    // // const normalizedColumn = normalizeColumn(columnToNormalize);

    // // // Replace the normalized values in the dataset
    // // xg1 = xg1.map((row, index) => {
    // //   row[2] = normalizedColumn[index] * 20;
    // //   return row;
    // // });

    // this.scatterPlot = {
    //   // dataset: {
    //   //   source: xg1.sort((a, b) => b[2] - a[2])
    //   // },
    // visualMap: {
    //   show: false,
    //   dimension: 2, // means the 3rd column
    //   min: 2,
    //   max: 15,
    //   inRange: {
    //     // Size of the bubble.
    //     symbolSize: [10, 60],
    //   },
    // },
    // tooltip: {
    //   formatter: function (params) {
    //     const dataIndex = params.dataIndex;
    //     const data1 = params.value; // Access the data point values
    //     const formattedValue = data1[1].toFixed(2); // Format the value to two decimal places
    //     return `Frequency: ${formattedValue} <br/> Recency: ${data1[0].toFixed(2)} <br/> Monetary: ${data[dataIndex]['M']} <br/> Customer Name: ${data[dataIndex]['customerName']} <br/> Customer Id: ${data[dataIndex]['customer_id']} <br/> Age: ${data[dataIndex]['customerAge']}`; // Customize the tooltip content
    // }
    //   },
    //   xAxis: {
    //     type: 'category',
    //     name: "Recency",
    //     nameLocation: "center",
    //     nameGap: 30,
    //   },
    //   yAxis: {
    //     type: 'category',
    //     name: "Frequency",
    //   },
    //   series: {
    //     type: "scatter",
    //     data: xg1,
    //   },
    // };

    var xAxisData = data.map((item) => item.recency_bucket).sort();
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    let recencyValuesSet: any = new Set(
      data.map((item) => item.recency_bucket)
    );
    let frequencyValuesSet: any = new Set(
      data.map((item) => item.frequency_bucket)
    );
    var yAxisData = data.map((item) => item.frequency_bucket).sort();
    var bubbleSizes = data.map((item) => item.avg_monetary).sort();
    let recencyValues = [...recencyValuesSet].sort((a, b) => {
      let [numA, plusA] = String(a).split(/-|\+/);
      let [numB, plusB] = String(b).split(/-|\+/);

      if (plusA === undefined) plusA = "";
      if (plusB === undefined) plusB = "";

      if (plusA === "+" && plusB !== "+") {
        return 1;
      } else if (plusA !== "+" && plusB === "+") {
        return -1;
      } else {
        // If both have + or neither has +, compare the numeric part
        let numPartA = parseInt(numA);
        let numPartB = parseInt(numB);
        return numPartA - numPartB;
      }
    });
    let frequencyValues = [...frequencyValuesSet].sort((a, b) => {
      let [numA, plusA] = String(a).split(/-|\+/);
      let [numB, plusB] = String(b).split(/-|\+/);

      if (plusA === undefined) plusA = "";
      if (plusB === undefined) plusB = "";

      if (plusA === "+" && plusB !== "+") {
        return 1;
      } else if (plusA !== "+" && plusB === "+") {
        return -1;
      } else {
        // If both have + or neither has +, compare the numeric part
        let numPartA = parseInt(numA);
        let numPartB = parseInt(numB);
        return numPartA - numPartB;
      }
    });
    const largestCustomerObj = data.reduce((maxObj, currentObj) => {
      return currentObj.customer_count > maxObj.customer_count
        ? currentObj
        : maxObj;
    }, data[0]);

    // Find the object with the smallest customer_count
    const smallestCustomerObj = data.reduce((minObj, currentObj) => {
      return currentObj.customer_count < minObj.customer_count
        ? currentObj
        : minObj;
    }, data[0]);

    const largestRevObj = data.reduce((maxObj, currentObj) => {
      return currentObj.avg_monetary > maxObj.avg_monetary
        ? currentObj
        : maxObj;
    }, data[0]);

    // Find the object with the smallest customer_count
    const smallestRevObj = data.reduce((minObj, currentObj) => {
      return currentObj.avg_monetary < minObj.avg_monetary
        ? currentObj
        : minObj;
    }, data[0]);

    let scatterData = data.map((item) => {
      return {
        name: item.recency_bucket,
        value: [
          recencyValues.indexOf(item.recency_bucket),
          frequencyValues.indexOf(item.frequency_bucket),
          item.avg_monetary,
        ],
        label: {
          emphasis: {
            show: false,
            formatter: item.recency_bucket + " - " + item.frequency_bucket,
          },
        },
        itemStyle: {
          emphasis: {
            borderColor: "#fff",
            borderWidth: 1,
          },
        },
      };
    });

    // Set up the chart options
    this.scatterPlot = {
      xAxis: {
        type: "category",
        data: recencyValues,
        name: "Days Since Last order(Recency)",
        nameLocation: "center",
        nameGap: 30,
      },
      yAxis: {
        type: "category",
        data: frequencyValues,
        nameLocation: "center",
        nameGap: 60,
        name: "Monthly Average order Count(Frequency)",
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `Frequency: ${data[dataIndex]["frequency_bucket"]} <br/> Recency: ${data[dataIndex]["recency_bucket"]} <br/> Avg. Monetary: ${currencySymbol} ${data[dataIndex]["avg_monetary"].toFixed(2)} <br/> Total Revenue: ${currencySymbol} ${data[dataIndex]["total_monetary"].toFixed(2)} <br/> Total Customers: ${data[dataIndex]["customer_count"]}`; // Customize the tooltip content
        },
      },
      series: [
        {
          type: "scatter",
          symbolSize: function (data) {
            // let d = Math.sqrt(data[2]);
            // if (d < 10) {
            //   d = 10;
            // } else if (d > 60) {
            //   d = 60;
            // } else {
            //   d = d * 2;
            // }

            // return d; // Bubble size based on avg_monetary
              let value =
                (data[2] -
                  smallestRevObj.avg_monetary) /
                (largestRevObj.avg_monetary -
                  smallestRevObj.avg_monetary);
              switch (true) {
                case value >= 0.0 && value < 0.2:
                  return 20;
                case value >= 0.2 && value < 0.4:
                  return 30;
                case value >= 0.4 && value < 0.6:
                  return 40;
                case value >= 0.6 && value < 0.8:
                  return 50;
                case value >= 0.8 && value <= 1:
                  return 60;
                default:
                  return 60;
              }
          },
          itemStyle: {
            color: function (param) {
              // Use the condition based on your data
              // Here, I'm assuming you want different colors for different ranges
              const dataIndex = param.dataIndex;
              let value =
                (data[dataIndex]["customer_count"] -
                  smallestCustomerObj.customer_count) /
                (largestCustomerObj.customer_count -
                  smallestCustomerObj.customer_count);
              switch (true) {
                case value >= 0.0 && value < 0.2:
                  return "#a9d6e5";
                case value >= 0.2 && value < 0.4:
                  return "#61a5c2";
                case value >= 0.4 && value < 0.6:
                  return "#2c7da0";
                case value >= 0.6 && value < 0.8:
                  return "#2a6f97";
                case value >= 0.8 && value <= 1:
                  return "#013a63";
                default:
                  return "#012a4a";
              }
              // if (value === 1) {
              //   return "#008000"; // Red for values > 50
              // } else {
              //   console.log(value);
              //   return "#008000"; // Green otherwise
              // }
            },
          },
          emphasis: {
            label: {
              show: true,
              formatter: function (param) {
                // Access the data associated with the clicked bubble
                let dataIndex = param.dataIndex;
                let clickedBubbleData = scatterData[dataIndex];
                // Call your custom function when the bubble is clicked
                handleBubbleClick(clickedBubbleData);
              },
            },
          },
          data: scatterData,
          label: {
            show: false,
            formatter: function (param) {
              return currencySymbol + param.data.value[2]; // Bubble label showing avg_monetary
            },
          },
        },
      ],
    };
    function handleBubbleClick(bubbleData) {
      // Implement your logic here
      console.log("Bubble clicked! Data:", bubbleData);
      
      // Call your function or perform actions based on the clicked bubble data
    }
  }

  onChartClick(event: any): void {
    // Access the clicked data and handle the click event
    if (event.data) {
      console.log("Bubble clicked! Data:", event.data);
      // Call your function or perform actions based on the clicked bubble data
      const [recency, frequency] = event.data.label.emphasis.formatter.split(" - ");
      console.log(recency, frequency);
      this.isLoading = true;
      this.getCustomerList(frequency, recency);
    }
  }

  onChartClickFrequency(event: any): void {
    // Access the clicked data and handle the click event
    if (event.data) {
      console.log("Fre clicked! Data:", event);
      // Call your function or perform actions based on the clicked bubble data
      // const [recency, frequency] = event.data.label.emphasis.formatter.split(" - ");
      // console.log(recency, frequency);
      this.isLoading = true;
      this.getCustomerList(event.name, null);
    }
  }

  onChartClickRecency(event: any): void {
    // Access the clicked data and handle the click event
    if (event.data) {
      console.log("Rec clicked! Data:", event);
      // Call your function or perform actions based on the clicked bubble data
      // const [recency, frequency] = event.data.label.emphasis.formatter.split(" - ");
      // console.log(recency, frequency);
      this.isLoading = true;
      this.getCustomerList(null, event.name);
    }
  }

  totalCustomerRevenueTimeFrameDropDownHandler() {
    console.log(this.totalCustomerRevenueTimeFrame);
    // this.getAnalytics();
  }

  getDisplayFilterName(value){
    switch(value){
      case "PointsBucket" :
        return "Loyalty Points Bucket";
      case "Segment" :
        return "Segment Name";
      case "age_bucket" :
        return "Age Bucket";
      case "customerAge" :
        return "Customer's Age";
      case "tier_level" :
        return "Tier Level";
      case "tier_name":
        return "Tier Name";
      case "storeName":
        return "Store Name";
      case "category":
        return "Product Category";
      case "cltv_bucket": 
        return "Cltv Bucket"
      default:
        return value.toLowerCase().replace(/\b\w/g, function(char) {
          return char.toUpperCase();
        });;
    }
  }

  getCustomerList(frequency, recency){
    let data = this.applyFilter();
    if(frequency){
      data.stringFilters.push('frequency_bucket');
      data.stringValuesToCheck.push([frequency.indexOf("-")=== -1 ? frequency + ".0" : frequency]);
    }
    if(recency){
      data.stringFilters.push('recency_bucket');
      data.stringValuesToCheck.push([recency]);
    }
    this.getBucketChartsApi(data, "rfmcustomerslist").then((result) => {
      this.dataLoading = false;
      if (result) {
        this.isLoading = false;
        console.log(result);
        const dialogRef = this.dialog.open(DataPreviewAmalyticsDialogBoxComponent, { panelClass: "no-padding-dialog-popup",
        width: "780px",
        height: "auto",
        data: {
          customerDetails : result['body'],
          header : "Customer Details"
        },
      });
      }
    });
  }

  getBucketChartsApi(data, type) {
    return new Promise((resolve, reject) => {
      this._apiCall.getReportChart(data, type, "POST").subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  // getRFmSampleData(data, type){
  //   return new Promise((resolve, reject) => {
  //    let data = {
  //       "message": "success",
  //       "body": [
  //           {
  //               "customer_count": 2.0,
  //               "total_monetary": 278.98,
  //               "avg_monetary": 278.98,
  //               "frequency_bucket": 3.0,
  //               "recency_bucket": "121-150"
  //           },
  //           {
  //               "customer_count": 1.0,
  //               "total_monetary": 1489.95,
  //               "avg_monetary": 1489.95,
  //               "frequency_bucket": 1.0,
  //               "recency_bucket": "91-120"
  //           },
  //           {
  //               "customer_count": 3.0,
  //               "total_monetary": 1111.3,
  //               "avg_monetary": 1111.3,
  //               "frequency_bucket": 4.0,
  //               "recency_bucket": "61-90"
  //           },
  //           {
  //               "customer_count": 2.0,
  //               "total_monetary": 1649.45,
  //               "avg_monetary": 1649.45,
  //               "frequency_bucket": 6.0,
  //               "recency_bucket": "0-30"
  //           },
  //           {
  //               "customer_count": 1.0,
  //               "total_monetary": 297.65,
  //               "avg_monetary": 297.65,
  //               "frequency_bucket": 2.0,
  //               "recency_bucket": "0-30"
  //           },
  //           {
  //               "customer_count": 3.0,
  //               "total_monetary": 249.95,
  //               "avg_monetary": 249.95,
  //               "frequency_bucket": 1.0,
  //               "recency_bucket": "121-150"
  //           },
  //           {
  //               "customer_count": 2.0,
  //               "total_monetary": 349.95,
  //               "avg_monetary": 349.95,
  //               "frequency_bucket": 1.0,
  //               "recency_bucket": "251-300"
  //           },
  //           {
  //               "customer_count": 4.0,
  //               "total_monetary": 249.95,
  //               "avg_monetary": 249.95,
  //               "frequency_bucket": 1.0,
  //               "recency_bucket": "0-30"
  //           },
  //           {
  //               "customer_count": 1.0,
  //               "total_monetary": 599.8,
  //               "avg_monetary": 599.8,
  //               "frequency_bucket": 2.0,
  //               "recency_bucket": "61-90"
  //           }
  //       ]
  //     };
  //     resolve(data);
  //   });
  // }
  
  selectAllOptions(key, value) {
    if (this.selectedFilter[key].length === value.length) {
      this.selectedFilter[key] = [];
    } else {
      if(key !== 'Segment')
        this.selectedFilter[key] = [...value];
      else {
        let valueSegment = [];
        value.forEach((data)=>{
          valueSegment.push(data.id);
        });
        this.selectedFilter[key] = valueSegment;
      }
    }
  }

  openInsight(type){
    const dialogref = this.dialog.open(
      InsightGraphDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "500px",
        height : "500px",
        data: {
          type : type
        }
      }
    );
  }

  getAllFiltersApi(type) {
    return new Promise((resolve, reject) => {
      this._apiCall.getFilterForAnalytics(type).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }
}
