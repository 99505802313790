<div style="padding: 30px;">
  <div style="display: flex;justify-content:space-between;width:100%">
      <div class="qb-dialog-header">{{'SHARED_PAGE.SELECT_SOCIAL_MEDIA_PLATFORM' | i18nTranslate}}</div>
      <div *ngIf="data['refresh'] === false">
          <mat-icon class="qb-close-button" (click)="close(0)">cancel</mat-icon>
      </div>
  </div>
  <div class="qb-dialog-text qb-mb-16" *ngIf="data['refresh'] === false">{{'SHARED_PAGE.SELECT_SOCIA_MEDIA_PARAGRAPH' | i18nTranslate}}</div>
  <div class="qb-dialog-text qb-mb-16" *ngIf="data['refresh'] === true">{{data['textMsg']}}</div>

  <div class="qb-social-channel-list" *ngIf="data['refresh'] === false"> 
    <div class="qb-social-channel-box" *ngFor="let channel of socialChannelList">

      <div class="qb-social-channel-head" [style.background-color]="channel.headBackroundColor"></div>
      <div class="qb-social-channel-body-wh">

        <div class="qb-social-channel-inside-box">
          <div [innerHtml]="channel.svg | safeHtml"></div>
        </div>
<div></div>
        <div class="qb-dialog-subheader">{{channel.name}}</div>

        <div class="qb-btn-connect" (click)="close(channel.channelName)">
          {{'SHARED_PAGE.CONNECT' | i18nTranslate}}
        </div>

      </div>
    </div>

  </div>
  <div *ngIf="data['refresh'] === true">
    <div class="primary-button" style="width: 150px;" (click)="close('facebook')">{{'SHARED_PAGE.CONNECT' | i18nTranslate}}</div>
  </div>
</div>