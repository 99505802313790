<div class="qb-main">
  <div class="qb-main-padding">
    <div class="qb-rectangle-cards">
      <div class="qb-rectangle-card">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-heading-text">{{'GAMIFY_PAGE.IMPRESSIONS' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentImpression">
            <ng-template #popContentImpression class="qb-background-ng-template">
              <div class="qb-ng-template-text">
                <div class="qb-d-inline-flex">{{'GAMIFY_PAGE.IMPRESSIONS_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Impression.svg" alt="">
          </div>
        </div>
        <div class="qb-value-text">
          {{ImpressionValue}}
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_TARGET_CUSTOMER' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalTargetCustomer}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_VIEWS' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalViews}}</div>
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-flex-end">
          <div class="qb-d-flex qb-pl-5">
            <div>{{'GAMIFY_PAGE.DESKTOP' | i18nTranslate}} &nbsp;{{DeskstopPercentage}}%</div>
            <div>&nbsp; {{'GAMIFY_PAGE.MOBILE' | i18nTranslate}} &nbsp;{{MobilePercentage}}%</div>
          </div>
        </div>
      </div>
      <div class="qb-rectangle-card qb-ml-10">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-heading-text">{{'GAMIFY_PAGE.ENGAGEMENT' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentEngagement">
            <ng-template #popContentEngagement class="qb-background-ng-template">
              <div class="qb-ng-template-text">
                <div class="qb-d-inline-flex">{{'GAMIFY_PAGE.ENGAGEMENT_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Conversion-rate.svg" alt="">
          </div>
        </div>
        <div class="qb-value-text">
          {{EngagementValue}}%
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_PEOPLE_PLAYED' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalPeoplePlayed}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{"GAMIFY_PAGE.NO_PEOPLE_COMPLETED" | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{NoPeopleCompleted}}</div>
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-flex-end">
          <div class="qb-d-flex qb-pl-5">
            <div>{{'GAMIFY_PAGE.DESKTOP' | i18nTranslate}} &nbsp;{{DeskstopPercentageEngagement}}%</div>
            <div>&nbsp; {{'GAMIFY_PAGE.MOBILE' | i18nTranslate}} &nbsp;{{MobilePercentageEngagement}}%</div>
          </div>
        </div>
      </div>
      <div class="qb-rectangle-card qb-ml-10">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-heading-text">{{'GAMIFY_PAGE.CONVERSION_RATE' | i18nTranslate}}</div>
            <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
            [ngbPopover]="popContentConversion">
            <ng-template #popContentConversion class="qb-background-ng-template">
              <div class="qb-ng-template-text">
                <div class="qb-d-inline-flex">{{'GAMIFY_PAGE.CONVERSION_RATE_INFO' | i18nTranslate}}</div>
              </div>
            </ng-template>
              <div class="qb-i">i</div>
            </div>
          </div>
          <!-- <div class="qb-image-div">
            <div class="qb-image">
              <img class="qb-images" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Engagement.svg" >
            </div>
            </div> -->
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Engagement.svg" alt="">
          </div>
        </div>
        <div class="qb-value-text">
          {{ConversionRate}}%
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.TOTAL_PURCHASES' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{TotalPurchases}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.ANONYMOUS_PURCHASE' | i18nTranslate}} </div>
          <div class="qb-text-format-stat">{{AnonymusPurchases}}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.EMAIL_COLLECTED' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{EmailCollected}}</div>
        </div>
      </div>
      <div class="qb-rectangle-card qb-ml-10">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex">
            <div class="qb-heading-text">{{'GAMIFY_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
            <!-- <div class="qb-i-div-survey-dash">
              <div class="qb-i">i</div>
            </div> -->
          </div>
          <div>
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/Revenue.svg" alt="">
          </div>
        </div>
        <div class="qb-value-text">
          {{RevenueGenerated| customCurrency: revCurrency: true }}
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.VIA_DESKSTOP' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{viaDeskstop | customCurrency: revCurrency: true }}</div>
        </div>
        <div class="qb-d-flex qb-jc-space-between qb-mt-5">
          <div class="qb-text-format-stat">{{'GAMIFY_PAGE.VIA_MOBILE' | i18nTranslate}}</div>
          <div class="qb-text-format-stat">{{viaMobile | customCurrency: revCurrency: true }}</div>
        </div>
        <hr class="qb-hrline">
        <div class="qb-d-flex qb-jc-flex-end">
          <div class="qb-d-flex qb-pl-5">
            <div>{{'GAMIFY_PAGE.DESKTOP' | i18nTranslate}} &nbsp;{{DeskstopPercentageRevenue}}%</div>
            <div>&nbsp; {{'GAMIFY_PAGE.MOBILE' | i18nTranslate}} &nbsp;{{MobilePercentageRevenue}}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-graph-main">
    <div class="qb-d-flex qb-jc-space-between">
      <div class="qb-d-flex">
        <div>
          <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/analyze.svg" alt="">
        </div>
        <div class="qb-sub-text">
          {{'GAMIFY_PAGE.REVENUE_GENERATED' | i18nTranslate}}
        </div>
      </div>
      <div class="qb-d-flex qb-ai-center">

        <div class="qb-home-date-range-selector" (click)="openSelect()">
          <div class="qb-calender-container">
            <span
              class="material-icons icons-primary qb-calender-span-container"
            >
              calendar_today
            </span>
          </div>
          <div class="qb-select-div-2">
            <mat-select
              #select
              class="qb-graph-select"
              [(ngModel)]="selectedPeriod"
              (selectionChange)="ChangePeriod()"
              [value]="selectedPeriod"
            >
              <mat-option
                value="TODAY"
                >{{'GAMIFY_PAGE.TODAY' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="YESTERDAY"
                >{{'GAMIFY_PAGE.YESTERDAY' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="LAST_7_DAYS"
                >{{'GAMIFY_PAGE.LAST_WEEK' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="LAST_15_DAYS"
                >{{'GAMIFY_PAGE.LAST_15_DAYS' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="LAST_30_DAYS"
                >{{'GAMIFY_PAGE.LAST_30_DAYS' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="LAST_90_DAYS"
                >{{'GAMIFY_PAGE.LAST_90_DAYS' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="LAST_6_MONTHS"
                >{{'GAMIFY_PAGE.LAST_6_MONTHS' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="LAST_1_YEAR"
                >Last Year</mat-option
              >
              <mat-option
                value="PREV_WEEK"
                >{{'GAMIFY_PAGE.PREV_WEEK' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="THIS_WEEK"
                >{{'GAMIFY_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="THIS_MONTH"
                >{{'GAMIFY_PAGE.THIS_MONTH' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="PREV_MONTH"
                >{{'GAMIFY_PAGE.PREV_MONTH' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="THIS_QUARTER"
                >{{'GAMIFY_PAGE.THIS_QUARTER' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="PREV_QUARTER"
                >{{'GAMIFY_PAGE.PREV_QUARTER' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="THIS_YEAR"
                >{{'GAMIFY_PAGE.THIS_YEAR' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="PREV_YEAR"
                >{{'GAMIFY_PAGE.PREV_YEAR' | i18nTranslate}}</mat-option
              >
              <mat-option
                value="CUSTOM"
                >{{'GAMIFY_PAGE.CUSTOM' | i18nTranslate}}</mat-option
              >
            </mat-select>
          </div>
        </div>


        <div *ngIf="selectedPeriod==='CUSTOM'">
          <!-- <div class="qb-date-range-picker-wrapper">
            <mat-form-field appearance="fill">
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onEndDateChange($event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div> -->
          <ejs-daterangepicker
          #ejDateRangePicker
          cssClass="customCSS"
          [format]="dateFormat"
          [max]="maxDate"
          placeholder="Select a range"
          [startDate]="start"
          [endDate]="end"
          (oninvalid)="errorDate()"
          (change)="dateChangeDetected($event)"
        >
        </ejs-daterangepicker>
        </div>
        <div class="qb-filter-view-by-container" *ngIf="selectedPeriod==='CUSTOM'">
          <div class="qb-filter-text">
            {{'GAMIFY_PAGE.FILTER_VIEW_BY' | i18nTranslate}} &nbsp;&nbsp;
          </div>
          <div>
            <div>
              <div
                class="qb-multi-select-panel"
                id="multi-select-panel"
                (click)="toggleSelect()"
              >
                <span *ngIf="filterViewBy" class="qb-text-transform-capital"
                  >{{ filterViewBy }}</span
                >
                <span *ngIf="filterViewBy == null">{{'GAMIFY_PAGE.SELECT' | i18nTranslate}}</span>
                <div
                  class="keyboard-down-icon-container"
                  id="keyboard-down-icon-container"
                >
                  <mat-icon class="keyboard-down-icon"
                    >keyboard_arrow_down</mat-icon
                  >
                </div>
              </div>
              <div class="qb-select-container">
                <div class="qb-multi-select-dropdown" id="multi-select-dropdown">
                  <div id="segment-select" class="segment-select">
                    <div #filterdropdown>
                      <mat-option
                        class="qb-font-size-11"
                        (click)="updateCheckboxValues('Daily')"
                        color="primary"
                        data-widget-name="widgetNameForAnalytics"
                        data-button-name="Filter By: Days"
                        >{{'GAMIFY_PAGE.DAILY' | i18nTranslate}}
                      </mat-option>
                      <!-- <mat-option  style="font-size: 11px;"*ngIf="daySpan>=7" (click)="updateCheckboxValues('week')" color="primary">Weeks
                    </mat-option> -->
                      <mat-option
                        class="qb-font-size-11"
                        *ngIf="daySpan >= 7"
                        (click)="updateCheckboxValues('Weekly')"
                        color="primary"
                        data-button-name="Filter By: Weeks"
                        >{{'GAMIFY_PAGE.WEEKLY' | i18nTranslate}}
                      </mat-option>
                      <mat-option
                      class="qb-font-size-11"
                      *ngIf="daySpan >= 28"
                      (click)="updateCheckboxValues('Monthly')"
                      color="primary"
                      data-button-name="Filter By: Months"
                      >{{'GAMIFY_PAGE.MONTHLY' | i18nTranslate}}
                    </mat-option>
                  <mat-option
                  class="qb-font-size-11"
                  *ngIf="daySpan >= 92"
                  (click)="updateCheckboxValues('Quarterly')"
                  color="primary"
                  data-button-name="Filter By: Quarterly"
                  >{{'GAMIFY_PAGE.QUARTLY' | i18nTranslate}}
                </mat-option>
                <mat-option
                class="qb-font-size-11"
                *ngIf="daySpan >= 365"
                (click)="updateCheckboxValues('Yearly')"
                color="primary"
                data-button-name="Filter By: Yearly"
                >{{'GAMIFY_PAGE.YEARLY' | i18nTranslate}}
              </mat-option>
                      <!-- <mat-option  style="font-size: 11px;"*ngIf="daySpan>=365" (click)="updateCheckboxValues('year')" color="primary">Years
                    </mat-option> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="qb-hrline">
    <div class="qb-d-flex">
      <div  class="qb-revenue-container">
        <div class="qb-revenue-text">
          {{TotalRevenue | customCurrency: revCurrency: true }}
        </div>
        <div class="qb-normal-text" *ngIf="selectedGame==='All Games'">
          {{'GAMIFY_PAGE.REVENUE_ALL_GAMES' | i18nTranslate}}
        </div>
        <div class="qb-normal-text" *ngIf="selectedGame!='All Games'">
          {{'GAMIFY_PAGE.REVENUE_FROM' | i18nTranslate}} <span class="qb-email-name qb-game-name-color" (click)="changeGame()">{{selectedGame['title']}}</span>
        </div>
        <div class="qb-normal-text qb-mt-30">
          {{'GAMIFY_PAGE.REVENUE_OF_SPECIFIC_GAME' | i18nTranslate}}
        </div>

        <div class="qb-home-game-selector qb-game-page-selector-div" (click)="openSelectGame()">
          <div class="qb-game-page-selector-container">
          </div>
          <div class="qb-select-div-2 qb-game-page-selector-container-2">
            <mat-select
              #selectGame
              class="qb-graph-select qb-of-auto"
              [(ngModel)]="selectedGame"
              (selectionChange)="ChangeGame()"
              [value]="selectedGame"

            >
              <mat-option
                value="All Games"
                >{{'GAMIFY_PAGE.ALL_GAMES' | i18nTranslate}}</mat-option
              >
              <mat-option *ngFor="
              let game of AllGameList"
              [value]="game"

              >{{game['title']}}</mat-option
            >
            </mat-select>
          </div>
        </div>





      </div>
      <div class="qb-verticalLine"></div>
      <div class="qb-chart-area-container">
        <div
        class="qb-chart-area-sub-container"
        #chartArea
      >
        <div
          *ngIf="!graphDataLoading"
          class="qb-bar-chart-area"
          [style.height.px]="chartheight"
        >
          <div *ngIf="!graphDataNull && TotalRevenue !=0" class="qb-full-size">
            <div class="qb-bar-chart-container">
              <div class="qb-write-vr-bt">{{ YLegend }}</div>
              <div class="qb-width-98">
                <app-insights-bar-chart
                  [data]="graphData"
                  [sortBy]="filterViewBy"
                  [chartSize]="chartheight"
                >
                </app-insights-bar-chart>
              </div>
            </div>
            <div class="qb-xlegent-text">
              {{ Xlegend | titlecase }}
            </div>
          </div>

          <div
            *ngIf="graphDataNull || TotalRevenue == 0"
            class="qb-no-data-area"
          >
            <!-- <div style="display: flex;justify-content: center;align-items: center;"> -->
            <!-- <div
            class="qb-no-data-container"
            >
              No Data Available
            </div> -->
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt="" style="padding-top: 100px;">
            <div style="margin-top: 15px;">
              {{'GAMIFY_PAGE.NO_DATA_FOR_THIS_REPORT' | i18nTranslate}}
            </div>
            <!-- </div> -->
          </div>
          <!-- <app-bar-chart [data]="graphData"></app-bar-chart> -->
        </div>
        <div
          *ngIf="graphDataLoading"
          class="qb-spinner"
          [style.height.px]="chartheight"
        >
          <div
           class="qb-spinner-container"
          >
            <mat-spinner diameter="25"></mat-spinner>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>