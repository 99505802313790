import { Component, HostListener, OnInit, Pipe } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PartnershipNotificationRedirectService } from "src/app/core/services/partnership-notification-redirect.service";
import { ConfirmationDialogBoxComponent, UpdateFormComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-remove-offer",
  templateUrl: "./remove-offer.component.html",
  styleUrls: ["./remove-offer.component.css"],
})
export class RemoveOfferComponent implements OnInit {
  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;
  offerData = [];
  requestId;
  formHtml;
  formName;
  isRedirect;
  names = [];
  message;
  message2;
  subscription: Subscription;
  formJson;
  formId;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private apiCall: ApiCallService,
    private activatedRoute: ActivatedRoute,
    private redirectService: PartnershipNotificationRedirectService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getScreenSize();
    this.getSubscription();
  }

  getSubscription() {
    this.subscription = this.redirectService.currentMessage.subscribe(
      (message) => {
        this.message2 = message;
        this.getParams(message);
      }
    );
  }

  getParams(message) {
    this.activatedRoute.params.subscribe((param) => {
      this.requestId = param.id;
      if (message === "") {
        this.redirectNotification(param);
      } else {
        this.setValues(message);
      }
    });
  }

  redirectNotification(param) {
    this.apiCall
      .notificationRedirect(param.notifId, "OFFER_DISABLED_NOTIF")
      .subscribe((response) => {
        this.message2 = response;
        this.setValues(response);
      });
  }

  setValues(message) {
    this.isRedirect = message["body"]["isRedirect"];
    let response = this.message2["body"]["data"]["allOffersForForm"];
    this.offerData = response["formOfferDetailsList"];
    this.offerData.forEach((offer) => {
      if (!offer.isActive) {
        this.names.push(offer.storeName);
      }
    });
    this.formName = response["formName"];
    let response2 = this.message2["body"]["data"]["formDetails"];
    this.formId = response2["formId"];
    this.formHtml = response2["formHtml"];
    this.formJson = response2["formJson"];
  }

  editForm() {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("EDIT FORM", ['POS_PAGE']),
        message:
           this._i18nDynamicTranslate.transform("If you continue with editing the form all the beautification and editing that you did while making the form will be lost. Are you sure you want to continue?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Edit Form", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate([
          "/app/partnership/create-form",
          {
            clone: false,
            request: this.formId,
            editForm: true,
          },
        ]);
      }
    });
  }

  update() {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    this.dialog.open(UpdateFormComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1100px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: {
        json: this.formJson,
        id: this.formId,
      },
    });
  }

  cancel() {
    this.router.navigate(["/app/partnership/my-partners"]);
  }
}
