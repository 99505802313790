<div
  style="
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    gap: 15px;
  "
>
  <div style="display: flex; gap: 15px; flex-direction: column"    [ngClass]="{
    'qb-w-700': (validWidgets | widgetConfig: 52),
    'qb-w-100': !(validWidgets | widgetConfig: 52)
}">
    <div class="qb-bck qb-height-270 qb-padding-15 qb-border-radius-5 overflow-auto"    [ngClass]="{
      'qb-height-270': dataType === 'socialChannel' && (validWidgets | widgetConfig: 52),
      'qb-height-190': dataType !== 'socialChannel' && (validWidgets | widgetConfig: 52),
      'qb-h-100' : !(validWidgets | widgetConfig: 52)
  }">
  <!-- <div>
    <div class="tabs-ad">
      <div style="display: flex">
        <div class="tab-label" (click)="changeType('socialChannel')"
          [ngClass]="{ active: dataType == 'socialChannel' }" data-widget-name="OFFER" data-button-name="socialChannel">
          {{'COMMUNICATION_PAGE.SOCIAL_CHANNEL' | i18nTranslate}}
        </div>
        <div class="tab-label objectives-tab-label" (click)="changeType('adAccount')"
          [ngClass]="{ active: dataType == 'adAccount' }" data-widget-name="OFFER"
          data-button-name="adAccount">
          {{'COMMUNICATION_PAGE.AD_ACCOUNT' | i18nTranslate}}
        </div>
      </div>
      <div class="qb-social-card-title" >
        {{'COMMUNICATION_PAGE.AD_ACCOUNT' | i18nTranslate}}
      </div>
    </div>
  </div> -->
      <div
        *ngIf="(socialChannels.length == 0 && !socialChannelsLoading && dataType ==='socialChannel') || (adAccount.length == 0 && dataType ==='adAccount' && !isAdAccountLoading)"
        class="qb-display-flex-center qb-flex-column"
      >
        <!-- <div class="qb-social-card-title qb-text-align-center" *ngIf="dataType ==='socialChannel'">
          {{'COMMUNICATION_PAGE.ADD_SOCIAL_CHANNEL' | i18nTranslate}}
        </div>
        <div class="qb-social-card-title qb-text-align-center" *ngIf="dataType ==='adAccount'">
          {{'COMMUNICATION_PAGE.ADD_AD_ACCOUNT' | i18nTranslate}}
        </div>
        <div class="qb-social-card-subtitle qb-text-align-center" *ngIf="dataType ==='socialChannel'">
          {{'COMMUNICATION_PAGE.CHANNEL_ACCOUNT_LINK_CHANNEL' | i18nTranslate}}
        </div>
        <div class="qb-social-card-subtitle qb-text-align-center" *ngIf="dataType ==='adAccount'">
          {{'COMMUNICATION_PAGE.AD_ACCOUNT_LINK_AD_ACCOUNT' | i18nTranslate}}
        </div> -->

        <div class="qb-d-flex">
          <h3 class="module-heading">Social Media</h3>
          <button class="primary-button button-hover-effect margin-left-auto" (click)="openConnectSocialProfilesDialogAd(false)" *ngIf="dataType ==='adAccount'">
            <span>Add Your Ad Account</span>
          </button>
        </div>
        

        <div class="qb-card-container margin-top-13">
          <div class="semi-big-text">
            {{'COMMUNICATION_PAGE.AD_ACCOUNT' | i18nTranslate}}
          </div>
          <div class="content-text">
            Utilize the social media feature within Qubriux to create a dynamic custom audience list for precision-targeting your Facebook/Instagram ads towards a specific segment or tailored customer journey. Qubriux empowers you to seamlessly include or exclude customers in your meta custom audience list through segments or campaign nodes, ensuring a refined audience definition for your social media ads.
          </div>
        </div>

        <!-- <div
          class="qb-text-align-center cursor-pointer qb-add-icon"
          (click)="openConnectSocialProfilesDialog(false)"
          *ngIf="dataType ==='socialChannel'"
        >
        <span class="material-symbols-outlined">
          add
          </span>
        </div> -->
        
        <div class="row-sp margin-top-13">
          <div class="qb-card-container-2">
            <div class="card-title">
              <div class="icon-bg">
                <span class="material-symbols-outlined big-icon">
                  link
                  </span>
              </div>
              <div class="semi-big-text">
                Step 1: Integrate
              </div>
            </div>
            <div class="card-content">
              Integrate your social media ads page with Qubriux. Once integrated, you can effortlessly generate multiple custom audience lists directly from Qubriux, giving you control over who enters the list (as outlined in step 2).
            </div>
          </div>
          <div class="qb-card-container-2">
            <div class="card-title">
              <div class="icon-bg">
                <span class="material-symbols-outlined big-icon">
                  ios_share
                  </span>
              </div>
              <div class="semi-big-text">
                Step 2: Define your target audience
              </div>
            </div>
            <div class="card-content">
              Define your target audience in two ways
              <ol start="a" type="a">
                <li style="margin-bottom: 17px">
                  Export a segment directly as a custom audience. This functionality is accessible through the segmentation screen. The custom audience list will be regularly updated, providing a fresh list of individuals daily who either fall into or are removed from the segment, ensuring that your ads consistently reach the relevant customers
                </li>
                <li style="margin-bottom: 20px">
                  Outline a campaign journey and incorporate a social media node to automatically add customers reaching that node to a custom audience list. Once added to the list, the ads targeted to this audience will immediately become visible to them.
                </li>
              </ol>
            </div>
          </div>
          <div class="qb-card-container-2">
            <div class="card-title">
              <div class="icon-bg">
                <span class="material-symbols-outlined big-icon">
                  add_ad
                  </span>
              </div>
              <div class="semi-big-text">
                Step 3: Configure Ads
              </div>
            </div>
            <div class="card-content">
              Access your Meta ads account and configure your ads to target the respective custom audience lists. Once configured, Qubriux takes over the responsibility of maintaining and updating the audience lists, ensuring that your ads stay relevant and effective.
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="(socialChannels.length > 0 && !socialChannelsLoading && dataType === 'socialChannel') || (adAccount.length > 0 && dataType ==='adAccount' && !isAdAccountLoading)"
        class="qb-social-channel"
      >
        <span *ngIf="dataType === 'socialChannel'">
          <div class="qb-display-flex qb-channel-block">
            <div
              class="qb-social-channel-title"
              *ngFor="let tableName of socialChannelTableData; let last = last"
            >
            <div *ngIf="tableName !== 'New Followers'">
              {{ tableName }}
              </div>
              <div style="display: flex;" *ngIf="tableName === 'New Followers'">
                {{ tableName }}
                <div class="qb-i-div-survey-dash" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
                [ngbPopover]="popContentImpression">
                <ng-template #popContentImpression class="qb-background-ng-template">
                  <div class="qb-ng-template-text">
                    <div class="qb-d-inline-flex">{{'COMMUNICATION_PAGE.NEW_FOLLOWERS_ANALYTICS' | i18nTranslate}}</div>
                  </div>
                </ng-template>
                  <div class="qb-i">i</div>
                </div>
                </div>
            </div>
          </div>
          <div style="height:calc(100% - 110px);overflow:auto">
          <div
            class="qb-display-flex qb-block-hieght-40"
            *ngFor="let account of socialChannels; let last = last"
          >
          <div class="qb-social-channel-value qb-display-flex-center">
            {{ account.channel_name }}
          </div>
            <div class="qb-social-channel-value qb-display-flex-center" *ngIf="account.channel === 'Facebook'">
              <img
              style="height: 20px; width: 20px"
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/facebook.png"
              alt=""
            />
            </div>
            <div class="qb-social-channel-value qb-display-flex-center" *ngIf="account.channel === 'Instagram'">
              <img
              style="height: 20px; width: 20px"
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Instagram.png"
              alt=""
            />
            </div>
            <div class="qb-social-channel-value qb-display-flex-center">
              {{ account.total_followers }}
            </div>
            <div class="qb-social-channel-value qb-display-flex-center" *ngIf="account.new_followers !== null">
              {{ account.new_followers }}
            </div>
            <div class="qb-social-channel-value qb-display-flex-center" *ngIf="account.new_followers === null">
              NA
            </div>
            <div class="qb-social-channel-value qb-display-flex-center">
              {{ account.no_of_posts }}
            </div>
            <div class="qb-social-channel-value qb-display-flex-center">
                {{ account.reach }}
            </div>
            <div style="padding: 0px 0px 0px 10px;" class="qb-social-channel-value qb-display-flex-center cursor-pointer"  [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" (click)="deleteChannel(account.id, account.channel_name, account.channel)">
              <div class="material-icons-outlined icons-primary cursor-pointer">delete</div>
            </div>
          </div>
          
          </div>
          <div style="display: flex;width:100%;justify-content: flex-end;" *ngIf="socialChannels.length>0"> <div style="cursor: pointer;" (click)="openConnectSocialProfilesDialog(false)">+ {{'COMMUNICATION_PAGE.ADD_CHANNEL' | i18nTranslate}}</div></div>
        </span>
        <span *ngIf="dataType === 'adAccount'">
          <!-- <div class="qb-display-flex qb-channel-block">
            <div
              class="qb-social-channel-title-ad"
              *ngFor="let tableName of adAccountTableData; let last = last"
            >
            <div>
              {{ tableName }}
              </div>
            </div>
          </div>
          <div style="height:calc(100% - 110px);overflow:auto">
            <div
              class="qb-display-flex qb-block-hieght-40"
              *ngFor="let account of adAccount; let last = last"
            >
            <div class="qb-social-channel-value-ad qb-display-flex-center">
              <img
              style="height: 20px; width: 20px"
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/facebook.png"
              alt=""
            />
            </div>
            <div class="qb-social-channel-value-ad qb-display-flex-center">
              {{ account.id }}
            </div>
            <div class="qb-social-channel-value-ad qb-display-flex-center">
              {{ account.name }}
            </div>
            <div style="padding: 0px 0px 0px 10px;" class="qb-social-channel-value-ad qb-display-flex-center cursor-pointer" [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" (click)="deleteAdAccount(account.id, account.name)">
              <div class="material-icons-outlined icons-primary">delete</div>
            </div>
            </div>
          </div> -->
          <div class="semi-big-text center-it">
            Your Ad Account
          </div>
          <div class="social-active-card" *ngFor="let account of adAccount; let last = last">
            <div class="row-sp-2">
              <div class="heading-pair gap-7">
                <label class="label-style-2">Account Id</label>
                <div>
                  {{account.id}}
                </div>
              </div>
              <div [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" (click)="deleteAdAccount(account.id, account.name)">
                <div class="material-icons-outlined icons-primary cursor-pointer">delete</div>
              </div>
            </div>
            <div class="flex-100-center margin-top-13">
              <img
              style="height: 85px; width: 85px"
              src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png"
              alt=""
              />
            </div>
            <div class="heading-pair gap-7 margin-top-13">
              <label class="label-style-2">Account Name</label>
              <div>
                  {{account.name}}
              </div>
            </div>
          </div>
        </span>
      </div>
      <div
        *ngIf="(socialChannelsLoading && dataType ==='socialChannel') || (dataType ==='adAccount' && isAdAccountLoading)"
        class="qb-display-flex-center qb-flex-column qb-h-100p margin-top-12"
      >
        <app-qubriux-loading [size]="65" [loadingText]="'Fetching your details..'"></app-qubriux-loading>
      </div>
    </div>

    <div class="qb-bck-white qb-padding-15 qb-flex-1 qb-border-radius-5" style="height: calc(100% - 257px);" *ngIf="validWidgets | widgetConfig: 52">
      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.YOUR_RECENT_ACTIVITY' | i18nTranslate}}</div>
      <div *ngIf="postAnalyticsObj.length == 0 && !recentPostLoading">
        <div class="qb-display-flex-center">
          <svg
            width="168"
            height="180"
            viewBox="0 0 168 180"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.7683 74.3464C50.8968 75.9441 49.9605 78.0204 48.8416 79.1893C47.4613 80.6273 46.0578 80.4043 45.4176 81.6751C44.6486 83.2131 46.2885 84.3397 45.4311 85.945C44.9043 86.9313 44.1814 86.6967 43.124 87.9502C41.8475 89.4748 43.0991 89.9093 42.1935 91.0263C39.2848 94.6118 38.1197 92.126 36.3491 93.068C34.0536 94.2888 32.5175 95.3327 31.3928 95.0886C26.971 94.1273 28.336 91.2243 26.202 90.1054C23.3047 88.5923 21.8898 82.4037 23.8777 80.2217C24.9197 79.0797 25.9482 78.9413 26.0463 77.9896C26.1847 76.617 24.1122 76.2171 23.9026 74.5656C23.6643 72.6931 26.1482 71.7856 25.779 70.2034C25.4176 68.6654 22.8664 68.7173 22.703 67.6157C22.455 65.945 27.8938 62.9536 33.5364 63.1381C36.2279 63.2266 40.6228 64.0994 41.3534 66.343C41.7764 67.6407 40.6786 68.477 41.2976 70.1534C41.7475 71.3723 42.7415 72.0529 44.0603 72.9315C45.3119 73.762 47.7708 71.5223 49.7683 74.3464Z"
              fill="#EEEEEE"
            />
            <path
              d="M36.2953 73.3429C37.1835 73.2967 37.6411 79.2123 37.1201 79.9871C36.5991 80.7619 35.6398 81.3329 35.5821 82.1807C35.5244 83.0285 35.8974 98.0281 36.2088 97.8108C36.5203 97.5936 39.1195 93.664 38.6004 92.7469C38.0814 91.8299 37.495 90.4168 37.6161 89.221C37.7372 88.0252 37.7391 86.3199 38.0756 86.3622C38.412 86.4045 38.1294 89.3383 38.362 89.9073C38.5947 90.4764 38.635 90.9224 38.9696 90.9321C39.3041 90.9417 39.3118 87.8368 39.8232 87.8906C40.3345 87.9444 39.9866 92.522 39.8097 93.3698C39.6328 94.2176 39.0407 97.1418 38.4812 98.2203C37.9218 99.2989 36.2434 100.425 36.2031 100.793C36.1627 101.16 36.4299 109.896 36.4299 109.896L32.0196 109.984C32.0196 109.984 33.6499 92.7584 33.2866 92.1855C32.9232 91.6126 30.622 86.9601 30.1279 86.4833C29.6338 86.0065 29.3416 83.1093 29.6011 83.067C29.8607 83.0247 30.0048 85.8527 30.547 85.8989C31.0891 85.945 31.3775 80.4274 31.7697 80.4754C32.1619 80.5235 31.3852 86.2007 31.6005 86.5506C31.8158 86.9005 33.0424 89.0018 33.4519 88.9576C33.8614 88.9134 33.227 73.4659 33.5769 73.291C33.9268 73.116 34.1902 73.2621 34.4074 74.877C34.6247 76.492 35.0399 80.0256 35.2822 80.0217C35.5244 80.0179 36.1281 79.4834 36.1415 79.1297C36.155 78.7759 35.9012 73.3621 36.2953 73.3429Z"
              fill="#BDBDBD"
            />
            <path
              d="M131.879 90.884C131.239 91.7876 131.768 92.9622 132.404 93.6255C133.185 94.4387 133.981 94.3138 134.342 95.0328C134.78 95.9018 133.848 96.54 134.342 97.4494C134.64 98.0088 135.05 97.8762 135.644 98.5837C136.365 99.4469 135.657 99.693 136.17 100.325C137.816 102.356 138.477 100.946 139.479 101.479C140.779 102.171 141.648 102.761 142.284 102.633C144.783 102.088 144.014 100.445 145.229 99.8103C146.869 98.9547 147.671 95.45 146.544 94.2157C145.956 93.5697 145.372 93.4909 145.318 92.9526C145.239 92.1836 146.412 91.9491 146.531 91.0128C146.666 89.9535 145.26 89.4402 145.47 88.5443C145.679 87.6484 147.117 87.7022 147.2 87.0774C147.34 86.1315 144.259 84.4377 141.067 84.5435C139.541 84.5935 137.055 85.0856 136.645 86.3583C136.405 87.0928 137.03 87.5657 136.676 88.5154C136.422 89.2056 135.859 89.5901 135.113 90.088C134.402 90.5514 133.01 89.2844 131.879 90.884Z"
              fill="#EEEEEE"
            />
            <path
              d="M139.506 90.3149C139.004 90.288 138.749 93.637 139.039 94.0754C139.329 94.5137 139.879 94.8444 139.915 95.3192C139.952 95.7941 139.737 104.29 139.56 104.163C139.383 104.036 137.912 101.815 138.214 101.296C138.516 100.777 138.839 99.9775 138.772 99.3008C138.704 98.6241 138.7 97.657 138.512 97.682C138.324 97.707 138.481 99.3662 138.349 99.6891C138.216 100.012 138.195 100.266 138.004 100.266C137.814 100.266 137.812 98.5126 137.522 98.5356C137.232 98.5587 137.43 101.158 137.522 101.637C137.614 102.115 137.96 103.775 138.274 104.384C138.587 104.993 139.541 105.634 139.564 105.841C139.587 106.049 139.435 110.994 139.435 110.994L141.934 111.04C141.934 111.04 141.011 101.287 141.217 100.964C141.423 100.641 142.726 98.0031 143.005 97.7339C143.284 97.4648 143.451 95.8229 143.303 95.7999C143.155 95.7768 143.074 97.3783 142.769 97.4033C142.463 97.4283 142.297 94.3061 142.074 94.3272C141.851 94.3484 142.29 97.5686 142.171 97.7666C142.051 97.9646 141.355 99.1547 141.123 99.1297C140.89 99.1047 141.252 90.3591 141.052 90.2592C140.852 90.1592 140.704 90.2438 140.583 91.1589C140.461 92.074 140.223 94.0715 140.087 94.0696C139.971 94.0314 139.866 93.9656 139.781 93.8779C139.696 93.7901 139.634 93.683 139.6 93.5659C139.592 93.3871 139.731 90.3245 139.506 90.3149Z"
              fill="#BDBDBD"
            />
            <path
              d="M83.9259 162.021C130.277 162.021 167.852 148.719 167.852 132.311C167.852 115.902 130.277 102.6 83.9259 102.6C37.5749 102.6 0 115.902 0 132.311C0 148.719 37.5749 162.021 83.9259 162.021Z"
              fill="url(#paint0_linear_68_5499)"
            />
            <path
              d="M86.5733 100.077C86.381 100.452 86.1753 100.927 86.1753 101.231C86.1753 101.535 86.133 102.663 86.1753 102.796C86.2176 102.929 87.5326 103.159 88.0728 103.115C88.2884 103.081 88.4946 103.003 88.6786 102.885C88.8625 102.768 89.0202 102.614 89.1418 102.433C89.1744 102.311 89.2513 100.614 89.1418 100.414C89.0322 100.214 86.5733 100.077 86.5733 100.077Z"
              fill="#F2DAD4"
            />
            <path
              d="M79.9271 100.668C79.8455 101.104 79.8286 101.551 79.8771 101.992C79.9271 102.185 80.1655 102.569 80.1655 102.923C80.1655 103.277 82.784 103.275 82.8878 103.048C82.9916 102.821 82.9954 102.215 82.9839 101.977C82.9724 101.739 83.2088 101.298 83.1358 101.062C83.0627 100.825 82.7628 100.368 82.484 100.306C82.2053 100.245 79.9271 100.668 79.9271 100.668Z"
              fill="#F2DAD4"
            />
            <path
              d="M75.5534 62.4307C75.0227 64.3801 74.5248 70.5053 74.8805 72.993C75.2361 75.4807 76.1917 82.2788 76.1917 85.2972C76.1917 88.3155 77.2317 93.2199 77.7297 95.0117C78.2276 96.8035 78.9293 100.756 79.3138 100.843C79.6983 100.929 80.3039 101.266 81.4286 101.054C82.5533 100.843 82.8782 100.56 82.8955 100.093C82.9128 99.6257 82.1015 94.4907 81.9765 93.2545C81.8516 92.0183 81.0153 86.8409 80.9883 86.1508C80.9614 85.4606 81.6593 83.0228 81.7304 81.5924C81.8016 80.1621 82.1707 75.0366 82.3841 74.3465C82.5975 73.6563 83.107 70.3534 83.3454 70.3361C83.5838 70.3188 84.8834 74.3291 85.0756 75.6192C85.2679 76.9092 86.258 85.3029 86.506 85.797C86.754 86.2911 86.4176 89.2441 86.258 90.338C86.0984 91.4319 86.0657 100.018 86.2407 100.229C86.4156 100.441 87.3942 100.881 88.0229 100.881C88.6515 100.881 89.6916 100.612 89.8473 100.287C90.0031 99.9622 89.7916 98.774 89.9608 98.1934C90.13 97.6128 91.7141 91.5473 91.7987 90.0804C91.8833 88.6135 91.4623 82.9959 91.3988 81.6213C91.3354 80.2467 91.9871 76.046 92.1332 74.9367C92.2793 73.8274 92.783 69.1941 92.783 68.104C92.783 67.0139 92.5735 63.6822 91.9237 62.9267C91.2738 62.1711 75.5534 62.4307 75.5534 62.4307Z"
              fill="#192B59"
            />
            <path
              d="M101.313 44.332C101.578 44.1512 102.013 43.8148 102.082 43.5802C102.151 43.3457 102.432 42.719 102.73 42.569C103.126 42.3993 103.531 42.2523 103.943 42.1287C104.231 42.0326 104.954 41.6116 105.097 41.552C105.239 41.4924 105.656 41.4136 105.771 41.6424C105.82 41.7942 105.812 41.9582 105.751 42.1051C105.689 42.2519 105.577 42.3721 105.435 42.444C105.133 42.5575 104.422 42.7862 104.489 42.9439C104.556 43.1015 105.235 43.2688 105.771 43.0035C106.308 42.7382 107.234 42.5036 107.452 42.3844C107.669 42.2652 109.265 41.6154 109.424 41.6154C109.506 41.6125 109.587 41.632 109.658 41.6716C109.73 41.7112 109.789 41.7696 109.83 41.8404C109.903 41.9922 110.38 41.6193 110.528 41.873C110.676 42.1268 110.335 42.2576 110.31 42.346C110.285 42.4344 110.503 42.5075 110.503 42.6959C110.503 42.8843 110.228 42.9881 109.782 43.1919C109.336 43.3957 107.315 44.6684 106.994 44.8376C106.441 45.1537 105.831 45.3551 105.198 45.4297C104.379 45.5028 103.386 45.5431 103.153 45.8392C102.92 46.1353 102.384 46.9197 102.23 46.9677C102.076 47.0158 101.163 47.16 100.938 46.6063C100.713 46.0526 101.313 44.332 101.313 44.332Z"
              fill="#F2DAD4"
            />
            <path
              d="M66.5386 42.917C66.2733 42.7363 65.8388 42.3979 65.7696 42.1634C65.7004 41.9288 65.4197 41.3021 65.1217 41.1521C64.7257 40.9832 64.3207 40.8363 63.9086 40.7119C63.6202 40.6157 62.8973 40.1947 62.7647 40.1351C62.632 40.0755 62.2052 39.9967 62.0899 40.2255C62.0415 40.3772 62.049 40.5413 62.1111 40.688C62.1732 40.8346 62.2857 40.9543 62.4282 41.0252C62.7282 41.1406 63.4395 41.3694 63.3722 41.5251C63.3049 41.6808 62.6263 41.85 62.0899 41.5866C61.5535 41.3232 60.6268 41.0868 60.4096 40.9656C60.1923 40.8445 58.5967 40.1966 58.4371 40.1966C58.3558 40.1945 58.2754 40.2143 58.2044 40.2539C58.1334 40.2934 58.0743 40.3513 58.0334 40.4216C57.9584 40.5735 57.4816 40.2024 57.3336 40.4543C57.1855 40.7061 57.5258 40.8503 57.5508 40.9272C57.5758 41.0041 57.3585 41.0887 57.3585 41.2771C57.3585 41.4655 57.6335 41.5712 58.0795 41.7731C58.5255 41.975 60.5461 43.2496 60.8672 43.4188C61.4196 43.7357 62.0303 43.9378 62.6628 44.0128C63.4818 44.084 64.4757 44.1244 64.7084 44.4204C64.941 44.7165 65.1102 45.0875 65.2543 45.1356C65.3985 45.1837 66.3213 45.3279 66.5463 44.7742C66.7712 44.2205 66.5386 42.917 66.5386 42.917Z"
              fill="#F2DAD4"
            />
            <path
              d="M81.3979 34.3349C80.2867 34.4694 75.911 35.0058 75.142 36.6073C74.373 38.2087 73.6905 42.0423 73.331 43.1208C72.9715 44.1993 72.2717 46.7313 71.966 46.7313C71.6603 46.7313 66.6291 42.8324 66.5214 42.8324C66.4138 42.8324 66.6464 43.7668 66.0523 44.3704C65.4583 44.9741 65.0295 44.8203 65.1372 45.1241C65.2449 45.4278 67.9768 48.7192 68.6958 49.6708C69.4148 50.6225 72.4851 53.1314 73.2579 53.1045C74.0308 53.0776 76.6377 49.2325 76.8358 49.2325C77.0338 49.2325 76.5916 55.3596 76.259 56.6573C75.9264 57.955 74.4345 63.6168 74.8363 64.1109C75.2381 64.605 79.5311 66.0738 82.8686 66.1661C86.2061 66.2584 92.9696 65.0299 93.0926 64.1647C93.2157 63.2996 91.7026 58.5337 91.7026 56.6188C91.7026 54.704 91.2759 47.8906 91.5546 47.8483C91.8334 47.806 93.7021 53.0391 95.5246 52.9334C97.3472 52.8276 101.1 49.0287 101.511 48.2982C101.923 47.5676 102.713 46.7967 102.5 46.689C102.286 46.5813 101.511 45.8739 101.511 45.3432C101.511 44.8126 101.577 44.1205 101.34 44.2282C101.104 44.3358 99.56 45.7085 98.916 46.0738C98.2719 46.4391 97.2414 47.0177 97.1126 46.8024C96.9838 46.5871 95.0017 42.8689 94.8517 42.1192C94.7018 41.3694 93.7155 36.3247 92.8773 35.8748C92.0391 35.4249 88.146 34.0292 87.4712 34.0292C86.7964 34.0292 81.3979 34.3349 81.3979 34.3349Z"
              fill="url(#paint1_linear_68_5499)"
            />
            <path
              d="M81.3286 32.9506C81.3286 33.4697 81.6055 34.1849 81.3978 34.3348C81.1902 34.4848 82.3783 37.7069 83.5318 37.7761C84.6853 37.8454 86.7424 34.6367 86.7886 34.2772C86.7963 33.7127 86.75 33.1487 86.6502 32.593C86.6194 32.5469 81.3286 32.9506 81.3286 32.9506Z"
              fill="url(#paint2_linear_68_5499)"
            />
            <path
              d="M79.7579 28.6442C79.5656 27.2561 79.1177 25.9526 79.4157 24.5896C79.7137 23.2265 80.6884 21.577 82.7013 21.3674C84.7141 21.1579 85.6716 21.9249 86.6636 23.2054C87.6556 24.4858 87.5249 28.5346 87.2653 28.7961C87.0058 29.0575 85.3024 29.8669 83.3838 29.8938C81.4651 29.9207 79.7579 28.6442 79.7579 28.6442Z"
              fill="#E64D4E"
            />
            <path
              d="M80.5499 30.7147C80.5499 30.7147 80.9517 32.9045 81.2343 33.141C81.5169 33.3774 83.0165 34.5771 83.9739 34.529C84.9313 34.481 86.8865 33.116 87.0038 32.3393C87.1211 31.5626 87.0038 30.0495 87.0788 30.0784C87.1538 30.1072 87.6844 30.1649 87.7863 29.344C87.8882 28.5231 87.8574 27.2388 87.5402 27.2292C87.223 27.2196 87.0653 28.4096 86.8923 28.4096C86.7193 28.4096 86.7001 26.6678 86.3886 26.2949C86.0772 25.9219 84.022 25.5105 83.7297 25.5105C83.4375 25.5105 82.111 27.2407 80.398 27.5137C80.398 27.5137 80.7441 28.8787 80.4903 28.898C80.2365 28.9172 79.8885 27.806 79.6521 27.806C79.4156 27.806 79.3618 28.9345 79.4598 29.6804C79.5579 30.4264 79.9885 30.8455 80.1346 30.8455C80.2807 30.8455 80.3922 30.6128 80.5499 30.7147Z"
              fill="#F2DAD4"
            />
            <path
              d="M89.3111 102.94C89.5494 103.292 89.5148 104.19 89.9801 105.055C90.4453 105.92 90.8068 106.666 90.826 107.281C90.8452 107.896 90.2012 108.9 89.3111 109.04C88.4209 109.181 87.4116 109.233 86.9617 108.998C86.5119 108.763 85.9755 108.354 85.9755 107.695C85.9755 107.035 86.0812 105.759 85.9755 105.539C85.8467 105.153 85.776 104.749 85.7659 104.342C85.7659 104.017 85.6852 103.057 85.8717 102.819C86.3096 102.382 86.8971 102.128 87.5154 102.108C88.2364 102.113 89.0996 102.642 89.3111 102.94Z"
              fill="url(#paint3_linear_68_5499)"
            />
            <path
              d="M79.8693 102.94C79.6309 103.292 79.6655 104.19 79.2003 105.055C78.735 105.92 78.3716 106.666 78.3543 107.281C78.337 107.896 78.9792 108.9 79.8693 109.04C80.7594 109.181 81.7687 109.233 82.2186 108.998C82.6685 108.763 83.2029 108.354 83.2029 107.695C83.2029 107.035 83.0972 105.759 83.2029 105.539C83.3333 105.153 83.4046 104.749 83.4144 104.342C83.4144 104.017 83.4933 103.057 83.3087 102.819C82.8703 102.382 82.282 102.127 81.663 102.108C80.9324 102.113 80.0712 102.642 79.8693 102.94Z"
              fill="url(#paint4_linear_68_5499)"
            />
            <path
              d="M74.2583 13.5678L41.2304 0.717328C40.2122 0.32116 39.0656 0.825424 38.6694 1.84364L28.8485 27.0849C28.4524 28.1031 28.9566 29.2497 29.9749 29.6459L63.0028 42.4964C64.021 42.8926 65.1676 42.3883 65.5638 41.3701L75.3846 16.1288C75.7808 15.1106 75.2765 13.964 74.2583 13.5678Z"
              fill="#EEEEEE"
            />
            <path
              d="M33.7075 20.7465L40.3902 3.57062C40.4467 3.42521 40.5314 3.29237 40.6393 3.17973C40.7473 3.06708 40.8763 2.97683 41.0192 2.91415C41.1621 2.85146 41.3159 2.81758 41.4719 2.81443C41.6279 2.81129 41.783 2.83894 41.9282 2.89582L72.2637 14.7001C72.4091 14.7566 72.542 14.8413 72.6546 14.9492C72.7673 15.0572 72.8575 15.1863 72.9202 15.3291C72.9829 15.472 73.0168 15.6258 73.0199 15.7818C73.0231 15.9378 72.9954 16.0929 72.9385 16.2381L66.2539 33.414L61.0746 18.4183C60.9845 18.1569 60.8375 17.9187 60.6442 17.721C60.4509 17.5233 60.2161 17.3709 59.9568 17.2749C59.6975 17.1789 59.4201 17.1417 59.1446 17.1658C58.8692 17.19 58.6025 17.275 58.3639 17.4147L47.5785 23.7321C47.3581 23.8615 47.1112 23.9394 46.8564 23.9599C46.6016 23.9804 46.3454 23.943 46.1071 23.8506C45.8687 23.7581 45.6544 23.613 45.4801 23.426C45.3057 23.2391 45.176 23.0151 45.1004 22.7709L43.5355 17.6377C43.4479 17.3497 43.2955 17.0856 43.09 16.8656C42.8845 16.6456 42.6314 16.4757 42.35 16.3687C42.0686 16.2618 41.7664 16.2208 41.4667 16.2488C41.167 16.2768 40.8776 16.3731 40.6209 16.5304L33.7075 20.7465Z"
              fill="#BDBDBD"
            />
            <path
              d="M53.3694 13.9696C53.1267 14.5929 52.7046 15.1303 52.1565 15.5137C51.6083 15.8971 50.9588 16.1093 50.29 16.1235C49.6212 16.1377 48.9633 15.9533 48.3994 15.5935C47.8354 15.2338 47.3908 14.7148 47.1219 14.1024C46.8529 13.4899 46.7716 12.8114 46.8883 12.1528C47.0049 11.4941 47.3143 10.8848 47.7773 10.402C48.2403 9.91919 48.836 9.58452 49.4892 9.44034C50.1424 9.29615 50.8237 9.34892 51.4469 9.59197C52.2819 9.91815 52.9532 10.5623 53.3137 11.3831C53.6741 12.2038 53.6942 13.1341 53.3694 13.9696Z"
              fill="#EEEEEE"
            />
            <path
              d="M161.765 178.657C161.758 178.557 161.788 178.457 161.849 178.378C161.88 178.345 161.917 178.32 161.959 178.303C162 178.286 162.045 178.279 162.09 178.282C162.136 178.28 162.182 178.287 162.225 178.303C162.268 178.32 162.307 178.345 162.34 178.378C162.398 178.459 162.429 178.555 162.429 178.655C162.429 178.754 162.398 178.851 162.34 178.932C162.274 178.991 162.188 179.023 162.099 179.023C162.011 179.023 161.925 178.991 161.859 178.932C161.793 178.856 161.759 178.757 161.765 178.657Z"
              fill="#777777"
            />
            <defs>
              <linearGradient
                id="paint0_linear_68_5499"
                x1="83.9259"
                y1="89.1326"
                x2="83.9259"
                y2="135.788"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E5E5E5" />
                <stop offset="0.48" stop-color="#F4F4F4" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_68_5499"
                x1="77.8105"
                y1="25.1586"
                x2="87.1924"
                y2="57.5301"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#4E9CFF" />
                <stop offset="0.98" stop-color="#176AD4" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_68_5499"
                x1="83.2088"
                y1="30.807"
                x2="84.7315"
                y2="36.0593"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F2BFAD" />
                <stop offset="1" stop-color="#F2DAD4" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_68_5499"
                x1="14988.5"
                y1="6971.47"
                x2="14894.7"
                y2="7204.54"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#4E9CFF" />
                <stop offset="0.98" stop-color="#176AD4" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_68_5499"
                x1="7628.53"
                y1="6971.47"
                x2="7722.29"
                y2="7204.56"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#4E9CFF" />
                <stop offset="0.98" stop-color="#176AD4" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div class="qb-social-card-subtitle qb-text-align-center">
          {{'COMMUNICATION_PAGE.NO_DATA_GRAPH_TEXT_QUIET_HERE' | i18nTranslate}}
        </div>
      </div>

      <div *ngIf="postAnalyticsObj.length > 0 && !recentPostLoading" style="    width: 100%;
        height: calc(100% - 20px);
        overflow: auto;">
        <div
          style="
            display: flex;
            width: 100%;
            gap: 8px;
           
          "
        >
          <div
            *ngFor="let post of postAnalyticsObj; let i = index"
            style="width: 33.33%; display: flex; flex-direction: column;    padding-right: 8px;
            border-right: 1px solid #e6e6e6;"
          >

          <div style="    margin: 12px 0 8px;    display: flex;
          justify-content: space-between;">
            <div *ngIf="post.channel === 'FACEBOOK'">
            <img
            style="height: 20px; width: 20px"
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/facebook.png"
            alt=""
          /> {{post.name}}
        </div>
        <div *ngIf="post.channel === 'INSTAGRAM'">
          <img
          style="height: 20px; width: 20px"
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Instagram.png"
          alt=""
        /> {{post.name}}
      </div>
          <mat-icon (click)="openPost(i, post.channel)" style="    cursor: pointer;
          color: rgba(34, 34, 34, 0.6);
          height: 18px;
          font-size: 18px;
          width: 18px;" [matTooltip]="'COMMON_PAGE.VIEW_POST' | i18nTranslate">launch</mat-icon>
          </div>
          <div class="qb-recent-post-date" >
           {{ post.post_details.launch_time}}
          </div>
            <div style="min-height: 180px; overflow-y: auto">
              <div
              class="qb-flex qb-block-height-70-min" 
              >
               
                <div >
                  <div  class="qb-post-content" style="height: 180px;">
                    <div >{{ post.post_details.contents.message }}</div>
      
                    <div style="display: flex;    gap: 10px;
                    ">
                      <div *ngFor="let imageurl of post.post_details.contents.images">
                        <img
                          [src]="imageurl"
                          alt=""
                          style="
                            height: 80px;
                            width: 80px;
                            object-fit: contain;
                            border: 1px solid rgba(99, 100, 102, 0.3);
                          "
                        />
                      </div>
                      <div *ngFor="let videoUrl of post.post_details.contents.video">
                        <video
                          style="
                            height: 80px;
                            width: 80px;
                            object-fit: contain;
                            border: 1px solid rgba(99, 100, 102, 0.3);
                          "
                        >
                          <source [src]="videoUrl" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
      
                <!-- <div
                class="qb-social-channel-value qb-display-flex-center qb-w-15 cursor-pointer qb-text-underline"
                (click)="(navigateToPostAnalytics)"
              >
              <span  *ngIf="post.launchTimestamp!=null"></span>
                {{ post.launchTimestamp | date }}
                {{ post.launchTimestamp | date: "HH:mm" }}
      
                <span *ngIf="post.launchTimestamp==null"> -</span>
              </div> -->
      
                
              </div>
      
            </div>

            <div >
              <div *ngIf="post.channel === 'FACEBOOK'" class="qb-recent-analitics-sub">
                <div>{{'COMMUNICATION_PAGE.REACTIONS' | i18nTranslate}}</div>
                <div>{{ post.post_analytics.reactions }}</div>
              </div>
              <div class="qb-recent-analitics-sub">
                <div>{{'COMMUNICATION_PAGE.COMMENTS' | i18nTranslate}}</div>
                <div>{{ post.post_analytics.comments }}</div>
              </div>
              <div class="qb-recent-analitics-sub">
                <div>{{'COMMUNICATION_PAGE.SHARES' | i18nTranslate}}</div>
                <div>{{ post.post_analytics.shares }}</div>
              </div>
              <div class="qb-recent-analitics-sub">
                <div>{{'COMMUNICATION_PAGE.ENGAGEMENT' | i18nTranslate}}</div>
                <div>{{ post.post_analytics.engagement }}</div>
              </div>
              <div *ngIf="post.channel === 'FACEBOOK'" class="qb-recent-analitics-sub">
                <div>{{'COMMUNICATION_PAGE.NEGATIVE_FEEDBACK' | i18nTranslate}}</div>
                <div>{{ post.post_analytics.negative_feedback }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="recentPostLoading">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
    </div>
  </div>
  <div style="display: flex; width: 30%; flex-direction: column; gap: 16px" *ngIf="validWidgets | widgetConfig: 52">
    <div class="qb-bck-white qb-padding-15 qb-border-radius-5">
      <div class="qb-social-card-title">{{'COMMUNICATION_PAGE.CREATE_NEW_POST' | i18nTranslate}}</div>

      <div class="qb-text-align-center">
        <svg
          width="178"
          height="137"
          viewBox="0 0 178 137"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            opacity="0.4"
            d="M116.437 1.42659C104.408 1.42659 93.2102 5.00632 83.8377 11.1642C77.4209 15.3794 69.123 15.3975 62.6834 11.2199C56.3821 7.12989 49.0509 4.93595 41.5524 4.89621C19.5942 4.74541 1.64523 22.7396 1.71775 44.834C1.73335 53.3265 4.46045 61.5892 9.49701 68.404C12.4856 72.4508 13.9861 77.4252 13.6917 82.4567C13.6823 82.6136 13.6738 82.7709 13.6662 82.9286C12.1609 113.335 37.2175 138.497 67.4349 136.931C76.6208 136.463 85.5087 133.498 93.153 128.352C99.8876 123.822 107.833 121.495 115.932 121.562C116.1 121.562 116.268 121.562 116.436 121.562C149.407 121.562 176.135 94.6685 176.135 61.4944C176.135 28.3203 149.408 1.42659 116.437 1.42659Z"
            fill="url(#paint0_linear_36_2294)"
          />
          <path
            d="M131.281 98.7537C135.04 97.787 141.678 94.9604 143.706 90.98C145.579 87.3052 141.952 83.2749 143.212 79.761C144.472 76.2472 154.077 72.8448 156.283 68.6888C158.898 63.7599 151.083 60.6896 154.09 57.1532C157.097 53.6168 168.18 48.8444 168.706 44.8884C169.233 40.9323 164.634 41.4762 167.841 33.9214C170.557 27.5248 174.216 19.1227 169.667 16.1745C164.818 13.0321 160.044 19.8139 157.715 24.9874C154.715 31.6516 150.323 25.3164 146.472 28.6498C142.989 31.6639 144.866 46.2057 142.305 49.3676C139.745 52.5294 134.024 50.8649 130.585 56.2551C127.145 61.6452 133.598 68.9321 131.939 72.8925C130.279 76.853 124.331 75.9491 121.95 81.5741C119.569 87.1991 131.281 98.7537 131.281 98.7537Z"
            fill="url(#paint1_linear_36_2294)"
          />
          <path
            d="M135.911 101.304C138.108 101.817 142.284 102.144 144.386 100.638C146.328 99.2467 145.529 96.1919 147.113 94.7184C148.697 93.245 154.555 94.075 156.797 92.5251C159.456 90.6868 156.241 87.0005 158.731 85.9856C161.22 84.9707 168.206 85.4912 169.53 83.5923C170.853 81.6935 168.336 80.7369 171.998 77.7038C175.099 75.1354 179.219 71.787 177.656 69.0417C175.991 66.1169 171.726 68.3297 169.15 70.3714C165.831 73.0013 165.25 68.5522 162.377 69.2349C159.78 69.854 156.883 77.8581 154.722 78.7992C152.56 79.7402 150.053 77.3434 146.846 79.1971C143.64 81.0509 145.031 86.5463 143.122 88.1423C141.214 89.7382 138.386 87.6722 135.663 89.9323C132.94 92.1925 135.911 101.304 135.911 101.304Z"
            fill="url(#paint2_linear_36_2294)"
          />
          <path
            d="M0.328846 55.3392C-3.53881 70.8526 28.0147 79.4426 27.8749 82.4983C27.7351 85.554 11.5165 82.9516 14.5218 91.3426C17.307 99.1185 42.6023 102.999 61.4545 94.8967C62.0003 94.6623 75.9353 72.0828 66.7391 58.2715C57.5429 44.4603 53.8739 67.5501 49.4151 66.7992C44.9563 66.0483 55.7176 37.9106 46.7833 31.5105C37.526 24.8772 33.9146 45.2094 29.5824 41.9237C25.4783 38.8119 24.2481 9.71547 12.5295 13.0812C12.079 13.2122 11.6392 13.378 11.2141 13.5773H11.2088C10.9688 13.6882 10.7249 13.8123 10.477 13.9497C-3.16435 21.5049 21.3091 52.942 15.4615 53.9193C9.61385 54.8966 2.28948 47.4775 0.328846 55.3392Z"
            fill="url(#paint3_linear_36_2294)"
          />
          <path
            d="M44.665 98.4813C44.665 98.4813 35.7923 73.3935 26.6272 59.3532C17.4622 45.3129 9.02588 41.3471 9.02588 41.3471C9.02588 41.3471 13.5427 47.5898 16.9673 56.1662C20.392 64.7425 22.1262 74.0051 26.8518 82.233C34.0008 94.6782 44.665 98.4813 44.665 98.4813Z"
            fill="url(#paint4_linear_36_2294)"
          />
          <path
            d="M41.1053 99.0584C41.1053 99.0584 32.4031 82.5996 24.6269 73.8216C16.8508 65.0437 10.6023 63.1669 10.6023 63.1669C10.6023 63.1669 14.3667 67.0243 17.6124 72.6104C20.8582 78.1965 23.0025 84.4321 27.1137 89.6427C33.3327 97.5248 41.1053 99.0584 41.1053 99.0584Z"
            fill="url(#paint5_linear_36_2294)"
          />
          <path
            d="M42.0455 73.4997H35.7341C35.1997 73.4997 34.7664 73.9356 34.7664 74.4734V76.7597C34.7664 77.2975 35.1997 77.7334 35.7341 77.7334H42.0455C42.58 77.7334 43.0132 77.2975 43.0132 76.7597V74.4734C43.0132 73.9356 42.58 73.4997 42.0455 73.4997Z"
            fill="url(#paint6_linear_36_2294)"
          />
          <path
            d="M68.5961 77.1276H45.1814C45.1814 77.1276 45.976 73.4749 46.1896 72.2473C46.5122 70.39 48.0417 68.9931 49.9158 68.9931H63.8604C65.7345 68.9931 67.2639 70.3896 67.5865 72.2473C67.801 73.4749 68.5961 77.1276 68.5961 77.1276Z"
            fill="url(#paint7_linear_36_2294)"
          />
          <path
            d="M65.0971 75.9239H48.6807C48.6807 75.9239 49.252 73.9207 49.3878 73.241C49.6489 71.9453 50.6866 70.9592 52.0003 70.9592H61.7775C63.0912 70.9592 64.1289 71.9453 64.39 73.241C64.5271 73.9207 65.0971 75.9239 65.0971 75.9239Z"
            fill="url(#paint8_linear_36_2294)"
          />
          <path
            d="M71.2044 74.8117H33.8406C30.5873 74.8117 27.95 77.4653 27.95 80.7387V103.092C27.95 106.365 30.5873 109.019 33.8406 109.019H71.2044C74.4577 109.019 77.0951 106.365 77.0951 103.092V80.7387C77.0951 77.4653 74.4577 74.8117 71.2044 74.8117Z"
            fill="url(#paint9_linear_36_2294)"
          />
          <path
            d="M52.5228 107.199C60.9119 107.199 67.7126 100.356 67.7126 91.9153C67.7126 83.4745 60.9119 76.6319 52.5228 76.6319C44.1337 76.6319 37.333 83.4745 37.333 91.9153C37.333 100.356 44.1337 107.199 52.5228 107.199Z"
            fill="url(#paint10_linear_36_2294)"
          />
          <path
            d="M35.031 80.9623C36.0251 79.9621 36.0251 78.3403 35.031 77.3401C34.0368 76.3398 32.425 76.3398 31.4309 77.3401C30.4368 78.3403 30.4368 79.9621 31.4309 80.9623C32.425 81.9626 34.0368 81.9626 35.031 80.9623Z"
            fill="url(#paint11_linear_36_2294)"
          />
          <path
            d="M38.0274 79.8919C38.8058 79.8919 39.4369 79.257 39.4369 78.4737C39.4369 77.6905 38.8058 77.0555 38.0274 77.0555C37.249 77.0555 36.6179 77.6905 36.6179 78.4737C36.6179 79.257 37.249 79.8919 38.0274 79.8919Z"
            fill="url(#paint12_linear_36_2294)"
          />
          <path
            d="M52.5228 106.521C60.54 106.521 67.0392 99.9819 67.0392 91.9153C67.0392 83.8487 60.54 77.3094 52.5228 77.3094C44.5056 77.3094 38.0063 83.8487 38.0063 91.9153C38.0063 99.9819 44.5056 106.521 52.5228 106.521Z"
            fill="url(#paint13_linear_36_2294)"
          />
          <path
            d="M65.1454 91.9153C65.1454 92.0414 65.1436 92.1667 65.1401 92.2911C65.046 95.5944 63.6748 98.7304 61.3182 101.032C58.9615 103.334 55.8054 104.62 52.521 104.616C45.5491 104.616 39.8979 98.9301 39.8979 91.9153C39.8979 88.6119 41.177 85.4384 43.464 83.0683C45.7509 80.6982 48.8657 79.3178 52.1474 79.2202C52.2714 79.2166 52.3959 79.2149 52.521 79.2149C59.4942 79.2149 65.1454 84.9009 65.1454 91.9153Z"
            fill="#03053E"
          />
          <path
            d="M56.562 91.9153C56.562 91.9555 56.562 91.9958 56.562 92.0356C56.5386 92.8288 56.285 93.5977 55.8324 94.2476C55.3798 94.8974 54.748 95.3997 54.0149 95.6925C53.2819 95.9853 52.4797 96.0558 51.7074 95.8952C50.935 95.7346 50.2263 95.3501 49.6686 94.7889C49.1109 94.2278 48.7287 93.5147 48.5691 92.7376C48.4096 91.9605 48.4796 91.1534 48.7706 90.4158C49.0616 89.6783 49.5608 89.0426 50.2067 88.5872C50.8525 88.1318 51.6168 87.8766 52.4051 87.8531C52.4447 87.8531 52.4847 87.8531 52.5246 87.8531C53.5953 87.8535 54.622 88.2816 55.3791 89.0433C56.1361 89.805 56.5616 90.8381 56.562 91.9153Z"
            fill="url(#paint14_linear_36_2294)"
          />
          <path
            opacity="0.7"
            d="M64.3461 92.2381C64.2719 94.9191 63.2936 97.495 61.5719 99.5426L44.9441 82.8105C46.9787 81.0786 49.5381 80.0943 52.2021 80.0192L64.3461 92.2381Z"
            fill="url(#paint15_linear_36_2294)"
          />
          <path
            opacity="0.5"
            d="M59.8521 101.215C58.951 101.93 57.9508 102.51 56.8832 102.934L41.5713 87.5281C41.9934 86.4539 42.5693 85.4475 43.2805 84.5409L59.8521 101.215Z"
            fill="url(#paint16_linear_36_2294)"
          />
          <path
            d="M72.8455 77.0343H66.5342C65.9997 77.0343 65.5664 77.4703 65.5664 78.0081V80.2943C65.5664 80.8321 65.9997 81.2681 66.5342 81.2681H72.8455C73.38 81.2681 73.8133 80.8321 73.8133 80.2943V78.0081C73.8133 77.4703 73.38 77.0343 72.8455 77.0343Z"
            fill="url(#paint17_linear_36_2294)"
          />
          <path
            d="M74.2034 108.394C74.2152 108.665 73.7841 109.018 73.1573 109.119C72.5798 109.212 72.1662 108.946 72.1368 108.673C72.0704 108.063 74.1849 107.974 74.2034 108.394Z"
            fill="#EFBC90"
          />
          <path
            d="M66.3888 103.118L68.2554 108.837L67.7086 108.488L72.025 108.185L72.4166 108.157L72.493 108.92L72.1032 108.971L67.8159 109.537C67.7033 109.552 67.589 109.528 67.4915 109.469C67.3941 109.411 67.3191 109.321 67.2788 109.214L67.2691 109.188L65.1476 103.559L64.9138 102.94L66.1849 102.488L66.3888 103.118Z"
            fill="#EFBC90"
          />
          <path
            d="M70.3252 108.023L70.4794 110.441C70.4794 110.441 67.7743 110.281 66.8952 109.209C65.662 107.706 64.056 103.43 65.4075 102.781C66.9089 102.059 66.9941 105.751 68.107 107.416C68.5838 108.13 70.3252 108.023 70.3252 108.023Z"
            fill="url(#paint18_linear_36_2294)"
          />
          <path
            d="M66.9518 97.3439C65.7713 96.4926 64.35 97.0728 64.161 98.3022C63.9162 99.8977 64.2256 101.182 63.8318 102.241C63.2209 103.881 63.3923 105.493 63.3923 105.493C65.0031 103.282 66.6679 104.198 67.5869 101.979C67.8805 101.267 68.0831 98.1598 66.9518 97.3439Z"
            fill="#03053E"
          />
          <path
            d="M66.482 102.719H65.0725V100.006H65.934L66.482 102.719Z"
            fill="#EFBC90"
          />
          <path
            d="M67.5231 98.632C67.7609 99.746 67.5851 100.869 66.917 101.112C66.2903 101.341 65.203 100.737 64.705 99.6354C64.1811 98.4764 64.4369 97.6101 65.3643 97.2877C66.3505 96.945 67.2616 97.4115 67.5231 98.632Z"
            fill="#F4CAAB"
          />
          <path
            d="M69.3924 125.752L68.4448 118.848L67.4458 111.952L67.2648 110.705L64.6545 111.271L65.0009 112.483L66.9154 119.175L68.7969 125.877L68.8795 126.172L69.4342 126.056L69.3924 125.752Z"
            fill="#EFBC90"
          />
          <path
            d="M63.664 110.705L63.62 111.965L63.3673 119.292L60.1857 124.064L60.0156 124.319L60.4551 124.669L60.6621 124.444L64.7284 120.017C64.8042 119.935 64.8542 119.832 64.8722 119.721L64.8897 119.615L66.1089 112.216L66.3142 110.972L63.664 110.705Z"
            fill="#EFBC90"
          />
          <path
            d="M64.1433 110.472H67.8404L69.4354 123.416L67.284 123.731L64.1433 110.472Z"
            fill="url(#paint19_linear_36_2294)"
          />
          <path
            d="M63.1287 110.472H66.6807C66.6807 110.472 66.0303 118.746 65.5002 119.718C64.8647 120.884 62.349 123.59 62.349 123.59L60.6406 122.264C60.6406 122.264 62.44 119.699 62.8751 118.497C63.3102 117.295 63.1287 110.472 63.1287 110.472Z"
            fill="url(#paint20_linear_36_2294)"
          />
          <path
            d="M65.0388 102.488C65.0388 102.488 65.7987 103.158 66.5586 102.712C66.5586 102.712 66.8188 105.505 67.1585 106.644C67.4983 107.784 68.3812 110.682 68.3812 110.682L62.9753 110.778C62.8263 108.228 63.784 105.331 65.0388 102.488Z"
            fill="#76D3F4"
          />
          <path
            d="M65.5857 104.326C65.2315 104.992 64.8896 105.686 64.5749 106.378C64.4193 106.725 64.2703 107.074 64.1354 107.423C64.0008 107.763 63.8869 108.112 63.7943 108.466C63.7737 108.551 63.757 108.632 63.742 108.715L63.7003 108.989C63.6757 109.175 63.6528 109.362 63.6339 109.551C63.5952 109.929 63.5653 110.312 63.5399 110.696C63.4911 111.463 63.4577 112.239 63.4392 113.008L63.43 113.38L62.7118 113.385L62.7 113.013C62.6745 112.228 62.6635 111.45 62.6692 110.664C62.6727 110.271 62.6811 109.878 62.6991 109.481C62.7074 109.283 62.7206 109.083 62.7356 108.883L62.7646 108.578C62.7778 108.469 62.7949 108.357 62.8147 108.254C62.8972 107.851 63.0043 107.453 63.1355 107.064C63.2608 106.682 63.3992 106.309 63.5416 105.94C63.8308 105.2 64.1393 104.483 64.4773 103.76L64.741 103.194L65.8754 103.776L65.5857 104.326Z"
            fill="#F4CAAB"
          />
          <path
            d="M64.8479 102.162C64.8479 102.162 62.2078 106.136 62.7453 110.374C62.7453 110.374 62.5185 111.206 65.5142 111.093C68.5099 110.979 68.6817 110.562 68.6817 110.562C67.7333 108.288 66.9962 105.714 66.6305 102.614C66.6292 102.615 65.5107 102.657 64.8479 102.162Z"
            fill="url(#paint21_linear_36_2294)"
          />
          <path
            d="M64.5636 111.271L62.2549 111.558C62.2549 111.558 62.3463 108.918 62.6887 107.405C63.119 105.504 63.9219 102.673 65.3697 103.058C67.2618 103.562 64.5166 107.378 64.3184 108.424C64.1588 109.269 64.5636 111.271 64.5636 111.271Z"
            fill="url(#paint22_linear_36_2294)"
          />
          <path
            d="M62.6609 115.492C62.4144 115.429 62.2364 114.832 62.3476 114.081C62.4495 113.388 62.8192 112.985 63.0728 113.028C63.6406 113.123 63.0424 115.588 62.6609 115.492Z"
            fill="#F4CAAB"
          />
          <path
            d="M63.395 114.538C63.5057 114.521 63.6064 114.228 63.5862 113.843C63.5677 113.486 63.4205 113.259 63.3075 113.265C63.0553 113.278 63.224 114.563 63.395 114.538Z"
            fill="#F4CAAB"
          />
          <path
            d="M71.5575 126.419L71.1215 126.341L69.5274 125.722V125.735C69.5274 125.735 69.508 126.01 69.1758 126.061C68.9318 126.099 68.7736 126.034 68.7073 125.997C68.6977 125.986 68.6852 125.978 68.6712 125.974C68.5649 125.974 68.4783 126.151 68.4783 126.365C68.478 126.431 68.4867 126.496 68.5042 126.56V126.86H71.6801V126.556C71.6805 126.522 71.6681 126.489 71.6453 126.464C71.6226 126.438 71.5913 126.422 71.5575 126.419Z"
            fill="#03053E"
          />
          <path
            d="M74.5353 105.879L74.2502 105.762L72.8587 109.209L73.1438 109.325L74.5353 105.879Z"
            fill="#03053E"
          />
          <path
            d="M61.8135 126.379L61.5432 126.025L60.7798 124.486L60.771 124.495C60.771 124.495 60.5728 124.686 60.2937 124.5C60.0876 124.363 60.0142 124.207 59.99 124.135C59.9903 124.12 59.9863 124.106 59.9786 124.094C59.8999 124.021 59.7189 124.094 59.5743 124.252C59.53 124.301 59.4926 124.355 59.4631 124.414L59.2622 124.635L61.6105 126.786L61.814 126.561C61.8362 126.536 61.8484 126.503 61.8483 126.47C61.8483 126.436 61.8359 126.404 61.8135 126.379Z"
            fill="#03053E"
          />
          <path
            d="M73.5696 107.89C73.5199 107.782 73.1951 107.766 72.8088 107.902C72.4528 108.028 72.2669 108.247 72.3069 108.361C72.3956 108.616 73.647 108.058 73.5696 107.89Z"
            fill="#EFBC90"
          />
          <rect
            x="60.2124"
            y="96.9787"
            width="7.07605"
            height="9.02117"
            fill="url(#pattern0)"
          />
          <path
            d="M65.5341 99.7812C65.6885 99.7116 65.7462 99.5039 65.6631 99.3173C65.58 99.1308 65.3875 99.0361 65.2331 99.1057C65.0788 99.1754 65.021 99.383 65.1041 99.5696C65.1872 99.7561 65.3797 99.8508 65.5341 99.7812Z"
            fill="#F4CAAB"
          />
          <path
            d="M142.162 9.70662L98.6389 1.94575C98.0759 1.84528 97.4987 1.85739 96.9403 1.98139C96.3819 2.10539 95.8533 2.33885 95.3846 2.66843C94.9159 2.99802 94.5163 3.41727 94.2087 3.90225C93.901 4.38724 93.6914 4.92844 93.5916 5.49497L88.4494 34.6776C88.2483 35.8218 88.507 36.9994 89.1688 37.9516C89.8305 38.9038 90.8411 39.5526 91.9782 39.7551L105.435 42.1546L112.195 52.41L122.046 45.117L135.5 47.5169C136.637 47.7196 137.807 47.4595 138.754 46.7939C139.7 46.1283 140.345 45.1117 140.547 43.9677L145.689 14.785C145.891 13.641 145.633 12.4633 144.971 11.5109C144.31 10.5585 143.299 9.9095 142.162 9.70662Z"
            fill="url(#paint23_linear_36_2294)"
          />
          <path
            d="M110.04 9.32808L107.787 22.1169L105.247 21.6641L101.795 12.1388C101.762 12.3587 101.743 12.5035 101.737 12.5731C101.727 12.7605 101.707 12.9473 101.677 13.1325L100.328 20.7868L98.01 20.3733L100.263 7.58443L103.471 8.15931L106.467 16.473C106.496 16.0915 106.527 15.8039 106.561 15.6102L107.739 8.9208L110.04 9.32808Z"
            fill="url(#paint24_linear_36_2294)"
          />
          <path
            d="M121.241 13.8382L115.189 12.7588L114.727 15.3825L119.485 16.2293L119.062 18.6385L114.303 17.7903L113.811 20.582L119.863 21.6615L119.418 24.1914L110.339 22.572L112.592 9.78313L121.67 11.4025L121.241 13.8382Z"
            fill="url(#paint25_linear_36_2294)"
          />
          <path
            d="M138.711 14.4414L133.266 26.6607L130.34 26.1389L129.742 17.3061L126.235 25.4066L123.334 24.8888L122.315 11.5171L125.353 12.0588L125.731 20.1756C125.731 20.2143 125.74 20.3578 125.76 20.6063C125.77 20.6983 125.781 20.8356 125.794 21.0181L129.391 12.78L132.137 13.2696L132.757 22.2611C132.829 22.1063 132.939 21.8488 133.085 21.4886L136.263 14.0054L138.711 14.4414Z"
            fill="url(#paint26_linear_36_2294)"
          />
          <path
            d="M99.0134 30.5509L98.2662 34.8063L95.5549 34.323L97.5705 22.8661L102.058 23.6665C103.059 23.8433 103.807 24.0876 104.3 24.3992C104.793 24.7108 105.199 25.18 105.519 25.8068C105.838 26.435 105.927 27.1522 105.785 27.9582C105.684 28.5254 105.472 29.0666 105.16 29.5501C104.843 30.0481 104.454 30.4145 103.994 30.6495C103.534 30.8845 103.105 31.0186 102.706 31.0519C102.307 31.0846 101.702 31.0286 100.89 30.8839L99.0134 30.5509ZM99.2885 28.453L101.024 28.7626C101.685 28.8805 102.176 28.8094 102.498 28.5494C102.809 28.306 103.018 27.9542 103.085 27.5633C103.166 27.0986 103.083 26.7027 102.834 26.3755C102.585 26.0482 102.157 25.8305 101.55 25.7223L99.8234 25.4128L99.2885 28.453Z"
            fill="url(#paint27_linear_36_2294)"
          />
          <path
            d="M110.107 37.1018C108.574 36.8285 107.49 36.0379 106.855 34.7298C106.22 33.4217 106.048 31.9453 106.337 30.3006C106.63 28.6272 107.301 27.3069 108.35 26.3397C109.399 25.3724 110.709 25.0288 112.28 25.3089C113.869 25.5925 114.963 26.4032 115.561 27.741C116.159 29.0789 116.322 30.5177 116.051 32.0575C115.758 33.7193 115.094 35.0467 114.06 36.0396C113.026 37.0325 111.708 37.3866 110.107 37.1018ZM111.877 27.4965C110.478 27.2471 109.561 28.359 109.125 30.8321C108.689 33.3112 109.17 34.6754 110.568 34.9248C111.923 35.1663 112.818 34.0504 113.253 31.5772C113.69 29.097 113.231 27.7362 111.877 27.4947V27.4965Z"
            fill="url(#paint28_linear_36_2294)"
          />
          <path
            d="M126.226 30.573L123.823 30.9321C123.676 29.971 123.11 29.4024 122.123 29.2264C121.068 29.0386 120.481 29.2892 120.36 29.9782C120.335 30.1087 120.337 30.2431 120.367 30.3727C120.396 30.5022 120.453 30.624 120.533 30.73C120.696 30.9549 121.113 31.1996 121.783 31.464C122.902 31.9063 123.709 32.2959 124.206 32.6328C124.702 32.9698 125.088 33.4382 125.363 34.0382C125.637 34.6275 125.721 35.2886 125.602 35.9282C125.419 36.9633 124.866 37.7879 123.941 38.402C123.017 39.016 121.776 39.1844 120.22 38.907C119.053 38.6985 118.107 38.2542 117.382 37.5741C116.656 36.894 116.255 36.0044 116.18 34.9054L118.748 34.7833C118.836 35.9015 119.513 36.5734 120.78 36.7989C121.391 36.908 121.867 36.8703 122.208 36.6857C122.55 36.5012 122.749 36.2439 122.808 35.9141C122.866 35.5786 122.776 35.2942 122.537 35.061C122.298 34.8278 121.804 34.5606 121.055 34.2593C119.658 33.6991 118.698 33.1022 118.174 32.4683C117.649 31.8345 117.476 31.0145 117.654 30.0083C117.833 28.99 118.352 28.1967 119.211 27.6283C120.07 27.0599 121.131 26.8885 122.392 27.114C124.624 27.5096 125.902 28.6626 126.226 30.573Z"
            fill="url(#paint29_linear_36_2294)"
          />
          <path
            d="M136.019 29.7226L135.62 31.9885L132.8 31.4857L131.181 40.6767L128.478 40.1947L130.098 31.0037L127.261 30.4982L127.66 28.2319L136.019 29.7226Z"
            fill="url(#paint30_linear_36_2294)"
          />
          <path
            d="M68.9837 0.215361L39.4709 11.7917C38.6998 12.0941 38.0797 12.6924 37.7471 13.4549C37.4144 14.2174 37.3964 15.0816 37.697 15.8574L45.3646 35.6456C45.6653 36.4214 46.2598 37.0452 47.0175 37.3799C47.7752 37.7146 48.634 37.7327 49.405 37.4304L58.5296 33.8516L66.4667 37.5918L69.7937 29.433L78.9183 25.8541C79.3002 25.7045 79.6491 25.4806 79.945 25.1953C80.2409 24.91 80.4781 24.5688 80.6429 24.1912C80.8077 23.8137 80.8971 23.4071 80.9057 22.9949C80.9144 22.5826 80.8423 22.1726 80.6935 21.7884L73.0245 2.00014C72.7239 1.22432 72.1293 0.600429 71.3715 0.265718C70.6137 -0.0689927 69.7548 -0.0871067 68.9837 0.215361Z"
            fill="url(#paint31_linear_36_2294)"
          />
          <path
            d="M48.0263 22.0895L48.2311 23.119L47.3028 23.4816L47.7366 25.66L46.7636 26.0425L46.3315 23.8641L45.576 24.1604L46.008 26.3392L45.0556 26.7129L44.6218 24.5345L43.5727 24.9462L43.3679 23.9168L44.417 23.5051L44.2584 22.7091L43.2281 23.1128L43.0203 22.09L44.0544 21.6845L43.6496 19.6503L44.6073 19.2748L45.0108 21.309L45.7663 21.0127L45.3628 18.9786L46.3298 18.5987L46.7345 20.6329L47.6492 20.2742L47.8522 21.2989L46.9394 21.657L47.098 22.453L48.0263 22.0895ZM45.9698 22.0374L45.2143 22.3336L45.3725 23.1296L46.128 22.8333L45.9698 22.0374Z"
            fill="url(#paint32_linear_36_2294)"
          />
          <path
            d="M50.4343 20.2636L51.8939 24.0304L50.4994 24.5779L49.0398 20.8111L48.4768 21.0322L48.0413 19.909L48.6047 19.6879C48.4157 19.182 48.3095 18.8006 48.2861 18.5439C48.2626 18.2871 48.3524 18.0157 48.5555 17.7297C48.7585 17.4435 49.0943 17.2087 49.5628 17.0253C49.786 16.9403 50.0148 16.871 50.2476 16.8179L50.6791 17.9314C50.4673 17.9959 50.2941 18.0549 50.1597 18.1083C49.9636 18.1852 49.8536 18.2884 49.8296 18.4178C49.8056 18.5472 49.8385 18.7277 49.9285 18.9591L50.0001 19.1431L50.8791 18.7977L51.3142 19.9209L50.4343 20.2636Z"
            fill="url(#paint33_linear_36_2294)"
          />
          <path
            d="M53.5613 17.6258C54.3014 17.3357 54.9734 17.3528 55.5773 17.6771C56.1812 18.0014 56.6251 18.5301 56.909 19.2633C57.1894 19.9862 57.2076 20.6736 56.9635 21.3254C56.7194 21.9772 56.2401 22.4432 55.5254 22.7232C54.8333 22.9947 54.1871 22.9731 53.5868 22.6582C52.9864 22.3434 52.5413 21.8114 52.2515 21.0623C52.0502 20.5428 51.9729 20.0646 52.0195 19.6277C52.0616 19.2037 52.21 18.7973 52.4506 18.4466C52.6909 18.0954 53.0611 17.8219 53.5613 17.6258ZM53.9656 18.5545C53.6937 18.6612 53.5299 18.8472 53.4742 19.1125C53.4186 19.3779 53.5128 19.8252 53.7568 20.4547C53.9807 21.0325 54.2069 21.4216 54.4355 21.6221C54.664 21.8226 54.9174 21.8681 55.1958 21.7588C55.4393 21.6632 55.5944 21.4787 55.6612 21.2051C55.728 20.9315 55.6447 20.493 55.4112 19.8895C55.1624 19.2471 54.928 18.8373 54.7079 18.6602C54.4879 18.483 54.2405 18.4479 53.9656 18.5549V18.5545Z"
            fill="url(#paint34_linear_36_2294)"
          />
          <path
            d="M58.7558 21.3391L56.1948 14.7302L57.6289 14.1677L60.1895 20.7766L58.7558 21.3391Z"
            fill="url(#paint35_linear_36_2294)"
          />
          <path
            d="M61.3687 20.314L58.8059 13.7051L60.24 13.1426L62.8006 19.7515L61.3687 20.314Z"
            fill="url(#paint36_linear_36_2294)"
          />
          <path
            d="M64.0613 13.5075C64.8014 13.2171 65.4734 13.2342 66.0773 13.5588C66.6811 13.8833 67.125 14.4119 67.409 15.1445C67.6894 15.8674 67.7075 16.5548 67.4635 17.2066C67.2194 17.8584 66.74 18.3244 66.0254 18.6044C65.3333 18.876 64.6871 18.8543 64.0868 18.5394C63.4864 18.2246 63.0413 17.6927 62.7515 16.9439C62.5502 16.4245 62.4729 15.9463 62.5195 15.5094C62.5616 15.0852 62.7099 14.6786 62.9506 14.3278C63.1912 13.9772 63.5614 13.7038 64.0613 13.5075ZM64.4656 14.4361C64.1934 14.5428 64.0296 14.7287 63.9742 14.9937C63.9189 15.2588 64.0131 15.7063 64.2568 16.3363C64.4804 16.9138 64.7066 17.3028 64.9354 17.5033C65.1643 17.7038 65.4177 17.7493 65.6958 17.64C65.9393 17.5447 66.0944 17.3603 66.1612 17.0868C66.228 16.8132 66.1447 16.3745 65.9111 15.7707C65.6624 15.1286 65.4272 14.719 65.2057 14.5418C64.9842 14.3646 64.7375 14.3295 64.4656 14.4366V14.4361Z"
            fill="url(#paint37_linear_36_2294)"
          />
          <path
            d="M73.7079 9.84061L74.1338 15.3068L72.8702 15.8021L70.8823 12.9569L71.332 16.4062L70.1347 16.8754L66.7334 12.5766L68.2277 11.9898L70.1778 14.6661L69.7642 11.387L71.0612 10.8785L72.9375 13.6061L72.5991 10.2753L73.7079 9.84061Z"
            fill="url(#paint38_linear_36_2294)"
          />
          <path
            d="M80.1221 43.4658L60.2565 42.8621C59.7375 42.8464 59.2335 43.0388 58.8555 43.3968C58.4774 43.7549 58.2562 44.2494 58.2404 44.7716L57.8405 58.0925C57.8249 58.6147 58.016 59.1217 58.3719 59.5021C58.7278 59.8825 59.2193 60.1051 59.7383 60.1209L65.8808 60.3075L69.5516 64.4276L73.4632 60.5361L79.6053 60.7228C80.1239 60.7382 80.6275 60.546 81.0054 60.1882C81.3833 59.8305 81.6046 59.3364 81.6208 58.8146L82.0203 45.4938C82.0359 44.9716 81.8446 44.4646 81.4886 44.0843C81.1326 43.704 80.6411 43.4815 80.1221 43.4658Z"
            fill="url(#paint39_linear_36_2294)"
          />
          <path
            d="M73.5844 47.7752C73.5549 47.7628 73.525 47.7504 73.4965 47.7389C72.1221 47.2136 70.5918 48.2205 69.9967 49.6241C69.4864 48.1869 68.0189 47.0889 66.6165 47.5302C66.5857 47.5395 66.5549 47.5497 66.525 47.5607C64.8163 48.1661 64.1566 50.3909 65.1788 51.9713C66.3132 53.7238 69.7778 56.9144 69.7778 56.9144C69.7778 56.9144 73.4257 53.9401 74.6647 52.2597C75.7788 50.7446 75.2536 48.4832 73.5844 47.7752Z"
            fill="url(#paint40_linear_36_2294)"
          />
          <path
            d="M115.835 93.8579H117.245V91.1458H116.383L115.835 93.8579Z"
            fill="#EFBC90"
          />
          <path
            d="M114.795 89.7727C114.557 90.8862 114.733 92.0086 115.4 92.2523C116.027 92.4804 117.115 91.8764 117.612 90.7761C118.136 89.6171 117.88 88.7512 116.953 88.4288C115.967 88.0861 115.056 88.5526 114.795 89.7727Z"
            fill="#F4CAAB"
          />
          <path
            d="M116.76 88.0353C117.785 88.1158 118.352 89.0524 118.286 89.9744C118.225 90.8377 117.307 91.2985 117.307 91.2985C117.307 91.2985 117.005 90.4224 116.509 90.2813C115.817 90.0868 116.117 89.465 115.793 89.3969C115.457 89.3266 114.724 89.0577 114.738 88.5646C115.211 88.5837 115.609 87.9447 116.76 88.0353Z"
            fill="#03053E"
          />
          <path
            d="M116.659 90.4648C116.576 90.6514 116.633 90.8588 116.788 90.9287C116.943 90.9985 117.135 90.9035 117.218 90.7169C117.301 90.5303 117.243 90.3233 117.088 90.2535C116.934 90.1836 116.742 90.2787 116.659 90.4648Z"
            fill="#F4CAAB"
          />
          <path
            d="M116.76 90.2658C116.76 90.2658 116.683 91.4691 116.455 91.9263C116.256 92.3243 115.388 92.7351 114.916 91.7906C114.916 91.7906 115.395 91.8914 115.341 91.6875C115.277 91.4474 114.785 91.4448 114.785 91.4448C114.758 91.4435 114.733 91.4337 114.712 91.4168C114.692 91.3999 114.677 91.3769 114.671 91.351C114.645 91.2184 114.709 91.0039 114.709 91.0039C114.709 91.0039 115.361 91.2378 115.642 91.2378C115.96 91.2378 116.249 91.0118 116.521 90.265L116.76 90.2658Z"
            fill="#03053E"
          />
          <path
            d="M112.646 116.795L113.94 109.505L114.819 105.914L115.705 102.314L116.006 101.091L118.546 101.906L118.087 103.08L116.749 106.497C116.304 107.637 115.851 108.777 115.411 109.923L113.235 116.941L113.145 117.234L112.591 117.098L112.646 116.795Z"
            fill="#EFBC90"
          />
          <path
            d="M117.325 101.121L117.426 102.377L117.997 109.497L123.347 112.017L123.623 112.148L123.421 112.679L123.129 112.59L116.997 110.723C116.881 110.688 116.778 110.622 116.696 110.533C116.615 110.444 116.558 110.335 116.533 110.216L116.512 110.114L114.951 102.741L114.69 101.508L117.325 101.121Z"
            fill="#EFBC90"
          />
          <path
            d="M118.919 100.344L114.171 101.272L112.686 115.552L114.279 115.772L118.919 100.344Z"
            fill="url(#paint41_linear_36_2294)"
          />
          <path
            d="M119.15 101.296L114.36 100.623C114.36 100.623 115.094 109.563 116.527 110.705C117.325 111.34 121.757 112.407 121.757 112.407L122.235 111.038C122.235 111.038 118.899 109.68 118.62 109.26C118.315 108.801 119.15 101.296 119.15 101.296Z"
            fill="url(#paint42_linear_36_2294)"
          />
          <path
            d="M115.97 94.1653L112.83 98.6803L113.401 98.5202L109.698 97.1051L109.362 96.9764L109.095 97.6247L109.423 97.7733L113.034 99.4122C113.128 99.4551 113.235 99.4647 113.335 99.4394C113.436 99.414 113.526 99.3552 113.589 99.2724L113.605 99.2516L116.945 94.8834L117.313 94.4018L116.318 93.6669L115.97 94.1653Z"
            fill="#EFBC90"
          />
          <path
            d="M117.607 95.0736C117.145 96.2906 114.97 98.993 113.288 99.4843C112.289 99.7757 110.123 98.2461 110.123 98.2461L110.438 97.2652C110.438 97.2652 112.08 98.3619 112.961 97.5195C113.843 96.6771 115.407 93.9782 116.203 93.8292C116.905 93.6992 117.814 94.527 117.607 95.0736Z"
            fill="url(#paint43_linear_36_2294)"
          />
          <path
            d="M117.148 93.2848C117.148 93.2848 116.409 93.9371 115.67 93.5024C115.67 93.5024 115.417 96.2193 115.086 97.328C114.756 98.4366 113.847 101.291 113.847 101.291L119.157 101.349C119.3 98.8687 118.369 96.0491 117.148 93.2848Z"
            fill="#DBC6AF"
          />
          <path
            d="M117.404 93.0619C117.404 93.0619 119.972 96.9269 119.449 101.05C119.449 101.05 119.669 101.859 116.755 101.749C113.842 101.638 113.674 101.234 113.674 101.234C114.597 99.0225 115.314 96.517 115.67 93.5024C115.67 93.5024 116.759 93.5426 117.404 93.0619Z"
            fill="url(#paint44_linear_36_2294)"
          />
          <path
            d="M116.567 94.9772C116.933 95.6126 117.287 96.276 117.613 96.9384C117.776 97.2705 117.932 97.6053 118.074 97.9409C118.216 98.2671 118.338 98.6017 118.439 98.9429C118.462 99.0252 118.483 99.1035 118.498 99.1835L118.548 99.4488C118.577 99.6288 118.606 99.8097 118.63 99.9932C118.68 100.36 118.721 100.731 118.758 101.103C118.831 101.848 118.887 102.6 118.93 103.347L118.951 103.709L119.65 103.691V103.329C119.65 102.565 119.635 101.808 119.606 101.044C119.59 100.662 119.569 100.28 119.539 99.8942C119.524 99.7018 119.505 99.5081 119.484 99.3135L119.446 99.0186C119.43 98.9124 119.41 98.8059 119.387 98.705C119.294 98.3158 119.176 97.9328 119.035 97.5584C118.904 97.1913 118.756 96.8331 118.605 96.4785C118.3 95.7709 117.977 95.082 117.625 94.3899L117.35 93.8486L116.266 94.4509L116.567 94.9772Z"
            fill="#F4CAAB"
          />
          <path
            d="M117.835 93.7412C118.761 94.6512 119.294 96.7173 119.688 98.4339C120.082 100.151 119.762 102.757 119.762 102.757L118.7 102.803C118.7 102.803 118.64 100.15 118.221 99.0004C117.803 97.8507 115.964 95.3358 116.144 94.5411C116.303 93.8429 117.419 93.3326 117.835 93.7412Z"
            fill="url(#paint45_linear_36_2294)"
          />
          <path
            d="M107.75 96.7151C107.668 96.9499 107.949 97.3727 108.468 97.6256C108.945 97.8591 109.374 97.7353 109.472 97.5036C109.69 96.987 107.876 96.3516 107.75 96.7151Z"
            fill="#EFBC90"
          />
          <path
            d="M119.31 105.818C119.557 105.799 119.825 105.256 119.841 104.517C119.857 103.836 119.568 103.389 119.318 103.388C118.76 103.385 118.93 105.848 119.31 105.818Z"
            fill="#F4CAAB"
          />
          <path
            d="M118.763 104.782C118.659 104.748 118.611 104.452 118.693 104.084C118.769 103.745 118.947 103.553 119.054 103.576C119.294 103.631 118.923 104.835 118.763 104.782Z"
            fill="#F4CAAB"
          />
          <path
            d="M110.519 117.525C110.519 117.525 110.888 117.437 110.99 117.405C111.4 117.272 112.46 116.869 112.46 116.869C112.46 116.869 112.583 117.082 112.906 117.132C113.048 117.153 113.193 117.138 113.328 117.087C113.432 117.087 113.516 117.258 113.516 117.469C113.517 117.533 113.509 117.596 113.492 117.658V117.95L110.399 117.954V117.658C110.399 117.625 110.411 117.593 110.434 117.568C110.456 117.544 110.486 117.528 110.519 117.525Z"
            fill="#03053E"
          />
          <path
            d="M123.228 114.849C123.228 114.849 123.242 114.469 123.237 114.36C123.215 113.928 123.11 112.793 123.11 112.793C123.11 112.793 123.347 112.729 123.48 112.43C123.538 112.297 123.562 112.152 123.549 112.008C123.576 111.907 123.761 111.87 123.964 111.926C124.025 111.942 124.084 111.967 124.139 111.999L124.42 112.076L123.608 115.079L123.324 115.001C123.292 114.992 123.264 114.972 123.247 114.944C123.229 114.916 123.222 114.882 123.228 114.849Z"
            fill="#03053E"
          />
          <path
            d="M107.863 94.4878L107.584 94.5867L108.765 97.9566L109.044 97.8576L107.863 94.4878Z"
            fill="#03053E"
          />
          <path
            d="M108.421 96.4365C108.494 96.3547 108.783 96.4268 109.086 96.6492C109.365 96.8548 109.47 97.0959 109.405 97.1852C109.261 97.3846 108.31 96.563 108.421 96.4365Z"
            fill="#EFBC90"
          />
          <path
            d="M36.6724 64.7535L35.6035 64.4577L36.1661 62.4001L36.8192 62.581L36.6724 64.7535Z"
            fill="#EFBC90"
          />
          <path
            d="M38.3089 61.873C38.2588 62.7676 37.8927 63.5821 37.3354 63.6268C36.8128 63.6683 36.1131 62.982 35.9641 62.0423C35.8068 61.0535 36.1804 60.4504 36.9513 60.4004C37.7696 60.3478 38.3638 60.893 38.3089 61.873Z"
            fill="#F4CAAB"
          />
          <path
            d="M36.8688 61.8402C37.0407 61.5139 37.3813 61.429 37.6393 61.398C37.8973 61.3671 38.3153 61.4865 38.3662 61.2972C38.4172 61.1079 38.3117 60.4167 37.2569 60.2359C36.3019 60.0722 35.7046 60.6113 35.4664 61.2998C35.258 61.9021 35.6092 62.5942 36.0865 62.5009C36.5638 62.4076 36.6609 62.2356 36.8688 61.8402Z"
            fill="#03053E"
          />
          <path
            d="M36.6216 62.4516C36.7533 62.4324 36.8414 62.2879 36.8185 62.1288C36.7956 61.9698 36.6703 61.8564 36.5386 61.8756C36.407 61.8948 36.3189 62.0393 36.3418 62.1984C36.3647 62.3574 36.49 62.4708 36.6216 62.4516Z"
            fill="#F4CAAB"
          />
          <path
            d="M37.2148 59.3227C37.373 59.7322 37.152 60.3809 36.6465 60.5786C36.1411 60.7762 35.5425 60.4459 35.3843 60.0386C35.2261 59.6314 35.5979 59.3709 36.1033 59.1732C36.6087 58.9756 37.0561 58.9132 37.2148 59.3227Z"
            fill="#03053E"
          />
          <path
            d="M41.7972 69.7015C41.7876 69.897 41.4852 70.1216 41.0716 70.1521C40.6901 70.18 40.4383 69.962 40.4365 69.7643C40.4308 69.3229 41.8104 69.399 41.7972 69.7015Z"
            fill="#EFBC90"
          />
          <path
            d="M42.2406 71.8631L42.1637 71.8485L36.6549 70.8234L35.6854 70.643L35.4441 72.7444L36.429 72.7913L41.2078 73.0186L39.2739 77.6941L39.1816 77.9152L39.5851 78.1142L39.7038 77.9042L42.6366 72.7196C42.6682 72.6632 42.6905 72.602 42.7025 72.5383C42.7302 72.3871 42.6971 72.2311 42.6105 72.1045C42.5239 71.9778 42.3908 71.891 42.2406 71.8631Z"
            fill="#EFBC90"
          />
          <path
            d="M42.7746 72.1527L42.7047 72.1399L36.5925 71.0078L35.6256 70.8283L35.3738 72.9129L36.3574 72.9721L42.06 73.3157L42.3729 79.2387L42.3857 79.4797H42.837L42.8515 79.2392L43.2357 72.7444C43.2437 72.6062 43.2013 72.4697 43.1164 72.3608C43.0315 72.2518 42.91 72.1778 42.7746 72.1527Z"
            fill="#EFBC90"
          />
          <path
            d="M37.3792 70.3338C38.0776 71.4668 42.2296 71.0746 42.8291 71.9064C43.6272 73.0159 40.3476 77.3045 40.3476 77.3045L39.2559 76.6911C39.2559 76.6911 40.926 73.658 40.8605 73.44C40.7752 73.1636 36.2184 73.8185 34.9856 72.7718C34.0279 71.9585 34.5022 70.3338 34.5022 70.3338H37.3792Z"
            fill="url(#paint46_linear_36_2294)"
          />
          <path
            d="M37.0539 70.3338C37.6631 71.7255 42.6163 71.2112 43.3186 72.4246C43.8851 73.4033 43.3032 78.3729 43.3032 78.3729L41.9926 78.3371C41.9926 78.3371 42.0001 74.1059 41.7434 73.8189C41.5549 73.608 36.2966 74.0953 34.9847 73.1508C33.8609 72.3419 34.5012 70.3338 34.5012 70.3338H37.0539Z"
            fill="url(#paint47_linear_36_2294)"
          />
          <path
            d="M36.82 65.2041L37.5641 69.4746L37.2103 69.1691L40.3181 69.4012L40.6002 69.422L40.5765 69.9734L40.2934 69.9699L37.1774 69.9283C37.0953 69.9276 37.0159 69.899 36.9521 69.8472C36.8882 69.7953 36.8437 69.7232 36.8258 69.6427L36.8214 69.6228L35.8865 65.3903L35.7837 64.9238L36.7361 64.7336L36.82 65.2041Z"
            fill="#EFBC90"
          />
          <path
            d="M35.353 65.3037C35.2796 66.3256 35.9362 68.9811 36.9726 69.8943C37.5879 70.4365 39.636 70.0712 39.636 70.0712L39.7358 69.2664C39.7358 69.2664 38.2032 69.4985 37.8534 68.603C37.5035 67.7076 37.282 65.2599 36.7643 64.8884C36.3076 64.559 35.386 64.8447 35.353 65.3037Z"
            fill="url(#paint48_linear_36_2294)"
          />
          <path
            d="M35.6524 64.1764C35.6524 64.1764 33.5344 67.149 33.8421 70.4042C33.8421 70.4042 33.6478 71.0352 35.9425 71.0233C38.2371 71.0114 38.3782 70.6974 38.3782 70.6974C37.7088 68.9338 37.2091 66.9465 37.0061 64.5669C37.0065 64.5674 36.1473 64.5709 35.6524 64.1764Z"
            fill="url(#paint49_linear_36_2294)"
          />
          <path
            d="M36.3342 65.8697C36.0265 66.3561 35.7232 66.8656 35.4442 67.3732C35.3053 67.6288 35.1712 67.8862 35.0486 68.1453C34.9256 68.3967 34.8182 68.6554 34.7269 68.9201C34.7062 68.9838 34.6887 69.0448 34.6724 69.1071L34.6245 69.3137C34.595 69.4538 34.5665 69.5954 34.541 69.7386C34.4891 70.0252 34.4438 70.3135 34.4021 70.6067C34.3195 71.1891 34.2487 71.7786 34.1894 72.3645L34.1608 72.6475L33.6123 72.6095L33.625 72.3252C33.6519 71.7246 33.6892 71.1312 33.7393 70.5302C33.7653 70.2308 33.7947 69.9314 33.8316 69.6298C33.8496 69.479 33.8716 69.3274 33.8949 69.1753L33.9349 68.9449C33.9512 68.8622 33.9709 68.779 33.992 68.7003C34.0784 68.3977 34.1836 68.1009 34.3067 67.8115C34.4245 67.5276 34.5511 67.2512 34.6825 66.9779C34.9462 66.4309 35.224 65.902 35.5241 65.37L35.7593 64.9547L36.5904 65.4655L36.3342 65.8697Z"
            fill="#EFBC90"
          />
          <path
            d="M35.3804 64.8543C34.6209 65.5371 34.1309 67.1424 33.7621 68.4779C33.3934 69.8134 33.5551 71.8728 33.5551 71.8728L34.3902 71.9466C34.3902 71.9466 34.5291 69.8638 34.8974 68.9754C35.2657 68.0869 36.7978 66.1735 36.6835 65.5429C36.5811 64.9875 35.7214 64.5475 35.3804 64.8543Z"
            fill="url(#paint50_linear_36_2294)"
          />
          <path
            d="M33.5969 74.2921C33.4083 74.2448 33.2699 73.7889 33.3516 73.2136C33.4268 72.6829 33.7085 72.3733 33.9023 72.4052C34.3361 72.4751 33.8891 74.3642 33.5969 74.2921Z"
            fill="#EFBC90"
          />
          <path
            d="M34.1551 73.5585C34.2394 73.5457 34.3155 73.3228 34.2988 73.0279C34.283 72.7546 34.1696 72.5817 34.083 72.5856C33.89 72.5963 34.0241 73.5789 34.1551 73.5585Z"
            fill="#EFBC90"
          />
          <path
            d="M44.5731 79.7314L44.2299 79.6699L42.9759 79.1835V79.1936C42.9759 79.1936 42.961 79.4099 42.7004 79.4506C42.5753 79.4755 42.4454 79.4576 42.3316 79.3997C42.3242 79.3911 42.3144 79.3849 42.3035 79.382C42.2195 79.382 42.1519 79.5196 42.1519 79.6916C42.1516 79.7433 42.1584 79.7948 42.1721 79.8446V80.0808H44.6698V79.8415C44.6707 79.8142 44.6612 79.7876 44.6432 79.7671C44.6252 79.7466 44.6001 79.7339 44.5731 79.7314Z"
            fill="#03053E"
          />
          <path
            d="M40.7187 79.3931L40.4857 79.1322L39.7961 77.9705L39.79 77.9784C39.79 77.9784 39.6458 78.1394 39.4155 78.0103C39.3019 77.9524 39.2107 77.8581 39.1562 77.7423C39.1556 77.7308 39.1516 77.7197 39.1448 77.7104C39.0789 77.6587 38.9409 77.725 38.8371 77.859C38.8052 77.8994 38.7791 77.9441 38.7593 77.9917L38.6147 78.1779L40.5837 79.7256L40.7296 79.5373C40.7462 79.5162 40.7542 79.4897 40.7522 79.463C40.7501 79.4363 40.7382 79.4113 40.7187 79.3931Z"
            fill="#03053E"
          />
          <path
            d="M41.4496 69.3517C41.425 69.269 41.1951 69.2239 40.9059 69.281C40.6391 69.334 40.4844 69.4707 40.5007 69.556C40.538 69.7466 41.4869 69.4791 41.4496 69.3517Z"
            fill="#EFBC90"
          />
          <path
            d="M43.7543 66.9072C43.7303 66.8995 43.7051 66.8966 43.68 66.8988C43.6549 66.9009 43.6305 66.9081 43.6082 66.9197C43.5859 66.9314 43.566 66.9474 43.5498 66.9668C43.5337 66.9862 43.5215 67.0086 43.5139 67.0328L42.7667 69.4207H40.5169C40.4677 69.4231 40.4214 69.4444 40.3874 69.4803C40.3534 69.5161 40.3345 69.5637 40.3345 69.6133C40.3345 69.6628 40.3534 69.7105 40.3874 69.7463C40.4214 69.7822 40.4677 69.8035 40.5169 69.8059H42.9078C42.954 69.8058 42.9985 69.789 43.0333 69.7585C43.0681 69.7281 43.0908 69.686 43.0973 69.64L43.8791 67.1508C43.887 67.1266 43.89 67.101 43.8879 67.0756C43.8859 67.0502 43.8789 67.0255 43.8673 67.0028C43.8557 66.9801 43.8397 66.96 43.8203 66.9436C43.8009 66.9272 43.7785 66.9148 43.7543 66.9072Z"
            fill="url(#paint51_linear_36_2294)"
          />
          <path
            d="M42.7644 69.4176H42.8831L43.6113 67.0717H43.501L42.7644 69.4176Z"
            fill="white"
          />
          <path
            d="M106.75 65.7729V81.2592H84.605V65.7729C84.605 65.2568 84.4012 64.7619 84.0385 64.397C83.6759 64.0321 83.184 63.8271 82.6711 63.8271C82.1582 63.8271 81.6664 64.0321 81.3037 64.397C80.941 64.7619 80.7373 65.2568 80.7373 65.7729V85.5049C80.7373 85.8306 80.8011 86.153 80.9249 86.4539C81.0488 86.7547 81.2304 87.0281 81.4592 87.2583C81.6881 87.4886 81.9598 87.6712 82.2589 87.7958C82.5579 87.9204 82.8784 87.9845 83.202 87.9844H108.155C108.478 87.9845 108.799 87.9204 109.098 87.7958C109.397 87.6712 109.668 87.4886 109.897 87.2583C110.126 87.0281 110.308 86.7547 110.432 86.4539C110.556 86.153 110.619 85.8306 110.619 85.5049V65.7729C110.619 65.2568 110.416 64.7619 110.053 64.397C109.69 64.0321 109.198 63.8271 108.685 63.8271C108.431 63.827 108.18 63.8772 107.945 63.9749C107.71 64.0726 107.497 64.2159 107.317 64.3966C107.137 64.5772 106.995 64.7918 106.898 65.028C106.8 65.2641 106.75 65.5172 106.75 65.7729Z"
            fill="url(#paint52_linear_36_2294)"
          />
          <path
            d="M103.807 46.479L87.5233 46.494C85.8972 46.494 84.5844 47.5929 84.5857 48.9444L84.6156 81.9071C84.6156 83.2589 85.9341 84.3534 87.5576 84.3521L103.841 84.337C105.465 84.337 106.78 83.2386 106.779 81.8867L106.749 48.924C106.748 47.5722 105.431 46.4768 103.807 46.479Z"
            fill="url(#paint53_linear_36_2294)"
          />
          <path
            d="M105.493 49.2681L105.522 80.6379C105.522 80.8763 105.291 81.0691 105.005 81.0695L86.3881 81.0864C86.1024 81.0864 85.8703 80.8944 85.8699 80.6561L85.8413 49.2862C85.8413 49.0478 86.0729 48.855 86.359 48.8546L104.975 48.8373C105.262 48.8373 105.493 49.0302 105.493 49.2681Z"
            fill="white"
          />
          <path
            d="M93.2238 62.4036L100.406 72.395L99.1224 72.0563L107.359 68.7614L108.106 68.462L108.726 69.9023L107.998 70.2467L99.9763 74.0405C99.7658 74.1399 99.528 74.165 99.3016 74.1117C99.0751 74.0585 98.8731 73.9299 98.7281 73.7469L98.6925 73.7026L91.0706 64.0469L90.2307 62.9812L92.4318 61.3008L93.2238 62.4036Z"
            fill="#EFBC90"
          />
          <path
            d="M112.795 67.3887C112.983 67.9123 112.131 68.9625 110.649 69.6772C109.283 70.3365 108.111 70.1561 107.88 69.6444C107.359 68.5013 112.504 66.5786 112.795 67.3887Z"
            fill="#EFBC90"
          />
          <path
            d="M95.2087 50.8393C92.7883 49.0974 89.8778 50.2887 89.495 52.8076C88.9958 56.076 89.6326 58.7071 88.8274 60.8758C87.5797 64.2366 87.9291 67.5395 87.9291 67.5395C91.2254 63.0103 94.6373 64.8809 96.5162 60.3314C97.1179 58.8703 97.5266 52.5077 95.2087 50.8393Z"
            fill="#03053E"
          />
          <path
            d="M94.2562 61.8495L91.3686 61.8522L91.3638 56.2948L93.128 56.2931L94.2562 61.8495Z"
            fill="#EFBC90"
          />
          <path
            d="M96.3806 53.4771C96.8707 55.7576 96.5125 58.0584 95.1438 58.5586C93.86 59.0277 91.6309 57.7922 90.6094 55.5378C89.5344 53.1644 90.0565 51.3894 91.957 50.7269C93.9756 50.0234 95.8431 50.9768 96.3806 53.4771Z"
            fill="#F4CAAB"
          />
          <path
            d="M96.2937 81.0815L96.2471 80.7644L95.8744 78.2084L90.5278 79.3741L91.0192 81.0863L96.2937 81.0815Z"
            fill="#EFBC90"
          />
          <path
            d="M93.5451 81.0841L93.927 78.755L88.4978 78.2146L88.4108 80.7962L88.4011 81.0885L93.5451 81.0841Z"
            fill="#EFBC90"
          />
          <path
            d="M97.4688 81.0806L97.0531 77.7308L89.4795 77.7379L90.2759 81.0872L97.4688 81.0806Z"
            fill="url(#paint54_linear_36_2294)"
          />
          <path
            d="M94.3954 81.0832C94.5712 79.0933 94.6771 77.733 94.6771 77.733L87.4006 77.7397C87.4006 77.7397 87.4384 79.1202 87.463 81.0894L94.3954 81.0832Z"
            fill="url(#paint55_linear_36_2294)"
          />
          <path
            d="M103.941 69.5109L105.738 74.1338C105.738 74.1338 100.36 75.5153 97.9807 73.9738C94.6439 71.8118 88.8639 64.4727 91.1001 62.3585C93.5838 60.0108 96.0341 67.1627 99.2359 69.7161C100.609 70.8106 103.941 69.5109 103.941 69.5109Z"
            fill="url(#paint56_linear_36_2294)"
          />
          <path
            d="M91.2986 61.3794C91.2986 61.3794 92.8567 62.7503 94.4125 61.8345C94.4125 61.8345 94.9505 67.5559 95.6489 69.8899C96.3472 72.2239 98.1611 78.1593 98.1611 78.1593L87.0855 78.3658C86.777 73.1424 88.7332 67.2074 91.2986 61.3794Z"
            fill="#76D3F4"
          />
          <path
            d="M92.4226 65.1458C91.6988 66.51 90.9991 67.9318 90.3609 69.3495C90.0427 70.0606 89.7381 70.7752 89.4621 71.4925C89.1861 72.2097 88.9374 72.931 88.7655 73.6279C88.7216 73.8048 88.689 73.9684 88.6587 74.1387L88.5734 74.6999C88.5242 75.0811 88.4772 75.4636 88.4385 75.8496C88.3603 76.6248 88.2996 77.4089 88.2482 78.1934C88.1493 79.7654 88.0834 81.3534 88.046 82.9291L88.028 83.691L86.557 83.7043L86.5315 82.9428C86.4783 81.3349 86.4546 79.7385 86.4643 78.1292C86.4713 77.3249 86.488 76.5187 86.5236 75.7063C86.5407 75.3004 86.5675 74.8909 86.5974 74.4801L86.6563 73.8566C86.6831 73.6328 86.7179 73.4068 86.7583 73.1932C86.9244 72.3345 87.1591 71.5389 87.4136 70.7549C87.6681 69.9708 87.9498 69.2071 88.2429 68.4509C88.8341 66.9354 89.4648 65.4664 90.1561 63.9845L90.6963 62.8259L93.0212 64.015L92.4226 65.1458Z"
            fill="#F4CAAB"
          />
          <path
            d="M90.9077 60.7161C90.9077 60.7161 85.5062 68.8604 86.6155 77.5415C86.6155 77.5415 86.1522 79.2476 92.2895 79.0092C98.4268 78.7709 98.777 77.9179 98.777 77.9179C96.83 73.2604 95.3159 67.9884 94.5604 61.6399C94.5604 61.6377 92.2658 61.7248 90.9077 60.7161Z"
            fill="url(#paint57_linear_36_2294)"
          />
          <path
            d="M87.954 86.0674C88.1803 86.0338 88.386 85.4372 88.3443 84.6444C88.3052 83.9129 88.0037 83.4504 87.7729 83.461C87.2556 83.4902 87.6033 86.12 87.954 86.0674Z"
            fill="#F4CAAB"
          />
          <path
            d="M90.3415 79.3736L85.6133 79.9666C85.6133 79.9666 85.7952 74.5561 86.4923 71.458C87.3713 67.5634 89.0093 61.7607 91.9764 62.5482C95.8537 63.5768 90.2373 71.3978 89.8334 73.5404C89.5086 75.2721 90.3415 79.3736 90.3415 79.3736Z"
            fill="url(#paint58_linear_36_2294)"
          />
          <path
            d="M86.4562 88.0039C85.9507 87.8761 85.5846 86.6583 85.8088 85.1242C86.0158 83.7091 86.7717 82.8888 87.2908 82.9755C88.4506 83.1696 87.2371 88.2016 86.4562 88.0039Z"
            fill="#F4CAAB"
          />
          <path
            d="M92.6132 50.103C91.9565 50.103 91.5069 50.1631 90.9479 50.4567C90.0495 50.9264 88.8651 52.0487 88.6317 54.5844C88.4141 56.9502 89.3564 58.1071 87.3501 60.3402C85.0919 62.0689 81.0651 61.7774 81.5337 65.6508C82.0022 69.5242 87.107 69.0139 88.3187 67.0761C89.5305 65.1383 89.3925 63.4119 90.0662 62.1905C90.74 60.9691 91.9121 61.6833 92.4971 60.3358C93.1828 59.0676 91.9877 56.2259 91.7724 55.7368C91.9157 55.2596 92.1007 54.7365 92.8681 54.5251C94.5153 54.0718 95.8791 52.5015 95.8787 51.9961C95.8782 51.4906 94.3707 50.103 92.6132 50.103Z"
            fill="#03053E"
          />
          <path
            d="M92.2994 55.8451C92.6155 55.7022 92.7336 55.2767 92.563 54.8949C92.3925 54.5131 91.998 54.3194 91.6818 54.4624C91.3657 54.6054 91.2476 55.0308 91.4182 55.4126C91.5887 55.7945 91.9832 55.9881 92.2994 55.8451Z"
            fill="#F4CAAB"
          />
          <path
            d="M110.191 67.2198C110.027 67.0389 109.383 67.2109 108.713 67.7195C108.096 68.1887 107.869 68.7317 108.017 68.9294C108.348 69.3707 110.445 67.4988 110.191 67.2198Z"
            fill="#EFBC90"
          />
          <path
            d="M90.0198 87.9844H101.334V89.6113C101.334 89.8995 101.22 90.1759 101.018 90.3796C100.815 90.5834 100.541 90.6979 100.254 90.6979H91.0997C90.8133 90.6979 90.5386 90.5834 90.3361 90.3796C90.1336 90.1759 90.0198 89.8995 90.0198 89.6113V87.9844Z"
            fill="url(#paint59_linear_36_2294)"
          />
          <path
            d="M98.0228 90.6979H93.3306V105.767H98.0228V90.6979Z"
            fill="url(#paint60_linear_36_2294)"
          />
          <path
            d="M85.6681 115.96H82.1551C82.0924 115.96 82.0311 115.941 81.9791 115.906C81.9271 115.87 81.8868 115.82 81.8632 115.761C81.8397 115.703 81.8339 115.639 81.8468 115.577C81.8596 115.515 81.8905 115.458 81.9354 115.414L93.3322 104.199H98.0244L86.3586 115.677C86.1741 115.859 85.9263 115.96 85.6681 115.96Z"
            fill="url(#paint61_linear_36_2294)"
          />
          <path
            d="M105.685 115.96H109.198C109.261 115.96 109.322 115.941 109.374 115.906C109.426 115.87 109.466 115.82 109.49 115.761C109.514 115.703 109.519 115.639 109.506 115.577C109.494 115.515 109.463 115.458 109.418 115.414L98.021 104.199H93.3306L104.995 115.677C105.179 115.859 105.427 115.96 105.685 115.96Z"
            fill="url(#paint62_linear_36_2294)"
          />
          <path
            d="M47.0786 46.4489L37.889 44.9949C37.7701 44.9761 37.6487 44.981 37.5318 45.0094C37.4148 45.0378 37.3045 45.0891 37.2072 45.1604C37.11 45.2317 37.0276 45.3216 36.9649 45.4249C36.9022 45.5282 36.8603 45.643 36.8417 45.7626L35.8787 51.924C35.8411 52.1654 35.9004 52.4119 36.0433 52.6094C36.1863 52.8069 36.4014 52.9393 36.6412 52.9773L39.4827 53.4266L40.9467 55.5568L42.9899 53.9816L45.8309 54.4309C46.0709 54.469 46.3162 54.4095 46.5127 54.2656C46.7092 54.1217 46.8408 53.9052 46.8787 53.6637L47.8416 47.5018C47.8789 47.2605 47.8195 47.014 47.6765 46.8167C47.5335 46.6193 47.3185 46.487 47.0786 46.4489Z"
            fill="white"
          />
          <path
            d="M45.2691 50.0804L42.8563 47.256C42.8459 47.2438 42.8323 47.2348 42.817 47.2302C42.8017 47.2256 42.7854 47.2254 42.7701 47.2298C42.7547 47.2342 42.741 47.2429 42.7304 47.2549C42.7198 47.2669 42.7128 47.2817 42.7103 47.2976L42.5697 48.1966C40.6645 48.1409 39.0866 49.0094 38.1747 50.9609C38.1669 50.978 38.1645 50.997 38.1675 51.0155C38.1706 51.0341 38.1791 51.0513 38.1919 51.0649C38.2048 51.0786 38.2213 51.0881 38.2395 51.0922C38.2578 51.0964 38.2768 51.095 38.2942 51.0882C39.6892 50.5386 40.7642 50.5133 42.1179 51.0913L41.9654 52.0642C41.9629 52.0801 41.965 52.0964 41.9714 52.1112C41.9778 52.126 41.9883 52.1386 42.0016 52.1476C42.0149 52.1565 42.0305 52.1615 42.0465 52.1618C42.0625 52.1622 42.0783 52.1579 42.092 52.1495L45.2498 50.2038C45.2596 50.1973 45.2679 50.1889 45.2742 50.179C45.2805 50.1691 45.2847 50.158 45.2866 50.1464C45.2884 50.1348 45.2878 50.1229 45.2848 50.1116C45.2818 50.1002 45.2765 50.0896 45.2691 50.0804Z"
            fill="url(#paint63_linear_36_2294)"
          />
          <path
            d="M126.687 75.5117H135.005V77.255C135.005 77.4426 134.931 77.6226 134.799 77.7553C134.667 77.888 134.488 77.9625 134.302 77.9625H127.391C127.205 77.9625 127.026 77.888 126.894 77.7553C126.762 77.6226 126.688 77.4426 126.688 77.255V75.5126L126.687 75.5117Z"
            fill="url(#paint64_linear_36_2294)"
          />
          <path
            d="M131.892 77.9625H129.8V87.1309H131.892V77.9625Z"
            fill="url(#paint65_linear_36_2294)"
          />
          <path
            d="M124.082 109.44H123.388C123.348 109.44 123.308 109.43 123.273 109.412C123.237 109.394 123.206 109.368 123.183 109.336C123.159 109.304 123.143 109.267 123.136 109.227C123.129 109.188 123.132 109.147 123.143 109.109L129.801 87.1305H131.028L124.326 109.258C124.31 109.311 124.277 109.357 124.233 109.39C124.19 109.422 124.136 109.44 124.082 109.44Z"
            fill="url(#paint66_linear_36_2294)"
          />
          <path
            d="M137.609 109.44H138.303C138.343 109.44 138.383 109.43 138.418 109.412C138.454 109.394 138.485 109.368 138.508 109.336C138.532 109.304 138.548 109.266 138.555 109.227C138.561 109.187 138.559 109.147 138.547 109.109L131.89 87.1305H130.665L137.368 109.258C137.384 109.31 137.416 109.356 137.459 109.388C137.503 109.421 137.555 109.439 137.609 109.44Z"
            fill="url(#paint67_linear_36_2294)"
          />
          <path
            d="M130.232 87.1309H131.46V109.823C131.46 109.891 131.433 109.956 131.385 110.004C131.337 110.052 131.273 110.079 131.205 110.079H130.49C130.456 110.079 130.423 110.073 130.392 110.06C130.361 110.047 130.333 110.028 130.309 110.004C130.286 109.98 130.267 109.952 130.254 109.921C130.241 109.89 130.235 109.856 130.235 109.823V87.1309H130.232Z"
            fill="url(#paint68_linear_36_2294)"
          />
          <path
            d="M130.846 49.9845C123.949 49.9845 118.358 55.9 118.358 63.1974C118.358 70.4948 123.949 76.4108 130.846 76.4108C137.743 76.4108 143.335 70.4948 143.335 63.1974C143.335 55.9 137.744 49.9845 130.846 49.9845ZM130.846 71.6716C126.423 71.6716 122.836 67.8778 122.836 63.1974C122.836 58.517 126.423 54.7232 130.846 54.7232C135.269 54.7232 138.856 58.5174 138.856 63.1974C138.856 67.8774 135.27 71.6716 130.846 71.6716Z"
            fill="url(#paint69_radial_36_2294)"
          />
          <path
            d="M131.697 87.483H129.995C129.866 87.483 129.742 87.4314 129.651 87.3396C129.56 87.2478 129.508 87.1233 129.508 86.9934C129.508 86.8635 129.559 86.7389 129.651 86.647C129.742 86.5551 129.866 86.5035 129.995 86.5035H131.697C131.761 86.5035 131.825 86.5161 131.884 86.5408C131.943 86.5654 131.996 86.6015 132.042 86.647C132.087 86.6925 132.123 86.7465 132.147 86.806C132.171 86.8654 132.184 86.9291 132.184 86.9934C132.184 87.1233 132.133 87.2478 132.041 87.3396C131.95 87.4314 131.826 87.483 131.697 87.483Z"
            fill="url(#paint70_linear_36_2294)"
          />
          <path
            d="M31.5127 26.2366L20.1571 27.0547C20.0102 27.0653 19.8669 27.1048 19.7351 27.1711C19.6034 27.2375 19.486 27.3292 19.3896 27.4412C19.2931 27.5532 19.2196 27.6832 19.1731 27.8238C19.1266 27.9644 19.1081 28.1128 19.1186 28.2607L19.6605 35.8738C19.671 36.0216 19.7104 36.1658 19.7763 36.2983C19.8423 36.4307 19.9335 36.5488 20.0448 36.6458C20.1561 36.7428 20.2853 36.8168 20.425 36.8636C20.5647 36.9103 20.7122 36.9289 20.859 36.9183L24.3694 36.6654L26.6987 38.7982L28.7025 36.3532L32.2159 36.1002C32.3627 36.0896 32.5061 36.05 32.6377 35.9837C32.7694 35.9174 32.8867 35.8256 32.9831 35.7136C33.0795 35.6016 33.1531 35.4716 33.1995 35.3311C33.246 35.1905 33.2645 35.0421 33.254 34.8943L32.7099 27.2811C32.6887 26.9829 32.5507 26.7054 32.3262 26.5095C32.1017 26.3136 31.8091 26.2155 31.5127 26.2366Z"
            fill="white"
          />
          <path
            d="M23.1594 30.2727L24.7135 30.1591L24.9772 28.181L25.6998 28.1289L25.4361 30.1069L27.373 29.9676L27.6367 27.9891L28.3592 27.9374L28.0955 29.9154L28.9956 29.8504L29.0462 30.558L27.9997 30.6331L27.7685 32.3702L29.1675 32.2693L29.2176 32.9711L27.6736 33.0826L27.4099 35.0606L26.6873 35.1119L26.951 33.1339L25.0141 33.2732L24.7504 35.2516L24.0279 35.3038L24.2916 33.3253L23.3818 33.3908L23.3317 32.6885L24.3865 32.6125L24.6177 30.8786L23.2113 30.9798L23.1594 30.2727ZM25.3403 30.8264L25.1091 32.5603L27.046 32.421L27.2772 30.6866L25.3403 30.8264Z"
            fill="url(#paint71_linear_36_2294)"
          />
          <path
            d="M161.954 55.5316L153.772 52.8783C153.666 52.8439 153.555 52.8309 153.444 52.8399C153.333 52.849 153.225 52.8799 153.126 52.9309C153.027 52.982 152.939 53.0522 152.867 53.1374C152.795 53.2227 152.741 53.3215 152.707 53.428L150.949 58.9115C150.88 59.1266 150.899 59.3604 151.001 59.5615C151.103 59.7626 151.281 59.9145 151.495 59.9838L154.025 60.8037L155.058 62.9626L157.147 61.8195L159.677 62.6393C159.89 62.7086 160.123 62.6897 160.323 62.5866C160.523 62.4835 160.674 62.3048 160.743 62.0897L162.501 56.6035C162.535 56.497 162.548 56.3848 162.539 56.2733C162.53 56.1617 162.499 56.0531 162.448 55.9535C162.397 55.8539 162.327 55.7654 162.243 55.693C162.158 55.6206 162.06 55.5658 161.954 55.5316Z"
            fill="white"
          />
          <path
            d="M158.629 58.3826C158.573 58.3055 158.548 58.2094 158.56 58.1145C158.573 58.0196 158.621 57.9331 158.695 57.8731L158.725 57.782C158.755 57.6877 158.747 57.5853 158.702 57.497C158.657 57.4088 158.579 57.3419 158.486 57.3111L157.139 56.8746C157.208 56.7817 157.311 56.6323 157.347 56.5699C157.752 55.8862 157.611 55.2649 157.435 55.1787C157.259 55.0925 156.739 56.2489 155.831 56.7021L154.872 56.3926L154.189 58.528L155.238 58.8681C155.255 58.9261 155.286 58.9789 155.328 59.0222C155.37 59.0655 155.422 59.0979 155.479 59.1166L157.593 59.8016C157.687 59.8316 157.788 59.8231 157.876 59.7779C157.963 59.7328 158.029 59.6546 158.06 59.5606L158.089 59.4695C158.108 59.4084 158.112 59.3432 158.099 59.2803C158.162 59.2663 158.221 59.236 158.269 59.1922C158.317 59.1485 158.353 59.0928 158.373 59.0308L158.402 58.9397C158.426 58.8618 158.425 58.7779 158.397 58.7009C158.478 58.6653 158.544 58.6018 158.583 58.5218C158.587 58.5168 158.59 58.5112 158.592 58.505C158.592 58.5006 158.592 58.4962 158.595 58.4922C158.598 58.4882 158.6 58.4803 158.602 58.4741L158.629 58.3826Z"
            fill="url(#paint72_linear_36_2294)"
          />
          <path
            d="M160.534 30.7291L148.861 33.2546C148.71 33.2872 148.567 33.3494 148.44 33.4377C148.313 33.526 148.204 33.6386 148.12 33.7691C148.036 33.8996 147.979 34.0454 147.951 34.1983C147.923 34.3511 147.926 34.508 147.958 34.6599L149.628 42.4871C149.694 42.794 149.878 43.062 150.14 43.2323C150.402 43.4027 150.721 43.4613 151.025 43.3954L154.634 42.6149L157.364 44.4864L159.09 41.6514L162.699 40.8708C163.004 40.8048 163.27 40.6196 163.439 40.356C163.608 40.0923 163.667 39.7719 163.601 39.465L161.931 31.6378C161.899 31.4858 161.837 31.3418 161.749 31.2138C161.661 31.0859 161.549 30.9766 161.419 30.8922C161.29 30.8078 161.145 30.75 160.993 30.722C160.841 30.694 160.685 30.6964 160.534 30.7291Z"
            fill="white"
          />
          <path
            d="M157.337 34.189C157.318 34.1859 157.299 34.1832 157.28 34.181C156.403 34.0736 155.653 34.8824 155.508 35.7871C155.005 35.023 153.993 34.5932 153.237 35.0553L153.188 35.0862C152.279 35.6868 152.212 37.0806 153.034 37.8536C153.946 38.7115 156.423 40.0709 156.423 40.0709C156.423 40.0709 158.127 37.8076 158.608 36.6481C159.042 35.6032 158.412 34.3601 157.337 34.189Z"
            fill="url(#paint73_linear_36_2294)"
          />
          <path
            d="M25.1772 48.7896L19.068 51.9395C18.989 51.9802 18.9186 52.0363 18.8611 52.1044C18.8035 52.1725 18.7598 52.2513 18.7326 52.3364C18.7053 52.4215 18.695 52.5112 18.7022 52.6003C18.7094 52.6894 18.7339 52.7762 18.7745 52.8558L20.8604 56.9533C20.9422 57.114 21.0842 57.2353 21.2549 57.2907C21.4257 57.3461 21.6113 57.331 21.771 57.2487L23.6609 56.2759L25.4545 56.9069L25.9933 55.0735L27.8832 54.1006C27.9623 54.0599 28.0326 54.0039 28.0902 53.9358C28.1477 53.8678 28.1914 53.7889 28.2187 53.7039C28.2459 53.6188 28.2563 53.5292 28.2491 53.4401C28.2419 53.3511 28.2173 53.2643 28.1768 53.1848L26.0878 49.0859C26.006 48.9252 25.8642 48.8037 25.6934 48.7482C25.5227 48.6926 25.337 48.7075 25.1772 48.7896Z"
            fill="white"
          />
          <path
            d="M23.9132 51.1904L23.8798 51.1944C23.377 51.266 23.0808 51.8272 23.1326 52.3521C22.7402 52.0027 22.1143 51.9165 21.7627 52.2853C21.7548 52.2933 21.7473 52.3017 21.7398 52.3101C21.3232 52.7797 21.4924 53.5637 22.0633 53.8702C22.6971 54.2103 24.274 54.5945 24.274 54.5945C24.274 54.5945 24.8858 53.0822 24.9816 52.3662C25.0691 51.7211 24.5355 51.1245 23.9132 51.1904Z"
            fill="url(#paint74_linear_36_2294)"
          />
          <path
            d="M132.842 61.563L134.035 61.4405L133.802 59.1436L133.073 59.2183L132.842 61.563Z"
            fill="#EFBC90"
          />
          <path
            d="M131.609 58.1955C131.504 59.1591 131.749 60.0944 132.336 60.2425C132.886 60.3814 133.755 59.7756 134.081 58.8C134.425 57.7732 134.134 57.0621 133.321 56.8697C132.457 56.6637 131.726 57.1377 131.609 58.1955Z"
            fill="#F4CAAB"
          />
          <path
            d="M133.832 59.4425L133.7 59.4036C133.7 59.4036 133.704 58.9526 133.612 58.8464C133.494 58.7102 132.973 58.7792 132.973 58.7792C132.973 58.7792 132.902 58.2831 132.692 58.1699C132.504 58.069 131.769 58.0633 131.503 58.2388C131.503 58.2388 131.329 57.7745 131.499 57.323C131.686 56.8255 132.096 56.6323 132.623 56.5513C133.151 56.4704 133.726 56.5071 133.905 57.0895C133.905 57.0895 134.368 57.1421 134.432 57.7719C134.496 58.4016 133.832 59.4425 133.832 59.4425Z"
            fill="#03053E"
          />
          <path
            d="M133.185 58.6881C133.131 58.8535 133.198 59.0242 133.334 59.0698C133.471 59.1153 133.626 59.0185 133.68 58.8531C133.735 58.6877 133.668 58.5175 133.531 58.4719C133.395 58.4264 133.24 58.5232 133.185 58.6881Z"
            fill="#F4CAAB"
          />
          <path
            d="M124.569 76.857L125.924 70.8663C125.948 70.7645 125.997 70.6708 126.069 70.595C126.14 70.5191 126.23 70.4637 126.33 70.4347L126.408 70.4117L132.839 68.542L133.863 68.2439L134.356 70.4625L133.301 70.6319L127.031 71.6388L125.065 77.0038L124.977 77.2483L124.513 77.1099L124.569 76.857Z"
            fill="#EFBC90"
          />
          <path
            d="M126.521 70.7673L126.603 70.7447L132.855 69.0201L133.883 68.7366L134.342 70.9671L133.287 71.1175L129.308 71.6835L132.389 72.8819L132.632 72.9761L132.488 73.44L132.236 73.3794L126.543 72.0148C126.405 71.9815 126.281 71.9029 126.192 71.7913C126.103 71.6797 126.054 71.5415 126.051 71.3984C126.049 71.2553 126.094 71.1155 126.179 71.001C126.265 70.8865 126.385 70.8038 126.522 70.7659L126.521 70.7673Z"
            fill="#F4CAAB"
          />
          <path
            d="M131.742 68.1356C130.66 69.1598 126.595 69.4547 125.809 70.3391C124.997 71.2519 124.65 75.8332 124.65 75.8332L125.614 76.1206C125.614 76.1206 126.963 71.7869 127.21 71.6008C127.457 71.4146 132.977 71.6928 134.334 70.7738C135.459 70.0123 134.858 68.1356 134.858 68.1356H131.742Z"
            fill="url(#paint75_linear_36_2294)"
          />
          <path
            d="M132.236 68.1356C131.481 69.3619 126.134 69.7077 125.853 71.01C125.545 72.4335 131.461 73.3781 131.461 73.3781L131.717 72.4141C131.717 72.4141 129.504 71.8202 129.538 71.6999C129.571 71.588 133.494 71.9051 134.827 70.7712C135.863 69.8917 135.352 68.1329 135.352 68.1329L132.236 68.1356Z"
            fill="url(#paint76_linear_36_2294)"
          />
          <path
            d="M133.091 62.5566L132.134 67.0672L132.53 66.751L129.215 66.877L128.908 66.8885L128.912 67.4855L129.219 67.4931L132.535 67.5735C132.623 67.5756 132.71 67.5477 132.781 67.4941C132.851 67.4406 132.902 67.3647 132.924 67.2786L132.93 67.2574L134.09 62.7945L134.222 62.2939L133.2 62.0498L133.091 62.5566Z"
            fill="#EFBC90"
          />
          <path
            d="M134.655 62.722C134.693 63.8121 133.935 66.5773 132.798 67.5077C132.122 68.06 129.914 67.6244 129.914 67.6244L129.839 66.7652C129.839 66.7652 131.509 66.9452 131.918 66.0059C132.327 65.0667 132.605 62.6008 133.17 62.2241C133.668 61.8924 134.637 62.2325 134.655 62.722Z"
            fill="url(#paint77_linear_36_2294)"
          />
          <path
            d="M134.166 60.9784C134.166 60.9784 136.352 64.268 135.907 67.7774C135.907 67.7774 136.095 68.4668 133.614 68.3731C131.132 68.2793 130.991 67.9344 130.991 67.9344C131.776 66.051 132.386 63.9195 132.689 61.3529C132.689 61.3529 133.617 61.3874 134.166 60.9784Z"
            fill="url(#paint78_linear_36_2294)"
          />
          <path
            d="M127.61 67.0438C127.606 67.2556 127.918 67.5201 128.362 67.5824C128.771 67.6403 129.059 67.4232 129.075 67.2096C129.111 66.7333 127.616 66.7161 127.61 67.0438Z"
            fill="#EFBC90"
          />
          <path
            d="M127.95 66.6516C127.986 66.5658 128.239 66.5463 128.542 66.644C128.823 66.7325 128.972 66.9005 128.944 66.9903C128.88 67.1906 127.893 66.7842 127.95 66.6516Z"
            fill="#EFBC90"
          />
          <path
            d="M132.977 61.2963C132.922 62.7182 132.762 64.1341 132.499 65.5323C132.073 67.7345 131.425 69.185 131.425 69.185C131.425 69.185 135.728 69.4504 136.476 68.3802C137.151 67.4166 135.316 62.0494 134.323 60.8709L132.977 61.2963Z"
            fill="url(#paint79_linear_36_2294)"
          />
          <path
            d="M132.2 75.7859L132.233 75.3242L132.02 73.5501L132.033 73.5536C132.033 73.5536 132.313 73.6045 132.45 73.2821C132.551 73.0447 132.526 72.8678 132.505 72.7908C132.497 72.7778 132.492 72.7624 132.492 72.7466C132.519 72.6387 132.716 72.5963 132.931 72.6515C132.997 72.668 133.061 72.6936 133.12 72.7276L133.42 72.8045L132.607 76.0216L132.302 75.9438C132.269 75.9347 132.24 75.9136 132.221 75.8845C132.202 75.8554 132.194 75.8203 132.2 75.7859Z"
            fill="#03053E"
          />
          <path
            d="M122.318 77.4456L122.772 77.3664L124.431 76.7287V76.742C124.431 76.742 124.449 77.0277 124.794 77.083C125.047 77.1236 125.211 77.0564 125.281 77.0184C125.29 77.007 125.303 76.9987 125.318 76.9945C125.429 76.9945 125.517 77.1776 125.517 77.4018C125.516 77.4703 125.507 77.5384 125.489 77.6043V77.9139L122.19 77.8993V77.5836C122.19 77.5486 122.204 77.5152 122.228 77.4896C122.251 77.4641 122.284 77.4484 122.318 77.4456Z"
            fill="#03053E"
          />
          <path
            d="M129.671 67.8835H125.693V68.2501H129.671V67.8835Z"
            fill="url(#paint80_linear_36_2294)"
          />
          <path
            d="M128.284 68.2501H125.69L125.301 65.6698H127.894L128.284 68.2501Z"
            fill="url(#paint81_linear_36_2294)"
          />
          <path
            d="M126.831 66.5949C126.831 66.6935 126.735 66.7718 126.617 66.7718C126.499 66.7718 126.403 66.6922 126.403 66.5949C126.403 66.4976 126.498 66.418 126.617 66.418C126.736 66.418 126.831 66.4963 126.831 66.5949Z"
            fill="url(#paint82_linear_36_2294)"
          />
          <path
            d="M133.252 62.5677L133.18 67.2609L133.507 66.8735L130.226 67.6523L129.928 67.7235L130.047 68.3085L130.349 68.2559L133.671 67.677C133.758 67.6614 133.838 67.617 133.897 67.5508C133.956 67.4846 133.991 67.4004 133.997 67.3118V67.2896L134.278 62.607L134.309 62.091L133.259 62.0507L133.252 62.5677Z"
            fill="#F4CAAB"
          />
          <path
            d="M128.71 68.1896C128.754 68.3974 129.113 68.5876 129.56 68.5508C129.972 68.5172 130.206 68.2413 130.175 68.0304C130.108 67.5576 128.644 67.8689 128.71 68.1896Z"
            fill="#F4CAAB"
          />
          <path
            d="M128.956 67.7327C128.973 67.6412 129.216 67.5665 129.533 67.5952C129.826 67.6217 130.008 67.7513 130 67.8451C129.981 68.0569 128.93 67.8742 128.956 67.7327Z"
            fill="#F4CAAB"
          />
          <path
            d="M134.836 62.4222C135.159 63.4835 134.845 66.434 133.892 67.5873C133.327 68.2723 131.068 68.2506 131.068 68.2506L130.825 67.4077C130.825 67.4077 132.502 67.3931 132.724 66.3756C132.946 65.358 132.768 62.5363 133.26 62.0485C133.692 61.6213 134.662 61.8495 134.836 62.4222Z"
            fill="url(#paint83_linear_36_2294)"
          />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use
                xlink:href="#image0_36_2294"
                transform="scale(0.00621118 0.00490196)"
              />
            </pattern>
            <linearGradient
              id="paint0_linear_36_2294"
              x1="1.71731"
              y1="69.2133"
              x2="176.136"
              y2="69.2133"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_36_2294"
              x1="68696.3"
              y1="107307"
              x2="188336"
              y2="107436"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_36_2294"
              x1="97061.1"
              y1="70235"
              x2="185230"
              y2="58450.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_36_2294"
              x1="164034"
              y1="190292"
              x2="-9103.14"
              y2="120818"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_36_2294"
              x1="-140.304"
              y1="64148.8"
              x2="55654"
              y2="90712.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_36_2294"
              x1="5998.65"
              y1="55254.2"
              x2="42619.8"
              y2="79831.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_36_2294"
              x1="34.7664"
              y1="75.6161"
              x2="43.0132"
              y2="75.6161"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_36_2294"
              x1="45.1823"
              y1="73.0606"
              x2="68.5961"
              y2="73.0606"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_36_2294"
              x1="18231.9"
              y1="8316.21"
              x2="24363.8"
              y2="8316.21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_36_2294"
              x1="31281.3"
              y1="71175"
              x2="97236"
              y2="71175"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_36_2294"
              x1="25842.6"
              y1="63610.3"
              x2="46841.3"
              y2="63610.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_36_2294"
              x1="3585.25"
              y1="9245.52"
              x2="4175.02"
              y2="9245.52"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_36_2294"
              x1="2385.27"
              y1="5110.42"
              x2="2566.08"
              y2="5110.42"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_36_2294"
              x1="25144.2"
              y1="60794.6"
              x2="44322.5"
              y2="60794.6"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_36_2294"
              x1="8957.76"
              y1="16978.2"
              x2="10442.3"
              y2="16978.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_36_2294"
              x1="19885.4"
              y1="39716.9"
              x2="28450.4"
              y2="39716.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint16_linear_36_2294"
              x1="17332.7"
              y1="39073.8"
              x2="24936.4"
              y2="39073.8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE2E4" />
              <stop offset="1" stop-color="#A4B8EA" />
            </linearGradient>
            <linearGradient
              id="paint17_linear_36_2294"
              x1="12368.4"
              y1="7654.91"
              x2="13915.9"
              y2="7654.91"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_36_2294"
              x1="70.3731"
              y1="105.615"
              x2="66.1817"
              y2="107.31"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC1C2" />
              <stop offset="1" stop-color="#FE958F" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_36_2294"
              x1="8596.22"
              y1="35065.9"
              x2="7946.39"
              y2="35091.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint20_linear_36_2294"
              x1="9227.1"
              y1="35003.5"
              x2="8637.29"
              y2="34930.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint21_linear_36_2294"
              x1="69.0891"
              y1="107.486"
              x2="62.6741"
              y2="106.301"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FE958F" />
              <stop offset="1" stop-color="#F77C77" />
            </linearGradient>
            <linearGradient
              id="paint22_linear_36_2294"
              x1="36007.2"
              y1="20871.9"
              x2="35757.3"
              y2="20853.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC1C2" />
              <stop offset="1" stop-color="#FE958F" />
            </linearGradient>
            <linearGradient
              id="paint23_linear_36_2294"
              x1="103.562"
              y1="9.4261"
              x2="70.9404"
              y2="-27.0679"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#E7ECF9" />
            </linearGradient>
            <linearGradient
              id="paint24_linear_36_2294"
              x1="26752"
              y1="-266.363"
              x2="23886.6"
              y2="16150.7"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint25_linear_36_2294"
              x1="28331.6"
              y1="53.8607"
              x2="25490.7"
              y2="16330.6"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint26_linear_36_2294"
              x1="45596.9"
              y1="2819.55"
              x2="42610.9"
              y2="19927.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint27_linear_36_2294"
              x1="22766.6"
              y1="-66.8247"
              x2="20412.3"
              y2="13421.6"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint28_linear_36_2294"
              x1="24405"
              y1="207.28"
              x2="22042.4"
              y2="13743.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint29_linear_36_2294"
              x1="26884"
              y1="629.638"
              x2="24514.4"
              y2="14206.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint30_linear_36_2294"
              x1="25461.7"
              y1="237.962"
              x2="23008"
              y2="14296.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint31_linear_36_2294"
              x1="60569.4"
              y1="11873.6"
              x2="75543"
              y2="-28477.6"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#E7ECF9" />
            </linearGradient>
            <linearGradient
              id="paint32_linear_36_2294"
              x1="4604.38"
              y1="-6475.52"
              x2="7563.86"
              y2="1122.98"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint33_linear_36_2294"
              x1="3467.6"
              y1="-5828.27"
              x2="6297.91"
              y2="1438.49"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint34_linear_36_2294"
              x1="6027.93"
              y1="-5502.87"
              x2="8030.78"
              y2="-360.526"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint35_linear_36_2294"
              x1="4502.36"
              y1="-5891.13"
              x2="7117.94"
              y2="824.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint36_linear_36_2294"
              x1="4741.47"
              y1="-5985.36"
              x2="7357.06"
              y2="730.173"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint37_linear_36_2294"
              x1="7258.91"
              y1="-5986.66"
              x2="9261.71"
              y2="-844.487"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint38_linear_36_2294"
              x1="11653.5"
              y1="-8601.61"
              x2="14219.3"
              y2="-2013.83"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint39_linear_36_2294"
              x1="39454.6"
              y1="26684.9"
              x2="33350.4"
              y2="6670.88"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#E7ECF9" />
            </linearGradient>
            <linearGradient
              id="paint40_linear_36_2294"
              x1="26040.4"
              y1="-2504.19"
              x2="28577.5"
              y2="-2428.02"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FE958F" />
              <stop offset="1" stop-color="#F77C77" />
            </linearGradient>
            <linearGradient
              id="paint41_linear_36_2294"
              x1="18667.1"
              y1="37848.9"
              x2="19343.1"
              y2="37842.1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint42_linear_36_2294"
              x1="23711.5"
              y1="28761.9"
              x2="24542.9"
              y2="28571.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint43_linear_36_2294"
              x1="22492.3"
              y1="12369.5"
              x2="23224.8"
              y2="12754.7"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint44_linear_36_2294"
              x1="17747.5"
              y1="19372.3"
              x2="18495.8"
              y2="19307.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint45_linear_36_2294"
              x1="11737.2"
              y1="20612.2"
              x2="11945.5"
              y2="20581.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint46_linear_36_2294"
              x1="-33334.6"
              y1="11011"
              x2="-33856.3"
              y2="11685.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FE958F" />
              <stop offset="1" stop-color="#F77C77" />
            </linearGradient>
            <linearGradient
              id="paint47_linear_36_2294"
              x1="-35681.6"
              y1="12715.1"
              x2="-36411"
              y2="13678.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC1C2" />
              <stop offset="1" stop-color="#FE958F" />
            </linearGradient>
            <linearGradient
              id="paint48_linear_36_2294"
              x1="-17128.8"
              y1="8352.05"
              x2="-17401.2"
              y2="8367.35"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint49_linear_36_2294"
              x1="-17949.3"
              y1="10649.3"
              x2="-18435.9"
              y2="10575.8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint50_linear_36_2294"
              x1="-13205"
              y1="11231.5"
              x2="-13357.6"
              y2="11205.8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint51_linear_36_2294"
              x1="-14445.2"
              y1="4561.24"
              x2="-14157.1"
              y2="4561.24"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint52_linear_36_2294"
              x1="95.6765"
              y1="48.0387"
              x2="95.6765"
              y2="89.882"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint53_linear_36_2294"
              x1="-42885.7"
              y1="17799.9"
              x2="-42885.7"
              y2="79600.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint54_linear_36_2294"
              x1="16183"
              y1="6073.33"
              x2="15508.9"
              y2="6191.91"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint55_linear_36_2294"
              x1="14244.5"
              y1="6120.52"
              x2="13127"
              y2="5913.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint56_linear_36_2294"
              x1="35475.4"
              y1="19381.6"
              x2="31973.2"
              y2="20779.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC1C2" />
              <stop offset="1" stop-color="#FE958F" />
            </linearGradient>
            <linearGradient
              id="paint57_linear_36_2294"
              x1="24849.8"
              y1="29128.1"
              x2="21857.4"
              y2="29042.7"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FE958F" />
              <stop offset="1" stop-color="#F77C77" />
            </linearGradient>
            <linearGradient
              id="paint58_linear_36_2294"
              x1="16126.3"
              y1="28521.8"
              x2="15192.3"
              y2="28408.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC1C2" />
              <stop offset="1" stop-color="#FE958F" />
            </linearGradient>
            <linearGradient
              id="paint59_linear_36_2294"
              x1="23151.9"
              y1="5569.99"
              x2="29580.4"
              y2="5569.99"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint60_linear_36_2294"
              x1="9979.7"
              y1="33565.4"
              x2="10651.3"
              y2="33565.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint61_linear_36_2294"
              x1="28790.1"
              y1="29382.2"
              x2="36033.2"
              y2="29382.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint62_linear_36_2294"
              x1="-25517.8"
              y1="29382.2"
              x2="-32760.1"
              y2="29382.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint63_linear_36_2294"
              x1="3690.28"
              y1="9458.63"
              x2="4827.99"
              y2="9273.45"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint64_linear_36_2294"
              x1="23911.7"
              y1="4328.37"
              x2="25768.2"
              y2="4328.37"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint65_linear_36_2294"
              x1="6312.6"
              y1="17192.2"
              x2="6482.24"
              y2="17192.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint66_linear_36_2294"
              x1="22325.2"
              y1="49671.2"
              x2="23647.1"
              y2="49671.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint67_linear_36_2294"
              x1="26429.7"
              y1="49671.2"
              x2="23447.8"
              y2="49671.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint68_linear_36_2294"
              x1="3761"
              y1="51257.3"
              x2="3821.01"
              y2="51257.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <radialGradient
              id="paint69_radial_36_2294"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(130.714 63.1974) scale(12.4754 13.2129)"
            >
              <stop offset="0.64" stop-color="#FFE2E4" />
              <stop offset="0.69" stop-color="#FFD6D7" />
              <stop offset="0.76" stop-color="#FFC6C8" />
              <stop offset="0.82" stop-color="#FFC1C2" />
              <stop offset="0.88" stop-color="#FFC5C6" />
              <stop offset="0.95" stop-color="#FFD2D3" />
              <stop offset="1" stop-color="#FFE2E4" />
            </radialGradient>
            <linearGradient
              id="paint70_linear_36_2294"
              x1="7920.75"
              y1="2013.37"
              x2="8191.06"
              y2="2013.37"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#0F24A4" />
              <stop offset="1" stop-color="#152070" />
            </linearGradient>
            <linearGradient
              id="paint71_linear_36_2294"
              x1="3678.24"
              y1="1408.28"
              x2="4506.59"
              y2="1284.21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint72_linear_36_2294"
              x1="12945.6"
              y1="23751"
              x2="13357.9"
              y2="23884.8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint73_linear_36_2294"
              x1="26061.1"
              y1="-4563.53"
              x2="26949.1"
              y2="-4753.21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FE958F" />
              <stop offset="1" stop-color="#F77C77" />
            </linearGradient>
            <linearGradient
              id="paint74_linear_36_2294"
              x1="3553.11"
              y1="-2835.34"
              x2="3811.15"
              y2="-2966.69"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FE958F" />
              <stop offset="1" stop-color="#F77C77" />
            </linearGradient>
            <linearGradient
              id="paint75_linear_36_2294"
              x1="-21232.1"
              y1="12554.8"
              x2="-19997.6"
              y2="13168.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint76_linear_36_2294"
              x1="-19087.6"
              y1="8262.36"
              x2="-17662.7"
              y2="8889.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint77_linear_36_2294"
              x1="-9114.76"
              y1="8289.77"
              x2="-8822.62"
              y2="8403.78"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint78_linear_36_2294"
              x1="-9184.38"
              y1="10827.3"
              x2="-9007.92"
              y2="10860.1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC1C2" />
              <stop offset="1" stop-color="#FE958F" />
            </linearGradient>
            <linearGradient
              id="paint79_linear_36_2294"
              x1="-9741.16"
              y1="12507.4"
              x2="-9108.85"
              y2="12408.1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7A8FD8" />
              <stop offset="1" stop-color="#435AC2" />
            </linearGradient>
            <linearGradient
              id="paint80_linear_36_2294"
              x1="-11615.9"
              y1="789.275"
              x2="-11617.1"
              y2="795.568"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint81_linear_36_2294"
              x1="-8666.69"
              y1="5070.68"
              x2="-8686.53"
              y2="5082.15"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <linearGradient
              id="paint82_linear_36_2294"
              x1="126.802"
              y1="66.5016"
              x2="126.43"
              y2="66.6865"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8095DA" />
              <stop offset="1" stop-color="#A1B5E9" />
            </linearGradient>
            <linearGradient
              id="paint83_linear_36_2294"
              x1="-7591.95"
              y1="9488.44"
              x2="-7280.95"
              y2="9558.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#435AC2" />
              <stop offset="1" stop-color="#0F24A4" />
            </linearGradient>
            <image
              id="image0_36_2294"
              width="161"
              height="204"
              xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKEAAADMCAYAAAAbO8MyAAAACXBIWXMAAAsSAAALEgHS3X78AAAFC0lEQVR4Xu3dSXLcRhRF0U+FvC0vXtuSIuiBDKk6FtrMn805Y04AXL5EKcr0x+fnZ9C/7//8e+pB/vr542PtZ0r5EGG/zoa3RY04RdiZGuG9UyJKEXYiO75HV8Yowsa1Ft+jK2IUYcNaD/DWmRhF2KCe4nt0JEYRNqTn+B7tifHb2g9Qx0gBRuy7HhE2YM8D68nW6xJhsq0Pqldbrk+EibY8oBGsXacPJknWHsyoXn1gsYQJZg0w4vW1i7CymQNcPN4DEZLiNkQRVmQFXxNhJQJ8ttwTEZLOP9FUYAXfs4SFCXCdCEknwoKs4DYiJJ0IC7GC2/z6+eNDhKQTYQFWcJvlGzUiJJ0IL2YFt7n9XqEIqe7xi60ipCrfrC7MUXyMCKnmq/8gXoRU8e4vMoiQ4tb+JIgIKWotwAgRXsaHkmdbAoyI+L72A7DX1vgWlpBL7Q0wQoRc6EiAESLkIkcDjPBOeImZP5SciW9hCTnsigAjLCEHXBXfQoRsdnV8C8cxm5QKMMISnjb6h5KS8S1EyJMa4d0SIX/Ujm8hwsllhXdLhBNqIbxbIpxIa/Et/BPNJFoNMMISDq/l+BaWkHQiHFgPKxghwmH1EmCECGmACAfU0wpGiJAGiHAwva1ghAhpgAhJJ0LSiXAwPX7TW4SkE+GAeltDEZJOhKQT4aB6OpJFSDoRkk6EA+vlSBYh6UQ4uB7WUISkEyHpRDiB1o9kEZJOhKQTIelEOImW3wtFSDoRkk6EE2n1SBYh6URIOhGe0Orx1hsRkk6Ek2lxvUVIOhEe1OKi9EqEpBMh6UR4gKP4WiKcUGu/RCIknQh3am1FRiBC0olwBytYhgg3EmA5IiSdCDewgmWJkHQiXGEFyxPhGwKsQ4RfEGA9IiSdCF8YfQVb+38ii/DB6AG2SIQ3BJhDhP8TYB4RTqa198GIiO9rPzA6C5hv6iWcLcAWVzBi0iWcLb7WTbeEswbY6gpGTLSEs8YX0XaAERNEOHN8vRg2QvH91voKRgwYofj+6iHAiEEiFN69XuJbdB2h+O71Ft+iywjFd6/X+BbdRCi8e72Hd6v5CMX310jh3WoyQuHdGzW+RTMRCu/Z6PEt0iMU37NZ4lukRCi8r80WYETlCMX33owBRlSKUHzrZg0wonCE4mOLIhGKb5+ZVzCiwDerBbjP7AFGXLiE4uOo0xGK7zgr+NvhCMXHVQ69EwrwPCv41+4IBcjVdkUoQErYHKEAr+MovrcpQgFS0mqEAqS0txEK8HqO4mdfRihAalk9jqG0lxFaQWp6ilCA5XgffM1xTLq7CK0gGSwh6URYiffBr/2J0FFMFktIOhGSToQVeB9871uE90FyWULSiZB0IizM++A6EZJOhKQTYUGO4m1ESDoRkk6EpBNhId4HtxMh6URIOhEW4CjeR4SkEyHpRHgxR/F+IiTdtwi/vVdxH4+xhKQTIen+ROgoOcf9O84Skk6EF7CC54iQdHcR+o3ezz07zxKSToQnWMFrPEXoxlLbyyUU4jr36DqO4wMEeK0vI3SjqeXtEgrxmXtyPcfxDgIsYzVCN/4396Gc1QgjPIDZr7+0TRFGzPsgZr3umjZHGDHfA5nterN8fH7u/8P9o/+1f/HVdSjCxYgxCrC+UxFGjBOi+PLseid8ZYSHN8I19Oz0Et7qaRWF145LI1y0GqPw2lQkwlvZQQqvfcUjvFUrSOH1pWqEr5wJU2xj+A8HUd5czO9U7gAAAABJRU5ErkJggg=="
            />
          </defs>
        </svg>
      </div>

      <div
        class="primary-button-border qb-primary-color-text qb-mr-top-15 qb-display-flex-center"
        (click)="createNewPost()" [matTooltip]="socialChannels.length>0?null:'Add a channel'"
      >
        {{'COMMUNICATION_PAGE.CREATE_NEW_POST' | i18nTranslate}}
      </div>
    </div>

    <!-- <div class="qb-bck-white qb-padding-15 qb-flex-1 qb-border-radius-5">
      <div class="qb-social-card-title">Live Updates</div>
      <div class="qb-social-card-subtitle">
        Here’s where you’ll see activity updates from across all social channels
      </div>

      <div *ngFor="let message of liveUpdateData.slice().reverse()">
        {{ message }}
      </div>
    </div> -->
  </div>
</div>
