import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { AnalyticsCustomerListDialogBoxComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-journey-analytics-view",
  templateUrl: "./journey-analytics-view.component.html",
  styleUrls: ["./journey-analytics-view.component.css"],
})
export class JourneyAnalyticsViewComponent implements OnInit {
  private documentClickListener: () => void;
  constructor(
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private tokenStorageService: TokenStorageService,
    private snackBar: SnackbarCollection,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private _WidgetConfigService: WidgetConfigService
  ) {}

  selectedId: number = -1;
  selectedModule = [];
  modules = [];
  mainTopic = "";
  mainCategory = "all";
  loading = false;
  categoryList = ["all", "active", "ended"];
  op2ListSearch = "";
  dropdownListLoading: boolean = false;
  noDataSubModule: boolean = false;
  offerCustomerDetails = [];
  customerSegmentAndTiers = [];
  showMoreSegmentButtonStatus = false;
  strategyData = {
    strategyDescription: "",
    strategyName: "",
    segments: [],
    customerCount: 0,
    strategyType: "",
    isSegmented: false,
    allLead: false,
    allCustomer: false,
    created_by: null,
  };
  moduleData = [
    {
      displayName: "Email",
      type: "EMAIL",
    },
    {
      displayName: "SMS",
      type: "SMS",
    },
    {
      displayName: "Offer",
      type: "OFFER",
    },
    {
      displayName: "Survey",
      type: "SURVEY",
    },
  ];
  nonClickableEvent = ["sent", "delivered"];
  allowedInSms = ["sent", "delivered", "unsubscribed", "failed"];
  moduleAnalyticsData = {};

  subModuleAnalyticsData = {};

  subModuleData = [];
  validWidgets = null;
  orderByRowNum = (a, b) => {
    return a;
  }; //keyValue is always displyed alphabetically so a fix to show it in orderbyrow

  ngOnInit(): void {
    document
      .getElementById("parent-div")
      .addEventListener("click", (event) => this.hideHeaderEvents());
    this.getWidgets();
    this.getCustomerSegmentsAndTiers();
    this.getModulesData("campaign");
    this.documentClickListener = this.renderer.listen(
      "document",
      "click",
      (event) => {
        const isInsideDropdown = this.elRef?.nativeElement
          .querySelector(".dropdown")
          .contains(event.target);
        if (!isInsideDropdown) {
          this.hideAllDropdowns(event);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      // Clean up the event listener when the component is destroyed
      this.documentClickListener();
    }
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  selectJourney(result) {
    this.noDataSubModule = false;
    this.selectedId = result["moduleId"];
    this.selectedModule = result;
    this.getSubModules();
    this.getParticularJourneyDetails();
  }

  selectJourneyCategory(value) {
    this.mainCategory = value;
  }

  getSubModules() {
    this.getSubModulesOfJourneyApi(this.selectedId).then(
      (response) => {
        this.subModuleData = response["body"];
        this.setUpModuleAnalytcisData(response["body"]);
      },
      (reject) => {}
    );
  }

  showLoading(id, action) {
    let element = document.getElementById(id);
    let element1 = document.getElementById("data" + id);
    if (element) {
      element.style.display = action ? "flex" : "none";
    }
    if (element1) {
      element1.style.display = action ? "none" : "grid";
    }
  }

  showNodataAvailable(id) {
    let element = document.getElementById("noData" + id);
    if (element) {
      element.style.display = "block";
    }
  }

  showChannelNoData(hide) {
    let element = document.getElementById("no-moduleAnalytics-data");
    if (element && hide) {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  }

  getSelectedModuleData(channel, subModuleId) {
    this.showLoading(subModuleId, true);
    if (channel === "EMAIL" || channel === "SMS") {
      let data = {
        moduleType: "CAMPAIGN",
        merchantId: this.tokenStorageService.getMerchantId(),
        moduleId: this.selectedId,
        storeId: null,
        subModuleId: subModuleId ? subModuleId : null,
        event: null,
        channel: channel,
        url: null,
      };
      this.getRateOrCountInsightApi(data).then(
        (response) => {
          let res = response["body"]["eventWiseRateList"];
          if (subModuleId === null) {
            this.setDataForModule(res, channel);
          } else {
            if (res === null) {
              this.showNodataAvailable(subModuleId);
              this.showLoading(subModuleId, false);
            } else {
              this.setSubModuleData(subModuleId, res, "CHANNEL", channel);
            }
          }
        },
        (reject) => {
          this.noDataSubModule = true;
        }
      );
    } else {
      if (subModuleId !== null) {
        this.getOfferCountInsightApi(
          this.getChannelByModuleId(subModuleId.split("_")[0]).offerId
        ).then(
          (response) => {
            let res = response["body"]["totalRedeemedCount"];
            this.offerCustomerDetails = response["body"];
            this.setSubModuleData(subModuleId, res, "OFFER", null);
          },
          (reject) => {}
        );
        let data = {
          moduleType: "CAMPAIGN",
          merchantId: this.tokenStorageService.getMerchantId(),
          moduleId: this.selectedId,
          storeId: null,
          subModuleId: subModuleId ? subModuleId.split("_")[0] : null,
          event: null,
          channel: subModuleId.split("_")[1].toUpperCase(),
          url: null,
        };
        this.getRateOrCountInsightApi(data).then((response) => {
          let res = response["body"]["eventWiseRateList"];
          if (res != null)
            this.setSubModuleData(
              subModuleId,
              res,
              "CHANNEL",
              subModuleId.split("_")[1]
            );
          else {
            this.showNodataAvailable(subModuleId);
            this.showLoading(subModuleId, false);
          }
        });
      }
    }
  }

  setDataForModule(data, channel) {
    for (let i in data) {
      this.moduleAnalyticsData[channel] =
        this.moduleAnalyticsData[channel] || {};
      this.moduleAnalyticsData[channel][data[i].event] =
        this.moduleAnalyticsData[channel][data[i].event] || {};
      if (data[i].event.toLowerCase() === "sent") {
        this.moduleAnalyticsData[channel][data[i].event]["value"] =
          data[i].count;
      } else {
        this.moduleAnalyticsData[channel][data[i].event]["value"] =
          data[i].rate;
      }
      this.moduleAnalyticsData[channel][data[i].event]["displayName"] =
        this.getDisplayNameBasedOnEvent(data[i].event, "rate", channel);
    }
  }

  setSubModuleData(id, data, type, channel) {
    if (type === "CHANNEL") {
      for (let i in data) {
        if (
          this.ChannelCheck(channel.toUpperCase(), data[i].event.toLowerCase())
        ) {
          this.subModuleAnalyticsData[id] =
            this.subModuleAnalyticsData[id] || {};
          this.subModuleAnalyticsData[id][data[i].event] =
            this.subModuleAnalyticsData[id][data[i].event] || {};
          this.subModuleAnalyticsData[id][data[i].event]["value"] =
            data[i].count;
          this.subModuleAnalyticsData[id][data[i].event]["displayName"] =
            this.getDisplayNameBasedOnEvent(data[i].event, "count", channel);
        }
      }
    }
    if (type === "OFFER") {
      this.subModuleAnalyticsData[id] = this.subModuleAnalyticsData[id] || {};
      this.subModuleAnalyticsData[id]["redeemed"] =
        this.subModuleAnalyticsData[id]["redeemed"] || {};
      this.subModuleAnalyticsData[id]["redeemed"]["value"] = data;
      this.subModuleAnalyticsData[id]["redeemed"]["displayName"] =
        this.getDisplayNameBasedOnEvent("redeemed", "count", null);
    }
    this.showLoading(id, false);
    console.log(this.subModuleAnalyticsData);
  }

  ChannelCheck(channel, event): boolean {
    if (channel === "SMS") {
      return this.allowedInSms.indexOf(event) === -1 ? false : true;
    } else if (channel === "EMAIL") {
      return true;
    }
  }

  getDisplayNameBasedOnEvent(event, type, channel) {
    switch (event.toLowerCase()) {
      case "delivered":
        return type === "rate" ? "Delivery Rate" : "Delivered";
      case "opened":
        return type === "rate" ? "Open Rate" : "Opened";
      case "clicked":
        return type === "rate" ? "Clicked Rate" : "Clicked";
      case "sent":
        return channel.toLowerCase() + " Sent";
      case "redeemed":
        return "Offer Redeemed";
      default:
        return event.toLowerCase();
    }
  }

  setUpModuleAnalytcisData(data, tabChnage?) {
    if (!tabChnage) {
      this.moduleAnalyticsData = {};
      this.subModuleAnalyticsData = {};
      this.moduleAnalyticsData = data.reduce((acc, { channelType }) => {
        if (channelType.toUpperCase() !== "SURVEY" && !acc[channelType]) {
          acc[channelType] = {}; // Create an empty object for the channelType
        }
        return acc;
      }, {});
      if (Object.keys(this.moduleAnalyticsData)[0]) {
        this.mainTopic = Object.keys(this.moduleAnalyticsData)[0];
        this.showChannelNoData(false);
      } else {
        this.mainTopic = "";
        this.showChannelNoData(true);
      }
    }
    if (this.mainTopic.length > 0) {
      this.subModuleAnalyticsData = data.reduce(
        (acc, { moduleId, channelType }) => {
          if (channelType === this.mainTopic) {
            let subChannel = this.getNonNullKeys(
              this.getChannelByModuleId(moduleId)?.subChannel
            );
            if (subChannel?.length > 0) {
              for (let i = 0; i < subChannel.length; i++) {
                acc[moduleId + "_" + subChannel[i]] = {};
              }
            } else {
              acc[moduleId] = {};
            }
          }
          return acc;
        },
        {}
      );
      this.getSelectedModuleData(this.mainTopic, null);
    }
  }

  subModucleChange(data) {
    if (this.mainTopic !== data) {
      this.mainTopic = data;
      this.getSelectedModuleData(this.mainTopic, null);
      this.setUpModuleAnalytcisData(this.subModuleData, true);
    } else {
      return;
    }
  }

  getModuleName(id) {
    const foundModule = this.subModuleData.find(
      (module) => module.moduleId === id.split("_")[0]
    );
    return foundModule
      ? foundModule.moduleName +
          (id.split("_")[1] ? " (" + id.split("_")[1] + ")" : "")
      : null;
  }

  getDisplayName(type: string): string | undefined {
    return this.moduleData.find((module) => module.type === type)?.displayName;
  }

  getChannelType(type) {
    return this.moduleData.find((module) => module.type === type)?.displayName;
  }

  getCategoryDisplayName(value) {
    switch (value) {
      case "all":
        return "All";
      case "active":
        return "Active";
      case "ended":
        return "Ended";
    }
  }

  getCustomerData(subModuleId, subData) {
    let payLoadData = {
      moduleType: "CAMPAIGN",
      merchantId: this.tokenStorageService.getMerchantId(),
      moduleId:
        subData.key.toLowerCase() !== "redeemed"
          ? this.selectedId
          : this.getChannelByModuleId(subModuleId.split("_")[0]).offerId,
      subModuleId:
        subData.key.toLowerCase() !== "redeemed"
          ? subModuleId.split("_")[0]
          : null,
      channel:
        subData.key.toLowerCase() !== "redeemed"
          ? subModuleId.includes("_")
            ? subModuleId.split("_")[1]
            : this.mainTopic.toLowerCase()
          : subData.key.toLowerCase(),
      event: subData.key,
      url: null,
    };
    const dialogRef = this.dialog.open(
      AnalyticsCustomerListDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "780px",
        height: "auto",
        data: {
          header:
            subData.key.toLowerCase() === "clicked"
              ? "Click Performance"
              : "Customer Details",
          payLoadData: payLoadData,
          isClicked: subData.key.toLowerCase() === "clicked" ? true : false,
          customerData:
            subData.key.toLowerCase() === "redeemed"
              ? this.offerCustomerDetails
              : null,
        },
      }
    );
  }

  getModulesData(module) {
    this.dropdownListLoading = true;
    this.getModuleDataApi(module).then(
      (response) => {
        this.modules = response["body"];
        this.dropdownListLoading = false;
      },
      (reject) => {
        this.dropdownListLoading = false;
        this.snackBar.openSnackBar("Failed to fetch Journey List", 5000);
      }
    );
  }

  getNameFromEmailSenderList(id) {
    if (id == -1) {
      return this.getListType("campaign");
    } else {
      let item = this.modules.find((item) => item.moduleId == id);
      if (item) {
        return item.moduleName;
      }
    }
  }

  getListType(type) {
    switch (type) {
      case "campaign":
        return "Select Journeys";
      case "offer":
        return "Select Offers";
      case "survey":
        return "Select Surveys";
    }
  }

  getDropDownType(type) {
    switch (type) {
      case "campaign":
        return "Selected Journey";
      case "offer":
        return "Selected Offer";
      case "survey":
        return "Selected Survey";
    }
  }

  toggleDropdown(event, logExpIndex, compIndex, dropdownName) {
    event.stopPropagation();

    //check if click happened within category-container or within search
    if (
      event.target.parentElement.classList.contains("category-container") ||
      event.target.classList.contains("op-search")
    ) {
      return; //if so dont toggle
    }
    let exceptionIdForDropdown = `${dropdownName}-dropdown${logExpIndex}${compIndex}`;
    let exceptionIdForDownArrow = `down-arrow-${dropdownName}${logExpIndex}${compIndex}`;
    const dropdownElement = this.elRef.nativeElement.querySelector(
      `#${exceptionIdForDropdown}`
    );
    const keyboardDownIconElement = this.elRef.nativeElement.querySelector(
      `#${exceptionIdForDownArrow}`
    );
    this.hideAllDropdowns(
      event,
      exceptionIdForDropdown,
      exceptionIdForDownArrow
    );
    if (dropdownElement.style.height === "180px") {
      this.renderer.setStyle(dropdownElement, "height", "0px");
      this.renderer.setStyle(dropdownElement, "border", "unset");
      this.renderer.setStyle(
        keyboardDownIconElement,
        "transform",
        "rotate(0deg)"
      );
      this.op2ListSearch = "";
    } else {
      this.renderer.setStyle(dropdownElement, "z-index", "10");
      this.renderer.setStyle(dropdownElement, "height", "180px");
      this.renderer.setStyle(dropdownElement, "border", "1px solid #636466");
      this.renderer.setStyle(
        keyboardDownIconElement,
        "transform",
        "rotate(180deg)"
      );
    }
  }

  hideAllDropdowns(event, exceptionIdForDropdown?, exceptionIdForDownArrow?) {
    const dropdownElements =
      this.elRef.nativeElement.querySelectorAll(".dropdown");
    const downArrowElements =
      this.elRef.nativeElement.querySelectorAll(".down-arrow");
    dropdownElements.forEach((element: HTMLElement) => {
      if (element.id != exceptionIdForDropdown) {
        this.renderer.setStyle(element, "height", "0px");
        this.renderer.setStyle(element, "border", "unset");
      }
    });
    downArrowElements.forEach((element: HTMLElement) => {
      if (element.id != exceptionIdForDownArrow) {
        this.renderer.setStyle(element, "transform", "rotate(0deg)");
      }
    });
  }

  stringInputHandler(event: KeyboardEvent, maxLength) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  searchFiltered(list): any[] {
    if (!this.op2ListSearch) {
      // If no search term is provided, return only items with status "ENDED"
      return this.mainCategory === "all"
        ? list
        : list.filter(
            (item) => item.status === this.mainCategory.toUpperCase()
          );
    }

    const searchTerm = this.op2ListSearch.trim().toLowerCase();

    return list.filter((item) => {
      // Check if the item matches the search term and has the desired status
      return this.mainCategory !== "all"
        ? item.moduleName.toLowerCase().includes(searchTerm) &&
            item.status === this.mainCategory.toUpperCase()
        : item.moduleName.toLowerCase().includes(searchTerm);
    });
  }

  getsufixOfDisplayName() {
    if (this.mainTopic == "EMAIL") {
      return "Email Subject: ";
    } else if (this.mainTopic == "SMS") {
      return "SMS: ";
    } else if (this.mainTopic == "OFFER") {
      return "Offer Name: ";
    } else {
      return "";
    }
  }

  getParticularJourneyDetails() {
    this.getParticularJourneyDetailsApi(this.selectedId).then(
      (response) => {
        let strategyData = JSON.parse(response["body"]);
        this.strategyData.allLead = JSON.parse(strategyData["definition"])[
          "allLead"
        ];
        this.strategyData.allCustomer = JSON.parse(strategyData["definition"])[
          "allCustomer"
        ];
        this.strategyData.created_by = JSON.parse(strategyData["created_by"]);
        this.renderNodes(strategyData);
      },
      (reject) => {
        this.noDataSubModule = true;
      }
    );
  }

  showSegments(event) {
    event.stopPropagation();
    let element = document.getElementById("segment-popup");
    element.style.display = "flex";
  }

  hideHeaderEvents() {
    let element = document.getElementById("segment-popup");
    if (element) {
      element.style.display = "none";
    }
  }

  async renderNodes(strategyData) {
    let segmentIds = JSON.parse(strategyData["definition"])["segmentIds"];
    let tierIds = JSON.parse(strategyData["definition"])["tierIds"];
    let customerIds = JSON.parse(strategyData["definition"])["customerListIds"];
    let employeeIds = JSON.parse(strategyData["definition"])["employeeListIds"];
    this.strategyData.segments = [];
    if (this.customerSegmentAndTiers.length > 0) {
      if (segmentIds) {
        for (let i = 0; i < segmentIds.length; i++) {
          this.strategyData.segments.push(
            this.customerSegmentAndTiers[
              this.customerSegmentAndTiers.findIndex(
                (x) => x.id === segmentIds[i]
              )
            ]
          );
        }
      }

      if (tierIds) {
        for (let i = 0; i < tierIds.length; i++) {
          this.strategyData.segments.push(
            this.customerSegmentAndTiers[
              this.customerSegmentAndTiers.findIndex((x) => x.id === tierIds[i])
            ]
          );
        }
      }
      if (customerIds) {
        for (let i = 0; i < customerIds.length; i++) {
          this.strategyData.segments.push(
            this.customerSegmentAndTiers[
              this.customerSegmentAndTiers.findIndex(
                (x) => x.id === customerIds[i]
              )
            ]
          );
        }
      }
      if (employeeIds) {
        for (let i = 0; i < employeeIds.length; i++) {
          this.strategyData.segments.push(
            this.customerSegmentAndTiers[
              this.customerSegmentAndTiers.findIndex(
                (x) => x.id === employeeIds[i]
              )
            ]
          );
        }
      }
      this.getSegmentCount();
      this.checkMoreSegmentButtonStatus();
    }
  }

  async getCustomerSegmentsAndTiers() {
    await this.apiCall.getCustomerSegmentsAndTiers().subscribe(
      (response) => {
        this.customerSegmentAndTiers = response["body"];
      },
      (error) => {}
    );
  }

  getSegmentCount() {
    this.strategyData.customerCount = null;
    if (this.strategyData.segments.length > 0) {
      this.apiCall
        .getCustomerCountInSegmentAndTiers(this.strategyData.segments)
        .subscribe((response) => {
          this.strategyData.customerCount = JSON.parse(
            response["body"]
          ).totalCustomerCount;
        });
    } else {
      this.apiCall
        .getAnalytics(
          new Date(),
          new Date(),
          this.strategyData.allLead,
          this.strategyData.allCustomer
        )
        .subscribe((response) => {
          this.strategyData.customerCount = JSON.parse(
            response["body"]
          ).numberOfCustomers;
        });
    }
  }

  checkMoreSegmentButtonStatus() {
    this.showMoreSegmentButtonStatus = false;
    let length = 0;
    for (let i = 0; i < this.strategyData.segments.length; i++) {
      length += this.strategyData.segments[i].segmentName.length;
      if (i < this.strategyData.segments.length - 1) {
        length++;
      }
    }
    if (length > 20) {
      this.showMoreSegmentButtonStatus = true;
    }
  }

  getParticularJourneyDetailsApi(id) {
    return new Promise((resolve, reject) => {
      this.apiCall.getStrategy(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  getCreatedByData(data, type) {
    let datajson = data;
    if (type === "qid") {
      return datajson?.qid;
    } else {
      return datajson?.storeName ? datajson?.storeName : "-";
    }
  }

  getNonNullKeys(obj: Record<string, any>): string[] {
    return obj ? Object.keys(obj).filter((key) => obj[key] !== null) : [];
  }

  getChannelByModuleId(moduleId: string) {
    return this.subModuleData.find((channel) => channel.moduleId === moduleId);
  }

  getRateOrCountInsightApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getRateOrCountInsights(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: {
      //     moduleName: "CAMPAIGN",
      //     moduleId: "1232",
      //     subModuleId: "4567",
      //     eventWiseRateList: [
      //       {
      //         event: "sent",
      //         count: 60,
      //       },
      //       {
      //         event: "delivered",
      //         rate: 98.5,
      //         count: 150,
      //       },
      //       {
      //         event: "opened",
      //         rate: 75.0,
      //         count: 120,
      //       },
      //       {
      //         event: "clicked",
      //         rate: 55.5,
      //         count: 60,
      //       },
      //     ],
      //   },
      // };
      // resolve(res);
    });
  }

  getOfferCountInsightApi(id): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getOfferRedeemedCount(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: {
      //     moduleName: "CAMPAIGN",
      //     moduleId: "1232",
      //     subModuleId: "4567",
      //     eventWiseRateList: [
      //       {
      //         event: "sent",
      //         count: 60,
      //       },
      //       {
      //         event: "delivered",
      //         rate: 98.5,
      //         count: 150,
      //       },
      //       {
      //         event: "opened",
      //         rate: 75.0,
      //         count: 120,
      //       },
      //       {
      //         event: "clicked",
      //         rate: 55.5,
      //         count: 60,
      //       },
      //     ],
      //   },
      // };
      // resolve(res);
    });
  }

  getSubModulesOfJourneyApi(id): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getSubModulesOfJourney(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: [
      //     {
      //       channelType: "EMAIL",
      //       moduleId: "123",
      //       moduleName: "Quotes won", // this will be the subject of the
      //       status: null, // response wont be there
      //     },
      //     {
      //       channelType: "EMAIL",
      //       moduleId: "456",
      //       moduleName: "NewsletterModule",
      //       status: null,
      //     },
      //     {
      //       channelType: "SMS",
      //       moduleId: "457",
      //       moduleName: "offerName",
      //       status: null,
      //     },
      //   ],
      // };
      // resolve(res);
    });
  }

  getModuleDataApi(moduleName): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getModulesForInsightData(moduleName).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: [
      //     {
      //       channelType: null,
      //       moduleId: "123",
      //       moduleName: "DoubleStar",
      //       status: "ACTIVE",
      //     },
      //     {
      //       channelType: null,
      //       moduleId: "456",
      //       moduleName: "Test campaign",
      //       status: "ENDED",
      //     },
      //   ],
      // };
      // resolve(res);
    });
  }
}
