import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterDataServiceService {

  private messageSource = new BehaviorSubject<Array<any>>([]);
  filters = this.messageSource.asObservable();

  constructor() { }

  setFilters(filters: any) {
    this.messageSource.next(filters)
  }

  getFilters() {
    return this.messageSource.getValue();
  }

}
