import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})

export class ActivateComponent implements OnInit {

    userID = 1;
    pageIndex = 1;
    pageSize = 10;
    stores = [];
    storeDetails = {};
    totalNumberOfStores = 0;
    locationData = {};
    allCategories;
    addresses;
    showAddressDiv = false;
    backupStoreDetails;
    

  constructor(private apiCall: ApiCallService, private dialog: MatDialog, private locationService: Location, private _i18nDynamicTranslate: TranslateDynamicText) { }

  ngOnInit(): void {
    this.getStores();
  }

  getStores() {
    this.apiCall.getOnboardedStores(this.userID, this.pageIndex, this.pageSize).subscribe(response => {
        this.totalNumberOfStores = response["body"]["totalCount"];
        this.stores = response["body"]["data"];
    })
  }

  getStoreDetails(storeID) {
    this.apiCall.getOnboardedStoreDetails(this.userID, storeID).subscribe(response => {
        this.storeDetails = response["body"];
        this.backupStoreDetails = JSON.stringify(response["body"]);
        Object.keys(this.storeDetails).forEach((value, key) => {
          if(this.storeDetails[value] === null && value !== "searchMetaTags") 
              this.storeDetails[value] = '';
          
      })
        this.getCategories();
    })
  }

  getAddresses() {

  }

  update(action) {
  let storeName;
  if(this.storeDetails["storeName"] === null)
  storeName = JSON.parse(this.backupStoreDetails)["storeName"];
  else
  storeName = this.storeDetails["storeName"]
    let dialogReference = this.dialog.open(
        ConfirmationDialogBoxComponent,
        {
          panelClass: "no-padding-dialog-popup",
          width: "40%",
          data: {
            subject: action + ' Store',
            message:
              this._i18nDynamicTranslate.transform("Would you like to", ['POS_PAGE']) + " " + action.toLowerCase() + " " + storeName + "?",
            cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
            successButtonText: action,
  
          },
        }
      );
      dialogReference.afterClosed().subscribe((result) => {
        if (result) {
          Object.keys(this.storeDetails).forEach((value, key) => {
            if((this.storeDetails[value] === '' || this.storeDetails[value] === JSON.parse(this.backupStoreDetails)[value]) && value !== 'storeId' && value !== 'chainId')  {
                this.storeDetails[value] = null;
            }
        })
                this.storeDetails["isStoreReviewDone"] = true;
                this.allCategories.forEach((value: boolean, key: string) => {
                    if(this.storeDetails["chainCategory"] === value["categoryName"]) {
                        this.storeDetails["chainCategory"] = value["categoryId"]
                    }
                });
            if(action === "Activate")
            this.storeDetails["isQfferEnabled"] = true;
            this.apiCall.updateStore(this.userID, this.storeDetails["storeId"], this.storeDetails).subscribe(response => {
                this.getStoreDetails(this.storeDetails["storeId"]);
            })
        }
        return false;
      });
  }

  getCategories() {
    this.apiCall.getCategories().subscribe(response => {
        this.allCategories = response["body"];
        this.allCategories.forEach((value: boolean, key: string) => {
            if(this.storeDetails["chainCategory"] === value["categoryId"]) {
                this.storeDetails["chainCategory"] = value["categoryName"]
            }
        });
    })
  }

  openPanel(storeID) {
    this.getStoreDetails(storeID);
  }

  getNextData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getStores();
  }

  changeAddress(event) {
    if(event.length > 2) {
        this.showAddressDiv = true;
    this.getLatLng();
    this.apiCall.getAddresses(event, this.locationData["latitude"], this.locationData["longitude"]).subscribe(response => {
        this.addresses = response["body"];
    })
} else
this.showAddressDiv = false;
  }

  getLatLng() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            this.locationData["latitude"] = position.coords.latitude;
            this.locationData["longitude"] = position.coords.longitude;
          }
        });
      } else {
        alert(this._i18nDynamicTranslate.transform("Geolocation is not supported by this browser.", ['POS_PAGE']));
      }
    }
}
