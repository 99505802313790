<div style="    padding-bottom: 60px;
overflow-y: auto;
height: 100%;">
    <div style="display: flex;flex-direction: column;align-items: center;height: 100%;">
        <div class="gf-payment-heading">
            <div (click)="backBtnPressed()" class="gf-left-box">
                <mat-icon style="margin-right: 2px;">chevron_left</mat-icon>
            </div>


            <div class="gf-upgrade-plan">{{planMsgHeader | i18nDynamicTranslate : 'POS_PAGE'}}</div>
            <div></div>
        </div>
        <!-- </div> -->

        <div class="gf-item-flow" *ngIf="plangetStartedSelected==null&&!planListLoading">
            <div class="gf-item-flow-item" *ngFor="let plan of masterPlanList">
                <div class="gf-plan-block">

                    <div style="display: flex;">
                        <div><img class="gf-icon-plan"
                                src='{{ "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/" + plan.planName + ".svg" }}'
                                alt=""></div>
                        <div>
                            <div style="display: flex;margin-bottom: 5px;gap: 10px;align-items: center;">
                                <div class="gf-plan-name">{{plan.planName}}</div>
                                <div class="gf-recommended-text" *ngIf="(plan.planName==recommendedPlanName)">
                                    {{'PROFILE_PAGE.RECOMMENED' | i18nTranslate}}</div>
                            </div>
                            <div class="gf-credit-info[">{{'PROFILE_PAGE.FOR_LESS_CONATCTS_STORE' | i18nTranslate : plan.customerCount}} </div>
                        </div>

                    </div>
                    <div style="display: flex;flex-direction: column;align-items:center">
                        <div>
                            <div class="gf-plan-price" *ngIf="plan.planName!='Basic'">${{plan.price}}</div>
                            <div class="gf-plan-price" *ngIf="plan.planName=='Basic'">{{'PROFILE_PAGE.FREE_CAPS' | i18nTranslate}}</div>
                        </div>
                        <div class="gf-select-btn gf-primary-hover"
                            [ngClass]="{'gf-select-btn-current': (plan.planName==currentPlanName),'gf-primary-hover':plan.planName!=currentPlanName}"
                            (click)="selectGetStarted(plan)" >
                            {{plan.planName==currentPlanName?'PROFILE_PAGECURRENT_PLAN':'PROFILE_PAGE.GET_STARTED' | i18nTranslate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- plan selected preview -->
        <div class="gf-selected-block" *ngIf="plangetStartedSelected!=null&&!planListLoading">

            <div style="width: auto;"><img class="gf-icon-plan-preview"
                    src='{{ "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/" + plangetStartedSelected.planName + ".svg" }}'
                    alt=""></div>
            <div style="width: auto;">
                <div class="gf-selected-plan-preview">{{plangetStartedSelected.planName}} </div>
                <div class="gf-selected-plan-price-preview" *ngIf="plangetStartedSelected.planName!='Basic'">
                    ${{plangetStartedSelected.price}}<span class="gf-plan-period">/mo</span> </div>
                <div class="gf-selected-plan-price-preview" *ngIf="plangetStartedSelected.planName=='Basic'">{{'PROFILE_PAGE.FREE_CAPS' | i18nTranslate}}</div>

                <!-- <div style="display: flex;margin-bottom: 16px;
                align-items: center;">
                    <mat-icon class="gf-done-icon">check_circle</mat-icon><span
                        style="font-weight: 600;">{{plangetStartedSelected.customerCount}}</span>&nbsp;<span
                        class="gf-indica">Contacts</span>
                </div> -->
                <div style="display: flex;margin-bottom: 16px;
                align-items: center;">
                    <mat-icon class="gf-done-icon">check_circle</mat-icon><span
                        style="font-weight: 600;">{{plangetStartedSelected.emailCredits}}</span>&nbsp;<span
                        class="gf-indica">{{'PROFILE_PAGE.EMAILS' | i18nTranslate}}</span>
                </div>
                <div style="display: flex;margin-bottom: 16px;
                align-items: center;">
                    <mat-icon class="gf-done-icon">check_circle</mat-icon><span
                        style="font-weight: 600;">{{plangetStartedSelected.smsCredits}}</span>&nbsp;<span
                        class="gf-indica">{{'PROFILE_PAGE.DOMESTIC_SMS' | i18nTranslate}}</span>
                </div>



                <div class="gf-overflow-text-notice-preview" *ngIf="plangetStartedSelected.planName!='Basic'">{{'PROFILE_PAGE.PLAN_UPDATE_MESSAGE' | i18nTranslate}}</div>
                <div class="gf-select-btn-preview gf-primary-hover" (click)="selectPlan(plangetStartedSelected)"
                    *ngIf="!selectedPlanBtnName">{{'PROFILE_PAGE.BUY_NOW' | i18nTranslate}}</div>
                <div class="gf-select-btn-preview-loading" (click)="selectPlan(plangetStartedSelected)"
                    *ngIf="selectedPlanBtnName">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>

            </div>



        </div>

        <div *ngIf="planListLoading"
            style="display: flex;width: 100%;height: 100%;justify-content: center;align-items: center;">

            <mat-spinner diameter="20"></mat-spinner>
        </div>


    </div>
</div>