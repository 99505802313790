<div
  style="padding-bottom: 5%; background: #fff"
  style="height: 100%; overflow: auto"
  data-widget-name="SURVEY SCHEDULE"
  id="viewconfigDiv"
>
  <!-- newUI -->

  <div *ngIf="validWidgets">
    <div *ngIf="validWidgets | widgetConfig: 73" class="surveySet">
        <div [formGroup]="scheduleForm" style="padding: 26px">
          <div class="mr-t-10">
            <div>
              <span class="norm-text-survey"
                >{{'SURVEY_PAGE.SURVEY_NAME' | i18nTranslate}}

                <div
                  class="i-div-survey"
                  popoverClass="my-custom-class"
                  triggers="mouseenter:mouseleave"
                  placement="bottom"
                  [ngbPopover]="popContent1"
                >
                  <ng-template #popContent1>
                    <div class="w-200">
                      {{'SURVEY_PAGE.SURVEY_NAME_INFO' | i18nTranslate}}
                    </div>
                  </ng-template>
                  <div class="i-survey">i</div>
                </div>
              </span>

              <div
                class="formDivSurvey"
                [ngClass]="{
                  active: scheduleForm.get('surveyName').errors
                    ? (scheduleForm.get('surveyName').errors['required'] ||
                        scheduleForm.get('surveyName').errors['maxlength']) &&
                      (scheduleForm.get('surveyName').touched || submitClicked)
                    : false
                }"
              >
                <input
                  class="formInput"
                  type="text"
                  placeholder="Survey Name (For internal use and not visible to customers)"
                  formControlName="surveyName"
                  maxlength="100"
                />
                <span class="charText" style="color: #222222">
                  {{ 100 - scheduleForm.get("surveyName").value.length }}
                  <span class="charText">/100</span>
                </span>
              </div>
              <div class="fontExo-14p" style="height: 15px">
                <div
                  style="
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="
                      height: 13px;
                      font-size: 12px;
                      color: #ff5f31;
                      margin-bottom: 13px;
                    "
                    class="fontExo2"
                  >
                    <span
                      *ngIf="
                        scheduleForm.get('surveyName').errors
                          ? (scheduleForm.get('surveyName').errors[
                              'required'
                            ] &&
                              scheduleForm.get('surveyName').touched) ||
                            (scheduleForm.get('surveyName').errors[
                              'required'
                            ] &&
                              submitClicked)
                          : false
                      "
                      >{{'SURVEY_PAGE.SURVEY_NAME_REQ' | i18nTranslate}}</span
                    >
                  </div>
                </div>
              </div>
              <!-- <div>
                            <mat-form-field class="Rectangle" style="width: 98%;" appearance="outline">
                                <mat-label>Survey name</mat-label>
                                <input matInput placeholder="Survey Name (For internal use and not visible to customers)" formControlName="surveyName">
                            </mat-form-field>
                        </div> -->
              <!-- <div style="height:13px; font-size:12px; color:#ff5f31; margin-bottom: 13px;" class="fontExo2">
              <span
                *ngIf="scheduleForm.get('surveyName').errors ? (scheduleForm.get('surveyName').errors['required'] && scheduleForm.get('surveyName').touched) || (scheduleForm.get('surveyName').errors['required'] && submitClicked) : false">Survey
                Name is required</span>
              <span
                *ngIf="scheduleForm.get('surveyName').errors ? scheduleForm.get('surveyName').errors['maxlength'] : false">Character
                count should be less than 50</span>
            </div> -->
            </div>
          </div>
          <!-- <div class="mr-t-10">
            <span class="norm-text-survey"
              >Survey Email Subject
              <div
                class="i-div-survey"
                popoverClass="my-custom-class"
                triggers="mouseenter:mouseleave"
                placement="bottom"
                [ngbPopover]="popContent2"
              >
                <ng-template #popContent2>
                  <div class="w-200">
                    Customers will see this as the Subject line of Survey Email
                  </div>
                </ng-template>
                <div class="i-survey">i</div>
              </div>
            </span>
            <div
              class="formDivSurvey"
              [ngClass]="{
                active: scheduleForm.get('surveyEmailSubject').errors
                  ? (scheduleForm.get('surveyEmailSubject').errors[
                      'required'
                    ] ||
                      scheduleForm.get('surveyEmailSubject').errors[
                        'maxlength'
                      ]) &&
                    (scheduleForm.get('surveyEmailSubject').touched ||
                      submitClicked)
                  : false
              }"
            >
              <input
                class="formInput"
                type="text"
                placeholder="Survey Email Subject (Will be visible to customers)"
                formControlName="surveyEmailSubject"
                maxlength="100"
              />
              <span class="charText" style="color: #222222">
                {{ 100 - scheduleForm.get("surveyEmailSubject").value.length }}
                <span class="charText">/100</span>
              </span>
            </div>

            <div class="fontExo-14p" style="height: 15px">
              <div
                style="
                  display: flex;
                  flex-direction: row-reverse;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    height: 13px;
                    font-size: 12px;
                    color: #ff5f31;
                    margin-bottom: 13px;
                  "
                  class="fontExo2"
                >
                  <span
                    *ngIf="
                      scheduleForm.get('surveyEmailSubject').errors
                        ? (scheduleForm.get('surveyEmailSubject').errors[
                            'required'
                          ] &&
                            scheduleForm.get('surveyEmailSubject').touched) ||
                          (scheduleForm.get('surveyEmailSubject').errors[
                            'required'
                          ] &&
                            submitClicked)
                        : false
                    "
                    >Survey Email Subject is required</span
                  >
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div style="display: flex">
            <div style="width: 30%; margin-right: 30px">
              <span class="fontExo2" class="norm-text-survey"
                >{{'SURVEY_PAGE.SCHEDULE_TYPE' | i18nTranslate}}</span
              >
              <div
                class="formDivSurvey"
                style="margin-top: 5px; margin-bottom: 10px"
              >
                <mat-select
                  class="fontSegm"
                  formControlName="scheduleType"
                  (selectionChange)="changeScheduleTypeSelection()"
                  style="font-family: 'Rubik'; font-size: 13px"
                  disableOptionCentering
                  panelClass="myPanelClass"
                > -->
                  <!-- <mat-option value="PERIODIC" class="fontExo2"
                    >{{'SURVEY_PAGE.PERIODIC' | i18nTranslate}}</mat-option
                  > -->
                  <!-- <mat-option value="ORDER" class="fontExo2" *ngIf="shop === 'shopify'"
                    >{{'SURVEY_PAGE.ORDER_FULFILED' | i18nTranslate}}
                  </mat-option>
                  <mat-option value="INSTANT" class="fontExo2"
                    >{{'SURVEY_PAGE.INSTANT' | i18nTranslate}}</mat-option
                  >
                </mat-select>
              </div> -->
              <!-- <div>
                            <mat-form-field class="Rectangle" appearance="outline">
                                <mat-label>Schedule Type</mat-label>
                                <mat-select class="formInput fontSegm" formControlName="scheduleType" (selectionChange)="changeScheduleTypeSelection()">
                                    <mat-option value="PERIODIC" class=" fontExo2">Periodic</mat-option>
                                    <mat-option value="ORDER" class=" fontExo2">Order Status </mat-option>
                                    <mat-option value="INSTANT" class=" fontExo2">Instant</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
            <!-- </div>
            <div style="width: 30%">
              <div
                *ngIf="scheduleForm.get('scheduleType').value === 'PERIODIC'"
              >
                <div class="norm-text-survey" class="norm-text-survey">
                  {{'SURVEY_PAGE.SEND_SURVEY_EVERY' | i18nTranslate}}
                </div>
                <div> -->
                  <!-- <span style="font-size: 12px;">Period</span> -->
                  <!-- <div style="display: flex">
                    <div
                      class="formDivSurvey"
                      [ngClass]="{
                        active: scheduleForm.get('delayDaysPeriodic').errors
                          ? (scheduleForm.get('delayDaysPeriodic').errors[
                              'required'
                            ] ||
                              scheduleForm.get('delayDaysPeriodic').errors[
                                'maxlength'
                              ]) &&
                            (scheduleForm.get('delayDaysPeriodic').touched ||
                              submitClicked)
                          : false
                      }"
                      style="width: 30%"
                    >
                      <input
                        class="formInput"
                        placeholder=""
                        type="number"
                        min="1"
                        formControlName="delayDaysPeriodic"
                      />
                    </div>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 0px 10px;
                        margin-top: 12px;
                        font-size: 12px;
                        color: #101424;
                      "
                      *ngIf="scheduleForm.get('delayDaysPeriodic').value < 2"
                      >{{'SURVEY_PAGE.DAY' | i18nTranslate}}</span
                    >
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 0px 10px;
                        margin-top: 12px;
                        font-size: 12px;
                        color: #101424;
                      "
                      *ngIf="scheduleForm.get('delayDaysPeriodic').value > 1"
                      >{{'SURVEY_PAGE.DAYS' | i18nTranslate}}</span
                    >
                  </div>
                </div>
                <div
                  style="height: 13px; font-size: 12px; color: #ff5f31"
                  class="fontExo2"
                >
                  <span *ngIf="scheduleForm.get('delayDaysPeriodic').value < 7"
                    >Value should be greater than or equal to 7</span
                  >
                </div>
              </div>
              <div *ngIf="scheduleForm.get('scheduleType').value === 'ORDER'">
                <div class="norm-text-survey">{{'SURVEY_PAGE.SURVEY_SEND_AFTER' | i18nTranslate}}</div>

                <div style="display: flex">
                  <div
                    style="display: flex; width: 25%; flex-direction: column"
                  >
                    <div
                      class="formDivSurvey"
                      style="width: 70%; padding-right: 3px"
                      [ngClass]="{
                        active: scheduleForm.get('delayDays').errors
                      }"
                    >
                      <input
                        class="formInput periodDelays"
                        type="number"
                        min="0"
                        max="59"
                        formControlName="delayDays"
                      />
                    </div>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px 0 10px;
                        color: #222222;
                        font-size: 11px;
                      "
                      *ngIf="scheduleForm.get('delayDays').value > 1"
                      >{{'SURVEY_PAGE.DAYS' | i18nTranslate}}</span
                    >
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px 0 10px;
                        color: #222222;
                        font-size: 11px;
                      "
                      *ngIf="scheduleForm.get('delayDays').value < 2"
                      >{{'SURVEY_PAGE.DAY' | i18nTranslate}}</span
                    >
                  </div>
                  <div
                    style="display: flex; width: 25%; flex-direction: column"
                  >
                    <div
                      class="formDivSurvey"
                      style="width: 70%; padding-right: 3px"
                      [ngClass]="{
                        active: scheduleForm.get('delayHours').errors
                      }"
                    >
                      <input
                        class="formInput periodDelays"
                        type="number"
                        min="0"
                        max="23"
                        formControlName="delayHours"
                      />
                    </div>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px 0 10px;
                        color: #222222;
                        font-size: 11px;
                      "
                      *ngIf="scheduleForm.get('delayHours').value > 1"
                      >{{'SURVEY_PAGE.HOURS' | i18nTranslate}}</span
                    >
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px 0 10px;
                        color: #222222;
                        font-size: 11px;
                      "
                      *ngIf="scheduleForm.get('delayHours').value < 2"
                      >{{'SURVEY_PAGE.HOUR' | i18nTranslate}}</span
                    >
                  </div>
                  <div
                    style="display: flex; width: 25%; flex-direction: column"
                  >
                    <div
                      class="formDivSurvey"
                      style="width: 70%; padding-right: 3px"
                      [ngClass]="{
                        active: scheduleForm.get('delayMinutes').errors
                      }"
                    >
                      <input
                        class="formInput periodDelays"
                        type="number"
                        formControlName="delayMinutes"
                      />
                    </div>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px 0 10px;
                        color: #222222;
                        font-size: 11px;
                      "
                      *ngIf="scheduleForm.get('delayMinutes').value > 1"
                      >{{'SURVEY_PAGE.MINUTES' | i18nTranslate}}</span
                    >
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px 0 10px;
                        color: #222222;
                        font-size: 11px;
                      "
                      *ngIf="scheduleForm.get('delayMinutes').value < 2"
                      >{{'SURVEY_PAGE.MINUTE' | i18nTranslate}}</span
                    >
                  </div>
                </div> -->

                <!-- <span style="font-size: 12px;">Delay</span> -->

                <!-- <div
                  style="height: 13px; font-size: 12px; color: #ff5f31"
                  class="fontExo2"
                >
                  <span
                    *ngIf="
                      scheduleForm.get('delayDays').errors ||
                      scheduleForm.get('delayMinutes').errors ||
                      scheduleForm.get('delayHours').errors
                    "
                    >{{'SURVEY_PAGE.ALL_VALUE_VALID' | i18nTranslate}}</span
                  >
                </div>
              </div>
            </div>
          </div> -->
          <div style="display: flex">
            <div
              *ngIf="scheduleForm.get('scheduleType').value != 'ORDER'"
              style="width: 30%;"
            >
              <span class="norm-text-survey">{{'SURVEY_PAGE.CUSTOMER_SEGEMNTS_TIERS' | i18nTranslate}}</span>
              <div class="formDivSurvey" style="margin-top: 5px">
                <mat-select
                  class="fontSegm"
                  (selectionChange)="changeSegmentSelection()"
                  color="primary"
                  placeholder="Segments/Tiers/List"
                  formControlName="customerSegments"
                  multiple
                  style="font-family: 'Rubik'; font-size: 13px"
                  disableOptionCentering
                  panelClass="myPanelClass"
                >
                  <div style="display: flex; padding: 10px 15px">
                    <div class="material-icons-outlined" style="color: #c0c4d0">
                      search
                    </div>
                    <input
                      (input)="searchingValue = $event.target.value"
                      style="margin-left: 10px"
                      type="text"
                      placeholder="Search by name"
                      (keydown)="$event.stopPropagation()"
                    />
                  </div>
                  <mat-option
                    [disabled]="disableAllCustomerSelection"
                    class="fontExo2"
                    value="All Customers"
                    color="primary"
                    [hidden]="
                      !'all customers'.includes(searchingValue.toLowerCase()) &&
                      searchingValue.length > 0
                    "
                    >{{'SURVEY_PAGE.ALL_CUSTOMERS' | i18nTranslate}}
                  </mat-option>
                  <div class="category-text-container" *ngIf="isSegmentPresent()">
                    <div class="category-line"></div>
                    <span class="category-text">{{'SURVEY_PAGE.SEGMENTS' | i18nTranslate}}</span>
                    <div class="category-line"></div>
                  </div>
                  <mat-option
                    [disabled]="
                      disableCustomerSegmentSelection || !segment['isActive']
                    "
                    color="primary"
                    class="fontExo2"
                    *ngFor="let segment of customerSegmentList"
                    [value]="segment.name"
                    [matTooltip]="
                      segment['isActive']
                        ? ''
                        : 'We are preparing your segment. It will be available for use shortly'
                    "
                    [hidden]="
                      !segment.name
                        .toLowerCase()
                        .includes(searchingValue.toLowerCase())
                    "
                  >
                    {{ segment.name }}</mat-option
                  >
                  <div class="category-text-container" *ngIf="isTierSegmentPresent()">
                    <div class="category-line"></div>
                    <span class="category-text">{{'SURVEY_PAGE.TIERS' | i18nTranslate}}</span>
                    <div class="category-line"></div>
                  </div>
                  <mat-option
                    [disabled]="
                      disableCustomerSegmentSelection || !segment['isActive']
                    "
                    color="primary"
                    class="fontExo2"
                    *ngFor="let segment of customerTierList"
                    [value]="segment.name"
                    [matTooltip]="
                      segment['isActive']
                        ? ''
                        : 'We are preparing your segment. It will be available for use shortly'
                    "
                    [hidden]="
                      !segment.name
                        .toLowerCase()
                        .includes(searchingValue.toLowerCase())
                    "
                  >
                    {{ segment.name }}</mat-option
                  >
                  <div class="category-text-container" *ngIf="isCustomerListPresent()">
                    <div class="category-line"></div>
                    <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                    <div class="category-line"></div>
                  </div>
                  <mat-option
                    [disabled]="
                      disableCustomerSegmentSelection || !segment['isActive']
                    "
                    color="primary"
                    class="fontExo2"
                    *ngFor="let segment of customerList"
                    [value]="segment.name"
                    [matTooltip]="
                      segment['isActive']
                        ? ''
                        : 'We are preparing your segment. It will be available for use shortly'
                    "
                    [hidden]="
                      !segment.name?.toLowerCase().includes(searchingValue?.toLowerCase())
                    "
                  >
                    {{ segment.name }}</mat-option
                  >
                  <div class="category-text-container" *ngIf="isEmployeeListPresent()">
                    <div class="category-line"></div>
                    <span class="category-text">{{'COMMON_PAGE.EMPLOYEE_LIST' | i18nTranslate}}</span>
                    <div class="category-line"></div>
                  </div>
                  <mat-option
                    [disabled]="
                      disableCustomerSegmentSelection || !segment['isActive']
                    "
                    color="primary"
                    class="fontExo2"
                    *ngFor="let segment of employeeList"
                    [value]="segment.name"
                    [matTooltip]="
                      segment['isActive']
                        ? ''
                        : 'We are preparing your employee list. It will be available for use shortly'
                    "
                    [hidden]="
                      !segment.name?.toLowerCase().includes(searchingValue?.toLowerCase())
                    "
                  >
                    {{ segment.name }}</mat-option
                  >
                  <div *ngIf="!isSegmentPresent() && !isTierSegmentPresent() && !isCustomerListPresent() && !isEmployeeListPresent()" class="no-data">
                    {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                  </div>
                </mat-select>
              </div>

              <div
                style="
                  height: 13px;
                  font-size: 12px;
                  color: #ff5f31;
                  margin-bottom: 13px;
                "
                class="fontExo2"
              >
                <span
                  *ngIf="
                    (scheduleForm.get('customerSegments').value == null ||
                      scheduleForm.get('customerSegments').value.length == 0) &&
                    (scheduleForm.get('customerSegments').touched ||
                      submitClicked)
                  "
                  >{{'SURVEY_PAGE.FIELD_CANNOT_BE_BLANK' | i18nTranslate}}</span
                >
              </div>
            </div>

            <div
              *ngIf="scheduleForm.get('scheduleType').value === 'ORDER'"
              style="width: 30%;"
            >
              <span class="norm-text-survey">{{'SURVEY_PAGE.CUSTOMER_SEGMENTS' | i18nTranslate}}</span>
              <div class="formDivSurvey" style="margin-top: 5px">
                <input
                  matInput
                  placeholder=""
                  class="fontSegm"
                  style="
                    outline: none;
                    border: none;
                    width: 100%;
                    padding: 0 10px;
                    font-size: 12px;
                    font-family: 'Rubik';
                  "
                  formControlName="customerSegments"
                  value="All Customers"
                  readonly
                  [matTooltip]="'COMMON_PAGE.OFFER_SCHEDULE_APPLICABLE' | i18nTranslate"
                />
              </div>
            </div>

            <div class="info-scl-container">
              <app-segmentation-customer-list-redirect [miniVer]="true"></app-segmentation-customer-list-redirect>
            </div>

            <!-- <div>
              <span class="fontExo2" class="norm-text-survey"
                >Survey Channel</span
              >
              <div style="display: flex; align-items: center">
                <div class="formDivSurvey" style="margin-top: 5px">
                  <input
                    matInput
                    placeholder=""
                    class="fontSegm"
                    style="
                      outline: none;
                      border: none;
                      width: 100%;
                      padding: 0 10px;
                      font-size: 12px;
                      font-family: 'Rubik';
                    "
                    formControlName="channel"
                    value="Email"
                    readonly
                    matTooltip="Channel is Email"
                  />
                </div>
                <div
                  (click)="opensnapshotHtmlDialog()"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                  "
                >
                  <span
                    class="material-icons-outlined icons-primary"
                    style="margin: 0 8px 0 12px"
                  >
                    visibility
                  </span>
                  <span
                    class="norm-text-survey"
                    style="font-size: 11px; color: #222222"
                    >Preview Template</span
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div style="font-size: 14px">
            <span>{{'SURVEY_PAGE.TOTAL_NUMBER_TARGETED' | i18nTranslate}}</span>
            <div style="display: flex; align-items: center; gap: 5px;">
              <span class="material-icons-outlined person-icon"> person </span>
              <mat-spinner
                *ngIf="totalCustomerCount === null"
                diameter="15"
              ></mat-spinner>
              <span
                style="font-size: 0.71rem; line-height: 16px"
                *ngIf="totalCustomerCount !== null"
                >{{ totalCustomerCount }}</span
              >
            </div>
          </div>

          <!-- </div> -->
          <!-- <div style="display:flex;align-items: center;"> -->

          <!-- <div style="width:40%; margin-left: 5%;"> -->

          <!-- </div> -->
          <!-- </div> -->
          <!-- <div style="margin-top: 20px;padding-bottom: 20px;">
          <mat-checkbox class="fontClass" color="primary" formControlName="reminder">Send Reminder</mat-checkbox><sup><span style="
            align-items: center;">

              <ng-template #popContent4>
                  <div class="w-200">
                    Survey reminder is sent after 30 days
                  </div>
              </ng-template>
              <mat-icon type="button" placement="top" [autoClose]="'outside'" [ngbPopover]="popContent4"
                style="color: #c0c4d0; cursor: pointer;  position: relative;font-size: 13px;width: 13px;">
                info</mat-icon>
            </span></sup>
        </div> -->
        </div>
      <div
    style="
      display: flex;
      font-size: 12px;
      align-items: center;
      padding: 0px 26px 26px 26px;
    "
  >
    <div
      class="cancel-segment-rectangle"
      (click)="backPressed()"
      data-button-name="Cancel"
    >
      <span>{{'SURVEY_PAGE.CANCEL' | i18nTranslate}}</span>
    </div>
    <!-- <div style="margin-left: 15px;"> -->
    <div
      class="primary-button"
      style="margin-left: 15px"
      (click)="selectChannel()"
      [attr.data-button-name]="
        'Survey-schedule:' + scheduleForm.get('scheduleType').value
      "
      [attr.data-item-name]="SurveyId"
    >
      <!-- <mat-icon class="save-icon">calendar_today</mat-icon> -->
      <!-- <span class="calender-icon"></span> -->
      <!-- <span *ngIf="scheduleForm.get('scheduleType').value == 'INSTANT'"
        >Send</span
      >
      <span *ngIf="scheduleForm.get('scheduleType').value != 'INSTANT'"
        >Schedule</span
      > -->
      <span>{{'SURVEY_PAGE.SELECT_CHANNEL' | i18nTranslate}}</span>
    </div>
    <!-- </div> -->
    <!-- <div style="margin-left: 5px; display: flex; align-items: center;">
          <mat-icon style="color: #c0c4d0;">more_vert</mat-icon>
      </div> -->
  </div>
    </div>
  </div>
  
  <!-- </div>
</div> -->
</div>

<div id="overlay"></div>

<div style="  position: absolute;
          top: 0;
          right: 0;
          height: 100vh;
          width: 45%;
          z-index: -1;font-size: 13px;" id="configDiv">


  <app-survey-channel-select style="width: 100%;height: 100%;" (messageEvent)="receiveMessage($event)"
    [submitData]="submitData" *ngIf='openScheduleOverlay'>
  </app-survey-channel-select>
</div>
