import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.css']
})
export class AccountSetupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
