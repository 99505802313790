import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: "app-product-game-list-dialog",
  templateUrl: "./product-game-list-dialog.component.html",
  styleUrls: ["./product-game-list-dialog.component.css"],
})
export class ProductGameListDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ProductGameListDialogComponent>,
    private apicall: ApiCallService,
    private _snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  page = 1;
  pageNon = 1;
  size = 50;
  categoryIds;
  productIds;
  ProductAllIds = []; //This array is used to store all the products which have category with its corresponding category
  NonCategoryProduct = []; //To store all the noncategory products
  SelectedCategory = []; //To store the category selected by the user
  category;
  ShownIds = [];
  checkedId;
  indexOfCategory;
  ProductArrayId = []; //To store the product ids selected by user
  CategoryArrayId = []; //Used to store the occurance of category id when non-category product selected we push 'NON_CATEGORY' thus is used to get the count of how many products in a category is selected and helps in select all functionality.
  DataAvailability = false;
  apiloading;
  nonCategoryCount;
  NoData=true;
  search='';
  temporaryProductId=[];
  temporaryCategoryId=[];
  x;
  totalProducts;
  ngOnInit() {
    this.x= JSON.parse(JSON.stringify(this.data))
    if(this.data.search){
      this.search=this.data.search
      this.getCategory(this.search);
    }
    else{
      this.getCategory(this.search)
    }
    this.getAllProductInStore();
  }
  getCategory(data?) {
    this.NoData=true;
    this.ProductAllIds=[];
    //To get category of products and also to get all the products in each category.
    this.apiloading = true;
    this.apicall.getCategoryGame(this.page, this.size, data).subscribe((response) => {
      this.categoryIds = response["body"]["elements"];
      if(this.categoryIds.length>0)
        for (const x in this.categoryIds) {
          this.apicall
            .getProductOfCategoryGame(
              this.page,
              this.size,
              this.categoryIds[x]["id"]
            )
            .subscribe((response) => {
              this.productIds = response["body"]["elements"];
              this.calculateTotalNumberOfProducts(response["body"]["count"]);
                this.getAllGamesAndCategories(
                  this.categoryIds[x]["id"],
                  this.categoryIds[x]["name"],
                  this.productIds
                );
            });
        }
        else{
          this.NoData=false;
          this.apiloading=false;
          this.generateValue()
        }
    });
  }

  parentArrowClicked(i) {
    document.getElementById("variant-section-" + i).classList.toggle("active"); // toggle variant section
  }
  parentArrowNonCategory() {
    document
      .getElementById("variant-section-noncategory")
      .classList.toggle("active"); // toggle variant section
  }
  cancel() {
    // this.dialogRef.close(null); // close dialog
    this.dialogRef.close(null);
  }
  getDataForTable($event: any) {
    $event.target.classList.toggle("active"); // toggle variant section
    // get data for table since we are opening the div to show the body
  }
  getAllGamesAndCategories(id, name, products) {
    //To store all the product under its corresponding category
    this.ProductAllIds.push({
      id: id,
      name: name,
      products: products,
    });
    if (this.ProductAllIds.length === this.categoryIds.length) {
      this.getAllNonCategory(); //To get all non-category products
    }
  }
  setAllChecked(event, ProductArray, CategoryId) {
    //To select and check all the products in a category and remove when its not diselected.
    var i;
    if (event === true && this.SelectedCategory.indexOf(CategoryId) === -1) {
      //To store the categoryId when the category is selected
      this.SelectedCategory.push(CategoryId);
    }
    if (event === false && this.SelectedCategory.indexOf(CategoryId) !== -1) {
      const index = this.SelectedCategory.indexOf(CategoryId);
      this.SelectedCategory.splice(index, 1);
    }
    if (ProductArray.length === 0 && event === true) {
      this.CategoryArrayId.push(CategoryId);
    }
    if (ProductArray.length === 0 && event === false) {
      const index = this.CategoryArrayId.indexOf(CategoryId);
      if (index !== -1) {
        this.CategoryArrayId.splice(index, 1);
      }
    }
    for (i = 0; i < ProductArray.length; i++) {
      if (
        event == 1 &&
        this.ProductArrayId.indexOf(ProductArray[i]["id"]) === -1
      ) {
        //To store the productId of each Category
        this.updateSelection(ProductArray[i]["id"], 1, CategoryId, []);
      } else if (
        event == 0 &&
        this.ProductArrayId.indexOf(ProductArray[i]["id"]) != -1
      ) {
        this.updateSelection(ProductArray[i]["id"], 0, CategoryId, []);
      }
    }
  }
  updateSelection(pid, event, cid, CategoryArray?) {
    //To update the selecteion of ProductId and categoryId
    if (event) {
      this.ProductArrayId.push(pid);
      this.CategoryArrayId.push(cid);
      if (
        CategoryArray.length > 0 &&
        CategoryArray.length === this.countInArray(cid)
      ) {
        const index2: number = this.SelectedCategory.indexOf(cid);
        if (index2 === -1) {
          this.SelectedCategory.push(cid);
        }
      }
    } else {
      const index: number = this.ProductArrayId.indexOf(pid);
      if (index !== -1) {
        this.ProductArrayId.splice(index, 1);
      }
      const index1: number = this.CategoryArrayId.indexOf(cid);
      if (index1 !== -1) {
        this.CategoryArrayId.splice(index1, 1);
      }
      const index2: number = this.SelectedCategory.indexOf(cid);
      if (index2 !== -1) {
        this.SelectedCategory.splice(index2, 1);
      }
    }
  }
  countInArray(what) {
    //To count the occurance of categoryId to check whether all products in a category is slected or not.
    let array = this.CategoryArrayId;
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      // loop through array
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }
  save() {
    //To pass the selected prodctId and selected categoryId
    if (this.SelectedCategory.indexOf("NON_CATEGORY") !== -1) {
      //To remove NON_CATEGORY from selected category as we are only passing categoryIds
      const index = this.SelectedCategory.indexOf("NON_CATEGORY");
      this.SelectedCategory.splice(index, 1);
    }
    this.apicall.getExcludedProductsCount(this.data.id).subscribe((response)=>{
      this.totalProducts=response['body']['totalCount'];
      if (this.totalProducts === this.ProductArrayId.length) {
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("All products can not be excluded. Remove some products for the game to run.", ['SHARED_PAGE']), 2000);
        return 0;
      } else {
        this.temporaryProductId=this.ProductArrayId
        this.temporaryCategoryId=this.SelectedCategory
  
        let k = {
          CategoryId: this.temporaryCategoryId,
          ProductId: this.temporaryProductId,
          AllCategoryId: this.CategoryArrayId,
        };
        let reqbody = {
          excludedProductElements: {
            all: false,
            categories:
            this.temporaryCategoryId,
            products: this.temporaryProductId,
            allcategories: this.CategoryArrayId,
          },
        };
        this.apicall.updateGame(reqbody, this.data.id).subscribe((response)=>{
          if(response['body']['qbErrors'] == null){
            this.dialogRef.close(k);
          }
        else{
          document.getElementById('qbGmExcludedProducts').innerHTML="";

          for(const x in response['body']['qbErrors'])
            document.getElementById(response['body']['qbErrors'][x]['uiField']).innerHTML = response['body']['qbErrors'][x]['message'];
        }
        })
        
      }
    })
  }
  temp = [];
  generateValue() {
    //To generate the value from the api and show it.
    this.temporaryCategoryId=this.x['productsAndCategory'][0].categoryIds;
    this.temporaryProductId=this.x['productsAndCategory'][0].productIds;
    this.SelectedCategory = this.x['productsAndCategory'][0].categoryIds;
    this.ProductArrayId = this.x['productsAndCategory'][0].productIds;
    if(this.search.length >0){

    }
    else{}
    this.CategoryArrayId = [];
    var i;
    for (i = 0; i < this.SelectedCategory.length; i++) {
      this.SelectedCategory[i] = this.SelectedCategory[i].toString();
      this.populateCategoryArrayId(this.SelectedCategory[i]);
    }
    for (i = 0; i < this.ProductArrayId.length; i++) {
      this.ProductArrayId[i] = this.ProductArrayId[i].toString();
      this.findNonCategoryProduct(this.ProductArrayId[i]);
    }
    for (i = 0; i < this.ProductArrayId.length; i++) {
      this.ProductArrayId[i] = this.ProductArrayId[i].toString();
      this.findCategoryOfProduct(this.ProductArrayId[i]);
    }
  }
  populateCategoryArrayId(cid) {
    //To populate the CategoryId when the whole Category is selected.
    for (var i = 0; i < this.ProductAllIds.length; i++) {
      if (this.ProductAllIds[i]["id"] === cid) {
        for (var j = 0; j < this.ProductAllIds[i]["products"].length; j++) {
          this.CategoryArrayId.push(cid);
          this.temp.push(this.ProductAllIds[i]["products"][j]["id"]);
        }
      }
    }
  }
  findCategoryOfProduct(pid) {
    //To find ProductId and populate the CategoryId
    if (this.temp.indexOf(pid) === -1) {
      for (var i = 0; i < this.ProductAllIds.length; i++) {
        for (var j = 0; j < this.ProductAllIds[i]["products"].length; j++) {
          if (this.ProductAllIds[i]["products"][j]["id"] === pid) {
            this.CategoryArrayId.push(this.ProductAllIds[i]["id"]);
          }
        }
      }
    }
  }
  totalNoOfProducts = 0;
  calculateTotalNumberOfProducts(num) {
    //To calculate the total number of products
    this.totalNoOfProducts = this.totalNoOfProducts + num;
    if (this.totalNoOfProducts === 0) {
      this.DataAvailability = false;
    } else {
      this.DataAvailability = true;
    }
  }
  findNonCategoryProduct(pid) {
    //To find and populate the Non category products
    for (var i = 0; i < this.NonCategoryProduct.length; i++) {
      if (this.NonCategoryProduct[i]["id"] === pid) {
        this.CategoryArrayId.push("NON_CATEGORY");
      }
    }
  }
  selectAllCategoryAndProduct(event) {
    //To select all the category and non-categoryProducts
    if (event) {
      this.ProductArrayId = [];
      this.CategoryArrayId = [];
      this.SelectedCategory = [];
      for (var i = 0; i < this.ProductAllIds.length; i++) {
        if (
          this.SelectedCategory.indexOf(this.ProductAllIds[i]["id"]) === -1 &&
          this.ProductAllIds[i]["products"].length != 0
        ) {
          this.SelectedCategory.push(this.ProductAllIds[i]["id"]);
        }
        for (var j = 0; j < this.ProductAllIds[i]["products"].length; j++) {
          if(this.ProductArrayId.indexOf(this.ProductAllIds[i]["products"][j]["id"]) == -1){
            this.ProductArrayId.push(this.ProductAllIds[i]["products"][j]["id"]);
          }
          this.CategoryArrayId.push(this.ProductAllIds[i]["id"]);
        }
      }

      if (this.nonCategoryCount == this.NonCategoryProduct.length) {
        for (var i = 0; i < this.NonCategoryProduct.length; i++) {
          this.ProductArrayId.push(this.NonCategoryProduct[i]["id"]);
          this.CategoryArrayId.push("NON_CATEGORY");
        }
      } else {
      }
    } else {
      this.ProductArrayId = [];
      this.CategoryArrayId = [];
      this.SelectedCategory = [];
    }
  }
  getAllProductInStore() {
    this.apiloading = true;
    this.apicall
      .getProductNotInCategory(this.page, this.size)
      .subscribe((response) => {
        this.NonCategoryProduct = response["body"]["elements"];
        this.calculateTotalNumberOfProducts(response["body"]["count"]);
        this.nonCategoryCount = response["body"]["count"];
      });
  }
  setAllCheckedNonCategory(event, NonCategoryProduct, CategoryId) {
    if (event === false && this.SelectedCategory.indexOf(CategoryId) !== -1) {
      const index = this.SelectedCategory.indexOf(CategoryId);
      this.SelectedCategory.splice(index, 1);
    }
    var i;
    for (i = 0; i < NonCategoryProduct.length; i++) {
      if (
        event == 1 &&
        this.ProductArrayId.indexOf(NonCategoryProduct[i]["id"]) === -1
      ) {
        this.updateSelection(NonCategoryProduct[i]["id"], 1, CategoryId, []);
      } else if (
        event == 0 &&
        this.ProductArrayId.indexOf(NonCategoryProduct[i]["id"]) != -1
      ) {
        this.updateSelection(NonCategoryProduct[i]["id"], 0, CategoryId, []);
      }
    }
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.pageNon += 1;
      this.getProduct(this.pageNon, this.size); // get next page on scroll
    }
  }
  getProduct(pg, size) {
    this.apicall
      .getProductNotInCategory(pg, this.size)
      .subscribe((response) => {
        let body = response["body"]["elements"];
        body.forEach((element) => {
          this.NonCategoryProduct.push(element);
        });
      });
  }
  getAllNonCategory() {
    this.pageNon += 1;
    var check;
    this.apiloading = true;
    this.apicall
      .getProductNotInCategory(this.pageNon, this.size)
      .subscribe((response) => {
        let body = response["body"]["elements"];
        body.forEach((element) => {
          this.NonCategoryProduct.push(element);
        });
        if (response["body"]["elements"].length != 0) {
          check = true;
          return this.getAllNonCategory();
        } else {
          this.generateValue();
          this.apiloading = false;
        }
      });
  }
  previousData="";
  timeout;
  getInputField(data){
    // if (data && data.length >= 1 && data.length > this.previousData.length) {
    //   this.getCategory(data);
    // }
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {

        if (data && data.length >= 3 && data.length > $this.previousData.length) { // check if input field value is greater than 3 and greater than previous value
          $this.getCategory(data);
        }
        else{
          $this.getCategory('');
        }
        $this.previousData = data || ""; // update previous data to current data after backend call.
      // }
    }, 600);
  }

  
}
