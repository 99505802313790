import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { ConnectPaypalComponent, PartnershipRewardCreationDialogboxComponent, SuccessDialogComponent, PartnershipInitiateDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-partner-details",
  templateUrl: "./partner-details.component.html",
  styleUrls: ["./partner-details.component.css"],
})
export class PartnerDetailsComponent implements OnInit {
  action;
  partnerData;
  timeRange1 = "All Time";
  timeRange2 = "All Time";
  link;
  isInitiatePartnership;
  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    partnershipRequestId: -1,
    startDate: this.datePipe.transform(
      new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString(),
      "yyyy-MM-dd"
    ),
    endDate: this.datePipe.transform(
      new Date(Date.now()).toISOString(),
      "yyyy-MM-dd"
    ),
    dataPointType: "WEEK",
  };
  loading = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private matDialog: MatDialog,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    this.getParams();
  }

  getSourceMerchantStats() {
    this.apiCall.getSourceMerchantStats(this.data).subscribe((response) => {
      this.loading = false;
      this.partnerData = response["body"];
      this.link = "https://" + this.partnerData["shopURL"];
    });
  }

  getTargetMerchantStats() {
    this.apiCall.getTargetMerchantStats(this.data).subscribe((response) => {
      this.loading = false;
      this.partnerData = response["body"];
      this.link = "https://" + this.partnerData["shopURL"];
    });
  }

  getParams() {
    this.activatedRoute.params.subscribe((param) => {
      this.action = param.action;
      this.data.partnershipRequestId = param.id;
      this.isInitiatePartnership = param.initiatePartnership;
      this.getMerchantData();
      if (this.action === "view") this.getSourceMerchantStats();
      else this.getTargetMerchantStats();
    });
  }

  //To check if the merchant has agreed to the partnership terms and conditions
  getMerchantData() {
    this.apiCall.getMerchantProfileData().subscribe((response) => {
      if (response["body"]["partnershipConsent"] != true) {
        if (this.action === "view") {
          this.router.navigate([
            "/app/partnership",
            {
              action: this.action,
              id: this.data.partnershipRequestId,
            },
          ]);
        }
      }
    });
  }

  openConnectToPaypalDialog() {
    const d = this.matDialog.open(ConnectPaypalComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
    });
    d.afterClosed().subscribe((res) => {
      if (res) {
        this.processPartnershipRequest(null);
      }
    });
  }

  processPartnershipRequest(store) {
    this.apiCall
      .processPartnershipRequest(
        this.data.partnershipRequestId,
        "REQUEST_ACCEPTED",
        null,
        null,
        null,
        null,
        null,
        false
      )
      .subscribe((response) => {
        this.rerouteToMyPartners(store);
      });
  }

  acceptRequest(store) {
    this.apiCall.getPaypalID().subscribe((response) => {
      if (response["body"] === "Paypal id doesn't exist") {
        this.openConnectToPaypalDialog();
      } else {
        this.processPartnershipRequest(store);
      }
    });
  }

  rerouteToMyPartners(store) {
    if (store !== null)
      this.router.navigate([
        "/app/partnership/my-partners/" + store,
        {
          id: this.data.partnershipRequestId,
          action: "OFFER_NOT_SHARED",
          type: "source",
        },
      ]);
    else this.router.navigate(["/app/partnership/my-partners"]);
  }

  createForm(id) {
    this.router.navigate([
      "/app/partnership/form-list",
      {
        request: id,
      },
    ]);
  }

  openRewardCreationDialog() {
    const dialogRef = this.matDialog.open(
      PartnershipRewardCreationDialogboxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "540px",
        height: "228x",
        data: {
          name: this.partnerData.partnerName,
          targetId: this.partnerData.sourceMerchantId,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSuccessDialog();
      } else {
        dialogRef.close();
      }
    });
  }

  openSuccessDialog() {
    const dialogRef2 = this.matDialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "25%",
      data: {
        message: this._i18nDynamicTranslate.transform("Request sent successfully", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Continue to my partnership request page", ['POS_PAGE']),
      },
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if (result) {
        this.rerouteToPartnershipRequests();
      } else {
        dialogRef2.close();
        this.getSourceMerchantStats();
      }
    });
  }

  rerouteToPartnershipRequests() {
    this.router.navigate(["/app/partnership/partnership-requests"]);
  }

  initiatePartnership() {
    const dialog = this.matDialog.open(PartnershipInitiateDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "228x",
      data: {
        name: this.partnerData.partnerName,
        targetId: this.partnerData.sourceMerchantId,
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.openRewardCreationDialog();
      } else if (result == null) {
        this.openSuccessDialog();
      } else {
        dialog.close();
      }
    });
  }
}
