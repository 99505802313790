<div class="container">
    <form [formGroup]="dataForm">
        <div>
            <div *ngIf="data.nodeType === 'e-t'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.EVENT_NAME' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class" formControlName="eventName">
                                <mat-option *ngFor="let event of data.customerEvents" class="font-class" [value]="event.eventName">
                                    {{event.displayName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 't-b'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.TYPE' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class" formControlName="timeBasedType">
                                <mat-option class="font-class" value="Recurring">{{'SHARED_PAGE.RECURRING' | i18nTranslate}}</mat-option>
                                <mat-option class="font-class" value="Scheduled">{{'SHARED_PAGE.SCHEDULED' | i18nTranslate}}</mat-option>
                                <mat-option class="font-class" value="Instant">{{'SHARED_PAGE.INSTANT' | i18nTranslate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div style="margin-top: 10px; margin-bottom: 15px;" *ngIf="dataForm.get('timeBasedType').value === 'Recurring' || dataForm.get('timeBasedType').value === 'Scheduled'">
                        <div style="display:flex; justify-content:space-between;">
                            <div style="width: 48%;">
                                <span class="headText">{{'SHARED_PAGE.START_DATE' | i18nTranslate}}</span>
                                <div class="formDiv" style="margin-top: 10px;">
                                    <input style="margin-left: 5%;" formControlName="startDate" class="formInput" matInput [matDatepicker]="picker1" placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </div>
                            </div>
                            <div style="width: 48%;">
                                <span class="headText">{{'SHARED_PAGE.START_TIME' | i18nTranslate}}</span>
                                <div class="formDiv" style="margin-top: 10px;">
                                    <input style="margin-left: 5%;" class="formInput" formControlName="startTime" [format]="serviceCountry === 'US' ? 12 : 24" [ngxTimepicker]="picker1T">
                                    <ngx-material-timepicker #picker1T></ngx-material-timepicker>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex; justify-content:space-between; margin-top: 10px;" *ngIf="dataForm.get('timeBasedType').value === 'Recurring'">
                            <div style="width: 48%;">
                                <span class="headText">{{'SHARED_PAGE.END_DATE' | i18nTranslate}}</span>
                                <div class="formDiv" style="margin-top: 10px;">
                                    <input style="margin-left: 5%;" formControlName="endDate" class="formInput" matInput [matDatepicker]="picker2" placeholder="Choose a date">
                                    <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                            </div>
                            <div style="width: 48%;">
                                <span class="headText">{{'SHARED_PAGE.END_TIME' | i18nTranslate}}</span>
                                <div class="formDiv" style="margin-top: 10px;">
                                    <input style="margin-left: 5%;" class="formInput" formControlName="endTime" [format]="serviceCountry === 'US' ? 12 : 24" [ngxTimepicker]="picker2T">
                                    <ngx-material-timepicker #picker2T></ngx-material-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 10px; margin-bottom: 15px;" *ngIf="dataForm.get('timeBasedType').value === 'Recurring'">
                        <span class="headText">{{'SHARED_PAGE.REPEAT' | i18nTranslate}}</span>
                        <div class="formDiv" style="margin-top: 10px;">
                            <mat-select class="font-class" formControlName="repeatType">
                                <mat-option class="font-class" value="DAY">{{'SHARED_PAGE.DAY' | i18nTranslate}}</mat-option>
                                <mat-option class="font-class" value="WEEK">{{'SHARED_PAGE.WEEK' | i18nTranslate}}</mat-option>
                                <mat-option class="font-class" value="MONTH">{{'SHARED_PAGE.MONTH' | i18nTranslate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 'r-e'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.EVENT_NAME' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class" formControlName="eventName">
                                <mat-option *ngFor="let event of data.customerEvents" class="font-class" [value]="event.eventName">
                                    {{event.displayName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div>
                    <span class="headText">{{'SHARED_PAGE.SHOULD_BY' | i18nTranslate}}</span>
                    <div style="display:flex; justify-content:space-between; margin-top: 10px; margin-bottom: 15px;">
                        <div style="width:25%;">
                            <span style="font-size: 13px; color: #959595;">{{'SHARED_PAGE.DAYS' | i18nTranslate}}</span>
                            <div class="formDiv" style="margin-top: 4px;">
                                <input type="number" style="width: 95%;" class="formInput" formControlName="days">
                            </div>
                        </div>
                        <div style="width:25%;">
                            <span style="font-size: 13px; color: #959595;">{{'SHARED_PAGE.HOURS' | i18nTranslate}}</span>
                            <div class="formDiv" style="margin-top: 4px;">
                                <mat-select style="width: 95%;" formControlName="hours">
                                    <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div style="width:25%;">
                            <span style="font-size: 13px; color: #959595;">{{'SHARED_PAGE.MINUTES' | i18nTranslate}}</span>
                            <div class="formDiv" style="margin-top: 4px;">
                                <mat-select style="width: 95%;" formControlName="minutes">
                                    <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 'e-c'">
                <!-- <div>
          <span class="headText">Content</span>
          <div style="margin-top: 10px; margin-bottom: 15px;">
            <div class="formDiv">
              <mat-select class="font-class"  formControlName="emailContentType">
                <mat-option class="font-class" value="EMAIL">Email</mat-option>
                <mat-option class="font-class" value="OFFER_DISPATCH">Offer</mat-option>
              </mat-select>
            </div>
          </div>
        </div> -->
                <div>
                    <span class="headText">{{'SHARED_PAGE.EMAIL_SUBJECT' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <input class="formInput" type="text" formControlName="emailSubject">
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="dataForm.get('emailContentType').value === 'OFFER_DISPATCH'">
          <span class="headText">Offer Type</span>
          <div style="margin-top: 10px; margin-bottom: 15px;">
            <div class="formDiv">
              <mat-select class="font-class"  formControlName="offerId">
                <mat-option *ngFor="let offer of data.offers" class="font-class" [value]="offer.id">
                  {{offer.offerName}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div> -->
                <div>
                    <span class="headText">{{'SHARED_PAGE.EMAIL_TYPE' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class" formControlName="emailLayoutId">
                                <mat-option *ngFor="let email of data.emailLayouts" class="font-class" [value]="email.id">
                                    {{email.layoutName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 's-o'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.OFFER_TYPE' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class" formControlName="offerId">
                                <mat-option *ngFor="let offer of data.offers" class="font-class" [value]="offer.id">
                                    {{offer.offerName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 's-s'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.SURVEY_TEMPLATE' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class" formControlName="surveyTemplateId">
                                <mat-option *ngFor="let survey of data.surveyTemplates" class="font-class" [value]="survey.id">
                                    {{survey.layoutName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <!-- <div>
                    <span class="headText">Survey Title</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <input type="text" class="formInput" formControlName="surveyTitle">
                        </div>
                    </div>
                </div> -->
                <div>
                    <span class="headText">{{'SHARED_PAGE.SURVEY_EMAIL_SUBJECT' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <input type="text" class="formInput" formControlName="surveyEmailSubject">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 's-c'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.SMS_TYPE' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class">
                                <mat-option class="font-class">{{'SHARED_PAGE.CART_CREATED' | i18nTranslate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div>
                    <span class="headText">{{'SHARED_PAGE.CONTENT' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class">
                                <mat-option class="font-class">{{'SHARED_PAGE.CART_CREATED' | i18nTranslate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 'p-n'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.TYPE' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class">
                                <mat-option class="font-class">{{'SHARED_PAGE.CART_CREATED' | i18nTranslate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div>
                    <span class="headText">{{'SHARED_PAGE.CONTENT' | i18nTranslate}}</span>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                        <div class="formDiv">
                            <mat-select class="font-class">
                                <mat-option class="font-class">{{'SHARED_PAGE.CART_CREATED' | i18nTranslate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.nodeType === 'w-n'">
                <div>
                    <span class="headText">{{'SHARED_PAGE.TIME' | i18nTranslate}}</span>
                    <div style="display:flex; justify-content:space-between; margin-top: 10px; margin-bottom: 15px;">
                        <div style="width:25%;">
                            <span style="font-size: 13px; color: #959595;">{{'SHARED_PAGE.DAYS' | i18nTranslate}}</span>
                            <div class="formDiv" style="margin-top: 4px;">
                                <input type="number" style="width: 95%;" class="formInput" formControlName="days">
                            </div>
                        </div>
                        <div style="width:25%;">
                            <span style="font-size: 13px; color: #959595;">{{'SHARED_PAGE.HOURS' | i18nTranslate}}</span>
                            <div class="formDiv" style="margin-top: 4px;">
                                <mat-select style="width: 95%;" formControlName="hours">
                                    <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div style="width:25%;">
                            <span style="font-size: 13px; color: #959595;">{{'SHARED_PAGE.MINUTES' | i18nTranslate}}</span>
                            <div class="formDiv" style="margin-top: 4px;">
                                <mat-select style="width: 95%;" formControlName="minutes">
                                    <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="data.previousNodeType === 'r-e'">
            <span class="headText">{{'SHARED_PAGE.RELATION' | i18nTranslate}}</span>
            <div style="margin-top: 10px; margin-bottom: 15px;">
                <div class="formDiv">
                    <mat-select class="font-class" formControlName="relationType">
                        <mat-option class="font-class" value="NEXT">{{'SHARED_PAGE.NEXT_NODE' | i18nTranslate}}</mat-option>
                        <mat-option class="font-class" value="TIMEOUT">{{'SHARED_PAGE.TIMEOUT' | i18nTranslate}}</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="errorMessage" class="errorMessage">
        <span>{{errorMessage}}</span>
    </div>
    <div style="display:flex; justify-content:space-between; width: 100%;">
        <span class="dismiss-button" (click)="closeDialog(false)">{{'SHARED_PAGE.DISMISS' | i18nTranslate}}</span>
        <span class="done-button" (click)="closeDialog(true)" widget-id="16" event-id="1" type="button" [attr.object-type]="data['strategyType']" [attr.data-strategy-action]="node_event">Done</span>
    </div>
</div>