<div class="qb-redirect">
    <div style="display: flex; justify-content: center; margin-top: 75px;" class="qb-w-100" *ngIf="!failed">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px;" *ngIf="apiloading && !failed">
        {{'PROFILE_PAGE.REDIRECT_PAYMENT_PAGE' | i18nTranslate}}
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px;" *ngIf="failed">
        <div>
            {{'PROFILE_PAGE.SOMETHING_WRONG' | i18nTranslate}}
        </div>
        <div>
            {{'PROFILE_PAGE.REDIRECT_TO_PLAN' | i18nTranslate : timertoRedirect}}
        </div>
    </div>
</div>
<!-- 
<div class="qb-redirect">
    <div style="display: flex; justify-content: center; margin-top: 75px;" class="qb-w-100" *ngIf="!failed">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px;" *ngIf="apiloading && !failed">
        Exporting Customer Data
    </div>
</div> -->