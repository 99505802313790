import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';



@Component({
  selector: 'app-sms-preview-id-dialog',
  templateUrl: './sms-preview-id-dialog.component.html',
  styleUrls: ['./sms-preview-id-dialog.component.css']
})
export class SmsPreviewIdDialogComponent implements OnInit {

  smsBody;
  loading:boolean=false;

  constructor(public dialogRef: MatDialogRef<SmsPreviewIdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private _ApiCallService: ApiCallService) { }

  ngOnInit() {
    this.getSmsData()
  }

  getSmsData(){
    this.loading=true;
    this._ApiCallService.getSmsTemplate(this.data).subscribe((response)=>{
      if(response['message']=="success"||response['message']=="SUCCESS")
      this.smsBody=response['body'];
      this.loading=false;
    })
  }

}
