import { Component, OnInit, HostListener } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { PopUpDialogService } from 'src/app/core/services/pop-up-dialog.service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';


class ComparisonStatement {
  constructor(segmentVariable,logicalOperator?) {
    this.logicalOperator = logicalOperator;
    this.category = segmentVariable.category;
    this.setOperand1(segmentVariable);
  }
  setOperand1(operand1) {
    this.operand1 = operand1;
    this.operator = operand1.operators[0];
    this.operand2.type = operand1.dataType.toLowerCase();
    this.operand2.value = null;
    this.hasError = false;
  }
  displayMode = 'CREATE';
  category;
  operand2Category;
  operand1;
  operand2 = {
    value:null,
    type:'',
  };
  operator;
  operandStartN;
  operandEndN;
  hasOperandStartN = false;
  hasOperandEndN = false;
  hasError = false;
  hasFutureDate = false;
  logicalOperator?;//logicalOperator - Dynamically added if combined with other statements
}

class LogicalExpression {
  constructor(segmentVariable,logicalOperator?) {
    this.logicalOperator = logicalOperator;
    this.comparisonStatements = [new ComparisonStatement(segmentVariable)];
  }
  comparisonStatements :ComparisonStatement[];
  logicalOperator?//logicalOperator - Dynamically added if combined with other logicalExpressions
};




@Component({
  selector: 'app-new-create-segments',
  templateUrl: './new-create-segments.component.html',
  styleUrls: ['./new-create-segments.component.css']
})
export class NewCreateSegmentsComponent implements OnInit {
  @ViewChild('mainContainer') mainContainer: ElementRef;
  
  constructor(private apiCall: ApiCallService,
    private renderer: Renderer2, 
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private popUp: PopUpDialogService,
    private elRef: ElementRef,
    private date_format: getdateformatService) { }

  segmentVariables;
  segmentVariableList;
  segementVariableCategoryList = [];
  loading = true;
  loadingText = '';
  logicalExpressions = [];
  apiProductList: any = [];

  apiCampaignList: any = [];
  // apiOfferList: any = [];
  apiSurveyList: any = [];

  modifierProductList: any = [];
  viewList: any = [];
  productListApiData = {
    pageNo:1,
    pageSize:1500,
    maxProductListPrize:null,
  }
  modifierListApiData = {
    pageNo:1,
    pageSize:150,
    maxModifierListPrize:null,
  }
  segmentName = '';
  isSegmentNameInvalid = false;
  showSavePopup = false;
  showPreviewPopup = false;
  storeList = [];
  selectedStores = [];
  storeAvailability = false;
  qId;
  templateId;
  isStoresLoading = true;
  isProductsLoading = false;
  isModifiersLoading = false;
  flowMode = '';
  isSaving = false;
  op2ListSearch = '';
  previewLoading = false;
  segmentCustomerCount;
  segmentErrortext;
  productCategoryMap = {};
  emailSenderCategoryMap = {};
  tagList = [];
  
  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.hideAllDropdowns(event);
  }
  

  ngOnInit(): void {
    //qId is null if user is admin store thus allowing store addition
    this.qId = this.tokenStorage.getqId()=='null'?null:this.tokenStorage.getqId();
    //getgetCustomerSegmentVariables is called internally when both stores API is loaded
    this.getProduct('');
    this.getStores();
    this.getModifiers('');
    this.getEmailSenderList();
    this.getCustomerTags();
    //getgetCustomerSegmentVariables is called internally when stores API is loaded
  }

  getEmailSenderList() {
    this.apiCall.getEmailSenderList().subscribe((response)=> {
      console.log(response);
      this.apiCampaignList = response['body']['CAMPAIGNLIST'];
      // this.apiOfferList = response['body']['OFFERLIST'];
      this.apiSurveyList = response['body']['SURVEYLIST'];
      this.emailSenderCategoryMap = {
        'CAMPAIGNLIST': this.apiCampaignList,
        // 'OFFERLIST': this.apiOfferList,
        'SURVEYLIST': this.apiSurveyList,
      };
    },(error)=> {
      this.snackBar.openSnackBar('Error fetching email senders data',3000);
      this.emailSenderCategoryMap = {
        'CAMPAIGNLIST': this.apiCampaignList,
        // 'OFFERLIST': this.apiOfferList,
        'SURVEYLIST': this.apiSurveyList,
      };
    });
  }

  getStores(){
    this.isStoresLoading = true;
    this.loadingText = 'Loading stores...'
    this.apiCall.getStoreData().subscribe((response)=>{
      this.storeList = response['body'];
      if(this.storeList.length != 0) {
        this.storeAvailability = true;
      }
      else
        this.storeAvailability = false;
        this.isStoresLoading = false;
        this.getCustomerSegmentVariables();
    },(error)=>{
        this.storeAvailability = false;
        this.storeList = [];
        this.isStoresLoading = true;
        this.snackBar.openSnackBar('Error fetching store data',3000);
    })
  }

  getCustomerSegmentVariables() {
    this.loading = true;
    this.loadingText = 'Loading parameters...'
    this.apiCall.getCustomerSegmentVariables().subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.segmentVariables = response["body"];

          //create category list and segmentVariableList
          this.segementVariableCategoryList = Object.keys(this.segmentVariables);
          console.log(this.segementVariableCategoryList);
          this.segmentVariableList = [];
          for(let cat of this.segementVariableCategoryList) {
            //check for special variable
            // if()
            for(let segmentVariable of this.segmentVariables[cat]) {
              this.segmentVariableList.push({...segmentVariable,category:cat});
            }
          }

          this.route.params.subscribe((param) => {
            this.templateId = param["id"];
            if (this.templateId === "null") {
              //If new segment is being created
              this.templateId = null;
              this.addLogicalExpression(null);
              //If admin add all stores else add just qId Store
              if(!this.qId) {
                this.addAllStores(true);
              } else {
                this.updateStoreData(true,this.storeList.find((store)=>store.qid === this.qId));
              }
              this.loading = false;
            } else {
              //Edit or Clone
              if(param['clone'] === 'true') {
                this.flowMode = 'CLONE';
              } else {
                this.flowMode = 'EDIT';
              }
              this.fetchAndPopulateSegmentData();
              //fetchAndPopulateSegmentData will turn loading off
            }
          });
        } else {
          this.loading = true;
        }
      },
      (err) => {
        this.snackBar.openSnackBar('Error fetching segment variables',3000);
        this.loading = true;
      }
    );
  }

  getProduct(char, type?) { // get product list
    this.isProductsLoading = true;
    // this.loadingText = 'Loading products...'
    this.apiCall
      .getOfferManagementProductsList(char,this.productListApiData.pageNo,this.productListApiData.pageSize)
      .subscribe((resultList) => {
        let data = (resultList["body"]);
        // this.previousChar = this.currentChar;
        this.productListApiData.maxProductListPrize = parseInt(Object.keys(data)[0]);
        data[Object.keys(data)[0]].forEach((product)=>this.apiProductList.push(product.productName));
        //lazy fetch other products
        if(this.productListApiData.maxProductListPrize > this.productListApiData.pageNo * this.productListApiData.pageSize) {
          this.productListApiData.pageNo++;
          this.getProduct('');
        }
        //after getting all products
        if(this.productListApiData.maxProductListPrize == this.apiProductList.length) {
          this.isProductsLoading = false;
          this.loadingText = '';
          this.productCategoryMap['PRODUCTLIST'] = this.apiProductList;
          // if(!this.isStoresLoading) {
          //   this.getCustomerSegmentVariables();
          // }
        }
      }, (error) => {
        this.productCategoryMap['PRODUCTLIST'] = this.apiProductList;
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  2000);
        this.isProductsLoading = false;
       });
  }

  getModifiers(char){
    this.isModifiersLoading = true;
    // this.loadingText = 'Loading products...'
    this.apiCall
      .getModifierList(char,this.modifierListApiData.pageNo,this.modifierListApiData.pageSize)
      .subscribe((resultList) => {
        let data = (resultList["body"]);
        // this.previousChar = this.currentChar;
        this.modifierListApiData.maxModifierListPrize = parseInt(Object.keys(data)[0]);
        data[Object.keys(data)[0]].forEach((product)=>this.modifierProductList.push(product.productName));
        //lazy fetch other modifiers
        if(this.modifierListApiData.maxModifierListPrize > this.modifierListApiData.pageNo * this.modifierListApiData.pageSize) {
          this.modifierListApiData.pageNo++;
          this.getModifiers('');
        }
        //after getting all modifiers
        if(this.modifierListApiData.maxModifierListPrize == this.modifierProductList.length) {
          this.productCategoryMap['MODIFIERLIST'] = this.modifierProductList;
          this.isModifiersLoading = false;
        }
      }, (error) => {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  2000);
        this.isModifiersLoading = false;
       });
  }

  fetchAndPopulateSegmentData() {
    this.loading = true;
    this.loadingText = 'Fetching your segment definition...'
    this.apiCall.getCustomerSegment(this.templateId).subscribe(
      (response) => {
        let fetchedLogicalExpressions = response['body']['segmentDefinition']['logicalExpressions'];
        if(this.flowMode === 'CLONE') {
          this.segmentName = this.addClonedSuffix(response['body']['segmentName']);
          this.templateId = null;
          if(!this.qId) {
            //Find and preselect stores from storelist
            if(response['body']['stores']) {
              response['body']['stores'].forEach((store1)=>{
                let storeFromStoreList = this.storeList.find((store2)=>store1.qid==store2.qid);
                this.updateStoreData(true,storeFromStoreList);
              });
            } else {
              this.addAllStores(true);
            }
          } else {
            if(response['body']['stores']) {
              this.updateStoreData(true,this.storeList.find((store)=>store.qid === this.qId));
            } else {
              this.updateStoreData(true,this.storeList.find((store)=>store.qid === this.qId));
            }
          }
        } else {
          this.segmentName = response['body']['segmentName'];
          //Find and preselect stores from storelist
          response['body']['stores'].forEach((store1)=>{
            let storeFromStoreList = this.storeList.find((store2)=>store1.qid==store2.qid)
            this.updateStoreData(true,storeFromStoreList);
          });
        }
  
        //Creating request segmentDefinition
        fetchedLogicalExpressions.forEach((LE)=>{
          let curLe = new LogicalExpression(this.segmentVariableList[0],LE.logicalOperator);
          curLe.comparisonStatements = [];
          LE.comparisonStatements.forEach((CS)=>{
            let curCs = new ComparisonStatement(this.segmentVariableList[0],CS.logicalOperator);
            let reqSegmentVariable = this.segmentVariableList.find((obj)=>obj.name ===  CS.operand1);
            curCs.category = reqSegmentVariable.category;
            this.setAttribute('PARAMETER',reqSegmentVariable,curCs);
            curCs.setOperand1(reqSegmentVariable);
            curCs.operator = reqSegmentVariable.operators.find((opObj)=>opObj.operator==CS.operator);
            if(reqSegmentVariable.dataType.toLowerCase() == 'boolean') {
              curCs.operand2.value = null;
              curCs.operand2.type = 'boolean';
            } else if(reqSegmentVariable.dataType.toLowerCase() == 'date') {
              curCs.operand2.value = new Date(CS.operand2);
              curCs.operand2.type = 'date'
            } else if(reqSegmentVariable.dataType.toLowerCase() == 'string') {
              curCs.operand2.value = CS.operand2;
              curCs.operand2.type = 'string';
            } else if(reqSegmentVariable.dataType.toLowerCase() == 'product') {
              let found;
              for( let listType in this.productCategoryMap) {
                found = this.productCategoryMap[listType]?.find(item=>item == CS.operand2);
                if(found) {
                  curCs.operand2Category = listType;
                  break;
                }
              }
              curCs.operand2.value = found;
              curCs.operand2.type = 'product';
            } else if(reqSegmentVariable.dataType.toLowerCase() == 'emailsender') {
              let found;
              for( let listType in this.emailSenderCategoryMap) {
                found = this.emailSenderCategoryMap[listType]?.find(item=>item.name == CS.operand2);
                if(found) {
                  curCs.operand2Category = listType;
                  break;
                }
              }
              curCs.operand2.value = found.name;
              curCs.operand2.type = 'emailsender';
            } else if(reqSegmentVariable.dataType.toLowerCase() == 'tag') {
              curCs.operand2.value = CS.operand2;
              curCs.operand2.type = 'tag';
            }
            else {
              curCs.operand2.value = CS.operand2;
              curCs.operand2.type = 'number';
            }
            if(CS.operandStartN) {
              curCs.hasOperandStartN = true;
              curCs.operandStartN = CS.operandStartN;
            }
            if(CS.operandEndN) {
              curCs.hasOperandEndN = true;
              curCs.operandEndN = CS.operandEndN;
            }
            curLe.comparisonStatements.push(curCs);
          });
          this.logicalExpressions.push(curLe);
        }); 
        this.loading = false;
        this.loadingText = '';
      },
      (err) => {
        this.snackBar.openSnackBar('Error getting segment definition',3000);
      }
    );
  }

  addClonedSuffix(name) {
    let maxLength = 50;
    let suffix = "-Cloned";
    let maxBaseNameLength = maxLength - suffix.length;
    return name.length <= maxBaseNameLength?name + suffix: name.substring(0, maxBaseNameLength).trim() + suffix;
  }

  isLogicalExpressionsInvalid(dontSetError?) {
    //dontSetError is used to run validation without indicating the error in the UI.
    //dontSetError is used for button disable logic
    let logExpIndex = 0;
    let firstErrorCsId;
    for(let logicalExpression of this.logicalExpressions) {
      let compIndex = 0;
      for(let comparisonStatement of logicalExpression.comparisonStatements) {
          if(comparisonStatement.category && comparisonStatement.operand1 
              && comparisonStatement.operator && comparisonStatement.operand2.value != null) {
            if(comparisonStatement.operand2.type == 'email') {
              //validateEmail if the operator doesn't contain equal
              if(comparisonStatement.operator.operator.includes('equal') && !this.validateEmail(comparisonStatement.operand2.value.trim())) {
                if(!dontSetError) comparisonStatement.hasError = 'Please enter valid email';
                firstErrorCsId ||= logExpIndex+'-'+compIndex;
              } else if(!comparisonStatement.operator.operator.includes('equal')) {
                if(!comparisonStatement.operand2.value.trim()) {
                  if(!dontSetError) comparisonStatement.hasError = 'Please enter valid input';
                  firstErrorCsId ||= logExpIndex+'-'+compIndex;
                } else {
                  comparisonStatement.operand2.value = comparisonStatement.operand2.value.trim();
                  if(!dontSetError) comparisonStatement.hasError = false;
                  compIndex++;
                  continue;
                }
              }
               else {
                comparisonStatement.operand2.value = comparisonStatement.operand2.value.trim();
                if(!dontSetError) comparisonStatement.hasError = false;
                compIndex++;
                continue;
              }
            }
            if(comparisonStatement.operand2.type == 'string') {
              if(!comparisonStatement.operand2.value.trim()) {
                if(!dontSetError) comparisonStatement.hasError = 'Please enter valid input';
                firstErrorCsId ||= logExpIndex+'-'+compIndex;
              } else {
                comparisonStatement.operand2.value = comparisonStatement.operand2.value.trim();
                if(!dontSetError) comparisonStatement.hasError = false;
                compIndex++;
                continue;
              }
            }
            if(comparisonStatement.operand1.showRange) {
              if(comparisonStatement.hasOperandStartN && comparisonStatement.operandStartN == null) {
                if(!dontSetError) comparisonStatement.hasError = 'Please enter min value';
                firstErrorCsId ||= logExpIndex+'-'+compIndex;
              }
              if(comparisonStatement.hasOperandEndN && comparisonStatement.operandEndN == null) {
                if(!dontSetError) comparisonStatement.hasError = 'Please enter max value';
                firstErrorCsId ||= logExpIndex+'-'+compIndex;
              }
              if(comparisonStatement.hasOperandStartN && comparisonStatement.hasOperandEndN) {
                if(comparisonStatement.operandStartN > comparisonStatement.operandEndN) {
                  if(!dontSetError) comparisonStatement.hasError = 'Min value has to be lesser than max';
                  firstErrorCsId ||= logExpIndex+'-'+compIndex;
                }
              }
            }
            if(!dontSetError && !firstErrorCsId) comparisonStatement.hasError = false;
            compIndex++;
            continue;
          } else {
            if(comparisonStatement.operand1.dataType.toLowerCase() != 'boolean' ) {
              if(!dontSetError) comparisonStatement.hasError = 'Please enter valid input';
              firstErrorCsId ||= logExpIndex+'-'+compIndex;
            }
          }
      }
      logExpIndex++;
    }
    return firstErrorCsId || false;
  }

  setAttribute(attributeType: string, attributeValue, comparisonStatement) {
    if(attributeType === 'CATEGORY') {
      comparisonStatement.category = attributeValue;
      this.setAttribute('PARAMETER',this.segmentVariables[comparisonStatement.category][0],comparisonStatement);
    }
    if(attributeType === 'OPERAND2CATEGORY'){
      comparisonStatement.operand2Category = attributeValue;
      if(attributeValue === 'PRODUCTLIST' || attributeValue === 'MODIFIERLIST'){
        this.viewList = this.productCategoryMap[attributeValue];
      } else {
        this.viewList = this.emailSenderCategoryMap[attributeValue];
      }
      //for emailSender
      comparisonStatement.operand2.value = this.viewList[0].name;
    }
    if(attributeType === 'PARAMETER') {
      //setOperand also preselects operator and operand2 and resets hasError
      comparisonStatement.setOperand1(attributeValue);
      //special input type cases handled here
      if(attributeValue.displayName.toLowerCase() == 'email') {
        comparisonStatement.operand2.type = 'email';
      }
      //if parameter has product datatype preselect the first product
      if(comparisonStatement.operand2.type == 'product') {
        for( let listType in this.productCategoryMap) {
          if(this.productCategoryMap[listType].length > 0) {
            this.viewList = this.productCategoryMap[listType];
            comparisonStatement.operand2Category = listType;
            comparisonStatement.operand2.value = this.viewList[0];
            break;
          }
        }
      }

      //if parameter has product datatype preselect the first product
      if(comparisonStatement.operand2.type == 'tag') {
        this.viewList = this.tagList;
        comparisonStatement.operand2.value = this.viewList[0];
      }

      //if parameter has emailsender datatype preselect the first product
      if(comparisonStatement.operand2.type == 'emailsender') {
        for( let listType in this.emailSenderCategoryMap) {
          if(this.emailSenderCategoryMap[listType].length > 0) {
            this.viewList = this.emailSenderCategoryMap[listType];
            comparisonStatement.operand2Category = listType;
            comparisonStatement.operand2.value = this.viewList[0].name;
            break;
          }
        }
      }
    }
    if(attributeType === 'OPERATOR') {
      comparisonStatement.hasError = false;
      comparisonStatement.operator = attributeValue;
      if(comparisonStatement.operator.operator.toLowerCase() == 'never purchased') { 
        comparisonStatement.operand1.showRange = false; 
      } else {
        comparisonStatement.operand1.showRange = this.segmentVariableList.find((obj)=>obj.name == comparisonStatement.operand1.name).showRange;
      }
    }
    if(attributeType === 'VALUE') {
      comparisonStatement.hasError = false;
      comparisonStatement.operand2.value = attributeValue;
    }
  }

  deleteComparisonStatement(compIndex,logicalExpression) {
    logicalExpression.comparisonStatements.splice(compIndex,1);
    //incase first comparisonStatement is deleted remove its logicalOperator
    if(logicalExpression.comparisonStatements[0].hasOwnProperty('logicalOperator')) {
      delete logicalExpression.comparisonStatements[0].logicalOperator;
    }
  }

  deleteLogicalExpression(logicalExpIndex) {
    this.logicalExpressions.splice(logicalExpIndex,1);
    //incase first logicalExpression is deleted remove its logicalOperator
    if(this.logicalExpressions[0].hasOwnProperty('logicalOperator')) {
      delete this.logicalExpressions[0].logicalOperator;
    }
  }

  addComparisionStatement(logicalExpression) {
    logicalExpression.comparisonStatements.push(new ComparisonStatement(this.segmentVariableList[0],'AND'));
    let index = (this.logicalExpressions.length-1)+'-'+(logicalExpression.comparisonStatements.length-1);
    setTimeout(()=>this.scrollActiveCsToView(index),100);
    console.log(this.logicalExpressions);
  }

  selectCsCondition(logicalOp,logicalExpression) {
    for(let i=1;i<=logicalExpression.comparisonStatements.length-1;i++) {
      logicalExpression.comparisonStatements[i].logicalOperator = logicalOp;
    }
  }

  selectLeCondition(logicalOp,logicalExpression) {
    for(let i=1;i<=this.logicalExpressions.length-1;i++) {
      this.logicalExpressions[i].logicalOperator = logicalOp;
    }
  }

  addLogicalExpression(operator) {
    this.logicalExpressions.push(new LogicalExpression(this.segmentVariableList[0],operator));
    let index = (this.logicalExpressions.length-1)+'-0';
    setTimeout(()=>this.scrollActiveCsToView(index),100);
  }

  numberInputHandler(event: KeyboardEvent,maxLength,comparisonStatement?) {
    const invalidCharacters = ['e', '-', '+','.'];
    const inputValue = (event.target as HTMLInputElement).value;
    if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key)) {
      event.preventDefault();
    }
    if(comparisonStatement) comparisonStatement.hasError = false;
  }

  stringInputHandler(event: KeyboardEvent,maxLength,comparisonStatement?) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== 'Backspace') {
      event.preventDefault();
    }
    if(comparisonStatement) comparisonStatement.hasError = false;
    if(this.isSegmentNameInvalid) this.isSegmentNameInvalid = false; 
  }

  validateEmail(email: string): boolean {
    const regexPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regexPattern.test(email);
  }

  updateStoreData(event, store) {
    if(event){
      if(this.selectedStores.indexOf(store) == -1){
        this.selectedStores.push(store);
      }
    }
    else{
      if(this.selectedStores.indexOf(store) != -1){
        const index = this.selectedStores.indexOf(store);
        this.selectedStores.splice(index, 1);
      }
    }
  }

  addAllStores(event) {
    this.selectedStores = [];
    if(event){
      for(let i =0;i < this.storeList.length; i++){
        this.updateStoreData(true,this.storeList[i]);
      }
    }
    else{
      this.selectedStores = [];
    }
  }

  allStoresSelected() {
    if(this.selectedStores.length === this.storeList.length)
      return true;
    else
      return false;
  }
  
  prepareSegmentDefinition() {
    let segmentDefinition = {
      logicalExpressions : [],
    };

    //CONVERTING UI DATASTRUCTURE TO REQUEST DATASTRUCTURE
    this.logicalExpressions.forEach((LE)=>{
      let curLE = {};
      let curCSList = [];
      LE.comparisonStatements.forEach((CS)=>{
        let curCS = {};
        curCS['operandN'] = CS.operandN;
        curCS['operand1'] = CS.operand1.name;
        curCS['operator'] = CS.operator.operator;
        curCS['operandStartN'] = null;
        curCS['operandEndN'] = null;
        if(CS.operand2.type == 'date') {
          curCS['operand2'] = 
          this.datePipe.transform(
            CS.operand2.value,
            "yyyy-MM-dd HH:mm:ss"
          );
        } else if(CS.operand2.type == 'boolean') {
          curCS['operand2'] = null;
        } else if(CS.operand2.type == 'product') {
          curCS['operand2'] = CS.operand2.value; 
        } else {
          curCS['operand2'] = CS.operand2.value;
        }
        if(CS.operand1.showRange) {
          if(CS.hasOperandStartN) curCS['operandStartN'] = CS.operandStartN;
          if(CS.hasOperandEndN) curCS['operandEndN'] = CS.operandEndN;
        }
        if(CS.logicalOperator) curCS['logicalOperator'] = CS.logicalOperator;
        curCSList.push(curCS);
      });
      if(LE.logicalOperator) curLE['logicalOperator'] = LE.logicalOperator;
      curLE['comparisonStatements'] = curCSList;
      segmentDefinition.logicalExpressions.push(curLE);
    });
    return segmentDefinition;
  }

  saveSegment() {
    let id = this.templateId;
    let operation = id!=null?'update':'create';

    let data = {
      id: id,
      operation: operation,
      merchantid: this.tokenStorage.getMerchantId(),
      segmentName: this.segmentName.trim(),
      segmentDefinition: this.prepareSegmentDefinition(),
      stores: this.selectedStores,
     };
     if (operation === 'create') {
        data['createdBy'] = {
            storeId: this.tokenStorage.getqId() === "null" ? null : this.tokenStorage.getqId(),
            storeName: this.tokenStorage.getStoreName()
        };
      }
    this.isSaving = true;
    this.apiCall
    .updateCustomerSegment(data
    ).subscribe(
      (response) => {
        this.showSavePopup = false;
        this.isSaving = false;
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          width: "20%",
          disableClose: true,
          data: {
            message: this._i18nDynamicTranslate.transform("Your segment has been successfully created", ['POS_PAGE']),
            buttonText: this._i18nDynamicTranslate.transform("Back to Segment List", ['POS_PAGE']),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            let routeId = response["body"].split("=")[1];
              this.router.navigate(["/app/segments", { id: routeId }]);
          }
        });
      },
      (err) => {
        this.isSaving = false;
        this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']));
      }
    );
  }

  scrollActiveCsToView(index) {
    const mainContainer = this.mainContainer.nativeElement;
    const activCs = mainContainer.querySelector('#comparison-statement-'+index) as HTMLElement;
  
    if (activCs) {
      const elementTop = activCs.getBoundingClientRect().top - mainContainer.getBoundingClientRect().top;
      const currentScroll = mainContainer.scrollTop;
  
      mainContainer.scroll({
        top: elementTop + currentScroll,
        behavior: 'smooth'
      });
    }
  }

  dateChanged(selectedDate: Date,comparisonStatement) {
    comparisonStatement.hasError = false
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      comparisonStatement.hasFutureDate = true;
    } else {
      comparisonStatement.hasFutureDate = false;
    }
  }

  validateAndOpenSavePopup() {
    let res = this.isLogicalExpressionsInvalid();
    if(!res) {
      if(this.segmentName && this.segmentName.length>=3) {
        this.showSavePopup = true;
        this.isSegmentNameInvalid = false;
        this.segmentErrortext = null;
      } else {
        this.isSegmentNameInvalid = true;
        if(!this.segmentName) {
          this.segmentErrortext = "Please enter segment name";
        }
        if(this.segmentName && !(this.segmentName.length >= 3)) {
          this.segmentErrortext = "Minimum 3 characters";
        }
      } 
    } else {
      setTimeout(()=>this.scrollActiveCsToView(res),100);
    }
  }

  loadPreviewPopup() {
    this.previewLoading = true;
    let data = {
      segmentId: null,
      stores : this.selectedStores,
      segmentDefinition: this.prepareSegmentDefinition(),
    };

    this.apiCall.getSegmentQuery(data).subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.previewLoading = false;
          this.showPreviewPopup = true;
          this.segmentCustomerCount = response['body']['customerCount'];
        } else {
          this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Failed to query segment", ['POS_PAGE']));
          this.previewLoading = false;
        }
      },
      (err) => {
        this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']));
        this.previewLoading = false;
      }
    );
  }

  closeSavePopup() {
    if(this.isSaving) {
      this.snackBar.openSnackBar('Save in progress',2000);
    } else {
      this.showSavePopup = false;
    }
  }

  hideAllDropdowns(event,exceptionIdForDropdown?,exceptionIdForDownArrow?) {
    const dropdownElements = this.elRef.nativeElement.querySelectorAll('.dropdown');
    const downArrowElements = this.elRef.nativeElement.querySelectorAll('.down-arrow');
    dropdownElements.forEach((element: HTMLElement) => {
      if(element.id != exceptionIdForDropdown) {
        this.renderer.setStyle(element, 'height', '0px');
        this.renderer.setStyle(element, 'border', 'unset');
      }
    });
    downArrowElements.forEach((element: HTMLElement)=>{
      if(element.id != exceptionIdForDownArrow) {
        this.renderer.setStyle(element, 'transform', 'rotate(0deg)');
      }
    })
  }

  toggleDropdown(event,logExpIndex,compIndex,dropdownName,operand2Category?) {
    event.stopPropagation();
    //check if click happened within category-container or within search
    if (event.target.parentElement.classList.contains('category-container')
      ||event.target.classList.contains('op-search')) {
      return; //if so dont toggle
    }
    let exceptionIdForDropdown =`${dropdownName}-dropdown${logExpIndex}${compIndex}`;
    let exceptionIdForDownArrow=`down-arrow-${dropdownName}${logExpIndex}${compIndex}`;
    const dropdownElement = this.elRef.nativeElement.querySelector(`#${exceptionIdForDropdown}`);
    const keyboardDownIconElement = this.elRef.nativeElement.querySelector(`#${exceptionIdForDownArrow}`);
    this.hideAllDropdowns(event,exceptionIdForDropdown,exceptionIdForDownArrow);

    //This is for when the segment definition has been fetchAndPopulateSegmentDataed
    if(dropdownName == 'value' && operand2Category) {
      if(operand2Category === 'PRODUCTLIST' || operand2Category === 'MODIFIERLIST'){
        this.viewList = this.productCategoryMap[operand2Category];
      } else {
        this.viewList = this.emailSenderCategoryMap[operand2Category];
      }
    }

    if(dropdownName == 'tag'){
      this.viewList = this.tagList;
    }

    if (dropdownElement.style.height === '180px') {
      this.renderer.setStyle(dropdownElement, 'height', '0px');
      this.renderer.setStyle(dropdownElement, 'border', 'unset');
      this.renderer.setStyle(keyboardDownIconElement, 'transform', 'rotate(0deg)');
      this.op2ListSearch = '';
    } else {
      this.renderer.setStyle(dropdownElement, 'height', '180px');
      this.renderer.setStyle(dropdownElement, 'border', '1px solid #636466');
      this.renderer.setStyle(keyboardDownIconElement, 'transform', 'rotate(180deg)');
    }
  }

  getDisplayName(value){
    switch(value){
      case 'PRODUCTLIST':
        return 'Product List';
      case 'MODIFIERLIST':
        return 'Modifiers';
      case 'CAMPAIGNLIST':
        return 'Journey List';
      // case 'OFFERLIST':
      //   return 'Offer List';
      case 'SURVEYLIST':
        return 'Survey List';
    }
  }

  getDateFormat(){
    return this.date_format.getDateFormat();
  }

  searchFiltered(list): any[] {
    if (!this.op2ListSearch) {
      return list;
    }
    const searchTerm = this.op2ListSearch.trim().toLowerCase();
    return list.filter(item => {
      return item.name.toLowerCase().includes(searchTerm);
    });
  }

  getCustomerTags(){
    this.apiCall.getCustomerTag().subscribe((response)=>{
      console.log(response);
      let data = response['body']['CUSTOMER_TAGS'];
      this.tagList = Object.values(data);
    })
  }

  // getNameFromEmailSenderList(id) {
  //   let item = this.apiCampaignList.find(item => item.id == id);
  //   if (item) {
  //     return item.name;
  //   }
  //   // item = this.apiOfferList.find(item => item.id == id);
  //   // if (item) {
  //   //   return item.name;
  //   // }
  //   item = this.apiSurveyList.find(item => item.id == id);
  //   if (item) {
  //     return item.name;
  //   }
  //   return null;
  // }
}
