import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { InsightsComponent } from './pages/insights/insights.component';

const routes: Routes = [
    { path: "", component: InsightsComponent,  canActivate: [WidgetRouteGaurdService],
        data: {
            titleheading: "Email Analytics",
            title: "Insights",
            id: 28,
        },  
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnalyzeRoutingModule { }
