import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'widgetLabel'
})
export class WidgetLabel implements PipeTransform {

  transform(widgets, widgetId): any {
    return widgets[widgets.findIndex(d => d.widget.id === widgetId)].widget.widgetLabel;
  }
}