import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";


@Component({
  selector: "app-add-to-suppression-list",
  templateUrl: "./add-to-suppression-list.component.html",
  styleUrls: ["./add-to-suppression-list.component.css"],
})
export class AddToSuppressionListComponent implements OnInit {
  constructor(
    private tokenStorage: TokenStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCall: ApiCallService,
    private dialogRef: MatDialogRef<AddToSuppressionListComponent>
  ) {
    window["screen-name"] = "SUPPRESSION LISTS";
  }

  email = "";
  cancel = true;
  error = "";
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
  smsno = "";
  customCountryList1 = [];
  ngOnInit() {
    this.customCountryList1.push({ ISOCode: "IN" });
    this.customCountryList1.push({ ISOCode: "US" });
    this.customCountryList1.push({ ISOCode: "SK" });
  }

  keypress() {
    this.error = "";
  }

  add() {
    if (this.data.channel == "EMAIL") {
      if (this.email.length > 0) {
        if (this.email.match(this.emailPattern.trim()) == null) {
          this.error = "Email format is incorrect";
        } else {
          let requestBody = {
            merchantId: this.tokenStorage.getMerchantId(),
            emailOrPhone: this.email,
            domainOrHeader: this.data.domain,
            platform: this.tokenStorage.getMerchantOnlineStore(),
            channel: this.data.channel,
          };
          this.apiCall.addEmailToSuppressionList(requestBody).subscribe(
            (response) => {
              this.dialogRef.close();
            },
            (error) => {
              if (error.error.body.includes("not found")) {
                this.error = error.error.body.substring(0, 19);
              } else {
                this.error = error.error.body;
              }
            }
          );
        }
      } else {
        this.error = "Email address cannot be empty";
      }
    }
    if (this.data.channel == "SMS") {
      if (this.error.length != 0) {
        return 0;
      }
      if (this.smsno.length>0) {
        let requestBody = {
          merchantId: this.tokenStorage.getMerchantId(),
          emailOrPhone: this.smsno,
          domainOrHeader: this.data.domain,
          platform: this.tokenStorage.getMerchantOnlineStore(),
          channel: this.data.channel,
        };
        this.apiCall.addEmailToSuppressionList(requestBody).subscribe(
          (response) => {
            this.dialogRef.close();
          },
          (error) => {
            if (error.error.body.includes("not found")) {
              this.error = error.error.body.substring(0, 19);
            } else {
              this.error = error.error.body;
            }
          }
        );
      } else {
        this.error = "Mobile number cannot be empty";
      }
    }
  }

  telInputObject(obj) {
    // obj.setCountry('in');
  }
  getNumber(obj) {
    console.log(obj);
    this.smsno = obj;
  }
  hasError(event) {
    if(event){
      this.error="";
    }else{
      this.error="Mobile number is not valid";
    }
  }
  onCountryChange(obj) {
  }

  keypresssms(event) {
  }
}
