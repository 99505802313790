import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { CustomerEvolutionRoutingModule } from './customer-evolution-routing.module';
import { CustomerEvolutionComponent } from './pages';
import { CustomerInsightsAnalyticsComponent } from './pages/customer-insights-analytics/customer-insights-analytics.component';


@NgModule({
  declarations: [
    CustomerEvolutionComponent,
    CustomerInsightsAnalyticsComponent
   ],
  imports: [
    SharedModule,
    CustomerEvolutionRoutingModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ]
})
export class CustomerEvolutionModule { }
    