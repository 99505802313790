import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";

import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog, DialogPosition } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { AppDateAdapter, APP_DATE_FORMATS } from "src/app/core/services/date-format.service";
import { FilterDialogService } from "src/app/core/services/filter-dialog.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { CustomerProfileComponent } from "../customer-profile/customer-profile.component";
import { debounce } from 'lodash';
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { DeleteGameDialogBoxComponent, SuccessDialogComponent, UploadCsvDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-segment-dashboard",
  templateUrl: "./segment-dashboard.component.html",
  styleUrls: ["./segment-dashboard.component.css"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class SegmentDashboardComponent implements OnInit {
  validWidgets = null;
  selected = "Purchase Frequency";
  merchantID = this.tokenService.getMerchantId();
  revenueSortBy = "All-Time";
  numberOfOrdersSortBy = "All-Time";
  revenueTime = "ALL";
  numberOfOrdersTime = "ALL";
  pageSize = 10;
  pageIndex = 1;
  customers = [];
  popupElementID;
  totalCustomers;
  sortBy = "";
  filters = {
    segments: [],
  };
  showCustomers = "all";
  searchValue = "";
  filterSegments = [];
  selectedSortBy = "Desc";
  timeout;
  sub: Subscription;
  @ViewChild("search") searchElement: ElementRef;
  revCurrency;
  mobileView;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  showFiltersDiv = false;
  segments = {};
  loading = true;
  overallTotalCustomers=null;
  isAdmin : boolean = true; 
  isleads = false;
  previousState="";
  
  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private filterData: FilterDialogService,
    private tokenStorage: TokenStorageService,
    private date_format: getdateformatService,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    public router: Router
  ) {
    window["screen-name"] = "ALL CUSTOMERS";
  }

  @HostListener("document:click", ["$event"])
  closeSegmentsDiv() {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
  }

  @HostListener('scroll', ['$event'])
onScroll(event: any) {
  const debouncedScroll = debounce(() => {
    if (this.mobileView) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 5
      ) {
        this.pageIndex += 1;
        if (this.showCustomers === 'all') {
          this.getCustomers();
        } else {
          this.searchCustomer(this.searchValue, false);
        }
      }
    }
  }, 800); // Adjust the debounce delay (in milliseconds)

  debouncedScroll();
}

  ngOnInit() {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    if(this.tokenStorage.getqId() === 'null'){
      this.isAdmin = true;
    }
    else{
      this.isAdmin = false;
    }
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    // this.getTotalCustomerCount();
    this.getWidgets();
    this.getCustomers();
    // this.getSegments();
  }

  addSegmentFilter(value) {
    if (this.filters["segments"].indexOf(value) != -1) {
      this.filters["segments"].splice(
        this.filters["segments"].indexOf(value),
        1
      );
      if (this.filters["segments"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["segments"].splice(
          this.filters["segments"].indexOf("ALL"),
          1
        );
      }
    } else {
      this.filters["segments"].push(value);
      if (value === "ALL")
        for (let key in this.segments) {
          if (this.filters["segments"].indexOf(key) == -1)
            this.filters["segments"].push(key);
        }
    }
    for (let key in this.segments) {
      if (this.filters["segments"].indexOf(key) == -1)
        if (this.filters["segments"].indexOf("ALL") != -1)
          this.filters["segments"].splice(
            this.filters["segments"].indexOf("ALL"),
            1
          );
    }
    if (value === "ALL") {
      if (this.filters["segments"].indexOf("ALL") == -1) {
        this.filters["segments"] = [];
      }
    }
  }

  clear() {
    this.filters["segments"] = [];
  }

  getSegments() {
    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      JSON.parse(response["body"]).forEach((element) => {
        this.segments[element["id"]] = element["segmentName"];
      });
      // this.loading = false;
    });
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  applyFilter() {
    this.filterSegments = this.filters["segments"];
    this.filters["segments"] = this.filters["segments"];
    this.getCustomers();
    this.closeFiltersDiv();
  }

  openFiltersDiv() {
    this.showFiltersDiv = true;
  }

  closeFiltersDiv() {
    this.showFiltersDiv = false;
  }

  openCustomerDetailsDialog(customer): void {
    const dialogRef = this.dialog.open(CustomerProfileComponent, {
      data: {
        customer: customer,
      },
      height: "95%",
      disableClose: true,
      panelClass: "customer-profile-dialog-container",
    });

    dialogRef.afterClosed().subscribe(updatedCustomerFromPopup => {
      let updatedCustomer = this.customers.find((customer)=>
        customer.merchantCustomerId == updatedCustomerFromPopup.merchantCustomerId);
      console.log(updatedCustomer);
      updatedCustomer['customerName'] = updatedCustomerFromPopup['customerName'];
      updatedCustomer['birthday'] = updatedCustomerFromPopup['birthday'];
      updatedCustomer['anniversary'] = updatedCustomerFromPopup['anniversary'];
    });
  }

  reOrder() {
    if (this.selectedSortBy === "Desc") {
      this.sort("Asc");
    } else {
      this.sort("Desc");
    }
  }

  sort(sortBy) {
    this.selectedSortBy = sortBy;
    switch (sortBy) {
      case "Asc":
        if (this.selected == "Purchase Frequency")
          this.customers.sort((a, b) =>
            a.purchaseFrequency < b.purchaseFrequency ? -1 : 1
          );
        else if (this.selected == "Number of Orders")
          this.customers.sort((a, b) =>
            a.orderQuantity < b.orderQuantity ? -1 : 1
          );
        else this.customers.sort((a, b) => (a.revenue < b.revenue ? -1 : 1));
        break;
      case "Desc":
        if (this.selected == "Purchase Frequency")
          this.customers.sort((a, b) =>
            a.purchaseFrequency > b.purchaseFrequency ? -1 : 1
          );
        else if (this.selected == "Number of Orders")
          this.customers.sort((a, b) =>
            a.orderQuantity > b.orderQuantity ? -1 : 1
          );
        else this.customers.sort((a, b) => (a.revenue > b.revenue ? -1 : 1));
    }
  }

  getCustomers(resetPagination?) {
    this.loading = true;
    if(resetPagination) this.pageIndex = 1;
    this.apiCall
      .getCustomersV2(
        this.merchantID,
        this.pageSize,
        this.pageIndex,
        this.filterSegments
      )
      .subscribe((response) => {
        this.showCustomers = "all";
        if (this.mobileView) {
          (response["body"]).forEach((element) => {
            this.customers.push(element);
          });
        } else {
          this.customers = (response["body"]['customerDataList']);
        }
        // if (
        //   this.filterSegments.indexOf("ALL") != -1 ||
        //   this.filterSegments.length == 0
        // ){
        //   // this.getTotalCustomerCount();
        //   this.totalCustomers=this.overallTotalCustomers;
        // }
        //  this.getFilteredCustomersCount();
        // this.sort(this.selectedSortBy);
        // let res;
        // this.apiCall.getAllCustomerSegments().subscribe((response) => {
        //   res = response;
        // this.customers.forEach((customer) => {
        //   let segments = [];
        //   customer["customerSegments"]?.forEach((element) => {
        //     let i = 0;
        //     (res["body"]).forEach((element2) => {
        //       if(element2["id"] === element["id"]) {
        //         i++;
        //       }
        //                 });
        //                 if(i == 0) {
        //                   segments.push(element.segmentName);
        //                 }
        //   });
        //   customer.allSegments = segments.toString();

        //   });
        // });
        this.totalCustomers = response["body"]['totalCount'];
        this.overallTotalCustomers = this.overallTotalCustomers?this.overallTotalCustomers:response["body"]['totalCount'];
        this.customers.forEach((customer)=>{
          if(customer['customerSegments'] && customer['customerSegments'].length>0) {
            customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
          } else {
            customer.segments = [];
          }
        });
        this.loading = false;
      },(err)=>{
        this.snackBar.openSnackBar('Failed to fetch custom list',5000);
      });
  }

  tableSortBy(sortFor) {
    switch (sortFor) {
      case "revenue":
        if (this.revenueSortBy === "All-Time") this.revenueTime = "ALL";
        else this.revenueTime = "30";
        break;
      case "numberOfOrders":
        if (this.numberOfOrdersSortBy === "All-Time")
          this.numberOfOrdersTime = "ALL";
        else this.numberOfOrdersTime = "30";
    }
    this.changeSortByValue();
    if (this.searchValue.length > 0)
      this.searchCustomer(this.searchValue, false);
    else this.getCustomers();
  }

  showSegments(event, i) {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
    let id = event.srcElement.parentElement.id;
    event.stopPropagation();
    let element = document
      .getElementById(id)
      .getElementsByClassName("qb-segment-popup")[0];
    element.setAttribute("style", "display:flex");
    this.popupElementID = "popup" + i;
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.showCustomers === "all") this.getCustomers();
    else this.searchCustomer(this.searchValue, false);
  }

  getTotalCustomerCount() {
    
    this.apiCall.getAnalytics(new Date(), new Date()).subscribe((response) => {
      this.totalCustomers = JSON.parse(response["body"]).numberOfCustomers;
      // this.overallTotalCustomers=JSON.parse(response["body"]).numberOfCustomers;
    });
  }

  showSearch() {
    this.searchElement.nativeElement.focus();
  }

  changeSortByValue() {
    if (this.selected == "Purchase Frequency") this.sortBy = "";
    else if (this.selected == "Number of Orders")
      this.sortBy = this.numberOfOrdersSortBy;
    else this.sortBy = this.revenueSortBy;
    this.sort(this.selectedSortBy);
  }

  onKeySearch(event: any, resetPagination) {
    clearTimeout(this.timeout);
    var $this = this;
    let searchValue = event.trim();
    if(searchValue.length !==0){
      this.timeout = setTimeout(function () {
        // if (event.keyCode != 13) {
          $this.searchCustomer(searchValue, resetPagination);
        // }
      }, 600);
    }
    else if ((this.previousState!=searchValue) && searchValue.length === 0) {//if search value is 0 and to handle only search with spaces
     this.getCustomers(resetPagination);
    }
    this.previousState =searchValue
  }

  searchCustomer(searchValue, resetPagination) {
    this.loading = true;
    if (resetPagination) this.pageIndex = 1;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .searchCustomers(
        this.merchantID,
        searchValue,
        this.revenueTime,
        this.numberOfOrdersTime,
        this.pageSize,
        this.pageIndex,
        this.filterSegments
      )
      .subscribe((response) => {
        this.showCustomers = "search";
        if (!this.mobileView) {
          this.customers = (response["body"]['customerDataList']);
        } else {
          if (this.searchValue !== searchValue) {
            this.customers = (response["body"]['customerDataList']);
          } else {
            (response["body"]['customerDataList']).forEach((element) => {
              this.customers.push(element);
            });
          }
        }
        this.searchValue = searchValue;
        this.totalCustomers = response["body"]['totalCount'];
        // this.getSearchCount(searchValue);
        // this.sort(this.selectedSortBy);
        // this.customers.forEach((customer) => {
        //   let segments = [];
        //   customer["customerSegments"].forEach((element) => {
        //     segments.push(element.segmentName);
        //   });
        //   customer.allSegments = segments.toString();
        // });
        this.customers.forEach((customer)=>{
          if(customer['customerSegments'] && customer['customerSegments'].length>0) {
            customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
          } else {
            customer.segments = [];
          }
        });
        this.loading = false;
      },(err)=>{
        this.snackBar.openSnackBar('Failed to search customer',5000);
      });
    if (searchValue === "") this.getCustomers();
  }

  getSearchCount(searchValue) {
    this.apiCall
      .getSearchCount(searchValue, this.filterSegments)
      .subscribe((response) => {
        this.totalCustomers = response["body"];
      });
      if(searchValue.length === 0){
        this.getTotalCustomerCount();
      }
  }

  getFilteredCustomersCount() {
    this.apiCall
      .getSegementPaginated(this.merchantID, this.filterSegments)
      .subscribe((response) => {
        this.totalCustomers = response["body"];
      });
  }
  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }
  deleteCustomer(email,phone, name){
    const dialogRef = this.dialog.open(DeleteGameDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "540px",
      height: "200px",
      data: {
        subject: "Delete Customer",
        "customer-name": name,
        successButtonText: "Delete",
        cancelButtonText: "Cancel",
      },
    });
    let id = "";
    let isemail = false;
    if(email){
      id = email;
      isemail = true;
    }
    else{
      id = phone;
      isemail = false;
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let data :any = {};
        // data.storeMetaId = null;
        data.customerId = id;
        data.merchantId = this.tokenService.getMerchantId();
        data.isEmail = isemail;
        data.platform = this.tokenStorage.getMerchantOnlineStore();
        this.deleteFromAllCustomer(data).then((result) => {
          this.loading =  false;
          if(result){
            console.log(result);
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Customer removed from list successfully", ['POS_PAGE']), 2000);
            this.getCustomers();
          }
        });
      } else {
        // this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']), 2000);
      }
    });
  }
  deleteFromAllCustomer(id): Promise<object>{
    return new Promise((resolve, reject) =>{
      this.apiCall.deleteCustomerFromAll(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(false);
        }
      );
    });
  }

  uploadCSV(){
    const dialogref = this.dialog.open(
      UploadCsvDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "750px",
        height : "600px",
        data: { leads: this.isleads },
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if (result.decision) {
        this.openSuccessDialog(
          result.response['body'],
          "OK"
        );
      }
    })
  }

  openSuccessDialog(message, buttonText) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: message,
        buttonText: buttonText,
      },
    });
  }

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }
}
