import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-offer-type-dialog',
  templateUrl: './select-offer-type-dialog.component.html',
  styleUrls: ['./select-offer-type-dialog.component.css']
})
export class SelectOfferTypeDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SelectOfferTypeDialogComponent>) { }
    selectedTab=1;

  ngOnInit() {
  }

  clickTab(value){
    this.selectedTab= value;
  }

  submit(decision){
    if(decision==false){

       this.dialogRef.close(decision)
    }else{
      this.dialogRef.close(this.selectedTab)
    }
  }


}
