<footer class="qb-footer-div">
    <div class="footer-left-container">
        <div class="qb-logo">
            <svg width="213" height="50" viewBox="0 0 213 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10_550)">
                <path d="M80.0101 15.7342C79.7339 15.728 79.4591 15.7788 79.2035 15.8833C78.9476 15.9878 78.7159 16.1438 78.523 16.3417C78.33 16.5395 78.1799 16.7749 78.0819 17.0333C77.9839 17.2916 77.9399 17.5674 77.953 17.8434V33.2763C77.953 38.8076 74.0617 42.8222 68.6914 42.8222C63.416 42.8222 60.2687 39.4238 60.2687 33.7265V17.8434C60.2751 17.5647 60.225 17.2875 60.1214 17.0286C60.0176 16.7698 59.8625 16.5347 59.6653 16.3375C59.4682 16.1404 59.2332 15.9852 58.9742 15.8816C58.7155 15.7778 58.4382 15.7277 58.1595 15.7342C57.8833 15.728 57.6087 15.7788 57.3528 15.8833C57.097 15.9878 56.8653 16.1438 56.6723 16.3417C56.4796 16.5395 56.3293 16.7749 56.2313 17.0333C56.1333 17.2916 56.0893 17.5674 56.1024 17.8434V34.2339C56.1024 41.846 60.8896 46.7658 68.2979 46.7658C72.2983 46.7658 75.5973 45.2396 77.953 42.3246V44.2205C77.953 44.7735 78.1727 45.3042 78.5639 45.6952C78.9548 46.0864 79.4855 46.3061 80.0385 46.3061C80.5915 46.3061 81.122 46.0864 81.5132 45.6952C81.9044 45.3042 82.1241 44.7735 82.1241 44.2205V17.8434C82.1299 17.5644 82.0792 17.2871 81.9748 17.0283C81.8707 16.7695 81.715 16.5344 81.5176 16.3373C81.3199 16.1402 81.0846 15.9851 80.8254 15.8815C80.5665 15.7778 80.2891 15.7277 80.0101 15.7342Z" fill="white"/>
                <path d="M104.227 15.2855C99.8666 15.2855 96.1318 17.1814 93.1076 20.9211V5.44559C93.1135 5.16659 93.0628 4.88932 92.9586 4.63047C92.8542 4.37161 92.6989 4.13654 92.5012 3.93946C92.3037 3.74238 92.0682 3.58731 91.8092 3.48367C91.55 3.38002 91.2727 3.32991 90.9937 3.33637C90.7174 3.33021 90.4429 3.38096 90.187 3.48546C89.9311 3.58996 89.6997 3.74597 89.5068 3.9438C89.3138 4.14164 89.1634 4.37706 89.0654 4.63543C88.9674 4.89377 88.9234 5.16957 88.9365 5.44559V44.1937C88.9335 44.4722 88.9861 44.7484 89.0911 45.0065C89.1963 45.2643 89.3517 45.4985 89.5488 45.6956C89.7457 45.8925 89.9798 46.0482 90.238 46.1531C90.4958 46.2584 90.772 46.3107 91.0505 46.3077C91.3253 46.3065 91.5968 46.2506 91.8496 46.1431C92.1024 46.0359 92.3313 45.8791 92.5226 45.6823C92.7142 45.4854 92.8645 45.2524 92.965 44.9967C93.0656 44.7411 93.114 44.4683 93.1076 44.1937V41.3497C96.0605 44.9519 99.7956 46.7721 104.227 46.7721C111.441 46.7721 118.921 40.8852 118.921 31.0311V30.9175C118.902 21.1297 111.451 15.2855 104.227 15.2855ZM103.948 19.338C109.967 19.338 114.508 24.3622 114.508 31.0264V31.1403C114.508 38.0604 110.266 42.7149 103.948 42.7149C98.6534 42.7149 92.9369 38.2642 92.9369 31.0832V30.9696C92.9369 23.784 98.6534 19.338 103.948 19.338Z" fill="white"/>
                <path d="M138.865 15.5069C135.495 15.5069 130.983 17.5781 128.025 22.0715V17.8436C128.025 17.2905 127.806 16.76 127.414 16.3689C127.023 15.9778 126.493 15.7581 125.94 15.7581C125.387 15.7581 124.856 15.9778 124.465 16.3689C124.074 16.76 123.854 17.2905 123.854 17.8436V44.2018C123.848 44.4811 123.899 44.7584 124.003 45.0176C124.107 45.2766 124.263 45.5121 124.461 45.7096C124.658 45.907 124.893 46.0623 125.153 46.1668C125.411 46.2709 125.689 46.3216 125.968 46.3157C126.244 46.3177 126.517 46.2642 126.771 46.1579C127.025 46.0518 127.256 45.895 127.448 45.6976C127.64 45.5002 127.79 45.2657 127.889 45.0087C127.989 44.7517 128.035 44.4772 128.025 44.2018V33.6131C128.025 24.2897 133.656 20.441 138.927 19.9575C139.501 19.9378 140.045 19.6941 140.442 19.2787C140.838 18.8633 141.057 18.309 141.05 17.7346C141.057 17.4437 141.006 17.1544 140.899 16.8838C140.792 16.6131 140.632 16.3667 140.429 16.159C140.225 15.9513 139.981 15.7866 139.713 15.6745C139.444 15.5625 139.156 15.5055 138.865 15.5069Z" fill="white"/>
                <path d="M146.582 4.41235C146.267 4.40918 145.954 4.46893 145.663 4.58811C145.371 4.70728 145.106 4.88348 144.883 5.10637C144.66 5.32927 144.484 5.5944 144.365 5.88624C144.245 6.17808 144.186 6.49074 144.189 6.80597V7.75393C144.189 8.06715 144.251 8.37729 144.371 8.66637C144.492 8.95545 144.669 9.21777 144.891 9.43816C145.114 9.65852 145.378 9.8326 145.668 9.9503C145.958 10.068 146.269 10.127 146.582 10.1239C146.9 10.1314 147.215 10.0759 147.51 9.96044C147.806 9.84499 148.075 9.67202 148.303 9.45149C148.531 9.23099 148.713 8.96736 148.839 8.67594C148.964 8.38452 149.03 8.07111 149.033 7.75393V6.80597C149.024 6.16399 148.761 5.55163 148.302 5.103C147.843 4.65438 147.224 4.40603 146.582 4.41235Z" fill="white"/>
                <path d="M146.581 15.7342C146.306 15.7341 146.034 15.789 145.781 15.8956C145.528 16.0022 145.299 16.1584 145.107 16.355C144.915 16.5516 144.765 16.7846 144.665 17.0402C144.565 17.2958 144.516 17.5689 144.523 17.8434V44.2016C144.518 44.4809 144.568 44.7582 144.673 45.0174C144.777 45.2764 144.932 45.512 145.13 45.7094C145.327 45.9068 145.563 46.0622 145.822 46.1666C146.081 46.2707 146.358 46.3214 146.637 46.3156C146.914 46.3211 147.188 46.2696 147.444 46.1646C147.699 46.0597 147.931 45.9029 148.123 45.7046C148.316 45.5064 148.465 45.2706 148.563 45.0122C148.66 44.7535 148.703 44.4775 148.69 44.2016V17.8434C148.696 17.5647 148.646 17.2875 148.542 17.0286C148.439 16.7698 148.283 16.5347 148.086 16.3375C147.889 16.1404 147.654 15.9852 147.395 15.8816C147.137 15.7778 146.859 15.7277 146.581 15.7342Z" fill="white"/>
                <path d="M179.231 15.7342C178.955 15.7287 178.681 15.7799 178.425 15.8847C178.17 15.9895 177.939 16.1456 177.747 16.3434C177.554 16.5412 177.405 16.7764 177.307 17.0344C177.209 17.2924 177.165 17.5678 177.179 17.8434V33.2763C177.179 38.8076 173.282 42.8222 167.912 42.8222C162.641 42.8222 159.494 39.4238 159.494 33.7265V17.8434C159.5 17.5644 159.449 17.2871 159.345 17.0283C159.241 16.7695 159.085 16.5344 158.888 16.3373C158.69 16.1402 158.455 15.9851 158.196 15.8815C157.937 15.7778 157.659 15.7277 157.38 15.7342C157.104 15.728 156.829 15.7788 156.573 15.8833C156.318 15.9878 156.086 16.1438 155.893 16.3416C155.7 16.5395 155.55 16.7749 155.452 17.0333C155.354 17.2916 155.31 17.5674 155.323 17.8434V34.2339C155.323 41.846 160.11 46.7658 167.519 46.7658C171.519 46.7658 174.818 45.2396 177.179 42.3246V44.2205C177.172 44.4995 177.222 44.7769 177.326 45.0361C177.429 45.2951 177.584 45.5306 177.781 45.728C177.978 45.9255 178.214 46.0811 178.472 46.1852C178.731 46.2897 179.009 46.3403 179.288 46.3345C179.563 46.3367 179.836 46.283 180.09 46.1766C180.345 46.0705 180.575 45.9138 180.767 45.7163C180.959 45.5189 181.11 45.2845 181.209 45.0275C181.308 44.7705 181.354 44.4959 181.345 44.2205V17.8434C181.35 17.5644 181.3 17.2871 181.196 17.0283C181.091 16.7695 180.936 16.5344 180.738 16.3373C180.541 16.1402 180.305 15.9851 180.046 15.8815C179.787 15.7778 179.51 15.7277 179.231 15.7342Z" fill="white"/>
                <path d="M212.125 42.9189L202.337 30.6901L211.732 19.0727C212.04 18.6958 212.208 18.2232 212.206 17.7361C212.207 17.2149 212.005 16.7139 211.641 16.3401C211.278 15.9664 210.783 15.7495 210.262 15.7359C209.92 15.7494 209.586 15.842 209.286 16.0065C208.986 16.171 208.728 16.4028 208.532 16.6838L199.75 27.7229L190.881 16.5511C190.685 16.295 190.433 16.0881 190.143 15.9466C189.854 15.8051 189.535 15.7329 189.213 15.7359C188.676 15.7295 188.158 15.9366 187.773 16.3116C187.388 16.6866 187.168 17.1989 187.161 17.7361C187.169 18.2532 187.365 18.7497 187.71 19.1343L197.038 30.7422L187.198 42.9757C186.889 43.3541 186.721 43.8283 186.724 44.3169C186.723 44.8373 186.925 45.3377 187.287 45.7114C187.649 46.085 188.143 46.3025 188.663 46.3173C189.006 46.3045 189.341 46.2123 189.642 46.0477C189.943 45.8832 190.202 45.651 190.398 45.3692L199.612 33.7377L208.93 45.502C209.126 45.7582 209.379 45.965 209.669 46.1065C209.958 46.2479 210.277 46.3201 210.599 46.3173C211.136 46.3237 211.654 46.1165 212.039 45.7414C212.423 45.3667 212.644 44.8543 212.651 44.3169C212.647 43.8032 212.461 43.3076 212.125 42.9189Z" fill="white"/>
                <path d="M50.3354 47.5204L50.904 47.8331L50.6292 47.4586C49.0674 45.3023 48.0355 42.8085 47.6173 40.1792C47.1987 37.5498 47.4059 34.859 48.2215 32.3243C49.891 27.0085 49.6827 21.2802 47.6309 16.0998C45.579 10.9194 41.8086 6.60184 36.952 3.87091C32.0951 1.14 26.4471 0.161779 20.9548 1.10024C15.4625 2.0387 10.4597 4.83681 6.78522 9.02545C3.11075 13.2141 0.987942 18.5386 0.772684 24.1064C0.557426 29.6741 2.26281 35.1466 5.60292 39.6064C8.94303 44.0663 13.7148 47.2422 19.1183 48.6021C24.5218 49.9618 30.2284 49.4224 35.2816 47.0746C37.6481 45.9561 40.2457 45.4134 42.862 45.4908C45.4782 45.5682 48.0391 46.2638 50.3354 47.5204ZM16.2512 16.6593C18.1534 14.6669 20.6762 13.3802 23.4058 13.0101C26.1354 12.6401 28.9097 13.2087 31.2737 14.6226C33.6377 16.0366 35.4509 18.212 36.416 20.792C37.3811 23.372 37.4407 26.2033 36.585 28.8217C36.1498 30.114 36.0554 31.4968 36.311 32.8361C36.5669 34.1754 37.1642 35.4262 38.0449 36.467L38.173 36.614L38.0118 36.5048C36.8793 35.7603 35.5734 35.3217 34.221 35.2321C32.8686 35.1424 31.5162 35.4044 30.2952 35.9931C27.7328 37.2199 24.8214 37.5098 22.0673 36.8123C19.3131 36.1147 16.8906 34.4742 15.2209 32.1753C13.5512 29.8768 12.7399 27.0657 12.9281 24.2309C13.1164 21.3961 14.2922 18.7169 16.2512 16.6593Z" fill="#DB2726"/>
                </g>
                <defs>
                <clipPath id="clip0_10_550">
                <rect width="211.896" height="48.7277" fill="white" transform="translate(0.753815 0.753296)"/>
                </clipPath>
                </defs>
                </svg>
                
        </div>
        <div class="qb-copyright-div">
           <p>{{'STATIC_PAGE.COPYRIGHT_PART1' | i18nTranslate: year}}</p> 
           <p style="padding-top: 10px;"> {{'STATIC_PAGE.COPYRIGHT_PART2' | i18nTranslate}}</p> 
        </div>
        <div class="social-media-links">
            <div class="qb-social-media-link" *ngFor="let item of socialMediaLinks">
                <a href="{{item.link}}" class="qb-media-link" target="_blank" rel="noopener noreferrer" [innerHtml]="item.svg | safeHtml"></a>
            </div>
        </div>
    </div>
    <div class="footer-right-container">
        <div class="footer-right-header">
            <h2 style="font-weight: 700; font-size: 3.75rem; letter-spacing: -0.03em; color: white; opacity: 0.9;">{{'STATIC_PAGE.GET_IN_TOUCH' | i18nTranslate}}</h2>
            <p style="font-weight: 500; font-size: 1.125rem; letter-spacing: -0.02em; color: white; padding-bottom: 20px; opacity: 0.7;">{{'STATIC_PAGE.GET_IN_TOUCH_PARA' | i18nTranslate}}</p>
        </div>
        <div class="footer-form-container">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="qb-contact-form">
              <input 
                formControlName="name" 
                type="text" 
                [placeholder]="'STATIC_PAGE.PLACEHOLDER_NAME' | i18nTranslate"
                class="form-input" 
                required
              />
              <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" class="qb-error-message">
                <span *ngIf="contactForm.get('name')?.errors?.['required']">Name is required.</span>
                <span *ngIf="contactForm.get('name')?.errors?.['minlength']">Name must be at least 2 characters long.</span>
              </div>
              <input 
                formControlName="email" 
                type="email" 
                [placeholder]="'STATIC_PAGE.PLACEHOLDER_EMAIL' | i18nTranslate"
                class="form-input" 
                required
              />
              <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched" class="qb-error-message">
                <span *ngIf="contactForm.get('email')?.errors?.['required']">Email is required.</span>
                <span *ngIf="contactForm.get('email')?.errors?.['email']">Please enter a valid email address.</span>
              </div>
              <input 
                formControlName="phone" 
                type="tel" 
                [placeholder]="'STATIC_PAGE.PLACEHOLDER_PHONO' | i18nTranslate"
                class="form-input" 
              />
              <div *ngIf="contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched" class="qb-error-message">
                <span *ngIf="contactForm.get('phone')?.errors?.['pattern']">Please enter a valid phone number.</span>
              </div>          
              <textarea 
                formControlName="message2" 
                [placeholder]="'STATIC_PAGE.PLACEHOLDER_MESSAGE' | i18nTranslate"
                class="form-input" 
                rows="4"
                style="resize: none;">
              </textarea>
              <button type="submit" class="send-button">{{'STATIC_PAGE.SEND' | i18nTranslate}}</button>
            </form>
          </div>
    </div>
    <!-- <div class="qb-container">
        <div class="qb-main-footer-wrapper">
            <div class="qb-about-wrapper">
                <h3 class="qb-title">{{'STATIC_PAGE.ABOUT_US' | i18nTranslate}}</h3>
                <p class="qb-footer-text">
                    {{'STATIC_PAGE.PRODUCT_QUBRIUX' | i18nTranslate}} <br>
                    Visit <a class="qb-skellam-link" href="https://www.skellam.ai" target="_blank" rel="noopener noreferrer">www.skellam.ai</a>{{'STATIC_PAGE.MORE_ABOUT_US' | i18nTranslate}}
                </p>
                <h3 class="qb-title">{{'STATIC_PAGE.CONTACT_US' | i18nTranslate}}</h3>
                <a href="mailto:cs@qubriux.com" class="qb-mail-link">
                    <svg class="qb-mail-icon" xmlns="http://www.w3.org/2000/svg" width="36.427" height="33.488" viewBox="0 0 36.427 33.488">
                    <g id="Group_51" data-name="Group 51" transform="translate(-514.598 -969.903)">
                        <g id="Group_50" data-name="Group 50" transform="translate(514.598 969.903)">
                        <g id="Group_49" data-name="Group 49">
                            <path id="Path_41" data-name="Path 41" d="M551.017,986.9a.637.637,0,0,0-.009-.072.359.359,0,0,0-.022-.074.415.415,0,0,0-.033-.08.229.229,0,0,0-.013-.038c0-.007-.013-.011-.017-.019a.768.768,0,0,0-.166-.165c-.007,0-.011-.013-.019-.017l-6.986-4.191a.587.587,0,0,0-.6,1.007l6.147,3.688-16.483,9.89-16.483-9.89,6.147-3.688a.587.587,0,0,0-.6-1.007l-6.987,4.191c-.007,0-.011.012-.018.017a.543.543,0,0,0-.059.045.668.668,0,0,0-.064.065.628.628,0,0,0-.045.055c0,.007-.013.011-.017.019a.176.176,0,0,0-.013.038.47.47,0,0,0-.033.08.411.411,0,0,0-.022.074.65.65,0,0,0-.008.072c0,.013-.008.025-.008.039v18.8a1.763,1.763,0,0,0,1.763,1.763h32.9a1.763,1.763,0,0,0,1.763-1.763v-18.8C551.025,986.925,551.018,986.913,551.017,986.9Zm-1.168,18.839a.587.587,0,0,1-.588.588h-32.9a.587.587,0,0,1-.587-.588V987.977l16.734,10.041a.59.59,0,0,0,.606,0l16.734-10.041Z" transform="translate(-514.598 -974.013)" fill="#fff"/>
                            <path id="Path_42" data-name="Path 42" d="M538.184,985.179a.587.587,0,1,0,0-1.174,6.463,6.463,0,1,1,.121-12.925,6.616,6.616,0,0,1,6.342,6.7v3.878a.588.588,0,0,1-.588.588h-.587a.588.588,0,0,1-.588-.588v-6.463a.588.588,0,0,0-.588-.588.58.58,0,0,0-.561.456,4.7,4.7,0,1,0-.491,6.629,4.623,4.623,0,0,0,.465-.461v.427a1.763,1.763,0,0,0,1.763,1.762h.587a1.763,1.763,0,0,0,1.763-1.762v-3.878a7.8,7.8,0,0,0-7.495-7.873,7.638,7.638,0,0,0-.143,15.275Zm0-3.524a3.525,3.525,0,1,1,3.525-3.525A3.525,3.525,0,0,1,538.184,981.655Z" transform="translate(-519.97 -969.903)" fill="#fff"/>
                            <path id="Path_43" data-name="Path 43" d="M519.9,1013.8h-.588v-1.762a.588.588,0,0,0-1.176,0v2.35a.588.588,0,0,0,.588.588H519.9a.588.588,0,0,0,0-1.176Z" transform="translate(-515.784 -983.835)" fill="#fff"/>
                            <path id="Path_44" data-name="Path 44" d="M525.2,1014.983h-1.174a.588.588,0,0,0,0,1.176H525.2a.588.588,0,0,0,0-1.176Z" transform="translate(-517.563 -985.02)" fill="#fff"/>
                        </g>
                        </g>
                    </g>
                    </svg>
                    cs@qubriux.com
                </a>
                <p class="qb-footer-text">
                    {{'STATIC_PAGE.REG_ADDRESS' | i18nTranslate}} <br>
                    3600 136th PL SE #300, Bellevue, WA 98007, USA.
                </p>
            </div>
            <div class="qb-newsletter-wrapper">
                <div class="qb-right-container">
                    <form [formGroup]="newsLetterForm" (ngSubmit)="subscribeNewsLetter()" class="qb-neweletter-signup">
                        <label for="newsletter-signup" class="qb-footer-text">{{'STATIC_PAGE.SIGN_UP_NEWSLETTER' | i18nTranslate}}</label>
                        <div class="qb-newsletter-input-wrapper">
                            <input formControlName="email" [readonly]="disableInput" type="text" id="newsletter-signup" class="qb-neweletter-input" [ngClass]="apiLoading || disableInput? 'qb-input-disabled' :invalidEmail || (errorMessage&& errorMessage.length)? 'qb-error-input': ''" placeholder="Email ID" (focus)="formFocused()" (input)="formFocused()">
                            <button class="qb-p-btn qb-neweletter-btn" [ngClass]="apiLoading? 'qb-button-loading' : subscibeSuccess || disableInput? 'qb-success-btn': ''" type="submit">
                                <ng-container *ngIf="apiLoading">{{'STATIC_PAGE.SUBSCRIBING' | i18nTranslate}} <span class="qb-dots-loader-wrapper"><span class="qb-dots-loader"></span></span></ng-container>
                                <ng-container *ngIf="!apiLoading &&subscibeSuccess">
                                    <svg class="qb-success-icon" width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.7015 22.1493L2.92537 13.3731L0 16.2985L11.7015 28L36.7761 2.92537L33.8508 0L11.7015 22.1493Z" fill="white"/>
                                    </svg>                                        
                                </ng-container>
                                <ng-container *ngIf="!apiLoading && !subscibeSuccess && !disableInput">{{'STATIC_PAGE.SUBSCRIBE' | i18nTranslate}}</ng-container>
                                <ng-container *ngIf="!apiLoading &&!subscibeSuccess && disableInput">{{'STATIC_PAGE.SUBSCRIBED_NEWSLETTER' | i18nTranslate}}</ng-container>
                            </button>
                            <p class="qb-error-message" [ngClass]="invalidEmail || (errorMessage&& errorMessage.length)? 'qb-show-message': ''">
                                <mat-icon class="qb-error-icon">error_outline</mat-icon>
                                {{errorMessage&& errorMessage.length? errorMessage: 'STATIC_PAGE.INVALID_EMAIL' | i18nTranslate}}
                            </p>
                            <p class="qb-success-message" [ngClass]="alreadySubscribed || subscibeSuccess || (successMessage && successMessage.length)? 'qb-show-message': ''">{{successMessage}}</p>
                        </div>
                    </form>
                    <div class="qb-follow-wrapper">
                        <h4 class="qb-follow-text">{{'STATIC_PAGE.FOLLOW_US' | i18nTranslate}}</h4>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="qb-copyright-wrapper">
            <p class="qb-footer-text">
                {{'STATIC_PAGE.COPYRIGHT' | i18nTranslate: year}}
            </p>
        </div>
    </div> -->
</footer>