import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkIfCustomerCare'
})
export class checkIfRoleCustomerCare implements PipeTransform {

  transform(authorities): any {
    let index = authorities?.indexOf('ROLE_CUSTOMER_CARE');
    let index1 =  authorities?.indexOf('ROLE_CUSTOMER_CARE_VIEW');
    if(index > -1 || index1 > -1){
        return true;
    }
    else{
        return false;
    }
  }
}