<div style="padding: 16px" *ngIf="data.operation === 'delete'">
  <div style="display: flex; justify-content: space-between">
    <span class="delete-header">{{'SHARED_PAGE.DELETE_BRANCHING_BLOCK' | i18nTranslate}}</span>
    <mat-icon (click)="dialogRef.close()" class="close-icon">close</mat-icon>
  </div>
  <div style="display: flex" *ngIf="data.type != 'multiple'">
    <div
      class="selection"
      (click)="selection = 1"
      [ngStyle]="
        selection === 1
          ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
          : { border: '1px solid #cccccc', background: '#fff' }
      "
    >
      <div class="box-container">
        <div class="node-block-replica node-block-grey" style="top: 10px"></div>
        <div class="node-block-replica node-block-grey" style="top: 38px"></div>
        <div class="node-block-replica node-block-grey" style="top: 63px"></div>
        <div
          class="node-block-replica node-block-white"
          style="top: 38px"
        ></div>
        <div
          class="node-block-replica node-block-white"
          style="top: 63px"
        ></div>
        <div class="node-connector-vertical-1"></div>
        <div class="node-connector-vertical-2"></div>
        <div class="node-connector-horizontal"></div>
        <mat-icon class="close-icon-delete-branch" style="left: 19px"
          >close</mat-icon
        >
        <div class="text-container">
          <span>{{'SHARED_PAGE.DELETE' | i18nTranslate}} <span style="font-weight: 600">{{'SHARED_PAGE.YES' | i18nTranslate}}</span> {{'SHARED_PAGE.PATH' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
    <div
      class="selection"
      (click)="selection = 2"
      [ngStyle]="
        selection === 2
          ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
          : { border: '1px solid #cccccc', background: '#fff' }
      "
    >
      <div class="box-container">
        <div class="node-block-replica node-block-grey" style="top: 10px"></div>
        <div
          class="node-block-replica node-block-white"
          style="top: 38px; left: 20px"
        ></div>
        <div
          class="node-block-replica node-block-white"
          style="top: 63px; left: 20px"
        ></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 38px; left: 53px"
        ></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 63px; left: 53px"
        ></div>
        <div class="node-connector-vertical-1"></div>
        <div class="node-connector-vertical-2"></div>
        <div class="node-connector-horizontal"></div>
        <mat-icon class="close-icon-delete-branch" style="left: 52px"
          >close</mat-icon
        >
        <div class="text-container">
          <span>{{'SHARED_PAGE.DELETE' | i18nTranslate}} <span style="font-weight: 600">{{'SHARED_PAGE.NO' | i18nTranslate}}</span> {{'SHARED_PAGE.PATH' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
    <div
      class="selection"
      (click)="selection = 3"
      [ngStyle]="
        selection === 3
          ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
          : { border: '1px solid #cccccc', background: '#fff' }
      "
    >
      <div class="box-container">
        <div class="node-block-replica node-block-grey" style="top: 10px"></div>
        <div class="node-block-replica node-block-grey" style="top: 38px"></div>
        <div class="node-block-replica node-block-grey" style="top: 63px"></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 38px; left: 53px"
        ></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 63px; left: 53px"
        ></div>
        <div class="node-connector-vertical-1"></div>
        <div class="node-connector-vertical-2"></div>
        <div class="node-connector-horizontal"></div>
        <mat-icon class="close-icon-delete-branch" style="left: 19px"
          >close</mat-icon
        >
        <mat-icon class="close-icon-delete-branch" style="left: 52px"
          >close</mat-icon
        >
        <div class="text-container">
          <span>{{'SHARED_PAGE.DELETE' | i18nTranslate}} <span style="font-weight: 600">{{'SHARED_PAGE.BOTH' | i18nTranslate}}</span> {{'SHARED_PAGE.PATHS' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-d-flex" *ngIf="data.type === 'multiple' && data.nodeData.nodeType != 'ABTESTING'">
    <div *ngFor="let name of data.nodeData.nodeData.definition.groupName">
      <div
      class="selection"
      style="height: 50px;"
      (click)="changeSelection(name)"
      [ngStyle]="
        selection === name
          ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
          : { border: '1px solid #cccccc', background: '#fff' }
      "
    >
      <div class="box-container" style="text-align: center;">
        <!-- <div class="node-block-replica node-block-grey" style="top: 10px"></div>
        <div class="node-block-replica node-block-grey" style="top: 38px"></div>
        <div class="node-block-replica node-block-grey" style="top: 63px"></div>
        <div
          class="node-block-replica node-block-white"
          style="top: 38px"
        ></div>
        <div
          class="node-block-replica node-block-white"
          style="top: 63px"
        ></div>
        <div class="node-connector-vertical-1"></div>
        <div class="node-connector-vertical-2"></div>
        <div class="node-connector-horizontal"></div>
        <mat-icon class="close-icon-delete-branch" style="left: 19px"
          >close</mat-icon
        > -->
        <div class="text-container">
          <span>{{'SHARED_PAGE.DELETE' | i18nTranslate}} <span style="font-weight: 600">{{name}}</span> {{'SHARED_PAGE.PATH' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
    </div>
    <div
      class="selection"
      style="height: 50px;"
      (click)="selection = 3"
      [ngStyle]="
        selection === 3
          ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
          : { border: '1px solid #cccccc', background: '#fff' }
      "
    >
      <div class="box-container" style="text-align: center;">
        <!-- <div class="node-block-replica node-block-grey" style="top: 10px"></div>
        <div class="node-block-replica node-block-grey" style="top: 38px"></div>
        <div class="node-block-replica node-block-grey" style="top: 63px"></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 38px; left: 53px"
        ></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 63px; left: 53px"
        ></div>
        <div class="node-connector-vertical-1"></div>
        <div class="node-connector-vertical-2"></div>
        <div class="node-connector-horizontal"></div>
        <mat-icon class="close-icon-delete-branch" style="left: 19px"
          >close</mat-icon
        >
        <mat-icon class="close-icon-delete-branch" style="left: 52px"
          >close</mat-icon
        > -->
        <div class="text-container">
          <span>{{'SHARED_PAGE.DELETE' | i18nTranslate}} <span style="font-weight: 600">{{'SHARED_PAGE.ALL' | i18nTranslate}}</span> {{'SHARED_PAGE.PATHS' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data.type === 'multiple' && data.nodeData.nodeType === 'ABTESTING'">
    <div>
      {{'SHARED_PAGE.DELETE_AB_TESTING' | i18nTranslate}}
    </div>
  </div>
  <div class="delete-cancel-button-container">
    <div class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
    <div class="primary-button" (click)="submit(true)">{{'SHARED_PAGE.OK' | i18nTranslate}}</div>
  </div>
</div>
<div *ngIf="data.operation === 'add'" style="padding: 16px">
  <div style="display: flex; justify-content: space-between">
    <span class="add-header">{{'SHARED_PAGE.BRANCH_BETWEEN_ACTION' | i18nTranslate}}</span>
    <mat-icon (click)="dialogRef.close()" class="close-icon">close</mat-icon>
  </div>
  <div class="add-description">
    <span
      >{{'SHARED_PAGE.BRANCH_BETWEEN_ACTION_TEXT' | i18nTranslate}}</span
    >
  </div>
  <div style="width: 290px">
    <div>
      <mat-radio-group
        [(ngModel)]="radioSelection"
        (change)="radioSelectionChange()"
        style="display: flex; flex-direction: column; font-size: 12px"
      >
        <mat-radio-button color="primary" value="1">Discard</mat-radio-button>
        <mat-radio-button color="primary" value="2"
          >{{'SHARED_PAGE.MOVE_TO_BRANCH' | i18nTranslate}}</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <!-- <div
      class="selection"
      (click)="selection = 1"
      [ngStyle]="{
        border: selection === 1 ? '3px solid #25a7f9' : '3px solid #cccccc'
      }"
    >
      <div class="box-container">
        <div class="node-block-replica node-block-grey" style="top: 10px"></div>
        <div class="node-block-replica node-block-grey" style="top: 38px"></div>
        <div class="node-block-replica node-block-grey" style="top: 63px"></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 38px; left: 53px"
        ></div>
        <div
          class="node-block-replica node-block-grey"
          style="top: 63px; left: 53px"
        ></div>
        <div class="node-connector-vertical-1"></div>
        <div class="node-connector-vertical-2"></div>
        <div class="node-connector-horizontal"></div>
        <mat-icon class="close-icon-delete-branch" style="left: 19px">close</mat-icon>
        <mat-icon class="close-icon-delete-branch" style="left: 52px">close</mat-icon>
        <div class="text-container">
          <span>Delete <span style="font-weight: 600">Both</span> Paths</span>
        </div>
      </div>
    </div> -->
    <div
      style="display: flex; justify-content: space-between"
      *ngIf="radioSelection === '2' && data.type != 'multiple-split'"
    >
      <div
        class="selection"
        style="width: 115px; height: 100px"
        (click)="selection = 2; radioSelection = '2'"
        [ngStyle]="
          selection === 2
            ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
            : { border: '1px solid #cccccc', background: '#fff' }
        "
      >
        <div class="box-container">
          <div
            class="node-block-replica-add node-block-grey-add"
            style="top: 10px; left: 30px; background: #fff"
          ></div>
          <div
            class="
              node-block-replica-add
              node-block-grey-add
              node-delete-primary-color
            "
            style="top: 38px; left: 30px"
          ></div>
          <div
            class="node-block-replica-add node-block-white"
            style="top: 38px; left: 65px; background: #fff"
          ></div>
          <div class="node-connector-vertical-1-add"></div>
          <div class="node-connector-vertical-2-add"></div>
          <div class="node-connector-horizontal" style="left: 42px"></div>
          <span class="close-icon-add" style="left: 33px">{{'SHARED_PAGE.YES' | i18nTranslate}}</span>
          <div class="text-container">
            <span
              >{{'SHARED_PAGE.MOVE_TO' | i18nTranslate}} <span style="font-weight: 600">{{'SHARED_PAGE.YES' | i18nTranslate}}</span> {{'SHARED_PAGE.BRANCH' | i18nTranslate}}</span
            >
          </div>
        </div>
      </div>
      <div
        class="selection"
        style="width: 115px; height: 100px"
        (click)="selection = 3; radioSelection = '2'"
        [ngStyle]="
          selection === 3
            ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
            : { border: '1px solid #cccccc', background: '#fff' }
        "
      >
        <div class="box-container">
          <div
            class="node-block-replica-add node-block-grey-add"
            style="top: 10px; left: 30px; background: #fff"
          ></div>
          <div
            class="node-block-replica-add node-block-white"
            style="top: 38px; left: 30px; background: #fff"
          ></div>
          <div
            class="
              node-block-replica-add
              node-block-grey-add
              node-delete-primary-color
            "
            style="top: 38px; left: 65px"
          ></div>
          <div class="node-connector-vertical-1-add"></div>
          <div class="node-connector-vertical-2-add"></div>
          <div class="node-connector-horizontal" style="left: 42px"></div>
          <span class="close-icon-add" style="left: 70px">{{'SHARED_PAGE.NO' | i18nTranslate}}</span>
          <div class="text-container">
            <span>{{'SHARED_PAGE.MOVE_TO' | i18nTranslate}} <span style="font-weight: 600">{{'SHARED_PAGE.NO' | i18nTranslate}}</span> {{'SHARED_PAGE.BRANCH' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between"
      *ngIf="radioSelection === '2' && data.type === 'multiple-split'"
    >
      <div *ngFor="let name of data.nodeData">
        <div
        class="selection"
        style="height: 50px;"
        (click)="changeSelection(name)"
        [ngStyle]="
          selection === name
            ? { border: '1px solid #25a7f9', background: '#f5f5f5' }
            : { border: '1px solid #cccccc', background: '#fff' }
        "
      >
        <div class="box-container" style="text-align: center;">
          <div class="text-container">
            <span>{{'SHARED_PAGE.MOVE_TO' | i18nTranslate}} <span style="font-weight: 600">{{name}}</span> {{'SHARED_PAGE.PATH' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div *ngIf="radioSelection === '1'" style="height: 120px">
      <span>{{'SHARED_PAGE.DISCARD_DELETE_ALL_BRANCH' | i18nTranslate}}</span>
    </div>
  </div>
  <div *ngIf="data.operation === 'add'" class="add-node-button-container">
    <div class="secondary-button-border" (click)="submit(false)">
      {{'SHARED_PAGE.CANCEL_ACTION' | i18nTranslate}}
    </div>
    <div class="primary-button" (click)="submit(true)">{{'SHARED_PAGE.CONFIRM' | i18nTranslate}}</div>
  </div>
  <div
    *ngIf="data.operation === 'delete'"
    class="delete-cancel-button-container"
  >
    <div class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
    <div class="primary-button" (click)="submit(true)">{{'SHARED_PAGE.DELETE' | i18nTranslate}}</div>
  </div>
</div>
