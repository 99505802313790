import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryFilter'
})
export class CountryFilterPipe implements PipeTransform {
  transform(countries: any[], searchTerm: string): any[] {
    if (!countries || !searchTerm) {
      return countries;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();

    return countries.filter((country) => {
      return (
        country.name.toLowerCase().includes(lowerSearchTerm) ||
        country.alpha2Code.toLowerCase().includes(lowerSearchTerm) ||
        country.callingCodes[0].includes(searchTerm)
      );
    });
  }
}
