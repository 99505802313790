<div style="display: flex;width: 100%; height: 100% ;justify-content: space-between;" *ngIf="validWidgets"
    data-widget-name="REVIEW LIST">
    <div class="Mask" *ngIf="validWidgets" >
        <div class="reviewlistTable" *ngIf="!loading">
            <div>
            <div class="review-details-table" >
                <div style="font-size: 1.5rem; font-weight: 700; display: flex; align-items: center; gap: 12px;">{{'SHARED_PAGE.REVIEW_LIST' | i18nTranslate}}
                </div>

                <div *ngIf="isAdmin && storeFilterList.length>1" class="filter-container">
                        <div class="filter-title-2">Store filter: </div>
                        <mat-select [(ngModel)]="selectedStore" (selectionChange)="getFilteredSurveyList()">
                            <mat-option *ngFor="let store of storeFilterList" [value]="store" >
                            {{store.storeName}}
                            </mat-option>
                        </mat-select>
                </div>
            </div>
            </div>
            <div style="padding-top: 30px;">
            <div class="listTable" style="height: 50px;  background: var(--main-bg-bgrey); border-radius: 12px;" id="tableHeads"
                [style.padding-right.px]="rightMargin">
                <div class="listHead topicHead" style="flex: 1.3; gap: 8px; border-radius: 12px 0px 0px 12px;">
                    {{'SHARED_PAGE.REVIEWER_NAME' | i18nTranslate}}
                </div>
                <div class="listHead topicHead" style="flex: 1;">{{'SHARED_PAGE.REVIWER_PLATFORM' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1;">{{'SHARED_PAGE.STORE_NAME' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1;">{{'SHARED_PAGE.DATE_SUBMITTED' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1;">{{'SHARED_PAGE.LAST_MODIFIED' | i18nTranslate}}</div>
                <div class="listHead topicHead" style="flex: 1.3; border: none;">{{'SHARED_PAGE.RATING' | i18nTranslate}}</div>
            </div>
            </div>
            <div class="main" *ngIf="totalReviewslength>0" id="side-menu-survey-parent">
                <div id="side-menu-survey">
                    <div class="listValueTable" *ngFor="let review of reviewsBody; index as i"
                        (click)="SentimentAnalysisParticularReview(i)" data-button-name="Review"
                        [ngClass]="{active:selectedi==i}">
                        <div class="listHead" [ngClass]="{active:selectedi==i}" class=" listHead"
                            [ngClass]="{active:selectedi==i}" style="display: flex;align-items: center;gap: 8px; flex: 1.3;">
                            <div style="width: 90%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                <span class="reviewName" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" placement="bottom" [matTooltip]="review.reviewerName" matTooltipClass="ex-tooltip-red1">{{review.reviewerName}}</span>
                            </div>
                        </div>
                        <div class="reviewType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;">
                            {{review.reviewPlatform| titlecase}}</div>
                        <div class="reviewType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;">
                            {{review.storeName| titlecase}}</div>
                        <div class="reviewType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;">
                            {{review.dateSubmitted | date:getDateFormat("dd MMM yyyy")}} | {{review.dateSubmitted | customTime}}</div>
                        <div class="reviewType listHead" [ngClass]="{active:selectedi==i}" style="flex: 1; display: flex; align-items: center;">
                            {{review.lastModified | date:getDateFormat("dd MMM yyyy")}} | {{review.lastModified | customTime}}</div>
                            <div class="reviewType listHead" [ngClass]="{active: selectedi == i}" style="flex: 1.3; display: flex; align-items: center;">
                                <ng-container *ngFor="let star of getStars(review.rating); let index = index">
                                  <mat-icon *ngIf="index < review.rating">star</mat-icon>
                                  <mat-icon *ngIf="index >= review.rating">star_border</mat-icon>
                                </ng-container>
                              </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!loading && totalReviewslength>0">
                <mat-paginator #paginator [pageIndex]=0 [length]=totalReviewslength [pageSize]=pageSize
                    [pageSizeOptions]="[5, 10, 25, 100]" (page)="getNextData($event)">
                </mat-paginator>
            </div>
            <div *ngIf="totalReviewslength==0">
                <div class="viewcustomerContanier" style="width: 100%;justify-content: center;">
                    <div style="display: flex;align-items: center;position: relative;height: 50vh;justify-content: center;flex-direction: column;">
                        <div style="padding: 10px;border: 1px dotted #ff3b30;background: #fff;display: flex;justify-content: center;align-items: center;width: 98%;" class="data-avai-border">
                       {{'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="loading" style="display: flex;align-items: center;justify-content: center;height:100%;width: 100%;">
            <app-qubriux-loading [size]="45" [loadingText]="'SURVEY_PAGE.FETCHING_REVIEWS' | i18nTranslate"></app-qubriux-loading>
        </div>

    </div>

    
    <div class="sentimentMask" style="width: 29%; border-radius: 14px; overflow: scroll;">
        
        <div *ngIf="!graphDataLoading&&dashboardData">
            <app-review-sentiment-dialogbox [surveyData]="dashboardData"></app-review-sentiment-dialogbox>
        </div>
        <div *ngIf="graphDataLoading" style="display: flex;align-items: center;justify-content: center;height:100%;">
            <app-qubriux-loading [size]="45" [loadingText]="'SURVEY_PAGE.FETCHING_REVIEW_DETAILS' | i18nTranslate"></app-qubriux-loading>
        </div>

        <div *ngIf="!graphDataLoading && !dashboardData" style="height: 100%;">
              <div style="display: flex;align-items: center;height: 100%;justify-content: center;">
                <div style="padding: 10px;border: 1px dotted #ff3b30;background: #fff;display: flex;justify-content: center;align-items: center;width: 98%;" class="data-avai-border">
                  {{ 'SURVEY_PAGE.NO_DATA_AVAILABLE' | i18nTranslate }}
                </div>
              </div>
          </div>
    </div>
</div>