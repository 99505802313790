<div class="qb-header">
  <span class="qb-header-title"><strong>{{'CUSTOMER_PAGE.RESUBSCRIBE_CONTACT' | i18nTranslate}}</strong></span>
  <mat-dialog-actions>
    <button class="qb-xIcon" mat-dialog-close><div class="material-icons qb-icon-position">clear</div></button>
  </mat-dialog-actions>
</div>
<div data-widget-name="SUPPRESSION LISTS">
  <div class="qb-enterEmail">{{'CUSTOMER_PAGE.RESUBSCRIBE_CONTACT_PARAGRAPH' | i18nTranslate}}</div>
  <div class="qb-selectDomainName">
    <span class="qb-filterTitle" *ngIf="data.channel=='EMAIL'">{{'CUSTOMER_PAGE.EMAIL_ADDRESS' | i18nTranslate}}</span>
    <span class="qb-filterTitle" *ngIf="data.channel=='SMS'">{{'CUSTOMER_PAGE.PHONE_NUMBER' | i18nTranslate}}</span>

    <div>{{this.data.dataKey.emailOrPhone}}</div>
  </div>
  <div class="qb-buttons">
    <mat-dialog-actions>
      <button class="qb-cancel secondary-button-border" mat-dialog-close data-button-name="Canceled Delete Operation">{{'CUSTOMER_PAGE.CANCEL' | i18nTranslate}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button class="qb-confirm primary-button" [mat-dialog-close]=result data-button-name="Confirm: Delete" [attr.data-item-name]="this.data.dataKey.email">{{'CUSTOMER_PAGE.RESUBSCRIBE' | i18nTranslate}}</button>
    </mat-dialog-actions>
  </div>
</div>