<div class="qb-header" >
  <span class="qb-header-title"><strong>{{'CUSTOMER_PAGE.ADD_NEW_CONTACT' | i18nTranslate}}</strong></span>
  <mat-dialog-actions>
    <button class="qb-xIcon" mat-dialog-close><div class="material-icons qb-icon-position">clear</div></button>
  </mat-dialog-actions>
</div>
<div data-widget-name="SUPPRESSION LISTS">
  <div *ngIf="data.channel=='EMAIL'">
    <div class="qb-enterEmail">{{'CUSTOMER_PAGE.ADD_NEW_CONTACT_PARAGRAPH' | i18nTranslate}}</div>
    <div class="qb-selectDomainName">
      <span class="qb-filterTitle">{{'CUSTOMER_PAGE.EMAIL_ADDRESS' | i18nTranslate}}</span>
      <input type="text" [(ngModel)]="email" (keypress)="keypress()">
      <div *ngIf="error.length > 0" class="qb-error-text">{{error | i18nDynamicTranslate : 'POS_PAGE'}}</div>
    </div>
  </div>
  <div *ngIf="data.channel=='SMS'">
    <div class="qb-enterEmail">{{'CUSTOMER_PAGE.PHONE_NUMBER_PARAGRAPH' | i18nTranslate}}</div>
    <div class="qb-selectDomainName qb-d-flex qb-fd-coloumn">
      <span class="qb-filterTitle">{{'CUSTOMER_PAGE.PHONE_NUMBER' | i18nTranslate}}</span>

      <input type="text"
      ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'in'}"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      (countryChange)="onCountryChange($event)" />
      <!-- <input type="number" [(ngModel)]="smsno" (keypress)="keypress()"> -->
      <div *ngIf="error.length > 0" class="qb-error-text">{{error | i18nDynamicTranslate : 'POS_PAGE'}}</div>
    </div>
  </div>
  <div class="qb-buttons">
    <mat-dialog-actions>
      <button class="qb-cancel secondary-button-border" [mat-dialog-close]="cancel" data-button-name="Canceled">{{'CUSTOMER_PAGE.CANCEL' | i18nTranslate}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button class="qb-confirm primary-button" (click)="add()" data-button-name="Confirm: Contact Added" [attr.data-item-name]="email" >{{'CUSTOMER_PAGE.CONFIRM' | i18nTranslate}}</button>
    </mat-dialog-actions>
  </div>
</div>