import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { CampaignFullscreenComponent } from 'src/app/shared/components/dialog-box';


@Component({
  selector: 'app-campaign-kpi',
  templateUrl: './campaign-kpi.component.html',
  styleUrls: ['./campaign-kpi.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignKpiComponent implements OnInit {

  constructor(
    private apicall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private _dialog: MatDialog,
  ) { 
    this.getScreenSize();
  }

  recordsperPage = 10;
  pageNo = 1;
  selectedCampaign;
  selectedParameter;
  AllCampaignList = [{}] ;
  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    status: ["ACTIVE"],
    segmentIds: null,
    segmentedFlag: false,
    statusList: ["ACTIVE", "DRAFT", "ENDED", "SCHEDULED"],
    favoriteFlag: null,
    lastModifiedFlag: false,
  };
  nodeList = [];
  parameter = [];
  array;
  nodesAnalytics = [];
  selectedNodeAnalytics = [];
  analyticsData = [];
  isAnalyticsAvailable = false;
  isClicked = false;
  revenueData = [];
  scrHeight;
  scrContentHeight;
  scrWidth;
  zoomAmount = 100;
  revCurrency;
  totalRevenue = 0;
  activeCampaigns = 0;
  analyticsDataAll = {
    merchantId: this.tokenStorage.getMerchantId(),
    status: ["ACTIVE"],
  };
  selectedLineGraphData = "revenue";
  selectedGraphButton = "All Time";
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  dropDownStatusFilter: boolean = false;
  filterViewBy = "day";
  startDate;
  endDate;
  public last6month: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 5)).setDate(1)
    ).toDateString()
  );
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public currentDay: number = new Date().getDate();
  public last7dayEnd: Date = new Date(
    this.fullYear,
    this.month,
    this.currentDay - 1
  );
  emailSent = 0;
  totalRevenueEmail = 0;
  border_node_name = [];
  scaleFactor = 1;
  revenue = 0;
  revenueTotalbranch = 0;
  revenueBranched : any = {};
  @ViewChild("selectCampaign") selectCampaign: MatSelect;
  @ViewChild("selectParameter") selectParameter: MatSelect;
  @ViewChild("filterdropdown") filterdropdown: ElementRef;
  @ViewChild('myCanvas') canvasRef: ElementRef;

  ngOnInit() {
    this.getCampaign();
    this.filterViewBy = "month";
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.getAnalytics();
    this.startDate = this.datePipe.transform(this.last6month, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.last7dayEnd, "yyyy-MM-dd");
    this.getInsightCampaignEmailStat(this.startDate, this.endDate);
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrContentHeight = window.innerHeight - 60 - 90;
    this.scrWidth = window.innerWidth;
  }
  getAnalytics(){
    this.apicall.getTotalRevenue(this.analyticsDataAll).subscribe((response) => {
      this.activeCampaigns = response["body"].activeCampaigns;
      this.totalRevenue =
        Math.round((response["body"].totalRevenue + Number.EPSILON) * 100) /
        100;
    });
  } 
  openSelectCampaign() {
    this.selectCampaign.open();
    this.selectCampaign.openedChange.subscribe(() =>
      this.registerPanelScrollEvent()
    );
  }
  openSelectParameter(){
    this.selectParameter.open();
  }
  registerPanelScrollEvent() {
    const panel = this.selectCampaign.panel.nativeElement;
    panel.addEventListener("scroll", (event) => this.loadAllOnScroll(event));
  }
  loadAllOnScroll(event) {
    
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.pageNo += 1;
      this.getNewData(this.pageNo, this.recordsperPage);
    }
  }
  getNewData(pageNum, pageSize){
    this.apicall.getSearchCampaign(
      this.tokenStorage.getMerchantId(),
      null,
      pageNum,
      pageSize,
      this.data.statusList,
      this.data.segmentIds,
      null,
      null,
      this.data.favoriteFlag
    ).subscribe((response)=>{
      let json = (response["body"])["strategies"];
      for (let key in json) {
        let j: any={}
        j.id = key;
        j.name = json[key]['strategy']['strategyName']
        // let k: any={};
        // k.def = j;
        this.AllCampaignList.push(j);
          // this.selectedCampaign = this.AllCampaignList[1]
      }
      // this.AllCampaignList.splice(0,1);
      // this.getCampaignDetails(this.selectedCampaign);
      // this.AllCampaignList = json
    })
  }
  changeCampaign(){
    this.isClicked = false;
    this.parameter = [];
    this.selectedParameter = null;
    let parent = document.getElementById('canvas');
    parent.innerHTML = ""
    this.getCampaignDetails(this.selectedCampaign);
  }
  changeParameter(){
    console.log("SELCTED_PARAMETER",this.selectedParameter);
    this.nodeClick(this.selectedParameter.data);
  }
  getCampaign(){
    this.apicall.getStrategies().subscribe((response)=>{
    })
    this.apicall.getSearchCampaign(
      this.tokenStorage.getMerchantId(),
      null,
      this.pageNo,
      this.recordsperPage,
      this.data.statusList,
      this.data.segmentIds,
      null,
      null,
      this.data.favoriteFlag
    ).subscribe((response)=>{
      let json = (response["body"])["strategies"];
      for (let key in json) {
        let j: any={}
        j.id = key;
        j.name = json[key]['strategy']['strategyName']
        // let k: any={};
        // k.def = j;
        this.AllCampaignList.push(j);
        this.selectedCampaign = this.AllCampaignList[1]
      }
      this.AllCampaignList.splice(0,1);
      this.getCampaignDetails(this.selectedCampaign);
      // this.AllCampaignList = json
    })
  }
  getCampaignDetails(data){
    this.apicall.getStrategy(data['id']).subscribe((response)=>{
      let strategyData = JSON.parse(response["body"]);
      this.nodeList = JSON.parse(strategyData["displayParams"])["data"];
      this.prefillData();
    });
    this.apicall.getSubCampaignAnalytics(data['id']).subscribe((response)=>{
      this.isAnalyticsAvailable = true;
      this.analyticsData = response['body']
      // this.nodesAnalytics = []
      // for(let key in response['body']){
      //   let node = key.split('##')
      //   this.nodesAnalytics.push(node)
      // }
      // for( let i =0;i<this.nodesAnalytics.length;i++)
      // {}
    });
  }
  prefillData(){
    let parent = document.getElementById("canvas");
    let count_offer = 1;
    let count_audience = 1;
    let count_AB = 1;
    let count_email = 1;
    let count_survey = 1;
    let count_delay = 1;
    let count_reposne = 1;

    for(let nodeData in this.nodeList){
      let data = this.nodeList[nodeData]
      let previousNodeIndex = this.nodeList.findIndex(
        (x) => x.nodeId === data['previousNodeId']
      );
      if(data['nodeType'] === 'TRIGGER'){
        let i : any = {};
        i.displayname ="Journey Trigger";
        i.data = data;
        this.parameter.push(i);
        let inputWraper=document.createElement("div");
        // inputWraper.setAttribute("style","width:300px")
        inputWraper.setAttribute("id",data['nodeBlockId'])
        let wrapper = `
                <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                    <div class="node-kpi">
                        <div class="node-block-kpi trigger-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                            <div class="node-header-kpi">
                                <span class="material-icons-outlined node-header-icon-kpi icons-primary">play_circle</span>
                                <span>Journey Trigger</span>
                            </div>
                        </div>
                    </div>
                    <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                </div>
        `;
      //   inputWraper.addEventListener("click", (event) =>
      //   this.testClick(data)
      // );
        inputWraper.innerHTML = wrapper
        parent.append(inputWraper)
        // parent.insertAdjacentElement(
        //   "afterend",
        //   inputWraper
        // );
      }
      if(previousNodeIndex >-1){
        let parent1;
        if(data['previousNodeRelation'] === "NEXT" && this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'UNBRANCHED'){
          parent1 = document.getElementById(this.nodeList[previousNodeIndex]['nodeBlockId']);          
        }
        if(data['previousNodeRelation'] === "NEXT" && this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'RESPONSE_EVENT'){
          let connector_response = document.getElementById("left-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi" id="left-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.insertAdjacentElement(
            "afterend",
            inputWraper1
          );
          parent1 = document.getElementById("left-branch-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
        }
        if(data['previousNodeRelation'] === "TIMEOUT" && this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'RESPONSE_EVENT'){
          let connector_response = document.getElementById("right-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi" id="right-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.insertAdjacentElement(
            "afterend",
            inputWraper1
          );
          parent1 = document.getElementById("right-branch-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
        }
        if(this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'ABTESTING'){
          let i = data.previousNodeRelation.substring(6,(data.previousNodeRelation.length))
          let connector_response = document.getElementById("ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi-ab" id="right-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.append(
            inputWraper1
          );
          parent1 = document.getElementById("ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
        } 
        if(this.getNodeBranchType(this.nodeList[previousNodeIndex]['nodeType']) === 'AUDIENCESPLIT'){
          let i = data.previousNodeRelation.substring(7,(data.previousNodeRelation.length))
          let connector_response = document.getElementById("ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
          let inputWraper1=document.createElement("div");
          let wrapper1 = `<div class="node-connector-kpi-ab" id="right-connector-${this.nodeList[previousNodeIndex]['nodeBlockId']}"></div>`
          inputWraper1.innerHTML = wrapper1;
          connector_response.append(
            inputWraper1
          );
          parent1 = document.getElementById("ab-"+this.nodeList[previousNodeIndex]['nodeBlockId']+"-path-"+i);
        } 
        let inputWraper=document.createElement("div");
        // inputWraper.setAttribute("style","width:300px")

        inputWraper.setAttribute("id",data['nodeBlockId']);
        if(data['nodeType'] === "CHANNEL"){
          if(data['nodeData']['definition']['channelType'] === 'EMAIL'){
            let i : any = {};
            i.displayname ="Email-"+count_email;
            i.data = data;
            this.parameter.push(i);
            let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi email-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">email</span>
                                  <span>Email-${count_email}</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          count_email = count_email + 1;
          }
        }
        if(data['nodeType'] === "SURVEY"){
          let i : any = {};
          i.displayname ="Survey-"+count_survey;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi survey-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">thumb_up_off_alt</span>
                                  <span>Survey-${count_survey}</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          count_survey++;
        }
        if(data['nodeType'] === "OFFER"){
          let i : any = {};
          i.displayname ="Offer-"+count_offer;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi offer-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary"> local_offer </span>
                                  <span>Offer-${count_offer}</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          count_offer = count_offer + 1;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          
        }
        if(data['nodeType'] === "WAIT"){
          let i : any = {};
          i.displayname ="Time Delay-"+count_delay;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi delay-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">  timer  </span>
                                  <span>Time Delay-${count_delay}</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          count_delay++;
        }
        if(data['nodeType'] === "RESPONSE_EVENT"){
          let i : any = {};
          i.displayname ="If/Else Branch-"+count_reposne;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi">
                          <div class="node-block-kpi delay-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary icon-rotation">   alt_route   </span>
                                  <span>If/Else Condition-${count_reposne}</span>
                              </div>
                          </div>
                      </div>
                      <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                      <div class="horizontal-connector-kpi" id="horizontal-connector-${data['nodeBlockId']}"></div>                    
                      <div><div class="left-branch-node-kpi" id="left-${data['nodeBlockId']}">YES</div></div>
                      <div><div class="right-branch-node-kpi" id="right-${data['nodeBlockId']}">NO</div></div>
                      <div class="node-branch-kpi">
                        <div class="left-branch"><div id="left-branch-${data['nodeBlockId']}"></div></div>
                        <div class="right-branch"><div id="right-branch-${data['nodeBlockId']}"></div></div>
                      </div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
         

          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );

          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          // let connector_response = document.getElementById("right-"+data['nodeBlockId']);
          // let inputWraper1=document.createElement("div");
          // let wrapper1 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper1.innerHTML = wrapper1;
          // connector_response.insertAdjacentElement(
          //   "afterend",
          //   inputWraper1
          // );

          // let connector_response1 = document.getElementById("left-"+data['nodeBlockId']);
          // let inputWraper11=document.createElement("div");
          // let wrapper11 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper11.innerHTML = wrapper11;
          // connector_response1.insertAdjacentElement(
          //   "afterend",
          //   inputWraper11
          // );
          count_reposne++;
        }
        if(data['nodeType'] === "AUDIENCESPLIT"){
          let i : any = {};
          i.displayname ="Audience Split -"+count_audience;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi" id="node-kpi-${data['nodeBlockId']}">
                          <div class="node-block-kpi delay-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary">   group   </span>
                                  <span>Audience Split-${count_audience}</span>
                              </div>
                          </div>
                          <div class="qb-d-flex">
                            <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                          </div>
                      </div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          let node_branch = document.getElementById("node-kpi-"+data['nodeBlockId'])
          let pathGroupData=data.nodeData.definition.groupName
          // node_branch.style.width=`${(pathGroupData.length)*(355)}px`;
          let container = document.createElement("div");
              container.setAttribute("class","node-branch-kpi");

              let Abwrapper = document.createElement("div");
            Abwrapper.setAttribute("id","abtest-wrapper-"+data['nodeBlockId']);
            // Abwrapper.setAttribute("style","display:flex")
            Abwrapper.style.width=`${(pathGroupData.length)*(350)}px`;
            container.append(Abwrapper);

            let branchNodePath = document.createElement("div");
            branchNodePath.setAttribute("class","ab-branch-node-path-kpi");
            branchNodePath.setAttribute("id", "ab-" +data['nodeBlockId']+"-path-ab");
            Abwrapper.append(branchNodePath);

            
          for (var k in pathGroupData){ 
            if (pathGroupData.hasOwnProperty(k)) {
              let sub_container = document.createElement("div");
              sub_container.setAttribute("class","split-branch");
              sub_container.setAttribute("id","split-"+pathGroupData[k]);
              branchNodePath.append(sub_container);
              let mainWraper = document.createElement("div")
              mainWraper.style.position="relative";
              mainWraper.style.display="flex";
              mainWraper.style.width = "106px";
              mainWraper.style.flexDirection = "column";
              mainWraper.style.alignItems = "center";
              mainWraper.style.marginLeft = "98px"
              if(pathGroupData[k] != "Everyone else")
              mainWraper.setAttribute("id", "ab-"+data['nodeBlockId']+"-path-"+pathGroupData[k])
              else
              mainWraper.setAttribute("id", "ab-"+data['nodeBlockId']+"-path-others")

              let inputWraper=document.createElement("div");
              if(pathGroupData[k] != "Everyone else")
              inputWraper.setAttribute("id", "split-block-"+data['nodeBlockId']+"-path-"+pathGroupData[k])
              else
              inputWraper.setAttribute("id", "split-block-"+data['nodeBlockId']+"-path-others")
              inputWraper.style.background="#065D72";
              inputWraper.style.display="flex";
              inputWraper.style.width="110px";
              inputWraper.style.position="relative";
              inputWraper.style.padding="5px";
              inputWraper.style.color="#FFFFFF";
              inputWraper.style.justifyContent="center";
              inputWraper.style.borderRadius="4px";
              let wrapContent = `<div class="dynamic-split-vertical-kpi"></div> ${pathGroupData[k]}`;
              // inputElement.setAttribute("type", "number");
              // inputElement.setAttribute("class","abtest-input-area "+k);
              // inputElement.setAttribute("style","width: 48px;")
              // inputElement.value=pathGroupData[k];
              inputWraper.innerHTML = wrapContent
              mainWraper.append(inputWraper)
              sub_container.append(mainWraper)
              node_branch.insertAdjacentElement(
                "afterend",
                container
              );
            }
          }
          let horizontalLine = document.getElementById("ab-" +data['nodeBlockId']+"-path-ab")
          let horizontalLineContent = `<div class="horizaontal-node-connector-kpi-split" id="horizontal-connector-${data['nodeBlockId']}"></div>`
          let input = document.createElement("div");

          let count = this.getNumberOfNodeCount(data['nodeId'], pathGroupData);
          input.innerHTML = horizontalLineContent;
          horizontalLine.append(input);
          
          let horizontalLength = document.getElementById("horizontal-connector-"+data['nodeBlockId']);
          // if(count === 0){
          //   horizontalLength.setAttribute("style","width: calc(100% - 350px)")
          // }
          // else{
          //   horizontalLength.setAttribute("style","width: calc(100% - 445px)")
          // }

          horizontalLength.setAttribute("style","width: calc(100% - "+count+"px)")
          // let connector_response = document.getElementById("right-"+data['nodeBlockId']);
          // let inputWraper1=document.createElement("div");
          // let wrapper1 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper1.innerHTML = wrapper1;
          // connector_response.insertAdjacentElement(
          //   "afterend",
          //   inputWraper1
          // );

          // let connector_response1 = document.getElementById("left-"+data['nodeBlockId']);
          // let inputWraper11=document.createElement("div");
          // let wrapper11 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper11.innerHTML = wrapper11;
          // connector_response1.insertAdjacentElement(
          //   "afterend",
          //   inputWraper11
          // );
          count_audience++;
        }
        if(data['nodeType'] === "ABTESTING"){
          let i : any = {};
          i.displayname ="A/B Testing -"+count_AB;
          i.data = data;
          this.parameter.push(i);
          let wrapper = `
                  <div class="node-block-container-kpi" id="container-${data['nodeBlockId']}">
                      <div class="node-kpi" id="node-kpi-${data['nodeBlockId']}">
                          <div class="node-block-kpi delay-node-block" id ="node-container-kpi-${data['nodeBlockId']}">
                              <div class="node-header-kpi">
                                  <span class="material-icons-outlined node-header-icon-kpi icons-primary icon-rotation">   alt_route   </span>
                                  <span>A/B Testing-${count_AB}</span>
                              </div>
                          </div>
                          <div class="qb-d-flex">
                            <div class="node-connector-kpi" id="connector-${data['nodeBlockId']}"></div>
                          </div>
                      </div>
                  </div>
          `;
          // inputWraper.addEventListener("click", (event) =>
          // this.testClick(data)
          // );
          inputWraper.innerHTML = wrapper
          // parent.append(inputWraper);
          parent1.insertAdjacentElement(
            "afterend",
            inputWraper
          );
          let click = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
          click.setAttribute("style","cursor: pointer");
          click.addEventListener("click",(event)=>{
            this.nodeClick(data,i)
          });
          let node_branch = document.getElementById("node-kpi-"+data['nodeBlockId'])
          let pathGroupData=data.nodeData.definition.pathGroups
          // node_branch.style.width=`${(pathGroupData.length)*(355)}px`;
          let container = document.createElement("div");
              container.setAttribute("class","node-branch-kpi");
            let Abwrapper = document.createElement("div");
            Abwrapper.setAttribute("id","abtest-wrapper-"+data['nodeBlockId']);
            // Abwrapper.setAttribute("style","display:flex")
            let countPath = this.getPathLength(pathGroupData)
            Abwrapper.style.width=`${(countPath)*(350)}px`;
            // Abwrapper.style.minWidth=`340px`;
            container.append(Abwrapper);

            let branchNodePath = document.createElement("div");
            branchNodePath.setAttribute("class","ab-branch-node-path-kpi");
            branchNodePath.setAttribute("id", "ab-" +data['nodeBlockId']+"-path-ab");
            Abwrapper.append(branchNodePath);

          for (var k in pathGroupData){ 
            if (pathGroupData.hasOwnProperty(k)) {
              
              let sub_container = document.createElement("div");
              sub_container.setAttribute("class","split-branch");
              sub_container.setAttribute("id","split-"+data['nodeBlockId']+"-"+k);
              branchNodePath.append(sub_container);
              let mainWraper = document.createElement("div")
              mainWraper.style.position="relative";
              mainWraper.style.display="flex";
              mainWraper.style.width = "106px";
              mainWraper.style.flexDirection = "column";
              mainWraper.style.alignItems = "center";
              mainWraper.style.marginLeft = "98px"
              mainWraper.setAttribute("id", "ab-"+data['nodeBlockId']+"-path-"+k)

              let inputWraper=document.createElement("div");
              inputWraper.setAttribute("id", "ab-block-"+data['nodeBlockId']+"-path-"+k)
              inputWraper.style.background="#6165D7";
              inputWraper.style.display="flex";
              inputWraper.style.width="120px";
              inputWraper.style.position="relative";
              inputWraper.style.padding="5px";
              inputWraper.style.borderRadius="4px";
              inputWraper.style.color="#FFFFFF";
              let value = pathGroupData[k]['weight']
              let wrapContent = `<div class="dynamic-split-vertical-kpi"></div><input type="number" class="abtest-input-area ${k}" style="width: 48px;" value="${value}"/> <div class="qb-path-name">&nbsp; ${k}</div>`;
              // let inputElement = document.createElement("input");
              // inputElement.setAttribute("type", "number");
              // inputElement.setAttribute("class","abtest-input-area "+k);
              // inputElement.setAttribute("style","width: 48px;")
              // inputElement.value=pathGroupData[k];
              inputWraper.innerHTML = wrapContent
              mainWraper.append(inputWraper)
              sub_container.append(mainWraper)
              node_branch.insertAdjacentElement(
                "afterend",
                container
              );
            }
          }
          let horizontalLine = document.getElementById("ab-" +data['nodeBlockId']+"-path-ab")
          let horizontalLineContent = `<div class="horizaontal-node-connector-kpi-test" id="horizontal-connector-${data['nodeBlockId']}"></div>`
          let input = document.createElement("div");
          input.innerHTML = horizontalLineContent;

          let count = this.getNumberOfNodeCount(data['nodeId'], pathGroupData);

          
          horizontalLine.append(input);
          let horizontalLength = document.getElementById("horizontal-connector-"+data['nodeBlockId']);
          // if(count === 0){
          //   horizontalLength.setAttribute("style","width: calc(100% - 350px)")
          // }
          // if(count<0){
          //   horizontalLength.setAttribute("style","width: calc(100% + 50px)")
          // }
          // else{
          //   horizontalLength.setAttribute("style","width: calc(100% - 445px)")
          // }
          horizontalLength.setAttribute("style","width: calc(100% - "+count+"px)")

          // let connector_response = document.getElementById("right-"+data['nodeBlockId']);
          // let inputWraper1=document.createElement("div");
          // let wrapper1 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper1.innerHTML = wrapper1;
          // connector_response.insertAdjacentElement(
          //   "afterend",
          //   inputWraper1
          // );

          // let connector_response1 = document.getElementById("left-"+data['nodeBlockId']);
          // let inputWraper11=document.createElement("div");
          // let wrapper11 = `<div class="node-connector-kpi" id="next-connector-${data['nodeBlockId']}"></div>`
          // inputWraper11.innerHTML = wrapper11;
          // connector_response1.insertAdjacentElement(
          //   "afterend",
          //   inputWraper11
          // );
          count_AB++;
        }
      }
      if(this.nodeList.length-1 === parseInt(nodeData) && this.getNodeBranchType(data['nodeType']) === 'UNBRANCHED'){
        let connector = document.getElementById("connector-"+data['nodeBlockId']);
        connector.setAttribute("style", "display:none");
        for(let k in this.nodeList){
          console.log("K",k)
          let previousNodeIndex = this.nodeList.findIndex(
            (x) => x.previousNodeId === this.nodeList[k]['nodeId']
          );
          if(previousNodeIndex === -1){
            let connector = document.getElementById("connector-"+this.nodeList[k]['nodeBlockId']);
            connector.setAttribute("style", "display:none");
          }
        }
      }
    }
  }
  nodeClick(data, displayname?){
    if(displayname)
      this.selectedParameter = displayname;
    console.log("SELECTED__PARAMETER", this.selectedParameter);
    this.getRevenue(data)
    this.removeBackground()

    setTimeout(() => {
      let class_name = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
      let parent = <HTMLElement> document.getElementById("parent-div");
      let main = document.getElementById("canvas");
      let main_height = main.getBoundingClientRect().top + parent.scrollTop;
      let top = class_name.getBoundingClientRect().top + parent.scrollTop;
      let left = class_name.getBoundingClientRect().left;
      // console.log("LEFT", left);
      console.log("TOPP", top,"px", parent.scrollTop );
      console.log("MAIN_HEIGHT", main_height)
      let nextNodeIndex
      let first_wrapper = document.createElement("div");
      first_wrapper.setAttribute("id", "border-container-"+data['nodeBlockId']);
      this.border_node_name.push(data['nodeBlockId']);
      first_wrapper.style.position = "absolute";
      first_wrapper.style.height = "86px";
      first_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
      first_wrapper.style.width = "350px";
      first_wrapper.style.left = "-25px"
          class_name.append(first_wrapper);
      if(this.getBranchType(data['nodeType']) ==='BRANCHED'){
        nextNodeIndex = this.nodeList.findIndex(
          (x) => x.nodeId === data['nodeId']
        );

      }
      // class_name.setAttribute("class","box-shadow");
          // let wrapper = document.createElement("div");
          // wrapper.style.position = "absolute";
          // wrapper.style.height = (top - main_height + 90)+"px";
          // wrapper.style.top = "-"+(top - main_height)+"px";
          // wrapper.style.background = "rgba(231, 187, 187, 0.25)";
          // wrapper.style.width = "300px";
          // class_name.append(wrapper);
      // class_name.setAttribute("style","height: "+(top-main_height)+"px");
      else{
            nextNodeIndex = this.nodeList.findIndex(
              (x) => x.previousNodeId === data['nodeId']
            );
      }
      let previousNodeIndex = this.nodeList.findIndex(
        (x) => x.nodeId === data['previousNodeId']
      );
    
      let temp_block_id_1 = data['nodeBlockId'];
      let temp_block_id_2 = data['nodeBlockId'];
      while(previousNodeIndex > -1){
        if(this.nodeList[previousNodeIndex]['nodeType'] !=='TRIGGER'){
          let temp_class_name = document.getElementById("node-container-kpi-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
          let temp_selected = document.getElementById("node-container-kpi-"+temp_block_id_1);
          let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
          let temp_left = temp_class_name.getBoundingClientRect().left + parent.scrollLeft;
          let temp_selected_top = temp_selected.getBoundingClientRect().top + parent.scrollTop;
          let temp_selected_left = temp_selected.getBoundingClientRect().left + parent.scrollLeft;
          console.log("BLOCKIDS1",temp_block_id_1)
          console.log("CLASS_BEFORE",this.nodeList[previousNodeIndex]['nodeBlockId'] )
          console.log("HEIGHT", temp_top, temp_selected_top);
          console.log("LEFT",temp_left, temp_selected_left);

          if(temp_left === temp_selected_left){
            let temp_wrapper = document.createElement("div");
            temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
            this.border_node_name.push(this.nodeList[previousNodeIndex]['nodeBlockId'])
            temp_wrapper.style.position = "absolute";
            temp_wrapper.style.height = ( temp_selected_top - temp_top )+"px";
            temp_wrapper.style.top = "-"+(temp_selected_top - temp_top )+"px";
            temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
            temp_wrapper.style.width = "350px";
            temp_wrapper.style.left = "-25px"
            temp_selected.append(temp_wrapper);  
          }
          else{
            console.log("TESTTTING ENTRY")
            console.log("HEIGHT", temp_top, main_height);
            let temp_wrapper = document.createElement("div");
            temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
            this.border_node_name.push(this.nodeList[previousNodeIndex]['nodeBlockId'])
            temp_wrapper.style.position = "absolute";
            temp_wrapper.style.height = ( temp_selected_top - main_height )+"px";
            temp_wrapper.style.top = "-"+( temp_selected_top - main_height )+"px";
            temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
            temp_wrapper.style.width = "350px";
            temp_wrapper.style.left = "-25px"
            temp_selected.append(temp_wrapper);  
            let new_wrapper = document.createElement("div");
            new_wrapper.setAttribute("id", "border-container-"+temp_block_id_1);
            this.border_node_name.push(temp_block_id_1);
            new_wrapper.style.position = "absolute";
            new_wrapper.style.height = "100px";
            new_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
            new_wrapper.style.width = "350px";
            new_wrapper.style.left = "-25px"
            temp_class_name.append(new_wrapper);
            let len = (temp_left - temp_selected_left);
            if(len < 0)
              len = len * (-1);
            if( len > 350){
              let new_wrapper = document.createElement("div");
              new_wrapper.setAttribute("id", "border-container-"+temp_block_id_1);
              this.border_node_name.push(temp_block_id_1);
              new_wrapper.style.position = "absolute";
              new_wrapper.style.top = "-" + (temp_top - main_height)+"px";
              new_wrapper.style.height = (temp_top - main_height + 100)+"px";
              new_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              new_wrapper.style.width = 350 * ( (len / 350) - 1 )+ "px";
              new_wrapper.style.left = "325px"
              temp_class_name.append(new_wrapper);
            }
          }

        }
        else{
          console.log("INSIDE TRIGGER")
          let temp_class_name = document.getElementById("node-container-kpi-"+temp_block_id_1);
          let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
          let temp_wrapper = document.createElement("div");
          temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
          this.border_node_name.push(this.nodeList[previousNodeIndex]['nodeBlockId']);
          temp_wrapper.style.position = "absolute";
          temp_wrapper.style.height = (temp_top - main_height)+"px";
          temp_wrapper.style.top = "-"+(temp_top - main_height)+"px";
          temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
          temp_wrapper.style.width = "350px";
          temp_wrapper.style.left = "-25px"
          temp_class_name.append(temp_wrapper);  
          console.log("BLOCKIDS1",temp_block_id_1)
          console.log("HEIGHT", temp_top);
          
        }
        temp_block_id_1 = this.nodeList[previousNodeIndex]['nodeBlockId'];
        previousNodeIndex = this.nodeList.findIndex(
              (x) => x.nodeId === this.nodeList[previousNodeIndex]['previousNodeId']
            );
        
      }
      while(nextNodeIndex > -1){
        console.log("BRANCH__TYPE", this.getBranchType(this.nodeList[nextNodeIndex]['nodeType']));
        if(this.getBranchType(this.nodeList[nextNodeIndex]['nodeType']) === 'BRANCHED'){
          let childCount = this.nodeList.reduce(
            (a, v) => (v.previousNodeId === this.nodeList[nextNodeIndex]['nodeId'] ? a + 1 : a),
            0
          );
          console.log("CHILD_COUNT", childCount, this.nodeList[nextNodeIndex]['nodeId']);
          let branchNodeIndex = this.nodeList.findIndex(
            (x) => x.previousNodeId === this.nodeList[nextNodeIndex]['nodeId']
          );
          console.log("BRANCH_NODE_INDEX", branchNodeIndex);
          console.log("NODE_LIST",this.nodeList)
          if(childCount > 1){
            this.checkIfPathConnected(nextNodeIndex,childCount,this.nodeList[nextNodeIndex]['nodeType']);
            let temp_class_name = document.getElementById("node-container-kpi-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
            let temp_selected = document.getElementById("node-container-kpi-"+temp_block_id_2);
            let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
            let temp_left = temp_class_name.getBoundingClientRect().left + parent.scrollLeft;
            let temp_selected_top = temp_selected.getBoundingClientRect().top + parent.scrollTop;
            let temp_selected_left = temp_selected.getBoundingClientRect().left + parent.scrollLeft;
            if(temp_left === temp_selected_left){
              let temp_wrapper = document.createElement("div");
              temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
              temp_wrapper.style.position = "absolute";
              temp_wrapper.style.height = (temp_top - temp_selected_top)+"px";
              temp_wrapper.style.top = "-"+(temp_top - temp_selected_top - 86)+"px";
              temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              temp_wrapper.style.width = "350px";
              temp_wrapper.style.left = "-25px"
              temp_class_name.append(temp_wrapper);
            }
            else{
              let temp_wrapper = document.createElement("div");
              temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
              temp_wrapper.style.position = "absolute";
              temp_wrapper.style.height = (temp_top - main_height + 86)+"px";
              temp_wrapper.style.top = "-"+(temp_top - main_height)+"px";
              temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              temp_wrapper.style.width = "350px";
              temp_wrapper.style.left = "-25px"
              temp_class_name.append(temp_wrapper);
            }
            let index :any

            for(let i in this.nodeList){
              if(this.nodeList[i]['previousNodeId'] === this.nodeList[nextNodeIndex]['nodeId']){
                console.log("ENTRYYYYYY", this.nodeList[i]['nodeId'])
                index = this.findNextNodeForBranched(this.nodeList[i]['nodeId'], nextNodeIndex,this.nodeList[i]['nodeBlockId']);
                if(index > -1){
                  index = this.findNextNodeForBranched(this.nodeList[nextNodeIndex]['nodeId'], nextNodeIndex,this.nodeList[i]['nodeBlockId']);
                }
                }
            }
            nextNodeIndex = index;
          }
          else if(childCount ===0){
            this.checkIfPathConnected(nextNodeIndex,childCount,this.nodeList[nextNodeIndex]['nodeType']);
            nextNodeIndex = -1;
          }
          else{
            this.checkIfPathConnected(nextNodeIndex,childCount,this.nodeList[nextNodeIndex]['nodeType']);
            let temp_class_name = document.getElementById("node-container-kpi-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
            let temp_selected = document.getElementById("node-container-kpi-"+temp_block_id_2);
            let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
            let temp_left = temp_class_name.getBoundingClientRect().left + parent.scrollLeft;
            let temp_selected_top = temp_selected.getBoundingClientRect().top + parent.scrollTop;
            let temp_selected_left = temp_selected.getBoundingClientRect().left + parent.scrollLeft;
            
            console.log("ELSE_OF_BRANCHED")
            console.log("BLOCKIDS2",temp_block_id_2)
            console.log("NEXT_CLASS", this.nodeList[nextNodeIndex]['nodeBlockId'], this.nodeList[nextNodeIndex]['nodeType'])
            console.log("HEIGHT", temp_top, temp_selected_top);
            if(temp_left === temp_selected_left){
              let temp_wrapper = document.createElement("div");
              temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
              temp_wrapper.style.position = "absolute";
              temp_wrapper.style.height = (temp_top - temp_selected_top)+"px";
              temp_wrapper.style.top = "-"+(temp_top - temp_selected_top - 86)+"px";
              temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              temp_wrapper.style.width = "350px";
              temp_wrapper.style.left = "-25px"
              temp_class_name.append(temp_wrapper);
            }
            else{
              let temp_wrapper = document.createElement("div");
              temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
              temp_wrapper.style.position = "absolute";
              temp_wrapper.style.height = (temp_top - main_height + 86)+"px";
              temp_wrapper.style.top = "-"+(temp_top - main_height)+"px";
              temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              temp_wrapper.style.width = "350px";
              temp_wrapper.style.left = "-25px"
              temp_class_name.append(temp_wrapper);
            }
            temp_block_id_2 = this.nodeList[nextNodeIndex]['nodeBlockId'];
            nextNodeIndex = this.nodeList.findIndex(
                  (x) => x.previousNodeId === this.nodeList[nextNodeIndex]['nodeId']
                );
          }

          
        }
        else{
          // if(this.nodeList[previousNodeIndex]['nodeType'] ==='ABTESTING' || this.nodeList[previousNodeIndex]['nodeType'] ==='AUDIENCESPLIT' || this.nodeList[previousNodeIndex]['nodeType'] ==='RESPONSE_EVENT'){
            let temp_class_name = document.getElementById("node-container-kpi-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
            let temp_selected = document.getElementById("node-container-kpi-"+temp_block_id_2);
            let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
            let temp_left = temp_class_name.getBoundingClientRect().left + parent.scrollLeft;
            let temp_selected_top = temp_selected.getBoundingClientRect().top + parent.scrollTop;
            let temp_selected_left = temp_selected.getBoundingClientRect().left + parent.scrollLeft;
  
            console.log("BLOCKIDS2",temp_block_id_2)
            console.log("NEXT_CLASS", this.nodeList[nextNodeIndex]['nodeBlockId'], this.nodeList[nextNodeIndex]['nodeType'])
            console.log("HEIGHT", temp_top, temp_selected_top);
            if(temp_left === temp_selected_left){
              let temp_wrapper = document.createElement("div");
              temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
              temp_wrapper.style.position = "absolute";
              temp_wrapper.style.height = (temp_top - temp_selected_top)+"px";
              temp_wrapper.style.top = "-"+(temp_top - temp_selected_top - 86)+"px";
              temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              temp_wrapper.style.width = "350px";
              temp_wrapper.style.left = "-25px"
              temp_class_name.append(temp_wrapper);
            }
            else{
              let temp_wrapper = document.createElement("div");
              temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
              temp_wrapper.style.position = "absolute";
              temp_wrapper.style.height = (temp_top - main_height + 86)+"px";
              temp_wrapper.style.top = "-"+(temp_top - main_height)+"px";
              temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
              temp_wrapper.style.width = "350px";
              temp_wrapper.style.left = "-25px"
              temp_class_name.append(temp_wrapper);
            }
            temp_block_id_2 = this.nodeList[nextNodeIndex]['nodeBlockId'];
            nextNodeIndex = this.nodeList.findIndex(
                  (x) => x.previousNodeId === this.nodeList[nextNodeIndex]['nodeId']
                );
          }
              

        // }
      }
    });
    
    if(this.isClicked === false){
      // for( let i =0;i<this.nodesAnalytics.length;i++)
      let group = []
      if(data['nodeType'] === 'ABTESTING'){
        group = data.nodeData.definition.pathGroups;
      }
      else{
        group = data.nodeData.definition.groupName; 
      }
      if(data['nodeType'] === 'ABTESTING'){
        let pathdata = data.nodeData.definition.pathGroups
        this.selectedNodeAnalytics = [];
        for(let k in pathdata){
          let value = data['nodeId']+';AB;'+k;
          this.selectedNodeAnalytics.push(value)
        }
      }
      else{
        let pathdata = data.nodeData.definition.groupName
        this.selectedNodeAnalytics = [];
        for(let k in pathdata){
          let value = data['nodeId']+';AB;'+k;
          this.selectedNodeAnalytics.push(value)
        }
      }
      // for(let k in this.selectedNodeAnalytics){
      //   for( let i =0;i<this.nodesAnalytics.length;i++){
      //     console.log(this.nodesAnalytics[i].indexOf(k))
      //     let v :any
      //     v.name = group[i];
          
      //   }
      // }
      this.isClicked = true;
      let class_name = document.getElementById("node-container-kpi-"+data['nodeBlockId']);
      let height = class_name.offsetTop;
      console.log("HEIGHT", height)
      // class_name.setAttribute("style","box-shadow: 0px 0px 8px 10px red;");
      // let previousNodeIndex = this.nodeList.findIndex(
      //   (x) => x.nodeId === data['previousNodeId']
      // );
      // if(data['previousNodeRelation'] != 'NEXT' && data['nodeType'] != 'RESPONSE_EVENT'){
      //   if(data['previousNodeRelation'].substring(0,4) === 'PATH'){
      //     let node_path = this.nodeList.findIndex(
      //       (x) => x.nodeId === data['previousNodeId']
      //     ); 
      //   let i = data['previousNodeRelation'].substring(6,(data['previousNodeRelation'].length))
      //     let ab_node = document.getElementById("ab-block-"+this.nodeList[node_path]['nodeBlockId']+"-path-"+i);
      //     ab_node.setAttribute("class","box-shadow");
      //   }
      //   if(data['previousNodeRelation'].substring(0,5) === 'SPLIT'){
      //     let node_path = this.nodeList.findIndex(
      //       (x) => x.nodeId === data['previousNodeId']
      //     ); 
      //     let i = data['previousNodeRelation'].substring(7,(data['previousNodeRelation'].length))
      //     let ab_node = document.getElementById("split-block-"+this.nodeList[node_path]['nodeBlockId']+"-path-"+i);
      //     ab_node.setAttribute("class","box-shadow");
      //   }
      // }
      // while(previousNodeIndex > -1){
      //   let class_name = document.getElementById("node-container-kpi-"+this.nodeList[previousNodeIndex]['nodeBlockId']);
      // class_name.setAttribute("style","box-shadow: 0px 0px 8px 10px red;");
      // if(this.nodeList[previousNodeIndex]['previousNodeRelation'] != 'NEXT'){
      //   if(this.nodeList[previousNodeIndex]['previousNodeRelation'].substring(0,4) === 'PATH'){
      //     let node_path = this.nodeList.findIndex(
      //       (x) => x.nodeId === this.nodeList[previousNodeIndex]['previousNodeId']
      //     ); 
      //   let i = this.nodeList[previousNodeIndex]['previousNodeRelation'].substring(6,(this.nodeList[previousNodeIndex]['previousNodeRelation'].length))
      //     let ab_node = document.getElementById("ab-block-"+this.nodeList[node_path]['nodeBlockId']+"-path-"+i);
      //     ab_node.setAttribute("class","box-shadow");
      //   }
      //   if(this.nodeList[previousNodeIndex]['previousNodeRelation'].substring(0,5) === 'SPLIT'){
      //     let node_path = this.nodeList.findIndex(
      //       (x) => x.nodeId === this.nodeList[previousNodeIndex]['previousNodeId']
      //     ); 
      //     let i = this.nodeList[previousNodeIndex]['previousNodeRelation'].substring(7,(this.nodeList[previousNodeIndex]['previousNodeRelation'].length))
      //     let ab_node = document.getElementById("split-block-"+this.nodeList[node_path]['nodeBlockId']+"-path-"+i);
      //     ab_node.setAttribute("class","box-shadow");
      //   }
      // }
      //   previousNodeIndex = this.nodeList.findIndex(
      //     (x) => x.nodeId === this.nodeList[previousNodeIndex]['previousNodeId']
      //   );
      // }
    }

  }
  getNodeBranchType(nodeType) {
    switch (nodeType) {
      case "WAIT":
      case "CHANNEL":
      case "OFFER":
      case "SURVEY":
      case "TRIGGER":
        return "UNBRANCHED";
      case "RESPONSE_EVENT":
        return "RESPONSE_EVENT"
      case "ABTESTING":
        return "ABTESTING"
      case "AUDIENCESPLIT":
        return "AUDIENCESPLIT";
        // return "GROUPBRANCH"
    }
  }
  getBranchType(nodeType){
    switch (nodeType) {
      case "WAIT":
      case "CHANNEL":
      case "OFFER":
      case "SURVEY":
      case "TRIGGER":
        return "UNBRANCHED";
      case "RESPONSE_EVENT":
      case "ABTESTING":
      case "AUDIENCESPLIT":
        return "BRANCHED";
        // return "GROUPBRANCH"
    }
  }
  getNumberOfNodeCount(nodeId, data){
    let count = 0;
    let subCount = 0;
    let totalCount = 0;
    let branchedNodeCount = 0;
    let subbranchCount = 0;
    let noOfBranch = this.getPathLength(data)
    for(let i=0;i<this.nodeList.length;i++){
      if(this.nodeList[i]['previousNodeId'] === nodeId){
        totalCount++;
        if(this.nodeList[i]['nodeType'] === 'ABTESTING' || this.nodeList[i]['nodeType'] ==='AUDIENCESPLIT' || this.nodeList[i]['nodeType'] === 'RESPONSE_EVENT'){
          branchedNodeCount++;
          let split = this.nodeList[i]['previousNodeRelation'].substring(7,(this.nodeList[i]['previousNodeRelation'].length))
          let path = this.nodeList[i]['previousNodeRelation'].substring(6,(this.nodeList[i]['previousNodeRelation'].length))
          let pathgroupData = (this.nodeList[i]['previousNodeId'])
          let index = this.nodeList.findIndex(
            (x) => x.nodeId === pathgroupData
          );
          if(split !== 'others' && path !='Path-'+(this.getPathLength(this.nodeList[index]['nodeData']['definition']['pathGroups']))){
            count++; 
          }
        }
        else{
          let check = false;
          let previous = this.nodeList.findIndex(
            (x) => x.previousNodeId === this.nodeList[i]['nodeId']
          );
          while(previous > -1){
              if(this.nodeList[previous]['nodeType'] ==='ABTESTING' || this.nodeList[previous]['nodeType'] ==='AUDIENCESPLIT' || this.nodeList[previous]['nodeType'] === 'RESPONSE_EVENT'){
                branchedNodeCount++;
                let split = this.nodeList[i]['previousNodeRelation'].substring(7,(this.nodeList[i]['previousNodeRelation'].length))
                let path = this.nodeList[i]['previousNodeRelation'].substring(6,(this.nodeList[i]['previousNodeRelation'].length))
                let pathgroupData = (this.nodeList[i]['previousNodeId'])
                let index = this.nodeList.findIndex(
                  (x) => x.nodeId === pathgroupData
                );
                if(split !== 'others' && path !='Path-'+(this.getPathLength(this.nodeList[index]['nodeData']['definition']['pathGroups']))){
                  count++;
                  if(this.nodeList[previous]['nodeType'] === 'ABTESTING')
                    subbranchCount = this.getPathLength(this.nodeList[previous]['nodeData']['definition']['pathGroups'])
                  else if(this.nodeList[previous]['nodeType'] === 'AUDIENCESPLIT')
                    subbranchCount = this.getPathLength(this.nodeList[previous]['nodeData']['definition']['groupName'])
                }
                check = true;
              }
              previous = this.nodeList.findIndex(
                (x) => x.previousNodeId === this.nodeList[previous]['nodeId']
              );
          }
          if(check === false)
            subCount++;
        }
      }
    }

    if(subCount === totalCount && noOfBranch === totalCount){
      return 350;
    }
    if(subCount === totalCount && totalCount === 0){
      return 350;
    }
    if(subCount === totalCount && noOfBranch !== totalCount && totalCount !=0){
      return 400;
    }
    if(count === totalCount && noOfBranch === totalCount && subbranchCount<3){
      return -50
    }
    if(count === totalCount && count === branchedNodeCount && subbranchCount<3){
      return -350
    }
    if(branchedNodeCount === totalCount && count>0 && branchedNodeCount != count && subbranchCount<3){
      return -50
    }
    if(count === totalCount && noOfBranch !== totalCount && subbranchCount<3){
      return 350
    }
    if(subbranchCount >2){
        if(subbranchCount === 3){
          return -400
        }
        else{
          let value = 650 +((subbranchCount - 2)*50);
          return (-value)
        }
    }
    else
      return 350;
  }
  getPathLength(data){
    let count = 0;
    for(let k in data){
      count++;
    }
    return count;
  }

  magnify(type) {
    // this.hideAllEvents();
    let canvas = document.getElementById("canvas");
    if (type === "ADD") {
      if (this.zoomAmount < 150) {
        this.zoomAmount += 10;
        // canvas.style.transform = "scale(" + this.zoomAmount / 100 + ")";
        // canvas.style.transformOrigin = "left top";
        canvas.setAttribute("style","zoom:"+this.zoomAmount / 100);
      }
    } else {
      if (this.zoomAmount > 30) {
        this.zoomAmount -= 10;
        // canvas.style.transform = "scale(" + this.zoomAmount / 100 + ")";
        // canvas.style.transformOrigin = "left top";
        canvas.setAttribute("style","zoom:"+this.zoomAmount / 100);
      }
    }
  }
  onEndDateChange(event){
    if(event.value){
      let start = this.range?.value?.start;
      let end = this.range?.value?.end;
      let span = this.findDaySpan(start, end);
      let dateRange = {
        "startDate": start,
        "endDate": end,
        "daySpan": span
      };
      // if(start && end) this.dateChangeDetected(dateRange);
    }
  }
  findDaySpan(start, end){
    let span=0;
    if(start && end){
      let startDate = new Date(start).getTime();
      let endDate = new Date(end).getTime();
      span = ((endDate - startDate)/(1000 * 3600 * 24))+ 1;
    }
    return span;
  }
  toggleSelect(status?) {
    let element = document.getElementById("qb-multi-select-dropdown");
    let segmentSelect = document.getElementById("qb-segment-select");
    let segmentSelectPanel = document.getElementById("qb-multi-select-panel");
    let keyboardDownIconElement = document.getElementById(
      "qb-keyboard-down-icon-container"
    );
    if (this.dropDownStatusFilter || status) {
      this.dropDownStatusFilter = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      // segmentSelectPanel.style.border = "1px solid #919191";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //element.style.display = 'none';
    } else {
      this.dropDownStatusFilter = true;
      element.style.height = "auto";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      // segmentSelectPanel.style.border = "1px solid #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }
  updateCheckboxValues(value) {
    this.filterViewBy = value;
    this.toggleSelect();
  }
  getInsightCampaignEmailStat(start, end) {
    this.apicall.getInsightCampaignEmails(start, end, null).subscribe(
      (response) => {
        console.log("EMAIL ANALYTICS", response);
        this.emailSent = response['body']['emailStats']['totalEmailsSent'];
        this.totalRevenueEmail = response['body']['totalRevenue'];
      },
      (error) => {

      }
    );
  }
  openfullscreen(){
    let dialogReference = this._dialog.open(
      CampaignFullscreenComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "90%",
        height: "90%",
        data: {
          data: this.nodeList
        },
      }
    );
    dialogReference.afterClosed().subscribe((result) => {
      return false;
    });
  }
  removeBackground(){
    console.log("BORDER_NODE_NAME",this.border_node_name);
    for(let i =0; i< this.border_node_name.length ; i++){
      let element = document.getElementById(
        "border-container-"+this.border_node_name[i]
      );
      if(element)
        element.parentElement.removeChild(element);
    }
    this.border_node_name = [];
  }
  findNextNodeForBranched(nodeId, nextNodeIndex, nodeBlockId){
    let index = this.nodeList.findIndex(
      (x) => x.previousNodeId === nodeId
    );
    console.log("NODE_ID",nodeId);
    console.log("INDEX", index);
    console.log("GROUP", nodeBlockId);
    console.log("NEXTTT",nextNodeIndex);
    while(index > -1){
      if(this.getBranchType(this.nodeList[index]['nodeType']) === 'BRANCHED'){
        return this.nodeList.findIndex(
          (x) => x.previousNodeId === this.nodeList[index]['nodeId']
        );
        // index = -1;
      }
      else{
        // index = this.nodeList.findIndex(
        //   (x) => x.previousNodeId === this.nodeList[index]['nodeId']
        // );
        console.log("ENTRY")
        return this.findNextNodeForBranched(this.nodeList[index]['nodeId'], nextNodeIndex,this.nodeList[index]['nodeBlockId']);
      }
    }
    if(index === -1){
      console.log("ENTRY@@@222")
      console.log("INDEX", index);
      console.log("GROUP", nodeBlockId);
      console.log("NEXTTT",nextNodeIndex);
      let parent = <HTMLElement> document.getElementById("parent-div");
      let main = document.getElementById("canvas");
      let main_height = main.getBoundingClientRect().top + parent.scrollTop;
      let temp_class_name = document.getElementById("node-container-kpi-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
      let temp_selected = document.getElementById("node-container-kpi-"+nodeBlockId);
      let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
      let temp_left = temp_class_name.getBoundingClientRect().left + parent.scrollLeft;
      let temp_selected_top = temp_selected.getBoundingClientRect().top + parent.scrollTop;
      let temp_selected_left = temp_selected.getBoundingClientRect().left + parent.scrollLeft;

      console.log("BLOCKIDS2",nodeBlockId)
      console.log("NEXT_CLASS", this.nodeList[nextNodeIndex]['nodeBlockId'], this.nodeList[nextNodeIndex]['nodeType'])
      if(temp_left === temp_selected_left){
        console.log("HEIGHT_LEFTTT", temp_top, temp_selected_top);
        let temp_wrapper = document.createElement("div");
        temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
        this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
        temp_wrapper.style.position = "absolute";
        temp_wrapper.style.height = (temp_selected_top - temp_top)+"px";
        temp_wrapper.style.top = "-"+(temp_selected_top - temp_top - 86)+"px";
        temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
        temp_wrapper.style.width = "350px";
        temp_wrapper.style.left = "-25px"
        temp_selected.append(temp_wrapper);
      }
      else{
        console.log("HEIGHT", main_height, temp_selected_top);
        let temp_wrapper = document.createElement("div");
        temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
        this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
        temp_wrapper.style.position = "absolute";
        temp_wrapper.style.height = (temp_selected_top - main_height + 86)+"px";
        temp_wrapper.style.top = "-"+(temp_selected_top - main_height)+"px";
        temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
        temp_wrapper.style.width = "350px";
        temp_wrapper.style.left = "-25px"
        temp_selected.append(temp_wrapper);
      }
      return -1;
    }
  }
  checkIfPathConnected(nextNodeIndex, childCount,type){
    let data = [];
    console.log("NEXTNODE",nextNodeIndex)
    if(type === 'AUDIENCESPLIT'){
      let temp_data = this.nodeList[nextNodeIndex]['nodeData']['definition']['splitGroups'];
      for(let d in temp_data){
        console.log("DDD",d)
        data.push(d);
      }
    }
    else{
      let temp_data = this.nodeList[nextNodeIndex]['nodeData']['definition']['pathGroups'];
      for(let d in temp_data){
        console.log("DDD",d)
        data.push(d);
      }
    }
    console.log("DATA",data)
    if(childCount != data.length){ 
      for(let i in this.nodeList){
        if(this.nodeList[i]['previousNodeId'] === this.nodeList[nextNodeIndex]['nodeId']){
          for(let j =0;j<data.length;j++){
            console.log("j",j, this.nodeList[i]['previousNodeRelation'].substring(0,5))
            let path = this.nodeList[i].previousNodeRelation.substring(
              7,
              this.nodeList[i].previousNodeRelation.length
            );
            console.log("PATH", path);
            console.log("DATA_J",data[j])
            if(path === data[j]){
              data.splice(j,1)
              console.log("DAtA",data)
            }
          }
        }
      }
          if(data.length != 0){
            for(let j =0;j<data.length;j++){
              let parent = <HTMLElement> document.getElementById("parent-div");
              let main = document.getElementById("canvas");
              let main_height = main.getBoundingClientRect().top + parent.scrollTop;
              let temp_class_name = document.getElementById("node-container-kpi-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
              let temp_selected = document.getElementById("ab-"+this.nodeList[nextNodeIndex]['nodeBlockId']+"-path-"+data[j]);
              let temp_top = temp_class_name.getBoundingClientRect().top + parent.scrollTop;
              let temp_left = temp_class_name.getBoundingClientRect().left + parent.scrollLeft;
              let temp_selected_top = temp_selected.getBoundingClientRect().top + parent.scrollTop;
              let temp_selected_left = temp_selected.getBoundingClientRect().left + parent.scrollLeft;    
              console.log("NEXT_CLASS", this.nodeList[nextNodeIndex]['nodeBlockId'], this.nodeList[nextNodeIndex]['nodeType'], data[j])
              console.log("HEIGHT", temp_top, temp_selected_top);
              console.log("LEFT", temp_left, temp_selected_left);
              if(temp_selected_left - temp_left <120){
                let temp_wrapper = document.createElement("div");
                temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
                this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
                temp_wrapper.style.position = "absolute";
                temp_wrapper.style.height = (temp_selected_top - temp_top )+"px";
                // temp_wrapper.style.top = "-"+(temp_selected_top - temp_top - 86)+"px";
                temp_wrapper.style.top = "86px"
                temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
                temp_wrapper.style.width = "350px";
                temp_wrapper.style.left = "-25px"
                temp_class_name.append(temp_wrapper);
              }
              else{
                let temp_wrapper = document.createElement("div");
                temp_wrapper.setAttribute("id", "border-container-"+this.nodeList[nextNodeIndex]['nodeBlockId']);
                this.border_node_name.push(this.nodeList[nextNodeIndex]['nodeBlockId']);
                temp_wrapper.style.position = "absolute";
                temp_wrapper.style.height = (temp_top - main_height + 86 + 130)+"px";
                temp_wrapper.style.top = "-"+(temp_top - main_height)+"px";
                temp_wrapper.style.background = "rgba(231, 187, 187, 0.25)";
                temp_wrapper.style.width = "350px";
                temp_wrapper.style.left = "325px"
                temp_class_name.append(temp_wrapper);
              }
            }
          }
        
    }
  }
  // redraw(){
  //   let canvas = document.getElementById("canvas");
  //   let can = this.canvasRef.nativeElement;
  //   can.style.transform = "scale(" + this.scaleFactor + ")";
  //   can.style.transformOrigin = "left top";
  // }
  // zoomIn() {
  //   this.scaleFactor += 0.1;
  //   this.redraw();
  // }
  // zoomOut(){
  //   this.scaleFactor -= 0.1;
  //   this.redraw();
  // }
  getArray(temp_data){
    let data = [];
    for(let d in temp_data){
      console.log("DDD",d)
      data.push(d);
    }
    return data;
  }
  getRevenue(data){
    console.log("DAtA",data);
    console.log("analyticsData",this.analyticsData)
    if(data.nodeType !== 'ABTESTING' && data.nodeType !=='AUDIENCESPLIT'){
      for(let i in this.analyticsData){
        if(i.indexOf(data.nodeId) !== -1){
          let value = this.analyticsData[i];
          value = value.replace("REVENUE##",'');
          this.revenue = parseInt(value);
        }
      }
    }
    if(data.nodeType === 'ABTESTING'){
      for(let key in data.nodeData.definition.pathGroups){

      }
    }
    if(data.nodeType === 'AUDIENCESPLIT'){
      this.revenueBranched = {};
      for(let key in data.nodeData.definition.groupName){
        for(let i in this.analyticsData){
          if(i.indexOf(data.nodeId) !== -1 && i.indexOf(data.nodeData.definition.groupName[key]) !== -1){
            let value = this.analyticsData[i];
            value = value.replace("REVENUE##",'');
            this.revenue = parseInt(value);
            this.revenueTotalbranch = this.revenueTotalbranch + parseInt(value);
            this.revenueBranched[data.nodeData.definition.groupName[key]] = value;
          }
        }
      }
    }
    console.log(this.revenueBranched)
    console.log(this.revenue);
  }

}
