<div class="main-container">
    <div style="display: flex; justify-content: center; margin-bottom: 16px">
      <div>{{data['heading']}}</div>
    </div>
    <div class="done-icon-container" *ngIf="data['type'] !== 'FAIL'">
        <img
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/done.gif"
        style="    width: 90px;
height: 70px;
margin-bottom: 5px;" alt="">
    </div>
    <div class="done-icon-container" *ngIf="data['type'] === 'FAIL'">
      <img
      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/pricing/fail.gif"
      style="    width: 90px;
height: 70px;
margin-bottom: 5px;" alt="">
    </div>
    <span class="message">{{ data["message"] }}</span>
    <div class="done-icon-container" style="margin-top: 15px">
      <span class="back-button" (click)="submit()">{{ data["buttonText"] }}</span>
    </div>
  </div>
  