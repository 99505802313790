import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, SurveySchedulesSuccessComponent, ContactUsDialogComponent, HtmlDataDialogComponent, HtmlForEmailTemplatesDialogComponent } from "src/app/shared/components/dialog-box";
import { OfferAttachEmailDialogComponent } from "../../../offer/pages";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-survey-schedule",
  templateUrl: "./survey-schedule.component.html",
  styleUrls: ["./survey-schedule.component.css"],
})
export class SurveyScheduleComponent implements OnInit {
  merchantTrackingAction = "schedule";
  merchantTrackingType = "";

  SurveyId;
  surveyupdateId;
  scheduleForm;
  submitClicked = false;
  segments = [];
  selectedSegments = [];
  disableAllCustomerSelection = false;
  disableCustomerSegmentSelection = false;
  selectedSurveyName;
  preselectedSegments = [];
  validWidgets = null;
  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  layoutId = null;
  surveyTemplateId = null;
  previousLoc = "list";
  searchedSegments = [];
  searchingValue = "";
  customerSegmentList = [];
  customerTierList = [];
  customerList = [];
  employeeList = [];
  openScheduleOverlay = false;
  message = "";
  submitData : any;
  shop = "";
  totalCustomerCount = 0;
  adminCreated = {
    storeId:null,
    storeName:"ADMIN",
    qid:null
  };
  storeDataJSON = {};
  @ViewChild("search") searchElement: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private dialog: MatDialog,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    window["screen-name"] = "SURVEY SCHEDULE";
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit() {
    if(this.tokenStorage.getqId() !== "null"){
      this.getParticularStoreJSON()
    }
    this.getWidgets();
    this.initializeForm();
    this.shop = this.tokenStorage.getMerchantOnlineStore();
    //this.opensnapshotHtmlDialog();
    this.route.params.subscribe((param) => {
      this.SurveyId = param["id"];
      this.surveyupdateId = param["sid"];
      if (param["loc"]) {
        this.previousLoc = param["loc"];
      }
    });
    // this.apiCall.getAllCustomerSegments().subscribe(
    //   (response) => {
    //     let segment = (response["body"]);
    //     for (let i = 0; i < segment.length; i++) {
    //       this.segments.push({
    //         id: segment[i]["id"],
    //         name: segment[i]["segmentName"],
    //         isActive: segment[i]["isActive"],
    //         status: false,
    //       });
    //     }

    //     if (this.surveyupdateId) {
    //       this.apiCall
    //         .getScheduledSurveyDetails(this.surveyupdateId)
    //         .subscribe((result) => {
    //           this.prefillForm(result["body"]);
    //         });
    //     } else {
    //       this.initializeForm();
    //     }
    //   },
    //   (err) => {
    //     this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("error", ['POS_PAGE']), 2000);
    //   }
    // );
    this.getSurveyTemplateDetail(this.SurveyId);
    this.getCustomerSegmentsAndTiers();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  initializeForm() {
    this.scheduleForm = new FormGroup({
      surveyName: new FormControl(
        "",
        Validators.compose([Validators.maxLength(100), Validators.required])
      ),
      customerSegments: new FormControl(),
      scheduleType: new FormControl("INSTANT"),
      orderState: new FormControl("FULFILLED"),
      channel: new FormControl("Email"),
      delayDaysPeriodic: new FormControl(
        7,
        Validators.compose([Validators.min(7), Validators.required])
      ),
      delayDays: new FormControl(
        0,
        Validators.compose([Validators.min(0), Validators.required])
      ),
      delayHours: new FormControl(
        0,
        Validators.compose([Validators.min(0), Validators.required])
      ),
      delayMinutes: new FormControl(
        0,
        Validators.compose([Validators.min(0), Validators.required])
      ),
      reminder: new FormControl(false),
    });
  }

  search(value) {
    this.searchingValue = value;
    if (value.length > 0) {
      Object.keys(this.segments).map((key) => {
        if (
          this.segments[key].name.toLowerCase().includes(value.toLowerCase())
        ) {
          this.searchedSegments.push(this.segments[key]);
        }
      });
    } else {
      this.searchedSegments = [];
    }
  }

  prefillForm(item) {
    var i;
    this.layoutId = item.layoutId;
    let predelay: Array<string> =
      item["surveyScheduleType"]["delay"].split(":");
    for (i = 0; i < item["targetCustomerSegments"].length; i++) {
      this.preselectedSegments.push(
        item["targetCustomerSegments"][i]["segmentName"]
      );
    }
    // this.preselectedSegments = item["targetCustomerSegments"];
    this.scheduleForm = new FormGroup({
      surveyName: new FormControl(
        item["surveyName"],
        Validators.compose([Validators.maxLength(50), Validators.required])
      ),
      customerSegments: new FormControl(this.preselectedSegments),
      scheduleType: new FormControl(item["surveyScheduleType"]["scheduleType"]),
      orderState: new FormControl(item["surveyScheduleType"]["orderState"]),
      delayDaysPeriodic: new FormControl(
        predelay[0],
        Validators.compose([Validators.min(1), Validators.required])
      ),
      delayDays: new FormControl(
        predelay[0],
        Validators.compose([Validators.min(0), Validators.required])
      ),
      delayHours: new FormControl(
        predelay[1],
        Validators.compose([
          Validators.min(0),
          Validators.max(23),
          Validators.required,
        ])
      ),
      delayMinutes: new FormControl(
        predelay[2],
        Validators.compose([
          Validators.min(0),
          Validators.max(59),
          Validators.required,
        ])
      ),
      reminder: new FormControl(false),
    });
  }
  getSurveyTemplateDetail(surveyId) {
    this.apiCall.getSurveyTemplate(surveyId).subscribe(
      (response) => {
        if (response["message"] === "SUCCESS") {
          let survey = JSON.parse(response["body"]);
          // this.selectedSurvey = survey['layoutHtml'];
          this.selectedSurveyName = survey["name"];
          this.merchantTrackingType =
            survey["type"] == "STANDARD" ? "standard" : "custom";
        }
      },
      (err) => {
        // this.snackBar.openSnackBar("Failed", 2000);
      }
    );
  }
  validation() {
    if (this.scheduleForm.get("surveyName").errors || (this.scheduleForm.get('surveyName').value.split(' ').join('').length == 0)) {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please fill the Survey Name field", ['POS_PAGE']), 2000);
      return true;
    }
    // if (this.scheduleForm.get("surveyEmailSubject").errors || (this.scheduleForm.get('surveyEmailSubject').value.split(' ').join('').length == 0)) {
    //   this.snackBar.openSnackBar(
    //     "Please fill the Survey Email Subject field",
    //     2000
    //   );
    //   return true;
    // }
    if (
      this.scheduleForm.controls.customerSegments.value == null ||
      this.scheduleForm.controls.customerSegments.value.length == 0
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Please check the customer segment field", ['POS_PAGE']),
        2000
      );
      return true;
    }
    if (this.scheduleForm.get("scheduleType").value === "PERIODIC") {
      if (this.scheduleForm.get("delayDaysPeriodic").errors) {
        this.snackBar.openSnackBar(
          "'Send Survey every' field should be greater than 6",
          2000
        );
        return true;
      }
    }
    if (this.scheduleForm.get("scheduleType").value === "ORDER") {
      if (
        this.scheduleForm.get("delayDays").errors ||
        this.scheduleForm.get("delayMinutes").errors ||
        this.scheduleForm.get("delayHours").errors
      ) {
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please check the form fields", ['POS_PAGE']), 2000);
        return true;
      }
      if (
        this.scheduleForm.get("delayDays").value < 0 &&
        this.scheduleForm.get("delayDays").value < 0 &&
        this.scheduleForm.get("delayDays").value < 0
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("'Survey should be sent after' field should not be less than 0", ['POS_PAGE']),
          2000
        );
        return true;
      }
    }
  }

  submitSurvey() {
    this.submitClicked = true;
    let selectedSegmentsForRequest = [];
    let selectedTiersForRequest = [];
    let selectedCustomerListsForRequest = [];
    let selectedEmployeeListsForRequest = [];

    if (this.validation()) {
      return;
    }
    if (this.scheduleForm.controls.customerSegments.value) {
      for (
        let i = 0;
        i < this.scheduleForm.controls.customerSegments.value.length;
        i++
      ) {
        let cur = [...this.customerSegmentList,...this.customerTierList,...this.customerList, ...this.employeeList].find((curSegment)=>curSegment.name === this.scheduleForm.controls.customerSegments.value[i]);
        if(cur.category.toLowerCase() === 'tier') {
          selectedTiersForRequest.push({
            id: cur.id,
            tierName: cur.name,
          });
        }
        if(cur.category.toLowerCase() === 'segment') {
          selectedSegmentsForRequest.push({
            id: cur.id,
            segment: cur.name,
          });
        }
        if(cur.category.toLowerCase() === 'customerlist') {
          selectedCustomerListsForRequest.push({
            id: cur.id,
            segment: cur.name,
          });
        }
        if(cur.category.toLowerCase() === 'employeelist') {
          selectedEmployeeListsForRequest.push({
            id: cur.id,
            segment: cur.name,
          });
        }
      }
    }
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      surveyTemplateId: this.SurveyId,
      surveyName: this.scheduleForm.get("surveyName").value,
      // surveyEmailSubject: this.scheduleForm.get("surveyEmailSubject").value,
      // targetCustomerSegments: this.selectedSegments,
      surveyScheduleType: {
        scheduleType: this.scheduleForm.get("scheduleType").value,
        orderState: this.scheduleForm.get("orderState").value,
        delay:
          this.scheduleForm.get("scheduleType").value === "ORDER"
            ? this.scheduleForm.get("delayDays").value +
              ":" +
              this.scheduleForm.get("delayHours").value +
              ":" +
              this.scheduleForm.get("delayMinutes").value
            : this.scheduleForm.get("delayDaysPeriodic").value + ":0:0",
      },
      sendReminder: this.scheduleForm.get("reminder").value,
      surveyStatus: "ACTIVE",
      // channel: "EMAIL",
      // layoutId: this.layoutId,
    };
    if(selectedSegmentsForRequest.length>0) {
      data['targetCustomerSegments'] = selectedSegmentsForRequest;
    }
    if(selectedTiersForRequest.length>0) {
      data['targetCustomerTiers'] = selectedTiersForRequest;
    }
    if(selectedCustomerListsForRequest.length>0) {
      data['targetCustomerLists'] = selectedCustomerListsForRequest;
    }
    if(selectedEmployeeListsForRequest.length>0) {
      data['targetEmployeeLists'] = selectedEmployeeListsForRequest;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Schedule survey?", ['POS_PAGE']),
        message: "",
        "data-widget": "SURVEY SCHEDULE",
        "data-button": "Confirm: Schedule",
        "data-item": this.SurveyId,
        successButtonText: this._i18nDynamicTranslate.transform("Confirm", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.scheduleSurvey(data).subscribe(
          (response) => {
            if (response["message"] === "SUCCESS") {
              const dialogRef = this.dialog.open(
                SurveySchedulesSuccessComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "auto",
                  disableClose: true,
                }
              );
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.router.navigate(["/app/surveys"], {
                    queryParams: { scheduled: true },
                  });
                }
              });
            } else {
              this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']), 2000);
            }
          },
          (err) => {
            const dialogRef = this.dialog.open(ContactUsDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              height: "auto",
              disableClose: true,
              width: "380px",
              data: {
                header: this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
                body: this._i18nDynamicTranslate.transform(err.error["body"], ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              // this.disabled = false;
            });
            //  this.snackBar.openSnackBar(err["error"]["message"], 2000);
          }
        );
      }
    });
  }

  getCustomerSegmentId(segmentName) {
    return [...this.customerSegmentList,...this.customerTierList,...this.customerList,...this.employeeList].find((curSegment)=>curSegment.name === segmentName);
  }
  changeScheduleTypeSelection() {
    if (this.scheduleForm.get("scheduleType").value === "ORDER") {
      this.scheduleForm.controls.customerSegments.setValue(["All Customers"]);
    } else {
      this.scheduleForm.controls.customerSegments.setValue([]);
    }
    // this.scheduleForm.get("scheduleType").value
    this.selectedSegments = [];
    this.changeSegmentSelection();
    // this.disableAllCustomerSelection=false;
    // this.disableCustomerSegmentSelection=false;
  }

  changeSegmentSelection() {
    if (this.scheduleForm.controls.customerSegments["value"].length === 1) {
      if (
        this.scheduleForm.controls.customerSegments["value"][0] ===
        "All Customers"
      ) {
        this.disableCustomerSegmentSelection = true;
      } else {
        this.disableAllCustomerSelection = true;
      }
    } else if (
      this.scheduleForm.controls.customerSegments["value"].length === 0
    ) {
      this.selectedSegments = [];
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
    }
    this.getSegmentCount();
    // for (let i = 0; i < this.scheduleForm.controls.customerSegments['value'].length; i++) {
    //   if (this.scheduleForm.controls.customerSegments['value'][i] === "ALL_CUSTOMERS") {
    //     this.disableCustomerSegments = true;
    //     let value = ["ALL_CUSTOMERS"]
    //     this.scheduleForm.controls.targetCustomerSegments.setValue(value);
    //     return;
    //   }
    // }
    // this.disableCustomerSegments = false;
  }

  opensnapshotHtmlDialog() {
    var dat = { type: null, id: null, templateId: null, actionType: null };
    if (this.layoutId > 0) {
      const dialogRef = this.dialog.open(HtmlDataDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "60%",
        height: "500px",
        data: { type: "survey", id: this.layoutId, templateId: this.SurveyId },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "edit") {
          this.loadEmailEditor();
        }
      });
    } else {
      this.apiCall.listEmailLayoutFilter("SURVEY").subscribe((res) => {
        if (res["message"] === "SUCCESS" || res["message"] === "success") {
          this.surveyid = res["body"][0]["id"];
          dat = {
            type: "surveyemailtemplate",
            id: res["body"][0]["id"],
            templateId: this.SurveyId,
            actionType: "Survey",
          };
          const dialogRef = this.dialog.open(
            HtmlForEmailTemplatesDialogComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "60%",
              height: "500px",
              data: dat,
            }
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result == "edit") {
              this.loadEmailEditor();
            }
          });
        }
      });
    }
  }

  surveyid;

  loadEmailEditor() {
    var specialTagType = null;
    var imageTagStatus = false;
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);

    if (this.layoutId > 0) {
      var datas = {
        process: "EditTextBodySurvey",
        id: this.layoutId,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        type: "Survey",
        offerId: this.SurveyId,
        isReward: false,
        rewardType: null,
      };
      const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
        panelClass: "customAttachEmail",
        height: sh + "px",
        width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
        maxWidth: "99vw",
        disableClose: true,
        data: datas,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
          this.layoutId = result.layoutId;
        }
      });
    } else {
      var datas = {
        process: "surveyEmail",
        id: this.surveyid,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        type: "SURVEY",
        offerId: this.SurveyId,
        isReward: false,
        rewardType: null,
      };

      const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
        panelClass: "customAttachEmail",
        height: sh + "px",
        width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
        maxWidth: "99vw",
        disableClose: true,
        data: datas,
      });
      dialogRefer.afterClosed().subscribe((result) => {
        if (result) {
          this.layoutId = result.layoutId;
        }
      });
    }
  }
  // ;id=4957;type=standard
  backPressed() {
    if (this.previousLoc == "new") {
      this.router.navigate([
        "/app/surveys/add-survey",
        { id: this.SurveyId },
      ]);
    } else if (this.previousLoc == "dash") {
      this.router.navigate(["/app/surveys"]);
    } else {
      this.router.navigate([
        "/app/surveys/survey-list",
        { id: this.SurveyId, type: 'standard' }
      ]);
    }
  }
  getCustomerSegmentsAndTiers() {
    this.apiCall.getCustomerSegmentsAndTiers().subscribe(response => {
      let res = (response['body']);
      for (let i = 0; i < res.length; i++) {
        if(res[i].category.toLowerCase() === 'segment') {
          this.customerSegmentList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
        if(res[i].category.toLowerCase() === 'tier') {
          this.customerTierList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
        if(res[i].category.toLowerCase() === 'customerlist') {
          this.customerList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
        if(res[i].category.toLowerCase() === 'employeelist') {
          this.employeeList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
          });
        }
      }
    },
    error => {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching getCustomerSegmentsAndTiers data", ['POS_PAGE']), 2000);
    });
  }
  selectChannel(){
    let selectedSegmentsForRequest = [];
    let selectedTiersForRequest = [];
    let selectedCustomerListsForRequest = [];
    let selectedEmployeeListsForRequest = [];
    this.submitClicked = true;
    if (this.validation()) {
      return;
    }
    if (this.scheduleForm.controls.customerSegments.value) {
      if(this.scheduleForm.controls.customerSegments.value.length == 1 && this.scheduleForm.controls.customerSegments.value[0].toLowerCase() == 'all customers') {
        selectedSegmentsForRequest.push({
          id: null,
          segment: this.scheduleForm.controls.customerSegments.value[0],
        });
      } else {
        for (
          let i = 0;
          i < this.scheduleForm.controls.customerSegments.value.length;
          i++
        ) {
           let cur = [...this.customerSegmentList,...this.customerTierList,...this.customerList, ...this.employeeList].find((curSegment)=>curSegment.name === this.scheduleForm.controls.customerSegments.value[i]);
            if(cur.category.toLowerCase() === 'tier') {
              selectedTiersForRequest.push({
                id: cur.id,
                tierName: cur.name,
              });
            }
            if(cur.category.toLowerCase() === 'segment') {
              selectedSegmentsForRequest.push({
                id: cur.id,
                segment: cur.name,
              });
            }
            if(cur.category.toLowerCase() === 'customerlist') {
              selectedCustomerListsForRequest.push({
                id: cur.id,
                customerListName: cur.name,
              });
            }
            if(cur.category.toLowerCase() === 'employeelist') {
              selectedEmployeeListsForRequest.push({
                id: cur.id,
                customerListName: cur.name,
              });
            }
        }
      }
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      storeId : this.tokenStorage.getqId() === 'null' ? null : this.tokenStorage.getqId(),
      surveyTemplateId: this.SurveyId,
      surveyName: this.scheduleForm.get("surveyName").value,
      // surveyEmailSubject: this.scheduleForm.get("surveyEmailSubject").value,
      // targetCustomerSegments: this.selectedSegments,
      surveyScheduleType: {
        scheduleType: this.scheduleForm.get("scheduleType").value,
        orderState: this.scheduleForm.get("orderState").value,
        delay:
          this.scheduleForm.get("scheduleType").value === "ORDER"
            ? this.scheduleForm.get("delayDays").value +
              ":" +
              this.scheduleForm.get("delayHours").value +
              ":" +
              this.scheduleForm.get("delayMinutes").value
            : this.scheduleForm.get("delayDaysPeriodic").value + ":0:0",
      },
      sendReminder: this.scheduleForm.get("reminder").value,
      surveyStatus: "ACTIVE",
      // channel: "EMAIL",
      layoutId: this.layoutId,
      createdBy: this.tokenStorage.getqId() === "null" ? this.adminCreated : this.storeDataJSON
    };
    if(selectedSegmentsForRequest.length>0) {
      data['targetCustomerSegments'] = selectedSegmentsForRequest;
    }
    if(selectedTiersForRequest.length>0) {
      data['targetCustomerTiers'] = selectedTiersForRequest;
    }
    if(selectedCustomerListsForRequest.length>0) {
      data['targetCustomerLists'] = selectedCustomerListsForRequest;
    }
    if(selectedEmployeeListsForRequest.length>0) {
      data['targetEmployeeLists'] = selectedEmployeeListsForRequest;
    }
    this.submitData = data;

    this.openScheduleOverlay = true;
    this.modifyConfig(true);
   }
  }
  modifyConfig(status) {
    document.getElementById("viewconfigDiv").style.zIndex = "1";
    document.getElementById("overlay").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.display = "flex";
    this.on();
  }

  cancelAllSchedule() {
    this.off();
    document.getElementById("viewconfigDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "-1";
    document.getElementById("configDiv").style.display = "none";
    document.getElementById("overlay").style.zIndex = "-1";
    document.getElementById("overlay").style.display = "none";
  }

  on() {
    // this.getParticularOfferDetail();
    // this.getTemplates();
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }
  receiveMessage($event) {
    this.message = $event;
    this.cancelAllSchedule();
    this.openScheduleOverlay = false;
  }
  getSegmentCount() {
    console.log("test", this.scheduleForm.controls.customerSegments["value"], this.selectedSegments)
    this.totalCustomerCount = null;
    if (this.scheduleForm.controls.customerSegments["value"].length === 0) {
      this.totalCustomerCount = 0;
    } else {
      if (
        this.scheduleForm.controls.customerSegments["value"].length === 1 &&
        this.scheduleForm.controls.customerSegments["value"][0] === "All Customers"
      ) {
        let startDate = new Date();
        let endDate = new Date();
        this.apiCall.getAnalytics(startDate,endDate).subscribe((response)=>{
          this.totalCustomerCount = JSON.parse(
            response["body"]
          ).numberOfCustomers;
        })
      } else {
        let selectedSegmentsOrTiers = [];
        for (
          let i = 0;
          i < this.scheduleForm.controls.customerSegments.value.length;
          i++
        ) {
          selectedSegmentsOrTiers.push([...this.customerSegmentList,...this.customerTierList,...this.customerList,...this.employeeList].find((curSegment)=>curSegment.name === this.scheduleForm.controls.customerSegments.value[i]));
        }
        this.apiCall
          .getCustomerCountInSegmentAndTiers(selectedSegmentsOrTiers)
          .subscribe((response) => {
            this.totalCustomerCount = JSON.parse(
              response["body"]
            ).totalCustomerCount;
          });
      }
    }
  }
  getParticularStoreJSON(){
    let data: any = {};
    this.apiCall.getSpecificStoreOffer().subscribe((response)=>{
      console.log(response)
      data.storeId = response['body']['storeMetaInfo'].storeId;
      data.storeName = response['body']['storeMetaInfo'].storeName;
      data.qid = response['body']['storeMetaInfo'].qid;
      this.storeDataJSON = data;
    });
  }
  getSearchFilteredSegments(segmentsList) {
    return segmentsList.filter(segment =>
      this.searchingValue ? segment['name']?.toLowerCase().includes(this.searchingValue?.toLowerCase()) : true
    ); 
  }
  isSegmentPresent() {
    if (this.customerSegmentList) {
      return this.getSearchFilteredSegments(this.customerSegmentList).length > 0;
    }
    return false;
  }
  isTierSegmentPresent(): boolean {
    if (this.customerTierList) {
      return this.getSearchFilteredSegments(this.customerTierList).length > 0;
    }
    return false;
  }
  isCustomerListPresent(): boolean {
    if (this.customerList) {
      return this.getSearchFilteredSegments(this.customerList).length > 0;
    }
    return false;
  }

  isEmployeeListPresent(): boolean {
    if (this.employeeList) {
      return this.getSearchFilteredSegments(this.employeeList).length > 0;
    }
    return false;
  }
}
