<div *ngIf="data.ifSend === undefined" class="font" [attr.data-widget-name]="data['data-widget']">
  <div>
    <div class="header">
      <span style="text-align: center">SEND OFFER TO PARTNER</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div>
    <div class="message" *ngIf="length != 0">
      Please select an offer you want to send to {{data.name}}, or you can create a new offer also for them.
    </div>
    <div class="message" *ngIf="length == 0">
      In order to send an offer to {{ data["name"] }}, you need to create an offer for them.    </div>
  </div>
  <div
    *ngIf="length != 0"
    style="
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 0px 16px;
      width: 96%;
    "
  >
    <div style="margin-top: 20px">Select offer for partner customers</div>
    <div
      class="add-reward-div"
      style="cursor: pointer"
      (click)="continue(false)"
    >
      <div class="material-icons-outlined">add</div>
      <div style="text-transform: capitalize; ">{{'SHARED_PAGE.CREATE_NEW_OFFER' | i18nTranslate}}</div>
    </div>
  </div>
  <div *ngIf="length != 0" class="rewards">
    <div
      *ngFor="let list of offersList"
      style="display: flex; align-items: center; padding: 10px 0px 10px 16px"
    >
      <div
        class="card-selection"
        style="position: static; cursor: pointer"
        [ngClass]="{ active: offerId == list['id'] }"
        (click)="
          selectList(list['partnershipOfferName'], list['id'])
        "
      >
        <mat-icon class="card-selection-icon">done</mat-icon>
      </div>
      <span
        (click)="
          selectList(list['partnershipOfferName'], list['id'])
        "
        style="padding: 10px 0px 10px 16px; cursor: pointer;"
        >{{ list["partnershipOfferName"] }} - {{list['description']}}</span
      >
    </div>
  </div>
  <div class="button-container" style="margin-top: 30px">
    <span class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span *ngIf="length != 0" class="primary-button" (click)="Send()">
     {{'SHARED_PAGE.SEND_OFFER' | i18nTranslate}}
    </span>
  </div>
</div>





<div *ngIf="data.ifSend !== undefined" class="font" [attr.data-widget-name]="data['data-widget']">
  <div>
    <div class="header">
      <span style="text-align: center">{{'SHARED_PAGE.SEND_OFFER_TO_PARTNER' | i18nTranslate}}</span>
      <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
    </div>
  </div>
  <div>
    <div class="message">
      {{'SHARED_PAGE.SEND_OFFER_TO_PARTNER_PARAGRAPH' | i18nTranslate : data.name}}    </div>
  </div>
  <div
    *ngIf="length != 0"
    style="
      padding: 0px 0px 0px 16px;
      width: 96%;
    "
  >
    <div style="margin-top: 20px">{{'SHARED_PAGE.OFFER_DETAILS_FOR_CUSTOMER' | i18nTranslate}}</div>
    <div style="margin-top: 10px;">{{data.offerName.toUpperCase()}} - {{data.description}}</div>
  </div>
  <div class="button-container" style="margin-top: 30px">
    <span class="secondary-button-border" (click)="submit(false)">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span *ngIf="length != 0" class="primary-button" (click)="Send()">
      {{'SHARED_PAGE.SEND_OFFER' | i18nTranslate}}
    </span>
  </div>
</div>

