import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-whatsapp-template-creation-dialog',
  templateUrl: './whatsapp-template-creation-dialog.component.html',
  styleUrls: ['./whatsapp-template-creation-dialog.component.css']
})
export class WhatsappTemplateCreationDialogComponent implements OnInit {

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
@ViewChild('fileInputVideo', { static: false }) fileInputVideoRef: ElementRef;
@ViewChild('fileInputDocument', { static: false }) fileInputDocumentRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<WhatsappTemplateCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _ApiCallService: ApiCallService,
    private _snackBar: SnackbarCollection,
    private tokenStorage: TokenStorageService
  ) { }

  whatsappText = "";
  whatsappHeaderText = "";
  charCount = 0;
  headerCount = 0;
  mergeTags =[];
  selectedMergeTags :any = [[]];
  templateName = "";
  textSelection;
  nameError = false;
  submit = false;
  toggled: boolean = false;
  dropDownStatus = false;
  mergetagFilter = "";
  selectedCategory = "";
  selectedTemplateType = "standard";
  optOutText = false;
  dataType = 'text';
  imageurl = "";
  videourl = "";
  documenturl = "";
  fileToUpload:File = null;
  fileuploading=false;
  files: any[] = [];
  imagePath;
  url;
  shopName = "";
  message;
  type = null;
  layoutType = "CAMPAIGN";
  namePatterm = '^[A-Za-z0-9_ ]+$';
  urlPattern='(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  isButtonAdded = false;
  buttonName = '';
  buttonUrl = '';
  errorUrl = false;
  templateSaveLoading = false;
  @ViewChild("selectCategory") selectCategory: MatSelect;
  @ViewChild("selectTemplateType") selectTemplateType: MatSelect;

  ngOnInit(): void {
    this.shopName = this.tokenStorage.getShopName();
    // this.mergeTags = [
    //   { name: "shop name", value: "{{$shop_name}}" },
    //   { name: "user name", value: "{{$user_name}}" },
    //   { name: "product name", value: "{{$product_name}}" },
    //   { name: "category name", value: "{{$category_name}}" },
    //   { name: "date", value: "{{$date}}" },
    // ];
    console.log("DARA",this.data)
    if(this.data != null){
      this.prefillData();
    }
    this.getMergeTags();
  }
  prefillData(){
    console.log("DARA",this.data);
    this.type = this.data['type'];
    let d = this.getLayout(this.data['layoutData']);
    this.templateName = d['templateName'] + "_copy";
    this.layoutType = this.data['layoutType'];
    this.whatsappText = d['body']
    this.updateSmsCount();
    this.selectedCategory = this.data['channelType'];
    console.log("Tem",this.templateName)
    this.selectedTemplateType = d['header'] !== null ?'media':'standard';
    this.dataType = d['header'].toLowerCase();
    this.whatsappHeaderText = d['headerText'] ? d['headerText']:"";
    this.imageurl = d['headerImage'];
    this.videourl = d['headerVideo'];
    this.documenturl = d['headerDocument'];
  }
  openSelectCategory(){
    this.selectCategory.open();
  }
  openSelectTemplateType(){
    this.selectTemplateType.open();
  }
  templateNameKeyPress(event) {
    if (50 - this.templateName.length === 0) {
      return false;
    }
  }

  buttonNameKeypress(event){
    if (20 - this.buttonName.length === 0) {
      return false;
    }
  }
  
  templateNamePaste(event) {
    if (
      50 - this.templateName.length <
      event.clipboardData.getData("text").length
    ) {
      return false;
    }
  }
  updateMeasurementPointComment(event) {
    var txtarea = document.getElementById("whatsappText") as HTMLInputElement;
    this.textSelection = txtarea.selectionStart;
  }
  updateMeasurementPointCommentHeader(evet){
    var txtarea = document.getElementById("whatsappHeaderText") as HTMLInputElement;
    this.textSelection = txtarea.selectionStart;
  }
  updateSmsCount() {
    let whatsappText = this.whatsappText;
    let countOfChar = 0;
    for (let i = 0; i < whatsappText.length; i++) {
      if (whatsappText[i] === "{") {
        if (whatsappText[i + 1] === "{") {
          for (let j = i + 2, count = 1; j < whatsappText.length; j++, count++) {
            if (whatsappText[j] === "}") {
              if (whatsappText[j + 1] === "}") {
                if (this.checkMergeTagExist(whatsappText.substring(i, j + 2))) {
                  countOfChar += count + 3;
                }
                break;
              }
            }
          }
        }
      }
    }
    let finalCharCount = this.whatsappText.length - countOfChar;
    if (this.optOutText) {
      finalCharCount += 22;
    }
    this.charCount = finalCharCount;
  }
  updateHeaderTextCount(){
    let headerText = this.whatsappHeaderText;
    this.headerCount = headerText.length
  }
  checkMergeTagExist(mergeTag) {
    if (this.mergeTags.findIndex((x) => x.value === mergeTag) > -1) {
      return true;
    } else {
      return false;
    }
  }
  keypresshandler(event) {
    this.updateSmsCount();
  }
  onPaste(event) {
    this.updateSmsCount();
  }
  onPasteHeader(event){
    this.updateHeaderTextCount();
  }
  handleSelection(event) {
    if (!(160 - this.whatsappText.length < event.char.length)) {
      let text = this.whatsappText;
      this.whatsappText =
        text.slice(0, this.textSelection) +
        event.char +
        text.slice(this.textSelection);
      this.textSelection += event.char.length;
      var txtarea = document.getElementById("whatsappText") as HTMLInputElement;
      txtarea.selectionEnd = this.textSelection + 1;
      this.updateSmsCount();
    }
  }
  toggleSelect(
    event,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId
  ) {
    var txtarea = document.getElementById("whatsappText");
    txtarea.focus();
    event.stopPropagation();
    let element = document.getElementById(elementId);
    let segmentSelect = document.getElementById(segmentSelectId);
    let segmentSelectPanel = document.getElementById(segmentSelectPanelId);
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId
    );
    if (this.dropDownStatus) {
      this.dropDownStatus = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.boxShadow = "0 0 10px 0 #c6ced685";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      //this.getSegmentCount();
      //element.style.display = 'none';
    } else {
      this.dropDownStatus = true;
      element.style.height = "140px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }
  saveSms(schedule){
    console.log("YETSDSAd")
    this.submit = true;
    this.templateSaveLoading = true;
    this.getBodyText();
    if (this.whatsappText.length === 0 || this.templateName.length === 0 || !this.selectedCategory) {
      this.templateSaveLoading = false;
      return;
    }
    if(((this.dataType == 'text' && this.whatsappHeaderText.length == 0)
        || (this.dataType == 'image' && !this.imageurl[0])
        || (this.dataType == 'video' && !this.videourl[0])
        || (this.dataType == 'document' && !this.documenturl[0])) && this.selectedTemplateType !== 'standard') {
      this.templateSaveLoading = false;
      return;
    }
    if (this.templateName.match(this.namePatterm) === null) {
      this.templateSaveLoading = false;
      this.nameError = true;
      return;
    }
    if(this.buttonUrl.match(this.urlPattern) === null && this.isButtonAdded){
      this.templateSaveLoading = false;
      this.errorUrl = true;
      return;
    }
    let button;
    if(this.isButtonAdded){
      button = [{
        urlText: this.buttonName,
        url: this.buttonUrl
      }];
    }
    let data = {
     type: this.type,
     layoutName: this.templateName,
     templateName: this.templateName.replace(/ /g, '_').toLowerCase(),
     channelType: this.selectedCategory,
     layoutType: this.layoutType,
     body: this.whatsappText,
     bodyText: this.selectedMergeTags[0].length === 0 ? null : this.selectedMergeTags,
     header: this.selectedTemplateType !=='standard'? this.dataType.toUpperCase(): null,
     headerText: this.dataType === 'text' ? this.whatsappHeaderText : null,
     headerImage: this.dataType === 'image' ? this.imageurl[0] : null,
     headerVideo: this.dataType === 'video' ? this.videourl[0] : null,
     headerDocument: this.dataType === 'document' ? this.documenturl[0] : null,
     footer: this.optOutText ? "Reply STOP to opt out" : null,
     buttons: this.isButtonAdded? button : null

    };
    this._ApiCallService.checkDuplicateNameInTemplate(this.templateName, 'WHATSAPP').subscribe(
      (duplicateCheckResponse: any) => {
        if (duplicateCheckResponse.message === 'success' && duplicateCheckResponse.body === false) {
          this._ApiCallService.createWhatsAppMerchantTemplate(data).subscribe(
            (response: any) => {
              if (response["message"] === 'SUCCESS') {
                console.log("RESPONSE", response);
                this._ApiCallService.saveWhatsAppMerchantTemplate(data).subscribe((data) => {
                });
                this.templateSaveLoading = false;
                this.dialogRef.close({ id: response.body, schedule: schedule });
              } 
            },
            (error) => {
              let errorMessage = 'Template creation failed';
              this._snackBar.openSnackBar(errorMessage, 2000);
              this.templateSaveLoading = false;
            }
          );
        } else {
          // Show error message if template already exists
          this._snackBar.openSnackBar("Template name already exists", 2000);
          this.templateSaveLoading = false;
        }
      },
      (error) => {
        let errorMessage = 'Template creation failed';
        this._snackBar.openSnackBar(errorMessage, 2000);
        this.templateSaveLoading = false;
      }
    );
    
  }
  getMergeTags(){
    this._ApiCallService.getMergeTageWhatsapp(this.layoutType, this.type).subscribe((response)=>{
      console.log("MERGETAGS", response);
      let data : any = [];
      for(let k in response['body']){
        let n:any={}
        n.name = k;
        n.value = response['body'][k];
        data.push(n);
      }
      this.mergeTags = data;
      console.log("TAGS",this.mergeTags)
    })
  }
  changeCategory(){
    console.log("CATEGORY", this.selectedCategory);
  }
  changeTemplateType(){
    console.log("TEMPLATE_TYPE", this.selectedTemplateType);
  }
  optoutValueChange(event) {
    this.updateSmsCount();
  }
  changeHeaderType(value){
    this.videourl = "";
    this.imageurl = "";
    this.documenturl = "";
    this.dataType = value;
  }
  async fileBrowseHandler(event, file, type) {

    let maxSize;
  
    switch (type) {
      case 'image':
        maxSize = 1 * 1024 * 1024; // 1 MB
        break;
      case 'video':
        maxSize = 10 * 1024 * 1024; // 10 MB
        break;
      case 'document':
        maxSize = 5 * 1024 * 1024; // 5 MB
        break;
    }
    const selectedFile = event.target.files[0];
  
    if (selectedFile.size > maxSize) {
      this._snackBar.openSnackBar(`File size should be less than ${maxSize / (1024 * 1024)} MB`, 2000);
      return;
    }
  
    this.fileuploading = true;
    this.fileToUpload = selectedFile;
    const fd = new FormData();
    fd.append("files", this.fileToUpload, this.fileToUpload.name);
    event.target.title = selectedFile.name;
  
    switch (type) {
      case 'image':
        this._ApiCallService.uploadImageWhatsApp(fd).subscribe((response) => {
          this.imageurl = response['body'];
          this.fileuploading = false;
        }, (err) => {
          this._snackBar.openSnackBar(err['error']['body'], 2000);
          this.fileuploading = false;
        });
        break;
      case 'video':
        this._ApiCallService.uploadVideoWhatsapp(fd).subscribe((response) => {
          this.videourl = response['body'];
          this.fileuploading = false;
        }, (err) => {
          this._snackBar.openSnackBar(err['error']['body'], 2000);
          this.fileuploading = false;
        });
        break;
      case 'document':
        this._ApiCallService.uploadDocumentWhatsapp(fd).subscribe((response) => {
          this.documenturl = response['body'];
          this.fileuploading = false;
        }, (err) => {
          this._snackBar.openSnackBar(err['error']['body'], 2000);
          this.fileuploading = false;
        });
        break;
    }
  }
  onFileDropped($event, type ){
    this.prepareFilesList($event);
      this.setFiles2($event);
  }
  uploadImg(){
    const fd = new FormData();
    console.log("FILETOUPLOAD",this.fileToUpload)
    fd.append("files",this.fileToUpload, this.fileToUpload.name);
    console.log("FD",fd)
    this._ApiCallService.uploadImageWhatsApp(fd).subscribe((response) => {
      console.log("RESPONSE",response)
      this.imageurl = response['body']
      this.fileuploading=false;
    },(err)=>{
      this._snackBar.openSnackBar(err['error']['body'], 2000);
      this.fileuploading = false;
    });    
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);

    }
  }
  setFiles2(event) {
    // this.image = true;
    const files = event.target.files;
    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
  removeUrl(type){
    if(type == 'image'){
      this.imageurl = "";
      this.resetFileInput(this.fileDropRef);
    } else if(type == 'video'){
      this.videourl = "";
      this.resetFileInput(this.fileInputVideoRef);
    } else {
      this.documenturl = "";
      this.resetFileInput(this.fileInputDocumentRef);
    }
  }

  resetFileInput(fileInput: ElementRef) {
    fileInput.nativeElement.value = '';
    fileInput.nativeElement.title = '';
  }
  addMergeTag(
    value,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId
  ) {
    // if (160 - this.smsText.length < value.length) {
    //   return;
    // }
    var txtarea = document.getElementById("whatsappText");
    let element = document.getElementById(elementId);
    let segmentSelect = document.getElementById(segmentSelectId);
    let segmentSelectPanel = document.getElementById(segmentSelectPanelId);
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId
    );
    this.dropDownStatus = false;
    element.style.height = "0px";
    segmentSelect.style.overflow = "hidden";
    segmentSelectPanel.style.boxShadow = "unset";
    segmentSelectPanel.style.border = "1px solid #919191";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
    let text = this.whatsappText;
    this.whatsappText =
      text.slice(0, this.textSelection) +
      value +
      text.slice(this.textSelection);
    txtarea.focus();
    // this.selectedMergeTags.push(value);
    this.updateSmsCount();
    // let txtareaEle = document.getElementById("smsText") as HTMLInputElement;
    // txtareaEle.selectionEnd = ;
  }
  getLayout(layout){
    let layoutData = JSON.parse(layout);
    return layoutData;
  }
  getBodyText(){
    let whatsappText = this.whatsappText
    this.selectedMergeTags = [[]];
    for (let i = 0; i < whatsappText.length; i++) {
      if (whatsappText[i] === "{") {
        if (whatsappText[i + 1] === "{") {
          for (let j = i + 2, count = 1; j < whatsappText.length; j++, count++) {
            if (whatsappText[j] === "}") {
              if (whatsappText[j + 1] === "}") {
                this.selectedMergeTags[0].push(whatsappText.substring(i + 2, j))
                break;
              }
            }
          }
        }
      }
    }
    console.log("SELECTED_MERGETAGS", this.selectedMergeTags);
  }
  buttonValueChange(event){

  }
}

