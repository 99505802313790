<div>
    <div *ngIf="data.type === 'arrow'">
        <span *ngIf="data.relation === 'TIMEOUT'" class="node-heading-font">{{'SHARED_PAGE.ON_TIMEOUT' | i18nTranslate}}</span>
        <span *ngIf="data.relation === 'NEXT'" class="node-heading-font">{{'SHARED_PAGE.ON_EVENT' | i18nTranslate}}</span>
    </div>
    <div *ngIf="data.type === 'data'">
        <div *ngIf="data.nodeType === 'e-t'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('e-t')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.EVENT' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{getEvent()}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="data.nodeType === 't-b'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('t-b')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.TYPE' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{data.content.type}}</span>
                </div>
                <div *ngIf="data.content.type === 'Recurring' || data.content.type === 'Scheduled'">
                    <div>
                        <div style="display:fllex">
                            <span class="node-key">{{'SHARED_PAGE.START_DATE' | i18nTranslate}}:</span>
                            <span class="node-value">&nbsp;{{data.content.startDate.substring(0,10)}}</span>
                        </div>
                        <div style="display:fllex">
                            <span class="node-key">{{'SHARED_PAGE.START_TIME' | i18nTranslate}}:</span>
                            <span class="node-value">&nbsp;{{data.content.startDate.substring(11, 17)}}</span>
                        </div>
                    </div>
                    <div *ngIf="data.content.type === 'Recurring'">
                        <div>
                            <div style="display:fllex">
                                <span class="node-key">{{'SHARED_PAGE.END_DATE' | i18nTranslate}}:</span>
                                <span class="node-value">&nbsp;{{data.content.endDate.substring(0,10)}}</span>
                            </div>
                            <div style="display:fllex">
                                <span class="node-key">{{'SHARED_PAGE.END_TIME' | i18nTranslate}}:</span>
                                <span class="node-value">&nbsp;{{data.content.endDate.substring(11, 17)}}</span>
                            </div>
                            <div style="display:fllex">
                                <span class="node-key">{{'SHARED_PAGE.REPEAT' | i18nTranslate}}:</span>
                                <span class="node-value">&nbsp;{{data.content.repeatType}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="data.nodeType === 'r-e'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('r-e')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.EVENT' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{getEvent()}}</span>
                </div>
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.SHOULD_BY' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{getTime()}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="data.nodeType === 's-s'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('s-s')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.TITLE' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{data.content.surveyTitleName}}</span>
                </div>
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.TYPE' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{getSurvey()}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="data.nodeType === 'e-c'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('e-c')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.TITLE' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{getEmail()}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="data.nodeType === 's-o'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('s-o')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">'{{'SHARED_PAGE.TITLE' | i18nTranslate}}':</span>
                    <span class="node-value">&nbsp;{{getOffer()}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="data.nodeType === 'w-n'">
            <div class="node-heading">
                <span class="node-heading-font">{{getType('w-n')}}</span>
            </div>
            <div *ngIf="data.content">
                <div style="display:fllex">
                    <span class="node-key">{{'SHARED_PAGE.WAIT_TIME' | i18nTranslate}}:</span>
                    <span class="node-value">&nbsp;{{getTime()}}</span>
                </div>
            </div>
        </div>
    </div>
</div>