<div class="main">
  <div class="feature-list-header-container">
    <span>{{'SHARED_PAGE.PLAN_FEATURES' | i18nTranslate}}</span
    ><span
      class="material-icons feature-list-close-icon"
      (click)="dialogRef.close()"
    >
      close
    </span>
  </div>
  <div *ngFor="let feature of data">
    <div class="feature">
      <span class="material-icons tick-icon">done</span>
      <span>{{ feature.split("_").join(" ") | titlecase }}</span>
    </div>
  </div>
</div>
