import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { DomainConfirmationDialogPopUpComponent, DeleteDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-domain-screen",
  templateUrl: "./domain-screen.component.html",
  styleUrls: ["./domain-screen.component.css"],
})
export class DomainScreenComponent implements OnInit {
  progressStage = 1;
  allDomainList;
  domainNameNew = "";
  subdomainNameNew = "";
  addDomainForm = null;
  existingDomainCount = 0;
  pendingDomainCount = 0;
  InprogressDomainCount=0;
  sendingDNSRecord: any[] = [];
  recievingDNSRecod: any[] = [];
  verifiedStatusText = "";
  verifiedStatus = "none";
  verify;
  domainConfigurationDataLoaded: boolean = false;
  verifyButtonClicked: boolean = false;

  addDomainLoaded: boolean = true;
  verifiyDomainLoaded = false;
  verifyApiloaded: boolean = true;
  allDomainApiloaded: boolean = true;
  updateDomainApiLoaded: boolean = true;
  deleteApiLoaded: boolean = true;
  indexU = null;
  indexV = null;
  indexUAct = null;
  indexProg=null;

  verifiedDomainList = [];
  unverifiedDomainList = [];
  domainRecordsFound = true;
  checked: boolean = false;
  appSubscriptionPlan;
  validWidgets = null;
  subdomain;
  domain;
  isDemo;

  valueDomainNameInfo = null;
  InProgressDomainList=[];
  constructor(
    private apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    window["screen-name"] = "PROFILE";
  }

  ngOnInit() {
    this.getWidgets();
    this.getAllDomains();
    this.isDemo = this.tokenStorage.getIsDemo();
    // this.addDomainForm = new FormGroup({
    //   domainName: new FormControl("", [
    //     Validators.compose([
    //       Validators.required,
    //       Validators.pattern(
    //         "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,10}$"
    //       ),
    //       Validators.minLength(1),
    //       Validators.maxLength(253),
    //     ]),
    //   ]),

    //   checked: new FormControl(),

    //   subdomainName: new FormControl("", [
    //     Validators.compose([
    //       Validators.required,
    //       Validators.minLength(1),
    //       Validators.maxLength(253),
    //     ]),
    //   ]),
    // });

    // this.route.queryParams.subscribe((params) => {
    //   if (params["domain"] && params["subdomain"] && params["preserve"]) {
    //     this.addDomainForm.controls.domainName.setValue(params["domain"]);
    //     this.addDomainForm.controls.subdomainName.setValue(params["subdomain"]);
    //     this.progressStage = 3;
    //     //this.addDomain();

    //     this.getDomainRecords();
    //     //  this.getDomainDetails();
    //    // this.exitProgress();
    //   }else if(params["preserve"]==false)
    //     {
    //       this.exitProgress();
    //     }else{
    //     this.getAllDomains()
    //   }
    // });
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getAllDomains() {
    this.addDomainForm = new FormGroup({
      domainName: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,10}$"
          ),
          Validators.minLength(1),
          Validators.maxLength(253),
        ]),
      ]),

      checked: new FormControl(),

      subdomainName: new FormControl("", [
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(253),
        ]),
      ]),
    });
    this.domainRecordsFound = true;
    const queryParams: Params = {
      layout: "domain",
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      // remove to replace all query params by provided
    });
    this.verifiedStatus = "none";
    this.allDomainApiloaded = false;
    console.log(this.unverifiedDomainList)
    this.apiCall.getAllDomain().subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.allDomainList = JSON.parse(response["body"])["domainConfigs"];
          // console.log(this.allDomainList);
          (this.verifiedDomainList = []), (this.unverifiedDomainList = []),(this.InProgressDomainList=[]);
          var i;
          for (i = 0; i < this.allDomainList.length; i++) {
            if (this.allDomainList[i]["state"] == "unverified") {
              this.unverifiedDomainList.push(this.allDomainList[i]);
            } else if(this.allDomainList[i]["state"] == "active") {
              this.verifiedDomainList.push(this.allDomainList[i]);
            }
            else{
              this.InProgressDomainList.push(this.allDomainList[i])
            }
          }
          // for(var x=0; x<this.unverifiedDomainList.length;x++){
          //   for(var y=0; y<this.InProgressDomainList.length; y++){
          //     console.log(this.unverifiedDomainList[x]['domainName'])
          //     if(this.unverifiedDomainList[x]['domainName']==this.InProgressDomainList[y]['domainName']){
          //       this.unverifiedDomainList.splice(x,x+1)
          //     }
          //   }
          // }
          // console.log(this.unverifiedDomainList);
          this.existingDomainCount = this.verifiedDomainList.length;
          this.pendingDomainCount = this.unverifiedDomainList.length;
          this.InprogressDomainCount=this.InProgressDomainList.length;
          this.allDomainApiloaded = true;
        }
      },
      (err) => {
        this.allDomainApiloaded = true;
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load domain list", ['POS_PAGE']), 200);
      }
    );
  }

  async progressStageChange(stage) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if (1 == stage) {
        this.progressStage = 1;
      }
      if (2 == stage) {
        this.addDomainForm.controls.domainName.setValue("");
        this.addDomainForm.controls.subdomainName.setValue("");
        const queryParams: Params = {
          layout: "domain",
        };
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: "merge", // remove to replace all query params by provided
        });
        this.progressStage = 2;
      } else if (3 == stage) {
        this.addDomain();
      }
    }
  }
  addDomain() {
    //  if( this.addDomainForm.controls.subdomainName.value.indexOf(this.addDomainForm.controls.domainName.value) !== -1){
    //   this.snackbar.openSnackBar("Sending Domain Name should not contain domain name",2000);
    //   return
    // }

    // console.log(this.addDomainForm.controls.subdomainName.value.split()[1])
    var subd = "";
    if (this.addDomainForm.controls.checked.value) {
      subd = this.addDomainForm.controls.domainName.value;
      this.subdomain=this.addDomainForm.controls.domainName.value;
    } else {
      // if(this.addDomainForm.controls.subdomainName.value.split(this.addDomainForm.controls.domainName.value)[0])
      subd =
        this.addDomainForm.controls.subdomainName.value +
        "." +
        this.addDomainForm.controls.domainName.value;
        this.subdomain= this.addDomainForm.controls.subdomainName.value +
        "." +
        this.addDomainForm.controls.domainName.value;
    }
    this.domain=this.addDomainForm.controls.domainName.value;
    // this.subdomain=this.addDomainForm.controls.subdomainName.value;
    var data = {
      merchantId: this.tokenStorage.getMerchantId(),
      domainName: this.addDomainForm.controls.domainName.value,

      subDomainName: subd,
      state: "unverified",
      isActive: false,
    };
    // console.log(this.addDomainForm);
    if (this.addDomainForm.controls.domainName.valid &&((this.addDomainForm.controls.subdomainName.valid && !this.addDomainForm.controls.checked.value) || this.addDomainForm.controls.checked.value)) {
      // console.log(this.addDomainForm);
      this.verifiyDomainLoaded = false;
      const dialogRef=this.dialog.open(DomainConfirmationDialogPopUpComponent,{
        panelClass:"no-padding-dialog-popup",
        width:"540px",
        height:"247px",
        data:{
          DomainName:this.domain
        }
      })
      dialogRef.afterClosed().subscribe((result)=>{
        if(result){
          this.addDomainLoaded = false;
          this.apiCall.addDomain(data).subscribe(
            (response: any) => {
              // var response = {
              //   message: "success",
              //   body:
              //     '{"receiving_dns_records":[{"valid":"unknown","cached":[],"priority":"10","value":"mxa.mailgun.org","record_type":"MX"},{"valid":"unknown","cached":[],"priority":"10","value":"mxb.mailgun.org","record_type":"MX"}],"domain":{"require_tls":false,"smtp_login":"postmaster@mail.kanestore16.com","web_prefix":"email","is_disabled":false,"spam_action":"disabled","created_at":"Wed, 03 Mar 2021 11:51:41 GMT","skip_verification":false,"type":"custom","wildcard":false,"smtp_password":"281d38d80869ad18a4f7c2d68c212e7c-e49cc42c-064be128","web_scheme":"http","name":"mail.kanestore16.com","id":"603f784db0e9f5e730790b49","state":"unverified"},"message":"Domain has been created","sending_dns_records":[{"valid":"unknown","cached":[],"name":"mail.kanestore16.com","value":"v=spf1 include:mailgun.org ~all","record_type":"TXT"},{"valid":"unknown","cached":[],"name":"smtp._domainkey.mail.kanestore16.com","value":"k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDjQG0IifBj3lXEeA/bil3cWb8hjFaIyM3d0YFw5Ym7Kz5DnKO8ZgzOVe59xUTMAAeDDn1vEdvYw2uz3+xg8Kc/+G9va7ou3eZEmU5+XbhfoZmYX7B3cNi+9U4yrEGswpWigUPCBOpeIVQ2SbpIoRnCsZQMHI7ADIbe+PWh5eCoSwIDAQAB","record_type":"TXT"},{"valid":"unknown","cached":[],"name":"email.mail.kanestore16.com","value":"mailgun.org","record_type":"CNAME"}]}',
              // };
              // console.log(JSON.parse(response["body"]));
              if (response["message"] == "Domain DNS records have been created") {
                this.progressStage = 3;
                this.addDomainForm.reset();
                const queryParams: Params = {
                  layout: "domain",
                  domain: this.addDomainForm.controls.domainName.value,
                  subdomain: subd,
                };
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: queryParams,
                  queryParamsHandling: "merge", // remove to replace all query params by provided
                });
                // console.log(JSON.parse(response["body"]));
    
                this.sendingDNSRecord = response.sending_dns_records;
                this.recievingDNSRecod = response.receiving_dns_records;
                // console.log(this.recievingDNSRecod)
                // console.log(subd)
                this.addDomainLoaded = true;
                this.verifiyDomainLoaded = true;
              }
            },
            (error) => {
              this.addDomainLoaded = true;
              const errorMessage = error.error.body;
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform(errorMessage, ['POS_PAGE']), 2000);
              console.log(error);
            }
          );
        }
        else{
          dialogRef.close();
        }
      })
      // this.apiCall.addDomain(data).subscribe(
      //   (response) => {
      //     // var response = {
      //     //   message: "success",
      //     //   body:
      //     //     '{"receiving_dns_records":[{"valid":"unknown","cached":[],"priority":"10","value":"mxa.mailgun.org","record_type":"MX"},{"valid":"unknown","cached":[],"priority":"10","value":"mxb.mailgun.org","record_type":"MX"}],"domain":{"require_tls":false,"smtp_login":"postmaster@mail.kanestore16.com","web_prefix":"email","is_disabled":false,"spam_action":"disabled","created_at":"Wed, 03 Mar 2021 11:51:41 GMT","skip_verification":false,"type":"custom","wildcard":false,"smtp_password":"281d38d80869ad18a4f7c2d68c212e7c-e49cc42c-064be128","web_scheme":"http","name":"mail.kanestore16.com","id":"603f784db0e9f5e730790b49","state":"unverified"},"message":"Domain has been created","sending_dns_records":[{"valid":"unknown","cached":[],"name":"mail.kanestore16.com","value":"v=spf1 include:mailgun.org ~all","record_type":"TXT"},{"valid":"unknown","cached":[],"name":"smtp._domainkey.mail.kanestore16.com","value":"k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDjQG0IifBj3lXEeA/bil3cWb8hjFaIyM3d0YFw5Ym7Kz5DnKO8ZgzOVe59xUTMAAeDDn1vEdvYw2uz3+xg8Kc/+G9va7ou3eZEmU5+XbhfoZmYX7B3cNi+9U4yrEGswpWigUPCBOpeIVQ2SbpIoRnCsZQMHI7ADIbe+PWh5eCoSwIDAQAB","record_type":"TXT"},{"valid":"unknown","cached":[],"name":"email.mail.kanestore16.com","value":"mailgun.org","record_type":"CNAME"}]}',
      //     // };
      //     // console.log(JSON.parse(response["body"]));
      //     if (response["message"] == "success") {
      //       this.progressStage = 3;
      //       this.addDomainForm.reset();
      //       const queryParams: Params = {
      //         layout: "domain",
      //         domain: this.addDomainForm.controls.domainName.value,
      //         subdomain: subd,
      //       };
      //       this.router.navigate([], {
      //         relativeTo: this.route,
      //         queryParams: queryParams,
      //         queryParamsHandling: "merge", // remove to replace all query params by provided
      //       });
      //       // console.log(JSON.parse(response["body"]));

      //       this.sendingDNSRecord = JSON.parse(response["body"])[
      //         "sending_dns_records"
      //       ];
      //       this.recievingDNSRecod = JSON.parse(response["body"])[
      //         "receiving_dns_records"
      //       ];
      //       // console.log(this.recievingDNSRecod)
      //       // console.log(subd)
      //       this.addDomainLoaded = true;
      //       this.verifiyDomainLoaded = true;
      //     }
      //   },
      //   (error) => {
      //     this.addDomainLoaded = true;
      //     this.snackbar.openSnackBar(error.error.body, 2000);
      //     console.log(error);
      //   }
      // );
    } else {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("All field should be valid", ['POS_PAGE']), 2000);
    }
  }

  verifyDomain() {
    var data = {
      merchantId: this.tokenStorage.getMerchantId(),
      domainName: this.domain,
      subDomainName: this.subdomain,
    };
    this.verifyApiloaded = false;
    this.apiCall.verifyDomain(data).subscribe(
      (response) => {
        console.log(response);
        if (response["message"] == "success") {
          this.verifyApiloaded = true;
          if (response["body"] == "true") {
            this.verifiedStatus = "true";
          } else if(response["body"]=="false") {
            this.verifiedStatus = "false";
            this.verifiedStatusText = "Not Verified";
            // this.InProgressDomainList.push(data);
            // for(const x in this.unverifiedDomainList){
            //   console.log(this.unverifiedDomainList[x]['domainName'])
            //   if(this.unverifiedDomainList[x]['domainName']==data['domainName']){
            //     this.unverifiedDomainList[x]['state']='InProgress';
            //   }
            // }
            console.log(this.InProgressDomainList)
            console.log(this.unverifiedDomainList)
          }
          else{
            this.verifiedStatus = "NotFound";
          }
          this.verifyButtonClicked = true;

        }
      },
      (error) => {
        this.verifyApiloaded = true;
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']), 2000);
      }
    );
  }

  deleteDomain(domName, subdomName) {
    var data = {
      merchantId: this.tokenStorage.getMerchantId(),
      domainName: domName,
      subDomainName: subdomName,
    };
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: "no-padding-dialog-popup",
            width: "300px",
      data: {
        header: this._i18nDynamicTranslate.transform("Delete Domain?", ['POS_PAGE']),
        body:
          this._i18nDynamicTranslate.transform("Do you wish to delete", ['POS_PAGE']) + " " +domName+ "?",
          domainName:domName
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
     if(result){
    this.deleteApiLoaded=false;
    this.apiCall.deleteDomain(data).subscribe(
      (response) => {
        // console.log(response);
        if (response["message"] == "success") {
          this.getAllDomains();
          this.indexU=null;
        this.indexV=null;
        this.indexProg=null;
        
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Deleted", ['POS_PAGE']), 2000);
          this.deleteApiLoaded=true;
        }
      });
    }
    else{
      this.indexU=null;
      this.indexV=null;
      this.indexProg=null;
    }
  })
}
  updateDomain(domName, subdomName) {
    var data = {
      merchantId: this.tokenStorage.getMerchantId(),
      domainName: domName,
      subDomainName: subdomName,
      isActive: true,
    };
    this.updateDomainApiLoaded = false;
    this.apiCall.updateDomainStatus(data).subscribe(
      (response) => {
        //this.verifiedStatus='true';
        this.updateDomainApiLoaded = true;
        // console.log(response);
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Updated successfully", ['POS_PAGE']), 2000);
        this.exitProgress();
        // this.getAllDomains();
        this.indexU = null;
        this.indexV = null;
        this.indexUAct = null;
        this.indexProg=null;
      },
      (error) => {
        this.indexU = null;
        this.indexV = null;
        this.indexUAct = null;
        this.indexProg=null;

        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']), 2000);
        this.updateDomainApiLoaded = true;
      }
    );
  }
  getDomainDetails() {
    var data = {
      merchantId: this.tokenStorage.getMerchantId(),
      domainName: this.addDomainForm.controls.domainName.value,
      subDomainName: this.addDomainForm.controls.subdomainName.value,
    };

    this.apiCall.getDomain(data).subscribe(
      (response) => {
        // console.log(response);
      },
      (error) => {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed", ['POS_PAGE']), 2000);

        console.log(error);
      }
    );
  }
  getDomainRecords() {
    var data = {
      merchantId: this.tokenStorage.getMerchantId(),
      domainName: this.addDomainForm.controls.domainName.value,
      subDomainName: this.addDomainForm.controls.subdomainName.value,
    };
    this.subdomain=this.addDomainForm.controls.subdomainName.value;
    this.domain=this.addDomainForm.controls.domainName.value;
    this.domainRecordsFound = true;
    this.verifiyDomainLoaded = false;
    this.valueDomainNameInfo = this.addDomainForm.controls.subdomainName.value;
    this.apiCall.getDomainRecords(data).subscribe(
      (response) => {
        if (response["message"] == "success") {
          if (response["body"] == '{"message":"Domain not found"}') {
            this.domainRecordsFound = false;
            this.verifiyDomainLoaded = true;
          } else {
            this.domainRecordsFound = true;

            this.progressStage = 3;
            const queryParams: Params = {
              layout: "domain",
              domain: this.addDomainForm.controls.domainName.value,
              subdomain: this.addDomainForm.controls.subdomainName.value,
            };
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: queryParams,
              queryParamsHandling: "merge", // remove to replace all query params by provided
            });
            // console.log(JSON.parse(response["body"]));

            this.sendingDNSRecord = JSON.parse(response["body"])[
              "sending_dns_records"
            ];
            this.recievingDNSRecod = JSON.parse(response["body"])[
              "receiving_dns_records"
            ];
            this.addDomainLoaded = true;
            this.verifiyDomainLoaded = true;
          }
        }
      },
      (error) => {
        this.verifiyDomainLoaded = true;
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load", ['POS_PAGE']), 2000);
        this.verifiyDomainLoaded = true;
        console.log(error);
      }
    );
  }

  copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Text Copied!", ['POS_PAGE']) , 1500);
  }

  async continueVerification(domainNam, subdomainNam) {
    // const queryParams: Params = {
    //   layout: "domain",
    //   domain: domainNam,
    //   subdomain: subdomainNam,
    //   preserve:true
    // };
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: queryParams,
    //   queryParamsHandling: "merge", // remove to replace all query params by provided
    // });
    if(await this._SubscriptionPlanHandlerService.IsValidOperation(this.appSubscriptionPlan)){
      this.addDomainForm.controls.domainName.setValue(domainNam);
      this.addDomainForm.controls.subdomainName.setValue(subdomainNam);
      this.progressStage = 3;
      this.getDomainRecords();
    }
  }

  exitProgress() {
    this.addDomainForm.controls.domainName.setValue("");
    this.addDomainForm.controls.subdomainName.setValue("");
    this.progressStage = 1;
    this.verifyButtonClicked=false;
    this.getAllDomains();
  }

  showcheckboxOptions(event) {
    // console.log(event.checked);
    this.addDomainForm.controls.subdomainName.setValue("");
    this.addDomainForm.controls.checked.setValue(event.checked);
    if (event.checked) {
      this.addDomainForm.controls.subdomainName.setValue(
        this.addDomainForm.controls.domainName.value
      );
    }
  }
}
