import {
  Component,
  OnInit,
  Inject,
  HostListener,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import Bee from "@mailupinc/bee-plugin";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import {
  SendTestEmailDialogComponent,
  ContactUsDialogComponent,
  BeeEditorProductImageConfigDialogComponent,
  OfferSummaryDialogComponent,
} from "src/app/shared/components/dialog-box";
import { template1 } from "src/app/shared/json-asset";
import { chefDrivenTemplate1 } from "src/app/shared/json-asset/chef-driven-templates";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-offer-attach-email-dialog",
  templateUrl: "./offer-attach-email-dialog.component.html",
  styleUrls: ["./offer-attach-email-dialog.component.css"],
})
export class OfferAttachEmailDialogComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }

  scrHeight;
  finalHeight;

  submitClicked = false;

  // payload = {
  //   client_id: "55d274b7-252b-45ed-bff0-a2b81c79627c",
  //   client_secret: "b3uBjnItmw2cgzN8oRrPcEwvN6vg3DjSelVRBgHKrn3YlqBg6RJc",
  //   grant_type: "password",
  // };
  payload = {
    client_id: environment.beepluginClientId,
    client_secret: environment.beepluginClientSecretId,
    grant_type: "password",
  };

  beeTest;
  beeToken;
  beeConfig;
  specialLinks = [
    {
      type: "Unsubscribe",
      label: "Unsubscribe",
      link: "%unsubscribe_url%",
    },
  ];
  mergeTags = [];
  selectedTemplate = "T1";
  finalContent;
  templateName;
  templateForm: FormGroup;
  subjectError = false;
  nameError = false;
  submitClickedEmail = false;
  responseMsg: string = null;
  data = {
    process: "create",
    id: "null",
    tags: [],
    imageTagStatus: true,
    offerId: null,
    saveName: null,
    tableType: null,
  };
  emailtemplatedselected: boolean = false;
  loading: boolean = false;
  snakbardispalay;
  type = this.dataLoad.type;
  sendTestEmail;
  mergeTagsWithoutImage = [];
  mergeTagsWithImage = [];
  templateTypeOnSave;
  recActionMergeTags = [
    {
      name: "Shop Name",
      value: "{{shopName}}",
    },
    {
      name: "Merchant Name",
      value: "{{merchantName}}",
    },
    {
      name: "Customer Name",
      value: "{{customerName}}",
    },
    {
      name: "Shop URL",
      value: "{{shopUrl}}",
    },
  ];

  margetagsurvey = [
    {
      name: "Shop Name",
      value: "%shopName%",
    },
    {
      name: "Customer Name",
      value: "%CustomerName%",
    },
    {
      name: "Shop URL",
      value: "%shopUrl%",
    },
    {
      name: "Shopify Page Links",
      value: "%SHOPIFY_PAGE_LINK%",
    },
  ];
  toggled = false;
  searchEmoji = "";
  emojiTheme = {
    martShowHeader: true,
    martShowFooter: false,
    martHeaderPadding: { x: "0px", y: "0px" },
    martFooterPadding: { x: "0px", y: "0px" },
    martHeaderBG: "#e3e7e8",
    martFooterBG: "#e3e7e8",
    martBG: "#ebeff2",
    martCategoryColor: "#94a0a6",
    martCategoryColorActive: "#455a64",
    martActiveCategoryIndicatorColor: "#00897b",
    martEmojiFontSize: "110%",
    martCategoryFontSize: "12px",
    martBorderRadius: "14px",
    martActiveCategoryIndicatorHeight: "1px",
    martEmojiPadding: { x: "40px", y: "40px" },
  };
  template1;
  isTemplateNameEdit: boolean = false;

  constructor(
    public dialogRefer: MatDialogRef<OfferAttachEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataLoad,
    private apiCall: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private ngZone: NgZone
  ) {
    this.getScreenSize();
  }

  ngOnInit() {
    this.getSampleTemplates();
    this.templateForm = new FormGroup({
      TemplateName: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      subject: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });
    this.data.process = this.dataLoad["process"];
    this.data.id = this.dataLoad["id"];
    this.data.tags = this.dataLoad["tags"];
    this.data.offerId = this.dataLoad["offerId"];
    this.data.tableType = this.dataLoad["tableType"];
    this.templateForm.get("subject").setValue(this.dataLoad.subject);
    var i;

    //To seperate image merge tags
    for (i = 0; i < this.dataLoad["tags"].length; i++) {
      if (this.dataLoad["tags"][i]["name"].indexOf("Image URL") == -1) {
        this.mergeTagsWithoutImage.push(this.dataLoad["tags"][i]);
      } else {
        if (this.dataLoad["imageTagStatus"]) {
          //To show merge tags for BUYXGETY type and remove entitled tag in that case(In reward template section)
          if (this.dataLoad["specialTagType"] == "BUYXGETY") {
            this.dataLoad["tags"][i]["name"].indexOf("Entitled") == -1
              ? this.mergeTagsWithImage.push(this.dataLoad["tags"][i])
              : null;
          } else {
            this.mergeTagsWithImage.push(this.dataLoad["tags"][i]);
          }
        }
      }
    }

    // this.finalHeight = this.scrHeight;
    this.beeTest = new Bee(this.beeToken);
    this.beeConfig = {
      uid: "user-id-" + this.tokenStorage.getMerchantId(),
      container: "bee-plugin-container",
      language: "en-US",
      mergeTags: this.mergeTagsWithoutImage,
      onSave: (jsonFile, htmlFile) => {
        // this.snackbbb();
        this.snakbardispalay = this.snackbar.open("Saving... Please wait");
        this.saveAsHtml("onSave", jsonFile, htmlFile);
      },
      onSend: (htmlFile) => {
        this.apiCall
          .sendTestEngageEmailHTML(
            this.dataLoad.type.toUpperCase(),
            "EMAIL",
            this.dataLoad.offerId,
            this.sendTestEmail,
            null,
            this.dataLoad.isReward,
            this.dataLoad.rewardType,
            htmlFile
          )
          .subscribe(
            (response) => {
              this.snackBar.openSnackBar("Test email sent", 2000);
            },
            (err) => {
              this.snackBar.openSnackBar("Failed to send test email", 2000);
            }
          );
      },
      onChange: (jsonFile, response) => {
        if (this.mergeTagsWithImage.length > 0) {
          if (
            response["code"] == "0263" &&
            response["description"] == "Change image"
          ) {
            // var tex=jsonFile.replace('alternate text','{{barrier[0]image}}')
            // jsonFile=tex;

            if (
              response["value"] ==
              "https://" +
                environment.beeEditorS3BucketName +
                ".s3.amazonaws.com/personalized-images/sample%20image%20for%20personalized%20offer.jpg"
            ) {
              this.mergetagPersonalizedImage();
            }
          }
        }
      },
    };
    this.beeConfig.specialLinks = this.specialLinks;
    if (this.data["process"] === "create") {
      // this.loading=true;
      this.beeTest
        .getToken(this.payload.client_id, this.payload.client_secret)
        .then(() => this.beeTest.start(this.beeConfig, this.template1));
      // this.loading=false;
    }
    if (
      this.data["process"] === "NewTemplateAndLayout" &&
      this.dataLoad.type !== "FORM"
    ) {
      // this.loading=true;
      this.beeTest
        .getToken(this.payload.client_id, this.payload.client_secret)
        .then(() => this.beeTest.start(this.beeConfig, this.template1));
      // this.loading=false;
    } else {
      if (this.dataLoad.emailLayoutJSON) {
        this.beeTest
          .getToken(this.payload.client_id, this.payload.client_secret)
          .then(() =>
            this.beeTest.start(
              this.beeConfig,
              JSON.parse(this.dataLoad.emailLayoutJSON)
            )
          );
      }
    }

    if (this.data["process"] === "update") {
      // this.loading=true;
      this.apiCall.getEmailLayoutDetail(this.data["id"]).subscribe(
        (response) => {
          if (response["message"] === "success") {
            let layout = JSON.parse(response["body"]);
            this.templateForm.get("TemplateName").setValue(layout["name"]);
            this.templateName = layout["name"];
            let editTemplate = JSON.parse(layout["layoutJson"]);
            this.beeTest
              .getToken(this.payload.client_id, this.payload.client_secret)
              .then(() => this.beeTest.start(this.beeConfig, editTemplate));
            // this.loading=false;
          }
        },
        (err) => {}
      );
    }
    if (this.data["process"] === "surveyEmail") {
      // this.loading=true;
      this.apiCall.getEmailLayoutDetail(this.data["id"]).subscribe(
        (response) => {
          if (response["message"] === "success") {
            let layout = JSON.parse(response["body"]);
            this.templateForm.get("TemplateName").setValue(layout["name"]);
            this.templateName = layout["name"];
            let editTemplate = JSON.parse(layout["layoutJson"]);
            this.beeConfig.mergeTags = this.margetagsurvey;
            this.beeTest
              .getToken(this.payload.client_id, this.payload.client_secret)
              .then(() => this.beeTest.start(this.beeConfig, editTemplate));
            // this.loading=false;
          }
        },
        (err) => {}
      );
    }
    if (
      this.data["process"] === "AddTextBody" ||
      this.data["process"] === "AddTextBodyLayoutOnly"
    ) {
      // this.loading=true;
      this.apiCall.getEmailLayoutDetail(this.data["id"]).subscribe(
        (response) => {
          if (response["message"] === "success") {
            let layout = JSON.parse(response["body"]);
            let editTemplate = JSON.parse(layout["layoutJson"]);
            //this.beeConfig.mergeTags = this.data["tags"];
            this.beeTest
              .getToken(this.payload.client_id, this.payload.client_secret)
              .then(() => this.beeTest.start(this.beeConfig, editTemplate));
            // this.loading=false;
          }
        },
        (err) => {}
      );
    }
    if (
      this.data["process"] === "EditTextBody" ||
      this.data["process"] === "EditTextBodySurvey"
    ) {
      // this.loading=true;
      this.apiCall.getEmailContent(this.data["id"]).subscribe(
        (response) => {
          if (response["message"] === "success") {
            let layout = JSON.parse(response["body"]);
            this.templateForm.get("subject")?.setValue(layout["subject"]);
            let editTemplate = JSON.parse(layout["layoutJson"]);
            //this.beeConfig.mergeTags = this.data["tags"]
            if (this.data["process"] === "EditTextBodySurvey") {
              this.beeConfig.mergeTags = this.margetagsurvey;
            }
            this.beeTest
              .getToken(this.payload.client_id, this.payload.client_secret)
              .then(() => this.beeTest.start(this.beeConfig, editTemplate));
            // this.loading=false;
          }
        },
        (err) => {}
      );
    }
    if (this.data["process"] === "createRewardTemplateLayout") {
      // this.loading=true;
      if (this.data["tableType"] == "REWARD") {
        this.apiCall.getRewardEmailDetail(this.data["id"]).subscribe(
          (response) => {
            if (response["message"] === "success") {
              let layout = JSON.parse(response["body"]);
              let editTemplate = JSON.parse(layout["layoutJson"]);
              this.templateTypeOnSave = layout["type"];
              this.templateForm
                .get("TemplateName")
                .setValue(this.addEditedSuffix(layout["name"]));
              this.templateName = this.addEditedSuffix(layout["name"]);
              this.templateForm
                .get("subject")
                .setValue(
                  layout["subject"] ? layout["subject"] : "Offer Email"
                );
              this.beeTest
                .getToken(this.payload.client_id, this.payload.client_secret)
                .then(() => this.beeTest.start(this.beeConfig, editTemplate));
            }
          },
          (err) => {}
        );
      } else {
        this.apiCall.getEmailLayoutDetail(this.data.id).subscribe(
          (response) => {
            if (response["message"] === "success") {
              let layout = JSON.parse(response["body"]);
              let editTemplate = JSON.parse(layout["layoutJson"]);
              this.templateTypeOnSave = layout["type"];
              this.templateForm
                .get("TemplateName")
                .setValue(this.addEditedSuffix(layout["name"]));
              this.templateName = this.addEditedSuffix(layout["name"]);
              this.templateForm
                .get("subject")
                .setValue(
                  layout["subject"] ? layout["subject"] : "Offer Email"
                );
              this.beeTest
                .getToken(this.payload.client_id, this.payload.client_secret)
                .then(() => this.beeTest.start(this.beeConfig, editTemplate));
            }
          },
          (err) => {}
        );
      }
    }
  }

  addEditedSuffix(name: string): string {
    const maxLength = 50;
    const suffix = "-Edited";
    const maxBaseNameLength = maxLength - suffix.length;
    if (name.length <= maxBaseNameLength) {
      return name + suffix;
    }
    const trimmedBaseName = name.slice(0, maxBaseNameLength).trim();
    return trimmedBaseName + suffix;
  }

  openSendTestEmailDialog() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
        this.sendTestEmail = response;
        this.snackBar.openSnackBar("Sending test email", 2000);
        this.beeTest.send();
      }
    });
  }

  saveAsHtml(type, json, html) {
    // this.loading = true;
    this.submitClickedEmail = true;
    let mId = this.tokenStorage.getMerchantId();

    if (this.data.process === "create") {
      if (this.templateForm.controls.TemplateName.errors) {
        this.snakbardispalay.dismiss();
        return 0;
      }

      this.finalContent = {
        merchantId: mId,
        name: this.templateForm.get("TemplateName").value,
        layoutHtml: html,
        layoutJson: json,
        type: "GENERIC",
      };
      //  let snackBarRef = this.snackbar.open("Saving... Please wait");
      this.apiCall.createEmailLayout(this.finalContent).subscribe(
        (response) => {
          this.snakbardispalay.dismiss();
          if (response["message"] === "success") {
            var idnumber = response["body"];
            // this.setResponse("null", "success");
            // this.dialogRefer.close(idnumber);
            if (idnumber) {
              this.closeEditor(idnumber);
              return;
            }
          } else {
            // this.setResponse("Failed to save", "fail");
          }
        },
        (err) => {
          this.snakbardispalay.dismiss();
          const dialogRef = this.dialog.open(ContactUsDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            height: "auto",
            disableClose: true,
            width: "380px",
            data: {
              header: "Warning",
              body: err.error["body"],
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            return html;
            // this.disabled = false;
          });
        }
      );
    }

    if (this.data.process === "update") {
      if (this.templateForm.controls.TemplateName.errors) {
        //this.nameError = true;
        this.snakbardispalay.dismiss();
        return html;
      }
      this.finalContent = {
        id: this.data["id"],
        merchantId: mId,
        name: this.templateForm.get("TemplateName").value,
        layoutHtml: html,
        layoutJson: json,
        type: "GENERIC",
      };
      // this.snakbardispalay = this.snackbar.open("Saving... Please wait");

      this.apiCall.updateEmailLayout(this.finalContent).subscribe(
        (response) => {
          this.snakbardispalay.dismiss();
          if (response["message"] === "success") {
            // this.setResponse(null, "success");
            var idnumber = this.data["id"];

            if (idnumber) {
              // this.router.navigate([
              //   "/pos-management/layout/listing",
              //   { id: idnumber },
              // ]);
              this.closeEditor(idnumber);
            }
          } else {
            // this.setResponse("Failed to save", "fail");
          }
        },
        (err) => {
          // this.setResponse("Failed to save", "fail");
        }
      );
    }

    // if (this.data.process === "EditTextBody") {
    //   if (this.templateForm.controls.subject.errors) {
    //     return 0;
    //   }
    //   let data = {
    //     merchantId: this.tokenStorage.getMerchantId(),
    //     subject: this.templateForm.get("subject").value,
    //     layoutHtml: html,
    //     layoutJson: json,
    //     id: this.data.id,
    //   };
    //   this.apiCall.updateEmailContent(data).subscribe((response) => {
    //     if (response["message"] === "success") {
    //       let id = JSON.parse(response["body"]);
    //       // this.setResponse(id, "success");
    //     } else {
    //       // this.setResponse("Failed to save", "fail");
    //     }
    //   });
    // }

    if (
      this.data.process === "AddTextBody" ||
      this.data.process === "surveyEmail" ||
      this.data.process === "AddTextBodyLayoutOnly"
    ) {
      if (this.data.process === "surveyEmail") {
        this.templateForm.get("subject").setValue("survey email");
      }
      if (
        this.templateForm.controls.subject.errors &&
        this.data.process != "surveyEmail"
      ) {
        // this.snackBar.openSnackBar("Check subject field", 2000);
        this.snakbardispalay.dismiss();
        this.loading = false;
        return html;
      }

      let dataLayoutAndTemporaryTemplateName =
        this.templateForm.get("TemplateName").value;

      let data = {
        merchantId: this.tokenStorage.getMerchantId(),
        subject: this.templateForm.get("subject").value,
        layoutHtml: html,
        layoutJson: json,
        id: null,
        type: this.data.process === "surveyEmail" ? "SURVEY" : "OFFER",
      };
      this.loading = true;
      this.apiCall.addEmailContent(data).subscribe((response) => {
        if (response["message"] === "success") {
          let id = JSON.parse(response["body"]);
          this.snakbardispalay.dismiss();
          // this.setResponse(id, "success");
          // this.loading = false;
          var idnumber = id;

          if (idnumber) {
            // this.router.navigate([
            //   "/pos-management/layout/listing",
            //   { id: idnumber },
            // ]);
            this.closeEditor(idnumber);
          }
        } else {
          // this.setResponse("Failed to save", "fail");
        }
      });
    }

    if (this.data.process === "NewTemplateAndLayout") {
      if (this.templateForm.controls.subject.errors) {
        // this.snackBar.openSnackBar("Check subject field", 2000);
        this.snakbardispalay.dismiss();
        this.loading = false;
        return html;
      }
      this.finalContent = {
        merchantId: mId,
        name: this.templateForm.get("TemplateName").value,
        layoutHtml: html,
        layoutJson: json,
      };
      let data = {
        merchantId: this.tokenStorage.getMerchantId(),
        subject: this.templateForm.get("subject").value,
        layoutHtml: html,
        layoutJson: json,
        id: null,
        type: "OFFERS",
      };
      //  let snackBarRef = this.snackbar.open("Saving... Please wait");

      // this.apiCall.createEmailLayout(this.finalContent).subscribe(
      //   (response) => {

      // if (response["message"] === "success") {
      //   this.loading=true;
      //   var idnumberTemplate = response["body"];
      this.apiCall.addEmailContent(data).subscribe(
        (response) => {
          if (response["message"] === "success") {
            // snackBarRef.dismiss();
            this.snakbardispalay.dismiss();
            let id = JSON.parse(response["body"]);
            this.loading = true;
            var idnumber = id;
            if (idnumber) {
              this.closeEditor(idnumber);
            }
          } else {
            // snackBarRef.dismiss();
            return;
            // this.setResponse("Failed to save", "fail");
          }
        },
        (err) => {
          this.snakbardispalay.dismiss();
          //   snackBarRef.dismiss();
        }
      );
      // if (idnumber) {
      //    this.closeEditor(idnumber);
      //   return;

      // }

      //  else {
      //   // this.setResponse("Failed to save", "fail");
      // }

      // (err) => {

      //   // this.loading=false;

      //   if (err.error["body"]) {
      //    // snackBarRef.dismiss();
      //    this.snakbardispalay.dismiss();
      //     this.openduplicateDialog(err.error["body"],html,json)
      //   }
      // }
      // );
    }

    if (
      this.data.process === "EditTextBody" ||
      this.data["process"] === "EditTextBodySurvey"
    ) {
      if (this.data.process === "EditTextBodySurvey") {
        this.templateForm.get("subject").setValue("");
      }
      if (
        this.templateForm.controls.subject.errors &&
        this.data["process"] != "EditTextBodySurvey"
      ) {
        this.snakbardispalay.dismiss();
        return 0;
      }
      let data = {
        merchantId: this.tokenStorage.getMerchantId(),
        subject: this.templateForm.get("subject").value,
        layoutHtml: html,
        layoutJson: json,
        id: this.data.id,
        type:
          this.data["process"] === "EditTextBodySurvey" ? "SURVEY" : "OFFERS",
      };
      this.loading = true;
      this.apiCall.updateEmailContent(data).subscribe((response) => {
        if (response["message"] === "success") {
          this.snakbardispalay.dismiss();
          let id = JSON.parse(response["body"]);
          // this.setResponse(id, "success");
          this.closeEditor(id);
          // this.loading=false;
        } else {
          this.snackBar.openSnackBar("Failed to save", 2000);
        }
      });
    }

    if (this.data.process === "createRewardTemplateLayout") {
      if (
        this.templateForm.controls.subject.errors ||
        this.templateForm.controls.TemplateName.errors
      ) {
        this.snakbardispalay.dismiss();
        this.loading = false;
        return html;
      }
      let data = {
        merchantId: this.tokenStorage.getMerchantId(),
        subject: this.templateForm.get("subject").value,
        layoutHtml: html,
        layoutJson: json,
        id: null,
        type: this.templateTypeOnSave,
        name: this.templateForm.get("TemplateName").value,
      };
      this.loading = true;
      this.apiCall.createEmailLayout(data).subscribe(
        (response) => {
          // snackBarRef.dismiss();
          // this.returnToPreviousScreen(response["body"], "CUSTOM");
          if (response["message"] === "success") {
            let id = JSON.parse(response["body"]);
            this.snakbardispalay.dismiss();
            // this.setResponse(id, "success");
            // this.loading = false;
            var idnumber = id;

            if (idnumber) {
              // this.router.navigate([
              //   "/pos-management/layout/listing",
              //   { id: idnumber },
              // ]);
              this.closeEditor(
                idnumber,
                this.data.tableType == "REWARD" ? "REWARD" : "CAMPAIGN"
              );
            }
          } else {
            this.snackBar.openSnackBar("Failed to save", 2000);
          }
        },
        (err) => {
          this.snackBar.openSnackBar(err.error.body, 2000);
        }
      );
    }

    //this.closeEditor();

    return;
  }
  closeEditor(idnumber, tableType?) {
    var result = { layoutId: idnumber, tableType: tableType };
    this.loading = true;
    if (idnumber == undefined) {
      this.ngZone.run(() => {
        this.dialogRefer.close(0);
      });
    } else {
      this.ngZone.run(() => {
        this.dialogRefer.close(result);
      });
    }
  }
  closeEditorButton() {
    this.dialogRefer.close(0);
  }

  openduplicateDialog(message, html, json) {
    const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      disableClose: true,
      width: "380px",
      data: {
        header: "Error",
        body: "Duplicate email template name",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
      //  this.beeTest.start(this.beeConfig, json)
      return html;
      // this.disabled = false;
    });
  }
  mergetagPersonalizedImage() {
    var sec;
    const dialogRef = this.dialog.open(
      BeeEditorProductImageConfigDialogComponent,
      {
        height: "70vh",
        disableClose: true,
        width: "80vw",
        data: this.mergeTagsWithImage,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      sec = result;
      var x = document.getElementsByClassName("example");
      // var y=x.getElementByTagName("input")
      //  x.nodeValue="asdas";

      // this.disabled = false;
    });
    return sec;
  }

  snackbbb() {
    this.snakbardispalay = this.snackbar.open("Saving... Please wait");
  }

  openViewOfferSummary() {
    const dialogRefer = this.dialog.open(OfferSummaryDialogComponent, {
      panelClass: "customAttachEmail",
      width: "40%",
      maxWidth: "546px",
      data: this.data["offerId"],
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  handleEmoji(e) {
    let inputField: any = document.querySelector("#emailSubjectInputField");
    const caretPosition = inputField.selectionStart;
    let text = "";
    text = this.templateForm.get("subject").value;
    let updatedContent = `${text.slice(0, caretPosition)}${e.char}${text.slice(
      caretPosition
    )}`;
    this.templateForm.get("subject").setValue(updatedContent);
    console.log("Emoji Name", e.name);
  }
  @HostListener("document:click", ["$event"])
  clickout3(event3) {
    // if (document.getElementById("helpProfile").contains(event3.target)) {
    //   this.displayHelpPopover();
    // } else {
    //   this.closeHelpPopover();
    // }
    if (document.getElementById("emojiContainer")?.contains(event3.target)) {
    } else {
      this.toggled = false;
    }
  }

  getSampleTemplates() {
    if (this.tokenStorage.getMerchantOnlineStore() === "restomail") {
      this.template1 = chefDrivenTemplate1;
    } else {
      this.template1 = template1;
    }
  }

  save() {
    this.beeTest.save();
  }

  nameChange(type) {
    if (type) {
      if (this.templateForm.controls.TemplateName.errors) {
        return;
      }
      this.templateName = this.templateForm.get("TemplateName").value;
      this.isTemplateNameEdit = false;
      this.save();
    } else {
      this.templateForm.get("TemplateName").setValue(this.templateName);
      this.isTemplateNameEdit = false;
    }
  }

  previewEmailTemplate() {
    this.beeTest.preview();
  }
}
