import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBarComponent } from 'src/app/shared/components/mat-snack-bar/mat-snack-bar.component';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-session-out',
  templateUrl: './session-out.component.html',
  styleUrls: ['./session-out.component.css']
})
export class SessionOutComponent implements OnInit {



  s3BucketUrl=environment.s3BucketUrl;
  imgUrl=this.s3BucketUrl +"/greyfox-assets/assets2/icons/GreyFox-Loading.gif";
  constructor( private snackBar: MatSnackBarComponent, private dialog: MatDialog,
    private router: Router, private _i18nDynamicTranslate: TranslateDynamicText) { }

  ngOnInit() {
    let snackBarRef = this.snackBar.openSnackBar(
       this._i18nDynamicTranslate.transform("Session expired ... Please login again!", ['STATIC_PAGE']),
      "Close",
      "red-snackbar"
    );
    this.dialog.closeAll();

    snackBarRef.afterDismissed().subscribe(() => {
      this.router.navigate(["/auth/login"], {});
      //   window.location.reload();
    });
  }
}
