import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SignoutService } from 'src/app/core/services/signout.service';

@Component({
  selector: 'app-data-ingestion-check',
  templateUrl: './data-ingestion-check.component.html',
  styleUrls: ['./data-ingestion-check.component.css']
})
export class DataIngestionCheckComponent implements OnInit {
  scrHeight;
  scrContentHeight;
  scrWidth;
  apiloading = false;
  data = [];
  userName = "";
  private roles: string[];
  constructor(
    private router: Router,
    private apiCall: ApiCallService,
    private _signoutService: SignoutService,
    private tokenStorage: TokenStorageService
  ) { 
    this.getScreenSize();
  }
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrContentHeight = window.innerHeight - 60 - 90;
    this.scrWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.userName = this.tokenStorage.getUsername();
    this.roles = this.tokenStorage.getAuthorities();
    this.getCurrentPlanPOS();
  }
  getCurrentPlanPOS(){
    this.apiloading = true;
    this.apiCall.getCurrentPlanPos().subscribe((response)=>{
      this.data = response['body'];
      this.redirectTo(this.data['status'])
    },(err)=>{
      console.log("Hello", err)
      if(err['error']['message'] === 'FAILURE'){
        this.router.navigate(['payment-pos']);
      }
    })
    // this.data = [
    //   {
    //     "subscriptionMasterPlanId": 4,
    //     "subscriptionSubPlanId": 7,
    //     "subscriptionPlanId": 255,
    //     "planPlatForm": "shopify",
    //     "merchantId": 10188,
    //     "startDate": [
    //       2022,
    //       4,
    //       27
    //     ],
    //     "endDate": [
    //       2022,
    //       12,
    //       10
    //     ],
    //     "status": "INACTIVE",
    //     "orderId": 274,
    //     "userEmail": "chinmays+dev@skellam.ai",
    //     "planType": "MONTHLY_SUBSCRIPTION",
    //     "planName": "Growth",
    //     "emailCreditsAvailable": 35000.0,
    //     "totalEmails": 35000.0,
    //     "smsCreditsAvailable": 1000.0,
    //     "totalSMS": 1000.0,
    //     "customerAvailable": null,
    //     "isEmailVerified": true,
    //     "isCustomerThresholdReached": false,
    //     "isCancelled": false,
    //     "nextRecommendedSubPlanId": null,
    //     "nextRecommendedMasterPlanId": null,
    //     "platform": "shopify"
    //   }
    // ]
    // this.apiloading = false;
    // this.redirectTo(this.data[0]['status'])
  }
  redirectTo(value){
    console.log(value)
    switch(value){
      case "ACTIVE":
        if(this.roles?.indexOf('ROLE_REPORTING_VIEW') !== -1){
          this.router.navigate(['/app/all-report']);
        }
        else{
          this.router.navigate(["/app/app-home"], {
            queryParams: { type: "Objectives" },
          });
        }
        break;
      case "INACTIVE":
        this.router.navigate(['payment-pos']);
        break;
      case "INPROCESS":
        this.router.navigate(['store-inprogress']);
        break;
      case "FRESH":
        this.router.navigate(['payment-pos']);
        break;
      default:
        this.router.navigate(['payment-pos']);
    }
  }
  
  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.router.navigate(["auth/login"], {});
  }

}
