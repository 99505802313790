import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { ContactUsDialogComponent } from "../contact-us-dialog/contact-us-dialog.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-partnership-initiate-dialog-box",
  templateUrl: "./partnership-initiate-dialog-box.component.html",
  styleUrls: ["./partnership-initiate-dialog-box.component.css"],
})
export class PartnershipInitiateDialogBoxComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private router: Router,
    private dialogRef: MatDialogRef<PartnershipInitiateDialogBoxComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  RewardList = [];
  length;
  rewardName = "";
  rewardValue = "";
  rewardId;
  ngOnInit() {
    this.getRewardList();
  }
  continue(decision) {
    this.dialogRef.close(decision);
  }
  submit(decision) {
    this.dialogRef.close(decision);
  }
  getRewardList() {
    this.apiCall.getPartnershipRewardList().subscribe((response) => {
      this.RewardList = JSON.parse(response["body"]);
      this.length = this.RewardList.length;
      console.log(this.RewardList);
    });
  }
  Send() {
    if (this.rewardValue !== null && this.rewardValue.length < 1) {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please select a reward", ['SHARED_PAGE']), 2000);
    } else {
      this.apiCall
        .CreatePartnerShip(this.data["targetId"], this.rewardId)
        .subscribe(
          (response) => {
            console.log(this.rewardValue);
            if (response["message"] == "success") {
              this.dialogRef.close(null);
              // this.snackBar.openSnackBar("Partnership request sent", 2000);
              // this.router.navigate(["/app/partnership-requests"]);
            }
          },
          (error) => {
            if (error["error"]["message"] == "failure") {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "380px",
                height: "auto",
                disableClose: true,
                data: {
                  header: this._i18nDynamicTranslate.transform("Failure", ['SHARED_PAGE']),
                  body: this._i18nDynamicTranslate.transform(error["error"]["body"], ['SHARED_PAGE']),
                },
              });
              this.dialogRef.close();
            }
          }
        );
    }
  }
  selectList(name, value, id) {
    this.rewardName = name;
    this.rewardValue = value;
    this.rewardId = id;
  }
}
