<div *ngIf="totalValue==0" style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          margin-top: 50px;">

    <div style="padding: 10px;
            border: 1px dotted #ff3b30;
            width: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;">
        {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
    </div>
</div>
<div class="simple-bar-chart-container" style="width: 100%; height: 100%" #chartContainer id="Test"></div>

