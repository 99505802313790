<div class="font" data-widget-name="OFFER LISTING">
  <div>
    <div class="header">
      <span style="text-align: center">{{'SHARED_PAGE.SELECT_OFFER_TYPE' | i18nTranslate}}</span>
    </div>
  </div>
  <div style="width: 100%;display: flex;padding: 15px;">

    <div class="Rectangle-offertype" [ngClass]="{active:selectedTab==1}" (click)="clickTab(1)" style="margin-right: 15px;">
      <div class="offertitle">{{'SHARED_PAGE.SLECT_OFFER_TYPE_HEADING' | i18nTranslate}}</div>
      <div class="offerDescription">{{'SHARED_PAGE.SELECT_OFFER_TYPE_PARAGRAPH' | i18nTranslate}}</div>
    </div>

    <div class="Rectangle-offertype" [ngClass]="{active:selectedTab==2}" (click)="clickTab(2)">
      <div class="offertitle">{{'SHARED_PAGE.DIRECT_REWARD_TYPE_OFFER' | i18nTranslate}}</div>
      <div class="offerDescription">{{'SHARED_PAGE.DIRECT_REWARD_TYPE_OFFER_PARAGRAPH' | i18nTranslate}}</div>
    </div>


  </div>
<div class="seperator1"></div>

  <div style="
      display: flex;
      justify-content: space-around;
      padding: 15px 20px 15px 20px;
    ">
    <span class="dismiss-button" (click)="submit(false)" data-button-name="Cancel: Create Offer">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="primary-button" (click)="submit(true)" data-button-name="Create Offer Type" [attr.data-item-name]="selectedTab===1?'Overcome challenge and unlock reward type offer':'Direct reward type offer'">{{'SHARED_PAGE.CREATE' | i18nTranslate}}</span>
  </div>
</div>