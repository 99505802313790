import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import * as d3 from 'd3';
declare var venn: any;

@Component({
  selector: 'app-venn-diagram',
  templateUrl: './venn-diagram.component.html',
  styleUrls: ['./venn-diagram.component.css']
})
export class VennDiagramComponent implements OnInit, OnChanges {

  @Input() pdata;
  @Input() pid;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  chartContainerElement;


  constructor() { }

  ngOnInit() {
    // this.chartContainerElement = this.chartContainer.nativeElement;
    this.renderGraph()
  }

  ngOnChanges() {
    this.chartContainerElement = this.chartContainer.nativeElement;
  }

  renderGraph() {
    let vennDiagramData
    var sets = [{ sets: ['A'], label:"Total revenue", size: 100 },
    { sets: ['B'], label:"offer A", size: 10 },
    { sets: ['C'], label:"offer B", size: 15 },
    { sets: ['D'], label:"offer C", size: 20 },
    { sets: ['A', 'B'], size: 10 },
    { sets: ['A', 'C'], size: 15 },
    { sets: ['A', 'D'], size: 20 }];
    let interval = setInterval(() => {
      let element = d3.select('#venn');
      if (element['_groups'][0] === null) {
      }
      else {
        try {
          let chart = venn.VennDiagram().width(500).height(400);
          d3.select('#venn').datum(sets).call(chart);
        } catch (e) {
          d3.selectAll('.venn-area text.label')
            .html((d) => {
              let text = d.sets.length === 1 ? d.label + ' : ' + d.size : '';
              return text;
            })
        }
        clearInterval(interval);
      }
    }, 200);
  }
}
