import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-generate-sms',
  templateUrl: './generate-sms.component.html',
  styleUrls: ['./generate-sms.component.css']
})
export class GenerateSmsComponent implements OnInit {

  constructor(private _ApiCallService : ApiCallService){}

  countryCode : string = null;

  ngOnInit(){
    this._ApiCallService.getMerchantDetails().subscribe((response) => {
      let data = JSON.parse(response["body"]);
        this.countryCode = data.country;
    })
  }

}