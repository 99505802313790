<div class="qb-templateListContainer" id="container" *ngIf="validWidgets">
  <div class="qb-main-wrapper">
    <div>
    <div class="qb-main-header">
      <app-breadcrumb></app-breadcrumb>
     

      <!-- <div class="list-options">
        <div class="header-button-container" [ngClass]="{ 'margin-bottom-25px': !showFilters}">
          <button class="header-button header-button-left"  [ngClass]="{ active: tabSelected == 'preset'}" (click)="selectList('preset', $event)">
            <span>{{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}</span>
          </button>
          <button class="header-button header-button-right"  [ngClass]="{ active: tabSelected == 'custom'}" (click)="selectList('custom', $event)">
            <span>{{'COMMUNICATION_PAGE.CUSTOM' | i18nTranslate}}</span>
          </button>
        </div>
        <div class="content-filter-container" *ngIf="showFilters">
          <div class="label">{{'COMMON_PAGE.FILTERS' | i18nTranslate}}:</div>
          <div>
            <button class="filter-button margin-right-5px"  [ngClass]="{ active: filterSelected == 'general'}" (click)="selectList(tabSelected, $event,'general')">
              <span>{{'COMMON_PAGE.GENERAL' | i18nTranslate}}</span>
            </button>
            <button class="filter-button  margin-right-5px"  [ngClass]="{ active: filterSelected == 'reward'}" (click)="selectList(tabSelected, $event,'reward')">
              <span>{{'COMMON_PAGE.REWARD' | i18nTranslate}}</span>
            </button>
            <button *ngIf="isStandard" class="filter-button"  [ngClass]="{ active: filterSelected == 'consent'}" (click)="selectList(tabSelected, $event,'consent')">
              <span>{{'Consent'}}</span>
            </button>
          </div>
        </div>
      </div> -->

      <!-- <div style="display: flex; align-items: flex-end">
        <button
          style="border-radius: 3px 0px 0px 3px"
          class="qb-selectRectangle"
          (click)="selectList('all')"
          [ngClass]="{ active: tabSelected == 'all' }"
        >
          <span>All</span>
        </button>
        <button
          class="qb-selectRectangle"
          (click)="selectList('preset')"
          [ngClass]="{ active: tabSelected == 'preset' }"
        >
          <span>Preset</span>
        </button>
        <button
          style="border-radius: 0px 3px 3px 0px"
          class="qb-selectRectangle"
          (click)="selectList('mytemplates')"
          [ngClass]="{ active: tabSelected == 'mytemplates' }"
        >
          <span>Custom</span>
        </button>
      </div> -->
      <div class="qb-d-flex qb-ai-center">
        <div class="qb-template-style-switch-button-container">
          <div (click)="selectLayoutView()" class="qb-template-style-switch-icon-container qb-grid-view-icon">
            <span class="icons-primary cursor-pointer" [matTooltipShowDelay]="500"
              [matTooltip]="'COMMON_PAGE.GRID_VIEW' | i18nTranslate">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.25 1.5V4.25H14.5V2C14.5 1.75 14.25 1.5 14 1.5H11.25ZM9.75 1.5H6.25V4.25H9.75V1.5ZM4.75 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V4.25H4.75V1.5ZM1.5 5.75V8.25H4.75V5.75H1.5ZM1.5 9.75V12C1.5 12.2812 1.71875 12.5 2 12.5H4.75V9.75H1.5ZM6.25 12.5H9.75V9.75H6.25V12.5ZM11.25 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V9.75H11.25V12.5ZM14.5 8.25V5.75H11.25V8.25H14.5ZM0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2ZM6.25 8.25H9.75V5.75H6.25V8.25Z" fill="#4379EE"/>
                </svg>
                
            </span>
          </div>
          <div class="qb-template-style-switch-icon-container qb-icon-container">
            <span class="icons-primary qb-icon-format-list" 
              [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.LIST_VIEW' | i18nTranslate">
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0C1.53125 0 2 0.46875 2 1C2 1.5625 1.53125 2 1 2C0.4375 2 0 1.5625 0 1C0 0.46875 0.4375 0 1 0ZM4.75 0.25H14.25C14.6562 0.25 15 0.59375 15 1C15 1.4375 14.6562 1.75 14.25 1.75H4.75C4.3125 1.75 4 1.4375 4 1C4 0.59375 4.3125 0.25 4.75 0.25ZM4.75 5.25H14.25C14.6562 5.25 15 5.59375 15 6C15 6.4375 14.6562 6.75 14.25 6.75H4.75C4.3125 6.75 4 6.4375 4 6C4 5.59375 4.3125 5.25 4.75 5.25ZM4.75 10.25H14.25C14.6562 10.25 15 10.5938 15 11C15 11.4375 14.6562 11.75 14.25 11.75H4.75C4.3125 11.75 4 11.4375 4 11C4 10.5938 4.3125 10.25 4.75 10.25ZM2 6C2 6.5625 1.53125 7 1 7C0.4375 7 0 6.5625 0 6C0 5.46875 0.4375 5 1 5C1.53125 5 2 5.46875 2 6ZM1 10C1.53125 10 2 10.4688 2 11C2 11.5625 1.53125 12 1 12C0.4375 12 0 11.5625 0 11C0 10.4688 0.4375 10 1 10Z" fill="#4379EF"/>
                </svg>
                                
            </span>
          </div>
        </div>
        <!-- <button *ngIf="
            appSubscriptionPlan &&
            (validWidgets | widgetConfig: 19)
          " class="secondary-button" (click)="createsmsconsent()">
          <span class="qb-create-template-span"> {{'COMMUNICATION_PAGE.SEND_CONSENT' | i18nTranslate}}</span>
        </button> -->
        <button *ngIf="
          isSMSEditAllowed &&
            (validWidgets | widgetConfig: 19)
          " class="qb-create-template-div primary-button" (click)="createTemplate('new')">
          <span class="qb-create-template-span"> Create New
            Template
          </span>
        </button>
      </div>
    </div>
    </div>
    <div class="qb-select-list" *ngIf="validWidgets | widgetConfig: 18">
      <div class="qb-side-panel-container">
        <div>
        <div class="qb-template-list-header">
          <span class="qb-available-segment-static-text">{{
            tabSelected
            }} Templates</span>
        </div>

        </div>

        <div class="list-options">
          <mat-select [(ngModel)]="filterSelected" (selectionChange)="selectList(tabSelected,filterSelected,true)"
            class="mini-select-style" >
            <mat-option [value]="'All'">All</mat-option>
            <mat-option [value]="'general'">{{'COMMON_PAGE.GENERAL' | i18nTranslate}}</mat-option>
            <mat-option [value]="'reward'">{{'COMMON_PAGE.REWARD' | i18nTranslate}}</mat-option>
          </mat-select>
          <!-- <ng-template #noFilter>
            All
          </ng-template> -->
          <mat-select [(ngModel)]="tabSelected" (selectionChange)="selectList(tabSelected,null,true)"
            class="mini-select-style">
            <mat-option [value]="'preset'">{{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}</mat-option>
            <mat-option [value]="'custom'">{{'COMMUNICATION_PAGE.CUSTOM' | i18nTranslate}}</mat-option>
          </mat-select>
          <!-- <ng-template #noMainListFilter>
            {{'COMMUNICATION_PAGE.PRESET' | i18nTranslate}}
          </ng-template> -->
         
        </div>

          <div class="qb-Rectangle">
            <div class="qb-d-flex qb-ai-center qb-w-96 qb-h-100">
              <input class="qb-input-text" type="text" [(ngModel)]="filter.label"
                placeholder="Search" #search (input)="searchsms()" />
              <span class="material-icons icons-primary" *ngIf="filter.label.length==0">
                search
              </span>
              <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
                *ngIf="filter.label.length>0" (click)="filter.label='';searchsms()">
                close
              </span>
            </div>
          </div>
       
        <div class="qb-sms-list-container">
          <div *ngIf="!loading && smsLayouts.length>0">
            <div [id]="layout['id']" *ngFor="
              let layout of smsLayouts | labelPipe: filter.label:'name';
              let i = index
            " class="qb-top-list" [class.active]="layout['id'] === selectedLayout['id']"
            [class.noBorder]="i === selectedStandardTemplateIndex - 1" (click)="viewTemplateDetail(layout)">
            <div class="qb-list-class" [matTooltip]="layout['name']" matTooltipclass="qb-ex-tooltip-red1"
              matTooltipShowDelay="500" matTooltipPosition="right">
              <div class="qb-d-flex qb-ai-center qb-w-100">

                <div class="material-icons-outlined email-icon-color"> email </div>
                <div class="qb-segment-name-div">
                  <span class="qb-segment-name-text"> {{ layout["name"] }}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
          <!-- <div *ngIf="smsCount == 0 && !loading" class="qb-empty-customers-div">
            {{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
          </div> -->
          <div *ngIf="!loading && (smsLayouts.length === 0 || (smsLayouts | labelPipe: filter.label:'name').length==0)"
          class="qb-empty-customers-div">
          <div>{{'COMMUNICATION_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
        </div>
        <div *ngIf="loading" class="loader">
          <app-qubriux-loading [size]="45" [loadingText]="'Fetching SMS Templates...'"></app-qubriux-loading>
        </div>
        </div>
      </div>
      <div class="qb-layout-name-background">
        <div class="qb-d-flex qb-ai-center qb-jc-center qb-h-100">
          <div class="qb-main-container" *ngIf="selectedLayout">
            <div class="qb-header-container">
              <span class="qb-template-name-text">{{
                selectedLayout["name"]
                }}</span>
              <div class="qb-d-flex">
                <!-- <div>
                  <div class="qb-preview-rectangle" (click)="editLayout()">
                    <mat-icon class="qb-visibility-icon">edit</mat-icon>
                    <span class="qb-preview-text">Edit</span>
                  </div>
                </div> -->
                  <div 
                  *ngIf="selectedLayout['subLayout'] != 'CONSENT'"
                  class="qb-preview-rectangle" (click)="cloneLayout(selectedLayout,$event)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 0C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5V11.5C16 11.9167 15.8542 12.2708 15.5625 12.5625C15.2708 12.8542 14.9167 13 14.5 13H13V14.5C13 14.9167 12.8542 15.2708 12.5625 15.5625C12.2708 15.8542 11.9167 16 11.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V4.5C0 4.08333 0.145833 3.72917 0.4375 3.4375C0.729167 3.14583 1.08333 3 1.5 3H3V1.5C3 1.08333 3.14583 0.729167 3.4375 0.4375C3.72917 0.145833 4.08333 0 4.5 0H14.5ZM11.3125 14.5C11.4375 14.5 11.5 14.4375 11.5 14.3125V13H4.5C4.08333 13 3.72917 12.8542 3.4375 12.5625C3.14583 12.2708 3 11.9167 3 11.5V4.5H1.6875C1.5625 4.5 1.5 4.5625 1.5 4.6875V14.3125C1.5 14.4375 1.5625 14.5 1.6875 14.5H11.3125ZM14.3125 11.5C14.4375 11.5 14.5 11.4375 14.5 11.3125V1.6875C14.5 1.5625 14.4375 1.5 14.3125 1.5H4.6875C4.5625 1.5 4.5 1.5625 4.5 1.6875V11.3125C4.5 11.4375 4.5625 11.5 4.6875 11.5H14.3125Z" fill="#4379EF"/>
                    </svg>      
                    
                  </div>
                <!-- <div *ngIf="type != 'reward'">
                  <div
                    class="qb-preview-rectangle"
                    style="background: #0183fb; color: #fff"
                    (click)="sendNow(selectedLayout['id'])"
                  >
                    <mat-icon class="qb-visibility-icon">send</mat-icon>
                    <span>Send Now</span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="qb-layout-data-container">
              <div class="qb-w-460-px">
                <div class="qb-phone-image">
                  <div class="qb-text-container-div">
                    <div class="qb-preview-text-content">
                      <span class="qb-layout-data-text">{{ selectedLayout["layoutData"]
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>