import { Component, OnInit, Inject, HostListener, Input } from "@angular/core";
import * as d3 from "d3";
import { ActivatedRoute, Router } from "@angular/router";
import { KeywordComponent } from "./keyword/keyword.component";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

@Component({
  selector: "app-survey-sentiment-dialogbox",
  templateUrl: "./survey-sentiment-dialogbox.component.html",
  styleUrls: ["./survey-sentiment-dialogbox.component.css"],
})
export class SurveySentimentDialogboxComponent implements OnInit {
  @Input() surveyData;
  npsTotalScore;
  promoters;
  passives;
  detractors;
  totalCountValue = 0;
  sentimentScore;

  promotersPercentage = 0;
  passivesPercentage = 0;
  detractorsPercentage = 0;
  valuesUpdated = false;
  promotersKeyword;
  promotersKeywordArray;
  passivesKeyword;
  passivesKeywordArray;
  detractorsKeyword;
  detractorsKeywordArray;
  feedbacks = [];

  surveyresponse;
  surveyBody;

  scrHeight;
  scrWidth;
  scrChartWidth;
  scrChartHeight;

  NaNdetected: boolean = false;
  NPSDATA: boolean = false;
  ASentScoreDetected: boolean = true;
  LatestFeedbackDetected: boolean;
  totalCountSENTI = 0;
  totalCountNPS = 0;

  avgPromoter;
  avgPassive;
  avgDetractor;

  avgPromoterPerc;
  avgPassivePerc;
  avgDetractorPerc;
  avgTotalSentimentValue;

  avgSentimentScore;

  colorPromoter = "#4AD991";
  colorPassive = "#FEC53D";
  colorDetractor = "#FF6565";
  colorNeutral = "#FEC53D";
  validWidgets;
  appSubscriptionPlan;
  id;
  isAnalysis='true';
  name;
  dashboardData;
  graphData;

  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileView;

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private route:ActivatedRoute,
    private router:Router,
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private date_format: getdateformatService
  ) {}

  ngOnInit() {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    var sb
    if(this.mobileView){
      let dashboardData;
      this.id=this.route.snapshot.paramMap.get("id");
      this.isAnalysis=this.route.snapshot.paramMap.get("isAnalysis");
      this.name=this.route.snapshot.paramMap.get("name");
      this.apiCall.getIndividualSurveyDashboard(this.id).subscribe((response)=>{
        dashboardData = response["body"];
        this.dashboardData = dashboardData;
        this.graphData = {
          data: this.dashboardData,
          surveyName: this.name,
      }
      this.surveyData=this.graphData
      sb=JSON.parse(this.surveyData.data);
      this.getWidgets();
      this.scrChartWidth = this.scrWidth * 0.6 * 0.3;
  
      this.totalCountSENTI = 0;
      this.totalCountNPS = 0;
      // var sb = JSON.parse(this.surveyData.data);
      this.surveyBody = sb["surveyKeyphrase"];

      this.getFeedbackData(this.surveyBody["latestCustomerFeedbacks"]);
  
      if (sb["surveySentiment"]["sentimentScore"] != "NaN") {
        this.avgPromoter = sb["surveySentiment"]["positive"];
        this.avgPassive = sb["surveySentiment"]["neutral"];
        this.avgDetractor = sb["surveySentiment"]["negative"];
        this.avgSentimentScore = sb["surveySentiment"]["sentimentScore"];
        this.avgSentimentScore = this.avgSentimentScore.toFixed(2);
        this.totalCountSENTI =
          this.totalCountSENTI +
          this.avgPromoter +
          this.avgPassive +
          this.avgDetractor;
  
        this.avgTotalSentimentValue =
          this.avgPromoter + this.avgPassive + this.avgDetractor;
        this.avgPromoterPerc =
          (this.avgPromoter * 100) / this.avgTotalSentimentValue;
        this.avgPassivePerc =
          (this.avgPassive * 100) / this.avgTotalSentimentValue;
        this.avgDetractorPerc =
          (this.avgDetractor * 100) / this.avgTotalSentimentValue;
  
        this.ASentScoreDetected = true;
        var promoterSetASS = {
          promoter: this.avgPromoter,
          promoterPerc: this.avgPromoterPerc,
        };
        var passiveSetASS = {
          passive: this.avgPassive,
          passivePerc: this.avgPassivePerc,
        };
        var detractorSetASS = {
          detractor: this.avgDetractor,
          detractorPerc: this.avgDetractorPerc,
        };
        // if(this.ASentScoreDetected==true){
        let interval1 = setInterval(() => {
          if (this.totalCountSENTI > 0) {
            clearInterval(interval1);
            this.getDounutChart(
              "#donutarea2",
              promoterSetASS,
              passiveSetASS,
              detractorSetASS,
              [this.colorPromoter, this.colorNeutral, this.colorDetractor]
            );
          }
        }, 100);
  
        // }
      } else {
        this.ASentScoreDetected = false;
      }
  
      if (this.surveyBody["npsScore"] == "NaN") {
        this.npsTotalScore = null;
        this.NaNdetected = true;
      } else {
        this.npsTotalScore = this.surveyBody["npsScore"];
        this.npsTotalScore = this.npsTotalScore.toFixed(2);
        this.sentimentScore = this.surveyBody["sentimentScore"];
        var i;
        for (i = 0; i < this.surveyBody["surveyCategory"].length; i++) {
          if (this.surveyBody["surveyCategory"][i]["category"] == "PASSIVES") {
            this.passives = this.surveyBody["surveyCategory"][i]["count"];
            this.passivesKeyword = this.surveyBody["surveyCategory"][i][
              "keyphrase"
            ];
            this.passivesKeywordArray = this.passivesKeyword.split(",");
          } else if (
            this.surveyBody["surveyCategory"][i]["category"] == "PROMOTERS"
          ) {
            this.promoters = this.surveyBody["surveyCategory"][i].count;
            this.promotersKeyword = this.surveyBody["surveyCategory"][i][
              "keyphrase"
            ];
            this.promotersKeywordArray = this.promotersKeyword.split(",");
          } else if (
            this.surveyBody["surveyCategory"][i].category == "DETRACTORS"
          ) {
            this.detractors = this.surveyBody["surveyCategory"][i].count;
            this.detractorsKeyword = this.surveyBody["surveyCategory"][i][
              "keyphrase"
            ];
            this.detractorsKeywordArray = this.detractorsKeyword.split(",");
          } else {
          }
        }
        if (this.passives == undefined) {
          this.passives = 0;
        }
        if (this.promoters == undefined) {
          this.promoters = 0;
        }
        if (this.detractors == undefined) {
          this.detractors = 0;
        }
  
        this.totalCountValue = this.promoters + this.passives + this.detractors;
        this.promotersPercentage = (this.promoters * 100) / this.totalCountValue;
        this.passivesPercentage = (this.passives * 100) / this.totalCountValue;
        this.detractorsPercentage =
          (this.detractors * 100) / this.totalCountValue;
  
        this.totalCountNPS =
          this.totalCountNPS + this.promoters + this.passives + this.detractors;
        var promoterSetTSS = {
          promoter: this.promoters,
          promoterPerc: this.promotersPercentage,
        };
        var passiveSetTSS = {
          passive: this.passives,
          passivePerc: this.passivesPercentage,
        };
        var detractorSetTSS = {
          detractor: this.detractors,
          detractorPerc: this.detractorsPercentage,
        };
  
        if (this.NaNdetected == false) {
          let interval1 = setInterval(() => {
            if (this.totalCountNPS > 0) {
              clearInterval(interval1);
              this.getDounutChart(
                "#donutarea",
                promoterSetTSS,
                passiveSetTSS,
                detractorSetTSS,
                [this.colorPromoter, this.colorPassive, this.colorDetractor]
              );
            }
          }, 100);
        }
      }




    });
    // sb=this.graphData.data
    // console.log("out"+sb)
  }
    else{
      sb=JSON.parse(this.surveyData.data);
    console.log("3"+sb)
    // console.log(this.surveyData)
    this.getWidgets();
    this.scrChartWidth = this.scrWidth * 0.6 * 0.3;

    this.totalCountSENTI = 0;
    this.totalCountNPS = 0;
    // var sb = JSON.parse(this.surveyData.data);
    this.surveyBody = sb["surveyKeyphrase"];
    this.getFeedbackData(this.surveyBody["latestCustomerFeedbacks"]);

    if (sb["surveySentiment"]["sentimentScore"] != "NaN") {
      this.avgPromoter = sb["surveySentiment"]["positive"];
      this.avgPassive = sb["surveySentiment"]["neutral"];
      this.avgDetractor = sb["surveySentiment"]["negative"];
      this.avgSentimentScore = sb["surveySentiment"]["sentimentScore"];
      this.avgSentimentScore = this.avgSentimentScore.toFixed(2);
      this.totalCountSENTI =
        this.totalCountSENTI +
        this.avgPromoter +
        this.avgPassive +
        this.avgDetractor;

      this.avgTotalSentimentValue =
        this.avgPromoter + this.avgPassive + this.avgDetractor;
      this.avgPromoterPerc =
        (this.avgPromoter * 100) / this.avgTotalSentimentValue;
      this.avgPassivePerc =
        (this.avgPassive * 100) / this.avgTotalSentimentValue;
      this.avgDetractorPerc =
        (this.avgDetractor * 100) / this.avgTotalSentimentValue;

      this.ASentScoreDetected = true;
      var promoterSetASS = {
        promoter: this.avgPromoter,
        promoterPerc: this.avgPromoterPerc,
      };
      var passiveSetASS = {
        passive: this.avgPassive,
        passivePerc: this.avgPassivePerc,
      };
      var detractorSetASS = {
        detractor: this.avgDetractor,
        detractorPerc: this.avgDetractorPerc,
      };
      // if(this.ASentScoreDetected==true){
      let interval1 = setInterval(() => {
        if (this.totalCountSENTI > 0) {
          clearInterval(interval1);
          this.getDounutChart(
            "#donutarea2",
            promoterSetASS,
            passiveSetASS,
            detractorSetASS,
            [this.colorPromoter, this.colorNeutral, this.colorDetractor]
          );
        }
      }, 100);

      // }
    } else {
      this.ASentScoreDetected = false;
    }

    if (this.surveyBody["npsScore"] == "NaN") {
      this.npsTotalScore = null;
      this.NaNdetected = true;
    } else {
      this.npsTotalScore = this.surveyBody["npsScore"];
      this.npsTotalScore = this.npsTotalScore.toFixed(2);
      this.sentimentScore = this.surveyBody["sentimentScore"];
      var i;
      for (i = 0; i < this.surveyBody["surveyCategory"].length; i++) {
        if (this.surveyBody["surveyCategory"][i]["category"] == "PASSIVES") {
          this.passives = this.surveyBody["surveyCategory"][i]["count"];
          this.passivesKeyword = this.surveyBody["surveyCategory"][i][
            "keyphrase"
          ];
          this.passivesKeywordArray = this.passivesKeyword.split(",");
        } else if (
          this.surveyBody["surveyCategory"][i]["category"] == "PROMOTERS"
        ) {
          this.promoters = this.surveyBody["surveyCategory"][i].count;
          this.promotersKeyword = this.surveyBody["surveyCategory"][i][
            "keyphrase"
          ];
          this.promotersKeywordArray = this.promotersKeyword.split(",");
        } else if (
          this.surveyBody["surveyCategory"][i].category == "DETRACTORS"
        ) {
          this.detractors = this.surveyBody["surveyCategory"][i].count;
          this.detractorsKeyword = this.surveyBody["surveyCategory"][i][
            "keyphrase"
          ];
          this.detractorsKeywordArray = this.detractorsKeyword.split(",");
        } else {
        }
      }
      if (this.passives == undefined) {
        this.passives = 0;
      }
      if (this.promoters == undefined) {
        this.promoters = 0;
      }
      if (this.detractors == undefined) {
        this.detractors = 0;
      }

      this.totalCountValue = this.promoters + this.passives + this.detractors;
      this.promotersPercentage = (this.promoters * 100) / this.totalCountValue;
      this.passivesPercentage = (this.passives * 100) / this.totalCountValue;
      this.detractorsPercentage =
        (this.detractors * 100) / this.totalCountValue;

      this.totalCountNPS =
        this.totalCountNPS + this.promoters + this.passives + this.detractors;
      var promoterSetTSS = {
        promoter: this.promoters,
        promoterPerc: this.promotersPercentage,
      };
      var passiveSetTSS = {
        passive: this.passives,
        passivePerc: this.passivesPercentage,
      };
      var detractorSetTSS = {
        detractor: this.detractors,
        detractorPerc: this.detractorsPercentage,
      };

      if (this.NaNdetected == false) {
        let interval1 = setInterval(() => {
          if (this.totalCountNPS > 0) {
            clearInterval(interval1);
            this.getDounutChart(
              "#donutarea",
              promoterSetTSS,
              passiveSetTSS,
              detractorSetTSS,
              [this.colorPromoter, this.colorPassive, this.colorDetractor]
            );
          }
        }, 100);
      }
      // this.getFeedbackData(this.surveyBody['latestCustomerFeedbacks']);

      // var data = [
      //   {name: 'cats', count: 3, percentage: 2, color: '#000000'},
      //   {name: 'dogs', count: 10, percentage: 8, color: '#f8b70a'},
      //   {name: 'horses', count: 17, percentage: 15, color: '#6149c6'},
      //   {name: 'goats', count: 47, percentage: 41, color: '#9f8170'},
      //   {name: 'cows', count: 35, percentage: 31, color: '#8ABD4A'},
      // ];
    }}
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan = await this._WidgetConfigService.getAppSubscriptionPlan();
  }
  getDounutChart(idarea, promoterSet, passiveSet, detractorSet, colorSet) {
    var data = [
      {
        name: "Promoters",
        count: promoterSet.promoter,
        percentage: promoterSet.promoterPerc,
        color: colorSet[0],
      },
      {
        name: "Passives",
        count: passiveSet.passive,
        percentage: passiveSet.passivePerc,
        color: colorSet[1],
      },
      {
        name: "Detractors",
        count: detractorSet.detractor,
        percentage: detractorSet.detractorPerc,
        color: colorSet[2],
      },
    ];

    var p = 0.1;
    var i, n;
    for (i = 0, n = data.length; i < n; i++) {
      var d = data[i];
      for (var c in d) {
        if (c == "percentage") {
          if (d[c] == 100) p = 0;
        }
      }
      data[i] = d;
    }

    var totalCount = this.totalCountValue; //calcuting total manually

    var width = 150,
      height = 150,
      radius = Math.min(width, height) / 2;

    var arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - 20);
     // .cornerRadius(5);
    var pie = d3
      .pie()
      .padAngle(p)
      .sort(null)
      .value(function (d) {
        return d.count;
      });

    var svg = d3
      .select(idarea)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    var g = svg.selectAll(".arc").data(pie(data)).enter().append("g");

    g.append("path")
      .attr("d", arc)
      .style("fill", function (d, i) {
        return d.data.color;
      })
      .transition()
      .duration(function (d, i) {
        return i * 800;
      })
      .attrTween("d", function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    // g.append("text")
    //   .attr("transform", function (d) {
    //     var _d = arc.centroid(d);
    //     _d[0] *= 1.5;	//multiply by a constant factor
    //     _d[1] *= 1.5;	//multiply by a constant factor
    //     return "translate(" + _d + ")";
    //   })
    //   .attr("dy", ".50em")
    //   .style("text-anchor", "middle")
    //   .text(function (d) {
    //     if (d.data.percentage < 8) {
    //       return '';
    //     }
    //     return d.data.percentage + '%';
    //   });
    //   if('#donutarea2'==idarea){

    //     g.append("text")
    //     .attr("text-anchor", "middle")
    //     .attr('font-size', '16px')
    //     .attr('y', 0)
    //     .style("fill","#101424")
    //     .attr('font-family', 'Open sans')
    //     .text(this.avgSentimentScore);

    //   g.append("text")
    //     .attr("text-anchor", "middle")
    //     .attr("font-family", "Open sans")
    //     .attr('font-size', '14px')
    //     .attr('y', '1em')
    //     .style("fill","#101424")
    //     .text("Avg Sentiment Score");
    //   }
    //   else if('#donutarea'==idarea){
    //     g.append("text")
    //   .attr("text-anchor", "middle")
    //   .attr('font-size', '16px')
    //   .attr('y', 0)
    //   .style("fill","#101424")
    //   .attr('font-family', 'Open sans')
    //   .text(this.npsTotalScore);

    // g.append("text")
    //   .attr("text-anchor", "middle")
    //   .attr("font-family", "Open sans")
    //   .attr('font-size', '14px')
    //   .attr('y', '1em')
    //   .style("fill","#101424")
    //   .text("Total Survey Score");
    // }
  }

  submit(decision) {
    // this.dialogRef.close(decision)
  }

  getFeedbackData(data) {
    let feedback: any = {};

    for (let key in data) {
      let feedback: any = {};
      feedback["cName"] = key;
      feedback["data"] = data[key];
      this.feedbacks.push(feedback);
    }
    if (this.feedbacks.length > 0) {
      this.LatestFeedbackDetected = true;
    } else {
      this.LatestFeedbackDetected = false;
    }
  }
  reRoute(route){
    this.router.navigate([route])
  }
  keywordViewPassive(){
    console.log(this.passivesKeywordArray)
    this.dialog.open(KeywordComponent,{
      panelClass:"no-padding-dialog-popup",
      width:"343px",
      height:"226px",
      data:{
        subject:"Passives",
        keyword:this.passivesKeywordArray
      }
    })
  }
  keywordViewDetractor(){
    console.log(this.detractorsKeywordArray)
    this.dialog.open(KeywordComponent,{
      panelClass:"no-padding-dialog-popup",
      width:"343px",
      height:"226px",
      data:{
        subject:"Detractors",
        keyword:this.detractorsKeywordArray
      }
    })
  }
  keywordViewPromoter(){
    console.log(this.promotersKeywordArray)
    this.dialog.open(KeywordComponent,{
      panelClass:"no-padding-dialog-popup",
      width:"343px",
      height:"226px",
      data:{
        subject:"Promoters",
        keyword:this.promotersKeywordArray
      }
    })
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
