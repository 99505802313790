import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { WhatsappTemplateCreationComponent } from './components/whatsapp-template-creation/whatsapp-template-creation.component';
import { WhatsappTemplateListingComponent } from './pages';

const routes: Routes = [
    { path: "", redirectTo: "choose-layout", pathMatch: "full" },
    {
        path: "choose-layout",
        component: WhatsappTemplateListingComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "WhatsApp",
          title: "Choose WhatsApp template",
          minPath: "Basic",
          id: 18,
        },
    },
    {
      path: "layout-editor",
      component: WhatsappTemplateCreationComponent,
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WhatsAppRoutingModule { }
