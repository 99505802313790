import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-new-reward",
  templateUrl: "./new-reward.component.html",
  styleUrls: ["./new-reward.component.css"],
})
export class NewRewardComponent implements OnInit {
  constructor(
    private router: Router,
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  rewardNameError = "";
  rewardName = "";
  rewardValue = 10;

  ngOnInit() {}

  createReward() {
    this.apiCall
      .CreatePartnershipReward(this.rewardName, this.rewardValue)
      .subscribe(
        (response) => {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Reward created", ['POS_PAGE']), 2000);
          this.router.navigate(["/app/partnership/rewards"]);
        },
        (error) => {
          this.rewardNameError = this._i18nDynamicTranslate.transform(error["error"]["body"], ['POS_PAGE']);
        }
      );
  }

  save() {
    if (
      this.rewardName.replace(/\s/g, "").length > 0 &&
      this.rewardName.length < 15 &&
      this.rewardValue > 0 &&
      this.rewardValue < 100
    ) {
      this.createReward();
    } else {
      if (this.rewardValue <= 0) {
        this.displaySnackbar("Reward value must be greater than zero");
      } else if (this.rewardValue > 100) {
        this.displaySnackbar("Reward value cannot be greater than 100");
      } else if (this.rewardName.replace(/\s/g, "").length == 0) {
        this.rewardNameError = this._i18nDynamicTranslate.transform("Please enter a valid reward name", ['POS_PAGE']);
      } else if (this.rewardName.length > 15) {
        this.rewardNameError = this._i18nDynamicTranslate.transform("This field cannot have more than 15 characters", ['POS_PAGE']);
      } else {
        this.displaySnackbar("All fields are required");
      }
    }
  }

  displaySnackbar(text) {
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(text, ['POS_PAGE']), 2000);
  }

  redirect() {
    this.router.navigate(["/app/partnership/rewards"]);
  }
}
