import { Component, OnInit } from '@angular/core';
import { countries } from '../../json-asset/countries';


@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.css']
})
export class CountryDropdownComponent implements OnInit {

  countries = countries;
  searchTerm: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
