import { NgModule } from '@angular/core';
import { CampaignComponent, CampaignFilterDialogComponent, CampaignListComponent, CampaignOverviewComponent, CampaignStrategyComponent, CampaignTemplateCreationComponent, CampaignTemplateListingComponent, CampaignTemplatesComponent } from './pages';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignAddFilterComponent, CampaignChannelSelectComponent, CampaignHowToCreateComponent, CampaignStrategyNodeComponent, TriggerAdditionalConfigComponent } from './component';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatChipsModule } from '@angular/material/chips';
import { SetUpCampaignComponent } from './pages/set-up-campaign/set-up-campaign.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ConnectAdAccountComponent } from './pages/connect-ad-account/connect-ad-account.component';
import { CampaignKpiComponent } from '../analyze/components';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { NgxEmojModule } from 'ngx-emoj';
import { CampaignAbTestingComponent } from './pages/campaign-ab-testing/campaign-ab-testing.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    CampaignComponent,
    CampaignListComponent,
    CampaignOverviewComponent,
    CampaignStrategyComponent,
    CampaignTemplatesComponent,
    CampaignFilterDialogComponent,
    CampaignTemplateCreationComponent,
    CampaignTemplateListingComponent,
    CampaignStrategyNodeComponent,
    CampaignAddFilterComponent,
    CampaignHowToCreateComponent,
    SetUpCampaignComponent,
    ConnectAdAccountComponent,
    CampaignChannelSelectComponent,
    CampaignKpiComponent,
    TriggerAdditionalConfigComponent,
    CampaignAbTestingComponent
  ],
  imports: [
    SharedModule,
    CampaignRoutingModule,
    RouterModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    NgxEmojiPickerModule.forRoot(),
    NgxEmojModule,
    MatMenuModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ]
})
export class CampaignModule { }
