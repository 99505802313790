import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";

// const dotenv = require("dotenv");

declare var FB;
@Component({
  selector: "app-social-media",
  templateUrl: "./social-media.component.html",
  styleUrls: ["./social-media.component.css"],
})
export class SocialMediaComponent implements OnInit {
  constructor(
    private _apicall: ApiCallService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _dialog: MatDialog,

  ) {}
  fbConnectionStatus;
  longresponse;
  shorttoken;
  userClient;
  selectedTab;
  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      if (params.type) this.selectedTab = params.type;
      else {this.selectedTab="home";
      this._router.navigate(['.'], { relativeTo: this._route, queryParamsHandling: "merge",queryParams: { type:'home' }});
    }
    });
  } 
  authenticateFB() {
    FB.login((response) => {

      if (response.authResponse) {
        this.fbConnectionStatus = response.status;
        this.shorttoken = response.authResponse.accessToken;
      
      } else {
     
      }
    }, {
      scope: 'email,user_likes,pages_events,pages_manage_engagement,pages_manage_metadata,pages_manage_posts,pages_read_engagement,pages_read_user_content,public_profile,publish_video,read_insights',
      return_scopes: true,
      enable_profile_selector: true
  });
  }


  logoutFB() {
    FB.logout((response) => {

      this.fbConnectionStatus = response.status;
    });
  }




}
