<div class="templateListContainer" id="container">
    <div *ngIf="validWidgets">
        <div style="display: flex;justify-content: space-between;" *ngIf="validWidgets | widgetConfig : 7">
            <div style="width: 50%; display: flex; align-items: center;">
                <div class="Campaign-Strategy-Te">{{ validWidgets | widgetLabel : 17}}</div>
                <span style="margin-left: 10px;  display: flex; align-items: center;">
                    <ng-template #popContent> <div>Please check the <a
                            href="https://www.skellam.ai/greyfoxhelp/Campaign-Management"
                            target="_blank">documentation</a>
                        for more information.</div>
                    </ng-template>
                    <mat-icon type="button" placement="top" [autoClose]="'outside'" [ngbPopover]="popContent"
                        style="color: #c0c4d0; cursor: pointer;  position: relative;">
                        info</mat-icon>
                </span>
            </div>
            <div style="width: 50%; display: flex; justify-content: flex-end;">
                <div class="Rectangle">
                    <div style="display: flex;align-items: center;" widget-id="17" event-id="8" type="button" data-strategy-action="strategy-search">
                        <mat-icon style="color: #c0c4d0; margin: 0px 8px 0px 10px;" matPrefix>search</mat-icon>
                        <input style="border:none;outline: none;" type="text" [(ngModel)]="filter.label" placeholder="Search by name">
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;margin-top: 10px;">

            <button class="selectRectangle" (click)="selectList('preset')" [ngClass]="{ active: tabSelected=='preset'}" widget-id="17" event-id="7" type="button" object-type="standard" data-strategy-action="strategy-tab-click" tab-selected="preset-strategies"><span>Preset Journeys</span><span class="tabIconInside"><mat-icon style="transform: rotate(90deg);height: 22px;
        width: 22px;">chevron_right</mat-icon></span></button>
            <button class="selectRectangle" (click)="selectList('mytemplates')" [ngClass]="{ active: tabSelected=='mytemplates'}" widget-id="17" event-id="7" type="button" object-type="custom" data-strategy-action="strategy-tab-click" tab-selected="my-strategies"><span>My Journeys</span><span class="tabIconInside"><mat-icon style="transform: rotate(90deg);height: 22px;
                width: 22px;">chevron_right</mat-icon></span></button>


            <button class="selectRectangle" (click)="selectList('new')" [ngClass]="{ active: tabSelected=='new'}" widget-id="17" event-id="7" type="button" data-strategy-action="strategy-tab-click" object-type="custom" tab-selected="new-strategy"><span>Create New Journey</span><span class="tabIconInside" style="margin-bottom: -3px;justify-content: center;"><img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/segment-assets/group-5.svg" alt="" style="    height: 34px;
                width: 34px;"></span></button> </div>
        <div class="seperator1"></div>
        <div *ngIf="!loadingCustom&&!loadingStandard">
            <div>
                <div>
                    <div style="display: flex; flex-wrap: wrap;">
                        <!-- <div class="Rectangle-new" *ngIf="tabSelected=='mytemplates'&& appSubscription=='Professional'&&(customCampaigns | labelPipe: filter.label: 'strategyName').length==customLength">
                            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;height:100%">

                                <div (click)="createCampaign()" class="add-strategy-button" [ngClass]="{'inactive':appSubscription !== 'Professional'}" [matTooltip]="appSubscription !== 'Professional' ? 'Subscribe to higher Plan': ''">
                                    <mat-icon class="add-strategy-icon">add</mat-icon>
                                </div>
                                <div class="Start-new-blank-temp" [ngClass]="{'inactive':appSubscription !== 'Professional'}">
                                    <span>Create new Strategy</span>
                                </div>
                            </div>
                        </div> -->
                        <div *ngFor=" let layout of selectedTabTemplateList | labelPipe: filter.label: 'strategyName';let i=index;" class="Rectangle-list">
                            <div class="description-div">
                                <mat-icon class="description-icon" [matTooltip]="layout['description']">info
                                </mat-icon>
                            </div>
                            <div style="cursor: pointer; height:140px;" (click)="editCampaign(layout['id'])" widget-id="17" event-id="6" type="button" data-strategy-action="strategy-tile-click" [attr.object-type]="tabSelected=='preset'?'standard':'custom'">
                                <img class="image-class" [src]="layout['snapshot']" alt=" Email-Template ">
                            </div>
                            <div style="border-top: 2px solid #e2e2e2; height: 50px;">
                                <div style="display: flex; justify-content: space-between; height: 100%; padding: 8px;">
                                    <div class="templateHeadText">
                                        <span style="min-inline-size: max-content;" [matTooltip]="layout['strategyName']">{{(layout['strategyName'])}}</span>
                                    </div>
                                    <div style="width:30%; display: flex; justify-content: flex-end; align-items: center;">
                                        <mat-icon class="edit-icon" [matTooltip]="'COMMON_PAGE.EDIT_STRATEDY' | i18nTranslate" (click)="editCampaign(layout['id'])">
                                            edit</mat-icon>
                                        <mat-icon [matTooltip]="'COMMON_PAGE.DELETE_STRATEGY' | i18nTranslate" (click)="deleteCampaign(layout['id'])" class="delete-icon" *ngIf="tabSelected!='preset'" widget-id="17" event-id="9" type="button" data-strategy-action="strategy-delete" [attr.object-type]="tabSelected=='preset'?'standard':'custom'">delete_outline</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div>
                <div>
                    <div>
                        <div class="My-Templates">My Templates</div>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div class="Rectangle-new">
                                <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;height:100%">
                                   
                                    <div (click)="createCampaign()" class="add-strategy-button" [ngClass]="{'inactive':appSubscription !== 'Professional'}" [matTooltip]="appSubscription !== 'Professional' ? 'Subscribe to higher Plan': ''">
                                        <mat-icon class="add-strategy-icon">add</mat-icon>
                                    </div>
                                    <div class="Start-new-blank-temp" [ngClass]="{'inactive':appSubscription !== 'Professional'}">
                                        <span>Create new Strategy</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor=" let layout of customCampaigns | labelPipe: filter.label: 'strategyName';let i=index;" class="Rectangle-list">
                                <div class="description-div">
                                    <mat-icon class="description-icon" [matTooltip]="layout['description']">info
                                    </mat-icon>
                                </div>
                                <div style="cursor: pointer; height:140px;" (click)="editCampaign(layout['id'])">
                                    <img class="image-class" [src]="layout['snapshot']" alt=" Email-Template ">
                                </div>
                                <div style="border-top: 2px solid #e2e2e2; height: 50px;">
                                    <div style="display: flex; justify-content: space-between; height: 100%; padding: 8px;">
                                        <div class="templateHeadText">
                                            <span style="min-inline-size: max-content;" [matTooltip]="layout['strategyName']">{{(layout['strategyName'])}}</span>
                                        </div>
                                        <div style="width:30%; display: flex; justify-content: flex-end; align-items: center;">
                                            <mat-icon class="edit-icon" matTooltip="Edit Strategy" (click)="editCampaign(layout['id'])">
                                                edit</mat-icon>
                                            <mat-icon matTooltip="Delete Strategy" (click)="deleteCampaign(layout['id'])" class="delete-icon">delete_outline</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="My-Templates">Preset Templates</div>
                        <div style="display: flex; flex-wrap: wrap;" *ngIf="(standardCampaigs | labelPipe: filter.label: 'strategyName').length > 0">
                            <div *ngFor=" let layout of standardCampaigs | labelPipe: filter.label: 'strategyName';let i=index;" class="Rectangle-list">
                                <div class="description-div">
                                    <mat-icon class="description-icon" [matTooltip]="layout['description']">info
                                    </mat-icon>
                                </div>
                                <div style="cursor: pointer; height:140px;" (click)="editCampaign(layout['id'])">
                                    <img class="image-class" [src]="layout['snapshot']" alt=" Email-Template ">
                                </div>
                                <div style="border-top: 2px solid #e2e2e2; height: 50px;">
                                    <div style="display: flex; justify-content: space-between; height: 100%; padding: 8px;">
                                        <div class="templateHeadText">
                                            <span style="min-inline-size: max-content;" [matTooltip]="layout['strategyName']">{{(layout['strategyName'])}}</span>
                                        </div>
                                        <div style="width:15%; display: flex; justify-content: flex-end; align-items: center;">
                                            <mat-icon class="edit-icon" matTooltip="Edit Strategy" (click)="editCampaign(layout['id'])">
                                                edit</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="more-horizontal-icon" *ngIf="(standardCampaigs | labelPipe: filter.label: 'strategyName').length === 0">
                            <mat-icon>more_horiz</mat-icon>
                        </div>
                    </div>
                </div>
            </div> -->


            <!-- <ng-template #NoMatch> -->
            <div *ngIf="((selectedTabTemplateList | labelPipe: filter.label: 'strategyName').length==0)&&selectedTabTemplateList.length!=0">

                <div style="display: flex;justify-content: center;align-items: center;">
                    <div style="    padding: 10px;
            border: 1px dotted #ff3b30;
            width: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;"> No match found
                    </div>
                </div>
            </div>
            <!-- </ng-template> -->
        </div>
        <div *ngIf="loadingStandard&&loadingCustom">
            <div style="display: flex;justify-content: center;margin-top: 10%;">
                <mat-spinner diameter="45"></mat-spinner>
            </div>
        </div>
    </div>
</div>