<div class="font" style="padding: 15px">
    <div>
        <div class="header">
          <div>
            <span style="text-align: center">{{ data["header"] }}</span>
          </div>
          <div class="header-tab">            
            <span class="primary-button" (click)="submitCustomerListData()">Create Custom List</span>
            <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
          </div>
        </div>
    </div>
    <div class="sub-header">
        <table class="table">
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <!-- <th>Status</th> -->
              </tr>
            </thead>
            <tbody *ngIf="data.customerDetails.length>0">
              <tr *ngFor="let item of data.customerDetails">
                <td>{{ item.customername }}</td>
                <td>{{ item.mobilenumber }}</td>
                <td>{{ item.emailaddress }}</td>
                <!-- <td style="color: black">{{ item.status }}</td> -->
              </tr>
            </tbody>
          </table>
    </div>
</div>
  