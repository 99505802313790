import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-send-test-email-dialog',
  templateUrl: './send-test-email-dialog.component.html',
  styleUrls: ['./send-test-email-dialog.component.css']
})
export class SendTestEmailDialogComponent implements OnInit {

  email = "";
  error = false;
  send = false;
  fileType = 'Excel';
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiCall: ApiCallService,
  private snackBar: SnackbarCollection,
  private dialogRef: MatDialogRef<SendTestEmailDialogComponent>) { }

  ngOnInit() {
  }

  keypress() {
    this.error = false;
  }

  sendTestEmail() {
    if(this.email.trim().match(this.emailPattern)) {
      if(this.data?.reportEmail){
        let data = {};
        data['email'] = this.email;
        data['type'] = this.fileType;
        this.dialogRef.close(data);
      }
      else{
        this.send = true;
        this.dialogRef.close(this.email);
      }
    } else {
      this.error = true;
    }
   
  }

}
