import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
interface DialogData  {
  'customerSegments' : []
}

@Component({
  selector: 'app-sms-schedule-dialog',
  templateUrl: './sms-schedule-dialog.component.html',
  styleUrls: ['./sms-schedule-dialog.component.css']
})
export class SmsScheduleDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SmsScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _ApiCallService : ApiCallService) { }

  customerSegments = [];
  segmentFilter = "";
  dropDownStatus = false;
  totalCustomerCount = 0;

  ngOnInit() {
  }

  ngAfterViewInit() {
    let element = document.getElementById("schedule-container");
    element.addEventListener("click", (event) => this.hideAllEvents(event));
  }

  hideAllEvents(event) {
    let element = document.getElementById("multi-select-dropdown");
    if (!element.contains(event.target)) {
      if (this.dropDownStatus) {
        this.dropDownStatus = false;
        element.style.height = "0px";
        let segmentSelectPanel = document.getElementById("multi-select-panel");
        segmentSelectPanel.style.boxShadow = "unset";
        let keyboardDownIconElement = document.getElementById(
          "keyboard-down-icon-container"
        );
        keyboardDownIconElement.style.transform = "rotate(0deg)";
        this.getSegmentCount();
      }
    }
  }

  toggleSelect(event) {
    event.stopPropagation();
    let element = document.getElementById("multi-select-dropdown");
    let segmentSelect = document.getElementById("segment-select");
    let segmentSelectPanel = document.getElementById("multi-select-panel");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container"
    );
    if (this.dropDownStatus) {
      this.dropDownStatus = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
      this.getSegmentCount();
      //element.style.display = 'none';
    } else {
      this.dropDownStatus = true;
      element.style.height = "125px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
      //element.style.display = 'block';
    }
  }

  addAllCustomers(event) {
    document.getElementById("errorField").innerHTML = "";
    this.customerSegments = [];
    if (event["checked"] === true) {
      this.customerSegments.push("ALL");
    }
  }

  isChecked(segment) {
    if (
      this.customerSegments.length === 1 &&
      this.customerSegments[0] === "ALL"
    ) {
      return false;
    } else {
      let index = this.customerSegments.findIndex((x) => x.id === segment.id);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  updateCheckboxValues(segment) {
    document.getElementById("errorField").innerHTML = "";
    if (
      this.customerSegments.length === 1 &&
      this.customerSegments[0] === "ALL"
    ) {
      this.customerSegments = [];
    }
    let index = this.customerSegments.findIndex((x) => x.id === segment.id);
    if (index < 0) {
      this.customerSegments.push(segment);
    } else {
      this.customerSegments.splice(index, 1);
    }
  }

  getSegmentCount() {
    this.totalCustomerCount = null;
    if (this.customerSegments.length === 0) {
      this.totalCustomerCount = 0;
    } else {
      if (
        this.customerSegments.length === 1 &&
        this.customerSegments[0] === "ALL"
      ) {
        let startDate = new Date();
        let endDate = new Date();
        this._ApiCallService.getAnalytics(startDate,endDate).subscribe((response)=>{
          this.totalCustomerCount = JSON.parse(
            response["body"]
          ).numberOfCustomers;
        })
      } else {
        this._ApiCallService
          .getCustomerCountInSegmentList(this.customerSegments)
          .subscribe((response) => {
            this.totalCustomerCount = JSON.parse(
              response["body"]
            ).totalCustomerCount;
          });
      }
    }
  }

  submit(status){
    if(status){
      if(this.customerSegments.length === 0){
        document.getElementById('errorField').innerHTML = 'Select segment';
        return;
      }
      this.dialogRef.close(this.customerSegments);
    }
    else{
      this.dialogRef.close(false);
    }
  }
}
