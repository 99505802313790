<!-- <div class="qb-container">
    <div class="tab-container">
      <ul class="tabs clearfix">
        <li *ngFor="let tab of tabList" (click)="selectTab(tab)" [class.active]="this.selectedTab === tab" class="qb-tab-select-header">
          <a>{{ tab }}</a>
        </li>
      </ul>
    </div>
    <div class="outer-circle">
      <div *ngIf="selectedTab=='Store Info'" class="qb-full">
        <app-store-details [data]="parentData" (sendDataEvent)="receiveDataFromChild($event)"></app-store-details>
      </div>
      <div *ngIf="selectedTab=='Store Access Keys and Tokens'" class="qb-full">
        <app-store-keys [data]="parentData" (sendDataEvent)="receiveDataFromChild($event)"></app-store-keys>
      </div>
      <div *ngIf="selectedTab=='Data upload'" class="qb-full">
        <app-store-data-manual [data]="parentData" (sendDataEvent)="receiveDataFromChild($event)"></app-store-data-manual>
      </div>
    </div>
  </div> -->

  <router-outlet></router-outlet>