<div class="qb-d-flex qb-jc-space-between qb-header-wrapper">
  <app-breadcrumb></app-breadcrumb>
  <div class="primary-button" (click)="toPreviousPage()">
    <div>Back</div>
  </div>
</div>
<div class="qb-main">
  <div class="qb-d-flex" style="flex-direction: row">
    <div class="qb-store-details-customer" *ngIf="step === 1">
      <div class="qb-header">
        <div class="qb-store-info">
          {{ "CUSTOMER_PAGE.ADD_NEW_CUSTOMER" | i18nTranslate }}
        </div>
      </div>
      <div class="qb-paragraph">
        {{ "CUSTOMER_PAGE.FILL_IN_DATA" | i18nTranslate }}
      </div>
      <div class="qb-line-wrapper">
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="qb-label">
            {{ "CUSTOMER_PAGE.NAME" | i18nTranslate
            }}<span class="qb-imp-color">*</span>
          </div>
          <div
            class="qb-input-field"
            [ngClass]="{
              error: submit === true && name.length === 0 && errorTextName
            }"
          >
            <input
              class="qb-input"
              (input)="getErrorTextName($event.target.value)"
              type="text"
              placeholder="Customer Name"
              [(ngModel)]="name"
            />
          </div>
          <div *ngIf="errorTextName && name.length > 0" class="qb-error-text">
            {{ "CUSTOMER_PAGE.ENTER_VALID_NAME" | i18nTranslate }}
          </div>
          <div *ngIf="submit === true && name.length === 0" class="qb-error-text">
            {{ "CUSTOMER_PAGE.ENTER_NAME" | i18nTranslate }}
          </div>
        </div>
  
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="qb-label">
            {{ "CUSTOMER_PAGE.DATE_OF_BIRTH" | i18nTranslate }}
          </div>
          <div class="formDiv-customer">
            <input
              style="margin-left: 5%"
              [(ngModel)]="dateOfBirth"
              class="formInput fs08-rem"
              matInput
              [matDatepicker]="picker1"
              placeholder="Choose date of Birth"
              [max]="startDateMinValue"
              readonly
              [ngStyle]="dateOfBirth ? { opacity: 0, width: '0px' } : null"
            />
            <div class="formInput fs08-rem" *ngIf="dateOfBirth">
              {{ dateOfBirth | date : getDateFormat() }}
            </div>
            <mat-datepicker-toggle matSuffix [for]="picker1">
            </mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>
        </div>
  
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="qb-label">{{ "CUSTOMER_PAGE.GENDER" | i18nTranslate }}</div>
          <mat-select
            placeholder="{{ 'CUSTOMER_PAGE.SELECT_GENDER' | i18nTranslate }}"
            [(ngModel)]="gender"
            class="cust-select"
          >
            <mat-option *ngFor="let gender of genderList" [value]="gender">
              {{ gender }}
            </mat-option>
          </mat-select>
        </div>
        
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="qb-d-flex qb-fd-coloumn">
            <div class="qb-label">
              {{ "CUSTOMER_PAGE.EMAIL" | i18nTranslate
              }}<span class="qb-imp-color">*</span>
            </div>
            <div class="qb-input-field">
              <input
                class="qb-input"
                type="text"
                placeholder="customername@email.com"
                (input)="getErrorTextEmail($event.target.value)"
                [(ngModel)]="email"
              />
            </div>
            <div *ngIf="emailErrorText?.length > 0" class="qb-error-text">
              {{ emailErrorText }}
            </div>
          </div>
          <div class="qb-d-flex qb-fd-coloumn qb-p-relative">
            <div class="qb-label margin-top-30">
              Customer Phone Number<span class="qb-imp-color">*</span>
            </div>
            <div class="or-container qb-position-absolute">OR</div>
            <div class="qb-d-flex">
              <!-- <div class="qb-d-flex qb-jc-space-between ph-code">
                <div class="qb-d-flex qb-ai-center"></div>
              </div> -->
              <div class="rp-name-inp-container-2 max-width-100">
                +{{ countryCode }}
                <input
                  class="rp-name-inp"
                  type="number"
                  [placeholder]="phoneNumberPlaceHolder"
                  [(ngModel)]="phoneNumber"
                  [maxlength]="phoneNumberLength"
                  (input)="getErrorTextNumber($event.target.value)"
                  (keydown)="numberInputHandler($event, phoneNumberLength)"
                  #inp3
                />
                <div class="inp-text-left">
                  {{ inp3.value.length }}/{{ inp3.getAttribute("maxlength") }}
                </div>
              </div>
            </div>
            <div *ngIf="phoneNumberErrorText?.length > 0" class="qb-error-text">
              {{ phoneNumberErrorText }}
            </div>
          </div>
        </div>
      </div>   
      <div class="bottom-button-container">
          <button
          class="primary-button"
          (click)="!loading ? customerSignup() : null"
          [disabled]="checkIfDisabled()"
        >
          <span *ngIf="!loading || (errorTextEmail && errorTextNumber)">Create Customer</span>
          <span
            *ngIf="loading && (!errorTextName && (!errorTextEmail || !errorTextNumber))"
            style="
              display: flex;
              justify-content: center;
              width: 95px;
              height: 100%;
              align-items: center;
            "
          >
            <mat-spinner diameter="25" color="accent"></mat-spinner>
          </span>
        </button>
        <!-- <button
          class="primary-button"
          (click)="!loading ? generateOtp(false) : null"
          [disabled]="errorTextEmail || errorTextName"
        >
          <span *ngIf="!loading || errorTextEmail">Send Email OTP</span>
          <span
            *ngIf="loading && !errorTextEmail && !errorTextName"
            style="
              display: flex;
              justify-content: center;
              width: 95px;
              height: 100%;
              align-items: center;
            "
          >
            <mat-spinner diameter="25" color="accent"></mat-spinner>
          </span>
        </button> -->
        <!-- <button
          class="primary-button"
          (click)="!loading ? generateOtp(true) : null"
          [disabled]="errorTextNumber || errorTextName"
        >
          <span *ngIf="!loading || errorTextNumber">Send Mobile OTP</span>
          <span
            *ngIf="loading && !errorTextNumber && !errorTextName"
            style="
              display: flex;
              justify-content: center;
              width: 95px;
              height: 100%;
              align-items: center;
            "
          >
            <mat-spinner diameter="25" color="accent"></mat-spinner>
          </span>
        </button> -->
      </div>
    </div>
    <!-- <div class="qb-store-details-customer" *ngIf="step === 2">
      <div class="qb-label">Verify customer OTP</div>
      <div class="qb-paragraph">Enter OTP to add as customer.</div>
      <div class="qb-input-field" style="width: 50%;">
        <input
          class="qb-input"
          type="text"
          placeholder="****"
          [(ngModel)]="otp"
          maxlength="4"
          (input)="getErrorOTP($event.target.value)"
        />
      </div>
      <div *ngIf="submitOTP === true && otp.length !== 4" class="qb-error-text">
        {{ "CUSTOMER_PAGE.PLEASE_ENTER_OTP" | i18nTranslate }}
      </div>
      <div
        *ngIf="submitOTP === true && otpError.length !== 0"
        class="qb-error-text"
      >
        {{ otpError }}
      </div>
      <div class="qb-buttons-customers">
        <div
          *ngIf="resendTime > 0"
          class="qb-d-flex qb-ai-center"
          style="color: #0183fb"
        >
          Resend OTP in {{ resendTime }}
        </div>
        <div
          *ngIf="resendTime < 0"
          class="cursor-pointer qb-d-flex qb-ai-center"
          style="text-decoration: underline; color: #0183fb"
          (click)="generateOtp(isMobileOtp)"
        >
          Resend OTP
        </div> -->
        <!-- <div class="primary-button" (click)="checkOtp()">Verify Number</div> -->
        <!-- <div class="primary-button" (click)="!loading ? checkOtp() : null">
          <span *ngIf="!loading">Verify OTP</span>
          <span
            *ngIf="loading"
            style="
              display: flex;
              justify-content: center;
              width: 95px;
              height: 100%;
              align-items: center;
            "
          >
            <mat-spinner diameter="25" color="accent"></mat-spinner>
          </span>
        </div>
      </div>
    </div> -->
    <div
      class="qb-ilustration-add-customer"
      style="display: none"
      *ngIf="step === 1"
    >
      <img
        src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/assets2/illustrations/Group+4760customer.svg"
        alt=""
        style="width: 100%"
      />
    </div>
  </div>
</div>
