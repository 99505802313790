<div class="qb-main" id="list-main">
  <div class="qb-table-content">
    <div class="qb-table-div">
      <div>
        <span class="qb-supressed-text">{{'ACQUIRE_PAGE.PROSPECT_LIST' | i18nTranslate}} | {{ length }}</span>
      </div>
      <div
        *ngIf="!loading && prospectList.length > 0"
        class="material-icons-outlined icons-primary cursor-pointer"
        (click)="openActionDiv(null)"
      >
        email
      </div>
    </div>
    <div class="qb-table-main">
      <div class="qb-table">
        <div class="qb-header qb-form-name">{{'ACQUIRE_PAGE.EMAIL_ADDRESS' | i18nTranslate}}</div>
        <div class="qb-header qb-form-name">{{'ACQUIRE_PAGE.FORM_NAME' | i18nTranslate}}</div>
        <div class="qb-header qb-added-on">{{'ACQUIRE_PAGE.ADDED_ON' | i18nTranslate}}</div>
      </div>
      <div *ngIf="loading" class="qb-spinner">
        <mat-spinner diameter="15"> </mat-spinner>
      </div>
      <div class="qb-no-data-div">
        <div class="qb-no-data" *ngIf="!loading && prospectList.length == 0">
          {{'ACQUIRE_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
        </div>
      </div>
      <div *ngIf="!loading">
        <div
          (click)="openActionDiv(customer.value.formName)"
          *ngFor="let customer of prospectList | keyvalue"
        >
          <div class="qb-email-div-list">
            <div
              matTooltip="{{ customer.value.customerEmail }}"
              class="qb-contact qb-text-overflow qb-email-overflow"
            >
              {{ customer.value.customerEmail }}
            </div>
            <div
              matTooltip="{{ customer.value.formName }}"
              class="qb-contact qb-text-overflow qb-form-name-overflow"
            >
              {{ customer.value.formName }}
            </div>
            <div class="qb-contact qb-date-overflow">
              {{ customer.value.date | date: getDateFormat("dd MMM yyyy") }} |
              {{ customer.value.time }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-paginator
    [pageIndex]="pageIndex - 1"
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="refreshPaginatedData($event)"
  >
  </mat-paginator>
</div>
<div *ngIf="actionDiv">
  <div class="qb-overlay"></div>
  <div class="qb-actions">
    <div class="qb-send-email-div">
      <div class="qb-sub-header">
        <div class="qb-send-email-text" style="text-transform: uppercase;">{{'ACQUIRE_PAGE.SEND_EMAIL' | i18nTranslate}}</div>
        <div class="qb-send-email-text" *ngIf="actionType === 'offer'">
          {{'ACQUIRE_PAGE.SEND_OFFER' | i18nTranslate}}
        </div>
        <div class="qb-send-email-text" *ngIf="actionType === 'campaign'">
          {{'ACQUIRE_PAGE.ADD_TO_CAMPAIGN' | i18nTranslate}}
        </div>
        <div class="qb-send-email-text" *ngIf="actionType === 'survey'">
          {{'ACQUIRE_PAGE.SEND_SURVEY' | i18nTranslate}}
        </div>
        <button
          class="material-icons-outlined qb-x-icon qb-clear-button"
          (click)="cancelActionDiv()"
        >
          clear
        </button>
      </div>
      <div class="qb-email-div">
        <div class="qb-email-address">
          <div
            class="qb-choose-customers-div qb-choose-customers-div2"
            id="choose-customers-div2"
          >
            <div class="qb-choose-customers-text">{{'ACQUIRE_PAGE.EMAIL_ADDRESS' | i18nTranslate}}</div>
            <div
              class="qb-input-div qb-inp"
              id="customers-input"
              (click)="selectCustomers(); displayNotificationPopover($event)"
            >
              <div class="qb-input-text" *ngIf="selectedCustomers.length == 0">
                {{'ACQUIRE_PAGE.SELECT' | i18nTranslate}}
              </div>
              <div class="qb-input-text" *ngIf="selectedCustomers.length == 1">
                {{ selectedCustomerEmail }}
              </div>
              <div class="qb-input-text" *ngIf="selectedCustomers.length > 1">
                {{ totalCustomersSelected }} {{'ACQUIRE_PAGE.CUSTOMERS_SELECTED' | i18nTranslate}}
              </div>
              <div class="qb-arrow-div">
                <span class="material-icons icons-primary qb-explore">
                  expand_more
                </span>
              </div>
            </div>
            <div *ngIf="customersError" class="qb-customers-error">
              {{'ACQUIRE_PAGE.SELECT_CUSTOMER_REQ' | i18nTranslate}}
            </div>
            <div class="qb-dropdown-div" *ngIf="showSelectCustomersDiv">
              <div class="qb-search-div">
                <div class="qb-full-width">
                  <input
                    #search
                    type="text"
                    placeholder="Search Customer"
                    class="qb-search-customer-input"
                    (input)="searchCustomers($event.target.value)"
                    [(ngModel)]="searchedValue"
                  />
                </div>
                <span
                  class="material-icons icons-primary qb-search"
                  *ngIf="searchedValue.length == 0"
                >
                  search
                </span>
                <span
                  class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
                  *ngIf="searchedValue.length > 0"
                  (click)="searchedValue = ''; searchCustomers(searchedValue)"
                >
                  close
                </span>
              </div>
              <div class="qb-customers-div" (scroll)="onScroll($event)">
                <div
                  class="qb-customers qb-flex"
                  [ngClass]="{ active: selectedCustomers.indexOf('All') != -1 }"
                >
                  <mat-checkbox
                    class="qb-mat-checkbox"
                    (change)="addCustomer('All')"
                    [checked]="selectedCustomers.indexOf('All') != -1"
                  >
                  </mat-checkbox>
                  <div class="qb-customer-email" (click)="addCustomer('All')">
                    All
                  </div>
                </div>
                <div *ngIf="searching.length == 0">
                  <div *ngFor="let customer of prospectListEmails | keyvalue">
                    <div
                      class="qb-customers qb-flex"
                      [ngClass]="{
                        active:
                          selectedCustomers.indexOf(
                            customer.value.customerId
                          ) != -1
                      }"
                    >
                      <mat-checkbox
                        class="qb-mat-checkbox"
                        (change)="addCustomer(customer.value.customerId)"
                        [checked]="
                          selectedCustomers.indexOf(
                            customer.value.customerId
                          ) != -1
                        "
                      >
                      </mat-checkbox>
                      <div
                        class="qb-customer-email"
                        (click)="addCustomer(customer.value.customerId)"
                      >
                        {{ customer.value.customerEmail }}
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="(searchedEmails | json) != '{}'">
                  <div *ngFor="let customer of searchedEmails | keyvalue">
                    <div
                      class="qb-customers qb-flex"
                      [ngClass]="{
                        active:
                          selectedCustomers.indexOf(
                            customer.value.customerId
                          ) != -1
                      }"
                    >
                      <mat-checkbox
                        class="qb-mat-checkbox"
                        (change)="addCustomer(customer.value.customerId)"
                        [checked]="
                          selectedCustomers.indexOf(
                            customer.value.customerId
                          ) != -1
                        "
                      >
                      </mat-checkbox>
                      <div
                        class="qb-customer-email"
                        (click)="addCustomer(customer.value.customerId)"
                      >
                        {{ customer.value.customerEmail }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="qb-buttons qb-b">
                <div
                  class="qb-cancel-button secondary-button-border"
                  (click)="cancelSelectCustomers()"
                >
                  <div class="qb-cancel-text">{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</div>
                </div>
                <div class="qb-save-button primary-button" (click)="save()">
                  <div class="qb-save-text">{{'ACQUIRE_PAGE.SAVE' | i18nTranslate}}</div>
                </div>
              </div>
            </div>
            <div class="qb-overlay-dropdown" *ngIf="showSelectCustomersDiv" (click)="globalClick()"></div>
          </div>
        </div>
        <div class="qb-choose-customers-text qb-email-subject">
          {{'ACQUIRE_PAGE.EMAIL_SUBJECT' | i18nTranslate}}
        </div>
        <div class="qb-input-div email-subject-div" id="email-subject">
          <input
            class="qb-input-text"
            style="color: #000;"
            [(ngModel)]="subject"
            (input)="this.subjectError = false"
          />
        </div>
        <div *ngIf="subjectError" class="qb-subject-error">
          {{'ACQUIRE_PAGE.PLEASE_ENTER_EMAIL_SUBJECT' | i18nTranslate}}
        </div>
        <div
          class="qb-attach-template-button qb-email-attach-button"
          (click)="attachTemplate()"
        >
          <span class="material-icons icons-primary qb-attach">
            attach_file
          </span>

          <div *ngIf="templateName.length == 0" class="qb-attach-template-text">
            {{'ACQUIRE_PAGE.ATTACT_TEMPLATE' | i18nTranslate}}
          </div>
          <div
            *ngIf="templateName.length == 0 && actionType === 'offer'"
            class="qb-attach-template-text qb-email-attach-template"
          >
            {{'ACQUIRE_PAGE.ATTACH_OFFER' | i18nTranslate}}
          </div>
          <div
            *ngIf="templateName.length == 0 && actionType === 'survey'"
            class="qb-attach-template-text qb-email-attach-template"
          >
            {{'ACQUIRE_PAGE.ATTACT_SURVEY_TEMPLATE' | i18nTranslate}}
          </div>
          <div
            *ngIf="templateName.length > 0"
            class="qb-attach-template-text qb-email-attach-template"
          >
            {{'ACQUIRE_PAGE.REPLACE_TEMPLATE' | i18nTranslate}}
          </div>
        </div>
        <div *ngIf="selectTemplateError" class="qb-subject-error">
          {{'ACQUIRE_PAGE.PLEACE_ATTACT_TEMPLATE' | i18nTranslate}}
        </div>
        <div
          class="qb-flex"
          *ngIf="!showSelectCustomersDiv && templateName.length > 0"
        >
          <span class="material-icons-outlined qb-attach-file">
            attach_file
          </span>
          <div class="qb-select-template-name" (click)="showPreview()">
            {{ templateName }}
          </div>
        </div>
      </div>
      <div class="qb-email-div" *ngIf="actionType === 'campaign'">
        <div class="qb-select-campaign">
          {{'ACQUIRE_PAGE.SELECT_CAMPAIGN_TO_ADD' | i18nTranslate}}
        </div>
        <div *ngIf="campaignsLoading" class="qb-spinner2">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
        <div class="qb-campaigns">
          <div *ngFor="let campaign of campaigns">
            <mat-checkbox
              class="qb-example-margin"
              (click)="selectCampaign(campaign['id'], $event)"
            >
              {{ campaign["strategyName"] }}</mat-checkbox
            >
          </div>
        </div>
      </div>
      <div class="qb-button-end" data-widget-name="FORMS">
        <div
          class="qb-cancel-button qb-template-cancel-button"
          (click)="cancelActionDiv()"
          data-button-name="Cancel: Survey Alert Action"
        >
          <div class="qb-cancel-text">{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</div>
        </div>
        <div class="qb-save-button" (click)="send()">
          <div
            class="qb-save-text qb-send-email-button-text"
            data-button-name="Confirm: Send Email"
          >
            {{'ACQUIRE_PAGE.SEND_EMAIL' | i18nTranslate}}
          </div>
          <div
            class="qb-save-text qb-send-email-button-text"
            *ngIf="actionType === 'offer'"
            data-button-name="Confirm: Send Offer"
          >
            {{'ACQUIRE_PAGE.SEND_OFFER' | i18nTranslate}}
          </div>
          <div
            class="qb-save-text"
            *ngIf="actionType === 'campaign'"
            data-button-name="Confirm: Add to Campaign"
          >
            {{'ACQUIRE_PAGE.ADD' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
    <div
      class="qb-attach-email-template-div"
      id="attachEmailTemplateDiv"
      *ngIf="templateDiv"
    >
      <div class="qb-sub-header">
        <div class="qb-send-email-text">{{'ACQUIRE_PAGE.EMAIL_TEMPLATES' | i18nTranslate}}</div>
        <div class="qb-send-email-text" *ngIf="actionType === 'offer'">
          {{'ACQUIRE_PAGE.OFFER_TEMPLATES' | i18nTranslate}}
        </div>
        <div class="qb-send-email-text" *ngIf="actionType === 'survey'">
          {{'ACQUIRE_PAGE.SURVEY_TEMPLATES' | i18nTranslate}}
        </div>
        <button
          class="material-icons-outlined qb-x-icon qb-clear"
          (click)="cancelTemplateDiv()"
        >
          clear
        </button>
      </div>
      <div class="qb-create-template">
        <div class="qb-create-new-template">{{'ACQUIRE_PAGE.CREATE_NEW_TEMPLATE' | i18nTranslate}}</div>
        <div class="qb-add-button" (click)="createEmailTemplate(-1)">
          <span class="material-icons qb-add cursor-pointer"> add </span>
        </div>
      </div>
      <div
        class="qb-choose-customers-div qb-no-border"
        [ngClass]="{
          'qb-bottom-div': actionType === 'email',
          'qb-bottom-div2': actionType === 'offer'
        }"
      >
        <div class="qb-choose-customers-div3">
          <div
            class="qb-choose-customers-text qb-choose-template"
            *ngIf="actionType != 'campagin'"
          >
            {{'ACQUIRE_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}
          </div>
          <div class="qb-campaign-list">
            <div class="qb-campaign-list-rectangle">
              <div class="qb-campaign-list-search-bar">
                <input
                  #search
                  type="text"
                  class="qb-input-text-search"
                  [(ngModel)]="searchValue"
                  (input)="searchTemplates($event.target.value)"
                  placeholder="Search Template"
                />

                <span
                  class="material-icons icons-primary"
                  *ngIf="searchValue.length == 0"
                >
                  search
                </span>
                <span
                  class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
                  *ngIf="searchValue.length > 0"
                  (click)="searchValue = ''; searchTemplates(searchValue)"
                >
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="qb-offer-div">
          <div *ngIf="actionType === 'offer'" class="qb-offer">
            <div class="qb-campaign-list-rectangle">
              <div class="qb-campaign-list-search-bar">
                <input
                  class="qb-offer-search"
                  *ngIf="actionType === 'offer'"
                  #search
                  type="text"
                  [(ngModel)]="searchValue"
                  class="qb-input-text"
                  (input)="searchTemplates($event.target.value)"
                  placeholder="Search Offers"
                />
                <img
                  src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/home-screen/search.svg"
                  class="qb-offer-image"
                  (click)="showSearch()"
                />
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="actionType === 'offer'" class="qb-offer-border">
          <div class="qb-channel-div">
            <div class="qb-select-format">{{'ACQUIRE_PAGE.SELECT_CHANNEL_FORMAT' | i18nTranslate}}</div>
            <div>
              <div class="qb-channel">
                <div class="qb-input">
                  <input
                    type="checkbox"
                    name="email"
                    [checked]="channelEmail"
                    (change)="changeChannel('email')"
                    class="qb-input-checked"
                    checked
                  />
                  <div class="qb-email">{{'ACQUIRE_PAGE.EMAIL' | i18nTranslate}}</div>
                </div>
                <div class="qb-filter-div">
                  <input
                    type="checkbox"
                    name="sms"
                    [checked]="channelSMS"
                    (change)="changeChannel('sms')"
                    class="qb-input-checked"
                  />
                  <div class="qb-email" *ngIf="(validScreens | screenConfig: 18)">{{'ACQUIRE_PAGE.SMS' | i18nTranslate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="qb-filter-div">
            <div class="qb-i-div" type="button">
              <div class="qb-i">i</div>
            </div>
            <div class="qb-i-text">
              {{'ACQUIRE_PAGE.SMS_REGULATION_WARNING' | i18nTranslate}}
            </div>
          </div>
        </div>
        <div
          *ngIf="emailTemplatesLoading && offerTemplatesLoading"
          class="qb-spinner2"
        >
          <mat-spinner diameter="25"></mat-spinner>
        </div>
        <div class="qb-templates-div">
          <div class="qb-templates">
            <div *ngFor="let temp of templates">
              <div
                [style.border]="
                  templateID == temp['id'] && templateSelected
                    ? '3px solid #4abb5975'
                    : 'none'
                "
                class="qb-template-div"
                (click)="selectTemplate(temp['id'], temp['layoutName'], event)"
                [id]="temp['id']"
              >
                <div class="qb-image">
                  <img
                    class="qb-image-div"
                    src="{{ temp['snapshotUrl'] }}"
                    [id]="'img' + temp['id']"
                  />
                </div>
                <div class="qb-template-name">{{ temp["layoutName"] }}</div>
              </div>
            </div>
          </div>
          <div class="qb-templates2" *ngIf="actionType === 'offer'">
            <div *ngFor="let temp of templatesArray">
              <mat-checkbox
                class="qb-custom"
                [checked]="templateID == temp['id']"
                (click)="selectTemplate(temp['id'], temp['offerName'], event)"
              >
                <div class="qb-template-div2">
                  <div class="qb-choose-customers-div3">
                    <div class="qb-template-name-offers qb-break-word">
                      {{ temp["offerName"] }}
                    </div>
                    <div
                      class="qb-template-name-offers qb-preview cursor-pointer"
                      (click)="previewOfferSummary(temp['id'])"
                    >
                      {{'ACQUIRE_PAGE.PREVIEW' | i18nTranslate}}
                    </div>
                  </div>
                </div>
              </mat-checkbox>
            </div>
            <div
              *ngIf="templatesArray.length == 0 && !offerTemplatesLoading"
              class="qb-no-templates"
            >
              {{'ACQUIRE_PAGE.NO_TEMPLATE_TO_DISPLAY' | i18nTranslate}}
            </div>
          </div>
          <div class="qb-templates2" *ngIf="actionType === 'survey'">
            <div *ngFor="let temp of surveys">
              <mat-checkbox
                class="qb-custom"
                [checked]="templateID == temp['id']"
                (click)="selectTemplate(temp['id'], temp['layoutName'], $event)"
              >
                <div class="qb-template-div2">
                  <div class="qb-choose-customers-div3">
                    <div class="qb-template-name-offers qb-break-word">
                      {{ temp["layoutName"] }}
                    </div>
                    <div
                      class="qb-template-name-offers qb-preview cursor-pointer"
                      (click)="previewOfferSummary(temp['id'])"
                    >
                      {{'ACQUIRE_PAGE.PREVIEW' | i18nTranslate}}
                    </div>
                  </div>
                </div>
              </mat-checkbox>
            </div>
            <div
              *ngIf="templatesArray.length == 0 && !offerTemplatesLoading"
              class="qb-no-templates"
            >
              {{'ACQUIRE_PAGE.NO_TEMPLATE_TO_DISPLAY' | i18nTranslate}}
            </div>
          </div>
        </div>
      </div>
      <div class="qb-button-end">
        <div
          class="qb-cancel-button qb-template-cancel-button"
          (click)="cancelTemplateDiv()"
        >
          <div class="qb-cancel-text">{{'ACQUIRE_PAGE.CANCEL' | i18nTranslate}}</div>
        </div>
        <button class="qb-save-button" (click)="confirm()">
          <div class="qb-save-text qb-confirm-text">{{'ACQUIRE_PAGE.CONFIRM' | i18nTranslate}}</div>
        </button>
      </div>
    </div>
  </div>
</div>
