import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bee-editor-product-image-config-dialog',
  templateUrl: './bee-editor-product-image-config-dialog.component.html',
  styleUrls: ['./bee-editor-product-image-config-dialog.component.css']
})
export class BeeEditorProductImageConfigDialogComponent implements AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<BeeEditorProductImageConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    console.log(this.data);
    this.imageTags = this.data.filter(item => item.name.indexOf('Image URL') !== -1);

  }
  init: boolean = false;
  selectedType = 0;
  imageTags=[];
  // ngOnInit() {
  //   this.init = true;
  // }
  ngAfterViewInit(){
    this.init = true;

  }

  selectType(type: number) {
    if (type == 0) {
      this.selectedType = 0;
    } else if (type == 1) {
      this.selectedType = 1;
    } else if (type == 2) {
      this.selectedType = 2;
    } else {
    }
  }

  submit() {
    this.dialogRef.close();
  }

  copyText(val: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

}
