import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as d3 from "d3";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SidenavOperationService } from "src/app/core/services/sidenav-operation.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { EChartsOption } from 'echarts';

@Component({
  selector: "app-survey-dashboard",
  templateUrl: "./survey-dashboard.component.html",
  styleUrls: ["./survey-dashboard.component.css"],
  encapsulation: ViewEncapsulation.None ,
})
export class SurveyDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  netPromoterScore;
  promoters;
  passives;
  diteractors;
  feedbacks = [];
  timeSeriesData = [];
  dashboardData = null;
  thumbsup: boolean = true;

  heatmapvaluesrefreshed: boolean = false;

  dummyvalue = 54;
  NPS_MM_Period = "YEARLY";

  NPSStatus;
  NPSMMStatus;
  LFStatus;
  validWidgets = null;
  graphData = null;
  totalCountNPS = 0;

  topicwiseCatogory = [];
  topicwiseKey = [{}];
  topicwise = null;
  nodataInTopicWise = null;
  subtopic = null;
  selectedSubtopic = {
    name: "global",
    positive: 0,
    negative: 0,
    neutral: 0,
    total: 0,
    count: 0,
    subTopics: [],
  };
  topicdataLoading = false;
  topicdonutloading = false;
  subtopicdataLoading = false;
  responsesubTopicCount;
  responsesubTopic;
  topicGraphData = [];

  linegraphdata = [];
  lastdata;
  barChartData;
  chartheight;
  filterViewBy = "Months";
  YLegend;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileView;
  totalValue = 0;
  isAdmin = false;
  storeFilterList = [{
    storeName:'None',
    qid: null,
  }];
  selectedStore;

  npsChartOptions: EChartsOption;
  topicGraphOptions: EChartsOption;
  timeSeriesOptions: EChartsOption;
  barchartOptions : EChartsOption;

  @ViewChild("heatMapContainer") heatMapContainer: ElementRef;
  @ViewChild("linechartContainer") linechartContainer: ElementRef;
  constructor(
    private apiCall: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private _WidgetConfigService: WidgetConfigService,
    private _SidenavOperationService: SidenavOperationService,
    private tokenStorage : TokenStorageService
  ) {}
  ngOnDestroy() {
  }
  ngAfterViewInit(): void {
    this.initMethods();
  }
  ngOnInit() {
    if(this.tokenStorage.getqId() == 'null'){
      this.isAdmin = true;
      this.apiCall.getListStore().subscribe(
        (response) => {
          this.storeFilterList = [{
            storeName:'None',
            qid: null,
          }];
          if(response['body']) {
            response['body'].forEach((storeData)=>{
              if(storeData.qid) {
                this.storeFilterList.push({
                  'qid':storeData.qid,
                  'storeName':storeData.storeName,
                });
              }
            });
            this.selectedStore = this.storeFilterList[0];
          }
        },
        (err) => {
        }
      );
    }
  }

  dashboardLoading = false;
  initMethods(storeid?) {
    this.dashboardLoading = true;
    this.chartheight = this.linechartContainer?.nativeElement.offsetWidth - 60;
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.getWidgets();
    this.getTopicDivisionDetails(storeid);
    this.apiCall.getOverallSurveydashboard(storeid).subscribe((response) => {
      if (response["message"] === "SUCCESS") {
        let dashboardData = response["body"];
        this.dashboardData = dashboardData;
        this.dashboardLoading = false;

        if (dashboardData["npsScore"] != "NaN") {
          if(dashboardData["npsScore"] != 0){
            this.netPromoterScore = dashboardData["npsScore"].toFixed(2);
          }
          else{
            this.netPromoterScore = 0;
          }
        } else {
          this.NPSStatus = false;
          this.netPromoterScore = 0;
        }
        this.thumbsup = dashboardData["likeFlag"];
        this.heatmampData(dashboardData);
        // let interval = setInterval(() => {
        //   if (this.heatMapContainer) {
        //     clearInterval(interval);
        //     this.heatmampData(dashboardData);
        //     if( this.route.snapshot.queryParamMap.get('scheduled')&& document.getElementById('surveyList')){
        //       document.getElementById('surveyList').scrollIntoView({ behavior: "smooth", block: "center" });
        //      }
        //   }
        // }, 100);

        var data = [];
        this.totalCountNPS =
          dashboardData["promoters"] +
          dashboardData["passives"] +
          dashboardData["detractors"];

        data.push({
          name: "Promoters",
          count: dashboardData["promoters"],
          color: "#4AD991",
        });
        data.push({
          name: "Passives",
          count: dashboardData["passives"],
          color: "#FEC53D",
        });
        data.push({
          name: "Detractors",
          count: dashboardData["detractors"],
          color: "#FF6565",
        });
        this.graphData = data;
        this.getTopicTimeSeries(
          "months",
          "update",
          this.selectedSubtopic.name
        );
        this.setNPSChartOptions();
        this.setTopicGraphOptions();
        this.getTimeSeriesData(dashboardData["categoryData"]);
        this.setChartOptions();
        this.setBarChartOptions();
        // this.getFeedbackData(dashboardData["latestCustomerFeedbacks"]);
      }
    });
    this.chartheight = this.linechartContainer?.nativeElement.offsetWidth - 60;

  }

  setNPSChartOptions() {
    this.npsChartOptions = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['50%', '60%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.graphData.map((item) => ({
              value: item.count,
              name: item.name,
              itemStyle: {
                  color: item.color
              }
          }))
        }
      ]
    };
}

setTopicGraphOptions() {
  this.topicGraphOptions = {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        type: 'pie',
        center: ['50%', '50%'],
        radius: ['50%', '60%'], 
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: this.topicGraphData.map(item => ({
          value: item.count,
          name: item.name,
          itemStyle: {
            color: item.color
          }
        }))
      }
    ]
  };
}

setChartOptions(): void {

  const formattedDates = this.timeSeriesData.map(item => {
    const [month, year] = item.date.split('/'); 
    const date = new Date(Number(year), Number(month) - 1); 
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' }; 
    return date.toLocaleDateString('en-US', options); 
  });


  this.timeSeriesOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: '0%',
      data: ['PROMOTERS', 'PASSIVES', 'DETRACTORS']
    },
    xAxis: [{
      type: 'category',
      data: formattedDates,
      axisLabel: {
        interval: 0, 
      },
      name: "Months",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [15, 0, 0, 0]
      }
    }],
    yAxis: {
      type: 'value',
      name: 'Number of customers',
      nameLocation: "middle",
      nameTextStyle: {
      padding: [0, 0, 30, 0]
      }
    },
    series: [
      {
        name: 'PROMOTERS',
        type: 'bar',
        data: this.timeSeriesData.map(item => item.PROMOTERS || 0), // default to 0 if undefined
        itemStyle: {
          color: '#4abb59'
        },
        barWidth: '20%',
        barGap: '0%' 
      },
      {
        name: 'PASSIVES',
        type: 'bar',
        data: this.timeSeriesData.map(item => item.PASSIVES || 0), 
        itemStyle: {
          color: '#edbd22'
        },
        barWidth: '20%', 
        barGap: '0%' 
      },
      {
        name: 'DETRACTORS',
        type: 'bar',
        data: this.timeSeriesData.map(item => item.DETRACTORS || 0), 
        itemStyle: {
          color: '#f84737'
        },
        barWidth: '20%',
        barGap: '0%' 
      }
    ],
  };
}

setBarChartOptions() {
  const data = this.barChartData;
  const formattedDates = data?.map(d => {
    const [year, month] = d.orginalDate.split('-');
    const date = new Date(Number(year), Number(month) - 1);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }); 
  });
  console.log(data);
  this.barchartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}'
    },
    xAxis: {
      name : 'Months',
      type: 'category',
      data: formattedDates,
      nameLocation: "middle",
      nameTextStyle: {
        padding: [30, 0, 0, 0]
      }
    },
    yAxis: {
      type: 'value',
      name: this.selectedSubtopic.name,
      nameLocation: "middle",
      nameTextStyle: {
      padding: [0, 0, 20, 0]
      }
    },
    series: [{
      data: data.map(d => d.value),
      type: 'bar',
      color: '#4880FF'
    }]
  };
}



  

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  getDashboardForStore() {
    this.initMethods(this.selectedStore.qid);
  }

  getTimeSeriesData(data) {
    let finalData = [];
    for (let year in data) {
      for (let month in data[year]) {
        let monthlyData: any = {};
        monthlyData["date"] = +month + "/" + year;
        monthlyData[data[year][month][0]["category"]] =
          data[year][month][0]["count"];
        monthlyData[data[year][month][1]["category"]] =
          data[year][month][1]["count"];
        monthlyData[data[year][month][2]["category"]] =
          data[year][month][2]["count"];
        finalData.push(monthlyData);
      }
    }
    this.timeSeriesData = finalData;
    for (let i=0;i<this.timeSeriesData.length;i++){
      this.totalValue+=this.timeSeriesData[i]['PROMOTERS']+this.timeSeriesData[i]['PASSIVES']+this.timeSeriesData[i]['DETRACTORS'];
    }
  }

  getFeedbackData(data) {
    for (let key in data) {
      let feedback: any = {};
      feedback["cName"] = key;
      feedback["data"] = data[key];
      this.feedbacks.push(feedback);
    }
    if (this.feedbacks.length < 1) {
      this.LFStatus = false;
    } else {
      this.LFStatus = true;
    }
  }

  heatmampData(dashboardData) {
    // let heatMapDivWidth = this.heatMapContainer.nativeElement.offsetWidth;
    this.heatmapvaluesrefreshed = false;
    let totalValue =
      dashboardData["promoters"] +
      dashboardData["passives"] +
      dashboardData["detractors"];
    this.promoters = ((dashboardData["promoters"] / totalValue) * 100).toFixed(
      0
    );
    this.passives = ((dashboardData["passives"] / totalValue) * 100).toFixed(0);
    this.diteractors = (
      (dashboardData["detractors"] / totalValue) *
      100
    ).toFixed(0);
    // document.getElementById('promoter').style.width = (heatMapDivWidth / 100) * this.promoters + 'px';
    // document.getElementById('passive').style.width = (heatMapDivWidth / 100) * this.passives + 'px';
    // document.getElementById('deteractor').style.width = (heatMapDivWidth / 100) * this.diteractors + 'px';
    this.heatmapvaluesrefreshed = true;
  }

  createStandardTemplate() {
    this.router.navigate(["app/surveys/add-survey", { id: null }], {});
  }

  topicsLoading = false;
  getTopicDivisionDetails(storeid?) {
    var topicDivision = [];
    var topicblock;
    this.topicsLoading = true;
    this.topicdataLoading = true;
    setTimeout(()=>{
      this.apiCall.getTopicDivision(storeid).subscribe((result) => {
        //  result={"message":"SUCCESS","body":"[{\"sentiment\":{\"NEGATIVE\":4,\"POSITVE\":4,\"NEUTRAL\":2},\"topic\":\"Customer Service\"},{\"sentiment\":{\"NEGATIVE\":0,\"POSITVE\":1,\"NEUTRAL\":2},\"topic\":\"Payment\"},{\"sentiment\":{\"NEGATIVE\":0,\"POSITVE\":1,\"NEUTRAL\":0},\"topic\":\"Delivery Service\"},{\"sentiment\":{\"NEGATIVE\":3,\"POSITVE\":8,\"NEUTRAL\":7},\"topic\":\"global\"},{\"sentiment\":{\"NEGATIVE\":1,\"POSITVE\":5,\"NEUTRAL\":1},\"topic\":\"quality\"}]"}
        this.topicwise = result["body"];
        var body;
        // result["body"]='[{"sentiment":{"NEGATIVE":3,"POSITVE":3,"NEUTRAL":1},"topic":"Customer Service"},{"sentiment":{"NEGATIVE":0,"POSITVE":1,"NEUTRAL":0},"topic":"Payment"},{"sentiment":{"NEGATIVE":3,"POSITVE":7,"NEUTRAL":4},"topic":"global"},{"sentiment":{"NEGATIVE":1,"POSITVE":3,"NEUTRAL":1},"topic":"quality"}]'
        body = result["body"];
        if (body.length == 0) {
          this.nodataInTopicWise = false;
        } else {
          this.nodataInTopicWise = true;
        }
        var i;
  
        this.topicwiseCatogory = [];
        var topicDivision1 = [];
  
        for (i = 0; i < body.length; i++) {
          topicDivision = [];
          topicDivision["topic"] = body[i]["topic"];
          var d = topicDivision["topic"];
          var total = 0;
          total =
            body[i]["sentiment"]["POSITVE"] +
            body[i]["sentiment"]["NEUTRAL"] +
            body[i]["sentiment"]["NEGATIVE"];
          topicDivision["positive"] = body[i]["sentiment"]["POSITVE"] / total;
          topicDivision["neutral"] = body[i]["sentiment"]["NEUTRAL"] / total;
          topicDivision["negative"] = body[i]["sentiment"]["NEGATIVE"] / total;
          topicDivision["total"] = total;
  
          this.topicwiseCatogory.push(topicDivision);
        }
        this.topicdataLoading = false;
        this.getTopicCount(storeid);
        
      });
    },1000);
    
  }

  subtopicChange(val) {
    this.topicdonutloading = true;
    this.selectedSubtopic.name = val;
    this.YLegend = val;
    var i;
    this.getTopicTimeSeries("months", "update", val);
    this.topicGraphData = [];
    this.topicdataLoading = true;
    for (i = 0; i < this.topicwiseCatogory.length; i++) {
      if (this.topicwiseCatogory[i]["topic"] == val) {
        this.selectedSubtopic.positive =
          this.topicwiseCatogory[i]["positive"] * 100;
        this.selectedSubtopic.neutral =
          this.topicwiseCatogory[i]["neutral"] * 100;
        this.selectedSubtopic.negative =
          this.topicwiseCatogory[i]["negative"] * 100;
        this.selectedSubtopic.total = this.topicwiseCatogory[i]["total"];

        var data = [];

        data.push({
          name: "positive",
          count: this.selectedSubtopic.positive,
          color: "#4AD991",
        });
        data.push({
          name: "neutral",
          count: this.selectedSubtopic.neutral,
          color: "#FEC53D",
        });
        data.push({
          name: "negative",
          count: this.selectedSubtopic.negative,
          color: "#FF6565",
        });
        this.topicGraphData = data;
        break;
      }
    }
    for (i = 0; i < this.responsesubTopic.length; i++) {
      if (this.responsesubTopic[i]["topic"] == val) {
        this.selectedSubtopic.count = this.responsesubTopic[i]["count"];
        if (this.responsesubTopic[i]["subTopics"].length > 0) {
          this.selectedSubtopic.subTopics =
            this.responsesubTopic[i]["subTopics"];
          break;
        } else {
          // this.selectedSubtopic.count=null;
          this.selectedSubtopic.subTopics = null;
        }
      }
    }
    this.setTopicGraphOptions();
    this.topicdataLoading = false;
    this.topicdonutloading = false;
  }

  getTopicCount(storeid?) {
    this.subtopicdataLoading = true;
    this.apiCall.getTextAnalysisTopicCount(storeid).subscribe((result) => {
      //result={"message":"SUCCESS","body":"[{\"subTopics\":[{\"count\":10,\"topic\":\"Resolution\"}],\"count\":10,\"topic\":\"Customer Service\"},{\"subTopics\":[],\"count\":3,\"topic\":\"Payment\"},{\"subTopics\":[],\"count\":1,\"topic\":\"Delivery Service\"},{\"subTopics\":[],\"count\":18,\"topic\":\"global\"},{\"subTopics\":[{\"count\":3,\"topic\":\"service\"}],\"count\":7,\"topic\":\"quality\"}]"}
      this.responsesubTopic = result["body"];
      this.responsesubTopicCount = this.responsesubTopic.length;
      //this.topicAndSubtopicdetails();
      this.subtopicdataLoading = false;
      if (
        this.responsesubTopicCount > 0 &&
        this.topicwiseCatogory[0]["topic"]
      ) {
        this.subtopicChange(this.topicwiseCatogory[0]["topic"]);
        // this.getTopicTimeSeries(
        //   "months",
        //   "update",
        //   this.topicwiseCatogory[0]["topic"]
        // );
      } else {
        this.subtopicdataLoading = false;
      }
    });
  }

  getTopicTimeSeries(type, status, topic) {
    //   d3.select("div#container").selectAll("*").remove();
    var colorset = [];
    this.apiCall.getTopicTimeSeries(type,this.selectedStore?.qid).subscribe((result) => {
      //  result={"message":"SUCCESS","body":"[{\"date\":\"2021-02-09\",\"Customer Service\":1,\"Payment\":1,\"global\":3,\"quality\":2},{\"date\":\"2021-03-05\",\"global\":1},{\"date\":\"2021-03-17\",\"Customer Service\":2,\"global\":1},{\"date\":\"2021-03-19\",\"Customer Service\":2,\"global\":5,\"quality\":1},{\"date\":\"2021-03-23\",\"Customer Service\":2},{\"date\":\"2021-03-31\",\"global\":3,\"quality\":2},{\"date\":\"2021-04-02\",\"global\":1}]"}
      // this.timesseriesLoading = false;
      // result = {
      //   message: "SUCCESS",
      //   body:
      //     '[{"date":"2020-11","global":2},{"date":"2020-12","global":0},{"date":"2021-01","global":0},{"date":"2021-02","global":1},{"date":"2021-03","global":0},{"date":"2021-04","global":6}]',
      // };
      // result=[{"date":"2020-10","NULL_TOPIC":0},{"date":"2020-11","NULL_TOPIC":0},{"date":"2020-12","Customer Service":4,"global":17,"quality":12},{"date":"2021-01","NULL_TOPIC":0},{"date":"2021-02","NULL_TOPIC":0},{"date":"2021-03","NULL_TOPIC":0},{"date":"2021-04","NULL_TOPIC":0}]
      var data = result["body"]["data"];
      //   var x= {"data":[{"date":"2020-11","NULL_TOPIC":0},{"date":"2020-12","Customer Service":4,"global":17,"quality":12},{"date":"2021-01","NULL_TOPIC":0},{"date":"2021-02","NULL_TOPIC":0},{"date":"2021-03","NULL_TOPIC":0},{"date":"2021-04","NULL_TOPIC":0}],"topics":["Customer Service","global","quality"]}
      // data=x['data']
      var linedata = [];
      var i2 = 0;
      var totalCountDataValue = 0;
      for (i2 = 0; i2 < data.length; i2++) {
        if (data[i2][topic] == undefined) {
          linedata.push({
            name: ((data[i2]["date"])),
            value: 0,
            orginalDate:  (data[i2]["date"]),
          });
        } else {
          linedata.push({
            name: ((data[i2]["date"])),
            value: data[i2][topic],
            orginalDate: (data[i2]["date"]),
          });
          totalCountDataValue = totalCountDataValue + data[i2][topic];
        }
      }
      // this.chartheight = this.linechartContainer.nativeElement.offsetWidth - 60;

      this.barChartData = linedata;
      if (totalCountDataValue > 0) {
        this.linegraphdata = linedata;
      } else {
        d3.select("div#container").selectAll("*").remove();
        this.linegraphdata = [];
      }
      if(this.linegraphdata.length>0) {
        setTimeout(()=>{
          this.sentimenttopicgraph(linedata);
        },500);
      }
      this.setBarChartOptions();

    });
  }

  getYRange(min: number, max: number) {
    let minFloor,
      maxCeil,
      level = 1,
      diffUnit,
      ticks: number[] = [];
    while (max % level !== max) {
      level = level * 10;
    }
    level /= 10;
    minFloor = parseInt("" + min / level) * level;
    maxCeil = (parseInt("" + max / level) + 1) * level;
    diffUnit = (maxCeil - minFloor) / 4;
    // ticks.push(minFloor);
    for (let i = minFloor; i <= maxCeil; i += diffUnit) {
      ticks.push(i);
    }
    return {
      min: minFloor,
      max: maxCeil,
      ticks: ticks,
    };
  }
  svgHeight: number;
  svgWidth: number;

  sentimenttopicgraph(dataset) {
    d3.select("div#container").selectAll("*").remove();
    // set the dimensions and margins of the graph
     const margin = { top: 20, right: 20, bottom: 20, left: 45 };
    this.svgHeight =
      this.linechartContainer?.nativeElement.offsetHeight - 10;
    this.svgWidth=
      this.linechartContainer?.nativeElement.offsetWidth - 10;
    const width =
      this.svgWidth - margin.left - margin.right ;
    const height = 300;


    const parseTime = d3.timeParse("%Y-%m");
    const bisectDate = d3.bisector((d) => d.date).left;
    const formatTime = d3.timeFormat("%b");
    const formatTimeWithYear = d3.timeFormat("%b %Y");


    // set the ranges
    var x = d3.scaleBand().range([0, width]).padding(0.1);
    var y = d3.scaleLinear().range([height, 0]);

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var svg = d3
      .select("div#container")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  //get the data
  dataset.forEach(function (d) {
    if (parseTime(d.name)) {
      d.name = formatTime(parseTime(d.name));
      d.close = +d.close;
    } else {
      d.name =formatTime( parseTime(d.name));
      d.close = 0;
    }
  });

  var data = dataset;
  // format the data
  // data.forEach(function(d) {
  //   d.value = +d.sales;
  // });

  // Scale the range of the data in the domains
  x.domain(
    data.map(function (d) {
      return d.name;
    })
  );
  y.domain([
    0,
    d3.max(data, function (d) {
      return d.value;
    }),
  ]);
    let yValues = this.getYRange(
      0,
      d3.max(data, (d) => d.value)
    );
    let yMin = yValues.min;
    let yMax = yValues.max;
    let tickValues = yValues.ticks;

    let yScale = d3.scaleLinear().domain([0, yMax]).range([height, 0]);

    let yAxisTickLines = d3
      .axisLeft(yScale)
      .tickValues(tickValues)
      .tickSize(-width)
      .tickFormat("");

      var tooltip = d3.select("body").append("div").attr("class", "tooltip-dashboard");
    // append the rectangles for the bar chart
    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .style("fill", "#00BABE")
      .attr("x", function (d) {
        return x((d.name));
      })
      .attr("width", x.bandwidth())
      .attr("y", function (d) {
        return y(d.value);
      })
      .attr("height", function (d) {
        return height - y(d.value);
      })
      .on("mousemove", function (d) {
        tooltip
          .style("left", d3.event.pageX - 50 + "px")
          .style("top", d3.event.pageY - 70 + "px")
          .style("z-index", 2)
          .style("display", "inline-block")
          .html(
            "<div style='display:flex'><span>" +
            (d.name) +
              "</span><span> &nbsp;| &nbsp;</span><span style='display:flex; justify-content: center;'>" +
              d.value +
              "</span></div>"
          );
      });
      svg.on("mouseout", function (d) {
        tooltip.style("display", "none");
      });

    // add the x Axis
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // add the y Axis
    svg
      .append("g")
      .call(d3.axisLeft(y))
      .call((g) => g.select(".domain").remove());

    svg
      .append("g")
      .attr("class", "grid")
      .call(yAxisTickLines)
      .attr("color", "#c2c2c2")
      .style("stroke-dasharray", "3, 3")
      .call((g) => g.select(".domain").remove());
  }
}
