import { DatePipe, getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { ConfirmationDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { OfferSelectDoalogBoxComponent } from "src/app/shared/components/dialog-box/offer-select-doalog-box/offer-select-doalog-box.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-my-offers",
  templateUrl: "./my-offers.component.html",
  styleUrls: ["./my-offers.component.css"],
})
export class MyOffersComponent implements OnInit {
  revenueRange = "allTime";
  offersList = [];
  pageNumber = 1;
  pageSize = 10;
  offerName = "";
  timeout;
  length = 0;
  lengthGroup = 0;
  revCurrency;
  currencySymbol;
  loading = true;
  loadingGroup = true;
  tableHeadings = [
    "Offer Name",
    "Coupon Code",
    "Revenue Generated",
    "Coupon Valid Till",
  ];
  tableHeadingsGroup = [
    "Group Name",
    "Offers Used",
    "Partner Name",
    "Revenue Generated",
    "Coupon Valid Till",
    "Status"
  ]
  mainTopic = "singleOffer"
  coMarketingOffers=[];
  zero = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiCall: ApiCallService,
    private datePipe: DatePipe,
    private matDialog: MatDialog,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  ngOnInit() {
    this.getCurrencySymbol();
    this.getOffersList();
    this.getComarketingOfferTemplate();
    this.route.queryParams.subscribe((params) => {
      if (params.type) this.mainTopic = params.type;
    });
  }

  getCurrencySymbol() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }

  createNewOffer() {
    const dialog = this.matDialog.open(OfferSelectDoalogBoxComponent,{
      panelClass: "no-padding-dialog-popup",
      height: "440px",
      width: "480px",
    })
    dialog.afterClosed().subscribe((result) => {
      if(result){
        
      }
      return false;
    });
    // this.router.navigate(["/app/partnership/my-offers/new-offer"]);
  }

  refreshPaginatedData(event) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getOffersList();
  }

  getOffersList() {
    this.loading = true;
    this.apiCall
      .getPartnershipOffersList(this.pageNumber, this.pageSize, this.offerName)
      .subscribe((response) => {
        this.offersList = (response["body"])["offers"];
        this.length = (response["body"])["totalCount"];
        this.offersList.forEach((offer) => {
          offer["offerValidity"] = this.datePipe.transform(
            offer["offerValidity"],
            "dd MMM yyyy"
          );
        });
        this.loading = false;
      });
  }

  deleteOffer(id) {
    const dialogRef = this.matDialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete offer", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Do you want to delete offer?", ['POS_PAGE']),
        "data-widget": "PARTNERSHIP OFFERS",
        "data-button": "Confirm: Delete",
        "data-item": id,
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deletePartnershipOffer(id).subscribe((response) => {
          this.getOffersList();
        });
      }
    });
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageNumber = 1;
        $this.getOffersList();
      }
    }, 600);
  }
  offerTabChange(value){
    this.mainTopic = value;
  }
  getComarketingOfferTemplate(){
    this.loadingGroup = true;
    this.apiCall.getCoMarketingOffers().subscribe((response)=>{
      console.log("RESPOMNSE",response)
      this.coMarketingOffers = response['body']['comarketingOffers'];
      this.lengthGroup = this.coMarketingOffers.length;
      this.loadingGroup = false;
    })
    // this.coMarketingOffers = [
    //   {
    //     "comarkettingOffers": [
    //       {
    //         "id": 21,
    //         "offerName": "OFFER-CREATED-GROUPED",
    //         "isGrouped": true,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 21,
    //             "offerId": 11,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           },
    //           {
    //             "description": "xyz1",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere1",
    //             "status": "DISABLED",
    //             "revenue": 12344,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": 1232,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       },
    //       {
    //         "id": 211,
    //         "offerName": "OFFER-CREATED-SINGLE",
    //         "isGrouped": false,
    //         "offerList": [
    //           {
    //             "description": "xyz",
    //             "partnershipRequests": 211,
    //             "offerId": 111,
    //             "isActive": false,
    //             "reasonDisabled": "inventory over",
    //             "storeName": "storeNameHere",
    //             "status": "ACTIVE",
    //             "revenue": null,
    //             "couponValid": "21 Arpil 2023"
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
    console.log("GROUP",this.lengthGroup)
    // this.loadingGroup = false;
  }
}
