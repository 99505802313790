export class PieChartConfig {

    public colors: string[]
    constructor() {
        this.colors = [
            '#4BD762',
            '#EF5350',
            '#278ECF',
            '#FFCA1F',
            '#BDBDBD',
            '#535AD7',
            '#FF402C',
            '#83BFFF',
            '#6EDB8F',
            '#FFE366',
            '#FF9416'
        ];
    }
   
}