import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProfileUserComponent } from "./pages";
import { MyStorePreviewComponent } from "./pages/my-store-preview/my-store-preview.component";
import { EditShopDetailsComponent } from "./pages/edit-shop-details/edit-shop-details.component";
import { StoreInfoComponent } from "./pages/store-info/store-info.component";
import { StoreDetailsComponent } from "./pages/store-details/store-details.component";
import { StoreKeysComponent } from "./pages/store-keys/store-keys.component";
import { StoreDataManualComponent } from "./pages/store-data-manual/store-data-manual.component";

const routes: Routes = [
  { path: "", component: ProfileUserComponent },
  // { path: 'store-info', component:  StoreInfoComponent,data: { titleheading: "Profile", title: "Store Details" }},
  {
    path: "store-info",
    component: StoreInfoComponent,
    children: [
      {
        path: "",
        redirectTo: "store-details",
        pathMatch: "full",
        data: {
          titleheading: "Dashboard",
          title: "Dashboard",
          minPath: "Basic",
        },
      },
      {
        path: "store-details",
        component: StoreDetailsComponent,
        data: {
          titleheading: "Dashboard",
          title: "Dashboard",
          minPath: "Basic",
        },
      },
      { path: "store-keys", component: StoreKeysComponent },
      { path: "upload-data", component: StoreDataManualComponent },
    ],
  },

  {
    path: "my-store",
    component: MyStorePreviewComponent,
    data: { titleheading: "Profile", title: "Profile" },
  },
  { path: "edit-shop-details", component: EditShopDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
