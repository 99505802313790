import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as echarts from 'echarts';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { DataPreviewAmalyticsDialogBoxComponent, InsightGraphDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-customer-evolution',
  templateUrl: './customer-evolution.component.html',
  styleUrls: ['./customer-evolution.component.css']
})
export class CustomerEvolutionComponent implements OnInit {

  constructor(
    private _apiCall:ApiCallService, 
    private _snackBar:SnackbarCollection, 
    private _i18nDynamicTranslate: TranslateDynamicText,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  dataLoading = false;
  dashboardData;
  rewardRedamationRateStat = [];
  datasefor2 = [];
  totalCustomerRevenueFrameList = ['Quarter','Month', 'Week'];
  timeFrame = 'Month';
  top10customerAllData :any = [];
  selectedMonth = '';
  availableMonths = [];
  selectedTab = '';
  selectedType = '';
  availableOrderType = [];
  top10CustomerByOrderCountAllData : any = [];
  revCurrency = sessionStorage.getItem("serviceCurrency");
  isLoading: boolean = false;
  customerChumRateChart: echarts.EChartsCoreOption;
  trendOfRepeatChart: echarts.EChartsCoreOption;
  top10Customer: echarts.EChartsCoreOption;
  weightedMeanRev: echarts.EChartsCoreOption;
  newcustomerschart: echarts.EChartsCoreOption;
  top10CustomerByOrderCount: echarts.EChartsCoreOption;

  ngOnInit(): void {
    this.getAnalytics();
    // this.getFilters();
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
          this.selectedTab = params.type;
          this.selectTab(params.type);
      }else{
        this.router.navigate(["/app/customer-evolution"], {
          queryParams: { type: 'customerInsights' },
        });
        this.selectedTab = "customerInsights";
        this.selectTab("customerInsights");
      }
    });
  }

  selectTab(tab) {
    this.selectedTab = tab;
  }

  totalCustomerRevenueTimeFrameDropDownHandler(){
    console.log(this.timeFrame);
    this.getAnalytics();
  }

  getAnalytics(){
    this.dataLoading = true;
    // let data = {
    //   stringFilters: [],
    //   stringValuesToCheck: [[]],
    //   doubleFilters: [],
    //   doubleValuesToCheck: [[]],
    // }

    this.getBucketChartsApi('customerchurnchart', this.timeFrame.toLowerCase() === 'month' ? 'month_year' : this.timeFrame.toLowerCase()).then((result)=>{
      if(result['body']){
        this.createChumRateGraph(result['body']);
        this.dataLoading = false;
      }
      else{
        this.customerChumRateChart = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
          }
        };
        this.dataLoading = false;
      }
    });
    
    this.getBucketChartsApi('weightedmeanchart').then((result)=>{
      if(result['body']){
        this.createWeightedMerchatGraph(result['body']);
        this.dataLoading = false;
      }
      else{
        this.weightedMeanRev = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
          }
        };
        this.dataLoading = false;
      }
    });

    this.getBucketChartsApi('repeatcustomertrendchart', this.timeFrame.toLowerCase() === 'month' ? 'month_year' : this.timeFrame.toLowerCase()).then((result)=>{
      if(result['body']){
        this.createRepeatedCustomerGraph(result['body']);
        this.dataLoading = false;
      }
      else{
        this.trendOfRepeatChart = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
          }
        };
        this.dataLoading = false;
      }
    });

    this.getBucketChartsApi('newcustomerschart', this.timeFrame.toLowerCase() === 'month' ? 'month_year' : this.timeFrame.toLowerCase()).then((result)=>{
      if(result['body']){
        this.createNewCustomerChart(result['body']);
        this.dataLoading = false;
      }
      else{
        this.newcustomerschart = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
          }
        };
        this.dataLoading = false;
      }
    });

    this.getBucketChartsApi('topcustomerschart', this.timeFrame.toLowerCase()).then((result)=>{
      if(result['body']){
        // this.availableMonths = [];
        // this.top10customerAllData = result['body'];
        // for(let i =0; i<this.top10customerAllData.length;i++){
        //   this.availableMonths.push(this.top10customerAllData[i][this.timeFrame.toLowerCase()]);
        // }
        // this.selectedMonth = this.top10customerAllData[this.top10customerAllData.length -1][this.timeFrame.toLowerCase()];
        // this.createTopTenGraph(this.getParticularMonthData(this.top10customerAllData[this.top10customerAllData.length -1][this.timeFrame.toLowerCase()], this.timeFrame.toLowerCase()));

        this.availableMonths = [];
        this.availableOrderType = [];
        this.top10customerAllData = result['body'];
        for(let i =0; i<this.top10customerAllData.length;i++){
          // this.availableMonths.push(this.top10customerAllData[i][this.timeFrame.toLowerCase()]);
          if(this.availableOrderType.indexOf((this.top10customerAllData[i]['type'])) == -1){
            this.availableOrderType.push(this.top10customerAllData[i]['type']);
          }
        }
        // this.selectedMonth = this.top10customerAllData[this.top10customerAllData.length -1][this.timeFrame.toLowerCase()];
        this.selectedType = (this.availableOrderType[0].toLowerCase().indexOf('unknown') === -1 || this.availableOrderType.length === 1) ? this.availableOrderType[0] : this.availableOrderType[1];
        this.availableMonths = this.getAvailableMonths();
        this.selectedMonth = this.availableMonths[this.availableMonths.length -1];
        this.createTopTenGraph(this.getParticularMonthData(this.selectedMonth, this.timeFrame.toLowerCase()));
        
        this.getBucketChartsApi('topcustomersordercountchart', this.timeFrame.toLowerCase()).then((result)=>{
          if(result['body']){
            // this.availableOrderType = [];
            this.top10CustomerByOrderCountAllData = result['body'];
            // for(let i =0; i<this.top10CustomerByOrderCountAllData.length;i++){
            //   this.availableMonths.push(this.top10CustomerByOrderCountAllData[i][this.timeFrame.toLowerCase()]);
            //   // this.availableOrderType.push(this.top10customerAllData[i]['type']);
            // }
            // this.selectedMonth = this.top10CustomerByOrderCountAllData[this.top10CustomerByOrderCountAllData.length -1][this.timeFrame.toLowerCase()];
            this.createTopTenByOrderCountGraph(this.getParticularMonthDataByOrderCount(this.selectedMonth, this.timeFrame.toLowerCase()));
            this.dataLoading = false;
          }
          else{
            this.top10CustomerByOrderCount = {
              graphic: {
                elements: [{
                    type: 'text',
                    left: 'center',
                    top: 'center',
                    style: {
                        text: 'No Data Available',
                        font: '18px Arial, sans-serif',
                        fill: '#999'
                    }
                }]
              }
            };
            this.dataLoading = false;
          }
        });

        this.dataLoading = false;
      }
      else{
        this.top10Customer = {
          graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
          }
        };
        this.dataLoading = false;
      }
    },
    (error)=>{
      this.dataLoading = false;
    });
  }

  getParticularMonthData(monthToFind, type){
    console.log(monthToFind);
    const index = this.top10customerAllData.findIndex(item => (item[type] === monthToFind && item['type'] === this.selectedType));
    console.log(index);
    return this.top10customerAllData[index].customers;
  }

  getAvailableMonths(){
    return this.top10customerAllData.filter(entry => entry.type === this.selectedType).map(entry => entry[this.timeFrame.toLowerCase()]);;
  }

   getParticularMonthDataByOrderCount(monthToFind, type){
    console.log(monthToFind);
    const index = this.top10CustomerByOrderCountAllData.findIndex(item => (item[type] === monthToFind && item['type'] === this.selectedType));
    console.log(index);
    return this.top10CustomerByOrderCountAllData[index].customers;
  }

  createTopTenGraph(data){
    console.log(data);
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    let x1 = [], y1 =[];
    var counter = 0;
    data.forEach((data)=>{
      //data['name'] = data.dateRange;
      if (counter >= (data.length < 10 ? data.length : 10)) {
        return; // Exit the loop if the limit is reached
      }
      x1.push(data.revenue);
      y1.push(data.customername);
      counter++;
      // data['value'] = data.count;
    });
    this.top10Customer = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          },
          formatter: function (params) {
            const dataIndex = params[0].dataIndex;
            console.log(data);
            return `Revenue: ${currencySymbol} ${params[0].data}`; // Customize the tooltip content
          },
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
      },
      xAxis: {
          type: 'value',
          name: `Revenue (${currencySymbol})`,
          nameLocation:'center',
          nameGap: 30,
          axisLabel: {
            formatter: function (value) {
              if (value >= 1000) {
                return (value / 1000) + 'k';
              }
              return value;
            },
          }
      },
      yAxis: {
          type: 'category',
          data: y1.reverse().map(label => label.length > 13 ? label.slice(0, 13) + '...' : label)
      },
      series: [
          {
              name: 'Revenue',
              type: 'bar',
              data: x1.reverse(),
              itemStyle: {
                  // You can add custom item styles here if needed
              }
          }
      ]
  };
  }

  customerId = '';
  createTopTenByOrderCountGraph(data){
    console.log(data);
    this.customerId = data[0]['customer_id'];
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    let x1 = [], y1 =[];
    var counter = 0;
    data.forEach((data)=>{
      //data['name'] = data.dateRange;
      if (counter >= (data.length < 10 ? data.length : 10)) {
        return; // Exit the loop if the limit is reached
      }
      x1.push(data.order_count);
      y1.push(data.customername);
      counter++;
      // data['value'] = data.count;
    });
    this.top10CustomerByOrderCount = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          },
          formatter: function (params) {
            const dataIndex = params[0].dataIndex;
            console.log(data);
            return `Order Count: ${params[0].data}`; // Customize the tooltip content
          },
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
      },
      xAxis: {
          type: 'value',
          name: 'Order Count',
          nameLocation:'center',
          nameGap: 30,
          axisLabel: {
            formatter: function (value) {
              if (value >= 1000) {
                return (value / 1000) + 'k';
              }
              return value;
            },
          },
      },
      yAxis: {
          type: 'category',
          data: y1.reverse().map(label => label.length > 13 ? label.slice(0, 13) + '...' : label)
      },
      series: [
          {
              name: 'Revenue',
              type: 'bar',
              data: x1.reverse(),
              itemStyle: {
                  // You can add custom item styles here if needed
              }
          }
      ]
  };
  }

  
  typeChangeHandler(){
    this.availableMonths = this.getAvailableMonths();
    const index = this.top10customerAllData.findIndex(item => (item[this.timeFrame.toLowerCase()] === this.selectedMonth && item['type'] === this.selectedType));

    if(index === -1){
      this.selectedMonth = this.availableMonths[this.availableMonths.length - 1];
    }

    this.createTopTenGraph(this.getParticularMonthData(this.selectedMonth, this.timeFrame.toLowerCase()));
    this.createTopTenByOrderCountGraph(this.getParticularMonthDataByOrderCount(this.selectedMonth, this.timeFrame.toLowerCase()));
  }

  createNewCustomerChart(data){
    console.log(data);
    let x1 = [], y1 =[];
    if(this.timeFrame.toLowerCase() === 'month'){
      data.sort((a, b) => a.month_year.localeCompare(b.month_year));
      data.forEach((value)=>{
        //data['name'] = data.dateRange;
        x1.push(value.month_year);
        y1.push(value.new_rate)
        // data['value'] = data.count;
      });
    }
    else{
      data.sort((a, b) => a[this.timeFrame.toLowerCase()].localeCompare(b[this.timeFrame.toLowerCase()]));
      data.forEach((value)=>{
        //data['name'] = data.dateRange;
        x1.push(value[this.timeFrame.toLowerCase()]);
        y1.push(value.new_rate)
        // data['value'] = data.count;
      });
    }

    let timeType = this.timeFrame;
    function convertToMonthName(value) {
      if(value){
        if(timeType.toLowerCase() === 'month'){
          let [year, month] = value.split('-');
      
          // Convert numeric month to three-letter abbreviation
          let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
      
          // Format the label as 'AbbreviatedMonth-Year'
          return `${monthAbbreviation}-${year}`;
        }
        else if(timeType.toLowerCase() === 'week'){
          let [year, week] = value.split('-');
          return `Week-${week} ${year}`
        }
        else{
          let [year, quarter] = value.split('Q');
          return `${year} Q${quarter}`
        }
      }
      else{
        return null;
      }
    }

    function getLabelName(value){
      if(value === 'month'){
        return 'Month & Year';
      }
      else{
        return value;
      }
    }

    this.newcustomerschart = {
      xAxis: {
        type: 'category',
        name: this.timeFrame,
        nameLocation:'center',
        nameGap: 30,
        data: x1,
        axisLabel: {
          formatter: function (value, index) {
            if(value){
              if(timeType.toLowerCase() === 'month'){
                let [year, month] = value.split('-');
            
                // Convert numeric month to three-letter abbreviation
                let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
            
                // Format the label as 'AbbreviatedMonth-Year'
                return `${monthAbbreviation}-${year}`;
              }
              else if(timeType.toLowerCase() === 'week'){
                let [year, week] = value.split('-');
                return `Week-${week} ${year}`
              }
              else{
                let [year, quarter] = value.split('Q');
                return `${year} Q${quarter}`
              }
            }
            else{
              return null;
            }
          }
        }
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `New Customers: ${data[dataIndex]["new_customers"]} <br/> Total Customers of ${convertToMonthName(data[dataIndex][timeType.toLowerCase() === 'month' ? 'month_year' : timeType.toLowerCase()])} : ${data[dataIndex]["total_customers"]}`; // Customize the tooltip content
        },
      },
      yAxis: {
        type: 'value',
        data: x1,
      },
      series: [
        {
          data: y1,
          type: 'line'
        }
      ]
    };
  }

  createChumRateGraph(data){
    console.log(data);
    let x1 = [], y1 =[];
    if(this.timeFrame.toLowerCase() === 'month'){
      data.sort((a, b) => a.month_year.localeCompare(b.month_year));
      data.forEach((value, index)=>{
        //data['name'] = data.dateRange;
        if (index !== data.length - 1) {  
          x1.push(value.month_year);
          y1.push(value.churn_rate);
        }
        // data['value'] = data.count;
      });
    }
    else{
      data.sort((a, b) => a[this.timeFrame.toLowerCase()].localeCompare(b[this.timeFrame.toLowerCase()]));
      data.forEach((value, index)=>{
        //data['name'] = data.dateRange;
        if (index !== data.length - 1) {  
          x1.push(value[this.timeFrame.toLowerCase()]);
          y1.push(value.churn_rate)
        }
        // data['value'] = data.count;
      });
    }

    let timeType = this.timeFrame;
    function convertToMonthName(value) {
      if(value){
        if(timeType.toLowerCase() === 'month'){
          let [year, month] = value.split('-');
      
          // Convert numeric month to three-letter abbreviation
          let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
      
          // Format the label as 'AbbreviatedMonth-Year'
          return `${monthAbbreviation}-${year}`;
        }
        else if(timeType.toLowerCase() === 'week'){
          let [year, week] = value.split('-');
          return `Week-${week} ${year}`
        }
        else{
          let [year, quarter] = value.split('Q');
          return `${year} Q${quarter}`
        }
      }
      else{
        return null;
      }
    }

    function getLabelName(value){
      if(value === 'month'){
        return 'Month & Year';
      }
      else{
        return value;
      }
    }

    this.customerChumRateChart = {
      xAxis: {
        type: 'category',
        name: this.timeFrame,
        nameLocation:'center',
        nameGap: 30,
        data: x1,
        axisLabel: {
          formatter: function (value, index) {
            if(value){
              if(timeType.toLowerCase() === 'month'){
                let [year, month] = value.split('-');
            
                // Convert numeric month to three-letter abbreviation
                let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
            
                // Format the label as 'AbbreviatedMonth-Year'
                return `${monthAbbreviation}-${year}`;
              }
              else if(timeType.toLowerCase() === 'week'){
                let [year, week] = value.split('-');
                return `Week-${week} ${year}`
              }
              else{
                let [year, quarter] = value.split('Q');
                return `${year} Q${quarter}`
              }
            }
            else{
              return null;
            }
          }
        }
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `Churned Customers: ${data[dataIndex]["churned_customers"]} <br/> Total Customers of ${convertToMonthName(data[dataIndex][timeType.toLowerCase() === 'month' ? 'month_year' : timeType.toLowerCase()])}: ${data[dataIndex]["total_customers"]}`; // Customize the tooltip content
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: y1,
          type: 'line'
        }
      ]
    };
  }

  createWeightedMerchatGraph(data){
    let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    console.log(data);
    let x1 = [], y1 =[];
    data.sort((a, b) => {
      let [numA, plusA] = String(a.days_bucket).split(/-|\+/);
      let [numB, plusB] = String(b.days_bucket).split(/-|\+/);

      if (plusA === undefined) plusA = "";
      if (plusB === undefined) plusB = "";

      if (plusA === "+" && plusB !== "+") {
        return 1;
      } else if (plusA !== "+" && plusB === "+") {
        return -1;
      } else {
        // If both have + or neither has +, compare the numeric part
        let numPartA = parseInt(numA);
        let numPartB = parseInt(numB);
        return numPartA - numPartB;
      }
    });
    data.forEach((value)=>{
      //data['name'] = data.dateRange;
      x1.push(value.days_bucket);
      y1.push(value.weighted_average)
      // data['value'] = data.count;
    });

    this.weightedMeanRev = {
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `Avg. Revenue: ${currencySymbol} ${data[dataIndex]["weighted_average"].toFixed(2)}`; // Customize the tooltip content
        },
      },
      xAxis: {
        data: x1,
        name: 'Days Since First Order (Customer Life Cycle)',
        nameLocation:'center',
        nameGap: 30,
      },
      yAxis: {
      },
      series: [
        {
          name: 'sales',
          type: 'bar',
          data: y1,
          label: {
            show: false,
            position: 'top',
            rotate: 90,
            offset: [20, 8],
              formatter: function (params) {
        // Display the value of the bar with two decimal places
                if (params.value >= 1000) {
                  return params.value / 1000 + "k";
                }
                return params.value > 0 ? `${currencySymbol} ${params.value.toFixed(2)}` : '';; 
              },
            color: 'black' // Text color
          },
        }
      ]
    };
  }

  createRepeatedCustomerGraph(data){
    console.log(data);
    let x1 = [], y1 =[];
    if(this.timeFrame.toLowerCase() === 'month'){
      data.sort((a, b) => a.month_year.localeCompare(b.month_year));
      data.forEach((value, index)=>{
        //data['name'] = data.dateRange;
        if (index !== data.length - 1) {  
          x1.push(value.month_year);
          y1.push(value.repeat_customer_rate);
        }
        // data['value'] = data.count;
      });
    }
    else{
      data.sort((a, b) => a[this.timeFrame.toLowerCase()].localeCompare(b[this.timeFrame.toLowerCase()]));
      data.forEach((value, index)=>{
        //data['name'] = data.dateRange;
        if (index !== data.length - 1) {
          x1.push(value[this.timeFrame.toLowerCase()]);
          y1.push(value.repeat_customer_rate)
         }
        // data['value'] = data.count;
      });
    }
    let timeType = this.timeFrame;
    function convertToMonthName(value) {
      if(value){
        if(timeType.toLowerCase() === 'month'){
          let [year, month] = value.split('-');
      
          // Convert numeric month to three-letter abbreviation
          let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
      
          // Format the label as 'AbbreviatedMonth-Year'
          return `${monthAbbreviation}-${year}`;
        }
        else if(timeType.toLowerCase() === 'week'){
          let [year, week] = value.split('-');
          return `Week-${week} ${year}`
        }
        else{
          let [year, quarter] = value.split('Q');
          return `${year} Q${quarter}`
        }
      }
      else{
        return null;
      }
    }

    function getLabelName(value){
      if(value === 'month'){
        return 'Month & Year';
      }
      else{
        return value;
      }
    }

    this.trendOfRepeatChart = {
      xAxis: {
        type: 'category',
        name: this.timeFrame,
        nameLocation:'center',
        nameGap: 30,
        data: x1,
        axisLabel: {
          formatter: function (value, index) {
            if(value){
              if(timeType.toLowerCase() === 'month'){
                let [year, month] = value.split('-');
            
                // Convert numeric month to three-letter abbreviation
                let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
            
                // Format the label as 'AbbreviatedMonth-Year'
                return `${monthAbbreviation}-${year}`;
              }
              else if(timeType.toLowerCase() === 'week'){
                let [year, week] = value.split('-');
                return `Week-${week} ${year}`
              }
              else{
                let [year, quarter] = value.split('Q');
                return `${year} Q${quarter}`
              }
            }
            else{
              return null;
            }
          }
        }
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          return `Repeating Customers: ${data[dataIndex]["repeatingcustomers"]} <br/> Total Customers of ${convertToMonthName(data[dataIndex][timeType.toLowerCase() === 'month' ? 'month_year' : timeType.toLowerCase()])} : ${data[dataIndex]["customer_count"]}`; // Customize the tooltip content
        },
      },
      yAxis: {
        type: 'value',

      },
      series: [
        {
          data: y1,
          type: 'line'
        }
      ]
    };
  }
  
  monthChangeHandler(){
    this.createTopTenGraph(this.getParticularMonthData(this.selectedMonth, this.timeFrame.toLowerCase()));
    this.createTopTenByOrderCountGraph(this.getParticularMonthDataByOrderCount(this.selectedMonth, this.timeFrame.toLowerCase()));
  }

  convertToMonthName(value) {
    if(value){
      if(this.timeFrame.toLowerCase() === 'month'){
        let [year, month] = value.split('-');
    
        // Convert numeric month to three-letter abbreviation
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
    
        // Format the label as 'AbbreviatedMonth-Year'
        return `${monthAbbreviation}-${year}`;
      }
      else if(this.timeFrame.toLowerCase() === 'week'){
        let [year, week] = value.split('-');
        return `Week-${week} ${year}`
      }
      else{
        let [year, quarter] = value.split('Q');
        return `${year} Q${quarter}`
      }
    }
    else{
      return null;
    }
  }

  openInsight(type){
    const dialogref = this.dialog.open(
      InsightGraphDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "500px",
        height : "500px",
        data: {
          type : type
        }
      }
    );
  }

  // getFilters(){
  //   if(this.timeFrame.toUpperCase() === 'MONTH'){
  //     this.monthlyTotalCustomerSaleFiltersApi().then((result) => {
  //       this.dataLoading =  false;
  //       if(result){
  //         // this.createTotalCustomerSalesGraph(result['body']['totalSales'], result['body']['totalCustomers']);
  //       }
  //     });
  //   }
  //   else{
  //     this.quartlyTotalCustomerSaleFiltersApi().then((result) => {
  //       this.dataLoading =  false;
  //       if(result){
  //         console.log(result);
  //         // this.createTotalCustomerSalesGraph(result['body']['totalSales'], result['body']['totalCustomers']);
  //       }
  //     });
  //   }
  // }


  onChartClick(event: any, type): void {
    // Access the clicked data and handle the click event
    if (event.data) {
      console.log("Bubble clicked! Data:", event.name, type);
      // Call your function or perform actions based on the clicked bubble data
      this.isLoading = true;
      this.getCustomerList(event.name, type);
    }
  }


  getCustomerList(val, type){
    // let data = {
    //   stringFilters: [],
    //   stringValuesToCheck: [],
    //   doubleFilters: [],
    //   doubleValuesToCheck: [],
    //   listFilters: [],
    //   listValuesToCheck: []
    // };
    // data.stringFilters.push(this.timeFrame.toLowerCase());
    // data.stringValuesToCheck.push([val]);
    this.getCustomerListApi(val, type).then((result) => {
      this.dataLoading = false;
      if (result) {
        this.isLoading = false;
        console.log(result);
        const dialogRef = this.dialog.open(DataPreviewAmalyticsDialogBoxComponent, { panelClass: "no-padding-dialog-popup",
        width: "780px",
        height: "auto",
        data: {
          customerDetails : result['body'],
          header : "Customer Details"
        },
      });
      }
    });
  }

  getBucketChartsApi(type, timeframe?){
    return new Promise((resolve, reject) => {
      this._apiCall.getReportChart(null, type, "GET",timeframe).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  getCustomerListApi(data, type){
    return new Promise((resolve, reject) => {
      this._apiCall.getCustomerTrendCustomerList(data, type,  this.timeFrame.toLowerCase() === 'month' ? 'month_year' : this.timeFrame.toLowerCase()).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }
}
