<div>
  <div class="header">
    <span style="text-align: center">{{data["subject"]}} KEYWORDS</span>
    <!-- <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon> -->
    <span class="material-icons-outlined close-icon" (click)="submit(false)">close</span>
  </div>
  <div>
  <div *ngFor="let Key of data['keyword'];">
    <div class="message">{{Key | i18nDynamicTranslate : 'POS_PAGE'}}</div>
  </div>
  </div>
</div>