<div class="qb-social-post-container">
  <div
    style="
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      background: #fff;
      padding: 16px;
      overflow: auto;
    "
  >
    <div>
      <div
        style="
          height: 50px;
          border: 1px solid #919191;
          border-radius: 5px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          padding: 0px 15px;
          cursor: pointer;
          justify-content: space-between;
          position: relative;
        "
        (click)="toggleSelect($event)"
        id="sm-accounts-multiple-select-button"
      >
        <span
          >{{
            selectedAccounts.length == 0
              ? "Please select an account"
              :( selectedAccounts.length + (selectedAccounts.length==1?" account":" accounts")+" selected")
          }}
        </span>
        <span class="qb-display-flex qb-align-center">
          <mat-spinner diameter="15" *ngIf="loading"></mat-spinner>
          <span id="sm-accounts-multiple-select-arrow" class="material-icons-outlined"
        >
          expand_more 
        </span>
      
         
        </span>

        <div
          style="
            height: 0px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 55px;
            left: 0px;
            width: 100%;
            z-index: 1;
            background: #ffffff;
            overflow: auto;
          "
          id="sm-accounts-multiple-select"

        >
        <div *ngFor="let account of fbAccounts">
          <mat-checkbox *ngIf="account.channel.includes('facebook')" [disabled]="videoOb.length > 1 || imagesOb.length > 1"
            [value]="account.userId"
            (change)="addSocialProfileToList(account, 'facebook')"
          >
            <div
              style="
                margin: 0 20px;
                display: flex;
                align-items: center;
                gap: 20px;
              "
            >
              <img
                style="height: 36px; width: 36px; border-radius: 50%"
                [src]="account.profile_picture"
                alt=""
              />
              <div style="display: flex; flex-direction: column">
                <span style="height: 20px">{{ account.name }} </span>
                <span style="color: rgba(34, 34, 34, 0.5); font-size: 12px">{{
                  account.channel == "facebook" ? "Facebook Page" : ""
                }}</span>
              </div>
            </div>
          </mat-checkbox>
          <mat-checkbox *ngIf="account.instagramProfile !== null"
          [value]="account.instagramProfile.id"
          (change)="addSocialProfileToList(account.instagramProfile, 'instagram')"
        >
          <div
            style="
              margin: 0 20px;
              display: flex;
              align-items: center;
              gap: 20px;
            "
          >
            <img
              style="height: 36px; width: 36px; border-radius: 50%"
              [src]="account.instagramProfile.profilePictureUrl"
              alt=""
            />
            <div style="display: flex; flex-direction: column">
              <span style="height: 20px">{{ account.instagramProfile.username }} </span>
              <span style="color: rgba(34, 34, 34, 0.5); font-size: 12px">
                {{'COMMUNICATION_PAGE.INSTAGRAM_ACCOUNT' | i18nTranslate}}
              </span>
            </div>
          </div>
        </mat-checkbox>
        </div>
        </div>
      </div>
      <!-- <mat-select
        style="
          width: 100%;
          height: 40px;
          border: 1px solid rgba(99, 100, 102, 0.3);
          border-radius: 5px;
          padding: 10px;
        "
        [(ngModel)]="selectedAccounts"
        (selectionChange)="selectChange()"
        disableOptionCentering
        panelClass="myPanelClass"
        placeholder="Please select an account"
        multiple
      >
        <mat-option *ngFor="let account of fbAccounts" [value]="account.userId">
          <div style="margin: 0 20px;display: flex;
          align-items: center;
          gap: 20px ;"><img  style="height: 36px;
            width: 36px;
            border-radius: 50%;" [src]="account.profile_picture" alt="">
            
        <div style="display: flex;flex-direction:column;height: 23px;">  <span style="height: 20px;">{{ account.name }} </span> <span style="color: rgba(34, 34, 34, 0.5);font-size:12px">{{account.channel=='facebook'?'Facebook Page':''}}</span></div></div>
         
        </mat-option>
      </mat-select> -->
    </div>

    <div>
      <div
        class="text-container textarea"
        style="margin: 16px 0px"
        [ngClass]="{ error: postContent.length === 0 && submit }"
      >
        <textarea
          style="
            border: none;
            width: 100%;
            height: 100%;
            resize: none;
            outline: none;
          "
          class=""
          name="postContent"
          id="postContent"
          cols="20"
          rows="8"
          [(ngModel)]="postContent"
          (focusout)="updateMeasurementPointComment($event)"
          (keyup)="keypresshandler($event)"
          (paste)="onPaste($event)"
        ></textarea>
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex;">
            <!-- <span
            (click)="toggled = !toggled"
            [(emojiPickerIf)]="toggled"
            [emojiPickerDirection]="'right'"
            (emojiPickerSelect)="handleSelection($event)"
              ><img
                class="smiley-container"
               
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/smile.png"
                alt=""
            /></span> -->
            <!-- <span
              ><img
                class="cursor-pointer"
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/image-plus.png"
                [matMenuTriggerFor]="menu"
            /></span> -->
            <span class="material-symbols-outlined cursor-pointer" style="color: black;"                 [matMenuTriggerFor]="menu"
            >
              add_photo_alternate
              </span>
            <span *ngIf="fileuploading">
              <mat-spinner diameter="12"></mat-spinner>
            </span>
            <span>
              <div class="info" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave"
              [ngbPopover]="popContentImpression">
              <ng-template #popContentImpression class="qb-background-ng-template">
                <div class="qb-ng-template-text">
                  <div class="qb-d-inline-flex">{{'COMMUNICATION_PAGE.FOR_INSTA_ONLY_JPEG' | i18nTranslate}}</div>
                </div>
              </ng-template>
                <div class="qb-i">i</div>
              </div>
            </span>
          </div>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="!fileuploading ? fileInput.click() : null"
              [disabled]="((videoOb.length + imagesOb.length > 0) && isFacebookSelected)  || ((videoOb.length + imagesOb.length > 9))"
            >
              {{'COMMUNICATION_PAGE.ADD_IMAGE' | i18nTranslate}}
            </button>
            <input
              type="file"
              id="imageUpload"
              accept=".jpeg"
              #fileInput
              (change)="setFiles($event)"
              hidden="true"
              multiple
            />
            <button
              mat-menu-item
              (click)="addVideo()"
              [disabled]="((videoOb.length + imagesOb.length > 0) && isFacebookSelected) || ((videoOb.length + imagesOb.length > 9))"
              (click)="!fileuploading ? fileInputVideo.click() : null"
            >
              {{'COMMUNICATION_PAGE.ADD_VIDEO' | i18nTranslate}}
            </button>
            <input
              type="file"
              id="imageUpload"
              accept=".mp4, .mov"
              #fileInputVideo
              (change)="setFilesVideo($event)"
              hidden="true"
              multiple
            />
          </mat-menu>
          <div>
            <span class="char-limit-text"
              ><span style="color: #00b759; font-size: 12px">{{
                postContent.length
              }}</span
              >/5000</span
            >
          </div>
        </div>
      </div>

      <div style="display: flex; gap: 16px; flex-wrap: wrap">
        <div *ngFor="let imageurl of imagesOb" class="img_wrp">
          <img
            [src]="imageurl"
            alt=""
            style="
              height: 80px;
              width: 80px;
              object-fit: contain;
              border: 1px solid rgba(99, 100, 102, 0.3);
            "
          />
          <mat-icon
            class="qb-close-button remove-img-btn cursor-pointer"
            (click)="removeImage(imageurl)"
            >cancel</mat-icon
          >
        </div>
        <div *ngFor="let videoUrl of videoOb" class="img_wrp">
          <video
            style="
              height: 80px;
              width: 80px;
              object-fit: contain;
              border: 1px solid rgba(99, 100, 102, 0.3);
            "
          >
            <source [src]="videoUrl" />
          </video>
          <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
          <mat-icon
            class="qb-close-button remove-img-btn cursor-pointer"
            (click)="removeVideourl(videoUrl)"
            >cancel</mat-icon
          >
        </div>
      </div>
    </div>

    <div>
      <div style="margin-bottom: 16px">{{'COMMUNICATION_PAGE.WHEN_TO_POST' | i18nTranslate}}</div>
      <mat-select
        class="formInput fontExo2"
        style="
          width: 100%;
          height: 40px;
          border: 1px solid rgba(99, 100, 102, 0.3);
          border-radius: 5px;
          padding: 10px;
          outline: none;
        "
        [(ngModel)]="scheduleType"
        disableOptionCentering
        panelClass="myPanelClass"
      >
        <mat-option value="INSTANT">{{'COMMUNICATION_PAGE.INSTANT' | i18nTranslate}} </mat-option>

        <mat-option value="SCHEDULED">{{'COMMUNICATION_PAGE.SPECIFIC_DATE_TIME' | i18nTranslate}} </mat-option>
      </mat-select>
    </div>

    <div
      *ngIf="scheduleType == 'SCHEDULED'"
      style="display: flex; gap: 24px; margin-top: 16px"
    >
      <div style="display: flex; flex-direction: column">
        <div style="margin-bottom: 8px">{{'COMMUNICATION_PAGE.DATE' | i18nTranslate}}</div>
        <div
          class="formDivP"
          style="
            height: 40px;
            border: 1px solid rgba(99, 100, 102, 0.3);
            border-radius: 5px;
            padding: 10px;
            outline: none;
          "
        >
          <input
            [(ngModel)]="scheduleDataObj['date']"
            class="formInput fontFam input-box-alignment"
            [min]="startDateMinValue"
            (dateChange)="startDateChange('start')"
            matInput
            [matDatepicker]="picker1"
            placeholder="Choose a date"
            readonly
            [ngStyle]="scheduleDataObj['date'] ? { opacity: 0 , width: '0px' } : null" 
            />
            <div class="formInput fontFam input-box-alignment">
              {{scheduleDataObj['date'] | date: getDateFormat() }}
          </div>
          <mat-datepicker-toggle matSuffix [for]="picker1">
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
      <div style="display: flex">
        <div style="display: flex; flex-direction: column">
          <div style="margin-bottom: 8px">{{'COMMUNICATION_PAGE.TIME' | i18nTranslate}}</div>
          <div style="display: flex; flex-direction: row">
            <div class="formDiv2">
              <input style="height: 40px;
              border: 1px solid rgba(99, 100, 102, 0.3);
              border-radius: 5px;
              padding: 10px;
              outline: none;margin-right: 4px;"
                type="number"
                name=""
                [min]="minHours"
                max="23"
                id=""
                (change)="valuechange($event)"
                [(ngModel)]="scheduleDataObj['time']['HH']"
              />HH  
            </div>
            <div class="formDiv2" style="    margin: 0px 8px;">
              <input style="height: 40px;
              border: 1px solid rgba(99, 100, 102, 0.3);
              border-radius: 5px;
              padding: 10px;
              outline: none;margin-right: 4px;"
                type="number"
                name=""
                id=""
                [min]="minMinutes"
                max="59"
                [(ngModel)]="scheduleDataObj['time']['MM']"
              />MM
            </div>
          </div>
          <!-- <div
            class="formDiv2"
            [ngClass]="{
              active:
                scheduleDataObj['time'] == null ||
                scheduleDataObj['time'].length == 0
            }"
            style="
              cursor: pointer;
              display: flex;
              justify-content: space-evenly;
              height: 40px;
              border: 1px solid rgba(99, 100, 102, 0.3);
              border-radius: 5px;
              padding: 10px;
              outline: none;
            "
          >
            <input #dispatchTimeOpen
              [(ngModel)]="pickerTime"
              [format]="24"
              [min]="
                selectedStartDateString == MinDateString
                  ? currentTimeMin
                  : '00:00'
              "
              class="formInput"
              style="height: 90%; cursor: pointer"
              [ngxTimepicker]="pickerFrom"
              (ngModelChange)="checkTime()"
              readonly
            />
            <ngx-material-timepicker #pickerFrom> </ngx-material-timepicker>
            <img
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
              alt=""
              (click)="clickTime('START')"
            />
          </div> -->
        </div>
      </div>
    </div>
    <!-- footer -->
    <div style="padding-left: 15px">
      <div
        style="
          display: flex;
          padding-top: 15px;
          justify-content: flex-end;
          gap: 10px;
        "
      >
        <div
          class="gf-btn cursor-pointer"
          style="color: #636466"
          *ngIf="stepsCompleted > 1"
          (click)="previousButtonClicked()"
        >
          <span>{{'COMMUNICATION_PAGE.PREVIOUS' | i18nTranslate}}</span>
        </div>

        <div
          class="gf-btn gf-discard-btn cursor-pointer"
          *ngIf="!isDiscardBtnLoading"
          (click)="discardGameSetup(); isDiscardBtnLoading = true"
        >
          <span>{{'COMMUNICATION_PAGE.DISCARD' | i18nTranslate}}</span>
        </div>
        <div
          class="gf-btn gf-discard-btn"
          style="color: #636466"
          *ngIf="isDiscardBtnLoading"
        >
          <mat-spinner diameter="12"></mat-spinner>
        </div>
        <div
          class="gf-btn gf-save-btn cursor-pointer"
          *ngIf="!isSaveBtnLoading"
          (click)="continueButtonClicked(0); "
        >
          <span>{{'COMMUNICATION_PAGE.SAVE_EXIT' | i18nTranslate}}</span>
        </div>
        <div class="gf-btn gf-save-btn" *ngIf="isSaveBtnLoading">
          <mat-spinner diameter="12"></mat-spinner>
        </div>
        <div
          class="gf-btn gf-continue-btn cursor-pointer"
          *ngIf="!isContinueBtnLoading || selectedAccounts.length == 0"
          (click)="continueButtonClicked(1)"
        >
          <span>{{'COMMUNICATION_PAGE.SCHEDULE' | i18nTranslate}}</span>
        </div>
        <div
          class="gf-btn gf-continue-btn"
          *ngIf="isContinueBtnLoading && selectedAccounts.length !== 0"
        >
          <mat-spinner diameter="12"></mat-spinner>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
  <div
    style="
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      background: #fff;
      padding: 16px;
      overflow: auto;
    "
  >
    <div style="margin-bottom: 16px">{{'COMMUNICATION_PAGE.POST_PREVIEW' | i18nTranslate}}</div>

    <div
      style="
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* or 150% */

        color: rgba(34, 34, 34, 0.8);
      "
    >
      {{'COMMUNICATION_PAGE.POST_PREVIEW_TEXT' | i18nTranslate}}
    </div>

    <hr />

    <div style="display: flex">
      <img
        style="width: 20px; height: 20px"
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/social-media-assets/facebook.png"
        alt=""
      />

      <div style="display: flex; flex-direction: column">
        <div style="margin: 0 12px 8px 12px">{{'COMMUNICATION_PAGE.FACEBOOK' | i18nTranslate}}</div>

        <div *ngFor="let account of fbAccounts" style="margin-bottom: 16px">
          <div
            style="border: 1px solid #c2c2c2; border-radius: 5px; width: 370px"
            *ngIf="selectedAccounts.includes(account.userId)"
          >
            <div style="padding: 8px;">
              <div
                style="
                  margin: 0 20px 0 0px;
                  display: flex;
                  align-items: center;
                  gap: 20px;
                "
              >
                <img
                  style="height: 36px; width: 36px; border-radius: 50%"
                  [src]="account.profile_picture"
                  alt=""
                />
                <div style="display: flex; flex-direction: column">
                  <span style="height: 20px">{{ account.name }} </span>
                  <span style="color: rgba(34, 34, 34, 0.5); font-size: 12px">
                    Jan 1
                  </span>
                </div>
              </div>

              <div style="margin-top: 8px;word-wrap: break-word;">{{ postContent }}</div>

              <div>
                <div style="display: flex; gap: 16px; flex-wrap: wrap">
                  <div *ngFor="let imageurl of imagesOb" class="img_wrp">
                    <img
                      [src]="imageurl"
                      alt=""
                      style="height: 90px; width: 90px; object-fit: contain"
                    />
                  </div>
                  <div *ngFor="let videoUrl of videoOb" class="img_wrp">
                    <video
                      style="height: 90px; width: 90px; object-fit: contain"
                    >
                      <source [src]="videoUrl" />
                    </video>
                    <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div style="border-top: 1px solid #c2c2c2; padding: 8px">
              <div style="display: flex; width: 100%; padding: 8px 0 0 0">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 33.33%;
                  "
                >
                  <span class="material-symbols-outlined"> thumb_up </span>

                  <span style="margin-left: 8px">{{'COMMUNICATION_PAGE.LIKE' | i18nTranslate}}</span>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 33.33%;
                  "
                >
                  <span class="material-symbols-outlined"> chat_bubble </span>

                  <span style="margin-left: 8px">{{'COMMUNICATION_PAGE.COMMENT' | i18nTranslate}}</span>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 31.33%;
                  "
                >
                  <span class="material-symbols-outlined">
                    google_plus_reshare
                  </span>

                  <span style="margin-left: 8px">{{'COMMUNICATION_PAGE.SHARE' | i18nTranslate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>

    <div style="display: flex">
      <img
        style="width: 20px; height: 20px"
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/social-icons/Instagram.png"
        alt=""
      />

      <div style="display: flex; flex-direction: column">
        <div style="margin: 0 12px 8px 12px">{{'COMMUNICATION_PAGE.INSTAGRAM' | i18nTranslate}}</div>

        <div *ngFor="let account of fbAccounts" style="margin-bottom: 16px">
          <div
            style="border: 1px solid #c2c2c2; border-radius: 5px; width: 370px"
            *ngIf="account.instagramProfile !== null && selectedAccounts.includes(account.instagramProfile.id)"
          >
            <div style="padding: 8px;">
              <div
                style="
                  margin: 0 20px 0 0px;
                  display: flex;
                  align-items: center;
                  gap: 20px;
                "
              >
                <img
                  style="height: 36px; width: 36px; border-radius: 50%"
                  [src]="account.instagramProfile.profilePictureUrl"
                  alt=""
                />
                <div style="display: flex; flex-direction: column">
                  <span style="height: 20px">{{ account.instagramProfile.username }} </span>
                  <span style="color: rgba(34, 34, 34, 0.5); font-size: 12px">
                    Jan 1
                  </span>
                </div>
              </div>

              <div style="margin-top: 8px;word-wrap: break-word;">{{ postContent }}</div>

              <div>
                <div style="display: flex; gap: 16px; flex-wrap: wrap">
                  <div *ngFor="let imageurl of imagesOb" class="img_wrp">
                    <img
                      [src]="imageurl"
                      alt=""
                      style="height: 90px; width: 90px; object-fit: contain"
                    />
                  </div>
                  <div *ngFor="let videoUrl of videoOb" class="img_wrp">
                    <video
                      style="height: 90px; width: 90px; object-fit: contain"
                    >
                      <source [src]="videoUrl" />
                    </video>
                    <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div style="border-top: 1px solid #c2c2c2; padding: 8px">
              <div style="display: flex; width: 100%; padding: 8px 0 0 0">
                <div style="display: flex; width: 95%;">
                <div 
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 10%;
                  "
                >
                  <span class="material-symbols-outlined"> favorite </span>

                  <!-- <span style="margin-left: 8px">Like</span> -->
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 10%;
                  "
                >
                  <span class="material-symbols-outlined"> chat_bubble </span>

                  <!-- <span style="margin-left: 8px">Comment</span> -->
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 10%;
                  "
                >
                  <span class="material-symbols-outlined">
                    send
                  </span>

                  <!-- <span style="margin-left: 8px">Share</span> -->
                </div>
                </div>
                <div>
                <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 10%;
                "
              >
                <span class="material-symbols-outlined">
                  bookmark
                </span>

                <!-- <span style="margin-left: 8px">Share</span> -->
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  </div>
</div>
