import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { DateFormatService } from "src/app/core/services/date-format.service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

interface DialogData {
  operation: string;
  campaignName: string;
  startDate: Date;
  endDate: Date;
  dateDifference: number;
}

@Component({
  selector: "app-campaign-strategy-activate-dialog",
  templateUrl: "./campaign-strategy-activate-dialog.component.html",
  styleUrls: ["./campaign-strategy-activate-dialog.component.css"],
})
export class CampaignStrategyActivateDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CampaignStrategyActivateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _DateFormatService: DateFormatService,
    private date_format: getdateformatService
  ) {}

  checkboxValue = false;
  recurringValue = false;
  recurEndDate;
  recurEndMaxDate;
  recurringPeriod;
  recurringOptions = [];
  startDate;
  endDate;
  repeatStartDate;
  repeatEndDate;

  ngOnInit() {
    console.log(this.data)
    if (this.data.operation === "ACTIVATE") {
      this.startDate = this._DateFormatService.formatRawDate(
        this.data.startDate
      );
      this.endDate = this._DateFormatService.formatRawDate(this.data.endDate);
      this.recurEndDate = this.data.endDate
        ? this.data.endDate
        : this.data.startDate;
      this.recurringOptions = [];
      if (!this.data.endDate) { 
        this.recurringOptions.push(["Day"]);
        this.recurringPeriod = "Day";
      }
      if (this.data.dateDifference < 7) {
        this.recurringOptions.push(["Week"]);
        this.recurringPeriod = "Week";
      }
      if (this.data.dateDifference < 31) {
        this.recurringOptions.push(["Month"]);
        this.recurringPeriod = "Month";
      }
      if (this.data.dateDifference < 365) {
        this.recurringOptions.push(["Year"]);
        this.recurringPeriod = "Year";
      }
      console.log(this.data['dateDifference'])
    }
  }

  repeat() {
    let repeatStartDate = new Date(this.data.startDate);
    let repeatEndDate = new Date(this.data.endDate);
    switch (this.recurringPeriod) {
      case "Day":
        repeatStartDate.setDate(repeatStartDate.getDate() + 1);
        if (this.data.endDate) {
          repeatEndDate.setDate(repeatEndDate.getDate() + 1);
        }
        break;
      case "Week":
        repeatStartDate.setDate(repeatStartDate.getDate() + 7);
        if (this.data.endDate) {
          repeatEndDate.setDate(repeatEndDate.getDate() + 7);
        }
        break;
      case "Month":
        repeatStartDate.setDate(repeatStartDate.getDate() + 31);
        if (this.data.endDate) {
          repeatEndDate.setDate(repeatEndDate.getDate() + 31);
        }
        break;
      case "Year":
        repeatStartDate.setDate(repeatStartDate.getDate() + 365);
        if (this.data.endDate) {
          repeatEndDate.setDate(repeatEndDate.getDate() + 365);
        }
        break;
    }
    this.recurEndMaxDate = this.data.endDate ? repeatEndDate : repeatStartDate;
    this.recurEndDate = this.data.endDate ? repeatEndDate : repeatStartDate;
    this.repeatStartDate =
      this._DateFormatService.formatRawDate(repeatStartDate);
    this.repeatEndDate = this._DateFormatService.formatRawDate(repeatEndDate);
  }

  submit(status) {
    let datePipe = new DatePipe("en-US");
    if (this.data.operation === "ACTIVATE") {
      if (status) {
        let data = {
          repeat: this.checkboxValue ? this.recurringPeriod : null,
          repeatEnd: this.recurringValue
            ? datePipe.transform(this.recurEndDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          startDate: datePipe.transform(
            this.data.startDate,
            "yyyy-MM-dd HH:mm:ss"
          ),
        };
        this.dialogRef.close(data);
      } else {
        this.dialogRef.close(status);
      }
    } else {
      this.dialogRef.close(status);
    }
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
