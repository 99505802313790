<div class="main-container-1">
  <div class="header-div">
    <span class="header-span">{{'SHARED_PAGE.CAMPAIGN_DETAILS' | i18nTranslate}}</span>
    <mat-icon (click)="closeDialog(false)" class="close-icon">close</mat-icon>
  </div>
  <div
    class="form-container"
    id="form-container"
  >
    <form [formGroup]="dataForm" style="padding:13px">
      <div>
        <span class="main-label-style">{{'SHARED_PAGE.CAMPAIGN_NAME' | i18nTranslate}}</span
        ><span style="color: #c71a1a; font-size: 12px">*</span>
        <div style="margin-top: 5px; margin-bottom: 10px">
          <div
            class="formDiv pos-rel"
            [ngClass]="{
              error:
                dataForm.get('strategyName').errors &&
                (dataForm.get('strategyName').touched || submitClicked)
            }"
          >
            <input
              placeholder="eg. Upsell to existing customers"
              type="text"
              formControlName="strategyName"
              class="formInput"
              (keydown)="stringInputHandler($event, 50)"
              maxlength="50" #inp2
            />
            <div class="remaining-text-left-2">{{inp2.value.length}}/{{inp2.getAttribute('maxlength')}}</div>
          </div>
          <span
            *ngIf="dataForm.get('strategyName').errors"
            style="color: var(--main-font-red); font-size: 12px"
            id="name-error"
          ></span>
          <span
            *ngIf="duplicateNameErrorText"
            style="color: var(--main-font-red); font-size: 12px"
          >{{duplicateNameErrorText}}</span>
        </div>
      </div>
      <div>
        <div style="margin-top: 10px">
          <span class="main-label-style">{{'SHARED_PAGE.CAMPAIGN_DESCRIPTION' | i18nTranslate}}</span>
          <div
            style="height: 80px; margin-top: 5px"
            class="formDiv pos-rel"
            [ngClass]="{
              error:
                dataForm.get('description').errors &&
                (dataForm.get('description').touched || submitClicked)
            }"
          >
            <textarea
              [matTooltip]="dataForm.get('description').value"
              style="height: 90%"
              formControlName="description"
              class="formInput description-textarea"
              #inp3
              maxlength="300"
            >
            </textarea>
            <div class="remaining-text-left-2">{{inp3.value.length}}/{{inp3.getAttribute('maxlength')}}</div>
          </div>
          <span
            *ngIf="dataForm.get('description').errors"
            style="color: var(--main-font-red); font-size: 12px"
            id="description-error"
          ></span>
        </div>
      </div>
    </form>
  
    <div style="padding: 0px 15px 0px 15px;">
      <span class="main-label-style"
        >{{'SHARED_PAGE.CHOOSE_SEGMENT_CAMPAIGN' | i18nTranslate}}</span
      >
      <span style="color: var(--main-font-red); font-size: 12px">*</span>
    </div>
    <div style="padding: 5px 15px 0px 15px">
      <div class="flex-with-gap">
        <div
        class="multi-select-panel"
        id="multi-select-panel"
        (click)="toggleSelect($event)"
        >
          <span class="color-black">{{'SHARED_PAGE.SELECTED' | i18nTranslate}} {{ customerSegments.length }}</span>
          <div class="keyboard-down-icon-container" id="keyboard-down-icon-container">
              <mat-icon class="keyboard-down-icon">keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <app-segmentation-customer-list-redirect [miniVer]="true" (click)="dialogRef.close()"></app-segmentation-customer-list-redirect>
      </div>
      
      <div style="position: relative">
        <div class="multi-select-dropdown" id="multi-select-dropdown">
          <div class="search">
            <input
              [(ngModel)]="segmentFilter"
              placeholder="Search"
              type="text"
              style="border: none; outline: none; width: 100%"
            />
            <span class="material-icons-outlined" *ngIf="segmentFilter.length==0"> search </span>
            <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="segmentFilter.length>0" (click)="segmentFilter=''">
              close
            </span>
          </div>
          <div class="border-line"></div>
          <div id="segment-select" class="segment-select">
            <div>
              <div>
                <!-- <mat-checkbox
                  [checked]="
                    customerSegments.length === 1 && customerSegments[0] === 'ALL'
                  "
                  (change)="addAllCustomers($event)"
                  color="primary"
                  >All customers</mat-checkbox
                > -->
                <mat-checkbox class="custom-check"
                          *ngFor="                          let segment of allcustomerObj
                            | labelPipe: segmentFilter:'segmentName'"
                            [checked]="customerSegments.includes(segment.id)
                          "
                          (change)="addAllCustomers($event, segment.id)"
                          color="primary"
                        >
                        <span>
                          {{ segment.segmentName | limitTo: 40}}
                        </span>
                        </mat-checkbox>
              </div>
              <div class="category-text-container" *ngIf="isSegmentPresent()">
                <div class="category-line"></div>
                <span class="category-text">{{'SHARED_PAGE.SEGMENTS' | i18nTranslate}}</span>
                <div class="category-line"></div>
              </div>
              <div *ngFor="
                  let segment of data.segments
                      | labelPipe: segmentFilter:'segmentName'
                ">
                  <ng-container *ngIf="segment.category.toLowerCase() == 'segment'">
                    <!-- [checked]="customerSegments | isChecked: segment.id" -->
                    <mat-checkbox class="custom-check"
                    [checked]="isChecked(segment, 'segment')"
                    (change)="updateCheckboxValues(segment, 'segment')"
                    [disabled]="!data.customerSegmentSelect || !segment['isActive']"
                    color="primary"
                    
                    ><span    [matTooltip]="
                    data.customerSegmentSelect
                      ? segment['isActive']
                        ? segment.segmentName
                        : 'We are preparing your segment. It will be available for use shortly'
                      : 'Sign up event is applicable to All customers only'
                  " >
                      {{ segment.segmentName }}
                    </span>
                    </mat-checkbox
                    >
                  </ng-container>
              </div>
              <div class="category-text-container" *ngIf="isTierSegmentPresent()">
                <div class="category-line"></div>
                <span class="category-text">{{'SHARED_PAGE.LOYALITY_TIERS' | i18nTranslate}}</span>
                <div class="category-line"></div>
              </div>
              <div
                *ngFor="
                  let segment of data.segments
                    | labelPipe: segmentFilter:'segmentName'
                "
              >
                <ng-container *ngIf="segment.category.toLowerCase() == 'tier'">
                        <!-- [checked]="customerSegments | isChecked: segment.id" -->
                        <mat-checkbox class="custom-check"
                        [checked]="isChecked(segment, 'tier')"
                        (change)="updateCheckboxValues(segment, 'tier')"
                        [disabled]="!data.customerSegmentSelect || !segment['isActive']"
                        color="primary"
                        ><span     [matTooltip]="
                        data.customerSegmentSelect
                          ? segment['isActive']
                            ? segment.segmentName
                            : 'We are preparing your segment. It will be available for use shortly'
                          : 'Sign up event is applicable to All customers only'
                      " [matTooltipPosition]="'right'">
                          {{ segment.segmentName | limitTo: 40 }}
                        </span>
                        </mat-checkbox
                        >
                </ng-container>
              </div>
              <div class="category-text-container" *ngIf="isCustomerListPresent()">
                <div class="category-line"></div>
                <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                <div class="category-line"></div>
              </div>
              <div
                *ngFor="
                  let segment of data.segments
                    | labelPipe: segmentFilter:'segmentName'
                "
              >
                <ng-container *ngIf="segment.category.toLowerCase() == 'customerlist'">
                        <!-- [checked]="customerSegments | isChecked: segment.id" -->
                        <mat-checkbox class="custom-check"
                        [checked]="isChecked(segment, 'customerlist')"
                        (change)="updateCheckboxValues(segment, 'customerlist')"
                        [disabled]="!data.customerSegmentSelect || !segment['isActive']"
                        color="primary"
                        ><span [matTooltip]="
                        data.customerSegmentSelect
                          ? segment['isActive']
                            ? segment.segmentName
                            : 'We are preparing your custom list. It will be available for use shortly'
                          : 'Sign up event is applicable to All customers only'
                      "
                      [matTooltipPosition]="'right'">
                      {{ segment.segmentName | limitTo: 40 }}
                        </span></mat-checkbox
                        >
                </ng-container>
              </div>
              <div class="category-text-container" *ngIf="isEmployeeListPresent()">
                <div class="category-line"></div>
                <span class="category-text">{{'COMMON_PAGE.EMPLOYEE_LIST' | i18nTranslate}}</span>
                <div class="category-line"></div>
              </div>
              <div
                *ngFor="
                  let segment of data.segments
                    | labelPipe: segmentFilter:'segmentName'
                "
              >
                <ng-container *ngIf="segment.category.toLowerCase() == 'employeelist'">
                        <!-- [checked]="customerSegments | isChecked: segment.id" -->
                        <mat-checkbox class="custom-check"
                        [checked]="isChecked(segment, 'employeelist')"
                        (change)="updateCheckboxValues(segment, 'employeelist')"
                        [disabled]="!data.customerSegmentSelect || !segment['isActive']"
                        [matTooltip]="
                          data.customerSegmentSelect
                            ? segment['isActive']
                              ? segment.segmentName
                              : 'We are preparing your Employee list. It will be available for use shortly'
                            : 'Sign up event is applicable to All customers only'
                        "
                        color="primary"
                        [matTooltipPosition]="'right'"
                        ><span      [matTooltip]="
                        data.customerSegmentSelect
                          ? segment['isActive']
                            ? segment.segmentName
                            : 'We are preparing your Employee list. It will be available for use shortly'
                          : 'Sign up event is applicable to All customers only'
                      "
                      color="primary"
                      [matTooltipPosition]="'right'">
                      {{ segment.segmentName | limitTo: 40 }}
                        </span></mat-checkbox
                        >
                </ng-container>
              </div>
              <div *ngIf="!isSegmentPresent() && !isTierSegmentPresent() && !isCustomerListPresent() && !isEmployeeListPresent()" class="no-data">
                {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-text-err">
      <span
        style="color: var(--main-font-red); font-size: 12px;"
        id="errorField"
      ></span>
    </div>
  
    <div class="count-details">
      <div class="box row g-0">
        <div class="col-4 box-icon-container">
          <div class="material-symbols-outlined box-icon">
            group
          </div>
        </div>
        <div class="col-8 flex-col" *ngIf="isAdmin">
          <div class="main-label-style flex-1" id="definition">
            {{'SHARED_PAGE.CUSTOMER_TARGETED' | i18nTranslate}}
          </div>
          <div class="details">
            <app-qubriux-loading *ngIf="totalCustomerCount === null" [size]="15"></app-qubriux-loading>
            <div id="count" *ngIf="totalCustomerCount !== null">{{ totalCustomerCount }}</div>
          </div>
        </div>

        <div class="col-8 flex-col" *ngIf="!isAdmin">
          <div class="main-label-style flex-1" id="definition">
            {{'SHARED_PAGE.STORE_CUSTOMER_TARGETED' | i18nTranslate}} / {{'SHARED_PAGE.CUSTOMER_TARGETED' | i18nTranslate}}
          </div>
          <div class="details">
            <app-qubriux-loading *ngIf="totalCustomerCount === null" [size]="15"></app-qubriux-loading>
            <div id="count" *ngIf="totalCustomerCount !== null">{{ storeTargetedCustomerCount }} / {{totalCustomerCount}}</div>
          </div>
        </div>
      </div>

      <div class="box row g-0">
        <div class="col-4 box-icon-container">
          <div class="material-symbols-outlined box-icon">
            mail
          </div>
        </div>
        <div class="col-8 flex-col">
          <div class="main-label-style flex-1" id="definition">
            {{'SHARED_PAGE.CUSTOMER_HAVING_VALID_EMAIL' | i18nTranslate}}
          </div>
          <div class="details">
            <app-qubriux-loading *ngIf="totalCustomersValidEmail === null" [size]="15"></app-qubriux-loading>
            <div id="count" *ngIf="totalCustomersValidEmail !== null">{{ totalCustomersValidEmail }}</div>
          </div>
        </div>
      </div>

      <div class="box row g-0">
        <div class="col-4 box-icon-container">
          <div class="material-symbols-outlined box-icon">
            call
          </div>
        </div>
        <div class="col-8 flex-col">
          <div class="main-label-style flex-1" id="definition">
            {{'SHARED_PAGE.CUSTOMER_HAVING_VALID_PHONENUMBER' | i18nTranslate}}
          </div>
          <div class="details">
            <app-qubriux-loading *ngIf="totalCustomersValidPhNo === null" [size]="15"></app-qubriux-loading>
            <div id="count" *ngIf="totalCustomersValidPhNo !== null">{{ totalCustomersValidPhNo }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container-div-sub">
      <span
      class="secondary-button-border"
      (click)="closeDialog(false)"
      data-widget-name="CREATE NEW CAPAIGN"
      data-button-name="Cancel"
      >{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span
      >
      <span
        class="primary-button"
        (click)="closeDialog(true)"
        id="strategy-save"
        widget-id="16"
        event-id="3"
        data-widget-name="CREATE NEW CAPAIGN"
        data-button-name="Save Details"
        >{{'SHARED_PAGE.SAVE' | i18nTranslate}}</span
      >
    </div>
  </div>
</div>

