<div id="schedule-container" style="min-width: 250px;">
  <div class="header">
    <span>{{'SHARED_PAGE.SEND_SMS' | i18nTranslate}}</span>
  </div>
  <div style="margin: 20px;">
    <div>
      <div
        class="multi-select-panel"
        id="multi-select-panel"
        (click)="toggleSelect($event)"
      >
        <span>{{'SHARED_PAGE.SEGMENT_SELECTED' | i18nTranslate}} - {{ customerSegments.length }}</span>
        <div
          class="keyboard-down-icon-container"
          id="keyboard-down-icon-container"
        >
          <mat-icon class="keyboard-down-icon">keyboard_arrow_down</mat-icon>
        </div>
      </div>
      <div style="position: relative">
        <div class="multi-select-dropdown" id="multi-select-dropdown">
          <div class="search">
            <mat-icon class="group-icon">search</mat-icon>
            <input
              [(ngModel)]="segmentFilter"
              placeholder="Search Segment"
              type="text"
              style="border: none; outline: none"
            />
          </div>
          <div class="border-line"></div>
          <div id="segment-select" class="segment-select">
            <div>
              <div>
                <mat-checkbox
                [checked]="customerSegments.length === 1 && customerSegments[0] === 'ALL'"
                (change)="addAllCustomers($event)"
                color="primary"
                >{{'SHARED_PAGE.ALL_CUSTOMERS' | i18nTranslate}}</mat-checkbox
              >
              </div>
              <div
                *ngFor="
                  let segment of data.customerSegments
                    | labelPipe: segmentFilter:'segmentName'
                "
              >
              <!-- [checked]="customerSegments | isChecked: segment.id" -->
                <mat-checkbox
                  [checked]="isChecked(segment)"
                  (change)="updateCheckboxValues(segment)"
                  color="primary"
                  >{{ segment.segmentName }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px; font-size: 12px">
      <div style="display: flex; align-items: center">
        <mat-icon class="group-icon">group</mat-icon>
        <mat-spinner
          *ngIf="totalCustomerCount === null"
          diameter="15"
        ></mat-spinner>
        <span *ngIf="totalCustomerCount !== null">{{ totalCustomerCount }}</span>
      </div>
    </div>
    <div class="instant-text">
      <span>{{'SHARED_PAGE.SMS_SEND_INSTANTLY' | i18nTranslate}}.</span>
    </div>
  </div>
  <div class="split-line"></div>
  <div style="margin-left: 20px;">
    <span
      style="color: #c71a1a; font-size: 11px;"
      id="errorField"
    ></span>
  </div>
  <div class="button-container">
    <span class="cancel-button" (click)=submit(false)>{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</span>
    <span class="confirm-button" (click)=submit(true)>{{'SHARED_PAGE.CONFIRM' | i18nTranslate}}</span>
  </div>
</div>