<div *ngIf="loading" class="qb-spinner">
  <mat-spinner diameter="20"></mat-spinner>
</div>
<div class="qb-body" *ngIf="!loading">
  <div class="qb-main-div">
    <div class="qb-logo">
      <img [src]="partnerData.shopLogoURL" class="qb-logo-image" />
    </div>
    <div class="qb-content">
      <div class="qb-header">
        <div class="qb-header-details">
          <div class="qb-store-name">{{ partnerData.partnerName }}</div>
          <div class="qb-store-link">
            <a [href]="link" target="_blank"> {{ partnerData.shopURL }}</a>
          </div>
          <div class="qb-partner-analytics">
            <div>Total Partners: {{ partnerData.totalPartners }}</div>
            <div class="qb-customer-strength">
              <div>Customer Strength: {{ partnerData.customerStrength }}</div>
              <div
                class="qb-strength"
                *ngIf="partnerData['customerStrength'] === 'high'"
              >
                <div class="qb-strength1"></div>
                <div class="qb-strength2"></div>
                <div class="qb-strength3"></div>
                <div class="qb-strength4"></div>
                <div class="qb-strength5"></div>
              </div>
              <div
                class="qb-strength"
                *ngIf="partnerData['customerStrength'] === 'medium'"
              >
                <div class="qb-mediumstrength1"></div>
                <div class="qb-mediumstrength2"></div>
                <div class="qb-mediumstrength3"></div>
                <div class="qb-mediumstrength4"></div>
                <div class="qb-strength5"></div>
              </div>
              <div
                class="qb-strength"
                *ngIf="partnerData['customerStrength'] === 'low'"
              >
                <div class="qb-lowstrength1"></div>
                <div class="qb-lowstrength2"></div>
                <div class="qb-lowstrength3"></div>
                <div class="qb-lowstrength4"></div>
                <div class="qb-strength5"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="primary-button"
          (click)="initiatePartnership()"
          *ngIf="partnerData.isIntiatePartnership && isSource"
        >
          {{'PARTNERSHIP_PAGE.INITIATE_PATNERSHIP_BACK' | i18nTranslate}}
        </div>
      </div>
      <div
        class="qb-reward-info"
        *ngIf="
          (action === 'VIEW_OFFER' && isSource) || action === 'OFFER_NOT_SHARED'
        "
      >
        <div class="qb-reward-div">
          <div class="qb-reward-image">
            <img
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/reward.png"
            />
          </div>
          <div>
            <div class="qb-store-name"><strong>{{'PARTNERSHIP_PAGE.REWARD' | i18nTranslate}}</strong></div>
            <div class="qb-reward">
              {{'PARTNERSHIP_PAGE.EARN' | i18nTranslate}} <strong>{{ partnerData.rewardValueShared }}%</strong>
              {{'PARTNERSHIP_PAGE.REWARD_MSG_PREFIX' | i18nTranslate}}
            </div>
          </div>
        </div>
        <div *ngIf="action === 'OFFER_NOT_SHARED'">
          <div class="qb-image-div">
            <img
              src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/partner-details.png"
            />
          </div>
          <div>
            {{'PARTNERSHIP_PAGE.WAIT_FOR_ACCEPTING_MSG' | i18nTranslate}}
          </div>
        </div>
        <div class="qb-offer" *ngIf="action !== 'OFFER_NOT_SHARED'">
          <div class="qb-reward-div">
            <div class="qb-reward-image">
              <img
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/offer.png"
              />
            </div>
            <div>
              <div class="qb-store-name">
                <strong>{{'PARTNERSHIP_PAGE.OFFER_SHARED_BY_PARTNER' | i18nTranslate}}</strong>
              </div>
              <div class="qb-offer-details">
                <strong>{{ partnerData.offersShared[0].offerName }}</strong>
              </div>
              <div class="qb-offer-info">
                {{ partnerData.offersShared[0].offerDescription }}
              </div>
            </div>
          </div>
          <div class="qb-form-div"  *ngIf="!isPos">
            <div class="qb-reward-image">
              <img
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/form.png"
              />
            </div>
            <div>
              <div class="qb-offer-info qb-create-form">
                <strong>
                  {{'PARTNERSHIP_PAGE.CREATE_FORM_OR_CAMPAIGN' | i18nTranslate}}</strong
                >
              </div>
              <div
                class="primary-button qb-create-form-button"
                (click)="createForm()"
              >
                {{'PARTNERSHIP_PAGE.CREATE_FORM' | i18nTranslate}}
              </div>
              <div
              style="margin-top: 10px;"
              class="primary-button qb-create-form-button"
              (click)="createCampaign()"
            >
              {{'PARTNERSHIP_PAGE.CREATE_CAMPAIGN' | i18nTranslate}}
            </div>
            </div>
          </div>
          <div class="qb-form-div" *ngIf="isPos">
            <div class="qb-reward-image">
              <img
                src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/form.png"
              />
            </div>
            <div>
              <div class="qb-offer-info qb-create-form">
                <strong>
                  {{'PARTNERSHIP_PAGE.SCHEDULE_OFFER_CAMPAIGN' | i18nTranslate}}</strong
                >
              </div>
              <div
                class="primary-button qb-create-form-button"
                (click)="scheduleOffer()"
              >
                {{'PARTNERSHIP_PAGE.SCHEDULE_OFFER' | i18nTranslate}}
              </div>
              <div
              style="margin-top: 10px;"
              class="primary-button qb-create-form-button"
              (click)="createCampaign()"
            >
              {{'PARTNERSHIP_PAGE.CREATE_CAMPAIGN' | i18nTranslate}}
            </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          ((action === 'VIEW_OFFER' && !isSource) ||
            action === 'OFFER_DISABLED' ||
            action === 'OFFER_SENT' ||
            action === 'ACTIVE' ||
            action === 'FORM_ENDED' ||
            action === 'FORM_DISABLED') &&
          action !== 'OFFER_NOT_SHARED'
        "
      >
        <div class="qb-revenue-div">
          <div class="qb-rectangle">
            <div class="qb-card-title">
              <div class="qb-consent-text">{{'PARTNERSHIP_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
              <div
                class="qb-i-div"
                type="button"
                [ngbPopover]="popContent2"
                triggers="mouseenter:mouseleave"
              >
                <ng-template #popContent2>
                  <div class="qb-i-text" *ngIf="isSource">
                    {{'PARTNERSHIP_PAGE.REVENUE_GEN_INFO_DATA' | i18nTranslate}}
                  </div>
                  <div class="qb-i-text" *ngIf="!isSource">
                    {{'PARTNERSHIP_PAGE.REVENUE_GEN_MESSAGE_DATA' | i18nTranslate}}
                  </div>
                </ng-template>
                <div class="qb-i">i</div>
              </div>
              <div class="qb-image-div-card">
                <div class="qb-image">
                  <img
                    class="qb-images"
                    src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/money.png"
                  />
                </div>
              </div>
            </div>
            <div
              class="qb-value-number"
              *ngIf="partnerData.revenueGenerated === null"
            >
              {{ currencySymbol }}0
            </div>
            <div
              class="qb-value-number"
              *ngIf="partnerData.revenueGenerated !== null"
            >
              {{ currencySymbol
              }}{{ partnerData.revenueGenerated | number: "1.2-2" }}
            </div>
          </div>
          <div class="qb-rectangle qb-revenue-rectangle" *ngIf="!isSource">
            <div class="qb-card-title">
              <div class="qb-consent-text">{{'PARTNERSHIP_PAGE.PRODUCTS_SOLD' | i18nTranslate}}</div>
              <div
                class="qb-i-div"
                type="button"
                [ngbPopover]="popContent3"
                triggers="mouseenter:mouseleave"
              >
                <ng-template #popContent3>
                  <div class="qb-i-text">
                    {{'PARTNERSHIP_PAGE.PRODUCT_SOLD_USING_DISCOUNT_INFO' | i18nTranslate}}
                  </div>
                </ng-template>
                <div class="qb-i">i</div>
              </div>
              <div class="qb-image-div-card">
                <div class="qb-image">
                  <img
                    class="qb-images"
                    src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/partnership-assets/cart.png"
                  />
                </div>
              </div>
            </div>
            <div
              class="qb-value-number"
              *ngIf="partnerData.productsSold === null"
            >
              0
            </div>
            <div
              class="qb-value-number"
              *ngIf="partnerData.productsSold !== null"
            >
              {{ partnerData.productsSold }}
            </div>
          </div>
          <!-- <div class="qb-rectangle qb-revenueRectangle qb-r2">
            <div class="qb-consent-text">Customers accquired</div>
            <div
              class="qb-value-number"
              *ngIf="partnerData.customersAcquired === null"
            >
              0
            </div>
            <div
              class="qb-value-number"
              *ngIf="partnerData.customersAcquired !== null"
            >
              {{ partnerData.customersAcquired }}
            </div>
          </div> -->
        </div>
      </div>
      <div
        class="qb-table-div"
        *ngIf="
          ((action === 'VIEW_OFFER' && !isSource) ||
            action === 'OFFER_DISABLED' ||
            action === 'OFFER_SENT' ||
            action === 'ACTIVE' ||
            action === 'FORM_ENDED' ||
            action === 'FORM_DISABLED') &&
          action !== 'OFFER_NOT_SHARED'
        "
      >
        <div class="qb-table-header">
          <div class="qb-partners-list-div">
            <div>{{'PARTNERSHIP_PAGE.OFFER_SHARED_WITH_PARTNER' | i18nTranslate}}</div>
          </div>
          <div
            *ngIf="showSendNewOffer && !isSource"
            class="primary-button qb-add-button"
            (click)="sendNewOffer()"
          >
            <div class="material-icons-outlined">add</div>
            <div>{{'PARTNERSHIP_PAGE.SEND_NEW_OFFER' | i18nTranslate}}</div>
          </div>
        </div>
        <div class="qb-table-head">
          <div
            class="qb-table-head-section"
            [ngClass]="{'qb-width-22': heading === 'Offer Name', 'qb-width-23' : heading === 'Coupon Code' }"
            *ngFor="let heading of offerTableHeadings"
          >
            <div
            >
              {{ heading | i18nDynamicTranslate : 'POS_PAGE' }}
            </div>
          </div>
        </div>
        <div *ngIf="false" class="qb-spinner-div">
          <mat-spinner diameter="15"></mat-spinner>
        </div>
        <div *ngIf="false" class="qb-no-data">{{'PARTNERSHIP_PAGE.SEND_NEW_OFFER' | i18nTranslate}}</div>
        <div class="qb-table-content">
          <div
            class="qb-table-data-div"
            *ngFor="let offer of partnerData.offersShared"
          >
            <div class="qb-table-data" [matTooltip]="offer.offerName" style="width: 22%;">
              {{ offer.offerName }}
            </div>
            <div class="qb-table-data" [matTooltip]="offer.couponCode" style="width: 23%;">
              {{ offer.couponCode }}
            </div>
            <div class="qb-table-data qb-offer-valid-tills" style="width: 25%;">
              {{ offer.offerDisplayValidy.split(" ")[0] }}
            </div>
            <div class="qb-table-data" style="width: 19%">
              {{ offer.couponValidity.split(" ")[0] }}
            </div>
            <div class="qb-table-data qb-action-div">
              <div
                class="qb-disable-offer"
                (click)="disableOffer(offer.couponCode)"
                *ngIf="offer.action === 'ACTIVE' && !isSource"
              >
                {{'PARTNERSHIP_PAGE.DISABLE' | i18nTranslate}}
              </div>
              <div
                class="qb-disabled"
                *ngIf="offer.action === 'OFFER_DISABLED'"
              >
                {{'PARTNERSHIP_PAGE.DISABLED' | i18nTranslate}}
              </div>
            </div>
          </div>
        </div>
        <div class="qb-table-header form-header" *ngIf="isSource && !isPos">
          <div class="qb-partners-list-div">
            <div>{{'PARTNERSHIP_PAGE.ACTIVE_FROM_PARTNER' | i18nTranslate}}</div>
          </div>
          <div
            *ngIf="
              isSource &&
              partnerData.activeForms.length == 0 &&
              showSendNewOffer
            "
            class="primary-button qb-add-button"
            (click)="createForm()"
          >
            <div class="material-icons-outlined">add</div>
            <div>{{'PARTNERSHIP_PAGE.CREATE_NEW_FORM' | i18nTranslate}}</div>
          </div>
        </div>
        <div class="qb-table-head" *ngIf="isSource && !isPos">
          <div *ngFor="let heading of formTableHeadings" class="qb-form-head-section ">
            <div class="qb-table-head-section">
              {{ heading | i18nDynamicTranslate : 'POS_PAGE' }}
            </div>
          </div>
        </div>
        <div *ngIf="false && isSource && !isPos" class="qb-spinner-div">
          <mat-spinner diameter="15"></mat-spinner>
        </div>
        <div *ngIf="false && isSource && !isPos" class="qb-no-data">
          {{'PARTNERSHIP_PAGE..NO_DATA_AVAILABLE' | i18nTranslate}}
        </div>
        <div class="qb-table-content" *ngIf="isSource && !isPos">
          <div *ngIf="partnerData.activeForms.length == 0" class="qb-no-data">
            {{'PARTNERSHIP_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
          </div>
          <div
            class="qb-table-data-div"
            *ngFor="let form of partnerData.activeForms"
          >
            <div
              class="qb-table-data qb-form-head-section"
              [matTooltip]="form.name"
            >
              {{ form.name }}
            </div>
            <div class="qb-table-data qb-form-head-section">
              {{ form.target }}
            </div>
            <div class="qb-table-data qb-form-head-section">
              {{ form.lastActivatedOn }}
            </div>
            <div class="qb-table-data qb-form-head-section">
              <div class="qb-form-status">
                <div
                  class="material-icons icons-primary qb-active-flag qb-flag"
                  *ngIf="form.status === 'ACTIVE'"
                >
                  flag
                </div>
                <div
                  class="material-icons icons-primary qb-ended-flag qb-flag"
                  *ngIf="form.status === 'FORM_ENDED'"
                >
                  flag
                </div>
                <div
                  class="material-icons icons-primary qb-disabled-flag qb-flag"
                  *ngIf="form.status === 'FORM_DISABLED'"
                >
                  flag
                </div>
                <div
                  *ngIf="form.status === 'FORM_DISABLED'"
                  class="qb-disabled-flag"
                >
                  {{'PARTNERSHIP_PAGE.DISABLED' | i18nTranslate}}
                </div>
                <div *ngIf="form.status === 'FORM_ENDED'" class="qb-ended-flag">
                  {{'PARTNERSHIP_PAGE.ENDED' | i18nTranslate}}
                </div>
                <div *ngIf="form.status === 'ACTIVE'" class="qb-active-flag">
                  {{'PARTNERSHIP_PAGE.ACTIVE' | i18nTranslate}}
                </div>
              </div>
              <div>
                <div
                  class="material-icons icons-primary qb-active-flag qb-flag"
                  *ngIf="form.status === 'ACTIVE'"
                >
                  <mat-slide-toggle
                    (change)="toggleForm(form.formId)"
                    [checked]="form.status === 'ACTIVE'"
                  ></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="qb-graph-card"
        *ngIf="
          ((action === 'VIEW_OFFER' && !isSource) ||
            action === 'OFFER_DISABLED' ||
            action === 'OFFER_SENT' ||
            action === 'ACTIVE' ||
            action === 'FORM_ENDED' ||
            action === 'FORM_DISABLED') &&
          action !== 'OFFER_NOT_SHARED'
        "
      >
        <div class="qb-graph-header">
          <div class="qb-graph-title" *ngIf="!isSource">{{'PARTNERSHIP_PAGE.REVENUE_GENERATED' | i18nTranslate}}</div>
          <div class="qb-graph-title" *ngIf="isSource">{{'PARTNERSHIP_PAGE.REWARD_GENERATED' | i18nTranslate}}</div>
          <div class="qb-range">
            <div
              class="material-icons-outlined qb-calendar-icon"
              (click)="select1.open()"
            >
              calendar_month
            </div>
            <div class="qb-range-select">
              <mat-select [(value)]="timeRange1" #select1>
                <mat-option value="All Time">{{'PARTNERSHIP_PAGE.ALL_TIME' | i18nTranslate}}</mat-option>
                <mat-option value="This Week">{{'PARTNERSHIP_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="qb-graph-div" #chartArea>
          <div class="qb-graph">
            <app-revenue-rewards-chart
              [selectedGraphButton]="timeRange1"
              [width]="720"
              page="target"
              [id]="data.partnershipRequestId"
            >
            </app-revenue-rewards-chart>
          </div>
        </div>
      </div>
      <div
        class="qb-graph-card"
        *ngIf="
          ((action === 'VIEW_OFFER' && !isSource) ||
            action === 'OFFER_DISABLED' ||
            action === 'OFFER_SENT' ||
            action === 'ACTIVE' ||
            action === 'FORM_ENDED' ||
            action === 'FORM_DISABLED') &&
          action !== 'OFFER_NOT_SHARED' &&
          !isSource
        "
      >
        <div class="qb-graph-header">
          <div class="qb-graph-title">{{'PARTNERSHIP_PAGE.NUMBER_PRODUCTS_SOLD' | i18nTranslate}}</div>
          <div class="qb-range">
            <div
              class="material-icons-outlined qb-calendar-icon"
              (click)="select2.open()"
            >
              calendar_month
            </div>
            <div class="qb-range-select">
              <mat-select [(value)]="timeRange2" #select2>
                <mat-option value="All Time">{{'PARTNERSHIP_PAGE.ALL_TIME' | i18nTranslate}}</mat-option>
                <mat-option value="This Week">{{'PARTNERSHIP_PAGE.THIS_WEEK' | i18nTranslate}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="qb-graph-div">
          <div class="qb-graph">
            <app-products-sold-chart
              [selectedGraphButton]="timeRange2"
              [width]="720"
              page="target"
              [id]="data.partnershipRequestId"
            >
            </app-products-sold-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
