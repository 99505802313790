import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { SmsAnalyticsComponent } from './pages';

const routes: Routes = [
  { path: "", redirectTo: "sms-analytics", pathMatch: "full" },
  { path: "sms-analytics", component: SmsAnalyticsComponent,  canActivate: [WidgetRouteGaurdService],
      data: {
          titleheading: "SMS Analytics",
          title: "Insights",
          id: 28,
      },  
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmsAnalyticsRoutingModule { }
