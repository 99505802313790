<div style="padding: 20px;">
    <div class="qb-d-flex qb-jc-space-between">
        <div class="qb-h2">
            {{storeName}}
        </div>
        <div>
            <img [src]="imgPath" class="qb-icon-header">
        </div>
    </div>
    <hr>
    <div style="padding: 20px;">
        <h1 class="qb-main-page-title">{{surveyName}}</h1>
        <div class="qb-survey-view-container" id="surveyHtml"></div>
    </div>
</div>