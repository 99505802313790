import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerEvolutionComponent } from './pages';

const routes: Routes = [
    { 
      path: "", 
      component: CustomerEvolutionComponent, 
      data: {
        titleheading: "Customer Trends",
        title: "Customer Trends",
        minPath: "Basic"
      },
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerEvolutionRoutingModule { }
