import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-partnership-dashboard",
  templateUrl: "./partnership-dashboard.component.html",
  styleUrls: ["./partnership-dashboard.component.css"],
})
export class PartnershipDashboardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
