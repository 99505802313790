<div style="padding: 30px; height: 100%; overflow-y: auto;">
    <div>
        <div>
            <div class="qb-header-wrapper">
                <app-breadcrumb></app-breadcrumb>
                <div class="qb-mb-25" style="display: flex; align-items: center;">
                    <button class="primary-button qb-create-new-offer-button"
                        [matTooltip]="'COMMON_PAGE.DOWNLOAD_REPORT' | i18nTranslate" appButtonDebounce
                        [throttleTime]="600" (throttledClick)="downloadWalletReport()" data-button-name="Download Wallet Report">
                        Download Report
                    </button>
                </div>
            </div>
            <div class="qb-main-report-header">
                <div class="qb-heading-report">
                    <h4>Offer Details <span style="color: #E55151;">*</span></h4>
                </div>
                <div class="qb-d-flex">
                    <div class="qb-filters-div-report qb-mb-25 qb-filter-padding">
                        <div>
                            <h6 class="dropdown-heading">Wallet Type</h6>
                            <div class="mat-select-report-filter" style="width: 250px;">
                                <mat-select placeholder="Select Type" [(ngModel)]="selectedReport">
                                    <mat-option *ngFor="let val of typeOfReports" [value]="val.value" selected>{{val.name |
                                        i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div *ngIf="selectedReport.length > 0">
                            <h6 class="dropdown-heading">Select User</h6>
                            <div class="mat-select-report-filter">
                                <mat-select placeholder="Select Stores" [(ngModel)]="selectedStore">
                                    <mat-option value="ALL" selected *ngIf="qid === 'null'">All Users</mat-option>
                                    <mat-option value="THIS_STORE" selected>{{storeName}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div *ngIf="selectedReport.length > 0">
                            <h6 class="dropdown-heading">Transaction Type</h6>
                            <div class="mat-select-report-filter">
                                <mat-select placeholder="Select Transaction Type" [(ngModel)]="selectedTransaction">
                                    <mat-option *ngFor="let val of typeOfTransaction" [value]="val.type" selected>{{val.name |
                                        i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div>
                            <h6 class="dropdown-heading">Date Range</h6>
                            <div class="qb-date-range-picker-wrapper" *ngIf="selectedReport.length > 0">
                                <mat-form-field appearance="fill">
                                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="getMaxDate()"
                                        [ngStyle]="range.value.start !== null ? { display: 'none' } : null">
                                        <input matStartDate formControlName="start" placeholder="Start date">
                                        <input matEndDate formControlName="end" placeholder="End date"
                                            (dateChange)="onEndDateChange($event)">
                                    </mat-date-range-input>
                                    <div style="display: flex; flex-direction: row;">
                                        <div>{{range.value.start | date: getDateFormat() }}</div>
                                        <span style="margin: 0 4px;" *ngIf="range.value.start != null">-</span>
                                        <div>{{range.value.end | date: getDateFormat() }}</div>
                                    </div>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
            
                                    <mat-error
                                        *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'ANALYZE_PAGE.INVALID_START_DATE'
                                        | i18nTranslate}}</mat-error>
                                    <mat-error
                                        *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'ANALYZE_PAGE.INVALID_END_DATE' |
                                        i18nTranslate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="qb-mb-25" style="display: flex; align-items: center;" *ngIf="selectedReport.length > 0">
                        <button class="primary-button qb-create-new-offer-button" (click)="searchReport()"
                            data-button-name="Create New Offer">
                            Apply Filter
                        </button>
                    </div> -->
                </div>
                <div class="qb-ilustration-add-customer">
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/illustrations/Checklist-removebg-preview.png"
                        alt="" style="width: 350px; height:300px; margin-top: 30px">
                </div>
            </div>
    </div>
</div>
<!-- <div *ngIf="checkForLoyalty === true"
    style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
    <mat-spinner diameter="45"></mat-spinner>
</div> -->