<div class="reviewContainer" data-widget-name="REVIEWS">
      <div class="featureTab">
        <app-breadcrumb></app-breadcrumb>
        <div class="qb-platform-dropdown">
            <label class="filter-title-2" for="platformSelect">Platform Filter: </label>
            <mat-select id="platformSelect" [(ngModel)]="selectedPlatform" (change)="onPlatformChange($event)">
              <mat-option *ngFor="let platform of platformList" [value]="platform">{{ platform }}</mat-option>
            </mat-select>
        </div>
        <div class="button" *ngIf="isAdmin" >
          <div class="primary-button" data-button-name="Review Config" (click)="openSettingsDialog()">
            <span >{{'SHARED_PAGE.CONFIGURE_REVIEWS' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
  
    <div *ngIf="selectedTab=='review-list'" class="review-details-container">
      <div class="tabContent">
        <div class="contentReviewList">
            <app-review-instance [platformFilter]="selectedPlatform"></app-review-instance>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTab=='dashboard'" style="width: 100%; flex: 1 1 auto; overflow: auto;">
      <div class="tabContent">
        <div class="contentReviewList">
            <app-review-dashboard [platformFilter]="selectedPlatform" ></app-review-dashboard>
        </div>
      </div>
    </div>
  </div>

  
