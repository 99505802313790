import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { SocialMediaCreatePostComponent } from './components';
import { SocialMediaComponent } from './pages';

const routes: Routes = [
    { 
        path: "",
        component: SocialMediaComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
            titleheading: "Social",
            id: 40,
        }, 
    },
    {
        path: "social/post",
        component: SocialMediaCreatePostComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
            titleheading: "Post-Create",
            id: 40,
        }, 
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SocialMediaRoutingModule { }
