<div class="qb-main-conatiner-table">
    <div class="qb-header-wrapper">
        <div class="qb-d-flex qb-gap-4">
            <div>
                <svg *ngIf="data['data'].type === 'surveys'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_59_1794)">
                        <path d="M7 21C5.89543 21 5 20.1046 5 19V3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                            stroke="url(#paint0_linear_59_1794)" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M13 3V9H19" stroke="url(#paint1_linear_59_1794)" stroke-width="2"
                            stroke-linejoin="round" />
                        <path d="M9 13H15" stroke="url(#paint2_linear_59_1794)" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M9 17H15" stroke="url(#paint3_linear_59_1794)" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_59_1794" x1="12" y1="3" x2="12" y2="21"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_59_1794" x1="16" y1="3" x2="16" y2="9"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_59_1794" x1="12" y1="13" x2="12" y2="14"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_59_1794" x1="12" y1="17" x2="12" y2="18"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                        <clipPath id="clip0_59_1794">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <svg *ngIf="data['data'].type === 'offers'" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_62_3320)">
                        <path
                            d="M13.0858 4.58579C12.7107 4.21071 12.202 4 11.6716 4H4.5V11.1716C4.5 11.702 4.71071 12.2107 5.08579 12.5858L12.0858 19.5858C12.8668 20.3668 14.1332 20.3668 14.9142 19.5858L20.0858 14.4142C20.8668 13.6332 20.8668 12.3668 20.0858 11.5858L13.0858 4.58579Z"
                            stroke="url(#paint0_linear_62_3320)" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <rect x="9.5" y="9" width="0.01" height="0.01" stroke="url(#paint1_linear_62_3320)"
                            stroke-width="3" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_62_3320" x1="13" y1="4" x2="13" y2="21"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_62_3320" x1="9.505" y1="9" x2="9.505" y2="9.01"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                        <clipPath id="clip0_62_3320">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </svg>
                <svg *ngIf="data['data'].type === 'journeys'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 3L8 5M8 21L8 13M8 5L20 5L18 9L20 13L8 13M8 5L8 13" stroke="url(#paint0_linear_47_971)"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <defs>
                        <linearGradient id="paint0_linear_47_971" x1="20" y1="12" x2="8" y2="12"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#482FEB" />
                            <stop offset="1" stop-color="#9687F5" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="qb-heading-text">
                {{data["data"].type}}
            </div>
        </div>
        <div class="cursor-pointer" (click)="close()">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#D8DEE6" />
                <g clip-path="url(#clip0_59_1782)">
                    <path d="M19 19L29 29M19 29L29 19" stroke="#4C5664" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_59_1782">
                        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="qb-table-data-container">
        <div class="qb-table-data-header-data-wrapper" [ngClass]="{
            'qb-heading-scroll-padding' : isScrollVisible,
            'qb-heading-no-scroll-padding' : !isScrollVisible
        }">
            <div class="qb-table-header-col" *ngFor="let header of headingList" [ngClass]="{
                'qb-flex-small': header.toLowerCase() === 'offer id' || header.toLowerCase() === 'status' || header.toLowerCase() === 'valid till',
                'qb-flex-medium': header.toLowerCase() === 'offer code'
            }">
                {{header}}
            </div>
        </div>
        <div *ngIf="data['data'].type === 'surveys'">
            <div #scrollContainer class="qb-table-data-wrapper" *ngFor="let list of data['tableData']">
                <div class="qb-table-data-col">
                    {{list.id}}
                </div>
                <div class="qb-table-data-col">
                    {{list.surveyName}}
                </div>
                <div class="qb-table-data-col">
                  {{list.createdTs | date: getDateFormat('dd MMM yyyy')}} {{list.createdTs | date : 'h:mm a'}}
                </div>
            </div>
            <div *ngIf="data['tableData'].length == 0" class="empty-list-indicator-order">
                No Surveys Found
            </div>
        </div>
        <div #scrollContainer class="qb-table-data-scoll-wrapper" *ngIf="data['data'].type === 'offers'">
            <div class="qb-table-data-wrapper" *ngFor="let list of data['tableData']">
                <div class="qb-table-data-col qb-flex-small">
                    {{list.offerId}}
                </div>
                <div class="qb-table-data-col qb-flex-medium">
                    <span [matTooltip] = "list.discountCode">
                        {{list.discountCode | limitTo: 18}}
                    </span>
                </div>
                <div class="qb-table-data-col qb-flex-small" [ngClass]="{
                    'qb-red': list.status === 'EXPIRED',
                    'qb-yellow': list.status === 'REDEEMED',
                    'qb-green': list.status === 'ACTIVE'
                }">
                    {{list.status}}
                </div>
                <div class="qb-table-data-col qb-flex-small">
                  {{list.expiryDate | date: getDateFormat('dd MMM yyyy')}} {{list.expiryDate | date : 'h:mm a'}}
                </div>
            </div>
            <div *ngIf="data['tableData'].length == 0" class="empty-list-indicator-order">
                No Offers Found
            </div>
        </div>
        <div #scrollContainer class="qb-table-data-scoll-wrapper" *ngIf="data['data'].type === 'journeys'">
            <div class="qb-table-data-wrapper" *ngFor="let list of data['tableData']">
                <div class="qb-table-data-col">
                    {{list.id}}
                </div>
                <div class="qb-table-data-col">
                    {{list.campaignName}}
                </div>
                <div class="qb-table-data-col">
                  {{list.executionDate | date: getDateFormat('dd MMM yyyy')}} {{list.executionDate | date : 'h:mm a'}}
                </div>
            </div>
            <div *ngIf="data['tableData'].length == 0" class="empty-list-indicator-order">
                No Journeys Found
            </div>
        </div>
    </div>
</div>