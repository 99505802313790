import { NgModule } from '@angular/core';
import { CreateSegmentsComponent, EditSegmentsComponent } from './pages';
import { ViewSegmentsComponent } from './components';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { SegmentRoutingModule } from './segments-routing.module';
import { ExportSegmentComponent } from './components/export-segment/export-segment.component';
import { NewCreateSegmentsComponent } from './pages/new-create-segments/new-create-segments.component';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [
    CreateSegmentsComponent,
    EditSegmentsComponent,
    ViewSegmentsComponent,
    ExportSegmentComponent,
    NewCreateSegmentsComponent
  ],
  imports: [
    SharedModule,
    SegmentRoutingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ]
})
export class SegmentsModule { }
