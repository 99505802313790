import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { PreviewTemplateComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-single-game-analytics",
  templateUrl: "./single-game-analytics.component.html",
  styleUrls: ["./single-game-analytics.component.css"],
})
export class SingleGameAnalyticsComponent implements OnInit {
  range = "LAST_7_DAYS";
  gameid;
  gameName;
  layout;
  id;
  ImpressionValue;
  TotalTargetCustomer;
  TotalViews;
  DeskstopPercentage;
  MobilePercentage;
  EngagementValue;
  TotalPeoplePlayed;
  NoPeopleCompleted;
  ConversionRate;
  TotalPurchases;
  AnonymusPurchases;
  EmailCollected;
  RevenueGenerated;
  viaDeskstop;
  viaMobile;
  TotalRevenue;
  DeskstopPercentageEngagement;
  MobilePercentageEngagement;
  DeskstopPercentageRevenue;
  MobilePercentageRevenue;
  StatusGame;
  discountLowerLimit;
  discountUpperLimit;
  ProductExcludedLength;
  totalGameSteps;
  productsPerStep;
  coolOffPeriod = [];
  timeToRedeemReward = [];
  maxRetries;
  initialHaltDuration = [];
  startDate;
  endDate;
  startTime;
  endTime;
  AllCustomersCheck;
  AllProductExcludedCheck;
  daysOfTheweek;
  AllDaysOfWeekCheck;
  revCurrency;
  atAnyTime = false;
  @ViewChild("select") select;

  constructor(
    private route: ActivatedRoute,
    private _apiCall: ApiCallService,
    private _dialog: MatDialog,
    private date_format: getdateformatService
  ) {}

  ngOnInit() {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.route.queryParams.subscribe((params) => {
      if (params.id) this.gameid = params.id;
      // if (params.name) this.gameName = params.name;
    });
    this.getStatData();
    this.getGameDetails();
    this.getTargetAudianceName();
  }
  showPreview() {
    //Function called to show the preview of email template
    this.id = this.route.snapshot.queryParams.id;
    this._apiCall.getNegotiatorEmailTemplate(this.id).subscribe((response) => {
      let emailTemplate = response["body"];
      let data = {
        selectedLayout: emailTemplate["html"],
        templateId: null,
        templateName: "Negotiator Reward Email",
      };
      this.layout = emailTemplate["html"];
      let finalScreenHeight = document.getElementById(
        "single-game-container"
      ).offsetHeight;
      let finalScreenWidth = document.getElementById(
        "single-game-container"
      ).offsetWidth;
      let sh = finalScreenHeight * (90 / 100);
      let sw = finalScreenHeight * (70 / 100);
      const dialogRef = this._dialog.open(PreviewTemplateComponent, {
        panelClass: "previewDialog",
        height: sh + "px",
        width: finalScreenWidth < 1500 ? "1024px" : sw + "px",
        minWidth: "1024px",
        maxWidth: "99vw",
        disableClose: true,
        autoFocus: false,
        data: {
          obj: data,
          type: "NEGOTIATOR_EMAIL",
          height: finalScreenHeight,
          isEditAllowed: false,
        },
      });
    });
  }
  getStatData() {
    //To get the stats of single game
    this._apiCall.getNegotiatorAnalytics(this.gameid).subscribe((response) => {
      this.ImpressionValue = response["body"]["total"]["distinctViewed"]
      this.TotalTargetCustomer = response["body"]["targetAudience"];
      this.TotalViews = response["body"]["total"]["overallViewed"];
      this.DeskstopPercentage = this.divide(
        response["body"]["desktop"]["overallViewed"],
        response["body"]["desktop"]["overallViewed"] +
          response["body"]["mobile"]["overallViewed"]
      );
      this.MobilePercentage = this.divide(
        response["body"]["mobile"]["overallViewed"],
        response["body"]["desktop"]["overallViewed"] +
          response["body"]["mobile"]["overallViewed"]
      );
      this.EngagementValue = this.divide(
        response["body"]["total"]["played"],
        response["body"]["total"]["distinctViewed"]
      );
      this.TotalPeoplePlayed = response["body"]["total"]["played"];
      this.NoPeopleCompleted = response["body"]["total"]["completed"];
      this.DeskstopPercentageEngagement = this.divide(
        response["body"]["desktop"]["completed"],
        response["body"]["desktop"]["completed"] +
          response["body"]["mobile"]["completed"]
      );
      this.MobilePercentageEngagement = this.divide(
        response["body"]["mobile"]["completed"],
        response["body"]["desktop"]["completed"] +
          response["body"]["mobile"]["completed"]
      );
      this.ConversionRate = this.divide(
        response["body"]["total"]["distinctPurchased"],
        response["body"]["total"]["completed"]
      );
      this.TotalPurchases = response["body"]["total"]["overallPurchased"];
      this.AnonymusPurchases = response["body"]["anonymous"]["overallPurchased"];
      this.EmailCollected = response["body"]["anonymous"]["emailsCollected"];
      this.RevenueGenerated =Math.round(response["body"]["total"]["revenue"] *100)/100;
      this.viaDeskstop = Math.round(response["body"]["desktop"]["revenue"]*100)/100;
      this.viaMobile = Math.round(response["body"]["mobile"]["revenue"]*100)/100;
      this.DeskstopPercentageRevenue = this.divide(
        response["body"]["desktop"]["revenue"],
        response["body"]["desktop"]["revenue"] +
          response["body"]["mobile"]["revenue"]
      );
      this.MobilePercentageRevenue = this.divide(
        response["body"]["mobile"]["revenue"],
        response["body"]["desktop"]["revenue"] +
          response["body"]["mobile"]["revenue"]
      );
    });
  }
  divide(num1, num2) {
    if (num2 == 0) {
      return 0;
    } else {
      var value;
      value = (num1 / num2) * 100;
      value = Math.round(value * 100) / 100;
      return value;
    }
  }
  openSelect() {
    this.select.open();
  }
  getGameDetails() {
    //To get details of a single game
    this._apiCall.getSingleGameDetails(this.gameid).subscribe((response) => {
      this.StatusGame = response["body"]["status"];
      this.gameName = response["body"]["title"]
      this.discountLowerLimit = response["body"]["discountRange"]["lowerLimit"];
      this.discountUpperLimit = response["body"]["discountRange"]["upperLimit"];
      this.ProductExcludedLength =
        response["body"]["excludedProductElements"]["products"].length;
      this.totalGameSteps = response["body"]["totalGameSteps"];
      this.productsPerStep = response["body"]["productsPerStep"];
      this.initialHaltDuration = response["body"]["initialHaltDuration"];
      this.coolOffPeriod = response["body"]["coolOffPeriod"];
      this.timeToRedeemReward = response["body"]["timeToRedeemReward"];
      this.maxRetries = response["body"]["maxRetries"];
      this.startDate = response["body"]["startDate"];
      this.endDate = response["body"]["endDate"];
      this.endTime = response["body"]["endTime"]
      this.startTime = response["body"]["startTime"]
      this.daysOfTheweek = response["body"]["daysOfWeek"];
      if(this.startTime ==="00:00:00" && this.endTime === "23:59:00"){
        this.atAnyTime = true;
      }
      else{
        this.atAnyTime = false;
      }
      if (this.daysOfTheweek.length == 7) {
        this.AllDaysOfWeekCheck = true;
      } else {
        this.AllDaysOfWeekCheck = false;
      }
      if (response["body"]["enabledCustomerSegments"]["all"] == true) {
        this.AllCustomersCheck = true;
      } else {
        this.AllCustomersCheck = false;
      }
      if (response["body"]["excludedProductElements"]["all"] == true) {
        this.AllProductExcludedCheck = true;
      } else {
        this.AllProductExcludedCheck = false;
      }
    });
  }
  TargetAudiance = [];
  getTargetAudianceName() {
    //To get the name of the customer segment selected
    this._apiCall
      .getSingleGameDetails(this.gameid, "SUMMARY")
      .subscribe((response) => {
        this.TargetAudiance = response["body"]["targetAudience"]["elements"];
      });
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
}
