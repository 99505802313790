<div class="content-center" *ngIf="mainLoading">
    <app-qubriux-loading [size]="75" [loadingText]="'Fetching your '+getListName()+'...'"></app-qubriux-loading>
</div>
<div class="qb-segment-container" #container data-widget-name="CUSTOMER LIST LISTING" *ngIf="!mainLoading">
    <div>
        <div class="qb-header" #header>
            <div class="qb-header-contents qb-segment-search-container-2">
                <div class="qb-segment-list-header" #segmentListHeader>
                    <app-breadcrumb></app-breadcrumb>
                </div>
            </div>
            <div *ngIf="validWidgets" class="qb-header-contents qb-create-segment-button">
                <div *ngIf="validWidgets | widgetConfig: 9">
                    <span class="primary-button" (click)="createCustomerList()" data-button-name="Create New Segment">
                        Create New {{getListName()}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="qb-segment-content" *ngIf="validWidgets">
        <div class="qb-list-view-container">
            <div class="qb-side-bar main-white-panel" *ngIf="(validWidgets | widgetConfig: 8) && this.customerList.length>0">
                <div *ngIf="customerList.length !== 0" class="qb-h-100">
                    <div *ngIf="customerList" class="qb-h-100">
                        <div *ngIf="customerList.length !== 0" class="qb-h-100 qb-d-flex qb-fd-coloumn qb-ai-stretch">
                            <div class="qb-w-100">
                                <div class="qb-formDiv qb-segment-filter-ip-form">
                                    <input class="qb-formInput" [(ngModel)]="filter.label" type="text"
                                        [placeholder]="'Search ' + getListName()" />
                                    <span class="material-icons-outlined icons-primary" *ngIf="filter.label.length==0"> search
                                    </span>
                                    <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
                                        *ngIf="filter.label.length>0" (click)="filter.label=''">
                                        close
                                    </span>
                                </div>
                            </div>
        <div class="qb-segment-list-content-list">

            <ng-container *ngIf="qId === null || listType ==='employeeList'">
              <div *ngFor="let list of customerList | labelPipe: filter.label:'customerListName'; let i = index">
                <div class="qb-top-list" [class.active]="selectedCustomerId == list['id']" [class.noBorder]="i === selectedStandardTemplateIndex - 1"
                     (click)="listType === 'customerList' ? viewCustomerList(list['id']) : viewEmployeeList(list['id'])"
                     data-button-name="View">
                  <div class="qb-list-class">
                    <div class="qb-template-list-container" [id]="'segment-list-container' + list['id']">
                      <div class="qb-template-list-content" [id]="'segment-list' + list['id']">
                        <span class="material-icons-outlined icons-primary qb-segment-list-icon">
                          {{listType=='customerList'?'recent_actors':'badge'}}
                        </span>
                        <div class="qb-segment-name-div">
                          <span [id]="list['id']" class="qb-segment-name-text" [matTooltip]="list['customerListName']"
                                matTooltipClass="ex-tooltip-red1" matTooltipPosition="right" matTooltipShowDelay="500">
                            {{ list['customerListName'] }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
  
        
            <ng-container *ngIf="qId !== null && listType ==='customerList'">
        
              <!-- Store Created Customer List -->
              <div *ngIf="(customerListWithCreatedByStoreId | labelPipe: filter.label:'customerListName').length > 0">
                <div class="category-text-container">
                  <div class="category-line"></div>
                  <span class="category-text">Store Custom lists</span>
                  <div class="category-line"></div>
                </div>
                <div *ngFor="let list of customerListWithCreatedByStoreId | labelPipe: filter.label:'customerListName'; let i = index">
                  <div class="qb-top-list" [class.active]="selectedCustomerId == list['id']" [class.noBorder]="i === selectedStandardTemplateIndex - 1"
                       (click)="listType === 'customerList' ? viewCustomerList(list['id']) : viewEmployeeList(list['id'])"
                       data-button-name="View">
                    <div class="qb-list-class">
                      <div class="qb-template-list-container" [id]="'segment-list-container' + list['id']">
                        <div class="qb-template-list-content" [id]="'segment-list' + list['id']">
                          <span class="material-icons-outlined icons-primary qb-segment-list-icon">
                            {{listType=='customerList'?'recent_actors':'badge'}}
                          </span>
                          <div class="qb-segment-name-div">
                            <span [id]="list['id']" class="qb-segment-name-text" [matTooltip]="list['customerListName']"
                                  matTooltipClass="ex-tooltip-red1" matTooltipPosition="right" matTooltipShowDelay="500">
                              {{ list['customerListName'] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Other Customer List -->
              <div *ngIf="(customerListWithoutMatchingCreatedBy | labelPipe: filter.label:'customerListName').length > 0">
                <div class="category-text-container">
                  <div class="category-line"></div>
                  <span class="category-text">Other Custom List</span>
                  <div class="category-line"></div>
                </div>
                <div *ngFor="let list of customerListWithoutMatchingCreatedBy | labelPipe: filter.label:'customerListName'; let i = index">
                  <div class="qb-top-list" [class.active]="selectedCustomerId == list['id']" [class.noBorder]="i === selectedStandardTemplateIndex - 1"
                       (click)="listType === 'customerList' ? viewCustomerList(list['id']) : viewEmployeeList(list['id'])"
                       data-button-name="View">
                    <div class="qb-list-class">
                      <div class="qb-template-list-container" [id]="'segment-list-container' + list['id']">
                        <div class="qb-template-list-content" [id]="'segment-list' + list['id']">
                          <span class="material-icons-outlined icons-primary qb-segment-list-icon">
                            {{listType=='customerList'?'recent_actors':'badge'}}
                          </span>
                          <div class="qb-segment-name-div">
                            <span [id]="list['id']" class="qb-segment-name-text" [matTooltip]="list['customerListName']"
                                  matTooltipClass="ex-tooltip-red1" matTooltipPosition="right" matTooltipShowDelay="500">
                              {{ list['customerListName'] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>  
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="(customerList | labelPipe: filter.label:'customerListName').length === 0">
                    <div class="qb-segment-list-header" #segmentListHeader>
                        <span>{{ selectedSegmentType }}</span>
                    </div>
                    <div class="qb-empty-segment-list-message-container" *ngIf="!mainLoading">
                        {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                    </div>
                </div> -->
            </div>
            <div class="qb-segment-description main-white-panel" *ngIf="validWidgets | widgetConfig: 9" [style.width]="this.customerList.length==0?'100%':'74%'">
                <div style="height: 100%;" *ngIf="(customerList | labelPipe: filter.label:'customerListName').length !== 0" >
                    <app-customer-list-data-view [customerId]="selectedCustomerId" [listType] = "listType" (customerDataFromChild) = "customerDataFromChild($event)"></app-customer-list-data-view>
                </div>
                <div *ngIf="(customerList | labelPipe: filter.label:'customerListName').length === 0" class="qb-no-data-area">
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg"
                        alt="" style="padding-top: 100px;width: 500px;">
                    <div style="margin-top: 15px; color: orangered;">
                        No Custom Lists Available
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>