<div style="padding: 30px; height: 100%; overflow: auto;">
    <div>
        <div class="qb-header-wrapper">
            <app-breadcrumb></app-breadcrumb>
            <button class="primary-button qb-create-new-offer-button"
            [matTooltip]="'COMMON_PAGE.SEND_REPORT_EMAIL' | i18nTranslate" appButtonDebounce
            [disabled] = "selectedOffer.length === 0"
            [throttleTime]="600" (throttledClick)="sendReportViaEmail()" data-button-name="Create New Offer">
            Send Via Email
            </button>
        </div>
        <div class="qb-main-report-header">
            <div class="qb-heading-report" *ngIf="reportType.toUpperCase() === 'OFFER'">
                <h4>Offer Details <span style="color: #E55151;">*</span></h4>
            </div>
            <div class="qb-heading-report" *ngIf="reportType.toUpperCase() === 'JOURNEY'">
                <h4>A/B Testing Details <span style="color: #E55151;">*</span></h4>
            </div>
            <div class="qb-d-flex">
                <div class="qb-filters-div-report qb-mb-25 qb-filter-padding">
                    <div>
                        <div class="qb-heading-select">
                            {{reportType}} Name
                        </div>
                        <div class="mat-select-report-filter" style="width: 250px;" *ngIf="reportType.toUpperCase() === 'OFFER'">
                            <mat-select placeholder="Select Type" [(ngModel)]="selectedOffer">
                                <div class="qb-rectangle">
                                    <div class="qb-rect">
                                        <mat-icon class="qb-icon" matPrefix>search</mat-icon>
                                        <input class="qb-no-border-input" type="text" [(ngModel)]="filter.label"
                                            (keydown)="$event.stopPropagation()" placeholder=" Search by name " />
                                    </div>
                                </div>
                                <mat-option *ngFor="let val of offerList | labelPipe: filter.label:'offerName'"
                                    [value]="val.id.toString()" selected>
                                    {{val.offerName |
                                    i18nDynamicTranslate: 'POS_PAGE'}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="mat-select-report-filter" style="width: 250px;" *ngIf="reportType.toUpperCase() === 'JOURNEY'">
                            <mat-select placeholder="Select Type" [(ngModel)]="selectedOffer">
                                <div class="qb-rectangle">
                                    <div class="qb-rect">
                                        <mat-icon class="qb-icon" matPrefix>search</mat-icon>
                                        <input class="qb-no-border-input" type="text" [(ngModel)]="filter.label"
                                            (keydown)="$event.stopPropagation()" placeholder=" Search by name " />
                                    </div>
                                </div>
                                <mat-option *ngFor="let val of offerList | labelPipe: filter.label:'strategyName'"
                                    [value]="val.abNodeId.toString()" selected>
                                    {{val.strategyName |
                                    i18nDynamicTranslate: 'POS_PAGE'}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div *ngIf="selectedOffer.length > 0 && reportType.toUpperCase() === 'OFFER'">
                        <div class="qb-heading-select">
                            Select Status
                        </div>
                        <div class="mat-select-report-filter">
                            <mat-select placeholder="Select Status" [(ngModel)]="selectedStatus" multiple>
                                <div class="qb-all-select" (click)="selectAllOptions(typeOfStatus)">
                                    <mat-checkbox color="primary" style="pointer-events: none;"
                                        [checked]="selectedStatus.length === typeOfStatus.length">All</mat-checkbox>
                                </div>
                                <!-- <mat-option *ngFor="let val of filter.value" [value]="val" selected>{{val}}</mat-option> -->
                                <mat-option *ngFor="let val of typeOfStatus" [value]="val.type" selected>{{val.name |
                                    i18nDynamicTranslate: 'POS_PAGE'}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div *ngIf="selectedOffer.length > 0">
                        <div class="qb-heading-select">
                            Date Range
                        </div>
                        <div class="qb-date-range-picker-wrapper" *ngIf="selectedOffer.length > 0">
                            <mat-form-field appearance="fill">
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="getMaxDate()"
                                    [ngStyle]="range.value.start !== null ? { display: 'none' } : null">
                                    <input matStartDate formControlName="start" placeholder="Start date" readonly>
                                    <input matEndDate formControlName="end" placeholder="End date" readonly
                                        (dateChange)="onEndDateChange($event)">
                                </mat-date-range-input>
                                <div style="display: flex; flex-direction: row;">
                                    <div>{{range.value.start | date: getDateFormat() }}</div>
                                    <span style="margin: 0 4px;" *ngIf="range.value.start != null">-</span>
                                    <div>{{range.value.end | date: getDateFormat() }}</div>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
    
                                <mat-error
                                    *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'ANALYZE_PAGE.INVALID_START_DATE'
                                    | i18nTranslate}}</mat-error>
                                <mat-error
                                    *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'ANALYZE_PAGE.INVALID_END_DATE'
                                    |
                                    i18nTranslate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- <div class="qb-mb-25" style="display: flex; align-items: center;" *ngIf="selectedOffer.length > 0">
                    <button class="primary-button qb-create-new-offer-button" (click)="searchReport()"
                        data-button-name="Create New Offer">
                        Apply Filter
                    </button>
                </div> -->
            </div>
            <div class="qb-ilustration-add-customer">
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/illustrations/Checklist-removebg-preview.png"
                    alt="" style="width: 350px; height:300px; margin-top: 30px">
            </div>
            <div  *ngIf="reportType.toUpperCase() === 'OFFER'" class="warning-message">**The redeemed count will be available after 08 Feb 2024</div>
        </div>
    </div>
</div>
<!-- <div *ngIf="checkForLoyalty === true"
    style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
    <mat-spinner diameter="45"></mat-spinner>
</div> -->