import { Component, OnInit, HostListener, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { assetPath } from 'src/app/configs/asset-path-config';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { CampaignTemplateViewDialogComponent, ConfirmationDialogBoxComponent, DialogOverviewComponent } from 'src/app/shared/components/dialog-box';
import { dataBase } from 'src/app/shared/models/model';


@Component({
  selector: 'app-campaign-template-listing',
  templateUrl: './campaign-template-listing.component.html',
  styleUrls: ['./campaign-template-listing.component.css']
})
export class CampaignTemplateListingComponent implements OnInit {

  masterCampaign;
  scrHeight;
  scrContentHeight;
  scrWidth;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrContentHeight = window.innerHeight - 150;
    this.scrWidth = window.innerWidth;
  }


  constructor(private apiCall: ApiCallService,
    private dataBase: dataBase,
    private errorHandle: ErrorHandler,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private tokenStorage: TokenStorageService) { }

  assetPath = {
    dBinUrl: assetPath.path + "/noun_dustbin_2025484.svg",
    editUrl: assetPath.path + "/noun_edit_958193.svg"
  }


  ngOnInit() {
    this.getScreenSize();
    if (!this.dataBase.merchantId) {
      this.apiCall.fetchUserMerchantData().subscribe((response) => {
        let userPosDetails = JSON.parse(response['body']);
        if (response['message'] === 'failure') {
          this._snackBar.open("Request failed", response['message'])
        }
        else {
          this.dataBase.merchantId = userPosDetails[0]['id'];
          this.getInitData()
        }
      },
        error => {
          this.errorHandle.handleError(error)
        });
    }
    else {
      this.getInitData()
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getInitData() {
    this.apiCall.getMasterCampaigns().subscribe((response) => {
      this.masterCampaign = JSON.parse(response['body']);
    },
      err => {
        this.errorHandle.handleError(err);
      })
  }

  viewCampaignTemplate(position) {
    for (let i = 0; i < this.masterCampaign[position]['campaignActions'].length; i++) {
      //this.masterCampaign[position]['campaignActions'][i]['messageTemplateParams']['messageTemplateId']
      //this.getMessageTemplate(this.masterCampaign[position]['campaignActions'][i]['messageTemplateParams']['messageTemplateId'],i,position)
    }
    const dialogRef = this.dialog.open(CampaignTemplateViewDialogComponent, {
      width: "60%",
      height: "500px",
      data: {
        'type': 'campaignTemplate',
        'data': this.masterCampaign[position]
      }
    })
  }

  getMessageTemplate(id, i, position) {
    this.apiCall.getMessageTemplate(id).subscribe((response) => {

      let template = JSON.parse(response['body'])
      this.masterCampaign[position]['campaignActions'][i]['messageTemplateParams']['messageTemplateId'] = {
        'subject': JSON.parse(template['templateJson'])['subject'],
        'body': JSON.parse(template['templateJson'])['body']
      }
    },
      err => { })
  }

  deleteTemplate(campaignTemplateId) {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      width: "20%",
      data: {
        subject: "Delete?",
        message: "click Ok to Delete",
        cancelButtonText: "Cancel",
        successButtonText: "Ok",
      }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deleteCampaignTemplate(campaignTemplateId).subscribe((response) => {
          this.openSnackBar("Template deleted", "");
          this.getInitData();
        },
          (err) => {
            this._snackBar.open("error" + err)
          })
      }
    })
  }

  launchCampaign(id) {
    let campaignTriggerType;
    for (let i = 0; i < this.masterCampaign.length; i++) {
      if (this.masterCampaign[i]['id'] === id) {
        campaignTriggerType = this.masterCampaign[i]['campaignTrigger']
      }
    }
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: "20%",
      data: {
        'type': 'launchCampaign',
        'triggerType': campaignTriggerType,
      }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.launchCampaignApiCall(id, result)
      }
    })
  }

  launchCampaignApiCall(id, result) {
    for (let i = 0; i < this.masterCampaign.length; i++) {
      if (this.masterCampaign[i]['id'] === id) {
        if (result['type'] === 'TIME_TRIGGER') {
          if (result['repeat']) {
            this.masterCampaign[i]['campaignTrigger']['startDate'] = result['startDate']
            this.masterCampaign[i]['campaignTrigger']['endDate'] = result['endDate'] ? result['endDate'] : null
            this.masterCampaign[i]['campaignTrigger']['daysOfTheWeek'] = result['daysOfTheWeek']
            this.masterCampaign[i]['campaignTrigger']['occurranceCount'] = result['occurrence'] ? result['occurrence'] : null
          }
          else {
            this.masterCampaign[i]['campaignTrigger']['startDate'] = result['startDate']
            this.masterCampaign[i]['campaignTrigger']['endDate'] = result['endDate'] ? result['endDate'] : null
            this.masterCampaign[i]['campaignTrigger']['daysOfTheWeek'] = result['daysOfTheWeek']
            this.masterCampaign[i]['campaignTrigger']['occurranceCount'] = result['occurrence'] ? result['occurrence'] : null
          }
        }
        if (result['type'] === 'EVENT_TRIGGER') {
          this.masterCampaign[i]['campaignTrigger']['startDate'] = result['startDate']
          this.masterCampaign[i]['campaignTrigger']['endDate'] = result['endDate'] ? result['endDate'] : null
          this.masterCampaign[i]['campaignTrigger']['daysOfTheWeek'] = result['daysOfTheWeek']
          this.masterCampaign[i]['campaignTrigger']['occurranceCount'] = result['occurrence'] ? result['occurrence'] : null
        }
        let campaign = {
          "id": id,
          "operation": "create",
          "merchantId": this.tokenStorage.getMerchantId(),
          "campaignTemplate": this.masterCampaign[i]
        }
        this.apiCall.createCampaign(campaign).subscribe((response) => {
          let campaignId = response['body'].split('=')[1]
          let campaign = {
            "campaignId": campaignId,
            "cronExpression": null,
            "events": null
          }
          this.apiCall.launchCampaign(campaign).subscribe((response) => {
            this.openSnackBar("Journey Launched", "");
          },
            err => { })
        },
          err => { })
      }
    }
  }

  updateCampaignTemplate(campaignId) {
    this.router.navigate(['/app/campaign-templates/creation', { id: campaignId }])
  }

}
