import { CreateFormComponent } from './pages/create-form/create-form.component';
import { FormsOverviewComponent } from './pages/forms-overview/forms-overview.component';
import { FormsTemplateListComponent } from './pages/forms-template-list/forms-template-list.component';
import { NewFormComponent } from './pages/new-form/new-form.component';
import { ProspectListComponent } from './pages/prospect-list/prospect-list.component';
import { FormContentEditComponent } from './components/form-content-edit/form-content-edit.component';
import { FormScheduleComponent } from './components/form-schedule/form-schedule.component';
import { SharedModule } from 'src/app/modules/shared/shared-module';
import { AcquireRoutingModule } from './aquire-routing.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsComponent } from './pages';



@NgModule({
  declarations: [
    CreateFormComponent,
    FormsOverviewComponent,
    FormsTemplateListComponent,
    NewFormComponent,
    ProspectListComponent,
    FormContentEditComponent,
    FormScheduleComponent,
    FormsComponent
  ],
  imports: [
    SharedModule,
    AcquireRoutingModule,
    FormsModule,
    RouterModule,
  ]
})
export class AquireModule { }
