import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';




class ComparisonStatement {
  constructor(operand1,logicalOperator?) {
    this.logicalOperator = logicalOperator;
    this.operand1 = operand1;
    this.operator = operand1.operators[0];
    this.operand2.dataType = operand1.dataType.toLowerCase();
  }
  operand1Display;
  operand2Display;
  operand1;
  operand2 = {
    value:null,
    dataType:'',
  };
  operator;
  logicalOperator?;//logicalOperator - Dynamically added if combined with other statements
}

class LogicalExpression {
  constructor(operand1,logicalOperator?) {
    this.logicalOperator = logicalOperator;
    this.comparisonStatements = [new ComparisonStatement(operand1)];
  }
  comparisonStatements :ComparisonStatement[];
  logicalOperator?//logicalOperator - Dynamically added if combined with other logicalExpressions
};



@Component({
  selector: 'app-trigger-additional-config',
  templateUrl: './trigger-additional-config.component.html',
  styleUrls: ['./trigger-additional-config.component.css']
})
export class TriggerAdditionalConfigComponent implements OnInit,OnChanges {
  @Input() showAc;
  @Input() additionalConfigs;
  @Input() preSelectedLogicalExpressions;
  @Input() apiProductList;
  @Input() storeList;
  @Output() cancelEvent = new EventEmitter<void>();
  constructor(private apiCall :ApiCallService,
    private snackBar :SnackbarCollection,
    private _i18nDynamicTranslate :TranslateDynamicText,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //since additionalConfigs is not available during init ngOnChanges is used for init logic
    //reset logical expression and button
    if (changes.additionalConfigs && changes.additionalConfigs.currentValue) {
      if(this.preSelectedLogicalExpressions) {
        this.isAddButtonClicked = true;
        this.logicalExpressions = [];
        this.preSelectLogicalExpressions();
      } else {
        this.logicalExpressions = [];
        this.logicalExpressions.push(new LogicalExpression(this.additionalConfigs[0]));
        this.isAddButtonClicked = false;
      }
    }
  }
  filteredStoreList = [];
  isAddButtonClicked = false;
  logicalExpressions = [];
  storeSearch = '';
  

  preSelectLogicalExpressions() {
    this.preSelectedLogicalExpressions.forEach((LE)=>{
      let curLe = new LogicalExpression(this.additionalConfigs[0], LE.logicalOperator);
      curLe.comparisonStatements = [];
      LE.comparisonStatements.forEach((CS)=>{
        let curCs = new ComparisonStatement(this.additionalConfigs[0],CS.logicalOperator);
          for(let obj of this.additionalConfigs) {
            if(obj.name === CS.operand1) {
              curCs.logicalOperator = CS.logicalOperator;
              curCs.operand1 = obj;
              curCs.operator = obj.operators.find((opObj)=>opObj.operator==CS.operator);
              if(obj.dataType.toLowerCase() == 'boolean') {
                curCs.operand2.value = null;
                curCs.operand2.dataType = 'boolean';
                break;
              } else if(obj.dataType.toLowerCase() == 'date') {
                curCs.operand2.value = new Date(CS.operand2);
                curCs.operand2.dataType = 'date';
                break;
              } else if(obj.dataType.toLowerCase() == 'string') {
                curCs.operand2.value = CS.operand2;
                curCs.operand2.dataType = 'string';
                break;
              } else if(obj.dataType.toLowerCase() == 'product') {
                console.log(this.apiProductList);
                let found = this.apiProductList.find(productName=>productName == CS.operand2);
                curCs.operand2.value = found;
                curCs.operand2.dataType = 'product';
                break;
              } else if(obj.dataType.toLowerCase() == 'store') {
                curCs.operand2.value = CS.operand2;
                curCs.operand2.dataType = 'store';
              } else {
                curCs.operand2.value = CS.operand2;
                curCs.operand2.dataType = 'number';
                break;
              }
            } 
          }
        curLe.comparisonStatements.push(curCs);
      });
      this.logicalExpressions.push(curLe);
    }); 
  }

  selectOp1(comparisonStatement) {
    comparisonStatement.operator = comparisonStatement.operand1.operators[0];
    comparisonStatement.operand2.dataType = comparisonStatement.operand1.dataType.toLowerCase();
  }

  numberInputHandler(event: KeyboardEvent,maxLength) {
    const invalidCharacters = ['e', '-', '+','.'];
    const inputValue = (event.target as HTMLInputElement).value;
    if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key)) {
      event.preventDefault();
    }
  }

  stringInputHandler(event: KeyboardEvent,maxLength) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  addComparisionStatement(logicalExpression) {
    logicalExpression.comparisonStatements.push(new ComparisonStatement(this.additionalConfigs[0],'AND'));
  }

  selectCsCondition(logicalOp,logicalExpression) {
    for(let i=1;i<=logicalExpression.comparisonStatements.length-1;i++) {
      logicalExpression.comparisonStatements[i].logicalOperator = logicalOp;
    }
  }

  deleteComparisonStatement(compIndex,logicalExpression) {
    logicalExpression.comparisonStatements.splice(compIndex,1);
  }

  deleteLogicalExpression(logicalExpIndex) {
    this.logicalExpressions.splice(logicalExpIndex,1);
  }

  addLogicalExpression() {
    this.logicalExpressions.push(new LogicalExpression(this.additionalConfigs[0],'AND'));
  }

  selectLeCondition(logicalOp,logicalExpression) {
    logicalExpression.logicalOperator = logicalOp;
  }

  reset() {
    this.isAddButtonClicked = false;
    this.logicalExpressions = [];
    this.logicalExpressions.push(new LogicalExpression(this.additionalConfigs[0]));
    this.cancelEvent.emit();
  }

  validateEmail(email: string): boolean {
    const regexPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regexPattern.test(email);
  }

  runValidationAndReturnValue() {
    if(!this.isAddButtonClicked) {
      return true;
    }
    for(let logicalExpression of this.logicalExpressions) {
      for(let comparisonStatement of logicalExpression.comparisonStatements) {
        if(comparisonStatement.operand2.type == 'boolean' && 
            (!comparisonStatement.operand1 || !comparisonStatement.operator)) {
          return false;
        }
        if(comparisonStatement.operand2.type != 'boolean') {
          if(comparisonStatement.operand1 
            && comparisonStatement.operator && comparisonStatement.operand2.value != null) {
            if(comparisonStatement.operand2.type == 'email') {
              if(this.validateEmail(comparisonStatement.operand2.value.trim())) {
                comparisonStatement.operand2.value = comparisonStatement.operand2.value.trim();
              } else {
                return false;
              }
            }
            if(comparisonStatement.operand2.type == 'string') {
              if(comparisonStatement.operand2.value.trim()) {
                comparisonStatement.operand2.value = comparisonStatement.operand2.value.trim();
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        }
      }
    }
    return this.getLogicalExpressionsOutput();
  }

  getLogicalExpressionsOutput() {

    let logicalExpressions = [];

    //CONVERTING UI DATASTRUCTURE TO REQUEST DATASTRUCTURE
    this.logicalExpressions.forEach((LE)=>{
      let curLE = {};
      let curCSList = [];
      LE.comparisonStatements.forEach((CS)=>{
        let curCS = {};
        curCS['operandN'] = CS.operandN;
        curCS['operand1'] = CS.operand1.name;
        curCS['operand1Display'] = CS.operand1.variableDisplayName;
        curCS['operator'] = CS.operator.operator;
        curCS['eventType'] = CS.operand1.eventKey;
        if(CS.operand2.dataType == 'date') {
          curCS['operand2'] = 
          this.datePipe.transform(
            CS.operand2.value,
            "yyyy-MM-dd HH:mm:ss"
          );
        } else if(CS.operand2.dataType == 'boolean') {
          curCS['operand2'] = null;
        } else if(CS.operand2.dataType == 'product') {
          curCS['operand2'] = CS.operand2.value; 
        } else if(CS.operand2.dataType == 'store') { 
          curCS['operand2Display'] = this.storeList.find(store=>store.qid == CS.operand2.value).storeName;
          curCS['operand2'] = CS.operand2.value;
        } else {
          curCS['operand2'] = CS.operand2.value;
        }
        if(CS.logicalOperator) curCS['logicalOperator'] = CS.logicalOperator;
        curCSList.push(curCS);
      });
      if(LE.logicalOperator) curLE['logicalOperator'] = LE.logicalOperator;
      curLE['comparisonStatements'] = curCSList;
      logicalExpressions.push(curLE);
    });
    return logicalExpressions;
  }

  onStoreMenuOpened() {
    this.storeSearch = '';
    this.filteredStoreList = this.storeList.filter(store =>
      store.storeName.toLowerCase().includes(this.storeSearch.toLowerCase())
    );
  }

  onProductMenuOpened() {
    this.productSearch = '';
    this.filteredProductList = this.apiProductList.filter(product =>
      product.toLowerCase().includes(this.productSearch.toLowerCase())
    );
  }

  filteredProductList = [];
  productSearch;
  filterProduct() {
    this.filteredProductList = this.apiProductList.filter(product =>
      product.toLowerCase().includes(this.productSearch.toLowerCase())
    );
  }

  filterStore() {
    this.filteredStoreList = this.storeList.filter(store =>
      store.storeName.toLowerCase().includes(this.storeSearch.toLowerCase())
    );
  }

  setStoreId(qid,comparisonStatement) {
    comparisonStatement.operand2.value = qid;
  }

  setProduct(productName,comparisonStatement) {
    comparisonStatement.operand2.value = productName;
  }

  getSelectedStore(comparisonStatement) {
    return this.storeList.find((store)=>store.qid == comparisonStatement.operand2.value)?.storeName;
  }

}
