import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { PreviewTemplateComponent } from "src/app/shared/components/dialog-box";

export interface scheduleDataVar {
  showTo: string;
  showWhen: string;
  showIfEmpty: boolean;
  deviceVisibility: string;
  showOn: string;
  showOnSpecificUrl: string[];
  selectOtherUrl: string;
  limit: string;
  limitvalue: number;
}
@Component({
  selector: "app-form-schedule",
  templateUrl: "./form-schedule.component.html",
  styleUrls: ["./form-schedule.component.css"],
})
export class FormScheduleComponent implements OnInit {
  constructor(private apiCall: ApiCallService, private _dialog: MatDialog) {}
  data = {
    showTo: "",
    segments: [],
    showWhen: "",
    showWhenValue: 0,
    deviceVisibility: "",
    showOn: "",
    showOnSpecificUrl: [],
    selectOtherUrl: [],
    limit: "",
    limitvalue: 0,
  };
  invalidShowWhenValue = false;
  disableAllCustomerSelection = false;
  disableCustomerSegmentSelection = false;
  selectedSegments = [];
  segments = [];
  tempsegmentFilter = "";

  allcustomerObj = [{ id: "ALL_CUSTOMERS", name: "My Customers" }];
  initialized: boolean = false;
  @Input() scheduleConstants;
  @Input() scheduleData;
  @Input() contentData;
  @Output() sheduleDataChange = new EventEmitter<any>();

  ngOnInit() {
    this.getFormConstants();
    this.data = this.scheduleData;
    this.initialized = true;
    this.getCustomerData();
  }

  getFormConstants() {
    this.apiCall.getFormConstants().subscribe((response) => {
      this.scheduleConstants = JSON.parse(response["body"]);
      this.contentData.emailTemplateId =
        this.scheduleConstants.configurations.content.noCoupon.id;
      this.contentData.emailTemplateName =
        this.scheduleConstants.configurations.content.noCoupon.emailLabel;
    });
  }

  changeSegmentSelection() {
    if (this.data.segments.length === 1) {
      if (this.data.segments[0] === "ALL_CUSTOMERS") {
        this.disableCustomerSegmentSelection = true;
      } else {
        this.disableAllCustomerSelection = true;
      }
    } else if (this.data.segments.length === 0) {
      this.selectedSegments = [];
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
    }
  }

  getCustomerData() {
    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      let segment = JSON.parse(response["body"]);
      for (let i = 0; i < segment.length; i++) {
        if (segment[i]["isActive"] === true) {
          this.segments.push({
            id: segment[i]["id"],
            name: segment[i]["segmentName"],
            status: false,
          });
        }
      }
    });
  }

  openEmailPreviewDialog() {
    this.apiCall
      .getEmailLayoutDetail(this.contentData.emailTemplateId)
      .subscribe((response) => {
        let emailTemplate = JSON.parse(response["body"]);
        let data = {
          selectedLayout: emailTemplate["layoutHtml"],
          templateId: emailTemplate["id"],
          templateName: emailTemplate["name"],
        };
        this.openTemplatePreviewDialog(data, "EMAIL");
      });
  }

  openTemplatePreviewDialog(objectData, type) {
    let finalScreenHeight = window.innerHeight;
    let finalScreenWidth = window.innerWidth;
    let sh = finalScreenHeight * (90 / 100);
    let sw = finalScreenHeight * (70 / 100);
    const dialogRef = this._dialog.open(PreviewTemplateComponent, {
      panelClass: "previewDialog",
      height: sh + "px",
      width: finalScreenWidth < 1500 ? "1024px" : sw + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      disableClose: true,
      autoFocus: false,
      data: { obj: objectData, type: type, height: finalScreenHeight },
    });
  }
}
