<div
  id="mainContainer"
  #mainContainer
  class="qb-main-container"
  *ngIf="dataFetchComplete && dataFillComplete"
>
  <div id="queryResults" data-widget-name="SEGMENT CREATION">
    <div *ngIf="!queryloading">
      <div class="qb-preview-heading-div" #previewHeading>
        <span class="qb-preview-inner-text">{{'SEGMENT_PAGE.PREVIEW' | i18nTranslate}}</span>
        <mat-icon
          class="qb-preview-close-button"
          (click)="queryResultsDisplayToggle()"
          >close</mat-icon
        >
      </div>
      <div [style.height.px]="previewHeadingHeight" class="qb-of-auto">
        <div class="qb-segment-result-container">
          <div *ngIf="isExpanded" class="qb-p-10">
            <div *ngIf="isExpanded" class="qb-fs-12">
              {{'SEGMENT_PAGE.SEGMENT_COUNT' | i18nTranslate}} -
              <span class="qb-fw-600">{{ customerCount }}</span>
            </div>
          </div>
          <div *ngIf="isExpanded && customerCount != 0">
            <div *ngIf="customerPercentage">
              <circle-progress
                [radius]="24"
                [percent]="customerPercentage"
                [maxPercent]="100"
                [space]="-3"
                [outerStrokeWidth]="3"
                [outerStrokeColor]="'#00BABE'"
                [outerStrokeLinecap]="square"
                [innerStrokeColor]="'#636466'"
                [innerStrokeWidth]="3"
                [animateTitle]="true"
                [animationDuration]="10"
                [showUnits]="true"
                [showBackground]="false"
                [startFromZero]="false"
                [titleFontSize]="10"
                [lazy]="false"
                [showSubtitle]="false"
                [toFixed]="2"
              ></circle-progress>
            </div>
          </div>
        </div>
        <div *ngIf="customerCount == 0">
          <img
            src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/noconfiguration.svg"
            width="100px"
            height="119px"
            class="qb-m-20-120"
          />
        </div>
        <div
          *ngIf="customerCount != 0 && store === 'shopify'"
          class="qb-preview-split-line"
        ></div>
        <div
          *ngIf="customerCount != 0 && store === 'shopify'"
          class="qb-spend-div"
        >
          <span>{{'SEGMENT_PAGE.SPEND_DISTRIBUTION' | i18nTranslate}}</span>
        </div>
        <div
          *ngIf="customerCount != 0 && store === 'shopify'"
          class="qb-d-flex"
        >
          <div *ngIf="topicGraphData" class="qb-graph-area">
            <app-donut-chart
              [data]="topicGraphData"
              [totalCount]="queryAverageSpendTotal"
              idn="nps3"
            >
            </app-donut-chart>
          </div>
          <div class="qb-preview-count-container">
            <div class="qb-w-100">
              <div class="qb-donut-content">
                <div class="qb-donut-parent">
                  <div class="qb-donut-green"></div>
                  <span class="qb-ml-5-px">{{'SEGMENT_PAGE.HIGH' | i18nTranslate}} </span>
                </div>
                <span class="qb-fw-600">{{ queryAvgSpends.high }}</span>
              </div>
              <div class="qb-donut-content">
                <div class="qb-donut-parent">
                  <div class="qb-donut-yellow"></div>
                  <span class="qb-ml-5-px">{{'SEGMENT_PAGE.MEDIUM' | i18nTranslate}}</span>
                </div>
                <span class="qb-fw-600">{{ queryAvgSpends.medium }}</span>
              </div>
              <div class="qb-donut-content">
                <div class="qb-donut-parent">
                  <div class="qb-donut-red"></div>
                  <span class="qb-ml-5-px">{{'SEGMENT_PAGE.LOW' | i18nTranslate}}</span>
                </div>
                <span class="qb-fw-600">{{ queryAvgSpends.low }}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="customerCount == 0" class="qb-no-customer-text">
          {{'SEGMENT_PAGE.NO_CUSTOMER_OVERLAP' | i18nTranslate}}
        </div>

        <div *ngIf="customerCount != 0" class="qb-preview-split-line"></div>
        <div *ngIf="isExpanded" class="qb-customer-overlap-text">
          <div
            class="qb-customer-overlap-heading-text"
            *ngIf="querySegmentCount.length > 0 && querysegmentTotal > 0"
          >
            {{'SEGMENT_PAGE.CUSTOMER_OVERLAP' | i18nTranslate}}
          </div>
          <div class="qb-customer-overlap-container">
            <div class="qb-w-100">
              <div *ngFor="let segment of querySegmentCount; let i = index">
                <div *ngIf="segment['Value'] != 0" class="qb-m-20-0">
                  <div class="qb-customer-overlap-text-count">
                    <span>{{ segment["Name"] }}</span>
                    <span class="qb-fw-600"
                      >{{ segment["Value"] }}
                      <span class="qb-customer-count-text"
                        >/{{ customerCount }}</span
                      ></span
                    >
                  </div>
                  <div class="qb-overlap-background">
                    <div
                      [style.width.%]="segment['Percent']"
                      class="qb-overlap-foreground"
                    ></div>
                  </div>
                </div>
                <!-- <mat-progress-bar
                  class="qb-liveSegmentCount"
                  mode="determinate"
                  [value]="segment['Percent']"
                  *ngIf="segment['Value'] != 0"
                >
                </mat-progress-bar> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="segmentCreationRef" class="qb-w-100" [formGroup]="segment">
    <div #mainHeader class="qb-main-header" data-widget-name="SEGMENT CREATION">
      <div class="qb-main-header-name-div">
        <div
          class="qb-w-70"
          *ngIf="
            templateId === 'null' ||
            cloneStatus === true
          "
        >
          <div
            class="qb-formDiv"
            [ngClass]="{
              active:
                (segment.controls.segmentName.errors
                  ? segment.controls.segmentName.errors.maxlength
                  : false) ||
                (segment.controls.segmentName.errors
                  ? (segment.controls.segmentName.errors.required &&
                      segment.controls.segmentName.touched) ||
                    submitClicked
                  : false)
            }"
          >
            <input
              placeholder="Enter Segment Name"
              class="qb-formInput"
              type="text"
              formControlName="segmentName"
            />
          </div>
          <div class="qb-h-10-px">
            <span
              class="qb-error-text"
              *ngIf="
                segment.controls.segmentName.errors
                  ? segment.controls.segmentName.errors.required &&
                    (segment.controls.segmentName.touched || submitClicked)
                  : false
              "
              >{{'SEGMENT_PAGE.ENTER_SEGMENT_NAME' | i18nTranslate}}</span
            >
            <span
              class="qb-error-text"
              *ngIf="
                segment.controls.segmentName.errors
                  ? segment.controls.segmentName.errors.maxlength
                  : false
              "
              >{{'SEGMENT_PAGE.MAX_50_ALLOWED' | i18nTranslate}}</span
            >
            <span
              class="qb-error-text"
              *ngIf="
                segment.controls.segmentName.errors
                  ? segment.controls.segmentName.errors.pattern && submitClicked
                  : false
              "
              >{{'SEGMENT_PAGE.ENTER_VALID_SEGMENT_NAME' | i18nTranslate}}</span
            >
          </div>
        </div>
        <div
        *ngIf="store !== 'shopify' && qId == null"
          (click)="viewStore()"
          class="view-selected-stores-text"
        >
          {{'SEGMENT_PAGE.VIEW_SELECTED_STORE' | i18nTranslate}}
        </div>
        <div
          class="qb-segment-name-text"
          *ngIf="
            templateId !== 'null' &&
            cloneStatus === false
          "
        >
          <span class="qb-create-new-segment-text">{{
            segmentDetail["segmentName"]
          }}</span>
        </div>
      </div>
      <div class="qb-header-button-container">
        <div>
          <button
            class="secondary-button-border"
            appButtonDebounce
            [throttleTime]="2000"
            (throttledClick)="getQueryDetails()"
            data-button-name="Preview"
          >
            <span
              *ngIf="!previewLoading"
              class="material-icons-outlined qb-button-icon icons-primary"
            >
              visibility
            </span>
            <span *ngIf="!previewLoading" class="qb-preview-text" style="text-transform: capitalize;">{{'SEGMENT_PAGE.PREVIEW' | i18nTranslate}}</span>
            <div
              *ngIf="previewLoading"
              style="display: flex; justify-content: center; width: 100%"
            >
              <mat-spinner diameter="15"></mat-spinner>
            </div>
          </button>
        </div>
        <div class="qb-ml-15">
          <div
            class="qb-primary-button primary-button"
            appButtonDebounce
            [throttleTime]="2000"
            (throttledClick)="submitSegmentData()"
            id="segment-create-edit"
            data-button-name="Save Segment"
          >
            <span
              class="material-icons-outlined qb-button-icon"
              id="segment-create-edit-i"
            >
              save
            </span>
            <span class="qb-save-segment-text" id="segment-create-edit-t"
              >{{'SEGMENT_PAGE.SAVE' | i18nTranslate}}</span
            >
          </div>
        </div>
        <div
          class="qb-add-condition-button-container-mid qb-ml-15"
          style="margin-top: 0px"
          *ngIf="store !== 'shopify' && qId == null"
        >
          <span
            (click)="addStoreData()"
            class="primary-button qb-primary-button"
            data-button-name=" Add stores"
          >
            <span class="material-icons-outlined qb-button-icon"> add </span>
            {{'SEGMENT_PAGE.ADD_STORES' | i18nTranslate}}
          </span>
        </div>
      </div>
    </div>
    <div
      *ngIf="createSegmentStatus"
      class="qb-create-container"
      [style.height.px]="createContainerHeight"
      data-widget-name="SEGMENT CONFIGURATION"
    >
      <div class="qb-segmentSet">
        <div>
          <div>
            <div formArrayName="segmentGroupArray">
              <div>
                <div
                  class="qb-group-container"
                  *ngFor="
                    let group of segment.get('segmentGroupArray').controls;
                    let i = index
                  "
                >
                  <div *ngIf="i > 0" class="qb-group-operator-container">
                    <div class="qb-d-flex qb-jc-center">
                      <div class="qb-group-and-operator-background">
                        <div
                          class="qb-segment-group-logical-operator-container qb-operator-radius"
                          (click)="relationToggle('AND', i)"
                          [ngClass]="{
                            active:
                              segment.get('segmentGroupArray')['controls'][i][
                                'controls'
                              ]['relation']['value'] === 'AND'
                          }"
                          data-button-name="Condition: AND"
                        >
                          <span class="qb-operator-text">{{'SEGMENT_PAGE.AND' | i18nTranslate}}</span>
                        </div>
                        <div
                          class="qb-segment-group-logical-operator-container qb-operator-radius-2"
                          (click)="relationToggle('OR', i)"
                          [ngClass]="{
                            active:
                              segment.get('segmentGroupArray')['controls'][i][
                                'controls'
                              ]['relation']['value'] === 'OR'
                          }"
                          data-button-name="Condition: OR"
                        >
                          <span class="qb-operator-text">{{'SEGMENT_PAGE.OR' | i18nTranslate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    [formGroupName]="i"
                    class="qb-segment-rule-container-inner1"
                  >
                    <div class="qb-formDivGates">
                      <div class="qb-w-100">
                        <div class="qb-and-or-button-container">
                          <div class="qb-d-flex">
                            <div
                              class="qb-d-flex"
                              *ngIf="
                                segment
                                  .get('segmentGroupArray')
                                  ['controls'][i].get('segmentConditionArray')
                                  .length > 1
                              "
                            >
                              <span
                                class="qb-inner-relation-button qb-operator-radius"
                                (click)="changeInnerRelation(i, 'AND')"
                                [ngClass]="{
                                  active:
                                    segment.controls.segmentGroupArray.controls[
                                      i
                                    ].controls.innerRelation.value === 'AND'
                                }"
                                data-button-name="Condition: AND"
                              >
                              {{'SEGMENT_PAGE.AND' | i18nTranslate}}
                              </span>
                              <span
                                class="qb-inner-relation-button qb-operator-radius-2"
                                (click)="changeInnerRelation(i, 'OR')"
                                [ngClass]="{
                                  active:
                                    segment.controls.segmentGroupArray.controls[
                                      i
                                    ].controls.innerRelation.value === 'OR'
                                }"
                                data-button-name="Condition: OR"
                              >
                              {{'SEGMENT_PAGE.OR' | i18nTranslate}}
                              </span>
                            </div>
                          </div>
                          <div class="qb-add-condition-container">
                            <mat-icon
                              class="qb-add-condition-icon"
                              (click)="addCondition(i)"
                              data-button-name="Add Condition"
                              >add</mat-icon
                            >
                            <span (click)="addCondition(i)">{{'SEGMENT_PAGE.ADD_CONDITION' | i18nTranslate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div formArrayName="segmentConditionArray">
                      <div
                        class="qb-w-100"
                        *ngFor="
                          let condition of segment.controls.segmentGroupArray
                            .controls[i].controls.segmentConditionArray
                            .controls;
                          let j = index
                        "
                      >
                        <!-- <div
                          style="display: flex; padding: 1%"
                          *ngIf="
                            j ===
                            segment
                              .get('segmentGroupArray')
                              ['controls'][i].get('segmentConditionArray')[
                              'controls'
                            ].length -
                              1
                          "
                        ></div> -->
                        <div *ngIf="j > 0">
                          <div>
                            <div class="qb-p-relative">
                              <div
                                class="qb-AND-operator-background"
                                *ngIf="
                                  segment
                                    .get('segmentGroupArray')
                                    ['controls'][i].get(
                                      'segmentConditionArray'
                                    )['controls'][j]['controls']['relation'][
                                    'value'
                                  ] === 'AND'
                                "
                              >
                                <div
                                  class="qb-segment-logical-operator-container"
                                >
                                  <span class="qb-operator-text">{{'SEGMENT_PAGE.AND' | i18nTranslate}}</span>
                                </div>
                              </div>
                              <div
                                class="qb-AND-operator-background"
                                *ngIf="
                                  segment
                                    .get('segmentGroupArray')
                                    ['controls'][i].get(
                                      'segmentConditionArray'
                                    )['controls'][j]['controls']['relation'][
                                    'value'
                                  ] === 'OR'
                                "
                              >
                                <div
                                  class="qb-segment-logical-operator-container"
                                >
                                  <span class="qb-operator-text">{{'SEGMENT_PAGE.OR' | i18nTranslate}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div [formGroupName]="j" class="qb-w-100">
                          <div class="qb-d-flex qb-fd-coloumn">
                            <div class="qb-d-flex qb-jc-flex-end">
                              <div class="qb-icons-container"></div>
                            </div>
                            <div class="qb-delete-icon-container">
                              <div class="qb-segment-rule-form-container">
                                <div class="qb-rule-cancel-button">
                                  <span
                                    class="material-icons-outlined icons-primary qb-rule-cancel-icon"
                                    data-button-name="Delete condition"
                                    *ngIf="
                                      segment
                                        .get('segmentGroupArray')
                                        ['controls'][i].get(
                                          'segmentConditionArray'
                                        ).length > 1
                                    "
                                    [matTooltip]="'COMMON_PAGE.DELETE_CONDITION' | i18nTranslate"
                                    (click)="removeCondition(i, j)"
                                  >
                                    delete
                                  </span>
                                </div>
                                <div class="qb-form-container">
                                  <div class="qb-w-90">
                                    <span class="qb-input-header-text"
                                      >{{'SEGMENT_PAGE.PARAMETER' | i18nTranslate}}</span
                                    >
                                    <div class="qb-formDiv2">
                                      <!-- <mat-select
                                        class="qb-formInput"
                                        formControlName="attribute"
                                        (selectionChange)="
                                          refreshConditionArray(i, j);
                                          valueSuffix(i, j)
                                        "
                                      >
                                        <div>
                                          <div>
                                            <input
                                              type="text"
                                              formControlName="attributeSearch"
                                            />
                                          </div>
                                          <mat-option

                                            *ngFor="
                                              let attribute of segmentVariables
                                                | labelPipe
                                                  : segment
                                                      .get('segmentGroupArray')
                                                      ['controls'][i].get(
                                                        'segmentConditionArray'
                                                      )['controls'][j][
                                                      'controls'
                                                    ]['attributeSearch'][
                                                      'value'
                                                    ]
                                                  : 'displayName'
                                            "
                                            [value]="attribute['name']"
                                          >
                                            {{ attribute["displayName"] }}
                                          </mat-option>
                                        </div>
                                      </mat-select> -->
                                      <!-- <input (focus)="openDropDown()" type="text" formControlName="attribute"> -->
                                      <div>
                                        <div
                                          class="qb-multi-select-panel"
                                          [id]="'multi-select-panel' + i + j"
                                          (click)="toggleSelect($event, i, j)"
                                        >
                                          <span class="qb-attribute-display">{{
                                            getAttributeDisplayName(i, j)
                                          }}</span>
                                          <div
                                            class="qb-keyboard-down-icon-container"
                                            [id]="
                                              'keyboard-down-icon-container' +
                                              i +
                                              j
                                            "
                                          >
                                            <mat-icon
                                              class="qb-keyboard-down-icon"
                                              >keyboard_arrow_down</mat-icon
                                            >
                                          </div>
                                        </div>
                                        <div class="qb-p-relative">
                                          <div
                                            class="qb-multi-select-dropdown"
                                            [id]="
                                              'multi-select-dropdown' + i + j
                                            "
                                          >
                                            <div class="qb-search">
                                              <input
                                                #searchAttribute
                                                formControlName="attributeSearch"
                                                placeholder="Search Parameter"
                                                type="text"
                                                class="qb-input-text-area"
                                              />
                                              <span
                                                class="material-icons-outlined icons-primary"
                                                *ngIf="
                                                  searchAttribute.length == 0
                                                "
                                                >search</span
                                              >
                                              <span
                                                class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
                                                [id]="
                                                'close' + i + j
                                              "
                                                *ngIf="
                                                  searchAttribute.value.length >
                                                  0
                                                "
                                                (click)="
                                                  searchAttribute.value = '';emptyAttributeinputField(i, j);
                                                "
                                              >
                                                close
                                              </span>
                                            </div>
                                            <div class="qb-border-line"></div>
                                            <div
                                              [id]="'segment-select' + i + j"
                                              class="qb-segment-select"
                                            >
                                              <div>
                                                <div
                                                  *ngFor="
                                                    let item of segmentVariables
                                                      | keyvalue
                                                  "
                                                >
                                                  <div
                                                    class="qb-segment-param-key"
                                                  >
                                                    <span
                                                      class="qb-segment-param-key-text"
                                                      >{{ item.key }}</span
                                                    >
                                                    <div
                                                      class="qb-segment-param-key-line"
                                                    ></div>
                                                  </div>
                                                  <div
                                                    (click)="
                                                      selectAttributeFromDropdown(
                                                        i,
                                                        j,
                                                        item.key,
                                                        attribute,
                                                        item
                                                      )
                                                    "
                                                    class="qb-event-list"
                                                    *ngFor="
                                                      let attribute of segmentVariables[
                                                        item.key
                                                      ]
                                                        | labelPipe
                                                          : segment
                                                              .get(
                                                                'segmentGroupArray'
                                                              )
                                                              ['controls'][
                                                                i
                                                              ].get(
                                                                'segmentConditionArray'
                                                              )['controls'][j][
                                                              'controls'
                                                            ][
                                                              'attributeSearch'
                                                            ]['value']
                                                          : 'displayName'
                                                    "
                                                  >
                                                    <span>{{
                                                      attribute["displayName"]
                                                    }}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="qb-form-container">
                                  <div class="qb-w-90">
                                    <span class="qb-input-header-text"
                                      >{{'SEGMENT_PAGE.CONDITIONS' | i18nTranslate}}</span
                                    >
                                    <div class="qb-formDiv2">
                                      <!-- <mat-select
                                        class="qb-formInput"
                                        formControlName="condition"
                                        (selectionChange)="
                                          refreshConditionArray(i, j);
                                          valueSuffix(i, j)
                                        "
                                      >
                                        <mat-option

                                          *ngFor="
                                            let condition of segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['operators']
                                          "
                                          [value]="condition['operator']"
                                        >
                                          {{ condition["operator"] }}
                                        </mat-option>
                                      </mat-select> -->
                                      <div>
                                        <div
                                          class="qb-multi-select-panel"
                                          [id]="
                                            'multi-select-panel-condition' +
                                            i +
                                            j
                                          "
                                          (click)="
                                            toggleSelectCondition($event, i, j)
                                          "
                                        >
                                          <span class="qb-attribute-display">{{
                                            segment
                                              .get("segmentGroupArray")
                                              ["controls"][i].get(
                                                "segmentConditionArray"
                                              )["controls"][j]["controls"][
                                              "condition"
                                            ]["value"]
                                          }}</span>
                                          <div
                                            class="qb-keyboard-down-icon-container"
                                            [id]="
                                              'keyboard-down-icon-container-condition' +
                                              i +
                                              j
                                            "
                                          >
                                            <mat-icon
                                              class="qb-keyboard-down-icon"
                                              >keyboard_arrow_down</mat-icon
                                            >
                                          </div>
                                        </div>
                                        <div class="qb-p-relative">
                                          <div
                                            class="qb-multi-select-dropdown"
                                            [id]="
                                              'multi-select-dropdown-condition' +
                                              i +
                                              j
                                            "
                                          >
                                            <!-- <div class="qb-search">
                                              <mat-icon class="qb-group-icon"
                                                >search</mat-icon
                                              >
                                              <input
                                                formControlName="attributeSearch"
                                                placeholder="Search Event"
                                                type="text"
                                                style="
                                                  border: none;
                                                  outline: none;
                                                "
                                              />
                                            </div>
                                            <div class="qb-border-line"></div> -->
                                            <div
                                              [id]="
                                                'segment-select-condition' +
                                                i +
                                                j
                                              "
                                              class="qb-segment-select"
                                            >
                                              <div>
                                                <div
                                                  (click)="
                                                    selectRelationFromDropdown(
                                                      i,
                                                      j,
                                                      condition
                                                    )
                                                  "
                                                  class="qb-event-list"
                                                  *ngFor="
                                                    let condition of segmentVariables[
                                                      segment
                                                        .get(
                                                          'segmentGroupArray'
                                                        )
                                                        ['controls'][i].get(
                                                          'segmentConditionArray'
                                                        )['controls'][j][
                                                        'controls'
                                                      ]['attributeKey']['value']
                                                    ][
                                                      segment
                                                        .get(
                                                          'segmentGroupArray'
                                                        )
                                                        ['controls'][i].get(
                                                          'segmentConditionArray'
                                                        )['controls'][j][
                                                        'controls'
                                                      ]['attributeIndex'][
                                                        'value'
                                                      ]
                                                    ]['operators']
                                                  "
                                                >
                                                  <span>{{
                                                    condition["operator"]
                                                  }}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="qb-form-container">
                                  <div class="qb-w-90">
                                    <span class="qb-input-header-text"
                                      >{{'SEGMENT_PAGE.VALUE' | i18nTranslate}}</span
                                    >
                                    <!-- <div
                                      *ngIf="
                                        segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get(
                                            'segmentConditionArray'
                                          )['controls'][j]['controls'][
                                          'attribute'
                                        ]['value'] === '$gender'
                                      "
                                    >
                                      <div class="qb-formDiv2">
                                        <mat-select
                                          class="qb-formInput "
                                          formControlName="value"
                                        >
                                          <mat-option

                                            value="Male"
                                          >
                                            Male</mat-option
                                          >
                                          <mat-option

                                            value="Female"
                                          >
                                            Female</mat-option
                                          >
                                        </mat-select>
                                      </div>
                                    </div> -->
                                    <div
                                      *ngIf="
                                        segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get(
                                            'segmentConditionArray'
                                          )['controls'][j]['controls'][
                                          'attribute'
                                        ]['value'] !== '$gender'
                                      "
                                      (onload)="valueSuffix(i, j)"
                                    >
                                      <div
                                      *ngIf="
                                      segmentVariables[
                                        segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get(
                                            'segmentConditionArray'
                                          )['controls'][j]['controls'][
                                          'attributeKey'
                                        ]['value']
                                      ][
                                        segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get(
                                            'segmentConditionArray'
                                          )['controls'][j]['controls'][
                                          'attributeIndex'
                                        ]['value']
                                      ]['dataType'] !== 'Product'
                                    "
                                        class="qb-formDiv qb-input-padding-container"
                                        [ngClass]="{
                                          active:
                                            ((segment.controls.segmentGroupArray
                                              .controls[i].controls
                                              .segmentConditionArray.controls[j]
                                              .controls['value'].errors &&
                                              segment.controls.segmentGroupArray
                                                .controls[i].controls
                                                .segmentConditionArray.controls[
                                                j
                                              ].controls['value'].touched) ||
                                            (segment.controls.segmentGroupArray
                                              .controls[i].controls
                                              .segmentConditionArray.controls[j]
                                              .controls['value'].errors &&
                                              (submitClicked || previewClicked))) && 
                                              segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType'] !== 'Boolean'
                                        }"
                                      >
                                        <input
                                          type="text"
                                          (keypress)="
                                            keypresshandler(i, j, $event)
                                          "
                                          class="qb-formInput"
                                          *ngIf="
                                            segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType'] === 'String' ||
                                            segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType'] === 'Number'
                                          "
                                          [type]="
                                            segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType']
                                          "
                                          formControlName="value"
                                          (input)="valueSuffix(i, j)"
                                        />
                                        <div style="font-size: 12px; color: #212529;" *ngIf="
                                        segmentVariables[
                                          segment
                                            .get('segmentGroupArray')
                                            ['controls'][i].get(
                                              'segmentConditionArray'
                                            )['controls'][j]['controls'][
                                            'attributeKey'
                                          ]['value']
                                        ][
                                          segment
                                            .get('segmentGroupArray')
                                            ['controls'][i].get(
                                              'segmentConditionArray'
                                            )['controls'][j]['controls'][
                                            'attributeIndex'
                                          ]['value']
                                        ]['dataType'] === 'Boolean'
                                      ">
                                        Not Required
                                        </div>
                                        <span
                                          matSuffix
                                          [id]="'pvalue-' + i + '-' + j"
                                          class="qb-option-text-div"
                                        ></span>
                                        <!-- <mat-select
                                          class="qb-formInput"
                                          formControlName="value"
                                          *ngIf="
                                            segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType'] === 'Boolean'
                                          "
                                        >
                                          <mat-option value="True">
                                            True</mat-option
                                          >
                                          <mat-option value="False">
                                            False</mat-option
                                          >
                                        </mat-select> -->
                                        <div
                                          class="qb-d-flex qb-h-90 qb-ai-center"
                                          *ngIf="
                                            segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType'] === 'Date'
                                          "
                                        >
                                          <input
                                            formControlName="value"
                                            class="qb-formInput qb-ml-5"
                                            matInputplaceholder="Choose a date"
                                            [matDatepicker]="picker1"
                                            readonly
                                            (click)="picker1.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker1"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker1>
                                          </mat-datepicker>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                    class="qb-d-flex qb-ai-center"
                                    *ngIf="
                                      segmentVariables[
                                        segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get(
                                            'segmentConditionArray'
                                          )['controls'][j]['controls'][
                                          'attributeKey'
                                        ]['value']
                                      ][
                                        segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get(
                                            'segmentConditionArray'
                                          )['controls'][j]['controls'][
                                          'attributeIndex'
                                        ]['value']
                                      ]['dataType'] === 'Product'
                                    "
                                  >
                                    <!-- <input
                                      formControlName="value"
                                      class="qb-formInput qb-ml-5"
                                      matInputplaceholder="Choose a date"
                                      [matDatepicker]="picker1"
                                      readonly
                                      (click)="picker1.open()"
                                    />
                                    <mat-datepicker-toggle
                                      matSuffix
                                      [for]="picker1"
                                    >
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1>
                                    </mat-datepicker> -->
                                    <div class="qb-formDiv2" style="width: 100%;">
                                      <div>
                                        <div
                                          class="qb-multi-select-panel"
                                          [id]="'multi-select-panel-product' + i + j"
                                          (click)="toggleSelectProduct($event, i, j)"
                                        >
                                          <span class="qb-attribute-display">{{
                                            getproductDisplayName(i, j)
                                          }}</span>
                                          <div
                                            class="qb-keyboard-down-icon-container"
                                            [id]="
                                              'keyboard-down-icon-container-product' +
                                              i +
                                              j
                                            "
                                          >
                                            <mat-icon
                                              class="qb-keyboard-down-icon"
                                              >keyboard_arrow_down</mat-icon
                                            >
                                          </div>
                                        </div>
                                        <div class="qb-p-relative">
                                          <div
                                            class="qb-multi-select-dropdown"
                                            [id]="
                                              'multi-select-dropdown-product' + i + j
                                            "
                                          >
                                            <div class="qb-search">
                                              <input
                                                #searchProduct
                                                placeholder="Search Product"
                                                type="text"
                                                class="qb-input-text-area"
                                                formControlName="productSearch"
                                                (input)="getInputField($event.target.value, i, j);"
                                              />
                                              <span
                                                class="material-icons-outlined icons-primary"
                                                *ngIf="
                                                searchProduct.length == 0
                                                "
                                                >search</span
                                              >
                                              <span
                                                class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
                                                [id]="
                                                'close-product' + i + j
                                              "
                                                *ngIf="
                                                searchProduct.value.length >
                                                  0
                                                "
                                                (click)="
                                                searchProduct.value = ''; getInputField('', i, j)
                                                "
                                              >
                                                close
                                              </span>
                                            </div>
                                            <div class="qb-border-line"></div>
                                            <div
                                              [id]="'segment-select-product' + i + j"
                                              class="qb-segment-select"
                                              (scroll)="onScroll($event)"
                                            >
                                              <div>
                                                  <div
                                                    (click)="
                                                      selectProductFromDropdown(
                                                        i,
                                                        j,
                                                       product
                                                      )
                                                    "
                                                    class="qb-event-list"
                                                    *ngFor="
                                                      let product of apiProductList
                                                    "
                                                  >
                                                    <span>{{
                                                      product.productName
                                                    }}</span>
                                                  </div>
                                                  <div *ngIf="apiProductList.length === 0 && loading" class="qb-mat-spinner">
                                                    <mat-spinner diameter="25"></mat-spinner>
                                                  </div>
                                                  <div class="qb-no-data" *ngIf="apiProductList.length === 0 && !loading">
                                                    {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                                <div class="qb-p-relative">
                                  <div
                                    class="qb-error-popup"
                                    *ngIf="
                                     (segment.controls.segmentGroupArray
                                        .controls[i].controls
                                        .segmentConditionArray.controls[j]
                                        .controls['value'].errors !=null &&
                                        (segment.controls.segmentGroupArray
                                          .controls[i].controls
                                          .segmentConditionArray.controls[j]
                                          .controls['value'].touched ||
                                        submitClicked || previewClicked)) && 
                                        (segmentVariables[
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeKey'
                                              ]['value']
                                            ][
                                              segment
                                                .get('segmentGroupArray')
                                                ['controls'][i].get(
                                                  'segmentConditionArray'
                                                )['controls'][j]['controls'][
                                                'attributeIndex'
                                              ]['value']
                                            ]['dataType'] !== 'Boolean' ||
                                            (this.segment
                                            .get('segmentGroupArray')
                                            ['controls'][i].get('segmentConditionArray')['controls'][
                                            j
                                          ]['value'].value === null || this.segment
                                          .get('segmentGroupArray')
                                          ['controls'][i].get('segmentConditionArray')['controls'][
                                          j
                                        ]['value'].value === ''))
                                    "
                                  >
                                    {{ getErrorMessage(i, j) }}
                                  </div>
                                </div>
                                <!-- <mat-icon
                                  class="qb-info-icon-class"
                                  [id]="'id' + i + j"
                                  (click)="openInfoTooltip(i, j)"
                                  >info
                                </mat-icon> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="qb-rule-cancel-button">
                    <span
                      class="material-icons-outlined icons-primary cursor-pointer"
                      data-button-name="Delete group"
                      *ngIf="segment.get('segmentGroupArray').length > 1"
                      (click)="removeGroup(i)"
                      [matTooltip]="'COMMON_PAGE.DELETE_GROUP' | i18nTranslate"
                    >
                      delete
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div class="qb-add-icon-position">
                  <div class="primary-button qb-add-group-button">
                    <mat-icon class="qb-add-group-button-icon qb-button-icon"
                      >add</mat-icon
                    >
                    <span (click)="addGroup()" data-button-name="Add New Group"
                      >{{'SEGMENT_PAGE.ADD_NEW_GROUP' | i18nTranslate}}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="!(dataFetchComplete && dataFillComplete)"
        class="qb-mat-spinner"
      >
        <mat-spinner diameter="25"></mat-spinner>
      </div>
    </div>
    <div *ngIf="!createSegmentStatus" class="qb-add-condition-button-container">
      <div>
        <div class="qb-add-section-container" *ngIf="storeDataAdded">
          {{'SEGMENT_PAGE.ADD_CONDITION_SEGMENT_CUSTOMER' | i18nTranslate}}
        </div>
        <div
          class="qb-add-condition-button-container-mid"
          *ngIf="storeDataAdded"
        >
          <span
            (click)="addNewCondition()"
            class="primary-button qb-primary-button"
            data-widget-name="SEGMENT CONFIGURATION"
            data-button-name=" Add your first condition"
          >
            <span class="material-icons-outlined qb-button-icon"> add </span>
            {{'SEGMENT_PAGE.ADD_FIRST_CONDITION' | i18nTranslate}}
          </span>
        </div>
        <div
          class="qb-add-condition-button-container-mid"
          *ngIf="!storeDataAdded"
        >
          <span
          *ngIf="store !== 'shopify'"
            (click)="addStoreData()"
            class="primary-button qb-primary-button"
            data-button-name=" Add stores"
          >
            <span class="material-icons-outlined qb-button-icon"> add </span>
            {{'SEGMENT_PAGE.ADD_STORES' | i18nTranslate}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
