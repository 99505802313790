<div class="offerCreationHead" id="viewconfigDiv"
  style="display: flex;flex-direction: column;width: 100%;height: calc(100vh - 60px);font-size: 14px;overflow: hidden;"
  *ngIf="validWidgets" data-widget-name="OFFER CREATION/EDITING">
  <div style="display: flex;width: 100%;height:90px;padding:15px;align-items: center;">
    <div style="width: 60%;">

      <div class="formDiv" style="    width: 80%;    background: #fff;"
        [ngClass]="{active:submitClicked&&(offerTemplateName.length<1)}">
        <input class="formInput" type="text" placeholder="Offer Name" maxlength="100" [(ngModel)]="offerTemplateName"
          *ngIf="!previewmode">
        <div *ngIf="previewmode" style="width: 100%;" matTooltip="Preview only, can’t edit"><input class="formInput"
            type="text" placeholder="Offer Name" maxlength="100" [(ngModel)]="offerTemplateName" disabled></div>

        <span class="charText" style="color:#636466 ;"> {{100-offerTemplateName.length}} <span
            class="charText">/100</span> </span>
      </div>
      <div class="fontExo-13p" style="height:15px;color:red">
        <small style="color:red" *ngIf="submitClicked&&offerTemplateName.length<1">Offer Template Name is
          required</small>
      </div>

    </div>
    <div style="display: flex;flex-direction:column; width: 40%;justify-content: flex-end;    align-items: center;">
      <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;" *ngIf="!previewmode">
<div *ngIf="validWidgets | widgetConfig: 30"style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;">
        <!-- if no button click -->
        <button class="save-rectangle" appButtonDebounce [throttleTime]="600" (throttledClick)="saveandExitPressed()"
           *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==false)" data-button-name="Save and Exit" >
          <mat-icon class="save-icon" >save</mat-icon>
          <span class="save-segment-text" >Save and Exit</span>
        </button>

        <!-- <button class="schedule-rectangle" appButtonDebounce [throttleTime]="600"
          (throttledClick)="submitOfferForm('DISPATCH');" type="button" *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==false)" data-button-name="Save and Schedule">
          <span class="schedule-text" type="button">Save and Schedule</span>
        </button> -->
        <div class="primary-button"
        (throttledClick)="submitOfferForm('DISPATCH');"  data-button-name="Save and Schedule" *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==false)" [matTooltip]="'COMMON_PAGE.SAVE_AND_SCHEDULE' | i18nTranslate">
        <span class="normText" style="color: #fff; margin-left: 3px;">Save and
            Schedule</span>                           
         </div>

        <!-- if save and exit button clicked -->
        <button class="save-rectangle" *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==true)">
          <div style="display: flex;justify-content: center;width: 100%;height: 100%;align-items: center; ">
            <mat-spinner diameter="18" color="accent"></mat-spinner>
          </div>
        </button>

        <!-- <button class="schedule-rectangle-disabled" type="button" *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==true)">
          <span class="schedule-text" type="button">Save and Schedule</span>
        </button> -->

        <div class="primary-button" *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==true)">
          <span class="normText" style="color: #fff;">Save and Schedule</span>
        </div>

        <!-- if save and schedule button clicked -->

        <button class="save-rectangle-disabled" *ngIf="saveAndScheduleButtonLoading==true&&saveAndExitButtonLoading==false">
          <mat-icon class="save-icon" type="button">save</mat-icon>
          <span class="save-segment-text" type="button">Save and Exit</span>
        </button>

        <button class="schedule-rectangle" type="button" *ngIf="saveAndScheduleButtonLoading==true&&saveAndExitButtonLoading==false">
          <div style="display: flex;justify-content: center;width: 100%;height: 100%;align-items: center; ">
            <mat-spinner diameter="18" color="accent"></mat-spinner>
          </div>
        </button>
      </div>

        <mat-icon style="    color: #d8d8d8;
      background: #000;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;cursor: pointer;" (click)="closeOfferScreen()" data-button-name="Close">cancel</mat-icon>
      </div>


      <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;" *ngIf="previewmode">

        <div style="color:#0183FB;font-size: 13px;font-weight: 600;margin: 0 25px;" *ngIf="previewmode">Preview only,
          can’t edit.</div>

        <mat-icon style="    color: #d8d8d8;
      background: #000;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;cursor: pointer;" (click)="closeOfferScreen()">cancel</mat-icon>
      </div>

      <div class="fontExo-13p" style="height:15px">

      </div>
    </div>
  </div>

  <div class="seperator1"></div>

  <div style="height: calc(100% - 90px);display: flex;width: 100%;"
    *ngIf="offerReady&&showofferTemplateSkeleton && (validWidgets | widgetConfig: 29)">
    <div style="width: 60%;border-right: 1px solid #c6ced6;height: calc(100vh - 150px);padding:15px">

      <div class="barrierRect" [ngClass]="{active:barrierSectionExpanded}" *ngIf="barrierParamType!='NONE'">
        <div class="cursor-pointer"
          style="display: flex;padding: 15px;justify-content: space-between;align-items: center;height: 60px;"
          (click)="barrierExpand('barrier')">
          <div class="offerTitleText" style="margin-bottom: 0;cursor: pointer;">Customer will unlock a reward
            when<span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
              [ngbPopover]="popContentCustomerWillUnlockaRewardWhen" triggers="mouseenter:mouseleave">i

            </span></div>
          <div>
            <mat-icon style="border-radius: 50%;
            background: #0183fb;
            color: #fff;" [ngClass]="{matIconRotate:barrierSectionExpanded}">expand_more</mat-icon>
          </div>
        </div>

        <div *ngIf="barrierSectionExpanded" style="height: calc(100vh - 320px);
        overflow: auto; " [@appear]>
          <div class="seperator1"></div>
          <div style="width: 100%;height: 100%;" class="pointerevents-none" [ngClass]="{active:previewmode}">
            <div style="width: 100%;display:flex;">
              <div class="barrierTypeRect cursor-pointer" [ngClass]="{active:barrierType=='PRODUCT_BASED'}"
                (click)="barrierTypeChange('PRODUCT_BASED')"><span>A Specific Product is Purchased</span><span
                  class="i-div-offer" [ngClass]="{active:barrierType=='PRODUCT_BASED'}" popoverClass="my-custom-class"
                  placement="bottom" [ngbPopover]="popContentSpecificProductIsPurchased"
                  triggers="mouseenter:mouseleave">i

                </span></div>
              <div class="barrierTypeRect cursor-pointer" [ngClass]="{active:barrierType=='SPENT_BASED'}"
                (click)="barrierTypeChange('SPENT_BASED');"><span>Minimum Amount is spent</span><span class="i-div-offer"
                  [ngClass]="{active:barrierType=='SPENT_BASED'}" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentMinimumAmountIsSpent" triggers="mouseenter:mouseleave">i

                </span></div>
            </div>
            <div class="seperator1"></div>

            <div
              style="display: flex;justify-content: space-between;width: 100%;align-items: center;height: 65px;padding: 15px;"
              *ngIf="barrierType === 'PRODUCT_BASED'">

              <div class="offerTextBold"> <span>Customer needs to purchase</span><span class="i-div-offer"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentCustomerNeedsToPurchase"
                  triggers="mouseenter:mouseleave">i

                </span></div>

              <div style="width: 50%;">
                <div style="width:90%;">
                  <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleProductInnerType==null)}"
                    style="background: #f5f5f5;border: solid 1px #636466;">
                    <mat-select #hurdleProductInnerTypeselect *ngIf="barrierType === 'PRODUCT_BASED'"
                      class="formInput fontExo2" [(ngModel)]="hurdleProductInnerType" disableOptionCentering
                      panelClass="myPanelClass">

                      <!-- <mat-option *ngIf="fullproductListLength && hurdleRewardType == 'BUY_X_GET_Y'" matTooltip="Not applicable for Free Product Reward" disabled>Any Product
                      </mat-option > -->
                      <mat-option *ngIf="fullproductListLength" value="PRODUCT">Specific Product
                      </mat-option>
                      <mat-option *ngIf="fullproductCategoryListLength" value="CATEGORY">Any product from a category
                      </mat-option>
                      <mat-option *ngIf="fullproductListLength" value="ANY_PRODUCT"
                        (click)="anyProductForProductBasedSelected()">Any Product
                      </mat-option>
                    </mat-select>
                    <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                      id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                      (click)="selectopenclose('hurdleProductInnerTypeselect')">
                      <mat-icon _ngcontent-c14=""
                        class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                        aria-hidden="true">keyboard_arrow_down</mat-icon>
                    </div>
                  </div>
                </div>

                <small style="color:red" *ngIf="submitClicked&&(hurdleProductInnerType==null)">Field is
                  required</small>
              </div>

            </div>

            <div
              style="display: flex;justify-content: space-between;width: 100%;align-items: center;height: 65px;padding: 15px;"
              *ngIf="barrierType === 'SPENT_BASED'">

              <div class="offerTextBold"><span> Minimum amount that needs to be spent</span><span class="i-div-offer"
                  popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentMinimumAmountThatNeedsToBeSpent" triggers="mouseenter:mouseleave">i

                </span></div>
              <div style="width: 50%;">
                <div style="width:90%">
                  <!-- <span class="fontExo2">Value</span> -->
                  <div style="width: 100%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(hurdleSpentAmount==null)||(hurdleSpentAmount!=null&&(hurdleSpentAmount==e||hurdleSpentAmount<1))}"
                      style="width: 30%;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleSpentAmount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleSpentAmount'].errors['min'] : false)
|| (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleSpentAmount'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleSpentAmount'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleSpentAmount'].touched || submitClicked) : false)}"> -->
                      <input class="formInput fontExo2" [(ngModel)]="hurdleSpentAmount" type="number" min=0>

                    </div>
                    <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                      {{currencySymbol}}</span>
                  </div>
                  <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                    <span *ngIf="submitClicked&&(hurdleSpentAmount<1||hurdleSpentAmount==e)&&hurdleSpentAmount!=null">
                      Value should be greater than
                      0</span>

                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(hurdleSpentAmount==null)">Field is required</small>
              </div>
            </div>
            <div class="seperator1"></div>
            <div *ngIf="barrierType === 'PRODUCT_BASED' ">
              <div style="display: flex;align-items: center;padding: 15px;"
                *ngIf="hurdleProductInnerType!='ANY_PRODUCT'">
                <span class="charText2" style="margin-right: 10px;align-items: center;">AI Assistance<span class="i-div-offer"
                    popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentAIAssistance"
                    triggers="mouseenter:mouseleave">i

                  </span></span>
                <mat-slide-toggle (change)="toggleAIassistance($event);"
                  [checked]="barrierParamType === 'NON_PERSONALIZED' ? false : true">
                </mat-slide-toggle>
              </div>


              <div style="display: flex;width: 100%;align-items: baseline;">

                <div style="display: flex;width: 50%;padding: 15px;" class="offerTextTitle2"
                  *ngIf="hurdleProductInnerType!=null &&barrierParamType === 'NON_PERSONALIZED'">
                  <span
                    *ngIf="barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType==='PRODUCT'"><span>Product
                      Details</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                      [ngbPopover]="popContentProductDetails" triggers="mouseenter:mouseleave">i
                    </span>
                  </span>

                  <span *ngIf="barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType==='CATEGORY'">
                    <span>Category
                      Details</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                      [ngbPopover]="popContentCategoryDetails" triggers="mouseenter:mouseleave">i

                    </span></span>
                </div>
                <div style="display: flex;width: 50%;padding: 15px;" class="offerTextTitle2"
                  *ngIf="barrierParamType === 'PERSONALIZED'">
                  <span *ngIf="barrierParamType === 'PERSONALIZED'"><span>Difficulty
                      level for Customer</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                      [ngbPopover]="popContentProductType" triggers="mouseenter:mouseleave">i

                    </span></span>
                </div>
                <!-- <span *ngIf="barrierParamType === 'PERSONALIZED' &&hurdleProductInnerType==='CATEGORY'"><span>Difficulty
                    level for Customer</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                      [ngbPopover]="popContentCategoryType" triggers="mouseenter:mouseleave">i

                    </span></span> -->


                <div style="width: 50%;padding: 15px 0; "
                  *ngIf="hurdleProductInnerType!=null&&barrierParamType === 'NON_PERSONALIZED'">
                  <div style="width:100%;" *ngIf="barrierType === 'PRODUCT_BASED'">
                    <div style="width: 50%;"
                      *ngIf="barrierType === 'PRODUCT_BASED' && barrierParamType === 'NON_PERSONALIZED'">
                      <div style="width:100%;margin-bottom: 10px;" *ngIf="hurdleProductInnerType === 'PRODUCT'">
                        <div style="width:90%;">
                          <!-- <span class="fontExo2">Product
                        </span> -->
                          <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleProduct==null)}">
                            <mat-select #hurdleproductselect class="formInput fontExo2"
                              *ngIf="hurdleProductInnerType === 'PRODUCT'" color="primary" placeholder="Choose Product"
                              [(ngModel)]="hurdleProduct" (openedChange)="openedChange($event,i,'barrier')"
                              (selectionChange)="mainProductChange(i,hurdleProduct,'barrier')" disableOptionCentering
                              panelClass="myPanelClass">

                              <div class="Rectangle">
                                <div style="display: flex;align-items: center;    border-bottom: 1px solid #c2c2c2;
                                height: 45px;">
                                  <mat-icon style="color: #c0c4d0;
                                margin: 0px 8px 0px 10px;" matPrefix>search
                                  </mat-icon>
                                  <input style="border:none;outline: none;height: 40px;" type="text"
                                    [(ngModel)]="temphurdleSegmentFilter" placeholder=" Search by name ">
                                </div>

                              </div>
                              <div class="matSelectDropdowns">
                                <mat-option class="fontExo2"
                                  *ngFor="let product of apiProductList | labelPipe: temphurdleSegmentFilter: 'productName'"
                                  [value]="product">
                                  {{product.productName}}
                                </mat-option>
                              </div>
                            </mat-select>
                            <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                              id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                              (click)="selectopenclose('hurdleproductselect')">
                              <mat-icon _ngcontent-c14=""
                                class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                                role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                            </div>


                          </div>
                        </div>
                        <small style="color:red" *ngIf="submitClicked&&(hurdleProduct==null)">Product is
                          required</small>
                      </div>
                      <div style="width:100%;margin-bottom: 10px;" *ngIf="hurdleProductInnerType=== 'CATEGORY'">
                        <div style="width:90%;">
                          <!-- <span class="fontExo2">Product
                          Category</span> -->
                          <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleProductCategory==null)}">
                            <mat-select #hurdleProductCategoryselect class="formInput fontExo2"
                              *ngIf="hurdleProductInnerType === 'CATEGORY'" color="primary"
                              [(ngModel)]="hurdleProductCategory" [value]="productCategoryType[0].name"
                              disableOptionCentering panelClass="myPanelClass">

                              <mat-option class="fontExo2" *ngFor="let category of productCategoryType"
                                [value]="category.name">
                                {{category.name}}
                              </mat-option>
                            </mat-select>
                            <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                              id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                              (click)="selectopenclose('hurdleProductCategoryselect')">
                              <mat-icon _ngcontent-c14=""
                                class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                                role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                            </div>
                          </div>
                        </div>
                        <small style="color:red" *ngIf="submitClicked&&(hurdleProductCategory==null)">Category is
                          required</small>
                      </div>
                    </div>
                    <div style="width: 50%;"
                      *ngIf="barrierType === 'PRODUCT_BASED' && barrierParamType === 'NON_PERSONALIZED'&&hurdleProductInnerType === 'PRODUCT'">
                      <div style="width:90%;">
                        <!-- <span class="fontExo2">
                        Variant</span> -->
                        <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleProductVariant==null)}">
                          <mat-select #hurdleProductVariantselect class="formInput fontExo2"
                            [(ngModel)]="hurdleProductVariant" placeholder="Choose Variant"
                            (openedChange)="openedChange($event,i,'barrier')"
                            (selectionChange)="mainProductChange2(i,hurdleProductVariant)" disableOptionCentering
                            panelClass="myPanelClass">
                            <div class="Rectangle">
                              <div style="display: flex;align-items: center;    border-bottom: 1px solid #c2c2c2;
                            height: 45px;">
                                <mat-icon style="color: #c0c4d0;
                            margin: 0px 8px 0px 10px;" matPrefix>search
                                </mat-icon>
                                <input style="border:none;outline: none;height: 40px;" type="text"
                                  [(ngModel)]="temphurdleVariantSegmentFilter" placeholder=" Search by name ">
                              </div>

                            </div>
                            <div class="matSelectDropdowns" *ngIf="hurdleProduct!=null">
                              <mat-option class="fontExo2"
                                *ngFor="let variant of hurdleProduct.variants | labelPipe: temphurdleVariantSegmentFilter: 'variantName'"
                                [value]="variant">
                                {{variant.variantName}}
                              </mat-option>
                            </div>
                          </mat-select>
                          <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                            id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                            (click)="selectopenclose('hurdleProductVariantselect')">
                            <mat-icon _ngcontent-c14=""
                              class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                              role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                          </div>
                        </div>
                      </div>
                      <small style="color:red" *ngIf="submitClicked&&(hurdleProductVariant==null)">Field is
                        required</small>
                    </div>

                  </div>
                </div>

                <div style="width: 50%;padding: 15px 0; "
                  *ngIf="barrierParamType === 'PERSONALIZED'&&barrierType === 'PRODUCT_BASED'">
                  <div style="width:100%;" *ngIf="barrierType === 'PRODUCT_BASED'">
                    <div style="width: 50%;"
                      *ngIf="barrierType === 'PRODUCT_BASED' && barrierParamType === 'PERSONALIZED'">
                      <div style="width:90%;">
                        <!-- <span class="fontExo2">Difficulty
                        Level</span> -->
                        <div class="formDiv" [ngClass]="{active:submitClicked&&(productPropensity==null)}">
                          <mat-select #productPropensityselect class="formInput fontExo2"
                            [(ngModel)]="productPropensity" disableOptionCentering panelClass="myPanelClass">
                            <mat-option value="LOW">Low
                            </mat-option>
                            <mat-option value="MEDIUM">
                              Medium
                            </mat-option>
                            <mat-option value="HIGH">High
                            </mat-option>
                          </mat-select>
                          <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                            id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                            (click)="selectopenclose('productPropensityselect')">
                            <mat-icon _ngcontent-c14=""
                              class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                              role="img" aria-hidden="true">keyboard_arrow_down</mat-icon>
                          </div>
                        </div>
                      </div>
                      <small style="color:red" *ngIf="submitClicked&&(productPropensity==null)">Field is
                        required</small>
                    </div>

                  </div>
                </div>




              </div>

              <div style="display:flex;width: 100%;margin-bottom: 10px;padding: 15px;align-items: baseline;">
                <div style="width: 50%;" class="offerTextTitle2"><span>Minimum items in a purchase </span><span
                    class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentMinimumItemsInAPurchase" triggers="mouseenter:mouseleave">i

                  </span></div>
                <div style="width:50%">

                  <div style="width: 100%;" *ngIf="barrierType === 'PRODUCT_BASED'">
                    <div style="width:90%;">
                      <!-- <span class="fontExo2">Product
                      Count</span> -->
                      <div style="height: 35px; user-select: none;">
                        <div class="formDiv2"
                          [ngClass]="{active:(submitClicked&&(hurdleProductCount==null))||((hurdleProductCount<1||hurdleProductCount>10||hurdleProductCount==e)&&hurdleProductCount!=null)}"
                          style="width: 30%;">
                          <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors['min'] : false)
  ||(offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors['max'] : false)
  ||(offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].touched || submitClicked) : false)}"> -->
                          <input type="number" min=1 max=10 class="formInput fontExo2" [(ngModel)]="hurdleProductCount">
                        </div>
                        <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                          <span *ngIf="(hurdleProductCount<1||hurdleProductCount==e)&&hurdleProductCount!=null">Enter
                            a valid value</span>
                          <span *ngIf="hurdleProductCount>10">Maximum value is 10</span>

                          <span style="color:#4abb59" *ngIf="hurdleProductCount==10">Maximum value is 10</span>
                          <!-- <span
                      *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors['min'] : false">Count
                      should be greater than
                      0</span>
                  <span
                      *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors['max'] : false">Count
                      should be less than 6</span>
                  <span
                      *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].touched ||
  (submitClicked && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleProductCount'].errors.required)) : false">Product
                      count cannot be blank</span> -->
                        </div>
                      </div>
                    </div>
                    <small style="color:red" *ngIf="submitClicked&&(hurdleProductCount==null)">Field is
                      required</small>
                  </div>
                </div>

              </div>
            </div>

            <div class="seperator1" *ngIf="barrierType === 'PRODUCT_BASED'"></div>




            <div style="padding:15px">
              <div style="display: flex;width: 100%;align-items: baseline;">
                <div style="width: 50%;" class="offerTextTitle2"> <span>Offer will be valid for</span> <span
                    class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentOfferWillBeValidFor" triggers="mouseenter:mouseleave">i

                  </span></div>
                <div style="width: 50%;">
                  <div style="width:100%;" *ngIf="barrierParamType !== 'NONE'">
                    <div style="width:90%;">
                      <!-- <span class="fontExo2">Time to complete
                      Barrier</span> -->
                      <div style="width: 100%;display: flex;align-items: center;">
                        <div class="formDiv2"
                          [ngClass]="{active:(submitClicked&&(hurdleTimeToComplete==null))||(hurdleTimeToComplete<1&&hurdleTimeToComplete!=null)}"
                          style="width: 30%;">
                          <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors['min'] : false)
|| (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].touched || submitClicked) : false)}" -->

                          <input class="formInput fontExo2" placeholder="days" min=1 type="number"
                            [(ngModel)]="hurdleTimeToComplete">

                        </div>
                        <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;"
                          *ngIf="hurdleTimeToComplete>1">
                          Days
                        </span>
                        <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;"
                          *ngIf="hurdleTimeToCompletee<2">
                          Day
                        </span>

                      </div>
                      <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                        <span
                          *ngIf="submitClicked&&(hurdleTimeToComplete<1||hurdleTimeToComplete==e)&&hurdleTimeToComplete!=null">Enter
                          a valid
                          value</span>
                        <span
                          *ngIf="!submitClicked&&(hurdleTimeToComplete==e||hurdleTimeToComplete<1)&&hurdleTimeToComplete!=null">Enter
                          a valid
                          value</span>
                        <!-- <span
                          *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors['min'] : false">Value
                          should be greater than 0</span>
                      <span
                          *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].touched ||
(submitClicked && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleTimeToComplete'].errors.required)) : false">Time
                          should not be blank</span> -->
                      </div>
                    </div>
                    <small style="color:red" *ngIf="submitClicked&&(hurdleTimeToComplete==null)">Field is
                      required</small>
                  </div>
                </div>

              </div>

              <div style="display: flex;width: 100%; align-items: baseline;   margin-bottom: 10px;">

                <div style="display:flex; width: 50%;" class="offerTextTitle2">At any time <span class="i-div-offer"
                    popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentAtAnyTime"
                    triggers="mouseenter:mouseleave">i

                  </span></div>


                <div style="display:flex; width: 50%; align-items: center;">


                  <mat-slide-toggle (change)="togglehurdleAtAnyTime($event);"
                    [checked]="hurdleAtAnyTime">
                  </mat-slide-toggle>

                  <small style="color:orange;margin-left: 10px;" *ngIf="!hurdleAtAnyTime">Free Product reward is not
                    valid for this configuration</small>
                </div>

                <!-- <div style="display:flex; width: 50%;" *ngIf="hurdleRewardType == 'BUY_X_GET_Y'">
                  <mat-slide-toggle color="primary"
                    [checked]="1==1"  disabled matTooltip="Not ">
                  </mat-slide-toggle>
                </div> -->
              </div>


              <div style="display: flex;width: 100%;" *ngIf="!hurdleAtAnyTime">

                <div style="width:100%;" *ngIf="barrierParamType !== 'NONE'">
                  <div style="display: flex; width: 100%;">
                    <div style=" margin-right: 20px;">
                      <span class="fontExo2">From</span>
                      <div style="display:flex; justify-content: flex-start;">
                        <div style="width:100%;">
                          <div style="display:flex;">
                            <div class="formDiv2" [ngClass]="{active:submitClicked&&(hourFrom==null)}">
                              <!-- [ngClass]="{'active' :submitClicked && !timeRangeSetValidation(offerTemplate['value']['offerBarrier'][i]['hourFrom'],offerTemplate['value']['offerBarrier'][i]['hourTo'])}"
                                      style="width:100%; cursor:pointer; display:flex; justify-content: space-evenly;"> -->
                              <input [(ngModel)]="hourFrom" [format]="serviceCountry === 'US' ? 12 : 24" class="formInput"
                                style="height: 90%;  cursor: pointer;" [ngxTimepicker]="pickerFrom">
                              <ngx-material-timepicker #pickerFrom>
                              </ngx-material-timepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span class="fontExo2">To</span>
                      <div style="display:flex; justify-content: flex-start;">
                        <div style="width:100%;">
                          <div style="display:flex;">
                            <div class="formDiv2" [ngClass]="{active:submitClicked&&(hourTo==null)}"
                              style="cursor:pointer; display:flex; justify-content: space-evenly;">
                              <!-- [ngClass]="{'active' :submitClicked && !timeRangeSetValidation(offerTemplate['value']['offerBarrier'][i]['hourFrom'],offerTemplate['value']['offerBarrier'][i]['hourTo'])}" -->

                              <input [(ngModel)]="hourTo" [format]="serviceCountry === 'US' ? 12 : 24" class="formInput"
                                style="height: 90%;  cursor: pointer;" [ngxTimepicker]="pickerTo">
                              <ngx-material-timepicker #pickerTo>
                              </ngx-material-timepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                    <!-- <span
                      *ngIf="submitClicked && timeRangeValidityCheck(offerTemplate['value']['offerBarrier'][i]['hourFrom'],offerTemplate['value']['offerBarrier'][i]['minuteFrom'],offerTemplate['value']['offerBarrier'][i]['dayBreakFrom'],
offerTemplate['value']['offerBarrier'][i]['hourTo'],offerTemplate['value']['offerBarrier'][i]['minuteTo'],offerTemplate['value']['offerBarrier'][i]['dayBreakTo'])">Time
                      range does not match</span> -->
                  </div>
                </div>




              </div>

              <div style="display: flex;width: 100%;align-items: baseline;">

                <div style="display:flex; width: 50%;" class="offerTextTitle2"><span>Every day of the week </span><span
                    class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentEveryDayOfTheWeek" triggers="mouseenter:mouseleave">i

                  </span></div>


                <div style="display:flex; width: 50%;">


                  <mat-slide-toggle (change)="togglehurdleEverydayOfTheWeek($event);"
                    [checked]="hurdleEverydayOfTheWeek">
                  </mat-slide-toggle>
                  <small style="color:orange;margin-left: 10px;" *ngIf="!hurdleEverydayOfTheWeek">Free Product reward is
                    not valid for this configuration</small>

                </div>
              </div>




              <div *ngIf="!hurdleEverydayOfTheWeek" style="width: 100%;">

                <mat-chip-list placeholder="Select a day" class="font-class fs08-rem" multiple="true">
                  <mat-chip #c="matChip" *ngFor="let day of weekDaysChips" style="min-height: 26px;color: #fff;"
                    class="font-class fs08-rem cursor-pointer weekdays-chip" [value]="day.name"
                    (click)="toggleSelection(day) " [selected]="day.selected">
                    {{day.name}}
                  </mat-chip>
                </mat-chip-list>

              </div>

            </div>
          </div>





        </div>
      </div>
      <div class="barrierRect" [ngClass]="{active:rewardSectionExpanded}" style="margin-top: 20px;">
        <div class="cursor-pointer"
          style="display: flex;padding: 15px;justify-content: space-between;align-items: center;height: 60px;"
          (click)="rewardExpand('reward')">
          <div class="offerTitleText" style="margin-bottom: 0;cursor: pointer;"><span>Customer will be rewarded
              with</span> <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
              [ngbPopover]="popContentCustomerWillBeRewardedWith" triggers="mouseenter:mouseleave">i

            </span> </div>
          <div>
            <mat-icon style="border-radius: 50%;
            background: #0183fb;
            color: #fff;" [ngClass]="{matIconRotate:rewardSectionExpanded}">expand_more</mat-icon>
          </div>
        </div>

        <div *ngIf="rewardSectionExpanded" [style.height.px]="scrHeight - rewardHeight" style="
        overflow: auto; " [@appear]>
          <div class="seperator1"></div>
          <div style="width: 100%;height: 100%;" class="pointerevents-none" [ngClass]="{active:previewmode}">

            <div style="width: 100%;display:flex;">
              <div class="barrierTypeRect cursor-pointer br-left"
                [ngClass]="{active:hurdleRewardType=='BUY_X_GET_Y',disabled:(hurdleProductInnerType=='ANY_PRODUCT'&&barrierType === 'PRODUCT_BASED')||(hurdleEverydayOfTheWeek==false||hurdleAtAnyTime==false)}"
                (click)="rewardTypeChange('BUY_X_GET_Y')"
                *ngIf="barrierParamType!='NONE' &&barrierType=='PRODUCT_BASED'"
                [matTooltip]="(hurdleEverydayOfTheWeek==false||hurdleAtAnyTime==false)?'Choose At any time and Every day of the week to enable this':hurdleProductInnerType!='ANY_PRODUCT'&&barrierType === 'PRODUCT_BASED'?'':'Not applicable for Any Product in the Challenge'">
                <span>Free Product</span><span class="i-div-offer" [ngClass]="{active:hurdleRewardType=='BUY_X_GET_Y'}"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentFreeProduct"
                  triggers="mouseenter:mouseleave">i

                </span>
              </div>
              <div class="barrierTypeRect cursor-pointer br-left"
                [ngClass]="{active:hurdleRewardType=='PERCENTAGE_OFF'}" (click)="rewardTypeChange('PERCENTAGE_OFF')">
                <span>A percentage Off</span><span class="i-div-offer" [ngClass]="{active:hurdleRewardType=='PERCENTAGE_OFF'}"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentPercentageOff"
                  triggers="mouseenter:mouseleave">i

                </span></div>
              <div class="barrierTypeRect cursor-pointer br-left"
                [ngClass]="{active:hurdleRewardType=='FIXED_AMOUNT_OFF'}"
                (click)="rewardTypeChange('FIXED_AMOUNT_OFF')"><span>An Amount Off</span><span class="i-div-offer"
                  [ngClass]="{active:hurdleRewardType=='FIXED_AMOUNT_OFF'}" popoverClass="my-custom-class"
                  placement="bottom" [ngbPopover]="popContentAmountOff" triggers="mouseenter:mouseleave">i

                </span></div>
              <div class="barrierTypeRect cursor-pointer" [ngClass]="{active:hurdleRewardType=='FREE_SHIPPING'}"
                (click)="rewardTypeChange('FREE_SHIPPING')"><span>Free Shipping</span><span class="i-div-offer"
                  [ngClass]="{active:hurdleRewardType=='FREE_SHIPPING'}" popoverClass="my-custom-class"
                  placement="bottom" [ngbPopover]="popContentFreeShipping" triggers="mouseenter:mouseleave">i

                </span></div>
            </div>
            <div class="seperator1"></div>




            <div
              style="display: flex;justify-content: space-between;width: 100%;align-items: center;height: 65px;padding: 15px;"
              *ngIf="hurdleRewardType === 'BUY_X_GET_Y'">

              <div class="offerTextBold" *ngIf="hurdleRewardType === 'BUY_X_GET_Y'"> <span>Type of free product</span>
                <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentTypeOfFreeProduct" triggers="mouseenter:mouseleave">i

                </span>
              </div>

              <div style="width: 50%;">
                <div style="width:90%;">
                  <div class="formDiv" [ngClass]="{active:submitClicked&&(buyXGetYType==null)}"
                    style="background: #f5f5f5;border: solid 1px #636466;">
                    <mat-select #buyXGetYTypeselect class="formInput fontExo2" [(ngModel)]="buyXGetYType">
                      <!-- <mat-option value='PRODUCT'>Product
                      </mat-option>
                      <mat-option value='COLLECTION'>
                          Category</mat-option> -->

                      <mat-option *ngIf="fullproductListLength" value="PRODUCT">Specific Product
                      </mat-option>
                      <mat-option *ngIf="fullproductCategoryListLength" value="COLLECTION">
                        Any product from a category
                      </mat-option>
                    </mat-select>
                    <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                      id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                      (click)="selectopenclose('buyXGetYTypeselect')">
                      <mat-icon _ngcontent-c14=""
                        class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                        aria-hidden="true">keyboard_arrow_down</mat-icon>
                    </div>
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(buyXGetYType==null)">Field is required</small>
              </div>

            </div>

            <div
              style="display: flex;justify-content: space-between;width: 100%;align-items: center;height: 65px;padding: 15px;"
              *ngIf="hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF'">

              <div class="offerTextBold" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF'"> <span>Customers will get a
                  percentage
                  off on</span> <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentCustomersWillGetAPercentage" triggers="mouseenter:mouseleave">i

                </span></div>
              <div class="offerTextBold" *ngIf="hurdleRewardType === 'FIXED_AMOUNT_OFF'"> <span>Customers will get an
                  amount
                  off
                  on</span> <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentCustomersWillGetAnAmountOff" triggers="mouseenter:mouseleave">i

                </span></div>

              <div style="width: 50%;">
                <div style="width:90%;">
                  <div class="formDiv" [ngClass]="{active:submitClicked&&(discountAndShippingType==null)}"
                    style="background: #f5f5f5;border: solid 1px #636466;">
                    <mat-select #discountAndShippingTypeselect class="formInput fontExo2"
                      [(ngModel)]="discountAndShippingType">
                      <!-- <mat-option value='PRODUCT'>
                          Product</mat-option>
                      <mat-option value='COLLECTION'>
                          Category</mat-option> -->
                      <mat-option *ngIf="fullproductListLength" value="PRODUCT">
                        Specific Product
                      </mat-option>
                      <mat-option *ngIf="fullproductCategoryListLength" value="COLLECTION">
                        Any product from a category
                      </mat-option>
                      <mat-option value='ORDER' *ngIf="rewardPersonalizationType=='NON_PERSONALIZED'">Complete Order
                      </mat-option>
                    </mat-select>
                    <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                      id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                      (click)="selectopenclose('discountAndShippingTypeselect')">
                      <mat-icon _ngcontent-c14=""
                        class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                        aria-hidden="true">keyboard_arrow_down</mat-icon>
                    </div>
                  </div>

                </div>
                <small style="color:red" *ngIf="submitClicked&&(discountAndShippingType==null)">Field is
                  required</small>
              </div>

            </div>

            <!-- <div
            style="display: flex;justify-content: space-between;width: 100%;align-items: center;height: 65px;padding: 15px;"
            *ngIf="hurdleRewardType === 'FREE_SHIPPING'">
            <div class="offerTextBold">Free Shipping should be given over <span class="i-div-offer" popoverClass="my-custom-class"
                placement="bottom" [ngbPopover]="popContent" triggers="mouseenter:mouseleave">i

              </span></div>
            <div style="width: 50%;">
              <div style="width:90%;">
                <div class="formDiv" [ngClass]="{active:submitClicked&&(freeShippingType==null)}"
                  style="background: #f5f5f5;border: solid 1px #d2d8ee;">
                  <mat-select #freeShippingTypeselect class="formInput fontExo2" [(ngModel)]="freeShippingType">
                    <mat-option value='MIN_ORDER_AMOUNT'>
                      Minimum Spend
                    </mat-option>
                    <mat-option value='MIN_ORDER_QUANTITY'>
                      Minimum Items
                    </mat-option>
                    <mat-option value='ORDER'>Direct
                    </mat-option>
                  </mat-select>
                  <div _ngcontent-c14="" class="keyboard-down-icon-container" id="keyboard-down-icon-container"
                    style="transform: rotate(0deg);" (click)="selectopenclose('freeShippingTypeselect')">
                    <mat-icon _ngcontent-c14=""
                      class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      aria-hidden="true">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

            <div class="seperator1"></div>

            <div style="display: flex;align-items: center;padding: 15px;"
              *ngIf="barrierParamType === 'NONE' &&hurdleRewardType!='FREE_SHIPPING'">
              <span class="charText2" style="margin-right: 10px;align-items: center;">AI Assistance <span class="i-div-offer"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentAIAssistance"
                  triggers="mouseenter:mouseleave">i

                </span></span>
              <mat-slide-toggle (change)="toggleAIassistanceReward($event);"
                [checked]="rewardPersonalizationType === 'NON_PERSONALIZED' ? false : true">
              </mat-slide-toggle>
            </div>

            <div style="display: flex;width: 100%;"
              *ngIf="(buyXGetYType!=null||discountAndShippingType!=null)&&hurdleRewardType === 'BUY_X_GET_Y'">
              <div style=" display: flex;width: 50%;padding: 15px;" class="offerTextTitle2" *ngIf="buyXGetYType!=null">
                <span
                  *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType==='PRODUCT'&&rewardPersonalizationType==='NON_PERSONALIZED'"><span>
                    Product
                    Details
                  </span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentProductDetails" triggers="mouseenter:mouseleave">i

                  </span></span>

                <span
                  *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType==='COLLECTION'&&rewardPersonalizationType==='NON_PERSONALIZED'"><span>Category
                    Details</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentCategoryDetails" triggers="mouseenter:mouseleave">i

                  </span></span>
                <!-- <span
                *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType==='PRODUCT'&&rewardPersonalizationType==='PERSONALIZED'"><span>Difficulty
                  level for Customer</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentCategoryType" triggers="mouseenter:mouseleave">i

                </span></span> -->
              </div>

              <div style=" display: flex;width: 50%;padding: 15px;" class="offerTextTitle2"
                *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&rewardPersonalizationType==='PERSONALIZED'">
                <span
                  *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&rewardPersonalizationType==='PERSONALIZED'"><span>Difficulty
                    level for Customer</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentProductType" triggers="mouseenter:mouseleave">i

                  </span></span>
              </div>

              <div style="display:flex; width: 50%;flex-direction: column;padding: 15px 0;"
                *ngIf="hurdleRewardType === 'BUY_X_GET_Y'">
                <div style="margin-bottom: 10px;width: 50%;"
                  *ngIf="hurdleRewardType === 'BUY_X_GET_Y' && buyXGetYType === 'PRODUCT'">
                  <div style="width:90%;">

                    <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleRewardProduct==null)}">
                      <mat-select #hurdleRewardProductselect1 class="formInput fontExo2" color="primary"
                        placeholder="Choose Product" [(ngModel)]="hurdleRewardProduct"
                        (openedChange)="openedChange($event,i,'barrier')"
                        (selectionChange)="mainProductChange(i,hurdleRewardProduct,'reward')">
                        <div class="Rectangle">
                          <div style="display: flex;align-items: center;    border-bottom: 1px solid #c2c2c2;
                          height: 45px;">
                            <mat-icon style="    color: #c0c4d0;
                          margin: 0px 8px 0px 10px;" matPrefix>search
                            </mat-icon>
                            <input style="border:none;outline: none;height: 40px;" type="text"
                              [(ngModel)]="temprewardSegmentFilter" placeholder=" Search by name ">
                          </div>
                        </div>
                        <div class="matSelectDropdowns">
                          <mat-option class="fontExo2"
                            *ngFor="let product of apiProductList | labelPipe: temprewardSegmentFilter: 'productName'"
                            [value]="product">
                            {{product.productName}}
                          </mat-option>
                        </div>
                      </mat-select>
                      <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                        id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                        (click)="selectopenclose('hurdleRewardProductselect1')">
                        <mat-icon _ngcontent-c14=""
                          class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                          aria-hidden="true">keyboard_arrow_down</mat-icon>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProduct==null)">Field is
                    required</small>
                </div>
                <div style="margin-bottom: 10px;width:50%"
                  *ngIf="hurdleRewardType=== 'BUY_X_GET_Y' && buyXGetYType === 'PRODUCT'">
                  <div style="width:90%;">

                    <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardProductVariant==null)}">
                      <mat-select #rewardProductVariantselect1 class="formInput fontExo2"
                        [(ngModel)]="rewardProductVariant" placeholder="Choose Variant"
                        (openedChange)="openedChange($event,i,'barrier')"
                        (selectionChange)="mainProductChange2(i,hurdleRewardProduct)">
                        <div class="Rectangle">
                          <div style="display: flex;align-items: center;    border-bottom: 1px solid #c2c2c2;
                          height: 45px;">
                            <mat-icon style=" color: #c0c4d0;
                          margin: 0px 8px 0px 10px;" matPrefix>search
                            </mat-icon>
                            <input style="border:none;outline: none;height: 40px;" type="text"
                              [(ngModel)]="temprewardVariantSegmentFilter" placeholder=" Search by name ">
                          </div>
                        </div>
                        <div class="matSelectDropdowns" *ngIf="hurdleRewardProduct!=null">
                          <mat-option class="fontExo2"
                            *ngFor="let variant of hurdleRewardProduct.variants | labelPipe: temprewardVariantSegmentFilter: 'variantName'"
                            [value]="variant">
                            {{variant.variantName}}
                          </mat-option>
                        </div>
                      </mat-select>
                      <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                        id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                        (click)="selectopenclose('rewardProductVariantselect1')">
                        <mat-icon _ngcontent-c14=""
                          class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                          aria-hidden="true">keyboard_arrow_down</mat-icon>
                      </div>
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(rewardProductVariant==null)">Field is
                    required</small>
                </div>
                <div style="margin-bottom: 10px;width: 50%;"
                  *ngIf="hurdleRewardType === 'BUY_X_GET_Y' && buyXGetYType === 'COLLECTION'">
                  <div style="width:90%;">

                    <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleRewardProductCategory==null)}">

                      <mat-select #hurdleRewardProductCategoryselect class="formInput fontExo2" color="primary"
                        placeholder="Category" [(ngModel)]="hurdleRewardProductCategory">
                        <mat-option class="fontExo2" *ngFor="let product of productCategoryType" [value]="product.name">
                          {{product.name}}
                        </mat-option>
                      </mat-select>
                      <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                        id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                        (click)="selectopenclose('hurdleRewardProductCategoryselect')">
                        <mat-icon _ngcontent-c14=""
                          class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                          aria-hidden="true">keyboard_arrow_down</mat-icon>
                      </div>
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProductCategory==null)">Field is
                    required</small>
                </div>
              </div>
            </div>
            <div style="display: flex;width: 100%;margin-bottom: 10px;padding: 15px;color:#a8ca2d"
              *ngIf="hurdleRewardType === 'BUY_X_GET_Y'">
              <div style="width: 50%;" class="offerTextTitle2"><span>Number of products</span> <span class="i-div-offer"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentNumberOfProducts"
                  triggers="mouseenter:mouseleave">i

                </span></div>
              <div style="width:50%;">
                <div style="width:90%;">

                  <div style="height: 35px; user-select: none;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(hurdleRewardProductCount==null)||(hurdleRewardProductCount!=null&&(hurdleRewardProductCount<1||hurdleRewardProductCount>10))}"
                      style="width: 30%;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors['min'] : false)
|| (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors['max'] : false)
|| (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].touched || submitClicked) : false)}"> -->
                      <input class="formInput fontExo2" [(ngModel)]="hurdleRewardProductCount" type="number" min="1"
                        max="10">
                    </div>
                    <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                      <span
                        *ngIf="(hurdleRewardProductCount<1||hurdleRewardProductCount==e)&&hurdleRewardProductCount!=null">Enter
                        a valid value</span>
                      <span *ngIf="hurdleRewardProductCount>10">Maximum value is 10</span>
                      <span *ngIf="hurdleRewardProductCount==10" style="color:#4abb59">Maximum value is 10</span>
                      <!-- <span
                      *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors['min'] : false">Count
                      should be greater than
                      0</span>
                  <span
                      *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors['max'] : false">Count
                      should be less than
                      6</span>
                  <span
                      *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].touched ||
(submitClicked && offerTemplate.controls.offerBarrier.controls[i].controls['hurdleRewardProductCount'].errors.required)) : false">Count
                      cannot be blank</span> -->
                    </div>
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProductCount==null)">Field is
                  required</small>
              </div>
            </div>

            <div style="display: flex;width: 100%;padding: 15px;" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF' ||
            hurdleRewardType === 'FIXED_AMOUNT_OFF'">
              <div style="display: flex;width: 50%;" class="offerTextTitle2" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF' ||
            hurdleRewardType === 'FIXED_AMOUNT_OFF'">
                <span
                  *ngIf="discountAndShippingType==='PRODUCT'&&rewardPersonalizationType==='NON_PERSONALIZED'"><span>Product
                    Details</span>
                  <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentProductDetails" triggers="mouseenter:mouseleave">i

                  </span>
                </span>
                <span *ngIf="rewardPersonalizationType==='PERSONALIZED'"><span>Difficulty
                    level for Customer</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentProductType" triggers="mouseenter:mouseleave">i

                  </span></span>
                <span
                  *ngIf="discountAndShippingType==='COLLECTION'&&rewardPersonalizationType==='NON_PERSONALIZED'"><span>
                    Category
                    Details
                  </span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                    [ngbPopover]="popContentCategoryDetails" triggers="mouseenter:mouseleave">i

                  </span></span>

                <!-- <span
                *ngIf="discountAndShippingType==='COLLECTION'&&rewardPersonalizationType==='PERSONALIZED'">
                <span>Difficulty
                  level for Customer</span><span class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentCategoryType" triggers="mouseenter:mouseleave">i


              </div>
              <div style="display: flex;flex-direction: column;width: 50%;" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF' ||
              hurdleRewardType === 'FIXED_AMOUNT_OFF'">
                <div style="display:flex;flex-direction: column;" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF' ||
        hurdleRewardType === 'FIXED_AMOUNT_OFF'">
                  <div *ngIf="barrierParamType === 'NONE' && rewardPersonalizationType === 'PERSONALIZED'"
                    style="width:50%;margin-bottom: 10px">
                    <div style="width:90%;">
                      <!-- <span class="fontExo2">Difficulty
                      Level</span> -->
                      <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardPropensity==null)}">
                        <mat-select #rewardPropensityselect class="formInput fontExo2" [(ngModel)]="rewardPropensity">
                          <mat-option value='LOW'>Low
                          </mat-option>
                          <mat-option value='MEDIUM'>
                            Medium</mat-option>
                          <mat-option value='HIGH'>
                            High
                          </mat-option>
                        </mat-select>
                        <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                          id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                          (click)="selectopenclose('rewardPropensityselect')">
                          <mat-icon _ngcontent-c14=""
                            class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                            aria-hidden="true">keyboard_arrow_down</mat-icon>
                        </div>
                      </div>
                    </div>
                    <small style="color:red" *ngIf="submitClicked&&(rewardPropensity==null)">Field is required</small>
                  </div>
                  <div style="width:50%;margin-bottom: 10px"
                    *ngIf="discountAndShippingType === 'PRODUCT' && rewardPersonalizationType === 'NON_PERSONALIZED'">
                    <div style="width:90%;">
                      <!-- <span class="fontExo2">Product</span> -->
                      <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleRewardProduct==null)}">
                        <mat-select #hurdleRewardProductselect3 class="formInput fontExo2" color="primary"
                          placeholder="Choose Product" [(ngModel)]="hurdleRewardProduct"
                          (selectionChange)="mainProductChange(i,hurdleRewardProduct,'reward')">
                          <div class="Rectangle">
                            <div style="display: flex;align-items: center;    border-bottom: 1px solid #c2c2c2;
                                          height: 45px;">
                              <mat-icon style="    color: #c0c4d0;
                                          margin: 0px 8px 0px 10px;" matPrefix>
                                search
                              </mat-icon>
                              <input style="border:none;outline: none;height: 40px;" type="text"
                                [(ngModel)]="temprewardSegmentFilter" placeholder=" Search by name ">
                            </div>
                          </div>
                          <div class="matSelectDropdowns">
                            <mat-option class="fontExo2"
                              *ngFor="let product of apiProductList | labelPipe: temprewardSegmentFilter: 'productName'"
                              [value]="product">
                              {{product.productName}}
                            </mat-option>
                          </div>
                        </mat-select>
                        <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                          id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                          (click)="selectopenclose('hurdleRewardProductselect3')">
                          <mat-icon _ngcontent-c14=""
                            class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                            aria-hidden="true">keyboard_arrow_down</mat-icon>
                        </div>
                      </div>
                    </div>
                    <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProduct==null)">Field is
                      required</small>
                  </div>
                  <div style="width:50%;margin-bottom: 10px"
                    *ngIf="discountAndShippingType === 'PRODUCT' && rewardPersonalizationType === 'NON_PERSONALIZED'">
                    <div style="width:90%;">
                      <!-- <span class="fontExo2">
                      Variant</span> -->
                      <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardProductVariant==null)}">
                        <mat-select #rewardProductVariantselect2 class="formInput fontExo2"
                          [(ngModel)]="rewardProductVariant" placeholder="Choose Variant"
                          (selectionChange)="mainProductChange2(i,hurdleRewardProduct)">
                          <div class="Rectangle">
                            <div style="display: flex;align-items: center;    border-bottom: 1px solid #c2c2c2;
                                          height: 45px;">
                              <mat-icon style="    color: #c0c4d0;
                                          margin: 0px 8px 0px 10px;" matPrefix>
                                search
                              </mat-icon>
                              <input style="border:none;outline: none;height: 40px;" type="text"
                                [(ngModel)]="temprewardVariantSegmentFilter" placeholder="Search by name">
                            </div>
                          </div>
                          <div class="matSelectDropdowns" *ngIf="hurdleRewardProduct!=null">
                            <mat-option class="fontExo2"
                              *ngFor="let variant of hurdleRewardProduct.variants | labelPipe:temprewardVariantSegmentFilter: 'variantName'"
                              [value]="variant">
                              {{variant.variantName}}
                            </mat-option>
                          </div>
                        </mat-select>
                        <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                          id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                          (click)="selectopenclose('rewardProductVariantselect2')">
                          <mat-icon _ngcontent-c14=""
                            class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                            aria-hidden="true">keyboard_arrow_down</mat-icon>
                        </div>
                      </div>
                    </div>
                    <small style="color:red" *ngIf="submitClicked&&(rewardProductVariant==null)">Field is
                      required</small>
                  </div>
                  <div style="width:50%;margin-bottom: 10px"
                    *ngIf="discountAndShippingType === 'COLLECTION' && rewardPersonalizationType === 'NON_PERSONALIZED'">
                    <div style="width:90%;">
                      <!-- <span class="fontExo2">Category</span> -->
                      <div class="formDiv" [ngClass]="{active:submitClicked&&(hurdleRewardProductCategory==null)}">
                        <mat-select #hurdleRewardProductCategoryselect2 class="formInput fontExo2" color="primary"
                          placeholder="Category" [(ngModel)]="hurdleRewardProductCategory">
                          <mat-option class="fontExo2" *ngFor="let product of productCategoryType"
                            [value]="product.name">
                            {{product.name}}
                          </mat-option>
                        </mat-select>
                        <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                          id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                          (click)="selectopenclose('hurdleRewardProductCategoryselect2')">
                          <mat-icon _ngcontent-c14=""
                            class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                            aria-hidden="true">keyboard_arrow_down</mat-icon>
                        </div>
                      </div>
                    </div>
                    <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProductCategory==null)">Field is
                      required</small>
                  </div>
                </div>
              </div>
            </div>


            <div style="width:100%; display: flex;padding: 15px;" *ngIf="hurdleRewardType=== 'PERCENTAGE_OFF'">
              <div style="width: 50%;" class="offerTextTitle2"><span>Discount Percentage </span><span class="i-div-offer"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentDiscountPercentage"
                  triggers="mouseenter:mouseleave">i

                </span></div>
              <div style="width:50%;">
                <div style="width:90%;">
                  <!-- <span
                    class="fontExo2">Percentage</span> -->
                  <div style="width: 100%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(percentageOff==null)||(percentageOff!=null&&(percentageOff<1||percentageOff==e||percentageOff>100))}"
                      style="width: 30%;display: flex;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['min'] || offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['max']) : false)
|| (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].touched || submitClicked) : false)}"> -->
                      <input class=" formInput fontExo2" type="number" min=0 max=100 [(ngModel)]="percentageOff">
                    </div>
                    <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                      %</span>
                  </div>
                  <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                    <span *ngIf="percentageOff!=null&&(percentageOff<1||percentageOff==e||percentageOff>100)">Enter
                      Valid value</span>
                    <!-- <span
                        *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['min'] : false">Percentage
                        value should be greater
                        than
                        0</span>
                    <span
                        *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['max'] : false">Percentage
                        value should less than
                        or equal
                        to 100</span>
                    <span
                        *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].touched ||
(submitClicked && offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors.required)) : false">Percentage
                        cannot be blank</span> -->
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(percentageOff==null)">Field is required</small>
              </div>
            </div>
            <div style="width:100%; display: flex;padding: 15px;" *ngIf="hurdleRewardType === 'FIXED_AMOUNT_OFF'">
              <div style="width: 50%;" class="offerTextTitle2"><span>Discount Amount </span><span class="i-div-offer"
                  popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContentDiscountAmount"
                  triggers="mouseenter:mouseleave">i

                </span></div>
              <div style="width:50%;">
                <div style="width:90%;">
                  <!-- <span
                    class="fontExo2">Amount</span> -->
                  <div style="width: 100%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(amountOff==null)||(amountOff!=null&&(amountOff<1||amountOff==e))}"
                      style="width: 30%;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors['min'] : false)
  || (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].touched || submitClicked) : false)}"> -->
                      <input class=" formInput fontExo2" type="number" min=0 [(ngModel)]="amountOff">
                    </div>
                    <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                      {{currencySymbol}}</span>
                  </div>
                  <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">
                    <span *ngIf="amountOff!=null&&(amountOff<1||amountOff==e)">Enter Valid value</span>
                    <!-- <span
                        *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors['min'] : false">Amount
                        value should be greater
                        than
                        0</span>
                    <span
                        *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].touched ||
(submitClicked && offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors.required)) : false">Amount
                        cannot be blank</span> -->
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(amountOff==null)">Field is required</small>
              </div>
            </div>

            <div class="seperator1" *ngIf="hurdleRewardType != 'FREE_SHIPPING'&&hurdleRewardType != 'BUY_X_GET_Y'"></div>



            <!-- <div style="width:100%; display: flex;padding: 15px;" *ngIf="hurdleRewardType === 'FREE_SHIPPING'"> -->
            <!-- <div style="width: 50%;" class="offerTextTitle2" *ngIf="freeShippingType==='MIN_ORDER_AMOUNT'">Minimum
              Spend
              Amount <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContent"
                triggers="mouseenter:mouseleave">i

              </span>
            </div>
            <div style="width:50%;"
              *ngIf="hurdleRewardType === 'FREE_SHIPPING' && freeShippingType === 'MIN_ORDER_AMOUNT'">
              <div style="width:90%;">
                <span class="fontExo2">Amount</span>
                <div style="width: 100%;display: flex;align-items: center;">
                  <div class="formDiv2" [ngClass]="{active:submitClicked&&(freeShippingMinimumAmount==null)}"
                    style="width: 25%;">
                    <input type="number" min=0 class="formInput" [(ngModel)]="freeShippingMinimumAmount">

                  </div>
                  <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                    {{currencySymbol}}</span>
                </div>
                <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">

                </div>
              </div>
            </div> -->

            <!-- <div style="width: 50%;" class="offerTextTitle2" *ngIf="freeShippingType === 'MIN_ORDER_QUANTITY'">Minimum
              Items count <span class="i-div-offer" popoverClass="my-custom-class" placement="bottom" [ngbPopover]="popContent"
                triggers="mouseenter:mouseleave">i

              </span></div>
            <div style="width:50%;"
              *ngIf="hurdleRewardType === 'FREE_SHIPPING' &&freeShippingType === 'MIN_ORDER_QUANTITY'">
              <div style="width:90%;">

                <div class="formDiv2" [ngClass]="{active:submitClicked&&(freeShippingMinimumOrderCount==null)}"
                  style="width: 25%;">
                  <input type="number" min=0 class="formInput" [(ngModel)]="freeShippingMinimumOrderCount">
                </div>
                <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">

                </div>
              </div>
            </div> -->

            <!-- </div> -->
            <div style="display: flex;width: 100%;margin-bottom: 10px;padding: 15px;color:#101111"
              *ngIf="hurdleRewardType != 'BUY_X_GET_Y'">
              <div style="display: flex;width: 50%;" class="offerTextTitle2"><span>Reward will be valid for </span><span
                  class="i-div-offer" popoverClass="my-custom-class" placement="bottom"
                  [ngbPopover]="popContentRewardWillBeValidFor" triggers="mouseenter:mouseleave">i

                </span></div>
              <div style="display: flex;width: 50%;">
                <div style="width:100%;">
                  <div style="width:90%;">
                    <!-- <span class="fontExo2">Days to
            redeem</span> -->
                    <div style="width: 100%;display: flex;align-items: center;">
                      <div class="formDiv2"
                        [ngClass]="{active:submitClicked&&(rewardTimeToComplete==null)||(rewardTimeToComplete!=null&&(rewardTimeToComplete<1||rewardTimeToComplete==e))}"
                        style="width: 30%;">
                        <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors['min'] : false) -->
                        <!-- || (offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].touched || submitClicked) : false)}"> -->
                        <input class="formInput fontExo2" min=1 type="number" [(ngModel)]="rewardTimeToComplete">

                      </div>
                      <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;"
                        *ngIf="rewardTimeToComplete>1">
                        Days</span>
                      <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;"
                        *ngIf="rewardTimeToComplete<2">
                        Day</span>
                    </div>
                    <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">

                      <span *ngIf="rewardTimeToComplete!=null&&(rewardTimeToComplete<1||rewardTimeToComplete==e)">Enter
                        Valid value</span>
                      <!-- <span
                *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors['min'] : false">Value
                should be greater than
                0</span>
            <span
                *ngIf="offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].touched ||
(submitClicked && offerTemplate.controls.offerBarrier.controls[i].controls['rewardTimeToComplete'].errors.required)) : false">Value
                cannot be blank</span> -->
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(rewardTimeToComplete==null)">Field is
                    required</small>
                </div>

              </div>
            </div>
          </div>

        </div>
      <!-- </div> -->
    <!-- </div> -->


    <!-- ************below********** -->


    <!-- *******ABOVE********** -->







    <div style="display: flex;width: 40%;    height: calc(100vh - 150px);
    padding: 15px;
    display: flex;
    flex-direction: column;overflow-y: auto;">
      <div class="previewRect" *ngIf="barrierParamType!='NONE'">
        <div style="height: 55px;display: flex;padding: 30px;align-items: center;"> <span
            class="BarrierPreviewHead">CUSTOMER WILL UNLOCK A REWARD WHEN</span></div>
        <div class="seperator1"></div>

        <div style="padding:30px">

          <div class="offerTitleText" *ngIf="barrierType==='PRODUCT_BASED'">A Specific Product is Purchased</div>
          <div class="offerTitleText" *ngIf="barrierType==='SPENT_BASED'">Minimum Amount is spent</div>

          <div style="display: flex;width:100%;" *ngIf="barrierType!=null">

            <div class="barrierPreviewTitle" *ngIf="barrierType==='PRODUCT_BASED'">Offer On</div>
            <div class="barrierPreviewTitleValue" *ngIf="barrierType==='PRODUCT_BASED'">
              <span *ngIf="hurdleProductInnerType=='PRODUCT'">Specific Product</span>
              <span *ngIf="hurdleProductInnerType=='CATEGORY'">Any product from a category</span>
              <span *ngIf="hurdleProductInnerType=='ANY_PRODUCT'">Any product</span>
              <span
                *ngIf="hurdleProductInnerType!='CATEGORY'&&hurdleProductInnerType!='PRODUCT'&&hurdleProductInnerType!='ANY_PRODUCT'">-</span>
            </div>
            <div class="barrierPreviewTitle" *ngIf="barrierType==='SPENT_BASED'">Minimum Amount Spent</div>
            <div class="barrierPreviewTitleValue" *ngIf="barrierType==='SPENT_BASED'">
              {{currencySymbol}} {{hurdleSpentAmount?hurdleSpentAmount :"-"}}
            </div>

          </div>

          <div style="display: flex;width:100%;"
            *ngIf="barrierType == 'PRODUCT_BASED' &&hurdleProductInnerType!='ANY_PRODUCT'">

            <div class="barrierPreviewTitle"
              *ngIf="barrierType === 'PRODUCT_BASED'&&barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType!=null">
              <span *ngIf="barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType==='PRODUCT'">Product
                Details</span>
              <span *ngIf="barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType==='CATEGORY'">Category
                Details</span>
            </div>
            <div class="barrierPreviewTitle"
              *ngIf="barrierType === 'PRODUCT_BASED'&&barrierParamType === 'PERSONALIZED'">
              <span *ngIf="barrierParamType === 'PERSONALIZED'">Difficulty
                level for Customer</span>

              <!-- <span *ngIf="barrierParamType === 'PERSONALIZED' &&hurdleProductInnerType==='CATEGORY'">Difficulty
                level for Customer</span> -->
            </div>
            <div class="barrierPreviewTitleValue" *ngIf="barrierType === 'PRODUCT_BASED'">
              <span
                *ngIf="barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType==='PRODUCT'">{{hurdleProduct?hurdleProduct.productName:"-"}}</span>
              <span
                *ngIf="barrierParamType === 'PERSONALIZED'">{{productPropensity?productPropensity=="LOW"?'Low':productPropensity=="MEDIUM"?"Medium"
                :productPropensity=="HIGH"?"High" :"-"
                :"-"| titlecase}}</span>
              <span
                *ngIf="barrierParamType === 'NON_PERSONALIZED' &&hurdleProductInnerType==='CATEGORY'">{{hurdleProductCategory?hurdleProductCategory:"-"
                | titlecase}}</span>
              <!-- <span
                *ngIf="barrierParamType === 'PERSONALIZED' &&hurdleProductInnerType==='CATEGORY'">{{productPropensity?productPropensity=="LOW"?'Low'
                :productPropensity=="MEDIUM"?"Medium" :productPropensity=="HIGH"?"High" :"-"
                :"-"| titlecase}}</span> -->


            </div>

          </div>

          <div style="display: flex;width:100%;" *ngIf="barrierType==='PRODUCT_BASED'">

            <div class="barrierPreviewTitle">Minimum Items</div>
            <div class="barrierPreviewTitleValue">{{hurdleProductCount?hurdleProductCount:"-"}}</div>

          </div>

          <div style="display: flex;width:100%;">

            <div class="barrierPreviewTitle">Days to Redeem</div>
            <div class="barrierPreviewTitleValue">{{hurdleTimeToComplete?hurdleTimeToComplete:"-"}}
              <span *ngIf="hurdleTimeToComplete==1">Day</span><span *ngIf="hurdleTimeToComplete>1">Days</span>
            </div>

          </div>



        </div>

      </div>
      <!-- ********REWARD******* -->
      <div class="previewRect" style="margin-top: 15px;">
        <div style="height: 55px;display: flex;padding: 30px;align-items: center;"> <span
            class="BarrierPreviewHead">CUSTOMER WILL BE REWARDED WITH</span></div>
        <div class="seperator1"></div>

        <div style="padding:30px">

          <div class="offerTitleText" *ngIf="hurdleRewardType === 'BUY_X_GET_Y'">Free Product</div>
          <div class="offerTitleText" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF'">A Percentage Off</div>
          <div class="offerTitleText" *ngIf="hurdleRewardType === 'FIXED_AMOUNT_OFF'">An Amount Off</div>
          <div class="offerTitleText" *ngIf="hurdleRewardType === 'FREE_SHIPPING'">Free Shipping</div>

          <div style="display: flex;width:100%;">

            <div class="barrierPreviewTitle">Offer On</div>
            <div class="barrierPreviewTitleValue"
              *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType=='COLLECTION'">Any product from a category

            </div>
            <div class="barrierPreviewTitleValue" *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType=='PRODUCT'">
              Specific Product</div>
            <div class="barrierPreviewTitleValue"
              *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&&discountAndShippingType=='COLLECTION'">
              Any product from a category</div>
            <div class="barrierPreviewTitleValue"
              *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&&discountAndShippingType=='PRODUCT'">
              Specific Product</div>
            <div class="barrierPreviewTitleValue"
              *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&&discountAndShippingType=='ORDER'">
              Complete Order</div>
            <div class="barrierPreviewTitleValue" *ngIf="hurdleRewardType === 'FREE_SHIPPING'">{{freeShippingType |
              titlecase}}</div>
            <div class="barrierPreviewTitleValue"
              *ngIf="hurdleRewardType != 'FREE_SHIPPING'&&(((hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&&discountAndShippingType==null)||(hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType==null))">
              -
            </div>



            <!-- <div class="barrierPreviewTitleValue" *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&&buyXGetYType==null">-</div>
              <div class="barrierPreviewTitleValue" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF'&&discountAndShippingType==null">-</div>
              <div class="barrierPreviewTitleValue" *ngIf="hurdleRewardType === 'FREE_SHIPPING'&&freeShippingType==null">-</div> -->

          </div>

          <div style="display: flex;width:100%;" *ngIf="hurdleRewardType != 'FREE_SHIPPING'">

            <div class="barrierPreviewTitle"
              *ngIf="hurdleRewardType == 'BUY_X_GET_Y'&&(buyXGetYType!=null)&&rewardPersonalizationType == 'NON_PERSONALIZED'">
              <span *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&& buyXGetYType==='PRODUCT' ">Product
                Details</span>
              <span *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&& buyXGetYType==='COLLECTION' ">Category
                Details</span>
              <!-- <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='PRODUCT'&&rewardPersonalizationType == 'NON_PERSONALIZED'  ">Product
                Details</span>
              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='COLLECTION' &&rewardPersonalizationType == 'NON_PERSONALIZED' ">Category
                Details</span> -->

            </div>
            <div class="barrierPreviewTitle"
              *ngIf="hurdleRewardType != 'FREE_SHIPPING'&&hurdleRewardType != 'BUY_X_GET_Y'&&(discountAndShippingType!=null&&discountAndShippingType!='ORDER')&&rewardPersonalizationType == 'NON_PERSONALIZED'">
              <!-- <span *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&& buyXGetYType==='PRODUCT' ">Product
                Details</span>
              <span *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&& buyXGetYType==='COLLECTION' ">Category
                Details</span> -->
              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='PRODUCT'&&rewardPersonalizationType == 'NON_PERSONALIZED'  ">Product
                Details</span>
              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='COLLECTION' &&rewardPersonalizationType == 'NON_PERSONALIZED' ">Category
                Details</span>

            </div>
            <div class="barrierPreviewTitle"
              *ngIf="hurdleRewardType != 'FREE_SHIPPING'&&rewardPersonalizationType == 'PERSONALIZED'&&(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')">
              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType!='ORDER' &&rewardPersonalizationType == 'PERSONALIZED' ">Difficulty
                level for Customer</span>
              <!-- <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF' || hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='COLLECTION'&&rewardPersonalizationType == 'PERSONALIZED'  ">Difficulty
                level for Customer</span> -->
            </div>
            <div class="barrierPreviewTitleValue" *ngIf="hurdleRewardType != 'FREE_SHIPPING'">
              <span
                *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&& buyXGetYType==='PRODUCT' ">{{hurdleRewardProduct?hurdleRewardProduct.productName:"-"}}</span>

              <span
                *ngIf="hurdleRewardType === 'BUY_X_GET_Y'&& buyXGetYType==='COLLECTION' ">{{hurdleRewardProductCategory?hurdleRewardProductCategory:"-"}}</span>

              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='PRODUCT'&&rewardPersonalizationType == 'NON_PERSONALIZED'  ">{{hurdleRewardProduct?hurdleRewardProduct.productName:"-"}}</span>
              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='COLLECTION' &&rewardPersonalizationType == 'NON_PERSONALIZED' ">{{hurdleRewardProductCategory?hurdleRewardProductCategory:"-"}}</span>
              <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&&rewardPersonalizationType == 'PERSONALIZED'  ">{{rewardPropensity!=null?rewardPropensity=="LOW"?'Low':rewardPropensity=="MEDIUM"?"Medium"
                :rewardPropensity=="HIGH"?"High" :rewardPropensity==null?"-":"-" :"-"}}</span>
              <!-- <span
                *ngIf="(hurdleRewardType === 'PERCENTAGE_OFF'||hurdleRewardType === 'FIXED_AMOUNT_OFF')&& discountAndShippingType==='COLLECTION' &&rewardPersonalizationType == 'PERSONALIZED' ">{{rewardPropensity!=null?rewardPropensity=="LOW"?'Low':rewardPropensity=="MEDIUM"?"Medium"
                :rewardPropensity=="HIGH"?"High" :rewardPropensity==null?"-":"-" :"-"}}</span> -->



            </div>

          </div>

          <div style="display: flex;width:100%;" *ngIf="hurdleRewardType === 'BUY_X_GET_Y'">

            <div class="barrierPreviewTitle">Minimum Items</div>
            <div class="barrierPreviewTitleValue">{{hurdleRewardProductCount?hurdleRewardProductCount:"-"}}</div>

          </div>
          <div style="display: flex;width:100%;" *ngIf="hurdleRewardType === 'PERCENTAGE_OFF'">

            <div class="barrierPreviewTitle">Discount Percentage</div>
            <div class="barrierPreviewTitleValue">{{percentageOff?percentageOff:"-"}} <span
                *ngIf="percentageOff>0">%</span></div>

          </div>
          <div style="display: flex;width:100%;" *ngIf="hurdleRewardType === 'FIXED_AMOUNT_OFF'">

            <div class="barrierPreviewTitle">Discount Amount</div>
            <div class="barrierPreviewTitleValue"><span *ngIf="amountOff">{{currencySymbol}}</span>
              {{amountOff?amountOff:"-"}}</div>

          </div>

          <!-- <div style="display: flex;width:100%;" *ngIf="hurdleRewardType === 'FREE_SHIPPING'">

            <div class="barrierPreviewTitle" *ngIf="freeShippingType === 'MIN_ORDER_QUANTITY'">Minimum Spend Amount
            </div>
            <div class="barrierPreviewTitle" *ngIf="freeShippingType === 'MIN_ORDER_AMOUNT'">Minimum Items count
            </div>

            <div class="barrierPreviewTitleValue" *ngIf="freeShippingType === 'MIN_ORDER_QUANTITY'">
              {{freeShippingMinimumOrderCount?freeShippingMinimumOrderCount:"-"}}</div>
            <div class="barrierPreviewTitleValue" *ngIf="freeShippingType === 'MIN_ORDER_AMOUNT'">
              {{currencySymbol}} {{freeShippingMinimumAmount?freeShippingMinimumAmount:"-"}}</div>

          </div> -->

          <div style="display: flex;width:100%;" *ngIf="hurdleRewardType != 'BUY_X_GET_Y'">

            <div class="barrierPreviewTitle">Days to Redeem</div>
            <div class="barrierPreviewTitleValue">{{rewardTimeToComplete?rewardTimeToComplete:"-"}}
              <span *ngIf="rewardTimeToComplete==1">Day</span><span *ngIf="rewardTimeToComplete>1">Days</span>
            </div>

          </div>



        </div>

      </div>



    </div>



  <!-- </div> -->

  <div *ngIf="!offerReady" style="justify-content: center;align-items: center;display: flex;width: 100%;height: 100%;">

    <mat-spinner diameter="22"></mat-spinner>
  </div>

  <!-- QUICKTIPS CONTENT -->

  <ng-template #popContentCustomerWillUnlockaRewardWhen>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff"> This is a type of complete the challenge and get incentive kind of offer.
          Customers will unlock a reward on their next purchase when they complete the provided challenge. Example -
          Buy a Product within 7 days and get 10% off on your next order.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentCustomerWillBeRewardedWith>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff" *ngIf="barrierParamType!='NONE'"> Customers will get the below-configured
          incentive on their next purchase. It can be an Amount off, Percentage off, or Free shipping. You can also
          choose to give a free product on the same purchase. Example - Since you bought a product, you are eligible
          for 10% on your next order. </div>
        <div style="font-size: 11px;color:#fff" *ngIf="barrierParamType=='NONE'"> Customers will get the below-configured
          incentive on their next purchase. It can be an Amount off, Percentage off, or Free shipping. Example - Get
          $20 off on your next purchase.

        </div>
      </div>

    </div>
  </ng-template>

  <ng-template #popContentSpecificProductIsPurchased>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Customers will unlock a reward on their next purchase when they buy a Product.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentMinimumAmountIsSpent>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Customer will unlock a reward on their next purchase when they make a purchase
          over a minimum amount.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentCustomerNeedsToPurchase>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">You can select whether a customer needs to purchase a specific product or a
          product from a Category.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentMinimumAmountThatNeedsToBeSpent>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the amount which must be spent by the customers.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentAIAssistance>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Use the power of AI and let the algorithm choose the right product for every
          user.
          Change the toggle to enable AI assistance.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentProductDetails>
    <div style="display: flex;">
      <div style="display: flex;width:15%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 85%;">
        <div style="font-size: 11px;color:#fff">Choose the product and its variant.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentCategoryDetails>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the category from which the purchase should be made. All the products
          listed in this category in your store are valid for crossing this challenge.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentProductType>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Set the difficulty level to low or medium or high. If you choose to set a low
          difficulty level, the barrier will be easy to overcome i.e. it can be a challenge to purchase a product
          that is a quick-buy or has been often bought by the user in the past or is a low checkout value product
          etc.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentCategoryType>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Set the difficulty level to low or medium or high. If you choose to set a low
          difficulty level, the barrier will be easy to overcome i.e. it can be a challenge to purchase a product
          that is a quick-buy or has been often bought by the user in the past or is a low checkout value product
          etc.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentMinimumItemsInAPurchase>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the minimum items which should be purchased to unlock the reward.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentOfferWillBeValidFor>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the validity of this challenge in the number of days.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentAtAnyTime>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Change challenge validity for specified hours of the day or the whole day.
          Change
          the toggle to edit the validity from full-day (24 hours) to specified hours.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentEveryDayOfTheWeek>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Change challenge validity for specified days of the week. Change to toggle and
          select the appropriate days.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentFreeProduct>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Customer will get a free product. Example Buy X and get Y free.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentPercentageOff>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Customer will get a % off on their next purchase. Example - 10% off.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentAmountOff>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Customer will get a fixed amount off on their next purchase. Example. $10 off.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentFreeShipping>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Customer will get free shipping on their next purchase. Example- Get free
          shipping
          on your next order.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentTypeOfFreeProduct>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">You can choose a specific product or any product from a chosen category.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentCustomersWillGetAPercentage>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose whether % off should apply on a Specific Product, Products from a
          Category
          or the whole Order.
        </div>
      </div>
    </div>

  </ng-template>


  <ng-template #popContentCustomersWillGetAnAmountOff>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose whether amount waived off should apply on a Specific Product, Products
          from
          a Category or the whole Order
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentNumberOfProducts>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the number of products that will be given free.
        </div>
      </div>
    </div>

  </ng-template>


  <ng-template #popContentDiscountPercentage>
    <div style="display: flex;">
      <div style="display: flex;width:17%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 83%;justify-content: center;">
        <div style="font-size: 11px;color:#fff">Choose the discount %.
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #popContentDiscountAmount>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the amount which will be waived off.
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #popContentRewardWillBeValidFor>
    <div style="display: flex;">
      <div style="display: flex;width:10%">
        <mat-icon style="color:#fff">
          lightbulb_outline
        </mat-icon>
      </div>
      <div style="width: 90%;">
        <div style="font-size: 11px;color:#fff">Choose the validity of reward in the number of days. After crossing the
          challenge,
          this reward will be valid for this specified number of days.
        </div>
      </div>
    </div>

  </ng-template>











  <div id="overlay"></div>

  <div style="  position: absolute;
            top: 0;
            right: 0;
            height: calc(100vh);
            width: 65%;
            z-index: -1;font-size: 13px;" id="configDiv">


    <app-offer-schedule-overlay style="width: 100%;height: 100%;" (messageEvent)="receiveMessage($event)"
      [offerId]="queryparamofferId" [offerReqBodyObj]="offerReqBodyObj" [action]="saveActionType" [BarrierRewardFull]="BarrierRewardFull"
      *ngIf='openScheduleOverlay'>
    </app-offer-schedule-overlay>
  </div>
</div>