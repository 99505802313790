import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { getCurrencySymbol } from "@angular/common";

@Component({
  selector: 'app-loyalty-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {

  constructor(private _router: Router,private _apicall: ApiCallService,private _snackBar: SnackbarCollection,private dialog: MatDialog,private _i18nDynamicTranslate: TranslateDynamicText) { }

  loyaltyProgramConfig;
  buttonType = 'create';
  showDashboard = false;
  dataLoaded = false;
  emailVerified = true;
  emailVerificationMessage;
  ruleFrequencies;
  ruleEventDropdownValues = [];
  currencySymbol;
  loyaltyRules;

  ngOnInit(): void {
  let revCurrency = sessionStorage.getItem("serviceCurrency");
  this.currencySymbol = getCurrencySymbol(revCurrency, "narrow");
    this._apicall.getLoyaltyProgramConfig().subscribe(
      response=>{
        this.loyaltyProgramConfig = response['body'];
        if(this.loyaltyProgramConfig) {
          if(this.loyaltyProgramConfig.partialUpdate) {
            this.buttonType = 'continueConfig';
            this.dataLoaded = true;
          } else {
            this.buttonType = 'edit';
            this.loyaltyRules = this.loyaltyProgramConfig['ruleConfig'].loyaltyRules;
            this.getLoyaltyProgramDropdowns();
          }
        }
        else {
          this.dataLoaded = true;
        }
        
      },
      error=>{
        this._snackBar.openSnackBar("Error fetching config data", 3000);
      }
    );
    this.checkEmailVerification();
  }

  getLoyaltyProgramDropdowns() {
    this.dataLoaded = false;
    this._apicall.getLoyaltyProgramDropdowns().subscribe(response => {
      let responseData = response["body"];
      this.ruleFrequencies = responseData.eventFrequencies;
      const events: any[] = Object.values(responseData.loyaltyEvents);
      for (let event of events) {
        event.forEach((each) => {
          if(each.displayName.toLowerCase() === 'amount spent') {
            each.displayName = `${this.currencySymbol} spent in a purchase`;
          }
          this.ruleEventDropdownValues.push({
            name: each.name,
            displayName: each.displayName,
            valueEditable: each.valueEditable,
            defaultValue: each.defaultValue,
            operators: each.operators,
          });
        });
      }
      this.loyaltyRules.forEach(rule => {
        if(rule.ruleCategory == 'BASIC') this.initializeEventDropdownObjForBasicRuleDetails(rule);
        if(rule.ruleCategory == 'ADVANCE_CUSTOM') this.initializeEventDropdownObjForAdvanceCustomRuleDetails(rule);
      });
      this.dataLoaded = true;
      this.showDashboard = true;
    },
    error => {
      this._snackBar.openSnackBar("Error fetching events dropdown data", 2000);
    });
  }

  initializeEventDropdownObjForBasicRuleDetails(rule) {
    this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
      if(curDropdownVal.name == rule.ruleDefinition.event) {
        rule.ruleDefinition.eventDropdownObj = curDropdownVal;
        return;
      }
    });
  }

initializeEventDropdownObjForAdvanceCustomRuleDetails(rule) {
    rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.forEach((logicalExpress)=>{
      logicalExpress.comparisonStatements.forEach((comparisonStatement)=>{
        this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
          if(curDropdownVal.name == comparisonStatement.operand1) {
            comparisonStatement.eventDropdownObj = curDropdownVal;
            return;
          }
        });
      });
    });
    for(let freqObj of this.ruleFrequencies as any) {
      if(rule.ruleFrequency === freqObj.value) {
        rule['ruleFrequencyDisplay'] = freqObj.key;
      }
    }
  }
  
  checkEmailVerification() {
    this._apicall.isEmailVerified().subscribe(response => {
      if(response['body']['message'] === 'verified') {
        this.emailVerified = true;
      } else {
        this.emailVerified = false;
        this.emailVerificationMessage = response['body']['message'];
      }
    })
  }

  redirectToCreate() {
    this._router.navigate(["/app/loyalty/create-loyalty-program"]);
  }
  showVerifyEmailPopup() {
    let dialogReference = this.dialog.open(
      ConfirmationDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "40%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Email Verification Pending", ['POS_PAGE']),
          message:
            this._i18nDynamicTranslate.transform("Verify your email address to access full features on Qubriux.", ['POS_PAGE']),
          cancelButtonText: false,
          successButtonText: this._i18nDynamicTranslate.transform("Close", ['POS_PAGE']),
        },
      }
    );
  }
}
