import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCheckedPYG'
})
export class IsCheckedPYG implements PipeTransform {

  transform(segments, segmentId): any {
    //console.log(segments, segmentId)
    let index = segments.findIndex(d =>d === segmentId);
    //console.log(segments.findIndex(d =>d === segmentId));
    if(index > -1){
     // console.log(segments, segmentId,'true')
        return true;
    }
    else if(index==0){
         console.log(segments, segmentId,'true')
      return true;
    }
    else{
        return false;
    }
  }
}