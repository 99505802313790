import { Injectable } from '@angular/core';
import { SnackbarCollection } from './snackbar.service'; 

@Injectable()

export class ErrorHandler {

    constructor(private snackbar : SnackbarCollection) { }

    // openSnackBar(message: string, action: string) {
    //     this._snackBar.open(message, action, {
    //       duration: 2000,
    //     });
    //   }

    showError(error) {



        if (error.status != 401 || error.error.error != "Unauthorized") {
            // this.openSnackBar('Error', 'Okay')
            this.snackbar.openSnackBar(error.message,2000);

        }
    }
}