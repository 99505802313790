<script
  type="text/javascript"
  src="https://gc.kes.v2.scr.kaspersky-labs.com/7EA5E9BB-55E1-4C31-9C21-4943DDFED2E4/main.js?attr=nH_v1_VumtMdKd1_vvoo_bDaHBS78czQCjz2ineT2v4P-JmmkYr0On-FqDLdV4E1nNPixnegODhsGMr5zdX6KlL1J3v3pMoKv28jbz3-q5S6R2VhWrhUcmFjwabndFy-SGrny1pf0weAUWiSUK11M4WlG61TL_FxgeEyRe4T1tc8oMuEVn6xJ9WAx7fniQHzkeQ7BjVNNYkMBUhR5rPnT7lIjbrjWhzSHrf-GPmXpebQXZcTxNaKnirb9UfPRlsT2LZcHWzsgCnl99iIy_1rL9C9q48r6tBO25_VJX5D_nVOfNShuJACbyMhUT6rYvOI6G1JZojahvV4i6VDUCEh7l_uBEK_ty53i2QsmKOw4e7x3ErvWgx_W4L0bK1lCDjbluQ4FS_gRvHC_snMVT2a_wIgYIEi8cQnCECQTyxRBnSfQDFz8dW3EwNCDJ4oXkFfhjfuypy8ISVw9gGOSzu91dqHOB1Xhu5EIHWEsDHwRKdzwenOcgmzygDBajW6UMzvG0mo-8G7UtXP7UtYlt7TMGEbBcZpUNWAplK2RdTfcFV-Viev2Lt980IxAm47QGF6Nj9ypFcVrbf0zl-p9Wbz8q9o6xaagFbtD7tSQym5zw2vGMsjnktSQ68z4V_0IK4lQ_WZ5ax_PpeUn-jdDEMjhY_8u9pDtrnUqIwOjhbIE0aBKvxSVGkkgcMeoDbw7WENN12PFjog1MVbAzfz-mGx0LQWFPByhMOmqNeX1M1n_1pkoWernG_IYli8cijbBkP88qPwIJ3pgIyLNM1objsVD0lukb911K9yhGYfuUPGRWPcYK_I2iSgn1PggOI5YX1HGO9CpRxhZCkJlzulcWJhuZUUu1zCmx1-dAZ89iSma07XGkHGvAWJx46pwdoifp4RwVdWyotdcjBQFbSfw264ui60kWmTdtWikUqpMbaaBX56XeKVrBwsDboQm3mPUQVqFWYYWOrDSIQzg8XTqtZ9pM1lyAbep6MQlY5s_k3HW75lcwGAv-5za8KnYj6BQnVxC6xyMY1I4TD_0vtg6JoyIk6w5SkUO1eB69W5sjuE-t5kE0b42BLm5ZrKM4PBYMp0L1jmEIV9EZwQtkMj3tqjDo_pAfluFqhUluAqrRteLxFiz1nl_A_e9t0OEKGNoVJcGXIdo1XuoGFNe1Tc3B7zRH19ZkrKalEIlzhniiwb8kJ2YHCutDp4lOqXUGsNag-r0mj_SZbRj9hwXkWidEwOl7jTM7eOi50BrZ6XcugO7y5Y_7ukftBWsUo593_YC8zWSEksmMoytSu58PCcX2ZfofEWzSbYYlicRgbvvlX4z-Y"
  nonce="c0e93f32220163f0c923bd5e8b76d6a2"
  charset="UTF-8"
></script>
<style>
  h1 {
    margin-top: 17px;

    font-size: 17px;
  }
  h2 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 0.3in;
    font-size: 17px;
    font-weight: normal;
  }
  h3 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 1in;
    font-size: 17px;
    font-weight: normal;
  }
  h4 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 2in;
    font-size: 17px;
    font-weight: normal;
  }
  h5 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 2.5in;
    font-size: 17px;
    font-weight: normal;
  }
  h6 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 3in;
    font-size: 17px;
    font-weight: normal;
  }
  p.MsoHeading7,
  li.MsoHeading7,
  div.MsoHeading7 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 3.75in;
    font-size: 17px;
  }
  p.MsoHeading8,
  li.MsoHeading8,
  div.MsoHeading8 {
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    text-indent: 4in;
    font-size: 17px;
  }
  p.MsoHeading9,
  li.MsoHeading9,
  div.MsoHeading9 {
    mso-style-link: "Heading 9 Char";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 3pt;
    margin-left: 0in;
    text-indent: 4.75in;
    font-size: 11pt;
  }
  p.Def2Heading1,
  li.Def2Heading1,
  div.Def2Heading1 {
    mso-style-name: "Def2 Heading 1";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.Def2Heading2,
  li.Def2Heading2,
  div.Def2Heading2 {
    mso-style-name: "Def2 Heading 2";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 1in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.Def2Heading3,
  li.Def2Heading3,
  div.Def2Heading3 {
    mso-style-name: "Def2 Heading 3";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 1.5in;
    text-indent: -9pt;
    font-size: 17px;
  }
  p.Def2Heading4,
  li.Def2Heading4,
  div.Def2Heading4 {
    mso-style-name: "Def2 Heading 4";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 2in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.Def2Heading5,
  li.Def2Heading5,
  div.Def2Heading5 {
    mso-style-name: "Def2 Heading 5";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 2.5in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.ExAHeading1,
  li.ExAHeading1,
  div.ExAHeading1 {
    mso-style-name: "ExA Heading 1";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.ExAHeading2,
  li.ExAHeading2,
  div.ExAHeading2 {
    mso-style-name: "ExA Heading 2";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 1in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.ExAHeading3,
  li.ExAHeading3,
  div.ExAHeading3 {
    mso-style-name: "ExA Heading 3";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 1.5in;
    text-indent: -9pt;
    font-size: 17px;
  }
  p.ExAHeading4,
  li.ExAHeading4,
  div.ExAHeading4 {
    mso-style-name: "ExA Heading 4";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 2in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.ExAHeading5,
  li.ExAHeading5,
  div.ExAHeading5 {
    mso-style-name: "ExA Heading 5";
    margin-top: 17px;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 2.5in;
    text-indent: -0.25in;
    font-size: 17px;
  }
  p.Body,
  li.Body,
  div.Body {
    mso-style-name: Body;
    margin-top: 6pt;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.25in;
    text-align: justify;
    text-indent: 0in;
    font-size: 17px;
  }
  .MsoChpDefault {
    font-size: 17px;
  }
  .MsoPapDefault {
    margin-top: 17px;
  }
  /* Page Definitions */
  @page WordSection1 {
    size: 8.5in 11in;
    margin: 1.2in 0.75in 1in 1in;
  }
  div.WordSection1 {
    page: WordSection1;
  }
  /* List Definitions */
  ol {
    margin-bottom: 0in;
  }
  ul {
    margin-bottom: 0in;
  }
</style>

<div>
  <p style="margin-top: 20px">
    <b>
      <span>
        IMPORTANT NOTICE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION
        PROVISION AND CLASS ACTION WAIVER. IT AFFECTS YOUR LEGAL RIGHTS AS
        DETAILED IN THE ARBITRATION AND CLASS ACTION WAIVER SECTION BELOW.
        PLEASE READ CAREFULLY.
      </span>
    </b>
  </p>

  <p>
    These Terms of Platform (the "<b>Agreement</b>") constitute a binding
    contract between you and Skellam AI, Inc. Throughout this Agreement, we will
    refer to you as "<b>you</b>," we will refer to Skellam AI, Inc. and its
    affiliates and subsidiaries as "<b>we</b>", "<b>us</b>", or "<b>SK AI</b>".
    This Agreement governs your use of the referral marketing platform created
    by SK AI (the "<b>Platform</b>").
    <b
      >BY ACCESSING OR USING ANY PART OF THE PLATFORM, YOU AGREE THAT YOU HAVE
      READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT
      AGREE, THEN YOU MAY NOT USE ANY PART OF THE PLATFORM OR RELATED
      SERVICE.</b
    >
  </p>

  <h1><span>1. </span><b>Summary of Material Terms</b>.</h1>

  <h1>
    As provided in greater detail in this Agreement (and without limiting the
    express language of this Agreement), you acknowledge the following:
  </h1>

  <h2>
    a. the Platform is licensed, not sold, to you, and you may use the Platform
    only as set forth in this Agreement;
  </h2>

  <h2>
    b. you consent to the collection, use, and disclosure of your personally
    identifiable information and other data in accordance with our Privacy
    Policy;
  </h2>

  <h2>
    c. the Platform is provided "as is" without warranties of any kind and SK
    AI's liability to you is limited; and
  </h2>

  <h2>
    d. disputes arising out of or relating to this Agreement (including, without
    limitation, its formation, performance, or breach) will be resolved by
    binding arbitration, and the parties agree
    <span
      >that the arbitration shall be conducted in their individual capacities
      only and not as a class action or other representative action</span
    >.<b> </b>
  </h2>

  <h1><span>2. </span><b>General</b>.</h1>

  <h2>
    a. <u>The Platform</u>. Users of the Platform will be referred to herein as
    "<b>Merchants</b>". As a user of the Platform, you will be a Merchant. A
    Merchant may offer (an "<b>Offer</b>") other Merchants (a) price discounts
    on the products or services sold online by such offering Merchant (the "<b
      >Seller Merchant</b
    >") and (b) referral fee incentives to other Merchants (each a "<b
      >Affiliate Merchant</b
    >") who advertise, market or promote such Offer to the Affiliate Merchant's
    users, audience or customers ("<b>Affiliate Merchant Customers</b>"). Each
    Offer must include a discounted price or other incentive on the Seller
    Merchant's products or services for the Affiliate Merchant Customers and a
    referral incentive fee to the Affiliate Merchant (a "<b>Referral Fee</b>") for
    each successful initial e-commerce transaction (an "<b>Referred Sale</b>")
    completed by the Seller Merchant from a referral by a Affiliate Merchant. The
    Affiliate Merchant alone is responsible for the manner in which an Offer is
    promoted by the Affiliate Merchant provided such advertising complies with all
    applicable laws and accurately reflects the discount offered by the Seller
    Merchant to the Affiliate Merchant Customers. The Referral Fee will be paid by
    the Seller Merchant to SK AI, who will then pay the Referral Fee in full to
    the Affiliate Merchant on behalf of the Seller Merchant. You may be a Seller
    Merchant for some Offers and a Affiliate Merchant for other Offers, or just one
    or the other. To be a Merchant, you will pay to SK AI a monthly fee (the "<b
      >Monthly SK AI Fee</b
    >") equal to <span style="background: yellow">[$]</span>. You will only owe
    one (1) Monthly SK AI Fee regardless of whether you are both a Seller
    Merchant on some offers and a Affiliate Merchant on other Offers. A Seller
    Merchant will not take into account returned or refunded e-commerce
    transactions when considering whether an Offer generated an Referred Sale.
    SK AI and the Seller Merchant will help facilitate tracking of successful
    Referred Sales. SK AI will provide a Merchant with the ability to track
    Referred Sales. You agree to indemnify in full and hold harmless SK AI in
    the event of any dispute involving you, a Seller Merchant, a Affiliate Merchant
    or a Affiliate Merchant Customer. If you terminate this Agreement, you will not
    be refunded any Monthly SK AI Fee and you will owe any Referral Fees you owe
    as a Seller Merchant for Referred Sales completed prior to the date of such
    termination. The following other obligations apply to you, depending on
    whether you are a Seller Merchant or Affiliate Merchant.
  </h2>

  <p>
    <span>(i) </span>The Affiliate Merchant is under no obligation to promote any
    offer or promotion from a Seller Merchant.
  </p>

  <p>
    <span>(ii) </span>The Affiliate Merchant may reduce or eliminate entirely the
    promotion of the Offer at any time.
  </p>

  <p>
    <span>(iii) </span>The Seller Merchant shall not owe the Affiliate Merchant any
    fees or compensation for the promotion other than the Referral Fee.
  </p>

  <p>
    <span>(iv) </span>The Affiliate Merchant or its affiliates will not incentivize
    any Affiliate Merchant Customer to make an Referred Sale where the intent of
    such Affiliate Merchant Customer is to return the goods received from or demand
    a refund for the Referred Sale.
  </p>

  <p>
    <span>(v) </span>No Merchant is making a promise or guarantee to any other
    Merchant hereunder regarding the attainment of a Referred Sale.
  </p>

  <p>
    <span>(vi) </span>The Affiliate Merchant need not be an e-commerce merchant to
    be a Affiliate Merchant.
  </p>

  <p>
    <span>(vii) </span>The Seller Merchant is responsible for withholding and
    paying any sales tax or other related tax due for the Referred Sale.
  </p>

  <p>
    <span>(viii) </span>Each Merchant acknowledges and agrees that the Seller
    Merchant and Affiliate Merchant may not be located in the same country and the
    Affiliate Merchant Customer may be located in a different country than the
    Seller Merchant or Affiliate Merchant.
  </p>

  <p>
    <span>(ix) </span>A Seller Merchant may rescind an Offer at any time with
    sufficient notice to SK AI and a Affiliate Merchant.
  </p>

  <h2>
    b. <u>Acceptance of Privacy Policy</u>. Your use of the Platform is subject
    to our Privacy Policy, which is available at
    <a href="https://octaneai.com/privacy"
      ><span style="color: blue; background: yellow">[]</span></a
    >
    (the "<b>Privacy Policy</b>"), and which is hereby incorporated by reference
    into this Agreement. By using the Platform you agree that you have read,
    understood, and agree to all of the provisions of the Privacy Policy,
    including but not limited to those regarding the collection and use of data.
    In the event of any conflict between these Terms of Platform and the Privacy
    Policy, the Privacy Policy will control.
  </h2>

  <h2>
    <span>c. </span><u><span>Users</span></u
    ><span
      >. To use the Platform, you must be, and represent and warrant that you
      are a legal entity or are 18 years of age or older or otherwise of legal
      age in your jurisdiction.</span
    >
  </h2>

  <h1>
    <span>3. </span><b><span>Scope of License.</span></b>
  </h1>

  <h2>
    a. <u><span>License Grant to You</span></u
    ><span>. The Platform is licensed</span>, not sold, to you and you are
    permitted to use the functions and features of the Platform made available
    to you by SK AI only according to the terms of this Agreement. Your rights
    to access and use the Platform are limited, revocable, non-exclusive,
    non-transferable, and non-sublicensable, and are solely for your internal
    business operations. Each Seller Merchant provides SK AI and the Affiliate
    Merchant with a limited, non-transferable, non-sublicensable license to use
    the Seller Merchant's name and offering details in the promotion related to
    the Offer.
  </h2>

  <h2>b. <u>License Limitations</u>.</h2>

  <h3>
    i. You may access and use the Platform solely as intended through the
    provided functionality of the Platform and as permitted under this
    Agreement. You may not do, or attempt to do, any of the following:
  </h3>

  <h4>
    A. use the Platform to provide services to any third party in support of
    such third party's business;
  </h4>

  <h4>
    B. directly rent, lease, lend, sell, redistribute or sublicense the
    Platform;
  </h4>

  <h4>
    C. copy, decompile, reverse engineer, disassemble, attempt to derive the
    source1 code of, modify, or create derivative works of any portion of the
    Platform, any updates, or any part thereof (except as and only to the extent
    that any foregoing restriction is prohibited by applicable law);
  </h4>

  <h4>
    D. display, mirror, publish, distribute, transmit, or transfer any portion
    of the Platform;
  </h4>

  <h4>
    E. remove or obscure any trademark symbols, copyright notices, or other
    intellectual property rights notices present in the Platform or any content
    therein;
  </h4>

  <h4>
    F. use any trademarks, service marks, design marks, logos, photographs or
    other content belonging to SK AI or obtained from the Platform;
  </h4>

  <h4>
    G. use any part of the Platform for third-party training, commercial
    time-sharing or service bureau use;
  </h4>

  <h4>
    H. publish or disclose any benchmark tests of any part of the Platform;
  </h4>

  <h4>
    I. use any part of the Platform for any illegal or unauthorized purpose,
    including any purpose in violation of any applicable law;
  </h4>

  <h4>
    J. interrupt operation of any part of the Platform or disable or circumvent
    any security or other technological measure designed to protect the Platform
    or any content available through the Platform;
  </h4>

  <h4>
    K. interfere with, compromise the system integrity or security of, or
    decipher any transmissions to or from the servers running the Platform;
  </h4>

  <h4>
    L. take any action that imposes, or may impose, as determined in SK AI's
    sole discretion, an unreasonable or disproportionately large load on its
    infrastructure;
  </h4>

  <h4>
    M.upload any invalid data, viruses, worms, or other software agents or
    malware to or through the SK AI;
  </h4>

  <h4>
    N. bypass the measures used to prevent or restrict access to the SK AI,
    including features that prevent or restrict use or copying of any content or
    enforce limitations on use of the Platform; or
  </h4>

  <h4>
    O. provide or transmit to SK AI or the Platform any data that contains any
    personally identifiable information or any other data that is subject to
    regulation under any data-protection legislation applicable to the
    processing or use of such data, including data that is governed by the
    Gramm-Leach-Bliley Act (GLBA), the Health Insurance Portability and
    Accountability Act (HIPAA), or the Children's Online Privacy and Protection
    Act (COPPA).
  </h4>

  <h3>
    ii. If you breach any of these restrictions, or otherwise exceed the scope
    of the licenses and rights granted in this Agreement, then you may be
    subject to prosecution and damages, liability for infringement of
    intellectual property rights, termination of your Agreement, and denial of
    access to the Platform. SK AI reserves the right to pursue any other legal
    and equitable remedies available to it.
  </h3>

  <h2>
    c. <u>Applicability to Updates</u>. The terms of this Agreement will govern
    any updates provided to you by SK AI that replace and/or supplement the
    Platform, unless such upgrade is accompanied by a separate license or other
    agreement, in which case the terms of that license or revised agreement will
    govern to the extent of any inconsistencies between this Agreement and such
    license or other agreement.
  </h2>

  <h1><span>4. </span><b>Account Security</b></h1>

  <h2>a. <u>[Intentionally left blank]</u>.</h2>

  <h2>b. <u>Account Security</u>.</h2>

  <h3>
    i. Your account with the Platform is personal to you or you represent, and
    you may not share your account information with, or allow access to your
    account by, any third party. As you will be responsible for all activity
    that occurs under your access credentials to the Platform, you agree to use
    reasonable efforts to prevent unauthorized access to or use of the Platform
    and to preserve the confidentiality of your username and password, and any
    device that you use to access the Platform. You agree to notify us
    immediately of any breach in the confidentiality of your access credentials
    to the Platform. You shall be solely responsible for maintaining the
    confidentiality of your password.
  </h3>

  <h3>
    ii. SK AI cannot guarantee that unauthorized third parties will never be
    able to defeat our security measures. If you have any reason to believe that
    your access credentials or other account information have been compromised
    or that your account has been accessed by a third party, you agree to
    immediately notify us via e-mail at
    <a href="mailto:cs@qubriux.com"
      ><span style="color: blue">cs@qubriux.com</span></a
    >. You will be solely responsible for the losses incurred by SK AI and
    others due to any unauthorized use of your account with the Platform.
  </h3>

  <h1><span>5. </span>[Intentionally left blank]</h1>

  <h1>
    <span>6. </span><u>Fees</u>. Your access to and use of the Platform is
    subject to the payment of all fees and other charges set forth in this
    Agreement, and you agree to pay all such fees in accordance with this
    Agreement. All information that you provide in connection with the
    Subscription Plan or other transaction with SK AI and/or the Platform must
    be accurate, complete, and current.
  </h1>

  <h2>
    a. <u>Payment Terms</u>. You must pay each invoice, if any, issued by SK AI
    upon the date due. Any amounts not paid within the applicable payment period
    shall bear interest at the rate of one and one-half percent per month or the
    maximum rate allowed by law, whichever is less. The Platform currently uses
    third parties to process payment of fees. Our third-party payment processors
    accept payments through various credit cards, as detailed on the applicable
    payment screen. All monetary transactions on the Platform take place in U.S.
    Dollars.
  </h2>

  <h2>
    b. <u>Taxes</u>. Except for taxes on (i) your income, a Merchant's income or
    SK AI's income or (ii) a Seller Merchant's sales tax on an Referred Sale,
    you are responsible for determining and paying all appropriate government
    taxes, fees, and service charges resulting from your use of the Platform or
    any transaction occurring through the Platform, including any sales, excise,
    service, use or other taxes now in effect or imposed later. We are not
    responsible for collecting, reporting, paying, or remitting to you any such
    taxes, fees, or service charges, except as may otherwise be required by law.
  </h2>

  <h2>
    c. <u>Audit Rights</u>. We reserve the right to audit your use of the
    Platform. Additional terms regarding such audits may be as set forth in a
    separate agreement.
  </h2>

  <h2>
    d. <u>Additional Features</u>. SK AI may add new features for additional
    fees and charges at any time. We may amend fees and charges for existing
    features at any time, and such amended fees shall go into effect immediately
    in the following month. Except as otherwise set forth in this Agreement, in
    no event will you be entitled to any refund of fees set forth herein.
  </h2>

  <h1><span>7. </span><b>Spam and Other Promotion-Related Legal Topics</b>.</h1>

  <h1>
    You agree that your use of the Platform will not include sending unsolicited
    marketing messages or broadcasts (i.e. spam). SK AI may utilize any means
    possible to block spammers and abusers from using the Platform. If you
    believe spam originated from the Platform or you receive spam via the
    Platform, please email us immediately at
    <a href="mailto:cs@qubriux.com"
      ><span style="color: blue">cs@qubriux.com</span></a
    >. Communication to Affiliate Merchant Customers shall not be considered spam
    as long as the Affiliate Merchant had a bone fide relationship with the
    customer or audience member before the marketing related to the Offer was
    sent to such Affiliate Merchant Customer. Any Affiliate Merchant communication
    related to this Agreement must allow users to opt-out of such communication
    and otherwise conform to all applicable U.S. and other relevant laws.
  </h1>

  <h1><span>8. </span><b>External Platforms and Third-Party Terms</b>.</h1>

  <h2>
    a. The Platform may contain certain functions and features, and may link,
    display or provide you with access to certain data, originating from
    External Platforms under agreements between such External Platforms and SK
    AI AI. The specific External Platforms are chosen by SK AI in its sole
    discretion. When using the Platform, you may be required to enter user
    identification, passwords and other credentials in order to access data
    originating with External Platforms. You are solely responsible for
    maintaining all valid credentials necessary to access External Platforms and
    SK AI shall not be responsible for any failure by you to access or connect
    to any External Platform due to invalid credentials. SK AI is not
    responsible for and does not endorse any features, content, advertising,
    products, or other material on other websites or applications and you assume
    all risk arising from your use of such websites or applications.
  </h2>

  <h2>
    b. SK AI has no control over and is not responsible for External Platforms
    or for the operational activities, privacy practices, data-collection
    practices, security, accuracy or content of any External Platforms. Access
    to, and use of, External Platforms may be subject to additional or different
    third-party terms and conditions and policies, including privacy policies
    (collectively, "<b>Third-Party Terms</b>") and may not be available in all
    languages or in all countries. You agree to comply with all Third-Party
    Terms applicable to External Platforms.
  </h2>

  <h2>
    c. Your access to External Platforms and use of any content or data supplied
    by External Platforms during your use of the Platform is at your own risk.
    You understand that by using the Platform you may encounter data,
    information, applications, materials and other content from third parties,
    including other users (collectively, "<b>Third-Party Materials</b>"). You
    use the Platform, and rely on any Third-Party Materials at your sole risk.
    SK AI shall have no liability to you for any Third-Party Materials that may
    be found to be inaccurate, incomplete, untimely, invalid, illegal, indecent,
    or otherwise objectionable.
  </h2>

  <h1><span>9. </span><b>Your Use of the Platform and SK AI Content</b>.</h1>

  <h1>
    Your right to use the Platform is expressly conditioned on the following:
  </h1>

  <h2>
    a. You agree not to undertake, cause, permit or authorize the translation,
    reverse engineering, disassembling or hacking of any aspect of the Platform,
    including any SK AI Content (as defined below) available on or through the
    Platform, or attempt to do any of the foregoing, except and solely to the
    extent permitted by this Agreement, the authorized features of the Platform,
    or by law, or otherwise attempt to use or access any portion of the Platform
    other than as intended by SK AI.
  </h2>

  <h2>
    b. You agree not to use, display, mirror, frame or utilize framing
    techniques to enclose the Platform, including any SK AI Content available on
    or through the Platform, or any portion thereof, through any other
    application or website, unless and solely to the extent SK AI makes
    available the means for embedding any part of the Platform or SK AI Content.
  </h2>

  <h2>
    c. You agree not to access, tamper with, or use non-public areas of the
    Platform, SK AI's (and its hosting company's) computer systems and
    infrastructure, or the technical delivery systems of SK AI's providers.
  </h2>

  <h2>
    d. You agree not to harass, abuse, harm or advocate or incite harassment,
    abuse or harm of another person or group, including SK AI employees and
    other Users.
  </h2>

  <h2>
    e. You agree not to provide any false personal information to SK AI or any
    other user, or create a false identity or impersonate another person or
    entity in any way.
  </h2>

  <h2>
    f.
    <span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
    >You agree not to create or attempt to create a new account with SK AI,
    without SK AI's express written consent, if SK AI has previously disabled an
    account of yours.
  </h2>

  <h2>
    g. You agree not to solicit, or attempt to solicit, personal information
    from other Users.
  </h2>

  <h2>
    h. You agree not to restrict, discourage or inhibit any person from using
    the Platform, disclose personal information about a third person on the
    Platform or obtained from the Platform without the consent of such person,
    or collect information about Users.
  </h2>

  <h2>
    i. You agree not to use the Platform, without SK AI's express written
    consent, to communicate or facilitate any commercial advertisement or
    solicitation, except as expressly permitted in this Agreement.
  </h2>

  <h2>
    j. You agree not to gain unauthorized access to the Platform, to other
    Users' accounts, names or personally identifiable information, or to other
    computers or websites connected or linked to the Platform.
  </h2>

  <h2>
    k. You agree not to post, transmit or otherwise make available any virus,
    worm, spyware or any other computer code, file, or program that may or is
    intended to disable, overburden, impair, damage, or hijack the operation of
    any hardware, software or telecommunications equipment, or any other aspect
    of the Platform or communications equipment and computers connected to the
    Platform.
  </h2>

  <h2>
    l. You agree not to interfere with or disrupt the Platform, or networks or
    servers connected to the Platform, or violate the regulations, policies or
    procedures of such networks or servers.
  </h2>

  <h2>
    m. You agree not to violate (1) any applicable international, federal, state
    or local laws or regulations applicable to you, your products or Platforms
    (or those of the Subscribing Entity), or any data you or the Subscribing
    Entity collect or process; (2) any judicial, governmental, or administrative
    order, judgment, decree or ruling or enforceable requirements of any
    industry self-regulatory body; or (3) any enforceable regulatory guidance
    and written or authoritative interpretation of any of the foregoing by a
    regulatory body.
  </h2>

  <h2>
    n. You agree not to assist or permit any persons in engaging in any of the
    activities described above.
  </h2>

  <p>
    A breach of these restrictions may subject you to prosecution and damages,
    as well as liability for infringement of intellectual property rights and
    any other legal and equitable remedies available to us.
  </p>

  <h1><span>10. </span><b>Ownership</b>.</h1>

  <h2>a. <u>Definitions</u>. As used in this Agreement,</h2>

  <h3>
    i. "<b>Intellectual Property Rights</b>" means all patent rights, copyright
    rights, moral rights, rights of publicity, trademark, trade dress and
    Platform mark rights, goodwill, trade secret rights and other proprietary or
    intellectual property rights.
  </h3>

  <h3>
    ii. "<b>Merchant Data</b>" means any data collected or received from you, or
    from External Platforms on your behalf, in connection with the Platform.
    Please see the Privacy Policy for more details regarding the information SK
    AI collects, and how it uses and discloses that information.
  </h3>

  <h3>
    iii. "<b>SK AI Content</b>" means all output, content, data or information
    made available through or contained within the Platform, including without
    limitation, text, documents, charts, logos, buttons, icons, "look and feel"
    and screenshots, audio and video recordings, graphics, photographs, still
    and moving images, sound, illustrations, information, and software,
    including the organization and arrangement of all such content within the
    Platform, and all derivative works of any such content. SK AI Content
    excludes (i) Merchant Data and (ii) any content provided by an External
    Platform.
  </h3>

  <h2>
    b. <u>Rights to Data</u>. Subject to the licenses you grant to SK AI below,
    as between you and SK AI, you exclusively own all right, title, and interest
    in and to all Merchant Data, including all Intellectual Property Rights
    therein. SK AI is not responsible for the content or accuracy of any
    Merchant Data and SK AI may at any time and without prior notice remove,
    edit or block any Merchant Data that SK AI determines, in its sole
    discretion, to be in violation of this Agreement.
  </h2>

  <h2>
    c. <u>Use of Data</u>. You hereby grant to SK AI a non-exclusive,
    irrevocable, fully paid and royalty-free, transferable, sublicensable,
    worldwide license to collect, use, copy, reproduce, process, adapt, modify,
    publish, transmit, display, distribute and create derivative works of the
    Merchant Data, and any part thereof, (a) to the extent necessary or useful
    for SK AI to provide the Platforms to you and to transfer raw and modified
    Merchant Data to and from External Platforms, (b) in order to create, use,
    store, publish and distribute aggregate data, metadata, analyses and reports
    that do not identify you, (c) to comply with applicable legal requirements,
    including legal process and law enforcement requests, (d) to protect SK AI's
    rights, property and operations, including to enforce SK AI's agreements,
    policies, and terms and conditions, and to protect the rights, property and
    operations of SK AI's affiliates, business partners, customers or others,
    (e) to protect the personal safety of any individual, and (f) disclose and
    transfer Merchant Data to third parties and their agents in the event of a
    sale or transfer, or proposed sale or transfer, of all or a part of SK AI's
    business, assets or stock to such third parties. SK AI will not publish
    Merchant Data without your consent except as described in (a) through (f)
    above.
  </h2>

  <h2>
    d. <u>Third Parties</u>. The authorizations you grant to SK AI under this
    Agreement, including under this Section ‎10 (Ownership), will extend to
    Platform providers and other contractors exercising such rights and licenses
    on SK AI's behalf, and SK AI may share Merchant Data with such third parties
    who provide Platforms on SK AI's behalf, subject to the restrictions
    applicable to SK AI in this Agreement.
  </h2>

  <h2>
    e. <u>Your Representations and Warranties</u>. You represent and warrant to
    SK AI that (i) you own the Merchant Data or otherwise have the right to
    grant the license set forth in this Agreement; (ii) the use of the Merchant
    Data on or through the Platform does not violate the trade secret rights,
    privacy rights, publicity rights, copyrights, intellectual property rights,
    or any other rights of any third party; and (iii) the use of the Merchant
    Data on the Platform does not result in a breach of contract between you and
    any third party, and (iv) you have obtained all required licenses, permits,
    authorizations, regulatory approval or other approvals for you to access and
    use the Platform and for SK AI to use, disclose and otherwise process the
    Merchant Data on your behalf as set forth herein. You will also comply with
    all terms of use imposed by External Platforms.
  </h2>

  <h2>
    f. <u>Reservation of Rights</u>. Other than rights expressly granted to you
    in this Agreement, SK AI retains all of its right, title and interest in and
    to the Platform and all SK AI Content, including all Intellectual Property
    Rights therein. Except as expressly provided in this Agreement, nothing
    herein shall be deemed to create a license in or under any such Intellectual
    Property Rights. Use of any SK AI Content or other materials obtained
    through the Platform for any purpose not expressly permitted in this
    Agreement is strictly prohibited.
  </h2>

  <h2>
    g. <u>Feedback</u>. We appreciate hearing from our Users and welcome your
    comments regarding the Platform. Please be advised, however, that if you
    send us creative ideas, suggestions, inventions, or materials (collectively,
    "<b>Feedback</b>"), we will:
  </h2>

  <h3>i. own, exclusively, all rights to the Feedback;</h3>

  <h3>
    ii. not be subject to any obligation of confidentiality and will not be
    liable for any use or disclosure of any Feedback; and
  </h3>

  <h3>
    iii. be entitled to unrestricted use of the Feedback for any purpose
    whatsoever, commercial or otherwise, without compensation to you or any
    other person.
  </h3>

  <h1><span>11. </span><b>Data Security</b>.</h1>

  <h2>
    a. <u><span>Secure Content</span></u
    ><span
      >. SK AI will implement and maintain commercially reasonable
      administrative, physical, and technical safeguards reasonably designed to
      prevent any unauthorized use, access, processing, destruction, loss,
      alteration or disclosure of your confidential account information, and
      Merchant Data ("<b>Secure Content</b>"). However, SK AI makes no
      representations that its information security program is adequate to
      safeguard sensitive personal information of any kind, including personal
      information that is subject to regulation under applicable data privacy
      laws, including, but not limited to the Gramm-Leach-Bliley Act (GLBA), the
      Health Insurance Portability and Accountability Act (HIPAA), or the
      Children's Online Privacy and Protection Act (COPPA). You are solely
      responsible for compliance with Laws applicable to such sensitive personal
      information.</span
    >
  </h2>

  <h2>
    b. <u><span>Security Breach</span></u
    ><span
      >. SK AI will use commercially reasonable efforts to notify Users
      following discovery of a breach or compromise of the security,
      confidentiality, or integrity of Secure Content. Notwithstanding the
      foregoing, Users will be solely responsible for all costs associated with
      such a breach or compromise, including but not limited to the cost, timing
      and content of providing notice to any affected persons or other parties
      as may be required under applicable data breach notification laws. You
      agree to promptly notify SK AI if you discover or become aware of any
      security vulnerability or breach in any SK AI system, network, or
      software.</span
    >
  </h2>

  <h1><span>12. </span><b>Confidentiality</b>.</h1>

  <h1>
    <span
      >You may obtain confidential and/or proprietary information of SK AI
      and/or its licensors through your use of the Platform, including without
      limitation SK AI Content, pricing and other terms and conditions herein or
      in the Subscription Plan, marketing and sales information relating to the
      Platform and other information, technology, ideas or algorithms derived
      from the Platform or the use thereof, excluding Merchant Data ("<b
        >Confidential Information</b
      >"). You shall not (a) use any Confidential Information, except to the
      extent expressly permitted in this Agreement, or (b) disclose any
      Confidential Information to any third party, except in connection with
      your performance under this Agreement and only on a need-to-know basis to
      employees, agents and contractors of the Subscribing Entity who have
      agreed in writing to treat the Confidential Information under terms at
      least as restrictive as those herein. You agree to take the necessary
      precautions to maintain the confidentiality of the Confidential
      Information by using at least the same degree of care with which you treat
      your own confidential information of a similar nature, but in no case less
      than reasonable care. The foregoing confidentiality restrictions will not
      apply to Confidential Information to the extent that you can demonstrate
      that: (i) such information is generally available for use and disclosure
      by the public without any charge or license and without a breach of an
      obligation of confidentiality to SK AI or its licensors; (ii) such
      information was in your possession without an obligation of
      confidentiality prior to its disclosure by SK AI (or through the
      Platform); or (iii) you rightfully obtained such information from a third
      party without restriction as to use or disclosure or the breach of an
      obligation of confidentiality to SK AI. If you are required by a court or
      government agency to disclose Confidential Information, you must provide
      advance notice to SK AI.</span
    >
  </h1>

  <h1><span>13. </span><b>Beta Products and Platforms</b>.</h1>

  <h1>
    <span
      >You acknowledge and agree that certain SK AI products and services may be
      made available through the Platform to select customers on a beta or trial
      basis, that such products and services may contain bugs, defects, and
      errors, and that such products and services are not expected to function
      fully when first made available.</span
    >
  </h1>

  <h1>
    <span>14. </span><b>Consequences of Violating These Terms of Platform</b>.
  </h1>

  <h1>
    <span
      >We reserve the right to suspend or terminate your account and prevent
      access to the Platform for any reason, </span
    >at our<span>
      sole discretion. We reserve the right to refuse to provide the Platform to
      you in the future</span
    >. <span>We </span>may review and remove any Merchant Data at any time for
    any reason, including activity which, in our sole judgment: violates this
    Agreement; violates applicable laws, rules, or regulations; is abusive,
    disruptive, offensive or illegal; or violates the rights of, or harms or
    threatens the safety of, Users of the Platform.
    <span
      >You are responsible for any claims, fees, fines, penalties, and other
      liability incurred by us or others caused by, or arising out of, your
      breach of this Agreement and your use of the Platform.</span
    >
  </h1>

  <h1>
    <span>15. </span><b>Changes to the Platform or these Terms of Platform</b>.
  </h1>

  <h1>
    <span
      >We may change, suspend, or discontinue any aspect of the Platform at any
      time, including hours of operation or availability of the Platform or any
      feature, without notice or liability. SK AI may modify these Terms of
      Platform or any policy or other terms referenced herein at any time by
      posting a revised version of the Terms of Platform or such other policy or
      terms on the Platform. The revised terms shall be effective upon the
      earlier of (a) five calendar days after the revised terms are posted, and
      (b) your acceptance, including by any click-through confirmation,
      acceptance button, or similar mechanism for acceptance provided by SK AI
      directly or through an External Platform.</span
    >
  </h1>

  <h1><span>16. </span><b>Third-Party Interactions and Disputes</b>.</h1>

  <h2>
    a. <u>Background Checks</u>.
    <span
      >YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH THIRD PARTIES. YOU
      UNDERSTAND THAT WE CURRENTLY DO NOT CONDUCT CRIMINAL BACKGROUND CHECKS OR
      SCREENINGS ON MERCHANTS ON THE PLATFORM. WE ALSO DO NOT INQUIRE INTO THE
      BACKGROUNDS OF ALL OF OUR USERS OR ATTEMPT TO VERIFY THE STATEMENTS OF OUR
      USERS. WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF
      MERCHANTS ON THE PLATFORM. THE COMPANY RESERVES THE RIGHT TO CONDUCT ANY
      CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS AT ANY TIME USING AVAILABLE
      PUBLIC RECORDS.</span
    >
  </h2>

  <h2>
    b. <u><span>User and Third-Party Disputes</span></u
    ><span>. </span>We are not responsible for any disputes or disagreements
    between you and any third party you interact with using the Platform. This
    includes disputes between Merchants. <span>You assume all </span>risks<span>
      associated with dealing with third parties. </span
    >You agree to resolve any disputes directly with the other party.
    <span
      >You release SK AI of all claims, demands, and damages in disputes among
      Merchants. </span
    >You further agree not to involve us in such disputes.
    <span
      >Use caution and common sense when using the Platform. SK AI makes no
      representations or warranties as to the conduct of Merchants. IN NO EVENT
      WILL SK AI AND ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES,
      CONSULTANTS, AGENTS, PARTNERS, AND LICENSORS (COLLECTIVELY, THE "<b
        >RELEASED PARTIES</b
      >") BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT,
      GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING
      OUT OF, OR RELATING TO, THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION
      WITH THE USE OF THE SERVICE, INCLUDING WITHOUT LIMITATION, BODILY INJURY,
      EMOTIONAL DISTRESS, LOSS OF BUSINESS, LOST PROFITS, TRADE SECRET
      MISAPPROPRIATION, INTELLECTUAL PROPERTY INFRINGEMENT, AND/OR ANY OTHER
      DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER USERS OF THIS
      SERVICE OR PERSONS YOU MEET THROUGH THE SERVICE. YOU AGREE TO TAKE
      REASONABLE PRECAUTIONS IN ALL INTERACTIONS WITH OTHER MERCHANTS AND THIRD
      PARTIES. YOU ASSUME ALL RISK WHEN ENGAGING THE PLATFORM OF ANY OTHER
      MERCHANT OR THIRD PARTY AND IN CONNECTION WITH USING THE PLATFORM,
      INCLUDING BUT NOT LIMITED TO ANY RISKS ASSOCIATED WITH SHARING
      CONFIDENTIAL INFORMATION. ALL USERS OF THE PLATFORM HEREBY EXPRESSLY AGREE
      NOT TO HOLD THE RELEASED PARTIES LIABLE FOR ANY INSTRUCTION, ADVICE OR
      SERVICES DELIVERED THAT ORIGINATED THROUGH THE PLATFORM AND THE RELEASED
      PARTIES EXPRESSLY DISCLAIM ANY LIABILITY WHATSOEVER FOR ANY DAMAGE, SUITS,
      CLAIMS, AND/OR CONTROVERSIES THAT ARISE OR RELATED IN ANY WAY TO THE
      SERVICE, THE INFORMATION PROVIDED THROUGH THE PLATFORM AND THE SERVICES
      PROVIDED BY OR TO ANY USER OF THE PLATFORM. </span
    >ANY DISPUTE YOU HAVE WITH ANY MERCHANT, THIRD-PARTY SERVICE OR OTHER THIRD
    PARTY, INCLUDING, WITHOUT LIMITATION, ANY OTHER MERCHANT ON THE PLATFORM, IS
    DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE THE
    RELEASED PARTIES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND
    CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF
    OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
  </h2>

  <h1><span>17. </span><b>Termination</b>.</h1>

  <h1>
    This Agreement is effective until terminated by you or SK AI. Your rights
    under this Agreement will terminate automatically without notice from SK AI
    if you fail to comply with any term(s) of this Agreement (including by
    violating any license restriction provided herein). Upon any termination of
    this Agreement, you must immediately cease all use of the Platform and all
    amounts due to SK AI under this Agreement for access to the Platform prior
    to the effective date of termination shall become immediately due and
    payable.  There will be no refunds of any fees due hereunder as of the
    termination date of this Agreement.
  </h1>

  <h1><span>18. </span><b>No Warranty</b>.</h1>

  <h1>
    <span
      >YOU UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE PLATFORM IS AT YOUR
      SOLE RISK, AND THE PLATFORM, INCLUDING ALL SK AI CONTENT AND THIRD-PARTY
      DATA, IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND THE RELEASED
      PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
      IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO PRODUCTS OR
      PLATFORMS OFFERED BY BUSINESSES LISTED ON THE PLATFORM, IMPLIED WARRANTIES
      OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
      NON-INFRINGEMENT; (B) THE RELEASED PARTIES MAKE NO WARRANTY THAT (i) THE
      PLATFORM WILL MEET YOUR REQUIREMENTS OR ACHIEVE ANY SPECIFIC RESULT, (ii)
      THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii)
      ANY DATA, INCLUDING DATA SUPPLIED BY THIRD-PARTY DATA SOURCES, THAT MAY BE
      ACCESSED OR OBTAINED FROM THE USE OF THE PLATFORM WILL BE ACCURATE OR
      RELIABLE, (iv) THE QUALITY OF ANY INFORMATION, DATA OR OTHER MATERIAL
      AVAILABLE THROUGH THE PLATFORM WILL MEET YOUR EXPECTATIONS, (iv) ANY
      ERRORS IN THE PLATFORM WILL BE CORRECTED, OR (v) THE PLATFORM OR THE SK AI
      CONTENT ARE APPROPRIATE OR AVAILABLE FOR USE IN ANY SPECIFIC GEOGRAPHIC
      LOCATION; AND (C) ANY MATERIAL OBTAINED THROUGH THE USE OF THE PLATFORM IS
      ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
      RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR
      LOSS OF DATA THAT RESULTS FROM THE USE OF ANY SUCH MATERIAL. SOME
      JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES.
      ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY
      TO YOU.</span
    >
  </h1>

  <h1>
    <span
      >YOU HEREBY REPRESENT THAT YOU HAVE FULLY OBSERVED, AND WILL FULLY
      OBSERVE, THE LAWS OF YOUR JURISDICTION IN CONNECTION WITH YOUR USE OF THE
      PLATFORM AND THE RELEASED PARTIES WILL HAVE NO LIABILITY RESULTING FROM OR
      RELATED TO YOUR FAILURE TO OBSERVE SUCH LAWS.</span
    >
  </h1>

  <h1><span>19. </span><b>Limitation of Liability</b>.</h1>

  <h1>
    <span
      >YOU UNDERSTAND AND AGREE THAT THE RELEASED PARTIES WILL NOT BE LIABLE TO
      YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
      DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
      GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE RELEASED
      PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
      FROM: (i) THE USE OR THE INABILITY TO USE THE PLATFORM; (ii) THE COST OF
      PROCUREMENT OF SUBSTITUTE GOODS AND PLATFORMS RESULTING FROM ANY GOODS,
      DATA, INFORMATION OR PLATFORMS OBTAINED OR MESSAGES RECEIVED OR
      TRANSACTIONS ENTERED INTO THROUGH, FROM, OR AS A RESULT OF THE SITE; (iii)
      UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv)
      STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON THE PLATFORM; (v) YOUR
      RELIANCE ON CONTENT OR DATA MADE AVAILABLE BY US; OR (vi) ANY OTHER MATTER
      RELATING TO THE PLATFORM. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION
      OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
      ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY
      TO YOU.</span
    >
  </h1>

  <p>
    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SK AI ASSUMES NO
    LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
    CONTENT OR LOSS OF DATA OR CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE
    OF ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS TO OR USE OF THE
    PLATFORM; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF SK AI'S SECURE SERVERS
    AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM OR SK AI;
    (V) ANY BUGS, VIRUSES, TROJAN HORSES, MALWARE OR THE LIKE THAT MAY BE
    TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY; AND (VI) ANY
    ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A
    RESULT OF THE USE OF ANY CONTENT PROVIDED, EMAILED, TRANSMITTED, OR
    OTHERWISE MADE AVAILABLE THROUGH THE PLATFORM.
  </p>

  <p>
    <span
      >TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE RELEASED PARTIES'
      MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM OR
      YOUR USE OF SK AI CONTENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN
      CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED THE
      GREATER OF THE FEES YOU HAVE PAID TO SK AI UNDER THIS AGREEMENT IN THE 12
      MONTHS PRIOR TO THE DATE OF THE EVENT GIVING RISE TO THE APPLICABLE CAUSE
      OF ACTION AND USD $100.</span
    >
  </p>

  <p>
    <span
      >THE LIMITATIONS OF LIABILITY DESCRIBED ABOVE SHALL APPLY FULLY TO
      RESIDENTS OF NEW JERSEY.</span
    >
  </p>

  <h1><span>20. </span><b>Third-Party Elements</b>.</h1>

  <p>
    <span
      >YOU ACKNOWLEDGE THAT (A) THE PLATFORM MAY CONTAIN SOFTWARE OR COMPONENTS
      THAT ARE EITHER OWNED BY A THIRD PARTY OR IN THE PUBLIC DOMAIN, AND (B) SK
      AI HAS NO PROPRIETARY INTEREST IN SUCH SOFTWARE OR COMPONENTS, AND AS
      SUCH, CANNOT GRANT YOU A LICENSE TO USE SUCH SOFTWARE AND/OR COMPONENTS.
      SK AI WILL PROVIDE, OR OTHERWISE MAKE AVAILABLE, TO YOU ANY NECESSARY
      SUBLICENSES FROM SUCH THIRD PARTY AND USERS SHALL HAVE THE RIGHTS LICENSED
      TO THEM BY THE OWNERS OF THE THIRD-PARTY SOFTWARE AND/OR COMPONENTS. A
      LISTING OF SUCH THIRD-PARTY SOFTWARE IS MADE AVAILABLE TO YOU IN THE
      PLATFORM DOCUMENTATION OR OTHERWISE UPON WRITTEN REQUEST. BY INSTALLING OR
      USING SUCH THIRD-PARTY SOFTWARE OR COMPONENTS, YOU ACKNOWLEDGE AND AGREE
      TO FULLY COMPLY WITH THE TERMS AND CONDITIONS SET FORTH IN THE APPLICABLE
      LICENSES FOR SUCH THIRD-PARTY SOFTWARE OR COMPONENTS. SK AI AND ITS
      LICENSORS MAKE NO WARRANTIES OR REPRESENTATIONS AS TO SUCH THIRD-PARTY
      SOFTWARE OR COMPONENTS, INCLUDING QUIET ENJOYMENT AND INFRINGEMENT, AND
      FURTHER DISCLAIM ALL LIABILITY FOR ANY LOSSES OR DAMAGES THAT MAY RESULT
      FROM YOUR USE OR DISTRIBUTION THEREOF (INCLUDING INFRINGEMENT
      INDEMNIFICATION). SK AI IS NOT OBLIGATED TO PROVIDE SUPPORT PLATFORMS FOR
      ANY SUCH THIRD-PARTY SOFTWARE OR COMPONENTS UNLESS EXPRESSLY AGREED TO IN
      WRITING BY SK AI UNDER A SEPARATE AGREEMENT. YOU FURTHER AGREE TO
      INDEMNIFY, HOLD HARMLESS AND DEFEND SK AI AND ITS LICENSORS FROM AND
      AGAINST ANY CLAIMS OR LAWSUITS, INCLUDING ATTORNEYS' FEES, THAT ARISE OR
      RESULT FROM YOUR USE OR DISTRIBUTION OF SUCH THIRD-PARTY SOFTWARE.</span
    >
  </p>

  <h1><span>21. </span><b>Indemnity</b>.</h1>

  <p>
    <span
      >TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO
      INDEMNIFY, DEFEND, AND HOLD SK AI AND THE RELEASED PARTIES HARMLESS FROM
      AND AGAINST ANY CLAIM OR DEMAND, INCLUDING WITHOUT LIMITATION, REASONABLE
      LAWYERS' FEES, COSTS AND DISBURSEMENTS, MADE BY ANY THIRD PARTY IN
      CONNECTION WITH OR ARISING OUT OF YOUR USE OF THE PLATFORM, YOUR
      CONNECTION TO THE PLATFORM, YOUR USE OR RELIANCE ON ANY SK AI CONTENT,
      YOUR VIOLATION OF THIS AGREEMENT OR THE PRIVACY POLICY, YOUR VIOLATION OF
      APPLICABLE LAW, YOUR SUBMISSION, POSTING, OR TRANSMISSION OF MERCHANT DATA
      TO THE PLATFORM, ANY REPRESENTATION BY YOU REGARDING THE ACCURACY OR
      RELIABILITY OF ANY MERCHANT DATA OR SK AI CONTENT, AND/OR YOUR VIOLATION
      OF ANY RIGHTS OF ANOTHER. SK AI RESERVES THE RIGHT, AT ITS OWN EXPENSE, TO
      ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF SUCH DISPUTES, AS APPROPRIATE,
      AND IN ANY EVENT YOU WILL COOPERATE WITH US IN ASSERTING ANY AVAILABLE
      DEFENSES.</span
    >
  </p>

  <p>
    <span
      >If you are a California resident, you hereby waive California Civil Code
      §1542, which says:
      <b
        >"<span style="font-variant: small-caps"
          >A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
          RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR
          AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER,
          WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR
          OR RELEASED PARTY.</span
        >"</b
      >
      This release includes the criminal acts of others. If you are not a
      California resident, you waive your rights under any statute or common law
      principle similar to Section 1542 that governs your rights in the
      jurisdiction of your residence.</span
    >
  </p>

  <h1><span>22. </span><b>Notice of Claims of Copyright Infringement</b>.</h1>

  <p>
    <span
      >SK AI respects the intellectual property rights of others and requires
      users of the Platform to do the same. In accordance with the Digital
      Millennium Copyright Act, 17 U.S.C. § 512 (the "<b>DMCA</b>") and other
      applicable law, SK AI </span
    >will respond expeditiously to claims of copyright infringement committed
    using the Platform if such claims are reported to SK AI's Designated
    Copyright Agent identified in the notice below.
  </p>

  <p>
    If you are a copyright owner, authorized to act on behalf of a copyright
    owner, or authorized to act under any exclusive right under copyright,
    please report any alleged copyright infringements taking place on or through
    the Platform by completing the following DMCA Notice of Alleged Infringement
    and delivering the Notice to SK AI's Designated Copyright Agent. Upon
    receipt of a DMCA Notice of Alleged Infringement as described below, SK AI
    will take whatever action, in its sole discretion, that SK AI deems
    appropriate, which may include the removal of, or disabling of access to,
    the challenged material from the Platform.
  </p>

  <table class="2" border="1" cellspacing="0" cellpadding="0" width="623">
    <tr>
      <td
        width="623"
        valign="top"
        style="border: solid black 1pt; padding: 0in 5.4pt 0in 5.4pt"
      >
        <p align="center">
          <span>DMCA Notice of Alleged Infringement ("<u>Notice</u>")</span>
        </p>
        <ol start="1" type="1">
          <li style="margin-bottom: 17px">
            Identify the copyrighted work that you claim has been infringed, or,
            if multiple copyrighted works are covered by this Notice, you may
            provide a representative list of the copyrighted works that you
            claim have been infringed.
          </li>
          <li style="margin-bottom: 17px">
            Identify the material or link you claim is infringing (or the
            subject of infringing activity) and that is to be removed or to
            which access is to be disabled, including at a minimum, if
            applicable, the URL or the link shown on the Platform or the exact
            location where such material may be found.
          </li>
          <li style="margin-bottom: 17px">
            Provide your name, company affiliation (if applicable), mailing
            address, telephone number, and, if available, email address.
          </li>
          <li style="margin-bottom: 17px">
            Include both of the following statements in the body of the Notice:
          </li>
        </ol>
        <p style="margin-left: 80px; text-indent: -0.25in">
          <span
            >-<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span
            >"I hereby state that I have a good faith belief that the disputed
            use of the copyrighted material is not authorized by the copyright
            owner, its agent, or the law (e.g., as a fair use)."</span
          >
        </p>
        <p style="margin-left: 80px; text-indent: -0.25in">
          <span
            >-<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span
            >"I hereby state that the information in this Notice is accurate
            and, under penalty of perjury, that I am the owner, or authorized to
            act on behalf of the owner, of the copyright or of an exclusive
            right under the copyright that is allegedly infringed."</span
          >
        </p>
        <ol style="margin-top: 0in" start="5" type="1">
          <li style="margin-bottom: 17px">
            Provide your full legal name and your electronic or physical
            signature.
          </li>
        </ol>
        <p style="margin-bottom: 17px">
          <span
            >Deliver this Notice, with all items completed, to SK AI's
            Designated Copyright Agent:</span
          >
        </p>
        <p><span>SK AI, Inc.</span></p>
        <p style="margin-top: 0in">
          <span>Attn: <span>Copyright Agent</span></span>
        </p>
        <p style="margin-top: 0in">
          <span>3600 136<sup>th</sup> PL SE, #300, Bellevue, WA  98007</span>
        </p>
        <p style="margin-bottom: 17px"><span>or</span></p>
        <p style="margin-bottom: 17px">
          <span>Email:  </span
          ><span
            ><a href="mailto:cs@qubriux.com"
              ><span style="color: blue">cs@qubriux.com</span></a
            ><span>
              (with the subject line "DMCA Notice of Alleged
              Infringement")</span
            ></span
          >
        </p>
      </td>
    </tr>
  </table>

  <p style="margin-bottom: 17px">
    <span
      >In the event SK AI, after receiving a proper DMCA Notice of Alleged
      Infringement, takes steps to remove or disable access to the challenged
      material, SK AI will take reasonable steps to promptly notify the party
      who originally posted such challenged materi</span
    >al to the Platform (the "<b>Posting Party</b>") that SK AI has removed or
    disabled access to such challenged material. Provided the challenged
    material does not otherwise violate this Agreement, the Posting Party may
    then dispute such DMCA Notice of Alleged Infringement by completing the
    following DMCA Counter-Notice and delivering the Counter-Notice to SK AI's
    Designated Copyright Agent. Upon receipt of a proper DMCA Counter-Notice, SK
    AI will promptly provide the party who submitted the relevant DMCA Notice of
    Alleged Infringement (the "<b>Reporting Party</b>") with a copy of such DMCA
    Counter-Notice and inform the Reporting Party that SK AI will replace the
    removed material or cease disabling access to such material in 10 business
    days, and will thereafter replace the removed material and cease disabling
    access to such material not less than 10, nor more than 14, business days
    following receipt of such DMCA Counter-Notice, unless SK AI's Copyright
    Agent first receives notice from the Reporting Party that such Reporting
    Party has filed an action seeking a court order to restrain the Posting
    Party from engaging in infringing activity relating to the challenged
    material on the Platform.
  </p>

  <table
    class="1"
    border="1"
    cellspacing="0"
    cellpadding="0"
    width="623"
    style="border-collapse: collapse; border: none"
  >
    <tr>
      <td
        width="623"
        valign="top"
        style="
          width: 467.5pt;
          border: solid black 1pt;
          padding: 0in 5.4pt 0in 5.4pt;
        "
      >
        <p align="center" style="margin-bottom: 17px; text-align: center">
          <span>DMCA Counter-Notice ("<u>Counter-Notice</u>")</span>
        </p>
        <ol style="margin-top: 0in" start="1" type="1">
          <li style="margin-bottom: 17px">
            Identify the material or link that has been removed or to which
            access has been disabled, including at a minimum, if applicable, the
            URL or the link shown on the Platform or the exact location where
            such material appeared before it was removed or access to it was
            disabled.
          </li>
          <li style="margin-bottom: 17px">
            In connection with such material, include the following statement in
            the body of the Counter-Notice:
          </li>
        </ol>
        <p style="margin-left: 80px; text-indent: -0.25in">
          <span>-</span
          ><span
            >"I hereby state, under penalty of perjury, that I have a good faith
            belief that the material was removed or disabled as a result of
            mistake or misidentification of the material to be removed or
            disabled."</span
          >
        </p>
        <ol start="3" type="1">
          <li style="margin-bottom: 17px">
            Provide your name, address and telephone number.
          </li>
          <li style="margin-bottom: 17px">
            If your address is located in the United States, include the
            following statement in the body of the Counter-Notice:
          </li>
        </ol>
        <p style="margin-left: 80px; text-indent: -0.25in">
          <span>-</span
          ><span
            >"I hereby state that I consent to the jurisdiction of the Federal
            District Court for the judicial district in which my address is
            located, and that I will accept service of process from the person
            who provided notification in connection with the material described
            in this counter-notice under 17 U.S. Code § 512(c)(1)(C) or an agent
            of such person."</span
          >
        </p>
        <ol start="5" type="1">
          <li style="margin-bottom: 17px">
            If your address is located outside of the United States, include the
            following statement in the body of the Counter-Notice:
          </li>
        </ol>
        <p style="margin-left: 80px; text-indent: -0.25in">
          <span>-</span
          ><span
            >"I hereby state that I consent to the jurisdiction of the Federal
            District Court for any judicial district in which SK AI, Inc. can be
            found, and that I will accept service of process from the person who
            provided notification in connection with the material described in
            this counter-notice under 17 U.S. Code § 512(c)(1)(C) or an agent of
            such person."</span
          >
        </p>
        <ol start="6" type="1">
          <li style="margin-bottom: 17px">
            Provide your full legal name and your electronic or physical
            signature.
          </li>
        </ol>
        <p style="margin-bottom: 17px">
          <span
            >Deliver this Counter-Notice, with all items completed, to SK AI's
            Designated Copyright Agent:</span
          >
        </p>
        <p><span>SK AI, Inc.</span></p>
        <p>
          <span>3600 136<sup>th</sup> PL SE, #300, Bellevue, WA  98007</span>
        </p>
        <p style="margin-bottom: 17px">
          <span
            >Email: 
            <a href="mailto:cs@qubriux.com"
              ><span style="color: blue">cs@qubriux.com</span></a
            ></span
          >
          (with<span> the subject line "DMCA Counter-Notice")</span>
        </p>
      </td>
    </tr>
  </table>

  <p style="margin-top: 20px">
    <span
      >Please note that pursuant to Section 512(f) of the DMCA, any person who
      knowingly materially misrepresents that material or activity is
      infringing, or that the material or activity was removed or disabled by
      mistake may be subject to liability. Please also be advised that SK AI
      enforces a policy of terminating the accounts of, or banning, repeat
      infringers. A repeat infringer includes any user who has made two or more
      postings that SK AI has removed or disabled access to pursuant to notices
      of infringement under these DMCA notice and takedown procedures.</span
    >
  </p>

  <p>
    <span
      >Please further note that the above contact information is for
      intellectual property infringement notices only. DO NOT CONTACT SK AI'S
      COPYRIGHT AGENT FOR OTHER INQUIRIES OR QUESTIONS.</span
    >
  </p>

  <h1><span>23. </span><b>Arbitration and Class Action Waiver</b>.</h1>

  <p style="margin-top: 20px">
    <b
      ><span
        >PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS,
        INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.</span
      ></b
    >
  </p>

  <p>
    <span
      >You and SK AI agree that the Platform and this Agreement affect
      interstate commerce and that the Federal Arbitration Act governs the
      interpretation and enforcement of these arbitration provisions. This
      section is intended to be interpreted broadly and governs any and all
      disputes between you and SK AI, including but not limited to claims
      arising out of or relating to any aspect of the relationship between you
      and SK AI, whether based in contract, tort, statute, fraud,
      misrepresentation or any other legal theory; claims that arose before this
      Agreement or any prior agreement (including, but not limited to, claims
      related to your use of the Platform); and claims that may arise after the
      termination of this Agreement or agreement to arbitrate. The only disputes
      excluded from this broad prohibition are the litigation of certain
      intellectual property and small court claims, as provided below.</span
    >
  </p>

  <p>
    <span
      >By agreeing to these Terms, you agree to resolve any and all disputes
      with SK AI as follows:</span
    >
  </p>

  <h2>
    <span>a. </span><u><span>Initial Dispute Resolution</span></u
    ><span
      >. Most disputes can be resolved without resort to litigation. You can
      reach SK AI's support department at </span
    ><a href="mailto:cs@qubriux.com"
      ><span style="color: blue">cs@qubriux.com</span></a
    >.<span>
      Except for intellectual property and small claims court claims, the
      parties agree to use their best efforts to settle any dispute, claim,
      question, or disagreement directly through consultation with SK AI's
      support department, and good faith negotiations shall be a condition to
      either party initiating a lawsuit or arbitration.</span
    >
  </h2>

  <h2>
    <span>b. </span><u><span>Binding Arbitration</span></u
    ><span
      >. If the parties do not reach an agreed-upon solution within a period of
      thirty (30) days from the time informal dispute resolution is initiated
      under the Initial Dispute Resolution provision above, then either party
      may initiate binding arbitration as the sole means to resolve claims,
      subject to the terms set forth below. Specifically, all claims arising out
      of or relating to this Agreement (including the formation of this
      Agreement or </span
    >Privacy Policy<span
      >, performance, and breach), the parties' relationship with each other,
      and/or your use of the Platform shall be finally settled by binding
      arbitration administered by JAMS in accordance with the JAMS Streamlined
      Arbitration Procedure Rules for claims that do not exceed $250,000 and the
      JAMS Comprehensive Arbitration Rules and Procedures for claims exceeding
      $250,000 in effect at the time the arbitration is initiated, excluding any
      rules or procedures governing or permitting class actions. The arbitrator,
      and not any federal, state, or local court or agency, shall have exclusive
      authority to resolve all disputes arising out of or relating to the
      interpretation, applicability, enforceability, or formation of this
      Agreement or the </span
    >Privacy Policy<span
      >, including but not limited to any claim that all or any part of this
      Agreement or </span
    >Privacy Policy<span>
      is void or voidable, whether a claim is subject to arbitration, or the
      question of waiver by litigation conduct. The arbitrator shall be
      empowered to grant whatever relief would be available in a court under law
      or in equity. The arbitrator's award shall be written and shall be binding
      on the parties and may be entered as a judgment in any court of competent
      jurisdiction. To start an arbitration, you must do the following: (a)
      write a Demand for Arbitration that includes a description of the claim
      and the amount of damages you seek to recover (you may find a copy of a
      Demand for Arbitration at </span
    ><a href="https://www.jamsadr.com/adr-forms/"
      ><span style="color: blue">https://www.jamsadr.com/adr-forms/</span></a
    ><span
      >); (b) send two copies of the Demand for Arbitration, plus the
      appropriate filing fee, to your local JAMS Resolution Center (see</span
    ><span> </span
    ><a href="http://www.jamsadr.com/locations/"
      ><span style="color: blue">http://www.jamsadr.com/locations/</span></a
    ><span> </span
    ><span
      >for locations); and (c) send one copy of the Demand for Arbitration to SK
      AI, Inc., 3600 136<sup>th</sup> PL SE, #300, Bellevue, WA 98007,
      USA.</span
    >
  </h2>

  <p>
    <span
      >You will be required to pay $250 to initiate an arbitration against us.
      If the arbitrator finds the arbitration to be non-frivolous, SK AI will
      pay all other fees invoiced by JAMS, including filing fees and arbitrator
      and hearing expenses. You are responsible for your own attorneys' fees
      unless the arbitration rules and/or applicable law provide
      otherwise.</span
    >
  </p>

  <p>
    <span
      >The parties understand that, absent this mandatory arbitration provision,
      they would have the right to sue in court and have a jury trial. They
      further understand that, in some instances, the costs of arbitration could
      exceed the costs of litigation and the right to discovery may be more
      limited in arbitration than in court. If you are a resident of the United
      States, arbitration may take place in the county where you reside at the
      time of filing. For individuals residing outside the United States,
      arbitration shall be initiated in the State of Washington, United States
      of America. You and SK AI further agree to submit to the personal
      jurisdiction of any federal or state court in King County, Washington in
      order to compel arbitration, to stay proceedings pending arbitration, or
      to confirm, modify, vacate, or enter judgment on the award entered by the
      arbitrator.</span
    >
  </p>

  <h2>
    <span>c. </span><u><span>Class Action Waiver</span></u
    ><span
      >. The parties further agree that the arbitration shall be conducted in
      their individual capacities only and not as a class action or other
      representative action, and the parties expressly waive their right to file
      a class action or seek relief on a class basis.&nbsp;<b
        >YOU AND SK AI AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
        IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
        MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</b
      >
      If any court or arbitrator determines that the class action waiver set
      forth in this paragraph is void or unenforceable for any reason or that an
      arbitration can proceed on a class basis, then the arbitration provisions
      set forth above shall be deemed null and void in their entirety and the
      parties shall be deemed to have not agreed to arbitrate disputes.</span
    >
  </h2>

  <h2>
    <span>d. </span
    ><u
      ><span
        >Exception: Litigation of Intellectual Property Rights and Small Claims
        Court Claims</span
      ></u
    ><span
      >. Notwithstanding the parties' decision to resolve all disputes through
      arbitration, either party may bring enforcement actions, validity
      determinations or claims arising from or relating to theft, piracy or
      unauthorized use of intellectual property in state or federal court or in
      the U.S. Patent and Trademark Office to protect its Intellectual Property
      Rights. Either party may also seek relief in a small claims court for any
      disputes or claims within the scope of that court's jurisdiction.</span
    >
  </h2>

  <h2>
    <span>e. </span><u><span>30-Day Right to Opt Out</span></u
    ><span
      >. You have the right to opt out and not be bound by the arbitration and
      class action waiver provisions set forth above by sending (from the email
      address you used to register for your account) written notice of your
      decision to opt out to </span
    ><a href="mailto:cs@qubriux.com"
      ><span style="color: blue">cs@qubriux.com</span></a
    >
    <span
      >with the subject line, ARBITRATION AND CLASS ACTION WAIVER OPT-OUT." The
      notice must be sent within thirty (30) days of your first use of the
      Platform or the effective date of the first Agreement containing an
      Arbitration and Class Action Waiver section, whichever is later;
      otherwise, you shall be bound to arbitrate disputes in accordance with the
      terms of these paragraphs. If you opt out of these arbitration provisions,
      SK AI also will not be bound by them.</span
    >
  </h2>

  <h2>
    <span>f. </span><u><span>Changes to This Section</span></u
    ><span
      >. SK AI will provide thirty (30) days' notice of any changes to this
      section by posting on the Platform, sending you a message, or otherwise
      notifying you when you are logged into your account. Such changes will
      become effective thirty (30) days after they are posted on the Platform or
      sent to you.</span
    >
  </h2>

  <h2>
    <span>a. </span
    ><span
      >Changes to this section will otherwise apply prospectively only to claims
      arising after the thirtieth (30th) day. If a court or arbitrator decides
      that this subsection on "Changes to This Section" is not enforceable or
      valid, then this subsection shall be severed from the section entitled
      "Arbitration and Class Action Waiver," and the court or arbitrator shall
      apply the first Arbitration and Class Action Waiver section in existence
      after you began using the Platform.</span
    >
  </h2>

  <h2>
    <span>g. </span><u><span>Survival</span></u
    ><span
      >. This Arbitration and Class Action Waiver section shall survive any
      termination of your account, the Platform, or of this Agreement.</span
    >
  </h2>

  <h1>
    <span>2. </span><b><span>Governing Law and Jurisdiction</span></b>
  </h1>

  <p>
    <span
      >By visiting or using the Platform, you agree that the laws of the United
      States of America and the laws of the State of Delaware, without regard to
      the principles of conflicts of laws, will govern your use of the Platform,
      and this Agreement and all matters relating to your access to and/or use
      of the Platform, including all disputes between you and us and/or the
      Released Parties. For disputes not subject to the Arbitration and Class
      Action Waiver section above, or if any court or arbitrator determines that
      the Arbitration and Class Action Waiver section above is void or
      unenforceable for any reason or that an arbitration can proceed on a class
      basis, you and SK AI irrevocably submit to the exclusive jurisdiction of,
      and venue in, the state and federal courts seated in King County,
      Washington, and the related appellate courts, in any related action or
      proceeding. You hereby waive any and all jurisdictional and venue defenses
      otherwise available. YOU AGREE THAT ANY COURT ACTION ARISING OUT OF OR
      RELATED TO YOUR USE OF THE SERVICE, THIS AGREEMENT AND ALL MATTERS
      RELATING TO YOUR ACCESS TO AND/OR USE OF THE SERVICE MUST BE COMMENCED BY
      YOU WITHIN ONE (1) YEAR AFTER THE CAUSE FOR SUCH ACTION ACCRUES;
      OTHERWISE, SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.</span
    >
  </p>

  <h1>
    <span>3. </span><b><span>Miscellaneous</span></b
    ><span>.</span>
  </h1>

  <h2>
    a. <u>Restricted Rights</u>. If the Platform is deemed to be licensed to the
    United States government or any agency thereof, the Platform, including any
    related technical data or accompanying documentation, are "commercial items"
    as defined in 48 C.F.R. §2.101, and include "commercial computer software"
    and "commercial computer software documentation" as such terms are used in
    48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. The use,
    duplication, reproduction, release, modification, disclosure or transfer of
    the Platform and any technical data or documentation relating thereto or
    derived therefrom, is restricted in accordance with 48 C.F.R. §12.211, 48
    C.F.R. §12.212, 48 C.F.R. §227.7102-1, 48 C.F.R. §227.7102-2, and 48 C.F.R.
    §227.7202-1 through §227.7202-4, as applicable. The commercial items,
    commercial computer software and commercial computer software documentation
    are being licensed to U.S. Government end users (i) only as commercial items
    and (ii) with only those rights as are granted to all other end users
    pursuant to the terms and conditions herein. This is in lieu of, and
    supersedes, any other Federal Acquisition Regulation ("<b>FAR</b>"), the
    Defense FAR Supplement, or other agency supplemental clause or provision
    that address Government rights in computer software or technical data. Any
    use, reproduction, release, performance, display or disclosure of the
    Platform and/or any related technical data or accompanying documentation by
    or for the U.S. Government will be governed solely by this Agreement, to the
    extent permitted by law.
  </h2>

  <h2>
    b. <u>Export Compliance</u>. The Platform and technical information of SK AI
    provided under this Agreement may be subject to U.S. export and import
    control laws and the trade laws of other countries. You agree to comply with
    all export and import control laws and to obtain any required licenses or
    classification to export, re-export or import the Platform and any technical
    information provided by SK AI. You agree not to export or re-export to
    entities on the current U.S. export exclusion lists or to any embargoed or
    terrorist countries as specified in the U.S. export laws or control laws of
    other countries. You will not use the Platform for prohibited nuclear,
    missile, or chemical biological weaponry end uses. SK AI assumes no
    responsibility for your failure to obtain any necessary export approvals or
    for your violation of any export or import control laws.
  </h2>

  <h2>
    c. <u>Force Majeure</u>. SK AI will not be liable under this Agreement by
    reason of any failure or delay in the performance of its obligations
    hereunder on account of strike, riot, insurrection, fire, flood, storm, acts
    of god, war, governmental action, labor conditions, earthquakes, or any
    other cause that is beyond the reasonable control of SK AI.
  </h2>

  <h2>
    d. <u>Assignment</u>. You may not assign this Agreement or any of the rights
    or licenses granted hereunder, directly or indirectly, including by sale,
    merger, change of control, operation of law or otherwise, without the prior
    written consent of SK AI. SK AI may assign this Agreement, including all its
    rights hereunder, without restriction. This Agreement shall be binding on
    and inure to the benefit of the parties hereto and their respective
    successors and permitted assigns.
  </h2>

  <h2>
    e. <u>Survival</u>. The provisions of this Agreement that are intended to
    survive the termination of this Agreement by their nature will survive the
    termination of this Agreement, including, but not limited to,<span
      style="color: #d23438"
    >
    </span
    ><span
      >Sections ‎2 (General), ‎6 (Fees; Payments), ‎8 (External Platforms and
      Third-Party Terms), ‎9 (Your Use of the Platform and SK AI Content), ‎10
      (Ownership), ‎11 (Data Security), ‎12 (Confidentiality), ‎14 (Consequences
      of Violating These Terms of Platform), ‎15 (Changes to the Platform or
      These Terms of Platform), ‎16 (Third-Party Interactions and Disputes), ‎17
      (Termination), ‎18 (No Warranty), ‎19 (Limitation of Liability), ‎20
      (Third-Party Elements), ‎21 (Indemnity), ‎22 (Notice of Claims of
      Copyright Infringement), ‎23 (Arbitration and Class Action Waiver), ‎24
      (Governing Law and Jurisdiction), and ‎25<b> </b>(Miscellaneous).</span
    >
  </h2>

  <h2>
    f. <u>Consent to Electronic Communications</u>. By using the Platform, you
    consent to receiving certain electronic communications from us as further
    described in our Privacy Policy. Please read our Privacy Policy to learn
    more about your choices regarding our electronic communications practices.
    You agree that any notices, agreements, disclosures, or other communications
    that we send to you electronically will satisfy any legal communication
    requirements, including that such communications be in writing.
  </h2>

  <h2>
    g. <u>Entire Agreement</u>. This Agreement,<b> </b>together with the
    Subscription Plan, Privacy Policy and any other agreements expressly
    incorporated by reference herein, constitute the entire and exclusive
    understanding and agreement between you and SK AI regarding your use of and
    access to the Platform, and, except as expressly permitted above, may be
    amended only by a written agreement signed by authorized representatives of
    all parties to this Agreement.
  </h2>

  <h2>
    h. <u>Waiver</u>. The failure to require performance of any provision will
    not affect our right to require performance at any time thereafter, nor will
    a waiver of any breach or default of this Agreement or any provision of this
    Agreement constitute a waiver of any subsequent breach or default or a
    waiver of the provision itself.
  </h2>

  <h2>
    i. <u>Headings</u>. Use of section headers in this Agreement is for
    convenience only and will not have any impact on the interpretation of
    particular provisions.
  </h2>

  <h2>
    j. <u>Severability</u>. In the event that any part of this Agreement is held
    to be invalid or unenforceable, the unenforceable part shall be given effect
    to the greatest extent possible and the remaining parts will remain in full
    force and effect.
  </h2>

  <h2>
    k. <u>Relationship</u>. You agree that no joint venture, partnership,
    employment, or agency relationship exists between you and SK AI as a result
    of this Agreement or use of the Platform.
  </h2>

  <h2>
    l. <u>Contacting SK AI</u>. You can contact SK AI, Inc. by email at
    <a href="mailto:cs@qubriux.com"
      ><span style="color: blue">cs@qubriux.com</span></a
    >, or by U.S. Post at <a name="bookmark=id.2bn6wsx"></a
    ><span>3600 136<sup>th</sup> PL SE, #300, Bellevue, WA  98007.</span>
  </h2>
</div>
