import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NameAndDescriptionComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-firsttime-game",
  templateUrl: "./firsttime-game.component.html",
  styleUrls: ["./firsttime-game.component.css"],
})
export class FirsttimeGameComponent implements OnInit {
  constructor(private _router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private _dialog:MatDialog) {}

  ngOnInit() {}

  createNewGame() {
    const dialogRef = this._dialog.open(
      NameAndDescriptionComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "30%",
        data: {
          title: this._i18nDynamicTranslate.transform("GAME DETAILS", ['POS_PAGE']),
          field1Name: this._i18nDynamicTranslate.transform("Game Name", ['POS_PAGE']),
          field2Name: this._i18nDynamicTranslate.transform("Game Description (Optional)", ['POS_PAGE']),
          cancelButtonName: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          submitButtonName: this._i18nDynamicTranslate.transform("Continue", ['POS_PAGE']),
          action:'Gamify'
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(["/app/gamify/create-game"], {
          queryParams: { id: result },
        });
      }
    });



  }
}
