<div class="sms-creator-container">
    <div class="header">
      <div>
        <span class="header-text">{{'SHARED_PAGE.NEW_WHATSAPP_TEMPLATE' | i18nTranslate}}</span>
      </div>
      <div class="close-icon-div" (click)="dialogRef.close(false)">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.22222 8L0 1.77778L1.77778 0L8 6.22222L14.2222 0L16 1.77778L9.77778 8L16 14.2222L14.2222 16L8 9.77778L1.77778 16L0 14.2222L6.22222 8Z" fill="#202224"/>
          </svg>          
      </div>
    </div>
    <div class="top-container">
      <div class="text-box">
        <div style="margin-bottom: 10px">
          <span class="input-header-text">{{'SHARED_PAGE.TEMPLATE_NAME' | i18nTranslate}}</span>
          <div
            class="formDiv"
            [ngClass]="{ error: (nameError && submit) || (templateName.length === 0 && submit)}"
          >
            <input
              (keypress)="templateNameKeyPress($event)"
              (paste)="templateNamePaste($event)"
              class="formInput"
              type="text"
              [(ngModel)]="templateName"
            />
            <span class="template-name-text-limiter"
              >{{ templateName.length
              }}<span style="color: #cccccc">/50</span></span
            >
          </div>
        </div>
        <div class="qb-d-flex qb-jc-space-between">
          <div class="qb-d-flex qb-fd-coloumn">
            <div class="input-header-text">
              {{'SHARED_PAGE.CATEGORY' | i18nTranslate}}
            </div>
            <div class="qb-home-whatsapp-selector qb-whatsapp-page-selector-div" (click)="openSelectCategory()" [ngClass]="{ error: (!selectedCategory && submit)}">
              <div class="qb-select-div-2 qb-whatsapp-page-selector-container-2">
                <mat-select
                  #selectCategory
                  class="qb-graph-select qb-of-auto"
                  [(ngModel)]="selectedCategory"
                  (selectionChange)="changeCategory()"
                  [value]="selectedCategory"
                  placeholder="Select Category"
                >
                <mat-option value="MARKETING">
                  {{'SHARED_PAGE.MARKETING' | i18nTranslate}}
                </mat-option>
                <mat-option value="UTILITY">
                  {{'SHARED_PAGE.UTILITY' | i18nTranslate}}
                </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <div class="qb-d-flex qb-fd-coloumn">
            <div class="input-header-text">
              {{'SHARED_PAGE.TEMPLATE_TYPE' | i18nTranslate}}
            </div>
            <div class="qb-home-whatsapp-selector qb-whatsapp-page-selector-div" (click)="openSelectTemplateType()">
              <div class="qb-select-div-2 qb-whatsapp-page-selector-container-2">
                <mat-select
                  #selectTemplateType
                  class="qb-graph-select qb-of-auto"
                  [(ngModel)]="selectedTemplateType"
                  (selectionChange)="changeTemplateType()"
                  [value]="selectedTemplateType"
                  [placeholder]="selectedTemplateType"
                >
                  <mat-option
                  value="standard"
      
                  >{{'SHARED_PAGE.STANDARD_TEXT_ONLY' | i18nTranslate}}</mat-option
                >
                <mat-option value="media">
                  {{'SHARED_PAGE.MEDIA_INTERACTIVE' | i18nTranslate}}
                </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTemplateType === 'media'">
          <div class="qb-d-flex qb-jc-space-between">
            <span class="input-header-text">Header</span>
            <div>
              <div [matMenuTriggerFor]="menuDraft" style="text-transform: capitalize;" class="border cursor-pointer">
                <div class="qb-d-flex qb-jc-space-between" style="padding: 4px; width: 80%;">
                  <div>
                    {{dataType}}
                  </div>
                  <span>
                    <mat-icon class="keyboard-down-icon" style="color: black; margin-left: 5px;"
                    >keyboard_arrow_down</mat-icon
                  >
                  </span>
                </div>
              </div>
              <mat-menu #menuDraft class="qb-channel-dialog">
                <button
                mat-menu-item
                class="qb-channel-dialog-content"
                (click)="changeHeaderType('text')"
              >
                <span>Text</span>
              </button>
                <button
                  mat-menu-item
                  class="qb-channel-dialog-content"
                  (click)="changeHeaderType('image')"
                >
                  <span>Image</span>
                </button>
                <button
                  mat-menu-item
                  class="qb-channel-dialog-content"
                  (click)="changeHeaderType('video')"
                >
                  <span>Video</span>
                </button>
                <button
                  mat-menu-item
                  class="qb-channel-dialog-content"
                  (click)="changeHeaderType('document')"
                >
                  <span>Document</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="text-container" *ngIf="dataType === 'text'">
            <textarea
              class="textarea"
              name="whatsappHeaderText"
              id="whatsappHeaderText"
              cols="20"
              rows="1"
              maxlength="60"
              [(ngModel)]="whatsappHeaderText"
              [ngClass]="{ error: whatsappHeaderText.length === 0 && submit }"
              (focusout)="updateMeasurementPointCommentHeader($event)"
            ></textarea>
            <!-- <span class="sms-count-text"
              >Count : <span>{{ smsCount }}</span></span
            > -->
            <span class="char-limit-text"
              ><span style="color: #00b759">{{ whatsappHeaderText.length }}</span
              >/60</span
            >
            <!-- <div
              class="smiley-container"
              (click)="toggled = !toggled"
              [(emojiPickerIf)]="toggled"
              [emojiPickerDirection]="'left'"
              (emojiPickerSelect)="handleSelection($event)"
              >😄</div> -->
          </div>
          <div class="text-container" *ngIf="dataType === 'image'">
            <div class="textarea" style="height: 64.5px; margin-bottom: 6.5px; border-style: dashed; display: flex; justify-content: center;" appDnd (fileDropped)="onFileDropped($event,'image')" [ngClass]="{ 'custom-error': !this.imageurl[0] && submit }">
              <input style="
                      opacity: 0;
                      position: absolute;
                      z-index: 2;
                      width: 100%;
                      height: 65px;
                      top: 0px;
                      left: 0px;
                      cursor: pointer;
                    " type="file" #fileDropRef id="fileDropRef" (click)="$event.target.value = null"
                    (change)="fileBrowseHandler($event, $event.target.files, 'image')" accept=".png, .jpg, .jpeg" title="" />
              <div class="primary-button">
                <span *ngIf="!fileuploading">
                  Browse File
                </span>
                <span *ngIf="fileuploading">
                  <mat-spinner diameter="12"></mat-spinner>
                </span>
              </div>
            </div>
            <p>Maximum file size: 1MB</p>
            <div *ngIf="imageurl" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
              <div class="img_wrp">
                <img
                [src]="imageurl"
                alt=""
                style="
                  height: 80px;
                  width: 80px;
                  object-fit: contain;
                  border: 1px solid rgba(99, 100, 102, 0.3);
                "
              />
              <mat-icon
              class="qb-close-button remove-img-btn cursor-pointer"
              (click)="removeUrl('image')"
              >cancel</mat-icon
            >
              </div>
            </div>
          </div>
          <div class="text-container" *ngIf="dataType === 'video'" >
            <div class="textarea" style="height: 64.5px; margin-bottom: 6.5px; border-style: dashed; display: flex; justify-content: center;" appDnd (fileDropped)="onFileDropped($event, 'video')" [ngClass]="{ 'custom-error': !this.videourl[0] && submit }">
              <input style="
                      opacity: 0;
                      position: absolute;
                      z-index: 2;
                      width: 100%;
                      height: 65px;
                      top: 0px;
                      left: 0px;
                      cursor: pointer;
                    " type="file" #fileInputVideo id="fileInputVideo" (click)="$event.target.value = null"
                    (change)="fileBrowseHandler($event, $event.target.files, 'video')" accept=".mp4, .mov" />
                    <div class="primary-button">
                      <span *ngIf="!fileuploading">
                        Browse File
                      </span>
                      <span *ngIf="fileuploading" class="qb-mat-style">
                        <mat-spinner color="accent" diameter="12" ></mat-spinner>
                      </span>
                    </div>
            </div>
            <p>Maximum file size: 10MB</p>
            <div *ngIf="videourl" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
              <div class="img_wrp">
                <video
              alt=""
              style="
                height: 80px;
                width: 80px;
                object-fit: contain;
                border: 1px solid rgba(99, 100, 102, 0.3);
              ">
              <source [src]="videourl" />  
            </video>
            <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
            <mat-icon
              class="qb-close-button remove-img-btn cursor-pointer"
              (click)="removeUrl('video')"
              >cancel</mat-icon
            >
              </div>
            </div>
          </div>
          <div class="text-container" *ngIf="dataType === 'document'" >
            <div class="textarea" style="height: 64.5px; margin-bottom: 6.5px; border-style: dashed; display: flex; justify-content: center;" appDnd (fileDropped)="onFileDropped($event, 'document')" [ngClass]="{ 'custom-error': !this.documenturl[0] && submit }">
              <input style="
                      opacity: 0;
                      position: absolute;
                      z-index: 2;
                      width: 100%;
                      height: 65px;
                      top: 0px;
                      left: 0px;
                      cursor: pointer;
                    " type="file" #fileInputDocument id="fileInputDocument" (click)="$event.target.value = null"
                    (change)="fileBrowseHandler($event, $event.target.files,'document')" accept=".pdf" />
              <div class="primary-button">
                Browse File
              </div>
            </div>
            <p>Maximum file size: 5MB</p>
            <div *ngIf="documenturl" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
              <div class="img_wrp">
                <embed [src]="documenturl" type="application/pdf"  style="
              height: 80px;
              width: 80px;
              object-fit: contain;
              border: 1px solid rgba(99, 100, 102, 0.3);
            ">
             <mat-icon
             class="qb-close-button remove-img-btn cursor-pointer"
             (click)="removeUrl('document')"
             >cancel</mat-icon
           >
              </div>
            </div>
          </div>
        </div>
        <span class="input-header-text">Body</span>
        <div class="text-container">
          <textarea
            class="textarea"
            name="whatsappText"
            id="whatsappText"
            cols="20"
            rows="8"
            maxlength="1024"
            [(ngModel)]="whatsappText"
            [ngClass]="{ error: whatsappText.length === 0 && submit }"
            (focusout)="updateMeasurementPointComment($event)"
            (keyup)="keypresshandler($event)"
            (paste)="onPaste($event)"
          ></textarea>
          <!-- <span class="sms-count-text"
            >Count : <span>{{ smsCount }}</span></span
          > -->
          <span class="char-limit-text"
            ><span style="color: #00b759">{{ charCount }}</span
            >/1024</span
          >
          <!-- <div
            class="smiley-container"
            (click)="toggled = !toggled"
            [(emojiPickerIf)]="toggled"
            [emojiPickerDirection]="'left'"
            (emojiPickerSelect)="handleSelection($event)"
            >😄</div> -->
        </div>
        <div class="sms-options-div">
          <div class="sms-options-div-col1">

            <div>
              <mat-checkbox
              [(ngModel)]="optOutText"
              color="primary"
              (change)="optoutValueChange($event)"
                >Add opt-out text</mat-checkbox
              >
            </div>

            <div>
              <mat-checkbox
              [(ngModel)]="isButtonAdded"
              color="primary"
              (change)="buttonValueChange($event)"
                >Add Button</mat-checkbox
              >
            </div>
          </div>
         
          <div style="display: flex; flex-direction: column; gap: 4px; font-size: 13px;">
            <span>Add merge tags</span>
            <div>
              <div
              class="multi-select-panel"
                id="multi-select-panel"
                (click)="
                  toggleSelect(
                    $event,
                    'multi-select-dropdown',
                    'segment-select',
                    'multi-select-panel',
                    'keyboard-down-icon-container'
                  )
                "
              >
                <span>Select merge tag</span>
                <div
                  class="keyboard-down-icon-container"
                  id="keyboard-down-icon-container"
                >
                <span>
                  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.38672 8.17188C8.17578 8.38281 7.78906 8.38281 7.57812 8.17188L0.828125 1.42188C0.617188 1.21094 0.617188 0.824219 0.828125 0.613281C1.03906 0.402344 1.42578 0.402344 1.63672 0.613281L8 6.97656L14.3281 0.613281C14.5391 0.402344 14.9258 0.402344 15.1367 0.613281C15.3477 0.824219 15.3477 1.21094 15.1367 1.42188L8.38672 8.17188Z" fill="#202224"/>
                    </svg>
                </span>
                </div>
              </div>
              <div style="position: relative">
                <div class="multi-select-dropdown" id="multi-select-dropdown">
                  <div class="search">
                    <mat-icon class="group-icon">search</mat-icon>
                    <input
                      [(ngModel)]="mergetagFilter"
                      placeholder="Search merge tag"
                      type="text"
                      style="border: none; outline: none"
                    />
                  </div>
                  <div class="border-line"></div>
                  <div id="segment-select" class="segment-select">
                    <div>
                      <div
                        class="tag-element"
                        (click)="
                          addMergeTag(
                            tags['value'],
                            'multi-select-dropdown',
                            'segment-select',
                            'multi-select-panel',
                            'keyboard-down-icon-container'
                          )
                        "
                        *ngFor="
                          let tags of mergeTags | labelPipe: mergetagFilter:'name'
                        "
                      >
                        <span>{{ tags["name"] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isButtonAdded">
          <div style="margin-bottom: 10px">
            <span style="font-size: 13px; font-weight: 600">Button Name</span>
            <div
              class="formDiv"
              [ngClass]="{ error: (buttonName.length === 0 && submit)}"
            >
              <input
                class="formInput"
                type="text"
                (keypress)="buttonNameKeypress($event)"
                [(ngModel)]="buttonName"
              />
              <span class="template-name-text-limiter"
                >{{ buttonName.length
                }}<span style="color: #cccccc">/20</span></span
              >
            </div>
          </div>
  
          <div style="margin-bottom: 10px">
            <span style="font-size: 13px; font-weight: 600">Button Url</span>
            <div
              class="formDiv"
              [ngClass]="{ error: (buttonUrl.length === 0 && submit)}"
            >
              <input
                class="formInput"
                type="text"
                [(ngModel)]="buttonUrl"
              />
            </div>
            <span style="font-style: italic; font-size: 10px; color: #c71a1a;" *ngIf="errorUrl">Enter a valid url.</span>
          </div>
        </div>
      </div>
      <div class="preview-box">
        <div class="phone-image-edit">
          <div class="phone-background-whatsapp-edit"></div>
          <div class="phone-background-whatsapp-contact-name-edit">
            <div class="qb-d-flex ">
              <span style="color: black; font-size: 20px;">{{shopName | limitTo: 10}} </span>
              <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/verified_logo.svg" style="margin-top: 7px; margin-left: 5px;" width="20px" height="20px">
            </div>
          </div>
          <div class="text-container-div">
            <div class="preview-text-content-whatsapp-edit">
                <div *ngIf="dataType === 'image'">
                  <img
                  *ngIf="imageurl"
                  [src]="imageurl"
                  alt=""
                  style="
                    height: auto;
                    width: 100%;
                    object-fit: contain;
                  "
                />
                </div>
                <div *ngIf="dataType === 'document'">
                  <embed [src]="documenturl" type="application/pdf"  style="
                height: 120px;
                width: 280px;
                object-fit: contain;
              ">
                </div>
                <div *ngIf="dataType === 'text'">
                  <span style="font-size: 18px; font-weight: 600; white-space: pre-line;
                  word-break: break-word;">{{whatsappHeaderText}}</span>
                </div>
                <div *ngIf="dataType === 'video'">
                  <div *ngIf="videourl" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
                    <div class="img_wrp">
                      <video
                    alt=""
                    style="
                      height: 120px;
                      width: 280px;
                      object-fit: contain;
                    ">
                    <source [src]="videourl" />  
                  </video>
                  <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                    </div>
                  </div>
                </div>
                <span style="white-space: pre-line; word-break: break-word; margin-top: 10px;">{{
                  whatsappText
                }}</span>
                <span *ngIf="optOutText" style="color: #636466; font-size: 14px;">Reply STOP to opt out</span>
                <div class="preview-text-content-after"></div>
            </div>
            <div class="qb-button-text-whatsapp-preview-mobile-creation" *ngIf="isButtonAdded">
              {{buttonName}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <div style="display: flex; font-size: 12px">
        <span style="color: #919191">Credits needed - </span
        ><span><span style="color: #00b759">0</span> Per Customer</span>
      </div> -->
      <div style="display: flex" *ngIf="!templateSaveLoading">
        <span class="primary-button" (click)="saveSms(false)">Save and Exit</span>
      </div>
      <div style="display: flex" *ngIf="templateSaveLoading">
        <span class="primary-button">
          <mat-spinner diameter="12"></mat-spinner>
        </span>
      </div>
    </div>
  
  </div>