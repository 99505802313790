import { Component, ElementRef, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { request } from 'http';


class Particle {
  x;
  y;
  directionX;
  directionY;
  size;
  color;
  speedFactor = 0.025;
  constructor(x,y, directionX, directionY, size, color) {
    this.x = x;
    this.y = y;
    this.directionX = directionX;
    this.directionY = directionY;
    this.size = size;
    this.color = color;
  }

  //draws the particle
  drawParticle(ctx) {
    ctx.beginPath();
    ctx.arc(this.x,this.y,this.size,0,Math.PI*2,false);
    ctx.fillStyle = this.color;
    ctx.fill();
  }

  //updates the particle position
  update(canvasNativeElement,ctx) {
    if(this.x> canvasNativeElement.width || this.x< 0) {
      this.directionX = -this.directionX;
    }
    if(this.y> canvasNativeElement.height || this.y< 0) {
      this.directionY = -this.directionY;
    }

    this.x += this.directionX * this.speedFactor; //speedFactor slows the speed 
    this.y += this.directionY * this.speedFactor;
    this.drawParticle(ctx);
  }

  
}

@Component({
  selector: 'app-dynamic-background',
  templateUrl: './dynamic-background.component.html',
  styleUrls: ['./dynamic-background.component.css']
})
export class DynamicBackgroundComponent implements AfterViewInit {
  
  @ViewChild('mainCanvas') canvasEle!: ElementRef;
  canvasNativeElement;
  ctx: CanvasRenderingContext2D | null = null;

  constructor() { }

  particleArray = [];
  numberOfParticles;

  ngAfterViewInit(): void {
    this.canvasNativeElement =  this.canvasEle.nativeElement;
    this.ctx = this.canvasNativeElement.getContext('2d');
    // console.log(this.canvas);
    this.canvasNativeElement.width = window.innerWidth;
    this.canvasNativeElement.height = window.innerHeight;
    this.numberOfParticles = (this.canvasNativeElement.height*this.canvasNativeElement.width)/15000;
    console.log(`NUMBER OF PARTICLES = ${this.canvasNativeElement.height} / ${this.canvasNativeElement.width}  so ${this.numberOfParticles}`)
    for(let i=0; i< this.numberOfParticles; i++) {
      let size = (Math.random()*5)+1;
      let x = (Math.random() * ((innerWidth - size*2) - (size*2)) +size*2);
      let y = (Math.random() * ((innerHeight - size*2) - (size*2)) +size*2);
      let directionX =  Math.random()*5 - 4.5;
      let directiony =  Math.random()*5 - 4.5;
      let color = '#8ad1f6';
      this.particleArray.push(new Particle(x,y,directionX,directiony,size,color));
    }
    // console.log('ARRRAY',this.particleArray);
    //starts animation
    this.animate();
  }

  //each frame of animation;
  animate = () => {
    requestAnimationFrame(this.animate);
    this.ctx.clearRect(0,0,innerWidth,innerHeight);
    for(let i=0; i<this.particleArray.length; i++) {
      this.particleArray[i].update(this.canvasNativeElement,this.ctx);
    }
    this.connectParticles();
  }

  //if particles are close enough draw a line between them
  connectParticles() {
    let lineOpacityValue = 0;
    for(let a = 0; a< this.particleArray.length; a++) {
      for(let b=0; b< this.particleArray.length; b++) {
        let distanceBetween = (( this.particleArray[a].x - this.particleArray[b].x) * ( this.particleArray[a].x - this.particleArray[b].x)
                              + ( this.particleArray[a].y - this.particleArray[b].y) * ( this.particleArray[a].y - this.particleArray[b].y));
        //connection distance 
        if(distanceBetween < (this.canvasNativeElement.width/6)*(this.canvasNativeElement.height/6)) {
          // console.log((this.canvasNativeElement.width/6)*(this.canvasNativeElement.height/6));
          lineOpacityValue = 1 - (distanceBetween/((this.canvasNativeElement.width/6)*(this.canvasNativeElement.height/6)+10000)) ;
          this.ctx.strokeStyle = `rgba(138,209,246,${lineOpacityValue})`;
          this.ctx.lineWidth = 1;
          this.ctx.beginPath();
          this.ctx.moveTo(this.particleArray[a].x,this.particleArray[a].y);
          this.ctx.lineTo(this.particleArray[b].x,this.particleArray[b].y);
          this.ctx.stroke();
        }
      }
    }
  }



  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.canvasNativeElement.width = window.innerWidth;
    this.canvasNativeElement.height = window.innerHeight;
    this.initializeParticles();
  }
  
  initializeParticles() {
    this.particleArray = [];
    this.numberOfParticles = (this.canvasNativeElement.height * this.canvasNativeElement.width) / 9000;
    for (let i = 0; i < this.numberOfParticles; i++) {
      let size = (Math.random() * 5) + 1;
      let x = (Math.random() * ((this.canvasNativeElement.width - size * 2) - (size * 2)) + size * 2);
      let y = (Math.random() * ((this.canvasNativeElement.height - size * 2) - (size * 2)) + size * 2);
      let directionX = Math.random() * 5 - 2.5;
      let directionY = Math.random() * 5 - 2.5;
      let color = '#8ad1f6';
      this.particleArray.push(new Particle(x, y, directionX, directionY, size, color));
    }
    this.animate();
  }

}