<div class="qb-main-div">
  <div *ngIf="!exporting && !showDoneImg">
    <div class="qb-title-div">
      <div class="qb-title">{{'SEGMENT_PAGE.EXPORT_FACEBOOK_AUD' | i18nTranslate}} </div>
      <div><span (click)="close()"
          class="material-icons-outlined icons-primary qb-close-icon"
        >
          close
        </span></div>
    </div>
    <!-- <div class="qb-input-div">{{'SEGMENT_PAGE.AUDIENCE_NAME' | i18nTranslate}} <span class="qb-required">*</span></div>
    <div class="qb-input">
    <input type="text" [(ngModel)]="data['audienceName']">
    </div> -->
    <div class="q-field-block-customer margin-top-13">
      <div class="q-field-name">{{'SEGMENT_PAGE.AUDIENCE_NAME' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
      <div class="q-input-outline-dialog">
        <input class="q-input-field" type="text" [(ngModel)]="data['audienceName']" (keypress)="audNameKeyPress($event,50)" maxlength="50">
        <span class="game-name-text-limiter"
        >{{ data['audienceName'].length
        }}<span style="color: #cccccc">/50</span></span>
      </div>
      <div style="color: #dc3545; font-size: 12px;" *ngIf="(!data['audienceName'] || data['audienceName'].length == 0) && submitted">Please enter valid name</div>
    </div>
    <!-- <div class="qb-error" *ngIf="data['audienceName'].length == 0 && submitted">{{'SEGMENT_PAGE.AUDIENCE_NAME _CANNOT_EMPTY' | i18nTranslate}}</div> -->
    <!-- <div class="qb-input-div">{{'SEGMENT_PAGE.DESCRIPTION' | i18nTranslate}}</div> -->
    <!-- <div class="qb-input">
    <input type="text" [(ngModel)]="data['description']">
    </div> -->
    <div class="q-field-block-customer">
      <div class="q-field-name">{{'SEGMENT_PAGE.AUDIENCE_NAME' | i18nTranslate}}</div>
      <div class="q-input-outline-dialog">
        <input class="q-input-field" type="text" [(ngModel)]="data['description']" (keypress)="audNameKeyPress($event,150)" maxlength="150">
        <span class="game-name-text-limiter"
        >{{ data['description'].length }}<span style="color: #cccccc">/150</span></span>
      </div>
    </div>
    <div class="qb-buttons">
        <div class="primary-button" (click)="save()">{{'SEGMENT_PAGE.SAVE' | i18nTranslate}}</div>
    </div>
  </div>
  <div *ngIf="exporting || showDoneImg" class="exporting-container">
    <app-qubriux-loading *ngIf="exporting" [size]="45" [loadingText]="'Exporting your Segment...'"></app-qubriux-loading>
    <img *ngIf="showDoneImg" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/dashboard-assets/done_anime.gif" alt="done">
  </div>
</div>