import { Injectable, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { EmailNotVerifiedDialogBoxComponent } from "src/app/shared/components/dialog-box/email-not-verified-dialog-box/email-not-verified-dialog-box.component";
import { TokenStorageService } from "../auth/token-storage.service";
import { ApiCallService } from "./api-call-service";
import { SnackbarCollection } from "./snackbar.service";

@Injectable()
export class create360dialogaccoountService implements OnInit {
  constructor(
    private apicall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private dialog: MatDialog,
    private snackBar: SnackbarCollection
  ){}
    env = '';
    requestedNumber
    partnerId = 'SYV1FsPA'
    queryParameters
    windowObjectReference = null;
    previousUrl = null;
    permissionUrl = 'https://hub.360dialog.com/dashboard/app/SYV1FsPA/permissions'
  ngOnInit(){
    }
    createAccount(){
        const windowFeatures =
        'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
        let partnerId= 'SYV1FsPA';
        let redirect_url = 'http://localhost:4200/app/whatsapp/choose-layout';
        let url = 'https://hub.360dialog.com/dashboard/app/'+partnerId+'/permissions';
        open(
          url,
          'integratedOnboardingWindow',
          windowFeatures
        );
    
        window.addEventListener(
          "message",
          (event) => {
            const { data } = event;
            const queryString = `${data}`;        
            let params = new URLSearchParams(queryString);
            let channels = params.get("channels");  
            let client = params.get("client")   
            // ?client=oaY9LLfUCL&channels=[y9MiLoCH]
          }, false
        );
    
        window.onload = function() {
          this.processParams();
        }

        // if (url) {
        //   // window.open("www.qubriux.skellam.com");
        //   window.location.href = url;
        //   // window.open(url)
        // }
    }
    createAccountNew(){
      this.apicall.isEmailVerified().subscribe(response => {
        if(response['body']['message'] === 'verified') {
          this.openSignInWindow(this.permissionUrl, 'connect-360dialog', window.location.origin)
        } else {
          const dialogRef = this.dialog.open(EmailNotVerifiedDialogBoxComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "30%",
            data: {
              cancelButtonText: "Cancel",
              successButtonText: "Ok",
            },
          });
        }
      })
      this.apicall.setRedirectPath().subscribe((response)=>{

      },(err)=>{
        this.snackBar.openSnackBar("Something wnet worng.",5000)
      });
      
    }
    processParams() {
        const params = window.location.search;
        if (window.opener) {
          window.opener.postMessage(params);
          window.close();
        }
    }
    async sendWhatsAppDetails(clinetId, channel){
     await this.apicall.sendWhatsAppClientDetails(clinetId, channel).subscribe((response)=>{
          const dialogRef = this.dialog.open(SuccessDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "30%",
            data: {
              message: "Your WhatsApp Business account Linked Sucessfully",
              buttonText: "Ok",
            },
          });
          this.tokenStorage.setIsAccountCreated(true);
      },(err)=>{
        this.snackBar.openSnackBar("Something wnet worng.",5000)
      });
    }
   
    
  receiveMessage(event?, baseUrl?){
    if (event.origin != baseUrl) {
      return;
    }
  
    const { data } = event;
    const queryString = `${data}`;
    let params = new URLSearchParams(queryString);
    let channels = params.get("channels");  
    let client = params.get("client"); 
    if(channels != null && client != null)
      setTimeout(() => {
        this.sendWhatsAppDetails(client, channels);
      }, 500);
    // window.location.search = redirectUrl;
    // window.history.replaceState({}, "", redirectUrl);
  }
    initializeOnBoard(){
      const params = window.location.search;
      const client = this.getUrlParameter(params, "client");
      const channels = this.getUrlParameter(params, "channels");
      const revokedChannels = this.getUrlParameter(params, 'revoked');
  
      if (client && channels) {
        const channelsArray = channels
          .substring(1, channels.length - 1)
          .split(",");
        
          let callbackObj = {
            client: client,
            channels: channelsArray,
          };
  
          if (revokedChannels) {
            const revokedChannelsArray = revokedChannels
              .substring(1, revokedChannels.length - 1)
              .split(',');
            callbackObj['revokedChannels'] = revokedChannelsArray;
          }
          this.callback(callbackObj);
        // remove search parameters from URL after fetching them
        // window.history.replaceState(null, "", window.location.pathname);
      }
      // send to parent (opener) window and close small window
      if (window.opener) {
        window.opener.postMessage(params);
        window.close();
      }
    }
    getUrlParameter(params: string, name: string) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results = regex.exec(params);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    openSignInWindow(url, name, baseUrl){
      // remove any existing event listeners
      window.removeEventListener("message", this.receiveMessage);
    
      // window features
      const strWindowFeatures =
        "toolbar=no, menubar=no, width=600, height=900, top=100, left=100";
    
      if (this.windowObjectReference === null || this.windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
          or if such pointer exists but the window was closed */
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (this.previousUrl !== url) {
        /* if the resource to load is different,
          then we load it in the already opened secondary window and then
          we bring such window back on top/in front of its parent window. */
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
        this.windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
          is not closed; therefore, we can bring it back on top of any other
          window with the focus() method. There would be no need to re-create
          the window or to reload the referenced resource. */
        this.windowObjectReference.focus();
      }
  
      // add the listener for receiving a message from the popup
      window.addEventListener(
        "message",
        (event) => this.receiveMessage(event, baseUrl),
        false
      );
      // assign the previous URL
      this.previousUrl = url;
    }
    callback(data){
    }

//#
  //   const baseUrl =
  //   (env === 'local' && 'http://0.0.0.0:8082') ||
  //   (env === 'staging' && 'https://admin.hub-staging.360dialog.io') ||
  //   (env === 'rc' && 'https://rc-admin.360dialog.io') ||
  //   'https://hub.360dialog.com';
    

  // let permissionUrl = requestedNumber
  //   ? `${baseUrl}/dashboard/app/${partnerId}/permissions?number=${requestedNumber}`
  //   : `${baseUrl}/dashboard/app/${partnerId}/permissions`;

  // if (queryParameters) {
  //   let values = Object.values(queryParameters)
  //   Object.keys(queryParameters).forEach((k, idx) => {
  //     if (idx === 0 && !requestedNumber) {
  //       permissionUrl = permissionUrl + `?${k}=${values[idx]}`;
  //     } else {
  //       permissionUrl = permissionUrl + `&${k}=${values[idx]}`;
  //     }
  //   })
  // }



 

 // #


}