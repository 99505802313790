<head>
  <meta charset="utf-8" />
  <title>{{'STATIC_PAGE.WARNING_TITLE' | i18nTranslate}}</title>
  <meta name="description" content="Sign In" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
</head>

<body>
  <div class="NFContainer">
    <img
      style="max-width: 130px; width: 20%"
      [src]="logoUrl"
      alt=""
    />
    <div class="content-section">
      <div
        style="display: flex; justify-content: center; width: 100%; height: 40%"
      >
        <img [src]="imgUrl" alt="greyfox" class="notFoundImage" />
      </div>

      <div style="display: flex; justify-content: center; width: 100%">
        <div>
          <p class="OOPS">{{'STATIC_PAGE.OOPS' | i18nTranslate}}</p>
          <p class="NFText">
            {{'STATIC_PAGE.WARNING_PARAGRAPH' | i18nTranslate}}
          </p>
          <div style="display: flex; width: 100%" class="hr-center">
            <div>
              <p class="button-confirm-MT" (click)="sendConfirmMessage()">
                {{'STATIC_PAGE.CONTINUE' | i18nTranslate}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
