import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, SocialMediaConnectChannelDialogComponent, SocialMediaPagesSelectDialogComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { environment } from "src/environments/environment";

export interface channelShortAnalytics{
  channel_name;
  total_followers;
  new_followers;
  no_of_posts;
  reach;
}
declare var FB;
@Component({
  selector: "app-social-media-home",
  templateUrl: "./social-media-home.component.html",
  styleUrls: ["./social-media-home.component.css"],
})
export class SocialMediaHomeComponent implements OnInit {
  fbConnectionStatus;
  longresponse;
  shorttoken;
  userClient;
  constructor(
    private _apicall: ApiCallService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _dialog: MatDialog,
    private _tokenstorage:TokenStorageService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private _WidgetConfigService: WidgetConfigService,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}
  createNewPostSvg = "";
  socialChannels = [];
  socialChannelsLoading = true;
  yourRecentActivity = [];
  eventDataStream;
  liveUpdateData=[];
  dataType = "adAccount"
  adAccount = [];

  socialChannelTableData = ["Account", "Channels","Total Followers","New Followers","No of Posts","Reach", "Action"];
  adAccountTableData = ["Channel", "Id", "Account Name", "Action"]

  recentPost=[];
  postAnalyticsObj=[];
  recentPostLoading=true;
  recentPostIds=[];
parParams=null;
channel;
isCampaign = false;
campaignId;
isPageSelectDialogOpenedOnClick=true;
appSubscriptionPlan;
validWidgets = null;

 async ngOnInit() {
  this.appSubscriptionPlan =
  await this._WidgetConfigService.getAppSubscriptionPlan();
  let params=this._route.snapshot.queryParams;
    this.parParams=params;
     this.channel = params["channel"];
   this.getSocialChannelsWithShortAnalytics();
  this.initializeSocialHomeScreenCardData();
  this._route.queryParams.subscribe(params => {
    if(params['state']){
      this.isCampaign = params['state'].split(':')[0];
      this.campaignId = params['state'].split(':')[1];
    }
});
    
      this.getRecentPostDetails();
      this.getAdAccountDetails()
     
  }

  initializeSocialHomeScreenCardData(){

   // this.connect();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  redirectForFbOauth(channel){
    let url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=`+environment.socialFBAppId+`&redirect_uri=
    `+window.location.protocol +
    "//" +
    environment.Origin +`/app/social-eng?channel=` + channel + `&state=state8564&&display=popup&scope=`+environment.fbScope;;
        window.open(url,"_self");
  }

  async openConnectSocialProfilesDialog(refresh, textMsg?) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
          const dialogref = this._dialog.open(
            SocialMediaConnectChannelDialogComponent,
            {
              panelClass: "customer-dialog-popup",
              height: "auto",
              disableClose: true,
              width: "50%",
              data: {
                connectAds: false,
                refresh: refresh,
                textMsg: textMsg ? textMsg : null
              }
            }
          );
      
          dialogref.afterClosed().subscribe((result) => {
            if (result) {
            //  this.authenticateFB();
            this.redirectForFbOauth(result); // this is for redirecting to facebook oauth
            }
          });
        }
  }
  openDialogFbPagesSelect(code,socialPagesIdList) {
    this.parParams=null;
    const dialogref = this._dialog.open(
      SocialMediaPagesSelectDialogComponent,
      {
        panelClass: "customer-dialog-popup",
        height: "auto",
       
        width: "40%",
        data: {
          code: code,
          channel: this.channel,
         alreadyLinkedChannels:this.socialChannels,
         socialPagesIdList:socialPagesIdList
        },
      }
    ); 

    dialogref.afterClosed().subscribe((result) => {
     
      if (result) {
        this.getSocialChannelsWithShortAnalytics();
        this.getAdAccountDetails()

      }
    });
  }

   getSocialChannelsWithShortAnalytics() {
    this.socialChannelsLoading = true;
    this._apicall.getSocialMediaUserLisingAndAnalyticsData().subscribe((response) => {
      this.processAnalyticsDataShort(JSON.parse(response["body"]));
      this.socialChannelsLoading = false; 
    },
    (error) => {
      this.socialChannelsLoading = false;
      if (this.parParams!=null&&this.parParams.code) {
        this._router.navigate([], {
          queryParams: {
            'code': null,
            'state': null,
          },
          queryParamsHandling: 'merge'
        })
    
       
        this.openDialogFbPagesSelect(this.parParams.code,[]);
        
      }
    });
    // let response = {"message":"success","body":"{\n  \"data\": [],\n  \"isRefresh\": true,\n  \"messageBody\": \"Your facebook access token expired, login back to facebook again!\",\n  \"channel\": \"Facebook\"\n}"}
    // this.processAnalyticsDataShort(JSON.parse(response["body"]));
    // this.socialChannelsLoading = false; 
  }

  processAnalyticsDataShort(fbdata){
    this.socialChannels=[];
    let i=0;
    let isRefresh = fbdata['isRefresh'];
    let socialPagesIdList=[];
    console.log(fbdata)
    if(isRefresh && this.parParams.code == null){
      this. openConnectSocialProfilesDialog(true, fbdata['messageBody'])
     }
     else{
       for(let account of fbdata['data']){
           let ch = "[" + account['channels'] + "]";
           if((ch.includes('instagram') && ch.includes('facebook'))){
             this.addFbPage(account, socialPagesIdList);
             this.addInstaAccount(account, socialPagesIdList);
           }
           else{
            if((ch.includes('facebook')))
              this.addFbPage(account, socialPagesIdList);
            if((ch.includes('instagram')))
              this.addInstaAccount(account, socialPagesIdList);
           }
        }
     }
  if (this.parParams!=null && this.parParams.code && !isRefresh) {
    this._router.navigate([], {
      queryParams: {
        'code': null,
        'state': null,
      },
      queryParamsHandling: 'merge'
    })


    this.openDialogFbPagesSelect(this.parParams.code,socialPagesIdList);
    
  }
  if(this.parParams!=null && this.parParams.code && isRefresh){
    this._router.navigate([], {
      queryParams: {
        'code': null,
        'state': null,
      },
      queryParamsHandling: 'merge'
    })
    this.refreshFacebook(this.parParams.code)
  }
}

addFbPage(account, socialPagesIdList) {
  let channelShortAnalytics={
    channel_name:null,
    total_followers:null,
    new_followers :null,
    no_of_posts :null,
    reach :null,
    id:null,
    channel: null
  }
  if(account['fbPageInsight'] != null){
    let insightMetricsData=account['fbPageInsight'].map.insights.map.data.myArrayList;
     channelShortAnalytics.total_followers=account['fbPageInsight'].map.followers_count;
     insightMetricsData.forEach(element => {
      if(element.map.name === 'page_daily_follows_unique' && element.map.period === "day") {
        channelShortAnalytics.new_followers = element.map.values.myArrayList[0].map.value
      }
      if(element.map.name === "page_impressions_unique" && element.map.period === "day") {
        channelShortAnalytics.reach = element.map.values.myArrayList[0].map.value
      }
     });
   channelShortAnalytics.channel_name=account['fbPageInsight'].map.name;
   channelShortAnalytics.no_of_posts=account['fbPageInsight'].map.published_posts.map.summary.map.total_count;
   channelShortAnalytics.id=account['fbPageInsight'].map.id;
   channelShortAnalytics.channel = "Facebook"
   socialPagesIdList.push(account['fbPageInsight'].map.id);
   this.socialChannels.push(channelShortAnalytics);
  }
}

addInstaAccount(account, socialPagesIdList) {
  let channelShortAnalytics={
    channel_name:null,
    total_followers:null,
    new_followers :null,
    no_of_posts :null,
    reach :null,
    id:null,
    channel: null
  }
  if(account['instaPageInsight'] != null){
    channelShortAnalytics.total_followers=account['instaPageInsight'].map.TotalFollowers;
  channelShortAnalytics.reach=account['instaPageInsight'].map.reach;
  channelShortAnalytics.channel_name=account['instaPageInsight'].map.igUserName;
  channelShortAnalytics.channel = "Instagram"
  channelShortAnalytics.no_of_posts=account['instaPageInsight'].map.TotalPost;
  channelShortAnalytics.id=account['instaPageInsight'].map.instaId;
  socialPagesIdList.push(account['instaPageInsight'].map.instaId);
  this.socialChannels.push(channelShortAnalytics);
  }
}

createNewPost(){
  if(this.socialChannels==null||this.socialChannels.length==0){
    return;
  }
  this._router.navigate(["app/social-eng/social/post"]);
}
connect(): void {
  // // let url="https://dev.qubriux.com/ezloyal-web/event/subscribe?merchantId="+this._tokenstorage.getMerchantId();
  // // let source = new EventSource(url);
  // source.addEventListener('social', message => {
  //     this.eventDataStream = JSON.parse(message["data"]);   
    
  //     if(this.eventDataStream.title=='fb-social'){
  //       this.liveUpdateData.push(this.eventDataStream.data); 
  //     }
  // });
}

getRecentPostDetails(){
  this.recentPostLoading=true;
  this._apicall.getRecentPostAnalyticsAndData().subscribe((response)=>{
      this.recentPostLoading = false; 

let rb=JSON.parse(response["body"]);
    for (let i=0;i<rb.length;i++){
      let jk={post_details:{contents:null,launch_time:null},post_analytics:null, channel:null, name:null};
      let parsedImposeData=JSON.parse(rb[i]["imposeData"])
let ppid = Object.keys(parsedImposeData);
this.recentPostIds.push(ppid);
if(rb[i].postList.channel === "FACEBOOK") {
  jk.channel = "FACEBOOK";
      jk.post_analytics=this.processData(parsedImposeData,ppid);
}
else {
  jk.channel = "INSTAGRAM";
  jk.post_analytics=this.processInstaData(parsedImposeData,ppid);
}
jk.name = rb[i].pageName;
      jk.post_details["contents"]=JSON.parse(rb[i]["postList"]["contents"]);
      if(rb[i]["postList"]["launchTimestamp"] !== null)
      jk.post_details["launch_time"]=rb[i]["postList"]["launchTimestamp"].split("|")[0];
     this.postAnalyticsObj.push(jk);
    }
    this.recentPostLoading=false;
  },(error)=>{
    this.recentPostLoading=false;
  })
} 

processInstaData(ob, id) {
  let engagementValueObj;
  let analyticsdataPoints={
shares:0,
likes:0,
comments:0,
engagement:0
}
  if(ob.error){
  }else{
    let obj =ob[id]
    obj.forEach(item => {
      if(item.name === "profile_activity") 
      engagementValueObj = item.values[0].value;
      if(item.name === "shares") 
      analyticsdataPoints.shares = item.values[0].value;
      if(item.name === "likes") 
      analyticsdataPoints.likes = item.values[0].value;
      if(item.name === "comments") 
      analyticsdataPoints.comments = item.values[0].value;
      if(item.name === "profile_activity") 
      analyticsdataPoints.engagement = item.values[0].value;
    })

return analyticsdataPoints;
 
    
  }
}

processData(ob,id){

  if(ob.error){
  }else{
    let obj =ob[id]
let insightMetricsData=obj.insights.data;

let engagementValueObj = insightMetricsData.find(
(opt) => opt.name == "post_engaged_users" && opt.period == "lifetime"
);
let negative_feedback_ValueObj=insightMetricsData.find(
(opt) => opt.name == "post_negative_feedback" && opt.period == "lifetime"
);

let analyticsdataPoints={
shares:obj.shares?obj.shares.count:0,
reactions:obj.reactions?obj.reactions.summary.total_count:0,
likes:obj.likes?obj.likes.summary.total_count:0,
comments:obj.comments?obj.comments.summary.total_count:0,
engagement:engagementValueObj.values[0].value,
negative_feedback:negative_feedback_ValueObj.values[0].value,
}

return analyticsdataPoints;
 
    
  }

}

openPost(i, channel){
  let postId= this.recentPostIds[i];
  if(channel === 'FACEBOOK') {
  let url ='https://www.fb.com/'+postId;
  window.open(url,'_blank')
  } else {
    let url ='https://www.instagram.com/'+postId;
  window.open(url,'_blank')
  }
}
async refreshFacebook(code){
  await this._apicall.refreshSocialMediaPagesCode(this.channel, code).subscribe((response)=>{
    const dialogRef = this._dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      disableClose: true,
      data: {
        message: this._i18nDynamicTranslate.transform("You have Sucessfully Logged back in.", ['POS_PAGE']),
        buttonText: this._i18nDynamicTranslate.transform("Close", ['POS_PAGE'])
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let params=this._route.snapshot.queryParams;
      this.parParams=params;
      this.channel = params["channel"];
      this.getSocialChannelsWithShortAnalytics();
      this.getAdAccountDetails()
    });
  },(err)=>{
    this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong.", ['POS_PAGE']),5000);
  })
}
changeType(value){
  this.dataType = value;
  if(value === 'adAccount'){
    this.getAdAccountDetails();
  }
}
getAccountData(){
    
}
deleteChannel(id, name, channel){
  console.log(id,name);
  const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
    panelClass: "no-padding-dialog-popup",
    data: {
      subject: this._i18nDynamicTranslate.transform("Delete Channel", ['POS_PAGE']),
      message: this._i18nDynamicTranslate.transform("Do you want to delete", ['POS_PAGE']) + " " + name + " " + this._i18nDynamicTranslate.transform("Channel?", ['POS_PAGE']),
      "data-button": "Confirm: Delete",
      successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
      cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      let data = {};
      if(channel === 'Facebook'){
        data = {
          fbPageId : id,
          igId : null
        }
      }
      else{
        data = {
          fbPageId : null,
          igId : id
        }
      }
      this._apicall.deleteChannel(data).subscribe((res)=>{
        this.getSocialChannelsWithShortAnalytics();
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Deleted", ['POS_PAGE']) + " "+name+ " " + this._i18nDynamicTranslate.transform("channel successfully", ['POS_PAGE']), 6000);
      },(err)=>{
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something Went Wrong", ['POS_PAGE']), 6000);
      });
    }
  });
}

isAdAccountLoading = true;
getAdAccountDetails(){
  this.isAdAccountLoading = true;
  this._apicall.getAdsAccount().subscribe((res)=>{
    // let res = {
    //    "message": "success",
    //    "body": "{\n  \"1153503498864115\": \"Qubriux-AdAccount-Dev\"\n}"
    //  }
    this.adAccount = [];
     let data = JSON.parse(res['body']);
     let temp :any = {}
     for(let i in data){
       temp.id = i;
       temp.name = data[i];
       this.adAccount.push(temp);
     }
     this.isAdAccountLoading = false;
     console.log("AdAccount",this.adAccount);
  },
  (error) => {
    this.snackBar.openSnackBar('Error fetching Ad Account Details',2000);
    this.isAdAccountLoading = false;
    console.log(error);
  })
}
deleteAdAccount(id, name){
  const dialogRef = this._dialog.open(ConfirmationDialogBoxComponent, {
    panelClass: "no-padding-dialog-popup",
    data: {
      subject:  this._i18nDynamicTranslate.transform("Delete Ad Account", ['POS_PAGE']),
      message: this._i18nDynamicTranslate.transform("Do you want to delete", ['POS_PAGE']) + " " + name + " " + this._i18nDynamicTranslate.transform("Ad Account?", ['POS_PAGE']),
      "data-button": "Confirm: Delete",
      successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
      cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      this._apicall.deleteAdChannel(id).subscribe((res)=>{
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Deleted", ['POS_PAGE']) + " " +name+" " + this._i18nDynamicTranslate.transform("Ad Account successfully", ['POS_PAGE']), 6000);
        this.getAdAccountDetails();
      },(err)=>{
        this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something Went Wrong", ['POS_PAGE']), 6000);
      });
    }
  });
}
async openConnectSocialProfilesDialogAd(refresh, textMsg?) {
  if (
    await this._SubscriptionPlanHandlerService.IsValidOperation(
      this.appSubscriptionPlan
    )
  ) {
        const dialogref = this._dialog.open(
          SocialMediaConnectChannelDialogComponent,
          {
            panelClass: "customer-dialog-popup",
            height: "auto",
            disableClose: true,
            width: "50%",
            data: {
              connectAds: true,
              refresh: refresh,
              textMsg: textMsg ? textMsg : null
            }
          }
        );
    
        dialogref.afterClosed().subscribe((result) => {
          if (result) {
          //  this.authenticateFB();
          this.redirectForFbOauth(result); // this is for redirecting to facebook oauth
          }
        });
      }
}

}
