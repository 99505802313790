import { Injectable, Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "filterPipe",
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], param: string, filterMetadata): any {
    param = param.toLowerCase();
    if (!items || !param) {
      return items;
    }
    if (items) {
      return items.filter((item, index) => {
        const find = item.toLowerCase().indexOf(param);
        return find === -1 ? false : true;
      });
    }
  }
  //   transform(items: any[], filter: string): any {
  //     if (!items || !filter) {
  //       return items;
  //     }
  //     // filter items array, items which match and return true will be
  //     // kept, false will be filtered out
  //     return items.filter((item) => item.layoutName.indexOf(filter) !== -1);
  //   }
}