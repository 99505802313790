import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiCallService } from 'src/app/core/services/api-call-service';

@Component({
  selector: 'app-export-segment',
  templateUrl: './export-segment.component.html',
  styleUrls: ['./export-segment.component.css']
})
export class ExportSegmentComponent implements OnInit {

  submitted = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ExportSegmentComponent>, private apiCall :ApiCallService, private snakbar :MatSnackBar) { }

  ngOnInit(): void {
  }

  exporting = false;
  save() {
    this.submitted = true;
    if(this.data["audienceName"].length > 0) {
      this.exporting = true;
      const startTime = performance.now();
      this.apiCall.exportSegment(this.data).subscribe(response => {
        console.log(response);
        const apiDuration = performance.now() - startTime;
        if (apiDuration <= 1200) {
          setTimeout(() => {
            this.exporting = false;
            this.showDone();
          }, 1200 - apiDuration);

        } else {
          this.exporting = false;
          this.showDone();
        }
      },
      error => {
        this.snakbar.open('Error exporting segment','',{
          duration: 2000
        });
        this.dialogRef.close(this.data);
      }
    )
    }
  }

  showDoneImg = false;
  showDone() {
    this.showDoneImg = true;
    setTimeout(()=> {
      this.showDoneImg = false;
      this.dialogRef.close(this.data);
    }
    ,2300);
  }

  audNameKeyPress(event,len) {
    if (len - this.data["audienceName"].length === 0) {
      return false;
    }
  }

  close() {
    this.dialogRef.close(false);
  }

}
