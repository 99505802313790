<div style="height: 100%;overflow-y: auto;width: 100%;">
  <div style="padding-bottom: 50px;
background: #FFFFFF;
box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
border-radius: 12px;
margin: 24px;
min-height: calc(100% - 48  px);">
    <div style="display: flex;flex-direction: column;">
      <div class="gf-topup-your">{{'PROFILE_PAGE.TOP_UP_ACCOUNT' | i18nTranslate}}</div>


      <div style="display: flex;justify-content: space-between;padding: 12px 20px 0px 20px;margin-bottom: 8px;">
        <div class="gf-estimate-text">{{'PROFILE_PAGE.ESTIMATED_PRICE' | i18nTranslate}}</div>
        <!-- <div *ngIf="!mobileMedia.matches">
        <div class="primary-button gf-select-btn" (click)="getTopupPriceInfo()" *ngIf="!isPricePopulatedLoading">
          Calculate
        </div>
        <div class="primary-button gf-select-btn" *ngIf="isPricePopulatedLoading">
          <mat-spinner diameter="12"></mat-spinner>
        </div>
      </div> -->

      </div>


      <div class="gf-topup-section">

        <!-- <div class="gf-topup-card">
        <div class="gf-topup-card-title">How much more contact you want to
          add to your plan?</div>
        <div class="gf-credit-input">
          <input class="gf-credit-input-field" type="number" min="1" placeholder="Contact Size"
            [(ngModel)]="requestedCredit.contact">
        </div>
        <div class="gf-topup-card-result-price">
          <div style=" font-size: 32px; display:flex;" *ngIf="!isPricePopulatedLoading"> <span
              style="font-size: 16px; ">
              $&nbsp;</span> <span style="line-height: 100%;">{{estimatedCreditAmount.contact}}</span></div>
          <div *ngIf="isPricePopulatedLoading">
            <mat-spinner diameter="12"></mat-spinner>
          </div>
        </div>
      </div> -->

        <div class="gf-topup-card">
          <div class="gf-topup-card-title">{{'PROFILE_PAGE.HOW_MANY_TO_SEND' | i18nTranslate}}</div>
          <div class="gf-credit-input" id="gf-test-11div">
            <input class="gf-credit-input-field" type="number" min="1" step="1"
          placeholder="Email credits"
              [(ngModel)]="requestedCredit.email" id="gf-req-email" autocomplete="off">
              <span>x 1000</span>
          </div>
          <div class="gf-topup-card-result-price">
            <div style=" font-size: 32px; display:flex;" *ngIf="!isPricePopulatedLoading"> <span
                style="font-size: 16px; ">
                $&nbsp;</span> <span style="line-height: 100%;overflow-wrap: anywhere;">{{estimatedCreditAmount.email}}</span></div>
            <div *ngIf="isPricePopulatedLoading">
              <mat-spinner diameter="12"></mat-spinner>
            </div>
          </div>
        </div>

        <div class="gf-topup-card">
          <div class="gf-topup-card-title">{{'PROFILE_PAGE.HOW_MANY_SEND_SMS' | i18nTranslate}}</div>
          <div class="gf-credit-input">
            <input class="gf-credit-input-field" type="number" min="1" step="1"
            placeholder="SMS credits"
              [(ngModel)]="requestedCredit.sms" id="gf-req-sms" autocomplete="off">
              <span>x 100</span>
          </div>
          <div class="gf-topup-card-result-price">
            <div style=" font-size: 32px; display:flex;" *ngIf="!isPricePopulatedLoading"> <span
                style="font-size: 16px; ">
                $&nbsp;</span> <span style="line-height: 100%;overflow-wrap: anywhere;">{{estimatedCreditAmount.sms}}</span></div>
            <div *ngIf="isPricePopulatedLoading">
              <mat-spinner diameter="12"></mat-spinner>
            </div>
          </div>
        </div>

        <div class="gf-topup-card">
          <div style="height:21px;margin-bottom: 16px;visibility: hidden;">{{'PROFILE_PAGE.CALCULATE_CREDIT' | i18nTranslate}}</div>
          <div style="height:55px">
            <div class="gf-price-calculate-btn">
              <div class="primary-button gf-select-btn " (click)="getTopupPriceInfo()" *ngIf="!isPricePopulatedLoading">
                {{'PROFILE_PAGE.CALCULATE' | i18nTranslate}}
              </div>
              <div class="primary-button gf-select-btn" *ngIf="isPricePopulatedLoading">
                <mat-spinner diameter="12"></mat-spinner>
              </div>
            </div>
          </div>
          <div style="    height: 81px;">

          </div>
          <!-- <div class="gf-price-calculate-btn">
          <div class="primary-button gf-select-btn " (click)="getTopupPriceInfo()" *ngIf="!isPricePopulatedLoading">
            Calculate
          </div>
          <div class="primary-button gf-select-btn" *ngIf="isPricePopulatedLoading">
            <mat-spinner diameter="12"></mat-spinner>
          </div>
        </div> -->

        </div>


      </div>
    </div>


    <div *ngIf="!isPricePopulatedLoading&&isPricePopulated" style="padding: 0 20px;">

      <div class="gf-estimated-info">
        <div class="gf-estimate-section">
          <div class="gf-estimate-text">{{"PROFILE_PAGE.ESTIMATED_TOTAL" | i18nTranslate}}</div>

          <div style="display: flex;align-items: flex-start;margin-bottom:36px">
            <div class="gf-estimated-currency-symbol">$</div>
            <div class="gf-estimated-price">{{estimatedTotalPrice}}</div>
          </div>



        </div>
        <div class="gf-estimate-section" *ngIf="estimatedTotalPrice>0">
          <div class="gf-estimate-text">{{'PROFILE_PAGE.PRICE_INCLUDES' | i18nTranslate}}</div>
          <!-- <div class="gf-price-includes-value" *ngIf="estimatedCreditAmount.contact>0">
            <div class="gf-info-done">
              <mat-icon class="gf-tick-mark">done</mat-icon>
            </div>
            <div>{{resultCredit.contact}} contacts add on</div>
          </div> -->
          <div class="gf-price-includes-value" *ngIf="estimatedCreditAmount.email>0">
            <div class="gf-info-done">
              <mat-icon class="gf-tick-mark">done</mat-icon>
            </div>
            <div>{{resultCredit.email*1000}} {{'PROFILE_PAGE.EMAIL_CREDITS_ADD_ON' | i18nTranslate}}</div>
          </div>
          <div class="gf-price-includes-value" *ngIf="estimatedCreditAmount.sms>0">
            <div class="gf-info-done">
              <mat-icon class="gf-tick-mark">done</mat-icon>
            </div>
            <div>{{resultCredit.sms*100}} {{'PROFILE_PAGE.SMS_CREDITS_ADD_ON' | i18nTranslate}}</div>
          </div>
        </div>
        <div style="    width: 33.3%;"></div>
      </div>

      <div class="primary-button gf-continue-pay" (click)="updateTopup()"
        *ngIf="estimatedTotalPrice>0 &&!isContinuePaymentLoading"> {{'PROFILE_PAGE.CONTINUE_TO_PAY' | i18nTranslate}}</div>
      <div class="primary-button gf-continue-pay" *ngIf="isContinuePaymentLoading">
        <mat-spinner diameter="12"></mat-spinner>
      </div>
    </div>
  </div>
</div>