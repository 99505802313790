export const fontList = [
  {
    name: "Roboto",
    fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Roboto",
  },
  {
    name: "Do Hyeon",
    fontFamily: "'Do Hyeon', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Lora&display=swap",
  },
  {
    name: "Pacifico",
    fontFamily: "'Pacifico', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Pacifico&display=swap",
  },
  {
    name: "Eurostile",
    url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/fonts/Eurostile.css",
    fontFamily: "'Eurostile',sans-serif",
  },
  {
    name: "Eurostile Extended 2",
    url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/fonts/eurostile-extended2.css",
    fontFamily: "'Eurostile Extended 2',sans-serif",
  },
  {
    name: "A Love of Thunder",
    fontFamily: "'A Love of Thunder', sans-serif",
    url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/fonts/Alovethuder.css",
  },
  {
    name: "Futura",
    fontFamily: "'Futura', sans-serif",
    url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/fonts/futura.css",
  },
  {
    name: "Open Sans",
    fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Open+Sans",
  },
  {
    name: "Montserrat",
    fontFamily: "'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Montserrat",
  },
  {
    name: "Raleway",
    fontFamily: "'Raleway', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Raleway",
  },
  {
    name: "Merriweather",
    fontFamily: "'Merriweather', 'Times New Roman', Times, serif",
    url: "https://fonts.googleapis.com/css?family=Merriweather",
  },
  {
    name: "Oswald",
    fontFamily: "'Oswald', 'Arial Narrow', Arial, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Oswald",
  },
  {
    name: "Lora",
    fontFamily: "'Lora', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Lora",
  },
  {
    name: "PT Sans",
    fontFamily: "'PT Sans', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=PT+Sans",
  },
  {
    name: "Playfair Display",
    fontFamily: "'Playfair Display', 'Times New Roman', Times, serif",
    url: "https://fonts.googleapis.com/css?family=Playfair+Display",
  },
  {
    name: "Noto Sans",
    fontFamily: "'Noto Sans', Arial, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Noto+Sans",
  },
  {
    name: "Slabo 27px",
    fontFamily: "'Slabo 27px', 'Times New Roman', Times, serif",
    url: "https://fonts.googleapis.com/css?family=Slabo+27px",
  },
  {
    name: "Ubuntu",
    fontFamily: "'Ubuntu', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Ubuntu",
  },
  {
    name: "Dancing Script",
    fontFamily: "'Dancing Script', cursive",
    url: "https://fonts.googleapis.com/css?family=Dancing+Script",
  },
  {
    name: "Titillium Web",
    fontFamily: "'Titillium Web', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Titillium+Web",
  },
  {
    name: "Cabin",
    fontFamily: "'Cabin', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Cabin",
  },
  {
    name: "Bitter",
    fontFamily: "'Bitter', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Bitter",
  },
  {
    name: "Comic Sans",
    fontFamily: "'Comic Sans MS', cursive, sans-serif",
  },
  {
    name: "Lobster",
    fontFamily: "'Lobster', Georgia, Times, serif",
    url: "https://fonts.googleapis.com/css?family=Lobster",
  },
  {
    name: "Alegreya",
    fontFamily: "'Alegreya', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Alegreya",
  },
  {
    name: "Arvo",
    fontFamily: "'Arvo', 'Courier New', Courier, monospace",
    url: "https://fonts.googleapis.com/css?family=Arvo",
  },
  {
    name: "Baloo",
    fontFamily: "'Baloo', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Baloo",
  },
  {
    name: "Bangers",
    fontFamily: "'Bangers', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Bangers",
  },
  {
    name: "Bebas Neue",
    fontFamily: "'Bebas Neue', 'Arial Black', Gadget, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Bebas+Neue",
  },
  {
    name: "Cairo",
    fontFamily: "'Cairo', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Cairo",
  },
  {
    name: "Caveat",
    fontFamily: "'Caveat', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Caveat",
  },
  {
    name: "Chewy",
    fontFamily: "'Chewy', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Chewy",
  },
  {
    name: "Cinzel",
    fontFamily: "'Cinzel', 'Times New Roman', Times, serif",
    url: "https://fonts.googleapis.com/css?family=Cinzel",
  },
  {
    name: "Courgette",
    fontFamily: "'Courgette', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Courgette",
  },
  {
    name: "Crimson Text",
    fontFamily: "'Crimson Text', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Crimson+Text",
  },
  {
    name: "Fjalla One",
    fontFamily: "'Fjalla One', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Fjalla+One",
  },
  {
    name: "Fira Sans",
    fontFamily: "'Fira Sans', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Fira+Sans",
  },
  {
    name: "Gloria Hallelujah",
    fontFamily: "'Gloria Hallelujah', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Gloria+Hallelujah",
  },
  {
    name: "Indie Flower",
    fontFamily: "'Indie Flower', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Indie+Flower",
  },
  {
    name: "Josefin Sans",
    fontFamily: "'Josefin Sans', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Josefin+Sans",
  },
  {
    name: "Kalam",
    fontFamily: "'Kalam', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Kalam",
  },
  {
    name: "Karla",
    fontFamily: "'Karla', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Karla",
  },
  {
    name: "Libre Baskerville",
    fontFamily: "'Libre Baskerville', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Libre+Baskerville",
  },
  {
    name: "Lobster Two",
    fontFamily: "'Lobster Two', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lobster+Two",
  },
  {
    name: "Merriweather Sans",
    fontFamily: "'Merriweather Sans', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Merriweather+Sans",
  },
  {
    name: "Mukta",
    fontFamily: "'Mukta', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Mukta",
  },
  {
    name: "Nanum Gothic",
    fontFamily: "'Nanum Gothic', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Nanum+Gothic",
  },
  {
    name: "Noto Serif",
    fontFamily: "'Noto Serif', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Noto+Serif",
  },
  {
    name: "Oxygen",
    fontFamily: "'Oxygen', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Oxygen",
  },
  {
    name: "Patua One",
    fontFamily: "'Patua One', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Patua+One",
  },
  {
    name: "Play",
    fontFamily: "'Play', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Play",
  },
  {
    name: "Poppins",
    fontFamily: "'Poppins', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Poppins",
  },
  {
    name: "Quicksand",
    fontFamily: "'Quicksand', 'Lucida Grande', Tahoma, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Quicksand",
  },
  {
    name: "Righteous",
    fontFamily: "'Righteous', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Righteous",
  },
  {
    name: "Russo One",
    fontFamily: "'Russo One', 'Arial Black', Gadget, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Russo+One",
  },
  {
    name: "Satisfy",
    fontFamily: "'Satisfy', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Satisfy",
  },
  {
    name: "Shadows Into Light",
    fontFamily: "'Shadows Into Light', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Shadows+Into+Light",
  },
  {
    name: "Source Code Pro",
    fontFamily: "'Source Code Pro', 'Courier New', Courier, monospace",
    url: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
  },
  {
    name: "Source Sans Pro",
    fontFamily: "'Source Sans Pro', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro",
  },
  {
    name: "Spectral",
    fontFamily: "'Spectral', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Spectral",
  },
  {
    name: "Squada One",
    fontFamily: "'Squada One', 'Arial Black', Gadget, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Squada+One",
  },
  {
    name: "Syncopate",
    fontFamily: "'Syncopate', 'Arial Black', Gadget, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Syncopate",
  },
  {
    name: "Tangerine",
    fontFamily: "'Tangerine', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Tangerine",
  },
  {
    name: "Ubuntu Condensed",
    fontFamily:
      "'Ubuntu Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Ubuntu+Condensed",
  },
  {
    name: "Varela Round",
    fontFamily: "'Varela Round', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Varela+Round",
  },
  {
    name: "Vollkorn",
    fontFamily: "'Vollkorn', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Vollkorn",
  },
  {
    name: "Work Sans",
    fontFamily: "'Work Sans', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Work+Sans",
  },
  {
    name: "Yanone Kaffeesatz",
    fontFamily: "'Yanone Kaffeesatz', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz",
  },
  {
    name: "Yellowtail",
    fontFamily: "'Yellowtail', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Yellowtail",
  },
  {
    name: "Zilla Slab",
    fontFamily: "'Zilla Slab', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Zilla+Slab",
  },
  {
    name: "Anton",
    fontFamily: "'Anton', 'Arial Black', Gadget, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Anton",
  },
  {
    name: "Barlow",
    fontFamily: "'Barlow', 'Arial', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Barlow",
  },
  {
    name: "Concert One",
    fontFamily: "'Concert One', 'Comic Sans MS', cursive, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Concert+One",
  },
  {
    name: "Crimson Pro",
    fontFamily: "'Crimson Pro', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Crimson+Pro",
  },
  {
    name: "Domine",
    fontFamily: "'Domine', Georgia, serif",
    url: "https://fonts.googleapis.com/css?family=Domine",
  },
  {
    name: "Acme",
    fontFamily: "'Acme', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Acme",
  },
  {
    name: "Alfa Slab One",
    fontFamily: "'Alfa Slab One', cursive",
    url: "https://fonts.googleapis.com/css?family=Alfa+Slab+One",
  },
  {
    name: "Amatic SC",
    fontFamily: "'Amatic SC', cursive",
    url: "https://fonts.googleapis.com/css?family=Amatic+SC",
  },
  {
    name: "Archivo",
    fontFamily: "'Archivo', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Archivo",
  },
  {
    name: "Arimo",
    fontFamily: "'Arimo', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Arimo",
  },
  {
    name: "Assistant",
    fontFamily: "'Assistant', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Assistant",
  },
  {
    name: "Bai Jamjuree",
    fontFamily: "'Bai Jamjuree', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Bai+Jamjuree",
  },
  {
    name: "Bitter",
    fontFamily: "'Bitter', serif",
    url: "https://fonts.googleapis.com/css?family=Bitter",
  },
  {
    name: "Bree Serif",
    fontFamily: "'Bree Serif', serif",
    url: "https://fonts.googleapis.com/css?family=Bree+Serif",
  },
  {
    name: "Caveat Brush",
    fontFamily: "'Caveat Brush', cursive",
    url: "https://fonts.googleapis.com/css?family=Caveat+Brush",
  },
  {
    name: "Chivo",
    fontFamily: "'Chivo', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Chivo",
  },
  {
    name: "Cormorant",
    fontFamily: "'Cormorant', serif",
    url: "https://fonts.googleapis.com/css?family=Cormorant",
  },
  {
    name: "EB Garamond",
    fontFamily: "'EB Garamond', serif",
    url: "https://fonts.googleapis.com/css?family=EB+Garamond",
  },
  {
    name: "Exo",
    fontFamily: "'Exo', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Exo",
  },
  {
    name: "Fjalla One",
    fontFamily: "'Fjalla One', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Fjalla+One",
  },
  {
    name: "Great Vibes",
    fontFamily: "'Great Vibes', cursive",
    url: "https://fonts.googleapis.com/css?family=Great+Vibes",
  },
  {
    name: "Heebo",
    fontFamily: "'Heebo', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Heebo",
  },
  {
    name: "Hind",
    fontFamily: "'Hind', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Hind",
  },
  {
    name: "Inconsolata",
    fontFamily: "'Inconsolata', monospace",
    url: "https://fonts.googleapis.com/css?family=Inconsolata",
  },
  {
    name: "Josefin Slab",
    fontFamily: "'Josefin Slab', serif",
    url: "https://fonts.googleapis.com/css?family=Josefin+Slab",
  },
  {
    name: "Lato",
    fontFamily: "'Lato', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lato",
  },
  {
    name: "Maven Pro",
    fontFamily: "'Maven Pro', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Maven+Pro",
  },
  {
    name: "Mukta Vaani",
    fontFamily: "'Mukta Vaani', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Mukta+Vaani",
  },
  {
    name: "Neuton",
    fontFamily: "'Neuton', serif",
    url: "https://fonts.googleapis.com/css?family=Neuton",
  },
  {
    name: "Orbitron",
    fontFamily: "'Orbitron', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Orbitron",
  },
  {
    name: "fuzzy-bubbles",
    fontFamily: "'fuzzy-bubbles', sans-serif;",
    url: "https://use.typekit.net/geb3osc.css"
  },
  {
    name: "Nanum Gothic Coding",
    fontFamily: "'Nanum Gothic Coding', monospace",
    url: "https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap",
  },
  {
    name: "Marcellus",
    fontFamily: "'Marcellus', serif",
    url: "https://fonts.googleapis.com/css2?family=Marcellus&display=swap",
  },
  {
    name: "Georgia",
    fontFamily: "'Georgia', serif",
    url: ""
  },
  {
    name: "Merriweather",
    fontFamily: "'Merriweather', serif",
    url: "https://fonts.googleapis.com/css2?family=Merriweather&display=swap",
  },
  {
    name: "Libre Baskerville",
    fontFamily: "'Libre Baskerville', serif",
    url: "https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap",
  }
];
