import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-select',
  templateUrl: './template-select.component.html',
  styleUrls: ['./template-select.component.css']
})
export class TemplateSelectComponent implements OnInit {


  selectedTemplate;
  constructor(public dialogRef: MatDialogRef<TemplateSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private tokenStorage: TokenStorageService) { }

    s3BucketUrl=environment.s3BucketUrl;
  ngOnInit() {

  }
  
  selectTemplate(index) {
    this.selectedTemplate = index;
  }

  closeDialog(i){

    if(i){
      this.dialogRef.close(this.data[this.selectedTemplate]);

    }
    else{
      this.dialogRef.close(0);

    }
  }
}
