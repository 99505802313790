<div class="qb-padding " style="height: 100%; overflow: auto;">
    <div class="qb-schedule-bold-text">
        <span>{{'CAMPAIGN_PAGE.SELECT_CHANNEL' | i18nTranslate}}</span
        ><span
          class="qb-i-div-schedule-offer"
          popoverClass="qb-my-custom-class"
          placement="bottom"
          triggers="mouseenter:mouseleave"
        >
          i
        </span>
      </div>
      <div
      class="qb-form-div-schedule qb-full-width cursor-pointer"
      (click)="selectopenclose()"
    >
      <mat-select
        #channelSelect
        (selectionChange)="changeSelection()"
        class="qb-font-exo2"
        color="primary"
        placeholder="Select channel"
        formControlName="dispatchcustomerSegments"
        multiple
        disableOptionCentering
        panelClass="myPanelClass"
      >
        <!-- <div class="qb-rectangle">
          <div class="qb-rect">
            <mat-icon class="qb-icon" matPrefix>search </mat-icon>
            <input
              class="qb-no-border-input"
              type="text"
              formControlName="tempsegmentFilter"
              placeholder=" Search by name "
            />
          </div>
        </div> -->
        <div class="qb-mat-select-dropdowns">
          <mat-option
            class="qb-font-exo2"
            value="email"
          >
            {{'CAMPAIGN_PAGE.EMAIL' | i18nTranslate}}
          </mat-option>
          <mat-option
          class="qb-font-exo2"
          value="sms"
          *ngIf="(channelDetails['sms'])"
        >
          {{'CAMPAIGN_PAGE.SMS' | i18nTranslate}}
        </mat-option>
        <mat-option
        class="qb-font-exo2"
        value="whatsapp"
        *ngIf="channelDetails['whatsapp'].length>0 && validWidgets | widgetConfig: 47"
      >
        {{'CAMPAIGN_PAGE.WHATSAPP' | i18nTranslate}}
      </mat-option>
        </div>
      </mat-select>
      <div
        class="qb-keyboard-down-icon-container qb-rotate cursor-pointer"
        id="keyboard-down-icon-container"
        (click)="selectopenclose()"
      >
        <mat-icon
          class="qb-keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
          role="img"
          aria-hidden="true"
          >keyboard_arrow_down</mat-icon
        >
      </div>
    </div>
    <em class="qb-error" *ngIf="submit && selectedChannel.length === 0">{{'CAMPAIGN_PAGE.SELECT_ATLEAST_ONE_CHANNEL' | i18nTranslate}}</em>
    <div *ngIf="selectedChannel.indexOf('email') !== -1">
        <div>
            <div class="qb-email-div">{{'CAMPAIGN_PAGE.EMAIL_SUBJECT' | i18nTranslate}}</div>
            <div class="qb-offer-text-div">
              <input type="text" [(ngModel)]="emailSubject" />
            </div>
            <em class="qb-error" *ngIf="submit && emailSubject.length === 0">{{'CAMPAIGN_PAGE.EMAIL_SUBJECT_CANNOT_EMPTY' | i18nTranslate}}</em>
            <div style="display: flex; justify-content: space-between; width: 100%;">
                <div style="width: 50%; margin-top: 30px;">{{'CAMPAIGN_PAGE.EMAIL_TEMPLATE' | i18nTranslate}}</div>
                <div class="qb-attach-div" style="width: 50%;" (click)="opensnapshotHtmlDialog()">
                  <div class="material-icons-outlined qb-attach-icon">
                    attach_file
                  </div>
                  <div class="qb-form-email">{{ templateName }}</div>
                </div>

            </div>
          </div>
    </div>
    <div class="qb-seperator" *ngIf="selectedChannel.length > 0"></div>
    <div style="display: flex; width: 100%;" *ngIf="selectedChannel.indexOf('sms') !== -1">
        <div style="width: 50%; margin-top: 30px;">{{'CAMPAIGN_PAGE.SMS_TEMPLATE' | i18nTranslate}}</div>
        <div class="qb-attach-div" style="width: 50%;" (click)="opensmsPreview()">
          <div class="material-icons-outlined qb-attach-icon">
            attach_file
          </div>
          <div class="qb-form-email">{{ templateNameSMS }}</div>
        </div>
    </div>
    <div
    class="qb-show-warning"
    *ngIf="selectedChannel.indexOf('sms') !== -1"
  >
    <div class="qb-width7">
      <span
        class="qb-i-div-schedule-offer2"
        popoverClass="qb-my-custom-class"
        placement="bottom"
      >
        i
      </span>
    </div>
    <div class="qb-width93">
      <span class="qb-sms-caution"
        >Telecom regulatory authorities in your region have prohibited
        sending promo SMSes between 08:55 PM to 10 AM local time.
      </span>
    </div>
  </div>
    <div class="qb-seperator" *ngIf="selectedChannel.length > 0"></div>
    <div style="display: flex; width: 100%;" *ngIf="selectedChannel.indexOf('whatsapp') !== -1">
        <div style="width: 50%; margin-top: 30px;">{{'CAMPAIGN_PAGE.WHATSAPP_TEMPLATE' | i18nTranslate}}</div>
        <div class="qb-attach-div" style="width: 50%;" (click)="openWhatsAppPreview()">
          <div class="material-icons-outlined qb-attach-icon">
            attach_file
          </div>
          <div class="qb-form-email">{{ templateNameWhatsApp }}</div>
        </div>
    </div>
    <div class="qb-seperator qb-line" *ngIf="selectedChannel.length > 0"></div>
</div>