import { Component, OnInit } from "@angular/core";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SocialMediaConnectChannelDialogComponent, SocialMediaPagesSelectDialogComponent } from "src/app/shared/components/dialog-box";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-social-media-insights",
  templateUrl: "./social-media-insights.component.html",
  styleUrls: ["./social-media-insights.component.css"],
})
export class SocialMediaInsightsComponent implements OnInit {
  constructor(private _apicall: ApiCallService,   public _dialog: MatDialog) {}

  fbAccounts = [];
  selectedTab = 'facebook';
  selectedAccount = null;
  audienceSummary = [];
  postEngagementSummary = [];
  performanceSummary = [];
  impressionSummary = [];
  demographicsSummary = [];
  demographicsDataReceived = false;
  insightsSummary=null;
  feedbackgraphData=[];
  totalFeedbackgraphData=0;
  totalReachGrapghData =0;
 reachGrapghData=[];
 loading=false;
  isNoAccountDetected=false;

  ngOnInit() {
    this.initInsights();
  }

  initInsights() {
    this.getaccounts();
  }
  getaccounts() {
    this.loading=true;
    this._apicall.getSocialMdiaUsersList().subscribe((response) => {
      this.fbAccounts = this.getChannelDimensionsByFilter(
        response["body"],
        this.selectedTab
      );
      console.log(this.fbAccounts);
      if(this.fbAccounts.length>0){
        this.selectAccount(this.fbAccounts[0]); 
      }else{
        this.isNoAccountDetected=true;
        this.loading=false;
      }
    });
  }

  getChannelDimensionsByFilter(userList, channel) {
    if(this.selectedTab === 'instagram') {
    let tempList = userList;
    userList = [];
    tempList.forEach(element => {
      if(element.instagramProfile !== null)
      userList.push(element.instagramProfile);
    });
  }
    return userList;
  }
  selectAccount(account) {
    if(this.selectedTab === 'facebook') {
    this.selectedAccount = account.userId;
    this.getAllPageData(account.userId);
    } else {
      this.selectedAccount = account.id;
    this.getAllPageData(account.id);
    }
  }

  getAllPageData(userId) {
    this.loading=true;
    let data = {
      userId: userId,
      channel: this.selectedTab,
      timePeriod: "month",
      startDate: "2022-04-01",
      endDate: "2022-06-20",
    };
    this._apicall.getPageAllAnalytics(data).subscribe(
      (response) => {
        console.log(JSON.parse(response['body']));
        if(this.selectedTab === 'facebook')
        this.setFBAnalytics(JSON.parse(response["body"]));
        else
        this.setInstaAnalytics(JSON.parse(response['body']));
      },
      (error) => {
        this.loading=false;
      }
    );
  }

  setInstaAnalytics(analytics) {
    console.log(analytics);
    let audienceSummaryTemp = {
      total_page_likes: null,
      new_likes: null,
      new_likes_avg: null,
      total_post: null,
      total_engagement: null,
      total_engagement_avg: 0,
      page_profile_view: null,
      page_profile_view_avg: null,
      page_clicks: null,
      page_clicks_avg: null,
      page_impression: null,
      page_impression_avg: null,
      page_reach: null,
      page_reach_avg: null,
    };

    console.log(analytics.new);

    audienceSummaryTemp.total_page_likes = analytics.TotalFollowers;
    if(analytics.NewFollowers !== undefined) {
      audienceSummaryTemp.new_likes = analytics.NewFollowers;
      audienceSummaryTemp.new_likes_avg = analytics.NewFollowers/30;
    } else {
      audienceSummaryTemp.new_likes = 'NA';
      audienceSummaryTemp.new_likes_avg = 'NA';
    }
    audienceSummaryTemp.total_post = analytics.TotalPost;
    audienceSummaryTemp.total_engagement = analytics.impressions;
    audienceSummaryTemp.page_profile_view = analytics.profile_views;
    audienceSummaryTemp.page_clicks = analytics.impressions;
    audienceSummaryTemp.page_impression = analytics.impressions;
    audienceSummaryTemp.page_reach = analytics.reach;
    
    audienceSummaryTemp.total_engagement_avg=Math.round((analytics.impressions/30 + Number.EPSILON) * 100) / 100;
    audienceSummaryTemp.page_profile_view_avg = Math.round((analytics.profile_views/30 + Number.EPSILON) * 100) / 100;
    audienceSummaryTemp.page_clicks_avg = Math.round((analytics.impressions/30 + Number.EPSILON) * 100) / 100;
    audienceSummaryTemp.page_impression_avg = Math.round((analytics.impressions/30 + Number.EPSILON) * 100) / 100;
    audienceSummaryTemp.page_reach_avg = Math.round((analytics.reach/30 + Number.EPSILON) * 100) / 100;

   
    
    this.insightsSummary=audienceSummaryTemp;




console.log(this.insightsSummary);

this.loading = false;
  }

  setFBAnalytics(analytics) {
    console.log(analytics);
    let audienceSummaryTemp = {
      total_page_likes: null,
      new_likes: null,
      new_likes_avg: null,
      likes_lost: null,
      likes_lost_avg: null,
      total_post: null,
      // total_post_avg: null,
      total_engagement: null,
      total_engagement_avg: 0,
      page_profile_view: null,
      page_profile_view_avg: null,
      page_clicks: null,
      page_clicks_avg: null,
      positive_feedback_perc: null,
      negative_feedback_perc: null,
      page_impression: null,
      page_impression_avg: null,
      page_reach: null,
      page_reach_avg: null,
      organic_reach: null,
      paid_reach: null,
    };

    let insightMetricsData = analytics.insights?analytics.insights.data:[];


    let new_likes_ValueObj = insightMetricsData.find(
      (opt) => opt.name == "page_fan_adds_unique" && opt.period == "day"
    );
    let likes_lost_uniqueObj = insightMetricsData.find(
      (opt) => opt.name == "page_fan_removes" && opt.period == "day"
    );
    let page_views_total_valueObj= insightMetricsData.find(
      (opt) => opt.name == "page_views_total" && opt.period == "day"
    );
    let page_engaged_users_valueObj= insightMetricsData.find(
      (opt) => opt.name == "page_engaged_users" && opt.period == "day"
    );

    let negative_feedback_ValueObj = insightMetricsData.find(
      (opt) => opt.name == "page_negative_feedback_unique" && opt.period == "day" && opt.period == "day"  
    );
    
    let positive_feedback_ValueObj = insightMetricsData.find(
      (opt) => opt.name == "page_positive_feedback_by_type_unique" && opt.period == "day"
    );
    let page_impressions_valueObj = insightMetricsData.find(
      (opt) => opt.name == "page_impressions_unique" && opt.period == "day"
    );
    let organic_reach_valueObj = insightMetricsData.find(
      (opt) => opt.name == "page_impressions_organic_unique_v2" && opt.period == "day"
    );
    let paid_reach_valueObj = insightMetricsData.find(
      (opt) => opt.name == "page_impressions_paid_unique" && opt.period == "day"
    );


    let page_fan_adds_uniqueObjSumAndAvg = this.getSumAndAverage(new_likes_ValueObj.values);
let likes_lost_uniqueObjSumAndAvg = this.getSumAndAverage(likes_lost_uniqueObj.values);
let page_views_total_valueObjSumAndAvg = this.getSumAndAverage(page_views_total_valueObj.values);
let page_engaged_users_valueObjSumAndAvg = this.getSumAndAverage(page_engaged_users_valueObj.values);
let negative_feedback_ValueObjSumAndAvg = this.getSumAndAverage(negative_feedback_ValueObj.values);
let positive_feedback_ValueObjSumAndAvg = this.getSumAndAverageFromType(positive_feedback_ValueObj.values);
let page_impressions_valueObjSumAndAvg = this.getSumAndAverage(page_impressions_valueObj.values);
let organic_reach_valueObjSumAndAvg = this.getSumAndAverage(organic_reach_valueObj.values);
let paid_reach_valueObjSumAndAvg = this.getSumAndAverage(paid_reach_valueObj.values);
    
    
    console.log(positive_feedback_ValueObjSumAndAvg);
    console.log(negative_feedback_ValueObjSumAndAvg);
    audienceSummaryTemp.total_page_likes = analytics.fan_count;
    audienceSummaryTemp.new_likes = page_fan_adds_uniqueObjSumAndAvg.total;
    audienceSummaryTemp.new_likes_avg = page_fan_adds_uniqueObjSumAndAvg.average;
    audienceSummaryTemp.likes_lost = likes_lost_uniqueObjSumAndAvg.total;
    audienceSummaryTemp.likes_lost_avg = likes_lost_uniqueObjSumAndAvg.average;
    audienceSummaryTemp.total_post = analytics.published_posts.summary.total_count;
    audienceSummaryTemp.total_engagement = page_impressions_valueObjSumAndAvg.total;
    audienceSummaryTemp.total_engagement_avg=page_impressions_valueObjSumAndAvg.average;
    audienceSummaryTemp.page_profile_view = page_views_total_valueObjSumAndAvg.total;
    audienceSummaryTemp.page_profile_view_avg = page_views_total_valueObjSumAndAvg.average;
    audienceSummaryTemp.page_clicks = page_engaged_users_valueObjSumAndAvg.total;
    audienceSummaryTemp.page_clicks_avg = page_engaged_users_valueObjSumAndAvg.average;
    audienceSummaryTemp.positive_feedback_perc = (positive_feedback_ValueObjSumAndAvg.total + negative_feedback_ValueObjSumAndAvg.total)!=0?(positive_feedback_ValueObjSumAndAvg.total / (positive_feedback_ValueObjSumAndAvg.total + negative_feedback_ValueObjSumAndAvg.total)) * 100:0;
    audienceSummaryTemp.negative_feedback_perc = (positive_feedback_ValueObjSumAndAvg.total + negative_feedback_ValueObjSumAndAvg.total)!=0?(negative_feedback_ValueObjSumAndAvg.total / (positive_feedback_ValueObjSumAndAvg.total + negative_feedback_ValueObjSumAndAvg.total)) * 100:0;
    audienceSummaryTemp.page_impression = page_impressions_valueObjSumAndAvg.total;
    audienceSummaryTemp.page_impression_avg = page_impressions_valueObjSumAndAvg.average;
    audienceSummaryTemp.page_reach = page_impressions_valueObjSumAndAvg.total;
    audienceSummaryTemp.page_reach_avg = page_impressions_valueObjSumAndAvg.average;
    audienceSummaryTemp.organic_reach = organic_reach_valueObjSumAndAvg.total;
    audienceSummaryTemp.paid_reach = paid_reach_valueObjSumAndAvg.total;

   
    let p ={
      name: "Positive",
    //  count: positive_feedback_ValueObjSumAndAvg.total ,
    count:4,
      color: "#FE958F",
    }
    let n={
      name: "Negative",
      // count: negative_feedback_ValueObjSumAndAvg.total ,
      count:2,
      color: "#435AC2",
    }
    this.feedbackgraphData.push(p);
    this.feedbackgraphData.push(n);
     // this.totalFeedbackgraphData=positive_feedback_ValueObjSumAndAvg.total+negative_feedback_ValueObjSumAndAvg.total;
     this.totalFeedbackgraphData=6;


     let o={
      name: "Organic",
      //  count: organic_reach_valueObjSumAndAvg.total;,
      count:4,
        color: "#FE958F",
     }
     let d={
      name: "Paid",
      // count:paid_reach_valueObjSumAndAvg.total;
      count:1,
      color: "#435AC2",
     }
     this.reachGrapghData.push(o);
     this.reachGrapghData.push(d);
      this.totalReachGrapghData=5;


    if(analytics.fan_count>200){
      this.demographicsDataReceived=true;
    }
    this.insightsSummary=audienceSummaryTemp;




console.log(this.insightsSummary);

this.loading = false;
  }

  getSumAndAverage(data) {
   
    let sum = 0;
    let average = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].value;
    }
    average = sum / data.length;
    let obj = {
      total: sum,
      average: average,
    };
   
    return obj;
  }
  getSumAndAverageFromType(data){
    let sum = 0;
    let average = 0;
    for (let i = 0; i < data.length; i++) {
    let totalInObj=0;
      let
      add = object => Object
          .values(data[i].value)
          .reduce((s, v) => s + (v && typeof v === 'object' ? add(v) : v), 0);
     console.log(add(data[i].value));
    
      totalInObj = add(data[i].value);
      sum += totalInObj;
    }
    average = sum / data.length;
    let obj = {
      total: sum,
      average: average,
    };
    console.log(average);
    return obj;

  }
  redirectForFbOauth(){
    let url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=`+environment.socialFBAppId+`&redirect_uri=
    `+window.location.protocol +
    "//" +
    environment.Origin +`/app/social-eng?channel=` + this.selectedTab + `&state=false:null&display=popup&scope=`+environment.fbScope;
        window.open(url,"_self");
  }

  openConnectSocialProfilesDialog() {
    const dialogref = this._dialog.open(
      SocialMediaConnectChannelDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        disableClose: true,
        width: "50%",
        data: {
          connectAds: false
        }
        
      }
    );

    dialogref.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      //  this.authenticateFB();
      this.redirectForFbOauth(); // this is for redirecting to facebook oauth
      }
    });
  }
}
