<div class="header">
  <div>{{'SHARED_PAGE.DATA_FETCH_PROCESS' | i18nTranslate}}</div>
  <div class="close-div" (click)="closeDialog()">
    <div class="material-icons-outlined close-icon">close</div>
  </div>
</div>
<div class="text">{{'SHARED_PAGE.DATA_FETCH_PARAGRAPH' | i18nTranslate}}</div>
<div class="text">{{'SHARED_PAGE.DATA_FETCH_PARAGRAPH_TWO' | i18nTranslate}}</div>
<div class="close-button-div" (click)="closeDialog()">
  <div class="primary-button">Close</div>
</div>