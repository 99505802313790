import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from "@angular/material/dialog"; 
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { ContactUsDialogComponent } from '../contact-us-dialog/contact-us-dialog.component';
import { PartnershipInitiateDialogBoxComponent } from '../partnership-initiate-dialog-box/partnership-initiate-dialog-box.component';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-partnership-send-offer-dialogbox',
  templateUrl: './partnership-send-offer-dialogbox.component.html',
  styleUrls: ['./partnership-send-offer-dialogbox.component.css']
})
export class PartnershipSendOfferDialogboxComponent implements OnInit {

  constructor(
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: SnackbarCollection,
    private router: Router,
    private dialogRef: MatDialogRef<PartnershipInitiateDialogBoxComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  offersList = [];
  length;
  offerName = "";
  offerId = -1;
  pageNumber = 1;
  pageSize = 10;
  name;

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 5
      ) {
        this.pageNumber += 1;
        this.getOfferList();
      }
  }

  ngOnInit() {
    this.offerId = this.data.offerId;
    this.activatedRoute.params.subscribe((param) => {
      this.name = param.type
    });
    this.getOfferList();
  }
  continue(decision) {
    this.dialogRef.close(decision);
    this.router.navigate([
      '/app/partnership/my-offers/new-offer',
      {
        id: this.data.id,
        name: this.data.name,
        send: true,
        sendOfferAgain: this.data.sendOfferAgain
      },
    ]);
  }
  submit(decision) {
    this.dialogRef.close(decision);
  }
  getOfferList() {
    if(this.length != this.offersList.length)
    this.apiCall.getPOffers().subscribe((response) => {
      JSON.parse(response["body"]).forEach(item => {
        this.offersList.push(item);
        if(item.id === this.data.offerId) {
          this.data.description = item.description;
        }
        console.log(response);
      })
      this.length = JSON.parse(response["body"])['offerCount'];
    });
  }
  Send() {
    if (this.offerId == -1) {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Please select an offer", ['SHARED_PAGE']), 2000);
    } else {
      console.log(this.data)
      this.apiCall
        .processPartnershipRequest(this.data.id, 'OFFER_SENT', null, [this.offerId], null, null, null, this.data.sendOfferAgain)
        .subscribe(
          (response) => {
            if (response["message"] == "success") {
              this.dialogRef.close(true);
              // this.snackBar.openSnackBar("Offer sent", 2000);
            }
          },
          (error) => {
            if (error["error"]["message"] == "failure") {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                width: "380px",
                height: "auto",
                disableClose: true,
                data: {
                  header: this._i18nDynamicTranslate.transform("Failure", ['SHARED_PAGE']),
                  body: this._i18nDynamicTranslate.transform(error["error"]["body"], ['SHARED_PAGE']),
                },
              });
              this.dialogRef.close(false);
            }
          }
        );
    }
  }
  selectList(name, id) {
    this.offerName = name;
    this.offerId = id;
  }

}
