<div class="qb-template-container">
    <div>
        <div class="qb-template-listing-container">
            <div class="qb-template-sub-container">
                <div class="qb-template-card-container">
                    <!-- <div class="qb-template-wrapper">
                        <article>
                            <div class="qb-top">
                                <div class="qb-img-wrapper">
                                    <img src="https://d1oco4z2z1fhwp.cloudfront.net/templates/default/3636_large.jpg" alt="">
                                </div>
                                <div class="qb-overlay">
                                    <span class="qb-overlay-text" style="gap: 10px;">
                                        <button class="primary-button">
                                            Start Designing
                                        </button>
                                        <button class="secondary-button qb-preview-email-template" (click)="startDesigningPage('template1')">
                                            Preview
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div> -->
                    <div class="qb-blank-template" [matTooltip]="isEditorLoaded ? '' : 'Please wait editor is loading'" (click)="!isEditorLoaded ? '' :  startDesigningPage('newTemplate')">
                        <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10.916 7a1.75 1.75 0 0 0-1.75 1.75v24.5c0 .967.784 1.75 1.75 1.75h21a1.75 1.75 0 0 0 1.75-1.75V21a1.75 1.75 0 1 1 3.5 0v12.25c0 2.9-2.35 5.25-5.25 5.25h-21a5.25 5.25 0 0 1-5.25-5.25V8.75c0-2.9 2.35-5.25 5.25-5.25h10.5a1.75 1.75 0 1 1 0 3.5h-10.5Z"
                                fill="#C9B5FF"></path>
                            <path d="m27.728 7.212-2.36 2.362 7.424 7.424 2.361-2.36a5.25 5.25 0 0 0-7.425-7.426Z"
                                fill="#C9B5FF"></path>
                            <path
                                d="m15.19 29.156 5.774-.825a1.75 1.75 0 0 0 .99-.495l10.838-10.838-7.424-7.424-10.839 10.838a1.75 1.75 0 0 0-.495.99l-.825 5.774a1.75 1.75 0 0 0 1.98 1.98Z"
                                fill="#7747FF"></path>
                        </svg>
                        <span>
                            Blank Template
                        </span>
                        <span>
                            Start from scratch
                        </span>
                    </div>
                </div>
            </div>
            <div class="qb-template-sub-container">
                <div class="qb-template-card-container">
                    <div class="qb-template-wrapper">
                        <article>
                            <div class="qb-top">
                                <div class="qb-img-wrapper">
                                    <img src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/sample+email+template+img/sampletemplate1.png"
                                        alt="" class="custom-img">
                                </div>
                                <div class="qb-overlay">
                                    <span class="qb-overlay-text" style="gap: 10px;">
                                        <button class="primary-button" [matTooltip]="isEditorLoaded ? '' : 'Please wait editor is loading'" [disabled]="!isEditorLoaded" (click)="startDesigningPage('T1')">
                                            Start Designing
                                        </button>
                                        <button class="secondary-button qb-preview-email-template" (click)="previewEmailTemplate('T1')" >
                                            Preview
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div class="qb-template-sub-container">
                <div class="qb-template-card-container">
                    <div class="qb-template-wrapper">
                        <article>
                            <div class="qb-top">
                                <div class="qb-img-wrapper">
                                    <img src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/sample+email+template+img/sampletemplate2.png"
                                        alt="" class="custom-img">
                                </div>
                                <div class="qb-overlay">
                                    <span class="qb-overlay-text" style="gap: 10px;">
                                        <button class="primary-button" [matTooltip]="isEditorLoaded ? '' : 'Please wait editor is loading'" [disabled]="!isEditorLoaded" (click)="startDesigningPage('T2')">
                                            Start Designing
                                        </button>
                                        <button class="secondary-button qb-preview-email-template" (click)="previewEmailTemplate('T2')">
                                            Preview
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div class="qb-template-sub-container">
                <div class="qb-template-card-container">
                    <div class="qb-template-wrapper">
                        <article>
                            <div class="qb-top">
                                <div class="qb-img-wrapper">
                                    <img src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/sample+email+template+img/sampletemplate3.png"
                                        alt="" class="custom-img">
                                </div>
                                <div class="qb-overlay">
                                    <span class="qb-overlay-text" style="gap: 10px;">
                                        <button class="primary-button" [matTooltip]="isEditorLoaded ? '' : 'Please wait editor is loading'" [disabled]="!isEditorLoaded" (click)="startDesigningPage('T3')">
                                            Start Designing
                                        </button>
                                        <button class="secondary-button qb-preview-email-template" (click)="previewEmailTemplate('T3')">
                                            Preview
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div class="qb-template-sub-container">
                <div class="qb-template-card-container">
                    <div class="qb-template-wrapper">
                        <article>
                            <div class="qb-top">
                                <div class="qb-img-wrapper">
                                    <img src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/sample+email+template+img/sampletemplate4.png"
                                        alt="" class="custom-img">
                                </div>
                                <div class="qb-overlay">
                                    <span class="qb-overlay-text" style="gap: 10px;">
                                        <button class="primary-button" [matTooltip]="isEditorLoaded ? '' : 'Please wait editor is loading'" [disabled]="!isEditorLoaded" (click)="startDesigningPage('T4')">
                                            Start Designing
                                        </button>
                                        <button class="secondary-button qb-preview-email-template" (click)="previewEmailTemplate('T4')">
                                            Preview
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>