<div class="qb-body">
  <div class="qb-main-div">
    <div>{{'PARTNERSHIP_PAGE.PARTNER_REWARD_DETAIL' | i18nTranslate}}</div>
    <div class="qb-offer-div">
      <div class="qb-input-label">{{'PARTNERSHIP_PAGE.REWARD_NAME' | i18nTranslate}}</div>
      <div class="qb-input-div">
        <input
          type="text"
          placeholder="Rewardforsale"
          [(ngModel)]="rewardName"
          (input)="rewardNameError = ''"
        />
      </div>
      <div class="qb-error">{{ rewardNameError }}</div>
    </div>
    <div class="qb-discount-label-div">
      <div>
        <div>
          <div class="qb-label-div">
            <div class="qb-input-label">{{'PARTNERSHIP_PAGE.REWARD_VALUE' | i18nTranslate}}</div>
          </div>
          <div class="qb-input-div">
            <input type="text" placeholder="20" [(ngModel)]="rewardValue" />
            <div class="qb-percentage">%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="qb-buttons-div">
      <div
        class="secondary-button-border qb-cancel-button"
        (click)="redirect()"
      >
        {{'PARTNERSHIP_PAGE.CANCEL' | i18nTranslate }}
      </div>
      <div class="primary-button" (click)="save()">{{'PARTNERSHIP_PAGE.SAVE' | i18nTranslate}}</div>
    </div>
  </div>
  <div class="qb-main-div">
    <div>{{'PARTNERSHIP_PAGE.PARTNER_REWARD_SUMMARY' | i18nTranslate}}</div>
    <div class="qb-coupon">{{'PARTNERSHIP_PAGE.NEW_REWARD_CAPS' | i18nTranslate}}</div>
    <div>
      <ul>
        <li class="qb-list-item">
          {{'PARTNERSHIP_PAGE.PARTNER_EARN_REARD_MEESAGE' | i18nTranslate : rewardValue}}
        </li>
      </ul>
    </div>
  </div>
</div>
