import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { assetPath } from "src/app/configs/asset-path-config";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { currentPlanDataService } from "src/app/core/services/current-plan-global.service";
import { Globals } from "src/app/core/services/global.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

@Component({
  selector: "app-payment-confirmation",
  templateUrl: "./payment-confirmation.component.html",
  styleUrls: ["./payment-confirmation.component.css"],
})
export class PaymentConfirmationComponent implements OnInit {
  countStart: number = 8;
  cleanCount;

  imagePath1 = assetPath.path + "/005copy.jpg";
  repeat = "no-repeat";
  message = "bookingconfirmation";
  masterPlan;
  letSelectPlan;
  activeplans;
  planlength;
  planName;
  latestPlan;

  currentPlanBody: any = 0;
  currentPlan;
  username;

  charge_id;
  loading: boolean = true;
  paymentSuccessStatus;
  billingtypeSubscritpion;
  currentPlanLoading = true;
  chargeType;
  confirmationMsgTitle = "Payment Successful";
  confirmationMsgDescription = "";
  subscriptionType = null;
  startDate;
  descmsg = "";
  buttonHomeText = null;
  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private apiCall: ApiCallService,
    private globalService: Globals,
    private _widgetConfigService: WidgetConfigService,
    private currentPlanData: currentPlanDataService,
    private datePipe: DatePipe
  ) {
    this.route.queryParams.subscribe((params) => {
      this.charge_id = params["charge_id"];
      this.chargeType = params["charge-type"];
      this.subscriptionType = params["stype"] ? params["stype"] : null;
    });
  }

  ngOnInit() {
    this.loading = true;
    if (this.subscriptionType) {
      this.charge_id = null;
      this.getPlanTypeStatus(this.charge_id,this.subscriptionType=="MONTHLY"?"MONTHLY_SUBSCRIPTION":this.subscriptionType);
    } else if (this.chargeType == "PARTNERSHIP") {
      this.getActivePartnerShipStatus(this.charge_id);
    }
    else if (this.chargeType == "PARTNERSHIP_BASIC") {
      this.getActivePartnerShipStatusAndActivateBasic(this.charge_id);
    } else if (this.chargeType != "top-up") {
      this.getPlanTypeStatus(this.charge_id,this.subscriptionType);
    } else {
      this.getTopupTypeStatus();
    }

    // this.looper();
  }

  getPlanTypeStatus(chargeId,subscriptionType) {
    // if no charge id-> basic plan else premium plan
    this.apiCall
      .shopifyPaymentStatus(chargeId,subscriptionType)
      .subscribe(
        (result) => {
          this.apiCall.getCurrentPlan().subscribe((response) => {
            this.loading = false;
            if (
              response["message"] === "success" ||
              response["message"] === "SUCCESS"
            ) {
              this.currentPlanBody = response["body"];
              let c = this.currentPlanBody;
              this._widgetConfigService.setIsAppSubscriptionTransistionAllowed(
                c["isEmailVerified"] == false ||
                  (c["isCustomerThresholdReached"] &&
                    (c["planName"] == "Basic" || c["planName"] == "Trial"))
                  ? 0
                  : 1
              ); // set the flag to true;

              this.confirmationMsgTitle =
                this.currentPlanBody.planName != "Basic"
                  ? "Payment Successful"
                  : "Subscription Successful";
              let startDateDD = new Date(this.currentPlanBody.startDate);
              this.startDate = this.datePipe.transform(
                startDateDD.toISOString(),
                "dd MMM yyyy"
              );

              this.descmsg =
                'Thank you for choosing Qubriux. Your payment has been succesfully processed and your selected plan <span style="color: #0183FB;">' +
                this.currentPlanBody.planName +
                "</span> will be active from " +
                this.startDate +
                ".";
              this.buttonHomeText = "Back to Home";
              this.currentPlanData.setCurrentPlanBody(this.currentPlanBody);
              this.paymentSuccessStatus = true;
              console.log(this.currentPlanBody);
            }
          });
        },
        (error) => {
          this.paymentSuccessStatus = false;
          this.loading = false;
        }
      );
  }

  getTopupTypeStatus() {
    // Topup credits
    this.apiCall.getTopupPricingPlan(this.charge_id).subscribe(
      (result) => {
        // if (result["message"] == "success") {

        this.apiCall.getCurrentPlan().subscribe((response) => {
          this.loading = false;
          if (
            response["message"] === "success" ||
            response["message"] === "SUCCESS"
          ) {
            this.currentPlanBody = response["body"];
            let c = this.currentPlanBody;
            this.confirmationMsgTitle = "Top up added successfully";
            this._widgetConfigService.setIsAppSubscriptionTransistionAllowed(
              c["isEmailVerified"] == false ||
                (c["isCustomerThresholdReached"] &&
                  (c["planName"] == "Basic" || c["planName"] == "Trial"))
                ? 0
                : 1
            ); // set the flag to true;
            response["body"]["subscriptionMasterPlanId"];
            this.buttonHomeText = "Back to Home";
            this.currentPlanData.setCurrentPlanBody(this.currentPlanBody);
            this.paymentSuccessStatus = true;
          }
        });
      },
      (error) => {
        this.paymentSuccessStatus = false;
        this.loading = false;
      }
    );
  }

  looper() {
    this.countStart = 8;
    this.cleanCount = setInterval(() => {
      this.countStart--;
      if (this.countStart == 0) {
        this.router.navigate(["../../app-home"], {
          relativeTo: this.route,
          skipLocationChange: false,
          replaceUrl: true,
        });
        clearTimeout(this.cleanCount);
      }
    }, 1000);
  }

  backToHome() {
    if (this.chargeType == "PARTNERSHIP") {
      this.router.navigate(["/app/partnership/my-partners"]);
    } else {
      this.router.navigate(["/app/app-home"], {
        queryParams: { type: "Objectives" },
      });
    }
  }

  addPartnershipConsent() {
    this.loading = true;
    this.apiCall.addPartnershipConsent().subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.tokenStorage.setPartnershipConsentStatus(true);
          this.buttonHomeText = "Back to Partnership";
          this.confirmationMsgTitle = "Payment Successful";
          this.loading = false;
          this.paymentSuccessStatus = true;
       //   this.router.navigate(["/app/partnership"]);
        }
      },
      (error) => {
        this.paymentSuccessStatus = false;
        this.loading = false;
      }
    );
  }

  getActivePartnerShipStatus(charge_id) {
    this.loading = true;
    this.apiCall.activatePartnership(this.charge_id).subscribe(
      (response) => {
        this.addPartnershipConsent();
        this.paymentSuccessStatus = true;
        // if (this.tokenStorage.getBasicDowngradeStatus == charge_id) {
        //   this.tokenStorage.removeBasicDowngradeStatus();
        //   this.getPlanTypeStatus(null);
        // }else{

        // }
      },
      (error) => {
        this.paymentSuccessStatus = false;
        this.loading = false;
      }
    );
  }
  getActivePartnerShipStatusAndActivateBasic(charge_id) {
    this.apiCall.activatePartnership(this.charge_id).subscribe(
      (response) => {
        this.getPlanTypeStatus(null,"MONTHLY_SUBSCRIPTION");
      }, (error) => {
        this.paymentSuccessStatus = false;
        this.loading = false;
      });
  }
}
