<div *ngIf="segment != null && validWidgets && analyticsLoaded" data-widget-name="SEGMENT LISTING" class="main-container">
  <div class="qb-segment-buttons-container">
    <div class="qb-d-flex qb-ai-center width-70">
      <span class="qb-segment-name">{{ segment["segmentName"] }}</span>
      <span class="qb-segment-name qb-m-0-5" *ngIf="onboarding">-</span>
      <span *ngIf="onboarding" class="qb-segment-status-onboard">{{'SEGMENT_PAGE.ACTUA_DATA_ONCE_ONBOARDED' | i18nTranslate}}</span>
      <!-- <div style="display: flex">
        <div class="qb-status-arrow-div">
          <img
            *ngIf="
              segment['customerSegmentTrend'] === 'INCREASING' ||
              segment['customerSegmentTrend'] === 'NO_CHANGE'
            "
            [src]="s3BucketUrl + '/greyfox-assets/segment-assets/icon.svg'"
            alt=""
          />
          <img
            *ngIf="segment['customerSegmentTrend'] === 'DECREASING'"
            [src]="s3BucketUrl + '/greyfox-assets/segment-assets/path.svg'"
            alt=""
          />
        </div>
      </div> -->
    </div>
    <div class="qb-d-flex qb-ai-center">
      <div class="qb-targeting-tactics-icon" *ngIf="segment['segmentType'] == 'STANDARD'">
        <span
        class="qb-button-2"
        *ngIf="appSubscriptionPlan"
        appButtonDebounce
        [throttleTime]="600"
        (throttledClick)=Targeting_Tactics()
        >
        <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
        {{'SEGMENT_PAGE.TARGETING_TACTICS' | i18nTranslate}}
        </span>
        <!-- <span class="qb-with-icon">
          <md-icon>wb_incandescent</md-icon>Targeting Tactics
        </span> -->
      </div>
      <div class="qb-edit-icon-div">
        <span
        class="material-icons-outlined icons-primary qb-segmet-buttons"
          data-button-name="Download"
          [matTooltip] = "'COMMON_PAGE.DOWNLOAD_CUSTOMER_LIST' | i18nTranslate"
          *ngIf="appSubscriptionPlan"
          appButtonDebounce
          [throttleTime]="600"
          (throttledClick)="downloadCustomerInSegment(segment['segmentName'])"
        >
          <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0625 2.8125C10.375 2.03125 9.375 1.5 8.25 1.5C6.15625 1.5 4.5 3.1875 4.5 5.25V5.28125C4.5 5.90625 4.09375 6.46875 3.5 6.6875C2.3125 7.09375 1.5 8.21875 1.5 9.5C1.5 11.1562 2.84375 12.5 4.5 12.5H15.75H15.8438C15.8438 12.5 15.875 12.5 15.9062 12.5C17.3438 12.4375 18.5 11.2188 18.5 9.75C18.5 8.625 17.8125 7.65625 16.8438 7.25C16.2188 6.96875 15.8438 6.3125 15.9688 5.625C15.9688 5.5 16 5.375 16 5.25C16 4.03125 14.9688 3 13.75 3C13.4062 3 13.0938 3.09375 12.8125 3.21875C12.2188 3.46875 11.5 3.3125 11.0625 2.8125ZM16 14H15.75H14.5H4.5C2 14 0 12 0 9.5C0 7.5625 1.25 5.875 3 5.28125V5.25C3 2.375 5.34375 0 8.25 0C9.8125 0 11.25 0.71875 12.2188 1.84375C12.6875 1.625 13.1875 1.5 13.75 1.5C15.8125 1.5 17.5 3.1875 17.5 5.25C17.5 5.46875 17.4688 5.65625 17.4375 5.875C18.9375 6.53125 20 8.03125 20 9.75C20 12.0312 18.2188 13.875 16 14ZM6.96875 8.78125C6.65625 8.5 6.65625 8.03125 6.96875 7.75C7.25 7.4375 7.71875 7.4375 8.03125 7.75L9.25 8.96875V4.75C9.25 4.34375 9.5625 4 10 4C10.4062 4 10.75 4.34375 10.75 4.75V8.96875L11.9688 7.75C12.25 7.4375 12.7188 7.4375 13 7.75C13.3125 8.03125 13.3125 8.5 13 8.78125L10.5 11.2812C10.2188 11.5938 9.75 11.5938 9.46875 11.2812L6.96875 8.78125Z" fill="#4379EF"/>
          </svg>
        </span>
      <!-- <div
        style="display: flex"
        *ngIf="segment['segmentType'] == 'STANDARD'"
        matTooltip="You can not edit a preset segment. You can instead clone and create a new segment."
      >
        <div>
          <span class="qb-material-icons-outlined icons-primary"> edit </span>
        </div>
      </div> -->
    </div>
      <div class="qb-edit-icon-div" *ngIf="validScreens | screenConfig: 40">
        <span
          class="material-icons-outlined icons-primary qb-segmet-buttons"
          data-button-name="Export"
          [attr.data-item-name]="
            '(' + segmentId + ')' + segment['segmentName']
          "
          [matTooltip]="exportTip"
          *ngIf="appSubscriptionPlan && exportStatus === null"
          appButtonDebounce
          [throttleTime]="600"
          (throttledClick)="exportSegment()"
        >
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75 12.25C8.75 12.6875 8.40625 13 8 13C7.5625 13 7.25 12.6875 7.25 12.25V3.5625L4.28125 6.53125C3.96875 6.84375 3.5 6.84375 3.21875 6.53125C2.90625 6.25 2.90625 5.78125 3.21875 5.46875L7.46875 1.21875C7.75 0.9375 8.21875 0.9375 8.5 1.21875L12.7812 5.46875C13.0625 5.78125 13.0625 6.25 12.7812 6.53125C12.4688 6.84375 12 6.84375 11.7188 6.53125L8.75 3.5625V12.25ZM9.75 12V10.5H14C15.0938 10.5 16 11.4062 16 12.5V15C16 16.125 15.0938 17 14 17H2C0.875 17 0 16.125 0 15V12.5C0 11.4062 0.875 10.5 2 10.5H6.25V12H2C1.71875 12 1.5 12.25 1.5 12.5V15C1.5 15.2812 1.71875 15.5 2 15.5H14C14.25 15.5 14.5 15.2812 14.5 15V12.5C14.5 12.25 14.25 12 14 12H9.75ZM12 13.75C12 13.3438 12.3125 13 12.75 13C13.1562 13 13.5 13.3438 13.5 13.75C13.5 14.1875 13.1562 14.5 12.75 14.5C12.3125 14.5 12 14.1875 12 13.75Z" fill="#4379EF"/>
          </svg>
        </span>
        <span
          class="material-icons-outlined icons-primary qb-segmet-buttons sp-icon-style"
          style="cursor: default;"
          [matTooltip]="exportTipInProgress"
          *ngIf="appSubscriptionPlan && exportStatus === 'PENDING'"
        >
        sync
        </span>
        <span
        class="material-icons-outlined icons-primary qb-segmet-buttons sp-icon-style"
        style="cursor: default;color: #4379EF;"
        [matTooltip]="exportTipDone"
        *ngIf="appSubscriptionPlan && exportStatus === 'COMPLETED'"
      >
      published_with_changes
      </span>
        </div>
      <div class="qb-edit-icon-div">
          <span
            [ngClass]="segment['segmentType'] == 'STANDARD' ? 'material-icons-outlined icons-primary qb-not-allowed':'material-icons-outlined icons-primary qb-segmet-buttons'"
            data-button-name="Edit"
            [attr.data-item-name]="
              '(' + segmentId + ')' + segment['segmentName']
            "
            [matTooltip]="(segment['segmentType'] == 'STANDARD' ?'You can not edit a preset segment. You can instead clone and create a new segment.':'Edit') | i18nDynamicTranslate : 'POS_PAGE'"
            *ngIf="appSubscriptionPlan"
            appButtonDebounce
            [throttleTime]="600"
            (throttledClick)="segment['segmentType'] == 'STANDARD' ?'':editSegment()"
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.125 12.2812C2.21875 11.9688 2.375 11.6562 2.5625 11.4062V11.375H2.59375C2.65625 11.2812 2.75 11.1875 2.8125 11.125L12.3125 1.625C13.0938 0.84375 14.375 0.84375 15.1562 1.625L16.375 2.84375C16.4688 2.9375 16.5625 3.0625 16.625 3.15625C17.1562 3.9375 17.0625 5 16.375 5.6875L6.875 15.1875C6.84375 15.2188 6.78125 15.25 6.75 15.3125C6.71875 15.3438 6.65625 15.375 6.625 15.4062V15.4375H6.59375C6.34375 15.625 6.03125 15.7812 5.71875 15.875L3.28125 16.5938L1.9375 17C1.6875 17.0625 1.40625 17 1.21875 16.7812C1 16.5938 0.9375 16.3125 1.03125 16.0625L1.40625 14.7188L2.125 12.2812ZM12.9688 6.96875L11.0312 5.03125L5.21875 10.8438L5.59375 12.4062L7.15625 12.7812L12.9688 6.96875ZM4.03125 12.2188L3.65625 12.4688C3.625 12.5312 3.59375 12.625 3.5625 12.7188L3.34375 13.4375L2.84375 15.1562L4.5625 14.6562L5.28125 14.4375C5.375 14.4062 5.46875 14.375 5.53125 14.3438L5.78125 14L4.8125 13.75C4.53125 13.6875 4.3125 13.4688 4.25 13.1875L4.03125 12.2188ZM10.8438 7.84375L7.84375 10.8438C7.65625 11.0312 7.3125 11.0312 7.125 10.8438C6.9375 10.6562 6.9375 10.3438 7.125 10.1562L10.125 7.15625C10.3125 6.9375 10.6562 6.9375 10.8438 7.15625C11.0312 7.34375 11.0312 7.65625 10.8438 7.84375Z" fill="#4379EF"/>
            </svg>
          </span>
        <!-- <div
          style="display: flex"
          *ngIf="segment['segmentType'] == 'STANDARD'"
          matTooltip="You can not edit a preset segment. You can instead clone and create a new segment."
        >
          <div>
            <span class="qb-material-icons-outlined icons-primary"> edit </span>
          </div>
        </div> -->
      </div>
      <div class="qb-targeting-tactics-icon">
        <div>
          <span
            class="material-icons-outlined icons-primary qb-segmet-buttons"
            *ngIf="appSubscriptionPlan"
            appButtonDebounce
            [throttleTime]="600"
            (throttledClick)="cloneSegment()"
            data-button-name="Clone"
            [attr.data-item-name]="
              '(' + segmentId + ')' + segment['segmentName']
            "
            [matTooltip]="'COMMON_PAGE.CLONE' | i18nTranslate"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 12H8C6.875 12 6 11.125 6 10V2C6 0.90625 6.875 0 8 0H12.375C12.75 0 13.1562 0.1875 13.4375 0.46875L15.5312 2.5625C15.8125 2.84375 16 3.25 16 3.625V10C16 11.125 15.0938 12 14 12ZM2 4H5V5.5H2C1.71875 5.5 1.5 5.75 1.5 6V14C1.5 14.2812 1.71875 14.5 2 14.5H8C8.25 14.5 8.5 14.2812 8.5 14V13H10V14C10 15.125 9.09375 16 8 16H2C0.875 16 0 15.125 0 14V6C0 4.90625 0.875 4 2 4Z" fill="#4379EF"/>
            </svg>
            
          </span>
        </div>
      </div>
      <div class="qb-d-flex"  *ngIf="segment['segmentType'] != 'STANDARD'">
        <div>
          <span
            class="material-icons-outlined icons-primary qb-segmet-buttons"
            *ngIf="appSubscriptionPlan"
            appButtonDebounce
            [throttleTime]="600"
            (throttledClick)="deleteSegment2()"
            data-button-name="Delete"
            [attr.data-item-name]="
              '(' + segmentId + ')' + segment['segmentName']
            "
            [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate"
          >
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#4379EF"/>
            </svg>
          </span>
        </div>
      </div>
      <!-- <div
        class="qb-d-flex cursor-not-allowed"
        *ngIf="segment['segmentType'] == 'STANDARD'"
        [matTooltip]="'COMMON_PAGE.CANNOT_DELETE_PRESET' | i18nTranslate"
      >
        <div>
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#4379EF"/>
          </svg>
        </div>
      </div> -->
    </div>
  </div>
  <div class="qb-chart-container">
    <div class="qb-segment-stat-outer-div" *ngIf="store !== 'tbpoc'">
      <div class="qb-segment-stat-inter-div widget-shadow" *ngIf="store !== 'tbpoc'">
        <div class="qb-segment-stat-div">
          <span class="qb-segment-stat-text">{{'SEGMENT_PAGE.ANG_ORDER_VALUE' | i18nTranslate}}</span>
          <span class="qb-segment-stat-value"  matTooltip="{{ currencySymbol }}&nbsp;{{segmentAnalytics['avgOrderValue']}}"
            >{{ currencySymbol }}&nbsp;{{
              segmentAnalytics["avgOrderValue"]
            }}</span
          >
        </div>
      </div>
      <div class="qb-segment-stat-inter-div widget-shadow">
        <div class="qb-segment-stat-div">
          <span class="qb-segment-stat-text">{{'SEGMENT_PAGE.CUSTOMER_COUNT' | i18nTranslate}}</span>
          <span class="qb-segment-stat-value"  matTooltip="{{ currencySymbol }}&nbsp;{{segmentAnalytics['avgOrderValue']}}"
            >{{segmentAnalytics["customerCount"]}}
          </span>
        </div>
      </div>
    </div>
    <div class="qb-customer-count-container-chart widget-shadow">
      <div echarts [options]="monthWiseCustomerCountChartOptions" class="line-chart-height"></div>
    </div>
  </div>
  <div class="qb-operators-margin">
    <div class="seg-def-container-2">
      <div
        *ngFor="
          let expression of segment['segmentDefinition']['logicalExpressions'];
          let i = index
        "
      >
        <div *ngIf="expression['logicalOperator']" class="qb-group-seperator">
          <div class="qb-group-seperator-line"></div>
          <div
            *ngIf="i > 0 && expression['logicalOperator'] === 'AND'"
            class="qb-AND-operator-background"
          >
            <div class="qb-group-operator-container">
              <span>{{ expression["logicalOperator"] }}</span>
            </div>
          </div>
          <div
            *ngIf="i > 0 && expression['logicalOperator'] === 'OR'"
            class="qb-OR-operator-background"
          >
            <div class="qb-group-operator-container">
              <span>{{ expression["logicalOperator"] }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="segmentVariables">
          <div class="qb-segment-group-top-container">
            <div class="qb-w-90">
              <div
                class="qb-segment-condition-container qb-p-relative"
                *ngFor="
                  let comparisonStatement of expression['comparisonStatements'];
                  let j = index
                "
              >
                <div
                  *ngIf="j > 0 && comparisonStatement['logicalOperator'] === 'AND'"
                  class="qb-AND-operator-background qb-d-flex qb-p-relative"
                >
                  <div class="qb-segment-condition-connector"></div>
                  <div class="qb-segment-logical-operator-container">
                    <span>{{ comparisonStatement["logicalOperator"] }}</span>
                  </div>
                </div>
                <!-- <div class="qb-group-seperator-line"></div> -->
                <div
                  *ngIf="j > 0 && comparisonStatement['logicalOperator'] === 'OR'"
                  class="qb-AND-operator-background qb-d-flex qb-p-relative"
                >
                  <div class="qb-segment-condition-connector"></div>
                  <div class="qb-segment-logical-operator-container">
                    <span>{{ comparisonStatement["logicalOperator"] }}</span>
                  </div>
                </div>
                <div class="qb-segment-rule-container">
                  <div class="rule-child">
                    {{ getDisplayName(comparisonStatement["operand1"]) }}
                  </div>
                  <div class="rule-child">
                    {{ comparisonStatement["operator"] }}
                  </div>
                  <div class="rule-child">
                    <span style="overflow:auto" *ngIf="!isDateString(comparisonStatement['operand2'])">
                      {{ comparisonStatement["operand2"] }}
                    </span> 
                    <span style="overflow:auto" *ngIf="isDateString(comparisonStatement['operand2'])">
                      {{ comparisonStatement["operand2"] | date: getDateFormat()}}
                    </span>
                    <span 
                    [ngClass]="valueSuffix(comparisonStatement['operand1'],comparisonStatement['operand2'])==currencySymbol?' ':'suffix'">
                      {{valueSuffix(comparisonStatement["operand1"],comparisonStatement["operand2"])}}</span>
                  </div>
                </div>
                <div class="min-max-container">
                  <span *ngIf="comparisonStatement['operandStartN']">
                    Atleast <span class="numb-cont">{{comparisonStatement['operandStartN']}}</span>
                  </span>
                  <span *ngIf="comparisonStatement['operandStartN'] && !comparisonStatement['operandEndN']">
                     Times 
                  </span>
                  <span *ngIf="comparisonStatement['operandStartN'] && comparisonStatement['operandEndN']">
                     and 
                  </span>
                  <span *ngIf="comparisonStatement['operandEndN']">
                     Atmost <span class="numb-cont">{{comparisonStatement['operandEndN']}}</span> Times
                  </span>
                </div>
                  
              </div>
            </div>
          </div>
          <!-- <span>)</span> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!analyticsLoaded" class="centerer">
  <app-qubriux-loading [size]="45" [loadingText]="'Fetching Segment Details...'"></app-qubriux-loading>
</div>
