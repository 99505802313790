<div style="display: flex;flex-direction: column;height: 100%;justify-content: space-between;">
  <div class="tree-title">{{'SHARED_PAGE.ADD_FROM_SPECIFIC_PRODUCT' | i18nTranslate}}</div>

  <div class="formDiv-search" style="padding: 0 10px;border-radius: 4px;background: #F8F8F8;margin-right: 20px;">
    <input class="formInput" autocomplete="off" matInput [(ngModel)]="search" placeholder="Search by name" (ngModelChange)="getInputField($event);search=$event;">
    <span class="material-icons-outlined icons-primary cursor-pointer" *ngIf="search.length==0">
      search
    </span>
    <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search.length>0" (click)="search='';getInputField(search)">
      close
    </span>
    <!-- setChildOk($event,dataSource.data) -->
    <!-- <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/home-screen/search.svg" alt="" > -->
  </div>
  <div style="flex-grow: 1;overflow: auto;" *ngIf="!loading">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [style.display]="!search || node.ok?'block':'none'">
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node" (change)="todoItemSelectionToggle($event.checked,node)"
          [checked]="node.selected">{{node.name}}</mat-checkbox>


      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
      [style.display]="!search || node.ok?'block':'none'">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox [checked]="node.selected" [indeterminate]="node.indeterminate && !node.selected"
            (change)="todoItemSelectionToggle($event.checked,node)">{{node.name}}</mat-checkbox>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>

<div style="flex-grow: 1;overflow: auto;justify-content: center;align-items: center;" *ngIf="loading">
  <mat-spinner diameter="15"></mat-spinner>
</div>
  <button mat-button (click)="submit()">{{'SHARED_PAGE.SUBMIT' | i18nTranslate}}</button>

</div>