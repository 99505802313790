import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { FilterDialogService } from 'src/app/core/services/filter-dialog.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})
export class FilterDialogComponent implements OnInit {

  segments = {};
  filterSubscription: Subscription;
  filters = {};
  loading = true;
  currentFilters;

  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,
              private apiCall: ApiCallService,
              private filterData: FilterDialogService) { }

  ngOnInit() {
    this.getSegments();
    this.currentFilters = this.filterData.getFilters();
  }

  getSegments() {
    this.apiCall.getAllCustomerSegments().subscribe(response => {
      JSON.parse(response["body"]).forEach(element => {
        this.segments[element['id']] = element['segmentName'];
      });
      this.getSelectedFilters();
    });
  }

  getSelectedFilters() {
    this.filters = this.filterData.getFilters();
    if(this.filters['segments'] === undefined || this.filters['segments'].length == 0)
      this.filters['segments'] = []
    this.loading = false;
  }

  applyFilter() {
    this.filters['addFilters'] = true;
    this.filterData.setFilters(this.filters);
  }

  addSegmentFilter(value) { 
    // if(event.target.checked) {
    //   if(event.target.name === "ALL")
    //     this.filters['segments'] = [];
    //   else {
    //     let index = this.filters['segments'].indexOf("ALL");
    //     if (index !== -1) 
    //       this.filters['segments'].splice(index, 1); 
    //   }
    //   this.filters['segments'].push(event.target.name)
    // } else {
    //   const index = this.filters['segments'].indexOf(event.target.name, 0);
    //   if (index > -1) {
    //     this.filters['segments'].splice(index, 1);
    //   }
    // }
    if (this.filters['segments'].indexOf(value) != -1) {
      this.filters['segments'].splice(
        this.filters['segments'].indexOf(value),
        1
      );
      if(this.filters['segments'].indexOf('ALL') != -1 && value !== 'ALL') {
        this.filters['segments'].splice(
          this.filters['segments'].indexOf('ALL'),
          1
        );
      }
      } else {
      this.filters['segments'].push(value);
      if(value === 'ALL') 
      for (let key in this.segments) {
        if (this.filters['segments'].indexOf(key) == -1)
            this.filters['segments'].push(key);
      }
    }
    for (let key in this.segments) {
      if(this.filters['segments'].indexOf(key) == -1)
        if(this.filters['segments'].indexOf('ALL') != -1)
        this.filters['segments'].splice(this.filters['segments'].indexOf('ALL'), 1);
  }
  if(value === 'ALL')  {
    if(this.filters['segments'].indexOf('ALL') == -1) {
      this.filters['segments'] = [];
    }
  }
  }

  clear() {
    this.filters['segments'] = [];
  }
}
