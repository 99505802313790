import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";

@Component({
  selector: "app-forms-template-list",
  templateUrl: "./forms-template-list.component.html",
  styleUrls: ["./forms-template-list.component.css"],
})
export class FormsTemplateListComponent implements OnInit {
  sub: Subscription;
  formData = [];
  loading = true;
  surveys;
  recordsPerPage = 5;
  pageNumber = 1;
  sortOrderByAsc = true;
  status = ["ACTIVE", "DRAFT", "ENDED"];
  scheduleInfo;

  constructor(private router: Router, private apiCall: ApiCallService, private tokenStorage: TokenStorageService) {}

  ngOnInit() {
    this.getAllForm(
      this.recordsPerPage,
      this.pageNumber,
      this.sortOrderByAsc,
      this.status,
      ""
    );
  }

  createForm() {
    this.router.navigate(["/app/forms/new-form", { id: "-1" }]);
  }

  getAllForm(recordsPerPage, pageNumber, sortOrderByAsc, status, name) {
    this.loading = true;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .getAllForms(
        recordsPerPage,
        pageNumber,
        sortOrderByAsc,
        status,
        "STANDARD",
        name
      )
      .subscribe((response) => {
        if (
          response["message"] === "SUCCESS" ||
          response["message"] === "success"
        ) {
          this.formData = JSON.parse(JSON.parse(response["body"]).forms);
          this.formData.forEach((element) => {
            element.scheduleInfo = JSON.parse(element.scheduleInfo);
          });
          this.loading = false;
        }
      });
  }

  editTemplate(id) {
    this.router.navigate(["/app/forms/new-form", { id: id }]);
  }

  getShowTo(value) {
    let a = JSON.parse(value);
    return a.showTo;
  }

  cloneForm(formID) {
    this.router.navigate([
      "/app/forms/new-form",
      {
        id: formID,
        clone: true,
        return: "/app/forms/overview",
      },
    ]);
  }
}
