import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";

@Component({
  selector: "app-social-media-my-posts",
  templateUrl: "./social-media-my-posts.component.html",
  styleUrls: ["./social-media-my-posts.component.css"],
})
export class SocialMediaMyPostsComponent implements OnInit {
  constructor(
    private _apicall: ApiCallService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _dialog: MatDialog,
    public _tokenStorageService:TokenStorageService,
    private date_format: getdateformatService
  ) {
    let params = this._route.snapshot.queryParams;
  
    if (params.post_id) {
      this.postAnalyticsPageSelected = true;
      this.objectId = params.post_id;
      this.clientId=params.cid;
    } else {
      this.postAnalyticsPageSelected = false;
    }

  }

  postAnalyticsPageSelected = false;
  loading = false;
  page = 1;
  size = 10;
  total_count=0;
  postId;

  listHeaders = [
    {
      title: "Content",
      width: "55",
    },
    {
      title: "Schedule date",
      width: "15",
    },
    
    {
      title: "Channels",
      width: "15",
    },
    {
      title: "Published By",
      width: "15",
    },
  ];

  postList;
  objectId;
  channel="facebook";

  postDataObj;
  postAnalyticsObj;
  postInsightData;
  clientId;

  ngOnInit() {

    if (!this.postAnalyticsPageSelected) {
      this.postId = null;
      this.getPostDetails();
    } else {
      this.postId = this.objectId.split("_")[1];
     // this.getPostDetailsWithAnalytics();

    }
  }

  getPostDetails() {
    this.loading = true;
    this._apicall
      .getSocialMediaPosts(this.page, this.size)
      .subscribe((response) => {
        let ob = response["body"].posts;
        this.total_count=response["body"].totalCount;

        for (let i = 0; i < response["body"].posts.length; i++) {
          let content = JSON.parse(response["body"].posts[i].contents);
          ob[i].contents = content;
      
        }
        this.postList = ob;
        console.log(this.postList)
        this.loading = false;
      });
  }
  createNewPost() {
    this._router.navigate(["app/social-eng/social/post"]);
  }

  navigateToPostAnalytics(id) {

    this._router.navigate(["app/social-eng/social/post"], {
      queryParams: {
        post_id: id,
      },
    });
  }
  getPostDetailsWithAnalytics(){
    this.loading = true;
    this._apicall
      .getPostAnalytics(this.objectId, this.channel)
      .subscribe((response) => {
        let ob = response["body"];
let obp=JSON.parse(response["body"]);

this.processData(obp);
  
      })
  }

  processData(ob){

    if(ob.error){
    }else{
      let obj =ob[this.objectId]

let insightMetricsData=obj.insights.data;

let engagementValueObj = insightMetricsData.find(
  (opt) => opt.name == "post_engaged_users" && opt.period == "lifetime"
);
let negative_feedback_ValueObj=insightMetricsData.find(
  (opt) => opt.name == "post_negative_feedback" && opt.period == "lifetime"
);

let analyticsdataPoints={
  shares:obj.shares.count,
  reactions:obj.reactions.summary.total_count,
  likes:obj.likes.summary.total_count,
  comments:obj.comments.summary.total_count,
  engagement:engagementValueObj.values[0].value,
  negative_feedback:negative_feedback_ValueObj.values[0].value,
}
this.postAnalyticsObj=analyticsdataPoints;



this.loading=false;

// let summarydataPoints={

// }




      
    }

  }

  getNextData(pageEvent) {

    this.page = pageEvent.pageIndex + 1;
    this.size = pageEvent.pageSize;

    this.getPostDetails();
     
  }
  viewInsight(id){
    this._router.navigate(["app/social/my-post"], {
      queryParams: {
        post_id: id,
      },
    });
  }

  editPost(id){
    this._router.navigate(["app/social-eng/social/post"], {
      queryParams: {
        id: id,
      },
    });
  }

  clonePost(id){
    this._router.navigate(["app/social/post"], {
      queryParams: {
        id: id,
        clone:true
      },
    });
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

}
