import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-disable-partnership-offer',
  templateUrl: './disable-partnership-offer.component.html',
  styleUrls: ['./disable-partnership-offer.component.css']
})
export class DisablePartnershipOfferComponent implements OnInit {

  reason = "Inventory exhausted";
  customReason = "";
  customReasonError = "";
  reasons = [];
  offerDisabled = false;

  constructor(public dialogRef: MatDialogRef<DisablePartnershipOfferComponent>, private router: Router, private apiCall: ApiCallService, private snackBar: SnackbarCollection, @Inject(MAT_DIALOG_DATA) public data: any, private _i18nDynamicTranslate: TranslateDynamicText) { }

  ngOnInit() {
    this.apiCall.getOfferConfig().subscribe(response => {
      this.reasons = response['body']['offerDisableDropdown'];
    })
  }

  disableOffer() {
    if(this.reason !== 'Others' || this.customReason.length > 0) {
      if(this.reason !== 'Others')
      this.dialogRef.close(this.reason);
      else
      this.dialogRef.close(this.customReason);
    } else {
      this.customReasonError = this._i18nDynamicTranslate.transform("This field is required", ['SHARED_PAGE'])
    }
  }

}
