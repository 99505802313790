import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

@Component({
  selector: "app-suppression-lists",
  templateUrl: "./suppression-lists.component.html",
  styleUrls: ["./suppression-lists.component.css"],
})
export class SuppressionListsComponent implements OnInit {

  selectedTab;
  validScreens = null;
  constructor(private router: Router, private route: ActivatedRoute, private _WidgetConfigService: WidgetConfigService,) {
    window["screen-name"] = "SUPPRESSION LIST";
  }

   ngOnInit() {
    this.getScreenId();
    this.reRoute();
  }
  async getScreenId(){
    this.validScreens = await this._WidgetConfigService.getScreen();
  }

  reRoute() {
    this.route.queryParams.subscribe((params) => {
      if (params.type) this.selectedTab = params.type;
    });
  }

  selectTab(tab) {
    this.router.navigate(["/app/customers/suppression-lists"], {
      queryParams: { type: tab },
    });
    this.reRoute();
  }
}