import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-email-subject-dialog',
  templateUrl: './add-email-subject-dialog.component.html',
  styleUrls: ['./add-email-subject-dialog.component.css']
})
export class AddEmailSubjectDialogComponent implements OnInit {

  dataForm = new FormGroup({
    Subject: new FormControl('', [Validators.required,Validators.maxLength(50),Validators.minLength(1)]),
  })
  constructor(public dialogRef: MatDialogRef<AddEmailSubjectDialogComponent>) { }
  submitClicked:boolean=false;
  ngOnInit() {
  }

  closeDialog(value){
    if(value){
      this.submitClicked=true;
      if(!this.dataForm.valid){
        return;
      }
      // else if(this.dataForm.controls.Subject.hasError('pattern')){
      //   return;
      // }
      else{
        this.dialogRef.close(this.dataForm.get('Subject').value)
      }
    }
    else{
      this.dialogRef.close(value)

    }
  
}
  

}
