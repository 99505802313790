import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateFormComponent, FormsTemplateListComponent, NewFormComponent, ProspectListComponent } from './pages';
import { FormsOverviewComponent } from './pages/forms-overview/forms-overview.component';

const routes: Routes = [
    { path: "", redirectTo: "overview", pathMatch: "full" },
    { path: "overview", component: FormsOverviewComponent },
    { path: "create-form", component: CreateFormComponent },
    { path: "new-form", component: NewFormComponent, data: { titleheading: "New-Form", title: "New-Form", }, },
    { path: "list-form", component: FormsTemplateListComponent },
    { path: "prospect-list", component: ProspectListComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcquireRoutingModule { }
