import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog"; 
import { Router, ActivatedRoute } from "@angular/router";
import { getCurrencySymbol } from "@angular/common";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent } from "src/app/shared/components/dialog-box";
import { environment } from "src/environments/environment";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-edit-segments",
  templateUrl: "./edit-segments.component.html",
  styleUrls: ["./edit-segments.component.css"],
})
export class EditSegmentsComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    public router: Router,
    private popUp: PopUpDialogService,
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private globalService: Globals,
    private route: ActivatedRoute,
    private _tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {
    window["screen-name"] = "SEGMENTS";
  }
  onboarding = false;
  s3BucketUrl = environment.s3BucketUrl;
  subscription;
  segmentList = [];
  filter = {
    label: "",
  };
  store;
  loading = true;
  segmentData;
  indexSubscription;
  data;
  allsegments;
  validWidgets = null;
  segmentType = "ALL";
  selectedSegmentType = "All segments";
  appSubscriptionPlan;
  revCurrency;
  currencySymbol;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  selectedSegmentId;
  sub;
  isSegmentOnboarding: boolean = false;
  iscustomersSegmented = true;
  loadingSegments = false;
  originalSegmentList = [];
  allCount = 0;
  presetCount = 0;
  createdCount = 0;
  qId;
  segmentsCreatedByStore;
  oringinalSegmentsCreatedByStore;
  segmentsNotCreatedByStore
  oringalSegmentsNotCreatedByStore
  // isSegmentJobCompleted;
  @ViewChild("header") header: ElementRef;
  @ViewChild("container") container: ElementRef;


  ngOnInit() {
    this.store = this._tokenStorage.getMerchantOnlineStore();
    this.checkAndLoadInit();
    this.qId = this._tokenStorage.getqId()=='null'?null:this._tokenStorage.getqId();
    // if (this._tokenStorage.getIsOnboardingCompleted()=='true') {
    //   this.iscustomersSegmented=true;
    //   this.checkAndLoadInit();
    // } else {
    //   this.isSegmentOnboarding = true;
    //   this.iscustomersSegmented=false;
    //   this.onboardStatus();
    // }

  }


  // onboardStatus() {
  //   this.apiCall.getOnboardingProgressStatus().subscribe((response) => {
  //     let responseBody = response["body"];
  //     this.iscustomersSegmented = responseBody.customersSegmented;
  //     this.isSegmentOnboarding=false;
  //     if(this.iscustomersSegmented){
  //       this.checkAndLoadInit();
  //     }
  //   });
  // }

  checkAndLoadInit() {
    this.getWidgets();
    this.getSegments(true);
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    // this.sub = this.globalService.isOnboarding.subscribe((data) => {
    //   if (data) {
    //     this.onboarding = data;
    //   } else {
    //     this.onboarding = false;
    //     if (this.sub) this.sub.unsubscribe();
    //   }
    // });
  }

  ngAfterViewInit() {}

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.segmentData = null;
    if (this.indexSubscription) {
      this.indexSubscription.unsubscribe();
    }
  }

  scrollToLocation(data) {
    let interval = setInterval(() => {
      let element = document.getElementById(data);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        clearInterval(interval);
      }
    }, 10);
  }

  getSegments(segmentStatus) {
    this.loadingSegments = true;
    const startTime = performance.now();
    this.apiCall.getCustomerSegments().subscribe(
      (response) => {
        this.loading = false;
        this.originalSegmentList = (response['body']);
        if(this.qId){
          const segmentsCreatedByStore = this.originalSegmentList.filter(segment => segment.createdBy?.storeId === this.qId);
          const segmentsNotCreatedByStore = this.originalSegmentList.filter(segment => segment.createdBy?.storeId !== this.qId);
          this.oringinalSegmentsCreatedByStore = segmentsCreatedByStore;
          this.oringalSegmentsNotCreatedByStore = segmentsNotCreatedByStore;
          this.segmentsCreatedByStore = this.oringinalSegmentsCreatedByStore;
          this.segmentsNotCreatedByStore = this.oringalSegmentsNotCreatedByStore;
          this.segmentList = [...segmentsCreatedByStore, ...segmentsNotCreatedByStore];
        }
        else{
          this.segmentList = [...this.originalSegmentList];
        }
        this.getTypeCount();
        if (segmentStatus && !this.mobileMedia.matches) {
          let param = this.route.params["_value"]["id"];
          if (param) {
            this.selectedSegmentId = param;
            this.scrollToLocation(param);
          } else {
            if(this.segmentList.length>0) {
              this.segmentData = this.segmentList[0];
              this.selectedSegmentId = this.segmentData["id"];
            }  
          }
        } else {
          if(this.segmentList.length>0) {
            this.router.navigate([
              "/app/segments",
              { id: this.segmentList[0]["id"] },
            ]);
            this.selectedSegmentId = this.segmentList[0]["id"];
          } else {
            this.router.navigate([
              "/app/segments",
            ]);
          }
        }
        const apiDuration = performance.now() - startTime;
        if (apiDuration <= 1500) {
          setTimeout(() => this.loadingSegments = false, 1500 - apiDuration);
        } else {
          this.loadingSegments = false;
        }
      },
      (err) => {
        this.popUp.openPopup("failure", err.error.body);
      }
      
    );
  }

  getTypeCount() {
    this.allCount = this.originalSegmentList.length;
    const counts = this.originalSegmentList.reduce((acc, segment) => {
      if (segment.segmentType === 'STANDARD') {
        acc.presetCount++;
      } else {
        acc.createdCount++;
      }
      return acc;
    }, { presetCount: 0, createdCount: 0 });
    this.presetCount = counts.presetCount;
    this.createdCount = counts.createdCount;
  }

  deleteSegment(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: this.mobileMedia.matches ? "90%" : "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Segment", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Do you wish to delete this segment?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deleteCustomerSegment(id).subscribe(
          (response) => {
            this.popUp.openPopup(this._i18nDynamicTranslate.transform("success", ['POS_PAGE']), this._i18nDynamicTranslate.transform("Delete successfull", ['POS_PAGE']));
            this.ngOnInit();
          },
          (err) => {
            const dialogRef = this.dialog.open(ContactUsDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              height: "auto",
              disableClose: true,
              width: "90%",
              data: {
                header: this._i18nDynamicTranslate.transform("Warning", ['POS_PAGE']),
                body: this._i18nDynamicTranslate.transform(JSON.parse(err.error["body"])["detailMessage"], ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              // this.disabled = false;
            });
          }
        );
      }
    });
  }

  async createSegment() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/segments/create-segment",
        { id: null, clone: false },
      ]);
    }
  }

  viewSegment(id, index, segmentType) {
    this.router.navigate(["/app/segments", { id: id }]);
    this.selectedSegmentId = id;
  }

  segmentDataFromChild(event) {
    if(this.qId==null){
      this.originalSegmentList.splice(this.originalSegmentList.findIndex((x) => x.id === event),1);
    }
    else{
      let index = this.oringinalSegmentsCreatedByStore.findIndex((x)=>x.id ==event);
      if(index!== -1){
        this.oringinalSegmentsCreatedByStore.splice(index,1);
        this.originalSegmentList.splice(this.originalSegmentList.findIndex((x) => x.id === event),1);
      }
      else{
        index = this.oringalSegmentsNotCreatedByStore.findIndex((x)=>x.id ==event);
        if(index!== -1){
          this.oringalSegmentsNotCreatedByStore.splice(index,1);
          this.originalSegmentList.splice(this.originalSegmentList.findIndex((x) => x.id === event),1);
        }
      }
    }
    this.selectSegmentType(this.segmentType);
    this.scrollToLocation(this.selectedSegmentId);
    this.filter.label = '';
    this.getTypeCount();
  }

  selectSegmentType(type) {
    this.segmentType = type;
    switch (type) {
      case "ALL":
        this.selectedSegmentType = "All segments";
        this.segmentList = [...this.originalSegmentList];
        this.segmentsCreatedByStore = [...this.oringinalSegmentsCreatedByStore];
        this.segmentsNotCreatedByStore = [...this.oringalSegmentsNotCreatedByStore];
        break;
      case "PRESET":
        this.selectedSegmentType = "Preset Segments";
        this.segmentList = [...this.originalSegmentList].filter(segment=>segment.segmentType == 'STANDARD');
        this.segmentsCreatedByStore = [...this.oringinalSegmentsCreatedByStore].filter(segment=>segment.segmentType == 'STANDARD');
        this.segmentsNotCreatedByStore = [...this.oringalSegmentsNotCreatedByStore].filter(segment=>segment.segmentType == 'STANDARD');
        break;
      case "CREATED":
        this.selectedSegmentType = "Custom Segments";
        this.segmentList = [...this.originalSegmentList].filter(segment=>segment.segmentType != 'STANDARD');
        this.segmentsCreatedByStore = [...this.oringinalSegmentsCreatedByStore].filter(segment=>segment.segmentType != 'STANDARD');
        this.segmentsNotCreatedByStore = [...this.oringalSegmentsNotCreatedByStore].filter(segment=>segment.segmentType != 'STANDARD');
        break;
    }
    if(!this.segmentList.find(segment => segment.id == this.selectedSegmentId)) {
      if(this.qId==null){
        this.segmentData = this.segmentList[0];
        this.selectedSegmentId = this.segmentData["id"];
      }
      else {
        if(this.segmentsCreatedByStore.length>0){
          this.segmentData = this.segmentsCreatedByStore[0];
          this.selectedSegmentId = this.segmentData["id"];
        }
        else if(this.segmentsNotCreatedByStore.length>0){
          this.segmentData = this.segmentsNotCreatedByStore[0];
          this.selectedSegmentId = this.segmentData["id"];
        }
      }
    }
  }
  getSegmentAnalytics(segment, event) {
    let onboardingHeight = this.onboarding ? 20 : 0;
    let deleteElement = document.getElementById(
      "segment-list-delete-icon" + segment["id"]
    );
    if (deleteElement) {
      if (deleteElement.contains(event.target)) {
        return;
      }
    }
    let segmentIndex = this.segmentList.findIndex(
      (x) => x.id === segment["id"]
    );
    let statusElement = document.getElementById(
      "segment-list-status-arrow" + segment["id"]
    );
    let containerElement = document.getElementById(
      "segment-list-container" + segment["id"]
    );
    if (this.segmentList[segmentIndex]["status"]) {
      statusElement.style.transform = "rotate(0deg)";
      containerElement.style.height = "45px";
      this.segmentList[segmentIndex]["status"] = false;
    } else {
      statusElement.style.transform = "rotate(180deg)";
      containerElement.style.height =
        JSON.stringify(96 + onboardingHeight) + "px";
      this.segmentList[segmentIndex]["status"] = true;
    }
    if (!this.segmentList[segmentIndex]["Analytics"]) {
      this.apiCall.getSegmentAnalytics(segment["id"]).subscribe((response) => {
        this.segmentList[segmentIndex]["Analytics"] = JSON.parse(
          response["body"]
        );
      });
    }
  }
}
