import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";

@Component({
  selector: "app-email-template-name-dialog",
  templateUrl: "./email-template-name-dialog.component.html",
  styleUrls: ["./email-template-name-dialog.component.css"],
})
export class EmailTemplateNameDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EmailTemplateNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCall: ApiCallService
  ) {}

  field1Value: string = "";
  errorText: string = "";

  ngOnInit(): void {}

  emailTemplateNameKeyPress(event) {
    this.errorText = "";
    if (45 - this.field1Value.length === 0) {
      return false;
    }
  }

  submit() {
    if (this.field1Value.length > 3) {
      this.checkTemplateNameApi(this.field1Value).then((result) => {
        if(result){
          if (result['body'] === false) {
            this.dialogRef.close(this.field1Value);          
          }
          else{
            this.errorText = "Duplicate Template Name";
          }
        }
      });
    } else this.errorText = "Please Enter Template Name";
  }

  checkTemplateNameApi(name): Promise<object>{
    return new Promise((resolve, reject) => {
      this.apiCall.checkDuplicateNameInTemplate(name, "EMAIL").subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  cancel() {
    this.dialogRef.close(0);
  }
}
