import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiCallService } from "./api-call-service";
import { TokenStorageService } from "../auth/token-storage.service";
import { ConfirmationDialogBoxComponent } from "../../shared/components/dialog-box/confirmation-dialog-box/confirmation-dialog-box.component";
import { ContactUsDialogComponent } from "../../shared/components/dialog-box/contact-us-dialog/contact-us-dialog.component";
import { currentPlanDataService } from "./current-plan-global.service";
import { OnboardingStatus } from "src/app/shared/enums/onboarding-status.enum";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Injectable({
  providedIn: "root",
})
export class WidgetConfigService {
  constructor(
    private _ApiCallService: ApiCallService,
    private _TokenStorageService: TokenStorageService,
    private _currentPlanDataService: currentPlanDataService,
    private _dialog: MatDialog,
    private _router: Router,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  validWidgets = [];
  apiCall;
  subscriptionPlanApiCall;
  subscriptionData;
  isAppSubscriptionTransistionAllowed;
  isEmailVerified: boolean = false;
  public fetchAllValidWidgets() {
    this.apiCall = this._ApiCallService
      .fetchAllValidWidgets(this._TokenStorageService.getMerchantOnlineStore())
      .subscribe(
        (response) => {
          this.validWidgets = response["body"];
        },
        (err) => {}
      );
  }

  public fetchSubscriptionPlan() {
    this.getOnboardingSteps()
      .then((result) => {})
      .catch((error) => {
        // Logic when an error occurs
        console.log("Error:", error);
      });
    if (this.tokenStorage.getMerchantOnlineStore() === "shopify") {
      this.subscriptionPlanApiCall = this._ApiCallService
        .getCurrentPlan()
        .subscribe(
          (response) => {
            let c = response["body"];
            this._currentPlanDataService.setCurrentPlanBody(c);
            this.isEmailVerified = c["isEmailVerified"];
            this.isAppSubscriptionTransistionAllowed =
              c["isEmailVerified"] == false ||
              (c["isCustomerThresholdReached"] &&
                (c["planName"] == "Basic" || c["planName"] == "Trial"))
                ? 0
                : 1;
            response["body"]["subscriptionMasterPlanId"];
            this.subscriptionData = response["body"];

            if (
              c["isCustomerThresholdReached"] == true &&
              !(c["planName"] == "Basic" || c["planName"] == "Trial") &&
              this._router.url.includes("app/payments/payment-confirmed") ==
                false
            ) {
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "For your information",
                    message:
                      "You have reached the customer limit for this Plan. Please upgrade now to avoid the additional customer charges.",
                    cancelButtonText: "Cancel",
                    successButtonText: "Upgrade Now",
                    "data-button": "Upgrade Now",
                    "data-item": "Plan recommendation",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                if (result) {
                  this._router.navigate(["/app/payments/payment-plan"], {
                    queryParams: { subId: c["nextRecommendedSubPlanId"] },
                  });
                }
                return false;
              });
            }
          },
          (err) => {
            this.isAppSubscriptionTransistionAllowed = 0;
            if (err.status === 402) {
              if (
                this._dialog.openDialogs.length > 0 ||
                this._router.url == "/app/payments/payment-plan" ||
                this._router.url == "/app/user-profile?layout=billing"
              )
                return;
              let dialogReference = this._dialog.open(
                ContactUsDialogComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Subscription Required",
                    body: this._i18nDynamicTranslate.transform("No active subscription. To continue using Qubriux, please upgrade your plan in your profile.", ['POS_PAGE']),
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            } else {
              // this.isAppSubscriptionTransistionAllowed = 1;
              if (this._dialog.openDialogs.length > 0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Error",
                    message:
                      "Something went wrong. Please try again after some time.",
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
          }
        );
    } else {
      this.subscriptionPlanApiCall = this._ApiCallService
        .getCurrentPlanPos()
        .subscribe(
          (response) => {
            let c = response["body"];
            this._currentPlanDataService.setCurrentPlanBody(c);
            this.isEmailVerified = c["isEmailVerified"];
            this.isAppSubscriptionTransistionAllowed =
              (c["isCustomerThresholdReached"] || c["status"] == "INACTIVE")
                ? 0
                : 1;
            response["body"]["subscriptionMasterPlanId"];
            this.subscriptionData = response["body"];

            if (
              c["isCustomerThresholdReached"] == true &&
              c["status"] === "ACTIVE"
            ) {
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "For your information",
                    message:
                      "You have reached the customer limit for this Plan. Please upgrade now to avoid the additional customer charges.",
                    cancelButtonText: "Cancel",
                    successButtonText: "Upgrade Now",
                    "data-button": "Upgrade Now",
                    "data-item": "Plan recommendation",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                if (result) {
                  this._router.navigate(["/app/payments/payment-plan-pos"]);
                }
                return false;
              });
            } else if (c["status"] === "INACTIVE") {
              if(this.tokenStorage.getMerchantOnlineStore().toLocaleLowerCase().includes('restomail')){
                let dialogReference = this._dialog.open(
                  ContactUsDialogComponent,
                  {
                    panelClass: "no-padding-dialog-popup",
                    height: "auto",
                    disableClose: true,
                    width: "480px",
                    data: {
                      header: this._i18nDynamicTranslate.transform("Choose Your Plan", ['POS_PAGE']),
                      body: this._i18nDynamicTranslate.transform("It seems you don’t have an active subscription right now. Explore our range of plans and find the one that suits you best.", ['POS_PAGE']),
                      buttonText: "Explore Plans"
                    },
                  }
                );
                dialogReference.afterClosed().subscribe((result) => {
                  this._router.navigate(["payment-pos"]);
                  return false;
                });
              }
              else{
                let dialogReference = this._dialog.open(
                  ContactUsDialogComponent,
                  {
                    panelClass: "no-padding-dialog-popup",
                    height: "auto",
                    disableClose: true,
                    width: "380px",
                    data: {
                      header: "Subscription Required",
                      body: this._i18nDynamicTranslate.transform("No active subscription. To continue using Qubriux, please upgrade your plan in your profile.", ['POS_PAGE']),
                    },
                  }
                );
                dialogReference.afterClosed().subscribe((result) => {
                  return false;
                });
              }
            }
          },
          (err) => {
            this.isAppSubscriptionTransistionAllowed = 0;
            if (err.status === 402) {
              // if (
              //   this._dialog.openDialogs.length > 0 ||
              //   this._router.url == "/app/payments/payment-plan-pos" ||
              //   this._router.url == "/app/user-profile?layout=billing"
              // )
              //   return;
              let dialogReference = this._dialog.open(
                ContactUsDialogComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Subscription Required",
                    body:  this._i18nDynamicTranslate.transform("No active subscription. To continue using Qubriux, please upgrade your plan in your profile.", ['POS_PAGE']),
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            } else {
              this.isAppSubscriptionTransistionAllowed = 1;
              if (this._dialog.openDialogs.length > 0) return;
              let dialogReference = this._dialog.open(
                ConfirmationDialogBoxComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  width: "40%",
                  data: {
                    subject: "Error",
                    message:
                      "Something went wrong. Please try again after some time.",
                    cancelButtonText: false,
                    successButtonText: "Close",
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
          }
        );
    }
  }

  public clearData() {
    this.apiCall = null;
    this.validWidgets = [];
    this.isAppSubscriptionTransistionAllowed = null;
  }

  public getWidget() {
    if (!this.apiCall) {
      this.fetchAllValidWidgets();
    }
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (this.validWidgets.length !== 0) {
          clearInterval(interval);
          resolve(this.validWidgets);
        }
      }, 10);
    });
  }

  public getScreen() {
    if (!this.apiCall) {
      this.fetchAllValidWidgets();
    }
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (this.validWidgets.length !== 0) {
          clearInterval(interval);
          let filteredScreen = [];
          this.validWidgets.map((widget) => {
            let index = filteredScreen.findIndex((d) => d === widget.screenId);
            if (index < 0) {
              filteredScreen.push(widget.screenId);
            }
          });
          resolve(filteredScreen);
        }
      }, 10);
    });
  }

  getPos() {
    if (!this.apiCall) {
      this.fetchAllValidWidgets();
    }
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (this.validWidgets.length !== 0) {
          clearInterval(interval);
          resolve(this.validWidgets[0]["pos"]);
        }
      }, 10);
    });
  }

  getAppSubscriptionPlan(param?) {
    if (!(this.isAppSubscriptionTransistionAllowed > 0)) {
      this.fetchSubscriptionPlan();
    }
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (this.isAppSubscriptionTransistionAllowed > 0) {
          clearInterval(interval);
          if (param) {
            resolve(this.subscriptionData[param]);
          } else {
            if(!this.isEmailVerified){
              resolve(0);
            }
            else
              resolve(this.isAppSubscriptionTransistionAllowed);
          }
        }
      }, 10);
    });
  }
  setIsAppSubscriptionTransistionAllowed(value) {
    this.isAppSubscriptionTransistionAllowed = value;
  }

  getOnboardingSteps(): Promise<OnboardingStatus> {
    return new Promise((resolve, reject) => {
      this._ApiCallService
        .getOnboardingCheck(this.tokenStorage.getUserId())
        .subscribe(
          (response) => {
            let errorMessage = "";
            let errorHeader = "";
            if (
              !response["body"]?.["onboardingStepsResponse"]["data"]?.[
                "storeAdditionCompleted"
              ]
            ) {
              errorHeader = "Get Started"
              errorMessage =
                 this._i18nDynamicTranslate.transform("Join Qubriux and explore all the incredible features by adding your store today!", ['POS_PAGE']);
            } else if (
              !response["body"]?.["onboardingStepsResponse"]["data"]?.[
                "planAdditionCompleted"
              ]
            ) {
              errorHeader = "Subscription Required"
              errorMessage =
                this._i18nDynamicTranslate.transform( "No active subscription. To continue using Qubriux, please upgrade your plan in your profile." ['POS_PAGE']);
            } else {
            }

            if (
              this._dialog.openDialogs.length > 0 ||
              this._router.url == "/app/payments/payment-plan" ||
              this._router.url == "/app/user-profile?layout=billing" ||
              this._router.url == "/app/user-profile?layout=profile"
            ) {
              if (response["body"]?.["data"]?.["stepsCompleted"] != null) {
                resolve(
                  response["body"]?.["onboardingStepsResponse"]?.[
                    "stepsCompleted"
                  ]
                );
              }
              return;
            }

            if (errorMessage.length > 1) {
              let dialogReference = this._dialog.open(
                ContactUsDialogComponent,
                {
                  panelClass: "no-padding-dialog-popup",
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: errorHeader,
                    body: errorMessage,
                  },
                }
              );
              dialogReference.afterClosed().subscribe((result) => {
                return false;
              });
            }
            console.log("Status Code:", response);
            if (response["body"]?.["data"]?.["stepsCompleted"] != null) {
              resolve(
                response["body"]?.["onboardingStepsResponse"]?.[
                  "stepsCompleted"
                ]
              );
            }
          },
          (error) => {
            console.log("Error:", error);

            resolve(OnboardingStatus.ERROR);
          }
        );
    });
  }
}
